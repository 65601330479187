import { errorHandler } from '~/shared/lib/errors';

import template from './multicontract-position-form.html?raw';
import type { ContractsService } from '../../contracts/legacy/contracts.srv';
import type { MulticontractService } from '../multicontract.service';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const MulticontractPositionForm = {
  bindings: {
    position: '<',
    updateFieldsTrigger: '<',
    formValidChange: '&',
    calculateTotals: '&',
    changeIsActive: '&',
    getPositionContractNumber: '&',
    positionFormName: '<',
    businessUnit: '<',
  },
  template,
  controller: [
    '$scope',
    '$timeout',
    'FormFieldParamsService',
    'MulticontractService',
    'ContractsService',
    'GtUtils',
    'FinancesService',
    class {
      businessUnit: any;
      calculateTotals: any;
      changeIsActive: any;
      fields: any;
      fieldsTemplateOptions: any;
      formValidChange: any;
      getPositionContractNumber: any;
      position: any;
      positionFormName = '';
      requiredFields: any;
      unwatchChangeIsActive: any;
      unwatchCurrency: any;
      unwatchForm = () => {
        // will be initiated on first call $onChanges
      };
      unwatchPositionAutoName: any;
      unwatchPositionFinalVolume: any;
      unwatchPositionVolume: any;
      unwatchPrice: any;
      unwatchVatOption: any;
      unwatchVatValue: any;
      constructor(
        private readonly $scope: ng.IScope,
        private readonly $timeout: ng.ITimeoutService,
        private readonly FormFieldParamsService: FormFieldParamsService,
        private readonly MulticontractService: MulticontractService,
        private readonly ContractsService: ContractsService,
        private readonly GtUtils: GtUtilsService,
        private readonly FinancesService: FinancesService,
      ) {
        this.fields = [];
        this.position = {};
        this.fieldsTemplateOptions = [];
        this.requiredFields = [];
      }

      needUpdateField(changes: any) {
        return (
          changes?.updateFieldsTrigger.previousValue !== changes.updateFieldsTrigger.currentValue ||
          changes.positionFormName?.previousValue !== changes.positionFormName?.currentValue
        );
      }

      $onChanges(changes: any) {
        if (this.needUpdateField(changes)) {
          this.updateFields().catch(this.GtUtils.errorClb);
          this.unwatchForm();
          this.unwatchForm = this.$scope.$watch('$ctrl.form.$valid', (newValue: any) => {
            this.position.$formValid = newValue;
            this.formValidChange();
          });
        }
      }

      $onInit() {
        this.updateFields().catch(this.GtUtils.errorClb);
        this.unwatchPositionVolume = this.$scope.$watch('$ctrl.position.volume', () =>
          this.calculateTotals(),
        );
        this.unwatchPositionAutoName = this.$scope.$watch('$ctrl.position.auto_name', () => {
          if (this.position.auto_name) {
            this.getPositionContractNumber(this.position);
          }
        });
        this.unwatchPositionFinalVolume = this.$scope.$watch('$ctrl.position.final_volume', () =>
          this.calculateTotals(),
        );
        this.unwatchChangeIsActive = this.$scope.$watch(
          '$ctrl.position.status',
          (newVal: string | undefined, oldVal: string | undefined) => {
            if (newVal !== oldVal) {
              this.changeIsActive(this.position);
              this.calculateTotals();
            }
          },
        );
        this.unwatchPrice = this.$scope.$watch('$ctrl.position.price', () => this.setPriceWoVat());
        this.unwatchVatOption = this.$scope.$watch('$ctrl.position.VAT_option', () =>
          this.setPriceWoVat(),
        );
        this.unwatchVatValue = this.$scope.$watch('$ctrl.position.VAT_value', () =>
          this.setPriceWoVat(),
        );
        this.unwatchCurrency = this.$scope.$watch('$ctrl.position.currency', () => {
          this.ContractsService.setCurrencyExchange(this.position);
        });
      }

      $onDestroy() {
        this.unwatchForm();
        this.unwatchPositionVolume();
        this.unwatchPositionFinalVolume();
        this.unwatchPositionAutoName();
        this.unwatchChangeIsActive();
        this.unwatchPrice();
        this.unwatchVatOption();
        this.unwatchVatValue();
        this.unwatchCurrency();
      }

      async updateFields() {
        const positionFieldsConfig = await this.FormFieldParamsService.getParams(
          this.positionFormName,
          this.businessUnit,
        );

        const priceWidgetFields = await this.FormFieldParamsService.getContractPriceWidgetFields(
          this.positionFormName,
          this.businessUnit,
          positionFieldsConfig,
        );
        const widgetFieldsConfig: Record<string, { visible: boolean; required: boolean }> = {};
        priceWidgetFields.forEach((field) => {
          widgetFieldsConfig[field.field_name] = {
            visible: field.visible,
            required: field.required,
          };
        });
        const fields = await this.FormFieldParamsService.getFields(
          this.getPositionFormConfig(this.position, widgetFieldsConfig),
          this.businessUnit,
          positionFieldsConfig,
        );
        this.fields = fields.filter((item: any) => {
          if (
            Array.isArray(item.fieldGroup[0].fieldGroup) &&
            item.fieldGroup[0].fieldGroup.length
          ) {
            return !item.fieldGroup[0].fieldGroup.every(
              (field: any) => typeof field.hideExpression === 'function' && field.hideExpression(),
            );
          }
          return true;
        });
        this.fieldsTemplateOptions = this.getFlatFields(fields);
        this.requiredFields = this.fieldsTemplateOptions.filter((f: any) => f.required);
      }

      getPositionFormConfig(position: any, priceWidgetFields: any) {
        return this.MulticontractService.getContractPositionFormConfig(
          position,
          this.positionFormName,
          priceWidgetFields,
        );
      }

      getFlatFields(fields: any) {
        return this.FormFieldParamsService.getFlatFields({ fieldsDef: fields }).map(
          (field: any) => ({
            ...field.templateOptions,
            key: field.key,
          }),
        );
      }

      setPriceWoVat() {
        if (this.position.price && this.position.VAT_option) {
          this.FinancesService.applyVat({
            value: this.position.price,
            volume: 1,
            vat_value: this.position.VAT_value,
            vat_type: 'without',
          })
            .then((response) => {
              this.position._priceWoVat = response;
              return this.FinancesService.applyVat({
                value: this.position.price,
                volume: 1,
                vat_value: this.position.VAT_value,
                vat_type: 'vat',
              });
            })
            .then((response) => {
              this.position._priceVatValue = response;
              return this.FinancesService.applyVat({
                value: this.position.price,
                volume: this.position.volume,
                vat_value: this.position.VAT_value,
                vat_type: 'with',
              });
            })
            .then((response) => {
              this.position._amountWithVat = response;
              this.position.calculated_amount_with_vat = this.position._amountWithVat;
              this.$timeout();
            })
            .catch(errorHandler);
        }
      }
    },
  ],
};
