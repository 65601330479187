/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as ResetPasswordImport } from './routes/reset-password';
import { Route as LoginImport } from './routes/login';
import { Route as AuthImport } from './routes/_auth';
import { Route as R500Import } from './routes/500';
import { Route as R404Import } from './routes/404';
import { Route as R403Import } from './routes/403';
import { Route as R401Import } from './routes/401';
import { Route as AuthIndexImport } from './routes/_auth/index';
import { Route as AuthSidebarImport } from './routes/_auth/_sidebar';
import { Route as AuthSidebarStartImport } from './routes/_auth/_sidebar/start';
import { Route as AuthSidebarReportsDashboardImport } from './routes/_auth/_sidebar/reports/dashboard';
import { Route as AuthSidebarFinanceReasignmentImport } from './routes/_auth/_sidebar/finance/reasignment';
import { Route as AuthSidebarFinancePaymentsImport } from './routes/_auth/_sidebar/finance/payments';
import { Route as AuthSidebarFinancePaymentPlansImport } from './routes/_auth/_sidebar/finance/payment-plans';
import { Route as AuthSidebarFinancePaymentDelaysImport } from './routes/_auth/_sidebar/finance/payment-delays';
import { Route as AuthSidebarFinanceOffsetImport } from './routes/_auth/_sidebar/finance/offset';
import { Route as AuthSidebarFinanceInvoicesImport } from './routes/_auth/_sidebar/finance/invoices';
import { Route as AuthSidebarFinanceInvoicePositionsImport } from './routes/_auth/_sidebar/finance/invoice-positions';
import { Route as AuthSidebarFinanceChargesOrGainsImport } from './routes/_auth/_sidebar/finance/charges-or-gains';
import { Route as AuthSidebarFinanceBankAccountsImport } from './routes/_auth/_sidebar/finance/bank-accounts';
import { Route as AuthSidebarFinanceAnalyticsImport } from './routes/_auth/_sidebar/finance/analytics';
import { Route as AuthSidebarFinanceAccountingImport } from './routes/_auth/_sidebar/finance/accounting';
import { Route as AuthSidebarExecutionWarehouseImport } from './routes/_auth/_sidebar/execution/warehouse';
import { Route as AuthSidebarExecutionVesselsImport } from './routes/_auth/_sidebar/execution/vessels';
import { Route as AuthSidebarExecutionTransportImport } from './routes/_auth/_sidebar/execution/transport';
import { Route as AuthSidebarExecutionTariffsImport } from './routes/_auth/_sidebar/execution/tariffs';
import { Route as AuthSidebarExecutionSamplesImport } from './routes/_auth/_sidebar/execution/samples';
import { Route as AuthSidebarExecutionPlanningImport } from './routes/_auth/_sidebar/execution/planning';
import { Route as AuthSidebarExecutionDisbursementBillOfLadingImport } from './routes/_auth/_sidebar/execution/disbursement-bill-of-lading';
import { Route as AuthSidebarExecutionCustomsDeclarationsImport } from './routes/_auth/_sidebar/execution/customs-declarations';
import { Route as AuthSidebarExecutionConsignmentsImport } from './routes/_auth/_sidebar/execution/consignments';
import { Route as AuthSidebarExecutionBillsOfLadingImport } from './routes/_auth/_sidebar/execution/bills-of-lading';
import { Route as AuthSidebarExecutionAnalyticsImport } from './routes/_auth/_sidebar/execution/analytics';
import { Route as AuthSidebarDealsTicketsImport } from './routes/_auth/_sidebar/deals/tickets';
import { Route as AuthSidebarDealsServicesImport } from './routes/_auth/_sidebar/deals/services';
import { Route as AuthSidebarDealsQuotationsImport } from './routes/_auth/_sidebar/deals/quotations';
import { Route as AuthSidebarDealsPotentialsImport } from './routes/_auth/_sidebar/deals/potentials';
import { Route as AuthSidebarDealsPassportsImport } from './routes/_auth/_sidebar/deals/passports';
import { Route as AuthSidebarDealsMultiticketsImport } from './routes/_auth/_sidebar/deals/multitickets';
import { Route as AuthSidebarDealsMultiservicesImport } from './routes/_auth/_sidebar/deals/multiservices';
import { Route as AuthSidebarDealsMulticontractsImport } from './routes/_auth/_sidebar/deals/multicontracts';
import { Route as AuthSidebarDealsMarketImport } from './routes/_auth/_sidebar/deals/market';
import { Route as AuthSidebarDealsContractsImport } from './routes/_auth/_sidebar/deals/contracts';
import { Route as AuthSidebarDealsCalculatorImport } from './routes/_auth/_sidebar/deals/calculator';
import { Route as AuthSidebarDealsAgreementsImport } from './routes/_auth/_sidebar/deals/agreements';
import { Route as AuthSidebarCrmUpdatesImport } from './routes/_auth/_sidebar/crm/updates';
import { Route as AuthSidebarCrmCounterpartiesImport } from './routes/_auth/_sidebar/crm/counterparties';
import { Route as AuthSidebarCrmContactsImport } from './routes/_auth/_sidebar/crm/contacts';
import { Route as AuthSidebarCompanyVehiclesImport } from './routes/_auth/_sidebar/company/vehicles';
import { Route as AuthSidebarCompanyTemplatePlaygroundImport } from './routes/_auth/_sidebar/company/template-playground';
import { Route as AuthSidebarCompanyTasksTemplatesImport } from './routes/_auth/_sidebar/company/tasks-templates';
import { Route as AuthSidebarCompanySettingsImport } from './routes/_auth/_sidebar/company/settings';
import { Route as AuthSidebarCompanyQualityRuleTemplatesImport } from './routes/_auth/_sidebar/company/quality-rule-templates';
import { Route as AuthSidebarCompanyNotificationsImport } from './routes/_auth/_sidebar/company/notifications';
import { Route as AuthSidebarCompanyDriversImport } from './routes/_auth/_sidebar/company/drivers';
import { Route as AuthSidebarCompanyDocumentsImport } from './routes/_auth/_sidebar/company/documents';
import { Route as AuthSidebarCompanyCustomFieldsImport } from './routes/_auth/_sidebar/company/custom-fields';
import { Route as AuthSidebarCompanyTeamIndexImport } from './routes/_auth/_sidebar/company/team/index';
import { Route as AuthSidebarReportsTradingReportsPositionPlAndMtmImport } from './routes/_auth/_sidebar/reports/trading-reports/position-pl-and-mtm';
import { Route as AuthSidebarReportsTradingReportsPhysicalPositionMtmImport } from './routes/_auth/_sidebar/reports/trading-reports/physical-position-mtm';
import { Route as AuthSidebarReportsTradingReportsPhysicalPositionByMonthsImport } from './routes/_auth/_sidebar/reports/trading-reports/physical-position-by-months';
import { Route as AuthSidebarReportsTradingReportsLineUpImport } from './routes/_auth/_sidebar/reports/trading-reports/line-up';
import { Route as AuthSidebarReportsTradingReportsFreightImport } from './routes/_auth/_sidebar/reports/trading-reports/freight';
import { Route as AuthSidebarReportsTradingReportsFixingOrHedgingImport } from './routes/_auth/_sidebar/reports/trading-reports/fixing-or-hedging';
import { Route as AuthSidebarReportsTradingReportsDerivativesImport } from './routes/_auth/_sidebar/reports/trading-reports/derivatives';
import { Route as AuthSidebarReportsTradingReportsContractsExecutionImport } from './routes/_auth/_sidebar/reports/trading-reports/contracts-execution';
import { Route as AuthSidebarReportsRiskReportsInvoicesRiskImport } from './routes/_auth/_sidebar/reports/risk-reports/invoices-risk';
import { Route as AuthSidebarReportsRiskReportsCreditReportImport } from './routes/_auth/_sidebar/reports/risk-reports/credit-report';
import { Route as AuthSidebarReportsManagementReportsTeamImport } from './routes/_auth/_sidebar/reports/management-reports/team';
import { Route as AuthSidebarReportsManagementReportsSigningsImport } from './routes/_auth/_sidebar/reports/management-reports/signings';
import { Route as AuthSidebarReportsManagementReportsKpiImport } from './routes/_auth/_sidebar/reports/management-reports/kpi';
import { Route as AuthSidebarReportsManagementReportsDocumentsDatesImport } from './routes/_auth/_sidebar/reports/management-reports/documents-dates';
import { Route as AuthSidebarReportsManagementReportsComplianceImport } from './routes/_auth/_sidebar/reports/management-reports/compliance';
import { Route as AuthSidebarReportsManagementReportsApprovalsImport } from './routes/_auth/_sidebar/reports/management-reports/approvals';
import { Route as AuthSidebarReportsManagementReportsActivityImport } from './routes/_auth/_sidebar/reports/management-reports/activity';
import { Route as AuthSidebarReportsFinanceReportsStatisticsImport } from './routes/_auth/_sidebar/reports/finance-reports/statistics';
import { Route as AuthSidebarReportsFinanceReportsReconciliationsImport } from './routes/_auth/_sidebar/reports/finance-reports/reconciliations';
import { Route as AuthSidebarReportsFinanceReportsPassportsPnlImport } from './routes/_auth/_sidebar/reports/finance-reports/passports-pnl';
import { Route as AuthSidebarReportsFinanceReportsCostsReportImport } from './routes/_auth/_sidebar/reports/finance-reports/costs-report';
import { Route as AuthSidebarReportsFinanceReportsCashFlowImport } from './routes/_auth/_sidebar/reports/finance-reports/cash-flow';
import { Route as AuthSidebarReportsFinanceReportsBudgetsImport } from './routes/_auth/_sidebar/reports/finance-reports/budgets';
import { Route as AuthSidebarReportsFinanceReportsBalanceImport } from './routes/_auth/_sidebar/reports/finance-reports/balance';
import { Route as AuthSidebarReportsFinanceReportsApArImport } from './routes/_auth/_sidebar/reports/finance-reports/ap-ar';
import { Route as AuthSidebarDealsTicketsSaleTicketsImport } from './routes/_auth/_sidebar/deals/tickets/sale-tickets';
import { Route as AuthSidebarDealsTicketsPurchaseTicketsImport } from './routes/_auth/_sidebar/deals/tickets/purchase-tickets';
import { Route as AuthSidebarDealsServicesGeneralContractsImport } from './routes/_auth/_sidebar/deals/services/general-contracts';
import { Route as AuthSidebarDealsServicesDirectContractsImport } from './routes/_auth/_sidebar/deals/services/direct-contracts';
import { Route as AuthSidebarDealsPassportsPassportImport } from './routes/_auth/_sidebar/deals/passports/_passport';
import { Route as AuthSidebarDealsMultiticketsMultiticketImport } from './routes/_auth/_sidebar/deals/multitickets/_multiticket';
import { Route as AuthSidebarDealsMultiservicesMultiserviceImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice';
import { Route as AuthSidebarDealsMarketOffersImport } from './routes/_auth/_sidebar/deals/market/offers';
import { Route as AuthSidebarDealsMarketBidsImport } from './routes/_auth/_sidebar/deals/market/bids';
import { Route as AuthSidebarDealsMarketOfferImport } from './routes/_auth/_sidebar/deals/market/_offer';
import { Route as AuthSidebarDealsMarketBidImport } from './routes/_auth/_sidebar/deals/market/_bid';
import { Route as AuthSidebarDealsAgreementsAgreementImport } from './routes/_auth/_sidebar/deals/agreements/_agreement';
import { Route as AuthSidebarCrmNewslettersTemplatesImport } from './routes/_auth/_sidebar/crm/newsletters/templates';
import { Route as AuthSidebarCrmNewslettersLogisticsImport } from './routes/_auth/_sidebar/crm/newsletters/logistics';
import { Route as AuthSidebarCrmNewslettersInvoicesImport } from './routes/_auth/_sidebar/crm/newsletters/invoices';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty';
import { Route as AuthSidebarCompanySettingsPersonalImport } from './routes/_auth/_sidebar/company/settings/personal';
import { Route as AuthSidebarCompanySettingsParsingImport } from './routes/_auth/_sidebar/company/settings/parsing';
import { Route as AuthSidebarCompanySettingsOrganizationImport } from './routes/_auth/_sidebar/company/settings/organization';
import { Route as AuthSidebarCompanyDriversDriverImport } from './routes/_auth/_sidebar/company/drivers/_driver';
import { Route as AuthSidebarCompanyTeamTraderIdIndexImport } from './routes/_auth/_sidebar/company/team/$traderId/index';
import { Route as AuthSidebarCompanyTeamTraderIdVoyagesImport } from './routes/_auth/_sidebar/company/team/$traderId/voyages';
import { Route as AuthSidebarCompanyTeamTraderIdUpdatesImport } from './routes/_auth/_sidebar/company/team/$traderId/updates';
import { Route as AuthSidebarCompanyTeamTraderIdTicketsImport } from './routes/_auth/_sidebar/company/team/$traderId/tickets';
import { Route as AuthSidebarCompanyTeamTraderIdSettingsImport } from './routes/_auth/_sidebar/company/team/$traderId/settings';
import { Route as AuthSidebarCompanyTeamTraderIdPassportsImport } from './routes/_auth/_sidebar/company/team/$traderId/passports';
import { Route as AuthSidebarCompanyTeamTraderIdLogisticsImport } from './routes/_auth/_sidebar/company/team/$traderId/logistics';
import { Route as AuthSidebarCompanyTeamTraderIdInfoImport } from './routes/_auth/_sidebar/company/team/$traderId/info';
import { Route as AuthSidebarCompanyTeamTraderIdIndicatorsImport } from './routes/_auth/_sidebar/company/team/$traderId/indicators';
import { Route as AuthSidebarCompanyTeamTraderIdHistoryImport } from './routes/_auth/_sidebar/company/team/$traderId/history';
import { Route as AuthSidebarCompanyTeamTraderIdFinancesImport } from './routes/_auth/_sidebar/company/team/$traderId/finances';
import { Route as AuthSidebarCompanyTeamTraderIdDocumentsImport } from './routes/_auth/_sidebar/company/team/$traderId/documents';
import { Route as AuthSidebarCompanyTeamTraderIdDerivativesImport } from './routes/_auth/_sidebar/company/team/$traderId/derivatives';
import { Route as AuthSidebarCompanyTeamTraderIdContractsImport } from './routes/_auth/_sidebar/company/team/$traderId/contracts';
import { Route as AuthSidebarCompanyTeamTraderIdContactsImport } from './routes/_auth/_sidebar/company/team/$traderId/contacts';
import { Route as AuthSidebarCompanyTeamTraderIdCompaniesImport } from './routes/_auth/_sidebar/company/team/$traderId/companies';
import { Route as AuthSidebarCompanyTeamTraderIdChargesImport } from './routes/_auth/_sidebar/company/team/$traderId/charges';
import { Route as AuthSidebarCompanyTeamTraderIdApprovalsImport } from './routes/_auth/_sidebar/company/team/$traderId/approvals';
import { Route as AuthSidebarCompanySettingsListVesselSizesImport } from './routes/_auth/_sidebar/company/settings/_list/vessel-sizes';
import { Route as AuthSidebarCompanySettingsListVehiclesImport } from './routes/_auth/_sidebar/company/settings/_list/vehicles';
import { Route as AuthSidebarCompanySettingsListVehicleSubtypesImport } from './routes/_auth/_sidebar/company/settings/_list/vehicle-subtypes';
import { Route as AuthSidebarCompanySettingsListUsersImport } from './routes/_auth/_sidebar/company/settings/_list/users';
import { Route as AuthSidebarCompanySettingsListTerminalsImport } from './routes/_auth/_sidebar/company/settings/_list/terminals';
import { Route as AuthSidebarCompanySettingsListStockExchangeImport } from './routes/_auth/_sidebar/company/settings/_list/stock-exchange';
import { Route as AuthSidebarCompanySettingsListStationsImport } from './routes/_auth/_sidebar/company/settings/_list/stations';
import { Route as AuthSidebarCompanySettingsListResponsibilitiesImport } from './routes/_auth/_sidebar/company/settings/_list/responsibilities';
import { Route as AuthSidebarCompanySettingsListRegionsImport } from './routes/_auth/_sidebar/company/settings/_list/regions';
import { Route as AuthSidebarCompanySettingsListQualityImport } from './routes/_auth/_sidebar/company/settings/_list/quality';
import { Route as AuthSidebarCompanySettingsListPortsImport } from './routes/_auth/_sidebar/company/settings/_list/ports';
import { Route as AuthSidebarCompanySettingsListPaymentInfoImport } from './routes/_auth/_sidebar/company/settings/_list/payment-info';
import { Route as AuthSidebarCompanySettingsListOtherActivitiesImport } from './routes/_auth/_sidebar/company/settings/_list/other-activities';
import { Route as AuthSidebarCompanySettingsListNewsletterImport } from './routes/_auth/_sidebar/company/settings/_list/newsletter';
import { Route as AuthSidebarCompanySettingsListLogisticTypesImport } from './routes/_auth/_sidebar/company/settings/_list/logistic-types';
import { Route as AuthSidebarCompanySettingsListGroupsImport } from './routes/_auth/_sidebar/company/settings/_list/groups';
import { Route as AuthSidebarCompanySettingsListGeneralExpensesImport } from './routes/_auth/_sidebar/company/settings/_list/general-expenses';
import { Route as AuthSidebarCompanySettingsListFinanceBankImport } from './routes/_auth/_sidebar/company/settings/_list/finance-bank';
import { Route as AuthSidebarCompanySettingsListFinanceAccountsImport } from './routes/_auth/_sidebar/company/settings/_list/finance-accounts';
import { Route as AuthSidebarCompanySettingsListExchangesImport } from './routes/_auth/_sidebar/company/settings/_list/exchanges';
import { Route as AuthSidebarCompanySettingsListDriversImport } from './routes/_auth/_sidebar/company/settings/_list/drivers';
import { Route as AuthSidebarCompanySettingsListDocumentsImport } from './routes/_auth/_sidebar/company/settings/_list/documents';
import { Route as AuthSidebarCompanySettingsListDistrictsImport } from './routes/_auth/_sidebar/company/settings/_list/districts';
import { Route as AuthSidebarCompanySettingsListDerivativesImport } from './routes/_auth/_sidebar/company/settings/_list/derivatives';
import { Route as AuthSidebarCompanySettingsListDerivativeAccountsImport } from './routes/_auth/_sidebar/company/settings/_list/derivative-accounts';
import { Route as AuthSidebarCompanySettingsListDeliveryConditionsImport } from './routes/_auth/_sidebar/company/settings/_list/delivery-conditions';
import { Route as AuthSidebarCompanySettingsListCurrenciesImport } from './routes/_auth/_sidebar/company/settings/_list/currencies';
import { Route as AuthSidebarCompanySettingsListCountriesImport } from './routes/_auth/_sidebar/company/settings/_list/countries';
import { Route as AuthSidebarCompanySettingsListContractChargesImport } from './routes/_auth/_sidebar/company/settings/_list/contract-charges';
import { Route as AuthSidebarCompanySettingsListConfigsImport } from './routes/_auth/_sidebar/company/settings/_list/configs';
import { Route as AuthSidebarCompanySettingsListCompanyGroupsImport } from './routes/_auth/_sidebar/company/settings/_list/company-groups';
import { Route as AuthSidebarCompanySettingsListCommoditiesImport } from './routes/_auth/_sidebar/company/settings/_list/commodities';
import { Route as AuthSidebarCompanySettingsListClientChatsImport } from './routes/_auth/_sidebar/company/settings/_list/client-chats';
import { Route as AuthSidebarCompanySettingsListCitiesImport } from './routes/_auth/_sidebar/company/settings/_list/cities';
import { Route as AuthSidebarCompanySettingsListChatsImport } from './routes/_auth/_sidebar/company/settings/_list/chats';
import { Route as AuthSidebarCompanySettingsListBotsImport } from './routes/_auth/_sidebar/company/settings/_list/bots';
import { Route as AuthSidebarCompanySettingsListAddressesImport } from './routes/_auth/_sidebar/company/settings/_list/addresses';
import { Route as AuthSidebarDealsTicketsSaleTicketIdIndexImport } from './routes/_auth/_sidebar/deals/tickets/sale/$ticketId/index';
import { Route as AuthSidebarDealsTicketsPurchaseTicketIdIndexImport } from './routes/_auth/_sidebar/deals/tickets/purchase/$ticketId/index';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdIndexImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/index';
import { Route as AuthSidebarDealsServicesDirectContractContractIdIndexImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/index';
import { Route as AuthSidebarDealsTicketsSaleTicketIdTelegramImport } from './routes/_auth/_sidebar/deals/tickets/sale/$ticketId/telegram';
import { Route as AuthSidebarDealsTicketsSaleTicketIdTasksImport } from './routes/_auth/_sidebar/deals/tickets/sale/$ticketId/tasks';
import { Route as AuthSidebarDealsTicketsSaleTicketIdQualityImport } from './routes/_auth/_sidebar/deals/tickets/sale/$ticketId/quality';
import { Route as AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseImport } from './routes/_auth/_sidebar/deals/tickets/sale/$ticketId/potential-purchase';
import { Route as AuthSidebarDealsTicketsSaleTicketIdHistoryImport } from './routes/_auth/_sidebar/deals/tickets/sale/$ticketId/history';
import { Route as AuthSidebarDealsTicketsSaleTicketIdDocumentsImport } from './routes/_auth/_sidebar/deals/tickets/sale/$ticketId/documents';
import { Route as AuthSidebarDealsTicketsSaleTicketIdCostsImport } from './routes/_auth/_sidebar/deals/tickets/sale/$ticketId/costs';
import { Route as AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsImport } from './routes/_auth/_sidebar/deals/tickets/sale/$ticketId/additional-agreements';
import { Route as AuthSidebarDealsTicketsPurchaseTicketIdTelegramImport } from './routes/_auth/_sidebar/deals/tickets/purchase/$ticketId/telegram';
import { Route as AuthSidebarDealsTicketsPurchaseTicketIdTasksImport } from './routes/_auth/_sidebar/deals/tickets/purchase/$ticketId/tasks';
import { Route as AuthSidebarDealsTicketsPurchaseTicketIdQualityImport } from './routes/_auth/_sidebar/deals/tickets/purchase/$ticketId/quality';
import { Route as AuthSidebarDealsTicketsPurchaseTicketIdHistoryImport } from './routes/_auth/_sidebar/deals/tickets/purchase/$ticketId/history';
import { Route as AuthSidebarDealsTicketsPurchaseTicketIdDocumentsImport } from './routes/_auth/_sidebar/deals/tickets/purchase/$ticketId/documents';
import { Route as AuthSidebarDealsTicketsPurchaseTicketIdCostsImport } from './routes/_auth/_sidebar/deals/tickets/purchase/$ticketId/costs';
import { Route as AuthSidebarDealsTicketsPurchaseTicketIdApprovalsImport } from './routes/_auth/_sidebar/deals/tickets/purchase/$ticketId/approvals';
import { Route as AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsImport } from './routes/_auth/_sidebar/deals/tickets/purchase/$ticketId/additional-agreements';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdTasksImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/tasks';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/sale-export-contract-allocations';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdQualityImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/quality';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdHistoryImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/history';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/fixing-and-hedging';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdFinanceImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/finance';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdExecutionImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/execution';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdDocumentsImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/documents';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdCostsImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/costs';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdClosingContractImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/closing-contract';
import { Route as AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsImport } from './routes/_auth/_sidebar/deals/services/general-contract/$contractId/additional-agreements';
import { Route as AuthSidebarDealsServicesDirectContractContractIdTasksImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/tasks';
import { Route as AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/sale-export-contract-allocations';
import { Route as AuthSidebarDealsServicesDirectContractContractIdQualityImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/quality';
import { Route as AuthSidebarDealsServicesDirectContractContractIdHistoryImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/history';
import { Route as AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/fixing-and-hedging';
import { Route as AuthSidebarDealsServicesDirectContractContractIdFinanceImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/finance';
import { Route as AuthSidebarDealsServicesDirectContractContractIdExecutionImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/execution';
import { Route as AuthSidebarDealsServicesDirectContractContractIdDocumentsImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/documents';
import { Route as AuthSidebarDealsServicesDirectContractContractIdCostsImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/costs';
import { Route as AuthSidebarDealsServicesDirectContractContractIdClosingContractImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/closing-contract';
import { Route as AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsImport } from './routes/_auth/_sidebar/deals/services/direct-contract/$contractId/additional-agreements';
import { Route as AuthSidebarDealsPassportsPassportPassportIdTicketsImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/tickets';
import { Route as AuthSidebarDealsPassportsPassportPassportIdTasksImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/tasks';
import { Route as AuthSidebarDealsPassportsPassportPassportIdInvoicesImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/invoices';
import { Route as AuthSidebarDealsPassportsPassportPassportIdFinanceImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/finance';
import { Route as AuthSidebarDealsPassportsPassportPassportIdExecutionImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/execution';
import { Route as AuthSidebarDealsPassportsPassportPassportIdDocumentsImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/documents';
import { Route as AuthSidebarDealsPassportsPassportPassportIdDerivativesImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/derivatives';
import { Route as AuthSidebarDealsPassportsPassportPassportIdDashboardImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/dashboard';
import { Route as AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/cost-of-goods';
import { Route as AuthSidebarDealsPassportsPassportPassportIdContractsImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/contracts';
import { Route as AuthSidebarDealsPassportsPassportPassportIdContactsImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/contacts';
import { Route as AuthSidebarDealsPassportsPassportPassportIdCommentsImport } from './routes/_auth/_sidebar/deals/passports/_passport/$passportId/comments';
import { Route as AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksImport } from './routes/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/tasks';
import { Route as AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityImport } from './routes/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/quality';
import { Route as AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseImport } from './routes/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/potential-purchase';
import { Route as AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsImport } from './routes/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/positions';
import { Route as AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryImport } from './routes/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/history';
import { Route as AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsImport } from './routes/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/documents';
import { Route as AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsImport } from './routes/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/costs-gains';
import { Route as AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsImport } from './routes/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/approvals';
import { Route as AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsImport } from './routes/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/additional-agreements';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/tasks';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/sale-export-contract-allocations';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/quality';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/profit-and-loss';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/print-and-sign';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/positions';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/history';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/fixing-and-hedging';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/finance';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/execution';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/documents';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/dashboard';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/costs-gains';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/closing-contract';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/approvals';
import { Route as AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsImport } from './routes/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/additional-agreements';
import { Route as AuthSidebarDealsMarketOfferOfferIdTelegramImport } from './routes/_auth/_sidebar/deals/market/_offer/$offerId/telegram';
import { Route as AuthSidebarDealsMarketOfferOfferIdTasksImport } from './routes/_auth/_sidebar/deals/market/_offer/$offerId/tasks';
import { Route as AuthSidebarDealsMarketOfferOfferIdQualityImport } from './routes/_auth/_sidebar/deals/market/_offer/$offerId/quality';
import { Route as AuthSidebarDealsMarketOfferOfferIdHistoryImport } from './routes/_auth/_sidebar/deals/market/_offer/$offerId/history';
import { Route as AuthSidebarDealsMarketOfferOfferIdDocumentsImport } from './routes/_auth/_sidebar/deals/market/_offer/$offerId/documents';
import { Route as AuthSidebarDealsMarketOfferOfferIdCostsImport } from './routes/_auth/_sidebar/deals/market/_offer/$offerId/costs';
import { Route as AuthSidebarDealsMarketOfferOfferIdApprovalsImport } from './routes/_auth/_sidebar/deals/market/_offer/$offerId/approvals';
import { Route as AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsImport } from './routes/_auth/_sidebar/deals/market/_offer/$offerId/additional-agreements';
import { Route as AuthSidebarDealsMarketBidBidIdTelegramImport } from './routes/_auth/_sidebar/deals/market/_bid/$bidId/telegram';
import { Route as AuthSidebarDealsMarketBidBidIdTasksImport } from './routes/_auth/_sidebar/deals/market/_bid/$bidId/tasks';
import { Route as AuthSidebarDealsMarketBidBidIdQualityImport } from './routes/_auth/_sidebar/deals/market/_bid/$bidId/quality';
import { Route as AuthSidebarDealsMarketBidBidIdPotentialPurchaseImport } from './routes/_auth/_sidebar/deals/market/_bid/$bidId/potential-purchase';
import { Route as AuthSidebarDealsMarketBidBidIdHistoryImport } from './routes/_auth/_sidebar/deals/market/_bid/$bidId/history';
import { Route as AuthSidebarDealsMarketBidBidIdDocumentsImport } from './routes/_auth/_sidebar/deals/market/_bid/$bidId/documents';
import { Route as AuthSidebarDealsMarketBidBidIdCostsImport } from './routes/_auth/_sidebar/deals/market/_bid/$bidId/costs';
import { Route as AuthSidebarDealsMarketBidBidIdApprovalsImport } from './routes/_auth/_sidebar/deals/market/_bid/$bidId/approvals';
import { Route as AuthSidebarDealsMarketBidBidIdAdditionalAgreementsImport } from './routes/_auth/_sidebar/deals/market/_bid/$bidId/additional-agreements';
import { Route as AuthSidebarDealsAgreementsAgreementAgreementIdTicketsImport } from './routes/_auth/_sidebar/deals/agreements/_agreement/$agreementId/tickets';
import { Route as AuthSidebarDealsAgreementsAgreementAgreementIdHistoryImport } from './routes/_auth/_sidebar/deals/agreements/_agreement/$agreementId/history';
import { Route as AuthSidebarDealsAgreementsAgreementAgreementIdExecutionImport } from './routes/_auth/_sidebar/deals/agreements/_agreement/$agreementId/execution';
import { Route as AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsImport } from './routes/_auth/_sidebar/deals/agreements/_agreement/$agreementId/documents';
import { Route as AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsImport } from './routes/_auth/_sidebar/deals/agreements/_agreement/$agreementId/custom-fields';
import { Route as AuthSidebarDealsAgreementsAgreementAgreementIdContractsImport } from './routes/_auth/_sidebar/deals/agreements/_agreement/$agreementId/contracts';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tickets';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tasks';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/risk';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/reconciliation';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/potentials';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/indicators';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/history';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/finance';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/execution';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/documents';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/derivatives';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/dashboard';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/costs-and-gains';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contracts';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contacts';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/bank-details';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/approvals';
import { Route as AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsImport } from './routes/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/agreements';
import { Route as AuthSidebarCompanyVehiclesVehicleVehicleIdInfoImport } from './routes/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/info';
import { Route as AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryImport } from './routes/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/history';
import { Route as AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsImport } from './routes/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/documents';
import { Route as AuthSidebarCompanyDriversDriverDriverIdInfoImport } from './routes/_auth/_sidebar/company/drivers/_driver/$driverId/info';
import { Route as AuthSidebarCompanyDriversDriverDriverIdHistoryImport } from './routes/_auth/_sidebar/company/drivers/_driver/$driverId/history';
import { Route as AuthSidebarCompanyDriversDriverDriverIdDocumentsImport } from './routes/_auth/_sidebar/company/drivers/_driver/$driverId/documents';

// Create/Update Routes

const ResetPasswordRoute = ResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => rootRoute,
} as any);

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any);

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any);

const R500Route = R500Import.update({
  id: '/500',
  path: '/500',
  getParentRoute: () => rootRoute,
} as any);

const R404Route = R404Import.update({
  id: '/404',
  path: '/404',
  getParentRoute: () => rootRoute,
} as any);

const R403Route = R403Import.update({
  id: '/403',
  path: '/403',
  getParentRoute: () => rootRoute,
} as any);

const R401Route = R401Import.update({
  id: '/401',
  path: '/401',
  getParentRoute: () => rootRoute,
} as any);

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRoute,
} as any);

const AuthSidebarRoute = AuthSidebarImport.update({
  id: '/_sidebar',
  getParentRoute: () => AuthRoute,
} as any);

const AuthSidebarStartRoute = AuthSidebarStartImport.update({
  id: '/start',
  path: '/start',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarReportsDashboardRoute = AuthSidebarReportsDashboardImport.update({
  id: '/reports/dashboard',
  path: '/reports/dashboard',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinanceReasignmentRoute = AuthSidebarFinanceReasignmentImport.update({
  id: '/finance/reasignment',
  path: '/finance/reasignment',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinancePaymentsRoute = AuthSidebarFinancePaymentsImport.update({
  id: '/finance/payments',
  path: '/finance/payments',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinancePaymentPlansRoute = AuthSidebarFinancePaymentPlansImport.update({
  id: '/finance/payment-plans',
  path: '/finance/payment-plans',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinancePaymentDelaysRoute = AuthSidebarFinancePaymentDelaysImport.update({
  id: '/finance/payment-delays',
  path: '/finance/payment-delays',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinanceOffsetRoute = AuthSidebarFinanceOffsetImport.update({
  id: '/finance/offset',
  path: '/finance/offset',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinanceInvoicesRoute = AuthSidebarFinanceInvoicesImport.update({
  id: '/finance/invoices',
  path: '/finance/invoices',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinanceInvoicePositionsRoute = AuthSidebarFinanceInvoicePositionsImport.update({
  id: '/finance/invoice-positions',
  path: '/finance/invoice-positions',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinanceChargesOrGainsRoute = AuthSidebarFinanceChargesOrGainsImport.update({
  id: '/finance/charges-or-gains',
  path: '/finance/charges-or-gains',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinanceBankAccountsRoute = AuthSidebarFinanceBankAccountsImport.update({
  id: '/finance/bank-accounts',
  path: '/finance/bank-accounts',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinanceAnalyticsRoute = AuthSidebarFinanceAnalyticsImport.update({
  id: '/finance/analytics',
  path: '/finance/analytics',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarFinanceAccountingRoute = AuthSidebarFinanceAccountingImport.update({
  id: '/finance/accounting',
  path: '/finance/accounting',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarExecutionWarehouseRoute = AuthSidebarExecutionWarehouseImport.update({
  id: '/execution/warehouse',
  path: '/execution/warehouse',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarExecutionVesselsRoute = AuthSidebarExecutionVesselsImport.update({
  id: '/execution/vessels',
  path: '/execution/vessels',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarExecutionTransportRoute = AuthSidebarExecutionTransportImport.update({
  id: '/execution/transport',
  path: '/execution/transport',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarExecutionTariffsRoute = AuthSidebarExecutionTariffsImport.update({
  id: '/execution/tariffs',
  path: '/execution/tariffs',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarExecutionSamplesRoute = AuthSidebarExecutionSamplesImport.update({
  id: '/execution/samples',
  path: '/execution/samples',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarExecutionPlanningRoute = AuthSidebarExecutionPlanningImport.update({
  id: '/execution/planning',
  path: '/execution/planning',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarExecutionDisbursementBillOfLadingRoute =
  AuthSidebarExecutionDisbursementBillOfLadingImport.update({
    id: '/execution/disbursement-bill-of-lading',
    path: '/execution/disbursement-bill-of-lading',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarExecutionCustomsDeclarationsRoute =
  AuthSidebarExecutionCustomsDeclarationsImport.update({
    id: '/execution/customs-declarations',
    path: '/execution/customs-declarations',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarExecutionConsignmentsRoute = AuthSidebarExecutionConsignmentsImport.update({
  id: '/execution/consignments',
  path: '/execution/consignments',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarExecutionBillsOfLadingRoute = AuthSidebarExecutionBillsOfLadingImport.update({
  id: '/execution/bills-of-lading',
  path: '/execution/bills-of-lading',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarExecutionAnalyticsRoute = AuthSidebarExecutionAnalyticsImport.update({
  id: '/execution/analytics',
  path: '/execution/analytics',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsTicketsRoute = AuthSidebarDealsTicketsImport.update({
  id: '/deals/tickets',
  path: '/deals/tickets',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsServicesRoute = AuthSidebarDealsServicesImport.update({
  id: '/deals/services',
  path: '/deals/services',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsQuotationsRoute = AuthSidebarDealsQuotationsImport.update({
  id: '/deals/quotations',
  path: '/deals/quotations',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsPotentialsRoute = AuthSidebarDealsPotentialsImport.update({
  id: '/deals/potentials',
  path: '/deals/potentials',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsPassportsRoute = AuthSidebarDealsPassportsImport.update({
  id: '/deals/passports',
  path: '/deals/passports',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsMultiticketsRoute = AuthSidebarDealsMultiticketsImport.update({
  id: '/deals/multitickets',
  path: '/deals/multitickets',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsMultiservicesRoute = AuthSidebarDealsMultiservicesImport.update({
  id: '/deals/multiservices',
  path: '/deals/multiservices',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsMulticontractsRoute = AuthSidebarDealsMulticontractsImport.update({
  id: '/deals/multicontracts',
  path: '/deals/multicontracts',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsMarketRoute = AuthSidebarDealsMarketImport.update({
  id: '/deals/market',
  path: '/deals/market',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsContractsRoute = AuthSidebarDealsContractsImport.update({
  id: '/deals/contracts',
  path: '/deals/contracts',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsCalculatorRoute = AuthSidebarDealsCalculatorImport.update({
  id: '/deals/calculator',
  path: '/deals/calculator',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarDealsAgreementsRoute = AuthSidebarDealsAgreementsImport.update({
  id: '/deals/agreements',
  path: '/deals/agreements',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCrmUpdatesRoute = AuthSidebarCrmUpdatesImport.update({
  id: '/crm/updates',
  path: '/crm/updates',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCrmCounterpartiesRoute = AuthSidebarCrmCounterpartiesImport.update({
  id: '/crm/counterparties',
  path: '/crm/counterparties',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCrmContactsRoute = AuthSidebarCrmContactsImport.update({
  id: '/crm/contacts',
  path: '/crm/contacts',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCompanyVehiclesRoute = AuthSidebarCompanyVehiclesImport.update({
  id: '/company/vehicles',
  path: '/company/vehicles',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCompanyTemplatePlaygroundRoute = AuthSidebarCompanyTemplatePlaygroundImport.update(
  {
    id: '/company/template-playground',
    path: '/company/template-playground',
    getParentRoute: () => AuthSidebarRoute,
  } as any,
);

const AuthSidebarCompanyTasksTemplatesRoute = AuthSidebarCompanyTasksTemplatesImport.update({
  id: '/company/tasks-templates',
  path: '/company/tasks-templates',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCompanySettingsRoute = AuthSidebarCompanySettingsImport.update({
  id: '/company/settings',
  path: '/company/settings',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCompanyQualityRuleTemplatesRoute =
  AuthSidebarCompanyQualityRuleTemplatesImport.update({
    id: '/company/quality-rule-templates',
    path: '/company/quality-rule-templates',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyNotificationsRoute = AuthSidebarCompanyNotificationsImport.update({
  id: '/company/notifications',
  path: '/company/notifications',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCompanyDriversRoute = AuthSidebarCompanyDriversImport.update({
  id: '/company/drivers',
  path: '/company/drivers',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCompanyDocumentsRoute = AuthSidebarCompanyDocumentsImport.update({
  id: '/company/documents',
  path: '/company/documents',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCompanyCustomFieldsRoute = AuthSidebarCompanyCustomFieldsImport.update({
  id: '/company/custom-fields',
  path: '/company/custom-fields',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCompanyTeamIndexRoute = AuthSidebarCompanyTeamIndexImport.update({
  id: '/company/team/',
  path: '/company/team/',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarReportsTradingReportsPositionPlAndMtmRoute =
  AuthSidebarReportsTradingReportsPositionPlAndMtmImport.update({
    id: '/reports/trading-reports/position-pl-and-mtm',
    path: '/reports/trading-reports/position-pl-and-mtm',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsTradingReportsPhysicalPositionMtmRoute =
  AuthSidebarReportsTradingReportsPhysicalPositionMtmImport.update({
    id: '/reports/trading-reports/physical-position-mtm',
    path: '/reports/trading-reports/physical-position-mtm',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsTradingReportsPhysicalPositionByMonthsRoute =
  AuthSidebarReportsTradingReportsPhysicalPositionByMonthsImport.update({
    id: '/reports/trading-reports/physical-position-by-months',
    path: '/reports/trading-reports/physical-position-by-months',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsTradingReportsLineUpRoute =
  AuthSidebarReportsTradingReportsLineUpImport.update({
    id: '/reports/trading-reports/line-up',
    path: '/reports/trading-reports/line-up',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsTradingReportsFreightRoute =
  AuthSidebarReportsTradingReportsFreightImport.update({
    id: '/reports/trading-reports/freight',
    path: '/reports/trading-reports/freight',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsTradingReportsFixingOrHedgingRoute =
  AuthSidebarReportsTradingReportsFixingOrHedgingImport.update({
    id: '/reports/trading-reports/fixing-or-hedging',
    path: '/reports/trading-reports/fixing-or-hedging',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsTradingReportsDerivativesRoute =
  AuthSidebarReportsTradingReportsDerivativesImport.update({
    id: '/reports/trading-reports/derivatives',
    path: '/reports/trading-reports/derivatives',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsTradingReportsContractsExecutionRoute =
  AuthSidebarReportsTradingReportsContractsExecutionImport.update({
    id: '/reports/trading-reports/contracts-execution',
    path: '/reports/trading-reports/contracts-execution',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsRiskReportsInvoicesRiskRoute =
  AuthSidebarReportsRiskReportsInvoicesRiskImport.update({
    id: '/reports/risk-reports/invoices-risk',
    path: '/reports/risk-reports/invoices-risk',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsRiskReportsCreditReportRoute =
  AuthSidebarReportsRiskReportsCreditReportImport.update({
    id: '/reports/risk-reports/credit-report',
    path: '/reports/risk-reports/credit-report',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsManagementReportsTeamRoute =
  AuthSidebarReportsManagementReportsTeamImport.update({
    id: '/reports/management-reports/team',
    path: '/reports/management-reports/team',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsManagementReportsSigningsRoute =
  AuthSidebarReportsManagementReportsSigningsImport.update({
    id: '/reports/management-reports/signings',
    path: '/reports/management-reports/signings',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsManagementReportsKpiRoute =
  AuthSidebarReportsManagementReportsKpiImport.update({
    id: '/reports/management-reports/kpi',
    path: '/reports/management-reports/kpi',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsManagementReportsDocumentsDatesRoute =
  AuthSidebarReportsManagementReportsDocumentsDatesImport.update({
    id: '/reports/management-reports/documents-dates',
    path: '/reports/management-reports/documents-dates',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsManagementReportsComplianceRoute =
  AuthSidebarReportsManagementReportsComplianceImport.update({
    id: '/reports/management-reports/compliance',
    path: '/reports/management-reports/compliance',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsManagementReportsApprovalsRoute =
  AuthSidebarReportsManagementReportsApprovalsImport.update({
    id: '/reports/management-reports/approvals',
    path: '/reports/management-reports/approvals',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsManagementReportsActivityRoute =
  AuthSidebarReportsManagementReportsActivityImport.update({
    id: '/reports/management-reports/activity',
    path: '/reports/management-reports/activity',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsFinanceReportsStatisticsRoute =
  AuthSidebarReportsFinanceReportsStatisticsImport.update({
    id: '/reports/finance-reports/statistics',
    path: '/reports/finance-reports/statistics',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsFinanceReportsReconciliationsRoute =
  AuthSidebarReportsFinanceReportsReconciliationsImport.update({
    id: '/reports/finance-reports/reconciliations',
    path: '/reports/finance-reports/reconciliations',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsFinanceReportsPassportsPnlRoute =
  AuthSidebarReportsFinanceReportsPassportsPnlImport.update({
    id: '/reports/finance-reports/passports-pnl',
    path: '/reports/finance-reports/passports-pnl',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsFinanceReportsCostsReportRoute =
  AuthSidebarReportsFinanceReportsCostsReportImport.update({
    id: '/reports/finance-reports/costs-report',
    path: '/reports/finance-reports/costs-report',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsFinanceReportsCashFlowRoute =
  AuthSidebarReportsFinanceReportsCashFlowImport.update({
    id: '/reports/finance-reports/cash-flow',
    path: '/reports/finance-reports/cash-flow',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsFinanceReportsBudgetsRoute =
  AuthSidebarReportsFinanceReportsBudgetsImport.update({
    id: '/reports/finance-reports/budgets',
    path: '/reports/finance-reports/budgets',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsFinanceReportsBalanceRoute =
  AuthSidebarReportsFinanceReportsBalanceImport.update({
    id: '/reports/finance-reports/balance',
    path: '/reports/finance-reports/balance',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarReportsFinanceReportsApArRoute = AuthSidebarReportsFinanceReportsApArImport.update(
  {
    id: '/reports/finance-reports/ap-ar',
    path: '/reports/finance-reports/ap-ar',
    getParentRoute: () => AuthSidebarRoute,
  } as any,
);

const AuthSidebarDealsTicketsSaleTicketsRoute = AuthSidebarDealsTicketsSaleTicketsImport.update({
  id: '/sale-tickets',
  path: '/sale-tickets',
  getParentRoute: () => AuthSidebarDealsTicketsRoute,
} as any);

const AuthSidebarDealsTicketsPurchaseTicketsRoute =
  AuthSidebarDealsTicketsPurchaseTicketsImport.update({
    id: '/purchase-tickets',
    path: '/purchase-tickets',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractsRoute =
  AuthSidebarDealsServicesGeneralContractsImport.update({
    id: '/general-contracts',
    path: '/general-contracts',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractsRoute =
  AuthSidebarDealsServicesDirectContractsImport.update({
    id: '/direct-contracts',
    path: '/direct-contracts',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsPassportsPassportRoute = AuthSidebarDealsPassportsPassportImport.update({
  id: '/_passport',
  getParentRoute: () => AuthSidebarDealsPassportsRoute,
} as any);

const AuthSidebarDealsMultiticketsMultiticketRoute =
  AuthSidebarDealsMultiticketsMultiticketImport.update({
    id: '/_multiticket',
    getParentRoute: () => AuthSidebarDealsMultiticketsRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceRoute =
  AuthSidebarDealsMultiservicesMultiserviceImport.update({
    id: '/_multiservice',
    getParentRoute: () => AuthSidebarDealsMultiservicesRoute,
  } as any);

const AuthSidebarDealsMarketOffersRoute = AuthSidebarDealsMarketOffersImport.update({
  id: '/offers',
  path: '/offers',
  getParentRoute: () => AuthSidebarDealsMarketRoute,
} as any);

const AuthSidebarDealsMarketBidsRoute = AuthSidebarDealsMarketBidsImport.update({
  id: '/bids',
  path: '/bids',
  getParentRoute: () => AuthSidebarDealsMarketRoute,
} as any);

const AuthSidebarDealsMarketOfferRoute = AuthSidebarDealsMarketOfferImport.update({
  id: '/_offer',
  getParentRoute: () => AuthSidebarDealsMarketRoute,
} as any);

const AuthSidebarDealsMarketBidRoute = AuthSidebarDealsMarketBidImport.update({
  id: '/_bid',
  getParentRoute: () => AuthSidebarDealsMarketRoute,
} as any);

const AuthSidebarDealsAgreementsAgreementRoute = AuthSidebarDealsAgreementsAgreementImport.update({
  id: '/_agreement',
  getParentRoute: () => AuthSidebarDealsAgreementsRoute,
} as any);

const AuthSidebarCrmNewslettersTemplatesRoute = AuthSidebarCrmNewslettersTemplatesImport.update({
  id: '/crm/newsletters/templates',
  path: '/crm/newsletters/templates',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCrmNewslettersLogisticsRoute = AuthSidebarCrmNewslettersLogisticsImport.update({
  id: '/crm/newsletters/logistics',
  path: '/crm/newsletters/logistics',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCrmNewslettersInvoicesRoute = AuthSidebarCrmNewslettersInvoicesImport.update({
  id: '/crm/newsletters/invoices',
  path: '/crm/newsletters/invoices',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCrmCounterpartiesCounterpartyRoute =
  AuthSidebarCrmCounterpartiesCounterpartyImport.update({
    id: '/_counterparty',
    getParentRoute: () => AuthSidebarCrmCounterpartiesRoute,
  } as any);

const AuthSidebarCompanySettingsPersonalRoute = AuthSidebarCompanySettingsPersonalImport.update({
  id: '/personal',
  path: '/personal',
  getParentRoute: () => AuthSidebarCompanySettingsRoute,
} as any);

const AuthSidebarCompanySettingsParsingRoute = AuthSidebarCompanySettingsParsingImport.update({
  id: '/parsing',
  path: '/parsing',
  getParentRoute: () => AuthSidebarCompanySettingsRoute,
} as any);

const AuthSidebarCompanySettingsOrganizationRoute =
  AuthSidebarCompanySettingsOrganizationImport.update({
    id: '/organization',
    path: '/organization',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanyDriversDriverRoute = AuthSidebarCompanyDriversDriverImport.update({
  id: '/_driver',
  getParentRoute: () => AuthSidebarCompanyDriversRoute,
} as any);

const AuthSidebarCompanyTeamTraderIdIndexRoute = AuthSidebarCompanyTeamTraderIdIndexImport.update({
  id: '/company/team/$traderId/',
  path: '/company/team/$traderId/',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCompanyTeamTraderIdVoyagesRoute =
  AuthSidebarCompanyTeamTraderIdVoyagesImport.update({
    id: '/company/team/$traderId/voyages',
    path: '/company/team/$traderId/voyages',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdUpdatesRoute =
  AuthSidebarCompanyTeamTraderIdUpdatesImport.update({
    id: '/company/team/$traderId/updates',
    path: '/company/team/$traderId/updates',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdTicketsRoute =
  AuthSidebarCompanyTeamTraderIdTicketsImport.update({
    id: '/company/team/$traderId/tickets',
    path: '/company/team/$traderId/tickets',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdSettingsRoute =
  AuthSidebarCompanyTeamTraderIdSettingsImport.update({
    id: '/company/team/$traderId/settings',
    path: '/company/team/$traderId/settings',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdPassportsRoute =
  AuthSidebarCompanyTeamTraderIdPassportsImport.update({
    id: '/company/team/$traderId/passports',
    path: '/company/team/$traderId/passports',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdLogisticsRoute =
  AuthSidebarCompanyTeamTraderIdLogisticsImport.update({
    id: '/company/team/$traderId/logistics',
    path: '/company/team/$traderId/logistics',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdInfoRoute = AuthSidebarCompanyTeamTraderIdInfoImport.update({
  id: '/company/team/$traderId/info',
  path: '/company/team/$traderId/info',
  getParentRoute: () => AuthSidebarRoute,
} as any);

const AuthSidebarCompanyTeamTraderIdIndicatorsRoute =
  AuthSidebarCompanyTeamTraderIdIndicatorsImport.update({
    id: '/company/team/$traderId/indicators',
    path: '/company/team/$traderId/indicators',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdHistoryRoute =
  AuthSidebarCompanyTeamTraderIdHistoryImport.update({
    id: '/company/team/$traderId/history',
    path: '/company/team/$traderId/history',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdFinancesRoute =
  AuthSidebarCompanyTeamTraderIdFinancesImport.update({
    id: '/company/team/$traderId/finances',
    path: '/company/team/$traderId/finances',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdDocumentsRoute =
  AuthSidebarCompanyTeamTraderIdDocumentsImport.update({
    id: '/company/team/$traderId/documents',
    path: '/company/team/$traderId/documents',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdDerivativesRoute =
  AuthSidebarCompanyTeamTraderIdDerivativesImport.update({
    id: '/company/team/$traderId/derivatives',
    path: '/company/team/$traderId/derivatives',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdContractsRoute =
  AuthSidebarCompanyTeamTraderIdContractsImport.update({
    id: '/company/team/$traderId/contracts',
    path: '/company/team/$traderId/contracts',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdContactsRoute =
  AuthSidebarCompanyTeamTraderIdContactsImport.update({
    id: '/company/team/$traderId/contacts',
    path: '/company/team/$traderId/contacts',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdCompaniesRoute =
  AuthSidebarCompanyTeamTraderIdCompaniesImport.update({
    id: '/company/team/$traderId/companies',
    path: '/company/team/$traderId/companies',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdChargesRoute =
  AuthSidebarCompanyTeamTraderIdChargesImport.update({
    id: '/company/team/$traderId/charges',
    path: '/company/team/$traderId/charges',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanyTeamTraderIdApprovalsRoute =
  AuthSidebarCompanyTeamTraderIdApprovalsImport.update({
    id: '/company/team/$traderId/approvals',
    path: '/company/team/$traderId/approvals',
    getParentRoute: () => AuthSidebarRoute,
  } as any);

const AuthSidebarCompanySettingsListVesselSizesRoute =
  AuthSidebarCompanySettingsListVesselSizesImport.update({
    id: '/_list/vessel-sizes',
    path: '/vessel-sizes',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListVehiclesRoute =
  AuthSidebarCompanySettingsListVehiclesImport.update({
    id: '/_list/vehicles',
    path: '/vehicles',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListVehicleSubtypesRoute =
  AuthSidebarCompanySettingsListVehicleSubtypesImport.update({
    id: '/_list/vehicle-subtypes',
    path: '/vehicle-subtypes',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListUsersRoute = AuthSidebarCompanySettingsListUsersImport.update({
  id: '/_list/users',
  path: '/users',
  getParentRoute: () => AuthSidebarCompanySettingsRoute,
} as any);

const AuthSidebarCompanySettingsListTerminalsRoute =
  AuthSidebarCompanySettingsListTerminalsImport.update({
    id: '/_list/terminals',
    path: '/terminals',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListStockExchangeRoute =
  AuthSidebarCompanySettingsListStockExchangeImport.update({
    id: '/_list/stock-exchange',
    path: '/stock-exchange',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListStationsRoute =
  AuthSidebarCompanySettingsListStationsImport.update({
    id: '/_list/stations',
    path: '/stations',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListResponsibilitiesRoute =
  AuthSidebarCompanySettingsListResponsibilitiesImport.update({
    id: '/_list/responsibilities',
    path: '/responsibilities',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListRegionsRoute =
  AuthSidebarCompanySettingsListRegionsImport.update({
    id: '/_list/regions',
    path: '/regions',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListQualityRoute =
  AuthSidebarCompanySettingsListQualityImport.update({
    id: '/_list/quality',
    path: '/quality',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListPortsRoute = AuthSidebarCompanySettingsListPortsImport.update({
  id: '/_list/ports',
  path: '/ports',
  getParentRoute: () => AuthSidebarCompanySettingsRoute,
} as any);

const AuthSidebarCompanySettingsListPaymentInfoRoute =
  AuthSidebarCompanySettingsListPaymentInfoImport.update({
    id: '/_list/payment-info',
    path: '/payment-info',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListOtherActivitiesRoute =
  AuthSidebarCompanySettingsListOtherActivitiesImport.update({
    id: '/_list/other-activities',
    path: '/other-activities',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListNewsletterRoute =
  AuthSidebarCompanySettingsListNewsletterImport.update({
    id: '/_list/newsletter',
    path: '/newsletter',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListLogisticTypesRoute =
  AuthSidebarCompanySettingsListLogisticTypesImport.update({
    id: '/_list/logistic-types',
    path: '/logistic-types',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListGroupsRoute = AuthSidebarCompanySettingsListGroupsImport.update(
  {
    id: '/_list/groups',
    path: '/groups',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any,
);

const AuthSidebarCompanySettingsListGeneralExpensesRoute =
  AuthSidebarCompanySettingsListGeneralExpensesImport.update({
    id: '/_list/general-expenses',
    path: '/general-expenses',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListFinanceBankRoute =
  AuthSidebarCompanySettingsListFinanceBankImport.update({
    id: '/_list/finance-bank',
    path: '/finance-bank',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListFinanceAccountsRoute =
  AuthSidebarCompanySettingsListFinanceAccountsImport.update({
    id: '/_list/finance-accounts',
    path: '/finance-accounts',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListExchangesRoute =
  AuthSidebarCompanySettingsListExchangesImport.update({
    id: '/_list/exchanges',
    path: '/exchanges',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListDriversRoute =
  AuthSidebarCompanySettingsListDriversImport.update({
    id: '/_list/drivers',
    path: '/drivers',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListDocumentsRoute =
  AuthSidebarCompanySettingsListDocumentsImport.update({
    id: '/_list/documents',
    path: '/documents',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListDistrictsRoute =
  AuthSidebarCompanySettingsListDistrictsImport.update({
    id: '/_list/districts',
    path: '/districts',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListDerivativesRoute =
  AuthSidebarCompanySettingsListDerivativesImport.update({
    id: '/_list/derivatives',
    path: '/derivatives',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListDerivativeAccountsRoute =
  AuthSidebarCompanySettingsListDerivativeAccountsImport.update({
    id: '/_list/derivative-accounts',
    path: '/derivative-accounts',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListDeliveryConditionsRoute =
  AuthSidebarCompanySettingsListDeliveryConditionsImport.update({
    id: '/_list/delivery-conditions',
    path: '/delivery-conditions',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListCurrenciesRoute =
  AuthSidebarCompanySettingsListCurrenciesImport.update({
    id: '/_list/currencies',
    path: '/currencies',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListCountriesRoute =
  AuthSidebarCompanySettingsListCountriesImport.update({
    id: '/_list/countries',
    path: '/countries',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListContractChargesRoute =
  AuthSidebarCompanySettingsListContractChargesImport.update({
    id: '/_list/contract-charges',
    path: '/contract-charges',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListConfigsRoute =
  AuthSidebarCompanySettingsListConfigsImport.update({
    id: '/_list/configs',
    path: '/configs',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListCompanyGroupsRoute =
  AuthSidebarCompanySettingsListCompanyGroupsImport.update({
    id: '/_list/company-groups',
    path: '/company-groups',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListCommoditiesRoute =
  AuthSidebarCompanySettingsListCommoditiesImport.update({
    id: '/_list/commodities',
    path: '/commodities',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListClientChatsRoute =
  AuthSidebarCompanySettingsListClientChatsImport.update({
    id: '/_list/client-chats',
    path: '/client-chats',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarCompanySettingsListCitiesRoute = AuthSidebarCompanySettingsListCitiesImport.update(
  {
    id: '/_list/cities',
    path: '/cities',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any,
);

const AuthSidebarCompanySettingsListChatsRoute = AuthSidebarCompanySettingsListChatsImport.update({
  id: '/_list/chats',
  path: '/chats',
  getParentRoute: () => AuthSidebarCompanySettingsRoute,
} as any);

const AuthSidebarCompanySettingsListBotsRoute = AuthSidebarCompanySettingsListBotsImport.update({
  id: '/_list/bots',
  path: '/bots',
  getParentRoute: () => AuthSidebarCompanySettingsRoute,
} as any);

const AuthSidebarCompanySettingsListAddressesRoute =
  AuthSidebarCompanySettingsListAddressesImport.update({
    id: '/_list/addresses',
    path: '/addresses',
    getParentRoute: () => AuthSidebarCompanySettingsRoute,
  } as any);

const AuthSidebarDealsTicketsSaleTicketIdIndexRoute =
  AuthSidebarDealsTicketsSaleTicketIdIndexImport.update({
    id: '/sale/$ticketId/',
    path: '/sale/$ticketId/',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsPurchaseTicketIdIndexRoute =
  AuthSidebarDealsTicketsPurchaseTicketIdIndexImport.update({
    id: '/purchase/$ticketId/',
    path: '/purchase/$ticketId/',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdIndexRoute =
  AuthSidebarDealsServicesGeneralContractContractIdIndexImport.update({
    id: '/general-contract/$contractId/',
    path: '/general-contract/$contractId/',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdIndexRoute =
  AuthSidebarDealsServicesDirectContractContractIdIndexImport.update({
    id: '/direct-contract/$contractId/',
    path: '/direct-contract/$contractId/',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsTicketsSaleTicketIdTelegramRoute =
  AuthSidebarDealsTicketsSaleTicketIdTelegramImport.update({
    id: '/sale/$ticketId/telegram',
    path: '/sale/$ticketId/telegram',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsSaleTicketIdTasksRoute =
  AuthSidebarDealsTicketsSaleTicketIdTasksImport.update({
    id: '/sale/$ticketId/tasks',
    path: '/sale/$ticketId/tasks',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsSaleTicketIdQualityRoute =
  AuthSidebarDealsTicketsSaleTicketIdQualityImport.update({
    id: '/sale/$ticketId/quality',
    path: '/sale/$ticketId/quality',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseRoute =
  AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseImport.update({
    id: '/sale/$ticketId/potential-purchase',
    path: '/sale/$ticketId/potential-purchase',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsSaleTicketIdHistoryRoute =
  AuthSidebarDealsTicketsSaleTicketIdHistoryImport.update({
    id: '/sale/$ticketId/history',
    path: '/sale/$ticketId/history',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsSaleTicketIdDocumentsRoute =
  AuthSidebarDealsTicketsSaleTicketIdDocumentsImport.update({
    id: '/sale/$ticketId/documents',
    path: '/sale/$ticketId/documents',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsSaleTicketIdCostsRoute =
  AuthSidebarDealsTicketsSaleTicketIdCostsImport.update({
    id: '/sale/$ticketId/costs',
    path: '/sale/$ticketId/costs',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsRoute =
  AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsImport.update({
    id: '/sale/$ticketId/additional-agreements',
    path: '/sale/$ticketId/additional-agreements',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsPurchaseTicketIdTelegramRoute =
  AuthSidebarDealsTicketsPurchaseTicketIdTelegramImport.update({
    id: '/purchase/$ticketId/telegram',
    path: '/purchase/$ticketId/telegram',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsPurchaseTicketIdTasksRoute =
  AuthSidebarDealsTicketsPurchaseTicketIdTasksImport.update({
    id: '/purchase/$ticketId/tasks',
    path: '/purchase/$ticketId/tasks',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsPurchaseTicketIdQualityRoute =
  AuthSidebarDealsTicketsPurchaseTicketIdQualityImport.update({
    id: '/purchase/$ticketId/quality',
    path: '/purchase/$ticketId/quality',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsPurchaseTicketIdHistoryRoute =
  AuthSidebarDealsTicketsPurchaseTicketIdHistoryImport.update({
    id: '/purchase/$ticketId/history',
    path: '/purchase/$ticketId/history',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsPurchaseTicketIdDocumentsRoute =
  AuthSidebarDealsTicketsPurchaseTicketIdDocumentsImport.update({
    id: '/purchase/$ticketId/documents',
    path: '/purchase/$ticketId/documents',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsPurchaseTicketIdCostsRoute =
  AuthSidebarDealsTicketsPurchaseTicketIdCostsImport.update({
    id: '/purchase/$ticketId/costs',
    path: '/purchase/$ticketId/costs',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsPurchaseTicketIdApprovalsRoute =
  AuthSidebarDealsTicketsPurchaseTicketIdApprovalsImport.update({
    id: '/purchase/$ticketId/approvals',
    path: '/purchase/$ticketId/approvals',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsRoute =
  AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsImport.update({
    id: '/purchase/$ticketId/additional-agreements',
    path: '/purchase/$ticketId/additional-agreements',
    getParentRoute: () => AuthSidebarDealsTicketsRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdTasksRoute =
  AuthSidebarDealsServicesGeneralContractContractIdTasksImport.update({
    id: '/general-contract/$contractId/tasks',
    path: '/general-contract/$contractId/tasks',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsRoute =
  AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsImport.update({
    id: '/general-contract/$contractId/sale-export-contract-allocations',
    path: '/general-contract/$contractId/sale-export-contract-allocations',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdQualityRoute =
  AuthSidebarDealsServicesGeneralContractContractIdQualityImport.update({
    id: '/general-contract/$contractId/quality',
    path: '/general-contract/$contractId/quality',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdHistoryRoute =
  AuthSidebarDealsServicesGeneralContractContractIdHistoryImport.update({
    id: '/general-contract/$contractId/history',
    path: '/general-contract/$contractId/history',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingRoute =
  AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingImport.update({
    id: '/general-contract/$contractId/fixing-and-hedging',
    path: '/general-contract/$contractId/fixing-and-hedging',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdFinanceRoute =
  AuthSidebarDealsServicesGeneralContractContractIdFinanceImport.update({
    id: '/general-contract/$contractId/finance',
    path: '/general-contract/$contractId/finance',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdExecutionRoute =
  AuthSidebarDealsServicesGeneralContractContractIdExecutionImport.update({
    id: '/general-contract/$contractId/execution',
    path: '/general-contract/$contractId/execution',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdDocumentsRoute =
  AuthSidebarDealsServicesGeneralContractContractIdDocumentsImport.update({
    id: '/general-contract/$contractId/documents',
    path: '/general-contract/$contractId/documents',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdCostsRoute =
  AuthSidebarDealsServicesGeneralContractContractIdCostsImport.update({
    id: '/general-contract/$contractId/costs',
    path: '/general-contract/$contractId/costs',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdClosingContractRoute =
  AuthSidebarDealsServicesGeneralContractContractIdClosingContractImport.update({
    id: '/general-contract/$contractId/closing-contract',
    path: '/general-contract/$contractId/closing-contract',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsRoute =
  AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsImport.update({
    id: '/general-contract/$contractId/additional-agreements',
    path: '/general-contract/$contractId/additional-agreements',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdTasksRoute =
  AuthSidebarDealsServicesDirectContractContractIdTasksImport.update({
    id: '/direct-contract/$contractId/tasks',
    path: '/direct-contract/$contractId/tasks',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsRoute =
  AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsImport.update({
    id: '/direct-contract/$contractId/sale-export-contract-allocations',
    path: '/direct-contract/$contractId/sale-export-contract-allocations',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdQualityRoute =
  AuthSidebarDealsServicesDirectContractContractIdQualityImport.update({
    id: '/direct-contract/$contractId/quality',
    path: '/direct-contract/$contractId/quality',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdHistoryRoute =
  AuthSidebarDealsServicesDirectContractContractIdHistoryImport.update({
    id: '/direct-contract/$contractId/history',
    path: '/direct-contract/$contractId/history',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingRoute =
  AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingImport.update({
    id: '/direct-contract/$contractId/fixing-and-hedging',
    path: '/direct-contract/$contractId/fixing-and-hedging',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdFinanceRoute =
  AuthSidebarDealsServicesDirectContractContractIdFinanceImport.update({
    id: '/direct-contract/$contractId/finance',
    path: '/direct-contract/$contractId/finance',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdExecutionRoute =
  AuthSidebarDealsServicesDirectContractContractIdExecutionImport.update({
    id: '/direct-contract/$contractId/execution',
    path: '/direct-contract/$contractId/execution',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdDocumentsRoute =
  AuthSidebarDealsServicesDirectContractContractIdDocumentsImport.update({
    id: '/direct-contract/$contractId/documents',
    path: '/direct-contract/$contractId/documents',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdCostsRoute =
  AuthSidebarDealsServicesDirectContractContractIdCostsImport.update({
    id: '/direct-contract/$contractId/costs',
    path: '/direct-contract/$contractId/costs',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdClosingContractRoute =
  AuthSidebarDealsServicesDirectContractContractIdClosingContractImport.update({
    id: '/direct-contract/$contractId/closing-contract',
    path: '/direct-contract/$contractId/closing-contract',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsRoute =
  AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsImport.update({
    id: '/direct-contract/$contractId/additional-agreements',
    path: '/direct-contract/$contractId/additional-agreements',
    getParentRoute: () => AuthSidebarDealsServicesRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdTicketsRoute =
  AuthSidebarDealsPassportsPassportPassportIdTicketsImport.update({
    id: '/$passportId/tickets',
    path: '/$passportId/tickets',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdTasksRoute =
  AuthSidebarDealsPassportsPassportPassportIdTasksImport.update({
    id: '/$passportId/tasks',
    path: '/$passportId/tasks',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdInvoicesRoute =
  AuthSidebarDealsPassportsPassportPassportIdInvoicesImport.update({
    id: '/$passportId/invoices',
    path: '/$passportId/invoices',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdFinanceRoute =
  AuthSidebarDealsPassportsPassportPassportIdFinanceImport.update({
    id: '/$passportId/finance',
    path: '/$passportId/finance',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdExecutionRoute =
  AuthSidebarDealsPassportsPassportPassportIdExecutionImport.update({
    id: '/$passportId/execution',
    path: '/$passportId/execution',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdDocumentsRoute =
  AuthSidebarDealsPassportsPassportPassportIdDocumentsImport.update({
    id: '/$passportId/documents',
    path: '/$passportId/documents',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdDerivativesRoute =
  AuthSidebarDealsPassportsPassportPassportIdDerivativesImport.update({
    id: '/$passportId/derivatives',
    path: '/$passportId/derivatives',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdDashboardRoute =
  AuthSidebarDealsPassportsPassportPassportIdDashboardImport.update({
    id: '/$passportId/dashboard',
    path: '/$passportId/dashboard',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsRoute =
  AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsImport.update({
    id: '/$passportId/cost-of-goods',
    path: '/$passportId/cost-of-goods',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdContractsRoute =
  AuthSidebarDealsPassportsPassportPassportIdContractsImport.update({
    id: '/$passportId/contracts',
    path: '/$passportId/contracts',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdContactsRoute =
  AuthSidebarDealsPassportsPassportPassportIdContactsImport.update({
    id: '/$passportId/contacts',
    path: '/$passportId/contacts',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsPassportsPassportPassportIdCommentsRoute =
  AuthSidebarDealsPassportsPassportPassportIdCommentsImport.update({
    id: '/$passportId/comments',
    path: '/$passportId/comments',
    getParentRoute: () => AuthSidebarDealsPassportsPassportRoute,
  } as any);

const AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksRoute =
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksImport.update({
    id: '/$multiticketId/tasks',
    path: '/$multiticketId/tasks',
    getParentRoute: () => AuthSidebarDealsMultiticketsMultiticketRoute,
  } as any);

const AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityRoute =
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityImport.update({
    id: '/$multiticketId/quality',
    path: '/$multiticketId/quality',
    getParentRoute: () => AuthSidebarDealsMultiticketsMultiticketRoute,
  } as any);

const AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseRoute =
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseImport.update({
    id: '/$multiticketId/potential-purchase',
    path: '/$multiticketId/potential-purchase',
    getParentRoute: () => AuthSidebarDealsMultiticketsMultiticketRoute,
  } as any);

const AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsRoute =
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsImport.update({
    id: '/$multiticketId/positions',
    path: '/$multiticketId/positions',
    getParentRoute: () => AuthSidebarDealsMultiticketsMultiticketRoute,
  } as any);

const AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryRoute =
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryImport.update({
    id: '/$multiticketId/history',
    path: '/$multiticketId/history',
    getParentRoute: () => AuthSidebarDealsMultiticketsMultiticketRoute,
  } as any);

const AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsRoute =
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsImport.update({
    id: '/$multiticketId/documents',
    path: '/$multiticketId/documents',
    getParentRoute: () => AuthSidebarDealsMultiticketsMultiticketRoute,
  } as any);

const AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsRoute =
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsImport.update({
    id: '/$multiticketId/costs-gains',
    path: '/$multiticketId/costs-gains',
    getParentRoute: () => AuthSidebarDealsMultiticketsMultiticketRoute,
  } as any);

const AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsRoute =
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsImport.update({
    id: '/$multiticketId/approvals',
    path: '/$multiticketId/approvals',
    getParentRoute: () => AuthSidebarDealsMultiticketsMultiticketRoute,
  } as any);

const AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsRoute =
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsImport.update({
    id: '/$multiticketId/additional-agreements',
    path: '/$multiticketId/additional-agreements',
    getParentRoute: () => AuthSidebarDealsMultiticketsMultiticketRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksImport.update({
    id: '/$multiserviceId/tasks',
    path: '/$multiserviceId/tasks',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsImport.update(
    {
      id: '/$multiserviceId/sale-export-contract-allocations',
      path: '/$multiserviceId/sale-export-contract-allocations',
      getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
    } as any,
  );

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityImport.update({
    id: '/$multiserviceId/quality',
    path: '/$multiserviceId/quality',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossImport.update({
    id: '/$multiserviceId/profit-and-loss',
    path: '/$multiserviceId/profit-and-loss',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignImport.update({
    id: '/$multiserviceId/print-and-sign',
    path: '/$multiserviceId/print-and-sign',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsImport.update({
    id: '/$multiserviceId/positions',
    path: '/$multiserviceId/positions',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryImport.update({
    id: '/$multiserviceId/history',
    path: '/$multiserviceId/history',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingImport.update({
    id: '/$multiserviceId/fixing-and-hedging',
    path: '/$multiserviceId/fixing-and-hedging',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceImport.update({
    id: '/$multiserviceId/finance',
    path: '/$multiserviceId/finance',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionImport.update({
    id: '/$multiserviceId/execution',
    path: '/$multiserviceId/execution',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsImport.update({
    id: '/$multiserviceId/documents',
    path: '/$multiserviceId/documents',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardImport.update({
    id: '/$multiserviceId/dashboard',
    path: '/$multiserviceId/dashboard',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsImport.update({
    id: '/$multiserviceId/costs-gains',
    path: '/$multiserviceId/costs-gains',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractImport.update({
    id: '/$multiserviceId/closing-contract',
    path: '/$multiserviceId/closing-contract',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsImport.update({
    id: '/$multiserviceId/approvals',
    path: '/$multiserviceId/approvals',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsRoute =
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsImport.update({
    id: '/$multiserviceId/additional-agreements',
    path: '/$multiserviceId/additional-agreements',
    getParentRoute: () => AuthSidebarDealsMultiservicesMultiserviceRoute,
  } as any);

const AuthSidebarDealsMarketOfferOfferIdTelegramRoute =
  AuthSidebarDealsMarketOfferOfferIdTelegramImport.update({
    id: '/$offerId/telegram',
    path: '/$offerId/telegram',
    getParentRoute: () => AuthSidebarDealsMarketOfferRoute,
  } as any);

const AuthSidebarDealsMarketOfferOfferIdTasksRoute =
  AuthSidebarDealsMarketOfferOfferIdTasksImport.update({
    id: '/$offerId/tasks',
    path: '/$offerId/tasks',
    getParentRoute: () => AuthSidebarDealsMarketOfferRoute,
  } as any);

const AuthSidebarDealsMarketOfferOfferIdQualityRoute =
  AuthSidebarDealsMarketOfferOfferIdQualityImport.update({
    id: '/$offerId/quality',
    path: '/$offerId/quality',
    getParentRoute: () => AuthSidebarDealsMarketOfferRoute,
  } as any);

const AuthSidebarDealsMarketOfferOfferIdHistoryRoute =
  AuthSidebarDealsMarketOfferOfferIdHistoryImport.update({
    id: '/$offerId/history',
    path: '/$offerId/history',
    getParentRoute: () => AuthSidebarDealsMarketOfferRoute,
  } as any);

const AuthSidebarDealsMarketOfferOfferIdDocumentsRoute =
  AuthSidebarDealsMarketOfferOfferIdDocumentsImport.update({
    id: '/$offerId/documents',
    path: '/$offerId/documents',
    getParentRoute: () => AuthSidebarDealsMarketOfferRoute,
  } as any);

const AuthSidebarDealsMarketOfferOfferIdCostsRoute =
  AuthSidebarDealsMarketOfferOfferIdCostsImport.update({
    id: '/$offerId/costs',
    path: '/$offerId/costs',
    getParentRoute: () => AuthSidebarDealsMarketOfferRoute,
  } as any);

const AuthSidebarDealsMarketOfferOfferIdApprovalsRoute =
  AuthSidebarDealsMarketOfferOfferIdApprovalsImport.update({
    id: '/$offerId/approvals',
    path: '/$offerId/approvals',
    getParentRoute: () => AuthSidebarDealsMarketOfferRoute,
  } as any);

const AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsRoute =
  AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsImport.update({
    id: '/$offerId/additional-agreements',
    path: '/$offerId/additional-agreements',
    getParentRoute: () => AuthSidebarDealsMarketOfferRoute,
  } as any);

const AuthSidebarDealsMarketBidBidIdTelegramRoute =
  AuthSidebarDealsMarketBidBidIdTelegramImport.update({
    id: '/$bidId/telegram',
    path: '/$bidId/telegram',
    getParentRoute: () => AuthSidebarDealsMarketBidRoute,
  } as any);

const AuthSidebarDealsMarketBidBidIdTasksRoute = AuthSidebarDealsMarketBidBidIdTasksImport.update({
  id: '/$bidId/tasks',
  path: '/$bidId/tasks',
  getParentRoute: () => AuthSidebarDealsMarketBidRoute,
} as any);

const AuthSidebarDealsMarketBidBidIdQualityRoute =
  AuthSidebarDealsMarketBidBidIdQualityImport.update({
    id: '/$bidId/quality',
    path: '/$bidId/quality',
    getParentRoute: () => AuthSidebarDealsMarketBidRoute,
  } as any);

const AuthSidebarDealsMarketBidBidIdPotentialPurchaseRoute =
  AuthSidebarDealsMarketBidBidIdPotentialPurchaseImport.update({
    id: '/$bidId/potential-purchase',
    path: '/$bidId/potential-purchase',
    getParentRoute: () => AuthSidebarDealsMarketBidRoute,
  } as any);

const AuthSidebarDealsMarketBidBidIdHistoryRoute =
  AuthSidebarDealsMarketBidBidIdHistoryImport.update({
    id: '/$bidId/history',
    path: '/$bidId/history',
    getParentRoute: () => AuthSidebarDealsMarketBidRoute,
  } as any);

const AuthSidebarDealsMarketBidBidIdDocumentsRoute =
  AuthSidebarDealsMarketBidBidIdDocumentsImport.update({
    id: '/$bidId/documents',
    path: '/$bidId/documents',
    getParentRoute: () => AuthSidebarDealsMarketBidRoute,
  } as any);

const AuthSidebarDealsMarketBidBidIdCostsRoute = AuthSidebarDealsMarketBidBidIdCostsImport.update({
  id: '/$bidId/costs',
  path: '/$bidId/costs',
  getParentRoute: () => AuthSidebarDealsMarketBidRoute,
} as any);

const AuthSidebarDealsMarketBidBidIdApprovalsRoute =
  AuthSidebarDealsMarketBidBidIdApprovalsImport.update({
    id: '/$bidId/approvals',
    path: '/$bidId/approvals',
    getParentRoute: () => AuthSidebarDealsMarketBidRoute,
  } as any);

const AuthSidebarDealsMarketBidBidIdAdditionalAgreementsRoute =
  AuthSidebarDealsMarketBidBidIdAdditionalAgreementsImport.update({
    id: '/$bidId/additional-agreements',
    path: '/$bidId/additional-agreements',
    getParentRoute: () => AuthSidebarDealsMarketBidRoute,
  } as any);

const AuthSidebarDealsAgreementsAgreementAgreementIdTicketsRoute =
  AuthSidebarDealsAgreementsAgreementAgreementIdTicketsImport.update({
    id: '/$agreementId/tickets',
    path: '/$agreementId/tickets',
    getParentRoute: () => AuthSidebarDealsAgreementsAgreementRoute,
  } as any);

const AuthSidebarDealsAgreementsAgreementAgreementIdHistoryRoute =
  AuthSidebarDealsAgreementsAgreementAgreementIdHistoryImport.update({
    id: '/$agreementId/history',
    path: '/$agreementId/history',
    getParentRoute: () => AuthSidebarDealsAgreementsAgreementRoute,
  } as any);

const AuthSidebarDealsAgreementsAgreementAgreementIdExecutionRoute =
  AuthSidebarDealsAgreementsAgreementAgreementIdExecutionImport.update({
    id: '/$agreementId/execution',
    path: '/$agreementId/execution',
    getParentRoute: () => AuthSidebarDealsAgreementsAgreementRoute,
  } as any);

const AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsRoute =
  AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsImport.update({
    id: '/$agreementId/documents',
    path: '/$agreementId/documents',
    getParentRoute: () => AuthSidebarDealsAgreementsAgreementRoute,
  } as any);

const AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsRoute =
  AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsImport.update({
    id: '/$agreementId/custom-fields',
    path: '/$agreementId/custom-fields',
    getParentRoute: () => AuthSidebarDealsAgreementsAgreementRoute,
  } as any);

const AuthSidebarDealsAgreementsAgreementAgreementIdContractsRoute =
  AuthSidebarDealsAgreementsAgreementAgreementIdContractsImport.update({
    id: '/$agreementId/contracts',
    path: '/$agreementId/contracts',
    getParentRoute: () => AuthSidebarDealsAgreementsAgreementRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsImport.update({
    id: '/$counterpartyId/tickets',
    path: '/$counterpartyId/tickets',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksImport.update({
    id: '/$counterpartyId/tasks',
    path: '/$counterpartyId/tasks',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskImport.update({
    id: '/$counterpartyId/risk',
    path: '/$counterpartyId/risk',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationImport.update({
    id: '/$counterpartyId/reconciliation',
    path: '/$counterpartyId/reconciliation',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsImport.update({
    id: '/$counterpartyId/potentials',
    path: '/$counterpartyId/potentials',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsImport.update({
    id: '/$counterpartyId/indicators',
    path: '/$counterpartyId/indicators',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryImport.update({
    id: '/$counterpartyId/history',
    path: '/$counterpartyId/history',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceImport.update({
    id: '/$counterpartyId/finance',
    path: '/$counterpartyId/finance',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionImport.update({
    id: '/$counterpartyId/execution',
    path: '/$counterpartyId/execution',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsImport.update({
    id: '/$counterpartyId/documents',
    path: '/$counterpartyId/documents',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesImport.update({
    id: '/$counterpartyId/derivatives',
    path: '/$counterpartyId/derivatives',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardImport.update({
    id: '/$counterpartyId/dashboard',
    path: '/$counterpartyId/dashboard',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsImport.update({
    id: '/$counterpartyId/costs-and-gains',
    path: '/$counterpartyId/costs-and-gains',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsImport.update({
    id: '/$counterpartyId/contracts',
    path: '/$counterpartyId/contracts',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsImport.update({
    id: '/$counterpartyId/contacts',
    path: '/$counterpartyId/contacts',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsImport.update({
    id: '/$counterpartyId/bank-details',
    path: '/$counterpartyId/bank-details',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsImport.update({
    id: '/$counterpartyId/approvals',
    path: '/$counterpartyId/approvals',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsRoute =
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsImport.update({
    id: '/$counterpartyId/agreements',
    path: '/$counterpartyId/agreements',
    getParentRoute: () => AuthSidebarCrmCounterpartiesCounterpartyRoute,
  } as any);

const AuthSidebarCompanyVehiclesVehicleVehicleIdInfoRoute =
  AuthSidebarCompanyVehiclesVehicleVehicleIdInfoImport.update({
    id: '/_vehicle/$vehicleId/info',
    path: '/$vehicleId/info',
    getParentRoute: () => AuthSidebarCompanyVehiclesRoute,
  } as any);

const AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryRoute =
  AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryImport.update({
    id: '/_vehicle/$vehicleId/history',
    path: '/$vehicleId/history',
    getParentRoute: () => AuthSidebarCompanyVehiclesRoute,
  } as any);

const AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsRoute =
  AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsImport.update({
    id: '/_vehicle/$vehicleId/documents',
    path: '/$vehicleId/documents',
    getParentRoute: () => AuthSidebarCompanyVehiclesRoute,
  } as any);

const AuthSidebarCompanyDriversDriverDriverIdInfoRoute =
  AuthSidebarCompanyDriversDriverDriverIdInfoImport.update({
    id: '/$driverId/info',
    path: '/$driverId/info',
    getParentRoute: () => AuthSidebarCompanyDriversDriverRoute,
  } as any);

const AuthSidebarCompanyDriversDriverDriverIdHistoryRoute =
  AuthSidebarCompanyDriversDriverDriverIdHistoryImport.update({
    id: '/$driverId/history',
    path: '/$driverId/history',
    getParentRoute: () => AuthSidebarCompanyDriversDriverRoute,
  } as any);

const AuthSidebarCompanyDriversDriverDriverIdDocumentsRoute =
  AuthSidebarCompanyDriversDriverDriverIdDocumentsImport.update({
    id: '/$driverId/documents',
    path: '/$driverId/documents',
    getParentRoute: () => AuthSidebarCompanyDriversDriverRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/401': {
      id: '/401';
      path: '/401';
      fullPath: '/401';
      preLoaderRoute: typeof R401Import;
      parentRoute: typeof rootRoute;
    };
    '/403': {
      id: '/403';
      path: '/403';
      fullPath: '/403';
      preLoaderRoute: typeof R403Import;
      parentRoute: typeof rootRoute;
    };
    '/404': {
      id: '/404';
      path: '/404';
      fullPath: '/404';
      preLoaderRoute: typeof R404Import;
      parentRoute: typeof rootRoute;
    };
    '/500': {
      id: '/500';
      path: '/500';
      fullPath: '/500';
      preLoaderRoute: typeof R500Import;
      parentRoute: typeof rootRoute;
    };
    '/_auth': {
      id: '/_auth';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof AuthImport;
      parentRoute: typeof rootRoute;
    };
    '/login': {
      id: '/login';
      path: '/login';
      fullPath: '/login';
      preLoaderRoute: typeof LoginImport;
      parentRoute: typeof rootRoute;
    };
    '/reset-password': {
      id: '/reset-password';
      path: '/reset-password';
      fullPath: '/reset-password';
      preLoaderRoute: typeof ResetPasswordImport;
      parentRoute: typeof rootRoute;
    };
    '/_auth/_sidebar': {
      id: '/_auth/_sidebar';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof AuthSidebarImport;
      parentRoute: typeof AuthImport;
    };
    '/_auth/': {
      id: '/_auth/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof AuthIndexImport;
      parentRoute: typeof AuthImport;
    };
    '/_auth/_sidebar/start': {
      id: '/_auth/_sidebar/start';
      path: '/start';
      fullPath: '/start';
      preLoaderRoute: typeof AuthSidebarStartImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/custom-fields': {
      id: '/_auth/_sidebar/company/custom-fields';
      path: '/company/custom-fields';
      fullPath: '/company/custom-fields';
      preLoaderRoute: typeof AuthSidebarCompanyCustomFieldsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/documents': {
      id: '/_auth/_sidebar/company/documents';
      path: '/company/documents';
      fullPath: '/company/documents';
      preLoaderRoute: typeof AuthSidebarCompanyDocumentsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/drivers': {
      id: '/_auth/_sidebar/company/drivers';
      path: '/company/drivers';
      fullPath: '/company/drivers';
      preLoaderRoute: typeof AuthSidebarCompanyDriversImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/notifications': {
      id: '/_auth/_sidebar/company/notifications';
      path: '/company/notifications';
      fullPath: '/company/notifications';
      preLoaderRoute: typeof AuthSidebarCompanyNotificationsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/quality-rule-templates': {
      id: '/_auth/_sidebar/company/quality-rule-templates';
      path: '/company/quality-rule-templates';
      fullPath: '/company/quality-rule-templates';
      preLoaderRoute: typeof AuthSidebarCompanyQualityRuleTemplatesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/settings': {
      id: '/_auth/_sidebar/company/settings';
      path: '/company/settings';
      fullPath: '/company/settings';
      preLoaderRoute: typeof AuthSidebarCompanySettingsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/tasks-templates': {
      id: '/_auth/_sidebar/company/tasks-templates';
      path: '/company/tasks-templates';
      fullPath: '/company/tasks-templates';
      preLoaderRoute: typeof AuthSidebarCompanyTasksTemplatesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/template-playground': {
      id: '/_auth/_sidebar/company/template-playground';
      path: '/company/template-playground';
      fullPath: '/company/template-playground';
      preLoaderRoute: typeof AuthSidebarCompanyTemplatePlaygroundImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/vehicles': {
      id: '/_auth/_sidebar/company/vehicles';
      path: '/company/vehicles';
      fullPath: '/company/vehicles';
      preLoaderRoute: typeof AuthSidebarCompanyVehiclesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/crm/contacts': {
      id: '/_auth/_sidebar/crm/contacts';
      path: '/crm/contacts';
      fullPath: '/crm/contacts';
      preLoaderRoute: typeof AuthSidebarCrmContactsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/crm/counterparties': {
      id: '/_auth/_sidebar/crm/counterparties';
      path: '/crm/counterparties';
      fullPath: '/crm/counterparties';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/crm/updates': {
      id: '/_auth/_sidebar/crm/updates';
      path: '/crm/updates';
      fullPath: '/crm/updates';
      preLoaderRoute: typeof AuthSidebarCrmUpdatesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/agreements': {
      id: '/_auth/_sidebar/deals/agreements';
      path: '/deals/agreements';
      fullPath: '/deals/agreements';
      preLoaderRoute: typeof AuthSidebarDealsAgreementsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/calculator': {
      id: '/_auth/_sidebar/deals/calculator';
      path: '/deals/calculator';
      fullPath: '/deals/calculator';
      preLoaderRoute: typeof AuthSidebarDealsCalculatorImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/contracts': {
      id: '/_auth/_sidebar/deals/contracts';
      path: '/deals/contracts';
      fullPath: '/deals/contracts';
      preLoaderRoute: typeof AuthSidebarDealsContractsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/market': {
      id: '/_auth/_sidebar/deals/market';
      path: '/deals/market';
      fullPath: '/deals/market';
      preLoaderRoute: typeof AuthSidebarDealsMarketImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/multicontracts': {
      id: '/_auth/_sidebar/deals/multicontracts';
      path: '/deals/multicontracts';
      fullPath: '/deals/multicontracts';
      preLoaderRoute: typeof AuthSidebarDealsMulticontractsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/multiservices': {
      id: '/_auth/_sidebar/deals/multiservices';
      path: '/deals/multiservices';
      fullPath: '/deals/multiservices';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/multitickets': {
      id: '/_auth/_sidebar/deals/multitickets';
      path: '/deals/multitickets';
      fullPath: '/deals/multitickets';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/passports': {
      id: '/_auth/_sidebar/deals/passports';
      path: '/deals/passports';
      fullPath: '/deals/passports';
      preLoaderRoute: typeof AuthSidebarDealsPassportsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/potentials': {
      id: '/_auth/_sidebar/deals/potentials';
      path: '/deals/potentials';
      fullPath: '/deals/potentials';
      preLoaderRoute: typeof AuthSidebarDealsPotentialsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/quotations': {
      id: '/_auth/_sidebar/deals/quotations';
      path: '/deals/quotations';
      fullPath: '/deals/quotations';
      preLoaderRoute: typeof AuthSidebarDealsQuotationsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/services': {
      id: '/_auth/_sidebar/deals/services';
      path: '/deals/services';
      fullPath: '/deals/services';
      preLoaderRoute: typeof AuthSidebarDealsServicesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/tickets': {
      id: '/_auth/_sidebar/deals/tickets';
      path: '/deals/tickets';
      fullPath: '/deals/tickets';
      preLoaderRoute: typeof AuthSidebarDealsTicketsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/analytics': {
      id: '/_auth/_sidebar/execution/analytics';
      path: '/execution/analytics';
      fullPath: '/execution/analytics';
      preLoaderRoute: typeof AuthSidebarExecutionAnalyticsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/bills-of-lading': {
      id: '/_auth/_sidebar/execution/bills-of-lading';
      path: '/execution/bills-of-lading';
      fullPath: '/execution/bills-of-lading';
      preLoaderRoute: typeof AuthSidebarExecutionBillsOfLadingImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/consignments': {
      id: '/_auth/_sidebar/execution/consignments';
      path: '/execution/consignments';
      fullPath: '/execution/consignments';
      preLoaderRoute: typeof AuthSidebarExecutionConsignmentsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/customs-declarations': {
      id: '/_auth/_sidebar/execution/customs-declarations';
      path: '/execution/customs-declarations';
      fullPath: '/execution/customs-declarations';
      preLoaderRoute: typeof AuthSidebarExecutionCustomsDeclarationsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/disbursement-bill-of-lading': {
      id: '/_auth/_sidebar/execution/disbursement-bill-of-lading';
      path: '/execution/disbursement-bill-of-lading';
      fullPath: '/execution/disbursement-bill-of-lading';
      preLoaderRoute: typeof AuthSidebarExecutionDisbursementBillOfLadingImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/planning': {
      id: '/_auth/_sidebar/execution/planning';
      path: '/execution/planning';
      fullPath: '/execution/planning';
      preLoaderRoute: typeof AuthSidebarExecutionPlanningImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/samples': {
      id: '/_auth/_sidebar/execution/samples';
      path: '/execution/samples';
      fullPath: '/execution/samples';
      preLoaderRoute: typeof AuthSidebarExecutionSamplesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/tariffs': {
      id: '/_auth/_sidebar/execution/tariffs';
      path: '/execution/tariffs';
      fullPath: '/execution/tariffs';
      preLoaderRoute: typeof AuthSidebarExecutionTariffsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/transport': {
      id: '/_auth/_sidebar/execution/transport';
      path: '/execution/transport';
      fullPath: '/execution/transport';
      preLoaderRoute: typeof AuthSidebarExecutionTransportImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/vessels': {
      id: '/_auth/_sidebar/execution/vessels';
      path: '/execution/vessels';
      fullPath: '/execution/vessels';
      preLoaderRoute: typeof AuthSidebarExecutionVesselsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/execution/warehouse': {
      id: '/_auth/_sidebar/execution/warehouse';
      path: '/execution/warehouse';
      fullPath: '/execution/warehouse';
      preLoaderRoute: typeof AuthSidebarExecutionWarehouseImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/accounting': {
      id: '/_auth/_sidebar/finance/accounting';
      path: '/finance/accounting';
      fullPath: '/finance/accounting';
      preLoaderRoute: typeof AuthSidebarFinanceAccountingImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/analytics': {
      id: '/_auth/_sidebar/finance/analytics';
      path: '/finance/analytics';
      fullPath: '/finance/analytics';
      preLoaderRoute: typeof AuthSidebarFinanceAnalyticsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/bank-accounts': {
      id: '/_auth/_sidebar/finance/bank-accounts';
      path: '/finance/bank-accounts';
      fullPath: '/finance/bank-accounts';
      preLoaderRoute: typeof AuthSidebarFinanceBankAccountsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/charges-or-gains': {
      id: '/_auth/_sidebar/finance/charges-or-gains';
      path: '/finance/charges-or-gains';
      fullPath: '/finance/charges-or-gains';
      preLoaderRoute: typeof AuthSidebarFinanceChargesOrGainsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/invoice-positions': {
      id: '/_auth/_sidebar/finance/invoice-positions';
      path: '/finance/invoice-positions';
      fullPath: '/finance/invoice-positions';
      preLoaderRoute: typeof AuthSidebarFinanceInvoicePositionsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/invoices': {
      id: '/_auth/_sidebar/finance/invoices';
      path: '/finance/invoices';
      fullPath: '/finance/invoices';
      preLoaderRoute: typeof AuthSidebarFinanceInvoicesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/offset': {
      id: '/_auth/_sidebar/finance/offset';
      path: '/finance/offset';
      fullPath: '/finance/offset';
      preLoaderRoute: typeof AuthSidebarFinanceOffsetImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/payment-delays': {
      id: '/_auth/_sidebar/finance/payment-delays';
      path: '/finance/payment-delays';
      fullPath: '/finance/payment-delays';
      preLoaderRoute: typeof AuthSidebarFinancePaymentDelaysImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/payment-plans': {
      id: '/_auth/_sidebar/finance/payment-plans';
      path: '/finance/payment-plans';
      fullPath: '/finance/payment-plans';
      preLoaderRoute: typeof AuthSidebarFinancePaymentPlansImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/payments': {
      id: '/_auth/_sidebar/finance/payments';
      path: '/finance/payments';
      fullPath: '/finance/payments';
      preLoaderRoute: typeof AuthSidebarFinancePaymentsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/finance/reasignment': {
      id: '/_auth/_sidebar/finance/reasignment';
      path: '/finance/reasignment';
      fullPath: '/finance/reasignment';
      preLoaderRoute: typeof AuthSidebarFinanceReasignmentImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/dashboard': {
      id: '/_auth/_sidebar/reports/dashboard';
      path: '/reports/dashboard';
      fullPath: '/reports/dashboard';
      preLoaderRoute: typeof AuthSidebarReportsDashboardImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/drivers/_driver': {
      id: '/_auth/_sidebar/company/drivers/_driver';
      path: '';
      fullPath: '/company/drivers';
      preLoaderRoute: typeof AuthSidebarCompanyDriversDriverImport;
      parentRoute: typeof AuthSidebarCompanyDriversImport;
    };
    '/_auth/_sidebar/company/settings/organization': {
      id: '/_auth/_sidebar/company/settings/organization';
      path: '/organization';
      fullPath: '/company/settings/organization';
      preLoaderRoute: typeof AuthSidebarCompanySettingsOrganizationImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/parsing': {
      id: '/_auth/_sidebar/company/settings/parsing';
      path: '/parsing';
      fullPath: '/company/settings/parsing';
      preLoaderRoute: typeof AuthSidebarCompanySettingsParsingImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/personal': {
      id: '/_auth/_sidebar/company/settings/personal';
      path: '/personal';
      fullPath: '/company/settings/personal';
      preLoaderRoute: typeof AuthSidebarCompanySettingsPersonalImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty';
      path: '';
      fullPath: '/crm/counterparties';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesImport;
    };
    '/_auth/_sidebar/crm/newsletters/invoices': {
      id: '/_auth/_sidebar/crm/newsletters/invoices';
      path: '/crm/newsletters/invoices';
      fullPath: '/crm/newsletters/invoices';
      preLoaderRoute: typeof AuthSidebarCrmNewslettersInvoicesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/crm/newsletters/logistics': {
      id: '/_auth/_sidebar/crm/newsletters/logistics';
      path: '/crm/newsletters/logistics';
      fullPath: '/crm/newsletters/logistics';
      preLoaderRoute: typeof AuthSidebarCrmNewslettersLogisticsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/crm/newsletters/templates': {
      id: '/_auth/_sidebar/crm/newsletters/templates';
      path: '/crm/newsletters/templates';
      fullPath: '/crm/newsletters/templates';
      preLoaderRoute: typeof AuthSidebarCrmNewslettersTemplatesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/deals/agreements/_agreement': {
      id: '/_auth/_sidebar/deals/agreements/_agreement';
      path: '';
      fullPath: '/deals/agreements';
      preLoaderRoute: typeof AuthSidebarDealsAgreementsAgreementImport;
      parentRoute: typeof AuthSidebarDealsAgreementsImport;
    };
    '/_auth/_sidebar/deals/market/_bid': {
      id: '/_auth/_sidebar/deals/market/_bid';
      path: '';
      fullPath: '/deals/market';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidImport;
      parentRoute: typeof AuthSidebarDealsMarketImport;
    };
    '/_auth/_sidebar/deals/market/_offer': {
      id: '/_auth/_sidebar/deals/market/_offer';
      path: '';
      fullPath: '/deals/market';
      preLoaderRoute: typeof AuthSidebarDealsMarketOfferImport;
      parentRoute: typeof AuthSidebarDealsMarketImport;
    };
    '/_auth/_sidebar/deals/market/bids': {
      id: '/_auth/_sidebar/deals/market/bids';
      path: '/bids';
      fullPath: '/deals/market/bids';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidsImport;
      parentRoute: typeof AuthSidebarDealsMarketImport;
    };
    '/_auth/_sidebar/deals/market/offers': {
      id: '/_auth/_sidebar/deals/market/offers';
      path: '/offers';
      fullPath: '/deals/market/offers';
      preLoaderRoute: typeof AuthSidebarDealsMarketOffersImport;
      parentRoute: typeof AuthSidebarDealsMarketImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice';
      path: '';
      fullPath: '/deals/multiservices';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesImport;
    };
    '/_auth/_sidebar/deals/multitickets/_multiticket': {
      id: '/_auth/_sidebar/deals/multitickets/_multiticket';
      path: '';
      fullPath: '/deals/multitickets';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsMultiticketImport;
      parentRoute: typeof AuthSidebarDealsMultiticketsImport;
    };
    '/_auth/_sidebar/deals/passports/_passport': {
      id: '/_auth/_sidebar/deals/passports/_passport';
      path: '';
      fullPath: '/deals/passports';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportImport;
      parentRoute: typeof AuthSidebarDealsPassportsImport;
    };
    '/_auth/_sidebar/deals/services/direct-contracts': {
      id: '/_auth/_sidebar/deals/services/direct-contracts';
      path: '/direct-contracts';
      fullPath: '/deals/services/direct-contracts';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractsImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contracts': {
      id: '/_auth/_sidebar/deals/services/general-contracts';
      path: '/general-contracts';
      fullPath: '/deals/services/general-contracts';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractsImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/tickets/purchase-tickets': {
      id: '/_auth/_sidebar/deals/tickets/purchase-tickets';
      path: '/purchase-tickets';
      fullPath: '/deals/tickets/purchase-tickets';
      preLoaderRoute: typeof AuthSidebarDealsTicketsPurchaseTicketsImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/sale-tickets': {
      id: '/_auth/_sidebar/deals/tickets/sale-tickets';
      path: '/sale-tickets';
      fullPath: '/deals/tickets/sale-tickets';
      preLoaderRoute: typeof AuthSidebarDealsTicketsSaleTicketsImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/reports/finance-reports/ap-ar': {
      id: '/_auth/_sidebar/reports/finance-reports/ap-ar';
      path: '/reports/finance-reports/ap-ar';
      fullPath: '/reports/finance-reports/ap-ar';
      preLoaderRoute: typeof AuthSidebarReportsFinanceReportsApArImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/finance-reports/balance': {
      id: '/_auth/_sidebar/reports/finance-reports/balance';
      path: '/reports/finance-reports/balance';
      fullPath: '/reports/finance-reports/balance';
      preLoaderRoute: typeof AuthSidebarReportsFinanceReportsBalanceImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/finance-reports/budgets': {
      id: '/_auth/_sidebar/reports/finance-reports/budgets';
      path: '/reports/finance-reports/budgets';
      fullPath: '/reports/finance-reports/budgets';
      preLoaderRoute: typeof AuthSidebarReportsFinanceReportsBudgetsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/finance-reports/cash-flow': {
      id: '/_auth/_sidebar/reports/finance-reports/cash-flow';
      path: '/reports/finance-reports/cash-flow';
      fullPath: '/reports/finance-reports/cash-flow';
      preLoaderRoute: typeof AuthSidebarReportsFinanceReportsCashFlowImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/finance-reports/costs-report': {
      id: '/_auth/_sidebar/reports/finance-reports/costs-report';
      path: '/reports/finance-reports/costs-report';
      fullPath: '/reports/finance-reports/costs-report';
      preLoaderRoute: typeof AuthSidebarReportsFinanceReportsCostsReportImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/finance-reports/passports-pnl': {
      id: '/_auth/_sidebar/reports/finance-reports/passports-pnl';
      path: '/reports/finance-reports/passports-pnl';
      fullPath: '/reports/finance-reports/passports-pnl';
      preLoaderRoute: typeof AuthSidebarReportsFinanceReportsPassportsPnlImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/finance-reports/reconciliations': {
      id: '/_auth/_sidebar/reports/finance-reports/reconciliations';
      path: '/reports/finance-reports/reconciliations';
      fullPath: '/reports/finance-reports/reconciliations';
      preLoaderRoute: typeof AuthSidebarReportsFinanceReportsReconciliationsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/finance-reports/statistics': {
      id: '/_auth/_sidebar/reports/finance-reports/statistics';
      path: '/reports/finance-reports/statistics';
      fullPath: '/reports/finance-reports/statistics';
      preLoaderRoute: typeof AuthSidebarReportsFinanceReportsStatisticsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/management-reports/activity': {
      id: '/_auth/_sidebar/reports/management-reports/activity';
      path: '/reports/management-reports/activity';
      fullPath: '/reports/management-reports/activity';
      preLoaderRoute: typeof AuthSidebarReportsManagementReportsActivityImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/management-reports/approvals': {
      id: '/_auth/_sidebar/reports/management-reports/approvals';
      path: '/reports/management-reports/approvals';
      fullPath: '/reports/management-reports/approvals';
      preLoaderRoute: typeof AuthSidebarReportsManagementReportsApprovalsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/management-reports/compliance': {
      id: '/_auth/_sidebar/reports/management-reports/compliance';
      path: '/reports/management-reports/compliance';
      fullPath: '/reports/management-reports/compliance';
      preLoaderRoute: typeof AuthSidebarReportsManagementReportsComplianceImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/management-reports/documents-dates': {
      id: '/_auth/_sidebar/reports/management-reports/documents-dates';
      path: '/reports/management-reports/documents-dates';
      fullPath: '/reports/management-reports/documents-dates';
      preLoaderRoute: typeof AuthSidebarReportsManagementReportsDocumentsDatesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/management-reports/kpi': {
      id: '/_auth/_sidebar/reports/management-reports/kpi';
      path: '/reports/management-reports/kpi';
      fullPath: '/reports/management-reports/kpi';
      preLoaderRoute: typeof AuthSidebarReportsManagementReportsKpiImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/management-reports/signings': {
      id: '/_auth/_sidebar/reports/management-reports/signings';
      path: '/reports/management-reports/signings';
      fullPath: '/reports/management-reports/signings';
      preLoaderRoute: typeof AuthSidebarReportsManagementReportsSigningsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/management-reports/team': {
      id: '/_auth/_sidebar/reports/management-reports/team';
      path: '/reports/management-reports/team';
      fullPath: '/reports/management-reports/team';
      preLoaderRoute: typeof AuthSidebarReportsManagementReportsTeamImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/risk-reports/credit-report': {
      id: '/_auth/_sidebar/reports/risk-reports/credit-report';
      path: '/reports/risk-reports/credit-report';
      fullPath: '/reports/risk-reports/credit-report';
      preLoaderRoute: typeof AuthSidebarReportsRiskReportsCreditReportImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/risk-reports/invoices-risk': {
      id: '/_auth/_sidebar/reports/risk-reports/invoices-risk';
      path: '/reports/risk-reports/invoices-risk';
      fullPath: '/reports/risk-reports/invoices-risk';
      preLoaderRoute: typeof AuthSidebarReportsRiskReportsInvoicesRiskImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/trading-reports/contracts-execution': {
      id: '/_auth/_sidebar/reports/trading-reports/contracts-execution';
      path: '/reports/trading-reports/contracts-execution';
      fullPath: '/reports/trading-reports/contracts-execution';
      preLoaderRoute: typeof AuthSidebarReportsTradingReportsContractsExecutionImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/trading-reports/derivatives': {
      id: '/_auth/_sidebar/reports/trading-reports/derivatives';
      path: '/reports/trading-reports/derivatives';
      fullPath: '/reports/trading-reports/derivatives';
      preLoaderRoute: typeof AuthSidebarReportsTradingReportsDerivativesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/trading-reports/fixing-or-hedging': {
      id: '/_auth/_sidebar/reports/trading-reports/fixing-or-hedging';
      path: '/reports/trading-reports/fixing-or-hedging';
      fullPath: '/reports/trading-reports/fixing-or-hedging';
      preLoaderRoute: typeof AuthSidebarReportsTradingReportsFixingOrHedgingImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/trading-reports/freight': {
      id: '/_auth/_sidebar/reports/trading-reports/freight';
      path: '/reports/trading-reports/freight';
      fullPath: '/reports/trading-reports/freight';
      preLoaderRoute: typeof AuthSidebarReportsTradingReportsFreightImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/trading-reports/line-up': {
      id: '/_auth/_sidebar/reports/trading-reports/line-up';
      path: '/reports/trading-reports/line-up';
      fullPath: '/reports/trading-reports/line-up';
      preLoaderRoute: typeof AuthSidebarReportsTradingReportsLineUpImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/trading-reports/physical-position-by-months': {
      id: '/_auth/_sidebar/reports/trading-reports/physical-position-by-months';
      path: '/reports/trading-reports/physical-position-by-months';
      fullPath: '/reports/trading-reports/physical-position-by-months';
      preLoaderRoute: typeof AuthSidebarReportsTradingReportsPhysicalPositionByMonthsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/trading-reports/physical-position-mtm': {
      id: '/_auth/_sidebar/reports/trading-reports/physical-position-mtm';
      path: '/reports/trading-reports/physical-position-mtm';
      fullPath: '/reports/trading-reports/physical-position-mtm';
      preLoaderRoute: typeof AuthSidebarReportsTradingReportsPhysicalPositionMtmImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/reports/trading-reports/position-pl-and-mtm': {
      id: '/_auth/_sidebar/reports/trading-reports/position-pl-and-mtm';
      path: '/reports/trading-reports/position-pl-and-mtm';
      fullPath: '/reports/trading-reports/position-pl-and-mtm';
      preLoaderRoute: typeof AuthSidebarReportsTradingReportsPositionPlAndMtmImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/': {
      id: '/_auth/_sidebar/company/team/';
      path: '/company/team';
      fullPath: '/company/team';
      preLoaderRoute: typeof AuthSidebarCompanyTeamIndexImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/settings/_list/addresses': {
      id: '/_auth/_sidebar/company/settings/_list/addresses';
      path: '/addresses';
      fullPath: '/company/settings/addresses';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListAddressesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/bots': {
      id: '/_auth/_sidebar/company/settings/_list/bots';
      path: '/bots';
      fullPath: '/company/settings/bots';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListBotsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/chats': {
      id: '/_auth/_sidebar/company/settings/_list/chats';
      path: '/chats';
      fullPath: '/company/settings/chats';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListChatsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/cities': {
      id: '/_auth/_sidebar/company/settings/_list/cities';
      path: '/cities';
      fullPath: '/company/settings/cities';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListCitiesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/client-chats': {
      id: '/_auth/_sidebar/company/settings/_list/client-chats';
      path: '/client-chats';
      fullPath: '/company/settings/client-chats';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListClientChatsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/commodities': {
      id: '/_auth/_sidebar/company/settings/_list/commodities';
      path: '/commodities';
      fullPath: '/company/settings/commodities';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListCommoditiesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/company-groups': {
      id: '/_auth/_sidebar/company/settings/_list/company-groups';
      path: '/company-groups';
      fullPath: '/company/settings/company-groups';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListCompanyGroupsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/configs': {
      id: '/_auth/_sidebar/company/settings/_list/configs';
      path: '/configs';
      fullPath: '/company/settings/configs';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListConfigsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/contract-charges': {
      id: '/_auth/_sidebar/company/settings/_list/contract-charges';
      path: '/contract-charges';
      fullPath: '/company/settings/contract-charges';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListContractChargesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/countries': {
      id: '/_auth/_sidebar/company/settings/_list/countries';
      path: '/countries';
      fullPath: '/company/settings/countries';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListCountriesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/currencies': {
      id: '/_auth/_sidebar/company/settings/_list/currencies';
      path: '/currencies';
      fullPath: '/company/settings/currencies';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListCurrenciesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/delivery-conditions': {
      id: '/_auth/_sidebar/company/settings/_list/delivery-conditions';
      path: '/delivery-conditions';
      fullPath: '/company/settings/delivery-conditions';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListDeliveryConditionsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/derivative-accounts': {
      id: '/_auth/_sidebar/company/settings/_list/derivative-accounts';
      path: '/derivative-accounts';
      fullPath: '/company/settings/derivative-accounts';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListDerivativeAccountsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/derivatives': {
      id: '/_auth/_sidebar/company/settings/_list/derivatives';
      path: '/derivatives';
      fullPath: '/company/settings/derivatives';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListDerivativesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/districts': {
      id: '/_auth/_sidebar/company/settings/_list/districts';
      path: '/districts';
      fullPath: '/company/settings/districts';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListDistrictsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/documents': {
      id: '/_auth/_sidebar/company/settings/_list/documents';
      path: '/documents';
      fullPath: '/company/settings/documents';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListDocumentsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/drivers': {
      id: '/_auth/_sidebar/company/settings/_list/drivers';
      path: '/drivers';
      fullPath: '/company/settings/drivers';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListDriversImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/exchanges': {
      id: '/_auth/_sidebar/company/settings/_list/exchanges';
      path: '/exchanges';
      fullPath: '/company/settings/exchanges';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListExchangesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/finance-accounts': {
      id: '/_auth/_sidebar/company/settings/_list/finance-accounts';
      path: '/finance-accounts';
      fullPath: '/company/settings/finance-accounts';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListFinanceAccountsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/finance-bank': {
      id: '/_auth/_sidebar/company/settings/_list/finance-bank';
      path: '/finance-bank';
      fullPath: '/company/settings/finance-bank';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListFinanceBankImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/general-expenses': {
      id: '/_auth/_sidebar/company/settings/_list/general-expenses';
      path: '/general-expenses';
      fullPath: '/company/settings/general-expenses';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListGeneralExpensesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/groups': {
      id: '/_auth/_sidebar/company/settings/_list/groups';
      path: '/groups';
      fullPath: '/company/settings/groups';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListGroupsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/logistic-types': {
      id: '/_auth/_sidebar/company/settings/_list/logistic-types';
      path: '/logistic-types';
      fullPath: '/company/settings/logistic-types';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListLogisticTypesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/newsletter': {
      id: '/_auth/_sidebar/company/settings/_list/newsletter';
      path: '/newsletter';
      fullPath: '/company/settings/newsletter';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListNewsletterImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/other-activities': {
      id: '/_auth/_sidebar/company/settings/_list/other-activities';
      path: '/other-activities';
      fullPath: '/company/settings/other-activities';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListOtherActivitiesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/payment-info': {
      id: '/_auth/_sidebar/company/settings/_list/payment-info';
      path: '/payment-info';
      fullPath: '/company/settings/payment-info';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListPaymentInfoImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/ports': {
      id: '/_auth/_sidebar/company/settings/_list/ports';
      path: '/ports';
      fullPath: '/company/settings/ports';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListPortsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/quality': {
      id: '/_auth/_sidebar/company/settings/_list/quality';
      path: '/quality';
      fullPath: '/company/settings/quality';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListQualityImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/regions': {
      id: '/_auth/_sidebar/company/settings/_list/regions';
      path: '/regions';
      fullPath: '/company/settings/regions';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListRegionsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/responsibilities': {
      id: '/_auth/_sidebar/company/settings/_list/responsibilities';
      path: '/responsibilities';
      fullPath: '/company/settings/responsibilities';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListResponsibilitiesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/stations': {
      id: '/_auth/_sidebar/company/settings/_list/stations';
      path: '/stations';
      fullPath: '/company/settings/stations';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListStationsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/stock-exchange': {
      id: '/_auth/_sidebar/company/settings/_list/stock-exchange';
      path: '/stock-exchange';
      fullPath: '/company/settings/stock-exchange';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListStockExchangeImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/terminals': {
      id: '/_auth/_sidebar/company/settings/_list/terminals';
      path: '/terminals';
      fullPath: '/company/settings/terminals';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListTerminalsImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/users': {
      id: '/_auth/_sidebar/company/settings/_list/users';
      path: '/users';
      fullPath: '/company/settings/users';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListUsersImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/vehicle-subtypes': {
      id: '/_auth/_sidebar/company/settings/_list/vehicle-subtypes';
      path: '/vehicle-subtypes';
      fullPath: '/company/settings/vehicle-subtypes';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListVehicleSubtypesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/vehicles': {
      id: '/_auth/_sidebar/company/settings/_list/vehicles';
      path: '/vehicles';
      fullPath: '/company/settings/vehicles';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListVehiclesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/settings/_list/vessel-sizes': {
      id: '/_auth/_sidebar/company/settings/_list/vessel-sizes';
      path: '/vessel-sizes';
      fullPath: '/company/settings/vessel-sizes';
      preLoaderRoute: typeof AuthSidebarCompanySettingsListVesselSizesImport;
      parentRoute: typeof AuthSidebarCompanySettingsImport;
    };
    '/_auth/_sidebar/company/team/$traderId/approvals': {
      id: '/_auth/_sidebar/company/team/$traderId/approvals';
      path: '/company/team/$traderId/approvals';
      fullPath: '/company/team/$traderId/approvals';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdApprovalsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/charges': {
      id: '/_auth/_sidebar/company/team/$traderId/charges';
      path: '/company/team/$traderId/charges';
      fullPath: '/company/team/$traderId/charges';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdChargesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/companies': {
      id: '/_auth/_sidebar/company/team/$traderId/companies';
      path: '/company/team/$traderId/companies';
      fullPath: '/company/team/$traderId/companies';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdCompaniesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/contacts': {
      id: '/_auth/_sidebar/company/team/$traderId/contacts';
      path: '/company/team/$traderId/contacts';
      fullPath: '/company/team/$traderId/contacts';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdContactsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/contracts': {
      id: '/_auth/_sidebar/company/team/$traderId/contracts';
      path: '/company/team/$traderId/contracts';
      fullPath: '/company/team/$traderId/contracts';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdContractsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/derivatives': {
      id: '/_auth/_sidebar/company/team/$traderId/derivatives';
      path: '/company/team/$traderId/derivatives';
      fullPath: '/company/team/$traderId/derivatives';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdDerivativesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/documents': {
      id: '/_auth/_sidebar/company/team/$traderId/documents';
      path: '/company/team/$traderId/documents';
      fullPath: '/company/team/$traderId/documents';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdDocumentsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/finances': {
      id: '/_auth/_sidebar/company/team/$traderId/finances';
      path: '/company/team/$traderId/finances';
      fullPath: '/company/team/$traderId/finances';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdFinancesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/history': {
      id: '/_auth/_sidebar/company/team/$traderId/history';
      path: '/company/team/$traderId/history';
      fullPath: '/company/team/$traderId/history';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdHistoryImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/indicators': {
      id: '/_auth/_sidebar/company/team/$traderId/indicators';
      path: '/company/team/$traderId/indicators';
      fullPath: '/company/team/$traderId/indicators';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdIndicatorsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/info': {
      id: '/_auth/_sidebar/company/team/$traderId/info';
      path: '/company/team/$traderId/info';
      fullPath: '/company/team/$traderId/info';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdInfoImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/logistics': {
      id: '/_auth/_sidebar/company/team/$traderId/logistics';
      path: '/company/team/$traderId/logistics';
      fullPath: '/company/team/$traderId/logistics';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdLogisticsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/passports': {
      id: '/_auth/_sidebar/company/team/$traderId/passports';
      path: '/company/team/$traderId/passports';
      fullPath: '/company/team/$traderId/passports';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdPassportsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/settings': {
      id: '/_auth/_sidebar/company/team/$traderId/settings';
      path: '/company/team/$traderId/settings';
      fullPath: '/company/team/$traderId/settings';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdSettingsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/tickets': {
      id: '/_auth/_sidebar/company/team/$traderId/tickets';
      path: '/company/team/$traderId/tickets';
      fullPath: '/company/team/$traderId/tickets';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdTicketsImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/updates': {
      id: '/_auth/_sidebar/company/team/$traderId/updates';
      path: '/company/team/$traderId/updates';
      fullPath: '/company/team/$traderId/updates';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdUpdatesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/voyages': {
      id: '/_auth/_sidebar/company/team/$traderId/voyages';
      path: '/company/team/$traderId/voyages';
      fullPath: '/company/team/$traderId/voyages';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdVoyagesImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/team/$traderId/': {
      id: '/_auth/_sidebar/company/team/$traderId/';
      path: '/company/team/$traderId';
      fullPath: '/company/team/$traderId';
      preLoaderRoute: typeof AuthSidebarCompanyTeamTraderIdIndexImport;
      parentRoute: typeof AuthSidebarImport;
    };
    '/_auth/_sidebar/company/drivers/_driver/$driverId/documents': {
      id: '/_auth/_sidebar/company/drivers/_driver/$driverId/documents';
      path: '/$driverId/documents';
      fullPath: '/company/drivers/$driverId/documents';
      preLoaderRoute: typeof AuthSidebarCompanyDriversDriverDriverIdDocumentsImport;
      parentRoute: typeof AuthSidebarCompanyDriversDriverImport;
    };
    '/_auth/_sidebar/company/drivers/_driver/$driverId/history': {
      id: '/_auth/_sidebar/company/drivers/_driver/$driverId/history';
      path: '/$driverId/history';
      fullPath: '/company/drivers/$driverId/history';
      preLoaderRoute: typeof AuthSidebarCompanyDriversDriverDriverIdHistoryImport;
      parentRoute: typeof AuthSidebarCompanyDriversDriverImport;
    };
    '/_auth/_sidebar/company/drivers/_driver/$driverId/info': {
      id: '/_auth/_sidebar/company/drivers/_driver/$driverId/info';
      path: '/$driverId/info';
      fullPath: '/company/drivers/$driverId/info';
      preLoaderRoute: typeof AuthSidebarCompanyDriversDriverDriverIdInfoImport;
      parentRoute: typeof AuthSidebarCompanyDriversDriverImport;
    };
    '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/documents': {
      id: '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/documents';
      path: '/$vehicleId/documents';
      fullPath: '/company/vehicles/$vehicleId/documents';
      preLoaderRoute: typeof AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsImport;
      parentRoute: typeof AuthSidebarCompanyVehiclesImport;
    };
    '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/history': {
      id: '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/history';
      path: '/$vehicleId/history';
      fullPath: '/company/vehicles/$vehicleId/history';
      preLoaderRoute: typeof AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryImport;
      parentRoute: typeof AuthSidebarCompanyVehiclesImport;
    };
    '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/info': {
      id: '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/info';
      path: '/$vehicleId/info';
      fullPath: '/company/vehicles/$vehicleId/info';
      preLoaderRoute: typeof AuthSidebarCompanyVehiclesVehicleVehicleIdInfoImport;
      parentRoute: typeof AuthSidebarCompanyVehiclesImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/agreements': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/agreements';
      path: '/$counterpartyId/agreements';
      fullPath: '/crm/counterparties/$counterpartyId/agreements';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/approvals': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/approvals';
      path: '/$counterpartyId/approvals';
      fullPath: '/crm/counterparties/$counterpartyId/approvals';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/bank-details': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/bank-details';
      path: '/$counterpartyId/bank-details';
      fullPath: '/crm/counterparties/$counterpartyId/bank-details';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contacts': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contacts';
      path: '/$counterpartyId/contacts';
      fullPath: '/crm/counterparties/$counterpartyId/contacts';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contracts': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contracts';
      path: '/$counterpartyId/contracts';
      fullPath: '/crm/counterparties/$counterpartyId/contracts';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/costs-and-gains': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/costs-and-gains';
      path: '/$counterpartyId/costs-and-gains';
      fullPath: '/crm/counterparties/$counterpartyId/costs-and-gains';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/dashboard': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/dashboard';
      path: '/$counterpartyId/dashboard';
      fullPath: '/crm/counterparties/$counterpartyId/dashboard';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/derivatives': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/derivatives';
      path: '/$counterpartyId/derivatives';
      fullPath: '/crm/counterparties/$counterpartyId/derivatives';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/documents': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/documents';
      path: '/$counterpartyId/documents';
      fullPath: '/crm/counterparties/$counterpartyId/documents';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/execution': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/execution';
      path: '/$counterpartyId/execution';
      fullPath: '/crm/counterparties/$counterpartyId/execution';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/finance': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/finance';
      path: '/$counterpartyId/finance';
      fullPath: '/crm/counterparties/$counterpartyId/finance';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/history': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/history';
      path: '/$counterpartyId/history';
      fullPath: '/crm/counterparties/$counterpartyId/history';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/indicators': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/indicators';
      path: '/$counterpartyId/indicators';
      fullPath: '/crm/counterparties/$counterpartyId/indicators';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/potentials': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/potentials';
      path: '/$counterpartyId/potentials';
      fullPath: '/crm/counterparties/$counterpartyId/potentials';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/reconciliation': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/reconciliation';
      path: '/$counterpartyId/reconciliation';
      fullPath: '/crm/counterparties/$counterpartyId/reconciliation';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/risk': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/risk';
      path: '/$counterpartyId/risk';
      fullPath: '/crm/counterparties/$counterpartyId/risk';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tasks': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tasks';
      path: '/$counterpartyId/tasks';
      fullPath: '/crm/counterparties/$counterpartyId/tasks';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tickets': {
      id: '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tickets';
      path: '/$counterpartyId/tickets';
      fullPath: '/crm/counterparties/$counterpartyId/tickets';
      preLoaderRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsImport;
      parentRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyImport;
    };
    '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/contracts': {
      id: '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/contracts';
      path: '/$agreementId/contracts';
      fullPath: '/deals/agreements/$agreementId/contracts';
      preLoaderRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdContractsImport;
      parentRoute: typeof AuthSidebarDealsAgreementsAgreementImport;
    };
    '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/custom-fields': {
      id: '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/custom-fields';
      path: '/$agreementId/custom-fields';
      fullPath: '/deals/agreements/$agreementId/custom-fields';
      preLoaderRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsImport;
      parentRoute: typeof AuthSidebarDealsAgreementsAgreementImport;
    };
    '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/documents': {
      id: '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/documents';
      path: '/$agreementId/documents';
      fullPath: '/deals/agreements/$agreementId/documents';
      preLoaderRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsImport;
      parentRoute: typeof AuthSidebarDealsAgreementsAgreementImport;
    };
    '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/execution': {
      id: '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/execution';
      path: '/$agreementId/execution';
      fullPath: '/deals/agreements/$agreementId/execution';
      preLoaderRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdExecutionImport;
      parentRoute: typeof AuthSidebarDealsAgreementsAgreementImport;
    };
    '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/history': {
      id: '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/history';
      path: '/$agreementId/history';
      fullPath: '/deals/agreements/$agreementId/history';
      preLoaderRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdHistoryImport;
      parentRoute: typeof AuthSidebarDealsAgreementsAgreementImport;
    };
    '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/tickets': {
      id: '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/tickets';
      path: '/$agreementId/tickets';
      fullPath: '/deals/agreements/$agreementId/tickets';
      preLoaderRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdTicketsImport;
      parentRoute: typeof AuthSidebarDealsAgreementsAgreementImport;
    };
    '/_auth/_sidebar/deals/market/_bid/$bidId/additional-agreements': {
      id: '/_auth/_sidebar/deals/market/_bid/$bidId/additional-agreements';
      path: '/$bidId/additional-agreements';
      fullPath: '/deals/market/$bidId/additional-agreements';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidBidIdAdditionalAgreementsImport;
      parentRoute: typeof AuthSidebarDealsMarketBidImport;
    };
    '/_auth/_sidebar/deals/market/_bid/$bidId/approvals': {
      id: '/_auth/_sidebar/deals/market/_bid/$bidId/approvals';
      path: '/$bidId/approvals';
      fullPath: '/deals/market/$bidId/approvals';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidBidIdApprovalsImport;
      parentRoute: typeof AuthSidebarDealsMarketBidImport;
    };
    '/_auth/_sidebar/deals/market/_bid/$bidId/costs': {
      id: '/_auth/_sidebar/deals/market/_bid/$bidId/costs';
      path: '/$bidId/costs';
      fullPath: '/deals/market/$bidId/costs';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidBidIdCostsImport;
      parentRoute: typeof AuthSidebarDealsMarketBidImport;
    };
    '/_auth/_sidebar/deals/market/_bid/$bidId/documents': {
      id: '/_auth/_sidebar/deals/market/_bid/$bidId/documents';
      path: '/$bidId/documents';
      fullPath: '/deals/market/$bidId/documents';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidBidIdDocumentsImport;
      parentRoute: typeof AuthSidebarDealsMarketBidImport;
    };
    '/_auth/_sidebar/deals/market/_bid/$bidId/history': {
      id: '/_auth/_sidebar/deals/market/_bid/$bidId/history';
      path: '/$bidId/history';
      fullPath: '/deals/market/$bidId/history';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidBidIdHistoryImport;
      parentRoute: typeof AuthSidebarDealsMarketBidImport;
    };
    '/_auth/_sidebar/deals/market/_bid/$bidId/potential-purchase': {
      id: '/_auth/_sidebar/deals/market/_bid/$bidId/potential-purchase';
      path: '/$bidId/potential-purchase';
      fullPath: '/deals/market/$bidId/potential-purchase';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidBidIdPotentialPurchaseImport;
      parentRoute: typeof AuthSidebarDealsMarketBidImport;
    };
    '/_auth/_sidebar/deals/market/_bid/$bidId/quality': {
      id: '/_auth/_sidebar/deals/market/_bid/$bidId/quality';
      path: '/$bidId/quality';
      fullPath: '/deals/market/$bidId/quality';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidBidIdQualityImport;
      parentRoute: typeof AuthSidebarDealsMarketBidImport;
    };
    '/_auth/_sidebar/deals/market/_bid/$bidId/tasks': {
      id: '/_auth/_sidebar/deals/market/_bid/$bidId/tasks';
      path: '/$bidId/tasks';
      fullPath: '/deals/market/$bidId/tasks';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidBidIdTasksImport;
      parentRoute: typeof AuthSidebarDealsMarketBidImport;
    };
    '/_auth/_sidebar/deals/market/_bid/$bidId/telegram': {
      id: '/_auth/_sidebar/deals/market/_bid/$bidId/telegram';
      path: '/$bidId/telegram';
      fullPath: '/deals/market/$bidId/telegram';
      preLoaderRoute: typeof AuthSidebarDealsMarketBidBidIdTelegramImport;
      parentRoute: typeof AuthSidebarDealsMarketBidImport;
    };
    '/_auth/_sidebar/deals/market/_offer/$offerId/additional-agreements': {
      id: '/_auth/_sidebar/deals/market/_offer/$offerId/additional-agreements';
      path: '/$offerId/additional-agreements';
      fullPath: '/deals/market/$offerId/additional-agreements';
      preLoaderRoute: typeof AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsImport;
      parentRoute: typeof AuthSidebarDealsMarketOfferImport;
    };
    '/_auth/_sidebar/deals/market/_offer/$offerId/approvals': {
      id: '/_auth/_sidebar/deals/market/_offer/$offerId/approvals';
      path: '/$offerId/approvals';
      fullPath: '/deals/market/$offerId/approvals';
      preLoaderRoute: typeof AuthSidebarDealsMarketOfferOfferIdApprovalsImport;
      parentRoute: typeof AuthSidebarDealsMarketOfferImport;
    };
    '/_auth/_sidebar/deals/market/_offer/$offerId/costs': {
      id: '/_auth/_sidebar/deals/market/_offer/$offerId/costs';
      path: '/$offerId/costs';
      fullPath: '/deals/market/$offerId/costs';
      preLoaderRoute: typeof AuthSidebarDealsMarketOfferOfferIdCostsImport;
      parentRoute: typeof AuthSidebarDealsMarketOfferImport;
    };
    '/_auth/_sidebar/deals/market/_offer/$offerId/documents': {
      id: '/_auth/_sidebar/deals/market/_offer/$offerId/documents';
      path: '/$offerId/documents';
      fullPath: '/deals/market/$offerId/documents';
      preLoaderRoute: typeof AuthSidebarDealsMarketOfferOfferIdDocumentsImport;
      parentRoute: typeof AuthSidebarDealsMarketOfferImport;
    };
    '/_auth/_sidebar/deals/market/_offer/$offerId/history': {
      id: '/_auth/_sidebar/deals/market/_offer/$offerId/history';
      path: '/$offerId/history';
      fullPath: '/deals/market/$offerId/history';
      preLoaderRoute: typeof AuthSidebarDealsMarketOfferOfferIdHistoryImport;
      parentRoute: typeof AuthSidebarDealsMarketOfferImport;
    };
    '/_auth/_sidebar/deals/market/_offer/$offerId/quality': {
      id: '/_auth/_sidebar/deals/market/_offer/$offerId/quality';
      path: '/$offerId/quality';
      fullPath: '/deals/market/$offerId/quality';
      preLoaderRoute: typeof AuthSidebarDealsMarketOfferOfferIdQualityImport;
      parentRoute: typeof AuthSidebarDealsMarketOfferImport;
    };
    '/_auth/_sidebar/deals/market/_offer/$offerId/tasks': {
      id: '/_auth/_sidebar/deals/market/_offer/$offerId/tasks';
      path: '/$offerId/tasks';
      fullPath: '/deals/market/$offerId/tasks';
      preLoaderRoute: typeof AuthSidebarDealsMarketOfferOfferIdTasksImport;
      parentRoute: typeof AuthSidebarDealsMarketOfferImport;
    };
    '/_auth/_sidebar/deals/market/_offer/$offerId/telegram': {
      id: '/_auth/_sidebar/deals/market/_offer/$offerId/telegram';
      path: '/$offerId/telegram';
      fullPath: '/deals/market/$offerId/telegram';
      preLoaderRoute: typeof AuthSidebarDealsMarketOfferOfferIdTelegramImport;
      parentRoute: typeof AuthSidebarDealsMarketOfferImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/additional-agreements': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/additional-agreements';
      path: '/$multiserviceId/additional-agreements';
      fullPath: '/deals/multiservices/$multiserviceId/additional-agreements';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/approvals': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/approvals';
      path: '/$multiserviceId/approvals';
      fullPath: '/deals/multiservices/$multiserviceId/approvals';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/closing-contract': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/closing-contract';
      path: '/$multiserviceId/closing-contract';
      fullPath: '/deals/multiservices/$multiserviceId/closing-contract';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/costs-gains': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/costs-gains';
      path: '/$multiserviceId/costs-gains';
      fullPath: '/deals/multiservices/$multiserviceId/costs-gains';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/dashboard': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/dashboard';
      path: '/$multiserviceId/dashboard';
      fullPath: '/deals/multiservices/$multiserviceId/dashboard';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/documents': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/documents';
      path: '/$multiserviceId/documents';
      fullPath: '/deals/multiservices/$multiserviceId/documents';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/execution': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/execution';
      path: '/$multiserviceId/execution';
      fullPath: '/deals/multiservices/$multiserviceId/execution';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/finance': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/finance';
      path: '/$multiserviceId/finance';
      fullPath: '/deals/multiservices/$multiserviceId/finance';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/fixing-and-hedging': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/fixing-and-hedging';
      path: '/$multiserviceId/fixing-and-hedging';
      fullPath: '/deals/multiservices/$multiserviceId/fixing-and-hedging';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/history': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/history';
      path: '/$multiserviceId/history';
      fullPath: '/deals/multiservices/$multiserviceId/history';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/positions': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/positions';
      path: '/$multiserviceId/positions';
      fullPath: '/deals/multiservices/$multiserviceId/positions';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/print-and-sign': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/print-and-sign';
      path: '/$multiserviceId/print-and-sign';
      fullPath: '/deals/multiservices/$multiserviceId/print-and-sign';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/profit-and-loss': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/profit-and-loss';
      path: '/$multiserviceId/profit-and-loss';
      fullPath: '/deals/multiservices/$multiserviceId/profit-and-loss';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/quality': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/quality';
      path: '/$multiserviceId/quality';
      fullPath: '/deals/multiservices/$multiserviceId/quality';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/sale-export-contract-allocations': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/sale-export-contract-allocations';
      path: '/$multiserviceId/sale-export-contract-allocations';
      fullPath: '/deals/multiservices/$multiserviceId/sale-export-contract-allocations';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/tasks': {
      id: '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/tasks';
      path: '/$multiserviceId/tasks';
      fullPath: '/deals/multiservices/$multiserviceId/tasks';
      preLoaderRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksImport;
      parentRoute: typeof AuthSidebarDealsMultiservicesMultiserviceImport;
    };
    '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/additional-agreements': {
      id: '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/additional-agreements';
      path: '/$multiticketId/additional-agreements';
      fullPath: '/deals/multitickets/$multiticketId/additional-agreements';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsImport;
      parentRoute: typeof AuthSidebarDealsMultiticketsMultiticketImport;
    };
    '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/approvals': {
      id: '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/approvals';
      path: '/$multiticketId/approvals';
      fullPath: '/deals/multitickets/$multiticketId/approvals';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsImport;
      parentRoute: typeof AuthSidebarDealsMultiticketsMultiticketImport;
    };
    '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/costs-gains': {
      id: '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/costs-gains';
      path: '/$multiticketId/costs-gains';
      fullPath: '/deals/multitickets/$multiticketId/costs-gains';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsImport;
      parentRoute: typeof AuthSidebarDealsMultiticketsMultiticketImport;
    };
    '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/documents': {
      id: '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/documents';
      path: '/$multiticketId/documents';
      fullPath: '/deals/multitickets/$multiticketId/documents';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsImport;
      parentRoute: typeof AuthSidebarDealsMultiticketsMultiticketImport;
    };
    '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/history': {
      id: '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/history';
      path: '/$multiticketId/history';
      fullPath: '/deals/multitickets/$multiticketId/history';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryImport;
      parentRoute: typeof AuthSidebarDealsMultiticketsMultiticketImport;
    };
    '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/positions': {
      id: '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/positions';
      path: '/$multiticketId/positions';
      fullPath: '/deals/multitickets/$multiticketId/positions';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsImport;
      parentRoute: typeof AuthSidebarDealsMultiticketsMultiticketImport;
    };
    '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/potential-purchase': {
      id: '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/potential-purchase';
      path: '/$multiticketId/potential-purchase';
      fullPath: '/deals/multitickets/$multiticketId/potential-purchase';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseImport;
      parentRoute: typeof AuthSidebarDealsMultiticketsMultiticketImport;
    };
    '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/quality': {
      id: '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/quality';
      path: '/$multiticketId/quality';
      fullPath: '/deals/multitickets/$multiticketId/quality';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityImport;
      parentRoute: typeof AuthSidebarDealsMultiticketsMultiticketImport;
    };
    '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/tasks': {
      id: '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/tasks';
      path: '/$multiticketId/tasks';
      fullPath: '/deals/multitickets/$multiticketId/tasks';
      preLoaderRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksImport;
      parentRoute: typeof AuthSidebarDealsMultiticketsMultiticketImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/comments': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/comments';
      path: '/$passportId/comments';
      fullPath: '/deals/passports/$passportId/comments';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdCommentsImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/contacts': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/contacts';
      path: '/$passportId/contacts';
      fullPath: '/deals/passports/$passportId/contacts';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdContactsImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/contracts': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/contracts';
      path: '/$passportId/contracts';
      fullPath: '/deals/passports/$passportId/contracts';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdContractsImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/cost-of-goods': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/cost-of-goods';
      path: '/$passportId/cost-of-goods';
      fullPath: '/deals/passports/$passportId/cost-of-goods';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/dashboard': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/dashboard';
      path: '/$passportId/dashboard';
      fullPath: '/deals/passports/$passportId/dashboard';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdDashboardImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/derivatives': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/derivatives';
      path: '/$passportId/derivatives';
      fullPath: '/deals/passports/$passportId/derivatives';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdDerivativesImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/documents': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/documents';
      path: '/$passportId/documents';
      fullPath: '/deals/passports/$passportId/documents';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdDocumentsImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/execution': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/execution';
      path: '/$passportId/execution';
      fullPath: '/deals/passports/$passportId/execution';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdExecutionImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/finance': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/finance';
      path: '/$passportId/finance';
      fullPath: '/deals/passports/$passportId/finance';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdFinanceImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/invoices': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/invoices';
      path: '/$passportId/invoices';
      fullPath: '/deals/passports/$passportId/invoices';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdInvoicesImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/tasks': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/tasks';
      path: '/$passportId/tasks';
      fullPath: '/deals/passports/$passportId/tasks';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdTasksImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/passports/_passport/$passportId/tickets': {
      id: '/_auth/_sidebar/deals/passports/_passport/$passportId/tickets';
      path: '/$passportId/tickets';
      fullPath: '/deals/passports/$passportId/tickets';
      preLoaderRoute: typeof AuthSidebarDealsPassportsPassportPassportIdTicketsImport;
      parentRoute: typeof AuthSidebarDealsPassportsPassportImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/additional-agreements': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/additional-agreements';
      path: '/direct-contract/$contractId/additional-agreements';
      fullPath: '/deals/services/direct-contract/$contractId/additional-agreements';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/closing-contract': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/closing-contract';
      path: '/direct-contract/$contractId/closing-contract';
      fullPath: '/deals/services/direct-contract/$contractId/closing-contract';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdClosingContractImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/costs': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/costs';
      path: '/direct-contract/$contractId/costs';
      fullPath: '/deals/services/direct-contract/$contractId/costs';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdCostsImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/documents': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/documents';
      path: '/direct-contract/$contractId/documents';
      fullPath: '/deals/services/direct-contract/$contractId/documents';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdDocumentsImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/execution': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/execution';
      path: '/direct-contract/$contractId/execution';
      fullPath: '/deals/services/direct-contract/$contractId/execution';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdExecutionImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/finance': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/finance';
      path: '/direct-contract/$contractId/finance';
      fullPath: '/deals/services/direct-contract/$contractId/finance';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdFinanceImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/fixing-and-hedging': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/fixing-and-hedging';
      path: '/direct-contract/$contractId/fixing-and-hedging';
      fullPath: '/deals/services/direct-contract/$contractId/fixing-and-hedging';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/history': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/history';
      path: '/direct-contract/$contractId/history';
      fullPath: '/deals/services/direct-contract/$contractId/history';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdHistoryImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/quality': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/quality';
      path: '/direct-contract/$contractId/quality';
      fullPath: '/deals/services/direct-contract/$contractId/quality';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdQualityImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/sale-export-contract-allocations': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/sale-export-contract-allocations';
      path: '/direct-contract/$contractId/sale-export-contract-allocations';
      fullPath: '/deals/services/direct-contract/$contractId/sale-export-contract-allocations';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/tasks': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/tasks';
      path: '/direct-contract/$contractId/tasks';
      fullPath: '/deals/services/direct-contract/$contractId/tasks';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdTasksImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/additional-agreements': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/additional-agreements';
      path: '/general-contract/$contractId/additional-agreements';
      fullPath: '/deals/services/general-contract/$contractId/additional-agreements';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/closing-contract': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/closing-contract';
      path: '/general-contract/$contractId/closing-contract';
      fullPath: '/deals/services/general-contract/$contractId/closing-contract';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdClosingContractImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/costs': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/costs';
      path: '/general-contract/$contractId/costs';
      fullPath: '/deals/services/general-contract/$contractId/costs';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdCostsImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/documents': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/documents';
      path: '/general-contract/$contractId/documents';
      fullPath: '/deals/services/general-contract/$contractId/documents';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdDocumentsImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/execution': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/execution';
      path: '/general-contract/$contractId/execution';
      fullPath: '/deals/services/general-contract/$contractId/execution';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdExecutionImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/finance': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/finance';
      path: '/general-contract/$contractId/finance';
      fullPath: '/deals/services/general-contract/$contractId/finance';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdFinanceImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/fixing-and-hedging': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/fixing-and-hedging';
      path: '/general-contract/$contractId/fixing-and-hedging';
      fullPath: '/deals/services/general-contract/$contractId/fixing-and-hedging';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/history': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/history';
      path: '/general-contract/$contractId/history';
      fullPath: '/deals/services/general-contract/$contractId/history';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdHistoryImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/quality': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/quality';
      path: '/general-contract/$contractId/quality';
      fullPath: '/deals/services/general-contract/$contractId/quality';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdQualityImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/sale-export-contract-allocations': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/sale-export-contract-allocations';
      path: '/general-contract/$contractId/sale-export-contract-allocations';
      fullPath: '/deals/services/general-contract/$contractId/sale-export-contract-allocations';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/tasks': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/tasks';
      path: '/general-contract/$contractId/tasks';
      fullPath: '/deals/services/general-contract/$contractId/tasks';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdTasksImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/tickets/purchase/$ticketId/additional-agreements': {
      id: '/_auth/_sidebar/deals/tickets/purchase/$ticketId/additional-agreements';
      path: '/purchase/$ticketId/additional-agreements';
      fullPath: '/deals/tickets/purchase/$ticketId/additional-agreements';
      preLoaderRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/purchase/$ticketId/approvals': {
      id: '/_auth/_sidebar/deals/tickets/purchase/$ticketId/approvals';
      path: '/purchase/$ticketId/approvals';
      fullPath: '/deals/tickets/purchase/$ticketId/approvals';
      preLoaderRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdApprovalsImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/purchase/$ticketId/costs': {
      id: '/_auth/_sidebar/deals/tickets/purchase/$ticketId/costs';
      path: '/purchase/$ticketId/costs';
      fullPath: '/deals/tickets/purchase/$ticketId/costs';
      preLoaderRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdCostsImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/purchase/$ticketId/documents': {
      id: '/_auth/_sidebar/deals/tickets/purchase/$ticketId/documents';
      path: '/purchase/$ticketId/documents';
      fullPath: '/deals/tickets/purchase/$ticketId/documents';
      preLoaderRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdDocumentsImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/purchase/$ticketId/history': {
      id: '/_auth/_sidebar/deals/tickets/purchase/$ticketId/history';
      path: '/purchase/$ticketId/history';
      fullPath: '/deals/tickets/purchase/$ticketId/history';
      preLoaderRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdHistoryImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/purchase/$ticketId/quality': {
      id: '/_auth/_sidebar/deals/tickets/purchase/$ticketId/quality';
      path: '/purchase/$ticketId/quality';
      fullPath: '/deals/tickets/purchase/$ticketId/quality';
      preLoaderRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdQualityImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/purchase/$ticketId/tasks': {
      id: '/_auth/_sidebar/deals/tickets/purchase/$ticketId/tasks';
      path: '/purchase/$ticketId/tasks';
      fullPath: '/deals/tickets/purchase/$ticketId/tasks';
      preLoaderRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdTasksImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/purchase/$ticketId/telegram': {
      id: '/_auth/_sidebar/deals/tickets/purchase/$ticketId/telegram';
      path: '/purchase/$ticketId/telegram';
      fullPath: '/deals/tickets/purchase/$ticketId/telegram';
      preLoaderRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdTelegramImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/sale/$ticketId/additional-agreements': {
      id: '/_auth/_sidebar/deals/tickets/sale/$ticketId/additional-agreements';
      path: '/sale/$ticketId/additional-agreements';
      fullPath: '/deals/tickets/sale/$ticketId/additional-agreements';
      preLoaderRoute: typeof AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/sale/$ticketId/costs': {
      id: '/_auth/_sidebar/deals/tickets/sale/$ticketId/costs';
      path: '/sale/$ticketId/costs';
      fullPath: '/deals/tickets/sale/$ticketId/costs';
      preLoaderRoute: typeof AuthSidebarDealsTicketsSaleTicketIdCostsImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/sale/$ticketId/documents': {
      id: '/_auth/_sidebar/deals/tickets/sale/$ticketId/documents';
      path: '/sale/$ticketId/documents';
      fullPath: '/deals/tickets/sale/$ticketId/documents';
      preLoaderRoute: typeof AuthSidebarDealsTicketsSaleTicketIdDocumentsImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/sale/$ticketId/history': {
      id: '/_auth/_sidebar/deals/tickets/sale/$ticketId/history';
      path: '/sale/$ticketId/history';
      fullPath: '/deals/tickets/sale/$ticketId/history';
      preLoaderRoute: typeof AuthSidebarDealsTicketsSaleTicketIdHistoryImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/sale/$ticketId/potential-purchase': {
      id: '/_auth/_sidebar/deals/tickets/sale/$ticketId/potential-purchase';
      path: '/sale/$ticketId/potential-purchase';
      fullPath: '/deals/tickets/sale/$ticketId/potential-purchase';
      preLoaderRoute: typeof AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/sale/$ticketId/quality': {
      id: '/_auth/_sidebar/deals/tickets/sale/$ticketId/quality';
      path: '/sale/$ticketId/quality';
      fullPath: '/deals/tickets/sale/$ticketId/quality';
      preLoaderRoute: typeof AuthSidebarDealsTicketsSaleTicketIdQualityImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/sale/$ticketId/tasks': {
      id: '/_auth/_sidebar/deals/tickets/sale/$ticketId/tasks';
      path: '/sale/$ticketId/tasks';
      fullPath: '/deals/tickets/sale/$ticketId/tasks';
      preLoaderRoute: typeof AuthSidebarDealsTicketsSaleTicketIdTasksImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/sale/$ticketId/telegram': {
      id: '/_auth/_sidebar/deals/tickets/sale/$ticketId/telegram';
      path: '/sale/$ticketId/telegram';
      fullPath: '/deals/tickets/sale/$ticketId/telegram';
      preLoaderRoute: typeof AuthSidebarDealsTicketsSaleTicketIdTelegramImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/services/direct-contract/$contractId/': {
      id: '/_auth/_sidebar/deals/services/direct-contract/$contractId/';
      path: '/direct-contract/$contractId';
      fullPath: '/deals/services/direct-contract/$contractId';
      preLoaderRoute: typeof AuthSidebarDealsServicesDirectContractContractIdIndexImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/services/general-contract/$contractId/': {
      id: '/_auth/_sidebar/deals/services/general-contract/$contractId/';
      path: '/general-contract/$contractId';
      fullPath: '/deals/services/general-contract/$contractId';
      preLoaderRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdIndexImport;
      parentRoute: typeof AuthSidebarDealsServicesImport;
    };
    '/_auth/_sidebar/deals/tickets/purchase/$ticketId/': {
      id: '/_auth/_sidebar/deals/tickets/purchase/$ticketId/';
      path: '/purchase/$ticketId';
      fullPath: '/deals/tickets/purchase/$ticketId';
      preLoaderRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdIndexImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
    '/_auth/_sidebar/deals/tickets/sale/$ticketId/': {
      id: '/_auth/_sidebar/deals/tickets/sale/$ticketId/';
      path: '/sale/$ticketId';
      fullPath: '/deals/tickets/sale/$ticketId';
      preLoaderRoute: typeof AuthSidebarDealsTicketsSaleTicketIdIndexImport;
      parentRoute: typeof AuthSidebarDealsTicketsImport;
    };
  }
}

// Create and export the route tree

interface AuthSidebarCompanyDriversDriverRouteChildren {
  AuthSidebarCompanyDriversDriverDriverIdDocumentsRoute: typeof AuthSidebarCompanyDriversDriverDriverIdDocumentsRoute;
  AuthSidebarCompanyDriversDriverDriverIdHistoryRoute: typeof AuthSidebarCompanyDriversDriverDriverIdHistoryRoute;
  AuthSidebarCompanyDriversDriverDriverIdInfoRoute: typeof AuthSidebarCompanyDriversDriverDriverIdInfoRoute;
}

const AuthSidebarCompanyDriversDriverRouteChildren: AuthSidebarCompanyDriversDriverRouteChildren = {
  AuthSidebarCompanyDriversDriverDriverIdDocumentsRoute:
    AuthSidebarCompanyDriversDriverDriverIdDocumentsRoute,
  AuthSidebarCompanyDriversDriverDriverIdHistoryRoute:
    AuthSidebarCompanyDriversDriverDriverIdHistoryRoute,
  AuthSidebarCompanyDriversDriverDriverIdInfoRoute:
    AuthSidebarCompanyDriversDriverDriverIdInfoRoute,
};

const AuthSidebarCompanyDriversDriverRouteWithChildren =
  AuthSidebarCompanyDriversDriverRoute._addFileChildren(
    AuthSidebarCompanyDriversDriverRouteChildren,
  );

interface AuthSidebarCompanyDriversRouteChildren {
  AuthSidebarCompanyDriversDriverRoute: typeof AuthSidebarCompanyDriversDriverRouteWithChildren;
}

const AuthSidebarCompanyDriversRouteChildren: AuthSidebarCompanyDriversRouteChildren = {
  AuthSidebarCompanyDriversDriverRoute: AuthSidebarCompanyDriversDriverRouteWithChildren,
};

const AuthSidebarCompanyDriversRouteWithChildren = AuthSidebarCompanyDriversRoute._addFileChildren(
  AuthSidebarCompanyDriversRouteChildren,
);

interface AuthSidebarCompanySettingsRouteChildren {
  AuthSidebarCompanySettingsOrganizationRoute: typeof AuthSidebarCompanySettingsOrganizationRoute;
  AuthSidebarCompanySettingsParsingRoute: typeof AuthSidebarCompanySettingsParsingRoute;
  AuthSidebarCompanySettingsPersonalRoute: typeof AuthSidebarCompanySettingsPersonalRoute;
  AuthSidebarCompanySettingsListAddressesRoute: typeof AuthSidebarCompanySettingsListAddressesRoute;
  AuthSidebarCompanySettingsListBotsRoute: typeof AuthSidebarCompanySettingsListBotsRoute;
  AuthSidebarCompanySettingsListChatsRoute: typeof AuthSidebarCompanySettingsListChatsRoute;
  AuthSidebarCompanySettingsListCitiesRoute: typeof AuthSidebarCompanySettingsListCitiesRoute;
  AuthSidebarCompanySettingsListClientChatsRoute: typeof AuthSidebarCompanySettingsListClientChatsRoute;
  AuthSidebarCompanySettingsListCommoditiesRoute: typeof AuthSidebarCompanySettingsListCommoditiesRoute;
  AuthSidebarCompanySettingsListCompanyGroupsRoute: typeof AuthSidebarCompanySettingsListCompanyGroupsRoute;
  AuthSidebarCompanySettingsListConfigsRoute: typeof AuthSidebarCompanySettingsListConfigsRoute;
  AuthSidebarCompanySettingsListContractChargesRoute: typeof AuthSidebarCompanySettingsListContractChargesRoute;
  AuthSidebarCompanySettingsListCountriesRoute: typeof AuthSidebarCompanySettingsListCountriesRoute;
  AuthSidebarCompanySettingsListCurrenciesRoute: typeof AuthSidebarCompanySettingsListCurrenciesRoute;
  AuthSidebarCompanySettingsListDeliveryConditionsRoute: typeof AuthSidebarCompanySettingsListDeliveryConditionsRoute;
  AuthSidebarCompanySettingsListDerivativeAccountsRoute: typeof AuthSidebarCompanySettingsListDerivativeAccountsRoute;
  AuthSidebarCompanySettingsListDerivativesRoute: typeof AuthSidebarCompanySettingsListDerivativesRoute;
  AuthSidebarCompanySettingsListDistrictsRoute: typeof AuthSidebarCompanySettingsListDistrictsRoute;
  AuthSidebarCompanySettingsListDocumentsRoute: typeof AuthSidebarCompanySettingsListDocumentsRoute;
  AuthSidebarCompanySettingsListDriversRoute: typeof AuthSidebarCompanySettingsListDriversRoute;
  AuthSidebarCompanySettingsListExchangesRoute: typeof AuthSidebarCompanySettingsListExchangesRoute;
  AuthSidebarCompanySettingsListFinanceAccountsRoute: typeof AuthSidebarCompanySettingsListFinanceAccountsRoute;
  AuthSidebarCompanySettingsListFinanceBankRoute: typeof AuthSidebarCompanySettingsListFinanceBankRoute;
  AuthSidebarCompanySettingsListGeneralExpensesRoute: typeof AuthSidebarCompanySettingsListGeneralExpensesRoute;
  AuthSidebarCompanySettingsListGroupsRoute: typeof AuthSidebarCompanySettingsListGroupsRoute;
  AuthSidebarCompanySettingsListLogisticTypesRoute: typeof AuthSidebarCompanySettingsListLogisticTypesRoute;
  AuthSidebarCompanySettingsListNewsletterRoute: typeof AuthSidebarCompanySettingsListNewsletterRoute;
  AuthSidebarCompanySettingsListOtherActivitiesRoute: typeof AuthSidebarCompanySettingsListOtherActivitiesRoute;
  AuthSidebarCompanySettingsListPaymentInfoRoute: typeof AuthSidebarCompanySettingsListPaymentInfoRoute;
  AuthSidebarCompanySettingsListPortsRoute: typeof AuthSidebarCompanySettingsListPortsRoute;
  AuthSidebarCompanySettingsListQualityRoute: typeof AuthSidebarCompanySettingsListQualityRoute;
  AuthSidebarCompanySettingsListRegionsRoute: typeof AuthSidebarCompanySettingsListRegionsRoute;
  AuthSidebarCompanySettingsListResponsibilitiesRoute: typeof AuthSidebarCompanySettingsListResponsibilitiesRoute;
  AuthSidebarCompanySettingsListStationsRoute: typeof AuthSidebarCompanySettingsListStationsRoute;
  AuthSidebarCompanySettingsListStockExchangeRoute: typeof AuthSidebarCompanySettingsListStockExchangeRoute;
  AuthSidebarCompanySettingsListTerminalsRoute: typeof AuthSidebarCompanySettingsListTerminalsRoute;
  AuthSidebarCompanySettingsListUsersRoute: typeof AuthSidebarCompanySettingsListUsersRoute;
  AuthSidebarCompanySettingsListVehicleSubtypesRoute: typeof AuthSidebarCompanySettingsListVehicleSubtypesRoute;
  AuthSidebarCompanySettingsListVehiclesRoute: typeof AuthSidebarCompanySettingsListVehiclesRoute;
  AuthSidebarCompanySettingsListVesselSizesRoute: typeof AuthSidebarCompanySettingsListVesselSizesRoute;
}

const AuthSidebarCompanySettingsRouteChildren: AuthSidebarCompanySettingsRouteChildren = {
  AuthSidebarCompanySettingsOrganizationRoute: AuthSidebarCompanySettingsOrganizationRoute,
  AuthSidebarCompanySettingsParsingRoute: AuthSidebarCompanySettingsParsingRoute,
  AuthSidebarCompanySettingsPersonalRoute: AuthSidebarCompanySettingsPersonalRoute,
  AuthSidebarCompanySettingsListAddressesRoute: AuthSidebarCompanySettingsListAddressesRoute,
  AuthSidebarCompanySettingsListBotsRoute: AuthSidebarCompanySettingsListBotsRoute,
  AuthSidebarCompanySettingsListChatsRoute: AuthSidebarCompanySettingsListChatsRoute,
  AuthSidebarCompanySettingsListCitiesRoute: AuthSidebarCompanySettingsListCitiesRoute,
  AuthSidebarCompanySettingsListClientChatsRoute: AuthSidebarCompanySettingsListClientChatsRoute,
  AuthSidebarCompanySettingsListCommoditiesRoute: AuthSidebarCompanySettingsListCommoditiesRoute,
  AuthSidebarCompanySettingsListCompanyGroupsRoute:
    AuthSidebarCompanySettingsListCompanyGroupsRoute,
  AuthSidebarCompanySettingsListConfigsRoute: AuthSidebarCompanySettingsListConfigsRoute,
  AuthSidebarCompanySettingsListContractChargesRoute:
    AuthSidebarCompanySettingsListContractChargesRoute,
  AuthSidebarCompanySettingsListCountriesRoute: AuthSidebarCompanySettingsListCountriesRoute,
  AuthSidebarCompanySettingsListCurrenciesRoute: AuthSidebarCompanySettingsListCurrenciesRoute,
  AuthSidebarCompanySettingsListDeliveryConditionsRoute:
    AuthSidebarCompanySettingsListDeliveryConditionsRoute,
  AuthSidebarCompanySettingsListDerivativeAccountsRoute:
    AuthSidebarCompanySettingsListDerivativeAccountsRoute,
  AuthSidebarCompanySettingsListDerivativesRoute: AuthSidebarCompanySettingsListDerivativesRoute,
  AuthSidebarCompanySettingsListDistrictsRoute: AuthSidebarCompanySettingsListDistrictsRoute,
  AuthSidebarCompanySettingsListDocumentsRoute: AuthSidebarCompanySettingsListDocumentsRoute,
  AuthSidebarCompanySettingsListDriversRoute: AuthSidebarCompanySettingsListDriversRoute,
  AuthSidebarCompanySettingsListExchangesRoute: AuthSidebarCompanySettingsListExchangesRoute,
  AuthSidebarCompanySettingsListFinanceAccountsRoute:
    AuthSidebarCompanySettingsListFinanceAccountsRoute,
  AuthSidebarCompanySettingsListFinanceBankRoute: AuthSidebarCompanySettingsListFinanceBankRoute,
  AuthSidebarCompanySettingsListGeneralExpensesRoute:
    AuthSidebarCompanySettingsListGeneralExpensesRoute,
  AuthSidebarCompanySettingsListGroupsRoute: AuthSidebarCompanySettingsListGroupsRoute,
  AuthSidebarCompanySettingsListLogisticTypesRoute:
    AuthSidebarCompanySettingsListLogisticTypesRoute,
  AuthSidebarCompanySettingsListNewsletterRoute: AuthSidebarCompanySettingsListNewsletterRoute,
  AuthSidebarCompanySettingsListOtherActivitiesRoute:
    AuthSidebarCompanySettingsListOtherActivitiesRoute,
  AuthSidebarCompanySettingsListPaymentInfoRoute: AuthSidebarCompanySettingsListPaymentInfoRoute,
  AuthSidebarCompanySettingsListPortsRoute: AuthSidebarCompanySettingsListPortsRoute,
  AuthSidebarCompanySettingsListQualityRoute: AuthSidebarCompanySettingsListQualityRoute,
  AuthSidebarCompanySettingsListRegionsRoute: AuthSidebarCompanySettingsListRegionsRoute,
  AuthSidebarCompanySettingsListResponsibilitiesRoute:
    AuthSidebarCompanySettingsListResponsibilitiesRoute,
  AuthSidebarCompanySettingsListStationsRoute: AuthSidebarCompanySettingsListStationsRoute,
  AuthSidebarCompanySettingsListStockExchangeRoute:
    AuthSidebarCompanySettingsListStockExchangeRoute,
  AuthSidebarCompanySettingsListTerminalsRoute: AuthSidebarCompanySettingsListTerminalsRoute,
  AuthSidebarCompanySettingsListUsersRoute: AuthSidebarCompanySettingsListUsersRoute,
  AuthSidebarCompanySettingsListVehicleSubtypesRoute:
    AuthSidebarCompanySettingsListVehicleSubtypesRoute,
  AuthSidebarCompanySettingsListVehiclesRoute: AuthSidebarCompanySettingsListVehiclesRoute,
  AuthSidebarCompanySettingsListVesselSizesRoute: AuthSidebarCompanySettingsListVesselSizesRoute,
};

const AuthSidebarCompanySettingsRouteWithChildren =
  AuthSidebarCompanySettingsRoute._addFileChildren(AuthSidebarCompanySettingsRouteChildren);

interface AuthSidebarCompanyVehiclesRouteChildren {
  AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsRoute: typeof AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsRoute;
  AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryRoute: typeof AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryRoute;
  AuthSidebarCompanyVehiclesVehicleVehicleIdInfoRoute: typeof AuthSidebarCompanyVehiclesVehicleVehicleIdInfoRoute;
}

const AuthSidebarCompanyVehiclesRouteChildren: AuthSidebarCompanyVehiclesRouteChildren = {
  AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsRoute:
    AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsRoute,
  AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryRoute:
    AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryRoute,
  AuthSidebarCompanyVehiclesVehicleVehicleIdInfoRoute:
    AuthSidebarCompanyVehiclesVehicleVehicleIdInfoRoute,
};

const AuthSidebarCompanyVehiclesRouteWithChildren =
  AuthSidebarCompanyVehiclesRoute._addFileChildren(AuthSidebarCompanyVehiclesRouteChildren);

interface AuthSidebarCrmCounterpartiesCounterpartyRouteChildren {
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksRoute;
  AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsRoute;
}

const AuthSidebarCrmCounterpartiesCounterpartyRouteChildren: AuthSidebarCrmCounterpartiesCounterpartyRouteChildren =
  {
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksRoute,
    AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsRoute:
      AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsRoute,
  };

const AuthSidebarCrmCounterpartiesCounterpartyRouteWithChildren =
  AuthSidebarCrmCounterpartiesCounterpartyRoute._addFileChildren(
    AuthSidebarCrmCounterpartiesCounterpartyRouteChildren,
  );

interface AuthSidebarCrmCounterpartiesRouteChildren {
  AuthSidebarCrmCounterpartiesCounterpartyRoute: typeof AuthSidebarCrmCounterpartiesCounterpartyRouteWithChildren;
}

const AuthSidebarCrmCounterpartiesRouteChildren: AuthSidebarCrmCounterpartiesRouteChildren = {
  AuthSidebarCrmCounterpartiesCounterpartyRoute:
    AuthSidebarCrmCounterpartiesCounterpartyRouteWithChildren,
};

const AuthSidebarCrmCounterpartiesRouteWithChildren =
  AuthSidebarCrmCounterpartiesRoute._addFileChildren(AuthSidebarCrmCounterpartiesRouteChildren);

interface AuthSidebarDealsAgreementsAgreementRouteChildren {
  AuthSidebarDealsAgreementsAgreementAgreementIdContractsRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdContractsRoute;
  AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsRoute;
  AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsRoute;
  AuthSidebarDealsAgreementsAgreementAgreementIdExecutionRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdExecutionRoute;
  AuthSidebarDealsAgreementsAgreementAgreementIdHistoryRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdHistoryRoute;
  AuthSidebarDealsAgreementsAgreementAgreementIdTicketsRoute: typeof AuthSidebarDealsAgreementsAgreementAgreementIdTicketsRoute;
}

const AuthSidebarDealsAgreementsAgreementRouteChildren: AuthSidebarDealsAgreementsAgreementRouteChildren =
  {
    AuthSidebarDealsAgreementsAgreementAgreementIdContractsRoute:
      AuthSidebarDealsAgreementsAgreementAgreementIdContractsRoute,
    AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsRoute:
      AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsRoute,
    AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsRoute:
      AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsRoute,
    AuthSidebarDealsAgreementsAgreementAgreementIdExecutionRoute:
      AuthSidebarDealsAgreementsAgreementAgreementIdExecutionRoute,
    AuthSidebarDealsAgreementsAgreementAgreementIdHistoryRoute:
      AuthSidebarDealsAgreementsAgreementAgreementIdHistoryRoute,
    AuthSidebarDealsAgreementsAgreementAgreementIdTicketsRoute:
      AuthSidebarDealsAgreementsAgreementAgreementIdTicketsRoute,
  };

const AuthSidebarDealsAgreementsAgreementRouteWithChildren =
  AuthSidebarDealsAgreementsAgreementRoute._addFileChildren(
    AuthSidebarDealsAgreementsAgreementRouteChildren,
  );

interface AuthSidebarDealsAgreementsRouteChildren {
  AuthSidebarDealsAgreementsAgreementRoute: typeof AuthSidebarDealsAgreementsAgreementRouteWithChildren;
}

const AuthSidebarDealsAgreementsRouteChildren: AuthSidebarDealsAgreementsRouteChildren = {
  AuthSidebarDealsAgreementsAgreementRoute: AuthSidebarDealsAgreementsAgreementRouteWithChildren,
};

const AuthSidebarDealsAgreementsRouteWithChildren =
  AuthSidebarDealsAgreementsRoute._addFileChildren(AuthSidebarDealsAgreementsRouteChildren);

interface AuthSidebarDealsMarketBidRouteChildren {
  AuthSidebarDealsMarketBidBidIdAdditionalAgreementsRoute: typeof AuthSidebarDealsMarketBidBidIdAdditionalAgreementsRoute;
  AuthSidebarDealsMarketBidBidIdApprovalsRoute: typeof AuthSidebarDealsMarketBidBidIdApprovalsRoute;
  AuthSidebarDealsMarketBidBidIdCostsRoute: typeof AuthSidebarDealsMarketBidBidIdCostsRoute;
  AuthSidebarDealsMarketBidBidIdDocumentsRoute: typeof AuthSidebarDealsMarketBidBidIdDocumentsRoute;
  AuthSidebarDealsMarketBidBidIdHistoryRoute: typeof AuthSidebarDealsMarketBidBidIdHistoryRoute;
  AuthSidebarDealsMarketBidBidIdPotentialPurchaseRoute: typeof AuthSidebarDealsMarketBidBidIdPotentialPurchaseRoute;
  AuthSidebarDealsMarketBidBidIdQualityRoute: typeof AuthSidebarDealsMarketBidBidIdQualityRoute;
  AuthSidebarDealsMarketBidBidIdTasksRoute: typeof AuthSidebarDealsMarketBidBidIdTasksRoute;
  AuthSidebarDealsMarketBidBidIdTelegramRoute: typeof AuthSidebarDealsMarketBidBidIdTelegramRoute;
}

const AuthSidebarDealsMarketBidRouteChildren: AuthSidebarDealsMarketBidRouteChildren = {
  AuthSidebarDealsMarketBidBidIdAdditionalAgreementsRoute:
    AuthSidebarDealsMarketBidBidIdAdditionalAgreementsRoute,
  AuthSidebarDealsMarketBidBidIdApprovalsRoute: AuthSidebarDealsMarketBidBidIdApprovalsRoute,
  AuthSidebarDealsMarketBidBidIdCostsRoute: AuthSidebarDealsMarketBidBidIdCostsRoute,
  AuthSidebarDealsMarketBidBidIdDocumentsRoute: AuthSidebarDealsMarketBidBidIdDocumentsRoute,
  AuthSidebarDealsMarketBidBidIdHistoryRoute: AuthSidebarDealsMarketBidBidIdHistoryRoute,
  AuthSidebarDealsMarketBidBidIdPotentialPurchaseRoute:
    AuthSidebarDealsMarketBidBidIdPotentialPurchaseRoute,
  AuthSidebarDealsMarketBidBidIdQualityRoute: AuthSidebarDealsMarketBidBidIdQualityRoute,
  AuthSidebarDealsMarketBidBidIdTasksRoute: AuthSidebarDealsMarketBidBidIdTasksRoute,
  AuthSidebarDealsMarketBidBidIdTelegramRoute: AuthSidebarDealsMarketBidBidIdTelegramRoute,
};

const AuthSidebarDealsMarketBidRouteWithChildren = AuthSidebarDealsMarketBidRoute._addFileChildren(
  AuthSidebarDealsMarketBidRouteChildren,
);

interface AuthSidebarDealsMarketOfferRouteChildren {
  AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsRoute: typeof AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsRoute;
  AuthSidebarDealsMarketOfferOfferIdApprovalsRoute: typeof AuthSidebarDealsMarketOfferOfferIdApprovalsRoute;
  AuthSidebarDealsMarketOfferOfferIdCostsRoute: typeof AuthSidebarDealsMarketOfferOfferIdCostsRoute;
  AuthSidebarDealsMarketOfferOfferIdDocumentsRoute: typeof AuthSidebarDealsMarketOfferOfferIdDocumentsRoute;
  AuthSidebarDealsMarketOfferOfferIdHistoryRoute: typeof AuthSidebarDealsMarketOfferOfferIdHistoryRoute;
  AuthSidebarDealsMarketOfferOfferIdQualityRoute: typeof AuthSidebarDealsMarketOfferOfferIdQualityRoute;
  AuthSidebarDealsMarketOfferOfferIdTasksRoute: typeof AuthSidebarDealsMarketOfferOfferIdTasksRoute;
  AuthSidebarDealsMarketOfferOfferIdTelegramRoute: typeof AuthSidebarDealsMarketOfferOfferIdTelegramRoute;
}

const AuthSidebarDealsMarketOfferRouteChildren: AuthSidebarDealsMarketOfferRouteChildren = {
  AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsRoute:
    AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsRoute,
  AuthSidebarDealsMarketOfferOfferIdApprovalsRoute:
    AuthSidebarDealsMarketOfferOfferIdApprovalsRoute,
  AuthSidebarDealsMarketOfferOfferIdCostsRoute: AuthSidebarDealsMarketOfferOfferIdCostsRoute,
  AuthSidebarDealsMarketOfferOfferIdDocumentsRoute:
    AuthSidebarDealsMarketOfferOfferIdDocumentsRoute,
  AuthSidebarDealsMarketOfferOfferIdHistoryRoute: AuthSidebarDealsMarketOfferOfferIdHistoryRoute,
  AuthSidebarDealsMarketOfferOfferIdQualityRoute: AuthSidebarDealsMarketOfferOfferIdQualityRoute,
  AuthSidebarDealsMarketOfferOfferIdTasksRoute: AuthSidebarDealsMarketOfferOfferIdTasksRoute,
  AuthSidebarDealsMarketOfferOfferIdTelegramRoute: AuthSidebarDealsMarketOfferOfferIdTelegramRoute,
};

const AuthSidebarDealsMarketOfferRouteWithChildren =
  AuthSidebarDealsMarketOfferRoute._addFileChildren(AuthSidebarDealsMarketOfferRouteChildren);

interface AuthSidebarDealsMarketRouteChildren {
  AuthSidebarDealsMarketBidRoute: typeof AuthSidebarDealsMarketBidRouteWithChildren;
  AuthSidebarDealsMarketOfferRoute: typeof AuthSidebarDealsMarketOfferRouteWithChildren;
  AuthSidebarDealsMarketBidsRoute: typeof AuthSidebarDealsMarketBidsRoute;
  AuthSidebarDealsMarketOffersRoute: typeof AuthSidebarDealsMarketOffersRoute;
}

const AuthSidebarDealsMarketRouteChildren: AuthSidebarDealsMarketRouteChildren = {
  AuthSidebarDealsMarketBidRoute: AuthSidebarDealsMarketBidRouteWithChildren,
  AuthSidebarDealsMarketOfferRoute: AuthSidebarDealsMarketOfferRouteWithChildren,
  AuthSidebarDealsMarketBidsRoute: AuthSidebarDealsMarketBidsRoute,
  AuthSidebarDealsMarketOffersRoute: AuthSidebarDealsMarketOffersRoute,
};

const AuthSidebarDealsMarketRouteWithChildren = AuthSidebarDealsMarketRoute._addFileChildren(
  AuthSidebarDealsMarketRouteChildren,
);

interface AuthSidebarDealsMultiservicesMultiserviceRouteChildren {
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsRoute;
  AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksRoute: typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksRoute;
}

const AuthSidebarDealsMultiservicesMultiserviceRouteChildren: AuthSidebarDealsMultiservicesMultiserviceRouteChildren =
  {
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsRoute,
    AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksRoute:
      AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksRoute,
  };

const AuthSidebarDealsMultiservicesMultiserviceRouteWithChildren =
  AuthSidebarDealsMultiservicesMultiserviceRoute._addFileChildren(
    AuthSidebarDealsMultiservicesMultiserviceRouteChildren,
  );

interface AuthSidebarDealsMultiservicesRouteChildren {
  AuthSidebarDealsMultiservicesMultiserviceRoute: typeof AuthSidebarDealsMultiservicesMultiserviceRouteWithChildren;
}

const AuthSidebarDealsMultiservicesRouteChildren: AuthSidebarDealsMultiservicesRouteChildren = {
  AuthSidebarDealsMultiservicesMultiserviceRoute:
    AuthSidebarDealsMultiservicesMultiserviceRouteWithChildren,
};

const AuthSidebarDealsMultiservicesRouteWithChildren =
  AuthSidebarDealsMultiservicesRoute._addFileChildren(AuthSidebarDealsMultiservicesRouteChildren);

interface AuthSidebarDealsMultiticketsMultiticketRouteChildren {
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsRoute;
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsRoute;
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsRoute;
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsRoute;
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryRoute;
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsRoute;
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseRoute;
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityRoute;
  AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksRoute: typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksRoute;
}

const AuthSidebarDealsMultiticketsMultiticketRouteChildren: AuthSidebarDealsMultiticketsMultiticketRouteChildren =
  {
    AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsRoute:
      AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsRoute,
    AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsRoute:
      AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsRoute,
    AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsRoute:
      AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsRoute,
    AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsRoute:
      AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsRoute,
    AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryRoute:
      AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryRoute,
    AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsRoute:
      AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsRoute,
    AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseRoute:
      AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseRoute,
    AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityRoute:
      AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityRoute,
    AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksRoute:
      AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksRoute,
  };

const AuthSidebarDealsMultiticketsMultiticketRouteWithChildren =
  AuthSidebarDealsMultiticketsMultiticketRoute._addFileChildren(
    AuthSidebarDealsMultiticketsMultiticketRouteChildren,
  );

interface AuthSidebarDealsMultiticketsRouteChildren {
  AuthSidebarDealsMultiticketsMultiticketRoute: typeof AuthSidebarDealsMultiticketsMultiticketRouteWithChildren;
}

const AuthSidebarDealsMultiticketsRouteChildren: AuthSidebarDealsMultiticketsRouteChildren = {
  AuthSidebarDealsMultiticketsMultiticketRoute:
    AuthSidebarDealsMultiticketsMultiticketRouteWithChildren,
};

const AuthSidebarDealsMultiticketsRouteWithChildren =
  AuthSidebarDealsMultiticketsRoute._addFileChildren(AuthSidebarDealsMultiticketsRouteChildren);

interface AuthSidebarDealsPassportsPassportRouteChildren {
  AuthSidebarDealsPassportsPassportPassportIdCommentsRoute: typeof AuthSidebarDealsPassportsPassportPassportIdCommentsRoute;
  AuthSidebarDealsPassportsPassportPassportIdContactsRoute: typeof AuthSidebarDealsPassportsPassportPassportIdContactsRoute;
  AuthSidebarDealsPassportsPassportPassportIdContractsRoute: typeof AuthSidebarDealsPassportsPassportPassportIdContractsRoute;
  AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsRoute: typeof AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsRoute;
  AuthSidebarDealsPassportsPassportPassportIdDashboardRoute: typeof AuthSidebarDealsPassportsPassportPassportIdDashboardRoute;
  AuthSidebarDealsPassportsPassportPassportIdDerivativesRoute: typeof AuthSidebarDealsPassportsPassportPassportIdDerivativesRoute;
  AuthSidebarDealsPassportsPassportPassportIdDocumentsRoute: typeof AuthSidebarDealsPassportsPassportPassportIdDocumentsRoute;
  AuthSidebarDealsPassportsPassportPassportIdExecutionRoute: typeof AuthSidebarDealsPassportsPassportPassportIdExecutionRoute;
  AuthSidebarDealsPassportsPassportPassportIdFinanceRoute: typeof AuthSidebarDealsPassportsPassportPassportIdFinanceRoute;
  AuthSidebarDealsPassportsPassportPassportIdInvoicesRoute: typeof AuthSidebarDealsPassportsPassportPassportIdInvoicesRoute;
  AuthSidebarDealsPassportsPassportPassportIdTasksRoute: typeof AuthSidebarDealsPassportsPassportPassportIdTasksRoute;
  AuthSidebarDealsPassportsPassportPassportIdTicketsRoute: typeof AuthSidebarDealsPassportsPassportPassportIdTicketsRoute;
}

const AuthSidebarDealsPassportsPassportRouteChildren: AuthSidebarDealsPassportsPassportRouteChildren =
  {
    AuthSidebarDealsPassportsPassportPassportIdCommentsRoute:
      AuthSidebarDealsPassportsPassportPassportIdCommentsRoute,
    AuthSidebarDealsPassportsPassportPassportIdContactsRoute:
      AuthSidebarDealsPassportsPassportPassportIdContactsRoute,
    AuthSidebarDealsPassportsPassportPassportIdContractsRoute:
      AuthSidebarDealsPassportsPassportPassportIdContractsRoute,
    AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsRoute:
      AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsRoute,
    AuthSidebarDealsPassportsPassportPassportIdDashboardRoute:
      AuthSidebarDealsPassportsPassportPassportIdDashboardRoute,
    AuthSidebarDealsPassportsPassportPassportIdDerivativesRoute:
      AuthSidebarDealsPassportsPassportPassportIdDerivativesRoute,
    AuthSidebarDealsPassportsPassportPassportIdDocumentsRoute:
      AuthSidebarDealsPassportsPassportPassportIdDocumentsRoute,
    AuthSidebarDealsPassportsPassportPassportIdExecutionRoute:
      AuthSidebarDealsPassportsPassportPassportIdExecutionRoute,
    AuthSidebarDealsPassportsPassportPassportIdFinanceRoute:
      AuthSidebarDealsPassportsPassportPassportIdFinanceRoute,
    AuthSidebarDealsPassportsPassportPassportIdInvoicesRoute:
      AuthSidebarDealsPassportsPassportPassportIdInvoicesRoute,
    AuthSidebarDealsPassportsPassportPassportIdTasksRoute:
      AuthSidebarDealsPassportsPassportPassportIdTasksRoute,
    AuthSidebarDealsPassportsPassportPassportIdTicketsRoute:
      AuthSidebarDealsPassportsPassportPassportIdTicketsRoute,
  };

const AuthSidebarDealsPassportsPassportRouteWithChildren =
  AuthSidebarDealsPassportsPassportRoute._addFileChildren(
    AuthSidebarDealsPassportsPassportRouteChildren,
  );

interface AuthSidebarDealsPassportsRouteChildren {
  AuthSidebarDealsPassportsPassportRoute: typeof AuthSidebarDealsPassportsPassportRouteWithChildren;
}

const AuthSidebarDealsPassportsRouteChildren: AuthSidebarDealsPassportsRouteChildren = {
  AuthSidebarDealsPassportsPassportRoute: AuthSidebarDealsPassportsPassportRouteWithChildren,
};

const AuthSidebarDealsPassportsRouteWithChildren = AuthSidebarDealsPassportsRoute._addFileChildren(
  AuthSidebarDealsPassportsRouteChildren,
);

interface AuthSidebarDealsServicesRouteChildren {
  AuthSidebarDealsServicesDirectContractsRoute: typeof AuthSidebarDealsServicesDirectContractsRoute;
  AuthSidebarDealsServicesGeneralContractsRoute: typeof AuthSidebarDealsServicesGeneralContractsRoute;
  AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsRoute: typeof AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsRoute;
  AuthSidebarDealsServicesDirectContractContractIdClosingContractRoute: typeof AuthSidebarDealsServicesDirectContractContractIdClosingContractRoute;
  AuthSidebarDealsServicesDirectContractContractIdCostsRoute: typeof AuthSidebarDealsServicesDirectContractContractIdCostsRoute;
  AuthSidebarDealsServicesDirectContractContractIdDocumentsRoute: typeof AuthSidebarDealsServicesDirectContractContractIdDocumentsRoute;
  AuthSidebarDealsServicesDirectContractContractIdExecutionRoute: typeof AuthSidebarDealsServicesDirectContractContractIdExecutionRoute;
  AuthSidebarDealsServicesDirectContractContractIdFinanceRoute: typeof AuthSidebarDealsServicesDirectContractContractIdFinanceRoute;
  AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingRoute: typeof AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingRoute;
  AuthSidebarDealsServicesDirectContractContractIdHistoryRoute: typeof AuthSidebarDealsServicesDirectContractContractIdHistoryRoute;
  AuthSidebarDealsServicesDirectContractContractIdQualityRoute: typeof AuthSidebarDealsServicesDirectContractContractIdQualityRoute;
  AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsRoute: typeof AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsRoute;
  AuthSidebarDealsServicesDirectContractContractIdTasksRoute: typeof AuthSidebarDealsServicesDirectContractContractIdTasksRoute;
  AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsRoute;
  AuthSidebarDealsServicesGeneralContractContractIdClosingContractRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdClosingContractRoute;
  AuthSidebarDealsServicesGeneralContractContractIdCostsRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdCostsRoute;
  AuthSidebarDealsServicesGeneralContractContractIdDocumentsRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdDocumentsRoute;
  AuthSidebarDealsServicesGeneralContractContractIdExecutionRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdExecutionRoute;
  AuthSidebarDealsServicesGeneralContractContractIdFinanceRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdFinanceRoute;
  AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingRoute;
  AuthSidebarDealsServicesGeneralContractContractIdHistoryRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdHistoryRoute;
  AuthSidebarDealsServicesGeneralContractContractIdQualityRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdQualityRoute;
  AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsRoute;
  AuthSidebarDealsServicesGeneralContractContractIdTasksRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdTasksRoute;
  AuthSidebarDealsServicesDirectContractContractIdIndexRoute: typeof AuthSidebarDealsServicesDirectContractContractIdIndexRoute;
  AuthSidebarDealsServicesGeneralContractContractIdIndexRoute: typeof AuthSidebarDealsServicesGeneralContractContractIdIndexRoute;
}

const AuthSidebarDealsServicesRouteChildren: AuthSidebarDealsServicesRouteChildren = {
  AuthSidebarDealsServicesDirectContractsRoute: AuthSidebarDealsServicesDirectContractsRoute,
  AuthSidebarDealsServicesGeneralContractsRoute: AuthSidebarDealsServicesGeneralContractsRoute,
  AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsRoute:
    AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsRoute,
  AuthSidebarDealsServicesDirectContractContractIdClosingContractRoute:
    AuthSidebarDealsServicesDirectContractContractIdClosingContractRoute,
  AuthSidebarDealsServicesDirectContractContractIdCostsRoute:
    AuthSidebarDealsServicesDirectContractContractIdCostsRoute,
  AuthSidebarDealsServicesDirectContractContractIdDocumentsRoute:
    AuthSidebarDealsServicesDirectContractContractIdDocumentsRoute,
  AuthSidebarDealsServicesDirectContractContractIdExecutionRoute:
    AuthSidebarDealsServicesDirectContractContractIdExecutionRoute,
  AuthSidebarDealsServicesDirectContractContractIdFinanceRoute:
    AuthSidebarDealsServicesDirectContractContractIdFinanceRoute,
  AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingRoute:
    AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingRoute,
  AuthSidebarDealsServicesDirectContractContractIdHistoryRoute:
    AuthSidebarDealsServicesDirectContractContractIdHistoryRoute,
  AuthSidebarDealsServicesDirectContractContractIdQualityRoute:
    AuthSidebarDealsServicesDirectContractContractIdQualityRoute,
  AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsRoute:
    AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsRoute,
  AuthSidebarDealsServicesDirectContractContractIdTasksRoute:
    AuthSidebarDealsServicesDirectContractContractIdTasksRoute,
  AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsRoute:
    AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsRoute,
  AuthSidebarDealsServicesGeneralContractContractIdClosingContractRoute:
    AuthSidebarDealsServicesGeneralContractContractIdClosingContractRoute,
  AuthSidebarDealsServicesGeneralContractContractIdCostsRoute:
    AuthSidebarDealsServicesGeneralContractContractIdCostsRoute,
  AuthSidebarDealsServicesGeneralContractContractIdDocumentsRoute:
    AuthSidebarDealsServicesGeneralContractContractIdDocumentsRoute,
  AuthSidebarDealsServicesGeneralContractContractIdExecutionRoute:
    AuthSidebarDealsServicesGeneralContractContractIdExecutionRoute,
  AuthSidebarDealsServicesGeneralContractContractIdFinanceRoute:
    AuthSidebarDealsServicesGeneralContractContractIdFinanceRoute,
  AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingRoute:
    AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingRoute,
  AuthSidebarDealsServicesGeneralContractContractIdHistoryRoute:
    AuthSidebarDealsServicesGeneralContractContractIdHistoryRoute,
  AuthSidebarDealsServicesGeneralContractContractIdQualityRoute:
    AuthSidebarDealsServicesGeneralContractContractIdQualityRoute,
  AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsRoute:
    AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsRoute,
  AuthSidebarDealsServicesGeneralContractContractIdTasksRoute:
    AuthSidebarDealsServicesGeneralContractContractIdTasksRoute,
  AuthSidebarDealsServicesDirectContractContractIdIndexRoute:
    AuthSidebarDealsServicesDirectContractContractIdIndexRoute,
  AuthSidebarDealsServicesGeneralContractContractIdIndexRoute:
    AuthSidebarDealsServicesGeneralContractContractIdIndexRoute,
};

const AuthSidebarDealsServicesRouteWithChildren = AuthSidebarDealsServicesRoute._addFileChildren(
  AuthSidebarDealsServicesRouteChildren,
);

interface AuthSidebarDealsTicketsRouteChildren {
  AuthSidebarDealsTicketsPurchaseTicketsRoute: typeof AuthSidebarDealsTicketsPurchaseTicketsRoute;
  AuthSidebarDealsTicketsSaleTicketsRoute: typeof AuthSidebarDealsTicketsSaleTicketsRoute;
  AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsRoute;
  AuthSidebarDealsTicketsPurchaseTicketIdApprovalsRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdApprovalsRoute;
  AuthSidebarDealsTicketsPurchaseTicketIdCostsRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdCostsRoute;
  AuthSidebarDealsTicketsPurchaseTicketIdDocumentsRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdDocumentsRoute;
  AuthSidebarDealsTicketsPurchaseTicketIdHistoryRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdHistoryRoute;
  AuthSidebarDealsTicketsPurchaseTicketIdQualityRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdQualityRoute;
  AuthSidebarDealsTicketsPurchaseTicketIdTasksRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdTasksRoute;
  AuthSidebarDealsTicketsPurchaseTicketIdTelegramRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdTelegramRoute;
  AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsRoute: typeof AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsRoute;
  AuthSidebarDealsTicketsSaleTicketIdCostsRoute: typeof AuthSidebarDealsTicketsSaleTicketIdCostsRoute;
  AuthSidebarDealsTicketsSaleTicketIdDocumentsRoute: typeof AuthSidebarDealsTicketsSaleTicketIdDocumentsRoute;
  AuthSidebarDealsTicketsSaleTicketIdHistoryRoute: typeof AuthSidebarDealsTicketsSaleTicketIdHistoryRoute;
  AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseRoute: typeof AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseRoute;
  AuthSidebarDealsTicketsSaleTicketIdQualityRoute: typeof AuthSidebarDealsTicketsSaleTicketIdQualityRoute;
  AuthSidebarDealsTicketsSaleTicketIdTasksRoute: typeof AuthSidebarDealsTicketsSaleTicketIdTasksRoute;
  AuthSidebarDealsTicketsSaleTicketIdTelegramRoute: typeof AuthSidebarDealsTicketsSaleTicketIdTelegramRoute;
  AuthSidebarDealsTicketsPurchaseTicketIdIndexRoute: typeof AuthSidebarDealsTicketsPurchaseTicketIdIndexRoute;
  AuthSidebarDealsTicketsSaleTicketIdIndexRoute: typeof AuthSidebarDealsTicketsSaleTicketIdIndexRoute;
}

const AuthSidebarDealsTicketsRouteChildren: AuthSidebarDealsTicketsRouteChildren = {
  AuthSidebarDealsTicketsPurchaseTicketsRoute: AuthSidebarDealsTicketsPurchaseTicketsRoute,
  AuthSidebarDealsTicketsSaleTicketsRoute: AuthSidebarDealsTicketsSaleTicketsRoute,
  AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsRoute:
    AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsRoute,
  AuthSidebarDealsTicketsPurchaseTicketIdApprovalsRoute:
    AuthSidebarDealsTicketsPurchaseTicketIdApprovalsRoute,
  AuthSidebarDealsTicketsPurchaseTicketIdCostsRoute:
    AuthSidebarDealsTicketsPurchaseTicketIdCostsRoute,
  AuthSidebarDealsTicketsPurchaseTicketIdDocumentsRoute:
    AuthSidebarDealsTicketsPurchaseTicketIdDocumentsRoute,
  AuthSidebarDealsTicketsPurchaseTicketIdHistoryRoute:
    AuthSidebarDealsTicketsPurchaseTicketIdHistoryRoute,
  AuthSidebarDealsTicketsPurchaseTicketIdQualityRoute:
    AuthSidebarDealsTicketsPurchaseTicketIdQualityRoute,
  AuthSidebarDealsTicketsPurchaseTicketIdTasksRoute:
    AuthSidebarDealsTicketsPurchaseTicketIdTasksRoute,
  AuthSidebarDealsTicketsPurchaseTicketIdTelegramRoute:
    AuthSidebarDealsTicketsPurchaseTicketIdTelegramRoute,
  AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsRoute:
    AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsRoute,
  AuthSidebarDealsTicketsSaleTicketIdCostsRoute: AuthSidebarDealsTicketsSaleTicketIdCostsRoute,
  AuthSidebarDealsTicketsSaleTicketIdDocumentsRoute:
    AuthSidebarDealsTicketsSaleTicketIdDocumentsRoute,
  AuthSidebarDealsTicketsSaleTicketIdHistoryRoute: AuthSidebarDealsTicketsSaleTicketIdHistoryRoute,
  AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseRoute:
    AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseRoute,
  AuthSidebarDealsTicketsSaleTicketIdQualityRoute: AuthSidebarDealsTicketsSaleTicketIdQualityRoute,
  AuthSidebarDealsTicketsSaleTicketIdTasksRoute: AuthSidebarDealsTicketsSaleTicketIdTasksRoute,
  AuthSidebarDealsTicketsSaleTicketIdTelegramRoute:
    AuthSidebarDealsTicketsSaleTicketIdTelegramRoute,
  AuthSidebarDealsTicketsPurchaseTicketIdIndexRoute:
    AuthSidebarDealsTicketsPurchaseTicketIdIndexRoute,
  AuthSidebarDealsTicketsSaleTicketIdIndexRoute: AuthSidebarDealsTicketsSaleTicketIdIndexRoute,
};

const AuthSidebarDealsTicketsRouteWithChildren = AuthSidebarDealsTicketsRoute._addFileChildren(
  AuthSidebarDealsTicketsRouteChildren,
);

interface AuthSidebarRouteChildren {
  AuthSidebarStartRoute: typeof AuthSidebarStartRoute;
  AuthSidebarCompanyCustomFieldsRoute: typeof AuthSidebarCompanyCustomFieldsRoute;
  AuthSidebarCompanyDocumentsRoute: typeof AuthSidebarCompanyDocumentsRoute;
  AuthSidebarCompanyDriversRoute: typeof AuthSidebarCompanyDriversRouteWithChildren;
  AuthSidebarCompanyNotificationsRoute: typeof AuthSidebarCompanyNotificationsRoute;
  AuthSidebarCompanyQualityRuleTemplatesRoute: typeof AuthSidebarCompanyQualityRuleTemplatesRoute;
  AuthSidebarCompanySettingsRoute: typeof AuthSidebarCompanySettingsRouteWithChildren;
  AuthSidebarCompanyTasksTemplatesRoute: typeof AuthSidebarCompanyTasksTemplatesRoute;
  AuthSidebarCompanyTemplatePlaygroundRoute: typeof AuthSidebarCompanyTemplatePlaygroundRoute;
  AuthSidebarCompanyVehiclesRoute: typeof AuthSidebarCompanyVehiclesRouteWithChildren;
  AuthSidebarCrmContactsRoute: typeof AuthSidebarCrmContactsRoute;
  AuthSidebarCrmCounterpartiesRoute: typeof AuthSidebarCrmCounterpartiesRouteWithChildren;
  AuthSidebarCrmUpdatesRoute: typeof AuthSidebarCrmUpdatesRoute;
  AuthSidebarDealsAgreementsRoute: typeof AuthSidebarDealsAgreementsRouteWithChildren;
  AuthSidebarDealsCalculatorRoute: typeof AuthSidebarDealsCalculatorRoute;
  AuthSidebarDealsContractsRoute: typeof AuthSidebarDealsContractsRoute;
  AuthSidebarDealsMarketRoute: typeof AuthSidebarDealsMarketRouteWithChildren;
  AuthSidebarDealsMulticontractsRoute: typeof AuthSidebarDealsMulticontractsRoute;
  AuthSidebarDealsMultiservicesRoute: typeof AuthSidebarDealsMultiservicesRouteWithChildren;
  AuthSidebarDealsMultiticketsRoute: typeof AuthSidebarDealsMultiticketsRouteWithChildren;
  AuthSidebarDealsPassportsRoute: typeof AuthSidebarDealsPassportsRouteWithChildren;
  AuthSidebarDealsPotentialsRoute: typeof AuthSidebarDealsPotentialsRoute;
  AuthSidebarDealsQuotationsRoute: typeof AuthSidebarDealsQuotationsRoute;
  AuthSidebarDealsServicesRoute: typeof AuthSidebarDealsServicesRouteWithChildren;
  AuthSidebarDealsTicketsRoute: typeof AuthSidebarDealsTicketsRouteWithChildren;
  AuthSidebarExecutionAnalyticsRoute: typeof AuthSidebarExecutionAnalyticsRoute;
  AuthSidebarExecutionBillsOfLadingRoute: typeof AuthSidebarExecutionBillsOfLadingRoute;
  AuthSidebarExecutionConsignmentsRoute: typeof AuthSidebarExecutionConsignmentsRoute;
  AuthSidebarExecutionCustomsDeclarationsRoute: typeof AuthSidebarExecutionCustomsDeclarationsRoute;
  AuthSidebarExecutionDisbursementBillOfLadingRoute: typeof AuthSidebarExecutionDisbursementBillOfLadingRoute;
  AuthSidebarExecutionPlanningRoute: typeof AuthSidebarExecutionPlanningRoute;
  AuthSidebarExecutionSamplesRoute: typeof AuthSidebarExecutionSamplesRoute;
  AuthSidebarExecutionTariffsRoute: typeof AuthSidebarExecutionTariffsRoute;
  AuthSidebarExecutionTransportRoute: typeof AuthSidebarExecutionTransportRoute;
  AuthSidebarExecutionVesselsRoute: typeof AuthSidebarExecutionVesselsRoute;
  AuthSidebarExecutionWarehouseRoute: typeof AuthSidebarExecutionWarehouseRoute;
  AuthSidebarFinanceAccountingRoute: typeof AuthSidebarFinanceAccountingRoute;
  AuthSidebarFinanceAnalyticsRoute: typeof AuthSidebarFinanceAnalyticsRoute;
  AuthSidebarFinanceBankAccountsRoute: typeof AuthSidebarFinanceBankAccountsRoute;
  AuthSidebarFinanceChargesOrGainsRoute: typeof AuthSidebarFinanceChargesOrGainsRoute;
  AuthSidebarFinanceInvoicePositionsRoute: typeof AuthSidebarFinanceInvoicePositionsRoute;
  AuthSidebarFinanceInvoicesRoute: typeof AuthSidebarFinanceInvoicesRoute;
  AuthSidebarFinanceOffsetRoute: typeof AuthSidebarFinanceOffsetRoute;
  AuthSidebarFinancePaymentDelaysRoute: typeof AuthSidebarFinancePaymentDelaysRoute;
  AuthSidebarFinancePaymentPlansRoute: typeof AuthSidebarFinancePaymentPlansRoute;
  AuthSidebarFinancePaymentsRoute: typeof AuthSidebarFinancePaymentsRoute;
  AuthSidebarFinanceReasignmentRoute: typeof AuthSidebarFinanceReasignmentRoute;
  AuthSidebarReportsDashboardRoute: typeof AuthSidebarReportsDashboardRoute;
  AuthSidebarCrmNewslettersInvoicesRoute: typeof AuthSidebarCrmNewslettersInvoicesRoute;
  AuthSidebarCrmNewslettersLogisticsRoute: typeof AuthSidebarCrmNewslettersLogisticsRoute;
  AuthSidebarCrmNewslettersTemplatesRoute: typeof AuthSidebarCrmNewslettersTemplatesRoute;
  AuthSidebarReportsFinanceReportsApArRoute: typeof AuthSidebarReportsFinanceReportsApArRoute;
  AuthSidebarReportsFinanceReportsBalanceRoute: typeof AuthSidebarReportsFinanceReportsBalanceRoute;
  AuthSidebarReportsFinanceReportsBudgetsRoute: typeof AuthSidebarReportsFinanceReportsBudgetsRoute;
  AuthSidebarReportsFinanceReportsCashFlowRoute: typeof AuthSidebarReportsFinanceReportsCashFlowRoute;
  AuthSidebarReportsFinanceReportsCostsReportRoute: typeof AuthSidebarReportsFinanceReportsCostsReportRoute;
  AuthSidebarReportsFinanceReportsPassportsPnlRoute: typeof AuthSidebarReportsFinanceReportsPassportsPnlRoute;
  AuthSidebarReportsFinanceReportsReconciliationsRoute: typeof AuthSidebarReportsFinanceReportsReconciliationsRoute;
  AuthSidebarReportsFinanceReportsStatisticsRoute: typeof AuthSidebarReportsFinanceReportsStatisticsRoute;
  AuthSidebarReportsManagementReportsActivityRoute: typeof AuthSidebarReportsManagementReportsActivityRoute;
  AuthSidebarReportsManagementReportsApprovalsRoute: typeof AuthSidebarReportsManagementReportsApprovalsRoute;
  AuthSidebarReportsManagementReportsComplianceRoute: typeof AuthSidebarReportsManagementReportsComplianceRoute;
  AuthSidebarReportsManagementReportsDocumentsDatesRoute: typeof AuthSidebarReportsManagementReportsDocumentsDatesRoute;
  AuthSidebarReportsManagementReportsKpiRoute: typeof AuthSidebarReportsManagementReportsKpiRoute;
  AuthSidebarReportsManagementReportsSigningsRoute: typeof AuthSidebarReportsManagementReportsSigningsRoute;
  AuthSidebarReportsManagementReportsTeamRoute: typeof AuthSidebarReportsManagementReportsTeamRoute;
  AuthSidebarReportsRiskReportsCreditReportRoute: typeof AuthSidebarReportsRiskReportsCreditReportRoute;
  AuthSidebarReportsRiskReportsInvoicesRiskRoute: typeof AuthSidebarReportsRiskReportsInvoicesRiskRoute;
  AuthSidebarReportsTradingReportsContractsExecutionRoute: typeof AuthSidebarReportsTradingReportsContractsExecutionRoute;
  AuthSidebarReportsTradingReportsDerivativesRoute: typeof AuthSidebarReportsTradingReportsDerivativesRoute;
  AuthSidebarReportsTradingReportsFixingOrHedgingRoute: typeof AuthSidebarReportsTradingReportsFixingOrHedgingRoute;
  AuthSidebarReportsTradingReportsFreightRoute: typeof AuthSidebarReportsTradingReportsFreightRoute;
  AuthSidebarReportsTradingReportsLineUpRoute: typeof AuthSidebarReportsTradingReportsLineUpRoute;
  AuthSidebarReportsTradingReportsPhysicalPositionByMonthsRoute: typeof AuthSidebarReportsTradingReportsPhysicalPositionByMonthsRoute;
  AuthSidebarReportsTradingReportsPhysicalPositionMtmRoute: typeof AuthSidebarReportsTradingReportsPhysicalPositionMtmRoute;
  AuthSidebarReportsTradingReportsPositionPlAndMtmRoute: typeof AuthSidebarReportsTradingReportsPositionPlAndMtmRoute;
  AuthSidebarCompanyTeamIndexRoute: typeof AuthSidebarCompanyTeamIndexRoute;
  AuthSidebarCompanyTeamTraderIdApprovalsRoute: typeof AuthSidebarCompanyTeamTraderIdApprovalsRoute;
  AuthSidebarCompanyTeamTraderIdChargesRoute: typeof AuthSidebarCompanyTeamTraderIdChargesRoute;
  AuthSidebarCompanyTeamTraderIdCompaniesRoute: typeof AuthSidebarCompanyTeamTraderIdCompaniesRoute;
  AuthSidebarCompanyTeamTraderIdContactsRoute: typeof AuthSidebarCompanyTeamTraderIdContactsRoute;
  AuthSidebarCompanyTeamTraderIdContractsRoute: typeof AuthSidebarCompanyTeamTraderIdContractsRoute;
  AuthSidebarCompanyTeamTraderIdDerivativesRoute: typeof AuthSidebarCompanyTeamTraderIdDerivativesRoute;
  AuthSidebarCompanyTeamTraderIdDocumentsRoute: typeof AuthSidebarCompanyTeamTraderIdDocumentsRoute;
  AuthSidebarCompanyTeamTraderIdFinancesRoute: typeof AuthSidebarCompanyTeamTraderIdFinancesRoute;
  AuthSidebarCompanyTeamTraderIdHistoryRoute: typeof AuthSidebarCompanyTeamTraderIdHistoryRoute;
  AuthSidebarCompanyTeamTraderIdIndicatorsRoute: typeof AuthSidebarCompanyTeamTraderIdIndicatorsRoute;
  AuthSidebarCompanyTeamTraderIdInfoRoute: typeof AuthSidebarCompanyTeamTraderIdInfoRoute;
  AuthSidebarCompanyTeamTraderIdLogisticsRoute: typeof AuthSidebarCompanyTeamTraderIdLogisticsRoute;
  AuthSidebarCompanyTeamTraderIdPassportsRoute: typeof AuthSidebarCompanyTeamTraderIdPassportsRoute;
  AuthSidebarCompanyTeamTraderIdSettingsRoute: typeof AuthSidebarCompanyTeamTraderIdSettingsRoute;
  AuthSidebarCompanyTeamTraderIdTicketsRoute: typeof AuthSidebarCompanyTeamTraderIdTicketsRoute;
  AuthSidebarCompanyTeamTraderIdUpdatesRoute: typeof AuthSidebarCompanyTeamTraderIdUpdatesRoute;
  AuthSidebarCompanyTeamTraderIdVoyagesRoute: typeof AuthSidebarCompanyTeamTraderIdVoyagesRoute;
  AuthSidebarCompanyTeamTraderIdIndexRoute: typeof AuthSidebarCompanyTeamTraderIdIndexRoute;
}

const AuthSidebarRouteChildren: AuthSidebarRouteChildren = {
  AuthSidebarStartRoute: AuthSidebarStartRoute,
  AuthSidebarCompanyCustomFieldsRoute: AuthSidebarCompanyCustomFieldsRoute,
  AuthSidebarCompanyDocumentsRoute: AuthSidebarCompanyDocumentsRoute,
  AuthSidebarCompanyDriversRoute: AuthSidebarCompanyDriversRouteWithChildren,
  AuthSidebarCompanyNotificationsRoute: AuthSidebarCompanyNotificationsRoute,
  AuthSidebarCompanyQualityRuleTemplatesRoute: AuthSidebarCompanyQualityRuleTemplatesRoute,
  AuthSidebarCompanySettingsRoute: AuthSidebarCompanySettingsRouteWithChildren,
  AuthSidebarCompanyTasksTemplatesRoute: AuthSidebarCompanyTasksTemplatesRoute,
  AuthSidebarCompanyTemplatePlaygroundRoute: AuthSidebarCompanyTemplatePlaygroundRoute,
  AuthSidebarCompanyVehiclesRoute: AuthSidebarCompanyVehiclesRouteWithChildren,
  AuthSidebarCrmContactsRoute: AuthSidebarCrmContactsRoute,
  AuthSidebarCrmCounterpartiesRoute: AuthSidebarCrmCounterpartiesRouteWithChildren,
  AuthSidebarCrmUpdatesRoute: AuthSidebarCrmUpdatesRoute,
  AuthSidebarDealsAgreementsRoute: AuthSidebarDealsAgreementsRouteWithChildren,
  AuthSidebarDealsCalculatorRoute: AuthSidebarDealsCalculatorRoute,
  AuthSidebarDealsContractsRoute: AuthSidebarDealsContractsRoute,
  AuthSidebarDealsMarketRoute: AuthSidebarDealsMarketRouteWithChildren,
  AuthSidebarDealsMulticontractsRoute: AuthSidebarDealsMulticontractsRoute,
  AuthSidebarDealsMultiservicesRoute: AuthSidebarDealsMultiservicesRouteWithChildren,
  AuthSidebarDealsMultiticketsRoute: AuthSidebarDealsMultiticketsRouteWithChildren,
  AuthSidebarDealsPassportsRoute: AuthSidebarDealsPassportsRouteWithChildren,
  AuthSidebarDealsPotentialsRoute: AuthSidebarDealsPotentialsRoute,
  AuthSidebarDealsQuotationsRoute: AuthSidebarDealsQuotationsRoute,
  AuthSidebarDealsServicesRoute: AuthSidebarDealsServicesRouteWithChildren,
  AuthSidebarDealsTicketsRoute: AuthSidebarDealsTicketsRouteWithChildren,
  AuthSidebarExecutionAnalyticsRoute: AuthSidebarExecutionAnalyticsRoute,
  AuthSidebarExecutionBillsOfLadingRoute: AuthSidebarExecutionBillsOfLadingRoute,
  AuthSidebarExecutionConsignmentsRoute: AuthSidebarExecutionConsignmentsRoute,
  AuthSidebarExecutionCustomsDeclarationsRoute: AuthSidebarExecutionCustomsDeclarationsRoute,
  AuthSidebarExecutionDisbursementBillOfLadingRoute:
    AuthSidebarExecutionDisbursementBillOfLadingRoute,
  AuthSidebarExecutionPlanningRoute: AuthSidebarExecutionPlanningRoute,
  AuthSidebarExecutionSamplesRoute: AuthSidebarExecutionSamplesRoute,
  AuthSidebarExecutionTariffsRoute: AuthSidebarExecutionTariffsRoute,
  AuthSidebarExecutionTransportRoute: AuthSidebarExecutionTransportRoute,
  AuthSidebarExecutionVesselsRoute: AuthSidebarExecutionVesselsRoute,
  AuthSidebarExecutionWarehouseRoute: AuthSidebarExecutionWarehouseRoute,
  AuthSidebarFinanceAccountingRoute: AuthSidebarFinanceAccountingRoute,
  AuthSidebarFinanceAnalyticsRoute: AuthSidebarFinanceAnalyticsRoute,
  AuthSidebarFinanceBankAccountsRoute: AuthSidebarFinanceBankAccountsRoute,
  AuthSidebarFinanceChargesOrGainsRoute: AuthSidebarFinanceChargesOrGainsRoute,
  AuthSidebarFinanceInvoicePositionsRoute: AuthSidebarFinanceInvoicePositionsRoute,
  AuthSidebarFinanceInvoicesRoute: AuthSidebarFinanceInvoicesRoute,
  AuthSidebarFinanceOffsetRoute: AuthSidebarFinanceOffsetRoute,
  AuthSidebarFinancePaymentDelaysRoute: AuthSidebarFinancePaymentDelaysRoute,
  AuthSidebarFinancePaymentPlansRoute: AuthSidebarFinancePaymentPlansRoute,
  AuthSidebarFinancePaymentsRoute: AuthSidebarFinancePaymentsRoute,
  AuthSidebarFinanceReasignmentRoute: AuthSidebarFinanceReasignmentRoute,
  AuthSidebarReportsDashboardRoute: AuthSidebarReportsDashboardRoute,
  AuthSidebarCrmNewslettersInvoicesRoute: AuthSidebarCrmNewslettersInvoicesRoute,
  AuthSidebarCrmNewslettersLogisticsRoute: AuthSidebarCrmNewslettersLogisticsRoute,
  AuthSidebarCrmNewslettersTemplatesRoute: AuthSidebarCrmNewslettersTemplatesRoute,
  AuthSidebarReportsFinanceReportsApArRoute: AuthSidebarReportsFinanceReportsApArRoute,
  AuthSidebarReportsFinanceReportsBalanceRoute: AuthSidebarReportsFinanceReportsBalanceRoute,
  AuthSidebarReportsFinanceReportsBudgetsRoute: AuthSidebarReportsFinanceReportsBudgetsRoute,
  AuthSidebarReportsFinanceReportsCashFlowRoute: AuthSidebarReportsFinanceReportsCashFlowRoute,
  AuthSidebarReportsFinanceReportsCostsReportRoute:
    AuthSidebarReportsFinanceReportsCostsReportRoute,
  AuthSidebarReportsFinanceReportsPassportsPnlRoute:
    AuthSidebarReportsFinanceReportsPassportsPnlRoute,
  AuthSidebarReportsFinanceReportsReconciliationsRoute:
    AuthSidebarReportsFinanceReportsReconciliationsRoute,
  AuthSidebarReportsFinanceReportsStatisticsRoute: AuthSidebarReportsFinanceReportsStatisticsRoute,
  AuthSidebarReportsManagementReportsActivityRoute:
    AuthSidebarReportsManagementReportsActivityRoute,
  AuthSidebarReportsManagementReportsApprovalsRoute:
    AuthSidebarReportsManagementReportsApprovalsRoute,
  AuthSidebarReportsManagementReportsComplianceRoute:
    AuthSidebarReportsManagementReportsComplianceRoute,
  AuthSidebarReportsManagementReportsDocumentsDatesRoute:
    AuthSidebarReportsManagementReportsDocumentsDatesRoute,
  AuthSidebarReportsManagementReportsKpiRoute: AuthSidebarReportsManagementReportsKpiRoute,
  AuthSidebarReportsManagementReportsSigningsRoute:
    AuthSidebarReportsManagementReportsSigningsRoute,
  AuthSidebarReportsManagementReportsTeamRoute: AuthSidebarReportsManagementReportsTeamRoute,
  AuthSidebarReportsRiskReportsCreditReportRoute: AuthSidebarReportsRiskReportsCreditReportRoute,
  AuthSidebarReportsRiskReportsInvoicesRiskRoute: AuthSidebarReportsRiskReportsInvoicesRiskRoute,
  AuthSidebarReportsTradingReportsContractsExecutionRoute:
    AuthSidebarReportsTradingReportsContractsExecutionRoute,
  AuthSidebarReportsTradingReportsDerivativesRoute:
    AuthSidebarReportsTradingReportsDerivativesRoute,
  AuthSidebarReportsTradingReportsFixingOrHedgingRoute:
    AuthSidebarReportsTradingReportsFixingOrHedgingRoute,
  AuthSidebarReportsTradingReportsFreightRoute: AuthSidebarReportsTradingReportsFreightRoute,
  AuthSidebarReportsTradingReportsLineUpRoute: AuthSidebarReportsTradingReportsLineUpRoute,
  AuthSidebarReportsTradingReportsPhysicalPositionByMonthsRoute:
    AuthSidebarReportsTradingReportsPhysicalPositionByMonthsRoute,
  AuthSidebarReportsTradingReportsPhysicalPositionMtmRoute:
    AuthSidebarReportsTradingReportsPhysicalPositionMtmRoute,
  AuthSidebarReportsTradingReportsPositionPlAndMtmRoute:
    AuthSidebarReportsTradingReportsPositionPlAndMtmRoute,
  AuthSidebarCompanyTeamIndexRoute: AuthSidebarCompanyTeamIndexRoute,
  AuthSidebarCompanyTeamTraderIdApprovalsRoute: AuthSidebarCompanyTeamTraderIdApprovalsRoute,
  AuthSidebarCompanyTeamTraderIdChargesRoute: AuthSidebarCompanyTeamTraderIdChargesRoute,
  AuthSidebarCompanyTeamTraderIdCompaniesRoute: AuthSidebarCompanyTeamTraderIdCompaniesRoute,
  AuthSidebarCompanyTeamTraderIdContactsRoute: AuthSidebarCompanyTeamTraderIdContactsRoute,
  AuthSidebarCompanyTeamTraderIdContractsRoute: AuthSidebarCompanyTeamTraderIdContractsRoute,
  AuthSidebarCompanyTeamTraderIdDerivativesRoute: AuthSidebarCompanyTeamTraderIdDerivativesRoute,
  AuthSidebarCompanyTeamTraderIdDocumentsRoute: AuthSidebarCompanyTeamTraderIdDocumentsRoute,
  AuthSidebarCompanyTeamTraderIdFinancesRoute: AuthSidebarCompanyTeamTraderIdFinancesRoute,
  AuthSidebarCompanyTeamTraderIdHistoryRoute: AuthSidebarCompanyTeamTraderIdHistoryRoute,
  AuthSidebarCompanyTeamTraderIdIndicatorsRoute: AuthSidebarCompanyTeamTraderIdIndicatorsRoute,
  AuthSidebarCompanyTeamTraderIdInfoRoute: AuthSidebarCompanyTeamTraderIdInfoRoute,
  AuthSidebarCompanyTeamTraderIdLogisticsRoute: AuthSidebarCompanyTeamTraderIdLogisticsRoute,
  AuthSidebarCompanyTeamTraderIdPassportsRoute: AuthSidebarCompanyTeamTraderIdPassportsRoute,
  AuthSidebarCompanyTeamTraderIdSettingsRoute: AuthSidebarCompanyTeamTraderIdSettingsRoute,
  AuthSidebarCompanyTeamTraderIdTicketsRoute: AuthSidebarCompanyTeamTraderIdTicketsRoute,
  AuthSidebarCompanyTeamTraderIdUpdatesRoute: AuthSidebarCompanyTeamTraderIdUpdatesRoute,
  AuthSidebarCompanyTeamTraderIdVoyagesRoute: AuthSidebarCompanyTeamTraderIdVoyagesRoute,
  AuthSidebarCompanyTeamTraderIdIndexRoute: AuthSidebarCompanyTeamTraderIdIndexRoute,
};

const AuthSidebarRouteWithChildren = AuthSidebarRoute._addFileChildren(AuthSidebarRouteChildren);

interface AuthRouteChildren {
  AuthSidebarRoute: typeof AuthSidebarRouteWithChildren;
  AuthIndexRoute: typeof AuthIndexRoute;
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthSidebarRoute: AuthSidebarRouteWithChildren,
  AuthIndexRoute: AuthIndexRoute,
};

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren);

export interface FileRoutesByFullPath {
  '/401': typeof R401Route;
  '/403': typeof R403Route;
  '/404': typeof R404Route;
  '/500': typeof R500Route;
  '': typeof AuthSidebarRouteWithChildren;
  '/login': typeof LoginRoute;
  '/reset-password': typeof ResetPasswordRoute;
  '/': typeof AuthIndexRoute;
  '/start': typeof AuthSidebarStartRoute;
  '/company/custom-fields': typeof AuthSidebarCompanyCustomFieldsRoute;
  '/company/documents': typeof AuthSidebarCompanyDocumentsRoute;
  '/company/drivers': typeof AuthSidebarCompanyDriversDriverRouteWithChildren;
  '/company/notifications': typeof AuthSidebarCompanyNotificationsRoute;
  '/company/quality-rule-templates': typeof AuthSidebarCompanyQualityRuleTemplatesRoute;
  '/company/settings': typeof AuthSidebarCompanySettingsRouteWithChildren;
  '/company/tasks-templates': typeof AuthSidebarCompanyTasksTemplatesRoute;
  '/company/template-playground': typeof AuthSidebarCompanyTemplatePlaygroundRoute;
  '/company/vehicles': typeof AuthSidebarCompanyVehiclesRouteWithChildren;
  '/crm/contacts': typeof AuthSidebarCrmContactsRoute;
  '/crm/counterparties': typeof AuthSidebarCrmCounterpartiesCounterpartyRouteWithChildren;
  '/crm/updates': typeof AuthSidebarCrmUpdatesRoute;
  '/deals/agreements': typeof AuthSidebarDealsAgreementsAgreementRouteWithChildren;
  '/deals/calculator': typeof AuthSidebarDealsCalculatorRoute;
  '/deals/contracts': typeof AuthSidebarDealsContractsRoute;
  '/deals/market': typeof AuthSidebarDealsMarketOfferRouteWithChildren;
  '/deals/multicontracts': typeof AuthSidebarDealsMulticontractsRoute;
  '/deals/multiservices': typeof AuthSidebarDealsMultiservicesMultiserviceRouteWithChildren;
  '/deals/multitickets': typeof AuthSidebarDealsMultiticketsMultiticketRouteWithChildren;
  '/deals/passports': typeof AuthSidebarDealsPassportsPassportRouteWithChildren;
  '/deals/potentials': typeof AuthSidebarDealsPotentialsRoute;
  '/deals/quotations': typeof AuthSidebarDealsQuotationsRoute;
  '/deals/services': typeof AuthSidebarDealsServicesRouteWithChildren;
  '/deals/tickets': typeof AuthSidebarDealsTicketsRouteWithChildren;
  '/execution/analytics': typeof AuthSidebarExecutionAnalyticsRoute;
  '/execution/bills-of-lading': typeof AuthSidebarExecutionBillsOfLadingRoute;
  '/execution/consignments': typeof AuthSidebarExecutionConsignmentsRoute;
  '/execution/customs-declarations': typeof AuthSidebarExecutionCustomsDeclarationsRoute;
  '/execution/disbursement-bill-of-lading': typeof AuthSidebarExecutionDisbursementBillOfLadingRoute;
  '/execution/planning': typeof AuthSidebarExecutionPlanningRoute;
  '/execution/samples': typeof AuthSidebarExecutionSamplesRoute;
  '/execution/tariffs': typeof AuthSidebarExecutionTariffsRoute;
  '/execution/transport': typeof AuthSidebarExecutionTransportRoute;
  '/execution/vessels': typeof AuthSidebarExecutionVesselsRoute;
  '/execution/warehouse': typeof AuthSidebarExecutionWarehouseRoute;
  '/finance/accounting': typeof AuthSidebarFinanceAccountingRoute;
  '/finance/analytics': typeof AuthSidebarFinanceAnalyticsRoute;
  '/finance/bank-accounts': typeof AuthSidebarFinanceBankAccountsRoute;
  '/finance/charges-or-gains': typeof AuthSidebarFinanceChargesOrGainsRoute;
  '/finance/invoice-positions': typeof AuthSidebarFinanceInvoicePositionsRoute;
  '/finance/invoices': typeof AuthSidebarFinanceInvoicesRoute;
  '/finance/offset': typeof AuthSidebarFinanceOffsetRoute;
  '/finance/payment-delays': typeof AuthSidebarFinancePaymentDelaysRoute;
  '/finance/payment-plans': typeof AuthSidebarFinancePaymentPlansRoute;
  '/finance/payments': typeof AuthSidebarFinancePaymentsRoute;
  '/finance/reasignment': typeof AuthSidebarFinanceReasignmentRoute;
  '/reports/dashboard': typeof AuthSidebarReportsDashboardRoute;
  '/company/settings/organization': typeof AuthSidebarCompanySettingsOrganizationRoute;
  '/company/settings/parsing': typeof AuthSidebarCompanySettingsParsingRoute;
  '/company/settings/personal': typeof AuthSidebarCompanySettingsPersonalRoute;
  '/crm/newsletters/invoices': typeof AuthSidebarCrmNewslettersInvoicesRoute;
  '/crm/newsletters/logistics': typeof AuthSidebarCrmNewslettersLogisticsRoute;
  '/crm/newsletters/templates': typeof AuthSidebarCrmNewslettersTemplatesRoute;
  '/deals/market/bids': typeof AuthSidebarDealsMarketBidsRoute;
  '/deals/market/offers': typeof AuthSidebarDealsMarketOffersRoute;
  '/deals/services/direct-contracts': typeof AuthSidebarDealsServicesDirectContractsRoute;
  '/deals/services/general-contracts': typeof AuthSidebarDealsServicesGeneralContractsRoute;
  '/deals/tickets/purchase-tickets': typeof AuthSidebarDealsTicketsPurchaseTicketsRoute;
  '/deals/tickets/sale-tickets': typeof AuthSidebarDealsTicketsSaleTicketsRoute;
  '/reports/finance-reports/ap-ar': typeof AuthSidebarReportsFinanceReportsApArRoute;
  '/reports/finance-reports/balance': typeof AuthSidebarReportsFinanceReportsBalanceRoute;
  '/reports/finance-reports/budgets': typeof AuthSidebarReportsFinanceReportsBudgetsRoute;
  '/reports/finance-reports/cash-flow': typeof AuthSidebarReportsFinanceReportsCashFlowRoute;
  '/reports/finance-reports/costs-report': typeof AuthSidebarReportsFinanceReportsCostsReportRoute;
  '/reports/finance-reports/passports-pnl': typeof AuthSidebarReportsFinanceReportsPassportsPnlRoute;
  '/reports/finance-reports/reconciliations': typeof AuthSidebarReportsFinanceReportsReconciliationsRoute;
  '/reports/finance-reports/statistics': typeof AuthSidebarReportsFinanceReportsStatisticsRoute;
  '/reports/management-reports/activity': typeof AuthSidebarReportsManagementReportsActivityRoute;
  '/reports/management-reports/approvals': typeof AuthSidebarReportsManagementReportsApprovalsRoute;
  '/reports/management-reports/compliance': typeof AuthSidebarReportsManagementReportsComplianceRoute;
  '/reports/management-reports/documents-dates': typeof AuthSidebarReportsManagementReportsDocumentsDatesRoute;
  '/reports/management-reports/kpi': typeof AuthSidebarReportsManagementReportsKpiRoute;
  '/reports/management-reports/signings': typeof AuthSidebarReportsManagementReportsSigningsRoute;
  '/reports/management-reports/team': typeof AuthSidebarReportsManagementReportsTeamRoute;
  '/reports/risk-reports/credit-report': typeof AuthSidebarReportsRiskReportsCreditReportRoute;
  '/reports/risk-reports/invoices-risk': typeof AuthSidebarReportsRiskReportsInvoicesRiskRoute;
  '/reports/trading-reports/contracts-execution': typeof AuthSidebarReportsTradingReportsContractsExecutionRoute;
  '/reports/trading-reports/derivatives': typeof AuthSidebarReportsTradingReportsDerivativesRoute;
  '/reports/trading-reports/fixing-or-hedging': typeof AuthSidebarReportsTradingReportsFixingOrHedgingRoute;
  '/reports/trading-reports/freight': typeof AuthSidebarReportsTradingReportsFreightRoute;
  '/reports/trading-reports/line-up': typeof AuthSidebarReportsTradingReportsLineUpRoute;
  '/reports/trading-reports/physical-position-by-months': typeof AuthSidebarReportsTradingReportsPhysicalPositionByMonthsRoute;
  '/reports/trading-reports/physical-position-mtm': typeof AuthSidebarReportsTradingReportsPhysicalPositionMtmRoute;
  '/reports/trading-reports/position-pl-and-mtm': typeof AuthSidebarReportsTradingReportsPositionPlAndMtmRoute;
  '/company/team': typeof AuthSidebarCompanyTeamIndexRoute;
  '/company/settings/addresses': typeof AuthSidebarCompanySettingsListAddressesRoute;
  '/company/settings/bots': typeof AuthSidebarCompanySettingsListBotsRoute;
  '/company/settings/chats': typeof AuthSidebarCompanySettingsListChatsRoute;
  '/company/settings/cities': typeof AuthSidebarCompanySettingsListCitiesRoute;
  '/company/settings/client-chats': typeof AuthSidebarCompanySettingsListClientChatsRoute;
  '/company/settings/commodities': typeof AuthSidebarCompanySettingsListCommoditiesRoute;
  '/company/settings/company-groups': typeof AuthSidebarCompanySettingsListCompanyGroupsRoute;
  '/company/settings/configs': typeof AuthSidebarCompanySettingsListConfigsRoute;
  '/company/settings/contract-charges': typeof AuthSidebarCompanySettingsListContractChargesRoute;
  '/company/settings/countries': typeof AuthSidebarCompanySettingsListCountriesRoute;
  '/company/settings/currencies': typeof AuthSidebarCompanySettingsListCurrenciesRoute;
  '/company/settings/delivery-conditions': typeof AuthSidebarCompanySettingsListDeliveryConditionsRoute;
  '/company/settings/derivative-accounts': typeof AuthSidebarCompanySettingsListDerivativeAccountsRoute;
  '/company/settings/derivatives': typeof AuthSidebarCompanySettingsListDerivativesRoute;
  '/company/settings/districts': typeof AuthSidebarCompanySettingsListDistrictsRoute;
  '/company/settings/documents': typeof AuthSidebarCompanySettingsListDocumentsRoute;
  '/company/settings/drivers': typeof AuthSidebarCompanySettingsListDriversRoute;
  '/company/settings/exchanges': typeof AuthSidebarCompanySettingsListExchangesRoute;
  '/company/settings/finance-accounts': typeof AuthSidebarCompanySettingsListFinanceAccountsRoute;
  '/company/settings/finance-bank': typeof AuthSidebarCompanySettingsListFinanceBankRoute;
  '/company/settings/general-expenses': typeof AuthSidebarCompanySettingsListGeneralExpensesRoute;
  '/company/settings/groups': typeof AuthSidebarCompanySettingsListGroupsRoute;
  '/company/settings/logistic-types': typeof AuthSidebarCompanySettingsListLogisticTypesRoute;
  '/company/settings/newsletter': typeof AuthSidebarCompanySettingsListNewsletterRoute;
  '/company/settings/other-activities': typeof AuthSidebarCompanySettingsListOtherActivitiesRoute;
  '/company/settings/payment-info': typeof AuthSidebarCompanySettingsListPaymentInfoRoute;
  '/company/settings/ports': typeof AuthSidebarCompanySettingsListPortsRoute;
  '/company/settings/quality': typeof AuthSidebarCompanySettingsListQualityRoute;
  '/company/settings/regions': typeof AuthSidebarCompanySettingsListRegionsRoute;
  '/company/settings/responsibilities': typeof AuthSidebarCompanySettingsListResponsibilitiesRoute;
  '/company/settings/stations': typeof AuthSidebarCompanySettingsListStationsRoute;
  '/company/settings/stock-exchange': typeof AuthSidebarCompanySettingsListStockExchangeRoute;
  '/company/settings/terminals': typeof AuthSidebarCompanySettingsListTerminalsRoute;
  '/company/settings/users': typeof AuthSidebarCompanySettingsListUsersRoute;
  '/company/settings/vehicle-subtypes': typeof AuthSidebarCompanySettingsListVehicleSubtypesRoute;
  '/company/settings/vehicles': typeof AuthSidebarCompanySettingsListVehiclesRoute;
  '/company/settings/vessel-sizes': typeof AuthSidebarCompanySettingsListVesselSizesRoute;
  '/company/team/$traderId/approvals': typeof AuthSidebarCompanyTeamTraderIdApprovalsRoute;
  '/company/team/$traderId/charges': typeof AuthSidebarCompanyTeamTraderIdChargesRoute;
  '/company/team/$traderId/companies': typeof AuthSidebarCompanyTeamTraderIdCompaniesRoute;
  '/company/team/$traderId/contacts': typeof AuthSidebarCompanyTeamTraderIdContactsRoute;
  '/company/team/$traderId/contracts': typeof AuthSidebarCompanyTeamTraderIdContractsRoute;
  '/company/team/$traderId/derivatives': typeof AuthSidebarCompanyTeamTraderIdDerivativesRoute;
  '/company/team/$traderId/documents': typeof AuthSidebarCompanyTeamTraderIdDocumentsRoute;
  '/company/team/$traderId/finances': typeof AuthSidebarCompanyTeamTraderIdFinancesRoute;
  '/company/team/$traderId/history': typeof AuthSidebarCompanyTeamTraderIdHistoryRoute;
  '/company/team/$traderId/indicators': typeof AuthSidebarCompanyTeamTraderIdIndicatorsRoute;
  '/company/team/$traderId/info': typeof AuthSidebarCompanyTeamTraderIdInfoRoute;
  '/company/team/$traderId/logistics': typeof AuthSidebarCompanyTeamTraderIdLogisticsRoute;
  '/company/team/$traderId/passports': typeof AuthSidebarCompanyTeamTraderIdPassportsRoute;
  '/company/team/$traderId/settings': typeof AuthSidebarCompanyTeamTraderIdSettingsRoute;
  '/company/team/$traderId/tickets': typeof AuthSidebarCompanyTeamTraderIdTicketsRoute;
  '/company/team/$traderId/updates': typeof AuthSidebarCompanyTeamTraderIdUpdatesRoute;
  '/company/team/$traderId/voyages': typeof AuthSidebarCompanyTeamTraderIdVoyagesRoute;
  '/company/team/$traderId': typeof AuthSidebarCompanyTeamTraderIdIndexRoute;
  '/company/drivers/$driverId/documents': typeof AuthSidebarCompanyDriversDriverDriverIdDocumentsRoute;
  '/company/drivers/$driverId/history': typeof AuthSidebarCompanyDriversDriverDriverIdHistoryRoute;
  '/company/drivers/$driverId/info': typeof AuthSidebarCompanyDriversDriverDriverIdInfoRoute;
  '/company/vehicles/$vehicleId/documents': typeof AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsRoute;
  '/company/vehicles/$vehicleId/history': typeof AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryRoute;
  '/company/vehicles/$vehicleId/info': typeof AuthSidebarCompanyVehiclesVehicleVehicleIdInfoRoute;
  '/crm/counterparties/$counterpartyId/agreements': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsRoute;
  '/crm/counterparties/$counterpartyId/approvals': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsRoute;
  '/crm/counterparties/$counterpartyId/bank-details': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsRoute;
  '/crm/counterparties/$counterpartyId/contacts': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsRoute;
  '/crm/counterparties/$counterpartyId/contracts': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsRoute;
  '/crm/counterparties/$counterpartyId/costs-and-gains': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsRoute;
  '/crm/counterparties/$counterpartyId/dashboard': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardRoute;
  '/crm/counterparties/$counterpartyId/derivatives': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesRoute;
  '/crm/counterparties/$counterpartyId/documents': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsRoute;
  '/crm/counterparties/$counterpartyId/execution': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionRoute;
  '/crm/counterparties/$counterpartyId/finance': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceRoute;
  '/crm/counterparties/$counterpartyId/history': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryRoute;
  '/crm/counterparties/$counterpartyId/indicators': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsRoute;
  '/crm/counterparties/$counterpartyId/potentials': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsRoute;
  '/crm/counterparties/$counterpartyId/reconciliation': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationRoute;
  '/crm/counterparties/$counterpartyId/risk': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskRoute;
  '/crm/counterparties/$counterpartyId/tasks': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksRoute;
  '/crm/counterparties/$counterpartyId/tickets': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsRoute;
  '/deals/agreements/$agreementId/contracts': typeof AuthSidebarDealsAgreementsAgreementAgreementIdContractsRoute;
  '/deals/agreements/$agreementId/custom-fields': typeof AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsRoute;
  '/deals/agreements/$agreementId/documents': typeof AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsRoute;
  '/deals/agreements/$agreementId/execution': typeof AuthSidebarDealsAgreementsAgreementAgreementIdExecutionRoute;
  '/deals/agreements/$agreementId/history': typeof AuthSidebarDealsAgreementsAgreementAgreementIdHistoryRoute;
  '/deals/agreements/$agreementId/tickets': typeof AuthSidebarDealsAgreementsAgreementAgreementIdTicketsRoute;
  '/deals/market/$bidId/additional-agreements': typeof AuthSidebarDealsMarketBidBidIdAdditionalAgreementsRoute;
  '/deals/market/$bidId/approvals': typeof AuthSidebarDealsMarketBidBidIdApprovalsRoute;
  '/deals/market/$bidId/costs': typeof AuthSidebarDealsMarketBidBidIdCostsRoute;
  '/deals/market/$bidId/documents': typeof AuthSidebarDealsMarketBidBidIdDocumentsRoute;
  '/deals/market/$bidId/history': typeof AuthSidebarDealsMarketBidBidIdHistoryRoute;
  '/deals/market/$bidId/potential-purchase': typeof AuthSidebarDealsMarketBidBidIdPotentialPurchaseRoute;
  '/deals/market/$bidId/quality': typeof AuthSidebarDealsMarketBidBidIdQualityRoute;
  '/deals/market/$bidId/tasks': typeof AuthSidebarDealsMarketBidBidIdTasksRoute;
  '/deals/market/$bidId/telegram': typeof AuthSidebarDealsMarketBidBidIdTelegramRoute;
  '/deals/market/$offerId/additional-agreements': typeof AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsRoute;
  '/deals/market/$offerId/approvals': typeof AuthSidebarDealsMarketOfferOfferIdApprovalsRoute;
  '/deals/market/$offerId/costs': typeof AuthSidebarDealsMarketOfferOfferIdCostsRoute;
  '/deals/market/$offerId/documents': typeof AuthSidebarDealsMarketOfferOfferIdDocumentsRoute;
  '/deals/market/$offerId/history': typeof AuthSidebarDealsMarketOfferOfferIdHistoryRoute;
  '/deals/market/$offerId/quality': typeof AuthSidebarDealsMarketOfferOfferIdQualityRoute;
  '/deals/market/$offerId/tasks': typeof AuthSidebarDealsMarketOfferOfferIdTasksRoute;
  '/deals/market/$offerId/telegram': typeof AuthSidebarDealsMarketOfferOfferIdTelegramRoute;
  '/deals/multiservices/$multiserviceId/additional-agreements': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsRoute;
  '/deals/multiservices/$multiserviceId/approvals': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsRoute;
  '/deals/multiservices/$multiserviceId/closing-contract': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractRoute;
  '/deals/multiservices/$multiserviceId/costs-gains': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsRoute;
  '/deals/multiservices/$multiserviceId/dashboard': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardRoute;
  '/deals/multiservices/$multiserviceId/documents': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsRoute;
  '/deals/multiservices/$multiserviceId/execution': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionRoute;
  '/deals/multiservices/$multiserviceId/finance': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceRoute;
  '/deals/multiservices/$multiserviceId/fixing-and-hedging': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingRoute;
  '/deals/multiservices/$multiserviceId/history': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryRoute;
  '/deals/multiservices/$multiserviceId/positions': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsRoute;
  '/deals/multiservices/$multiserviceId/print-and-sign': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignRoute;
  '/deals/multiservices/$multiserviceId/profit-and-loss': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossRoute;
  '/deals/multiservices/$multiserviceId/quality': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityRoute;
  '/deals/multiservices/$multiserviceId/sale-export-contract-allocations': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsRoute;
  '/deals/multiservices/$multiserviceId/tasks': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksRoute;
  '/deals/multitickets/$multiticketId/additional-agreements': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsRoute;
  '/deals/multitickets/$multiticketId/approvals': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsRoute;
  '/deals/multitickets/$multiticketId/costs-gains': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsRoute;
  '/deals/multitickets/$multiticketId/documents': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsRoute;
  '/deals/multitickets/$multiticketId/history': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryRoute;
  '/deals/multitickets/$multiticketId/positions': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsRoute;
  '/deals/multitickets/$multiticketId/potential-purchase': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseRoute;
  '/deals/multitickets/$multiticketId/quality': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityRoute;
  '/deals/multitickets/$multiticketId/tasks': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksRoute;
  '/deals/passports/$passportId/comments': typeof AuthSidebarDealsPassportsPassportPassportIdCommentsRoute;
  '/deals/passports/$passportId/contacts': typeof AuthSidebarDealsPassportsPassportPassportIdContactsRoute;
  '/deals/passports/$passportId/contracts': typeof AuthSidebarDealsPassportsPassportPassportIdContractsRoute;
  '/deals/passports/$passportId/cost-of-goods': typeof AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsRoute;
  '/deals/passports/$passportId/dashboard': typeof AuthSidebarDealsPassportsPassportPassportIdDashboardRoute;
  '/deals/passports/$passportId/derivatives': typeof AuthSidebarDealsPassportsPassportPassportIdDerivativesRoute;
  '/deals/passports/$passportId/documents': typeof AuthSidebarDealsPassportsPassportPassportIdDocumentsRoute;
  '/deals/passports/$passportId/execution': typeof AuthSidebarDealsPassportsPassportPassportIdExecutionRoute;
  '/deals/passports/$passportId/finance': typeof AuthSidebarDealsPassportsPassportPassportIdFinanceRoute;
  '/deals/passports/$passportId/invoices': typeof AuthSidebarDealsPassportsPassportPassportIdInvoicesRoute;
  '/deals/passports/$passportId/tasks': typeof AuthSidebarDealsPassportsPassportPassportIdTasksRoute;
  '/deals/passports/$passportId/tickets': typeof AuthSidebarDealsPassportsPassportPassportIdTicketsRoute;
  '/deals/services/direct-contract/$contractId/additional-agreements': typeof AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsRoute;
  '/deals/services/direct-contract/$contractId/closing-contract': typeof AuthSidebarDealsServicesDirectContractContractIdClosingContractRoute;
  '/deals/services/direct-contract/$contractId/costs': typeof AuthSidebarDealsServicesDirectContractContractIdCostsRoute;
  '/deals/services/direct-contract/$contractId/documents': typeof AuthSidebarDealsServicesDirectContractContractIdDocumentsRoute;
  '/deals/services/direct-contract/$contractId/execution': typeof AuthSidebarDealsServicesDirectContractContractIdExecutionRoute;
  '/deals/services/direct-contract/$contractId/finance': typeof AuthSidebarDealsServicesDirectContractContractIdFinanceRoute;
  '/deals/services/direct-contract/$contractId/fixing-and-hedging': typeof AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingRoute;
  '/deals/services/direct-contract/$contractId/history': typeof AuthSidebarDealsServicesDirectContractContractIdHistoryRoute;
  '/deals/services/direct-contract/$contractId/quality': typeof AuthSidebarDealsServicesDirectContractContractIdQualityRoute;
  '/deals/services/direct-contract/$contractId/sale-export-contract-allocations': typeof AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsRoute;
  '/deals/services/direct-contract/$contractId/tasks': typeof AuthSidebarDealsServicesDirectContractContractIdTasksRoute;
  '/deals/services/general-contract/$contractId/additional-agreements': typeof AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsRoute;
  '/deals/services/general-contract/$contractId/closing-contract': typeof AuthSidebarDealsServicesGeneralContractContractIdClosingContractRoute;
  '/deals/services/general-contract/$contractId/costs': typeof AuthSidebarDealsServicesGeneralContractContractIdCostsRoute;
  '/deals/services/general-contract/$contractId/documents': typeof AuthSidebarDealsServicesGeneralContractContractIdDocumentsRoute;
  '/deals/services/general-contract/$contractId/execution': typeof AuthSidebarDealsServicesGeneralContractContractIdExecutionRoute;
  '/deals/services/general-contract/$contractId/finance': typeof AuthSidebarDealsServicesGeneralContractContractIdFinanceRoute;
  '/deals/services/general-contract/$contractId/fixing-and-hedging': typeof AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingRoute;
  '/deals/services/general-contract/$contractId/history': typeof AuthSidebarDealsServicesGeneralContractContractIdHistoryRoute;
  '/deals/services/general-contract/$contractId/quality': typeof AuthSidebarDealsServicesGeneralContractContractIdQualityRoute;
  '/deals/services/general-contract/$contractId/sale-export-contract-allocations': typeof AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsRoute;
  '/deals/services/general-contract/$contractId/tasks': typeof AuthSidebarDealsServicesGeneralContractContractIdTasksRoute;
  '/deals/tickets/purchase/$ticketId/additional-agreements': typeof AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsRoute;
  '/deals/tickets/purchase/$ticketId/approvals': typeof AuthSidebarDealsTicketsPurchaseTicketIdApprovalsRoute;
  '/deals/tickets/purchase/$ticketId/costs': typeof AuthSidebarDealsTicketsPurchaseTicketIdCostsRoute;
  '/deals/tickets/purchase/$ticketId/documents': typeof AuthSidebarDealsTicketsPurchaseTicketIdDocumentsRoute;
  '/deals/tickets/purchase/$ticketId/history': typeof AuthSidebarDealsTicketsPurchaseTicketIdHistoryRoute;
  '/deals/tickets/purchase/$ticketId/quality': typeof AuthSidebarDealsTicketsPurchaseTicketIdQualityRoute;
  '/deals/tickets/purchase/$ticketId/tasks': typeof AuthSidebarDealsTicketsPurchaseTicketIdTasksRoute;
  '/deals/tickets/purchase/$ticketId/telegram': typeof AuthSidebarDealsTicketsPurchaseTicketIdTelegramRoute;
  '/deals/tickets/sale/$ticketId/additional-agreements': typeof AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsRoute;
  '/deals/tickets/sale/$ticketId/costs': typeof AuthSidebarDealsTicketsSaleTicketIdCostsRoute;
  '/deals/tickets/sale/$ticketId/documents': typeof AuthSidebarDealsTicketsSaleTicketIdDocumentsRoute;
  '/deals/tickets/sale/$ticketId/history': typeof AuthSidebarDealsTicketsSaleTicketIdHistoryRoute;
  '/deals/tickets/sale/$ticketId/potential-purchase': typeof AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseRoute;
  '/deals/tickets/sale/$ticketId/quality': typeof AuthSidebarDealsTicketsSaleTicketIdQualityRoute;
  '/deals/tickets/sale/$ticketId/tasks': typeof AuthSidebarDealsTicketsSaleTicketIdTasksRoute;
  '/deals/tickets/sale/$ticketId/telegram': typeof AuthSidebarDealsTicketsSaleTicketIdTelegramRoute;
  '/deals/services/direct-contract/$contractId': typeof AuthSidebarDealsServicesDirectContractContractIdIndexRoute;
  '/deals/services/general-contract/$contractId': typeof AuthSidebarDealsServicesGeneralContractContractIdIndexRoute;
  '/deals/tickets/purchase/$ticketId': typeof AuthSidebarDealsTicketsPurchaseTicketIdIndexRoute;
  '/deals/tickets/sale/$ticketId': typeof AuthSidebarDealsTicketsSaleTicketIdIndexRoute;
}

export interface FileRoutesByTo {
  '/401': typeof R401Route;
  '/403': typeof R403Route;
  '/404': typeof R404Route;
  '/500': typeof R500Route;
  '/login': typeof LoginRoute;
  '/reset-password': typeof ResetPasswordRoute;
  '': typeof AuthSidebarRouteWithChildren;
  '/': typeof AuthIndexRoute;
  '/start': typeof AuthSidebarStartRoute;
  '/company/custom-fields': typeof AuthSidebarCompanyCustomFieldsRoute;
  '/company/documents': typeof AuthSidebarCompanyDocumentsRoute;
  '/company/drivers': typeof AuthSidebarCompanyDriversDriverRouteWithChildren;
  '/company/notifications': typeof AuthSidebarCompanyNotificationsRoute;
  '/company/quality-rule-templates': typeof AuthSidebarCompanyQualityRuleTemplatesRoute;
  '/company/settings': typeof AuthSidebarCompanySettingsRouteWithChildren;
  '/company/tasks-templates': typeof AuthSidebarCompanyTasksTemplatesRoute;
  '/company/template-playground': typeof AuthSidebarCompanyTemplatePlaygroundRoute;
  '/company/vehicles': typeof AuthSidebarCompanyVehiclesRouteWithChildren;
  '/crm/contacts': typeof AuthSidebarCrmContactsRoute;
  '/crm/counterparties': typeof AuthSidebarCrmCounterpartiesCounterpartyRouteWithChildren;
  '/crm/updates': typeof AuthSidebarCrmUpdatesRoute;
  '/deals/agreements': typeof AuthSidebarDealsAgreementsAgreementRouteWithChildren;
  '/deals/calculator': typeof AuthSidebarDealsCalculatorRoute;
  '/deals/contracts': typeof AuthSidebarDealsContractsRoute;
  '/deals/market': typeof AuthSidebarDealsMarketOfferRouteWithChildren;
  '/deals/multicontracts': typeof AuthSidebarDealsMulticontractsRoute;
  '/deals/multiservices': typeof AuthSidebarDealsMultiservicesMultiserviceRouteWithChildren;
  '/deals/multitickets': typeof AuthSidebarDealsMultiticketsMultiticketRouteWithChildren;
  '/deals/passports': typeof AuthSidebarDealsPassportsPassportRouteWithChildren;
  '/deals/potentials': typeof AuthSidebarDealsPotentialsRoute;
  '/deals/quotations': typeof AuthSidebarDealsQuotationsRoute;
  '/deals/services': typeof AuthSidebarDealsServicesRouteWithChildren;
  '/deals/tickets': typeof AuthSidebarDealsTicketsRouteWithChildren;
  '/execution/analytics': typeof AuthSidebarExecutionAnalyticsRoute;
  '/execution/bills-of-lading': typeof AuthSidebarExecutionBillsOfLadingRoute;
  '/execution/consignments': typeof AuthSidebarExecutionConsignmentsRoute;
  '/execution/customs-declarations': typeof AuthSidebarExecutionCustomsDeclarationsRoute;
  '/execution/disbursement-bill-of-lading': typeof AuthSidebarExecutionDisbursementBillOfLadingRoute;
  '/execution/planning': typeof AuthSidebarExecutionPlanningRoute;
  '/execution/samples': typeof AuthSidebarExecutionSamplesRoute;
  '/execution/tariffs': typeof AuthSidebarExecutionTariffsRoute;
  '/execution/transport': typeof AuthSidebarExecutionTransportRoute;
  '/execution/vessels': typeof AuthSidebarExecutionVesselsRoute;
  '/execution/warehouse': typeof AuthSidebarExecutionWarehouseRoute;
  '/finance/accounting': typeof AuthSidebarFinanceAccountingRoute;
  '/finance/analytics': typeof AuthSidebarFinanceAnalyticsRoute;
  '/finance/bank-accounts': typeof AuthSidebarFinanceBankAccountsRoute;
  '/finance/charges-or-gains': typeof AuthSidebarFinanceChargesOrGainsRoute;
  '/finance/invoice-positions': typeof AuthSidebarFinanceInvoicePositionsRoute;
  '/finance/invoices': typeof AuthSidebarFinanceInvoicesRoute;
  '/finance/offset': typeof AuthSidebarFinanceOffsetRoute;
  '/finance/payment-delays': typeof AuthSidebarFinancePaymentDelaysRoute;
  '/finance/payment-plans': typeof AuthSidebarFinancePaymentPlansRoute;
  '/finance/payments': typeof AuthSidebarFinancePaymentsRoute;
  '/finance/reasignment': typeof AuthSidebarFinanceReasignmentRoute;
  '/reports/dashboard': typeof AuthSidebarReportsDashboardRoute;
  '/company/settings/organization': typeof AuthSidebarCompanySettingsOrganizationRoute;
  '/company/settings/parsing': typeof AuthSidebarCompanySettingsParsingRoute;
  '/company/settings/personal': typeof AuthSidebarCompanySettingsPersonalRoute;
  '/crm/newsletters/invoices': typeof AuthSidebarCrmNewslettersInvoicesRoute;
  '/crm/newsletters/logistics': typeof AuthSidebarCrmNewslettersLogisticsRoute;
  '/crm/newsletters/templates': typeof AuthSidebarCrmNewslettersTemplatesRoute;
  '/deals/market/bids': typeof AuthSidebarDealsMarketBidsRoute;
  '/deals/market/offers': typeof AuthSidebarDealsMarketOffersRoute;
  '/deals/services/direct-contracts': typeof AuthSidebarDealsServicesDirectContractsRoute;
  '/deals/services/general-contracts': typeof AuthSidebarDealsServicesGeneralContractsRoute;
  '/deals/tickets/purchase-tickets': typeof AuthSidebarDealsTicketsPurchaseTicketsRoute;
  '/deals/tickets/sale-tickets': typeof AuthSidebarDealsTicketsSaleTicketsRoute;
  '/reports/finance-reports/ap-ar': typeof AuthSidebarReportsFinanceReportsApArRoute;
  '/reports/finance-reports/balance': typeof AuthSidebarReportsFinanceReportsBalanceRoute;
  '/reports/finance-reports/budgets': typeof AuthSidebarReportsFinanceReportsBudgetsRoute;
  '/reports/finance-reports/cash-flow': typeof AuthSidebarReportsFinanceReportsCashFlowRoute;
  '/reports/finance-reports/costs-report': typeof AuthSidebarReportsFinanceReportsCostsReportRoute;
  '/reports/finance-reports/passports-pnl': typeof AuthSidebarReportsFinanceReportsPassportsPnlRoute;
  '/reports/finance-reports/reconciliations': typeof AuthSidebarReportsFinanceReportsReconciliationsRoute;
  '/reports/finance-reports/statistics': typeof AuthSidebarReportsFinanceReportsStatisticsRoute;
  '/reports/management-reports/activity': typeof AuthSidebarReportsManagementReportsActivityRoute;
  '/reports/management-reports/approvals': typeof AuthSidebarReportsManagementReportsApprovalsRoute;
  '/reports/management-reports/compliance': typeof AuthSidebarReportsManagementReportsComplianceRoute;
  '/reports/management-reports/documents-dates': typeof AuthSidebarReportsManagementReportsDocumentsDatesRoute;
  '/reports/management-reports/kpi': typeof AuthSidebarReportsManagementReportsKpiRoute;
  '/reports/management-reports/signings': typeof AuthSidebarReportsManagementReportsSigningsRoute;
  '/reports/management-reports/team': typeof AuthSidebarReportsManagementReportsTeamRoute;
  '/reports/risk-reports/credit-report': typeof AuthSidebarReportsRiskReportsCreditReportRoute;
  '/reports/risk-reports/invoices-risk': typeof AuthSidebarReportsRiskReportsInvoicesRiskRoute;
  '/reports/trading-reports/contracts-execution': typeof AuthSidebarReportsTradingReportsContractsExecutionRoute;
  '/reports/trading-reports/derivatives': typeof AuthSidebarReportsTradingReportsDerivativesRoute;
  '/reports/trading-reports/fixing-or-hedging': typeof AuthSidebarReportsTradingReportsFixingOrHedgingRoute;
  '/reports/trading-reports/freight': typeof AuthSidebarReportsTradingReportsFreightRoute;
  '/reports/trading-reports/line-up': typeof AuthSidebarReportsTradingReportsLineUpRoute;
  '/reports/trading-reports/physical-position-by-months': typeof AuthSidebarReportsTradingReportsPhysicalPositionByMonthsRoute;
  '/reports/trading-reports/physical-position-mtm': typeof AuthSidebarReportsTradingReportsPhysicalPositionMtmRoute;
  '/reports/trading-reports/position-pl-and-mtm': typeof AuthSidebarReportsTradingReportsPositionPlAndMtmRoute;
  '/company/team': typeof AuthSidebarCompanyTeamIndexRoute;
  '/company/settings/addresses': typeof AuthSidebarCompanySettingsListAddressesRoute;
  '/company/settings/bots': typeof AuthSidebarCompanySettingsListBotsRoute;
  '/company/settings/chats': typeof AuthSidebarCompanySettingsListChatsRoute;
  '/company/settings/cities': typeof AuthSidebarCompanySettingsListCitiesRoute;
  '/company/settings/client-chats': typeof AuthSidebarCompanySettingsListClientChatsRoute;
  '/company/settings/commodities': typeof AuthSidebarCompanySettingsListCommoditiesRoute;
  '/company/settings/company-groups': typeof AuthSidebarCompanySettingsListCompanyGroupsRoute;
  '/company/settings/configs': typeof AuthSidebarCompanySettingsListConfigsRoute;
  '/company/settings/contract-charges': typeof AuthSidebarCompanySettingsListContractChargesRoute;
  '/company/settings/countries': typeof AuthSidebarCompanySettingsListCountriesRoute;
  '/company/settings/currencies': typeof AuthSidebarCompanySettingsListCurrenciesRoute;
  '/company/settings/delivery-conditions': typeof AuthSidebarCompanySettingsListDeliveryConditionsRoute;
  '/company/settings/derivative-accounts': typeof AuthSidebarCompanySettingsListDerivativeAccountsRoute;
  '/company/settings/derivatives': typeof AuthSidebarCompanySettingsListDerivativesRoute;
  '/company/settings/districts': typeof AuthSidebarCompanySettingsListDistrictsRoute;
  '/company/settings/documents': typeof AuthSidebarCompanySettingsListDocumentsRoute;
  '/company/settings/drivers': typeof AuthSidebarCompanySettingsListDriversRoute;
  '/company/settings/exchanges': typeof AuthSidebarCompanySettingsListExchangesRoute;
  '/company/settings/finance-accounts': typeof AuthSidebarCompanySettingsListFinanceAccountsRoute;
  '/company/settings/finance-bank': typeof AuthSidebarCompanySettingsListFinanceBankRoute;
  '/company/settings/general-expenses': typeof AuthSidebarCompanySettingsListGeneralExpensesRoute;
  '/company/settings/groups': typeof AuthSidebarCompanySettingsListGroupsRoute;
  '/company/settings/logistic-types': typeof AuthSidebarCompanySettingsListLogisticTypesRoute;
  '/company/settings/newsletter': typeof AuthSidebarCompanySettingsListNewsletterRoute;
  '/company/settings/other-activities': typeof AuthSidebarCompanySettingsListOtherActivitiesRoute;
  '/company/settings/payment-info': typeof AuthSidebarCompanySettingsListPaymentInfoRoute;
  '/company/settings/ports': typeof AuthSidebarCompanySettingsListPortsRoute;
  '/company/settings/quality': typeof AuthSidebarCompanySettingsListQualityRoute;
  '/company/settings/regions': typeof AuthSidebarCompanySettingsListRegionsRoute;
  '/company/settings/responsibilities': typeof AuthSidebarCompanySettingsListResponsibilitiesRoute;
  '/company/settings/stations': typeof AuthSidebarCompanySettingsListStationsRoute;
  '/company/settings/stock-exchange': typeof AuthSidebarCompanySettingsListStockExchangeRoute;
  '/company/settings/terminals': typeof AuthSidebarCompanySettingsListTerminalsRoute;
  '/company/settings/users': typeof AuthSidebarCompanySettingsListUsersRoute;
  '/company/settings/vehicle-subtypes': typeof AuthSidebarCompanySettingsListVehicleSubtypesRoute;
  '/company/settings/vehicles': typeof AuthSidebarCompanySettingsListVehiclesRoute;
  '/company/settings/vessel-sizes': typeof AuthSidebarCompanySettingsListVesselSizesRoute;
  '/company/team/$traderId/approvals': typeof AuthSidebarCompanyTeamTraderIdApprovalsRoute;
  '/company/team/$traderId/charges': typeof AuthSidebarCompanyTeamTraderIdChargesRoute;
  '/company/team/$traderId/companies': typeof AuthSidebarCompanyTeamTraderIdCompaniesRoute;
  '/company/team/$traderId/contacts': typeof AuthSidebarCompanyTeamTraderIdContactsRoute;
  '/company/team/$traderId/contracts': typeof AuthSidebarCompanyTeamTraderIdContractsRoute;
  '/company/team/$traderId/derivatives': typeof AuthSidebarCompanyTeamTraderIdDerivativesRoute;
  '/company/team/$traderId/documents': typeof AuthSidebarCompanyTeamTraderIdDocumentsRoute;
  '/company/team/$traderId/finances': typeof AuthSidebarCompanyTeamTraderIdFinancesRoute;
  '/company/team/$traderId/history': typeof AuthSidebarCompanyTeamTraderIdHistoryRoute;
  '/company/team/$traderId/indicators': typeof AuthSidebarCompanyTeamTraderIdIndicatorsRoute;
  '/company/team/$traderId/info': typeof AuthSidebarCompanyTeamTraderIdInfoRoute;
  '/company/team/$traderId/logistics': typeof AuthSidebarCompanyTeamTraderIdLogisticsRoute;
  '/company/team/$traderId/passports': typeof AuthSidebarCompanyTeamTraderIdPassportsRoute;
  '/company/team/$traderId/settings': typeof AuthSidebarCompanyTeamTraderIdSettingsRoute;
  '/company/team/$traderId/tickets': typeof AuthSidebarCompanyTeamTraderIdTicketsRoute;
  '/company/team/$traderId/updates': typeof AuthSidebarCompanyTeamTraderIdUpdatesRoute;
  '/company/team/$traderId/voyages': typeof AuthSidebarCompanyTeamTraderIdVoyagesRoute;
  '/company/team/$traderId': typeof AuthSidebarCompanyTeamTraderIdIndexRoute;
  '/company/drivers/$driverId/documents': typeof AuthSidebarCompanyDriversDriverDriverIdDocumentsRoute;
  '/company/drivers/$driverId/history': typeof AuthSidebarCompanyDriversDriverDriverIdHistoryRoute;
  '/company/drivers/$driverId/info': typeof AuthSidebarCompanyDriversDriverDriverIdInfoRoute;
  '/company/vehicles/$vehicleId/documents': typeof AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsRoute;
  '/company/vehicles/$vehicleId/history': typeof AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryRoute;
  '/company/vehicles/$vehicleId/info': typeof AuthSidebarCompanyVehiclesVehicleVehicleIdInfoRoute;
  '/crm/counterparties/$counterpartyId/agreements': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsRoute;
  '/crm/counterparties/$counterpartyId/approvals': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsRoute;
  '/crm/counterparties/$counterpartyId/bank-details': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsRoute;
  '/crm/counterparties/$counterpartyId/contacts': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsRoute;
  '/crm/counterparties/$counterpartyId/contracts': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsRoute;
  '/crm/counterparties/$counterpartyId/costs-and-gains': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsRoute;
  '/crm/counterparties/$counterpartyId/dashboard': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardRoute;
  '/crm/counterparties/$counterpartyId/derivatives': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesRoute;
  '/crm/counterparties/$counterpartyId/documents': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsRoute;
  '/crm/counterparties/$counterpartyId/execution': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionRoute;
  '/crm/counterparties/$counterpartyId/finance': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceRoute;
  '/crm/counterparties/$counterpartyId/history': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryRoute;
  '/crm/counterparties/$counterpartyId/indicators': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsRoute;
  '/crm/counterparties/$counterpartyId/potentials': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsRoute;
  '/crm/counterparties/$counterpartyId/reconciliation': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationRoute;
  '/crm/counterparties/$counterpartyId/risk': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskRoute;
  '/crm/counterparties/$counterpartyId/tasks': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksRoute;
  '/crm/counterparties/$counterpartyId/tickets': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsRoute;
  '/deals/agreements/$agreementId/contracts': typeof AuthSidebarDealsAgreementsAgreementAgreementIdContractsRoute;
  '/deals/agreements/$agreementId/custom-fields': typeof AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsRoute;
  '/deals/agreements/$agreementId/documents': typeof AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsRoute;
  '/deals/agreements/$agreementId/execution': typeof AuthSidebarDealsAgreementsAgreementAgreementIdExecutionRoute;
  '/deals/agreements/$agreementId/history': typeof AuthSidebarDealsAgreementsAgreementAgreementIdHistoryRoute;
  '/deals/agreements/$agreementId/tickets': typeof AuthSidebarDealsAgreementsAgreementAgreementIdTicketsRoute;
  '/deals/market/$bidId/additional-agreements': typeof AuthSidebarDealsMarketBidBidIdAdditionalAgreementsRoute;
  '/deals/market/$bidId/approvals': typeof AuthSidebarDealsMarketBidBidIdApprovalsRoute;
  '/deals/market/$bidId/costs': typeof AuthSidebarDealsMarketBidBidIdCostsRoute;
  '/deals/market/$bidId/documents': typeof AuthSidebarDealsMarketBidBidIdDocumentsRoute;
  '/deals/market/$bidId/history': typeof AuthSidebarDealsMarketBidBidIdHistoryRoute;
  '/deals/market/$bidId/potential-purchase': typeof AuthSidebarDealsMarketBidBidIdPotentialPurchaseRoute;
  '/deals/market/$bidId/quality': typeof AuthSidebarDealsMarketBidBidIdQualityRoute;
  '/deals/market/$bidId/tasks': typeof AuthSidebarDealsMarketBidBidIdTasksRoute;
  '/deals/market/$bidId/telegram': typeof AuthSidebarDealsMarketBidBidIdTelegramRoute;
  '/deals/market/$offerId/additional-agreements': typeof AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsRoute;
  '/deals/market/$offerId/approvals': typeof AuthSidebarDealsMarketOfferOfferIdApprovalsRoute;
  '/deals/market/$offerId/costs': typeof AuthSidebarDealsMarketOfferOfferIdCostsRoute;
  '/deals/market/$offerId/documents': typeof AuthSidebarDealsMarketOfferOfferIdDocumentsRoute;
  '/deals/market/$offerId/history': typeof AuthSidebarDealsMarketOfferOfferIdHistoryRoute;
  '/deals/market/$offerId/quality': typeof AuthSidebarDealsMarketOfferOfferIdQualityRoute;
  '/deals/market/$offerId/tasks': typeof AuthSidebarDealsMarketOfferOfferIdTasksRoute;
  '/deals/market/$offerId/telegram': typeof AuthSidebarDealsMarketOfferOfferIdTelegramRoute;
  '/deals/multiservices/$multiserviceId/additional-agreements': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsRoute;
  '/deals/multiservices/$multiserviceId/approvals': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsRoute;
  '/deals/multiservices/$multiserviceId/closing-contract': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractRoute;
  '/deals/multiservices/$multiserviceId/costs-gains': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsRoute;
  '/deals/multiservices/$multiserviceId/dashboard': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardRoute;
  '/deals/multiservices/$multiserviceId/documents': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsRoute;
  '/deals/multiservices/$multiserviceId/execution': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionRoute;
  '/deals/multiservices/$multiserviceId/finance': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceRoute;
  '/deals/multiservices/$multiserviceId/fixing-and-hedging': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingRoute;
  '/deals/multiservices/$multiserviceId/history': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryRoute;
  '/deals/multiservices/$multiserviceId/positions': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsRoute;
  '/deals/multiservices/$multiserviceId/print-and-sign': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignRoute;
  '/deals/multiservices/$multiserviceId/profit-and-loss': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossRoute;
  '/deals/multiservices/$multiserviceId/quality': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityRoute;
  '/deals/multiservices/$multiserviceId/sale-export-contract-allocations': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsRoute;
  '/deals/multiservices/$multiserviceId/tasks': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksRoute;
  '/deals/multitickets/$multiticketId/additional-agreements': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsRoute;
  '/deals/multitickets/$multiticketId/approvals': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsRoute;
  '/deals/multitickets/$multiticketId/costs-gains': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsRoute;
  '/deals/multitickets/$multiticketId/documents': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsRoute;
  '/deals/multitickets/$multiticketId/history': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryRoute;
  '/deals/multitickets/$multiticketId/positions': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsRoute;
  '/deals/multitickets/$multiticketId/potential-purchase': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseRoute;
  '/deals/multitickets/$multiticketId/quality': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityRoute;
  '/deals/multitickets/$multiticketId/tasks': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksRoute;
  '/deals/passports/$passportId/comments': typeof AuthSidebarDealsPassportsPassportPassportIdCommentsRoute;
  '/deals/passports/$passportId/contacts': typeof AuthSidebarDealsPassportsPassportPassportIdContactsRoute;
  '/deals/passports/$passportId/contracts': typeof AuthSidebarDealsPassportsPassportPassportIdContractsRoute;
  '/deals/passports/$passportId/cost-of-goods': typeof AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsRoute;
  '/deals/passports/$passportId/dashboard': typeof AuthSidebarDealsPassportsPassportPassportIdDashboardRoute;
  '/deals/passports/$passportId/derivatives': typeof AuthSidebarDealsPassportsPassportPassportIdDerivativesRoute;
  '/deals/passports/$passportId/documents': typeof AuthSidebarDealsPassportsPassportPassportIdDocumentsRoute;
  '/deals/passports/$passportId/execution': typeof AuthSidebarDealsPassportsPassportPassportIdExecutionRoute;
  '/deals/passports/$passportId/finance': typeof AuthSidebarDealsPassportsPassportPassportIdFinanceRoute;
  '/deals/passports/$passportId/invoices': typeof AuthSidebarDealsPassportsPassportPassportIdInvoicesRoute;
  '/deals/passports/$passportId/tasks': typeof AuthSidebarDealsPassportsPassportPassportIdTasksRoute;
  '/deals/passports/$passportId/tickets': typeof AuthSidebarDealsPassportsPassportPassportIdTicketsRoute;
  '/deals/services/direct-contract/$contractId/additional-agreements': typeof AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsRoute;
  '/deals/services/direct-contract/$contractId/closing-contract': typeof AuthSidebarDealsServicesDirectContractContractIdClosingContractRoute;
  '/deals/services/direct-contract/$contractId/costs': typeof AuthSidebarDealsServicesDirectContractContractIdCostsRoute;
  '/deals/services/direct-contract/$contractId/documents': typeof AuthSidebarDealsServicesDirectContractContractIdDocumentsRoute;
  '/deals/services/direct-contract/$contractId/execution': typeof AuthSidebarDealsServicesDirectContractContractIdExecutionRoute;
  '/deals/services/direct-contract/$contractId/finance': typeof AuthSidebarDealsServicesDirectContractContractIdFinanceRoute;
  '/deals/services/direct-contract/$contractId/fixing-and-hedging': typeof AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingRoute;
  '/deals/services/direct-contract/$contractId/history': typeof AuthSidebarDealsServicesDirectContractContractIdHistoryRoute;
  '/deals/services/direct-contract/$contractId/quality': typeof AuthSidebarDealsServicesDirectContractContractIdQualityRoute;
  '/deals/services/direct-contract/$contractId/sale-export-contract-allocations': typeof AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsRoute;
  '/deals/services/direct-contract/$contractId/tasks': typeof AuthSidebarDealsServicesDirectContractContractIdTasksRoute;
  '/deals/services/general-contract/$contractId/additional-agreements': typeof AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsRoute;
  '/deals/services/general-contract/$contractId/closing-contract': typeof AuthSidebarDealsServicesGeneralContractContractIdClosingContractRoute;
  '/deals/services/general-contract/$contractId/costs': typeof AuthSidebarDealsServicesGeneralContractContractIdCostsRoute;
  '/deals/services/general-contract/$contractId/documents': typeof AuthSidebarDealsServicesGeneralContractContractIdDocumentsRoute;
  '/deals/services/general-contract/$contractId/execution': typeof AuthSidebarDealsServicesGeneralContractContractIdExecutionRoute;
  '/deals/services/general-contract/$contractId/finance': typeof AuthSidebarDealsServicesGeneralContractContractIdFinanceRoute;
  '/deals/services/general-contract/$contractId/fixing-and-hedging': typeof AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingRoute;
  '/deals/services/general-contract/$contractId/history': typeof AuthSidebarDealsServicesGeneralContractContractIdHistoryRoute;
  '/deals/services/general-contract/$contractId/quality': typeof AuthSidebarDealsServicesGeneralContractContractIdQualityRoute;
  '/deals/services/general-contract/$contractId/sale-export-contract-allocations': typeof AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsRoute;
  '/deals/services/general-contract/$contractId/tasks': typeof AuthSidebarDealsServicesGeneralContractContractIdTasksRoute;
  '/deals/tickets/purchase/$ticketId/additional-agreements': typeof AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsRoute;
  '/deals/tickets/purchase/$ticketId/approvals': typeof AuthSidebarDealsTicketsPurchaseTicketIdApprovalsRoute;
  '/deals/tickets/purchase/$ticketId/costs': typeof AuthSidebarDealsTicketsPurchaseTicketIdCostsRoute;
  '/deals/tickets/purchase/$ticketId/documents': typeof AuthSidebarDealsTicketsPurchaseTicketIdDocumentsRoute;
  '/deals/tickets/purchase/$ticketId/history': typeof AuthSidebarDealsTicketsPurchaseTicketIdHistoryRoute;
  '/deals/tickets/purchase/$ticketId/quality': typeof AuthSidebarDealsTicketsPurchaseTicketIdQualityRoute;
  '/deals/tickets/purchase/$ticketId/tasks': typeof AuthSidebarDealsTicketsPurchaseTicketIdTasksRoute;
  '/deals/tickets/purchase/$ticketId/telegram': typeof AuthSidebarDealsTicketsPurchaseTicketIdTelegramRoute;
  '/deals/tickets/sale/$ticketId/additional-agreements': typeof AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsRoute;
  '/deals/tickets/sale/$ticketId/costs': typeof AuthSidebarDealsTicketsSaleTicketIdCostsRoute;
  '/deals/tickets/sale/$ticketId/documents': typeof AuthSidebarDealsTicketsSaleTicketIdDocumentsRoute;
  '/deals/tickets/sale/$ticketId/history': typeof AuthSidebarDealsTicketsSaleTicketIdHistoryRoute;
  '/deals/tickets/sale/$ticketId/potential-purchase': typeof AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseRoute;
  '/deals/tickets/sale/$ticketId/quality': typeof AuthSidebarDealsTicketsSaleTicketIdQualityRoute;
  '/deals/tickets/sale/$ticketId/tasks': typeof AuthSidebarDealsTicketsSaleTicketIdTasksRoute;
  '/deals/tickets/sale/$ticketId/telegram': typeof AuthSidebarDealsTicketsSaleTicketIdTelegramRoute;
  '/deals/services/direct-contract/$contractId': typeof AuthSidebarDealsServicesDirectContractContractIdIndexRoute;
  '/deals/services/general-contract/$contractId': typeof AuthSidebarDealsServicesGeneralContractContractIdIndexRoute;
  '/deals/tickets/purchase/$ticketId': typeof AuthSidebarDealsTicketsPurchaseTicketIdIndexRoute;
  '/deals/tickets/sale/$ticketId': typeof AuthSidebarDealsTicketsSaleTicketIdIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/401': typeof R401Route;
  '/403': typeof R403Route;
  '/404': typeof R404Route;
  '/500': typeof R500Route;
  '/_auth': typeof AuthRouteWithChildren;
  '/login': typeof LoginRoute;
  '/reset-password': typeof ResetPasswordRoute;
  '/_auth/_sidebar': typeof AuthSidebarRouteWithChildren;
  '/_auth/': typeof AuthIndexRoute;
  '/_auth/_sidebar/start': typeof AuthSidebarStartRoute;
  '/_auth/_sidebar/company/custom-fields': typeof AuthSidebarCompanyCustomFieldsRoute;
  '/_auth/_sidebar/company/documents': typeof AuthSidebarCompanyDocumentsRoute;
  '/_auth/_sidebar/company/drivers': typeof AuthSidebarCompanyDriversRouteWithChildren;
  '/_auth/_sidebar/company/notifications': typeof AuthSidebarCompanyNotificationsRoute;
  '/_auth/_sidebar/company/quality-rule-templates': typeof AuthSidebarCompanyQualityRuleTemplatesRoute;
  '/_auth/_sidebar/company/settings': typeof AuthSidebarCompanySettingsRouteWithChildren;
  '/_auth/_sidebar/company/tasks-templates': typeof AuthSidebarCompanyTasksTemplatesRoute;
  '/_auth/_sidebar/company/template-playground': typeof AuthSidebarCompanyTemplatePlaygroundRoute;
  '/_auth/_sidebar/company/vehicles': typeof AuthSidebarCompanyVehiclesRouteWithChildren;
  '/_auth/_sidebar/crm/contacts': typeof AuthSidebarCrmContactsRoute;
  '/_auth/_sidebar/crm/counterparties': typeof AuthSidebarCrmCounterpartiesRouteWithChildren;
  '/_auth/_sidebar/crm/updates': typeof AuthSidebarCrmUpdatesRoute;
  '/_auth/_sidebar/deals/agreements': typeof AuthSidebarDealsAgreementsRouteWithChildren;
  '/_auth/_sidebar/deals/calculator': typeof AuthSidebarDealsCalculatorRoute;
  '/_auth/_sidebar/deals/contracts': typeof AuthSidebarDealsContractsRoute;
  '/_auth/_sidebar/deals/market': typeof AuthSidebarDealsMarketRouteWithChildren;
  '/_auth/_sidebar/deals/multicontracts': typeof AuthSidebarDealsMulticontractsRoute;
  '/_auth/_sidebar/deals/multiservices': typeof AuthSidebarDealsMultiservicesRouteWithChildren;
  '/_auth/_sidebar/deals/multitickets': typeof AuthSidebarDealsMultiticketsRouteWithChildren;
  '/_auth/_sidebar/deals/passports': typeof AuthSidebarDealsPassportsRouteWithChildren;
  '/_auth/_sidebar/deals/potentials': typeof AuthSidebarDealsPotentialsRoute;
  '/_auth/_sidebar/deals/quotations': typeof AuthSidebarDealsQuotationsRoute;
  '/_auth/_sidebar/deals/services': typeof AuthSidebarDealsServicesRouteWithChildren;
  '/_auth/_sidebar/deals/tickets': typeof AuthSidebarDealsTicketsRouteWithChildren;
  '/_auth/_sidebar/execution/analytics': typeof AuthSidebarExecutionAnalyticsRoute;
  '/_auth/_sidebar/execution/bills-of-lading': typeof AuthSidebarExecutionBillsOfLadingRoute;
  '/_auth/_sidebar/execution/consignments': typeof AuthSidebarExecutionConsignmentsRoute;
  '/_auth/_sidebar/execution/customs-declarations': typeof AuthSidebarExecutionCustomsDeclarationsRoute;
  '/_auth/_sidebar/execution/disbursement-bill-of-lading': typeof AuthSidebarExecutionDisbursementBillOfLadingRoute;
  '/_auth/_sidebar/execution/planning': typeof AuthSidebarExecutionPlanningRoute;
  '/_auth/_sidebar/execution/samples': typeof AuthSidebarExecutionSamplesRoute;
  '/_auth/_sidebar/execution/tariffs': typeof AuthSidebarExecutionTariffsRoute;
  '/_auth/_sidebar/execution/transport': typeof AuthSidebarExecutionTransportRoute;
  '/_auth/_sidebar/execution/vessels': typeof AuthSidebarExecutionVesselsRoute;
  '/_auth/_sidebar/execution/warehouse': typeof AuthSidebarExecutionWarehouseRoute;
  '/_auth/_sidebar/finance/accounting': typeof AuthSidebarFinanceAccountingRoute;
  '/_auth/_sidebar/finance/analytics': typeof AuthSidebarFinanceAnalyticsRoute;
  '/_auth/_sidebar/finance/bank-accounts': typeof AuthSidebarFinanceBankAccountsRoute;
  '/_auth/_sidebar/finance/charges-or-gains': typeof AuthSidebarFinanceChargesOrGainsRoute;
  '/_auth/_sidebar/finance/invoice-positions': typeof AuthSidebarFinanceInvoicePositionsRoute;
  '/_auth/_sidebar/finance/invoices': typeof AuthSidebarFinanceInvoicesRoute;
  '/_auth/_sidebar/finance/offset': typeof AuthSidebarFinanceOffsetRoute;
  '/_auth/_sidebar/finance/payment-delays': typeof AuthSidebarFinancePaymentDelaysRoute;
  '/_auth/_sidebar/finance/payment-plans': typeof AuthSidebarFinancePaymentPlansRoute;
  '/_auth/_sidebar/finance/payments': typeof AuthSidebarFinancePaymentsRoute;
  '/_auth/_sidebar/finance/reasignment': typeof AuthSidebarFinanceReasignmentRoute;
  '/_auth/_sidebar/reports/dashboard': typeof AuthSidebarReportsDashboardRoute;
  '/_auth/_sidebar/company/drivers/_driver': typeof AuthSidebarCompanyDriversDriverRouteWithChildren;
  '/_auth/_sidebar/company/settings/organization': typeof AuthSidebarCompanySettingsOrganizationRoute;
  '/_auth/_sidebar/company/settings/parsing': typeof AuthSidebarCompanySettingsParsingRoute;
  '/_auth/_sidebar/company/settings/personal': typeof AuthSidebarCompanySettingsPersonalRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty': typeof AuthSidebarCrmCounterpartiesCounterpartyRouteWithChildren;
  '/_auth/_sidebar/crm/newsletters/invoices': typeof AuthSidebarCrmNewslettersInvoicesRoute;
  '/_auth/_sidebar/crm/newsletters/logistics': typeof AuthSidebarCrmNewslettersLogisticsRoute;
  '/_auth/_sidebar/crm/newsletters/templates': typeof AuthSidebarCrmNewslettersTemplatesRoute;
  '/_auth/_sidebar/deals/agreements/_agreement': typeof AuthSidebarDealsAgreementsAgreementRouteWithChildren;
  '/_auth/_sidebar/deals/market/_bid': typeof AuthSidebarDealsMarketBidRouteWithChildren;
  '/_auth/_sidebar/deals/market/_offer': typeof AuthSidebarDealsMarketOfferRouteWithChildren;
  '/_auth/_sidebar/deals/market/bids': typeof AuthSidebarDealsMarketBidsRoute;
  '/_auth/_sidebar/deals/market/offers': typeof AuthSidebarDealsMarketOffersRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice': typeof AuthSidebarDealsMultiservicesMultiserviceRouteWithChildren;
  '/_auth/_sidebar/deals/multitickets/_multiticket': typeof AuthSidebarDealsMultiticketsMultiticketRouteWithChildren;
  '/_auth/_sidebar/deals/passports/_passport': typeof AuthSidebarDealsPassportsPassportRouteWithChildren;
  '/_auth/_sidebar/deals/services/direct-contracts': typeof AuthSidebarDealsServicesDirectContractsRoute;
  '/_auth/_sidebar/deals/services/general-contracts': typeof AuthSidebarDealsServicesGeneralContractsRoute;
  '/_auth/_sidebar/deals/tickets/purchase-tickets': typeof AuthSidebarDealsTicketsPurchaseTicketsRoute;
  '/_auth/_sidebar/deals/tickets/sale-tickets': typeof AuthSidebarDealsTicketsSaleTicketsRoute;
  '/_auth/_sidebar/reports/finance-reports/ap-ar': typeof AuthSidebarReportsFinanceReportsApArRoute;
  '/_auth/_sidebar/reports/finance-reports/balance': typeof AuthSidebarReportsFinanceReportsBalanceRoute;
  '/_auth/_sidebar/reports/finance-reports/budgets': typeof AuthSidebarReportsFinanceReportsBudgetsRoute;
  '/_auth/_sidebar/reports/finance-reports/cash-flow': typeof AuthSidebarReportsFinanceReportsCashFlowRoute;
  '/_auth/_sidebar/reports/finance-reports/costs-report': typeof AuthSidebarReportsFinanceReportsCostsReportRoute;
  '/_auth/_sidebar/reports/finance-reports/passports-pnl': typeof AuthSidebarReportsFinanceReportsPassportsPnlRoute;
  '/_auth/_sidebar/reports/finance-reports/reconciliations': typeof AuthSidebarReportsFinanceReportsReconciliationsRoute;
  '/_auth/_sidebar/reports/finance-reports/statistics': typeof AuthSidebarReportsFinanceReportsStatisticsRoute;
  '/_auth/_sidebar/reports/management-reports/activity': typeof AuthSidebarReportsManagementReportsActivityRoute;
  '/_auth/_sidebar/reports/management-reports/approvals': typeof AuthSidebarReportsManagementReportsApprovalsRoute;
  '/_auth/_sidebar/reports/management-reports/compliance': typeof AuthSidebarReportsManagementReportsComplianceRoute;
  '/_auth/_sidebar/reports/management-reports/documents-dates': typeof AuthSidebarReportsManagementReportsDocumentsDatesRoute;
  '/_auth/_sidebar/reports/management-reports/kpi': typeof AuthSidebarReportsManagementReportsKpiRoute;
  '/_auth/_sidebar/reports/management-reports/signings': typeof AuthSidebarReportsManagementReportsSigningsRoute;
  '/_auth/_sidebar/reports/management-reports/team': typeof AuthSidebarReportsManagementReportsTeamRoute;
  '/_auth/_sidebar/reports/risk-reports/credit-report': typeof AuthSidebarReportsRiskReportsCreditReportRoute;
  '/_auth/_sidebar/reports/risk-reports/invoices-risk': typeof AuthSidebarReportsRiskReportsInvoicesRiskRoute;
  '/_auth/_sidebar/reports/trading-reports/contracts-execution': typeof AuthSidebarReportsTradingReportsContractsExecutionRoute;
  '/_auth/_sidebar/reports/trading-reports/derivatives': typeof AuthSidebarReportsTradingReportsDerivativesRoute;
  '/_auth/_sidebar/reports/trading-reports/fixing-or-hedging': typeof AuthSidebarReportsTradingReportsFixingOrHedgingRoute;
  '/_auth/_sidebar/reports/trading-reports/freight': typeof AuthSidebarReportsTradingReportsFreightRoute;
  '/_auth/_sidebar/reports/trading-reports/line-up': typeof AuthSidebarReportsTradingReportsLineUpRoute;
  '/_auth/_sidebar/reports/trading-reports/physical-position-by-months': typeof AuthSidebarReportsTradingReportsPhysicalPositionByMonthsRoute;
  '/_auth/_sidebar/reports/trading-reports/physical-position-mtm': typeof AuthSidebarReportsTradingReportsPhysicalPositionMtmRoute;
  '/_auth/_sidebar/reports/trading-reports/position-pl-and-mtm': typeof AuthSidebarReportsTradingReportsPositionPlAndMtmRoute;
  '/_auth/_sidebar/company/team/': typeof AuthSidebarCompanyTeamIndexRoute;
  '/_auth/_sidebar/company/settings/_list/addresses': typeof AuthSidebarCompanySettingsListAddressesRoute;
  '/_auth/_sidebar/company/settings/_list/bots': typeof AuthSidebarCompanySettingsListBotsRoute;
  '/_auth/_sidebar/company/settings/_list/chats': typeof AuthSidebarCompanySettingsListChatsRoute;
  '/_auth/_sidebar/company/settings/_list/cities': typeof AuthSidebarCompanySettingsListCitiesRoute;
  '/_auth/_sidebar/company/settings/_list/client-chats': typeof AuthSidebarCompanySettingsListClientChatsRoute;
  '/_auth/_sidebar/company/settings/_list/commodities': typeof AuthSidebarCompanySettingsListCommoditiesRoute;
  '/_auth/_sidebar/company/settings/_list/company-groups': typeof AuthSidebarCompanySettingsListCompanyGroupsRoute;
  '/_auth/_sidebar/company/settings/_list/configs': typeof AuthSidebarCompanySettingsListConfigsRoute;
  '/_auth/_sidebar/company/settings/_list/contract-charges': typeof AuthSidebarCompanySettingsListContractChargesRoute;
  '/_auth/_sidebar/company/settings/_list/countries': typeof AuthSidebarCompanySettingsListCountriesRoute;
  '/_auth/_sidebar/company/settings/_list/currencies': typeof AuthSidebarCompanySettingsListCurrenciesRoute;
  '/_auth/_sidebar/company/settings/_list/delivery-conditions': typeof AuthSidebarCompanySettingsListDeliveryConditionsRoute;
  '/_auth/_sidebar/company/settings/_list/derivative-accounts': typeof AuthSidebarCompanySettingsListDerivativeAccountsRoute;
  '/_auth/_sidebar/company/settings/_list/derivatives': typeof AuthSidebarCompanySettingsListDerivativesRoute;
  '/_auth/_sidebar/company/settings/_list/districts': typeof AuthSidebarCompanySettingsListDistrictsRoute;
  '/_auth/_sidebar/company/settings/_list/documents': typeof AuthSidebarCompanySettingsListDocumentsRoute;
  '/_auth/_sidebar/company/settings/_list/drivers': typeof AuthSidebarCompanySettingsListDriversRoute;
  '/_auth/_sidebar/company/settings/_list/exchanges': typeof AuthSidebarCompanySettingsListExchangesRoute;
  '/_auth/_sidebar/company/settings/_list/finance-accounts': typeof AuthSidebarCompanySettingsListFinanceAccountsRoute;
  '/_auth/_sidebar/company/settings/_list/finance-bank': typeof AuthSidebarCompanySettingsListFinanceBankRoute;
  '/_auth/_sidebar/company/settings/_list/general-expenses': typeof AuthSidebarCompanySettingsListGeneralExpensesRoute;
  '/_auth/_sidebar/company/settings/_list/groups': typeof AuthSidebarCompanySettingsListGroupsRoute;
  '/_auth/_sidebar/company/settings/_list/logistic-types': typeof AuthSidebarCompanySettingsListLogisticTypesRoute;
  '/_auth/_sidebar/company/settings/_list/newsletter': typeof AuthSidebarCompanySettingsListNewsletterRoute;
  '/_auth/_sidebar/company/settings/_list/other-activities': typeof AuthSidebarCompanySettingsListOtherActivitiesRoute;
  '/_auth/_sidebar/company/settings/_list/payment-info': typeof AuthSidebarCompanySettingsListPaymentInfoRoute;
  '/_auth/_sidebar/company/settings/_list/ports': typeof AuthSidebarCompanySettingsListPortsRoute;
  '/_auth/_sidebar/company/settings/_list/quality': typeof AuthSidebarCompanySettingsListQualityRoute;
  '/_auth/_sidebar/company/settings/_list/regions': typeof AuthSidebarCompanySettingsListRegionsRoute;
  '/_auth/_sidebar/company/settings/_list/responsibilities': typeof AuthSidebarCompanySettingsListResponsibilitiesRoute;
  '/_auth/_sidebar/company/settings/_list/stations': typeof AuthSidebarCompanySettingsListStationsRoute;
  '/_auth/_sidebar/company/settings/_list/stock-exchange': typeof AuthSidebarCompanySettingsListStockExchangeRoute;
  '/_auth/_sidebar/company/settings/_list/terminals': typeof AuthSidebarCompanySettingsListTerminalsRoute;
  '/_auth/_sidebar/company/settings/_list/users': typeof AuthSidebarCompanySettingsListUsersRoute;
  '/_auth/_sidebar/company/settings/_list/vehicle-subtypes': typeof AuthSidebarCompanySettingsListVehicleSubtypesRoute;
  '/_auth/_sidebar/company/settings/_list/vehicles': typeof AuthSidebarCompanySettingsListVehiclesRoute;
  '/_auth/_sidebar/company/settings/_list/vessel-sizes': typeof AuthSidebarCompanySettingsListVesselSizesRoute;
  '/_auth/_sidebar/company/team/$traderId/approvals': typeof AuthSidebarCompanyTeamTraderIdApprovalsRoute;
  '/_auth/_sidebar/company/team/$traderId/charges': typeof AuthSidebarCompanyTeamTraderIdChargesRoute;
  '/_auth/_sidebar/company/team/$traderId/companies': typeof AuthSidebarCompanyTeamTraderIdCompaniesRoute;
  '/_auth/_sidebar/company/team/$traderId/contacts': typeof AuthSidebarCompanyTeamTraderIdContactsRoute;
  '/_auth/_sidebar/company/team/$traderId/contracts': typeof AuthSidebarCompanyTeamTraderIdContractsRoute;
  '/_auth/_sidebar/company/team/$traderId/derivatives': typeof AuthSidebarCompanyTeamTraderIdDerivativesRoute;
  '/_auth/_sidebar/company/team/$traderId/documents': typeof AuthSidebarCompanyTeamTraderIdDocumentsRoute;
  '/_auth/_sidebar/company/team/$traderId/finances': typeof AuthSidebarCompanyTeamTraderIdFinancesRoute;
  '/_auth/_sidebar/company/team/$traderId/history': typeof AuthSidebarCompanyTeamTraderIdHistoryRoute;
  '/_auth/_sidebar/company/team/$traderId/indicators': typeof AuthSidebarCompanyTeamTraderIdIndicatorsRoute;
  '/_auth/_sidebar/company/team/$traderId/info': typeof AuthSidebarCompanyTeamTraderIdInfoRoute;
  '/_auth/_sidebar/company/team/$traderId/logistics': typeof AuthSidebarCompanyTeamTraderIdLogisticsRoute;
  '/_auth/_sidebar/company/team/$traderId/passports': typeof AuthSidebarCompanyTeamTraderIdPassportsRoute;
  '/_auth/_sidebar/company/team/$traderId/settings': typeof AuthSidebarCompanyTeamTraderIdSettingsRoute;
  '/_auth/_sidebar/company/team/$traderId/tickets': typeof AuthSidebarCompanyTeamTraderIdTicketsRoute;
  '/_auth/_sidebar/company/team/$traderId/updates': typeof AuthSidebarCompanyTeamTraderIdUpdatesRoute;
  '/_auth/_sidebar/company/team/$traderId/voyages': typeof AuthSidebarCompanyTeamTraderIdVoyagesRoute;
  '/_auth/_sidebar/company/team/$traderId/': typeof AuthSidebarCompanyTeamTraderIdIndexRoute;
  '/_auth/_sidebar/company/drivers/_driver/$driverId/documents': typeof AuthSidebarCompanyDriversDriverDriverIdDocumentsRoute;
  '/_auth/_sidebar/company/drivers/_driver/$driverId/history': typeof AuthSidebarCompanyDriversDriverDriverIdHistoryRoute;
  '/_auth/_sidebar/company/drivers/_driver/$driverId/info': typeof AuthSidebarCompanyDriversDriverDriverIdInfoRoute;
  '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/documents': typeof AuthSidebarCompanyVehiclesVehicleVehicleIdDocumentsRoute;
  '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/history': typeof AuthSidebarCompanyVehiclesVehicleVehicleIdHistoryRoute;
  '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/info': typeof AuthSidebarCompanyVehiclesVehicleVehicleIdInfoRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/agreements': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdAgreementsRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/approvals': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdApprovalsRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/bank-details': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdBankDetailsRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contacts': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContactsRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contracts': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdContractsRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/costs-and-gains': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdCostsAndGainsRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/dashboard': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDashboardRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/derivatives': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDerivativesRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/documents': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdDocumentsRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/execution': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdExecutionRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/finance': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdFinanceRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/history': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdHistoryRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/indicators': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdIndicatorsRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/potentials': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdPotentialsRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/reconciliation': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdReconciliationRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/risk': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdRiskRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tasks': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTasksRoute;
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tickets': typeof AuthSidebarCrmCounterpartiesCounterpartyCounterpartyIdTicketsRoute;
  '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/contracts': typeof AuthSidebarDealsAgreementsAgreementAgreementIdContractsRoute;
  '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/custom-fields': typeof AuthSidebarDealsAgreementsAgreementAgreementIdCustomFieldsRoute;
  '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/documents': typeof AuthSidebarDealsAgreementsAgreementAgreementIdDocumentsRoute;
  '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/execution': typeof AuthSidebarDealsAgreementsAgreementAgreementIdExecutionRoute;
  '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/history': typeof AuthSidebarDealsAgreementsAgreementAgreementIdHistoryRoute;
  '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/tickets': typeof AuthSidebarDealsAgreementsAgreementAgreementIdTicketsRoute;
  '/_auth/_sidebar/deals/market/_bid/$bidId/additional-agreements': typeof AuthSidebarDealsMarketBidBidIdAdditionalAgreementsRoute;
  '/_auth/_sidebar/deals/market/_bid/$bidId/approvals': typeof AuthSidebarDealsMarketBidBidIdApprovalsRoute;
  '/_auth/_sidebar/deals/market/_bid/$bidId/costs': typeof AuthSidebarDealsMarketBidBidIdCostsRoute;
  '/_auth/_sidebar/deals/market/_bid/$bidId/documents': typeof AuthSidebarDealsMarketBidBidIdDocumentsRoute;
  '/_auth/_sidebar/deals/market/_bid/$bidId/history': typeof AuthSidebarDealsMarketBidBidIdHistoryRoute;
  '/_auth/_sidebar/deals/market/_bid/$bidId/potential-purchase': typeof AuthSidebarDealsMarketBidBidIdPotentialPurchaseRoute;
  '/_auth/_sidebar/deals/market/_bid/$bidId/quality': typeof AuthSidebarDealsMarketBidBidIdQualityRoute;
  '/_auth/_sidebar/deals/market/_bid/$bidId/tasks': typeof AuthSidebarDealsMarketBidBidIdTasksRoute;
  '/_auth/_sidebar/deals/market/_bid/$bidId/telegram': typeof AuthSidebarDealsMarketBidBidIdTelegramRoute;
  '/_auth/_sidebar/deals/market/_offer/$offerId/additional-agreements': typeof AuthSidebarDealsMarketOfferOfferIdAdditionalAgreementsRoute;
  '/_auth/_sidebar/deals/market/_offer/$offerId/approvals': typeof AuthSidebarDealsMarketOfferOfferIdApprovalsRoute;
  '/_auth/_sidebar/deals/market/_offer/$offerId/costs': typeof AuthSidebarDealsMarketOfferOfferIdCostsRoute;
  '/_auth/_sidebar/deals/market/_offer/$offerId/documents': typeof AuthSidebarDealsMarketOfferOfferIdDocumentsRoute;
  '/_auth/_sidebar/deals/market/_offer/$offerId/history': typeof AuthSidebarDealsMarketOfferOfferIdHistoryRoute;
  '/_auth/_sidebar/deals/market/_offer/$offerId/quality': typeof AuthSidebarDealsMarketOfferOfferIdQualityRoute;
  '/_auth/_sidebar/deals/market/_offer/$offerId/tasks': typeof AuthSidebarDealsMarketOfferOfferIdTasksRoute;
  '/_auth/_sidebar/deals/market/_offer/$offerId/telegram': typeof AuthSidebarDealsMarketOfferOfferIdTelegramRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/additional-agreements': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdAdditionalAgreementsRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/approvals': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdApprovalsRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/closing-contract': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdClosingContractRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/costs-gains': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdCostsGainsRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/dashboard': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDashboardRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/documents': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdDocumentsRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/execution': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdExecutionRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/finance': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFinanceRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/fixing-and-hedging': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdFixingAndHedgingRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/history': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdHistoryRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/positions': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPositionsRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/print-and-sign': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdPrintAndSignRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/profit-and-loss': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdProfitAndLossRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/quality': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdQualityRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/sale-export-contract-allocations': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdSaleExportContractAllocationsRoute;
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/tasks': typeof AuthSidebarDealsMultiservicesMultiserviceMultiserviceIdTasksRoute;
  '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/additional-agreements': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdAdditionalAgreementsRoute;
  '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/approvals': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdApprovalsRoute;
  '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/costs-gains': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdCostsGainsRoute;
  '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/documents': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdDocumentsRoute;
  '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/history': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdHistoryRoute;
  '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/positions': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdPositionsRoute;
  '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/potential-purchase': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdPotentialPurchaseRoute;
  '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/quality': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdQualityRoute;
  '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/tasks': typeof AuthSidebarDealsMultiticketsMultiticketMultiticketIdTasksRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/comments': typeof AuthSidebarDealsPassportsPassportPassportIdCommentsRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/contacts': typeof AuthSidebarDealsPassportsPassportPassportIdContactsRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/contracts': typeof AuthSidebarDealsPassportsPassportPassportIdContractsRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/cost-of-goods': typeof AuthSidebarDealsPassportsPassportPassportIdCostOfGoodsRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/dashboard': typeof AuthSidebarDealsPassportsPassportPassportIdDashboardRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/derivatives': typeof AuthSidebarDealsPassportsPassportPassportIdDerivativesRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/documents': typeof AuthSidebarDealsPassportsPassportPassportIdDocumentsRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/execution': typeof AuthSidebarDealsPassportsPassportPassportIdExecutionRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/finance': typeof AuthSidebarDealsPassportsPassportPassportIdFinanceRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/invoices': typeof AuthSidebarDealsPassportsPassportPassportIdInvoicesRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/tasks': typeof AuthSidebarDealsPassportsPassportPassportIdTasksRoute;
  '/_auth/_sidebar/deals/passports/_passport/$passportId/tickets': typeof AuthSidebarDealsPassportsPassportPassportIdTicketsRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/additional-agreements': typeof AuthSidebarDealsServicesDirectContractContractIdAdditionalAgreementsRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/closing-contract': typeof AuthSidebarDealsServicesDirectContractContractIdClosingContractRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/costs': typeof AuthSidebarDealsServicesDirectContractContractIdCostsRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/documents': typeof AuthSidebarDealsServicesDirectContractContractIdDocumentsRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/execution': typeof AuthSidebarDealsServicesDirectContractContractIdExecutionRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/finance': typeof AuthSidebarDealsServicesDirectContractContractIdFinanceRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/fixing-and-hedging': typeof AuthSidebarDealsServicesDirectContractContractIdFixingAndHedgingRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/history': typeof AuthSidebarDealsServicesDirectContractContractIdHistoryRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/quality': typeof AuthSidebarDealsServicesDirectContractContractIdQualityRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/sale-export-contract-allocations': typeof AuthSidebarDealsServicesDirectContractContractIdSaleExportContractAllocationsRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/tasks': typeof AuthSidebarDealsServicesDirectContractContractIdTasksRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/additional-agreements': typeof AuthSidebarDealsServicesGeneralContractContractIdAdditionalAgreementsRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/closing-contract': typeof AuthSidebarDealsServicesGeneralContractContractIdClosingContractRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/costs': typeof AuthSidebarDealsServicesGeneralContractContractIdCostsRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/documents': typeof AuthSidebarDealsServicesGeneralContractContractIdDocumentsRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/execution': typeof AuthSidebarDealsServicesGeneralContractContractIdExecutionRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/finance': typeof AuthSidebarDealsServicesGeneralContractContractIdFinanceRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/fixing-and-hedging': typeof AuthSidebarDealsServicesGeneralContractContractIdFixingAndHedgingRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/history': typeof AuthSidebarDealsServicesGeneralContractContractIdHistoryRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/quality': typeof AuthSidebarDealsServicesGeneralContractContractIdQualityRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/sale-export-contract-allocations': typeof AuthSidebarDealsServicesGeneralContractContractIdSaleExportContractAllocationsRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/tasks': typeof AuthSidebarDealsServicesGeneralContractContractIdTasksRoute;
  '/_auth/_sidebar/deals/tickets/purchase/$ticketId/additional-agreements': typeof AuthSidebarDealsTicketsPurchaseTicketIdAdditionalAgreementsRoute;
  '/_auth/_sidebar/deals/tickets/purchase/$ticketId/approvals': typeof AuthSidebarDealsTicketsPurchaseTicketIdApprovalsRoute;
  '/_auth/_sidebar/deals/tickets/purchase/$ticketId/costs': typeof AuthSidebarDealsTicketsPurchaseTicketIdCostsRoute;
  '/_auth/_sidebar/deals/tickets/purchase/$ticketId/documents': typeof AuthSidebarDealsTicketsPurchaseTicketIdDocumentsRoute;
  '/_auth/_sidebar/deals/tickets/purchase/$ticketId/history': typeof AuthSidebarDealsTicketsPurchaseTicketIdHistoryRoute;
  '/_auth/_sidebar/deals/tickets/purchase/$ticketId/quality': typeof AuthSidebarDealsTicketsPurchaseTicketIdQualityRoute;
  '/_auth/_sidebar/deals/tickets/purchase/$ticketId/tasks': typeof AuthSidebarDealsTicketsPurchaseTicketIdTasksRoute;
  '/_auth/_sidebar/deals/tickets/purchase/$ticketId/telegram': typeof AuthSidebarDealsTicketsPurchaseTicketIdTelegramRoute;
  '/_auth/_sidebar/deals/tickets/sale/$ticketId/additional-agreements': typeof AuthSidebarDealsTicketsSaleTicketIdAdditionalAgreementsRoute;
  '/_auth/_sidebar/deals/tickets/sale/$ticketId/costs': typeof AuthSidebarDealsTicketsSaleTicketIdCostsRoute;
  '/_auth/_sidebar/deals/tickets/sale/$ticketId/documents': typeof AuthSidebarDealsTicketsSaleTicketIdDocumentsRoute;
  '/_auth/_sidebar/deals/tickets/sale/$ticketId/history': typeof AuthSidebarDealsTicketsSaleTicketIdHistoryRoute;
  '/_auth/_sidebar/deals/tickets/sale/$ticketId/potential-purchase': typeof AuthSidebarDealsTicketsSaleTicketIdPotentialPurchaseRoute;
  '/_auth/_sidebar/deals/tickets/sale/$ticketId/quality': typeof AuthSidebarDealsTicketsSaleTicketIdQualityRoute;
  '/_auth/_sidebar/deals/tickets/sale/$ticketId/tasks': typeof AuthSidebarDealsTicketsSaleTicketIdTasksRoute;
  '/_auth/_sidebar/deals/tickets/sale/$ticketId/telegram': typeof AuthSidebarDealsTicketsSaleTicketIdTelegramRoute;
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/': typeof AuthSidebarDealsServicesDirectContractContractIdIndexRoute;
  '/_auth/_sidebar/deals/services/general-contract/$contractId/': typeof AuthSidebarDealsServicesGeneralContractContractIdIndexRoute;
  '/_auth/_sidebar/deals/tickets/purchase/$ticketId/': typeof AuthSidebarDealsTicketsPurchaseTicketIdIndexRoute;
  '/_auth/_sidebar/deals/tickets/sale/$ticketId/': typeof AuthSidebarDealsTicketsSaleTicketIdIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/401'
    | '/403'
    | '/404'
    | '/500'
    | ''
    | '/login'
    | '/reset-password'
    | '/'
    | '/start'
    | '/company/custom-fields'
    | '/company/documents'
    | '/company/drivers'
    | '/company/notifications'
    | '/company/quality-rule-templates'
    | '/company/settings'
    | '/company/tasks-templates'
    | '/company/template-playground'
    | '/company/vehicles'
    | '/crm/contacts'
    | '/crm/counterparties'
    | '/crm/updates'
    | '/deals/agreements'
    | '/deals/calculator'
    | '/deals/contracts'
    | '/deals/market'
    | '/deals/multicontracts'
    | '/deals/multiservices'
    | '/deals/multitickets'
    | '/deals/passports'
    | '/deals/potentials'
    | '/deals/quotations'
    | '/deals/services'
    | '/deals/tickets'
    | '/execution/analytics'
    | '/execution/bills-of-lading'
    | '/execution/consignments'
    | '/execution/customs-declarations'
    | '/execution/disbursement-bill-of-lading'
    | '/execution/planning'
    | '/execution/samples'
    | '/execution/tariffs'
    | '/execution/transport'
    | '/execution/vessels'
    | '/execution/warehouse'
    | '/finance/accounting'
    | '/finance/analytics'
    | '/finance/bank-accounts'
    | '/finance/charges-or-gains'
    | '/finance/invoice-positions'
    | '/finance/invoices'
    | '/finance/offset'
    | '/finance/payment-delays'
    | '/finance/payment-plans'
    | '/finance/payments'
    | '/finance/reasignment'
    | '/reports/dashboard'
    | '/company/settings/organization'
    | '/company/settings/parsing'
    | '/company/settings/personal'
    | '/crm/newsletters/invoices'
    | '/crm/newsletters/logistics'
    | '/crm/newsletters/templates'
    | '/deals/market/bids'
    | '/deals/market/offers'
    | '/deals/services/direct-contracts'
    | '/deals/services/general-contracts'
    | '/deals/tickets/purchase-tickets'
    | '/deals/tickets/sale-tickets'
    | '/reports/finance-reports/ap-ar'
    | '/reports/finance-reports/balance'
    | '/reports/finance-reports/budgets'
    | '/reports/finance-reports/cash-flow'
    | '/reports/finance-reports/costs-report'
    | '/reports/finance-reports/passports-pnl'
    | '/reports/finance-reports/reconciliations'
    | '/reports/finance-reports/statistics'
    | '/reports/management-reports/activity'
    | '/reports/management-reports/approvals'
    | '/reports/management-reports/compliance'
    | '/reports/management-reports/documents-dates'
    | '/reports/management-reports/kpi'
    | '/reports/management-reports/signings'
    | '/reports/management-reports/team'
    | '/reports/risk-reports/credit-report'
    | '/reports/risk-reports/invoices-risk'
    | '/reports/trading-reports/contracts-execution'
    | '/reports/trading-reports/derivatives'
    | '/reports/trading-reports/fixing-or-hedging'
    | '/reports/trading-reports/freight'
    | '/reports/trading-reports/line-up'
    | '/reports/trading-reports/physical-position-by-months'
    | '/reports/trading-reports/physical-position-mtm'
    | '/reports/trading-reports/position-pl-and-mtm'
    | '/company/team'
    | '/company/settings/addresses'
    | '/company/settings/bots'
    | '/company/settings/chats'
    | '/company/settings/cities'
    | '/company/settings/client-chats'
    | '/company/settings/commodities'
    | '/company/settings/company-groups'
    | '/company/settings/configs'
    | '/company/settings/contract-charges'
    | '/company/settings/countries'
    | '/company/settings/currencies'
    | '/company/settings/delivery-conditions'
    | '/company/settings/derivative-accounts'
    | '/company/settings/derivatives'
    | '/company/settings/districts'
    | '/company/settings/documents'
    | '/company/settings/drivers'
    | '/company/settings/exchanges'
    | '/company/settings/finance-accounts'
    | '/company/settings/finance-bank'
    | '/company/settings/general-expenses'
    | '/company/settings/groups'
    | '/company/settings/logistic-types'
    | '/company/settings/newsletter'
    | '/company/settings/other-activities'
    | '/company/settings/payment-info'
    | '/company/settings/ports'
    | '/company/settings/quality'
    | '/company/settings/regions'
    | '/company/settings/responsibilities'
    | '/company/settings/stations'
    | '/company/settings/stock-exchange'
    | '/company/settings/terminals'
    | '/company/settings/users'
    | '/company/settings/vehicle-subtypes'
    | '/company/settings/vehicles'
    | '/company/settings/vessel-sizes'
    | '/company/team/$traderId/approvals'
    | '/company/team/$traderId/charges'
    | '/company/team/$traderId/companies'
    | '/company/team/$traderId/contacts'
    | '/company/team/$traderId/contracts'
    | '/company/team/$traderId/derivatives'
    | '/company/team/$traderId/documents'
    | '/company/team/$traderId/finances'
    | '/company/team/$traderId/history'
    | '/company/team/$traderId/indicators'
    | '/company/team/$traderId/info'
    | '/company/team/$traderId/logistics'
    | '/company/team/$traderId/passports'
    | '/company/team/$traderId/settings'
    | '/company/team/$traderId/tickets'
    | '/company/team/$traderId/updates'
    | '/company/team/$traderId/voyages'
    | '/company/team/$traderId'
    | '/company/drivers/$driverId/documents'
    | '/company/drivers/$driverId/history'
    | '/company/drivers/$driverId/info'
    | '/company/vehicles/$vehicleId/documents'
    | '/company/vehicles/$vehicleId/history'
    | '/company/vehicles/$vehicleId/info'
    | '/crm/counterparties/$counterpartyId/agreements'
    | '/crm/counterparties/$counterpartyId/approvals'
    | '/crm/counterparties/$counterpartyId/bank-details'
    | '/crm/counterparties/$counterpartyId/contacts'
    | '/crm/counterparties/$counterpartyId/contracts'
    | '/crm/counterparties/$counterpartyId/costs-and-gains'
    | '/crm/counterparties/$counterpartyId/dashboard'
    | '/crm/counterparties/$counterpartyId/derivatives'
    | '/crm/counterparties/$counterpartyId/documents'
    | '/crm/counterparties/$counterpartyId/execution'
    | '/crm/counterparties/$counterpartyId/finance'
    | '/crm/counterparties/$counterpartyId/history'
    | '/crm/counterparties/$counterpartyId/indicators'
    | '/crm/counterparties/$counterpartyId/potentials'
    | '/crm/counterparties/$counterpartyId/reconciliation'
    | '/crm/counterparties/$counterpartyId/risk'
    | '/crm/counterparties/$counterpartyId/tasks'
    | '/crm/counterparties/$counterpartyId/tickets'
    | '/deals/agreements/$agreementId/contracts'
    | '/deals/agreements/$agreementId/custom-fields'
    | '/deals/agreements/$agreementId/documents'
    | '/deals/agreements/$agreementId/execution'
    | '/deals/agreements/$agreementId/history'
    | '/deals/agreements/$agreementId/tickets'
    | '/deals/market/$bidId/additional-agreements'
    | '/deals/market/$bidId/approvals'
    | '/deals/market/$bidId/costs'
    | '/deals/market/$bidId/documents'
    | '/deals/market/$bidId/history'
    | '/deals/market/$bidId/potential-purchase'
    | '/deals/market/$bidId/quality'
    | '/deals/market/$bidId/tasks'
    | '/deals/market/$bidId/telegram'
    | '/deals/market/$offerId/additional-agreements'
    | '/deals/market/$offerId/approvals'
    | '/deals/market/$offerId/costs'
    | '/deals/market/$offerId/documents'
    | '/deals/market/$offerId/history'
    | '/deals/market/$offerId/quality'
    | '/deals/market/$offerId/tasks'
    | '/deals/market/$offerId/telegram'
    | '/deals/multiservices/$multiserviceId/additional-agreements'
    | '/deals/multiservices/$multiserviceId/approvals'
    | '/deals/multiservices/$multiserviceId/closing-contract'
    | '/deals/multiservices/$multiserviceId/costs-gains'
    | '/deals/multiservices/$multiserviceId/dashboard'
    | '/deals/multiservices/$multiserviceId/documents'
    | '/deals/multiservices/$multiserviceId/execution'
    | '/deals/multiservices/$multiserviceId/finance'
    | '/deals/multiservices/$multiserviceId/fixing-and-hedging'
    | '/deals/multiservices/$multiserviceId/history'
    | '/deals/multiservices/$multiserviceId/positions'
    | '/deals/multiservices/$multiserviceId/print-and-sign'
    | '/deals/multiservices/$multiserviceId/profit-and-loss'
    | '/deals/multiservices/$multiserviceId/quality'
    | '/deals/multiservices/$multiserviceId/sale-export-contract-allocations'
    | '/deals/multiservices/$multiserviceId/tasks'
    | '/deals/multitickets/$multiticketId/additional-agreements'
    | '/deals/multitickets/$multiticketId/approvals'
    | '/deals/multitickets/$multiticketId/costs-gains'
    | '/deals/multitickets/$multiticketId/documents'
    | '/deals/multitickets/$multiticketId/history'
    | '/deals/multitickets/$multiticketId/positions'
    | '/deals/multitickets/$multiticketId/potential-purchase'
    | '/deals/multitickets/$multiticketId/quality'
    | '/deals/multitickets/$multiticketId/tasks'
    | '/deals/passports/$passportId/comments'
    | '/deals/passports/$passportId/contacts'
    | '/deals/passports/$passportId/contracts'
    | '/deals/passports/$passportId/cost-of-goods'
    | '/deals/passports/$passportId/dashboard'
    | '/deals/passports/$passportId/derivatives'
    | '/deals/passports/$passportId/documents'
    | '/deals/passports/$passportId/execution'
    | '/deals/passports/$passportId/finance'
    | '/deals/passports/$passportId/invoices'
    | '/deals/passports/$passportId/tasks'
    | '/deals/passports/$passportId/tickets'
    | '/deals/services/direct-contract/$contractId/additional-agreements'
    | '/deals/services/direct-contract/$contractId/closing-contract'
    | '/deals/services/direct-contract/$contractId/costs'
    | '/deals/services/direct-contract/$contractId/documents'
    | '/deals/services/direct-contract/$contractId/execution'
    | '/deals/services/direct-contract/$contractId/finance'
    | '/deals/services/direct-contract/$contractId/fixing-and-hedging'
    | '/deals/services/direct-contract/$contractId/history'
    | '/deals/services/direct-contract/$contractId/quality'
    | '/deals/services/direct-contract/$contractId/sale-export-contract-allocations'
    | '/deals/services/direct-contract/$contractId/tasks'
    | '/deals/services/general-contract/$contractId/additional-agreements'
    | '/deals/services/general-contract/$contractId/closing-contract'
    | '/deals/services/general-contract/$contractId/costs'
    | '/deals/services/general-contract/$contractId/documents'
    | '/deals/services/general-contract/$contractId/execution'
    | '/deals/services/general-contract/$contractId/finance'
    | '/deals/services/general-contract/$contractId/fixing-and-hedging'
    | '/deals/services/general-contract/$contractId/history'
    | '/deals/services/general-contract/$contractId/quality'
    | '/deals/services/general-contract/$contractId/sale-export-contract-allocations'
    | '/deals/services/general-contract/$contractId/tasks'
    | '/deals/tickets/purchase/$ticketId/additional-agreements'
    | '/deals/tickets/purchase/$ticketId/approvals'
    | '/deals/tickets/purchase/$ticketId/costs'
    | '/deals/tickets/purchase/$ticketId/documents'
    | '/deals/tickets/purchase/$ticketId/history'
    | '/deals/tickets/purchase/$ticketId/quality'
    | '/deals/tickets/purchase/$ticketId/tasks'
    | '/deals/tickets/purchase/$ticketId/telegram'
    | '/deals/tickets/sale/$ticketId/additional-agreements'
    | '/deals/tickets/sale/$ticketId/costs'
    | '/deals/tickets/sale/$ticketId/documents'
    | '/deals/tickets/sale/$ticketId/history'
    | '/deals/tickets/sale/$ticketId/potential-purchase'
    | '/deals/tickets/sale/$ticketId/quality'
    | '/deals/tickets/sale/$ticketId/tasks'
    | '/deals/tickets/sale/$ticketId/telegram'
    | '/deals/services/direct-contract/$contractId'
    | '/deals/services/general-contract/$contractId'
    | '/deals/tickets/purchase/$ticketId'
    | '/deals/tickets/sale/$ticketId';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/401'
    | '/403'
    | '/404'
    | '/500'
    | '/login'
    | '/reset-password'
    | ''
    | '/'
    | '/start'
    | '/company/custom-fields'
    | '/company/documents'
    | '/company/drivers'
    | '/company/notifications'
    | '/company/quality-rule-templates'
    | '/company/settings'
    | '/company/tasks-templates'
    | '/company/template-playground'
    | '/company/vehicles'
    | '/crm/contacts'
    | '/crm/counterparties'
    | '/crm/updates'
    | '/deals/agreements'
    | '/deals/calculator'
    | '/deals/contracts'
    | '/deals/market'
    | '/deals/multicontracts'
    | '/deals/multiservices'
    | '/deals/multitickets'
    | '/deals/passports'
    | '/deals/potentials'
    | '/deals/quotations'
    | '/deals/services'
    | '/deals/tickets'
    | '/execution/analytics'
    | '/execution/bills-of-lading'
    | '/execution/consignments'
    | '/execution/customs-declarations'
    | '/execution/disbursement-bill-of-lading'
    | '/execution/planning'
    | '/execution/samples'
    | '/execution/tariffs'
    | '/execution/transport'
    | '/execution/vessels'
    | '/execution/warehouse'
    | '/finance/accounting'
    | '/finance/analytics'
    | '/finance/bank-accounts'
    | '/finance/charges-or-gains'
    | '/finance/invoice-positions'
    | '/finance/invoices'
    | '/finance/offset'
    | '/finance/payment-delays'
    | '/finance/payment-plans'
    | '/finance/payments'
    | '/finance/reasignment'
    | '/reports/dashboard'
    | '/company/settings/organization'
    | '/company/settings/parsing'
    | '/company/settings/personal'
    | '/crm/newsletters/invoices'
    | '/crm/newsletters/logistics'
    | '/crm/newsletters/templates'
    | '/deals/market/bids'
    | '/deals/market/offers'
    | '/deals/services/direct-contracts'
    | '/deals/services/general-contracts'
    | '/deals/tickets/purchase-tickets'
    | '/deals/tickets/sale-tickets'
    | '/reports/finance-reports/ap-ar'
    | '/reports/finance-reports/balance'
    | '/reports/finance-reports/budgets'
    | '/reports/finance-reports/cash-flow'
    | '/reports/finance-reports/costs-report'
    | '/reports/finance-reports/passports-pnl'
    | '/reports/finance-reports/reconciliations'
    | '/reports/finance-reports/statistics'
    | '/reports/management-reports/activity'
    | '/reports/management-reports/approvals'
    | '/reports/management-reports/compliance'
    | '/reports/management-reports/documents-dates'
    | '/reports/management-reports/kpi'
    | '/reports/management-reports/signings'
    | '/reports/management-reports/team'
    | '/reports/risk-reports/credit-report'
    | '/reports/risk-reports/invoices-risk'
    | '/reports/trading-reports/contracts-execution'
    | '/reports/trading-reports/derivatives'
    | '/reports/trading-reports/fixing-or-hedging'
    | '/reports/trading-reports/freight'
    | '/reports/trading-reports/line-up'
    | '/reports/trading-reports/physical-position-by-months'
    | '/reports/trading-reports/physical-position-mtm'
    | '/reports/trading-reports/position-pl-and-mtm'
    | '/company/team'
    | '/company/settings/addresses'
    | '/company/settings/bots'
    | '/company/settings/chats'
    | '/company/settings/cities'
    | '/company/settings/client-chats'
    | '/company/settings/commodities'
    | '/company/settings/company-groups'
    | '/company/settings/configs'
    | '/company/settings/contract-charges'
    | '/company/settings/countries'
    | '/company/settings/currencies'
    | '/company/settings/delivery-conditions'
    | '/company/settings/derivative-accounts'
    | '/company/settings/derivatives'
    | '/company/settings/districts'
    | '/company/settings/documents'
    | '/company/settings/drivers'
    | '/company/settings/exchanges'
    | '/company/settings/finance-accounts'
    | '/company/settings/finance-bank'
    | '/company/settings/general-expenses'
    | '/company/settings/groups'
    | '/company/settings/logistic-types'
    | '/company/settings/newsletter'
    | '/company/settings/other-activities'
    | '/company/settings/payment-info'
    | '/company/settings/ports'
    | '/company/settings/quality'
    | '/company/settings/regions'
    | '/company/settings/responsibilities'
    | '/company/settings/stations'
    | '/company/settings/stock-exchange'
    | '/company/settings/terminals'
    | '/company/settings/users'
    | '/company/settings/vehicle-subtypes'
    | '/company/settings/vehicles'
    | '/company/settings/vessel-sizes'
    | '/company/team/$traderId/approvals'
    | '/company/team/$traderId/charges'
    | '/company/team/$traderId/companies'
    | '/company/team/$traderId/contacts'
    | '/company/team/$traderId/contracts'
    | '/company/team/$traderId/derivatives'
    | '/company/team/$traderId/documents'
    | '/company/team/$traderId/finances'
    | '/company/team/$traderId/history'
    | '/company/team/$traderId/indicators'
    | '/company/team/$traderId/info'
    | '/company/team/$traderId/logistics'
    | '/company/team/$traderId/passports'
    | '/company/team/$traderId/settings'
    | '/company/team/$traderId/tickets'
    | '/company/team/$traderId/updates'
    | '/company/team/$traderId/voyages'
    | '/company/team/$traderId'
    | '/company/drivers/$driverId/documents'
    | '/company/drivers/$driverId/history'
    | '/company/drivers/$driverId/info'
    | '/company/vehicles/$vehicleId/documents'
    | '/company/vehicles/$vehicleId/history'
    | '/company/vehicles/$vehicleId/info'
    | '/crm/counterparties/$counterpartyId/agreements'
    | '/crm/counterparties/$counterpartyId/approvals'
    | '/crm/counterparties/$counterpartyId/bank-details'
    | '/crm/counterparties/$counterpartyId/contacts'
    | '/crm/counterparties/$counterpartyId/contracts'
    | '/crm/counterparties/$counterpartyId/costs-and-gains'
    | '/crm/counterparties/$counterpartyId/dashboard'
    | '/crm/counterparties/$counterpartyId/derivatives'
    | '/crm/counterparties/$counterpartyId/documents'
    | '/crm/counterparties/$counterpartyId/execution'
    | '/crm/counterparties/$counterpartyId/finance'
    | '/crm/counterparties/$counterpartyId/history'
    | '/crm/counterparties/$counterpartyId/indicators'
    | '/crm/counterparties/$counterpartyId/potentials'
    | '/crm/counterparties/$counterpartyId/reconciliation'
    | '/crm/counterparties/$counterpartyId/risk'
    | '/crm/counterparties/$counterpartyId/tasks'
    | '/crm/counterparties/$counterpartyId/tickets'
    | '/deals/agreements/$agreementId/contracts'
    | '/deals/agreements/$agreementId/custom-fields'
    | '/deals/agreements/$agreementId/documents'
    | '/deals/agreements/$agreementId/execution'
    | '/deals/agreements/$agreementId/history'
    | '/deals/agreements/$agreementId/tickets'
    | '/deals/market/$bidId/additional-agreements'
    | '/deals/market/$bidId/approvals'
    | '/deals/market/$bidId/costs'
    | '/deals/market/$bidId/documents'
    | '/deals/market/$bidId/history'
    | '/deals/market/$bidId/potential-purchase'
    | '/deals/market/$bidId/quality'
    | '/deals/market/$bidId/tasks'
    | '/deals/market/$bidId/telegram'
    | '/deals/market/$offerId/additional-agreements'
    | '/deals/market/$offerId/approvals'
    | '/deals/market/$offerId/costs'
    | '/deals/market/$offerId/documents'
    | '/deals/market/$offerId/history'
    | '/deals/market/$offerId/quality'
    | '/deals/market/$offerId/tasks'
    | '/deals/market/$offerId/telegram'
    | '/deals/multiservices/$multiserviceId/additional-agreements'
    | '/deals/multiservices/$multiserviceId/approvals'
    | '/deals/multiservices/$multiserviceId/closing-contract'
    | '/deals/multiservices/$multiserviceId/costs-gains'
    | '/deals/multiservices/$multiserviceId/dashboard'
    | '/deals/multiservices/$multiserviceId/documents'
    | '/deals/multiservices/$multiserviceId/execution'
    | '/deals/multiservices/$multiserviceId/finance'
    | '/deals/multiservices/$multiserviceId/fixing-and-hedging'
    | '/deals/multiservices/$multiserviceId/history'
    | '/deals/multiservices/$multiserviceId/positions'
    | '/deals/multiservices/$multiserviceId/print-and-sign'
    | '/deals/multiservices/$multiserviceId/profit-and-loss'
    | '/deals/multiservices/$multiserviceId/quality'
    | '/deals/multiservices/$multiserviceId/sale-export-contract-allocations'
    | '/deals/multiservices/$multiserviceId/tasks'
    | '/deals/multitickets/$multiticketId/additional-agreements'
    | '/deals/multitickets/$multiticketId/approvals'
    | '/deals/multitickets/$multiticketId/costs-gains'
    | '/deals/multitickets/$multiticketId/documents'
    | '/deals/multitickets/$multiticketId/history'
    | '/deals/multitickets/$multiticketId/positions'
    | '/deals/multitickets/$multiticketId/potential-purchase'
    | '/deals/multitickets/$multiticketId/quality'
    | '/deals/multitickets/$multiticketId/tasks'
    | '/deals/passports/$passportId/comments'
    | '/deals/passports/$passportId/contacts'
    | '/deals/passports/$passportId/contracts'
    | '/deals/passports/$passportId/cost-of-goods'
    | '/deals/passports/$passportId/dashboard'
    | '/deals/passports/$passportId/derivatives'
    | '/deals/passports/$passportId/documents'
    | '/deals/passports/$passportId/execution'
    | '/deals/passports/$passportId/finance'
    | '/deals/passports/$passportId/invoices'
    | '/deals/passports/$passportId/tasks'
    | '/deals/passports/$passportId/tickets'
    | '/deals/services/direct-contract/$contractId/additional-agreements'
    | '/deals/services/direct-contract/$contractId/closing-contract'
    | '/deals/services/direct-contract/$contractId/costs'
    | '/deals/services/direct-contract/$contractId/documents'
    | '/deals/services/direct-contract/$contractId/execution'
    | '/deals/services/direct-contract/$contractId/finance'
    | '/deals/services/direct-contract/$contractId/fixing-and-hedging'
    | '/deals/services/direct-contract/$contractId/history'
    | '/deals/services/direct-contract/$contractId/quality'
    | '/deals/services/direct-contract/$contractId/sale-export-contract-allocations'
    | '/deals/services/direct-contract/$contractId/tasks'
    | '/deals/services/general-contract/$contractId/additional-agreements'
    | '/deals/services/general-contract/$contractId/closing-contract'
    | '/deals/services/general-contract/$contractId/costs'
    | '/deals/services/general-contract/$contractId/documents'
    | '/deals/services/general-contract/$contractId/execution'
    | '/deals/services/general-contract/$contractId/finance'
    | '/deals/services/general-contract/$contractId/fixing-and-hedging'
    | '/deals/services/general-contract/$contractId/history'
    | '/deals/services/general-contract/$contractId/quality'
    | '/deals/services/general-contract/$contractId/sale-export-contract-allocations'
    | '/deals/services/general-contract/$contractId/tasks'
    | '/deals/tickets/purchase/$ticketId/additional-agreements'
    | '/deals/tickets/purchase/$ticketId/approvals'
    | '/deals/tickets/purchase/$ticketId/costs'
    | '/deals/tickets/purchase/$ticketId/documents'
    | '/deals/tickets/purchase/$ticketId/history'
    | '/deals/tickets/purchase/$ticketId/quality'
    | '/deals/tickets/purchase/$ticketId/tasks'
    | '/deals/tickets/purchase/$ticketId/telegram'
    | '/deals/tickets/sale/$ticketId/additional-agreements'
    | '/deals/tickets/sale/$ticketId/costs'
    | '/deals/tickets/sale/$ticketId/documents'
    | '/deals/tickets/sale/$ticketId/history'
    | '/deals/tickets/sale/$ticketId/potential-purchase'
    | '/deals/tickets/sale/$ticketId/quality'
    | '/deals/tickets/sale/$ticketId/tasks'
    | '/deals/tickets/sale/$ticketId/telegram'
    | '/deals/services/direct-contract/$contractId'
    | '/deals/services/general-contract/$contractId'
    | '/deals/tickets/purchase/$ticketId'
    | '/deals/tickets/sale/$ticketId';
  id:
    | '__root__'
    | '/401'
    | '/403'
    | '/404'
    | '/500'
    | '/_auth'
    | '/login'
    | '/reset-password'
    | '/_auth/_sidebar'
    | '/_auth/'
    | '/_auth/_sidebar/start'
    | '/_auth/_sidebar/company/custom-fields'
    | '/_auth/_sidebar/company/documents'
    | '/_auth/_sidebar/company/drivers'
    | '/_auth/_sidebar/company/notifications'
    | '/_auth/_sidebar/company/quality-rule-templates'
    | '/_auth/_sidebar/company/settings'
    | '/_auth/_sidebar/company/tasks-templates'
    | '/_auth/_sidebar/company/template-playground'
    | '/_auth/_sidebar/company/vehicles'
    | '/_auth/_sidebar/crm/contacts'
    | '/_auth/_sidebar/crm/counterparties'
    | '/_auth/_sidebar/crm/updates'
    | '/_auth/_sidebar/deals/agreements'
    | '/_auth/_sidebar/deals/calculator'
    | '/_auth/_sidebar/deals/contracts'
    | '/_auth/_sidebar/deals/market'
    | '/_auth/_sidebar/deals/multicontracts'
    | '/_auth/_sidebar/deals/multiservices'
    | '/_auth/_sidebar/deals/multitickets'
    | '/_auth/_sidebar/deals/passports'
    | '/_auth/_sidebar/deals/potentials'
    | '/_auth/_sidebar/deals/quotations'
    | '/_auth/_sidebar/deals/services'
    | '/_auth/_sidebar/deals/tickets'
    | '/_auth/_sidebar/execution/analytics'
    | '/_auth/_sidebar/execution/bills-of-lading'
    | '/_auth/_sidebar/execution/consignments'
    | '/_auth/_sidebar/execution/customs-declarations'
    | '/_auth/_sidebar/execution/disbursement-bill-of-lading'
    | '/_auth/_sidebar/execution/planning'
    | '/_auth/_sidebar/execution/samples'
    | '/_auth/_sidebar/execution/tariffs'
    | '/_auth/_sidebar/execution/transport'
    | '/_auth/_sidebar/execution/vessels'
    | '/_auth/_sidebar/execution/warehouse'
    | '/_auth/_sidebar/finance/accounting'
    | '/_auth/_sidebar/finance/analytics'
    | '/_auth/_sidebar/finance/bank-accounts'
    | '/_auth/_sidebar/finance/charges-or-gains'
    | '/_auth/_sidebar/finance/invoice-positions'
    | '/_auth/_sidebar/finance/invoices'
    | '/_auth/_sidebar/finance/offset'
    | '/_auth/_sidebar/finance/payment-delays'
    | '/_auth/_sidebar/finance/payment-plans'
    | '/_auth/_sidebar/finance/payments'
    | '/_auth/_sidebar/finance/reasignment'
    | '/_auth/_sidebar/reports/dashboard'
    | '/_auth/_sidebar/company/drivers/_driver'
    | '/_auth/_sidebar/company/settings/organization'
    | '/_auth/_sidebar/company/settings/parsing'
    | '/_auth/_sidebar/company/settings/personal'
    | '/_auth/_sidebar/crm/counterparties/_counterparty'
    | '/_auth/_sidebar/crm/newsletters/invoices'
    | '/_auth/_sidebar/crm/newsletters/logistics'
    | '/_auth/_sidebar/crm/newsletters/templates'
    | '/_auth/_sidebar/deals/agreements/_agreement'
    | '/_auth/_sidebar/deals/market/_bid'
    | '/_auth/_sidebar/deals/market/_offer'
    | '/_auth/_sidebar/deals/market/bids'
    | '/_auth/_sidebar/deals/market/offers'
    | '/_auth/_sidebar/deals/multiservices/_multiservice'
    | '/_auth/_sidebar/deals/multitickets/_multiticket'
    | '/_auth/_sidebar/deals/passports/_passport'
    | '/_auth/_sidebar/deals/services/direct-contracts'
    | '/_auth/_sidebar/deals/services/general-contracts'
    | '/_auth/_sidebar/deals/tickets/purchase-tickets'
    | '/_auth/_sidebar/deals/tickets/sale-tickets'
    | '/_auth/_sidebar/reports/finance-reports/ap-ar'
    | '/_auth/_sidebar/reports/finance-reports/balance'
    | '/_auth/_sidebar/reports/finance-reports/budgets'
    | '/_auth/_sidebar/reports/finance-reports/cash-flow'
    | '/_auth/_sidebar/reports/finance-reports/costs-report'
    | '/_auth/_sidebar/reports/finance-reports/passports-pnl'
    | '/_auth/_sidebar/reports/finance-reports/reconciliations'
    | '/_auth/_sidebar/reports/finance-reports/statistics'
    | '/_auth/_sidebar/reports/management-reports/activity'
    | '/_auth/_sidebar/reports/management-reports/approvals'
    | '/_auth/_sidebar/reports/management-reports/compliance'
    | '/_auth/_sidebar/reports/management-reports/documents-dates'
    | '/_auth/_sidebar/reports/management-reports/kpi'
    | '/_auth/_sidebar/reports/management-reports/signings'
    | '/_auth/_sidebar/reports/management-reports/team'
    | '/_auth/_sidebar/reports/risk-reports/credit-report'
    | '/_auth/_sidebar/reports/risk-reports/invoices-risk'
    | '/_auth/_sidebar/reports/trading-reports/contracts-execution'
    | '/_auth/_sidebar/reports/trading-reports/derivatives'
    | '/_auth/_sidebar/reports/trading-reports/fixing-or-hedging'
    | '/_auth/_sidebar/reports/trading-reports/freight'
    | '/_auth/_sidebar/reports/trading-reports/line-up'
    | '/_auth/_sidebar/reports/trading-reports/physical-position-by-months'
    | '/_auth/_sidebar/reports/trading-reports/physical-position-mtm'
    | '/_auth/_sidebar/reports/trading-reports/position-pl-and-mtm'
    | '/_auth/_sidebar/company/team/'
    | '/_auth/_sidebar/company/settings/_list/addresses'
    | '/_auth/_sidebar/company/settings/_list/bots'
    | '/_auth/_sidebar/company/settings/_list/chats'
    | '/_auth/_sidebar/company/settings/_list/cities'
    | '/_auth/_sidebar/company/settings/_list/client-chats'
    | '/_auth/_sidebar/company/settings/_list/commodities'
    | '/_auth/_sidebar/company/settings/_list/company-groups'
    | '/_auth/_sidebar/company/settings/_list/configs'
    | '/_auth/_sidebar/company/settings/_list/contract-charges'
    | '/_auth/_sidebar/company/settings/_list/countries'
    | '/_auth/_sidebar/company/settings/_list/currencies'
    | '/_auth/_sidebar/company/settings/_list/delivery-conditions'
    | '/_auth/_sidebar/company/settings/_list/derivative-accounts'
    | '/_auth/_sidebar/company/settings/_list/derivatives'
    | '/_auth/_sidebar/company/settings/_list/districts'
    | '/_auth/_sidebar/company/settings/_list/documents'
    | '/_auth/_sidebar/company/settings/_list/drivers'
    | '/_auth/_sidebar/company/settings/_list/exchanges'
    | '/_auth/_sidebar/company/settings/_list/finance-accounts'
    | '/_auth/_sidebar/company/settings/_list/finance-bank'
    | '/_auth/_sidebar/company/settings/_list/general-expenses'
    | '/_auth/_sidebar/company/settings/_list/groups'
    | '/_auth/_sidebar/company/settings/_list/logistic-types'
    | '/_auth/_sidebar/company/settings/_list/newsletter'
    | '/_auth/_sidebar/company/settings/_list/other-activities'
    | '/_auth/_sidebar/company/settings/_list/payment-info'
    | '/_auth/_sidebar/company/settings/_list/ports'
    | '/_auth/_sidebar/company/settings/_list/quality'
    | '/_auth/_sidebar/company/settings/_list/regions'
    | '/_auth/_sidebar/company/settings/_list/responsibilities'
    | '/_auth/_sidebar/company/settings/_list/stations'
    | '/_auth/_sidebar/company/settings/_list/stock-exchange'
    | '/_auth/_sidebar/company/settings/_list/terminals'
    | '/_auth/_sidebar/company/settings/_list/users'
    | '/_auth/_sidebar/company/settings/_list/vehicle-subtypes'
    | '/_auth/_sidebar/company/settings/_list/vehicles'
    | '/_auth/_sidebar/company/settings/_list/vessel-sizes'
    | '/_auth/_sidebar/company/team/$traderId/approvals'
    | '/_auth/_sidebar/company/team/$traderId/charges'
    | '/_auth/_sidebar/company/team/$traderId/companies'
    | '/_auth/_sidebar/company/team/$traderId/contacts'
    | '/_auth/_sidebar/company/team/$traderId/contracts'
    | '/_auth/_sidebar/company/team/$traderId/derivatives'
    | '/_auth/_sidebar/company/team/$traderId/documents'
    | '/_auth/_sidebar/company/team/$traderId/finances'
    | '/_auth/_sidebar/company/team/$traderId/history'
    | '/_auth/_sidebar/company/team/$traderId/indicators'
    | '/_auth/_sidebar/company/team/$traderId/info'
    | '/_auth/_sidebar/company/team/$traderId/logistics'
    | '/_auth/_sidebar/company/team/$traderId/passports'
    | '/_auth/_sidebar/company/team/$traderId/settings'
    | '/_auth/_sidebar/company/team/$traderId/tickets'
    | '/_auth/_sidebar/company/team/$traderId/updates'
    | '/_auth/_sidebar/company/team/$traderId/voyages'
    | '/_auth/_sidebar/company/team/$traderId/'
    | '/_auth/_sidebar/company/drivers/_driver/$driverId/documents'
    | '/_auth/_sidebar/company/drivers/_driver/$driverId/history'
    | '/_auth/_sidebar/company/drivers/_driver/$driverId/info'
    | '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/documents'
    | '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/history'
    | '/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/info'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/agreements'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/approvals'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/bank-details'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contacts'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contracts'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/costs-and-gains'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/dashboard'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/derivatives'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/documents'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/execution'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/finance'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/history'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/indicators'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/potentials'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/reconciliation'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/risk'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tasks'
    | '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tickets'
    | '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/contracts'
    | '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/custom-fields'
    | '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/documents'
    | '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/execution'
    | '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/history'
    | '/_auth/_sidebar/deals/agreements/_agreement/$agreementId/tickets'
    | '/_auth/_sidebar/deals/market/_bid/$bidId/additional-agreements'
    | '/_auth/_sidebar/deals/market/_bid/$bidId/approvals'
    | '/_auth/_sidebar/deals/market/_bid/$bidId/costs'
    | '/_auth/_sidebar/deals/market/_bid/$bidId/documents'
    | '/_auth/_sidebar/deals/market/_bid/$bidId/history'
    | '/_auth/_sidebar/deals/market/_bid/$bidId/potential-purchase'
    | '/_auth/_sidebar/deals/market/_bid/$bidId/quality'
    | '/_auth/_sidebar/deals/market/_bid/$bidId/tasks'
    | '/_auth/_sidebar/deals/market/_bid/$bidId/telegram'
    | '/_auth/_sidebar/deals/market/_offer/$offerId/additional-agreements'
    | '/_auth/_sidebar/deals/market/_offer/$offerId/approvals'
    | '/_auth/_sidebar/deals/market/_offer/$offerId/costs'
    | '/_auth/_sidebar/deals/market/_offer/$offerId/documents'
    | '/_auth/_sidebar/deals/market/_offer/$offerId/history'
    | '/_auth/_sidebar/deals/market/_offer/$offerId/quality'
    | '/_auth/_sidebar/deals/market/_offer/$offerId/tasks'
    | '/_auth/_sidebar/deals/market/_offer/$offerId/telegram'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/additional-agreements'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/approvals'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/closing-contract'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/costs-gains'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/dashboard'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/documents'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/execution'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/finance'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/fixing-and-hedging'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/history'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/positions'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/print-and-sign'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/profit-and-loss'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/quality'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/sale-export-contract-allocations'
    | '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/tasks'
    | '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/additional-agreements'
    | '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/approvals'
    | '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/costs-gains'
    | '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/documents'
    | '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/history'
    | '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/positions'
    | '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/potential-purchase'
    | '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/quality'
    | '/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/tasks'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/comments'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/contacts'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/contracts'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/cost-of-goods'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/dashboard'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/derivatives'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/documents'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/execution'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/finance'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/invoices'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/tasks'
    | '/_auth/_sidebar/deals/passports/_passport/$passportId/tickets'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/additional-agreements'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/closing-contract'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/costs'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/documents'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/execution'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/finance'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/fixing-and-hedging'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/history'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/quality'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/sale-export-contract-allocations'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/tasks'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/additional-agreements'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/closing-contract'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/costs'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/documents'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/execution'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/finance'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/fixing-and-hedging'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/history'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/quality'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/sale-export-contract-allocations'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/tasks'
    | '/_auth/_sidebar/deals/tickets/purchase/$ticketId/additional-agreements'
    | '/_auth/_sidebar/deals/tickets/purchase/$ticketId/approvals'
    | '/_auth/_sidebar/deals/tickets/purchase/$ticketId/costs'
    | '/_auth/_sidebar/deals/tickets/purchase/$ticketId/documents'
    | '/_auth/_sidebar/deals/tickets/purchase/$ticketId/history'
    | '/_auth/_sidebar/deals/tickets/purchase/$ticketId/quality'
    | '/_auth/_sidebar/deals/tickets/purchase/$ticketId/tasks'
    | '/_auth/_sidebar/deals/tickets/purchase/$ticketId/telegram'
    | '/_auth/_sidebar/deals/tickets/sale/$ticketId/additional-agreements'
    | '/_auth/_sidebar/deals/tickets/sale/$ticketId/costs'
    | '/_auth/_sidebar/deals/tickets/sale/$ticketId/documents'
    | '/_auth/_sidebar/deals/tickets/sale/$ticketId/history'
    | '/_auth/_sidebar/deals/tickets/sale/$ticketId/potential-purchase'
    | '/_auth/_sidebar/deals/tickets/sale/$ticketId/quality'
    | '/_auth/_sidebar/deals/tickets/sale/$ticketId/tasks'
    | '/_auth/_sidebar/deals/tickets/sale/$ticketId/telegram'
    | '/_auth/_sidebar/deals/services/direct-contract/$contractId/'
    | '/_auth/_sidebar/deals/services/general-contract/$contractId/'
    | '/_auth/_sidebar/deals/tickets/purchase/$ticketId/'
    | '/_auth/_sidebar/deals/tickets/sale/$ticketId/';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  R401Route: typeof R401Route;
  R403Route: typeof R403Route;
  R404Route: typeof R404Route;
  R500Route: typeof R500Route;
  AuthRoute: typeof AuthRouteWithChildren;
  LoginRoute: typeof LoginRoute;
  ResetPasswordRoute: typeof ResetPasswordRoute;
}

const rootRouteChildren: RootRouteChildren = {
  R401Route: R401Route,
  R403Route: R403Route,
  R404Route: R404Route,
  R500Route: R500Route,
  AuthRoute: AuthRouteWithChildren,
  LoginRoute: LoginRoute,
  ResetPasswordRoute: ResetPasswordRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/401",
        "/403",
        "/404",
        "/500",
        "/_auth",
        "/login",
        "/reset-password"
      ]
    },
    "/401": {
      "filePath": "401.tsx"
    },
    "/403": {
      "filePath": "403.tsx"
    },
    "/404": {
      "filePath": "404.tsx"
    },
    "/500": {
      "filePath": "500.tsx"
    },
    "/_auth": {
      "filePath": "_auth.ts",
      "children": [
        "/_auth/_sidebar",
        "/_auth/"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/reset-password": {
      "filePath": "reset-password.tsx"
    },
    "/_auth/_sidebar": {
      "filePath": "_auth/_sidebar.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_sidebar/start",
        "/_auth/_sidebar/company/custom-fields",
        "/_auth/_sidebar/company/documents",
        "/_auth/_sidebar/company/drivers",
        "/_auth/_sidebar/company/notifications",
        "/_auth/_sidebar/company/quality-rule-templates",
        "/_auth/_sidebar/company/settings",
        "/_auth/_sidebar/company/tasks-templates",
        "/_auth/_sidebar/company/template-playground",
        "/_auth/_sidebar/company/vehicles",
        "/_auth/_sidebar/crm/contacts",
        "/_auth/_sidebar/crm/counterparties",
        "/_auth/_sidebar/crm/updates",
        "/_auth/_sidebar/deals/agreements",
        "/_auth/_sidebar/deals/calculator",
        "/_auth/_sidebar/deals/contracts",
        "/_auth/_sidebar/deals/market",
        "/_auth/_sidebar/deals/multicontracts",
        "/_auth/_sidebar/deals/multiservices",
        "/_auth/_sidebar/deals/multitickets",
        "/_auth/_sidebar/deals/passports",
        "/_auth/_sidebar/deals/potentials",
        "/_auth/_sidebar/deals/quotations",
        "/_auth/_sidebar/deals/services",
        "/_auth/_sidebar/deals/tickets",
        "/_auth/_sidebar/execution/analytics",
        "/_auth/_sidebar/execution/bills-of-lading",
        "/_auth/_sidebar/execution/consignments",
        "/_auth/_sidebar/execution/customs-declarations",
        "/_auth/_sidebar/execution/disbursement-bill-of-lading",
        "/_auth/_sidebar/execution/planning",
        "/_auth/_sidebar/execution/samples",
        "/_auth/_sidebar/execution/tariffs",
        "/_auth/_sidebar/execution/transport",
        "/_auth/_sidebar/execution/vessels",
        "/_auth/_sidebar/execution/warehouse",
        "/_auth/_sidebar/finance/accounting",
        "/_auth/_sidebar/finance/analytics",
        "/_auth/_sidebar/finance/bank-accounts",
        "/_auth/_sidebar/finance/charges-or-gains",
        "/_auth/_sidebar/finance/invoice-positions",
        "/_auth/_sidebar/finance/invoices",
        "/_auth/_sidebar/finance/offset",
        "/_auth/_sidebar/finance/payment-delays",
        "/_auth/_sidebar/finance/payment-plans",
        "/_auth/_sidebar/finance/payments",
        "/_auth/_sidebar/finance/reasignment",
        "/_auth/_sidebar/reports/dashboard",
        "/_auth/_sidebar/crm/newsletters/invoices",
        "/_auth/_sidebar/crm/newsletters/logistics",
        "/_auth/_sidebar/crm/newsletters/templates",
        "/_auth/_sidebar/reports/finance-reports/ap-ar",
        "/_auth/_sidebar/reports/finance-reports/balance",
        "/_auth/_sidebar/reports/finance-reports/budgets",
        "/_auth/_sidebar/reports/finance-reports/cash-flow",
        "/_auth/_sidebar/reports/finance-reports/costs-report",
        "/_auth/_sidebar/reports/finance-reports/passports-pnl",
        "/_auth/_sidebar/reports/finance-reports/reconciliations",
        "/_auth/_sidebar/reports/finance-reports/statistics",
        "/_auth/_sidebar/reports/management-reports/activity",
        "/_auth/_sidebar/reports/management-reports/approvals",
        "/_auth/_sidebar/reports/management-reports/compliance",
        "/_auth/_sidebar/reports/management-reports/documents-dates",
        "/_auth/_sidebar/reports/management-reports/kpi",
        "/_auth/_sidebar/reports/management-reports/signings",
        "/_auth/_sidebar/reports/management-reports/team",
        "/_auth/_sidebar/reports/risk-reports/credit-report",
        "/_auth/_sidebar/reports/risk-reports/invoices-risk",
        "/_auth/_sidebar/reports/trading-reports/contracts-execution",
        "/_auth/_sidebar/reports/trading-reports/derivatives",
        "/_auth/_sidebar/reports/trading-reports/fixing-or-hedging",
        "/_auth/_sidebar/reports/trading-reports/freight",
        "/_auth/_sidebar/reports/trading-reports/line-up",
        "/_auth/_sidebar/reports/trading-reports/physical-position-by-months",
        "/_auth/_sidebar/reports/trading-reports/physical-position-mtm",
        "/_auth/_sidebar/reports/trading-reports/position-pl-and-mtm",
        "/_auth/_sidebar/company/team/",
        "/_auth/_sidebar/company/team/$traderId/approvals",
        "/_auth/_sidebar/company/team/$traderId/charges",
        "/_auth/_sidebar/company/team/$traderId/companies",
        "/_auth/_sidebar/company/team/$traderId/contacts",
        "/_auth/_sidebar/company/team/$traderId/contracts",
        "/_auth/_sidebar/company/team/$traderId/derivatives",
        "/_auth/_sidebar/company/team/$traderId/documents",
        "/_auth/_sidebar/company/team/$traderId/finances",
        "/_auth/_sidebar/company/team/$traderId/history",
        "/_auth/_sidebar/company/team/$traderId/indicators",
        "/_auth/_sidebar/company/team/$traderId/info",
        "/_auth/_sidebar/company/team/$traderId/logistics",
        "/_auth/_sidebar/company/team/$traderId/passports",
        "/_auth/_sidebar/company/team/$traderId/settings",
        "/_auth/_sidebar/company/team/$traderId/tickets",
        "/_auth/_sidebar/company/team/$traderId/updates",
        "/_auth/_sidebar/company/team/$traderId/voyages",
        "/_auth/_sidebar/company/team/$traderId/"
      ]
    },
    "/_auth/": {
      "filePath": "_auth/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/_sidebar/start": {
      "filePath": "_auth/_sidebar/start.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/custom-fields": {
      "filePath": "_auth/_sidebar/company/custom-fields.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/documents": {
      "filePath": "_auth/_sidebar/company/documents.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/drivers": {
      "filePath": "_auth/_sidebar/company/drivers.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/company/drivers/_driver"
      ]
    },
    "/_auth/_sidebar/company/notifications": {
      "filePath": "_auth/_sidebar/company/notifications.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/quality-rule-templates": {
      "filePath": "_auth/_sidebar/company/quality-rule-templates.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/settings": {
      "filePath": "_auth/_sidebar/company/settings.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/company/settings/organization",
        "/_auth/_sidebar/company/settings/parsing",
        "/_auth/_sidebar/company/settings/personal",
        "/_auth/_sidebar/company/settings/_list/addresses",
        "/_auth/_sidebar/company/settings/_list/bots",
        "/_auth/_sidebar/company/settings/_list/chats",
        "/_auth/_sidebar/company/settings/_list/cities",
        "/_auth/_sidebar/company/settings/_list/client-chats",
        "/_auth/_sidebar/company/settings/_list/commodities",
        "/_auth/_sidebar/company/settings/_list/company-groups",
        "/_auth/_sidebar/company/settings/_list/configs",
        "/_auth/_sidebar/company/settings/_list/contract-charges",
        "/_auth/_sidebar/company/settings/_list/countries",
        "/_auth/_sidebar/company/settings/_list/currencies",
        "/_auth/_sidebar/company/settings/_list/delivery-conditions",
        "/_auth/_sidebar/company/settings/_list/derivative-accounts",
        "/_auth/_sidebar/company/settings/_list/derivatives",
        "/_auth/_sidebar/company/settings/_list/districts",
        "/_auth/_sidebar/company/settings/_list/documents",
        "/_auth/_sidebar/company/settings/_list/drivers",
        "/_auth/_sidebar/company/settings/_list/exchanges",
        "/_auth/_sidebar/company/settings/_list/finance-accounts",
        "/_auth/_sidebar/company/settings/_list/finance-bank",
        "/_auth/_sidebar/company/settings/_list/general-expenses",
        "/_auth/_sidebar/company/settings/_list/groups",
        "/_auth/_sidebar/company/settings/_list/logistic-types",
        "/_auth/_sidebar/company/settings/_list/newsletter",
        "/_auth/_sidebar/company/settings/_list/other-activities",
        "/_auth/_sidebar/company/settings/_list/payment-info",
        "/_auth/_sidebar/company/settings/_list/ports",
        "/_auth/_sidebar/company/settings/_list/quality",
        "/_auth/_sidebar/company/settings/_list/regions",
        "/_auth/_sidebar/company/settings/_list/responsibilities",
        "/_auth/_sidebar/company/settings/_list/stations",
        "/_auth/_sidebar/company/settings/_list/stock-exchange",
        "/_auth/_sidebar/company/settings/_list/terminals",
        "/_auth/_sidebar/company/settings/_list/users",
        "/_auth/_sidebar/company/settings/_list/vehicle-subtypes",
        "/_auth/_sidebar/company/settings/_list/vehicles",
        "/_auth/_sidebar/company/settings/_list/vessel-sizes"
      ]
    },
    "/_auth/_sidebar/company/tasks-templates": {
      "filePath": "_auth/_sidebar/company/tasks-templates.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/template-playground": {
      "filePath": "_auth/_sidebar/company/template-playground.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/vehicles": {
      "filePath": "_auth/_sidebar/company/vehicles.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/documents",
        "/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/history",
        "/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/info"
      ]
    },
    "/_auth/_sidebar/crm/contacts": {
      "filePath": "_auth/_sidebar/crm/contacts.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/crm/counterparties": {
      "filePath": "_auth/_sidebar/crm/counterparties.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/crm/counterparties/_counterparty"
      ]
    },
    "/_auth/_sidebar/crm/updates": {
      "filePath": "_auth/_sidebar/crm/updates.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/deals/agreements": {
      "filePath": "_auth/_sidebar/deals/agreements.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/deals/agreements/_agreement"
      ]
    },
    "/_auth/_sidebar/deals/calculator": {
      "filePath": "_auth/_sidebar/deals/calculator.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/deals/contracts": {
      "filePath": "_auth/_sidebar/deals/contracts.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/deals/market": {
      "filePath": "_auth/_sidebar/deals/market.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/deals/market/_bid",
        "/_auth/_sidebar/deals/market/_offer",
        "/_auth/_sidebar/deals/market/bids",
        "/_auth/_sidebar/deals/market/offers"
      ]
    },
    "/_auth/_sidebar/deals/multicontracts": {
      "filePath": "_auth/_sidebar/deals/multicontracts.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/deals/multiservices": {
      "filePath": "_auth/_sidebar/deals/multiservices.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/deals/multiservices/_multiservice"
      ]
    },
    "/_auth/_sidebar/deals/multitickets": {
      "filePath": "_auth/_sidebar/deals/multitickets.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/deals/multitickets/_multiticket"
      ]
    },
    "/_auth/_sidebar/deals/passports": {
      "filePath": "_auth/_sidebar/deals/passports.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/deals/passports/_passport"
      ]
    },
    "/_auth/_sidebar/deals/potentials": {
      "filePath": "_auth/_sidebar/deals/potentials.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/deals/quotations": {
      "filePath": "_auth/_sidebar/deals/quotations.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/deals/services": {
      "filePath": "_auth/_sidebar/deals/services.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/deals/services/direct-contracts",
        "/_auth/_sidebar/deals/services/general-contracts",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/additional-agreements",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/closing-contract",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/costs",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/documents",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/execution",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/finance",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/fixing-and-hedging",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/history",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/quality",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/sale-export-contract-allocations",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/tasks",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/additional-agreements",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/closing-contract",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/costs",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/documents",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/execution",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/finance",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/fixing-and-hedging",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/history",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/quality",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/sale-export-contract-allocations",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/tasks",
        "/_auth/_sidebar/deals/services/direct-contract/$contractId/",
        "/_auth/_sidebar/deals/services/general-contract/$contractId/"
      ]
    },
    "/_auth/_sidebar/deals/tickets": {
      "filePath": "_auth/_sidebar/deals/tickets.tsx",
      "parent": "/_auth/_sidebar",
      "children": [
        "/_auth/_sidebar/deals/tickets/purchase-tickets",
        "/_auth/_sidebar/deals/tickets/sale-tickets",
        "/_auth/_sidebar/deals/tickets/purchase/$ticketId/additional-agreements",
        "/_auth/_sidebar/deals/tickets/purchase/$ticketId/approvals",
        "/_auth/_sidebar/deals/tickets/purchase/$ticketId/costs",
        "/_auth/_sidebar/deals/tickets/purchase/$ticketId/documents",
        "/_auth/_sidebar/deals/tickets/purchase/$ticketId/history",
        "/_auth/_sidebar/deals/tickets/purchase/$ticketId/quality",
        "/_auth/_sidebar/deals/tickets/purchase/$ticketId/tasks",
        "/_auth/_sidebar/deals/tickets/purchase/$ticketId/telegram",
        "/_auth/_sidebar/deals/tickets/sale/$ticketId/additional-agreements",
        "/_auth/_sidebar/deals/tickets/sale/$ticketId/costs",
        "/_auth/_sidebar/deals/tickets/sale/$ticketId/documents",
        "/_auth/_sidebar/deals/tickets/sale/$ticketId/history",
        "/_auth/_sidebar/deals/tickets/sale/$ticketId/potential-purchase",
        "/_auth/_sidebar/deals/tickets/sale/$ticketId/quality",
        "/_auth/_sidebar/deals/tickets/sale/$ticketId/tasks",
        "/_auth/_sidebar/deals/tickets/sale/$ticketId/telegram",
        "/_auth/_sidebar/deals/tickets/purchase/$ticketId/",
        "/_auth/_sidebar/deals/tickets/sale/$ticketId/"
      ]
    },
    "/_auth/_sidebar/execution/analytics": {
      "filePath": "_auth/_sidebar/execution/analytics.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/execution/bills-of-lading": {
      "filePath": "_auth/_sidebar/execution/bills-of-lading.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/execution/consignments": {
      "filePath": "_auth/_sidebar/execution/consignments.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/execution/customs-declarations": {
      "filePath": "_auth/_sidebar/execution/customs-declarations.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/execution/disbursement-bill-of-lading": {
      "filePath": "_auth/_sidebar/execution/disbursement-bill-of-lading.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/execution/planning": {
      "filePath": "_auth/_sidebar/execution/planning.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/execution/samples": {
      "filePath": "_auth/_sidebar/execution/samples.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/execution/tariffs": {
      "filePath": "_auth/_sidebar/execution/tariffs.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/execution/transport": {
      "filePath": "_auth/_sidebar/execution/transport.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/execution/vessels": {
      "filePath": "_auth/_sidebar/execution/vessels.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/execution/warehouse": {
      "filePath": "_auth/_sidebar/execution/warehouse.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/accounting": {
      "filePath": "_auth/_sidebar/finance/accounting.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/analytics": {
      "filePath": "_auth/_sidebar/finance/analytics.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/bank-accounts": {
      "filePath": "_auth/_sidebar/finance/bank-accounts.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/charges-or-gains": {
      "filePath": "_auth/_sidebar/finance/charges-or-gains.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/invoice-positions": {
      "filePath": "_auth/_sidebar/finance/invoice-positions.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/invoices": {
      "filePath": "_auth/_sidebar/finance/invoices.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/offset": {
      "filePath": "_auth/_sidebar/finance/offset.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/payment-delays": {
      "filePath": "_auth/_sidebar/finance/payment-delays.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/payment-plans": {
      "filePath": "_auth/_sidebar/finance/payment-plans.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/payments": {
      "filePath": "_auth/_sidebar/finance/payments.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/finance/reasignment": {
      "filePath": "_auth/_sidebar/finance/reasignment.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/dashboard": {
      "filePath": "_auth/_sidebar/reports/dashboard.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/drivers/_driver": {
      "filePath": "_auth/_sidebar/company/drivers/_driver.tsx",
      "parent": "/_auth/_sidebar/company/drivers",
      "children": [
        "/_auth/_sidebar/company/drivers/_driver/$driverId/documents",
        "/_auth/_sidebar/company/drivers/_driver/$driverId/history",
        "/_auth/_sidebar/company/drivers/_driver/$driverId/info"
      ]
    },
    "/_auth/_sidebar/company/settings/organization": {
      "filePath": "_auth/_sidebar/company/settings/organization.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/parsing": {
      "filePath": "_auth/_sidebar/company/settings/parsing.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/personal": {
      "filePath": "_auth/_sidebar/company/settings/personal.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties",
      "children": [
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/agreements",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/approvals",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/bank-details",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contacts",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contracts",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/costs-and-gains",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/dashboard",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/derivatives",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/documents",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/execution",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/finance",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/history",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/indicators",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/potentials",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/reconciliation",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/risk",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tasks",
        "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tickets"
      ]
    },
    "/_auth/_sidebar/crm/newsletters/invoices": {
      "filePath": "_auth/_sidebar/crm/newsletters/invoices.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/crm/newsletters/logistics": {
      "filePath": "_auth/_sidebar/crm/newsletters/logistics.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/crm/newsletters/templates": {
      "filePath": "_auth/_sidebar/crm/newsletters/templates.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/deals/agreements/_agreement": {
      "filePath": "_auth/_sidebar/deals/agreements/_agreement.tsx",
      "parent": "/_auth/_sidebar/deals/agreements",
      "children": [
        "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/contracts",
        "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/custom-fields",
        "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/documents",
        "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/execution",
        "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/history",
        "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/tickets"
      ]
    },
    "/_auth/_sidebar/deals/market/_bid": {
      "filePath": "_auth/_sidebar/deals/market/_bid.tsx",
      "parent": "/_auth/_sidebar/deals/market",
      "children": [
        "/_auth/_sidebar/deals/market/_bid/$bidId/additional-agreements",
        "/_auth/_sidebar/deals/market/_bid/$bidId/approvals",
        "/_auth/_sidebar/deals/market/_bid/$bidId/costs",
        "/_auth/_sidebar/deals/market/_bid/$bidId/documents",
        "/_auth/_sidebar/deals/market/_bid/$bidId/history",
        "/_auth/_sidebar/deals/market/_bid/$bidId/potential-purchase",
        "/_auth/_sidebar/deals/market/_bid/$bidId/quality",
        "/_auth/_sidebar/deals/market/_bid/$bidId/tasks",
        "/_auth/_sidebar/deals/market/_bid/$bidId/telegram"
      ]
    },
    "/_auth/_sidebar/deals/market/_offer": {
      "filePath": "_auth/_sidebar/deals/market/_offer.tsx",
      "parent": "/_auth/_sidebar/deals/market",
      "children": [
        "/_auth/_sidebar/deals/market/_offer/$offerId/additional-agreements",
        "/_auth/_sidebar/deals/market/_offer/$offerId/approvals",
        "/_auth/_sidebar/deals/market/_offer/$offerId/costs",
        "/_auth/_sidebar/deals/market/_offer/$offerId/documents",
        "/_auth/_sidebar/deals/market/_offer/$offerId/history",
        "/_auth/_sidebar/deals/market/_offer/$offerId/quality",
        "/_auth/_sidebar/deals/market/_offer/$offerId/tasks",
        "/_auth/_sidebar/deals/market/_offer/$offerId/telegram"
      ]
    },
    "/_auth/_sidebar/deals/market/bids": {
      "filePath": "_auth/_sidebar/deals/market/bids.tsx",
      "parent": "/_auth/_sidebar/deals/market"
    },
    "/_auth/_sidebar/deals/market/offers": {
      "filePath": "_auth/_sidebar/deals/market/offers.tsx",
      "parent": "/_auth/_sidebar/deals/market"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices",
      "children": [
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/additional-agreements",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/approvals",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/closing-contract",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/costs-gains",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/dashboard",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/documents",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/execution",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/finance",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/fixing-and-hedging",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/history",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/positions",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/print-and-sign",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/profit-and-loss",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/quality",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/sale-export-contract-allocations",
        "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/tasks"
      ]
    },
    "/_auth/_sidebar/deals/multitickets/_multiticket": {
      "filePath": "_auth/_sidebar/deals/multitickets/_multiticket.tsx",
      "parent": "/_auth/_sidebar/deals/multitickets",
      "children": [
        "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/additional-agreements",
        "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/approvals",
        "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/costs-gains",
        "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/documents",
        "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/history",
        "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/positions",
        "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/potential-purchase",
        "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/quality",
        "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/tasks"
      ]
    },
    "/_auth/_sidebar/deals/passports/_passport": {
      "filePath": "_auth/_sidebar/deals/passports/_passport.tsx",
      "parent": "/_auth/_sidebar/deals/passports",
      "children": [
        "/_auth/_sidebar/deals/passports/_passport/$passportId/comments",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/contacts",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/contracts",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/cost-of-goods",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/dashboard",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/derivatives",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/documents",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/execution",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/finance",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/invoices",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/tasks",
        "/_auth/_sidebar/deals/passports/_passport/$passportId/tickets"
      ]
    },
    "/_auth/_sidebar/deals/services/direct-contracts": {
      "filePath": "_auth/_sidebar/deals/services/direct-contracts.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contracts": {
      "filePath": "_auth/_sidebar/deals/services/general-contracts.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/tickets/purchase-tickets": {
      "filePath": "_auth/_sidebar/deals/tickets/purchase-tickets.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/sale-tickets": {
      "filePath": "_auth/_sidebar/deals/tickets/sale-tickets.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/reports/finance-reports/ap-ar": {
      "filePath": "_auth/_sidebar/reports/finance-reports/ap-ar.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/finance-reports/balance": {
      "filePath": "_auth/_sidebar/reports/finance-reports/balance.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/finance-reports/budgets": {
      "filePath": "_auth/_sidebar/reports/finance-reports/budgets.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/finance-reports/cash-flow": {
      "filePath": "_auth/_sidebar/reports/finance-reports/cash-flow.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/finance-reports/costs-report": {
      "filePath": "_auth/_sidebar/reports/finance-reports/costs-report.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/finance-reports/passports-pnl": {
      "filePath": "_auth/_sidebar/reports/finance-reports/passports-pnl.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/finance-reports/reconciliations": {
      "filePath": "_auth/_sidebar/reports/finance-reports/reconciliations.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/finance-reports/statistics": {
      "filePath": "_auth/_sidebar/reports/finance-reports/statistics.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/management-reports/activity": {
      "filePath": "_auth/_sidebar/reports/management-reports/activity.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/management-reports/approvals": {
      "filePath": "_auth/_sidebar/reports/management-reports/approvals.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/management-reports/compliance": {
      "filePath": "_auth/_sidebar/reports/management-reports/compliance.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/management-reports/documents-dates": {
      "filePath": "_auth/_sidebar/reports/management-reports/documents-dates.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/management-reports/kpi": {
      "filePath": "_auth/_sidebar/reports/management-reports/kpi.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/management-reports/signings": {
      "filePath": "_auth/_sidebar/reports/management-reports/signings.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/management-reports/team": {
      "filePath": "_auth/_sidebar/reports/management-reports/team.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/risk-reports/credit-report": {
      "filePath": "_auth/_sidebar/reports/risk-reports/credit-report.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/risk-reports/invoices-risk": {
      "filePath": "_auth/_sidebar/reports/risk-reports/invoices-risk.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/trading-reports/contracts-execution": {
      "filePath": "_auth/_sidebar/reports/trading-reports/contracts-execution.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/trading-reports/derivatives": {
      "filePath": "_auth/_sidebar/reports/trading-reports/derivatives.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/trading-reports/fixing-or-hedging": {
      "filePath": "_auth/_sidebar/reports/trading-reports/fixing-or-hedging.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/trading-reports/freight": {
      "filePath": "_auth/_sidebar/reports/trading-reports/freight.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/trading-reports/line-up": {
      "filePath": "_auth/_sidebar/reports/trading-reports/line-up.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/trading-reports/physical-position-by-months": {
      "filePath": "_auth/_sidebar/reports/trading-reports/physical-position-by-months.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/trading-reports/physical-position-mtm": {
      "filePath": "_auth/_sidebar/reports/trading-reports/physical-position-mtm.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/reports/trading-reports/position-pl-and-mtm": {
      "filePath": "_auth/_sidebar/reports/trading-reports/position-pl-and-mtm.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/": {
      "filePath": "_auth/_sidebar/company/team/index.ts",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/settings/_list/addresses": {
      "filePath": "_auth/_sidebar/company/settings/_list/addresses.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/bots": {
      "filePath": "_auth/_sidebar/company/settings/_list/bots.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/chats": {
      "filePath": "_auth/_sidebar/company/settings/_list/chats.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/cities": {
      "filePath": "_auth/_sidebar/company/settings/_list/cities.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/client-chats": {
      "filePath": "_auth/_sidebar/company/settings/_list/client-chats.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/commodities": {
      "filePath": "_auth/_sidebar/company/settings/_list/commodities.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/company-groups": {
      "filePath": "_auth/_sidebar/company/settings/_list/company-groups.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/configs": {
      "filePath": "_auth/_sidebar/company/settings/_list/configs.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/contract-charges": {
      "filePath": "_auth/_sidebar/company/settings/_list/contract-charges.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/countries": {
      "filePath": "_auth/_sidebar/company/settings/_list/countries.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/currencies": {
      "filePath": "_auth/_sidebar/company/settings/_list/currencies.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/delivery-conditions": {
      "filePath": "_auth/_sidebar/company/settings/_list/delivery-conditions.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/derivative-accounts": {
      "filePath": "_auth/_sidebar/company/settings/_list/derivative-accounts.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/derivatives": {
      "filePath": "_auth/_sidebar/company/settings/_list/derivatives.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/districts": {
      "filePath": "_auth/_sidebar/company/settings/_list/districts.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/documents": {
      "filePath": "_auth/_sidebar/company/settings/_list/documents.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/drivers": {
      "filePath": "_auth/_sidebar/company/settings/_list/drivers.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/exchanges": {
      "filePath": "_auth/_sidebar/company/settings/_list/exchanges.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/finance-accounts": {
      "filePath": "_auth/_sidebar/company/settings/_list/finance-accounts.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/finance-bank": {
      "filePath": "_auth/_sidebar/company/settings/_list/finance-bank.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/general-expenses": {
      "filePath": "_auth/_sidebar/company/settings/_list/general-expenses.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/groups": {
      "filePath": "_auth/_sidebar/company/settings/_list/groups.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/logistic-types": {
      "filePath": "_auth/_sidebar/company/settings/_list/logistic-types.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/newsletter": {
      "filePath": "_auth/_sidebar/company/settings/_list/newsletter.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/other-activities": {
      "filePath": "_auth/_sidebar/company/settings/_list/other-activities.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/payment-info": {
      "filePath": "_auth/_sidebar/company/settings/_list/payment-info.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/ports": {
      "filePath": "_auth/_sidebar/company/settings/_list/ports.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/quality": {
      "filePath": "_auth/_sidebar/company/settings/_list/quality.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/regions": {
      "filePath": "_auth/_sidebar/company/settings/_list/regions.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/responsibilities": {
      "filePath": "_auth/_sidebar/company/settings/_list/responsibilities.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/stations": {
      "filePath": "_auth/_sidebar/company/settings/_list/stations.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/stock-exchange": {
      "filePath": "_auth/_sidebar/company/settings/_list/stock-exchange.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/terminals": {
      "filePath": "_auth/_sidebar/company/settings/_list/terminals.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/users": {
      "filePath": "_auth/_sidebar/company/settings/_list/users.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/vehicle-subtypes": {
      "filePath": "_auth/_sidebar/company/settings/_list/vehicle-subtypes.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/vehicles": {
      "filePath": "_auth/_sidebar/company/settings/_list/vehicles.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/settings/_list/vessel-sizes": {
      "filePath": "_auth/_sidebar/company/settings/_list/vessel-sizes.tsx",
      "parent": "/_auth/_sidebar/company/settings"
    },
    "/_auth/_sidebar/company/team/$traderId/approvals": {
      "filePath": "_auth/_sidebar/company/team/$traderId/approvals.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/charges": {
      "filePath": "_auth/_sidebar/company/team/$traderId/charges.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/companies": {
      "filePath": "_auth/_sidebar/company/team/$traderId/companies.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/contacts": {
      "filePath": "_auth/_sidebar/company/team/$traderId/contacts.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/contracts": {
      "filePath": "_auth/_sidebar/company/team/$traderId/contracts.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/derivatives": {
      "filePath": "_auth/_sidebar/company/team/$traderId/derivatives.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/documents": {
      "filePath": "_auth/_sidebar/company/team/$traderId/documents.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/finances": {
      "filePath": "_auth/_sidebar/company/team/$traderId/finances.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/history": {
      "filePath": "_auth/_sidebar/company/team/$traderId/history.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/indicators": {
      "filePath": "_auth/_sidebar/company/team/$traderId/indicators.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/info": {
      "filePath": "_auth/_sidebar/company/team/$traderId/info.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/logistics": {
      "filePath": "_auth/_sidebar/company/team/$traderId/logistics.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/passports": {
      "filePath": "_auth/_sidebar/company/team/$traderId/passports.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/settings": {
      "filePath": "_auth/_sidebar/company/team/$traderId/settings.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/tickets": {
      "filePath": "_auth/_sidebar/company/team/$traderId/tickets.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/updates": {
      "filePath": "_auth/_sidebar/company/team/$traderId/updates.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/voyages": {
      "filePath": "_auth/_sidebar/company/team/$traderId/voyages.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/team/$traderId/": {
      "filePath": "_auth/_sidebar/company/team/$traderId/index.tsx",
      "parent": "/_auth/_sidebar"
    },
    "/_auth/_sidebar/company/drivers/_driver/$driverId/documents": {
      "filePath": "_auth/_sidebar/company/drivers/_driver/$driverId/documents.tsx",
      "parent": "/_auth/_sidebar/company/drivers/_driver"
    },
    "/_auth/_sidebar/company/drivers/_driver/$driverId/history": {
      "filePath": "_auth/_sidebar/company/drivers/_driver/$driverId/history.tsx",
      "parent": "/_auth/_sidebar/company/drivers/_driver"
    },
    "/_auth/_sidebar/company/drivers/_driver/$driverId/info": {
      "filePath": "_auth/_sidebar/company/drivers/_driver/$driverId/info.tsx",
      "parent": "/_auth/_sidebar/company/drivers/_driver"
    },
    "/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/documents": {
      "filePath": "_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/documents.tsx",
      "parent": "/_auth/_sidebar/company/vehicles"
    },
    "/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/history": {
      "filePath": "_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/history.tsx",
      "parent": "/_auth/_sidebar/company/vehicles"
    },
    "/_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/info": {
      "filePath": "_auth/_sidebar/company/vehicles/_vehicle/$vehicleId/info.tsx",
      "parent": "/_auth/_sidebar/company/vehicles"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/agreements": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/agreements.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/approvals": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/approvals.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/bank-details": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/bank-details.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contacts": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contacts.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contracts": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/contracts.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/costs-and-gains": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/costs-and-gains.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/dashboard": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/dashboard.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/derivatives": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/derivatives.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/documents": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/documents.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/execution": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/execution.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/finance": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/finance.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/history": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/history.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/indicators": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/indicators.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/potentials": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/potentials.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/reconciliation": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/reconciliation.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/risk": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/risk.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tasks": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tasks.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tickets": {
      "filePath": "_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/tickets.tsx",
      "parent": "/_auth/_sidebar/crm/counterparties/_counterparty"
    },
    "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/contracts": {
      "filePath": "_auth/_sidebar/deals/agreements/_agreement/$agreementId/contracts.tsx",
      "parent": "/_auth/_sidebar/deals/agreements/_agreement"
    },
    "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/custom-fields": {
      "filePath": "_auth/_sidebar/deals/agreements/_agreement/$agreementId/custom-fields.tsx",
      "parent": "/_auth/_sidebar/deals/agreements/_agreement"
    },
    "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/documents": {
      "filePath": "_auth/_sidebar/deals/agreements/_agreement/$agreementId/documents.tsx",
      "parent": "/_auth/_sidebar/deals/agreements/_agreement"
    },
    "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/execution": {
      "filePath": "_auth/_sidebar/deals/agreements/_agreement/$agreementId/execution.tsx",
      "parent": "/_auth/_sidebar/deals/agreements/_agreement"
    },
    "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/history": {
      "filePath": "_auth/_sidebar/deals/agreements/_agreement/$agreementId/history.tsx",
      "parent": "/_auth/_sidebar/deals/agreements/_agreement"
    },
    "/_auth/_sidebar/deals/agreements/_agreement/$agreementId/tickets": {
      "filePath": "_auth/_sidebar/deals/agreements/_agreement/$agreementId/tickets.tsx",
      "parent": "/_auth/_sidebar/deals/agreements/_agreement"
    },
    "/_auth/_sidebar/deals/market/_bid/$bidId/additional-agreements": {
      "filePath": "_auth/_sidebar/deals/market/_bid/$bidId/additional-agreements.tsx",
      "parent": "/_auth/_sidebar/deals/market/_bid"
    },
    "/_auth/_sidebar/deals/market/_bid/$bidId/approvals": {
      "filePath": "_auth/_sidebar/deals/market/_bid/$bidId/approvals.tsx",
      "parent": "/_auth/_sidebar/deals/market/_bid"
    },
    "/_auth/_sidebar/deals/market/_bid/$bidId/costs": {
      "filePath": "_auth/_sidebar/deals/market/_bid/$bidId/costs.tsx",
      "parent": "/_auth/_sidebar/deals/market/_bid"
    },
    "/_auth/_sidebar/deals/market/_bid/$bidId/documents": {
      "filePath": "_auth/_sidebar/deals/market/_bid/$bidId/documents.tsx",
      "parent": "/_auth/_sidebar/deals/market/_bid"
    },
    "/_auth/_sidebar/deals/market/_bid/$bidId/history": {
      "filePath": "_auth/_sidebar/deals/market/_bid/$bidId/history.tsx",
      "parent": "/_auth/_sidebar/deals/market/_bid"
    },
    "/_auth/_sidebar/deals/market/_bid/$bidId/potential-purchase": {
      "filePath": "_auth/_sidebar/deals/market/_bid/$bidId/potential-purchase.tsx",
      "parent": "/_auth/_sidebar/deals/market/_bid"
    },
    "/_auth/_sidebar/deals/market/_bid/$bidId/quality": {
      "filePath": "_auth/_sidebar/deals/market/_bid/$bidId/quality.tsx",
      "parent": "/_auth/_sidebar/deals/market/_bid"
    },
    "/_auth/_sidebar/deals/market/_bid/$bidId/tasks": {
      "filePath": "_auth/_sidebar/deals/market/_bid/$bidId/tasks.tsx",
      "parent": "/_auth/_sidebar/deals/market/_bid"
    },
    "/_auth/_sidebar/deals/market/_bid/$bidId/telegram": {
      "filePath": "_auth/_sidebar/deals/market/_bid/$bidId/telegram.tsx",
      "parent": "/_auth/_sidebar/deals/market/_bid"
    },
    "/_auth/_sidebar/deals/market/_offer/$offerId/additional-agreements": {
      "filePath": "_auth/_sidebar/deals/market/_offer/$offerId/additional-agreements.tsx",
      "parent": "/_auth/_sidebar/deals/market/_offer"
    },
    "/_auth/_sidebar/deals/market/_offer/$offerId/approvals": {
      "filePath": "_auth/_sidebar/deals/market/_offer/$offerId/approvals.tsx",
      "parent": "/_auth/_sidebar/deals/market/_offer"
    },
    "/_auth/_sidebar/deals/market/_offer/$offerId/costs": {
      "filePath": "_auth/_sidebar/deals/market/_offer/$offerId/costs.tsx",
      "parent": "/_auth/_sidebar/deals/market/_offer"
    },
    "/_auth/_sidebar/deals/market/_offer/$offerId/documents": {
      "filePath": "_auth/_sidebar/deals/market/_offer/$offerId/documents.tsx",
      "parent": "/_auth/_sidebar/deals/market/_offer"
    },
    "/_auth/_sidebar/deals/market/_offer/$offerId/history": {
      "filePath": "_auth/_sidebar/deals/market/_offer/$offerId/history.tsx",
      "parent": "/_auth/_sidebar/deals/market/_offer"
    },
    "/_auth/_sidebar/deals/market/_offer/$offerId/quality": {
      "filePath": "_auth/_sidebar/deals/market/_offer/$offerId/quality.tsx",
      "parent": "/_auth/_sidebar/deals/market/_offer"
    },
    "/_auth/_sidebar/deals/market/_offer/$offerId/tasks": {
      "filePath": "_auth/_sidebar/deals/market/_offer/$offerId/tasks.tsx",
      "parent": "/_auth/_sidebar/deals/market/_offer"
    },
    "/_auth/_sidebar/deals/market/_offer/$offerId/telegram": {
      "filePath": "_auth/_sidebar/deals/market/_offer/$offerId/telegram.tsx",
      "parent": "/_auth/_sidebar/deals/market/_offer"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/additional-agreements": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/additional-agreements.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/approvals": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/approvals.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/closing-contract": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/closing-contract.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/costs-gains": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/costs-gains.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/dashboard": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/dashboard.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/documents": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/documents.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/execution": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/execution.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/finance": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/finance.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/fixing-and-hedging": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/fixing-and-hedging.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/history": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/history.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/positions": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/positions.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/print-and-sign": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/print-and-sign.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/profit-and-loss": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/profit-and-loss.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/quality": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/quality.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/sale-export-contract-allocations": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/sale-export-contract-allocations.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/tasks": {
      "filePath": "_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/tasks.tsx",
      "parent": "/_auth/_sidebar/deals/multiservices/_multiservice"
    },
    "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/additional-agreements": {
      "filePath": "_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/additional-agreements.tsx",
      "parent": "/_auth/_sidebar/deals/multitickets/_multiticket"
    },
    "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/approvals": {
      "filePath": "_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/approvals.tsx",
      "parent": "/_auth/_sidebar/deals/multitickets/_multiticket"
    },
    "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/costs-gains": {
      "filePath": "_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/costs-gains.tsx",
      "parent": "/_auth/_sidebar/deals/multitickets/_multiticket"
    },
    "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/documents": {
      "filePath": "_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/documents.tsx",
      "parent": "/_auth/_sidebar/deals/multitickets/_multiticket"
    },
    "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/history": {
      "filePath": "_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/history.tsx",
      "parent": "/_auth/_sidebar/deals/multitickets/_multiticket"
    },
    "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/positions": {
      "filePath": "_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/positions.tsx",
      "parent": "/_auth/_sidebar/deals/multitickets/_multiticket"
    },
    "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/potential-purchase": {
      "filePath": "_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/potential-purchase.tsx",
      "parent": "/_auth/_sidebar/deals/multitickets/_multiticket"
    },
    "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/quality": {
      "filePath": "_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/quality.tsx",
      "parent": "/_auth/_sidebar/deals/multitickets/_multiticket"
    },
    "/_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/tasks": {
      "filePath": "_auth/_sidebar/deals/multitickets/_multiticket/$multiticketId/tasks.tsx",
      "parent": "/_auth/_sidebar/deals/multitickets/_multiticket"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/comments": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/comments.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/contacts": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/contacts.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/contracts": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/contracts.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/cost-of-goods": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/cost-of-goods.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/dashboard": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/dashboard.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/derivatives": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/derivatives.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/documents": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/documents.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/execution": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/execution.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/finance": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/finance.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/invoices": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/invoices.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/tasks": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/tasks.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/passports/_passport/$passportId/tickets": {
      "filePath": "_auth/_sidebar/deals/passports/_passport/$passportId/tickets.tsx",
      "parent": "/_auth/_sidebar/deals/passports/_passport"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/additional-agreements": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/additional-agreements.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/closing-contract": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/closing-contract.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/costs": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/costs.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/documents": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/documents.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/execution": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/execution.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/finance": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/finance.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/fixing-and-hedging": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/fixing-and-hedging.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/history": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/history.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/quality": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/quality.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/sale-export-contract-allocations": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/sale-export-contract-allocations.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/tasks": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/tasks.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/additional-agreements": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/additional-agreements.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/closing-contract": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/closing-contract.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/costs": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/costs.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/documents": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/documents.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/execution": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/execution.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/finance": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/finance.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/fixing-and-hedging": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/fixing-and-hedging.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/history": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/history.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/quality": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/quality.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/sale-export-contract-allocations": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/sale-export-contract-allocations.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/tasks": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/tasks.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/tickets/purchase/$ticketId/additional-agreements": {
      "filePath": "_auth/_sidebar/deals/tickets/purchase/$ticketId/additional-agreements.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/purchase/$ticketId/approvals": {
      "filePath": "_auth/_sidebar/deals/tickets/purchase/$ticketId/approvals.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/purchase/$ticketId/costs": {
      "filePath": "_auth/_sidebar/deals/tickets/purchase/$ticketId/costs.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/purchase/$ticketId/documents": {
      "filePath": "_auth/_sidebar/deals/tickets/purchase/$ticketId/documents.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/purchase/$ticketId/history": {
      "filePath": "_auth/_sidebar/deals/tickets/purchase/$ticketId/history.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/purchase/$ticketId/quality": {
      "filePath": "_auth/_sidebar/deals/tickets/purchase/$ticketId/quality.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/purchase/$ticketId/tasks": {
      "filePath": "_auth/_sidebar/deals/tickets/purchase/$ticketId/tasks.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/purchase/$ticketId/telegram": {
      "filePath": "_auth/_sidebar/deals/tickets/purchase/$ticketId/telegram.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/sale/$ticketId/additional-agreements": {
      "filePath": "_auth/_sidebar/deals/tickets/sale/$ticketId/additional-agreements.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/sale/$ticketId/costs": {
      "filePath": "_auth/_sidebar/deals/tickets/sale/$ticketId/costs.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/sale/$ticketId/documents": {
      "filePath": "_auth/_sidebar/deals/tickets/sale/$ticketId/documents.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/sale/$ticketId/history": {
      "filePath": "_auth/_sidebar/deals/tickets/sale/$ticketId/history.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/sale/$ticketId/potential-purchase": {
      "filePath": "_auth/_sidebar/deals/tickets/sale/$ticketId/potential-purchase.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/sale/$ticketId/quality": {
      "filePath": "_auth/_sidebar/deals/tickets/sale/$ticketId/quality.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/sale/$ticketId/tasks": {
      "filePath": "_auth/_sidebar/deals/tickets/sale/$ticketId/tasks.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/sale/$ticketId/telegram": {
      "filePath": "_auth/_sidebar/deals/tickets/sale/$ticketId/telegram.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/services/direct-contract/$contractId/": {
      "filePath": "_auth/_sidebar/deals/services/direct-contract/$contractId/index.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/services/general-contract/$contractId/": {
      "filePath": "_auth/_sidebar/deals/services/general-contract/$contractId/index.tsx",
      "parent": "/_auth/_sidebar/deals/services"
    },
    "/_auth/_sidebar/deals/tickets/purchase/$ticketId/": {
      "filePath": "_auth/_sidebar/deals/tickets/purchase/$ticketId/index.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    },
    "/_auth/_sidebar/deals/tickets/sale/$ticketId/": {
      "filePath": "_auth/_sidebar/deals/tickets/sale/$ticketId/index.tsx",
      "parent": "/_auth/_sidebar/deals/tickets"
    }
  }
}
ROUTE_MANIFEST_END */
