import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import { useComponent } from '~/shared/lib/components';

import type { CounterpartiesContainerProps } from './counterparties-container.model';
import { CounterpartiesContainerModel } from './counterparties-container.model';

export const CounterpartiesContainer = (props: CounterpartiesContainerProps) => {
  const { ComponentProvider } = useComponent(props, CounterpartiesContainerModel);

  return (
    <ComponentProvider>
      <EntityListContainer />
    </ComponentProvider>
  );
};
