import type ng from 'angular';

import template from './contract-list-list-view.html?raw';

import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const ContractListListView = {
  bindings: {
    contracts: '<',
    count: '<',
    filterLevel: '<',
    total: '<',
    activeTab: '<',
    applyFilters: '&',
    openContractModal: '&',
    cloneContract: '&',
    openEmailModal: '&',
    openDocxModal: '&',
    openDocumentModal: '&',
    approveContract: '&',
    addLogistic: '&',
    addFinance: '&',
    reverseContract: '&',
    createPassport: '&',
    connectToPassport: '&',
    openVoyageModal: '&',
    openRoleModal: '&',
    openStageModal: '&',
    extraFields: '<?',
    showConnected: '<?',
    onConnect: '&?',
    expanded: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    'ContractsService',
    '$scope',
    class {
      $rootScope: GtRootScopeService;
      ContractsService: ContractsService;
      contracts: any;
      count: number;
      extraFields: any;
      gettext: ng.gettext.gettextFunction;
      mode: any;
      queryParams: QueryParams = {};
      selectedContract: any;
      selectedContractData: any;
      tableData: any;
      tableOptions: any;
      constructor(
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        ContractsService: ContractsService,
        $scope: ng.IScope,
      ) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.ContractsService = ContractsService;

        this.contracts = [];
        this.count = 0;

        this.tableOptions = {};
        this.tableData = {};
        this.queryParams = { serializer: 'table_info' };
        this.extraFields = [];
        this.selectedContractData = null;

        $scope.$watch('$ctrl.selectedContract', (newValue: any, oldValue: any) => {
          if (newValue !== oldValue) {
            this.selectedContractData = null;
            this.updateSelectedContractData(newValue);
          }
        });
      }

      $onInit() {
        this.contracts = this.contracts || [];
        this.count = this.count || 0;
        if (this.selectedContract) {
          this.updateSelectedContractData(this.selectedContract);
        }
      }

      getContract(contract: any) {
        return this.ContractsService.Contract.query({ id: contract.id, serializer: 'modal' })
          .$promise;
      }

      selectContract(contract: any) {
        this.getContract(contract).then((selectedContract: any) => {
          this.selectedContract = selectedContract;
        });
      }

      $onChanges(changes: any) {
        if (changes.selectedContract?.currentValue) {
          this.updateSelectedContractData(changes.selectedContract.currentValue);
        }
      }

      getContractType(selectedContract: any) {
        return this.ContractsService.Contract.get({
          id: selectedContract.id,
          fields: ['contract_type', 'deal_type'],
          stage: 'contract',
        }).$promise;
      }

      updateSelectedContractData(selectedContract: any) {
        this.getContractType(selectedContract).then((contract: any) => {
          this.selectedContractData = {
            contract: contract,
            mode: this.mode,
          };
        });
      }
    },
  ],
};
