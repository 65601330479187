import React from 'react';

import { errorHandler } from '~/shared/lib/errors';
import { useForm, v } from '~/shared/lib/forms';
import { IconSelector } from '~/shared/ui/components/icon-selector';
import { Button } from '~/shared/ui/kit/button';
import { Input } from '~/shared/ui/kit/input';

import type { Preset } from '../../types';

export const PresetForm: React.FC<{ preset: Preset; onSave: (preset: Preset) => void }> = ({
  preset,
  onSave,
}) => {
  const form = useForm({
    defaultValues: preset,
    onSubmit: ({ value }) => onSave({ ...preset, ...value }),
  });

  return (
    <form
      className="flex flex-col"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit().catch(errorHandler);
      }}
    >
      <div className="mb-3 flex gap-2 px-2">
        <form.Field name="icon">
          {(field) => (
            <IconSelector
              selectedIcon={field.state.value || 'HeartIcon'}
              onSelect={field.handleChange}
            />
          )}
        </form.Field>

        <form.Field
          name="title"
          validators={{
            onChange: v.pipe(v.string(), v.minLength(1, 'Title is required')),
          }}
        >
          {(field) => (
            <Input
              placeholder="Type Here"
              intent={field.state.meta.errors.length ? 'danger' : 'default'}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              required
            />
          )}
        </form.Field>
      </div>
      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit]) => (
          <div className="border-stroke-main-medium border-t p-2">
            <Button variant="primary" type="submit" disabled={!canSubmit} fullWidth>
              Save
            </Button>
          </div>
        )}
      </form.Subscribe>
    </form>
  );
};
