import ng from 'angular';

import { OffersListPage } from './components/offers-list-page/offers-list-page.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const offers = ng
  .module('market.offers', [])
  .component('offersListPage', OffersListPage)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('market.offers', {
        url: '/offers/list/',
        component: 'offersListPage',
        data: {
          pageTitle: gettext('Offers'),
        },
        resolve: {
          filterLevel: () => {
            return 'offers-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('offers-list-page');
            },
          ],
        },
      });
    },
  ]).name;
