import React from 'react';

import { Dropdown, type DropdownMenuItem } from '~/shared/ui/kit/dropdown-menu';
import { Tag, type TagVariants } from '~/shared/ui/kit/tag';

import { type FinanceRecord } from '../../lib';

const approvalStatusVariant: Record<string, TagVariants['variant']> = {
  wait: 'yellow',
  rejected: 'red',
  approved: 'green',
  process: 'gray',
};
const paymentStatusVariant: Record<string, TagVariants['variant']> = {
  new: 'green',
  rejected: 'red',
  approved: 'sky',
  executed: 'gray',
};

export const PaymentPlansTemplate: React.FC<{
  paymentPlans: FinanceRecord['paymentplans_data'];
  currencySymbol: FinanceRecord['currency_symbol'];
}> = ({ paymentPlans, currencySymbol }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const dropdownItems: DropdownMenuItem[] = paymentPlans.map((plan) => ({
    label: (
      <div
        ref={containerRef}
        className="flex max-w-[250px] items-center gap-3 overflow-hidden whitespace-nowrap"
        data-testid="payment-plans-template"
      >
        <span>{plan.name}</span>

        <Tag variant={approvalStatusVariant[plan.approval_status]}>{plan.approval_status}</Tag>

        <span>
          {plan.value} {currencySymbol}
        </span>

        <Tag variant={paymentStatusVariant[plan.payment_status]}>{plan.payment_status}</Tag>
      </div>
    ),
    value: String(plan.id),
  }));

  return dropdownItems.length > 1 ? (
    <Dropdown
      title={dropdownItems[0].label}
      items={dropdownItems.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
    />
  ) : (
    <Dropdown title={dropdownItems[0].label} />
  );
};
