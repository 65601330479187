import {
  BaseLogisticRepository,
  LogisticQueryParams,
  PnlLogisticListTotals,
  PnlLogisticRecord,
} from '~/features/execution/logistic/common';
import { logisticsLogisticsListPnlList, logisticsLogisticsTotalsPnlRetrieve } from '~/shared/api';
import { singleton } from '~/shared/lib/di';

@singleton()
export class PnlLogisticRepository extends BaseLogisticRepository<
  PnlLogisticRecord,
  PnlLogisticListTotals
> {
  queryFn = async (query: LogisticQueryParams) => {
    const { data } = await logisticsLogisticsListPnlList({ query });
    return { records: data.results, count: data.count };
  };
  getListTotals = async (query: LogisticQueryParams) => {
    const { data } = await logisticsLogisticsTotalsPnlRetrieve({ query });
    return data;
  };
}
