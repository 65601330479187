export class WarehouseShipmentService {
  $resource: ng.resource.IResourceService;
  warehouseShipmentResource: any;
  constructor($resource: ng.resource.IResourceService) {
    this.$resource = $resource;
    this.warehouseShipmentResource = $resource(
      '/api/logistics/logistic-consignment/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/logistics/logistic-consignment/bulk_create_or_update/',
        },
        listInfo: {
          method: 'GET',
          url: '/api/logistics/logistic-consignment-list/info/',
        },
        getContractChargesData: {
          method: 'GET',
          url: '/api/logistics/logistic-consignment/get_contractcharges_data/',
        },
      },
    );
  }

  getListData(params: object) {
    return this.warehouseShipmentResource.listInfo(params).$promise;
  }

  getContractChargesData(params: object) {
    return this.warehouseShipmentResource.getContractChargesData(params).$promise;
  }
}
WarehouseShipmentService.$inject = ['$resource'];
