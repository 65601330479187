import type ng from 'angular';

import numberCellTemplate from './number-cell-template.html?raw';
import template from './offset-list-table-view.html?raw';

import { html } from '^/shared/utils';

export const OffsetListTableView = {
  bindings: {
    offsets: '<',
    count: '<',
    total: '<',
    filterLevel: '<',
    editOffset: '&',
    openDocxModal: '&',
    applyFilters: '&',
    onSave: '&',
    addButton: '<',
    openOffsetModal: '&',
  },
  template,
  controller: [
    'gettext',
    class {
      applyFilters: any;
      count = 0;
      editOffset: any;
      filterLevel = '';
      gettext: ng.gettext.gettextFunction;
      offsets: any;
      onSave: any;
      openDocxModal: any;
      tableData: any;
      tableOptions: any;
      total: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;

        this.offsets = undefined;
      }

      $onInit() {
        this.offsets = [];
        this.count = 0;
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.offsets || changes.count || changes.total) {
          this.tableData = { rows: this.offsets, count: this.count, total: this.total };
        }
      }

      getOffsetsCount() {
        return this.offsets.length;
      }

      getTableOptions() {
        const options: any = {
          tableName: 'offsets',
          tableClass: ['request-table'],
          filterLevel: this.filterLevel,
          configurable: true,
          applyFilters: this.applyFilters,
          templateArgs: {
            editOffset: (offset: any) => this.editOffset({ offset: offset }),
            openDocxModal: (offset: any) => this.openDocxModal({ offset: offset }),
            save: (data: any) => this.onSave({ offset: data }),
            getOffsetsCount: () => this.getOffsetsCount(),
          },
          columnDefs: [] as any[],
          tabs: [],
        };

        options.columnDefs = [
          {
            columnName: 'actions',
            title: this.gettext('actions'),
            cellTemplate: html`
              <a ng-if="!item.edit" ng-click="item.edit = true" class="btn btn-xs btn-add-dark">
                <i class="fa fa-pencil-square"></i>
              </a>
              <a
                ng-if="item.edit == true"
                ng-click="item.edit = false;args.save(item)"
                class="btn btn-xs btn-success btn_success"
              >
                <i class="fa fa-floppy-o"></i>
              </a>
              <a ng-if="item.edit" class="btn btn-xs" ng-click="item.edit = false;">
                <i class="fa fa-times"></i>
              </a>
            `,
          },
          {
            columnName: 'number',
            title: this.gettext('Number'),
            cellTemplate: numberCellTemplate,
            predicate: 'number',
            totalTemplate: html`
              <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate><br />
              <label>{[{ args.getOffsetsCount() }]} / {[{ item.total_count }]}</label>
            `,
            totalBlockTemplates: [
              html`
                <span class="label"> {[{ count }]} </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>count</translate>
                </span>
              `,
            ],
          },
          {
            title: this.gettext('Offset contracts'),
            columnName: 'offset_contracts_data',
            class: 'td-right-align nowrap',
            cellTemplate: html`
              <span
                ng-repeat="contract in item.offset_unique_contracts_data"
                ng-if="item.offset_contracts_data"
              >
                <span ng-if="contract.contract_number">
                  <a ui-sref="gt.page.contract({id: contract.id })" class="btn-link">
                    <i class="fa fa-file-text-o"></i> {[{ contract.contract_number | cut:true:30:' '
                    }]}
                  </a>
                  <span> ({[{ contract.amount_to_connect_sum || 0 | number:2 }]}) </span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
          },
          {
            columnName: 'has_docs_original',
            title: this.gettext('Document original'),
            cellTemplate: html`
              <input
                style="width: 100%;"
                type="checkbox"
                ng-if="item.edit == true"
                ng-model="item.has_docs_original"
              />
              <span ng-if="!item.edit && item.has_docs_original">
                <i class="fa fa-check-circle"></i>
              </span>
              <span ng-if="!item.edit && !item.has_docs_original">
                <i class="fa fa-circle-o"></i>
              </span>
            `,
            totalBlockTemplates: [
              html`
                <span class="label"> {[{ item.total_amount || 0 | number:3 }]} </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>amount</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'date',
            title: this.gettext('Date'),
            predicate: 'date',
            cellTemplate: html`
              <span ng-if="item.date"> {[{ item.date | date: 'dd.MM.yy' }]} </span>
              <span ng-if="!item.date"> --- </span>
            `,
          },
          {
            columnName: 'distributor_name',
            title: this.gettext('Distributor'),
            predicate: 'distributor__name',
            class: 'td-left-align',
            cellTemplate: html`
              <span ng-if="!item.distributor_id"> --- </span>
              <span ng-if="item.distributor_id">
                <a ui-sref="gt.role({role: 'supplier', id: item.distributor_id})">
                  {[{ item.distributor_name | cut:true:30:'...' }]}
                </a>
              </span>
            `,
          },
          {
            columnName: 'distributorClientCompanyCode',
            title: this.gettext('Distributor code'),
            class: 'td-left-align',
            cellTemplate: html`
              <span ng-if="!item.distributor_client_company_code"> --- </span>
              <span ng-if="item.distributor_client_company_code">
                {[{ item.distributor_client_company_code | cut:true:30:'...' }]}
              </span>
            `,
          },
          {
            columnName: 'amount',
            title: this.gettext('Amount'),
            predicate: 'amount',
            class: 'td-right-align',
            cellTemplate: html`{[{ item.amount || 0 | number: 2 }]} `,
            totalTemplate: html`<span>{[{ item.total_amount || 0 | number:3 }]} </span>`,
          },
          {
            columnName: 'manager',
            title: this.gettext('manager'),
            class: 'td-left-align',
            predicate: 'manager__first_name',
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.manager_first_name}">
                {[{ item.manager_first_name || '---'}]}
              </span>
            `,
          },
          {
            columnName: 'business_unit',
            title: this.gettext('Business unit'),
            predicate: 'buisness_unit__title',
            class: 'td-left-align',
            cellTemplate: html`{[{ item.business_unit_title || '---' | cut:true:30:' ...' }]}`,
          },
          {
            columnName: 'info',
            title: this.gettext('info'),
            class: ' td-left-align',
            cellTemplate: html`
              <span
                ng-class="{tooltip: item.additional_info, 'label-opacity': !item.additional_info}"
                data-tip="{[{item.additional_info}]}"
              >
                <i class="fa fa-comment"></i> {[{item.additional_info || '---' | cut:true:30:'
                ...'}]}
              </span>
            `,
          },
          {
            columnName: 'editor',
            title: this.gettext('editor'),
            cellTemplate: html`
              <span
                class="tooltip"
                data-tip="{[{ 'last updated' | translate }]} - {[{item.update_time | date:'dd.MM.yy HH:mm'}]} - {[{ item.editor_first_name }]}, {[{ 'created' | translate }]} - {[{item.create_time | date:'dd.MM.yy HH:mm'}]} - {[{ item.author_first_name }]}"
              >
                <div
                  class="user-avatar"
                  ng-if="item.editor_first_name && item.editor_avatar.length"
                >
                  <img ng-src="/pictures/{[{item.editor_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="!item.editor_avatar.length">
                  {[{ item.editor_first_name | cut:true:1:' ' }]}
                </div>
              </span>
            `,
          },
          {
            columnName: 'seeds',
            title: this.gettext('Seeds'),
            class: 'td-right-align',
            cellTemplate: html`<a> {[{ item.seeds || 0 | number: 2 }]} </a>`,
            totalTemplate: html`<span>{[{ item.seeds_total_amount || 0 | number:3 }]} </span>`,
            totalBlockTemplates: [
              html`
                <span class="label"> {[{ item.seeds_total_amount || 0 | number:2 }]} </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>seeds</translate>
                  <translate>amount</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'crop_protection',
            title: this.gettext('Crop protection'),
            class: 'td-right-align',
            cellTemplate: html`<a> {[{ item.crop_protection || 0 | number: 2 }]} </a> `,
            totalTemplate: html`
              <span>{[{ item.crop_protection_total_amount || 0 | number:3 }]} </span>
            `,
            totalBlockTemplates: [
              html`
                <span class="label">
                  {[{ item.crop_protection_total_amount || 0 | number:2 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>crop protection</translate>
                  <translate>amount</translate>
                </span>
              `,
            ],
          },
        ];

        return options;
      }
    },
  ],
};
