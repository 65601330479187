import React from 'react';

import { Tooltip } from '~/shared/ui/kit/tooltip';

import { useEntityAutocomplete } from '../../hooks';
import type { DataTypeProps } from '../../lib';
import { messageIntent } from '../../lib/constants';

export const EntityWrite: React.FC<DataTypeProps<'entity'>> = ({
  value,
  onChange,
  disabled,
  message,
  typeExtra,
}) => {
  if (!typeExtra) {
    throw new Error('EntityWrite: typeExtra is required');
  }
  const EntityAutocomplete = useEntityAutocomplete(typeExtra.entity);
  const autocompleteElement = (
    <EntityAutocomplete
      value={value}
      disabled={disabled}
      valueChanged={onChange}
      multiple={typeExtra.multiple}
    />
  );

  return (
    <div className="entity-write-container relative w-full transition-colors">
      {message ? (
        <Tooltip content={message.text ?? ''} side="bottom" sideOffset={4}>
          <div className={`relative w-full ${messageIntent[message.type]}`}>
            {autocompleteElement}
          </div>
        </Tooltip>
      ) : (
        autocompleteElement
      )}
    </div>
  );
};
