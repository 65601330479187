import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import React from 'react';

const bannerVariants = cva(
  'text-text-main-inverted flex w-full flex-col items-center justify-center py-2 text-xs font-medium',
  {
    variants: {
      intent: {
        primary: 'bg-stroke-additional-info',
        warning: 'bg-background-additional-danger',
        default: 'bg-background-main-invertedSecondary',
      },
    },
    defaultVariants: {
      intent: 'default',
    },
  },
);

type BannerVariants = VariantProps<typeof bannerVariants>;

export const Banner = React.forwardRef<HTMLElement, { children: React.ReactNode } & BannerVariants>(
  ({ children, intent }, ref) => {
    return (
      <section className={bannerVariants({ intent })} ref={ref}>
        {children}
      </section>
    );
  },
);

Banner.displayName = 'Banner';
