import type React from 'react';

import type { DataType, DataTypeProps } from './types';
import { CheckboxRead } from '../components/checkbox-read';
import { CheckboxWrite } from '../components/checkbox-write';
import { DateRangeRead } from '../components/date-range-read';
import { DateRangeWrite } from '../components/date-range-write';
import { DateRead } from '../components/date-read';
import { DateWrite } from '../components/date-write';
import { EmailRead } from '../components/email-read';
import { EmailWrite } from '../components/email-write';
import { EntityRead } from '../components/entity-read';
import { EntityWrite } from '../components/entity-write';
import { LabelRead } from '../components/label-read';
import { LabelWrite } from '../components/label-write';
import { MultiSelectRead } from '../components/multi-select-read';
import { MultiSelectWrite } from '../components/multi-select-write/multi-select-write';
import { NumberRead } from '../components/number-read';
import { NumberWrite } from '../components/number-write';
import { PersonRead } from '../components/person-read';
import { PersonWrite } from '../components/person-write';
import { PhoneRead } from '../components/phone-read';
import { PhoneWrite } from '../components/phone-write';
import { SelectRead } from '../components/select-read';
import { SelectWrite } from '../components/select-write';
import { Template } from '../components/template';
import { TextFieldRead } from '../components/text-field-read';
import { TextFieldWrite } from '../components/text-field-write';
import { UrlRead } from '../components/url-read';
import { UrlWrite } from '../components/url-write';

export const dataTypeComponents = () => ({
  number: {
    read: NumberRead,
    write: NumberWrite,
  },
  string: {
    read: TextFieldRead,
    write: TextFieldWrite,
  },
  phone: {
    read: PhoneRead,
    write: PhoneWrite,
  },
  text: {
    read: TextFieldRead,
    write: TextFieldWrite,
  },
  label: {
    read: LabelRead,
    write: LabelWrite,
  },
  url: {
    read: UrlRead,
    write: UrlWrite,
  },
  email: {
    read: EmailRead,
    write: EmailWrite,
  },
  template: {
    read: Template,
    write: Template,
  },
  person: {
    read: PersonRead,
    write: PersonWrite,
  },
  checkbox: {
    read: CheckboxRead,
    write: CheckboxWrite,
  },
  date: {
    read: DateRead,
    write: DateWrite,
  },
  select: {
    read: SelectRead,
    write: SelectWrite,
  },
  multiselect: {
    read: MultiSelectRead,
    write: MultiSelectWrite,
  },
  entity: {
    read: EntityRead,
    write: EntityWrite,
  },
  daterange: {
    read: DateRangeRead,
    write: DateRangeWrite,
  },
});

export const getDataTypeComponent = <T extends DataType>(dataType: T, mode: 'read' | 'write') => {
  return dataTypeComponents()[dataType][mode] as React.FC<DataTypeProps<T>>;
};
