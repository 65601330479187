import type ng from 'angular';

export class ContractStatusService {
  static readonly $inject = ['$http'];
  constructor(private readonly $http: ng.IHttpService) {}

  getReportData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/contract-status/',
      params: params,
    }).then((res: any) => res.data);
  }
}
