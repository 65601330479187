import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('BankBranchModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    'FinancesService',
    'GtUtils',
    'gettext',
    'bank',
    'FormFieldParamsService',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    FinancesService: FinancesService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    bank: any,
  ) {
    const vm = this;

    vm.bank = bank || {};
    vm.bankBranch = {};
    vm.save = save;
    vm.close = close;
    vm.form = undefined;
    vm.fields = getFields();

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function save() {
      let saveFunction;
      vm.form.$invalid = true;

      if (vm.bankBranch.id) {
        saveFunction = FinancesService.BankBranch.update;
      } else {
        saveFunction = FinancesService.BankBranch.save;
      }

      return saveFunction(
        vm.bankBranch,
        function (data: any) {
          close(data, true);
          notify(GtUtils.translate(gettext('Bank branch saved')));
        },
        (e: any) => GtUtils.errorClb(e),
      ).$promise;
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-md-6 col-sm-12',
        fieldGroup: [],
      };

      col1.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('INFO'),
        },
        fieldGroup: [
          {
            key: 'name',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Name'),
              placeholder: gettext('Type name'),
              type: 'text',
              required: true,
            },
          },
          {
            key: 'bank',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Bank'),
              resource: 'finances.FinanceBank',
              placeholder: gettext('Bank'),
              required: true,
            },
            defaultValue: vm.bank,
          },
        ],
      });

      return [col1];
    }
  }
})();
