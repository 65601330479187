import ng from 'angular';

import { ReassignmentContainer } from './reassignment-container/reassignment-container.component';
import { ReassignmentItemContainer } from './reassignment-item/reassignment-item.component';
import { ReassignmentListTableView } from './reassignment-list-table-view/reassignment-list-table-view.component';
import { ReassignmentModal } from './reassignment-modal/reassignment-modal.component';
import { ReassignmentPageContainer } from './reassignment-page/reassignment-page.component';
import { ReassignmentPageView } from './reassignment-page-view/reassignment-page-view.component';
import { ReassignmentPositionTable } from './reassignment-position-table/reassignment-position-table.component';
import { ReassignmentService } from './reassignment.service';

const reassignmentConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('finances.reassignmentsPage', {
        url: '/reassignment',
        component: 'reassignmentPageView',
        resolve: {
          filterLevel: () => {
            return 'finances-reassignment-page-view';
          },
        },
        data: {
          pageTitle: gettext('Reassignment'),
        },
      })
      .state('gt.reassignment', {
        url: '/reassignment/:id',
        component: 'reassignmentPageContainer',
        data: {
          pageTitle: gettext('Reassignment'),
        },
      });
  },
];

export const reassignment = ng
  .module('finances.reassignments', [])
  .component('reassignmentModal', ReassignmentModal)
  .component('reassignmentContainer', ReassignmentContainer)
  .component('reassignmentPositionTable', ReassignmentPositionTable)
  .component('reassignmentPageView', ReassignmentPageView)
  .component('reassignmentItemContainer', ReassignmentItemContainer)
  .component('reassignmentListTableView', ReassignmentListTableView)
  .component('reassignmentPageContainer', ReassignmentPageContainer)
  .service('ReassignmentService', ReassignmentService)
  .config(reassignmentConfig).name;
