import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('signingsReportPageView', {
    bindings: {},
    template: require('./signings-report-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['ReportsService', 'PageService'];

  function Controller(this: any, ReportsService: ReportsService, PageService: PageService) {
    const vm = this;
    vm.path = '/static/app/reports/pages/signings-report-page-view/';
    vm.selectedDate = new Date();
    vm.saleQueryParams = { contract_type: 'sale' };
    vm.purchaseQueryParams = { contract_type: 'purchase' };
    vm.saleFilterLevel = 'signings-report-page-view-sale';
    vm.purchaseFilterLevel = 'signings-report-page-view-purchase';

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      return {
        class: 'page-header-main-clients-tab',
        buttons: [new PageService.buttons.Print()],
        pages: ReportsService.getPagesConfig(),
      };
    }
  }
})();
