import ng from 'angular';

import type { GtRootScopeService } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('execution.legacy').directive('transshipmentTariffRow', directive);

  function directive() {
    return {
      template: require('./transshipment-tariff-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        tariff: '<',
        onSave: '&',
        onDelete: '&',
      },
    };
  }

  Controller.$inject = ['$rootScope'];

  function Controller(this: any, $rootScope: GtRootScopeService) {
    const vm = this;

    vm.toggleEdit = toggleEdit;
    vm.errors = {};
    vm.saveTariff = saveTariff;
    vm.deleteTariff = deleteTariff;

    vm.$onInit = function () {
      vm.tariff = vm.tariff || {};
      vm.edit = !vm.tariff.id;
      vm.buQueryParams = { user: $rootScope.user.id };
    };

    function saveTariff() {
      vm.onSave({ tariff: vm.tariff });
    }

    function deleteTariff() {
      vm.onDelete({ tariff: vm.tariff });
    }

    function toggleEdit(key: any) {
      vm.edit = key;
    }
  }
})();
