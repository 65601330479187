import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class BillOfLadingService {
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  ContractsService: ContractsService;
  FinancesService: FinancesService;
  GtUtils: GtUtilsService;
  LogisticsService: LogisticsService;
  billOfLadingResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $q: ng.IQService,
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    gettext: ng.gettext.gettextFunction,
    FinancesService: FinancesService,
    ContractsService: ContractsService,
    LogisticsService: LogisticsService,
    GtUtils: GtUtilsService,
  ) {
    this.$q = $q;
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.gettext = gettext;
    this.FinancesService = FinancesService;
    this.ContractsService = ContractsService;
    this.LogisticsService = LogisticsService;
    this.GtUtils = GtUtils;

    this.billOfLadingResource = $resource(
      '/api/logistics/bills-of-lading/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        listInfo: {
          method: 'GET',
          url: '/api/logistics/bills-of-lading-list/info/',
        },
        listTotals: {
          method: 'GET',
          url: '/api/logistics/bills-of-lading-list/totals/',
        },
        bulkUpdate: {
          method: 'POST',
          url: '/api/logistics/bills-of-lading/bulk_update/',
        },
        getInvoicePositions: {
          method: 'POST',
          url: '/api/logistics/bills-of-lading/get_invoice_positions/',
        },
        getGroupedBySaleContract: {
          method: 'GET',
          url: '/api/logistics/bl-sale-contract-grouped/',
        },
        attachLogisitcs: {
          method: 'POST',
          url: '/api/logistics/bills-of-lading/:id/attach_logisitcs/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/bills-of-lading/predictions/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/bills-of-lading/export_column_names/',
        },
      },
    );
  }

  saveBillOfLading(bill: any) {
    return this.billOfLadingResource.save(bill).$promise;
  }

  updateBillOfLading(bill: any) {
    return this.billOfLadingResource.update(bill).$promise;
  }

  bulkUpdateBillOfLading(bills: any) {
    return this.billOfLadingResource.bulkUpdate(bills).$promise;
  }

  connectToLogistics(billId: number, logisticBillofladingData: any) {
    return this.billOfLadingResource.attachLogisitcs(
      { id: billId },
      { logistic_billoflaging_data: logisticBillofladingData },
    ).$promise;
  }

  deleteBillOfLading(bill: any) {
    return this.billOfLadingResource.delete(bill).$promise;
  }

  getBillOfLading(billOfLadingId: number) {
    return this.billOfLadingResource.get({ id: billOfLadingId }).$promise.then((data: any) => data);
  }

  getBillOfLadingData(params: object) {
    return this.billOfLadingResource.listInfo(params).$promise.then((data: any) => {
      return this.billOfLadingResource.listTotals(params).$promise.then((totals: any) => {
        return { data: data, totals: totals };
      });
    });
  }

  getGroupedBySaleContract(params: object) {
    return this.billOfLadingResource
      .getGroupedBySaleContract(params)
      .$promise.then((data: any) => data);
  }

  createInvoice(
    bills: any,
    passportId: string | undefined,
    callback: any,
    invoiceType: any,
    invoiceCondition = 'balance',
  ) {
    if (!bills.length) {
      return this.$q.reject({
        data: { detail: { message: this.gettext('Please select at least one BL.') } },
      });
    }
    return this.billOfLadingResource.getInvoicePositions(
      {
        bills: bills,
        passport_id: passportId,
        invoice_type: invoiceType,
      },
      (data: any) => {
        const uniqueCurrencies = data.invoice_positions
          .map(({ currency }: any) => currency)
          .filter((value: any, index: number, array: any) => array.indexOf(value) === index);

        const newInvoice = {
          invoice_type: invoiceType,
          condition: invoiceCondition,
          business_unit: data.business_unit,
          currency: uniqueCurrencies.length === 1 ? uniqueCurrencies[0] : null,
          payment_conditions: data.payment_conditions,
          payment_conditions_option: data.payment_conditions_option,
          counterparty_business_reference: data.counterparty_business_reference,
        };

        if (!newInvoice.currency) {
          notify(this.gettext('Passport and/or contracts have different currencies'), 'warning');
        }

        this.FinancesService.financeModal(newInvoice, {
          financePositions: data.invoice_positions,
          afterSaveFunc: callback,
        });
      },
      (errors: any) => {
        return errors;
      },
    ).$promise;
  }

  billOfLadingModal(billOfLading: any, extra: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<bill-of-lading-modal
        bill-of-lading="billOfLading"
        extra="extra"
        modal-instance="$uibModalInstance"
      >
      </bill-of-lading-modal>`,
      controller: [
        '$scope',
        'billOfLading',
        'extra',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          billOfLading: any,
          extra: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).billOfLading = billOfLading;
          ($scope as any).extra = extra;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template-third-width',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        billOfLading: () => billOfLading,
        extra: () => extra,
      },
    }).result;
  }

  checkContractsVolumeLimit(contractIds: any, blVolume: any) {
    return this.$q
      .all(
        contractIds
          .filter((id: number) => id)
          .map((id: number) => this.collectContractData(id).then((data: any) => data)),
      )
      .then((contracts: any) =>
        contracts.map((contract: any) => this.compareContractVolumes(contract, blVolume)).flat(),
      );
  }

  collectContractData(contractId: number) {
    return this.ContractsService.Contract.get({ id: contractId }).$promise.then((contract: any) =>
      this.LogisticsService.Basis.get({ id: contract.basis }).$promise.then((basis: any) =>
        this.getBillOfLadingData({
          [contract.contract_type === 'sale' ? 'sale_contract' : 'purchase_contract']: contract.id,
        }).then((bls: any) => ({
          contractNumber: contract.contract_number,
          volume: contract.volume || 0,
          finalVolume: contract.final_volume || 0,
          estimatedVolume: contract.volume_estimated_open || 0,
          tolerance: (contract.volume * contract.volume_options) / 100,
          loadingOption: contract.loading_option || 0,
          blsVolume: bls.totals.total_volume_sum || 0,
          basisCodeName: basis.codename,
        })),
      ),
    );
  }

  compareContractVolumes(contract: any, blVolume: any) {
    const blsVolume = contract.blsVolume + blVolume;
    return ['FOB', 'CIF', 'CFR', 'CIP', 'FAS'].includes(contract.basisCodeName)
      ? ([
          {
            condition: contract.finalVolume !== 0 && blsVolume > contract.finalVolume,
            message: `Total volume of bills of lading is bigger then Final volume of the contract ${contract.contractNumber}`,
          },
          {
            condition: contract.estimatedVolume !== 0 && blsVolume > contract.estimatedVolume,
            message: `Total volume of bills of lading is bigger then Estimated volume of the contract ${contract.contractNumber}`,
          },
          {
            condition:
              contract.loadingOption === 0 &&
              contract.volume !== 0 &&
              blsVolume > contract.volume + contract.tolerance,
            message: `Total volume of bills of lading is bigger then contract ${contract.contractNumber} volume +/- tolerance`,
          },
          {
            condition:
              contract.loadingOption === 2 &&
              contract.volume !== 0 &&
              blsVolume > contract.volume + contract.tolerance,
            message: `Total volume of bills of lading is bigger then  contract ${contract.contractNumber} volume + Tolerance`,
          },
          {
            condition:
              contract.loadingOption === 1 &&
              contract.volume !== 0 &&
              blsVolume > contract.volume - contract.tolerance,
            message: `Total volume of bills of lading is bigger then contract ${contract.contractNumber} volume - Tolerance`,
          },
        ].find((warning) => warning.condition) ?? [])
      : ([] as any);
  }
}
BillOfLadingService.$inject = [
  '$q',
  '$resource',
  '$uibModal',
  'gettext',
  'FinancesService',
  'ContractsService',
  'LogisticsService',
  'GtUtils',
];
