import ng from 'angular';

import { CommFeesGroupedContainer } from './components/comm-fees-grouped-container/comm-fees-grouped-container.component';
import { ConsolidatedOperationsContainer } from './components/consolidated-operations-container/consolidated-operations-container.component';
import { ConsolidatedOperationsContractContainer } from './components/consolidated-operations-contract-container/consolidated-operations-contract-container.component';
import { ConsolidatedOperationsPassportContainer } from './components/consolidated-operations-passport-container/consolidated-operations-passport-container.component';
import { FixingHedgingListContainer } from './components/fixing-hedging-list-container/fixing-hedging-list-container.component';
import { FixingHedgingReportPageView } from './components/fixing-hedging-report-page-view/fixing-hedging-report-page-view.component';
import { MatchedOperationsContainer } from './components/matched-operations-container/matched-operations-container.component';
import { OperationModal } from './components/operation-modal/operation-modal.component';
import { OperationsContainer } from './components/operations-container/operations-container.component';
import { OperationsPageView } from './components/operations-page-view/operations-page-view.component';
import { OperationsService } from './operations.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const operationsConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('derivatives', {
      url: '/derivatives',
      component: 'operationsPageView',
      parent: 'position',
      data: {
        pageTitle: gettext('Derivatives Position'),
        permissions: {
          only: 'view_futuresposition',
        },
      },
      resolve: {
        queryParams: [
          'PageService',
          (PageService: PageService) => PageService.syncUrlFilter('operations-page-view'),
        ],
      },
    });
  },
];
const fixingHedgingReportConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('fixingHedgingReport', {
      url: '/fixing-hedging-report',
      component: 'fixingHedgingReportPageView',
      parent: 'position',
      data: {
        pageTitle: gettext('Fixing and hedging'),
      },
      resolve: {
        filterLevel: () => {
          return 'fixing-hedging-report-page-view';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('fixing-hedging-report-page-view');
          },
        ],
      },
    });
  },
];

export const operations = ng
  .module('stockExchanges.lotBatches', [])
  .component('consolidatedOperationsContainer', ConsolidatedOperationsContainer)
  .component('consolidatedOperationsPassportContainer', ConsolidatedOperationsPassportContainer)
  .component('consolidatedOperationsContractContainer', ConsolidatedOperationsContractContainer)
  .component('matchedOperationsContainer', MatchedOperationsContainer)
  .component('operationsContainer', OperationsContainer)
  .component('operationsPageView', OperationsPageView)
  .component('commFeesGroupedContainer', CommFeesGroupedContainer)
  .component('fixingHedgingReportPageView', FixingHedgingReportPageView)
  .component('fixingHedgingListContainer', FixingHedgingListContainer)
  .component('operationModal', OperationModal)
  .service('OperationsService', OperationsService)
  .config(operationsConfig)
  .config(fixingHedgingReportConfig).name;
