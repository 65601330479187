import type { QueryParams } from '^/app/core/types';

export class PotentialService {
  $resource: ng.resource.IResourceService;
  cropAreaResource: any;
  constructor($resource: ng.resource.IResourceService) {
    this.$resource = $resource;

    this.cropAreaResource = $resource(
      '/api/clients/crop-areas/:id/',
      {
        id: '@id',
      },
      {
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/clients/crop-areas/info/',
        },
        totals: {
          method: 'GET',
          isArray: false,
          url: '/api/clients/crop-areas/totals/',
        },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/clients/crop-areas/predictions/',
        },
        group: {
          method: 'GET',
          isArray: false,
          url: '/api/clients/crop-areas/group/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/clients/crop-areas/export_column_names/',
        },
      },
    );
  }

  getCropAreaList(queryParams: QueryParams) {
    return this.cropAreaResource.query(queryParams).$promise;
  }

  getCropAreaGroupedList(queryParams: QueryParams) {
    return this.cropAreaResource.group(queryParams).$promise;
  }

  getCropAreaTotals(queryParams: QueryParams) {
    return this.cropAreaResource.totals(queryParams).$promise;
  }

  saveCropArea(cropArea: any) {
    if (cropArea.id) {
      return this.cropAreaResource.update(cropArea).$promise;
    }
    return this.cropAreaResource.save(cropArea).$promise;
  }

  deleteCropArea(cropAreaId: number) {
    return this.cropAreaResource.delete({ id: cropAreaId }).$promise;
  }
}

PotentialService.$inject = ['$resource'];
