import template from './warehouse-commodity-batch-movements-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const WarehouseCommodityBatchMovementsContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    readonly: '<?',
  },
  template,
  controller: [
    '$scope',
    'gettext',
    'gtFilterService',
    'GtUtils',
    'WarehouseMovementsService',
    class {
      $scope: ng.IScope;
      gettext: ng.gettext.gettextFunction;
      GtUtils: GtUtilsService;
      WarehouseMovementsService: any;
      count = 0;
      filterLevel: string;
      gtFilterService: GtFilterService;
      initQueryParams?: QueryParams;
      movements: any;
      queryParams: QueryParams = {};
      readonly: any;
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        WarehouseMovementsService: any,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.WarehouseMovementsService = WarehouseMovementsService;

        this.movements = [];
        this.filterLevel = '';
        this.initQueryParams = {};
      }

      $onInit() {
        this.queryParams = { page_size: 20, ...this.initQueryParams };
        this.filterLevel = this.filterLevel || 'warehouse-commodity-batch-movements-container';
        this.readonly = this.readonly || false;
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }
      $onChanges(changes: any) {
        if (changes.initQueryParams && this.initQueryParams) {
          this.gtFilterService.setQueryParams(
            { page_size: 20, filterLevel: this.filterLevel, ...this.initQueryParams },
            this.filterLevel,
          );
        }
      }

      applyFilters(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.WarehouseMovementsService.getMovements({ order: 'moment', ...this.queryParams })
          .then((data: any) => {
            this.movements = data.results;
            this.count = data.count;
          })
          .finally(() => this.GtUtils.overlay('hide'));
      }
    },
  ],
};
