import template from './consolidated-operations-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const ConsolidatedOperationsContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    closeDetails: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'OperationsService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      OperationsService: any;
      filterLevel = 'consolidated-operations-container';
      groups: any;
      groupsCount: number;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      mode: any;
      queryParams: QueryParams = {};
      totals: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        OperationsService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.OperationsService = OperationsService;

        this.groups = [];
        this.groupsCount = 0;
        this.totals = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'consolidated-operations-container';
        this.queryParams = { page_size: 20, ...this.initQueryParams };
        this.mode = 'commodity';

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = {
            page_size: 20,
            ...this.initQueryParams,
            ...data,
          };
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.OperationsService.getOperationConsolidated(this.queryParams).then((data: any) => {
          this.groups = data.results;
          this.groupsCount = data.count;
          this.OperationsService.getOperationConsolidatedTotals(this.queryParams).then(
            (data: any) => {
              this.totals = data;
              this.GtUtils.overlay('hide');
            },
          );
        });
      }
    },
  ],
};
