import ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('financeAccountActivityContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./finance-account-activity-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', '$timeout', 'GtUtils', 'ReportsService', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $timeout: ng.ITimeoutService,
    GtUtils: GtUtilsService,
    ReportsService: ReportsService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;

    vm.transactions = [];
    vm.transactionsCount = 0;
    vm.updateTransactions = updateTransactions;
    vm.applyFilter = applyFilter;

    vm.total = {};
    vm.balanceDate = undefined;
    vm.financeAccount = undefined;
    vm.client = undefined;
    vm.business_unit_list = [];
    vm.passport_list = [];
    vm.datepickerConfig = getDatePickerConfig();
    vm.datepicker = '';

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'finance-account-activity-container';
      vm.queryParams = { ...vm.initQueryParams };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateTransactions();
      });
    };

    vm.$onChanges = function () {
      vm.queryParams = { ...vm.initQueryParams };
    };

    ////////////////

    function updateTransactions() {
      GtUtils.overlay('show');
      ReportsService.FinanceClientDetails.query(vm.queryParams, function (data: any) {
        vm.transactions = data.results;
        vm.transactionsCount = data.count;
        vm.total = data.total;
        GtUtils.overlay('hide');
      });
    }

    function applyFilter() {
      vm.queryParams = {
        ...vm.queryParams,
        start_date: vm.queryParams.start_date,
        end_date: vm.queryParams.end_date,
        client_id: vm.client,
        financeaccount_id: vm.financeAccount,
        business_unit_list: vm.business_unit_list,
        passport_list: vm.passport_list,
        contract_list: vm.contract_list,
      };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    }

    function getDatePickerConfig() {
      const config = gtFilterService.getDateRangePickerConfig();
      config.eventHandlers = {
        'apply.daterangepicker': () => {
          $timeout(function () {
            setDates();
          });
        },
        'cancel.daterangepicker': () => {
          $timeout(function () {
            vm.datepicker = false;
            setDates();
          });
        },
      };
      return config;
    }

    function setDates() {
      if (!vm.datepicker) {
        vm.queryParams.start_date = undefined;
        vm.queryParams.end_date = undefined;
      } else {
        vm.queryParams.start_date = formatDate(vm.datepicker.startDate, 'yyyy-MM-dd');
        vm.queryParams.end_date = formatDate(vm.datepicker.endDate, 'yyyy-MM-dd');
      }
      applyFilter();
    }
  }
})();
