import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './passport-details-item-container.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const passportDetailsItemContainer = {
  bindings: {
    passport: '<',
    filterLevel: '<',
    updateConfigWithFilterLevel: '&',
  },
  template,
  controller: [
    '$scope',
    '$state',
    '$stateParams',
    'ContractsService',
    'ClientsService',
    'gtFilterService',
    'GtUtils',
    'DocumentsService',
    'LogisticsService',
    'AccountsService',
    'SaleExportContractAllocationService',
    'gettext',
    '$rootScope',
    'PageService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      AccountsService: AccountsService;
      ClientsService: any;
      ContractsService: ContractsService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      PageService: PageService;
      LogisticsService: LogisticsService;
      SaleExportContractAllocationService: any;
      activeTabs: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      hideWizard: any;
      passport: any;
      tab = '';
      updateConfigWithFilterLevel: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        $stateParams: ng.ui.IStateParamsService,
        ContractsService: ContractsService,
        ClientsService: any,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        DocumentsService: any,
        LogisticsService: LogisticsService,
        AccountsService: AccountsService,
        SaleExportContractAllocationService: any,
        gettext: ng.gettext.gettextFunction,
        $rootScope: GtRootScopeService,
        PageService: PageService,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ContractsService = ContractsService;
        this.ClientsService = ClientsService;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.DocumentsService = DocumentsService;
        this.LogisticsService = LogisticsService;
        this.AccountsService = AccountsService;
        this.SaleExportContractAllocationService = SaleExportContractAllocationService;
        this.gettext = gettext;
        this.$rootScope = $rootScope;
        this.PageService = PageService;

        this.passport = {};
        this.filterLevel = '';
        this.activeTabs = [];
        if (this.$rootScope.user.profile?.wizard) {
          this.hideWizard = false;
        } else {
          this.hideWizard = true;
        }
      }

      $onInit() {
        const initialTab =
          this.gtFilterService.getQueryParams(this.filterLevel).tab ??
          (this.$rootScope.user.settings.PASSPORT_DETAILS_DEFAULT_TAB || 'info');

        this.tab = this.hasTabPermission(initialTab as string) ? initialTab.toString() : '';

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
          if (data.tab) {
            this.tab = data.tab;
          }
        });
        if (this.$stateParams.tab) {
          this.gtFilterService.updateQueryParams({ tab: this.$stateParams.tab }, this.filterLevel);
        }
        this.updateData();
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.ContractsService.Passport.get({ serializer: 'base', id: this.passport.id })
          .$promise.then((data: any) => {
            this.passport = data;
            this.PageService.setPageTitle(this.$rootScope.pageTitle + ' #' + this.passport.title);
            this.gtFilterService.updateQueryParams({}, this.filterLevel);
            this.updateConfigWithFilterLevel({
              passport: this.passport,
              filterLevel: this.filterLevel,
            });
            this.updateDisableReactivateButton();
            this.GtUtils.overlay('hide');
            return this.passport;
          })
          .catch(() => {
            this.GtUtils.overlay('hide');
            notify(this.gettext("Passport doesn't exist"));
            this.$state.go('abstract-passports.passports');
          });
      }

      openPassportModal(passport: any) {
        this.ContractsService.passportModal(passport, { from: 'details' }).then((data: any) => {
          if (!data || data == 'cancel') {
            return;
          }
          this.updateData();
        });
      }

      openRoleDetails(id: number) {
        return this.ClientsService.openRoleDetails({ id: id });
      }

      changeTab(tab: string) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);

        if (!this.$rootScope.user.profile?.wizard) {
          this.hideWizard = true;
        }
      }

      createVoyage() {
        return this.LogisticsService.voyageModal().then((data: any) => {
          if (!data || data == 'cancel' || !data.id) {
            return;
          }
          this.passport.voyage = data.id;
          return this.ContractsService.Passport.update(this.passport).$promise.then((data: any) => {
            notify(this.gettext('Passport updated'));
            this.passport = data;
            this.updateData();
          }).$promise;
        });
      }

      openDocxModal() {
        return this.DocumentsService.generateDocxModal('Passport', this.passport.id);
      }

      updateFactsByExecution(factType: any) {
        if (
          !confirm(this.gettext('Update all ' + (factType || '') + ' connections from execution?'))
        ) {
          return;
        }
        this.GtUtils.overlay('show');
        return this.ContractsService.Passport.updateFactsFromExecution(
          {
            id_list: [this.passport.id],
            fact_type: factType,
          },
          () => {
            notify(this.gettext('Connections updated.'));
            notify(this.gettext('Passport updated.'));
            this.updateData();
            this.GtUtils.overlay('hide');
          },
          this.GtUtils.errorClb,
        );
      }

      updateVolumeFromExportExecution() {
        if (!confirm(this.gettext('Update all export connections from execution?'))) {
          return;
        }

        this.GtUtils.overlay('show');
        return this.SaleExportContractAllocationService.updateVolumeFromPassportExecution({
          passport_id_list: [this.passport.id],
        }).then(() => {
          notify(this.gettext('Connections updated.'));
          notify(this.gettext('Passport updated.'));
          this.updateData();
          this.GtUtils.overlay('hide');
        }, this.GtUtils.errorClb);
      }

      createContractFromTickets() {
        this.GtUtils.overlay('show');
        return this.ContractsService.executePassport(this.passport).then(
          () => {
            this.GtUtils.overlay('hide');
            this.updateData();
          },
          (data: any) => {
            this.GtUtils.errorClb(data);
            this.GtUtils.overlay('hide');
          },
        );
      }
      updateDisableReactivateButton() {
        if (!this.passport.id) {
          this.passport.disableReactivateButton = true;
          return;
        }
        if (this.passport.approval_status !== 'approved') {
          this.passport.disableReactivateButton = this.passport.approval_status !== 'rejected';
          return;
        }
        this.ContractsService.Request.query(
          {
            final_approve: false,
            with_passport: this.passport.id,
            stage: 'ticket',
          },
          (data: any) => {
            this.passport.disableReactivateButton = data.data.length === 0;
          },
        );
      }
      openEmailModal() {
        return this.ClientsService.sendEmailModal('Passport', this.passport.id);
      }

      hasTabPermission(tab: string) {
        const tabPermissions: any = {
          info: 'view_passportresult',
          recap: 'view_passport_recap',
          result: 'view_passport_pnl',
        };

        const permission = tabPermissions[tab];
        return permission ? this.AccountsService.hasPerm(permission) : true;
      }
    },
  ],
};
