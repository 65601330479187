import type ng from 'angular';

import template from './consolidated-stock-market-prices-table.html?raw';
import type { StockMarketPricesService } from '../stock-market-prices.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const ConsolidatedStockMarketPricesTable = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gettext',
    'gtFilterService',
    'StockMarketPricesService',
    'GtUtils',
    class {
      GtUtils: GtUtilsService;
      StockMarketPricesService: StockMarketPricesService;
      _gtFilterService: GtFilterService;
      filterLevel = 'consolidated-stock-market-prices-table';
      futuresPrices: any;
      gettext: ng.gettext.gettextFunction;
      queryParams: QueryParams = {};
      constructor(
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
        StockMarketPricesService: StockMarketPricesService,
        GtUtils: GtUtilsService,
      ) {
        this.gettext = gettext;
        this._gtFilterService = gtFilterService;
        this.StockMarketPricesService = StockMarketPricesService;
        this.GtUtils = GtUtils;

        this.futuresPrices = {};
        this.queryParams = {};
      }

      $onInit() {
        this._gtFilterService.subscribe(
          this.filterLevel,
          (params: QueryParams) => {
            this.queryParams = params;
            this.updateTableData();
          },
          this.queryParams,
        );
        this.updateTableData();
      }

      updateTableData() {
        this.GtUtils.overlay('show');
        this.StockMarketPricesService.getConsolidatedDerivativesTable(this.queryParams).then(
          (futuresPrices: any) => {
            this.futuresPrices = futuresPrices;
            this.GtUtils.overlay('hide');
          },
        );
      }
    },
  ],
};
