import { notify } from '~/shared/lib/notify';

import template from './margin-control.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const MarginControl = {
  bindings: {
    passport: '<',
    readOnly: '<?',
  },
  template,
  controller: [
    'ContractsService',
    'GtUtils',
    'gettext',
    class {
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      gettext: ng.gettext.gettextFunction;
      marginControls: any;
      marginCount: number;
      passport: any;
      readOnly: any;
      constructor(
        ContractsService: ContractsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.marginControls = [];
        this.marginCount = 0;
        this.readOnly = false;
        this.passport = {};
      }

      $onInit() {
        this.updateMarginControl();
      }

      updateMarginControl(params?: any) {
        return this.ContractsService.MarginControl.query(
          { ...(params?.params || {}), passport: this.passport.id },
          (data: any) => {
            this.marginControls = data.results;
            this.marginCount = data.count;
          },
        );
      }

      createMarginControl() {
        if (this.readOnly) {
          return;
        }
        this.ContractsService.MarginControl.save({
          passport: this.passport.id,
          custom_status: this.passport.custom_status,
          margin_selector: this.passport.margin_selector,
          ticket_margin_t: this.passport?.results?.plans_margin_analytical_per_t || 0,
          contract_margin_t: this.passport?.results?.facts_margin_per_t || 0,
          execution_margin_t: this.passport?.results?.facts_transport_margin_per_t || 0,
          invoice_margin_t: this.passport?.results?.facts_invoice_margin_per_t || 0,
          payment_margin_t: this.passport?.results?.facts_payment_margin_per_t || 0,
          ticket_margin: this.passport?.results?.plans_margin_with_vat || 0,
          ticket_analytical_margin: this.passport?.results?.plans_margin_analytical_with_vat || 0,
          contract_margin: this.passport?.results?.facts_margin_with_vat || 0,
          contract_analytical_margin: this.passport?.results?.facts_margin_analytical_with_vat || 0,
          execution_margin: this.passport?.results?.facts_transport_margin_with_vat || 0,
          invoice_margin: this.passport?.results?.invoices_margin_with_vat || 0,
          payment_margin: this.passport?.results?.payments_margin_with_vat || 0,
          derivative_margin: this.passport?.results?.derivativeMargin || 0,
        }).$promise.then(() => {
          this.updateMarginControl();
          notify(this.gettext('Margin control created'));
        }, this.GtUtils.errorClb);
      }
    },
  ],
};
