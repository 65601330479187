import { financesDisbursementblPredictionsRetrieve } from '@gt/api';

import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { DisbursementBl, DisbursementBlListParams } from '../lib';

export class DisbursementBillOfLadingRepository extends EntityRepository<
  DisbursementBl,
  DisbursementBlListParams
> {
  readonly entityName = 'finances.DisbursementBl';

  getFn = (_id: number): Promise<DisbursementBl> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: DisbursementBlListParams): Promise<Paginated<DisbursementBl>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, id?: number): Promise<EntityOption[]> => {
    const { data } = await financesDisbursementblPredictionsRetrieve({
      query: { search: input, id_list: id ? [String(id)] : [] },
    });
    return data.results;
  };
  createFn = (_entity: DisbursementBl): Promise<DisbursementBl> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<DisbursementBl>): Promise<DisbursementBl> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
