import ng from 'angular';

import { BidsListPage } from './components/bids-list-page/bids-list-page.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const bids = ng
  .module('market.bids', [])
  .component('bidsListPage', BidsListPage)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('market.bids', {
        url: '/bids/list/',
        component: 'bidsListPage',
        data: {
          pageTitle: gettext('Bids'),
        },
        resolve: {
          filterLevel: () => {
            return 'bids-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('bids-list-page');
            },
          ],
        },
      });
    },
  ]).name;
