import React from 'react';

import { t } from '~/shared/lib/i18n';
import { cn } from '~/shared/lib/utils';
import { Input } from '~/shared/ui/kit/input';
import { Tooltip } from '~/shared/ui/kit/tooltip';

import { type DataTypeProps, messageIntent } from '../../lib';

export const LabelWrite: React.FC<DataTypeProps<'label'>> = ({
  value,
  onChange,
  ref,
  disabled,
  message,
  ...props
}) => {
  const inputElement = (
    <Input
      intent={message ? 'danger' : 'transparent'}
      ref={ref}
      onBlur={(e) => onChange?.({ value: e.target.value, label: e.target.value })}
      defaultValue={value?.label ?? ''}
      disabled={disabled}
      fullWidth
      {...props}
    />
  );

  return (
    <div className={cn('relative w-full', message && messageIntent[message.type])}>
      {message ? (
        <Tooltip content={t(message.text ?? '')} side="bottom" sideOffset={4}>
          <div>{inputElement}</div>
        </Tooltip>
      ) : (
        inputElement
      )}
    </div>
  );
};
