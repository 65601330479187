import React from 'react';

import type { ComponentModel } from './component-model';
import type { ComponentProps, ComponentViewProps } from './types';
import type { InjectionToken } from '../di';
import { container } from '../di';

// WIP
export abstract class Component<
  TViewProps extends ComponentViewProps<TModel, TProps>,
  TProps extends ComponentProps = Omit<TViewProps, 'model'>,
  TModel extends ComponentModel<TProps> = ComponentModel<TProps>,
> extends React.Component<TProps> {
  protected abstract viewComponent: React.FC<TViewProps>;
  protected abstract modelClass: InjectionToken<TModel>;
  private readonly model: TModel;

  getModelClass = () => this.modelClass;

  constructor(props: TProps) {
    super(props);

    this.model = container.resolve<TModel>(this.getModelClass());
    this.model.props = props;
  }

  render() {
    const viewProps = { ...this.props, model: this.model } as TViewProps;
    return <this.viewComponent {...viewProps} />;
  }
}
