export class BillOfLadingInvoicepositionService {
  $resource: ng.resource.IResourceService;
  billOfLadingInvoicepositionResource: any;
  constructor($resource: ng.resource.IResourceService) {
    this.$resource = $resource;

    this.billOfLadingInvoicepositionResource = $resource(
      '/api/finances/invoice-position-bl/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
      },
    );
  }

  save(item: any) {
    return this.billOfLadingInvoicepositionResource.save(item).$promise;
  }

  update(item: any) {
    return this.billOfLadingInvoicepositionResource.update(item).$promise;
  }

  delete(item: any) {
    return this.billOfLadingInvoicepositionResource.delete(item).$promise;
  }

  getItem(billOfLadingInvoicepositionId: number) {
    return this.billOfLadingInvoicepositionResource
      .get({ id: billOfLadingInvoicepositionId })
      .$promise.then((data: any) => data);
  }

  getData(params: object) {
    return this.billOfLadingInvoicepositionResource.query(params).$promise.then((data: any) => {
      return { data: data };
    });
  }
}
BillOfLadingInvoicepositionService.$inject = ['$resource'];
