import type { GtRootScopeService } from '^/app/core/types';

export const GtCalendar = {
  bindings: {
    events: '<',
    onEventClick: '&',
    onEventDrop: '&',
  },
  template:
    '<div ui-calendar="$ctrl.uiConfig.calendar" class="calendar table-container-wrapper" ng-model="$ctrl.eventSources"></div> ',
  controller: [
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      eventSources: any;
      events: any;
      onEventClick: any;
      onEventDrop: any;
      uiConfig: any;
      constructor($rootScope: GtRootScopeService) {
        this.$rootScope = $rootScope;

        this.uiConfig = {};
        this.eventSources = [];
      }

      $onInit() {
        this.events = this.events || [];
        this.uiConfig = this.updateCalendarConfig();
      }

      $onChanges(changes: any) {
        if (changes.events) {
          this.eventSources = [{ events: changes.events.currentValue }];
        }
      }

      updateCalendarConfig() {
        return {
          calendar: {
            lang: this.$rootScope.user.profile?.language,
            locale: this.$rootScope.user.profile?.language,
            editable: true,
            weekNumberCalculation: 'ISO',
            height: 650,
            header: {
              left: 'month basicWeek basicDay agendaWeek agendaDay',
              center: 'title',
              right: 'today prev,next',
            },

            eventClick: (date: any, jsEvent: any, view: any) =>
              this.onEventClick({ event: { date: date, jsEvent: jsEvent, view: view } }),
            eventDrop: (event: any, delta: any, revertFunc: any, jsEvent: any) =>
              this.onEventDrop({
                event: { event: event, delta: delta, revertFunc: revertFunc, jsEvent: jsEvent },
              }),
            eventResize: (event: any, delta: any, revertFunc: any, jsEvent: any) =>
              console.info(event, delta, revertFunc, jsEvent),
            dayClick: (date: any) => console.info('dayClick', date),
            eventRender: (event: any, element: any, view: any) =>
              console.info(event, element, view),
          },
        };
      }
    },
  ],
};
