import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('warehouseLostsContainer', {
    bindings: {
      queryParams: '<?',
      filterLevel: '<',
    },
    template: require('./warehouse-losts-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gettext', 'GtUtils', 'gtFilterService', 'LogisticsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    LogisticsService: LogisticsService,
  ) {
    const vm = this;
    vm.losts = [];
    vm.totals = {};
    vm.count = 0;
    vm.updateLosts = updateLosts;
    vm.updateQueryParams = updateQueryParams;
    vm.saveLost = saveLost;
    vm.deleteLost = deleteLost;
    vm.newLost = { ...vm.queryParams };

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'warehouse-losts-container';
      vm.queryParams = { ...vm.queryParams };
      vm.cargo = vm.queryParams.cargo || undefined;
      vm.warehouse = vm.queryParams.warehouse || undefined;
      vm.warehouseType = vm.queryParams.warehouse_type || undefined;
      vm.warehouseService = LogisticsService.WarehouseLoss;
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateLosts();
      });
      updateLosts();
    };

    ////////////////

    function saveLost(lost: any) {
      if (lost.id) {
        return vm.warehouseService.update(lost, updateLosts, (e: any) => GtUtils.errorClb(e))
          .$promise;
      }
      return vm.warehouseService.save(lost, updateLosts, (e: any) => GtUtils.errorClb(e)).$promise;
    }

    function deleteLost(lost: any) {
      if (!confirm(gettext('Are you sure that you want delete Lost?'))) {
        return;
      }
      return vm.warehouseService.delete(lost, updateLosts, (e: any) => GtUtils.errorClb(e))
        .$promise;
    }

    function updateQueryParams() {
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }

    function updateLosts() {
      GtUtils.overlay('show');
      vm.newLost = { ...vm.queryParams };
      vm.newLost.warehouse_type = vm.warehouseType;
      return vm.warehouseService.query(vm.queryParams, function (data: any) {
        vm.losts = data.results;
        if (vm.newLost) {
          vm.losts.unshift(vm.newLost);
        }
        vm.count = data.count;
        return vm.warehouseService.tableTotal(vm.queryParams, function (data: any) {
          vm.totals = data;
          GtUtils.overlay('hide');
        }).$promise;
      }).$promise;
    }
  }
})();
