import { q } from '~/shared/api';
import { container } from '~/shared/lib/di';
import type { Schema, ValidateFunction } from '~/shared/lib/schema';
import { AjvService } from '~/shared/lib/schema';
import { genId, tryCatch } from '~/shared/lib/utils';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Entity, EntityName, EntityQueryParams, Paginated, Patched } from '../types';

/**
 * Abstract class representing a repository for managing entities.
 *
 * @template TEntity - The type of the entity.
 * @template TParams - The type of the query parameters.
 * @template TEntityRecord - The type of the entity list record, defaults to TEntity.
 * @template TEntityPost - The type of the entity for creation, defaults to TEntity.
 * @template TEntityPatch - The type of the entity for updates, defaults to a patched version of TEntityPost.
 */

export abstract class EntityRepository<
  TEntity extends Entity,
  TParams extends EntityQueryParams,
  TEntityRecord extends Entity = TEntity,
  TEntityPost extends Entity = TEntity,
  TEntityPatch extends Entity = Patched<TEntityPost>,
> {
  abstract entityName: EntityName;

  protected schemas = {
    entity: undefined as Schema | undefined,
    entityRecord: undefined as Schema | undefined,
    entityPost: undefined as Schema | undefined,
    entityPatch: undefined as Schema | undefined,
  };

  private entityValidate?: ValidateFunction<TEntity> = undefined;
  private entityRecordValidate?: ValidateFunction<TEntityRecord> = undefined;
  private entityPostValidate?: ValidateFunction<TEntityPost> = undefined;
  private entityPatchValidate?: ValidateFunction<TEntityPatch> = undefined;

  private readonly ajvService = container.resolve(AjvService);

  protected abstract getFn: (id: number) => Promise<TEntity>;
  protected abstract queryFn: (params: TParams) => Promise<Paginated<TEntityRecord>>;
  protected abstract searchFn: (input: string, id?: number) => Promise<EntityOption[]>;
  protected abstract createFn: (entity: TEntityPost) => Promise<TEntity>;
  protected abstract updateFn: (entity: TEntityPatch) => Promise<TEntity>;
  protected abstract deleteFn: (id: number) => Promise<void>;

  private searchCache: Record<string, EntityOption[]> = {};
  private optionsCache: Record<number, EntityOption> = {};

  get name() {
    return this.entityName;
  }

  get entityValidateFn() {
    if (!this.entityValidate && this.schemas.entity) {
      this.entityValidate = this.ajvService.compile<TEntity>(this.schemas.entity);
    }
    return this.entityValidate;
  }

  get entityRecordValidateFn() {
    if (!this.entityRecordValidate && this.schemas.entityRecord) {
      this.entityRecordValidate = this.ajvService.compile<TEntityRecord>(this.schemas.entityRecord);
    }
    return this.entityRecordValidate;
  }

  get entityPostValidateFn() {
    if (!this.entityPostValidate && this.schemas.entityPost) {
      this.entityPostValidate = this.ajvService.compile<TEntityPost>(this.schemas.entityPost);
    }
    return this.entityPostValidate;
  }

  get entityPatchValidateFn() {
    if (!this.entityPatchValidate && this.schemas.entityPatch) {
      this.entityPatchValidate = this.ajvService.compile<TEntityPatch>(this.schemas.entityPatch);
    }
    return this.entityPatchValidate;
  }

  private readonly validateData = <D>(data: D, validateFn?: ValidateFunction<D>): D => {
    if (!validateFn) {
      return data;
    }

    if (!validateFn(data)) {
      console.error(validateFn.errors);
      throw new Error('Invalid data');
    }

    return data;
  };

  get = async (id: number) => {
    const response = await this.getFn(id);
    return this.entityValidateFn ? this.validateData(response, this.entityValidateFn) : response;
  };

  query = async (params: TParams) => {
    const response = await this.queryFn(params);
    response.records = this.entityRecordValidateFn
      ? response.records.map((r) => this.validateData(r, this.entityRecordValidateFn))
      : response.records;

    this.optionsCache = {
      ...this.optionsCache,
      ...response.records.map(this.recordToOption).reduce((acc, o) => ({ ...acc, [o.id]: o }), {}),
    };

    return response;
  };

  search = async (input: string, id?: number): Promise<EntityOption[]> => {
    return q(async () => {
      if (id && this.optionsCache[id]) {
        return [this.optionsCache[id]];
      }

      if (!this.searchCache[input]) {
        this.searchCache[input] = await this.searchFn(input, id);
      }
      this.optionsCache = {
        ...this.optionsCache,
        ...this.searchCache[input].reduce((acc, o) => ({ ...acc, [o.id]: o }), {}),
      };

      if (id && !this.optionsCache[id]) {
        console.warn(this.name + ' Repository: Empty search result by id');
        const { data } = await tryCatch(this.get(id));
        if (data) {
          this.optionsCache[id] = this.recordToOption(data);
          return [this.optionsCache[id]];
        }
      }

      return this.searchCache[input];
    }, 1);
  };

  create = async (entity: TEntityPost) => {
    return await this.createFn(entity);
  };

  update = async (entity: TEntityPatch) => {
    return await this.updateFn(entity);
  };

  delete = async (id: number) => {
    return await this.deleteFn(id);
  };

  saveRecord(entity: TEntityRecord) {
    return entity.id > 0
      ? this.update(this.recordToPatch(entity))
      : this.create(this.recordToPost(entity));
  }

  saveEntity(entity: TEntityPost | TEntityPatch) {
    return entity.id > 0 ? this.update(entity as TEntityPatch) : this.create(entity as TEntityPost);
  }

  recordToPost = (record: TEntityRecord): TEntityPost => {
    console.warn('Method recordToPost not implemented, using implicit conversion');
    return record as unknown as TEntityPost;
  };

  recordToPatch = (record: TEntityRecord): TEntityPatch => {
    console.warn('Method recordToPatch not implemented, using implicit conversion');
    return record as unknown as TEntityPatch;
  };

  recordToOption = <R extends TEntityRecord | TEntity>(record: R): EntityOption => {
    const nameFields = ['name', 'title', 'column_name'];

    const result = {
      id: (record as Entity).id,
      title: String(record[nameFields.find((key) => key in record) as keyof R]),
    };

    if (!result.title?.length) {
      console.warn('Empty EntityOption title. Implement recordToOption method');
    }

    return result;
  };

  buildNewRecord = (): TEntityRecord => {
    console.warn('Method buildNewRecord not implemented, using implicit conversion');
    return { id: -genId() } as TEntityRecord;
  };
}
