import {
  logisticsPlacesOfDestinationList,
  logisticsPlacesOfDestinationPredictionsRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { PlaceOfDestination, PlaceOfDestinationListParams } from '../lib';

export class PlaceOfDestinationRepository extends EntityRepository<
  PlaceOfDestination,
  PlaceOfDestinationListParams
> {
  readonly entityName = 'logistics.PlaceOfDestination';

  getFn = (_id: number): Promise<PlaceOfDestination> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: PlaceOfDestination): Promise<PlaceOfDestination> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<PlaceOfDestination>): Promise<PlaceOfDestination> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: PlaceOfDestinationListParams) => {
    const { data } = await logisticsPlacesOfDestinationList({ query });
    return { records: data.results, count: data.count };
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await logisticsPlacesOfDestinationPredictionsRetrieve({
      query: { name: input },
    });
    return data.results;
  };
}
