import type ng from 'angular';

import type {
  Request,
  RequestListTotals,
  RequestPost,
  RequestRecord,
} from '~/features/deals/requests';
import { RequestsListModel } from '~/features/deals/requests/components/requests-container';
import { container } from '~/shared/lib/di';
import { notify } from '~/shared/lib/notify';
import type { Subscription } from '~/shared/lib/state';

import template from './requests-container.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { CoreUtils } from '^/app/core/core.utils';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { MulticontractService } from '^/app/deals/multicontract/multicontract.service';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

type RequestsContainerQueryParams = QueryParams & {
  contract_type: Request['contract_type'];
  deal_type: Request['deal_type'];
  serializer: string;
  stage: Request['stage'];
};

type TableRequest = RequestRecord & {
  $_selected?: boolean;
  $_showCheckbox?: boolean;
  $_inputedNumber?: number;
  unconnected_ticket_volume?: number;
};
class RequestsContainerController implements ng.IController {
  viewModel: RequestsListModel;
  subs: Record<string, Subscription> = {};
  total: RequestListTotals = {} as RequestListTotals;
  requests: TableRequest[] = [];
  requestsCount = 0;
  pageData: { count: number; records: RequestRecord[] } = {
    count: 0,
    records: [],
  };
  viewMode: 'table' | 'spreadsheet' | 'grid' = 'table';
  loading = false;

  initQueryParams?: Partial<RequestsContainerQueryParams>;
  filterLevel = 'requests-list-page';
  tableName = '';
  tableExpanded = false;
  onCreate?: () => void;
  onUpdate?: (data: { requests: Partial<Request>[] }) => void;
  suppressNotifications = false;
  view: 'list' | 'block' | 'table' = 'table';
  hideButton = false;
  simplifiedView = false;

  queryParams: RequestsContainerQueryParams = {
    contract_type: 'sale',
    deal_type: 'spot',
    serializer: 'table_info',
    stage: 'ticket',
  };
  resourceClass: 'request' | 'indicator' = 'request';
  savedFilterInitQueryParams: Partial<RequestsContainerQueryParams> = {};
  newRequest: Partial<Request> & { $_edit?: boolean } = {};
  newRequestTemplate: Partial<Request> = {};
  savedFilterChoices: any;
  creatingMultiTicket = false;

  static readonly $inject = [
    '$rootScope',
    '$scope',
    'gtFilterService',
    'ContractsService',
    'FinancesService',
    'GtUtils',
    'gettext',
    'CoreUtils',
    'CoreService',
    'ClientsService',
    'MulticontractService',
  ];
  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly $scope: ng.IScope,
    private readonly gtFilterService: GtFilterService,
    private readonly ContractsService: ContractsService,
    private readonly FinancesService: FinancesService,
    private readonly GtUtils: GtUtilsService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly CoreUtils: CoreUtils,
    private readonly CoreService: CoreService,
    private readonly ClientsService: any,
    private readonly MulticontractService: MulticontractService,
  ) {
    this.viewModel = container.resolve(RequestsListModel);
  }

  $onInit() {
    if (this.$rootScope.isDeviceMobile) {
      this.view = 'block';
    } else {
      this.view =
        (['list', 'block'].includes(this.$rootScope.user.profile?.tickets_view ?? '')
          ? this.$rootScope.user.profile?.tickets_view
          : this.view) ?? 'block';
    }

    if (this.initQueryParams) {
      this.queryParams = { ...this.queryParams, ...this.initQueryParams };
    }

    this.savedFilterInitQueryParams = {
      stage: this.queryParams.stage,
      serializer: this.queryParams.serializer,
      contract_type: this.queryParams.contract_type,
    };
    this.newRequest = { ...this.queryParams };

    this.tableName = this.tableName || this.queryParams.contract_type + '-requests-container';

    this.resourceClass = this.queryParams.stage === 'indicator' ? 'indicator' : 'request';
    this.viewModel.setStage(this.queryParams.stage === 'indicator' ? 'indicator' : 'ticket');
    this.viewModel.setContractType(this.queryParams.contract_type ?? 'sale');

    this.tableExpanded = this.tableExpanded || false;

    this.gtFilterService.subscribe(this.filterLevel, this.queryParamsChanged, this.queryParams);

    this.subs.loading = this.viewModel.loading$.subscribe((loading) => {
      if (loading != this.loading || !loading) {
        this.loading = loading;
        this.GtUtils.overlay(loading ? 'show' : 'hide');
        this.$scope.$applyAsync();
      }
    });
    this.subs.pageData = this.viewModel.pageData$.subscribe((pageData) => {
      this.pageData = pageData;
      this.updateData();
      this.$scope.$applyAsync();
    });
    this.subs.totals = this.viewModel.totals$.subscribe((total) => {
      if (JSON.stringify(this.total) !== JSON.stringify(total)) {
        this.total = total;
        this.$scope.$applyAsync();
      }
    });

    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
    this.GtUtils.overlay('hide');
    this.buildNewRequest().catch(this.GtUtils.errorClb);
    this.CoreService.getSavedFilterChoices(this.filterLevel)
      .then((data) => (this.savedFilterChoices = data))
      .catch(this.GtUtils.errorClb);
  }

  $onChanges = (changes: ng.IOnChangesObject) => {
    if ([changes.tableExpanded, this.tableExpanded].every(Boolean)) {
      this.updateData();
    }
  };

  $onDestroy() {
    this.gtFilterService.unsubscribe(this.filterLevel, this.queryParamsChanged);
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());
  }

  queryParamsChanged = (params: RequestsContainerQueryParams) => {
    this.viewModel.pageParamsUpdated({
      page: Number(params.page ?? 1),
      page_size: Number(params.page_size ?? 25),
      ...params,
    });

    const newResourceClass = params.stage === 'indicator' ? 'indicator' : 'request';
    if (newResourceClass !== this.resourceClass) {
      this.resourceClass = newResourceClass;
      this.viewModel.setStage(params.stage === 'indicator' ? 'indicator' : 'ticket');
      this.viewModel.setContractType(params.contract_type ?? 'sale');
    }
  };

  changeView = (view: 'table' | 'spreadsheet' | 'grid') => {
    this.viewMode = view;
    this.$scope.$applyAsync();
  };

  buildNewRequest = (origin: Partial<Request> = {}): Promise<Request> => {
    this.newRequest = { ...this.queryParams, ...origin };
    this.newRequest.crop_year =
      this.newRequest.crop_year ?? this.$rootScope.user.settings.DEFAULT_VALUES.crop_year;
    this.newRequest.responsible = this.$rootScope.user.id;
    const promises: any = [];
    if (this.newRequest.contract_type === 'sale') {
      this.newRequest.basis =
        this.newRequest.basis ?? this.$rootScope.user.settings.DEFAULT_VALUES.basis_sale;
      this.newRequest.supplier = this.$rootScope.user.settings.DEFAULT_VALUES.owner;
      if (this.initQueryParams?.client) {
        promises.push(
          this.ClientsService.Buyer.predictions(
            { client: this.initQueryParams.client },
            (data: any) => {
              this.newRequest.buyer = data.records.length && data.records[0].id;
            },
          ).$promise,
        );
      }
    } else {
      this.newRequest.basis =
        this.newRequest.basis ?? this.$rootScope.user.settings.DEFAULT_VALUES.basis_purchase;
      this.newRequest.buyer = this.$rootScope.user.settings.DEFAULT_VALUES.owner;
      if (this.initQueryParams?.client) {
        promises.push(
          this.ClientsService.Supplier.predictions(
            { client: this.initQueryParams.client },
            (data: any) => {
              this.newRequest.supplier = data.records.length && data.records[0].id;
            },
          ).$promise,
        );
      }
    }

    promises.push(
      this.CoreService.getDefaultBuId(this.newRequest).then(
        (data: any) => (this.newRequest.business_unit = data),
      ),
    );

    promises.push(
      this.FinancesService.Currency.query(
        { search: this.$rootScope.user.settings.DEFAULT_CURRENCY },
        (data: any) => (this.newRequest.currency = data.records[0].id),
      ).$promise,
    );

    return Promise.all(promises).then(() => {
      this.newRequest = { ...this.queryParams, ...this.newRequest, ...origin };
      if (this.newRequest.deal_type === 'services') {
        this.newRequest.contract_type = 'purchase';
      }
      return this.newRequest as Request;
    });
  };

  updateData = (onlyUpdate = false, origin?: any) => {
    if (!onlyUpdate) {
      this.buildNewRequest(origin)
        .then((data) => (this.newRequestTemplate = data))
        .catch(this.GtUtils.errorClb);
    }
    this.requests = this.pageData.records;
    this.onUpdate?.({ requests: this.requests });
    this.requestsCount = this.pageData.count;
  };

  approve(request: Request) {
    this.ContractsService.approveDeal(request).then(() => this.viewModel.pageParamsChanged({}));
  }

  cancel(request: Request) {
    this.ContractsService.cancelDeal(request).then(() => this.viewModel.pageParamsChanged({}));
  }

  openRequestModal(request: Request) {
    this.ContractsService.requestModal(request).then(() => this.viewModel.pageParamsChanged({}));
  }

  openRequestModalQuick(request: Request) {
    this.ContractsService.requestModalQuick(request).then(() =>
      this.viewModel.pageParamsChanged({}),
    );
  }

  openContractModal(contract: Request) {
    this.ContractsService.contractModal(contract).then(() => this.viewModel.pageParamsChanged({}));
  }

  createRequest = async (request: RequestPost) => {
    await this.viewModel.createRequest(request);
  };

  saveRequest = async (request: Request) => {
    await this.viewModel.updateRequest(request);
  };

  cloneRequest(request: Request) {
    this.GtUtils.overlay('show');
    return this.ContractsService.cloneRequest(request)
      .then(() => this.viewModel.pageParamsChanged({}), this.GtUtils.errorClb)
      .finally(() => this.GtUtils.overlay('hide'));
  }

  createTicketFromIndicator(indicator: Request) {
    return this.ContractsService.createTicket(indicator.id);
  }

  refreshShowCheckboxCreateTicket() {
    this.requests.forEach((item) => {
      item.$_selected = false;
      if (item.unconnected_ticket_volume <= 0) {
        item.$_showCheckbox = false;
      } else {
        item.$_showCheckbox = this.creatingMultiTicket;
        item.$_inputedNumber = item.unconnected_ticket_volume;
      }
    });
  }

  startCreatingMultiTicket() {
    this.creatingMultiTicket = true;
    this.refreshShowCheckboxCreateTicket();
  }

  stopCreatingMultiTicket() {
    this.creatingMultiTicket = false;
  }

  createMultiTicket() {
    const selectedIndicators = this.requests.filter((indicator: any) => {
      return indicator.$_selected;
    });

    if (!selectedIndicators.length) {
      notify(this.gettext('Please select at least one object'), 'warning');
      return false;
    }

    this.GtUtils.overlay('show');
    const fieldsForClear = [
      'id',
      'conclusion_date',
      'number',
      'contract_number',
      'multicontract',
      'status',
      'approval_status',
      'stage',
    ];
    const newPositions: any = [];
    const firstIndicator = selectedIndicators[0];
    const indicatorsMap = selectedIndicators.reduce((map: any, indicator: any) => {
      map[indicator.id] = indicator;
      return map;
    }, {});

    this.ContractsService.Request.query(
      {
        id_list: selectedIndicators.map((indicator: any) => indicator.id),
        serializer: 'modal',
      },
      (data: any) => {
        data.records.forEach((item: any) => {
          item.from_request = item.id;
          item.stage = 'ticket';
          item.conclusion_date = new Date();
          item.request_status = 'new';
          item.responsible = this.$rootScope.user.id;
          item.volume = indicatorsMap[item.id].$_inputedNumber;

          newPositions.push(this.CoreUtils.cleanBeforeClone({ ...item }, fieldsForClear));
        });

        const multiTicket = {
          ...this.CoreUtils.cleanBeforeClone({ ...firstIndicator }, fieldsForClear),
          stage: 'ticket',
          use_type: 'commodity',
          positions: newPositions,
        };
        this.GtUtils.overlay('hide');

        return this.MulticontractService.multicontractModal(multiTicket, { ...multiTicket }).then(
          () => {
            this.stopCreatingMultiTicket();
            this.viewModel.pageParamsChanged({});
          },
        );
      },
    );
  }
}

export const requestsContainerComponent: ng.IComponentOptions = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    tableName: '<?',
    tableExpanded: '<?',
    total: '<?',
    onCreate: '&?',
    onUpdate: '&?',
    suppressNotifications: '<?',
    view: '<?',
    hideButton: '<?',
    simplifiedView: '<?',
  },
  template,
  controller: RequestsContainerController,
};
