import ng from 'angular';

import { formatDate } from '~/shared/lib/date';
import { notify } from '~/shared/lib/notify';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { CustomsCargoDeclarationService } from '^/app/execution/components/transport/customs-cargo-declaration/customs-cargo-declaration.service';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

function Controller(
  this: any,
  $rootScope: GtRootScopeService,
  $scope: ng.IScope,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $q: ng.IQService,
  FinancesService: FinancesService,
  DocumentsService: any,
  finance: any,
  GtUtils: GtUtilsService,
  ContractsService: ContractsService,
  extraData: any,
  gettext: ng.gettext.gettextFunction,
  InvoiceFormFieldsService: any,
  FormFieldParamsService: FormFieldParamsService,
  $state: ng.ui.IStateService,
  CustomsCargoDeclarationService: CustomsCargoDeclarationService,
  CustomValuesService: CustomValuesService,
) {
  const vm = this;

  vm.finance = finance || {};
  vm.errors = [];
  vm.tab = extraData?.tab || 'edit';
  vm.contract = extraData?.contract;
  vm.invoicePositionInitQueryParams = extraData?.invoicePositionInitQueryParams;
  vm.form = undefined;
  vm.payment = {};
  vm.ccdList = [];
  vm.save = save;
  vm.close = close;
  vm.clone = clone;
  vm.destroy = destroy;
  vm.updateData = updateData;
  vm.getCcdByInvoice = getCcdByInvoice;
  vm.updateCcdFields = updateCcdFields;
  vm.fields = undefined;
  vm.logistics = [];
  vm.logisticsTotal = {};
  vm.financePositionIsValid = financePositionIsValid;
  vm.contractPrices = [];
  vm.financePositions = extraData?.financePositions || [{}];
  vm.openFieldsConfigModal = openFieldsConfigModal;
  vm.hovering = false;
  vm.setHovering = setHovering;
  vm.isInvalid = isInvalid;
  vm.conterpartiesValid = true;
  vm.hideSaveContinue = extraData?.hideSaveContinue || false;
  vm.logisticsData = extraData?.logisticsData || {};
  vm.afterSaveFunc = extraData?.afterSaveFunc || false;
  vm.isLogisticsInvoice = extraData?.isInvoiceFromLogistics || false;
  vm.usesList = [];
  vm.updateFields = updateFields;
  vm.updateCounterparties = updateCounterparties;
  vm.updateCurrencyExchange = updateCurrencyExchange;
  vm.getFlatFields = getFlatFields;
  vm.openDocxModal = openDocxModal;
  vm.logistics = extraData?.logistics || [];
  vm.stayAfterSave = false;
  vm.detailsAfterSave = false;
  vm.createCcdAfterSave = isNeedToCreateCcdAfterSave(extraData);
  vm.changeShowReserve = changeShowReserve;

  vm.updateFinanceAccounts = false;
  vm.showReserve = false;
  activate();

  ////////////////

  function activate() {
    let when;
    vm.tab = extraData?.tab || 'edit';
    vm.finance.date = vm.finance.date || new Date();
    if (vm.finance.id) {
      when = updateData().then(() => {
        updateContractPrices();
        updateFields();
        vm.updateFinanceAccounts = !vm.updateFinanceAccounts;
      });
    } else if (vm.finance.contract) {
      when = updateContractPrices();
    }
    if (!vm.finance.id) {
      updateFields();
      $scope.$watch('vm.finance.cargo', getFinanceNumber);
      $scope.$watch('vm.finance.assignment', chagedAssignment);
      $scope.$watch('vm.finance.date', watchDateField);
      $scope.$watch('vm.finance.client_role', getFinanceNumber);
      $scope.$watch('vm.finance.contract', getFinanceNumber);
      $scope.$watch('vm.finance.auto_name', getFinanceNumber);
      $scope.$watch('vm.finance.invoice_type', getFinanceNumber);
      $scope.$watch('vm.financePositions', getFinanceNumber, true);
      $scope.$watch('vm.finance.currency', getLatestRate);
      $scope.$on('custom-values-updated__finance', function (event: any, data: any) {
        vm.customValues = data;
      });

      when = $q.when();
    }
    when.then(function () {
      $scope.$watch('vm.finance.invoice_type', setUsesList);
      $scope.$watch('vm.finance.discount', function () {
        if (vm.finance.full_amount) {
          vm.finance.amount = vm.finance.full_amount * (vm.finance.discount / 100);
          vm.finance.amount = Math.round(vm.finance.amount * 100) / 100;
        }
      });
      $scope.$watch('vm.finance.discount_calc', function (newVal?: number) {
        vm.finance.discount = 100 - (newVal ?? 0);
      });
      $scope.$watch('vm.finance.full_amount', function (fullAmount: any) {
        if (fullAmount) {
          vm.finance.amount = fullAmount * (vm.finance.discount / 100);
          vm.finance.amount = Math.round(vm.finance.amount * 100) / 100;
        }
      });
      $scope.$watch('vm.finance.discount_amount', function () {
        if (vm.finance.full_amount && vm.finance.discount_amount != 0) {
          vm.finance.amount = vm.finance.full_amount - (Number(vm.finance.discount_amount) || 0);
          vm.finance.amount = Math.round(vm.finance.amount * 100) / 100;
        }
      });
      $scope.$watch('vm.finance.business_unit', function (newVal: any, oldVal: any) {
        if (newVal !== oldVal) {
          clearApprovalConfig();
        }
      });
      $scope.$on('finance-position-table__change-full-amount', function (ev: any, total: any) {
        vm.finance.full_amount = total.newFullAmount;
        vm.finance.full_amount_without_vat = total.fullAmountWithoutVat;
        vm.finance.total_vat = total.totalVat;
        vm.finance.totalQuantityTotalAmount = total.totalQuantityTotalAmount;
        vm.finance.quantityTotalAmountWithoutVat = total.quantityTotalAmountWithoutVat;
        vm.finance.quantityTotalAmountVAT = total.quantityTotalAmountVAT;
        vm.finance.totalQuantityTotal = total.totalQuantityTotal;
        vm.finance.volume = Math.round(total.totalVolume * 1000) / 1000;
        if (vm.finance.full_amount && vm.finance.interest_rate) {
          vm.finance.totalInterestRateAmount =
            (vm.finance.full_amount * (vm.finance.interest_rate / 100)) / 360;
        }
      });
      $scope.$watch('vm.finance.dont_validate_counterparties', (newVal: any, oldVal: any) => {
        if (newVal !== oldVal) {
          updateCounterparties();
        }
      });
      $scope.$watch('vm.finance.bank_account', updatePaymentAndCurrency);
      $scope.$watch('vm.finance.invoice_type', () => {
        vm.updateFinanceAccounts = !vm.updateFinanceAccounts;
      });
      $scope.$watch('vm.finance.currency', () => {
        vm.updateFinanceAccounts = !vm.updateFinanceAccounts;
      });
      vm.updateFinanceAccounts = !vm.updateFinanceAccounts;
    });
    vm.isLogisticsInvoice = extraData?.isInvoiceFromLogistics;
  }

  function updateData() {
    vm.showReserve = false;
    return FinancesService.Finance.get({ id: vm.finance.id }, function (data: any) {
      vm.finance = data;
      renewPayment();
      vm.finance.payments.forEach(
        (payment: any) => (payment.bank_account = payment.bank_account || finance.bank_account),
      );
      if (vm.finance.discount_amount != 0) {
        vm.finance.full_amount = vm.finance.amount + vm.finance.discount_amount;
        vm.finance.full_amount = Math.round(vm.finance.full_amount * 100) / 100;
      } else {
        const partAmount = vm.finance.amount / vm.finance.discount;
        vm.finance.full_amount = partAmount * 100;
        vm.finance.full_amount = Math.round(vm.finance.full_amount * 100) / 100;
      }
      if (vm.finance.client_role_model) {
        vm.finance.client_role_model = vm.finance.client_role_model.toLowerCase();
      } else {
        vm.finance.client_role_model = 'buyer';
      }
    }).$promise.then(() => {
      return FinancesService.InvoicePosition.get(
        { invoice: vm.finance.id, page_size: 100 },
        function (data: any) {
          vm.financePositions = data.results;
        },
      ).$promise;
    });
  }

  function isNeedToCreateCcdAfterSave(extraData: any) {
    const contractPurpose: any =
      vm.contract?.contract_purpose ||
      {
        outgoing: 'sale',
        incoming: 'purchase',
        export: 'export',
      }[extraData.invoicePurpose as string];
    const userSettings = $rootScope.user.settings;
    const allowFromLogistics =
      vm.isLogisticsInvoice && userSettings.LOGISTICS_AUTO_CREATE_CUSTOM_DECLARATION;
    const allowForExportInvoice = extraData?.isExportInvoice && allowFromLogistics;
    const allowFromSale = contractPurpose === 'sale' && userSettings.ALLOW_CCD_FOR_SALE_CTR;
    const allowFromPurchase =
      contractPurpose === 'purchase' && userSettings.ALLOW_CCD_FOR_PURCHASE_CTR;

    return (
      (contractPurpose === 'export' && allowFromLogistics) ||
      (allowFromSale && allowFromLogistics) ||
      (allowFromPurchase && allowFromLogistics) ||
      contractPurpose === 'export' ||
      allowFromSale ||
      allowFromPurchase ||
      allowForExportInvoice
    );
  }

  function watchDateField() {
    getFinanceNumber();
    calcDateOfExecution();
  }

  function getLatestRate() {
    if (vm.finance.id || !vm.finance.currency) {
      return Promise.resolve();
    }

    let chain: Promise<boolean>;
    if (vm.finance.currency_exchange) {
      chain = FinancesService.CurrencyExchange.get({
        id: vm.finance.currency_exchange,
      }).$promise.then(
        (exchange: { currency_id: string }) => exchange.currency_id === vm.finance.currency,
      );
    } else {
      chain = Promise.resolve(true);
    }

    return chain
      .then((needToUpdateCurrencyExchange) => {
        if (!needToUpdateCurrencyExchange) {
          return Promise.resolve();
        }
        return FinancesService.getLatestExchangeObject(vm.finance.currency).then(
          (data?: { id: string }) => {
            vm.finance.currency_exchange = data?.id;
          },
        );
      })
      .catch(GtUtils.errorClb);
  }

  function setUsesList() {
    const result = FinancesService.getUsesList(vm.financePositions);
    vm.usesList = result.usesList;
    vm.financePositions = result.financePositions;
  }

  function updatePaymentAndCurrency() {
    renewPayment();
    if (vm.finance.bank_account) {
      getBankAccountCurrency();
    }
  }

  function renewPayment() {
    vm.payment = {
      finance: vm.finance.id,
      bank_account: vm.finance.bank_account,
      amount: parseFloat(vm.finance.to_pay),
      finance_amount_USD: vm.finance.amount,
    };
  }

  function getBankAccountCurrency() {
    return FinancesService.getBankAccountInfo(vm.finance.bank_account).then((data: any) => {
      vm.finance.currency = data.currency;
    });
  }

  function updateContractPrices() {
    if (!vm.finance.contract) {
      vm.contractPrices = [];
      return;
    }
    return ContractsService.ContractPrice.query(
      {
        contract: vm.finance.contract,
      },
      function (data: any) {
        vm.contractPrices = data.results;
      },
    ).$promise;
  }

  function updateCurrencyExchange(data: any) {
    vm.finance.currency_exchange = data;
    return FinancesService.Finance.update(vm.finance).$promise.then(() => updateData());
  }

  function updateCounterparties() {
    vm.conterpartiesValid = false;
    if (vm.finance.dont_validate_counterparties) {
      vm.conterpartiesValid = true;
      const field = vm.finance.invoice_type == 'outgoing' ? 'clientrole_from' : 'clientrole_to';

      GtUtils.getDefaultFinanceOwnerId(field, vm.finance.invoice_type).then((value: any) => {
        if (!vm.finance[field]) {
          vm.finance[field] = value;
        }
      });
    }

    const ipContractIds: any = [];
    vm.financePositions.forEach((item: any) => {
      if (item.contract && item.use == 'cargo') {
        ipContractIds.push(item.contract);
      }
    });

    ContractsService.getUniqueCounterparties(ipContractIds)
      .then((data: any) => {
        if (data.unique_counterparties.length > 1) {
          vm.conterpartiesValid = false;
          return notify(gettext('Counterparties in contracts is not unique'), 'error');
        }
        vm.conterpartiesValid = true;
        if (data.unique_counterparties.length < 1) {
          return;
        }
        const counterparties = data.unique_counterparties[0];
        vm.finance.clientrole_from =
          vm.finance.custom_clientrole_from ||
          counterparties.supplier_id ||
          vm.finance.clientrole_from;
        vm.finance.clientrole_to = counterparties.buyer_id || vm.finance.clientrole_to;
      })
      .catch((error: any) => {
        GtUtils.errorClb(error);
      });
  }

  function isInvalid() {
    if (vm.ccdList.length) {
      return false;
    }
    return (
      vm.form.$invalid ||
      !vm.financePositionIsValid() ||
      !vm.conterpartiesValid ||
      vm.finance.full_amount < 0
    );
  }

  function openFieldsConfigModal() {
    FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(updateFields);
  }

  function updateFields() {
    FormFieldParamsService.getFields(getFormConfig())
      .then(function (fields: any) {
        vm.fields = fields;
        vm.fieldsTemplateOptions = getFlatFields(fields);
      })
      .catch(GtUtils.errorClb);
  }

  function getFlatFields(fields: any) {
    return FormFieldParamsService.getFlatFields({ fieldsDef: fields }).map((field: any) => ({
      ...field.templateOptions,
      key: field.key,
    }));
  }

  function close(data: any, silent: any) {
    if (!silent && !confirm(gettext('Close modal?'))) {
      return;
    }
    $uibModalInstance.close(data || 'cancel');
  }

  function clone() {
    return FinancesService.cloneInvoice(vm.finance);
  }

  function destroy() {
    const msg = gettext('Are you sure that you want delete this payment?');
    if (!confirm(msg)) {
      return;
    }
    FinancesService.Finance.delete({ id: vm.finance.id }, function () {
      notify(gettext('Payment removed'));
      close(null, true);
    });
  }

  function afterSaveCalBack(func: any, data: any) {
    if (func) {
      return func(data).$promise;
    }
    return $q.when();
  }

  function calcDateOfExecution() {
    if (vm.finance.id || !$rootScope.user.settings.AUTO_DATE_OF_PAYMENT_PLAN) {
      return;
    }

    const startDate = formatDate(vm.finance.date, 'dd.MM.yyyy');
    return GtUtils.calcBusinessDays(
      startDate,
      vm.finance._days_for_invoice_payment ||
        $rootScope.user.settings.DEFAULT_VALUES.invoice_payment_days,
    ).then((response: any) => {
      vm.finance.date_of_execution = new Date(
        vm.finance.date.getTime() + response.data.calendar_days * 24 * 3600 * 1000,
      );
    });
  }

  function save() {
    vm.form.$invalid = true;
    if (vm.finance.use !== 'costs') {
      vm.finance.client_role_model = undefined;
    }

    const discount = Math.round((vm.finance.amount / vm.finance.full_amount) * 1000000) / 10000;
    if (vm.finance.discount == 100 && vm.finance.discount_amount != 0) {
      vm.finance.discount = discount;
    }
    if (vm.finance.id && vm.finance.discount_amount != 0) {
      vm.finance.discount = discount;
    }

    checkChargePositions(vm.financePositions)
      .then((save: any) => {
        if (save) {
          const financeId = vm.finance.id;
          return FinancesService.Finance.bulkCreateOrUpdatePositions(
            {
              invoice: vm.finance,
              invoice_positions: vm.financePositions,
              logistics_data: vm.logisticsData,
              logistics: vm.logistics,
              currency_symbol: 'usd',
            },
            (data: any) => {
              afterSaveCalBack(vm.afterSaveFunc, data.invoice);
              notify(gettext('Invoice saved'));
              vm.finance.id = data.invoice.id;
              if (financeId === undefined) {
                CustomValuesService.createCustomValues(vm.finance.id, vm.customValues);
              }
              const when = vm.createCcdAfterSave ? getCcdByInvoice(vm.finance.id) : $q.when();
              when.then((ccdList: any) => {
                if (ccdList) {
                  vm.ccdList = ccdList.map((ccd: any) => vm.updateCcdFields(ccd));
                  vm.updateData().then(() => updateFields());
                } else if (vm.stayAfterSave) {
                  return vm.updateData().then(() => updateFields());
                } else if (vm.detailsAfterSave) {
                  vm.updateData().then(() => {
                    updateFields();
                    close(vm.finance, true);
                    if ($rootScope.user.settings.DOCUMENTS_REQUIRED_FOR_INVOICE) {
                      $state.go('gt.page.payment', { id: vm.finance.id, tab: 'documents' });
                    } else {
                      $state.go('gt.page.payment', { id: vm.finance.id });
                    }
                  });
                } else {
                  close(vm.finance, true);
                  if ($rootScope.user.settings.DOCUMENTS_REQUIRED_FOR_INVOICE) {
                    $state.go('gt.page.payment', { id: vm.finance.id, tab: 'documents' });
                  } else {
                    $state.go('gt.page.payment', { id: vm.finance.id });
                  }
                }
              });
            },
            _error,
          ).$promise;
        }
      })
      .then(() => {
        if (vm.createCcdAfterSave && vm.ccdList.length) {
          if (vm.detailsAfterSave) {
            vm.updateData().then(() => {
              updateFields();
              close(vm.finance, true);
              if ($rootScope.user.settings.DOCUMENTS_REQUIRED_FOR_INVOICE) {
                $state.go('gt.page.payment', { id: vm.finance.id, tab: 'documents' });
              } else {
                $state.go('gt.page.payment', { id: vm.finance.id });
              }
            });
          } else {
            close(vm.finance, true);
            if ($rootScope.user.settings.DOCUMENTS_REQUIRED_FOR_INVOICE) {
              $state.go('gt.page.payment', { id: vm.finance.id, tab: 'documents' });
            } else {
              $state.go('gt.page.payment', { id: vm.finance.id });
            }
          }
        }
      });
  }

  function checkChargePositions(positions: any) {
    const checkPosition = (charge: any) => {
      const idParams = {
        passport_list: charge.passport,
        contract_list: charge.contract,
        logistic_list: charge.logistic,
      };
      return ContractsService.isChargeUnique(idParams, {
        charge: charge.charge,
        price: charge.amount,
        is_gain: charge.use === 'gains',
      }).then((result: any) => {
        if (result) {
          return true;
        } else if (
          confirm(
            GtUtils.translate(
              'The contract or passport already contains a cost with the same name and amount. ' +
                'Are you sure you want to save it?',
            ),
          )
        ) {
          return true;
        } else {
          throw new Error();
        }
      });
    };
    return positions
      .filter(
        (position: any) =>
          (position.contract || position.logistic || position.passport) &&
          !position.contractcharge &&
          ['costs', 'gains'].includes(position.use),
      )
      .map((charge: any) => () => checkPosition(charge))
      .reduce((promise: any, next: any) => promise.then(next), $q.resolve(true));
  }

  function financePositionIsValid() {
    return vm.financePositions.every(checkPositionByUse);
  }

  function checkPositionByUse(invoicePosition: any) {
    const requiredFields = FinancesService.getRequiredFields(invoicePosition);
    return requiredFields.every((field: any) =>
      FinancesService.validatePositionField(field, invoicePosition),
    );
  }

  function _error(data: any) {
    GtUtils.errorClb(data);
    vm.finance.errors = data.data;
  }

  function changedFormName() {
    updateFields();
  }

  function chagedAssignment() {
    getFinanceNumber();
    changedFormName();
  }

  function getFinanceNumber() {
    const cargoId = getUniquePositionCargoId();
    const chargeId = getUniquePositionChargeId();
    const contractId = getUniquePositionContractId();
    const date = formatDate(vm.finance.date, 'yyyy-MM-dd');
    const contractIds = getPositionsCargoContractIds();

    const params = {
      invoice_type: vm.finance.invoice_type,
      contract_ids: contractIds,
      contract_all_use_ids: vm.financePositions.map((position: any) => position.contract),
      cargo_id: cargoId,
      charge_id: chargeId,
      contract_id: contractId,
      date: date,
      clientrole_from_id: vm.finance.clientrole_from,
      clientrole_to_id: vm.finance.clientrole_to,
      assignment: vm.finance.assignment,
    };

    if (vm.financePositions.length && vm.finance.auto_name) {
      return FinancesService.Finance.generateNumber(
        params,
        function (data: any) {
          if (data.finance_number) {
            vm.finance.number = data.finance_number;
          }
          if (data.packing_list) {
            vm.finance.packing_list = data.packing_list;
          }
        },
        _error,
      );
    }
  }

  function getPositionsCargoContractIds() {
    return vm.financePositions
      .filter((position: any) => position.use == 'cargo')
      .map((position: any) => position.contract);
  }

  function getUniquePositionCargoId() {
    const positionsCargoIds = vm.financePositions.map(
      (invoicePosition: any) => invoicePosition.crop,
    );
    if (positionsCargoIds.some((id?: number) => id == undefined)) {
      return;
    }
    return getUniqueValue(positionsCargoIds);
  }

  function getUniquePositionChargeId() {
    const positionsChargeIds = vm.financePositions.map(
      (invoicePosition: any) => invoicePosition._charge_id,
    );
    if (positionsChargeIds.some((id?: number) => id == undefined)) {
      return;
    }
    return getUniqueValue(positionsChargeIds);
  }

  function getUniquePositionContractId() {
    const positionsContractIds = vm.financePositions.map(
      (invoicePosition: any) => invoicePosition.contract,
    );
    if (positionsContractIds.some((id?: number) => id == undefined)) {
      return;
    }
    return getUniqueValue(positionsContractIds);
  }

  function getUniqueValue(valuesList: any) {
    const valuesSet = Array.from(new Set(valuesList));

    if (valuesSet.length == 1) {
      return valuesSet[0];
    }
    return null;
  }

  function openDocxModal() {
    return DocumentsService.generateDocxModal('Finance', vm.finance.id);
  }

  function getFormConfig() {
    return InvoiceFormFieldsService.getFields(vm.finance, vm.financePositions);
  }

  function setHovering(value: any) {
    vm.hovering = value;
  }

  function getCcdByInvoice(invoiceId: number) {
    return CustomsCargoDeclarationService.getCcdByInvoice({ invoice_id: invoiceId });
  }

  function updateCcdFields(declaration: any) {
    return CustomsCargoDeclarationService.updateCcdFieldsModel(declaration, extraData);
  }

  function clearApprovalConfig() {
    vm.finance.approval_config = null;
  }

  function changeShowReserve(value: any) {
    vm.showReserve = value;
  }
}

(function () {
  'use strict';
  ng.module('finances.legacy').controller('FinancesFinanceModalController', Controller);

  Controller.$inject = [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    '$q',
    'FinancesService',
    'DocumentsService',
    'finance',
    'GtUtils',
    'ContractsService',
    'extraData',
    'gettext',
    'InvoiceFormFieldsService',
    'FormFieldParamsService',
    '$state',
    'CustomsCargoDeclarationService',
    'CustomValuesService',
  ];
})();

export type FinanceModalController = ReturnType<typeof Controller>;
