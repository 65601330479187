import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './form-field-params-modal.html?raw';
import type { FormFieldParamsService } from '../form-field-params.service';

import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { html } from '^/shared/utils';

export const FormFieldParamsModal = {
  bindings: {
    formName: '<',
    fields: '<',
    modalInstance: '<',
    filterLevel: '<',
    extraData: '<?',
  },
  template,
  controller: [
    '$scope',
    'gettext',
    'FormFieldParamsService',
    'CoreService',
    'GtUtils',
    class {
      $scope: ng.IScope;
      CoreService: CoreService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      business_unit: any;
      business_units: any;
      clickedAll: any;
      defaultBU: any;
      draggableFields: any;
      extraData: any;
      fields: any;
      filterLevel = 'form-field-params-modal';
      formName = 'form-field-params-modal';
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      overlay: any;
      tableApi: any;
      tableOptions: any;
      applyToAllBusinessUnits: boolean;
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        CoreService: CoreService,
        GtUtils: GtUtilsService,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.CoreService = CoreService;
        this.GtUtils = GtUtils;

        this.overlay = false;
        this.tableOptions = this.getTableOptions();
        this.tableApi = undefined;
        this.clickedAll = false;
        this.extraData = this.extraData || {};
        this.defaultBU = {
          id: 'undefined',
          title: 'Default',
          filter_level: this.filterLevel,
        };
        this.business_unit = {
          id: 'undefined',
          title: 'Default',
          filter_level: this.filterLevel,
        };
        this.business_units = [this.defaultBU];
        this.applyToAllBusinessUnits = false;
      }

      $onInit() {
        this.updateBusinessUnits().then(() => this.updateConfigData());
        this.draggableFields = this.extraData?.draggableFields || false;

        this.$scope.$watch('$ctrl.applyToAllBusinessUnits', (newVal, oldVal) => {
          if (newVal !== oldVal) {
            this.tableOptions.templateArgs.applyToAllBusinessUnits = newVal;
          }
        });
      }

      updateBusinessUnits() {
        return this.CoreService.BusinessUnit.query().$promise.then((data: any) => {
          this.business_units = [this.defaultBU, ...data];
        });
      }

      updateConfigData() {
        this.overlay = true;
        return this.FormFieldParamsService.getFormFieldParams(
          this.formName,
          this.business_unit,
          this.fields,
        )
          .then((data: any) => {
            this.tableApi.setRowData(data);
          })
          .finally(() => {
            this.overlay = false;
          });
      }

      getTableOptions() {
        return {
          tableName: 'form-field-params-modal-table',
          filterLevel: `${this.formName}_form-field-params-modal`,
          templateArgs: {
            applyToAllBusinessUnits: this.applyToAllBusinessUnits,
          },
          columnDefs: [
            {
              columnName: 'title',
              title: this.gettext('Title'),
              cellTemplate:
                '<span ng-class="{\'label-opacity label-smoke\': !item.visible}"><span ng-if="item.isNew" ng-class="{\'label-strong\': true}">*</span><span ng-class="{\'negative-number label-strong\': item.required}"> {[{ item.title | translate }]}</span></span>',
            },
            {
              columnName: 'new_title',
              title: this.gettext('New title'),
              cellTemplate:
                '<input class="form-control" ng-if="item.visible" ng-model="item.new_title"/>',
            },
            {
              columnName: 'field_name',
              title: this.gettext('Field'),
              cellTemplate:
                '<span ng-class="{\'label-opacity label-smoke\': !item.visible}">{[{ item.field_name }]}<span>',
            },
            {
              columnName: 'visible',
              hint: this.gettext('Select/Deselect all'),
              onHeadClick: () => {
                const data = this.tableApi.getRowData();
                data
                  .filter((i: any) => !i.required)
                  .forEach((i: any) => (i.visible = this.clickedAll));
                this.clickedAll = !this.clickedAll;
                this.tableApi.setRowData(data);
              },
              title: this.gettext('Visible'),
              cellTemplate:
                '<input class="form-control" ng-disabled="item.required || args.applyToAllBusinessUnits" ng-model="item.visible" type="checkbox">',
            },
            {
              columnName: 'required',
              hint: this.gettext('Select/Deselect all'),
              onHeadClick: () => {
                const data = this.tableApi.getRowData();
                data
                  .filter((i: any) => !i.initRequired)
                  .forEach((i: any) => {
                    i.required = this.clickedAll;
                    i.visible = true;
                  });
                this.clickedAll = !this.clickedAll;
                this.tableApi.setRowData(data);
              },
              title: this.gettext('Required'),
              cellTemplate: html`
                <input
                  class="form-control"
                  ng-disabled="item.initRequired || args.applyToAllBusinessUnits"
                  ng-model="item.required"
                  ng-click="item.visible = true"
                  type="checkbox"
                />
              `,
            },
            {
              columnName: 'users',
              title: this.gettext('Users'),
              cellTemplate: html`
                <div ng-if="item.visible">
                  <a
                    class="btn btn-xs"
                    ng-click="item.$showUsers=!item.$showUsers"
                    ng-class="{'btn-danger': item.$showUsers, 'btn-add-dark': !item.$showUsers}"
                    ng-if="item.visible"
                  >
                    <i class="fa fa-user"></i>
                    <translate ng-if="!item.$showUsers">Show</translate>
                    <translate ng-if="item.$showUsers">Hide</translate>
                  </a>
                  <gt-resource-multiselect
                    ng-if="item.$showUsers"
                    ng-model="item.users"
                    placeholder="'Users'|translate"
                    resource-name="'auth.User'"
                    allow-clear="true"
                  ></gt-resource-multiselect>
                </div>
              `,
            },
          ],
          onInit: (api: any) => (this.tableApi = api),
        };
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'cancel');
      }

      save() {
        this.overlay = true;
        if (this.applyToAllBusinessUnits) {
          this.FormFieldParamsService.saveParamsForAllBusinessUnits(this.tableApi.getRowData())
            .then(() => {
              this.overlay = false;
              this.close(false, true);
              notify(this.gettext('Configuration is being applied to all business units'));
            })
            .catch(() => {
              this.overlay = false;
            });
        } else {
          this.FormFieldParamsService.saveParams(
            this.tableApi.getRowData(),
            this.business_unit,
          ).then(() => {
            this.overlay = false;
            this.close(false, true);
            notify(this.gettext('Configuration is saved'));
          });
        }
      }
    },
  ],
};
