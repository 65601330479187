import ng from 'angular';

import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').directive('voyageRow', directive);

  function directive() {
    return {
      template: require('./voyage-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        voyage: '<',
        expanded: '<?',
        onChange: '&',
        block: '<?',
        onMenuHover: '&?',
      },
    };
  }

  Controller.$inject = [
    '$scope',
    'LogisticsService',
    'ContractsService',
    'DocumentsService',
    'ClientsService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    LogisticsService: LogisticsService,
    ContractsService: ContractsService,
    DocumentsService: any,
    ClientsService: any,
  ) {
    const vm = this;
    vm.openVoyageModal = openVoyageModal;
    vm.openVesselModal = openVesselModal;
    vm.openPassportModal = openPassportModal;
    vm.openDocumentModal = openDocumentModal;
    vm.clone = clone;
    vm.openEmailModal = openEmailModal;
    vm.connectToPassport = connectToPassport;
    vm.getTariffFilterLevel = getTariffFilterLevel;

    ////////////////

    function updateData() {
      $scope.$emit('voyages-update');
    }

    function openVoyageModal(voyage: any) {
      return LogisticsService.voyageModal(voyage).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        updateData();
      });
    }

    function openVesselModal(vessel: any) {
      return LogisticsService.vesselModal(vessel).then(updateData);
    }

    function openPassportModal(passport: any) {
      return ContractsService.passportModal(passport).then(updateData);
    }

    function cloneVoyage(voyage: any) {
      const newVoyage = { ...voyage };
      delete newVoyage.id;
      newVoyage.few_commodity.forEach((row: any) => {
        delete row.id;
        delete row.voyage;
      });
      return newVoyage;
    }
    function clone(voyage: any) {
      return LogisticsService.voyageModal(cloneVoyage(voyage)).then(updateData);
    }

    function openDocumentModal(modelName: string, objectId: number) {
      return DocumentsService.documentListModal({
        model_name: modelName,
        object_id: objectId,
      }).then(() => updateData());
    }

    function openEmailModal() {
      return ClientsService.sendEmailModal('Voyage', vm.voyage.id);
    }

    function connectToPassport(voyage: any, passportId: number) {
      return LogisticsService.Voyage.connectToPassport({
        voyage_id: voyage.id,
        passport_id: passportId,
      }).$promise.then(updateData);
    }

    function getTariffFilterLevel(voyageId: number) {
      return voyageId + '-voyage-list-tarifs';
    }
  }
})();
