import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').config(configFunction);

  configFunction.$inject = ['$stateProvider', 'gettext'];

  function configFunction(
    $stateProvider: ng.ui.IStateProvider,
    gettext: ng.gettext.gettextFunction,
  ) {
    $stateProvider
      .state('clients', {
        parent: 'gt.page',
        url: '/clients',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('clients.persons', {
        url: '/persons',
        component: 'personsPageView',
        data: {
          pageTitle: gettext('Contacts'),
        },
      })
      .state('clients.roles', {
        url: '/clients',
        component: 'clientListPage',
        resolve: {
          filterLevel: () => {
            return 'client-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('client-list-page');
            },
          ],
        },
        data: {
          pageTitle: gettext('Clients'),
        },
      })
      .state('clients.updates', {
        url: '/updates',
        component: 'updatesPageView',
        resolve: {
          filterLevel: () => {
            return 'updates-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('updates-page-view');
            },
          ],
        },
        data: {
          pageTitle: gettext('Updates'),
        },
      });
  }
})();
