import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('company.documents.legacy').component('documentsPageView', {
    bindings: {
      queryParams: '<?',
      filterLevel: '<',
    },
    template: require('./documents-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'DocumentsService',
    'PageService',
    'gtFilterService',
    'gettext',
    'GtUtils',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    DocumentsService: any,
    PageService: PageService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
  ) {
    const vm = this;

    vm.quickAdd = false;
    vm.documentsCount = 0;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'documents-page-view';
      vm.queryParams = vm.queryParams || {};
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-pl-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Refresh(vm.filterLevel),
        ],
        pages: [
          {
            title: gettext('Documents'),
            icon: 'fa-upload',
          },
        ],
      };

      config.filters = {
        filterLevel: vm.filterLevel,
        search: true,
        dates: true,
        dateSelects: [
          {
            argument: 'create_time',
            placeholder: gettext('Create date'),
          },
          {
            argument: 'invoice_date_of_payment_plan',
            placeholder: gettext('Invoice date of payment plan'),
          },
          {
            argument: 'invoice_date_of_payment_fact',
            placeholder: gettext('Invoice date of payment fact'),
          },
          {
            argument: 'invoice_date_of_issuance',
            placeholder: gettext('Invoice date of issuance'),
          },
          {
            argument: 'contract_conclusion_date',
            placeholder: gettext('Contract conclusion date'),
          },
        ],
        multiSelects: [
          {
            argument: 'client_list',
            placeholder: gettext('client'),
            resource: 'clients.client',
          },
          {
            argument: 'voyage_list',
            placeholder: gettext('voyage'),
            resource: 'logistics.Voyage',
          },
          {
            argument: 'invoice_commodity_list',
            placeholder: gettext('Invoice commodity'),
            resource: 'crops.Crop',
          },
          {
            argument: 'invoice_charge_list',
            placeholder: gettext('Invoice charge'),
            resource: 'finances.Charge',
          },
          {
            argument: 'contract_buyer_list',
            placeholder: gettext('contract buyer'),
            resource: 'clients.buyer',
          },
          {
            argument: 'contract_supplier_list',
            placeholder: gettext('contract supplier'),
            resource: 'clients.supplier',
          },
          {
            argument: 'contract_list',
            placeholder: gettext('contract'),
            resource: 'contracts.contractbase',
          },
          {
            argument: 'invoice_list',
            placeholder: gettext('invoice'),
            resource: 'finances.finance',
          },
          {
            argument: 'passport_list',
            placeholder: gettext('passport'),
            resource: 'passports.passport',
          },
          {
            argument: 'invoice_clientrole_from_list',
            placeholder: gettext('invoice client from'),
            resource: 'clients.clientrole',
          },
          {
            argument: 'invoice_clientrole_to_list',
            placeholder: gettext('invoice client to'),
            resource: 'clients.clientrole',
          },
          {
            argument: 'basis_doc_list',
            placeholder: gettext('basis doc'),
            resource: 'contracts.BasisDoc',
          },
          {
            argument: 'client_doc_list',
            placeholder: gettext('client doc'),
            resource: 'clients.ClientDocumentType',
          },
          {
            argument: 'contract_counterparty_list',
            placeholder: gettext('Contract counterparty'),
            resource: 'clients.Client',
          },
          {
            argument: 'author_list',
            placeholder: gettext('author'),
            resource: 'auth.user',
            related_fk: 'author',
            related_model: 'documents.Document',
          },
          {
            argument: 'content_type_model_list',
            placeholder: gettext('object type'),
            resource: 'contenttypes.contenttype',
            getQueryParams: () => {
              return { for_documents: true };
            },
          },
        ],
        nestedSelects: [
          gtFilterService.getBoolFilter(
            'has_invoice_ctr_passp_connected',
            gettext('Has invoice Contract/Passport connected'),
          ),
        ],
        nestedMultiSelects: [
          {
            argument: 'request_stage_list',
            placeholder: gettext('Request stage'),
            items: [
              { id: 'indicator', title: gettext('indicator') },
              { id: 'ticket', title: gettext('ticket') },
            ],
          },
          {
            argument: 'contract_type_list',
            placeholder: gettext('Contract type'),
            items: [
              { id: 'sale', title: gettext('sale') },
              { id: 'purchase', title: gettext('purchase') },
            ],
          },
          {
            argument: 'contract_deal_type_list',
            placeholder: gettext('Contract deal type'),
            items: [
              { id: 'spot', title: gettext('commodity') },
              { id: 'services', title: gettext('services') },
              { id: 'intermediate', title: gettext('intermediate') },
              { id: 'export', title: gettext('export') },
            ],
          },
          {
            argument: 'invoice_type_list',
            placeholder: gettext('Invoice type'),
            items: [
              { id: 'incoming', title: gettext('incoming') },
              { id: 'outgoing', title: gettext('outgoing') },
            ],
          },
          {
            argument: 'doc_type_list',
            placeholder: gettext('Document type'),
            items: [
              { id: 'docx', title: gettext('word') },
              { id: 'xlsx', title: gettext('excel') },
              { id: 'pdf', title: gettext('pdf') },
            ],
          },
          {
            argument: 'passport_business_date_year_list',
            placeholder: gettext('Passport business date year'),
            items: GtUtils.getYearList(),
          },
          {
            argument: 'passport_business_date_month_list',
            placeholder: gettext('Passport business date month'),
            items: GtUtils.getMonthList(),
          },
          {
            argument: 'invoice_use_list',
            placeholder: gettext('Invoice use'),
            items: [
              { id: 'cargo', title: gettext('cargo') },
              { id: 'gains', title: gettext('gains') },
              { id: 'costs', title: gettext('costs') },
              { id: 'expenses', title: gettext('expenses') },
              { id: 'washout', title: gettext('washout') },
              { id: 'credit', title: gettext('credit') },
              { id: 'other', title: gettext('other') },
            ],
          },
        ],
      };

      return config;
    }
  }
})();
