import type ng from 'angular';

import template from './analytics-container.html?raw';
import type { MarketAnalyticsService } from '../analytics.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';

export const AnalyticsContainer = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gettext',
    'MarketAnalyticsService',
    'gtFilterService',
    class {
      MarketAnalyticsService: MarketAnalyticsService;
      filterLevel = 'market-analytics-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      potentialsByCropChartConfig: any;
      queryParams: QueryParams = {};
      showElevatorPrices: any;
      valueExecutionChartConfig: any;
      volumeExecutionChartConfig: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        MarketAnalyticsService: MarketAnalyticsService,
        gtFilterService: GtFilterService,
      ) {
        this.gettext = gettext;
        this.MarketAnalyticsService = MarketAnalyticsService;
        this.gtFilterService = gtFilterService;

        this.queryParams = {};

        this.showElevatorPrices = false;
        this.volumeExecutionChartConfig = undefined;
        this.valueExecutionChartConfig = undefined;
        this.potentialsByCropChartConfig = undefined;
      }

      $onInit() {
        this.gtFilterService.subscribe(
          this.filterLevel,
          (params) => {
            this.queryParams = params;
            this.updateAnalyticsData();
          },
          this.queryParams,
        );
        this.updateAnalyticsData();
      }

      updateFilterParams() {
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      updateAnalyticsData() {
        this.MarketAnalyticsService.getAnalyticsData(this.queryParams);
        this.updateVolumeExecutionChartConfig();
        this.updateValueExecutionChartConfig();
        this.updatePotentialsByCropChartConfig();
      }

      updateVolumeExecutionChartConfig() {
        this.MarketAnalyticsService.getVolumeExecutionChartConfig().then(
          (data: any) => (this.volumeExecutionChartConfig = data),
        );
      }

      updateValueExecutionChartConfig() {
        this.MarketAnalyticsService.getValueExecutionChartConfig().then(
          (data: any) => (this.valueExecutionChartConfig = data),
        );
      }

      updatePotentialsByCropChartConfig() {
        this.MarketAnalyticsService.getPotentialsByCropChartConfig().then(
          (data: any) => (this.potentialsByCropChartConfig = data),
        );
      }
    },
  ],
};
