import { genId } from '~/shared/lib/utils';

import type {
  ColumnConfigParams,
  ColumnsConfig,
  ColumnsSetConfig,
  DataParams,
  DataRecord,
  FieldDef,
} from '../lib/types';

export const getConfigParamsTitle = (params: ColumnConfigParams) => {
  return params.new_title !== '' && params.new_title !== undefined
    ? params.new_title
    : params.title;
};

export const addMissingColsFromDefaultConfig = (
  columns: ColumnConfigParams[],
  defaultColumnsMap: Record<string, ColumnConfigParams>,
): ColumnConfigParams[] => {
  const colNames = columns.map((col) => col.column_name);
  const missingCols = Object.keys(defaultColumnsMap).filter(
    (colName) => !colNames.includes(colName),
  );

  return [...columns, ...missingCols.map((colName) => defaultColumnsMap[colName])];
};

export const columnsConfigFromColumnsSetConfig = (
  config: ColumnsSetConfig,
  defaultColumns: ColumnConfigParams[],
): ColumnsConfig => {
  const defaultColumnsMap = Object.fromEntries(defaultColumns.map((col) => [col.column_name, col]));

  const columns: ColumnConfigParams[] =
    config.column_params
      ?.filter((col) => col.column_name in defaultColumnsMap)
      .map((col) => ({
        ...col,
        index: col.index ?? 0,
        requiredByDefault: defaultColumnsMap[col.column_name].requiredByDefault,
        visible: (defaultColumnsMap[col.column_name].requiredByDefault || col.visible) ?? true,
        title:
          col.new_title !== undefined && col.new_title !== ''
            ? col.new_title
            : (defaultColumnsMap[col.column_name].title ?? col.column_name),
      })) ?? [];

  return {
    ...config,
    column_params: addMissingColsFromDefaultConfig(columns, defaultColumnsMap)
      .toSorted((a, b) => (a.index ?? 0) - (b.index ?? 0))
      .map((col, index) => ({ ...col, index, columnssetconfig_id: config.id })),
  };
};

export const columnConfigDefFromFieldDef = <R extends DataRecord, P extends DataParams>(
  fieldDefs: FieldDef<R, P>[],
): ColumnConfigParams[] =>
  fieldDefs.map((field, index) => ({
    id: -genId(),
    columnssetconfig_id: null,
    index,
    title: field.title ?? String(field.key),
    table_name: '',
    column_name: String(field.key),
    requiredByDefault: field.required ?? false,
    required: false,
    visible: true,
  }));

export const updateColumnsIndexes = (
  columns: ColumnConfigParams[],
  draggedId: number,
  overId: number,
): ColumnConfigParams[] => {
  const colsMap = Object.fromEntries(columns.map((col) => [col.id, col]));
  const [draggableCol, overCol] = [colsMap[draggedId], colsMap[overId]];
  const draggedOverSameList = draggableCol.visible == overCol.visible;
  const increment = draggableCol.index < overCol.index ? 1 : -1;

  draggableCol.index = overCol.index + increment;
  draggableCol.visible = draggedOverSameList ? draggableCol.visible : !draggableCol.visible;

  colsMap[draggableCol.id] = draggableCol;

  return Object.values(colsMap)
    .map((col) => {
      return {
        ...col,
        index: col.index > draggableCol.index ? col.index + 1 : col.index - 1,
      };
    })
    .toSorted((a, b) => a.index - b.index)
    .map((col, index) => ({ ...col, index }));
};

export const buildNewColumnConfig = (
  defaultColumnParams: ColumnConfigParams[],
  config?: Partial<ColumnsConfig>,
): ColumnsConfig => ({
  id: -genId(),
  update_time: null,
  create_time: null,
  editor: null,
  config_level: '',
  title: '',
  column_params:
    config?.column_params?.map((col) => ({
      ...col,
      id: -genId(1),
      columnssetconfig_id: -genId(),
    })) ?? defaultColumnParams,
});
