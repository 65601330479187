import React from 'react';

import { PageFilters } from '~/core/page-filters';
import { useComponent } from '~/shared/lib/components';
import { useObservableState } from '~/shared/lib/state';

import { DatasetFiltersModel, type DatasetFiltersProps } from './dataset-filters.model';
import type { DataParams, DataRecord } from '../../lib/types';

export const DatasetFilters = <R extends DataRecord, P extends DataParams>(
  props: DatasetFiltersProps<R, P>,
) => {
  const { model } = useComponent(props, DatasetFiltersModel<R, P>);

  const filtersOptions = useObservableState(model.filtersOptions$);
  const sortingOptions = useObservableState(model.sortingOptions$);
  const presetOptions = useObservableState(model.presetOptions$);

  return (
    (!!filtersOptions?.length && !!sortingOptions && !!presetOptions && (
      <PageFilters
        filtersOptions={filtersOptions}
        sortingOptions={sortingOptions}
        presetOptions={presetOptions}
        onChange={model.presetChanged}
        onSave={model.savePreset}
        onDelete={model.deletePreset}
        mode="line"
      />
    )) ||
    null
  );
};
