import { coreQueryparamssetsList } from '@gt/api';
import type ng from 'angular';
import moment from 'moment';

import type { GtRootScopeService, QueryParams } from './types';

import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class CoreService {
  static getModelContentType(_model: string) {
    throw new Error('Method not implemented.');
  }
  BusinessUnit: any;
  ChecklistPoint: any;
  CollectionExportColumnParams: any;
  ColumnParams: any;
  ColumnsSetConfigs: any;
  Comment: any;
  ContentType: any;
  CropYear: any;
  CustomStatus: any;
  ExportColumnParams: any;
  PageFilterConfig: any;
  QueryParamsSet: any;
  RangeRule: any;
  Season: any;
  TypeOfActivity: any;
  contentTypeObjects: any;
  contentTypes: any;
  controlResource: any;
  siteSettings: any;

  static readonly $inject = [
    '$rootScope',
    '$resource',
    '$http',
    '$uibModal',
    '$q',
    'gettext',
    'gettextCatalog',
  ];
  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly $resource: ng.resource.IResourceService,
    private readonly $http: ng.IHttpService,
    private readonly $uibModal: ng.ui.bootstrap.IModalService,
    private readonly $q: ng.IQService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly gettextCatalog: ng.gettext.gettextCatalog,
  ) {
    this.contentTypes = undefined;
    this.contentTypeObjects = undefined;

    this.ContentType = this.$resource(
      '/api/core/content-types/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/content-types/predictions/',
        },
      },
    );

    this.QueryParamsSet = this.$resource(
      '/api/core/queryparamssets/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/queryparamssets/predictions/',
        },
      },
    );

    this.ColumnsSetConfigs = this.$resource(
      '/api/core/columns-set-configs/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        create: { method: 'POST' },
        update: { method: 'PATCH' },
        delete: { method: 'DELETE' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/columns-set-configs/predictions/',
        },
      },
    );

    this.PageFilterConfig = this.$resource(
      '/api/core/page-filter-config/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
      },
    );

    this.BusinessUnit = this.$resource(
      '/api/core/business-units/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: true },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/business-units/predictions/',
        },
      },
    );

    this.CropYear = this.$resource(
      '/api/core/year/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: true },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/year/predictions/',
        },
      },
    );

    this.RangeRule = this.$resource(
      '/api/core/range-rules/:id/',
      { id: '@id' },
      {
        getValue: {
          method: 'GET',
          isArray: false,
          params: {
            field: '@field',
            value: '@value',
          },
          url: '/api/core/range-rules/find_value_for_matching_range_rule/?field=:field&value=:value',
        },
      },
    );

    this.TypeOfActivity = this.$resource(
      '/api/core/type-of-activities/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: true },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/type-of-activities/predictions/',
        },
      },
    );

    this.ChecklistPoint = this.$resource(
      '/api/core/checklist-points/:id/',
      { id: '@id' },
      {
        query: { method: 'GET' },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/checklist-points/predictions/',
        },
      },
    );

    this.Season = this.$resource(
      '/api/core/seasons/:id/',
      { id: '@id' },
      {
        query: { method: 'GET' },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/seasons/predictions/',
        },
      },
    );

    this.Comment = this.$resource(
      '/api/core/comments/:id/',
      { id: '@id' },
      {
        query: { method: 'GET' },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/comments/predictions/',
        },
      },
    );

    this.ColumnParams = this.$resource(
      '/api/core/column-params/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: true },
        update: { method: 'PATCH' },
      },
    );

    this.ExportColumnParams = this.$resource(
      '/api/core/export-column-params/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: true },
        update: { method: 'PATCH' },
      },
    );

    this.CustomStatus = this.$resource(
      '/api/core/custom-statuses/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/custom-statuses/predictions/',
        },
      },
    );

    this.CollectionExportColumnParams = this.$resource(
      '/api/core/collection-export-column-params/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: true },
        update: { method: 'PATCH' },
        delete: { method: 'DELETE' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/collection-export-column-params/predictions/',
        },
      },
    );

    this.controlResource = $resource(
      '/api/core/control/:id/',
      { id: '@id' },
      {
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/control/predictions/',
        },
      },
    );

    this.siteSettings = $resource('/api/core/site-settings/');
    this.initContentTypes();
  }
  initContentTypes() {
    return this.ContentType.query({ page_size: 1000 }).$promise.then((data: any) => {
      if (data.count > data.results.length) {
        throw new Error('getModelContentType page size overflow!');
      }
      const result = data.results.reduce(
        (result: any, contentType: any) => {
          result.contentTypes[contentType.app_label + '.' + contentType.model] = contentType;
          result.contentTypes[contentType.model] = contentType;
          result.contentTypeObjects[contentType.id] = {
            ...contentType,
            label: contentType.app_label + '.' + contentType.model,
          };
          return result;
        },
        { contentTypes: {}, contentTypeObjects: {} },
      );
      this.contentTypeObjects = { ...result.contentTypeObjects };
      this.contentTypes = { ...result.contentTypes };
      return result;
    });
  }
  columnParamsModal(tableOptions: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./legacy/components/column-params-modal/column-params-modal.tpl.html?raw'),
      controller: 'ColumnParamsModalController as vm',
      windowClass: 'modal-template modal-template-three-thirds-width',
      size: 'sm',
      appendTo: getModalRoot(),
      resolve: {
        tableOptions: () => tableOptions,
      },
    }).result;
  }

  openFilterConfigModal() {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<filter-config-modal
                    modal-instance="$uibModalInstance"
                ></<filter-config-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      appendTo: getModalRoot(),
      windowClass: 'modal-template modal-template-half-width',
      size: 'sm',
    }).result;
  }

  gtExportModal(filterLevel: string, resourceName: string, extraData: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./legacy/components/gt-export-modal/gt-export-modal.tpl.html?raw'),
      controller: 'GtExportModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      size: 'sm',
      appendTo: getModalRoot(),
      resolve: {
        filterLevel: () => filterLevel,
        resourceName: () => resourceName,
        extraData: () => extraData,
      },
    }).result;
  }

  getModelContentType(model: string, appLabel?: string) {
    if (this.contentTypes) {
      const key = appLabel
        ? appLabel.toLowerCase() + '.' + model.toLowerCase()
        : model.toLowerCase();
      return this.$q.when().then(() => this.contentTypes[key]);
    }
    return this.initContentTypes().then(() => this.getModelContentType(model, appLabel));
  }

  getModelContentTypeId() {
    return this.$http({
      method: 'GET',
      url: '/api/core/content-types/',
      params: { page_size: 1000 },
    }).then((data: any) => data.data);
  }
  getContentType(contentTypeId: string) {
    if (this.contentTypeObjects) {
      return this.$q.when().then(() => this.contentTypeObjects[contentTypeId]);
    }
    return this.initContentTypes().then(() => this.getContentType(contentTypeId));
  }
  confirmDeletionModal(resource: any, objectId: number) {
    return this.$uibModal.open({
      backdrop: 'static',
      appendTo: getModalRoot(),
      template: html`<confirm-deletion-modal
        resource="resource"
        object-id="objectId"
        modal-instance="$uibModalInstance"
      >
      </confirm-deletion-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
          ($scope as any).resource = resource;
          ($scope as any).objectId = objectId;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template modal-template-half-width',
      size: 'sm',
    }).result;
  }

  getExportColumnParams(resource: any, queryParams: QueryParams) {
    let params: object[] = [];
    if (!queryParams.collection) {
      throw new Error('Attempt to get ExportColumnParams w/o arguments');
    }
    return resource.exportColumnNames(queryParams).$promise.then((data: any) => {
      return this.ExportColumnParams.query(queryParams).$promise.then((paramsData: any) => {
        data.results.forEach((columnName: string, colId: number) => {
          const param = paramsData
            .filter((item: any) => item.column_name == columnName)
            .shift() || {
            column_name: columnName,
            collection: queryParams.collection,
            new_name: '',
            visible: paramsData.length == 0,
            index: colId + 1,
          };
          params.push(param);
        });

        params = params.sort((a: any, b: any) => a.index - b.index);
        return params;
      });
    });
  }

  getPagesConfig() {
    return [
      {
        title: this.gettext('Settings'),
        permissions: [],
        state: 'settings.lists',
        icon: 'fa-cog',
        tabs: [
          {
            title: this.gettext('Lists'),
            state: 'settings.lists',
            icon: 'fa-list',
          },
          {
            title: this.gettext('Organization'),
            state: 'settings.organization',
            icon: 'fa-building',
          },
          {
            title: this.gettext('Personal'),
            state: 'settings.personal',
            icon: 'fa-user',
          },
          {
            title: this.gettext('Parsing'),
            state: 'settings.parsing',
            icon: 'fa-scissors',
            showConfig: this.$rootScope.user.settings.QUOTE_PARSING_CONFIG,
          },
        ],
      },
    ];
  }

  async getSavedFilterChoices(filterLevel: string) {
    const { data } = await coreQueryparamssetsList({ query: { filter_level: filterLevel } });
    return data.results.map((queryParam) => ({
      id: queryParam.id,
      key: queryParam.id,
      title: queryParam.title,
      index: queryParam.index,
      column_params: queryParam.column_params,
      params: JSON.parse(queryParam.query_params),
    }));
  }

  getSavedReportConfigs(configLevel: any) {
    const savedReportConfigs: any = [];

    this.ColumnsSetConfigs.query({ config_level: configLevel }, (data: any) => {
      data.results.forEach((queryParam: any) => {
        savedReportConfigs.push({
          id: queryParam.id,
          key: queryParam.id,
          title: queryParam.title,
          index: queryParam.index,
          column_params: queryParam.column_params,
          params: JSON.parse(queryParam.query_params),
        });
      });
    });

    return savedReportConfigs;
  }

  getBuildInFilters(filterLevel: string) {
    return this.QueryParamsSet.query({ filter_level: filterLevel }).$promise.then((data: any) => {
      return (data.results.length && JSON.parse(data.results[0].query_params)) || {};
    });
  }

  setLocale(lang: string, dayOfWeek: number) {
    moment.updateLocale(lang, { week: { dow: dayOfWeek || 1, doy: 4 } });

    this.gettextCatalog.setCurrentLanguage(lang);
    this.$http({
      method: 'POST',
      url: '/i18n/setlang/',
      data: `language=${lang}`,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  }

  getSeasonList(params: object) {
    return this.Season.query(params).$promise;
  }

  getDefaultSeasonId() {
    return this.getSeasonList({
      is_default: 1,
      ordering: '-end_date',
      page_size: 1,
    }).then((data: any) => {
      return data.results.shift()?.id;
    });
  }

  getBuList() {
    const filteredBu = this.$rootScope.user.profile?.business_units.filter(
      (bu) => bu.is_custom_view,
    );
    if (filteredBu?.length) {
      return filteredBu.map((bu) => bu.businessunit);
    } else {
      return this.$rootScope.user.profile?.business_units.map((bu) => bu.businessunit);
    }
  }

  getDefaultBuList() {
    const filteredBu = this.$rootScope.user.profile?.business_units.filter((bu) => bu.is_default);
    return filteredBu?.map((bu) => bu.businessunit) ?? [];
  }

  getDefaultBuId(obj: any) {
    const defer = this.$q.defer();
    if (obj.id) {
      defer.resolve(null);
      return defer.promise;
    }
    defer.resolve(this.getDefaultBuList().shift());
    return defer.promise;
  }

  groupBy(list: any, key: string) {
    return list.reduce((accumulator: any, currentValue: any) => {
      const currentVal = currentValue[key];
      const currentList = accumulator[currentVal] || [];
      if (currentVal) {
        currentList.push(currentValue);
        accumulator[currentVal] = currentList;
      }
      return accumulator;
    }, {});
  }

  globalSearch(text: any) {
    return this.$http({
      method: 'GET',
      url: '/api/core/global-search/',
      params: { search: text },
    }).then((data: any) => data.data);
  }

  getRangeRuleValue(field: any, value: any) {
    return this.RangeRule.getValue({ field, value }, (data: any) => data.value).$promise;
  }

  getSiteSettings() {
    return this.siteSettings.get().$promise.then((data: any) => {
      return data.results.length ? data.results.at() : {};
    });
  }

  getFilterConfig(filterLevel: string) {
    return this.PageFilterConfig.query({ filter_level: filterLevel }).$promise.then((data: any) =>
      data.results.length
        ? { ...data.results.at(0), config: JSON.parse(data.results.at(0).config) }
        : {},
    );
  }

  applyFilterConfig(pageFilters: any) {
    const isFilterInConfig = (filters: any, config: any, key: any) =>
      config[key] && Array.isArray(filters[key]);

    const filterModifyByConfig = (filters: any, config: any, key: any) =>
      filters[key]
        .filter((item: any) => config[key]?.[item.argument])
        .map((item: any) => ({
          ...item,

          ...{
            isVisible: config[key][item.argument],
            placeholder: config[key][`${item.argument}Title`] || item.placeholder,
          },
        }));

    const getFilters = (filters: any, config: any) => {
      return Object.keys(filters).reduce((obj, key) => {
        obj = isFilterInConfig(filters, config, key)
          ? { ...obj, [key]: filterModifyByConfig(filters, config, key) }
          : { ...obj, [key]: filters[key] };
        return obj;
      }, {});
    };

    return this.getFilterConfig(pageFilters.filterLevel).then(
      (data: any) => (data.id ? getFilters(pageFilters, data.config) : pageFilters),
      () => pageFilters,
    );
  }
}
