import {
  BaseLogisticRepository,
  LogisticQueryParams,
  SaleLogisticListTotals,
  SaleLogisticRecord,
} from '~/features/execution/logistic/common';
import {
  logisticsLogisticsListBuyerList,
  logisticsLogisticsTotalsSaleRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';

@singleton()
export class SaleLogisticRepository extends BaseLogisticRepository<
  SaleLogisticRecord,
  SaleLogisticListTotals
> {
  queryFn = async (query: LogisticQueryParams) => {
    const { data } = await logisticsLogisticsListBuyerList({ query });
    return { records: data.results, count: data.count };
  };

  getListTotals = async (query: LogisticQueryParams) => {
    const { data } = await logisticsLogisticsTotalsSaleRetrieve({ query });
    return data;
  };
}
