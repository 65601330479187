import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').component('positionsPlansEditContainer', {
    bindings: {
      initQueryParams: '<?',
      saleFilterLevel: '<?',
      purchaseFilterLevel: '<?',
      onUpdate: '&?',
      savePlans: '<?',
    },
    template: require('./positions-plans-edit-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', '$q', 'GtUtils', 'ContractsService', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $q: ng.IQService,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;
    vm.salePlans = [];
    vm.salePlansCount = 0;
    vm.saleTotal = 0;
    vm.purchasePlans = [];
    vm.purchasePlansCount = 0;
    vm.purchaseTotal = 0;
    vm.save = save;
    vm.passport = undefined;
    vm.connectToPassport = connectToPassport;

    vm.$onInit = function () {
      vm.salePlansQueryParams = { ...vm.initQueryParams };
      vm.salePlansFilterLevel = vm.saleFilterLevel || 'positions-plans-edit-container-sales';
      vm.purchasePlansQueryParams = { ...vm.initQueryParams };
      vm.purchasePlansFilterLevel =
        vm.purchaseFilterLevel || 'positions-plans-edit-container-purchases';
      gtFilterService.setQueryParams(vm.salePlansQueryParams, vm.salePlansFilterLevel);
      gtFilterService.setQueryParams(vm.purchasePlansQueryParams, vm.purchasePlansFilterLevel);

      $scope.$on('gt-filter-updated_' + vm.salePlansFilterLevel, function (ev: any, data: any) {
        vm.salePlansQueryParams = data;
        updateSalePlans();
      });
      $scope.$on('gt-filter-updated_' + vm.purchasePlansFilterLevel, function (ev: any, data: any) {
        vm.purchasePlansQueryParams = data;
        updatePurchasePlans();
      });
      $scope.$on('sale-connections-updated', rebuildSalePlans);
      $scope.$on('purchase-connections-updated', rebuildPurchasePlans);
      updatePurchasePlans();
      updateSalePlans().then(function () {
        return ContractsService.Passport.get(
          {
            id: vm.initQueryParams.passport,
          },
          function (data: any) {
            vm.passport = data;
          },
        ).$promise;
      });
    };

    vm.$onChanges = function (changes: any) {
      if (changes.savePlans && vm.savePlans === true) {
        vm.save();
      }
    };

    ////////////////

    function rebuildSalePlans(ev: any, requests: any) {
      const prevCount = vm.salePlans.length;
      vm.salePlans = vm.salePlans.filter(function (plan: any) {
        return plan.id || plan.volume;
      });
      vm.salePlansCount -= prevCount - vm.salePlans.length;
      requests.forEach(function (request: any) {
        const requestConnected = vm.salePlans.filter(function (plan: any) {
          return plan.request == request.id;
        }).length;
        if (!request._toConnect || requestConnected || !commodityConformityCheck(request)) {
          return false;
        }
        vm.salePlans.push({
          passport: vm.salePlansQueryParams.passport,
          request_buyer_name: request.buyer_name,
          volume: request.volume,
          request_object: request,
          request_cargo: request.cargo,
          request_cargo_title: request.crop_title,
          request_parent_cargo: request.parent_cargo_id,
          request_final_volume: request.final_volume,
          request_volume: request.volume,
          request_total_connected: request.total_connected,
          contract_number: request.contract_number,
          request_create_time: request.create_time,
          contract_type: request.contract_type,
          request_price: request.price,
          request_basis_name: request.basis_name,
          request_port_set: request.port_set,
          request_date_of_execution: request.date_of_execution,
          request_end_of_execution: request.end_of_execution,
          request_available_to_connect: request.available_to_connect,
          request: request.id,
          request_currency_symbol: request.currency_symbol,
          _dirty: true,
        });
        vm.salePlansCount++;
      });
      vm.saleTotal = calculateTotal(vm.salePlans);
    }

    function rebuildPurchasePlans(ev: any, requests: any) {
      const prevCount = vm.purchasePlans.length;
      vm.purchasePlans = vm.purchasePlans.filter(function (plan: any) {
        return plan.id || plan.volume;
      });
      vm.purchasePlansCount -= prevCount - vm.purchasePlans.length;
      requests.forEach(function (request: any) {
        const requestConnected = vm.purchasePlans.filter(function (plan: any) {
          return plan.request == request.id;
        }).length;
        if (!request._toConnect || requestConnected || !commodityConformityCheck(request)) {
          return false;
        }
        vm.purchasePlans.push({
          passport: vm.purchasePlansQueryParams.passport,
          request_supplier_name: request.supplier_name,
          volume: request.volume,
          request_object: request,
          request_cargo: request.cargo,
          request_cargo_title: request.crop_title,
          request_parent_cargo: request.parent_cargo_id,
          request_final_volume: request.final_volume,
          request_volume: request.volume,
          request_total_connected: request.total_connected,
          contract_number: request.contract_number,
          request_create_time: request.create_time,
          contract_type: request.contract_type,
          request_price: request.price,
          request_basis_name: request.basis_name,
          request_port_set: request.port_set,
          request_date_of_execution: request.date_of_execution,
          request_end_of_execution: request.end_of_execution,
          request_available_to_connect: request.available_to_connect,
          request: request.id,
          request_currency_symbol: request.currency_symbol,
          _dirty: true,
        });
        vm.purchasePlansCount++;
      });
      vm.purchaseTotal = calculateTotal(vm.purchasePlans);
    }

    function updateSalePlans() {
      return ContractsService.getPassportSalePlan(vm.salePlansQueryParams).then((data: any) => {
        vm.salePlans = data.results;
        vm.salePlansCount = data.count;
        vm.saleTotal = calculateTotal(data.results);
      });
    }

    function updatePurchasePlans() {
      return ContractsService.getPassportPurchasePlan(vm.purchasePlansQueryParams).then(
        (data: any) => {
          vm.purchasePlans = data.results;
          vm.purchasePlansCount = data.count;
          vm.purchaseTotal = calculateTotal(data.results);
        },
      );
    }

    function calculateTotal(plans: any) {
      return plans.reduce((total: any, plan: any) => {
        return total + plan.volume;
      }, 0);
    }

    function commodityConformityCheck(request: any) {
      const passportPlans = request.contract_type === 'sale' ? vm.purchasePlans : vm.salePlans;
      if (passportPlans.length === 0) {
        return true;
      }
      if (passportPlans.map((plan: any) => plan.request_cargo).includes(request.cargo)) {
        return request._toConnect;
      } else {
        request._toConnect = confirm(
          `The commodity of the ${GtUtils.capitalize(
            request.contract_type,
          )} request is different from the commodity of the ${GtUtils.capitalize(
            passportPlans[0].contract_type,
          )} request. Are you sure you want to continue?`,
        );
        return request._toConnect;
      }
    }

    function save() {
      let chain = Promise.resolve();
      vm.purchasePlans.concat(vm.salePlans).forEach((plan: any) => {
        if (plan._error) {
          notify(plan._error, 'error');
        }
      });

      GtUtils.overlay('show');
      vm.salePlans.forEach(function (plan: any) {
        if (!plan._dirty) {
          return;
        }
        chain = chain.then(function () {
          delete plan.request_object;
          if (plan.id && plan.volume) {
            return ContractsService.PassportSalePlan.update(plan).$promise;
          } else if (!plan.volume && plan.id) {
            return ContractsService.PassportSalePlan.delete({ id: plan.id }).$promise;
          } else if (plan.volume) {
            return ContractsService.PassportSalePlan.save(plan).$promise;
          }
        });
      });
      vm.purchasePlans.forEach(function (plan: any) {
        if (!plan._dirty) {
          return;
        }
        chain = chain.then(function () {
          delete plan.request_object;
          if (plan.id && plan.volume) {
            return ContractsService.PassportPurchasePlan.update(plan).$promise;
          } else if (!plan.volume && plan.id) {
            return ContractsService.PassportPurchasePlan.delete({ id: plan.id }).$promise;
          } else if (plan.volume) {
            return ContractsService.PassportPurchasePlan.save(plan).$promise;
          }
        });
      });
      chain.then(
        function () {
          GtUtils.overlay('hide');
          vm.onUpdate({ withPage: true });
        },
        function (data) {
          GtUtils.errorClb(data);
          GtUtils.overlay('hide');
          vm.onUpdate({ withPage: true });
        },
      );
    }
    function connectToPassport(request: any) {
      if (request.requestId === -1) {
        return notify('This is not request.');
      }

      if (!commodityConformityCheck(request)) {
        return false;
      }
      return ContractsService.connectToPassport({
        id: vm.passport.id,
        deal_id: request.requestId,
        stage: 'ticket',
        type: request.contractType,
        volume: request.volume,
      }).then(
        (res: any) => {
          if (res === undefined) {
            return false;
          } else if (request.contractType === 'sale') {
            updateSalePlans();
          } else {
            updatePurchasePlans();
          }
          notify('Passport updated');
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }
  }
})();
