export class PassportPnlService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getPassportPnlTableData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/passport-pnl/',
      params: params,
    }).then((response: any) => response.data);
  }
}
PassportPnlService.$inject = ['$http'];
