import { ContractSerializer } from '~/features/deals/contracts';
import {
  SaleContract,
  contractsSaleContractsCreate,
  contractsSaleContractsDestroy,
  contractsSaleContractsListExecutionList,
  contractsSaleContractsListInfoList,
  contractsSaleContractsPartialUpdate,
  contractsSaleContractsPredictionsRetrieve,
  contractsSaleContractsRetrieve,
  contractsSaleContractsTotalsExecutionRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';
import { EntityOption } from '~/shared/ui/data-types';

import {
  SaleContractPatch,
  SaleContractPost,
  SaleContractRecord,
  SaleContractsQueryParams,
} from '../lib';

export const saleContractsListDatasourceMap = {
  table_info: contractsSaleContractsListInfoList,
  table_execution: contractsSaleContractsListExecutionList,
  table_reconciliation: contractsSaleContractsListExecutionList,
  table_prepayments_accounting: contractsSaleContractsListInfoList,
  default: contractsSaleContractsListInfoList,
};

@singleton()
export class SaleContractsRepository extends EntityRepository<
  SaleContract,
  SaleContractsQueryParams,
  SaleContractRecord,
  SaleContractPost,
  SaleContractPatch
> {
  readonly entityName = 'contracts.SaleContract';

  listDatasource: (typeof saleContractsListDatasourceMap)[keyof typeof saleContractsListDatasourceMap] =
    saleContractsListDatasourceMap.default;

  setDatasources = (dsKey: ContractSerializer) => {
    this.listDatasource =
      saleContractsListDatasourceMap[dsKey] || saleContractsListDatasourceMap.default;
  };

  getFn = async (id: number) => {
    const { data } = await contractsSaleContractsRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: SaleContractsQueryParams) => {
    const { data } = await this.listDatasource({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await contractsSaleContractsPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };

  createFn = async (dto: SaleContractPost) => {
    const res = await contractsSaleContractsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  updateFn = async (entity: SaleContractPatch) => {
    const { data } = await contractsSaleContractsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  deleteFn = async (id: number) => {
    await contractsSaleContractsDestroy({ path: { id } });
  };

  getTotals = async (query: SaleContractsQueryParams) => {
    const { data } = await contractsSaleContractsTotalsExecutionRetrieve({ query });
    return data;
  };
}
