import React from 'react';

import { container } from '~/shared/lib/di';

import { BiDashboardsStore } from '../services/bi-dashboards.store';

export const useBiDashboards = () => {
  const biDashboardsStore = React.useMemo(() => container.resolve(BiDashboardsStore), []);
  return { biDashboardsStore };
};
