import type { RowData } from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';

import { useComponentModel } from '~/shared/lib/components';
import { useObservableEagerState, useObservableState } from '~/shared/lib/state';

import { buildColumnDefs } from './column-builder';
import type { DataParams, DataRecord, DatasetArgs, FieldDef } from '../../lib/types';
import type { DatasetModel } from '../dataset/dataset.model';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    field: FieldDef<DataRecord & TData, DataParams>;
  }
}

export const allRowsSelected = <T extends { id: string }>(
  data: T[],
  selectedRowIds: string[],
  rowSelected?: (ids: string[]) => void,
) => {
  const allSelected = selectedRowIds.length === data.length;
  if (allSelected) {
    rowSelected?.([]);
  } else {
    rowSelected?.(data.map((item) => item.id));
  }
};

export const useTable = <R extends DataRecord, P extends DataParams>(
  selectable: DatasetArgs<R, P>['selectable'],
) => {
  const model = useComponentModel<DatasetModel<R, P>>();

  const combinedRecords = useObservableState(model.combinedRecords$, []);
  const rowsSelected = useObservableEagerState(model.rowsSelected$);
  const fields = useObservableState(model.fields$);

  const columns = React.useMemo(
    () => (fields ? buildColumnDefs({ fields, selectable }) : []),
    [fields, selectable],
  );

  const table = useReactTable({
    data: combinedRecords,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: model.setRowsSelected,
    columnResizeMode: 'onChange' as const,
    columnResizeDirection: 'ltr' as const,
    state: { rowSelection: rowsSelected },
    getRowId: (row) => row.id.toString(),
    defaultColumn: { size: 200, minSize: 24, maxSize: 300 },
  });

  return table;
};
