import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './client-details-item-container.html?raw';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

export const clientDetailsItemContainer = {
  bindings: {
    client: '<',
    filterLevel: '<?',
    updateConfigWithFilterLevel: '&?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'FormFieldParamsService',
    'ClientFormFieldsService',
    'GtUtils',
    'ClientsService',
    'DocumentsService',
    'PageService',
    'gtFilterService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      FormFieldParamsService: FormFieldParamsService;
      ClientFormFieldsService: any;
      GtUtils: GtUtilsService;
      ClientsService: any;
      DocumentsService: any;
      PageService: PageService;
      gtFilterService: GtFilterService;
      gettext: ng.gettext.gettextFunction;

      client: any;
      filterLevel = 'client-list-page';
      updateConfigWithFilterLevel: any;

      activeTabs: any;
      contractVolumesChartConfigs: any;
      contractsByCrops: any;
      contractsConsolidated: any;
      fields: any;
      form: any;
      getIcon: any;
      hideWizard: any;
      isCheckList: any;
      tab = [''];
      tabVisibility: any;
      updating: any;

      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        $stateParams: ng.ui.IStateParamsService,
        FormFieldParamsService: FormFieldParamsService,
        ClientFormFieldsService: any,
        GtUtils: GtUtilsService,
        ClientsService: any,
        DocumentsService: any,
        PageService: PageService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.FormFieldParamsService = FormFieldParamsService;
        this.ClientFormFieldsService = ClientFormFieldsService;
        this.GtUtils = GtUtils;
        this.ClientsService = ClientsService;
        this.DocumentsService = DocumentsService;
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.client = {};
        this.activeTabs = [];

        this.tabVisibility = {};
        this.getIcon = this.GtUtils.getIcon;

        this.contractsConsolidated = {};
        this.contractsByCrops = {};
        this.form = undefined;
        this.fields = [];
        this.updating = true;
        if (this.$rootScope.user.profile?.wizard) {
          this.hideWizard = false;
        } else {
          this.hideWizard = true;
        }

        this.contractVolumesChartConfigs = {};
        this.isCheckList = false;
      }

      $onInit() {
        this.tab = this.$stateParams.tab || ['info', 'banks'];
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
          if (data.tab) {
            this.tab = data.tab;
          }
        });
        if (this.$stateParams.tab) {
          this.gtFilterService.updateQueryParams({ tab: this.$stateParams.tab }, this.filterLevel);
        }
        this.updateData();
      }

      updateData() {
        this.fields = [];
        this.updating = true;
        this.getCheckList();
        return this.ClientsService.getClientDetails(this.client.id)
          .then((data: any) => {
            this.client = data;
            this.client.supplier_id = this.getRole('supplier').id;
            this.client.buyer_id = this.getRole('buyer').id;
            this.client.other_id = this.getRole('other').id;
            this.updating = false;
            this.gtFilterService.updateQueryParams({}, this.filterLevel);
            this.updateConfigWithFilterLevel({
              client: this.client,
              filterLevel: this.filterLevel,
            });
            this.updateFields()
              .then(() => {
                this.PageService.setPageTitle(this.client.name);
                this.updateTabVisibility();
                this.GtUtils.overlay('hide');
                this.updateContractsData();
              })
              .catch(this.GtUtils.errorClb);
            return this.client;
          })
          .catch(() => {
            notify(this.gettext(this.GtUtils.translate("Client doesn't exist")));
            this.GtUtils.overlay('hide');
            this.$state.go('clients.roles');
          });
      }

      getCheckList() {
        this.ClientsService.getClientCheckList(this.client.id).then((data: any) => {
          this.isCheckList = data.results.length > 0;
        });
      }

      changeTab(tab: string) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);

        if (!this.$rootScope.user.profile?.wizard) {
          this.hideWizard = true;
        }
      }

      updateFields() {
        return this.FormFieldParamsService.getFields(this.getFormConfig()).then(
          (fields: any) => (this.fields = fields),
        );
      }

      getFormConfig() {
        return this.ClientFormFieldsService.getFields(this.client);
      }

      hasRoles(roles: any) {
        if (!this.client.role_set) {
          return;
        }
        const roleNames = this.client.role_set.map((r: any) => r.role_name);
        return roleNames.some((i: any) => roles.includes(i));
      }

      getRole(roleName: string) {
        return this.client.role_set.filter((role: any) => role.role_name == roleName).shift() || {};
      }

      updateTabVisibility() {
        const isTrader = this.hasRoles(['buyer', 'supplier']);
        this.tabVisibility = {
          info: true,
          contacts: true,
          banks: true,
          potentials: this.$rootScope.user.settings.SYSTEM_BLOCKS.block_crm_potentials,
          indicators:
            isTrader && this.$rootScope.user.settings.SYSTEM_BLOCKS.block_deals_indicators,
          tickets: isTrader && this.$rootScope.user.settings.SYSTEM_BLOCKS.block_deals_tickets,
          contracts: true,
          persons: this.$rootScope.user.settings.SYSTEM_BLOCKS.block_crm_contacts,
          costs: true,
          documents: this.$rootScope.user.settings.SYSTEM_BLOCKS.block_company_documents,
          finances: true,
          payments: true,
          transport: this.$rootScope.user.settings.SYSTEM_BLOCKS.block_execution_logistics,
          approvals: this.$rootScope.user.settings.SYSTEM_BLOCKS.block_crm_approvals,
          risk: isTrader && this.$rootScope.user.settings.SYSTEM_BLOCKS.block_crm_risk,
          position:
            isTrader && this.$rootScope.user.settings.SYSTEM_BLOCKS.block_report_derivatives,
          derivatives: isTrader && this.$rootScope.user.settings.SYSTEM_BLOCKS.block_crm_risk,
          agreements:
            isTrader && this.$rootScope.user.settings.SYSTEM_BLOCKS.block_deals_agreements,
          tasks: this.$rootScope.user.settings.SYSTEM_BLOCKS.block_crm_updates,
          voyages: this.$rootScope.user.settings.SYSTEM_BLOCKS.block_execution_vessels,
          history: true,
          reconciliation: true,
          customValues: true,
          dashboard: isTrader,
          warehouses: true,
        };
      }

      updateContractsData() {
        this.ClientsService.getContractsConsolidated(this.client.id).then(
          (data: any) => (this.contractsConsolidated = data),
        );

        if (this.hasRoles(['buyer', 'supplier'])) {
          this.ClientsService.getContractsByCrops(this.client.id).then((data: any) => {
            this.contractsByCrops = data;
            this.getContractVolumesChartConfig('buyer');
            this.getContractVolumesChartConfig('supplier');
          });
        }
      }

      getContractVolumesChartConfig(role: any) {
        const chartConfig: any = {
          labels: [],
          data: {
            Volumes: [],
          },
        };
        this.contractsByCrops[role].forEach((i: any) => {
          chartConfig.labels.push(i.crop_title);
          chartConfig.data.Volumes.push(Math.round(i.volume_sum * 100) / 100);
        });
        this.contractVolumesChartConfigs[role] = chartConfig;
      }

      filterLogisticVolumes(volumes: any, role: any) {
        return volumes.filter(
          (volume: any) => volume[role + '_volume_received'] || volume[role + '_volume_departed'],
        );
      }

      openClientModal(tab: string) {
        return this.ClientsService.clientModal(this.client.id, null, tab).then(() =>
          this.updateData(),
        );
      }

      openClientModalQuick(tab: string) {
        return this.ClientsService.clientModalQuick(this.client.id, null, tab).then(() =>
          this.updateData(),
        );
      }

      openEmailModal() {
        return this.ClientsService.sendEmailModal('Client', this.client.id);
      }

      openDocxModal() {
        return this.DocumentsService.generateDocxModal('Client', this.client.id);
      }
    },
  ],
};
