export class OilExtensionService {
  $resource: ng.resource.IResourceService;
  oilExtensionResource: any;
  constructor($resource: ng.resource.IResourceService) {
    this.$resource = $resource;
    this.oilExtensionResource = $resource(
      '/api/contracts/contract-oil-extension/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
      },
    );
  }

  getListData(params: object) {
    return this.oilExtensionResource.query(params).$promise.then((data: any) => {
      return { data: data };
    });
  }

  save(item: any) {
    if (item.id) {
      return this.oilExtensionResource.update(item).$promise;
    } else {
      return this.oilExtensionResource.save(item).$promise;
    }
  }

  delete(item: any) {
    return this.oilExtensionResource.delete(item).$promise;
  }
}
OilExtensionService.$inject = ['$resource'];
