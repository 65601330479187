import ng from 'angular';

import template from './update-button.tpl.html?raw';
import type { GtFilterService } from '../../gt-filter/gt-filter.srv';

import type { HelpButtonsService } from '^/app/core/legacy/help-buttons/help-buttons.srv';

(function () {
  'use strict';
  ng.module('core.legacy').directive('updateButton', directive);

  function directive() {
    return {
      template,
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {},
    };
  }

  Controller.$inject = ['$scope', 'HelpButtonsService', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    HelpButtonsService: HelpButtonsService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;

    vm.updating = false;
    vm.click = click;
    vm.config = HelpButtonsService.getConfig().update;

    activate();

    ////////////////

    function activate() {
      $scope.$on('hb-config-updated', function () {
        vm.config = HelpButtonsService.getConfig().update;
      });
    }

    function click() {
      if (vm.config.filterLevel) {
        return gtFilterService.updateQueryParams({}, vm.config.filterLevel);
      }
      vm.updating = true;
      const promise = vm.config.method();
      if (promise?.then) {
        promise.then(function () {
          vm.updating = false;
        });
      } else {
        vm.updating = false;
      }
    }
  }
})();
