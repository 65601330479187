import {
  ApplyVatParams,
  Finance,
  FinanceListParams,
  FinancePatch,
  FinancePost,
  FinanceRecord,
} from '~/features/finances/finances';
import {
  coreApplyVatRetrieve,
  financesFinancesCreate,
  financesFinancesDestroy,
  financesFinancesListInfoList,
  financesFinancesPartialUpdate,
  financesFinancesPredictionsRetrieve,
  financesFinancesRetrieve,
  financesFinancesTotalsInfoRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';

@singleton()
export class FinanceRepository extends EntityRepository<
  Finance,
  FinanceListParams,
  FinanceRecord,
  FinancePost,
  FinancePatch
> {
  readonly entityName = 'finances.Finance';

  getFn = async (id: number) => {
    const { data } = await financesFinancesRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: FinanceListParams) => {
    const { data } = await financesFinancesListInfoList({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (input: string) => {
    const { data } = await financesFinancesPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };

  createFn = async (entity: FinancePost) => {
    const res = await financesFinancesCreate({ body: entity });

    return res.data;
  };

  updateFn = async (entity: FinancePatch) => {
    const { data } = await financesFinancesPartialUpdate({ body: entity, path: { id: entity.id } });
    return data;
  };

  deleteFn = async (id: number) => {
    await financesFinancesDestroy({ path: { id } });
  };

  applyVat = async (params: ApplyVatParams) => {
    const { data } = await coreApplyVatRetrieve({ query: params });
    return data.value;
  };

  getFinanceListTotals = async (query: FinanceListParams) => {
    const { data } = await financesFinancesTotalsInfoRetrieve({ query });
    return data;
  };

  buildNewRecord = (): FinanceRecord =>
    ({
      id: -Date.now(),
      number: '',
      invoicepositions_set: [],
      status: 'new',
      date: new Date().toISOString(),
      date_of_execution: new Date().toISOString(),
      assignment: 'commercial',
      invoice_type: 'incoming',
    }) as unknown as FinanceRecord;
}
