import { locationRegionsPredictionsRetrieve } from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository, Paginated } from '~/shared/lib/entities';

import { Region, RegionPatch, RegionPost, RegionQueryParams } from '../lib';

@singleton()
export class RegionRepository extends EntityRepository<
  Region,
  RegionQueryParams,
  Region,
  RegionPost,
  RegionPatch
> {
  readonly entityName = 'location.Region';

  getFn = (_id: number): Promise<Region> => {
    throw new Error('method not implemented');
  };
  queryFn = (_params: RegionQueryParams): Promise<Paginated<Region>> => {
    throw new Error('method not implemented');
  };
  searchFn = async (search: string, _id?: number) => {
    const { data } = await locationRegionsPredictionsRetrieve({
      query: { search },
    });
    return data.results;
  };

  createFn = (_entity: RegionPost): Promise<Region> => {
    throw new Error('method not implemented');
  };
  updateFn = (_entity: RegionPatch): Promise<Region> => {
    throw new Error('method not implemented');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('method not implemented');
  };
}
