import type ng from 'angular';
import type { Subscription } from 'rxjs';

import { CertsLogisticListModel } from '~/features/execution/logistic/certs/components/certs-logistic-list.model';
import type { BaseLogisticListModel } from '~/features/execution/logistic/common/components/logistic-container/base-logistic-list.model';
import type {
  BaseLogisticListTotals,
  BaseLogisticRecord,
  ConnectionMode,
  InvoicePurpose,
  InvoicingVolume,
  LogisticPatch,
  LogisticPost,
  LogisticTable,
} from '~/features/execution/logistic/common/lib';
import { CostsLogisticListModel } from '~/features/execution/logistic/costs/components/costs-logistic-list.model';
import { ExportLogisticListModel } from '~/features/execution/logistic/export/components/export-logistic-list.model';
import { InfoLogisticListModel } from '~/features/execution/logistic/info/components/info-logistic-list.model';
import { PnlLogisticListModel } from '~/features/execution/logistic/pnl/components/pnl-logistic-list.model';
import { PurchaseLogisticListModel } from '~/features/execution/logistic/purchase/components/purchase-logistic-list.model';
import { QualitiesLogisticListModel } from '~/features/execution/logistic/qualities/components/qualities-logistic-list.model';
import { SaleLogisticListModel } from '~/features/execution/logistic/sale/components/sale-logistic-list.model';
import { container } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifyError } from '~/shared/lib/notify';

import template from './logistics-container.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { DisbursementBlService } from '^/app/execution/components/transport/disbursementbls/disbursementbls.service';
import type { LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

type LogisticsQueryParams = QueryParams & {
  serializer: LogisticTable;
};
type VolumeChoices =
  | 'volume_received'
  | 'volume_departed'
  | 'volume_departed_consignment'
  | 'volume_departed_real'
  | 'volume_boarded';

type ViewMode = 'table' | 'spreadsheet' | 'grid';

class LogisticsContainerController implements ng.IController {
  viewModel;

  editing = false;
  pageData: { count: number; records: BaseLogisticRecord[] } = { records: [], count: 0 };
  totals: {
    totals: BaseLogisticListTotals;
    mainTotals: BaseLogisticListTotals;
    intermediateTotals: BaseLogisticListTotals;
  } = {
    totals: {} as BaseLogisticListTotals,
    mainTotals: {} as BaseLogisticListTotals,
    intermediateTotals: {} as BaseLogisticListTotals,
  };
  selectAllChecked = false;

  logistics: BaseLogisticRecord[] = [];
  logisticsCount = 0;
  logisticsIntermediateTotals: BaseLogisticListTotals = {} as BaseLogisticListTotals;
  logisticsMainTotals: BaseLogisticListTotals = {} as BaseLogisticListTotals;
  logisticsTotal: BaseLogisticListTotals = {} as BaseLogisticListTotals;
  selectedLogistics: BaseLogisticRecord[] = [];
  selectedTotals: BaseLogisticListTotals = {} as BaseLogisticListTotals;
  invoicePurpose?: InvoicePurpose;
  selectableItems: number[] = [];

  connectionMode?: ConnectionMode;
  contractType?: 'sale' | 'purchase';

  subs: Record<string, Subscription> = {};

  loading = false;
  filterLevel = 'logistics-container';
  tableName: LogisticTable = 'table_info';
  initQueryParams: Partial<LogisticsQueryParams> = {};
  showAllOnInvoicing = false;
  sertDocument?: object;
  canConnectToDbl = false;
  onConnectionToDbl?: (_p: { logisticIds: number[]; logistic_type: string }) => Promise<void>;
  newLogisticInitData?: LogisticPost;
  readonly = false;
  logisticsExportConfig:
    | 'logistics-export-config-table_qualities'
    | 'logistics-export-config-table_info';
  newLogistic?: LogisticPost;
  reportConfig?: object[];
  logisticsQueryParams: LogisticsQueryParams = { serializer: 'table_info' };
  queryParams: LogisticsQueryParams = { serializer: 'table_info' };
  view: ViewMode = 'table';
  activeTab = 'table_info';
  showTotals: boolean;
  contract?: number;
  certificateTabTitles;
  volumeChoices;
  logisticVolume: VolumeChoices = 'volume_received';

  static readonly $inject = [
    '$scope',
    '$rootScope',
    'gettext',
    'LogisticsService',
    'gtFilterService',
    'GtUtils',
    'AccountsService',
    'DisbursementBlService',
    'FinancesService',
  ];
  constructor(
    private readonly $scope: ng.IScope,
    private readonly $rootScope: GtRootScopeService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly LogisticsService: LogisticsService,
    private readonly gtFilterService: GtFilterService,
    private readonly GtUtils: GtUtilsService,
    private readonly AccountsService: AccountsService,
    private readonly DisbursementBlService: DisbursementBlService,
    private readonly FinancesService: FinancesService,
  ) {
    this.logisticsExportConfig = this.AccountsService.user?.settings.DEFAULT_LOGISTICS_WITH_QUALITY
      ? 'logistics-export-config-table_qualities'
      : 'logistics-export-config-table_info';
    this.showTotals = this.AccountsService.user?.settings.SHOW_TABLES_TOTAL ?? false;
    this.viewModel = this.resolveViewModel(this.tableName);
    this.certificateTabTitles = [
      { columnName: 'railway', title: this.gettext('Railway') },
      { columnName: 'vet', title: this.gettext('Vet') },
      { columnName: 'quarantine', title: this.gettext('Quarantine') },
      { columnName: 'quality', title: this.gettext('Quality') },
      { columnName: 'declaration', title: this.gettext('Declaration') },
      { columnName: 'eur_one', title: this.gettext('Eur one') },
      { columnName: 'broker_confirmation', title: this.gettext('Broker confirmation') },
      {
        columnName: 'additional_document_one',
        title: this.gettext('additional document one'),
      },
      {
        columnName: 'additional_document_two',
        title: this.gettext('additional document two'),
      },
      {
        columnName: 'additional_document_three',
        title: this.gettext('additional document three'),
      },
    ];
    this.volumeChoices = [
      { key: 'volume_received', title: this.gettext('Volume received') },
      { key: 'volume_departed', title: this.gettext('Volume departed') },
      {
        key: 'volume_departed_consignment',
        title: this.gettext('Volume departed consignment'),
      },
      { key: 'volume_departed_real', title: this.gettext('Volume departed real') },
      { key: 'volume_boarded', title: this.gettext('Volume boarded') },
    ];
    this.logisticVolume = 'volume_received';
  }

  resolveViewModel = (table: LogisticTable) => {
    if (table == 'table_supplier') {
      return container.resolve(PurchaseLogisticListModel);
    }
    if (table == 'table_buyer') {
      return container.resolve(SaleLogisticListModel);
    }
    if (table == 'table_costs') {
      return container.resolve(CostsLogisticListModel);
    }
    if (table == 'table_certs') {
      return container.resolve(CertsLogisticListModel);
    }
    if (table == 'table_export') {
      return container.resolve(ExportLogisticListModel);
    }
    if (table == 'table_qualities') {
      return container.resolve(QualitiesLogisticListModel);
    }
    if (table == 'table_pnl') {
      return container.resolve(PnlLogisticListModel);
    }
    return container.resolve(InfoLogisticListModel);
  };

  $onInit() {
    this.queryParams = {
      serializer: this.AccountsService.user?.settings.DEFAULT_LOGISTICS_WITH_QUALITY
        ? 'table_qualities'
        : 'table_info',
      page_size: 25,
      ...this.initQueryParams,
    };
    this.contract = (this.initQueryParams.buyer_contract ??
      this.initQueryParams.supplier_contract ??
      this.initQueryParams.buyer_multicontract ??
      this.initQueryParams.supplier_multicontract) as number;
    if (this.newLogisticInitData) {
      this.newLogistic = { ...this.newLogisticInitData };
    }

    this.gtFilterService.subscribe(this.filterLevel, this.queryParamsChanged, this.queryParams);
    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
    this.GtUtils.overlay('hide');

    /** @deprecated: use callbacl subscription after refactoring core-inline-report-config-view */
    this.$rootScope.$on(
      `gt-report-config-selected_${this.filterLevel}`,
      (_ev, config?: { column_params: object[] }) => {
        this.reportConfig = config ? config.column_params : undefined;
      },
    );

    this.$rootScope.$on('invoicingVolume-changed', (_ev, invoicingVolume: InvoicingVolume) => {
      this.viewModel.invoicingVolumeChanged(invoicingVolume);
    });
  }

  $onDestroy() {
    this.gtFilterService.unsubscribe(this.filterLevel, this.queryParamsChanged);
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());
  }
  changeTable = (table: LogisticTable) => {
    this.viewModel = this.resolveViewModel(table);
    this.activeTab = table;
    this.subs.pageData = (this.viewModel as BaseLogisticListModel).pageData$.subscribe((data) => {
      this.pageData = data;
      this.logisticUpdated();
      this.$scope.$applyAsync();
    });
    this.subs.totals = this.viewModel.totals$.subscribe((totals) => {
      this.totals = totals;
      this.logisticUpdated();
      this.$scope.$applyAsync();
    });
    this.subs.selectedTotals = this.viewModel.selectedItemsTotals$.subscribe((totals) => {
      this.selectedTotals = totals;
      this.$scope.$applyAsync();
    });
    this.subs.loading = this.viewModel.loading$.subscribe((loading) => {
      if (loading != this.loading || !loading) {
        this.loading = loading;
        this.GtUtils.overlay(loading ? 'show' : 'hide');
        this.$scope.$applyAsync();
      }
    });
    this.subs.invoicePurpose = this.viewModel.invoicePurpose$.subscribe((purpose) => {
      this.invoicePurpose = purpose;
      this.$scope.$applyAsync();
    });
    this.subs.selectableItems = this.viewModel.selectableItems$.subscribe((selectable) => {
      this.selectableItems = selectable;
      this.$scope.$applyAsync();
    });
    this.subs.selectedItems = this.viewModel.selectedItems$.subscribe((selected) => {
      this.selectedLogistics = this.logistics.filter((l) => selected.includes(l.id));
      this.$scope.$applyAsync();
    });
    this.subs.connectionMode = this.viewModel.connectionMode$.subscribe((mode) => {
      this.connectionMode = mode;
      this.$scope.$applyAsync();
    });
    this.subs.contractType = this.viewModel.invoicingContractType$.subscribe((contractType) => {
      this.contractType = contractType;
      this.$scope.$applyAsync();
    });
    this.subs.editing = this.viewModel.editingMode$.subscribe((editing) => {
      this.editing = editing;
      this.$scope.$applyAsync();
    });
    this.viewModel.pageParamsUpdated({
      page: this.queryParams.page as number,
      page_size: this.queryParams.page_size as number,
      ...this.queryParams,
    });
  };

  queryParamsChanged = (params: LogisticsQueryParams) => {
    this.queryParams = params;
    this.changeTable(this.queryParams.serializer);
  };

  setEditing(editing: boolean) {
    this.viewModel.setEditing(editing);
  }

  changeView = (view: ViewMode) => {
    this.view = view;
    this.$scope.$applyAsync();
  };

  connectionModeChanged(editMode: ConnectionMode) {
    this.viewModel.connectionModeChanged(editMode);
  }

  toggleLogisticSelection(id: number) {
    this.viewModel.toggleItemSelection(id);
  }

  selectAll(checked: boolean) {
    this.viewModel.selectedItemsChanged(checked ? this.selectableItems : []);
  }

  resetEditMode(updated: boolean) {
    this.viewModel.resetConnectionModeAndInvoicing(updated);
  }

  startInvoicing(purpose: InvoicePurpose) {
    this.viewModel.invoicePurposeChanged(purpose);
  }

  unselectAll() {
    this.selectAll(false);
  }

  connectToDbl() {
    const selectedLogisticsIds = this.selectedLogistics.map((l) => l.id);
    if (!selectedLogisticsIds.length) {
      notifyError(this.gettext('No logistics selected'));
    }
    if (this.onConnectionToDbl) {
      this.onConnectionToDbl({ logisticIds: selectedLogisticsIds, logistic_type: 'Logistic' })
        .then(() => this.resetEditMode(true))
        .catch(errorHandler);
    }
  }

  disconnectFromDbl() {
    if (!this.selectedLogistics.length) {
      notifyError(this.gettext('No logistics selected'));
      return;
    }

    if (this.selectedLogistics.some((logistic) => !('disbursementbl_id' in logistic))) {
      throw new Error(this.gettext('Wrong table selected'));
    }

    Promise.all(
      this.selectedLogistics.map((logistic) =>
        this.viewModel.updateLogistic({ ...logistic, disbursementbl: null }),
      ),
    ).catch(errorHandler);

    this.resetEditMode(true);
  }

  async createDbl() {
    if (!this.selectedLogistics.length) {
      notifyError('No logistics selected');
      return;
    }

    if (!this.contract) {
      notifyError('Contract is not set.');
      return;
    }

    const dbl = await this.DisbursementBlService.createDisbursementBLFromLogistics(
      this.selectedLogistics,
      this.logisticVolume,
      this.contract,
    );

    if (!dbl) {
      return;
    }

    return this.FinancesService.disbursementblModal(dbl)
      .then((dbl) => {
        Promise.all(
          this.selectedLogistics.map((logistic) =>
            this.viewModel.updateLogistic({ ...logistic, disbursementbl: dbl.id }),
          ),
        ).catch(errorHandler);
        this.resetEditMode(true);
      })
      .catch(errorHandler);
  }

  logisticUpdated() {
    if (this.queryParams.next) {
      this.logistics = this.logistics.concat(this.pageData.records);
      delete this.queryParams.next;
    } else {
      this.logistics = this.pageData.records;
    }
    this.logisticsCount = this.pageData.count;
    this.logisticsTotal = this.totals.totals;
    this.logisticsIntermediateTotals = this.totals.intermediateTotals;
    this.logisticsMainTotals = this.totals.mainTotals;
    this.updateLogisticExportConfig();
  }

  async applyQualityDiscount() {
    if (!confirm(this.gettext('Do you want to update logistics by discount?'))) {
      return Promise.resolve(false);
    }
    await this.viewModel.applyDiscount();
  }

  async applyCheckLimit() {
    if (!confirm(this.gettext('Do you want to update status of logistics with limits?'))) {
      return Promise.resolve(false);
    }
    await this.viewModel.updateApprovalStatus();
  }

  async setPurchasePrice() {
    if (
      !confirm(this.gettext('Do you want to update purchase price of logistics from indicator?'))
    ) {
      return Promise.resolve(false);
    }
    await this.viewModel.recalculatePurchasePrice();
  }

  async updateLogistic(logistic: LogisticPatch) {
    await this.viewModel.updateLogistic(logistic);
  }

  updateLogisticExportConfig() {
    this.logisticsQueryParams = { ...this.queryParams };
    delete this.logisticsQueryParams.page_size;
    this.gtFilterService.setQueryParams(this.logisticsQueryParams, this.logisticsExportConfig);
  }

  openLogisticsDocumentModal() {
    return this.LogisticsService.logisticsDocumentsModal(this.queryParams);
  }

  openLogisticModal() {
    return this.LogisticsService.logisticModal(this.newLogistic).then(() => {
      this.changeTable(this.queryParams.serializer);
    });
  }

  applyFilters(params: LogisticsQueryParams) {
    this.gtFilterService.updateQueryParams(params, this.filterLevel);
  }
}

export const logisticsContainer: ng.IComponentOptions = {
  bindings: {
    filterLevel: '<?',
    tableName: '<?',
    initQueryParams: '<?',
    showAllOnInvoicing: '<?',
    sertDocument: '<?',
    canConnectToDbl: '<?',
    onConnectionToDbl: '&?',
    newLogisticInitData: '<?',
    readonly: '<?',
  },
  template,
  controller: LogisticsContainerController,
};
