import ng from 'angular';

import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.requests.legacy').directive('requestsList', directive);

  function directive() {
    return {
      template: require('./requests-list.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        requests: '<',
        count: '<',
        total: '<?',
        filterLevel: '<?',
        onUpdate: '&?',
        mode: '@?', // template modificator,
        approve: '&',
        createRequest: '&',
        openRequestModal: '&?',
        openRequestModalQuick: '&?',
      },
    };
  }

  Controller.$inject = ['$scope', 'ContractsService', 'ClientsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    ContractsService: ContractsService,
    ClientsService: any,
  ) {
    const vm = this;

    vm.queryParams = { serializer: 'table_info' };
    vm.setHovering = setHovering;
    vm.finalApprove = finalApprove;
    vm.hovering = false;
    vm.clone = clone;
    vm.openEmailModal = openEmailModal;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'requests-table';
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
    };

    ////////////////

    function finalApprove(request: any) {
      return vm.approve({ request: request });
    }
    function openEmailModal(request: any) {
      return ClientsService.sendEmailModal('Request', { request: request });
    }

    function clone(request: any) {
      return ContractsService.cloneRequest(request).then(vm.onUpdate);
    }

    function setHovering(value: any) {
      vm.hovering = value;
    }
  }
})();
