export class CalendarService {
  CalendarResource: any;
  constructor($resource: ng.resource.IResourceService) {
    this.CalendarResource = $resource(
      '/api/clients/calendar/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
      },
    );
  }

  getEvents(params: object) {
    return this.CalendarResource.query(params).$promise;
  }
}
CalendarService.$inject = ['$resource'];
