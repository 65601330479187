import type ng from 'angular';

import template from './contract-pnl-mtm-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const ContractPnlMtmListContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    extraFields: '<?',
  },
  template,
  controller: [
    '$scope',
    '$window',
    'GtUtils',
    'gettext',
    'gtFilterService',
    '$httpParamSerializer',
    'ContractPnlMtmService',
    class {
      $httpParamSerializer: any;
      $scope: ng.IScope;
      $window: ng.IWindowService;
      ContractPnlMtmService: any;
      GtUtils: GtUtilsService;
      contracts: any;
      contractsCount: number;
      contractsTotal: any;
      filterLevel = 'contract-pnl-mtm-list-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams & { id_list?: number[] } = {};
      constructor(
        $scope: ng.IScope,
        $window: ng.IWindowService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
        $httpParamSerializer: any,
        ContractPnlMtmService: any,
      ) {
        this.$scope = $scope;
        this.$window = $window;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.$httpParamSerializer = $httpParamSerializer;
        this.ContractPnlMtmService = ContractPnlMtmService;

        this.contracts = [];
        this.contractsTotal = {};
        this.contractsCount = 0;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'contract-pnl-mtm-list-container';
        this.$scope.$on(
          'gt-filter-updated_' + this.filterLevel,
          (ev, data: QueryParams & { id_list?: number[] }) => {
            this.queryParams = data;
            this.updateData();
          },
        );
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      $onChanges(changes: any) {
        if (changes.initQueryParams) {
          Object.assign(this.queryParams, this.initQueryParams);
          this.updateData();
        }
      }

      applyFilters(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      downloadXlsxReport() {
        this.$window.open(
          `/api/contracts/contract-pnl-mtm/list/?${this.$httpParamSerializer({
            ...this.queryParams,
            as_xlsx: true,
            page_size: 999,
          })}`,
          '_blank',
        );
      }

      updateData() {
        if (!this.queryParams.id_list?.length) {
          this.contracts = [];
          this.contractsCount = 0;
          this.contractsTotal = {};
          return;
        }
        this.GtUtils.overlay('show');
        return this.ContractPnlMtmService.getTableData({
          id_list: this.queryParams.id_list,
          page: this.queryParams.page,
          page_size: this.queryParams.page_size,
        }).then((data: any) => {
          this.contracts = data.results;
          this.contractsCount = data.count;
          this.contractsTotal = data.total;
          this.GtUtils.overlay('hide');
        });
      }
    },
  ],
};
