import {
  ContractSerializer,
  ServicesContractPatch,
  ServicesContractPost,
  ServicesContractQueryParams,
  ServicesContractRecord,
} from '~/features/deals/contracts';
import {
  ServicesContract,
  contractsServicesContractsCreate,
  contractsServicesContractsDestroy,
  contractsServicesContractsListExecutionList,
  contractsServicesContractsListInfoList,
  contractsServicesContractsPartialUpdate,
  contractsServicesContractsPredictionsRetrieve,
  contractsServicesContractsRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';
import { EntityOption } from '~/shared/ui/data-types';

export const servicesContractsListDatasourceMap = {
  table_info: contractsServicesContractsListInfoList,
  table_execution: contractsServicesContractsListExecutionList,
  table_reconciliation: contractsServicesContractsListExecutionList,
  table_prepayments_accounting: contractsServicesContractsListInfoList,
  default: contractsServicesContractsListInfoList,
};

@singleton()
export class ServicesContractsRepository extends EntityRepository<
  ServicesContract,
  ServicesContractQueryParams,
  ServicesContractRecord,
  ServicesContractPost,
  ServicesContractPatch
> {
  readonly entityName = 'contracts.ServiceContract';
  listDatasource: (typeof servicesContractsListDatasourceMap)[keyof typeof servicesContractsListDatasourceMap] =
    servicesContractsListDatasourceMap.default;

  setDatasources(dsKey: ContractSerializer) {
    this.listDatasource =
      servicesContractsListDatasourceMap[dsKey] || servicesContractsListDatasourceMap.default;
  }

  getFn = async (id: number) => {
    const { data } = await contractsServicesContractsRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: ServicesContractQueryParams) => {
    const { data } = await this.listDatasource({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await contractsServicesContractsPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };

  createFn = async (dto: ServicesContractPost) => {
    const res = await contractsServicesContractsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  updateFn = async (entity: ServicesContractPatch) => {
    const { data } = await contractsServicesContractsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  deleteFn = async (id: number) => {
    await contractsServicesContractsDestroy({ path: { id } });
  };

  getTotals = async (_query: ServicesContractQueryParams) => {
    return Promise.resolve({});
  };
}
