import React from 'react';

import { FinancePositionListContainer } from '~/features/finances/finance-position/components/finance-position-container/finance-position-list-container';
import type { FinanceRecord } from '~/features/finances/finances/lib';
import { ChevronDownIcon } from '~/shared/ui/icons';
import { Dropdown } from '~/shared/ui/kit/dropdown-menu';
import { Tag } from '~/shared/ui/kit/tag';

export const FinancePositionTemplate: React.FC<{
  value: FinanceRecord;
  title: React.ComponentProps<typeof Dropdown>['title'];
  count?: number;
}> = ({ value, title, count = 0 }) =>
  value.id > 0 ? (
    <div className="pl-2">
      <Dropdown
        title={
          <span className="relative inline-block w-[115px] truncate pr-6">
            <span>{title}</span>
            <span className="bg-tags-background-gray text-tags-text-gray absolute top-1/2 right-1 -translate-y-1/2 rounded-md p-0.5">
              +{count}
            </span>
          </span>
        }
        trigger={
          <ChevronDownIcon className="transition duration-200 ease-in-out hover:opacity-50" />
        }
        items={[
          {
            value: '0',
            label: (
              /* TO DO fix this issue */
              <span onClick={(e) => e.stopPropagation()}>
                <FinancePositionListContainer
                  viewMode="grid"
                  initPageParams={{ invoice: String(value.id) }}
                  containerId="finance-position-template"
                />
              </span>
            ),
          },
        ]}
      />
    </div>
  ) : (
    <Tag variant="red">save for editing</Tag>
  );
