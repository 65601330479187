import { genId } from '~/shared/lib/utils';

import type { Preset } from './types';

export const emptyPreset: Preset = {
  id: -genId(),
  datasetId: '',
  title: 'Preset',
  icon: 'HeartIcon',
  filters: [],
};
