import type ng from 'angular';

import { notifyInfo } from '~/shared/lib/notify';

import type { QueryParams } from '^/app/core/types';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class NotificationService {
  $http: ng.IHttpService;
  $uibModal: ng.ui.bootstrap.IModalService;
  EventSubscriptionResource: any;
  EventSubscriptionTemplateResource: any;
  NotificationResource: any;
  TelegramChatResource: any;
  gettext: ng.gettext.gettextFunction;
  messagesCount: number;
  notification: any;
  notificationData: any;
  constructor(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    $http: ng.IHttpService,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.gettext = gettext;

    this.notificationData = undefined;
    this.messagesCount = 0;
    this.NotificationResource = $resource(
      '/api/notifications/notifications/:model/:id/',
      {
        model: '@model',
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        readAll: {
          method: 'GET',
          isArray: false,
          url: '/api/notifications/notifications/read_all/',
        },
        unreadCount: {
          method: 'GET',
          isArray: false,
          url: '/api/notifications/notifications/unread_count/',
        },
      },
    );
    this.EventSubscriptionResource = $resource(
      '/api/notifications/event-subscriptions/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        system_events: {
          method: 'GET',
          url: '/api/notifications/event-subscriptions/system_events/',
        },
      },
    );
    this.EventSubscriptionTemplateResource = $resource(
      '/api/notifications/event-subscription-templates/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/notifications/event-subscription-templates/predictions/',
        },
      },
    );
    this.TelegramChatResource = $resource(
      '/api/notifications/telegram-chats/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/notifications/telegram-chats/predictions/',
        },
      },
    );
  }

  getData(queryParams: QueryParams) {
    return this.NotificationResource.query(queryParams).$promise;
  }

  getUnreadCount() {
    return this.NotificationResource.unreadCount().$promise;
  }

  update(notification: any) {
    return this.NotificationResource.update(notification).$promise;
  }

  readAll() {
    return this.NotificationResource.readAll().$promise;
  }

  showLastMessages(count: number) {
    this.getData({ page_size: count }).then((data: any) => {
      data.results.map((notify: any) => notifyInfo(notify.verb));
    });
  }

  getPageConfig() {
    return [
      {
        title: this.gettext('Notifications'),
        permissions: [],
        state: 'settings.notifications',
        icon: 'fa-bell',
      },
      {
        title: this.gettext('Template playground'),
        permissions: [],
        state: 'settings.templatePlayground',
        icon: 'fa-gamepad',
      },
    ];
  }

  getEventSubscriptionList(params: object) {
    return this.EventSubscriptionResource.query(params).$promise;
  }

  getEventSubscription(id: number) {
    return this.EventSubscriptionResource.get({ id: id }).$promise;
  }

  getSystemEvents() {
    return this.EventSubscriptionResource.system_events().$promise;
  }

  deleteEventSubscription(id: number) {
    return this.EventSubscriptionResource.delete({ id: id }).$promise;
  }

  saveEventSubscription(eventSubscription: any) {
    if (eventSubscription.id) {
      return this.EventSubscriptionResource.update(eventSubscription).$promise;
    }
    return this.EventSubscriptionResource.save(eventSubscription).$promise;
  }

  openEventSubscriptionModal(eventSubscription: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<event-subscription-modal
        event-subscription="eventSubscription"
        modal-instance="$uibModalInstance"
      ></event-subscription-modal>`,
      controller: [
        '$scope',
        'eventSubscription',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          eventSubscription: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).eventSubscription = eventSubscription;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        eventSubscription: () => eventSubscription,
      },
    }).result;
  }

  checkTemplate(modelName: string, objId: number, template: any) {
    return this.$http({
      method: 'POST',
      url: '/api/notifications/check-template/',
      data: { model_name: modelName, obj_id: objId, template },
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  }

  getTemplateExt(modelName: string, objId: number) {
    return this.$http({
      method: 'GET',
      url: '/api/notifications/get-template-ext/',
      params: { model_name: modelName, obj_id: objId },
    });
  }

  saveScreenCaptureTask(task: any) {
    return this.$http({
      method: 'POST',
      url: '/api/screenshots/',
      data: task,
    }).then((response: any) => response.data);
  }

  getUserEmails() {
    return this.$http({
      method: 'GET',
      url: '/api/screenshots/emails/',
    }).then((response: any) => response.data);
  }

  doScreenCaptureTask(taskId: number) {
    return this.$http({
      method: 'POST',
      url: `/api/screenshots/${taskId}/send_screenshot/`,
    });
  }

  openScreenCaptureTaskModal(currentPage: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<screen-capture-task-modal
                    modal-instance="$uibModalInstance"
                    current-page=currentPage
                ></<screen-capture-task-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
          ($scope as any).$uibModalInstance = $uibModalInstance;
          ($scope as any).currentPage = currentPage;
        },
      ],
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: { currentPage: () => currentPage },
    }).result;
  }
}

NotificationService.$inject = ['$resource', '$uibModal', '$http', 'gettext'];
