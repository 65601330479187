import React from 'react';

import { useTranslation } from '~/shared/lib/i18n';
import { cn } from '~/shared/lib/utils';
import { MultiSelect } from '~/shared/ui/kit/multi-select';
import { Tooltip } from '~/shared/ui/kit/tooltip';

import { MultiSelectWriteContent } from './multi-select-write-content';
import { type DataTypeProps, messageIntent } from '../../lib';

export const MultiSelectWrite: React.FC<DataTypeProps<'multiselect'>> = ({
  message,
  disabled,
  onChange,
  value,
  typeExtra,
}) => {
  const { t } = useTranslation();

  const inputElement = (
    <div
      className={cn(
        'block w-full rounded-sm py-1',
        message ? 'border-stroke-additional-danger border-b-2' : 'border-transparent',
      )}
      data-testid="multi-select-container"
    >
      <MultiSelect
        value={value}
        disabled={disabled}
        placeholder={t('Choose')}
        isOpen={typeExtra?.isOpen}
      >
        <MultiSelectWriteContent
          value={value}
          options={typeExtra?.options ?? []}
          onChange={onChange}
          isOpen={typeExtra?.isOpen}
        />
      </MultiSelect>
    </div>
  );

  return message ? (
    <Tooltip content={t(message.text ?? '')} side="bottom" sideOffset={4}>
      <div className={cn('relative w-full', messageIntent[message.type])}>{inputElement}</div>
    </Tooltip>
  ) : (
    inputElement
  );
};
