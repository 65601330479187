import ng from 'angular';

import type { CoreService } from '^/app/core/core.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

(function () {
  'use strict';
  ng.module('core.legacy').component('settingsPersonalPageView', {
    bindings: {},
    template: require('./settings-personal-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['PageService', 'CoreService'];

  function Controller(this: any, PageService: PageService, CoreService: CoreService) {
    const vm = this;

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      return {
        class: 'page-header-main-pl-tab',
        buttons: [new PageService.buttons.Print()],
        pages: CoreService.getPagesConfig(),
        filters: { clicked: false },
      };
    }
  }
})();
