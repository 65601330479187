import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './contract-status-table.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { html } from '^/shared/utils';

export const ContractStatusTable = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'gtFilterService',
    'ContractStatusService',
    'gettext',
    'GtUtils',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      applyFilters: any;
      contractStatusService: any;
      filterLevel = 'contract-status-table';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      reportData: any;
      tableApi: any;
      tableOptions: any;
      tableTotalOptions: any;
      totals: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        ContractStatusService: any,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.contractStatusService = ContractStatusService;

        this.tableApi = undefined;
        this.tableOptions = {};
        this.queryParams = {};

        this.reportData = [];
        this.totals = [];
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'contract-status-table';
        Object.assign(this.queryParams, this.initQueryParams);
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.tableTotalOptions = this.getTableTotalOptions();
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.initQueryParams) {
          Object.assign(this.queryParams, this.initQueryParams);
          this.updateData();
        }
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.reportData = [];
        return this.contractStatusService
          .getReportData(this.queryParams)
          .then(
            (data: any) => {
              this.reportData = data;
              this.totals = this.reportData.total_set.reduce(
                (totalRow: any, row: any) => ({
                  volume_sum: totalRow.volume_sum + (row.volume_sum || 0),

                  volume_departed_sum:
                    totalRow.volume_departed_sum + (row.volume_departed_sum || 0),

                  volume_received_sum:
                    totalRow.volume_received_sum + (row.volume_received_sum || 0),
                }),
                { volume_sum: 0, volume_departed_sum: 0, volume_received_sum: 0 },
              );
            },
            () => {
              notify(this.gettext('Something went wrong... try again.'), 'error');
            },
          )
          .finally(() => {
            this.GtUtils.overlay('hide');
          });
      }

      getTableTotalOptions() {
        return {
          tableName: 'contract-status-total-table',
          tableClass: 'table main-table table-condensed request-table contract-status-table',
          applyFilters: this.applyFilters,
          templateArgs: {
            updateData: () => this.updateData(),
            $rootScope: this.$rootScope,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          configurable: true,
          columnDefs: [
            {
              title: this.gettext('Currency'),
              columnName: 'currency_symbol',
              class: 'td-left-align',
              cellTemplate: html`{[{ item.currency_symbol }]}`,
            },
            {
              title: this.gettext('Volume'),
              columnName: 'volume_sum',
              class: 'td-right-align',
              cellTemplate: html`{[{ item.volume_sum | number:3 }]}`,
              totalTemplate: html`{[{ item.volume_sum | number:3 }]}`,
            },
            {
              title: this.gettext('Tolerance'),
              columnName: 'tolerance_volume_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.tolerance_volume_sum == 0}">
                  {[{ item.tolerance_volume_sum | number:3 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Tolerance plus'),
              columnName: 'tolerance_volume_plus_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.tolerance_volume_plus_sum == 0}">
                  {[{ item.tolerance_volume_plus_sum | number:3 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Tolerance minus'),
              columnName: 'tolerance_volume_minus_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.tolerance_volume_minus_sum == 0}">
                  {[{ item.tolerance_volume_minus_sum | number:3 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Departed plus'),
              columnName: 'departed_volume_plus_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.departed_volume_plus_sum == 0}">
                  {[{ item.departed_volume_plus_sum | number:3 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Departed minus'),
              columnName: 'departed_volume_minus_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.departed_volume_minus_sum == 0}">
                  {[{ item.departed_volume_minus_sum | number:3 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Volume departed'),
              columnName: 'volume_departed_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.volume_departed_sum == 0}">
                  {[{ item.volume_departed_sum | number:3 }]}
                </span>
              `,
              totalTemplate: html`{[{ item.volume_departed_sum | number:3 }]}`,
            },
            {
              title: this.gettext('Shipment balance'),
              columnName: 'shipment_balance_volume_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.shipment_balance_volume_sum == 0}">
                  {[{ item.shipment_balance_volume_sum | number:3 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Volume received'),
              columnName: 'volume_received_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.volume_received_sum == 0}">
                  {[{ item.volume_received_sum | number:3 }]}
                </span>
              `,
              totalTemplate: html`{[{ item.volume_received_sum | number:3 }]}`,
            },
            {
              title: this.gettext('Contract value no VAT'),
              columnName: 'tolerance_value_wo_vat_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span
                  ng-class="{'label-opacity': item.tolerance_value_wo_vat_sum == 0}"
                  ng-if="item.tolerance_value_wo_vat_sum"
                >
                  {[{ item.tolerance_value_wo_vat_sum | number:2 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Contract value'),
              columnName: 'tolerance_value_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.tolerance_value_sum == 0}">
                  {[{ item.tolerance_value_sum | number:2 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Departed value'),
              columnName: 'departed_value_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.departed_value_sum == 0}">
                  {[{ item.departed_value_sum | number:2 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Received value'),
              columnName: 'received_value_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.received_value_sum == 0}">
                  {[{ item.received_value_sum | number:2 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Invoiced amount'),
              columnName: 'amount_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.invoiceposition_amount_sum == 0}">
                  {[{ item.invoiceposition_amount_sum | number:2 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Paid amount'),
              columnName: 'payment_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.invoiceposition_payment_sum == 0}">
                  {[{ item.invoiceposition_payment_sum | number:2 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Unpaid amount'),
              columnName: 'unpaid_amount',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.invoiceposition_unpaid_amount_sum == 0}">
                  {[{ item.invoiceposition_unpaid_amount_sum | number:2 }]}
                </span>
              `,
            },
          ],
        };
      }

      getTableOptions() {
        return {
          tableName: 'contract-status-table',
          tableClass: 'table main-table table-condensed request-table contract-status-table',
          applyFilters: this.applyFilters,
          templateArgs: {
            updateData: () => this.updateData(),
            $rootScope: this.$rootScope,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          configurable: true,
          columnDefs: [
            {
              title: this.gettext('Date'),
              columnName: 'conclusion_date',
              class: 'td-left-align',
              cellTemplate: html`
                <i class="fa fa-calendar"></i> {[{ item.conclusion_date || "---" |date:'dd.MM.yy'}]}
              `,
            },
            {
              title: this.gettext('Contract'),
              columnName: 'contract',
              class: 'td-left-align',
              cellTemplate: html`
                <a ui-sref="gt.page.contract({id: item.id })" target="_blank">
                  <span
                    class="label"
                    ng-class="{'label_calculate': item.contract_type == 'sale', 'label_default': item.contract_type == 'purchase' }"
                  >
                    <i
                      class="fa"
                      ng-class="{'fa-arrow-up': item.contract_type == 'sale', 'fa-arrow-down': item.contract_type == 'purchase' }"
                    ></i>
                  </span>
                  {[{ item.contract_number | cut:true:30:' ' }]}
                </a>
              `,
              totalTemplate: html`{[{ item.symbol }]}`,
            },
            {
              title: this.gettext('signed'),
              columnName: 'supplier_signed',
              class: 'td-left-align',
              cellTemplate: html`
                <span
                  class="label label_success smaller-label"
                  ng-if="item.signature_stage_we_signed"
                >
                  <i class="fa fa-calendar"></i> {[{ item.signature_stage_we_signed || "---" |
                  date:'dd MMM' }]}
                  <i
                    class="fa"
                    ng-class="item.signature_stage_we_signed ? 'fa-check-circle' : 'fa-circle-o'"
                  ></i>
                </span>
                <span ng-if="!item.signature_stage_we_signed" class="label-opacity"> - </span>
              `,
            },
            {
              title: this.gettext('Status'),
              columnName: 'status',
              class: 'td-left-align',
              cellTemplate: html`
                <span
                  class="label"
                  ng-class="{'label_success': item.status == 'new', 'label_default': item.status == 'executed', 'label_warning': (item.status == 'shipment' || item.status == 'delivery_period' || item.status == 'cargo_executed'), 'label_calculate': item.status == 'approved' }"
                >
                  <span ng-if="item.status == 'new'" class="positive-number">
                    <translate>New</translate>
                  </span>
                  <span ng-if="item.status == 'washout'"> <translate>Washout</translate> </span>
                  <span ng-if="item.status == 'shipment'">
                    <translate>In progress</translate>
                  </span>
                  <span ng-if="item.status == 'executed'">
                    <translate>Executed</translate>
                  </span>
                  <span ng-if="item.status == 'cancelled'">
                    <translate>Cancelled</translate>
                  </span>
                  <span ng-if="item.status == 'washout'">
                    <translate>Washout</translate>
                  </span>
                  <span ng-if="item.status == 'cargo_executed'">
                    <translate>Cargo executed</translate>
                  </span>
                  <span ng-if="item.status == 'approved'">
                    <translate>Approved</translate>
                  </span>
                  <span ng-if="item.status == 'delivery_period'">
                    <translate>Delivery period</translate>
                  </span>
                </span>
              `,
            },
            {
              title: this.gettext('Price no VAT'),
              columnName: 'price_wo_vat',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-if="item.price_wo_vat && item.VAT_option">
                  {[{ item.price_wo_vat| number:2 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Price'),
              columnName: 'price',
              class: 'td-right-align',
              cellTemplate: html`{[{ item.price | number:2 }]}`,
              totalTemplate: html`{[{ item.tolerance_price | number:2 }]}`,
            },
            {
              title: this.gettext('VAT'),
              columnName: 'vat',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-if="item.VAT_option" class="label label_success smaller-label">
                  <i class="fa fa-check-circle"></i> <translate>VAT</translate></span
                >
              `,
            },

            {
              title: this.gettext('Counterparty'),
              columnName: 'counterparty_name',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.contract_type == 'sale'">
                  <i class="fa fa-bookmark"></i> {[{ item.buyer_name || "---"| cut:true:30:' ' }]}
                </span>
                <span ng-if="item.contract_type == 'purchase'">
                  <i class="fa fa-bookmark-o"></i> {[{ item.supplier_name || "---"| cut:true:30:' '
                  }]}
                </span>
              `,
            },
            {
              title: this.gettext('Owner'),
              columnName: 'owner_name',
              class: 'td-left-align',
              cellTemplate: html`
                <i class="fa fa-home"></i> {[{ item.owner_name || "---"| cut:true:30:' ' }]}
              `,
            },
            {
              title: this.gettext('Basis'),
              columnName: 'basis_name',
              class: 'td-left-align',
              cellTemplate: html`<span> {[{ item.basis_name || "---" }]} </span>`,
            },
            {
              title: this.gettext('Ports'),
              columnName: 'ports',
              class: 'td-left-align',
              cellTemplate: html`
                <ul class="inside-table-ul" ng-if="item.port_set.length > 1">
                  <li ng-repeat="port in item.port_set | orderBy: 'title'">
                    <i class="fa fa-anchor"></i> {[{ port.name| cut:true:30:' ' }]}
                  </li>
                </ul>
                <span
                  ng-if="item.port_set.length == 1"
                  ng-repeat="port in item.port_set | orderBy: 'title'"
                >
                  <i class="fa fa-anchor"></i> {[{ port.name| cut:true:30:' ' }]}
                </span>
              `,
            },
            {
              title: this.gettext('Volume'),
              columnName: 'volume',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.volume}">
                  {[{ item.volume | number:3 }]}
                  <span class="smaller-label"
                    >{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
              totalTemplate: html`
                {[{ item.volume_sum | number:3 }]}
                <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              `,
            },
            {
              title: this.gettext('Tolerance, %'),
              columnName: 'tolerance',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.volume_options}">
                  {[{ item.volume_options }]} %
                </span>
              `,
            },
            {
              title: this.gettext('Tolerance volume'),
              columnName: 'tolerance_volume',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.tolerance_volume}">
                  {[{ item.tolerance_volume | number:3 }]}
                  <span class="smaller-label"
                    >{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
              totalTemplate: html`
                {[{ item.tolerance_volume_sum | number:3 }]}
                <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              `,
            },
            {
              title: this.gettext('Tolerance plus'),
              columnName: 'tolerance_volume_plus_sum',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.tolerance_volume_plus == 0}">
                  {[{ item.tolerance_volume_plus | number:3 }]}
                </span>
              `,
              totalTemplate: html`
                {[{ item.tolerance_volume_plus_sum | number:3 }]}
                <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              `,
            },
            {
              title: this.gettext('Tolerance minus'),
              columnName: 'tolerance_volume_minus',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.tolerance_volume_minus == 0}">
                  {[{ item.tolerance_volume_minus | number:3 }]}
                </span>
              `,
              totalTemplate: html`
                {[{ item.tolerance_volume_minus_sum | number:3 }]}
                <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              `,
            },
            {
              title: this.gettext('Departed plus'),
              columnName: 'departed_volume_plus',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.departed_volume_plus == 0}">
                  {[{ item.departed_volume_plus | number:3 }]}
                </span>
              `,
              totalTemplate: html`
                {[{ item.departed_volume_plus_sum | number:3 }]}
                <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              `,
            },
            {
              title: this.gettext('Departed minus'),
              columnName: 'departed_volume_minus',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': item.departed_volume_minus == 0}">
                  {[{ item.departed_volume_minus | number:3 }]}
                </span>
              `,
              totalTemplate: html`
                {[{ item.departed_volume_minus_sum | number:3 }]}
                <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              `,
            },
            {
              title: this.gettext('Loading option'),
              columnName: 'loading_option',
              class: 'td-left-align',
              cellTemplate: html`
                <span class="badge label_success" ng-if="item.loading_option == 2"> + </span>
                <span class="badge label_danger" ng-if="item.loading_option == 1"> - </span>
                <span ng-if="!item.loading_option">---</span>
              `,
            },
            {
              title: this.gettext('Volume departed'),
              columnName: 'volume_departed',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.volume_departed_sum}">
                  {[{ item.volume_departed_sum | number:3 }]}
                  <span class="smaller-label"
                    >{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
              totalTemplate: html`
                {[{ item.volume_departed_sum | number:3 }]}
                <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              `,
            },
            {
              title: this.gettext('Shipment balance'),
              columnName: 'shipment_balance',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.shipment_balance_volume}">
                  {[{ item.shipment_balance_volume | number:3 }]}
                  <span class="smaller-label"
                    >{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
              totalTemplate: html`
                {[{ item.shipment_balance_volume_sum | number:3 }]}
                <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              `,
            },
            {
              title: this.gettext('Volume received'),
              columnName: 'volume_received',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.volume_received_sum}">
                  {[{ item.volume_received_sum | number:3 }]}
                  <span class="smaller-label"
                    >{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
              totalTemplate: html`
                {[{ item.volume_received_sum | number:3 }]}
                <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              `,
            },
            {
              title: this.gettext('Value no VAT'),
              columnName: 'contract_value_wo_vat',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.tolerance_value_wo_vat}">
                  {[{ item.tolerance_value_wo_vat | number:2 }]}
                </span>
              `,
              totalTemplate: html`{[{ item.tolerance_value_wo_vat_sum | number:2 }]}`,
            },
            {
              title: this.gettext('Contract value'),
              columnName: 'contract_value',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.tolerance_value}">
                  {[{ item.tolerance_value | number:2 }]}
                </span>
              `,
              totalTemplate: html`{[{ item.tolerance_value_sum | number:2 }]}`,
            },
            {
              title: this.gettext('Departed value'),
              columnName: 'departed_value',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.departed_value_sum}">
                  {[{ item.departed_value_sum | number:2}]}
                </span>
              `,
              totalTemplate: html`{[{ item.departed_value_sum | number:2 }]}`,
            },
            {
              title: this.gettext('Received value'),
              columnName: 'received_value',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.received_value_sum}">
                  {[{ item.received_value_sum | number:2}]}
                </span>
              `,
              totalTemplate: html`{[{ item.received_value_sum | number:2 }]}`,
            },
            {
              title: this.gettext('Invoiced Cargo'),
              columnName: 'invoiced_cargo',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.invoiceposition_amount_sum}">
                  {[{ item.invoiceposition_amount_sum | number:2 }]}
                </span>
                <span
                  class="label smaller-label"
                  ng-class="{'label_success': item.invoiceposition_amount_sum == item.tolerance_value, 'label_danger': item.invoiceposition_amount_sum > item.tolerance_value}"
                  ng-if="item.invoiceposition_amount_sum && item.tolerance_value"
                >
                  {[{(item.invoiceposition_amount_sum / item.tolerance_value) * 100 | number:0 }]}%
                </span>
              `,
              totalTemplate: html`
                {[{ item.invoiceposition_amount_sum | number:2 }]}
                <span
                  class="label smaller-label"
                  ng-class="{'label_success': item.invoiceposition_amount_sum == item.tolerance_value_sum, 'label_danger': item.invoiceposition_amount_sum > item.tolerance_value_sum}"
                  ng-if="item.invoiceposition_amount_sum && item.tolerance_value_sum"
                >
                  {[{(item.invoiceposition_amount_sum / item.tolerance_value_sum) * 100 | number:0
                  }]}%
                </span>
              `,
            },
            {
              title: this.gettext('Paid Cargo'),
              columnName: 'paid_cargo',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.invoiceposition_payment_sum}">
                  {[{ item.invoiceposition_payment_sum | number:2 }]}
                </span>
                <span
                  class="label smaller-label"
                  ng-class="{'label_success': item.invoiceposition_amount_sum == item.invoiceposition_payment_sum, 'label_danger': item.invoiceposition_amount_sum < item.invoiceposition_payment_sum}"
                  ng-if="item.invoiceposition_amount_sum && item.invoiceposition_payment_sum"
                >
                  {[{(item.invoiceposition_payment_sum / item.invoiceposition_amount_sum) * 100 |
                  number:0 }]}%
                </span>
              `,
              totalTemplate: html`
                {[{ item.invoiceposition_payment_sum | number:2 }]}
                <span
                  class="label smaller-label"
                  ng-class="{'label_success': item.invoiceposition_amount_sum == item.invoiceposition_payment_sum, 'label_danger': item.invoiceposition_amount_sum < item.invoiceposition_payment_sum}"
                  ng-if="item.invoiceposition_amount_sum && item.invoiceposition_payment_sum"
                >
                  {[{(item.invoiceposition_payment_sum / item.invoiceposition_amount_sum) * 100 |
                  number:0 }]}%
                </span>
              `,
            },
            {
              title: this.gettext('Delivery dates'),
              columnName: 'execution_dates',
              class: 'td-left-align',
              cellTemplate: html`
                <span
                  class="tooltip"
                  ng-if="item.date_of_execution"
                  ng-class="{
                  'label label_warning': ((item.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 > 0 && (item.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 < $root.user.settings.DATE_EXECUTION_REMINDER && item.end_of_execution > $root.serverTime) && !item.final_volume,
                  'label label_danger': ((item.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 <= 0 && (item.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 > $root.user.settings.DATE_EXECUTION_ALERT) && !item.final_volume
                }"
                  data-tip="{[{ 'execution period' | translate }]} {[{item.date_of_execution | date:'dd MMM yy'}]} - {[{item.end_of_execution | date:'dd MMM yy'}]}"
                >
                  <i class="fa fa-calendar"></i> {[{item.date_of_execution || "---" |
                  date:'dd/MM/yy'}]} - {[{item.end_of_execution || "---" | date:'dd/MM/yy'}]}
                </span>
              `,
            },
            {
              title: this.gettext('Budget'),
              columnName: 'budget',
              class: 'td-left-align',
              cellTemplate: html`<span>{[{ item.budget_title || '---' }]}</span>`,
            },
            {
              title: this.gettext('additional info'),
              columnName: 'additional_info',
              class: 'td-left-align',
              cellTemplate: html`
                <span
                  class="tooltip small-font-cell smaller-label"
                  data-tip="{[{item.additional_info}]}"
                  ng-if="item.additional_info"
                >
                  <i class="fa fa-comment"></i> {[{item.additional_info | cut:true:70:' ...'}]}
                </span>
              `,
            },
            {
              title: this.gettext('Unpaid Cargo'),
              columnName: 'unpaid_cargo',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-class="{'label-opacity': !item.invoiceposition_unpaid_amount_sum}">
                  {[{ item.invoiceposition_unpaid_amount_sum | number:2 }]}
                </span>
              `,
              totalTemplate: html`{[{ item.invoiceposition_unpaid_amount_sum | number:2 }]}`,
            },
            {
              title: this.gettext('Warehouse'),
              columnName: 'warehouse_name',
              class: 'td-left-align',
              cellTemplate: html`
                <span> {[{ item.contract_warehouse_title || "---" | cut:true:30:' ' }]} </span>
              `,
            },
            {
              title: this.gettext('Not invoiced cargo'),
              columnName: 'not_invoiced_cargo',
              class: 'td-left-align',
              cellTemplate: html`<span> {[{ item.not_invoiced_cargo | number:2 }]} </span>`,
            },
          ],
        };
      }
    },
  ],
};
