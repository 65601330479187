import type { Entity, EntityName } from '~/shared/lib/entities';
import type { ListOption, MessageIntent } from '~/shared/lib/types';

import type { InputProps } from '../../kit/input';

export type DataTypeProps<T extends DataType = 'string'> = {
  value?: DataTypeValue<T>;
  typeExtra?: DataTypeExtra<T>;
  disabled?: boolean;
  onChange?: (value?: DataTypeValue<T>) => void;
  message?: {
    type: MessageIntent;
    text?: string;
  };
  ref?: React.Ref<HTMLInputElement>;
};

export type EntityOption = Entity & { title: string };

export const isEntityOption = (value: unknown): value is EntityOption => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'id' in value &&
    'title' in value &&
    (value.id !== null || value.id != undefined)
  );
};

export const isEntityOptionArray = (value: unknown): value is EntityOption[] =>
  Array.isArray(value) && value.every(isEntityOption);

export type Person = {
  firstName: string;
  lastName?: string;
  avatarSrc?: string;
};

export type DateRange = {
  from?: Date;
  to?: Date;
};

export type DataTypeValue<T extends DataType> = DataTypes[T]['value'];
export type DataTypeExtra<T extends DataType> = DataTypes[T]['typeExtra'];

export type DataTypes = {
  number: {
    value?: number;
    typeExtra?: {
      inputProps: InputProps;
    };
  };
  string: {
    value: string;
    typeExtra?: {
      inputProps: InputProps;
    };
  };
  phone: {
    value: string;
    typeExtra?: {
      inputProps: InputProps;
    };
  };
  text: {
    value: string;
    typeExtra?: {
      inputProps: InputProps;
    };
  };
  label: {
    value: ListOption;
    typeExtra?: {
      inputProps: InputProps;
    };
  };
  url: {
    value: string;
    typeExtra?: {
      inputProps: InputProps;
    };
  };
  email: {
    value: string;
    typeExtra?: {
      inputProps: InputProps;
    };
  };
  template: {
    value: unknown;
    typeExtra?: {
      Render: (props: { value?: unknown } & Record<string, unknown>) => React.ReactNode;
      props?: Record<string, unknown>;
    };
  };
  person: { value: Person; typeExtra?: never };
  checkbox: { value: boolean; typeExtra?: never };
  date: {
    value: Date;
    typeExtra?: { print?: string; stringify?: string };
  };
  daterange: {
    value: DateRange;
    typeExtra?: { print?: string; stringify?: string };
  };
  select: {
    value: ListOption;
    typeExtra: { options: ListOption[]; isOpen?: boolean };
  };
  multiselect: {
    value: ListOption[];
    typeExtra: { options: ListOption[]; isOpen?: boolean };
  };
  entity: {
    value: EntityOption[];
    typeExtra: { entity: EntityName; multiple?: boolean };
  };
};

export type DataType = keyof DataTypes;
