import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const CalendarPage = {
  bindings: {},
  template: '<calendar class="gt-calendar"></calendar>',
  controller: [
    'PageService',
    'gettext',
    class {
      PageService: PageService;
      gettext: ng.gettext.gettextFunction;
      constructor(PageService: PageService, gettext: ng.gettext.gettextFunction) {
        this.PageService = PageService;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-crm-tab',
          buttons: [new this.PageService.buttons.Refresh()],
          pages: [
            {
              title: this.gettext('Calendar'),
              permissions: [],
              state: 'crm.calendar',
              icon: 'fa-calendar',
              tabs: [
                {
                  title: this.gettext('Calendar'),
                  permissions: [],
                  state: 'crm.calendar',
                  icon: 'fa-calendar',
                },
              ],
            },
          ],
        };
      }
    },
  ],
};
