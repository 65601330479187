import type ng from 'angular';

export class AnalyticsService {
  static readonly $inject = ['gettext'];
  constructor(private readonly gettext: ng.gettext.gettextFunction) {}

  getAnalyticPageConfig(page: string) {
    switch (page) {
      case 'crm':
        return this.getCrmPagesConfig();
      case 'deals':
        return this.getDealsPagesConfig();
      case 'executions':
        return this.getExecutionsPagesConfig();
      case 'finances':
        return this.getFinancesPagesConfig();
      default:
        return {};
    }
  }

  getCrmPagesConfig() {
    return {
      class: 'page-header-main-crm-tab',
      buttons: [],
      pages: [
        {
          title: this.gettext('Analytics'),
          state: 'analytic.counterparty',
          icon: 'fa fa-area-chart',
          tabs: [
            {
              title: this.gettext('Counterparty'),
              state: 'analytic.counterparty',
              icon: 'fa fa-building',
              permissions: [],
            },
            {
              title: this.gettext('Updates'),
              state: 'analytic.updates',
              icon: 'fa fa-commenting',
              permissions: [],
            },
          ],
          permissions: ['view_analytics'],
        },
      ],
    };
  }

  getDealsPagesConfig() {
    return {
      class: 'page-header-main-prices-tab',
      buttons: [],
      pages: [
        {
          title: this.gettext('Analytics'),
          state: 'analytic.contracts',
          icon: 'fa fa-area-chart',
          tabs: [
            {
              title: this.gettext('Tickets'),
              state: 'analytic.tickets',
              icon: 'fa fa-file-text-o',
              permissions: [],
            },
            {
              title: this.gettext('Contracts'),
              state: 'analytic.contracts',
              icon: 'fa fa-file-text',
              permissions: [],
            },
            {
              title: this.gettext('Passports'),
              state: 'analytic.passports',
              icon: 'fa fa-exchange',
              permissions: [],
            },
          ],
          permissions: ['view_analytics'],
        },
      ],
    };
  }

  getExecutionsPagesConfig() {
    return {
      class: 'page-header-main-logistics-tab',
      buttons: [],
      pages: [
        {
          title: this.gettext('Analytics'),
          state: 'analytic.logistics',
          icon: 'fa fa-area-chart',
          tabs: [
            {
              title: this.gettext('Logistics'),
              state: 'analytic.logistics',
              icon: 'fa fa-truck',
              permissions: [],
            },
            {
              title: this.gettext('Voyages'),
              state: 'analytic.voyages',
              icon: 'fa fa-ship',
              permissions: [],
            },
          ],
          permissions: ['view_analytics'],
        },
      ],
    };
  }

  getFinancesPagesConfig() {
    return {
      class: 'page-header-main-payments-tab',
      buttons: [],
      pages: [
        {
          title: this.gettext('Analytics'),
          state: 'analytic.invoices',
          icon: 'fa fa-area-chart',
          tabs: [
            {
              title: this.gettext('Invoices'),
              state: 'analytic.invoices',
              icon: 'fa fa-credit-card-alt',
              permissions: [],
            },
            {
              title: this.gettext('Charges'),
              state: 'analytic.charges',
              icon: 'fa fa-money',
              permissions: [],
            },
          ],
          permissions: ['view_analytics'],
        },
      ],
    };
  }
}
