import {
  BaseLogisticRepository,
  ExportLogisticRecord,
  InfoLogisticListTotals,
  LogisticQueryParams,
} from '~/features/execution/logistic/common';
import {
  logisticsLogisticsListExportList,
  logisticsLogisticsTotalsInfoRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';

@singleton()
export class ExportLogisticRepository extends BaseLogisticRepository<
  ExportLogisticRecord,
  InfoLogisticListTotals
> {
  queryFn = async (query: LogisticQueryParams) => {
    const { data } = await logisticsLogisticsListExportList({ query });
    return { records: data.results, count: data.count };
  };
  getListTotals = async (query: LogisticQueryParams) => {
    const { data } = await logisticsLogisticsTotalsInfoRetrieve({ query });
    return data;
  };
}
