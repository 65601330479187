import type ng from 'angular';

import { formatDate } from '~/shared/lib/date';

export class StockMarketPricesService {
  $http: ng.IHttpService;
  $resource: ng.resource.IResourceService;
  Derivatives: any;
  StockMarketPrice: any;
  constructor($http: ng.IHttpService, $resource: ng.resource.IResourceService) {
    this.$http = $http;
    this.$resource = $resource;

    this.StockMarketPrice = this.$resource(
      '/api/stockexchanges/stock-market-prices/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        consolidated: {
          url: '/api/stockexchanges/consolidated-stock-market-prices/',
          method: 'GET',
          isArray: false,
        },
        parseActiveQuotes: {
          method: 'POST',
          isArray: false,
          url: '/api/stockexchanges/stock-market-prices/parse_active_quotes/',
        },
      },
    );

    this.Derivatives = this.$resource(
      '/api/stockexchanges/derivatives/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
      },
    );
  }

  getDerivativesTable(params: object) {
    return this.StockMarketPrice.query(params).$promise;
  }

  createStockMarketPrice(futureMarketPrice: any) {
    return this.StockMarketPrice.save(futureMarketPrice).$promise;
  }

  update(future: any) {
    return this.StockMarketPrice.update(future).$promise;
  }

  delete(future: any) {
    return this.StockMarketPrice.delete({ id: future.id }).$promise;
  }

  getConsolidatedDerivativesTable(params: object) {
    const consolidatedDerivativesTable = {
      months: [],
      cargoList: [],
      data: {},
    };
    return this.$http({
      method: 'GET',
      url: '/api/stockexchanges/consolidated-stock-market-prices/',
      params: params,
    }).then((response: any) => {
      response.data.results.map((price: any) => {
        this.addPriceToTable(consolidatedDerivativesTable, price);
      });
      return consolidatedDerivativesTable;
    });
  }

  addPriceToTable(consolidatedFuturesTable: any, price: any) {
    const yearMonthKey = formatDate(price.month, 'yyyy-MM');
    const monthIndex = consolidatedFuturesTable.months
      .map((month: any) => month.key)
      .indexOf(yearMonthKey);
    const cargoIndex = consolidatedFuturesTable.cargoList
      .map((cargo: any) => cargo.cargo_id)
      .indexOf(price.cargo_id);
    if (monthIndex < 0) {
      consolidatedFuturesTable.months.push({
        key: yearMonthKey,
        title: formatDate(price.month, 'MMM, yyyy'),
        value: price.month,
      });
    }
    if (cargoIndex < 0) {
      consolidatedFuturesTable.cargoList.push({
        title: price.cargo_title,
        cargo_id: price.cargo_id,
      });
    }
    consolidatedFuturesTable.data[yearMonthKey] = consolidatedFuturesTable.data[yearMonthKey] || {};
    consolidatedFuturesTable.data[yearMonthKey][price.cargo_id] = price;
  }
}
StockMarketPricesService.$inject = ['$http', '$resource'];
