import { shareReplay } from 'rxjs';

import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import {
  BillOfLadingPatch,
  BillOfLadingPost,
  BillOfLadingQueryParams,
  BillOfLadingRecord,
} from '~/features/execution/bills-of-lading';
import { BillsOfLadingRepository } from '~/features/execution/bills-of-lading/services/bills-of-lading.repository';
import { BillOfLading } from '~/shared/api';
import { injectable } from '~/shared/lib/di';
import { from, switchMap } from '~/shared/lib/state';

export type BillsOfLadingListContainerProps = ContainerProps<BillOfLadingQueryParams>;

@injectable()
export class BillsOfLadingListViewModel extends EntityListContainerModel<
  BillOfLading,
  BillOfLadingQueryParams,
  BillsOfLadingRepository,
  BillsOfLadingListContainerProps,
  BillOfLadingRecord,
  BillOfLadingPost,
  BillOfLadingPatch
> {
  entityRepoClass = BillsOfLadingRepository;

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.repo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );
}
