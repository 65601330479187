import ng from 'angular';

import type { QueryParams } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').controller('UpdateListModalController', Controller);

  Controller.$inject = ['$uibModalInstance', 'queryParams', 'gettext'];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    queryParams: QueryParams,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.close = close;
    vm.filterLevel = 'update-list-modal';
    vm.queryParams = queryParams;
    vm.newUpdate = {
      message_type: 'call',
      manager: queryParams.manager,
      client: queryParams.client,
      update: '',
    };

    ////////////////

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
