import type ng from 'angular';

import template from './consolidated-market-prices-table.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { MarketPricesService } from '^/app/finances/stock-exchange/stock-market-prices/market-prices.service';
import { html } from '^/shared/utils';

export const ConsolidatedMarketPricesTable = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$timeout',
    'gettext',
    'MarketPricesService',
    'GtUtils',
    'gtFilterService',
    class {
      $timeout: ng.ITimeoutService;
      GtUtils: GtUtilsService;
      MarketPricesService: MarketPricesService;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      filterLevel = 'consolidated-market-prices-table';
      premiumPrices: any;
      queryParams: QueryParams = {};
      tableApi: any;
      tableOptions: any;
      constructor(
        $timeout: ng.ITimeoutService,
        gettext: ng.gettext.gettextFunction,
        MarketPricesService: MarketPricesService,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
      ) {
        this.$timeout = $timeout;
        this.gettext = gettext;
        this.MarketPricesService = MarketPricesService;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;

        this.premiumPrices = {};
        this.queryParams = {};

        this.tableOptions = this.getTableOptions();
        this.tableApi = undefined;
      }

      $onInit() {
        this.gtFilterService.subscribe(
          this.filterLevel,
          (params: QueryParams) => {
            this.queryParams = params;
            this.queryParams.ordering = this.queryParams.ordering ?? '-date';
            this.updateTableData();
          },
          this.queryParams,
        );
        this.updateTableData();
      }

      updateTableData() {
        this.GtUtils.overlay('show');
        this.MarketPricesService.getConsolidatedTable(this.queryParams).then((result: any) => {
          this.tableApi.setColumnDefs(this.getTableOptions(result.cargoList).columnDefs);
          this.tableApi.setRowData([]);
          // FIX: gt-table. Don't repeat this!
          this.$timeout(() => {
            this.tableApi.setRowData(result.data);
            this.GtUtils.overlay('hide');
          });
        });
      }

      getTableOptions(cargoList?: object[]) {
        const options: any = {
          filterLevel: this.filterLevel,
          tableName: 'consolidated-market-prices-table',
          tableClass: ['passport-position-crop-table', 'table-hover'],
          columnDefs: [
            {
              columnName: 'title',
              class: 'td-left-align',
              title: this.gettext('Month'),
              cellTemplate: html`{[{ item.date | date: date:'dd.MM.yy'}]} `,
            },
            {
              columnName: 'port',
              predicate: 'port',
              title: this.gettext('Port'),
              cellTemplate: html`{[{ item.port_name || '---' }]} `,
            },
            {
              columnName: 'basis',
              predicate: 'basis',
              title: this.gettext('Basis'),
              cellTemplate: html`{[{ item.basis_name || '---' }]} `,
            },
          ],
          rowData: [],
          onInit: (api: any) => (this.tableApi = api),
        };
        if (cargoList) {
          cargoList.forEach((cargo: any) => {
            options.columnDefs.push({
              columnName: `cargo_${cargo.id}`,
              title: cargo.title,
              cellTemplate: `
                <div ng-if="!item.cargo_map[${cargo.id}]">---</div>
                <div ng-if="item.cargo_map[${cargo.id}]" class="alert-success">
                  <span class="label-strong">
                    {[{ item.cargo_map[${cargo.id}].price || 0 | number:2 }]}
                  </span>
                  <span class="smaller-label">
                    <i class="fa fa-clock-o"></i>
                    {[{item.cargo_map[${cargo.id}].date | date:'dd.MM.yy' || '---' }]}
                  </span>
                </div>
              `,
            });
          });
        }
        return options;
      }
    },
  ],
};
