import React from 'react';

import { useComponent } from '~/shared/lib/components';
import { useTranslation } from '~/shared/lib/i18n';
import { useObservableEagerState, useObservableState } from '~/shared/lib/state';
import { cn } from '~/shared/lib/utils';
import { ChevronDownIcon, GridIcon, PencilIcon, PlusIcon } from '~/shared/ui/icons';
import {
  DropdownLayout,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { ConfigModal } from './config-modal';
import type { DatasetViewsProps } from './dataset-views.model';
import { DatasetViewsModel } from './dataset-views.model';
import type { DataParams, DataRecord } from '../../lib/types';

export const DatasetViews = <R extends DataRecord, P extends DataParams>(
  props: DatasetViewsProps<R, P>,
) => {
  const { model, ComponentProvider } = useComponent(props, DatasetViewsModel<R, P>);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const configs = useObservableState(model.columnConfigs$, []);
  const editingConfig = useObservableEagerState(model.editingColumnsConfig$);
  const activeConfig = useObservableEagerState(model.activeColumnsConfig$);
  const loading = useObservableEagerState(model.loading$);

  const createConfigHandler = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      model.createColumnsConfig();
    },
    [model],
  );

  return (
    <ComponentProvider>
      <DropdownLayout>
        <DropdownMenu onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild>
            <button
              className={cn(
                'group text-text-main-primary flex cursor-pointer items-center gap-1 rounded-lg p-2 transition-all duration-200 ease-in-out focus:outline-none',
                open ? 'shadow-md' : '',
                loading ? 'animate-pulse shadow-md' : '',
              )}
            >
              <div className="text-text-main-secondary">{activeConfig?.title ?? t('Default')}</div>
              <span
                className={cn(
                  'transform transition-transform duration-200 ease-in-out',
                  open ? 'rotate-180' : 'rotate-0',
                )}
              >
                <ChevronDownIcon className="text-text-main-secondary" />
              </span>
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="relative z-30 mt-2 flex w-max min-w-[100px] flex-col gap-1.5 overflow-visible rounded-md shadow-2xl"
            collisionPadding={250}
          >
            {configs.map((config) => (
              <DropdownMenuItem
                key={String(config.id)}
                onSelect={(e) => {
                  e.preventDefault();
                  model.setActiveColumnsConfig(config);
                }}
                className={cn(
                  'group mx-1 flex cursor-pointer items-center gap-2 rounded-md p-2 hover:opacity-70',
                  activeConfig?.id == config.id
                    ? 'shadow-sm'
                    : 'transition duration-200 ease-in-out',
                  loading ? 'bg-tags-background-gray pointer-events-none animate-pulse' : '',
                )}
              >
                <span>
                  <GridIcon className="text-text-main-secondary" />
                </span>
                <span className="text-text-main-primary flex-1">{config.title}</span>
                <button
                  className={cn(
                    'ml-auto cursor-pointer rounded-md p-1 opacity-0 transition duration-200 ease-in-out group-hover:opacity-100',
                    activeConfig?.id === config.id ? 'opacity-100' : '',
                    editingConfig?.id === config.id ? 'bg-stroke-main-light opacity-100' : '',
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    model.setEditingColumnsConfig(config);
                  }}
                  data-testid="edit-view-config-btn"
                >
                  <PencilIcon className="text-text-main-primary" />
                </button>
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator className="bg-stroke-main-light my-0 h-px" />
            <DropdownMenuItem
              key="add-item"
              className={cn(
                'group mx-1 mb-2 flex cursor-pointer items-center gap-2 rounded-md p-2',
                editingConfig && editingConfig.id < 0
                  ? 'shadow-sm'
                  : 'transition duration-200 ease-in-out hover:opacity-50',
                loading ? 'bg-tags-background-gray pointer-events-none animate-pulse' : '',
              )}
              onSelect={(e) => e.preventDefault()}
              onClick={createConfigHandler}
            >
              <span>
                <PlusIcon className="text-text-main-secondary" />
              </span>
              <span className="text-text-main-primary">{t('Add View')}</span>
            </DropdownMenuItem>

            {editingConfig && (
              <div className="bg-background-main-primary absolute top-0 left-full ml-2 rounded-md shadow-lg">
                <ConfigModal config={editingConfig} />
              </div>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </DropdownLayout>
    </ComponentProvider>
  );
};
