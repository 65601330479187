import { logisticsStationsPredictionsRetrieve } from '@gt/api';

import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Station, StationListParams } from '../lib';

export class StationRepository extends EntityRepository<Station, StationListParams> {
  readonly entityName = 'logistics.Station';

  getFn = (_id: number): Promise<Station> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: StationListParams): Promise<Paginated<Station>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await logisticsStationsPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  createFn = (_entity: Station): Promise<Station> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Station>): Promise<Station> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
