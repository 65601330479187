import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './customs-cargo-declaration-details-item-container.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { CustomsCargoDeclarationService } from '^/app/execution/components/transport/customs-cargo-declaration/customs-cargo-declaration.service';

export const CustomsCargoDeclarationDetailsItemContainer = {
  bindings: {
    filterLevel: '<',
    declaration: '<',
  },
  template,
  controller: [
    '$scope',
    '$state',
    'GtUtils',
    'gtFilterService',
    'CustomsCargoDeclarationService',
    'FormFieldParamsService',
    'CoreService',
    'AccountsService',
    'CCDFormFieldsService',
    class {
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      AccountsService: AccountsService;
      CCDFormFieldsService: any;
      CoreService: CoreService;
      CustomsCargoDeclarationService: CustomsCargoDeclarationService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      activeTabs: any;
      declaration: any;
      fields: any;
      filterLevel = '';
      gtFilterService: GtFilterService;
      tab: string | string[] = ['info', 'finance'];
      updating: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        CustomsCargoDeclarationService: CustomsCargoDeclarationService,
        FormFieldParamsService: FormFieldParamsService,
        CoreService: CoreService,
        AccountsService: AccountsService,
        CCDFormFieldsService: any,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.CustomsCargoDeclarationService = CustomsCargoDeclarationService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.CoreService = CoreService;
        this.AccountsService = AccountsService;
        this.CCDFormFieldsService = CCDFormFieldsService;
        this.fields = [];
        this.activeTabs = [];
      }

      $onInit() {
        this.updateFields();
        this.updateData();
        this.tab = (this.gtFilterService.getQueryParams(this.filterLevel).tab as string) || 'info';
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
          if (data.tab) {
            this.tab = data.tab;
          }
        });
        this.changeTab('finance');
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.CustomsCargoDeclarationService.getCCD(this.declaration.id)
          .then((data: any) => {
            this.declaration = data;
            this.getAuthorInfo().catch(this.GtUtils.errorClb);
            this.getEditorInfo().catch(this.GtUtils.errorClb);
            this.CoreService.getModelContentType('customscargodeclaration').then((data: any) => {
              this.declaration.content_type = data.id;
            });
            this.GtUtils.overlay('hide');
            this.updating = false;
          })
          .catch(() => {
            notify(this.GtUtils.translate("CCD doesn't exist"));
            this.$state.go('logistics.customsCargoDeclaration');
          })
          .finally(() => {
            this.GtUtils.overlay('hide');
          });
      }

      getAuthorInfo() {
        return this.AccountsService.User.get(this.declaration.author).then((data) => {
          this.declaration.author_first_name = data.first_name;
          this.declaration.author_avatar = data.profile.avatar;
        });
      }

      getEditorInfo() {
        return this.AccountsService.User.get(this.declaration.editor).then((data) => {
          this.declaration.editor_first_name = data.first_name;
          this.declaration.editor_avatar = data.profile.avatar;
        });
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => {
            this.fields = fields;
          })
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        return this.CCDFormFieldsService.getFields(this.declaration);
      }

      changeTab(tab: string) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);
      }

      editDeclaration() {
        this.CustomsCargoDeclarationService.openModal(this.declaration).then((result: any) => {
          this.declaration = { id: result.id };
          this.updateData();
        });
      }
    },
  ],
};
