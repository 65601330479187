import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './customs-cargo-declaration-modal.html?raw';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { CustomsCargoDeclarationService } from '^/app/execution/components/transport/customs-cargo-declaration/customs-cargo-declaration.service';

export const CustomsCargoDeclarationModal = {
  bindings: {
    modalInstance: '<',
    declaration: '<?',
    extra: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$state',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'CoreService',
    'CustomsCargoDeclarationService',
    'CCDFormFieldsService',
    'CustomValuesService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      GtUtils: GtUtilsService;
      gettext: ng.gettext.gettextFunction;
      FormFieldParamsService: FormFieldParamsService;
      CoreService: CoreService;
      CustomsCargoDeclarationService: CustomsCargoDeclarationService;
      CCDFormFieldsService: any;
      CustomValuesService: CustomValuesService;

      customValues: any;
      declaration: any;
      extra: any;
      fields: any;
      form: any;
      modalInstance: any;
      tab: string;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        CoreService: CoreService,
        CustomsCargoDeclarationService: CustomsCargoDeclarationService,
        CCDFormFieldsService: any,
        CustomValuesService: CustomValuesService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.CoreService = CoreService;
        this.CustomsCargoDeclarationService = CustomsCargoDeclarationService;
        this.CCDFormFieldsService = CCDFormFieldsService;
        this.CustomValuesService = CustomValuesService;

        this.tab = 'edit';
        this.form = undefined;
        this.fields = [];
      }

      $onInit() {
        this.declaration = this.declaration || this.initNewDeclaration();
        this.extra = this.extra || {};
        this.tab = this.extra.tab || 'edit';
        if (this.declaration.id) {
          this.updateData();
        } else {
          this.getContentType();
          this.updateFields();
        }
        this.$scope.$watch(
          () => this.declaration.declaration_type,
          () => this.onDecladationTypeSelect(),
        );
        this.$scope.$on(
          'custom-values-updated__customscargodeclaration',
          (event: any, data: any) => {
            this.customValues = data;
          },
        );
      }

      updateData() {
        this.GtUtils.overlay('show');

        return this.CustomsCargoDeclarationService.getCCD(this.declaration.id)
          .then((data: any) => {
            this.declaration = data;
            this.updateFields();
            this.GtUtils.overlay('hide');
          })
          .catch(() => {
            this.GtUtils.overlay('hide');
            notify(this.gettext("CCD doesn't exist"));
            this.$state.go('logistics.customsCargoDeclaration');
          });
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      updateContracts() {
        if (this.declaration.stage === 'cargo') {
          if (this.declaration.purchase_contract) {
            this.declaration.purchase_contracts = [this.declaration.purchase_contract];
          }
          if (this.declaration.sale_contract) {
            this.declaration.sale_contracts = [this.declaration.sale_contract];
          }
        }

        this.declaration.contracts = [
          ...(this.declaration?.sale_contracts || []),
          ...(this.declaration?.purchase_contracts || []),
        ];
      }

      destroy() {
        if (
          !confirm(this.gettext("Are you sure that you want delete 'Custom cargo declaration'?"))
        ) {
          return;
        }
        return this.CustomsCargoDeclarationService.deleteCCD(this.declaration.id).then(() => {
          notify(this.gettext('Custom cargo declaration removed'));
          this.close('delete', true);
        });
      }

      save() {
        const declarationId = this.declaration.id;
        this.updateContracts();

        return this.CustomsCargoDeclarationService.saveCCD(this.declaration).then((data: any) => {
          this.GtUtils.overlay('hide');
          this.close(data, true);

          if (declarationId === undefined) {
            this.CustomValuesService.createCustomValues(data.id, this.customValues);
          }
        }, this.GtUtils.errorClb);
      }

      initNewDeclaration() {
        return {
          contracts: [],
          sale_contracts: [],
          purchase_contracts: [],
          passports: [],
          logistics: [],
          currency:
            this.$rootScope.user.settings.CURRENCY_OBJECTS[
              this.$rootScope.user.settings.DEFAULT_CURRENCY
            ].id,
          ...this.declaration,
        };
      }

      getContentType() {
        this.CoreService.getModelContentType('customscargodeclaration').then((data: any) => {
          this.declaration.content_type = data.id;
        });
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        return this.CCDFormFieldsService.getFields(this.declaration);
      }

      onDecladationTypeSelect() {
        if (this.declaration.declaration_type === 'transit') {
          this.declaration.stage = 'cargo';
        }
      }
    },
  ],
};
