import type ng from 'angular';

import template from './analytics-container.html?raw';
import type { TransportAnalyticsService } from '../analytics.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';

export const AnalyticsContainer = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gettext',
    'TransportAnalyticsService',
    'gtFilterService',
    class {
      TransportAnalyticsService: TransportAnalyticsService;
      bottomStationsChartConfig: any;
      bottomStationsTableOptions: any;
      filterLevel = 'transport-analytics-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      lostsByTerminalChartConfig: any;
      lostsByTerminalTableOptions: any;
      plansByMonthChartConfig: any;
      queryParams: QueryParams = {};
      topStationsChartConfig: any;
      topStationsTableOptions: any;
      volumesByCropTerminalConfig: any;
      volumesByMonthChartConfig: any;
      volumesByTerminalChartConfig: any;
      volumesByTerminalTableOptions: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        TransportAnalyticsService: TransportAnalyticsService,
        gtFilterService: GtFilterService,
      ) {
        this.gettext = gettext;
        this.TransportAnalyticsService = TransportAnalyticsService;
        this.gtFilterService = gtFilterService;

        this.queryParams = {};

        this.volumesByMonthChartConfig = undefined;
        this.volumesByCropTerminalConfig = undefined;
        this.volumesByTerminalTableOptions = this.getVolumesByTerminalTableOptions();
        this.lostsByTerminalChartConfig = undefined;
        this.lostsByTerminalTableOptions = this.getLostsByTerminalTableOptions();
        this.topStationsChartConfig = undefined;
        this.topStationsTableOptions = this.getTopStationsTableOptions();
        this.bottomStationsChartConfig = undefined;
        this.bottomStationsTableOptions = this.getBottomStationsTableOptions();
        this.plansByMonthChartConfig = undefined;
      }

      $onInit() {
        this.gtFilterService.subscribe(
          this.filterLevel,
          (params) => {
            this.queryParams = params;
            this.updateAnalyticsData();
          },
          this.queryParams,
        );
        this.updateAnalyticsData();
      }

      updateAnalyticsData() {
        this.updateVolumesByMonthChartConfig();
        this.updateVolumesByTerminalChartConfig();
        this.updateLostsByTerminalChartConfig();
        this.updateTopStationsChartConfig();
        this.updateBottomStationsChartConfig();
      }

      updateVolumesByMonthChartConfig() {
        this.TransportAnalyticsService.getVolumesByMonthChartConfig(this.queryParams).then(
          (data: any) => {
            this.volumesByMonthChartConfig = data;
          },
        );
      }

      updateVolumesByTerminalChartConfig() {
        this.TransportAnalyticsService.getVolumesByTerminalChartConfig().then((data: any) => {
          this.volumesByTerminalChartConfig = data;
        });
      }

      getVolumesByTerminalTableOptions() {
        return {
          tableName: 'VolumesByTerminal',
          columnDefs: [
            {
              columnName: 'terminal__name',
              title: this.gettext('Station'),
            },
            {
              columnName: 'volume_real_received',
              cellTemplate: '{[{ item.volume_real_received | number:3 }]}',
              title: this.gettext('Volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.TransportAnalyticsService.getVolumesByTerminalData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      updateLostsByTerminalChartConfig() {
        this.TransportAnalyticsService.getLostsByTerminalChartConfig().then((data: any) => {
          this.lostsByTerminalChartConfig = data;
        });
      }

      getLostsByTerminalTableOptions() {
        return {
          tableName: 'LostsByTerminal',
          columnDefs: [
            {
              columnName: 'terminal__name',
              title: this.gettext('Station'),
            },
            {
              columnName: 'volume_lost',
              cellTemplate: '{[{ item.volume_lost | number:3 }]}',
              title: this.gettext('Volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.TransportAnalyticsService.getLostsByTerminalData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      updateTopStationsChartConfig() {
        this.TransportAnalyticsService.getTopStationsChartConfig().then((data: any) => {
          this.topStationsChartConfig = data;
        });
      }

      getTopStationsTableOptions() {
        return {
          tableName: 'TopStations',
          columnDefs: [
            {
              columnName: 'station__name',
              title: this.gettext('Station'),
            },
            {
              columnName: 'volume_departed',
              cellTemplate: '{[{ item.volume_departed | number:3 }]}',
              title: this.gettext('Volume departed'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.TransportAnalyticsService.getTopStationData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      updateBottomStationsChartConfig() {
        this.TransportAnalyticsService.getBottomStationsChartConfig().then((data: any) => {
          this.bottomStationsChartConfig = data;
        });
      }
      getBottomStationsTableOptions() {
        return {
          tableName: 'BottomStations',
          columnDefs: [
            {
              columnName: 'station__name',
              title: this.gettext('Station'),
            },
            {
              columnName: 'volume_departed',
              cellTemplate: '{[{ item.volume_departed | number:3 }]}',
              title: this.gettext('Volume departed'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.TransportAnalyticsService.getBottomStationData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }
    },
  ],
};
