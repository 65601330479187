import type ng from 'angular';

import type { CustomsCargoDeclaration, CustomsDeclarationInvoicePosition } from '~/shared/api';
import { logisticsCustomsCargoDeclarationRetrieve } from '~/shared/api';
import { errorHandler } from '~/shared/lib/errors';

import type { CoreUtils } from '^/app/core/core.utils';
import type { QueryParams } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class CustomsCargoDeclarationService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  $q: ng.IQService;
  CoreUtils: CoreUtils;
  CDTypeResource: any;
  LogisticsService: LogisticsService;
  customsCargoDeclarationResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    $q: ng.IQService,
    CoreUtils: CoreUtils,
    gettext: ng.gettext.gettextFunction,
    LogisticsService: LogisticsService,
  ) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.CoreUtils = CoreUtils;
    this.gettext = gettext;
    this.LogisticsService = LogisticsService;
    this.customsCargoDeclarationResource = $resource(
      '/api/logistics/customs-cargo-declaration/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        listInfo: {
          method: 'GET',
          url: '/api/logistics/customs-cargo-declaration-list/info/',
        },
        predictions: {
          method: 'GET',
          url: '/api/logistics/customs-cargo-declaration/predictions/',
        },
        getPreparedCcdByInvoice: {
          method: 'GET',
          isArray: true,
          url: '/api/logistics/customs-cargo-declaration/get_prepared_ccd_by_invoice/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/logistics/customs-cargo-declaration/bulk_create_or_update/',
        },
        bulkCreateOrUpdateWithLogistics: {
          method: 'POST',
          url: '/api/logistics/customs-cargo-declaration/bulk_create_or_update_with_logistics/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/customs-cargo-declaration/export_column_names/',
        },
        listTotals: {
          method: 'GET',
          url: '/api/logistics/customs-cargo-declaration-list/totals/',
        },
      },
    );
    this.CDTypeResource = $resource(
      '/api/logistics/customs-cargo-declaration-type/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        predictions: {
          method: 'GET',
          url: '/api/logistics/customs-cargo-declaration-type/predictions/',
        },
      },
    );
  }

  getListData(params: QueryParams) {
    return this.customsCargoDeclarationResource.listInfo(params).$promise;
  }

  getCCDData(params: QueryParams) {
    return this.customsCargoDeclarationResource.listInfo(params).$promise.then((data: any) => {
      return this.customsCargoDeclarationResource
        .listTotals(params)
        .$promise.then((totals: any) => {
          return { data: data, totals: totals };
        });
    });
  }

  getCcdByInvoice(params: object) {
    return this.customsCargoDeclarationResource.getPreparedCcdByInvoice(params).$promise;
  }

  getCCD(ccdId: number) {
    return this.customsCargoDeclarationResource.get({ id: ccdId }).$promise;
  }

  deleteCCD(ccdId: number) {
    return this.customsCargoDeclarationResource.delete({ id: ccdId }).$promise;
  }

  bulkCreateOrUpdate(ccd: CustomsCargoDeclaration) {
    return this.customsCargoDeclarationResource.bulkCreateOrUpdate(ccd).$promise;
  }

  saveCCD(item: CustomsCargoDeclaration) {
    if (item.id) {
      return this.customsCargoDeclarationResource.update(item).$promise;
    }
    return this.customsCargoDeclarationResource.save(item).$promise;
  }

  openModal(declaration: CustomsCargoDeclaration) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<customs-cargo-declaration-modal
        declaration="declaration"
        modal-instance="$uibModalInstance"
      >
      </customs-cargo-declaration-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
          ($scope as any).$uibModalInstance = $uibModalInstance;
          ($scope as any).declaration = declaration;
        },
      ],
      windowClass: 'modal-template-third-width',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result;
  }

  updateCcdFieldsModel(declaration: any, extraData: any) {
    const newDeclaration = {
      ...declaration,
      id: declaration?.id,
      currency_exchange: declaration?.currency_exchange_id,
      currency: declaration?.currency_id,
      from_declaration: declaration?.from_declaration_id,
      invoice_positions: declaration?.invoice_positions,
      passports: declaration?.passport_id ? [declaration?.passport_id] : [],
      result_status: 'default',
      terminal: declaration?.terminal_id,
    };

    if (extraData?.contract) {
      newDeclaration.customs_broker = extraData.contract.customs_broker;
      newDeclaration.destination_of_crop = extraData.contract.destination_of_crop;
    }

    return newDeclaration;
  }

  clone(declaration: CustomsCargoDeclaration) {
    if (!confirm(this.gettext('Do you want to clone this Customs declaration?'))) {
      return this.$q.resolve();
    }

    return logisticsCustomsCargoDeclarationRetrieve({ path: { id: declaration.id } })
      .then((data) => {
        const newObj = this.CoreUtils.cleanBeforeClone({ ...data.data }, ['id']);

        if (newObj.invoice_positions && Array.isArray(newObj.invoice_positions)) {
          newObj.invoice_positions = newObj.invoice_positions.map(
            (position: CustomsDeclarationInvoicePosition) => ({
              volume: position.volume,
              invoice_position: position.invoice_position,
            }),
          );
        }

        return this.openModal(newObj);
      })
      .catch(errorHandler);
  }
}
CustomsCargoDeclarationService.$inject = [
  '$resource',
  '$uibModal',
  '$q',
  'CoreUtils',
  'gettext',
  'LogisticsService',
];
