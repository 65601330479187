import React from 'react';

import { Outlet as OriginOutlet } from '~/shared/lib/router';

import { Sidebar } from '../sidebar';

const Outlet = React.memo(OriginOutlet);

export const SidebarLayout: React.FC = React.memo(() => {
  return (
    <section className="flex">
      <Sidebar />
      <main className="scrollbar border-stroke-main-medium bg-background-main-primary flex h-screen w-screen flex-col overflow-x-auto border">
        <Outlet />
      </main>
    </section>
  );
});

SidebarLayout.displayName = 'SidebarLayout';
