import React from 'react';

import { SearchIcon } from '~/shared/ui/icons';

export const LocalSearch: React.FC = () => {
  return (
    <section className="my-0.5 ml-9 flex items-center gap-2">
      <SearchIcon size={16} className="text-text-main-secondary" />
      <input
        className="text-text-main-primary placeholder:text-text-main-tertiary w-[84%] bg-transparent text-xs focus:border-none focus:outline-0"
        placeholder="Search"
      />
    </section>
  );
};
