import template from './projects-status-report-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const ProjectsStatusReportPageView = {
  bindings: {},
  template,
  controller: [
    'PageService',
    'ReportsService',
    'gtFilterService',
    'gettext',
    class {
      PageService: PageService;
      ReportsService: ReportsService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      constructor(
        PageService: PageService,
        ReportsService: ReportsService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.filterLevel = 'projects-status-report-page-view';
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.queryParams = this.gtFilterService.getQueryParams(this.filterLevel);
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
        };

        config.filters = {
          filterLevel: this.filterLevel,
          clicked: false,
          search: true,
          dates: true,
          dateSelects: [
            {
              argument: 'conclusion_date',
              placeholder: this.gettext('Passport conclusion date'),
            },
            {
              argument: 'sale_contract_start_execution',
              placeholder: this.gettext('Sale start execution'),
            },
            {
              argument: 'sale_contract_end_execution',
              placeholder: this.gettext('Sale end execution'),
            },
            {
              argument: 'sale_invoice_date',
              placeholder: this.gettext('Sale invoice date'),
            },
            {
              argument: 'sale_payment_date',
              placeholder: this.gettext('Sale payment date'),
            },
          ],
          multiSelects: [
            {
              argument: 'fact_distributor_list',
              placeholder: this.gettext('fact suppliers/distributors'),
              resource: 'clients.supplier',
              getQueryParams: () => {
                return { is_distributor: 1 };
              },
            },
            {
              argument: 'currency_list',
              placeholder: this.gettext('currency'),
              resource: 'finances.currency',
            },
            {
              argument: 'businessunit_list',
              placeholder: this.gettext('business unit'),
              resource: 'core.businessunit',
            },
            {
              argument: 'cargo_list',
              placeholder: this.gettext('cargo'),
              resource: 'crops.crop',
            },
            {
              argument: 'sale_contract_list',
              placeholder: this.gettext('sale contracts'),
              resource: 'contracts.SaleContract',
            },
            {
              argument: 'sale_contract_buyer_list',
              placeholder: this.gettext('sale buyers'),
              resource: 'clients.buyer',
            },
            {
              argument: 'purchase_contract_list',
              placeholder: this.gettext('purchase contracts'),
              resource: 'contracts.PurchaseContract',
            },
            {
              argument: 'purchase_contract_supplier_list',
              placeholder: this.gettext('purchase suppliers'),
              resource: 'clients.supplier',
            },
            {
              argument: 'purchase_contract_basis_list',
              placeholder: this.gettext('purchase basis'),
              resource: 'logistics.basis',
            },
            {
              argument: 'purchase_contract_author_list',
              placeholder: this.gettext('Purchase author'),
              resource: 'auth.user',
            },
            {
              argument: 'purchase_contract_responsible_list',
              placeholder: this.gettext('Purchase responsible'),
              resource: 'auth.user',
            },
            {
              argument: 'purchase_contract_responsible_for_signing_list',
              placeholder: this.gettext('Purchase responsible for signing'),
              resource: 'auth.user',
            },
            {
              argument: 'purchase_contract_author_list',
              placeholder: this.gettext('Purchase author'),
              resource: 'auth.user',
            },
            {
              argument: 'purchase_contract_loyalty_program_list',
              placeholder: this.gettext('Purchase loyalty program'),
              resource: 'contracts.ContractOption',
            },
            {
              argument: 'purchase_contract_regional_manager_list',
              placeholder: this.gettext('Purchase sale manager'),
              resource: 'accounts.regionalmanager',
            },
            {
              argument: 'custom_status_list',
              placeholder: this.gettext('custom status'),
              resource: 'core.CustomStatus',
              queryParams: {
                content_type__model: 'passport',
              },
            },
            {
              argument: 'saporder_list',
              placeholder: this.gettext('sap order'),
              resource: 'contracts.saporder',
            },
            {
              argument: 'sale_contract_saporder_list',
              placeholder: this.gettext('sale sap order'),
              resource: 'contracts.saporder',
            },
            {
              argument: 'purchase_contract_saporder_list',
              placeholder: this.gettext('purchase sap order'),
              resource: 'contracts.saporder',
            },
          ],
          nestedSelects: [
            this.gtFilterService.getBoolFilter(
              'has_negative_balance',
              this.gettext('has negative balance'),
            ),
          ],
        };
        return config;
      }
    },
  ],
};
