import { financesCurrenciesList, financesCurrenciesPredictionsRetrieve } from '~/shared/api';
import { EntityRepository, type Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Currency, CurrencyQueryParams } from '../lib';

export class CurrencyRepository extends EntityRepository<Currency, CurrencyQueryParams> {
  readonly entityName = 'finances.Currency';

  getFn = (_id: number): Promise<Currency> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: Currency): Promise<Currency> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Currency>): Promise<Currency> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: CurrencyQueryParams): Promise<Paginated<Currency>> => {
    const { data } = await financesCurrenciesList({ query });
    return { records: data.results, count: data.count };
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await financesCurrenciesPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
}
