import type ng from 'angular';

import template from './invoice-risk-report-table-view.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';

export const InvoiceRiskReportTableView = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<?',
    data: '<',
    savedFilterChoices: '<?',
    currencies: '<',
  },
  template,
  controller: [
    'gettext',
    '$state',
    '$httpParamSerializer',
    '$window',
    'gtFilterService',
    class {
      $httpParamSerializer: any;
      $state: ng.ui.IStateService;
      $window: ng.IWindowService;
      currencies: any;
      filterLevel = 'invoice-risk-report-table-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      theadConfig: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        $state: ng.ui.IStateService,
        $httpParamSerializer: any,
        $window: ng.IWindowService,
        gtFilterService: GtFilterService,
      ) {
        this.gettext = gettext;
        this.$state = $state;
        this.$httpParamSerializer = $httpParamSerializer;
        this.$window = $window;
        this.gtFilterService = gtFilterService;
        this.theadConfig = {};
        this.currencies = [];
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.currencies = this.currencies || [];
      }

      $onChanges() {
        this.queryParams = { ...this.initQueryParams };
        this.updateTable();
      }

      getInvoiceListHref(idList: any) {
        const params = this.$httpParamSerializer({ id_list: idList, invoice_type: 'outgoing' });
        return this.$state.href('finances.invoices.list') + '?' + params;
      }

      getVoyageInvoiceListHref(idVoyage: number) {
        const params: {
          passport_voyage_list: number;
          invoice_type: string;
          use_list?: string;
          with_passport?: number;
          status_list?: string[];
        } = {
          passport_voyage_list: idVoyage,
          invoice_type: 'outgoing',
        };

        if (!idVoyage) {
          params.use_list = 'cargo';
          params.with_passport = 0;
          params.status_list = ['new', 'process'];
        }

        const paramString = this.$httpParamSerializer(params);
        return this.$state.href('finances.invoices.list') + '?' + paramString;
      }

      getVoyageListHref(name: string) {
        return this.$state.href('logistics.voyages') + '?search=' + name;
      }

      getGroupListHref(name: string) {
        if (this.initQueryParams.group_by == 'counterparty_name') {
          return this.$state.href('clients.roles') + '?search=' + name;
        }
        if (this.initQueryParams.group_by == 'counterpaty_bank_name') {
          return '/admin/finances/financebankgroup/?q=' + name;
        }
      }

      updateTable() {
        this.theadConfig = this.getTheadConfig();
      }

      downloadXlsxReport() {
        this.$window.open(
          `/api/reports/invoice-risk-report/?${this.$httpParamSerializer({
            ...this.gtFilterService.getQueryParams(this.filterLevel),
            as_xlsx: 1,
            page_size: 999,
          })}`,
          '_blank',
        );
      }

      getTheadConfig() {
        const config: any = { tabs: [], columns: [] };

        config.columns = [
          {
            title: this.gettext('Group by'),
            columnName: 'group_by',
            class: 'th-left-align',
          },
          {
            title: this.gettext('Voyage'),
            columnName: 'voyage',
            class: 'th-left-align',
          },
          {
            title: this.gettext('Loading / Destination'),
            columnName: 'date',
            class: 'th-left-align',
          },
          {
            title: this.gettext('Currency'),
            columnName: 'currency',
            class: 'th-left-align product',
          },
          // incoming
          {
            title: this.gettext('Amount'),
            columnName: 'amount',
            class: 'th-left-align',
          },
          {
            title: this.gettext('Paid'),
            columnName: 'paid',
            class: 'th-left-align',
          },
          {
            title: this.gettext('Balance'),
            columnName: 'balance',
            class: 'th-left-align',
          },
          // outgoing
          {
            title: this.gettext('Amount'),
            columnName: 'amount',
            class: 'th-left-align',
          },
          {
            title: this.gettext('Amount withdrawn'),
            columnName: 'withdrawn',
            class: 'th-left-align',
          },
          {
            title: this.gettext('Paid'),
            columnName: 'paid',
            class: 'th-left-align',
          },
          {
            title: this.gettext('Balance'),
            columnName: 'balance',
            class: 'th-left-align',
          },
        ];

        return config;
      }
    },
  ],
};
