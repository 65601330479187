import React from 'react';

import { ActionBar, type ActionBarConfig } from '~/shared/ui/components/action-bar';

export const SelectRecordToolbar: React.FC<{
  selectedCount: number;
  config: ActionBarConfig[];
}> = ({ selectedCount, config }) => {
  return (
    <ActionBar
      title={
        <span className="text-text-main-tertiary text-xs font-medium">
          {selectedCount} selected
        </span>
      }
      config={config}
    />
  );
};
