import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').config(configFunction);

  configFunction.$inject = ['$stateProvider', 'gettext'];

  function configFunction(
    $stateProvider: ng.ui.IStateProvider,
    gettext: ng.gettext.gettextFunction,
  ) {
    $stateProvider
      .state('logistics', {
        parent: 'gt.page',
        url: '/logistics',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('logistics.transport', {
        url: '/transport',
        component: 'logisticsPageView',
        resolve: {
          filterLevel: () => {
            return 'logistics-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('logistics-page-view');
            },
          ],
        },
        data: {
          pageTitle: gettext('Transport'),
          permissions: {
            only: 'view_logistic',
          },
        },
      })
      .state('logistics.problems', {
        url: '/problems',
        component: 'problemsPageView',
        resolve: {
          filterLevel: () => {
            return 'problems-page-view';
          },
          initQueryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('problems-page-view');
            },
          ],
        },
        data: {
          pageTitle: gettext('Unconnected'),
          permissions: {
            only: 'view_logistic',
          },
        },
      })
      .state('logistics.tariffs', {
        url: '/tariffs',
        abstract: true,
        template: '<ui-view/>',
        data: {
          pageTitle: gettext('Tariffs'),
          permissions: {
            only: 'view_clienttariff',
          },
        },
      })
      .state('logistics.tariffs.auto', {
        url: '/auto',
        component: 'tariffsPageView',
        resolve: {
          queryParams: () => {
            return { transportation_type: 'auto' };
          },
        },
        data: {
          pageTitle: gettext('Auto'),
          permissions: {
            only: 'view_clienttariff',
          },
        },
      })
      .state('logistics.tariffs.railway', {
        url: '/railway',
        component: 'tariffsPageView',
        resolve: {
          queryParams: () => {
            return { transportation_type: 'railway' };
          },
        },
        data: {
          pageTitle: gettext('Railway'),
          permissions: {
            only: 'view_clienttariff',
          },
        },
      })
      .state('logistics.tariffs.broker', {
        url: '/broker',
        component: 'tariffsPageView',
        resolve: {
          queryParams: () => {
            return { transportation_type: 'broker' };
          },
        },
        data: {
          pageTitle: gettext('Broker'),
          permissions: {
            only: 'view_clienttariff',
          },
        },
      })
      .state('logistics.tariffs.surveyor', {
        url: '/surveyor',
        component: 'tariffsPageView',
        resolve: {
          queryParams: () => {
            return { transportation_type: 'surveyor' };
          },
        },
        data: {
          pageTitle: gettext('Surveyor'),
          permissions: {
            only: 'view_clienttariff',
          },
        },
      })
      .state('logistics.tariffs.elevator', {
        url: '/elevator',
        component: 'tariffsPageView',
        resolve: {
          queryParams: () => {
            return { transportation_type: 'elevator' };
          },
        },
        data: {
          pageTitle: gettext('Elevator'),
          permissions: {
            only: 'view_clienttariff',
          },
        },
      })
      .state('logistics.tariffs.transshipment', {
        url: '/transshipment-tariff',
        component: 'transshipmentTariffPageView',
        data: {
          pageTitle: gettext('Transhipment'),
          permissions: {
            only: 'view_clienttariff',
          },
        },
      })
      .state('logistics.tariffs.freight', {
        url: '/freights',
        component: 'freightPageView',
        data: {
          pageTitle: gettext('Freights'),
          permissions: {
            only: 'view_clienttariff',
          },
        },
      })
      .state('logistics.warehouse', {
        url: '/warehouse/',
        component: 'warehousePageView',
        resolve: {
          filterLevel: () => {
            return 'warehouse-page-view';
          },
          initQueryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('warehouse-page-view');
            },
          ],
        },
        data: {
          pageTitle: gettext('Warehouse'),
        },
      })
      .state('logistics.warehousetrade', {
        url: '/warehouse-trade',
        component: 'warehouseTradePageView',
        data: {
          pageTitle: gettext('Warehouse Trade'),
        },
      })
      .state('logistics.warehouseRests', {
        url: '/warehouse-rests-report/',
        component: 'warehousePageView',
        data: {
          pageTitle: gettext('Warehouse Rests Report'),
        },
        resolve: {
          filterLevel: () => {
            return 'warehouse-rests-page-view';
          },
          isWarehouseRestPage: () => {
            return true;
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('warehouse-rests-report-page-view');
            },
          ],
        },
      })
      .state('logistics.vessels', {
        url: '/vessels',
        component: 'vesselsPageView',
        resolve: {
          filterLevel: () => {
            return 'vessels-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('vessels-page-view');
            },
          ],
        },
        data: {
          pageTitle: gettext('Vessels'),
          permissions: {
            only: 'view_logistic',
          },
        },
      })
      .state('gt.old-page.vessel', {
        url: '/vessels/:id',
        component: 'vesselPage',
        data: {
          pageTitle: gettext('Vessels'),
          permissions: {
            only: 'view_vessel',
          },
        },
      })
      .state('logistics.voyages', {
        url: '/voyages',
        component: 'voyagesPageView',
        resolve: {
          filterLevel: () => {
            return 'voyages-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('voyages-page-view');
            },
          ],
        },
        data: {
          pageTitle: gettext('Voyages'),
          permissions: {
            only: 'view_vessel',
          },
        },
      })
      .state('logistics.voyages.modal', {
        url: '/:id',
        params: {
          id: null,
        },
        onEnter: [
          '$stateParams',
          '$window',
          'LogisticsService',
          'gtFilterService',
          function (
            $stateParams: ng.ui.IStateParamsService,
            $window: ng.IWindowService,
            LogisticsService: LogisticsService,
            gtFilterService: GtFilterService,
          ) {
            LogisticsService.voyageModal({ id: $stateParams.id }).then(function (data: any) {
              if (!data || data == 'cancel') {
                $window.history.back();
              } else {
                gtFilterService.updateQueryParams({}, 'voyages-page-view');
                $window.history.back();
              }
            });
          },
        ],
      });
  }
})();
