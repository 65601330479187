import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import { useComponent } from '~/shared/lib/components';

import type { UserListContainerProps } from './user-list-container.model';
import { UserListContainerModel } from './user-list-container.model';

export const UserListContainer = (props: UserListContainerProps) => {
  const { ComponentProvider } = useComponent(props, UserListContainerModel);

  return (
    <ComponentProvider>
      <EntityListContainer />;
    </ComponentProvider>
  );
};
