import * as Sentry from '@sentry/react';

import { notify } from '~/shared/lib/notify';

export const errorHandler = (data: unknown) => {
  console.info('Error:', data);
  try {
    const content = parseError(data);
    const message = renderMessage(content);
    notify(message, 'error');
  } catch (error) {
    Sentry.captureException(error);
    console.error('errorHandler:', error);
  }
};

export type ErrorContent = { key: string; value: string[] };

const isError = (data: unknown): data is Error => {
  const _data = data as Error;
  return _data && typeof _data === 'object' && 'message' in _data && 'stack' in _data;
};

const isZodError = (data: unknown): data is { name: string; message: string } => {
  return !!data && typeof data === 'object' && 'name' in data && data.name === 'ZodError';
};
const isObject = (data: unknown): data is Record<string, unknown> =>
  typeof data === 'object' && data !== null && !Array.isArray(data);

const isString = (data: unknown): data is string => typeof data === 'string';

/**
 * encoding:
 * {a} - array
 * {s} - string
 * {d} - dict
 * {v} - value
 * {e} - error
 */
const encodeMessage = (data: unknown): string => {
  let message = '';

  if (Array.isArray(data)) {
    message = '{a}' + data.map((i) => `${encodeMessage(i)}`).join('');
  } else if (isString(data)) {
    message = '{s}' + (data.includes('</') ? 'Server Error' : `${data}`);
  } else if (isZodError(data)) {
    message += '{d}{v}API Error: Invalid response format';
  } else if (isError(data)) {
    message = `${data.message}`;
    if ('body' in data && data.body) {
      message += `${encodeMessage(data.body)}`;
    }
    if (data.cause) {
      message += `{d}{e}{v}${encodeMessage(data.cause)}`;
    }
  } else if (isObject(data)) {
    const stripKeys = ['message', 'detail', 'data', 'body'];
    const excludeKeys = ['status', 'config', 'statusText', 'xhrStatus', 'resource', 'headers'];

    message = Object.entries(data)
      .filter(([key, value]) => key && value && !excludeKeys.includes(key))
      .map(([key, value]) =>
        stripKeys.includes(key) ? encodeMessage(value) : `{d}${key}{v}${encodeMessage(value)}`,
      )
      .join('');
  } else {
    message = `{s}${JSON.stringify(data)}`;
  }

  if (!message) {
    message = '{s}An error occurred. We are working on it. Please try again later.';
  }

  return message;
};

export const parseError = (data: unknown): ErrorContent[] => {
  const encodedStr = encodeMessage(data);
  const result = [];
  for (const d of encodedStr.split('{d}').filter(Boolean)) {
    const [key, v] = d.includes('{v}') ? d.split('{v}') : ['', d];
    for (const a of v.split('{a}').filter(Boolean)) {
      result.push({ key, value: a.split('{s}').filter(Boolean) });
    }
  }
  return result;
};

export const renderMessage = (content: ErrorContent[]): string => {
  return content
    .map(({ key, value }) => {
      const keyStr = (key && key == '{e}' && '🔗 ') || (key && `⚠️ ${key}: `) || '';
      const valStr = value.length > 1 ? value.map((s) => `🔴 ${s}`) : value;
      return `${keyStr}${valStr.join(' ')}`;
    })
    .join(' ');
};
