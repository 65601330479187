import type ng from 'angular';

import template from './gt-inline-filter.tpl.html?raw';

import { type GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import { type GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

class GtInlineFilterController implements ng.IController {
  type:
    | 'roleType'
    | 'crop'
    | 'search'
    | 'contract-type'
    | 'confirmation-status'
    | 'invoice-type'
    | 'checkbox' = 'search';
  filterLevel = '';
  contractStage?: string;
  predicateBool?: boolean;
  title?: string;
  cropParams?: any;
  view?: string;
  disabledSearch?: boolean;
  keyName = '';
  searchPrompt?: string;
  queryParams: QueryParams & { confirmation_status_list?: string[] };
  selectedCrop: any;
  selectedRoleType: any;
  crops: any[];
  messageTypes: any[];
  confirmationStatuses: any[];
  roleTypeChoices: { id: string; title: string; icon: string }[];
  paramsKey?: string;

  static readonly $inject = ['$scope', 'gettext', 'gtFilterService', 'CropsService', 'GtUtils'];

  constructor(
    private readonly $scope: ng.IScope,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly gtFilterService: GtFilterService,
    private readonly CropsService: any,
    private readonly GtUtils: GtUtilsService,
  ) {
    this.queryParams = {};
    this.selectedCrop = {};
    this.selectedRoleType = {};
    this.crops = [];
    this.messageTypes = [
      { param: 'call', title: this.gettext('Call'), icon: 'fa-phone-square' },
      { param: 'email', title: this.gettext('Email'), icon: 'fa-envelope' },
      { param: 'meet', title: this.gettext('Meet'), icon: 'fa-map-marker' },
      { param: 'task', title: this.gettext('Task'), icon: 'fa-tasks' },
      { param: 'commentary', title: this.gettext('Commentary'), icon: 'fa-commenting' },
    ];

    this.confirmationStatuses = [
      { param: 'process', title: this.gettext('In process'), icon: 'fa-spin fa-spinner' },
      {
        param: 'approved_condition',
        title: this.gettext('Approved condition'),
        icon: 'fa-check-circle',
      },
      { param: 'success', title: this.gettext('Success'), icon: 'fa-check-circle' },
      { param: 'fail', title: this.gettext('Fail'), icon: 'fa-times' },
    ];

    this.roleTypeChoices = [
      { id: 'buyer', title: this.gettext('Buyer'), icon: this.GtUtils.getIcon('clients.Buyer') },
      {
        id: 'supplier',
        title: this.gettext('Supplier'),
        icon: this.GtUtils.getIcon('clients.Supplier'),
      },
      {
        id: 'exporter',
        title: this.gettext('Exporter'),
        icon: this.GtUtils.getIcon('clients.Exporter'),
      },
      {
        id: 'deliverer',
        title: this.gettext('Forwarder'),
        icon: this.GtUtils.getIcon('clients.Deliverer'),
      },
      {
        id: 'elevator',
        title: this.gettext('Elevator'),
        icon: this.GtUtils.getIcon('clients.Elevator'),
      },
      { id: 'farm', title: this.gettext('Farm'), icon: this.GtUtils.getIcon('clients.Farm') },
      {
        id: 'surveyor',
        title: this.gettext('Surveyor'),
        icon: this.GtUtils.getIcon('clients.Surveyor'),
      },
      { id: 'broker', title: this.gettext('Broker'), icon: this.GtUtils.getIcon('clients.Broker') },
      {
        id: 'insurer',
        title: this.gettext('Insurer'),
        icon: this.GtUtils.getIcon('clients.Insurer'),
      },
      { id: 'owner', title: this.gettext('Owner'), icon: this.GtUtils.getIcon('clients.Owner') },
      { id: 'bank', title: this.gettext('Bank'), icon: this.GtUtils.getIcon('clients.Bank') },
      { id: 'other', title: this.gettext('Other'), icon: this.GtUtils.getIcon('clients.Other') },
    ];
  }

  $onInit() {
    if (this.filterLevel == '') {
      throw new Error('Filter level is required');
    }

    this.cropParams = this.cropParams || {
      prices_section: '1',
      is_analytic_parent: '0',
      page_size: 999,
    };
    if (this.type == 'crop') {
      this.getCrops();
    }
    this.paramsKey = this.keyName || 'crop_type';
    this.queryParams = this.gtFilterService.getQueryParams(this.filterLevel);
    this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
      this.queryParams = data;
    });
  }

  getCrops() {
    this.CropsService.getMainCrops(this.cropParams).then((crops: any) => {
      this.crops = crops;
    });
  }

  selectRoleType(roleType: { id: string; title: string; icon: string }) {
    if (this.queryParams.role_name_list == roleType.id) {
      this.queryParams.role_name_list = undefined;
      this.selectedRoleType = {};
    } else {
      this.queryParams.role_name_list = [roleType.id];
    }
    this.applyFilter();
  }

  selectCrop(crop: any) {
    if (this.paramsKey && this.queryParams[this.paramsKey] == crop.id) {
      this.queryParams[this.paramsKey] = crop.parent || undefined;
      this.selectedCrop = {};
    } else if (this.paramsKey) {
      if (!crop.parent) {
        this.selectedCrop = crop;
      }
      this.queryParams[this.paramsKey] = crop.id;
    }
    this.applyFilter();
  }

  selectMessageType(messageType: any) {
    if (this.queryParams.message_type == messageType) {
      this.queryParams.message_type = undefined;
    } else {
      this.queryParams.message_type = messageType;
    }
    this.applyFilter();
  }

  selectConfirmationStatus(confirmationStatus: any) {
    if (!this.queryParams.confirmation_status_list) {
      this.queryParams.confirmation_status_list = [];
    }
    if (this.queryParams.confirmation_status_list.includes(confirmationStatus)) {
      this.queryParams.confirmation_status_list.splice(
        this.queryParams.confirmation_status_list.indexOf(confirmationStatus),
        1,
      );
    } else {
      this.queryParams.confirmation_status_list.push(confirmationStatus);
    }
    if (!this.queryParams.confirmation_status_list.length) {
      delete this.queryParams.confirmation_status_list;
    }
    this.applyFilter();
  }

  applyFilter() {
    this.queryParams.page = undefined;
    this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
  }
}

export const gtInlineFilter: ng.IComponentOptions = {
  bindings: {
    type: '@',
    filterLevel: '<',
    contractStage: '@?',
    predicateBool: '<?',
    title: '<?',
    cropParams: '<?',
    view: '<?',
    disabledSearch: '<?',
    keyName: '<',
    searchPrompt: '<?',
  },
  template: template,
  controller: GtInlineFilterController,
};
