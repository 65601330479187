import template from './basic-metrics-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const BasicMetricsPage = {
  bindings: {},
  template,
  controller: [
    'PageService',
    'ReportsService',
    'GtUtils',
    class {
      GtUtils: GtUtilsService;
      PageService: PageService;
      ReportsService: ReportsService;
      counters: any;
      constructor(
        PageService: PageService,
        ReportsService: ReportsService,
        GtUtils: GtUtilsService,
      ) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.GtUtils = GtUtils;

        this.counters = {};
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.updateCounters();
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [],
          pages: this.ReportsService.getPagesConfig(),
          filters: {},
        };
      }
      updateCounters() {
        const modelsForCounters: any = [
          'logistics.Logistic',
          'logistics.voyage',
          'logistics.billoflading',
          'logistics.logistic',
          'logistics.vessel',

          'contracts.indicator',
          'contracts.ticket',
          'contracts.request',
          'contracts.purchasecontract',
          'contracts.salecontract',
          'contracts.multicontract',

          'documents.document',

          'clients.client',
          'clients.clientupdate',
          'clients.person',

          'auth.user',
          'passports.passport',

          'finances.finance',
          'finances.payment',
          'finances.bankaccount',
        ];

        this.GtUtils.getCounters(modelsForCounters)
          .then((data: any) => {
            this.counters = data;
            this.updateRequestCounters();
          })
          .catch(this.GtUtils.errorClb);
      }

      updateRequestCounters() {
        this.GtUtils.getCounters(['contracts.request'], { stage: 'ticket' })
          .then((data: any) => {
            this.counters['contracts.ticket'] = data['contracts.request'];
            this.counters['contracts.indicator'] =
              this.counters['contracts.request'] - this.counters['contracts.ticket'];
          })
          .catch(this.GtUtils.errorClb);
      }
    },
  ],
};
