import ng from 'angular';

import { formatDate } from '~/shared/lib/date';
import { notify } from '~/shared/lib/notify';

import template from './request-table-cols.tpl.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('deals.requests.legacy').directive('requestTableCols', directive);

  function directive() {
    return {
      template,
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        request: '=',
        mode: '@?',
        mainBlock: '@?',
        financeBlock: '@?',
        block: '=?',
        onUpdate: '&?',
      },
    };
  }

  Controller.$inject = [
    '$state',
    'ContractsService',
    'FinancesService',
    'ClientsService',
    'DocumentsService',
    'GtUtils',
    'AccountsService',
    'gettext',
  ];

  function Controller(
    this: any,
    $state: ng.ui.IStateService,
    ContractsService: ContractsService,
    FinancesService: FinancesService,
    ClientsService: any,
    DocumentsService: any,
    GtUtils: GtUtilsService,
    AccountsService: AccountsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.mode = vm.mode || 'full';
    vm.tab = 'info';
    vm.openRequestModal = openRequestModal;
    vm.createContract = createContract;
    vm.tariffs = [];
    vm.updatePrices = updatePrices;
    vm.openEmailModal = openEmailModal;
    vm.openRoleModal = openRoleModal;
    vm.openDocxModal = openDocxModal;
    vm.createTicket = createTicket;
    vm.clone = clone;
    vm.prices = [];
    vm.reverse = reverse;
    vm.approve = approve;
    vm.openContractModal = openContractModal;
    vm.createPrepayment = createPrepayment;
    vm.currency = 'local'; // can be: 'usd' || 'local'
    vm.convert = convert;
    vm.convert_opposite = convertOpposite;
    vm.curSign = curSign;
    vm.createPassport = createPassport;
    vm.openMarketToMarketModal = openMarketToMarketModal;
    vm.approvalAction = approvalAction;

    vm.connectToPassport = connectToPassport;
    vm.passportConnectShow = false;
    vm.passportConnectId = null;
    vm.passportConnectParams = { cargo: vm.request.cargo };

    vm.diffDays = diffDays;

    ////////////////

    function updateData() {
      GtUtils.overlay('show');
      return ContractsService.Request.get(
        {
          id: vm.request.id,
          serializer: 'full',
        },
        function (data: any) {
          vm.request = data;
          GtUtils.overlay('hide');
        },
      ).$promise;
    }

    function diffDays(firstDate: any, secondDate: any) {
      if (!firstDate || !secondDate) {
        return '--';
      }
      return Math.round(
        Math.abs((firstDate.getTime() - secondDate.getTime()) / (24 * 60 * 60 * 1000)),
      ).toString();
    }

    function updatePrices() {
      return ContractsService.Request.prices({ id: vm.request.id }, function (data: any) {
        vm.prices = data;
      }).$promise;
    }

    function openRequestModal(request: any) {
      return ContractsService.requestModal(request).then(function () {
        updateData();
        vm.onUpdate();
      });
    }

    function createContract() {
      if (!confirm(gettext('Do you want to create contract?'))) {
        return;
      }
      return updateData().then(function () {
        if (vm.request.stage == 'prepayment') {
          return ContractsService.contractModal({
            cargo: vm.request.cargo,
            volume: vm.request.volume,
            regional_manager: vm.request.regional_manager,
            contract_type: 'purchase',
            from_request: vm.request.id,
            contract_number:
              vm.request.contract_type.charAt(0).toUpperCase() +
              formatDate(new Date(), 'yyyyMMdd') +
              '-' +
              vm.request.crop_title,
          });
        }
        GtUtils.overlay('show');
        return ContractsService.Request.createContract(
          {
            id: vm.request.id,
          },
          function (data: any) {
            GtUtils.dismissAllModals();
            GtUtils.overlay('hide');
            $state.go('gt.page.contract', { id: data.id });
          },
        ).$promise;
      });
    }

    function openEmailModal() {
      return ClientsService.sendEmailModal('Request', vm.request.id);
    }

    function openDocxModal() {
      return DocumentsService.generateDocxModal('Request', vm.request.id);
    }

    function openRoleModal(id: number, model: any) {
      return ClientsService.roleModal({ id: id, model_name: model });
    }

    function createTicket() {
      return ContractsService.createTicket(vm.request);
    }

    function clone() {
      return ContractsService.cloneRequest(vm.request).then(vm.onUpdate);
    }

    function reverse() {
      if (!confirm(gettext('Do you want to reverse request?'))) {
        return;
      }
      return ContractsService.Request.get(
        {
          id: vm.request.id,
          serializer: 'full',
        },
        function (data: any) {
          return ContractsService.reverseDeal(data);
        },
      ).$promise;
    }

    function approve() {
      return ContractsService.approveDeal(vm.request).then(updateData);
    }

    function openContractModal(contract: any) {
      return ContractsService.contractModal(contract);
    }

    function convert(value: any) {
      value = parseFloat(value);
      let result =
        (vm.currency == 'usd' && value) || value * (vm.request.currency_exchange_rate || 1);

      if (vm.currency == 'usd' && vm.request.VAT_option) {
        result /= 1.2;
      }

      return result;
    }

    function convertOpposite(value: any) {
      value = parseFloat(value);
      return (
        (vm.currency == 'usd' && value) || value * (vm.request.exchange_calculated_opposite || 1)
      );
    }

    function curSign() {
      return vm.currency == 'usd' ? '$' : vm.request.currency;
    }

    function createPrepayment() {
      return updateData().then(function () {
        const finance = {
          use: 'prepayment',
          regional_manager: vm.request.regional_manager,
          amount: vm.request.price * vm.request.volume,
          number: '',
        };
        return AccountsService.RegionalManager.details(
          {
            id: vm.request.regional_manager,
          },
          function (data: any) {
            finance.number =
              data.first_name + ' ' + data.last_name + ' ' + data.curator_first_name + ' ';
            return GtUtils.getCounters(['finances.Finance'], {
              regional_manager: vm.request.regional_manager,
            }).then(function (data: any) {
              finance.number += data['finances.Finance'] + 1;
              return FinancesService.financeModal(finance);
            });
          },
        );
      });
    }

    function openMarketToMarketModal() {
      const params = {
        stage: vm.request.stage,
        contract_type: 'sale',
        cargo: vm.request.cargo,
        ordering: '-price_in_USD',
        min_price: 0.01,
        min_volume: 0.01,
        active: 1,
      };
      if (vm.request.contract_type == 'sale') {
        params.contract_type = 'purchase';
      }
      return ContractsService.requestModalQuick(params, { quickAdd: false });
    }

    function createPassport() {
      return updateData().then(function () {
        const passport: any = {
          title: vm.request.id,
          cargo: vm.request.cargo,
          total_volume_plan: vm.request.volume,
        };
        passport[vm.request.contract_type + '_plans'] = [
          {
            request: vm.request.id,
            volume: vm.request.volume,
          },
        ];
        return ContractsService.passportModal(passport).then(function (data: any) {
          if (!data || data == 'cancel') {
            return;
          }
          data[vm.request.contract_type + '_plans'] = [
            {
              request: { id: vm.request.id },
              volume: vm.request.volume,
            },
          ];
          return ContractsService.Passport.update(data, function (data: any) {
            $state.go('gt.page.passport', { id: data.id });
          }).$promise;
        });
      });
    }

    function connectToPassport() {
      return ContractsService.connectToPassport({
        id: vm.passportConnectId,
        deal_id: vm.request.id,
        stage: 'request',
        type: vm.request.contract_type,
        volume: vm.request.volume,
      }).then(
        function () {
          updateData();
          return notify(gettext('Passport updated'));
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }

    function approvalAction(approve: any) {
      let whenApproval;

      if (approve) {
        whenApproval = AccountsService.approveObject('Request', vm.request.id);
      } else {
        whenApproval = AccountsService.declineObject('Request', vm.request.id);
      }

      whenApproval.then(
        function (approval: any) {
          vm.request.approval = approval;
          notify(gettext('Approval updated'));
        },
        function (data: any) {
          GtUtils.errorClb(data);
        },
      );
    }
  }
})();
