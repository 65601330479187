import type ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import template from './warehouse-balance-table-view.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const WarehouseBalanceTableView = {
  bindings: {
    reportData: '<',
    verticalGroups: '<',
    horizontalGroups: '<',
    filterLevel: '<?',
  },
  template,
  controller: [
    'gettext',
    'GtUtils',
    class {
      GtUtils: GtUtilsService;
      aggsConfig: any;
      aggsTypesConfig: any;
      columns: any;
      count: number;
      filterLevel: string;
      flatData: any;
      flatTotals: any;
      gettext: ng.gettext.gettextFunction;
      groups: any;
      horizontalGroups: any;
      reportData: any;
      resources: any;
      resourcesConfig: any;
      tableName = '';
      verticalGroups: any;
      constructor(gettext: ng.gettext.gettextFunction, GtUtils: GtUtilsService) {
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.reportData = {};
        this.flatData = [];
        this.flatTotals = [];
        this.groups = [];
        this.verticalGroups = [];
        this.horizontalGroups = [];
        this.count = 0;
        this.filterLevel = '';
        this.columns = [];
        this.resources = [];
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'warehouse-balance-table-view';
        this.tableName = this.tableName || this.filterLevel;
      }
      buildConfig(arr: any, selectedFunc?: any) {
        selectedFunc =
          selectedFunc || ((idx: number, arr: any) => idx === 0 || idx === arr.length - 1);
        return arr.reduce(
          (res: any, item: any, idx: number, arr: any) => [
            ...res,
            {
              key: item[0],
              title: item[1],
              selected: selectedFunc(idx, arr),
              class: this.getConfigClass(item[0]),
            },
          ],
          [],
        );
      }
      buildAggsConfig(resources: any, aggregates: any) {
        return resources.reduce(
          (aggs: any, res: any) => [
            ...aggs,
            ...aggregates.map((agg: any) => ({
              key: [res.key, agg.key].join('_'),
              title: [res.key, agg.key].join(': '),
              class: agg.class,
            })),
          ],
          [],
        );
      }
      $onChanges() {
        if (this.reportData?.total) {
          this.updateTable();
        }
      }
      updateTable() {
        this.resourcesConfig = this.buildConfig(this.reportData.resources, (idx: number) => !idx);
        this.aggsTypesConfig = this.buildConfig(this.reportData.aggregates);
        this.aggsConfig = this.buildAggsConfig(this.resourcesConfig, this.aggsTypesConfig);
        this.buildFlatTotals();
        this.groups = [...this.verticalGroups, ...this.horizontalGroups].sort((a, b) =>
          a.localeCompare(b),
        );
        this.updateView();
      }
      buildFlatTotals() {
        this.flatTotals = [this.reportData.total, ...this.reportData.totals].reduce(
          (res, totals) => [
            ...res,
            ...totals.data.map((total: any) => ({
              totalsKey: JSON.stringify([...totals.groups].sort((a, b) => a.localeCompare(b))),

              totalKey: JSON.stringify(
                [...totals.groups]
                  .sort((a, b) => a.localeCompare(b))
                  .reduce((res, field) => {
                    res[field] = total[field]?.pk;
                    return res;
                  }, {}),
              ),

              level: totals.level,
              groups: totals.groups.sort(),
              fields: [...totals.groups, ...this.aggsConfig.map((v: any) => v.key)],

              ...totals.groups.reduce((fields: any, field: any) => {
                fields[field] = total[field];
                return fields;
              }, {}),

              ...this.aggsConfig.reduce((aggs: any, agg: any) => {
                aggs[agg.key] = total[agg.key] || 0;
                return aggs;
              }, {}),
            })),
          ],
          [],
        );
      }
      updateView() {
        this.buildColumns();
        this.prepareTable();
      }
      getRowLevelClass(level: number | string) {
        return {
          'highlighted-tr-level-0': level === 0,
          'highlighted-tr-level-1': level === 1,
          'highlighted-tr-level-2': level === 2,
          'highlighted-tr-level-3': level === 3,
          'highlighted-tr-level-4': level === 4,
          'highlighted-tr-level-5': level === 5,
          'details-info': level === 'detail',
        };
      }
      buildHorizontalRow(total: any, parent: any) {
        let row = {
          level: total.level,
          expand: total.level === 0,
          visible: total.level <= 1,
          levelClass: this.getRowLevelClass(total.level),
          title: (total.level && this.horizontalGroups[total.level - 1]) || 'TOTAL',
          name: (total.level && this.horizontalGroups[total.level - 1]) || 'TOTAL',
          groups: total.groups.sort(),
          groupsKey: JSON.stringify(
            total.groups.sort().reduce((res: any, gr: any) => {
              res[gr] = total[gr]?.pk;
              return res;
            }, {}),
          ),
          parent: parent,
          children: [],
          TOTAL: total,
          ...total.groups.reduce((groups: any, group: any) => {
            groups[group] = total[group];
            return groups;
          }, {}),
        };
        row.title = !parent ? 'TOTAL' : total[row.name]?.title || '<...>';
        const cells = this.columns
          .slice(-1)
          .flat()
          .reduce((cells: any, column: any) => {
            cells[column.name] = this.cellData(row, column)[column.name];
            return cells;
          }, {});
        row = { ...row, ...cells };
        parent?.children.push(row);
        return row;
      }
      getDetailRows(_level: number, parent: any) {
        parent.children = this.reportData.details
          .filter((detail: any) =>
            this.horizontalGroups.every(
              (group: any) => JSON.stringify(detail[group]) === JSON.stringify(parent[group]),
            ),
          )
          .map((detail: any) => ({
            ...detail,
            level: parent.level + 1,
            detailsRow: true,
            expand: false,
            visible: false,
            levelClass: this.getRowLevelClass('detail'),
            title: `[${formatDate(detail.period, 'dd.MM.yyyy')}] - ${detail.recorder?.title}`,
            sref: detail.recorder?.sref,
            href: detail.recorder?.href,

            detailsClass: ({ receipt: 'label_success', dispatch: 'label_danger' } as any)[
              detail.record_type
            ],

            detailsStamp: detail.record_type,

            stamps: [
              {
                text: detail.record_type,
                class: ({ receipt: 'label_success', dispatch: 'label_danger' } as any)[
                  detail.record_type
                ],
              },
              ...this.resourcesConfig
                .filter((res: any) => Boolean(detail[res.key]) && Number(detail[res.key]))
                .map((res: any) => ({
                  text: res.title,
                  class: 'label_info',
                })),
            ],

            name: 'details',
            groups: [...this.horizontalGroups].sort((a, b) => a.localeCompare(b)),
            parent: parent,
            children: [],
            TOTAL: detail,
          }))
          .map((row: any) => ({
            ...row,

            ...this.columns
              .slice(-1)
              .flat()
              .filter((column: any) => column.agg.key === row.record_type)
              .filter((column: any) =>
                this.verticalGroups.every(
                  (group: any) => JSON.stringify(row[group]) === JSON.stringify(column[group]),
                ),
              )
              .reduce((cells: any, column: any) => {
                cells[column.name] = row[column.parent.resource.key];
                return cells;
              }, {}),
          }))
          .reduce((res: any, row: any) => [...res, row], []);
        return parent.children;
      }
      addHorizontalRow(level = 0, parent: any = null) {
        if (level > this.horizontalGroups.length) {
          return this.getDetailRows(level + 1, parent);
        }
        return this.flatTotals
          .filter(
            (total: any) =>
              total.totalsKey ===
              JSON.stringify(
                [...this.horizontalGroups.slice(0, level)].sort((a, b) => a.localeCompare(b)),
              ),
          )
          .filter(
            (total: any) =>
              parent === null ||
              parent.groupsKey ===
                JSON.stringify(
                  parent.groups.reduce((res: any, gr: any) => {
                    res[gr] = total[gr]?.pk;
                    return res;
                  }, {}),
                ),
          )
          .sort((a: any, b: any) => this.sortFunc(a, b, this.horizontalGroups[level - 1]))
          .reduce((res: any, total: any) => {
            const row = this.buildHorizontalRow(total, parent);
            row.sref = row[row.name]?.sref;
            row.href = row[row.name]?.href;
            res = [...res, row, ...this.addHorizontalRow(level + 1, row)];
            return res;
          }, []);
      }
      sortFunc(a: any, b: any, field: any) {
        if (!a?.[this.horizontalGroups[field]]) {
          return 1;
        }
        if (!b?.[this.horizontalGroups[field]]) {
          return -1;
        }
        return a[this.horizontalGroups[field]].title > b[this.horizontalGroups[field]].title
          ? 1
          : -1;
      }
      prepareTable() {
        this.flatData = this.addHorizontalRow();
      }
      toggleExpand(axis: any, expand: any) {
        if (axis.groups.some((group: any) => this.horizontalGroups.includes(group))) {
          this.toggleExpandRow(axis, expand);
        }
        if (axis.groups.some((group: any) => this.verticalGroups.includes(group))) {
          this.toggleExpandColumn(axis, expand);
          this.calcStyles();
        }
      }
      toggleExpandRow(row: any, expand: any) {
        row.expand = expand;
        row.children?.forEach((child: any) => {
          child.visible = expand;
          if (!expand) {
            this.toggleExpandRow(child, expand);
          }
        });
      }
      toggleExpandColumn(column: any, expand: any) {
        column.expand = expand;
        if (!column.children?.length) {
          return;
        }
        const flatColumns = this.columns.flat();

        column.children
          .filter(() => expand)
          .forEach((child: any) => {
            child.visible = true;
            flatColumns
              .filter((subChild: any) => child.totalKey === subChild.totalKey)
              .forEach((subChild: any) => (subChild.visible = true));
          });
        flatColumns
          .filter(() => !expand)
          .filter((child: any) =>
            column.groups.every((group: any) => column[group]?.pk === child[group]?.pk),
          )
          .forEach((child: any) => (child.visible = column.totalKey === child.totalKey));
      }
      addResourcesColumnRows() {
        this.columns.push(
          this.columns
            .slice(-1)
            .flat()
            .reduce((row: any, column: any) => {
              column.children = this.resourcesConfig.map((res: any) => ({
                ...column,
                parent: column,
                subTotal: false,
                class: 'column-resource',
                name: `${column.name}_${res.key}`,
                resource: res,
                resourceKey: res.key,
                title: res.title,
              }));
              return [...row, ...column.children];
            }, [])
            .map((col: any, index: number) => {
              col.name = `column-${index}`;
              return col;
            }),
        );
        this.columns.push(
          this.columns
            .slice(-1)
            .flat()
            .reduce((row: any, column: any) => {
              column.children = this.aggsTypesConfig.map((agg: any) => ({
                ...column,
                class: `column-resource-agg ${agg.class}`,
                width: 90,
                'min-width': 30,
                'max-width': 120,
                parent: column,
                name: `${column.name}_${agg.key}`,
                title: agg.title,
                resource: column.resource,
                resourceKey: `${column.resource.key}_${agg.key}`,
                agg: agg,
              }));
              column.width = column.children.reduce((r: any, v: any) => r + v.width);
              column['min-width'] = column.children.reduce((r: any, v: any) => r + v['min-width']);
              column['max-width'] = column.children.reduce((r: any, v: any) => r + v['max-width']);
              return [...row, ...column.children];
            }, []),
        );
      }
      getConfigClass(key: any) {
        return (
          (
            {
              dispatch: 'negative-number',
              receipt: 'positive-number',
              turnover: 'number',
              start_balance: '',
              end_balance: '',
            } as any
          )[key] || ''
        );
      }
      buildColumnsRow(result: any, group: any) {
        const parentRow = result.slice(-1).pop();
        if (!parentRow) {
          result = [
            group.totals.map((item: any) => ({
              ...item,
              name: `${group.group}_${item[group.group].pk}`,
              sref: item[group.group]?.sref,
              href: item[group.group]?.href,
              class: 'column-vertical-group',
              title: item[group.group].title,
              parent: null,
              groups: group.groups,
              groupsKey: JSON.stringify(group.groups),
              visible: true,
            })),
          ];
          return result;
        }
        result.push(
          parentRow.reduce((res: any, parent: any) => {
            if (parent.subTotal) {
              parent.children = [{ ...parent, childrenCount: 1 }];
              return [...res, ...parent.children];
            }
            parent.children = [
              {
                ...parent,
                class: 'column-vertical-group',
                title: `Total ${parent.title}`,
                parent: parent,
                subTotal: true,
                expand: false,
                visible: true,
                hide: false,
              },
              ...group.totals
                .filter((item: any) =>
                  parent.groups.every(
                    (gr: any) =>
                      (item[gr] === null && parent[gr] === null) || item[gr]?.pk === parent[gr]?.pk,
                  ),
                )
                .map((item: any) => ({
                  ...item,
                  name: `${group.group}_${item[group.group].pk}`,
                  sref: item[group.group]?.sref,
                  href: item[group.group]?.href,
                  class: 'column-vertical-group',
                  title: item[group.group].title,
                  parent: parent,
                  groups: group.groups,
                  groupsKey: JSON.stringify(group.groups),
                  visible: false,
                })),
            ];
            parent.childrenCount = parent.children.length;
            return [...res, ...parent.children];
          }, []),
        );
        return result;
      }
      buildColumns() {
        this.columns = [];
        this.columns = this.verticalGroups
          .map((group: any, level: string, self: any) => ({
            group: group,
            groups: self.slice(0, level + 1),

            totals: this.flatTotals
              .filter(
                (total: any) => total.totalsKey === JSON.stringify(self.slice(0, level + 1).sort()),
              )
              .sort((a: any, b: any) => this.sortFunc(a, b, group)),
          }))
          .reduce((result: any, group: any) => this.buildColumnsRow(result, group), this.columns);
        this.addTotalsColumn();
        this.addResourcesColumnRows();
        this.calcStyles();
      }
      addTotalsColumn() {
        const total = {
          ...this.reportData.total,
          name: 'total',
          title: 'TOTAL',
          parent: null,
          groups: [],
          total: true,
          visible: true,
          hide: false,
        };
        this.verticalGroups
          .filter((item: any, index: number) => index)
          .reduce(
            (res: any) => {
              const parent = res.flat().slice(-1).pop();
              parent.children = [{ ...total, parent: parent }];
              res.push(parent.children);
              return res;
            },
            [[total]],
          )
          .forEach((totalRow: any, index: number) => {
            this.columns[index] = [totalRow[0], ...(this.columns[index] || [])];
          });
      }
      calcStyles() {
        this.columns.reverse().forEach((row: any) => {
          row.forEach((col: any) => {
            col.childrenCount =
              col.children
                ?.filter((ch: any) => !ch.hide && ch.visible)
                .reduce((res: any, ch: any) => res + ch.childrenCount, 0) || 1;

            col.width =
              (col.childrenCount === 1 && col.width) ||
              col.children
                .filter((ch: any) => !ch.hide && Boolean(ch.visible))
                .reduce((r: any, v: any) => r + v.width, 0);
            col.hide =
              (col.agg && !col.agg.selected) ||
              (!col.agg && col.resource && !col.resource.selected);
            if (col.hide && col.children) {
              col.children.forEach((child: any) => (child.hide = true));
            }
            col.style = `width: ${col.width}px; min-width: ${col.width}px; max-width: ${col.width}px;`;
          });
        });
        this.columns.reverse();
      }
      cellData(row: any, column: any, defaultValue = 0) {
        const cell = {
          ...row,
          ...column,
          groups: [row.groups, column.groups].flat().sort((a, b) => a.localeCompare(b)),
        };
        cell.keys = cell.groups.reduce((keys: any, group: any) => {
          keys[group] = cell[group]?.pk;
          return keys;
        }, {});
        cell.totalKey = JSON.stringify(cell.keys);
        cell.totalsKey = JSON.stringify(cell.groups);

        cell[column.name] =
          this.flatTotals
            .filter(
              (total: any) =>
                cell.totalsKey === total.totalsKey && cell.totalKey === total.totalKey,
            )
            .slice(-1)
            .pop() || {};
        if (column.resource) {
          cell[column.name] = cell[column.name][column.resourceKey] || defaultValue;
        }
        return cell;
      }
      toggleAggResource(item: any) {
        item.selected = !item.selected;
        this.calcStyles();
      }
    },
  ],
};
