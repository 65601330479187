import React from 'react';

import { type Observable, combineLatest, useObservableState } from '..';

type AsyncProps<T> = {
  args: { [K in keyof T]: Observable<T[K]> };
  children: (values: T) => React.ReactNode;
};

export const Async = <T,>({ args: observables, children }: AsyncProps<T>) => {
  const combined$ = React.useMemo(() => combineLatest(observables), [observables]);

  const values = useObservableState(combined$);

  return <>{values ? children(values) : null}</>;
};
