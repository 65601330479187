import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').directive('gtResourceSelect', directive);

  function directive() {
    return {
      template: require('./gt-resource-select.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      link: link,
      scope: {
        ngModel: '=',
        resourceName: '=',
        placeholder: '=?',
        queryParams: '<?',
        onSearch: '&?',
        onSelect: '=?',
        onSelectArgs: '=?',
        allowClear: '=?',
        title: '<?',
        gtDisabled: '=?',
        onSelectClb: '&?',
        onOpenClose: '&?',
        submenu: '<?',
        required: '<?',
      },
    };
  }

  function link(scope: any) {
    scope.callOnSelect = function () {
      if (scope.vm.onSelectClb) {
        scope.vm.onSelectClb({ $event: '' });
      }
      if (scope.vm.onSelect) {
        scope.vm.onSelectArgs = scope.vm.onSelectArgs || [];
        return scope.vm.onSelect.apply(this, scope.vm.onSelectArgs);
      }
    };
  }

  Controller.$inject = ['$scope', '$timeout', 'GtUtils', 'gettext'];

  function Controller(
    this: {
      template: any;
      bindToController: boolean;
      controller: {
        (
          $scope: ng.IScope,
          $timeout: ng.ITimeoutService,
          GtUtils: any,
          gettext: (text: string) => string,
        ): void;
        $inject: string[];
      };
      controllerAs: string;
      link: (scope: any) => void;
      scope: {
        ngModel: string;
        resourceName: string;
        placeholder: string;
        queryParams: string;
        onSearch: string;
        onSelect: string;
        onSelectArgs: string;
        allowClear: string;
        title: string;
        gtDisabled: string;
        onSelectClb: string;
        onOpenClose: string;
        submenu: string;
        required: string;
      };
    },
    $scope: ng.IScope,
    $timeout: ng.ITimeoutService,
    GtUtils: any,
    gettext: (text: string) => string,
  ) {
    const vm = this as unknown as any;
    vm.updateData = updateData;
    vm.items = [];
    vm.clear = clear;
    vm.initLoad = true;
    vm.isLoadingMissingItem = false;
    vm.currentItem = {};
    vm.resource = vm.resourceName;
    vm.setCurrentItem = setCurrentItem;
    vm.isOpenHandler = isOpenHandler;
    vm.copyToClipboard = copyToClipboard;
    vm.active = false;
    vm.defaultPlaceholder = gettext('Choose');

    vm.$onInit = function () {
      initializeComponent();
    };

    vm.onUiSelectInit = (uiSelect: any) => {
      vm.updateData();
      setTimeout(() => uiSelect.activate());
    };
    function initializeComponent() {
      vm.placeholder = vm.placeholder || vm.defaultPlaceholder;
      resolveResource();

      $scope.$watch('vm.resourceName', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          resolveResource();
          updateCurrentItem();
          updateData();
        }
      });

      $scope.$watch('vm.ngModel', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          updateCurrentItem();
        }
      });

      if (vm.ngModel && vm.title) {
        setCurrentItem({ id: vm.ngModel, title: vm.title });
      } else if (vm.ngModel) {
        updateCurrentItem();
      }

      updateData();
    }

    function updateCurrentItem() {
      if (!vm.ngModel || !vm.resource) {
        resetCurrentItem();
        return;
      }

      fetchItemById(vm.ngModel).then(processFetchedItem).catch(handleItemError);
    }

    function resetCurrentItem() {
      vm.title = undefined;
      vm.items = [];
    }

    function fetchItemById(id: string) {
      const resource = GtUtils.getResource(vm.resource);
      const method = resource.predictions
        ? (args: { id: string }) => resource.predictions(args.id)
        : resource.get;
      return method({ id }).$promise || method({ id });
    }

    function processFetchedItem(item: any) {
      if (item.results?.length) {
        item = item.results.find((i: any) => i.id === vm.ngModel);
      }

      if (!item?.id) {
        return loadMissingItemById(vm.ngModel);
      }

      setCurrentItem({ id: item.id, title: GtUtils.getObjectTitle(item) });
      addItemToList(item);
    }

    function addItemToList(item: any) {
      if (!vm.items.some((i: any) => i.id === item.id)) {
        vm.items.push({ id: item.id, title: GtUtils.getObjectTitle(item) });
        $scope.$applyAsync();
      }
    }

    function handleItemError() {
      vm.clear(null);
    }

    function loadMissingItemById(id: any) {
      if (vm.isLoadingMissingItem) {
        return;
      }

      vm.isLoadingMissingItem = true;

      fetchMissingItem(id)
        .then(addMissingItemToList)
        .finally(() => (vm.isLoadingMissingItem = false));
    }

    function fetchMissingItem(id: string) {
      const resource = GtUtils.getResource(vm.resource);
      const result = resource.get({ id });
      return result.$promise || result;
    }

    function addMissingItemToList(item: any) {
      if (!item?.id) {
        return;
      }

      const missingItem = { id: item.id, title: GtUtils.getObjectTitle(item) };
      if (!vm.items.some((i: any) => i.id === item.id)) {
        vm.items.push(missingItem);
      }

      setCurrentItem(missingItem);
      $scope.$applyAsync();
    }

    function updateData(search?: string, force?: boolean) {
      if (vm.initLoad && !force) {
        vm.initLoad = false;
        return;
      }
      vm.initLoad = false;

      resolveResource();
      const params = getQueryParams(search);

      fetchPredictions(params, force)
        .then(processPredictionData)
        .catch(() => !force && updateData(undefined, true));
    }

    function getQueryParams(search?: string) {
      const params = GtUtils.filterEmptyParams(
        (typeof vm.queryParams === 'function' ? vm.queryParams() : vm.queryParams) || {},
      );
      return search ? { ...params, search } : params;
    }

    function fetchPredictions(params: Record<string, any>, force?: boolean) {
      return GtUtils.getPredictions([vm.resource], params, undefined, force);
    }

    function processPredictionData(data: any) {
      vm.items = data[vm.resource];
      $scope.$applyAsync();

      if (vm.ngModel && !vm.items.some((item: any) => item.id === vm.ngModel)) {
        loadMissingItemById(vm.ngModel);
      }
    }

    function clear(ev?: Event) {
      if (ev) {
        ev.stopPropagation();
      }

      vm.ngModel = null;
      $timeout(executeClearCallbacks, 1000);
    }

    function executeClearCallbacks() {
      if (vm.onSelect) {
        vm.onSelect(vm.onSelectArgs || []);
      }
      if (vm.onSelectClb) {
        vm.onSelectClb({ $event: '' });
      }

      vm.items = vm.items.filter((item: any) => item.id);
    }

    function resolveResource() {
      vm.resource = (typeof vm.resourceName === 'function' && vm.resourceName()) || vm.resourceName;
    }

    function setCurrentItem(item: { id: string; title: string }) {
      vm.title = item.title;
      vm.currentItem = { id: item.id, title: item.title };
      vm.ngModel = item.id;
    }

    function copyToClipboard(value: string, event: Event) {
      event.stopPropagation();
      GtUtils.copyToClipboard(value);
    }

    function isOpenHandler(isOpen: boolean) {
      if (typeof vm.onOpenClose === 'function') {
        vm.onOpenClose({ isOpen });
      }
    }
  }
})();
