import { PnlLogisticRecord, PnlLogisticRepository } from '~/features/execution/logistic';
import {
  BaseLogisticListContainerProps,
  BaseLogisticListModel,
} from '~/features/execution/logistic/common/components/logistic-container/base-logistic-list.model';
import { injectable } from '~/shared/lib/di';

export type PnlLogisticListContainerProps = BaseLogisticListContainerProps;

@injectable()
export class PnlLogisticListModel extends BaseLogisticListModel<
  PnlLogisticRecord,
  PnlLogisticRepository
> {
  entityRepoClass = PnlLogisticRepository;
}
