import type ng from 'angular';

import template from './consolidated-crop-position-list-table.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const ConsolidatedCropPositionListTable = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'ConsolidatedCropPositionService',
    'gettext',
    'GtUtils',
    class {
      $scope: ng.IScope;
      ConsolidatedCropPositionService: any;
      GtUtils: GtUtilsService;
      cropReportData: any;
      filterLevel = 'consolidated-crop-position-list-table';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        ConsolidatedCropPositionService: any,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.ConsolidatedCropPositionService = ConsolidatedCropPositionService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;

        this.cropReportData = [];
        this.queryParams = { with_internal_chain: 0 };
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams, ...this.queryParams };
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateCropPositionTableData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateCropPositionTableData() {
        this.GtUtils.overlay('show');
        this.cropReportData = [];
        return this.ConsolidatedCropPositionService.getCropPositionTableData(this.queryParams).then(
          (data: any) => {
            this.GtUtils.overlay('hide');
            this.cropReportData = data;
          },
        );
      }
    },
  ],
};
