import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('operationalbalanceReportPageView', {
    bindings: {},
    template: require('./operationalbalance-report-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['ReportsService', 'PageService'];

  function Controller(this: any, ReportsService: ReportsService, PageService: PageService) {
    const vm = this;
    vm.filterLevel = 'operationalbalance-page-view';

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      return {
        class: 'page-header-main-clients-tab',
        buttons: [new PageService.buttons.Print()],
        pages: ReportsService.getPagesConfig(),
      };
    }
  }
})();
