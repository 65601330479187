import template from './payment-info-list-container.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const PaymentInfoListContainer = {
  bindings: {
    queryParams: '<',
    newPaymentInfo: '<?',
  },
  template,
  controller: [
    'FinancesService',
    'GtUtils',
    class {
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      newPaymentInfo: any;
      newPaymentInfoCopy: any;
      paymentInfos: any;
      paymentInfosCount: number;
      queryParams?: QueryParams = {};
      constructor(FinancesService: FinancesService, GtUtils: GtUtilsService) {
        this.FinancesService = FinancesService;
        this.GtUtils = GtUtils;

        this.newPaymentInfo = undefined;
        this.paymentInfos = [];
        this.paymentInfosCount = 0;
        this.newPaymentInfoCopy = undefined;
      }

      $onInit() {
        this.queryParams = this.queryParams ?? {};
        this.updateData();
      }

      $onChanges(changes: any) {
        if (changes.queryParams && !changes.queryParams.isFirstChange()) {
          this.updateData();
        }
      }

      updateData(params = {}) {
        Object.assign(params, this.queryParams);
        this.GtUtils.overlay('show');
        this.newPaymentInfoCopy = Object.assign(params, this.newPaymentInfo);
        this.FinancesService.getPaymentInfoList(params).then((data: any) => {
          this.paymentInfos = data.results;
          this.paymentInfosCount = data.count;
          this.GtUtils.overlay('hide');
        });
      }

      savePaymentInfo(paymentInfo: any) {
        return this.FinancesService.savePaymentInfo(paymentInfo).then(() => this.updateData());
      }

      deletePaymentInfo(paymentInfo: any) {
        return this.FinancesService.deletePaymentInfo(paymentInfo).then(() => this.updateData());
      }
    },
  ],
};
