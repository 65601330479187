import { ExportLogisticRecord } from '~/features/execution/logistic/common';
import {
  BaseLogisticListContainerProps,
  BaseLogisticListModel,
} from '~/features/execution/logistic/common/components/logistic-container/base-logistic-list.model';
import { ExportLogisticRepository } from '~/features/execution/logistic/export/services';
import { injectable } from '~/shared/lib/di';

export type ExportLogisticListContainerProps = BaseLogisticListContainerProps;

@injectable()
export class ExportLogisticListModel extends BaseLogisticListModel<
  ExportLogisticRecord,
  ExportLogisticRepository
> {
  entityRepoClass = ExportLogisticRepository;
}
