import template from './contract-details-item-view.html?raw';

import type { GtRootScopeService, QueryParams } from '^/app/core/types';

export const contractDetailsItemView = {
  bindings: {
    contract: '<',
    contractChargesFilterLevel: '<',
    tab: '<',
    fields: '<',
    openEmailModal: '&?',
    editContract: '&?',
    editContractQuick: '&?',
    openDocxModal: '&?',
    clone: '&?',
    reverse: '&?',
    approve: '&?',
    createPassport: '&?',
    connectToPassport: '&?',
    addLogistic: '&?',
    addFinance: '&?',
    addBalanceInvoice: '&?',
    openRoleModal: '&?',
    openRoleDetails: '&?',
    goBack: '&?',
    changeTab: '&?',
    updateData: '&?',
    hideWizard: '<?',
    costsPredictionsQueryParams: '<?',
    filterLevel: '<?',
    passportQueryParams: '<?',
  },
  template,
  controller: [
    '$rootScope',
    class {
      addBalanceInvoice: any;
      addFinance: any;
      addLogistic: any;
      approve: any;
      clone: any;
      contract: any;
      createPassport: any;
      editContract: any;
      editContractQuick: any;
      fields: any;
      goBack: any;
      hideWizard: any;
      openDocxModal: any;
      openEmailModal: any;
      openRoleModal: any;
      passportQueryParams: QueryParams = {};
      reverse: any;
      showDetail: any;
      tab: string;
      updateData: any;
      constructor($rootScope: GtRootScopeService) {
        this.contract = {};
        this.tab = '';

        this.fields = [];

        if ($rootScope.user.profile?.recap) {
          this.showDetail = true;
        } else {
          this.showDetail = false;
        }
      }
    },
  ],
};
