import { ComponentModel } from '~/shared/lib/components';
import { injectable } from '~/shared/lib/di';
import { BehaviorSubject, combineLatest, map, take, tap } from '~/shared/lib/state';
import { ListOption, MessageIntent } from '~/shared/lib/types';

export type AutocompleteProps = {
  value?: ListOption[];
  options: ListOption[];
  multiple?: boolean;
  loading?: boolean;

  onSearch: (input: string) => void;
  onChange?: (value: ListOption[]) => void;
  onOpenChange?: (isOpen: boolean) => void;
  message?: {
    type: MessageIntent;
    text?: string;
  };
  disabled?: boolean;
  emptyMessage?: string;
};

@injectable()
export class AutocompleteModel extends ComponentModel<AutocompleteProps> {
  private readonly editedValuesSubj = new BehaviorSubject<ListOption[] | null>(null);

  combinedValues$ = combineLatest([this.editedValuesSubj, this.props$]).pipe(
    map(([editedValues, { value }]) => editedValues ?? value ?? []),
  );
  optionsById$ = this.props$.pipe(
    map(({ options }) => Object.fromEntries(options.map((o) => [o.value, o]))),
  );

  selectValue = (selectedValue: string) => {
    combineLatest([this.optionsById$, this.combinedValues$], (optionsById, combinedValues) => ({
      selectedOption: optionsById[selectedValue],
      combinedValues,
    }))
      .pipe(
        take(1),
        tap(({ selectedOption, combinedValues }) => {
          const selected = combinedValues.some((v) => v.value === selectedValue);

          if (!this.props.multiple) {
            this.editedValuesSubj.next(selected ? [] : [selectedOption]);
            return this.props.onChange?.(selected ? [] : [selectedOption]);
          }

          this.editedValuesSubj.next(
            selected
              ? combinedValues.filter((v) => v.value !== selectedValue)
              : [...combinedValues, selectedOption],
          );
        }),
      )
      .subscribe();
  };
}
