import { UserRepository } from '~/features/accounts';
import { AcceptanceCertificateRepository } from '~/features/common/acceptance-certificate';
import { BalancePaymentRepository } from '~/features/common/balance-payment';
import { BankRepository } from '~/features/common/bank';
import { BusinessUnitRepository } from '~/features/common/business-units';
import { CityRepository } from '~/features/common/cities';
import { ClientRoleRepository } from '~/features/common/client-role';
import { CommodityRepository } from '~/features/common/commodity';
import { CompanyGroupRepository } from '~/features/common/company-group';
import { CountryRepository } from '~/features/common/countries';
import { CurrencyRepository } from '~/features/common/currency';
import { CurrencyExchangeRepository } from '~/features/common/currency-exchange';
import { CustomClientRoleRepository } from '~/features/common/custom-client-role';
import { CustomStatusRepository } from '~/features/common/custom-statuses';
import { DelivererRepository } from '~/features/common/deliverer';
import { DistrictRepository } from '~/features/common/districts';
import { ElevatorRepository } from '~/features/common/elevator/';
import { ExporterRepository } from '~/features/common/exporter';
import { MeasurementRepository } from '~/features/common/measurements';
import { PaymentConditionRepository } from '~/features/common/payment-condition';
import { PaymentInfoRepository } from '~/features/common/payment-info';
import { PortRepository } from '~/features/common/port';
import { RegionRepository } from '~/features/common/region';
import { SeasonRepository } from '~/features/common/season';
import { StationRepository } from '~/features/common/station';
import { CounterpartiesRepository } from '~/features/crm/counterparties';
import { BasisRepository } from '~/features/deals/basises';
import {
  ContractsRepository,
  ExportContractsRepository,
  IntermediateContractsRepository,
  PurchaseContractsRepository,
  SaleContractsRepository,
  ServicesContractsRepository,
} from '~/features/deals/contracts';
import { PassportRepository } from '~/features/deals/passports';
import { DisbursementBillOfLadingRepository } from '~/features/execution/disbursement-bill-of-lading';
import { InfoLogisticRepository } from '~/features/execution/logistic';
import { PlaceOfDestinationRepository } from '~/features/execution/places-of-destination';
import { TerminalRepository } from '~/features/execution/terminal';
import { VoyageRepository } from '~/features/execution/voyages';
import { WarehouseRepository } from '~/features/execution/warehouses';
import { FinanceRepository } from '~/features/finances';
import { BankAccountRepository } from '~/features/finances/bank-accounts';
import { ChargeRepository } from '~/features/finances/charges';
import { FinanceAccountRepository } from '~/features/finances/finance-accounts';
import { GeneralExpensesRepository } from '~/features/finances/general-expenses';
import { OtherActivityRepository } from '~/features/finances/other-activities';
import { container } from '~/shared/lib/di';
import { registerEntityAutocomplete } from '~/shared/ui/data-types';

registerEntityAutocomplete('logistics.Logistic', container.resolve(InfoLogisticRepository).search);
registerEntityAutocomplete('finances.Currency', container.resolve(CurrencyRepository).search);
registerEntityAutocomplete('logistics.Warehouse', container.resolve(WarehouseRepository).search);
registerEntityAutocomplete('location.District', container.resolve(DistrictRepository).search);
registerEntityAutocomplete('location.Country', container.resolve(CountryRepository).search);
registerEntityAutocomplete('location.City', container.resolve(CityRepository).search);

registerEntityAutocomplete('logistics.Basis', container.resolve(BasisRepository).search);
registerEntityAutocomplete(
  'stockexchanges.Measurement',
  container.resolve(MeasurementRepository).search,
);
registerEntityAutocomplete('core.BusinessUnit', container.resolve(BusinessUnitRepository).search);
registerEntityAutocomplete('contracts.Contract', container.resolve(ContractsRepository).search);
registerEntityAutocomplete('clients.ClientRole', container.resolve(ClientRoleRepository).search);
registerEntityAutocomplete('clients.Client', container.resolve(CounterpartiesRepository).search);
registerEntityAutocomplete('logistics.Voyage', container.resolve(VoyageRepository).search);
registerEntityAutocomplete('crops.Commodity', container.resolve(CommodityRepository).search);
registerEntityAutocomplete('core.Season', container.resolve(SeasonRepository).search);
registerEntityAutocomplete(
  'finances.CurrencyExchange',
  container.resolve(CurrencyExchangeRepository).search,
);
registerEntityAutocomplete(
  'finances.BalancePayment',
  container.resolve(BalancePaymentRepository).search,
);
registerEntityAutocomplete(
  'core.IndicatorCustomStatus',
  container.resolve(CustomStatusRepository).searchWithContentType('indicator'),
);
registerEntityAutocomplete(
  'core.RequestCustomStatus',
  container.resolve(CustomStatusRepository).searchWithContentType('contractbase'),
);
registerEntityAutocomplete(
  'logistics.LogisticCustomStatus',
  container.resolve(CustomStatusRepository).searchWithContentType('logistic'),
);
registerEntityAutocomplete(
  'clients.ClientsCustomStatus',
  container.resolve(CustomStatusRepository).searchWithContentType('client'),
);
registerEntityAutocomplete(
  'finances.DisbursementBl',
  container.resolve(DisbursementBillOfLadingRepository).search,
);
registerEntityAutocomplete('logistics.Station', container.resolve(StationRepository).search);
registerEntityAutocomplete('finances.Finance', container.resolve(FinanceRepository).search);
registerEntityAutocomplete('clients.Deliverer', container.resolve(DelivererRepository).search);
registerEntityAutocomplete('clients.Elevator', container.resolve(ElevatorRepository).search);
registerEntityAutocomplete('clients.Exporter', container.resolve(ExporterRepository).search);
registerEntityAutocomplete(
  'logistics.AcceptanceCertificate',
  container.resolve(AcceptanceCertificateRepository).search,
);
registerEntityAutocomplete(
  'contracts.SaleContract',
  container.resolve(SaleContractsRepository).search,
);
registerEntityAutocomplete(
  'contracts.PurchaseContract',
  container.resolve(PurchaseContractsRepository).search,
);
registerEntityAutocomplete(
  'contracts.ExportContract',
  container.resolve(ExportContractsRepository).search,
);
registerEntityAutocomplete(
  'contracts.IntermediateContract',
  container.resolve(IntermediateContractsRepository).search,
);
registerEntityAutocomplete(
  'contracts.ServiceContract',
  container.resolve(ServicesContractsRepository).search,
);
registerEntityAutocomplete('clients.Bank', container.resolve(BankRepository).search);
registerEntityAutocomplete(
  'finances.PaymentCondition',
  container.resolve(PaymentConditionRepository).search,
);
registerEntityAutocomplete('finances.PaymentInfo', container.resolve(PaymentInfoRepository).search);
registerEntityAutocomplete('finances.BankAccount', container.resolve(BankAccountRepository).search);
registerEntityAutocomplete(
  'finances.FinanceAccount',
  container.resolve(FinanceAccountRepository).search,
);
registerEntityAutocomplete('accounts.User', container.resolve(UserRepository).search);
registerEntityAutocomplete(
  'logistics.PlaceOfDestination',
  container.resolve(PlaceOfDestinationRepository).search,
);
registerEntityAutocomplete(
  'finances.OtherActivity',
  container.resolve(OtherActivityRepository).search,
);
registerEntityAutocomplete(
  'finances.GeneralExpenses',
  container.resolve(GeneralExpensesRepository).search,
);
registerEntityAutocomplete('passports.Passport', container.resolve(PassportRepository).search);
registerEntityAutocomplete('finances.Charge', container.resolve(ChargeRepository).search);
registerEntityAutocomplete('logistics.Port', container.resolve(PortRepository).search);
registerEntityAutocomplete('logistics.Terminal', container.resolve(TerminalRepository).search);
registerEntityAutocomplete(
  'clients.CustomClientRole',
  container.resolve(CustomClientRoleRepository).search,
);
registerEntityAutocomplete(
  'clients.CompanyGroup',
  container.resolve(CompanyGroupRepository).search,
);
registerEntityAutocomplete('location.Region', container.resolve(RegionRepository).search);
