import ng from 'angular';

import { consolidatedInvoicingModule } from './consolidated-invoicing/consolidated-invoicing.module';
import { invoiceDetails } from './details/invoice-details.module';
import { InvoiceFilterConfigService } from './invoice-filter-config.service';
import { InvoiceFormFieldsService } from './invoice-form-field.service';
import { InvoiceListBlockView } from './invoice-list-block-view/invoice-list-block-view.component';
import { invoicesListContainer } from './invoice-list-container/invoice-list-container.component';
import { InvoiceListListView } from './invoice-list-list-view/invoice-list-list-view.component';
import { InvoicesListPage } from './invoice-list-page/invoice-list-page.component';
import { InvoiceListTableView } from './invoice-list-table-view/invoice-list-table-view.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const invoiceConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('finances.invoices.list', {
        url: '/list/?id',
        component: 'invoicesListPage',
        data: {
          pageTitle: gettext('Invoices'),
        },
        resolve: {
          filterLevel: () => {
            return 'invoices-page-view';
          },
          queryParams: [
            'PageService',
            '$stateParams',
            function (PageService: PageService, $stateParams: ng.ui.IStateParamsService) {
              const params = PageService.syncUrlFilter('invoices-page-view');
              if ($stateParams.id) {
                params.id_list = [$stateParams.id];
              }
              return params;
            },
          ],
        },
      })
      .state('finances.invoices.payment', {
        url: '/payment/',
        params: {
          view: 'list',
          without_status_list: ['paid'],
        },
        component: 'invoicesListPage',
        data: {
          pageTitle: gettext('Payment delays'),
        },
        resolve: {
          filterLevel: () => 'invoices-page-view',
          queryParams: [
            'PageService',
            '$stateParams',
            (PageService: PageService, $stateParams: ng.ui.IStateParamsService) => {
              const params = { ...$stateParams };
              delete params['#'];
              return {
                ...PageService.syncUrlFilter('invoices-page-view'),
                ...params,
              };
            },
          ],
        },
      });
  },
];

export const invoice = ng
  .module('finances.invoice', [invoiceDetails, consolidatedInvoicingModule])
  .component('invoicesListContainer', invoicesListContainer)
  .component('invoiceListTableView', InvoiceListTableView)
  .component('invoiceListListView', InvoiceListListView)
  .component('invoiceListBlockView', InvoiceListBlockView)
  .component('invoicesListPage', InvoicesListPage)
  .service('InvoiceFormFieldsService', InvoiceFormFieldsService)
  .service('InvoiceFilterConfigService', InvoiceFilterConfigService)
  .config(invoiceConfig).name;
