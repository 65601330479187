import type { GtRootScopeService } from '^/app/core/types';

export class CCDFormFieldsService {
  $rootScope: GtRootScopeService;
  $window: ng.IWindowService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $rootScope: GtRootScopeService,
    $window: ng.IWindowService,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.$rootScope = $rootScope;
    this.$window = $window;
    this.gettext = gettext;
  }

  getFields(declaration: any, formName: string) {
    formName = formName || 'customs-cargo-declaration-modal';
    const classes = 'form-group-container col-xs-12 col-sm-8 center-block';
    const fieldsConfig: any = {
      formName: formName,
      fieldsDef: [
        { className: classes, fieldGroup: [] },
        { className: classes, fieldGroup: [] },
        { className: classes, fieldGroup: [] },
      ],
    };
    fieldsConfig.fieldsDef[0].fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: 'INFO',
      },
      fieldGroup: [
        {
          key: 'number',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Declaration number'),
            type: 'text',
          },
        },
        {
          key: 'date',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Declaration date'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'border_crossing_date',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Border crossing date'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'type_of_cd',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Type of CD'),
            placeholder: this.gettext('Type of CD'),
            resource: 'logistics.cdtype',
          },
        },
        {
          key: 'declaration_type',
          type: 'gt-select',
          templateOptions: {
            label: this.gettext('Declaration type'),
            defaultValue: 'export',
            options: [
              { title: this.gettext('Import'), id: 'import' },
              { title: this.gettext('Export'), id: 'export' },
              { title: this.gettext('Transit'), id: 'transit' },
            ],
          },
        },
        {
          key: 'result_status',
          type: 'gt-select',
          templateOptions: {
            label: this.gettext('Result status'),
            defaultValue: 'default',
            options: [
              { title: this.gettext('Default'), id: 'default' },
              { title: this.gettext('Approved'), id: 'approved' },
              { title: this.gettext('Rejected'), id: 'rejected' },
            ],
          },
        },
        {
          key: 'stage',
          type: 'gt-select',
          templateOptions: {
            label: this.gettext('Stage'),
            defaultValue: undefined,

            options: [
              { title: this.gettext('Periodic customs declaration'), id: 'periodic' },
              { title: this.gettext('Cargo customs declaration'), id: 'cargo' },
            ],
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.declaration_type === "transit"',
          },
        },
        {
          key: 'from_declaration',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Periodic customs declaration'),
            placeholder: this.gettext('Periodic customs declaration'),
            resource: 'logistics.customscargodeclaration',
            queryParams: () => ({ stage: 'periodic' }),
          },
          hideExpression: () => declaration.stage !== 'cargo',
          expressionProperties: {
            'templateOptions.disabled': 'model.stage !== "cargo"',
          },
        },
        {
          key: 'customs',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Customs'),
            placeholder: this.gettext('Customs'),
            resource: 'clients.customs',
          },
        },
        {
          key: 'terminal',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('terminal'),
            placeholder: this.gettext('terminal'),
            resource: 'logistics.terminal',
            queryParams: () => ({ ccd_list: [declaration.id] }),
          },
        },
        {
          key: 'currency',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('currency'),
            placeholder: this.gettext('currency'),
            resource: 'finances.Currency',
          },
        },
        {
          key: 'currency_exchange',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('currency exchange'),
            placeholder: this.gettext('currency exchange'),
            resource: 'finances.CurrencyExchange',
            queryParams: () => ({ local_currency: declaration.currency }),
          },
        },
        {
          key: 'customs_broker',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Customs broker'),
            placeholder: this.gettext('Customs broker'),
            resource: 'clients.clientrole',
            queryParams: () => ({ role_name: 'customs_broker' }),
          },
        },
        {
          key: 'destination_of_crop',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Destination'),
            placeholder: this.gettext('Type destination'),
            resource: 'location.Country',
            hint: this.gettext('To which country delivered'),
            addon: this.gettext('country'),
            addFunc: () => {
              this.$window.open('/admin/location/country/add/');
            },
            addIcon: 'fa fa-location-arrow',
            addPerms: ['add_country'],
          },
        },
        {
          key: 'volume',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Volume'),
            type: 'number',
          },
          hideExpression: () => formName === 'customs-cargo-declaration-modal',
        },
        {
          key: 'additional_info',
          type: 'gt-input',
          templateOptions: {
            type: 'text',
            label: this.gettext('Note'),
          },
        },
      ],
    });
    fieldsConfig.fieldsDef[1].fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: 'PASSPORTS',
      },
      fieldGroup: [
        {
          key: 'passports',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('passports'),
            placeholder: this.gettext('passports'),
            resource: 'passports.Passport',
          },
        },
      ],
    });
    fieldsConfig.fieldsDef[2].fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: 'CONTRACTS',
      },
      fieldGroup: [
        {
          key: 'purchase_contracts',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('purchase contracts'),
            placeholder: this.gettext('purchase contracts'),
            resource: 'contracts.PurchaseContract',
            queryParams: { passport_list: declaration.passports },
          },
          hideExpression: () => declaration.stage === 'cargo',
        },
        {
          key: 'purchase_contract',
          type: 'gt-ui-select',
          defaultValue: declaration.purchase_contracts?.at(0),
          templateOptions: {
            label: this.gettext('purchase contract'),
            placeholder: this.gettext('purchase contract'),
            resource: 'contracts.PurchaseContract',
            queryParams: () => {
              return { passport_list: declaration.passports };
            },
          },
          hideExpression: () => declaration.stage !== 'cargo',
        },
      ],
    });

    return {
      formName: formName,
      fieldsDef: fieldsConfig.fieldsDef,
    };
  }
}
CCDFormFieldsService.$inject = ['$rootScope', '$window', 'gettext'];
