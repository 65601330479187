import template from './costs-report-container.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const CostsReportContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    selectedGroups: '<',
  },
  template,
  controller: [
    '$scope',
    'ReportsService',
    'CostsReportService',
    'GtUtils',
    'gtFilterService',
    'CoreService',
    class {
      $scope: ng.IScope;
      CoreService: CoreService;
      CostsReportService: any;
      GtUtils: GtUtilsService;
      ReportsService: ReportsService;
      data: any;
      filterLevel = 'costs-report-container';
      gtFilterService: GtFilterService;
      initQueryParams?: QueryParams = {};
      queryParams: QueryParams = {};
      reportData: any;
      constructor(
        $scope: ng.IScope,
        ReportsService: ReportsService,
        CostsReportService: any,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        CoreService: CoreService,
      ) {
        this.$scope = $scope;
        this.ReportsService = ReportsService;
        this.CostsReportService = CostsReportService;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.CoreService = CoreService;
        this.data = {};
        this.initQueryParams = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'costs-report-container';
        this.initQueryParams = this.initQueryParams ?? {};
        this.queryParams = {
          ...this.initQueryParams,
          ...this.gtFilterService.getQueryParams(this.filterLevel),
        };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.CostsReportService.getReportData(this.queryParams).then((data: any) => {
          this.reportData = data;
          this.GtUtils.overlay('hide');
        });
      }
    },
  ],
};
