import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('requestsPageView', {
    bindings: {
      queryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./requests-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    'ContractsService',
    'RequestFilterConfigService',
    'PageService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    ContractsService: ContractsService,
    RequestFilterConfigService: any,
    PageService: PageService,
  ) {
    const vm = this;
    vm.filterLevel = vm.filterLevel || 'requests-page-view';
    vm.tableName = null;
    vm.groupBy = null;

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
      vm.tableName = vm.queryParams.contract_type + '-request-list-page';
      vm.ticketQueryParams = {
        ...vm.queryParams,
        ...($rootScope.user.perms.includes('view_ticket') ? {} : { author: $rootScope.user.id }),
      };
    };

    ////////////////

    function getPageConfig() {
      const buttons = PageService.getButtons();
      return {
        class: 'page-header-main-prices-tab',
        buttons: [
          new buttons.Filters(),
          new buttons.Print(),
          new buttons.Refresh(vm.filterLevel),
          new buttons.ScreenCaptureTask(),
        ],
        quickAdd: () => {
          vm.quickAdd = !vm.quickAdd;
        },
        pages: ContractsService.getPagesConfig(),
        filters: RequestFilterConfigService.getFilterConfig(
          vm.filterLevel,
          vm.queryParams.contract_type,
        ),
      };
    }
  }
})();
