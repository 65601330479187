import type ng from 'angular';

import template from './terminal-certs-logistic.html?raw';

import { html } from '^/shared/utils';

export const TerminalCertsLogistic = {
  bindings: {
    contractId: '<',
    contractType: '<',
  },
  template,
  controller: [
    'gettext',
    '$httpParamSerializer',
    '$state',
    'TerminalCertsLogisticService',
    class {
      $httpParamSerializer: any;
      $state: ng.ui.IStateService;
      contractId?: number;
      contractType: any;
      gettext: ng.gettext.gettextFunction;
      tableApi: any;
      tableOptions: any;
      terminalCertsLogisticService: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        $httpParamSerializer: any,
        $state: ng.ui.IStateService,
        TerminalCertsLogisticService: any,
      ) {
        this.gettext = gettext;
        this.$state = $state;
        this.$httpParamSerializer = $httpParamSerializer;
        this.terminalCertsLogisticService = TerminalCertsLogisticService;
        this.tableOptions = this.getTableOptions();
        this.tableApi = undefined;
      }

      updateData() {
        return this.terminalCertsLogisticService
          .getTerminalCertsLogistics(this.contractId, this.contractType)
          .then((data: any) => {
            data.results.map((terminal: any) => {
              const prepayQuerystring = this.$httpParamSerializer({
                pklist: terminal.prepay_ids,
              });
              const balanceQuerystring = this.$httpParamSerializer({
                pklist: terminal.balance_ids,
              });

              terminal.prepayref =
                '/en/' + this.$state.href('logistics.transport') + '?' + prepayQuerystring;
              terminal.balanceref =
                '/en/' + this.$state.href('logistics.transport') + '?' + balanceQuerystring;
            });
            this.tableApi.setRowData(data.results);
          });
      }

      getTableOptions() {
        return {
          tableName: 'terminal-certs-logistic',
          templateArgs: {},
          columnDefs: [
            {
              columnName: 'name',
              title: this.gettext('Terminal'),
            },
            {
              columnName: 'prepay_volume',
              title: this.gettext('Prepay volume'),
              cellTemplate: html`
                <a
                  href="{[{ item.prepayref }]}"
                  target="_blank"
                  class="btn btn-xs btn-success ng-scope"
                  ng-if="item.prepay_volume > 0"
                >
                  <i class="fa fa-credit-card-alt"></i>
                  {[{ item.prepay_volume | number: 3 }]}
                </a>
                <span ng-if="item.prepay_volume <= 0">-</span>
              `,
            },
            {
              columnName: 'balance_volume',
              title: this.gettext('Balance volume'),
              cellTemplate: html`
                <a
                  href="{[{ item.balanceref }]}"
                  target="_blank"
                  class="btn btn-xs btn-success ng-scope"
                  ng-if="item.balance_volume > 0"
                >
                  <i class="fa fa-credit-card-alt"></i>
                  {[{ item.balance_volume | number: 3}]}
                </a>
                <span ng-if="item.balance_volume <= 0">-</span>
              `,
            },
          ],
          rowData: [],
          onInit: (api: any) => {
            this.tableApi = api;
            this.updateData();
          },
        };
      }
    },
  ],
};
