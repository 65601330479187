import type {
  BaseLogisticListTotals,
  BaseLogisticRecord,
  Logistic,
  LogisticPatch,
  LogisticPost,
  LogisticQueryParams,
} from '~/features/execution/logistic/common/lib';
import type { LogisticRequest } from '~/shared/api';
import {
  logisticsLogisticsCreate,
  logisticsLogisticsDestroy,
  logisticsLogisticsPartialUpdate,
  logisticsLogisticsRetrieve,
  logisticsLogisticsSetApprovalStatusProcessPartialUpdate,
  logisticsLogisticsSetDiscountByQualityPartialUpdate,
  logisticsLogisticsSetPurchasePriceFromIndicatorPartialUpdate,
} from '~/shared/api';
import type { Entity } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

export abstract class BaseLogisticRepository<
  TEntityRecord extends Entity = BaseLogisticRecord,
  TListTotals extends BaseLogisticListTotals = BaseLogisticListTotals,
> extends EntityRepository<
  Logistic,
  LogisticQueryParams,
  TEntityRecord,
  LogisticPost,
  LogisticPatch
> {
  readonly entityName = 'logistics.Logistic';

  abstract getListTotals: (query: LogisticQueryParams) => Promise<TListTotals>;

  getFn = async (id: number) => {
    const { data } = await logisticsLogisticsRetrieve({ path: { id } });
    return data;
  };

  createFn = async (dto: LogisticPost): Promise<Logistic> => {
    const res = await logisticsLogisticsCreate({
      body: dto as LogisticRequest,
    });
    return res.data;
  };

  searchFn = (_input: string, _id?: number): Promise<EntityOption[]> => {
    throw new Error('method not implemented');
  };

  updateFn = async (entity: LogisticPatch): Promise<Logistic> => {
    const { data } = await logisticsLogisticsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  deleteFn = async (id: number) => {
    await logisticsLogisticsDestroy({ path: { id } });
  };

  buildNewRecord = () =>
    ({
      id: -Date.now(),
      logistic_type: 'main',
      approval_status: 'process',
    }) as unknown as TEntityRecord;

  setPurchasePriceFromIndicator = async (idList?: number[]) => {
    await logisticsLogisticsSetPurchasePriceFromIndicatorPartialUpdate({
      body: { id_list: idList },
    });
  };

  setDiscountByQuality = async (idList?: number[]) => {
    await logisticsLogisticsSetDiscountByQualityPartialUpdate({
      body: { id_list: idList },
    });
  };

  setApprovalStatusProcess = async (idList?: number[]) => {
    await logisticsLogisticsSetApprovalStatusProcessPartialUpdate({
      body: { id_list: idList },
    });
  };
}
