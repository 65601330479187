import type ng from 'angular';

import template from './costs-report-page.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const CostsReportPage = {
  bindings: {
    queryParams: '<?',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'CoreService',
    'PageService',
    'ReportsService',
    'gettext',
    'GtUtils',
    'gtFilterService',
    class {
      $scope: ng.IScope;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      ReportsService: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      groupChoices: any;
      gtFilterService: GtFilterService;
      hideFilters: any;
      orderChoices: any;
      queryParams: QueryParams = {};
      savedFilterChoices: any;
      selectedGroups: any;
      unselectedGroups: any;
      constructor(
        $scope: ng.IScope,
        CoreService: CoreService,
        PageService: PageService,
        ReportsService: any,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.CoreService = CoreService;
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;

        this.filterLevel = 'costs-report-page';
        this.hideFilters = true;
        this.queryParams = {};
        this.groupChoices = [
          {
            param: 'currency',
            title: this.gettext('Currency'),
            icon: this.GtUtils.getIcon('currency'),
          },
          {
            param: 'company_title',
            title: this.gettext('Company group'),
            icon: this.GtUtils.getIcon('companygroup'),
          },
          {
            param: 'client',
            title: this.gettext('Counterparty'),
            icon: this.GtUtils.getIcon('client'),
          },
          {
            param: 'charge_title',
            title: this.gettext('Charge'),
            icon: this.GtUtils.getIcon('charge'),
          },
          {
            param: 'parent_charge_title',
            title: this.gettext('Parent charge'),
            icon: this.GtUtils.getIcon('contractcharge'),
          },
          {
            param: 'contractcharge',
            title: this.gettext('Cost / gain'),
            icon: this.GtUtils.getIcon('contractcharge'),
          },
          {
            param: 'passport',
            title: this.gettext('Passport'),
            icon: this.GtUtils.getIcon('passport'),
          },
          {
            param: 'contract',
            title: this.gettext('Contract'),
            icon: this.GtUtils.getIcon('contractbase'),
          },
          {
            param: 'passport_bu_title',
            title: this.gettext('Passport`s BU'),
            icon: this.GtUtils.getIcon('passport'),
          },
        ];
        this.orderChoices = [
          {
            param: 'currency',
            title: this.gettext('Currency'),
            icon: this.GtUtils.getIcon('currency'),
          },
          {
            param: 'client',
            title: this.gettext('Counterparty'),
            icon: this.GtUtils.getIcon('client'),
          },
          {
            param: 'amount',
            title: this.gettext('Amount'),
            icon: 'fa-file-lines',
          },
          {
            param: 'invoiced',
            title: this.gettext('Invoiced'),
            icon: 'fa-credit-card-alt',
          },
          {
            param: 'paid',
            title: this.gettext('Paid'),
            icon: 'fa-credit-card',
          },
          {
            param: 'delta_amount_paid',
            title: this.gettext('Amount - Paid'),
            icon: 'fa-square-minus',
          },
          {
            param: 'delta_invoiced_paid',
            title: this.gettext('Invoiced - Paid'),
            icon: 'fa-circle-minus',
          },
        ];
        this.selectedGroups = [];
        this.unselectedGroups = [];
        this.savedFilterChoices = [];
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'costs-report-page';
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) =>
          this.updateQueryParams(data),
        );
        this.PageService.setConfig(this.getPageConfig());
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
      }
      updateQueryParams(params: QueryParams) {
        this.queryParams = params;
        [this.selectedGroups, this.unselectedGroups] = this.ReportsService.parseGroupsFromString(
          this.groupChoices,
          this.queryParams.group_by,
        );
      }
      applyFilters(params?: QueryParams) {
        if (params) {
          this.updateQueryParams(params);
          this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel, false);
        } else {
          this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
        }
      }
      selectGroup(group: any) {
        this.selectedGroups.push(group);
        this.unselectedGroups.splice(this.unselectedGroups.indexOf(group), 1);
        this.queryParams.group_by = this.selectedGroups.map((v: any) => v.param).join(',');
      }
      unselectGroup(group: any) {
        this.unselectedGroups.push(group);
        this.selectedGroups.splice(this.selectedGroups.indexOf(group), 1);
      }

      toggleOrdering(order: any) {
        this.queryParams.reverse_order =
          this.queryParams.order_by === order && this.queryParams.reverse_order === '1'
            ? null
            : '1';
        this.queryParams.order_by = order;
        this.applyFilter();
      }
      applyFilter() {
        this.queryParams.group_by = this.selectedGroups.map((v: any) => v.param).join(',');
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            search: true,
            dates: true,
            dateSelects: [
              {
                argument: 'passport_business_date',
                placeholder: this.gettext('passport business date'),
              },
              {
                argument: 'contract_conclusion_date',
                placeholder: this.gettext('Contract conclusion date'),
              },
              {
                argument: 'business_date',
                placeholder: this.gettext('Passport or contract date'),
              },
              {
                argument: 'create_time',
                placeholder: this.gettext('Created date'),
              },
              {
                argument: 'date',
                placeholder: this.gettext('Charge date'),
              },
            ],
            multiSelects: [
              {
                argument: 'client_list',
                placeholder: this.gettext('Cost counterparty'),
                resource: 'clients.client',
              },
              {
                argument: 'contract_client_list',
                placeholder: this.gettext('Contract counterparty'),
                resource: 'clients.client',
              },
              {
                argument: 'contract_list',
                placeholder: this.gettext('contract'),
                resource: 'contracts.ContractBase',
              },
              {
                argument: 'multicontract_list',
                placeholder: this.gettext('multicontract'),
                resource: 'contracts.Multicontract',
              },
              {
                argument: 'contract_bu_list',
                placeholder: this.gettext('contract business unit'),
                resource: 'core.BusinessUnit',
              },
              {
                argument: 'charges_list',
                placeholder: this.gettext('charge'),
                resource: 'finances.Charge',
              },
              {
                argument: 'charge_parent_list',
                placeholder: this.gettext('Parent charge'),
                resource: 'finances.Charge',
              },
              {
                argument: 'charge_analytic_parent_list',
                placeholder: this.gettext('Analytical parent charge'),
                resource: 'finances.Charge',
              },
              {
                argument: 'passport_list',
                placeholder: this.gettext('passports'),
                resource: 'passports.Passport',
              },
              {
                argument: 'passport_bu_list',
                placeholder: this.gettext('passport business unit'),
                resource: 'core.BusinessUnit',
              },
              {
                argument: 'passport_custom_status_list',
                placeholder: this.gettext('passports custom status'),
                resource: 'core.CustomStatus',
                queryParams: {
                  content_type__model: 'passport',
                },
              },
              {
                argument: 'currency_list',
                placeholder: this.gettext('currency'),
                resource: 'finances.currency',
              },
              {
                argument: 'author_list',
                placeholder: this.gettext('author'),
                resource: 'auth.user',
              },
              {
                argument: 'payment_bank_account_list',
                placeholder: this.gettext('payment bank account'),
                resource: 'finances.bankaccount',
              },
              {
                argument: 'invoice_list',
                placeholder: this.gettext('invoices'),
                resource: 'finances.Finance',
              },
              {
                argument: 'contract_destination_list',
                placeholder: this.gettext('Contract destinations'),
                resource: 'location.Country',
              },
              {
                argument: 'passport_destination_list',
                placeholder: this.gettext('Passport destinations'),
                resource: 'location.Country',
              },
            ],
            nestedSelects: [
              this.gtFilterService.getBoolFilter(
                'has_multicontract',
                this.gettext('Has Multicontract'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_analytical_amount',
                this.gettext('Has Analytical amount'),
              ),
              this.gtFilterService.getBoolFilter('has_our_amount', this.gettext('Has Our amount')),
              this.gtFilterService.getBoolFilter(
                'has_final_amount',
                this.gettext('Has Final amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_several_passports',
                this.gettext('Has several passports'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_passport_key',
                this.gettext('Passport filled in  manually'),
              ),
              this.gtFilterService.getBoolFilter(
                'final_amount_diff_invoiced',
                this.gettext('final amount <> invoiced'),
              ),
              this.gtFilterService.getBoolFilter(
                'exec_amount_diff_paid',
                this.gettext('exec forecast amount <> payment amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_planned_costs',
                this.gettext('has planned costs'),
              ),
              this.gtFilterService.getBoolFilter(
                'exec_amount_diff_invoiced',
                this.gettext('exec forecast amount <> invoiced amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'final_amount_diff_amount',
                this.gettext('final amount <> amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'our_amount_diff_amount',
                this.gettext('our amount <> amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'analytical_amount_diff_amount',
                this.gettext('analytical amount <> amount'),
              ),
              this.gtFilterService.getBoolFilter('has_invoice', this.gettext('Has invoice')),
              this.gtFilterService.getBoolFilter(
                'has_cancelled_invoice',
                this.gettext('Has cancelled invoice'),
              ),
              this.gtFilterService.getBoolFilter('has_payment', this.gettext('Has payment')),
              this.gtFilterService.getBoolFilter('is_gain', this.gettext('Is gain')),
              this.gtFilterService.getBoolFilter(
                'has_exec_forecasted_amount',
                this.gettext('Has Exec Forecasted Amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_additional_info',
                this.gettext('Has comments'),
              ),
              this.gtFilterService.getBoolFilter('has_contract_key', this.gettext('Has contract')),
              this.gtFilterService.getBoolFilter('reverse_order', this.gettext('Reverse order')),
            ],
            nestedMultiSelects: [
              {
                argument: 'status_list',
                placeholder: this.gettext('Status'),
                items: [
                  { id: 'validated', title: this.gettext('Validated') },
                  { id: 'under_discussion', title: this.gettext('Under discussion') },
                  { id: 'pending_confirmation', title: this.gettext('Pending confirmation') },
                  { id: 'forecast', title: this.gettext('Forecast') },
                  { id: 'planned', title: this.gettext('Planned') },
                  { id: 'cancelled', title: this.gettext('Cancelled') },
                  { id: 'closed', title: this.gettext('Closed') },
                ],
              },
              this.gtFilterService.getClientRoleFilter(
                'clientrole_type_list',
                this.gettext('client role type'),
              ),
              this.gtFilterService.getPassportStatusFilter(
                'passport_status_list',
                'passport status',
              ),
              this.gtFilterService.getContractStatusFilter(
                'contract_status_list',
                this.gettext('contract status'),
              ),
              this.gtFilterService.getTypeFilter(
                'contract_type_list',
                this.gettext('contract type'),
              ),
              {
                argument: 'passport_approval_status_list',
                placeholder: this.gettext('passport approval status'),
                items: [
                  { id: 'wait', title: this.gettext('Waiting for approval') },
                  { id: 'approved', title: this.gettext('Approved') },
                  { id: 'process', title: this.gettext('Approval in process') },
                  { id: 'rejected', title: this.gettext('Rejected') },
                ],
              },
            ],
            orderedNestedMultiSelects: [
              {
                argument: 'group_by',
                placeholder: this.gettext('Grouping chain'),
                items: this.groupChoices.map((gr: any) => ({
                  id: gr.param,
                  title: gr.title,
                })),
              },
              {
                argument: 'order_by',
                placeholder: this.gettext('Order by'),
                items: this.orderChoices.map((gr: any) => ({
                  id: gr.param,
                  title: gr.title,
                })),
              },
            ],
          },
        };
      }
    },
  ],
};
