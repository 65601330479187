import { notifyError, notifySuccess } from '../../notify';

export const getCookies = (document: Document) => {
  const cookies: Record<string, string> = {};
  document.cookie.split(';').forEach((cookie) => {
    const [key, value] = cookie.split('=');
    cookies[key.trim()] = value;
  });
  return cookies;
};

export const lsRead = <T>(key: string, fallback: T): T => {
  const value = localStorage.getItem(key);
  return value ? (JSON.parse(value) as T) : fallback;
};

export const lsWrite = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getWindowSize = () => ({ height: window.innerHeight, width: window.innerWidth });

export const setDataAttribute = (key: string, value: string, element = document.body) =>
  element.setAttribute(`data-${key}`, value);

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      notifySuccess('Copied to clipboard');
    },
    () => {
      notifyError('Could not copy text');
    },
  );
};
export const setFocus = (el: HTMLElement | null) => {
  if (document.activeElement !== el) {
    el?.focus();
  }
};
export const scrollIntoView = (el: HTMLElement | null) =>
  el?.scrollIntoView({ behavior: 'smooth' });

export const addClass = (el: HTMLElement | null, cls: string) => el?.classList.add(cls);
export const rmClass = (el: HTMLElement | null, cls: string) => el?.classList.remove(cls);

export const addClassList = (el: HTMLElement | null, ...clsList: string[]) =>
  clsList.forEach((cls) => addClass(el, cls));
export const rmClassList = (el: HTMLElement | null, ...clsList: string[]) =>
  clsList.forEach((cls) => rmClass(el, cls));
