import type ng from 'angular';
import { container } from 'tsyringe';

import { BiDashboardsStore } from '~/features/common/bi-dashboards';
import type { Subscription } from '~/shared/lib/state';

import type { AnalyticsService } from '../analytics.service';
import template from './analytics-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { QueryParams } from '^/app/core/types';

class AnalyticsPageController implements ng.IController {
  initQueryParams: QueryParams = {};
  queryParams: QueryParams = {};
  dashboardsStore;
  usersDashboardsSub?: Subscription;

  static readonly $inject = ['PageService', 'AnalyticsService'];
  constructor(
    private readonly PageService: PageService,
    private readonly AnalyticsService: AnalyticsService,
  ) {
    this.dashboardsStore = container.resolve(BiDashboardsStore);
  }

  $onInit() {
    this.queryParams = { ...this.initQueryParams };
    this.PageService.setConfig(this.getPageConfig());

    this.usersDashboardsSub = this.dashboardsStore.usersDashboards$.subscribe((dashboards) => {
      const dashboard = dashboards.find((db) => db.slug === this.queryParams.dashboard);
      if (!dashboard) {
        throw new Error('Dashboard not found');
      }
      this.dashboardsStore.dashboardSelected(dashboard);
    });
  }

  $onDestroy() {
    this.usersDashboardsSub?.unsubscribe();
  }

  getPageConfig() {
    return this.AnalyticsService.getAnalyticPageConfig(String(this.queryParams.page));
  }
}

export const AnalyticsPageComponent = {
  bindings: {
    initQueryParams: '<?',
  },
  template,
  controller: AnalyticsPageController,
};
