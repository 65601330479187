import { createIconComponent } from '../lib';

const iconPath =
  '<path d="M5 21V5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L13.0815 17.1953C12.4227 16.7717 11.5773 16.7717 10.9185 17.1953L5 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none"/>';

export const BookmarkOutlineIcon = createIconComponent(
  iconPath,
  'BookmarkOutlineIcon',
  '0 0 24 24',
);
