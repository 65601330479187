import template from './comm-fees-grouped-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const CommFeesGroupedContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    closeDetails: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'OperationsService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      OperationsService: any;
      data: any;
      dataCount = 0;
      filterLevel = '';
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      totals: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        OperationsService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.OperationsService = OperationsService;

        this.data = undefined;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'comm-fees-grouped-container';
        this.queryParams = { page_size: 20, ...this.initQueryParams };
        this.data = [];
        this.dataCount = 0;
        this.totals = {};

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.OperationsService.getCommFeesGrouped(this.queryParams).then((data: any) => {
          this.data = data;
          this.GtUtils.overlay('hide');
        });
      }
    },
  ],
};
