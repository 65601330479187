import { ContentType, coreContentTypesList } from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { Paginated } from '~/shared/lib/entities';

import { ContentTypesParams } from '../lib/types';

@singleton()
export class ContentTypesRepository {
  queryContentTypes = async (params: ContentTypesParams): Promise<Paginated<ContentType>> => {
    const { data } = await coreContentTypesList({ query: params });
    return { records: data.results, count: data.count };
  };
}
