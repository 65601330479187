import {
  stockexchangesMeasurementsList,
  stockexchangesMeasurementsPredictionsRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Measurement, MeasurementListParams } from '../lib/types';

export class MeasurementRepository extends EntityRepository<Measurement, MeasurementListParams> {
  readonly entityName = 'stockexchanges.Measurement';

  getFn = (_id: number): Promise<Measurement> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: Measurement): Promise<Measurement> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Measurement>): Promise<Measurement> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: MeasurementListParams) => {
    const { data } = await stockexchangesMeasurementsList({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await stockexchangesMeasurementsPredictionsRetrieve({
      query: { title: input },
    });
    return data.results;
  };
}
