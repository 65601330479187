import template from './comments-table.html?raw';
import type { GtUtilsService } from '../../legacy/gt-utils/gt-utils.srv';

export const CommentsTable = {
  bindings: {
    objectId: '<',
    contentType: '<?',
  },
  template,
  controller: [
    'CommentsService',
    'GtUtils',
    class {
      CommentsService: any;
      GtUtils: GtUtilsService;
      comments: any;
      commentsCount = 0;
      contentType: any;
      filterLevel = 'comments-container';
      newComment: any;
      objectId?: number;
      constructor(CommentsService: any, GtUtils: GtUtilsService) {
        this.CommentsService = CommentsService;
        this.GtUtils = GtUtils;
      }

      $onInit() {
        this.updateComments();
      }

      updateComments() {
        this.CommentsService.getComments({
          content_type: this.contentType,
          object_id: this.objectId,
          page_size: 200,
        }).then((data: any) => {
          this.comments = data.results;
          this.commentsCount = data.count;
          this.newComment = this.CommentsService.getNewComment(this.contentType, this.objectId);
        });
      }

      saveComment(comment: any) {
        this.CommentsService.saveComment(comment)
          .then(() => this.updateComments())
          .catch(this.GtUtils.errorClb);
      }

      deleteComment(comment: any) {
        this.CommentsService.deleteComment(comment)
          .then(() => this.updateComments())
          .catch(this.GtUtils.errorClb);
      }
    },
  ],
};
