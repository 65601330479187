import type ng from 'angular';

import template from './freight-position-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const FreightPositionListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
  },
  template,
  controller: [
    '$q',
    '$scope',
    'GtUtils',
    'gtFilterService',
    'gettext',
    'FreightPositionService',
    'ContractsService',
    'LogisticsService',
    class sampleControlContainer {
      $q: ng.IQService;
      $scope: ng.IScope;
      ContractsService: ContractsService;
      FreightPositionService: any;
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      dataCount: number;
      dataList: any;
      dataTotal: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        $q: ng.IQService,
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
        FreightPositionService: any,
        ContractsService: ContractsService,
        LogisticsService: LogisticsService,
      ) {
        this.$q = $q;
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;
        this.FreightPositionService = FreightPositionService;
        this.ContractsService = ContractsService;
        this.LogisticsService = LogisticsService;

        this.filterLevel = '';
        this.queryParams = {};

        this.dataList = [];
        this.dataCount = 0;
        this.dataTotal = {};

        this.initQueryParams = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'freight-position-page-view';
        this.queryParams = { page_size: 20, ...this.initQueryParams };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: QueryParams) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.FreightPositionService.getListData(this.queryParams).then((data: any) => {
          this.dataList = data.data.results;
          this.dataCount = data.data.count;
          return this.FreightPositionService.getTotalsData(this.queryParams).then((data: any) => {
            this.dataTotal = data.data;
            this.GtUtils.overlay('hide');
          });
        });
      }

      openContractModal(contract: any) {
        return this.ContractsService.contractModal(contract).then(() => {
          this.updateData();
        });
      }

      openVoyageModal(voyage: any) {
        return this.LogisticsService.voyageModal(voyage).then(() => this.updateData());
      }
    },
  ],
};
