import ng from 'angular';

import { CCDFormFieldsService } from './ccd-form-field.service';
import { CustomsCargoDeclarationListContainer } from './customs-cargo-declaration-list-container/customs-cargo-declaration-list-container.component';
import { CustomsCargoDeclarationListTableView } from './customs-cargo-declaration-list-table-view/customs-cargo-declaration-list-table-view.component';
import { CustomsCargoDeclarationModal } from './customs-cargo-declaration-modal/customs-cargo-declaration-modal.component';
import { CustomsCargoDeclarationPageView } from './customs-cargo-declaration-page-view/customs-cargo-declaration-page-view.component';
import { CustomsCargoDeclarationService } from './customs-cargo-declaration.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const customsCargoDeclaration = ng
  .module('execution.transport.customsCargoDeclaration', [])
  .service('CustomsCargoDeclarationService', CustomsCargoDeclarationService)
  .service('CCDFormFieldsService', CCDFormFieldsService)
  .component('customsCargoDeclarationPageView', CustomsCargoDeclarationPageView)
  .component('customsCargoDeclarationListContainer', CustomsCargoDeclarationListContainer)
  .component('customsCargoDeclarationListTableView', CustomsCargoDeclarationListTableView)
  .component('customsCargoDeclarationModal', CustomsCargoDeclarationModal)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('logistics.customsCargoDeclaration', {
        url: '/customs-cargo-declaration',
        component: 'customsCargoDeclarationPageView',
        data: {
          pageTitle: gettext('Customs declarations'),
          permissions: {
            only: 'view_customscargodeclaration',
          },
        },
        resolve: {
          filterLevel: () => {
            return 'customs-cargo-declaration-page-view';
          },
          queryParams: [
            'PageService',
            (PageService: PageService) => {
              return PageService.syncUrlFilter('customs-cargo-declaration-page-view');
            },
          ],
        },
      });
    },
  ]).name;
