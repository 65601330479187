import { notify } from '~/shared/lib/notify';

import template from './role-limits.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const RoleLimits = {
  bindings: {
    roleName: '<',
    roleId: '<',
  },
  template,
  controller: [
    'ClientsService',
    'ContractsService',
    'GtUtils',
    'gettext',
    class {
      ClientsService: any;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      edit: any;
      gettext: ng.gettext.gettextFunction;
      roleId?: number;
      roleName = '';
      roleWithLimits: any;
      constructor(
        ClientsService: any,
        ContractsService: ContractsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.ClientsService = ClientsService;
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.edit = false;
        this.roleWithLimits = {};
      }

      $onInit() {
        this.updateRoleLimits();
      }

      updateRoleLimits() {
        if (!this.roleId) {
          throw new Error('Role ID is required');
        }
        this.GtUtils.overlay('show');
        return this.ContractsService.getRoleLimits(this.roleName, this.roleId)
          .then((data: any) => {
            this.roleWithLimits = { id: this.roleId, ...data };
          }, this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }

      save() {
        this.GtUtils.overlay('show');
        return this.ClientsService.saveRole(this.roleWithLimits, this.roleName)
          .then(() => {
            notify(this.gettext('Limit saved'));
            this.updateRoleLimits();
            this.edit = false;
          }, this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }
    },
  ],
};
