import { BehaviorSubject, tap } from 'rxjs';

import { singleton } from '~/shared/lib/di';
import { lsRead, lsWrite, setDataAttribute } from '~/shared/lib/utils';

const LS_KEY = 'gt-theme';
const SECTIONS = ['start', 'crm', 'company', 'deals', 'execution', 'finance', 'reports'];
type Mode = 'dark' | 'light';

singleton();
export class ThemeStore {
  private readonly modeSubj = new BehaviorSubject<Mode>(lsRead(LS_KEY, 'light'));
  private readonly sectionSubj = new BehaviorSubject<(typeof SECTIONS)[number]>('start');
  private readonly titleSubj = new BehaviorSubject('GrainTrack');
  private readonly fontSubj = new BehaviorSubject('inter');

  constructor() {
    setDataAttribute('mode', this.modeSubj.value);
  }

  readonly fonts = {
    inter: 'Inter (Default)',
    'source-sans-pro': 'Source Sans Pro',
    'proxima-soft': 'Proxima Soft (wide but soft)',
    'fira-sans': 'Fira Sans (condensed and accurate)',
    'sf-pro-display': 'San Francisco (by Apple, standard Telegram font)',
    // nunito: 'Nunito (nice font)',
    'proxima-nova-condensed': 'Proxima Nova Condensed (narrow texts)',
    roboto: 'Roboto (standard Google)',
    rawline: 'Rawline (very wide and raw)',
    barlow: 'Barlow (Latin only)',
  };

  readonly mode$ = this.modeSubj.pipe(tap((mode) => lsWrite(LS_KEY, mode)));
  readonly section$ = this.sectionSubj.asObservable();
  readonly title$ = this.titleSubj.asObservable();
  readonly font$ = this.fontSubj.asObservable();

  public modeChanged(mode: Mode) {
    setDataAttribute('mode', mode);
    this.modeSubj.next(mode);
  }

  public locationChanged(pathname: string) {
    const chapter = pathname.split('/')[1];
    const newSection = SECTIONS.includes(chapter) ? chapter : 'start';
    if (newSection !== this.sectionSubj.value) {
      setDataAttribute('section', newSection);
      this.sectionSubj.next(newSection);
    }
  }

  public setTitle(title: string) {
    this.titleSubj.next(title);
  }

  public setFont(font: string) {
    font = font && font in this.fonts ? font : 'inter';
    this.fontSubj.next(font);
  }
}
