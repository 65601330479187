import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './client-modal.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export const ClientModal = {
  bindings: {
    modalInstance: '<',
    client: '<?',
    tab: '<?',
  },
  template,
  controller: [
    '$state',
    '$uibModal',
    '$rootScope',
    '$scope',
    '$window',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'ClientFormFieldsService',
    'ClientsService',
    'CoreService',
    'AccountsService',
    'CustomValuesService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $uibModal: ng.ui.bootstrap.IModalService;
      $window: ng.IWindowService;
      AccountsService: AccountsService;
      ClientFormFieldsService: any;
      ClientsService: any;
      CoreService: CoreService;
      CustomValuesService: CustomValuesService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      client: any;
      clientVerificationFields: any;
      contentTypeId?: number;
      customValues: any;
      fields: any;
      fieldsTemplateOptions: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      stayAfterSave: any;
      tab = 'edit';
      constructor(
        $state: ng.ui.IStateService,
        $uibModal: ng.ui.bootstrap.IModalService,
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $window: ng.IWindowService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        ClientFormFieldsService: any,
        ClientsService: any,
        CoreService: CoreService,
        AccountsService: AccountsService,
        CustomValuesService: CustomValuesService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$window = $window;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.ClientFormFieldsService = ClientFormFieldsService;
        this.CustomValuesService = CustomValuesService;
        this.ClientsService = ClientsService;
        this.CoreService = CoreService;
        this.AccountsService = AccountsService;
        this.$state = $state;
        this.$uibModal = $uibModal;

        this.modalInstance = undefined;
        this.client = undefined;

        this.form = undefined;
        this.stayAfterSave = false;
        this.fields = [];
      }

      $onInit() {
        this.client = this.client || {};
        this.tab = this.tab || 'edit';
        this.clientVerificationFields = ['has_sanctions', 'has_criminal', 'has_tax_debt'];
        if (this.client.id) {
          this.updateData();
        } else {
          this.updateFields();
        }
        this.CoreService.getModelContentType('client').then(
          (ct: any) => (this.contentTypeId = ct.id),
        );

        this.$scope.$on('custom-values-updated__client', (event: any, data: any) => {
          this.customValues = data;
        });
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.ClientsService.getClient(this.client.id, { serializer: 'edit_modal' }).then(
          (data: any) => {
            this.client = data;
            if (data.client_verification.length) {
              this.clientVerificationFields.forEach((key: any) => {
                this.client[key] = data.client_verification[0][key];
              });
            }
            this.updateFields();
            this.GtUtils.overlay('hide');
          },
        );
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        this.CoreService.confirmDeletionModal(this.ClientsService.Client, this.client.id).then(
          (data: any) => {
            if (data == 'delete') {
              this.close('delete', true);
            }
          },
        );
      }

      copyVerificationFieldsToSave() {
        this.client.client_verification = [{}];
        this.clientVerificationFields.forEach((key: any) => {
          this.client.client_verification[0][key] = this.client[key];
        });
      }

      save() {
        this.copyVerificationFieldsToSave();
        const clientId = this.client.id;
        return this.ClientsService.saveClient(this.client).then(
          (data: any) => {
            if (this.stayAfterSave) {
              this.client = data;
              this.updateData();
              this.close(data, true);
              if (this.$rootScope.user.settings.BANK_INFO_REQUIRED_FOR_CLIENT) {
                this.$state.go('gt.page.client', { id: this.client.id, tab: 'banks' }).then(() => {
                  this.openPaymentInfoModal({ client: this.client.id });
                });
              } else {
                this.$state.go('gt.page.client', { id: this.client.id });
              }
            } else {
              this.close(data, true);
            }

            if (clientId === undefined) {
              this.CustomValuesService.createCustomValues(data.id, this.customValues);
            }

            notify(this.gettext('Counterparty saved'));
          },
          (error: any) => {
            this.stayAfterSave = false;
            this.GtUtils.errorClb(error);
            this.client.errors = error.data; // show server error messages on inputs
          },
        );
      }

      openPaymentInfoModal(paymentInfo: any) {
        return this.$uibModal.open({
          backdrop: 'static',
          appendTo: getModalRoot(),
          template: html`<payment-info-modal
            payment-info="paymentInfo"
            modal-instance="$uibModalInstance"
          >
          </payment-info-modal>`,
          controller: [
            '$scope',
            '$uibModalInstance',
            ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
              ($scope as any).$uibModalInstance = $uibModalInstance;
              ($scope as any).paymentInfo = paymentInfo;
            },
          ],
          windowClass: 'modal-template-third-width',
          size: 'sm',
        }).result;
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => {
            this.fields = fields;
            this.fieldsTemplateOptions = this.getFlatFields(fields);
          })
          .catch(this.GtUtils.errorClb);
      }

      getFlatFields(fields: any) {
        return this.FormFieldParamsService.getFlatFields({ fieldsDef: fields }).map(
          (field: any) => ({
            ...field.templateOptions,
            key: field.key,
          }),
        );
      }

      hasRoles(roles: any) {
        return this.client.role_names?.some((i: any) => roles.includes(i));
      }

      getFormConfig() {
        return this.ClientFormFieldsService.getFields(this.client);
      }
    },
  ],
};
