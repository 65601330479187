import ng from 'angular';

import type { PageService } from '../../components/gt-page/gt-page.srv';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CoreService } from '^/app/core/core.service';

(function () {
  'use strict';
  ng.module('core.legacy').component('settingsListsPageView', {
    bindings: {},
    template: require('./settings-lists-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$injector'];

  function Controller(this: any, $injector: ng.auto.IInjectorService) {
    const vm = this;
    vm.PageService = $injector.get<PageService>('PageService');
    vm.CoreService = $injector.get<CoreService>('CoreService');
    vm.AccountsService = $injector.get<AccountsService>('AccountsService');
    vm.counters = {};
    vm.openFrame = openFrame;

    vm.$onInit = function () {
      vm.PageService.setConfig(getPageConfig());
      vm.subtab = vm.AccountsService.hasPerm('view_user') ? 'users' : 'groups';
    };

    ////////////////

    function getPageConfig() {
      return {
        class: 'page-header-main-pl-tab',
        buttons: [new vm.PageService.buttons.Print()],
        pages: vm.CoreService.getPagesConfig(),
        filters: { clicked: false },
      };
    }

    function openFrame(frameName: string) {
      vm.subtab = frameName;
    }
  }
})();
