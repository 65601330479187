import type ng from 'angular';

import template from './transportation-storage-expense-modal.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

export const TransportationStorageExpenseModal = {
  bindings: {
    expense: '<',
    modalInstance: '<',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'GtUtils',
    'gettext',
    'CoreService',
    'TransportationStorageExpenseFormFieldsService',
    'TransportationStorageExpenseService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      TransportationStorageExpenseFormFieldsService: any;
      TransportationStorageExpenseService: any;
      expense: any;
      extra: any;
      fields: any;
      form: any;
      formName: string;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      positions: any;
      stayAfterSave: any;
      tab: string;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        CoreService: CoreService,
        TransportationStorageExpenseFormFieldsService: any,
        TransportationStorageExpenseService: any,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.CoreService = CoreService;
        this.TransportationStorageExpenseFormFieldsService =
          TransportationStorageExpenseFormFieldsService;
        this.TransportationStorageExpenseService = TransportationStorageExpenseService;

        this.modalInstance = undefined;
        this.expense = undefined;

        this.tab = 'edit';
        this.form = undefined;
        this.fields = [];
        this.stayAfterSave = false;
        this.formName = '';
      }
      $onInit() {
        this.expense = this.expense || {};
        this.extra = this.extra || {};
        this.tab = this.extra.tab || 'edit';
        this.formName =
          this.extra.formName || 'transportation-storage-expense-modal-positions-form';
        this.initData();
      }
      $onChange(changes: any) {
        if (!changes.expense) {
          return;
        }
        this.expense = this.expense || {};
        this.extra = this.extra || {};
        this.tab = this.extra.tab || 'edit';
        this.initData();
      }
      initData() {
        this.stayAfterSave = !this.expense.id;
        if (this.expense.id) {
          return this.updateData().then(() => this.updateFields());
        } else {
          this.getContentType();
          this.updateFields();
        }
      }
      updateData() {
        this.GtUtils.overlay('show');
        return this.TransportationStorageExpenseService.getExpenseModal(this.expense.id)
          .then((data: any) => {
            this.expense = data;
            this.updateFields();
            this.GtUtils.overlay('hide');
          })
          .catch(this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }
      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }
      save() {
        this.GtUtils.overlay('show');
        return this.TransportationStorageExpenseService.saveExpense(this.expense)
          .then((data: any) => {
            this.expense.id = data.id;
            if (!this.stayAfterSave) {
              return this.modalInstance.close(data || 'close');
            }
            return this.initData();
          })
          .catch(this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }
      destroy() {
        this.GtUtils.overlay('show');
        return this.TransportationStorageExpenseService.deleteExpense(this.expense)
          .then(() => this.modalInstance.close('delete', true))
          .catch(this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }
      getContentType() {
        this.CoreService.getContentType('transportationstorageexpense').then((data: any) => {
          this.expense.content_type = data.id;
        });
      }
      destroyPosition(position: any) {
        this.expense.positions = this.expense.positions.filter((pos: any) => pos !== position);
        if (position.id) {
          return this.TransportationStorageExpenseService.deletePosition(position.id);
        }
      }
      updateFields() {
        this.TransportationStorageExpenseFormFieldsService.getExpenseFields(
          this.expense,
          this.formName,
        ).then((fields: any) => {
          this.fields = fields;
        });
      }
      openFieldsConfigModal() {
        return this.TransportationStorageExpenseFormFieldsService.openExpenseFieldConfigModal(
          this.expense,
          this.formName,
        ).then(() => this.updateFields());
      }
      updatePositions(positions: any) {
        this.positions = positions;
      }
      completePositionsData() {
        this.GtUtils.overlay('show');
        return this.TransportationStorageExpenseService.preparePositionsData(this.expense)
          .catch(this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }
      distributeCosts() {
        this.GtUtils.overlay('show');
        const totals = {
          base: this.expense.positions.reduce(
            (base: any, pos: any) => base + (pos[this.expense.base] || 0),
            0,
          ),
          costs: this.expense.costs_amount,
        };
        this.expense.positions.forEach((pos: any) => {
          pos.costs_amount =
            Math.round(((totals.costs * pos[this.expense.base]) / totals.base) * 100) / 100;
          totals.costs -= pos.costs_amount;
          totals.base -= pos[this.expense.base] || 0;
        });
        this.GtUtils.overlay('hide');
      }
    },
  ],
};
