import React from 'react';

import { useSidebar } from '../../hooks';
import { BurgerMenu } from '../burger-menu';

export const PageTopBar: React.FC<{
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}> = ({ leftContent, rightContent }) => {
  const { sidebarOpen } = useSidebar();

  return (
    <header
      className="border-stroke-main-medium bg-background-main-primary sticky top-0 z-20 flex h-[52px] w-full items-center justify-between border-b px-3"
      data-testid="page-top-bar"
    >
      <section className="flex items-center gap-7">
        {!sidebarOpen && <BurgerMenu />}
        <div>{leftContent}</div>
      </section>
      <section>{rightContent}</section>
    </header>
  );
};
