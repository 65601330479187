import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const contractDetailsPage = {
  bindings: {
    filterLevel: '<?',
  },
  template: require('./contract-details-page.html?raw'),
  controller: [
    '$stateParams',
    'ContractFilterConfigService',
    'PageService',
    class {
      $stateParams: ng.ui.IStateParamsService;
      ContractFilterConfigService: any;
      PageService: PageService;
      contract: any;
      filterLevel: string;
      constructor(
        $stateParams: ng.ui.IStateParamsService,
        ContractFilterConfigService: any,
        PageService: PageService,
      ) {
        this.$stateParams = $stateParams;
        this.ContractFilterConfigService = ContractFilterConfigService;
        this.PageService = PageService;

        this.contract = {};
        this.filterLevel = '';
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'contract-side-list';
        this.contract = { id: this.$stateParams.id };
        const config = this.PageService.getConfig();
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            filters: this.ContractFilterConfigService.getFilterConfig(this.filterLevel),
            pages: [],
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            filters: this.ContractFilterConfigService.getFilterConfig(this.filterLevel),
            pages: [],
          });
        }
      }

      updateConfigWithFilterLevel(contract: any, filterLevel: string) {
        const config = this.PageService.getConfig();
        let isOpen;

        if (ng.equals(config, {}) || config.sidebar?.is_open) {
          isOpen = true;
        } else {
          isOpen = false;
        }
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            sidebar: {
              verboseName: contract.contract_type + ' Contracts',
              modelName: `contracts.${contract.contract_type}contract`,
              instance: contract,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            sidebar: {
              verboseName: contract.contract_type + ' Contracts',
              modelName: `contracts.${contract.contract_type}contract`,
              instance: contract,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
            filters: this.ContractFilterConfigService.getFilterConfig(filterLevel),
            pages: [],
          });
        }
      }
    },
  ],
};
