import type { ContainerProps } from '~/core/entity-lists';
import { EntityListContainerModel } from '~/core/entity-lists';
import {
  type BaseLogisticRecord,
  type ConnectionMode,
  type InvoicePurpose,
  type InvoicingVolume,
  type Logistic,
  type LogisticPatch,
  type LogisticPost,
  type LogisticQueryParams,
  chunkQuery,
  concatListTotalsChunks,
  logisticInvoicingConfig,
} from '~/features/execution/logistic/common/lib';
import type { BaseLogisticRepository } from '~/features/execution/logistic/common/services';
import { LogisticService } from '~/features/execution/logistic/common/services';
import { container } from '~/shared/lib/di';
import type { Entity } from '~/shared/lib/entities';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  map,
  of,
  startWith,
  switchMap,
} from '~/shared/lib/state';

export type BaseLogisticListContainerProps = ContainerProps<LogisticQueryParams>;

export abstract class BaseLogisticListModel<
  TEntityRecord extends Entity = BaseLogisticRecord,
  TRepo extends BaseLogisticRepository<TEntityRecord> = BaseLogisticRepository<TEntityRecord>,
> extends EntityListContainerModel<
  Logistic,
  LogisticQueryParams,
  TRepo,
  BaseLogisticListContainerProps,
  TEntityRecord,
  LogisticPost,
  LogisticPatch
> {
  private readonly logisticService = container.resolve(LogisticService);
  private readonly selectedItemsSubj = new BehaviorSubject<number[]>([]);
  private readonly connectionModeSubj = new BehaviorSubject<ConnectionMode | undefined>(undefined);
  private readonly invoicePurposeSubj = new BehaviorSubject<InvoicePurpose | undefined>(undefined);
  private readonly invoicingVolumeSubj = new BehaviorSubject<InvoicingVolume | undefined>(
    undefined,
  );

  readonly selectedItems$ = this.selectedItemsSubj.asObservable();
  readonly invoicePurpose$ = this.invoicePurposeSubj.asObservable();
  readonly connectionMode$ = this.connectionModeSubj.asObservable();
  readonly invoicingContractType$ = this.invoicePurposeSubj.pipe(
    map((purpose) => (purpose ? logisticInvoicingConfig[purpose].contractType : undefined)),
  );

  readonly selectableItems$ = combineLatest([
    this.invoicePurpose$,
    this.invoicingVolumeSubj,
    this.connectionMode$,
    this.pageData$,
  ]).pipe(
    map(([invoicePurpose, invoicingVolume, connectionMode, { records }]) =>
      connectionMode
        ? records
            .filter((record) => this.logisticService.isLogisticConnectable(record, connectionMode))
            .map((record) => record.id)
        : records
            .filter((record) =>
              this.logisticService.isLogisticInvoiceable(record, invoicePurpose, invoicingVolume),
            )
            .map((record) => record.id),
    ),
    startWith([]),
  );

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) =>
      forkJoin([
        this.repo.getListTotals(params),
        this.repo.getListTotals({ ...params, logistic_type: 'main' }),
        this.repo.getListTotals({ ...params, logistic_type: 'intermediate' }),
      ]).pipe(
        map(([totals, mainTotals, intermediateTotals]) => ({
          totals,
          mainTotals,
          intermediateTotals,
        })),
      ),
    ),
    startWith({
      totals: {} as Awaited<ReturnType<TRepo['getListTotals']>>,
      mainTotals: {} as Awaited<ReturnType<TRepo['getListTotals']>>,
      intermediateTotals: {} as Awaited<ReturnType<TRepo['getListTotals']>>,
    }),
  );
  readonly selectedItemsTotals$ = this.selectedItems$.pipe(
    switchMap((selectedItems) => of(chunkQuery({ id_list: selectedItems.map(String) }))),
    switchMap((chunks) => of(chunks.map((chunk) => this.repo.getListTotals(chunk)))),
    switchMap((totals) => concatListTotalsChunks(totals)),
  );

  connectionModeChanged = (mode?: ConnectionMode) => {
    this.connectionModeSubj.next(mode);
  };

  invoicePurposeChanged = (purpose?: InvoicePurpose) => {
    this.invoicePurposeSubj.next(purpose);
  };

  invoicingVolumeChanged = (invoicingVolume?: InvoicingVolume) => {
    this.invoicingVolumeSubj.next(invoicingVolume);
  };

  selectedItemsChanged = (selected: number[]) => {
    this.selectedItemsSubj.next(selected);
  };

  toggleItemSelection = (id: number) => {
    const currentSelection = this.selectedItemsSubj.getValue();

    if (currentSelection.includes(id)) {
      this.selectedItemsSubj.next(currentSelection.filter((selectedId) => selectedId !== id));
    } else {
      this.selectedItemsSubj.next([...currentSelection, id]);
    }
  };

  resetConnectionModeAndInvoicing = (updated: boolean) => {
    this.invoicePurposeChanged();
    this.invoicingVolumeChanged();
    this.connectionModeChanged();
    if (updated) {
      this.pageParamsChanged({});
    }
  };

  recalculatePurchasePrice = async () => {
    this.loadingChanged(true);
    try {
      await this.repo.setPurchasePriceFromIndicator();
      notifySuccess('Purchase price updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.loadingChanged(false);
      this.pageParamsChanged({});
    }
  };

  applyDiscount = async () => {
    this.loadingChanged(true);
    try {
      await this.repo.setDiscountByQuality();
      notifySuccess('Discount updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.loadingChanged(false);
      this.pageParamsChanged({});
    }
  };

  updateApprovalStatus = async () => {
    this.loadingChanged(true);
    try {
      await this.repo.setApprovalStatusProcess();
      notifySuccess('Approval status updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.loadingChanged(false);
      this.pageParamsChanged({});
    }
  };

  updateLogistic = async (logistic: LogisticPatch) => {
    return await this.repo.update(logistic);
  };
}
