import template from './dbl-logistics-list-container.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const DblLogisticsListContainer = {
  bindings: {
    dbl: '<',
    queryParams: '<?',
  },
  template,
  controller: [
    'LogisticsService',
    'GtUtils',
    class {
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      dbl: any;
      logisticsData: any;
      queryParams: QueryParams = {};
      constructor(LogisticsService: LogisticsService, GtUtils: GtUtilsService) {
        this.LogisticsService = LogisticsService;
        this.GtUtils = GtUtils;

        this.logisticsData = {};
        this.queryParams = {};
        this.dbl = {};
      }

      $onInit() {
        this.updateData();
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.LogisticsService.Logistic.getDblLogisticsList({ dbl_id: this.dbl.id }).$promise.then(
          (data: any) => {
            this.logisticsData = data;
            this.GtUtils.overlay('hide');
          },
        );
      }
    },
  ],
};
