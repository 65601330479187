import type ng from 'angular';

import { BiDashboardsStore } from '~/features/common/bi-dashboards';
import { container } from '~/shared/lib/di';

import template from './quantitative-metrics-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

class QuantitativeMetricsPageController implements ng.IController {
  dashboardsStore;

  static readonly $inject = ['PageService', 'ReportsService'];
  constructor(
    private readonly PageService: PageService,
    private readonly ReportsService: ReportsService,
  ) {
    this.dashboardsStore = container.resolve(BiDashboardsStore);
  }

  $onInit(): void {
    this.PageService.setConfig(this.getPageConfig());
  }

  getPageConfig() {
    return {
      class: 'page-header-main-clients-tab',
      buttons: [
        new this.PageService.buttons.Filters(),
        new this.PageService.buttons.Print(),
        new this.PageService.buttons.Refresh(),
      ],
      pages: this.ReportsService.getPagesConfig(),
      filters: {},
    };
  }
}

export const QuantitativeMetricsPage = {
  template,
  controller: QuantitativeMetricsPageController,
};
