import template from './loan-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const LoanPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'PageService',
    'gettext',
    class {
      PageService: PageService;
      filterLevel = 'loans-page-view';
      gettext: ng.gettext.gettextFunction;
      constructor(PageService: PageService, gettext: ng.gettext.gettextFunction) {
        this.PageService = PageService;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-payments-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
          ],
          pages: [
            {
              title: this.gettext('Loans'),
              permissions: [],
              state: 'finances.loansPage',
              icon: 'fa-arrows-alt',
              tabs: [],
            },
          ],
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            nestedSelects: [],
            multiSelects: [
              {
                argument: 'borrower_list',
                placeholder: this.gettext('borrowers'),
                resource: 'clients.clientrole',
              },
              {
                argument: 'lender_list',
                placeholder: this.gettext('lenders'),
                resource: 'clients.clientrole',
              },
              {
                argument: 'general_agreement_list',
                placeholder: this.gettext('general agreements'),
                resource: 'contracts.generalagreement',
              },
              {
                argument: 'id_list',
                placeholder: this.gettext('loans'),
                resource: 'finances.loan',
              },
              {
                argument: 'finance_purpose_list',
                placeholder: this.gettext('fin purposes'),
                resource: 'finances.financepurpose',
              },
              {
                argument: 'passport_list',
                placeholder: this.gettext('passports'),
                resource: 'passports.passport',
              },
            ],
            nestedMultiSelects: [
              {
                argument: 'status_list',
                placeholder: this.gettext('Status'),
                items: [
                  { id: 'forecast', title: this.gettext('Forecast') },
                  { id: 'processing', title: this.gettext('Processing') },
                  { id: 'done', title: this.gettext('Done') },
                  { id: 'cancelled', title: this.gettext('Cancelled') },
                ],
              },
            ],
          },
        };
      }
    },
  ],
};
