import type ng from 'angular';

import template from './contract-quality-rules-list-table-view.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';

export const ContractQualityRulesListTableView = {
  bindings: {
    list: '<',
    count: '<',
    total: '<',
    filterLevel: '<',
    initQueryParams: '<',
    applyFilters: '&',
    saveItem: '&',
    destroy: '&',
    updateData: '&',
    setHovering: '&',
    tableName: '<?',
    disableEdit: '<?',
  },
  template,
  controller: [
    'gettext',
    'GtUtils',
    '$window',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      GtUtils: GtUtilsService;
      commodityParams: any;
      count: number;
      destroy: any;
      gettext: ng.gettext.gettextFunction;
      initQueryParams: QueryParams = {};
      list: any;
      numberInput: any;
      saveItem: any;
      selection: any;
      tableData: any;
      tableName?: string;
      tableOptions: any;
      total: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        $window: ng.IWindowService,
        $rootScope: GtRootScopeService,
      ) {
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.$window = $window;
        this.$rootScope = $rootScope;

        this.list = [];
        this.count = 0;
        this.selection = false;
        this.numberInput = false;
        this.tableOptions = {};
        this.tableData = {};
        this.initQueryParams = {};
        this.commodityParams = {};
      }

      $onChanges(changes: any) {
        this.tableName = this.tableName ?? 'contract-quality-rules';
        if (changes.list || changes.count || changes.total) {
          this.tableData = { rows: this.list, count: this.count, total: this.total };
        }
        if (changes.initQueryParams && this.initQueryParams.cargoId) {
          this.commodityParams = { id_list: [this.initQueryParams.cargoId] };
        }
      }

      save(item: any) {
        this.saveItem({ item: item });
      }

      delete(item: any) {
        this.destroy({ item: item });
      }

      deletePosition(rule: any, position: any) {
        rule.positions.splice(rule.positions.indexOf(position), 1);
      }

      clone(item: any) {
        Object.assign(this.tableData.rows[0], item, {
          author: undefined,
          create_time: undefined,
          editor: undefined,
          update_time: undefined,
          id: undefined,
        });
      }

      addPosition(rule: any) {
        const newPosition = { from_value: 0, to_value: 0, coefficient: 1 };
        rule.positions.push(newPosition);
      }
    },
  ],
};
