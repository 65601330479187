import React from 'react';

import type { InjectionToken } from '../di';
import { container } from '../di';
import type { ComponentModel } from './component-model';
import type { ComponentProps, ComponentViewProps } from './types';

export const createComponent = <
  TViewProps extends ComponentViewProps<TModel, TProps>,
  // original type:  TProps extends ComponentProps = Omit<TViewProps, 'model'>,
  TProps extends ComponentProps & { model?: TViewProps['model'] } = Omit<TViewProps, 'model'> & {
    model?: TViewProps['model'];
  },
  TModel extends ComponentModel<TProps> = ComponentModel<TProps>,
>(
  ViewComponent: React.FC<TViewProps>,
  modelClass: InjectionToken<TModel>,
): React.FC<TProps> => {
  const ComponentWrapper: React.FC<TProps> = (props: TProps) => {
    const model = React.useMemo(() => {
      const model = (props.model as TModel) ?? container.resolve<TModel>(modelClass);
      model.props = props;
      return model;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
      model.props = props;
    }, [model, props]);

    return <ViewComponent {...({ ...props, model } as TViewProps)} />;
  };

  ComponentWrapper.displayName = `createComponent(${ViewComponent.displayName ?? ViewComponent.name ?? 'View'})`;

  return ComponentWrapper;
};

const ComponentContext = React.createContext<unknown>(null);

export const useComponent = <P extends ComponentProps, TModel extends ComponentModel<P>>(
  props: P & { model?: TModel },
  modelClass: InjectionToken<TModel>,
): {
  model: TModel;
  ComponentProvider: ({ children }: React.PropsWithChildren) => React.ReactNode;
} => {
  const model = React.useMemo(() => {
    const model = props.model ?? container.resolve<TModel>(modelClass);

    model.props = props;

    model.onInit(props);

    return model;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ComponentProvider = React.useMemo(() => {
    const ComponentProvider = ({ children }: React.PropsWithChildren) => (
      <ComponentContext.Provider value={model}>{children}</ComponentContext.Provider>
    );
    return ComponentProvider;
  }, [model]);

  React.useEffect(() => {
    model.props = props;
  }, [model, props]);

  return { model, ComponentProvider };
};

export const useComponentModel = <TModel extends object>() => {
  const model = React.useContext(ComponentContext);

  if (!model) {
    throw new Error('useComponentModel must be used within a ComponentProvider');
  }

  return model as TModel;
};
