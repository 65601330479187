import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './payment-plan-finances.html?raw';
import type { PaymentPlanFinancesService } from './payment-plan-finances.service';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const paymentPlanFinances = {
  bindings: {
    finances: '=',
    paymentPlanId: '<?',
    plannedExpenses: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gettext',
    'PaymentPlanFinancesService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      PaymentPlanFinancesService: PaymentPlanFinancesService;
      currentPage: any;
      defaultFinances: any;
      expenses: any;
      finances: any;
      financesCount: number;
      financesTotals: any;
      gettext: ng.gettext.gettextFunction;
      isDefaultFinancesSet: any;
      newFinancesRow: any;
      onFinanceSelect: any;
      paymentPlanId?: number;
      plannedExpenses: any;
      tempKey: any;
      updated: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        PaymentPlanFinancesService: PaymentPlanFinancesService,
      ) {
        this.PaymentPlanFinancesService = PaymentPlanFinancesService;
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.finances = [];
        this.financesTotals = [];
        this.newFinancesRow = {};
        this.isDefaultFinancesSet = false;
        this.defaultFinances = [];
        this.financesCount = 0;
        this.currentPage = 1;
        this.updated = false;

        this.expenses = {
          planned: 0,
          totalSelected: 0,
          balance: 0,
        };
        this.tempKey = 1;
        this.plannedExpenses = 0;
      }

      $onInit() {
        if (this.finances) {
          this.isDefaultFinancesSet = true;
          this.defaultFinances = JSON.parse(JSON.stringify(this.finances));
          this.calculateTotals();
          this.finances
            .filter((finance: any) => !finance.id)
            .forEach((finance: any) => {
              finance.tempKey = this.tempKey;
              finance.balance = finance.to_pay - finance.value;
              this.tempKey++;
            });
        } else {
          this.finances = [];
        }
        this.financesCount = this.finances.length;

        this.onFinanceSelect = (item: any) => this.validatePaymentPlanFinanceRow(item);

        this.$scope.$watch(
          () => this.finances,
          (newVal: string[]) => this.onFinanceChange(newVal),
          true,
        );

        if (this.paymentPlanId) {
          this.updateData();
        }
      }

      validatePaymentPlanFinanceRow(item: any) {
        this.GtUtils.overlay('show');

        this.PaymentPlanFinancesService.matchFinances(item)
          .then(() => {
            if (item && !item.finance) {
              item[0].value = 0;
            }

            if (item.finance) {
              this.validateNewPayment(item);
            }

            this.calculateTotals();
            item.balance = 0;
          })
          .catch((err: any) => {
            this.GtUtils.overlay('hide');
            this.GtUtils.errorClb(err);
          });
      }

      onFinanceChange(newVal: string[]) {
        if (!newVal.length) {
          return;
        }
        if (this.finances && !this.isDefaultFinancesSet) {
          this.isDefaultFinancesSet = true;
          this.financesCount = newVal.length;
          this.defaultFinances = JSON.parse(JSON.stringify(this.finances));
        }
        this.calculateExpenses();
      }

      addNewRow() {
        this.finances.unshift({ ...this.newFinancesRow, tempKey: this.tempKey });
        this.financesCount++;
        this.tempKey++;
      }

      updateData() {
        this.newFinancesRow = {
          additional_info: null,
          author: null,
          bank_name: null,
          business_unit: null,
          clientrole_from_name: null,
          clientrole_to_name: null,
          create_time: null,
          currency_exchange: null,
          editor: null,
          finance: null,
          finance_number: null,
          id: null,
          invoicepositions: [],
          payment_plan: null,
          update_time: null,
          value: null,
          updated: true,
        };

        return this.PaymentPlanFinancesService.getPaymentPlanFinances({
          payment_plan: this.paymentPlanId,
          ordering: '-update_time',
          page_size: '10000',
        }).then((response: any) => {
          this.finances.push(...response.results);
          this.financesCount = this.finances.length;
          this.calculateExpenses();
          this.calculateTotals();
        });
      }

      deletePaymentPlanFinance(paymentPlanFinance: any) {
        if (paymentPlanFinance.id) {
          this.PaymentPlanFinancesService.deletePaymentPlanFinance({
            id: paymentPlanFinance.id,
          })
            .then(() => {
              this.finances = this.finances.filter(
                (finance: any) => finance.id !== paymentPlanFinance.id,
              );

              this.financesCount = this.finances.length;
            })
            .catch((error: any) => {
              if (error.detail === 'Not found.') {
                this.finances = this.finances.filter(
                  (finance: any) => finance.id !== paymentPlanFinance.id,
                );
              } else {
                this.GtUtils.errorClb(error);
              }
            })
            .finally(() => {
              this.calculateExpenses();
              this.calculateTotals();
            });
        } else {
          this.finances = this.finances.filter(
            (finance: any) => finance.tempKey !== paymentPlanFinance.tempKey,
          );
          this.financesCount = this.finances.length;
          this.calculateExpenses();
          this.calculateTotals();
        }
      }

      calculateTotals() {
        this.financesTotals = this.finances
          ? this.PaymentPlanFinancesService.calculatePaymentPlanFinancesTotals(this.finances)
          : [];
      }

      calculateExpenses() {
        this.expenses.totalSelected = this.finances
          .reduce((acc: any, { value }: any) => acc + value, 0)
          .toFixed(2);
        this.expenses.balance = (
          Number(this.expenses.planned) - Number(this.expenses.totalSelected)
        ).toFixed(2);
      }

      recalculateExpensesValues(total: any) {
        const { client, currency, value, type } = total;
        const recalculatedFinances = this.finances.filter((finance: any) => {
          const isSameClient =
            finance.invoice_type === 'outgoing'
              ? finance.clientrole_to_name === client
              : finance.clientrole_from_name === client;
          return (
            isSameClient && finance.currency_symbol === currency && finance.invoice_type === type
          );
        });

        recalculatedFinances.sort(
          (first: any, second: any) =>
            new Date(first.finance_date).getTime() - new Date(second.finance_date).getTime(),
        );

        let maxTotalValue = 0;
        let sum = value;
        recalculatedFinances.forEach((item: any) => {
          const index = this.finances.findIndex(
            (finance: any) =>
              finance.id === item.id || (finance.tempKey && finance.tempKey === item.tempKey),
          );
          const maxValue = this.finances[index].invoicepositions.reduce(
            (acc: any, cur: any) => acc + cur.amount,
            0,
          );
          maxTotalValue += maxValue;

          if (sum > maxValue) {
            this.finances[index].value = maxValue;
            this.finances[index].updated = true;
            sum -= maxValue;
          } else {
            this.finances[index].value = sum;
            this.finances[index].updated = true;
            sum = 0;
          }
        });

        if (value > maxTotalValue) {
          total.value = maxTotalValue;
        }

        this.calculateExpenses();
      }

      onFinanceValueChange(financeID: any) {
        const index = this.finances.findIndex((finance: any) => finance.id === financeID);
        const maxValue =
          this.getPositionsSum(this.finances[index]) -
            this.getSamePaymentsSum(this.finances[index], index) || this.finances[index].to_pay;

        if (this.finances[index].value > maxValue) {
          this.finances[index].value = maxValue;
        }
        this.finances[index].balance = maxValue - this.finances[index].value;
        this.calculateTotals();
      }

      resetValues() {
        this.finances = JSON.parse(JSON.stringify(this.defaultFinances));
        this.calculateExpenses();
        this.calculateTotals();
      }

      isPaymentVisible(index: number) {
        const from = (this.currentPage - 1) * 25;
        const to = from + 25;
        return index >= from && index < to;
      }

      getPositionsSum(finance: any) {
        return finance.invoicepositions.reduce((acc: any, cur: any) => {
          return acc + cur.amount;
        }, 0);
      }

      getSamePaymentsSum(finance: any, index: number) {
        return this.finances.reduce(
          (acc: any, cur: any, curIndex: number) =>
            cur.finance === finance.finance && curIndex !== index ? acc + cur.value : acc,
          0,
        );
      }

      validateNewPayment(item: any) {
        this.PaymentPlanFinancesService.getPaymentsByFinance(item.finance)
          .then(
            (data: any) =>
              data.results.reduce((acc: any, cur: any) => acc + cur.value, 0) +
              this.finances
                .filter(({ tempKey }: any) => tempKey && tempKey !== item.tempKey)
                .reduce((acc: any, cur: any) => acc + cur.value, 0),
          )
          .then((sum: any) => {
            if (sum >= item.toPay) {
              notify(this.gettext('Invoice is already fully paid'), 'error');
              const index = this.finances.findIndex(
                (finance: any) => finance.tempKey === item.tempKey,
              );
              this.finances[index] = this.newFinancesRow;
            } else {
              item.value = item.toPay - sum;
            }
          })
          .catch(this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }
    },
  ],
};
