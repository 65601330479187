import React from 'react';

import { EntityAutocomplete } from './entity-autocomplete';
import type { EntityRepository } from '../../lib/entity-repository';
import type { Entity, EntityQueryParams } from '../../types';

export const entityAutocompleteWithSearch =
  (search: EntityRepository<Entity, EntityQueryParams>['search']) => () => {
    const EntityAutocompleteWithRepo: React.FC<
      Omit<React.ComponentProps<typeof EntityAutocomplete>, 'repo'>
    > = (props) => <EntityAutocomplete {...props} onSearch={search} />;

    EntityAutocompleteWithRepo.displayName = `entityAutocompleteWithRepo(${EntityAutocomplete.displayName ?? EntityAutocomplete.name})`;
    return EntityAutocompleteWithRepo;
  };
