import React from 'react';

import { errorHandler } from '~/shared/lib/errors';
import type { ListOption } from '~/shared/lib/types';
import { useDebouncedCallback } from '~/shared/lib/utils';
import { type EntityOption } from '~/shared/ui/data-types';
import { Autocomplete } from '~/shared/ui/kit/autocomplete';

import type { EntityRepository } from '../../lib/entity-repository';
import type { Entity, EntityQueryParams } from '../../types';

export const EntityAutocomplete: React.FC<
  Omit<
    React.ComponentProps<typeof Autocomplete>,
    'options' | 'onSearch' | 'valueChanged' | 'value'
  > & {
    onSearch?: EntityRepository<Entity, EntityQueryParams>['search'];
    value?: EntityOption[];
    valueChanged?: (value?: EntityOption[]) => void;
  }
> = ({ onSearch, value: values, valueChanged, ...props }) => {
  const [options, setOptions] = React.useState<ListOption[]>([]);
  const [entitiesById, setEntitiesById] = React.useState<Record<number, EntityOption>>({});

  const valueItems = React.useMemo(
    () => Object.values(entitiesById).map((i) => ({ label: i.title, value: i.id.toString() })),
    [entitiesById],
  );

  if (!onSearch) {
    throw new Error('EntityAutocomplete: onSearch is required');
  }

  React.useEffect(() => {
    const reduceEntities = (entities: EntityOption[]) =>
      entities.reduce((acc, v) => ({ ...acc, [v.id]: v }), {} as Record<number, EntityOption>);

    const getEntity = async (id: number) => {
      const entity = (await onSearch('', id)).find((e) => e.id === id);
      return { id: entity?.id ?? id, title: entity?.title ?? 'Not found' };
    };

    const prepareEntities = (entities: EntityOption[]) =>
      reduceEntities(entities.map((v) => (v.title ? v : { ...v, title: 'Loading...' })));

    const enrichEntities = async (entities: EntityOption[]) =>
      reduceEntities(await Promise.all(entities.map((i) => (i.title ? i : getEntity(i.id)))));

    if (values) {
      setEntitiesById(prepareEntities(values));
      enrichEntities(values).then(setEntitiesById).catch(errorHandler);
    }
  }, [onSearch, values]);

  const onSearchHandler = useDebouncedCallback((input: string) => {
    onSearch(input)
      .then((entities) => {
        setOptions(entities.map((o) => ({ label: o.title, value: o.id.toString() })));
      })
      .catch(errorHandler);
  }, 500);

  const onValueChangeHandler = React.useCallback(
    (option: ListOption[]) => {
      valueChanged?.(option.map((o) => ({ id: parseInt(o.value, 10), title: o.label })));
    },
    [valueChanged],
  );

  const onOpenChangeHandler = React.useCallback(
    (isOpen: boolean) => {
      if (isOpen && !options.length) {
        onSearchHandler('');
      }
    },
    [onSearchHandler, options.length],
  );

  return (
    <Autocomplete
      value={valueItems}
      onSearch={onSearchHandler}
      options={options}
      onChange={onValueChangeHandler}
      onOpenChange={onOpenChangeHandler}
      {...props}
    />
  );
};
