angular.module('core.legacy').run([
  'gettextCatalog',
  function (gettextCatalog) {
    /* jshint -W100 */
    gettextCatalog.setStrings('ru', {
      0: '0',
      1: '1',
      2: '2',
      3: '3',
      2019: '2019',
      2020: '2020',
      2021: '2021',
      2022: '2022',
      '"Reservation" deleted': '«Бронирование» удалено',
      '"Reservation" doesn\'t exist': '«Бронирование» не существует',
      '"Stock Reserve Unit" deleted': '«Единица резервного фонда» удалена',
      '"Stock Reserve Unit" doesn\'t exist': '«Единица резервного запаса» не существует',
      '#': '#',
      '# of containers': 'кол-во контейнеров',
      $: '$',
      '$/20dv': '$/20dv',
      '$/40dv': '$/40dv',
      '$/day': '$/день',
      '$/t': '$/т',
      '%': '%',
      '% from the deal': '% от сделки',
      '&gt; 105%': '&gt; 105%',
      '&lt; not selected &gt;': '&lt; не выбрано &gt;',
      "'Stock Reserve Unit' doesn`t exist": '«Единица резервного запаса» не существует',
      '(Boat required)': '(Необходимо указать Лодку)',
      '(admin)': '(админ)',
      '(purchase)': '(закупка)',
      '(sale)': '(продажа)',
      '(simple mode)': '(простой режим)',
      '+0 0 (000)000-00-00': '+0 0 (000)000-00-00',
      '---': '---',
      '-Payment conditions value must be 1-100': 'Условия оплаты должны быть в диапазоне 1-100',
      '...expand': '...расширять',
      '0 - 10': '0 - 10',
      '0%': '0%',
      '0-100': '0-100',
      '0-100%': '0-100%',
      '0-200': '0-200',
      '0-200%': '0-200%',
      '0-95%': '0-95%',
      '0000000': '0000000',
      '1) we calculate P&L passport by passport, from different perspectives: by\n                  contracts (as a plan), by execution (as a fact), by invoices (as stronger fact),\n                  etc.':
        '1. Мы рассчитываем P&L по паспортам с разных точек зрения: по контрактам (как план), по исполнению (как факт), по инвойсам (как более сильный факт) и т.д.',
      '1-100': '1-100',
      '100% matched': '100% совпадение',
      '11 - 30': '11 - 30',
      '1Q': '1Q',
      '1t': '1т',
      '2) we combine inside this result both physical and non physical operations by\n                  passport':
        '2) мы учитываем и физические и не физические операции \nв результате паспорта',
      '24h': '24ч',
      '2Q': '2Q',
      '3) when you press button CONTROL, we take the most actual P&L and put it here (you\n                  can choose which one, using MARGIN_SELECTOR inside passport modal window)':
        '3) при нажатии кнопки КОНТРОЛЬ, мы берем самый актуальный P&L и помещаем его сюда (вы можете выбрать какой именно, используя MARGIN_SELECTOR внутри модального окна паспорта)',
      '30-60 days': '30-60 дней',
      '31 - 60': '31 - 60',
      '3Q': '3Q',
      "4) then we take all contracts, and group them by certain criteria (origin,\n                  destination, client, etc). We know the PNL by each contract from it's share inside\n                  passport":
        '4) затем мы берём все контракты и группируем их по определённым критериям (происхождение, назначение, клиент и т. д.). Мы знаем PNL по каждому контракту на основе его доли внутри паспорта.',
      '5) and as a result you see the grouped list of passports inside certain month\n                  (depending on a business date appointed in the passport)':
        '5) как результат, вы видите сгруппированный список паспортов в пределах определенного месяца\n(На месяц влияет бизнес дата паспорта)',
      '60-90 days': '60-90 дней',
      '61 - 90': '61 - 90',
      '90 and over days': '90 и больше дней',
      '95-105%': '95-105%',
      '<i class="fa fa-file-excel-o ng-scope"></i>\n            <translate>Download</translate>':
        '<i class="fa fa-file-excel-o ng-scope"></i>\n            <translate>Download</translate>',
      '<i class="fa fa-file-excel-o ng-scope"></i>\n    <translate>Download</translate>':
        '<i class="fa fa-file-excel-o ng-scope"></i>\n    <translate>Download</translate>',
      '<i class="fa fa-file-text-o"></i> Create ticket':
        '<i class="fa fa-file-text-o"></i>Создать тикет',
      '<i class="fa fa-filter"></i> <translate>Apply</translate>':
        '<i class="fa fa-filter"></i> <translate>Применить</translate>',
      '<i class="fa fa-floppy-o"></i>': '<i class="fa fa-floppy-o"></i>',
      '<i class="fa fa-pencil-square"></i>': '<i class="fa fa-pencil-square"></i>',
      '<i class="fa fa-refresh"></i>': '<i class="fa fa-file-text-o"></i>',
      '<i class="fa fa-times"></i>': '<i class="fa fa-times"></i>',
      '<i class="fa fa-times-circle"></i>': '<i class="fa fa-times-circle"></i>',
      '<span class="ng-scope">Apply</span>': '<span class="ng-scope">Применить</span>',
      '<span class="ng-scope">Clear</span>': '<span class="ng-scope">Очистить</span>',
      '<span class="ng-scope">Save</span>': '<span class="ng-scope">Сохранить</span>',
      '<span ng-if="!withoutEndOfExecution"> {{ month |date:\'MMM-yyyy\' }}</span>\n              <span ng-if="withoutEndOfExecution">N/A</span>':
        '<span ng-if="!withoutEndOfExecution"> {{ month |date:\'MMM-yyyy\' }}</span>\n              <span ng-if="withoutEndOfExecution">N/A</span>',
      'A server error occurred. Please contact the administrator.':
        'Оой. Ошибка сервера. Свяжитесь с поддержкой.',
      ADD: 'ДОБАВИТЬ',
      'ADD NEW': 'ДОБАВИТЬ НОВЫЙ',
      ADDITIONAL: 'ДОПОЛНИТЕЛЬНО',
      'ADDITIONAL AGGR.': 'ДОПОЛНИТЕЛЬНОЕ СОГЛАШ.',
      ALL: 'ВСЕ',
      AMOUNT: 'СУММА',
      AMOUNTS: 'СУММЫ',
      ANALYTICAL: 'АНАЛИТИЧЕСКИЙ',
      ANY: 'ЛЮБОЙ',
      'AP/AR': 'Дебет/Кредит',
      'APPROVAL CONFIGURATION': 'КОНФИГУРАЦИЯ ПОДТВЕРЖДЕНИЙ',
      APPROVE: 'ПОДТВЕРДИТЬ',
      'AS A': 'КАК',
      ASPIRATION: 'АСПИРАЦИЯ',
      'AVG Delay': 'Средняя задержка',
      'AVG contract flat price': 'Средняя фиксированная цена контракта',
      'AVG current flat price': 'Средняя текущая фиксированная цена',
      'AVG delay': 'Средняя задержка',
      'AVG hedging + mtm': 'AVG хеджирование + mtm',
      'AVG hedging price': 'Средняя хеджинговая цена',
      'AVG packing': 'Вместительность',
      'AVG price': 'СРД цена',
      'AVG rate': 'СРЕД ставка',
      'AWB number': 'AWB номер',
      Abbreviation: 'Аббревиатура',
      'Absolute value': 'Абсолютное значение',
      'Access denied!': 'Доступ запрещен!',
      'According to contract template': 'Согласно шаблону контракта',
      Account: 'Счет',
      'Account activity': 'Счет активность',
      'Account connection': 'Подключение акаунта',
      'Account details': 'Детали аккаунта',
      'Account is active': 'Счет активный',
      'Account name': 'Имя аккаунта',
      'Account period': 'Отчетный период',
      'Account type': 'Тип счета',
      Accounting: 'Учет',
      'Accounting period': 'Отчетный период',
      Accounts: 'Счета',
      'Accounts position': 'Позиция счетов',
      'Acct#': '№',
      Action: 'Действие',
      'Action flag': 'Флаг действия',
      Actions: 'Действия',
      Active: 'Активный',
      Activity: 'Активность',
      'Activity type': 'Тип деятельности',
      'Activity types': 'Виды деятельности',
      'Actual date': 'Текущая дата',
      'Actually contracted': 'Реально законтрактовано',
      Add: 'Добавить',
      'Add agreement': 'Дополнительное соглашение',
      'Add bank details': 'Добавить банковские реквизиты',
      'Add contacts': 'Добавить контакты',
      'Add contracts': 'Добавить контракты',
      'Add costs/gains': 'Добавить затраты/доходы',
      'Add document': 'Добавить документ',
      'Add driver': 'Добавить водителя',
      'Add execution': 'Добавить исполнение',
      'Add filter': 'Добавить фильтр',
      'Add finance': 'Добавить инвойс',
      'Add finance (R/W)': 'Добавить инвойс (жд)',
      'Add finance (auto)': 'Добавить инвойс (авто)',
      'Add invoice': 'Добавить инвойс',
      'Add invoices': 'Добавить инвойсы',
      'Add logistic': 'Добавить транспорт',
      'Add new': 'Добавить новый',
      'Add new template': 'Добавить новый шаблон',
      'Add payment': 'Добавить оплату',
      'Add position': 'Добавить позицию',
      'Add quality': 'Добавить качество',
      'Add quality rule template': 'Добавить шаблон дисконта качества',
      'Add template': 'Добавить шаблон',
      'Add transport': 'Добавить транспорт',
      'Add vehicle': 'Добавить транспортное средство',
      'Add/update report config to table': 'Добавить/обновить конфигурацию отчета в таблицу',
      Added: 'Добавлено',
      Additional: 'Примечание',
      'Additional Info': 'Дополнительная инфо',
      'Additional aggr': 'Дополнительное соглашение',
      'Additional agreement': 'Дополнительное соглашение',
      'Additional agreement saved': 'Дополнительное соглашение сохранено',
      'Additional agreements': 'Дополнительные соглашения',
      'Additional agreements date': 'Доп соглашение дата',
      'Additional alt': 'Дополнительно альт',
      'Additional costs': 'Дополнительные затраты',
      'Additional currency': 'Дополнительная валюта',
      'Additional doc one': 'Дополнительный документ №1',
      'Additional doc three': 'Дополнительный документ № 3',
      'Additional doc two': 'Дополнительный документ №2',
      'Additional document one': 'Дополнительный документ №1',
      'Additional document three': 'Дополнительный документ №3',
      'Additional document two': 'Дополнительный документ №2',
      'Additional exchange rate': 'Дополнительный курс обмена валют',
      'Additional info': 'Дополнительная информация',
      'Additional info for screenshot': 'Дополнительная информация для скриншота',
      'Additional info regarding confirmation of this company':
        'Дополнительная информация про подтверждение компании',
      'Additional info updated': 'Дополнительная информация обновлена',
      Address: 'Адрес',
      'Address (eng)': 'Адрес (англ)',
      'Address Eng': 'Адрес Англ',
      'Address eng': 'Адрес (Англ.)',
      Addresses: 'Адреса',
      'Admin panel': 'Админ панель',
      Affiliated: 'Аффилированный',
      'Affiliated suppliers': 'Аффилированные поставщики',
      Age: 'Возраст',
      Agent: 'Посредник',
      Aggregators: 'Агрегаторы',
      Agreement: 'Договор',
      'Agreement approval status': 'Статус утверждения соглашения',
      "Agreement doesn't exist": 'Договор не существует',
      'Agreement limit': 'Лимит договора',
      'Agreement number': 'Номер договора',
      'Agreement removed': 'Договор удален',
      'Agreement saved': 'Договор сохранен',
      'Agreement status': 'Статус соглашения',
      'Agreement value': 'Сумма договора',
      Agreements: 'Договора',
      Agritel: 'Агритель',
      All: 'Все',
      'All Additional Agreements has originals': 'У всех дополнительных соглашений есть оригиналы',
      'All copies': 'Все копии',
      'All counterparties of your company': 'Все ваши компании',
      'All history': 'Вся история',
      'All info': 'Вся информация',
      'All of the following related items will be deleted:':
        'Все из нижеследующих объектов будут удалены:',
      'All originals': 'Все оригиналы',
      'All signed': 'Все подписали',
      'All uploaded documents': 'Все загруженные документы',
      'All users of the company': 'Все пользователи',
      'Allocate this operation to certain account. This account will be a criteria to match sales and purchases in Derivatives - Account Position report':
        'Поместить эту операцию на определенный счет. Этот счет будет критерием соответствия продаж и закупок в Деривативы - отчет о состоянии счета',
      'Allocate this operation to certain contract':
        'Закрепить эту операцию к определенному контракту',
      'Allocate this operation to certain passport. This passport will be a criteria to match sales and purchases in Derivatives - Contract Position report':
        'Поместите эту операцию на определенный паспорт. Этот паспорт будет критерием соответствия продаж и закупок в Деривативы - Контракт Позиция',
      'Allocate this passport to certain account. This account will be a criteria to match sales and purchases in Derivatives - Account Position report':
        'Привяжите этот паспорт к определенному рахунку. Этот счет будет критерием соответствия продажи и закупки в деривативах - ответ о позиции счета',
      Allocated: 'Распределение',
      Allocation: 'Распределение',
      Allocations: 'Распределения',
      Amount: 'Сумма',
      'Amount $': 'Сумма $',
      'Amount (basic)': 'Сумма (базовая)',
      'Amount - Paid': 'Сумма - Оплачено',
      'Amount BL+10%': 'Сумма коносаментов + 10%',
      'Amount USD': 'Сумма $',
      'Amount With Vat': 'Сумма с Ндс',
      'Amount alternative': 'Альтернативная сумма',
      'Amount correction': 'Корректировка суммы',
      'Amount in offset': 'Сумма в зачете',
      'Amount local': 'Сумма локал валюта',
      'Amount not reserved': 'Сумма не зарезервирована',
      'Amount of discount': 'Сумма дисконта',
      'Amount of premium': 'Сумма премии',
      'Amount of premiums for the delivered goods': 'Сумма премий за доставленный товар',
      'Amount of premiums from the contracted goods': 'Сумма премий за законтрактованный товар',
      'Amount per 1 t': 'Сумма за 1 т',
      'Amount per deal execution': 'Общая сумма за сделку',
      'Amount per t': 'Сумма за 1 т',
      'Amount per whole deal': 'Сумма за всю сделку',
      'Amount reconciliation': 'Сверка суммы',
      'Amount to connect': 'Свободная сумма расходной накладной',
      'Amount to connect DBL': 'Сумма для подключения DBL',
      'Amount with VAT': 'Сумма с НДС',
      'Amount with discount': 'Сумма со скидкой',
      'Amount withdrawn': 'Сумма списания',
      'Amount without VAT': 'Сумма без НДС',
      'Amount without VAT, $': 'Сумма без НДС, $',
      'Amount, $': 'Сумма $',
      'Amount/t': 'Сумма/т',
      'Amount_local no vat': 'Сумма в локальной валюте, без НДС',
      Amounts: 'Суммы',
      'An error occurred while fetching multicontract data.':
        'Произошла ошибка при получении данных мультиконтракта.',
      'An error occurred while saving charges.': 'Произошла ошибка при сохранении расходов.',
      Analytic: 'Аналитика',
      Analytical: 'Аналитический',
      'Analytical Amount': 'Аналитическая сумма',
      'Analytical P&L MTM': 'Аналитический ПНЛ МТМ',
      'Analytical Price': 'Аналитическая Цена',
      'Analytical amount': 'Аналитическая сумма',
      'Analytical costs': 'Аналитические затраты',
      'Analytical diff': 'Аналитическая разница',
      'Analytical parent': 'Аналитическая парент група',
      'Analytical parent charge': 'Аналитическая парент затрата',
      'Analytical parent commodity': 'Аналитическая парент культура',
      'Analytical price': 'Аналитическая цена',
      'Analytical price different from contract price':
        'Аналитическая цена отличается от контрактной',
      'Analytical result': 'Аналитический итог',
      'Analytical-Plan-Fact PnL': 'Аналитический План-Факт ПнЛ',
      Analytics: 'Аналитика',
      Angry: 'Злой',
      'Applied filters': 'Примененные фильтры',
      Apply: 'Применить',
      'Apply default': 'Применить по умолчанию',
      'Apply reserve': 'Применить резерв',
      'Appoint an amount of discount or use discount in %':
        'Укажите сумму дисконта или используйте Дисконт,%',
      'Appoint it if you want to apply discount per 1 mt':
        'Отметьте, если хотите применить дисконт на 1 т',
      'Appoint to which CONTRACT you want to allocate this charge or gain':
        'Назначьте, на какой КОНТРАКТ вы хотите распределить эту плату или прибыль',
      'Appoint to which PASSPORT you want to allocate this charge or gain in cases when contract has more than one passport and charge has to be allocated to a specific one. In other cases leave these field blank':
        'Укажите, на какой ПАСПОРТ вы хотите распределить эту затрату или доход в случаях, когда контракт имеет более одного паспорта и затрата/доход должны быть распределены на конкретный паспорт. В других случаях оставьте поле пустым',
      Approval: 'Подтверждение',
      'Approval Config': 'Конфигурация подтверждения',
      'Approval Status': 'Статус подтверждения',
      'Approval configuration': 'Конфигурация подтверждения',
      'Approval in process': 'Подтверждение в процессе',
      'Approval report': 'Отчет по подтверждениям',
      'Approval status': 'Статус подтверждения',
      'Approval updated': 'Подтверждение обновлено',
      Approvals: 'Подтверждения',
      'Approvals reactivated': 'Подтверждение снова активировано',
      'Approvals report': 'Отчет по подтверждениям',
      Approve: 'Утвердить',
      'Approve / Decline': 'Утверждено / отклонено',
      'Approve approvals': 'Подтвердить',
      Approved: 'Утверджено',
      'Approved by month': 'Подтверждено по месяцам',
      'Approved condition': 'Подтверждено с условием',
      Approvers: 'Подтверждающие',
      April: 'Апрель',
      Archived: 'Архивировано',
      'Are you sure that you want copy quality rules for all purchase facts?':
        'Вы уверены, что хотите скопировать правила качества для всех фактов закупки?',
      "Are you sure that you want delete 'Custom cargo declaration'?":
        'Вы уверены, что хотите удалить Грузовую таможенную декларацию?',
      "Are you sure that you want delete 'Driver'?": "Вы уверены, что хотите удалить 'Водителя'?",
      "Are you sure that you want delete 'Plan'?": "Вы уверены, что хотите удалить 'План' ?",
      "Are you sure that you want delete 'Vehicle'?":
        "Вы уверены, что хотите удалить 'Транспортное средство' ?",
      'Are you sure that you want delete Cert?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want delete Contract Charge?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want delete Freight?': 'Вы уверены, что хотите удалить Фрахт?',
      'Are you sure that you want delete Lost?': 'Вы уверены что хотите удалить потери?',
      'Are you sure that you want delete Position?': 'Вы уверены, что хотите удалить позицию?',
      'Are you sure that you want delete Tariff?': 'Вы уверены, что хотите удалить этот тариф?',
      'Are you sure that you want delete a template?': 'Вы уверены, что хотите удалить шаблон?',
      'Are you sure that you want delete agreement?': 'Вы уверены, что хотите удалить договор?',
      'Are you sure that you want delete bill of lading?':
        'Вы уверены, что вы хотите удалить коносамент?',
      'Are you sure that you want delete passport?': 'Вы уверены, что хотите удалить этот паспорт?',
      'Are you sure that you want delete this "Stock Reserve Unit"?':
        'Вы уверены, что хотите удалить эту «Единицу резерва запасов»?',
      'Are you sure that you want delete this Person?':
        'Вы уверены, что хотите удалить этот Контакт?',
      'Are you sure that you want delete this bank operation?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want delete this bank?': 'Вы уверены, что хотите удалить этот банк?',
      'Are you sure that you want delete this consignment?':
        'Вы уверены, что хотите удалить этот груз?',
      'Are you sure that you want delete this credit note?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want delete this disbursement bill of lading?':
        'Вы уверены, что вы хотите удалить это?',
      'Are you sure that you want delete this document?':
        'Вы уверены, что хотите удалить документ?',
      'Are you sure that you want delete this payment plan?':
        'Вы уверены, что хотите удалить этот план платежей?',
      'Are you sure that you want delete this payment?':
        'Вы уверены, что хотите удалить этот платеж?',
      'Are you sure that you want delete this template?':
        'Вы уверены, что хотите удалить этот шаблон?',
      'Are you sure that you want delete this transaction?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want delete this vehicle?': 'Вы уверены, что хотите удалить это т\\с?',
      'Are you sure that you want delete this warehouse?':
        'Вы уверены, что хотите удалить этот склад?',
      'Are you sure that you want delete this?': 'Вы уверены, что хотите удалить это?',
      'Are you sure that you want delete user?': 'Вы уверены, что хотите удалить пользователя?',
      'Are you sure that you want delete?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want to create Contract Charges?':
        'Вы уверены, что хотите удалить Затрату контракта?',
      'Are you sure that you want to delete parsing config?':
        'Вы уверены, что хотите удалить конфигурацию парсинга?',
      'Are you sure that you want to delete?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want to set cargo confirmation?':
        'Вы уверены, что хотите установить ГРУЗ Подтвержден?',
      'Are you sure that you want to set fact volume to plans?':
        'Вы уверены, что хотите установить фактический объем вместо всех плановых?',
      'Are you sure you want to delete loan?': 'Вы уверены, что хотите удалить займ?',
      'Are you sure you want to delete quote parsing config?':
        'Вы уверены, что хотите удалить конфигурацию парсинга?',
      'Are you sure you want to delete the': 'Вы уверены, что хотите удалить',
      'Are you sure you want to delete this collection?':
        'Вы уверены, что хотите удалить эту коллекцию?',
      'Are you sure?': 'Вы уверены?',
      'Area, hectare': 'Площадь, га',
      'Arrival date': 'Дата поступления',
      'Arrival end position': 'Конечная позиция прибытия',
      'Arrival start position': 'Начальная позиция прибытия',
      Aspiration: 'Аспирация',
      Assets: 'Активы',
      'Assigned to': 'Ответственный',
      'Assigned to me': 'Назначены мне',
      Assignment: 'Назначение',
      'At discharging port': 'В порту разгрузки',
      'At end': 'В конце',
      'At least one company is required': 'По крайней мере одна компания обязательна',
      'At loading port': 'В порту отгрузки',
      'At road': 'В пути',
      'At start': 'В начале',
      Attach: 'Прикрепить',
      'Attach contracts to passport': 'Прикрепить контракты к паспорту',
      'Attach documents': 'Прикрепить документы',
      'Attach purchase': 'Присоединить закупку',
      'Attach sale': 'Присоединить продажу',
      'Attach stocks': 'Привязать остатки',
      'Attach ticket': 'Присоединить тикет',
      'Attach to the passport': 'Прикрепить к паспорту',
      'Attached to passport': 'Привязано к паспорту',
      'Attaching files': 'Прикрепляю файлы',
      Attachments: 'Вложения',
      August: 'Август',
      Author: 'Автор',
      Auto: 'Авто',
      'Auto generated': 'Автоматически сгенерированы',
      'Auto payment conditions': 'Условия оплаты (авто)',
      'Auto prepay - balance percent': 'Авто, % предоплаты (баланс)',
      'Available in bot': 'Доступно в боте',
      'Available passports': 'Доступные паспорта',
      'Available to connect': 'Доступно для подключения',
      'Available under limit': 'Доступно в рамках лимита',
      'Available volume': 'Доступный объем',
      'Average commodities price, $/t with VAT': 'Средняя цена товара, $/т с НДС',
      'Average flat price per fixed lots': 'Средняя цена за зафиксированные лоты',
      'Average flat price per hedged lots': 'Средняя цена за захеджированные лоты',
      'Average mt price per fixed lots': 'Средняя рыночная цена за зафиксированные лоты',
      'Average price': 'Средняя цена',
      'Average purchase': 'Средняя закупочная',
      'Average sale': 'Средняя продажная',
      B: 'B',
      'B/L': 'Коносамент',
      'B/L date': 'Дата коносамента',
      'B/L number': 'Коносамент номер',
      "B/L's": 'Коносаменты',
      'B/Ls total volume': 'Общий объем коносаментов',
      B2B: 'B2B',
      BALANCE: 'БАЛАНС',
      'BANK ACCOUNTS': 'БАНКОВСКИЕ СЧЕТА',
      BI: 'ПокИнв',
      'BILLS OF LADING': 'КОНОСАМЕНТЫ',
      BL: 'Коносамент',
      'BL Volume': 'Объем коносаментов',
      'BL date': 'Дата коносамента',
      'BL loaded': 'Коносамент загружен',
      'BL max date': 'Последняя дата коносамента',
      'BL volume:': 'Объем коносаментов:',
      'BL/ETA ASC': 'BL/ETA ASC',
      'BL/ETA DESC': 'BL/ETA DESC',
      BLs: 'Коносаменты',
      'BLs are invoiced': 'Коносаменты проинвойсированы',
      'BLs losses volume': 'Объем потерь коносамента',
      BOAT: 'СУДНО',
      BP: 'ПокОпл',
      BU: 'БЮ',
      'BU / Client': 'БЮ / Клиент',
      BUYER: 'ПОКУПАТЕЛЬ',
      'B\\L': 'Коносамент',
      "B\\L's": 'Коносаменты',
      'Bad payment amount': 'Сумма плохих платежей',
      'Bags quantity': 'Количество мешков',
      'Bags weight': 'Вес тары',
      Balance: 'Баланс',
      'Balance at the beginning': 'Баланс на начало',
      'Balance at the end': 'Баланс на конец',
      'Balance default': 'Баланс по умолчанию',
      'Balance invoice': 'Баланс инвойс',
      'Balance payment': 'Балансовый платеж',
      'Balance payment dates': 'Балансовый платеж даты',
      'Balance to offset': 'Баланс для зачета',
      'Balance to settlement': 'Баланс для выставления',
      'Balance volume': 'Балансовый объем',
      'Balance, Cost / Gains': 'Баланс, затраты/доходы',
      Bank: 'Банк',
      'Bank Account': 'Банковский счет',
      'Bank Account Balance': 'Банк счет баланс',
      'Bank Account Total': 'Банк счета всего',
      'Bank Accounts': 'Банковские счета',
      'Bank Comission': 'Банк комиссия',
      'Bank Eng': 'Банк англ',
      'Bank Operations': 'Банк операции',
      'Bank Transactions': 'Банковские транзакции',
      'Bank account': 'Банковский счет',
      'Bank account deleted successfully': 'Банковский счет успешно удален',
      "Bank account doesn't exist": 'Банк счет не существует',
      'Bank account saved': 'Банк счет сохранен',
      'Bank accounts': 'Банк счета',
      'Bank branch': 'Отделение банка',
      'Bank branch saved': 'Отделение банка сохранено',
      'Bank branches': 'Отделения банка',
      'Bank client': 'Банк контрагента',
      'Bank code': 'Код банка',
      'Bank data check': 'Проверка банковских данных',
      'Bank data is checked': 'Банковские данные проверены',
      'Bank details': 'Банковские реквизиты',
      'Bank details is cloned': 'Банковские реквизиты клонированы',
      'Bank name': 'Название банка',
      'Bank name IBAN': 'Название банка IBAN',
      'Bank operation': 'Банк операция',
      'Bank operation removed': 'Банк операция удалена',
      'Bank operation saved': 'Банк операция сохранена',
      'Bank operations': 'Банк операции',
      'Bank removed': 'Банк удален',
      'Bank saved': 'Банк сохранен',
      'Bank transaction': 'Банковская транзакция',
      'Bank transaction commission': 'Комиссия за банковские операции',
      'Bank transaction saved.': 'Банковская транзакция сохранена.',
      'Bank transactions': 'Банковские операции',
      Banks: 'Банки',
      Barchart: 'Барчат',
      Base: 'База',
      'Base, days': 'База, дни',
      'Basic metrics': 'Базовые показатели',
      Basis: 'Базис',
      'Basis Docs': 'Документ',
      'Basis Opposite': 'Встречный базис',
      BasisDoc: 'Базис Док',
      Basises: 'Базисы',
      Batch: 'Партия',
      'Batch History': 'История партии',
      'Batch Owner': 'Владелец партии',
      'Batch Vessel': 'Судно партии',
      'Batch Voyage': 'Рейс партии',
      'Batch owner': 'Владелец партии',
      'Batch rest age (only on balance)': 'Остаток партии (только на балансе)',
      'Batch storage time': 'Время хранения партии',
      Bcc: 'Скрытая копия',
      'Because exactly this figure participates in calculation of Passport\n                    profitability.':
        'Потому что именно эта цифра участвует в расчете доходности Паспорта.',
      Bid: 'Продажа',
      'Bid/Offer': 'Бид/оффер',
      Bids: 'Продажи',
      Big: 'Большой',
      'Bill of Lading': 'Коносамент',
      'Bill of lading': 'Коносамент',
      'Bill of lading number': 'Коносамент номер',
      'Bill of lading removed': 'Коносамент удален',
      'Bill of lading saved': 'Коносамент сохранен',
      'Bill of lading saved and connected to logistics':
        'Коносамент сохранен и связанный с логистикой',
      BillOfLading: 'Коносамент',
      Billoflading: 'Коносамент',
      'Bills of Lading': 'Коносаменты',
      'Bills of lading': 'Коносаменты',
      Birthday: 'День Рождения',
      'Bite off some volume from this Indicator to create a Ticket':
        'Откусить некоторый объем из этого Оффера для создания Тикета',
      'Bite off some volume from this Potential to create a Ticket':
        'Откусить некоторый объем из этого Потенциала для создания Тикета',
      'Black list': 'Черный список',
      'Black listed': 'В черном списке',
      Blacklisted: 'В черном списке',
      Block: 'Раздел',
      Blocked: 'Заблокировано',
      Boarded: 'Погружено',
      'Boarded (date)': 'Погрузка (дата)',
      'Boarded Balance': 'Загруженный баланс',
      'Boarded volume': 'Загруженный объем',
      Boat: 'Судно',
      Body: 'Тело письма',
      Boolean: 'Булевий',
      'Border Crossing Date': 'Дата пересечения границы',
      'Border crossing date': 'Дата пересечения границы',
      Borrower: 'Заемщик',
      Bot: 'Бот',
      'Both calculations': 'Оба расчета',
      Bots: 'Боты',
      Broker: 'Брокер',
      'Broker EDRPOU code': 'Брокер ЕДРПОУ код',
      'Broker Signed': 'Брокер подписал',
      'Broker address': 'Брокер адрес',
      'Broker can signatory docs': 'Брокер подписант доки',
      'Broker can signatory docs eng': 'Брокер подписант доки англ',
      'Broker confirmation': 'Подтверждение брокера',
      'Broker cost': 'Брокер затраты',
      'Broker country': 'Брокер страна',
      'Broker country eng': 'Брокер страна англ',
      'Broker details': 'Детали брокера',
      'Broker email': 'Брокер почта',
      'Broker full name eng': 'Брокер полное название англ',
      'Broker full name ru': 'Брокер полное название',
      'Broker legal address': 'Брокер адрес полный',
      'Broker legal address eng': 'Брокер адрес полный англ',
      'Broker name': 'Брокер имя',
      'Broker phone': 'Брокер телефон',
      'Broker signatory': 'Брокер подписант',
      'Broker signatory eng': 'Брокер подписант аннл',
      'Broker signed': 'Подписано брокером',
      'Broker website': 'Брокер сайт',
      Brokerage: 'Брокер',
      Brokers: 'Брокеры',
      Budget: 'Бюджет',
      Budgets: 'Бюджеты',
      'Busines Unit': 'Бизнес-юнит',
      Business: 'Бизнес',
      'Business (date)': 'Бизнес (дата)',
      'Business Date': 'Бизнес дата',
      'Business Unit': 'Бизнес-юнит',
      'Business Units': 'Бизнес юниты',
      'Business confirmation': 'Бизнес подтверждение',
      'Business date': 'Бизнес дата',
      'Business days': 'Рабочие дни',
      'Business unit': 'Бизнес-юнит',
      'Business units': 'Бизнес-юниты',
      Buy: 'Покупка',
      'Buy quantity': 'Количество покупки',
      Buyer: 'Покупатель',
      'Buyer 10 invoice': 'Покупатель баланс счет',
      'Buyer 90 invoice': 'Покупатель 90 инвойс',
      'Buyer EDRPOU code': 'Покупатель ЕДРПОУ код',
      'Buyer ITN (Individual Tax Number)': 'Покупатель индивидуальный налоговый номер',
      'Buyer Signed': 'Покупатель подписал',
      'Buyer address': 'Покупатель адрес',
      'Buyer bank details': 'Банковские детали покупателя',
      'Buyer can signatory docs': 'Покупатель подписант доки',
      'Buyer can signatory docs eng': 'Покупатель подписант доки англ',
      'Buyer contract': 'Покупатель контракт',
      'Buyer contract #': 'Покупатель контракт №',
      'Buyer contract ISCC': 'Контракт покупателя ISCC',
      'Buyer contract status': 'Покуп контр статус',
      'Buyer country': 'Страна покупателя',
      'Buyer country eng': 'Покупатель страна англ',
      'Buyer email': 'Покупатель почта',
      'Buyer full name': 'Покупатель полное название',
      'Buyer full name eng': 'Покупатель полное название английский',
      'Buyer invoiced': 'Покупателю выставлено инвойс',
      'Buyer is required': 'Покупатель обязателен',
      'Buyer legal address': 'Покупатель адрес полный англ',
      'Buyer legal address eng': 'Покупатель адрес полный англ',
      'Buyer paid': 'Покупатель оплатил',
      'Buyer passports #': 'Покупатель паспорта №',
      'Buyer payment info': 'Инфо про оплату покупателя',
      'Buyer phone': 'Покупатель телефон',
      'Buyer signatory': 'Покупатель подписант',
      'Buyer signatory eng': 'Покупатель подписант англ',
      'Buyer signed': 'Покупатель подписал',
      'Buyer tax info': 'Покупатель налоговое инфо',
      'Buyer website': 'Покупатель сайт',
      'Buyer/Owner': 'Покупатель/Собственник',
      Buyers: 'Покупатели',
      'By analytical price': 'По аналитической цене',
      'By contract price': 'По цене контракта',
      'By default currency': 'По умолчанию валюта',
      'By invoice currency': 'По валюте инвойса',
      CARGO: 'ГРУЗ',
      'CASH FLOW RECREATE': 'ДЕНЕЖНЫЙ ПОТОК ПЕРЕСОЗДАТЬ',
      CASHFLOW: 'ДЕНЕЖНЫЙ ПОТОК',
      'CASHFLOW + BALANCES': 'КЕШ-ФЛО+БАЛАНСЫ',
      CCD: 'CCD',
      "CCD doesn't exist": 'CCD не существует',
      'CCD volume': 'Объем CCD',
      CD: 'ТД',
      CHAIN: 'ЦЕПОЧКА',
      CHARGES: 'ЗАТРАТЫ',
      'CHECK LIST': 'ЧЕК ЛИСТ',
      'CHOOSE FAVORITES': 'ВЫБРАТЬ ИЗБРАННЫЕ',
      CLOSED: 'ЗАКРЫТО',
      COMMISSIONS: 'КОМИССИИ',
      COMMODITY: 'ТОВАР',
      COMPANIES: 'КОМПАНИИ',
      COMPANY: 'КОМПАНИЯ',
      COMPLIANCE: 'КОМПЛАЙЕНС',
      'CONCLUSION DATE': 'ДАТА ЗАКЛЮЧЕНИЯ',
      CONDITIONS: 'УСЛОВИЯ',
      CONFIG: 'КОНФИГУРАЦИЯ',
      CONFIGURATION: 'КОНФИГУРАЦИЯ',
      CONSIGNMENTS: 'НАКЛАДНЫЕ',
      CONTACTS: 'КОНТАКТЫ',
      CONTRACT: 'КОНТРАКТ',
      'CONTRACT DOCUMENTS': 'ДОКУМЕНТЫ КОНТРАКТА',
      'CONTRACT VALUE': 'СУММА КОНТРАКТОВ',
      CONTRACTS: 'КОНТРАКТЫ',
      CONTROL: 'КОНТРОЛЬ',
      COSTS: 'ЗАТРАТЫ',
      'COSTS OF GOODS': 'СЕБЕСТОИМОСТЬ',
      COUNTERPARTIES: 'КОНТРАГЕНТЫ',
      'CP approval status': 'Статус утверждения CP',
      'CP custom status': 'Пользовательский статус CP',
      'CP date': 'Дата чартер партии',
      'CP/Seeds Prepayments': 'CP/Seeds предоплаты',
      'CREATE A FILTER': 'СОЗДАТЬ ФИЛЬТР',
      CREATED: 'СОЗДАНО',
      'CREATION DATE': 'ДАТА СОЗДАНИЯ',
      CREDIT: 'КРЕДИТ',
      CRM: 'CRM',
      CTL: 'ЦТЛ',
      CURRENCY: 'ВАЛЮТА',
      'CUSTOM VALUES': 'ПОЛЬЗОВАТЕЛЬСКИЕ ЗНАЧЕНИЯ',
      'Cached value should be only number, string, array or object':
        'Кэшированное значение должно быть только числом, строкой, массивом или объектом',
      'Calc intermediate logistics': 'Расчёт промежуточной логистики',
      'Calculate auto tariff': 'Расчет авто тариф',
      'Calculate broker cost': 'Кальк брокер затрата',
      'Calculate elevator costs': 'Расчет перевалка',
      'Calculate profit and loss': 'Расчет PnL',
      'Calculate railway tariff': 'Расчет жд тариф',
      'Calculate tax profit to pay': 'Кальк налог',
      'Calculated VAT': 'Расчетный НДС',
      'Calculated quantity': 'Рассчитанное количество',
      Calculator: 'Калькулятор',
      Calendar: 'Календарь',
      'Calendar days': 'Календарные дни',
      Call: 'Звонок',
      'Can access Admin': 'Может заходить в Админку',
      'Can be contract owner': 'Может быть владельцем контракта',
      'Can confirm': 'Может подтверждать',
      Cancel: 'Отмена',
      'Cancel all': 'Отменить все',
      'Cancel contract': 'Отменить контракт',
      'Cancel indicator': 'Отменить индикатор',
      'Cancel ticket': 'Отменить тикет',
      'Cancel {[{ $ctrl.multicontract.stage }]}': 'Отменить {[{ $ctrl.multicontract.stage }]}',
      'Cancel {[{ item.stage }]}': 'Отменить {[{ item.stage }]}',
      Canceled: 'Отменено',
      Cancelled: 'Отменено',
      'Cancelled?': 'Отмена?',
      'Cannot delete {[{ $ctrl.results.object_model }]}':
        'Нельзя удалить {[{ $ctrl.results.object_model }]}',
      Capacity: 'Емкость',
      'Capacity avg': 'Средняя емкость',
      'Capacity est': 'Предполагаемая вместимость',
      'Capacity max': 'Максимальная емкость',
      'Capacity min': 'Минимальная емкость',
      Car: 'Авто',
      Cargo: 'Груз',
      'Cargo Price': 'Цена за груз',
      'Cargo all': 'Груз все',
      'Cargo brand': 'Марка груза',
      'Cargo confirmation': 'Подтверждение груза',
      'Cargo customs declaration': 'Грузовая таможенная декларация',
      'Cargo customs declarations': 'Грузовые таможенные декларации',
      'Cargo details': 'Детали груза',
      'Cargo eng': 'Груз англ',
      'Cargo executed': 'Груз выполнен',
      'Cargo fully paid': 'Груз полностью оплачен',
      'Cargo is required': 'Груз обязателен',
      'Cargo partial executed': 'Груз выполнен частично',
      'Cargo receiver': 'Получатель груза',
      'Cargo shipper': 'Отправитель груза',
      Carrier: 'Перевозчик',
      'Cars prepay - balance percent': 'Авто предоплата – процент баланса',
      Cash: 'Наличные',
      'Cash Flow': 'Денежный поток',
      'Cash Flow (mixed)': 'Кеш-фло микс',
      'Cash Flow report': 'Денежный поток (отчет)',
      'Cash Start': 'Остаток на начало',
      'Cash flow': 'Денежный поток',
      'Cash flow recreated': 'Денежный поток воссоздан',
      Cashback: 'Кешбек',
      'Cashback amount': 'Сумма кешбеку',
      Cashflow: 'Денежный поток',
      Cc: 'Копия',
      'Certificate Dates': 'Даты сертификации',
      'Certificates exist in following logistics. Do you want to override them?':
        'Сертификаты уже существуют в этих т\\с. Вы хотите переопределить их?',
      'Certification schemes': 'Сертификационные схемы',
      Certs: 'Сертификаты',
      'Chain order': 'Порядок цепочки',
      Change: 'Изменить',
      'Change password': 'Поменять пароль',
      'Change the status of contract to shipment': 'Поменять статус контракта на Исполнение',
      Changed: 'Изменен',
      Charge: 'Затрата',
      'Charge Distribution': 'Распределение затрат',
      'Charge amount': 'Сумма затраты',
      'Charge basis': 'Базис затраты',
      'Charge date': 'Дата начисления',
      'Charge level': 'Уровень начисления',
      'Charge list': 'Список начислений',
      Charges: 'Затраты',
      'Charges / Gains': 'Затраты / Доходы',
      'Charges Analytics': 'Аналитика начислений',
      'Charges types': 'Типы затрат',
      Chart: 'График',
      'Charter party (date)': 'Дата чартер партии',
      'Charter party date': 'Дата чартер партии',
      Charterer: 'Фрахтователь',
      'Charterer is owner': 'Чартере - владелец',
      Charts: 'Графики',
      Chats: 'Чаты',
      Check: 'Отметка',
      'Check connections': 'Проверить соединения',
      'Check costs': 'Проверить затраты',
      'Check destination': 'Проверить назначение',
      'Check execution': 'Проверить исполнение',
      "Check if everything's allright": 'Проверьте, все ли в порядке',
      'Check invoices': 'Проверить инвойс',
      'Check list': 'Проверить список',
      'Check list of docs is set up': 'Проверка списка документов настроена',
      'Check point of departure': 'Проверить отправку',
      'Check preview and send an email': 'Просмотреть перед отправкой и отправить email',
      'Check the hedging': 'Проверить хеджирование',
      'Check the position': 'Проверить позицию',
      'Check this if LOI was issued': 'Проверьте, был ли выдан LOI',
      'Check this if counterparty has criminal cases':
        'Отметить, если у контрагента есть криминальные дела',
      'Check this if counterparty has sanctions': 'Отметить, если у контрагента есть санкции',
      'Check this if counterparty has tax debt': 'Отметить, если контрагент имеет налоговый долг',
      'Check this if the agreement is digitally signed':
        'Проверьте, если соглашение подписано в цифровом виде',
      'Check this if the contract is digitally signed':
        'Проверьте это, если контракт подписан в цифровом виде',
      'Check this if the counterparty in invoice is different than the one in the contract':
        'Отметьте, если контрагент инвойса отличается от контрагента указанного в контракте',
      'Check this if the purchase of certified products on iscc standards':
        'Отметьте это если закупка серт продуктов соответствует iscc стандартам',
      'Check this if this supplier is distributor.':
        'Выберите если поставщик является дистрибьютором.',
      'Check this if you don`t want to check with SINGING DAYS QUANTITY value':
        'Выберите, если не хотите проверять КОЛИЧЕСТВО ДНЕЙ ПОДПИСАНИЯ',
      'Check this if you to show basis-port-price widget':
        'Отметьте, если хотите указать несколько цен',
      'Check this if you to show derivatives widget': 'Отметьте, если хотите указать несколько цен',
      'Check this if you want add multiple consignees':
        'Проверьте это, если хотите добавить нескольких получателей',
      'Check this if you want agreement number to be auto generated':
        'Отметить, если хотите, чтобы номер соглашения сгенерировался автоматически',
      'Check this if you want calculate complex percent':
        'Отметить, если вы хотите посчитать сложный процент',
      'Check this if you want calculate simple percent':
        'Отметить, если вы хотите посчитать простой процент',
      'Check this if you want contract name to be auto generated':
        'Отметьте, если хотите, чтобы номер контракта сгенерировался автоматически',
      'Check this if you want finance number to be auto generated':
        'Отметьте, если хотите, чтобы номер инвойса сгенерировался автоматически',
      'Check this if you want loan name to be auto generated':
        'Отметьте, если хотите, чтобы номер займа сгенерировался автоматически',
      'Check this if you want name to be auto generated':
        'Отметить, если вы хотите, чтобы название было автоматически сгенерировано',
      'Check this if you want number to be auto generated':
        'Отметьте, если хотите, чтобы номер сгенерировался автоматически',
      'Check this if you want to display BLACKLIST label alongside company name':
        'Отметьте, если вы хотите отображать значок ЧЕРНЫЙ СПИСОК рядом с названием компании',
      'Check this if you want to ignore allocation':
        'Проверьте это, если хотите игнорировать распределение',
      'Check this if you want to make contract to swap type': 'СВОП контракт',
      'Check this if you want to parse this quote':
        'Отметьте, если вы хотите парсить эту котировку',
      'Check this if you want to use broker tariff in price calculate':
        'Отметьте это если хотите чтобы брокер тариф посчитался в калькуляторе',
      'Check this if you want to use it as internal chain':
        'Отметьте, если хотите использовать как внутреннюю цепочку',
      'Check this if you want to use match with commodity parent':
        'Отметить, это, если желаете использовать соответствие с родительским товаром',
      'Check this if you want to use tax profit to pay in price calculate':
        'Отметьте это если хотите чтобы налог посчитался в калькуляторе',
      'Check this if you want to use volume position as aspiration':
        'Отметить, если желаете использовать объем позиций, как аспирацию',
      'Check this to avoid standard KPI evaluation process':
        'Отметьте это, чтобы избежать стандартного процесса оценки KPI',
      'Check this, if current company buy or sell in USD':
        'Убедитесь, что текущая компания покупает или продает в валюте USD',
      'Check this, if current company buy or sell only in local currency':
        'Убедитесь, что текущая компания покупает или продает только в локальной валюте',
      'Check this, if current company is an Agent and not a Producer':
        'Отметьте, если данная компания является Посредником, а не Производителем',
      'Check this, if the Supplier of Farm is an original producer':
        'Отметьте, если Поставщик или Хозяйство является Производителем',
      'Check volume': 'Проверьте объем',
      'Checklist Point': 'Пункт контрольного списка',
      'Checklist completed (copy)': 'Чеклист заполнен (копия)',
      'Checklist completed (original)': 'Чеклист заполнен (оригинал)',
      'Checklist completed (originals)': 'Чеклист заполнен (оригиналы)',
      'Checklist point': 'Контрольный пункт',
      'Child commodity': 'Дочерняя культура',
      Choice: 'Выбор',
      Choose: 'Выбрать',
      'Choose Batch': 'Выбрать партию',
      'Choose Business Unit': 'Выберите бизнес-юнит',
      'Choose Business unit': 'Выберите бизнес-юнит',
      'Choose Condition': 'Выберите условия',
      'Choose Invoicing Amount': 'Выберите сумму инвойса',
      'Choose Multipassport': 'Выбрать мультипаспорт',
      'Choose Owner': 'Выберите Владельца',
      'Choose Producer': 'Выберите производителя',
      'Choose Receiver': 'Выберите получателя',
      'Choose Reserve Unit': 'Выберите резервную единицу',
      'Choose SAP orders': 'Выбрать SAP-заказы',
      'Choose Stock Reserve Unit': 'Выберите единицу резерва запасов',
      'Choose Warehouse': 'Выберите склад',
      'Choose a business unit': 'Выберите бизнес-юнит',
      'Choose a preset of report config': 'Выберите предыдущую конфигурацию отчета',
      'Choose a range': 'Выберите диапазон',
      'Choose a report:': 'Выбрать отчет:',
      'Choose a template': 'Выберите шаблон',
      'Choose approve status': 'Выбрать статус подтверждения',
      'Choose assignment': 'Выбрать переназначение',
      'Choose base': 'Выберите базу',
      'Choose batch': 'Выберите партию',
      'Choose charge': 'Выберите начисление',
      'Choose charterer': 'Выбрать перевозчика',
      'Choose client': 'Выберите контрагента',
      'Choose commodity': 'Выбрать товар',
      'Choose compliance status': 'Выбрать статус подтверждения',
      'Choose configuration to launch the approval process by this object. You need first to set up configuration an Admin section, and here just pick it up':
        'Выберите конфигурацию, чтобы запустить процесс подтверждения этого объекта. Вам нужно сначала настроить конфигурацию в разделе Админ, а здесь просто выбрать',
      'Choose contract': 'Выберите контракт',
      'Choose contract position': 'Выберите позицию контракта',
      'Choose contract position to update': 'Выберите позицию контракта для обновления',
      'Choose cost volume source': 'Выберите источник объема затрат',
      'Choose counterparty': 'Выберите контрагента',
      'Choose counterparty and currency': 'Выберите контрагента и валюту',
      'Choose currency': 'Выберите валюту',
      'Choose custom status': 'Выберите пользов. статус',
      'Choose date filter': 'Фильтр по дате',
      'Choose fines days count method': 'Выберите метод подсчета дней штрафа',
      'Choose fines invoicing method': 'Выберите метод выставления штрафов',
      'Choose from existing customs declarations. To add new one click +':
        'Выберите из существующих таможенных деклараций. Чтобы добавить новую, нажмите +',
      'Choose from existing products. To add new one click +':
        'Выберите из существующих продуктов. Чтобы добавить новый, нажмите +',
      'Choose from predefined list of types. To add new one click +':
        'Выберите из заранее определенного списка типов. Для добавления нового нажмите +',
      'Choose invoice assignment': 'Выбрать назначение инвойса',
      'Choose invoice date': 'Выберите дату инвойса',
      'Choose invoice type': 'Выберите тип инвойса',
      'Choose invoicing weight': 'Выберите тоннаж инвойса',
      'Choose manager': 'Выбрать менеджера',
      'Choose margin': 'Выберите маржу',
      'Choose one or more default incoterms on which this Supplier or Buyer is selling or buying':
        'Выберите один или несколько базисов по умолчанию, на которых Поставщик или Покупатель продает или покупает',
      'Choose operation type': 'Выберите тип операции',
      'Choose option': 'Выбрать опцию',
      'Choose options': 'Выбрать опцию',
      'Choose port': 'Выбрать порт',
      'Choose prepay conditions': 'Выберите условия предоплаты',
      'Choose producer': 'Выберите производителя',
      'Choose product': 'Выберите товар',
      'Choose program type': 'Выберите тип программы',
      'Choose purchase contract': 'Выберите закупочный контракт',
      'Choose rate': 'Выберите ставку',
      'Choose receiver': 'Выберите получателя',
      'Choose relations': 'Выберите отношение',
      'Choose resource': 'Выбрать ресурс',
      'Choose responsible': 'Выберите ответственного',
      'Choose role': 'Выбрать контрагента',
      'Choose services contract': 'Выберите сервисный контракт',
      'Choose ship classification': 'Выбрать класс лодки',
      'Choose signatory': 'Выберите подписанта',
      'Choose size': 'Выберите размер',
      'Choose status': 'Статус',
      'Choose strategy': 'Выберите стратегию',
      'Choose tax registration': 'Выбрать налог регистрацию',
      'Choose template': 'Выберите шаблон',
      'Choose terminal': 'Выберите терминал',
      'Choose the Excel template you want to send. It will be an excel spreadsheet\n                        attached to a letter. You can choose the order and naming of columns':
        'Выберите шаблон Excel, который вы хотите отправить. Это будет таблица Excel, прикрепленная к письму. \n                        Вы можете выбрать порядок и название столбцов',
      'Choose the block, to which this template will be attached.This means, you might use it only within this block':
        'Выберите блок, к которому прикреплять шаблоны. Это означает, что вы сможете использовать их в рамках этого блока',
      'Choose the email template you want to send. It can be customized for every\n                        client':
        'Выберите шаблон email, который вы хотите отправить. Он может быть настроен для каждого \n                        контрагента',
      'Choose the file on computer': 'Выберите файл на компьютере',
      'Choose the range': 'Диапазон',
      'Choose type': 'Выберите тип',
      'Choose type of invoice': 'Выберите тип инвойса',
      'Choose type of logistic': 'Выберите тип логистики',
      'Choose usage': 'Выберите использование',
      'Choose vehicle type': 'Выберите тип транспортного средства',
      'Choose vessel': 'Выберите судно',
      'Choose warehouse': 'Выберите склад',
      'Choose who decide the option': 'Кто выбирает опцию',
      'Choosed quality list': 'Выбранный список качеств',
      'Chose a role of a counterparty to be bound to a finance':
        'Выберите роль или контрагента для того, чтобы связать с инвойсом',
      'Chose new filter...': 'Выбрать новый фильтр…',
      Cities: 'Города',
      City: 'Город',
      Clean: 'Очистить',
      'Clean All': 'Очистить всё',
      Cleaning: 'Очистка',
      Clear: 'Очистить',
      'Clear contract': 'Очистить контракт',
      'Clear filters': 'Очистить фильтры',
      'Clear passport': 'Очистить паспорт',
      'Click this checkbox if you want to include all package of documents by all\n                        logistics you want to send':
        'Отметьте этот пункт, если хотите включить весь пакет документов\n                         по всей логистике, которую хотите отправить',
      Client: 'Клиент',
      'Client & cargo': 'Контрагент и товар',
      'Client / Terminal': 'Контрагент / Терминал',
      'Client Document Type': 'Тип документа',
      'Client Reconciliation by logistic': 'Сверка с контрагентом по логистике',
      'Client Role': 'Роль контрагента',
      'Client author': 'Автор',
      'Client bank': 'Банк контрагента',
      'Client chats': 'Клиентские чаты',
      'Client data check': 'Проверка данных контрагента',
      'Client data is checked': 'Данные контрагента проверены',
      'Client from': 'Контрагент от',
      'Client history': 'История контрагента',
      'Client role': 'Роль контрагента',
      'Client template': 'Шаблон контрагента',
      'Client to': 'Контрагент',
      "Client's Template": 'Шаблон контрагента',
      'Clientrole from': 'Клиент от кого',
      'Clientrole to': 'Клиент кому',
      Clients: 'Контрагенты',
      'Clients Reconciliations': 'Сверка контрагентов',
      Clone: 'Клонировать',
      'Clone costs': 'Клонировать затраты',
      Close: 'Закрыть',
      'Close contract': 'Закрыть контракт',
      'Close modal?': 'Закрыть окно?',
      'Close price': 'Цена закрытия',
      Closed: 'Закрыто',
      Closing: 'Закрытие',
      'Closing contract': 'Закрытие контракта',
      'Closing of existing position': 'Закрытие существующей позиции',
      Code: 'Код',
      Coefficient: 'Коэффициент',
      Cold: 'Холодный',
      Collateral: 'Обеспечение',
      'Collateral amount': 'Объем обеспечения',
      Collection: 'Конфигурация',
      'Collection deleted successfully': 'Коллекция успешно удалена',
      'Collection title': 'Конфигурация название',
      'Column name': 'Название колонки',
      'Columns sets:': 'Наборы столбцов:',
      'Comm Fees': 'Комиссионные сборы',
      'Comm fees': 'Комиссии',
      'Comm fees,$': 'Комиссии, дол',
      'Comm. fees': 'Комиссии',
      Comment: 'Комментарий',
      Commentary: 'Комментарий',
      'Commentary successfully deleted.': 'Комментарий удален.',
      'Commentary successfully saved.': 'Комментарий сохранен.',
      Comments: 'Комментарии',
      Commercial: 'Коммерческий',
      'Commercial Invoice': 'Коммерческий инвойс',
      Commission: 'Комиссия',
      'Commission amount': 'Комиссия сумма',
      'Commission credit account': 'Комиссия кредит счет',
      'Commission debit account': 'Комиссия дебет счет',
      Commissions: 'Комиссии',
      Commodities: 'Товары',
      Commodity: 'Продукт',
      'Commodity Balance': 'Баланс по грузу',
      'Commodity default': 'Продукт по умолчанию',
      'Commodity level': 'Товарный уровень',
      'Commodity opposite': 'Товар встречный',
      Companies: 'Компании',
      Company: 'Компания',
      'Company Group': 'Группа компаний',
      'Company from': 'Компания от',
      'Company group': 'Группа компаний',
      'Company groups': 'Группы компаний',
      'Company name': 'Название компании',
      'Company parameters': 'Параметры компании',
      'Company to': 'Компания кому',
      'Company with whom we have these charge or gain, not a contract counterparty':
        'Компания, с которой у нас есть эти затраты или доход, не является контрагентом по контракту',
      'Complete compliance': 'Комплаенс завершен',
      'Complete positions table data': 'Заполнить данные таблицы позиций',
      Completed: 'Завершено',
      'Complex percent': 'Сложный процент',
      Compliance: 'Комплайенс',
      'Compliance KPI': 'Комплаенс KPI',
      'Compliance status': 'Статус комплаенса',
      Conclusion: 'Заключение',
      'Conclusion (date)': 'Дата заключения',
      'Conclusion date': 'Дата заключения',
      'Conclusion date currency': 'Курс на дату заключения',
      'Conclusion date exchange rate': 'Курс валют на дату заключения',
      'Conclusion date flat price': 'Цена на дату заключения',
      'Conclusion date position': 'Дата заключения позиции',
      Condition: 'Условие',
      Conditions: 'Условия',
      'Config title': 'Название конфигурации',
      Configs: 'Конфигурация',
      Configure: 'Настроить',
      'Configure fields': 'Настроить поля',
      'Configure report': 'Настроить отчет',
      Confirm: 'Подтвердить',
      'Confirm New Password': 'Подтвердить новый пароль',
      Confirmation: 'Подтверждение',
      'Confirmation (date)': 'Подтверждение (дата)',
      'Confirmation date': 'Дата подтверждения',
      'Confirmation info': 'Подтверждение информации',
      Confirmations: 'Подтверждения',
      Confirmed: 'Подтверждено',
      Connect: 'Соединить',
      'Connect Documents': 'Связать документы',
      'Connect contract to existing passport': 'Связать контракт с существ. паспортом',
      'Connect logistics': 'Соединение с логистикой',
      'Connect position to existing passport': 'Привязать позицию к существующему паспорту',
      'Connect to': 'Соединить с',
      'Connect to contract': 'Присоединить к контракту',
      'Connect to existing passport': 'Связать с существ. паспортом',
      'Connect to passport': 'Связать с паспортом',
      'Connect to purchase': 'Привязать к закупке',
      'Connect to sale': 'Привязать к продаже',
      'Connect to {[{ $root.user.settings.PASSPORT_TITLE }]}':
        'Связать с {[{ $root.user.settings.PASSPORT_TITLE }]}',
      'Connect with purchase': 'Привязать к закупке',
      'Connect with purchase contract': 'Привязать к закупочному контракту',
      'Connect with sale': 'Привязать к продаже',
      'Connect with sale contract': 'Привязать к продажному контракту',
      'Connect wrong crops?': 'Связать с разными культурами?',
      Connected: 'Связано',
      'Connected Potentials history': 'История связанных потенциалов',
      'Connected passports': 'Привязанные паспорта',
      'Connected volume': 'Привязанный объем',
      'Connecting...': 'Соединяем...',
      Connection: 'Привязка',
      Connections: 'Привязки',
      'Connections updated.': 'Привязки обновлены.',
      'Consecutive voyages': 'Последовательные рейсы',
      Consignee: 'Получатель',
      'Consignee list': 'Список грузополучателей',
      Consignees: 'Грузополучатели',
      Consignment: 'Накладная',
      'Consignment (#)': 'Consignment (#)',
      'Consignment and volume are required.': 'Необходимы накладная и объем.',
      'Consignment contract commodities': 'Культура накладных контракта',
      'Consignment date': 'Дата накладной',
      'Consignment number': 'Номер накладной',
      'Consignment removed': 'Накладная удалена',
      'Consignment saved': 'Накладная сохранена',
      Consignments: 'Накладные',
      Consolidated: 'Консолидированное инфо',
      'Consolidated Contract PnL': 'Консолидированный контракт PnL',
      'Consolidated Invoiced Amount': 'Консолидированная сумма инвойса',
      'Consolidated PnL': 'Консолидированный ПнЛ',
      'Consolidated costs': 'Консолидированные расходы',
      'Consolidated costs invoice': 'Консолидированный инвойс расходов',
      'Consolidated crop position': 'Консолидированная позиция культуры',
      'Consolidated position': 'Консолидированная позиция',
      Contact: 'Контакт',
      'Contact date expired': 'Дата контакта просрочена',
      'Contact first time': 'Дата первого контакта',
      'Contact info': 'Контактная информация',
      'Contact next time': 'Связаться в след раз',
      'Contact status': 'Статус контакта',
      'Contact today': 'Связаться сегодня',
      Contacted: 'Контакт',
      Contacts: 'Контакты',
      Container: 'Контейнер',
      Continue: 'Продолжить',
      Contract: 'Контракт',
      'Contract #': 'Контракт №',
      'Contract - Analytical': 'Контракт - Аналитический',
      'Contract - Execution': 'Контракт - Исполнение',
      'Contract BU': 'БЮ контракта',
      'Contract Charges / Gains': 'Контракты Затраты / Доходы',
      'Contract Charges 1t': 'Контракт затраты за 1т',
      'Contract DSTU': 'Контракт ДСТУ',
      'Contract Number': 'Номер контракта',
      'Contract Option': 'Контракт опция',
      'Contract Owner': 'Контракт владелец',
      'Contract P&L': 'Контракт PnL',
      'Contract P&L MTM': 'Контракт PnL МТМ',
      'Contract Position': 'Позиция контракта',
      'Contract Price': 'Цена контракта',
      'Contract ROS': 'Контракт ROS',
      'Contract Status': 'Контракт статус',
      'Contract Sum': 'Контракт сумма',
      'Contract amount': 'Сумма контрактов',
      'Contract can have only one warehouse': 'Контракт может иметь только один склад',
      'Contract charge': 'Затрата контракта',
      'Contract charge saved': 'Затрата контракта сохранена',
      'Contract charge updated': 'Плата по контракту обнавлена',
      'Contract charges': 'Контракт затраты',
      'Contract charges saved for each contract in the multicontract':
        'Контрактные расходы, сохраненные для каждого контракта в мультиконтракте',
      'Contract conclusion date': 'Дата заключения контракта',
      'Contract connected.': 'Контакт прикреплен.',
      'Contract counterparty': 'Контрагент по контракту',
      'Contract creation': 'Создание контракта',
      'Contract crop year': 'Контракт год урожая',
      'Contract crops': 'Контракт культуры',
      'Contract custom status': 'Контрактный пользовательский статус',
      'Contract deal type': 'Тип сделки',
      'Contract destinations': 'Назначения контракта',
      'Contract details': 'Контракт детали',
      'Contract document': 'Документ контракта',
      "Contract doesn't exist": 'Контракт не существует',
      'Contract executed': 'Контакт исполнен',
      'Contract executed. Containes three confirmation. Cargo - everything is ok with a delivery. Payment - everything is ok with payments. Final - everything is ok in general, and the contract might be considered as executed':
        'Контракт исполнен. Включает три показателя. Груз - когда все хорошо с доставкой. Платеж - когда все хорошо с оплатами. Финал - когда везде все хорошо и контракт можно считать выполненым',
      'Contract flat price': 'Фиксированная цена контракта',
      'Contract info': 'Контракт примеч',
      'Contract invoice type': 'Тип инвойса по контракту',
      'Contract margin': 'Маржа по контрактам',
      'Contract margin differs': 'Маржа по контрактам отличается',
      'Contract notes': 'Контракт примеч',
      'Contract number': 'Номер контракта',
      'Contract numbers': 'Номера контрактов',
      'Contract option': 'Опция',
      'Contract passports': 'Паспорта контракта',
      'Contract ports': 'Контракт порты',
      'Contract position': 'Контрактная позиция',
      'Contract positions': 'Позиции контракта',
      'Contract price': 'Цена контракта',
      'Contract price average, 1 MT/$': 'Средняя цена контракта, 1 MT/$',
      'Contract quantity': 'Контракт количество',
      'Contract removed': 'Контракт удален',
      'Contract result': 'Результат контракта',
      'Contract saved': 'Контракт сохранен',
      'Contract status': 'Статус контракта',
      'Contract stock (t)': 'Остатки контракт (т)',
      'Contract stock amount': 'Контракт остаток сумма',
      'Contract type': 'Тип контракта',
      'Contract updated': 'Контракт обновлен',
      'Contract value': 'Стоимость контракта',
      'Contract value in UAH': 'Сумма контракта в ГРН',
      'Contract value no VAT': 'Сумма контракта без НДС',
      'Contract volume': 'Объем контракта',
      'Contract volume (t)': 'Контрактный объем (т)',
      'Contract volume - Total volume connected to all passports from this contract':
        'Объем контракта - Общий объем, связанный со всеми паспортами из этого контракта',
      'Contract volume - Total volume of opposite contracts connected to same\n          passports':
        'Объем контракта - Общий объем противоположных контрактов, связанных с одними и теми же \n          паспортами',
      "Contract's Costs Opposite": 'Затраты встречного контракта',
      "Contract's Flat Price": 'Контракт флэт цена',
      "Contract's Flat Price Opposite": 'Контракт цена встречная',
      "Contract's Open Volume": 'Контракт открытый объем',
      "Contract's Premium Price": 'Контракт премиум цена',
      "Contract's Premium Price Opposite": 'Контракт премиум цена встречная',
      Contracted: 'Законтрактовано',
      'Contracted volume:': 'Законтрактованный объем:',
      Contracts: 'Контракты',
      'Contracts Analytical': 'Контракты Аналитические',
      'Contracts Analytics': 'Аналитика контрактов',
      'Contracts Execution': 'Исполнение контрактов',
      'Contracts analytical': 'Аналитика контрактов',
      'Contracts control created': 'Создан контроль за контрактами',
      'Contracts created from this stock': 'Контракты, созданные из этого остатка',
      'Contracts final volume': 'Финальный объем контракта',
      'Contracts import': 'Контракты импорт',
      'Contracts quantity': 'Объем контрактов',
      'Contracts should have same currency.': 'У контрактов должны совпадать валюты.',
      'Contracts status updated': 'Статус контрактов обновлен',
      'Contracts total value': 'Общая сумма контрактов',
      'Contracts totals': 'Контракт тотал',
      'Contracts updated': 'Контракт обновлен',
      'Contracts value': 'Стоимость контрактов',
      'Contracts volume': 'Объем контракта',
      Control: 'Контроль',
      'Control margin': 'Контроль маржи',
      Controlled: 'Контролируемый',
      'Controlled margin': 'Проконтролированная маржа',
      Controller: 'Проконтролировавший',
      Controls: 'Контроль',
      'Controls on a page': 'Управление на странице',
      'Convert to contract': 'Преобразовать в контракт',
      'Convert to ticket': 'Преобразовать в тикет',
      Coordinates: 'Координаты',
      Copy: 'Скопировать',
      'Copy to clipboard': 'Копировать',
      'Corr account': 'Корр аккаунт',
      'Corr account 1': 'Корр. счет 1',
      'Corr account 2': 'Корр. счет 2',
      'Corr bank': 'Банк корреспондент',
      'Corr bank 1': 'Банк корреспондент 1',
      'Corr bank 2': 'Банк корреспондент 2',
      'Corr iban': 'Корр IBAN',
      'Corr iban 1': 'Корр IBAN 1',
      'Corr iban 2': 'Корр IBAN 2',
      'Corr swift': 'Корр свифт',
      'Corr swift 1': 'Корр. SWIFT 1',
      'Corr swift 2': 'Корр. SWIFT 2',
      Correspondent: 'Корреспондент',
      Cost: 'Затраты',
      'Cost / Gain': 'Расходы/Доходы',
      'Cost / gain': 'Расходы/доходы',
      'Cost counterparty': 'Затраты контрагента',
      'Cost details': 'Детали стоимости',
      'Cost of goods sold': 'Себестоимость',
      'Cost of production mUSD, with VAT': 'Стоимость производства USD с НДС',
      'Cost-Contract counterparty difference': 'Затраты-Контракт разница по контрагентам',
      'Cost-Invoice counterparty difference': 'Затраты-Инвойс разница по контрагентам',
      'Cost/gain charge': 'Расходы/доходы',
      'Cost/gain reserve charge': 'Затраты/прибыль резервного сбора',
      Costs: 'Затраты',
      'Costs amount': 'Сумма расходов',
      'Costs distributions': 'Распределение затрат',
      'Costs invoices': 'Инвойсы на затраты',
      'Costs of goods': 'Стоимость товара',
      'Costs opposite': 'Встречные затраты',
      'Costs report': 'Отчет по затратам',
      'Costs statistics report': 'Отчет о статистике расходов',
      'Costs sync strategy': 'Стратегия синхронизации затрат',
      'Costs/gains': 'Расходы/доходы',
      Count: 'Счетчик',
      Counterparties: 'Контрагенты',
      'Counterparties in contracts is not unique': 'Контрагенты в контракте не уникальны',
      Counterparty: 'Контрагент',
      'Counterparty Analytics': 'Аналитика контрагентов',
      'Counterparty amount': 'Сумма контрагента',
      'Counterparty approval status': 'Статус подтверждения контрагента',
      'Counterparty bank': 'Банк контрагента',
      'Counterparty bank account': 'Банковский счет контрагента',
      'Counterparty business reference': 'Бизнес-референс контрагента',
      'Counterparty confirmation. Completed if this Counterparty is confirmed by your organization':
        'Подтверждение контрагента. Успешно, если Контрагент подтвержден вашей компанией (в редактировании Контрагента)',
      'Counterparty confirmed': 'Контрагент подтвержден',
      'Counterparty created': 'Контрагент создан',
      'Counterparty creation': 'Создание контрагента',
      'Counterparty from': 'Компания от',
      'Counterparty payment IBAN': 'Контрагент платеж IBAN',
      'Counterparty payment info': 'Платежная информация контрагента',
      'Counterparty saved': 'Контрагент сохранен',
      'Counterparty status:': 'Статус контрагента:',
      'Counterparty to': 'Контрагент которому',
      'Counterparty type': 'Тип контрагента',
      Countries: 'Страны',
      Country: 'Страна',
      'Country, city, street, app': 'Страна, город, улица, номер',
      'Counts by month': 'Кол-во по месяцам',
      Create: 'Создано',
      'Create Balance Invoice': 'Создать баланс инвойса',
      'Create Contract from this Ticket. Ticket will become Executed':
        'Создать контракт из этого Тикета. Тикет станет Выполненым',
      'Create DisbursementBL': 'Создать расходную накладную',
      'Create Document': 'Создать Документ',
      'Create Email Template': 'Создать шаблон',
      'Create Prepayment Invoice': 'Создать предоплатный инвойс',
      'Create Screen Capture Task': 'Создать задачу захвата экрана',
      'Create Service Request': 'Создать сервисный тикет',
      'Create Supplier': 'Создать поставщика',
      'Create Ticket': 'Создать Тикет',
      'Create Ticket from this Ticket': 'Создать тикет из тикета',
      'Create a collection': 'Сохранить коллекцию',
      'Create a document': 'Создать документ',
      'Create a letter': 'Создать письмо',
      'Create a new passport': 'Создать новый паспорт',
      'Create a template': 'Создать шаблон',
      'Create an email': 'Создать письмо',
      'Create approvals for declined': 'Создать подтвержд для отмененных',
      'Create contract': 'Создать контракт',
      'Create contracts from tickets': 'Создать контракты из тикетов',
      'Create control': 'Создать контроль',
      'Create date': 'Дата создания',
      'Create document': 'Создать документ',
      'Create invoice': 'Создать инвойс',
      'Create invoices': 'Создать инвойсы',
      'Create new': 'Создать новый',
      'Create new ticket': 'Создать новый тикет',
      'Create operation': 'Создать операцию',
      'Create passport': 'Создать паспорт',
      'Create passport with this contract': 'Создать паспорт с этим контрактом',
      'Create passport with this position': 'Создать паспорт с этой позицией',
      'Create payment plan': 'Создать план оплат',
      'Create rolling': 'Создать роллинг',
      'Create ticket': 'Создать Тикет',
      'Create time': 'Время создания',
      'Create transport': 'Создать транспорт',
      'Create user': 'Создать пользователя',
      'Create vessel': 'Создать судно',
      'Create voyage': 'Создать рейс',
      'Create your favorite Filter in Right Sidebar and it will appear here':
        'Создайте свой любимый фильтр в правой боковой панели и он появится здесь',
      Created: 'Создано',
      'Created / Edited': 'Создан / Изменен',
      'Created at': 'Создан в',
      'Created date': 'Дата создания',
      'Created time': 'Время создания',
      'Created via agreement': 'Создано з помощью соглашения',
      'Created via multicontract': 'Создано через мультиконтракт',
      'Created via multiticket': 'Создан через мультитикет',
      Createor: 'Создал',
      'Creating invoices': 'Создание инвойсов',
      Creation: 'Создание',
      Credit: 'Кредит',
      'Credit Account': 'Кредит счет',
      'Credit Bank Account': 'Кредит банк счет',
      'Credit Counterparty': 'Кредит контрагент',
      'Credit Finance Account': 'Кредит финанс счет',
      'Credit Finance Account (commission)': 'Кредит финанс счет (комиссия)',
      'Credit Finance account': 'Кредит финанс счет',
      'Credit Note': 'Кредит нота',
      'Credit Notes': 'Кредит ноут',
      'Credit Report': 'Отчет по рискам',
      'Credit account': 'Кредит счет',
      'Credit bank account': 'Кредит банк счет',
      'Credit counterparty': 'Кредит контрагент',
      'Credit default': 'Кредит по умолчанию',
      'Credit note': 'Кредит ноут',
      'Credit note number': 'Кредит ноут номер',
      'Credit note payments': 'Кредит ноут оплаты',
      'Credit note removed': 'Кредит ноут удален',
      'Credit note saved': 'Кредит ноут сохранен',
      'Credit note updated': 'Кредит ноут обновлен',
      'Credit notes': 'Кредит ноуты',
      'Credit report': 'Отчет по рискам',
      'Creditor Balance': 'Кредитор баланс',
      Criminal: 'Криминальная деятельность',
      Critical: 'Критично',
      Crop: 'Культура',
      'Crop DSTU': 'Культура ДСТУ',
      'Crop Groups': 'Группы товаров',
      'Crop Year': 'Год урожая',
      'Crop area': 'Посевная площадь',
      'Crop level': 'Уровень культуры',
      'Crop price created': 'Культура цена создана',
      'Crop price deleted': 'Культура цена удалена',
      'Crop prices deleted': 'Цены удалены',
      'Crop protection': 'Защита посевов',
      'Crop year': 'Год урожая',
      Cryptocurrency: 'Криптовалюта',
      Ct: 'Кр',
      Currencies: 'Валюты',
      Currency: 'Валюта',
      'Currency Exchange': 'Обменный курс',
      'Currency Rate': 'Обменный курс',
      'Currency alternative': 'Альтернатива валюты',
      'Currency and rate': 'Валюта и курс',
      'Currency exchange': 'Обмен валюты',
      'Currency is required': 'Валюта обязательна',
      'Currency opposite': 'Валюта встречная',
      'Currency rate': 'Обменный курс',
      'Current Password': 'Текущий пароль',
      'Current address': 'Фактический адрес',
      'Current derivative': 'Текущий дериватив',
      'Current flat price': 'Текущая цена',
      'Current goal': 'Текущая цель',
      'Current opposite flat price': 'Текущая встречная цена',
      'Custom cargo declaration removed': 'Грузовая таможенная декларация удалена',
      'Custom declarations': 'Таможенные декларации',
      'Custom declarations saved': 'Сохраненные таможенные декларации',
      'Custom field`s values': 'Значения кастомных полей',
      'Custom fields': 'Кастомные поля',
      'Custom filter': 'Свой фильтр',
      'Custom margin': 'Настраиваемая маржа',
      'Custom roles': 'Пользовательские роли',
      'Custom status': 'Пользовательский статус',
      'Custom statuses': 'Пользовательские статусы',
      'Custom values': 'Кастомные значения',
      'Customize a fields': 'Персонализация полей',
      Customs: 'Таможня',
      'Customs Broker': 'Таможенный брокер',
      'Customs Declaration': 'Таможенная накладная',
      'Customs Terminals': 'Таможенные терминалы',
      'Customs broker': 'Таможенный брокер',
      'Customs cleared amount ()': 'Сумма таможенного оформления',
      'Customs cleared volume (t)': 'Объем таможенного оформления (т)',
      'Customs cleared volumes by customs terminal':
        'Объемы таможенного оформления через таможенный терминал',
      'Customs declaration': 'Таможенная декларация',
      'Customs declaration volume, mt': 'Обьем Таможенной декларации, mt',
      'Customs declarations': 'Таможенные декларации',
      'Customs not cleared by customs terminal': 'Таможня не пройдена таможенным терминалом',
      'Customs prepared amount ()': 'Подготовленная сумма для таможни ()',
      'Customs prepared volume (t)': 'Подготовленный обьем для таможни (t)',
      'Customs uncleared amount ()': 'Сумма таможенной неочищенной пошлины ()',
      'Customs uncleared volume (t)': 'Неочищенный таможенный Обьем (t)',
      'Customs unprepared amount ()': 'Неподготовленная таможенная сумма ()',
      'Customs unprepared volume (t)': 'Неподготовленный таможенный обьем (t)',
      DASHBOARD: 'ПАНЕЛЬ',
      'DAT Self cost': 'DAT себестоимость',
      'DATE RANGE': 'ПЕРИОД',
      DATES: 'ДАТЫ',
      'DATES & NOTES': 'ДАТЫ & ЗАМЕТКИ',
      'DBL SAP registered': 'DBL SAP зарегистрирован',
      'DBL amount': 'Расходная накладная сумма',
      'DBL are tax registered': 'Расходная накладная налог зарег',
      'DBL volume': 'Расходная накладная объем',
      DEBIT: 'ДЕБЕТ',
      DECLINE: 'ОТКАЗАТЬ',
      DELIVERY: 'ДОСТАВКА',
      DERIVATIVES: 'ДЕРИВАТИВЫ',
      'DESTINATION 1ST PORT': 'ПОРТ НАЗНАЧЕНИЯ 1-Й',
      'DESTINATION FINAL': 'КОНЕЧНЫЙ ПУНКТ НАЗНАЧЕНИЯ',
      DETAILS: 'ДЕТАЛИ',
      DIFF: 'РАЗНИЦА',
      'DIFF margin (ROS)': 'Разница маржи (ROS)',
      DIFFs: 'РАЗНИЦА',
      DIMENSIONS: 'РАЗМЕРЫ',
      'DISBURSMENT BILL OF LADING': 'РАСХОДНАЯ НАКЛАДНАЯ',
      DO: 'DO',
      DOCS: 'ДОКИ',
      DOCUMENTS: 'ДОКУМЕНТЫ',
      'DUE DATE': 'ДЕДЛАЙН',
      Daily: 'Ежедневный',
      'Daily fines rate': 'Ежедневная ставка штрафа',
      Dark: 'Темная',
      Dashboard: 'Панель',
      Data: 'Данные',
      'Data updated': 'Данные обновлены',
      Date: 'Дата',
      'Date date': 'Дата',
      'Date from': 'Дата с',
      'Date of Disbursement bill of lading': 'Дата расходной накладной',
      'Date of berth': 'Дата причаливания',
      'Date of berth destination': 'Дата прихода на причал в пункте назначения',
      'Date of berth loading': 'Дата погрузки на причале',
      'Date of certification': 'Дата сертификации',
      'Date of conduction': 'Дата проведения',
      'Date of discharging end': 'Дата выгрузки конец',
      'Date of discharging start': 'Дата выгрузки старт',
      'Date of documents withdrawal': 'Дата изъятия документов',
      'Date of execution': 'Дата исполнения',
      'Date of execution fact': 'Дата платежа (факт)',
      'Date of future contact': 'Следующий контакт дата',
      'Date of issuance': 'Дата выставления',
      'Date of last contact': 'Дата последнего контакта',
      'Date of loading end': 'Дата отгрузки конец',
      'Date of loading start': 'Дата отгрузки старт',
      'Date of notice': 'Дата нотиса',
      'Date of notice destination': 'Дата нотиса (выгрузка)',
      'Date of notice loading': 'Дата нотиса (загрузка)',
      'Date of payment': 'Дата платежа',
      'Date of payment (fact)': 'Дата платежа (факт)',
      'Date of payment (plan)': 'Дата платежа (план)',
      'Date of receiving': 'Дата получения',
      'Date of withdrawal': 'Дата выдачи',
      'Date range': 'Период',
      'Date range of execution': 'Период исполнения',
      'Date to': 'Дата до',
      'Date, when the company was confirmed by us first time':
        'Дата, когда компания была впервые подтверждена нами',
      'Date, when the company was officially confirmed by us':
        'Дата, когда компания была официально подтверждена нами',
      'Date, when the documents were received from the company':
        'Дата, когда документы были получены от этой компании',
      'Date, when the documents were requested from the company':
        'Дата, когда документы были запрошены у этой компании',
      'Dates are reqired': 'Даты обязательны',
      Day: 'День',
      Days: 'Дни',
      'Days at port': 'Дней в порту',
      'Days before ending status': 'Дней до завершения статуса',
      "Days to set 'ending status'(end of execution)":
        'Дни для установки «статуса окончания» (конец выполнения)',
      'Days until final day': 'Дней до финального дня',
      Dbl: 'Расходная накладная',
      'Dbl date': 'Расходная накладная дата',
      'Dbl number': 'Dbl номер',
      'Dead freight': 'Мерт фрахт',
      'Deal type': 'Тип сделки',
      Deals: 'Сделки',
      Debit: 'Дебет',
      'Debit Account': 'Дебет счет',
      'Debit Bank Account': 'Дебет банк счет',
      'Debit Counterparty': 'Дебет контрагент',
      'Debit Credit': 'Дебет кредит',
      'Debit Finance Account': 'Дебет финанс счет',
      'Debit Finance Account (commission)': 'Дебет финанс счет (комиссия)',
      'Debit Finance account': 'Дебет финанс счет',
      'Debit Note': 'Дебет нота',
      'Debit account': 'Дебет счет',
      'Debit bank account': 'Дебет банк счет',
      'Debit counterparty': 'Дебет контрагент',
      'Debit default': 'Дебет по умолчанию',
      'Debitor Balance': 'Дебитор баланс',
      Debts: 'Долги',
      December: 'Декабрь',
      Declaration: 'Декларация',
      'Declaration date': 'Дата декларации',
      'Declaration number': 'Номер декларации',
      'Declaration required': 'Декларация обязательна',
      'Declaration type': 'Тип декларации',
      'Declared volume': 'Заявленный объем',
      Decline: 'Отклонить',
      'Decline approvals': 'Отказано',
      Declined: 'Отклонено',
      Default: 'По умолчанию',
      'Default Contract Tab': 'Вкладка Контракт по умолчанию',
      'Default Margin View': 'Вид маржи по умолчанию',
      'Default contract for connect': 'Контракт для присоединения по умолчанию',
      'Default prepayment with this counterparty. You can specify another in each contract':
        'Предоплата по умолчанию с этим контрагентом. Вы можете указать другую в каждом контракте',
      Delay: 'Задержка',
      'Delayed payment': 'Задержка платежа',
      Delays: 'Задержки',
      Delete: 'Удалить',
      'Delete payment?': 'Удалить оплату?',
      'Delete price': 'Удалить цену',
      'Delete quality?': 'Удалить качество?',
      Deleted: 'Удалено',
      "Deleted  logistics from  selected'": 'Удалена логистика из выбранного',
      'Deleting the': 'Удаляя',
      Delivered: 'Доставлено',
      Deliverer: 'Экспедитор',
      'Deliverer in port': 'Доставщик в порту',
      Delivery: 'Доставка',
      'Delivery (end date)': 'Доставка (окончание)',
      'Delivery (start date)': 'Доставка (начало)',
      'Delivery Basis': 'Базис',
      'Delivery addresses': 'Адреса доставки',
      'Delivery condition': 'Вид транспортировки',
      'Delivery conditions': 'Вид транспортировки',
      'Delivery dates': 'Даты доставки',
      'Delivery estimated month': 'Ожидаемый месяц доставки',
      'Delivery finished': 'Отгрузка завершилась',
      'Delivery period': 'Период поставки',
      'Delivery period sale contract': 'Период исполнения продажного контракта',
      'Delivery status': 'Статус доставки',
      'Dem rate': 'Ставка демерреджа',
      'Demmurage rate': 'Ставка демереджа',
      Demo: 'Демо',
      'Demo removed': 'Демо убрали',
      Demurrage: 'Демерредж',
      'Demurrage rate': 'Ставка демереджа',
      Density: 'Плотность',
      'Depart station': 'Станция отправки',
      'Departed consignment': 'Отправлено накладные',
      'Departed minus': 'Отправлено минус',
      'Departed plus': 'Отправлено плюс',
      'Departed real': 'Отправлено док',
      'Departed value': 'Отправлено значение',
      'Departed volume': 'Отправленный объем',
      'Departed w/o consignment': 'Отправлено без накладных',
      'Departure station': 'Станция отправления',
      'Depends on a stage of passport execution. Select which margin we will display in PNL':
        'Зависит от стадии исполнения паспорта. Выберите, какую маржу мы будем отображать в PNL',
      Derivative: 'Дериватив',
      'Derivative Accounts': 'Аккаунт дериватива',
      'Derivative account': 'Деривативный счет',
      'Derivative margin': 'Маржа дериватива',
      'Derivative mode': 'Режим дериватива',
      'Derivative ticker': 'Дериватив тикер',
      'Derivative title': 'Дериватив название',
      'Derivative type': 'Тип дериватива',
      Derivatives: 'Деривативы',
      'Derivatives & Rolling': 'Деривативы и Роллинг',
      'Derivatives Margin': 'Маржа деривативов',
      'Derivatives Position': 'Позиция деривативов',
      'Derivatives list': 'Список деривативов',
      'Derivatives margin': 'Маржа деривативов',
      Description: 'Описание',
      'Despatch rate': 'Ставка деспач',
      Destination: 'Назначение',
      'Destination 1st port': 'Порт назначения 1-й',
      'Destination Port': 'Порт назначения',
      'Destination country': 'Страна назначения',
      'Destination dem/dis': 'Выгрузка дем/дис',
      'Destination final port': 'Конечный порт назначения',
      'Destination port': 'Порт назначения',
      'Destination station': 'Станция назначения',
      Destinations: 'Направления',
      'Destinations of crop': 'Назначение культуры',
      Detailed: 'Детально',
      'Detailed by responsible': 'Рассмотрено ответственным',
      Details: 'Детали',
      'Did not happen': 'Не произошло событие',
      Diff: 'Разница',
      'Diff Plan | Fact': 'Разница План | Факт',
      'Diff analytical': 'Diff аналитический',
      'Diff btwn contract price and MTM, $': 'Разница между ценой контракта и MTM, $',
      'Difference = 0': 'Разница = 0',
      'Difference > 0': 'Разница > 0',
      'Difference >= 0.1': 'Разница > 0.1',
      Diffs: 'Разницы',
      'Digital signature': 'Электронная подпись',
      Direct: 'Прямой',
      'Direct Contracts': 'Прямые контракты',
      'Direct Tickets': 'Прямые Тикеты',
      'Disable previous template and clear all filter before create new.':
        'Отключить предыдущий шаблон и очистить все фильтры перед созданием нового.',
      Disabled: 'Отключено',
      'Disbursement bill of lading': 'Расходная накладная',
      'Disbursement bill of lading Amount incl. VAT, UAH': 'Расходная накладная сумма, UAH с НДС',
      'Disbursement bill of lading Price incl. VAT, UAH': 'Расходная накладная цена, UAH с НДС',
      'Disbursement bill of lading Quantity, mt': 'Расходная накладная объем, т',
      'Disbursement bill of lading removed': 'Расходная накладная удалена',
      'Disbursement bill of lading saved': 'Расходная накладная создана',
      'Disbursement bl': 'Расходная накладная',
      'Disbursement bl and reassignment execution': 'Выполнение расходной накладной и переуступки',
      'Disbursement bl value': 'Расходная накладная сумма',
      'Disbursement bl volume': 'Расходная накладная объем',
      Disbursementbl: 'Расходная накладная',
      'Disbursment B/L': 'Расходная накладная',
      "Disbursment B/L's": 'Расходные накладные',
      'Disbursment bills of lading': 'Расходная накладная',
      'Disch rate': 'Норма выгрузки',
      Discharge: 'Выгрузка',
      'Discharge approval volume, mt': 'Объем разрешенный на выгрузку, mt',
      Discharged: 'Выгружено',
      Discharging: 'Выгружается',
      Discount: 'Дисконт',
      'Discount %': 'Дисконт, %',
      'Discount / 1 mt': 'Дисконт/ 1 т',
      'Discount amount': 'Значение дисконта',
      'Discount calculation, %': 'Расчеты дисконта, %',
      'Discount per': 'Дисконт за 1 т',
      'Discount per 1 mt': 'Дисконт за 1 т',
      'Discount, %': 'Дисконт, %',
      Discussion: 'Обсуждение',
      'Disp rate': 'Ставка диспача',
      Dispatch: 'Диспач',
      'Dispatch rate': 'Диспач ставка',
      'Disponent owner': 'Владелец диспонента',
      Disport: 'Порт назначения',
      'Disselect all': 'Убрать всё',
      'Distribute by volume': 'Распределить по обьему',
      'Distribute evenly': 'Распределить равномерно',
      'Distribution by users': 'Распределение по пользователям',
      Distributor: 'Дистрибьютор',
      'Distributor code': 'Код Дистрибьютора',
      Distributors: 'Дистрибьюторы',
      'Distributors fact': 'Дистрибьюторы факт',
      'Distributors plan': 'Дистрибьюторы план',
      District: 'Район',
      Districts: 'Районы',
      'Divide invoice amount for commodity': 'Распределение суммы инвойса по культуре',
      'Divide invoice amount for contracts': 'Распределение суммы инвойса по контрактам',
      'Divide invoice amount for passports': 'Распределить сумму инвойса на паспорта',
      'Do not display in passport': 'Не отображать в паспорте',
      'Do not pay': 'Не платить',
      'Do not update contracts': 'Не обновлять контракты',
      'Do not use in Freight position': 'Не применять в фрахтовой позиции',
      'Do not validate': 'Не сверять',
      'Do the compliance': 'Выполнить комплаенс',
      'Do you really want to delete this object ?': 'Вы уверены, что хотите удалить этот объект?',
      'Do you want delete this Request?': 'Вы уверены, что хотите удалить эту Заявку?',
      'Do you want replace sides in invoice?': 'Вы уверены, что хотите поменять стороны в инвойсе?',
      'Do you want to cancel this Contract?': 'Вы уверены, что хотите отменить этот Контракт?',
      'Do you want to clone this Client?': 'Вы уверены, что хотите клонировать этого Клиента?',
      'Do you want to clone this Consignment?': 'Вы уверены, что хотитет клонировать Накладную?',
      'Do you want to clone this Contract?': 'Вы уверены, что хотитет клонировать Контракт?',
      'Do you want to clone this Disbursement bill of lading?':
        'Вы уверены, что вы хотите клонировать это?',
      'Do you want to clone this General Agreement ?':
        'Вы уверены, что хотите клонировать Договор?',
      'Do you want to clone this Invoice?': 'Вы уверены, что хотитет клонировать инвойс?',
      'Do you want to clone this Logistic?': 'Вы уверены, что хотитет клонировать Транспорт?',
      'Do you want to clone this Person?': 'Вы уверены, что хотите клонировать Контакт?',
      'Do you want to clone this Request?': 'Вы уверены, что хотите клонировать Заявку?',
      'Do you want to clone this object?': 'Вы уверены, что хотите клонировать этот объект?',
      'Do you want to connect': 'Хотите ли вы соединить',
      'Do you want to create a control?': 'Вы уверены, что хотите создать Контроль?',
      'Do you want to create a supplier with short name  and role':
        'Вы хотите создать поставщика с коротким именем и ролью',
      'Do you want to create a user for this Person?':
        'Вы хотите создать пользователя Кабинета из этого Контакта?',
      'Do you want to create contract?': 'Вы уверены, что хотитет создать Контракт?',
      'Do you want to create mailing by  contracts from \\\n  selected  logisitc(s)?':
        'Вы хотите создать рассылки по контрактам из  \\\n       выбранных логистик?',
      'Do you want to create mailing by contracts from selected  invoice(s)?':
        'Вы хотите создать рассылки по контрактам из выбранных инвойсов?',
      'Do you want to create multicontract?': 'Вы уверены, что хотите создать Мультиконтракт?',
      'Do you want to create payment by this plan?': 'Хотите оплатить инвойсы из плана?',
      'Do you want to create ticket?': 'Вы хотите создать тикет?',
      'Do you want to delete all passport connections?': 'Вы хотите удалить все привязки паспорта?',
      'Do you want to delete selected Logistics?':
        'Вы уверены, что хотите удалить выбранную логистику?',
      'Do you want to delete this mailing?': 'Вы уверены, что хотите удалить эту Рассылку?',
      'Do you want to drop  mails?': 'Хочешь сбросить  письма?',
      'Do you want to execute this passport?':
        'Вы хотите исполнить этот План Паспорта (создать контракты) ?',
      'Do you want to read all?': 'Вы действительно хотите прочитать все?',
      'Do you want to reverse contract?': 'Вы уверены, что хотите продать/купить эту Заявку?',
      'Do you want to reverse request?': 'Вы уверены, что хотите продать/купить эту Заявку?',
      'Do you want to reverse ticket?': 'Вы хотите купать/продать эту сделку?',
      'Do you want to send  mails?': 'Вы уверены, что хотите отправить рассылку?',
      'Do you want to update final volume from execution?':
        'Вы хотите обновить финальный объем из исполнения?',
      'Do you want to update logistics by discount?':
        'Вам необходимо обновить логистику с дисконтом?',
      'Do you want to update purchase price of logistics from indicator?':
        'Вы хотите обновить закупочную цену логистики с индикатора?',
      'Do you want to update status of logistics with limits?':
        'Вам необходимо обновить статус логистики с учетом лимитов?',
      'Doc removed': 'Документ удален',
      Docs: 'Доки',
      'Docs approved': 'Доки подтверждены',
      'Docs approved by us': 'Доки подтвердили',
      'Docs received': 'Доки получены',
      'Docs requested': 'Доки запросили',
      Document: 'Документ',
      'Document ID': 'Документ ID',
      'Document Type': 'Тип документа',
      'Document copy': 'Копия документа',
      'Document original': 'Оригинал документа',
      'Document saved': 'Документ сохранен',
      'Document templates': 'Шаблоны документов',
      'Document type': 'Тип документа',
      Documentation: 'Документация',
      Documents: 'Документы',
      'Documents check-list': 'Перечень документов',
      'Documents dates': 'Даты документов',
      'Documents entry dates': 'Даты внесения документов',
      'Documents saved': 'Документы сохранены',
      'Documents. Completed if all documents per contract are received':
        'Документы. Успешно, если получены все доки по контракту',
      "Don't use for KPI": 'Не использовать для KPI',
      'Don`t use kpi check': 'Не использовать для KPI',
      Done: 'Готово',
      Download: 'Скачать',
      'Download a template': 'Скачать шаблон',
      'Download all': 'Скачать всё',
      'Download all documents': 'Загрузить все документы',
      Downloaded: 'Скачано',
      Draft: 'Драфт',
      'Drag and drop a file': 'Перетащите файл',
      'Drag and drop to reorder': 'Перетащите файл чтобы изменить порядок',
      Driver: 'Водитель',
      'Driver details': 'Детали водителя',
      "Driver doesn't exist": 'Водитель не существует',
      'Driver passport seria & number': 'Серия и номер паспорта водителя',
      'Driver passport seria & number (Ukrainian)': 'Серия и номер паспорта водителя (украинский)',
      'Driver removed': 'Водитель удален',
      Drivers: 'Водители',
      'Drop all connections to passport': 'Сбросить все привязки к паспорту',
      'Drop connections': 'Сбросить привязки',
      'Drop selected': 'Удалить выбранное',
      Drying: 'Сушка',
      Dt: 'Дт',
      'Due date': 'Дедлайн',
      'Due date days': 'Дедлайн',
      'Due date days after create': 'Срок выполнения в днях после создания',
      Dynamic: 'Динамичный',
      'E-mail': 'E-mail',
      'E.g. you have a chain from inland EXW purchase to CIF sale via several counteparties\n              - you might use two or more passport for each part of the chain.':
        'Например, у Вас есть цепь от внутренней покупки EXW до продажи CIF через несколько контрагентов\n              - можно использовать два или более паспорта для каждой части цепи.',
      'EDUCATION MODE in either company or in your personal settings is':
        'РЕЖИМ ОБУЧЕНИЯ в компании или в личных настройках',
      ENABLED: 'ВКЛЮЧЁН',
      ENTER: 'ВВОД',
      ETA: 'ETA',
      ETB: 'ETB',
      ETC: 'ETC',
      ETS: 'ETS',
      'EUR 1': 'EUR 1',
      'EUR 1 required': 'EUR 1 обязательно',
      EXECUTION: 'ИСПОЛНЕНИЕ',
      'EXECUTION VALUE': 'Сумма исполнения',
      EXPORT: 'ЭКСПОРТ',
      'EXPORTER CONFIRMATION': 'ЭКСПОРТЕР ПОДТВЕРЖДЕНИЕ',
      EXPORTERS: 'ЭКСПОРТЕРЫ',
      'EXTRA DIMENSIONS': 'ДОПОЛНИТЕЛЬНЫЕ РАЗМЕРЫ',
      'Economic activities code': 'КВЭД',
      Edit: 'Редактировать',
      'Edit (admin)': 'Редактировать (админ)',
      'Edit Users': 'Редактировать пользователей',
      Editor: 'Редактор',
      Elevator: 'Элеватор',
      'Elevator Name': 'Элеватор',
      'Elevator cost': 'Элеватор затраты',
      'Elevator losts': 'Элеватор потери',
      Elevators: 'Элеваторы',
      Email: 'Эл.почта',
      'Email Templates': 'Шаблоны рассылки',
      'Email max length': 'Максимальная длина E-mail-а',
      'Email preview': 'Предварительный просмотр Email',
      'Email templates': 'Шаблоны рассылки',
      Emails: 'Emails',
      'Empty finances list': 'Пустой список инвойсов',
      Enabled: 'Включено',
      End: 'Окончание',
      'End Date': 'Дата окончания',
      'End balance': 'Итоговый баланс',
      'End date': 'Дата окончания',
      'End of execution': 'Конец исполнения',
      'End usage (date)': 'Конец использ (дата)',
      Ending: 'Окончание',
      'Ending cash': 'Остаток на конец',
      'Ending status': 'Финальный статус',
      Eng: 'Англ',
      English: 'English',
      'Ensure to <span class="positive-number">save customs declarations</span> if\n            necessary!':
        'Обязательно <span class="positive-number">сохраните таможенные декларации</span> \n            при необходимости!',
      'Enter logistic volume limit': 'Ввести лимит объема Логистики, т',
      'Enter volume limit': 'Ввести лимит объема',
      'Entry date / Exit date': 'Дата въезда / Дата выезда',
      'Error:': 'Ошибка:',
      'Estimated P&L': 'Предполагаемый П&Л',
      'Estimated Q (T)': 'Ожидаемое кол-во (т)',
      'Estimated receiving (date)': 'Предполагаемое получение (дата)',
      'Estimated volume of the deal for contract allocation':
        'Ожидаемый объем сделки для распределения контракта',
      Europe: 'Европа',
      Event: 'Событие',
      'Event Subscription': 'Подписки',
      'Event subscriptions': 'Подписки',
      Exact: 'Точный',
      Exchange: 'Курс',
      'Exchange rate': 'Курс обмена',
      'Exchange rate alternative': 'Альтернативный курс обмена',
      Exchanges: 'Курсы',
      'Exclude from positions': 'Не учитывать в позициях',
      'Exec forecasted amount': 'Ожидаемая сумма за сделку',
      Executed: 'Выполнено',
      'Executed - Paid': 'Исполнено - оплачено',
      'Executed qty': 'Выполненное кол-во',
      'Executed volume': 'Выполненный объем',
      Execution: 'Исполнение',
      'Execution (end date)': 'Исполнение (дата окончания)',
      'Execution (start date)': 'Исполнение (дата начала)',
      'Execution (t)': 'Исполнение (т)',
      'Execution - Analytical': 'Выполнение - Аналитика',
      'Execution - Invoices': 'Выполнено - Проинвойсированно',
      'Execution Dates': 'Даты исполнения',
      'Execution amount': 'Сумма по исполнению',
      'Execution date': 'Дата исполнения',
      'Execution end': 'Дата окончания исполнения',
      'Execution end date': 'Исполнение (дата конца)',
      'Execution end date position': 'Позиция даты окончания выполнения',
      'Execution estimated month': 'Ожидаемый месяц исполнения',
      'Execution margin': 'Маржа по исполнению',
      'Execution month': 'Месяц исполнения',
      'Execution of loyalty programs': 'Исполнение программ лояльности',
      'Execution period': 'Период исполнения',
      'Execution start': 'Дата начала исполнения',
      'Execution start date': 'Исполнение дата начала',
      'Execution start date position': 'Позиция даты начала выполнения',
      'Execution value': 'Сумма исполнения',
      'Existing Payment plan': 'Существующий План оплат',
      'Expected month of charge': 'Ожидаемый месяц начисления',
      Expense: 'Расходы',
      'Expense type': 'Тип Затраты',
      Expenses: 'Затраты',
      Expiration: 'Экспирация',
      'Expiration date of power of attorney': 'Окончание срока действия доверенности',
      'Expiration end': 'Окончание экспирации',
      'Expiration start': 'Начало экспирации',
      Expired: 'Истекший',
      Expiring: 'Экспирация',
      Export: 'Экспорт',
      'Export Contract': 'Экспортный контракт',
      'Export Contract (#)': 'Экспортный контракт (#)',
      'Export allocations': 'Распределение экспорта',
      'Export business unit': 'Експорт бизнес юнита',
      'Export contract': 'Экспортный контракт',
      'Export contract number': 'Экспорт номера контракта',
      'Export contract position': 'Экспорт позиции контракта',
      'Export contract price': 'Экспорт цены контракта',
      'Export contract total': 'Всего по экспортному контракту',
      'Export invoice': 'Инвойс для экспорта',
      'Export invoiced': 'Выставлен экспортный инвойс',
      'Export multicontract': 'Экспортный мультиконтракт',
      'Export multiticket': 'Экспортный мультитикет',
      'Export tax': 'Экспортная пошлина',
      'Export tax:': 'Экспортная пошлина:',
      ExportContract: 'Экспортный контракт',
      Exporter: 'Экспортер',
      'Exporter address': 'Экспортер адрес',
      'Exporter can signatory docs': 'Экспортер подписант доки',
      'Exporter can signatory docs eng': 'Экспортер подписант доки англ',
      'Exporter country': 'Экспортер страна',
      'Exporter country eng': 'Экспортер страна англ',
      'Exporter email': 'Экспортер почта',
      'Exporter full name': 'Экспортер полное название',
      'Exporter full name eng': 'Экспортер полное название английский',
      'Exporter legal address': 'Экспортер адрес полный англ',
      'Exporter legal address eng': 'Экспортер адрес полный англ',
      'Exporter list': 'Список экспортеров',
      'Exporter list eng': 'Список экспортеров англ',
      'Exporter name': 'Экспортер имя',
      'Exporter phone': 'Экспортер телефон',
      'Exporter signatory': 'Экспортер подписант',
      'Exporter signatory eng': 'Экспортер подписант англ',
      'Exporter website': 'Экспортер сайт',
      Exporters: 'Экспортеры',
      'Exporters eng': 'Список экспортеров англ',
      'Exporters list': 'Список экспортеров',
      'Exporters list eng': 'Список экспортеров англ',
      Exposition: 'Экспозиция',
      'Exposition 12,70 Cts/Bu ou -5$/T': 'Результат 12,70 Cts/Bu ou -5$/T',
      'Exposition 25,40 Cts/Bu ou -10$/T': 'Результат 25,40 Cts/Bu ou -10$/T',
      'Exposition, Mt': 'Риск, т',
      Ext: 'Ext',
      Extension: 'Пролонгация',
      'Extension (end)': 'Есть экстеншн',
      'Extension (start)': 'Пролонгация (начало)',
      'Extra actions/buttons': 'Дополнительные действия/кнопки',
      FACT: 'ФАКТ',
      FILTERS: 'ФИЛЬТРЫ',
      FINANCE: 'ФИНАНСЫ',
      FINANCES: 'ФИНАНСЫ',
      'FOB price': 'FOB цена',
      'FUNDS DISTRIBUTION': 'РАСПРЕДЕЛЕНИЕ СРЕДСТВ',
      Fact: 'Факт',
      'Fact - Plan': 'Факт - План',
      'Fact created': 'Факт создано',
      'Fact crop protection': 'Защита урожая факт',
      'Fact distributor': 'Фактический дистрибьютор',
      'Fact distributors': 'Фактические дистрибьюторы',
      'Fact seeds': 'Посевы факт',
      Facts: 'Факты',
      Fail: 'Неудачно',
      Failed: 'Провалено',
      'Failure reason': 'Причина отказа',
      'Failure reasons': 'Причины отказа',
      Farm: 'Хозяйство',
      Favorite: 'Избранные',
      'Favorite Filters': 'Любимые фильтры',
      'Favorite filter': 'Любимый фильтр',
      'Favorites management': 'Управление избранным',
      'Favourite filters': 'Избранные фильтры',
      February: 'Февраль',
      'Fee amount': 'Сумма комиссии',
      'Few consignees': 'Несколько грузополучателей',
      'Few prices': 'Несколько цен',
      Field: 'Поле',
      File: 'Файл',
      "File '' is to big": 'Файл ‘’ слишком большой',
      'Fill by template': 'Заполнить по шаблону',
      'Fill if you want to calculate MTM by this contract':
        'Заполните, если хотите рассчитать МТМ по этому контракту',
      'Fill it if only there are some differences from standard compliance process. This will cause APPROVED WITH CONDITION status':
        'Заполните, если есть некоторые отличия от стандартного процесса комплаенса. В этом случае статус будет\nПОДТВЕРЖДЕНО С УСЛОВИЯМ',
      'Fill it only if you want this contract to be converted by a certain rate':
        'Заполните, если вы хотите, чтобы этот контракт был конвертирован по определенному курсу',
      'Fill it to display Invoice in Budget report':
        'Заполните, чтобы показать инвойс в отчете по бюджету',
      Filter: 'Фильтр',
      'Filter Set removed': 'Фильтры удален',
      'Filter Set updated': 'Фильтр обновлен',
      'Filter by': 'Фильтр по',
      'Filter by selected business units': 'Фильтр по выбранным бизнес юнитам',
      Filters: 'Фильтры',
      'Fin purpose': 'Фин цель',
      Final: 'Финал',
      'Final Amount': 'Финальная сумма',
      'Final Volume (T)': 'Финальный объем',
      'Final amount': 'Финальная сумма',
      'Final approve': 'Финальное подтверждение',
      'Final confirmation': 'Финальное подтверждение',
      'Final date': 'Финальная дата',
      'Final total': 'Итоговая сумма',
      'Final volume': 'Финальный объем',
      'Final volume equals connections': 'Финальный объем равен привязкам',
      'Final volume sum from positions': 'Итоговая сумма объемов по позициям',
      Finance: 'Финансы',
      'Finance Account': 'Финанс счета',
      'Finance Bank': 'Финансы банк',
      'Finance account': 'Финанс счет',
      'Finance accounts': 'Финанс счета',
      'Finance credit account': 'Финанс кредит счет',
      'Finance days': 'Финанс дни',
      'Finance debit account': 'Финанс дебет счет',
      "Finance doesn't exist": 'Инвойс не существует',
      'Finance offset value': 'Финансы зачет стоимость',
      'Finance passport position': 'Финансы паспорт позиция',
      Finances: 'Финансы',
      'Finances mailing': 'Рассылка инвойсов',
      'Financial purpose': 'Финансовая цель',
      'Financial result': 'Финансовый результат',
      Financing: 'Финансирование',
      'Find a filter': 'Найти фильтр',
      'Fines amount': 'Сумма штрафов',
      'Fines days count': 'Количество дней штрафа',
      'Fines days count method': 'Метод подсчета штрафных дней',
      'Fines invoicing': 'Выставление счетов на оплату штрафов',
      'Fines invoicing method': 'Метод инвойсирования штрафов',
      'First (validation)': 'Первый (валидация)',
      'First - select a invoices previously filtered them Second - press Create an Emails\n                Third - look at the table below, you email will be ready to preview, gently check it\n                and then send.':
        'Во-первых, выберите инвойсы, предварительно отфильтровав их. Во-вторых, нажмите «Создать письмо».\n                 В-третьих, посмотрите на таблицу ниже, ваше письмо будет готово к просмотру, аккуратно проверьте его, \n                а затем отправьте.',
      'First Name': 'Имя',
      'First Name is required': 'Имя обязательно',
      'First charge': 'Первая затрата',
      'First confirm date': 'Дата первого подтверждения',
      'First confirmation (date)': 'Первое подтверждение (дата)',
      'First confirmed': 'Первое подтверждение',
      'First contact': 'Перв.контакт',
      'First date must be less then Second': 'Первая дата должна быть ранее другой',
      'First day of the week': 'Первый день недели',
      'First name': 'Имя',
      Fixed: 'Зафиксировано',
      'Fixed lots': 'Зафиксированные лоты',
      'Fixed price bonus': 'Бонус с фиксированной ценой',
      'Fixed tonnes': 'Зафиксированные тонны',
      Fixing: 'Fixing',
      'Fixing Hedging': 'Фиксинг/Хеджирование',
      'Fixing and hedging': 'Фиксинг и хеджинг',
      'Fixing position': 'Фиксинг позиция',
      'Fixing/Hedging': 'Фиксинг/Хеджирование',
      Flag: 'Флаг',
      'Flat price': 'Фиксированная цена',
      'Flat price per not fixed lots': 'Цена за незафиксированный лоты',
      'Flat price per not hedged lots': 'Цена за незахеджированные лоты',
      Fobbing: 'Фоббинг',
      'Folder number': 'Номер папки',
      Font: 'Шрифт',
      'For KPI year report': 'Для КПИ отчета (год)',
      'For example if the contract qty is 5000 and this contract has cost of 1000$ and\n                    you allocated this contract on two passports 2500 and 2500 respectively':
        'Например, если объем контракта составляет 5000 и этот контракт имеет затрату 1000 USD,\n                   и вы распределили этот контракт на два паспорта 2500 и 2500 соответственно',
      'For purposes of filtering information by specific season':
        'Для фильтрации информации по конкретному сезону',
      Forecast: 'Прогноз',
      'Forecasted - Payment': 'Ожидание - Оплаты',
      'Foreign currency': 'Валюта',
      'Form invalid': 'Ошибка формы',
      Forward: 'Форвард',
      'Forward fact': 'Форвард факт',
      'Forward limit': 'Форвард лимит',
      Forwarder: 'Экспедитор',
      'Forwarder railway': 'Экспедитор жд',
      Forwarders: 'Экспедиторы',
      Fr: 'Пт',
      'Free Volume': 'Свободный объем',
      'Free days EWRP': 'Свободные дни EWRP',
      'Free days JCC': 'Свободные дни JCC',
      'Free tax invoice': 'Свободный налог инвойс',
      'Free volume': 'Свободный объем',
      'Free volume PCD': 'Свободный объем PCD',
      Freight: 'Фрахт',
      'Freight Info': 'Фрахт информация',
      'Freight Position': 'ПОЗИЦИЯ ФРАХТА',
      'Freight VAT': 'Фрахт НДС',
      'Freight broker costs': 'Фрахт брокер затраты',
      'Freight costs': 'Фрахт затраты',
      'Freight currency opposite': 'Валюта фрахта противоположная',
      'Freight exchange rate': 'Обменный курс по фрахту',
      'Freight level': 'Фрахт',
      'Freight opposite': 'Встречный фрахт',
      'Freight position + MTM': 'ПОЗИЦИЯ ФРАХТА + ОЦЕНКА К РЫНКУ',
      Freights: 'Фрахты',
      From: 'От',
      'From Stock Reserve Unit': 'Из резервного подразделения',
      'From production': 'С производства',
      'From user': 'От пользователя',
      'From which country purchased': 'Из какой страны куплено',
      'From whom': 'От кого',
      'Full amount': 'Полная стоимость',
      'Full amount without VAT': 'Полная Сумма без НДС',
      'Full company name, LLC': 'Полное название компании, ООО',
      'Full current address': 'Полный текущий адрес',
      'Full name': 'Полное название',
      'Full name (eng)': 'Полное имя (англ.)',
      'Full name Eng': 'Полное имя (Англ.)',
      'Full name eng': 'Полное название Английский',
      'Full name of person who signs documents by this company':
        'ФИО представителя компании, подписывающего документы',
      'Full name of the Signatory to use in requisites while generating documents':
        'Полное имя Подписанта, которое используется для генерации документов',
      'Full name to use in requisites while generating documents':
        'Полное имя, которое используется для генерации документов',
      'Full self-cost': 'Полная Себестоимость',
      'Fully invoiced': 'Полностью проинвойсировано',
      'Future contact': 'Следующий контакт',
      'Future deleted': 'Фьючерс удален',
      'Future updated': 'Фьючерс обновлен',
      Futures: 'Фьючерсы',
      GAINS: 'ПОСТУПЛЕНИЯ',
      GENERAL: 'ОБЩЕЕ',
      'GENERAL INFO': 'ОБЩАЯ ИНФОРМАЦИЯ',
      'GENERAL TOTAL': 'ОБЩИЙ ИТОГИ',
      GROUPS: 'ГРУППЫ',
      'GT User': 'Пользователь',
      Gains: 'Поступления',
      General: 'Общий',
      'General Agreement': 'Договор',
      'General Contracts': 'Генеральные соглашения',
      'General Expenses': 'Общие расходы',
      'General Tickets': 'Общие тикеты',
      'General Transaction': 'Общая транзакция',
      'General Transactions': 'Общие транзакции',
      'General agreement': 'Генеральный договор',
      'General agreements': 'Генеральные соглашения',
      'General expense': 'Общие затраты',
      'General expenses': 'Общие затраты',
      'General info': 'Общая информация',
      'General transactions': 'Общие транзакции',
      Generate: 'Сгенерировать',
      'Generate Document': 'Сгенерировать документ',
      'Generate a document': 'Распечатать документ',
      'Generate a preview': 'Сгенерировать предпросмотр',
      'Generate agreement number': 'Сгенерировать номер договора',
      'Generate an email': 'Сгенерировать письмо',
      'Generate contract name': 'Сгенерировать номер',
      'Generate document': 'Распечатать документ',
      'Generate loan name': 'Сгенерировать номер займа',
      'Generate name': 'Сгенерировать наименование',
      'Generate number': 'Сгенерировать номер',
      'Get counterparty data': 'Получить данные контрагента',
      'Go to a PNL report': 'Перейти к отчету PnL',
      'Go to details': 'Перейти к деталям',
      Goal: 'Цель',
      'Google clean': 'Очистка Google',
      'Grain Quality Check': 'Проверка качества',
      Green: 'Зеленое',
      Grey: 'Серое',
      'Gross Closed': 'Валовая по закрытым',
      'Gross Margin': 'Общая маржа',
      Group: 'Группа компаний',
      'Group by': 'Группировка по',
      'Group passports into a chain': 'Группировать паспорта в цепочку',
      'Group totals': 'Групповой тотал',
      Grouped: 'Сгруппировано',
      Grouping: 'Группировка',
      'Grouping & filtering': 'Группировка и фильтр',
      'Grouping chain': 'Цепь группировки',
      'Grouping criteria': 'Критерий группировки',
      Groups: 'Группы',
      HO: 'HO',
      'Has Amount Per Deal Execution': 'Есть общая сумма за сделку',
      'Has Analytical amount': 'Есть аналитическая сумма',
      'Has BL': 'Есть коносамент',
      'Has Business Unit': 'Есть бизнес юнит',
      'Has Custom status': 'Пользовательский статус',
      'Has Discount': 'Есть дисконт',
      'Has Exec Forecasted Amount': 'Есть ожидаемая сумма за сделку',
      'Has Final amount': 'Есть финальная сумма',
      'Has LOI': 'Есть LOI',
      'Has Multicontract': 'Имеет Мультиконтракт',
      'Has Our amount': 'Есть наш объем',
      'Has Payment Info': 'Есть банковская информация',
      'Has Voyage': 'Есть рейс',
      'Has additional info': 'Есть дополнительная инфо',
      'Has all docs copy of DBL': 'Есть копия доков DBL',
      'Has amount reconciliation': 'Имеет сверку суммы',
      'Has any quality': 'Имеет любое качество',
      'Has balance': 'Есть баланс',
      'Has balance prepay': 'Есть баланс',
      'Has balance to connect': 'Есть доступный объем к прикреплению',
      'Has bank details': 'Имеет банковские реквизиты',
      'Has basis doc': 'Есть базис документ',
      'Has broker': 'Есть брокер',
      'Has buyer contract cargo confirmation': 'У продажного контракта груз подтвержден',
      'Has buyer contract final confirmation': 'У продажного контракта финал подтвержден',
      'Has buyer contract payment confirmation': 'У продажного контракта платеж подтвержден',
      'Has cancelled invoice': 'Есть отклонен инвойс',
      'Has cancelled signature': 'Подписание отменено',
      'Has cargo confirmation': 'Груз подтверждение',
      'Has cargo customs declaration': 'Имеет грузовую таможенную декларацию',
      'Has comments': 'Есть комментарии',
      'Has connection to passport': 'Имеет привязку к паспорту',
      'Has contract': 'Есть контракт',
      'Has contracts': 'Имеет контракты',
      'Has control date': 'Есть дата контроля',
      'Has cost basis warning': 'Имеет предупреждение о стоимости',
      'Has customs declaration': 'Имеет таможенную декларацию',
      'Has date': 'Есть дата',
      'Has date of execution': 'Есть дата исполнения',
      'Has derivative operations': 'Имеет деривативные операции',
      'Has destination agent': 'Есть агент в стране назначения',
      'Has destination intermediate agent': 'Есть агент-посредник в стране назначения',
      'Has destination port': 'Имеет порт назначения',
      'Has destination port intermediate': 'Имеет промежуточный порт назначения',
      'Has diff limit': 'Имеет ограничения по лимиту',
      'Has disbursement bill of lading': 'Есть расходная накладная',
      'Has docs': 'Есть документы',
      'Has docs copy': 'Есть копия доков',
      'Has docs original': 'Есть оригинал доков',
      'Has documents': 'Есть документы',
      'Has empty qualities': 'Имеет пустые качества',
      'Has export facts': 'Имеет экспортные факты',
      'Has export invoice': 'Имеет инвойс экспорта',
      'Has export logistics': 'Имеет логистику экспорта',
      'Has fact crop protection': 'Есть дата исполнения',
      'Has fact seeds': 'Есть факт посев',
      'Has filled qualities': 'Имеет наполненные качества',
      'Has final confirmation': 'Финал подтвержд',
      'Has fixing': 'Есть фиксинг',
      'Has general agreement': 'Имеет генеральное соглашение',
      'Has grain quality check': 'Есть проверка качества',
      'Has hedging': 'Есть хеджирование',
      'Has incoming invoice': 'Есть входящий инвойс',
      'Has indicator': 'Имеет индикатор',
      'Has invoice': 'Есть инвойс',
      'Has invoice Contract/Passport connected': 'Есть связанный контракт/паспорт с инвойсом',
      'Has invoice documents': 'Есть документы на инвойс',
      'Has invoices': 'Имеет инвойсы',
      'Has issuer counterparty': 'Есть контрагент выдал',
      'Has limit': 'Есть лимт',
      'Has loading agent': 'Есть агент в стране загрузки',
      'Has loading intermediate agent': 'Есть агент-посредник в стране загрузки',
      'Has loading port': 'Имеет порт загрузки',
      'Has loading port intermediate': 'Имеет промежуточный порт погрузки',
      'Has logistic': 'Есть логистика',
      'Has margin diff by type': 'Имеет разницу в марже по типу',
      'Has multicontract': 'Имеет мультиконтракт',
      'Has no basis doc': 'Нет базис документ',
      'Has no quality': 'Нет качества',
      'Has note': 'Есть примеч',
      'Has offset': 'Есть зачёт',
      'Has only empty qualities': 'Имеет только пустые качества',
      'Has only filled qualities': 'Имеет только заполненные качества',
      'Has originals received': 'Оригиналы получены',
      'Has outgoing invoice': 'Есть исходящие инвойс',
      'Has own purchase price': 'Имеет свою цену закупки',
      'Has own sale price': 'Имеет свою цену продажи',
      'Has passport or contract': 'Имеет паспорт или контракт',
      'Has payment': 'Есть оплата',
      'Has payment confirmation': 'Оплата подтверждение',
      'Has periodic customs declaration': 'Имеет периодическую таможенную декларацию',
      'Has plan crop protection': 'Есть план защиты урожая',
      'Has plan seeds': 'Есть плановый посев',
      'Has position cancelled signature': 'Подпись для позиции отменена',
      'Has position connected to passport': 'Позиция связана с паспортом',
      'Has position with cargo customs declaration': 'Позиция с таможенной декларацией на груз',
      'Has position with periodic customs declaration':
        'Имеет позицию с периодическим таможенным декларированием',
      'Has povitive balance': 'Есть положительный баланс',
      'Has prepay': 'Есть предоплата',
      'Has purchase facts': 'Есть закупка',
      'Has reassignment': 'Есть переуступка',
      'Has receiver counterparty': 'Есть контрагент получ',
      'Has responsible': 'Есть ответственный',
      'Has sale facts': 'Есть продажа',
      'Has sale internal': 'Имеет внутренний договор на продажу',
      'Has season': 'Есть сезон',
      'Has several passports': 'Имеет несколько паспортов',
      'Has shipments': 'Имеет поставки',
      'Has supplier contract cargo confirmation': 'У закупочного контракта груз подтвержден',
      'Has supplier contract final confirmation': 'У закупочного контракта финал подтвержден',
      'Has supplier contract payment confirmation': 'У закупочного контракта платеж подтвержден',
      'Has ticket': 'Имеет тикет',
      'Has uninvoiced cost': 'Имеет непроинвойсированные расходы',
      'Has unpaid invoice': 'Имеет неоплаченные инвойсы',
      'Has volume reconciliation': 'Имеет сверку объемов',
      'Has volume with docs': 'Есть объем с доками',
      'Has withdrawal date': 'Имеет дату вывода',
      'Have to confirm': 'Могут подтверждать',
      'Heads of regional manager': 'Главы региональных представителей',
      'Heads of sale managers': 'Главы региональных представителей',
      Hedged: 'Захеджированно',
      'Hedged lots': 'Хеджировать лоты',
      Hedging: 'Хеджирование',
      'Help buttons expanded': 'Кнопки справки расширены',
      'Here are the similar companies by name. Check if your current company is not conflicting with existing ones.':
        'Здесь показаны похожие названия компаний. Проверьте, что название текущей компании не совпадает с уже существующими.',
      'Here is the list of all passports. Passport is the full cycle of the deal from\n                beginning to the end.':
        'Вот список всех паспортов. Паспорт – это полный цикл сделки \n                от начала до конца.',
      'Here you can allocate purchase side with the sale side and GT will do everything by\n                itself: calculate profitability, consolidate costs, documents, invoices in one\n                place.':
        'Здесь вы можете распределить сторону закупки со стороной продаж и GrainTrack все сделает самостоятельно:\n                рассчитает рентабельность, консолидирует затрат, документы, инвойсы\n                в одном месте.',
      'Here you can type full title of the company with type of ownership, etc. Example - Full company name, LLC':
        'Здесь вы можете ввести полное название компании с типом собственности и т.д. Пример - полное название компании, ООО',
      'Hidden by default': 'Скрыто по умолчанию',
      Hide: 'Спрятать',
      'Hide Users': 'Спрятать пользователей',
      'Hide all': 'Спрятать всё',
      'Hide child crops': 'Спрятать дочерние культуры',
      'Hide executed': 'Спрятать исполненное',
      'Hide fixed contracts': 'Скрыть фиксированные контракты',
      'Hide full table': 'Спрятать полную таблицу',
      'Hide hedged contracts': 'Скрыть хеджированные контракты',
      'Hide history': 'Спрятать историю',
      'Hide inactive accounts': 'Скрыть неактивные аккаунты',
      'Hide passport without accounts': 'Скрыть паспорт без аккаунтов',
      High: 'Важно',
      History: 'История',
      Home: 'Главная',
      'Horizontal groups': 'Горизонтальные группы',
      Hot: 'Горячий',
      IBAN: 'IBAN',
      ID: 'ID',
      IMO: 'ИМО',
      IN: 'Вход',
      INCOMING: 'ВХОДЯЩИЕ',
      INDICATORS: 'ИНДИКАТОРЫ',
      INFO: 'ИНФО',
      'INITIAL DATE': 'НАЧАЛЬНАЯ ДАТА',
      INTERMEDIATE: 'ПРОМЕЖУТОЧНЫЙ',
      INVOICE: 'ИНВОЙС',
      'INVOICE DOCUMENTS': 'ДОКУМЕНТЫ ИНВОЙСЫ',
      INVOICED: 'ПРОИНВОЙСИРОВАННО',
      'INVOICED VALUE': 'СУММА ИНВОЙСОВ',
      INVOICES: 'ИНВОЙСЫ',
      'INVOICES LIST': 'СПИСОК ИНВОЙСОВ',
      ISCC: 'ISCC',
      ITN: 'ИНН',
      'If company has more than one number': 'Если у компании больше одного номера',
      'If current company is a part of a bigger group':
        'Если текущая компания является частью более крупной группы',
      'If you have a complicated chain you will need it.':
        'Если у вас сложная цепочка, она вам понадобится.',
      'If you pick something here, information about this object will be displayed only for people from those business units':
        'Если вы выберете что-то здесь, информация об этом объекте будет отображаться только для людей из этих бизнес-юнитов',
      'If you want fill by template': 'Если вы хотите заполнить по шаблону',
      'If you want so set negotiation process and defined whether charge is validated or under discussion':
        'Если вы хотите установить процесс подтверждения и определить, подтверждена ли затрата или обсуждается',
      'If you want to add type or activity to this company':
        'Если вы хотите добавить тип или деятельность к этой компании',
      'If you want to assign some custom role to this company':
        'Если вы хотите назначить какую-то пользовательскую роль этой компании',
      'If you want to convert all financial data in passport by one rate to USD':
        'Если вы хотите конвертировать все финансовые данные в паспорте по одному курсу в USD',
      'Ignore allocation': 'Игнорировать распределение',
      'Ignore client status': 'Игнорировать статус контрагента',
      'Ignore counterparty limits': 'Игнорировать лимит',
      'Ignore counterparty status': 'Игнорировать статус контрагента',
      'Ignore excess contract volume': 'Игнорировать избыточный объем контракта',
      'Ignore limits': 'Игнорировать лимиты',
      Imo: 'Имо',
      Import: 'Импорт',
      Important: 'Важно',
      Imported: 'Импортировано',
      In: 'Вход',
      'In date': 'Дата получения',
      'In general, passport is one vessel, which can contain sale and purchases in\n                different configurations: 1 sale vs 1 purchase, 1vs3, 5vs2, whatever. Or if it is\n                inland deal - one passport is just some sale in from of some purchase even without a\n                vessel.':
        'В общем, паспорт — это одно судно, которое может содержать продажу и покупку в\n                различных конфигурациях: 1 продажа против 1 покупки, 1 против 3, 5 против 2, что угодно. Или, если это\n                внутренняя сделка — один паспорт — это просто некоторая продажа в форме некоторой покупки даже без\n                судна.',
      'In number': 'Входящий номер',
      'In process': 'В процессе',
      'In progress': 'В процессе',
      'In the case of any questions, please, contact our Support Team':
        'В случае возникновения вопросов обращайтесь в нашу службу поддержки.',
      'In the case, if contract is PURCHASE - then EXPORTERS are chosen amont the exporters of SUPPLIER of this contractIf the contract is SALE, then EXPORTERS are chose from PURCHASE contracts, that are linked with this SALE contractvia passport. So, to add Exporters to Sale contract, at first, link this Sale contract with other Purchase contracts via Passport.':
        'Если контракт ЗАКУПОЧНЫЙ - тогда ЭКСПОРТЕРОВ выбираем из экспортеров ПОСТАВЩИКА. Если контракта ПРОДАЖНЫЙ, тогда ЭКСПОРТЕРЫ сами выбираются со всех ЗАКУПОЧНЫХ контрактов, которые связаны с ПРОДАЖНЫМ контрактов через ПАСПОРТ. Итак, для того, чтобы добавить Экспортеров в Продажный контракт свяжите этот Продажный контракт с Закупочными через Паспорт.',
      'In the case, if general agreement is PURCHASE - then the BUYER is our company. It is chosen from the list of Owners':
        'Если контракт ЗАКУПОЧНЫЙ - тогда ПОКУПАТЕЛЕМ является наша компания. Ее можно выбрать из списка Владельцев',
      'In the case, if general agreement is SALE - then the SUPPLIER is our company. It is chosen from the list of Owners':
        'Когда контракта ПРОДАЖНЫЙ - ПОСТАВЩИКОМ является наша компания. Ее можно выбрать из списка владельцев',
      Inactive: 'Неактивный',
      'Include VAT in amount': 'Включить ПДВ в сумму',
      'Include intermediate logistics': 'Включить промежуточную логистику',
      'Including derivatives': 'Включить деривативы',
      Incomes: 'Доходы',
      Incoming: 'Входящие',
      'Incoming (date)': 'Входящий (дата)',
      'Incoming - all invoiced issued to us (means we will spend cash). Outgoing - all invoices issued by us (means: we will receive cash)':
        'Входящие - все инвойсы, выставленные нам (означает, что мы платим). Исходящие - все инвойсы, выставленными нами (нам платят)',
      'Incoming - invoice is received from counterparty, Outgoing - invoice is issued by us':
        'Входящие – инвойс получен от контрагента, Исходящие – счет выставлен нами',
      'Incoming balance': 'Входящий баланс',
      'Incoming invoice status': 'Статус входящего инвойса',
      'Incoming invoices': 'Входящие инвойсы',
      'Incoming payments': 'Входящие оплаты',
      'Incoming reserve': 'Входящий резерв',
      'Incoming volume': 'Входящий объем',
      Incoterms: 'Базис',
      Indicator: 'Индикатор',
      'Indicator creation': 'Создание индикатора',
      'Indicator crops': 'Индикатор товары',
      'Indicator free volume exceeded! Available free volume:':
        'Индикатор свободного объема превышен! Доступный свободный объем:',
      'Indicator number': 'Номер индикатора',
      'Indicator price': 'Цена индикатора',
      'Indicator saved': 'Индикатор сохранен',
      Indicators: 'Индикаторы',
      'Indicators (spot)': 'Индикаторы (спот)',
      'Indicators number': 'Номера индикаторов',
      'Individual tax number': 'Индивидуальный налоговый номер',
      Info: 'Инфо',
      'Info for traders': 'Информация для трейдеров',
      Information: 'Информация',
      'Information about company as a tax payer': 'Информация о компании как налогоплательщике',
      'Initial amount': 'Первоначальная сумма',
      'Initial balance': 'Первоначальный баланс',
      'Initial cash': 'Первоначальные денежные средства',
      'Initial cash is required': 'Первоначальная сумма обязательна',
      'Initial date': 'Начальная дата',
      Initiator: 'Инициатор',
      'Input number': 'Введите число',
      Instance: 'Сущность',
      'Instructions sent': 'Инструкции отосланы',
      Insurance: 'Страхование',
      'Insurance status': 'Статус страхования',
      Insurer: 'Страховая',
      Insurers: 'Страховые',
      'Intake volume': 'Объем погрузки',
      'Interest Rate Amount': 'Сумма процентной ставки',
      'Interest amount': 'Сумма процентов',
      'Interest payment date': 'Дата оплаты процентов',
      'Interest payment date (plan)': 'Дата оплаты процентов (план)',
      'Interest rate': 'Процентная ставка',
      'Interest rate delay': 'Задержка процентной ставки',
      'Interest rate, %': 'Процентная ставка, %',
      Intermediate: 'Промежуточный',
      'Intermediate Contract': 'Промежуточный Контракт',
      'Intermediate Contract #': 'Промежуточный Контракт #',
      'Intermediate buyer': 'Промежуточный покупатель',
      'Intermediate contract': 'Промежуточный контракт',
      'Intermediate logistics': 'Промежуточная логистика',
      'Intermediate multicontract': 'Промежуточный мультиконтракт',
      'Intermediate multiticket': 'Промежуточный мультитикет',
      IntermediateContract: 'Промежуточный контракт',
      'Internal Chain': 'Внутренняя цепочка',
      'Internal chain': 'Внутренняя цепочка',
      'Internal chain contracts': 'Внутрення цепочка контрактов',
      Invalid: 'Неактуально',
      'Inventory Value in Stock, $': 'Стоимость запасов на складе, $',
      Invoice: 'Инвойс',
      'Invoice Amount Difference': 'Разница в сумме инвойса',
      'Invoice Assignment': 'Назначение счета',
      'Invoice Position': 'Инвойс-позиция',
      'Invoice Position configs': 'Конфигурации инвойсов',
      'Invoice Positions': 'Инвойс позиции',
      'Invoice Status': 'Статус инвойса',
      'Invoice charge': 'Оплата инвойса',
      'Invoice commodity': 'Культура инвойса',
      'Invoice condition': 'Условие инвойса',
      'Invoice configuration': 'Конфигурации инвойсов',
      'Invoice creation': 'Создание инвойса',
      'Invoice data': 'Дата инвойса',
      'Invoice date': 'Дата инвойса',
      'Invoice date of issuance': 'Дата выставления инвойса',
      'Invoice date of payment fact': 'Дата платежа инвойса (факт)',
      'Invoice date of payment plan': 'Дата платежа инвойса (план)',
      'Invoice discount': 'Дисконт',
      'Invoice discount amount': 'Инвойс дисконт значение',
      'Invoice documents': 'Инвойс документы',
      'Invoice fines': 'Штрафы инвойса',
      'Invoice is already fully paid': 'Счет уже полностью оплачен',
      'Invoice is overpaid': 'Инвойс переплачен',
      'Invoice is paid partially': 'Инвойс оплачен частично',
      'Invoice is paid.': 'Инвойс оплачен.',
      'Invoice is unpaid': 'Инвойс не оплачен',
      'Invoice margin': 'Маржа по инвойсам',
      'Invoice number': 'Номер ин',
      'Invoice number #': 'Номер инвойса №',
      'Invoice payments': 'Инвойс оплаты',
      'Invoice percent': 'Процент',
      'Invoice position': 'Инвойс позиция',
      'Invoice positions': 'Инвойс позиции',
      'Invoice ref': 'Номер инвойса',
      'Invoice risk': 'Инвойс риск',
      'Invoice risk report': 'Отчет о риске инвойса',
      'Invoice saved': 'Инвойс сохранен',
      'Invoice status': 'Статус инвойса',
      'Invoice type': 'Тип инвойса',
      'Invoice updated': 'Инвойс обновлен',
      'Invoice use': 'Использование инвойса',
      'Invoice was successfully created': 'Инвойс успешно создан',
      'Invoice-Contract counterparty difference': 'Инвойс-Контракт разница по контрагентам',
      Invoiced: 'Выставлено счетов',
      'Invoiced (bal)': 'Выставлено (баланс)',
      'Invoiced (balance)': 'Выставлено (баланс)',
      'Invoiced - Paid': 'Выставлено - оплачено',
      'Invoiced - paid': 'Выставлено - оплачено',
      'Invoiced Amount': 'Сумма инвойсов',
      'Invoiced Cargo': 'Оплаченный груз',
      'Invoiced amount': 'Сумма инвойса',
      'Invoiced canceled': 'Инвойс отменен',
      'Invoiced logistic': 'Инвойс логистика',
      'Invoiced volume': 'Выставленный объем инвойса',
      Invoices: 'Инвойсы',
      'Invoices - Analytical': 'Инвойсы - Аналитика',
      'Invoices - Payment': 'Инвойсы - Оплаты',
      'Invoices Analytics': 'Аналитика инвойсов',
      'Invoices risk': 'Риск инвойсов',
      'Invoices to be paid': 'Инвойсы к оплате',
      'Invoices. Completed when invoices are issued': 'Инвойсы. Завершено, когда инвойс выставлен',
      Invoicing: 'Инвойсирование',
      'Invoicing costs': 'Инвойсирование расходов',
      'Is Autocreated': 'Создано автоматически',
      'Is Elevator': 'Элеватор',
      'Is Exporter': 'Экспортер',
      'Is Farm': 'Хозяйство',
      'Is Internal Chain': 'Внутренняя цепочка',
      'Is Swap': 'СВОП',
      'Is a role of legal entity': 'Является ролью юридического лица',
      'Is a ticket lost': 'Тикет потерян',
      'Is client agent': 'Компания посредник',
      'Is client producer': 'Это контрагент-производитель',
      'Is client resident': 'Контрагент является резидентом',
      'Is closing of position': 'Закрытие существующей позиции',
      'Is distributor': 'Дистрибьютор',
      'Is extension': 'Расширение',
      'Is full invoiced': 'Полностью проинвойсированно',
      'Is fully paid': 'Полностью оплачено',
      'Is gain': 'Это доход',
      'Is internal chain': 'Внутренняя цепочка',
      'Is liquid': 'Жидкость',
      'Is lost': 'Потеряно',
      'Is main': 'Главный',
      'Is main deal': 'Основная сделка',
      'Is passport cost': 'Затраты паспорта',
      'Is person verified?': 'Персона подтвержден?',
      'Is resident': 'Резидент',
      'Is virtual': 'Виртуальный',
      Issuance: 'Выставление',
      'Issuance date': 'Дата выставления',
      'Issuance fee': 'Комиссия за выдачу',
      'Issue invoice': 'Выставить инвойс',
      'It seems there are no autos/wagons yet': 'Пока нет транспортных средств',
      'It seems there are no entries': 'Пока нет записей',
      'It seems there are no invoice positions yet': 'Кажется, нет ни одной инвойс позиции',
      'It seems there are no month yet': 'Кажется, пока нет ни одного месяца',
      'It seems there are no positions yet': 'Кажется, нет ни одной позиции',
      "It's consisted from realized and open P&L. Both physical and derivative.":
        'Он состоял из реализуемых и открытых PnL.Оба физические и деривативы.',
      'Item was deleted': 'Объект удалён',
      January: 'Январь',
      July: 'Июль',
      June: 'Июнь',
      KPI: 'KPI',
      'KPI confirmations': 'KPI подтверждения',
      'KPI signings': 'KPI подписания',
      'KPI success': 'KPI успех',
      Kazachstan: 'Казахстан',
      LOADING: 'ОТГРУЗКА',
      'LOADING 2ND PORT': 'ОТГРУЗКА 2-ГО ПОРТА',
      'LOADING FINAL': 'ОТГРУЗКА ФИНАЛ',
      LOCATION: 'ГЕОГРАФИЯ',
      LOGISTICS: 'ЛОГИСТИКА',
      LOI: 'LOI',
      LONG: 'ЛОНГ',
      LOSSES: 'ПОТЕРИ',
      'LOYALITY PROGRAM': 'ПРОГРАММА ЛОЯЛЬНОСТИ',
      LTD: 'LTD',
      Language: 'Язык',
      'Last 30 Days': 'Посл. 30 дней',
      'Last 7 Days': 'Посл. 7 дней',
      'Last B/L date': 'Последняя дата коносамента',
      'Last Month': 'Прошедший месяц',
      'Last login': 'Последний вход',
      'Last market price': 'Последняя рыночная цена',
      'Last name': 'Фамилия',
      'Last name is required': 'Фамилия обязательна',
      'Last price': 'Последняя цена',
      'Last update': 'Последнее обновление',
      'Last updated within 1 day': 'Последнее обновление в течение 1 дня',
      Latitude: 'Широта',
      Laycan: 'Сталийное время',
      'Laycan (end)': 'Окончание сталийного времени',
      'Laycan (start)': 'Начало сталийного времени',
      'Laycan end date': 'Окончание сталийного времени',
      'Laycan start date': 'Начало сталийного времени',
      Leased: 'Арендованные',
      'Left to be shipped': 'Осталось отгрузить',
      'Left to be shipped volume': 'Осталось отгрузить объема',
      Legal: 'Юридическое',
      'Legal address': 'Юридический адрес',
      'Legal address Eng': 'Юридический адрес (англ)',
      Lender: 'Кредитор',
      'Less 0': 'Меньше 0',
      'Letter of Credit No.': 'Номер аккредитива No.',
      Libor: 'Libor',
      'License expiration date': 'Дата окончания Лицензии',
      Limit: 'Лимит',
      'Limit balance': 'Лимит баланса',
      'Limit fact': 'Лимит факт',
      'Limit plan': 'Лимит план',
      'Limit saved': 'Лимит сохранен',
      'Limit saved.': 'Лимит сохранен.',
      'Limit value': 'Граничное значение',
      Limits: 'Лимиты',
      'Line Up': 'Лайн-ап',
      'Line up': 'Лайн-ап',
      Lineup: 'Лайнап',
      'Link with companies': 'Связь с компанией',
      'Link with group': 'Связь с группой',
      'Linked invoice': 'Связанный инвойс',
      'List filters': 'Список фильтров',
      'List of actions in a dropdown menu': 'Список действий в выпадающем меню',
      'List of business units empty': 'Список бизнес юнитов пуст',
      Lists: 'Справочники',
      Load: 'Загрузка',
      'Load rate': 'Норма погрузки',
      'Load source': 'Источник загрузки',
      Loaded: 'Отгружено',
      'Loaded volume': 'Объем загруж',
      'Loaded/Discharged': 'Отгружено/Выгружено',
      Loading: 'Отгрузка',
      'Loading (date)': 'Отгрузка (дата)',
      'Loading / Destination': 'Загрузка / Назначение',
      'Loading 2nd port': 'Отгрузки 2-го порта',
      'Loading Port': 'Порт отгрузки',
      'Loading address': 'Адрес загрузки',
      'Loading addresses': 'Адреса загрузки',
      'Loading date': 'Дата отгрузки',
      'Loading date from': 'Дата отгрузки от',
      'Loading date to': 'Дата отгрузки до',
      'Loading date was changed': 'Дата загрузки была изменена',
      'Loading dem/dis': 'Отгрузка дем/дис',
      'Loading final port': 'Отгрузка конечного порта',
      'Loading minus': 'Отгрузка в минус',
      'Loading option': 'Опцион отгрузки',
      'Loading plus': 'Отгрузка в плюс',
      'Loading port': 'Порт отгрузки',
      'Loading related objects': 'Загружаем связанные объекты',
      'Loading shortage (boarding losses)': 'Недостаток загрузки (потери при погрузке)',
      'Loading/Destination': 'Отгрузка/Назначение',
      Loadport: 'Порт отгрузки',
      'Loads without buyer': 'Отгрузки без покупателей',
      Loan: 'Займ',
      'Loan agreement': 'Договор займа',
      'Loan amount': 'Сумма займа',
      'Loan number': 'Номер займа',
      'Loan saved': 'Займ сохранен',
      'Loan updated': 'Займ обновлен',
      Loans: 'Займы',
      Local: 'Местная',
      'Local currency': 'Локальная валюта',
      Location: 'География',
      'Log out': 'Выйти',
      Logistic: 'Логистика',
      'Logistic difference with invoice': 'Логистика разница с инвойсами',
      "Logistic doesn't exist": 'Логистика не существует',
      'Logistic loaded': 'Логистика отгружено',
      'Logistic loading; date': 'Логистика отгрузка дата',
      'Logistic purchase contract': 'Логистика закупочного контракта',
      'Logistic purchase contracts': 'Логистика закупочные контракты',
      'Logistic sale contracts': 'Логистика продажные контракты',
      'Logistic sale export contracts': 'Логистика продажные экспортные контракты',
      'Logistic season': 'Логистика сезон',
      'Logistic type': 'Тип логистики',
      'Logistic types': 'Типы логистики',
      'Logistic updated': 'Логистика обновлена',
      'Logistic volume': 'Объем логистики',
      Logistics: 'Логистика',
      'Logistics Analytics': 'Аналитика логистики',
      'Logistics cost': 'Затраты логистики',
      'Logistics costs VAT': 'Затраты логистики (НДС)',
      'Logistics have already connected to bill of lading.':
        'Логистика уже соединена с коносаментом.',
      'Logistics losses volume': 'Объем потерь по логистике',
      'Logistics mailing': 'Рассылка логистики',
      'Logistics updated!': 'Логистика обновлена!',
      'Logistics volume without CCD': 'Объем Логистики без CCD',
      Long: 'Лонг',
      'Long lots': 'Длинные лоты',
      Longitude: 'Долгота',
      Loses: 'Потери',
      Losses: 'Потери',
      'Losses volume': 'Объем потерь',
      Lost: 'Потеря',
      Lots: 'Лоты',
      Low: 'Низкий',
      'Loyalty Program': 'Программа лояльности',
      'Loyalty Programs': 'Программы лояльности',
      'Loyalty program': 'Программа лояльности',
      'Loyalty programs': 'Программа лояльности',
      'LoyaltyProgram saved.': 'Программа лояльности сохранена.',
      MAIN: 'ОСНОВНОЙ',
      'MAIN APPROVERS': 'СУПЕР ПОДТВЕРЖАЮЩИЕ',
      'MAIN DIMENSIONS': 'ОСНОВНЫЕ РАЗМЕРЫ',
      MAJORITY: 'БОЛЬШИНСТВО',
      MARGIN: 'МАРЖА',
      'MARGIN CONTROL': 'КОНТРОЛЬ МАРЖИ',
      'MARK TO MARKET': 'ОТНОШЕНИЕ К РЫНКУ',
      MATCHED: 'СООТВЕТСТВУЕТ',
      MONTH: 'МЕСЯЦ',
      'MONTH DIFF': 'МЕСЯЧНЫЙ DIFF',
      'MORE INFO': 'БОЛЬШЕ ИНФО',
      MOVEMENT: 'ПЕРЕДВИЖЕНИЕ',
      'MT Equivalent': 'Эквивалент тонн',
      MTM: 'Отношение к рынку',
      'MTM Inventory Valuation, $': 'МТМ оценка товарно-материальных запасов, $',
      'MTM P&L': 'MTM PnL',
      'MTM Price': 'МТМ цена',
      'MTM price': 'МТМ цена',
      'MTM price:': 'МТМ цена:',
      'MTM value': 'МТМ значение',
      'MTM, 1 MT/$': 'МТМ, 1 MT/$',
      'MULTISELECT FILTERS': 'МНОЖЕСТВЕННЫЕ ВЫБОР',
      'MULTISELECT OPTIONS': 'МУЛЬТИСЕЛЕКТ',
      Mail: 'Почта',
      'Mail preview': 'Превью письма',
      'Mailing Invoices': 'Рассылка инвойсы',
      'Mailing Logistics': 'Логистика для рассылки',
      'Mailing address': 'Адрес рассылки',
      Main: 'Главный',
      'Main company settings': 'Основные настройки компании',
      'Main contact': 'Основной контакт',
      'Main contact of company?': 'Главный контакт в компании?',
      'Main deal': 'Основная сделка',
      'Main option': 'Основная опция',
      'Main program': 'Основная програма',
      'Main ticket': 'Основной тикет',
      'Make reassignment': 'Создать переуступку',
      'Make sure you filled out': 'Убедитесь, что вы заполнили',
      'Make your choice': 'Сделайте свой выбор',
      'Make your choices': 'Сделайте выбор',
      Manage: 'Управлять',
      'Manage check-list': 'Управление чек-листом',
      'Manage costs/gains': 'Управление затраты/доходы',
      Manager: 'Менеджер',
      'Managing company': 'Управляющая компания',
      March: 'Март',
      Margin: 'Маржа',
      'Margin (Analytical - Contract)': 'Маржа (Аналитическая - Контрактная)',
      'Margin (Plan - Fact)': 'Маржа (План / Факт)',
      'Margin absolute': 'Маржа абсолютная',
      'Margin control': 'Контроль маржи',
      'Margin control ASC': 'Контроль маржи по возростанию',
      'Margin control DESC': 'Контроль маржи (описание)',
      'Margin control created': 'Контроль маржи создан',
      'Margin per 1t': 'Маржа за 1 т',
      'Margin relative': 'Маржа относительная',
      'Margin selector': 'Переключатель маржи',
      'Margin type from passports': 'Тип маржи из паспортов',
      'Margin with vat': 'Маржа с НДС',
      'Margin with vat return': 'Маржа с возвратом НДС',
      'Marine Cover Policy No.': 'Полис морского страхования No.',
      'Marine Traffic': 'Marine Traffic',
      'Marine traffic': 'Marine Traffic',
      Marinetraffic: 'Marine Traffic',
      'Mark to Market': 'Оценка к рынку',
      'Mark to market': 'Оценка к рынку',
      Market: 'Рынок',
      'Market price': 'Рыночная цена',
      'Market price (MTM)': 'Рыночная цена (МТМ)',
      'Market price deleted': 'Рыночная цена удалена',
      'Market price updated': 'Рыночная цена обновлена',
      'Market price:': 'Рыночная цена:',
      Marks: 'Метки',
      'Master data': 'Справочники',
      'Matched P&L': 'Подобранный PnL',
      'Matched Positions': 'Совпавшие позиции',
      'Matched objects:': 'Совпавшие объекты:',
      Maturity: 'Длительность',
      'Maturity date': 'Срок платежа',
      'Maturity date (plan)': 'Срок платежа (план)',
      Max: 'Макс',
      'Max alt': 'Макс альт',
      'Max amount': 'Максимальная сумма',
      'Max bl date': 'Последняя дата коносамента',
      'Max purchase price at': 'Максимальная закупочная цена для',
      'Maximum amount of one contract': 'Максимальная сумма по одному контракту',
      'Maximum quantity of one contract': 'Максимальный объем по одному контракту',
      May: 'Май',
      Measurement: 'Измерение',
      Medium: 'Средний',
      Meet: 'Встреча',
      Message: 'Сообщение',
      'Message Template': 'Шаблон сообщения',
      'Message type': 'Тип сообщения',
      'Middle Name': 'Отчетство',
      'Middle name': 'Отчетство',
      Min: 'Мин',
      'Min alt': 'Миним альт',
      'Min amount': 'Минимальная сумма',
      'Min payment delay': 'Минимальная задержка платежа',
      'Minimum batch of payment': 'Минимальная сумма платежа',
      'Minimum value is 0': 'Минимальное значение 0',
      Mixed: 'Смешанный',
      Mo: 'Mo',
      Model: 'Раздел',
      Monday: 'Понедельник',
      Month: 'Месяц',
      'Monthly plan': 'Месячный план',
      'More 90': 'Больше 90',
      Movement: 'Движение',
      'Movement recorder': 'Регистратор перемещений',
      Movements: 'Перемещения',
      'Mtm margin': 'Mтм маржа',
      Multi: 'Мульти',
      'Multi Contract document': 'Документы мультиконтракта',
      'Multi contracts': 'Мультиконтракты',
      'Multi tickets': 'Мультитикеты',
      MultiServices: 'МультиУслуги',
      MultiTicket: 'Мультитикет',
      Multicontract: 'Мультиконтракт',
      'Multicontract No.': 'Мультиконтракт No.',
      'Multicontract custom status': 'Пользовательские статусы мультиконтрактов',
      'Multicontract deleted.': 'Мультиконтракт удален.',
      "Multicontract doesn't exist": 'Мультиконтракт не существует',
      'Multicontract number': 'Номер мультиконтракта',
      'Multicontract passports': 'Паспорт мультиконтракта',
      'Multicontract saved.': 'Мультиконтракт сохранен.',
      'Multicontract voyages': 'Рейсы мультиконтракта',
      Multicontracts: 'Мультиконтракты',
      Multipassport: 'Мультипаспорт',
      'Multipassport contains several passports in itself.':
        'Мультипаспорт содержит в себе несколько паспортов.',
      'Multipassport saved.': 'Мультипаспорт сохранен.',
      Multipassports: 'Мультипаспорта',
      'Multipassports report': 'Мультипаспорта отчет',
      'Multiple choices': 'Множественный выбор',
      'Multiple connection': 'Множественное соединение',
      'Multiple contracts': 'Мультиконтракты',
      'Multiply amount with rate': 'Привести сумму к ставке',
      'Multirequest saved.': 'Мультитикет сохранен.',
      Multiticket: 'Мультитикет',
      Multitickets: 'Мультитикеты',
      Multitiket: 'Мультитикет',
      'Multi{[{ $ctrl.multicontract.stage }]}': 'Мульти{[{ $ctrl.multicontract.contract_type }]}',
      'Must include either a contract or a passport or a logistic, fill in at least one field.':
        'Необходимо включить либо контракт, либо паспорт, либо логистику, заполните хотя бы одно поле.',
      'My cabinet': 'Мой кабинет',
      NDF: 'NDF',
      NEW: 'НОВОЕ',
      'NEXT STEP': 'СЛЕДУЮЩИЙ ШАГ',
      NO: 'НЕТ',
      NUMBER: 'НОМЕР',
      Name: 'Имя',
      'Name of Buyer sale contract': 'Покупатель продажного контракта',
      'Name of seller purchase contract': 'Поставщик закупочного контракта',
      Navbar: 'Навбар',
      'Needs approval': 'Необходимо подтверждение',
      'Nested costs': 'Вложенные затраты',
      'Nested costs volume': 'Объем вложенных затрат',
      'Nested volume': 'Вложенный объем',
      'Net Closed': 'Чистая по закрытым',
      'Net P&L': 'Чистый P&L',
      'Net P&L MTM': 'Чистый P&L MTM',
      'Net buying price': 'Чистая цена покупки',
      'Net closed P&L': 'Закрытый PnL',
      'Net selling price': 'Чистая цена продажи',
      Neutral: 'Нейтральный',
      New: 'Новое',
      'New - after creation of disbursement bill of lading. Process - partly connected to reassignment. Done - fully connected to reassignment or connected to offset.':
        'Новое - после создания расходной накладной. В Процессе - частичное соединение с переуступкой. Выполнено - полное соединения к переуступке или зачету.',
      'New - after creation of reassignment. Process - reassignment partly connected to offset. Done - reassignment fully connected to offset.':
        'Новое - после создания переуступки. В процессе - переуступка частично связана с зачетом. Готово - переуступка полностью связана с зачетом.',
      'New Name': 'Новое Имя',
      'New Password': 'Новый пароль',
      'New Reservation': 'Новое бронирование',
      'New Stock Reserve Unit': 'Новый резервный блок',
      'New Warehouse': 'Новый склад',
      'New collection': 'Новая конфигурация',
      'New costs report': 'Новый отчет по затратам',
      'New derivative': 'Новый дериватив',
      'New destination port name': 'Новый порт назначения',
      'New loading port name': 'Новый порт погрузки',
      'New month': 'Новый месяц',
      'New multicontract': 'Новый мультиконтракт',
      'New passport': 'Новый паспорт',
      'New tariff': 'Новый тариф',
      'New task': 'Новое задание',
      'New template': 'Новый шаблон',
      'New title': 'Новое название',
      'New vessel name': 'Новое название судна',
      'New {[{ $root.user.settings.PASSPORT_TITLE }]}':
        'Новое {[{ $root.user.settings.PASSPORT_TITLE }]}',
      'News letters': 'Рассылки',
      NewsLetters: 'Рассылки',
      Newsletter: 'Рассылка',
      'Newsletter of invoices gives you a possibility to quickly form a letter with an\n                excel spreadsheet of all invoices (paid or not) and send it to a client or bank.\n                Also, you can attach all documents, related to the deal.':
        'Рассылка инвойсов дает возможность быстро сформировать письмо с таблицей Excel\n                всех инвойсов (оплаченных или нет) и отправить его контрагенту или банку.\n                Также можно приложить все документы, связанные с сделкой.',
      'Newsletter of logistics gives you a possibility to quickly form a letter with an\n                excel spreadsheet of all vehicles/containers and send it to a client or bank. Also,\n                you can attach full package documents, related to the deal.':
        'Рассылка логистики дает возможность быстро сформировать письмо с таблицей Excel\n                всех транспортных средств/контейнеров и отправить его контрагенту или банку.\n                Кроме того, вы можете прикрепить полный пакет документов, связанных со сделкой.',
      Newsletters: 'Рассылки',
      'Newsletters by invoices': 'Рассылки по инвойсам',
      'Newsletters by logistics': 'Рассылки по логистике',
      Next: 'Далее',
      'Next contact': 'Следующий контакт',
      'Next step': 'Следующий шаг',
      'Next steps': 'Следующие шаги',
      'Next update': 'Следующее обновление',
      No: 'Нет',
      'No additional agreements yet': 'Пока нет доп. соглашений',
      'No bank accounts yet': 'Банковских счетов пока нет',
      'No bill of ladings': 'Нет коносаментов',
      'No charges': 'Без затрат',
      'No contracts': 'Нет контрактов',
      'No currency exchange rate found': 'Курс обмена валют не найден',
      'No data yet': 'Нет данных',
      'No emails yet': 'Нет адресов',
      'No entries yet': 'Нет записей',
      'No logistic to update': 'Нет логистики для обновления',
      'No one logistic selected': 'Ни одна логистика не выбрана',
      'No open position': 'Нет открытой позиции',
      'No open position in passport': 'В паспорте нет открытой позиции',
      'No payments provided yet': 'Платежи еще не произведены',
      'No requests': 'Нет заявок',
      'No response': 'Нет ответа',
      'No sign': 'Нет подписи',
      'No similar entries yet': 'Нет похожих',
      'No templates yet': 'Пока нет шаблонов',
      'No, take me back': 'Нет, верните меня обратно',
      'Normal write-off volume, mt': 'Нормальный объем списания, т',
      'Not contacted': 'Нет контакта',
      'Not executed': 'Не выполнено',
      'Not fixed': 'Не зафиксированно',
      'Not fixed lots': 'Незафиксированные лоты',
      'Not fixed tonnes': 'Незафиксированные тонны',
      'Not hedged': 'Не захеджированно',
      'Not insured': 'Без страхования',
      'Not invoiced': 'Не выставлено счетов',
      'Not invoiced cargo': 'Непроинвойсированный груз',
      'Not paid': 'Не оплачено',
      'Not pay': 'Не платить',
      'Not saved field': 'Не сохраненное поле',
      'Not selected': 'Не выбрано',
      'Not signed': 'Не подписан',
      'Not used in P/L and totals calculations': 'Не используется в расчетах P/L и итогов',
      Note: 'Примеч',
      'Note one': 'Примечание один',
      'Note two': 'Примечание два',
      Notes: 'Примеч',
      'Notification date': 'Дата уведомления',
      'Notification time': 'Время уведомления',
      Notifications: 'Уведомления',
      Notified: 'Уведомлен',
      'Notify party': 'Уведомленная сторона',
      'Notify party 2': 'Уведомленная сторона 2',
      'Notify party 3': 'Уведомленная сторона 3',
      November: 'Ноябрь',
      Number: 'Номер',
      'Number (#)': 'Номер (№)',
      'Number of Purchase contract': 'Номер Закуп контракта',
      'Number of Sale contract': 'Номер Продаж контракта',
      'Number of containers': 'Кол-во контейнеров',
      'Number of contracts': 'Кол-во контрактов',
      'Number of days before notification': 'Количество дней до уведомления',
      'Number of days before notification about power of attorney expiration':
        'Количество дней до уведомления об истечении срока действия доверенности',
      'Number of days for payment': 'Количество дней для оплаты',
      'Number of days to issue an invoice': 'Количество дней до уведомления',
      OPEN: 'ОТКРЫТЬ',
      OPTIONS: 'ОПЦИИ',
      ORDER: 'ЗАКАЗ',
      OTHER: 'ДРУГОЕ',
      OUT: 'ВЫХОД',
      OUTGOING: 'ИСХОДЯЩИЕ',
      Object: 'Объект',
      'Object deleted': 'Объект удален',
      'Object has been added': 'Объект добавлен',
      'Object has been removed': 'Объект был удален',
      'Object name (type)': 'Имя объекта (тип)',
      'Object removed': 'Объект удален',
      'Object saved': 'Объект сохранен',
      'Object type': 'Тип объекта',
      'Object updated': 'Объект обновлён',
      October: 'Октябрь',
      Offer: 'Закупка',
      Offers: 'Закупки',
      Offset: 'Зачет',
      'Offset contracts': 'Офсетные контракты',
      'Offset date': 'Дата зачета',
      "Offset doesn't exist": 'Зачет не существует',
      'Offset list': 'Зачет список',
      'Offset number': 'Номер зачета',
      'Offset saved': 'Зачет сохранен',
      'Offset through reassignment date': 'Зачёт из переуступки',
      Offsets: 'Зачеты',
      'Old Cash Flow': 'Кеш-фло (старый отчет)',
      'On Road Volume': 'Объем в дороге',
      'On first event': 'По первому событию',
      'On road': 'В пути',
      'On road amount': 'Товар в пути (сумма)',
      'One full cargo': 'Весь объем',
      'One of the vessel voyages, which will execute this deal':
        'Один из рейсов судна, которое будет выполнять эту сделку',
      'Only complete transactions': 'Только завершенные транзакции',
      'Only my updates': 'Только мои',
      'Only users with permission secret_invoice will be able see this invoice':
        'Только пользователи со специальным разрешением могут увидеть этот инвойс',
      'Only volume': 'Только объем',
      Open: 'Открыто',
      'Open P&L': 'Открытый P&L',
      'Open Positions': 'Открытые позиции',
      'Open amount balance': 'Баланс открытой суммы',
      'Open balance': 'Открытый баланс',
      'Open balance (forward)': 'Открытый баланс (форвард)',
      'Open balance (spot)': 'Открытый баланс (спот)',
      'Open balance amount': 'Сумма открытого баланса',
      'Open balance of all contracts': 'Открытый баланс по всем контрактам',
      'Open contracts': 'Открытые контракты',
      'Open position': 'Открытая позиция',
      'Open profit and loss': 'Открытые прибыль и потери',
      'Open quote': 'Открытая котировка',
      'Open/closed': 'Открыто/закрыто',
      Operation: 'Операция',
      'Operation amount': 'Сумма операции',
      'Operation deleted': 'Операция удалена',
      'Operation saved': 'Операция сохранена',
      'Operation type': 'Тип операции',
      'Operational profit and loss': 'Операционная прибыль и потери',
      Operations: 'Операции',
      Opposite: 'Противоположный',
      'Opposite basis': 'Противоположный базис',
      'Opposite contract costs': 'Встречные затраты контракта',
      'Opposite costs': 'Встречные затраты',
      'Opposite counterparties': 'Противоположные контрагенты',
      'Opposite derivative': 'Встречный дериватив',
      'Opposite details': 'Противоположные детали',
      'Opposite freight': 'Встречный фрахт',
      'Opposite passport basis costs': 'Встречные затраты паспорта',
      'Opposite passport contract costs': 'Встречные затраты контракта паспорта',
      'Opposite price': 'Встречная цена',
      'Opposite value': 'Встречная цена',
      Option: 'Опция',
      Order: 'Порядок',
      'Order by': 'Сортировать по',
      'Order sale contract': 'Ордер продажных контрактов',
      Ordering: 'Заказ',
      Organization: 'Организация',
      Origin: 'Происхождение',
      Original: 'Оригинал',
      'Original available': 'Есть оригинал',
      'Originals received': 'Оригиналы получены',
      'Origins of crop': 'Происхождение культуры',
      Other: 'Другое',
      'Other Activity': 'Другое назначение',
      'Other activities': 'Другое назначение',
      'Other activity': 'Другое назначение',
      'Our Counterparty': 'Наш контрагент',
      'Our amount': 'Наша сумма',
      Out: 'Выход',
      'Out date': 'Дата отправки',
      'Out from country': 'Из страны',
      'Out number': 'Исходящий номер',
      'Out of approval': 'Вне согласования',
      Outgoing: 'Исходящие',
      'Outgoing (date)': 'Исходящий (дата)',
      'Outgoing Volume': 'Исходящий объем',
      'Outgoing balance': 'Исходящий баланс',
      'Outgoing invoice status': 'Статус исходящего инвойса',
      'Outgoing invoices': 'Исходящие инвойсы',
      'Outgoing payments': 'Исходящие платежи',
      'Outgoing reserve': 'Исходящий резерв',
      'Outgoing/Incoming': 'Исходящие/входящие',
      'Outstanding payment': 'Просрочена дата платежа',
      Overdue: 'Просроченный',
      Overpaid: 'Переплата',
      'Overpaid directions': 'Переплаченные направления',
      Overpaids: 'Переплаты',
      Own: 'Собственные',
      'Own costs': 'Собственные затраты',
      Owner: 'Владелец',
      'Owner bank': 'Банк владельца',
      'Owner bank account': 'Банковский счет владельца',
      'Owner equals Disponent': 'Владелец равен Распорядителю',
      'Owner info': 'Информация о владельце',
      'Owner name': 'Имя владельца',
      "Owner's bank account": 'Банковский счет владельца',
      Owners: 'Владельцы',
      'P&L': 'PnL',
      'P&L Consolidated': 'P&L консолидированный',
      'P&L MTM': 'P&L MTM',
      'P&L MTM (cts/bu)': 'P&L MTM (ц/буш)',
      'P&L MTM per one lot': 'P&L MTM за один лот',
      'P&L MTM, $': 'ПнЛ Оценка к рынку, $',
      'P&L books': 'P&L бухгалтерский',
      'P&L details': 'PnL детали',
      'P&L distribution': 'Распределение PnL',
      'P&L per passport': 'ПнЛ по паспорту',
      'P/L': 'ПнЛ',
      PAID: 'ОПЛАЧЕНО',
      'PASSPORT COSTS': 'ЗАТРАТЫ ПАСПОРТА',
      PASSPORTS: 'ПАСПОРТА',
      PAYMENT: 'ОПЛАТА',
      'PAYMENT CONDITIONS': 'УСЛОВИЯ ОПЛАТЫ',
      'PAYMENT ORDERS': 'ПЛАТЕЖНЫЕ ПОРУЧЕНИЯ',
      PAYMENTS: 'ПЛАТЕЖИ',
      PCD: 'PCD',
      'PCD execution': 'Исполнение',
      'PCD volume': 'Объем по PCD',
      PDF: 'ПДФ',
      'PICK DYNAMIC RANGE': 'ДИНАМИЧЕСКИЙ ДИАПАЗОН ВЫБОРА',
      PLAN: 'ПЛАН',
      PLANNED: 'ПЛАН',
      PNL: 'ПнЛ',
      'PNL report': 'ПнЛ отчет',
      'PO number': 'Номер закупочного заказа',
      POD: 'ПО',
      'POD under way': 'POD в пути',
      POL: 'ПВ',
      'POL under way': 'POL в пути',
      PORTS: 'ПОРТЫ',
      POSITIONS: 'ПОЗИЦИИ',
      'POTENTIAL BUYERS': 'ПОТЕНЦИАЛЬНЫЕ ПОКУПАТЕЛИ',
      'POTENTIAL SUPPLIERS': 'ПОТЕНЦИАЛЬНЫЕ ПОСТАВЩИКИ',
      PRICE: 'ЦЕНА',
      PRODUCT: 'ПРОДУКТ',
      PROPERTIES: 'ПОКАЗАТЕЛИ',
      PURCHASE: 'ЗАКУПКА',
      'PURCHASE ASC': 'PURCHASE ASC',
      'PURCHASE CONTRACTS': 'ЗАКУПОЧНЫЕ КОНТРАКТЫ',
      'PURCHASE COSTS': 'ЗАКУПКА ЗАТРАТЫ',
      'PURCHASE DESC': 'PURCHASE DESC',
      'PURCHASE PLANS': 'ПЛАНЫ ЗАКУПКИ',
      'PURCHASE TICKETS': 'ЗАКУПОЧНЫЕ ТИКЕТЫ',
      Packing: 'Упаковка',
      'Packing List': 'Товарная накладная',
      'Packing is too large number, less than 6 digits required (3 for integer, 3 for decimal).':
        'В упаковке слишком большое число нужно указать менее 6 цифр (3 для целого числа, 3 для десятичного).',
      'Page filter config': 'Конфигурация фильтра страницы',
      'Page scale': 'Масштаб страницы',
      'Page size': 'Размер страницы',
      'Page url': 'Ссылка страницы',
      Paid: 'Оплачено',
      'Paid (bal)': 'Оплач (бал)',
      'Paid Cargo': 'Оплаченный груз',
      'Paid amount': 'Сумма полученных оплат',
      'Paid date': 'Дата оплаты',
      'Paid volume': 'Оплаченный объем',
      'Paid volume (t)': 'Оплаченный объем (т)',
      Paper: 'Бумажная',
      'Paper trade': 'Бумажная торговля',
      Parameters: 'Параметры',
      Parent: '"Родительская" культура',
      'Parent analytic commodity': 'Родительская аналитическая культура',
      'Parent charge': 'Родительская затрата',
      'Parent commodity': 'Родительский товар',
      'Parent indicator price': 'Родительская Цена индикатора',
      'Parent reassignment': 'Источник переуступки',
      'Parse this quote': 'Спарсить эту котировку',
      Parsing: 'Парсинг',
      'Parsing config template removed': 'Шаблон парсинга котировок удалён',
      'Parsing enabled': 'Парсинг включен',
      'Partial executed': 'Выполнено частично',
      'Partial shipment allowed': 'Частичный объем',
      Passport: 'Паспорт',
      'Passport BU': 'Паспорт БЮ',
      'Passport P&L': 'Паспорт PnL',
      'Passport and/or contracts have different currencies':
        'Паспорта и/или контракты имеют разные валюты',
      'Passport aspiration': 'Аспирация паспорта',
      'Passport basis costs': 'Затраты паспорта',
      'Passport business date': 'Бизнес дата паспорта',
      'Passport business date month': 'Бизнес дата паспорта месяц',
      'Passport business date updated': 'Бизнес дата паспорта обновлена',
      'Passport business date year': 'Бизнес дата паспорта год',
      'Passport charge': 'Затраты паспорта',
      'Passport conclusion date': 'Дата заключения паспорта',
      'Passport contract': 'Паспорт контракта',
      'Passport contract costs': 'Затраты контракта паспорта',
      'Passport costs': 'Затраты паспорта',
      'Passport date': 'Бизнес дата паспорта',
      'Passport destinations': 'Назначение Паспорта',
      "Passport doesn't exist": 'Паспорт не существует',
      'Passport expiration date': 'Дата окончания срока действия паспорта',
      'Passport filled in  manually': 'Паспорт заполнен вручную',
      'Passport for connect': 'Паспорт для привязки',
      'Passport have no export contract or more then one':
        'Паспорт не имеет экспортного контракта или имеет более одного',
      'Passport have no sale contract or more then one':
        'У паспорта нет продажного контракта или их больше, чем один',
      'Passport info': 'Паспорт информация',
      'Passport operation': 'Операция паспорта',
      'Passport or contract date': 'Дата Паспорта или Контракта',
      'Passport quantity': 'Паспорт количество',
      'Passport removed': 'Паспорт убран',
      'Passport saved': 'Паспорт сохранен',
      'Passport status': 'Паспорт статус',
      'Passport status updated': 'Паспорт обновлен',
      'Passport title': 'Название паспорта',
      'Passport totals': 'Итоги паспорта',
      'Passport updated': 'Паспорт обновлен',
      'Passport updated.': 'Паспорт обновлен.',
      'Passport volume updated': 'Объем паспорта обновлен',
      'Passport. Completed if contract is attached to passports on full volume':
        'Паспорт. Завершено когда контракт полностью распределен по паспортам',
      Passport_connected: 'Паспорт связано',
      'Passport`s BU': 'БЮ Паспорта',
      Passports: 'Паспорта',
      'Passports (contract and direct)': 'Паспорта (контрактные и прямые)',
      'Passports Analytics': 'Аналитика паспортов',
      'Passports Profit and Loss': 'Паспорта P&L',
      'Passports costs': 'Затраты паспортов',
      'Passports created.': 'Паспорт создан.',
      'Passports position': 'Паспорт позиция',
      'Passports report': 'Отчет по паспортам',
      Password: 'Пароль',
      'Paste a hyperlink to DHL here to track the status of delivery':
        'Вставьте гиперссылку на DHL, чтобы отслеживать статус доставки',
      Pay: 'Оплатить',
      'Pay now': 'Оплатить сейчас',
      Payables: 'Кредиторка',
      Payment: 'Оплата',
      'Payment Bank name': 'Название банка платежа',
      'Payment Fact': 'Платеж факт',
      'Payment Info': 'Платеж инфо',
      'Payment Plan': 'План оплаты',
      'Payment Plans': 'Планы оплат',
      'Payment amount': 'Сумма оплат',
      'Payment bank account': 'Банковский счет платежа',
      'Payment bank name': 'Название банка платежа',
      'Payment condition': 'Условия оплаты',
      'Payment condition options': 'Опции условий оплаты',
      'Payment conditions': 'Условия платежа',
      'Payment conditions (%)': 'Условия платежа (%)',
      'Payment conditions (description)': 'Условия платежа (опция)',
      'Payment conditions auto (%)': 'Условия оплаты (авто)',
      'Payment conditions option': 'Опция условий оплаты',
      'Payment conditions options': 'Опции условий оплаты',
      'Payment confirmation': 'Подтверждение оплаты',
      'Payment date': 'Дата платежа',
      'Payment delays': 'Задержки платежей',
      'Payment events': 'События, связанные с платежами',
      'Payment info': 'Информация об оплате',
      'Payment info saved': 'Платежная информация сохранена',
      'Payment plan': 'План платежа',
      'Payment plan creation': 'Создание плана оплат',
      'Payment plan removed': 'План удален',
      'Payment plan saved': 'План сохранен',
      'Payment plans': 'Планы оплат',
      'Payment removed': 'Оплата удалена',
      'Payment saved': 'Оплата сохранена',
      'Payment status': 'Статус оплаты',
      'Payment statuses': 'Статусы платежей',
      'Payment stock (t)': 'Задолженность за отгрузку (т)',
      'Payment stock amount': 'Сумма оплат остаток',
      'Payment successfully created': 'Оплата успешна создана',
      'Payment terms': 'Сроки оплаты',
      'Payment updated': 'Платеж обновлен',
      Payments: 'Оплаты',
      'Payments currency exchange': 'Обменный курс оплат',
      'Payments margin': 'Маржа по платежам',
      'Payments plans': 'Планы оплат',
      'Payments were created': 'Оплаты созданы',
      Penalties: 'Штрафы',
      Pending: 'В процессе',
      'Pending confirmation': 'Ожидает подтверждения',
      'Per 1 t': 'За 1 т',
      'Per t:': 'За 1 т:',
      'Percent conditions': 'Процент условия',
      'Percent, %': 'Процент, %',
      Percentage: 'Процент',
      'Percentage, %': 'Процент, %',
      Period: 'Период',
      'Period of detalization': 'Период детализации',
      'Period of execution': 'Период выполнения',
      'Period totals data': 'Итоговые данные за период',
      'Periodic customs declaration': 'Периодическая таможенная декларация',
      'Periodic customs declarations': 'Периодическое таможенное декларирование',
      Permissions: 'Права',
      Person: 'Контакт',
      'Person - main contact of the company': 'Основной контакт компании',
      'Person removed': 'Контакт убран',
      'Person saved': 'Контакт сохранен',
      'Person signatory eng': 'Подписант (англ)',
      'Person updated': 'Контакт обновлен',
      Personal: 'Персональные',
      'Personal settings': 'Персональные настройки',
      Persons: 'Контакты',
      Phone: 'Телефон',
      'Phone (additional)': 'Телефон (дополнительный)',
      'Phone add': 'Телефон (доп)',
      'Phone additional': 'Телефон дополнительный',
      Physical: 'Физическая',
      'Physical + Derivatives margin': 'Физическая маржа и деривативы',
      'Physical MTM': 'Физическая оценка к рынку',
      'Physical margin': 'Физическая маржа',
      'Physical per T': 'Физический объем на тонну',
      'Physical position': 'Физическая позиция',
      'Physical position + MTM': 'Физическая позиция + МТМ',
      'Physical position by months': 'Физическая позиция по месяцам',
      'Pick a bank account': 'Выбрать банковский счет',
      'Pick a borrower that will be bound to this loan':
        'Выбрать заемщика который будет привязан к займу',
      'Pick a counterparty that will be bound to this invoice':
        'Выберите роль или контрагента для того, чтобы связать с инвойсом',
      'Pick a currency': 'Выбрать валюту',
      'Pick a date': 'Выберите дату',
      'Pick a deadline': 'Выберите дедлайн',
      'Pick a distributor that will be bound to this offset':
        'Выберите дистрибьютора который будет связан с этим зачетом',
      'Pick a distributor that will be bound to this reassignment':
        'Выберите дистрибьютора который будет связан с этой переуступкой',
      'Pick a lender that will be bound to this loan':
        'Выбрать кредитора который будет привязан к займу',
      'Pick a loan agreement': 'Выбрать договор займа',
      'Pick a time': 'Выберите время',
      'Pick a voyage to which this bill of lading is related too':
        'Выберите рейс, к которому также относится этот коносамент',
      'Pick accounts': 'Выберите счета',
      'Pick an owner that will be bound to this reassignment':
        'Выберите владельца который будет связан с этой переуступкой',
      'Pick currency in which you want to convert USD':
        'Выберите валюту, которую хотите конвертировать в USD',
      'Pick exchange rate if you want to convert invoice value to USD by certain rate':
        'Выберите обменный курс, если вы хотите конвертировать суму инвойса в USD по определенному курсу',
      'Pick from 0 to 100 to set up Daily fines rate':
        'Выберите от 0 до 100 для настройки дневной ставки штрафов',
      'Pick from 0 to 100 to set up interest rate':
        'Выберите от 0 до 100 для установления процентной ставки',
      'Pick from 0 to 100 to set up loan percentage':
        'Выберите от 0 до 100 для установления процента кредита',
      'Pick from 0 to 100 to set up prepay': 'От 0 до 100 чтобы задать предоплату',
      'Pick from 0 to 200': 'Выберите что-нибудь от 0 до 200',
      'Pick from 0 to 200 to increase or decrease the amount. Less than 100 - decreases, more than 100 - increases':
        'Выберите от 0 до 200 для увеличения или уменьшения суммы. Значения менее 100 уменьшает сумму, значения больше 100 увеличивает сумму',
      'Pick from 0 to 200, use this or discount amount':
        'Выберите от 0 до 200 или используйте значение дисконта',
      'Pick here a date, when you expect to have this charge':
        'Выберите здесь дату, когда вы ожидаете это начисление',
      'Pick here a date, when you got first contact with this company':
        'Выберите дату когда состоялся первый контакт с компанией',
      'Pick here a date, when you need to contact this company in future':
        'Выберите дату, когда необходимо в следующий раз связаться с этой компанией',
      'Pick one of the options to define the status of relations with the company':
        'Выберите одну из опций, чтобы определить статус ваших взаимоотношений с этой компанией',
      'Pick one of the options to define the status of the company':
        'Выберите одну из опций, чтобы определить статус ваших взаимоотношений с этой компанией',
      'Pick one of the options to define the type of the company':
        'Выберите один из вариантов, чтобы определить тип компании',
      'Pick the short name for counterparty. For convenience try to avoid naming the form of ownership, e.g. LLC  etc. You can appoint it in the full name. Good example for short name is COMPANY NAME without commas, etc. It would helpful you to find this company within search':
        'Выберите короткое название контгагента. Для удобства старайтесь избегать названий с формами собственности, например, ООО, и так далее. Вы можете указать это в Полном названии. Хороший пример короткого названия - НАЗВАНИЕ КОМПАНИИ без точек, скобок и запятых. Такие названия гораздо легче будет искать',
      Place: 'Точка отгрузки',
      'Place of destination': 'Место поставки',
      'Place of storage': 'Место хранения',
      Places: 'Места',
      Plan: 'План',
      'Plan VS contracted': 'План VS законтрактовано',
      'Plan VS delivered': 'План VS доставлено',
      'Plan amount': 'Сумма плана',
      'Plan costs': 'План затрат',
      'Plan created': 'План создан',
      "Plan doesn't exist": 'План не существует',
      'Plan execution range': 'Исполнение плана',
      'Plan payments': 'План оплат',
      'Plan removed': 'План удален',
      'Plan value': 'Плановый показатель',
      Planned: 'Запланировано',
      'Planned Expenses': 'Плановые расходы',
      'Planned balance (t)': 'Плановый баланс (т)',
      'Planned balance amount': 'Планируемая балансовая сумма',
      'Planned costs': 'Планируемые расходы',
      'Planned distributor': 'Плановый дистрибьютор',
      'Planned distributors': 'Плановые дистрибьюторы',
      Planning: 'Месплан',
      Plans: 'Планы',
      'Please select a contract': 'Пожалуйста, выберите контракт',
      'Please select a contract from the left': 'Пожалуйста, выберите контракт слева',
      'Please select at least one BL.': 'Пожалуйста, выберите минимум один коносамент.',
      'Please select at least one Disbursement Bill of Lading':
        'Пожалуйста, выберите как минимум одну расходную накладную',
      'Please select at least one invoice': 'Пожалуйста, выберите минимум один инвойс',
      'Please select at least one logistic':
        'Пожалуйста, выберите минимум одно транспортное средство',
      'Please select at least one object': 'Пожалуйста, выберите хотя бы один объект',
      'Please set date.': 'Укажите дату.',
      'Please, first apply filters for export data':
        'Пожалуйста, сначала примените фильтры для экспорта данных',
      'Please, first set period for get report data':
        'Пожалуйста, сначала установите период для получения отчетных данных',
      'Please, not the all the values related to contracts and internal objects are\n                    corrected according to the volume of allocation of certain contract.':
        'Пожалуйста, обратите внимание, что не все значения, связанные с контрактами и внутренними объектами, исправлены в соответствии с объемом распределения конкретного контракта.',
      'Please, select export contract': 'Пожалуйста, выберите контракт на экспорт',
      'Please, select purchase contract': 'Пожалуйста, выберите закупочный контракт',
      'Please, select purchase prepay invoice':
        'Пожалуйста, выберите предоплатный инвойс на закупку',
      'Please, select sale contract': 'Пожалуйста, выберите продажный контракт',
      'Please, select sale export contract filter':
        'Пожалуйста, выберите продажных экспортный контракт',
      'Please, select sale prepay invoice': 'Пожалуйста, выберите предоплатный инвойс на продажу',
      Pledge: 'Залог',
      'Pmt date': 'Дата платежа',
      PnL: 'ПнЛ',
      Port: 'Порт',
      'Port of Discharge': 'Порт выгрузки',
      'Port of Loading': 'Порт отгрузки',
      'Port of destination': 'Порт назначения',
      'Port of discharge': 'Порт выгрузки',
      'Port of loading': 'Порт отгрузки',
      Ports: 'Порты',
      'Ports list': 'Список портов',
      'Ports list eng': 'Список портов англ',
      'Ports list short name': 'Список портов короткие назв',
      'Ports opposite': 'Порты встречные',
      Position: 'Должность',
      'Position + MTM': 'Позиция + МТМ',
      'Position P&L + MTM': 'Позиция PnL + МТМ',
      'Position conclusion date': 'Дата заключения позиции',
      'Position documents': 'Документы позиции',
      'Position excluded': 'Позиция исключена',
      'Position passport business date': 'Бизнес-дата паспорта позиции',
      'Position payment status': 'Статус платежа по позиции',
      'Position saved': 'Позиция сохранена',
      'Position updated': 'Позиция обновлена',
      Positions: 'Позиции',
      'Positions info': 'Информация о позициях',
      'Positions signature': 'Подпись позиции',
      'Positon of person who signs documents by this company':
        'Позиция лица, которое подписывает документы этой компании',
      Potencial: 'Потенциальный',
      Potential: 'Потенциал',
      'Potential crops': 'Потенциальные товары',
      'Potential purchase': 'Потенциальная закупка',
      Potentials: 'Потенциалы',
      'Predictions order': 'Порядок заказов',
      'Preliminary loaded': 'Предварительно загруженный',
      Premium: 'Премиум',
      'Premium balance': 'Премиум баланс',
      'Premium currency': 'Премиальная валюта',
      'Premium for delivered goods, Plan': 'Премия за доставленные товары, план',
      'Premium price': 'Премиум цена',
      'Premium price opposite': 'Премиум цена встречная',
      'Premium value': 'Премия',
      Prepay: 'Предоплата',
      'Prepay - amount of money, will be paid by contract, before final delivery,  Balance of money, will be paid after end of delivery':
        'Предоплата - сумма, которая будет выплачена до окончания поставки. Баланс - сумма, которая выплачена после окончания поставки',
      'Prepay - balance percent': 'Предоплата - баланс процент',
      'Prepay invoice': 'Предоплата инвойс',
      'Prepay payment': 'Предоплата платежа',
      'Prepay payment dates': 'Предоплата даты оплаты',
      'Prepay volume': 'Предоплата объем',
      'Prepayment invoice': 'Счет предоплаты',
      'Preset Set Saved': 'Шаблон сохранен',
      'Preset Set Updated': 'Шаблон обновлен',
      'Preset Set removed': 'Шаблон удален',
      Preshipment: 'До отправки',
      'Press to control': 'Нажмите контроль',
      'Press to show more': 'Загрузить больше',
      'Press ↵ ENTER to': 'Нажмите ↵ ENTER',
      Preview: 'Предварительный просмотр',
      'Previous Price': 'Предыдущая Цена',
      Price: 'Цена',
      'Price (with costs, with VAT)': 'Цена (с расходами, с НДС)',
      'Price (with costs, without VAT)': 'Цена (с расходами, без НДС)',
      'Price (with costs, without VAT), $': 'Цена (с расходами, без НДС), $',
      'Price / t': 'Цена/т',
      'Price 1t': 'Цена 1т',
      'Price VAT': 'Цена c НДС',
      'Price create': 'Цена создана',
      'Price currency': 'Цена валюты',
      'Price diff': 'Разница в цене',
      'Price discount': 'Цена со скидкой',
      'Price estimated:': 'Прогнозированная цена:',
      'Price for measurement': 'Цена на измерение',
      'Price for measurement / kg': 'Цена за измерение/кг',
      'Price in USD': 'Цена в USD',
      'Price in USD words': 'Цена, USD прописью',
      'Price indicative': 'Цена ориентировочная',
      'Price no VAT': 'Цена без НДС',
      'Price of premium': 'Премиум цена',
      'Price opposite': 'Цена закупки\\продажи',
      'Price options': 'Опции цен',
      'Price per one': 'Цена за единицу',
      'Price per piece': 'Цена за шт',
      'Price per t': 'Цена за тонну',
      'Price premium': 'Премиум цена',
      'Price premium opposite': 'Премиум цена встречная',
      'Price with VAT': 'Цена с НДС',
      'Price with discount': 'Цена со скидкой',
      'Price without VAT': 'Цена без НДС',
      'Price without Vat': 'Цена без НДС',
      Prices: 'Цены',
      Print: 'Печать',
      'Print a contract': 'Распечатать контракт',
      'Print a document': 'Сгенерировать документ',
      'Print an invoice': 'Распечатать инвойс',
      'Print and sign': 'Распечатать и подписать',
      Priority: 'Приоритет',
      Process: 'Процесс',
      Processed: 'В процессе',
      Processing: 'Процесс',
      'Processing...': 'В процессе...',
      Producer: 'Производитель',
      Product: 'Товар',
      'Production Year': 'Год производства',
      Products: 'Продукты',
      Profile: 'Профиль',
      Profit: 'Прибыль',
      'Profit and Loss': 'Прибыль и потери',
      'Profit and loss': 'Прибыль и потери',
      Profitability: 'Прибыльность',
      Proforma: 'Проформа',
      'Proforma Invoice': 'Проформа инвойс',
      'Program type': 'Тип программы',
      Progress: 'Прогресс',
      'Project cost, $ with VAT': 'Стоимость проекта, дол с НДС',
      'Project cost, with VAT': 'Стоимость проекта, с НДС',
      'Project status': 'Статус проекта',
      'Projected rate': 'Ожидаемая ставка',
      'Projects Status Detailed Summary': 'Статус проектов детальная сводка',
      Properties: 'Свойства',
      Property: 'Показатель',
      'Property "positions volume by execution" is disabled.':
        'Свойство "Объем позиций по исполнению" отключено.',
      'Property alternative': 'Свойство альтернативное',
      Provider: 'Поставщик',
      Purcahse: 'Закупка',
      Purchase: 'Закупка',
      'Purchase Contract': 'Закупочный контракт',
      'Purchase Contract #': 'Закупочный Контракт №',
      'Purchase Currencies': 'Закупка валюты',
      'Purchase KPI': 'Закупка KPI',
      'Purchase Plan': 'План закупки',
      'Purchase amount': 'Сумма закупки',
      'Purchase author': 'Закупка автор',
      'Purchase business unit': 'Бизнес юнит закупки',
      'Purchase contract': 'Закупочный контракт',
      'Purchase contract #': 'Закупочный контракт №',
      'Purchase contract author': 'Закупочный контракт автор',
      'Purchase contract client': 'Контрагент закупочного контрака',
      'Purchase contract deal type': 'Тип закупочного контракта',
      'Purchase contract owner': 'Владелец закупочного контракта',
      'Purchase contract passports': 'Паспорт закупочного контракта',
      'Purchase contract payment conditions option': 'Опция условий оплаты закупочного контракта',
      'Purchase contract status': 'Статус закупочного контракта',
      'Purchase contract statuses': 'Статусы закупочных контрактов',
      'Purchase contract supplier company code': 'Код компании поставщика контракта на закупку',
      'Purchase contracts': 'Закупочные контракты',
      'Purchase init price': 'Исходная цена закупки',
      'Purchase loyalty program': 'Закупка программа лояльности',
      'Purchase multicontract': 'Закупочный мультиконтракт',
      'Purchase multiticket': 'Закупочный мультитикет',
      'Purchase plan': 'План закупки',
      'Purchase plan report': 'План закупки отчёт',
      'Purchase price': 'Цена закупки',
      'Purchase price differs': 'Цена закупки разная',
      'Purchase price local': 'Закупочная цена локальная',
      'Purchase price wit VAT': 'Цена закупка с НДС',
      'Purchase price wit VAT on Elevator': 'Цена закупка с НДС на Элеваторе',
      'Purchase price without VAT': 'Цена закупка без НДС',
      'Purchase responsible': 'Ответственный закупка',
      'Purchase responsible for signing': 'Закупка ответственный за подписание',
      'Purchase sale manager': 'Закупка менеджер продажи',
      'Purchase status': 'Статус закупки',
      'Purchase this': 'Купить это',
      'Purchase ticket': 'Закупочный тикет',
      'Purchase to execute': 'Закупка для исполнения',
      'Purchase to invoice': 'Инвойс закупки',
      'Purchase to pay': 'Закупка к оплате',
      'Purchase totals': 'Всего по закупке',
      'Purchase value local': 'Стоимость закупки локальная валюта',
      PurchaseContract: 'Закупочный контракт',
      Purchases: 'Закупки',
      'Purchases (Analytical - Contract)': 'Закупки (Аналитика - Контракт)',
      'Purchases (Plan - Fact)': 'Закупки (План - Факт)',
      Purpose: 'Цель',
      QTY: 'КОЛ-ВО',
      QUARTER: 'КВАРТАЛ',
      'QUARTER DIFF': 'ЧЕТВЕРТЬ РАЗНИЦЫ',
      Qualities: 'Качества',
      Quality: 'Качество',
      'Quality Rules': 'Правила качества',
      'Quality date': 'Дата качества',
      'Quality deleted': 'Качество удалено',
      'Quality eng': 'Качество англ',
      'Quality list': 'Список качеств',
      'Quality list eng': 'Список качеств англ',
      'Quality required': 'Качеств обяз',
      'Quality rule Config': 'Конфигурация правил качества',
      'Quality rule templates': 'Шаблоны дисконтов качества',
      'Quality rules': 'Правила качеств',
      'Quality rules are not included': 'Правила качества не включены',
      'Quantitative metrics': 'Количественные метрики',
      Quantity: 'Количество',
      'Quantity = Volume / Packing size': 'Количество = Объем / Размер упаковки',
      'Quantity Total': 'Кол-во общее',
      'Quantity Total Amount': 'Общая сумма кол-ва',
      'Quantity Total Amount without VAT': 'Общая сумма количества без НДС',
      'Quantity Total VAT Amount': 'Общая сумма количества с НДС',
      'Quantity fact': 'Факт количества',
      'Quantity of measurement': 'Количество единицы измерения',
      'Quantity of wagons by contract': 'Количество вагонов по контракту',
      'Quantity total': 'Кол-во общее',
      'Quantity total amount': 'Кол-во общая сумма',
      Quarantine: 'Карантин',
      'Quarantine required': 'Карантин обязателен',
      'Quick add': 'Быстро добавить',
      'Quick edit': 'Быстрое редактирование',
      Quit: 'Выйти',
      Quotations: 'Предложения',
      'Quote Parsing Config': 'Конфигурация парсинга котировок',
      'Quote Parsing Config saved': 'Конфигурация парсинга котировок сохранена',
      'Quotes updated.': 'Котировки обновлены.',
      'RATES & COSTS': 'СТАВКИ и ЗАТРАТЫ',
      REALIZED: 'РЕАЛИЗИРОВАН',
      REASSIGNMENTS: 'ПЕРЕУСТУПКИ',
      'RECENTLY UPDATED': 'ПОСЛЕДНЕЕ ОБНОВЛЕНИЕ',
      RECIPIENTS: 'ПОЛУЧАТЕЛИ',
      REPORT: 'ОТЧЕТ',
      RESULT: 'РЕЗУЛЬТАТ',
      ROE: 'ROE',
      ROS: 'ROS',
      'RW payment conditions': 'Условия оплаты (жд)',
      'RW prepay - balance percent': 'ЖД предоплата - баланс %',
      Railway: 'Ж/д',
      'Railway cost': 'ЖД затраты',
      'Railway enterance': 'Ж\\д внесение',
      'Railway losts': 'ЖД потери',
      'Railway prepay - balance percent': 'ЖД предоплата - процент баланса',
      'Railway required': 'Ж/д обяз',
      Range: 'Диапазон',
      Rate: 'Ставка',
      Rates: 'Ставки',
      'Re assignment': 'Переназначение',
      'Re-approval': 'Повтор подтв',
      'Re-approve': 'Повторное подтверждение',
      Reactivate: 'Активировать повторно',
      'Reactivate approving': 'Активировать повторно',
      'Read all': 'Читать все',
      Ready: 'Готово',
      'Real stocks': 'Реальные остатки',
      Realized: 'Реализов',
      'Realized + Open': 'Реализовано + Открыто',
      'Realized profit and loss': 'Реализованные прибыль и потери',
      'Realized profit and loss per 1': 'Реализованный PnL за 1т',
      'Realized quantity': 'Реализованный объем',
      'Reason for rejection': 'Причина отказа',
      Reassignment: 'Переназначение',
      'Reassignment amount': 'Переуступка сумма',
      'Reassignment date': 'Переуступка дата',
      "Reassignment doesn't exist": 'Переуступки не существует',
      'Reassignment list': 'Список переуступок',
      'Reassignment number': 'Переуступка номер',
      'Reassignment positions': 'Переуступка позиции',
      'Reassignment rate': 'Переуступка ставка',
      'Reassignment saved': 'Переуступка сохранена',
      'Reassignment status': 'Переуступка статус',
      'Reassignment value': 'Переуступки сумма',
      Reassignments: 'Переназначения',
      'Recalculate invoice': 'Пересчитать счет',
      Recall: 'Перезвонить',
      Recap: 'Резюме',
      Receipt: 'Квитанция',
      Receivables: 'Доходы',
      Received: 'Получ',
      'Received (date)': 'Получено (дата)',
      'Received date': 'Дата получения',
      'Received registry (port)': 'Регистр получен (порт)',
      'Received value': 'Получено значение',
      'Received volume': 'Полученный объем',
      Receiver: 'Получатель',
      Receiving: 'Получение',
      'Receiving (date)': 'Получение (дата)',
      'Receiving date': 'Дата получения',
      'Receiving date from': 'Дата получения от',
      'Receiving date to': 'Дата получения до',
      'Receiving date was changed': 'Дата получения была изменена',
      'Recent confirmations': 'Последние подтверждения',
      'Recieve station': 'Станция получения',
      'Recipient type': 'Тип получателя',
      Recipients: 'Получатели',
      Reconciliation: 'Сверка',
      'Reconciliation by invoice': 'Сверка по инвойсу',
      'Reconciliation by logistics': 'Сверка по логистике',
      'Record type': 'Тип записи',
      Recreate: 'Пересоздать',
      Red: 'Красное',
      Region: 'Область',
      Regions: 'Области',
      'Register stocks': 'Зарегистрированные остатки',
      'Registered since': 'Регистрация от',
      Registry: 'Реестр',
      'Registry received (forwarder)': 'Регистр получен (экспедитор)',
      'Registry sent (forwarder)': 'Регистр отправлен (перевозчик)',
      'Registry sent (port)': 'Регистр отправлен (порт)',
      Regular: 'Обычный',
      Rejected: 'Отклонено',
      'Related companies': 'Связанные компании',
      'Related counterparties': 'Связанные контрагенты',
      'Related counterparty': 'Связанный контрагент',
      'Related counterparty from': 'Связанный контрагент от кого',
      'Related counterparty to': 'Связанный контрагент кому',
      'Related deal': 'Связанная сделка',
      'Related fees': 'Связанные комиссии',
      'Related invoice': 'Связанный инвойс',
      'Related invoice number': 'Номер связанного инвойса',
      'Related owner': 'Связанный владелец',
      Relation: 'Связь',
      Relations: 'Связи',
      Remains: 'Остатки',
      'Remind me before': 'Напомнить мне до',
      Remove: 'Удалить',
      'Renewal date': 'Дата продления',
      Rentability: 'Рентабельность',
      Replace: 'Заменить',
      Report: 'Отчет',
      'Report config created': 'Конфигурация отчета создана',
      'Report config deleted': 'Конфигурация отчета удалена',
      'Report config updated': 'Конфигурация отчета обновлена',
      'Report grouping': 'Группировка отчетов',
      'Report type': 'Тип отчета',
      Reports: 'Отчеты',
      Request: 'Заявка',
      'Request (date)': 'Дата запроса доков',
      'Request date': 'Дата заявки',
      'Request removed': 'Заявка удалена',
      'Request saved': 'Тикет сохранен',
      'Request stage': 'Этап запроса',
      'Request updated': 'Запрос обновлен',
      Requested: 'По запросу',
      'Requested to update on:': 'Запрашивается обновление по:',
      Required: 'Обязательно',
      'Rerouting addresses': 'Перенаправление адресов',
      Reservation: 'Бронирование',
      Reservations: 'Резервации',
      Reserve: 'Резерв',
      'Reserve Client': 'Резервный контрагент',
      'Reserve Contract': 'Резервный контракт',
      'Reserve Passport': 'Резервный паспорт',
      'Reserve Passport Vessel': 'Резервное судно паспорта',
      'Reserve Passport Voyage': 'Резервный рейс паспорта',
      'Reserve Vessel': 'Резервное судно',
      'Reserve Voyage': 'Резервный рейс',
      'Reserve available': 'Резерв доступен',
      'Reserve charge': 'Резервирование платы',
      'Reserve used': 'Резерв использован',
      'Reserved Volume': 'Зарезервированный объем',
      Reset: 'Перезагрузить',
      'Reset values': 'Сбросить значения',
      'Residual volume': 'Остаточный объем',
      Resource: 'Ресурс',
      Resources: 'Ресурсы',
      Response: 'Ответ',
      Responsibilities: 'Ответственности',
      Responsibility: 'Ответственность',
      Responsible: 'Ответственный',
      'Responsible for signing': 'Ответственный за подписание',
      'Responsible users': 'Ответственные пользователи',
      Result: 'Результат',
      'Result date': 'Результат дата',
      'Result status': 'Статус результата',
      'Reverse order': 'Обратный порядок',
      Risk: 'Риск',
      'Risk position terminal': 'Риск позиция терминал',
      Role: 'Роль',
      'Role specific': 'Специфичный для роли',
      Roles: 'Роли',
      'Roles, in which this Counterparty will be presented in system.You can select multiple roles, pressing CTRL (CMD)':
        'Роли, в которых этот Контрагент будет представлен в системе. Вы можете выбрать несколько ролей, нажав CTRL (CMD)',
      Rolling: 'Подвижной',
      'Rolling stock': 'Rolling состав',
      Russia: 'Россия',
      Russian: 'Русский',
      SALE: 'ПРОДАЖА',
      'SALE ASC': 'SALE ASC',
      'SALE CONTRACTS': 'ПРОДАЖНЫЕ КОНТРАКТЫ',
      'SALE COSTS': 'ПРОДАЖА ЗАТРАТЫ',
      'SALE DESC': 'SALE DESC',
      'SALE TICKETS': 'ПРОДАЖНЫЕ ТИКЕТЫ',
      'SAP Order Purchase Contract': 'SAP заказ закупочный контракт',
      'SAP number': 'SAP номер',
      'SAP order numbers': 'SAP номера заказов',
      'SAP orders': 'SAP заказы',
      'SAP vendor number': 'SAP номер контрагента',
      SAVE: 'СОХРАНИТЬ',
      'SEARCH FILTERS': 'ПОИСК ФИЛЬТРОВ',
      SELLER: 'ПРОДАВЕЦ',
      SERVICES: 'УСЛУГИ',
      SHIPMENT: 'ОТГРУЗКА',
      'SHIPMENT END': 'КОНЕЦ ОТГРУЗКИ',
      'SHIPMENT START': 'НАЧАЛО ОТГРУЗКИ',
      SHORT: 'ШОРТ',
      SI: 'ПосИнв',
      SIDES: 'СТОРОНЫ',
      SIGNATURE: 'ПОДПИСЬ',
      'SIGNING DETAILS': 'ПОДРОБНОСТИ ПОДПИСАНИЯ',
      SP: 'ПосОпл',
      SPECIAL: 'ОСОБЕННОЕ',
      STATUS: 'СТАТУС',
      STEP: 'ШАГ',
      SUBSCRIBE: 'ПОДПИСАТЬСЯ',
      SUPPLIER: 'ПОСТАВЩИК',
      'SUPPLIER CONFIRMATION': 'ПОСТАВЩИК ПОДТВЕРЖДЕНИЕ',
      SWIFT: 'SWIFT',
      'SYSTEM NOTIFICATION': 'УВЕДОМЛЕНИЯ',
      'SYSTEM USERS': 'СИСТЕМНЫЕ ПОЛЬЗОВАТЕЛИ',
      Sa: 'Сб',
      Sale: 'Продажа',
      'Sale (date)': 'Продажа (дата)',
      'Sale Contract': 'Продажный контракт',
      'Sale Contract #': 'Продажный контракт #',
      'Sale Contract Position': 'Позиция Продажного Контракта',
      'Sale Currencies': 'Продажа валюты',
      'Sale KPI': 'Продажа KPI',
      'Sale Price': 'Цена продажи',
      'Sale business unit': 'Продажный бизнес-юнит',
      'Sale contract': 'Продажный контракт',
      'Sale contract deal type': 'Тип продажного контракта',
      'Sale contract passports': 'Паспорт продажного контракта',
      'Sale contract status': 'Статус продажного контракта',
      'Sale contract statuses': 'Статусы продажных контрактов',
      'Sale contract volume': 'Объем продажи по контракту',
      'Sale contracts': 'Продажные контракты',
      'Sale discount more then purchase': 'Дисконт на продажу больший, чем на закупку',
      'Sale discount value': 'Сумма дисконта на продажу',
      'Sale end execution': 'Продажа конец исполнения',
      'Sale init price': 'Исходная цена продажи',
      'Sale invoice date': 'Продажный инвойс дата',
      'Sale logistic volumes': 'Объемы логистики продаж',
      'Sale multicontract': 'Продажный мультиконтракт',
      'Sale multiticket': 'Продажный мультитикет',
      'Sale or Purchase': 'Продажа или Закупка',
      'Sale payment date': 'Дата оплаты продажа',
      'Sale plan report': 'План продаж',
      'Sale price': 'Цена продажи',
      'Sale price (incl. VAT)': 'Цена продажи (вкл. НДС)',
      'Sale price differs': 'Цена продажи разная',
      'Sale price discount': 'Дисконт на цену продажи',
      'Sale price local': 'Продажная цена локальная',
      'Sale start execution': 'Продажа начало исполнения',
      'Sale status': 'Статус продажи',
      'Sale this': 'Продать это',
      'Sale ticket': 'Продажный тикет',
      'Sale to execute': 'Продажа для исполнения',
      'Sale to invoice': 'Продажа по инвойсу',
      'Sale to pay': 'Продажа до оплаты',
      'Sale total value': 'Общая сумма продажи',
      'Sale total value discounted': 'Общая сумма продажи с учетом дисконта',
      'Sale totals': 'Продажа тоталы',
      'Sale volume': 'Продажный объем',
      SaleContract: 'Продажный контракт',
      Sales: 'Продажи',
      'Sales (Plan - Fact)': 'Продажи (план - факт)',
      'Sales amount': 'Сумма продажи',
      'Sales execution': 'Выполнение продажи',
      'Sales managers': 'Региональные представители',
      'Sales payment conditions': 'Условия оплаты продажного контракта',
      'Sales price': 'Цена продажи',
      'Sample Control': 'Контроль образцов',
      'Sample In date': 'Дата поступления образца',
      'Sample Out date': 'Образцы исходящая дата',
      'Sample received date': 'Образец дата получения',
      'Sample result date': 'Образец дата результата',
      'Sample type': 'Тип образца',
      Samples: 'Образцы',
      'Sampling Condition': 'Условия отбора образцов',
      Sanctions: 'Санкции',
      'Sap Order': 'SAP номер заказа',
      'Sap Orders': 'SAP заказы',
      'Sap orders': 'Заказы SAP',
      Saturday: 'Суббота',
      Save: 'Сохранить',
      'Save & continue': 'Сохранить & продолжить',
      'Save all': 'Сохранить все',
      'Save costs': 'Сохранить затраты',
      'Save current filter': 'Сохранить текущий фильтр',
      'Save filers as a preset': 'Сохранить набор фильтров как шаблон',
      'Save without diff': 'Сохранить без изменений',
      Saved: 'Сохранено',
      'Screenshots configs': 'Конфигурации скриншотов',
      Seal: 'Пломба',
      Search: 'Поиск',
      'Search and press ↵ ENTER': 'Введите и нажмите ↵ ENTER',
      'Search in database': 'Поиск в базе',
      'Search permissions': 'Искать полномочия',
      Season: 'Сезон',
      'Season year': 'Сезон год',
      Seasons: 'Сезоны',
      'Second (validation)': 'Второй (подтверждение)',
      'Second Name': 'Фамилия',
      'Second name': 'Фамилия',
      'Secret invoice': 'Секретный инвойс',
      Section: 'Раздел',
      'See all': 'Смотреть все',
      Seeds: 'Посевы',
      Select: 'Выбор',
      'Select .XLS template': 'Выберите шаблон . XLS',
      'Select All': 'Выбрать все',
      'Select All {[{$ctrl.mode}]} on the page': 'Выбрать все {[{$ctrl.mode}]} на странице',
      'Select Business Unit': 'Выбрать Бизнес юнит',
      'Select Email Template': 'Выберите шаблон Email',
      'Select Passport for costs': 'Выберите паспорт для затрат',
      'Select User to be responsible': 'Выберите ответственного пользователя',
      'Select a collection': 'Выбрать коллекцию',
      'Select a document': 'Выбрать документ',
      'Select a passport': 'Выбрать паспорт',
      'Select a responsible': 'Выберите ответственного',
      'Select a status': 'Выберите статус',
      'Select a tags': 'Выбрать теги',
      'Select all': 'Выбрать все',
      'Select all documents': 'Выбрать все документы',
      'Select all transport': 'Выбрать весь транспорт',
      'Select any of item': 'Выберите любой элемент',
      'Select business unit': 'Выбрать Бизнес юниты',
      'Select by which contract property you want to group P&L. Default grouping\n                          can be set in Settings.':
        'Выберите, по какому свойству контракта вы хотите группировать P&L. Стандартная группировка может быть установлена в настройках.',
      'Select cargo': 'Выберите груз',
      'Select cargo receiver': 'Выберите грузополучателя',
      'Select cargo shipper': 'Выберите грузоотправителя',
      'Select charges and press confirm': 'Выберите затраты и нажмите подтвердить',
      'Select commodity of passport': 'Выбрать товар паспорта',
      'Select consignment': 'Выберите накладные',
      'Select consignments to create costs': 'Выберите накладные для создания затрат',
      'Select contracts for passport': 'Выбрать контракты для паспорта',
      'Select date': 'Выберите дату',
      'Select departure station': 'Выберите станцию отправления',
      'Select elevator': 'Выберите элеватор',
      'Select invoices to send': 'Выберите инвойсы для отправки',
      'Select logistics': 'Выберите логистику',
      'Select or drag and drop files': 'Выберите или перетащите файл',
      'Select property': 'Выберите значение',
      'Select purchase contract': 'Выбрать закупочный контракт',
      'Select responsible': 'Выберите ответственного',
      'Select sale contract': 'Выберите продажный контракт',
      'Select station destination': 'Выберите станцию назначения',
      'Select status': 'Выберите статус',
      'Select terminal': 'Выберите терминал',
      'Select the charge': 'Выберите затрату',
      'Select the contract': 'Выберите контракт',
      'Select the contract charge': 'Выберите затрату контракта',
      'Select the logistic': 'Выберите логистику',
      'Select the other activity': 'Выбрать другую деятельность',
      'Select the passport': 'Выберите паспорт',
      'Select the product': 'Выберите продукт',
      'Select to': 'Выбрать',
      'Select to Approve': 'Выбрать для подтверждения',
      'Select to Pay': 'Выбрать для оплаты',
      'Select to Payment Plan': 'Запланировать',
      'Select transport': 'Выбрать транспорт',
      'Select transport to send': 'Выберите транспорт для отправки',
      'Select which margin type you want to display from passports. E.g. contract\n                          - only plan. If mixed - we will show the appropriate margin per each\n                          passport as per MARGIN SELECTOR setting.':
        'Выберите, какой тип поля вы хотите отобразить из паспортов. Например, контракт\n                          - только план. Если смешанный - мы покажем соответствующее поле для каждого\n                          паспорта в соответствии с настройкой ВЫБОРА ПОЛЕЙ.',
      'Select/Deselect all': 'Выбрать/Отменить всё',
      Selected: 'Выбранные',
      'Selected groups': 'Выбранные группы',
      'Selected more than one forwarder.': 'Выбрано больше одного экспедитора.',
      'Selected volume:': 'Selected volume:',
      Selector: 'Выбор',
      'Self-cost': 'Себестоимость',
      'Self-cost per 1 t including all costs': 'Себестоимость за 1 т, включая все расходы',
      'Selfcost Amount, $': 'Себестоимость, $',
      'Selfcost per MT': 'Себестоимость за 1т',
      Sell: 'Продажа',
      'Sell quantity': 'Количество продажи',
      Seller: 'Продавец',
      Send: 'Отправить',
      'Send Screenshot': 'Отправить снимок экрана',
      'Send an invoice': 'Отправить инвойс',
      'Send business confirmation': 'Отправить бизнес подтверждение',
      'Send email': 'Отправить письмо',
      'Send selected': 'Отправить выбранные',
      Sent: 'Отправлено',
      'Sent docs to Buyers': 'Доки отправили Покупателям',
      September: 'Сентябрь',
      Sequence: 'Последовательность',
      'Sequence order': 'Последовательность действий',
      'Server error': 'Ошибка сервера',
      'Service contract': 'Сервисный контракт',
      'Service invoice': 'Инвойс за услуги',
      Services: 'Услуги',
      'Services Contract': 'Сервисный контракт',
      'Services contract': 'Сервисный контракт',
      'Services multicontract': 'Сервисный мультиконтракт',
      'Services multiticket': 'Сервисный мультитикет',
      'Services positions': 'Сервисные позиции',
      ServicesContract: 'Сервисный контракт',
      'Set cargo confirmation': 'Установить подтверждение груза',
      'Set contract closure': 'Установить закрытие контракта',
      'Set directly': 'Установить напрямую',
      'Set export contract to sale logistics':
        'Установить экспортный контракт для продажной логистики',
      'Set fact volume to plans': 'Установить фактический объем вместо плана',
      'Set final volume': 'Установить финальный объем',
      'Set if only you want to convert amount in USD by a certain rate':
        'Установить, если хотите конвертировать сумму в USD по определенному курсу',
      'Set it only if volume of charge differs from contract volume':
        'Установить, если объем стоимости отличается от объема контракта',
      'Set plan': 'Установить план',
      'Set positions number': 'Установить количество позиций',
      'Set sale contract to purchase logistics':
        'Установить продажный контракт для логистики закупочных контрактов',
      'Set status closed': 'Установить статус Закрыто',
      'Set the status Closed for all costs that are fully invoiced and paid':
        'Установить статус Закрыто для всех затрат, которые полностью проинвойсированы и оплачены',
      'Set voyage data': 'Установить данные о рейсе',
      Settings: 'Настройки',
      Share: 'Поделиться',
      'Ship classification': 'Класс лодки',
      Shipment: 'Отгрузка',
      'Shipment Plan': 'Месплан',
      'Shipment Plan by Passport': 'План отправки по паспорту',
      'Shipment Terminal': 'Терминал отгрузки',
      'Shipment balance': 'Баланс отгрузки',
      'Shipment business unit': 'Бизнес-юнит отгрузки',
      'Shipment days saved.': 'Дни отгрузки сохранены.',
      'Shipment elevator': 'Элеватор отгрузки',
      'Shipment period': 'Период исполнения',
      'Shipment place': 'Место отгрузки',
      'Shipment plan deleted.': 'План отгрузки удален.',
      'Shipment plan saved.': 'План отгрузки сохранен.',
      'Shipment planned': 'Отгрузка распланирована',
      'Shipment type': 'Тип отгрузки',
      'Shipment warehouse': 'Склад отгрузки',
      'Shipment. Completed if shipment is done': 'Отгрузка. Успех - когда нужный объем исполнился',
      Shipments: 'Отгрузки',
      Shipped: 'Отгружено',
      'Shipped on board date': 'Дата погрузки на борт',
      'Shipped wagons': 'Погруженные вагоны',
      Short: 'Шорт',
      'Short lots': 'Короткие лоты',
      'Shortage rate': 'Шортадж ставка',
      'Should all the details be filled in from the Parent warehouse?':
        'Следует ли заполнять все данные с главного склада?',
      Show: 'Показывать',
      'Show Aging': 'Показать задержки',
      'Show Facts': 'Показать факты',
      'Show MTM': 'Показать МТМ',
      'Show all': 'Показать всё',
      'Show bank balances': 'Показать банк балансы',
      'Show bank transaction': 'Показать банковскую транзакцию',
      'Show child crops': 'Показать дочерние культуры',
      'Show debts by plan date': 'Показать долги по дате плана',
      'Show detail': 'Показать детали',
      'Show details': 'Показать детали',
      'Show full table': 'Показать полную таблицу',
      'Show history': 'Показать историю',
      'Show initial balance': 'Показать первоначальный баланс',
      'Show intermediate contract`s cargo positions':
        'Показать позиции груза промежуточного контракта',
      'Show less': 'Показать меньше',
      'Show level': 'Показать уровень',
      'Show level:': 'Показать уровень:',
      'Show more': 'Показать больше',
      'Show my': 'Показать мои',
      'Show overpaid': 'Показать переплаты',
      'Show past periods debts': 'Показать долги прошлых периодов',
      'Show reserve': 'Показать резерв',
      'Show volume': 'Показывать объем',
      'Show voyage details': 'Показать детали рейса',
      'Show/hide all': 'Показать/скрыть все',
      'Shown by default': 'Показать по умолчанию',
      Sides: 'Стороны',
      'Sign a contract': 'Подписать контракт',
      Signatory: 'Подписант',
      'Signatory Docs': 'Подписант документов',
      'Signatory Eng': 'Подписант (Англ)',
      'Signatory Eng Docs': 'Подписант документов (Англ)',
      'Signatory Eng Position': 'Должность подписанта (англ)',
      'Signatory Position': 'Должность подписанта',
      'Signatory docs': 'Подписант доки',
      'Signatory docs eng': 'Подписант доки (англ)',
      'Signatory eng': 'Подписант английский',
      'Signatory, the son of Signator': 'Подписант Подписантович',
      Signature: 'Подпись',
      'Signature stages': 'Стадии подписания',
      'Signature. Completed if all sides signed a document':
        'Подпись. Успех, когда все стороны подписали контракт',
      Signed: 'Подписан',
      Signings: 'Подписания',
      'Signings KPI': 'Подписи KPI',
      'Signings status': 'Статус подписания',
      Similar: 'Похожие',
      'Simple percent': 'Простой процент',
      'Simple system': 'Простая система',
      'Since we group this report by contract, you should choose either by sales\n                          contracts or by purchase contracts':
        'Поскольку мы группируем этот отчет по контракту, вы должны выбрать либо по контрактам на продажу, либо по контрактам на закупку.',
      Size: 'Размер',
      Small: 'Маленький',
      'Something went wrong': 'Что-то пошло не так',
      'Something went wrong...': 'Что-то пошло не так…',
      'Something went wrong... try again.': 'Что-то пошло не так. Попробуйте ещё раз.',
      Source: 'Источник',
      Sources: 'Источники',
      Special: 'Специальное',
      'Specific information about freight': 'Особенная информация про этот объект',
      'Specific information about this object': 'Особенная информация про этот объект',
      Split: 'Разделить',
      Spot: 'Спот',
      'Spot fact': 'Спот факт',
      'Spot limit': 'Лимит спот',
      'Spot or Forward': 'Спот или форвард',
      Stage: 'Этап',
      'Stage #': 'Этап №',
      Start: 'Начало',
      'Start Date': 'Дата начала',
      'Start balance': 'Первоначальный баланс',
      'Start date': 'Дата начала',
      'Start date (plan)': 'Дата начала (план)',
      'Start end': 'Начало конца',
      'Start of execution': 'Начать исполнение',
      'Start usage (date)': 'Начало использ (дата)',
      Station: 'Станция',
      'Station Departure': 'Станция Отправления',
      'Station arrival': 'Станция получения',
      'Station depart': 'Станция отправления',
      'Station departure': 'Станция отправки',
      'Station destination': 'Станция назначения',
      'Station receive': 'Станция получения',
      'Station receiving': 'Станция получ',
      Stations: 'Станции',
      Statistics: 'Статистика',
      Status: 'Статус',
      'Status depends on current progress on general agreement':
        'Статус зависит от текущего прогресса по Договору',
      'Status depends on current progress on payment plan':
        'Статус зависит от текущего прогреса по плану',
      'Status depends on current progress on request':
        'Статус зависит от текущего прогреса по Заявке',
      'Status of te deal: NEW - recently created, SHIPMENT - the execution has been started, EXECUTED - the Contract is closed and executed, CANCELLED - rejected by the manager':
        'Статус сделки: НОВОЕ - только созданное, ОТГРУЗКА: начало движения транспортных средств, ИСПОЛНЕНО - контракт выполнен в полном объеме, ОТМЕНЕНО - сделка отвергнута менеджером',
      'Status updated': 'Статус обновлен',
      'Status will be changed automatically, based on a compliance status. You need to fill out request, received and confirmed date':
        'Статус будет изменен автоматически в зависимости от статуса комплаенса. Необходимо заполнить запрос, дату получения и подтверждение',
      Statuses: 'Статусы',
      Step: 'Шаг',
      'Step saved': 'Шаг сохранен',
      Stock: 'Остатки',
      'Stock Exchange': 'Биржевый курс',
      'Stock Reserve Unit': 'Резервный блок',
      'Stock Reserve Units': 'Резервные единицы запаса',
      'Stock exchange': 'Биржевый курс',
      'Stock exchanges': 'Биржы',
      'Stock plan (t)': 'Остатки план (т)',
      'Stock plan amount': 'Остаток план сумма',
      'Stock reserve unit': 'Резервный блок',
      'Stock reserve units': 'Резервные блоки',
      Stocklot: 'Остатки товара',
      Stocks: 'Запасы',
      'Stocks allocation': 'Привязка остатков',
      'Stocks, MT': 'Запасы, т',
      Storage: 'Хранение',
      'Storage & cargo': 'Хранение и груз',
      'Storage days': 'Дни хранения',
      Strategy: 'Стратегия',
      String: 'Строка',
      Stuff: 'Персонал',
      Su: 'Вс',
      'Sub distributor': 'Субдистрибьютор',
      'Sub reassignments': 'Двойная переуступки',
      Subject: 'Тема',
      'Submit documents': 'Подтвердить документы',
      Subscribe: 'Подписаться',
      Subuse: 'Под-назначение',
      Success: 'Успешно',
      'Success created.': 'Успешно создан.',
      'Successfully updated.': 'Успешно обновлено.',
      Summary: 'Итог',
      Sunday: 'Воскресенье',
      'Super Admin Rights': 'Права супер администратора',
      Supplier: 'Поставщик',
      'Supplier 10 invoice': 'Поставщик баланс счет',
      'Supplier 90 invoice': 'Поставщик 90 инвойс',
      'Supplier EDRPOU code': 'Продавец ЕДРПОУ код',
      'Supplier ITN (Individual Tax Number)': 'Продавец индивидуальный налоговый номер',
      'Supplier Signed': 'Поставщик подписал',
      'Supplier address': 'Продавец адрес',
      'Supplier address eng': 'Продавец адрес англ',
      'Supplier bank details': 'Продавец банк. детали',
      'Supplier can signatory docs': 'Продавец подписант доки',
      'Supplier can signatory docs eng': 'Продавец подписант доки англ',
      'Supplier contract': 'Поставщик контракт',
      'Supplier contract #': 'Поставщик контракт №',
      'Supplier contract ISCC': 'Контракт поставщика ISCC',
      'Supplier contract status': 'Поставщик контракт статус',
      'Supplier country': 'Страна поставщика',
      'Supplier country eng': 'Продавец страна англ',
      'Supplier email': 'Продавец почта',
      'Supplier full name': 'Продавец полное название',
      'Supplier full name eng': 'Продавец полное название английский',
      'Supplier invoiced': 'Поставщику выставлен инвойс',
      'Supplier is required': 'Поставщик обязателен',
      'Supplier legal address': 'Продавец адрес полный',
      'Supplier paid': 'Поставщик оплатил',
      'Supplier passports': 'Поставщик паспорта',
      'Supplier payment info': 'Инфо про оплату поставщика',
      'Supplier phone': 'Продавец телефон',
      'Supplier signatory': 'Продавец подписант',
      'Supplier signed': 'Поставщик подписал',
      'Supplier website': 'Продавец сайт',
      'Supplier/Owner': 'Поставщик/Собственик',
      Suppliers: 'Поставщики',
      Surveyor: 'Сюрвейер',
      Surveyors: 'Сюрвейеры',
      Swap: 'СВОП',
      Swift: 'Свифт',
      'System Event': 'События системы',
      'System notify': 'Системное уведомление',
      TASKS: 'ЗАДАНИЯ',
      TEMPLATE: 'ШАБЛОН',
      TICKET: 'ТИКЕТ',
      'TICKET DOCUMENTS': 'ДОКУМЕНТЫ ТИКЕТА',
      TICKETS: 'ТИКЕТЫ',
      TITLE: 'НАЗВАНИЕ',
      TOTAL: 'ВСЕГО',
      'TOTAL data': 'ИТОГОВЫЕ данные',
      TOTALS: 'ВСЕГО',
      TRANSLOAD: 'ПЕРЕВАЛКА',
      TRANSPORT: 'ТРАНСПОРТ',
      'TRANSPORT CERTIFICATES': 'ТРАНСПОРТНЫЕ СЕРТИФИКАТЫ',
      'TRANSPORT DOCUMENTS': 'ТРАНСПОРТНЫЕ ДОКУМЕНТЫ',
      TURNOVER: 'ОБОРОТ',
      Tabs: 'Вкладки',
      Tag: 'Тег',
      'Tare weight': 'Вес тары',
      Tariff: 'Тариф',
      'Tariff 20': 'Тариф 20',
      'Tariff 40': 'Тариф 40',
      Tariffs: 'Тарифы',
      Task: 'Задание',
      'Task state': 'Статус задачи',
      Tasks: 'Задания',
      'Tasks templates': 'Шаблон задачи',
      Tax: 'Налог',
      'Tax debt': 'Налоговая задолженность',
      'Tax info': 'Налоговая информация',
      'Tax profit to pay': 'Налог на прибыль',
      'Tax registration': 'Налог регистрация',
      'Tax status': 'Налоговый статус',
      Team: 'Команда',
      'Technical passport number': 'Номер технического паспорта',
      Telegram: 'Telegram',
      'Telegram Chat': 'Telegram чат',
      'Telegram username': 'Telegram никнейм пользователя',
      Template: 'Шаблон',
      'Template deleted': 'Шаблон удален',
      'Template name': 'Название шаблона',
      'Template playground': 'Песочница для шаблонов',
      'Template removed': 'Шаблон удален',
      'Template saved': 'Шаблон сохранен',
      Templates: 'Шаблоны',
      'Term of proxy': 'Срок действия доверенности',
      Terminal: 'Терминал',
      'Terminal Transshipment': 'Перевалка на терминале',
      'Terminal Transshipment Tariff': 'Тариф терминала перевалки',
      Terminals: 'Терминалы',
      Text: 'Текст',
      Th: 'Чт',
      'The All signed date must be greater than or equal to all other dates.':
        'Дата всех подписанных документов должна быть больше или равна всем остальным датам.',
      'The Legal Entity for this Role will be auto created':
        'Юридическое лицо для этой роли будет создано автоматически',
      'The calculation principle is the following:': 'Принцип расчета заключается в следующем:',
      'The commission of bank': 'Комиссия банка',
      'The company name abbreviation': 'Аббревиатура компании',
      'The contract': 'Контракт',
      'The contract does not contain an exchange rate': 'Контракт не содержит обменного курса',
      'The cost depends on the VAT tax rate specified in the contract':
        'Стоимость зависит от ставки налога на добавленную стоимость, указанной в договоре',
      'The discount of VAT compensation by the deal': 'Дисконт по НДС по этой сделке',
      'The email for internal use': 'Почта для внутреннего использования',
      'The fields contract, derivative must make a unique set.':
        'Поле контракт, дериватив долен составлять уникальный набор.',
      'The fields voyage, commodity must make a unique set.':
        'Поля контракта дериватива должны составлять уникальный набор.',
      'The first name for internal use': 'Имя для внутреннего использования',
      'The form is': 'Форма',
      'The last name for internal use': 'Фамилия для внутреннего использования',
      'The main person of the company': 'Основной контакт компании',
      'The name for internal use, can be whatever you want':
        'Название для внутреннего использования, может быть каким угодно',
      'The normal loss rate of Elevator': 'Норма потерь Элеватора',
      'The normal loss rate of RW': 'Норма потерь ЖД',
      'The number on which sale price will be multiplied':
        'Процент на который будет умножена продажная цена',
      'The personal code of this company in state instances':
        'Уникальный код компании в государственных учреждениях. ЕДРПОУ, ИНН, и так далее',
      'The phone number for internal use': 'Телефон для внутреннего использования',
      'The rate of return for VAT for cargo': 'Ставка возврата НДС за груз',
      'The rate of return for VAT for costs': 'Ставка возврата НДС по затратам',
      'The telegram username for internal use': 'Telegram никнейм для внутреннего использования',
      'The ticket': 'Тикет',
      'The totals have been updated': 'Итоги были обновлены',
      'The username for internal use': 'Никнейм для внутреннего использования',
      TheadConfig: 'Конфигурация заголовка',
      Theme: 'Тема',
      'There are no selected filters to save': 'Нет выбранных фильтров для сохранения',
      'There are not undrop mailing in your set': 'В вашем наборе нет отложенных рассылок',
      'There are not unsent mailing in your set': 'Все рассылки отправлены',
      'There is no purchase contracs assigned to this passport':
        'Для этого паспорта не привязан ни один контракт на закупку',
      'There is nothing to show': 'Ничего не нашли',
      'These is the Subject, your recipients see when receive letter':
        'Это Тема. Её увидят получатели',
      'These is the Title, only you will see, to identify current template':
        'Это Название. Видите его только вы, для того, что бы идентифицировать шаблон',
      'Third (validation)': 'Третье (валидация)',
      'This Month': 'Текущий месяц',
      'This actin will start voting again. Continue?':
        'Это действие снова начнет голосование. Продолжать?',
      'This action will create new ticket. How much to bite off?':
        'Это действие создаст новый тикет. Сколько “откусить”?',
      'This amount will change final invoice amount':
        'Эта сумма изменит окончательную сумму инвойса',
      'This cannot be undone': 'Это невозможно отменить',
      'This consignment already connected.': 'Эта накладная уже подключена.',
      'This contract cannot be connected with this volume to passport.':
        'Контракт не может быть привязан с этим объемом к паспорту.',
      'This contract has estimated volume': 'У этого контракта есть ожидаемый объем',
      'This contract has finalized volume': 'У этого контракта есть финальный объем',
      'This contract was made from indicator': 'Этот контракт был составлен на основе индикатора',
      'This contract was made from ticket': 'Этот контракт был создан из тикета',
      'This contracts already fully connected': 'Эти контракты уже полностью связаны',
      'This email will be send on behalf of you and via your email, appointed in Settings.':
        'Это письмо будет отправлено от вашего имени и через ваш email, указанный в настройках.',
      'This field should be filled to pass compliance':
        'Это поле должно быть заполнено для проверки комплаенса',
      'This first block is combined P&L. It is based on the result you got by passports.\n                  Right after you press CONTROL button in the certain passport - its P&L will be\n                  instantly displayed here.':
        'Этот первый блок — это объединенный P&L. Он основан на результате, полученном по паспортам. Как только вы нажмете кнопку CONTROL в определенном паспорте, его P&L сразу отобразится здесь.',
      'This invoice was made from credit note': 'Инвойс создан из кредит ноута',
      'This is bank details of our company': 'Это банковские реквизиты нашей компании',
      'This is not a valid float number. Type value in format 5 or 5.5 for\n                      example':
        'Это некорректное значение с плавающей запятой. Введите значение в формате 5 или 5.5, например.',
      'This is not a valid float number. Type value in format 5 or 5.5 for\n                example.':
        'Неправильный формат числа. Попробуйте 5 или 5.5\n                 например.',
      'This is not contract.': 'Это не контракт.',
      'This is the Message of your letter.You can use tags {{ tag }} to add some fields to template here.':
        'Это сообщение вашего письма. Вы можете использовать теги {{tag}}, чтобы добавить некоторые поля в шаблон.',
      'This is the report of passports, showing you all allocations of contracts,\n                    costs, invoices and execution between each other.':
        'Это отчет по паспортам, который показывает все распределения контрактов, затрат, счетов-фактур и их исполнение между собой.',
      'This is your operational P&L.': 'Это ваш операционный PnL.',
      'This logistic already selected.': 'Эта логистика уже выбрана.',
      'This object has voted by you already. Skipping.':
        'Вы уже проголосовали за этот объект. Пропуск.',
      'This should be filled for multilingual agreements':
        'Это должно быть заполнено для многоязычных соглашений',
      'This ticket was made from': 'Этот тикет был создан из',
      'This ticket was made from indicator': 'Этот Тикет был создан из индикатора',
      'This value will be added to your margin': 'Это значение будет прибавлено к вашей марже',
      'This will add a mark that you have no intention to pay this invoice in a nearest future':
        'Это придаст отметку о том, что вы не намерены оплачивать этот инвойс в ближайшее время',
      'Thus, you will se profitability of each part and the consolidated profitability of\n              the whole chain.':
        'Таким образом, вы увидите рентабельность каждой части и консолидированную рентабельность\n              всей цепочки.',
      Ticker: 'Тикер',
      Ticket: 'Тикет',
      'Ticket ROS': 'Тикет ROS',
      'Ticket creation': 'Создание тикета',
      'Ticket custom status': 'Пользовательский статус тикета',
      'Ticket number': 'Номер тикета',
      'Ticket price exceeds indicator’s price': 'Цена тикета превышает цену индикатора',
      'Ticket price is lower than indicator’s price': 'Цена тикета ниже цены индикатора',
      'Ticket value': 'Тикет сумма',
      'Ticket volume': 'Объем тикета',
      'Ticket/Indicator': 'Тикет/Индикатор',
      Tickets: 'Тикеты',
      'Tickets Analytical': 'Тикет Аналитический',
      'Tickets Analytics': 'Аналитика тикетов',
      'Tickets analytical': 'Аналитика контрактов',
      'Tickets margin': 'Маржа тикетов',
      'Tickets volume': 'Объем тикета',
      Title: 'Название',
      'Title can not be empty': 'Название не может быть пустым',
      'Title must be unique': 'Название должно быть уникальным',
      To: 'До',
      'To Stock Reserve Unit': 'В резервный блок',
      'To allocate': 'Закрепить',
      'To be invoiced': 'Будет выставлено',
      'To be invoiced (bal)': 'Будет выставлено (баланс)',
      'To be paid': 'Будет оплачено',
      'To be shipped': 'Будет отгружаться',
      'To filter contracts for allocation': 'Фильтрация контрактов для распределения',
      'To fix': 'Зафиксировать',
      'To fix lots': 'Зафиксировать лоты',
      'To fix tonnes': 'Зафиксировать тонны',
      'To hedge': 'Захеджировать',
      'To keep the existing cost/gain while creating a new one for the invoice, please\n                remove the value in the Contract charge field.':
        'Чтобы сохранить существующую затрату/прибыль при создании новой для инвойса, пожалуйста, удалите значение в поле «Контрактная плата».',
      'To make them appear add email or employees with emails to related\n                            counterparty':
        'Чтобы они появились, добавьте email или сотрудников из email до связанного \n                            контрагента',
      'To pay': 'Оплатить',
      'To receive': 'Получить',
      'To which country delivered': 'В какую страну доставлено',
      'To whom': 'Кому',
      Today: 'Сегодня',
      Tolerance: 'Опция',
      'Tolerance minus': 'Опция в минус',
      'Tolerance plus': 'Опция в плюс',
      'Tolerance volume': 'Объем опции',
      'Tolerance, %': 'Толеранс, %',
      'Top 20 Buyers': 'Топ 20 Покупателей',
      'Top 20 Suppliers': 'Топ 20 Поставщиков',
      Topic: 'Тема',
      Total: 'Всего',
      'Total Nested costs volume of invoices in': 'Общий объем вложенных затрат по инвойсам в',
      'Total P&L': 'Всего P&L',
      'Total Paid': 'Всего Оплачено',
      'Total VAT': 'Сумма НДС',
      'Total Volume': 'Всего объем',
      'Total absolute value of charge': 'Общая стоимость затраты',
      'Total amount': 'Полная сумма',
      'Total amount of all incoming invoices converted in USD':
        'Общая сумма всех входящих инвойсов, конвертированная в USD',
      'Total amount of all incoming invoices in': 'Общая сумма всех входящих инвойсов в',
      'Total amount of all outgoing invoices converted in USD':
        'Общая сумма всех исходящих инвойсов, конвертированная в USD',
      'Total amount of all outgoing invoices in': 'Общая сумма всех исходящих инвойсов',
      'Total amount of invoices in': 'Общая сумма инвойсом в',
      'Total approved': 'Всего подтверждено',
      'Total by real stocks': 'Всего реальных остатков',
      'Total cumulative amount of all contracts': 'Общая сумма по всем контрактам',
      'Total cumulative volume of all contracts': 'Общая объем по всем контрактам',
      'Total for current date': 'Сумма для текущей даты',
      'Total in USD': 'Всего в USD',
      'Total invoiced (fact)': 'Всего выставлено (факт)',
      'Total invoiced (plan)': 'Всего выставлено (план)',
      'Total lost volume': 'Общий потерянный объем',
      'Total margin': 'Общая маржа',
      'Total outgoing - Total incoming': 'Всего исходящие - всего входящие',
      'Total outgoing - Total incoming in': 'Всего исходящие - всего входящие в',
      'Total paid': 'Всего оплачено',
      'Total paid amount by all incoming invoices in':
        'Общая сумма оплат по всем входящих инвойсам в',
      'Total paid amount by all outgoing invoices in':
        'Общая сумма оплат по всем исходящим инвойсам в',
      'Total paid amount of all incoming invoices converted in USD':
        'Общая сумма оплат по всем входящих инвойсам, конвертированная в USD',
      'Total paid amount of all outgoing invoices converted in USD':
        'Общая сумма оплат по всем исходящим инвойсам, конвертированная в USD',
      'Total project plan': 'Общий план проекта',
      'Total quantity': 'Общее количество',
      'Total requested': 'Всего заявлено',
      'Total selected': 'Общее количество выбранных',
      'Total shipped': 'Всего отгружено',
      'Total value': 'Общая сумма',
      'Total volume': 'Всего объем',
      'Total volume (forward)': 'Общий объем (форвард)',
      'Total volume (spot)': 'Общий объем (спот)',
      'Total volume of the deal. Choose plan value, at first, but then substitute on delivered volume':
        'Общий тоннаж сделки. Напишите плановый объем, а когда станет известен весь поставленный объем по контрактам - исправьте для точности подсчетов',
      'Total volume plan': 'Всего объем',
      'Total volumes by customs terminal': 'Общие объемы по таможенному терминалу',
      'Totally not invoiced': 'Полностью не выставлен счет',
      Totals: 'Всего',
      Trade: 'Торговля',
      'Trade date': 'Дата сделки',
      'Trader Note': 'Примечание трейдера',
      'Trader note': 'Заметка трейдера',
      'Traders amount': 'Сумма трейдера',
      'Traders discount value': 'Сумма дисконта для трейдеров',
      'Traders volume': 'Объем трейдеров',
      'Trades in USD': 'Торги в USD',
      'Trades in local currency': 'Торги в локальной валюте',
      Trailer: 'Прицеп',
      Transaction: 'Транзакция',
      'Transaction (date)': 'Транзакция (дата)',
      'Transaction Date': 'Дата транзакции',
      'Transaction removed': 'Транзакция удалена',
      'Transaction sum': 'Транзакция сумма',
      Transactions: 'Транзакции',
      Transfer: 'Перевалка',
      Transhipment: 'Перевалка',
      Transit: 'Транзит',
      Transload: 'Перевалка',
      'Transloaded Date': 'Дата перевалки',
      'Transloaded date': 'Дата перевалки',
      'Transloading facility': 'Место перевалки',
      Transport: 'Транспорт',
      'Transport charge': 'Затрата транспорта',
      'Transport costs': 'Транспортные расходы',
      'Transport costs per t': 'Транспортные затраты на т',
      'Transport costs per unit': 'Транспортные затраты на единицу',
      'Transport count': 'Количество транспорта',
      'Transportation Storage Expense': 'Расходы на транспортировку и хранение',
      'Transportation Storage Expenses': 'Расходы на транспортировку и хранение',
      'Transports count': 'Количество транспортов',
      Transshipment: 'Перевалка',
      Truck: 'Грузовик',
      Tu: 'Вт',
      'Turn off': 'Выключать',
      'Turnover for the period': 'Оборот за период',
      Type: 'Тип',
      'Type a new name': 'Введите новое имя',
      'Type address': 'Напишите адрес',
      'Type address (eng)': 'Напишите адрес (англ)',
      'Type amount': 'Введите сумму',
      'Type any comments': 'Напишите любой комментарий',
      'Type bank branches separated by comma': 'Введите отделения банка, разделенные запятой',
      'Type bank code': 'Напишите банковский код',
      'Type credit note number': 'Напишите номер кредит ноута',
      'Type days': 'Напишите дни',
      'Type destination': 'Тип назначения',
      'Type email of user': 'Напишите почту пользователя',
      'Type first name of user': 'Напишите имя пользователя',
      'Type full name': 'Напишите полное имя',
      'Type full name (eng)': 'Напишите полное имя (англ)',
      'Type here any specific information about this deal':
        'Напишите здесь любую дополнительную информацию по сделке',
      'Type invoice number': 'Номер',
      'Type last name of user': 'Напишите фамилию пользователя',
      'Type loan number': 'Напишите номер соглашения',
      'Type name': 'Напишите имя',
      'Type new broker': 'Напишите имя нового брокера',
      'Type new buyer': 'Напишите имя нового покупателя',
      'Type new supplier': 'Напишите имя нового поставщика',
      'Type number': 'Тип номера',
      'Type of CD': 'Тип ТД',
      'Type of activities': 'Вид деятельности',
      'Type of consignment': 'Тип поставки',
      'Type of contract': 'Тип контракта',
      'Type of contracts perspective': 'Тип контракта',
      'Type of operation': 'Тип операции',
      'Type offset number': 'Написать номер зачета',
      'Type origin': 'Напишите происхождение',
      'Type packing': 'Напишите вместительность',
      'Type percent conditions': 'Напишите условия процента',
      'Type phone number of user': 'Напишите телефон пользователя',
      'Type price': 'Введите цену',
      'Type quantity': 'Количество',
      'Type rate': 'Написать ставку',
      'Type reassignment number': 'Напишите номер переуступки',
      'Type swift': 'Напишите свифт',
      'Type telegram username of user': 'Напишите telegram никнейм',
      'Type title of passport': 'Название паспорта',
      'Type title of template': 'Введите название шаблона',
      'Type tolerance': 'Тип опциона',
      'Type username of user': 'Напишите никнейм',
      'Type value': 'Напишите значение',
      'Type volume': 'Напишите объем',
      'Type volume max': 'Напишите объем (макс)',
      'Type volume min': 'Напишите объем (мин)',
      'Types of activities': 'Типы активности',
      'UNREALIZED Position': 'НЕРЕАЛИЗОВАННАЯ позиция',
      UPDATED: 'ОБНОВЛЕНО',
      UPDATES: 'ОБНОВЛЕНИЯ',
      'UPLOADED DOCUMENTS': 'ЗАГРУЖЕННЫЕ ДОКУМЕНТЫ',
      USA: 'США',
      USD: 'USD',
      'USD, EUR': 'USD, EUR',
      'USD, UAH': 'USD, UAH',
      Ukraine: 'Украина',
      Ukrainian: 'Украинский',
      'Unable to create payment for unapproved invoice':
        'Невозможно создать платеж по неутвержденному инвойсу',
      Unconfirmed: 'Не подтверждено',
      Unconnected: 'Несоединенные',
      'Unconnected Stock': 'Несвязанный запас',
      'Unconnected amount per deal': 'Несвязанная сумма за сделку',
      'Unconnected stock': 'Несвязанный запас',
      'Unconnected transport': 'Несоединенный транспорт',
      'Unconnected volume (by contract final volume)':
        'Несвязанный объем (по финальному объему контракта)',
      'Unconnected volume (contract)': 'Несвязанный объем (контракт)',
      'Unconnected volume (ticket)': 'Не подключен объем (тикет)',
      Undefined: 'Неопределённый',
      Undelivered: 'Не доставлено',
      'Under discussion': 'Обсуждается',
      Undistributed: 'Нераспределенное',
      Unexecuted: 'Неисполненный',
      'Unfulfilled volume': 'Невыполненный объем',
      'Uninvoiced charges': 'Невыставленные начисления',
      'Uninvoiced cost': 'Непроинвойсированные расходы',
      'Unit price': 'Цена юнит',
      'Unload costs per t': 'Затраты на разгрузку за тонну',
      'Unload costs per unit': 'Затраты на разгрузку на единицу',
      'Unloading business unit': 'Бизнес-юнит разгрузки',
      'Unloading place': 'Место разгрузки',
      'Unloading volume': 'Объем выгрузки',
      'Unloading warehouse': 'Склад выгрузки',
      'Unpaid Cargo': 'Неоплаченный груз',
      'Unpaid amount': 'Неоплаченная сумма',
      'Unpaid volume': 'Неоплаченный объем',
      Unread: 'Непрочитано',
      'Unrealized Position': 'Нереализованная позиция',
      Unrelevant: 'Нерелевантно',
      'Unselected groups': 'Невыбранные группы',
      'Untie contracts from passport': 'Отвязать контракты от паспорта',
      Upcoming: 'Предстоит выполнить',
      Update: 'Обновление',
      'Update Business Date': 'Обновить бизнес дату',
      'Update Execution Stages': 'Обновить стадии исполнения',
      'Update Execution Stages (save)': 'Обновить стадии исполнения (сохранить)',
      'Update Telegram CHAT_ID': 'Обновить Telegram CHAT_ID',
      'Update Volume from BL': 'Обновить объем из коносамента',
      'Update all  connections from execution?': 'Обновить все привязки из исполнения?',
      'Update all export connections from execution?':
        'Обновить все экспортные соединения из выполнения?',
      'Update all volumes': 'Обновить все объемы',
      'Update cargo_confirmation, payment_confirmation, final_confirmation':
        'Обновить подтверждение по грузу, оплате и финальное',
      'Update date': 'Обновить дату',
      'Update export volumes': 'Обновить экспортные объемы',
      'Update full contract price volume': 'Обновить полный объем цены контракта',
      'Update part of the position': 'Обновить часть позиции',
      'Update part of the volume': 'Обновить часть объема',
      'Update purchase volumes': 'Обновить объемы закупки',
      'Update sale volumes': 'Обновить объемы продажи',
      'Update status': 'Обновить статус',
      'Update statuses': 'Обновить статус',
      'Update time': 'Дата обновления',
      'Update title': 'Обновить заголовок',
      Updated: 'Обновлено',
      'Updated at': 'Обновлено в',
      'Updated date': 'Дата обновления',
      'Updated on:': 'Обновлено в:',
      'Updated.': 'Обновлено.',
      Updates: 'Обновления',
      'Updates Analytics': 'Обновления аналитики',
      Updatind: 'Обновляется',
      'Updating...': 'Обновляется...',
      Upload: 'Загрузить',
      'Upload a document': 'Загрузить документ',
      'Upload date': 'Дата загрузки',
      'Uploaded documents': 'Загруженные документы',
      'Uploading is in progress. Please wait': 'Загрузка впроцессе. Ждите',
      'Usage cost': 'Затраты на использ',
      Use: 'Использование',
      'Use commodity parent match': 'Использовать совпадение с родительским товаром',
      'Use duval': 'Использовать дюваль',
      'Use escalations': 'Используйте эскалацию',
      'Use for price': 'Применять к цене',
      'Use for volume': 'Использовать для объема',
      'Use in position': 'Использовать в позиции',
      'Use intermediate logistics in execution calculation': 'Использовать промежут логистику',
      'Use open price': 'Использовать открытую цену',
      'Use this or %': 'Используйте это или процент',
      'Use this or discount %': 'Используйте это или процент дисконта',
      'Use type': 'Тип использования',
      User: 'Пользователь',
      'User removed': 'Пользователь удален',
      'User to be manager for the offset': 'Пользователь ответственный за зачет',
      'User to be manager for the reassignment': 'Пользователь ответственный за переуступку',
      'User to be responsible': 'Назначьте ответственного пользователя',
      'User to be responsible for the contract': 'Пользователь ответственный за контракт',
      'User to be responsible for the deal': 'Выбрать пользователя ответственным за сделку',
      'User to be responsible_for_signing': 'Ответственный за подписание',
      'User updated': 'Пользователь обновлен',
      Username: 'Никнейм',
      Users: 'Пользователи',
      VALIDATION: 'ВАЛИДАЦИЯ',
      VALUE: 'СТОИМОСТЬ',
      VAT: 'НДС',
      'VAT amount': 'Сумма НДС',
      'VAT costs return rate': 'Возврат НДС по затратам',
      'VAT included': 'НДС включен',
      'VAT return': 'Возврат НДС',
      'VAT return rate': 'Ставка возврата НДС',
      'VAT value': 'стоимость НДС',
      VESSEL: 'СУДНО',
      VESSELS: 'СУДНА',
      'VET required': 'ВЕТ обяз',
      VOLUME: 'ОБЪЕМ',
      VOLUMES: 'ОБЪЕМЫ',
      VOYAGE: 'РЕЙС',
      'VOYAGE DESTINATION DATES': 'РЕЙС ДАТЫ НАЗНАЧЕНИЯ',
      'VOYAGE LOADING DATES': 'РЕЙС ДАТЫ ОТПРАВКИ',
      Valid: 'Актуально',
      Validated: 'Утверждено',
      Value: 'Стоимость',
      'Value - invoiced': 'Сумма - проинвойсированно',
      'Value - paid': 'Сумма - оплачено',
      'Value alt': 'Значение альт',
      'Value alternative': 'Значение альтернативное',
      'Value general': 'Значение общее',
      'Value general (forward)': 'Общая сумма (форвард)',
      'Value general (spot)': 'Общая сумма (спот)',
      'Value no VAT': 'Стоимость без НДС',
      'Value of charge in %. Will be converted to total amount automatically, based on contract amount':
        'Стоимость затраты в %. Преобразуется в общую сумму автоматически на основе суммы контракта',
      'Value of charge per 1 metric ton. Will be converted to total amount automatically, based on contract quantity':
        'Стоимость затраты за 1 т. Преобразуется в общую сумму автоматически на основе объема контракта',
      'Value of goods': 'Стоимость товара',
      'Value per deal': 'Значение за сделку',
      'Value per deal (forward)': 'Сумма за сделку (форвард)',
      'Value per deal (spot)': 'Сумма за сделку (спот)',
      'Value with VAT': 'Стоимость с НДС',
      Vat: 'НДС',
      Vehicle: 'Транспортное средство',
      'Vehicle (#)': 'Номер т\\с',
      'Vehicle details': 'Детали транспортного средства',
      "Vehicle doesn't exist": 'Транспортное средство не существует',
      'Vehicle number': 'Номер транспортного средства',
      'Vehicle registration number': 'Регистрационный номер автомобиля',
      'Vehicle removed': 'Т\\с удалено',
      'Vehicle restrictions': 'Ограничение для ТЗ',
      'Vehicle saved': 'Т\\с сохранено',
      'Vehicle subtype': 'Подтип транспортного средства',
      'Vehicle subtypes': 'Подтипы транспортного средства',
      'Vehicle type': 'Тип транспортного средства',
      'Vehicle types': 'Тип ТС',
      'Vehicle updated': 'Т\\с обновлено',
      Vehicles: 'Транспортные средства',
      Verified: 'Проверено',
      'Vertical groups': 'Вертикальные группы',
      Vessel: 'Судно',
      'Vessel IMO': 'IMO судна',
      'Vessel capacity': 'Вместительность судна',
      'Vessel imo': 'ИМО судна',
      'Vessel info': 'Инфо по судну',
      'Vessel name': 'Название судна',
      'Vessel size': 'Размер судна',
      'Vessel size:': 'Размер судна:',
      'Vessel sizes': 'Размеры судна',
      'Vessel with such name and IMO already exists. Are you sure you want to save it?':
        'Судно с таким названием и ИМО уже существует. Вы уверены, что хотите его сохранить?',
      'Vessel year': 'Год судна',
      Vessels: 'Судна',
      'Vessels are loaded': 'Судна загружены',
      Vet: 'Вет',
      'Vet enterance': 'Вет внесение',
      'Vet required': 'Вет обяз',
      Virtual: 'Виртуальный',
      Visible: 'Видимый',
      Volume: 'Объем',
      'Volume (boarded)': 'Объем (погруж)',
      'Volume (departed consignment)': 'Объем (по ТТН)',
      'Volume (departed)': 'Объем (отправ)',
      'Volume (lost)': 'Объем (потер)',
      'Volume (real)': 'Объем (зачет)',
      'Volume (received)': 'Объем (получ)',
      'Volume (t)': 'Объем (т)',
      'Volume Boarded': 'Объем погруженный',
      'Volume Departed': 'Отправленный объем',
      'Volume Departed Real': 'Объем (отправленный)',
      'Volume Received': 'Полученный объем',
      'Volume With Docs (T)': 'Объем с доками (Т)',
      'Volume accepted': 'Принятый объём',
      'Volume accepted at unloading, mt': 'Объем принятый при разгрузке, т',
      'Volume and amounts': 'Объем и суммы',
      'Volume at end': 'Объем в конце',
      'Volume at start': 'Объем в начале',
      'Volume boarded': 'Объем погружен',
      'Volume boarded empty': 'Погруженный объем пустой',
      'Volume delivered, mt': 'Объем доставлен, т',
      'Volume departed': 'Объем отправленный',
      'Volume departed consignment': 'Объем (по ТТН)',
      'Volume departed real': 'Реальный объем отгрузки',
      'Volume departed, mt': 'Объем отправлен, т',
      'Volume discharged': 'Объем разгружен',
      'Volume discount': 'Дисконт по объему',
      'Volume discounted': 'Объем с дисконтом',
      'Volume estimated': 'Ожидаемый объем',
      'Volume in contract': 'Объем в контракте',
      'Volume in mt': 'Объем в тоннах',
      'Volume in positions from execution': 'Объем в позициях по исполнению',
      'Volume intake': 'Объем погрузки',
      'Volume intake sum': 'Объем погрузки',
      'Volume lost': 'Потерянный объем',
      'Volume max': 'Объем макс',
      'Volume min': 'Объем мин',
      'Volume must be greater than 0': 'Объем должен быть больше чем 0',
      'Volume of deal': 'Объем сделки',
      'Volume options': 'Опцион',
      'Volume per deal': 'Объем за сделку',
      'Volume per deal (forward)': 'Объем за сделку (форвард)',
      'Volume per deal (spot)': 'Объем за сделку (спот)',
      'Volume plan': 'Объем плана',
      'Volume received': 'Полученный объем',
      'Volume received, mt': 'Объем полученный, т',
      'Volume reconciliation': 'Сверка объемов',
      'Volume rest fact before CD, mt': 'Объем остаточного факта до CD, мт',
      'Volume rest plan before CD, mt': 'Объем остаточного плана до CD, мт',
      'Volume rest, mt': 'Оставшийся объем, т',
      'Volume sum from positions': 'Сумма объемов из позиций',
      'Volume without PCD': 'Объем без PCD',
      'Volume, $ with VAT': 'Стоимость, дол с НДС',
      'Volume, mt': 'Объем, т',
      'Volume/Goal': 'Объем/Цель',
      Volumes: 'Объемы',
      'Volumes by terminals': 'Объемы по терминалам',
      Vote: 'Голосовать',
      Voyage: 'Рейс',
      'Voyage name': 'Имя рейса',
      'Voyage status': 'Статус рейса',
      'Voyage status updated': 'Статус рейса обновлен',
      Voyages: 'Рейсы',
      'Voyages Analytics': 'Аналитика рейсов',
      W: 'Н',
      'W/O Broker': 'Без брокера',
      'W/o business unit': 'Без бизнес юнита',
      WAREHOUSE: 'СКЛАД',
      'WE BUY': 'МЫ ПОКУПАЕМ',
      'WE SELL': 'МЫ ПРОДАЕМ',
      Wagon: 'Вагон',
      Wait: 'Подождите',
      'Wait a moment...': 'Немножечко подождите...',
      Waiting: 'Ожидание',
      'Waiting for approval': 'Ожидает подтверждения',
      'Waiting my approval': 'Я еще не утвердил',
      Warehouse: 'Склад',
      'Warehouse Balance': 'Остаток на складе',
      'Warehouse List': 'Список складов',
      'Warehouse Loses': 'Потери склада',
      'Warehouse Losses': 'Склад потери',
      'Warehouse Rest': 'Остатки склада',
      'Warehouse Rests Report': 'Отчет о остатках на складе',
      'Warehouse Shipment': 'Отгрузки склад',
      'Warehouse Trade': 'Склад трейд',
      'Warehouse balance': 'Баланс склада',
      'Warehouse deleted': 'Склад удален',
      'Warehouse distances': 'Расстояния до складов',
      "Warehouse doesn't exist": 'Склад не существует',
      'Warehouse doesn`t exist': 'Склад не существует',
      'Warehouse name': 'Название склада',
      'Warehouse owner': 'Владелец склада',
      'Warehouse parent': 'Родительский склад',
      'Warehouse receipts': 'Складские квитанции',
      'Warehouse type': 'Склад тип',
      'Warehouse-Rest': 'Склад-Остатки',
      Warehouses: 'Склады',
      'Warehouses balance': 'Балан складов',
      'Warehouses list': 'Список складов',
      Warm: 'Теплый',
      'Warning: More than one task has the same sequence order.':
        'Предупреждение: несколько задач имеют одинаковый порядок последовательности.',
      Washout: 'Вошаут',
      We: 'Ср',
      Website: 'Вебсайт',
      Week: 'Неделя',
      'Weighing addresses': 'Адреса взвешивания',
      'When all the certification have been resolved': 'Когда все сертификаты исполнены',
      'When payment was actually made': 'Когда фактически была произведена оплата',
      'When you expect payment has actually been made': 'Когда ожидается платеж',
      'When you expect payment to be made': 'Когда платеж планируется быть исполненым',
      'When you have conducted money': 'Когда провелись деньги',
      'When you have issued invoice': 'Когда выставляется счет',
      'When you have received invoice': 'Когда получен счет',
      White: 'Белая',
      'Who issued invoice': 'Кто выставил инвойс',
      'Who receives invoice': 'Кто получает инвойс',
      'Whoose option': 'Чья опция',
      'Why am i seeing this': 'Почему я это вижу',
      'Will be change automatically, based on passport execution':
        'Будет изменен автоматически, на основании исполнения паспорта',
      'Will be change automatically, based on payment. New - no payment yet, Process - paid not in full, Paid - paid in full':
        'Будет изменен автоматически, на основе оплаты. Новый - нет оплаты еще, Процесс - оплачен не полностью, Оплачен - оплачен полностью',
      'Will be changed automatically based on configuration':
        'Будет изменяться автоматически на основе конфигурации',
      'Will be filled automatically, when you set cargo execution':
        'Будет заполнен автоматически, когда вы установите выполнение груза',
      'With Business Unit': 'С бизнес-юнитом',
      'With Passport': 'В Паспорте',
      'With VAT': 'С учетом НДС',
      'With buyer': 'С покупателем',
      'With buyers': 'С покупателями',
      'With contracts': 'С контрактами',
      'With contracts voyage': 'С рейсами по контрактам',
      'With currency exchange': 'С курсом',
      'With date confirmation': 'С датой подтверждения',
      'With date received': 'С датой получения',
      'With date request': 'С датой запроса',
      'With derivative': 'С деривативом',
      'With destination country': 'Есть страна назначения',
      'With disport': 'Есть порт назначения',
      'With exporters': 'С экспортерами',
      'With internal chain': 'Внутренняя цепочка',
      'With loadport': 'Есть порт отгрузки',
      'With mtm price': 'С мтм ценой',
      'With opposite price': 'Есть встречная цена',
      'With origin country': 'Есть страна отправки',
      'With payment delay': 'С задержкой платежа',
      'With ports': 'Есть порты',
      'With roles': 'С ролью',
      'With shipment terminal': 'С терминалом отгрузки',
      'With supplier': 'С поставщиком',
      'With suppliers': 'С поставщиками',
      'With voyage': 'С рейсом',
      Withdrawal: 'Списание',
      Without: 'Без',
      'Without VAT': 'Без НДС',
      'Without a status': 'Без статуса',
      'Without rate': 'Без ставки',
      Wizard: 'Мастер',
      Word: 'Слово',
      'Work in progress': 'Работа в процессе',
      'Working hours': 'Часы работы',
      'Works from': 'Работает с',
      'Works till': 'Работает до',
      'Write an email': 'Написать электронное письмо',
      'Write down the specific information  about this invoice':
        'Напишите специфическую информацию по этому счету',
      'Write down the specific information about this bank operation':
        'Напишите специфическую информацию по этой операции',
      'Write of volume': 'Объем списания',
      'Write some update here...': 'Напишите любое обновление здесь...',
      'Write-of volume balance': 'Открытый баланс к списанию',
      'Wrong crops': 'Разные культуры',
      'Wrong volume': 'Некорректный объем',
      YES: 'ДА',
      Year: 'Год',
      'Year %': 'Год %',
      'Year Budget': 'Годовой бюджет',
      'Year budget': 'Годовой бюджет',
      Years: 'Года',
      Yellow: 'Желтое',
      Yes: 'Да',
      Yesterday: 'Вчера',
      You: 'Вы',
      'You can click on buttons to display these objects inside passport.':
        'Вы можете нажать на кнопки, чтобы отобразить эти объекты внутри паспорта.',
      'You can create and apply your own statuses for passport':
        'Вы можете создавать и применять свои собственные статусы для паспорта',
      'You can directly go to a consolidated PNL report':
        'Вы можете сразу перейти к консолидированному отчету PnL',
      'You can open information about all passports that are inside certain cell.':
        'Можно открыть информацию обо всех паспортах, которые находятся внутри определенной ячейке.',
      'You can open the information about open deals with a position':
        'Вы можете открыть информацию об открытых сделках с позицией',
      'You can pick up multiple objects, clicking with CTRL':
        'Вы можете выбрать несколько объектов, зажав CTRL',
      'You can see the quantity by each grouping criteria and passport':
        'Вы можете увидеть количество по каждому критерию группировкии паспортом',
      'You can select multiple roles, pressing CTRL':
        'Вы можете выбрать несколько ролей, нажав CTRL',
      "You can't edit approved clients": 'Вы не можете редактировать подтвержденных контрагентов',
      "You can't vote for this object": 'Вы не можете голосовать за этот объект',
      'You do not have new notifications': 'У вас нет уведомлений',
      'You have a long position': 'У вас длинная позиция',
      'You have a short position': 'У вас короткая позиция',
      'You have voted for this object. Revote this?':
        'Вы проголосовали за этот объект. Переголосовать?',
      'You need to verify Google account': 'Вам нужно подтвердить свой Google аккаунт',
      'Your charges': 'Ваши затраты',
      'Your contacts': 'Ваши контракты',
      'Your contracts': 'Ваши контракты',
      'Your counterparties': 'Ваши контрагенты',
      'Your derivatives': 'Ваши деривативы',
      'Your indicators': 'Ваши индикаторы',
      'Your invoices': 'Ваши инвойсы',
      "Your last payment hasn't gone through.": 'Ваш последний платеж не прошел.',
      'Your logistics': 'Ваши логистика',
      'Your next approvals': 'Ваши следующие подтверждения',
      'Your passports': 'Ваши паспорта',
      'Your request to generate a bill of lading has been declined. The volume exceedsthe permissible limits. Please verify and enter the correct volume, taking intoaccount the tolerance.':
        'Ваш запрос на создание коносамента отклонен. Объем превышает допустимые пределы. Проверьте и введите правильный объем с учетом толеранса.',
      'Your tickets': 'Ваши тикеты',
      'Your updates': 'Ваши обновления',
      'Your vessels': 'Ваши судна',
      'a country': 'страна',
      'a district': 'район',
      'a size': 'размер',
      'a status': 'статус',
      'a supplier to which this Exporter is affiliated':
        'выберите Поставщика, с которым аффилирован данный Экспортер',
      'a type': 'тип',
      'access admin': 'вход в админ',
      'access rights': 'права доступа',
      account: 'счет',
      'account name': 'имя аккаунта',
      'account type': 'тип счета',
      actions: 'действия',
      active: 'активно',
      activity: 'активность',
      'actual cash amount': 'текущая сумма на счету',
      'actual cash amount, $': 'фактическая сумма средств, $',
      'actually shipped from Transport page': 'актуальные отгрузки по Реестру',
      add: 'добавить',
      'add another': 'добавить еще',
      'add costs': 'добавить затраты',
      'add stock': 'добавить запасы',
      'add stocks': 'добавить остатки',
      additional: 'дополнительно',
      'additional info': 'примечание',
      address: 'адрес',
      admin: 'админ',
      agent: 'посредник',
      agreement: 'договор',
      'all logistics': 'вся логистика',
      allocated: 'распределено',
      'allocated to this passport': 'соединено с паспортом',
      alt: 'альт',
      alternative: 'дополнительно',
      amount: 'сумма',
      'amount (dbl currency) to connect': 'сумма (валюта dbl) для подключения',
      'amount metric': 'метрика суммы',
      'amount to connect': 'сумма для присоединения',
      'amount type': 'тип суммы',
      'amount with vat': 'сумма с НДС',
      'amount, $': 'сумма, $',
      'analytic parent': 'аналитический родитель',
      analytical: 'аналитический',
      'analytical MTM margin': 'Аналитическая маржа MTM',
      'analytical amount': 'аналитическая сумма',
      'analytical amount <> amount': 'аналитическая сумма <> сумма',
      'analytical derivative margin': 'аналитическая маржа по деривативам',
      'analytical diff': 'аналитическая разница',
      'analytical physical margin': 'аналитическая физическая маржа',
      'analytical price': 'аналитическая цена',
      'analytical total margin': 'аналитическая общая маржа',
      'analytical value': 'аналитическая стоимость',
      and: 'и',
      'any of logistics': 'любая из логистик',
      apply: 'применить',
      approval: 'подтверждения',
      'approval config': 'конфигурация подтверждения',
      'approval configuration': 'конфигурация подтверждения',
      'approval status': 'статус подтверждения',
      approvals: 'подтверждения',
      approved: 'утверджено',
      'arrival end': 'прибытие конец',
      'arrival period': 'период прибытия',
      'arrival period end': 'период прибытия конец',
      'arrival period start': 'период прибытия начало',
      'arrival start': 'период прибытия',
      assignment: 'переназначение',
      'at port': 'в порту',
      'at road': 'в пути',
      attached: 'привязанные',
      author: 'автор',
      authors: 'авторы',
      autor: 'автор',
      available: 'доступно',
      'available for allocation': 'доступно для прикрепления',
      'available to connect': 'доступно к прикреплению',
      avg: 'срд',
      'avg charges': 'сред. затраты',
      'avg fixing': 'средний фиксинг',
      'avg hedging': 'среднее хеджирование',
      'avg price': 'сред. цена',
      balance: 'баланс',
      'balance payment': 'балансовая оплата',
      'balance to execute': 'баланс к исполнению',
      'balance with payment': 'баланс с оплатами',
      bank: 'банк',
      'bank account': 'банк счета',
      'bank accounts': 'банк счета',
      'bank branch': 'отделение банка',
      'bank details': 'банковские реквизиты',
      'bank name': 'название банка',
      bank_name: 'bank_name',
      'based on profit and loss by': 'основан на прибылях и убытках по',
      basis: 'базис',
      'basis doc': 'базис документ',
      'basis doc with copy': 'базовый документ с копией',
      'basis doc with file': 'базовый документ с файлом',
      'basis doc without copy': 'базовый документ без копии',
      'basis doc without file': 'базовый документ без файла',
      'basis of buyer contract': 'базис прод контракта',
      'basis of supplier contract': 'базис закуп контракта',
      basises: 'базисы',
      batch: 'партия',
      'before execution ends': 'до окончания исполнения',
      berthing: 'причаливание',
      'bill of lading': 'коносамент',
      'billoflading number': 'коносамент номер',
      birthday: 'день рождения',
      'black list': 'черный список',
      block: 'блок',
      blocked: 'заблокировано',
      boarded: 'погружено',
      borrowers: 'заемщики',
      broker: 'брокер',
      'broker freight costs': 'фрахт брокер затраты',
      brokerage: 'брокерская деятельность',
      budget: 'бюджет',
      'business date': 'бизнес дата',
      'business unit': 'бизнес-юнит',
      'business units': 'бизнес юниты',
      businessunit: 'бизнес-юнит',
      buyer: 'покупатель',
      'buyer balance invoices': 'выберите покупатель баланс инвойсы',
      'buyer contract': 'покупатель контракт',
      'buyer contract basis': 'покуп контракт базис',
      'buyer contracts': 'покуп. контракт',
      'buyer invoiced': 'покупателю выставлено инвойс',
      'buyer managers': 'менеджеры покупателя',
      'buyer paid': 'покупатель оплатил',
      'buyer prepay invoices': 'покупатель предоплатные инвойсы',
      'buyer to be invoiced': 'покупателю выставят',
      buyers: 'покупатели',
      by: 'by',
      'by commodities': 'по товарам',
      'by crop': 'по культуре',
      'by currencies': 'по валютам',
      'by default': 'по умолчанию',
      'by price': 'по цене',
      'by selected': 'по выбранным',
      calc: 'кальк',
      cancel: 'отмена',
      'cancel changes': 'отменить затраты',
      cancelled: 'отменено',
      'caontacts logistic prices': 'контракт логистика цены',
      capacity: 'вместительность',
      'capacity est': 'вместительность ожид',
      'capacity max': 'вместительность макс',
      'capacity min': 'вместительность мин',
      cargo: 'груз',
      'cargo brands': 'бренды груза',
      'cargo confirm': 'груз подтвержд',
      cargo_executed: 'cargo_executed',
      category: 'категория',
      cent: 'центы',
      'certificate scheme': 'схема сертификации',
      chain: 'цепочка',
      charge: 'затрата',
      charges: 'затраты',
      'charges per 1 mt': 'затраты за 1т',
      chart: 'график',
      charterer: 'Чартеродержатель',
      'choose commodity receiver': 'выбрать получателя товара',
      'choose commodity shipper': 'выбрать грузоотправителя',
      'choose exporter': 'выбрать экспортера',
      'choose file type': 'выберите тип файла',
      'choose one or multiple recipients': 'выберите одного или нескольких получателей',
      'choose producer': 'выбрать производителя',
      'choose shipment warehouse': 'выбрать склад отгрузки',
      'choose unloading warehouse': 'выбрать склад разгрузки',
      city: 'город',
      'click here to expand the list of contracts inside each passport':
        'нажмите здесь, чтобы расширить список контрактов внутри каждого паспорта',
      client: 'клиент',
      'client doc': 'документ контрагента',
      'client role from type': 'контрагент от кого',
      'client role name': 'роль контрагента',
      'client role to type': 'контрагент кому',
      'client role type': 'контрагент тип роли',
      'clientrole from': 'клиент от кого',
      'clientrole to': 'клиент кому',
      clients: 'клиенты',
      clone: 'клонировать',
      close: 'закрыть',
      'close window': 'закрыть окно',
      code: 'код',
      'coefficient of payments delay': 'коэффициент задержки платежей',
      collapse: 'свернуть',
      collection: 'коллекция',
      comment: 'коммент',
      comments: 'комментарии',
      'commisiton account': 'комиссия счета',
      commodities: 'товары',
      commodity: 'продукт',
      'commodity analytical parent': 'аналитический родитель товара',
      'commodity receiver': 'получатель товара',
      'commodity shipper': 'грузоотправитель',
      commodity_receiver: 'commodity_receiver',
      companies: 'компании',
      company: 'компания',
      'company group': 'группа компаний',
      'company groups': 'группы компаний',
      complete: 'завершить',
      completion: 'выпонение',
      'completion ratio': 'коэффициент выполнения',
      compliance: 'комплайенс',
      'compliance status': 'статус комплаенса',
      concluded: 'дата контр',
      conclusion: 'заключение',
      'conclusion date': 'дата заключения',
      'conclusion price': 'цена при подписании',
      condition: 'условие',
      conditions: 'условия',
      config: 'конфигурация',
      'confirmation date - the date when you confirmed the legal documents of this company':
        'дата подтверждения - дата когда вы подтвердили документы по компании',
      confirmations: 'подтверждения',
      confirmed: 'подтверждено',
      connect: 'связ объем',
      connected: 'связано',
      'connected to this passport': 'связанный с этим паспортом',
      connections: 'привязки',
      'consecutive voyages': 'последовательные рейсы',
      consignees: 'грузополучатели',
      consignment: 'накладные',
      'consignment contracts': 'выберите контракты накладных',
      'consignment elevators': 'элеваторы накладных',
      'consignment number': 'номер накладной',
      consignments: 'накладные',
      consolidated: 'консолидировано',
      contact: 'контакт',
      'contact info': 'контактная информация',
      contacts: 'контакты',
      continue: 'продолжить',
      contract: 'контракт',
      'contract avg charges': 'средние затраты по контракту',
      'contract business unit': 'бизнес юнит контракта',
      'contract buyer': 'покупатель контракта',
      'contract charges/gains': 'контракт Затраты / Доходы',
      'contract commodity': 'товар контракта',
      'contract counterparty': 'контрагент контракта',
      'contract custom status': 'пользовательские статусы контрактов',
      'contract incoterms': 'базис контракта',
      'contract is confirmed by cargo': 'контракт подтвержден по отгрузке',
      'contract number': 'номер контракта',
      'contract option': 'контракт опция',
      'contract passport': 'паспорт контракта',
      'contract payment conditions': 'условия оплаты контракт',
      'contract price': 'цена контракта',
      'contract status': 'статус контракта',
      'contract supplier': 'поставщик контракта',
      'contract total charges': 'общие затраты по контракту',
      'contract type': 'тип контракта',
      'contract value': 'сумма контракта',
      'contract vat return value': 'сумма возврата ндс по контракту',
      'contract volume': 'объем контракта',
      contracts: 'контракты',
      'contracts avg price': 'средняя цена контрактов',
      'contracts balance payment': 'остаток по оплате контрактов',
      'contracts facts exceed contract volume': 'факты по контрактам превышают объем контракта',
      'contracts passport custom status': 'специальный статус паспорта контрактов',
      'contracts passport vessel': 'паспорт контракта для судна',
      'contracts passport voyage': 'паспорт контракта для рейса',
      'control margin': 'контроль маржи',
      controlled: 'проконтролировано',
      'controlled by': 'контролируется',
      'converted price': 'конвертированная цена',
      copy: 'копия',
      correspondent: 'корреспондент',
      'cost of goods sold': 'себестоимость',
      costs: 'затраты',
      'costs charge': 'начисление затрат',
      'costs distribution': 'распределение затрат',
      'costs from purchase contracts of this passport':
        'затраты из закупочных контрактов под этот паспорта',
      'costs from sale contracts of this passport':
        'затраты из продажных контрактов под этот паспорта',
      'costs from this passport': 'затраты по этому паспорту',
      'costs invoices': 'инвойсы на затраты',
      'costs number': 'номер затрат',
      'costs opposite': 'встречные затраты',
      'costs vat return value': 'сумма возврата ндс по затратам',
      count: 'счетчик',
      counterparties: 'контрагенты',
      counterparty: 'контрагент',
      'counterparty amount': 'сумма контрагента',
      'counterparty bank account': 'банковский счет контрагента',
      'counterparty payment info': 'платежная информация контрагента',
      country: 'страна',
      'create template': 'создать шаблон',
      'create time': 'время создания',
      'create user': 'создать пользователя',
      created: 'создано',
      'created from': 'создано с',
      creation: 'создание',
      'creation date': 'дата создания',
      creator: 'создатель',
      credit: 'кредит',
      'credit accounts': 'кредит аккаунты',
      'credit counterparty': 'кредит контрагента',
      'credit notes': 'кредит ноут',
      creditor: 'кредитор',
      critical: 'критично',
      crop: 'культура',
      'crop class': 'класс культуры',
      'crop protection': 'защита урожая',
      'crop protection amount': 'защита урожая сумма',
      'crop year': 'год культуры',
      crops: 'культуры',
      'ctr price': 'цена',
      currencies: 'валюты',
      currency: 'валюта',
      'currency & Exchange Rate': 'валюта и обменный курс',
      'currency exchange': 'обменный курс',
      'currency rate': 'курс валют',
      current: 'текущее',
      'current delay, h': 'текущая задержка, часы',
      'current position': 'текущая позиция',
      'custom clientrole': 'пользовательская роль',
      'custom status': 'пользовательский статус',
      customs: 'таможня',
      'customs declarations': 'таможенные декларации',
      'data checked': 'данные проверены',
      date: 'дата',
      'date filters': 'выберите диапазон дат',
      'date joined': 'дата создания',
      'date of execution opposite': 'дата исполнения встречная',
      'date range while the proposition is actual': 'период в который данное предложение актуально',
      dates: 'даты',
      day: 'день',
      days: 'дни',
      'days of payments delay': 'дней задержки',
      'days to load': 'дней на загрузку',
      'deal type': 'тип сделки',
      'debit accounts': 'дебет счета',
      'debit counterparty': 'дебет контрагента',
      'debit/credit': 'дебет/кредит',
      debitor: 'дебитор',
      delay: 'задержка',
      delete: 'удалить',
      'delete a transfer': 'удалить перевалку',
      'delete agreement': 'удалить договор',
      'delete collection': 'коллекцию удалено',
      'delete consignment': 'удалить накладную',
      'delete item': 'удалить элемент',
      'delete logistic': 'удалить логистику',
      'delete permanently': 'удалить навсегда',
      'delete report congif': 'удалить конфигурацию отчета',
      delivered: 'доставлено',
      delivery: 'доставка',
      'delivery condition': 'вид транспортировки',
      'delivery conditions': 'условия поставки',
      'delivery period': 'период поставки',
      delivery_period: 'delivery_period',
      'demurrage rate': 'демерредж ставка',
      departed: 'отправлено',
      'departure stations': 'станции отправления',
      derivative: 'дериватив',
      'derivative account': 'аккаунт дериватива',
      'derivative cargo': 'культура деривативов',
      derivatives: 'деривативы',
      'derivatives margin': 'маржа деривативов',
      dest: 'пункт назначения',
      destination: 'назначение',
      'destination 1st port': 'порт назначения 1-й',
      'destination 2nd port': 'порт назначения 2-й',
      'destination country': 'страна назначения',
      'destination of crop': 'назначение культуры',
      'destination port': 'порт назначения',
      'destinations of crop': 'пункты назначения урожая',
      details: 'детали',
      'details of the profit and loss of': 'информация о прибылях и убытках',
      'df costs': 'мф затраты',
      'df quantity': 'мф объем',
      'did not happen': 'не произошло',
      'diff price': 'разница в цене',
      'diff value': 'разница в стоимости',
      diffs: 'различия',
      'disbursement bill of lading': 'расходная накладная',
      'disbursement bl': 'расходная накладная',
      disbursementBL: 'расходная накладная',
      disbursementbl: 'расходная накладная',
      'disch rate': 'норма выгрузки',
      'discharge approval volume': 'объем утвержденной разгрузки',
      'discharge dem/dis': 'отгрузка дем/дис',
      'discharge rate': 'норма разгрузки',
      'discharge volume': 'объем разгрузки',
      'discharge window': 'отгрузка окно',
      discharged: 'выгружено',
      discount: 'дисконт',
      'discount amount': 'значение дисконта',
      'discount mt': 'дисконт за тонну',
      'discount value': 'Размер скидки',
      'dispatch rate': 'диспач ставка',
      'disponent owner': 'диспонент-владелец',
      disport: 'порт назначения',
      'distribute costs': 'распределить затраты',
      'distribution base': 'база распределения',
      'distribution by cargo': 'распределение грузов',
      distributor: 'дистрибьютор',
      distributors: 'дистрибьюторы',
      district: 'район',
      docs: 'доки',
      'docs status': 'статус документов',
      document: 'документ',
      documents: 'документы',
      done: 'готово',
      'download all': 'скачать всё',
      'download docx': 'загрузить docx',
      'download pdf': 'загрузить pdf',
      'due date': 'до дат',
      edit: 'Редактировать',
      'edit a transfer': 'редактировать перевалку',
      'edit changes': 'редактировать затраты',
      'edit contract': 'редктировать контракт',
      editor: 'редактор',
      editors: 'редакторы',
      elevator: 'элеватор',
      'elevator (with consignment)': 'элеватор (по накладной)',
      'elevator/farm': 'элеватор/хозяйство',
      elevators: 'элеваторы',
      email: 'e-mail',
      'email.mail.com': 'email.mail.com',
      emails: 'e-mails',
      'empty file': 'пустой файл',
      end: 'конец',
      'end balance': 'Конечный баланс',
      'end date': 'дата окончания',
      'end of execution': 'конец исполнения',
      eng: 'англ',
      est: 'ожид',
      'est.': 'ожид.',
      'estimated lots': 'количество лотов',
      'estimated volume': 'ожидаемый объем',
      eta: 'eta',
      etb: 'etb',
      etc: 'etc',
      ets: 'ets',
      event: 'событие',
      events: 'события',
      'events by the system': 'системные события',
      excel: 'excel',
      'exchange fact': 'обмен факт',
      'exchange plan': 'обменный курс план',
      'exchange rate': 'обменный курс',
      'exec forecast amount <> invoiced amount': 'ожидаемая сумма <> сумма выставленного инвойса',
      'exec forecast amount <> payment amount': 'ожидаемая сумма <> сумма оплаты',
      executed: 'выполнено',
      'executed export volume': 'выполненный экспортный объем',
      execution: 'исполнение',
      'execution amount': 'сумма по исполнению',
      'execution month': 'месяц исполнения',
      'execution period': 'период исполнения',
      'execution price': 'цена исполнения',
      'existing vessel': 'существующее судно',
      expense: 'Общие затраты',
      expenses: 'затраты',
      export: 'экспорт',
      'export / import data': 'экспорт / импорт данных',
      'export charges': 'экспортные затраты',
      'export contract': 'экспортный контракт',
      'export contracts': 'экспортные контракты',
      exporter: 'экспортер',
      'exporter contract': 'экспортер контракт',
      exporters: 'экспортеры',
      fact: 'факт',
      'fact distributors': 'фактические дистрибьюторы',
      'fact suppliers/distributors': 'фактические поставщики/дистрибьюторы',
      fail: 'неудача',
      'fail or did not happen': 'неудача или не произошло',
      failed: 'Неуспешно',
      'failure reason': 'причина отказа',
      farm: 'хозяйство',
      'farm (with consignment)': 'хозяйство (по накладной)',
      farms: 'хозяйства',
      'few logistics': 'несколько логистик',
      fields: 'поля',
      'file name': 'имя файла',
      'fill by template': 'Заполнить по шаблону',
      filter: 'фильтр',
      'filter invoices to send': 'фильтровать инвойсы для отправки',
      'filter transport to send': 'фильтровать транспорт для отправки',
      'fin purposes': 'выбрать цели',
      final: 'финал',
      'final amount': 'финальная сумма',
      'final amount <> amount': 'финальная сумма <> сумма',
      'final amount <> invoiced': 'финальная сумма <> проинвойсированно',
      'final buyer': 'окончательный покупатель',
      'final confirm': 'окончательное подтвержд',
      'final volume': 'финальный объем',
      'final volume of the contract': 'финальный объем по этому контракту',
      'final volume of this contract': 'финальный объем по этому контракту',
      'finance credit account': 'финансовый кредитный счет',
      'finance debit account': 'Финансовый дебетовый счет',
      'finance type': 'Тип финансирования',
      finance_account_title: 'finance_account_title',
      'financing - estimated opposite payment date':
        'финансирование - предполагаемая дата противоположного платежа',
      'financing - interest rate': 'Финансирование - процентная ставка',
      'financing - loan percentage': 'Финансирование - процент по кредиту',
      'financing - payment days': 'Финансирование - сроки оплаты',
      'first confirmation date - the date when you first confirmed the legal documents of this company':
        'дата первого подтверждения - дата когда вы впервые подтвердили документы по компании',
      'first contact = the date, when this company was added to the system':
        'первый контакт - дата, когда эта компания была добавлена в систему',
      'first name': 'имя',
      'first_contact was automatically generated': 'первый контакт автоматически сгенерирован',
      'fixed price bonus': 'фиксированный бонус за ценой',
      fixing: 'фиксинг',
      flag: 'флаг',
      'folder number': 'номер папки',
      for: 'для',
      'for limits': 'для лимитов',
      forward: 'форвард',
      forwarder: 'перевозчик',
      'forwarder contracts': 'Экспедиторские контракты',
      forwarders: 'экспедиторы',
      found: 'найдено',
      free: 'свободно',
      'free export volume': 'свободный объем экспортный',
      freight: 'фрахт',
      'freight costs': 'фрахт затраты',
      'freight opposite': 'встречный фрахт',
      from: 'от кого',
      'from company': 'компанию от кого',
      'from each ticket of chosen passport we will create a contract and attach it\n                        to the passport automatically':
        'из каждого тикета выбранного паспорта мы создадим контракт и добавим его\n                        в паспорт автоматически',
      'full diff': 'все изменения',
      'full invoiced': 'Полностью проинвойсировано',
      'full paid': 'полностью оплачено',
      'fully connected to passports': 'полностью привязано к паспорту',
      'future contact': 'следующий контакт',
      'future contact = the date, when you need to contact this company later (can be set while editing company':
        'следующий контакт - дата, когда нужно связаться с этой компанией повторно (можно указать при редактировании компании)',
      'futures position': 'фьючерс позиция',
      gains: 'поступления',
      'general agreement': 'рамочный договор',
      'general agreements': 'рамочные договора',
      'general expense': 'Общие затраты',
      'general expenses': 'общие затраты',
      generalexpenses: 'Общие затраты',
      'give the name to your collection to find it on the Download tab':
        'назовите коллекцию так, чтобы потом найти ее на вкладке Загрузить',
      'go to details': 'перейти к деталям',
      'go to the page of the item': 'перейдите на страницу элемента',
      'group by': 'группировка по',
      grouping: 'Группировка',
      groups: 'группы',
      guarantor: 'гарант',
      ha: 'га',
      'has logistics': 'есть логистика',
      'has negative balance': 'есть негативный баланс',
      'has planned costs': 'имеет запланированные затраты',
      'has purchase contract': 'имеет закупочный контракт',
      'has sale contract': 'имеет продажный контракт',
      hectares: 'гектары',
      hedging: 'хеджирование',
      "here are shown all actions you've done in the system":
        'здесь показаны все действия, которые вы совершили в системе',
      'here are shown all charges you created': 'здесь показаны все созданные вами платежи',
      'here are shown all contacts you created': 'здесь показаны все созданные вами контакты',
      'here are shown all contracts you created': 'здесь показаны все созданные вами контракты',
      'here are shown all counterparties you created':
        'здесь показаны все созданные вами контрагенты',
      'here are shown all documents that were uploaded in counterparties, contracts,\n                  invoices, passports':
        'здесь показаны все документы, которые были загружены в контрагенты, контракты, инвойсы, паспорта',
      'here are shown all indicators you created': 'здесь показаны все созданные вами индикаторы',
      'here are shown all invoices you created': 'здесь показаны все созданные вами инвойсы',
      'here are shown all objects where you are next approver':
        'здесь показаны все объекты, где вы находитесь в качестве следующего утверждающего',
      'here are shown all operations you created': 'здесь показаны все созданные вами операции',
      'here are shown all passports you assigned as responsible for':
        'здесь показаны все паспорта, которые вы назначили ответственными за',
      'here are shown all suppliers, buyers, brokers, farms, and others, already grouped by\n                their roles':
        'здесь показаны все поставщики, покупатели, брокеры, фермы и другие, уже сгруппированные по \n                их ролям',
      'here are shown all tickets you created': 'здесь показаны все созданные вами тикеты',
      'here are shown all updates you are responsible for':
        'здесь показаны все обновления, за которые вы несете ответственность',
      'here are shown all vehicles you created':
        'здесь показаны все созданные вами транспортные средства',
      'here are shown all vessels you created': 'здесь показаны все созданные вами судна',
      'hidden from freight': 'Скрыто от фрахта',
      hide: 'спрятать',
      'hide executed': 'спрятать выполненные',
      high: 'высокий',
      history: 'история',
      hours: 'часы',
      'https://website.com': 'https://website.com',
      iban: 'iban',
      'if there is more than one notify party': 'если имеется более чем одна уведомляющая сторона',
      'if there is more than two notify party': 'если имеется более двух уведомляющих сторон',
      'if you want to see more info about certain user press on his name':
        'если вы хотите увидеть больше информации об определенном пользователе нажмите на его имя',
      imo: 'имо',
      'implied freight level': 'прогнозированная стоимость фрахта',
      import: 'импорт',
      'import data': 'импорт дата',
      'import google drive': 'Импортировать Google Drive',
      important: 'важно',
      'inactive<translate></translate>': 'неактивный<translate></translate>',
      'inc inv qty': 'inc inv qty',
      incoming: 'входящие',
      'incoming balance': 'входящий баланс',
      incoterms: 'инкотермс',
      indicator: 'индикатор',
      'individual entrepreneur': 'индивидуальный предприниматель',
      info: 'информация',
      initial: 'первоначальное',
      'initial cash amount': 'первоначальная сумма',
      'initial cash amount, $': 'Начальная сумма наличных, $',
      'initial date': 'первоначальная дата',
      'initialization date': 'дата инициализации',
      initiator: 'Инициатор',
      'inline edit': 'внесение изменений на месте',
      insured: 'Застрахованный',
      insurer: 'страховая',
      intake: 'загрузка',
      'interest rate delay': 'задержка процентной ставки',
      interest_rate: 'interest_rate',
      intermediate: 'промежуточный',
      'intermediate charges': 'Промежуточные затраты',
      'intermediate contracts': 'промежуточные контракты',
      'intermediate logistics delivery costs': 'пром логистика затраты на доставку',
      'internal purchases': 'внутренние закупки',
      'internal volume': 'внутренний объем',
      invoice: 'инвойс',
      'invoice (R/W)': 'инвойс (жд)',
      'invoice (auto)': 'инвойс (авто)',
      'invoice bank account': 'банковский счет инвойса',
      'invoice client from': 'выберите контрагент от',
      'invoice client to': 'контрагент от',
      'invoice from us': 'инвойсы от нас',
      'invoice number': 'номер инвойса',
      'invoice position': 'инвойс-позиция',
      'invoice positions': 'инвойс-позиции',
      'invoice price': 'цена инвойса',
      'invoice to us': 'инвойсы нам',
      'invoice type': 'тип инвойса',
      invoiced: 'выставлено',
      'invoiced <> amount': 'проинвойсированно <> сумма',
      'invoiced <> paid': 'проинвойсировано <> оплачено',
      'invoiced cancelled': 'выставление инвойса отменено',
      'invoiced logistics': 'инвойсированные логистики',
      invoices: 'инвойсы',
      'is active': 'активен',
      'is gain': 'это доход',
      'is sap registered': 'sap зарегистрировано',
      'is staff': 'персонал',
      'is superuser': 'суперпользователь',
      'is tax return registered': 'зарегистрирован возврат налогов',
      'issuance fee': 'комиссия за відачу',
      km: 'км',
      language: 'язык',
      'last 10 days': 'последние 10 дней',
      'last 30 days': 'последние 30 дней',
      'last 7 days': 'последние 7 дней',
      'last approve time': 'время последнего подтверждения',
      'last approver': 'последний подтверждающий',
      'last login': 'последний вход',
      'last month': 'прошлый месяц',
      'last name': 'фамилия',
      'last operation amount': 'сумма последней операции',
      'last operation date': 'дата последней операции',
      'last operation type': 'тип последней операции',
      'last update': 'посл обнов',
      'last updated': 'обновлено',
      'last week': 'на прошлой неделе',
      'legal entity': 'юридическое лицо',
      lenders: 'заемщики',
      list: 'список',
      load: 'загрузка',
      'load into a container': 'погрузка в контейнер',
      'load rate': 'норма погрузки',
      'loading 1st port': 'порт загрузки 1-й',
      'loading 2nd port': 'порт загрузки 2-й',
      'loading date': 'дата погрузки',
      'loading dem/dis': 'отгрузка дем/дис',
      'loading option': 'опция отгрузки',
      'loading port': 'порт отгрузки',
      'loading window': 'загрузочное окно',
      loadport: 'порт отгрузки',
      loans: 'займы',
      local: 'локал',
      location: 'местонахождение',
      logistic: 'логистика',
      'logistic executed volume': 'выполненный объем логистики',
      'logistic type': 'Тип логистики',
      'logistic(s) have been successfully linked to the sales contract':
        'логистика была успешно связана с продажным контрактом',
      logistic_type: 'logistic_type',
      logistics: 'логистика',
      'logistics by terminal': 'логистика на терминале',
      'logistics to invoice by terminal': 'логистика для инвойсирования на терминале',
      'logistics total value': 'логистика стоимость',
      'logistics with single purchase contract': 'логистика с единственным закупочным контрактом',
      long: 'длинная',
      'long net pnl mtm': 'длинный чистый pnl mtm',
      lots: 'лоты',
      low: 'низкий',
      'loyalty program': 'программа лояльности',
      'loyalty program from contract': 'программа лояльности из контракта',
      'loyalty program from disbursement bill of lading':
        'программа лояльности из расходной накладной',
      mailings: 'рассылки',
      main: 'главная',
      'main contact': 'основной контакт',
      'make document': 'создать документ',
      manager: 'менеджер',
      managers: 'менеджеры',
      'managing company': 'управляющая компания',
      'manual enter': 'ручной ввод',
      margin: 'маржа',
      'margin = value of sale contracts - value of purchase contracts  - all costs':
        'маржа = стоимость продажных контрактов - стоимость закупочных контрактов - все затраты',
      'margin = value of sale contracts - value of purchase contracts  - all costs + vat * return rate':
        'маржа = стоимость продажных контрактов - стоимость закупочных контрактов - все затраты + ндс * ставка возврата',
      'margin by month': 'маржа по месяцам',
      'margin selector': 'переключатель маржи',
      'margin with MTM': 'маржа с учетом MTM',
      'marine traffic': 'marine traffic',
      'mark as read': 'Отметить как прочитанное',
      'mark to market': 'рынок',
      'market zone': 'маркет зона',
      'matched + open net pnl': 'совпадение + открытый чистый PnL',
      'matched contract average purchase price':
        'Сопоставленная средняя закупочная цена по контракту',
      'matched contract average sale price': 'средняя цена продажи по согласованному контракту',
      'matched contract comm fees': 'сравненные комиссии контракта',
      'matched contract long': 'согласованный контракт длинный',
      'matched contract short': 'согласованный контракт короткий',
      'matched net pnl': 'совпадающий чистый PnL',
      max: 'макс',
      measurement: 'ед. измерения',
      'measurement price': 'цена измерения',
      medium: 'средний',
      message: 'сообщение',
      min: 'мин',
      'min payment / not invoiced': 'минимальный платеж / не выставленный инвойс',
      'min/max': 'мин/макс',
      month: 'месяц',
      mt: 'мт',
      mtm: 'отношение к рынку',
      'mtm costs': 'мтм затраты',
      'mtm dtd price': 'mtm dtd цена',
      'mtm dtd value': 'mtm dtd стоимость',
      'mtm freight': 'MTM фрахт',
      'mtm ltd price': 'mtm ltd цена',
      'mtm ltd value': 'mtm ltd стоимость',
      'mtm margin': 'мтм маржа',
      'mtm passport margin': 'маржа по паспорту MTM',
      'mtm per 1mt': 'оценка к рынку за 1т',
      'mtm price': 'оценка к рынку цена',
      'mtm volume': 'мтм объем',
      'multi contracts': 'мультиконтракты',
      'multi services': 'мультиуслуги',
      multicontract: 'мультиконтракт',
      'multicontract No.': 'мультиконтракт No.',
      'multicontract purchase': 'мультиконтракт закупка',
      'multicontract sale': 'мультиконтракт продажа',
      multipassport: 'мультипаспорт',
      multirequest: 'мультитикет',
      multiticket: 'мультитикет',
      mv: 'лодка',
      name: 'имя',
      new: 'новый',
      'new - is just created mailing, but not sent. sent - is sent. progress - we\n                        are sending it just now. failed - is failed to be sent.':
        'новый – это только что созданная рассылка, но не отправленная. отправлено – это отправлено. прогресс -\n                        мы отправляем его сейчас. неудача – не удалось отправить.',
      'new Cashflow': 'новый Кеш-фло',
      'new template': 'новый шаблон',
      'next approver': 'следующий подтверждающий',
      'next approvers': 'следующие подтверждающие',
      'no customs logistics': 'нет таможенной логистики',
      'no data': 'нет данных',
      'no elevator': 'нет элеватора',
      'no farm': 'нет хозяйства',
      'no forwarder': 'нет экспедитора',
      'no port': 'нет порта',
      'no position': 'нет позиции',
      'no purchase contracts attached': 'не заключены закупочные контракты',
      'no purchase tickets attached': 'закупочные тикеты не прикреплены',
      'no sale contracts attached': 'не заключены продажные контракты',
      'no sale tickets attached': 'продажные тикеты не прикреплены',
      'no station dep': 'нет станций отправки',
      'no station rec': 'нет станций получения',
      'no status': 'без статуса',
      'no vessel': 'нет судна',
      'no volume': 'нет объема',
      'normal write off coeff': 'нормальный коэфф. списания',
      'not allocated': 'не распределено',
      'not done': 'не выполнено',
      'not insured': 'не застраховано',
      'not invoiced': 'не инвойсировано',
      'not signed': 'не подписано',
      note: 'примечание',
      notes: 'примеч',
      notice: 'примечание',
      number: 'номер',
      object: 'объект',
      'object title': 'название объекта',
      'object type': 'объект тип',
      'of counterparties': 'контрагентов',
      'of the': 'из',
      offset: 'зачет',
      'offset minus': 'офсет минусовой',
      'offset plus': 'офсет позитивный',
      on: 'про',
      'on a page': 'на странице',
      'on behalf of': 'от имени',
      'on road': 'в пути',
      'one counterparty can have several roles, e.g, if you have sales and purchases with\n                one company, you should create one company and assign it with both roles (supplier\n                and buyer)':
        'один контрагент может иметь несколько ролей, например, если у вас есть продажи и закупка с\n                одной компании, вы должны создать одну компанию и назначить ей обе роли (поставщик\n                и покупателя)',
      'one side': 'одна сторона',
      open: 'открыто',
      'open balance': 'открытый баланс',
      'open contract': 'открытый контракт',
      'open contracts': 'открытые контракты',
      'open position': 'открытая позиция',
      'open positions': 'открытые позиции',
      'open the modal window for editing of the item':
        'открыть модальное окно для редактирования элемента',
      'open volume': 'открытый объем',
      operation: 'операция',
      opposite: 'встречная',
      'opposite basis costs': 'встречный базис расходов',
      'opposite direct costs': 'противоположные прямые затраты',
      'opposite freight level': 'встречный фрахт',
      'opposite matched average purchase price': 'встречно подобранная средняя цена закупки',
      'opposite matched average sale price': 'встречно подобранная средняя цена продажи',
      'opposite matched comm fees': 'встречно подобранные комиссии',
      'opposite matched long': 'противоположное совпадающее long',
      'opposite matched short': 'противоположное совпадающее short',
      'opposite passport contract costs': 'встречные затраты контракта паспорта',
      'opposite price': 'встречная цена',
      'opposite total value costs': 'встречная общая сумма затрат',
      'opposite value': 'встречная сумма',
      'opposite volume': 'встречный объем',
      option: 'опция',
      'or select manually': 'или выбрать вручную',
      'or you can': 'или вы можете',
      ordering: 'сортировка',
      origin: 'происхождение',
      'origin country': 'страна отправки',
      'origin district': 'происхождение район',
      'origin of crop': 'происхождение',
      'origin region': 'происхождение область',
      original: 'оригинал',
      'original available': 'есть оригинал',
      origination: 'происхождение',
      'origins of crop': 'происхождение товара',
      other: 'другое',
      'other activites': 'другие назначения',
      'other activities': 'другие назначения',
      otheractivity: 'другая деятельность',
      'our amount': 'наша сумма',
      'our amount <> amount': 'наша сумма <> сумма',
      'out inv qty': 'out inv qty',
      outgoing: 'исходящие',
      'outgoing balance': 'исходный баланс',
      owner: 'владелец',
      'owner name': 'имя владельца',
      owners: 'владельцы',
      'packing size': 'размер упаковки',
      paid: 'оплачено',
      'paid (plan)': 'оплачено(план)',
      'paid <> amount': 'оплачено <> сумма',
      'paid date': 'дата оплаты',
      'paper trade': 'бумажная торговля',
      parameters: 'параметры',
      'parent cargo': 'родительская культура',
      'parent charge': 'родительская затрата',
      'parent indicator': 'родительский индикатор',
      'parent indicator price': 'цена родительского индикатора',
      'parse active quotes': 'парсинг активных котировок',
      passport: 'паспорт',
      'passport MTM': 'паспорт MTM',
      'passport approval status': 'статус подтверждения паспорта',
      'passport basis costs': 'встречные затраты паспорта',
      'passport business date': 'бизнес дата паспорта',
      'passport business unit': 'бизнес юнит паспорта',
      'passport charges': 'затраты паспорта',
      'passport commodity': 'паспорт груз',
      'passport contract costs': 'затраты контракта паспорта',
      'passport contracts': 'контракты паспорта',
      'passport costs': 'затраті паспорта',
      'passport custom status': 'пользовательский статус паспорта',
      'passport execution': 'исполнение паспорта',
      'passport finance': 'финансы паспорта',
      'passport number': 'номер паспорта',
      'passport season': 'паспорт сезон',
      'passport status': 'паспорт статус',
      'passport vessel': 'паспорта судна',
      'passport voyage': 'паспорт рейса',
      passports: 'паспорта',
      'passports custom status': 'пользовательские статусы паспортов',
      payment: 'оплата',
      'payment bank account': 'банковский счет платежа',
      'payment commission': 'комиссия за оплату',
      'payment condition': 'условия платежа',
      'payment condition option': 'опция условия оплаты',
      'payment conditions': 'условия платежа',
      'payment confirm': 'платеж подтвержд',
      'payment date': 'дата платежа',
      'payment date (fact)': 'дата платежа (факт)',
      'payment date (plan)': 'дата платежа (план)',
      'payment days': 'дни оплаты',
      'payment delay': 'задержка платежа',
      'payment info': 'информация об оплате',
      'payment type': 'тип оплаты',
      'payment type: {[{detail_row.payment_type}]}': 'тип оплаты: {[{detail_row.payment_type}]}',
      payments: 'платежи',
      pdf: 'pdf',
      pending: 'в процессе',
      per: 'за',
      'per 1': 'за 1',
      'per 1 mt': 'за 1 т',
      'per 1t': 'за 1 т',
      'per lot': 'за лот',
      'per one': 'за сделку',
      'per one deal': 'за сделку',
      'percent conditions': 'процентные условия',
      percentage: 'процент',
      period: 'период',
      periodic: 'периодический',
      permissions: 'полномочия',
      phone: 'телефон',
      'phone number': 'телефонный номер',
      'physical person': 'Физическое лицо',
      picture: 'аватар',
      'place of shipment / unloading': 'место отгрузки/разгрузки',
      plan: 'план',
      'plan distributors': 'плановые дистрибьюторы',
      port: 'порт',
      'port destination': 'порт назначения',
      'port destination country': 'страна порта назначения',
      'port loading': 'порт отгрузки',
      'port loading country': 'страна порта погрузки',
      ports: 'порты',
      'ports opposite': 'порты встречные',
      position: 'позиция',
      'position concluded': 'позиция завершена',
      'position number': 'номер позиции',
      'position statuses': 'статус позиции',
      'positons custom status': 'кастомный статус позиции',
      'positons season': 'сезон позиции',
      'pre PCD logistic volume': 'объем логистики до ПТД',
      'pre advice': 'преадвайс',
      'prefix for outgoing invoices': 'префикс № для исходящих инвойсов',
      premium: 'премиум',
      'premium price': 'премиум цена',
      prepay: 'предоплата',
      prepayment: 'предоплата',
      preview: 'предварительный просмотр',
      price: 'цена',
      'price create': 'цена создана',
      'price for current vehicle differs to contract':
        'цена за текущее транспортное средство отличается от контрактной',
      'price from transshpment from cars': 'цена от перевалки из пром логистики',
      'price includes VAT': 'цена включает НДС',
      'price of current deal + its cost + your yield (set in settings) = the sale price by which this deal should be sold to satisfy the requirements of your yield':
        'цена текущей сделки + ее затраты + ваша рентабельность (задается в настройках) = такой должна быть закупочная цена для этой продажи, чтобы удовлетворить требованиям рентабельности',
      'price of current deal + its costs = the real costs of the deal':
        'цена текущий сделки + ее затраты = реальная стоимость сделки',
      'price of current deal - its cost - your yield (set in settings) = the purchase price by which this deal should be purchased to satisfy the requirements of your yield':
        'цена текущей сделки - ее затраты - ваша рентабельность (задается в настройках) = такой должна быть продажная цена для этой закупки, чтобы удовлетворить требованиям рентабельности',
      'price of current deal - its costs = the real income of  the deal':
        'цена текущей сделки - ее затраты = реальный доход от сделки',
      prices: 'цены',
      print: 'напечатать',
      process: 'процесс',
      producer: 'производитель',
      producers: 'производители',
      product: 'продукт',
      'production year': 'год производства',
      'profit and loss': 'прибыль и потери',
      progress: 'прогресс',
      property: 'показатель',
      'protein * volume': 'протеин * объем',
      purchase: 'закупка',
      'purchase Contr. No.': 'закупочный контракт №.',
      'purchase Contr. Volume, UAH': 'сумма закупочного контракта, UAH с НДС',
      'purchase Contr. Volume, mt': 'объем (т) закупочного контракта',
      'purchase Contract Date': 'дата',
      'purchase basis': 'базис закупки',
      'purchase contract': 'закупочный контракт',
      'purchase contract author': 'Автор закупочного контракта',
      'purchase contract basis': 'базис закупочного контракта',
      'purchase contract buyer': 'покупатель закупочного контракта',
      'purchase contract custom status': 'пользовательский статус закупочного контракта',
      'purchase contract number': 'номер закупочного контракта',
      'purchase contract payment condition option': 'опция условия оплаты закупочного контракта',
      'purchase contract responsible': 'закупочный контракт ответственный',
      'purchase contract supplier': 'поставщик закупочного контракта',
      'purchase contracts': 'закупочные контракты',
      'purchase contracts logistics buyer invoices':
        'инвойсы покупателя логистики закупочного контракта',
      'purchase destinations': 'назначения закупки',
      'purchase invoice prepay': 'предоплата по инвойсу на закупку',
      'purchase origins': 'происхождение закупки',
      'purchase sap order': 'sap номера заказов закупки',
      'purchase supplier': 'закупка поставщик',
      'purchase suppliers': 'поставщики закупки',
      'purchase ticket': 'закупочный тикет',
      'purchases & sales': 'закупки и продажи',
      purpose: 'назначение',
      'push this button after applying filters to find a logistics you want to send\n                        by email.':
        'нажмите эту кнопку после применения фильтров, чтобы найти логистику, которую вы хотите отправить\n                        по электронной почте.',
      qty: 'кол-во',
      quality: 'качество',
      'quality enterance': 'качество внесение',
      quantity: 'количество',
      'quantity of containers': 'кол-во контейнеров',
      'quantity of measurement': 'кол-во измерения',
      'quantity total amount': 'кол-во общая сумма',
      rate: 'ставка',
      read: 'прочитано',
      ready: 'готово',
      'real capacity': 'реал. вместительность',
      'realized value': 'реализованная сумма',
      reassignment: 'переуступка',
      receipt: 'квитанция',
      received: 'получено',
      'received date - the date when you received the legal documents of this company':
        'дата получения - дата когда вы получили документы по компании',
      'received real': 'получено реальный',
      'received volume': 'полученный объем',
      receiver: 'Получатель',
      'receiver code': 'код получателя',
      receivers: 'получатели',
      'receiving date': 'дата получения',
      'receiving stations': 'станции получения',
      refresh: 'обновить',
      'refresh totals': 'перезагрузить тоталы',
      region: 'область',
      'regional managers': 'региональные менеджеры',
      'regions, where this counterparty operate mostly':
        'выберите области, в которых компания оперирует больше всего',
      'related counterparties': 'связанные контрагенты',
      'related counterparty': 'связанный контрагент',
      'related invoice': 'связанный инвойс',
      'related to': 'относится к',
      relations: 'отношения',
      remains: 'остатки',
      'rename file': 'переименовывать файл',
      report: 'отчет',
      'report column type': 'тип колонки отчета',
      'request date': 'дата заявки',
      requested: 'запрос',
      'requested date - the date when you requested the legal documents of this company':
        'дата запроса - дата когда вы запросили документы по компании',
      required: 'обязательно',
      reservation: 'бронирование',
      responsibilities: 'ответственности',
      responsibility: 'ответственность',
      responsible: 'ответственный',
      'responsible confirmation': 'ответственный за подтверждение',
      'responsible for compliance': 'ответственный за комплаенс',
      'responsible for confirm': 'выбрать пользователя ответственным за подтверждение',
      'responsible for signing': 'ответственный за подписание',
      'responsible signing': 'выбрать ответственного за подписание',
      'responsible users': 'ответственные пользователи',
      responsible_for_signing: 'ответственный за подписание',
      rests: 'остатки',
      'rests groupping': 'группировка остатков',
      return: 'возврат',
      risk: 'риск',
      road: 'в пути',
      'road volume': 'объем в дороге',
      role: 'роль',
      roles: 'роли',
      rolling: 'роллинг',
      sailed: 'отошло',
      sale: 'продажа',
      'sale Contr. No.': 'продажный контракт №.',
      'sale Plan': 'план продаж',
      'sale and purchases of': 'продажи и закупки по',
      'sale buyer': 'продажа покупатель',
      'sale buyers': 'продажа покупатели',
      'sale commodity': 'продажный товар',
      'sale contract': 'продажный контракт',
      'sale contract basis': 'продаж контракт базис',
      'sale contract buyer': 'покупатель продажного контракта',
      'sale contract commodity': 'товар продажного контракта',
      'sale contract number': 'номер продажного контракта',
      'sale contract supplier': 'поставщик продажного контракта',
      'sale contracts': 'продажные контракты',
      'sale destinations': 'назначения продажи',
      'sale invoice prepay': 'Продажный инвойс предоплата',
      'sale origins': 'происхождение продажи',
      'sale owner': 'продажа владелец',
      'sale price multiplicator': 'мультипликатор продаж цены',
      'sale sap order': 'продажа sap заказ',
      'sale ticket': 'Продажный тикет',
      'sales manager': 'региональный представитель',
      'sales manager head': 'глава региональных представителей',
      'sap order': 'sap номер заказа',
      'sap orders': 'sap-заказы',
      'sap registered': 'sap зарегистрирован',
      save: 'сохранить',
      'save a transfer': 'сохранить трансфер',
      'save and continue': 'сохранить и продолжить',
      'save and continue editing': 'сохранить и продолжить редактирование',
      'save changes': 'сохранить изменения',
      'save collection': 'сохранить конфигурация',
      'save report congif': 'Сохранить конфигурацию отчета',
      season: 'сезон',
      seasons: 'сезоны',
      seeds: 'посевы',
      'seeds amount': 'посевы сумма',
      'select a season for a position': 'Выберите сезон для позиции',
      'select and rename any of the fields you want to save for an Excel file\n                  donwload':
        'выберите и переименуйте любые поля, которые вы хотите сохранить для файла Excel.',
      'select current business unit for editing': 'выберите текущий бизнес-юнит для редактирования',
      'select current collection for editing or select New collection to create':
        'выберите текущую коллекцию для редактирования или нажмите Новая коллекций для создания',
      'select emails you want to send': 'выберите emails, которые вы хотите отправить',
      'select one from templates for sending': 'выберите один из шаблонов для отправки',
      'select one or multiple to display a position':
        'выберите один или несколько, чтобы отобразить позицию',
      'select single option to display child commodities':
        'выберите один вариант для отображения дочерних товаров',
      'self-cost': 'Себестоимость',
      'self-cost = (value of goods purchase + value of purchase costs) / volume of sale':
        'себестоимость = (стоимость покупки товара + стоимость затрат на покупку) / объем продаж',
      'self-cost per 1t': 'себестоимость за 1т',
      'self-cost-including-all-costs = (purchase value without VAT + sale contract charge value without VAT + purchase contract charge value without VAT + value intermediate contracts + value passport without VAT) / volume of sale':
        'себестоимость-включая-все-затраты = (стоимость покупки без НДС + стоимость договора купли-продажи без НДС + стоимость договора купли-продажи без НДС + стоимость промежуточных контрактов + стоимость паспорта без НДС) / объем продажи',
      selfcost: 'себестоимость',
      'send a report': 'отправить отчет',
      senders: 'отправители',
      sent: 'отправл',
      service: 'услуги',
      'service contract': 'сервисный контракт',
      'service costs': 'сервисные затраты',
      services: 'услуги',
      set: 'набор',
      'set a prices to evaluate a position': 'установить цены для оценки позиции',
      shipment: 'отгрузка',
      'shipment elevators': 'элеваторы отгрузки',
      'shipment period': 'период отгрузки',
      'shipment period of the contract': 'период исполнения контракта',
      'shipment status': 'отгрузка началась',
      'shipment terminal': 'терминал отгрузки',
      'shipment terminals': 'выберите терминалы отгрузки',
      'shipment warehouse': 'склад отгрузки',
      'shipment warehouses': 'склады отгрузки',
      shipped: 'отгруж',
      'shipped by logistics': 'отгружено логистикой',
      short: 'шорт',
      'short net pnl mtm': 'short net pnl mtm',
      'short/long': 'шорт/лонг',
      shortage: 'шортадж',
      'shortage fee': 'шортадж затраты',
      'shortage quantity': 'шортадж объем',
      'shortage rate': 'шортадж ставка',
      'show all stock': 'показать все остатки',
      'show expired': 'показать неактуальные',
      'show expired tariffs': 'показать неактуальные тарифы',
      'side from': 'сторона от',
      'side from status': 'сторона от статуса',
      'side to': 'сторона до',
      'side to status': 'сторона к статусу',
      sides: 'стороны',
      signature: 'подпись',
      signed: 'подписано',
      simple: 'образец',
      'single document size up to 1 mb': 'размер одного документа не должен превышать 1мб',
      'single document size up to 50 mb': 'один документ до 50 мегабайт',
      'single file size up to 50 mb (depends on your SMTP provider)':
        'размер одного файла не должен превышать 50 мб (зависит от провайдера почты)',
      source: 'источник',
      spot: 'спот',
      start: 'начало',
      'start Date': 'Дата начала',
      'start balance': 'начальный баланс',
      'start date': 'дата начала',
      'start of execution': 'начало исполнения',
      station: 'станция',
      'station arrival': 'станция получения',
      'station departing': 'станция отправки',
      'station departure': 'станция отправки',
      'station deps': 'станции отправления',
      'station receiving': 'станция получения',
      'station recs': 'станция получения',
      status: 'статус',
      'stowage factor': 'фактор укладки',
      submit: 'подтвердить',
      'submit documents': 'подтвердить документы',
      subuse: 'под-назначение',
      subuses: 'под-назначения',
      success: 'успешно',
      superuser: 'суперпользователь',
      supplier: 'поставщик',
      'supplier balance invoices': 'поставщик баланс инвойсы',
      'supplier contract': 'поставщик контракт',
      'supplier contract basis': 'постав контракт базис',
      'supplier contracts': 'постав. контракт',
      'supplier invoiced': 'поставщику выставлен инвойс',
      'supplier managers': 'менеджеры поставщика',
      'supplier paid': 'поставщик оплатил',
      'supplier prepay invoices': 'поставщик предоплатные инвовйсы',
      'supplier to be invoiced': 'поставщик выставит',
      suppliers: 'поставщики',
      'suppliers of purchase contracts logistics buyer invoices':
        'выберите поставщиков закупочных контрактов',
      'suppliers/distributors': 'поставщики/дистрибьюторы',
      surveyor: 'сюрвейер',
      swift: 'свифт',
      t: 'т',
      't/day': 'т/день',
      't/h': 'т/ч',
      tasks: 'задачи',
      'tax return registered': 'возврат налога зарегистрирован',
      telegram: 'telegram',
      'telegram username': 'telegram никнейм',
      template: 'шаблон',
      terminal: 'терминал',
      'terminal (with consignment)': 'терминал (по накладной)',
      'terminal volume': 'объем на терминале',
      terminals: 'терминалы',
      text: 'текст',
      'the actual tolerance range of the contract': 'опцион по контракту',
      'the full title of the legal entity (for usage in documents)':
        'полное название юридического лица (для использования в документах)',
      'the legal address of the legal entity': 'юридический адрес юридического лица',
      'the list of quality for this deal': 'список качества по этой сделке',
      'the list of users, who approved this deal':
        'список пользователей, которые подтверждают эту сделку',
      'the number of contracts that involve this company. Click on the counter and you will see them all':
        'количество контрактов, добавленное по этой компании. Кликните на счетчик, чтобы увидеть все',
      'the number of documents attached to this company. Click on the counter and you will see them all':
        'количество документов, добавленное по этой компании. Кликните на счетчик, чтобы увидеть все',
      'the number of documents attached to this driver. Click on the counter and you will see them all':
        'количество документов, прикрепленных к этому водителю. Нажмите на счетчик, и вы увидите их все',
      'the number of invoices that involve this company. Click on the counter and you will see them all':
        'количество инвойсов, добавленное по этой компании. Кликните на счетчик, чтобы увидеть все',
      'the number of issued invoiced by this contract. To know more - go to contract details':
        'сумма всех выставленных инвойсов по контракту. Чтобы узнать больше - зайдите в детали контракта',
      'the number of logistics that involve this company': 'количество логистики по этой компании',
      'the number of persons added by this company. Click on the counter and you will see them all':
        'количество контактов, добавленное по этой компании. Кликните на счетчик, чтобы увидеть все',
      'the number of updates and the last one added by this company. Click on the counter and you will see them all':
        'количество обновлений, добавленное по этой компании. Кликните на счетчик, чтобы увидеть все',
      'the open balance of the contract': 'открытый баланс по контракту',
      'the person who will be appointed in documents':
        'сотрудник компании, который будет указан в документах',
      'the roles in which this legal entity will be used in the system. It could be multiple roles, e.g. Buyer and Seller':
        'роли, в которых используется данное юридическое лицо в системе. Их может быть несколько, например, Поставщик и Покупатель в рамках одного юр лица',
      'the short title of the role (for internal use)':
        'короткое название роли (для внутреннего использования)',
      'the status is changed by setting dates of confirmations of counterparty':
        'статус меняется, выставлением дат подтверждения по контрагенту',
      'the value of the deal (except charges)': 'стоимость сделки (без учета затрат)',
      'the volume, connected to passports': 'объем, привязанный к паспортам',
      'the volume, delivered to port': 'объем, доставленный в порт',
      'the volume, departed to port': 'объем, отправленный в порт',
      'the volume, for transport from open balance calculation':
        'объем по транспорту из расчёта открытого баланса',
      'the volume, from transport by basis': 'объем логистики по базисам',
      'the volume,that is currently on the road': 'объем, в дороге',
      'this button will send all chosen emails':
        'с помощью этой кнопки можно отправить все избранные письма по электронной почте',
      'this counterparty can confirm other counterparties. You can see it in the header of the page COUNTERPARTIES - > CONFIRMATIONS':
        'контрагент может подтверждать других контрагентов',
      'this field is calculated automatically': 'это поле вычисляется автоматически',
      'this margin is chosen for P&L report by margin selector':
        'эта маржа выбирается для отчета PnL с помощью переключателя маржи',
      'this month': 'этот месяц',
      'this page was designed the main settings of the company. Only admin can do\n              this':
        'эта страница была разработана на основных настройках компании. Только администратор имеет\n              доступ',
      'this page was designed to manage master data of the system. Only admin can do\n                this':
        'эта страница была разработана для управления основными данными системы. Только администратор имеет\n              доступ',
      'this passport use one side deal': 'этот паспорт использует одностороннюю сделку',
      'this week': 'эта неделя',
      ticket: 'тикет',
      tickets: 'тикеты',
      time: 'время',
      'time, date and user, who created this deal':
        'время, дата, пользователь, который создал эту сделку',
      title: 'название',
      to: 'до',
      'to company': 'компания кому',
      'to deliver and sale at': 'доставки и продажи в',
      'to open actions menu on certain item, press on its name':
        'чтобы открыть меню действий над определенным элементом, нажмите на его название',
      'to pay': 'оплатить',
      tolerance: 'опцион',
      tomorrow: 'завтра',
      tones: 'тоны',
      tonnes: 'тонны',
      topic: 'тема',
      total: 'всего',
      'total amount': 'общая сумма',
      'total amount _metric': 'общая amount _metric',
      'total approve time, h': 'общее время подтверждения, часы',
      'total charges': 'всего затраты',
      'total connected': 'всего подключено',
      'total costs': 'всего затраты',
      'total days of payments delay': 'всего дней задержки',
      'total mtm amount': 'общая сумма mtm',
      'total mtm costs': 'общие затраты mtm',
      'total mtm value': 'общая стоимость mtm',
      'total value': 'общая сумма',
      'total value analytical': 'общая стоимость аналитическая',
      'total value discounted': 'общая стоимость со скидкой',
      'total volume': 'общий объем',
      totals: 'всего',
      transaction: 'трансакция',
      'transaction date': 'дата транзакции',
      transit: 'транзит',
      transport: 'транспорт',
      'transport open balance volume': 'открытый баланс по транспорту',
      turnover: 'оборот',
      'turnover groupping': 'группировка товарооборота',
      type: 'тип',
      'type of activities': 'вид деятельности',
      types: 'типы',
      'under each passport you will see the one costs, split on two 500 and 500.':
        'под каждым паспортом вы увидите одну стоимость, разделенную на две части 500 и 500.',
      unexecuted: 'не исполнено',
      'uninvoiced charge': 'невыставленное начисление',
      unit: 'единица',
      units: 'юниты',
      'unloading terminals': 'выберите терминалы разгрузки',
      'unloading warehouse': 'разгрузка склада',
      'unloading warehouses': 'разгрузка складов',
      'until execution starts': 'до начала исполнения',
      'up to 30 days': 'до 30 дн',
      update: 'обновление',
      'update time': 'время обновления',
      update_time: 'время_обновления',
      updated: 'обновлено',
      updates: 'обновления',
      'usd price': 'цена в USD',
      use: 'цель',
      'use a checkbox to completely hide/show field from the table or use the\n                        button Users to customize the visibility for particular person':
        'используйте флажок, чтобы полностью скрыть/показать поле из таблицы или используйте \n                        кнопку Пользователи, чтобы настроить видимость для определенного человека',
      'use a filter button on the right top corner of the page to filter the\n                        list':
        'используйте кнопку фильтра в правом верхнем углу страницы для фильтрации\n                        списка',
      'use a update button on the right top corner of the page to get the latest\n                        data without refreshing the page':
        'используйте кнопку обновления в правом верхнем углу страницы, чтобы получить последние\n                        данные без обновления страницы',
      'use convertation': 'используйте конвертацию',
      'use favorite filters button above the table. You can save sets of different\n                        filters and use them in a single click. This can be managed in the filters\n                        sidebar':
        'используйте кнопку "Любимые фильтры" над таблицей. Вы можете сохранить наборы различных \n                        фильтров и использовать их в один клик. Настраивать можно\n                         с помощью боковой панели фильтров',
      user: 'пользователь',
      'user created': 'пользователь создан',
      'username / login': 'никнейм / логин',
      'users approved this counterparty': 'пользователи подтвержд контрагента',
      uses: 'использование',
      validated: 'подтверждено',
      value: 'стоимость',
      vat: 'ндс',
      'vat = VAT CARGO SALE - (VAT CARGO PURCHASE + VAT CHARGES SALE + VAT CHARGES PURCHASE )':
        'ндс = НДС груз продажи - (НДС груз закупка + НДС затраты продажа + НДС затраты закупка)',
      'vat return': 'возврат ндс',
      'vat return rate': 'ставка возврата НДС',
      'vehicle number': 'номер транспортного средства',
      vehicles: 'кол-во транс-та',
      vessel: 'судно',
      'vessel Managing company': 'Управляющая компания судна',
      'vessel owner': 'владелец судна',
      'vessel size': 'размер судна',
      virtual: 'виртуальный',
      volume: 'объем',
      'volume / free': 'объем / свободный',
      'volume departed': 'объем отправленный',
      'volume departed consignment': 'отправленный объем накладных',
      'volume discharged': 'объем отгруж',
      'volume intake': 'объем погрузки',
      'volume loaded': 'объем загруж',
      'volume lost': 'потерянный объем',
      'volume max': 'макс объем',
      'volume metric': 'метрика объема',
      'volume min': 'мин объем',
      'volume received': 'объем полученный',
      'volume without pcd': 'объем без птд',
      volume_freighted: 'объем фрахт',
      'volumes by month': 'объемы по месяцам',
      vote: 'голосовать',
      voyage: 'рейс',
      'w/o passport': 'без паспорта',
      warehouse: 'склад',
      'warehouses (all)': 'склады (все)',
      'was made from this indicator': 'сделан из индикатора',
      'was made from this ticket': 'был сделан из тикета',
      washout: 'вошаут',
      website: 'вебсайт',
      with: 'с',
      'with VAT': 'с НДС',
      'with docs': 'с документами',
      "with sale contract's passports?": 'с продажными контрактами паспортами?',
      'with selected passport?': 'с выбранным паспортом?',
      'without VAT': 'без НДС',
      'without invoice, offset, reassignment': 'без инвойса, зачёта, переуступки',
      wizard: 'мастер',
      word: 'слово',
      'would require deleting the following protected related objects:':
        'может потребоваться удаление следующих защищенных связанных объектов:',
      'write a response': 'написать ответ',
      year: 'год',
      years: 'лет',
      yes: 'да',
      yesterday: 'вчера',
      'you can add new one': 'вы можете добавить новый',
      'you can create a new vessel and attach it to this passport':
        'вы можете создать новое судно и привязать его к паспорту',
      'you can download any kit of fields for Export, created on Collection tab':
        'вы можете скачать любой набор полей в Экспорте, созданные на вкладке Коллекция',
      'you can make your own configuration of export and download the table in a\n                        certain EXCEL format':
        'вы можете создать свою конфигурацию экспорта и загрузить таблицу в\n                        определенном формате EXCEL',
      'you can manage email or messenger notifications on system events':
        'вы можете управлять уведомлениями на почту или в мессенджер',
      'you can manage the columns and their ordering pressing setting icon above\n                        the table':
        'вы можете управлять столбцами и их порядком, нажимая значок настройки над \n                        таблицей',
      'you can now click': 'теперь вы можете нажать',
      'you can print any documents from predefined template':
        'вы можете распечатать любые документы из заранее определенного шаблона',
      'you can start the whole approval process once again':
        'вы можете начать заново весь процесс утверждения',
      'you can switch different views of the table using buttons on the right top\n                        of the table':
        'вы можете переключать разные виды таблицы с помощью кнопок в правом верхнем углу\n                        таблицы',
      'you can validate the counterparty as per predefined approval configuration':
        'вы можете подтвердить контрагента по предварительно определенной конфигурации подтверждения',
      'you can validate the passport as per predefined approval configuration':
        'вы можете подтвердить паспорт по предварительно определенной конфигурации подтверждения',
      'you can write an email to a client with predefined templates':
        'вы можете написать email контрагенту с предварительно определенными шаблонами',
      'you have limit on {[{ $root.user.settings.USERS_MAX_COUNT }]} active users':
        'у вас есть ограничение на {[{ $root.user.settings.USERS_MAX_COUNT }]} активных пользователей',
      '{[{ $ctrl.$rootScope.user.settings.DEFAULT_CURRENCY }]}':
        '{[{ $ctrl.$rootScope.user.settings.DEFAULT_CURRENCY }]}',
      '{[{ $ctrl.contract.approval_status }]}': '{[{ $ctrl.contract.approval_status }]}',
      '{[{ $ctrl.contract.contract_type }]}': '{[{ $ctrl.contract.contract_type }]}',
      '{[{ $ctrl.finance.approval_status }]}': '{[{ $ctrl.finance.approval_status }]}',
      '{[{ $ctrl.multicontract.contract_type }]}': '{[{ $ctrl.multicontract.contract_type }]}',
      '{[{ $ctrl.queryParams.margin_selector }]}': '{[{ $ctrl.queryParams.margin_selector }]}',
      '{[{ $ctrl.request.approval_status }]}': '{[{ $ctrl.request.approval_status }]}',
      '{[{ $ctrl.request.contract_type }]}': '{[{ $ctrl.request.contract_type }]}',
      '{[{ $ctrl.status }]}': '{[{ $ctrl.status }]}',
      '{[{ $root.gtUtils.getLocalCurrency() | translate }]}':
        '{[{ $root.gtUtils.getLocalCurrency() | translate }]}',
      '{[{ $root.user.settings.DEFAULT_CURRENCY }]}':
        '{[{ $root.user.settings.DEFAULT_CURRENCY }]}',
      "{[{ 'ISCC'|translate }]}": "{[{ 'ISCC'|translate }]}",
      '{[{ client.approval_status }]}': '{[{ client.approval_status }]}',
      '{[{ column.title }]}': '{[{ column.title }]}',
      '{[{ columnSet.title }]}': '{[{ columnSet.title }]}',
      '{[{ consignment.object_subtype }]}': '{[{ consignment.object_subtype }]}',
      '{[{ consignment.object_type }]}': '{[{ consignment.object_type }]}',
      '{[{ contract.approval_status }]}': '{[{ contract.approval_status }]}',
      "{[{ derivative.month | date:'MMM,yy' }]}": "{[{ derivative.month | date:'MMM,yy' }]}",
      '{[{ detail_row.finance_type }]}': '{[{ detail_row.finance_type }]}',
      '{[{ detail_row.report_column_type }]}': '{[{ detail_row.report_column_type }]}',
      '{[{ finance.finance_type }]}': '{[{ finance.finance_type }]}',
      '{[{ finance.report_column_type }]}': '{[{ finance.report_column_type }]}',
      '{[{ invoice.status }]})': '{[{ invoice.status }]})',
      '{[{ item.approval_status }]}': '{[{ item.approval_status }]}',
      '{[{ item.counterparty_approval_status }]}': '{[{ item.counterparty_approval_status }]}',
      '{[{ item.cp_approval_status }]}': '{[{ item.cp_approval_status }]}',
      '{[{ item.generalagreement_status }]}': '{[{ item.generalagreement_status }]}',
      '{[{ item.request_status }]}': '{[{ item.request_status }]}',
      '{[{ item.status }]}': '{[{ item.status }]}',
      '{[{ item.title }]}': '{[{ item.title }]}',
      '{[{ level.confirmation_logic | uppercase }]}':
        '{[{ level.confirmation_logic | uppercase }]}',
      "{[{ month['title'] }]}": "{[{ month['title'] }]}",
      '{[{ request.approval_status }]}': '{[{ request.approval_status }]}',
      "{[{ side.role_name == 'supplier' ? 'seller' : side.role_name }]}":
        "{[{ side.role_name == 'supplier' ? 'seller' : side.role_name }]}",
      '{[{ total.title}]}': '{[{ total.title}]}',
      '{[{ type.title }]}': '{[{ type.title }]}',
      '{[{ vm.logentry.change_message }]}': '{[{ vm.logentry.change_message }]}',
      '{[{ vm.mode }]}': '{[{ vm.mode }]}',
      '{[{ vm.passport.approval_status }]}': '{[{ vm.passport.approval_status }]}',
      '{[{ vm.title }]}': '{[{ vm.title }]}',
      '{[{$ctrl.certificateRepr[certificateType]}]}':
        '{[{$ctrl.certificateRepr[certificateType]}]}',
      '{[{$ctrl.mode}]}': '{[{$ctrl.mode}]}',
      '{[{$ctrl.selectedGroups.indexOf(level) + 1}]}. {[{ level.title }]}':
        '{[{$ctrl.selectedGroups.indexOf(level) + 1}]}. {[{ level.title }]}',
      '{[{certificateType}]}': '{[{certificateType}]}',
      "{[{contractInfo.crop_title || '---' }]}": "{[{contractInfo.crop_title || '---' }]}",
      '{[{contractInfo.open_balance_sum || 0 | number:\n                                        3}]}':
        '{[{contractInfo.open_balance_sum || 0 | number:\n                                        3}]}',
      '{[{contractInfo.volume_sum || 0 | number: 3 }]}':
        '{[{contractInfo.volume_sum || 0 | number: 3 }]}',
      '{[{group.title}]}': '{[{group.title}]}',
      '{[{item.use}]}': '{[{item.use}]}',
      '{[{row.client}]}': '{[{row.client}]}',
      '{[{row.currency}]}': '{[{row.currency}]}',
      '{[{rowType.title}]}': '{[{rowType.title}]}',
      '{[{stamp.text}]}': '{[{stamp.text}]}',
      '{{ $ctrl.errors.ext }}': '{{ $ctrl.errors.ext }}',
      '{{ $ctrl.errors.resourceObjId }}': '{{ $ctrl.errors.resourceObjId }}',
      '{{ $ctrl.errors.responseResult }}': '{{ $ctrl.errors.responseResult }}',
      '{{ $ctrl.errors.templateText }}': '{{ $ctrl.errors.templateText }}',
      '{{ $ctrl.verboseName }}': '{{ $ctrl.verboseName }}',
      '{{ contentType.title }}': '{{ contentType.title }}',
      '{{ derivative.commodity }}': '{{ derivative.commodity }}',
      '{{ derivative.title }}': '{{ derivative.title }}',
      '{{ role.role_name }}': '{{ role.role_name }}',
      'Нas Debit/Credit Cargo': 'Есть дебиторка/кредиторка по товару',
      'Нas Debit/Credit Contract': 'Есть дебиторка/кредиторка по контракту',
      'Нas Debit/Credit Invoice': 'Есть дебиторка/кредиторка по инвойсу',
      Сourier: 'Курьер',
      сashback: 'кешбек',
      іnvoices: 'инвойсы',
      '✅Approve/❌Decline': '✅Одобрить/❌Отклонить',
      '💬 Add comment': '💬 Добавить комментарий',
      '🔗 Open in browser': '🔗 Открыть в браузере',
    });
    gettextCatalog.setStrings('ru', {
      'No credentials found for the current file.': 'Для текущего файла учетные данные не найдены.',
      'The credentials have expired or are invalid. Please click the Refresh Google credentials button to re-authorize.':
        'Учетные данные просрочены или недействительны. Нажмите кнопку «Обновить учетные данные Google» для повторной авторизации.',
      'The credentials for the file owner ({}) are invalid or expired. Please contact the file owner to re-authorize their Google account.':
        'Учетные данные владельца файла ({}) недействительны или просрочены. Пожалуйста, свяжитесь с владельцем файла, чтобы повторно авторизовать его учетную запись Google.',
      'Your Google Credentials were invalid, please authorize again.':
        'Ваши учетные данные Google недействительны, пожалуйста, авторизуйтесь еще раз.',
      'Waiting for approval': 'Ожидает подтверждения',
      Approved: 'Утверджено',
      'Approval in process': 'Подтверждение в процессе',
      Rejected: 'Отклонено',
      'Approval Config': 'Конфигурация подтверждения',
      'Approval status': 'Статус подтверждения',
      Approvals: 'Подтверждения',
      'Any of users': 'Любой из пользователей',
      'All users': 'Все пользователи',
      'Majority users': 'Большинство пользователей',
      Client: 'Клиент',
      Request: 'Заявка',
      Indicator: 'Индикатор',
      Ticket: 'Тикет',
      'Sale Ticket (position)': 'Продажный тикет (позиция)',
      'Purchase Ticket (position)': 'Закупочный тикет (позиция)',
      'Purchase Contract': 'Закупочный контракт',
      'Sale Contract': 'Продажный контракт',
      'Services contract': 'Сервисный контракт',
      'Intermediate contract': 'Промежуточный контракт',
      'Export contract': 'Экспортный контракт',
      'Purchase Multicontract': 'Закупочный Мультиконтракт',
      'Sale Multicontract': 'Продажный Мультиконтракт',
      'Services Multicontract': 'Сервисный Мультиконтракт',
      'Intermediate Multicontract': 'Промежуточный Мультиконтракт',
      'Export Multicontract': 'Экспортный Мультиконтракт',
      'Purchase Ticket': 'Закупочный Тикет',
      'Sale Ticket': 'Продажный Тикет',
      'Services Ticket': 'Сервисный Тикет',
      'Export Ticket': 'Экспортный Тикет',
      'General Agreement': 'Договор',
      Passport: 'Паспорт',
      Finance: 'Финансы',
      'Payment Plan': 'План оплаты',
      Title: 'Название',
      'Object type': 'Тип объекта',
      'Main approvers': 'Супер утверждающие',
      'Is active': 'Активен',
      'Approval Configs': 'Конфигурации подтверждений',
      'Index (ordering)': 'Сортировка',
      Approvers: 'Подтверждающие',
      'Confirmation logic': 'Логика подтверждения',
      'Is ready for vote condition': 'Готов к голосованию',
      'Need related objects approvals': 'Необходимы подтверждения связанных объектов',
      'Without condition': 'Без условий',
      'Auto approve condition': 'Условие автоматического подтверждения',
      'Approval Config Level': 'Уровень подтверждения',
      'Approval Config Levels': 'Уровни подтверждения',
      Declined: 'Отклонено',
      Comment: 'Комментарий',
      Archived: 'Архивировано',
      Approval: 'Подтверждение',
      'First name': 'Имя',
      'Middle name': 'Отчетство',
      'Last name': 'Фамилия',
      Phone: 'Телефон',
      Email: 'Эл.почта',
      Position: 'Должность',
      'Business Units': 'Бизнес юниты',
      'HS code': 'HS код',
      'Sale manager': 'Региональный представитель',
      'Sale managers': 'Региональные представители',
      Condition: 'Условие',
      'Can create Google Drive file': 'Может создавать файл в Google Drive',
      'Google Authentication required.': 'Требуется аутентификация Google.',
      Enter: 'Войти',
      'The reset link is broken or already used':
        'Ссылка для сброса не работает или уже использована',
      'In the case of any questions, please, contact our Support Team':
        'В случае возникновения вопросов обращайтесь в нашу службу поддержки.',
      Login: 'Логин',
      Password: 'Пароль',
      'Forgot password': 'Забыли пароль',
      'Pressing the button I confirm, I have read and agree with':
        'Нажимая на кнопку ВОЙТИ и заходя на сайт, Я подтверждаю, что ознакомился с',
      'terms of use and privacy policy': 'условиями использования и конфиденциальности',
      "You're receiving this email because you requested a password reset for your user account":
        'Вы получили это письмо, потому что вы запросили сброс пароля для своей учетной записи пользователя',
      'Please go to the following page and choose a new password:':
        'Пожалуйста, перейдите на следующую страницу и выберите новый пароль:',
      'Your username, in case you’ve forgotten:': 'Ваше имя пользователя, если вы забыли:',
      'Thanks for using our site!': 'Спасибо, что пользуетесь нашим сайтом!',
      "Password reset link sent to user's email":
        'Ссылка для сброса пароля отправлена на электронную почту пользователя',
      "You're receiving this notification because you requested a password reset for your user.":
        'Вы получили это уведомление, поскольку запросили сброс пароля для своего пользователя.',
      "This request doesn't exist anymore.": 'Этот запрос больше не существует.',
      '{approval.user.get_full_name()}, voted this approval already.':
        '{approval.user.get_full_name()} уже проголосовал за это подтверждение.',
      '{user.get_full_name()}, you are not allowed to vote this approval.':
        '{user.get_full_name()}, вам не разрешено голосовать за это подтверджение.',
      'Thank you, {user.get_full_name()}, you Approved the object.':
        'Спасибо, {user.get_full_name()}, вы подтвердили объект.',
      'Thank you, {user.get_full_name()}, you Declined the object.':
        'Спасибо, {user.get_full_name()}, вы отклонили объект.',
      'This field can not be changed after creation.':
        'Это поле не может быть изменено после создания.',
      'Invalid login and/or password': 'Неправильный Логин и/или Пароль. Попробуйте снова',
      'You need sent three values (current, new, confirm)':
        'Вам нужно сохранить три значения (текущее, новое, подтвержд.)',
      'Wrong password.': 'Неправильный пароль.',
      'New password and confirm must be the same.':
        'Новый пароль и подтверждение должны быть одинаковыми.',
      'Password updated.': 'Пароль обновлен.',
      'Object type is required': 'Тип объекта обязателен',
      'You should approve this item': 'Вам следует подтвердить этот пункт',
      'Approval requested.': 'Запрос на согласование.',
      'Google Drive logged in successfully. Try again':
        'Успешный вход в Google Drive. Попробуйте еще раз',
      'Enter new password': 'Введите новый пароль',
      'Password reset unsuccessful': 'Сброс пароля не удался',
      'There are {} cities named {}. Please, add district to it.':
        'Есть {} города/ов с именем {}. Пожалуйста, добавьте к нему район.',
      'There are no district {} for city {}.': 'Район {} для города {} отсутствует.',
      'Region matching query (title={}) does not exist':
        'Область {} соответствующий запрос ({} = {}) не существует',
      'Role names cannot be empty. Please provide at least one valid role.':
        'Имена ролей не могут быть пустыми. Пожалуйста, укажите хотя бы одну допустимую роль.',
      'The following role names are invalid: {': 'Следующие названия ролей недействительны: {',
      'Not found company with this code': 'Компания с таким кодом не найдена',
      'set vkursi credentials': 'установить учетные данные vkursi',
      'bad YouControl key': 'неправильный ключ YouControl',
      'User for this person already exist.': 'Пользователь для этого человека уже существует.',
      Supplier: 'Поставщик',
      Exporter: 'Экспортер',
      Farm: 'Хозяйство',
      Elevator: 'Элеватор',
      Deliverer: 'Экспедитор',
      Bank: 'Банк',
      Owner: 'Владелец',
      Other: 'Другое',
      Surveyor: 'Сюрвейер',
      Buyer: 'Покупатель',
      Broker: 'Брокер',
      Insurer: 'Страховая',
      'Customs broker': 'Таможенный брокер',
      Hot: 'Горячий',
      Warm: 'Теплый',
      Neutral: 'Нейтральный',
      Cold: 'Холодный',
      Angry: 'Злой',
      New: 'Новое',
      'Approved condition': 'Подтверждено с условием',
      'Out of approval': 'Вне согласования',
      Pending: 'В процессе',
      'Re-approval': 'Повтор подтв',
      Big: 'Большой',
      Medium: 'Средний',
      Small: 'Маленький',
      Call: 'Звонок',
      Meet: 'Встреча',
      Task: 'Задание',
      Commentary: 'Комментарий',
      Low: 'Низкий',
      High: 'Важно',
      Critical: 'Критично',
      Success: 'Успешно',
      Fail: 'Неудачно',
      Progress: 'Прогресс',
      Cancelled: 'Отменено',
      Upcoming: 'Предстоит выполнить',
      'Company group': 'Группа компаний',
      'Additional info': 'Дополнительная информация',
      'Client Document Type': 'Тип документа',
      'Days to update client status': 'Дни до обновления статуса клиента',
      'company group': 'группа компаний',
      'Company groups': 'Группы компаний',
      Responsibility: 'Ответственность',
      responsibility: 'ответственность',
      Responsibilities: 'Ответственности',
      'Django user': 'Обычный пользователь',
      'First Name': 'Имя',
      'Second Name': 'Фамилия',
      'Middle Name': 'Отчетство',
      Companies: 'Компании',
      'Phone additional': 'Телефон дополнительный',
      Birthday: 'День Рождения',
      'Main contact': 'Основной контакт',
      Relation: 'Связь',
      Verified: 'Проверено',
      person: 'контакт',
      Persons: 'Контакты',
      'Can create GrainTrack user for person': 'Может создавать пользователя Кабинетов',
      'Can verify person': 'Можете подтвердить особу',
      Name: 'Имя',
      'Related counterparties': 'Связанные контрагенты',
      'Custom status': 'Пользовательский статус',
      Abbreviation: 'Аббревиатура',
      'Full name': 'Полное название',
      'Full name Eng': 'Полное имя (Англ.)',
      Code: 'Код',
      EIC: 'EIC',
      ITN: 'ИНН',
      'Tax info': 'Налоговая информация',
      Country: 'Страна',
      Address: 'Адрес',
      'Legal address': 'Юридический адрес',
      'Legal address eng': 'Юридический адрес англ',
      Person: 'Контакт',
      Signatory: 'Подписант',
      'Signatory Eng': 'Подписант (Англ)',
      'Signatory Docs': 'Подписант документов',
      'Signatory Eng Docs': 'Подписант документов (Англ)',
      'Phone (additional)': 'Телефон (дополнительный)',
      Website: 'Вебсайт',
      'Black list': 'Черный список',
      'Company data is checked': 'Данные компании проверены',
      'Bank data is checked': 'Банковские данные проверены',
      Documents: 'Документы',
      'First confirmation (date)': 'Первое подтверждение (дата)',
      'Term of proxy': 'Срок действия доверенности',
      'Days before deadline to notify': 'Дней до крайнего срока уведомления',
      'Confirmation (date)': 'Подтверждение (дата)',
      'Confirmation info': 'Подтверждение информации',
      'Request (date)': 'Дата запроса доков',
      'Received (date)': 'Получено (дата)',
      'Incorporation (date)': 'Дата инкорпорации',
      Status: 'Статус',
      President: 'Президент',
      CEO: 'CEO',
      CFO: 'CFO',
      'Other executive': 'Другие менеджеры',
      'Parent company': 'Материнская компания',
      'Ultimate parent company': 'Материнская компания',
      'Ultimate beneficiary owners': 'Конечные бенефициары',
      'Activities & traded products': 'Активность & товары',
      Suppliers: 'Поставщики',
      Customers: 'Клиенты',
      'Can confirm clients': 'Может подтверждать',
      'Don`t use kpi check': 'Не использовать для KPI',
      Responsible: 'Ответственный',
      'Responsible for confirmation': 'ответственный за подтверждение',
      'Client is resident': 'Контрагент является резидентом',
      'SAP vendor number': 'SAP номер контрагента',
      Equity: 'Капитал',
      'Net income': 'Чистая прибыль',
      'Total fixed assets': 'Всего основных средств',
      Turnover: 'Оборот',
      'Total assets': 'Всего активы',
      'Annual traded volume': 'Годовой объем торгов',
      'Info for traders': 'Информация для трейдеров',
      'Contact (future)': 'Контакт (будущий)',
      City: 'Город',
      District: 'Район',
      Regions: 'Области',
      'Client crop area': 'Посевная площадь по контрагенту',
      'Cash Flow': 'Денежный поток',
      'Client transports count': 'Счетчик транспортных средств компании',
      'Regular status': 'Статус сделок',
      Potencial: 'Потенциальный',
      Active: 'Активный',
      Inactive: 'Неактивный',
      Closed: 'Закрыто',
      Size: 'Размер',
      'Contact Date (first)': 'Дата контакта (первая)',
      Agent: 'Посредник',
      Producer: 'Производитель',
      Competitor: 'Конкурент',
      'Local currency': 'Локальная валюта',
      'Foreign currency': 'Валюта',
      'Own cunterparty': 'Собственный контрагент',
      'outgoing invoice prefix': 'префикс исходящего инвойса',
      Template: 'Шаблон',
      'Signatory position': 'Должность подписанта',
      'Signatory position eng': 'Должность подписанта (англ.)',
      'Code of economic activities': 'КВЭД',
      'Counterparty type': 'Тип контрагента',
      'legal entity': 'юридическое лицо',
      'physical person': 'Физическое лицо',
      'individual entrepreneur': 'индивидуальный предприниматель',
      'Mailing address': 'Адрес рассылки',
      'Types of activity': 'Виды деятельности',
      Updates: 'Обновления',
      Clients: 'Контрагенты',
      'Can view client offers': 'Просмотр индикаторов контрагентов',
      'Can view client tickets': 'Просмотр тикетов контрагентов',
      'Can view client confirmations': 'Просмотр подтверждений по контрагенту',
      'Can view client statistics': 'Просмотр статистики контрагентов',
      'Can view client persons': 'Просмотр сотрудников контрагентов',
      'Can edit date_confirmation': 'Дата подтверждения',
      'Can edit date_first_confirmation': 'Первое подтверждение (дата)',
      'Can edit status': 'Только куратор может редактировать',
      'Can edit kpi confirmation success status': 'Может редактировать KPI успех',
      'Can edit client responsible': 'Может редактировать ответственного по контрагенту',
      'Can edit client confirmation of a counterparty':
        'Может редактировать подтверждения по контрагенту',
      'Can approve client': 'Может подтверждать клиента',
      'Can reactivate approval config': 'Можно повторно активировать конфигурацию подтверждения',
      'Has sanctions': 'Есть санкции',
      'Has criminal cases': 'Имеет криминальные дела',
      'Has tax debt': 'Имеет налоговый долг',
      Crop: 'Культура',
      'Initial volume (t)': 'Первичный объем (т)',
      'Volume (t)': 'Объем (т)',
      'Cultivated area': 'Посевная площадь',
      'productivity of land': 'продуктивность',
      Note: 'Примеч',
      potential: 'потенциал',
      potentials: 'потенциалы',
      'Role Name': 'Имя роли',
      'Limit per one deal (spot), t': 'Лимит за сделку (спот), т',
      'Limit general (spot), t': 'Лимит общий, т',
      'Limit open balance (spot), t': 'Лимит открытый баланс (спот), т',
      'Limit unpaid volume (spot), t': 'Лимит по неоплаченному объему (спот), т',
      'Limit per one deal (spot), USD': 'Лимит за сделку (спот), дол',
      'Limit general (spot), USD': 'Лимит общий (спот), дол',
      'Limit per one deal (forward), t': 'Лимит за сделку (форвард), т',
      'Limit general (forward), t': 'Лимит общий (форвард), т',
      'Limit open balance (forward), t': 'Лимит открытый баланс (форвард), т',
      'Limit unpaid volume (forward), t': 'Лимит по неоплаченному объему (форвард), т',
      'Limit per one deal (forward), USD': 'Лимит за сделку (форвард), дол',
      'Limit general (forward), USD': 'Лимит общий (форвард), дол',
      'Affiliated suppliers': 'Аффилированные поставщики',
      Basises: 'Базисы',
      'Payment conditions': 'Условия платежа',
      'Payment conditions auto': 'Условия оплаты (авто)',
      'Payment conditions option': 'Опция условий оплаты',
      'Client role': 'Роль контрагента',
      'Client roles': 'Юридические лица, роли',
      'Can change limits': 'Может изменять лимиты',
      owner: 'владелец',
      Owners: 'Владельцы',
      other: 'другое',
      Others: 'Другие',
      seller: 'продавец',
      Sellers: 'Продавцы',
      'Can view sellers': 'Просмотр списка продавцов',
      broker: 'брокер',
      Brokers: 'Брокеры',
      surveyor: 'сюрвейер',
      Surveyors: 'Сюрвейеры',
      insurer: 'страховая',
      Insurers: 'Страховые',
      bank: 'банк',
      Banks: 'Банки',
      forwarder: 'перевозчик',
      Forwarders: 'Экспедиторы',
      exporter: 'экспортер',
      Exporters: 'Экспортеры',
      buyer: 'покупатель',
      Buyers: 'Покупатели',
      elevator: 'элеватор',
      Elevators: 'Элеваторы',
      Farms: 'Хозяйства',
      Update: 'Обновление',
      Response: 'Ответ',
      Priority: 'Приоритет',
      Type: 'Тип',
      'Contact status': 'Статус контакта',
      'Is auto created': 'сгенерировано автоматически',
      'Responsible users': 'Ответственные пользователи',
      Initiator: 'Инициатор',
      'Notification date (+time)': 'Дата оповещения (+время)',
      'Sequence order': 'Последовательность действий',
      'Due date hours after create': 'Срок выполнения (часы после создания)',
      'Checklist Point': 'Пункт контрольного списка',
      'Can change responsible': 'Возможно изменить ответственного',
      'Update section': 'Обновить раздел',
      'Update sections': 'Обновить разделы',
      'Update topic': 'Тема обновлений',
      'Update topics': 'Темы обновлений',
      'Update failure reason': 'Причина неудачного обновления',
      'Update failure reasons': 'Причины неудачного обновления',
      'Title in English': 'Название на английском',
      'Is resident': 'Резидент',
      'Is distributor': 'Дистрибьютор',
      'Is agent': 'Посредник',
      'Is producer': 'Производитель',
      'Client Document Types': 'Типы документов',
      'Is visible': 'видимый',
      'Copy is available': 'Есть копия',
      'Original is available': 'Есть оригинал',
      'Sending date': 'Дата отправки',
      'Receipt date': 'Дата получения',
      'Client Document': 'Документ',
      'Client Documents': 'Документы',
      'Commentary text': 'Комментарий',
      'Client commentary': 'Клиентский комментарий',
      'Client commentaries': 'Комментарии',
      'Can view client commentaries': 'Может просматривать клиентские комментарии',
      Area: 'Посевная площадь',
      Volume: 'Объем',
      'Crop year': 'Год урожая',
      'Grain quality check': 'Проверка качества',
      'Cargo price': 'Цена груз',
      Currency: 'Валюта',
      'Exchange rate': 'Курс обмена',
      'Place of storage': 'Место хранения',
      'Crop Area': 'Посевная площадь',
      'Crop Areas': 'Культура, объем',
      Value: 'Стоимость',
      'Value alt': 'Значение альт',
      'Title alt': 'Название альт',
      Distance: 'Расстояние',
      'Warehouse Distance': 'Расстояние',
      'Warehouse Distances': 'Расстояниe',
      Codename: 'Код название',
      'Client Role': 'Роль контрагента',
      'Client Roles': 'Роли контрагента',
      Calendar: 'Календарь',
      Calendars: 'Календари',
      'Cargo Brand': 'Бренд груза',
      'Cargo Brands': 'Бренды грузов',
      'Name Eng': 'Наименование (англ.)',
      Region: 'Область',
      Customs: 'Таможня',
      Customses: 'Таможни',
      Potential: 'Потенциал',
      Contract: 'Контракт',
      Multicontract: 'Мультиконтракт',
      Multiticket: 'Мультитикет',
      Invoice: 'Инвойс',
      Voyage: 'Рейс',
      GeneralAgreement: 'Генеральное соглашение',
      'Invoice Position': 'Инвойс-позиция',
      Payment: 'Оплата',
      Logistic: 'Логистика',
      Consignment: 'Накладная',
      'Bill of Lading': 'Коносамент',
      'Disbursement Bill of Lading': 'Расходная накладная',
      'Contract Charges': 'Расходы контракта',
      Operation: 'Операция',
      Control: 'Контроль',
      'Bank Account': 'Банковский счет',
      created: 'создано',
      updated: 'обновлено',
      of: 'из',
      'This supplier must be a distributor, because it has opened reassignments.':
        'Этот поставщик должен являться дистрибьютором, потому что у него есть открытые переуступки.',
      'This supplier must be a distributor, because it has opened offsets.':
        'Этот поставщик должен являться дистрибьютором, потому что у него есть открытые зачеты.',
      'Warehouse distance can have only one warehouse.':
        'У дистанции может быть только один склад.',
      'Warehouse required.': 'Склад обязателен.',
      'Distance should be unique by client, city and warehouse.':
        'Расстояние должно быть уникальным для клиента, города и склада.',
      from: 'от кого',
      'Delivery period': 'Период поставки',
      Shipment: 'Отгрузка',
      'Cargo executed': 'Груз выполнен',
      Executed: 'Выполнено',
      Washout: 'Вошаут',
      Draft: 'Драфт',
      Process: 'Процесс',
      Valid: 'Актуально',
      invalid: 'Неактуально',
      'One full cargo': 'Весь объем',
      'Partial shipment allowed': 'Частичный объем',
      Seller: 'Продавец',
      Sale: 'Продажа',
      Purchase: 'Закупка',
      Prepayment: 'Предоплата',
      Processed: 'В процессе',
      Sent: 'Отправлено',
      Lost: 'Потеря',
      Spot: 'Спот',
      Forward: 'Форвард',
      Brokerage: 'Брокер',
      Services: 'Услуги',
      Intermediate: 'Промежуточный',
      Export: 'Экспорт',
      '20 DV': 'Тариф 20',
      '40 DV': 'Тариф 40',
      'Not selected': 'Не выбрано',
      'Loading minus': 'Отгрузка в минус',
      'Loading plus': 'Отгрузка в плюс',
      'Free tax invoice': 'Свободный налог инвойс',
      'On first event': 'По первому событию',
      'Absolute value': 'Абсолютное значение',
      Percentage: 'Процент',
      Receipt: 'Квитанция',
      Dispatch: 'Диспач',
      'Not executed': 'Не выполнено',
      'Contract Option': 'Контракт опция',
      'Option (value)': 'Опция (стоимость)',
      'Conclusion (date)': 'Дата заключения',
      'Execution (start date)': 'Исполнение (дата начала)',
      'Execution (end date)': 'Исполнение (дата окончания)',
      'Deal Type': 'Тип сделки',
      'Notify party': 'Уведомленная сторона',
      'Use type': 'Тип использования',
      'According to contract template': 'Согласно шаблону контракта',
      'Shipment terminal': 'Терминал отгрузки',
      'start date': 'дата начала',
      'end date': 'дата окончания',
      'Program type': 'Тип программы',
      'Main program': 'Основная програма',
      Crops: 'Культуры',
      'Price currency': 'Цена валюты',
      'Premium currency': 'Премиальная валюта',
      month: 'месяц',
      Price: 'Цена',
      Premium: 'Премиум',
      Charge: 'Затрата',
      'Price per t': 'Цена за тонну',
      'With VAT': 'С учетом НДС',
      'Trader note': 'Заметка трейдера',
      'Price opposite': 'Цена закупки\\продажи',
      'Is gain': 'Это доход',
      'Counterparty amount': 'Сумма контрагента',
      'Our amount': 'Наша сумма',
      'Final Amount': 'Финальная сумма',
      'Analytical Amount': 'Аналитическая сумма',
      Validated: 'Утверждено',
      'Under discussion': 'Обсуждается',
      Forecast: 'Прогноз',
      'Pending confirmation': 'Ожидает подтверждения',
      Planned: 'Запланировано',
      Date: 'Дата',
      Commodity: 'Продукт',
      'ContractCharge by default': 'Расходы контракта по умолчанию',
      'ContractCharge by default rules':
        'Автоматическое начисление затрат по контракту по умолчанию',
      'Can add contract charge': 'Может добавить затрату контракта',
      'Can add ticket charge': 'Может добавить затрату тикета',
      'Opposite basis': 'Противоположный базис',
      'Costs opposite': 'Встречные затраты',
      'Currency exchange': 'Обмен валюты',
      'Counterparties opposite': 'Противоположный контрагент',
      'Execution date opposite': 'Дата заключения противоположная',
      'Opposite freight level': 'Стоимость фрахта встречного контракта',
      'Freight currency': 'Валюта фрахта',
      'Freight currency exchange': 'Обмен валюты фрахта',
      'Price premium opposite': 'Премиум цена встречная',
      Derivative: 'Дериватив',
      'Custom values': 'Кастомные значения',
      'Number of contract (#)': 'Номер контракта (№)',
      'Number of folder (#)': 'Номер папки',
      'Responsible for signing': 'Ответственный за подписание',
      'Volume with docs (t)': 'Объем с доками (Т)',
      Option: 'Опция',
      'Final volume (t)': 'Финальный объем (т)',
      Origin: 'Происхождение',
      Destination: 'Назначение',
      Origins: 'Происхождение',
      Destinations: 'Направления',
      'Conclusion price': 'Заключительная цена',
      'Analytical price': 'Аналитическая цена',
      'Price USD': 'Цена, $',
      'Currency and Rate': 'Валюта и Курс',
      Basis: 'Базис',
      'Paper Trade': 'Бумажная торговля',
      'Payment conditions options': 'Опции условий оплаты',
      'Custom declaration': 'ГТД',
      'Dont check kpi time different': 'Не использовать для KPI',
      'Expiration start': 'Начало экспирации',
      'Expiration end': 'Окончание экспирации',
      'Nomination date': 'Дата номинации',
      Extention: 'Пролонгация',
      'Bill of lading (date)': 'Коносамент (дата)',
      'Transfer of ownership (date)': 'Передача права собственности',
      'Shipment type': 'Тип отгрузки',
      'Prepay payment': 'Предоплата платежа',
      'Balance payment': 'Балансовый платеж',
      'Broker contacts': 'Контакты брокера',
      Forwarder: 'Экспедитор',
      Ports: 'Порты',
      'Opposite ports': 'Противоположный порт',
      'Station depart': 'Станция отправления',
      'Station receive': 'Станция получения',
      'Exchange for calculation': 'Курс (вручную)',
      'Exchange for calculation (opposite)': 'Курс (вручную)',
      'Delivery conditions': 'Вид транспортировки',
      VAT: 'НДС',
      'VAT (value)': 'НДС процент',
      'No extension': 'Без продления',
      'Preadvice (days)': 'Преадвайс (дни)',
      'Quality info': 'Качество инфо',
      Stage: 'Этап',
      'Previous status': 'Предыдущий статус',
      'Logistics costs VAT': 'Затраты логистики (НДС)',
      'Logistics penalties VAT': 'Штрафы по логистике (НДС)',
      'Needs original documents': 'Нужны оригиналы',
      'Regional managers': 'Региональные менеджеры',
      'Heads of sales manager': 'Региональный представитель',
      'Calculate execution by intermediate logistics': 'Расчет исполнения (пром. логистика)',
      'Calculate tax profit to pay': 'Кальк налог',
      'Elevator (start date)': 'Исполнение (начало)',
      'Elevator (end date)': 'Элеватор (дата окончания)',
      'Estimated execution date': 'Ожидаемый месяц исполнения',
      'Loading option': 'Опцион отгрузки',
      Swap: 'СВОП',
      'Ignore limits': 'Игнорировать лимиты',
      'Ignore client status': 'Игнорировать статус контрагента',
      Terminal: 'Терминал',
      'Few departure stations': 'Несколько станций отправки',
      ISCC: 'ISCC',
      'Minimum batch of payment': 'Минимальная сумма платежа',
      'Internal Chain': 'Внутренняя цепочка',
      'Ship classification': 'Класс лодки',
      'Estimated freight value': 'Прогнозированная стоимость фрахта',
      'Estimated freight VAT value': 'Прогнозированная стоимость ПДВ фрахта',
      'Estimated freight VAT': 'Прогнозированная стоимость фрахта ПДВ',
      'Contract been updated today': 'Обновлено сегодня',
      Season: 'Сезон',
      'vat return rate': 'ставка возврата НДС',
      Port: 'Порт',
      Budget: 'Бюджет',
      'Conclusion date currency': 'Курс на дату заключения',
      'Conclusion date exchange rate': 'Курс валют на дату заключения',
      'Potential buyers': 'Потенциальные покупатели',
      'Potential suppliers': 'Потпнциальные поставщики',
      'Created from risk position origination': 'Создано из склада',
      'Tare Weight': 'Вес тары',
      'Quantity of measurement': 'Количество единицы измерения',
      'price of measurement': 'Цена на измерение',
      'Quantity of containers': 'Количество контейнеров',
      guarantors: 'гарант',
      'Load rate': 'Норма погрузки',
      'Discharge rate': 'Норма выгрузки',
      'Demmurage rate': 'Ставка демереджа',
      'Dispatch rate': 'Диспач ставка',
      'Volume (min)': 'Объем (мин)',
      'Volume (max)': 'Объем (макс)',
      'Implied freight level': 'Прогнозированная стоимость фрахта',
      'Load port': 'Порт отгрузки',
      'Discharge port': 'Порт выгрузки',
      'Arrival period start': 'Период прибытия начало',
      'Arrival period end': 'Период прибытия конец',
      'Pre-advice': 'Преадвайс',
      'Is extension': 'Расширение',
      Discount: 'Дисконт',
      'Discount amount': 'Значение дисконта',
      'Discount 1 mt': 'Дисконт за 1 т',
      'Sampling Condition': 'Условия отбора образцов',
      'Counterparty business reference': 'Бизнес-референс контрагента',
      'Loan percentage': 'Процент кредита',
      'Interest rate': 'Процентная ставка',
      'Estimated opposite payment date': 'Прогнозированная дата встречной оплаты',
      'Payment days': 'Даты платежа',
      'Fixed price bonus': 'Бонус с фиксированной ценой',
      'Use open price': 'Использовать открытую цену',
      'Ending status': 'Финальный статус',
      Open: 'Открыто',
      Ending: 'Окончание',
      Expiring: 'Экспирация',
      Expired: 'Истекший',
      "Days to set 'ending status'(end of execution)":
        'Дни для установки «статуса окончания» (конец выполнения)',
      'Certification schemes': 'Сертификационные схемы',
      'Vehicle restrictions': 'Ограничение для ТЗ',
      Terminals: 'Терминалы',
      'Ignore allocation': 'Игнорировать распределение',
      'Number of days for payment': 'Количество дней для оплаты',
      'Do not display in passport': 'Не отображать в паспорте',
      'Payment status': 'Статус оплаты',
      Contractbase: 'База контрактов',
      Contractbases: 'Базы контрактов',
      'Can view contract logistics': 'Просмотр логистики по контрактам',
      'Can view contract payments': 'Просмотр платежей по контракту',
      'Can view contract documents': 'Просмотр документов по контракту',
      'Can view contract processes': 'Просмотр этапов контракта',
      'Can change contract status': 'Можно изменить статус контракта',
      'Can confirm cargo execution of contract': 'Может подтверждать исполнение контракта (груз)',
      'Can confirm payment execution of contract':
        'Может подтверждать исполнение контракта (финансы)',
      'Can confirm final execution of contract': 'Может подтверждать исполнение контракта (финал)',
      'Can see costs progressbar': 'Просмотр затрат по контаркту',
      'Can edit kpi_signing_success': 'Может редактировать KPI успех',
      'Can ignore client compliance status': 'Можно игнорировать статус комплаенса контрагента',
      'Can ignore excess contract volume': 'Игнорировать лишний объем контракта',
      'Can set voyage info for contract': 'Можно установить информацию про рейс для контракта',
      'Can clone contract': 'Может клонировать контракт',
      'Purchase contract': 'Закупочный контракт',
      'Purchase contracts': 'Закупочные контракты',
      'Can approve purchase contract': 'Может подтверждать закупочные контракты',
      'Can view purchase contracts of all authors':
        'Может просматривать закупочные контракты от всех авторов',
      'Can view purchase contracts of all responsibles':
        'Может просматривать закупочные контракты от всех ответственных',
      'Sale contract': 'Продажный контракт',
      'Sales contracts': 'Продажные контракты',
      'Can approve sale contract': 'Может утверждать продажные контракты',
      'Can view sale contracts of all authors':
        'Может просматривать продажные контракты от всех авторов',
      'Can view sale contracts of all responsibles':
        'Может просматривать продажные контракты от всех ответственных',
      'Services contracts': 'Контракты на услуги',
      'Intermediate contracts': 'Промежуточные контракты',
      'Export contracts': 'Экспортные контракты',
      Calculate: 'Расчетный',
      'Vat discount': 'НДС дисконт',
      '20 dv': 'Тариф 20',
      Containers_type: 'Тип контейнеров',
      'Price purchase calc': 'Цена закупки кальк',
      'Costs calc': 'Затраты расчет',
      'Calculate auto tariff price': 'Расчет авто тариф',
      'Calculate elevator costs': 'Расчет перевалка',
      'Calculate railway costs': 'Расчет жд тариф',
      'Calculate freight costs': 'Посчитать фрахт',
      'Conclusion date opposite': 'Дата заключения противопол',
      'Financing cost': 'Финанс затраты',
      'Financing days': 'Финанс дни',
      'Storage days': 'Дни хранения',
      Distributors: 'Дистрибьюторы',
      'Closing of existing position': 'Закрытие существующей позиции',
      'Estimated P&L': 'Предполагаемый П&Л',
      'Broker commission': 'Брокерская комиссия',
      'Possible buyers info': 'Потенциальные покупатели (инфо)',
      'Possible suppliers info': 'Потенциальные поставщики',
      'Possible brokers info': 'Потенциальные брокеры (инфо)',
      Strategy: 'Стратегия',
      'Trade confirmations': 'Трейдерские подтверждения',
      'Payment terms': 'Сроки оплаты',
      'Price (min)': 'Цена (мин)',
      'Price (max)': 'Цена (макс)',
      'Connection to passport status': 'Статус привязки к паспорту',
      'Crop protection': 'Защита посевов',
      Seeds: 'Посевы',
      'Is lost': 'Потеряно',
      Requests: 'Заявки',
      'Can view all requests': 'Просмотр всего списка индикаторов/тикетов',
      'Can view prices': 'Просмотр цен',
      'Can create ticket from offer': 'Может создать тикет из индикатора',
      'Can approve ticket or contract': 'Может утверждать тикет или контракт',
      'Can cancel ticket or contract': 'Может отменять тикет или контракт',
      'Restrict ticket creation from expired indicator':
        'Ограничить создание тикетов по индикатору истечения срока',
      Tickets: 'Тикеты',
      Indicators: 'Индикаторы',
      'Sale ticket (position)': 'Продажный тикет (позиция)',
      'Sale tickets (positions)': 'Продажный тикет (позиции)',
      "Can change approved 'Sale ticket (position)' and related":
        'Может изменить утвержденный «Тикет продажи (позиция)» и связанное с ним',
      'Purchase ticket (position)': 'Закупочный тикет (позиция)',
      'Purchase tickets (positions)': 'Закупочный тикет (позиции)',
      "Can change approved 'Purchase ticket (position)' and related":
        'Может изменить утвержденный «Тикет закупки (позиция)» и связанное с ним',
      ContractPrice: 'Цена контракта',
      'Update part of the volume': 'Обновить часть объема',
      'Number of agreement (#)': 'Номер договора (№)',
      'Agreement date': 'Дополнительное соглашение (дата)',
      autogenerated: 'сгенерировано',
      'Premium price': 'Премиум цена',
      Warehouse: 'Склад',
      'Additional agreement': 'Дополнительное соглашение',
      'Additional agreements': 'Дополнительные соглашения',
      'Select one or more basises to filter charges inside contracts':
        'Выберите один или несколько базисов, чтобы отфильтровать по ним затраты внутри контрактов',
      'Object stage': 'Этап объекта',
      'Basis Document': 'Базовый документ',
      'Basis Documents': 'Базовые документы',
      'Contract Basis Doc': 'Базовый документ',
      'Active option': 'Активная опция',
      'Price premium': 'Премиум цена',
      'Margin relative, USD': 'Маржа относительная, USD',
      'Margin absolute, USD': 'Маржа абсолютная, USD',
      'For KPI year report': 'Для КПИ отчета (год)',
      Сommodity: 'Продукт',
      'Production Year': 'Год производства',
      'Purchase Plan': 'План закупки',
      'User volume': 'Пользователь объем',
      'Volume in percent': 'Объем в процентах',
      'Indication date': 'Дата индикатора',
      'A month for which the indicator price is assigned':
        'Месяц на который запланирован индикатор',
      'Cargo price, USD': 'Цена, $',
      'Used in market': 'Использовать в рынке',
      'Can mass-delete market prices': 'Может массово удалять рыночные цены',
      'Number (#)': 'Номер (№)',
      Receiver: 'Получатель',
      'Stock reserve unit': 'Резервный блок',
      Consignments: 'Накладные',
      Credit: 'Кредит',
      'Main deal': 'Основная сделка',
      'Buyer payment info': 'Инфо про оплату покупателя',
      'Supplier payment info': 'Инфо про оплату поставщика',
      Amount: 'Сумма',
      'Tax registration': 'Налог регистрация',
      'percent conditions': 'процентные условия',
      'Buyer signator': 'Подписант покупателя',
      'Supplier signator': 'Подписант поставщика',
      'General agreement': 'Генеральный договор',
      'General agreements': 'Генеральные соглашения',
      'Can approve general agreement': 'Может подтверждать рамочные договора',
      'Railway required': 'Ж/д обяз',
      'Vet required': 'Вет обяз',
      'Quality required': 'Качеств обяз',
      'Declaration required': 'Декларация обязательна',
      'EUR 1 required': 'EUR 1 обязательно',
      'Partial executed': 'Выполнено частично',
      'Volume in positions from execution': 'Объем в позициях по исполнению',
      'With derivative': 'С деривативом',
      'Sale multicontract': 'Продажный мультиконтракт',
      'Sales multicontracts': 'Продажные мультиконтракты',
      'Purchase multicontract': 'Закупочный мультиконтракт',
      'Purchases multicontracts': 'Закупочные мультиконтракты',
      'Intermediate multicontract': 'Промежуточный мультиконтракт',
      'Intermediates multicontracts': 'Промежуточные мультиконтракты',
      'Export multicontract': 'Экспортный мультиконтракт',
      'Exports multicontracts': 'Экспортные мультиконтракты',
      'Services multicontract': 'Сервисный мультиконтракт',
      'Servicess multicontracts': 'Сервисные мультиконтракты',
      'Sale multiticket': 'Продажный мультитикет',
      'Sales multitickets': 'Продажные мультитикеты',
      'Purchase multiticket': 'Закупочный мультитикет',
      'Purchases multitickets': 'Закупочные мультитикеты',
      'Export multiticket': 'Экспортный мультитикет',
      'Exports multitickets': 'Экспортные мультитикеты',
      'Services multiticket': 'Сервисный мультитикет',
      'Servicess multitickets': 'Сервисные мультитикеты',
      Number: 'Номер',
      'Stock volume': 'Проверьте объем',
      'Quantity of estimated lots': 'Количество лотов',
      'margin selector': 'переключатель маржи',
      default: 'по умолчанию',
      analytical: 'аналитический',
      Margin: 'Маржа',
      'Margin per ton': 'Маржа за тонну',
      Mtm: 'Мтм',
      'Physical margin': 'Физическая маржа',
      'Derivative margin': 'Маржа дериватива',
      'Total margin': 'Общая маржа',
      'Total value': 'Общая сумма',
      volume: 'объем',
      'Mrket price': 'Рыночная цена',
      Commission: 'Комиссия',
      'Is liquid': 'Жидкость',
      'Use escalations': 'Используйте эскалацию',
      Start: 'Начало',
      End: 'Окончание',
      'Auto apply': 'Автоприменение',
      'Auto applying:': 'Автоматическое применение:',
      'Handly applying:': 'Ручное применение:',
      Any: 'Любой',
      'Export Contract': 'Экспортный контракт',
      'Base contract': 'Основной контракт',
      Consignee: 'Получатель',
      'Delivery terminal': 'Терминал доставки',
      'Delivery address': 'Адрес доставки',
      'Contract consignee': 'Грузополучатель контракта',
      'Contract consignees': 'Грузополучатель контракта',
      'Empty contract number': 'Пустой номер контракта',
      'Crops required.': 'Обязательно указать культуры.',
      'Months required.': 'Обязательно указать месяцы.',
      'Positions required.': 'Обязательно указать позиции.',
      'Term of the contract': 'Условия контракта',
      'is completed on ': 'будет завершен ',
      info: 'информация',
      t: 'т',
      'Ticket status': 'Статус тикета',
      'Is a closing of the existing position': 'Закрывает существующую позицию',
      Details: 'Детали',
      Startegy: 'Стратегия',
      'Trade confirmation': 'Трейдерское подтверждение',
      'PurchasePlan must be unique by crop in particular period':
        'План должен быть уникален по культуре и периоду',
      'First date must be less then Second': 'Первая дата должна быть ранее другой',
      'is not approved (or approved condition)': 'не подтверждено (или согласовано)',
      'The volume of current indicator is exceeded. ': 'Объем текущего индикатора превышен. ',
      'The volume of current potential is exceeded. ': 'Объем текущего потенциала превышен. ',
      'Can`t be empty': 'Не может быть пустым',
      'has status - closed': 'имеет статус "закрыто"',
      'Unpaid volume is greater than limit for spot contracts':
        'Неоплаченный превышает общий лимит ({} t)',
      'Unpaid volume is greater than limit for forward contracts':
        'Неоплаченный превышает общий лимит ({} t)',
      'Volume is greater than limit per one deal for spot contracts ({} t)':
        'Объем превышает лимит по одной сделке ({} t)',
      'Volume is greater than general limit for spot contracts ({} t)':
        'Объем превышает общий лимит ({} t)',
      'Volume is greater than limit per one deal for forward contracts ({} t)':
        'Объем превышает лимит по одной сделке ({} t)',
      'Volume is greater than general limit for forward contracts ({} t)':
        'Объем превышает общий лимит ({} t)',
      'Total value is greater than limit per one deal for spot contracts ({} USD)':
        'Сумма превышает лимит по одной сделке ({} t)',
      'Total value is greater than general limit for spot contracts ({} USD)':
        'Сумма превышает общий лимит ({} t)',
      'Total value is greater than limit per one deal for forward contracts ({} USD)':
        'Сумма превышает лимит по одной сделке ({} t)',
      'Total value is greater than general limit for forward({} USD)':
        'Сумма превышает общий лимит ({} t)',
      'With this volume open balance is greater than open balance limit for spot contracts ({} t)':
        'Объем открытого баланса превышает допустимый по спот контрактам ({} t)',
      'With this volume open balance is greater than open balance limit for forward contracts ({} t)':
        'Объем открытого баланса превышает допустимый по форвард контрактам ({} t)',
      'Contract already exist with number ': 'Контракт с указанным номером уже существует ',
      'Contract and general agreement must have same currency.':
        'Контракты и соглашение должны иметь одинаковую валюту.',
      'Shipment terminal required.': 'Необходимо заполнить поле Терминал.',
      'Supplier must be owner too': 'Поставщик также должен быть Владельцем',
      'Buyer must be owner too': 'Покупатель также должен быть Владельцем',
      'Credit limit required': 'Кредитный лимит обязателен',
      'Currency required': 'Валюта обязательна',
      'Contracts and general agreement must have same currency.':
        'Контракты и соглашение должны иметь одинаковую валюту.',
      'Contract plan must be unique by month and passport.':
        'Контракт план должен быть уникальным по месяцу и паспорту.',
      'Entry must be unique by month and passport.':
        'Контракт план должен быть уникальным по месяцу и паспорту.',
      'Entry must be unique by contract and derivative.':
        'Запись должна быть уникальной по контракту и деривативу.',
      'In forward contracts end of execution is required.':
        'В форвардных контрактах конец исполнения обязателен.',
      'In forward contracts conclusion date should be less than end of execution.':
        'В форвардных контрактах дата заключения должна начинаться раньше даты конца исполнения.',
      'Currency exchange should be local.': 'Обмен валюты должен быть локальным.',
      'Number should be positive.': 'Число должно быть положительным.',
      'Must include either a contract or a passport, fill in at least one field.':
        'Необходимо указать либо контракт, либо паспорт, заполните хотя бы одно поле.',
      'Must include a contract or a passport, fill in just one field.':
        'Необходимо указать контракт или паспорт, заполните только одно поле.',
      'Please select exchange rate': 'Пожалуйста, выберите обменный курс',
      'Currency exchange rate should be in {}': 'Курс валюты должен быть в {}',
      'Such sale plan already exists': 'Такой план продажи уже существует',
      'conclusion date can`t be in the past': 'дата заключения не может быть в прошлом времени',
      'conclusion date can`t be less then "Expiration start"':
        'Дата заключения не может быть меньше, чем “Начало истечения”',
      'conclusion date can`t be greater then "Expiration end"':
        'Дата заключения не может быть больше "Даты окончания"',
      'A ticket cannot be created with an unconfirmed indicator':
        'Невозможно создать тикет с неконфирмированным индикатором',
      'Cannot create ticket from expired indicator':
        'Невозможно создать тикет з просроченным индикатором',
      'Contract volume can`t be less than contract executed volume':
        'Объем контракта не может быть меньше объема исполненного контракта',
      'Contract {obj.contract_number} can`t be with draft status when execution exists':
        'Контракт {obj.contract_number} не может быть в статусе черновика, если есть исполнение',
      'Contract status of all positions can’t be Draft.':
        'Статус контракта по всем позициям не может быть «Черновик».',
      'Cannot create contract from lost ticket':
        'Невозможно создать контракт из просроченного тикета',
      'The receiver must have "buyer" role.': 'Получатель должен иметь роль «покупателя».',
      'The producer must be marked as a producer.':
        'Производитель должен быть отмечен как производитель.',
      'Consignment type must be set if there is no contract.':
        'При отсутствии договора необходимо указать тип накладной.',
      'Consignment type must be "{type}" for {contract_type} contract.':
        'Тип отгрузки должен быть “{type}” для контракта {contract_type}.',
      'you has no permissions to change approved ticket data':
        'У вас нет прав для изменения данных утвержденного тикета',
      'you have no permissions to change approved ticket data':
        'У вас нет прав на изменение данных утвержденного тикета',
      'Tolerance should be in range 0-100': 'Допуск должен быть в пределах от 0 до 100',
      'No contract': 'Без контракта',
      'No charges': 'Без затрат',
      'charge don`t have calculation': 'нет расчета для затрат',
      'Key {str(e)} does not exist': 'Ключ не существует',
      'No main option available': 'Нет доступной основной опции',
      'No main option available due to duplicates': 'Основной вариант недоступен из-за дубликатов',
      'The number of objects is not specified.': 'Количество объектов не указано.',
      'Contract purpose filter': 'Фильтр по назначению контракта',
      All: 'Все',
      'Personal info': 'Персональная информация',
      Permissions: 'Права',
      'Important dates': 'Важные даты',
      "File name contains the invalid characters (don't use special symbols like '<>|&=-;`/\\').":
        "Файл содержит неподдерживаемые символы(старайтесь не использовать символы по типу '<>|&=-;`/\\').",
      'Please check the data you would like to import. It seems that the spreadsheet has a lot of empty rows or to long.\nThe best way to import the registry is to copy only data to the new spreadsheet, save it and import again.\nIn case you see this message again please contact Support Manager.':
        'Пожалуйста, проверьте данные, которые хотите импортировать. В импортируемом файле много пустых рядов.\nЛучший способ импортировать реестр - это скопировать только данные в новый файл, сохранить и импортировать еще раз.\nВ случае, если это сообщение продолжается появляться - свяжитесь с вашим Саппорт менеджером.',
      English: 'English',
      Ukrainian: 'Украинский',
      Russian: 'Русский',
      Sunday: 'Воскресенье',
      Monday: 'Понедельник',
      Tuesday: 'Вторник',
      Wednesday: 'Среда',
      Thursday: 'Четверг',
      Friday: 'Пятница',
      Saturday: 'Суббота',
      'Dark theme': 'Темная тема',
      'White theme': 'Белая тема',
      'Collapsed navbar': 'Группировка навигации',
      'Open navbar': 'Группировка навигации',
      'Sale deals': 'Продажные сделки',
      'Purchase deals': 'Закупочные сделки',
      'Export deals': 'Экспорт сделок',
      'Intermediate deals': 'Промежуточные сделки',
      Direct: 'Прямой',
      General: 'Общий',
      Lineup: 'Лайнап',
      'Table view': 'Таблица',
      'List view': 'Список',
      'Block view': 'Блок',
      'Passport margin selector': 'Выбор маржи паспорта',
      Analytical: 'Аналитический',
      Contracts: 'Контракты',
      Execution: 'Исполнение',
      Invoices: 'Инвойсы',
      Payments: 'Оплаты',
      Enabled: 'Включено',
      Disabled: 'Отключено',
      Allocation: 'Распределение',
      'Risk position': 'Риск позиция',
      'Derivatives position': 'Позиция дериватива',
      'Consolidated crop position': 'Консолидированная позиция культуры',
      Processing: 'Процесс',
      Unloaded: 'Отгружено',
      'Contract tolerance': 'Опция контракта',
      'Ticket tolerance': 'Опция тикета',
      'Indicator tolerance': 'Опция индикатора',
      'Is default': 'по умолчанию',
      'Business Unit': 'Бизнес-юнит',
      Year: 'Год',
      Years: 'Года',
      'Type Of Activity': 'Вид деятельности',
      'Types Of Activity': 'Виды деятельности',
      Author: 'Автор',
      'Last editor': 'Редактор',
      Updated: 'Обновлено',
      Created: 'Создано',
      Yes: 'Да',
      'Site setting': 'Настройки сайта',
      'Table name': 'Название таблицы',
      'Column name': 'Название колонки',
      'New title': 'Новое название',
      Visible: 'Видимый',
      Users: 'Пользователи',
      'Columns Set Config': 'Конфигурация набора столбцов',
      'Form name': 'Имя формы',
      'Field name': 'Название поля',
      Required: 'Обязательно',
      title: 'название',
      'Filter level (table)': 'Фильтр таблица',
      Collection: 'Конфигурация',
      'New name': 'Новое имя',
      'Can use export button': 'Может использовать кнопку Экспорта таблиц',
      'Can view settings': 'Просмотр настроек',
      'Can view logentry': 'Просмотр истории действий пользователей',
      'Can view users': 'Просмотр списка пользователей',
      'Can view user details': 'Просмотр деталей пользователей',
      'Can edit closed elements': 'Может редактировать закрытые элементы',
      'Can view contracts analytics': 'Просмотр аналитики в контрактах',
      'Can view logistics analytics': 'Просмотр аналитики в логистике',
      'Can view finances analytics': 'Просмотр аналитики в финансах',
      'Can view clients analytics': 'Просмотр аналитики в клиентах',
      'Can view market analytics': 'Просмотр аналитики в рынке',
      'Can view passports analytics': 'Просмотр аналитики в паспорте',
      'Can edit approval status': 'Может редактировать статус апрува',
      'Can view playground templates': 'Можно просматривать песочницу шаблонов',
      'Profile Owner': 'Владелец профиля',
      'Business Unit Filter': 'Фильтр по бизнес юниту',
      'Application language': 'Язык приложения',
      'First day of week': 'Первый день недели',
      'Application color scheme': 'Цветовая схема',
      'Application font': 'Шрифт программы',
      'Navbar grouping': 'Расширенная навигация',
      'Direct or general services by default': 'Прямые или общие услуги по умолчанию',
      'Direct or general multiservices by default': 'Прямые или общие мультисервисы по умолчанию',
      'Sale or purchase deals by default': 'Продажа или закупка по умолчанию',
      'Sale or purchase general agreement by default':
        'Генеральный договор купли или продажи по умолчанию',
      'Warehouse type by default': 'Тип склада по умолчанию',
      'Lineup report by default': 'Лайнап по умолчанию',
      'Counterpartiew view by default': 'Отображение контрагентов по умолчанию',
      'Persons view by default': 'Отображение контактов по умолчанию',
      'Tickets view by default': 'Отображение тикетов по умолчанию',
      'Contracts view by default': 'Отображение контрактов по умолчанию',
      'Contract tab by default': 'Вкладка контракта по умолчанию',
      'Default margin view': 'Вид маржи по умолчанию',
      'Invoices view by default': 'Отображение инвойсов по умолчанию',
      'Vessels view by default': 'Отображение суден по умолчанию',
      'Passports view by default': 'Отображение паспортов по умолчанию',
      'Voyages view by default': 'Отображение рейсов по умолчанию',
      'Position report by default': 'Отчет позиция по умолчанию',
      Wizard: 'Мастер',
      'Hide navbar': 'Скрыть панель навигации',
      Recap: 'Резюме',
      'Help buttons expanded': 'Кнопки справки расширены',
      'Simplified system': 'Упрощенная система',
      'The length of the message than browser mail client can accept':
        'Длина сообщения превышает допустимую для почтового клиента браузера',
      'Warehouse page view by default': 'Вид страницы склада по умолчанию',
      'Warehouse-Rest': 'Склад-Остатки',
      'Warehouse Balance': 'Остаток на складе',
      'Warehouse list': 'Список складов',
      Trade: 'Торговля',
      Losses: 'Потери',
      Reservations: 'Резервации',
      'Stock Reserve Units': 'Резервные единицы запаса',
      'BI role name list': 'Список имен ролей BI',
      'User Profile': 'Профиль пользователя',
      'Filter by this unit': 'Фильтровать по этому юниту',
      'Is default in forms': 'по умолчанию',
      'Comment text': 'Текст комментария',
      Comments: 'Комментарии',
      'Custom statuses': 'Пользовательские статусы',
      'Start date': 'Дата начала',
      'End date': 'Дата окончания',
      'Is current': 'Текущее',
      Seasones: 'Сезонность',
      'Filter level': 'Уровень фильтра',
      'Ext Data': 'Внешние данные',
      Purposes: 'Назначения',
      Boolean: 'Булевий',
      String: 'Строка',
      Text: 'Текст',
      Choice: 'Выбор',
      'Multiple choices': 'Множественный выбор',
      Precision: 'Точность',
      'Custom field': 'Кастомное поле',
      'Custom fields': 'Кастомные поля',
      'Custom field`s choice value': 'Значение выбора кастомного поля',
      Purpose: 'Цель',
      'Date value': 'Дата (значение)',
      'Text value': 'Текстовое значение',
      'Boolean value': 'Логическое значение',
      'String value': 'Строковое значение',
      'Choice value': 'Значение выбора',
      'Choice values': 'Значения выбора',
      'Object`s custom field`s value': 'Значение специального поля объекта',
      'Object`s custom field`s values': 'Значения специальных полей объекта',
      'Target field name for a value to be used in':
        'Название целевого поля для значения, которое будет использоваться в',
      'Lower bound of the range': 'Нижняя граница диапазона',
      'Upper bound of the range': 'Верхняя граница диапазона',
      'Value to be applied within the range':
        'Значение, которое должно применяться в пределах диапазона',
      'Indicates if the value is a percentage': 'Указывает, является ли значение процентом',
      RangeRule: 'Правило диапазона',
      RangeRules: 'Правила диапазона',
      'Is main': 'Главный',
      'Select one or more basises': 'Выберите один или больше базисов',
      'Predictions order': 'Порядок заказов',
      'Choose one from: %(keys)s': 'Выберите один из: %(keys)s',
      'Select positions which you want to import':
        'Выберите позиции, которые вы хотите импортировать',
      'Please select positions': 'Пожалуйста, выберите позиции',
      "We couldn't find your GT Bot. Please contact with your Support Manager.":
        'Мы не смогли найти вашего GT бота. Пожалуйста, свяжитесь с вашим менеджером поддержки.',
      '{telegram_chat.user.profile.user.get_full_name()}, you already have a chat with us. \nIs it you?':
        '{telegram_chat.user.profile.user.get_full_name()}, вы уже общаетесь с нами.\nЭто вы?',
      'Hello, {telegram_chat.user.profile.user.get_full_name()}, you have been successfully authenticated.\nNow you can use our GT Bot to receive notifications.':
        'Здравствуйте, {telegram_chat.user.profile.user.get_full_name()}, вы успешно прошли аутентификацию.\nТеперь вы можете использовать наш GT Bot для получения уведомлений.',
      'Thank you for your answer. Chat is Active.\nFeel free to ask your Support Manager any questions.':
        'Спасибо за ваш ответ. Чат активен.\nНе стесняйтесь задавать любые вопросы менеджеру службы поддержки.',
      'Chat deactivated, contact with your Support Manager':
        'Чат деактивирован, свяжитесь с вашим менеджером поддержки.',
      'Not found': 'Не найдено',
      'Wrong credentials or api key': 'Неправильные учетные данные или ключ API',
      'Finished with status {}: {}': 'Завершено со статусом {}: {}',
      'Client connection timeout': 'Таймаут подключения клиента',
      "Transliteration tag translit available only with lang 'ua' or 'ru'.":
        "Тег транслитерации доступен только для языков 'ua' и 'ru'.",
      'Can not be converted value to number. Example: {{ price | to_float(3) }}, where price must be a number.':
        'Нельзя конвертировать значение в число. Пример: {{ price | to_float(3) }}, где цена должна быть числом.',
      'Can not be splitted value with negative number. It should be greater than 0.':
        'Нельзя разделить число с отрицательным значением. Оно должно быть больше 0.',
      'Can not split value with split_by. Example: {{ price | split_by(3) }}.':
        'Не можем разделить значение. Пример {{ price | split_by(3) }}.',
      'Syntax error: {}': 'Синтаксическая ошибка: {}',
      'Date should be in the present': 'Дата должна быть в настоящем',
      'Check volume': 'Проверьте объем',
      'Currency rate must be same as currency': 'Платеж и счет должны иметь одинаковою валюту',
      'One user can have only one default business unit':
        'Один пользователь может иметь только один Бизнес Юнит по умолчанию',
      'Wrong content type': 'неправильный тип содержания',
      'Crop year({crop_year}) should be in this list: {crop_years_list}':
        'Год урожая ({crop_year}) должен быть в этом списке: {crop_years_list}',
      'Year must be an integer': 'Год должен быть целым числом',
      'Year must be in 1984-2123 interval': 'Год должен быть в интервале с 1984 по 2123',
      'Only one year can be default. Current ': 'Только один год может быть дефолтным. Текущий ',
      'Lower bound and upper bound must be positive integers':
        'Нижняя и верхняя границы должны быть положительными целыми числами',
      'Lower bound must be less than upper bound.':
        'Нижняя граница должна быть меньше верхней границы.',
      'The range you want to create ({}-{}) overlaps with an existing range ({}-{}).':
        'Диапазон, который вы хотите создать ({}-{}), пересекается с существующим диапазоном ({}-{}).',
      'Percentage value must be between 0 and 100.':
        'Процентное значение должно быть в диапазоне от 0 до 100.',
      'No custom values provided': 'Кастомные значения не предоставлены',
      '{} matching query ({}={}) does not exist':
        '{} соответствующий запрос ({} = {}) не существует',
      'There are more than one {} (where {}={}) in the database - {}':
        'В базе данных более одного {} (где {} = {}) - {}',
      'This quality absents in crop quality': 'Это качество отсутствует в качестве урожая',
      'Title eng': 'Название (англ)',
      'Is positive': 'Положительный',
      moisture: 'Влажность, %',
      trash: 'мусор',
      'Code name': 'Код название',
      'code name for calculation duval': 'кодовое назначение для расчета duval',
      Quality: 'Качество',
      Qualities: 'Качества',
      'Use in templates': 'Использование в шаблонах',
      Tariff: 'Тариф',
      'Crop quality': 'Качество зерна',
      'Crop qualities': 'Показатели качества зерна',
      'Can choose CropQuality': 'Можно выбрать качество культуры',
      Packing: 'Упаковка',
      'container 20 dv, t': 'контейнер, 20',
      'container 40 dv, t': 'контейнер, 40',
      'Packing wagon, t': 'Вагон',
      'Packing car, t': 'Авто',
      'Prices section': 'Секция цен',
      'Is included in position': 'включить в позицию',
      'Charge-off ratio': 'Списание ставка',
      'Default VAT return in contracts for this cargo': 'Возврат НДС по умолчанию для этого товара',
      'Fetch derivatives': 'Получить деривативы',
      'Is analytic parent': 'Является аналитическим родителем',
      'Warehouse entry': 'Склад',
      'Warehouse entries': 'Склады',
      'Parse until date': 'Парсить до даты',
      Limit: 'Лимит',
      Plan: 'План',
      'Price discount': 'Цена со скидкой',
      'Use for volume': 'Использовать для объема',
      'Use for price': 'Применять к цене',
      'Use duval': 'Использовать дюваль',
      'From value': 'Из значения',
      'To value': 'К значению',
      Coefficient: 'Коэффициент',
      'Crop can not be a parent to itself.': 'Культура не может быть родительской сама для себя.',
      'More than two with same quality and commodity.':
        'Более двух с одинаковым качеством и товаром.',
      'Intermediate logistic': 'Промежуточная логистика',
      'Bill of landing': 'Коносамент',
      'Contract charge': 'Затрата контракта',
      'Disbursement bill of lading': 'Расходная накладная',
      Vehicle: 'Транспортное средство',
      Driver: 'Водитель',
      'Document title': 'Название документа',
      'Document status': 'Статус документов',
      'Document group name': 'Тег',
      'Checklist point': 'Контрольный пункт',
      document: 'документ',
      Reassignment: 'Переназначение',
      Offset: 'Зачет',
      'Disbursement BL': 'Расходная накладная',
      'Bill Of Lading': 'Коносамент',
      Model: 'Раздел',
      'Choose the block, to which this template will be attached. This means, you might use it only within this block':
        'Выберите блок, к которому прикреплять шаблоны. Это означает, что вы сможете использовать их в рамках этого блока',
      'These is the Title, only you will see, to identify current template':
        'Это Название. Видите его только вы, для того, что бы идентифицировать шаблон',
      'Attach file in docx format to use it when generate a document from object; you also might use tags from the hint':
        'Загрузите файл в формате .docx и используйте его чтобы сгенерировать документ со свойствами объекта; можно использовать теги из подсказок',
      'Download file name template': 'Шаблон имени файла для загрузки',
      'Document template': 'Шаблон документа',
      'Document templates': 'Шаблоны документов',
      'Owner content type': 'тип собственника',
      'View {title} document checklists': 'Просмотр {title} чеклиста документов',
      'View {title} documents': 'Просмотр названий документов',
      'Unsupported file extension. Valid extensions: ':
        'Неподдерживаемое расширения файла. Поддерживаемые расширения: ',
      'Intermediate Contract': 'Промежуточный Контракт',
      'Services Contract': 'Сервисный контракт',
      'Logistics mailing': 'Рассылка логистики',
      'Finances mailing': 'Рассылка инвойсов',
      Logistics: 'Логистика',
      'Bill of lading': 'Коносамент',
      'Intermediate multiticket': 'Промежуточный мультитикет',
      Failed: 'Провалено',
      Subject: 'Тема',
      Body: 'Тело письма',
      'Email template group name': 'Тег',
      'Email Template': 'Шаблон рассылки',
      'Email Templates': 'Шаблоны рассылки',
      'Email template': 'Шаблон рассылки',
      'Export params collection': 'Параметры экспорта',
      Mailing: 'Рассылка',
      'Mailing status': 'Статус отправки',
      'Template error: {}': 'Шаблон ошибки: {}',
      'Email sent successfully! Response: {}': 'Письмо успешно отправлено! Ответ: {}',
      'No permissions for changing of finance account': 'Нет прав на изменение финанс аккаунта',
      'Object can not be a child of itself.': 'Объект не может быть родительским сам для себя.',
      Paid: 'Оплачено',
      Canceled: 'Отменено',
      Done: 'Готово',
      Ready: 'Готово',
      Blocked: 'Заблокировано',
      Invalid: 'Неактуально',
      'Accrued interest': 'Начисленные проценты',
      'Accrued taxes': 'Начисленные налоги',
      'Subordinated loan': 'Субординированный кредит',
      "Shareholder's equity": 'Собственный капитал',
      "Shareholder's capital": 'Акционерный капитал',
      'Legal reserve': 'Обязательный резерв',
      Prepay: 'Предоплата',
      Balance: 'Баланс',
      Cargo: 'Груз',
      Costs: 'Затраты',
      'General expenses': 'Общие затраты',
      Gains: 'Поступления',
      Incoming: 'Входящие',
      Outgoing: 'Исходящие',
      Expense: 'Расходы',
      Income: 'Доход',
      Debit: 'Дебет',
      GET: 'GET',
      POST: 'ОПУБЛИКОВАТЬ',
      HTML: 'HTML',
      XML: 'XML',
      JSON: 'JSON',
      CSV: 'CSV',
      'Calendar days': 'Календарные дни',
      'Business days': 'Рабочие дни',
      '{} matching query (id={}) does not exist in the database.':
        '{} соответствующий запрос ({} = {}) не существует в базе данных.',
      'Transaction date': 'Transaction date',
      'Other activity': 'Другое назначение',
      'Debit finance account': 'Дебет финанс счет',
      'Credit finance account': 'Кредит финанс счет',
      'Debit account': 'Дебет счет',
      'Credit account': 'Кредит счет',
      'Has docs original': 'Есть оригинал доков',
      'Finance account': 'Финанс счет',
      'Date of receiving': 'Дата получения',
      'Will be used in calculator': 'Будет использовано в калькуляторе',
      'Use Type': 'Тип использования',
      'Tariff Type': 'Тип тарифа',
      Auto: 'Авто',
      Railway: 'Ж/д',
      Freight: 'Фрахт',
      Financing: 'Финансирование',
      Insurance: 'Страхование',
      Unloading: 'Разгрузка',
      Loss: 'Потери',
      'Chose specific tariff type.': 'Выберите тип тарифа.',
      Storage: 'Хранение',
      Receiving: 'Получение',
      Drying: 'Сушка',
      Cleaning: 'Очистка',
      Loading: 'Отгрузка',
      'Chose specific tariff to map it with this charge.':
        'Выберите тип тарифа для того чтобы связать его с этой затратой.',
      'Choose the type of counterparty, to which this charge will be\n            linked to.':
        'Выберите тип контрагента, к которому эта затрата \nбудет привязана.',
      'Use this charge as passport_multiplicator for passport':
        'Используйте эту затрату как passport_multiplicator для паспорта',
      'Include in financing': 'Учесть в финансировании',
      'Is analytic use': 'Используется в аналитике',
      'HS-Code': 'HS-Код',
      Charges: 'Затраты',
      'General expenseses': 'Общие затраты',
      'Other activities': 'Другое назначение',
      'Invoice #': 'Номер инвойса',
      'Date of certification': 'Дата сертификации',
      'Date of payment (plan)': 'Дата платежа (план)',
      'Date of payment': 'Дата платежа',
      'Date of withdrawal': 'Дата выдачи',
      'Counterpaty payment info': 'Контрагента платеж информация',
      'Own money': 'Собственные средства',
      'Amount USD': 'Сумма $',
      'Additional currency': 'Дополнительная валюта',
      'Additional exchange rate': 'Дополнительный курс обмена валют',
      'Payment rate': 'Курс оплаты',
      'Delivery status': 'Статус доставки',
      'Cargo not delivered': 'Груз не доставлен',
      'Role from': 'От кого',
      'Role to': 'Кому',
      'Not pay': 'Не платить',
      'Secret Invoice': 'Секретный инвойс',
      'Daily fines rate': 'Ежедневная ставка штрафа',
      'Fines days count': 'Количество дней штрафа',
      'Fines invoicing': 'Выставление счетов на оплату штрафов',
      'Don`t validate counterparties': 'Не валидировать контрагентов',
      'Related credit note': 'Связ кредит ноут',
      'Marine Cover Policy No.': 'Полис морского страхования No.',
      'Letter of Credit No.': 'Номер аккредитива No.',
      Assignment: 'Назначение',
      'Commercial Invoice': 'Коммерческий инвойс',
      'Proforma Invoice': 'Проформа инвойс',
      'Debit Note': 'Дебет нота',
      'Credit Note': 'Кредит нота',
      Reserve: 'Резерв',
      'Packing List': 'Товарная накладная',
      'Related invoice': 'Связанный инвойс',
      Marks: 'Метки',
      'PO number': 'Номер закупочного заказа',
      'Note one': 'Примечание один',
      'Note two': 'Примечание два',
      'Can see and create secret invoice': 'Просмотр секретных инвойсов',
      'Amount alternative': 'Альтернативная сумма',
      'Currency alternative': 'Альтернатива валюты',
      'Exchange rate alternative': 'Альтернативный курс обмена',
      'Commission USD': 'Комиссия дол',
      'Trade Rate': 'Обменный курс',
      'Bank operation': 'Банк операция',
      CreditNote: 'Кредит ноут',
      'Commission debit finance account': 'Комиссия дебет финанс счет',
      'Commission credit finance account': 'Комиссия кредит финанс счет',
      'No single vessel with charter party date': 'Ни одного судна с датой чартер партии',
      PaymentPlan: 'План оплат',
      'Can approve Payment plan': 'Может подтверждать планы оплат',
      'Invoice value': 'Инвойс сумма',
      'Payment Plan Finance': 'План оплаты инвойс',
      'Symbol/Code': 'Символ/Код',
      'Is local': 'Локальная',
      Currencies: 'Валюты',
      Rate: 'Ставка',
      'Load source': 'Источник загрузки',
      Parser: 'Парсер',
      'Weighted AVG from payments': 'Средневзвешенный курс по платежам',
      'Max retries count': 'Максимальное количество повторов',
      'Retry interval step in minutes': 'Интервал повтора шага в минутах',
      'Currency parser retry policy': 'Политика повторной попытки парсера валют',
      'Next parse rule': 'Следующее правило парсинга',
      'Parse rule title': 'Название правила парсинга',
      'Parse data type': 'Тип данных парсера',
      'Parse rule': 'Правило парсинга',
      'Response parse arguments': 'Парсинг аргументов ответа',
      'Currency parse rule': 'Правило парсинга валюты',
      'Currency parse rules': 'Правила парсинга валюты',
      'Retry policy': 'Политика повтора',
      'Parser label': 'Метка парсера',
      'Request url': 'URL заявки',
      'Request method': 'Метод запроса',
      'Request headers': 'Заголовки запросов',
      'Request body': 'Тело запроса',
      'Parse Time': 'Время парсинга',
      'Is the reverse course': 'Обратный курс',
      'Currency parser': 'Парсинг валют',
      'Currency parsers': 'Парсинги валют',
      Days: 'Дни',
      'payment condition': 'условия платежа',
      'Invoice type': 'Тип инвойса',
      'Balance payments': 'Балансовые платежи',
      Account: 'Счет',
      'Bank name Eng': 'Полное название (Англ)',
      'Bank code': 'Код банка',
      IBAN: 'IBAN',
      Swift: 'Свифт',
      'Contact info': 'Контактная информация',
      'Address Eng': 'Адрес Англ',
      'Corr bank': 'Банк корреспондент',
      'Corr IBAN': 'Корр. IBAN',
      'Corr swift': 'Корр свифт',
      'Corr account': 'Корр аккаунт',
      'Corr bank 1': 'Банк корреспондент 1',
      'Corr IBAN 1': 'Корр IBAN 1',
      'Corr SWIFT 1': 'Корр. SWIFT 1',
      'Corr Account 1': 'Корр. счет 1',
      'Corr bank 2': 'Банк корреспондент 2',
      'Corr IBAN 2': 'Корр IBAN 2',
      'Corr SWIFT 2': 'Корр. SWIFT 2',
      'Corr Account 2': 'Корр. счет 2',
      'Account currency': 'Валюта счета',
      'payment information': 'платежная информация',
      'Payment information': 'Платежная информация',
      Cash: 'Наличные',
      Regular: 'Обычный',
      Cryptocurrency: 'Криптовалюта',
      'Account type': 'Тип счета',
      'Bank name': 'Название банка',
      'Account name': 'Имя аккаунта',
      'Incoming reserve amount': 'Сумма входящего резерва',
      'Outgoing reserve amount': 'Сумма исходящего резерва',
      'Initial cash': 'Первоначальные денежные средства',
      'Account initial date': 'Дата создания аккаунта',
      'Payment Info': 'Платеж инфо',
      'Bank accounts': 'Банк счета',
      'Can change initial amount': 'Может менять первоначальную сумму',
      Passive: 'Пассив',
      'Payment type': 'Метод оплаты',
      'Finance Accounts': 'Финансовые счета',
      'Can configurate finblock entries': 'Может конфигурировать записи финблока',
      Description: 'Описание',
      Use: 'Использование',
      InvoicePosition: 'Инвойс позиция',
      Quantity: 'Количество',
      'From account': 'Со счета',
      'From counterparty': 'От контрагента',
      'To counterparty': 'К контрагенту',
      'To account': 'На счет',
      'Invoice position': 'Инвойс позиция',
      'Credit bank account': 'Кредит банк счет',
      'Debit bank account': 'Дебет банк счет',
      'Use convertation': 'Использовать конверсию',
      'Transaction number #': 'Номер транзакции #',
      'Transaction name': 'Наименование транзакции',
      'Simple fee': 'Простая комиссия',
      'Complex fee': 'Оплата комиссии',
      'Operation Type': 'Тип операции',
      Counterparty: 'Контрагент',
      'Related counterparty': 'Связанный контрагент',
      'Issuance Fee Type': 'Тип комиссия за выдачу',
      'Issuance Fee Persent': 'Простая комиссия за выдачу',
      'Cash back': 'Возврат денег',
      'Cachback amount': 'Сумма возврата денег',
      'Issuance Fee': 'Комиссия за выдачу',
      'Credit note #': 'Кредит ноут №',
      Liabilities: 'Пассивы',
      Role: 'Роль',
      'Secret credit note': 'Секретный кредит ноут',
      'credit note': 'кредит ноут',
      'Credit notes': 'Кредит ноуты',
      'Credit note': 'Кредит ноут',
      'credit note position': 'кредит ноут позиция',
      'Credit note positions': 'Кредит ноут позиции',
      Month: 'Месяц',
      'Budget Item': 'Бюджет',
      'Budget Items': 'Статьи бюджета',
      Budgets: 'Бюджеты',
      'Has docs copy': 'Есть копия доков',
      'Tax return registered': 'Возврат НДС зарегистрирован',
      'SAP registered': 'SAP зарегистрирован',
      'Amount correction': 'Корректировка суммы',
      'DisbursementBL Consignment': 'Расходная накладная',
      'DisbursementBL Consignments': 'Расходные накладные',
      'Reassignment rate': 'Переуступка ставка',
      'Parent Reassignment': 'Переуступка (основная)',
      Reassignments: 'Переназначения',
      'Disbursement bill or lading': 'Расходная накладная',
      'Reassignment amount': 'Переуступка сумма',
      'Reassignment Position': 'Переназначение',
      'Reassignment Positions': 'Переуступка позиции',
      Offsets: 'Зачеты',
      'Finance offset': 'Зачет',
      DisbursementBL: 'Расходная накладная',
      'Amount available': 'Доступная сумма',
      'Offset Position': 'Позиция зачета',
      'Offset Positions': 'Позиции Зачета',
      'Finance Purpose': 'Финансовая цель',
      'Finance Purposes': 'Финансовые цели',
      Lender: 'Кредитор',
      Borrower: 'Заемщик',
      'Margin, %': 'Маржа, %',
      'Libor, %': 'Libor, %',
      'Related fees': 'Связанные комиссии',
      Notes: 'Примеч',
      'Collateral, %': 'Обеспечение, %',
      'Finance purpose': 'Финансовая цель',
      'Related deal': 'Связанная сделка',
      'Destination country': 'Страна назначения',
      'Start date (plan)': 'Дата начала (план)',
      'Maturity date (plan)': 'Срок платежа (план)',
      'Maturity date': 'Срок платежа',
      'Interest payment date (plan)': 'Дата оплаты процентов (план)',
      'Interest payment date': 'Дата оплаты процентов',
      Loan: 'Займ',
      Loans: 'Займы',
      'Bank Group': 'Банковская группа',
      'Finance Bank': 'Финансы банк',
      'Finance Banks': 'Финансы банки',
      'Bank Branch': 'Банковское отделение',
      'Bank Branches': 'Банковские отделения',
      'Finance Bank Group': 'Группа Финансовых Банков',
      'Finance Bank Groups': 'Группы Финансовых Банков',
      'Invoice Position Config': 'Настройка инвойс позиций',
      'Actual date': 'Текущая дата',
      mount: 'сумма',
      'Bank Account`s rests': 'Остатки на банковском счете',
      'contr.': 'контр.',
      'inv.': 'инв',
      '"Use" filter value is empty or improper.': 'Значение «Use» фильтра пустое или неправильное.',
      AVG: 'СРЕДНЕЕ',
      Save: 'Сохранить',
      Delete: 'Удалить',
      'Save as new': 'Сохранить как новое',
      'Save and add another': 'Сохранить и добавить еще',
      'Save and continue editing': 'Сохранить и продолжить редактирование',
      'Test parser': 'Тест парсера валют',
      'created new Invoice': 'Создано новый инвойс',
      'Invoice date': 'Дата инвойса',
      'Rate must be greater than 0': 'Ставка должна быть больше 0',
      'You cant create rate for default currency':
        'Вы не можете создать курс для валюты по умолчанию',
      'Too long value': 'Слишком длинная сумма',
      "You can't to pay more than invoice's amount. PAYMENT_DOES_NOT_EXCEED_INVOICE is True":
        'Вы не можете оплатить больше суммы инвойса. Включена настройка PAYMENT_DOES_NOT_EXCEED_INVOICE',
      'Contracts in positions must be with the same counterparties.':
        'Контракты в инвойс позиции должны содержать одинаковых контрагентов.',
      'Invoice position with this use must have contract_id or passport_id or logistic_id':
        'Позиция инвойса с этим использованием должна иметь contract_id, passport_id или logistic_id',
      'Invoice position with this use, must have something one contract or passport':
        'Позиция инвойса с этим использованием должна иметь хотя бы один контракт или паспорт',
      'Plan amount sum exceeds invoice amount.': 'Плановая сумма превышает объем инвойса.',
      'Payment plan date cannot be earlier than issuance date':
        'Дата платежного плана не может быть раньше даты выдачи',
      'This supplier is not a distributor.': 'Этот поставщик не является дистрибьютором.',
      'You can`t edit Invoice Position with closed contract.':
        'Вы не можете редактировать Инвойс Позицию при закрытом контракте.',
      'You can`t change use of Invoice Position with use costs or gains.':
        'Вы не можете изменить назначение инвойс позиции при назначении доходы/затраты.',
      "Wrong use 'costs' with invoice type 'outgoing'":
        'Неправильное использование назначения затраты с типом инвойса исходящий',
      "Wrong use 'gains' with invoice type 'incoming'":
        'Неправильное использование назначения доходы с типом инвойса входящий',
      'Duplicated scenarios.': 'Дублированные сценарии.',
      'Disbursement volume exceeds available consignment volume. Available volume for consignment ':
        'Объем выплат превышает доступный объем партии. Доступный объем для партии ',
      'Warehouse receipts have different date with disbursement':
        'Складские квитанции имеют разную дату с выплатой',
      'You can`t create Finance with negative Full or Invoiced amount.':
        'Вы не можете создать Финансы с отрицательной полной или выставленной по счету суммой.',
      '{role} {name} is not approved (or approved condition)':
        '{role} {name} не подтверждена (или подтверждена с условием)',
      '{role} {name} has status - closed': '{role} {name} имеет статус - закрыто',
      'Main Bank Account for this currency already exists':
        'Основной банковский счет для этой валюты уже существует',
      'Invoice with this number already exists': 'Инвойс с этим номером уже существует',
      'The Bank Account is required. The bank account of the owner-company\n                     must be indicated on each invoice. Please, check your invoices.':
        'Банковский счет обязателен. Банковский счет компании-владельца\n                     должен быть указан на каждом счете. Пожалуйста, проверьте свои счета.',
      'All invoices are paid': 'Все инвойсы оплачены',
      "Amount by client can't be less zero.\n                     Please, check Payment Plan positions.":
        'Сумма по клиенту не может быть меньше нуля.\n                     Пожалуйста, проверьте позиции Плана оплаты.',
      'Consignment free volume is less than requested volume, free volume is {}':
        'Свободный объем партии меньше запрашиваемого, свободный объем составляет {}',
      weekend: 'выходные',
      'sat-sun': 'сб-вс',
      'fri-sat': 'пт-сб',
      'sun-mon': 'вс-пн',
      Countries: 'Страны',
      'Phone code': 'Телефонный код',
      Districts: 'Районы',
      Delivery: 'Доставка',
      Weighing: 'Взвешивание',
      Rerouting: 'Перенаправление',
      Actual: 'Фактический',
      Addresses: 'Адреса',
      'No Google credentials found for the current file.':
        'Не найдены учетные данные Google для текущего файла.',
      'You do not have permission to refresh these credentials.':
        'У вас нет разрешения на обновление этих учетных данных.',
      'Google credentials successfully refreshed.': 'Учетные данные Google успешно обновлены.',
      'Google Drive file created': 'Файл Google Drive создан',
      'Link to file': 'Ссылка на файл',
      'The credentials have expired or are invalid, if you are the owner of the file, please click the Refresh Google credentials button to re-authorize.':
        'Срок действия учетных данных истек или они недействительны. Если вы являетесь владельцем файла, нажмите кнопку «Обновить учетные данные Google» для повторной авторизации.',
      'Probably the credentials have expired or are invalid, if you are the owner of the file, please click the Refresh Google credentials button to re-authorize.':
        'Вероятно, срок действия учетных данных истек или они недействительны. Если вы являетесь владельцем файла, нажмите кнопку «Обновить учетные данные Google» для повторной авторизации.',
      'File has already been imported': 'Текущее состояние уже импортировано',
      'Found several vehicles with the save attributes.Please delete duplicates before import: {}':
        'Найдено несколько транспортных средств с одинаковыми аттрибутами. Пожалуйста, удалите дубликаты перед импортом: {}',
      FAS: 'FAS',
      CFR: 'CFR',
      EXW: 'EXW',
      CPT: 'CPT',
      CIF: 'CIF',
      FOB: 'FOB',
      DDP: 'DDP',
      DAP: 'DAP',
      CIP: 'CIP',
      DAT: 'DAT',
      FCA: 'FCA',
      Handysize: 'Хэндисайз',
      Handymax: 'Хэндимакс',
      Seawaymax: 'Сивэймакс',
      Aframax: 'Афрамакс',
      Suezmax: 'Суэцмакс',
      Panamax: 'Панамакс',
      'Post-Panamax': 'Пост-панамакс',
      Malaccamax: 'Малаккамакс',
      Capesize: 'Кэйпсайз',
      'Volume (depart, t)': 'Объем отправленый ГТД. (т)',
      'Volume (board, t)': 'Объем (загруженный, т)',
      'Vehicle types': 'Тип ТС',
      'Vehicle (number)': 'Транспорт (номер)',
      'Vehicle (number) alt': 'Номер т\\с (прицеп)',
      Revision: 'Ревизия',
      Imported: 'Импортировано',
      'File ID': 'Файл ID',
      'English name': 'Англ. название',
      'Full name (eng)': 'Полное имя (англ.)',
      'Bank details': 'Банковские реквизиты',
      'Bank details changed': 'Банковские реквизиты (изм.)',
      'Acceptance certificate': 'Сертификат о приемке',
      'Logistic bill of lading': 'Логистика коносамент',
      'Logistic bills of lading': 'Логистика коносаменты',
      Car: 'Авто',
      Wagon: 'Вагон',
      Container: 'Контейнер',
      Vessel: 'Судно',
      'Volume received': 'Полученный объем',
      'Volume departed': 'Объем отправленный',
      'Volume departed consignment': 'Объем (по ТТН)',
      'Volume departed real': 'Реальный объем отгрузки',
      'Volume boarded': 'Объем погружен',
      Main: 'Главный',
      'Loading (date)': 'Отгрузка (дата)',
      'Start usage (date)': 'Начало использ (дата)',
      'End usage (date)': 'Конец использ (дата)',
      'Consignment (#)': 'Consignment (#)',
      'Volume (depart consignment, t)': 'Объем отправленный по ттн. (т)',
      'Volume (received real, t)': 'Объем (полученный реальный (т)',
      'Volume (gross, t)': 'Объем (брутто, т)',
      'Crop (class)': 'Культура (класс)',
      'Exporter contract (#)': 'Экспортер (номер контракта)',
      'Receiving (date)': 'Получение (дата)',
      'Sale (date)': 'Продажа (дата)',
      'Boarded (date)': 'Погрузка (дата)',
      'Volume (receive, t)': 'Объем полученный (т)',
      'Volume (lost, t)': 'Объем (потери, т)',
      'Volume (trader, t)': 'Объем (торговый, т)',
      'Buyer 90 invoice': 'Покупатель 90 инвойс',
      'Buyer 10 invoice': 'Покупатель баланс счет',
      'Railway (date)': 'ЖД (дата)',
      'Vet (date)': 'Вет (дата)',
      'Quarantine (date)': 'карантин (дата)',
      'Quarantine required': 'Карантин обязателен',
      'Quality (date)': 'Качество (дата)',
      'Declaration (date)': 'Декларация (дата)',
      'Broker confirmation (date)': 'Подтверждение брокера (дата)',
      'Broker confirmation required': 'подтверждение брокера обязательно',
      'EUR 1 (date)': 'EUR 1 (дата)',
      'Additional document one': 'Дополнительный документ №1',
      'Additional document two': 'Дополнительный документ №2',
      'Additional document three': 'Дополнительный документ №3',
      'No balance invoice': 'Нет баланс инвойса',
      'Supplier 90 invoice': 'Поставщик 90 инвойс',
      'Supplier 10 invoice': 'Поставщик баланс счет',
      Penalties: 'Штрафы',
      'Costs in USD': 'Затраты',
      'Penalties in USD': 'Штрафы',
      'Usage cost': 'Затраты на использ',
      'Purchase init price': 'Исходная цена закупки',
      'Sale init price': 'Исходная цена продажи',
      'Sale rader amount': 'Сумма продаж трейдера',
      'Sale price multiplicator': 'Мультипликатор продажной цены',
      'Loading place': 'Место отгрузки',
      'Discharging place': 'Точка отгрузки',
      'Payment date': 'Дата платежа',
      'Costs vat': 'Затраты НДС',
      'Penalties vat': 'Штрафы НДС',
      'Is FCA': 'FCA',
      'Sale payment conditions': 'Продажа условия платежа',
      'Purchase payment conditions': 'Закупка условия платежа',
      'Result month': 'Результат за месяц',
      'Season year': 'Сезон год',
      'Invoice position for costs': 'Инвойс позиция для затрат',
      'Cargo brand': 'Марка груза',
      'BAGS WEIGHT IN (KGS)': 'ВСЕ МЕШКОВ В (КГ)',
      'NO OF BAGS': 'КОЛИЧЕСТВО МЕШКОВ',
      'Estimated receiving (date)': 'Предполагаемое получение (дата)',
      'Unload costs per t': 'Затраты на разгрузку за тонну',
      'Unload costs unit': 'Единица измерения расходов на разгрузку',
      'Use unload costs': 'Использовать затраты на разгрузку',
      'Forwarder contract': 'Контракт экспедитора',
      'Buyer invoice volume field': 'Покуп инвойс объем поле',
      'Supplier invoice volume field': 'Поставщ инвойс объем поле',
      'Loaded on board': 'Отгружено на борт',
      'Purpose invoicing': 'Цель инвойсирования',
      'Supplier invoiced': 'Поставщику выставлен инвойс',
      'Supplier paid': 'Поставщик оплатил',
      'Buyer invoiced': 'Покупателю выставлено инвойс',
      'Buyer paid': 'Покупатель оплатил',
      'Transloader invoiced': 'Перевалка проинвойсирована',
      'Transloader paid': 'Перевалка оплачена',
      'Vehicle type': 'Тип транспортного средства',
      'Driver first name': 'Имя водителя',
      'Driver last name': 'Фамилия водителя',
      'Driver license number': 'Номер водительского удостоверения',
      'Driver middle name': 'Отчество водителя',
      'Driver passport seria & number': 'Серия и номер паспорта водителя',
      'Driver phone number': 'Номер телефона водителя',
      'Deliverer in port': 'Доставщик в порту',
      'Unloading business unit': 'Бизнес-юнит разгрузки',
      'Shipment business unit': 'Бизнес-юнит отгрузки',
      'Charge type for losses': 'Тип затраты за потери',
      'Losses accounting method': 'Метод учета убытков',
      'Ignore loss costs': 'Игнорировать затраты на потери',
      'Create default loss costs': 'Создать стандартные затраты на потери',
      'Include loss costs in self-cost by default': 'Включить потери в себестоимость по умолчанию',
      'Dispatch Stock reserve unit': 'Единица резерва для отгрузки',
      'Receipt Stock reserve unit': 'Единица резерва для получения товара',
      Transport: 'Транспорт',
      'Can view logistic certs': 'Просмотр сертификатов по логистике',
      'Can view logistics purchase price': 'Просмотр закупочной цене в транспорте',
      'Can view logistics sale price': 'Просмотр продажной цены в транспорте',
      'Can view logistics finance tab': 'Просмотр вкладки Логистика',
      'Can view logistics export tab': 'Можно просмотреть вкладку «Экспорт логистики»',
      'Can view logistics road tab': 'Просмотр таба дорога логистики',
      'Can view logistics quality tab': 'Просмотр таба качества логистики',
      'Can view logistics full tab': 'Просмотр полного таба логистики',
      'Can view logistics rentability': 'Просмотр рентабельность в транспорте',
      'Terminal Transshipment': 'Перевалка на терминале',
      'Date from': 'Дата с',
      deliverer: 'экспедитор',
      'Departure station': 'Станция отправления',
      'Destination station': 'Станция назначения',
      farm: 'хозяйство',
      'Daily tariff': 'Дневной тариф',
      'Transportation tariff': 'Тариф',
      'Transportation tariffs': 'Тарифы',
      'Can view Tariffs': 'Просмотр списка логистических тарифов',
      'Can change Tariffs': 'Может изменять тарифы',
      Station: 'Станция',
      'Price in USD': 'Цена в USD',
      'Transshipment tariff': 'Перевалка тариф',
      'Transshipment tariffs': 'Перевалка тарифы',
      Freights: 'Фрахты',
      'Basis code name for calculation purposes': 'Код базиса для калькуляции',
      'Premium bonus percentage': 'Процент бонуса премии',
      basis: 'базис',
      'Name (short)': 'Короткое название',
      'Prices priority': 'Приоритет цен',
      port: 'порт',
      IMO: 'ИМО',
      Age: 'Возраст',
      'Owner info': 'Информация о владельце',
      'Managing company': 'Управляющая компания',
      Flag: 'Флаг',
      'Capacity avg': 'Средняя емкость',
      'Capacity min': 'Минимальная емкость',
      'Capacity max': 'Максимальная емкость',
      'Marine Traffic link': 'Marine Traffic',
      'vessel size': 'размер судна',
      Vessels: 'Судна',
      'POL under way': 'POL в пути',
      'At loading_port': 'В порту загрузки',
      Loaded: 'Отгружено',
      'POD under way': 'POD в пути',
      'At discharging port': 'В порту разгрузки',
      Discharging: 'Выгружается',
      Discharged: 'Выгружено',
      'Voyage name': 'Имя рейса',
      'Charter party (date)': 'Дата чартер партии',
      'Freighted volume (t)': 'Фрахт объем',
      'Disponent owner': 'Владелец диспонента',
      Charterer: 'Фрахтователь',
      'Laycan (start date)': 'Период допустимости (дата начала)',
      'Laycan (end date)': 'Период допустимости (дата окончания)',
      'Extension (start date)': 'Продление (дата начала)',
      'Extension (end date)': 'Продление (дата окончания)',
      'Sale contracts': 'Продажные контракты',
      'Date of loading start': 'Дата отгрузки старт',
      'Date of loading end': 'Дата отгрузки конец',
      'Date of notice loading': 'Дата нотиса (загрузка)',
      'Date of berth loading': 'Дата погрузки на причале',
      ETA: 'ETA',
      ETB: 'ETB',
      ETC: 'ETC',
      ETS: 'ETS',
      'Loading port': 'Порт отгрузки',
      'Load rate intermediate': 'Промежуточная скорость погрузки',
      'Date of loading start intermediate': 'Дата начала промежуточной погрузки',
      'Date of loading end intermediate': 'Дата конца промежуточной погрузки',
      'Date of notice loading intermediate': 'Дата уведомления о промежуточной погрузке',
      'Date of berth loading intermediate': 'Дата начала промежуточной погрузки на причале',
      'Loaded volume (t) intermediate': 'Объем погруженного груза (т) промежуточный',
      'ETA intermediate': 'ETA промежуточное',
      'ETB intermediate': 'ETB промежуточное',
      'ETC intermediate': 'ETC промежуточное',
      'ETS intermediate': 'ETS промежуточное',
      'Loading port intermediate': 'Промежуточный порт загрузки',
      'Disch rate intermediate': 'Промежуточная скорость разгрузки',
      'Date of discharging start intermediate': 'Дата начала промежуточной разгрузки',
      'Date of discharging end intermediate': 'Дата окончания промежуточной разгрузки',
      'Date of notice destination intermediate':
        'Дата уведомления о промежуточном месте назначения',
      'Date of berth destination intermediate':
        'Дата прихода на причал в промежуточном пункте назначения',
      'Volume discharged intermediate': 'Объем разгруженного груза (промежуточный)',
      'Destination port': 'Порт назначения',
      'Disch rate': 'Норма выгрузки',
      'Date of discharging start': 'Дата выгрузки старт',
      'Date of discharging end': 'Дата выгрузки конец',
      'Date of notice destination': 'Дата нотиса (выгрузка)',
      'Date of berth destination': 'Дата прихода на причал в пункте назначения',
      'Shortage rate': 'Шортадж ставка',
      'Demurrage rate': 'Ставка демереджа',
      'Freight costs': 'Фрахт затраты',
      'Freight info': 'Информация по фрахту',
      'Freight broker costs': 'Фрахт брокер затраты',
      'Loading dem/dis': 'Отгрузка дем/дис',
      'Destination dem/dis': 'Выгрузка дем/дис',
      'Additional costs': 'Дополнительные затраты',
      'Consecutive voyages': 'Последовательные рейсы',
      'Do not use in Freight position': 'Не применять в фрахтовой позиции',
      'Delivery condition': 'Вид транспортировки',
      'Intermediate delivery condition': 'Условия промежуточной поставки',
      'Destination delivery condition': 'Условия доставки на место назначения',
      'Intermediate destination delivery condition':
        'Условие доставки на промежуточный пункт назначения',
      'Free days JCC': 'Свободные дни JCC',
      'Free days EWRP': 'Свободные дни EWRP',
      'Volume intake (t)': 'Объем приема (т)',
      'Capacity est': 'Предполагаемая вместимость',
      'Tolerance, %': 'Толеранс, %',
      'Loaded volume (t)': 'Объем загруж',
      'Volume discharged': 'Объем разгружен',
      charterer: 'Чартеродержатель',
      'Voyage Few Commodity': 'Рейс нескольких товаров',
      'Min amount': 'Минимальная сумма',
      'Max amount': 'Максимальная сумма',
      'Use as cost': 'Использовать как затраты',
      'Use in positon': 'Использовать в позиции',
      'Voyage Tariff': 'Тариф рейса',
      'Voyage Tariffs': 'Тарифы рейса',
      'Name (long)': 'Название длинное',
      terminal: 'терминал',
      Direction: 'Направление',
      station: 'станция',
      Stations: 'Станции',
      'Loss type': 'Тип потери',
      'Decline quantity (batch self-costs will be increased)':
        'Уменьшение количества (себестоимость партии увеличится)',
      'Decline quantity and amounts (batch self-costs will be saved)':
        'Уменьшение количества и сумм (себестоимость партии сохранится)',
      'Logistic loss': 'Логистика потери',
      'Logistic losses': 'Логистические потери',
      'Place of destination': 'Место поставки',
      'Places of destination': 'Порт назначения',
      'Unloading volume (t)': 'Объем разгрузки (т)',
      Carrier: 'Перевозчик',
      LOI: 'LOI',
      'Can create bills of lading from logistics': 'Может создавать коносаменты с логистики',
      'Ship classification code name for idintification purposes':
        'Код имени класс судна для внутреннего назначения',
      'Ships classification': 'Класс лодки',
      Vet: 'Вет',
      Quarantine: 'Карантин',
      Declaration: 'Декларация',
      'EUR.1': 'Евро 1',
      'Broker confirmation': 'Подтверждение брокера',
      'Document one': 'Документ один',
      'Document two': 'Документ два',
      'Document three': 'Документ три',
      Document: 'Документ',
      'Type of certificate document': 'Тип сертификата',
      'Logistic Certificate Document': 'Сертификаты логистики документ',
      'Logistic Certificate Documents': 'Сертификаты логистики документы',
      'Transloaded date': 'Дата перевалки',
      'Transloaded volume': 'Объем перевалки',
      'Intermodal Transport': 'Промежуточный транспорт',
      'Intermodal Transports': 'Промежуточный транспорт',
      'Sample In date': 'Дата поступления образца',
      'Sample in no.': 'Образец в no.',
      'Sample type': 'Тип образца',
      Preshipment: 'До отправки',
      Stocklot: 'Остатки товара',
      'Sample out date': 'Дата исхода образц',
      'Sample out from': 'Проба из',
      'Quantity (MT)': 'Количество (МТ)',
      Received_date: 'Дата получения',
      receiver: 'Получатель',
      Courier: 'Курьер',
      'AWB No.': 'AWB No.',
      'Result status': 'Статус результата',
      Undefined: 'Неопределённый',
      Result_date: 'Дата результата',
      'Reason for rejection': 'Причина отказа',
      'Cargo Receiver': 'Получатель груза',
      'Receiver code': 'Код получателя',
      'Shipment Shedule': 'План отгрузки',
      'Shipment Shedules': 'Планы отгрузок',
      CTL: 'ЦТЛ',
      Own: 'Собственные',
      Leased: 'Арендованные',
      'Shipment Schedule': 'План отгрузок',
      'Cargo Shipper': 'Грузоотправитель',
      'rolling stock type': 'тип подвижного состава',
      'Shipment Shedule Position': 'План отгрузок позиция',
      'Shipment Shedule Positions': 'План отгрузок позиция',
      'Shipment Schedule Position': 'План отгрузок позиция',
      Day: 'День',
      Requested: 'По запросу',
      Shipped: 'Отгружено',
      'Shipment Schedule Position Day': 'План отгрузок день',
      'Shipment Schedule Position Days': 'План отгрузок дни',
      'Warehouse receipt': 'Складская накладная',
      Default: 'По умолчанию',
      'Code name for calculation purposes': 'Кодовое наименование для расчетных целей',
      'Vehicle subtype': 'Подтип транспортного средства',
      'Vehicle subtypes': 'Подтипы транспортного средства',
      'Date of issuance': 'Дата выставления',
      'Border Crossing Date': 'Дата пересечения границы',
      Overdue: 'Просроченный',
      'Declaration Type': 'Тип декларации',
      Import: 'Импорт',
      Transit: 'Транзит',
      'Chose specific declaration type.': 'Выберите конкретный тип декларации.',
      'Periodic customs declaration': 'Периодическая таможенная декларация',
      'cargo customs declaration': 'Таможенная декларация на груз',
      'Chose specific stage': 'Выберите конкретный этап',
      'Customs Declaration': 'Таможенная накладная',
      'Customs Declarations': 'Таможенные накладные',
      'Customs Declaration Invoice Position': 'Позиция инвойса таможенной декларации',
      'Customs Declaration Invoice Positions': 'Позиции инвойса таможенной декларации',
      'Customs Declaration Type': 'Тип таможенной декларации',
      'Customs Declaration Types': 'Типы таможенной декларации',
      'Driver first name (ENG)': 'Имя водителя (англ.)',
      'Driver last name (ENG)': 'Фамилия водителя (англ.)',
      'Driver passport seria & number (Ukrainian)': 'Серия и номер паспорта водителя (украинский)',
      'Driver license expiration date': 'Дата истечения срока действия водительского удостоверения',
      'Driver passport expiration date': 'Срок действия паспорта водителя',
      'Driver passport issue date': 'Дата выдачи паспорта водителя',
      Drivers: 'Водители',
      Trailer: 'Прицеп',
      'Vehicle registration number': 'Регистрационный номер автомобиля',
      'Vehicle brand': 'Марка транспортного средства',
      'Vehicle model': 'Модель транспортного средства',
      'Vehicle technical passport number': 'Номер технического паспорта транспортного средства',
      'Vehicle length': 'Длина транспортного средства',
      'Vehicle width': 'Ширина транспортного средства',
      'Vehicle altitude': 'Высота транспортного средства',
      'Vehicle unladen weight': 'Вес транспортного средства без груза',
      'Vehicle maximum weight': 'Максимальный вес транспортного средства',
      'Vehicle total number of sections': 'Общее число секций транспортного средства',
      'Vehicle general frame': 'Общая рама транспортного средства',
      'Number of sections': 'Количество секций',
      'Total section volume': 'Общий объем секции',
      Vehicles: 'Транспортные средства',
      Capacity: 'Емкость',
      'Working hours': 'Часы работы',
      'Works from': 'Работает с',
      'Works till': 'Работает до',
      responsible: 'ответственный',
      Coordinates: 'Координаты',
      Latitude: 'Широта',
      Longitude: 'Долгота',
      'Can view "Warehouse" page': 'Может видеть страницу "Склад"',
      'Can view "Warehouse-Rest" page': 'Может видеть страницу "Склад-Остатки"',
      'Can view "Warehouse Balance" page': 'Может видеть страницу "Баланс склада"',
      'Can view "Warehouse list" page': 'Может видеть страницу "Список складов"',
      'Can view "Shipment" page': 'Может видеть страницу "Отгрузки"',
      'Can view "Trade" page': 'Может видеть страницу "Торговля"',
      'Can view "Losses" page': 'Может видеть страницу "Потери"',
      'Can view "Stock Reserve Units" list': 'Может видеть список "Резервные единицы запаса"',
      'Can view "Reservation" list': 'Может видеть список "Резервы"',
      'Batch storage time': 'Время хранения партии',
      'Batch rest age (only on balance)': 'Остаток партии (только на балансе)',
      'Amount (basic)': 'Сумма (базовая)',
      'Total amount': 'Полная сумма',
      'Transportation storage expense': 'Расходы на транспортировку и хранение',
      'Discharge approval volume': 'Объем утверждения разгрузки',
      'Normal write-off coeff': 'Нормальный коэфф. списания',
      'logistic type': 'Тип логистики',
      'Cargo shipper': 'Отправитель груза',
      'Cargo receiver': 'Получатель груза',
      'Passport Connection Shipment Plan': 'Связь паспорта с планом отгрузки',
      'Passport Connection Shipment Plans': 'Связь паспорта с планами отгрузки',
      'Duplicated Logistic Ids: {}, Values: {}': 'Дублированные т/с ID: {}, значения: {}',
      "You can't import with executed contracts: {}":
        'Вы не можете импортировать с контрактом, у которого статус: "Выполнено" {}',
      "Can't convert to float value: '{}'. ZERO_ON_CONVERSE_UNITS_ERROR={}":
        "Невозможно преобразовать в плавающее значение: '{}'. ZERO_ON_CONVERSE_UNITS_ERROR={}",
      'Fix validation errors': 'Исправить ошибки валидации',
      'Required field.': 'Обязательное поле.',
      'value should be greater than 0': 'значение должно быть больше 0',
      'Consignments deficiency.': 'Дефицит накладных.',
      'Google Drive Create File': 'Создание файла в Google Drive',
      'Google Drive Import': 'Импорт Google Drive',
      'Booking confirmation': 'Подтверждение бронирования',
      'has its docs cut-off date on': 'установлено дедлайн по документам на',
      'Plans plan, start, end dates must be in one         month':
        'Дата начала и окончания должны быть в пределах         месяца',
      'Elevator tariff must be unique with crop, charge, date from.':
        'Тариф элеватора должен быть уникальным для продукта, затраты и даты.',
      'Supplier nineteen invoice number field does not match with number from imported file':
        'Значение номера инвойса на предоплату от поставщика не совпадает с номером из импортированного файла',
      'Buyer nineteen invoice number field does not match with number from imported file':
        'Значение номера инвойса на предоплату покупателю не совпадает с номером из импортированного файла',
      'Supplier ten invoice number field does not match with number from imported file':
        'Значение номера балансового инвойса от поставщика не совпадает с номером из импортированного файла',
      'Buyer ten invoice number field does not match with number from imported file':
        'Значение номера балансового инвойса покупателю не совпадает с номером из импортированного файла',
      'No sale contract specified for sale date': 'Не выбран продажный контракт для даты продажи',
      'You cannot amend logistic if balance invoice is settled':
        'Невозможно изменить логистику, если баланс по счету фактуры урегулирован',
      'Logistic with this acceptance certificate already exists.':
        'Логистика с этим сертификатом приема уже существует.',
      'You need clean "Supplier 90 invoice" and "Supplier 10 invoice" fields before change contract':
        'Нужно очистить поля “Счет поставщика 90” и “Счет поставщика 10” перед изменением контракта',
      'You need clean "Buyer 90 invoice" and "Buyer 10 invoice" fields before change contract':
        'Нужно очистить поля “Счет покупателя 90” и “Счет покупателя 10” перед изменением контракта',
      'CCD with this acceptance certificate already exists.':
        'Таможенная грузовая декларация с этим актом приемки уже существует.',
      'Loss should have one warehouse.': 'У потерь должен быть один склад.',
      'Object should have one warehouse.': 'Объект должен содержать только один склад.',
      'Loss should have same business unit as contract':
        'Потеря должна иметь тот же бизнес юнит что и контракт',
      'Warning: Logistics Volume Exceeds Warehouse Receipt':
        'Предупреждение: Объем логистики превышает объем на складе',
      'Bill of lading must have at least one contract.':
        'Коносамент должен содержать хотя бы один контракт.',
      'Charge type for losses must be empty for chosen losses costs accounting method':
        'Тип затрат для потерь должен быть пустым для выбранного метода учета потерь',
      'Charge type for losses can`t be empty for chosen losses costs accounting method':
        'Тип затраты для потерь не может быть пустым для выбранного метода учета потерь',
      'Disbursement bill of lading can have only logistic or intermediatelogistic, not both.':
        'Расходная накладная может иметь либо логистику, либо промежуточную логистику, но не обе одновременно.',
      'Contract is required.': 'Контракт обязателен.',
      'End date must be after start date.': 'Дата окончания должна быть позже даты начала.',
      'Can not load logistic to itself.': 'Невозможно подгрузить т/с.',
      'Logistics are looped.': 'Логистика зациклена.',
      'Can`t connect to contract more volume it has':
        'Невозможно подключиться к контракту большего объема, который у него есть',
      'Should have only one position freight': 'Должна быть только одна позиция для фрахта',
      'Can`t allocate more volume that invoice position has. ':
        'Нельзя выделить больше объема, чем указано в позиции инвойса. ',
      'This invoice exceeds logistic volume.': 'Этот счет превышает логистический объем.',
      'You shoud set export contract list filter':
        'Вам следует установить фильтр экспорта списка контрактов',
      'Voyage and passport are required': 'Рейс и паспорт обязательны',
      'Passport not found': 'Паспорт не найден',
      'Voyage not found': 'Рейс не найден',
      'Passport connected to voyage': 'Паспорт подключен к рейсу',
      'Wrong value for invoicing_method. It should be on od (main_warehouse, warehouse_from_positions)':
        'Неверное значение для invoicing_method. Должно быть на od (main_warehouse, warehouse_from_positions)',
      "Volume departed ({}t) greater than supplier contract's volume ({}t) (option included). Set {} to False to prevent this validation":
        'Отправленный объем ({}t) превышает объем закупочного контракта ({}t) (даже с учетом опциона)',
      "Logistic.loading_date ({}) can't be earlier than (purchase) contract.date_of_execution ({}) and later than contract.end_of_execution ({})":
        'Дата отгрузки тс ({}) cне должна быть раньше даты начала контракта ({}) и позже его даты окончания ({})',
      'With lookup by vessel vessel_name and buyer_name must be set':
        'Для сопоставления по судну название судна и имя покупателя должно быть заполнено',
      'Contract can’t be with draft status.': 'Контракт не может быть в статусе черновика.',
      'webhook filled': 'вебхук заполнен',
      No: 'Нет',
      'Select Source User': 'Выберите исходного пользователя',
      'Select Target User': 'Выберите целевого пользователя',
      'Subscriptions copied successfully from {} to {}.':
        'Подписки успешно скопированы с {} на {}.',
      'available in bot': 'доступен в боте',
      'Approval created': 'Согласование создано',
      'Approval resent request': 'Повторный запрос на одобрение',
      'Approval updated': 'Подтверждение обновлено',
      'Client created': 'Создан контрагент',
      'Client updated': 'Контрагент обновлен',
      'Client status changed': 'Статус клиента изменен',
      'Client approving finished': 'Подтверждение контрагента завершено',
      'ClientCommentary created': 'Комментарий по клиенту создан',
      'Client proxy deadline today': 'Крайний срок назначения клиентского прокси сегодня',
      'Client Update notification at notify time':
        'Уведомление о обновлении клиента в момент уведомления',
      'Client Update created': 'Обновление контрагента создано',
      'Client Update updated': 'Обновление контрагента обновлено',
      'Client deleted': 'Контрагент удален',
      'contractbase volume_estimated_open changed':
        'Изменен объем contractbase volume_estimated_open',
      'contractbase ending_status changed': 'Изменен финальный статус контракта',
      'contractbase final_volume clicked': 'Финальный объем контракта кликнут',
      'Contract "Cargo confirmation" stage changed': 'Этап “Подтверждение груза” контракта изменен',
      'Contract "Payment confirmation" stage changed':
        'Изменен этап контракта “Подтверждение оплаты”',
      'Contract "Final confirmation" stage changed':
        'Этап “Окончательное подтверждение” контракта изменен',
      'Contract "Cancel" stage changed': 'Изменен этап «Отмена» контракта',
      'Purchase contract created': 'Создан закупочный контракт',
      'Purchase contract xlsx exported': 'Закупочный контракт xlsx экспортирован',
      'Purchase contract approving finished': 'Согласование закупочного контракта финализировано',
      'Purchase contract updated': 'Закупочный контракт обновлен',
      'Purchase contract deleted': 'Закупочный контракт удален',
      "Ticket volume exceeds indicator's free volume":
        'Объем тикета превышает доступный объем индикатора',
      "Few price volume exceeds indicator's free volume":
        'Объем по цене превышает доступный объем индикатора',
      'Request created': 'Тикет создан',
      'Request updated': 'Запрос обновлен',
      'Request canceled': 'Тикет отклонен',
      'Request saved': 'Тикет сохранен',
      'Request approving started': 'Подтверждение тикета началось',
      'Request first approving finished': 'Запрос на первое утверждение завершен',
      'Request approving finished': 'Подтверждение контрагента завершено',
      'Sale contract created': 'Создан контракт на продажу',
      'Sale contract xlsx exported': 'Продажный контракт xlsx экспортируется',
      'Sale contract created in usd': 'Создан контракт на продажу в $',
      'Sale contract approving finished': 'Согласование контракта на продажу финализировано',
      'Sale contract updated': 'Продажный контракт обновлен',
      'Sale contract deleted': 'Продажный контракт удален',
      'Services contract created': 'Сервисный контракт создан',
      'Services contract approving finished': 'Согласование сервисного контракта завершено',
      'Services contract updated': 'Сервисный контракт обновлен',
      'General Agreement created': 'Генеральное соглашение создано',
      'General Agreement xlsx exported': 'Генеральное соглашение xlsx экспортируется',
      'General Agreement approving finished': 'Согласование Общего соглашения финализировано',
      'Consignment created': 'Накладная создана',
      'Consignment updated': 'Отгрузка обновлена',
      'Consignment deleted': 'Отгрузка удалена',
      'Prices Position Indicator(MTM price) created': 'Создан индикатор позиции цен (MTM цена)',
      'Prices Position Indicator(MTM price) updated': 'Обновлен индикатор позиции цен (MTM цена)',
      'Prices Position Indicator(MTM price) deleted': 'Удален индикатор позиции цен (MTM цена)',
      'Contract basis doc created': 'Создан документ на основе контракта',
      'Contract basis doc updated': 'Обновлен документ о контракта',
      'Contract basis doc deleted': 'Документ контракта удален',
      'Export contract created': 'Экспортный контракт создан',
      'Export contract updated': 'Экспортный контракт обновлен',
      'Export contract approving finished': 'Согласование экспортного контракта завершено',
      'Export contract approving started': 'Согласование экспортного контракта начато',
      'Intermediate contract created': 'Промежуточный контракт создан',
      'Intermediate contract updated': 'Промежуточный контракт обновлен',
      'Intermediate contract approving finished': 'Согласование промежуточного контракта завершено',
      'Intermediate contract approving started': 'Согласование промежуточного контракта начато',
      'Sale multicontract created': 'Продажный мультиконтракт создан',
      'Sale multicontract updated': 'Продажный мультиконтракт обновлен',
      'Sale multicontract deleted': 'Продажный мультиконтракт удален',
      'Sale multicontract approving finished': 'Согласование продажного мультиконтракта завершено',
      'Purchase multicontract created': 'Закупочный мультиконтракт создан',
      'Purchase multicontract updated': 'Закупочный мультиконтракт обновлен',
      'Purchase multicontract deleted': 'Закупочный мультиконтракт удален',
      'Purchase multicontract approving finished':
        'Согласование закупочного мультиконтракта завершено',
      'Intermediate multicontract created': 'Промежуточный мультиконтракт создан',
      'Intermediate multicontract updated': 'Промежуточный мультиконтракт обновлен',
      'Intermediate multicontract deleted': 'Промежуточный мультиконтракт удален',
      'Intermediate multicontract approving finished':
        'Согласование промежуточного мультиконтракта завершено',
      'Export multicontract created': 'Экспортерный мультиконтракт создан',
      'Export multicontract updated': 'Экспортерный мультиконтракт обновлен',
      'Export multicontract deleted': 'Экспортерный мультиконтракт удален',
      'Export multicontract approving finished':
        'Согласование экспортного мультиконтракта завершено',
      'Services multicontract created': 'Сервисный мультиконтракт создан',
      'Services multicontract updated': 'Сервисный мультиконтракт обновлен',
      'Services multicontract deleted': 'Сервисный мультиконтракт удален',
      'Services multicontract approving finished':
        'Согласование сервисного мультиконтракта завершено',
      'Sale ticket created': 'Продажный тикет создано',
      'Sale ticket updated': 'Продажный тикет обновлено',
      'Sale ticket deleted': 'Продажный тикет удалено',
      'Sale ticket approving finished': 'Согласование продажного тикета завершено',
      'Purchase ticket created': 'Закупочный тикет создан',
      'Purchase ticket updated': 'Закупочный тикет обновлен',
      'Purchase ticket deleted': 'Закупочный тикет удален',
      'Purchase ticket approving finished': 'Согласование закупочного тикета завершено',
      'Export ticket created': 'Экспортный тикет создано',
      'Export ticket updated': 'Экспортный тикет обновлено',
      'Export ticket deleted': 'Экспортный тикет удален',
      'Export ticket approving finished': 'Согласование экспортного тикета завершено',
      'Services ticket created': 'Сервисный тикет создано',
      'Services ticket updated': 'Сервисный тикет обновлено',
      'Services ticket deleted': 'Сервисный тикет удалено',
      'Services ticket approving finished': 'Согласование сервисного тикета завершено',
      'Comment created': 'Создан комментарий',
      'Approver commented request': 'Подтверждающий прокомментировал тикет',
      'Control created': 'Контроль создан',
      'Contract charge created': 'Создана плата по контракту',
      'Contract charge updated': 'Плата по контракту обнавлена',
      'Contract charge deleted': 'Плата по контракту удалена',
      'Commodity created': 'Товар создан',
      'Commodity updated': 'Товар обновлен',
      'Commodity deleted': 'Продукт удалён',
      'Document uploaded': 'Документ загружен',
      'Passport custom status changed': 'Уведомления на изменение кастомного статуса паспорта',
      'Passport created': 'Паспорт создан',
      'Passport updated': 'Паспорт обновлен',
      'Passport approving finished': 'Подтверждение паспорта завершено',
      'Control Passport P&L': 'Контроль P&L паспорта',
      'Passport margin changed (distinct to "Margin control")':
        'Изменена маржа паспорта (отличается от “Контроль Маржи”)',
      'Passport deleted': 'Паспорт удален',
      'Finance approving finished': 'Согласование Инвойса финализировано',
      'Finance created': 'Инвойс создан',
      'Finance updated': 'Инвойс обновлен',
      'Finance deleted': 'Инвойс удален',
      'Payment created': 'Оплата создана',
      'Payment updated': 'Платеж обновлен',
      'Payment deleted': 'Платеж удален',
      'PaymentPlan created': 'План оплат создан',
      'PaymentPlan updated': 'План оплат обновлен',
      'Disbusment BLS created': 'Расходная накладная создана',
      'Disbusment BLS updated': 'Расходная накладная обновлена',
      'Disbusment BLS deleted': 'Расходная накладная удалена',
      'PaymentPlan approving finished': 'План оплаты инвойс',
      'Voyage created': 'Рейс создан',
      'Voyage updated': 'Рейс обновлен',
      'Voyage ETA today': 'ETA лодок сегодня',
      'VoyageFewCommodity volume_intake updated': 'Объем потребления грузовых перевозок обновлен',
      'Bill Of Lading created': 'Коносамент создан',
      'Bill Of Lading updated': 'Коносамент обновлен',
      'Logistic import finished': 'Импорт логистики завершен',
      'Logistic created': 'Логистика создана',
      'Logistic updated': 'Логистика обновлена',
      'Logistic deleted': 'Логистика удалена',
      'Logistic uploaded': 'Логистика загружена',
      'Logistic canceled': 'Логистика отменена',
      'Logistic create qualities finished': 'Завершен контроль качества логистик',
      'Port created': 'Порт создан',
      'Port updated': 'Порт обновлен',
      'Port deleted': 'Порт удален',
      'Terminal created': 'Терминал создан',
      'Terminal updated': 'Терминал обновлен',
      'Terminal deleted': 'Терминал удален',
      'Warehouse loss created': 'Складские потери созданы',
      'Warehouse loss updated': 'Складские потери обновлены',
      'Warehouse loss deleted': 'Складские потери удалены',
      'Operation created': 'Операция создана',
      'User custom event': 'Пользовательское событие',
      'Customs cargo declaration created': 'Таможенная декларация создана',
      'Customs cargo declaration updated': 'Таможенная декларация обновлена',
      'Customs cargo declaration deleted': 'Таможенная декларация удалена',
      'Contract signed': 'Контракт подписан',
      'Notification bot': 'Бот уведомлений',
      'Client bot': 'Клиентский бот',
      '💬 Add comment': '💬 Добавить комментарий',
      '✅Approve/❌Decline': '✅Одобрить/❌Отклонить',
      '🔗 Open in browser': '🔗 Открыть в браузере',
      'Enable/Disable subscription': 'Включить/отключить подписку',
      'Append objects url to body text': 'Добавлять гиперссылку объекта к телу письма',
      'Include Approve/Decline action buttons': 'Добавлять кнопки Подтвердить/Отклонить',
      'POST http request with template as application/json':
        'POST http-запрос с шаблоном как application/json',
      'Send simple webhook without template': 'Отправить простой вебхук без шаблона',
      'Invalid JSON: {e}': 'Неверный JSON: {e}',
      "Header values can't contain newlines": 'Заголовки не могут содержать новые строки',
      'Telegram bot': 'Телеграм-бот',
      'Enable/Disable chat': 'Включить/отключить чат',
      'Read-only field, will be filled automatically':
        'Поле только для чтения, будет заполнено автоматически',
      'User agreement': 'Пользовательское Соглашение',
      'Chat language': 'Язык чата',
      'Chat user role': 'Роль пользователя чата',
      Manager: 'Менеджер',
      'Clients bot': 'Клиентские боты',
      'Clients bots': 'Клиентские боты',
      'Notifications bot': 'Бот уведомлений',
      'Notifications bots': 'Боты уведомлений',
      Create: 'Создано',
      Edit: 'Редактировать',
      'Final confirmation': 'Финальное подтверждение',
      'Custom status change': 'Пользовательские статусы',
      Info: 'Инфо',
      Warning: 'Внимание',
      Error: 'Срочно',
      'Quality comment': 'Оценка качества',
      'Upload instruction URL': 'URL инструкций',
      'Upload instruction text': 'Загрузить текст инструкции',
      'report url': 'URL отчета',
      'User with this username does not exist.':
        'Пользователь с таким именем пользователя не существует.',
      'Multiple users with this username exist.':
        'Существует несколько пользователей с таким именем.',
      'User does not exist': 'Пользователь не существует',
      'Multiple users with this Telegram username exist':
        'Существует несколько пользователей с таким именем в Telegram',
      Authorization: 'Авторизация',
      'Telegram user name': 'Имя пользователя Telegram',
      'Phone number': 'Номер телефона',
      Language: 'Язык',
      Lookup: 'Поиск',
      'Current client': 'Текущий клиент',
      Notifications: 'Уведомления',
      'My notifications': 'Мои уведомления',
      'Subscribe notifications': 'Подписаться на уведомления',
      'Plus for price': 'плюс к цене',
      Operations: 'Операции',
      'Create application': 'Создать заявку',
      'Approval config': 'Конфигурация подтверждения',
      'Please, input number!!': 'Пожалуйста, введите номер!!',
      'Upload volume greater register volume more then +15%':
        'Объем загрузки превышает объем регистрации больше чем на +15%',
      'Open contract volume less then you try to upload':
        'Объем открытого контракта меньше, чем вы пытаетесь загрузить',
      'Volume can`t be less ZERO!!': 'Объем не может быть меньше нуля!!',
      "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_received or 0)) %}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%}rest: {{volume_format | format(volume | float)}}{{_('mt')}}":
        "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_received or 0)) %}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%}rest: {{volume_format | format(volume | float)}}{{_('mt')}}",
      'Transport upload': 'Транспорт загружен',
      'Create client': 'Создать контрагента',
      'Company code': 'Код',
      Roles: 'Роли',
      'Business units': 'Бизнес-юниты',
      'Add contact person': 'Добавить контактное лицо',
      'Contact person': 'Контактное лицо',
      'The Opened Ticket': 'Открытый тикет',
      'Second name': 'Фамилия',
      'Telegram nickname': 'Никнейм в Telegram',
      Relations: 'Связи',
      'Add person': 'Добавить контакт',
      'Open contract volume less then you try to register':
        'Объем открытого контракта меньше, чем вы пытаетесь зарегистрировать',
      'You try to register too big volume': 'Вы пытаетесь зарегистрировать слишком большой объем',
      'The expected delivery date is not included in the delivery period according to the contract':
        'Ожидаемая дата доставки не входит в период доставки согласно контракту',
      "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_departed or 0))%}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%}rest: {{volume_format | format(volume | float)}}{{_('mt')}}":
        "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_departed or 0))%}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%}rest: {{volume_format | format(volume | float)}}{{_('mt')}}",
      '"The car number"': '«Номер автомобиля»',
      'was entered in an invalid format, the number of characters of the car number must be 8, the letters are Cyrillic!':
        'был введен в неверном формате, количество символов в номере автомобиля должно быть 8, буквы кириллицей!',
      '"The trailer number"': '«Номер трейлера»',
      'was entered in an invalid format, the number of characters of the trailer number must be 8 or less':
        'был введен в неверном формате, количество символов в номере прицепа должно быть 8 или меньше',
      '"Driver first name"': '«Имя водителя»',
      'contains not alphabetical symbols!': 'содержит неалфавитные символы!',
      '"Driver middle name"': '«Отчество водителя»',
      '"Driver last name"': '"Фамилия водителя"',
      'Driver phone number (+380...)': 'Телефон водителя (+380...)',
      '"Driver phone number"': '«Телефон водителя»',
      'Phone number must start with "+" and contains 8-14 digits only':
        'Номер телефона должен начинаться с “+” и содержать только 8-14 цифр',
      'Estimated delivery date': 'Предполагаемая дата поставки',
      'Transport order': 'Заказ транспорта',
      'Delivery address title': 'Адрес доставки',
      Duration: 'Продолжительность',
      '1 day': '1 день',
      '3 days': '3 дня',
      '5 days': '5 дней',
      '7 days': '7 дней',
      '10 days': '10 дней',
      '14 days': '14 дней',
      'Delivery end date': 'Дата окончания доставки',
      'Delivery addresses': 'Адреса доставки',
      'The Fixed Ticket': 'Фиксированный тикет',
      user: 'пользователь',
      phone: 'телефон',
      Tolerance: 'Опция',
      Reports: 'Отчеты',
      'Sale report': 'Отчет о продаже',
      'Delivery report': 'Отчет о доставке',
      'Payments report': 'Отчет о платежах',
      'Not approved tickets': 'Не утвержденные тикеты',
      'You have no waiting for approve tickets!!!': 'Вам не нужно ждать одобрения тикетов!!!',
      'Rejected tickets': 'Отклоненные тикеты',
      'You have no rejected tickets!!!': 'У вас нет отклоненных тикетов!!!',
      'New contracts': 'Новые контракты',
      'You have no new contracts!!!': 'У вас нет новых контрактов!!!',
      'Contracts in process': 'Контракты в процессе',
      'You have no contracts in process!!!': 'У вас нет незавершенных контрактов!!!',
      'Executed contracts': 'Выполненные контракты',
      'You have no executed contracts!!!': 'У Вас нет исполненных договоров!!!',
      'Contracts rests': 'Контракты заключены',
      'Not implemented yet': 'Еще не реализовано',
      Reconciliation: 'Сверка',
      Settings: 'Настройки',
      'Accept agreement': 'Принять соглашение',
      'Decline agreement': 'Отклонить соглашение',
      'Something went wrong:\n': 'Что-то пошло не так.\n',
      'Please accept the user agreement.': 'Пожалуйста, примите пользовательское соглашение.',
      'You have registered as an authorized person supplier ':
        'Вы зарегистрированы как уполномоченное лицо поставщика ',
      'Unfortunately, we were unable to identify your account.':
        'К сожалению, мы не смогли идентифицировать ваш аккаунт.',
      'Please contact your manager for additional information.':
        'За дополнительной информацией обращайтесь к своему менеджеру.',
      'For continue you must fill username in your telegram account':
        'Для продолжения необходимо заполнить имя пользователя в вашем аккаунте Telegram',
      Back: 'Назад',
      'Build report': 'Составить отчет',
      Clear: 'Очистить',
      Home: 'Главная',
      Next: 'Далее',
      Prev: 'Назад',
      FIND: 'НАЙТИ',
      'Clear Lookup': 'Очистить поиск',
      'Please, choose: ': 'Пожалуйста, выберите: ',
      'Please, input: ': 'Пожалуйста, введите: ',
      'Please, select: ': 'Пожалуйста, выберите: ',
      Mo: 'Mo',
      Tu: 'Вт',
      We: 'Ср',
      Th: 'Чт',
      Fr: 'Пт',
      Sa: 'Сб',
      Su: 'Вс',
      'Something went wrong': 'Что-то пошло не так',
      'Please, make your choice:': 'Пожалуйста, сделайте ваш выбор:',
      'Error occurred': 'Произошла ошибка',
      'Something went wrong:': 'Что-то пошло не так:',
      'Please fill all fields': 'Пожалуйста, заполните все поля',
      'A new {} has not been created. Reason:': 'Новый {} не был создан. Причина:',
      'Report config': 'Конфигурация отчета',
      'can`t get report url': 'Невозможно получить URL отчета',
      'Can`t get report file by url at bot`s report_config':
        'Не удается получить отчетный файл по URL в настройках отчета бота',
      'Set params for report': 'Установить параметры для отчета',
      'Shipment period': 'Период исполнения',
      Totals: 'Всего',
      'Open balance (by upload)': 'Открытый баланс (по загрузке)',
      'Open balance (by register)': 'Открытый баланс (по регистру)',
      'Transport sent': 'Транспорт отправлен',
      'Transport uploaded': 'Транспорт загружен',
      'Percent execution': 'Процент исполнения',
      'by the': 'по',
      'Wrong phone number format!': 'Неверный формат номера телефона!',
      'Incorrect value': 'Неверное значение',
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`' -":
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`' -",
      abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890:
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890',
      'START:': 'НАЧАЛО:',
      SUCCESS: 'УСПЕХ',
      FAIL: 'НЕУДАЧА',
      'Input your comment, please:': 'Введите ваш комментарий, пожалуйста:',
      'Comment was added.': 'Комментарий добавлен.',
      'Comment was`t added.': 'Комментарий не был добавлен.',
      'Instance not found. Please contact with your Support Manager.':
        'Экземпляр не найден. Свяжитесь с менеджером поддержки.',
      'Server error. Please contact with your Support Manager.':
        'Ошибка сервера. Свяжитесь с менеджером поддержки.',
      '✅ Approve': '✅ Одобрить',
      '❌ Decline': '❌Отклонить',
      Approve: 'Утвердить',
      Decline: 'Отклонить',
      'Chat not found. Please contact with your Support Manager':
        'Чат не найден. Пожалуйста, свяжитесь с вашим менеджером поддержки',
      'Chat was deactivated. Please contact with your Support Manager.':
        'Чат был деактивирован. Пожалуйста, свяжитесь с вашим менеджером поддержки.',
      'Invalid action.': 'Недопустимое действие.',
      was: 'был',
      'Following users have to approve object №':
        'Следующие пользователи должны согласовать объект',
      'Following users have to approve invoice №':
        'Следующие пользователи должны согласовать инвойс',
      'Approval required for the ticket №': 'Необходимо согласование по тикету',
      'Approval required for the passport': 'Подтверждение необходимо для этого паспорта',
      'Approval required for the contract №': 'Подтверждение необходимо для этого контракта №',
      'GrainTrack. Approval required for the contract №':
        'Грейнтрек. Подтверждение необходимо для этого контракта №',
      'Approval required for the client': 'Необходимо согласование по клиенту',
      'Approval required for the invoice №': 'Подтверждение необходимо для этого инвойса №',
      'Approval required for the object №': 'Подтверждение необходимо для этого объекта №',
      'Approval updated for the ticket №': 'Согласование обновлено по тикету',
      'Approval updated for the passport': 'Подтверждение обновлено для паспорта',
      'Approval updated for the client': 'Обновление по согласованию клиента',
      'Approval updated for the invoice №': 'Подтверждение обновлено для этого инвойса №',
      'Approval updated for the object №': 'Подтверждение обновлено для этого объекта №',
      'Approver commented on the request №': 'Подтверждающий прокомментировал тикет №',
      'GrainTrack. Final approve for the client':
        'Грейнтрек. Финальное подтверждение для контрагента',
      'created a new сompany': 'создана новая компания',
      'was commented by user': 'прокомментировано пользователем',
      'GrainTrack. Сomment was created in the  client':
        'Грейнтрек. Кто-то прокомментировал контргента',
      'Ticket №': 'Тикет',
      'Contract №': 'Контракт',
      'commented by user': 'прокомментировано пользователем',
      'GrainTrack. Сomment was created in the request №':
        'Грейнтрек. Кто-то прокомментировал тикет',
      'GrainTrack. Сomment was created in the  passport':
        'Грейнтрек. Кто-то прокомментировал паспорт',
      'GrainTrack. Сomment was created in the contract №':
        'Грейнтрек. Кто-то прокомментировал контракт №',
      'GrainTrack. Сomment was created in the  invoice №':
        'Грейнтрек. Кто-то прокомментировал инвойс №',
      'GrainTrack. Сomment was created in the  object №':
        'Грейнтрек. Кто-то прокомментировал объект',
      'GrainTrack. Final approve for the invoice №':
        'Грейнтрек. Финальное подтверждение для инвойса №',
      'created new invoice': 'Создано новый инвойс',
      'GrainTrack. Final approve for the passport':
        'Грейнтрек. Финальное подтверждение для паспорта',
      'created new contract': 'Создан новый контракт',
      'GrainTrack. Final approve for the contract №':
        'GrainTrack. Финальное подтвердение по контракту',
      'after unloading the auto, the balance after the GCD.':
        'после разгрузки авто, баланс после ГТД.',
      'GrainTrack. Final approve for the ticket №':
        'Грейнтрек. Финальное подтверждение для тикета №',
      'GrainTrack. Started approving for the ticket №':
        'Грейнтрек. Началось подтверждение для тикета №',
      'Created a new ticket №': 'создан новый тикет',
      'has edited ticket': 'тикет изменен',
      'created new USD contract': 'Создан новый контракт в $',
      'created a new task': 'создана новая задача',
      'updated a task': 'Обновить задание',
      'Field cannot contain spaces.': 'Поле не может содержать пробелов.',
      'Cargo partial executed': 'Груз выполнен частично',
      'Volume Departed': 'Отправленный объем',
      'Volume Received': 'Полученный объем',
      'Volume Departed Real': 'Объем (отправленный)',
      'Volume property': 'Показатель',
      'Current progress': 'Текущий прогресс',
      'Details of trade confirmation': 'Детали трейдерского подтверждения',
      'Transport fact': 'Факт по транспорту',
      'Charge fact': 'Затрата факт',
      'Docs fact': 'Док факт',
      'Offers warehouse': 'Оффер как склад',
      'Warehouse deviation, %': 'Отклонение качества, %',
      'Custom margin': 'Настраиваемая маржа',
      'credit period (days)': 'кредитный период',
      'credit percent': 'процент по кредиту',
      'permanent costs': 'постоянные затраты',
      taxes: 'налоги',
      'vat return rate for costs': 'Ставка возврата НДС для затрат',
      Multipassport: 'Мультипаспорт',
      Multipassports: 'Мультипаспорта',
      'Multipassport ': 'Мультипаспорт ',
      'Derivative account': 'Деривативный счет',
      tiket: 'тикет',
      contract: 'контракт',
      'contract analytical': 'аналитический контракт',
      execution: 'исполнение',
      invoice: 'инвойс',
      payment: 'оплата',
      'Execution costs from invoices': 'Учитывать инвойсы для затрат в марже по исполнению',
      'Execution pl from dbl & ccd': 'Выполнение pl из dbl и ccd',
      'Business (date)': 'Бизнес (дата)',
      'Sap orders': 'Заказы SAP',
      'Volume position as aspiration': 'Объем позиции, как аспирация',
      'Use commodity parent match': 'Использовать совпадение с родительским товаром',
      Passports: 'Паспорта',
      'Can view passport result': 'Просмотр результата в паспорте',
      'Can view passport P&L': 'Может просматривать P&L паспорта',
      'Can view passport recap': 'Можно просмотреть выписку из паспорта',
      'Can view passport costs&gains': 'Можно просмотреть расходы&доходы паспорта',
      'Can view passport costs of goods': 'Можно посмотреть стоимость товара паспорта',
      'Can view passport execution': 'Можно просмотреть исполнение паспорта',
      'Can view passport invoices': 'Можно просмотреть инвойсы паспорта',
      'Is insured': 'Застрахован',
      'This fact is done': 'Факт выполнен',
      'Chain order': 'Порядок цепочки',
      'Passport and contract have different cargo': 'У паспорта и контракта разные культуры',
      'Ticket margin': 'Маржа по тикетам',
      'Ticket margin per ton': 'Маржа по тикетам за тонну',
      'Ticket analytical margin': 'Аналитическая маржа тикета',
      'Contract analitical margin': 'Контрактная аналитическая маржа',
      'Contract margin': 'Маржа по контрактам',
      'Contract margin per ton': 'Маржа по контрактам за тонну',
      'Execution margin': 'Маржа по исполнению',
      'Execution margin per ton': 'Маржа по исполнению за тонну',
      'Invoice margin': 'Маржа по инвойсам',
      'Invoice margin per ton': 'Маржа по инвойсам за тонну',
      'Payment margin': 'Марда платежа',
      'Payment margin per ton': 'Маржа по оплатам за тонну',
      'Margin Control': 'Контроль маржи',
      'Margin Controls': 'Контроль маржи',
      'Contract No.': 'Номер контракта.',
      'Business unit': 'Бизнес-юнит',
      'Conclusion Date': 'Дата заключения',
      'Buyer country': 'Страна покупателя',
      'Buyer ITN': 'ИНН покупателя',
      'Supplier country': 'Страна поставщика',
      'Supplier ITN': 'ИНН поставщика',
      'Price per t, USD': 'Цена за т, долл. США',
      'Currency Symbol USD': 'Символ валюты доллар США',
      'Price per t, local': 'Цена за т, местная',
      'Currency Symbol Local': 'Символ местной валюты',
      'Contract volume': 'Объем контракта',
      'Incoterms Basis': 'Базис Инкотермс',
      'Derivatives margin split per contracts': 'Разделение маржи по деривативам на контракты',
      'Total Value of contracts/Total Passport margin in USD':
        'Общая стоимость контрактов/Общая маржа паспортов в USD',
      'Total Value of contracts/Total Passport margin in local':
        'Общая стоимость контрактов/Общая маржа паспортов в локальной валюте',
      'Costs Plan in USD': 'План затрат в долларах США',
      'Costs, Invoice amount in USD': 'Расходы, сумма счета в долларах США',
      'Cost Amount per t in USD': 'Стоимость за тонну в долларах США',
      'Invoice Costs Number': 'Номер инвойса на расходы',
      'Invoice Date': 'Дата инвойса',
      'Info about costs payments': 'Информация об оплате расходов',
      'Amount in USD': 'Сумма в долларах США',
      'Executed in MT': 'Выполнено в МТ',
      'Execution Value/Margin in USD': 'Стоимость исполнения/маржа в долларах США',
      'Execution Value/Margin in local': 'Стоимость исполнения/маржа в местной валюте',
      'BL DETAILS': 'Детали коносамента',
      'self-cost': 'Себестоимость',
      'Invoiced Value/Margin in USD': 'Выставленная стоимость/маржа в долларах США',
      'Invoiced Value/Margin in local': 'Выставленная стоимость/маржа в местной валюте',
      'Invoice for cargo number.': 'Инвойс на номер груза.',
      'Info about cargo payments': 'Информация об оплате груза',
      'Info about cargo payments Non-cash': 'Информация о платежах за груз',
      'Qty MT': 'Количество (MT)',
      'Non-cash amount': 'Безналичная сумма',
      'Non-cash paid': 'Безналичный расчет',
      'Payments in USD': 'Платежи в долларах США',
      'Payments in local': 'Платежи в местной валюте',
      'Non-cash payments': 'Безналичные платежи',
      'This contract cannot be connected with this volume to passport.':
        'Контракт не может быть привязан с этим объемом к паспорту.',
      'Key {key} does not exist': 'Ключ {key} не существует',
      'Wrong contract purpose': 'Неправильное назначение контракта',
      'The number of passports is not specified, or more than five.':
        'Количество паспортов не указано, или больше пяти.',
      'Coming to load': 'Прибывает на погрузку',
      'At load port / Under loading': 'Порт отгрузки',
      'Sailed / under way to discharge': 'Отчалил / в пути на разгрузку',
      'At discharging port / under discharge': 'В порту назначения / разгружается',
      'W/o status': 'Без статуса',
      'This number is input by hand': 'Это число введено вручную',
      'Cash Flows': 'Денежный поток',
      'Can see cashflow recreation modal': 'Может смотреть кешфло',
      'Can view reports': 'Просмотр отчетов',
      'Can view debit-credit reports': 'Просмотр отчета дебет кредит',
      'Can view position report': 'Просмотр отчета по позиции',
      'Can view position origination report': 'Может просматривать позицию ориджинейшн',
      'Can view position physical report': 'Просмотр отчета по позиции',
      'Can view confirmations report': 'Просмотр отчета по подтверждениям',
      'Can view signings report': 'Просмотр отчета по подписаниям',
      'Can view kpiconfirmations report': 'Просмотр отчета по KPI подтверждениям',
      'Can view kpisignings report': 'Просмотр отчета по KPI подписаниям',
      'Can view balance report': 'Может просматривать отчет баланс',
      'Can view budgets report': 'Просмотр отчета бюджеты',
      'Can view purchase plan report': 'Просмотр отчета по плану закупке',
      'Can view lineup report': 'Просмотр отчета по лайнапу',
      'Can view futures position report': 'Может просматривать фьючерс позицию отчет',
      'Can view certificate dates report': 'Может просматривать отчет по датам сертификации',
      'Can view execution of loyalty programs report': 'Просмотр отчета по программам лояльности',
      'Can view Projects Status Detailed Summary Report':
        'Может просматривать Сводный отчет по статусам проэктов',
      'Can view P&L report': 'Просмотр ПНЛ отчета',
      'Can view P&L per passport report': 'Просмотр отчета ПнЛ по паспорту',
      'Can view P&L Contract PNL report': 'Может видеть отчет P&L Contract PNL',
      'Can view P&L Passports PNL report': 'Может видеть отчет P&L Passports PNL',
      'Can view P&L Analytical-Plan-Fact PnL report':
        'Может видеть Аналитический план-факт PnL отчет P&L',
      'Can view P&L mark to market report': 'Просмотр отчета ПнЛ МТМ',
      'Can view forward client status mtm report':
        'Может просматривать отчет мтм по форвардным сделкам',
      'Can view freight in trading report': 'Можно просмотреть фрахт в торговом отчете',
      'Can view fixing/hedging in trading report':
        'Можно просматривать фиксацию/хеджирование в торговом отчете',
      'Can view invoice risk in report': 'Можно просмотреть инвойс-риск в отчете',
      'Can view credit risk in report': 'Можно просмотреть кредитный риск в отчете',
      'Can view approvals report': 'Можно просмотреть отчет об одобрениях',
      'Can view cost report': 'Можно просмотреть отчет о расходах',
      'Can view activity in management reports':
        'Можно просматривать активность в управленческих отчетах',
      'Can view reconciliation reports': 'Может просматривать отчеты о сверке',
      'Staff report': 'Отчет по пользователям',
      User: 'Пользователь',
      'Contracts count': 'Контрактов',
      'Indicators count': 'Индикаторов',
      'Tickets count': 'Тикетов',
      'Clients count': 'Контрагентов',
      'Roles count': 'Ролей',
      'Logistics count': 'Транспорта',
      'Plans count': 'План счет',
      'Invoices count': 'Инвойсов',
      'Payments count': 'Платежей',
      'Passports count': 'Паспортов',
      'Potentials count': 'Счетчик потенциалов',
      'Updates count': 'Обновлений',
      'Tasks count': 'Заданий',
      'On site seconds': 'Секунд на сайте',
      Completed: 'Завершено',
      'Group By': 'Группа по',
      'Loading / Destination': 'Загрузка / Назначение',
      'Vessel name': 'Название судна',
      TOTAL: 'ВСЕГО',
      'Invalid date format, use YYYY-MM-DD': 'Неправильный формат даты, используйте ГГГГ-ММ-ДД',
      'VAT option': 'НДС опция',
      'VAT value': 'стоимость НДС',
      'Contract number': 'Номер контракта',
      'Date of execution': 'Дата исполнения',
      'End of execution': 'Конец исполнения',
      'Contract type': 'Тип контракта',
      'Tolerance volume': 'Объем опции',
      'Contract value': 'Стоимость контракта',
      'Value no VAT': 'Стоимость без НДС',
      'Price no VAT': 'Цена без НДС',
      'Tolerance plus': 'Опция в плюс',
      'Tolerance minus': 'Опция в минус',
      'Departed plus': 'Отправлено плюс',
      'Departed minus': 'Отправлено минус',
      'Departed value': 'Отправлено значение',
      'Received value': 'Получено значение',
      'Shipment balance': 'Баланс отгрузки',
      'Invoiced Cargo': 'Оплаченный груз',
      'Paid Cargo': 'Оплаченный груз',
      'Unpaid Cargo': 'Неоплаченный груз',
      Signed: 'Подписан',
      'Wrong date format': 'Неправильный формат даты',
      'Start date should be less the end date': 'Дата начала должна быть меньше даты окончания',
      'Upload date': 'Дата загрузки',
      'Vehicle number': 'Номер транспортного средства',
      'Consignment number': 'Номер накладной',
      'Declared volume': 'Заявленный объем',
      'Volume accepted': 'Принятый объём',
      'Price with discount': 'Цена со скидкой',
      'Amount with discount': 'Сумма со скидкой',
      Debts: 'Долги',
      'Period totals data': 'Итоговые данные за период',
      'TOTAL data': 'ИТОГОВЫЕ данные',
      '⚠️ ATTENTION! The "REST HOOKS" feature is being phased out. Please transition all hooks to "Event Subscriptions". This feature will be removed in version 52.23.0. ⚠️':
        '⚠️ ВНИМАНИЕ! Функция “REST HOOKS” постепенно выводится из эксплуатации. Пожалуйста, переведите все хуки на “Подписки на события”. Эта функция будет удалена в версии 52.23.0. ⚠️',
      Once: 'Один раз',
      'Once a day': 'Раз в день',
      Hourly: 'Почасово',
      Daily: 'Ежедневный',
      Weekly: 'Еженедельно',
      Monthly: 'Ежемесячно',
      Yearly: 'Ежегодно',
      'Started execution': 'Начато выполнение',
      'Failed execution': 'Не удалось выполнить',
      Stopped: 'Остановлено',
      Unknown: 'Неизвестный',
      January: 'Январь',
      February: 'Февраль',
      March: 'Март',
      April: 'Апрель',
      May: 'Май',
      June: 'Июнь',
      July: 'Июль',
      August: 'Август',
      September: 'Сентябрь',
      October: 'Октябрь',
      November: 'Ноябрь',
      December: 'Декабрь',
      '---': '---',
      'Activate system event': 'Активировать системное событие',
      'Set contacts not updated': 'Установленные контакты не обновлены',
      'Reset tickets approvals': 'Сбросить одобрение тикетов',
      'Set voyage data': 'Установить данные о рейсе',
      'Close paid contractcharges': 'Закрыть оплаченные расходы по контракту',
      'Update contracts ending status': 'Обновить статус окончания контрактов',
      'Update clients statuses': 'Обновить статусы контрагентов',
      'Update clients regular statuses': 'Обновить регулярные статусы контрагентов',
      'Update currencies rates': 'Обновить курсы валют',
      'Create quotes': 'Создать ноуты',
      'Generate staff report': 'Сформировать отчет по персоналу',
      'Update voyages statuses': 'Обновить статусы рейсов',
      'Update passports status': 'Обновить статус паспортов',
      'Create margin P&L control': 'Создайте контроль маржи P&L',
      'Update passports business dates': 'Обновить рабочие даты паспортов',
      'Parsing sstockmarket prices': 'Парсинг цен на бирже',
      'Resend pending approvals': 'Повторно отправить ожидающие одобрения',
      'Finances data sample preparing ready': 'Подготовка образца данных финансов завершена',
      'Passports data sample preparing ready': 'Подготовка образца данных паспортов завершена',
      'Payment plans data sample preparing ready':
        'Подготовка образца данных платежных планов завершена',
      'Payments data sample preparing ready': 'Подготовка образца данных платежей завершена',
      Action: 'Действие',
      Object: 'Объект',
      'Date and time at which this job is scheduled to be executed next.':
        'Дата и время, на которые запланировано следующее выполнение этого задания.',
      'Scheduled task': 'Запланированная задача',
      'Scheduled tasks': 'Запланированные задачи',
      Periodicity: 'Периодичность',
      'Repeat week day': 'Повторить день недели',
      'Month repeat': 'Месячный повтор',
      'On days': 'В дни',
      'On months': 'По месяцам',
      'Total run time of this job (in seconds).':
        'Общее время выполнения этого задания (в секундах).',
      'Details of exception that occurred during job execution (if any).':
        'Подробная информация об исключении, возникшем во время выполнения задания (если таковое имело место).',
      'Traceback of exception that occurred during job execution (if any).':
        'Отслеживание исключения, возникшего во время выполнения задания (если таковое имелось).',
      'This field can`t be empty': 'Это поле не может быть пустым',
      'screenshot name': 'Название скриншота',
      'telegram chat': 'Telegram-чат',
      Scale: 'Шкала',
      'Comment for screenshot': 'Комментарий к скриншоту',
      'pdf report attachment': 'Вложение отчета в формате pdf',
      'This field must be unique.': 'Это поле должно быть уникальным.',
      'Stock Exchange': 'Биржевый курс',
      'usd/t': 'дол/т',
      'cent/b': 'центы/бушели',
      'eur/t': 'евро/т',
      Futures: 'Фьючерсы',
      Barchart: 'Барчат',
      Agritel: 'Агритель',
      Ticker: 'Тикер',
      'Expiration date': 'Дата экспирации',
      'A date date until which the derivative is valid':
        'Дата до которой действительно значение дериватива',
      'Derivative type': 'Тип дериватива',
      'Derivative mode': 'Режим дериватива',
      'Derivative month': 'Месяц дериватива',
      'A month when derivative will be relevant': 'Месяц когда фьючерс будет релевантен',
      'Lot size': 'Размер лота',
      'Point value': 'Позиция точки',
      'Service Charge': 'Комиссия за услуги',
      'Exch Fees': 'Комиссии обмена',
      'NFA Fees': 'NFA комиссия',
      'cost per lot': 'Затраты за 1 лот',
      'Initial amount': 'Первоначальная сумма',
      'Price date': 'Дата цены',
      'A date for which the price is assigned': 'Дата на которую назначена цена',
      Long: 'Лонг',
      Short: 'Шорт',
      Put: 'Put',
      Fixing: 'Fixing',
      Rolling: 'Подвижной',
      Hedging: 'Хеджирование',
      'Paper trade': 'Бумажная торговля',
      'Deal type': 'Тип сделки',
      'Option type': 'Тип опции',
      'Operation type': 'Тип операции',
      'Derivative Account': 'Дериватив аккаунт',
      'Quantity of lots': 'Количество лотов',
      'Business date': 'Бизнес дата',
      'Virtual operation': 'Виртуальная операция',
      'use in position': 'Использовать в позиции',
      'Tariff correction': 'Коррекция тарифа',
      'Charge per mt': 'Затрата за 1т',
      'Consolidated type': 'Тип консолидации',
      'Exit date': 'Дата выезда',
      Resource: 'Ресурс',
      'Parsing enabled': 'Парсинг включен',
      'Quotes Parsing Config': 'Конфигуратор парсинга котировок',
      'Quotes Parsing Configs': 'Конфигуратор парсинга котировок',
      Measurement: 'Измерение',
      Measurements: 'Единицы измерения',
      coefficient: 'коэффициент',
      'Measurement Crop': 'Единица измерения товара',
      'Measurement Crops': 'Единицы измерения товара',
      'There can be only one chosen StockMarketPrice per day and derivative':
        'Может быть выбрана только одна цена, дериватив и биржа в день',
      'There is no parsers for this resource': 'Для этого ресурса нет парсинга',
      'Parsing error. No quote for this resource and ticker: ':
        'Ошибка парсинга. Нет котировки для этого ресурса и тикера: ',
      'Direct or brother': 'Прямой или братский',
      'Direct or children': 'Прямой или дочерний',
      'Direct or descendant': 'Прямой или потомственный',
      Dimension: 'Измерение',
      Attribute: 'Атрибут',
      'Time moment': 'Момент времени',
      RecorderReference: 'Ссылка на записчик',
      'Reserved volume': 'Зарезервированный объем',
      'Free volume': 'Свободный объем',
      'Costs amount': 'Сумма расходов',
      'additional info': 'примечание',
      'In process': 'В процессе',
      'Exporter approval (docs sent buyer)': 'Экспортер подтв (доки отправили)',
      Additional: 'Примечание',
      Cancellation: 'Отмена',
      'Abstract Stages': 'Абстрактные стадии',
      'Signature (draft)': 'Подпись (драфт)',
      'Broker signed': 'Подписано брокером',
      'We signed': 'Мы подписали',
      'Both signed': 'Оба подписаны',
      'All signed': 'Все подписали',
      'Originals received': 'Оригиналы получены',
      'Cargo confirmation': 'Подтверждение груза',
      'Payment confirmation': 'Подтверждение оплаты',
      Graintrack: 'Грейнтрек',
      'Graintrack. Admin panel': 'Грейнтрек. Панель администратора',
      'Welcome,': 'Добро пожаловать,',
      Documentation: 'Документация',
      'Change password': 'Поменять пароль',
      'Log out': 'Выйти',
      'To EXPORT something, first, choose the format of the file and then press SUBMIT button. This will save the file on your local drive ':
        'Для того чтобы ЭКСПОРТИРОВАТЬ что-то, выберите формат файла и нажмите кнопку ПОДТВЕРДИТЬ. После этого файл будет сохранен у вас на компьютере. ',
      Submit: 'Подтвердить',
      'Require to have a business unit for validation logistics, logistics contracts and logistic invoices':
        'Бизнес юнит обязателен для валидации логистики, контрактов, инвойсов',
      "Below is a preview of data to be imported. If you are satisfied with the results, click 'Confirm import'":
        'Это предварительный просмотр того, что вы собираетесь импортировать. Просмотрите внимательно, все ли вгрузилось как нужно и подтвердите действие',
      'Confirm import': 'Подтверждение импорта',
      'This importer will import the following fields: ':
        'Этот импортер импортирует следующие поля: ',
      'You have not Business Unit. Make sure you have at least one business unit.':
        'У вас нет Бизнес Юнита. Убедитесь, что есть хотя бы один бизнес юнит.',
      Errors: 'Ошибки',
      'Line number': 'Номер строки',
      'Some rows failed to validate': 'Некоторые строки не удалось проверить',
      'Please correct these errors in your data where possible, then reupload it using the form above.':
        'Пожалуйста, исправьте эти ошибки в своих данных, где это возможно, а затем повторно загрузите их, используя форму выше.',
      Row: 'Ряд',
      'Non field specific': 'Не зависит от поля',
      Preview: 'Предварительный просмотр',
      'Event Subscriptions': 'Подписки на события',
      application: 'заявка',
      model: 'модель',
      Add: 'Добавить',
      Change: 'Изменить',
      View: 'Просмотр',
      'Last update': 'Последнее обновление',
      'Receiving date': 'Дата получения',
      'Loading date': 'Дата отгрузки',
      'Created contract': 'Создан контракт',
      'Last update contract': 'Последнее обновление контракта',
      'Conclusion date contract': 'Дата заключения контракта',
      'Conclusion date': 'Дата заключения',
      Line: 'Линия',
      Bar: 'Столбец',
      counterparty: 'контрагент',
      'business unit': 'бизнес-юнит',
      origin: 'происхождение',
      destination: 'назначение',
      year: 'год',
      split: 'разделить',
      consolidated: 'консолидировано',
      commodity: 'продукт',
      incoterms: 'инкотермс',
      status: 'статус',
      Alphabet: 'Алфавит',
      Smart: 'Смарт',
      'By numbers': 'Числ',
      'Broker confirm': 'Брокер подтвержден',
      'N/A': 'N/A',
      'P&L': 'PnL',
      Dashboard: 'Панель',
      'Physical position + MTM': 'Физическая позиция + МТМ',
      'Physical position by months': 'Физическая позиция по месяцам',
      'Position P&L + MTM': 'Позиция PnL + МТМ',
      'Market price': 'Рыночная цена',
      'Last market price': 'Последняя рыночная цена',
      Columns: 'Столбцы',
      List: 'Список',
      Table: 'Таблица',
      Block: 'Раздел',
      'Consignment number, vehicle number': 'Номер накладной, номер тс',
      'Consignment number, vehicle number, loading date':
        'Номер накладной, номер тс, дата отгрузки',
      'Vehicle number, loading date': 'Номер тс, дата отгрузки',
      'Vehicle number, receiving date, custom status':
        'Номер ТС, дата получения, специальный статус',
      'Vehicle number, consignment number, receiving date, custom status':
        'Номер транспортного средства, номер партии, дата получения, таможенный статус',
      'Vehicle number, loading date, consignment number if exists':
        'Номер ТС, дата загрузки, номер отгрузки, если он существует',
      KG: 'КГ',
      T: 'Т',
      OR: 'ИЛИ',
      AND: 'И',
      'Short name': 'Короткое название',
      'Short title': 'Короткое название',
      Boat: 'Судно',
      Forbid: 'Запрещено',
      Warn: 'Предупр',
      YouControl: 'YouControl',
      Vkursi: 'Vkursi',
      'Fixing position': 'Фиксинг позиция',
      'Passports position': 'Паспорт позиция',
      'Accounts position': 'Позиция счетов',
      'Calculate by passport operations': 'Расчет по операциям в паспорте',
      'Calculate by contract operations': 'Расчет по операциям по контракту',
      'Passports & Open contracts & Services': 'Паспорта & Открытые контракты & Услуги',
      'Passports & Open contracts & Services & Spec. Accounts':
        'Паспорта & открытые контракты & услуги & специальные счета',
      'Passports & Open contracts': 'Паспорта & Открытые контракты',
      'Passports & Spec. Accounts': 'Паспорта & спец. счета',
      'Passports & Open contracts & Spec. Accounts': 'Паспорта & Открытые контракты & Спец. счета',
      'Passports & Services': 'Паспорта & Услуги',
      'Passports & Services & Spec. Accounts': 'Паспорта & Услуги & Спец. счета',
      'Open contracts & Services': 'Открытые контракты & Услуги',
      'Open contracts & Services & Spec. Accounts': 'Открытые контракты & Услуги & Спец. счета',
      'Open contracts': 'Открытые контракты',
      'Spec. Accounts': 'Спец. счета',
      'Don`t use': 'Не использовать',
      '{Warehouse} => {Commodity} => {Contract position} => {Batch}':
        '{Warehouse} => {Commodity} => {Contract position} => {Batch}',
      '{Warehouse} => {Commodity} => {Contract position}':
        '{Warehouse} => {Commodity} => {Contract position}',
      '{Warehouse} => {Commodity}': '{Warehouse} => {Commodity}',
      'By month': 'По месяцям',
      'By date': 'По дате',
      'From movements': 'Из движений',
      "Don't convert amount": 'Не конвертировать сумму',
      'Convert to dbl currency': 'Конвертировать в валюту dbl',
      'enables the education mode in the system for new users':
        'включает режим обучения в системе для новых пользователей',
      'Carbon Copy emails, comma separated': 'Копия, email - через запятую',
      'Blind Carbon Copy emails, comma separated': 'Скрытая копия, email - разделять запятой',
      'Carbon Copy emails, comma separated for logistics newsletter':
        'Email-адреса копий для рассылки логистики, через запятую',
      'Carbon Copy emails, comma separated for invoice newsletter':
        'Email-адреса копий для рассылки инвойсов, через запятую',
      'Uses for recipients filter': 'Используется для поиска получателей',
      'SMTP port. Not secure - 25, GMail - 587 or read documentation':
        'Порт SMTP. Небезопасный - 25, GMail - 587, детали в документации',
      'Use TLS encryption. Turn off if your SMTP provider does not support it':
        'Использовать шифрование TLS. Отключите, если ваш SMTP-провайдер его не поддерживает',
      'Use SSL encryption. Turn on if TLS does not work.':
        'Использовать SSL-шифрование. Включите, если TLS не работает.',
      'SMTP usermane. Email sender': 'SMTP логин. Отправитель Email',
      "SMTP password. Stores encrypted. Don't edit.":
        'Пароль SMTP. Хранится в зашифрованном виде. Не редактировать.',
      'translate latin vehicle numbers to cyrillic':
        'перевести латинские номера транспортных средств на кириллицу',
      'Auto create export custom declaration':
        'Автоматическое создание экспортной таможенной декларации',
      'default quantity days value from receiving date for search vehicles':
        'значение количества дней по умолчанию с даты получения для поиска транспортных средств',
      'Check if this CONTRACT has this FINANCE': 'Проверьте, содержит ли этот КОНТРАКТ финансы',
      'Create qualition on import': 'Создавать качество логистики при импорте',
      'Check if logistic volume sum less, than contract volume. YES - prevent import, NO - notification only':
        'Отметьте, когда объем логистики меньше, чем объем контракта. ДА- не импортировать, НЕТ - только уведомлять',
      'Check if logistic volume departed less, than contract volume and prevent import':
        'Отметьте, когда объем логистики меньше, чем объем контракта и вы хотите предотвращать импорт',
      'Trying to find the buyer_contract by related vessel and buyer names':
        'Пытаемся сопоставить продажный контракт по названию судна имени покупателя',
      'Trying to find the contract by commodity and price':
        'Пытаюсь найти контракт по товару и цене',
      'Creates deliverers on import': 'Создает экспедитора при импорте',
      'Creates stations on import': 'Создает станции при импорте',
      'Parse quotes configures in quote parsing configs.':
        'Парсинг котировок настраивается в конфигах парсинга котировок.',
      'enables default value for VAT': 'включает значение по умолчанию для НДС',
      'Allow create customs declaration for sale contracts':
        'Позволяет создать таможенную декларацию для продажных контрактов',
      'Allow create customs declaration for purchase contracts':
        'Позволяет создать таможенную декларацию для закупочных контрактов',
      'Check to apply purchase status contract filter in warehouse':
        'Проверить применение фильтра статуса закупочного контракта на складе',
      'Allow to create tickets even if client is not approved':
        'Разрешить создавать тикеты, даже если контрагент не утвержден',
      'Allow to create passport from executed tickets':
        'Разрешить создание паспорта из выполненых тикетов',
      'Disable changing crop when create ticket from indicator':
        'Отключить изменение культуры при создании тикета из индикатора',
      'Do not let to change conclusion date when create contracts':
        'Запретить изменение даты заключения при создании контрактов',
      'Logistic Google Drive file ID':
        'Проверьте настройку LOGISTIC_FILE_GOOGLE_ID в НАСТРОЙКАХ ОРГАНИЗАЦИИ',
      'Intermediate logistic Google Drive file ID': 'Промежуточная логистика Google Drive file ID',
      'Show opened quality in logistics table':
        'Отображать открытые показатели качества в таблице логистики',
      'Show opened passports in conslidated profit and loss':
        'Показывать детализацию паспортов в отчете ПНЛ',
      'Show only recent year in the PNL by default':
        'Показывать только текущий год в отчете PNL по умолчанию',
      'Group passport pnl report by commodities': 'Группировать отчет PNL паспортов по товарам',
      'Show VAT everywhere': 'Использовать НДС',
      'Show return on sales': 'Использовать рентабельность продаж',
      'Show return on equity': 'Использовать рентабельность собственного капитала',
      'Restricts logistics volume for EXW contracts based on warehouse receipt.':
        'Ограничивать объем логистики для контрактов EXW на основе складского поступления.',
      'Show insurance in passport facts': 'Показать страхование в паспорте',
      'Show insurance in passport PASSPORT_FACTS_USE_CHAIN_ORDER':
        'Показывать страхование в паспорте PASSPORT_FACTS_USE_CHAIN_ORDER',
      'Use chain order in passport facts': 'Использовать внутреннюю цепочку в результате паспорта',
      'Show margin by tickets in passport results': 'Показать маржу в паспорте по тикетам',
      'Show margin by analytical in passport results':
        'Показать аналитическую маржу в результатах паспорта',
      'Use priority analitical in passport results for tickets':
        'Использовать приоритетную аналитику в результатах паспорта для тикетов',
      'Use priority analitical in passport results for contracts':
        'Использовать приоритетную аналитику в результатах паспорта для контрактов',
      'Show Analytical PNL in Contract PNL report and Passport PNL Distr tab':
        'Отображать аналитический PNL в отчете PNL контракта и вкладке распределения PNL паспортов',
      'Show margin by contracts in passport results': 'Показать маржу в паспорте по контрактам',
      'Show margin by logistics in passport results': 'Показать маржу в паспорте по логистике',
      'Show margin by invoices in passport results': 'Показать маржу в паспорте по инвойсам',
      'Show margin by payments in passport results': 'Показать маржу в паспорте по оплатам',
      'offer role': 'роль',
      'Tariff auto active': 'Авто тариф актив',
      'Tariff rw active': 'ЖД тариф актив',
      'Elevator active': 'Перевалка актив',
      'Freight active': 'Фрахт актив',
      'Approval expiration time, in hours': 'Срок действия утверждения, в часах',
      'Define how to call "allocation"': 'Определить, как называть "распределение"',
      'Allow only the same crops or child crops': 'Разрешать только одинаковые культуры',
      'If payment is overdue': 'Если оплата просрочена',
      'Allow connect not more than contract volume':
        'Не разрешать привязывать больше чем контрактный объем',
      'Enable filter for consolidated table ': 'Включить фильтр для консолидированной таблицы ',
      'Change view of FOB position "Load" modal':
        'Изменить отображение ФОБ позиции Погрузка в судно',
      'To prevent logistic from amendment when balance invoice':
        'Запретить изменение логистики, если есть балансовый инвойс',
      'validate if counterparty is approved before creating general agreement':
        'устанавливать конфигурацию подтверждения по умолчанию, если пользователь является главным утверждающим',
      'To put approval config by default if user is main approver':
        'Установить конфигурацию подтверждения по умолчанию, если пользователь является главным утверждающим',
      "Logistic's loading_date must be between date_of_execution and end_of_execution its supplier_contract":
        'Дата отгрузки тс должна быть в периоде исполнения контракта',
      'Prevents Logistics Import while there are duplicated records in the DB by LOGISTICS_MATCHING_FIELDS':
        'Предотвращает импорт логистики при наличии дублированных записей в Базе Данных с помощью LOGISTICS_MATCHING_FIELDS',
      'Process vehicle numbers and keep only LOGISTIC_IMPORT_VEHICLE_NUMBER_LENGHT symbols':
        'Обработать номера транспортных средств и сохранить только символы LOGISTIC_IMPORT_VEHICLE_NUMBER_LENGHT',
      'Enable/disable row selection during logistics import':
        'Включить/отключить выбор строк во время импорта логистики',
      'Length of vehicle number': 'Длина автомобильного номера',
      'Skip import unchanged rows (may work incorrectly with some kind of fields like qualities or related objects)':
        'Пропустить импорт неизмененных строк (может некорректно работать с некоторыми полями, такими как качества или связанные объекты)',
      'Unique logistics acceptance certificate': 'Уникальная логистика прием сертификат',
      'Check if adding bank details is required to create a counterparty':
        'Проверьте, требуется ли добавление банковских реквизитов для создания контрагента',
      'Check if adding documents is required to create an invoice':
        'Проверьте, требуется ли добавление документов для создания инвойса',
      'Invoice position contract or passport required': 'Инвойс позиция контракт обязателен',
      'Passport is required for export contracts in finance position':
        'Для экспортных контрактов в финансовой позиции требуется паспорт',
      'Disable invoice positions for editing for cancelled or executed contracts':
        'Отключить редактирование инвойс-позиций для аннулированных или исполненных контрактов',
      'Dont validate the same counterparties from contracts in invoice positions':
        'Не валидировать на одинаковых контрагентов из контрактов в инвойс позициях',
      'Always check invoice numbers for duplicates':
        'Всегда проверяйте номера инвойсов на предмет дубликатов',
      'Enable inline add in tables': 'Включить встроенное добавление в таблицы',
      'Enable quick modals to create objects':
        'Включить быстрые модальные окна для создания объектов',
      'Require for terminal in purchase contract for certain basises':
        'Обязательно указать терминал в контракте купли-продажи для указанного базисов',
      'Use costs for execution from invoices':
        'Использовать инвойсы для затрат в марже по исполнению',
      'Use ccd & dbl for execution pl': 'Используйте ccd & dbl для выполнения pl',
      'removes contracts pages, leaves only general agreements':
        'удаляет страницы контрактов, оставляя только генеральные соглашения',
      'Suppress request_saved event for back_to_back':
        'Не отсылать уведомление про создание тикета при бэкенда ту бэкенда сделках',
      'Require for destination with basies CIF, FOB, CFR':
        'Сделать обязательным поле “назначение” для CIF, FOB, CFR',
      'Document file format to download from template': 'Формат документа для загрузки из шаблона',
      'Simplify main tables removing tabs': 'Упростить основные таблицы, убрав вкладки',
      'Removing negotiation from charges': 'Убрать переговорный процесс в затратах',
      'On purchase contracts page shows tab "CP/Seeds Prepayments"':
        'На странице закупочных контрактов отображает вкладку “Предоплата за семена/CP”',
      'Show invoice positions in passport by default"':
        'Показывать инвойс позиции в паспорте по умолчанию',
      'Default tab on derivatives report': 'Вкладка по умолчанию в отчете о деривативах',
      'Number of months to check for pending approvals':
        'Количество месяцев для проверки ожидающих подтверждений',
      'Models in the %(name)s application': 'Models in the %(name)s application',
      '%(name)s': '%(name)s',
      "You don't have permission to edit anything.": 'У вас нет прав на редактирование чего-либо.',
      'Recent Actions': 'Последние действия',
      'My Actions': 'Мои действия',
      'None available': 'Недоступно',
      'Unknown content': 'Неизвестные данные',
    });
    gettextCatalog.setStrings('uk', {
      0: '0',
      1: '1',
      2: '2',
      3: '3',
      2019: '2019',
      2020: '2020',
      2021: '2021',
      2022: '2022',
      '"Reservation" deleted': 'Резервацію” видалено',
      '"Reservation" doesn\'t exist': '“Резервація” не існує',
      '"Stock Reserve Unit" deleted': '“Одиницю резерву запасів” видалено',
      '"Stock Reserve Unit" doesn\'t exist': '“Одиниця резерву запасів” не існує',
      '#': '№',
      '# of containers': '№ контейнерів',
      $: 'USD',
      '$/20dv': 'USD/20dv',
      '$/40dv': 'USD/40dv',
      '$/day': 'USD/день',
      '$/t': 'USD/т',
      '%': '%',
      '% from the deal': '% від угоди',
      '&gt; 105%': '&gt; 105%',
      '&lt; not selected &gt;': '&lt; не обрано &gt;',
      "'Stock Reserve Unit' doesn`t exist": '‘Одиниця резерву запасів’ не існує',
      '(Boat required)': '(Потрібно судно)',
      '(admin)': '(адмін)',
      '(purchase)': '(закупівля)',
      '(sale)': '(продаж)',
      '(simple mode)': '(простий режим)',
      '+0 0 (000)000-00-00': '+0 0 (000)000-00-00',
      '---': '---',
      '-Payment conditions value must be 1-100': 'Умови оплати мають бути в діапазоні 1-100',
      '...expand': '...розширити',
      '0 - 10': '0 - 10',
      '0%': '0%',
      '0-100': '0-100',
      '0-100%': '0-100%',
      '0-200': '0-200',
      '0-200%': '0-200%',
      '0-95%': '0-95%',
      '0000000': '0000000',
      '1) we calculate P&L passport by passport, from different perspectives: by\n                  contracts (as a plan), by execution (as a fact), by invoices (as stronger fact),\n                  etc.':
        '1. Ми розраховуємо P&L для паспорта по кожному паспорту, з різних точок зору: за \n                  контрактами (як план), за виконанням (як факт), за рахунками (як більш підтверджений факт) \n                  тощо.',
      '1-100': '1-100',
      '100% matched': '100% точне співпадіння',
      '11 - 30': '11 - 30',
      '1Q': '1 кв',
      '1t': '1 т',
      '2) we combine inside this result both physical and non physical operations by\n                  passport':
        '2. Ми об’єднуємо в цьому результаті як фізичні, так і нефізичні операції за паспортом.',
      '24h': '24год.',
      '2Q': '2 кв',
      '3) when you press button CONTROL, we take the most actual P&L and put it here (you\n                  can choose which one, using MARGIN_SELECTOR inside passport modal window)':
        '3) Коли ви натискаєте кнопку CONTROL, ми беремо найактуальніший P&L і відображаємо його тут (ви можете вибрати, який саме, використовуючи MARGIN_SELECTOR у модальному вікні паспорта).',
      '30-60 days': '30-60 днів',
      '31 - 60': '31 - 60',
      '3Q': '3 кв',
      "4) then we take all contracts, and group them by certain criteria (origin,\n                  destination, client, etc). We know the PNL by each contract from it's share inside\n                  passport":
        '4) потім ми беремо всі контракти та групуємо їх за певними критеріями (походження, пункт призначення, клієнт тощо). Ми знаємо PNL для кожного контракту на основі його частки всередині паспорта.',
      '5) and as a result you see the grouped list of passports inside certain month\n                  (depending on a business date appointed in the passport)':
        '5. І в результаті ви бачите згрупований список паспортів у межах певного місяця (залежно від бізнес-дати, зазначеної в паспорті).',
      '60-90 days': '60-90 днів',
      '61 - 90': '61 - 90',
      '90 and over days': '90 і більше днів',
      '95-105%': '95-105%',
      '<i class="fa fa-file-excel-o ng-scope"></i>\n            <translate>Download</translate>':
        '<i class="fa fa-file-excel-o ng-scope"></i>\n            <translate>Download</translate>',
      '<i class="fa fa-file-excel-o ng-scope"></i>\n    <translate>Download</translate>':
        '<i class="fa fa-file-excel-o ng-scope"></i>\n    <translate>Download</translate>',
      '<i class="fa fa-file-text-o"></i> Create ticket':
        '<i class="fa fa-file-text-o"></i> Створити тікет',
      '<i class="fa fa-filter"></i> <translate>Apply</translate>':
        '<i class="fa fa-filter"></i> <translate>Застосувати</translate>',
      '<i class="fa fa-floppy-o"></i>': '<i class="fa fa-floppy-o"></i>',
      '<i class="fa fa-pencil-square"></i>': '<i class="fa fa-pencil-square"></i>',
      '<i class="fa fa-refresh"></i>': '<i class="fa fa-refresh"></i>',
      '<i class="fa fa-times"></i>': '<i class="fa fa-times"></i>',
      '<i class="fa fa-times-circle"></i>': '<i class="fa fa-times-circle"></i>',
      '<span class="ng-scope">Apply</span>': '<span class="ng-scope">Застосувати</span>',
      '<span class="ng-scope">Clear</span>': '<span class="ng-scope">Очистити</span>',
      '<span class="ng-scope">Save</span>': '<span class="ng-scope">Зберегти</span>',
      '<span ng-if="!withoutEndOfExecution"> {{ month |date:\'MMM-yyyy\' }}</span>\n              <span ng-if="withoutEndOfExecution">N/A</span>':
        '<span ng-if="!withoutEndOfExecution"> {{ місяць | дата :\'ммм-рррр\' }}</span>\n              <span ng-if="withoutEndOfExecution">N/A</span>',
      'A server error occurred. Please contact the administrator.':
        'Сталася помилка сервера. Будь ласка, зверніться до підримки.',
      ADD: 'ДОДАТИ',
      'ADD NEW': 'ДОДАТИ НОВИЙ',
      ADDITIONAL: 'ДОДАТКОВИЙ',
      'ADDITIONAL AGGR.': 'ДОДАТКОВА УГОДА.',
      ALL: 'ВСІ',
      AMOUNT: 'СУМА',
      AMOUNTS: 'СУМИ',
      ANALYTICAL: 'АНАЛІТИЧНИЙ',
      ANY: 'БУДЬ-ЯКИЙ',
      'AP/AR': 'Дебет/Кредит',
      'APPROVAL CONFIGURATION': 'КОНФІГУРАЦІЯ ПІДТВЕРДЖЕННЯ',
      APPROVE: 'ПІДТВЕРДИТИ',
      'AS A': 'ЯК',
      ASPIRATION: 'АСПІРАЦІЯ',
      'AVG Delay': 'Затримка AVG',
      'AVG contract flat price': 'Середня фіксована ціна контракту',
      'AVG current flat price': 'Середня поточна ціна',
      'AVG delay': 'Середня затримка',
      'AVG hedging + mtm': 'Середнє хеджування та маржа за ринковими цінами (MTM)',
      'AVG hedging price': 'Середня ціна хеджування',
      'AVG packing': 'Середня упаковка',
      'AVG price': 'Середня ціна',
      'AVG rate': 'Середня ставка',
      'AWB number': 'AWB номер',
      Abbreviation: 'Абревіатура',
      'Absolute value': 'Абсолютне значення',
      'Access denied!': 'Доступ заборонено!',
      'According to contract template': 'Згідно з шаблоном контракту',
      Account: 'Рахунок',
      'Account activity': 'Активність рахунку',
      'Account connection': 'Підключення аккаунту',
      'Account details': 'Деталі аккаунту',
      'Account is active': 'Аккаунт активний',
      'Account name': 'Назва рахунку',
      'Account period': 'Період обліку',
      'Account type': 'Тип рахунку',
      Accounting: 'Облік',
      'Accounting period': 'Звітний період',
      Accounts: 'Рахунки',
      'Accounts position': 'Позиція рахунків',
      'Acct#': '№',
      Action: 'Дія',
      'Action flag': 'Прапор дії',
      Actions: 'Дії',
      Active: 'Активний',
      Activity: 'Активність',
      'Activity type': 'Тип активності',
      'Activity types': 'Активності',
      'Actual date': 'Фактична дата',
      'Actually contracted': 'Реально законтрактовано',
      Add: 'Додати',
      'Add agreement': 'Додати угоду',
      'Add bank details': 'Додати банківські реквізити',
      'Add contacts': 'Додати контакти',
      'Add contracts': 'Додати контракти',
      'Add costs/gains': 'Додати витрати/доходи',
      'Add document': 'Додати документ',
      'Add driver': 'Додати водія',
      'Add execution': 'Додати виконання',
      'Add filter': 'Додати фільтр',
      'Add finance': 'Додати інвойс',
      'Add finance (R/W)': 'Додати інвойс (залізниця)',
      'Add finance (auto)': 'Додати інвойс (авто)',
      'Add invoice': 'Додати інвойс',
      'Add invoices': 'Додати інвойси',
      'Add logistic': 'Додати логістику',
      'Add new': 'Додати новий',
      'Add new template': 'Додати новий шаблон',
      'Add payment': 'Додати оплату',
      'Add position': 'Додати позицію',
      'Add quality': 'Додати якість',
      'Add quality rule template': 'Додати шаблон дисконту якості',
      'Add template': 'Додати шаблон',
      'Add transport': 'Додати транспорт',
      'Add vehicle': 'Додати транспортний засіб',
      'Add/update report config to table': 'Додати/оновити конфігурацію звіту до таблиці',
      Added: 'Додано',
      Additional: 'Примітка',
      'Additional Info': 'Додаткова інформація',
      'Additional aggr': 'Додаткова угода',
      'Additional agreement': 'Додаткова угода',
      'Additional agreement saved': 'Додаткову угоду збережено',
      'Additional agreements': 'Додаткові угоди',
      'Additional agreements date': 'Дата додаткових угод',
      'Additional alt': 'Додатковий (альтернативний)',
      'Additional costs': 'Додаткові витрати',
      'Additional currency': 'Додаткова валюта',
      'Additional doc one': 'Додатковий документ №1',
      'Additional doc three': 'Додатковий документ №3',
      'Additional doc two': 'Додатковий документ №2',
      'Additional document one': 'Додатковий документ один',
      'Additional document three': 'Додатковий документ №3',
      'Additional document two': 'Додатково документ (2)',
      'Additional exchange rate': 'Додатковий курс обміну',
      'Additional info': 'Додаткова інформація',
      'Additional info for screenshot': 'Додаткові деталі для знімку екрану',
      'Additional info regarding confirmation of this company':
        'Додаткова інформація щодо статусу підтвердження цієї компанії',
      'Additional info updated': 'Додаткова інформація оновлена',
      Address: 'Адреса',
      'Address (eng)': 'Адреса (англ)',
      'Address Eng': 'Адреса (англ)',
      'Address eng': 'Адреса (англ)',
      Addresses: 'Адреси',
      'Admin panel': 'Адмін панель',
      Affiliated: 'Приєднаний',
      'Affiliated suppliers': 'Приєднані постачальники',
      Age: 'Вік',
      Agent: 'Посередник',
      Aggregators: 'Агрегатори',
      Agreement: 'Угода',
      'Agreement approval status': 'Статус підтвердження угоди',
      "Agreement doesn't exist": 'Угоди не існує',
      'Agreement limit': 'Ліміт угоди',
      'Agreement number': 'Номер угоди',
      'Agreement removed': 'Угоду видалено',
      'Agreement saved': 'Угоду збережено',
      'Agreement status': 'Статус генеральної угоди',
      'Agreement value': 'Сума угоди',
      Agreements: 'Угоди',
      Agritel: 'Агрітель',
      All: 'Всі',
      'All Additional Agreements has originals': 'Всі додаткові угоди мають оригінали',
      'All copies': 'Всі копії',
      'All counterparties of your company': 'Всі контрагенти вашої компанії',
      'All history': 'Вся історія',
      'All info': 'Вся інформація',
      'All of the following related items will be deleted:':
        "Всі наступні пов'язані об'єкти будуть видалені:",
      'All originals': 'Всі оригінали',
      'All signed': 'Всі підписали',
      'All uploaded documents': 'Всі завантажені документи',
      'All users of the company': 'Всі користувачі компанії',
      'Allocate this operation to certain account. This account will be a criteria to match sales and purchases in Derivatives - Account Position report':
        'Закріпити цю операцію до певного рахунку. Цей рахунок буде критерієм відповідності продажів і закупівлі у деривативах - звіт про позицію рахунка',
      'Allocate this operation to certain contract': 'Закріпити цю операцію до певного контракту',
      'Allocate this operation to certain passport. This passport will be a criteria to match sales and purchases in Derivatives - Contract Position report':
        'Закріпити цю операцію до певного паспорта. Цей паспорт стане критерієм для відповідності продажів і закупівлі у Деривативах - звіті про позиції контракту',
      'Allocate this passport to certain account. This account will be a criteria to match sales and purchases in Derivatives - Account Position report':
        'Призначте цей паспорт певному рахунку. Цей рахунок буде критерієм відповідності продажів і закупівлі у деривативах - звіт про позицію рахунка',
      Allocated: 'Розподілено',
      Allocation: 'Розподіл',
      Allocations: 'Розподіли',
      Amount: 'Сума',
      'Amount $': 'Сума, usd',
      'Amount (basic)': 'Сума (базова)',
      'Amount - Paid': 'Сума - Оплачено',
      'Amount BL+10%': 'Сума коносаментів + 10%',
      'Amount USD': 'Сума, USD',
      'Amount With Vat': 'Сума з ПДВ',
      'Amount alternative': 'Сума альтернативна',
      'Amount correction': 'Виправлення суми',
      'Amount in offset': 'Сума у заліку',
      'Amount local': 'Локальна сума',
      'Amount not reserved': 'Сума не зарезервована',
      'Amount of discount': 'Сума дисконту',
      'Amount of premium': 'Сума премії',
      'Amount of premiums for the delivered goods': 'Сума премій за поставлений товар',
      'Amount of premiums from the contracted goods': 'Розмір премій від законтрактованих товарів',
      'Amount per 1 t': 'Сума за 1 т',
      'Amount per deal execution': 'Загальна сума за угоду',
      'Amount per t': 'Сума за 1 т',
      'Amount per whole deal': 'Сума за всю угоду',
      'Amount reconciliation': 'Звірка суми',
      'Amount to connect': 'Вільна сума видаткової накладної',
      'Amount to connect DBL': 'Вільна сума для приєднання до ВН',
      'Amount with VAT': 'Сума з ПДВ',
      'Amount with discount': 'Сума з дисконтом',
      'Amount withdrawn': 'Знята сума',
      'Amount without VAT': 'Сума без ПДВ',
      'Amount without VAT, $': 'Сума без ПДВ, $',
      'Amount, $': 'Сума, usd',
      'Amount/t': 'Сума/т',
      'Amount_local no vat': 'Сума у локальній валюті, без ПДВ',
      Amounts: 'Суми',
      'An error occurred while fetching multicontract data.':
        'Під час отримання даних мультиконтрактів сталася помилка.',
      'An error occurred while saving charges.': 'Під час збереження витрат сталася помилка.',
      Analytic: 'Аналітичний',
      Analytical: 'Аналітичний',
      'Analytical Amount': 'Аналітична сума',
      'Analytical P&L MTM': 'Аналітичний ПНЛ МТМ',
      'Analytical Price': 'Аналітична ціна',
      'Analytical amount': 'Аналітична сума',
      'Analytical costs': 'Аналітичні витрати',
      'Analytical diff': 'Аналітична різниця',
      'Analytical parent': 'Аналітична парент група',
      'Analytical parent charge': 'Аналітична група витрат',
      'Analytical parent commodity': 'Аналітична батьківська культура',
      'Analytical price': 'Аналітична ціна',
      'Analytical price different from contract price':
        'Аналітична ціна відрізняється від контрактної ціни',
      'Analytical result': 'Аналітичний результат',
      'Analytical-Plan-Fact PnL': 'Аналітичний План-Факт ПнЛ',
      Analytics: 'Аналітичні',
      Angry: 'Злий',
      'Applied filters': 'Застосовані фільтри',
      Apply: 'Застосувати',
      'Apply default': 'Застосувати за замовчуванням',
      'Apply reserve': 'Застосувати резерв',
      'Appoint an amount of discount or use discount in %':
        'Вкажіть суму дисконту або використайте відсоток дисконту',
      'Appoint it if you want to apply discount per 1 mt':
        'Позначте, якщо бажаєте застосувати дисконт на 1 т',
      'Appoint to which CONTRACT you want to allocate this charge or gain':
        'Призначте, на який КОНТРАКТ Ви хочете закріпити цю витрату або дохід',
      'Appoint to which PASSPORT you want to allocate this charge or gain in cases when contract has more than one passport and charge has to be allocated to a specific one. In other cases leave these field blank':
        "Призначте, до якого ПАСПОРТУ ви хочете закріпити цю витрату або дохід у випадках,коли контракт має більше одного паспорта і витрату/дохід необхідно зв'язати із конкретним паспортом. У інших випадках залиште ці поля порожніми",
      Approval: 'Погодження',
      'Approval Config': 'Конфігурація підтвердження',
      'Approval Status': 'Статус підтвердження',
      'Approval configuration': 'Конфігурація підтвердження',
      'Approval in process': 'Підтвердження в процесі',
      'Approval report': 'Звіт про затвердження',
      'Approval status': 'Статус підтвердження',
      'Approval updated': 'Погодження оновлено',
      Approvals: 'Підтвердження',
      'Approvals reactivated': 'Оновлено підтвердження',
      'Approvals report': 'Звіт про погодження',
      Approve: 'Затвердити',
      'Approve / Decline': 'Підтвердити / Відхилити',
      'Approve approvals': 'Підтвердити',
      Approved: 'Підтверджено',
      'Approved by month': 'Підтверджено за місяцями',
      'Approved condition': 'Підтверджено з умовою',
      Approvers: 'Затверджувачі',
      April: 'Квітень',
      Archived: 'Архівовано',
      'Are you sure that you want copy quality rules for all purchase facts?':
        'Ви впевнені, що бажаєте скопіювати правила якості для всіх фактів закупівлі?',
      "Are you sure that you want delete 'Custom cargo declaration'?":
        'Ви впевнені, що хочете видалити «Вантажну митну декларацію»?',
      "Are you sure that you want delete 'Driver'?": 'Ви впевнені, що бажаєте видалити Водія?',
      "Are you sure that you want delete 'Plan'?": 'Ви впевнені, що бажаєте видалити "План"?',
      "Are you sure that you want delete 'Vehicle'?":
        'Ви впевнені, що бажаєте видалити транспортний засіб?',
      'Are you sure that you want delete Cert?': 'Ви впевнені, що бажаєте видалити Сертифікат?',
      'Are you sure that you want delete Contract Charge?':
        'Ви впевнені, що бажаєте видалити Витрати за контрактом?',
      'Are you sure that you want delete Freight?': 'Ви впевнені, що бажаєте видалити Фрахт?',
      'Are you sure that you want delete Lost?': 'Ви впевнені, що бажаєте видалити Витрати?',
      'Are you sure that you want delete Position?': 'Ви впевнені, що бажаєте видалити Позицію?',
      'Are you sure that you want delete Tariff?': 'Ви впевнені, що бажаєте видалити Тариф?',
      'Are you sure that you want delete a template?': 'Ви впевнені, що бажаєте видалити шаблон?',
      'Are you sure that you want delete agreement?': 'Ви впевнені, що бажаєте видалити Угоду?',
      'Are you sure that you want delete bill of lading?':
        'Ви впевнені, що бажаєте видалити коносамент?',
      'Are you sure that you want delete passport?': 'Ви впевнені, що бажаєте видалити паспорт?',
      'Are you sure that you want delete this "Stock Reserve Unit"?':
        'Ви впевнені, що бажаєте видалити цю «Одиницю фондового резерву»?',
      'Are you sure that you want delete this Person?':
        'Ви впевнені, що бажаєте видалити цей Контакт?',
      'Are you sure that you want delete this bank operation?':
        'Ви впевнені, що бажаєте видалити банківську операцію?',
      'Are you sure that you want delete this bank?': 'Ви впевнені, що бажаєте видалити банк?',
      'Are you sure that you want delete this consignment?':
        'Ви впевнені, що бажаєте видалити квитанцію?',
      'Are you sure that you want delete this credit note?':
        'Ви впевнені, що бажаєте це видалити кредит-ноту?',
      'Are you sure that you want delete this disbursement bill of lading?':
        'Ви впевнені, що бажаєте видалити видаткову накладну?',
      'Are you sure that you want delete this document?':
        'Ви впевнені, що бажаєте видалити документ?',
      'Are you sure that you want delete this payment plan?':
        'Ви впевнені, що бажаєте це видалити цей план оплати?',
      'Are you sure that you want delete this payment?': 'Ви впевнені, що бажаєте видалити оплату?',
      'Are you sure that you want delete this template?':
        'Ви впевнені, що бажаєте видалити шаблон?',
      'Are you sure that you want delete this transaction?':
        'Ви впевнені, що хочете вилучити цю операцію?',
      'Are you sure that you want delete this vehicle?':
        'Ви впевнені, що бажаєте це видалити транспортний засіб?',
      'Are you sure that you want delete this warehouse?':
        'Ви впевнені, що бажаєте видалити цей склад?',
      'Are you sure that you want delete this?': 'Ви впевнені, що бажаєте видалити це?',
      'Are you sure that you want delete user?': 'Ви впевнені, що бажаєте видалити користувача?',
      'Are you sure that you want delete?': 'Ви впевнені, що бажаєте видалити?',
      'Are you sure that you want to create Contract Charges?':
        'Ви впевнені, що бажаєте видалити Витрату контракту?',
      'Are you sure that you want to delete parsing config?':
        'Ви впевнені, що бажаєте видалити конфігурацію парсингу?',
      'Are you sure that you want to delete?': 'Ви впевнені, що бажаєте видалити це?',
      'Are you sure that you want to set cargo confirmation?':
        'Ви впевнені, що бажаєте встановити підтвердження вантажу?',
      'Are you sure that you want to set fact volume to plans?':
        "Ви впевнені, що бажаєте встановити фактичний об'єм замість планового?",
      'Are you sure you want to delete loan?': 'Ви впевнені, що бажаєте видалити позику?',
      'Are you sure you want to delete quote parsing config?':
        'Ви впевнені, що бажаєте видалити це?',
      'Are you sure you want to delete the': 'Ви впевнені, що бажаєте видалити',
      'Are you sure you want to delete this collection?':
        'Ви впевнені, що хочете видалити цю колекцію?',
      'Are you sure?': 'Ви впевнені?',
      'Area, hectare': 'Площа, га',
      'Arrival date': 'Дата прибуття',
      'Arrival end position': 'Кінць прибуття позиції',
      'Arrival start position': 'Початок прибуття позиції',
      Aspiration: 'Аспірація',
      Assets: 'Активи',
      'Assigned to': 'Призначено',
      'Assigned to me': 'Призначено мені',
      Assignment: 'Завдання',
      'At discharging port': 'У порту розвантаження',
      'At end': 'В кінці',
      'At least one company is required': 'Потрібна хоча б одна компанія',
      'At loading port': 'У порту завантаження',
      'At road': 'В дорозі',
      'At start': 'На початку',
      Attach: 'Приєднати',
      'Attach contracts to passport': 'Приєднати контракти до паспорта',
      'Attach documents': 'Додані документи',
      'Attach purchase': 'Приєднати закупівлю',
      'Attach sale': 'Приєднати продаж',
      'Attach stocks': 'Приєднати залишки',
      'Attach ticket': 'Приєднати тікет',
      'Attach to the passport': 'Приєднати до паспорта',
      'Attached to passport': 'Приєднано до паспорту',
      'Attaching files': 'Приєднання файлів',
      Attachments: 'Вкладення',
      August: 'Серпень',
      Author: 'Автор',
      Auto: 'Авто',
      'Auto generated': 'Автостворені',
      'Auto payment conditions': 'Умови автооплати',
      'Auto prepay - balance percent': 'Автопередоплата - відсоток балансу',
      'Available in bot': 'Доступно в боті',
      'Available passports': 'Доступні паспорти',
      'Available to connect': "Доступні для з'єднання",
      'Available under limit': 'Доступно поверх ліміту',
      'Available volume': "Доступний об'єм",
      'Average commodities price, $/t with VAT': 'Середня ціна товарів, $/т з ПДВ',
      'Average flat price per fixed lots': 'Середня фіксована ціна за фіксовані лоти',
      'Average flat price per hedged lots': 'Середня фіксована ціна за хеджовані лоти',
      'Average mt price per fixed lots': 'Середня ціна за 1т за фіксовані лоти',
      'Average price': 'Середня ціна',
      'Average purchase': 'Середня закупівля',
      'Average sale': 'Середній продаж',
      B: 'B',
      'B/L': 'Коносамент',
      'B/L date': 'Дата коносаменту',
      'B/L number': 'Номер коносаменту',
      "B/L's": 'Коносаменти',
      'B/Ls total volume': "Загальний об'єм коносаментів",
      B2B: 'B2B',
      BALANCE: 'БАЛАНС',
      'BANK ACCOUNTS': 'БАНКІВСЬКІ РАХУНКИ',
      BI: 'BI',
      'BILLS OF LADING': 'КОНОСАМЕНТИ',
      BL: 'Коносамент',
      'BL Volume': "Об'єм коносамента",
      'BL date': 'Дата коносаменту',
      'BL loaded': 'Завантажено по коносаменту',
      'BL max date': 'Остання дата коносаменту',
      'BL volume:': "Об'єм коносаментів:",
      'BL/ETA ASC': 'BL/ETA ASC',
      'BL/ETA DESC': 'BL/ETA DESC',
      BLs: 'Коносаменти',
      'BLs are invoiced': 'Виставлені інвойси коносаментів',
      'BLs losses volume': 'Втрати по коносаменту',
      BOAT: 'СУДНО',
      BP: 'BP',
      BU: 'Бізнес-юніт',
      'BU / Client': 'БЮ/Контрагент',
      BUYER: 'ПОКУПЕЦЬ',
      'B\\L': 'Коносамент',
      "B\\L's": 'Коносаменти',
      'Bad payment amount': 'Неправильна сума платежу',
      'Bags quantity': 'Кількість мішків',
      'Bags weight': 'Вага мішків',
      Balance: 'Баланс',
      'Balance at the beginning': 'Баланс на початок',
      'Balance at the end': 'Баланс на кінець',
      'Balance default': 'Баланс за замовчуванням',
      'Balance invoice': 'Інвойс балансу',
      'Balance payment': 'Оплата балансу',
      'Balance payment dates': 'Дати оплати балансу',
      'Balance to offset': 'Баланс для заліку',
      'Balance to settlement': 'Баланс до розрахунку',
      'Balance volume': "Об'єм балансу",
      'Balance, Cost / Gains': 'Баланс, витрати/дохід',
      Bank: 'Банк',
      'Bank Account': 'Банківський рахунок',
      'Bank Account Balance': 'Баланс банківського рахунку',
      'Bank Account Total': 'Загальна сума банківського рахунку',
      'Bank Accounts': 'Банківські рахунки',
      'Bank Comission': 'Комісія банку',
      'Bank Eng': 'Банк (англ)',
      'Bank Operations': 'Банківські операції',
      'Bank Transactions': 'Банківські транзакції',
      'Bank account': 'Банківський рахунок',
      'Bank account deleted successfully': 'Банківський рахунок успішно видалено',
      "Bank account doesn't exist": 'Банківського рахунку не існує',
      'Bank account saved': 'Банківський рахунок збережено',
      'Bank accounts': 'Банківські рахунки',
      'Bank branch': 'Відділення банку',
      'Bank branch saved': 'Відділення банку збережено',
      'Bank branches': 'Відділення банків',
      'Bank client': 'Банк контрагента',
      'Bank code': 'Код банку',
      'Bank data check': 'Перевірка банківських даних',
      'Bank data is checked': 'Банківські дані перевірені',
      'Bank details': 'Банківські реквізити',
      'Bank details is cloned': 'Банківські деталі склоновано',
      'Bank name': 'Назва банку',
      'Bank name IBAN': 'Назва банку IBAN',
      'Bank operation': 'Банківська операція',
      'Bank operation removed': 'Банківська операція видалена',
      'Bank operation saved': 'Банківська операція збережена',
      'Bank operations': 'Банківські операції',
      'Bank removed': 'Банк видалений',
      'Bank saved': 'Банк збережено',
      'Bank transaction': 'Банківська транзакція',
      'Bank transaction commission': 'Комісія за банківські операції',
      'Bank transaction saved.': 'Банківська транзакція збережена.',
      'Bank transactions': 'Банківські операції',
      Banks: 'Банки',
      Barchart: 'Гістограма',
      Base: 'База',
      'Base, days': 'База, днів',
      'Basic metrics': 'Базові показники',
      Basis: 'Базис',
      'Basis Docs': 'Документ',
      'Basis Opposite': 'Протилежний базис',
      BasisDoc: 'Базис документ',
      Basises: 'Базиси',
      Batch: 'Партія',
      'Batch History': 'Історія партії',
      'Batch Owner': 'Власник партії',
      'Batch Vessel': 'Судно партії',
      'Batch Voyage': 'Рейс партії',
      'Batch owner': 'Власник партії',
      'Batch rest age (only on balance)': 'Вік залишку партії (тільки на балансі)',
      'Batch storage time': 'Час зберігання партії',
      Bcc: 'Прихована копія',
      'Because exactly this figure participates in calculation of Passport\n                    profitability.':
        'Бо саме ця цифра бере участь у розрахунку за паспортом прибутковість.',
      Bid: 'Заявка',
      'Bid/Offer': 'Ставка/Пропозиція',
      Bids: 'Бід',
      Big: 'Великий',
      'Bill of Lading': 'Коносамент',
      'Bill of lading': 'Коносамент',
      'Bill of lading number': 'Номер коносаменту',
      'Bill of lading removed': 'Коносамент видалено',
      'Bill of lading saved': 'Коносамент збережено',
      'Bill of lading saved and connected to logistics':
        'Коносамент збережено та підключено до логістики',
      BillOfLading: 'Коносамент',
      Billoflading: 'Коносамент',
      'Bills of Lading': 'Коносаменти',
      'Bills of lading': 'Коносамент',
      Birthday: 'День народження',
      'Bite off some volume from this Indicator to create a Ticket':
        'Використати певний об’єм від Індикатора, щоб створити Тікет',
      'Bite off some volume from this Potential to create a Ticket':
        'Використати певний об’єм від Потенціала, щоб створити Тікет',
      'Black list': 'Чорний список',
      'Black listed': 'Занесений до чорного списку',
      Blacklisted: 'В чорному списк',
      Block: 'Блок',
      Blocked: 'Заблоковано',
      Boarded: 'Завантажено',
      'Boarded (date)': 'Завантажено (дата)',
      'Boarded Balance': 'Завантажений баланс',
      'Boarded volume': "Завантажений об'єм",
      Boat: 'Судно',
      Body: 'Тіло листа',
      Boolean: 'Булевий',
      'Border Crossing Date': 'Дата перетину кордону',
      'Border crossing date': 'Дата перетину кордону',
      Borrower: 'Позичальник',
      Bot: 'Бот',
      'Both calculations': 'Обидва розрахунки',
      Bots: 'Боти',
      Broker: 'Брокер',
      'Broker EDRPOU code': 'Брокер код ЄДРПОУ',
      'Broker Signed': 'Брокер підписав',
      'Broker address': 'Брокер адреса',
      'Broker can signatory docs': 'Брокер може підписувати документи',
      'Broker can signatory docs eng': 'Брокер може підписувати документи (англ)',
      'Broker confirmation': 'Підтвердження брокера',
      'Broker cost': 'Брокерські витрати',
      'Broker country': 'Брокер країна',
      'Broker country eng': 'Брокер країна (англ)',
      'Broker details': 'Деталі брокера',
      'Broker email': 'Брокер email',
      'Broker full name eng': 'Брокер повна назва (англ)',
      'Broker full name ru': 'Брокер повна назва',
      'Broker legal address': 'Брокер юридична адреса',
      'Broker legal address eng': 'Брокер юридична адреса (англ)',
      'Broker name': 'Назва брокера',
      'Broker phone': 'Брокер телефон',
      'Broker signatory': 'Підписант брокера',
      'Broker signatory eng': 'Підписант брокера (англ)',
      'Broker signed': 'Підписано брокером',
      'Broker website': 'Брокер веб-сайт',
      Brokerage: 'Брокерська діяльність',
      Brokers: 'Брокери',
      Budget: 'Бюджет',
      Budgets: 'Бюджети',
      'Busines Unit': 'Бізнес-юніт',
      Business: 'Бізнес',
      'Business (date)': 'Бізнес (дата)',
      'Business Date': 'Бізнес дата',
      'Business Unit': 'Бізнес-юніт',
      'Business Units': 'Бізнес-юніти',
      'Business confirmation': 'Бізнес підтвердження',
      'Business date': 'Бізнес дата',
      'Business days': 'Робочі дні',
      'Business unit': 'Бізнес-юніт',
      'Business units': 'Бізнес-юніти',
      Buy: 'Покупка',
      'Buy quantity': 'Кількість покупки',
      Buyer: 'Покупець',
      'Buyer 10 invoice': '10 інвойс покупця',
      'Buyer 90 invoice': 'Покупець 90 інвойс',
      'Buyer EDRPOU code': 'Код ЄДРПОУ покупця',
      'Buyer ITN (Individual Tax Number)': 'Покупець ІПН (індивідуальний податковий номер)',
      'Buyer Signed': 'Покупець підписав',
      'Buyer address': 'Адреса покупця',
      'Buyer bank details': 'Банківські реквізити покупця',
      'Buyer can signatory docs': 'Покупець може підписувати документи',
      'Buyer can signatory docs eng': 'Покупець може підписувати документи (англ)',
      'Buyer contract': 'Контракт покупця',
      'Buyer contract #': 'Контракт покупця №',
      'Buyer contract ISCC': 'Контракт покупця ISCC',
      'Buyer contract status': 'Статус контракту покупця',
      'Buyer country': 'Країна покупця',
      'Buyer country eng': 'Країна покупця (англ)',
      'Buyer email': 'Покупець email',
      'Buyer full name': 'Повна назва покупця',
      'Buyer full name eng': 'Повна назва покупця (англ)',
      'Buyer invoiced': 'Покупець виставив інвойс',
      'Buyer is required': 'Необхідний покупець',
      'Buyer legal address': 'Покупець юридична адреса',
      'Buyer legal address eng': 'Покупець юридична адреса (англ)',
      'Buyer paid': 'Покупець сплатив',
      'Buyer passports #': 'Паспорт покупця №',
      'Buyer payment info': 'Інфо про оплату покупця',
      'Buyer phone': 'Телефон покупця',
      'Buyer signatory': 'Підписант покупця',
      'Buyer signatory eng': 'Підписант покупця (англ)',
      'Buyer signed': 'Покупець підписав',
      'Buyer tax info': 'Покупець податкова інфо',
      'Buyer website': 'Покупець веб-сайт',
      'Buyer/Owner': 'Покупець/Власник',
      Buyers: 'Покупці',
      'By analytical price': 'По аналітичній ціні',
      'By contract price': 'По ціні контракту',
      'By default currency': 'За валютою за замовчуванням',
      'By invoice currency': 'За валютою інвойсу',
      CARGO: 'ВАНТАЖ',
      'CASH FLOW RECREATE': 'КЕШ-ФЛО ВІДНОВИТИ',
      CASHFLOW: 'КЕШ-ФЛО',
      'CASHFLOW + BALANCES': 'КЕШ-ФЛО + БАЛАНСИ',
      CCD: 'CCD',
      "CCD doesn't exist": 'CCD не існує',
      'CCD volume': "CCD об'єм",
      CD: 'CD',
      CHAIN: 'ЛАНЦЮГ',
      CHARGES: 'ВИТРАТИ',
      'CHECK LIST': 'ЧЕК-ЛИСТ',
      'CHOOSE FAVORITES': 'ВИБРАТИ УЛЮБЛЕНІ',
      CLOSED: 'ЗАКРИТО',
      COMMISSIONS: 'КОМІСІЇ',
      COMMODITY: 'ТОВАР',
      COMPANIES: 'КОМПАНІЇ',
      COMPANY: 'КОМПАНІЯ',
      COMPLIANCE: 'КОМПЛАЄНС',
      'CONCLUSION DATE': 'ДАТА УКЛАДАННЯ',
      CONDITIONS: 'УМОВИ',
      CONFIG: 'КОНФІГУРАЦІЯ',
      CONFIGURATION: 'КОНФІГУРАЦІЯ',
      CONSIGNMENTS: 'СКЛАДСЬКІ КВИТАНЦІЇ',
      CONTACTS: 'КОНТАКТИ',
      CONTRACT: 'КОНТРАКТ',
      'CONTRACT DOCUMENTS': 'ДОКУМЕНТИ КОНТРАКТУ',
      'CONTRACT VALUE': 'СУМА КОНТРАКТІВ',
      CONTRACTS: 'КОНТРАКТИ',
      CONTROL: 'КОНТРОЛЬ',
      COSTS: 'ВИТРАТИ',
      'COSTS OF GOODS': 'СОБІВАРТІСТЬ',
      COUNTERPARTIES: 'КОНТРАГЕНТИ',
      'CP approval status': 'CP статус підтвердження',
      'CP custom status': 'CP статус користувача',
      'CP date': 'Дата чартер партії',
      'CP/Seeds Prepayments': 'Передоплата чартер партії/посівів',
      'CREATE A FILTER': 'СТВОРИТИ ФІЛЬТР',
      CREATED: 'СТВОРЕНО',
      'CREATION DATE': 'ДАТА СТВОРЕННЯ',
      CREDIT: 'КРЕДИТ',
      CRM: 'CRM',
      CTL: 'CTL',
      CURRENCY: 'ВАЛЮТА',
      'CUSTOM VALUES': 'КАСТОМНІ ЗНАЧЕННЯ',
      'Cached value should be only number, string, array or object':
        'Збережене значення повинно бути лише числом, рядком, масивом або об’єктом',
      'Calc intermediate logistics': 'Розрахунок проміжної логістики',
      'Calculate auto tariff': 'Розрахувати тариф ТЗ',
      'Calculate broker cost': 'Розрахувати брокерські витрати',
      'Calculate elevator costs': 'Розрахувати витрати на елеватор',
      'Calculate profit and loss': 'Розрахунок PnL',
      'Calculate railway tariff': 'Розрахувати тариф на залізницю',
      'Calculate tax profit to pay': 'Розрахувати податок до сплати',
      'Calculated VAT': 'Розрахований ПДВ',
      'Calculated quantity': 'Розрахована кількість',
      Calculator: 'Калькулятор',
      Calendar: 'Календар',
      'Calendar days': 'Календарні дні',
      Call: 'Дзвінок',
      'Can access Admin': 'Має адмін доступ',
      'Can be contract owner': 'Може бути власником контракту',
      'Can confirm': 'Може підтвердити',
      Cancel: 'Скасувати',
      'Cancel all': 'Скасувати все',
      'Cancel contract': 'Скасувати контракт',
      'Cancel indicator': 'Скасувати індикатор',
      'Cancel ticket': 'Скасувати тікет',
      'Cancel {[{ $ctrl.multicontract.stage }]}': 'Скасувати {[{ $ctrl.multicontract.stage }]}',
      'Cancel {[{ item.stage }]}': 'Скасувати {[{ item.status }]}',
      Canceled: 'Скасовано',
      Cancelled: 'Скасовано',
      'Cancelled?': 'Скасувати?',
      'Cannot delete {[{ $ctrl.results.object_model }]}':
        'Неможливо видалити {[{ $ctrl.results.object_model }]}',
      Capacity: 'Вмістимість',
      'Capacity avg': 'Середня вмістимість',
      'Capacity est': 'Розрахункова місткість',
      'Capacity max': 'Ємність максимальна',
      'Capacity min': 'Мінімальна місткість',
      Car: 'Автомобіль',
      Cargo: 'Вантаж',
      'Cargo Price': 'Ціна за вантаж',
      'Cargo all': 'Весь вантаж',
      'Cargo brand': 'Бренд вантажу',
      'Cargo confirmation': 'Підтвердження вантажу',
      'Cargo customs declaration': 'Вантажна митна декларація',
      'Cargo customs declarations': 'Вантажна митна декларація',
      'Cargo details': 'Деталі вантажу',
      'Cargo eng': 'Вантаж (англ)',
      'Cargo executed': 'Виконаний вантаж',
      'Cargo fully paid': 'Вантаж повністю оплачений',
      'Cargo is required': 'Потрібен вантаж',
      'Cargo partial executed': 'Вантаж виконано частково',
      'Cargo receiver': 'Отримувач вантажу',
      'Cargo shipper': 'Вантажовідправник',
      Carrier: 'Перевізник',
      'Cars prepay - balance percent': 'Авто по передоплаті - відсоток балансу',
      Cash: 'Кошти',
      'Cash Flow': 'Кеш-фло',
      'Cash Flow (mixed)': 'Кеш-фло (мікс)',
      'Cash Flow report': 'Звіт Кеш-фло',
      'Cash Start': 'Початкова сума',
      'Cash flow': 'Кеш-фло',
      'Cash flow recreated': 'Кеш-фло відновлено',
      Cashback: 'Кешбек',
      'Cashback amount': 'Сума кешбеку',
      Cashflow: 'Кеш-фло',
      Cc: 'Копія',
      'Certificate Dates': 'Дати сертифікатів',
      'Certificates exist in following logistics. Do you want to override them?':
        'Сертифікати вже існують у цій логістиці. Ви бажаєте їх замінити?',
      'Certification schemes': 'Сертифікаційні схеми',
      Certs: 'Сертифікати',
      'Chain order': 'Послідовне замовлення',
      Change: 'Змінити',
      'Change password': 'Змінити пароль',
      'Change the status of contract to shipment': 'Змінити статус контракту на відвантаження',
      Changed: 'Змінено',
      Charge: 'Витрати',
      'Charge Distribution': 'Розподіл витрати',
      'Charge amount': 'Сума витрати',
      'Charge basis': 'Базис витрати',
      'Charge date': 'Дата витрати',
      'Charge level': 'Рівень витрати',
      'Charge list': 'Список витрат',
      Charges: 'Витрати',
      'Charges / Gains': 'Витрати / Доходи',
      'Charges Analytics': 'Аналітика витрат',
      'Charges types': 'Типи витратів',
      Chart: 'Графік',
      'Charter party (date)': 'Чартер-партія (дата)',
      'Charter party date': 'Договір чартеру (дата)',
      Charterer: 'Фрахтувальник',
      'Charterer is owner': 'Фрахтувальник є власником',
      Charts: 'Графіки',
      Chats: 'Чат',
      Check: 'Перевірити',
      'Check connections': "Перевірити зв'язки",
      'Check costs': 'Перевірте витрати',
      'Check destination': 'Перевірте пункт призначення',
      'Check execution': 'Перевірте виконання',
      "Check if everything's allright": 'Перевірте, чи все гаразд',
      'Check invoices': 'Перевірте інвойс',
      'Check list': 'Перевірте список',
      'Check list of docs is set up': 'Перевірка списку документів налаштована',
      'Check point of departure': 'Перевірте пункт відправлення',
      'Check preview and send an email': 'Переглянути перед надсиланням та надіслати email',
      'Check the hedging': 'Перевірте хеджування',
      'Check the position': 'Перевірте позицію',
      'Check this if LOI was issued': 'Перевірте, чи було видано LOI',
      'Check this if counterparty has criminal cases':
        'Позначте, якщо у контрагента є кримінальні справи',
      'Check this if counterparty has sanctions': 'Позначте, якщо контрагент має санкції',
      'Check this if counterparty has tax debt': 'Позначте, якщо контрагент має податковий борг',
      'Check this if the agreement is digitally signed':
        'Перевірте це, якщо угода має цифровий підпис',
      'Check this if the contract is digitally signed':
        'Перевірте це, якщо контракт має цифровий підпис',
      'Check this if the counterparty in invoice is different than the one in the contract':
        'Позначте, якщо контрагент в інвойсі відрізняється від контрагента в контракті',
      'Check this if the purchase of certified products on iscc standards':
        'Позначте, якщо купуєте сертифіковану продукцію за стандартами iscc',
      'Check this if this supplier is distributor.':
        'Позначте, якщо цей постачальник є дистриб’ютором.',
      'Check this if you don`t want to check with SINGING DAYS QUANTITY value':
        'Позначте, якщо не бажаєте перевіряти КІЛЬКІСТЬ ДНІВ ПІДПИСАННЯ',
      'Check this if you to show basis-port-price widget':
        'Позначте, якщо ви бажаєте відобразити віджет базис-порт-ціна',
      'Check this if you to show derivatives widget': 'Позначте, якщо хочете вказати декілька цін',
      'Check this if you want add multiple consignees':
        'Позначте це, якщо бажаєте додати кількох вантажоотримувачів',
      'Check this if you want agreement number to be auto generated':
        'Позначте, якщо бажаєте, щоб номер угоди генерувався автоматично',
      'Check this if you want calculate complex percent':
        'Позначте, якщо ви хочете обчислити складний відсоток',
      'Check this if you want calculate simple percent':
        'Позначте, якщо ви хочете обчислити простий відсоток',
      'Check this if you want contract name to be auto generated':
        'Позначте, якщо бажаєте , щоб номер контракту був автоматично згенерований',
      'Check this if you want finance number to be auto generated':
        'Позначте, якщо бажаєте , щоб номер інвойсу автоматично згенеровався',
      'Check this if you want loan name to be auto generated':
        'Позначте, якщо бажаєте , щоб номер позики автоматично згенеровався',
      'Check this if you want name to be auto generated':
        'Позначте це, якщо ви хочете, щоб назва була автоматично згенерована',
      'Check this if you want number to be auto generated':
        'Позначте, якщо бажаєте, щоб номер генерувався автоматично',
      'Check this if you want to display BLACKLIST label alongside company name':
        'Позначте ,якщо бажаєте відображати позначку ЧОРНИЙ СПИСОК поряд з назвою компанії',
      'Check this if you want to ignore allocation':
        'Позначте це, якщо бажаєте ігнорувати розподіл',
      'Check this if you want to make contract to swap type':
        'Позначте, бажаєте укласти СВОП контракт',
      'Check this if you want to parse this quote':
        'Позначте, якщо ви хочете парсувати це котирування',
      'Check this if you want to use broker tariff in price calculate':
        'Позначте, якщо бажаєте використовувати брокерський тариф при розрахунку ціни',
      'Check this if you want to use it as internal chain':
        'Позначте, якщо бажаєте використовувати як внутрішній ланцюг',
      'Check this if you want to use match with commodity parent':
        'Позначте це, якщо бажаєте використовувати відповідність з батьківським товаром',
      'Check this if you want to use tax profit to pay in price calculate':
        'Позначте, якщо бажаєте використовувати податок на прибуток в розрахунку ціни',
      'Check this if you want to use volume position as aspiration':
        'Позначте, якщо бажаєте використовувати об’єм позиції як аспірацію',
      'Check this to avoid standard KPI evaluation process':
        'Позначте це, щоб уникнути стандартного процесу оцінювання KPI',
      'Check this, if current company buy or sell in USD':
        'Перевірте, якщо поточна компанія купує або продає у валюті USD',
      'Check this, if current company buy or sell only in local currency':
        'Перевірте це, якщо поточна компанія купує або продає лише в локальній валюті',
      'Check this, if current company is an Agent and not a Producer':
        'Позначте, якщо компанія є Посередником, а не Виробником',
      'Check this, if the Supplier of Farm is an original producer':
        'Позначте, якщо Постачальник чи Господарство є Виробником',
      'Check volume': "Перевірте об'єм",
      'Checklist Point': 'Пункт перевірки',
      'Checklist completed (copy)': 'Чеклист завершено (копія)',
      'Checklist completed (original)': 'Чеклист завершено (оригінал)',
      'Checklist completed (originals)': 'Чеклист завершено (оригінали)',
      'Checklist point': 'Пункт перевірки',
      'Child commodity': 'Дочірня культура',
      Choice: 'Вибір',
      Choose: 'Вибрати',
      'Choose Batch': 'Виберіть партію',
      'Choose Business Unit': 'Виберіть бізнес-юніт',
      'Choose Business unit': 'Виберіть Бізнес-юніт',
      'Choose Condition': 'Виберіть умову',
      'Choose Invoicing Amount': 'Виберіть суму інвойсу',
      'Choose Multipassport': 'Виберіть мультипаспорт',
      'Choose Owner': 'Виберіть власника',
      'Choose Producer': 'Виберіть виробника',
      'Choose Receiver': 'Виберіть отримувача',
      'Choose Reserve Unit': 'Виберіть резервну одиницю',
      'Choose SAP orders': 'Виберіть SAP-замовлення',
      'Choose Stock Reserve Unit': 'Оберіть одиницю резерву запасів',
      'Choose Warehouse': 'Виберіть склад',
      'Choose a business unit': 'Виберіть бізнес-юніт',
      'Choose a preset of report config': 'Виберіть попередню конфігурацію звіту',
      'Choose a range': 'Виберіть діапазон',
      'Choose a report:': 'Виберіть звіт:',
      'Choose a template': 'Виберіть шаблон',
      'Choose approve status': 'Виберіть статус підтвердження',
      'Choose assignment': 'Виберіть завдання',
      'Choose base': 'Виберіть базу',
      'Choose batch': 'Виберіть партію',
      'Choose charge': 'Виберіть витрату',
      'Choose charterer': 'Виберіть фрахтувальника',
      'Choose client': 'Виберіть контрагента',
      'Choose commodity': 'Виберіть товар',
      'Choose compliance status': 'Виберіть статус комплаєнсу',
      'Choose configuration to launch the approval process by this object. You need first to set up configuration an Admin section, and here just pick it up':
        "Виберіть конфігурацію для запуску процесу затвердження цього об'єкта. Спочатку необхідно налаштувати конфігурацію в розділі «Адмін», а тут просто вибрати її",
      'Choose contract': 'Виберіть контракт',
      'Choose contract position': 'Виберіть позицію контракта',
      'Choose contract position to update': 'Виберіть позицію контакта для оновлення',
      'Choose cost volume source': 'Виберіть джерело обсягу витрат',
      'Choose counterparty': 'Виберіть контрагента',
      'Choose counterparty and currency': 'Виберіть контрагента та валюту',
      'Choose currency': 'Виберіть валюту',
      'Choose custom status': 'Виберіть спеціальний статус',
      'Choose date filter': 'Виберіть фільтр дати',
      'Choose fines days count method': 'Виберіть спосіб підрахунку штрафних днів',
      'Choose fines invoicing method': 'Виберіть спосіб нарахування штрафів',
      'Choose from existing customs declarations. To add new one click +':
        'Виберіть з існуючих митних декларацій. Щоб додати нову, натисніть +',
      'Choose from existing products. To add new one click +':
        'Вибирайте з існуючих продуктів. Щоб додати новий, натисніть +',
      'Choose from predefined list of types. To add new one click +':
        'Виберіть з попередньо визначеного списку типів. Щоб додати новий, натисніть +',
      'Choose invoice assignment': 'Виберіть призначення інвойсу',
      'Choose invoice date': 'Виберіть дату інвойсу',
      'Choose invoice type': 'Виберіть тип інвойсу',
      'Choose invoicing weight': 'Виберіть вагу для виставлення інвойсу',
      'Choose manager': 'Виберіть менеджера',
      'Choose margin': 'Виберіть маржу',
      'Choose one or more default incoterms on which this Supplier or Buyer is selling or buying':
        'Виберіть один або кілька базисів, за якими цей Постачальник або Покупець продає або купує',
      'Choose operation type': 'Виберіть тип операції',
      'Choose option': 'Виберіть опцію',
      'Choose options': 'Виберіть опції',
      'Choose port': 'Виберіть порт',
      'Choose prepay conditions': 'Виберіть умови передплати',
      'Choose producer': 'Виберіть виробника',
      'Choose product': 'Виберіть товар',
      'Choose program type': 'Виберіть тип програми',
      'Choose purchase contract': 'Виберіть закупівельний контракт',
      'Choose rate': 'Виберіть тариф',
      'Choose receiver': 'Виберіть отримувача',
      'Choose relations': 'Виберіть відносини',
      'Choose resource': 'Виберіть ресурс',
      'Choose responsible': 'Виберіть відповідального',
      'Choose role': 'Виберіть роль',
      'Choose services contract': 'Виберіть сервісний контракт',
      'Choose ship classification': 'Виберіть класифікацію корабля',
      'Choose signatory': 'Виберіть підписанта',
      'Choose size': 'Виберіть розмір',
      'Choose status': 'Виберіть статус',
      'Choose strategy': 'Виберіть стратегію',
      'Choose tax registration': 'Виберіть податкову реєстрацію',
      'Choose template': 'Виберіть шаблон',
      'Choose terminal': 'Виберіть термінал',
      'Choose the Excel template you want to send. It will be an excel spreadsheet\n                        attached to a letter. You can choose the order and naming of columns':
        'Виберіть шаблон Excel, який ви хочете надіслати. Він буде представляти собою електронну таблицю Excel, \n                        прикріплену до листа. Ви можете вибрати порядок та назву стовпців',
      'Choose the block, to which this template will be attached.This means, you might use it only within this block':
        'Виберіть блок, до якого буде додано цей шаблон. Це означає, що ви зможете використовувати його лише у межах цього блоку',
      'Choose the email template you want to send. It can be customized for every\n                        client':
        'Виберіть шаблон email, який ви хочете надіслати. Він може бути налаштований для кожного \n                        контрагента окремо',
      'Choose the file on computer': "Виберіть файл на комп'ютері",
      'Choose the range': 'Виберіть діапазон',
      'Choose type': 'Виберіть тип',
      'Choose type of invoice': 'Виберіть тип інвойсу',
      'Choose type of logistic': 'Виберіть тип логістики',
      'Choose usage': 'Виберіть використання',
      'Choose vehicle type': 'Виберіть тип транспортного засобу',
      'Choose vessel': 'Виберіть судно',
      'Choose warehouse': 'Виберіть склад',
      'Choose who decide the option': 'Виберіть, хто вибирає опцію',
      'Choosed quality list': 'Виберіть список якості',
      'Chose a role of a counterparty to be bound to a finance':
        "Виберіть роль контрагента, щоб зв'язати з інвойсом",
      'Chose new filter...': 'Виберіть новий фільтр...',
      Cities: 'Міста',
      City: 'Місто',
      Clean: 'Очистити',
      'Clean All': 'Очистити все',
      Cleaning: 'Очищення',
      Clear: 'Очистити',
      'Clear contract': 'Очистити контракт',
      'Clear filters': 'Очистити фільтри',
      'Clear passport': 'Очистити паспорт',
      'Click this checkbox if you want to include all package of documents by all\n                        logistics you want to send':
        'Позначте цей пункт, якщо бажаєте включити весь пакет документів\n                         у всю логістику, яку бажаєте надіслати',
      Client: 'Контрагент',
      'Client & cargo': 'Контрагент & вантаж',
      'Client / Terminal': 'Контрагент / Термінал',
      'Client Document Type': 'Тип документа контрагента',
      'Client Reconciliation by logistic': 'Звірка контрагента за логістикою',
      'Client Role': 'Роль контрагента',
      'Client author': 'Автор',
      'Client bank': 'Банк контрагента',
      'Client chats': 'Клієнтські чати',
      'Client data check': 'Перевірка даних контрагента',
      'Client data is checked': 'Дані контрагента перевіряються',
      'Client from': 'Контрагент від',
      'Client history': 'Історія контрагента',
      'Client role': 'Роль контрагента',
      'Client template': 'Шаблон контрагента',
      'Client to': 'Контрагент до',
      "Client's Template": 'Шаблон контрагента',
      'Clientrole from': 'Контрагент (роль) від',
      'Clientrole to': 'Контрагент (роль) до',
      Clients: 'Контрагенти',
      'Clients Reconciliations': 'Звірка контрагентів',
      Clone: 'Клонувати',
      'Clone costs': 'Клонувати витрати',
      Close: 'Закрити',
      'Close contract': 'Закрити контракт',
      'Close modal?': 'Закрити модальне вікно?',
      'Close price': 'Ціна закриття',
      Closed: 'Закритий',
      Closing: 'Закриття',
      'Closing contract': 'Закриття контракту',
      'Closing of existing position': 'Закриття існуючої позиції',
      Code: 'Код',
      Coefficient: 'Коефіцієнт',
      Cold: 'Холодний',
      Collateral: 'Забезпечення',
      'Collateral amount': 'Сума забезпечення',
      Collection: 'Конфігурація',
      'Collection deleted successfully': 'Колекцію успішно видалено',
      'Collection title': 'Назва конфігурації',
      'Column name': 'Назва стовпчика',
      'Columns sets:': 'Набори стовпців:',
      'Comm Fees': 'Комісійні збори',
      'Comm fees': 'Комісійні збори',
      'Comm fees,$': 'Комісії, usd',
      'Comm. fees': 'Комісії',
      Comment: 'Коментар',
      Commentary: 'Коментар',
      'Commentary successfully deleted.': 'Коментар успішно видалено.',
      'Commentary successfully saved.': 'Коментар успішно збережено.',
      Comments: 'Коментарі',
      Commercial: 'Комерційний',
      'Commercial Invoice': 'Комерційний інвойс',
      Commission: 'Комісія',
      'Commission amount': 'Сума комісії',
      'Commission credit account': 'Рахунок зарахування комісій',
      'Commission debit account': 'Рахунок списання комісій',
      Commissions: 'Комісії',
      Commodities: 'Товари',
      Commodity: 'Товар',
      'Commodity Balance': 'Баланс по товару',
      'Commodity default': 'Товар за замовчуванням',
      'Commodity level': 'Рівень товарообігу',
      'Commodity opposite': 'Товар зустрічний',
      Companies: 'Компанії',
      Company: 'Компанія',
      'Company Group': 'Група компаній',
      'Company from': 'Компанія від',
      'Company group': 'Група компаній',
      'Company groups': 'Групи компаній',
      'Company name': 'Назва компанії',
      'Company parameters': 'Параметри компанії',
      'Company to': 'Компанія до',
      'Company with whom we have these charge or gain, not a contract counterparty':
        'Компанія, з якою у нас є ці витрати або дохід, не є контрагентом за контрактом',
      'Complete compliance': 'Комплаєнс завершено',
      'Complete positions table data': 'Заповніть дані таблиці позицій',
      Completed: 'Виконано',
      'Complex percent': 'Складний відсоток',
      Compliance: 'Комплаєнс',
      'Compliance KPI': 'Комплаєнс KPI',
      'Compliance status': 'Статус комплаєнсу',
      Conclusion: 'Укладання',
      'Conclusion (date)': 'Укладання (дата)',
      'Conclusion date': 'Дата укладання',
      'Conclusion date currency': 'Валюта на момент укладання',
      'Conclusion date exchange rate': 'Курс валюти на дату заключення',
      'Conclusion date flat price': 'Фіксована ціна на дату укладання',
      'Conclusion date position': 'Дата укладання позиції',
      Condition: 'Умова',
      Conditions: 'Умови',
      'Config title': 'Назва конфігурації',
      Configs: 'Конфігурація',
      Configure: 'Конфігурація',
      'Configure fields': 'Налаштувати поля',
      'Configure report': 'Конфігурація звіту',
      Confirm: 'Підтвердити',
      'Confirm New Password': 'Підтвердити новий пароль',
      Confirmation: 'Підтвердження',
      'Confirmation (date)': 'Підтвердження (дата)',
      'Confirmation date': 'Дата підтвердження',
      'Confirmation info': 'Інформація про підтвердження',
      Confirmations: 'Підтвердження',
      Confirmed: 'Підтверджено',
      Connect: "З'єднати",
      'Connect Documents': "З'єднати документи",
      'Connect contract to existing passport': "З'єднати контракт з існуючим паспортом",
      'Connect logistics': "З'єднати логістику",
      'Connect position to existing passport': 'Підключіть позицію до наявного паспорта',
      'Connect to': "З'єднати з",
      'Connect to contract': "З'єднати з контрактом",
      'Connect to existing passport': "З'єднати до існуючого паспорта",
      'Connect to passport': 'З’єднати до паспорта',
      'Connect to purchase': "З'єднати до закупівлі",
      'Connect to sale': "З'єднати до продажу",
      'Connect to {[{ $root.user.settings.PASSPORT_TITLE }]}':
        "З'єднати з {[{ $root.user.settings.PASSPORT_TITLE }]}",
      'Connect with purchase': "З'єднати із закупівлею",
      'Connect with purchase contract': "З'єднати до закупівельного контракту",
      'Connect with sale': "З'єднати із продажем",
      'Connect with sale contract': "З'єднати до продажного контракту",
      'Connect wrong crops?': "З'єднати неправильні культури?",
      Connected: "З'єднаний",
      'Connected Potentials history': 'Історія підключених потенціалів',
      'Connected passports': "Зв'язані паспорти",
      'Connected volume': "З'єднаний об'єм",
      'Connecting...': "З'єднуємо...",
      Connection: "Зв'язок",
      Connections: "Зв'язки",
      'Connections updated.': "Зв'язки оновлені.",
      'Consecutive voyages': 'Послідовні рейси',
      Consignee: 'Отримувач',
      'Consignee list': 'Список вантажоодержувачів',
      Consignees: 'Вантажоодержувачі',
      Consignment: 'Накладна',
      'Consignment (#)': 'Накладна (№)',
      'Consignment and volume are required.': 'Необхідні накладна та обсяг',
      'Consignment contract commodities': 'Товар накладних контракту',
      'Consignment date': 'Дата накладної',
      'Consignment number': 'Номер ТТН',
      'Consignment removed': 'Накладну видалено',
      'Consignment saved': 'Накладну збережено',
      Consignments: 'Складські квитанції',
      Consolidated: 'Консолідований',
      'Consolidated Contract PnL': 'Консолідований PnL контракту',
      'Consolidated Invoiced Amount': 'Консолідована сума в інвойсі',
      'Consolidated PnL': 'Консолідований PnL',
      'Consolidated costs': 'Консолідовані витрати',
      'Consolidated costs invoice': 'Консолідовані витратні інвойси',
      'Consolidated crop position': 'Консолідована позиція культури',
      'Consolidated position': 'Консолідована позиція',
      Contact: 'Контакт',
      'Contact date expired': 'Дата контакту неактуальна',
      'Contact first time': 'Перший контакт',
      'Contact info': 'Контактна інформація',
      'Contact next time': "Зв'яжіться наступного разу",
      'Contact status': 'Статус контакту',
      'Contact today': "Зв'язатися сьогодні",
      Contacted: "Зв'язався",
      Contacts: 'Контакти',
      Container: 'Контейнер',
      Continue: 'Продовжити',
      Contract: 'Контракт',
      'Contract #': 'Контракт №',
      'Contract - Analytical': 'Контракт - Аналітичний',
      'Contract - Execution': 'Контракт - Виконання',
      'Contract BU': 'БЮ контракта',
      'Contract Charges / Gains': 'Контрактні Витрати/Доходи',
      'Contract Charges 1t': 'Витрати контракту за 1 т',
      'Contract DSTU': 'Контракт ДСТУ',
      'Contract Number': 'Номер контракту',
      'Contract Option': 'Опція контракту',
      'Contract Owner': 'Власник контракту',
      'Contract P&L': 'PnL контракту',
      'Contract P&L MTM': 'Контракт PnL MTM',
      'Contract Position': 'Позиція контракта',
      'Contract Price': 'Ціна контракту',
      'Contract ROS': 'Контракт ROS',
      'Contract Status': 'Статус контракту',
      'Contract Sum': 'Сума контракту',
      'Contract amount': 'Сума контракту',
      'Contract can have only one warehouse': 'Контракт може мати тільки один склад',
      'Contract charge': 'Витрата контракту',
      'Contract charge saved': 'Витрата за контрактом збережено',
      'Contract charge updated': 'Витрата контракту оновлена',
      'Contract charges': 'Контрактні витрати',
      'Contract charges saved for each contract in the multicontract':
        'Контрактні витрати, збережені для кожного контракту в мультиконтракті',
      'Contract conclusion date': 'Дата укладання контракту',
      'Contract connected.': "Контракт з'єднано.",
      'Contract counterparty': 'Контрагент контракта',
      'Contract creation': 'Створення контракту',
      'Contract crop year': 'Контракт рік урожаю',
      'Contract crops': 'Культура контракту',
      'Contract custom status': 'Спеціальний статус контракту',
      'Contract deal type': 'Тип угоди',
      'Contract destinations': 'Контрактні пункти призначення',
      'Contract details': 'Деталі контракту',
      'Contract document': 'Документи контракту',
      "Contract doesn't exist": 'Контракт не існує',
      'Contract executed': 'Контракт виконано',
      'Contract executed. Containes three confirmation. Cargo - everything is ok with a delivery. Payment - everything is ok with payments. Final - everything is ok in general, and the contract might be considered as executed':
        'Контракт виконано. Він містить три підтвердження. Вантаж - все гаразд з доставкою. Оплата - все гаразд з платежами. Фінал - все в цілому гаразд , і контракт можна вважати виконаним',
      'Contract flat price': 'Фіксована ціна контракту',
      'Contract info': 'Інформація про контракт',
      'Contract invoice type': 'Тип інвойсу контракту',
      'Contract margin': 'Маржа контракту',
      'Contract margin differs': 'Маржа за контрактами відрізняється',
      'Contract notes': 'Примітки до контракту',
      'Contract number': 'Номер контракту',
      'Contract numbers': 'Номери контрактів',
      'Contract option': 'Опція контракту',
      'Contract passports': 'Паспорти контракту',
      'Contract ports': 'Порт контракту',
      'Contract position': 'Позиція контракту',
      'Contract positions': 'Позиції контракту',
      'Contract price': 'Ціна контракту',
      'Contract price average, 1 MT/$': 'Контрактна ціна середня, 1 т/$',
      'Contract quantity': 'Кількість контракту',
      'Contract removed': 'Контракт видалено',
      'Contract result': 'Результат контракту',
      'Contract saved': 'Контракт збережено',
      'Contract status': 'Статус контракту',
      'Contract stock (t)': 'Залишки контракту (т)',
      'Contract stock amount': 'Сума залишків контракту',
      'Contract type': 'Тип контракту',
      'Contract updated': 'Контракт оновлено',
      'Contract value': 'Сума контракту',
      'Contract value in UAH': 'Сума контракту в UAH',
      'Contract value no VAT': 'Сума контракту без ПДВ',
      'Contract volume': "Об'єм контракту",
      'Contract volume (t)': "Об'єм контракту (т)",
      'Contract volume - Total volume connected to all passports from this contract':
        "Контрактний обсяг - Загальний обсяг, пов'язаний з усіма паспортами цього контракту",
      'Contract volume - Total volume of opposite contracts connected to same\n          passports':
        'Обсяг контракту - Загальний обсяг протилежних контрактів, пов’язаних з тими ж \n          паспортами',
      "Contract's Costs Opposite": 'Зустрічні витрати контракту',
      "Contract's Flat Price": 'Фіксована ціна контракту',
      "Contract's Flat Price Opposite": 'Зустрічна фіксована ціна контракту',
      "Contract's Open Volume": "Відкритий об'єм контракту",
      "Contract's Premium Price": 'Преміум ціна контракту',
      "Contract's Premium Price Opposite": 'Зустрічна преміум ціна контракту',
      Contracted: 'Законтрактовано',
      'Contracted volume:': "Контрактований об'єм",
      Contracts: 'Контракти',
      'Contracts Analytical': 'Аналітика контрактів',
      'Contracts Analytics': 'Аналітика контрактів',
      'Contracts Execution': 'Виконання контрактів',
      'Contracts analytical': 'Аналітика контрактів',
      'Contracts control created': 'Створено контроль за контрактами',
      'Contracts created from this stock': 'Контракти, створені з цього залишку',
      'Contracts final volume': 'Фінальний обсяг контрактів',
      'Contracts import': 'Імпорт контрактів',
      'Contracts quantity': "Об'єм контрактів",
      'Contracts should have same currency.': 'Контракти повинні мати однакову валюту.',
      'Contracts status updated': 'Статус контрактів оновлений',
      'Contracts total value': 'Загальна сума контрактів',
      'Contracts totals': 'Всього контрактів',
      'Contracts updated': 'Контракти оновлено',
      'Contracts value': 'Суми контрактів',
      'Contracts volume': "Об'єм контрактів",
      Control: 'Контроль',
      'Control margin': 'Контроль маржі',
      Controlled: 'Контрольований',
      'Controlled margin': 'Проконтрольована маржа',
      Controller: 'Контролер',
      Controls: 'Контроль',
      'Controls on a page': 'Управління на сторінці',
      'Convert to contract': 'Перетворити на контракт',
      'Convert to ticket': 'Перетворити на тікет',
      Coordinates: 'Координати',
      Copy: 'Копія',
      'Copy to clipboard': 'Скопіювати',
      'Corr account': 'Рахунок банку-кореспондента',
      'Corr account 1': 'Кореспондентський рахунок 1',
      'Corr account 2': 'Кореспондентський рахунок 2',
      'Corr bank': 'Банк-кореспондент',
      'Corr bank 1': 'Банк-кореспондент 1',
      'Corr bank 2': 'Банк-кореспондент 2',
      'Corr iban': 'IBAN банку-кореспондента',
      'Corr iban 1': 'IBAN 1 банку-кореспондента',
      'Corr iban 2': 'IBAN 2 банку-кореспондента',
      'Corr swift': 'Swift банку-кореспондента',
      'Corr swift 1': 'SWIFT 1 банку-кореспондента',
      'Corr swift 2': 'SWIFT 2 банку-кореспондента',
      Correspondent: 'Кореспондент',
      Cost: 'Витрати',
      'Cost / Gain': 'Витрати / доходи',
      'Cost / gain': 'Витрати/ доходи',
      'Cost counterparty': 'Витрати контрагента',
      'Cost details': 'Деталі витрати',
      'Cost of goods sold': 'Собівартість реалізованих товарів',
      'Cost of production mUSD, with VAT': 'Вартість виробництва USD, з ПДВ',
      'Cost-Contract counterparty difference': 'Витрати-Контракт різниця за контрагентами',
      'Cost-Invoice counterparty difference': 'Витрати-Інвойс різниця за контрагентами',
      'Cost/gain charge': 'Витрати/ доходи прибуток',
      'Cost/gain reserve charge': 'Витрати/прибуток за резервами',
      Costs: 'Витрати',
      'Costs amount': 'Сумма витрати',
      'Costs distributions': 'Розподіл витрат',
      'Costs invoices': 'Інвойси на витрати',
      'Costs of goods': 'Собівартість',
      'Costs opposite': 'Витрати зустрічного контракту',
      'Costs report': 'Звіт про витрати',
      'Costs statistics report': 'Звіт про статистику витрат',
      'Costs sync strategy': 'Стратегія синхронізації витрат',
      'Costs/gains': 'Витрати/доходи',
      Count: 'Лічильник',
      Counterparties: 'Контрагенти',
      'Counterparties in contracts is not unique': 'Контрагенти в контрактах не унікальні',
      Counterparty: 'Контрагент',
      'Counterparty Analytics': 'Аналітика контрагентів',
      'Counterparty amount': 'Сума контрагента',
      'Counterparty approval status': 'Статус затвердження контрагента',
      'Counterparty bank': 'Банк контрагента',
      'Counterparty bank account': 'Банківський рахунок контрагента',
      'Counterparty business reference': 'Бізнес-довідка контрагента',
      'Counterparty confirmation. Completed if this Counterparty is confirmed by your organization':
        'Підтвердження контрагента. Завершено, якщо цей контрагент підтвердиться вашою організацією',
      'Counterparty confirmed': 'Контрагент підтвердив',
      'Counterparty created': 'Створено контрагента',
      'Counterparty creation': 'Створення контрагента',
      'Counterparty from': 'Контрагент від',
      'Counterparty payment IBAN': 'Платіж контрагента IBAN',
      'Counterparty payment info': 'Платіжні відомості контрагента',
      'Counterparty saved': 'Контрагента збережено',
      'Counterparty status:': 'Статус контагента',
      'Counterparty to': 'Контрагент до',
      'Counterparty type': 'Тип контрагента',
      Countries: 'Країни',
      Country: 'Країна',
      'Country, city, street, app': 'Країна, місто, вулиця, офіс',
      'Counts by month': 'Кількість по місяцям',
      Create: 'Створити',
      'Create Balance Invoice': 'Створити баланс інвойсу',
      'Create Contract from this Ticket. Ticket will become Executed':
        'Створити Контракт з цього Тікета. Тікет буде вважатися Виконаним',
      'Create DisbursementBL': 'Створити видаткову накладну',
      'Create Document': 'Створити Документ',
      'Create Email Template': 'Створити шаблон Email',
      'Create Prepayment Invoice': 'Створити інвойс передплати',
      'Create Screen Capture Task': 'Створити завдання для знімка екрану',
      'Create Service Request': 'Створити Сервісний тікет',
      'Create Supplier': 'Створити постачальника',
      'Create Ticket': 'Створити тікет',
      'Create Ticket from this Ticket': 'Створити тікет з цього тікета',
      'Create a collection': 'Створити конфігурацію',
      'Create a document': 'Створити документ',
      'Create a letter': 'Створити лист',
      'Create a new passport': 'Створити новий пароль',
      'Create a template': 'Створити шаблон',
      'Create an email': 'Створити email',
      'Create approvals for declined': 'Створити підтвердження для скасованих',
      'Create contract': 'Створити контракт',
      'Create contracts from tickets': 'Створити контракти з тікетів',
      'Create control': 'Створити контроль',
      'Create date': 'Дата створення',
      'Create document': 'Створити документ',
      'Create invoice': 'Створити інвойс',
      'Create invoices': 'Створити інвойси',
      'Create new': 'Створити нове',
      'Create new ticket': 'Створити новий тікет',
      'Create operation': 'Створити операцію',
      'Create passport': 'Створити паспорт',
      'Create passport with this contract': 'Створити паспорт з цим контрактом',
      'Create passport with this position': 'Створити паспорт з цією позицією',
      'Create payment plan': 'Створити план оплат',
      'Create rolling': 'Створити ролінг',
      'Create ticket': 'Створити тікет',
      'Create time': 'Час створення',
      'Create transport': 'Створити транспорт',
      'Create user': 'Створити користувача',
      'Create vessel': 'Створити судно',
      'Create voyage': 'Створити рейс',
      'Create your favorite Filter in Right Sidebar and it will appear here':
        'Створіть свій улюблений фільтр на правій бічній панелі, і він з’явиться тут',
      Created: 'Створено',
      'Created / Edited': 'Створено / Відредаговано',
      'Created at': 'Створено в',
      'Created date': 'Дата створення',
      'Created time': 'Час створення',
      'Created via agreement': 'Створено за допомогою угоди',
      'Created via multicontract': 'Створено за допомогою мультиконтракту',
      'Created via multiticket': 'Створено через мультитікет',
      Createor: 'Створив',
      'Creating invoices': 'Створення інвойсів',
      Creation: 'Створення',
      Credit: 'Кредит',
      'Credit Account': 'Кредит рахунок',
      'Credit Bank Account': 'Кредит банківського рахунку',
      'Credit Counterparty': 'Кредит контрагента',
      'Credit Finance Account': 'Кредит фінансового рахунку',
      'Credit Finance Account (commission)': 'Кредит фінансового рахунку (комісія)',
      'Credit Finance account': 'Кредит фінансового рахунку',
      'Credit Note': 'Кредит-нота',
      'Credit Notes': 'Кредит-ноти',
      'Credit Report': 'Звіт ризиків',
      'Credit account': 'Кредит рахунок',
      'Credit bank account': 'Кредитний банківський рахунок',
      'Credit counterparty': 'Кредит контрагента',
      'Credit default': 'Кредитний дефолт',
      'Credit note': 'Кредит-нота',
      'Credit note number': 'Кредит-нота номер',
      'Credit note payments': 'Кредит-нота платежів',
      'Credit note removed': 'Кредит-ноту видалено',
      'Credit note saved': 'Кредит-ноту збережено',
      'Credit note updated': 'Кредит-ноту оновлено',
      'Credit notes': 'Кредит ноти',
      'Credit report': 'Звіт ризиків',
      'Creditor Balance': 'Баланс кредиторів',
      Criminal: 'Кримінальна діяльність',
      Critical: 'Критичний',
      Crop: 'Культура',
      'Crop DSTU': 'Культура ДСТУ',
      'Crop Groups': 'Групи культур',
      'Crop Year': 'Рік Врожаю',
      'Crop area': 'Посівна площа',
      'Crop level': 'Рівень культури',
      'Crop price created': 'Ціна культури створена',
      'Crop price deleted': 'Ціну культури видалено',
      'Crop prices deleted': 'Ціни культури видалено',
      'Crop protection': 'Захист посівів',
      'Crop year': 'Рік врожаю',
      Cryptocurrency: 'Криптовалюта',
      Ct: 'Ct',
      Currencies: 'Валюти',
      Currency: 'Валюта',
      'Currency Exchange': 'Обмін валюти',
      'Currency Rate': 'Курс валюти',
      'Currency alternative': 'Альтернативна валюта',
      'Currency and rate': 'Валюта та курс',
      'Currency exchange': 'Обмін валюти',
      'Currency is required': 'Потрібна валюта',
      'Currency opposite': 'Валюта зустрічна',
      'Currency rate': 'Курс валюти',
      'Current Password': 'Поточний пароль',
      'Current address': 'Поточний адрес',
      'Current derivative': 'Поточний дериватив',
      'Current flat price': 'Поточна ціна',
      'Current goal': 'Поточна ціль',
      'Current opposite flat price': 'Поточна зустрічна ціна',
      'Custom cargo declaration removed': 'Митна вантажна декларація видалена',
      'Custom declarations': 'Митні декларації',
      'Custom declarations saved': 'Митні декларації збережено',
      'Custom field`s values': 'Значення кастомних полів',
      'Custom fields': 'Кастомні поля',
      'Custom filter': 'Фільтр користувача',
      'Custom margin': 'Налаштування маржі',
      'Custom roles': 'Спеціальні ролі',
      'Custom status': 'Спеціальний статус',
      'Custom statuses': 'Статуси користувачів',
      'Custom values': 'Кастомні значення',
      'Customize a fields': 'Налаштування полів',
      Customs: 'Митниця',
      'Customs Broker': 'Митний брокер',
      'Customs Declaration': 'Митна декларація',
      'Customs Terminals': 'Митні термінали',
      'Customs broker': 'Митний брокер',
      'Customs cleared amount ()': 'Розмитнена сума ()',
      'Customs cleared volume (t)': 'Розмитнений обсяг (т)',
      'Customs cleared volumes by customs terminal':
        'Обсяги, очищені митницею, за митним терміналом',
      'Customs declaration': 'Митна декларація',
      'Customs declaration volume, mt': 'Обсяг митної декларації, т',
      'Customs declarations': 'Митні декларації',
      'Customs not cleared by customs terminal':
        'Обсяги, не очищені митницею, за митним терміналом',
      'Customs prepared amount ()': 'Підготовлена митницею сума ()',
      'Customs prepared volume (t)': "Підготовлений митним об'ємом (т)",
      'Customs uncleared amount ()': 'Нерозмитнена сума ()',
      'Customs uncleared volume (t)': 'Нерозмитнений обсяг (т)',
      'Customs unprepared amount ()': 'Неопрацьована сума митниці ()',
      'Customs unprepared volume (t)': 'Неопрацьована сума митниці ()',
      DASHBOARD: 'ДАШБОРД',
      'DAT Self cost': 'DAT собівартість',
      'DATE RANGE': 'ДІАПАЗОН ДАТ',
      DATES: 'ДАТИ',
      'DATES & NOTES': 'ДАТИ & ПРИМІТКИ',
      'DBL SAP registered': 'DBL SAP зареєстровано',
      'DBL amount': 'Видаткова накладна сума',
      'DBL are tax registered': 'Видаткові накладні - зареєстровані для оподаткування',
      'DBL volume': "Видаткова накладна об'єм",
      DEBIT: 'ДЕБІТ',
      DECLINE: 'ВІДХИЛИТИ',
      DELIVERY: 'ДОСТАВКА',
      DERIVATIVES: 'ДЕРИВАТИВИ',
      'DESTINATION 1ST PORT': 'МІСЦЕ ПРИЗНАЧЕННЯ - 1-Й ПОРТ',
      'DESTINATION FINAL': 'КІНЦЕВИЙ ПУНКТ ПРИЗНАЧЕННЯ',
      DETAILS: 'ДЕТАЛІ',
      DIFF: 'РІЗНИЦЯ',
      'DIFF margin (ROS)': 'Різниця маржі (ROS)',
      DIFFs: 'Різниця',
      DIMENSIONS: 'РОЗМІРИ',
      'DISBURSMENT BILL OF LADING': 'ВИДАТКОВА НАКЛАДНА',
      DO: 'DO',
      DOCS: 'ДОКУМЕНТИ',
      DOCUMENTS: 'ДОКУМЕНТИ',
      'DUE DATE': 'ТЕРМІН ВИКОНАННЯ',
      Daily: 'Щодня',
      'Daily fines rate': 'Щоденна ставка штрафу',
      Dark: 'Темна',
      Dashboard: 'Дашборд',
      Data: 'Дані',
      'Data updated': 'Дані оновлено',
      Date: 'Дата',
      'Date date': 'Дата',
      'Date from': 'Дата з',
      'Date of Disbursement bill of lading': 'Дата видаткової накладної',
      'Date of berth': 'Дата причалу',
      'Date of berth destination': 'Дата причалу (розвантаження)',
      'Date of berth loading': 'Дата завантаження на причал',
      'Date of certification': 'Дата сертифікації',
      'Date of conduction': 'Дата проведення',
      'Date of discharging end': 'Дата завершення розвантаження',
      'Date of discharging start': 'Дата початку розвантаження',
      'Date of documents withdrawal': 'Дата вилучення документів',
      'Date of execution': 'Дата виконання',
      'Date of execution fact': 'Дата виконання факт',
      'Date of future contact': 'Дата наступного контакту',
      'Date of issuance': 'Дата видачі',
      'Date of last contact': 'Дата останнього контакту',
      'Date of loading end': 'Дата завершення завантаження',
      'Date of loading start': 'Дата початку завантаження',
      'Date of notice': 'Дата повідомлення',
      'Date of notice destination': 'Повідомлення про дату місця призначення',
      'Date of notice loading': 'Повідомлення про дату завантаження',
      'Date of payment': 'Дата оплати',
      'Date of payment (fact)': 'Дата оплати (факт)',
      'Date of payment (plan)': 'Дата оплати (план)',
      'Date of receiving': 'Дати отримання',
      'Date of withdrawal': 'Дата вилучення',
      'Date range': 'Діапазон дат',
      'Date range of execution': 'Діапазон дат виконання',
      'Date to': 'Дати до',
      'Date, when the company was confirmed by us first time':
        'Дата, коли компанія була підтверджена вперше',
      'Date, when the company was officially confirmed by us':
        'Дата, коли компанія була офіційно підтверджена нами',
      'Date, when the documents were received from the company':
        'Дата, коли документи були отримані від компанії',
      'Date, when the documents were requested from the company':
        'Дата, коли документи були замовлені у компанії',
      'Dates are reqired': "Дати обов'язкові",
      Day: 'День',
      Days: 'Дні',
      'Days at port': 'Дні в порту',
      'Days before ending status': 'Днів до завершення статусу',
      "Days to set 'ending status'(end of execution)":
        'Дні до встановлення «фінального статусу» (кінець виконання)',
      'Days until final day': 'Дні до фінального дня',
      Dbl: 'ВН',
      'Dbl date': 'Дата ВН',
      'Dbl number': 'Номер видаткової накладної',
      'Dead freight': 'Мертвий фрахт',
      'Deal type': 'Тип угоди',
      Deals: 'Угоди',
      Debit: 'Дебіт',
      'Debit Account': 'Дебет рахунок',
      'Debit Bank Account': 'Дебет банківського рахунку',
      'Debit Counterparty': 'Дебет контрагента',
      'Debit Credit': 'Дебет - кредит',
      'Debit Finance Account': 'Дебет фінансового рахунку',
      'Debit Finance Account (commission)': 'Дебет фінансового рахунку (комісія)',
      'Debit Finance account': 'Дебет фінансового рахунку',
      'Debit Note': 'Дебет-нота',
      'Debit account': 'Дебет рахунок',
      'Debit bank account': 'Дебет банківський рахунок',
      'Debit counterparty': 'Дебет контрагента',
      'Debit default': 'Дебетовий дефолт',
      'Debitor Balance': 'Баланс дебітора',
      Debts: 'Заборгованість',
      December: 'Грудень',
      Declaration: 'Декларація',
      'Declaration date': 'Дата декларації',
      'Declaration number': 'Номер декларації',
      'Declaration required': 'Потрібна декларація',
      'Declaration type': 'Тип декларації',
      'Declared volume': 'Обʼєм заявлений',
      Decline: 'Відхилити',
      'Decline approvals': 'Відхилити підтвердження',
      Declined: 'Відхилено',
      Default: 'За замовчуванням',
      'Default Contract Tab': 'Вкладка контракту за замовчуванням',
      'Default Margin View': 'Типовий вигляд маржі',
      'Default contract for connect': "Типовий контракт для зв'язку",
      'Default prepayment with this counterparty. You can specify another in each contract':
        'Передоплата за замовчуванням з цим контрагентом. Ви можете вказати іншу передоплату у кожному контракті',
      Delay: 'Затримка',
      'Delayed payment': 'Затриманий платіж',
      Delays: 'Затримки',
      Delete: 'Видалити',
      'Delete payment?': 'Видалити платіж?',
      'Delete price': 'Видалити ціну',
      'Delete quality?': 'Видалити якість?',
      Deleted: 'Видалено',
      "Deleted  logistics from  selected'": 'Логістику з вибраного видалено',
      'Deleting the': 'Видаляючи',
      Delivered: 'Доставлений',
      Deliverer: 'Експедитор',
      'Deliverer in port': 'Доставник в порту',
      Delivery: 'Виконання',
      'Delivery (end date)': 'Доставка (кінцева дата)',
      'Delivery (start date)': 'Доставка (дата початку)',
      'Delivery Basis': 'Базис',
      'Delivery addresses': 'Адреси доставки',
      'Delivery condition': 'Умови доставки',
      'Delivery conditions': 'Умови доставки',
      'Delivery dates': 'Дати доставки',
      'Delivery estimated month': 'Очікуваний місяць доставки',
      'Delivery finished': 'Доставка завершена',
      'Delivery period': 'Термін поставки',
      'Delivery period sale contract': 'Період виконання продажного контракту',
      'Delivery status': 'Статус доставки',
      'Dem rate': 'Ставка демереджу',
      'Demmurage rate': 'Ставка демереджа',
      Demo: 'Демо',
      'Demo removed': 'Демо вилучено',
      Demurrage: 'Демереджа',
      'Demurrage rate': 'Ставка демереджа',
      Density: 'Щільність',
      'Depart station': 'Станція відправлення',
      'Departed consignment': 'Відправлена накладна',
      'Departed minus': 'Відправлено мінус',
      'Departed plus': 'Відправлено, обʼєм',
      'Departed real': 'Відправлено (реально)',
      'Departed value': 'Відправлено сума',
      'Departed volume': "Відправлений об'єм",
      'Departed w/o consignment': 'Відправлено без накладної',
      'Departure station': 'Станція відправлення',
      'Depends on a stage of passport execution. Select which margin we will display in PNL':
        'Залежить від етапу оформлення паспорта. Виберіть, яку маржу ми будемо відображати в PnL',
      Derivative: 'Дериватив',
      'Derivative Accounts': 'Дериватив рахунки',
      'Derivative account': 'Рахунок деривативу',
      'Derivative margin': 'Маржа деривативу',
      'Derivative mode': 'Режим деривативу',
      'Derivative ticker': 'Тікер деривативу',
      'Derivative title': 'Назва деривативу',
      'Derivative type': 'Тип деривативу',
      Derivatives: 'Деривативи',
      'Derivatives & Rolling': 'Деривативи та Ролінг',
      'Derivatives Margin': 'Маржа Деривативів',
      'Derivatives Position': 'Позиція деривативу',
      'Derivatives list': 'Список деривативів',
      'Derivatives margin': 'Маржа деривативів',
      Description: 'Опис',
      'Despatch rate': 'Despatch ставка',
      Destination: 'Призначення',
      'Destination 1st port': 'МІСЦЕ ПРИЗНАЧЕННЯ - 2-Й ПОРТ',
      'Destination Port': 'Порт призначення',
      'Destination country': 'Країна призначення',
      'Destination dem/dis': 'Призначення dem/dis',
      'Destination final port': 'Кінцевий порт призначення',
      'Destination port': 'Порт призначення',
      'Destination station': 'Станція призначення',
      Destinations: 'Призначення',
      'Destinations of crop': 'Призначення культури',
      Detailed: 'Деталізовано',
      'Detailed by responsible': 'Розглянуто відповідальним',
      Details: 'Деталі',
      'Did not happen': 'Не відбулася подія',
      Diff: 'Різниця',
      'Diff Plan | Fact': 'Різниця План | Факт',
      'Diff analytical': 'Аналітична різниця',
      'Diff btwn contract price and MTM, $': 'Різниця між ціною контракту та MTM, $',
      'Difference = 0': 'Різниця = 0',
      'Difference > 0': 'Різниця > 0',
      'Difference >= 0.1': 'Різниця >= 0.1',
      Diffs: 'Різниці',
      'Digital signature': 'Цифровий підпис',
      Direct: 'Прямий',
      'Direct Contracts': 'Прямі контракти',
      'Direct Tickets': 'Прямі тікети',
      'Disable previous template and clear all filter before create new.':
        'Відключити попередній шаблон і очистити весь фільтр перед створенням нового.',
      Disabled: 'Вимкнено',
      'Disbursement bill of lading': 'Видаткова накладна',
      'Disbursement bill of lading Amount incl. VAT, UAH':
        'Сума видаткової накладної (в т.ч. ПДВ) ,UAH',
      'Disbursement bill of lading Price incl. VAT, UAH':
        'Ціна видаткової накладної (в т.ч. ПДВ) ,UAH',
      'Disbursement bill of lading Quantity, mt': "Об'єм видаткової накладної, т",
      'Disbursement bill of lading removed': 'Видаткову накладну видалено',
      'Disbursement bill of lading saved': 'Видаткову накладну збережено',
      'Disbursement bl': 'Видаткова накладна',
      'Disbursement bl and reassignment execution': 'Виконання видаткової накладної та переуступки',
      'Disbursement bl value': 'Сума видаткової накладної',
      'Disbursement bl volume': "Об'єм видаткової накладної",
      Disbursementbl: 'Видаткова накладна',
      'Disbursment B/L': 'Видаткова накладна',
      "Disbursment B/L's": 'Видаткові накладні',
      'Disbursment bills of lading': 'Видаткові накладні',
      'Disch rate': 'Норма розвантаження',
      Discharge: 'Розвантаження',
      'Discharge approval volume, mt': 'Обсяг затвердженого вивантаження, т',
      Discharged: 'Розвантажений',
      Discharging: 'Розвантажується',
      Discount: 'Дисконт',
      'Discount %': 'Дисконт %',
      'Discount / 1 mt': 'Дисконт / 1 т',
      'Discount amount': 'Розмір дисконту',
      'Discount calculation, %': 'Розрахунки дисконту, %',
      'Discount per': 'Дисконт за 1 т',
      'Discount per 1 mt': 'Дисконт за 1 т',
      'Discount, %': 'Дисконт, %',
      Discussion: 'Обговорення',
      'Disp rate': 'Ставка диспача',
      Dispatch: 'Відправлення',
      'Dispatch rate': 'Ставка диспача',
      'Disponent owner': 'Диспонент-власник',
      Disport: 'Порт призначення',
      'Disselect all': 'Зняти відмітку з усіх',
      'Distribute by volume': "Розподілити за об'ємом",
      'Distribute evenly': 'Розподілити рівномірно',
      'Distribution by users': 'Розподіл за користувачами',
      Distributor: "Дистриб'ютор",
      'Distributor code': "Код дистриб'ютора",
      Distributors: 'Дистрибʼютори',
      'Distributors fact': "Факт дистриб'юторів",
      'Distributors plan': "План дистриб'юторів",
      District: 'Район',
      Districts: 'Райони',
      'Divide invoice amount for commodity': 'Розділіть суму інвойсу за товаром',
      'Divide invoice amount for contracts': 'Розподіл суми інвойсу за контрактами',
      'Divide invoice amount for passports': 'Розподілити суму інвойсу на паспорти',
      'Do not display in passport': 'Не показувати в паспорті',
      'Do not pay': 'Не платити',
      'Do not update contracts': 'Не оновлювати контракти',
      'Do not use in Freight position': 'Не використовувати в позиціях фрахту',
      'Do not validate': 'Не перевіряти',
      'Do the compliance': 'Виконати комплаєнс',
      'Do you really want to delete this object ?': 'Ви справді бажаєте видалити об’ єкт?',
      'Do you want delete this Request?': 'Бажаєте видалити Тікет?',
      'Do you want replace sides in invoice?': 'Бажаєте замінити сторони в інвойсі?',
      'Do you want to cancel this Contract?': 'Бажаєте скасувати цей Контракт?',
      'Do you want to clone this Client?': 'Бажаєте клонувати цього контрагента?',
      'Do you want to clone this Consignment?': 'Бажаєте клонувати Накладну?',
      'Do you want to clone this Contract?': 'Бажаєте клонувати Контракт?',
      'Do you want to clone this Disbursement bill of lading?':
        'Бажаєте клонувати Видаткову накладну?',
      'Do you want to clone this General Agreement ?': 'Бажаєте клонувати угоду?',
      'Do you want to clone this Invoice?': 'Бажаєте клонувати Інвойс?',
      'Do you want to clone this Logistic?': 'Бажаєте клонувати Логістику?',
      'Do you want to clone this Person?': 'Бажаєте клонувати Контакт?',
      'Do you want to clone this Request?': 'Бажаєте клонувати Тікет?',
      'Do you want to clone this object?': "Бажаєте клонувати цей об'єкт?",
      'Do you want to connect': "Бажаєте з'єднати",
      'Do you want to create a control?': 'Бажаєте створити Контроль?',
      'Do you want to create a supplier with short name  and role':
        'Ви хочете створити постачальника з короткою назвою  та роллю',
      'Do you want to create a user for this Person?':
        'Бажаєте створити користувача для цього контакту?',
      'Do you want to create contract?': 'Бажаєте створити контракт?',
      'Do you want to create mailing by  contracts from \\\n  selected  logisitc(s)?':
        'Бажаєте створити розсилку за контрактами з обраною логістикою?',
      'Do you want to create mailing by contracts from selected  invoice(s)?':
        'Бажаєте створити розсилку за контрактами з обраних інвойсів?',
      'Do you want to create multicontract?': 'Бажаєте створити мультиконтракт?',
      'Do you want to create payment by this plan?': 'Бажаєте створити оплату за цим планом?',
      'Do you want to create ticket?': 'Бажаєте створити тікет?',
      'Do you want to delete all passport connections?':
        "Бажаєте видалити всі паспортні з'єднання?",
      'Do you want to delete selected Logistics?': 'Бажаєте видалити обрану Логістику?',
      'Do you want to delete this mailing?': 'Бажаєте вилучити цю розсилку?',
      'Do you want to drop  mails?': 'Бажаєте скинути листи?',
      'Do you want to execute this passport?': 'Бажаєте оформити паспорт?',
      'Do you want to read all?': 'Бажаєте прочитати все?',
      'Do you want to reverse contract?': 'Бажаєте змінити контракт?',
      'Do you want to reverse request?': 'Хочете зробити бек-ту-бек тікет?',
      'Do you want to reverse ticket?': 'Бажаєте повернути тікет?',
      'Do you want to send  mails?': 'Бажаєте відправити листи?',
      'Do you want to update final volume from execution?':
        "Бажаєте оновити фінальний об'єм з виконання?",
      'Do you want to update logistics by discount?':
        'Вам потрібно оновити логістику із дисконтом?',
      'Do you want to update purchase price of logistics from indicator?':
        'Ви бажаєте оновити закупівельну ціну логістики з індикатора?',
      'Do you want to update status of logistics with limits?':
        'Вам потрібно оновити статус логістики з врахуванням лімітів?',
      'Doc removed': 'Документ видалено',
      Docs: 'Документи',
      'Docs approved': 'Документи підтверджено',
      'Docs approved by us': 'Підтверджені документи',
      'Docs received': 'Документи отримано',
      'Docs requested': 'Документи запит',
      Document: 'Документ',
      'Document ID': 'Документ ID',
      'Document Type': 'Тип Документа',
      'Document copy': 'Копія документа',
      'Document original': 'Оригінал документа',
      'Document saved': 'Документ збережено',
      'Document templates': 'Шаблони документів',
      'Document type': 'Тип документа',
      Documentation: 'Документація',
      Documents: 'Документи',
      'Documents check-list': 'Перелік документів',
      'Documents dates': 'Дати документів',
      'Documents entry dates': 'Дати введення документів',
      'Documents saved': 'Документи збережено',
      'Documents. Completed if all documents per contract are received':
        'Документи. Заповнюється, якщо отримано всі документи за контрактом',
      "Don't use for KPI": 'Не використовувати KPI',
      'Don`t use kpi check': 'Не використовувати перевірку KPI',
      Done: 'Виконано',
      Download: 'Завантажити',
      'Download a template': 'Завантажити шаблон',
      'Download all': 'Завантажити все',
      'Download all documents': 'Завантажити всі документи',
      Downloaded: 'Завантажено',
      Draft: 'Чернетка',
      'Drag and drop a file': 'Перетягніть і скиньте файл',
      'Drag and drop to reorder': 'Перетягніть, щоб змінити порядок',
      Driver: 'Водій',
      'Driver details': 'Деталі про водія',
      "Driver doesn't exist": 'Водій не існує',
      'Driver passport seria & number': 'Серія та номер паспорта водія',
      'Driver passport seria & number (Ukrainian)': 'Серія та номер паспорта водія (українською)',
      'Driver removed': 'Водій видалений',
      Drivers: 'Водії',
      'Drop all connections to passport': "Скинути всі прив'язки до паспорта",
      'Drop connections': "Скинути прив'язки",
      'Drop selected': 'Відмінити вибір',
      Drying: 'Сушіння',
      Dt: 'Dt',
      'Due date': 'Термін виконання',
      'Due date days': 'Кінцеві дні',
      'Due date days after create': 'Кількість днів до дати виконання після створення',
      Dynamic: 'Динамічний',
      'E-mail': 'E-mail',
      'E.g. you have a chain from inland EXW purchase to CIF sale via several counteparties\n              - you might use two or more passport for each part of the chain.':
        'Наприклад, у Вас є ланцюг від внутрішньої покупки EXW до продажу CIF через кількох контрагентів \n              - можна використовувати два або більше паспортів для кожної частини ланцюга.',
      'EDUCATION MODE in either company or in your personal settings is':
        'РЕЖИМ НАВЧАННЯ в компанії або в особистих налаштуваннях',
      ENABLED: 'УВІМКНЕНО',
      ENTER: 'Введіть',
      ETA: 'ETA',
      ETB: 'ETB',
      ETC: 'ETC',
      ETS: 'ETS',
      'EUR 1': 'EUR 1',
      'EUR 1 required': 'Потрібен 1 EUR',
      EXECUTION: 'ВИКОНАННЯ',
      'EXECUTION VALUE': 'СУМА ВИКОНАННЯ',
      EXPORT: 'ЕКСПОРТ',
      'EXPORTER CONFIRMATION': 'ПІДТВЕРДЖЕННЯ ЕКСПОРТЕРА',
      EXPORTERS: 'ЕКСПОРТЕРИ',
      'EXTRA DIMENSIONS': 'ДОДАТКОВІ ВИМІРЮВАННЯ',
      'Economic activities code': 'КВЕД',
      Edit: 'Редагувати',
      'Edit (admin)': 'Редагування (адмін)',
      'Edit Users': 'Редагувати користувачів',
      Editor: 'Редактор',
      Elevator: 'Елеватор',
      'Elevator Name': 'Назва елеватора',
      'Elevator cost': 'Витрати елеватора',
      'Elevator losts': 'Елеватор втрати',
      Elevators: 'Елеватори',
      Email: 'Email',
      'Email Templates': 'Шаблони Email',
      'Email max length': 'Максимальна довжина Email',
      'Email preview': 'Попередній перегляд Email',
      'Email templates': 'Шаблони Email',
      Emails: 'Електронні адреси',
      'Empty finances list': 'Пустий список інвойсів',
      Enabled: 'Увімкнено',
      End: 'Кінець',
      'End Date': 'Кінцева Дата',
      'End balance': 'Кінцевий баланс',
      'End date': 'Кінцева дата',
      'End of execution': 'Доставка (дата закінчення)',
      'End usage (date)': 'Кінець використання (дата)',
      Ending: 'Закінчення',
      'Ending cash': 'Залишок на кінець',
      'Ending status': 'Фінальний статус',
      Eng: 'Англ',
      English: 'Англійська',
      'Ensure to <span class="positive-number">save customs declarations</span> if\n            necessary!':
        'Переконайтесь, що <span class="positive-number">зберегли митні декларації, якщо це необхідно!',
      'Enter logistic volume limit': 'Введіть ліміт логістичного обсягу',
      'Enter volume limit': "Введіть обмеження об'єму",
      'Entry date / Exit date': 'Дата в’їзду / Дата виїзду',
      'Error:': 'Помилка:',
      'Estimated P&L': 'Очікуваний PnL',
      'Estimated Q (T)': 'Очікувана кількість (т)',
      'Estimated receiving (date)': 'Прогнозована дата отримання',
      'Estimated volume of the deal for contract allocation':
        "Очікуваний об'єм угоди для розподілу контракту",
      Europe: 'Європа',
      Event: 'Подія',
      'Event Subscription': 'Підписка на подію',
      'Event subscriptions': 'Підписки на події',
      Exact: 'Визначений',
      Exchange: 'Обмін',
      'Exchange rate': 'Курс обміну',
      'Exchange rate alternative': 'Альтернативний курс валюти',
      Exchanges: 'Курси',
      'Exclude from positions': 'Не враховувати у позиціях',
      'Exec forecasted amount': 'Очікувана сума за угоду',
      Executed: 'Виконано',
      'Executed - Paid': 'Виконано - Оплачено',
      'Executed qty': 'Виконана кількість',
      'Executed volume': "Виконаний об'єм",
      Execution: 'Виконання',
      'Execution (end date)': 'Виконання (кінцева дата)',
      'Execution (start date)': 'Виконання (дата початку)',
      'Execution (t)': 'Виконання (т)',
      'Execution - Analytical': 'Виконання - Аналітичне',
      'Execution - Invoices': 'Виконання - Інвойси',
      'Execution Dates': 'Дати виконання',
      'Execution amount': 'Сума виконання',
      'Execution date': 'Дата виконання',
      'Execution end': 'Кінець виконання',
      'Execution end date': 'Кінцева дата виконання',
      'Execution end date position': 'Кінцева дата виконання позиції',
      'Execution estimated month': 'Очікуваний місяць виконання',
      'Execution margin': 'Маржа виконання',
      'Execution month': 'Місяць виконання',
      'Execution of loyalty programs': 'Виконання програм лояльності',
      'Execution period': 'Термін виконання',
      'Execution start': 'Початок виконання',
      'Execution start date': 'Дата початку виконання',
      'Execution start date position': 'Дата початку виконання позиції',
      'Execution value': 'Сума виконання',
      'Existing Payment plan': 'Існуючий План оплати',
      'Expected month of charge': 'Очікуваний місяць оплати',
      Expense: 'Витрата',
      'Expense type': 'Тип витрати',
      Expenses: 'Витрати',
      Expiration: 'Експірація',
      'Expiration date of power of attorney': 'Закінчення терміну дії довіреності',
      'Expiration end': 'Кінцева дата експірації',
      'Expiration start': 'Початок експірації',
      Expired: 'Термін дії минув',
      Expiring: 'Еспірація',
      Export: 'Експорт',
      'Export Contract': 'Експортний контракт',
      'Export Contract (#)': 'Експортний контракт (#)',
      'Export allocations': 'Розподіл експорту',
      'Export business unit': 'Бізнес-юніт експорту',
      'Export contract': 'Експортний контракт',
      'Export contract number': 'Номер експортного контракту',
      'Export contract position': 'Позиція експортного контракта',
      'Export contract price': 'Ціна експортного контракта',
      'Export contract total': 'Загальна сума експортного контракту',
      'Export invoice': 'Інвойс для експорту',
      'Export invoiced': 'Виставлено експортний інвойс',
      'Export multicontract': 'Експортний мультиконтракт',
      'Export multiticket': 'Експортний мультитікет',
      'Export tax': 'Податок на експорт',
      'Export tax:': 'Податок на експорт:',
      ExportContract: 'Експортний контракт',
      Exporter: 'Експортер',
      'Exporter address': 'Адреса експортера',
      'Exporter can signatory docs': 'Експортер може підписувати документи',
      'Exporter can signatory docs eng': 'Експортер може підписувати документи (англ)',
      'Exporter country': 'Експортер країна',
      'Exporter country eng': 'Експортер країна (англ)',
      'Exporter email': 'Експортер email',
      'Exporter full name': 'Повна назва експортера',
      'Exporter full name eng': 'Повна назва експортера (англ)',
      'Exporter legal address': 'Юридична адреса експортера',
      'Exporter legal address eng': 'Юридична адреса експортера (англ)',
      'Exporter list': 'Список експортерів',
      'Exporter list eng': 'Список експортерів (англ)',
      'Exporter name': 'Назва експортера',
      'Exporter phone': 'Телефон експортера',
      'Exporter signatory': 'Підписант експортера',
      'Exporter signatory eng': 'Підписант експортера (англ)',
      'Exporter website': 'Експортер веб-сайт',
      Exporters: 'Експортери',
      'Exporters eng': 'Експортери (англ)',
      'Exporters list': 'Список експортерів',
      'Exporters list eng': 'Список експортерів (англ)',
      Exposition: 'Результат',
      'Exposition 12,70 Cts/Bu ou -5$/T': 'Результат 12,70 Cts/Bu ou -5$/T',
      'Exposition 25,40 Cts/Bu ou -10$/T': 'Результат 25,40 Cts/Bu ou -10$/T',
      'Exposition, Mt': 'Ризик, т',
      Ext: 'Ext',
      Extension: 'Пролонгація',
      'Extension (end)': 'Пролонгація (кінець)',
      'Extension (start)': 'Пролонгація (початок)',
      'Extra actions/buttons': 'Додаткові дії/кнопки',
      FACT: 'ФАКТ',
      FILTERS: 'ФІЛЬТРИ',
      FINANCE: 'ФІНАНСИ',
      FINANCES: 'ФІНАНСИ',
      'FOB price': 'FOB ціна',
      'FUNDS DISTRIBUTION': 'РОЗПОДІЛ КОШТІВ',
      Fact: 'Факт',
      'Fact - Plan': 'Факт - План',
      'Fact created': 'Створено факт',
      'Fact crop protection': 'Факт захист урожаю',
      'Fact distributor': "Факт дистриб'ютора",
      'Fact distributors': "Факт дистриб'юторів",
      'Fact seeds': 'Факт посіву',
      Facts: 'Факти',
      Fail: 'Невдача',
      Failed: 'Невдалий',
      'Failure reason': 'Причина помилки',
      'Failure reasons': 'Причини невдачі',
      Farm: 'Господарство',
      Favorite: 'Улюблений',
      'Favorite Filters': 'Улюблені фільтри',
      'Favorite filter': 'Улюблений фільтр',
      'Favorites management': 'Управління вибраними',
      'Favourite filters': 'Улюблені фільтри',
      February: 'Лютий',
      'Fee amount': 'Сума комісії',
      'Few consignees': 'Декілька вантажоодержувачів',
      'Few prices': 'Декілька цін',
      Field: 'Поле',
      File: 'Файл',
      "File '' is to big": 'Файл занадто великий',
      'Fill by template': 'Заповнити за шаблоном',
      'Fill if you want to calculate MTM by this contract':
        'Заповніть, якщо ви хочете розрахувати MTM за цим контрактом',
      'Fill it if only there are some differences from standard compliance process. This will cause APPROVED WITH CONDITION status':
        'Заповніть, якщо тільки є деякі відмінності від стандартного процесу комплаєнсу. Це призведе до статусу "ЗАТВЕРДЖЕНО З УМОВОЮ".',
      'Fill it only if you want this contract to be converted by a certain rate':
        'Заповніть, якщо ви хочете, щоб цей контракт був конвертований за певним курсом',
      'Fill it to display Invoice in Budget report':
        'Заповніть, щоб показати інвойс у звіті про бюджет',
      Filter: 'Фільтр',
      'Filter Set removed': 'Фільтр видалено',
      'Filter Set updated': 'Фільтр оновлено',
      'Filter by': 'Фільтрувати за',
      'Filter by selected business units': 'Фільтр за вибраними бізнес-юнітами',
      Filters: 'Фільтри',
      'Fin purpose': 'Фінансова ціль',
      Final: 'Фінал',
      'Final Amount': 'Остаточна сума',
      'Final Volume (T)': "Фінальний об'єм (т)",
      'Final amount': 'Фінальна сума',
      'Final approve': 'Фінальне підтвердження',
      'Final confirmation': 'Фінальне підтвердження',
      'Final date': 'Фінальна дата',
      'Final total': 'Остаточна сума',
      'Final volume': "Фінальний об'єм",
      'Final volume equals connections': "Фінальний об'єм дорівнює з’єднанням",
      'Final volume sum from positions': "Остаточна сума об'єму з позицій",
      Finance: 'Фінанси',
      'Finance Account': 'Фінансовий рахунок',
      'Finance Bank': 'Фінансовий банк',
      'Finance account': 'Фінансовий рахунок',
      'Finance accounts': 'Фінансові рахунки',
      'Finance credit account': 'Кредит фінансового рахунку',
      'Finance days': 'Фінансові дні',
      'Finance debit account': 'Дебет фінансового рахунку',
      "Finance doesn't exist": 'Фінансів не існує',
      'Finance offset value': 'Фінанси залік вартість',
      'Finance passport position': 'Позиція фінансів паспорта',
      Finances: 'Фінанси',
      'Finances mailing': 'Розсилка інвойсів',
      'Financial purpose': 'Фінансова ціль',
      'Financial result': 'Фінансовий результат',
      Financing: 'Фінансування',
      'Find a filter': 'Знайти фільтр',
      'Fines amount': 'Сума штрафів',
      'Fines days count': 'Кількість днів штрафу',
      'Fines days count method': 'Метод обчислення днів штрафу',
      'Fines invoicing': 'Виставлення штрафів на рахунок',
      'Fines invoicing method': 'Спосіб нарахування штрафів',
      'First (validation)': 'Перша (валідація)',
      'First - select a invoices previously filtered them Second - press Create an Emails\n                Third - look at the table below, you email will be ready to preview, gently check it\n                and then send.':
        'Перше — оберіть раніше відфільтровані інвойси. \nДруге — натисніть “Створити електронні листи”.\nТретє — подивіться на таблицю нижче, ваш електронний лист буде готовий до попереднього перегляду. Обережно перевірте його, а потім надішліть.',
      'First Name': 'Імʼя',
      'First Name is required': 'Потрібно вказати ім’я',
      'First charge': 'Перша витрата',
      'First confirm date': 'Дата першого підтвердження',
      'First confirmation (date)': 'Перше підтвердження (дата)',
      'First confirmed': 'Перше підтвердження',
      'First contact': 'Перший контакт',
      'First date must be less then Second': 'Перша дата має бути меншою за другу',
      'First day of the week': 'Перший день тижня',
      'First name': 'Імʼя',
      Fixed: 'Зафіксовано',
      'Fixed lots': 'Зафіксовані лоти',
      'Fixed price bonus': 'Фіксований бонус за ціною',
      'Fixed tonnes': 'Зафіксовані тонни',
      Fixing: 'Фіксація',
      'Fixing Hedging': 'Фіксинг Хеджування',
      'Fixing and hedging': 'Фіксинг та Хеджування',
      'Fixing position': 'Фіксинг позиція',
      'Fixing/Hedging': 'Фіксинг/Хеджування',
      Flag: 'Прапор',
      'Flat price': 'Фіксована ціна',
      'Flat price per not fixed lots': 'Фіксована ціна за нефіксовані лоти',
      'Flat price per not hedged lots': 'Фіксована ціна за нехеджовані лоти',
      Fobbing: 'Фобінг',
      'Folder number': 'Номер папки',
      Font: 'Шрифт',
      'For KPI year report': 'До звіту за рік КПІ',
      'For example if the contract qty is 5000 and this contract has cost of 1000$ and\n                    you allocated this contract on two passports 2500 and 2500 respectively':
        "Наприклад, якщо об'єм контракту становить 5000 і цей контракт має вартість 1000 usd і ви розподілили цей контракт на два паспорти 2500 і 2500 відповідно",
      'For purposes of filtering information by specific season':
        'Для фільтрації інформації за конкретним сезоном',
      Forecast: 'Прогноз',
      'Forecasted - Payment': 'Очікування - Оплати',
      'Foreign currency': 'Іноземна валюта',
      'Form invalid': 'Форма недійсна',
      Forward: 'Форвард',
      'Forward fact': 'Форвард факт',
      'Forward limit': 'Форвард ліміт',
      Forwarder: 'Перевізник',
      'Forwarder railway': 'Експедитор залізниця',
      Forwarders: 'Експедитори',
      Fr: 'Fr',
      'Free Volume': 'Вільний обʼєм',
      'Free days EWRP': 'Безкоштовні дні EWRP',
      'Free days JCC': 'Безкоштовні дні JCC',
      'Free tax invoice': 'Інвойс без податків',
      'Free volume': 'Вільний обʼєм',
      'Free volume PCD': "Вільний об'єм PCD",
      Freight: 'Фрахт',
      'Freight Info': 'Інформація про фрахт',
      'Freight Position': 'Позиція фрахту',
      'Freight VAT': 'Фрахт ПДВ',
      'Freight broker costs': 'Витрати на брокера з вантажних перевезень',
      'Freight costs': 'Фрахт витрати',
      'Freight currency opposite': 'Зустрічна валюта фрахту',
      'Freight exchange rate': 'Обмінний курс фрахту',
      'Freight level': 'Фрахт',
      'Freight opposite': 'Зустрічний фрахт',
      'Freight position + MTM': 'Позиція фрахту + МТМ',
      Freights: 'Фрахти',
      From: 'Від',
      'From Stock Reserve Unit': 'З одиниці резерву запасів',
      'From production': 'З виробництва',
      'From user': 'Від користувача',
      'From which country purchased': 'З якої країни закупили',
      'From whom': 'Від кого',
      'Full amount': 'Повна сума',
      'Full amount without VAT': 'Повна сума без ПДВ',
      'Full company name, LLC': 'Повна назва компанії, ТОВ',
      'Full current address': 'Повна поточна адреса',
      'Full name': 'Повна назва',
      'Full name (eng)': 'Повна назва (англ.)',
      'Full name Eng': 'Повна назва (англ.)',
      'Full name eng': 'Повна назва (англ.)',
      'Full name of person who signs documents by this company':
        'ПІБ особи, яка підписує документи від імені цієї компанії',
      'Full name of the Signatory to use in requisites while generating documents':
        'ПІБ підписанта, яке слід використовувати в реквізитах при генерації документів',
      'Full name to use in requisites while generating documents':
        'Для використання в реквізитах при створенні документів',
      'Full self-cost': 'Собівартість',
      'Fully invoiced': 'Повністю проінвойсовано',
      'Future contact': 'Майбутній контакт',
      'Future deleted': "Ф'ючерс видалено",
      'Future updated': "Ф'ючерс оновлено",
      Futures: "Ф'ючерси",
      GAINS: 'ДОХОДИ',
      GENERAL: 'ЗАГАЛЬНЕ',
      'GENERAL INFO': 'ЗАГАЛЬНА ІНФОРМАЦІЯ',
      'GENERAL TOTAL': 'ЗАГАЛЬНА СУМА',
      GROUPS: 'ГРУПИ',
      'GT User': 'GT користувач',
      Gains: 'Доходи',
      General: 'Загальне',
      'General Agreement': 'Договір',
      'General Contracts': 'Загальні Контракти',
      'General Expenses': 'Загальні витрати',
      'General Tickets': 'Загальні Тікети',
      'General Transaction': 'Загальна транзакція',
      'General Transactions': 'Загальні транзакції',
      'General agreement': 'Генеральна угода',
      'General agreements': 'Генеральні угоди',
      'General expense': 'Загальні витрати',
      'General expenses': 'Загальні витрати',
      'General info': 'Загальна інформація',
      'General transactions': 'Загальні операції',
      Generate: 'Створити',
      'Generate Document': 'Створити документ',
      'Generate a document': 'Створити документ',
      'Generate a preview': 'Згенерувати попередній перегляд',
      'Generate agreement number': 'Згенерувати номер угоди',
      'Generate an email': 'Створити email',
      'Generate contract name': 'Створити назву контракту',
      'Generate document': 'Створити документ',
      'Generate loan name': 'Створити назву позики',
      'Generate name': 'Згенерувати назву',
      'Generate number': 'Згенерувати номер',
      'Get counterparty data': 'Отримати дані контрагента',
      'Go to a PNL report': 'Перейти до звіту PnL',
      'Go to details': 'Перейти до деталей',
      Goal: 'Ціль',
      'Google clean': 'Очистка Google',
      'Grain Quality Check': 'Перевірка якості',
      Green: 'Зелена',
      Grey: 'Сіра',
      'Gross Closed': 'Брутто закрито',
      'Gross Margin': 'Валова маржа',
      Group: 'Група',
      'Group by': 'Групувати за',
      'Group passports into a chain': 'Згрупуйте паспорти в ланцюг',
      'Group totals': 'Загальні суми групи',
      Grouped: 'Згруповані',
      Grouping: 'Групування',
      'Grouping & filtering': 'Групування та фільтрація',
      'Grouping chain': 'Ланцюжок групування',
      'Grouping criteria': 'Критерії групування',
      Groups: 'Групи',
      HO: 'HO',
      'Has Amount Per Deal Execution': 'Має суму за виконання угоди',
      'Has Analytical amount': 'Має аналітичну суму',
      'Has BL': 'Має коносамент',
      'Has Business Unit': 'Має бізнес-юніт',
      'Has Custom status': 'Має спеціальний статус',
      'Has Discount': 'Має дисконт',
      'Has Exec Forecasted Amount': 'Має очікувану сума за угоду',
      'Has Final amount': 'Має фінальну суму',
      'Has LOI': 'Має LOI',
      'Has Multicontract': 'Має мультиконтракт',
      'Has Our amount': 'Має нашу суму',
      'Has Payment Info': 'Має інформацію про оплату',
      'Has Voyage': 'Має рейс',
      'Has additional info': 'Має додаткову інформацію',
      'Has all docs copy of DBL': 'Має всі копії документів видаткової накладної',
      'Has amount reconciliation': 'Має звірку суми',
      'Has any quality': 'Має будь-яку якість',
      'Has balance': 'Має баланс',
      'Has balance prepay': 'Має передоплату балансу',
      'Has balance to connect': "Має баланс для з'єднання",
      'Has bank details': 'Має банківські реквізити',
      'Has basis doc': 'Має базис документу',
      'Has broker': 'Має брокера',
      'Has buyer contract cargo confirmation': 'У продажного контракту вантаж підтверджений',
      'Has buyer contract final confirmation': 'У продажного контракту є кінцеве підтвердження',
      'Has buyer contract payment confirmation': 'У продажного контракту оплата підтверджена',
      'Has cancelled invoice': 'Має скасований інвойс',
      'Has cancelled signature': 'Підписання скасовано',
      'Has cargo confirmation': 'Має підтвердження вантажу',
      'Has cargo customs declaration': 'Має вантажну митну декларацію',
      'Has comments': 'Має коментарі',
      'Has connection to passport': "Має прив'язку до паспорта",
      'Has contract': 'Має контракт',
      'Has contracts': 'Має контракт',
      'Has control date': 'Має дату контролю',
      'Has cost basis warning': 'Має попередження про вартість',
      'Has customs declaration': 'Має вантажну митну декларацію',
      'Has date': 'Має дату',
      'Has date of execution': 'Має дату виконання',
      'Has derivative operations': 'Має операції деривативів',
      'Has destination agent': 'Є агент в країні призначення',
      'Has destination intermediate agent': 'Є агент-посередник в країні призначення',
      'Has destination port': 'Має порт призначення',
      'Has destination port intermediate': 'Має проміжний порт призначення',
      'Has diff limit': 'Має обмеження за лімітом',
      'Has disbursement bill of lading': 'Має видаткову накладну',
      'Has docs': 'Має документи',
      'Has docs copy': 'Має копію документів',
      'Has docs original': 'Має оригінал документів',
      'Has documents': 'Має документи',
      'Has empty qualities': 'Містить незаповнені характеристики',
      'Has export facts': 'Має факт експорту',
      'Has export invoice': 'Має інвойс експорту',
      'Has export logistics': 'Має логістику експорту',
      'Has fact crop protection': 'Є факт захисту врожаю',
      'Has fact seeds': 'Має факт посів',
      'Has filled qualities': 'Має заповнені характеристики',
      'Has final confirmation': 'Має остаточне підтвердження',
      'Has fixing': 'Має фіксінг',
      'Has general agreement': 'Має генеральну угоду',
      'Has grain quality check': 'Має перевірку якості',
      'Has hedging': 'Має хеджування',
      'Has incoming invoice': 'Має вхідний інвойс',
      'Has indicator': 'Має індикатор',
      'Has invoice': 'Має інвойс',
      'Has invoice Contract/Passport connected': "Має з'єднаний інвойс до контракту/паспорта",
      'Has invoice documents': 'Має документи на інвойс',
      'Has invoices': 'Має інвойси',
      'Has issuer counterparty': 'Видав контрагенту',
      'Has limit': 'Має ліміт',
      'Has loading agent': 'Є агент в країні завантаження',
      'Has loading intermediate agent': 'Є агент-посередник в країні завантаження',
      'Has loading port': 'Має порт завантаження',
      'Has loading port intermediate': 'Має проміжний порт завантаження',
      'Has logistic': 'Має логістику',
      'Has margin diff by type': 'Має різницю маржі за типом',
      'Has multicontract': 'Має мультиконтракт',
      'Has no basis doc': 'Немає базису документа',
      'Has no quality': 'Не має якості',
      'Has note': 'Має ноту',
      'Has offset': 'Має залік',
      'Has only empty qualities': 'Має лише незаповнені характеристики',
      'Has only filled qualities': 'Має лише заповнені характеристики',
      'Has originals received': 'Оригінали отримано',
      'Has outgoing invoice': 'Має вихідний інвойс',
      'Has own purchase price': 'Має власну закупівельну ціну',
      'Has own sale price': 'Має власну продажну ціну',
      'Has passport or contract': 'Має паспорт або контракт',
      'Has payment': 'Має оплату',
      'Has payment confirmation': 'Має підтвердження оплати',
      'Has periodic customs declaration': 'Має періодичну митну декларацію',
      'Has plan crop protection': 'Є план захисту врожаю',
      'Has plan seeds': 'Має план посів',
      'Has position cancelled signature': 'Має скасований підпис позиції',
      'Has position connected to passport': 'Має позицію, пов’язану з паспортом',
      'Has position with cargo customs declaration': 'Має позицію з вантажною митною декларацією',
      'Has position with periodic customs declaration':
        'Має позицію з періодичною митною декларацією',
      'Has povitive balance': 'Є позитивний баланс',
      'Has prepay': 'Має передплату',
      'Has purchase facts': 'Має факти закупівлі',
      'Has reassignment': 'Має переуступку',
      'Has receiver counterparty': 'Має контрагента-одержувача',
      'Has responsible': 'Має відповідальність',
      'Has sale facts': 'Має факт продажу',
      'Has sale internal': 'Має внутрішню угоду на продаж',
      'Has season': 'Має сезон',
      'Has several passports': 'Має декілька паспортів',
      'Has shipments': 'Має відвантаження',
      'Has supplier contract cargo confirmation': 'У закупівельного контракту вантаж підтверджено',
      'Has supplier contract final confirmation': 'У контракту закупівлі є кінцеве підтвердження',
      'Has supplier contract payment confirmation':
        'У закупівельного контракту оплата підтверджена',
      'Has ticket': 'Має тікет',
      'Has uninvoiced cost': 'Має не проінвойсовані витрати',
      'Has unpaid invoice': 'Має неоплачений інвойс',
      'Has volume reconciliation': 'Має звірку об’ємів',
      'Has volume with docs': "Має об'єм з документами",
      'Has withdrawal date': 'Має дату вилучення',
      'Have to confirm': 'Потрібно підтвердити',
      'Heads of regional manager': 'Керівники відділу продажів',
      'Heads of sale managers': 'Керівники відділу продажів',
      Hedged: 'Захеджовано',
      'Hedged lots': 'Хеджовані лоти',
      Hedging: 'Хеджування',
      'Help buttons expanded': 'Розгорнути кнопки допомоги',
      'Here are the similar companies by name. Check if your current company is not conflicting with existing ones.':
        'Показані подібні компанії за назвою. Перевірте, чи Ваша поточна компанія не збігається з існуючими.',
      'Here is the list of all passports. Passport is the full cycle of the deal from\n                beginning to the end.':
        'Ось список всіх паспортів. Паспорт - це повний цикл угоди від початку до кінця.',
      'Here you can allocate purchase side with the sale side and GT will do everything by\n                itself: calculate profitability, consolidate costs, documents, invoices in one\n                place.':
        'Тут ви можете розподілити сторону закупівлі із стороною продажу і GrainTrack все зробить cамостійно: \n                розрахує рентабельність, консолідує витрати, документи, інвойси \n                в одному місці.',
      'Here you can type full title of the company with type of ownership, etc. Example - Full company name, LLC':
        'Тут ви можете ввести повну назву компанії з типом власності і т.д. Наприклад - повна назва компанії, ТОВ',
      'Hidden by default': 'Приховано за замовчуванням',
      Hide: 'Сховати',
      'Hide Users': 'Сховати користувачів',
      'Hide all': 'Сховати все',
      'Hide child crops': 'Сховати дочірні культури',
      'Hide executed': 'Сховати виконано',
      'Hide fixed contracts': 'Сховати фіксовані контракти',
      'Hide full table': 'Сховати всю таблицю',
      'Hide hedged contracts': 'Приховати хеджовані контракти',
      'Hide history': 'Сховати історію',
      'Hide inactive accounts': 'Приховати неактивні рахунки',
      'Hide passport without accounts': 'Приховати паспорт без відповідних рахунків',
      High: 'Високий',
      History: 'Історія',
      Home: 'Головна',
      'Horizontal groups': 'Горизонтальні групи',
      Hot: 'Гарячий',
      IBAN: 'IBAN',
      ID: 'ID',
      IMO: 'IMO',
      IN: 'Вхід',
      INCOMING: 'ВХІДНИЙ',
      INDICATORS: 'ІНДИКАТОРИ',
      INFO: 'ІНФО',
      'INITIAL DATE': 'ПОЧАТКОВА ДАТА',
      INTERMEDIATE: 'ПРОМІЖНА',
      INVOICE: 'ІНВОЙС',
      'INVOICE DOCUMENTS': 'ІНВОЙС ДОКУМЕНТИ',
      INVOICED: 'ВИСТАВЛЕНИЙ ІНВОЙС',
      'INVOICED VALUE': 'СУМА ІНВОЙСІВ',
      INVOICES: 'ІНВОЙСИ',
      'INVOICES LIST': 'ПЕРЕЛІК ІНВОЙСІВ',
      ISCC: 'ISCC',
      ITN: 'ІПН',
      'If company has more than one number': 'Якщо компанія має більше одного номера',
      'If current company is a part of a bigger group':
        'Якщо поточна компанія входить до складу більшої групи',
      'If you have a complicated chain you will need it.':
        'Якщо у вас складний ланцюг, то вам знадобиться це.',
      'If you pick something here, information about this object will be displayed only for people from those business units':
        'Якщо ви виберете щось тут, інформація про цей об’єкт буде відображатися лише для людей з цих бізнес-юнітів',
      'If you want fill by template': 'Якщо ви бажаєте заповнити за допомогою шаблону',
      'If you want so set negotiation process and defined whether charge is validated or under discussion':
        'Якщо ви бажаєте встановити процес підтвердження і визначити, що витрата підтверджується або обговорюється',
      'If you want to add type or activity to this company':
        'Якщо ви хочете додати тип або діяльність до цієї компанії',
      'If you want to assign some custom role to this company':
        'Якщо ви хочете призначити певну персональну роль для цієї компанії',
      'If you want to convert all financial data in passport by one rate to USD':
        'Якщо ви хочете конвертувати всі фінансові дані в паспорті за одним курсом в USD',
      'Ignore allocation': 'Ігнорувати розподіл',
      'Ignore client status': 'Ігнорувати статус контрагента',
      'Ignore counterparty limits': 'Ігнорувати ліміти контрагента',
      'Ignore counterparty status': 'Ігнорувати статус контрагента',
      'Ignore excess contract volume': 'Ігнорувати надлишковий обсяг контракту',
      'Ignore limits': 'Ігнорувати ліміти',
      Imo: 'Imo',
      Import: 'Імпорт',
      Important: 'Важливо',
      Imported: 'Імпортовано',
      In: 'Вхід',
      'In date': 'Дата отримання',
      'In general, passport is one vessel, which can contain sale and purchases in\n                different configurations: 1 sale vs 1 purchase, 1vs3, 5vs2, whatever. Or if it is\n                inland deal - one passport is just some sale in from of some purchase even without a\n                vessel.':
        'Загалом, паспорт — це одне судно, яке може містити продажі та покупки в різних конфігураціях: 1 продаж проти 1 покупки, 1 проти 3, 5 проти 2, будь-яка комбінація. Або, якщо це внутрішня угода, то один паспорт — це просто продаж у вигляді якоїсь покупки, навіть без судна.',
      'In number': 'Вхідний номер',
      'In process': 'В процесі',
      'In progress': 'В процесі',
      'In the case of any questions, please, contact our Support Team':
        'У разі будь-яких питань, будь ласка, звертайтесь до нашої команди підтримки.',
      'In the case, if contract is PURCHASE - then EXPORTERS are chosen amont the exporters of SUPPLIER of this contractIf the contract is SALE, then EXPORTERS are chose from PURCHASE contracts, that are linked with this SALE contractvia passport. So, to add Exporters to Sale contract, at first, link this Sale contract with other Purchase contracts via Passport.':
        "У випадку, якщо контракт Є ЗАКУПІВЕЛЬНИЙ - тоді ЕКСПОРТЕРІВ вибираємо з експортерів ПОСТАЧАЛЬНИКА.\nЯкщо контракт ПРОДАЖНИЙ, тоді ЕКСПОРТЕРИ самі вибираються з усіх ЗАКУПІВЕЛЬНИХ контрактів, які пов'язані з ПРОДАЖНИМ контрактом через ПАСПОРТ.\nОтже, для того, щоб додати Експортерів до Продажного контракту, зв'яжіть цей Продажний контракт із Закупівельними через Паспорт.",
      'In the case, if general agreement is PURCHASE - then the BUYER is our company. It is chosen from the list of Owners':
        'Якщо контракт ЗАКУПІВЕЛЬНИЙ - тоді ПОКУПЦЕМ є наша компанія. Її можна вибрати зі списку Власників',
      'In the case, if general agreement is SALE - then the SUPPLIER is our company. It is chosen from the list of Owners':
        'Коли контракт ПРОДАЖНИЙ - ПОСТАЧАЛЬНИКОМ є наша компанія. Її можна вибрати зі списку власників',
      Inactive: 'Неактивний',
      'Include VAT in amount': 'Включити ПДВ в суму',
      'Include intermediate logistics': 'Включити проміжну логістику',
      'Including derivatives': 'Включаючи деривативи',
      Incomes: 'Доходи',
      Incoming: 'Вхідні',
      'Incoming (date)': 'Вхідний (дата)',
      'Incoming - all invoiced issued to us (means we will spend cash). Outgoing - all invoices issued by us (means: we will receive cash)':
        'Вхідний - всі видані нам інвойси (означає, що ми витратимо кошти). Вихідні - всі виставлені нами рахунки (означає, що ми отримаємо кошти)',
      'Incoming - invoice is received from counterparty, Outgoing - invoice is issued by us':
        'Вхідний - інвойс отримується від контрагента, Вихідний - рахунок виставляється нами',
      'Incoming balance': 'Вхідний баланс',
      'Incoming invoice status': 'Статус вхідного інвойсу',
      'Incoming invoices': 'Вхідні інвойси',
      'Incoming payments': 'Вхідні оплати',
      'Incoming reserve': 'Вхідний резерв',
      'Incoming volume': "Вхідний об'єм",
      Incoterms: 'Базис',
      Indicator: 'Індикатор',
      'Indicator creation': 'Створення індикатора',
      'Indicator crops': 'Індикатор культури',
      'Indicator free volume exceeded! Available free volume:':
        'Індикатор перевищення вільного обсягу! Доступний вільний обсяг:',
      'Indicator number': 'Номер індикатора',
      'Indicator price': 'Ціна індикатора',
      'Indicator saved': 'Індикатор збережено',
      Indicators: 'Індикатори',
      'Indicators (spot)': 'Індикатори (спот)',
      'Indicators number': 'Номер індикаторів',
      'Individual tax number': 'Індивідуальний податковий номер',
      Info: 'Інфо',
      'Info for traders': 'Інформація для трейдерів',
      Information: 'Інформація',
      'Information about company as a tax payer': 'Інформація про компанію як платника податків',
      'Initial amount': 'Початкова сума',
      'Initial balance': 'Початковий баланс',
      'Initial cash': 'Початкова сума',
      'Initial cash is required': 'Потрібна початкова сума',
      'Initial date': 'Початкова дата',
      Initiator: 'Ініціатор',
      'Input number': 'Введіть номер',
      Instance: 'Сутність',
      'Instructions sent': 'Інструкції відправлено',
      Insurance: 'Страхування',
      'Insurance status': 'Статус страхування',
      Insurer: 'Страховик',
      Insurers: 'Страховики',
      'Intake volume': "Об'єм завантаження",
      'Interest Rate Amount': 'Сума процентної ставки',
      'Interest amount': 'Сума відсотків',
      'Interest payment date': 'Дата виплати відсотків',
      'Interest payment date (plan)': 'Дата виплати відсотків (план)',
      'Interest rate': 'Процентна ставка',
      'Interest rate delay': 'Затримка процентної ставки',
      'Interest rate, %': 'Процентна ставка, %',
      Intermediate: 'Проміжний',
      'Intermediate Contract': 'Проміжний Контракт',
      'Intermediate Contract #': 'Проміжний Контракт #',
      'Intermediate buyer': 'Проміжний покупець',
      'Intermediate contract': 'Проміжний контракт',
      'Intermediate logistics': 'Проміжна логістика',
      'Intermediate multicontract': 'Проміжний мультиконтракт',
      'Intermediate multiticket': 'Проміжний мультитікет',
      IntermediateContract: 'Проміжний Контракт',
      'Internal Chain': 'Внутрішній ланцюг',
      'Internal chain': 'Внутрішній ланцюг',
      'Internal chain contracts': 'Внутрішній ланцюг контрактів',
      Invalid: 'Недійсний',
      'Inventory Value in Stock, $': 'Вартість інвентарю на складі, $',
      Invoice: 'Інвойс',
      'Invoice Amount Difference': 'Різниця суми інвойсу',
      'Invoice Assignment': 'Призначення інвойсу',
      'Invoice Position': 'Інвойс-позиція',
      'Invoice Position configs': 'Конфігурації позиції інвойсу',
      'Invoice Positions': 'Інвойс позиції',
      'Invoice Status': 'Статус Інвойсу',
      'Invoice charge': 'Витрата інвойса',
      'Invoice commodity': 'Культура інвойса',
      'Invoice condition': 'Умова інвойса',
      'Invoice configuration': 'Конфігурація інвойсу',
      'Invoice creation': 'Створення інвойсу',
      'Invoice data': 'Дата інвойса',
      'Invoice date': 'Дата інвойсу',
      'Invoice date of issuance': 'Дата видачі інвойсу',
      'Invoice date of payment fact': 'Дата фактичного платежу інвойсу',
      'Invoice date of payment plan': 'Дата планового платежу інвойсу',
      'Invoice discount': 'Дисконт за інвойсом',
      'Invoice discount amount': 'Сума дисконту за інвойсом',
      'Invoice documents': 'Документи інвойсу',
      'Invoice fines': 'Штрафи за інвойсом',
      'Invoice is already fully paid': 'Інвойс вже повністю оплачено',
      'Invoice is overpaid': 'Сума переплати по інвойсу',
      'Invoice is paid partially': 'Інвойс сплачено частково',
      'Invoice is paid.': 'Інвойс сплачено.',
      'Invoice is unpaid': 'Інвойс не оплачено',
      'Invoice margin': 'Маржа інвойса',
      'Invoice number': 'Номер інвойса',
      'Invoice number #': 'Номер інвойса №',
      'Invoice payments': 'Оплата інвойса',
      'Invoice percent': 'Відсотки за інвойсом',
      'Invoice position': 'Інвойс позиція',
      'Invoice positions': 'Інвойс позиції',
      'Invoice ref': 'Номер інвойса',
      'Invoice risk': 'Ризик інвойсу',
      'Invoice risk report': 'Звіт про ризик інвойсу',
      'Invoice saved': 'Інвойс збережено',
      'Invoice status': 'Статус інвойсу',
      'Invoice type': 'Тип інвойсу',
      'Invoice updated': 'Інвойс оновлено',
      'Invoice use': 'Використання інвойсу',
      'Invoice was successfully created': 'Інвойс був успішно створений',
      'Invoice-Contract counterparty difference':
        'Різниця між інвойсом та контрактом стосовно контрагента',
      Invoiced: 'Виставлено інвойс',
      'Invoiced (bal)': 'Виставлено інвойс (баланс)',
      'Invoiced (balance)': 'Виставлено інвойс (баланс)',
      'Invoiced - Paid': 'Виставлено - Сплачено',
      'Invoiced - paid': 'Виставлено - сплачено',
      'Invoiced Amount': 'Виставлена сума інвойсу',
      'Invoiced Cargo': 'Вантаж за виставленим інвойсом',
      'Invoiced amount': 'Сума виставленого інвойсу',
      'Invoiced canceled': 'Інвойс скасовано',
      'Invoiced logistic': 'Логістика за виставленим інвойсом',
      'Invoiced volume': "Виставлений об'єм інвойсу",
      Invoices: 'Інвойси',
      'Invoices - Analytical': 'Інвойси - Аналітичні',
      'Invoices - Payment': 'Інвойси - Оплати',
      'Invoices Analytics': 'Аналіта інвойсів',
      'Invoices risk': 'Ризик по інвойсам',
      'Invoices to be paid': 'Інвойс, який потрібно сплатити',
      'Invoices. Completed when invoices are issued': 'Інвойси. Завершено, коли виставлено інвойс',
      Invoicing: 'Виставлення інвойсів',
      'Invoicing costs': 'Виставлення витрат',
      'Is Autocreated': 'Автоматично створено',
      'Is Elevator': 'Елеватор',
      'Is Exporter': 'Експортер',
      'Is Farm': 'Господарство',
      'Is Internal Chain': 'Внутрішній ланцюг',
      'Is Swap': 'Своп',
      'Is a role of legal entity': 'Є роллю контрагента',
      'Is a ticket lost': 'Чи тікет втрачено',
      'Is client agent': 'Контрагент посередник',
      'Is client producer': 'Це контрагент-виробник',
      'Is client resident': 'Є контрагентом-резидентом',
      'Is closing of position': 'Чи є закриття позиції',
      'Is distributor': 'Дистрибʼютор',
      'Is extension': 'Продовження',
      'Is full invoiced': 'Повністю виставлено інвойс',
      'Is fully paid': 'Повністю сплачено',
      'Is gain': 'Це дохід',
      'Is internal chain': 'Внутрішній ланцюг',
      'Is liquid': 'Є рідиною',
      'Is lost': 'Втрачено',
      'Is main': 'Є головним',
      'Is main deal': 'Є основною угодою',
      'Is passport cost': 'Витрати паспорта',
      'Is person verified?': 'Особа перевірена?',
      'Is resident': 'Резидент',
      'Is virtual': 'Віртуальний',
      Issuance: 'Видача',
      'Issuance date': 'Дата видачі',
      'Issuance fee': 'Комісія за видачу',
      'Issue invoice': 'Виставити інвойс',
      'It seems there are no autos/wagons yet': 'Здається, що ще немає транспортних засобів',
      'It seems there are no entries': 'Здається, немає записів',
      'It seems there are no invoice positions yet': 'Здається, ще немає інвойс позиції',
      'It seems there are no month yet': 'Здається, поки немає жодного місяця',
      'It seems there are no positions yet': 'Здається, ще немає позицій',
      "It's consisted from realized and open P&L. Both physical and derivative.":
        'Він складався з реалізованих і відкритих PnL.Обидва фізичні та деривативи.',
      'Item was deleted': "Об'єкт видалено",
      January: 'Cічень',
      July: 'Липень',
      June: 'Червень',
      KPI: 'KPI',
      'KPI confirmations': 'KPI підтвердження',
      'KPI signings': 'KPI підписи',
      'KPI success': 'KPI успішно',
      Kazachstan: 'Казахстан',
      LOADING: 'ЗАВАНТАЖЕННЯ',
      'LOADING 2ND PORT': 'ЗАВАНТАЖЕННЯ 2-ГО ПОРТУ',
      'LOADING FINAL': 'ЗАВАНТАЖЕННЯ ФІНАЛ',
      LOCATION: 'РОЗТАШУВАННЯ',
      LOGISTICS: 'ЛОГІСТИКА',
      LOI: 'LOI',
      LONG: 'ДОВГИЙ',
      LOSSES: 'ВТРАТИ',
      'LOYALITY PROGRAM': 'ПРОГРАМА ЛОЯЛЬНОСТІ',
      LTD: 'LTD',
      Language: 'Мова',
      'Last 30 Days': 'За останні 30 днів',
      'Last 7 Days': 'За останні 7 днів',
      'Last B/L date': 'Дата останнього коносамента',
      'Last Month': 'Останній місяць',
      'Last login': 'Останній вхід',
      'Last market price': 'Остання ринкова ціна',
      'Last name': 'Прізвище',
      'Last name is required': 'Потрібно вказати прізвище',
      'Last price': 'Кінцева ціна',
      'Last update': 'Останнє оновлення',
      'Last updated within 1 day': 'Останнє оновлення протягом 1 дня',
      Latitude: 'Широта',
      Laycan: 'Сталійний час',
      'Laycan (end)': 'Кінець сталійного часу',
      'Laycan (start)': 'Початок сталійного часу',
      'Laycan end date': 'Кінець сталійного часу',
      'Laycan start date': 'Початок сталійного часу',
      Leased: 'Орендований',
      'Left to be shipped': 'Залишено для відвантаження',
      'Left to be shipped volume': "Залишилося відвантажити об'єм",
      Legal: 'Юридичний',
      'Legal address': 'Юридична адреса',
      'Legal address Eng': 'Юридична адреса (англ)',
      Lender: 'Кредитор',
      'Less 0': 'Менше 0',
      'Letter of Credit No.': 'Номер листа акредитиву.',
      Libor: 'Libor',
      'License expiration date': 'Дата закінчення терміну дії ліцензії',
      Limit: 'Ліміт',
      'Limit balance': 'Баланс ліміту',
      'Limit fact': 'Факт ліміту',
      'Limit plan': 'План ліміту',
      'Limit saved': 'Ліміт збережено',
      'Limit saved.': 'Ліміт збережено.',
      'Limit value': 'Граничний показник',
      Limits: 'Ліміти',
      'Line Up': 'Line Up',
      'Line up': 'Line up',
      Lineup: 'Lineup',
      'Link with companies': "Зв'язок з компаніями",
      'Link with group': "Зв'язок з групою",
      'Linked invoice': 'Підключений інвойс',
      'List filters': 'Список фільтрів',
      'List of actions in a dropdown menu': 'Список дій у розгорнутому меню',
      'List of business units empty': 'Список бізнес-юнітів порожній',
      Lists: 'Довідники',
      Load: 'Завантаження',
      'Load rate': 'Норма завантаження',
      'Load source': 'Джерело',
      Loaded: 'Завантажено',
      'Loaded volume': "Завантажений об'єм",
      'Loaded/Discharged': 'Завантажено/розвантажено',
      Loading: 'Завантаження',
      'Loading (date)': 'Завантаження (дата)',
      'Loading / Destination': 'Завантаження / Розвантаження',
      'Loading 2nd port': 'Завантаження 2-го порту',
      'Loading Port': 'Порт завантаження',
      'Loading address': 'Адреса завантаження',
      'Loading addresses': 'Адреса завантаження',
      'Loading date': 'Дата завантаження',
      'Loading date from': 'Дата завантаження від',
      'Loading date to': 'Дата завантаження до',
      'Loading date was changed': 'Дата завантаження була змінена',
      'Loading dem/dis': 'Завантаження dem/dis',
      'Loading final port': 'Завантаження кінцевого порту',
      'Loading minus': 'Завантаження в мінус',
      'Loading option': 'Опція завантаження',
      'Loading plus': 'Завантаження в плюс',
      'Loading port': 'Порт завантаження',
      'Loading related objects': "Завантаження пов'язаних об'єктів",
      'Loading shortage (boarding losses)': 'Недостача при завантаженні (втрати при завантаженні)',
      'Loading/Destination': 'Завантаження/Розвантаження',
      Loadport: 'Порт завантаження',
      'Loads without buyer': 'Завантаження без покупців',
      Loan: 'Позика',
      'Loan agreement': 'Договір позики',
      'Loan amount': 'Сума позики',
      'Loan number': 'Номер позики',
      'Loan saved': 'Позику збережено',
      'Loan updated': 'Позику оновлено',
      Loans: 'Позики',
      Local: 'Локальна',
      'Local currency': 'Локальна валюта',
      Location: 'Розташування',
      'Log out': 'Вийти',
      Logistic: 'Логістика',
      'Logistic difference with invoice': 'Логістична різниця з інвойсом',
      "Logistic doesn't exist": 'Логістика не існує',
      'Logistic loaded': 'Логістика завантажено',
      'Logistic loading; date': 'Дата завантаження логістики',
      'Logistic purchase contract': 'Логістика закупівельного контракта',
      'Logistic purchase contracts': 'Логістика закупівельних контрактів',
      'Logistic sale contracts': 'Логістика продажні контракти',
      'Logistic sale export contracts': 'Логістика продажні експортні контракти',
      'Logistic season': 'Логістика сезон',
      'Logistic type': 'Тип логістики',
      'Logistic types': 'Типи логістики',
      'Logistic updated': 'Логістику оновлено',
      'Logistic volume': "Об'єм логістики",
      Logistics: 'Логістика',
      'Logistics Analytics': 'Аналітика логістики',
      'Logistics cost': 'Витрати логістики',
      'Logistics costs VAT': 'Витрати по логістиці ПДВ',
      'Logistics have already connected to bill of lading.':
        'Логістика вже підключена до коносаменту.',
      'Logistics losses volume': "Об'єм логістичних втрат",
      'Logistics mailing': 'Розсилка логістики',
      'Logistics updated!': 'Логістика оновлена!',
      'Logistics volume without CCD': "Об'єм логістики без МД",
      Long: 'Довгий',
      'Long lots': 'Довгі лоти',
      Longitude: 'Довгота',
      Loses: 'Втрати',
      Losses: 'Втрати',
      'Losses volume': "Втрачений об'єм",
      Lost: 'Втрата',
      Lots: 'Лоти',
      Low: 'Низький',
      'Loyalty Program': 'Програма лояльності',
      'Loyalty Programs': 'Програми лояльності',
      'Loyalty program': 'Програма лояльності',
      'Loyalty programs': 'Програми лояльності',
      'LoyaltyProgram saved.': 'Програма лояльності збережена.',
      MAIN: 'ОСНОВНИЙ',
      'MAIN APPROVERS': 'СУПЕР ПОГОДЖЕННЯ',
      'MAIN DIMENSIONS': 'ОСНОВНІ РОЗМІРИ',
      MAJORITY: 'БІЛЬШІСТЬ',
      MARGIN: 'МАРЖА',
      'MARGIN CONTROL': 'КОНТРОЛЬ МАРЖІ',
      'MARK TO MARKET': 'ОЦІНКА РИНКУ',
      MATCHED: 'ЗІСТАВЛЕНІ',
      MONTH: 'МІСЯЦЬ',
      'MONTH DIFF': 'РІЗНИЦЯ МІСЯЦІ',
      'MORE INFO': 'БІЛЬШЕ ІНФОРМАЦІЇ',
      MOVEMENT: 'ПЕРЕСУВАННЯ',
      'MT Equivalent': 'Еквівалент МТ',
      MTM: 'MTM',
      'MTM Inventory Valuation, $': 'Оцінка запасів MTM, $',
      'MTM P&L': 'MTM PnL',
      'MTM Price': 'Ціна MTM',
      'MTM price': 'Ціна MTM',
      'MTM price:': 'MTM ціна:',
      'MTM value': 'MTM вартість',
      'MTM, 1 MT/$': 'MTM, 1 MT/$',
      'MULTISELECT FILTERS': 'МУЛЬТИ-ФІЛЬТРИ',
      'MULTISELECT OPTIONS': 'МНОЖИННІ ВАРІАНТИ',
      Mail: 'Лист',
      'Mail preview': 'Попередній перегляд листа',
      'Mailing Invoices': 'Розсилка інвойсів',
      'Mailing Logistics': 'Розсилка логістики',
      'Mailing address': 'Адреса розсилки',
      Main: 'Головна',
      'Main company settings': 'Основні налаштування компанії',
      'Main contact': 'Основний контакт',
      'Main contact of company?': 'Основний контакт компанії?',
      'Main deal': 'Основна угода',
      'Main option': 'Основна опція',
      'Main program': 'Основна програма',
      'Main ticket': 'Основний тікет',
      'Make reassignment': 'Створити переуступку',
      'Make sure you filled out': 'Переконайтеся, що ви заповнили',
      'Make your choice': 'Зробіть свій вибір',
      'Make your choices': 'Зробіть свій вибір',
      Manage: 'Керувати',
      'Manage check-list': 'Управління чек-листом',
      'Manage costs/gains': 'Управління витрати/дохід',
      Manager: 'Менеджер',
      'Managing company': 'Управляюча компанія',
      March: 'Березень',
      Margin: 'Маржа',
      'Margin (Analytical - Contract)': 'Маржа ( Аналітичний - Контракт)',
      'Margin (Plan - Fact)': 'Маржа (План - Факт)',
      'Margin absolute': 'Маржа абсолютна',
      'Margin control': 'Контроль маржі',
      'Margin control ASC': 'Контроль маржі ASC',
      'Margin control DESC': 'Контроль маржі DESC',
      'Margin control created': 'Контроль маржі створено',
      'Margin per 1t': 'Маржа на 1т',
      'Margin relative': 'Відносна маржа',
      'Margin selector': 'Перемикач маржі',
      'Margin type from passports': 'Тип маржі з паспортів',
      'Margin with vat': 'Маржа з ПДВ',
      'Margin with vat return': 'Маржа з поверненням ПДВ',
      'Marine Cover Policy No.': 'Політика морського страхування №.',
      'Marine Traffic': 'MarineTraffic',
      'Marine traffic': 'MarineTraffic',
      Marinetraffic: 'MarineTraffic',
      'Mark to Market': 'Оцінка ринку',
      'Mark to market': 'Оцінка ринку',
      Market: 'Ринок',
      'Market price': 'Ринкова ціна',
      'Market price (MTM)': 'Ринкова ціна (MTM)',
      'Market price deleted': 'Ринкова ціна видалена',
      'Market price updated': 'Ринкова ціна оновлена',
      'Market price:': 'Ринкова ціна:',
      Marks: 'Позначки',
      'Master data': 'Довідники',
      'Matched P&L': 'Підібраний PnL',
      'Matched Positions': 'Підібрані позиції',
      'Matched objects:': "Підібрані об'єкти:",
      Maturity: 'Погашення',
      'Maturity date': 'Дата погашення',
      'Maturity date (plan)': 'Дата погашення (план)',
      Max: 'Макс',
      'Max alt': 'Макс',
      'Max amount': 'Максимальна сума',
      'Max bl date': 'Остання дата коносаменту',
      'Max purchase price at': 'Максимальна закупівельна ціна',
      'Maximum amount of one contract': 'Максимальна сума одного контракту',
      'Maximum quantity of one contract': "Максимальна об'єм одного контракту",
      May: 'Травень',
      Measurement: 'Вимірювання',
      Medium: 'Середній',
      Meet: 'Зустріч',
      Message: 'Повідомлення',
      'Message Template': 'Шаблон повідомлення',
      'Message type': 'Тип повідомлення',
      'Middle Name': 'По батькові',
      'Middle name': 'По батькові',
      Min: 'Мін',
      'Min alt': 'Мін',
      'Min amount': 'Мінімальна сума',
      'Min payment delay': 'Мінімальна відстрочка платежу',
      'Minimum batch of payment': 'Мінімальна сума платежу',
      'Minimum value is 0': 'Мінімальна вартість - 0',
      Mixed: 'Змішані',
      Mo: 'Пн',
      Model: 'Зразок',
      Monday: 'Понеділок',
      Month: 'Місяць',
      'Monthly plan': 'Місячний план',
      'More 90': 'Більше 90',
      Movement: 'Пересування',
      'Movement recorder': 'Реєст пересування',
      Movements: 'Пересування',
      'Mtm margin': 'Маржа mtm',
      Multi: 'Мульти',
      'Multi Contract document': 'Документи мультиконтракту',
      'Multi contracts': 'Мультиконтракти',
      'Multi tickets': 'Мультитікети',
      MultiServices: 'МультиПослуги',
      MultiTicket: 'Мультитікет',
      Multicontract: 'Мультиконтракт',
      'Multicontract No.': 'Мультиконтракт No.',
      'Multicontract custom status': 'Спеціальний статус мультиконтракту',
      'Multicontract deleted.': 'Мультиконтракт видалено.',
      "Multicontract doesn't exist": 'Мультиконтракт не існує',
      'Multicontract number': 'Номер мультиконтракту',
      'Multicontract passports': 'Паспорти мультиконтракту',
      'Multicontract saved.': 'Мультиконтракт збережено.',
      'Multicontract voyages': 'Рейси мультиконтракту',
      Multicontracts: 'Мультиконтракти',
      Multipassport: 'Мультипаспорт',
      'Multipassport contains several passports in itself.':
        'Мультипаспорт містить в собі кілька паспортів.',
      'Multipassport saved.': 'Мультипаспорт збережено.',
      Multipassports: 'Мультипаспорти',
      'Multipassports report': 'Звіт мультипаспорта',
      'Multiple choices': 'Кілька варіантів',
      'Multiple connection': 'Множинне з’єднання',
      'Multiple contracts': 'Мультиконтракти',
      'Multiply amount with rate': 'Помножте суму на ставку',
      'Multirequest saved.': 'Мультитікет збережено.',
      Multiticket: 'Мультитікет',
      Multitickets: 'Мультитікети',
      Multitiket: 'Мультитікет',
      'Multi{[{ $ctrl.multicontract.stage }]}': 'Multi{[{ $ctrl.multicontract.stage }]}',
      'Must include either a contract or a passport or a logistic, fill in at least one field.':
        'Необхідно вказати або контракт, або паспорт, або логістику, заповніть хоча б одне поле.',
      'My cabinet': 'Мій кабінет',
      NDF: 'NDF',
      NEW: 'НОВИЙ',
      'NEXT STEP': 'НАСТУПНИЙ КРОК',
      NO: 'NO',
      NUMBER: 'НОМЕР',
      Name: 'Назва',
      'Name of Buyer sale contract': 'Покупець продажного контракту',
      'Name of seller purchase contract': 'Постачальник закупівельного контракту',
      Navbar: 'Навігаційна панель',
      'Needs approval': 'Необхідне підтвердження',
      'Nested costs': 'Вкладені витрати',
      'Nested costs volume': "Об'єм вкладених витрат",
      'Nested volume': "Вкладений об'єм",
      'Net Closed': 'Net Closed',
      'Net P&L': 'Чистий PnL',
      'Net P&L MTM': 'Чистий PnL MTM',
      'Net buying price': 'Чиста закупівельна ціна',
      'Net closed P&L': 'Закритий PnL',
      'Net selling price': 'Чиста продажна ціна',
      Neutral: 'Нейтральний',
      New: 'Новий',
      'New - after creation of disbursement bill of lading. Process - partly connected to reassignment. Done - fully connected to reassignment or connected to offset.':
        "Новий - після створення видаткової накладної. У процесі - частково з'єднаний з переуступкою. Виконано - повністю з'єднаний до переуступки або заліку.",
      'New - after creation of reassignment. Process - reassignment partly connected to offset. Done - reassignment fully connected to offset.':
        "Новий - після створення переуступки. У процесі – переуступка частково пов'язана із заліком. Виконано - переуступка повністю пов'язана із заліком.",
      'New Name': 'Нова назва',
      'New Password': 'Новий пароль',
      'New Reservation': 'Нове бронювання',
      'New Stock Reserve Unit': 'Нова одиниця резерву запасів',
      'New Warehouse': 'Новий склад',
      'New collection': 'Нова конфігурації',
      'New costs report': 'Новий звіт про витрати',
      'New derivative': 'Новий дериватив',
      'New destination port name': 'Нова назва порту призначення',
      'New loading port name': 'Нова назва порту завантаження',
      'New month': 'Новий місяць',
      'New multicontract': 'Новий мультиконтракт',
      'New passport': 'Новий паспорт',
      'New tariff': 'Новий тариф',
      'New task': 'Нове завдання',
      'New template': 'Новий шаблон',
      'New title': 'Нова назва',
      'New vessel name': 'Нова назва судна',
      'New {[{ $root.user.settings.PASSPORT_TITLE }]}':
        'Новий {[{ $root.user.settings.PASSPORT_TITLE }]}',
      'News letters': 'Розсилки',
      NewsLetters: 'Розсилки',
      Newsletter: 'Розсилка',
      'Newsletter of invoices gives you a possibility to quickly form a letter with an\n                excel spreadsheet of all invoices (paid or not) and send it to a client or bank.\n                Also, you can attach all documents, related to the deal.':
        "Розсилка інвойсів дає можливість швидко сформувати лист із таблицею Excel \n                всіх інвойсів (сплачених чи ні) та відправити його контрагенту або банку. \n                Також, ви можете додати всі документи, пов'язані з угодою.",
      'Newsletter of logistics gives you a possibility to quickly form a letter with an\n                excel spreadsheet of all vehicles/containers and send it to a client or bank. Also,\n                you can attach full package documents, related to the deal.':
        "Розсилка логістики дає можливість швидко сформувати лист з таблицею Excel \n                всіх транспортних засобів / контейнерів і відправити його контрагенту або банку. \n                Крім того, ви можете додати всі документи, пов'язані з угодою.",
      Newsletters: 'Розсилки',
      'Newsletters by invoices': 'Розсилки за інвойсами',
      'Newsletters by logistics': 'Розсилки за логістикою',
      Next: 'Наступний(a)',
      'Next contact': 'Наступний контакт',
      'Next step': 'Наступний крок',
      'Next steps': 'Наступні кроки',
      'Next update': 'Наступне оновлення',
      No: 'Ні',
      'No additional agreements yet': 'Додаткових угод немає',
      'No bank accounts yet': 'Ще немає банківських рахунків',
      'No bill of ladings': 'Немає коносамента',
      'No charges': 'Без витрат',
      'No contracts': 'Немає контрактів',
      'No currency exchange rate found': 'Курс обміну валюти не знайдено',
      'No data yet': 'Даних ще немає',
      'No emails yet': 'Немає emails',
      'No entries yet': 'Записів ще немає',
      'No logistic to update': 'Немає логістики для оновлення',
      'No one logistic selected': 'Жодної логістики не вибрано',
      'No open position': 'Немає відкритої позиції',
      'No open position in passport': 'В паспорті немає відкритої позиції',
      'No payments provided yet': 'Поки що немає платежів',
      'No requests': 'Немає запитів',
      'No response': 'Немає відповіді',
      'No sign': 'Немає підпису',
      'No similar entries yet': 'Схожих записів ще немає',
      'No templates yet': 'Шаблонів ще немає',
      'No, take me back': 'Ні, поверни мене назад',
      'Normal write-off volume, mt': 'Нормальний обсяг списання, т',
      'Not contacted': "Не зв'язався",
      'Not executed': 'Не виконано',
      'Not fixed': 'Не фіксовано',
      'Not fixed lots': 'Не фіксовані лоти',
      'Not fixed tonnes': 'Нефіксовані тонни',
      'Not hedged': 'Не хеджований',
      'Not insured': 'Без страхування',
      'Not invoiced': 'Не виставлено інвойс',
      'Not invoiced cargo': 'Не проінвойсований вантаж',
      'Not paid': 'Не сплачено',
      'Not pay': 'Не платити',
      'Not saved field': 'Не збережене поле',
      'Not selected': 'Не вибрано',
      'Not signed': 'Не підписано',
      'Not used in P/L and totals calculations':
        'Не використовується в розрахунках P&L та підсумків',
      Note: 'Примітка',
      'Note one': 'Примітка 1',
      'Note two': 'Примітка 2',
      Notes: 'Примітки',
      'Notification date': 'Дата сповіщення',
      'Notification time': 'Час повідомлення',
      Notifications: 'Сповіщення',
      Notified: 'Повідомлено',
      'Notify party': 'Повідомлення про партію',
      'Notify party 2': 'Повідомляюча сторона 2',
      'Notify party 3': 'Повідомляюча сторона 3',
      November: 'Листопад',
      Number: 'Номер',
      'Number (#)': 'Номер (#)',
      'Number of Purchase contract': 'Номер закупівельного контракту',
      'Number of Sale contract': 'Номер продажного контракту',
      'Number of containers': 'Кількість контейнерів',
      'Number of contracts': 'Кількість контрактів',
      'Number of days before notification': 'Кількість днів до повідомлення',
      'Number of days before notification about power of attorney expiration':
        'Кількість днів до повідомлення про закінчення терміну дії довіреності',
      'Number of days for payment': 'Кількість днів для оплати',
      'Number of days to issue an invoice': 'Кількість днів до видачі інвойсу',
      OPEN: 'ВІДКРИТИ',
      OPTIONS: 'ОПЦІЇ',
      ORDER: 'ЗАМОВЛЕННЯ',
      OTHER: 'ІНШЕ',
      OUT: 'ВИХІД',
      OUTGOING: 'ВИХІДНИЙ',
      Object: "Об'єкт",
      'Object deleted': "Об' єкт видалено",
      'Object has been added': 'Об’єкт додано',
      'Object has been removed': "Об' єкт видалено",
      'Object name (type)': "Назва об'єкта (тип)",
      'Object removed': "Об' єкт видалено",
      'Object saved': "Об' єкт збережено",
      'Object type': 'Тип обʼєкту',
      'Object updated': "Об'єкт оновлено",
      October: 'Жовтень',
      Offer: 'Оффер',
      Offers: 'Оффери',
      Offset: 'Залік',
      'Offset contracts': 'Офсетні контракти',
      'Offset date': 'Дата заліку',
      "Offset doesn't exist": 'Заліку не існує',
      'Offset list': 'Залік список',
      'Offset number': 'Залік номер',
      'Offset saved': 'Залік збережено',
      'Offset through reassignment date': 'Залік з переуступки',
      Offsets: 'Заліки',
      'Old Cash Flow': 'Кеш-фло (старий звіт)',
      'On Road Volume': "Об'єм в дорозі",
      'On first event': 'За першою подією',
      'On road': 'В дорозі',
      'On road amount': 'Товар у дорозі (сума)',
      'One full cargo': 'Один повний вантаж',
      'One of the vessel voyages, which will execute this deal':
        'Один із рейсів судна, який виконає цю угоду',
      'Only complete transactions': 'Тільки завершені транзакції',
      'Only my updates': 'Тільки мої оновлення',
      'Only users with permission secret_invoice will be able see this invoice':
        'Тільки користувачі із спеціальним дозволом можуть побачити цей інвойс',
      'Only volume': "Тільки об'єм",
      Open: 'Відкритий',
      'Open P&L': 'ВІдкритий PnL',
      'Open Positions': 'Відкриті позиції',
      'Open amount balance': 'Баланс відкритої суми',
      'Open balance': 'Відкритий баланс',
      'Open balance (forward)': 'Відкритий баланс (форвард)',
      'Open balance (spot)': 'Відкритий баланс (спот)',
      'Open balance amount': 'Сума відкритого балансу',
      'Open balance of all contracts': 'Відкритий баланс всіх контрактів',
      'Open contracts': 'Відкриті контракти',
      'Open position': 'Відкрита позиція',
      'Open profit and loss': 'Відкритий прибуток і збиток',
      'Open quote': 'Відкрита пропозиція',
      'Open/closed': 'Відкрито/закрито',
      Operation: 'Операція',
      'Operation amount': 'Сума операції',
      'Operation deleted': 'Операцію видалено',
      'Operation saved': 'Операцію збережено',
      'Operation type': 'Тип операції',
      'Operational profit and loss': 'Операційні прибутки та збитки',
      Operations: 'Операції',
      Opposite: 'Протилежний',
      'Opposite basis': 'Протилежний базис',
      'Opposite contract costs': 'Зустрічні витрати контракту',
      'Opposite costs': 'Зустрічні витрати',
      'Opposite counterparties': 'Протилежні контрагенти',
      'Opposite derivative': 'Зустрічний дериватив',
      'Opposite details': 'Протилежні деталі',
      'Opposite freight': 'Зустрічний фрахт',
      'Opposite passport basis costs': 'Зустрічні витрати паспорта',
      'Opposite passport contract costs': 'Зустрічні витрати контракту паспорта',
      'Opposite price': 'Зустрічна ціна',
      'Opposite value': 'Протилежне значення',
      Option: 'Опціон',
      Order: 'Порядок',
      'Order by': 'Сортувати за',
      'Order sale contract': 'Ордер продажних контрактів',
      Ordering: 'Впорядкування',
      Organization: 'Організація',
      Origin: 'Походження',
      Original: 'Походження',
      'Original available': 'Оригінал доступний',
      'Originals received': 'Оригінали отримані',
      'Origins of crop': 'Походження культури',
      Other: 'Інше',
      'Other Activity': 'Інше призначення',
      'Other activities': 'Інші види діяльності',
      'Other activity': 'Інше призначення',
      'Our Counterparty': 'Наш контрагент',
      'Our amount': 'Наша сума',
      Out: 'Вихід',
      'Out date': 'Дата відправки',
      'Out from country': 'З країни',
      'Out number': 'Вихідний номер',
      'Out of approval': 'Відсутнє затвердження',
      Outgoing: 'Вихідний',
      'Outgoing (date)': 'Вихідний (дата)',
      'Outgoing Volume': "Вихідний об'єм",
      'Outgoing balance': 'Вихідний баланс',
      'Outgoing invoice status': 'Статус вихідного інвойсу',
      'Outgoing invoices': 'Вихідні інвойси',
      'Outgoing payments': 'Вихідні платежі',
      'Outgoing reserve': 'Вихідний резерв',
      'Outgoing/Incoming': 'Вихідні/Вхідні',
      'Outstanding payment': 'Прострочено дату платежу',
      Overdue: 'Прострочено',
      Overpaid: 'Переплата',
      'Overpaid directions': 'Напрямки переплат',
      Overpaids: 'Переплати',
      Own: 'Власний',
      'Own costs': 'Власні витрати',
      Owner: 'Власник',
      'Owner bank': 'Банк-власник',
      'Owner bank account': 'Банківський рахунок власника',
      'Owner equals Disponent': 'Власник є Диспонент',
      'Owner info': 'Інформація про власника',
      'Owner name': 'Ім’я власника',
      "Owner's bank account": 'Банківський рахунок власника',
      Owners: 'Власники',
      'P&L': 'PnL',
      'P&L Consolidated': 'PnL консолідований',
      'P&L MTM': 'PnL MTM',
      'P&L MTM (cts/bu)': 'PnL MTM (cts/bu)',
      'P&L MTM per one lot': 'PnL MTM за одну партію',
      'P&L MTM, $': 'PnL MTM, usd',
      'P&L books': 'Бухгалтерський PnL',
      'P&L details': 'PnL деталі',
      'P&L distribution': 'Розподіл PnL',
      'P&L per passport': 'PnL за паспортом',
      'P/L': 'PnL',
      PAID: 'СПЛАЧЕНО',
      'PASSPORT COSTS': 'ВИТРАТИ ПАСПОРТА',
      PASSPORTS: 'ПАСПОРТИ',
      PAYMENT: 'ОПЛАТА',
      'PAYMENT CONDITIONS': 'УМОВИ ОПЛАТИ',
      'PAYMENT ORDERS': 'ПЛАТІЖНІ ДОРУЧЕННЯ',
      PAYMENTS: 'ОПЛАТИ',
      PCD: 'PCD',
      'PCD execution': 'PCD виконання',
      'PCD volume': "PCD об'єм",
      PDF: 'PDF',
      'PICK DYNAMIC RANGE': 'ДИНАМІЧНИЙ ДІАПАЗОН',
      PLAN: 'ПЛАН',
      PLANNED: 'ПЛАН',
      PNL: 'PNL',
      'PNL report': 'Звіт PNL',
      'PO number': 'Номер замовлення на поставку',
      POD: 'Порт доставки',
      'POD under way': 'POD в процесі',
      POL: 'Порт відправки',
      'POL under way': 'POL в процесі',
      PORTS: 'ПОРТИ',
      POSITIONS: 'ПОЗИЦІЇ',
      'POTENTIAL BUYERS': 'ПОТЕНЦІЙНІ ПОКУПЦІ',
      'POTENTIAL SUPPLIERS': 'ПОТЕНЦІЙНІ ПОСТАЧАЛЬНИКИ',
      PRICE: 'ЦІНА',
      PRODUCT: 'ТОВАР',
      PROPERTIES: 'ПОКАЗНИКИ',
      PURCHASE: 'ЗАКУПІВЛЯ',
      'PURCHASE ASC': 'ЗАКУПІВЛЯ ASC',
      'PURCHASE CONTRACTS': 'ЗАКУПІВЕЛЬНІ КОНТРАКТИ',
      'PURCHASE COSTS': 'ВИТРАТИ ЗАКУПІВЛІ',
      'PURCHASE DESC': 'ЗАКУПІВЛЯ DESC',
      'PURCHASE PLANS': 'ПЛАНИ ЗАКУПІВЛІ',
      'PURCHASE TICKETS': 'ЗАКУПІВЛЬНІ ТІКЕТИ',
      Packing: 'Упаковка',
      'Packing List': 'Пакувальний лист',
      'Packing is too large number, less than 6 digits required (3 for integer, 3 for decimal).':
        'У упаковці занадто велике число, потрібно вказати менше 6 цифр (3 для цілого числа, 3 для десяткового).',
      'Page filter config': 'Конфігурація фільтра сторінки',
      'Page scale': 'Масштаб сторінки',
      'Page size': 'Розмір сторінки',
      'Page url': 'Посилання сторінки',
      Paid: 'Оплачено',
      'Paid (bal)': 'Сплачено (баланс)',
      'Paid Cargo': 'Сплачений Вантаж',
      'Paid amount': 'Сума сплачена',
      'Paid date': 'Дата оплати',
      'Paid volume': "Сплачений об'єм",
      'Paid volume (t)': "Сплачений об'єм (т)",
      Paper: 'Paper',
      'Paper trade': 'Paper Trading',
      Parameters: 'Налаштування',
      Parent: '«Батьківська» культура',
      'Parent analytic commodity': 'Батьківський аналітичний товар',
      'Parent charge': 'Батьківська витрата',
      'Parent commodity': 'Батьківський товар',
      'Parent indicator price': 'Батьківська ціна індикатора',
      'Parent reassignment': 'Джерело переуступки',
      'Parse this quote': 'Спарсить це котирування',
      Parsing: 'Парсинг',
      'Parsing config template removed': 'Шаблон парсингу котирувань видалено',
      'Parsing enabled': 'Парсинг увімкнено',
      'Partial executed': 'Виконано частково',
      'Partial shipment allowed': 'Дозволяється часткове відвантаження',
      Passport: 'Паспорт',
      'Passport BU': 'БЮ паспорту',
      'Passport P&L': 'Паспорт PnL',
      'Passport and/or contracts have different currencies':
        'Паспорт та/або контракти мають різні валюти',
      'Passport aspiration': 'Аспірація паспорта',
      'Passport basis costs': 'Витрати паспорта',
      'Passport business date': 'Бізнес дата паспорта',
      'Passport business date month': 'Місяць бізнесової дати паспорта',
      'Passport business date updated': 'Бізнес дату паспорта оновлено',
      'Passport business date year': 'Рік бізнесової дати паспорта',
      'Passport charge': 'Витрати паспорта',
      'Passport conclusion date': 'Дата укладання паспорта',
      'Passport contract': 'Паспорт контракту',
      'Passport contract costs': 'Витрати контракту паспорта',
      'Passport costs': 'Витрати паспорта',
      'Passport date': 'Дата паспорта',
      'Passport destinations': 'Призначення паспорта',
      "Passport doesn't exist": 'Паспорта не існує',
      'Passport expiration date': 'Термін дії паспорта',
      'Passport filled in  manually': 'Паспорт заповнено вручну',
      'Passport for connect': "Паспорт для з'єднання",
      'Passport have no export contract or more then one':
        'Паспорт не має експортного контракту або має більше одного',
      'Passport have no sale contract or more then one':
        'У паспорта немає продажного контракту або їх більше, ніж один',
      'Passport info': 'Інформація про паспорт',
      'Passport operation': 'Операція паспорта',
      'Passport or contract date': 'Паспорт або дата контракту',
      'Passport quantity': "Об'єм паспорта",
      'Passport removed': 'Паспорт видалено',
      'Passport saved': 'Паспорт збережено',
      'Passport status': 'Статус паспорта',
      'Passport status updated': 'Статус паспорта оновлено',
      'Passport title': 'Назва паспорта',
      'Passport totals': 'Всього по паспорту',
      'Passport updated': 'Паспорт оновлено',
      'Passport updated.': 'Паспорт оновлено.',
      'Passport volume updated': "Об'єм паспорта оновлений",
      'Passport. Completed if contract is attached to passports on full volume':
        "Паспорт. Завершено, якщо контракт додається до паспортів в повному об'ємі",
      Passport_connected: "Паспорт з'єднано",
      'Passport`s BU': 'БЮ паспорта',
      Passports: 'Паспорти',
      'Passports (contract and direct)': 'Паспорти (контрактні та прямі)',
      'Passports Analytics': 'Аналітика паспортів',
      'Passports Profit and Loss': 'PnL паспортів',
      'Passports costs': 'Витрати паспортів',
      'Passports created.': 'Створено паспорти.',
      'Passports position': 'Паспорт позиція',
      'Passports report': 'Звіт про паспорти',
      Password: 'Пароль',
      'Paste a hyperlink to DHL here to track the status of delivery':
        'Вставте гіперпосилання до DHL, щоб відстежувати статус доставки',
      Pay: 'Сплата',
      'Pay now': 'Сплатити зараз',
      Payables: 'Кредиторська заборгованість',
      Payment: 'Платіж',
      'Payment Bank name': 'Назва платіжного банку',
      'Payment Fact': 'Факт оплати',
      'Payment Info': 'Інформація про оплату',
      'Payment Plan': 'План оплат',
      'Payment Plans': 'Плани оплат',
      'Payment amount': 'Сума оплат',
      'Payment bank account': 'Банківський рахунок платежу',
      'Payment bank name': 'Назва платіжного банку',
      'Payment condition': 'Умова оплати',
      'Payment condition options': 'Опція умови оплати',
      'Payment conditions': 'Умови оплати',
      'Payment conditions (%)': 'Умови оплати (%)',
      'Payment conditions (description)': 'Умови оплати (опис)',
      'Payment conditions auto (%)': 'Умови оплати (авто, %)',
      'Payment conditions option': 'Опція умов оплати',
      'Payment conditions options': 'Опції умов оплати',
      'Payment confirmation': 'Підтвердження оплати',
      'Payment date': 'Дата оплати',
      'Payment delays': 'Затримки платежів',
      'Payment events': 'Платіжні події',
      'Payment info': 'Інформація про оплату',
      'Payment info saved': 'Платіжну інформацію збережено',
      'Payment plan': 'План оплати',
      'Payment plan creation': 'Створення плану оплати',
      'Payment plan removed': 'План оплати видалено',
      'Payment plan saved': 'План оплати збережено',
      'Payment plans': 'Плани оплат',
      'Payment removed': 'Оплату видалено',
      'Payment saved': 'Оплату збережено',
      'Payment status': 'Статус оплати',
      'Payment statuses': 'Статуси платежів',
      'Payment stock (t)': 'Заборгованість за відвантаження (т)',
      'Payment stock amount': 'Сума оплат залишок',
      'Payment successfully created': 'Оплату успішно створено',
      'Payment terms': 'Терміни оплати',
      'Payment updated': 'Оплату оновлено',
      Payments: 'Платежі',
      'Payments currency exchange': 'Обмінний курс оплати',
      'Payments margin': 'Маржа платежів',
      'Payments plans': 'Плани оплат',
      'Payments were created': 'Оплата створено',
      Penalties: 'Штрафи',
      Pending: 'В очікуванні',
      'Pending confirmation': 'Очікує підтвердження',
      'Per 1 t': 'За 1 т',
      'Per t:': 'За 1 т:',
      'Percent conditions': 'Умови відсоток',
      'Percent, %': 'Відсоток, %',
      Percentage: 'Відсоток',
      'Percentage, %': 'Відсоток, %',
      Period: 'Період',
      'Period of detalization': 'Період деталізації',
      'Period of execution': 'Період виконання',
      'Period totals data': 'Загалом за період',
      'Periodic customs declaration': 'Періодична митна декларація',
      'Periodic customs declarations': 'Періодичні митні декларації',
      Permissions: 'Права',
      Person: 'Співробітник',
      'Person - main contact of the company': 'Основний контакт компанії',
      'Person removed': 'Контакт видалено',
      'Person saved': 'Контакт збережено',
      'Person signatory eng': 'Підписант (англ)',
      'Person updated': 'Контакт оновлено',
      Personal: 'Особисті',
      'Personal settings': 'Персональні налаштування',
      Persons: 'Контакти',
      Phone: 'Телефон',
      'Phone (additional)': 'Телефон (додатковий)',
      'Phone add': 'Додані телефони',
      'Phone additional': 'Додатковий телефон',
      Physical: 'Фізична',
      'Physical + Derivatives margin': 'Фізична маржа та деривативи',
      'Physical MTM': 'Фізична МТМ',
      'Physical margin': 'Фізична маржа',
      'Physical per T': 'Фізичний за Т',
      'Physical position': 'Фізична позиція',
      'Physical position + MTM': 'Фізична позиція + MTM',
      'Physical position by months': 'Фізична позиція за місяцями',
      'Pick a bank account': 'Виберіть банківський рахунок',
      'Pick a borrower that will be bound to this loan':
        "Виберіть позичальника, якого буде пов'язано з цією позикою",
      'Pick a counterparty that will be bound to this invoice':
        "Виберіть контрагента, який буде пов'язаний з цим інвойсом",
      'Pick a currency': 'Виберіть валюту',
      'Pick a date': 'Виберіть дату',
      'Pick a deadline': 'Виберіть дедлайн',
      'Pick a distributor that will be bound to this offset':
        "Виберіть дистриб'ютор, який буде пов'язаний з цим заліком",
      'Pick a distributor that will be bound to this reassignment':
        "Виберіть дистриб'ютора, який буде пов'язаний з цією переуступкою",
      'Pick a lender that will be bound to this loan':
        "Виберіть кредитора, якого буде пов'язаний з цією позикою",
      'Pick a loan agreement': 'Виберіть договір позики',
      'Pick a time': 'Виберіть час',
      'Pick a voyage to which this bill of lading is related too':
        'Виберіть рейс, до якого також відноситься цей коносамент',
      'Pick accounts': 'Виберіть рахунки',
      'Pick an owner that will be bound to this reassignment':
        "Виберіть власника, який буде пов'язаний з цією переуступкою",
      'Pick currency in which you want to convert USD':
        'Оберіть валюту, яку бажаєте конвертувати в USD',
      'Pick exchange rate if you want to convert invoice value to USD by certain rate':
        'Виберіть обмінний курс, якщо ви хочете конвертувати суму інвойсу в USD за певним курсом',
      'Pick from 0 to 100 to set up Daily fines rate':
        'Виберіть від 0 до 100, щоб налаштувати щоденну кількість штрафів',
      'Pick from 0 to 100 to set up interest rate':
        'Виберіть від 0 до 100 для встановлення процентної ставки',
      'Pick from 0 to 100 to set up loan percentage':
        'Виберіть від 0 до 100 для встановлення відсотку кредиту',
      'Pick from 0 to 100 to set up prepay': 'Виберіть від 0 до 100, щоб встановити передоплату',
      'Pick from 0 to 200': 'Виберіть від 0 до 200',
      'Pick from 0 to 200 to increase or decrease the amount. Less than 100 - decreases, more than 100 - increases':
        'Виберіть від 0 до 200 для збільшення або зменшення суми. Значення менше 100 зменшує суму, значення більше 100 збільшує суму',
      'Pick from 0 to 200, use this or discount amount':
        'Виберіть від 0 до 200, використовуйте це або суму дисконта',
      'Pick here a date, when you expect to have this charge':
        'Виберіть тут дату, коли ви очікуєте отримати цю плату',
      'Pick here a date, when you got first contact with this company':
        'Виберіть дату, коли Ви вперше контактували з цією компанією',
      'Pick here a date, when you need to contact this company in future':
        "Виберіть дату, коли потрібно зв'язатися з цією компанією в майбутньому",
      'Pick one of the options to define the status of relations with the company':
        'Виберіть одну із опцій, щоб визначити статус відносин з компанією',
      'Pick one of the options to define the status of the company':
        'Виберіть один із варіантів визначення статусу компанії',
      'Pick one of the options to define the type of the company':
        'Оберіть один із варіантів, щоб визначити тип компанії',
      'Pick the short name for counterparty. For convenience try to avoid naming the form of ownership, e.g. LLC  etc. You can appoint it in the full name. Good example for short name is COMPANY NAME without commas, etc. It would helpful you to find this company within search':
        'Виберіть скорочену назву контрагента. Для зручності намагайтеся уникати назви форми власності (наприклад, ТОВ, тощо). Ви можете вказати це в Повній назві. Гарний приклад короткої назви - НАЗВА КОМПАНІЇ без крапок, дужок та ком. Це допоможе Вам знайти цю компанію в пошуку',
      Place: 'Місце',
      'Place of destination': 'Місце призначення',
      'Place of storage': 'Місце зберігання',
      Places: 'Місця',
      Plan: 'План',
      'Plan VS contracted': 'План VS законтрактовано',
      'Plan VS delivered': 'План VS доставлено',
      'Plan amount': 'Сума плану',
      'Plan costs': 'План витрат',
      'Plan created': 'План створено',
      "Plan doesn't exist": 'План не існує',
      'Plan execution range': 'Норма плану виконання',
      'Plan payments': 'План оплат',
      'Plan removed': 'План видалено',
      'Plan value': 'Плановий показник',
      Planned: 'Заплановано',
      'Planned Expenses': 'Плановані витрати',
      'Planned balance (t)': 'Плановий баланс (т)',
      'Planned balance amount': 'Планова сума балансу',
      'Planned costs': 'Планові витрати',
      'Planned distributor': "Планований дистриб'ютор",
      'Planned distributors': "Планові дистриб'ютори",
      Planning: 'Планування',
      Plans: 'Плани',
      'Please select a contract': 'Будь ласка, виберіть контракт',
      'Please select a contract from the left': 'Будь ласка, виберіть контракт зліва',
      'Please select at least one BL.': 'Будь ласка, виберіть принаймні один коносамент.',
      'Please select at least one Disbursement Bill of Lading':
        'Будь ласка, виберіть принаймні одну витратну накладну',
      'Please select at least one invoice': 'Будь ласка, виберіть принаймні один інвойс',
      'Please select at least one logistic': 'Будь ласка, виберіть хоча б одну логістику',
      'Please select at least one object': "Будь ласка, виберіть хоча б один об'єкт",
      'Please set date.': 'Будь ласка, встановіть дату.',
      'Please, first apply filters for export data':
        'Будь ласка, спершу застосуйте фільтри для експорту даних',
      'Please, first set period for get report data':
        'Будь ласка, спочатку встановіть період для отримання даних звіту.',
      'Please, not the all the values related to contracts and internal objects are\n                    corrected according to the volume of allocation of certain contract.':
        'Будь ласка, зверніть увагу, що не всі значення, що стосуються контрактів та внутрішніх об’єктів, були виправлені відповідно до обсягу розподілу конкретного контракту.',
      'Please, select export contract': 'Будь ласка, виберіть контракт на експорт',
      'Please, select purchase contract': 'Будь ласка, виберіть закупівельний контракт',
      'Please, select purchase prepay invoice':
        'Будь ласка, виберіть передплатний інвойс на закупівлю',
      'Please, select sale contract': 'Будь ласка, виберіть продажний контракт',
      'Please, select sale export contract filter':
        'Будь ласка, виберіть продажний експортний контракт',
      'Please, select sale prepay invoice': 'Будь ласка, виберіть передплатний інвойс на продаж',
      Pledge: 'Застава',
      'Pmt date': 'Дата оплати',
      PnL: 'PnL',
      Port: 'Порт',
      'Port of Discharge': 'Порт розвантаження',
      'Port of Loading': 'Порт завантаження',
      'Port of destination': 'Порт призначення',
      'Port of discharge': 'Порт розвантаження',
      'Port of loading': 'Порт завантаження',
      Ports: 'Порти',
      'Ports list': 'Список портів',
      'Ports list eng': 'Список портів (англ)',
      'Ports list short name': 'Скорочена назва списку портів',
      'Ports opposite': 'Порти зустрічні',
      Position: 'Позиція',
      'Position + MTM': 'Позиція + MTM',
      'Position P&L + MTM': 'Позиція PnL + MTM',
      'Position conclusion date': 'Дата укладання позиції',
      'Position documents': 'Документи позиції',
      'Position excluded': 'Позиція виключена',
      'Position passport business date': 'Бізнес-дата позиції паспорта',
      'Position payment status': 'Статус оплати позиції',
      'Position saved': 'Позицію збережено',
      'Position updated': 'Позицію оновлено',
      Positions: 'Позиції',
      'Positions info': 'Інформація позиції',
      'Positions signature': 'Підписант позиції',
      'Positon of person who signs documents by this company':
        'Позиція особи, яка підписує документи цієї компанії',
      Potencial: 'Потенційний',
      Potential: 'Потенціал',
      'Potential crops': 'Потенційні товари',
      'Potential purchase': 'Потенційна закупівля',
      Potentials: 'Потенціали',
      'Predictions order': 'Порядок замовлень',
      'Preliminary loaded': 'Попередньо завантажено',
      Premium: 'Преміум',
      'Premium balance': 'Преміум баланс',
      'Premium currency': 'Преміум валюта',
      'Premium for delivered goods, Plan': 'Премія за доставлені товари, План',
      'Premium price': 'Преміум ціна',
      'Premium price opposite': 'Преміум ціна зустрічна',
      'Premium value': 'Преміум вартість',
      Prepay: 'Передоплата',
      'Prepay - amount of money, will be paid by contract, before final delivery,  Balance of money, will be paid after end of delivery':
        'Передоплата - сума грошей, яка буде сплачена за контрактом, до кінцевої доставки. Баланс – сума, яка буде виплачена після закінчення доставки',
      'Prepay - balance percent': 'Передоплата - відсоток балансу',
      'Prepay invoice': 'Інвойс передплати',
      'Prepay payment': 'Попередня оплата',
      'Prepay payment dates': 'Дати передплати',
      'Prepay volume': "Передплачений об'єм",
      'Prepayment invoice': 'Інвойс передплати',
      'Preset Set Saved': 'Шаблон збережено',
      'Preset Set Updated': 'Шаблон оновлено',
      'Preset Set removed': 'Шаблон видалено',
      Preshipment: 'Попереднє відвантаження',
      'Press to control': 'Натисніть контроль',
      'Press to show more': 'Натисніть, щоб показати більше',
      'Press ↵ ENTER to': 'Натисніть ↵ ENTER',
      Preview: 'Попередній перегляд',
      'Previous Price': 'Попередня ціна',
      Price: 'Ціна',
      'Price (with costs, with VAT)': 'Ціна (з витратами, з ПДВ)',
      'Price (with costs, without VAT)': 'Ціна (з витратами, без ПДВ)',
      'Price (with costs, without VAT), $': 'Ціна (з витратами, без ПДВ), $',
      'Price / t': 'Ціна / т',
      'Price 1t': 'Ціна за 1т',
      'Price VAT': 'Ціна ПДВ',
      'Price create': 'Ціна створена',
      'Price currency': 'Валюта ціни',
      'Price diff': 'Різниця ціни',
      'Price discount': 'Дисконт на ціну',
      'Price estimated:': 'Прогнозована ціна:',
      'Price for measurement': 'Ціна на вимірювання',
      'Price for measurement / kg': 'Ціна за вимірювання / кг',
      'Price in USD': 'Ціна в USD',
      'Price in USD words': 'Ціни в USD (прописом)',
      'Price indicative': 'Ціна орієнтовна',
      'Price no VAT': 'Ціна без ПДВ',
      'Price of premium': 'Преміум ціна',
      'Price opposite': 'Зустрічна ціна',
      'Price options': 'Опції цін',
      'Price per one': 'Ціна за одиницю',
      'Price per piece': 'Ціна за штуку',
      'Price per t': 'Ціна за т',
      'Price premium': 'Преміум ціна',
      'Price premium opposite': 'Преміум ціна зустрічного контракту',
      'Price with VAT': 'Ціна з ПДВ',
      'Price with discount': 'Ціна з дисконтом',
      'Price without VAT': 'Ціна без ПДВ',
      'Price without Vat': 'Ціна без ПДВ',
      Prices: 'Ціни',
      Print: 'Друкувати',
      'Print a contract': 'Роздрукувати контракт',
      'Print a document': 'Надрукуйте документ',
      'Print an invoice': 'Роздрукувати інвойс',
      'Print and sign': 'Роздрукувати та підписати',
      Priority: 'Пріоритет',
      Process: 'Процес',
      Processed: 'Оброблено',
      Processing: 'Обробка',
      'Processing...': 'Обробка….',
      Producer: 'Виробник',
      Product: 'Товар',
      'Production Year': 'Рік виробництва',
      Products: 'Продукти',
      Profile: 'Профіль',
      Profit: 'Прибуток',
      'Profit and Loss': 'PnL',
      'Profit and loss': 'PnL',
      Profitability: 'Прибутковість',
      Proforma: 'Проформа',
      'Proforma Invoice': 'Проформа інвойс',
      'Program type': 'Тип програми',
      Progress: 'Прогрес',
      'Project cost, $ with VAT': 'Вартість проекту, usd з ПДВ',
      'Project cost, with VAT': 'Вартість проекту, з ПДВ',
      'Project status': 'Стан проекту',
      'Projected rate': 'Очікувана ставка',
      'Projects Status Detailed Summary': 'Статус проектів Детальний підсумок',
      Properties: 'Показники',
      Property: 'Власність',
      'Property "positions volume by execution" is disabled.':
        "Властивість «об'єм позицій за виконанням» відключена.",
      'Property alternative': 'Властивість альтернативна',
      Provider: 'Постачальник',
      Purcahse: 'Закупівля',
      Purchase: 'Закупівля',
      'Purchase Contract': 'Закупівельний контракт',
      'Purchase Contract #': 'Закупівельний контракт №',
      'Purchase Currencies': 'Закупівля валюти',
      'Purchase KPI': 'Закупівля KPI',
      'Purchase Plan': 'План закупівлі',
      'Purchase amount': 'Сумма закупівлі',
      'Purchase author': 'Закупівля автор',
      'Purchase business unit': 'Бізнес-юніт закупівлі',
      'Purchase contract': 'Закупівельний контракт',
      'Purchase contract #': 'Закупівельний контракт №',
      'Purchase contract author': 'Закупівельний контракт автор',
      'Purchase contract client': 'Контрагент закупівельного контракту',
      'Purchase contract deal type': 'Тип закупівельного контракту',
      'Purchase contract owner': 'Власник закупівельного контракту',
      'Purchase contract passports': 'Паспорт закупівельного контракту',
      'Purchase contract payment conditions option': 'Опція умов оплати закупівельного контракту',
      'Purchase contract status': 'Статус закупівельного контракту',
      'Purchase contract statuses': 'Статуси закупівельних контрактів',
      'Purchase contract supplier company code':
        'Код компанії постачальника закупівельного контракту',
      'Purchase contracts': 'Закупівельні контракти',
      'Purchase init price': 'Початкова ціна покупки',
      'Purchase loyalty program': 'Закупівля програма лояльності',
      'Purchase multicontract': 'Закупівельний мультіконтракт',
      'Purchase multiticket': 'Закупівельний мультитікет',
      'Purchase plan': 'План закупівлі',
      'Purchase plan report': 'План закупівлі звіт',
      'Purchase price': 'Ціна закупівлі',
      'Purchase price differs': 'Закупівельна ціна різна',
      'Purchase price local': 'Закупівельна ціна локальна',
      'Purchase price wit VAT': 'Ціна закупівлі з ПДВ',
      'Purchase price wit VAT on Elevator': 'Ціна закупівлі з ПДВ на елеватор',
      'Purchase price without VAT': 'Ціна закупівлі без ПДВ',
      'Purchase responsible': 'Відповідальний по закупівлі',
      'Purchase responsible for signing': 'Закупівля відповідальний за підпис',
      'Purchase sale manager': 'Закупівля менеджер з продажу',
      'Purchase status': 'Закупівля статус',
      'Purchase this': 'Придбати це',
      'Purchase ticket': 'Закупівельний тікет',
      'Purchase to execute': 'Закупівля для виконання',
      'Purchase to invoice': 'Інвойс закупівлі',
      'Purchase to pay': 'Закупівля до оплати',
      'Purchase totals': 'Всього закупівлі',
      'Purchase value local': 'Вартість закупівлі локальна валюта',
      PurchaseContract: 'Закупівельний контракт',
      Purchases: 'Закупівля',
      'Purchases (Analytical - Contract)': 'Закупівля ( Аналітичний - Контракт)',
      'Purchases (Plan - Fact)': 'Закупівля ( План - Факт)',
      Purpose: 'Ціль',
      QTY: 'КІЛЬКІСТЬ',
      QUARTER: 'КВАРТАЛ',
      'QUARTER DIFF': 'ЧВЕРТЬ РІЗНИЦІ',
      Qualities: 'Якості',
      Quality: 'Якість',
      'Quality Rules': 'Правила якості',
      'Quality date': 'Дата якості',
      'Quality deleted': 'Якість видалено',
      'Quality eng': 'Якісь (англ)',
      'Quality list': 'Список якості',
      'Quality list eng': 'Список якості (англ)',
      'Quality required': 'Потрібна якість',
      'Quality rule Config': 'Конфігурація правил якості',
      'Quality rule templates': 'Шаблони дисконтів якості',
      'Quality rules': 'Правила якості',
      'Quality rules are not included': 'Правила якості не включені',
      'Quantitative metrics': 'Кількісні метрики',
      Quantity: 'Кількість',
      'Quantity = Volume / Packing size': 'Кількість = Обсяг / Розмір упаковки',
      'Quantity Total': 'Кількість всього',
      'Quantity Total Amount': "Загального сума об'єму",
      'Quantity Total Amount without VAT': 'Кількість Загальна сума без ПДВ',
      'Quantity Total VAT Amount': "Загального сума ПДВ об'єму",
      'Quantity fact': 'Факт кількості',
      'Quantity of measurement': 'Кількість одиниці виміру',
      'Quantity of wagons by contract': 'Кількість вагонів за контрактом',
      'Quantity total': 'Загальна кількість',
      'Quantity total amount': 'Загальна сума',
      Quarantine: 'Карантин',
      'Quarantine required': 'Потрібен карантин',
      'Quick add': 'Швидке додавання',
      'Quick edit': 'Швидке редагування',
      Quit: 'Завершити',
      Quotations: 'Пропозиції',
      'Quote Parsing Config': 'Конфігурація парсингу котирувань',
      'Quote Parsing Config saved': 'Конфігурація парсингу котирувань збережено',
      'Quotes updated.': 'Котирування оновлено.',
      'RATES & COSTS': 'ТАРИФИ ТА ВИТРАТИ',
      REALIZED: 'РЕАЛІЗОВАНО',
      REASSIGNMENTS: 'ПЕРЕУСТУПКИ',
      'RECENTLY UPDATED': 'НЕЩОДАВНО ОНОВЛЕНО',
      RECIPIENTS: 'ОТРИМУВАЧІ',
      REPORT: 'ЗВІТ',
      RESULT: 'РЕЗУЛЬТАТ',
      ROE: 'ROE',
      ROS: 'ROS',
      'RW payment conditions': 'Умови оплати',
      'RW prepay - balance percent': 'Передоплата залізниці - відсоток балансу',
      Railway: 'Залізниця',
      'Railway cost': 'Витрати залізниці',
      'Railway enterance': 'Залізничний транспорт',
      'Railway losts': 'Залізничні втрати',
      'Railway prepay - balance percent': 'Залізничні передоплати - відсоток балансу',
      'Railway required': 'Потрібна залізниця',
      Range: 'Діапазон',
      Rate: 'Ставка',
      Rates: 'Ставки',
      'Re assignment': 'Переуступка',
      'Re-approval': 'Повторне підтвердження',
      'Re-approve': 'Повторне підтвердження',
      Reactivate: 'Активувати повторно',
      'Reactivate approving': 'Відновлення підтвердження',
      'Read all': 'Прочитати все',
      Ready: 'Готово',
      'Real stocks': 'Реальні залишки',
      Realized: 'Реалізовано',
      'Realized + Open': 'Реалізовано + Відкрито',
      'Realized profit and loss': 'Реалізований прибуток і збиток',
      'Realized profit and loss per 1': 'Реалізований прибуток і збиток за 1 рік',
      'Realized quantity': "Реалізований об'єм",
      'Reason for rejection': 'Причина відхилення',
      Reassignment: 'Переуступка',
      'Reassignment amount': 'Сума переуступки',
      'Reassignment date': 'Дата переуступки',
      "Reassignment doesn't exist": 'Переуступки не існує',
      'Reassignment list': 'Список переуступок',
      'Reassignment number': 'Номер переуступки',
      'Reassignment positions': 'Позиція переуступки',
      'Reassignment rate': 'Ставка переуступки',
      'Reassignment saved': 'Переуступку збережено',
      'Reassignment status': 'Статус переуступки',
      'Reassignment value': 'Сума переуступки',
      Reassignments: 'Переуступки',
      'Recalculate invoice': 'Перерахувати інвойс',
      Recall: 'Нагадати',
      Recap: 'Підсумок',
      Receipt: 'Receipt',
      Receivables: 'Доходи',
      Received: 'Отримано',
      'Received (date)': 'Отримано (дата)',
      'Received date': 'Дата отримання',
      'Received registry (port)': 'Отриманий реєстр (порт)',
      'Received value': 'Отримано, сума',
      'Received volume': "Отриманий об'єм",
      Receiver: 'Одержувач',
      Receiving: 'Отримання',
      'Receiving (date)': 'Отримання (дата)',
      'Receiving date': 'Дата отримання',
      'Receiving date from': 'Дата отримання від',
      'Receiving date to': 'Дата отримання до',
      'Receiving date was changed': 'Дата отримання змінена',
      'Recent confirmations': 'Останні підтвердження',
      'Recieve station': 'Станція отримання',
      'Recipient type': 'Тип одержувача',
      Recipients: 'Одержувачі',
      Reconciliation: 'Звірка',
      'Reconciliation by invoice': 'Звірка по інвойсу',
      'Reconciliation by logistics': 'Звірка по логістиці',
      'Record type': 'Тип запису',
      Recreate: 'Відновити',
      Red: 'Червоне',
      Region: 'Регіон',
      Regions: 'Регіони',
      'Register stocks': 'Зареєстровані залишки',
      'Registered since': 'Зареєстрований з',
      Registry: 'Реєстр',
      'Registry received (forwarder)': 'Реєстр отриманий (експедитор)',
      'Registry sent (forwarder)': 'Реєстр відправлено (експедитор)',
      'Registry sent (port)': 'Реєстр відправлено (порт)',
      Regular: 'Звичайний',
      Rejected: 'Відхилено',
      'Related companies': "Пов'язані компанії",
      'Related counterparties': 'Пов’язані контрагенти',
      'Related counterparty': "Пов'язаний контрагент",
      'Related counterparty from': "Пов'язаний контрагент від",
      'Related counterparty to': "Пов'язаний контрагент до",
      'Related deal': "Пов'язана угода",
      'Related fees': "Пов'язані комісії",
      'Related invoice': "Пов'язаний інвойс",
      'Related invoice number': "Номер пов'язаного інвойсу",
      'Related owner': "Пов'язаний власник",
      Relation: 'Відношення',
      Relations: 'Відношення',
      Remains: 'Залишки',
      'Remind me before': 'Нагадати мені до',
      Remove: 'Видалено',
      'Renewal date': 'Дата оновлення',
      Rentability: 'Рентабельність',
      Replace: 'Замінити',
      Report: 'Звіт',
      'Report config created': 'Конфігурацію звіту створено',
      'Report config deleted': 'Конфігурацію звіту видалено',
      'Report config updated': 'Конфігурацію звіту оновлено',
      'Report grouping': 'Групування звітів',
      'Report type': 'Тип звіту',
      Reports: 'Звіти',
      Request: 'Заявка',
      'Request (date)': 'Заявка (дата)',
      'Request date': 'Дата заявки',
      'Request removed': 'Тікет видалено',
      'Request saved': 'Тікет збережено',
      'Request stage': 'Етап тікета',
      'Request updated': 'Тікет оновлено',
      Requested: 'Запрошено',
      'Requested to update on:': 'Запитано оновлення на:',
      Required: "Обов'язково",
      'Rerouting addresses': 'Адреси перенаправлення',
      Reservation: 'Резервування',
      Reservations: 'Резервації',
      Reserve: 'Резерв',
      'Reserve Client': 'Резервний клієнт',
      'Reserve Contract': 'Резервний Контракт',
      'Reserve Passport': 'Резервний паспорт',
      'Reserve Passport Vessel': 'Резервне судно паспорта',
      'Reserve Passport Voyage': 'Резервний рейс паспорта',
      'Reserve Vessel': 'Резервне судно',
      'Reserve Voyage': 'Резервний рейс',
      'Reserve available': 'Є резерв',
      'Reserve charge': 'Резервна витрата',
      'Reserve used': 'Резерв використаний',
      'Reserved Volume': "Резервний об'єм",
      Reset: 'Очищення фільтру',
      'Reset values': 'Скинути суму',
      'Residual volume': "Кінцевий об'єм",
      Resource: 'Ресурс',
      Resources: 'Ресурси',
      Response: 'Відповідь',
      Responsibilities: 'Відповідальність',
      Responsibility: 'Відповідальність',
      Responsible: 'Відповідальний',
      'Responsible for signing': 'Відповідальний за підпис',
      'Responsible users': 'Відповідальні користувачі',
      Result: 'Результат',
      'Result date': 'Дата результату',
      'Result status': 'Результат статус',
      'Reverse order': 'Зворотній порядок',
      Risk: 'Ризик',
      'Risk position terminal': 'Ризик позиція термінал',
      Role: 'Роль',
      'Role specific': 'Особливість ролі',
      Roles: 'Ролі',
      'Roles, in which this Counterparty will be presented in system.You can select multiple roles, pressing CTRL (CMD)':
        'Ролі, в яких цей контрагент буде представлений в системі.можна вибрати кілька ролей, натиснувши CTRL (CMD)',
      Rolling: 'Rolling',
      'Rolling stock': 'Rolling склад',
      Russia: 'Росія',
      Russian: 'Русский',
      SALE: 'ПРОДАЖ',
      'SALE ASC': 'ПРОДАЖ ASC',
      'SALE CONTRACTS': 'ПРОДАЖНІ КОНТРАКТИ',
      'SALE COSTS': 'ПРОДАЖ ВИТРАТИ',
      'SALE DESC': 'ПРОДАЖ DESC',
      'SALE TICKETS': 'ПРОДАЖНІ ТІКЕТИ',
      'SAP Order Purchase Contract': 'SAP замовлення закупівельний контракт',
      'SAP number': 'SAP номер',
      'SAP order numbers': 'SAP номери замовлень',
      'SAP orders': 'SAP ордери',
      'SAP vendor number': 'SAP номер постачальника',
      SAVE: 'ЗБЕРЕГТИ',
      'SEARCH FILTERS': 'ФІЛЬТРИ ПОШУКУ',
      SELLER: 'ПРОДАВЕЦЬ',
      SERVICES: 'ПОСЛУГИ',
      SHIPMENT: 'ВІДВАНТАЖЕННЯ',
      'SHIPMENT END': 'КІНЕЦЬ ВІДВАНТАЖЕННЯ',
      'SHIPMENT START': 'ПОЧАТОК ВІДВАНТАЖЕННЯ',
      SHORT: 'КОРОТКИЙ',
      SI: 'SI',
      SIDES: 'СТОРОНИ',
      SIGNATURE: 'ПІДПИС',
      'SIGNING DETAILS': 'ПІДПИСНІ ДЕТАЛІ',
      SP: 'SP',
      SPECIAL: 'СПЕЦІАЛЬНИЙ',
      STATUS: 'СТАТУС',
      STEP: 'КРОК',
      SUBSCRIBE: 'ПІДПИСКА',
      SUPPLIER: 'ПОСТАЧАЛЬНИК',
      'SUPPLIER CONFIRMATION': 'ПІДТВЕРДЖЕННЯ ПОСТАЧАЛЬНИКА',
      SWIFT: 'SWIFT',
      'SYSTEM NOTIFICATION': 'ПОВІДОМЛЕННЯ',
      'SYSTEM USERS': 'СИСТЕМНІ КОРИСТУВАЧІ',
      Sa: 'Сб',
      Sale: 'Продаж',
      'Sale (date)': 'Продаж (дата)',
      'Sale Contract': 'Продажний контракт',
      'Sale Contract #': 'Продажний контракт #',
      'Sale Contract Position': 'Позиція продажного контракту',
      'Sale Currencies': 'Продаж валюти',
      'Sale KPI': 'Продаж KPI',
      'Sale Price': 'Ціна продажу',
      'Sale business unit': 'Продажний бізнес-юніт',
      'Sale contract': 'Продажний контракт',
      'Sale contract deal type': 'Тип продажного контракту',
      'Sale contract passports': 'Паспорт продажного контракту',
      'Sale contract status': 'Статус продажного контракту',
      'Sale contract statuses': 'Статуси продажних контрактів',
      'Sale contract volume': "Об'єм продажу за контрактом",
      'Sale contracts': 'Продажні контракти',
      'Sale discount more then purchase': 'Дисконт на продаж більший, ніж на закупівлю',
      'Sale discount value': 'Сума дисконту на продаж',
      'Sale end execution': 'Продаж кінець виконання',
      'Sale init price': 'Початкова ціна продажу',
      'Sale invoice date': 'Дата інвойсу на продаж',
      'Sale logistic volumes': "Продажний об'єм логістики",
      'Sale multicontract': 'Продажний мультиконтракт',
      'Sale multiticket': 'Продажний мультитікет',
      'Sale or Purchase': 'Продаж або Закупівля',
      'Sale payment date': 'Дата оплати продажу',
      'Sale plan report': 'Звіт про план продажу',
      'Sale price': 'Ціна продажу',
      'Sale price (incl. VAT)': 'Ціна продажу (у т.ч. ПДВ)',
      'Sale price differs': 'Продажна ціна різна',
      'Sale price discount': 'Дисконт на ціну продажу',
      'Sale price local': 'Ціна продажу локальна',
      'Sale start execution': 'Продаж початок виконання',
      'Sale status': 'Статус продажу',
      'Sale this': 'Продати це',
      'Sale ticket': 'Продажний тікет',
      'Sale to execute': 'Продаж до виконання',
      'Sale to invoice': 'Продаж по інвойсу',
      'Sale to pay': 'Продаж до сплати',
      'Sale total value': 'Загальна суму продажу',
      'Sale total value discounted': 'Загальна суму продажу з урахуванням дисконту',
      'Sale totals': 'Всього продажу',
      'Sale volume': "Продажний об'єм",
      SaleContract: 'Продажний контракт',
      Sales: 'Продажі',
      'Sales (Plan - Fact)': 'Продаж ( План - Факт)',
      'Sales amount': 'Сума продажу',
      'Sales execution': 'Виконання продажу',
      'Sales managers': 'Регіональні представники',
      'Sales payment conditions': 'Умови оплати продажного контракту',
      'Sales price': 'Ціна продажу',
      'Sample Control': 'Контроль зразків',
      'Sample In date': 'Дата отримання зразка',
      'Sample Out date': 'Зразки вихідна дата',
      'Sample received date': 'Зразок дата отримання',
      'Sample result date': 'Зразок дата результату',
      'Sample type': 'Тип зразка',
      Samples: 'Зразки',
      'Sampling Condition': 'Умова відбору зразків',
      Sanctions: 'Санкції',
      'Sap Order': 'SAP замовлення',
      'Sap Orders': 'SAP замовлення',
      'Sap orders': 'SAP замовлення',
      Saturday: 'Субота',
      Save: 'Зберегти',
      'Save & continue': 'Зберегти та продовжити',
      'Save all': 'Зберегти все',
      'Save costs': 'Зберегти витрати',
      'Save current filter': 'Зберегти поточний фільтр',
      'Save filers as a preset': 'Зберегти набір фільтрів як шаблон',
      'Save without diff': 'Зберегти без різниці',
      Saved: 'Збережено',
      'Screenshots configs': 'Конфігурації знімків екрану',
      Seal: 'Пломба',
      Search: 'Пошук',
      'Search and press ↵ ENTER': 'Знайдіть і натисніть ↵ ENTER',
      'Search in database': 'Пошук в базі даних',
      'Search permissions': 'Права доступу для пошуку',
      Season: 'Сезон',
      'Season year': 'Рік сезону',
      Seasons: 'Сезони',
      'Second (validation)': 'Друга (валідація)',
      'Second Name': 'Прізвище',
      'Second name': 'Прізвище',
      'Secret invoice': 'Секретний інвойс',
      Section: 'Розділ',
      'See all': 'Переглянути все',
      Seeds: 'Посіви',
      Select: 'Виберіть',
      'Select .XLS template': 'Виберіть шаблон .XLS',
      'Select All': 'Вибрати все',
      'Select All {[{$ctrl.mode}]} on the page': 'Оберіть усі {[{$ctrl.mode}]} на сторінці',
      'Select Business Unit': 'Виберіть бізнес-юніт',
      'Select Email Template': 'Виберіть шаблон Email',
      'Select Passport for costs': 'Виберіть Паспорт для витрат',
      'Select User to be responsible': 'Виберіть відповідального користувача',
      'Select a collection': 'Виберіть конфігурацію',
      'Select a document': 'Виберіть документ',
      'Select a passport': 'Виберіть паспорт',
      'Select a responsible': 'Виберіть відповідального',
      'Select a status': 'Виберіть статус',
      'Select a tags': 'Виберіть теги',
      'Select all': 'Вибрати все',
      'Select all documents': 'Виберіть усі документи',
      'Select all transport': 'Виберіть весь транспорт',
      'Select any of item': 'Виберіть будь-який елемент',
      'Select business unit': 'Виберіть бізнес-юніт',
      'Select by which contract property you want to group P&L. Default grouping\n                          can be set in Settings.':
        'Виберіть, за якою властивістю контракту ви хочете групувати P&L. За замовчуванням групування можна налаштувати в налаштуваннях.',
      'Select cargo': 'Виберіть вантаж',
      'Select cargo receiver': 'Виберіть вантажоприймача',
      'Select cargo shipper': 'Виберіть вантажовідправника',
      'Select charges and press confirm': 'Виберіть витрати та натисніть підтвердити',
      'Select commodity of passport': 'Виберіть товар паспорта',
      'Select consignment': 'Виберіть квитанції',
      'Select consignments to create costs': 'Виберіть квитанції для створення витрат',
      'Select contracts for passport': 'Виберіть контракти для паспорта',
      'Select date': 'Виберіть дату',
      'Select departure station': 'Виберіть станцію відправлення',
      'Select elevator': 'Виберіть елеватор',
      'Select invoices to send': 'Виберіть інвойс для відправки',
      'Select logistics': 'Виберіть декілька логістик',
      'Select or drag and drop files': 'Виберіть або перетягніть файли',
      'Select property': 'Виберіть показник',
      'Select purchase contract': 'Виберіть закупівельний контракт',
      'Select responsible': 'Виберіть відповідального',
      'Select sale contract': 'Виберіть продажний контракт',
      'Select station destination': 'Виберіть станцію призначення',
      'Select status': 'Вибрати статус',
      'Select terminal': 'Виберіть термінал',
      'Select the charge': 'Виберіть витрату',
      'Select the contract': 'Виберіть контракт',
      'Select the contract charge': 'Виберіть витрату контракту',
      'Select the logistic': 'Виберіть логістику',
      'Select the other activity': 'Виберіть іншу активність',
      'Select the passport': 'Виберіть паспорт',
      'Select the product': 'Виберіть продукт',
      'Select to': 'Вибрати',
      'Select to Approve': 'Обрати для підтвердження',
      'Select to Pay': 'Вибрати для оплати',
      'Select to Payment Plan': 'Виберіть план оплати',
      'Select transport': 'Виберіть транспорт',
      'Select transport to send': 'Виберіть транспорт для відправки',
      'Select which margin type you want to display from passports. E.g. contract\n                          - only plan. If mixed - we will show the appropriate margin per each\n                          passport as per MARGIN SELECTOR setting.':
        'Оберіть тип маржі, який ви хочете відображати з паспортів. Наприклад, контракт — тільки план. Якщо змішаний — ми покажемо відповідну маржу для кожного паспорта згідно з налаштуваннями MARGIN SELECTOR.',
      'Select/Deselect all': 'Вибрати/Зняти виділення з усіх',
      Selected: 'Вибрано',
      'Selected groups': 'Вибрані групи',
      'Selected more than one forwarder.': 'Вибрано більше одного експедитора.',
      'Selected volume:': "Обраний об'єм:",
      Selector: 'Вибір',
      'Self-cost': 'Собівартість',
      'Self-cost per 1 t including all costs': 'Собівартість за 1 т, включаючи всі витрати',
      'Selfcost Amount, $': 'Собівартість, usd',
      'Selfcost per MT': 'Собівартість за 1т',
      Sell: 'Продаж',
      'Sell quantity': 'Кількість продажу',
      Seller: 'Продавець',
      Send: 'Відправити',
      'Send Screenshot': 'Надіслати скріншот',
      'Send an invoice': 'Відправити інвойс',
      'Send business confirmation': 'Надіслати бізнес підтвердження',
      'Send email': 'Відправити email',
      'Send selected': 'Відправити вибране',
      Sent: 'Надіслано',
      'Sent docs to Buyers': 'Відправлено документи покупцям',
      September: 'Вересень',
      Sequence: 'Послідовність',
      'Sequence order': 'Порядок послідовності',
      'Server error': 'Помилка сервера',
      'Service contract': 'Сервісний контракт',
      'Service invoice': 'Інвойс за послуги',
      Services: 'Послуги',
      'Services Contract': 'Контракт про надання послуг',
      'Services contract': 'Контракт про надання послуг',
      'Services multicontract': 'Сервісний мультіконтракт',
      'Services multiticket': 'Сервісний мультитікет',
      'Services positions': 'Сервісні позиції',
      ServicesContract: 'Сервісний контракт',
      'Set cargo confirmation': 'Встановити підтвердження вантажу',
      'Set contract closure': 'Встановити закриття договору',
      'Set directly': 'Встановити безпосередньо',
      'Set export contract to sale logistics':
        'Встановити експортний контракт для логістики продажу',
      'Set fact volume to plans': "Встановити фактичний об'єм замість плану",
      'Set final volume': "Встановити фінальний об'єм",
      'Set if only you want to convert amount in USD by a certain rate':
        'Встановити, якщо бажаєте конвертувати суму в USD за певним курсом',
      'Set it only if volume of charge differs from contract volume':
        "Встановити, якщо об'єм вартості відрізняється від об'єму контракту",
      'Set plan': 'Встановити план',
      'Set positions number': 'Встановіть кількість позицій',
      'Set sale contract to purchase logistics':
        'Встановити продажний контракт для логістики закупівельних контрактів',
      'Set status closed': 'Встановити статус закрито',
      'Set the status Closed for all costs that are fully invoiced and paid':
        'Встановіть статус Закрито для всіх витрат, які повністю виставлені та оплачені',
      'Set voyage data': 'Встановити дані подорожі',
      Settings: 'Налаштування',
      Share: 'Поділитися',
      'Ship classification': 'Класифікація судна',
      Shipment: 'Відвантаження',
      'Shipment Plan': 'План відвантаження',
      'Shipment Plan by Passport': 'План відвантаження за паспортом',
      'Shipment Terminal': 'Термінал відвантаження',
      'Shipment balance': 'Баланс відвантаження',
      'Shipment business unit': 'Бізнес-юніт відвантаження',
      'Shipment days saved.': 'Кількість днів відвантаження збережено.',
      'Shipment elevator': 'Елеватор відвантаження',
      'Shipment period': 'Період відвантаження',
      'Shipment place': 'Місце відвантаження',
      'Shipment plan deleted.': 'План відвантаження видалено.',
      'Shipment plan saved.': 'План відвантаження збережено.',
      'Shipment planned': 'Відвантаження заплановане',
      'Shipment type': 'Тип відвантаження',
      'Shipment warehouse': 'Склад відвантаження',
      'Shipment. Completed if shipment is done':
        'Відвантаження. Завершено, якщо відправлення виконано',
      Shipments: 'Відвантаження',
      Shipped: 'Відправлено',
      'Shipped on board date': 'Дата завантаження на борт',
      'Shipped wagons': 'Відвантажені вагони',
      Short: 'Короткий',
      'Short lots': 'Короткі лоти',
      'Shortage rate': 'Shortage ставка',
      'Should all the details be filled in from the Parent warehouse?':
        'Чи повинні бути заповнені всі деталі з головного складу?',
      Show: 'Показати',
      'Show Aging': 'Показати затримки',
      'Show Facts': 'Показати факти',
      'Show MTM': 'Показати MTM',
      'Show all': 'Показати все',
      'Show bank balances': 'Показати банківські баланси',
      'Show bank transaction': 'Показати банківську транзакцію',
      'Show child crops': 'Показати дочірні культури',
      'Show debts by plan date': 'Показати заборгованість за датою плану',
      'Show detail': 'Показати деталі',
      'Show details': 'Показати деталі',
      'Show full table': 'Показати повну таблицю',
      'Show history': 'Показати історію',
      'Show initial balance': 'Показати початковий баланс',
      'Show intermediate contract`s cargo positions': 'Показати проміжні товарні позиції контракту',
      'Show less': 'Показати менше',
      'Show level': 'Показати рівень',
      'Show level:': 'Показати рівень:',
      'Show more': 'Показати більше',
      'Show my': 'Показати мої',
      'Show overpaid': 'Показати переплату',
      'Show past periods debts': 'Показати борги минулих періодів',
      'Show reserve': 'Показати резерв',
      'Show volume': "Показати об'єм",
      'Show voyage details': 'Показати деталі рейсу',
      'Show/hide all': 'Показати/сховати все',
      'Shown by default': 'Відображається за умовчанням',
      Sides: 'Сторони',
      'Sign a contract': 'Підписати контракт',
      Signatory: 'Підписант',
      'Signatory Docs': 'Підписанант документів',
      'Signatory Eng': 'Підписант Англ',
      'Signatory Eng Docs': 'Підписант документів (англ.)',
      'Signatory Eng Position': 'Посада підписанта (англ)',
      'Signatory Position': 'Посада підписанта',
      'Signatory docs': 'Підписані документи',
      'Signatory docs eng': 'Підписані документи (англ)',
      'Signatory eng': 'Підписант (англ)',
      'Signatory, the son of Signator': 'Підписант Підписантович',
      Signature: 'Підпис',
      'Signature stages': 'Етапи підпису',
      'Signature. Completed if all sides signed a document':
        'Підпис. Завершено, якщо всі сторони підписали документ',
      Signed: 'Підписано',
      Signings: 'Підписи',
      'Signings KPI': 'Підписи KPI',
      'Signings status': 'Статус підписання',
      Similar: 'Схожі',
      'Simple percent': 'Простий відсоток',
      'Simple system': 'Проста система',
      'Since we group this report by contract, you should choose either by sales\n                          contracts or by purchase contracts':
        'Оскільки ми групуємо цей звіт за контрактом, ви повинні вибрати або за контрактами на продаж, або за контрактами на покупку',
      Size: 'Розмір',
      Small: 'Маленький',
      'Something went wrong': 'Щось пішло не так',
      'Something went wrong...': 'Щось пішло не так...',
      'Something went wrong... try again.': 'Щось пішло не так... спробуйте ще раз.',
      Source: 'Джерело',
      Sources: 'Джерела',
      Special: 'Спеціальні',
      'Specific information about freight': 'Конкретна інформація про фрахт',
      'Specific information about this object': "Конкретна інформація про цей об'єкт",
      Split: 'Розподіл',
      Spot: 'Спот',
      'Spot fact': 'Спот факт',
      'Spot limit': 'Ліміт споту',
      'Spot or Forward': 'Спот або Форвард',
      Stage: 'Етап',
      'Stage #': 'Етап №',
      Start: 'Початок',
      'Start Date': 'Дата Початку',
      'Start balance': 'Початковий баланс',
      'Start date': 'Дата початку',
      'Start date (plan)': 'Дата початку (план)',
      'Start end': 'Кінцева дата',
      'Start of execution': 'Початок виконання',
      'Start usage (date)': 'Початок використання (дата)',
      Station: 'Станція',
      'Station Departure': 'Станції відправлення',
      'Station arrival': 'Станція прибуття',
      'Station depart': 'Станція відправлення',
      'Station departure': 'Станції відправлення',
      'Station destination': 'Станція призначення',
      'Station receive': 'Станція отримання',
      'Station receiving': 'Станція отримання',
      Stations: 'Станції',
      Statistics: 'Статистика',
      Status: 'Статус',
      'Status depends on current progress on general agreement':
        'Статус залежить від поточного прогресу за угодою',
      'Status depends on current progress on payment plan':
        'Статус залежить від поточного виконання плану платежів',
      'Status depends on current progress on request':
        'Стан залежить від поточного стану виконання заявки',
      'Status of te deal: NEW - recently created, SHIPMENT - the execution has been started, EXECUTED - the Contract is closed and executed, CANCELLED - rejected by the manager':
        'Статус угоди: НОВА - нещодавно створена, ВІДВАНТАЖЕННЯ - розпочато виконання, ВИКОНАНО - контракт закрито і виконано, СКАСОВАНО - відхилено менеджером',
      'Status updated': 'Статус оновлено',
      'Status will be changed automatically, based on a compliance status. You need to fill out request, received and confirmed date':
        'Статус буде змінено автоматично, виходячи зі статусу комплаєнсу. Потрібно заповнити запит, дату отримання і підтвердження',
      Statuses: 'Статуси',
      Step: 'Крок',
      'Step saved': 'Крок збережено',
      Stock: 'Залишки',
      'Stock Exchange': 'Біржовий курс',
      'Stock Reserve Unit': 'Одиниця резерву запасів',
      'Stock Reserve Units': 'Одиниці резерву запасів',
      'Stock exchange': 'Фондова біржа',
      'Stock exchanges': 'Фондові біржі',
      'Stock plan (t)': 'Залишки план (т)',
      'Stock plan amount': 'Залишок план сума',
      'Stock reserve unit': 'Одиниця резерву запасів',
      'Stock reserve units': 'Одиниці резерву запасів',
      Stocklot: 'Залишкова партія',
      Stocks: 'Запаси',
      'Stocks allocation': "Прив'язка залишків",
      'Stocks, MT': 'Запаси, т',
      Storage: 'Зберігання',
      'Storage & cargo': 'Зберігання та вантаж',
      'Storage days': 'Дні зберігання',
      Strategy: 'Стратегія',
      String: 'Рядок',
      Stuff: 'Персонал',
      Su: 'Нд',
      'Sub distributor': "Суб-дистриб'ютор",
      'Sub reassignments': 'Подвійна переуступка',
      Subject: 'Тема',
      'Submit documents': 'Підтвердити документи',
      Subscribe: 'Підписатися',
      Subuse: 'Cубкористування',
      Success: 'Успіх',
      'Success created.': 'Успішно створено.',
      'Successfully updated.': 'Успішно оновлено.',
      Summary: 'Підсумок',
      Sunday: 'Неділя',
      'Super Admin Rights': 'Права суперадміністратора',
      Supplier: 'Постачальник',
      'Supplier 10 invoice': 'Постачальник 10 інвойс',
      'Supplier 90 invoice': 'Постачальник 90 інвойс',
      'Supplier EDRPOU code': 'Код ЄДРПОУ постачальника',
      'Supplier ITN (Individual Tax Number)': 'ІПН постачальника (індивідуальний податковий номер)',
      'Supplier Signed': 'Постачальник підписав',
      'Supplier address': 'Адреса постачальника',
      'Supplier address eng': 'Адреса постачальника (англ)',
      'Supplier bank details': 'Реквізити банку-постачальника',
      'Supplier can signatory docs': 'Постачальник може підписати документи',
      'Supplier can signatory docs eng': 'Постачальник може підписати документи (англ)',
      'Supplier contract': 'Контракт постачальника',
      'Supplier contract #': 'Контракт постачальника №',
      'Supplier contract ISCC': 'Контракт постачальника ISCC',
      'Supplier contract status': 'Статус контракту постачальника',
      'Supplier country': 'Країна постачальника',
      'Supplier country eng': 'Країна постачальника (англ)',
      'Supplier email': 'Постачальник email',
      'Supplier full name': 'Повна назва постачальника',
      'Supplier full name eng': 'Повна назва постачальника (англ)',
      'Supplier invoiced': 'Постачальник виставив інвойс',
      'Supplier is required': 'Потрібен постачальник',
      'Supplier legal address': 'Юридична адреса постачальника',
      'Supplier paid': 'Постачальник сплатив',
      'Supplier passports': 'Паспорти постачальників',
      'Supplier payment info': 'Інфо про оплату постачальника',
      'Supplier phone': 'Телефон постачальника',
      'Supplier signatory': 'Підписант постачальника',
      'Supplier signed': 'Постачальник підписав',
      'Supplier website': 'Веб-сайт постачальника',
      'Supplier/Owner': 'Постачальник/Власник',
      Suppliers: 'Постачальники',
      Surveyor: 'Сюрвеєр',
      Surveyors: 'Сюрвеєри',
      Swap: 'Своп',
      Swift: 'Swift',
      'System Event': 'Системна подія',
      'System notify': 'Системне повідомлення',
      TASKS: 'ЗАВДАННЯ',
      TEMPLATE: 'ШАБЛОН',
      TICKET: 'ТІКЕТ',
      'TICKET DOCUMENTS': 'ДОКУМЕНТИ ТІКЕТУ',
      TICKETS: 'ТІКЕТИ',
      TITLE: 'НАЗВА',
      TOTAL: 'ВСЬОГО',
      'TOTAL data': 'ВСЬОГО даних',
      TOTALS: 'ВСЬОГО',
      TRANSLOAD: 'ПЕРЕВАЛКА',
      TRANSPORT: 'ТРАНСПОРТ',
      'TRANSPORT CERTIFICATES': 'ТРАНСПОРТНІ СЕРТИФІКАТИ',
      'TRANSPORT DOCUMENTS': 'ДОКУМЕНТИ ТРАНСПОРТУ',
      TURNOVER: 'ОБОРОТ',
      Tabs: 'Вкладки',
      Tag: 'Тег',
      'Tare weight': 'Маса тари',
      Tariff: 'Тариф',
      'Tariff 20': 'Тариф 20',
      'Tariff 40': 'Тариф 40',
      Tariffs: 'Тарифи',
      Task: 'Завдання',
      'Task state': 'Стан задачі',
      Tasks: 'Завдання',
      'Tasks templates': 'Шаблони завдань',
      Tax: 'Податок',
      'Tax debt': 'Борг за податками',
      'Tax info': 'Податкова інформація',
      'Tax profit to pay': 'Податок на прибуток до сплати',
      'Tax registration': 'Реєстрація податку',
      'Tax status': 'Податковий статус',
      Team: 'Команда',
      'Technical passport number': 'Номер техпаспорта',
      Telegram: 'Telegram',
      'Telegram Chat': 'Telegram чат',
      'Telegram username': 'Нікнейм Telegram',
      Template: 'Шаблон',
      'Template deleted': 'Шаблон видалено',
      'Template name': 'Назва шаблону',
      'Template playground': 'Пісочниця для шаблонів',
      'Template removed': 'Шаблон видалено',
      'Template saved': 'Шаблон збережено',
      Templates: 'Шаблони',
      'Term of proxy': 'Строк дії довіреності',
      Terminal: 'Термінал',
      'Terminal Transshipment': 'Термінал перевалки',
      'Terminal Transshipment Tariff': 'Тариф на термінал перевалки',
      Terminals: 'Термінали',
      Text: 'Текст',
      Th: 'Чт',
      'The All signed date must be greater than or equal to all other dates.':
        'Дата “Всі підписані” повинна бути більшою або рівною всім іншим датам.',
      'The Legal Entity for this Role will be auto created':
        'Контрагент для цієї ролі буде створена автоматично',
      'The calculation principle is the following:': 'Принцип розрахунку полягає в наступному:',
      'The commission of bank': 'Комісія банку',
      'The company name abbreviation': 'Абревіатура назви компанії',
      'The contract': 'Контракт',
      'The contract does not contain an exchange rate': 'Контракт не містить курсу обміну',
      'The cost depends on the VAT tax rate specified in the contract':
        'Вартість залежить від ставки ПДВ, вказаної в контракті',
      'The discount of VAT compensation by the deal': 'Дисконт з ПДВ по угоді',
      'The email for internal use': 'Еmail для внутрішнього використання',
      'The fields contract, derivative must make a unique set.':
        'Поля контракту, деривативу повинні складати унікальний набір.',
      'The fields voyage, commodity must make a unique set.':
        'Поля рейсу, товару повинні складати унікальний набір.',
      'The first name for internal use': 'Перша назва для внутрішнього використання',
      'The form is': 'Форма',
      'The last name for internal use': 'Прізвище для внутрішнього користування',
      'The main person of the company': 'Головна особа компанії',
      'The name for internal use, can be whatever you want':
        'Назва для внутрішнього використання, може бути будь-яка',
      'The normal loss rate of Elevator': 'Норма втрат Елеватора',
      'The normal loss rate of RW': 'Норма втрат Залізниці',
      'The number on which sale price will be multiplied':
        'Число, на яке буде помножена продажна ціна',
      'The personal code of this company in state instances':
        'Персональний код цієї компанії в державних інстанціях',
      'The phone number for internal use': 'Номер телефону для внутрішнього використання',
      'The rate of return for VAT for cargo': 'Ставка повернення ПДВ за вантаж',
      'The rate of return for VAT for costs': 'Ставка повернення ПДВ за витрати',
      'The telegram username for internal use': 'Нікнейм telegram для внутрішнього використання',
      'The ticket': 'Тікет',
      'The totals have been updated': 'Підсумки були оновлені',
      'The username for internal use': 'Нікнейм для внутрішнього використання',
      TheadConfig: 'Конфігурація заголовка',
      Theme: 'Тема',
      'There are no selected filters to save': 'Немає вибраних фільтрів для збереження',
      'There are not undrop mailing in your set': 'У вашому наборі немає відкладених розсилок',
      'There are not unsent mailing in your set': 'У вашому наборі немає невідправлених розсилок',
      'There is no purchase contracs assigned to this passport':
        'До цього паспорта не прив’язано жодних контрактів на закупівлю',
      'There is nothing to show': 'Нічого не знайшли',
      'These is the Subject, your recipients see when receive letter':
        'Це Тема. Її побачать одержувачі листів',
      'These is the Title, only you will see, to identify current template':
        'Це назва, яку бачитимете лише Ви, щоб ідентифікувати шаблон',
      'Third (validation)': 'Третя (валідація)',
      'This Month': 'Цього місяця',
      'This actin will start voting again. Continue?':
        'Ця дія знову почне голосування. Продовжити?',
      'This action will create new ticket. How much to bite off?':
        'Ця дія створить новий тікет. Скільки забрати?',
      'This amount will change final invoice amount': 'Ця сума змінить остаточну суму інвойсу',
      'This cannot be undone': 'Це неможливо скасувати',
      'This consignment already connected.': 'Ця накладна вже підключена.',
      'This contract cannot be connected with this volume to passport.':
        "Цей контракт не може бути зв'язаний із цим обʼємом до паспорта.",
      'This contract has estimated volume': "Цей контракт має очікуваний об'єм",
      'This contract has finalized volume': "Цей контракт має кінцевий об'єм",
      'This contract was made from indicator': 'Цей контракт укладено з індикатора',
      'This contract was made from ticket': 'Цей контракт був зроблений із тікета',
      'This contracts already fully connected': "Ці контракти вже з'єднані",
      'This email will be send on behalf of you and via your email, appointed in Settings.':
        'Цей лист буде відправлений від вашого імені і через ваш email, вказаний в налаштуваннях.',
      'This field should be filled to pass compliance':
        'Це поле необхідно заповнити, щоб пройти комплаєнс',
      'This first block is combined P&L. It is based on the result you got by passports.\n                  Right after you press CONTROL button in the certain passport - its P&L will be\n                  instantly displayed here.':
        'Цей перший блок — це комбінований P&L. Він базується на результатах, отриманих за паспортами. Як тільки ви натискаєте кнопку CONTROL у певному паспорті, його P&L миттєво відображається тут.',
      'This invoice was made from credit note': 'Цей інвойс був створений з кредит-ноти',
      'This is bank details of our company': 'Це банківські реквізити нашої компанії',
      'This is not a valid float number. Type value in format 5 or 5.5 for\n                      example':
        'Це не дійсне число з плаваючою комою. Введіть значення у форматі 5 або 5.5, наприклад',
      'This is not a valid float number. Type value in format 5 or 5.5 for\n                example.':
        'Ви ввели некоректний номер. Введіть номеру форматі 5 або 5.5\n                    наприклад.',
      'This is not contract.': 'Це не контракт.',
      'This is the Message of your letter.You can use tags {{ tag }} to add some fields to template here.':
        'Це повідомлення. Ви можете використовувати спеціальні теги {{tag}}, щоб додати деякі поля до шаблону.',
      'This is the report of passports, showing you all allocations of contracts,\n                    costs, invoices and execution between each other.':
        'Це звіт по паспортах, який показує всі розподіли контрактів, витрат, інвойсів та виконання між ними.',
      'This is your operational P&L.': 'Це ваш операційний PnL.',
      'This logistic already selected.': 'Ця логістика вже обрана.',
      'This object has voted by you already. Skipping.':
        "За цей об'єкт ви вже проголосували. Пропуск.",
      'This should be filled for multilingual agreements':
        'Потрібно заповнити для багатомовних угод',
      'This ticket was made from': 'Цей тікет був створений з',
      'This ticket was made from indicator': 'Цей тікет був зроблений з індикатора',
      'This value will be added to your margin': 'Ця сума буде додана до вашої маржі',
      'This will add a mark that you have no intention to pay this invoice in a nearest future':
        'Це додасть позначку про те, що ви не маєте наміру сплачувати цей інвойс найближчим часом',
      'Thus, you will se profitability of each part and the consolidated profitability of\n              the whole chain.':
        'Таким чином, ви побачите рентабельність кожної частини та консолідовану рентабельність\n               усього ланцюга.',
      Ticker: 'Тікер',
      Ticket: 'Тікет',
      'Ticket ROS': 'Тікет ROS',
      'Ticket creation': 'Створення тікета',
      'Ticket custom status': 'Спеціальний статус тікета',
      'Ticket number': 'Номер тікета',
      'Ticket price exceeds indicator’s price': 'Ціна тікета перевищує ціну індикатора',
      'Ticket price is lower than indicator’s price': 'Ціна тікета нижча за ціну індикатора',
      'Ticket value': 'Тікет сума',
      'Ticket volume': "Об'єм тікета",
      'Ticket/Indicator': 'Тікет/Індикатор',
      Tickets: 'Тікети',
      'Tickets Analytical': 'Аналітичні тікети',
      'Tickets Analytics': 'Аналітика тікетів',
      'Tickets analytical': 'Тікети аналітичні',
      'Tickets margin': 'Маржа тікетів',
      'Tickets volume': "Об'єм тікетів",
      Title: 'Назва',
      'Title can not be empty': 'Назва не може бути порожньою',
      'Title must be unique': 'Назва має бути унікальною',
      To: 'До',
      'To Stock Reserve Unit': 'До одиниці резерву запасів',
      'To allocate': 'Закріпити',
      'To be invoiced': 'Для виставлення інвойсу',
      'To be invoiced (bal)': 'Для виставлення інвойсу (баланс)',
      'To be paid': 'До сплати',
      'To be shipped': 'Буде відвантажуватись',
      'To filter contracts for allocation': 'Фільтрувати контракти для розподілу',
      'To fix': 'Зафіксувати',
      'To fix lots': 'Зафіксувати лоти',
      'To fix tonnes': 'Зафіксувати тонни',
      'To hedge': 'Захеджувати',
      'To keep the existing cost/gain while creating a new one for the invoice, please\n                remove the value in the Contract charge field.':
        'Щоб зберегти існуючі витрати/прибуток при створенні нового інвойсу, будь ласка, видаліть значення в полі “Витрати контракту”.',
      'To make them appear add email or employees with emails to related\n                            counterparty':
        "Щоб вони з’явилися, додайте email або співробітників із email до пов'язаного\n                            контрагента",
      'To pay': 'Сплатити',
      'To receive': 'Отримати',
      'To which country delivered': 'В яку країну доставлено',
      'To whom': 'Кому',
      Today: 'Сьогодні',
      Tolerance: 'Толеранс',
      'Tolerance minus': 'Толеранс, мінус',
      'Tolerance plus': 'Толеранс, плюс',
      'Tolerance volume': 'Толеранс обʼєму',
      'Tolerance, %': 'Толеранс, %',
      'Top 20 Buyers': 'Топ-20 покупців',
      'Top 20 Suppliers': 'Топ-20 постачальників',
      Topic: 'Тема',
      Total: 'Всього',
      'Total Nested costs volume of invoices in': "Загальний об'єм вкладених витрат в інвойсах",
      'Total P&L': 'Всього PnL',
      'Total Paid': 'Всього оплачено',
      'Total VAT': 'Всього ПДВ',
      'Total Volume': "Загальний об'єм",
      'Total absolute value of charge': 'Загальна вартість витрати',
      'Total amount': 'Повна сума',
      'Total amount of all incoming invoices converted in USD':
        'Загальна сума всіх вхідних інвойсів, конвертована в USD',
      'Total amount of all incoming invoices in': 'Загальна сума всіх вхідних інвойсів у',
      'Total amount of all outgoing invoices converted in USD':
        'Загальна сума всіх вихідних інвойсів, конвертована в USD',
      'Total amount of all outgoing invoices in': 'Загальна сума всіх вихідних інвойсів у',
      'Total amount of invoices in': 'Загальна сума інвойсів в',
      'Total approved': 'Всього підтверджено',
      'Total by real stocks': 'Всього реальних залишків',
      'Total cumulative amount of all contracts': 'Загальний сума за всіма контрактами',
      'Total cumulative volume of all contracts': "Загальний об'єм за всіма контрактами",
      'Total for current date': 'Сума для поточної дати',
      'Total in USD': 'Всього в USD',
      'Total invoiced (fact)': 'Всього виставлено інвойсів (факт)',
      'Total invoiced (plan)': 'Всього виставлено інвойсів (план)',
      'Total lost volume': "Загальний втрачений об'єм",
      'Total margin': 'Загальна маржа',
      'Total outgoing - Total incoming': 'Всього вихідних - Всього вхідних',
      'Total outgoing - Total incoming in': 'Всього вихідних - всього вхідних',
      'Total paid': 'Всього сплачено',
      'Total paid amount by all incoming invoices in':
        'Загальна сплачена сума за всіма вхідними інвойсами в',
      'Total paid amount by all outgoing invoices in':
        'Загальна сплачена сума по всіх вихідних інвойсах в',
      'Total paid amount of all incoming invoices converted in USD':
        'Загальна сплачена сума всіх вхідних інвойсів, конвертованих у USD',
      'Total paid amount of all outgoing invoices converted in USD':
        'Загальна сплачена сума всіх вихідних інвойсів, конвертованих у USD',
      'Total project plan': 'Загальний план проекту',
      'Total quantity': 'Загальна кількість',
      'Total requested': 'Всього тікетів',
      'Total selected': 'Загальна кількість вибраних',
      'Total shipped': 'Всього відвантажено',
      'Total value': 'Загальна сума',
      'Total volume': "Загальний об'єм",
      'Total volume (forward)': "Загальний об'єм (форвард)",
      'Total volume (spot)': "Загальний об'єм (спот)",
      'Total volume of the deal. Choose plan value, at first, but then substitute on delivered volume':
        "Загальний об'єм угоди. Виберіть спочатку сума плану, але потім замініть на поставлений об'єм",
      'Total volume plan': "Загальний об'єм план",
      'Total volumes by customs terminal': "Загальні об'єми по митному терміналу",
      'Totally not invoiced': 'Загалом не виставлено інвойсів',
      Totals: 'Усього',
      Trade: 'Торгівля',
      'Trade date': 'Дата угоди',
      'Trader Note': 'Примітка трейдера',
      'Trader note': 'Примітка трейдера',
      'Traders amount': 'Сума трейдера',
      'Traders discount value': 'Сума дисконту для трейдерів',
      'Traders volume': "Об'єм трейдерів",
      'Trades in USD': 'Торги в USD',
      'Trades in local currency': 'Торги в локальній валюті',
      Trailer: 'Причіп',
      Transaction: 'Транзакція',
      'Transaction (date)': 'Транзакція (дата)',
      'Transaction Date': 'Дата транзакції',
      'Transaction removed': 'Транзакцію видалено',
      'Transaction sum': 'Сума транзакції',
      Transactions: 'Транзакції',
      Transfer: 'Перевалка',
      Transhipment: 'Перевалка',
      Transit: 'Транзит',
      Transload: 'Перевалка',
      'Transloaded Date': 'Дата перевалки',
      'Transloaded date': 'Дата перевалки',
      'Transloading facility': 'Місце перевалки',
      Transport: 'Транспорт',
      'Transport charge': 'Витрати транспорта',
      'Transport costs': 'Витрати транспорта',
      'Transport costs per t': 'Транспортні витрати на т',
      'Transport costs per unit': 'Транспортні витрати на одиницю',
      'Transport count': 'Кількість транспорту',
      'Transportation Storage Expense': 'Витрата на зберігання транспорту',
      'Transportation Storage Expenses': 'Витрати на зберігання транспорту',
      'Transports count': 'Кількість транспортів',
      Transshipment: 'Перевалка',
      Truck: 'Вантажівка',
      Tu: 'Вт',
      'Turn off': 'Вимкнути',
      'Turnover for the period': 'Оборот за період',
      Type: 'Тип',
      'Type a new name': 'Введіть нову назву',
      'Type address': 'Введіть адресу',
      'Type address (eng)': 'Введіть адресу (англ)',
      'Type amount': 'Введіть суму',
      'Type any comments': 'Введіть будь- які коментарі',
      'Type bank branches separated by comma': 'Введіть відділення банку, розділені комою',
      'Type bank code': 'Введіть код банку',
      'Type credit note number': 'Введіть номер кредитної ноти',
      'Type days': 'Введіть дні',
      'Type destination': 'Введіть призначення',
      'Type email of user': 'Введіть email користувача',
      'Type first name of user': "Введіть ім' я користувача",
      'Type full name': 'Введіть повну назву',
      'Type full name (eng)': 'Введіть повну назву (англ)',
      'Type here any specific information about this deal':
        'Введіть тут будь-яку інформацію про цю угоду',
      'Type invoice number': 'Введіть номер інвойсу',
      'Type last name of user': 'Введіть прізвище користувача',
      'Type loan number': 'Введіть номер позики',
      'Type name': 'Введіть назву',
      'Type new broker': 'Введіть нового брокера',
      'Type new buyer': 'Введіть нового покупця',
      'Type new supplier': 'Введіть нового постачальника',
      'Type number': 'Тип номера',
      'Type of CD': 'Тип МД',
      'Type of activities': 'Вид діяльності',
      'Type of consignment': 'Тип накладної',
      'Type of contract': 'Тип контракту',
      'Type of contracts perspective': 'Тип контракту',
      'Type of operation': 'Тип операції',
      'Type offset number': 'Введіть номер заліку',
      'Type origin': 'Введіть походження',
      'Type packing': 'Тип упаковки',
      'Type percent conditions': 'Введіть відсоткові умови',
      'Type phone number of user': 'Введіть номер телефону користувача',
      'Type price': 'Введіть ціну',
      'Type quantity': 'Введіть кількість',
      'Type rate': 'Введіть ставку',
      'Type reassignment number': 'Введіть номер переуступки',
      'Type swift': 'Введіть SWIFT',
      'Type telegram username of user': 'Введіть нікнейм Telegram',
      'Type title of passport': 'Введіть назву паспорта',
      'Type title of template': 'Введіть назву шаблону',
      'Type tolerance': 'Тип толерансу',
      'Type username of user': 'Введіть нікнейм',
      'Type value': 'Введіть суму',
      'Type volume': "Введіть об'єм",
      'Type volume max': "Введіть об'єм макс",
      'Type volume min': "Введіть об'єм мін",
      'Types of activities': 'Види діяльності',
      'UNREALIZED Position': 'НЕРЕАЛІЗОВАНА позиція',
      UPDATED: 'ОНОВЛЕНО',
      UPDATES: 'ОНОВЛЕННЯ',
      'UPLOADED DOCUMENTS': 'ЗАВАНТАЖЕНІ ДОКУМЕНТИ',
      USA: 'USD',
      USD: 'USD',
      'USD, EUR': 'USD, EUR',
      'USD, UAH': 'USD, UAH',
      Ukraine: 'Україна',
      Ukrainian: 'Українська',
      'Unable to create payment for unapproved invoice':
        'Неможливо створити платіж для неузгодженого рахунку',
      Unconfirmed: 'Непідтверджено',
      Unconnected: "Нез'єднані",
      'Unconnected Stock': 'Непідключений запас',
      'Unconnected amount per deal': "Непов'язана сума за угоду",
      'Unconnected stock': 'Непідключений запас',
      'Unconnected transport': "Незв'язаний транспорт",
      'Unconnected volume (by contract final volume)':
        "Непідключений об'єм (фінальний об'єм за контрактом )",
      'Unconnected volume (contract)': "Нез'єднаний об'єм (контракт)",
      'Unconnected volume (ticket)': "Нез'єднаний об'єм (тікет)",
      Undefined: 'Невизначено',
      Undelivered: 'Недоставлено',
      'Under discussion': 'Обговорюється',
      Undistributed: 'Нерозподілений',
      Unexecuted: 'Невиконано',
      'Unfulfilled volume': "Невиконаний об'єм",
      'Uninvoiced charges': 'Непроінвойсовані витрати',
      'Uninvoiced cost': 'Непроінвойсована вартість',
      'Unit price': 'Ціна юніт',
      'Unload costs per t': 'Вартість розвантаження на 1 тонну',
      'Unload costs per unit': 'Витрати на розвантаження за одиницю',
      'Unloading business unit': 'Бізнес-юніт розвантаження',
      'Unloading place': 'Місце розвантаження',
      'Unloading volume': "Об'єм розвантаження",
      'Unloading warehouse': 'Склад розвантаження',
      'Unpaid Cargo': 'Несплачений вантаж',
      'Unpaid amount': 'Несплачена сума',
      'Unpaid volume': "Неоплачений об'єм",
      Unread: 'Непрочитаний',
      'Unrealized Position': 'Нереалізована позиція',
      Unrelevant: 'Нерелевантні',
      'Unselected groups': 'Невибрані групи',
      'Untie contracts from passport': "Відв'язати контракти від паспорта",
      Upcoming: 'Майбутній',
      Update: 'Оновлення',
      'Update Business Date': 'Оновити бізнес дату',
      'Update Execution Stages': 'Оновити етапи виконання',
      'Update Execution Stages (save)': 'Оновити етапи виконання (зберегти)',
      'Update Telegram CHAT_ID': 'Оновити Telegram chat id',
      'Update Volume from BL': "Оновити об'єм з коносаменту",
      'Update all  connections from execution?': "Оновити всі зв'язки з виконання?",
      'Update all export connections from execution?':
        "Оновити всі з'єднання експорту з виконанням?",
      'Update all volumes': "Оновити всі об'єми",
      'Update cargo_confirmation, payment_confirmation, final_confirmation':
        'Оновити вантажне підтвердження, підтвердження оплати, кінцеве підтвердження',
      'Update date': 'Дата оновлення',
      'Update export volumes': "Оновити об'єми експорту",
      'Update full contract price volume': "Оновити повний об'єм контрактної ціни",
      'Update part of the position': 'Оновити частину позиції',
      'Update part of the volume': "Оновити частину об'єму",
      'Update purchase volumes': "Оновити об'єми закупівлі",
      'Update sale volumes': "Оновити об'єми продажу",
      'Update status': 'Оновити статус',
      'Update statuses': 'Оновлення статусів',
      'Update time': 'Дата оновлення',
      'Update title': 'Оновити назву',
      Updated: 'Оновлено',
      'Updated at': 'Оновлено о',
      'Updated date': 'Дата оновлення',
      'Updated on:': 'Оновлено в:',
      'Updated.': 'Оновлено.',
      Updates: 'Оновлення',
      'Updates Analytics': 'Оновлення аналітики',
      Updatind: 'Оновлення',
      'Updating...': 'Вивантаження...',
      Upload: 'Завантажити',
      'Upload a document': 'Завантажити документ',
      'Upload date': 'Дата вивантаження',
      'Uploaded documents': 'Завантажені документи',
      'Uploading is in progress. Please wait': 'Триває вивантаження. Будь ласка, зачекайте',
      'Usage cost': 'Вартість використання',
      Use: 'Використовувати',
      'Use commodity parent match': 'Використовуйте відповідність за батьківським товаром',
      'Use duval': 'Використовуйте Duval',
      'Use escalations': 'Використовуйте ескалації',
      'Use for price': 'Використовуйте для ціни',
      'Use for volume': 'Використати для об’єму',
      'Use in position': 'Використовуйте в позиції',
      'Use intermediate logistics in execution calculation':
        'Використовувати проміжну логістику в розрахунку виконання',
      'Use open price': 'Використати відкриту ціну',
      'Use this or %': 'Використовуйте це або %',
      'Use this or discount %': 'Користуйтеся цим або дисконтом %',
      'Use type': 'Тип використання',
      User: 'Користувач',
      'User removed': 'Користувач видалений',
      'User to be manager for the offset': 'Користувач відповідальний за залік',
      'User to be manager for the reassignment': 'Користувач відповідальний за переуступку',
      'User to be responsible': 'Відповідальний користувач',
      'User to be responsible for the contract': 'Користувач, відповідальний за контракт',
      'User to be responsible for the deal': 'Користувач, відповідальний за угоду',
      'User to be responsible_for_signing': 'Користувач, відповідальний за підписання',
      'User updated': 'Користувач оновлений',
      Username: 'Нікнейм',
      Users: 'Користувачі',
      VALIDATION: 'ВАЛІДАЦІЯ',
      VALUE: 'ЗНАЧЕННЯ',
      VAT: 'ПДВ',
      'VAT amount': 'сума ПДВ',
      'VAT costs return rate': 'Повернення ПДВ за витратами',
      'VAT included': 'з ПДВ',
      'VAT return': 'Повернення ПДВ',
      'VAT return rate': 'Ставка повернення ПДВ',
      'VAT value': 'Вартість ПДВ',
      VESSEL: 'СУДНО',
      VESSELS: 'СУДНА',
      'VET required': 'Необхідний Vet',
      VOLUME: "ОБ'ЄМ",
      VOLUMES: "ОБ'ЄМИ",
      VOYAGE: 'РЕЙС',
      'VOYAGE DESTINATION DATES': 'РЕЙС ДАТИ ПРИЗНАЧЕННЯ',
      'VOYAGE LOADING DATES': 'РЕЙС ДАТИ ЗАВАНТАЖЕННЯ',
      Valid: 'Дійсний',
      Validated: 'Підтверджено',
      Value: 'Показник',
      'Value - invoiced': 'Сума - виставлено інвойс',
      'Value - paid': 'Сума - сплачено',
      'Value alt': 'Значення альтернативне',
      'Value alternative': 'Інше значення',
      'Value general': 'Загальна вартість',
      'Value general (forward)': 'Загальна вартість (форвард)',
      'Value general (spot)': 'Загальна вартість (спот)',
      'Value no VAT': 'Сума без ПДВ',
      'Value of charge in %. Will be converted to total amount automatically, based on contract amount':
        'Вартість витрати у %. Буде автоматично розраховано на загальну суму на основі суми контракту',
      'Value of charge per 1 metric ton. Will be converted to total amount automatically, based on contract quantity':
        "Вартість витрати за 1 т. Буде автоматично розраховано в загальну суму на основі об'єму контракту",
      'Value of goods': 'Вартість товару',
      'Value per deal': 'Вартість за угоду',
      'Value per deal (forward)': 'Вартість за угоду (форвартд)',
      'Value per deal (spot)': 'Вартість за угоду (спот)',
      'Value with VAT': 'Вартість з ПДВ',
      Vat: 'ПДВ',
      Vehicle: 'Транспортний засіб',
      'Vehicle (#)': 'Транспортний засіб (№)',
      'Vehicle details': 'Деталі автомобіля',
      "Vehicle doesn't exist": 'Транспортного засобу не існує',
      'Vehicle number': 'Номер авто',
      'Vehicle registration number': 'Реєстраційний номер транспортного засобу',
      'Vehicle removed': 'Видалено транспортний засіб',
      'Vehicle restrictions': 'Обмеження для ТЗ',
      'Vehicle saved': 'Збережено транспортний засіб',
      'Vehicle subtype': 'Підтип транспортного засобу',
      'Vehicle subtypes': 'Підтипи транспортних засобів',
      'Vehicle type': 'Тип ТЗ',
      'Vehicle types': 'Типи транспортних засобів',
      'Vehicle updated': 'Оновлено транспортний засіб',
      Vehicles: 'Транспортні засоби',
      Verified: 'Перевірено',
      'Vertical groups': 'Вертикальні групи',
      Vessel: 'Судно',
      'Vessel IMO': 'IMO судна',
      'Vessel capacity': 'Місткість судна',
      'Vessel imo': 'IMO судна',
      'Vessel info': 'Інформація про судно',
      'Vessel name': 'Назва судна',
      'Vessel size': 'Розмір судна',
      'Vessel size:': 'Розмір судна:',
      'Vessel sizes': 'Розміри судна',
      'Vessel with such name and IMO already exists. Are you sure you want to save it?':
        'Судно з такою назвою та ІМО вже існує. Ви впевнені, що хочете зберегти його?',
      'Vessel year': 'Рік судна',
      Vessels: 'Судна',
      'Vessels are loaded': 'Завантажені судна',
      Vet: 'Vet',
      'Vet enterance': 'Vet внесення',
      'Vet required': 'Необхідний Vet',
      Virtual: 'Віртуальний',
      Visible: 'Наявний',
      Volume: 'Обʼєм',
      'Volume (boarded)': "Об'єм завантажений",
      'Volume (departed consignment)': "Об'єм (відправлений по накладній)",
      'Volume (departed)': "Об'єм (відправлений)",
      'Volume (lost)': "Об'єм (втрачений)",
      'Volume (real)': "Об'єм (реальний)",
      'Volume (received)': "Об'єм (отриманий)",
      'Volume (t)': "Об'єм (т)",
      'Volume Boarded': 'Обʼєм Завантажений',
      'Volume Departed': 'Обʼєм відправлений',
      'Volume Departed Real': 'Обʼєм заліковий',
      'Volume Received': "Об'єм отриманий",
      'Volume With Docs (T)': "Об'єм по документам (т)",
      'Volume accepted': 'Обсяг прийнятий',
      'Volume accepted at unloading, mt': 'Обсяг прийнятий при розвантаженні, т',
      'Volume and amounts': "Об'єм і суми",
      'Volume at end': "Об'єм в кінці",
      'Volume at start': "Об'єм на початку",
      'Volume boarded': 'Обʼєм завантажений (на борту)',
      'Volume boarded empty': 'Обʼєм завантажений порожній',
      'Volume delivered, mt': "Об'єм доставлений, т",
      'Volume departed': "Об'єм відправлений",
      'Volume departed consignment': 'Об’єм (за ТТН)',
      'Volume departed real': 'Обʼєм заліковий',
      'Volume departed, mt': "Об'єм відправлений, т",
      'Volume discharged': 'Обʼєм розвантажений',
      'Volume discount': 'Дисконт за обʼємом',
      'Volume discounted': "Об'єм із дисконтом",
      'Volume estimated': "Очікуваний об'єм",
      'Volume in contract': "Об'єм в контракті",
      'Volume in mt': "Об'єм, т",
      'Volume in positions from execution': 'Обʼєм по виконанню',
      'Volume intake': "Об'єм завантаження",
      'Volume intake sum': "Об'єм завантаження",
      'Volume lost': "Втрачений об'єм",
      'Volume max': "Об'єм макс",
      'Volume min': "Об'єм мін",
      'Volume must be greater than 0': "Об'єм повинен бути більшим за 0",
      'Volume of deal': "Об'єм угоди",
      'Volume options': "Об'єм опції",
      'Volume per deal': "Об'єм за одну угоду",
      'Volume per deal (forward)': "Об'єм за одну угоду (форвард)",
      'Volume per deal (spot)': "Об'єм за одну угоду (спот)",
      'Volume plan': "План об'єму",
      'Volume received': "Об'єм отриманий",
      'Volume received, mt': "Об'єм отриманий, т",
      'Volume reconciliation': 'Звірка обʼємів',
      'Volume rest fact before CD, mt': 'Фактичний залишок обсягу до МД, т',
      'Volume rest plan before CD, mt': 'Плановий залишок обсягу до МД, т',
      'Volume rest, mt': "Залишок об'єму, т",
      'Volume sum from positions': "Сума об'єму з позицій",
      'Volume without PCD': "Об'єм без ПМД",
      'Volume, $ with VAT': "Об'єм, usd з ПДВ",
      'Volume, mt': "Об'єм, т",
      'Volume/Goal': "Об'єм/ціль",
      Volumes: "Об'єми",
      'Volumes by terminals': "Об'єми по терміналах",
      Vote: 'Голосувати',
      Voyage: 'Рейс',
      'Voyage name': 'Назва рейсу',
      'Voyage status': 'Статус рейсу',
      'Voyage status updated': 'Стан рейсу оновлено',
      Voyages: 'Рейси',
      'Voyages Analytics': 'Аналітика рейсів',
      W: 'W',
      'W/O Broker': 'Без брокера',
      'W/o business unit': 'Без бізнес юніта',
      WAREHOUSE: 'СКЛАД',
      'WE BUY': 'МИ КУПУЄМО',
      'WE SELL': 'МИ ПРОДАЄМО',
      Wagon: 'Вагон',
      Wait: 'Зачекайте',
      'Wait a moment...': 'Зачекайте хвилину...',
      Waiting: 'Очікування',
      'Waiting for approval': 'Очікування затвердження',
      'Waiting my approval': 'Очікує мого підтвердження',
      Warehouse: 'Склад',
      'Warehouse Balance': 'Баланс складу',
      'Warehouse List': 'Список складів',
      'Warehouse Loses': 'Складські втрати',
      'Warehouse Losses': 'Складські втрати',
      'Warehouse Rest': 'Залишок на складі',
      'Warehouse Rests Report': 'Звіт про залишки на складах',
      'Warehouse Shipment': 'Відвантаження зі складу',
      'Warehouse Trade': 'Складська торгівля',
      'Warehouse balance': 'Баланс складу',
      'Warehouse deleted': 'Склад видалено',
      'Warehouse distances': 'Відстань до складу',
      "Warehouse doesn't exist": 'Склад не існує',
      'Warehouse doesn`t exist': 'Склад не існіє',
      'Warehouse name': 'Назва складу',
      'Warehouse owner': 'Власник складу',
      'Warehouse parent': 'Батьківський склад',
      'Warehouse receipts': 'Складські квитанції',
      'Warehouse type': 'Тип складу',
      'Warehouse-Rest': 'Склад-Залишки',
      Warehouses: 'Склади',
      'Warehouses balance': 'Балан складів',
      'Warehouses list': 'Список складів',
      Warm: 'Теплий',
      'Warning: More than one task has the same sequence order.':
        'Попередження: Більше ніж одне завдання має однаковий порядковий номер.',
      Washout: 'Вошаут',
      We: 'Ср',
      Website: 'Веб-сайт',
      Week: 'Тиждень',
      'Weighing addresses': 'Адреси зважування',
      'When all the certification have been resolved': 'Коли всі сертифікації будуть вирішені',
      'When payment was actually made': 'Коли оплата була фактично зроблена',
      'When you expect payment has actually been made':
        'Коли очікуєте, що оплата фактично буде здійснена',
      'When you expect payment to be made': 'Коли очікуєте, що оплата буде здійснена',
      'When you have conducted money': 'Коли провели гроші',
      'When you have issued invoice': 'Коли виставили інвойс',
      'When you have received invoice': 'Коли отримали інвойс',
      White: 'Біла',
      'Who issued invoice': 'Хто створив інвойс',
      'Who receives invoice': 'Хто отримує інвойс',
      'Whoose option': 'Яка опція',
      'Why am i seeing this': 'Чому я це бачу',
      'Will be change automatically, based on passport execution':
        'Змінюється автоматично, на основі виконання паспорта',
      'Will be change automatically, based on payment. New - no payment yet, Process - paid not in full, Paid - paid in full':
        'Зміни за оплатою будуть автоматичні. Нові - ще не оплачені, Процес - не повністю оплачені, Сплачені - сплачені в повному обсязі',
      'Will be changed automatically based on configuration':
        'Буде автоматично змінено на основі налаштування',
      'Will be filled automatically, when you set cargo execution':
        'Заповнюється автоматично, коли ви встановлюєте виконання вантажу',
      'With Business Unit': 'З бізнес-юнітом',
      'With Passport': 'З паспортом',
      'With VAT': 'З ПДВ',
      'With buyer': 'З покупцем',
      'With buyers': 'З покупцями',
      'With contracts': 'З контрактами',
      'With contracts voyage': 'З рейсами за контрактами',
      'With currency exchange': 'З обміном валюти',
      'With date confirmation': 'З датою підтвердження',
      'With date received': 'З датою отримання',
      'With date request': 'З датою заявки',
      'With derivative': 'З деривативом',
      'With destination country': 'З країною призначення',
      'With disport': 'З портом призначення',
      'With exporters': 'З експортерами',
      'With internal chain': 'З внутрішнім ланцюгом',
      'With loadport': 'З портом завантаження',
      'With mtm price': 'З ціною мтм',
      'With opposite price': 'З протилежною ціною',
      'With origin country': 'З країною походження',
      'With payment delay': 'Із затримкою оплати',
      'With ports': 'З портами',
      'With roles': 'З ролями',
      'With shipment terminal': 'З терміналом відвантаження',
      'With supplier': 'З постачальником',
      'With suppliers': 'З постачальниками',
      'With voyage': 'З рейсом',
      Withdrawal: 'Списання',
      Without: 'Без',
      'Without VAT': 'Без ПДВ',
      'Without a status': 'Без статусу',
      'Without rate': 'Без ставки',
      Wizard: 'Wizard',
      Word: 'Слово',
      'Work in progress': 'Робота в процесі',
      'Working hours': 'Робочі години',
      'Works from': 'Працює з',
      'Works till': 'Працює до',
      'Write an email': 'Створити email',
      'Write down the specific information  about this invoice':
        'Напишіть конкретну інформацію про цей інвойс',
      'Write down the specific information about this bank operation':
        'Запишіть конкретну інформацію про цю банківську операцію',
      'Write of volume': "Об'єм списання",
      'Write some update here...': 'Написати оновлення тут...',
      'Write-of volume balance': 'Відкритий баланс до списання',
      'Wrong crops': 'Неправильні культури',
      'Wrong volume': "Неправильний об'єм",
      YES: 'ТАК',
      Year: 'Рік',
      'Year %': 'Рік, %',
      'Year Budget': 'Річний бюджет',
      'Year budget': 'Річний бюджет',
      Years: 'Роки',
      Yellow: 'Жовтий',
      Yes: 'Так',
      Yesterday: 'Вчора',
      You: 'Ви',
      'You can click on buttons to display these objects inside passport.':
        "Ви можете натиснути на кнопки, щоб показати ці об'єкти всередині паспорта.",
      'You can create and apply your own statuses for passport':
        'Ви можете створити і застосувати власні статуси для паспорта',
      'You can directly go to a consolidated PNL report':
        'Ви можете безпосередньо перейти до консолідованого звіту PnL',
      'You can open information about all passports that are inside certain cell.':
        'Ви можете відкрити інформацію про всі паспорти, які знаходяться в певній клітинці.',
      'You can open the information about open deals with a position':
        'Ви можете відкрити інформацію про відкриті угоди з позицією',
      'You can pick up multiple objects, clicking with CTRL':
        "Ви можете вибрати кілька об'єктів, натиснувши CTRL",
      'You can see the quantity by each grouping criteria and passport':
        'Ви можете побачити кількість за кожним критерієм групування та паспортом',
      'You can select multiple roles, pressing CTRL':
        'Можна вибрати декілька ролей, натиснувши CTRL',
      "You can't edit approved clients": 'Ви не можете редагувати затверджених клієнтів',
      "You can't vote for this object": "Ви не можете голосувати за цей об'єкт",
      'You do not have new notifications': 'У вас немає нових повідомлень',
      'You have a long position': 'У вас довга позиція',
      'You have a short position': 'У вас коротка позиція',
      'You have voted for this object. Revote this?':
        "Ви проголосували за цей об'єкт. Переголосуйте?",
      'You need to verify Google account': 'Вам потрібно підтвердити обліковий запис Google',
      'Your charges': 'Ваші витрати',
      'Your contacts': 'Ваші контракти',
      'Your contracts': 'Ваші контракти',
      'Your counterparties': 'Ваші контрагенти',
      'Your derivatives': 'Ваші деривативи',
      'Your indicators': 'Ваші індикатори',
      'Your invoices': 'Ваші інвойси',
      "Your last payment hasn't gone through.": 'Ваш останній платіж не пройшов.',
      'Your logistics': 'Ваша логістика',
      'Your next approvals': 'Ваші наступні схвалення',
      'Your passports': 'Ваші паспорти',
      'Your request to generate a bill of lading has been declined. The volume exceedsthe permissible limits. Please verify and enter the correct volume, taking intoaccount the tolerance.':
        'Ваш запит на створення коносамента відхилено. Обсяг перевищує допустимі межі. Будь ласка, перевірте та введіть правильний обсяг, враховуючи толеранс.',
      'Your tickets': 'Ваші тікети',
      'Your updates': 'Ваші оновлення',
      'Your vessels': 'Ваші судна',
      'a country': 'країна',
      'a district': 'район',
      'a size': 'розмір',
      'a status': 'статус',
      'a supplier to which this Exporter is affiliated':
        "постачальника, з яким пов'язаний цей експортер",
      'a type': 'тип',
      'access admin': 'вхід в адмін',
      'access rights': 'права доступу',
      account: 'рахунок',
      'account name': 'рахунок',
      'account type': 'тип рахунку',
      actions: 'дії',
      active: 'активно',
      activity: 'активність',
      'actual cash amount': 'поточна сума на рахунку',
      'actual cash amount, $': 'фактична сума коштів, $',
      'actually shipped from Transport page': 'актуальні відвантаження за реєстром',
      add: 'додати',
      'add another': 'додати інше',
      'add costs': 'додати витрату',
      'add stock': 'додати запаси',
      'add stocks': 'додати залишки',
      additional: 'додатково',
      'additional info': 'додаткова інформація',
      address: 'адреса',
      admin: 'адмін',
      agent: 'посередник',
      agreement: 'угода',
      'all logistics': 'вся логістика',
      allocated: 'розподілено',
      'allocated to this passport': "з'єднано з паспортом",
      alt: 'додатково',
      alternative: 'додатковий',
      amount: 'cума',
      'amount (dbl currency) to connect': 'сума (dbl валюта) для з’єднання',
      'amount metric': 'метрика суми',
      'amount to connect': 'сума для з’єднання',
      'amount type': 'тип суми',
      'amount with vat': 'сума з ПДВ',
      'amount, $': 'сума, USD',
      'analytic parent': 'Аналітична перент група',
      analytical: 'аналітичний',
      'analytical MTM margin': 'аналітична маржа MTM',
      'analytical amount': 'аналітична сума',
      'analytical amount <> amount': 'аналітична сума <> сума',
      'analytical derivative margin': 'аналітична маржа деривативів',
      'analytical diff': 'аналітична різниця',
      'analytical physical margin': 'аналітичка фізична маржа',
      'analytical price': 'аналітична ціна',
      'analytical total margin': 'аналітична сумарна маржа',
      'analytical value': 'аналітична вартість',
      and: 'і',
      'any of logistics': 'будь-яка логістика',
      apply: 'застосувати',
      approval: 'підтвердження',
      'approval config': 'конфігурація підтвердження',
      'approval configuration': 'конфігурація підтвердження',
      'approval status': 'статус підтвердження',
      approvals: 'підтвердження',
      approved: 'підтверджено',
      'arrival end': 'закінчення прибуття',
      'arrival period': 'період прибуття',
      'arrival period end': 'закінчення періоду прибуття',
      'arrival period start': 'початок періоду прибуття',
      'arrival start': 'початок прибуття',
      assignment: 'призначення',
      'at port': 'в порту',
      'at road': 'в дорозі',
      attached: "з'єднано",
      author: 'автор',
      authors: 'автори',
      autor: 'авто',
      available: 'доступні',
      'available for allocation': 'доступні для з’єднання',
      'available to connect': "доступні для з'єднання",
      avg: 'середнє',
      'avg charges': 'середні витрати',
      'avg fixing': 'середній фіксінг',
      'avg hedging': 'середнє хеджування',
      'avg price': 'середня ціна',
      balance: 'баланс',
      'balance payment': 'оплата балансу',
      'balance to execute': 'баланс, який слід виконати',
      'balance with payment': 'баланс з оплатою',
      bank: 'банк',
      'bank account': 'банківський рахунок',
      'bank accounts': 'банківські рахунки',
      'bank branch': 'відділення банку',
      'bank details': 'банківські реквізити',
      'bank name': 'назва банку',
      bank_name: 'назва банку',
      'based on profit and loss by': 'на основі прибутків і збитків по',
      basis: 'базис',
      'basis doc': 'базовий документ',
      'basis doc with copy': 'базовий документ із копією',
      'basis doc with file': 'базовий документ із файлом',
      'basis doc without copy': 'базовий документ без копії',
      'basis doc without file': 'базовий документ без файлу',
      'basis of buyer contract': 'базис контракту покупця',
      'basis of supplier contract': 'базис контракту постачальника',
      basises: 'базиси',
      batch: 'партія',
      'before execution ends': 'до завершення виконання',
      berthing: 'причалювання',
      'bill of lading': 'коносамент',
      'billoflading number': 'коносамент номер',
      birthday: 'день народження',
      'black list': 'чорний список',
      block: 'блок',
      blocked: 'заблоковано',
      boarded: 'завантажено',
      borrowers: 'позичальники',
      broker: 'брокер',
      'broker freight costs': 'витрати брокера фрахту',
      brokerage: 'посередництво',
      budget: 'бюджет',
      'business date': 'бізнес дата',
      'business unit': 'бізнес-юніт',
      'business units': 'бізнес-юніти',
      businessunit: 'бізнес-юніт',
      buyer: 'покупець',
      'buyer balance invoices': 'інвойс балансу покупця',
      'buyer contract': 'контракт покупця',
      'buyer contract basis': 'базис контракту покупця',
      'buyer contracts': 'контракти покупця',
      'buyer invoiced': 'покупець виставив інвойс',
      'buyer managers': 'менеджери покупців',
      'buyer paid': 'покупець сплатив',
      'buyer prepay invoices': 'інвойс на передоплату покупця',
      'buyer to be invoiced': 'покупцеві виставляється інвойс',
      buyers: 'покупці',
      by: 'за',
      'by commodities': 'за товарами',
      'by crop': 'за культурою',
      'by currencies': 'за валютами',
      'by default': 'за замовчуванням',
      'by price': 'за ціною',
      'by selected': 'за обраним',
      calc: 'обчислення',
      cancel: 'скасувати',
      'cancel changes': 'скасувати зміни',
      cancelled: 'скасовано',
      'caontacts logistic prices': 'ціни на логістику контрактів',
      capacity: 'місткість',
      'capacity est': 'місткість очікувана',
      'capacity max': 'місткість макс',
      'capacity min': 'місткість мін',
      cargo: 'вантаж',
      'cargo brands': 'бренди вантажу',
      'cargo confirm': 'підтвердження вантажу',
      cargo_executed: 'виконаний за вантажем',
      category: 'категорія',
      cent: 'цент',
      'certificate scheme': 'схема сертифіката',
      chain: 'ланцюг',
      charge: 'витрата',
      charges: 'витрати',
      'charges per 1 mt': 'витрати за 1т',
      chart: 'графік',
      charterer: 'фрахтувальник',
      'choose commodity receiver': 'вибрати товароотримувача',
      'choose commodity shipper': 'вибрати вантажовідправника',
      'choose exporter': 'вибрати експортера',
      'choose file type': 'виберіть тип файла',
      'choose one or multiple recipients': 'вибрати один або кілька одержувачів',
      'choose producer': 'вибрати виробника',
      'choose shipment warehouse': 'вибрати склад відвантаження',
      'choose unloading warehouse': 'вибрати склад розвантаження',
      city: 'місто',
      'click here to expand the list of contracts inside each passport':
        'натисніть тут,щоб розширити перелік контрактів у кожному паспорті',
      client: 'контрагент',
      'client doc': 'документ контрагента',
      'client role from type': 'контрагент від',
      'client role name': 'назва ролі контрагента',
      'client role to type': 'контрагент до',
      'client role type': 'тип ролі контрагента',
      'clientrole from': 'роль контрагента від',
      'clientrole to': 'роль контрагента до',
      clients: 'контрагенти',
      clone: 'клонувати',
      close: 'закрити',
      'close window': 'закрити вікно',
      code: 'код',
      'coefficient of payments delay': 'коефіцієнт затримки оплати',
      collapse: 'колапс',
      collection: 'конфігурація',
      comment: 'коментар',
      comments: 'коментарі',
      'commisiton account': 'комісія рахунку',
      commodities: 'товари',
      commodity: 'товар',
      'commodity analytical parent': 'Батьківська аналітична категорія товару',
      'commodity receiver': 'отримувач товару',
      'commodity shipper': 'вантажовідправник',
      commodity_receiver: 'commodity_receiver',
      companies: 'компанії',
      company: 'компанія',
      'company group': 'група компаній',
      'company groups': 'групи компаній',
      complete: 'завершити',
      completion: 'виконання',
      'completion ratio': 'коефіцієнт виконання',
      compliance: 'комплаєнсу',
      'compliance status': 'статус комплаєнсу',
      concluded: 'дата укладання',
      conclusion: 'укладання',
      'conclusion date': 'дата укладання',
      'conclusion price': 'ціна при підписанні',
      condition: 'умова',
      conditions: 'умови',
      config: 'конфігурація',
      'confirmation date - the date when you confirmed the legal documents of this company':
        'дата підтвердження - дата, коли Ви підтвердили юридичні документи цієї компанії',
      confirmations: 'підтвердження',
      confirmed: 'підтверджено',
      connect: "з'єднати",
      connected: "з'єднано",
      'connected to this passport': "з'єднано з цим паспортом",
      connections: "зв'язки",
      'consecutive voyages': 'наступний рейс',
      consignees: 'вантажоодержувачі',
      consignment: 'накладні',
      'consignment contracts': 'виберіть контракти накладних',
      'consignment elevators': 'накладні елеватори',
      'consignment number': 'номер накладної',
      consignments: 'складські квитанції',
      consolidated: 'консолідований',
      contact: 'контакт',
      'contact info': 'контактна інформація',
      contacts: 'контакти',
      continue: 'продовжити',
      contract: 'контракт',
      'contract avg charges': 'середні витрати за контрактом',
      'contract business unit': 'бізнес-юніт контракту',
      'contract buyer': 'покупець контракту',
      'contract charges/gains': 'контрактні витрати/доходи',
      'contract commodity': 'товар контракту',
      'contract counterparty': 'контрагент за контрактом',
      'contract custom status': 'статус за контрактом',
      'contract incoterms': 'базис контракту',
      'contract is confirmed by cargo': 'контракт підтверджено вантажем',
      'contract number': 'номер контракту',
      'contract option': 'опція контракту',
      'contract passport': 'паспорт контракту',
      'contract payment conditions': 'умови оплати за контрактом',
      'contract price': 'ціна контракту',
      'contract status': 'статус контракту',
      'contract supplier': 'постачальник контракту',
      'contract total charges': 'загальні витрати контракту',
      'contract type': 'тип контракту',
      'contract value': 'сума контракту',
      'contract vat return value': 'вартість повернення ПДВ за контрактом',
      'contract volume': "об'єм контракту",
      contracts: 'контракти',
      'contracts avg price': 'середня ціна контракту',
      'contracts balance payment': 'балансовий платіж контракту',
      'contracts facts exceed contract volume': 'факти контрактів перевищують обсяг контрактів',
      'contracts passport custom status': 'спеціальний статус паспорту контрактів',
      'contracts passport vessel': 'паспорт контракту для судна',
      'contracts passport voyage': 'паспорт контракту для рейсу',
      'control margin': 'контроль маржі',
      controlled: 'контролюється',
      'controlled by': 'контролюється',
      'converted price': 'конвертована ціна',
      copy: 'копія',
      correspondent: 'кореспондент',
      'cost of goods sold': 'собівартість реалізованих товарів',
      costs: 'витрати',
      'costs charge': 'нарахування витрат',
      'costs distribution': 'розподіл витрат',
      'costs from purchase contracts of this passport':
        'витрати з продажних контрактів під цей паспорт',
      'costs from sale contracts of this passport': 'витрати з продажних контрактів цього паспорта',
      'costs from this passport': 'витрати з цього паспорта',
      'costs invoices': 'інвойси на витрати',
      'costs number': 'номер витрат',
      'costs opposite': 'зустрічні витрати',
      'costs vat return value': 'витрати на вартість повернення ПДВ',
      count: 'кількість',
      counterparties: 'контрагенти',
      counterparty: 'контрагент',
      'counterparty amount': 'сума контрагента',
      'counterparty bank account': 'банківський рахунок контрагента',
      'counterparty payment info': 'інформація про оплату контрагента',
      country: 'країна',
      'create template': 'створити шаблон',
      'create time': 'час створення',
      'create user': 'створити користувача',
      created: 'створений',
      'created from': 'створено з',
      creation: 'створено',
      'creation date': 'дата створення',
      creator: 'автор',
      credit: 'кредит',
      'credit accounts': 'кредит рахунок',
      'credit counterparty': 'кредит контрагента',
      'credit notes': 'кредит-ноти',
      creditor: 'кредитор',
      critical: 'критичний',
      crop: 'культура',
      'crop class': 'клас культури',
      'crop protection': 'захист посівів',
      'crop protection amount': 'захист посівів сума',
      'crop year': 'рік врожаю',
      crops: 'культури',
      'ctr price': 'ціна',
      currencies: 'валюти',
      currency: 'валюта',
      'currency & Exchange Rate': 'валюта і обмінний курс',
      'currency exchange': 'обмін валют',
      'currency rate': 'курс валют',
      current: 'поточний',
      'current delay, h': 'поточна затримка, годин',
      'current position': 'поточної позиція',
      'custom clientrole': 'роль контрагента',
      'custom status': 'статус користувача',
      customs: 'митниця',
      'customs declarations': 'митні декларації',
      'data checked': 'перевірені дані',
      date: 'дата',
      'date filters': 'фільтри дат',
      'date joined': 'дата реєстрації',
      'date of execution opposite': 'зустрічна дата виконання',
      'date range while the proposition is actual': 'термін, коли пропозиція актуальна',
      dates: 'дати',
      day: 'день',
      days: 'днів',
      'days of payments delay': 'днів затримки платежів',
      'days to load': 'днів для завантаження',
      'deal type': 'тип угоди',
      'debit accounts': 'дебетні рахунки',
      'debit counterparty': 'дебет контрагента',
      'debit/credit': 'дебет / кредит',
      debitor: 'дебітор',
      delay: 'затримка',
      delete: 'видалити',
      'delete a transfer': 'видалити перевалку',
      'delete agreement': 'видалити угоду',
      'delete collection': 'вибалити конфігурацію',
      'delete consignment': 'видалити накладну',
      'delete item': 'видалити елемент',
      'delete logistic': 'видалити логістику',
      'delete permanently': 'видалити назавжди',
      'delete report congif': 'видалити конфігурацію звіту',
      delivered: 'доставлено',
      delivery: 'доставка',
      'delivery condition': 'вид транспортування',
      'delivery conditions': 'умови доставки',
      'delivery period': 'період доставки',
      delivery_period: 'термін поставки',
      'demurrage rate': 'ставка demurrage',
      departed: 'відправлений',
      'departure stations': 'станції відправлення',
      derivative: 'дериватив',
      'derivative account': 'рахунок дериватива',
      'derivative cargo': 'культура деривативів',
      derivatives: 'деривативи',
      'derivatives margin': 'маржа деривативів',
      dest: 'призначення',
      destination: 'призначення',
      'destination 1st port': 'порт призначення 1-й',
      'destination 2nd port': 'порт призначення 2-й',
      'destination country': 'країна призначення',
      'destination of crop': 'призначення культури',
      'destination port': 'порт призначення',
      'destinations of crop': 'призначення культури',
      details: 'деталі',
      'details of the profit and loss of': 'деталі PnL',
      'df costs': 'витрати',
      'df quantity': "об'єм",
      'did not happen': 'не відбулось',
      'diff price': 'різниця ціни',
      'diff value': 'різниця значення',
      diffs: 'різниця',
      'disbursement bill of lading': 'видаткова накладна',
      'disbursement bl': 'видаткова накладна',
      disbursementBL: 'видаткова накладна',
      disbursementbl: 'видаткова накладна',
      'disch rate': 'норма розвантаження',
      'discharge approval volume': "об'єм затвердження вивантаження",
      'discharge dem/dis': 'розвантаження dem/dis',
      'discharge rate': 'норма розвантаження',
      'discharge volume': "об'єм розвантаження",
      'discharge window': 'вікно розвантаження',
      discharged: 'розвантажений',
      discount: 'дисконт',
      'discount amount': 'розмір дисконту',
      'discount mt': 'дисконт за тонну',
      'discount value': 'сума дисконту',
      'dispatch rate': 'норма відправлення',
      'disponent owner': 'диспонент-власник',
      disport: 'порт призначення',
      'distribute costs': 'розподіляти витрати',
      'distribution base': 'база розподілу',
      'distribution by cargo': 'розподіл за вантажем',
      distributor: "дистриб'ютор",
      distributors: "дистриб'ютори",
      district: 'район',
      docs: 'документи',
      'docs status': 'статус документів',
      document: 'документ',
      documents: 'документи',
      done: 'виконано',
      'download all': 'завантажити все',
      'download docx': 'завантажити docx',
      'download pdf': 'завантажити pdf',
      'due date': 'термін виконання',
      edit: 'редагувати',
      'edit a transfer': 'редагування перевалки',
      'edit changes': 'редагування зміни',
      'edit contract': 'редагувати контракт',
      editor: 'редактор',
      editors: 'редактори',
      elevator: 'елеватор',
      'elevator (with consignment)': 'елеватор (за накладною)',
      'elevator/farm': 'елеватор/ферма',
      elevators: 'елеватори',
      email: 'email',
      'email.mail.com': 'email.mail.com',
      emails: 'emails',
      'empty file': 'порожній файл',
      end: 'кінець',
      'end balance': 'кінцевий баланс',
      'end date': 'кінцева дата',
      'end of execution': 'кінець виконання',
      eng: 'англ',
      est: 'очікуваний',
      'est.': 'очікуваний.',
      'estimated lots': 'очікувані лоти',
      'estimated volume': "очікуваний об'єм",
      eta: 'eta',
      etb: 'etc',
      etc: 'etc',
      ets: 'ets',
      event: 'подія',
      events: 'події',
      'events by the system': 'події системи',
      excel: 'excel',
      'exchange fact': 'факт обмінного курсу',
      'exchange plan': 'план обмінного курсу',
      'exchange rate': 'обмінний курс',
      'exec forecast amount <> invoiced amount': 'очікувана сума <> сума виставлено інвойсу',
      'exec forecast amount <> payment amount': 'очікувана сума <> сума платежу',
      executed: 'виконано',
      'executed export volume': "виконаний об'єм експорту",
      execution: 'виконання',
      'execution amount': 'сума виконання',
      'execution month': 'місяць виконання',
      'execution period': 'термін виконання',
      'execution price': 'ціна виконання',
      'existing vessel': 'існуюче судно',
      expense: 'загальні витрати',
      expenses: 'витрати',
      export: 'експорт',
      'export / import data': 'експорт / імпорт даних',
      'export charges': 'експортні витрати',
      'export contract': 'експортний контракт',
      'export contracts': 'експортні контракти',
      exporter: 'експортер',
      'exporter contract': 'контракт експортера',
      exporters: 'експортери',
      fact: 'факт',
      'fact distributors': "факти дистриб'юторів",
      'fact suppliers/distributors': "факти постачальників / дистриб'юторів",
      fail: 'невдача',
      'fail or did not happen': 'не вдалося або не відбулося',
      failed: 'помилка',
      'failure reason': 'причина невдачі',
      farm: 'господарство',
      'farm (with consignment)': 'господарство (за накладною)',
      farms: 'господарства',
      'few logistics': 'декілька логістик',
      fields: 'поля',
      'file name': 'назва файлу',
      'fill by template': 'заповнити за шаблоном',
      filter: 'фільтр',
      'filter invoices to send': 'фільтрувати інвойси для відправлення',
      'filter transport to send': 'транспорт фільтру для відправки',
      'fin purposes': 'фінансова ціль',
      final: 'фінальний',
      'final amount': 'фінальна сума',
      'final amount <> amount': 'фінальна сума <> сума',
      'final amount <> invoiced': 'фінальна сума <> виставленого інвойсу',
      'final buyer': 'кінцевий покупець',
      'final confirm': 'фінальне підтвердження',
      'final volume': "фінальний об'єм",
      'final volume of the contract': "фінальний об'єм контракту",
      'final volume of this contract': "фінальний об'єм цього контракту",
      'finance credit account': 'кредит фінансового рахунку',
      'finance debit account': 'дебет фінансового рахунку',
      'finance type': 'тип фінансування',
      finance_account_title: 'назва фінансового рахунку',
      'financing - estimated opposite payment date':
        'фінансування - прогнозна дата зустрічна оплати',
      'financing - interest rate': 'фінансування - процентна ставка',
      'financing - loan percentage': 'фінансування - відсоток кредиту',
      'financing - payment days': 'фінансування - дні оплат',
      'first confirmation date - the date when you first confirmed the legal documents of this company':
        'перша дата підтвердження - дата, коли ви вперше підтвердили юридичні документи цієї компанії',
      'first contact = the date, when this company was added to the system':
        'перший контакт = дата, коли ця компанія була додана в систему',
      'first name': "ім'я",
      'first_contact was automatically generated': 'перший контакт був автоматично згенерований',
      'fixed price bonus': 'фіксований бонус за ціною',
      fixing: 'фіксинг',
      flag: 'прапор',
      'folder number': 'номер папки',
      for: 'для',
      'for limits': 'для лімітів',
      forward: 'форвард',
      forwarder: 'експедитор',
      'forwarder contracts': 'контракти з експедиторами',
      forwarders: 'експедитори',
      found: 'знайдено',
      free: 'вільний',
      'free export volume': 'вільний експортний об’єм',
      freight: 'фрахт',
      'freight costs': 'фрахт витрати',
      'freight opposite': 'зустрічний фрахт',
      from: 'від',
      'from company': 'від компанії',
      'from each ticket of chosen passport we will create a contract and attach it\n                        to the passport automatically':
        'з кожного тікета вибраного паспорта ми створимо контракт і додамо його\n                        в паспорт автоматично',
      'full diff': 'повна різниця',
      'full invoiced': 'повністю проінвойсовано',
      'full paid': 'повністю сплачено',
      'fully connected to passports': "повністю з'єднаний з паспортами",
      'future contact': 'наступний контакт',
      'future contact = the date, when you need to contact this company later (can be set while editing company':
        "наступний контакт - дата, коли потрібно зв'язатися з цією компанією повторно (можна вказати під час редагування компанії)",
      'futures position': "ф'ючерсна позиція",
      gains: 'доходи',
      'general agreement': 'рамкова угода',
      'general agreements': 'рамкова угода',
      'general expense': 'загальні витрати',
      'general expenses': 'загальні витрати',
      generalexpenses: 'загальні витрати',
      'give the name to your collection to find it on the Download tab':
        'дайте назву конфігурації, щоб знайти її на вкладці «Завантаження»',
      'go to details': 'перейти до деталей',
      'go to the page of the item': 'перейдіть на сторінку елемента',
      'group by': 'групувати за',
      grouping: 'групування',
      groups: 'групи',
      guarantor: 'гарант',
      ha: 'га',
      'has logistics': 'має логістику',
      'has negative balance': 'має негативний баланс',
      'has planned costs': 'має заплановані витрати',
      'has purchase contract': 'має закупівельний контракт',
      'has sale contract': 'має продажний контракт',
      hectares: 'гектари',
      hedging: 'хеджування',
      "here are shown all actions you've done in the system":
        'тут показані всі дії, які ви виконали в системі',
      'here are shown all charges you created': 'тут показано всі створені вами витрати',
      'here are shown all contacts you created': 'тут показані всі контакти, які ви створили',
      'here are shown all contracts you created': 'тут показано всі створені вами контракти',
      'here are shown all counterparties you created': 'тут показані всі створені вами контрагенти',
      'here are shown all documents that were uploaded in counterparties, contracts,\n                  invoices, passports':
        'тут показані всі документи, які були завантажені в контрагенти, договори, накладні, паспорти',
      'here are shown all indicators you created': 'тут показані всі індикатори, які ви створили',
      'here are shown all invoices you created': 'тут показано всі створені вами інвойси',
      'here are shown all objects where you are next approver':
        "тут показані всі об'єкти, де ви наступний затверджувач",
      'here are shown all operations you created': 'тут показані всі створені вами операції',
      'here are shown all passports you assigned as responsible for':
        'тут показані всі паспорти, за які ви призначили відповідальних',
      'here are shown all suppliers, buyers, brokers, farms, and others, already grouped by\n                their roles':
        'тут показані всі постачальники, покупці, брокери, ферми та інші, уже згруповані за їхніми ролями',
      'here are shown all tickets you created': 'тут показані всі створені вами тікети',
      'here are shown all updates you are responsible for':
        'тут показано всі оновлення, за які ви відповідаєте',
      'here are shown all vehicles you created':
        'тут показані всі транспортні засоби, які ви створили',
      'here are shown all vessels you created': 'тут показані всі судна, які ви створили',
      'hidden from freight': 'прихований від фрахту',
      hide: 'сховати',
      'hide executed': 'сховати виконане',
      high: 'високий',
      history: 'історія',
      hours: 'годин',
      'https://website.com': 'https://website.com',
      iban: 'iban',
      'if there is more than one notify party': 'якщо є більше однієї повідомляючої сторони',
      'if there is more than two notify party': 'якщо є більше двох повідомляючих сторін',
      'if you want to see more info about certain user press on his name':
        "якщо ви хочете побачити більше інформації про певного користувача натисніть на його ім'я",
      imo: 'imo',
      'implied freight level': 'прогнозована вартість фрахту',
      import: 'імпорт',
      'import data': 'імпорт даних',
      'import google drive': 'імпортувати з Google Drive',
      important: 'важливо',
      'inactive<translate></translate>': 'неактивно<translate></translate>',
      'inc inv qty': 'inc inv qty',
      incoming: 'вхідний',
      'incoming balance': 'вхідний баланс',
      incoterms: 'Інкотермс',
      indicator: 'індикатор',
      'individual entrepreneur': 'фізична особа-підприємець',
      info: 'інфо',
      initial: 'початковий',
      'initial cash amount': 'початкова сума',
      'initial cash amount, $': 'початкова сума готівки, $',
      'initial date': 'початкова дата',
      'initialization date': 'дата ініціалізації',
      initiator: 'ініціатор',
      'inline edit': 'внесення змін на місці',
      insured: 'застрахований',
      insurer: 'страховик',
      intake: 'завантаження',
      'interest rate delay': 'відстрочка процентної ставки',
      interest_rate: 'interest_rate',
      intermediate: 'проміжний',
      'intermediate charges': 'проміжні витрати',
      'intermediate contracts': 'проміжні контракти',
      'intermediate logistics delivery costs': 'витрати на доставку проміжної логістики',
      'internal purchases': 'внутрішня закупівля',
      'internal volume': "внутрішній об'єм",
      invoice: 'інвойс',
      'invoice (R/W)': 'інвойс (залізниця)',
      'invoice (auto)': 'інвойс (авто)',
      'invoice bank account': 'банківський рахунок інвойсу',
      'invoice client from': 'виставити інвойс контрагенту від',
      'invoice client to': 'виставити інвойс контрагенту до',
      'invoice from us': 'інвойс від нас',
      'invoice number': 'номер інвойсу',
      'invoice position': 'інвойс позиція',
      'invoice positions': 'інвойс-позиції',
      'invoice price': 'ціна інвойсу',
      'invoice to us': 'інвойс для нас',
      'invoice type': 'тип інвойсу',
      invoiced: 'виставлений інвойс',
      'invoiced <> amount': 'виставлено інвойс <> сума',
      'invoiced <> paid': 'виставлено інвойс <> сплачено',
      'invoiced cancelled': 'виставлення інвойсу скасовано',
      'invoiced logistics': 'логістика за інвойсом',
      invoices: 'інвойси',
      'is active': 'активний',
      'is gain': 'це дохід',
      'is sap registered': 'sap зареєстровано',
      'is staff': 'співробітник',
      'is superuser': 'суперкористувач',
      'is tax return registered': 'зареєстровано повернення податків',
      'issuance fee': 'комісія за видачу',
      km: 'км',
      language: 'мова',
      'last 10 days': 'останні 10 днів',
      'last 30 days': 'останні 30 днів',
      'last 7 days': 'останні 7 днів',
      'last approve time': 'час останнього підтвердження',
      'last approver': 'останній підтверджувач',
      'last login': 'останній вхід',
      'last month': 'останній місяць',
      'last name': 'прізвище',
      'last operation amount': 'сума останньої операції',
      'last operation date': 'остання дата операції',
      'last operation type': 'останні тип операції',
      'last update': 'останнє оновлення',
      'last updated': 'останнє оновлення',
      'last week': 'минулий тиждень',
      'legal entity': 'юридична особа',
      lenders: 'кредитори',
      list: 'список',
      load: 'завантаження',
      'load into a container': 'завантажити в контейнер',
      'load rate': 'норма завантаження',
      'loading 1st port': 'завантаження 1-го порту',
      'loading 2nd port': 'завантаження 2-го порту',
      'loading date': 'дата завантаження',
      'loading dem/dis': 'завантаження dem/dis',
      'loading option': 'опція завантаження',
      'loading port': 'порт завантаження',
      'loading window': 'завантаження вікна',
      loadport: 'порт завантаження',
      loans: 'позики',
      local: 'локальний',
      location: 'розподіл',
      logistic: 'логістика',
      'logistic executed volume': "виконаний об'єм логістики",
      'logistic type': 'тип логістики',
      'logistic(s) have been successfully linked to the sales contract':
        'логістика успішно була зв’язана з контрактом продажу',
      logistic_type: 'logistic_type',
      logistics: 'логістика',
      'logistics by terminal': 'логістика за терміналом',
      'logistics to invoice by terminal': 'інвойс логістики за терміналом',
      'logistics total value': 'загальна вартість логістики',
      'logistics with single purchase contract': 'логістика з єдиним контрактом закупівлі',
      long: 'довгий',
      'long net pnl mtm': 'довгий чистий pnl mtm',
      lots: 'лотів',
      low: 'низький',
      'loyalty program': 'програми лояльності',
      'loyalty program from contract': 'програма лояльності за контрактом',
      'loyalty program from disbursement bill of lading':
        'програма лояльності за видатковою накладною',
      mailings: 'розсилка',
      main: 'головний',
      'main contact': 'основний контакт',
      'make document': 'створити документ',
      manager: 'менеджер',
      managers: 'менеджери',
      'managing company': 'керуюча компанія',
      'manual enter': 'введення вручну',
      margin: 'маржа',
      'margin = value of sale contracts - value of purchase contracts  - all costs':
        'маржа = вартість продажних контрактів - вартість закупівельних контрактів - всі витрати',
      'margin = value of sale contracts - value of purchase contracts  - all costs + vat * return rate':
        'маржа = вартість продажних контрактів - вартість закупівельних контрактів - всі витрати + пдв * ставка повернення',
      'margin by month': 'маржа по місяцях',
      'margin selector': 'перемикач маржі',
      'margin with MTM': 'маржа з МТМ',
      'marine traffic': 'marine traffic',
      'mark as read': 'позначити як прочитане',
      'mark to market': 'оцінка ринку',
      'market zone': 'ринкова зона',
      'matched + open net pnl': 'збіг + відкритий чистий pnl',
      'matched contract average purchase price':
        'відповідає середньозваженій ціні покупки контракту',
      'matched contract average sale price': 'відповідає середньозваженій ціні покупки закупівлі',
      'matched contract comm fees': 'підібрані комісії за контрактом',
      'matched contract long': 'підібраний контракт довгий',
      'matched contract short': 'підібраний контракт короткий',
      'matched net pnl': 'підібраний чистий pnl',
      max: 'макс',
      measurement: 'вимірювання',
      'measurement price': 'ціна вимірювання',
      medium: 'середній',
      message: 'повідомлення',
      min: 'мін',
      'min payment / not invoiced': 'мін оплата / не виставлено інвойс',
      'min/max': 'мін/макс',
      month: 'місяць',
      mt: 'т',
      mtm: 'mtm',
      'mtm costs': 'витрати mtm',
      'mtm dtd price': 'ціна mtm dtd',
      'mtm dtd value': 'вартість mtm dtd',
      'mtm freight': 'фрахт mtm',
      'mtm ltd price': 'ціна mtm ltd',
      'mtm ltd value': 'вартість mtm ltd',
      'mtm margin': 'маржа mtm',
      'mtm passport margin': 'маржа паспорту mtm',
      'mtm per 1mt': 'за 1 т mtm',
      'mtm price': 'ціна mtm',
      'mtm volume': "об'єм mtm",
      'multi contracts': 'мультиконтракти',
      'multi services': 'мультипослуги',
      multicontract: 'мультиконтракт',
      'multicontract No.': 'мультиконтракт No.',
      'multicontract purchase': 'мультиконтракт закупівля',
      'multicontract sale': 'мультиконтракт продаж',
      multipassport: 'мультипаспорт',
      multirequest: 'мультитікет',
      multiticket: 'мультитікети',
      mv: 'mv',
      name: 'назва',
      new: 'новий',
      'new - is just created mailing, but not sent. sent - is sent. progress - we\n                        are sending it just now. failed - is failed to be sent.':
        'новий - тільки що створена розсилка, але не відправлена. відправлено - це відправлено. прогрес - \n                        ми відправляємо його зараз. невдача - не вдалося відправити.',
      'new Cashflow': 'кеш-фло',
      'new template': 'новий шаблон',
      'next approver': 'наступний підтверджувач',
      'next approvers': 'наступні підтверджуючі',
      'no customs logistics': 'без митної логістики',
      'no data': 'немає даних',
      'no elevator': 'немає елеватора',
      'no farm': 'немає господарства',
      'no forwarder': 'немає експедитора',
      'no port': 'немає порту',
      'no position': 'немає позиції',
      'no purchase contracts attached': 'не укладено закупівельних контрактів',
      'no purchase tickets attached': 'поки що відсутні закупівельні тікети',
      'no sale contracts attached': 'поки що відсутні продажні контракти',
      'no sale tickets attached': 'поки що відсутні продажні тікети',
      'no station dep': 'немає станцій відправлення',
      'no station rec': 'немає станцій отримання',
      'no status': 'без статусу',
      'no vessel': 'без судна',
      'no volume': "без об'єму",
      'normal write off coeff': 'нормальний коефіцієнт списання',
      'not allocated': "не з'єднано",
      'not done': 'не виконано',
      'not insured': 'не застраховано',
      'not invoiced': 'не виставлено інвойс',
      'not signed': 'не підписано',
      note: 'примітка',
      notes: 'примітки',
      notice: 'повідомлення',
      number: 'номер',
      object: "об'єкт",
      'object title': 'назва об’ єкту',
      'object type': "тип об'єкту",
      'of counterparties': 'контрагентів',
      'of the': 'від',
      offset: 'залік',
      'offset minus': 'від’ємний офсет',
      'offset plus': 'позитивний офсет',
      on: 'про',
      'on a page': 'на сторінці',
      'on behalf of': 'від імені',
      'on road': 'в дорозі',
      'one counterparty can have several roles, e.g, if you have sales and purchases with\n                one company, you should create one company and assign it with both roles (supplier\n                and buyer)':
        'один контрагент може мати кілька ролей, наприклад, якщо у вас є продаж і закупівля з однією компанією, \n                ви повинні створити одну компанію і призначити їй обидві ролі \n                (постачальник і покупець)',
      'one side': 'одна сторона',
      open: 'відкритий',
      'open balance': 'відкритий баланс',
      'open contract': 'відкритий контракт',
      'open contracts': 'відкриті контракти',
      'open position': 'відкрита позиція',
      'open positions': 'відкриті позиції',
      'open the modal window for editing of the item':
        'відкрити модальне вікно для редагування елемента',
      'open volume': "відкритий об'єм",
      operation: 'операція',
      opposite: 'протилежний',
      'opposite basis costs': 'зустрічний базис витрат',
      'opposite direct costs': 'протилежні прямі витрати',
      'opposite freight level': 'зустрічний фрахт',
      'opposite matched average purchase price': 'зустрічно підібрана середня ціна закупівлі',
      'opposite matched average sale price': 'зустрічно підібрана середня ціна продажу',
      'opposite matched comm fees': 'зустрічно підібрані комісії',
      'opposite matched long': 'протилежно підібрано довгий',
      'opposite matched short': 'протилежно підібрано короткий',
      'opposite passport contract costs': 'зустрічні витрати контракта паспорта',
      'opposite price': 'зустрічна ціна',
      'opposite total value costs': 'зустрічна загальна сума витрат',
      'opposite value': 'зустрічна сума',
      'opposite volume': 'зустрічний обʼєм',
      option: 'опція',
      'or select manually': 'або вибрати вручну',
      'or you can': 'або ви можете',
      ordering: 'впорядкування',
      origin: 'походження',
      'origin country': 'країна походження',
      'origin district': 'район походження',
      'origin of crop': 'походження культури',
      'origin region': 'регіон походження',
      original: 'оригінал',
      'original available': 'оригінал доступний',
      origination: 'походження',
      'origins of crop': 'походження культури',
      other: 'інший',
      'other activites': 'інше призначення',
      'other activities': 'інші призначення',
      otheractivity: 'інше призначення',
      'our amount': 'наша сума',
      'our amount <> amount': 'наша сума <> сума',
      'out inv qty': 'out inv qty',
      outgoing: 'вихідний',
      'outgoing balance': 'вихідний баланс',
      owner: 'власник',
      'owner name': 'ім’я власника',
      owners: 'власники',
      'packing size': 'розмір пакування',
      paid: 'сплачено',
      'paid (plan)': 'сплачено (план)',
      'paid <> amount': 'сплачено <> сума',
      'paid date': 'дата оплати',
      'paper trade': 'paper trade',
      parameters: 'параметри',
      'parent cargo': 'батьківська культура',
      'parent charge': 'батьківське нарахування',
      'parent indicator': 'батьківський індикатор',
      'parent indicator price': 'ціна батьківського індикатора',
      'parse active quotes': 'парсинг активних котирувань',
      passport: 'паспорт',
      'passport MTM': 'паспорт MTM',
      'passport approval status': 'статус підтвердження паспорта',
      'passport basis costs': 'витрати паспорта',
      'passport business date': 'бізнес дата паспорта',
      'passport business unit': 'бізнес-юніт паспорта',
      'passport charges': 'витрати паспорта',
      'passport commodity': 'товар паспорта',
      'passport contract costs': 'витрати контракту паспорта',
      'passport contracts': 'Контракти паспорта',
      'passport costs': 'витрати паспорта',
      'passport custom status': 'статус користувача паспорта',
      'passport execution': 'Виконання паспорта',
      'passport finance': 'Фінанси паспорта',
      'passport number': 'номер паспорта',
      'passport season': 'сезон паспорта',
      'passport status': 'статус паспорта',
      'passport vessel': 'судно паспорта',
      'passport voyage': 'рейс паспорта',
      passports: 'паспорти',
      'passports custom status': 'статуси користувача паспортів',
      payment: 'оплата',
      'payment bank account': 'банківський рахунок платежу',
      'payment commission': 'комісія за оплату',
      'payment condition': 'умова оплати',
      'payment condition option': 'опція умови оплати',
      'payment conditions': 'умови оплати',
      'payment confirm': 'оплата підтверджена',
      'payment date': 'дата оплати',
      'payment date (fact)': 'день оплати (факт)',
      'payment date (plan)': 'день оплати (план)',
      'payment days': 'дні оплати',
      'payment delay': 'відстрочка платежу',
      'payment info': 'інфо про оплату',
      'payment type': 'тип оплати',
      'payment type: {[{detail_row.payment_type}]}': 'тип оплати: {[{detail_row.payment_type}]}',
      payments: 'оплати',
      pdf: 'pdf',
      pending: 'очікується',
      per: 'за',
      'per 1': 'за 1',
      'per 1 mt': 'за 1 т',
      'per 1t': 'за 1 т',
      'per lot': 'за лот',
      'per one': 'за одну',
      'per one deal': 'за одну угоду',
      'percent conditions': 'відсоткові умови',
      percentage: 'відсоток',
      period: 'період',
      periodic: 'періодичний',
      permissions: 'повноваження',
      phone: 'телефон',
      'phone number': 'номер телефону',
      'physical person': 'фізична особа',
      picture: 'аватар',
      'place of shipment / unloading': 'місце відвантаження / розвантаження',
      plan: 'план',
      'plan distributors': "план дистриб'юторів",
      port: 'порт',
      'port destination': 'порт призначення',
      'port destination country': 'країна порту призначення',
      'port loading': 'порт завантаження',
      'port loading country': 'порт країни завантаження',
      ports: 'порти',
      'ports opposite': 'порти зустрічні',
      position: 'позиція',
      'position concluded': 'позиція виключена',
      'position number': 'номер позиції',
      'position statuses': 'статуси позиції',
      'positons custom status': 'користувацький статус позицій',
      'positons season': 'сезон позицій',
      'pre PCD logistic volume': "Об'єм логістики до ПМД",
      'pre advice': 'преадвайс',
      'prefix for outgoing invoices': 'префікс № для вихідних інвойсів',
      premium: 'преміум',
      'premium price': 'преміум ціна',
      prepay: 'передоплата',
      prepayment: 'передоплата',
      preview: 'попередній перегляд',
      price: 'ціна',
      'price create': 'ціна створена',
      'price for current vehicle differs to contract':
        'ціна на поточний транспортний засіб відрізняється від контрактної',
      'price from transshpment from cars': 'ціна від перевалки з авто',
      'price includes VAT': 'ціна включає ПДВ',
      'price of current deal + its cost + your yield (set in settings) = the sale price by which this deal should be sold to satisfy the requirements of your yield':
        'ціна поточної угоди + її вартість + Ваша рентабельність (встановлюється в параметрах) = ціна продажу, за якою ця угода повинна бути продана, щоб задовольнити вимоги рентабельності',
      'price of current deal + its costs = the real costs of the deal':
        'ціна поточної угоди + її витрати = реальна вартість угоди',
      'price of current deal - its cost - your yield (set in settings) = the purchase price by which this deal should be purchased to satisfy the requirements of your yield':
        'ціна поточної угоди - її вартість - Ваша рентабельність (встановлюється в параметрах) = закупівельна ціна, за якою ця угода повинна бути придбана ,щоб задовольнити вимоги рентабельності',
      'price of current deal - its costs = the real income of  the deal':
        'ціна поточної угоди - її витрати = реальний дохід від угоди',
      prices: 'ціни',
      print: 'друкувати',
      process: 'процес',
      producer: 'виробник',
      producers: 'виробники',
      product: 'товар',
      'production year': 'рік виробництва',
      'profit and loss': 'прибуток і збитки',
      progress: 'прогрес',
      property: 'показник',
      'protein * volume': "протеїн*об'єм",
      purchase: 'закупівля',
      'purchase Contr. No.': 'закупівельний контракт №.',
      'purchase Contr. Volume, UAH': 'сума закупівельного контракту, UAH з ПДВ',
      'purchase Contr. Volume, mt': "об'єм (т) закупівельного контракту",
      'purchase Contract Date': 'дата закупівельного контракту',
      'purchase basis': 'базис закупівлі',
      'purchase contract': 'закупівельний контракт',
      'purchase contract author': 'автор закупівельного контракту',
      'purchase contract basis': 'базис закупівельного контракту',
      'purchase contract buyer': 'покупець закупівельного контракту',
      'purchase contract custom status': 'спеціальний статус закупівельного контракту',
      'purchase contract number': 'номер закупівельного контракту',
      'purchase contract payment condition option': 'опція умови оплати закупівельного контракту',
      'purchase contract responsible': 'закупівельний контракт відповідальний',
      'purchase contract supplier': 'постачальник закупівельного контракту',
      'purchase contracts': 'закупівельні контракти',
      'purchase contracts logistics buyer invoices':
        'інвойс покупця логістики закупівельного контракту',
      'purchase destinations': 'місце призначення закупівлі',
      'purchase invoice prepay': 'передоплата за інвойсом на закупівлю',
      'purchase origins': 'закупівля походження',
      'purchase sap order': 'закупівля sap номери замовлень',
      'purchase supplier': 'постачальник закупівель',
      'purchase suppliers': 'постачальники закупівель',
      'purchase ticket': 'закупівельний тікет',
      'purchases & sales': 'закупівля та продаж',
      purpose: 'призначення',
      'push this button after applying filters to find a logistics you want to send\n                        by email.':
        'натисніть цю кнопку після застосування фільтрів, щоб знайти логістику, яку ви хочете надіслати\n електронною поштою.',
      qty: "об'єм",
      quality: 'якість',
      'quality enterance': 'якість внесення',
      quantity: 'кількість',
      'quantity of containers': "об'єм контейнерів",
      'quantity of measurement': "об'єм вимірювання",
      'quantity total amount': "загального сума об'єму",
      rate: 'ставка',
      read: 'прочитано',
      ready: 'готово',
      'real capacity': 'реальна місткість',
      'realized value': 'реалізована вартість',
      reassignment: 'переуступка',
      receipt: 'отримання',
      received: 'отримано',
      'received date - the date when you received the legal documents of this company':
        'дата отримання - дата, коли Ви отримали юридичні документи по компанії',
      'received real': 'отримано реально',
      'received volume': "отриманий об'єм",
      receiver: 'одержувач',
      'receiver code': 'код отримувача',
      receivers: 'одержувачі',
      'receiving date': 'дата отримання',
      'receiving stations': 'станція отримання',
      refresh: 'оновити',
      'refresh totals': 'оновити підсумкові дані',
      region: 'регіон',
      'regional managers': 'регіональні менеджери',
      'regions, where this counterparty operate mostly':
        'регіони, де переважно працює даний контрагент',
      'related counterparties': "пов'язані контрагенти",
      'related counterparty': "пов'язаний контрагент",
      'related invoice': 'пов’язаний інвойс',
      'related to': "пов'язані з",
      relations: 'відносини',
      remains: 'залишки',
      'rename file': 'перейменувати файл',
      report: 'звіт',
      'report column type': 'тип стовпця звіту',
      'request date': 'дата заявка',
      requested: 'запрошені',
      'requested date - the date when you requested the legal documents of this company':
        'дата запиту - дата, коли ви запросили юридичні документи по компанії',
      required: "обов'язкові",
      reservation: 'бронювання',
      responsibilities: 'відповідальность',
      responsibility: 'відповідальність',
      responsible: 'відповідальний',
      'responsible confirmation': 'відповідальне підтвердження',
      'responsible for compliance': 'відповідальний за комплаєнс',
      'responsible for confirm': 'відповідальний за підтвердження',
      'responsible for signing': 'відповідальний за підпис',
      'responsible signing': 'відповідальний за підпис',
      'responsible users': 'відповідальні користувачі',
      responsible_for_signing: 'відповідальний за підпис',
      rests: 'залишки',
      'rests groupping': 'групування залишків',
      return: 'повернення',
      risk: 'ризик',
      road: 'в дорозі',
      'road volume': "об'єм в дорозі",
      role: 'роль',
      roles: 'ролі',
      rolling: 'ролінг',
      sailed: 'відчалив',
      sale: 'продаж',
      'sale Contr. No.': 'продажний контракт №.',
      'sale Plan': 'план продажу',
      'sale and purchases of': 'продажу та закупівлі',
      'sale buyer': 'покупець продажу',
      'sale buyers': 'покупці продажу',
      'sale commodity': 'товар продажу',
      'sale contract': 'продажний контракт',
      'sale contract basis': 'базис продажного контракту',
      'sale contract buyer': 'покупець продажного контракту',
      'sale contract commodity': 'товар продажного контракту',
      'sale contract number': 'номер продажного контракту',
      'sale contract supplier': 'постачальник продажного контракту',
      'sale contracts': 'продажні контракти',
      'sale destinations': 'пункт призначення продажу',
      'sale invoice prepay': 'передоплата за інвойсом на продаж',
      'sale origins': 'походження продажу',
      'sale owner': 'власник продажу',
      'sale price multiplicator': 'мультиплікатор ціни продажу',
      'sale sap order': 'продаж sap замовлення',
      'sale ticket': 'продажний тікет',
      'sales manager': 'регіональний представник',
      'sales manager head': 'керівник відділу продажів',
      'sap order': 'sap номер замовлення',
      'sap orders': 'sap номери замовлень',
      'sap registered': 'sap зареєстровано',
      save: 'зберегти',
      'save a transfer': 'зберегти перевалку',
      'save and continue': 'зберегти та продовжити',
      'save and continue editing': 'зберегти та продовжити редагування',
      'save changes': 'зберегти зміни',
      'save collection': 'зберегти конфігурацію',
      'save report congif': 'зберегти конфігурацію звіту',
      season: 'сезон',
      seasons: 'сезони',
      seeds: 'посіви',
      'seeds amount': 'посіви сума',
      'select a season for a position': 'вибрати сезон для позиції',
      'select and rename any of the fields you want to save for an Excel file\n                  donwload':
        'виберіть і перейменуйте будь-які поля, які потрібно зберегти для файлу Excel завантажити',
      'select current business unit for editing': 'виберіть для редагування поточний бізнес-юніт',
      'select current collection for editing or select New collection to create':
        'виберіть поточну конфігурацію для редагування або виберіть Нову конфігурацію для створення',
      'select emails you want to send':
        'виберіть emails , які хочете відправити, які хочете відправити',
      'select one from templates for sending': 'вибрати один з шаблонів для відправки',
      'select one or multiple to display a position':
        'виберіть одну або декілька позицій для відображення',
      'select single option to display child commodities':
        'виберіть один варіант для відображення дочірніх товарів',
      'self-cost': 'собівартість',
      'self-cost = (value of goods purchase + value of purchase costs) / volume of sale':
        "собівартість = (вартість закупівлі товару + вартість витрат на закупівлю) / об'єм продажу",
      'self-cost per 1t': 'собівартість за 1т',
      'self-cost-including-all-costs = (purchase value without VAT + sale contract charge value without VAT + purchase contract charge value without VAT + value intermediate contracts + value passport without VAT) / volume of sale':
        'собівартість-включно-всі-витрати = (купівельна вартість без ПДВ + нарахована вартість договору купівлі-продажу без ПДВ + нарахована вартість договору купівлі-продажу без ПДВ + вартість проміжних контрактів + паспортна вартість без ПДВ) / обсяг продажу',
      selfcost: 'собівартість',
      'send a report': 'відправити звіт',
      senders: 'відправники',
      sent: 'відправлено',
      service: 'послуга',
      'service contract': 'сервісний контракт',
      'service costs': 'витрати на послуги',
      services: 'послуги',
      set: 'встановити',
      'set a prices to evaluate a position': 'встановити ціни для оцінки позиції',
      shipment: 'відвантаження',
      'shipment elevators': 'відвантаження елеваторів',
      'shipment period': 'період виконання',
      'shipment period of the contract': 'період відвантаження контракту',
      'shipment status': 'статус відвантаження',
      'shipment terminal': 'термінал відвантаження',
      'shipment terminals': 'термінали відвантаження',
      'shipment warehouse': 'склад відвантаження',
      'shipment warehouses': 'склади відвантаження',
      shipped: 'відвантажено',
      'shipped by logistics': 'відвантажено логістикою',
      short: 'шорт',
      'short net pnl mtm': 'короткий чистий pnl mtm',
      'short/long': 'короткий/довгий',
      shortage: 'шортадж',
      'shortage fee': 'shortage витрати',
      'shortage quantity': "shortage об'єм",
      'shortage rate': 'shortage ставка',
      'show all stock': 'показати всі залишки',
      'show expired': 'показати неактуальні',
      'show expired tariffs': 'показати неактуальні тарифи',
      'side from': 'сторона від',
      'side from status': 'сторона від статусу',
      'side to': 'сторона кому',
      'side to status': 'сторона до статусу',
      sides: 'сторони',
      signature: 'підпис',
      signed: 'підписаний',
      simple: 'проста',
      'single document size up to 1 mb': 'розмір одного документа до 1 мб',
      'single document size up to 50 mb': 'розмір одного документа до 50 мб',
      'single file size up to 50 mb (depends on your SMTP provider)':
        'розмір одного файлу до 50 мб (залежить від постачальника SMTP)',
      source: 'джерело',
      spot: 'спот',
      start: 'початок',
      'start Date': 'Дата початку',
      'start balance': 'початковий баланс',
      'start date': 'дата початку',
      'start of execution': 'початок виконання',
      station: 'станція',
      'station arrival': 'станція отримання',
      'station departing': 'станція відправлення',
      'station departure': 'станція відправлення',
      'station deps': 'станція відправлення',
      'station receiving': 'станція отримання',
      'station recs': 'станція отримання',
      status: 'статус',
      'stowage factor': 'коефіцієнт укладання',
      submit: 'підтвердити',
      'submit documents': 'підтвердити документи',
      subuse: 'підвикористання',
      subuses: 'підвикористання',
      success: 'успішно',
      superuser: 'суперкористувача',
      supplier: 'постачальник',
      'supplier balance invoices': 'інвойс балансу постачальників',
      'supplier contract': 'контракт постачальника',
      'supplier contract basis': 'базис контракту постачальника',
      'supplier contracts': 'контракти постачальників',
      'supplier invoiced': 'постачальник виставив рахунок',
      'supplier managers': 'менеджери постачальників',
      'supplier paid': 'постачальник сплатив',
      'supplier prepay invoices': 'інвойси постачальника на передоплату',
      'supplier to be invoiced': 'постачальник виставив інвойс',
      suppliers: 'постачальники',
      'suppliers of purchase contracts logistics buyer invoices':
        'виберіть постачальників закупівельних контрактів',
      'suppliers/distributors': "постачальники/дистриб'ютори",
      surveyor: 'сюрвеєр',
      swift: 'swift',
      t: 'т',
      't/day': 'т/ добу',
      't/h': 'т/год',
      tasks: 'завдання',
      'tax return registered': 'повернення податків зареєстровано',
      telegram: 'telegram',
      'telegram username': 'нікнейм Telegram',
      template: 'шаблон',
      terminal: 'термінал',
      'terminal (with consignment)': 'термінал (за накладною)',
      'terminal volume': "об'єм терміналу",
      terminals: 'термінали',
      text: 'текст',
      'the actual tolerance range of the contract': 'фактичний толеранс контракту',
      'the full title of the legal entity (for usage in documents)':
        'повна назва юридичної особи (для використання в документах)',
      'the legal address of the legal entity': 'юридична адреса контрагента',
      'the list of quality for this deal': 'перелік якості для цієї угоди',
      'the list of users, who approved this deal': 'список користувачів, які підтвердили цю угоду',
      'the number of contracts that involve this company. Click on the counter and you will see them all':
        'кількість контрактів, що стосуються цієї компанії. Натисніть на лічильник, щоб переглянути все',
      'the number of documents attached to this company. Click on the counter and you will see them all':
        'кількість документів, що додаються до цієї компанії. Натисніть на лічильник, щоб переглянути все',
      'the number of documents attached to this driver. Click on the counter and you will see them all':
        'кількість документів, що додаються до цього водія. Натисніть на лічильник, і ви побачите їх усіх',
      'the number of invoices that involve this company. Click on the counter and you will see them all':
        'кількість інвойсів, що стосуються цієї компанії. Натисніть на лічильник, щоб переглянути все',
      'the number of issued invoiced by this contract. To know more - go to contract details':
        'кількість виставлених інвойсів, які були оформлені за цим контрактом. Щоб дізнатися більше - перейдіть до деталей контракту',
      'the number of logistics that involve this company':
        'кількість логістики, що включає ця компанія',
      'the number of persons added by this company. Click on the counter and you will see them all':
        'кількість контактів, додана по цій компанії. Натисніть на лічильник, щоб переглянути все',
      'the number of updates and the last one added by this company. Click on the counter and you will see them all':
        'кількість оновлень і останнє, що додається цією компанією. Натисніть на лічильник, щоб переглянути все',
      'the open balance of the contract': 'відкритий баланс контракту',
      'the person who will be appointed in documents': 'особа, яка буде призначена в документах',
      'the roles in which this legal entity will be used in the system. It could be multiple roles, e.g. Buyer and Seller':
        'ролі, які буде використовувати в системі контрагент. Може бути кілька ролей (наприклад, Покупець і Продавець)',
      'the short title of the role (for internal use)':
        'коротка назва ролі (для внутрішнього використання)',
      'the status is changed by setting dates of confirmations of counterparty':
        'зміна статусу шляхом встановлення дати підтвердження контрагента',
      'the value of the deal (except charges)': 'сума угоди (за винятком витрат)',
      'the volume, connected to passports': "об'єм, пов'язаний з паспортами",
      'the volume, delivered to port': "об'єм, доставлений до порту",
      'the volume, departed to port': "об'єм, відправлений до порту",
      'the volume, for transport from open balance calculation':
        "об'єм транспорту з розрахунку відкритого балансу",
      'the volume, from transport by basis': "об'єм логістики по базису",
      'the volume,that is currently on the road': "об'єм, який зараз у дорозі",
      'this button will send all chosen emails':
        'за допомогою цієї кнопки можна відправити всі вибрані листи електронною поштою',
      'this counterparty can confirm other counterparties. You can see it in the header of the page COUNTERPARTIES - > CONFIRMATIONS':
        'цей контрагент може підтвердити інших контрагентів',
      'this field is calculated automatically': 'це поле обчислюється автоматично',
      'this margin is chosen for P&L report by margin selector':
        'ця маржа вибирається для звіту PnL за допомогою перемикача маржі',
      'this month': 'цього місяця',
      'this page was designed the main settings of the company. Only admin can do\n              this':
        'на цій сторінці були розроблені основні налаштування компанії. Тільки адміністратор має \n              доступ',
      'this page was designed to manage master data of the system. Only admin can do\n                this':
        'ця сторінка була розроблена для управління основними даними системи. Тільки адміністратор має \n              доступ',
      'this passport use one side deal': 'цей паспорт використовує односторонню угоду',
      'this week': 'цього тижня',
      ticket: 'тікет',
      tickets: 'тікети',
      time: 'час',
      'time, date and user, who created this deal':
        'час, дата та користувач, який створив цю угоду',
      title: 'назва',
      to: 'до',
      'to company': 'до компанії',
      'to deliver and sale at': 'доставки та продажі в',
      'to open actions menu on certain item, press on its name':
        'щоб відкрити меню дій над певним елементом, натисніть на його назву',
      'to pay': 'сплатити',
      tolerance: 'толеранс',
      tomorrow: 'завтра',
      tones: 'тони',
      tonnes: 'тонн',
      topic: 'тема',
      total: 'всього',
      'total amount': 'загальна сума',
      'total amount _metric': 'total amount _metric',
      'total approve time, h': 'загальний час підтвердження, годин',
      'total charges': 'всього витрат',
      'total connected': "всього з'єднано",
      'total costs': 'загальні витрати',
      'total days of payments delay': 'кількість днів затримки платежів',
      'total mtm amount': 'загальна сума мтм',
      'total mtm costs': 'загальні витрати MTM',
      'total mtm value': 'загальна mtm вартість',
      'total value': 'загальна сума',
      'total value analytical': 'загальне значення аналітичне',
      'total value discounted': 'загальна сума з дисконтом',
      'total volume': "загальний об'єм",
      totals: 'підсумки',
      transaction: 'транзакція',
      'transaction date': 'дата транзакції',
      transit: 'транзит',
      transport: 'транспорт',
      'transport open balance volume': 'обсяг відкритого балансу транспорту',
      turnover: 'оборот',
      'turnover groupping': 'групування обороту',
      type: 'тип',
      'type of activities': 'види діяльності',
      types: 'типи',
      'under each passport you will see the one costs, split on two 500 and 500.':
        'під кожним паспортом ви побачите одну вартість, розділену на дві частини 500 і 500.',
      unexecuted: 'невиконано',
      'uninvoiced charge': 'невиставлена плата',
      unit: 'юніт',
      units: 'юніти',
      'unloading terminals': 'термінали розвантаження',
      'unloading warehouse': 'склад завантаження',
      'unloading warehouses': 'склади завантаження',
      'until execution starts': 'до початку виконання',
      'up to 30 days': 'до 30 днів',
      update: 'оновлено',
      'update time': 'час оновлення',
      update_time: 'update_time',
      updated: 'оновлено',
      updates: 'оновлення',
      'usd price': 'ціна usd',
      use: 'використання',
      'use a checkbox to completely hide/show field from the table or use the\n                        button Users to customize the visibility for particular person':
        'скористайтеся прапорцем, щоб повністю приховати/показати поле з таблиці, або \n                        скористайтеся кнопкою Користувачі, щоб налаштувати права доступу для конкретної особи',
      'use a filter button on the right top corner of the page to filter the\n                        list':
        'використовуйте кнопку фільтра у правому верхньому куті сторінки, щоб відфільтрувати \n                        список',
      'use a update button on the right top corner of the page to get the latest\n                        data without refreshing the page':
        'скористайтеся кнопкою оновлення у правому верхньому куті сторінки, щоб отримати \n                        найновіші дані без оновлення сторінки',
      'use convertation': 'використовуйте конвертацію',
      'use favorite filters button above the table. You can save sets of different\n                        filters and use them in a single click. This can be managed in the filters\n                        sidebar':
        'використовуйте кнопку улюблених фільтрів над таблицею. Ви можете зберігати нові набори\n                         різних фільтрів і використовувати їх одним натисканням. Налаштовувати можна\n                         за допомогою бічної панелі фільтрів',
      user: 'користувач',
      'user created': 'користувача створено',
      'username / login': 'нікнейм / логін',
      'users approved this counterparty': 'користувачі підтвердили контрагента',
      uses: 'використання',
      validated: 'перевірений',
      value: 'значення',
      vat: 'пдв',
      'vat = VAT CARGO SALE - (VAT CARGO PURCHASE + VAT CHARGES SALE + VAT CHARGES PURCHASE )':
        'пдв = ПДВ вантаж продажу - (ПДВ вантаж закупівлі + ПДВ витрати продажу + ПДВ витрати закупівлі)',
      'vat return': 'повернення ПДВ',
      'vat return rate': 'ставка повернення ПДВ',
      'vehicle number': 'номер транспортного засобу',
      vehicles: 'транспортні засоби',
      vessel: 'судно',
      'vessel Managing company': 'Керуюча компанія судна',
      'vessel owner': 'власник судна',
      'vessel size': 'розмір судна',
      virtual: 'віртуальний',
      volume: 'обʼєм',
      'volume / free': "об'єм / вільний",
      'volume departed': "об'єм відправлений",
      'volume departed consignment': 'об’єм (за ТТН)',
      'volume discharged': "об'єм розвантажений",
      'volume intake': "об'єм завантаження",
      'volume loaded': "об'єм завантажений",
      'volume lost': "об'єм втрачений",
      'volume max': "об'єм максимальний",
      'volume metric': "Метрика об'єму",
      'volume min': "об'єм мінімальний",
      'volume received': "об'єм отриманий",
      'volume without pcd': "об'єм без пмд",
      volume_freighted: "об'єм фрахт",
      'volumes by month': 'кількість по місяцям',
      vote: 'голосувати',
      voyage: 'рейс',
      'w/o passport': 'без паспорта',
      warehouse: 'склад',
      'warehouses (all)': 'складськи (всі)',
      'was made from this indicator': 'зроблено з індикатора',
      'was made from this ticket': 'зроблено із тікета',
      washout: 'вошаут',
      website: 'веб-сайт',
      with: 'з',
      'with VAT': 'з ПДВ',
      'with docs': 'з документами',
      "with sale contract's passports?": 'з паспортами продажних контрактів?',
      'with selected passport?': 'з обраним паспортом?',
      'without VAT': 'без ПДВ',
      'without invoice, offset, reassignment': 'без інвойсу, заліку, переуступки',
      wizard: 'wizard',
      word: 'слово',
      'would require deleting the following protected related objects:':
        "необхідно видалити наступні захищені пов'язані об'єкти:",
      'write a response': 'написати відповідь',
      year: 'рік',
      years: 'років',
      yes: 'так',
      yesterday: 'вчора',
      'you can add new one': 'ви можете додати новий',
      'you can create a new vessel and attach it to this passport':
        "можна створити нове судно і прив'язати його до цього паспорта",
      'you can download any kit of fields for Export, created on Collection tab':
        'ви можете завантажити будь-який набір полів для експорту, створений на вкладці конфігурація',
      'you can make your own configuration of export and download the table in a\n                        certain EXCEL format':
        'ви можете зробити власну конфігурацію експорту і завантажити таблицю \n                        в певному форматі EXCEL',
      'you can manage email or messenger notifications on system events':
        'ви можете керувати повідомленнями email або месенджера про системні події',
      'you can manage the columns and their ordering pressing setting icon above\n                        the table':
        'ви можете керувати стовпцями та їх упорядкуванням, натиснувши значок налаштування \n                        над таблицею',
      'you can now click': 'тепер ви можете натиснути',
      'you can print any documents from predefined template':
        'ви можете надрукувати будь-які документи з попередньо визначеного шаблону',
      'you can start the whole approval process once again':
        'можна почати весь процес підтвердження ще раз',
      'you can switch different views of the table using buttons on the right top\n                        of the table':
        'ви можете перемикати різні види таблиці за допомогою кнопок у правій верхній частині\n                        таблиці',
      'you can validate the counterparty as per predefined approval configuration':
        'ви можете підтвердити контрагента за попередньо визначеною конфігурацією підтвердження',
      'you can validate the passport as per predefined approval configuration':
        'ви можете підтвердити паспорт за попередньо визначеною конфігурацією підтвердження',
      'you can write an email to a client with predefined templates':
        'ви можете написати email контрагенту з попередньо визначеними шаблонами',
      'you have limit on {[{ $root.user.settings.USERS_MAX_COUNT }]} active users':
        'у вас є обмеження на {[{ $root.user.settings.USERS_MAX_COUNT }]} активних користувачів',
      '{[{ $ctrl.$rootScope.user.settings.DEFAULT_CURRENCY }]}':
        '{[{ $ctrl.$rootScope.user.settings.DEFAULT_CURRENCY }]}',
      '{[{ $ctrl.contract.approval_status }]}': '{[{ $ctrl.contract.approval_status }]}',
      '{[{ $ctrl.contract.contract_type }]}': '{[{ $ctrl.contract.contract_type }]}',
      '{[{ $ctrl.finance.approval_status }]}': '{[{ $ctrl.finance.approval_status }]}',
      '{[{ $ctrl.multicontract.contract_type }]}': '{[{ $ctrl.multicontract.contract_type }]}',
      '{[{ $ctrl.queryParams.margin_selector }]}': '{[{ $ctrl.queryParams.margin_selector }]}',
      '{[{ $ctrl.request.approval_status }]}': '{[{ $ctrl.request.approval_status }]}',
      '{[{ $ctrl.request.contract_type }]}': '{[{ $ctrl.request.contract_type }]}',
      '{[{ $ctrl.status }]}': '{[{ $ctrl.status }]}',
      '{[{ $root.gtUtils.getLocalCurrency() | translate }]}':
        '{[{ $root.gtUtils.getLocalCurrency() | translate }]}',
      '{[{ $root.user.settings.DEFAULT_CURRENCY }]}':
        '{[{ $root.user.settings.DEFAULT_CURRENCY }]}',
      "{[{ 'ISCC'|translate }]}": "{[{ 'ISCC'|translate }]}",
      '{[{ client.approval_status }]}': '{[{ client.approval_status }]}',
      '{[{ column.title }]}': '{[{ column.title }]}',
      '{[{ columnSet.title }]}': '{[{ columnSet.title }]}',
      '{[{ consignment.object_subtype }]}': '{[{ consignment.object_subtype }]}',
      '{[{ consignment.object_type }]}': '{[{ consignment.object_type }]}',
      '{[{ contract.approval_status }]}': '{[{ contract.approval_status }]}',
      "{[{ derivative.month | date:'MMM,yy' }]}": "{[{ derivative.month | date:'MMM,yy' }]}",
      '{[{ detail_row.finance_type }]}': '{[{ detail_row.finance_type }]}',
      '{[{ detail_row.report_column_type }]}': '{[{ detail_row.report_column_type }]}',
      '{[{ finance.finance_type }]}': '{[{ finance.finance_type }]}',
      '{[{ finance.report_column_type }]}': '{[{ finance.report_column_type }]}',
      '{[{ invoice.status }]})': '{[{ invoice.status }]})',
      '{[{ item.approval_status }]}': '{[{ item.approval_status }]}',
      '{[{ item.counterparty_approval_status }]}': '{[{ item.counterparty_approval_status }]}',
      '{[{ item.cp_approval_status }]}': '{[{ item.cp_approval_status }]}',
      '{[{ item.generalagreement_status }]}': '{[{ item.generalagreement_status }]}',
      '{[{ item.request_status }]}': '{[{ item.request_status }]}',
      '{[{ item.status }]}': '{[{ item.status }]}',
      '{[{ item.title }]}': '{[{ item.title }]}',
      '{[{ level.confirmation_logic | uppercase }]}':
        '{[{ level.confirmation_logic | uppercase }]}',
      "{[{ month['title'] }]}": "{[{ month['title'] }]}",
      '{[{ request.approval_status }]}': '{[{ request.approval_status }]}',
      "{[{ side.role_name == 'supplier' ? 'seller' : side.role_name }]}":
        "{[{ side.role_name == 'supplier' ? 'seller' : side.role_name }]}",
      '{[{ total.title}]}': '{[{ total.title}]}',
      '{[{ type.title }]}': '{[{ type.title }]}',
      '{[{ vm.logentry.change_message }]}': '{[{ vm.logentry.change_message }]}',
      '{[{ vm.mode }]}': '{[{ vm.mode }]}',
      '{[{ vm.passport.approval_status }]}': '{[{ vm.passport.approval_status }]}',
      '{[{ vm.title }]}': '{[{ vm.title }]}',
      '{[{$ctrl.certificateRepr[certificateType]}]}':
        '{[{$ctrl.certificateRepr[certificateType]}]}',
      '{[{$ctrl.mode}]}': '{[{$ctrl.mode}]}',
      '{[{$ctrl.selectedGroups.indexOf(level) + 1}]}. {[{ level.title }]}':
        '{[{$ctrl.selectedGroups.indexOf(level) + 1}]}. {[{ level.title }]}',
      '{[{certificateType}]}': '{[{certificateType}]}',
      "{[{contractInfo.crop_title || '---' }]}": "{[{contractInfo.crop_title || '---' }]}",
      '{[{contractInfo.open_balance_sum || 0 | number:\n                                        3}]}':
        '{[{contractInfo.open_balance_sum || 0 | number:\n                                        3}]}',
      '{[{contractInfo.volume_sum || 0 | number: 3 }]}':
        '{[{contractInfo.volume_sum || 0 | number: 3 }]}',
      '{[{group.title}]}': '{[{group.title}]}',
      '{[{item.use}]}': '{[{item.use}]}',
      '{[{row.client}]}': '{[{row.client}]}',
      '{[{row.currency}]}': '{[{row.currency}]}',
      '{[{rowType.title}]}': '{[{rowType.title}]}',
      '{[{stamp.text}]}': '{[{stamp.text}]}',
      '{{ $ctrl.errors.ext }}': '{{ $ctrl.errors.ext }}',
      '{{ $ctrl.errors.resourceObjId }}': 'ctrl.errors.resourceObjId }}',
      '{{ $ctrl.errors.responseResult }}': '{{ $ctrl.errors.responseResult }}',
      '{{ $ctrl.errors.templateText }}': '{{ $ctrl.errors.templateText }}',
      '{{ $ctrl.verboseName }}': '{{ $ctrl.verboseName }}',
      '{{ contentType.title }}': '{{ contentType.title }}',
      '{{ derivative.commodity }}': '{{ derivative.commodity }}',
      '{{ derivative.title }}': '{{ derivative.title }}',
      '{{ role.role_name }}': '{{ role.role_name }}',
      'Нas Debit/Credit Cargo': 'Є Дебет/Кредит по товару',
      'Нas Debit/Credit Contract': 'Є Дебет/Кредит по контракту',
      'Нas Debit/Credit Invoice': 'Є Дебет/Кредит по інвойсу',
      Сourier: "Кур'єр",
      сashback: 'кешбек',
      іnvoices: 'інвойси',
      '✅Approve/❌Decline': '✅Схвалити / ❌Відхилити',
      '💬 Add comment': '💬 Додати коментар',
      '🔗 Open in browser': '🔗 Відкрити в браузері',
    });
    gettextCatalog.setStrings('uk', {
      'No credentials found for the current file.':
        'Для поточного файлу не знайдено жодних облікових даних.',
      'The credentials have expired or are invalid. Please click the Refresh Google credentials button to re-authorize.':
        'Облікові дані вичерпали термін дії або є недійсними. Будь ласка, натисніть кнопку ‘Оновити облікові дані Google’, щоб повторно авторизуватися',
      'The credentials for the file owner ({}) are invalid or expired. Please contact the file owner to re-authorize their Google account.':
        'Облікові дані для власника файлу ({}) недійсні або прострочені. Зверніться до власника файлу, щоб повторно авторизувати його обліковий запис Google.',
      'Your Google Credentials were invalid, please authorize again.':
        'Ваші Google облікові дані є недійсними, будь ласка, авторизуйтеся знову.',
      'Waiting for approval': 'Очікування затвердження',
      Approved: 'Підтверджено',
      'Approval in process': 'Підтвердження в процесі',
      Rejected: 'Відхилено',
      'Approval Config': 'Конфігурація підтвердження',
      'Approval status': 'Статус підтвердження',
      Approvals: 'Підтвердження',
      'Any of users': 'Будь-хто з користувачів',
      'All users': 'Всі користувачі',
      'Majority users': 'Більшість користувачів',
      Client: 'Контрагент',
      Request: 'Заявка',
      Indicator: 'Індикатор',
      Ticket: 'Тікет',
      'Sale Ticket (position)': 'Продаж Тікет (позиція)',
      'Purchase Ticket (position)': 'Закупівельний тікет (позиція)',
      'Purchase Contract': 'Закупівельний контракт',
      'Sale Contract': 'Продажний контракт',
      'Services contract': 'Контракт про надання послуг',
      'Intermediate contract': 'Проміжний контракт',
      'Export contract': 'Експортний контракт',
      'Purchase Multicontract': 'Закупівельний контракт',
      'Sale Multicontract': 'Продажний мультиконтракт',
      'Services Multicontract': 'Мультиконтракт на послуги',
      'Intermediate Multicontract': 'Проміжний Мультиконтракт',
      'Export Multicontract': 'Експортний Мультиконтракт',
      'Purchase Ticket': 'Закупівельний тікет',
      'Sale Ticket': 'Продажний Тікет',
      'Services Ticket': 'Сервісний тікет',
      'Export Ticket': 'Експорт Тікет',
      'General Agreement': 'Договір',
      Passport: 'Паспорт',
      Finance: 'Фінанси',
      'Payment Plan': 'План оплат',
      Title: 'Назва',
      'Object type': 'Тип обʼєкту',
      'Main approvers': 'Супер підтвердження',
      'Is active': 'Активний',
      'Approval Configs': 'Конфігурації підтвердження',
      'Index (ordering)': 'Індекс (впорядкування)',
      Approvers: 'Затверджувачі',
      'Confirmation logic': 'Логіка підтвердження',
      'Is ready for vote condition': 'Готовий до голосування',
      'Need related objects approvals': "Потрібні погодження на супутні об'єкти",
      'Without condition': 'Без умов',
      'Auto approve condition': 'Умова автоматичного схвалення',
      'Approval Config Level': 'Рівень підтвердження',
      'Approval Config Levels': 'Рівні підтвердження',
      Declined: 'Відхилено',
      Comment: 'Коментар',
      Archived: 'Архівовано',
      Approval: 'Погодження',
      'First name': 'Імʼя',
      'Middle name': 'По батькові',
      'Last name': 'Прізвище',
      Phone: 'Телефон',
      Email: 'Email',
      Position: 'Позиція',
      'Business Units': 'Бізнес-юніти',
      'HS code': 'HS код',
      'Sale manager': 'Регіональні представники',
      'Sale managers': 'Регіональний представник',
      Condition: 'Умова',
      'Can create Google Drive file': 'Може створити файл Google Drive',
      'Google Authentication required.': 'Потрібна авторизація через Google',
      Enter: 'Увійти',
      'The reset link is broken or already used':
        'Посилання для скидання не працює або вже використовується',
      'In the case of any questions, please, contact our Support Team':
        'У разі будь-яких питань, будь ласка, звертайтесь до нашої команди підтримки.',
      Login: 'Логін',
      Password: 'Пароль',
      'Forgot password': 'Забули пароль',
      'Pressing the button I confirm, I have read and agree with':
        'Натискаючи кнопку, ПІДТВЕРДЖУЮ, я прочитав і згоден з',
      'terms of use and privacy policy': 'умови використання та політика конфіденційності',
      "You're receiving this email because you requested a password reset for your user account":
        'Ви отримали цей електронний лист, оскільки надіслали запит на скидання пароля для свого облікового запису користувача',
      'Please go to the following page and choose a new password:':
        'Будь ласка, перейдіть на наступну сторінку та виберіть новий пароль:',
      'Your username, in case you’ve forgotten:': "Ваше ім'я користувача, якщо ви забули:",
      'Thanks for using our site!': 'Дякуємо, що користуєтеся нашим сайтом!',
      "Password reset link sent to user's email":
        'Посилання для зміни пароля надіслано на електронну адресу користувача',
      "You're receiving this notification because you requested a password reset for your user.":
        'Ви отримали це сповіщення, оскільки надіслали запит на скидання пароля для свого користувача.',
      "This request doesn't exist anymore.": 'Цей запит більше не існує.',
      '{approval.user.get_full_name()}, voted this approval already.':
        '{approval.user.get_full_name()}, ви вже голосували за це.',
      '{user.get_full_name()}, you are not allowed to vote this approval.':
        '{user.get_full_name()}, вам не дозволено голосувати за це затвердження.',
      'Thank you, {user.get_full_name()}, you Approved the object.':
        'Дякую, {user.get_full_name()}, ви Погодили об’єкт.',
      'Thank you, {user.get_full_name()}, you Declined the object.':
        'Дякую, {user.get_full_name()}, ви Відхилили об’єкт.',
      'This field can not be changed after creation.': 'Це поле не можна змінити після створення.',
      'Invalid login and/or password': 'Невірний логін та/або пароль. Спробуйте знову',
      'You need sent three values (current, new, confirm)':
        'Потрібно надіслати три значення (поточне, нове, підтвердження)',
      'Wrong password.': 'Неправильний пароль.',
      'New password and confirm must be the same.':
        'Новий пароль і підтвердження мають співпадати.',
      'Password updated.': 'Пароль оновлено.',
      'Object type is required': 'Необхідно вказати тип об’єкта',
      'You should approve this item': 'Ви повинні погодити цей елемент',
      'Approval requested.': 'Запит на погодження.',
      'Google Drive logged in successfully. Try again':
        'Успішний вхід в Google Drive. Спробуйте ще раз',
      'Enter new password': 'Введіть новий пароль',
      'Password reset unsuccessful': 'Невдале скидання пароля',
      'There are {} cities named {}. Please, add district to it.':
        'Є {} місто із назвою {}. Будь ласка, додайте район.',
      'There are no district {} for city {}.': 'Немає району {} для міста {}.',
      'Region matching query (title={}) does not exist':
        'Запит на відповідність регіону (title={}) не існує',
      'Role names cannot be empty. Please provide at least one valid role.':
        'Ролі не можуть бути пустими. Укажіть принаймні одну дійсну роль.',
      'The following role names are invalid: {': 'Наступні імена ролей недійсні: {',
      'Not found company with this code': 'Компанію з таким кодом не знайдено',
      'set vkursi credentials': 'встановити облікові дані Vkursi',
      'bad YouControl key': 'недійсний ключ YouControl',
      'User for this person already exist.': 'Кабінет користувачу вже створено для цього контакту.',
      Supplier: 'Постачальник',
      Exporter: 'Експортер',
      Farm: 'Господарство',
      Elevator: 'Елеватор',
      Deliverer: 'Експедитор',
      Bank: 'Банк',
      Owner: 'Власник',
      Other: 'Інше',
      Surveyor: 'Сюрвеєр',
      Buyer: 'Покупець',
      Broker: 'Брокер',
      Insurer: 'Страховик',
      'Customs broker': 'Митний брокер',
      Hot: 'Гарячий',
      Warm: 'Теплий',
      Neutral: 'Нейтральний',
      Cold: 'Холодний',
      Angry: 'Злий',
      New: 'Новий',
      'Approved condition': 'Підтверджено з умовою',
      'Out of approval': 'Відсутнє затвердження',
      Pending: 'В очікуванні',
      'Re-approval': 'Повторне підтвердження',
      Big: 'Великий',
      Medium: 'Середній',
      Small: 'Маленький',
      Call: 'Дзвінок',
      Meet: 'Зустріч',
      Task: 'Завдання',
      Commentary: 'Коментар',
      Low: 'Низький',
      High: 'Високий',
      Critical: 'Критичний',
      Success: 'Успіх',
      Fail: 'Невдача',
      Progress: 'Прогрес',
      Cancelled: 'Скасовано',
      Upcoming: 'Майбутній',
      'Company group': 'Група компаній',
      'Additional info': 'Додаткова інформація',
      'Client Document Type': 'Тип документа контрагента',
      'Days to update client status': 'Дні до оновлення статусу контрагента',
      'company group': 'група компаній',
      'Company groups': 'Групи компаній',
      Responsibility: 'Відповідальність',
      responsibility: 'відповідальність',
      Responsibilities: 'Відповідальність',
      'Django user': 'Користувач Django',
      'First Name': 'Імʼя',
      'Second Name': 'Прізвище',
      'Middle Name': 'По батькові',
      Companies: 'Компанії',
      'Phone additional': 'Додатковий телефон',
      Birthday: 'День народження',
      'Main contact': 'Основний контакт',
      Relation: 'Відношення',
      Verified: 'Перевірено',
      person: 'контакт',
      Persons: 'Контакти',
      'Can create GrainTrack user for person':
        'Можна створити користувача GrainTrack для співробітника',
      'Can verify person': 'Можна перевірити особу',
      Name: 'Назва',
      'Related counterparties': 'Пов’язані контрагенти',
      'Custom status': 'Спеціальний статус',
      Abbreviation: 'Абревіатура',
      'Full name': 'Повна назва',
      'Full name Eng': 'Повна назва (англ.)',
      Code: 'Код',
      EIC: 'EIC',
      ITN: 'ІПН',
      'Tax info': 'Податкова інформація',
      Country: 'Країна',
      Address: 'Адреса',
      'Legal address': 'Юридична адреса',
      'Legal address eng': 'Юридична адреса (англ.)',
      Person: 'Співробітник',
      Signatory: 'Підписант',
      'Signatory Eng': 'Підписант Англ',
      'Signatory Docs': 'Підписанант документів',
      'Signatory Eng Docs': 'Підписант документів (англ.)',
      'Phone (additional)': 'Телефон (додатковий)',
      Website: 'Веб-сайт',
      'Black list': 'Чорний список',
      'Company data is checked': 'Дані компанії перевірені',
      'Bank data is checked': 'Банківські дані перевірені',
      Documents: 'Документи',
      'First confirmation (date)': 'Перше підтвердження (дата)',
      'Term of proxy': 'Строк дії довіреності',
      'Days before deadline to notify': 'Днів до кінцевого терміну для повідомлення',
      'Confirmation (date)': 'Підтвердження (дата)',
      'Confirmation info': 'Інформація про підтвердження',
      'Request (date)': 'Заявка (дата)',
      'Received (date)': 'Отримано (дата)',
      'Incorporation (date)': 'Інкорпорація (дата)',
      Status: 'Статус',
      President: 'Президент',
      CEO: 'CEO',
      CFO: 'CFO',
      'Other executive': 'Інші виконавці',
      'Parent company': 'Материнська компанія',
      'Ultimate parent company': 'Кінцева материнська компанія',
      'Ultimate beneficiary owners': 'Кінцеві бенефіціарні власники',
      'Activities & traded products': 'Активність & товари',
      Suppliers: 'Постачальники',
      Customers: 'Клієнти',
      'Can confirm clients': 'Підтвердження контрагента',
      'Don`t use kpi check': 'Не використовувати перевірку KPI',
      Responsible: 'Відповідальний',
      'Responsible for confirmation': 'Відповідальний за підтвердження',
      'Client is resident': 'Контрагент є резидентом',
      'SAP vendor number': 'SAP номер постачальника',
      Equity: 'Капітал',
      'Net income': 'Чистий прибуток',
      'Total fixed assets': 'Всього основного капіталу',
      Turnover: 'Оборот',
      'Total assets': 'Всього активів',
      'Annual traded volume': 'Річний обсяг торгів',
      'Info for traders': 'Інформація для трейдерів',
      'Contact (future)': 'Контакт (майбутній)',
      City: 'Місто',
      District: 'Район',
      Regions: 'Регіони',
      'Client crop area': 'Посівна площа контрагента',
      'Cash Flow': 'Кеш-фло',
      'Client transports count': 'Лічильник транспортних засобів контрагента',
      'Regular status': 'Статус угод',
      Potencial: 'Потенційний',
      Active: 'Активний',
      Inactive: 'Неактивний',
      Closed: 'Закритий',
      Size: 'Розмір',
      'Contact Date (first)': 'Дата контакту (перша)',
      Agent: 'Посередник',
      Producer: 'Виробник',
      Competitor: 'Конкурент',
      'Local currency': 'Локальна валюта',
      'Foreign currency': 'Іноземна валюта',
      'Own cunterparty': 'Власний контрагент',
      'outgoing invoice prefix': 'префікс вихідного інвойсу',
      Template: 'Шаблон',
      'Signatory position': 'Посада підписанта',
      'Signatory position eng': 'Посада підписанта (англ.)',
      'Code of economic activities': 'КВЕД',
      'Counterparty type': 'Тип контрагента',
      'legal entity': 'юридична особа',
      'physical person': 'фізична особа',
      'individual entrepreneur': 'фізична особа-підприємець',
      'Mailing address': 'Адреса розсилки',
      'Types of activity': 'Види діяльності',
      Updates: 'Оновлення',
      Clients: 'Контрагенти',
      'Can view client offers': 'Можна переглядати оффер контрагентів',
      'Can view client tickets': 'Можна переглядати тікети контрагентів',
      'Can view client confirmations': 'Можна переглядати підтвердження по контрагенту',
      'Can view client statistics': 'Можна переглядати статистику контрагентів',
      'Can view client persons': 'Можна переглядати співробітників контрагентів',
      'Can edit date_confirmation': 'Можна редагувати дату підтвердження',
      'Can edit date_first_confirmation': 'Можна редагувати дату першого підтвердження',
      'Can edit status': 'Лише куратор може редагувати статус',
      'Can edit kpi confirmation success status':
        'Можна редагувати статус успішного підтвердження KPI',
      'Can edit client responsible': 'Можна редагувати відповідального по контрагенту',
      'Can edit client confirmation of a counterparty':
        'Можна редагувати підтвердження по контрагенту',
      'Can approve client': 'Можна підтверджувати контрагента',
      'Can reactivate approval config': 'Можна повторно активувати конфігурацію підтвердження',
      'Has sanctions': 'Є санкції',
      'Has criminal cases': 'Має кримінальні справи',
      'Has tax debt': 'Має податковий борг',
      Crop: 'Культура',
      'Initial volume (t)': 'Початковий обʼєм (т)',
      'Volume (t)': "Об'єм (т)",
      'Cultivated area': 'Посівна площа',
      'productivity of land': 'продуктивність землі',
      Note: 'Примітка',
      potential: 'потенціал',
      potentials: 'потенціали',
      'Role Name': 'Імʼя ролі',
      'Limit per one deal (spot), t': 'Ліміт на одну угоду (спот), т',
      'Limit general (spot), t': 'Ліміт загальний (спот), т',
      'Limit open balance (spot), t': 'Ліміт відкритого балансу (спот), т',
      'Limit unpaid volume (spot), t': 'Ліміт неоплаченого Обʼєму (спот), т',
      'Limit per one deal (spot), USD': 'Ліміт на одну угоду (спот), USD',
      'Limit general (spot), USD': 'Ліміт загальний (спот), USD',
      'Limit per one deal (forward), t': 'Ліміт на одну угоду (форвард), т',
      'Limit general (forward), t': 'Ліміт загальний (форвард), т',
      'Limit open balance (forward), t': 'Ліміт відкритого балансу (форвард), т',
      'Limit unpaid volume (forward), t': 'Ліміт неоплаченого обʼєму (форвард), т',
      'Limit per one deal (forward), USD': 'Ліміт на одну угоду (форвард), USD',
      'Limit general (forward), USD': 'Ліміт загальний (форвард), USD',
      'Affiliated suppliers': 'Приєднані постачальники',
      Basises: 'Базиси',
      'Payment conditions': 'Умови оплати',
      'Payment conditions auto': 'Умови оплати авто',
      'Payment conditions option': 'Опція умов оплати',
      'Client role': 'Роль контрагента',
      'Client roles': 'Ролі контрагентів',
      'Can change limits': 'Має повноваження змінювати ліміти',
      owner: 'власник',
      Owners: 'Власники',
      other: 'інший',
      Others: 'Інші',
      seller: 'продавець',
      Sellers: 'Продавці',
      'Can view sellers': 'Можна переглядати продавців',
      broker: 'брокер',
      Brokers: 'Брокери',
      surveyor: 'сюрвеєр',
      Surveyors: 'Сюрвеєри',
      insurer: 'страховик',
      Insurers: 'Страховики',
      bank: 'банк',
      Banks: 'Банки',
      forwarder: 'експедитор',
      Forwarders: 'Експедитори',
      exporter: 'експортер',
      Exporters: 'Експортери',
      buyer: 'покупець',
      Buyers: 'Покупці',
      elevator: 'елеватор',
      Elevators: 'Елеватори',
      Farms: 'Господарства',
      Update: 'Оновлення',
      Response: 'Відповідь',
      Priority: 'Пріоритет',
      Type: 'Тип',
      'Contact status': 'Статус контакту',
      'Is auto created': 'Згенеровано',
      'Responsible users': 'Відповідальні користувачі',
      Initiator: 'Ініціатор',
      'Notification date (+time)': 'Дата сповіщення (+час)',
      'Sequence order': 'Порядок послідовності',
      'Due date hours after create': 'Час до дати виконання після створення',
      'Checklist Point': 'Пункт перевірки',
      'Can change responsible': 'Можливо змінити відповідального',
      'Update section': 'Розділ оновлення',
      'Update sections': 'Розділи оновлення',
      'Update topic': 'Тема оновлень',
      'Update topics': 'Теми оновлень',
      'Update failure reason': 'Причина невдалого оновлення',
      'Update failure reasons': 'Причини невдалого оновлення',
      'Title in English': 'Назва (англ.)',
      'Is resident': 'Резидент',
      'Is distributor': 'Дистрибʼютор',
      'Is agent': 'Посередник',
      'Is producer': 'Виробник',
      'Client Document Types': 'Типи документів',
      'Is visible': 'Видно',
      'Copy is available': 'Копія отримана',
      'Original is available': 'Оригінал отримано',
      'Sending date': 'Дата відправлення',
      'Receipt date': 'Дата отримання',
      'Client Document': 'Документ контрагента',
      'Client Documents': 'Документи контрагентів',
      'Commentary text': 'Коментар',
      'Client commentary': 'Коментар контрагента',
      'Client commentaries': 'Коментарі контрагентів',
      'Can view client commentaries': 'Можна переглядати коментарі контрагентів',
      Area: 'Площа',
      Volume: 'Обʼєм',
      'Crop year': 'Рік врожаю',
      'Grain quality check': 'Перевірка якості',
      'Cargo price': 'Вартість вантажу',
      Currency: 'Валюта',
      'Exchange rate': 'Курс обміну',
      'Place of storage': 'Місце зберігання',
      'Crop Area': 'Посівна площа',
      'Crop Areas': 'Посівні площі',
      Value: 'Показник',
      'Value alt': 'Значення альтернативне',
      'Title alt': 'Назва альтернативна',
      Distance: 'Відстань',
      'Warehouse Distance': 'Відстань до складу',
      'Warehouse Distances': 'Відстань до складу',
      Codename: 'Код назва',
      'Client Role': 'Роль контрагента',
      'Client Roles': 'Ролі контрагента',
      Calendar: 'Календар',
      Calendars: 'Календарі',
      'Cargo Brand': 'Бренд вантажу',
      'Cargo Brands': 'Бренди вантажів',
      'Name Eng': 'Назва (англ)',
      Region: 'Регіон',
      Customs: 'Митниця',
      Customses: 'Митниці',
      Potential: 'Потенціал',
      Contract: 'Контракт',
      Multicontract: 'Мультиконтракт',
      Multiticket: 'Мультитікет',
      Invoice: 'Інвойс',
      Voyage: 'Рейс',
      GeneralAgreement: 'Генеральна угода',
      'Invoice Position': 'Інвойс-позиція',
      Payment: 'Платіж',
      Logistic: 'Логістика',
      Consignment: 'Накладна',
      'Bill of Lading': 'Коносамент',
      'Disbursement Bill of Lading': 'Видаткова накладна',
      'Contract Charges': 'Витрати контракту',
      Operation: 'Операція',
      Control: 'Контроль',
      'Bank Account': 'Банківський рахунок',
      created: 'створений',
      updated: 'оновлено',
      of: 'з',
      'This supplier must be a distributor, because it has opened reassignments.':
        'Цей постачальник повинен бути дистрибʼютором, тому що він має відкриті переуступки.',
      'This supplier must be a distributor, because it has opened offsets.':
        'Цей постачальник повинен бути дистрибʼютором, тому що він відкрив заліки.',
      'Warehouse distance can have only one warehouse.':
        'Складська дистанція може мати тільки один склад.',
      'Warehouse required.': 'Потрібен склад.',
      'Distance should be unique by client, city and warehouse.':
        'Відстань має бути унікальною для контрагента, міста та складу.',
      from: 'від',
      'Delivery period': 'Термін поставки',
      Shipment: 'Відвантаження',
      'Cargo executed': 'Виконаний вантаж',
      Executed: 'Виконано',
      Washout: 'Вошаут',
      Draft: 'Чернетка',
      Process: 'Процес',
      Valid: 'Дійсний',
      invalid: 'недійсний',
      'One full cargo': 'Один повний вантаж',
      'Partial shipment allowed': 'Дозволяється часткове відвантаження',
      Seller: 'Продавець',
      Sale: 'Продаж',
      Purchase: 'Закупівля',
      Prepayment: 'Передоплата',
      Processed: 'Оброблено',
      Sent: 'Надіслано',
      Lost: 'Втрата',
      Spot: 'Спот',
      Forward: 'Форвард',
      Brokerage: 'Брокерська діяльність',
      Services: 'Послуги',
      Intermediate: 'Проміжний',
      Export: 'Експорт',
      '20 DV': 'Тариф 20 DV',
      '40 DV': 'Тариф 40 DV',
      'Not selected': 'Не вибрано',
      'Loading minus': 'Завантаження в мінус',
      'Loading plus': 'Завантаження в плюс',
      'Free tax invoice': 'Інвойс без податків',
      'On first event': 'За першою подією',
      'Absolute value': 'Абсолютне значення',
      Percentage: 'Відсоток',
      Receipt: 'Receipt',
      Dispatch: 'Відправлення',
      'Not executed': 'Не виконано',
      'Contract Option': 'Опція контракту',
      'Option (value)': 'Опціон (сума)',
      'Conclusion (date)': 'Укладання (дата)',
      'Execution (start date)': 'Виконання (дата початку)',
      'Execution (end date)': 'Виконання (кінцева дата)',
      'Deal Type': 'Тип угоди',
      'Notify party': 'Повідомлення про партію',
      'Use type': 'Тип використання',
      'According to contract template': 'Згідно з шаблоном контракту',
      'Shipment terminal': 'Термінал відвантаження',
      'start date': 'дата початку',
      'end date': 'кінцева дата',
      'Program type': 'Тип програми',
      'Main program': 'Основна програма',
      Crops: 'Культури',
      'Price currency': 'Валюта ціни',
      'Premium currency': 'Преміум валюта',
      month: 'місяць',
      Price: 'Ціна',
      Premium: 'Преміум',
      Charge: 'Витрати',
      'Price per t': 'Ціна за т',
      'With VAT': 'З ПДВ',
      'Trader note': 'Примітка трейдера',
      'Price opposite': 'Зустрічна ціна',
      'Is gain': 'Це дохід',
      'Counterparty amount': 'Сума контрагента',
      'Our amount': 'Наша сума',
      'Final Amount': 'Остаточна сума',
      'Analytical Amount': 'Аналітична сума',
      Validated: 'Підтверджено',
      'Under discussion': 'Обговорюється',
      Forecast: 'Прогноз',
      'Pending confirmation': 'Очікує підтвердження',
      Planned: 'Заплановано',
      Date: 'Дата',
      Commodity: 'Товар',
      'ContractCharge by default': 'Витрати за контрактом за замовчуванням',
      'ContractCharge by default rules':
        'Автоматичне нарахування витрат за контрактом за замовчуванням',
      'Can add contract charge': 'Можна додати плату за контракт',
      'Can add ticket charge': 'Можна додати плату за квиток',
      'Opposite basis': 'Протилежний базис',
      'Costs opposite': 'Витрати зустрічного контракту',
      'Currency exchange': 'Обмін валюти',
      'Counterparties opposite': 'Зустрічний контрагент',
      'Execution date opposite': 'Дата укладання зустрічна',
      'Opposite freight level': 'Зустрічний фрахт',
      'Freight currency': 'Валюта фрахту',
      'Freight currency exchange': 'Обмін валют фрахту',
      'Price premium opposite': 'Преміум ціна зустрічного контракту',
      Derivative: 'Дериватив',
      'Custom values': 'Кастомні значення',
      'Number of contract (#)': 'Номер контакту (#)',
      'Number of folder (#)': 'Номер папки (#)',
      'Responsible for signing': 'Відповідальний за підпис',
      'Volume with docs (t)': 'Обʼєм по документам (т)',
      Option: 'Опціон',
      'Final volume (t)': 'Кінцевий обʼєм (т)',
      Origin: 'Походження',
      Destination: 'Призначення',
      Origins: 'Походження',
      Destinations: 'Призначення',
      'Conclusion price': 'Заключна ціна',
      'Analytical price': 'Аналітична ціна',
      'Price USD': 'Ціна USD',
      'Currency and Rate': 'Валюта та курс',
      Basis: 'Базис',
      'Paper Trade': 'Paper Trade',
      'Payment conditions options': 'Опції умов оплати',
      'Custom declaration': 'Митне декларування',
      'Dont check kpi time different': 'Не використовувати для KPI',
      'Expiration start': 'Початок експірації',
      'Expiration end': 'Кінцева дата експірації',
      'Nomination date': 'Дата номінації',
      Extention: 'Пролонгація',
      'Bill of lading (date)': 'Коносамент (дата)',
      'Transfer of ownership (date)': 'Перехід права власності (дата)',
      'Shipment type': 'Тип відвантаження',
      'Prepay payment': 'Попередня оплата',
      'Balance payment': 'Оплата балансу',
      'Broker contacts': 'Контакти брокера',
      Forwarder: 'Перевізник',
      Ports: 'Порти',
      'Opposite ports': 'Протилежні порти',
      'Station depart': 'Станція відправлення',
      'Station receive': 'Станція отримання',
      'Exchange for calculation': 'Розрахувати курс',
      'Exchange for calculation (opposite)': 'Розрахувати курс (протилежний)',
      'Delivery conditions': 'Умови доставки',
      VAT: 'ПДВ',
      'VAT (value)': 'ПДВ (сума)',
      'No extension': 'Без продовження',
      'Preadvice (days)': 'Преадвайс (дні)',
      'Quality info': 'Якість інфо',
      Stage: 'Етап',
      'Previous status': 'Попередній статус',
      'Logistics costs VAT': 'Витрати по логістиці ПДВ',
      'Logistics penalties VAT': 'Штрафи по логістиці (ПДВ)',
      'Needs original documents': 'Потрібні оригінали документів',
      'Regional managers': 'Регіональні менеджери',
      'Heads of sales manager': 'Регіональні представники',
      'Calculate execution by intermediate logistics': 'Розрахувати виконання (проміжна логістика)',
      'Calculate tax profit to pay': 'Розрахувати податок до сплати',
      'Elevator (start date)': 'Елеватор (дата початку)',
      'Elevator (end date)': 'Елеватор (кінцева дата)',
      'Estimated execution date': 'Очікувана дата виконання',
      'Loading option': 'Опція завантаження',
      Swap: 'Своп',
      'Ignore limits': 'Ігнорувати ліміти',
      'Ignore client status': 'Ігнорувати статус контрагента',
      Terminal: 'Термінал',
      'Few departure stations': 'Кілька станцій відправлення',
      ISCC: 'ISCC',
      'Minimum batch of payment': 'Мінімальна сума платежу',
      'Internal Chain': 'Внутрішній ланцюг',
      'Ship classification': 'Класифікація судна',
      'Estimated freight value': 'Очікувана вартість фрахту',
      'Estimated freight VAT value': 'Приблизна сума фрахт ПДВ',
      'Estimated freight VAT': 'Приблизна фрахт ПДВ',
      'Contract been updated today': 'Контракт оновлено сьогодні',
      Season: 'Сезон',
      'vat return rate': 'ставка повернення ПДВ',
      Port: 'Порт',
      Budget: 'Бюджет',
      'Conclusion date currency': 'Валюта на момент укладання',
      'Conclusion date exchange rate': 'Курс валюти на дату заключення',
      'Potential buyers': 'Потенційні покупці',
      'Potential suppliers': 'Потенційні постачальники',
      'Created from risk position origination': 'Створено зі складу',
      'Tare Weight': 'Вага тари',
      'Quantity of measurement': 'Кількість одиниці виміру',
      'price of measurement': 'ціна за вимірювання',
      'Quantity of containers': 'Кількість контейнерів',
      guarantors: 'гаранти',
      'Load rate': 'Норма завантаження',
      'Discharge rate': 'Норма розвантаження',
      'Demmurage rate': 'Ставка демереджа',
      'Dispatch rate': 'Ставка диспача',
      'Volume (min)': 'Обʼєм (мін.)',
      'Volume (max)': 'Обʼєм (макс.)',
      'Implied freight level': 'Передбачуваний фрахт вантажу',
      'Load port': 'Порт завантаження',
      'Discharge port': 'Порт розвантаження',
      'Arrival period start': 'Початок періоду прибуття',
      'Arrival period end': 'Кінець періоду прибуття',
      'Pre-advice': 'Преадвайс',
      'Is extension': 'Продовження',
      Discount: 'Дисконт',
      'Discount amount': 'Розмір дисконту',
      'Discount 1 mt': 'Дисконт / 1 т',
      'Sampling Condition': 'Умова відбору зразків',
      'Counterparty business reference': 'Бізнес-довідка контрагента',
      'Loan percentage': 'Відсоток кредиту',
      'Interest rate': 'Процентна ставка',
      'Estimated opposite payment date': 'Прогнозна дата зустрічної оплати',
      'Payment days': 'Дні оплати',
      'Fixed price bonus': 'Фіксований бонус за ціною',
      'Use open price': 'Використати відкриту ціну',
      'Ending status': 'Фінальний статус',
      Open: 'Відкритий',
      Ending: 'Закінчення',
      Expiring: 'Еспірація',
      Expired: 'Термін дії минув',
      "Days to set 'ending status'(end of execution)":
        'Дні до встановлення «фінального статусу» (кінець виконання)',
      'Certification schemes': 'Сертифікаційні схеми',
      'Vehicle restrictions': 'Обмеження для ТЗ',
      Terminals: 'Термінали',
      'Ignore allocation': 'Ігнорувати розподіл',
      'Number of days for payment': 'Кількість днів для оплати',
      'Do not display in passport': 'Не показувати в паспорті',
      'Payment status': 'Статус оплати',
      Contractbase: 'База контрактів',
      Contractbases: 'Бази контрактів',
      'Can view contract logistics': 'Перегляд логістики за контрактами',
      'Can view contract payments': 'Перегляд контрактних платежів',
      'Can view contract documents': 'Перегляд документів за контрактом',
      'Can view contract processes': 'Перегляд етапів контракту',
      'Can change contract status': 'Можна змінити статус контракту',
      'Can confirm cargo execution of contract': 'Може підтверджувати виконання контракту (вантаж)',
      'Can confirm payment execution of contract':
        'Може підтверджувати виконання контракту (інвойс)',
      'Can confirm final execution of contract': 'Може підтверджувати виконання контракту (фінал)',
      'Can see costs progressbar': 'Перегляд витрат за контрактом',
      'Can edit kpi_signing_success': 'Можна редагувати успіх підписання kpi',
      'Can ignore client compliance status': 'Можна ігнорувати статус комплаєнса контрагента',
      'Can ignore excess contract volume': 'Ігнорувати зайвий обʼєм контракту',
      'Can set voyage info for contract': 'Можна встановити інформацію про рейс для контракту',
      'Can clone contract': 'Можна клонувати контракт',
      'Purchase contract': 'Закупівельний контракт',
      'Purchase contracts': 'Закупівельні контракти',
      'Can approve purchase contract': 'Може підтверджувати закупівельні контракти',
      'Can view purchase contracts of all authors':
        'Можна переглядати закупівельні контракти від усіх авторів',
      'Can view purchase contracts of all responsibles':
        'Можна переглядати закупівельні контракти від усіх відповідальних осіб',
      'Sale contract': 'Продажний контракт',
      'Sales contracts': 'Продажні контракти',
      'Can approve sale contract': 'Може підтверджувати продажний контракт',
      'Can view sale contracts of all authors':
        'Можна переглядати продажні контракти від усіх авторів',
      'Can view sale contracts of all responsibles':
        'Можна переглядати продажні контракти від усіх відповідальних осіб',
      'Services contracts': 'Контракти на послуги',
      'Intermediate contracts': 'Проміжні контракти',
      'Export contracts': 'Експортні контракти',
      Calculate: 'Розрахувати',
      'Vat discount': 'Знижка з ПДВ',
      '20 dv': 'Тариф 20 dv',
      Containers_type: 'Тип_контейнерів',
      'Price purchase calc': 'Ціна закупівлі розрахунок',
      'Costs calc': 'Розрахунок витрат',
      'Calculate auto tariff price': 'Розрахувати авто тариф',
      'Calculate elevator costs': 'Розрахувати витрати на елеватор',
      'Calculate railway costs': 'Розрахувати тариф залізниці',
      'Calculate freight costs': 'Розрахувати фрахт',
      'Conclusion date opposite': 'Зустрічна дата укладання',
      'Financing cost': 'Витрати фінансування',
      'Financing days': 'Дні фінансування',
      'Storage days': 'Дні зберігання',
      Distributors: 'Дистрибʼютори',
      'Closing of existing position': 'Закриття існуючої позиції',
      'Estimated P&L': 'Очікуваний PnL',
      'Broker commission': 'Брокерська комісія',
      'Possible buyers info': 'Інфо про потенційних покупців',
      'Possible suppliers info': 'Інфо про потенційних постачальників',
      'Possible brokers info': 'Інфо про потенційних брокерів',
      Strategy: 'Стратегія',
      'Trade confirmations': 'Трейдерські підтвердження',
      'Payment terms': 'Терміни оплати',
      'Price (min)': 'Ціна (мін.)',
      'Price (max)': 'Ціна (макс.)',
      'Connection to passport status': 'Зʼєднання до статусу паспорта',
      'Crop protection': 'Захист посівів',
      Seeds: 'Посіви',
      'Is lost': 'Втрачено',
      Requests: 'Тікети',
      'Can view all requests': 'Перегляд всіх тікетів',
      'Can view prices': 'Переглянути ціни',
      'Can create ticket from offer': 'Можна створити тікет із пропозиції',
      'Can approve ticket or contract': 'Може погодити тікет або контракт',
      'Can cancel ticket or contract': 'Можна скасувати тікет або контракт',
      'Restrict ticket creation from expired indicator':
        'Обмежити створення квитка за індикатором прострочення',
      Tickets: 'Тікети',
      Indicators: 'Індикатори',
      'Sale ticket (position)': 'Продажний тікет (позиція)',
      'Sale tickets (positions)': 'Продажний тікет (позиції)',
      "Can change approved 'Sale ticket (position)' and related":
        'Можна змінювати затверджений "продажний тікет (позиція)" та пов’язане з ним',
      'Purchase ticket (position)': 'Закупівельний тікет (позиція)',
      'Purchase tickets (positions)': 'Закупівельні тікети (позиції)',
      "Can change approved 'Purchase ticket (position)' and related":
        'Можна змінити погоджений «Закупівельний тікет (позиція)» і пов’язане з ним',
      ContractPrice: 'Ціна контракту',
      'Update part of the volume': "Оновити частину об'єму",
      'Number of agreement (#)': 'Номер угоди (#)',
      'Agreement date': 'Дата угоди',
      autogenerated: 'згенеровано',
      'Premium price': 'Преміум ціна',
      Warehouse: 'Склад',
      'Additional agreement': 'Додаткова угода',
      'Additional agreements': 'Додаткові угоди',
      'Select one or more basises to filter charges inside contracts':
        'Виберіть один або кілька базисів, щоб відфільтрувати витрати всередині контрактів',
      'Object stage': "Стадія об'єкта",
      'Basis Document': 'Базис документу',
      'Basis Documents': 'Базис документів',
      'Contract Basis Doc': 'Базис контракту',
      'Active option': 'Активна опція',
      'Price premium': 'Преміум ціна',
      'Margin relative, USD': 'Відносна маржа, USD',
      'Margin absolute, USD': 'Маржа абсолютна, USD',
      'For KPI year report': 'До звіту за рік КПІ',
      Сommodity: 'Продукт',
      'Production Year': 'Рік виробництва',
      'Purchase Plan': 'План закупівлі',
      'User volume': 'Обʼєм користувача',
      'Volume in percent': 'Обʼєм у відсотках',
      'Indication date': 'Індифікатор дата',
      'A month for which the indicator price is assigned':
        'Місяць, на який призначається вказати ціну',
      'Cargo price, USD': 'Вартість вантажу, USD',
      'Used in market': 'Використовується на ринку',
      'Can mass-delete market prices': 'Може масово видаляти ринкові ціни',
      'Number (#)': 'Номер (#)',
      Receiver: 'Одержувач',
      'Stock reserve unit': 'Одиниця резерву запасів',
      Consignments: 'Складські квитанції',
      Credit: 'Кредит',
      'Main deal': 'Основна угода',
      'Buyer payment info': 'Інфо про оплату покупця',
      'Supplier payment info': 'Інфо про оплату постачальника',
      Amount: 'Сума',
      'Tax registration': 'Реєстрація податку',
      'percent conditions': 'відсоткові умови',
      'Buyer signator': 'Підписант покупця',
      'Supplier signator': 'Підписант постачальника',
      'General agreement': 'Генеральна угода',
      'General agreements': 'Генеральні угоди',
      'Can approve general agreement': 'Має повноваження підтвердження генеральної угоди',
      'Railway required': 'Потрібна залізниця',
      'Vet required': 'Необхідний Vet',
      'Quality required': 'Потрібна якість',
      'Declaration required': 'Потрібна декларація',
      'EUR 1 required': 'Потрібен 1 EUR',
      'Partial executed': 'Виконано частково',
      'Volume in positions from execution': 'Обʼєм по виконанню',
      'With derivative': 'З деривативом',
      'Sale multicontract': 'Продажний мультиконтракт',
      'Sales multicontracts': 'Продажні мультіконтракти',
      'Purchase multicontract': 'Закупівельний мультіконтракт',
      'Purchases multicontracts': 'Закупівельні мультіконтракти',
      'Intermediate multicontract': 'Проміжний мультиконтракт',
      'Intermediates multicontracts': 'Проміжні мультіконтракти',
      'Export multicontract': 'Експортний мультиконтракт',
      'Exports multicontracts': 'Експортні мультіконтракти',
      'Services multicontract': 'Сервісний мультіконтракт',
      'Servicess multicontracts': 'Сервісні мультіконтракти',
      'Sale multiticket': 'Продажний мультитікет',
      'Sales multitickets': 'Продажні мультитікети',
      'Purchase multiticket': 'Закупівельний мультитікет',
      'Purchases multitickets': 'Закупівельні мультитікети',
      'Export multiticket': 'Експортний мультитікет',
      'Exports multitickets': 'Експортні мультитікети',
      'Services multiticket': 'Сервісний мультитікет',
      'Servicess multitickets': 'Сервісні мультитікети',
      Number: 'Номер',
      'Stock volume': 'Обʼєм запасів',
      'Quantity of estimated lots': 'Кількість очікуваних лотів',
      'margin selector': 'перемикач маржі',
      default: 'за замовчуванням',
      analytical: 'аналітичний',
      Margin: 'Маржа',
      'Margin per ton': 'Маржа за тонну',
      Mtm: 'Mtm',
      'Physical margin': 'Фізична маржа',
      'Derivative margin': 'Маржа деривативу',
      'Total margin': 'Загальна маржа',
      'Total value': 'Загальна сума',
      volume: 'обʼєм',
      'Mrket price': 'Ринкова ціна',
      Commission: 'Комісія',
      'Is liquid': 'Є рідиною',
      'Use escalations': 'Використовуйте ескалації',
      Start: 'Початок',
      End: 'Кінець',
      'Auto apply': 'Автозастосування',
      'Auto applying:': 'Автозастосувати:',
      'Handly applying:': 'Застосувати вручну:',
      Any: 'Будь-який',
      'Export Contract': 'Експортний контракт',
      'Base contract': 'Основний контракт',
      Consignee: 'Отримувач',
      'Delivery terminal': 'Термінал доставки',
      'Delivery address': 'Адреса доставки',
      'Contract consignee': 'Вантажоодержувач контракту',
      'Contract consignees': 'Вантажоодержувач контракту',
      'Empty contract number': 'Порожній номер контракту',
      'Crops required.': 'Необхідні культури.',
      'Months required.': 'Потрібні місяці.',
      'Positions required.': 'Необхідні посади.',
      'Term of the contract': 'Строк дії договору',
      'is completed on ': 'завершено на ',
      info: 'інфо',
      t: 'т',
      'Ticket status': 'Статус тікета',
      'Is a closing of the existing position': 'Закриває існуючу позицію',
      Details: 'Деталі',
      Startegy: 'Стратегія',
      'Trade confirmation': 'Трейдерське підтвердження',
      'PurchasePlan must be unique by crop in particular period':
        'План закупівлі має бути унікальним для культури в певний період',
      'First date must be less then Second': 'Перша дата має бути меншою за другу',
      'is not approved (or approved condition)': 'не підтверджено (або стан погоджено)',
      'The volume of current indicator is exceeded. ': 'Обсяг поточного індикатора перевищено ',
      'The volume of current potential is exceeded. ': 'Обсяг поточного потенціалу перевищено. ',
      'Can`t be empty': 'Не може бути порожнім',
      'has status - closed': 'має статус «закрито»',
      'Unpaid volume is greater than limit for spot contracts':
        'Неоплачений обʼєм перевищує ліміт для спот контрактів',
      'Unpaid volume is greater than limit for forward contracts':
        'Неоплачений обʼєм перевищує ліміт для форвардних контрактів',
      'Volume is greater than limit per one deal for spot contracts ({} t)':
        'Обʼєм перевищує ліміт на одну угоду для спот контрактів ({} t)',
      'Volume is greater than general limit for spot contracts ({} t)':
        'Обʼєм перевищує загальний ліміт для спот контрактів ({} t)',
      'Volume is greater than limit per one deal for forward contracts ({} t)':
        'Обʼєм перевищує ліміт на одну угоду для форвардних контрактів ({} t)',
      'Volume is greater than general limit for forward contracts ({} t)':
        'Обʼєм перевищує загальний ліміт для форвардних контрактів ({} t)',
      'Total value is greater than limit per one deal for spot contracts ({} USD)':
        'Загальна вартість більше, ніж ліміт на одну угоду за спот контрактами ({} USD)',
      'Total value is greater than general limit for spot contracts ({} USD)':
        'Загальна вартість більше, ніж загальний ліміт для спот контрактів ({} USD)',
      'Total value is greater than limit per one deal for forward contracts ({} USD)':
        'Загальна вартість більше, ніж ліміт на одну угоду для форвардних контрактів ({} USD)',
      'Total value is greater than general limit for forward({} USD)':
        'Загальна вартість перевищує загальний ліміт для форварду ({} USD)',
      'With this volume open balance is greater than open balance limit for spot contracts ({} t)':
        'Обʼєм відкритого балансу перевищує ліміт відкритого балансу для спот контрактів ({} t)',
      'With this volume open balance is greater than open balance limit for forward contracts ({} t)':
        'Обʼєм відкритого балансу перевищує ліміт відкритого балансу для форвардних контрактів ({} t)',
      'Contract already exist with number ': 'Контракт із таким номером вже існує ',
      'Contract and general agreement must have same currency.':
        'Контракт і генеральна угода повинні мати однакову валюту.',
      'Shipment terminal required.': 'Необхідний термінал відвантаження.',
      'Supplier must be owner too': 'Постачальник також повинен бути власником',
      'Buyer must be owner too': 'Покупець також повинен бути власником',
      'Credit limit required': 'Необхідний кредитний ліміт',
      'Currency required': 'Необхідна валюта',
      'Contracts and general agreement must have same currency.':
        'Контракти та генеральна угода повинні мати однакову валюту.',
      'Contract plan must be unique by month and passport.':
        'План контракту повинен бути унікальним за місяцем і паспортом.',
      'Entry must be unique by month and passport.':
        'Контракт повинен бути унікальним за місяцем і паспортом.',
      'Entry must be unique by contract and derivative.':
        'Запис повинен бути унікальним за контрактом і деривативом.',
      'In forward contracts end of execution is required.':
        'У форвардних контрактах кінець виконання є обовʼязковим.',
      'In forward contracts conclusion date should be less than end of execution.':
        'У форвардних контрактах дата укладення повинна бути меншою за кінцеву дату виконання.',
      'Currency exchange should be local.': 'Обмін валюти має бути локальним.',
      'Number should be positive.': 'Число має бути додатним.',
      'Must include either a contract or a passport, fill in at least one field.':
        'Необхідно додати або контракт, або паспорт, заповніть принаймні одне поле.',
      'Must include a contract or a passport, fill in just one field.':
        'Необхідно додати контракт або паспорт, заповніть лише одне поле.',
      'Please select exchange rate': 'Будь ласка, виберіть валютний курс',
      'Currency exchange rate should be in {}': 'Курс обміну валют має бути в {}',
      'Such sale plan already exists': 'Такий план продажу вже існує',
      'conclusion date can`t be in the past': 'дата укладання не може бути в минулому часі',
      'conclusion date can`t be less then "Expiration start"':
        'дата завершення не може бути менше, ніж «Початок терміну дії»',
      'conclusion date can`t be greater then "Expiration end"':
        'Дата укладання не може бути більшою за «Дату закінчення»',
      'A ticket cannot be created with an unconfirmed indicator':
        'Тікет не можна створити з не підтвердженим індикатором.',
      'Cannot create ticket from expired indicator':
        'Неможливо створити тікет з простроченим індикатором',
      'Contract volume can`t be less than contract executed volume':
        'Обсяг контракту не може бути меншим за виконаний обсяг контракту',
      'Contract {obj.contract_number} can`t be with draft status when execution exists':
        'Контракт {obj.contract_number} не може мати статус чернетки, якщо існує виконання',
      'Contract status of all positions can’t be Draft.':
        'Статус контракту для всіх позицій не може бути чернеткою.',
      'Cannot create contract from lost ticket': 'Неможливо створити контракт із втраченого тікету',
      'The receiver must have "buyer" role.': 'Одержувач повинен мати роль "покупця".',
      'The producer must be marked as a producer.': 'Виробник повинен бути позначений як виробник.',
      'Consignment type must be set if there is no contract.':
        'Тип накладної повинен бути вказаний, якщо контракт відсутній.',
      'Consignment type must be "{type}" for {contract_type} contract.':
        'Тип вантажу має бути "{type}" для контракту {contract_type}.',
      'you has no permissions to change approved ticket data':
        'у вас немає прав для зміни даних затвердженого тікета',
      'you have no permissions to change approved ticket data':
        'у вас немає прав для зміни даних затвердженого квитка',
      'Tolerance should be in range 0-100': 'Толеранс має бути в діапазоні 0-100',
      'No contract': 'Без контракту',
      'No charges': 'Без витрат',
      'charge don`t have calculation': 'немає розрахунку для витрат',
      'Key {str(e)} does not exist': 'Ключ {str(e)} не існує',
      'No main option available': 'Основний варіант недоступний',
      'No main option available due to duplicates': 'Основний варіант недоступний через дублікати',
      'The number of objects is not specified.': "Кількість об'єктів не вказана.",
      'Contract purpose filter': 'Фільтр призначення контракту',
      All: 'Всі',
      'Personal info': 'Персональна інформація',
      Permissions: 'Права',
      'Important dates': 'Важливі дати',
      "File name contains the invalid characters (don't use special symbols like '<>|&=-;`/\\').":
        "Назва файлу містить неприпустимі символи (не використовуйте спеціальні символи, як-от '<>|&=-;`/\\').",
      'Please check the data you would like to import. It seems that the spreadsheet has a lot of empty rows or to long.\nThe best way to import the registry is to copy only data to the new spreadsheet, save it and import again.\nIn case you see this message again please contact Support Manager.':
        'Перевірте дані, які ви хочете імпортувати. Здається, що таблиця має багато порожніх або довгих рядків. \nНайкращий спосіб імпортувати реєстр — скопіювати тільки дані в нову таблицю, зберегти їх і імпортувати знову.\nЯкщо ви бачите це повідомлення знову, будь ласка, звʼяжіться з менеджером підтримки.',
      English: 'Англійська',
      Ukrainian: 'Українська',
      Russian: 'Русский',
      Sunday: 'Неділя',
      Monday: 'Понеділок',
      Tuesday: 'Вівторок',
      Wednesday: 'Середа',
      Thursday: 'Четвер',
      Friday: 'Пʼятниця',
      Saturday: 'Субота',
      'Dark theme': 'Темна тема',
      'White theme': 'Біла тема',
      'Collapsed navbar': 'Згорнута панель навігації',
      'Open navbar': 'Відкрита панель навігації',
      'Sale deals': 'Продажні угоди',
      'Purchase deals': 'Закупівельні угоди',
      'Export deals': 'Експортні угоди',
      'Intermediate deals': 'Проміжні угоди',
      Direct: 'Прямий',
      General: 'Загальне',
      Lineup: 'Lineup',
      'Table view': 'Вид таблиці',
      'List view': 'Перегляд списку',
      'Block view': 'Перегляд блоків',
      'Passport margin selector': 'Вибір маржи паспорта',
      Analytical: 'Аналітичний',
      Contracts: 'Контракти',
      Execution: 'Виконання',
      Invoices: 'Інвойси',
      Payments: 'Платежі',
      Enabled: 'Увімкнено',
      Disabled: 'Вимкнено',
      Allocation: 'Розподіл',
      'Risk position': 'Позиція ризику',
      'Derivatives position': 'Позиція деривативу',
      'Consolidated crop position': 'Консолідована позиція культури',
      Processing: 'Обробка',
      Unloaded: 'Розвантажено',
      'Contract tolerance': 'Толеранс контракту',
      'Ticket tolerance': 'Толеранс тікету',
      'Indicator tolerance': 'Толеранс індикатору',
      'Is default': 'За умовчанням',
      'Business Unit': 'Бізнес-юніт',
      Year: 'Рік',
      Years: 'Роки',
      'Type Of Activity': 'Вид діяльності',
      'Types Of Activity': 'Види діяльності',
      Author: 'Автор',
      'Last editor': 'Останній редактор',
      Updated: 'Оновлено',
      Created: 'Створено',
      Yes: 'Так',
      'Site setting': 'Налаштування сайту',
      'Table name': 'Назва таблиці',
      'Column name': 'Назва стовпчика',
      'New title': 'Нова назва',
      Visible: 'Наявний',
      Users: 'Користувачі',
      'Columns Set Config': 'Конфігурація набору стовпців',
      'Form name': 'Назва форми',
      'Field name': 'Назва поля',
      Required: "Обов'язково",
      title: 'назва',
      'Filter level (table)': 'Фільтр рівня (таблиця)',
      Collection: 'Конфігурація',
      'New name': 'Нова назва',
      'Can use export button': 'Можна використовувати кнопку експорту',
      'Can view settings': 'Можна переглядати налаштування',
      'Can view logentry': 'Перегляд даних про вхід',
      'Can view users': 'Перегляд списку користувачів',
      'Can view user details': 'Перегляд даних користувачів',
      'Can edit closed elements': 'Можна редагувати закриті елементи',
      'Can view contracts analytics': 'Перегляд аналітики контрактів',
      'Can view logistics analytics': 'Перегляд аналітики логістики',
      'Can view finances analytics': 'Перегляд аналітики фінансів',
      'Can view clients analytics': 'Перегляд аналітики контрагентів',
      'Can view market analytics': 'Перегляд аналітики ринку',
      'Can view passports analytics': 'Перегляд аналітики паспортів',
      'Can edit approval status': 'Можна редагувати статус підтвердження',
      'Can view playground templates': 'Можна переглядати пісочницю для шаблонів',
      'Profile Owner': 'Профіль власника',
      'Business Unit Filter': 'Фільтр бізнес-юніт',
      'Application language': 'Мова програми',
      'First day of week': 'Перший день тижня',
      'Application color scheme': 'Схема кольорів системи',
      'Application font': 'Шрифт програми',
      'Navbar grouping': 'Групування панелі навігації',
      'Direct or general services by default': 'Прямі або загальні послуги за замовчуванням',
      'Direct or general multiservices by default':
        'Прямі або загальні мультипослуги за замовчуванням',
      'Sale or purchase deals by default': 'Продаж або закупівля за замовчуванням',
      'Sale or purchase general agreement by default':
        'Продажна або закупівельна генеральна угода за замовчуванням',
      'Warehouse type by default': 'Тип складу за замовчуванням',
      'Lineup report by default': 'Звіт Lineup за замовчуванням',
      'Counterpartiew view by default': 'Перегляд контрагента за замовчуванням',
      'Persons view by default': 'Перегляд контактів за замовчуванням',
      'Tickets view by default': 'Перегляд тікетів за замовчуванням',
      'Contracts view by default': 'Перегляд контрактів за замовчуванням',
      'Contract tab by default': 'Вкладка контракту за замовчуванням',
      'Default margin view': 'Дефолтний вигляд маржі',
      'Invoices view by default': 'Перегляд інвойсів за замовчуванням',
      'Vessels view by default': 'Перегляд суден за замовчуванням',
      'Passports view by default': 'Перегляд паспортів за замовчуванням',
      'Voyages view by default': 'Перегляд рейсів за замовчуванням',
      'Position report by default': 'Звіт про позицію за замовчуванням',
      Wizard: 'Wizard',
      'Hide navbar': 'Приховати панель навігації',
      Recap: 'Підсумок',
      'Help buttons expanded': 'Розгорнути кнопки допомоги',
      'Simplified system': 'Спрощена система',
      'The length of the message than browser mail client can accept':
        'Довжина повідомлення, яку може прийняти поштовий клієнт браузера',
      'Warehouse page view by default': 'Вид сторінки складу за замовчуванням',
      'Warehouse-Rest': 'Склад-Залишки',
      'Warehouse Balance': 'Баланс складу',
      'Warehouse list': 'Список складів',
      Trade: 'Торгівля',
      Losses: 'Втрати',
      Reservations: 'Резервації',
      'Stock Reserve Units': 'Одиниці резерву запасів',
      'BI role name list': 'Список ролей BI',
      'User Profile': 'Профіль користувача',
      'Filter by this unit': 'Фільтр за цим юнітом',
      'Is default in forms': 'За замовчуванням у формах',
      'Comment text': 'Текст коментаря',
      Comments: 'Коментарі',
      'Custom statuses': 'Статуси користувачів',
      'Start date': 'Дата початку',
      'End date': 'Кінцева дата',
      'Is current': 'Поточний',
      Seasones: 'Сезонність',
      'Filter level': 'Рівень фільтра',
      'Ext Data': 'Ext Data',
      Purposes: 'Цілі',
      Boolean: 'Булевий',
      String: 'Рядок',
      Text: 'Текст',
      Choice: 'Вибір',
      'Multiple choices': 'Кілька варіантів',
      Precision: 'Призначення',
      'Custom field': 'Спеціальне поле',
      'Custom fields': 'Кастомні поля',
      'Custom field`s choice value': 'Вибір значення кастомного поля',
      Purpose: 'Ціль',
      'Date value': 'Дата',
      'Text value': 'Текстове значення',
      'Boolean value': 'Булеве значення',
      'String value': 'Рядкове значення',
      'Choice value': 'Вибране значення',
      'Choice values': 'Доступні значення',
      'Object`s custom field`s value': "Значення спеціального поля об'єкта",
      'Object`s custom field`s values': 'Значення спеціальних полів об’єкта',
      'Target field name for a value to be used in':
        'Цільове ім’я поля для значення, яке буде використано в',
      'Lower bound of the range': 'Нижня межа діапазону',
      'Upper bound of the range': 'Верхня межа діапазону',
      'Value to be applied within the range': 'Значення, яке буде застосовано в межах діапазону',
      'Indicates if the value is a percentage': 'Вказує, чи є значення у відсотках',
      RangeRule: 'Правило діапазону',
      RangeRules: 'Правила діапазону',
      'Is main': 'Є головним',
      'Select one or more basises': 'Виберіть один або кілька базисів',
      'Predictions order': 'Порядок замовлень',
      'Choose one from: %(keys)s': 'Виберіть один із: %(keys)s',
      'Select positions which you want to import': 'Виберіть позиції для імпорту',
      'Please select positions': 'Будь-ласка, виберіть позиції',
      "We couldn't find your GT Bot. Please contact with your Support Manager.":
        'Ми не змогли знайти вашого GT бота. Будь ласка, зв’яжіться з менеджером підтримки.',
      '{telegram_chat.user.profile.user.get_full_name()}, you already have a chat with us. \nIs it you?':
        '{telegram_chat.user.profile.user.get_full_name()}, у вас вже є чат з нами . \nЦе ви?',
      'Hello, {telegram_chat.user.profile.user.get_full_name()}, you have been successfully authenticated.\nNow you can use our GT Bot to receive notifications.':
        'Привіт, {telegram_chat.user.profile.user.get_full_name()}! Ви успішно пройшли аутентифікацію.\nТепер ви можете використовувати бота GT для отримання сповіщень.',
      'Thank you for your answer. Chat is Active.\nFeel free to ask your Support Manager any questions.':
        'Дякую за вашу відповідь. Чат активний.\nЯкщо виникнуть будь-які питання, будь ласка, зверніться до менеджера.',
      'Chat deactivated, contact with your Support Manager':
        'Чат деактивовано.Будь ласка, зв’яжіться з менеджером підтримки',
      'Not found': 'Не знайдено',
      'Wrong credentials or api key': 'Невірні облікові дані або ключ API',
      'Finished with status {}: {}': 'Завершено зі статусом {}: {}',
      'Client connection timeout': 'Перевищено час очікування підключення клієнта',
      "Transliteration tag translit available only with lang 'ua' or 'ru'.":
        'Тег транслітерації доступний лише з мовою «ua» або «ru».',
      'Can not be converted value to number. Example: {{ price | to_float(3) }}, where price must be a number.':
        'Неможливо перетворити значення на число. Приклад: {{ price | to_float(3) }}, де ціна має бути числом.',
      'Can not be splitted value with negative number. It should be greater than 0.':
        'Неможливо розділити значення на від’ємне число. Воно має бути більше 0.',
      'Can not split value with split_by. Example: {{ price | split_by(3) }}.':
        'Неможливо розділити значення . Приклад: {{ price | split_by(3) }}.',
      'Syntax error: {}': 'Синтаксична помилка: {}',
      'Date should be in the present': 'Дата повинна бути в сьогоденні',
      'Check volume': "Перевірте об'єм",
      'Currency rate must be same as currency': 'Платіж та рахунок повинні мати однакову валюту',
      'One user can have only one default business unit':
        'Один користувач може мати лише однин бізнес-юніт за умовчанням',
      'Wrong content type': 'Неправильний тип вмісту',
      'Crop year({crop_year}) should be in this list: {crop_years_list}':
        'Рік урожаю ({crop_year}) має бути в цьому списку: {crop_years_list}',
      'Year must be an integer': 'Рік повинен бути цілим числом',
      'Year must be in 1984-2123 interval': 'Рік має бути в інтервалі від 1984 до 2123',
      'Only one year can be default. Current ':
        'Лише один рік може бути за замовчуванням. Поточний ',
      'Lower bound and upper bound must be positive integers':
        'Нижня та верхня межі мають бути додатними цілими числами',
      'Lower bound must be less than upper bound.':
        'Нижня межа повинна бути меншою за верхню межу.',
      'The range you want to create ({}-{}) overlaps with an existing range ({}-{}).':
        'Діапазон, який ви хочете створити ({}-{}), збігається з існуючим діапазоном ({}-{}).',
      'Percentage value must be between 0 and 100.': 'Відсоткове значення має бути від 0 до 100.',
      'No custom values provided': 'Спеціальні значення не надано',
      '{} matching query ({}={}) does not exist': '{} відповідний запит ({}={}) не існує',
      'There are more than one {} (where {}={}) in the database - {}':
        'У базі даних є більше одного {} (де {}={}) - {}',
      'This quality absents in crop quality': 'Ця якість відсутня в якості врожаю',
      'Title eng': 'Назва (англ.)',
      'Is positive': 'Є позитивним',
      moisture: 'волога',
      trash: 'сміття',
      'Code name': 'Код назва',
      'code name for calculation duval': 'кодова назва для розрахунку duval',
      Quality: 'Якість',
      Qualities: 'Якості',
      'Use in templates': 'Використання в шаблонах',
      Tariff: 'Тариф',
      'Crop quality': 'Якість врожаю',
      'Crop qualities': 'Якості врожаю',
      'Can choose CropQuality': 'Можна вибрати якість урожаю',
      Packing: 'Упаковка',
      'container 20 dv, t': 'контейнер 20 dv, т',
      'container 40 dv, t': 'контейнер 40 dv, т',
      'Packing wagon, t': 'Вагон, т',
      'Packing car, t': 'Авто, т',
      'Prices section': 'Розділ цін',
      'Is included in position': 'Включити до позиції',
      'Charge-off ratio': 'Списання ставки',
      'Default VAT return in contracts for this cargo':
        'Повернення ПДВ за умовчанням у контрактах на цей вантаж',
      'Fetch derivatives': 'Отримати деривативи',
      'Is analytic parent': 'Є аналітичний',
      'Warehouse entry': 'Склад',
      'Warehouse entries': 'Склади',
      'Parse until date': 'Парсити до дати',
      Limit: 'Ліміт',
      Plan: 'План',
      'Price discount': 'Дисконт на ціну',
      'Use for volume': 'Використати для об’єму',
      'Use for price': 'Використовуйте для ціни',
      'Use duval': 'Використовуйте Duval',
      'From value': 'Із значення',
      'To value': 'До значення',
      Coefficient: 'Коефіцієнт',
      'Crop can not be a parent to itself.': 'Культура не може бути материнською сама для себе.',
      'More than two with same quality and commodity.':
        'Більше двох однакових за якістю та товаром.',
      'Intermediate logistic': 'Проміжна логістика',
      'Bill of landing': 'Коносамент',
      'Contract charge': 'Витрата контракту',
      'Disbursement bill of lading': 'Видаткова накладна',
      Vehicle: 'Транспортний засіб',
      Driver: 'Водій',
      'Document title': 'Назва документа',
      'Document status': 'Статус документа',
      'Document group name': 'Назва групи документів',
      'Checklist point': 'Пункт перевірки',
      document: 'документ',
      Reassignment: 'Переуступка',
      Offset: 'Залік',
      'Disbursement BL': 'Видаткова накладна',
      'Bill Of Lading': 'Коносамент',
      Model: 'Зразок',
      'Choose the block, to which this template will be attached. This means, you might use it only within this block':
        'Виберіть блок, до якого буде прикріплений цей шаблон. Це означає, щоможна використовувати його лише в цьому блоці',
      'These is the Title, only you will see, to identify current template':
        'Це назва, яку бачитимете лише Ви, щоб ідентифікувати шаблон',
      'Attach file in docx format to use it when generate a document from object; you also might use tags from the hint':
        "Завантажте файл у форматі .docx і використовуйте його для створення документа з властивостями об'єкта; можна використовувати теги з підказок",
      'Download file name template': 'Завантажити шаблон',
      'Document template': 'Шаблон документа',
      'Document templates': 'Шаблони документів',
      'Owner content type': 'Тип власника',
      'View {title} document checklists': 'Перегляд {title} чек-листів документів',
      'View {title} documents': 'Перегляд {title} документів',
      'Unsupported file extension. Valid extensions: ':
        'Розширення файлу не підтримується. Допустимі розширення: ',
      'Intermediate Contract': 'Проміжний Контракт',
      'Services Contract': 'Контракт про надання послуг',
      'Logistics mailing': 'Розсилка логістики',
      'Finances mailing': 'Розсилка інвойсів',
      Logistics: 'Логістика',
      'Bill of lading': 'Коносамент',
      'Intermediate multiticket': 'Проміжний мультитікет',
      Failed: 'Невдалий',
      Subject: 'Тема',
      Body: 'Тіло листа',
      'Email template group name': 'Тег',
      'Email Template': 'Шаблон email',
      'Email Templates': 'Шаблони Email',
      'Email template': 'Шаблон email',
      'Export params collection': 'Параметри експорту',
      Mailing: 'Розсилка',
      'Mailing status': 'Статус розсилки',
      'Template error: {}': 'Помилка шаблону: {}',
      'Email sent successfully! Response: {}': 'Лист успішно відправлений! Відповідь: {}',
      'No permissions for changing of finance account':
        'Немає дозволу на зміну фінансового рахунку',
      'Object can not be a child of itself.': "Об'єкт може бути дочірним сам собі.",
      Paid: 'Оплачено',
      Canceled: 'Скасовано',
      Done: 'Виконано',
      Ready: 'Готово',
      Blocked: 'Заблоковано',
      Invalid: 'Недійсний',
      'Accrued interest': 'Нараховані відсотки',
      'Accrued taxes': 'Нараховані податки',
      'Subordinated loan': 'Субординований борг',
      "Shareholder's equity": 'Власний капітал',
      "Shareholder's capital": 'Акціонерний капітал',
      'Legal reserve': "Обов'язковий резерв",
      Prepay: 'Передоплата',
      Balance: 'Баланс',
      Cargo: 'Вантаж',
      Costs: 'Витрати',
      'General expenses': 'Загальні витрати',
      Gains: 'Доходи',
      Incoming: 'Вхідні',
      Outgoing: 'Вихідний',
      Expense: 'Витрата',
      Income: 'Дохід',
      Debit: 'Дебіт',
      GET: 'GET',
      POST: 'ОПУБЛІКУВАТИ',
      HTML: 'HTML',
      XML: 'XML',
      JSON: 'JSON',
      CSV: 'CSV',
      'Calendar days': 'Календарні дні',
      'Business days': 'Робочі дні',
      '{} matching query (id={}) does not exist in the database.':
        '{} відповідний запит (id={}) не існує в базі даних.',
      'Transaction date': 'Дата транзакції',
      'Other activity': 'Інше призначення',
      'Debit finance account': 'Дебит фінансового рахунку',
      'Credit finance account': 'Кредит фінансового рахунку',
      'Debit account': 'Дебет рахунок',
      'Credit account': 'Кредит рахунок',
      'Has docs original': 'Має оригінал документів',
      'Finance account': 'Фінансовий рахунок',
      'Date of receiving': 'Дати отримання',
      'Will be used in calculator': 'Буде використано в калькуляторі',
      'Use Type': 'Тип використання',
      'Tariff Type': 'Тип тарифу',
      Auto: 'Авто',
      Railway: 'Залізниця',
      Freight: 'Фрахт',
      Financing: 'Фінансування',
      Insurance: 'Страхування',
      Unloading: 'Розвантаження',
      Loss: 'Втрата',
      'Chose specific tariff type.': 'Виберіть тип тарифу.',
      Storage: 'Зберігання',
      Receiving: 'Отримання',
      Drying: 'Сушіння',
      Cleaning: 'Очищення',
      Loading: 'Завантаження',
      'Chose specific tariff to map it with this charge.':
        "Виберіть тип тарифу, щоб зв'язати його з цією витратою.",
      'Choose the type of counterparty, to which this charge will be\n            linked to.':
        "Виберіть тип контрагента, до якого ця витрата буде прив'язана.",
      'Use this charge as passport_multiplicator for passport':
        'Використовуйте цю витрату як паспорт_мультиплікатор для паспорта',
      'Include in financing': 'Включити у фінансування',
      'Is analytic use': 'Є аналітичне використання',
      'HS-Code': 'HS-Код',
      Charges: 'Витрати',
      'General expenseses': 'Загальні витрати',
      'Other activities': 'Інші види діяльності',
      'Invoice #': 'Інвойс №',
      'Date of certification': 'Дата сертифікації',
      'Date of payment (plan)': 'Дата оплати (план)',
      'Date of payment': 'Дата оплати',
      'Date of withdrawal': 'Дата вилучення',
      'Counterpaty payment info': 'Інфо про оплату контрагента',
      'Own money': 'Власні кошти',
      'Amount USD': 'Сума, USD',
      'Additional currency': 'Додаткова валюта',
      'Additional exchange rate': 'Додатковий курс обміну',
      'Payment rate': 'Курс оплати',
      'Delivery status': 'Статус доставки',
      'Cargo not delivered': 'Вантаж не доставлений',
      'Role from': 'Роль від',
      'Role to': 'Роль до',
      'Not pay': 'Не платити',
      'Secret Invoice': 'Секретний інвойс',
      'Daily fines rate': 'Щоденна ставка штрафу',
      'Fines days count': 'Кількість днів штрафу',
      'Fines invoicing': 'Виставлення штрафів на рахунок',
      'Don`t validate counterparties': 'Не перевіряти контрагентів',
      'Related credit note': 'Пов’язаний кредит ноут',
      'Marine Cover Policy No.': 'Політика морського страхування №.',
      'Letter of Credit No.': 'Номер листа акредитиву.',
      Assignment: 'Завдання',
      'Commercial Invoice': 'Комерційний інвойс',
      'Proforma Invoice': 'Проформа інвойс',
      'Debit Note': 'Дебет-нота',
      'Credit Note': 'Кредит-нота',
      Reserve: 'Резерв',
      'Packing List': 'Пакувальний лист',
      'Related invoice': "Пов'язаний інвойс",
      Marks: 'Позначки',
      'PO number': 'Номер замовлення на поставку',
      'Note one': 'Примітка 1',
      'Note two': 'Примітка 2',
      'Can see and create secret invoice': 'Можна переглядати та створювати секретні інвойси',
      'Amount alternative': 'Сума альтернативна',
      'Currency alternative': 'Альтернативна валюта',
      'Exchange rate alternative': 'Альтернативний курс валюти',
      'Commission USD': 'Комісія, USD',
      'Trade Rate': 'Обмінний курс',
      'Bank operation': 'Банківська операція',
      CreditNote: 'Кредит ноут',
      'Commission debit finance account': 'Комісія дебит фінансового рахунку',
      'Commission credit finance account': 'Комісія кредит фінансового рахунку',
      'No single vessel with charter party date': 'Жодного судна з датою чартеру',
      PaymentPlan: 'План оплати',
      'Can approve Payment plan': 'Має повноваження підтверджувати Плани оплат',
      'Invoice value': 'Інвойс сума',
      'Payment Plan Finance': 'План оплати Фінанси',
      'Symbol/Code': 'Символ/код',
      'Is local': 'Локальна',
      Currencies: 'Валюти',
      Rate: 'Ставка',
      'Load source': 'Джерело',
      Parser: 'Парсер',
      'Weighted AVG from payments': 'Середньозважений курс по платежам',
      'Max retries count': 'Максимальна кількість спроб',
      'Retry interval step in minutes': 'Інтервал повторення в хвилинах',
      'Currency parser retry policy': 'Політика повторної спроби парсера валюти',
      'Next parse rule': 'Наступне правило парсингу',
      'Parse rule title': 'Назва правил парсингу',
      'Parse data type': 'Тип даних парсингу',
      'Parse rule': 'Правило парсера',
      'Response parse arguments': 'Аргументи парсингу відповіді',
      'Currency parse rule': 'Правило парсеру валют',
      'Currency parse rules': 'Правила парсеру валют',
      'Retry policy': 'Політика повторних спроб',
      'Parser label': 'Мітка парсера',
      'Request url': 'URL-адреса запиту',
      'Request method': 'Метод запиту',
      'Request headers': 'Заголовки запиту',
      'Request body': 'Тіло запиту',
      'Parse Time': 'Час парсингу',
      'Is the reverse course': 'Є зворотний курс',
      'Currency parser': 'Парсер валюти',
      'Currency parsers': 'Парсери валюти',
      Days: 'Дні',
      'payment condition': 'умова оплати',
      'Invoice type': 'Тип інвойсу',
      'Balance payments': 'Балансові платежі',
      Account: 'Рахунок',
      'Bank name Eng': 'Назва банку (англ)',
      'Bank code': 'Код банку',
      IBAN: 'IBAN',
      Swift: 'Swift',
      'Contact info': 'Контактна інформація',
      'Address Eng': 'Адреса (англ)',
      'Corr bank': 'Банк-кореспондент',
      'Corr IBAN': 'IBAN банку-кореспондента',
      'Corr swift': 'Swift банку-кореспондента',
      'Corr account': 'Рахунок банку-кореспондента',
      'Corr bank 1': 'Банк-кореспондент 1',
      'Corr IBAN 1': 'IBAN 1 банку-кореспондента',
      'Corr SWIFT 1': 'SWIFT 1 банку-кореспондента',
      'Corr Account 1': 'Рахунок 1 банку-кореспондента',
      'Corr bank 2': 'Банк-кореспондент 2',
      'Corr IBAN 2': 'IBAN 2 банку-кореспондента',
      'Corr SWIFT 2': 'SWIFT 2 банку-кореспондента',
      'Corr Account 2': 'Рахунок 2 банку-кореспондента',
      'Account currency': 'Валюта рахунку',
      'payment information': 'інформація про оплату',
      'Payment information': 'Інформація про оплату',
      Cash: 'Кошти',
      Regular: 'Звичайний',
      Cryptocurrency: 'Криптовалюта',
      'Account type': 'Тип рахунку',
      'Bank name': 'Назва банку',
      'Account name': 'Назва рахунку',
      'Incoming reserve amount': 'Вхідний резервний обсяг',
      'Outgoing reserve amount': 'Сума резерву на виході',
      'Initial cash': 'Початкова сума',
      'Account initial date': 'Дата створення рахунку',
      'Payment Info': 'Інформація про оплату',
      'Bank accounts': 'Банківські рахунки',
      'Can change initial amount': 'Можлива зміна початкової суми',
      Passive: 'Пасив',
      'Payment type': 'Тип оплати',
      'Finance Accounts': 'Фінансові рахунки',
      'Can configurate finblock entries': 'Можна налаштувати записи фінблока',
      Description: 'Опис',
      Use: 'Використовувати',
      InvoicePosition: 'Інвойс позиція',
      Quantity: 'Кількість',
      'From account': 'З рахунку',
      'From counterparty': 'Від контрагента',
      'To counterparty': 'До контрагента',
      'To account': 'На рахунок',
      'Invoice position': 'Інвойс позиція',
      'Credit bank account': 'Кредитний банківський рахунок',
      'Debit bank account': 'Дебет банківський рахунок',
      'Use convertation': 'Використати конвертацію',
      'Transaction number #': 'Номер транзакції №',
      'Transaction name': 'Назва транзакції',
      'Simple fee': 'Проста комісія',
      'Complex fee': 'Складна комісія',
      'Operation Type': 'Тип операції',
      Counterparty: 'Контрагент',
      'Related counterparty': "Пов'язаний контрагент",
      'Issuance Fee Type': 'Тип комісії за видачу',
      'Issuance Fee Persent': 'Відсоток комісії за видачу',
      'Cash back': 'Кешбек',
      'Cachback amount': 'Сума кешбеку',
      'Issuance Fee': 'Комісія за видачу',
      'Credit note #': 'Кредитна нота #',
      Liabilities: "Зобов'язання",
      Role: 'Роль',
      'Secret credit note': 'Секретний кредит ноут',
      'credit note': 'кредит ноут',
      'Credit notes': 'Кредит ноти',
      'Credit note': 'Кредит-нота',
      'credit note position': 'кредит ноут позиція',
      'Credit note positions': 'Кредит ноут позиції',
      Month: 'Місяць',
      'Budget Item': 'Бюджет',
      'Budget Items': 'Бюджетні пункти',
      Budgets: 'Бюджети',
      'Has docs copy': 'Має копію документів',
      'Tax return registered': 'Податкову декларацію зареєстровано',
      'SAP registered': 'SAP зареєстровано',
      'Amount correction': 'Виправлення суми',
      'DisbursementBL Consignment': 'Видаткова накладна',
      'DisbursementBL Consignments': 'Видаткові накладні',
      'Reassignment rate': 'Ставка переуступки',
      'Parent Reassignment': 'Переуступка (основна)',
      Reassignments: 'Переуступки',
      'Disbursement bill or lading': 'Видаткова накладна',
      'Reassignment amount': 'Сума переуступки',
      'Reassignment Position': 'Переуступка позиція',
      'Reassignment Positions': 'Переуступка позиції',
      Offsets: 'Заліки',
      'Finance offset': 'Фінансовий залік',
      DisbursementBL: 'Видаткова накладна',
      'Amount available': 'Доступна сума',
      'Offset Position': 'Позиція заліку',
      'Offset Positions': 'Позиціїї заліку',
      'Finance Purpose': 'Фінансова ціль',
      'Finance Purposes': 'Фінансова цілі',
      Lender: 'Кредитор',
      Borrower: 'Позичальник',
      'Margin, %': 'Маржа, %',
      'Libor, %': 'Libor, %',
      'Related fees': "Пов'язані комісії",
      Notes: 'Примітки',
      'Collateral, %': 'Застава, %',
      'Finance purpose': 'Фінансова мета',
      'Related deal': "Пов'язана угода",
      'Destination country': 'Країна призначення',
      'Start date (plan)': 'Дата початку (план)',
      'Maturity date (plan)': 'Дата погашення (план)',
      'Maturity date': 'Дата погашення',
      'Interest payment date (plan)': 'Дата виплати відсотків (план)',
      'Interest payment date': 'Дата виплати відсотків',
      Loan: 'Позика',
      Loans: 'Позики',
      'Bank Group': 'Група банків',
      'Finance Bank': 'Фінансовий банк',
      'Finance Banks': 'Фінансові банки',
      'Bank Branch': 'Банківське відділення',
      'Bank Branches': 'Банківські відділення',
      'Finance Bank Group': 'Фінансова група банків',
      'Finance Bank Groups': 'Фінансові групи банків',
      'Invoice Position Config': 'Конфігурація позиції інвойса',
      'Actual date': 'Фактична дата',
      mount: 'сума',
      'Bank Account`s rests': 'Залишки на банківському рахунку',
      'contr.': 'контр.',
      'inv.': 'інв.',
      '"Use" filter value is empty or improper.':
        'Значення фільтра "Використовувати" порожнє або неправильне.',
      AVG: 'AVG (середнє значення)',
      Save: 'Зберегти',
      Delete: 'Видалити',
      'Save as new': 'Зберегти як новий',
      'Save and add another': 'Збережіть і додайте інший',
      'Save and continue editing': 'Зберегти та продовжити редагування',
      'Test parser': 'Тест парсеру',
      'created new Invoice': 'створив новий інвойс',
      'Invoice date': 'Дата інвойсу',
      'Rate must be greater than 0': 'Ставка має бути більше 0',
      'You cant create rate for default currency':
        'Для валюти за замовчуванням не можна створити курс',
      'Too long value': 'Занадто довге значення',
      "You can't to pay more than invoice's amount. PAYMENT_DOES_NOT_EXCEED_INVOICE is True":
        'Ви не можете сплатити більше суми інвойсу. Увімкнено налаштування PAYMENT_DOES_NOT_EXCEED_INVOICE',
      'Contracts in positions must be with the same counterparties.':
        'Контракти в інвойс позиції повинні містити однакових контрагентів.',
      'Invoice position with this use must have contract_id or passport_id or logistic_id':
        'Позиція інвойсу з цим використанням повинна мати contract_id, passport_id або logistic_id',
      'Invoice position with this use, must have something one contract or passport':
        'Позиція інвойсу з цим використанням повинна мати хоча б один контракт або паспорт',
      'Plan amount sum exceeds invoice amount.': 'Сума плану перевищує суму рахунку.',
      'Payment plan date cannot be earlier than issuance date':
        'Дата планованої оплати не може бути раніше дати видачі',
      'This supplier is not a distributor.': "Цей постачальник не є дистриб'ютором.",
      'You can`t edit Invoice Position with closed contract.':
        'Не можна редагувати позицію інвойсу за закритим контрактом.',
      'You can`t change use of Invoice Position with use costs or gains.':
        'Не можна змінити призначення інвойс позиції за допомогою доходів або витрат.',
      "Wrong use 'costs' with invoice type 'outgoing'":
        'Неправильне використання «витрат» із типом інвойсу «вихідний»',
      "Wrong use 'gains' with invoice type 'incoming'":
        'Неправильне використання "доходів" із типом інвойсу вхідний',
      'Duplicated scenarios.': 'Дубльовані сценарії.',
      'Disbursement volume exceeds available consignment volume. Available volume for consignment ':
        'Обсяг виплати перевищує доступний обсяг партії. Доступний обсяг для партії ',
      'Warehouse receipts have different date with disbursement':
        'Складські квитанції мають різні дати з ВН',
      'You can`t create Finance with negative Full or Invoiced amount.':
        'Ви не можете створити фінансування з від’ємною повною або виставленою сумою.',
      '{role} {name} is not approved (or approved condition)':
        '{role} {name} не підтверджена (або підтверджена з умовою)',
      '{role} {name} has status - closed': '{role} {name} має статус «закрито»',
      'Main Bank Account for this currency already exists':
        'Основний банківський рахунок для цієї валюти вже існує',
      'Invoice with this number already exists': 'Інвойс з цим номером вже існує',
      'The Bank Account is required. The bank account of the owner-company\n                     must be indicated on each invoice. Please, check your invoices.':
        'Необхідний банківський рахунок. Рахунок власника компанії \n                     повинен бути вказаний в кожному інвойсі. Будь ласка, перевірте свої інвойси.',
      'All invoices are paid': 'Всі рахунки оплачені',
      "Amount by client can't be less zero.\n                     Please, check Payment Plan positions.":
        'Сума за клієнтом не може бути меншою за нуль. Будь ласка, перевірте позиції платіжного плану.',
      'Consignment free volume is less than requested volume, free volume is {}':
        'Вільний обсяг вантажу менший за запитуваний, вільний обсяг складає {}',
      weekend: 'вихідні',
      'sat-sun': 'сб-нд',
      'fri-sat': 'пт-сб',
      'sun-mon': 'нд-пн',
      Countries: 'Країни',
      'Phone code': 'Телефонний код',
      Districts: 'Райони',
      Delivery: 'Виконання',
      Weighing: 'Зважування',
      Rerouting: 'Перерозподіл',
      Actual: 'Фактичний',
      Addresses: 'Адреси',
      'No Google credentials found for the current file.':
        'Не знайдено облікових даних Google для поточного файлу',
      'You do not have permission to refresh these credentials.':
        'У вас немає дозволу на оновлення цих облікових даних.',
      'Google credentials successfully refreshed.': 'Облікові дані Google успішно оновлено.',
      'Google Drive file created': 'Файл Google Drive створено',
      'Link to file': 'Посилання на файл',
      'The credentials have expired or are invalid, if you are the owner of the file, please click the Refresh Google credentials button to re-authorize.':
        'Облікові дані вичерпали термін дії або є недійсними. Якщо ви є власником файлу, будь ласка, натисніть кнопку ‘Оновити облікові дані Google’, щоб повторно авторизуватися.',
      'Probably the credentials have expired or are invalid, if you are the owner of the file, please click the Refresh Google credentials button to re-authorize.':
        'Ймовірно, облікові дані вичерпали термін дії або є недійсними. Якщо ви є власником файлу, будь ласка, натисніть кнопку ‘Оновити облікові дані Google’, щоб повторно авторизуватися.',
      'File has already been imported': 'Файл уже імпортовано',
      'Found several vehicles with the save attributes.Please delete duplicates before import: {}':
        'Знайдено кілька транспортних засобів з однаковими характеристиками. Будь ласка, перед імпортом видаліть дублікати: {}',
      FAS: 'FAS',
      CFR: 'CFR',
      EXW: 'EXW',
      CPT: 'CPT',
      CIF: 'CIF',
      FOB: 'FOB',
      DDP: 'DDP',
      DAP: 'DAP',
      CIP: 'CIP',
      DAT: 'DAT',
      FCA: 'FCA',
      Handysize: 'Handysize',
      Handymax: 'Handymax',
      Seawaymax: 'Seawaymax',
      Aframax: 'Aframax',
      Suezmax: 'Suezmax',
      Panamax: 'Panamax',
      'Post-Panamax': 'Post-Panamax',
      Malaccamax: 'Malaccamax',
      Capesize: 'Capesize',
      'Volume (depart, t)': 'Обʼєм (відправлений, т)',
      'Volume (board, t)': 'Обʼєм (завантажений, т)',
      'Vehicle types': 'Типи транспортних засобів',
      'Vehicle (number)': 'Транспортний засіб (номер)',
      'Vehicle (number) alt': 'Транспортний засіб (причіп)',
      Revision: 'Ревізія',
      Imported: 'Імпортовано',
      'File ID': 'ID файлу',
      'English name': 'Назва (англ.)',
      'Full name (eng)': 'Повна назва (англ.)',
      'Bank details': 'Банківські реквізити',
      'Bank details changed': 'Змінено банківські реквізити',
      'Acceptance certificate': 'Сертифікат прийняття',
      'Logistic bill of lading': 'Логістика коносамента',
      'Logistic bills of lading': 'Логістика коносаментів',
      Car: 'Автомобіль',
      Wagon: 'Вагон',
      Container: 'Контейнер',
      Vessel: 'Судно',
      'Volume received': "Об'єм отриманий",
      'Volume departed': "Об'єм відправлений",
      'Volume departed consignment': 'Об’єм (за ТТН)',
      'Volume departed real': 'Обʼєм заліковий',
      'Volume boarded': 'Обʼєм завантажений (на борту)',
      Main: 'Головна',
      'Loading (date)': 'Завантаження (дата)',
      'Start usage (date)': 'Початок використання (дата)',
      'End usage (date)': 'Кінець використання (дата)',
      'Consignment (#)': 'Накладна (№)',
      'Volume (depart consignment, t)': 'Обʼєм (відправлена накладна по ттн, т)',
      'Volume (received real, t)': 'Обʼєм (отриманий реальний, т)',
      'Volume (gross, t)': 'Обʼєм (брутто, т)',
      'Crop (class)': 'Культура (клас)',
      'Exporter contract (#)': 'Контракт з експортером (#)',
      'Receiving (date)': 'Отримання (дата)',
      'Sale (date)': 'Продаж (дата)',
      'Boarded (date)': 'Завантажено (дата)',
      'Volume (receive, t)': 'Обʼєм (отримано, т)',
      'Volume (lost, t)': 'Обʼєм (втрачено, т)',
      'Volume (trader, t)': 'Обʼєм (торговець, т)',
      'Buyer 90 invoice': 'Покупець 90 інвойс',
      'Buyer 10 invoice': '10 інвойс покупця',
      'Railway (date)': 'Залізниця (дата)',
      'Vet (date)': 'Vet (дата)',
      'Quarantine (date)': 'Карантин (дата)',
      'Quarantine required': 'Потрібен карантин',
      'Quality (date)': 'Якість (дата)',
      'Declaration (date)': 'Декларація (дата)',
      'Broker confirmation (date)': 'Підтвердження брокера (дата)',
      'Broker confirmation required': 'Потрібне підтвердження брокера',
      'EUR 1 (date)': '1 EUR (дата)',
      'Additional document one': 'Додатковий документ один',
      'Additional document two': 'Додатково документ (2)',
      'Additional document three': 'Додатковий документ №3',
      'No balance invoice': 'Інвойс без балансу',
      'Supplier 90 invoice': 'Постачальник 90 інвойс',
      'Supplier 10 invoice': 'Постачальник 10 інвойс',
      Penalties: 'Штрафи',
      'Costs in USD': 'Витрати ,USD',
      'Penalties in USD': 'Штрафи , USD',
      'Usage cost': 'Вартість використання',
      'Purchase init price': 'Початкова ціна покупки',
      'Sale init price': 'Початкова ціна продажу',
      'Sale rader amount': 'Сума продажу торговця',
      'Sale price multiplicator': 'Мультиплікатор ціни продажу',
      'Loading place': 'Місце завантаження',
      'Discharging place': 'Місце розвантаження',
      'Payment date': 'Дата оплати',
      'Costs vat': 'Витрати ПДВ',
      'Penalties vat': 'Штрафи ПДВ',
      'Is FCA': 'FCA',
      'Sale payment conditions': 'Продаж умови платежу',
      'Purchase payment conditions': 'Закупівля умови платежу',
      'Result month': 'Результат за місяць',
      'Season year': 'Рік сезону',
      'Invoice position for costs': 'Інвойс позиція для витрат',
      'Cargo brand': 'Бренд вантажу',
      'BAGS WEIGHT IN (KGS)': 'ВАГА ТАРИ (КГ)',
      'NO OF BAGS': 'БЕЗ ТАРИ',
      'Estimated receiving (date)': 'Прогнозована дата отримання',
      'Unload costs per t': 'Вартість розвантаження на 1 тонну',
      'Unload costs unit': 'Одиниця витрат на розвантаження',
      'Use unload costs': 'Використати витрати розвантаження',
      'Forwarder contract': 'Контракт на перевезення',
      'Buyer invoice volume field': 'Обʼєм інвойсу покупця',
      'Supplier invoice volume field': 'Обʼєм інвойсу постачальника',
      'Loaded on board': 'Завантажено на борт',
      'Purpose invoicing': 'Цільове виставлення інвойсу',
      'Supplier invoiced': 'Постачальник виставив інвойс',
      'Supplier paid': 'Постачальник сплатив',
      'Buyer invoiced': 'Покупець виставив інвойс',
      'Buyer paid': 'Покупець сплатив',
      'Transloader invoiced': 'Перевалка інвойс',
      'Transloader paid': 'Перевалка оплачена',
      'Vehicle type': 'Тип ТЗ',
      'Driver first name': 'Імʼя водія',
      'Driver last name': 'Прізвище водія',
      'Driver license number': 'Водійське посвідчення',
      'Driver middle name': 'По батькові водія',
      'Driver passport seria & number': 'Серія та номер паспорта водія',
      'Driver phone number': 'Номер телефону водія',
      'Deliverer in port': 'Доставник в порту',
      'Unloading business unit': 'Бізнес-юніт розвантаження',
      'Shipment business unit': 'Бізнес-юніт відвантаження',
      'Charge type for losses': 'Тип витрати за втрати',
      'Losses accounting method': 'Метод обліку втрат',
      'Ignore loss costs': 'Ігнорувати витрати на збитки',
      'Create default loss costs': 'Створити стандартні витрати на збитки',
      'Include loss costs in self-cost by default':
        'За замовчуванням включіть витрати на збитки у собівартість',
      'Dispatch Stock reserve unit': 'Одиниця резерву відвантажувального запасу',
      'Receipt Stock reserve unit': 'Одиниця резерву приймального запасу',
      Transport: 'Транспорт',
      'Can view logistic certs': 'Перегляд сертифікатів логістики',
      'Can view logistics purchase price': 'Перегляд закупівельної ціни у транспорті',
      'Can view logistics sale price': 'Перегляд продажної ціни у транспорті',
      'Can view logistics finance tab': 'Перегляд вкладки фінансування логістики',
      'Can view logistics export tab': 'Можливість переглядати вкладку експорту логістики',
      'Can view logistics road tab': 'Перегляд вкладки дорога логістики',
      'Can view logistics quality tab': 'Перегляд вкладки якості логістики',
      'Can view logistics full tab': 'Перегляд вкладки повної логістики',
      'Can view logistics rentability': 'Перегляд рентабельністі логістики',
      'Terminal Transshipment': 'Термінал перевалки',
      'Date from': 'Дата з',
      deliverer: 'експедитор',
      'Departure station': 'Станція відправлення',
      'Destination station': 'Станція призначення',
      farm: 'господарство',
      'Daily tariff': 'Добовий тариф',
      'Transportation tariff': 'Транспортний тариф',
      'Transportation tariffs': 'Транспортні тарифи',
      'Can view Tariffs': 'Перегляд тарифів',
      'Can change Tariffs': 'Можлива зміна тарифів',
      Station: 'Станція',
      'Price in USD': 'Ціна в USD',
      'Transshipment tariff': 'Перевалка тариф',
      'Transshipment tariffs': 'Перевалка тарифи',
      Freights: 'Фрахти',
      'Basis code name for calculation purposes': 'Код базису для розрахунків',
      'Premium bonus percentage': 'Відсоток преміум бонусу',
      basis: 'базис',
      'Name (short)': 'Назва (скорочено)',
      'Prices priority': 'Пріоритет цін',
      port: 'порт',
      IMO: 'IMO',
      Age: 'Вік',
      'Owner info': 'Інформація про власника',
      'Managing company': 'Управляюча компанія',
      Flag: 'Прапор',
      'Capacity avg': 'Середня вмістимість',
      'Capacity min': 'Мінімальна місткість',
      'Capacity max': 'Ємність максимальна',
      'Marine Traffic link': 'Посилання на Marine Traffic',
      'vessel size': 'розмір судна',
      Vessels: 'Судна',
      'POL under way': 'POL в процесі',
      'At loading_port': 'В порту погрузки',
      Loaded: 'Завантажено',
      'POD under way': 'POD в процесі',
      'At discharging port': 'У порту розвантаження',
      Discharging: 'Розвантажується',
      Discharged: 'Розвантажений',
      'Voyage name': 'Назва рейсу',
      'Charter party (date)': 'Чартер-партія (дата)',
      'Freighted volume (t)': 'Фрахт обʼєм (т)',
      'Disponent owner': 'Диспонент-власник',
      Charterer: 'Фрахтувальник',
      'Laycan (start date)': 'Laycan (початкова дата)',
      'Laycan (end date)': 'Laycan (кінцева дата)',
      'Extension (start date)': 'Продовження (початкова дата)',
      'Extension (end date)': 'Продовження (кінцева дата)',
      'Sale contracts': 'Продажні контракти',
      'Date of loading start': 'Дата початку завантаження',
      'Date of loading end': 'Дата завершення завантаження',
      'Date of notice loading': 'Повідомлення про дату завантаження',
      'Date of berth loading': 'Дата завантаження на причал',
      ETA: 'ETA',
      ETB: 'ETB',
      ETC: 'ETC',
      ETS: 'ETS',
      'Loading port': 'Порт завантаження',
      'Load rate intermediate': 'Проміжна норма завантаження',
      'Date of loading start intermediate': 'Початкова дата проміжного завантаження',
      'Date of loading end intermediate': 'Кінцева дата проміжного завантаження',
      'Date of notice loading intermediate': 'Повідомлення про дату проміжного завантаження',
      'Date of berth loading intermediate': 'Дата проміжного завантаження на причал',
      'Loaded volume (t) intermediate': "Проміжний завантажений об'єм (т)",
      'ETA intermediate': 'ETA проміжна',
      'ETB intermediate': 'ETB проміжна',
      'ETC intermediate': 'ETC проміжна',
      'ETS intermediate': 'ETS проміжна',
      'Loading port intermediate': 'Порт завантаження (проміжний)',
      'Disch rate intermediate': 'Норма розвантаження проміжна',
      'Date of discharging start intermediate': 'Дата початку розвантаження (проміжна)',
      'Date of discharging end intermediate': 'Дата завершення розвантаження (проміжна)',
      'Date of notice destination intermediate': 'Повідомлення про дату розвантаження (проміжна)',
      'Date of berth destination intermediate': 'Дата проміжного розвантаження на причал',
      'Volume discharged intermediate': 'Обʼєм розвантажений (проміжний)',
      'Destination port': 'Порт призначення',
      'Disch rate': 'Норма розвантаження',
      'Date of discharging start': 'Дата початку розвантаження',
      'Date of discharging end': 'Дата завершення розвантаження',
      'Date of notice destination': 'Повідомлення про дату місця призначення',
      'Date of berth destination': 'Дата причалу (розвантаження)',
      'Shortage rate': 'Shortage ставка',
      'Demurrage rate': 'Ставка демереджа',
      'Freight costs': 'Фрахт витрати',
      'Freight info': 'Інформація про фрахт',
      'Freight broker costs': 'Витрати на брокера з вантажних перевезень',
      'Loading dem/dis': 'Завантаження dem/dis',
      'Destination dem/dis': 'Призначення dem/dis',
      'Additional costs': 'Додаткові витрати',
      'Consecutive voyages': 'Послідовні рейси',
      'Do not use in Freight position': 'Не використовувати в позиціях фрахту',
      'Delivery condition': 'Умови доставки',
      'Intermediate delivery condition': 'Проміжні умови доставки',
      'Destination delivery condition': 'Умови доставки в пункт призначення',
      'Intermediate destination delivery condition':
        'Умова доставки до проміжного пункту призначення',
      'Free days JCC': 'Безкоштовні дні JCC',
      'Free days EWRP': 'Безкоштовні дні EWRP',
      'Volume intake (t)': 'Об’єм надходження (т)',
      'Capacity est': 'Розрахункова місткість',
      'Tolerance, %': 'Толеранс, %',
      'Loaded volume (t)': 'Завантажений обʼєм (т)',
      'Volume discharged': 'Обʼєм розвантажений',
      charterer: 'фрахтувальник',
      'Voyage Few Commodity': 'Рейс з декількома товарами',
      'Min amount': 'Мінімальна сума',
      'Max amount': 'Максимальна сума',
      'Use as cost': 'Використати як витрату',
      'Use in positon': 'Використовувати в позиції',
      'Voyage Tariff': 'Тариф на рейс',
      'Voyage Tariffs': 'Тарифи на рейси',
      'Name (long)': 'Назва (повна)',
      terminal: 'термінал',
      Direction: 'Напрямок',
      station: 'станція',
      Stations: 'Станції',
      'Loss type': 'Тип втрати',
      'Decline quantity (batch self-costs will be increased)':
        'Зменшення кількості (собівартість партії буде збільшена)',
      'Decline quantity and amounts (batch self-costs will be saved)':
        'Зменшення кількості та сум (собівартість партії буде збережена)',
      'Logistic loss': 'Логістичні втрати',
      'Logistic losses': 'Логістичні втрати',
      'Place of destination': 'Місце призначення',
      'Places of destination': 'Місця призначення',
      'Unloading volume (t)': "Об'єм розвантаження (т)",
      Carrier: 'Перевізник',
      LOI: 'LOI',
      'Can create bills of lading from logistics': 'Можна створювати коносаменти з логістики',
      'Ship classification code name for idintification purposes':
        'Код класифікації корабля для ідентифікації',
      'Ships classification': 'Класифікація кораблів',
      Vet: 'Vet',
      Quarantine: 'Карантин',
      Declaration: 'Декларація',
      'EUR.1': '1 EUR',
      'Broker confirmation': 'Підтвердження брокера',
      'Document one': 'Документ один',
      'Document two': 'Документ два',
      'Document three': 'Документ три',
      Document: 'Документ',
      'Type of certificate document': 'Тип сертифіката',
      'Logistic Certificate Document': 'Сертифікат логістики',
      'Logistic Certificate Documents': 'Сертифікати логістики',
      'Transloaded date': 'Дата перевалки',
      'Transloaded volume': 'Обʼєм перевалки',
      'Intermodal Transport': 'Проміжний транспорт',
      'Intermodal Transports': 'Проміжний транспорт',
      'Sample In date': 'Дата отримання зразка',
      'Sample in no.': 'Номер зразка.',
      'Sample type': 'Тип зразка',
      Preshipment: 'Попереднє відвантаження',
      Stocklot: 'Залишкова партія',
      'Sample out date': 'Дата видачі зразків',
      'Sample out from': 'Зразок із',
      'Quantity (MT)': 'Кількість (MT)',
      Received_date: 'Дата отримання',
      receiver: 'одержувач',
      Courier: 'Кур’єр',
      'AWB No.': 'AWB номер.',
      'Result status': 'Результат статус',
      Undefined: 'Невизначено',
      Result_date: 'Дата виконання',
      'Reason for rejection': 'Причина відхилення',
      'Cargo Receiver': 'Отримувач вантажу',
      'Receiver code': 'Код отримувача',
      'Shipment Shedule': 'Графік відвантаження',
      'Shipment Shedules': 'Графік відвантаження',
      CTL: 'CTL',
      Own: 'Власний',
      Leased: 'Орендований',
      'Shipment Schedule': 'Графік відвантаження',
      'Cargo Shipper': 'Вантажовідправник',
      'rolling stock type': 'тип Rolling складу',
      'Shipment Shedule Position': 'Позиція графіка відвантаження',
      'Shipment Shedule Positions': 'Позиції графіка відвантаження',
      'Shipment Schedule Position': 'Позиція графіка відвантаження',
      Day: 'День',
      Requested: 'Запрошено',
      Shipped: 'Відправлено',
      'Shipment Schedule Position Day': 'День позиції графіка відвантаження',
      'Shipment Schedule Position Days': 'Дні позицій графіка відвантаження',
      'Warehouse receipt': 'Складська квитанція',
      Default: 'За замовчуванням',
      'Code name for calculation purposes': 'Кодова назва для розрахункових цілей',
      'Vehicle subtype': 'Підтип транспортного засобу',
      'Vehicle subtypes': 'Підтипи транспортних засобів',
      'Date of issuance': 'Дата видачі',
      'Border Crossing Date': 'Дата перетину кордону',
      Overdue: 'Прострочено',
      'Declaration Type': 'Декларація (тип)',
      Import: 'Імпорт',
      Transit: 'Транзит',
      'Chose specific declaration type.': 'Виберіть конкретний тип декларації.',
      'Periodic customs declaration': 'Періодична митна декларація',
      'cargo customs declaration': 'митна декларація',
      'Chose specific stage': 'Виберіть етап',
      'Customs Declaration': 'Митна декларація',
      'Customs Declarations': 'Митні декларації',
      'Customs Declaration Invoice Position': 'Інвойс-позиція митної декларації',
      'Customs Declaration Invoice Positions': 'Інвойс-позиції митної декларації',
      'Customs Declaration Type': 'Тип митної деклалації',
      'Customs Declaration Types': 'Типи митної декларації',
      'Driver first name (ENG)': "Ім'я водія (англ.)",
      'Driver last name (ENG)': 'Прізвище водія (англ.)',
      'Driver passport seria & number (Ukrainian)': 'Серія та номер паспорта водія (українською)',
      'Driver license expiration date': 'Термін дії водійського посвідчення',
      'Driver passport expiration date': 'Термін дії паспорта водія',
      'Driver passport issue date': 'Дата видачі паспорта водія',
      Drivers: 'Водії',
      Trailer: 'Причіп',
      'Vehicle registration number': 'Реєстраційний номер транспортного засобу',
      'Vehicle brand': 'Марка автомобіля',
      'Vehicle model': 'Модель автомобіля',
      'Vehicle technical passport number': 'Номер технічного паспорта автомобіля',
      'Vehicle length': 'Довжина автомобіля',
      'Vehicle width': 'Ширина автомобіля',
      'Vehicle altitude': 'Висота автомобіля',
      'Vehicle unladen weight': 'Вага порожнього автомобіля',
      'Vehicle maximum weight': 'Максимальна вага автомобіля',
      'Vehicle total number of sections': 'Загальна кількість секцій автомобіля',
      'Vehicle general frame': 'Загальна місткість ємності',
      'Number of sections': 'Кількість секцій',
      'Total section volume': 'Загальний обʼєм секції',
      Vehicles: 'Транспортні засоби',
      Capacity: 'Вмістимість',
      'Working hours': 'Робочі години',
      'Works from': 'Працює з',
      'Works till': 'Працює до',
      responsible: 'відповідальний',
      Coordinates: 'Координати',
      Latitude: 'Широта',
      Longitude: 'Довгота',
      'Can view "Warehouse" page': 'Може переглядати сторінку "Склад"',
      'Can view "Warehouse-Rest" page': 'Може переглядати сторінку "Склад-Залишки"',
      'Can view "Warehouse Balance" page': 'Може переглядати сторінку "Баланс складу"',
      'Can view "Warehouse list" page': 'Може переглядати сторінку "Список складів"',
      'Can view "Shipment" page': 'Може переглядати сторінку "Відвантаження"',
      'Can view "Trade" page': 'Може переглядати сторінку "Торгівля"',
      'Can view "Losses" page': 'Може переглядати сторінку "Втрати"',
      'Can view "Stock Reserve Units" list': 'Може переглядати список “Одиниці резерву на складі”',
      'Can view "Reservation" list': 'Може переглядати список "Резервування"',
      'Batch storage time': 'Час зберігання партії',
      'Batch rest age (only on balance)': 'Вік залишку партії (тільки на балансі)',
      'Amount (basic)': 'Сума (базова)',
      'Total amount': 'Повна сума',
      'Transportation storage expense': 'Витрати на зберігання транспорту',
      'Discharge approval volume': 'Обсяг затвердження розвантаження',
      'Normal write-off coeff': 'Коефіцієнт нормального списання',
      'logistic type': 'тип логістики',
      'Cargo shipper': 'Вантажовідправник',
      'Cargo receiver': 'Отримувач вантажу',
      'Passport Connection Shipment Plan': "План відвантаження з з'єднанням паспорта",
      'Passport Connection Shipment Plans': "Плани відвантаження з з'єднанням паспортів",
      'Duplicated Logistic Ids: {}, Values: {}': 'Дубльований трансопрт: {}, значення: {}',
      "You can't import with executed contracts: {}":
        'Не можна імпортувати із виконаного контракту: {}',
      "Can't convert to float value: '{}'. ZERO_ON_CONVERSE_UNITS_ERROR={}":
        "Не вдається перетворити на десяткове число: '{}'. ZERO_ON_CONVERSE_UNITS_ERROR = {}",
      'Fix validation errors': 'Виправити помилки валідації',
      'Required field.': "Обов'язкове поле.",
      'value should be greater than 0': 'значення повинно бути більше за 0',
      'Consignments deficiency.': 'Дефіцит накладних.',
      'Google Drive Create File': 'Створити файл Google Drive',
      'Google Drive Import': 'Імпорт Google Диск',
      'Booking confirmation': 'Підтвердження бронювання',
      'has its docs cut-off date on': 'має кінцеву дату для документів',
      'Plans plan, start, end dates must be in one         month':
        'Дата початку та кінця повинні бути в межах         місяця',
      'Elevator tariff must be unique with crop, charge, date from.':
        'Тариф елеватора має бути унікальним із зазначенням культури, витрат та дати.',
      'Supplier nineteen invoice number field does not match with number from imported file':
        'Значення номера інвойсу на передоплату постачальника не збігається з номером імпортованого файлу',
      'Buyer nineteen invoice number field does not match with number from imported file':
        'Значення номера інвойсу на передоплату покупцю не збігається з номером імпортованого файлу',
      'Supplier ten invoice number field does not match with number from imported file':
        'Значення номера балансового інвойсу постачальника не збігається з номером імпортованого файлу',
      'Buyer ten invoice number field does not match with number from imported file':
        'Значення номера балансового інвойсу покупця не збігається з номером імпортованого файлу',
      'No sale contract specified for sale date': 'У продажному контрактні не вказана дата продажу',
      'You cannot amend logistic if balance invoice is settled':
        'Ви не можете вносити зміни в логістику, якщо балансовий інвойс - сплачено',
      'Logistic with this acceptance certificate already exists.':
        'Логістика із цим сертифікатом приймання вже існує.',
      'You need clean "Supplier 90 invoice" and "Supplier 10 invoice" fields before change contract':
        'Вам потрібно очистити поля «Рахунок-фактура постачальника 90» і «Рахунок-фактура постачальника 10» перед зміною контракту',
      'You need clean "Buyer 90 invoice" and "Buyer 10 invoice" fields before change contract':
        'Вам потрібно очистити поля «Рахунок-фактура покупця 90» і «Рахунок-фактура покупця 10» перед зміною контракту',
      'CCD with this acceptance certificate already exists.':
        'ВМД з цим актом приймання вже існує.',
      'Loss should have one warehouse.': 'Збиток повинен мати один склад.',
      'Object should have one warehouse.': "Об'єкт повинен мати один склад.",
      'Loss should have same business unit as contract':
        'Збитки повинні мати той же бізнес-юніт, що і контракт',
      'Warning: Logistics Volume Exceeds Warehouse Receipt':
        'Попередження: обсяг логістики перевищує складську квитанцію',
      'Bill of lading must have at least one contract.':
        'В коносаменті має бути вказано хоча б один контракт.',
      'Charge type for losses must be empty for chosen losses costs accounting method':
        'Тип збору для втрат має бути порожнім для обраного методу обліку витрат на втрати',
      'Charge type for losses can`t be empty for chosen losses costs accounting method':
        'Тип збору для втрат не може бути порожнім для обраного методу обліку витрат на втрати',
      'Disbursement bill of lading can have only logistic or intermediatelogistic, not both.':
        'Видаткова накладна може мати або логістику, або проміжну логістику, а не обидва одночасно.',
      'Contract is required.': 'Потрібен контракт.',
      'End date must be after start date.': 'Дата закінчення повинна бути після дати початку.',
      'Can not load logistic to itself.': 'Неможливо завантажити логістику.',
      'Logistics are looped.': 'Логістика зациклена.',
      'Can`t connect to contract more volume it has':
        "Не можна під'єднати до контракту більше обʼєму, ніж він має",
      'Should have only one position freight': 'Повинна бути лише одна позиція для фрахту',
      'Can`t allocate more volume that invoice position has. ':
        'Неможливо виділити більше обсягу, ніж вказано в позиції інвойсу. ',
      'This invoice exceeds logistic volume.': 'Цей інвойс перевищує обʼєм логістики.',
      'You shoud set export contract list filter':
        'Ви повинні встановити фільтр для списку експортних контрактів',
      'Voyage and passport are required': 'Потрібні рейс і паспорт',
      'Passport not found': 'Паспорт не знайдено',
      'Voyage not found': 'Рейс не знайдено',
      'Passport connected to voyage': 'Паспорт приєднано до рейсу',
      'Wrong value for invoicing_method. It should be on od (main_warehouse, warehouse_from_positions)':
        'Невірне значення для invoicing_method. Має бути на od (main_warehouse, warehouse_from_positions)',
      "Volume departed ({}t) greater than supplier contract's volume ({}t) (option included). Set {} to False to prevent this validation":
        'Надісланий обʼєм ({}t) перевищує обʼєм контракту постачальника ({}t) (навіть з урахуванням опціону). Установіть для {} статус "Помилковий" , щоб запобігти цій перевірці',
      "Logistic.loading_date ({}) can't be earlier than (purchase) contract.date_of_execution ({}) and later than contract.end_of_execution ({})":
        'Дата завантаження транспортного засобу ({}) не може бути раніше дати початку закупівельного контракту ({}) і пізніше його дати закінчення ({})',
      'With lookup by vessel vessel_name and buyer_name must be set':
        'Для пошуку за судном необхідно вказати назву судна та ім’я покупця',
      'Contract can’t be with draft status.': 'Контракт не може бути зі статусом чернетки.',
      'webhook filled': 'вебхук заповнено',
      No: 'Ні',
      'Select Source User': 'Вибрати джерело користувача',
      'Select Target User': 'Вибрати цільового користувача',
      'Subscriptions copied successfully from {} to {}.':
        'Підписки успішно скопійовані з {} до {}.',
      'available in bot': 'використовувати у боті',
      'Approval created': 'Погодження створено',
      'Approval resent request': 'Запит на повторне погодження',
      'Approval updated': 'Погодження оновлено',
      'Client created': 'Контрагент створено',
      'Client updated': 'Контрагент оновлений',
      'Client status changed': 'Статус контрагента змінено',
      'Client approving finished': 'Підтвердження контрагента завершено',
      'ClientCommentary created': 'Створено коментар по контрагенту',
      'Client proxy deadline today': 'Дедлайн для клієнтського проксі сьогодні',
      'Client Update notification at notify time':
        'Сповіщення про оновлення клієнта в обрану годину',
      'Client Update created': 'Створено',
      'Client Update updated': 'Оновлено',
      'Client deleted': 'Контрагент видалено',
      'contractbase volume_estimated_open changed':
        'змінено прогнозований відкритий обʼєм на основі контракту',
      'contractbase ending_status changed': 'змінено фінальний статус контракту',
      'contractbase final_volume clicked': 'клікнуто на фінальний обʼєм контракту',
      'Contract "Cargo confirmation" stage changed':
        'Змінився етап контракту “Підтвердження вантажу”',
      'Contract "Payment confirmation" stage changed':
        'Стадія "Підтвердження платежу" контракту змінена',
      'Contract "Final confirmation" stage changed':
        'Змінився етап контракту “Остаточне підтвердження”',
      'Contract "Cancel" stage changed': 'Стадія "Скасування" контракту змінена',
      'Purchase contract created': 'Створено закупівельний контракт',
      'Purchase contract xlsx exported': 'Закупівельний контракт експортований у форматі XLSX',
      'Purchase contract approving finished': 'Завершено погодження закупівельного контракту',
      'Purchase contract updated': 'Закупівельний контракт оновлено',
      'Purchase contract deleted': 'Закупівельний контракт видалено',
      "Ticket volume exceeds indicator's free volume":
        "Об'єм тікету перевищує вільний обʼєм індикатора",
      "Few price volume exceeds indicator's free volume":
        'Обсяг за кількома цінами перевищує вільний обсяг індикатора',
      'Request created': 'Тікет створено',
      'Request updated': 'Тікет оновлено',
      'Request canceled': 'Тікет скасовано',
      'Request saved': 'Тікет збережено',
      'Request approving started': 'Розпочато підтвердження тікету',
      'Request first approving finished': 'Запит на перше затвердження завершено',
      'Request approving finished': 'Завершено підтвердження тікету',
      'Sale contract created': 'Створено продажний контракт',
      'Sale contract xlsx exported': 'Продажний контракт експортований у форматі XLSX',
      'Sale contract created in usd': 'Продажний контракт USD створено',
      'Sale contract approving finished': 'Завершено погодження продажного контракту',
      'Sale contract updated': 'Продажний контракт оновлено',
      'Sale contract deleted': 'Продажний контракт видалено',
      'Services contract created': 'Контракт на послуги створено',
      'Services contract approving finished': 'Завершено погодження контракту на послуги',
      'Services contract updated': 'Контракт на послуги оновлено',
      'General Agreement created': 'Генеральна угода створена',
      'General Agreement xlsx exported': 'Генеральна угода xlsx експортована',
      'General Agreement approving finished': 'Завершено погодження генеральної угоди',
      'Consignment created': 'Квитанцію створено',
      'Consignment updated': 'Квитанцію оновлено',
      'Consignment deleted': 'Квитанцію видалено',
      'Prices Position Indicator(MTM price) created':
        'Створено індикатор ціни по позиціях (ціна MTM)',
      'Prices Position Indicator(MTM price) updated':
        'Оновлено індикатор ціни по позиціях (ціна MTM)',
      'Prices Position Indicator(MTM price) deleted':
        'Видалено індикатор ціни по позиціях (ціна MTM)',
      'Contract basis doc created': 'Створено документ на основі контракту',
      'Contract basis doc updated': 'Документ на основі контракту оновлено',
      'Contract basis doc deleted': 'Документ на основі контракту видалено',
      'Export contract created': 'Експортний контракт створено',
      'Export contract updated': 'Експортний контракт оновлено',
      'Export contract approving finished': 'Завершено погодження експортного контракту',
      'Export contract approving started': 'Розпочато затвердження експортного контракту',
      'Intermediate contract created': 'Створено проміжний контракт',
      'Intermediate contract updated': 'Проміжний контракт оновлено',
      'Intermediate contract approving finished': 'Завершено погодження проміжного контракту',
      'Intermediate contract approving started': 'Розпочато затвердження проміжного контракту',
      'Sale multicontract created': 'Створено продажний мультіконтракт',
      'Sale multicontract updated': 'Продажний мультіконтракт оновлено',
      'Sale multicontract deleted': 'Продажний мультіконтракт видалено',
      'Sale multicontract approving finished': 'Завершено погодження продажного мультіконтракту',
      'Purchase multicontract created': 'Створено закупівельний мультіконтракт',
      'Purchase multicontract updated': 'Закупівельний мультіконтракт оновлено',
      'Purchase multicontract deleted': 'Закупівельний мультіконтракт видалено',
      'Purchase multicontract approving finished':
        'Завершено погодження закупівельного мультіконтракту',
      'Intermediate multicontract created': 'Проміжний мультіконтракт створено',
      'Intermediate multicontract updated': 'Проміжний мультіконтракт оновлено',
      'Intermediate multicontract deleted': 'Проміжний мультіконтракт видалено',
      'Intermediate multicontract approving finished':
        'Завершено погодження проміжного мультіконтракту',
      'Export multicontract created': 'Експортний мультіконтракт створено',
      'Export multicontract updated': 'Експортний мультіконтракт оновлено',
      'Export multicontract deleted': 'Експортний мультіконтракт видалено',
      'Export multicontract approving finished': 'Завершено погодження експортного мультіконтракту',
      'Services multicontract created': 'Сервісний мультіконтракт створено',
      'Services multicontract updated': 'Сервісний мультіконтракт оновлено',
      'Services multicontract deleted': 'Сервісний мультіконтракт видалено',
      'Services multicontract approving finished':
        'Завершено погодження сервісного мультіконтракту',
      'Sale ticket created': 'Створено продажний тікет',
      'Sale ticket updated': 'Продажний тікет оновлено',
      'Sale ticket deleted': 'Продажний тікет видалено',
      'Sale ticket approving finished': 'Завершено погодження продажного тікету',
      'Purchase ticket created': 'Створено закупівельний тікет',
      'Purchase ticket updated': 'Закупівельний тікет оновлено',
      'Purchase ticket deleted': 'Закупівельний тікет видалено',
      'Purchase ticket approving finished': 'Завершено погодження закупівельного тікету',
      'Export ticket created': 'Експортний тікет створено',
      'Export ticket updated': 'Експортний тікет оновлено',
      'Export ticket deleted': 'Експортний тікет видалено',
      'Export ticket approving finished': 'Погодження експортного тікету завершено',
      'Services ticket created': 'Сервісний тікет створено',
      'Services ticket updated': 'Сервісний тікет оновлено',
      'Services ticket deleted': 'Сервійсний тікет видалено',
      'Services ticket approving finished': 'Завершено погодження сервісного тікету',
      'Comment created': 'Коментар створено',
      'Approver commented request': 'Підтверджувач прокоментував тікет',
      'Control created': 'Контроль створено',
      'Contract charge created': 'Витрата контракту створена',
      'Contract charge updated': 'Витрата контракту оновлена',
      'Contract charge deleted': 'Витрату контракта видалено',
      'Commodity created': 'Товар створено',
      'Commodity updated': 'Товар оновлено',
      'Commodity deleted': 'Товар видалено',
      'Document uploaded': 'Документ завантажено',
      'Passport custom status changed': 'Статус паспорта змінено',
      'Passport created': 'Паспорт створено',
      'Passport updated': 'Паспорт оновлено',
      'Passport approving finished': 'Погодження паспорта завершено',
      'Control Passport P&L': 'Контроль Паспорт PnL',
      'Passport margin changed (distinct to "Margin control")':
        'Змінився маржинальний показник паспорта (відмінно від “Контролю маржі”)',
      'Passport deleted': 'Паспорт видалено',
      'Finance approving finished': 'Погодження інвойсу завершено',
      'Finance created': 'Інвойс створено',
      'Finance updated': 'Інвойс оновлено',
      'Finance deleted': 'Інвойс видалено',
      'Payment created': 'Створено оплату',
      'Payment updated': 'Оплату оновлено',
      'Payment deleted': 'Оплату видалено',
      'PaymentPlan created': 'План оплати створено',
      'PaymentPlan updated': 'План оплати оновлено',
      'Disbusment BLS created': 'Видаткова накладна створена',
      'Disbusment BLS updated': 'Видаткова накладна оновлена',
      'Disbusment BLS deleted': 'Видаткову накладну видалено',
      'PaymentPlan approving finished': 'Підтвердження плану оплати завершено',
      'Voyage created': 'Рейс створено',
      'Voyage updated': 'Рейс оновлено',
      'Voyage ETA today': 'Очікуваний час прибуття рейсу, сьогодні',
      'VoyageFewCommodity volume_intake updated':
        'Оновлено обʼєм прийому вантажу для кількох товарів рейсу',
      'Bill Of Lading created': 'Коносамент створено',
      'Bill Of Lading updated': 'Коносамент створений',
      'Logistic import finished': 'Імпорт логістики завершено',
      'Logistic created': 'Логістика створена',
      'Logistic updated': 'Логістику оновлено',
      'Logistic deleted': 'Логістику видалено',
      'Logistic uploaded': 'Логістику завантажено',
      'Logistic canceled': 'Логістику скасовано',
      'Logistic create qualities finished': 'Логістика створена з урахуванням вимог до якості',
      'Port created': 'Порт створено',
      'Port updated': 'Порт оновлено',
      'Port deleted': 'Порт видалено',
      'Terminal created': 'Термінал створено',
      'Terminal updated': 'Термінал оновлено',
      'Terminal deleted': 'Термінал видалено',
      'Warehouse loss created': 'Складська втрата створена',
      'Warehouse loss updated': 'Складська втрата оновлена',
      'Warehouse loss deleted': 'Складська втрата видалена',
      'Operation created': 'Операція створена',
      'User custom event': 'Користувацька подія',
      'Customs cargo declaration created': 'Створено митну декларацію на вантаж',
      'Customs cargo declaration updated': 'Оновлено митну декларацію на вантаж',
      'Customs cargo declaration deleted': 'Видалено митну декларацію на вантаж',
      'Contract signed': 'Контракт підписано',
      'Notification bot': 'Бот для Нотифікацій',
      'Client bot': 'Клієнт бот',
      '💬 Add comment': '💬 Додати коментар',
      '✅Approve/❌Decline': '✅Схвалити / ❌Відхилити',
      '🔗 Open in browser': '🔗 Відкрити в браузері',
      'Enable/Disable subscription': 'Увімкнути/Вимкнути підписку',
      'Append objects url to body text':
        "Додайте гіперпосилання об'єкта (URL-адресу) до основного тексту",
      'Include Approve/Decline action buttons': 'Додати кнопки Підтвердити/Відхилити',
      'POST http request with template as application/json':
        'POST запит HTTP з використанням шаблону у форматі application/json',
      'Send simple webhook without template': 'Відправити простий вебхук без шаблону',
      'Invalid JSON: {e}': 'Неправильний JSON: {e}',
      "Header values can't contain newlines": 'Заголовки не можуть містити нові рядки',
      'Telegram bot': 'Telegram бот',
      'Enable/Disable chat': 'Увімкнути/вимкнути підписку',
      'Read-only field, will be filled automatically':
        'Поле лише для читання, буде заповнено автоматично',
      'User agreement': 'Угода з користувачем',
      'Chat language': 'Мова програми',
      'Chat user role': 'Роль користувача чату',
      Manager: 'Менеджер',
      'Clients bot': 'Бот для клієнтів',
      'Clients bots': 'Боти для клієнтів',
      'Notifications bot': 'Бот для повідомлень',
      'Notifications bots': 'Боти для повідомлень',
      Create: 'Створити',
      Edit: 'Редагувати',
      'Final confirmation': 'Фінальне підтвердження',
      'Custom status change': 'Зміна статусу',
      Info: 'Інфо',
      Warning: 'Увага',
      Error: 'Помилка',
      'Quality comment': 'Коментар по якості',
      'Upload instruction URL': 'URL інструкцій',
      'Upload instruction text': 'Завантажити інструкції',
      'report url': 'url звіту',
      'User with this username does not exist.':
        'Кабінет користувачу вже створено для цього контакту.',
      'Multiple users with this username exist.':
        'Існують кілька користувачів з таким же ім’ям користувача.',
      'User does not exist': 'Користувач не існує',
      'Multiple users with this Telegram username exist':
        'З цим нікнеймом Telegram існує декілька користувачів',
      Authorization: 'Авторизація',
      'Telegram user name': 'Нікнейм Telegram',
      'Phone number': 'Номер телефону',
      Language: 'Мова',
      Lookup: 'Пошук',
      'Current client': 'Поточний клієнт',
      Notifications: 'Сповіщення',
      'My notifications': 'Мої сповіщення',
      'Subscribe notifications': 'Підписка на сповіщення',
      'Plus for price': 'Плюс до ціни',
      Operations: 'Операції',
      'Create application': 'Створити заявку',
      'Approval config': 'Конфігурація підтвердження',
      'Please, input number!!': 'Будь ласка введіть числове значення (т)!!',
      'Upload volume greater register volume more then +15%':
        'Вага вивантаження перевищує граничну вагу (до +15%) попередньо зареєстрованого ТЗ',
      'Open contract volume less then you try to upload':
        'Відкритий обʼєм контракта менше ніж обʼєм вивантаження',
      'Volume can`t be less ZERO!!': 'Обʼєм не може бути менше нуля!!',
      "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_received or 0)) %}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%}rest: {{volume_format | format(volume | float)}}{{_('mt')}}":
        "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_received or 0)) %}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%} залишок: {{volume_format | format(volume | float)}}{{_('mt')}}",
      'Transport upload': 'Вивантаження транспорту',
      'Create client': 'Створити контрагента',
      'Company code': 'Код компанії',
      Roles: 'Ролі',
      'Business units': 'Бізнес-юніти',
      'Add contact person': 'Додати контактну особу',
      'Contact person': 'Контактна особа',
      'The Opened Ticket': 'Відкритий тікет',
      'Second name': 'Прізвище',
      'Telegram nickname': 'Нікнейм Telegram',
      Relations: 'Відношення',
      'Add person': 'Додати контакту особу',
      'Open contract volume less then you try to register':
        'Відкритий обʼєм контракта менше ніж ви реєструєте для відправки',
      'You try to register too big volume': 'Надмірно великий обʼєм',
      'The expected delivery date is not included in the delivery period according to the contract':
        'ОЧІКУВАНА ДАТА ПОСТАВКИ НЕ ВКЛАДАЄТЬСЯ В ПЕРІОД ПОСТАВКИ ЗГІДНО КОНТРАКТУ',
      "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_departed or 0))%}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%}rest: {{volume_format | format(volume | float)}}{{_('mt')}}":
        "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_departed or 0))%}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%} залишок: {{volume_format | format(volume | float)}}{{_('mt')}}",
      '"The car number"': '"Номер авто"',
      'was entered in an invalid format, the number of characters of the car number must be 8, the letters are Cyrillic!':
        'введено у неправильному форматі, кількість символів номеру авто повинна бути 8, літери повинні бути кирилицею!',
      '"The trailer number"': '"Номер причіпа"',
      'was entered in an invalid format, the number of characters of the trailer number must be 8 or less':
        'введено в невірному форматі, кількість символів номеру причепа має бути 8 або менше',
      '"Driver first name"': '"Імʼя водія"',
      'contains not alphabetical symbols!': 'містить символи, що не є літерами алфавіту!',
      '"Driver middle name"': '"По батькові водія"',
      '"Driver last name"': '"Прізвище водія"',
      'Driver phone number (+380...)': 'Номер телефону водія (+380...)',
      '"Driver phone number"': 'Номер телефону водія',
      'Phone number must start with "+" and contains 8-14 digits only':
        'Номер телефону має починатись з "+" і містити тільки цифри (від 8 до 14 цифр)',
      'Estimated delivery date': 'Очікувана дата доставки',
      'Transport order': 'Реєстрація транспорту',
      'Delivery address title': 'Адреса доставки',
      Duration: 'Тривалість',
      '1 day': '1 день',
      '3 days': '3 дні',
      '5 days': '5 днів',
      '7 days': '7 днів',
      '10 days': '10 днів',
      '14 days': '14 днів',
      'Delivery end date': 'Дата завершення доставки',
      'Delivery addresses': 'Адреси доставки',
      'The Fixed Ticket': 'Фіксована заявка',
      user: 'користувач',
      phone: 'телефон',
      Tolerance: 'Толеранс',
      Reports: 'Звіти',
      'Sale report': 'Звіт по продажах',
      'Delivery report': 'Звіт про доставку',
      'Payments report': 'Звіт про оплати',
      'Not approved tickets': 'Не підтверджені заявки',
      'You have no waiting for approve tickets!!!':
        'Немає тікетів, що очікують на підтвердження!!!',
      'Rejected tickets': 'Відхилені заявки',
      'You have no rejected tickets!!!': 'Немає відхилених заявок!!!',
      'New contracts': 'Нові контракти (без виконання)',
      'You have no new contracts!!!': 'Немає нових контрактів (без виконання)!!!',
      'Contracts in process': 'Контракти в процессі виконання',
      'You have no contracts in process!!!': 'Немає контрактів в процессі виконання!!!',
      'Executed contracts': 'Виконані контракти',
      'You have no executed contracts!!!': 'Немає виконаних контрактів!!!',
      'Contracts rests': 'Залишки по контрактах',
      'Not implemented yet': 'У процесі розробки',
      Reconciliation: 'Звірка',
      Settings: 'Налаштування',
      'Accept agreement': 'Прийняти угоду',
      'Decline agreement': 'Відхилити угоду',
      'Something went wrong:\n': 'Щось пішло не так:\n',
      'Please accept the user agreement.': 'Будь ласка, прийміть угоду користувача.',
      'You have registered as an authorized person supplier ':
        'Ви зареєструвались як довірена особа постачальника ',
      'Unfortunately, we were unable to identify your account.':
        'На жаль, нам не вдалося ідентифікувати вас.',
      'Please contact your manager for additional information.':
        'Будь ласка, зверніться до вашого менеджера для отримання додаткової інформації.',
      'For continue you must fill username in your telegram account':
        'Для продовження необїідно заповнити username в вашому Telegram аккаутні',
      Back: 'Назад',
      'Build report': 'Сформувати звіт',
      Clear: 'Очистити',
      Home: 'Головна',
      Next: 'Наступний(a)',
      Prev: 'Попередній(я)',
      FIND: 'ЗНАЙТИ',
      'Clear Lookup': 'Очистити пошук',
      'Please, choose: ': 'Будь ласка, оберіть: ',
      'Please, input: ': 'Будь ласка, введіть: ',
      'Please, select: ': 'Будь ласка, виберіть: ',
      Mo: 'Пн',
      Tu: 'Вт',
      We: 'Ср',
      Th: 'Чт',
      Fr: 'Fr',
      Sa: 'Сб',
      Su: 'Нд',
      'Something went wrong': 'Щось пішло не так',
      'Please, make your choice:': 'Будь ласка, зробіть вибір:',
      'Error occurred': 'Сталася помилка',
      'Something went wrong:': 'Щось пішло не так:',
      'Please fill all fields': 'Будь ласка, заповніть всі поля',
      'A new {} has not been created. Reason:': 'Новий {} не створено. Причина:',
      'Report config': 'Конфігурація звіту',
      'can`t get report url': 'не вдається отримати URL-адресу звіту',
      'Can`t get report file by url at bot`s report_config': 'Помилка отримання звіту з адреси',
      'Set params for report': 'Введіть параметри звіту',
      'Shipment period': 'Період відвантаження',
      Totals: 'Усього',
      'Open balance (by upload)': 'Відкритий баланс (за вивантаженням)',
      'Open balance (by register)': 'Відкритий баланс (за реєстром)',
      'Transport sent': 'Транспорт відправлено',
      'Transport uploaded': 'Транспорт вивантажено',
      'Percent execution': 'Процент виконнання',
      'by the': 'від',
      'Wrong phone number format!': 'Невірно введено номер телефону!',
      'Incorrect value': 'Некоректне значення',
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`' -":
        "ґйцукенгшщзхїфівапролджєячсмитьбюҐЙЦУКЕНГШЩЗХЇФІВАПРОЛДЖЄЯЧСМИТЬБЮʼ' -",
      abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890:
        'ґйцукенгшщзхїфівапролджєячсмитьбюҐЙЦУКЕНГШЩЗХЇФІВАПРОЛДЖЄЯЧСМИТЬБЮ1234567890',
      'START:': 'ПОЧАТОК:',
      SUCCESS: 'УСПІШНО',
      FAIL: 'НЕВДАЧА',
      'Input your comment, please:': 'Будь ласка, введіть свій коментар:',
      'Comment was added.': 'Коментар додано.',
      'Comment was`t added.': 'Коментар не було додано.',
      'Instance not found. Please contact with your Support Manager.':
        'Екземпляр не знайдено. Будь ласка, зверніться до вашого менеджера.',
      'Server error. Please contact with your Support Manager.':
        'Помилка сервера. Будь ласка, зверніться до менеджера підтримки.',
      '✅ Approve': '✅ Погодити',
      '❌ Decline': '❌ Відхилено',
      Approve: 'Затвердити',
      Decline: 'Відхилити',
      'Chat not found. Please contact with your Support Manager':
        'Чат не знайдено. Будь ласка, зверніться до вашого менеджера',
      'Chat was deactivated. Please contact with your Support Manager.':
        'Чат відключено. Будь ласка, зв’яжіться з вашим менеджером.',
      'Invalid action.': 'Невірна дія.',
      was: 'був',
      'Following users have to approve object №': "Наступні користувачі мають погодити об'єкт №",
      'Following users have to approve invoice №': 'Наступні користувачі мають погодити інвойс №',
      'Approval required for the ticket №': 'Необхідне погодження для тікета №',
      'Approval required for the passport': 'Необхідне погодження для паспорта',
      'Approval required for the contract №': 'Необхідне погодження для контракту №',
      'GrainTrack. Approval required for the contract №':
        'GrainTrack. Необхідне погодження для контракту №',
      'Approval required for the client': 'Потрібне погодження контрагента',
      'Approval required for the invoice №': 'Погодження оновлено для інвойсу №',
      'Approval required for the object №': "Погодження оновлено для об'єкта №",
      'Approval updated for the ticket №': 'Погодження оновлено для тікета №',
      'Approval updated for the passport': 'Погодження оновлено для паспорту',
      'Approval updated for the client': 'Оновлення за згодою контрагента',
      'Approval updated for the invoice №': 'Підтвердження оновлено для цього інвойсу №',
      'Approval updated for the object №': "Підтвердження оновлено для цього об'єкта №",
      'Approver commented on the request №': 'Підтверджувач прокоментував тікет №',
      'GrainTrack. Final approve for the client':
        'GrainTrack. Остаточне погодження для контрагента',
      'created a new сompany': 'створено нову компанію',
      'was commented by user': 'було прокоментовано користувачем',
      'GrainTrack. Сomment was created in the  client':
        'GrainTrack. Коментар створено в контрагенту',
      'Ticket №': 'Тікет №',
      'Contract №': 'Контракт №',
      'commented by user': 'прокоментовано користувачем',
      'GrainTrack. Сomment was created in the request №':
        'GrainTrack. Коментар створений у тікету №',
      'GrainTrack. Сomment was created in the  passport':
        'GrainTrack. Коментар створено в паспорті',
      'GrainTrack. Сomment was created in the contract №':
        'GrainTrack. Коментар створено в контракті №',
      'GrainTrack. Сomment was created in the  invoice №':
        'GrainTrack. Коментар створено в інвойсі №',
      'GrainTrack. Сomment was created in the  object №':
        "GrainTrack. Коментар створено в об'єкті №",
      'GrainTrack. Final approve for the invoice №':
        'GrainTrack. Остаточне погодження для інвойсу №',
      'created new invoice': 'створено новий інвойс',
      'GrainTrack. Final approve for the passport': 'GrainTrack. Остаточне погодження для паспорта',
      'created new contract': 'створено новий контракт',
      'GrainTrack. Final approve for the contract №':
        'GrainTrack. Остаточне погодження контракту №',
      'after unloading the auto, the balance after the GCD.':
        'після розвантаження авто, баланс після НСД.',
      'GrainTrack. Final approve for the ticket №': 'GrainTrack. Остаточне погодження тікета №',
      'GrainTrack. Started approving for the ticket №': 'GrainTrack. Розпочато погодження тікета №',
      'Created a new ticket №': 'Створено новий тікет №',
      'has edited ticket': 'тікет змінений',
      'created new USD contract': 'створено новий контракт USD',
      'created a new task': 'створено нове завдання',
      'updated a task': 'оновлено завдання',
      'Field cannot contain spaces.': 'Поле не може містити пробілів.',
      'Cargo partial executed': 'Вантаж виконано частково',
      'Volume Departed': 'Обʼєм відправлений',
      'Volume Received': "Об'єм отриманий",
      'Volume Departed Real': 'Обʼєм заліковий',
      'Volume property': 'Показник обʼєму',
      'Current progress': 'Поточний прогрес',
      'Details of trade confirmation': 'Деталі трейдерського підтвердження',
      'Transport fact': 'Транспорт факт',
      'Charge fact': 'Витрата факт',
      'Docs fact': 'Документи факт',
      'Offers warehouse': 'Оффер для складу',
      'Warehouse deviation, %': 'Відхилення якості, %',
      'Custom margin': 'Налаштування маржі',
      'credit period (days)': 'період кредитування (днів)',
      'credit percent': 'відсоток за кредитом',
      'permanent costs': 'постійні витрати',
      taxes: 'податки',
      'vat return rate for costs': 'ставка повернення ПДВ на витрати',
      Multipassport: 'Мультипаспорт',
      Multipassports: 'Мультипаспорти',
      'Multipassport ': 'Мультипаспорт ',
      'Derivative account': 'Рахунок деривативу',
      tiket: 'тікет',
      contract: 'контракт',
      'contract analytical': 'аналітичний контракт',
      execution: 'виконання',
      invoice: 'інвойс',
      payment: 'оплата',
      'Execution costs from invoices': 'Враховувати інвойси по витратам у маржі за виконанням',
      'Execution pl from dbl & ccd': 'Виконання pl з dbl та ccd',
      'Business (date)': 'Бізнес (дата)',
      'Sap orders': 'SAP замовлення',
      'Volume position as aspiration': 'Обʼєм позиції як аспірація',
      'Use commodity parent match': 'Використовуйте відповідність за батьківським товаром',
      Passports: 'Паспорти',
      'Can view passport result': 'Перегляд результату паспорта',
      'Can view passport P&L': 'Може переглядати паспорт P&L',
      'Can view passport recap': 'Можна переглянути зведення паспорту',
      'Can view passport costs&gains': 'Можна переглядати витрати & доходи паспорта',
      'Can view passport costs of goods': 'Можна переглянути паспортні витрати на товари',
      'Can view passport execution': 'Можна переглядати виконання паспорта',
      'Can view passport invoices': 'Можна переглядати інвойси паспорту',
      'Is insured': 'Застрахований',
      'This fact is done': 'Факт виконано',
      'Chain order': 'Послідовне замовлення',
      'Passport and contract have different cargo': 'Паспорт і контракт мають різні культури',
      'Ticket margin': 'Маржа тікету',
      'Ticket margin per ton': 'Маржа тікету за тонну',
      'Ticket analytical margin': 'Аналізована маржа тікета',
      'Contract analitical margin': 'Контрактна аналітична маржа',
      'Contract margin': 'Маржа контракту',
      'Contract margin per ton': 'Маржа контракту за тонну',
      'Execution margin': 'Маржа виконання',
      'Execution margin per ton': 'Маржа виконання за тонну',
      'Invoice margin': 'Маржа інвойса',
      'Invoice margin per ton': 'Маржа інвойса за тонну',
      'Payment margin': 'Маржа платежу',
      'Payment margin per ton': 'Маржа платежу за тонну',
      'Margin Control': 'Контроль маржі',
      'Margin Controls': 'Контроль маржі',
      'Contract No.': 'Контракт №.',
      'Business unit': 'Бізнес-юніт',
      'Conclusion Date': 'Дата укладання',
      'Buyer country': 'Країна покупця',
      'Buyer ITN': 'ІПН Покупця',
      'Supplier country': 'Країна постачальника',
      'Supplier ITN': 'ІПН Постачальника',
      'Price per t, USD': 'Ціна за тонну, USD',
      'Currency Symbol USD': 'Символ валюти USD',
      'Price per t, local': 'Ціна за тонну, місцева валюта',
      'Currency Symbol Local': 'Символ місцевої валюти',
      'Contract volume': "Об'єм контракту",
      'Incoterms Basis': 'Базис Інкотермс',
      'Derivatives margin split per contracts': 'Розділ маржі по деривативам за контрактами',
      'Total Value of contracts/Total Passport margin in USD':
        'Загальна вартість контрактів/Загальний маржинальний показник паспорта в USD',
      'Total Value of contracts/Total Passport margin in local':
        'Загальна вартість контрактів/Загальний маржинальний показник паспорта в локальній валюті',
      'Costs Plan in USD': 'План витрат у USD',
      'Costs, Invoice amount in USD': 'Витрати, сума інвойсу в USD',
      'Cost Amount per t in USD': 'Сума витрат за тонну в USD',
      'Invoice Costs Number': '№ Інвойса на витрату',
      'Invoice Date': 'Дата інвойсу',
      'Info about costs payments':
        'Інформація про платежі Витрати (Дата, Тип рахунку, Сума оплати)',
      'Amount in USD': 'Сума в USD',
      'Executed in MT': 'Виконано МТ',
      'Execution Value/Margin in USD': 'Значення виконання/Маржа в USD',
      'Execution Value/Margin in local': 'Значення виконання/Маржа в місцевій валюті',
      'BL DETAILS': 'Деталі BL',
      'self-cost': 'собівартість',
      'Invoiced Value/Margin in USD': 'Сума інвойсів/Маржа в USD',
      'Invoiced Value/Margin in local': 'Сума інвойсів/Маржа в місцевій валюті',
      'Invoice for cargo number.': '№ Інвойсу на вантаж.',
      'Info about cargo payments': 'Інформація про платежі Товару (Дата, Тип рахунку, Сума оплати)',
      'Info about cargo payments Non-cash': 'Інформація про безготівкові платежі за вантаж',
      'Qty MT': 'Кількість тонн',
      'Non-cash amount': 'Всього р/р',
      'Non-cash paid': 'Сплачений на р/р',
      'Payments in USD': 'Платежі у USD',
      'Payments in local': 'Платежі в місцевій валюті',
      'Non-cash payments': 'Платежі на р/р',
      'This contract cannot be connected with this volume to passport.':
        "Цей контракт не може бути зв'язаний із цим обʼємом до паспорта.",
      'Key {key} does not exist': 'Ключ {key} не існує',
      'Wrong contract purpose': 'Не вірна ціль контракту',
      'The number of passports is not specified, or more than five.':
        "Кількість паспортів не вказана, або більше п'яти.",
      'Coming to load': 'Прибуває на завантаження',
      'At load port / Under loading': 'У порту завантаження',
      'Sailed / under way to discharge': 'Відчалив / у дорозі на розвантаження',
      'At discharging port / under discharge': 'У порту призначення / розвантажується',
      'W/o status': 'Без статусу',
      'This number is input by hand': 'Це число вводиться вручну',
      'Cash Flows': 'Кеш-фло',
      'Can see cashflow recreation modal': 'Можна переглядати кеш-фло',
      'Can view reports': 'Перегляд звітів',
      'Can view debit-credit reports': 'Перегляд звіту по дебету та кредиту',
      'Can view position report': 'Перегляд звіту по позиціям',
      'Can view position origination report': 'Перегляда звіту про визначення позиції',
      'Can view position physical report': 'Перегляд звіту по позиціям',
      'Can view confirmations report': 'Перегляд звіту про підтвердження',
      'Can view signings report': 'Перегляд звіту про підписи',
      'Can view kpiconfirmations report': 'Перегляд звіту про підтвердження KPI',
      'Can view kpisignings report': 'Перегляд звіту про підписання KPI',
      'Can view balance report': 'Перегляд звіту про баланс',
      'Can view budgets report': 'Перегляд звіту про бюджет',
      'Can view purchase plan report': 'Перегляд звіту про план закупівлі',
      'Can view lineup report': 'Перегляд звіту Lineup',
      'Can view futures position report': "Перегляд звіту про ф'ючерсну позицію",
      'Can view certificate dates report': 'Перегляд звіту про дати сертифікатів',
      'Can view execution of loyalty programs report':
        'Перегляд звіту про виконання програм лояльності',
      'Can view Projects Status Detailed Summary Report':
        'Можна переглядати детальний підсумковий звіт про стан проектів',
      'Can view P&L report': 'Перегляд звіту PnL',
      'Can view P&L per passport report': 'Перегляд звіту PnL за паспортом',
      'Can view P&L Contract PNL report': 'Може переглядати звіт P&L по контракту',
      'Can view P&L Passports PNL report': 'Може переглядати звіт P&L по паспортах',
      'Can view P&L Analytical-Plan-Fact PnL report':
        'Може переглядати звіт P&L Аналітичний-План-Факт',
      'Can view P&L mark to market report': 'Перегляд звіту PnL до ринкового звіту',
      'Can view forward client status mtm report': 'Можна переглядати звіт mtm форвардних угод',
      'Can view freight in trading report': 'Можна переглядати вантаж в торговому звіті',
      'Can view fixing/hedging in trading report':
        'Можна переглядати фіксування/хеджування в торговому звіті',
      'Can view invoice risk in report': 'Перегляд звіту ризиків по інвойсам',
      'Can view credit risk in report': 'Можна переглянути кредитний ризик у звіті',
      'Can view approvals report': 'Перегляд звіту затверджень',
      'Can view cost report': 'Перегляд звіту по витратам',
      'Can view activity in management reports': 'Перегляд звіту активності в управлінських звітах',
      'Can view reconciliation reports': 'Перегляд звіту звірок',
      'Staff report': 'Звіт по користувачам',
      User: 'Користувач',
      'Contracts count': 'Контрактів',
      'Indicators count': 'Індикаторів',
      'Tickets count': 'Кількість тікетів',
      'Clients count': 'Кількість контрагентів',
      'Roles count': 'Ролей',
      'Logistics count': 'Транспорту',
      'Plans count': 'Розрахунок плану',
      'Invoices count': 'Інвойсів',
      'Payments count': 'Платежів',
      'Passports count': 'Паспортів',
      'Potentials count': 'Розрахунок потенціалу',
      'Updates count': 'Оновлення',
      'Tasks count': 'Завдання',
      'On site seconds': 'Секунд на сайті',
      Completed: 'Виконано',
      'Group By': 'Групувати за',
      'Loading / Destination': 'Завантаження / Розвантаження',
      'Vessel name': 'Назва судна',
      TOTAL: 'ВСЬОГО',
      'Invalid date format, use YYYY-MM-DD': 'Недійсний формат дати, використовуйте РРРР-ММ-ДД',
      'VAT option': 'Є ПДВ',
      'VAT value': 'Вартість ПДВ',
      'Contract number': 'Номер контракту',
      'Date of execution': 'Дата виконання',
      'End of execution': 'Доставка (дата закінчення)',
      'Contract type': 'Тип контракту',
      'Tolerance volume': 'Толеранс обʼєму',
      'Contract value': 'Сума контракту',
      'Value no VAT': 'Сума без ПДВ',
      'Price no VAT': 'Ціна без ПДВ',
      'Tolerance plus': 'Толеранс, плюс',
      'Tolerance minus': 'Толеранс, мінус',
      'Departed plus': 'Відправлено, обʼєм',
      'Departed minus': 'Відправлено мінус',
      'Departed value': 'Відправлено сума',
      'Received value': 'Отримано, сума',
      'Shipment balance': 'Баланс відвантаження',
      'Invoiced Cargo': 'Вантаж за виставленим інвойсом',
      'Paid Cargo': 'Сплачений Вантаж',
      'Unpaid Cargo': 'Несплачений вантаж',
      Signed: 'Підписано',
      'Wrong date format': 'Формат дати некоректний',
      'Start date should be less the end date': 'Дата початку повинна бути менше за кінцеву дату',
      'Upload date': 'Дата вивантаження',
      'Vehicle number': 'Номер авто',
      'Consignment number': 'Номер ТТН',
      'Declared volume': 'Обʼєм заявлений',
      'Volume accepted': 'Обсяг прийнятий',
      'Price with discount': 'Ціна з дисконтом',
      'Amount with discount': 'Сума з дисконтом',
      Debts: 'Заборгованість',
      'Period totals data': 'Загалом за період',
      'TOTAL data': 'ВСЬОГО даних',
      '⚠️ ATTENTION! The "REST HOOKS" feature is being phased out. Please transition all hooks to "Event Subscriptions". This feature will be removed in version 52.23.0. ⚠️':
        '⚠️ УВАГА! Функцію «REST HOOKS» припиняють. Будь ласка, перейдіть до використання «Підписок на події» («Event Subscriptions»). Ця функція буде видалена у версії 52.23.0. ⚠️',
      Once: 'Один раз',
      'Once a day': 'Один раз в день',
      Hourly: 'Щогодини',
      Daily: 'Щодня',
      Weekly: 'Щотижня',
      Monthly: 'Щомісячний',
      Yearly: 'Щорічно',
      'Started execution': 'Розпочато виконання',
      'Failed execution': 'Невдале виконання',
      Stopped: 'Зупинено',
      Unknown: 'Невідомо',
      January: 'Cічень',
      February: 'Лютий',
      March: 'Березень',
      April: 'Квітень',
      May: 'Травень',
      June: 'Червень',
      July: 'Липень',
      August: 'Серпень',
      September: 'Вересень',
      October: 'Жовтень',
      November: 'Листопад',
      December: 'Грудень',
      '---': '---',
      'Activate system event': 'Активувати системну подію',
      'Set contacts not updated': 'Набір контактів не оновлений',
      'Reset tickets approvals': 'Скинути схвалення тікетів',
      'Set voyage data': 'Встановити дані подорожі',
      'Close paid contractcharges': 'Закрити оплачені контрактні витрати',
      'Update contracts ending status': 'Оновити статус завершення контрактів',
      'Update clients statuses': 'Оновити статуси контрагентів',
      'Update clients regular statuses': 'Оновити регулярні статуси контрагентів',
      'Update currencies rates': 'Оновити курси валют',
      'Create quotes': 'Створити котирування',
      'Generate staff report': 'Створити звіт персоналу',
      'Update voyages statuses': 'Оновити статуси рейсів',
      'Update passports status': 'Оновити статус паспортів',
      'Create margin P&L control': 'Створити контроль P&L по маржі',
      'Update passports business dates': 'Оновити бізнес-дати паспортів',
      'Parsing sstockmarket prices': 'Парсинг цін на фондовому ринку',
      'Resend pending approvals': 'Відправити на повторне затвердження очікуючі заявки',
      'Finances data sample preparing ready': 'Підготовка зразка фінансових даних завершена',
      'Passports data sample preparing ready': 'Підготовка зразка даних паспортів завершена',
      'Payment plans data sample preparing ready':
        'Підготовка зразка даних планів платежів завершена',
      'Payments data sample preparing ready': 'Підготовка зразка даних платежів завершена',
      Action: 'Дія',
      Object: "Об'єкт",
      'Date and time at which this job is scheduled to be executed next.':
        'Дата й час, коли заплановано наступне виконання цієї роботи.',
      'Scheduled task': 'Запланована задача',
      'Scheduled tasks': 'Заплановані задачі',
      Periodicity: 'Періодичність',
      'Repeat week day': 'Повторити день тижня',
      'Month repeat': 'Повторення щомісяця',
      'On days': 'Протягом днів',
      'On months': 'Протягом місяців',
      'Total run time of this job (in seconds).':
        'Загальний час виконання цієї роботи (у секундах).',
      'Details of exception that occurred during job execution (if any).':
        'Відомості про винятки, які виникли під час виконання завдання (якщо такі є).',
      'Traceback of exception that occurred during job execution (if any).':
        'Зворотне відстеження виняткової ситуації, яка сталася під час виконання завдання (за наявності).',
      'This field can`t be empty': 'Це поле не може бути порожнім',
      'screenshot name': 'назва знімка екрана',
      'telegram chat': 'telegram чат',
      Scale: 'Масштаб',
      'Comment for screenshot': 'Коментар до скріншоту',
      'pdf report attachment': 'вкладення звіту у форматі pdf',
      'This field must be unique.': 'Це поле повинно бути унікальним.',
      'Stock Exchange': 'Біржовий курс',
      'usd/t': 'usd/т',
      'cent/b': 'цент/бушель',
      'eur/t': 'eur/т',
      Futures: "Ф'ючерси",
      Barchart: 'Гістограма',
      Agritel: 'Агрітель',
      Ticker: 'Тікер',
      'Expiration date': 'Дата експірації',
      'A date date until which the derivative is valid': 'Дата, до якої діє дериватив',
      'Derivative type': 'Тип деривативу',
      'Derivative mode': 'Режим деривативу',
      'Derivative month': 'Місяць деривативу',
      'A month when derivative will be relevant': 'Місяць, коли дериватив буде актуальним',
      'Lot size': 'Розмір лоту',
      'Point value': 'Значення пункту',
      'Service Charge': 'Комісія за послуги',
      'Exch Fees': 'Комісії за обмін',
      'NFA Fees': 'Комісія NFA',
      'cost per lot': 'вартість 1 лоту',
      'Initial amount': 'Початкова сума',
      'Price date': 'Дата ціни',
      'A date for which the price is assigned': 'Дата, на яку призначена ціна',
      Long: 'Довгий',
      Short: 'Короткий',
      Put: 'Поставити',
      Fixing: 'Фіксація',
      Rolling: 'Rolling',
      Hedging: 'Хеджування',
      'Paper trade': 'Paper Trading',
      'Deal type': 'Тип угоди',
      'Option type': 'Тип опціону',
      'Operation type': 'Тип операції',
      'Derivative Account': 'Дериватив рахунок',
      'Quantity of lots': 'Кількість лотів',
      'Business date': 'Бізнес дата',
      'Virtual operation': 'Віртуальна операція',
      'use in position': 'включити в позицію',
      'Tariff correction': 'Корекція тарифу',
      'Charge per mt': 'Витрата за 1т',
      'Consolidated type': 'Тип консолідації',
      'Exit date': 'Дата виїзду',
      Resource: 'Ресурс',
      'Parsing enabled': 'Парсинг увімкнено',
      'Quotes Parsing Config': 'Конфігуратор парсингу котирувань',
      'Quotes Parsing Configs': 'Конфігуратор парсингу котирувань',
      Measurement: 'Вимірювання',
      Measurements: 'Одиниці виміру',
      coefficient: 'коефіцієнт',
      'Measurement Crop': 'Одиниця виміру культури',
      'Measurement Crops': 'Одиниці виміру культури',
      'There can be only one chosen StockMarketPrice per day and derivative':
        'Може бути обрана тільки одна ціна фондового ринку та деривативу',
      'There is no parsers for this resource': 'Для цього ресурсу немає парсерів',
      'Parsing error. No quote for this resource and ticker: ':
        'Помилка аналізу. Немає ролі для цього ресурсу та тікета: ',
      'Direct or brother': 'Прямий або братній',
      'Direct or children': 'Прямий або дочірній',
      'Direct or descendant': 'Прямий або нащадок',
      Dimension: 'Розмір',
      Attribute: 'Атрибут',
      'Time moment': 'Момент часу',
      RecorderReference: 'Посилання на записувач',
      'Reserved volume': 'Резервований обсяг',
      'Free volume': 'Вільний обʼєм',
      'Costs amount': 'Сумма витрати',
      'additional info': 'додаткова інформація',
      'In process': 'В процесі',
      'Exporter approval (docs sent buyer)':
        'Підтвердження експортера (документи відправлено покупцеві)',
      Additional: 'Примітка',
      Cancellation: 'Анулювання',
      'Abstract Stages': 'Анотація стадії',
      'Signature (draft)': 'Підпис (чернетка)',
      'Broker signed': 'Підписано брокером',
      'We signed': 'Ми підписали',
      'Both signed': 'Підписано',
      'All signed': 'Всі підписали',
      'Originals received': 'Оригінали отримані',
      'Cargo confirmation': 'Підтвердження вантажу',
      'Payment confirmation': 'Підтвердження оплати',
      Graintrack: 'Graintrack',
      'Graintrack. Admin panel': 'Graintrack. Панель адміністратора',
      'Welcome,': 'Ласкаво просимо,',
      Documentation: 'Документація',
      'Change password': 'Змінити пароль',
      'Log out': 'Вийти',
      'To EXPORT something, first, choose the format of the file and then press SUBMIT button. This will save the file on your local drive ':
        "Для ЕКСПОРТУ потрібно вибрати формат файлу та натиснути кнопку ПІДТВЕРДИТИ. Файл буде збережено на комп'ютері ",
      Submit: 'Підтвердити',
      'Require to have a business unit for validation logistics, logistics contracts and logistic invoices':
        'Необхідно мати бізнес-юніт для перевірки логістики, контрактів та інвойсів',
      "Below is a preview of data to be imported. If you are satisfied with the results, click 'Confirm import'":
        'Нижче наведено попередній перегляд даних для імпорту. Будь ласка,перевірте завантажені дані. Якщо ви задоволені результатами, натисніть «Підтвердити імпорт»',
      'Confirm import': 'Підтвердити імпорт',
      'This importer will import the following fields: ': 'Цей імпортер імпортує такі поля: ',
      'You have not Business Unit. Make sure you have at least one business unit.':
        'У вас немає Бізнес-юніта. Переконайтеся, що у вас є принаймні один бізнес-підрозділ.',
      Errors: 'Помилки',
      'Line number': 'Номер рядка',
      'Some rows failed to validate': 'Деякі рядки не вдалося перевірити',
      'Please correct these errors in your data where possible, then reupload it using the form above.':
        'Будь ласка, виправте ці помилки у своїх даних, де це можливо. Потім повторно завантажте їх за допомогою форми вище.',
      Row: 'Рядок',
      'Non field specific': 'Не залежить від поля',
      Preview: 'Попередній перегляд',
      'Event Subscriptions': 'Підписки на події',
      application: 'заявка',
      model: 'модель',
      Add: 'Додати',
      Change: 'Змінити',
      View: 'Переглянути',
      'Last update': 'Останнє оновлення',
      'Receiving date': 'Дата отримання',
      'Loading date': 'Дата завантаження',
      'Created contract': 'Створений контракт',
      'Last update contract': 'Останнє оновлення контракту',
      'Conclusion date contract': 'Дата укладення контракту',
      'Conclusion date': 'Дата укладання',
      Line: 'Лінія',
      Bar: 'Стовпець',
      counterparty: 'контрагент',
      'business unit': 'бізнес-юніт',
      origin: 'походження',
      destination: 'призначення',
      year: 'рік',
      split: 'розділити',
      consolidated: 'консолідований',
      commodity: 'товар',
      incoterms: 'Інкотермс',
      status: 'статус',
      Alphabet: 'Алфавіт',
      Smart: 'Смарт',
      'By numbers': 'Числовий',
      'Broker confirm': 'Брокерське підтвердження',
      'N/A': 'N/A',
      'P&L': 'PnL',
      Dashboard: 'Дашборд',
      'Physical position + MTM': 'Фізична позиція + MTM',
      'Physical position by months': 'Фізична позиція за місяцями',
      'Position P&L + MTM': 'Позиція PnL + MTM',
      'Market price': 'Ринкова ціна',
      'Last market price': 'Остання ринкова ціна',
      Columns: 'Стовпці',
      List: 'Список',
      Table: 'Таблиця',
      Block: 'Блок',
      'Consignment number, vehicle number': 'Номер накладної, номер ТЗ',
      'Consignment number, vehicle number, loading date':
        'Номер накладної, номер ТЗ, дата завантаження',
      'Vehicle number, loading date': 'Номер транспортного засобу, дата завантаження',
      'Vehicle number, receiving date, custom status':
        'Номер т/з, дата отримання, спеціальний статус',
      'Vehicle number, consignment number, receiving date, custom status':
        'Номер т/з, номер накладної, дата отримання, спеціальний статус',
      'Vehicle number, loading date, consignment number if exists':
        'Номер транспортного засобу, дата завантаження, номер відвантаження, якщо він існує',
      KG: 'кг',
      T: 'т',
      OR: 'або',
      AND: 'і',
      'Short name': "Коротке ім'я",
      'Short title': 'Коротка назва',
      Boat: 'Судно',
      Forbid: 'Заборонити',
      Warn: 'Попередити',
      YouControl: 'YouControl',
      Vkursi: 'Vkursi',
      'Fixing position': 'Фіксинг позиція',
      'Passports position': 'Паспорт позиція',
      'Accounts position': 'Позиція рахунків',
      'Calculate by passport operations': 'Розрахунок за операціями за паспортом',
      'Calculate by contract operations': 'Розрахунок за операціями за контрактом',
      'Passports & Open contracts & Services': 'Паспорти & Відкриті контракти & Послуги',
      'Passports & Open contracts & Services & Spec. Accounts':
        'Паспорти & Відкриті контракти & Послуги & Спеціальні рахунки',
      'Passports & Open contracts': 'Паспорти & Відкриті контракти',
      'Passports & Spec. Accounts': 'Паспорти & Спеціальні рахунки',
      'Passports & Open contracts & Spec. Accounts':
        'Паспорти & Відкриті контракти & Спеціальні рахунки',
      'Passports & Services': 'Паспорти & Послуги',
      'Passports & Services & Spec. Accounts': 'Паспорти & Послуги & Спеціальні рахунки',
      'Open contracts & Services': 'Відкриті контракти & Послуги',
      'Open contracts & Services & Spec. Accounts':
        'Відкриті контракти & Послуги & Спеціальні рахунки',
      'Open contracts': 'Відкриті контракти',
      'Spec. Accounts': 'Спеціальні рахунки',
      'Don`t use': 'Не використовувати',
      '{Warehouse} => {Commodity} => {Contract position} => {Batch}':
        '{Warehouse} => {Commodity} => {Contract position} => {Batch}',
      '{Warehouse} => {Commodity} => {Contract position}':
        '{Warehouse} => {Commodity} => {Contract position}',
      '{Warehouse} => {Commodity}': '{Warehouse} => {Commodity}',
      'By month': 'По місяцям',
      'By date': 'По даті',
      'From movements': 'Від переміщень',
      "Don't convert amount": 'Не конвертувати суму',
      'Convert to dbl currency': 'Конвертувати в валюту dbl',
      'enables the education mode in the system for new users':
        'включає режим навчання в системі для нових користувачів',
      'Carbon Copy emails, comma separated': 'Копія, email - через кому',
      'Blind Carbon Copy emails, comma separated': 'Прихована копія, email - розділяти комою',
      'Carbon Copy emails, comma separated for logistics newsletter':
        'Email-адреси копій для розсилки логістики, розділені комами',
      'Carbon Copy emails, comma separated for invoice newsletter':
        'Email-адреси копій для розсилки інвойсів, розділені комами',
      'Uses for recipients filter': 'Використовується для пошуку одержувачів',
      'SMTP port. Not secure - 25, GMail - 587 or read documentation':
        'порт SMTP. Небезпечно – 25, GMail – 587 ,деталі в документації',
      'Use TLS encryption. Turn off if your SMTP provider does not support it':
        'Використовуйте шифрування TLS. Вимкніть його, якщо ваш постачальник SMTP не підтримує цю функцію',
      'Use SSL encryption. Turn on if TLS does not work.':
        'Використовуйте шифрування SSL. Ввімкніть його, якщо TLS не працює.',
      'SMTP usermane. Email sender': 'Логін SMTP. Відправник електронної пошти',
      "SMTP password. Stores encrypted. Don't edit.":
        'Пароль SMTP. Зберігається в зашифрованому вигляді. Не редагуйте.',
      'translate latin vehicle numbers to cyrillic': 'перетворити латинські номери т/з на кирилицю',
      'Auto create export custom declaration': 'Автоматичне створення експортної митної декларації',
      'default quantity days value from receiving date for search vehicles':
        'за замовчуванням кількість днів від дати отримання для пошуку т/з',
      'Check if this CONTRACT has this FINANCE': 'Перевірте, чи цей КОНТРАКТ містить ФІНАНСИ',
      'Create qualition on import': 'Створити якість при імпорті',
      'Check if logistic volume sum less, than contract volume. YES - prevent import, NO - notification only':
        'Відмітьте, коли обʼєм логістики менший, ніж обʼєм контракту. ТАК - заборонити імпорт, НІ - лише сповіщення',
      'Check if logistic volume departed less, than contract volume and prevent import':
        'Відмітьте, коли логістичний обʼєм відправлення менший, ніж контрактний обʼєм, і бажаєте заборонити імпорт',
      'Trying to find the buyer_contract by related vessel and buyer names':
        'Спроба знайти покупця _ контракту за назвами судна і покупця',
      'Trying to find the contract by commodity and price':
        'Намагаючись знайти контракт за товаром і ціною',
      'Creates deliverers on import': 'Створює експедитора під час імпорту',
      'Creates stations on import': 'Створює станції під час імпорту',
      'Parse quotes configures in quote parsing configs.':
        'Парсинг котирувань налаштовується у конфігах парсингу котирувань.',
      'enables default value for VAT': 'Вмикає значення ПДВ за замовчуванням',
      'Allow create customs declaration for sale contracts':
        'Дозволити створення митної декларації для продажних контрактів',
      'Allow create customs declaration for purchase contracts':
        'Дозволити створення митної декларації для закупівельних контрактів',
      'Check to apply purchase status contract filter in warehouse':
        'Перевірте, чи встановлений фільтр за статусом закупівельного контракту в складі',
      'Allow to create tickets even if client is not approved':
        'Дозволяти створювати тікети, навіть якщо контрагент не схвалений',
      'Allow to create passport from executed tickets':
        'Дозволяє створювати паспорт із оформлених тікетів',
      'Disable changing crop when create ticket from indicator':
        'Вимкнути зміну культури під час створення тікета з індикатора',
      'Do not let to change conclusion date when create contracts':
        'Заборонити можливість зміни дати укладання при створенні контрактів',
      'Logistic Google Drive file ID':
        'Перевірте налаштування LOGISTIC_FILE_GOOGLE_ID в НАСТРОЙКАХ ОРГАНІЗАЦІЇ',
      'Intermediate logistic Google Drive file ID': 'Проміжна логістика Google Drive file ID',
      'Show opened quality in logistics table': 'Показати відкриту якість в таблиці логістики',
      'Show opened passports in conslidated profit and loss':
        'Відображати відкриті паспорти в звіті PnL',
      'Show only recent year in the PNL by default':
        'За замовчуванням показувати лише останній рік у звіті PnL',
      'Group passport pnl report by commodities': 'Згрупувати звіт паспорт PnL за товари',
      'Show VAT everywhere': 'Відображати ПДВ',
      'Show return on sales': 'Відображати рентабельність продажів',
      'Show return on equity': 'Відображати рентабельність власного капіталу',
      'Restricts logistics volume for EXW contracts based on warehouse receipt.':
        'Обмежує обсяг логістики для контрактів EXW на основі складської квитанції.',
      'Show insurance in passport facts': 'Відображати страхування в паспорті',
      'Show insurance in passport PASSPORT_FACTS_USE_CHAIN_ORDER':
        'Відображати страхування в паспорті PASSPORT_FACTS_USE_CHAIN_ORDER',
      'Use chain order in passport facts': 'Використовувати порядок ланцюга в фактах паспорту',
      'Show margin by tickets in passport results':
        'Відображати маржу за тікетами в результатах паспорта',
      'Show margin by analytical in passport results':
        'Відображати маржу - аналітичну в результатах паспорта',
      'Use priority analitical in passport results for tickets':
        'Використати пріоритетну аналітику в результатах паспортів для тікетів',
      'Use priority analitical in passport results for contracts':
        'Використати пріоритетну аналітику в результатах паспорта для контрактів',
      'Show Analytical PNL in Contract PNL report and Passport PNL Distr tab':
        'Показати аналітичний ПНЛ у звіті за контрактом та вкладці розподілу ПНЛ паспорта',
      'Show margin by contracts in passport results':
        'Відображати маржу за контрактами в результатах паспорта',
      'Show margin by logistics in passport results':
        'Відображати маржу за логістикою в результатах паспорта',
      'Show margin by invoices in passport results':
        'Відображати маржу за інвойсами в результатах паспорта',
      'Show margin by payments in passport results':
        'Відображати маржу по виплатах в результатах паспорта',
      'offer role': 'пропонувати роль',
      'Tariff auto active': 'Авто тариф актив',
      'Tariff rw active': 'Залізниця тариф актив',
      'Elevator active': 'Елеватор актив',
      'Freight active': 'Фрахт актив',
      'Approval expiration time, in hours': 'Термін дії схвалення, години',
      'Define how to call "allocation"': 'Визначити, як викликати «розподіл»',
      'Allow only the same crops or child crops': 'Дозволити тільки однакові чи дочірні культури',
      'If payment is overdue': 'Якщо оплата прострочена',
      'Allow connect not more than contract volume':
        'Дозволити з’ єднання не більше, ніж об’єм контракту',
      'Enable filter for consolidated table ': 'Увімкнути фільтр для зведеної таблиці ',
      'Change view of FOB position "Load" modal':
        'Змінити відображення позиції FOB Завантаження в судно',
      'To prevent logistic from amendment when balance invoice':
        'З метою унеможливлення змін у логістиці після сплати балансового інвойсу',
      'validate if counterparty is approved before creating general agreement':
        'перевірка наявності погодженого статусу контрагента перед створенням загальної угоди',
      'To put approval config by default if user is main approver':
        'Автоматично встановити конфігурацію погодження за замовчуванням, якщо користувач обраний головним схвалювачем',
      "Logistic's loading_date must be between date_of_execution and end_of_execution its supplier_contract":
        'Дата завантаження транспортного засобу має бути в періоді виконання контракту',
      'Prevents Logistics Import while there are duplicated records in the DB by LOGISTICS_MATCHING_FIELDS':
        'Запобігає імпорту логістики за наявності в базі даних повторюваних записів за LOGISTICS_MATCHING_FIELDS',
      'Process vehicle numbers and keep only LOGISTIC_IMPORT_VEHICLE_NUMBER_LENGHT symbols':
        'Обробити номери транспортних засобів і залишити лише символи з довжиною LOGISTIC_IMPORT_VEHICLE_NUMBER_LENGH',
      'Enable/disable row selection during logistics import':
        'Увімкнути/вимкнути вибір рядків під час імпорту логістики',
      'Length of vehicle number': 'Довжина номеру транспортного засобу',
      'Skip import unchanged rows (may work incorrectly with some kind of fields like qualities or related objects)':
        'Пропустити імпорт постійних рядків (може некоректно працювати з деякими полями, такими як якості або пов’язані об’єкти)',
      'Unique logistics acceptance certificate': 'Унікальний логістичний акт приймання',
      'Check if adding bank details is required to create a counterparty':
        'Позначте, якщо необхідно додати банківські реквізити під час створення контрагента',
      'Check if adding documents is required to create an invoice':
        'Позначте, якщо необхідно додати документи під час створення інвойсу',
      'Invoice position contract or passport required':
        'Необхідний контракт або паспорт для інвойс позиції',
      'Passport is required for export contracts in finance position':
        'Для експортних контрактів у інвойс позиції потрібен паспорт',
      'Disable invoice positions for editing for cancelled or executed contracts':
        'Вимкнути можливість редагування інвойс позицій для скасованих або виконаних контрактів',
      'Dont validate the same counterparties from contracts in invoice positions':
        'Не перевіряти однакових контрагентів із контрактів у позиціях інвойсу',
      'Always check invoice numbers for duplicates':
        'Завжди перевіряти наявність дублікатів номерів інвойсів',
      'Enable inline add in tables': 'Включити вбудоване додавання в таблиці',
      'Enable quick modals to create objects':
        'Увімкнути швидкі модальні вікна для створення об’єктів',
      'Require for terminal in purchase contract for certain basises':
        "Обов'язково вказати термінал у закупівельного контракту для зазначеного базису",
      'Use costs for execution from invoices': 'Використовувати інвойси для витрат',
      'Use ccd & dbl for execution pl': 'Використовуйте ccd & dbl для виконання pl',
      'removes contracts pages, leaves only general agreements':
        'видаляє сторінки з контрактами, залишає тільки загальні угоди',
      'Suppress request_saved event for back_to_back':
        'Не надсилати повідомлення про створення тікета',
      'Require for destination with basies CIF, FOB, CFR':
        "Обов'язковий для призначення з базисом CIF, FOB, CFR",
      'Document file format to download from template':
        'Формат файлу документа для завантаження з шаблону',
      'Simplify main tables removing tabs': 'Спростити основні таблиці, видаливши вкладки',
      'Removing negotiation from charges': 'Забрати переговорний процес у витратах',
      'On purchase contracts page shows tab "CP/Seeds Prepayments"':
        'На сторінці закупівельних контрактів відображається вкладка «CP/Seeds передоплати»',
      'Show invoice positions in passport by default"':
        'Показувати інвойс позиції у паспорті за замовчуванням',
      'Default tab on derivatives report': 'Вкладка за замовчуванням у звіті про деривативи',
      'Number of months to check for pending approvals':
        'Кількість місяців для перевірки схвалень, що очікують на розгляд',
      'Models in the %(name)s application': 'Models in the %(name)s application',
      '%(name)s': '%(name)s',
      "You don't have permission to edit anything.": 'Не маєте дозволу на редагування.',
      'Recent Actions': 'Останні дії',
      'My Actions': 'Мої дії',
      'None available': 'Недоступний',
      'Unknown content': 'Невідомі дані',
    });
    /* jshint +W100 */
  },
]);
