import type ng from 'angular';

import template from './quality-rule-templates-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const QualityRuleTemplatesPageView = {
  template,
  controller: [
    '$scope',
    'gettext',
    'PageService',
    'QualityRuleTemplatesService',
    class {
      $scope: ng.IScope;
      PageService: PageService;
      QualityRuleTemplatesService: any;
      filterLevel = 'quality-rule-templates-page-view';
      gettext: ng.gettext.gettextFunction;
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
        QualityRuleTemplatesService: any,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.PageService = PageService;
        this.QualityRuleTemplatesService = QualityRuleTemplatesService;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.PageService.syncUrlFilter(this.filterLevel);
      }

      getPageConfig() {
        return {
          class: 'page-header-main-pl-tab',
          buttons: [new this.PageService.buttons.Filters()],
          filters: {
            filterLevel: this.filterLevel,
            search: true,
            multiSelects: [
              {
                argument: 'quality_rule_commodity_list',
                placeholder: this.gettext('commodities'),
                resource: 'crops.Crop',
                related_fk: 'contractqualityrule__commodity',
                related_model: 'crops.QualityRuleTemplate',
              },
            ],
          },
          pages: this.QualityRuleTemplatesService.getPageConfig(),
        };
      }
    },
  ],
};
