import ng from 'angular';

import type { FinancesService } from '../../../../finances/legacy/finances.srv';
import type { ReportsService } from '../../reports.srv';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('reports.legacy').component('physicalPositionPageView', {
    bindings: {
      filterLevel: '<?',
      queryParams: '<?',
      subtab: '<?',
      marketPriceType: '<?',
      sourcePrice: '<?',
    },
    template: require('./physical-position-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$rootScope',
    'gettext',
    'GtUtils',
    'ReportsService',
    'PageService',
    'gtFilterService',
    'FinancesService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    ReportsService: ReportsService,
    PageService: PageService,
    gtFilterService: GtFilterService,
    FinancesService: FinancesService,
  ) {
    const vm = this;
    vm.applyFilter = applyFilter;
    vm.resetFilter = resetFilter;
    vm.filterLevel = 'physical-position-page';
    vm.saleFilterLevel = 'sale-physical-position-container';
    vm.purchaseFilterLevel = 'purchase-physical-position-container';
    vm.consolidatedFilterLevel = 'consolidated-physical-position-container';
    vm.originationFilterLevel = 'origination-physical-position-container';
    vm.originationTerminalFilterLevel = 'origination-terminal-physical-position-container';
    vm.pricePositionView = 'prices';
    vm.saleInitQueryParams = {
      contract_type: 'sale',
      active: '1',
      showVoyageDetails: $rootScope.user.settings.PHYSICAL_POSITION_REPORT_SHOW_VOYAGE_DETAILS,
    };
    vm.purchaseInitQueryParams = {
      contract_type: 'purchase',
      exclude_status_list: ['cancelled', 'washout', 'approved'],
      active: '1',
      showVoyageDetails: $rootScope.user.settings.PHYSICAL_POSITION_REPORT_SHOW_VOYAGE_DETAILS,
    };
    vm.lineupFilterLevel = 'physical-position-page-lineup';
    vm.lineupQueryParams = { is_short_type: 1, sale_has_internal_chain: 0, with_bl: 0 };
    vm.buQueryParams = GtUtils.getBUQueryParams();
    vm.cropPorts = {};
    vm.applyFilterAllContainers = applyFilterAllContainers;
    vm.syncVisibilityParams = syncVisibilityParams;
    vm.changeSubtab = changeSubtab;
    vm.withoutBusinessUnit = false;
    vm.warehouseType = $rootScope.user.profile?.warehouse_show_capitalize;
    vm.warehouseTypes = ['Farm', 'Elevator', 'Terminal'];
    vm.visibilityFilters = ['marketPriceType', 'sourcePrice'];
    vm.convert = convert;
    vm.localRate = 1;
    vm.currency = 'usd';
    vm._queryParams = {};
    vm._containersQueryParams = {};
    vm.showOrigination = true;
    vm.showLineup = true;
    vm.voyageStatusOptions = [
      {
        value: 'New',
        title: gettext('New'),
      },
      {
        value: 'pol_under_way',
        title: gettext('POL under way'),
      },
      {
        value: 'at_loading_port',
        title: gettext('At loading port'),
      },
      {
        value: 'Loading',
        title: gettext('Loading'),
      },
      {
        value: 'Loaded',
        title: gettext('Loaded'),
      },
      {
        value: 'pod_under_way',
        title: gettext('POD under way'),
      },
      {
        value: 'at_discharging_port',
        title: gettext('At discharging port'),
      },
      {
        value: 'Discharging',
        title: gettext('Discharging'),
      },
      {
        value: 'Discharged',
        title: gettext('Discharged'),
      },
      {
        value: 'Executed',
        title: gettext('Executed'),
      },
      {
        value: 'Washout',
        title: gettext('Washout'),
      },
      {
        value: 'Cancelled',
        title: gettext('Cancelled'),
      },
    ];

    vm.$onInit = function () {
      vm.queryParams = {
        ...vm.queryParams,
        showVoyageDetails: $rootScope.user.settings.PHYSICAL_POSITION_REPORT_SHOW_VOYAGE_DETAILS,
      };
      PageService.setConfig(getPageConfig());
      FinancesService.CurrencyExchange.query(
        {
          currency: GtUtils.getLocalCurrency(),
        },
        function (data: any) {
          vm.localRate = data.count && data.results[0].rate;
        },
      );
      $scope.$on('gt-filter-updated_' + vm.filterLevel, onFilterUpdated);
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
      gtFilterService.setQueryParams(vm.lineupQueryParams, vm.lineupFilterLevel);
      if ($rootScope.user.settings.DEFAULT_POSITION_SUBTAB === 'market_price') {
        vm.marketPriceType = 'opposite_price_usd';
      } else {
        vm.marketPriceType = 'last_opposite_price_usd';
      }
    };

    ////////////////

    function changeSubtab(subTab: any) {
      vm.subtab = subTab;
      applyFilter();
    }

    function onFilterUpdated(ev: any, data: any) {
      data.subtab = data?.subtab || 'consolidated';
      vm.consolidatedQueryParams = data;
      vm.purchaseQueryParams = { ...vm.purchaseInitQueryParams, ...data };
      vm.saleQueryParams = { ...vm.saleInitQueryParams, ...data };
      applyFilterAllContainers();
    }

    function applyFilter() {
      const newParams = {
        multicontract_list: vm.queryParams.multicontract_list,
        cargo_list: vm.queryParams.cargo_list,
        passport_list: vm.queryParams.passport_list,
        buyer_list: vm.queryParams.buyer_list,
        supplier_list: vm.queryParams.supplier_list,
        basis_list: vm.queryParams.basis_list,
        owner_list: vm.queryParams.owner_list,
        port_list: vm.queryParams.port_list,
        internal_chain: vm.queryParams.internal_chain,
        season_list: vm.queryParams.season_list,
        with_variants: vm.queryParams.with_variants,
        with_mtm_price: vm.queryParams.with_mtm_price,
        business_unit_list: (!vm.withoutBusinessUnit && vm.queryParams.business_unit_list) || null,
        with_business_unit: vm.withoutBusinessUnit ? '0' : null,
        farm_list: (vm.warehouseType === 'Farm' && vm.farmList) || [],
        elevator_list: (vm.warehouseType === 'Elevator' && vm.elevatorList) || [],
        terminal_list: (vm.warehouseType === 'Terminal' && vm.terminalList) || [],
        responsible: vm.queryParams.responsible,
        responsible_for_signing: vm.queryParams.responsible_for_signing,
        showVoyageDetails: vm.queryParams.showVoyageDetails,
      };
      gtFilterService.setQueryParams({ ...vm.queryParams, ...newParams }, vm.filterLevel);
      vm._queryParams = newParams;
    }

    function applyFilterAllContainers() {
      const queryParams = {
        passport_list: vm.queryParams.passport_list,
        cargo_list: vm.queryParams.cargo_list,
        buyer_list: vm.queryParams.buyer_list,
        supplier_list: vm.queryParams.supplier_list,
        basis_list: vm.queryParams.basis_list,
        owner_list: vm.queryParams.owner_list,
        port_list: vm.queryParams.port_list,
        internal_chain: vm.queryParams.internal_chain,
        business_unit_list: vm.queryParams.business_unit_list,
        with_business_unit: vm.queryParams.with_business_unit,
        page: vm.queryParams.page,
        subtab: vm.queryParams?.subtab,
      };
      if (vm.subtab === 'consolidated') {
        gtFilterService.setQueryParams(vm.saleQueryParams, vm.saleFilterLevel);
        gtFilterService.setQueryParams(vm.purchaseQueryParams, vm.purchaseFilterLevel);
        gtFilterService.setQueryParams(vm.consolidatedQueryParams, vm.consolidatedFilterLevel);
      }
      if (vm.subtab === 'origination') {
        gtFilterService.setQueryParams(vm.consolidatedQueryParams, vm.originationFilterLevel);
      }
      if (vm.subtab === 'originationterminal') {
        gtFilterService.setQueryParams(
          vm.consolidatedQueryParams,
          vm.originationTerminalFilterLevel,
        );
      }
      vm._containersQueryParams = queryParams;
    }

    function resetFilter() {
      vm.queryParams = {
        internal_chain: '0',
        withoutBusinessUnit: false,
        subtab: vm.queryParams.subtab,
      };
      applyFilter();
    }

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-clients-tab',
        pages: ReportsService.getPagesConfig(),
      };
      config.buttons = [
        new PageService.buttons.Refresh(vm.filterLevel),
        new PageService.buttons.ScreenCaptureTask(),
      ];
      return config;
    }

    function convert(value: any, from: any) {
      from = from || 'usd';
      value = parseFloat(value) || 0;
      const rate = vm.localRate || 1;
      if (from == vm.currency) {
        return value;
      } else if (from == 'usd') {
        return value * rate;
      } else {
        return value / rate;
      }
    }

    function syncVisibilityParams() {
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }
  }
})();
