import type ng from 'angular';

import template from './bank-balance-report-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const BankBalanceReportContainer = {
  bindings: {
    filterLevel: '<',
    indicatorView: '<',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'BankBalanceReportService',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      BankBalanceReportService: any;
      GtUtils: GtUtilsService;
      chartsConfigs: any;
      config: any;
      data: any;
      filterLevel = 'cashflow-report-page-view';
      gettext: ng.gettext.gettextFunction;
      groupChoices: any;
      gtFilterService: GtFilterService;
      hideFilters: any;
      indicatorView: any;
      indicators: any;
      queryParams: QueryParams = {};
      selectedGroups: any;
      unselectedGroups: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        BankBalanceReportService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.BankBalanceReportService = BankBalanceReportService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.data = [];
        this.indicators = [];
        this.queryParams = {};
        this.hideFilters = true;
        this.selectedGroups = [];
        this.unselectedGroups = [];
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'cashflow-report-page-view';
        this.groupChoices = [
          { param: 'currency', title: this.gettext('currency'), icon: 'fa-money' },
          { param: 'account', title: this.gettext('bank account'), icon: 'fa-bank' },
          { param: 'owner', title: this.gettext('owner'), icon: 'fa-building' },
        ];

        this.queryParams = this.gtFilterService.getQueryParams(this.filterLevel);
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          [this.selectedGroups, this.unselectedGroups] = this.BankBalanceReportService.parseGroups(
            this.groupChoices,
            this.queryParams.rests_groupping,
          );
          this.updateBankBalanceReportData();
        });
        this.$scope.$watch(
          () => this.indicatorView,
          () => this.changeView(),
        );
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }
      selectGroup(group: any) {
        this.selectedGroups.push(group);
        this.unselectedGroups.splice(this.unselectedGroups.indexOf(group), 1);
      }
      unselectGroup(group: any) {
        this.unselectedGroups.push(group);
        this.selectedGroups.splice(this.selectedGroups.indexOf(group), 1);
      }
      updateBankBalanceReportData() {
        this.GtUtils.overlay('show');
        this.BankBalanceReportService.getReportData({
          ...this.queryParams,
          group_by: this.queryParams.rests_groupping,
        }).then((data: any) => {
          this.GtUtils.overlay('hide');
          this.data = data.results;
          this.chartsConfigs = data.chartsConfigs;
          this.changeView();
        });
      }

      applyFilter() {
        this.queryParams.rests_groupping = this.selectedGroups.map((v: any) => v.param).join(',');
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      changeView() {
        this.config = this.BankBalanceReportService.getTheadConfig(
          this.selectedGroups.map((v: any) => v.title.toUpperCase()).join(' | '),
          this.indicatorView,
        );
      }
    },
  ],
};
