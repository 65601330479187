import React from 'react';
import { Trans } from 'react-i18next';

import { useAuth } from '~/core/auth/hooks';
import { useObservableState } from '~/shared/lib/state';

import type { BiDashboardsStore } from '../../services/bi-dashboards.store';
import { DashboardEmbedded } from '../dashboard-embeded';
import { DashboardSelect } from '../dashboard-select';

export const DashboardContainer: React.FC<{
  store: BiDashboardsStore;
  title?: boolean;
  selector?: boolean;
}> = ({ store, title = true, selector = true }) => {
  const { currentUser } = useAuth();

  const usersDashboards = useObservableState(store.usersDashboards$, []);
  const selectedDashboardUUID = useObservableState(store.selectedDashboardUUID$);
  const selectedDashboard = useObservableState(store.selectedDashboard$);

  return (
    <>
      {selectedDashboard && (
        <div className="flex items-center justify-between">
          {title && (
            <h4 className="text-text-main-primary text-sm">
              <Trans>Dashboards</Trans>
            </h4>
          )}
          {selector && (
            <DashboardSelect
              dashboard={selectedDashboard}
              dashboardOptions={usersDashboards}
              onSelect={(dashboard) => store.dashboardSelected(dashboard)}
            />
          )}
        </div>
      )}
      {selectedDashboardUUID && (
        <DashboardEmbedded
          dashboardUUID={selectedDashboardUUID}
          biDomain={currentUser.settings.BI_API_URL}
          fetchGuestToken={() => store.fetchGuestToken(selectedDashboardUUID)}
        />
      )}
    </>
  );
};
