import type ng from 'angular';

export class StatisticsReportService {
  static readonly $inject = ['$http'];
  constructor(private readonly $http: ng.IHttpService) {}

  getData(params: object) {
    return this.$http<any>({
      method: 'GET',
      url: '/api/reports/statistics-report/',
      params: params,
    }).then((data) => {
      data.data.results.forEach((group: any) => {
        group.saleContractType = {};
        group.purchaseContractType = {};
        group.contract_type_list.forEach((contractType: any) => {
          if (contractType.contract_type == 'sale') {
            group.saleContractType = contractType;
          }
          if (contractType.contract_type == 'purchase') {
            group.purchaseContractType = contractType;
          }
        });
      });
      return data.data;
    });
  }

  getTreeData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/statistics-report/',
      params: { ...params, view: 'tree' },
    }).then((data: any) => ({
      data: this.unpackTree(data.data.results),
      saleIndicators: data.data.extra_list.sale_indicators,
      purchaseIndicators: data.data.extra_list.purchase_indicators,
      indicators: data.data.extra_list.indicators,
    }));
  }

  unpackTree = (data: any, parent = {}): any[] => {
    const result: any = [];
    for (const branch of data) {
      branch.parent = parent;
      result.push(branch);
      if (branch.data?.length) {
        result.push(...this.unpackTree(branch.data, branch));
        delete branch.data;
      } else {
        branch.level = 'details';
        branch.group_data = branch.contract_number;
      }
    }
    return result;
  };
}
