import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import type { PaymentPlanFinancesService } from '^/app/finances/shared/payment-plan-finances/payment-plan-finances.service';
import { html } from '^/shared/utils';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('PaymentPlanModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    '$scope',
    'FinancesService',
    'PaymentPlanFinancesService',
    'GtUtils',
    'gettext',
    'paymentPlan',
    'AccountsService',
    'FormFieldParamsService',
    'CoreService',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $scope: ng.IScope,
    FinancesService: FinancesService,
    PaymentPlanFinancesService: PaymentPlanFinancesService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    paymentPlan: any,
    AccountsService: AccountsService,
    FormFieldParamsService: FormFieldParamsService,
    CoreService: CoreService,
  ) {
    const vm = this;

    vm.paymentPlan = paymentPlan || {};
    vm.save = save;
    vm.close = close;
    vm.destroy = destroy;
    vm.updateData = updateData;
    vm.openFieldsConfigModal = openFieldsConfigModal;
    vm.isInvalid = isInvalid;
    vm.clearApprovalConfig = clearApprovalConfig;
    vm.financesIsValid = financesIsValid;
    vm.form = undefined;
    vm.stayAfterSave = false;

    vm.fields = [];

    activate();

    ////////////////

    function activate() {
      $scope.$watch('vm.paymentPlan.business_unit', (newVal: any, oldVal: any) => {
        if (newVal !== oldVal) {
          clearApprovalConfig();
        }
      });

      if (vm.paymentPlan.id) {
        updateData();
      }
      updateFields();
    }

    function updateData() {
      const extraFinances = vm.paymentPlan.finances;
      return FinancesService.PaymentPlan.get({ id: vm.paymentPlan.id }, function (data: any) {
        vm.paymentPlan = data;
        vm.paymentPlan.finances = extraFinances;
      }).$promise;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function destroy() {
      const msg = GtUtils.translate(
        gettext('Are you sure that you want delete this payment plan?'),
      );
      if (!confirm(msg)) {
        return;
      }
      return FinancesService.PaymentPlan.delete({ id: vm.paymentPlan.id }, function () {
        notify(GtUtils.translate(gettext('Payment plan removed')));
        close(null, true);
      }).$promise;
    }

    function save() {
      let saveFunction;
      vm.form.$invalid = true;
      if (vm.paymentPlan.id) {
        saveFunction = FinancesService.PaymentPlan.update;
      } else {
        saveFunction = FinancesService.PaymentPlan.save;
      }

      const financesToDelete = vm.paymentPlan.finances.filter(
        ({ value, id }: any) => value === 0 && id,
      );
      financesToDelete.forEach(({ id }: any) => {
        PaymentPlanFinancesService.deletePaymentPlanFinance({
          id,
        }).catch(GtUtils.errorClb);
      });

      vm.paymentPlan.finances = vm.paymentPlan.finances.filter(({ value }: any) => Boolean(value));
      updateFinanceAdditionalInfo();
      return saveFunction(
        vm.paymentPlan,
        function (data: any) {
          savePaymentPlanFinances(data.id).then(
            function () {
              if (!vm.stayAfterSave) {
                close(data, true);
                notify(GtUtils.translate(gettext('Payment plan saved')));
              } else {
                PaymentPlanFinancesService.getPaymentPlanFinances({ payment_plan: data.id }).then(
                  function (finances: any) {
                    vm.paymentPlan = data;
                    vm.paymentPlan.finances = finances.results;
                    notify(GtUtils.translate(gettext('Payment plan saved')));
                    vm.form.$invalid = false;
                  },
                );
              }
            },
            (e: any) => GtUtils.errorClb(e),
          );
        },
        (e: any) => GtUtils.errorClb(e),
      ).$promise;
    }

    function updateFinanceAdditionalInfo() {
      const financesForUpdate = vm.paymentPlan.finances.map((finance: any) => {
        return {
          id: finance.finance,
          additional_info: finance.additional_info,
        };
      });
      return FinancesService.Finance.bulkCreateOrUpdate(
        { partial: true },
        financesForUpdate,
        () => notify(GtUtils.translate(gettext('Additional info updated'))),
        GtUtils.errorClb,
      );
    }

    function savePaymentPlanFinances(paymentPlanId: number) {
      const paymentPlanFinances = vm.paymentPlan.finances
        .filter((finance: any) => finance.updated || !finance.id)
        .map((finance: any) => {
          delete finance.updated;
          return {
            id: finance.id,
            payment_plan: paymentPlanId,
            finance: finance.finance,
            value: finance.value,
          };
        });

      return PaymentPlanFinancesService.bulkCreateOrUpdate(paymentPlanFinances);
    }

    function updateFields() {
      FormFieldParamsService.getFields(getFields())
        .then((fields: any) => (vm.fields = fields))
        .catch(GtUtils.errorClb);
    }

    function openFieldsConfigModal() {
      FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(() => updateFields());
    }

    function getFormConfig() {
      return getFields();
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-md-12 col-sm-12',
        fieldGroup: [],
      };

      col1.fieldGroup.push(
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('INFO'),
          },
          fieldGroup: [
            {
              key: 'name',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Title'),
                placeholder: gettext('Type name'),
                type: 'text',
                required: true,
              },
            },
            {
              key: 'payment_status',
              type: 'gt-select',
              defaultValue: 'new',
              templateOptions: {
                label: gettext('Status'),
                placeholder: gettext('Choose status'),
                hint: gettext('Status depends on current progress on payment plan'),
                disabled: vm.paymentPlan.approval_config,
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: gettext('New'), value: 'new' },
                  { name: gettext('Approved'), value: 'approved' },
                  { name: gettext('Rejected'), value: 'rejected' },
                  { name: gettext('Executed'), value: 'executed' },
                ],
              },
            },
            {
              key: 'date',
              type: 'gt-date-select',
              templateOptions: {
                label: gettext('Date of payment (plan)'),
                placeholder: gettext('date'),
                hint: gettext('When you expect payment to be made'),
                type: 'date',
              },
            },
            {
              key: 'approval_config',
              type: 'gt-ui-select',
              defaultValueResolve: () =>
                AccountsService.getDefaultApprovalConfigId('finances.PaymentPlan'),
              templateOptions: {
                label: gettext('Approval Config'),
                resource: 'accounts.ApprovalConfig',
                queryParams: () => {
                  return {
                    object_type: 'finances.PaymentPlan',
                    is_active: 1,
                    bu_list: vm.paymentPlan.business_unit,
                  };
                },
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              defaultValue: Object.assign([], CoreService.getDefaultBuList()).shift(),
              templateOptions: {
                label: gettext('Business unit'),
                placeholder: gettext('Business Unit'),
                queryParams: GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
              },
            },
          ],
        },
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('PAYMENT ORDERS'),
          },
          fieldGroup: [
            {
              template: html`
                <payment-orders-list-container
                  payment-plan-id="model.id"
                ></payment-orders-list-container>
              `,
            },
          ],
        },
        {
          wrapper: 'gt-panel',
          templateOptions: {},
          fieldGroup: [
            {
              template: html`<payment-plan-finances
                finances="model.finances"
                payment-plan-id="model.id"
                planned-expenses="{[{vm.paymentPlan.plannedExpenses}]}"
                isFinancesValid="model.isFinancesValid"
              ></payment-plan-finances>`,
            },
          ],
        },
      );

      return {
        formName: 'payment-plan-edit-modal',
        fieldsDef: [col1],
      };
    }

    function financesIsValid() {
      return (
        vm.paymentPlan.finances &&
        !PaymentPlanFinancesService.calculatePaymentPlanFinancesTotals(
          vm.paymentPlan.finances,
        ).filter((row: any) => row.value < 0).length
      );
    }

    function isInvalid() {
      return vm.form.$invalid || !vm.financesIsValid();
    }

    function clearApprovalConfig() {
      vm.paymentPlan.approval_config = null;
    }
  }
})();
