import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './driver-details-item-container.html?raw';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const DriverDetailsItemContainer = {
  bindings: {
    filterLevel: '<',
    driver: '<',
  },
  template,
  controller: [
    '$scope',
    '$state',
    'GtUtils',
    'gtFilterService',
    'FormFieldParamsService',
    'CoreService',
    'DriversService',
    class {
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      CoreService: CoreService;
      DriversService: any;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      activeTabs: any;
      driver: any;
      fields: any;
      filterLevel = '';
      gtFilterService: GtFilterService;
      tab = 'info';
      updating: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        FormFieldParamsService: FormFieldParamsService,
        CoreService: CoreService,
        DriversService: any,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.CoreService = CoreService;
        this.DriversService = DriversService;
        this.fields = [];
        this.activeTabs = [];
      }

      $onInit() {
        this.tab = (this.gtFilterService.getQueryParams(this.filterLevel).tab as string) || 'info';
        this.updateFields();
        this.updateData();
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_, data) => {
          if (data.tab) {
            this.tab = data.tab;
          }
        });
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.DriversService.getDriver(this.driver.id)
          .then((data: any) => {
            this.driver = data;
            this.GtUtils.overlay('hide');
            this.updating = false;
          })
          .catch(() => {
            notify(this.GtUtils.translate("Driver doesn't exist"));
            this.$state.go('drivers.list');
          })
          .finally(() => {
            this.GtUtils.overlay('hide');
          });
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => {
            this.fields = fields;
          })
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        const formName = 'driver-details';
        return this.DriversService.getFields(formName, this.driver);
      }

      changeTab(tab: string) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);
      }

      editDriver() {
        this.DriversService.openDriverModal(this.driver).then((result: any) => {
          this.driver = { id: result.id };
          this.updateData();
        });
      }
    },
  ],
};
