import type ng from 'angular';
import { v4 as uuidv4 } from 'uuid';

import { formatDate, parseDate, startOfDay } from '~/shared/lib/date';

export const GtDateSelect = {
  bindings: {
    dateModel: '=?',
    disabled: '<?',
    allowClear: '<?',
    startView: '<?',
    minView: '<?',
    placeholder: '<?',
    onSelected: '&?',
    asString: '<?',
    useWatch: '<?',
    required: '<?',
    onOpenClose: '&?',
    minDate: '<?',
    maxDate: '<?',
  },
  template: require('./gt-date-select.html?raw'),
  controller: [
    '$scope',
    '$timeout',
    class {
      $scope: ng.IScope;
      $timeout: ng.ITimeoutService;
      allowClear: any;
      altInputFormats: any;
      asString: any;
      config: any;
      date: any;
      dateFormat: any;
      dateModel: any;
      disabled: any;
      dropdownIsOpen: any;
      inputChangedManually: any;
      minView: any;
      onOpenClose: any;
      onSelected: any;
      required: any;
      startView: any;
      touched: any;
      uniqueId: string;
      useWatch: any;
      constructor($scope: ng.IScope, $timeout: ng.ITimeoutService) {
        this.$scope = $scope;
        this.$timeout = $timeout;

        this.touched = false;
        this.date = undefined;

        this.uniqueId = uuidv4();
        this.config = {};
        this.inputChangedManually = false;
        this.dateFormat = 'dd.MM.yyyy';
        this.altInputFormats = [
          'M!/d!/yyyy',
          'yyyy-M!-d!',
          'd!-M!-yyyy',
          'd!-M!-yy',
          'd!.M!.yyyy',
          'd!.M!.yy',
          'd!/M!/yyyy',
          'd!/M!/yy',
        ];
        this.allowClear = false;
        this.required = false;
        this.dropdownIsOpen = false;
      }

      $onInit() {
        this.allowClear = this.allowClear ?? this.allowClear;
        this.required = this.required || false;
        this.config = {
          startView: this.startView || 'day',
          minView: this.minView || 'day',
          dropdownSelector: `#dropdown-${this.uniqueId}`,
        };
        if (this.minView == 'month') {
          this.dateFormat = 'MM.yyyy';
        } else if (this.minView == 'minute') {
          this.dateFormat = 'dd.MM.yyyy, HH:mm';
        } else if (this.minView == 'year') {
          this.dateFormat = 'yyyy';
        }

        if (this.startView == 'hour') {
          this.dateFormat = 'h:mm';
        }
        this.updateDate();
        if (this.useWatch) {
          this.$scope.$watch(
            () => this.dateModel,
            (newVal?: Date | string, oldVal?: Date | string) => {
              if (!newVal || !oldVal) {
                this.updateDate();
              }
              if (newVal && oldVal) {
                const newDate = new Date(newVal);
                const oldDate = new Date(oldVal);

                if (newDate.getTime() !== oldDate.getTime()) {
                  this.updateDate();
                }
              }
            },
          );
        }
      }

      $onChanges(changes: any) {
        if (!this.useWatch && changes.dateModel) {
          this.updateDate();
        }
      }

      updateDate() {
        if (this.asString && this.dateModel) {
          this.date = parseDate(this.dateModel, 'dd.MM.yyyy');
        } else {
          this.date = this.dateModel ? startOfDay(this.dateModel) : null;
        }
        if (this.useWatch) {
          this.onSelected?.({ $event: { date: this.dateModel } });
        }
      }

      onClick() {
        if (!this.disabled) {
          this.touched = true;
        }
      }

      onInputChange() {
        this.inputChangedManually = true;
      }

      isOpenHandler(open: any) {
        return typeof this.onOpenClose == 'function' && this.onOpenClose({ isOpen: open });
      }

      onInputBlur() {
        if (this.inputChangedManually && this.date) {
          this.dateSelected();
        }
      }

      clear() {
        this.date = null;
        this.dateSelected();
      }

      dateSelected() {
        if (this.asString && this.date) {
          this.dateModel = formatDate(this.date, 'dd.MM.yyyy');
        } else {
          this.dateModel = this.date;
        }
        this.$timeout(() => {
          this.onSelected?.({ $event: { date: this.dateModel } });
          this.dropdownIsOpen = this.minView === 'minute';
        }, 0);
        this.inputChangedManually = false;
      }
    },
  ],
};
