import type { ToastContent, ToastOptions, TypeOptions } from 'react-toastify';
import { toast } from 'react-toastify';

export const notify = (msg: ToastContent<unknown>, type: TypeOptions = 'success') => {
  toast(msg, { type });
};

export const notifyError = (msg: string, options?: ToastOptions) => {
  toast.error(msg, options);
};

export const notifySuccess = (msg: string, options?: ToastOptions) => {
  toast.success(msg, options);
};

export const notifyWarn = (msg: string, options?: ToastOptions) => {
  toast.warn(msg, options);
};

export const notifyInfo = (msg: string, options?: ToastOptions) => {
  toast.info(msg, options);
};
