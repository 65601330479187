import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './table-bulk-edit-container.html?raw';

import type { CostsBulkEditFieldsService } from '^/app/common/table-bulk-edit/costs-bulk-edit-field.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const TableBulkEditContainer = {
  bindings: {
    selectedData: '<',
    onFinish: '&',
    mode: '<',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'CostsBulkEditFieldsService',
    'InvoicePositionBulkEditFieldsService',
    'InvoicePositionService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      gettext: ng.gettext.gettextFunction;
      FormFieldParamsService: FormFieldParamsService;
      CostsBulkEditFieldsService: CostsBulkEditFieldsService;
      InvoicePositionBulkEditFieldsService: any;
      InvoicePositionService: any;
      selectedData: any;
      onFinish: any;
      form: any;
      fields: any;
      formInstance: any;
      mode: string;
      blockConfirmButton: boolean;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        CostsBulkEditFieldsService: CostsBulkEditFieldsService,
        InvoicePositionBulkEditFieldsService: any,
        InvoicePositionService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.CostsBulkEditFieldsService = CostsBulkEditFieldsService;
        this.InvoicePositionBulkEditFieldsService = InvoicePositionBulkEditFieldsService;
        this.InvoicePositionService = InvoicePositionService;

        this.selectedData = [];

        this.form = undefined;
        this.fields = undefined;
        this.formInstance = {};

        this.mode = 'invoicePosition';
        this.blockConfirmButton = false;
      }

      $onInit() {
        this.updateFormFields();
      }

      getFormConfigService() {
        return {
          invoicePosition: this.InvoicePositionBulkEditFieldsService,
          costs: this.CostsBulkEditFieldsService,
        }[this.mode];
      }

      getCrudService() {
        return {
          invoicePosition: this.InvoicePositionService,
          costs: this.CostsBulkEditFieldsService,
        }[this.mode];
      }

      updateFormFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => {
            this.fields = fields;
          })
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        return this.getFormConfigService().getFields(this.formInstance);
      }

      _error(data: any) {
        this.GtUtils.errorClb(data);
        this.GtUtils.overlay('hide');
      }

      close() {
        return this.onFinish();
      }

      confirm() {
        this.GtUtils.overlay('show');
        this.blockConfirmButton = true;
        return this.getCrudService()
          .bulkCreateOrUpdate(
            { partial: true },
            this.getCrudService().getUpdatedObjects(this.selectedData, this.formInstance),
          )
          .then(() => {
            this.blockConfirmButton = false;
            notify(this.gettext('Updated.'));
            this.GtUtils.overlay('hide');
            this.close();
          })

          .catch((data: any) => {
            this.blockConfirmButton = false;
            this._error(data);
          });
      }
    },
  ],
};
