import type ng from 'angular';

import template from './warehouse-shipment-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const WarehouseShipmentPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gtFilterService',
    'PageService',
    'GtUtils',
    'LogisticsService',
    'gettext',
    class WarehouseLossesPageView {
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      PageService: PageService;
      filterLevel = 'warehouse-shipment-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        gtFilterService: GtFilterService,
        PageService: PageService,
        GtUtils: GtUtilsService,
        LogisticsService: LogisticsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.GtUtils = GtUtils;
        this.LogisticsService = LogisticsService;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
          ],
          pages: this.LogisticsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            dates: true,
            dateSelects: [
              {
                argument: 'consignment__date',
                placeholder: this.gettext('Consignment date'),
              },
              {
                argument: 'logistic__loading_date',
                placeholder: this.gettext('Logistic loading; date'),
              },
            ],
            nestedSelects: [
              {
                argument: 'storage_days',
                placeholder: this.gettext('Days'),
                items: [
                  { id: 'lt', title: '< 0' },
                  { id: 'zero', title: '0' },
                  { id: 'gt', title: '> 0' },
                ],
              },
              {
                argument: 'logistic_crop_year',
                placeholder: this.gettext('Crop year'),
                items: this.GtUtils.getYearList(),
              },
              {
                argument: 'purchase_contract_status_list',
                placeholder: this.gettext('Purchase contract status'),
                items: [
                  { id: 'washout', title: this.gettext('Washout') },
                  { id: 'new', title: this.gettext('New') },
                  { id: 'delivery_period', title: this.gettext('Delivery period') },
                  { id: 'shipment', title: this.gettext('Shipment') },
                  { id: 'cargo_executed', title: this.gettext('Cargo executed') },
                  { id: 'executed', title: this.gettext('Executed') },
                  { id: 'approved', title: this.gettext('Approved') },
                  { id: 'cancelled', title: this.gettext('Cancelled') },
                ],
              },
              {
                argument: 'sale_contract_status_list',
                placeholder: this.gettext('Sale contract status'),
                items: [
                  { id: 'washout', title: this.gettext('Washout') },
                  { id: 'new', title: this.gettext('New') },
                  { id: 'delivery_period', title: this.gettext('Delivery period') },
                  { id: 'shipment', title: this.gettext('Shipment') },
                  { id: 'cargo_executed', title: this.gettext('Cargo executed') },
                  { id: 'executed', title: this.gettext('Executed') },
                  { id: 'approved', title: this.gettext('Approved') },
                  { id: 'cancelled', title: this.gettext('Cancelled') },
                ],
              },
            ],
            multiSelects: [
              {
                argument: 'consignment_list',
                placeholder: this.gettext('consignments'),
                resource: 'contracts.consignment',
              },
              {
                argument: 'consignment_elevator_list',
                placeholder: this.gettext('consignment elevators'),
                resource: 'clients.elevator',
              },
              {
                argument: 'consignment_contract_list',
                placeholder: this.gettext('consignment contracts'),
                resource: 'contracts.contractbase',
                queryParams: { stage: 'contract' },
              },
              {
                argument: 'consignment_contract_commodity_list',
                placeholder: this.gettext('Consignment contract commodities'),
                resource: 'crops.crop',
              },
              {
                argument: 'logistic_purchase_contract_list',
                placeholder: this.gettext('Logistic purchase contracts'),
                resource: 'contracts.PurchaseContract',
              },
              {
                argument: 'logistic_sale_contract_list',
                placeholder: this.gettext('Logistic sale contracts'),
                resource: 'contracts.salecontract',
              },
              {
                argument: 'logistic_sale_export_contract_list',
                placeholder: this.gettext('Logistic sale export contracts'),
                resource: 'contracts.salecontract',
              },
            ],
          },
        };
      }
    },
  ],
};
