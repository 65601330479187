import type ng from 'angular';

import template from './reservation-details-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const ReservationDetailsContainer = {
  bindings: {
    item: '<',
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    '$stateParams',
    'GtUtils',
    'gettext',
    'ReservationsService',
    'ReservationFormFieldsService',
    class {
      $scope: ng.IScope;
      $stateParams: ng.ui.IStateParamsService;
      GtUtils: GtUtilsService;
      ReservationFormFieldsService: any;
      ReservationsService: any;
      activeTabs: any;
      fields: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      item: any;
      queryParams?: QueryParams = {};
      tab = [''];
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        $stateParams: ng.ui.IStateParamsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        ReservationsService: any,
        ReservationFormFieldsService: any,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.$stateParams = $stateParams;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.ReservationsService = ReservationsService;
        this.ReservationFormFieldsService = ReservationFormFieldsService;

        this.item = {};
        this.filterLevel = '';
        this.activeTabs = [];
        this.queryParams = {};
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'reservation-details-container';
        this.queryParams = this.queryParams ?? { tab: ['movements'] };
        this.tab = (this.queryParams.tab as string[] | undefined) ?? ['movements'];
        if (this.$stateParams.tab) {
          this.gtFilterService.updateQueryParams({ tab: this.$stateParams.tab }, this.filterLevel);
        }
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
          if (data.tab) {
            this.tab = data.tab;
          }
        });
        this.updateData();
      }
      updateData() {
        this.GtUtils.overlay('show');
        return this.ReservationsService.get(this.item.id)
          .then((data: any) => {
            this.item = data;
            return this.updateFields();
          })
          .catch(() => {
            this.GtUtils.errorClb(this.gettext("'Stock Reserve Unit' doesn`t exist"));
            return this.GtUtils.goPage('logistics.stockReserveUnits');
          })
          .finally(() => this.GtUtils.overlay('hide'));
      }
      updateFields() {
        return this.ReservationFormFieldsService.getFields(
          this.item,
          this.filterLevel + '-details',
        ).then((fields: any) => {
          this.fields = fields;
        });
      }
      openModal() {
        return this.ReservationsService.openModal({ ...this.item }).then(() => this.updateData());
      }
      changeTab(tab: string) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);
      }
    },
  ],
};
