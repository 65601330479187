import ng from 'angular';

import type { GtRootScopeService } from '../../types';

type ButtonsConfig = {
  table?: {
    expanded?: boolean | null;
  } | null;
  sidebar?: {
    clicked?: boolean | null;
  } | null;
  filter?: { clicked: boolean } | null;
  print?: object | null;
  import?: { link: string } | null;
  export?: { link: string } | null;
  update?: { method: () => void } | null;
};

function ServiceFunction($rootScope: GtRootScopeService) {
  const _config: Record<string, ButtonsConfig> = {
    default: {
      table: {
        expanded: false,
      },
      sidebar: {
        clicked: false,
      },
    },
  };
  let startIntro: () => void;

  return {
    getConfig: getConfig,
    setConfig: setConfig,
    updateConfig: updateConfig,
    getTableConfig: getTableConfig,
    setStartIntroFunc: setStartIntroFunc,
    getStartIntroFunc: getStartIntroFunc,
  };

  ///////////////

  function getConfig(level = 'default') {
    return _config[level] ?? {};
  }

  function setConfig(config: ButtonsConfig, level = 'default') {
    _config[level] = config;
    $rootScope.$broadcast('hb-config-updated', getConfig(level), level);
  }

  function updateConfig(config: ButtonsConfig, level = 'default') {
    _config[level] = Object.entries({ ...(_config[level] ?? {}), ...config })
      .filter(([_, value]) => value != null)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    $rootScope.$broadcast('hb-config-updated', _config[level], level);
  }

  function getTableConfig(level = 'default') {
    if (!_config[level].table?.expanded) {
      return { expanded: true };
    }
    return _config[level].table;
  }

  function setStartIntroFunc(func: () => void) {
    startIntro = func;
  }

  function getStartIntroFunc() {
    return startIntro;
  }
}

(function () {
  'use strict';

  ng.module('core.legacy').factory('HelpButtonsService', ServiceFunction);

  ServiceFunction.$inject = ['$rootScope'];
})();

export type HelpButtonsService = ReturnType<typeof ServiceFunction>;
