import ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export class ClientFormFieldsService {
  $rootScope: GtRootScopeService;
  $window: ng.IWindowService;
  AccountsService: AccountsService;
  ClientsService: any;
  CoreService: CoreService;
  CustomValuesService: CustomValuesService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $rootScope: GtRootScopeService,
    $window: ng.IWindowService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    ClientsService: any,
    AccountsService: AccountsService,
    CoreService: CoreService,
    CustomValuesService: CustomValuesService,
  ) {
    this.$rootScope = $rootScope;
    this.$window = $window;
    this.gettext = gettext;
    this.GtUtils = GtUtils;
    this.ClientsService = ClientsService;
    this.AccountsService = AccountsService;
    this.CoreService = CoreService;
    this.CustomValuesService = CustomValuesService;
  }

  hasRoles(client: any, roles: any) {
    return client?.role_names?.some((i: any) => roles.includes(i));
  }

  getDaysOptions(defaultTitle = '0 days') {
    return [{ title: this.gettext(defaultTitle), id: 0 }].concat(
      [1, 2, 3, 5, 7, 10, 14].map((day) => ({
        title: `${day} ${day > 1 ? this.gettext('days') : this.gettext('day')}`,
        id: day,
      })),
    );
  }

  getFields(client: any, formName: string) {
    const col1: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col2: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col3: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col4: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };

    col1.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('MAIN'),
      },
      fieldGroup: [
        {
          key: 'name',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Title'),
            placeholder: this.gettext('Company name'),
            hint: this.gettext(
              'Pick the short name for counterparty. For convenience try to avoid naming the form of ownership, e.g. LLC ' +
                ' etc. You can appoint it in the full name. Good example for short name is COMPANY NAME without commas, etc.' +
                ' It would helpful you to find this company within search',
            ),
            required: true,
          },
        },
        {
          template: require('./legacy/templates/gt-similar-clients.tpl.html?raw'),
          controller: [
            '$scope',
            '$rootScope',
            'GtUtils',
            function (scope: any, $rootScope: GtRootScopeService, GtUtils: GtUtilsService) {
              const lookupField = $rootScope.user.settings.COUNTERPARTY_LOOKUP_FIELD;
              scope.$watch(
                'model.' + lookupField,
                GtUtils.debounce(() => {
                  if (!scope.model[lookupField]) {
                    return;
                  }
                  if (scope.model.id && !scope._nameChecked) {
                    scope._nameChecked = true;
                    return;
                  }
                  const params: {
                    search?: string;
                    [key: string]: any;
                  } = {};
                  if (lookupField == 'name') {
                    params.search = scope.model[lookupField];
                  } else {
                    params[lookupField] = scope.model[lookupField];
                  }
                  GtUtils.getPredictions(['clients.Client'], params)
                    .then((data: any) => {
                      scope.similarClients = data['clients.Client'];
                    })
                    .catch(errorHandler);
                }, 1000),
              );
            },
          ],
          hideExpression: (viewValue: any, modelValue: any, scope: any) => !scope.model.name,
        },
        {
          key: 'company_group',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Company Group'),
            placeholder: this.gettext('Link with group'),
            resource: 'clients.CompanyGroup',
            hint: this.gettext('If current company is a part of a bigger group'),
            addFunc: () => this.$window.open('/admin/clients/companygroup/add/'),
            addPerms: ['add_companygroup'],
            addIcon: 'fa fa-suitcase',
          },
        },
        {
          key: 'company_code',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Code'),
            placeholder: this.gettext('0000000'),
            hint: this.gettext('The personal code of this company in state instances'),
          },
        },
        {
          key: 'client_data_provider',
          template: require('./legacy/templates/client-data-provider.tpl.html?raw'),
          templateOptions: {
            label: this.gettext('Get counterparty data'),
          },
          controller: [
            '$scope',
            'GtUtils',
            'ClientsService',
            function (scope: any, GtUtils: GtUtilsService, ClientsService: any) {
              scope.getCounterpartyData = function () {
                ClientsService.getCounterpartyData({
                  company_code: client.company_code,
                }).then(
                  function (data: any) {
                    client = Object.assign(client, data);
                    if (client.company_code.length === 10) {
                      client.itn = client.company_code;
                    }
                  },
                  function (data: any) {
                    GtUtils.errorClb(data);
                  },
                );
              };
            },
          ],
          hideExpression: () =>
            client.status == 'Approved' ||
            client.status == 'Approved_condition' ||
            !client.company_code,
        },
        {
          key: 'counterparty_type',
          type: 'gt-select',
          defaultValue: 'legal_entity',
          templateOptions: {
            label: this.gettext('Counterparty type'),
            placeholder: this.gettext('Choose type'),
            hint: this.gettext('Pick one of the options to define the type of the company'),
            valueProp: 'value',
            labelProp: 'title',
            options: [
              { title: this.gettext('legal entity'), value: 'legal_entity' },
              { title: this.gettext('physical person'), value: 'physical_person' },
              {
                title: this.gettext('individual entrepreneur'),
                value: 'individual_entrepreneur',
              },
            ],
          },
        },
        {
          key: 'economic_activities_code',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Economic activities code'),
          },
        },
        {
          key: 'client_template',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext("Client's Template"),
            hint: this.gettext('If you want fill by template'),
            resource: 'clients.clienttemplate',
          },
        },
        {
          key: 'fillByTemplate',
          template: html`
            <div class="clearfix"></div>
            <div class="container">
              <a
                class="btn btn_success centered"
                style="font-size: 0.7em"
                ng-click="fillByTemplate()"
              >
                <i class="fa fa-angle-double-up"></i> <translate>Fill by template</translate>
              </a>
            </div>
            <br />
          `,
          templateOptions: {
            label: this.gettext('fill by template'),
          },
          controller: [
            '$scope',
            'GtUtils',
            'ClientsService',
            function (scope: any, GtUtils: GtUtilsService, ClientsService: any) {
              scope.fillByTemplate = function () {
                ClientsService.getClientTemplate({
                  id: client.client_template,
                }).then(
                  function (data: any) {
                    client = Object.assign(client, data);
                    client.business_units = [];

                    if (data.set_all_business_units) {
                      GtUtils.getPredictions(['core.BusinessUnit'])
                        .then((data: any) => {
                          ng.forEach(data['core.BusinessUnit'], (item: any) => {
                            client.business_units.push(item.id);
                          });
                        })
                        .catch(errorHandler);
                    }
                  },
                  function (data: any) {
                    GtUtils.errorClb(data);
                  },
                );
              };
            },
          ],
          hideExpression: () => !client.client_template,
        },
        {
          key: 'itn',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('ITN'),
            placeholder: this.gettext('0000000'),
            hint: this.gettext('Individual tax number'),
          },
        },
        {
          key: 'role_names',
          type: 'gt-multiselect',
          templateOptions: {
            label: this.gettext('Roles'),
            required: true,
            wrapperclass: 'multiselect',
            options: [
              { id: 'owner', title: this.gettext('Owner') },
              { id: 'buyer', title: this.gettext('Buyer') },
              { id: 'supplier', title: this.gettext('Supplier') },
              { id: 'exporter', title: this.gettext('Exporter') },
              { id: 'broker', title: this.gettext('Broker') },
              { id: 'customs_broker', title: this.gettext('Customs Broker') },
              { id: 'deliverer', title: this.gettext('Deliverer') },
              { id: 'surveyor', title: this.gettext('Surveyor') },
              { id: 'insurer', title: this.gettext('Insurer') },
              { id: 'farm', title: this.gettext('Farm') },
              { id: 'elevator', title: this.gettext('Elevator') },
              { id: 'bank', title: this.gettext('Bank') },
              { id: 'other', title: this.gettext('Other') },
            ],
            hint: this.gettext('You can select multiple roles, pressing CTRL'),
            help: this.gettext(
              'Roles, in which this Counterparty will be presented in system.' +
                'You can select multiple roles, pressing CTRL (CMD)',
            ),
          },
        },
        {
          key: 'last_outgoing_invoice_number',
          type: 'gt-input',
          hideExpression:
            '!model.role_names.includes("supplier") && !model.role_names.includes("owner")',
          templateOptions: {
            type: 'number',
            label: this.gettext('Last Outgoing Invoice Number'),
            placeholder: this.gettext('Enter last invoice number'),
            hint: this.gettext('Used to generate next outgoing invoice number'),
          },
        },
        {
          key: 'is_own_counterparty',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Can be contract owner'),
          },
          hideExpression: () =>
            this.hasRoles(client, ['owner']) || !this.hasRoles(client, ['supplier']),
        },
        {
          key: 'clientroles',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Custom roles'),
            resource: 'clients.customclientrole',
            hint: this.gettext('If you want to assign some custom role to this company'),
            addFunc: () => this.$window.open('/admin/clients/customclientrole/add/'),
            addPerms: true,
            addIcon: 'fa fa-tasks',
          },
        },
        {
          key: 'type_of_activities',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Type of activities'),
            resource: 'core.TypeOfActivity',
            hint: this.gettext('If you want to add type or activity to this company'),
            addFunc: () => this.$window.open('/admin/core/typeofactivity/add/'),
            addPerms: true,
            addIcon: 'fa fa-tasks',
          },
        },
        {
          key: 'predictions_order',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Predictions order'),
            type: 'number',
          },
        },
        {
          template: html`
            <custom-values-container
              filter-level="'client-custom-values-container'"
              init-query-params="{
              object_id: model.id,
              purpose: model.content_type,
              purpose_model: 'client',
            }"
              mode="model.id ? 'edit' : 'create'"
              object-id="model.id"
            ></custom-values-container>
          `,
        },
      ],
    });

    col2.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('PROPERTIES'),
        defaultHide: true,
      },
      fieldGroup: [
        {
          key: 'abbreviation',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Abbreviation'),
            hint: this.gettext('The company name abbreviation'),
          },
        },
        {
          key: 'tax_info',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Tax info'),
            placeholder: this.gettext('Tax status'),
            hint: this.gettext('Information about company as a tax payer'),
          },
        },
        {
          key: 'blacklisted',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Black list'),
            help: this.gettext(
              'Check this if you want to display BLACKLIST label alongside company name',
            ),
          },
        },
        {
          key: 'custom_status',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Custom status'),
            placeholder: this.gettext('Choose custom status'),
            resource: 'core.CustomStatus',
            hint: this.gettext('custom status'),
            addFunc: () => {
              this.$window.open('/admin/core/customstatus/add/');
            },
            addIcon: 'fa fa-tasks',
            addPerms: true,
            queryParams: {
              content_type__model: 'client',
            },
          },
        },
        {
          key: 'regular_status',
          type: 'gt-select',
          defaultValue: 'potencial',
          templateOptions: {
            label: this.gettext('Status'),
            placeholder: this.gettext('Choose status'),
            hint: this.gettext('Pick one of the options to define the status of the company'),
            valueProp: 'value',
            labelProp: 'title',
            options: [
              { title: this.gettext('Potencial'), value: 'potencial' },
              { title: this.gettext('Active'), value: 'active' },
              { title: this.gettext('Inactive'), value: 'inactive' },
              { title: this.gettext('Closed'), value: 'closed' },
            ],
          },
        },
        {
          key: 'additional_info',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Additional Info'),
            placeholder: this.gettext('Specific information about this object'),
            tooltip: client.additional_info,
            className: 'additional-info',
          },
        },
        {
          key: 'has_sanctions',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Sanctions'),
            help: this.gettext('Check this if counterparty has sanctions'),
          },
        },
        {
          key: 'has_criminal',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Criminal'),
            help: this.gettext('Check this if counterparty has criminal cases'),
          },
        },
        {
          key: 'has_tax_debt',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Tax debt'),
            help: this.gettext('Check this if counterparty has tax debt'),
          },
        },
        {
          key: 'relations',
          type: 'gt-select',
          defaultValue: 'Neutral',
          templateOptions: {
            label: this.gettext('Relation'),
            placeholder: this.gettext('Choose relations'),
            hint: this.gettext(
              'Pick one of the options to define the status of relations with the company',
            ),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Hot'), value: 'Hot' },
              { name: this.gettext('Warm'), value: 'Warm' },
              { name: this.gettext('Neutral'), value: 'Neutral' },
              { name: this.gettext('Cold'), value: 'Cold' },
              { name: this.gettext('Angry'), value: 'Angry' },
            ],
          },
        },
        {
          key: 'size',
          type: 'gt-select',
          defaultValue: 'Medium',
          templateOptions: {
            label: this.gettext('Size'),
            placeholder: this.gettext('Choose size'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Big'), value: 'Big' },
              { name: this.gettext('Medium'), value: 'Medium' },
              { name: this.gettext('Small'), value: 'Small' },
            ],
          },
        },
        {
          key: 'is_resident',
          type: 'gt-checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.gettext('Is resident'),
          },
        },
        {
          key: 'is_distributor',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Is distributor'),
            hint: this.gettext('Check this if this supplier is distributor.'),
          },
          hideExpression: () =>
            !this.$rootScope.user.settings.SYSTEM_BLOCKS.block_execution_reassignment,
        },
        {
          key: 'is_agent',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Agent'),
            hint: this.gettext('Check this, if current company is an Agent and not a Producer'),
          },
        },
        {
          key: 'is_producer',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Producer'),
            hint: this.gettext('Check this, if the Supplier of Farm is an original producer'),
          },
        },
        {
          key: 'affiliated_suppliers',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Affiliated suppliers'),
            placeholder: this.gettext('Affiliated suppliers'),
            hint: this.gettext('a supplier to which this Exporter is affiliated'),
            addPerms: ['add_supplier'],
            resource: 'clients.Supplier',
            addFunc: () => {
              return this.ClientsService.roleModal({ model_name: 'Supplier' });
            },
            addIcon: this.GtUtils.getIcon('clients.Supplier'),
          },
          hideExpression: () => !this.hasRoles(client, ['exporter']),
        },
        {
          key: 'related_counterparties',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Related counterparties'),
            placeholder: this.gettext('Related counterparties'),
            resource: 'clients.Client',
          },
        },
        {
          key: 'business_units',
          type: 'gt-ui-multiselect',
          defaultValue: this.CoreService.getBuList(),
          templateOptions: {
            label: this.gettext('Business units'),
            placeholder: this.gettext('Business Units'),
            resource: 'core.BusinessUnit',
            hint: this.gettext(
              'If you pick something here, information about this object will be displayed only for people from those business units',
            ),
            addFunc: () => this.$window.open('/admin/core/businessunit/add/'),
            addIcon: 'fa fa-university',
            addPerms: true,
          },
        },
        {
          key: 'sap_vendor_number',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('SAP vendor number'),
          },
        },
        {
          key: 'author',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.id,
          templateOptions: {
            label: this.gettext('Author'),
            placeholder: this.gettext('User'),
            resource: 'auth.User',
          },
        },
        {
          key: 'responsible',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.id,
          templateOptions: {
            label: this.gettext('responsible'),
            placeholder: this.gettext('User'),
            resource: 'auth.User',
            disabled: !this.AccountsService.hasPerm('edit_client_responsible'),
          },
        },
        {
          key: 'outgoing_invoice_number_prefix',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('prefix for outgoing invoices'),
          },
          hideExpression: () => !this.hasRoles(client, ['owner']),
        },
      ],
    });
    col3.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('LOCATION'),
        defaultHide: true,
      },
      fieldGroup: [
        {
          key: 'current_address',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Full current address'),
            placeholder: this.gettext('Country, city, street, app'),
          },
        },
        {
          key: 'mailing_address',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Mailing address'),
            placeholder: this.gettext('Country, city, street, app'),
          },
        },
        {
          key: 'country',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Country'),
            resource: 'location.Country',
            placeholder: this.gettext('country'),
            addButtonUrl: '/admin/location/country/add/',
            addPerms: ['add_country'],
            addFunc: () => this.$window.open('/admin/location/country/add/'),
            addIcon: 'fa fa-flag',
          },
        },
        {
          key: 'regions',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Regions'),
            hint: this.gettext('regions, where this counterparty operate mostly'),
            addPerms: ['add_region'],
            addFunc: () => this.$window.open('/admin/location/region/add/', '_blank'),
            resource: 'location.region',
            addIcon: 'fa fa-flag',
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) => !scope.model.country,
        },
        {
          key: 'district',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('District'),
            resource: 'location.District',
            addPerms: ['add_district'],
            placeholder: this.gettext('a district'),
            queryParams: () => {
              return { regions: client.regions };
            },
            addFunc: () => this.$window.open('/admin/location/district/add/'),
            addIcon: 'fa fa-flag',
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) => !scope.model.regions,
        },
        {
          key: 'city',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('City'),
            resource: 'location.City',
            placeholder: this.gettext('city'),
            addButtonUrl: '/admin/location/city/add/',
            addPerms: ['add_city'],
            addFunc: () => this.$window.open('/admin/location/city/add/'),
            addIcon: 'fa fa-flag',
          },
        },
        {
          key: 'person',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Main contact'),
            placeholder: this.gettext('The main person of the company'),
            type: 'text',
            hint: this.gettext('Person - main contact of the company'),
          },
        },
        {
          key: 'phone',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Phone'),
            type: 'tel',
            placeholder: this.gettext('+0 0 (000)000-00-00'),
          },
        },
        {
          key: 'phone_additional',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Phone (additional)'),
            type: 'tel',
            placeholder: this.gettext('+0 0 (000)000-00-00'),
            hint: this.gettext('If company has more than one number'),
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) => !scope.model.phone,
        },
        {
          key: 'website',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Website'),
            placeholder: this.gettext('https://website.com'),
            type: 'text',
          },
        },
        {
          key: 'email',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('E-mail'),
            type: 'text',
            placeholder: this.gettext('email.mail.com'),
          },
        },
        {
          key: 'first_contact',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Contact first time'),
            placeholder: this.gettext('date'),
            hint: this.gettext('Pick here a date, when you got first contact with this company'),
          },
        },
        {
          key: 'future_contact',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Contact next time'),
            placeholder: this.gettext('date'),
            hint: this.gettext('Pick here a date, when you need to contact this company in future'),
          },
        },
        {
          key: 'full_name',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Full name'),
            placeholder: this.gettext('Full company name, LLC'),
            hint: this.gettext(
              'Here you can type full title of the company with type of ownership, etc. Example - Full company name, LLC',
            ),
          },
        },
        {
          key: 'full_name_eng',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Full name Eng'),
            placeholder: this.gettext('Full company name, LLC'),
            hint: this.gettext('This should be filled for multilingual agreements'),
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) => !scope.model.full_name,
        },
        {
          key: 'address',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Legal address'),
            placeholder: this.gettext('Country, city, street, app'),
          },
        },
        {
          key: 'address_eng',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Legal address Eng'),
            placeholder: this.gettext('Country, city, street, app'),
            hint: this.gettext('This should be filled for multilingual agreements'),
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) => !scope.model.address,
        },
        {
          key: 'person_signatory',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Signatory'),
            placeholder: this.gettext('Signatory, the son of Signator'),
            type: 'text',
            hint: this.gettext('Full name of person who signs documents by this company'),
          },
        },
        {
          key: 'person_signatory_eng',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Signatory Eng'),
            placeholder: this.gettext('Signatory, the son of Signator'),
            type: 'text',
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) =>
            !scope.model.person_signatory,
        },
        {
          key: 'person_signatory_docs',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Signatory Docs'),
            placeholder: this.gettext('Signatory, the son of Signator'),
            type: 'text',
            hint: this.gettext(
              'Full name of the Signatory to use in requisites while generating documents',
            ),
          },
        },
        {
          key: 'person_signatory_eng_docs',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Signatory Eng Docs'),
            placeholder: this.gettext('Signatory, the son of Signator'),
            type: 'text',
            hint: this.gettext('This should be filled for multilingual agreements'),
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) =>
            !scope.model.person_signatory_docs,
        },
        {
          key: 'signatory_position',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Signatory Position'),
            placeholder: this.gettext('Signatory Position'),
            type: 'text',
            hint: this.gettext('Positon of person who signs documents by this company'),
          },
        },
        {
          key: 'signatory_position_eng',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Signatory Eng Position'),
            placeholder: this.gettext('Signatory Eng Position'),
            type: 'text',
            hint: this.gettext('Positon of person who signs documents by this company'),
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) =>
            !scope.model.signatory_position,
        },
        {
          key: 'payment_conditions',
          type: 'gt-input',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.payment_conditions || 100,
          templateOptions: {
            label: this.gettext('Railway prepay - balance percent'),
            placeholder: this.gettext('0-100'),
            hint: this.gettext(
              'Default prepayment with this counterparty. You can specify another in each contract',
            ),
            addon: this.gettext('0-100%'),
            type: 'number',
            required: false,
          },
          validators: {
            notFalse: ($viewValue: any, $modelValue: any) => {
              const value = parseFloat($viewValue || $modelValue || 0);
              return value >= 0 && value <= 100;
            },
            notEmpty: ($viewValue: any, $modelValue: any, scope: any) => {
              if ($viewValue === '' || $viewValue === null || $viewValue === undefined) {
                scope.model.payment_conditions = 0;
              }
              return true;
            },
          },
          hideExpression: () => !this.hasRoles(client, ['buyer', 'supplier']),
        },
        {
          key: 'payment_conditions_auto',
          type: 'gt-input',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.payment_conditions_auto || 100,
          templateOptions: {
            label: this.gettext('Cars prepay - balance percent'),
            placeholder: this.gettext('0-100'),
            hint: this.gettext(
              'Default prepayment with this counterparty. You can specify another in each contract',
            ),
            addon: this.gettext('0-100%'),
            type: 'number',
            required: false,
          },
          validators: {
            notFalse: ($viewValue: any, $modelValue: any) => {
              const value = parseFloat($viewValue || $modelValue || 0);
              return value >= 0 && value <= 100;
            },
            notEmpty: ($viewValue: any, $modelValue: any, scope: any) => {
              if ($viewValue === '' || $viewValue === null || $viewValue === undefined) {
                scope.model.payment_conditions_auto = 0;
              }
              return true;
            },
          },
          hideExpression: () => !this.hasRoles(client, ['buyer', 'supplier']),
        },
        {
          key: 'payment_conditions_option',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.payment_conditions_descr,
          templateOptions: {
            label: this.gettext('Payment conditions option'),
            placeholder: this.gettext('Choose option'),
            hint: this.gettext(
              'Default prepayment with this counterparty. You can specify another in each contract',
            ),
            resource: 'finances.PaymentCondition',
            addFunc: () => this.$window.open('/admin/finances/paymentcondition/'),
            addIcon: 'fa fa-money',
            queryParams: () => {
              return { is_active: 1 };
            },
          },
          hideExpression: () => !this.hasRoles(client, ['buyer', 'supplier']),
        },
        {
          key: 'use_foreign_currency',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Trades in USD'),
            hint: this.gettext('Check this, if current company buy or sell in USD'),
          },
          hideExpression: () => !this.hasRoles(client, ['buyer', 'supplier']),
        },
        {
          key: 'use_local_currency',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Trades in local currency'),
            hint: this.gettext('Check this, if current company buy or sell only in local currency'),
          },
          hideExpression: () => !this.hasRoles(client, ['buyer', 'supplier']),
        },
        {
          key: 'basises',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Incoterms'),
            hint: this.gettext(
              'Choose one or more default incoterms on which this Supplier or Buyer is selling or buying',
            ),
            resource: 'logistics.Basis',
            addPerms: ['add_basis'],
            addFunc: () => this.$window.open('/admin/logistics/basis/add/'),
            addIcon: 'fa fa-train',
          },
          hideExpression: () => !this.hasRoles(client, ['buyer', 'supplier']),
        },
        {
          key: 'terminals',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Terminals'),
            placeholder: this.gettext('Terminals'),
            resource: 'logistics.Terminal',
            addFunc: () => this.$window.open('/admin/logistics/terminal/add/'),
            addIcon: 'fa fa-anchor',
            addPerms: ['add_terminal'],
          },
          hideExpression: () => !this.hasRoles(client, ['buyer']),
        },
        {
          key: 'info_for_traders',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Info for traders'),
          },
        },
      ],
    });
    col4.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('COMPLIANCE'),
        defaultHide: true,
      },
      fieldGroup: [
        {
          key: 'approval_config',
          type: 'gt-ui-select',
          defaultValueResolve: () => {
            return this.AccountsService.getDefaultApprovalConfigId('clients.Client').catch(() => {
              return null;
            });
          },
          templateOptions: {
            label: this.gettext('Approval Config'),
            hint: this.gettext(
              'Choose configuration to launch the approval process by this object. You need first to set up configuration an Admin section, and here just pick it up',
            ),
            resource: 'accounts.ApprovalConfig',
            addFunc: () => this.$window.open('/admin/accounts/approvalconfig/add'),
            addIcon: 'fa fa-cogs',
            addPerms: true,
            queryParams: () => {
              return {
                object_type: 'clients.Client',
                is_active: 1,
                bu_list: client.business_units,
              };
            },
          },
        },
        {
          key: 'status',
          type: 'gt-select',
          defaultValue: 'New',
          templateOptions: {
            label: this.gettext('Compliance status'),
            placeholder: this.gettext('Choose compliance status'),
            help: this.gettext(
              'Status will be changed automatically, based on a compliance status. You need to fill out request, received and confirmed date',
            ),
            options: [
              { title: this.gettext('New'), id: 'New' },
              { title: this.gettext('Approved'), id: 'Approved' },
              {
                title: this.gettext('Approved condition'),
                id: 'Approved_condition',
              },
              { title: this.gettext('Re-approval'), id: 'Re-approval' },
              { title: this.gettext('Out of approval'), id: 'Out_of_approval' },
              { title: this.gettext('Pending'), id: 'Pending' },
              { title: this.gettext('Rejected'), id: 'Rejected' },
            ],
            disabled: !this.AccountsService.hasPerm('edit_client_confirmation_counterparty'),
          },
        },
        {
          key: 'approval_status',
          type: 'gt-select',
          defaultValue: 'wait',
          templateOptions: {
            label: this.gettext('Approval status'),
            hint: this.gettext('Will be changed automatically based on configuration'),
            options: [
              { title: this.gettext('Waiting for approval'), id: 'wait' },
              { title: this.gettext('Approved'), id: 'approved' },
              { title: this.gettext('Approval in process'), id: 'process' },
              { title: this.gettext('Rejected'), id: 'rejected' },
            ],
            disabled: !this.AccountsService.hasPerm('edit_approval_status'),
          },
        },
        {
          key: 'responsible_for_confirmation',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.id,
          templateOptions: {
            label: this.gettext('responsible for compliance'),
            placeholder: this.gettext('User'),
            resource: 'auth.User',
          },
        },
        {
          key: 'confirmation_info',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Confirmation info'),
            placeholder: this.gettext('Additional info regarding confirmation of this company'),
            disabled: !this.AccountsService.hasPerm('edit_client_confirmation_counterparty'),
            tooltip: client.confirmation_info,
            help: this.gettext(
              'Fill it if only there are some differences from standard compliance process. This will cause APPROVED WITH CONDITION status',
            ),
          },
        },
        {
          key: 'date_request',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Request (date)'),
            hint: this.gettext('Date, when the documents were requested from the company'),
            placeholder: this.gettext('date'),
            type: 'date',
            disabled: !this.AccountsService.hasPerm('edit_client_confirmation_counterparty'),
            useWatch: true,
          },
        },
        {
          key: 'date_received',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Received (date)'),
            placeholder: this.gettext('date'),
            hint: this.gettext('Date, when the documents were received from the company'),
            type: 'date',
          },
          expressionProperties: {
            'templateOptions.disabled':
              '$root.user.perms.indexOf("edit_client_confirmation_counterparty") < 0 || !model.date_request',
          },
        },
        {
          key: 'date_first_confirmation',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('First confirmation (date)'),
            placeholder: this.gettext('date'),
            hint: this.gettext('Date, when the company was confirmed by us first time'),
            type: 'date',
          },
          expressionProperties: {
            'templateOptions.disabled':
              '$root.user.perms.indexOf("edit_client_date_first_confirmation") < 0 || !model.date_received',
          },
        },
        {
          key: 'date_confirmation',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Confirmation (date)'),
            placeholder: this.gettext('date'),
            hint: this.gettext('Date, when the company was officially confirmed by us'),
            type: 'date',
            help: this.gettext('This field should be filled to pass compliance'),
          },
          expressionProperties: {
            'templateOptions.disabled':
              '$root.user.perms.indexOf("edit_client_date_confirmation") < 0 || !model.date_first_confirmation',
          },
        },
        {
          key: 'kpi_confirmation_success',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Don`t use kpi check'),
            hint: this.gettext('Check this to avoid standard KPI evaluation process'),
          },
          hide: !this.AccountsService.hasPerm('edit_kpi_confirmation_success'),
        },
        {
          key: 'term_of_proxy',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Term of proxy'),
            placeholder: this.gettext('date'),
            hint: this.gettext('Expiration date of power of attorney'),
            type: 'date',
          },
        },
        {
          key: 'days_before_deadline_to_notify',
          type: 'gt-select',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Remind me before'),
            options: this.getDaysOptions('No notifications'),
            hint: this.gettext(
              'Number of days before notification about power of attorney expiration',
            ),
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.term_of_proxy',
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) =>
            !scope.model.term_of_proxy,
        },
        {
          key: 'client_data_is_checked',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Client data is checked'),
          },
        },
        {
          key: 'bank_data_is_checked',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Bank data is checked'),
          },
        },
      ],
    });

    return {
      formName: formName || 'client-edit-modal',
      fieldsDef: [col1, col2, col3, col4],
    };
  }

  getCustomFieldTableColumns() {
    return this.CustomValuesService.getCustomFieldTableColumns({ purpose_model: 'client' });
  }
}
ClientFormFieldsService.$inject = [
  '$rootScope',
  '$window',
  'gettext',
  'GtUtils',
  'ClientsService',
  'AccountsService',
  'CoreService',
  'CustomValuesService',
];
