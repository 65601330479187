import type ng from 'angular';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export class ReservationFormFieldsService {
  FormFieldParamsService: FormFieldParamsService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    FormFieldParamsService: FormFieldParamsService,
  ) {
    this.gettext = gettext;
    this.GtUtils = GtUtils;
    this.FormFieldParamsService = FormFieldParamsService;
  }
  getFormConfig(item: any, formName = 'reservation-modal') {
    const mainGroupFields = [
      {
        key: 'date',
        type: 'gt-date-select',
        defaultValue: new Date(),
        templateOptions: {
          label: this.gettext('Date'),
          placeholder: this.gettext('Date'),
          type: 'date',
          startView: 'day',
          minView: 'day',
          required: true,
        },
      },
      {
        key: 'title',
        type: 'gt-input',
        templateOptions: {
          label: this.gettext('Title'),
          type: 'text',
          required: true,
        },
      },
      {
        key: 'status',
        type: 'gt-select',
        defaultValue: 'new',
        templateOptions: {
          label: this.gettext('Status'),
          placeholder: this.gettext('Choose status'),
          options: [
            { title: this.gettext('New'), id: 'new' },
            { title: this.gettext('Processed'), id: 'processed' },
            { title: this.gettext('Cancelled'), id: 'cancelled' },
          ],
        },
      },
      {
        key: 'volume',
        type: 'gt-input',
        templateOptions: {
          label: this.gettext('Volume'),
          type: 'number',
          required: true,
        },
      },
      {
        key: 'balance',
        type: 'gt-input',
        templateOptions: {
          disabled: true,
          label: this.gettext('balance'),
          type: 'number',
          required: false,
        },
        validation: {
          show: true,
        },
      },
      {
        key: 'from_stock_reserve_unit',
        type: 'gt-ui-select',
        templateOptions: {
          label: this.gettext('From Stock Reserve Unit'),
          resource: 'logistics.stockReserveUnit',
        },
        expressionProperties: {
          'templateOptions.required': (viewValue: any, modelValue: any, scope: any) => {
            return !scope.model.to_stock_reserve_unit;
          },
        },
      },
      {
        key: 'to_stock_reserve_unit',
        type: 'gt-ui-select',
        templateOptions: {
          label: this.gettext('To Stock Reserve Unit'),
          resource: 'logistics.stockReserveUnit',
        },
        expressionProperties: {
          'templateOptions.required': (viewValue: any, modelValue: any, scope: any) => {
            return !scope.model.from_stock_reserve_unit;
          },
        },
      },
    ];
    const dimensionGroupFields = [
      {
        key: 'commodity',
        type: 'gt-ui-select',
        templateOptions: {
          label: this.gettext('Commodity'),
          placeholder: this.gettext('Choose commodity'),
          resource: 'crops.Crop',
          required: true,
        },
      },
      {
        key: 'warehouse',
        type: 'gt-ui-select',
        templateOptions: {
          label: this.gettext('Warehouse'),
          placeholder: this.gettext('Choose Warehouse'),
          resource: 'logistics.warehouse',
          required: true,
        },
      },
    ];
    const extraDimensionGroupFields = [
      {
        key: 'business_unit',
        type: 'gt-ui-select',
        templateOptions: {
          label: this.gettext('Business unit'),
          placeholder: this.gettext('Choose Business unit'),
          resource: 'core.BusinessUnit',
        },
      },
      {
        key: 'contract_position',
        type: 'gt-ui-select',
        templateOptions: {
          label: this.gettext('Contract position'),
          placeholder: this.gettext('Choose contract position'),
          resource: 'contracts.PurchaseContract',
        },
      },
      {
        key: 'producer',
        type: 'gt-ui-select',
        templateOptions: {
          label: this.gettext('Producer'),
          placeholder: this.gettext('Choose Producer'),
          resource: 'clients.Client',
        },
      },
      {
        key: 'receiver',
        type: 'gt-ui-select',
        templateOptions: {
          label: this.gettext('Receiver'),
          placeholder: this.gettext('Choose Receiver'),
          resource: 'clients.Client',
        },
      },
      {
        key: 'owner',
        type: 'gt-ui-select',
        hide: true,
        templateOptions: {
          label: this.gettext('Owner'),
          placeholder: this.gettext('Choose Owner'),
          resource: 'clients.Client',
        },
      },
      {
        key: 'batch',
        type: 'gt-ui-select',
        templateOptions: {
          label: this.gettext('Batch'),
          placeholder: this.gettext('Choose Batch'),
          resource: 'warehouses.recorderreference',
          queryParams: () => ({
            is_batch: true,
            commodity: item.commodity,
            contract_position: item.contract_position,
            warehouse: item.warehouse,
          }),
        },
      },
    ];
    const additionalGroupFields = [
      {
        key: 'description',
        type: 'gt-textarea',
        templateOptions: {
          label: this.gettext('Description'),
          placeholder: this.gettext('Specific information about this object'),
          className: 'additional-info',
        },
      },
    ];
    const fieldsDef = [
      {
        className: 'form-group-container col-sm-4 col-xs-12',
        fieldGroup: [
          {
            wrapper: 'gt-panel',
            templateOptions: { label: this.gettext('MAIN') },
            fieldGroup: mainGroupFields,
          },
        ],
      },
      {
        className: 'form-group-container col-sm-4 col-xs-12',
        fieldGroup: [
          {
            wrapper: 'gt-panel',
            templateOptions: { label: this.gettext('MAIN DIMENSIONS') },
            fieldGroup: dimensionGroupFields,
          },
          {
            wrapper: 'gt-panel',
            templateOptions: { label: this.gettext('EXTRA DIMENSIONS') },
            fieldGroup: extraDimensionGroupFields,
          },
        ],
      },
      {
        className: 'form-group-container col-sm-4 col-xs-12',
        fieldGroup: [
          {
            wrapper: 'gt-panel',
            templateOptions: { label: this.gettext('ADDITIONAL') },
            fieldGroup: additionalGroupFields,
          },
        ],
      },
    ];
    return {
      formName: formName,
      fieldsDef: fieldsDef,
    };
  }
  getFields(formName: string) {
    return this.FormFieldParamsService.getFields(this.getFormConfig(formName));
  }
  openFieldsConfigModal(formName: string) {
    return this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig(formName));
  }
  _prepareParams(item: any) {
    const customFilters: any = [
      'contract_position',
      'stock_reserve_unit',
      'producer',
      'receiver',
      'batch',
      'business_unit',
    ];
    return {
      commodity: item.commodity,
      warehouse: item.warehouse,
      start_date: item.date,
      end_date: item.date,
      groups: ['batch', 'stock_reserve_unit'],
      horizontalGroups: ['batch'],
      verticalGroups: ['stock_reserve_unit'],
      ...customFilters
        .filter((f: any) => item[f])
        .reduce((res: any, f: any) => {
          res[f] = item[f];
          return res;
        }, {}),
    };
  }
  openBalance(item: any) {
    return this.GtUtils.goPage('logistics.warehouseBalance', this._prepareParams(item));
  }
}
ReservationFormFieldsService.$inject = ['gettext', 'GtUtils', 'FormFieldParamsService'];
