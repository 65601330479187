import React from 'react';

import { useComponentModel } from '~/shared/lib/components';
import { notifyWarn } from '~/shared/lib/notify';
import { combineLatest } from '~/shared/lib/state';
import { addClass, isMessageError, rmClass } from '~/shared/lib/utils';

import { HeaderGroup } from './header-group';
import { useTable } from './hooks';
import { RowComponent } from './row-component';
import { parsePasteData } from '../../helpers/grid';
import type { DataParams, DataRecord, ViewProps } from '../../lib/types';
import { TableViewToolbar } from '../actions-bar/toolbar';
import type { DatasetModel } from '../dataset/dataset.model';

export const TableView = <R extends DataRecord, P extends DataParams>({
  editing$,
  selectable,
}: ViewProps<R, P>) => {
  const model = useComponentModel<DatasetModel<R, P>>();
  const table = useTable(selectable);

  React.useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      event.stopPropagation();
      event.preventDefault();
      const clipboardData = event.clipboardData?.getData('Text');
      let parsedData;
      try {
        parsedData = parsePasteData(clipboardData ?? '');
      } catch (error) {
        if (isMessageError(error)) {
          notifyWarn(error.message);
        }
        return;
      }

      model.pasteData(parsedData);
    };

    const handleCopy = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        event.preventDefault();
        model.copyData();
      }
    };

    const handleMouseUp = () => model.setSelecting(false);

    document.addEventListener('paste', handlePaste);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('keydown', handleCopy);

    const selectionChangeSub = model.selectionChange$.subscribe(({ selected, unselected }) => {
      selected
        .map(([id, key]) => model.getCellRef(id, key).current)
        .forEach((el) => addClass(el, '!bg-tags-background-blue'));
      unselected
        .map(([id, key]) => model.getCellRef(id, key).current)
        .forEach((el) => rmClass(el, '!bg-tags-background-blue'));
    });

    const nextSelectedCellSub = model.nextSelectedCell$.subscribe();

    const editedRecordsByIdSub = combineLatest([
      model.editedRecordsById$,
      model.combinedRecords$,
    ]).subscribe(([edited, combined]) => {
      combined.forEach((record) => {
        if (edited.has(record.id)) {
          addClass(model.getRowRef(record.id).current, '!bg-tags-background-yellow');
        } else {
          rmClass(model.getRowRef(record.id).current, '!bg-tags-background-yellow');
        }
      });
    });

    return () => {
      document.removeEventListener('paste', handlePaste);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('keydown', handleCopy);

      selectionChangeSub.unsubscribe();
      nextSelectedCellSub.unsubscribe();
      editedRecordsByIdSub.unsubscribe();
    };
  }, [model]);

  return (
    <>
      <div data-testid="data-grid-container" className="text-xs">
        <div className="scrollbar relative max-h-[89vh] w-full overflow-auto">
          <div className="grid">
            <div className={'sticky top-0 z-[2] w-full'}>
              {table.getHeaderGroups().map((headerGroup) => (
                <HeaderGroup key={headerGroup.id} headerGroup={headerGroup} />
              ))}
            </div>
            <div>
              {table.getRowModel().rows.map((row) => (
                <RowComponent key={row.id} row={row} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <TableViewToolbar table={table} editing$={editing$} />
    </>
  );
};
