import type ng from 'angular';

import template from './potential-purchase.html?raw';
import type { ContractsService } from '../../contracts/legacy/contracts.srv';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { html } from '^/shared/utils';

export const PotentialPurchase = {
  bindings: {
    obj: '<',
  },
  template,
  controller: [
    '$scope',
    '$state',
    'gettext',
    'gtFilterService',
    'PotentialPurchaseService',
    'ContractsService',
    'FinancesService',
    class {
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      ContractsService: ContractsService;
      FinancesService: FinancesService;
      PotentialPurchaseService: any;
      activeTab: string;
      balanceFilterOptions: any;
      count: number;
      currencyExchangeId?: number;
      dataList: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      obj: any;
      queryParams: QueryParams = {};
      tableData: any;
      tableName = 'potential-purchase-container';
      tableOptions: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
        PotentialPurchaseService: any,
        ContractsService: ContractsService,
        FinancesService: FinancesService,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.PotentialPurchaseService = PotentialPurchaseService;
        this.ContractsService = ContractsService;
        this.FinancesService = FinancesService;

        this.obj = {};
        this.dataList = [];
        this.count = 0;
        this.tableOptions = {};
        this.activeTab = 'table_info';
        this.filterLevel = 'potential-purchase-container';
        this.tableData = {};
        this.balanceFilterOptions = [
          {
            value: undefined,
            title: this.gettext('All'),
          },
          {
            value: '1',
            title: this.gettext('Yes'),
          },
          {
            value: '0',
            title: this.gettext('No'),
          },
        ];
      }

      $onInit() {
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.tableOptions = this.getTableOptions();
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      $onChanges(changes: any) {
        if (changes.obj) {
          this.updateData();
        }
      }

      updateData() {
        if (!this.currencyExchangeId) {
          return;
        }
        const params = {
          request_id: this.obj.id,
          currency_exchange_id: this.currencyExchangeId,
          agreement_type: 'purchase',
          date_predicate: 'conclusion_date',
          ...this.queryParams,
        };
        this.PotentialPurchaseService.getData(params).then((data: any) => {
          this.dataList = data.results;
          this.count = data.count;
          this.tableData = { rows: this.dataList, count: this.count };
        });
      }

      applyFilters(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      reverseDeal(row: any) {
        if (!confirm(this.gettext('Do you want to reverse ticket?'))) {
          return;
        }

        const ticket = {
          ...this.obj,
          VAT_option: true,
          general_agreement: row.id,
          price: row.request_price_opposite_local,
          price_opposite: row.request_price_local_without_charges,
          currency: this.FinancesService.getLocalCurrency().id,
          currency_exchange: this.currencyExchangeId,
        };

        if (this.obj.connections.length > 0) {
          ticket.contract_type = 'purchase';
          delete ticket.id;
          delete ticket.supplier;
          delete ticket.buyer;
          delete ticket.owner;
          delete ticket.contract_number;
          delete ticket.approval_config;
          delete ticket.responsible;
          return this.ContractsService.requestModal(ticket).then(() => {
            this.$state.go('tickets.purchase');
          });
        }
        return this.ContractsService.reverseDeal(ticket, true);
      }

      getTableOptions() {
        const options: any = {
          tableName: this.tableName,
          configurable: true,
          tableClass: 'request-table',
          filterLevel: this.filterLevel,
          applyFilters: (args: any) =>
            this.gtFilterService.updateQueryParams(args.params, this.filterLevel),
          activeTab: this.activeTab,
          columnDefs: [] as any[],
          tabs: [],
          showMore: true,

          templateArgs: {
            reverseDeal: (row: any) => this.reverseDeal(row),
          },
        };

        options.tabs = [
          {
            serializer: 'table_info',
            icon: 'fa-info-circle',
            columns: [
              'number',
              'amount',
              'buyer_name',
              'sides',
              'generalagreement_currency_symbol',
              'contracts_total_value',
              'balance_amount',
              'percent_conditions',
              'request_cargo_title',
              'request_volume',
              'request_price',
              'request_price_local_without_charges',
              'request_price_opposite_local',
              'request_total_value_opposite_local',
              'buttons',
            ],
          },
        ];

        options.columnDefs = [
          {
            columnName: 'number',
            title: this.gettext('#'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`<span> {[{ item.number }]} </span>`,
          },

          {
            columnName: 'sides',
            title: this.gettext('Sides'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span>
                <i class="fa fa-bookmark-o"></i> {[{ item.supplier_name }]}
                <div class="clearfix"></div>
                <i class="fa fa-bookmark"></i> {[{ item.buyer_name }]}
              </span>
            `,
          },
          {
            columnName: 'generalagreement_currency_symbol',
            title: this.gettext('Currency'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`<span> {[{ item.generalagreement_currency_symbol }]} </span>`,
          },
          {
            columnName: 'amount',
            title: this.gettext('Total value'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`<span> {[{ item.amount | number: 2 }]} </span>`,
          },
          {
            columnName: 'contracts_total_value',
            title: this.gettext('Contracts total value'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`<span> {[{ item.contracts_total_value | number: 2 }]} </span>`,
          },
          {
            columnName: 'balance_amount',
            title: this.gettext('Balance'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span
                class="label"
                ng-class="{'label_success': item.balance_amount > 0, 'label_danger': item.balance_amount < 0 }"
              >
                {[{ item.balance_amount | number: 2 }]}
              </span>
            `,
          },
          {
            columnName: 'percent_conditions',
            title: this.gettext('Percent conditions'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="label label_default" ng-if="item.percent_conditions">
                {[{ item.percent_conditions }]} %
              </span>
            `,
          },
          {
            columnName: 'request_price_local_without_charges',
            title: this.gettext('Sale price local'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`<span> {[{ item.request_price_local_without_charges }]} </span>`,
          },
          {
            columnName: 'request_price_opposite_local',
            title: this.gettext('Purchase price local'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="label"> {[{ item.request_price_opposite_local }]} </span>
            `,
          },
          {
            columnName: 'request_total_value_opposite_local',
            title: this.gettext('Purchase value local'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="label"> {[{ item.request_total_value_opposite_local }]} </span>
            `,
          },
          {
            columnName: 'buttons',
            title: '',
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span>
                <a class="btn btn-xs btn-success" translate="" ng-click="args.reverseDeal(item)">
                  <i class="fa fa-file-text-o"></i> Create ticket
                </a>
              </span>
            `,
          },
        ];

        return options;
      }
    },
  ],
};
