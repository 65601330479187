import React, { useMemo, useState } from 'react';

import { Input } from '~/shared/ui/kit/input';
import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';

import { type IconComponent, type IconName, useAllIcons, useIcon } from '../../icons';

const IconButton: React.FC<{
  Icon: IconComponent;
  iconName: IconName;
  onSelect?: (icon: IconName) => void;
}> = ({ Icon, iconName, onSelect }) => {
  return (
    <button
      key={iconName}
      className="hover:bg-transparent-light flex cursor-pointer items-center gap-2 p-2 transition duration-300 ease-in-out"
      onClick={() => onSelect?.(iconName)}
    >
      <Icon size={14} className="text-text-main-secondary" />
    </button>
  );
};

export const IconSelector: React.FC<{
  selectedIcon: IconName;
  onSelect?: (icon: IconName) => void;
}> = ({ selectedIcon, onSelect }) => {
  const allIcons = useAllIcons();
  const SelectedIcon = useIcon(selectedIcon);
  const [search, setSearch] = useState('');

  const filteredIcons = useMemo(() => {
    return Object.entries(allIcons).filter(([iconName]) =>
      iconName.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, allIcons]);

  return (
    <Popover>
      <PopoverTrigger className="border-stroke-main-medium h-8 rounded-sm border px-2">
        <SelectedIcon size={16} className="text-text-main-secondary" />
      </PopoverTrigger>
      <PopoverContent className="w-[200px]">
        <Input
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          fullWidth
        />
        <div className="flex max-h-48 flex-wrap overflow-auto" data-testid="icons-list">
          {filteredIcons.map(([iconName, icon]) => (
            <IconButton
              key={iconName}
              Icon={icon}
              iconName={iconName as IconName}
              onSelect={onSelect}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
