import ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';
import { notify } from '~/shared/lib/notify';

import type { ResourcesService } from '../../services/resources.service';
import type { GtRootScopeService, QueryParams } from '../../types';

import type { AccountsService } from '^/app/accounts/accounts.service';

type CacheStorageType = 'counters' | 'predictions' | 'report_configs';

export class GtUtilsService {
  readonly defaultCache: any = {
    counters: { default: {} },
    predictions: { default: {} },
    report_configs: { default: {} },
  };

  private _cache: any = {
    counters: {},
    predictions: {},
    report_configs: {},
  };

  readonly resourcesService: ResourcesService;
  readonly accountsService: AccountsService;
  private currencyExchange: any = null;
  private activeOverlays = 0;
  private _connections: object[] = [];
  private localCurrency = '';

  readonly _iconsMap = {
    'auth.user': 'fa-user',
    'accounts.regionalmanager': 'fa-address-book',
    'clients.client': 'fa-building',
    'clients.bank': 'fa-university',
    'clients.broker': 'fa-briefcase',
    'clients.buyer': 'fa-bookmark',
    'clients.elevator': 'fa-industry',
    'clients.exporter': 'fa-shield',
    'clients.farm': 'fa-building-o',
    'clients.insurer': 'fa-life-ring',
    'clients.person': 'fa-users',
    'clients.owner': 'fa-home',
    'clients.surveyor': 'fa-flask',
    'clients.deliverer': 'fa-truck',
    'clients.supplier': 'fa-bookmark-o',
    'clients.companygroup': 'fa-object-group',
    'clients.responsibility': '',
    'clients.clientupdate': '',
    'clients.other': 'fa-cube',
    'core.businessunit': 'fa-tag',
    'logistics.station': 'fa-train',
    'logistics.terminal': 'fa-anchor',
    'logistics.port': 'fa-anchor',
    'logistics.shipmentplan': 'fa-calendar-check-o',
    'logistics.vessel': '',
    'logistics.logistictariff': '',
    'logistics.logisticfreight': '',
    'logistics.logistic': 'fa-truck',
    'logistics.warehouseloss': 'fa-times',
    'warehouses.farm': 'fa-building-o',
    'warehouses.elevator': 'fa-industry',
    'warehouses.terminal': 'fa-anchor',
    'warehouses.warehouse': 'fa-warehouse',
    'warehouses.other': 'fa-question-circle',
    'contracts.contractbase': 'fa-file-text',
    'contracts.salecontract': 'fa-file-text',
    'contracts.purchasecontract': 'fa-file-text',
    'contracts.intermediatecontract': 'fa-file-text',
    'contracts.request': 'fa-file-text-o',
    'contracts.dealscompare': 'fa-arrows-alt',
    'contracts.contractcharge': 'fa-money',
    'contracts.consignment': 'fa-file',
    'passports.passport': 'fa-exchange',
    'location.country': '',
    'location.district': '',
    'crops.crop': '',
    'finances.charge': 'fa-paragraph',
    'finances.currency': 'fa-coins',
    'finances.currencyexchange': '',
    'finances.finance': 'fa-credit-card',
    'finances.financeaccount': 'fa-balance-scale',
  };
  readonly states = {
    'contracts.request': 'gt.page.request',
    'contracts.salecontract': 'gt.page.contract',
    'contracts.purchasecontract': 'gt.page.contract',
    'contracts.contractbase': 'gt.page.contract',
    'contracts.consignment': 'gt.page.contract',
    'contracts.generalagreement': 'gt.generalagreement',
    'contracts.contractcharge': 'finances.contractCharges',
    'passports.passport': 'gt.page.passport',
    'clients.client': 'gt.page.client',
    'clients.role': 'gt.page.role',
    'clients.buyer': 'gt.page.client',
    'clients.supplier': 'gt.page.client',
    'clients.exporter': 'gt.page.client',
    'clients.bank': 'gt.page.client',
    'clients.broker': 'gt.page.client',
    'clients.elevator': 'gt.page.client',
    'clients.farm': 'gt.page.client',
    'clients.insurer': 'gt.page.client',
    'clients.owner': 'gt.page.client',
    'clients.surveyor': 'gt.page.client',
    'clients.deliverer': 'gt.page.client',
    'finances.finance': 'gt.page.payment',
    'logistics.shipmentplan': 'logistics.planning',
    'logistics.vessel': 'gt.old-page.vessel',
    'logistics.logistic': 'gt.page.logistic',
    'logistics.billOfLading': 'logistics.billoflading',
    'clients.clientupdate': 'clients.updates',
    'auth.user': 'gt.page.trader',
  };
  static readonly $inject = [
    '$timeout',
    '$rootScope',
    '$uibModalStack',
    '$q',
    '$http',
    '$state',
    '$filter',
    '$document',
    '$window',
    '$location',
    '$injector',
    'gettext',
  ];
  constructor(
    private $timeout: ng.ITimeoutService,
    private $rootScope: GtRootScopeService,
    private $uibModalStack: any,
    private $q: ng.IQService,
    private $http: ng.IHttpService,
    private $state: ng.ui.IStateService,
    private $filter: any,
    private $document: any,
    private $window: any,
    private $location: ng.ILocationService,
    private $injector: ng.auto.IInjectorService,
    private gettext: (text: string) => string,
  ) {
    this.resourcesService = this.$injector.get<ResourcesService>('ResourcesService');
    this.accountsService = this.$injector.get<AccountsService>('AccountsService');
    this.localCurrency = this.accountsService.user?.settings.LOCAL_CURRENCY ?? '';
  }

  getLocalCurrency(): string {
    return this.localCurrency;
  }

  listUnique(list: any) {
    return list.filter(function (elem: any, index: number, self: any) {
      return index == self.indexOf(elem);
    });
  }

  debounce(callback: any, interval = 1000) {
    let timeout: any = null;
    return (...args: any[]) => {
      this.$timeout.cancel(timeout);
      timeout = this.$timeout(() => {
        callback.apply(this, args);
      }, interval);
    };
  }

  throttle(fn: (...args: any[]) => void, interval = 1000): (...args: any[]) => void {
    let last: number | null = null;
    let deferTimer: ng.IPromise<void> | null = null;
    return (...args: any[]) => {
      const now = Date.now();
      if (last && now < last + interval) {
        if (deferTimer) {
          this.$timeout.cancel(deferTimer);
        }
        deferTimer = this.$timeout(
          () => {
            last = now;
            fn(...args);
          },
          interval - (now - last),
        );
      } else {
        last = now;
        fn(...args);
      }
    };
  }

  getCurrentHost() {
    return this.$location.host().split('.').at(-2) ?? 'localhost';
  }

  daysInMonth(date: any) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  isSameDay(dateOne: any, dateTwo: any) {
    if (!dateOne || !dateTwo) {
      return false;
    }
    if (dateOne.getYear() != dateTwo.getYear()) {
      return false;
    }
    if (dateOne.getMonth() != dateTwo.getMonth()) {
      return false;
    }
    return dateOne.getDate() == dateTwo.getDate();
  }

  addBusinessDays(initialDate: Date, days: number): Date {
    const date = new Date(initialDate.getTime());
    let count = 0;
    while (count < days) {
      date.setDate(date.getDate() + 1);
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        count++;
      }
    }
    return date;
  }

  getResource(model: string) {
    return this.resourcesService.getResource(model);
  }

  getCounters(
    models: string[],
    params: QueryParams = {},
    level?: string,
    force?: any,
  ): Promise<any> {
    if (models.length === 1 && models[0]) {
      return this.getResource(models[0])
        .query({ count_only: true, ...params })
        .$promise.then(function (data: any) {
          const res: any = {};
          res[models[0]] = data.count;
          return res;
        })
        .catch((error: any) => this.errorClb(error));
    }

    const conf: { storage: CacheStorageType; apiUrl: string } = {
      storage: 'counters',
      apiUrl: '/api/core/counters/',
    };

    return this.getEntities(conf, models, level, force);
  }

  dismissAllModals() {
    return this.$uibModalStack.dismissAll();
  }

  getPredictions(
    models: string[],
    params?: Record<string, string | string[]>,
    level?: string,
    force?: boolean,
  ) {
    const conf: { storage: CacheStorageType; apiUrl: string } = {
      storage: 'predictions',
      apiUrl: '/api/core/predictions/',
    };

    const isSingleModel = models.length == 1 && models[0];
    const cachedModel =
      this._cache[conf.storage][level ?? 'default']?.[models[0] as 'results' | 'params'];

    const isSearch = params?.search && typeof params.search === 'string' && params.search.length;

    if (cachedModel && cachedModel.params.search === '') {
      if (
        cachedModel.params &&
        typeof cachedModel.params === 'object' &&
        'search' in cachedModel.params
      ) {
        delete cachedModel.params.search;
      }
    }

    if (params && params.search === '') {
      delete params.search;
    }

    const isParamsUpdated = cachedModel?.params && !this.isObjectsEqual(params, cachedModel.params);

    const getPredictionsResource = (
      conf: { storage: string },
      models: string[],
      params: object,
    ) => {
      const resource = this.getResource(models[0]);

      if (!resource?.predictions) {
        return Promise.resolve({ [models[0]]: [] });
      }

      const predictionsResult = resource.predictions(params);
      const promiseOrData = predictionsResult?.$promise || predictionsResult;

      return Promise.resolve(promiseOrData)
        .then((data: any) => {
          const { results } = data;

          if (!this._cache[conf.storage][level ?? 'default']) {
            this._cache[conf.storage][level ?? 'default'] = {};
          }

          this._cache[conf.storage][level ?? 'default'][models[0]] = {
            results,
            params,
          };

          this.setCache(this._cache);
          return { [models[0]]: results };
        })
        .catch((error: any) => this.errorClb(error));
    };

    if (force || isSearch || isParamsUpdated || isSingleModel) {
      return getPredictionsResource(conf, models, params ?? {});
    } else if (cachedModel) {
      return Promise.resolve({ [models[0]]: cachedModel.results });
    }

    return this.getEntities(conf, models, level, force);
  }

  isObjectsEqual(firstObj: any, secondObj: any) {
    return JSON.stringify(firstObj) === JSON.stringify(secondObj);
  }

  getCachedEntities(conf: any, modelsUpdate: any, level: string, force?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      if (force || modelsUpdate.length) {
        this.$http
          .get(conf.apiUrl, { params: { models: modelsUpdate } })
          .then((data: any) => {
            this._cache[conf.storage][level] = ng.extend(
              this._cache[conf.storage][level],
              data.data.results,
            );
            this.setCache(this._cache);
            resolve(this._cache[conf.storage][level]);
          })
          .catch((error: any) => {
            this.resetCache(conf.storage);
            reject(new Error('Failed to fetch cached entities', { cause: error }));
          });
      } else {
        resolve(this._cache[conf.storage][level]);
      }
    });
  }

  getEntities(
    conf: { storage: keyof GtUtilsService['_cache'] },
    models: string[],
    level = 'default',
    force = false,
  ): Promise<Record<string, string>> {
    this._cache[conf.storage][level] = this._cache[conf.storage][level] || {};

    models = models.length ? models : this.resourcesService.getModelNames();

    let modelsUpdate = force && models.length ? models : [];
    if (!force) {
      modelsUpdate = models.filter((model: string) => {
        return !this._cache[conf.storage][level][model];
      });
    }

    return new Promise((resolve, reject) => {
      this.getCachedEntities(conf, modelsUpdate, level, force)
        .then((result) => resolve(result))
        .catch((error) => {
          reject(new Error('Failed to fetch cached entities', { cause: error }));
        });
    });
  }

  updateCounters(counters: Record<string, any>, level = 'default'): void | Promise<any> {
    this._cache.counters[level] = this._cache.counters[level] || {};

    if (this._cache.counters[level].promise) {
      return this._cache.counters[level].promise.then(() => {
        ng.extend(this._cache.counters[level], counters);
        this.$rootScope.$broadcast('gt-counters-updated', {});
      });
    }

    ng.extend(this._cache.counters[level], counters);
    this.$rootScope.$broadcast('gt-counters-updated', {});
    this.setCache(this._cache);
  }

  newConnections(connections?: any): void {
    this.cancelConncections();
    if (connections && !Array.isArray(connections)) {
      connections = [connections];
    }
    this._connections = connections || [];
  }

  cancelConncections(): void {
    if (this._connections.length) {
      this._connections.forEach((item: any) => {
        if (item?.$cancelRequest) {
          item.$cancelRequest();
        } else if (item?.$$state?.value?.$cancelRequest) {
          item.$$state.value.$cancelRequest();
        }
      });
    }
    this._connections = [];
  }

  addConnection(connection: object): object {
    this._connections.push(connection);
    return connection;
  }

  overlay(action: 'show' | 'hide', newConn?: boolean) {
    if (newConn) {
      this.newConnections();
    }
    if (action === 'show') {
      if (this.activeOverlays === 0) {
        this.$rootScope.$broadcast('spinner-toggled', true);
      }
      this.activeOverlays++;
    } else {
      this.activeOverlays--;
      if (this.activeOverlays <= 0) {
        this.activeOverlays = 0;
        this.$rootScope.$broadcast('spinner-toggled', false);
      }
    }
  }

  getIcon = (modelName: string): string => {
    const iconKey = this.resourcesService.getModelName(modelName) || modelName;

    if (iconKey in this._iconsMap) {
      return this._iconsMap[iconKey as keyof typeof this._iconsMap];
    }

    return '';
  };

  errorClb = (data: any) => {
    this.overlay('hide');
    try {
      errorHandler(data);
    } catch {
      if (typeof data.data == 'string' || data.data === null) {
        return notify('A server error occurred. Please contact the administrator.', 'error');
      }
      if (data.data?.detail?.message) {
        return notify(data.data.detail.message, 'error');
      }
      if (data.data?.positions) {
        data.data.positions.forEach((position: any) => {
          ng.forEach(position, (value: any, key: any) =>
            notify('position ' + key + ': ' + position[key], 'error'),
          );
        });
      }
      if (ng.isArray(data.data)) {
        data.data.forEach((singleError: any) => {
          if (typeof singleError === 'string') {
            notify(singleError, 'error');
          } else {
            ng.forEach(singleError, (value: any, key: number) =>
              notify(key + ': ' + value[0], 'error'),
            );
          }
        });
      } else {
        ng.forEach(data.data, (value: any, key: number) => {
          if (Array.isArray(data.data[key])) {
            data.data[key].forEach((error: any) => {
              if (typeof error === 'string') {
                notify(`${key}: ${error}`, 'error');
              } else {
                ng.forEach(error, (value: any, key: number) =>
                  notify(key + ': ' + value[0], 'error'),
                );
              }
            });
          } else {
            notify(key + ': ' + data.data[key], 'error');
          }
        });
      }
    }
  };

  goToDetails(modelName: string, objectId: number) {
    modelName = this.resourcesService.getModelName(modelName);
    const state = this.states[modelName as keyof typeof this.states] || 'gt.home';
    return this.$state.go(state, { id: objectId });
  }

  goDetails(contentType: number, objectId: number, tab?: string) {
    return this.resourcesService
      .getResource('contenttypes.contenttype')
      .get({ id: contentType })
      .$promise.then((data: any) => {
        const key: string = data.app_label
          ? `${data.app_label.toLowerCase()}.${data.model.toLowerCase()}`
          : data.model.toLowerCase();

        const state = this.states[key as keyof typeof this.states] || 'gt.home';
        return this.$state.go(state, { id: objectId, tab });
      });
  }

  goPage(pageState: any, params?: any) {
    return this.$state.go(pageState || 'gt.home', params);
  }

  convertCurrency(value: any, fromSign: any): Promise<number> {
    const local = fromSign === 'USD' ? 'USD' : fromSign;

    return new Promise((resolve, reject) => {
      if (!this.currencyExchange) {
        this.currencyExchange = this.$q.defer();

        this.resourcesService
          .getResource('finances.currencyexchange')
          .query({ currency: local })
          .$promise.then((data: any) => {
            this.currencyExchange = data;
            resolve(this._convert(value, fromSign));
          })
          .catch((error: any) =>
            reject(new Error(error?.message || 'Failed to fetch currency exchange data')),
          );
      } else if (this.currencyExchange?.promise) {
        this.currencyExchange.promise.then(() => {
          resolve(this._convert(value, fromSign));
        });
      } else {
        resolve(this._convert(value, fromSign));
      }
    });
  }

  private _convert(value: any, from: any): number {
    value = parseFloat(value);
    if (from === 'USD') {
      return value * this.currencyExchange.rate;
    }
    return value / (this.currencyExchange?.rate || 1);
  }

  capitalize(value: any) {
    return value ? value.charAt(0).toUpperCase() + value.substr(1).toLowerCase() : '';
  }

  translate(text: any) {
    return this.$filter('translate')(text);
  }

  getObjectTitle(data: any) {
    return (
      data.name ||
      data.title ||
      data.model ||
      data.number ||
      data.contract_number ||
      (data.first_name && data.last_name && data.first_name + ' ' + data.last_name) ||
      data.username ||
      data.account_name ||
      data.account ||
      data.client_name ||
      data.id
    );
  }

  calcBusinessDays(startDate: any, businessDays: any) {
    return this.$http.get('/api/core/calc-business-days/', {
      params: {
        start_date: startDate,
        business_days: businessDays,
      },
    });
  }

  getBUQueryParams = (): any => {
    return {};
  };

  getDefaultOriginId() {
    if (this.$rootScope.user.settings.DEFAULT_VALUES.origin_of_crop) {
      return this.resourcesService
        .getResource('location.country')
        .query({
          search: this.$rootScope.user.settings.DEFAULT_VALUES.origin_of_crop,
        })
        .$promise.then((data: any) => data.results.shift()?.id);
    } else {
      return this.$q.when();
    }
  }

  getCustomStatusId(modelName: any) {
    return this.resourcesService
      .getResource('core.customstatus')
      .query({
        content_type__model: modelName,
        is_default: 1,
      })
      .$promise.then(function (data: any) {
        return data.results.shift()?.id;
      });
  }

  getDefaultFinanceOwnerId(field: any, invoiceType: any) {
    if (!this.$rootScope.user.settings.DEFAULT_VALUES.finance_owner) {
      return this.$q.when();
    }
    if (
      (field == 'clientrole_from' && invoiceType == 'outgoing') ||
      (field == 'clientrole_to' && invoiceType == 'incoming')
    ) {
      return this.resourcesService
        .getResource('clients.clientrole')
        .predictions({
          search: this.$rootScope.user.settings.DEFAULT_VALUES.finance_owner,
          role_name: 'owner',
        })
        .$promise.then(function (data: any) {
          return data.results.shift()?.id;
        });
    } else {
      return this.$q.when();
    }
  }

  filterEmptyParams(queryParams: QueryParams) {
    Object.keys(queryParams).forEach((key) => {
      const param = queryParams[key];
      if (
        param === null ||
        param === undefined ||
        param === typeof undefined ||
        param === 'true' ||
        (!param && typeof queryParams[key] == 'string') ||
        (key == '_theadFilters' && queryParams[key] == 'false')
      ) {
        delete queryParams[key];
      } else if (Array.isArray(param)) {
        queryParams[key] = param.filter((i) => {
          return i !== null && i !== undefined && i != 'undefined';
        });
      } else if (param === true) {
        queryParams[key] = 1;
      }
    });
    return queryParams;
  }

  downloadFile(path: any) {
    const _document = this.$document[0] || {};
    const element = _document.createElement('a');
    element.setAttribute('href', path);
    element.setAttribute('download', '');
    element.style.display = 'none';
    _document.body.appendChild(element);
    element.click();
    _document.body.removeChild(element);
  }

  copyToClipboard(value: any) {
    const _document = this.$document[0];
    const copyElement = _document.createElement('span');
    copyElement.appendChild(_document.createTextNode(value));
    copyElement.id = 'tempCopyToClipboard';
    copyElement.style = 'white-space: pre';
    ng.element(_document.body.append(copyElement));
    const range = _document.createRange();
    range.selectNode(copyElement);
    this.$window.getSelection().removeAllRanges();
    this.$window.getSelection().addRange(range);

    _document.execCommand('copy');
    this.$window.getSelection().removeAllRanges();
    copyElement.remove();
    notify(this.gettext(this.translate('Copied to clipboard')));
  }

  addDaysToDate(date: any, days: any) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  setCache(cache: any) {
    try {
      localStorage.setItem('gt-resources-cache', JSON.stringify(cache));
    } catch (error) {
      console.error('Failed to set gt-resource-cache: ', error);
    }
  }

  getCache() {
    try {
      const cachedResource = localStorage.getItem('gt-resources-cache');
      return cachedResource ? JSON.parse(cachedResource) : this.defaultCache;
    } catch (error) {
      console.error('Failed to get gt-resource-cache: ', error);
      return this.defaultCache;
    }
  }

  updateCache(
    storage: CacheStorageType,
    key: string,
    value: Record<string, string>,
    level = 'default',
  ): void {
    if (typeof value === 'function') {
      this.errorClb(this.gettext('Cached value should be only number, string, array or object'));
      return;
    }
    if (!this._cache[storage]) {
      this._cache[storage] = {};
    }
    if (!this._cache[storage][level]) {
      this._cache[storage][level] = {};
    }
    this._cache[storage][level][key] = value;
    this.setCache(this._cache);
  }

  getAllCache() {
    return this._cache;
  }

  getCachedValue(
    storage: CacheStorageType,
    key: string,
    level = 'default',
  ): Record<string, string> | null {
    return this._cache[storage][level][key] || (this.resetCache(storage), null);
  }

  resetCache(storage?: CacheStorageType): void {
    if (storage) {
      this._cache[storage] = { default: {} };
    } else {
      Object.keys(this.defaultCache).forEach((key) => {
        this._cache[key] = {
          ...this.defaultCache[key],
        };
      });
    }
    localStorage.setItem('gt-resources-cache', JSON.stringify(this._cache));
  }

  getYearList() {
    return this.$rootScope.user.settings.CROP_YEAR_LIST.map((year: any) => ({
      id: year,
      title: year,
    }));
  }

  getMonthList() {
    return [
      { id: 12, title: '12' },
      { id: 11, title: '11' },
      { id: 10, title: '10' },
      { id: 9, title: '09' },
      { id: 8, title: '08' },
      { id: 7, title: '07' },
      { id: 6, title: '06' },
      { id: 5, title: '05' },
      { id: 4, title: '04' },
      { id: 3, title: '03' },
      { id: 2, title: '02' },
      { id: 1, title: '01' },
    ];
  }
}
