import ng from 'angular';

import type { GtRootScopeService } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('execution.legacy').directive('clientTariffRow', directive);

  function directive() {
    return {
      template: require('./client-tariff-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      require: '^^tariffsTable',
      scope: {
        tariff: '<',
        onSave: '&',
        onDelete: '&',
      },
    };
  }

  Controller.$inject = ['$rootScope'];

  function Controller(this: any, $rootScope: GtRootScopeService) {
    const vm = this;

    vm.saveTariff = saveTariff;
    vm.deleteTariff = deleteTariff;
    vm.chargeEditQueryParams = {};
    vm.buQueryParams = { user: $rootScope.user.id };

    vm.$onInit = function () {
      vm.edit = !vm.tariff.id;
      vm.tariff.transportation_type = vm.tariff.transportation_type || 'auto';
      vm.chargeEditQueryParams = { tariff_type: vm.tariff.transportation_type };
    };

    function saveTariff() {
      vm.onSave({ tariff: vm.tariff });
    }

    function deleteTariff() {
      vm.onDelete({ tariff: vm.tariff });
    }
  }
})();
