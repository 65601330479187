import type ng from 'angular';

import template from './warehouse-shipment-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const WarehouseShipmentListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
  },
  template,
  controller: [
    '$q',
    '$scope',
    'GtUtils',
    'gtFilterService',
    'WarehouseShipmentService',
    'ContractsService',
    'gettext',
    class warehouseShipmentContainer {
      $q: ng.IQService;
      $scope: ng.IScope;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      WarehouseShipmentService: any;
      contractChargesData: any;
      dataCount: number;
      dataList: any;
      dataTotal: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      selectedConsignments: any;
      constructor(
        $q: ng.IQService,
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        WarehouseShipmentService: any,
        ContractsService: ContractsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$q = $q;
        this.$scope = $scope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.WarehouseShipmentService = WarehouseShipmentService;
        this.ContractsService = ContractsService;

        this.filterLevel = '';
        this.queryParams = {};

        this.dataList = [];
        this.dataCount = 0;
        this.dataTotal = {};
        this.selectedConsignments = [];
        this.contractChargesData = [];
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'warehouse-shipment-page-view';
        this.queryParams = { page_size: 20, ...this.initQueryParams };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: QueryParams) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      createCosts(contractChargesData: any) {
        this.ContractsService.ContractCharge.bulkCreateOrUpdate(contractChargesData).$promise.then(
          () => {
            const consignments = this.dataList
              .filter((item: any) => this.selectedConsignments.includes(item.id))
              .map((item: any) => ({
                ...item,
                is_costs_created: true,
              }));
            this.WarehouseShipmentService.warehouseShipmentResource
              .bulkCreateOrUpdate(consignments)
              .$promise.then(() => {
                this.updateData();
                this.contractChargesData = [];
              });
          },
          this.GtUtils.errorClb,
        );
      }

      getContractChargesData(consignments: any) {
        this.selectedConsignments = consignments;
        return this.WarehouseShipmentService.getContractChargesData({
          id_list: consignments,
        }).then((data: any) => {
          this.contractChargesData = data.results;
        }, this.GtUtils.errorClb);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.WarehouseShipmentService.getListData(this.queryParams).then((data: any) => {
          this.dataList = data.results;
          this.dataCount = data.count;
          this.dataTotal = data.totals;
          this.GtUtils.overlay('hide');
        });
      }
    },
  ],
};
