import ng from 'angular';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('paymentsContainer', {
    bindings: {
      payment: '<',
      initQueryParams: '<?',
      filterLevel: '<?',
      inlineAdd: '<?',
      mode: '<?',
      onUpdate: '=',
      updateInvoiceCurrencyExchange: '&?',
      invoice: '<?',
      simplePayments: '<?',
    },
    template: require('./payments-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$rootScope',
    '$filter',
    'GtUtils',
    'FinancesService',
    'gtFilterService',
    'CoreService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    $filter: ng.IFilterService,
    GtUtils: GtUtilsService,
    FinancesService: FinancesService,
    gtFilterService: GtFilterService,
    CoreService: CoreService,
  ) {
    const vm = this;
    vm.payments = [];
    vm.paymentsCount = 0;
    vm.updatePayments = updatePayments;
    vm.total = {};
    vm.newPayment = {};

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'payments-container';
      vm.invoice = vm.invoice || {};
      vm.queryParams = { ...vm.initQueryParams };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updatePayments();
      });
      if (vm.invoice.invoice_type == 'incoming') {
        vm.bankAccountQueryParams = {
          client_role: vm.invoice.clientrole_to,
          currency: vm.invoice.currency,
          status: 'valid',
        };
      } else {
        vm.bankAccountQueryParams = {
          client_role: vm.invoice.clientrole_from,
          currency: vm.invoice.currency,
          status: 'valid',
        };
      }
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      vm.savedFilterChoices = CoreService.getSavedFilterChoices(vm.filterLevel);
    };

    vm.$onChanges = function (changes: any) {
      vm.payment = vm.payment || {};
      if (changes.payment && vm.inlineAdd) {
        vm.newPayment.amount = vm.payment.amount;
        vm.newPayment.bank_account = vm.payment.bank_account;
        vm.newPayment.currency_exchange = vm.payment.currency_exchange;
        vm.newPayment.currency_alternative = vm.payment.currency_alternative;
      }
      $scope.$watch('vm.newPayment.currency_alternative', getBankAccountQueryParams);
    };

    ////////////////

    function getBankAccountQueryParams() {
      if ($rootScope.user.settings.PAYMENT_IN_ANOTHER_CURRENCY) {
        vm.bankAccountQueryParams.currency =
          vm.newPayment.currency_alternative || vm.invoice.currency;
      } else {
        vm.bankAccountQueryParams.currency = vm.invoice.currency;
      }
    }

    function updateCurrencyExchangeByPaymantDate(date: any) {
      FinancesService.CurrencyExchange.query({
        with_invoice: vm.newPayment.finance,
        exchange_date: $filter('date')(date, 'dd.MM.yyyy'),
      }).$promise.then((data: any) => {
        vm.newPayment.currency_exchange = data.results.length ? data.results.reverse()[0].id : null;
      });
    }

    function createNewPayment() {
      vm.newPayment = { ...vm.payment, ...vm.initQueryParams };
      vm.newPayment.finance = vm.initQueryParams?.finance || vm.initQueryParams?.linked_to_invoice;
      vm.newPayment.date = new Date();
      updateCurrencyExchangeByPaymantDate(vm.newPayment.date);
    }

    function updatePayments() {
      GtUtils.overlay('show');
      return FinancesService.Payment.query(vm.queryParams, function (data: any) {
        vm.payments = data.results;
        vm.paymentsCount = data.count;
        createNewPayment();
        if (vm.inlineAdd) {
          vm.payments.unshift(vm.newPayment);
        }
        return FinancesService.Payment.tableTotal(vm.queryParams, function (data: any) {
          vm.total = data;
          GtUtils.overlay('hide');
        }).$promise;
      }).$promise;
    }
  }
})();
