import ng from 'angular';

import { ThemeStore } from '~/core/theme/services';
import { container } from '~/shared/lib/di';

import type { GtFilterService } from '../../gt-filter/gt-filter.srv';
import type { GtUtilsService } from '../../gt-utils/gt-utils.srv';

import type { GtRootScopeService } from '^/app/core/types';
import type { NotificationService } from '^/app/crm/notifications/notifications.service';

function Service(
  $rootScope: GtRootScopeService,
  $window: ng.IWindowService,
  $httpParamSerializer: any,
  $location: ng.ILocationService,
  $state: ng.ui.IStateService,
  gtFilterService: GtFilterService,
  gettext: ng.gettext.gettextFunction,
  GtUtils: GtUtilsService,
  NotificationService: NotificationService,
) {
  const themeStore = container.resolve(ThemeStore);
  let _config: any = {};
  const urlParams: any = {};
  let onFilterListener: any;

  return {
    getConfig: getConfig,
    setConfig: setConfig,
    updatePageCounters: updatePageCounters,
    setTabCounter: setTabCounter,
    updateConfig: updateConfig,
    syncUrlFilter: syncUrlFilter,
    setPageTitle: setPageTitle,
    buttons: getButtons(),
    getButtons,
  };

  ////////////////

  function getConfig() {
    return { ..._config };
  }

  function setConfig(config: object) {
    _config = { ...config };
    $rootScope.$broadcast('page-config-updated', getConfig());
  }

  function updatePageCounters(params: object) {
    $rootScope.$broadcast('page-counters-updated', params);
  }

  function setTabCounter(counterKey: any, count: number) {
    _config.pages.forEach((page: any) => {
      page.tabs
        .filter((tab: any) => tab.counter == counterKey)
        .forEach((tab: any) => {
          tab.tabCounterNumber = count;
        });
    });
  }

  function updateConfig(config: any) {
    ng.extend(_config, config);
    for (const i in _config) {
      if (_config[i] === null || _config[i] === undefined) {
        delete _config[i];
      }
    }
    $rootScope.$broadcast('page-config-updated', getConfig());
  }

  function setPageTitle(title: string) {
    $rootScope.pageTitle = title;
    themeStore.setTitle(title);
  }

  function syncUrlFilter(filterLevel: string) {
    let queryParams = $location.search() || {};
    delete queryParams.serializer;
    queryParams = GtUtils.filterEmptyParams(queryParams);
    Object.keys(queryParams).forEach((key) => {
      if (key.endsWith('_list') && !Array.isArray(queryParams[key])) {
        queryParams[key] = [queryParams[key]];
      }
    });
    if (ng.isFunction(onFilterListener)) {
      onFilterListener();
    }
    onFilterListener = $rootScope.$on(
      'gt-filter-updated_' + filterLevel,
      function (ev: any, data: any) {
        data = GtUtils.filterEmptyParams({ ...data });
        delete data.serializer;
        urlParams[filterLevel] = JSON.stringify(data);
        $location.search(data);
      },
    );
    return gtFilterService.setQueryParams(queryParams, filterLevel);
  }

  function getButtons() {
    return {
      Print: class {
        icon = 'fa-print';
        tip = gettext('print');
        onClick;
        constructor(beforeRun?: () => Promise<void>) {
          this.onClick = () => {
            if (beforeRun) {
              beforeRun()
                .then(() => $window.print())
                .catch(GtUtils.errorClb);
            } else {
              $window.print();
            }
          };
        }
      },
      Refresh: class {
        icon = 'fa-refresh';
        tip = gettext('refresh');
        refresh = true;
        onClick;
        constructor(filterLevel?: string) {
          this.onClick = function () {
            if (typeof filterLevel == 'string') {
              gtFilterService.updateQueryParams({}, filterLevel);
            } else {
              $state.reload();
            }
          };
        }
      },
      Export: class {
        icon = 'fa-upload';
        tip = gettext('export');
        onClick;
        permissions: string[];
        constructor(resource: string, filterLevel: string, permissions: string[] = []) {
          this.onClick = () => {
            const params = gtFilterService.getQueryParams(filterLevel);
            const lng = $rootScope.user.profile?.language;
            const url = `/${lng}/admin/${resource.split('.').join('/')}/export/?`;
            $window.open(url + $httpParamSerializer(params), '_blank');
          };
          this.permissions = permissions;
        }
      },
      Admin: class {
        icon = 'fa-database';
        tip = gettext('admin');
        onClick;
        permissions: string[];
        constructor(resource: string, filterLevel: string, permissions: string[] = []) {
          this.onClick = () => {
            const params = gtFilterService.getQueryParams(filterLevel);
            const lng = $rootScope.user.profile?.language;
            const url = `/${lng}/admin/${resource.split('.').join('/')}`;
            $window.open(url + $httpParamSerializer(params), '_blank');
          };
          this.permissions = permissions;
        }
      },
      Import: class {
        icon = 'fa-download';
        tip = gettext('import');
        onClick;
        permissions: string[];
        constructor(resource: string, _filterLevel = '', permissions: string[] = []) {
          this.onClick = () => {
            const lng = $rootScope.user.profile?.language;
            const url = `/${lng}/admin/${resource.split('.').join('/')}/import/`;
            $window.open(url, '_blank');
          };
          this.permissions = permissions;
        }
      },
      ImportGoogleDrive: class {
        icon = 'fa-google-plus-square';
        tip = gettext('import google drive');
        onClick;
        permissions: string[];
        constructor(resource: string, _filterLevel = '', permissions: string[] = []) {
          this.onClick = () => {
            const lng = $rootScope.user.profile?.language;
            const url = `/${lng}/admin/${resource.split('.').join('/')}/gdrive_import/`;
            $window.open(url, '_blank');
          };
          this.permissions = permissions;
        }
      },
      Filters: class {
        icon = 'fa-filter';
        tip = gettext('filter');
        disabled = true;
        clicked = false;
        onClick = function () {
          const config = getConfig();
          config.filters.clicked = !config.filters.clicked;
          setConfig(config);
        };
      },
      ScreenCaptureTask: class {
        icon = 'fa-share';
        tip = gettext('send a report');
        onClick = function () {
          NotificationService.openScreenCaptureTaskModal({
            name: $rootScope.pageTitle,
            url: $location.url().slice(1),
          });
        };
      },
    };
  }
}

(function () {
  'use strict';
  ng.module('core.legacy').factory('PageService', Service);

  Service.$inject = [
    '$rootScope',
    '$window',
    '$httpParamSerializer',
    '$location',
    '$state',
    'gtFilterService',
    'gettext',
    'GtUtils',
    'NotificationService',
  ];
})();

export type PageService = ReturnType<typeof Service>;
