import React from 'react';

import { useAuth } from '~/core/auth/hooks';
import { type AuthCredentials } from '~/core/auth/lib';
import { useForm, v } from '~/shared/lib/forms';
import { Button } from '~/shared/ui/kit/button';
import { Input } from '~/shared/ui/kit/input';

export const LoginForm: React.FC<{ submit: (credentials: AuthCredentials) => void }> = ({
  submit,
}) => {
  const { loading } = useAuth();
  const form = useForm({
    defaultValues: { username: '', password: '' },
    onSubmit: ({ value }) => submit(value),
  });
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit().catch(console.error);
      }}
      className="flex flex-col gap-4"
    >
      <form.Field
        name="username"
        validators={{
          onChange: v.pipe(v.string(), v.minLength(1, 'Username is required')),
        }}
      >
        {(field) => (
          <Input
            type="text"
            placeholder="Type Here"
            intent={field.state.meta.errors.length ? 'danger' : 'default'}
            label="Username"
            onChange={(e) => field.handleChange(e.target.value)}
            helperText={
              !!field.state.meta.errors.length && (
                <p className="text-text-additional-danger">
                  {field.state.meta.errors.map(String).join(', ')}
                </p>
              )
            }
            data-testid="username-input"
            required
            fullWidth
          />
        )}
      </form.Field>
      <form.Field
        name="password"
        validators={{
          onChange: v.pipe(v.string(), v.minLength(1, 'Password is required')),
        }}
      >
        {(field) => (
          <Input
            type="password"
            placeholder="Type Here"
            intent={field.state.meta.errors.length ? 'danger' : 'default'}
            label="Password"
            onChange={(e) => field.handleChange(e.target.value)}
            required
            fullWidth
            helperText={
              !!field.state.meta.errors.length && (
                <p className="text-text-additional-danger text-xs">
                  {field.state.meta.errors.map(String).join(', ')}
                </p>
              )
            }
            data-testid="password-input"
          />
        )}
      </form.Field>
      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit]) => (
          <Button
            type="submit"
            variant="fadedSecondary"
            size="md"
            disabled={loading ?? !canSubmit}
            fullWidth
          >
            Login
          </Button>
        )}
      </form.Subscribe>
    </form>
  );
};
