import type ng from 'angular';

import template from './invoice-position-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const InvoicePositionPageView = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    'PageService',
    'gtFilterService',
    'FinancesService',
    'GtUtils',
    'gettext',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      filterLevel = 'invoice-position-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      constructor(
        PageService: PageService,
        gtFilterService: GtFilterService,
        FinancesService: FinancesService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        $rootScope: GtRootScopeService,
      ) {
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.FinancesService = FinancesService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.$rootScope = $rootScope;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-payments-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.FinancesService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            search: false,
            dates: true,
            dateSelects: [
              {
                argument: 'invoice__date',
                placeholder: this.gettext('Date'),
              },
              {
                argument: 'invoice__date_of_receiving',
                placeholder: this.gettext('Date of receiving'),
              },
              {
                argument: 'invoice__date_of_execution',
                placeholder: this.gettext('Date of payment (plan)'),
              },
              {
                argument: 'invoice__date_of_execution_fact',
                placeholder: this.gettext('Date of payment (fact)'),
              },
            ],
            multiSelects: [
              {
                argument: 'invoice_list',
                placeholder: this.gettext('invoices'),
                resource: 'finances.finance',
              },
              {
                argument: 'currency_list',
                placeholder: this.gettext('currencies'),
                resource: 'finances.currency',
              },
              {
                argument: 'terminal_list',
                placeholder: this.gettext('terminals'),
                resource: 'logistics.terminal',
              },
              {
                argument: 'contract_list',
                placeholder: this.gettext('contracts'),
                resource: 'contracts.ContractBase',
              },
              {
                argument: 'passport_list',
                placeholder: this.gettext('passports'),
                resource: 'passports.passport',
              },
              {
                argument: 'bu_list',
                placeholder: this.gettext('business units'),
                resource: 'core.BusinessUnit',
              },
              {
                argument: 'client_list',
                placeholder: this.gettext('client'),
                resource: 'clients.client',
              },
              {
                argument: 'clientrole_from_list',
                placeholder: this.gettext('from company'),
                resource: 'clients.clientrole',
              },
              {
                argument: 'clientrole_to_list',
                placeholder: this.gettext('to company'),
                resource: 'clients.clientrole',
              },
              {
                argument: 'bank_account_list',
                placeholder: this.gettext('bank account'),
                resource: 'finances.bankaccount',
              },
              {
                argument: 'charge_list',
                placeholder: this.gettext('purpose'),
                resource: 'finances.Charge',
              },
              {
                argument: 'expenses_option_list',
                placeholder: this.gettext('General expense'),
                resource: 'finances.GeneralExpenses',
              },
              {
                argument: 'other_activity_list',
                placeholder: this.gettext('Other activity'),
                resource: 'finances.OtherActivity',
              },
              {
                argument: 'cargo_list',
                placeholder: this.gettext('cargo'),
                resource: 'crops.Crop',
                related_fk: 'crop_id',
                related_model: 'finances.invoiceposition',
              },
            ],
            nestedSelects: [
              {
                argument: 'invoice_type',
                placeholder: this.gettext('Type'),
                items: [
                  {
                    id: 'outgoing',
                    title: this.gettext('Outgoing'),
                  },
                  {
                    id: 'incoming',
                    title: this.gettext('incoming'),
                  },
                ],
              },
              {
                argument: 'invoice_condition',
                placeholder: this.gettext('Condition'),
                items: [
                  {
                    id: 'prepay',
                    title: this.gettext('Prepay'),
                  },
                  {
                    id: 'balance',
                    title: this.gettext('Balance'),
                  },
                ],
              },
              {
                argument: 'contract_deal_type',
                class: '',
                items: [
                  { id: 'spot', title: this.gettext('Spot') },
                  { id: 'forward', title: this.gettext('Forward') },
                  { id: 'intermediate', title: this.gettext('Intermediate') },
                  { id: 'export', title: this.gettext('Export') },
                ],
                placeholder: this.gettext('Contract deal type'),
              },
              this.gtFilterService.getBoolFilter(
                'with_passport',
                this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
              ),
              this.gtFilterService.getBoolFilter('with_contract', this.gettext('Contract')),
              this.gtFilterService.getBoolFilter(
                'with_multiple_contracts',
                this.gettext('Multiple contracts'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_contract_internal_chain',
                this.gettext('Internal chain'),
              ),
            ],
            nestedMultiSelects: [
              {
                argument: 'use_list',
                placeholder: this.gettext('Use'),
                items: [
                  {
                    id: 'cargo',
                    title: this.gettext('Cargo'),
                  },
                  {
                    id: 'costs',
                    title: this.gettext('Costs'),
                  },
                  {
                    id: 'gains',
                    title: this.gettext('Gains'),
                  },
                  {
                    id: 'expenses',
                    title: this.gettext('General expenses'),
                  },
                  {
                    id: 'washout',
                    title: this.gettext('Washout'),
                  },
                  {
                    id: 'credit',
                    title: this.gettext('Credit'),
                  },
                  {
                    id: 'other',
                    title: this.gettext('Other'),
                  },
                ],
              },
              {
                argument: 'invoice_status_list',
                placeholder: this.gettext('Total'),
                items: [
                  {
                    id: 'new',
                    title: this.gettext('New'),
                  },
                  {
                    id: 'process',
                    title: this.gettext('Process'),
                  },
                  {
                    id: 'paid',
                    title: this.gettext('Paid'),
                  },
                  {
                    id: 'canceled',
                    title: this.gettext('Canceled'),
                  },
                ],
              },
              {
                argument: 'contract_crop_year_list',
                placeholder: this.gettext('Contract crop year'),
                items: this.GtUtils.getYearList(),
              },
            ],
          },
        };
      }
    },
  ],
};
