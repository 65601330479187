import ng from 'angular';

import { GtUtilsService } from './gt-utils/gt-utils.srv';

import { gtInlineFilter } from '^/app/core/legacy/components/gt-inline-filter/gt-inline-filter.cmp';

export const coreLegacyModule = ng
  .module('core.legacy', [])
  .service('GtUtils', GtUtilsService)
  .component('gtInlineFilter', gtInlineFilter).name;
