import type ng from 'angular';

export class SalePlanReportService {
  $http: ng.IHttpService;
  SalePlanResource: any;
  constructor($http: ng.IHttpService, $resource: ng.resource.IResourceService) {
    this.$http = $http;
    this.SalePlanResource = $resource(
      '/api/contracts/sale-plans/:id/',
      {
        id: '@id',
      },
      {
        save: { method: 'POST' },
        update: { method: 'PATCH' },
        delete: { method: 'DELETE' },
        list: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/sale-plans-report/list/',
        },
        totals: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/sale-plans-report/totals/',
        },
      },
    );
  }

  getData(params: object) {
    return this.SalePlanResource.list(params).$promise;
  }

  getTotalsData(params: object) {
    return this.SalePlanResource.totals(params).$promise;
  }

  save(item: any) {
    if (item.id) {
      return this.SalePlanResource.update(item).$promise;
    }
    return this.SalePlanResource.save(item).$promise;
  }

  delete(obj: any) {
    return this.SalePlanResource.delete({ id: obj.id }).$promise;
  }
}
SalePlanReportService.$inject = ['$http', '$resource', '$filter'];
