import ng from 'angular';

import { planning } from './components/planning/planning.module';
import { tariffs } from './components/tariffs/tariffs.module';
import { transport } from './components/transport/transport.module';
import { VoyageFewCommodityWidget } from './components/voyage-few-commodity-widget/voyage-few-commodity-widget.component';
import { voyageQualitiesTable } from './components/voyage-qualities-tab/voyage-qualities-table.component';
import { warehouse } from './components/warehouse/warehouse.module';
import { logisticsLegacyModule } from './legacy';
import { shared } from './shared/shared.module';

export const executionModule = ng
  .module('execution', [logisticsLegacyModule, transport, shared, warehouse, tariffs, planning])
  .component('voyageFewCommodityWidget', VoyageFewCommodityWidget)
  .component('voyageQualitiesTable', voyageQualitiesTable).name;
