import template from './customs-cargo-declaration-details-item-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const CustomsCargoDeclarationDetailsItemPage = {
  bindings: {
    filterLevel: '<',
  },
  template,
  controller: [
    '$stateParams',
    'PageService',
    class {
      $stateParams: ng.ui.IStateParamsService;
      PageService: PageService;
      declaration: any;
      constructor($stateParams: ng.ui.IStateParamsService, PageService: PageService) {
        this.$stateParams = $stateParams;
        this.PageService = PageService;
        this.declaration = {};
      }

      $onInit() {
        this.declaration = { id: this.$stateParams.id };
        this.PageService.setConfig({
          is_detail_page: true,
          pages: [],
        });
      }
    },
  ],
};
