import type ng from 'angular';

import template from './analytics-container.html?raw';
import type { ClientsAnalyticsService } from '../analytics.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import { html } from '^/shared/utils';

export const AnalyticsContainer = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gettext',
    'ClientsAnalyticsService',
    'gtFilterService',
    class {
      ClientsAnalyticsService: ClientsAnalyticsService;
      buyerConfirmationsTableApi: any;
      buyerConfirmationsTableOptions: any;
      buyersContractVolumesChartConfig: any;
      buyersContractVolumesTableApi: any;
      buyersContractVolumesTableOptions: any;
      exportersContractVolumesChartConfig: any;
      exportersContractVolumesTableApi: any;
      exportersContractVolumesTableOptions: any;
      filterLevel = 'clients-analytics-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      relationsChartConfig: any;
      responsiblesTableApi: any;
      responsiblesTableOptions: any;
      slowpokeClientsTableApi: any;
      slowpokeClientsTableOptions: any;
      suppliersCanceledContractTableApi: any;
      suppliersCanceledContractsTableOptions: any;
      suppliersContractVolumesChartConfig: any;
      suppliersContractVolumesTableApi: any;
      suppliersContractVolumesTableOptions: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        ClientsAnalyticsService: ClientsAnalyticsService,
        gtFilterService: GtFilterService,
      ) {
        this.gettext = gettext;
        this.ClientsAnalyticsService = ClientsAnalyticsService;
        this.gtFilterService = gtFilterService;

        this.responsiblesTableOptions = {};
        this.buyerConfirmationsTableOptions = {};
        this.slowpokeClientsTableOptions = {};
        this.relationsChartConfig = undefined;
        this.suppliersContractVolumesTableOptions = {};
        this.suppliersContractVolumesChartConfig = undefined;
        this.buyersContractVolumesTableOptions = {};
        this.buyersContractVolumesChartConfig = undefined;
        this.exportersContractVolumesTableOptions = {};
        this.exportersContractVolumesChartConfig = undefined;
        this.suppliersCanceledContractsTableOptions = {};
      }

      $onInit() {
        this.gtFilterService.subscribe(this.filterLevel, () => this.updateData(), {});
        this.updateData();
      }

      updateData() {
        this.ClientsAnalyticsService.getAnalyticsData({});
        this.responsiblesTableOptions = this.getResponsiblesTableOptions();
        this.buyerConfirmationsTableOptions = this.getBuyerConfirmationsTableOptions();
        this.slowpokeClientsTableOptions = this.getSlowpokeClientsTableOptions();
        this.updateRelationsChartConfig();
        this.suppliersContractVolumesTableOptions = this.getSuppliersContractVolumesTableOptions();
        this.updateSuppliersContractVolumesChartConfig();
        this.buyersContractVolumesTableOptions = this.getBuyersContractVolumesTableOptions();
        this.updateBuyersContractVolumesChartConfig();
        this.exportersContractVolumesTableOptions = this.getExportersContractVolumesTableOptions();
        this.updateExportersContractVolumesChartConfig();
        this.suppliersCanceledContractsTableOptions =
          this.getSuppliersCanceledContractsTableOptions();
      }

      getResponsiblesTableOptions() {
        return {
          tableName: 'responsibles',
          filterLevel: this.filterLevel,
          columnDefs: [
            {
              columnName: 'responsible__username',
              title: this.gettext('user'),
            },
            {
              columnName: 'responsible_count',
              title: this.gettext('responsible'),
              cellTemplate: html`<div class="progress">
                <div
                  class="progress-bar progress_bar_default"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: {[{ item.responsible_count_percentage }]}%"
                >
                  {[{ item.responsible_count }]} / {[{ item.clients_count }]}
                </div>
              </div>`,
            },
            {
              columnName: 'responsible_confirmation_count',
              title: this.gettext('responsible confirmation'),
              cellTemplate: html`<div class="progress">
                <div
                  class="progress-bar progress_bar_default"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: {[{ item.responsible_confirmation_count_percentage }]}%"
                >
                  {[{ item.responsible_confirmation_count }]} / {[{ item.clients_count }]}
                </div>
              </div>`,
            },
          ],
          rowData: [],
        };
      }

      onResponsiblesTableInit(event: any) {
        this.responsiblesTableApi = event.api;
        this.ClientsAnalyticsService.getUserResponsibles().then((data: any) => {
          this.responsiblesTableApi.setRowData(data);
        });
      }

      getBuyerConfirmationsTableOptions() {
        return {
          tableName: 'responsibles',
          filterLevel: this.filterLevel,
          columnDefs: [
            {
              columnName: 'name',
              title: this.gettext('buyer'),
            },
            {
              columnName: 'confirmations__count',
              title: this.gettext('confirmations'),
            },
          ],
          rowData: [],
        };
      }

      onBuyerConfirmationsTableInit(event: any) {
        this.buyerConfirmationsTableApi = event.api;
        this.ClientsAnalyticsService.getBuyerConfirmations().then((data: any) => {
          this.buyerConfirmationsTableApi.setRowData(data);
        });
      }

      getSlowpokeClientsTableOptions() {
        return {
          tableName: 'responsibles',
          filterLevel: this.filterLevel,
          columnDefs: [
            {
              columnName: 'name',
              title: this.gettext('client'),
            },
            {
              columnName: 'days',
              title: this.gettext('days'),
            },
          ],
          rowData: [],
        };
      }

      onSlowpokeClientsTableInit(event: any) {
        this.slowpokeClientsTableApi = event.api;
        this.ClientsAnalyticsService.getSlowpokeClients().then((data: any) => {
          this.slowpokeClientsTableApi.setRowData(data);
        });
      }

      updateRelationsChartConfig() {
        this.ClientsAnalyticsService.getRelationsChartConfig().then(
          (data: any) => (this.relationsChartConfig = data),
        );
      }

      getSuppliersContractVolumesTableOptions() {
        return {
          tableName: 'responsibles',
          filterLevel: this.filterLevel,
          columnDefs: [
            {
              columnName: 'supplier__name',
              title: this.gettext('company'),
            },
            {
              columnName: 'volume__sum',
              cellTemplate: html`{[{ item.volume__sum || 0 | number:2 }]}
                <span class="smaller-label label-opacity"
                  >{[{ item.volume_percentage || 0 | number:1 }]}%</span
                >`,
              title: this.gettext('volume'),
            },
            {
              columnName: 'contracts',
              title: this.gettext('contracts'),
            },
          ],
          rowData: [],
        };
      }

      onSuppliersContractVolumesTableInit(event: any) {
        this.suppliersContractVolumesTableApi = event.api;
        this.ClientsAnalyticsService.getSuppliersContractVolumes().then((data: any) => {
          this.suppliersContractVolumesTableApi.setRowData(data);
        });
      }

      updateSuppliersContractVolumesChartConfig() {
        this.ClientsAnalyticsService.getSuppliersContractVolumesChartConfig().then(
          (data: any) => (this.suppliersContractVolumesChartConfig = data),
        );
      }

      getBuyersContractVolumesTableOptions() {
        return {
          tableName: 'responsibles',
          filterLevel: this.filterLevel,
          columnDefs: [
            {
              columnName: 'buyer__name',
              title: this.gettext('company'),
            },
            {
              columnName: 'volume__sum',
              cellTemplate: html`{[{ item.volume__sum || 0 | number:2 }]}
                <span class="smaller-label label-opacity"
                  >{[{ item.volume_percentage || 0 | number:1 }]}%</span
                >`,
              title: this.gettext('volume'),
            },
            {
              columnName: 'contracts',
              title: this.gettext('contracts'),
            },
          ],
          rowData: [],
        };
      }

      onBuyersContractVolumesTableInit(event: any) {
        this.buyersContractVolumesTableApi = event.api;
        this.ClientsAnalyticsService.getBuyersContractVolumes().then((data: any) => {
          this.buyersContractVolumesTableApi.setRowData(data);
        });
      }

      updateBuyersContractVolumesChartConfig() {
        this.ClientsAnalyticsService.getBuyersContractVolumesChartConfig().then(
          (data: any) => (this.buyersContractVolumesChartConfig = data),
        );
      }

      getExportersContractVolumesTableOptions() {
        return {
          tableName: 'responsibles',
          filterLevel: this.filterLevel,
          columnDefs: [
            {
              columnName: 'exporters__name',
              title: this.gettext('company'),
            },
            {
              columnName: 'volume__sum',
              cellTemplate: html`{[{ item.volume__sum || 0 | number:2 }]}
                <span class="smaller-label label-opacity"
                  >{[{ item.volume_percentage || 0 | number:1 }]}%</span
                >`,
              title: this.gettext('volume'),
            },
            {
              columnName: 'contracts',
              title: this.gettext('contracts'),
            },
          ],
          rowData: [],
        };
      }

      onExportersContractVolumesTableInit(event: any) {
        this.exportersContractVolumesTableApi = event.api;
        this.ClientsAnalyticsService.getExportersContractVolumes().then((data: any) => {
          this.exportersContractVolumesTableApi.setRowData(data);
        });
      }

      updateExportersContractVolumesChartConfig() {
        this.ClientsAnalyticsService.getExportersContractVolumesChartConfig().then(
          (data: any) => (this.exportersContractVolumesChartConfig = data),
        );
      }

      getSuppliersCanceledContractsTableOptions() {
        return {
          tableName: 'responsibles',
          filterLevel: this.filterLevel,
          columnDefs: [
            {
              columnName: 'supplier__name',
              title: this.gettext('company'),
            },
            {
              columnName: 'volume__sum',
              cellTemplate: '{[{ item.volume__sum || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
            {
              columnName: 'contracts',
              title: this.gettext('contracts'),
            },
          ],
          rowData: [],
        };
      }

      onSuppliersCanceledContractsTableInit(event: any) {
        this.suppliersCanceledContractTableApi = event.api;
        this.ClientsAnalyticsService.getSuppliersCanceledContracts().then((data: any) => {
          this.suppliersCanceledContractTableApi.setRowData(data);
        });
      }
    },
  ],
};
