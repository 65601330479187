import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import type { FinancePositionListContainerProps } from '~/features/finances/finance-position/components/finance-position-container/finance-position-list.model';
import { FinancePositionListModel } from '~/features/finances/finance-position/components/finance-position-container/finance-position-list.model';
import { useComponent } from '~/shared/lib/components';

export const FinancePositionListContainer = (props: FinancePositionListContainerProps) => {
  const { ComponentProvider } = useComponent(props, FinancePositionListModel);

  return (
    <ComponentProvider>
      <EntityListContainer />
    </ComponentProvider>
  );
};
