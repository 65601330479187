import React from 'react';

import { EntriesRangeInfo } from './components/entries-range-info/entries-range-info';
import { PaginationButtonsBar } from './components/pagination-buttons-bar/pagination-buttons-bar';
import { perPageSizeOptions } from './lib/constants';
import { calculatePaginationData } from './lib/pagination-calculations';

export const Pagination: React.FC<{
  currentPageChanged: (page: number) => void;
  pageSizeChanged: (pageSize: number, pageCurrent: number) => void;
  recordsTotal: number;
  pageCurrent?: number;
  pageSize?: number;
  pageSizeOptions?: number[];
}> = ({
  recordsTotal,
  pageCurrent = 1,
  pageSize = 25,
  pageSizeOptions = perPageSizeOptions,
  pageSizeChanged,
  currentPageChanged,
}) => {
  const { pageTotal, from, to, shouldShowPaginationButtonsBar } = calculatePaginationData({
    pageCurrent,
    recordsTotal,
    pageSize,
  });

  return (
    <section
      data-testid="pagination"
      className="bg-background-main-primary !flex items-center justify-between gap-1 py-0.5"
    >
      <EntriesRangeInfo
        pageSizeOptions={pageSizeOptions}
        recordsTotal={recordsTotal}
        from={from}
        to={to}
        pageSizeChanged={pageSizeChanged}
        pageSize={pageSize}
        pageCurrent={pageCurrent}
      />
      {shouldShowPaginationButtonsBar && (
        <PaginationButtonsBar
          pageTotal={pageTotal}
          pageCurrent={pageCurrent}
          onPageClick={currentPageChanged}
        />
      )}
    </section>
  );
};
