import React from 'react';

import { t } from '~/shared/lib/i18n';
import { cn } from '~/shared/lib/utils';
import { Input } from '~/shared/ui/kit/input';
import { Tooltip } from '~/shared/ui/kit/tooltip';

import { type DataTypeProps, messageIntent } from '../../lib';

export const UrlWrite: React.FC<DataTypeProps<'url'>> = ({
  value,
  disabled,
  onChange,
  message,
  typeExtra,
}) => {
  const inputElement = (
    <Input
      type="url"
      intent={message ? 'danger' : 'transparent'}
      defaultValue={value}
      onBlur={(e) => onChange?.(e.target.value)}
      disabled={disabled}
      fullWidth
      {...typeExtra?.inputProps}
    />
  );

  return (
    <div className={cn('relative w-full', message && messageIntent[message?.type])}>
      {message ? (
        <Tooltip content={t(message?.text ?? '')} side="bottom" sideOffset={4}>
          <div>{inputElement}</div>
        </Tooltip>
      ) : (
        inputElement
      )}
    </div>
  );
};
