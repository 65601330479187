import type ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import template from './bill-of-lading-list-table-view.html?raw';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { BillOfLadingService } from '^/app/execution/components/transport/bill-of-lading/bill-of-lading.service';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import { html } from '^/shared/utils';

export const BillOfLadingListTableView = {
  bindings: {
    billsOfLading: '<',
    count: '<',
    total: '<',
    filterLevel: '<',
    applyFilters: '&',
    saveItem: '&',
    destroy: '&',
    updateData: '&',
    updateItem: '&',
    openDocumentModal: '&',
    openBlModal: '&',
    setHovering: '&',
    generateDocument: '&',
    tableName: '<?',
    onContractSelect: '&',
    readonly: '<',
    numberInput: '<?',
    invoiceType: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'gettext',
    'LogisticsService',
    'GtUtils',
    '$window',
    'BillOfLadingService',
    'CustomValuesService',
    class {
      $scope: ng.IScope;
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      BillOfLadingService: BillOfLadingService;
      CustomValuesService: CustomValuesService;
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      applyFilters: any;
      billsOfLading: any;
      count: number;
      destroy: any;
      filterLevel = 'bill-of-lading-list-table-view';
      generateDocument: any;
      gettext: ng.gettext.gettextFunction;
      invoiceType?: string;
      numberInput?: boolean;
      onContractSelect: any;
      openBlModal: any;
      openDocumentModal: any;
      readonly = false;
      saveItem: any;
      selection: any;
      tableApi: any;
      tableData: any;
      tableName = 'bill-of-lading';
      tableOptions: any;
      total: any;
      updateData: any;
      updateItem: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        LogisticsService: LogisticsService,
        GtUtils: GtUtilsService,
        $window: ng.IWindowService,
        BillOfLadingService: BillOfLadingService,
        CustomValuesService: CustomValuesService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.LogisticsService = LogisticsService;
        this.GtUtils = GtUtils;
        this.$window = $window;
        this.BillOfLadingService = BillOfLadingService;
        this.CustomValuesService = CustomValuesService;

        this.billsOfLading = [];
        this.count = 0;
        this.selection = false;
        this.tableOptions = {};
        this.tableData = {};
      }

      $onInit() {
        this.$scope.$on(
          'invoicing-toggled',
          (event, data: { invoiceType: string; numberInput: boolean; selection: boolean }) => {
            this.invoiceType = data.invoiceType;
            this.numberInput = data.numberInput;
            this.selection = data.selection;

            this.setTableOptions().catch(errorHandler);
          },
        );
      }

      $onChanges(changes: any) {
        if (changes.billsOfLading || changes.count || changes.total) {
          this.setTableOptions().catch(errorHandler);
          this.tableData = {
            rows: this.billsOfLading,
            count: this.count,
            total: this.total,
          };
        }
      }

      getCustomFieldTableColumns() {
        return this.CustomValuesService.getCustomFieldTableColumns({
          purpose_model: 'billoflading',
        });
      }

      cloneBl(billOflading: any) {
        Object.assign(this.tableData.rows[0], billOflading, {
          author: undefined,
          author_avatar: undefined,
          author_first_name: undefined,
          create_time: undefined,
          editor: undefined,
          editor_avatar: undefined,
          editor_first_name: undefined,
          update_time: undefined,
          id: undefined,
          invoices: [],
        });
      }

      addVoyagePorts(billOflading: any) {
        this.LogisticsService.Voyage.get({ id: billOflading.voyage }).$promise.then(
          (voyage: any) => {
            Object.assign(this.tableData.rows[this.tableData.rows.indexOf(billOflading)], {
              port_destination: voyage.port_destination,
              port_destination_name: voyage.port_destination_name,
              port_loading: voyage.port_loading,
              port_loading_name: voyage.port_loading_name,
              port_destination_intermediate: voyage.port_destination_intermediate,
              port_destination_intermediate_name: voyage.port_destination_intermediate_name,
              port_loading_intermediate: voyage.port_loading_intermediate,
              port_loading_intermediate_name: voyage.port_loading_intermediate_name,
            });
          },
        );
      }

      openVoyageModal(billOflading: any) {
        return this.LogisticsService.voyageModal({ id: billOflading.voyage });
      }

      setTableOptions() {
        const options: any = {
          tableName: this.tableName,
          tableClass: 'contract-charges-table bill-of-lading-table table-with-inline-add',
          applyFilters: this.applyFilters,
          selection: this.selection,
          numberInput: this.numberInput,
          configurable: true,
          showMore: true,
          changePageSize: true,
          numberInputCellTitle: 'Quantity to be invoiced',
          templateArgs: {
            saveItem: (item: any) => this.saveItem({ item: item }),
            destroy: (item: any) => this.destroy({ item: item }),
            cloneBl: (item: any) => this.cloneBl(item),
            updateData: () => this.updateData(),
            updateItem: (item: any) => this.updateItem({ item: item }),
            openDocumentModal: (object: any, contentType: any) =>
              this.openDocumentModal({ object: object, content_type: contentType }),
            openBlModal: (item: any, tab: string) => this.openBlModal({ item: item, tab: tab }),
            setHovering: (value: any) => this.tableApi.setHovering({ value: value }),
            generateDocument: (id: number) => this.generateDocument({ id: id }),
            addVoyagePorts: (item: any) => this.addVoyagePorts(item),
            openVoyageModal: (item: any) => this.openVoyageModal(item),
            onContractSelect: (item: any) => this.onContractSelect({ item: item }),
            scrollToTop: () => this.$window.scrollTo(0, 100),
            invoiceType: this.invoiceType,
            $rootScope: this.$rootScope,
            readonly: this.readonly,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          selectionCellTemplate: html`<input
              type="checkbox"
              ng-if="
                item.id
                && args.invoiceType == 'incoming'
                && item.purchase_contract
                && item.quantity_to_be_invoiced_incoming
              "
              ng-model="item.$_selected"
            />
            <input
              type="checkbox"
              ng-if="
                item.id
                && args.invoiceType == 'outgoing'
                && item.sale_contract
                && item.quantity_to_be_invoiced_outgoing
              "
              ng-model="item.$_selected"
            />`,
          numberInputCellTemplate: html`<input
              class="form-control"
              type="number"
              ng-if="item.id && args.invoiceType == 'incoming'"
              ng-model="item.quantity_to_be_invoiced_incoming"
            />
            <input
              class="form-control"
              type="number"
              ng-if="item.id && args.invoiceType == 'outgoing'"
              ng-model="item.quantity_to_be_invoiced_outgoing"
            />`,
          columnDefs: [
            {
              columnName: 'number',
              class: 'td-left-align',
              title: this.gettext('B/L number'),
              cellTemplate: html`
                <span ng-if="$ctrl.edit || !item.id">
                  <input class="form-control" type="text" ng-model="item.number" />
                </span>
                <span ng-if="!$ctrl.edit && item.id"> # {[{ item.number || '---' }]}</span>
              `,
              filters: [
                {
                  type: 'text',
                  predicate: 'number',
                },
              ],
            },
            {
              columnName: 'actions',
              class: 'td-right-align no-blur',
              cellTemplate: html`<span ng-show="!$root.user.settings.ENABLE_INLINE_ADD">
                  <a
                    class="btn btn-xs btn-blue-border"
                    ng-hide="$ctrl.edit || !item.id"
                    ng-click="args.openBlModal(item)"
                  >
                    <i class="fa fa-pencil-square"></i>
                  </a>
                </span>
                <span ng-show="$root.user.settings.ENABLE_INLINE_ADD">
                  <a
                    class="btn btn-xs btn-blue-border"
                    ng-hide="$ctrl.edit || !item.id"
                    ng-click="$ctrl.edit=true"
                  >
                    <i class="fa fa-pencil-square"></i>
                  </a>
                </span>
                <span ng-hide="$ctrl.edit || !item.id">
                  <a
                    class="btn btn-xs btn-dark-border"
                    ng-click="args.openBlModal(item, 'invoices')"
                  >
                    <i class="fa fa-credit-card"></i>
                  </a>
                  <a class="btn btn-xs btn-blue-border" ng-click="args.generateDocument(item.id)">
                    <i class="fa fa-file"></i>
                  </a>
                  <a
                    ng-click="args.cloneBl(item); args.scrollToTop()"
                    class="btn btn-xs btn-blue-border"
                  >
                    <i class="fa fa-files-o"></i>
                  </a>
                  <a
                    permission
                    permission-only="'add_document'"
                    class="btn btn-xs btn-green-border"
                    ng-click="args.openDocumentModal(item, 'billoflading')"
                  >
                    <i class="fa fa-upload"></i>
                  </a>
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <a
                    class="btn btn-md btn-success btn_success"
                    ng-if="item.id"
                    ng-click="args.updateItem(item);$ctrl.edit = false"
                    ng-disabled="args.readonly"
                  >
                    <i class="fa fa-floppy-o"></i>
                  </a>
                  <a
                    class="btn btn-md btn-success btn_success col-xs-12"
                    ng-if="!item.id"
                    ng-click="args.saveItem(item)"
                    ng-disabled="args.readonly"
                  >
                    <i class="fa fa-floppy-o"></i> <translate>Save</translate>
                  </a>
                  <a class="btn btn-md" ng-if="item.id" ng-click="$ctrl.edit = false">
                    <i class="fa fa-times"></i>
                  </a>
                  <a
                    class="btn btn-md btn-danger btn_danger"
                    ng-if="item.id"
                    ng-click="args.destroy(item.id);$ctrl.edit = false"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </span>`,
              title: this.gettext('Actions'),
            },
            {
              columnName: 'date',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  <i class="fa fa-calendar"></i>
                  {[{ item.date | date:'dd.MM.yy' || '---' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-date-select
                    date-model="item.date"
                    use-watch="true"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-date-select>
                </span>`,
              title: this.gettext('Date'),
            },
            {
              columnName: 'sale_execution_period',
              class: 'td-left-align',
              cellTemplate: html`
                <span
                  ng-if="!$ctrl.edit && item.id && item.sale_contract_date_of_execution"
                  class="tooltip smaller-label"
                  data-tip="{[{ item.sale_contract_date_of_execution | date:'dd/MM/yy' || '---'  }]} -
              {[{ item.sale_contract_end_of_execution | date:'dd/MM/yy' || '---'  }]}"
                >
                  <i class="fa fa-calendar"></i>
                  {[{ item.sale_contract_date_of_execution | date:'MMM' || '---' }]}
                  <div class="clearfix"></div>
                  <i class="fa fa-calendar"></i>
                  {[{ item.sale_contract_end_of_execution | date:'MMM' || '---' }]}
                </span>
              `,
              title: this.gettext('Sales execution'),
            },
            {
              columnName: 'container_number',
              title: this.gettext('# of containers'),
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="item.id"> {[{ item.container_number }]} </span>`,
            },
            {
              title: this.gettext('Volume'),
              columnName: 'volume',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.volume || 0 | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <input class="form-control" gt-clear-input type="number" ng-model="item.volume" />
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.logistics_volume_received">
                  {[{ item.logistics_volume_received | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
              totalTemplate: html`
                {[{ item.total_volume_sum || 0 | number:3 }]}
                <span class="smaller-label"
                  >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                >
              `,
            },
            {
              title: this.gettext('Unloading volume'),
              columnName: 'unloadingVolume',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.unloading_volume || 0 | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <input
                    class="form-control"
                    gt-clear-input
                    type="number"
                    ng-model="item.unloading_volume"
                  />
                </span>
              `,
              totalTemplate: html`
                {[{ item.unloading_volume_sum || 0 | number:3 }]}
                <span class="smaller-label"
                  >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                >
              `,
            },
            {
              title: this.gettext('Losses volume'),
              columnName: 'lossesVolume',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span
                  ng-if="!$ctrl.edit && item.id"
                  ng-class="{'label label_danger': item.losses_volume < 0}"
                >
                  {[{ item.losses_volume || 0 | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
              totalTemplate: html`
                {[{ item.losses_volume_sum || 0 | number:3 }]}
                <span class="smaller-label"
                  >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                >
              `,
            },
            {
              title: this.gettext('Logistic volume'),
              columnName: 'logistics_purchase_contract_data',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span
                  ng-repeat="contract in item.logistics_purchase_contract_data"
                  ng-if="contract.volume_received_sum"
                >
                  <a ui-sref="gt.page.contract({id: contract.id })">
                    <i class="fa fa-file-text-o"></i> {[{ contract.contract_number || 'N/A' |
                    cut:true:30:' ' }]} - {[{ contract.volume_received_sum | number: 3 }]} ({[{
                    contract.true_volume | number: 3 }]})
                    <span class="smaller-label"
                      >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                    >
                    <div class="clearfix"></div>
                  </a>
                </span>
                <div class="clearfix"></div>
                <a
                  ng-href="#/logistics/transport?billoflading_list={[{ item.id }]}"
                  class="pull-right hover-element btn-link"
                  target="_blank"
                >
                  <translate>Show detail</translate><i class="fa fa-arrow-right"></i>
                </a>
              `,
            },
            {
              title: this.gettext('Logistic purchase contract'),
              columnName: 'logistics_purchase_contracts_numbers',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span
                  ng-repeat="contract in item.logistics_purchase_contract_data"
                  ng-if="item.logistics_purchase_contract_data"
                >
                  <span ng-if="contract.contract_number">
                    <a ui-sref="gt.page.contract({id: contract.id })" class="btn-link">
                      <i class="fa fa-file-text-o"></i> {[{ contract.contract_number | cut:true:30:'
                      ' }]}
                    </a>
                    <div class="clearfix"></div>
                  </span>
                </span>
              `,
            },
            {
              title: this.gettext('Incoming invoices'),
              columnName: 'incoming_invoices',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  <span
                    ng-repeat="invoice in item.invoices track by $index"
                    ng-if="invoice.invoice_type == 'incoming'"
                  >
                    <span
                      class="label"
                      ng-class="{
                      'label_default': invoice.status == 'paid',
                      'label_success': invoice.status == 'new',
                      'label_warning': invoice.status == 'process'
                    } "
                      style="margin-left: 2px"
                    >
                      <a ui-sref="gt.page.payment({id: invoice.id })" class="btn-link">
                        <i class="fa fa-credit-card-alt"></i> {[{ invoice.number | cut:true:30:' '
                        }]} {[{ invoice.status | translate }]}
                      </a>
                    </span>
                    <div class="clearfix"></div>
                  </span>
                </span>
              `,
            },
            {
              title: this.gettext('Outgoing invoices'),
              columnName: 'outgoing_invoices',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  <span
                    ng-repeat="invoice in item.invoices track by $index"
                    ng-if="invoice.invoice_type == 'outgoing'"
                  >
                    <span
                      class="label"
                      ng-class="{
                      'label_default': invoice.status == 'paid',
                      'label_success': invoice.status == 'new',
                      'label_warning': invoice.status == 'process'
                    } "
                      style="margin-left: 2px"
                    >
                      <a ui-sref="gt.page.payment({id: invoice.id })" class="btn-link">
                        <i class="fa fa-credit-card-alt"></i> {[{ invoice.number | cut:true:30:' '
                        }]} {[{ invoice.status | translate }]}
                      </a>
                    </span>
                    <div class="clearfix"></div>
                  </span>
                </span>
              `,
            },
            {
              title: this.gettext('inc inv qty'),
              columnName: 'quantity_invoiced_incoming',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span ng-if="item.quantity_invoiced_incoming">
                  {[{ item.quantity_invoiced_incoming | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
            },
            {
              title: this.gettext('Export contract'),
              columnName: 'export_contract',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span ng-if="item.export_contract_number">
                  <a ui-sref="gt.page.contract({id: item.export_contract_id })" class="btn-link">
                    {[{ item.export_contract_number }]}
                  </a>
                </span>
              `,
            },
            {
              title: this.gettext('out inv qty'),
              columnName: 'quantity_invoiced_outgoing',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span ng-if="item.quantity_invoiced_outgoing">
                  {[{ item.quantity_invoiced_outgoing | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
            },
            {
              title: this.gettext('Costs invoices'),
              columnName: 'costs_invoices',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  <div
                    class="btn-link"
                    ng-if="item.cost_invoices_data && item.cost_invoices_data.length > 3"
                    ng-click="item.CostsInvoiceExpanded = !item.CostsInvoiceExpanded"
                  >
                    <i class="fa fa-credit-card-alt"></i> {[{item.cost_invoices_data.length}]}
                  </div>
                  <div ng-if="item.cost_invoices_data && item.cost_invoices_data.length <= 3">
                    <div ng-repeat="invoice in item.cost_invoices_data track by $index">
                      <span
                        class="label"
                        ng-class="{
                'label_default': invoice.status == 'paid',
                'label_success': invoice.status == 'new',
                'label_warning': invoice.status == 'process'
              } "
                        style="margin-left: 2px"
                      >
                        <a ui-sref="gt.page.payment({id: invoice.id })" class="btn-link">
                          <i class="fa fa-credit-card-alt"></i> {[{ invoice.number | cut:true:30:' '
                          }]} {[{ invoice.status | translate }]}
                        </a>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>

                  <div ng-if="item.CostsInvoiceExpanded">
                    <div class="clearfix"></div>
                    <div ng-repeat="invoice in item.cost_invoices_data track by $index">
                      <span
                        class="label"
                        ng-class="{
                'label_default': invoice.status == 'paid',
                'label_success': invoice.status == 'new',
                'label_warning': invoice.status == 'process'
              } "
                        style="margin-left: 2px"
                      >
                        <a ui-sref="gt.page.payment({id: invoice.id })" class="btn-link">
                          <i class="fa fa-credit-card-alt"></i> {[{ invoice.number | cut:true:30:' '
                          }]} {[{ invoice.status | translate }]}
                        </a>
                      </span>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </span>
              `,
            },
            {
              title: this.gettext('margin'),
              columnName: 'margin',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span
                  ng-if="item.margin && !$ctrl.edit && item.id"
                  class="label"
                  ng-class="{
                'label_danger': item.margin < 0,
                'label_success': item.margin > 0
              }"
                >
                  {[{ item.margin | number: 2 }]}
                </span>
              `,
              totalTemplate: html`{[{ item.margin_sum || 0 | number:2 }]}`,
            },
            {
              columnName: 'loading_port',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  <i class="fa fa-anchor"></i> {[{ item.port_loading_name || '---' | cut:true:30:' '
                  }]}
                  <div class="clearfix"></div>
                  <span class="label label_default smaller-label" ng-if="item.eta">
                    ETA : {[{item.eta || "---" | date:'dd/MM/yy HH:mm'}]}
                  </span>
                  <span class="label label_default smaller-label" ng-if="item.ets">
                    ETS: {[{item.ets || "---" | date:'dd/MM/yy HH:mm'}]}
                  </span>
                </span>
                <span ng-if="!$ctrl.edit && item.id && item.port_loading_intermediate_name">
                  <i class="fa fa-anchor"></i> {[{ item.port_loading_intermediate_name || '---' |
                  cut:true:30:' ' }]}
                  <div class="clearfix"></div>
                  <span class="label label_default smaller-label" ng-if="item.eta_intermediate">
                    ETA : {[{item.eta_intermediate || "---" | date:'dd/MM/yy HH:mm'}]}
                  </span>
                </span>
              `,
              title: this.gettext('Loading port'),
            },
            {
              columnName: 'destination_port',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  <i class="fa fa-anchor"></i> {[{ item.port_destination_name || '---' |
                  cut:true:30:' ' }]}
                  <div class="clearfix"></div>
                  <span class="label label_default smaller-label" ng-if="item.eta_destination">
                    ETA : {[{item.eta_destination || "---" | date:'dd/MM/yy HH:mm'}]}
                  </span>
                </span>
                <div class="clearfix"></div>
                <span ng-if="!$ctrl.edit && item.id && item.port_destination_intermediate_name">
                  <i class="fa fa-anchor"></i> {[{ item.port_destination_intermediate_name || '---'
                  | cut:true:30:' ' }]}
                  <div class="clearfix"></div>
                  <span
                    class="label label_default smaller-label"
                    ng-if="item.eta_destination_intermediate"
                  >
                    ETA : {[{item.eta_destination_intermediate || "---" | date:'dd/MM/yy HH:mm'}]}
                  </span>
                </span>
              `,
              title: this.gettext('Destination port'),
            },
            {
              columnName: 'place',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.place_of_destination_name | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.place_of_destination"
                    placeholder="'Place of destination'|translate"
                    resource-name="'logistics.placeofdestination'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
              `,
              title: this.gettext('Place of destination'),
            },
            {
              columnName: 'notify_party',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.notify_party_name | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.notify_party"
                    placeholder="'Notify party'|translate"
                    resource-name="'clients.clientrole'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
              title: this.gettext('Notify party'),
            },
            {
              columnName: 'notify_party_second',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.notify_party_second_name | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.notify_party_second"
                    placeholder="'Notify party 2'|translate"
                    resource-name="'clients.clientrole'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
              title: this.gettext('Notify party 2'),
            },
            {
              columnName: 'notify_party_third',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.notify_party_third_name | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.notify_party_third"
                    placeholder="'Notify party 3'|translate"
                    resource-name="'clients.clientrole'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
              title: this.gettext('Notify party 3'),
            },
            {
              columnName: 'consignee',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.consignee_name | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.consignee"
                    placeholder="'Consignee'|translate"
                    resource-name="'clients.clientrole'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
              title: this.gettext('Consignee'),
            },
            {
              columnName: 'cargo',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.sale_contract_commodity_title && !$ctrl.edit && item.id">
                  {[{ item.sale_contract_commodity_title | cut:true:30:' ' }]}
                </span>
              `,
              title: this.gettext('Commodity'),
            },
            {
              columnName: 'sale_contract_status',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.sale_contract_status && !$ctrl.edit && item.id">
                  <span
                    class="label"
                    ng-class="{'label_success': item.sale_contract_status == 'new',
                'label_default': item.sale_contract_status == 'executed',
                'label_danger': item.sale_contract_status == 'cancelled',
                'label_warning': item.sale_contract_status == 'delivery_period' || item.sale_contract_status == 'cargo_executed' || item.sale_contract_status == 'shipment'}"
                  >
                    <span ng-if="item.sale_contract_status == 'new'">
                      <translate>New</translate>
                    </span>
                    <span ng-if="item.sale_contract_status == 'washout'">
                      <translate>Washout</translate>
                    </span>
                    <span ng-if="item.sale_contract_status == 'shipment'">
                      <translate>In progress</translate>
                    </span>
                    <span ng-if="item.sale_contract_status == 'delivery_period'">
                      <translate>Delivery period</translate>
                    </span>
                    <span ng-if="item.sale_contract_status == 'cargo_executed'">
                      <translate>Cargo executed</translate>
                    </span>
                    <span ng-if="item.sale_contract_status == 'executed'">
                      <translate>Executed</translate>
                    </span>
                    <span ng-if="item.sale_contract_status == 'cancelled'">
                      <translate>Cancelled</translate>
                    </span>
                  </span>
                </span>
              `,
              title: this.gettext('Sale status'),
            },
            {
              columnName: 'purchase_contract_status',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.purchase_contract_status && !$ctrl.edit && item.id">
                  <span
                    class="label"
                    ng-class="{'label_success': item.purchase_contract_status == 'new',
                'label_default': item.purchase_contract_status == 'executed',
                'label_danger': item.purchase_contract_status == 'cancelled',
                'label_warning': item.purchase_contract_status == 'delivery_period' || item.purchase_contract_status == 'cargo_executed' || item.purchase_contract_status == 'shipment'}"
                  >
                    <span ng-if="item.purchase_contract_status == 'new'">
                      <translate>New</translate>
                    </span>
                    <span ng-if="item.purchase_contract_status == 'washout'">
                      <translate>Washout</translate>
                    </span>
                    <span ng-if="item.purchase_contract_status == 'shipment'">
                      <translate>In progress</translate>
                    </span>
                    <span ng-if="item.purchase_contract_status == 'delivery_period'">
                      <translate>Delivery period</translate>
                    </span>
                    <span ng-if="item.purchase_contract_status == 'cargo_executed'">
                      <translate>Cargo executed</translate>
                    </span>
                    <span ng-if="item.purchase_contract_status == 'executed'">
                      <translate>Executed</translate>
                    </span>
                    <span ng-if="item.purchase_contract_status == 'cancelled'">
                      <translate>Cancelled</translate>
                    </span>
                  </span>
                </span>
              `,
              title: this.gettext('Purchase status'),
            },
            {
              columnName: 'purchase_contracts',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  <a ui-sref="gt.page.contract({id: item.purchase_contract })" class="btn-link">
                    <i class="fa fa-arrow-down"></i> {[{ item.purchase_contract_number || '---' |
                    cut:true:30:' ' }]}
                    <span
                      class="label smaller-label label_info"
                      ng-if="item.purchase_deal_type == 'brokerage'"
                    >
                      {[{ item.purchase_deal_type }]}
                    </span>
                  </a>

                  <span class="clearfix"></span>
                  <span class="smaller-label label-smoke">
                    <i class="fa fa-bookmark-o"></i> {[{ item.purchase_contract_supplier_name ||
                    '---' | cut:true:30:' ' }]}
                  </span>
                  <span
                    class="smaller-label label-smoke"
                    ng-if="item.purchase_contract_buyer_name && item.sale_contract_deal_type == 'brokerage'"
                  >
                    <i class="fa fa-bookmark"></i> {[{ item.purchase_contract_buyer_name || '---' |
                    cut:true:30:' ' }]}
                  </span>
                  <span
                    class="smaller-label label-smoke smaller-label"
                    ng-if="item.purchase_contract_payment_conditions_option_title"
                  >
                    <i class="fa fa-credit-card-alt"></i> {[{
                    item.purchase_contract_payment_conditions_option_title || '---' | cut:true:20:'
                    ' }]}
                  </span>
                  <span class="smaller-label label-smoke" ng-if="item.purchase_contract_farm_name">
                    <i class="fa fa-building-o"></i> {[{ item.purchase_contract_farm_name || '---' |
                    cut:true:30:' ' }]}
                  </span>
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.purchase_contract"
                    placeholder="'Purchase contract'|translate"
                    resource-name="'contracts.PurchaseContract'"
                    query-params="{
                      with_passport: item.passport,
                      multicontract: item.purchase_multicontract
                    }"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                    on-select="args.onContractSelect"
                    on-select-args="[item]"
                  ></gt-resource-select>
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.purchaseContractDetails" class="smaller-label label-opacity">
                  <!-- {[{ item.purchaseContractDetails }]} -->
                  <span ng-if="item.purchaseContractDetails.multicontract_number">
                    <translate>Multicontract</translate>:
                    {[{item.purchaseContractDetails.multicontract_number}]}
                  </span>
                  <div class="clearfix"></div>
                  <span>
                    <translate>Passports</translate>:
                    {[{item.purchaseContractDetails.sale_passport_title_list +
                    item.purchaseContractDetails.purchase_passport_title_list}]}
                  </span>
                  <div class="clearfix"></div>
                  <span>
                    <translate>Voyages</translate>:
                    {[{item.purchaseContractDetails.sale_voyage_title_list +
                    item.purchaseContractDetails.purchase_voyage_title_list}]}
                  </span>
                </span>
              `,
              title: this.gettext('Purchase contracts'),
              filters: [
                {
                  type: 'ui-select',
                  predicate: 'purchase_contract',
                  label: this.gettext('Purchase'),
                  resource: 'contracts.PurchaseContract',
                },
              ],
            },
            {
              title: this.gettext('Sale contract'),
              columnName: 'sale_contract',
              class: 'td-left-align',
              filters: [
                {
                  type: 'ui-select',
                  predicate: 'sale_contract',
                  label: this.gettext('Sale'),
                  resource: 'contracts.SaleContract',
                },
              ],
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  <a ui-sref="gt.page.contract({id: item.sale_contract })" class="btn-link">
                    <i class="fa fa-arrow-up"></i> {[{ item.sale_contract_number || '---' |
                    cut:true:30:' ' }]}
                    <span
                      class="label smaller-label label_info"
                      ng-if="item.sale_contract_deal_type == 'brokerage'"
                    >
                      {[{ item.sale_contract_deal_type }]}
                    </span>
                  </a>

                  <span class="clearfix"></span>
                  <span class="smaller-label label-smoke">
                    <i class="fa fa-bookmark"></i> {[{ item.sale_contract_buyer_name || '---' |
                    cut:true:30:' ' }]}
                  </span>
                  <span
                    class="smaller-label label-smoke"
                    ng-if="item.sale_contract_supplier_name && item.sale_contract_deal_type == 'brokerage'"
                  >
                    <i class="fa fa-bookmark-o"></i> {[{ item.sale_contract_supplier_name || '---' |
                    cut:true:30:' ' }]}
                  </span>
                  <span class="smaller-label label-smoke" ng-if="item.sale_contract_farm_name">
                    <i class="fa fa-building-o"></i> {[{ item.sale_contract_farm_name || '---' |
                    cut:true:30:' ' }]}
                  </span>
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.sale_contract"
                    placeholder="'Sale contract'|translate"
                    resource-name="'contracts.SaleContract'"
                    query-params="{
                      with_passport: item.passport,
                      multicontract: item.sale_multicontract
                    }"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                    on-select="args.onContractSelect"
                    on-select-args="[item]"
                  ></gt-resource-select>
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.saleContractDetails" class="smaller-label label-opacity">
                  <!-- {[{ item.saleContractDetails }]} -->
                  <span ng-if="item.saleContractDetails.multicontract_number">
                    <translate>Multicontract</translate>:
                    {[{item.saleContractDetails.multicontract_number}]}
                  </span>
                  <div class="clearfix"></div>
                  <span>
                    <translate>Passports</translate>:
                    {[{item.saleContractDetails.sale_passport_title_list +
                    item.saleContractDetails.purchase_passport_title_list}]}
                  </span>
                  <div class="clearfix"></div>
                  <span>
                    <translate>Voyages</translate>:
                    {[{item.saleContractDetails.sale_voyage_title_list +
                    item.saleContractDetails.purchase_voyage_title_list}]}
                  </span>
                </span>
              `,
            },
            {
              title: this.gettext('Sale contract volume'),
              columnName: 'sale_contract_true_volume',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id && item.sale_contract_true_volume">
                  {[{ item.sale_contract_true_volume | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
            },
            {
              columnName: 'payment_conditions_option',
              class: 'td-left-align',
              cellTemplate: html`
                <span
                  ng-if="!$ctrl.edit && item.id && item.sale_contract_payment_conditions_option_title"
                >
                  <i class="fa fa-credit-card-alt"></i> {[{
                  item.sale_contract_payment_conditions_option_title || '---' | cut:true:10:' ' }]}
                </span>
              `,
              title: this.gettext('Sales payment conditions'),
            },
            {
              columnName: 'passports',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  <a ui-sref="gt.page.passport({id: item.passport })" class="btn-link">
                    <i class="fa fa-exchange"></i>
                    {[{ item.passport_title || '---' | cut:true:30:' ' }]}
                  </a>
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.passport"
                    placeholder="'Passport'|translate"
                    resource-name="'passports.Passport'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
              `,
              title: this.gettext('Passport'),
              filters: [
                {
                  type: 'ui-select',
                  predicate: 'passport',
                  label: this.gettext('Passport'),
                  resource: 'passports.Passport',
                },
              ],
            },
            {
              columnName: 'amount_bl',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.amount_bl || 0 | number: 2 }]}
                </span>
              `,
              title: this.gettext('Sales amount'),
              totalTemplate: html`{[{ item.amount_bl_sum || 0 | number:2 }]} `,
            },
            {
              columnName: 'sale_contract_price',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id && item.sale_contract_price">
                  {[{ item.sale_contract_price || 0 | number: 2 }]}
                </span>
              `,
              title: this.gettext('Sales price'),
            },
            {
              columnName: 'purchase_amount_bl',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.purchase_amount_bl || 0 | number: 2 }]}
                </span>
              `,
              title: this.gettext('Purchase amount'),
              totalTemplate: html`{[{ item.purchase_amount_bl_sum || 0 | number:2 }]}`,
            },
            {
              columnName: 'purchase_contract_price',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id && item.purchase_contract_price">
                  {[{ item.purchase_contract_price || 0 | number: 2 }]}
                </span>
              `,
              title: this.gettext('Purchase price'),
            },
            {
              columnName: 'amount_bl_110_percents',
              class: 'td-right-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.amount_bl_110_percents || 0 | number: 2 }]}
                </span>
              `,
              title: this.gettext('Amount BL+10%'),
              totalTemplate: html`{[{ item.amount_bl_110_percents_sum || 0 | number:2 }]} `,
            },
            {
              columnName: 'vessel_name',
              class: 'td-left-align',
              cellTemplate: html`
              <span ng-if="!$ctrl.edit && item.id">
                <a
                  ng-if="item.voyage"
                  class="btn-link"
                  ng-click="args.openVoyageModal(item)"
                >
                <i class="fa fa-ship"></i> {[{ item.voyage_name || item.vessel_name }]}
                </a>
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <gt-resource-select
                  ng-init="args.addVoyagePorts(item)"
                  ng-model="item.voyage"
                  placeholder="'Voyage'|translate"
                  resource-name="'logistics.Voyage'"
                  allow-clear="true"
                  on-open-close="args.setHovering(isOpen)"
                  on-select="args.addVoyagePorts"
                  on-select-args="[item]"
                >
              </gt-resource-select>
                <div class="form-add-btn">
                  <a class="btn btn-xs btn-info col-xs-12" ng-click="args.openVoyageModal({})"> <i class="fa fa-signs-post""></i></a>
              </span>`,
              title: this.gettext('Vessel'),
            },
            {
              title: this.gettext('Documents'),
              columnName: 'documents',
              class: 'td-left-align',
              cellTemplate: html`
                <div
                  class="btn-link"
                  ng-if="item.documents && item.documents.length > 3"
                  ng-click="item.BlDocsExpanded = !item.BlDocsExpanded"
                >
                  <i class="fa fa-download"></i> {[{item.documents.length}]}
                </div>
                <div ng-if="item.documents && item.documents.length <= 3">
                  <div class="clearfix"></div>
                  <div ng-repeat="doc in item.documents">
                    <a href="{[{doc.file}]}" class="btn-link smaller-label" target="_blank">
                      <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                    </a>
                  </div>
                </div>

                <div ng-if="item.BlDocsExpanded">
                  <div class="clearfix"></div>
                  <div ng-repeat="doc in item.documents">
                    <a href="{[{doc.file}]}" class="btn-link smaller-label" target="_blank">
                      <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                    </a>
                    <div class="clearfix"></div>
                  </div>
                </div>
              `,
            },
            {
              columnName: 'additional_info',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.additional_info }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <textarea class="form-control" type="text" ng-model="item.additional_info">
                  </textarea>
                </span>`,
              title: this.gettext('Additional info'),
            },
            {
              columnName: 'carrier',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id"> {[{ item.carrier }]} </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <textarea class="form-control" type="text" ng-model="item.carrier"> </textarea>
                </span>`,
              title: this.gettext('Carrier'),
            },
            {
              columnName: 'is_loi',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span
                  class="label label-info"
                  ng-if="!$ctrl.edit && item.id && item.is_loi"
                >
                  <translate>LOI</translate>
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <input type="checkbox" ng-model="item.is_loi" />
                </span>`,
              title: this.gettext('LOI'),
            },
            {
              title: this.gettext('Costs/gains'),
              columnName: 'costs',
              class: 'td-right-align small-font-cell',
              cellTemplate: html`
                <span ng-if="item.bl_cost_price_per_deal">
                  {[{ item.bl_cost_price_per_deal || 0 | number: 2 }]}
                  {[{item.default_currency_symbol}]}
                  <div
                    class="btn-link"
                    ng-if="item.contracts_costs_data"
                    ng-click="item.CostsExpanded = !item.CostsExpanded"
                  >
                    <i class="fa fa-money"></i> {[{item.contracts_costs_data.length}]}
                    <i
                      class="fa"
                      ng-class="{'fa-caret-down': !item.CostsExpanded, 'fa-caret-up': item.CostsExpanded, }"
                    ></i>
                  </div>
                </span>

                <div class="clearfix"></div>
                <span
                  class="smaller-label"
                  ng-repeat="charge in item.contracts_costs_data"
                  ng-if="item.contracts_costs_data && item.CostsExpanded"
                >
                  {[{ charge.title }]} - {[{ charge.price || 0 | number: 2 }]}
                  {[{item.default_currency_symbol}]}
                  <div class="clearfix"></div>
                </span>
              `,
            },
            {
              title: this.gettext('Export business unit'),
              columnName: 'exportCtrBusinessUnit',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                {[{ item.export_ctr_businessunit_title }]}
              </span>`,
            },
            {
              title: this.gettext('Purchase business unit'),
              columnName: 'purchaseCtrBusinessUnit',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                {[{ item.purchase_ctr_businessunit_title }]}
              </span>`,
            },
            {
              title: this.gettext('Sale business unit'),
              columnName: 'saleCtrBusinessUnit',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                {[{ item.sale_ctr_businessunit_title }]}
              </span>`,
            },
            {
              title: this.gettext('Author'),
              columnName: 'author_full_name',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                {[{ item.author_full_name }]}
              </span>`,
            },
          ],
        };
        return this.getCustomFieldTableColumns()
          .then((columns: any) => {
            options.columnDefs.push(...columns);
            this.tableOptions = options;
            this.$rootScope.$applyAsync();
          })
          .catch(errorHandler);
      }
    },
  ],
};
