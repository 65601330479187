import ng from 'angular';

import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').directive('problemsLogisticRow', directive);

  function directive() {
    return {
      template: require('./problems-logistic-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        logistic: '<',
        onUpdate: '&',
      },
    };
  }

  Controller.$inject = ['LogisticsService'];

  function Controller(this: any, LogisticsService: LogisticsService) {
    const vm = this;
    vm.openLogisticModal = openLogisticModal;

    ////////////////

    function openLogisticModal(logistic: any) {
      LogisticsService.logisticModal(logistic).then(function () {
        vm.onUpdate();
      });
    }
  }
})();
