import { from, shareReplay, switchMap } from 'rxjs';

import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import {
  ContractSerializer,
  ExportContractRecord,
  ExportContractsQueryParams,
} from '~/features/deals/contracts';
import { ExportContractsRepository } from '~/features/deals/contracts/export/services/export-contracts.repository';
import { ExportContract } from '~/shared/api';
import { injectable } from '~/shared/lib/di';

export type ExportContractsListContainerProps = ContainerProps<ExportContractsQueryParams>;

@injectable()
export class ExportContractsListViewModel extends EntityListContainerModel<
  ExportContract,
  ExportContractsQueryParams,
  ExportContractsRepository,
  ExportContractsListContainerProps,
  ExportContractRecord
> {
  entityRepoClass = ExportContractsRepository;

  totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.repo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );
  // @Y-Havryliv ми тут не оперуємо такими поняттями як таблиця чи серіалізатор. Прибери це
  public tableChanged(table: ContractSerializer) {
    this.repo.setDatasources(table);
  }
}
