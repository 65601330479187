import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { type VariantProps, cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '~/shared/lib/utils';

const avatarVariants = cva('relative flex shrink-0 overflow-hidden rounded-full', {
  variants: {
    size: {
      sm: 'h-3.5 w-3.5 text-[6px]',
      md: 'h-4 w-4 text-[8px]',
      lg: 'h-6 w-6 text-[9px]',
      xl: 'h-10 w-10 text-[11px]',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

type AvatarVariants = VariantProps<typeof avatarVariants>;

export const Avatar = React.forwardRef<
  React.ComponentRef<typeof AvatarPrimitive.Root>,
  AvatarVariants & { src: string; alt: string; fallback: React.ReactNode }
>(({ size, src, alt, fallback }, ref) => (
  <AvatarPrimitive.Root ref={ref} className={cn(avatarVariants({ size }))}>
    <AvatarPrimitive.Image className="aspect-square size-full h-full w-full" src={src} alt={alt} />
    <AvatarPrimitive.Fallback className="bg-gray-20 flex size-full h-full w-full items-center justify-center rounded-full leading-1">
      {fallback}
    </AvatarPrimitive.Fallback>
  </AvatarPrimitive.Root>
));
Avatar.displayName = AvatarPrimitive.Root.displayName;
