import ng from 'angular';

import { formatDate, isSameDay } from '~/shared/lib/date';
import { errorHandler } from '~/shared/lib/errors';
import { notify } from '~/shared/lib/notify';

import { type DBLService } from '../../../shared/dbl/dbl.service';
import { type FinancesService } from '../../finances.srv';

import { type FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import { type GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type GtRootScopeService } from '^/app/core/types';
import { type ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { html } from '^/shared/utils';

function Controller(
  this: any,
  $rootScope: GtRootScopeService,
  $scope: ng.IScope,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $window: ng.IWindowService,
  gettext: ng.gettext.gettextFunction,
  FinancesService: FinancesService,
  disbursementbl: any,
  extraData: any,
  GtUtils: GtUtilsService,
  FormFieldParamsService: FormFieldParamsService,
  DBLService: DBLService,
  ClientsService: any,
  ContractsService: ContractsService,
) {
  const vm = this;
  vm.save = save;
  vm.close = close;
  vm.handleUpdate = handleUpdate;
  vm.disbursementbl = disbursementbl || {};
  vm.initDisbursementbl = ng.copy(vm.disbursementbl);
  vm.dblInvoicePositionList = vm.disbursementbl.invoice_positions || [];
  vm.purchaseContract = vm.disbursementbl.purchase_contract || 0;
  vm.saleContract = vm.disbursementbl.sale_contract || 0;
  vm.extraData = extraData || {};
  vm.passPriceConvertation = true;
  vm.openDBLLogisticModal = openDBLLogisticModal;
  vm.openFieldsConfigModal = openFieldsConfigModal;
  vm.contract_buyer = '';
  vm.contract_supplier = '';
  vm.updating = false;
  vm.tab = 'edit';
  vm.localCurrency = FinancesService.getLocalCurrency();
  vm.defaultCurrencyId = FinancesService.getDefaultCurrencyId();

  activate().catch(GtUtils.errorClb);

  ////////////////

  function activate() {
    let func = Promise.resolve();
    if (!vm.disbursementbl.id) {
      if (
        (vm.disbursementbl.sale_contract || vm.disbursementbl.purchase_contract) &&
        vm.disbursementbl.contract_currency
      ) {
        vm.disbursementbl.currency = vm.disbursementbl.contract_currency;
      }
    } else {
      func = func.then(updateData) as any;
    }
    return func.then(setContractValues).then(() => {
      getAmountWithVat();
      updateFields();
      setWatchers();
    });
  }
  function setWatchers() {
    $scope.$watch('vm.disbursementbl.date', function () {
      $scope.$broadcast('dbl-date-changed', vm.disbursementbl.date);

      if (
        vm.disbursementbl.id &&
        isSameDay(vm.disbursementbl.date, vm.initDisbursementbl.date) &&
        vm.disbursementbl.currency === vm.initDisbursementbl.currency
      ) {
        vm.disbursementbl.currency_exchange = vm.initDisbursementbl.currency_exchange;
      } else if (
        !vm.disbursementbl.id &&
        (vm.disbursementbl.currency === vm.defaultCurrencyId || !vm.disbursementbl.date)
      ) {
        clearDblCurrencyExchangeRate();
      } else if (!vm.disbursementbl.id && vm.disbursementbl.currency) {
        setDblCurrencyRate(vm.disbursementbl.currency, vm.disbursementbl.date);
      } else {
        clearDblCurrencyExchangeRate();
      }
    });

    $scope.$watch('vm.disbursementbl.currency', function () {
      if (
        vm.disbursementbl.id &&
        isSameDay(vm.disbursementbl.date, vm.initDisbursementbl.date) &&
        vm.disbursementbl.currency === vm.initDisbursementbl.currency
      ) {
        vm.disbursementbl.currency_exchange = vm.initDisbursementbl.currency_exchange;
      } else if (
        !vm.disbursementbl.id &&
        (vm.disbursementbl.currency === vm.defaultCurrencyId || !vm.disbursementbl.currency)
      ) {
        clearDblCurrencyExchangeRate();
      } else if (!vm.disbursementbl.id && vm.disbursementbl.date) {
        setDblCurrencyRate(vm.disbursementbl.currency, vm.disbursementbl.date);
      } else {
        clearDblCurrencyExchangeRate();
      }
    });
    $scope.$watch('vm.disbursementbl.currency_exchange', (newVal: any, oldVal: any) => {
      if (vm.passPriceConvertation) {
        vm.passPriceConvertation = false;
        return false;
      }

      setContractValues(newVal, oldVal).then(getDblCurrency);
      return true;
    });
    $scope.$watch('vm.disbursementbl.purchase_contract', (newVal: any, oldVal: any) => {
      vm.purchaseContract = newVal;
      setContractValues(newVal, oldVal);
      getDefaultSaleContractThroughPassport();
    });
    $scope.$watch('vm.disbursementbl.sale_contract', (newVal: any, oldVal: any) => {
      vm.saleContract = newVal;
      setContractValues(newVal, oldVal);
    });
    $scope.$watch('vm.disbursementbl.price', () => {
      getAmountWithVat();
    });
    $scope.$watch('vm.disbursementbl.volume', () => {
      getAmountWithVat();
    });
    $scope.$watch('vm.disbursementbl.contract_vat_option', () => {
      getAmountWithVat();
    });
    $scope.$watch('vm.disbursementbl.dbl_type', (newVal: any, oldVal: any) => {
      setContractValues(newVal, oldVal).then(getAmountWithVat);
    });
    $scope.$watch('vm.disbursementbl.amount_correction', () => {
      getAmountWithVat();
    });
    $scope.$watch('vm.disbursementbl.contract_option', () => {
      getContractOptionData();
    });
    $scope.$watch('vm.disbursementbl.invoice_positions', (newValue: any) => {
      vm.dblInvoicePositionList = newValue || [];
    });
  }

  function handleUpdate(data: any) {
    if (data.dblInvoicePositionList?.length) {
      vm.disbursementbl.invoice_positions = data.dblInvoicePositionList;
    }
  }

  function updateData() {
    vm.updating = true;
    return FinancesService.DisbursementBL.query(
      { id: vm.disbursementbl.id, serializer: 'modal' },
      function (data: any) {
        vm.disbursementbl = data;
        getAmountWithVat();
        getContractOptionData();
        vm.updating = false;
      },
    ).$promise;
  }

  function getContractOptionData() {
    ContractsService.ContractOptions.get({
      id: vm.disbursementbl.contract_option,
    }).$promise.then((data: any) => {
      vm.disbursementbl.contract_option_program_type = data.program_type;
      getContractOptionPercentages(data);
    });
  }

  function getContractOptionPercentages(contractOption: any) {
    vm.contractOptionPercentages = [];
    if (contractOption.program_type !== 'percentage') {
      return;
    }

    contractOption.positions.forEach((position: any) => {
      if (position.crops.indexOf(vm.disbursementbl.cargo) > -1) {
        vm.contractOptionPercentages = position.months.map((month: any) => {
          return {
            name: month.percentage,
            value: month.percentage,
          };
        });
      }
    });
    updateFields();
  }

  function getAmountWithVat() {
    const vatCoeff =
      (vm.disbursementbl.contract_vat_option && vm.disbursementbl.contract_vat_value) || 0;
    if (!vm.disbursementbl.price || !vm.disbursementbl.volume) {
      vm.disbursementbl.amount_with_vat = 0;
      return;
    }

    FinancesService.applyVat({
      value: vm.disbursementbl.price,
      volume: vm.disbursementbl.volume,
      vat_value: vatCoeff,
    })
      .then((result) => {
        $scope.$apply(() => {
          vm.disbursementbl.amount_with_vat =
            result + parseFloat(vm.disbursementbl.amount_correction || 0);
        });
      })
      .catch(errorHandler);
  }

  function setContractValues(newVal?: any, oldVal?: any) {
    if (!newVal || newVal == oldVal) {
      return Promise.resolve();
    }
    ClientsService.Client.predictions({
      buyer_of_contract: vm.disbursementbl[vm.disbursementbl.dbl_type + '_contract'],
    }).$promise.then(function (data: any) {
      vm.disbursementbl.contract_buyer = data.results.length && data.results[0];
    });
    ClientsService.Client.predictions({
      supplier_of_contract: vm.disbursementbl[vm.disbursementbl.dbl_type + '_contract'],
    }).$promise.then(function (data: any) {
      vm.disbursementbl.contract_supplier = data.results.length && data.results[0];
    });
    return ContractsService.Contract.get({
      id: vm.disbursementbl[vm.disbursementbl.dbl_type + '_contract'],
    }).$promise.then(function (contract: any) {
      vm.disbursementbl.contract_vat_value = contract.VAT_value;
      vm.disbursementbl.contract_vat_option = contract.VAT_option;
      vm.disbursementbl.contract_option =
        vm.disbursementbl.contract_option || contract.contract_option;
      vm.disbursementbl.type_of_activities =
        vm.disbursementbl.type_of_activities || contract.type_of_activities;
      vm.disbursementbl.contractCurrency = contract.currency;
      vm.disbursementbl.contractCurrencyExchange = contract.currency_exchange;
      vm.disbursementbl.sap_orders = contract.sap_orders;

      vm.initDisbursementbl = ng.copy(vm.disbursementbl);
    });
  }

  function getLocalPrice() {
    if (!vm.disbursementbl.contractCurrencyExchange) {
      notify(gettext('The contract does not contain an exchange rate'), 'error');
      return;
    }
    const contract =
      vm.disbursementbl.dbl_type === 'purchase'
        ? vm.disbursementbl.purchase_contract
        : vm.disbursementbl.sale_contract;
    if (contract && vm.disbursementbl.currency_exchange) {
      FinancesService.DisbursementBL.getDisbursementblLocalPrice({
        contract_id: contract,
        dbl_rate_id: vm.disbursementbl.currency_exchange,
      }).$promise.then(
        function (data: any) {
          if (data.local_price) {
            vm.disbursementbl.price = data.local_price;
          }
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }
  }

  function getDblCurrency() {
    return FinancesService.Currency.query({
      id: vm.disbursementbl.currency,
    }).$promise.then(function (data: any) {
      vm.disbursementbl.currencyTitle = data.title;
      if (vm.disbursementbl.currencyTitle !== vm.disbursementbl.contractCurrency) {
        getLocalPrice();
      }
    });
  }

  function setDblCurrencyRate(currency: any, exchangeDate: any) {
    if (vm.disbursementbl.currency === vm.defaultCurrencyId) {
      clearDblCurrencyExchangeRate();
      return;
    }
    getDblCurencyExchangeRate(currency, exchangeDate)
      .then((data: any) => {
        if (data.count === 0) {
          notify(gettext('No currency exchange rate found'), 'error');
          clearDblCurrencyExchangeRate();
          return;
        }
        vm.disbursementbl.currency_exchange = data.results[0].id;
      })
      .catch((error: any) => {
        GtUtils.errorClb(error);
        clearDblCurrencyExchangeRate();
      });
  }

  function getDblCurencyExchangeRate(currency: number, exchangeDate: Date) {
    return FinancesService.getCurrencyExchangeByDate(
      currency,
      formatDate(exchangeDate, 'dd.MM.yyyy'),
    );
  }

  function clearDblCurrencyExchangeRate() {
    vm.disbursementbl.currency_exchange = null;
  }

  function getDefaultSaleContractThroughPassport() {
    if (!vm.disbursementbl.purchase_contract) {
      return;
    }
    ContractsService.Passport.contractsTotals(
      {
        purchase_contract_list: [vm.disbursementbl.purchase_contract],
      },
      function (data: any) {
        const saleContractId = (data.sale_facts_contract_id_list || []).shift();
        vm.disbursementbl.sale_contract = vm.disbursementbl.sale_contract || saleContractId || null;
      },
    );
  }

  function updateFields() {
    FormFieldParamsService.getFields(getFields())
      .then((fields: any) => (vm.fields = fields))
      .catch(console.error);
  }

  function openFieldsConfigModal() {
    FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(() => updateFields());
  }

  function getFormConfig() {
    return getFields();
  }

  function openDBLLogisticModal() {
    return DBLService.dblLogisticModal(vm.disbursementbl, vm.extraData).then(updateData);
  }

  function getFields() {
    const col1: any = {
      className: 'form-group-container col-md-4 col-xs-12',
      fieldGroup: [] as any[],
    };
    const col2: any = {
      className: 'form-group-container col-md-4 col-xs-12',
      fieldGroup: [] as any[],
    };
    const col3: any = {
      className: 'form-group-container col-md-4 col-xs-12',
      fieldGroup: [] as any[],
    };

    col1.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('INFO'),
        },
        fieldGroup: [
          {
            key: 'dbl_type',
            type: 'gt-select',
            defaultValue: 'purchase',
            templateOptions: {
              label: gettext('Type'),
              placeholder: gettext('Sale or Purchase'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: gettext('Purchase'), value: 'purchase' },
                { name: gettext('Sale'), value: 'sale' },
              ],
            },
          },
          {
            key: 'date',
            defaultValue: new Date(),
            type: 'gt-date-select',
            templateOptions: {
              label: gettext('Date'),
              placeholder: gettext('date'),
              type: 'date',
            },
          },
          {
            key: 'number',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Number'),
              placeholder: gettext('Number'),
              type: 'text',
            },
          },
          {
            key: 'status',
            type: 'gt-select',
            defaultValue: 'new',
            templateOptions: {
              label: gettext('Status'),
              placeholder: gettext('Choose status'),
              hint: gettext(
                'New - after creation of disbursement bill of lading. ' +
                  'Process - partly connected to reassignment. ' +
                  'Done - fully connected to reassignment or connected to offset.',
              ),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: gettext('New'), value: 'new' },
                { name: gettext('Ready'), value: 'ready' },
                { name: gettext('Process'), value: 'process' },
                { name: gettext('Done'), value: 'done' },
                { name: gettext('Blocked'), value: 'blocked' },
              ],
            },
            hideExpression: () =>
              !$rootScope.user.settings.SYSTEM_BLOCKS.block_execution_reassignment,
          },
          {
            key: 'price',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: gettext('Price'),
              placeholder: gettext('Price'),
              type: 'number',
              addon: gettext('per 1 mt'),
              required: true,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.logistic_set.length',
            },
          },
          {
            key: 'currency',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Currency'),
              placeholder: gettext('USD, UAH'),
              resource: 'finances.Currency',
              addFunc: () => {
                $window.open('/admin/finances/currency/add/');
              },
              addIcon: GtUtils.getIcon('finances.Currency'),
              addPerms: ['add_currency'],
              required: true,
            },
          },
          {
            key: 'currency_exchange',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Currency rate'),
              resource: 'finances.CurrencyExchange',
              addFunc: () => {
                $window.open('/admin/finances/currencyexchange/add/');
              },
              queryParams: () => {
                return { local_currency: vm.disbursementbl.currency };
              },
              addIcon: GtUtils.getIcon('finances.CurrencyExchange'),
              addPerms: ['add_currencyexchange'],
              required: true,
            },
            expressionProperties: {
              'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                scope.model.currency === FinancesService.getDefaultCurrencyId(),
              'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
                scope.model.currency !== FinancesService.getDefaultCurrencyId(),
            },
          },
          {
            key: 'volume',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: gettext('Volume'),
              placeholder: gettext('Volume'),
              type: 'number',
              addon: gettext('t'),
              required: true,
            },
            validators: {
              notFalse: function ($viewValue: any, $modelValue: any) {
                return ($viewValue || $modelValue || 0) >= 0;
              },
            },
          },
          {
            key: 'cargo',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('COMMODITY'),
              placeholder: gettext('Choose commodity'),
              resource: 'crops.Crop',
              required: true,
              addFunc: () => {
                $window.open('/admin/crops/crop/add/');
              },
              addIcon: GtUtils.getIcon('crops.Crop'),
              addPerms: ['add_crop'],
            },
            validation: {
              show: true,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.logistic_set.length',
            },
          },
          {
            key: 'has_docs_copy',
            defaultValue: true,
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('Has docs copy'),
            },
          },
          {
            key: 'has_docs_original',
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('Has docs original'),
            },
          },
          {
            key: 'is_tax_return_registered',
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('tax return registered'),
            },
          },
          {
            key: 'is_sap_registered',
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('sap registered'),
            },
          },
          {
            key: 'sap_orders',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: gettext('SAP orders'),
              placeholder: gettext('SAP orders'),
              resource: 'contracts.SapOrder',
              queryParams: () => {
                return {
                  contract: vm.extraData.contractId,
                };
              },
            },
          },
          {
            key: 'type_of_activities',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: gettext('Activity types'),
              resource: 'core.TypeOfActivity',
            },
          },
        ],
      },
    ];

    col2.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('AMOUNT'),
        },
        fieldGroup: [
          {
            key: 'amount_with_vat',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Amount With Vat'),
              disabled: true,
              type: 'number',
              help: gettext('The cost depends on the VAT tax rate specified in the contract'),
            },
          },
          {
            key: 'amount_correction',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: gettext('Amount correction'),
              type: 'number',
            },
          },
          {
            key: 'purchase_contract',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Purchase contract'),
              placeholder: gettext('Choose purchase contract'),
              resource: 'contracts.PurchaseContract',
              queryParams: () => ({
                multicontract: vm.disbursementbl.purchase_multicontract,
              }),
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'sale_contract',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Sale contract'),
              placeholder: gettext('Choose contract'),
              resource: 'contracts.SaleContract',
              queryParams: () => ({
                multicontract: vm.disbursementbl.sale_multicontract,
              }),
            },
          },
          {
            key: 'services_contract',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Services contract'),
              placeholder: gettext('Choose services contract'),
              resource: 'contracts.ServicesContract',
            },
          },
          {
            template: html`
                <disbursement-bl-consignment
                  disbursementbl-consignments="model.disbursementbl_consignments"
                  disbursementbl-id="model.id"
                  disbursementbl-date="model.date"
                  disbursementbl="model"
                </disbursement-bl-consignment>
              `,
          },
          {
            key: 'terminal',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Terminal'),
              placeholder: gettext('Choose terminal'),
              resource: 'logistics.Terminal',
              queryParams: { is_actual: '1' },
            },
          },
          {
            key: 'additional_info',
            type: 'gt-textarea',
            templateOptions: {
              label: gettext('Additional Info'),
              placeholder: gettext('Specific information about this object'),
              className: 'additional-info',
            },
          },
        ],
      },
    ];

    col3.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('LOYALITY PROGRAM'),
        },
        fieldGroup: [
          {
            key: 'contract_option',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Contract Option'),
              resource: 'contracts.ContractOption',
            },
          },
          {
            template: html`
              <loyality-program-data
                obj="model"
                date-from="model.estimated_date_execution || model.date"
                date-to="model.estimated_date_execution || model.date"
                bonus-percentage="model.premium_bonus_percentage"
              </loyality-program-data>
              `,
            hideExpression: (viewValue: any, modelValue: any, scope: any) =>
              !scope.model.contract_option,
          },
          {
            key: 'estimated_date_execution',
            type: 'gt-date-select',
            templateOptions: {
              label: gettext('Execution estimated month'),
              startView: 'month',
              minView: 'month',
            },
          },
        ],
      },
    ];

    return {
      formName: 'disbursementbl-edit-modal',
      fieldsDef: [col1, col2, col3],
    };
  }

  function save(stayAfterSave: any) {
    vm.form.$invalid = true;
    if (vm.disbursementbl.id) {
      return FinancesService.DisbursementBL.update(
        vm.disbursementbl,
        function (data: any) {
          notify(gettext('Disbursement bill of lading saved'));
          if (stayAfterSave) {
            vm.disbursementbl = data;
            return updateData();
          } else {
            close(data, true);
          }
        },
        _error,
      ).$promise;
    }
    return FinancesService.DisbursementBL.save(
      vm.disbursementbl,
      function (data: any) {
        notify(gettext('Disbursement bill of lading saved'));
        if (stayAfterSave) {
          vm.disbursementbl = data;
          return updateData();
        } else {
          close(data, true);
        }
      },
      _error,
    ).$promise;
  }

  function _error(data: any) {
    if (data.data?.disbursementbl_consignments) {
      const consignmentsError = data.data.disbursementbl_consignments[0][0];
      const matchedError = consignmentsError.match(/string='([^']+)'/);
      if (matchedError?.[1]) {
        const errorMessage = matchedError[1];
        notify(errorMessage, 'error');
      } else {
        notify(consignmentsError, 'error');
      }
    } else {
      notify('A server error occurred. Please contact the administrator.', 'error');
    }

    vm.disbursementbl.errors = data.data;
  }

  function close(data: any, silent: any) {
    if (!silent && !confirm(gettext('Close modal?'))) {
      return;
    }
    $uibModalInstance.close(data || 'cancel');
  }
}

(function () {
  'use strict';
  ng.module('finances.legacy').controller('DisbursementBLModalController', Controller);

  Controller.$inject = [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    '$window',
    'gettext',
    'FinancesService',
    'disbursementbl',
    'extraData',
    'GtUtils',
    'FormFieldParamsService',
    'DBLService',
    'ClientsService',
    'ContractsService',
  ];
})();

export type DisbursementBLModalController = ReturnType<typeof Controller>;
