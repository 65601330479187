import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('company.documents.legacy').controller('GenerateDocxModalController', Controller);

  Controller.$inject = [
    '$window',
    '$uibModalInstance',
    '$scope',
    'DocumentsService',
    'gtFilterService',
    'GtUtils',
    'gettext',
    'modelType',
    'objectId',
    'extra',
  ];

  function Controller(
    this: any,
    $window: ng.IWindowService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $scope: ng.IScope,
    DocumentsService: any,
    gtFilterService: GtFilterService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    modelType: any,
    objectId: number,
    extra: any,
  ) {
    const vm = this;
    vm.close = close;
    vm.templates = [];
    vm.templatesCount = 0;
    vm.objectId = objectId;
    vm.extraParams = extra?.params || {};
    vm.extraObjects = { objects_id: extra?.objects_id || [] };
    vm.downloadDocument = downloadDocument;
    vm.downloadMultipleDocuments = downloadMultipleDocuments;
    vm.filterLevel = 'generate-docx-modal';
    vm.initQueryParams = { model_type: modelType };

    vm.$onInit = function () {
      vm.queryParams = { ...vm.initQueryParams };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateData();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    ////////////////

    function updateData() {
      return DocumentsService.DocxDocument.query(vm.queryParams, function (data: any) {
        vm.templates = data.results;
        vm.templatesCount = data.count;
        vm.selectedTemplate = undefined;
      });
    }

    function downloadMultipleDocuments(params: object) {
      if (vm.extraObjects?.objects_id?.length) {
        vm.extraObjects.objects_id.forEach((objectId: number) => {
          vm.downloadDocument({
            ...params,
            object_id: objectId,
          });
        });
      } else {
        vm.downloadDocument(params);
      }
    }

    function downloadDocument(params: object) {
      GtUtils.overlay('show');
      DocumentsService.DocxDocument.generateDocument({
        ...params,
        ...vm.extraParams,
        ...vm.extraObjects,
      })
        .$promise.then(
          (response: any) => saveFileFromResponse(response),
          (error: any) => {
            if (error.status == 422 && error.data.headers.location) {
              if (!confirm(gettext('You need to verify Google account'))) {
                return false;
              }
              const verificationLink = $window.open(error.data.headers.location, '_blank');
              if (verificationLink == null || typeof verificationLink == 'undefined') {
                alert('Please disable your pop-up blocker in Browser and click again.');
                return false;
              }
              verificationLink.onload = () => {
                verificationLink.close();
                notify('You can work with Google drive');
              };
            }
            const reader = new FileReader();
            reader.onload = () => {
              if (error.data.data.type == 'application/json') {
                GtUtils.errorClb({ data: JSON.parse(reader.result as string) });
              }
            };
            reader.readAsText(new Blob([error.data.data], { type: error.data.data.type }));
          },
        )
        .finally(() => GtUtils.overlay('hide'));
    }

    function saveFileFromResponse(response: any) {
      if (response.data instanceof Blob) {
        const fileName = decodeURIComponent(
          response.headers['content-disposition'].split('=')[1].replace(/"/gi, ''),
        );
        const fileType = `${response.headers['content-type']};charset=utf-8`;
        const blob = new Blob([response.data], { type: fileType });
        const objectUrl = $window.URL || $window.webkitURL;
        const link = ng.element('<a>');
        link.css({ display: 'none' });
        link.attr({
          href: objectUrl.createObjectURL(blob),
          target: '_blank',
          download: fileName,
        });
        link[0].click();
        link.remove();
        objectUrl.revokeObjectURL(blob);
      }
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
