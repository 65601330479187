import template from './fact-pl-list.html?raw';

import type { GtRootScopeService, QueryParams } from '^/app/core/types';

export const FactPlList = {
  bindings: {
    passportId: '<',
    filterLevel: '<',
    initQueryParams: '<',
  },
  template,
  controller: [
    '$rootScope',
    'FactPlListService',
    class {
      $rootScope: GtRootScopeService;
      FactPlListService: any;
      initQueryParams: QueryParams = {};
      passportId?: number;
      purchaseFactData: any;
      purchaseFactTotals: any;
      queryParams: QueryParams = {};
      saleFactData: any;
      saleFactTotals: any;
      constructor($rootScope: GtRootScopeService, FactPlListService: any) {
        this.$rootScope = $rootScope;
        this.FactPlListService = FactPlListService;

        this.saleFactData = [];
        this.purchaseFactData = [];
        this.saleFactTotals = {};
        this.purchaseFactTotals = {};
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.updateFactPlList();
      }

      updateFactPlList() {
        return this.FactPlListService.getListData({
          passport: this.passportId,
          fact_type: 'sale',
          ...this.queryParams,
        }).then((data: any) => {
          this.saleFactData = data.data.results;
          return this.FactPlListService.getListData({
            passport: this.passportId,
            fact_type: 'purchase',
            ...this.queryParams,
          }).then((data: any) => {
            this.purchaseFactData = data.data.results;
            return this.FactPlListService.getTotalsData({
              passport: this.passportId,
              fact_type: 'sale',
              ...this.queryParams,
            }).then((data: any) => {
              this.saleFactTotals = data.data;
              return this.FactPlListService.getTotalsData({
                passport: this.passportId,
                fact_type: 'purchase',
                ...this.queryParams,
              }).then((data: any) => {
                this.purchaseFactTotals = data.data;
              });
            });
          });
        });
      }
    },
  ],
};
