import { financesOtherActivitiesPredictionsRetrieve } from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { OtherActivity, OtherActivityListParams } from '../lib';

@singleton()
export class OtherActivityRepository extends EntityRepository<
  OtherActivity,
  OtherActivityListParams
> {
  readonly entityName = 'finances.OtherActivity';

  getFn = (_id: number): Promise<OtherActivity> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: OtherActivityListParams): Promise<Paginated<OtherActivity>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await financesOtherActivitiesPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  createFn = (_entity: OtherActivity): Promise<OtherActivity> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<OtherActivity>): Promise<OtherActivity> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
