import ng from 'angular';

import { LoyaltyProgramListContainer } from './loyalty-program-list-container/loyalty-program-list-container.component';
import { LoyaltyProgramListTableView } from './loyalty-program-list-table-view/loyalty-program-list-table-view.component';
import { LoyaltyProgramModal } from './loyalty-program-modal/loyalty-program-modal.component';
import { LoyaltyProgramPositionTable } from './loyalty-program-position-table/loyalty-program-position-table.component';
import { LoyaltyProgramsPageView } from './loyalty-programs-page-view/loyalty-programs-page-view.component';
import { LoyaltyProgramsService } from './loyalty-programs.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const loyaltyProgramsConfig = [
  '$stateProvider',
  ($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider.state('reports.budgets.loyaltyPrograms', {
      url: '/loyalty-programs',
      component: 'loyaltyProgramsPageView',
      resolve: {
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('loyalty-program-page-view');
          },
        ],
      },
    });
  },
];

export const loyaltyPrograms = ng
  .module('contracts.loyaltyPrograms', [])
  .component('loyaltyProgramsPageView', LoyaltyProgramsPageView)
  .component('loyaltyProgramListContainer', LoyaltyProgramListContainer)
  .component('loyaltyProgramListTableView', LoyaltyProgramListTableView)
  .component('loyaltyProgramModal', LoyaltyProgramModal)
  .component('loyaltyProgramPositionTable', LoyaltyProgramPositionTable)
  .service('LoyaltyProgramsService', LoyaltyProgramsService)
  .config(loyaltyProgramsConfig).name;
