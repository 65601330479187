import type ng from 'angular';

import template from './client-invoice-payment-reconciliation-report-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const ClientInvoicePaymentReconciliationReportPage = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    'gettext',
    'PageService',
    'ReportsService',
    'gtFilterService',
    'ClientInvoicePaymentReconciliationReportService',
    class {
      PageService: PageService;
      ReportsService: ReportsService;
      Service: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
        ReportsService: ReportsService,
        gtFilterService: GtFilterService,
        ClientInvoicePaymentReconciliationReportService: any,
      ) {
        this.gettext = gettext;
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.gtFilterService = gtFilterService;
        this.Service = ClientInvoicePaymentReconciliationReportService;
        this.filterLevel = '';
        this.initQueryParams = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'client-invoice-payment-reconciliation-report-page';
        this.queryParams = { ...this.initQueryParams };
        this.updatePageConfig();
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updatePageConfig() {
        this.PageService.updateConfig({
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.Filters(),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            search: false,
            dates: true,
            useFlexDateRange: true,
            invert_filters: true,
            multiSelects: [
              {
                argument: 'client_list',
                placeholder: this.gettext('Counterparty'),
                resource: 'clients.client',
              },
              {
                argument: 'currency_list',
                placeholder: this.gettext('Currency'),
                resource: 'finances.currency',
              },
            ],
            nestedSelects: [
              {
                argument: 'invoice_type',
                placeholder: this.gettext('Invoice type'),
                items: [
                  { id: 'incoming', title: this.gettext('incoming') },
                  { id: 'outgoing', title: this.gettext('outgoing') },
                ],
              },
            ],
            nestedMultiSelects: [],
          },
        });
      }
    },
  ],
};
