import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import { useComponent } from '~/shared/lib/components';

import type { SaleLogisticListContainerProps } from './sale-logistic-list.model';
import { SaleLogisticListModel } from './sale-logistic-list.model';

export const SaleLogisticContainer = (props: SaleLogisticListContainerProps) => {
  const { ComponentProvider } = useComponent(props, SaleLogisticListModel);

  return (
    <ComponentProvider>
      <EntityListContainer />
    </ComponentProvider>
  );
};
