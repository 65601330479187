import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

export class RequestFilterConfigService {
  $rootScope: GtRootScopeService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  gtFilterService: GtFilterService;
  constructor(
    $rootScope: GtRootScopeService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.$rootScope = $rootScope;
    this.GtUtils = GtUtils;
    this.gtFilterService = gtFilterService;
    this.gettext = gettext;
  }

  getFilterConfig(filterLevel: string, contractType: any) {
    const filters: any = {
      groupBy: {
        supplier: this.gettext('Supplier'),
        buyer: this.gettext('Buyer'),
        basis: this.gettext('Basis'),
        cargo: this.gettext('Cargo'),
        crop_year: this.gettext('Crop year'),
        status: this.gettext('Status'),
        season: this.gettext('Season'),
        business_unit: this.gettext('Business unit'),
      },
      filterLevel: filterLevel,
      ordering: [
        {
          title: this.gettext('VOLUME'),
          predicate: 'volume',
        },
        {
          title: this.gettext('PRICE'),
          predicate: 'price',
        },
      ],
      search: true,
      dates: true,
      useFlexDateRange: true,
      invert_filters: true,
      dateSelects: [
        {
          argument: 'multicontract__create_time',
          placeholder: this.gettext('Create date'),
        },
        {
          argument: 'date',
          placeholder: this.gettext('Date'),
        },
        {
          argument: 'date_of_execution',
          placeholder: this.gettext('Execution start date'),
        },
        {
          argument: 'end_of_execution',
          placeholder: this.gettext('Execution end date'),
        },
        {
          argument: 'multicontract__conclusion_date',
          placeholder: this.gettext('Conclusion date'),
        },
        {
          argument: 'conclusion_date',
          placeholder: this.gettext('Position conclusion date'),
        },
      ],
      multiSelects: [
        {
          argument: 'author_list',
          placeholder: this.gettext('author'),
          resource: 'auth.user',
          related_fk: 'author_id',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'editor_list',
          placeholder: this.gettext('editor'),
          resource: 'auth.user',
          related_fk: 'editor_id',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'basis_list',
          placeholder: this.gettext('basis'),
          resource: 'logistics.basis',
          related_fk: 'basis_id',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'responsible_list',
          placeholder: this.gettext('responsible'),
          resource: 'auth.user',
          related_fk: 'responsible',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'business_unit_list',
          placeholder: this.gettext('business unit'),
          resource: 'core.BusinessUnit',
          getQueryParams: this.GtUtils.getBUQueryParams,
        },
        {
          argument: 'season_list',
          placeholder: this.gettext('season'),
          resource: 'core.Season',
          related_fk: 'season',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'owner_list',
          placeholder: this.gettext('owner'),
          resource: 'clients.Owner',
          related_fk: 'owner_id',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'broker_list',
          placeholder: this.gettext('broker'),
          resource: 'clients.broker',
          related_fk: 'broker',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'agent_list',
          placeholder: this.gettext('agent'),
          resource: 'clients.clientrole',
          related_fk: 'agent',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'distributor_list',
          placeholder: this.gettext('distributors'),
          resource: 'clients.supplier',
          getQueryParams: () => {
            return { is_distributor: 1 };
          },
        },
        {
          argument: 'custom_status_list',
          placeholder: this.gettext('custom status'),
          resource: 'core.CustomStatus',
          related_fk: 'custom_status',
          related_model: 'contracts.ContractBase',
          queryParams: {
            content_type__model: 'contractbase',
          },
        },
        {
          argument: 'type_of_activities_list',
          placeholder: this.gettext('Types of activities'),
          resource: 'core.TypeOfActivity',
          related_fk: 'type_of_activities',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'sap_order_list',
          placeholder: this.gettext('sap orders'),
          resource: 'contracts.saporder',
        },
        {
          argument: 'general_agreement',
          placeholder: this.gettext('general agreement'),
          resource: 'contracts.GeneralAgreement',
        },
        {
          argument: 'multicontract_list',
          placeholder: this.gettext('multiticket'),
          resource: 'contracts.multicontract',
          related_fk: 'multicontract',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'id_list',
          placeholder: this.gettext('position number'),
          resource: 'contracts.ContractBase',
          related_fk: 'id',
          related_model: 'contracts.ContractBase',
        },
        {
          argument: 'broker_contacts_list',
          placeholder: this.gettext('broker details'),
          resource: 'clients.Person',
        },
      ],
      nestedMultiSelects: [
        this.gtFilterService.getTicketPositionStatusFilter('request_status_list'),
        {
          argument: 'approval_status_list',
          placeholder: this.gettext('Approval status'),
          items: [
            { id: 'wait', title: this.gettext('Waiting for approval') },
            { id: 'approved', title: this.gettext('Approved') },
            { id: 'process', title: this.gettext('Approval in process') },
            { id: 'rejected', title: this.gettext('Rejected') },
          ],
        },
        {
          argument: 'counterparty_approval_status_list',
          placeholder: this.gettext('Counterparty approval status'),
          items: [
            { id: 'wait', title: this.gettext('Waiting for approval') },
            { id: 'approved', title: this.gettext('Approved') },
            { id: 'process', title: this.gettext('Approval in process') },
            { id: 'rejected', title: this.gettext('Rejected') },
          ],
        },
        {
          argument: 'general_agreement_status_list',
          placeholder: this.gettext('Agreement status'),
          items: [
            { id: 'new', title: this.gettext('New') },
            { id: 'approved', title: this.gettext('Approved') },
            { id: 'process', title: this.gettext('Approval in process') },
            { id: 'rejected', title: this.gettext('Rejected') },
          ],
        },
        this.gtFilterService.getCropsFilter('crop_field'),
        {
          argument: 'deal_type_list',
          placeholder: this.gettext('Deal type'),
          items: [
            { id: 'spot', title: this.gettext('Spot') },
            { id: 'forward', title: this.gettext('Forward') },
          ],
        },
        {
          argument: 'crop_year_list',
          placeholder: this.gettext('Crop year'),
          items: this.GtUtils.getYearList(),
        },
      ],
      nestedSelects: [
        this.gtFilterService.getBoolFilter('with_contract', this.gettext('With contracts')),
        this.gtFilterService.getBoolFilter('with_exporters', this.gettext('With exporters')),
        this.gtFilterService.getBoolFilter('with_season', this.gettext('Has season')),
        this.gtFilterService.getBoolFilter('with_business_unit', this.gettext('Has Business Unit')),
        this.gtFilterService.getBoolFilter(
          'with_general_agreement',
          this.gettext('Has general agreement'),
        ),
        this.gtFilterService.getBoolFilter('is_lost', this.gettext('Lost')),
        {
          argument: 'wait_my_approval',
          class: '',
          items: [{ id: this.$rootScope.user.id, title: this.gettext('Yes') }],
          placeholder: this.gettext('Waiting my approval'),
        },
      ],
    };

    if (contractType == 'sale') {
      filters.multiSelects.push({
        argument: 'buyer_list',
        placeholder: this.gettext('buyer'),
        resource: 'clients.buyer',
        related_fk: 'buyer',
        related_model: 'contracts.ContractBase',
      });
    }
    if (contractType == 'purchase') {
      filters.multiSelects.push(
        {
          argument: 'regional_manager_list',
          placeholder: this.gettext('sales manager'),
          resource: 'accounts.regionalmanager',
          related_fk: 'regional_managers',
          related_model: 'contracts.Request',
        },
        {
          argument: 'regional_manager_heads_list',
          placeholder: this.gettext('sales manager head'),
          resource: 'accounts.regionalmanager',
          related_fk: 'regional_manager_heads',
          related_model: 'contracts.Request',
        },
        {
          argument: 'supplier_list',
          placeholder: this.gettext('supplier'),
          resource: 'clients.supplier',
          related_fk: 'supplier',
          related_model: 'contracts.ContractBase',
        },
      );
    }
    return filters;
  }
}
RequestFilterConfigService.$inject = ['$rootScope', 'GtUtils', 'gtFilterService', 'gettext'];
