import ng from 'angular';

(function () {
  'use strict';

  ng.module('deals.contracts.legacy').controller('DetailsContractController', ControllerFunction);

  function ControllerFunction(this: any) {
    const vm = this;

    vm.tab = 'status';
    vm.tabActivate = tabActivate;

    /////////////

    function tabActivate(tabName: string) {
      vm.tab = tabName;
    }
  }
})();
