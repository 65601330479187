import React from 'react';

import { useComponentModel } from '~/shared/lib/components';
import { useObservableEagerState } from '~/shared/lib/state';
import type { Message } from '~/shared/lib/types';

import type { DatasetModel } from '../components/dataset/dataset.model';
import type { DataParams, DataRecord, FieldDef } from '../lib/types';

export const useErrorMessage = <R extends DataRecord, P extends DataParams>({
  recordId,
  fieldKey,
}: {
  recordId: R['id'];
  fieldKey: FieldDef<R>['key'];
}): Message | undefined => {
  const model = useComponentModel<DatasetModel<R, P>>();

  const errors = useObservableEagerState(model.recordsErrors$);
  const message = React.useMemo(
    () => errors[recordId as number]?.[fieldKey as string],
    [errors, recordId, fieldKey],
  );

  return message;
};
