import type ng from 'angular';

import template from './stock-reserve-units-table.html?raw';

import { html } from '^/shared/utils';

export const StockReserveUnitsTable = {
  template,
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
    items: '<',
    count: '<',
    applyFilters: '&',
    openModal: '&',
    clone: '&',
  },
  controller: [
    'gettext',
    class {
      applyFilters: any;
      clone: any;
      count: number;
      filterLevel = 'stock-reserve-units-table';
      gettext: ng.gettext.gettextFunction;
      items: any;
      openModal: any;
      tableData: any;
      tableName = '';
      tableOptions: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;
        this.items = [];
        this.count = 0;
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'stock-reserve-units-table';
        this.tableName = this.tableName || this.filterLevel;
        this.tableOptions = this.getTableOptions();
      }
      $onChanges(changes: any) {
        if (changes.items || changes.count) {
          this.tableData = { rows: this.items, count: this.count };
        }
      }
      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass:
            'contract-charges-table customs-cargo-declaration-table sample-control-table table-with-inline-add',
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          configurable: true,
          changePageSize: true,
          showMore: true,
          templateArgs: {
            openModal: (item: any) => this.openModal({ item: item }),
            clone: (item: any) => this.clone({ item: { ...item, id: undefined } }),
          },
          tabs: [],
          columnDefs: [
            {
              columnName: 'title',
              class: 'td-left-align',
              title: this.gettext('Title'),
              cellTemplate: html`
                <div class="space-between">
                  <div class="pull-left">
                    <a ui-sref="logistics.stockReserveUnit({id: item.pk})">
                      <i class="fa fa-lock"></i> <span>{[{item.title}]}</span>
                    </a>
                  </div>
                  <ul
                    class="nav navbar-nav item-settings-dropdown actions-btn pull-right"
                    ng-mouseenter="args.setHovering(true)"
                    ng-mouseleave="args.setHovering(false)"
                  >
                    <li>
                      <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                        <i class="fa fa-ellipsis hide-on-hover"></i>
                        <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="btn btn-xs col-xs-12"
                            ui-sref="logistics.stockReserveUnit({id: item.pk })"
                          >
                            <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                          </a>
                        </li>
                        <li>
                          <a
                            per
                            permission-only="'change_stockreserveunit'"
                            class="btn btn-xs aligned-btn"
                            ng-click="args.openModal({id: item.pk})"
                          >
                            <i class="fa fa-pencil"></i> <translate>Edit</translate>
                          </a>
                        </li>
                        <li>
                          <a
                            permission
                            permission-only="'change_stockreserveunit'"
                            ng-click="args.clone(item)"
                            class="btn btn-xs aligned-btn"
                          >
                            <i class="fa fa-files-o"></i> <translate>Clone</translate>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              `,
            },
            {
              columnName: 'startDate',
              class: 'td-left-align',
              title: this.gettext('start Date'),
              cellTemplate: html`<span>{[{ item.start_date | date:'dd.MM.yy' }]}</span>`,
            },
            {
              columnName: 'endDate',
              class: 'td-left-align',
              title: this.gettext('End date'),
              cellTemplate: html`<span>{[{ item.end_date | date:'dd.MM.yy' }]}</span>`,
            },
            {
              columnName: 'commodity',
              class: 'td-left-align',
              title: this.gettext('commodity'),
              cellTemplate: html`
                <i class="fa fa-wheat-alt"></i> <span>{[{ item.commodity.title}]}</span>
              `,
            },
            {
              columnName: 'businessUnit',
              class: 'td-left-align',
              title: this.gettext('Business unit'),
              cellTemplate: html`<span>{[{ item.business_unit.title}]}</span>`,
            },
            {
              columnName: 'client',
              class: 'td-left-align',
              title: this.gettext('Client'),
              cellTemplate: html`
                <span ng-if="item.client.pk">
                  <i class="fa fa-person"></i>
                  <a ui-sref="gt.page.client({id: item.client.pk })">{[{ item.client.title }]}</a>
                </span>
              `,
            },
            {
              columnName: 'responsible',
              class: 'td-left-align',
              title: this.gettext('responsible'),
              cellTemplate: html`
                <span ng-if="item.responsible.pk"> {[{ item.responsible.title }]} </span>
              `,
            },
            {
              columnName: 'contract',
              class: 'td-left-align',
              title: this.gettext('Sale Contract'),
              cellTemplate: html`
                <span ng-if="item.contract_position.pk">
                  <i class="fa fa-person"></i>
                  <a ui-sref="gt.page.contract({ id: item.contract_position.pk})">
                    {[{ item.contract_position.title || ''}]}
                  </a>
                </span>
              `,
            },
            {
              columnName: 'passport',
              class: 'td-left-align',
              title: this.gettext('Passport'),
              cellTemplate: html`
                <span ng-if="item.passport.pk">
                  <i class="fa fa-envelope"></i>
                  <a ui-sref="gt.page.passport({ id: item.passport.pk})"
                    >{[{ item.passport.title }]}</a
                  >
                </span>
              `,
            },
            {
              columnName: 'volume',
              class: 'td-left-align',
              title: this.gettext('Volume'),
              cellTemplate: html`
                <span ng-if="item.volume">{[{ item.volume | gtDecimal }]}</span>
              `,
            },
            {
              columnName: 'additional_info',
              class: 'td-left-align',
              title: this.gettext('Additional Info'),
              cellTemplate: html`
                <span ng-if="item.additional_info">{[{ item.additional_info }]}</span>
              `,
            },
          ],
        };
      }
    },
  ],
};
