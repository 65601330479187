import type ng from 'angular';

import template from './invoice-position-config-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { html } from '^/shared/utils';

export const InvoicePositionConfigContainer = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<',
  },
  template,
  controller: [
    '$scope',
    'InvoicePositionConfigService',
    'FinancesService',
    'gtFilterService',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      InvoicePositionConfigService: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      newInvoicePositionConfig: any;
      queryParams: QueryParams = {};
      tableData: any;
      tableOptions: any;
      constructor(
        $scope: ng.IScope,
        InvoicePositionConfigService: any,
        FinancesService: FinancesService,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.InvoicePositionConfigService = InvoicePositionConfigService;
        this.FinancesService = FinancesService;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.filterLevel = '';
        this.initQueryParams = {};

        this.tableData = { rows: [], count: 0 };
        this.queryParams = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'invoice-position-config-container';
        this.queryParams = { ...this.initQueryParams };

        this.tableOptions = this.getTableOptions();
        this.newInvoicePositionConfig = this.InvoicePositionConfigService.getInitValue(
          this.initQueryParams,
        );
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.InvoicePositionConfigService.getInvoicePositionConfigTableData(
          this.queryParams,
        ).then((data: any) => {
          this.newInvoicePositionConfig = this.InvoicePositionConfigService.getInitValue(
            this.initQueryParams,
          );
          data.results.unshift(this.newInvoicePositionConfig);
          this.tableData = { rows: data.results, count: data.count };
          this.GtUtils.overlay('hide');
        });
      }

      applyFilters = (event: any) => {
        this.gtFilterService.updateQueryParams(event.params, this.filterLevel);
      };

      checkFieldRequired(use: any, field: any) {
        return this.InvoicePositionConfigService.getRequiredFields(use).indexOf(field) > -1;
      }

      isValid(item: any) {
        return this.InvoicePositionConfigService.getRequiredFields(item.use).every((field: any) =>
          Boolean(item[field]),
        );
      }

      clearFieldOnUseChange(item: any) {
        const fields = this.InvoicePositionConfigService.getRequiredFields(item.use);
        fields.push('id');
        Object.keys(item).forEach((field) => {
          if (fields.indexOf(field) < 0) {
            item[field] = null;
          }
        });
      }

      saveItem(item: any) {
        this.clearFieldOnUseChange(item);
        this.InvoicePositionConfigService.save(item)
          .then(() => this.updateData())
          .catch(this.GtUtils.errorClb);
      }

      updateItem(item: any) {
        this.clearFieldOnUseChange(item);
        this.InvoicePositionConfigService.update(item)
          .then(() => this.updateData())
          .catch(this.GtUtils.errorClb);
      }

      destroy(itemId: number) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return;
        }
        this.InvoicePositionConfigService.delete(itemId)
          .then(() => this.updateData())
          .catch(this.GtUtils.errorClb);
      }

      getTableOptions() {
        const options: any = {
          tableName: 'invoice-position-configs',
          configurable: true,
          tableClass: ['payments_table invoice-position-list-table'],
          templateArgs: {
            usesList: this.FinancesService.getUsesList([]).usesList,
            checkFieldRequired: (use: any, field: any) => this.checkFieldRequired(use, field),
            contractTypesList: ['sale', 'purchase'],
            invoiceTypesList: ['incoming', 'outgoing'],
            saveItem: (item: any) => this.saveItem(item),
            updateItem: (item: any) => this.updateItem(item),
            destroy: (itemId: number) => this.destroy(itemId),
            isValid: (item: any) => this.isValid(item),
          },
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          gtFilterService: this.gtFilterService,
          columnDefs: [] as any,
          tabs: [],
          rowData: [],
          rowCount: 0,
        };

        options.columnDefs = [
          {
            columnName: 'invoice_type',
            title: this.gettext('Invoice type'),
            class: 'td-left-align',
            cellTemplate: html`
              <div ng-if="item.edit || !item.id">
                <select
                  class="form-control gt_test_field_field_invoice_use"
                  ng-model="item.invoice_type"
                  style="margin:0; padding:0;"
                  ng-options="invoiceType for invoiceType in args.invoiceTypesList"
                ></select>
              </div>
              <div ng-if="!item.edit && item.id">{[{ item.invoice_type }]}</div>
            `,
          },
          {
            columnName: 'currency',
            title: this.gettext('Currency'),
            class: 'gt-featured td-left-align',
            cellTemplate: html`
              <div ng-if="item.edit || !item.id">
                <gt-resource-select
                  ng-model="item.currency"
                  placeholder="'Currency'|translate"
                  resource-name="'finances.Currency'"
                  on-open-close="args.setHovering(isOpen)"
                  title="item.currency_symbol"
                ></gt-resource-select>
              </div>
              <div ng-if="!item.edit && item.id">{[{ item.currency_symbol }]}</div>
            `,
          },
          {
            columnName: 'use',
            title: this.gettext('Use'),
            class: 'gt-featured td-left-align',
            cellTemplate: html`
              <div ng-if="item.edit || !item.id">
                <select
                  class="form-control gt_test_field_field_invoice_use"
                  ng-model="item.use"
                  style="margin:0; padding:0;"
                  ng-options="use for use in args.usesList"
                ></select>
              </div>
              <div ng-if="!item.edit && item.id">{[{ item.use }]}</div>
            `,
          },
          {
            columnName: 'subuse',
            title: this.gettext('Subuse'),
            class: 'td-left-align',
            cellTemplate: html`
              <div ng-if="args.checkFieldRequired(item.use, 'cargo')">
                <div ng-if="item.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.crop"
                    placeholder="'Product'|translate"
                    resource-name="'crops.Crop'"
                    on-open-close="args.setHovering(isOpen)"
                    title="item.cargo_title"
                  ></gt-resource-select>
                </div>
                <div ng-if="!item.edit && item.id">{[{ item.cargo_title }]}</div>
              </div>
              <div ng-if="args.checkFieldRequired(item.use, 'charge')">
                <div ng-if="item.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.charge"
                    placeholder="'Charge'|translate"
                    resource-name="'finances.Charge'"
                    on-open-close="args.setHovering(isOpen)"
                    query-params="{is_gain:1 && item.use == 'gains' || 0}"
                    title="item.charge_title"
                  ></gt-resource-select>
                </div>
                <div ng-if="!item.edit && item.id">{[{ item.charge_title }]}</div>
              </div>
              <div ng-if="args.checkFieldRequired(item.use, 'expenses_option')">
                <div ng-if="item.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.expenses_option"
                    placeholder="'General Expenses'|translate"
                    resource-name="'finances.GeneralExpenses'"
                    on-open-close="args.setHovering(isOpen)"
                    title="item.expenses_option_title"
                  ></gt-resource-select>
                </div>
                <div ng-if="!item.edit && item.id">{[{ item.expenses_option_title }]}</div>
              </div>
              <div ng-if="args.checkFieldRequired(item.use, 'other_activity')">
                <div ng-if="item.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.other_activity"
                    placeholder="'Other Activity'|translate"
                    resource-name="'finances.otheractivity'"
                    on-open-close="args.setHovering(isOpen)"
                    title="item.other_activity_title"
                  ></gt-resource-select>
                </div>
                <div ng-if="!item.edit && item.id">{[{ item.other_activity_title }]}</div>
              </div>
            `,
          },
          {
            columnName: 'contract_type',
            title: this.gettext('Contract type'),
            class: 'td-left-align',
            cellTemplate: html`
              <div ng-if="item.use === 'cargo'">
                <div ng-if="item.edit || !item.id">
                  <select
                    class="form-control gt_test_field_field_invoice_contract_type"
                    ng-model="item.contract_type"
                    style="margin:0; padding:0;"
                    ng-options="contractType for contractType in args.contractTypesList"
                  ></select>
                </div>
                <div ng-if="!item.edit && item.id">{[{ item.contract_type }]}</div>
              </div>
            `,
          },
          {
            columnName: 'debit_account',
            title: this.gettext('Debit'),
            class: 'gt-featured td-left-align',
            cellTemplate: html`
              <div ng-if="item.edit || !item.id">
                <gt-resource-select
                  ng-model="item.debit_account"
                  placeholder="'Debit'|translate"
                  resource-name="'finances.FinanceAccount'"
                  on-open-close="args.setHovering(isOpen)"
                  title="item.debit_account_title"
                ></gt-resource-select>
              </div>
              <div ng-if="!item.edit && item.id">{[{ item.debit_account_title }]}</div>
            `,
          },
          {
            columnName: 'credit_account',
            title: this.gettext('Credit'),
            class: 'gt-featured td-left-align',
            cellTemplate: html`
              <div ng-if="item.edit || !item.id">
                <gt-resource-select
                  ng-model="item.credit_account"
                  placeholder="'Credit'|translate"
                  resource-name="'finances.FinanceAccount'"
                  on-open-close="args.setHovering(isOpen)"
                  title="item.credit_account_title"
                ></gt-resource-select>
              </div>
              <div ng-if="!item.edit && item.id">{[{ item.credit_account_title }]}</div>
            `,
          },
          {
            columnName: 'edit',
            title: this.gettext(''),
            cellTemplate: html`
              <a
                class="btn btn-xs btn-success btn_success"
                ng-if="!item.id"
                permission
                permission-only="'add_invoicepositionconfig'"
                ng-click="args.saveItem(item)"
                ng-disabled="!args.isValid(item)"
                style="font-size:1em;"
              >
                <i class="fa fa-floppy-o"></i>
              </a>
              <a
                class="btn btn-xs btn-success btn_success"
                ng-if="item.id && !item.edit"
                permission
                permission-only="'change_invoicepositionconfig'"
                ng-click="item.edit = true"
                style="font-size:1em;"
              >
                <i class="fa fa-pencil-square"></i>
              </a>
              <span ng-if="item.edit">
                <a
                  class="btn btn-xs btn-success btn_success"
                  ng-if="item.id"
                  permission
                  permission-only="'change_invoicepositionconfig'"
                  ng-click="args.updateItem(item)"
                  ng-disabled="!args.isValid(item)"
                  style="font-size:1em;"
                >
                  <i class="fa fa-floppy-o"></i>
                </a>
                <a
                  class="btn btn-xs"
                  ng-if="item.id"
                  permission
                  permission-only="'change_invoicepositionconfig'"
                  ng-click="item.edit = false"
                  style="font-size:1em;"
                >
                  <i class="fa fa-times"></i>
                </a>
              </span>
              <a
                class="btn btn-xs btn-danger btn_danger"
                ng-if="item.id"
                permission
                permission-only="'delete_invoicepositionconfig'"
                ng-click="args.destroy(item.id)"
                style="font-size:1em;"
              >
                <i class="fa fa-trash"></i>
              </a>
            `,
          },
        ];

        return options;
      }
    },
  ],
};
