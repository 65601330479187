import type ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';
import { html } from '^/shared/utils';

export const ApprovalsReportPage = {
  bindings: {
    initQueryParams: '<?',
  },
  template: html`
    <approvals-report-container
      filter-level="$ctrl.filterLevel"
      init-query-params="$ctrl.initQueryParams"
    ></approvals-report-container>
  `,
  controller: [
    'PageService',
    'ReportsService',
    'gettext',
    'gtFilterService',
    class {
      PageService: PageService;
      ReportsService: ReportsService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        PageService: PageService,
        ReportsService: ReportsService,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
      ) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;

        this.filterLevel = 'approvals-report-page-view';
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            dates: true,
            dateSelects: [
              {
                argument: 'create_time',
                placeholder: this.gettext('Create time'),
              },
            ],
            multiSelects: [
              {
                argument: 'approval_config_list',
                placeholder: this.gettext('approval config'),
                resource: 'accounts.approvalconfig',
              },
              {
                argument: 'last_approver_list',
                placeholder: this.gettext('last approver'),
                resource: 'auth.user',
              },
              {
                argument: 'next_approver_list',
                placeholder: this.gettext('next approver'),
                resource: 'auth.user',
              },
            ],
            nestedSelects: [],
            nestedMultiSelects: [
              {
                argument: 'object_type_list',
                placeholder: this.gettext('object type'),
                items: [
                  { id: 'salecontract', title: 'sale contract' },
                  { id: 'purchasecontract', title: 'purchase contract' },
                  { id: 'saleticket', title: 'sale ticket' },
                  { id: 'purchaseticket', title: 'purchase ticket' },
                  { id: 'saleindicator', title: 'sale indicator' },
                  { id: 'purchaseindicator', title: 'purchase indicator' },
                  { id: 'paymentplan', title: 'payment plan' },
                  { id: 'invoice', title: 'invoice' },
                  { id: 'passport', title: 'passport' },
                  { id: 'counterparty', title: 'counterparty' },
                ],
              },
              {
                argument: 'approval_status_list',
                placeholder: this.gettext('Approval status'),
                items: [
                  { id: 'wait', title: 'Waiting for approval' },
                  { id: 'approved', title: 'Approved' },
                  { id: 'process', title: 'Approval in process' },
                  { id: 'rejected', title: 'Rejected' },
                ],
              },
            ],
          },
        };
      }
    },
  ],
};
