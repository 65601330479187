import React from 'react';

import { MultiSelectRecords } from '~/shared/ui/data-types/components/multi-select-read/multi-select-records';
import { DropdownMenuContent } from '~/shared/ui/kit/dropdown-menu';
import { MultiSelect } from '~/shared/ui/kit/multi-select';

import type { DataTypeProps } from '../../lib';

export const MultiSelectRead: React.FC<DataTypeProps<'multiselect'>> = ({ disabled, value }) => {
  if (!value || value.length === 0) {
    return null;
  }

  return (
    <MultiSelect value={value} disabled={disabled}>
      {value.length > 2 && (
        <DropdownMenuContent className="w-[200px] p-2">
          <MultiSelectRecords items={value} disabled={disabled} className="flex-wrap" />
        </DropdownMenuContent>
      )}
    </MultiSelect>
  );
};
