import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('lineupTable', {
    bindings: {
      total: '<',
      passports: '<',
      count: '<',
      filterLevel: '<',
      subtab: '<',
      initQueryParams: '<',
      changeSubTab: '<',
      savedFilterChoices: '<?',
    },
    template: require('./lineup-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['gtFilterService', 'ContractsService'];

  function Controller(
    this: any,
    gtFilterService: GtFilterService,
    ContractsService: ContractsService,
  ) {
    const vm = this;

    vm.hovering = false;
    vm.queryParams = {};
    vm.saleContractQueryParams = {};
    vm.purchaseContractQueryParams = {};
    vm.applyFilters = applyFilters;
    vm.clearFilters = clearFilters;
    vm.openPassportModal = openPassportModal;

    vm.$onInit = function () {
      vm.queryParams = { ...vm.initQueryParams };
    };

    function applyFilters() {
      gtFilterService.updateQueryParams(
        Object.assign(vm.saleContractQueryParams, vm.queryParams),
        'lineup-contracts-table-sale',
      );
      gtFilterService.updateQueryParams(
        Object.assign(vm.purchaseContractQueryParams, vm.queryParams),
        'lineup-contracts-table-purchase',
      );
    }

    function openPassportModal(passport: any) {
      return ContractsService.passportModal(passport).then();
    }

    function clearFilters() {
      vm.queryParams = {};
      vm.saleContractQueryParams = {};
      vm.purchaseContractQueryParams = {};
      gtFilterService.setQueryParams({}, 'lineup-contracts-table-sale');
      gtFilterService.setQueryParams({}, 'lineup-contracts-table-purchase');
    }
  }
})();
