import type ng from 'angular';

import template from './sample-control-list-table-view.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export const SampleControlListTableView = {
  bindings: {
    list: '<',
    count: '<',
    total: '<',
    filterLevel: '<',
    applyFilters: '&',
    saveItem: '&',
    destroy: '&',
    updateData: '&',
    setHovering: '&',
    tableName: '<?',
  },
  template,
  controller: [
    'gettext',
    'GtUtils',
    '$window',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      GtUtils: GtUtilsService;
      applyFilters: any;
      count: number;
      destroy: any;
      filterLevel = 'sample-control-list-table-view';
      gettext: ng.gettext.gettextFunction;
      list: any;
      numberInput: any;
      saveItem: any;
      selection: any;
      tableApi: any;
      tableData: any;
      tableName = '';
      tableOptions: any;
      total: any;
      updateData: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        $window: ng.IWindowService,
        $rootScope: GtRootScopeService,
      ) {
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.$window = $window;
        this.$rootScope = $rootScope;

        this.list = [];
        this.count = 0;
        this.selection = false;
        this.numberInput = false;
        this.tableOptions = {};
        this.tableData = {};
      }

      $onChanges(changes: any) {
        this.tableName = this.tableName || 'sample-control';
        if (changes.list || changes.count || changes.total) {
          this.tableOptions = this.getTableOptions();
          this.tableData = { rows: this.list, count: this.count, total: this.total };
        }
      }

      clone(item: any) {
        Object.assign(this.tableData.rows[0], item, {
          author: undefined,
          create_time: undefined,
          editor: undefined,
          update_time: undefined,
          id: undefined,
        });
      }

      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass: 'contract-charges-table sample-control-table table-with-inline-add',
          applyFilters: this.applyFilters,
          selection: this.selection,
          numberInput: this.numberInput,
          configurable: true,
          showMore: true,
          templateArgs: {
            saveItem: (item: any) => this.saveItem({ item: item }),
            destroy: (item: any) => this.destroy({ item: item }),
            clone: (item: any) => this.clone(item),
            updateData: () => this.updateData(),
            setHovering: (value: any) => this.tableApi.setHovering({ value: value }),
            scrollToTop: () => this.$window.scrollTo(0, 100),
            $rootScope: this.$rootScope,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          columnDefs: [
            {
              columnName: 'awb_number',
              class: 'td-left-align',
              title: this.gettext('AWB number'),
              cellTemplate: html`
                <span ng-if="$ctrl.edit || !item.id">
                  <input class="form-control" type="text" ng-model="item.awb_number" />
                </span>
                <span ng-if="!$ctrl.edit && item.id"> # {[{ item.awb_number || '---' }]}</span>
              `,
            },
            {
              columnName: 'courier',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.courier_name }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.courier"
                    placeholder="'Courier'|translate"
                    resource-name="'clients.other'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
              title: this.gettext('Courier'),
            },
            {
              columnName: 'sample_type',
              class: 'td-left-align',
              title: this.gettext('Sample type'),
              cellTemplate: html`
                <span ng-if="$ctrl.edit || !item.id">
                  <select
                    class="form-control"
                    ng-change="vm.onTypeChange()"
                    ng-model="item.sample_type"
                  >
                    <option value="type" ng-selected="!item.sample_type">
                      <translate>Type</translate>
                    </option>
                    <option value="preshipment"><translate>Preshipment</translate></option>
                    <option value="stocklot"><translate>Stocklot</translate></option>
                  </select>
                </span>
                <span
                  ng-if="!$ctrl.edit && item.id"
                  ng-class="{'label label_success': item.sample_type == 'preshipment', 'label label_info': item.sample_type == 'stocklot', 'label label_default': item.sample_type == 'type'}"
                >
                  {[{ item.sample_type || '---' }]}</span
                >
              `,
            },
            {
              columnName: 'actions',
              class: 'td-left-align',
              cellTemplate: html`<a
                  ng-click="args.clone(item); args.scrollToTop()"
                  class="btn btn-xs table-edit-btn"
                  ng-if="!$ctrl.edit && item.id"
                >
                  <i class="fa fa-files-o"></i>
                </a>
                <a
                  class="btn btn-xs table-edit-btn"
                  ng-hide="$ctrl.edit || !item.id"
                  ng-click="$ctrl.edit = true"
                >
                  <i class="fa fa-pencil-square"></i>
                </a>
                <span ng-if="$ctrl.edit || !item.id" class="input-group-table-edit-btn">
                  <a
                    class="btn-success btn_success btn btn-md"
                    ng-if="item.id"
                    ng-click="args.saveItem(item);$ctrl.edit = false"
                  >
                    <i class="fa fa-floppy-o"></i>
                  </a>
                  <a
                    class="btn-success btn_success btn btn-md"
                    ng-if="!item.id"
                    ng-click="args.saveItem(item)"
                  >
                    <i class="fa fa-floppy-o"></i>
                  </a>
                  <a class="btn btn-md" ng-if="item.id" ng-click="$ctrl.edit = false">
                    <i class="fa fa-times"></i>
                  </a>
                  <a
                    class="btn-danger btn_danger btn btn-md"
                    ng-if="item.id"
                    ng-click="args.destroy(item.id);$ctrl.edit = false"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </span>`,
              title: this.gettext('Actions'),
            },
            {
              columnName: 'in_number',
              class: 'td-left-align',
              title: this.gettext('In number'),
              cellTemplate: html`
                <span ng-if="$ctrl.edit || !item.id">
                  <input class="form-control" type="text" ng-model="item.in_number" />
                </span>
                <span ng-if="!$ctrl.edit && item.id"> # {[{ item.in_number || '---' }]}</span>
              `,
              filters: [
                {
                  type: 'text',
                  predicate: 'in_number',
                },
              ],
              totalTemplate: html`<i class="fa fa-pie-chart"></i> <translate>TOTAL</translate>`,
            },
            {
              columnName: 'out_number',
              class: 'td-left-align',
              title: this.gettext('Out number'),
              cellTemplate: html`
                <span ng-if="$ctrl.edit || !item.id">
                  <input class="form-control" type="text" ng-model="item.out_number" />
                </span>
                <span ng-if="!$ctrl.edit && item.id"> # {[{ item.out_number || '---' }]}</span>
              `,
              filters: [
                {
                  type: 'text',
                  predicate: 'out_number',
                },
              ],
            },
            {
              columnName: 'in_date',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  <i class="fa fa-calendar"></i>
                  {[{ item.in_date | date:'dd.MM.yy' || '---' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-date-select
                    date-model="item.in_date"
                    use-watch="true"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-date-select>
                </span>`,
              title: this.gettext('In date'),
            },
            {
              columnName: 'out_date',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  <i class="fa fa-calendar"></i>
                  {[{ item.out_date | date:'dd.MM.yy' || '---' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-date-select
                    date-model="item.out_date"
                    use-watch="true"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-date-select>
                </span>`,
              title: this.gettext('Out date'),
            },

            {
              columnName: 'business_unit',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.business_unit_title | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.business_unit"
                    placeholder="'Business unit'|translate"
                    resource-name="'core.businessunit'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
              `,
              title: this.gettext('Business unit'),
            },
            {
              columnName: 'quantity',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                  {[{ item.quantity || 0 | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <input
                    class="form-control"
                    gt-clear-input
                    type="number"
                    ng-model="item.quantity"
                  />
                </span>
              `,
              title: this.gettext('Quantity'),
              totalTemplate: html`
                {[{ item.total_volume_sum || 0 | number:3 }]}
                <span class="smaller-label"
                  >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                >
              `,
            },
            {
              columnName: 'commodity',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.commodity_title | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.commodity"
                    placeholder="'Commodity'|translate"
                    resource-name="'crops.crop'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
              `,
              title: this.gettext('Commodity'),
            },
            {
              columnName: 'quality',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id"> {[{ item.quality }]} </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <textarea class="form-control" type="text" ng-model="item.quality"> </textarea>
                </span>`,
              title: this.gettext('Quality'),
            },
            {
              columnName: 'out_from_country',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.out_from_country_title | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.out_from_country"
                    placeholder="'Out from country'|translate"
                    resource-name="'location.country'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
              `,
              title: this.gettext('Out from country'),
            },
            {
              columnName: 'destination_country',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.destination_country_title | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.destination_country"
                    placeholder="'Destination country'|translate"
                    resource-name="'location.country'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
              `,
              title: this.gettext('Destination country'),
            },

            {
              columnName: 'supplier',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.supplier_name | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.supplier"
                    placeholder="'Supplier'|translate"
                    resource-name="'clients.supplier'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
              title: this.gettext('Supplier'),
            },

            {
              columnName: 'purchase_contracts',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  <i class="fa fa-arrow-down"></i>
                  <a ui-sref="gt.page.contract({id: item.purchase_contract })">
                    {[{ item.purchase_contract_number || '---' | cut:true:30:' ' }]}
                  </a>
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.purchase_contract"
                    placeholder="'Purchase contract'|translate"
                    resource-name="'contracts.PurchaseContract'"
                    query-params="{
                    with_passport: item.passport,
                    multicontract: item.purchase_multicontract
                  }"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                    on-select="args.onContractSelect"
                    on-select-args="[item]"
                  ></gt-resource-select>
                </span>
                <span ng-if="item.purchaseContractDetails"
                  >{[{ item.purchaseContractDetails }]}</span
                >
              `,
              title: this.gettext('Purchase contracts'),
              filters: [
                {
                  type: 'ui-select',
                  predicate: 'purchase_contract',
                  label: this.gettext('Purchase'),
                  resource: 'contracts.PurchaseContract',
                },
              ],
            },
            {
              columnName: 'buyer',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.buyer_name | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.buyer"
                    placeholder="'Buyer'|translate"
                    resource-name="'clients.buyer'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
              title: this.gettext('Buyer'),
            },
            {
              columnName: 'sale_contract',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="!$ctrl.edit && item.id">
                  <i class="fa fa-arrow-up"></i>
                  <a ui-sref="gt.page.contract({id: item.sale_contract })">
                    {[{ item.sale_contract_counterparty_business_reference ||
                    item.sale_contract_number || '---' | cut:true:30:' ' }]}
                  </a>
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.sale_contract"
                    placeholder="'Sale contract'|translate"
                    resource-name="'contracts.SaleContract'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                    on-select="args.onContractSelect"
                    on-select-args="[item]"
                    query-params="{
                    with_passport: item.passport,
                    multicontract: item.sale_multicontract
                  }"
                  ></gt-resource-select>
                </span>
                <span ng-if="item.saleContractDetails">{[{ item.saleContractDetails }]}</span>
              `,
              title: this.gettext('Sale contract'),
              filters: [
                {
                  type: 'ui-select',
                  predicate: 'sale_contract',
                  label: this.gettext('Sale'),
                  resource: 'contracts.SaleContract',
                },
              ],
            },
            {
              columnName: 'receiver',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.receiver_name | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.receiver"
                    placeholder="'Receiver'|translate"
                    resource-name="'clients.clientrole'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
              title: this.gettext('Receiver'),
            },
            {
              columnName: 'received_date',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  <i class="fa fa-calendar"></i>
                  {[{ item.received_date | date:'dd.MM.yy' || '---' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-date-select
                    date-model="item.received_date"
                    use-watch="true"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-date-select>
                </span>`,
              title: this.gettext('Received date'),
            },

            {
              columnName: 'result_date',
              class: 'td-left-align',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  <i class="fa fa-calendar"></i>
                  {[{ item.result_date | date:'dd.MM.yy' || '---' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-date-select
                    date-model="item.result_date"
                    use-watch="true"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-date-select>
                </span>`,
              title: this.gettext('Result date'),
            },
            {
              columnName: 'result_status',
              class: 'td-left-align',
              title: this.gettext('Result status'),
              cellTemplate: html`
                <span ng-if="$ctrl.edit || !item.id">
                  <select
                    class="form-control"
                    ng-change="vm.onTypeChange()"
                    ng-model="item.result_status"
                  >
                    <option value="undefined" ng-selected="!item.result_status">
                      <translate>Undefined</translate>
                    </option>
                    <option value="approved"><translate>Approved</translate></option>
                    <option value="rejected"><translate>Rejected</translate></option>
                  </select>
                </span>
                <span
                  ng-if="!$ctrl.edit && item.id"
                  ng-if="!$ctrl.edit && item.id"
                  ng-class="{'label label_success': item.result_status == 'approved', 'label label_danger': item.result_status == 'rejected'}"
                >
                  {[{ item.result_status || '---' }]}</span
                >
              `,
            },
            {
              columnName: 'reason_for_rejection',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.reason_for_rejection }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <textarea class="form-control" type="text" ng-model="item.reason_for_rejection">
                  </textarea>
                </span>`,
              title: this.gettext('Reason for rejection'),
            },
          ],
        };
      }
    },
  ],
};
