import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('vesselsContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      view: '<?',
    },
    template: require('./vessels-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$rootScope', '$scope', 'LogisticsService', 'GtUtils', 'gtFilterService'];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $scope: ng.IScope,
    LogisticsService: LogisticsService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;
    vm.filterLevel = vm.filterLevel || 'vessels-container';
    vm.vessels = [];
    vm.vesselsCount = 0;
    vm.updateVessels = updateVessels;
    vm.openVesselModal = openVesselModal;
    vm.queryParams = { ...vm.initQueryParams };
    vm.clone = clone;

    vm.$onInit = function () {
      if ($rootScope.isDeviceMobile) {
        vm.view = 'block';
      } else {
        vm.view = 'table';
      }

      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateVessels();
      });
      $scope.$on('vessels-update', function (ev: any, data: any) {
        gtFilterService.updateQueryParams(data, vm.filterLevel);
      });
      updateVessels();
    };

    ////////////////

    function updateVessels() {
      GtUtils.overlay('show');
      return LogisticsService.Vessel.query(vm.queryParams, function (data: any) {
        vm.vessels = data.results;
        vm.vesselsCount = data.count;
        GtUtils.overlay('hide');
      }).$promise;
    }

    function openVesselModal(vessel: any) {
      return LogisticsService.vesselModal(vessel).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        updateVessels();
      });
    }

    function clone(vessel: any) {
      delete vessel.id;
      return LogisticsService.vesselModal(vessel);
    }
  }
})();
