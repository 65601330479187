import { Subject, combineLatest, defer, shareReplay, startWith, switchMap } from 'rxjs';

import { injectable } from '~/shared/lib/di';

import { BiDashboard } from '../types';
import { BiDashboardsRepository } from './bi-dashboards.repository';

@injectable()
export class BiDashboardsStore {
  private readonly tokenCache = new Map<string, string>();
  private readonly selectedDashboardSubj = new Subject<BiDashboard>();

  constructor(private readonly biDashboardsRepo: BiDashboardsRepository) {}

  readonly selectedDashboard$ = defer(() =>
    combineLatest(
      [this.selectedDashboardSubj.pipe(startWith(undefined)), this.usersDashboards$],
      (selectedDashboard, usersDashboards) => {
        const dashboard =
          selectedDashboard ?? usersDashboards.find((d) => d.slug === 'analytics-company-basic');

        if (!dashboard) {
          throw new Error('Dashboard not found');
        }

        return dashboard;
      },
    ),
  );

  public usersDashboards$ = defer(() => {
    return this.biDashboardsRepo.getUsersDashboards();
  }).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  public selectedDashboardUUID$ = this.selectedDashboard$.pipe(
    switchMap((dashboard) => this.biDashboardsRepo.getDashboardUUID(dashboard.id)),
  );

  public async fetchGuestToken(dashboardUUID: string) {
    const token =
      this.tokenCache.get(dashboardUUID) ??
      (await this.biDashboardsRepo.getGuestToken(dashboardUUID));

    if (!this.tokenCache.has(dashboardUUID)) {
      this.tokenCache.set(dashboardUUID, token);
    }

    return token;
  }

  public dashboardSelected(dashboardId: BiDashboard) {
    this.selectedDashboardSubj.next(dashboardId);
  }
}
