import React from 'react';

import { useAuth } from '~/core/auth/hooks';
import { Link } from '~/shared/lib/router';
import { useObservableEagerState } from '~/shared/lib/state';

export const TeamPage: React.FC = () => {
  const { authStore } = useAuth();
  const currentUser = useObservableEagerState(authStore.currentUser$);
  return (
    <div>
      <h1>Team</h1>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
      <ul>
        <li>
          <Link to="/company/team/$traderId" params={{ traderId: '1' }}>
            Trader 1
          </Link>
        </li>
        <li>
          <Link to="/company/team/$traderId" params={{ traderId: '2' }}>
            Trader 2
          </Link>
        </li>
        <li>
          <Link to="/company/team/$traderId" params={{ traderId: currentUser.id.toString() }}>
            Profile
          </Link>
        </li>
      </ul>
    </div>
  );
};
