import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('generalAgreementsInlineFilter', {
    bindings: {
      filterLevel: '<',
    },
    template: require('./general-agreements-inline-filter.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gtFilterService'];

  function Controller(this: any, $scope: ng.IScope, gtFilterService: GtFilterService) {
    const vm = this;
    vm.queryParams = {};
    vm.applyFilter = applyFilter;

    vm.$onInit = function () {
      vm.queryParams = gtFilterService.getQueryParams(vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
    };

    ////////////////

    function applyFilter() {
      vm.queryParams.page = undefined;
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }
  }
})();
