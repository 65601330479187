import type ng from 'angular';

import template from './navbar-top-container.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { NotificationService } from '^/app/crm/notifications/notifications.service';

export const NavbarTopContainer = {
  bindings: {},
  template,
  controller: [
    '$rootScope',
    '$state',
    '$window',
    'GtUtils',
    '$injector',
    class {
      $location: ng.ILocationService;
      $rootScope: GtRootScopeService;
      $state: ng.ui.IStateService;
      AccountsService: AccountsService;
      GtUtils: GtUtilsService;
      NotificationService: NotificationService;
      _error: any;
      innerWidth: any;
      srefs: any;
      constructor(
        $rootScope: GtRootScopeService,
        $state: ng.ui.IStateService,
        $window: ng.IWindowService,
        GtUtils: GtUtilsService,
        $injector: ng.auto.IInjectorService,
      ) {
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.AccountsService = $injector.get<AccountsService>('AccountsService');
        this.innerWidth = $window.innerWidth;
        this.$location = $injector.get('$location');
        this.NotificationService = $injector.get<NotificationService>('NotificationService');
        this.srefs = {};
      }

      $onInit() {
        if (this.warehousePageVisibility()) {
          this.srefs.warehouse = this.getWarehouseSref();
        }
        if (this.positionPageVisibility()) {
          this.srefs.position = this.getPositionSref();
        }
      }

      changePreferences(newPrefsObj: any) {
        this.GtUtils.overlay('show');
        this.AccountsService.UserSelf.update(
          {
            id: this.$rootScope.user.id,
            profile: newPrefsObj,
          },
          (data: any) => {
            this.$rootScope.user.profile = data.profile;
            this.GtUtils.overlay('hide');
          },
          (data: any) => {
            this._error(data);
            this.GtUtils.overlay('hide');
          },
        );
      }

      warehousePageVisibility() {
        return (
          this.$rootScope.user.settings.SYSTEM_BLOCKS.block_execution_warehouse &&
          [
            'can_view_warehouse_rests',
            'can_view_warehouse_rests_with_amounts',
            'can_view_warehouse_balance',
            'can_view_warehouses_list',
            'can_view_warehouse_shipment',
            'can_view_warehouse_trade',
            'can_view_warehouse_losses',
            'can_view_stock_reserve_units_list',
            'can_view_reservations_list',
          ].some((perm) => this.$rootScope.user.perms.includes(perm))
        );
      }
      getWarehouseSref() {
        const pages: any = [
          {
            name: 'warehouses_list',
            state: 'logistics.warehouseList',
            permissions: ['can_view_warehouses_list'],
            icon: 'fa-warehouse',
          },
          {
            name: 'stock_reserve_units_list',
            state: 'logistics.stockReserveUnits',
            permissions: ['can_view_stock_reserve_units_list'],
            icon: 'fa-lock',
          },
          {
            name: 'reservations_list',
            state: 'logistics.reservationList',
            permissions: ['can_view_reservations_list'],
            icon: 'fa-arrow-down-up-lock',
          },
          {
            name: 'warehouse_rests',
            state: 'logistics.warehouse',
            icon: 'fa-warehouse',
            permissions: ['can_view_warehouse_rests'],
          },
          {
            name: 'warehouse_rests_with_amounts',
            state: 'logistics.warehouseRests',
            icon: 'fa-cart-arrow-down',
            permissions: ['can_view_warehouse_rests_with_amounts'],
          },
          {
            name: 'warehouse_balance',
            state: 'logistics.warehousesBalance',
            icon: 'fa-pie-chart',
            permissions: ['can_view_warehouse_balance'],
          },
          {
            name: 'warehouse_shipment',
            permissions: ['can_view_warehouse_shipment'],
            state: 'logistics.warehouseShipment',
            icon: 'fa-cart-arrow-down',
          },
          {
            name: 'warehouse_trade',
            state: 'logistics.warehousetrade',
            icon: 'fa-area-chart',
            permissions: ['can_view_warehouse_trade'],
          },
          {
            name: 'warehouse_losses',
            state: 'logistics.warehouseLosses',
            permissions: ['can_view_warehouse_losses'],
            icon: 'fa-times',
          },
        ]
          .filter((page) =>
            page.permissions.every((perm) => this.$rootScope.user.perms.includes(perm)),
          )
          .map((page) => ({
            ...page,
            isDefaultTab: page.name === this.$rootScope.user.profile?.warehouse_default_page,
          }));
        return pages.filter((page: any) => page.isDefaultTab).pop()?.state ?? pages[0].state;
      }

      positionPageVisibility() {
        return (
          this.$rootScope.user.settings.SYSTEM_BLOCKS.block_report_position &&
          this.$rootScope.user.perms.includes('view_positionreport')
        );
      }
      getPositionSref() {
        return (
          {
            pnl_position: 'position.mtm',
            position_by_month: 'reports.consolidatedCropPosition',
          }[this.$rootScope.user.settings.DEFAULT_POSITION_TAB] ?? 'position.physical'
        );
      }
      openScreenCaptureTaskModal() {
        this.NotificationService.openScreenCaptureTaskModal({
          name: this.$rootScope.pageTitle,
          url: this.$location.url().slice(1),
        });
      }
    },
  ],
};
