import type ng from 'angular';

import template from './attach-document-modal.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const AttachDocumentModal = {
  bindings: {
    object: '<',
    model: '<?',
    modalInstance: '<?',
  },
  template,
  controller: [
    'GtUtils',
    'gettext',
    class {
      $uibModal: ng.ui.bootstrap.IModalService;
      GtUtils: GtUtilsService;
      checkListPermission: any;
      gettext: ng.gettext.gettextFunction;
      hovering: any;
      modalInstance: any;
      model: any;
      object: any;
      constructor(
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        $uibModal: ng.ui.bootstrap.IModalService,
      ) {
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.modalInstance = undefined;
        this.model = '';
        this.object = {};
        this.hovering = false;
        this.$uibModal = $uibModal;
        this.checkListPermission = '';
      }

      $onInit() {
        this.checkListPermission = `view_${this.model}documentchecklists`;
      }

      close() {
        if (!confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close('close');
      }

      setHovering(hovering: any) {
        this.hovering = hovering;
      }
    },
  ],
};
