import type { Cell } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import React from 'react';

import { useComponentModel } from '~/shared/lib/components';
import { cn } from '~/shared/lib/utils';

import { getStickyStyles } from './helpers';
import type { DataRecord } from '../../lib/types';
import type { DatasetModel } from '../dataset/dataset.model';

type TinyCell<R extends DataRecord> = Pick<Cell<R, unknown>, 'id' | 'getContext'> & {
  rowId: number;
  colKey: string;
  sticky?: boolean;
  cell: Cell<R, unknown>['column']['columnDef']['cell'];
  getSize: Cell<R, unknown>['column']['getSize'];
  getStart: Cell<R, unknown>['column']['getStart'];
};

export const CellComponent = <R extends DataRecord>({ cell }: { cell: TinyCell<R> }) => {
  const model = useComponentModel<DatasetModel>();

  const cellRef = React.useMemo(
    () => model.getCellRef(cell.rowId, cell.colKey),
    [cell.colKey, cell.rowId, model],
  );

  const handleClick = React.useCallback(() => {
    const rowId = Number(cellRef.current?.dataset.rowid);
    const colKey = String(cellRef.current?.dataset.colkey);

    model.setCellSelected(rowId, colKey);

    const interactiveEl = cellRef.current?.querySelector(
      'input, textarea, [contenteditable], select',
    );

    if (!interactiveEl) {
      cellRef.current?.focus();
    }
  }, [model, cellRef]);

  const handleMouseDown = React.useCallback(() => {
    model.setCellSelected(cell.rowId, cell.colKey);
    model.setSelection({
      start: { rowId: cell.rowId, colKey: cell.colKey },
      end: { rowId: cell.rowId, colKey: cell.colKey },
    });
    model.setSelecting(true);
  }, [cell.colKey, cell.rowId, model]);

  const handleMouseEnter = React.useCallback(() => {
    const start = model.getSelection()?.start;

    if (!start || !model.getSelecting()) {
      return;
    }

    model.setSelection({
      start,
      end: { rowId: cell.rowId, colKey: cell.colKey },
    });
  }, [cell.colKey, cell.rowId, model]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const { key } = e;
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(key)) {
        e.preventDefault();
        model.setArrowKeyDown({
          key,
          rowId: cell.rowId,
          colKey: cell.colKey,
          shiftKey: e.shiftKey,
        });
      }
    },
    [cell.colKey, cell.rowId, model],
  );

  return (
    <div
      key={cell.id}
      ref={cellRef}
      role="none"
      data-rowid={cell.rowId}
      data-colkey={cell.colKey}
      onClick={handleClick}
      onFocus={handleClick}
      onBlur={handleClick}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onKeyDown={handleKeyDown}
      className={cn(
        'border-stroke-main-light relative flex h-full w-full items-center overflow-hidden border-r border-b ease-in-out',
        'focus:outline-none',
        (cell.colKey === '_selectRowCheckbox' || cell.sticky) && 'bg-background-main-primary',
        cell.rowId < 0 && 'bg-text-additional-success-light',
        cell.colKey === '_selectRowCheckbox' && 'border-r-0 pl-1',
      )}
      style={{
        width: cell.getSize(),
        ...getStickyStyles({ ...cell }),
      }}
    >
      {flexRender(cell.cell, cell.getContext())}
    </div>
  );
};
