import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import { useComponent } from '~/shared/lib/components';

import type { CertsLogisticListContainerProps } from './certs-logistic-list.model';
import { CertsLogisticListModel } from './certs-logistic-list.model';

export const CertsLogisticContainer = (props: CertsLogisticListContainerProps) => {
  const { ComponentProvider } = useComponent(props, CertsLogisticListModel);

  return (
    <ComponentProvider>
      <EntityListContainer />
    </ComponentProvider>
  );
};
