import ng from 'angular';

import { WarehouseBalanceContainer } from './warehouse-balance-container/warehouse-balance-container.component';
import { WarehouseBalancePage } from './warehouse-balance-page/warehouse-balance-page.component';
import { WarehouseBalanceTableView } from './warehouse-balance-table-view/warehouse-balance-table-view.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const warehouseBalanceConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('logistics.warehousesBalance', {
      url: '/warehouses-balances/',
      component: 'warehouseBalancePage',
      data: {
        pageTitle: gettext('Warehouses balance'),
        permissions: {
          only: 'can_view_warehouse_balance',
        },
      },
      resolve: {
        filterLevel: () => 'warehouse-balance-page',
        queryParams: [
          'PageService',
          (PageService: PageService) => ({
            verticalGroups: ['commodity'],
            horizontalGroups: ['warehouse', 'contract_position'],
            ...PageService.syncUrlFilter('warehouse-balance-page'),
          }),
        ],
      },
    });
  },
];

export const warehouseBalanceModule = ng
  .module('execution.warehouses.balances', [])
  .component('warehouseBalancePage', WarehouseBalancePage)
  .component('warehouseBalanceContainer', WarehouseBalanceContainer)
  .component('warehouseBalanceTableView', WarehouseBalanceTableView)
  .config(warehouseBalanceConfig).name;
