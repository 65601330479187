import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './multicontract-item-container.html?raw';
import type { MulticontractService } from '../../multicontract.service';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CoreService } from '^/app/core/core.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { AdditionalAgreementsService } from '^/app/deals/contracts/additional-agreements.service';

export const MulticontractItemContainer = {
  bindings: {
    multicontract: '<',
    filterLevel: '<?',
    isTicketDetail: '<?',
  },
  template,
  controller: [
    '$stateParams',
    '$rootScope',
    '$scope',
    '$state',
    'MulticontractService',
    'ClientsService',
    'AccountsService',
    'AdditionalAgreementsService',
    'GtUtils',
    'gtFilterService',
    'gettext',
    'DocumentsService',
    'CoreService',
    'PageService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      AccountsService: AccountsService;
      AdditionalAgreementsService: AdditionalAgreementsService;
      ClientsService: any;
      CoreService: CoreService;
      PageService: PageService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      MulticontractService: MulticontractService;
      activeTabs: any;
      addPermissionCode: any;
      additionalAgreements: any;
      changePermissionCode: any;
      connectToPassport: any;
      contentType: any;
      contractListInitQueryParams: QueryParams = {};
      contractSetIds: any;
      documentsCount: number;
      filterLevel = 'multicontract-item';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      isTicketDetail: any;
      logEntries: any;
      logEntriesCount = 0;
      modelName?: string;
      multicontract: any;
      queryParams: QueryParams & { contract_type?: string; tab?: string[] };
      requestListInitQueryParams: QueryParams = {};
      subtabs: any;
      tabs: any;
      updatesEntityPositionTitle: any;
      updatesEntityTitle: any;
      viewPermissionCode: any;
      constructor(
        $stateParams: ng.ui.IStateParamsService,
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        MulticontractService: MulticontractService,
        ClientsService: any,
        AccountsService: AccountsService,
        AdditionalAgreementsService: AdditionalAgreementsService,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
        DocumentsService: any,
        CoreService: CoreService,
        PageService: PageService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.multicontract = { id: $stateParams.id };
        this.MulticontractService = MulticontractService;
        this.ClientsService = ClientsService;
        this.AccountsService = AccountsService;
        this.AdditionalAgreementsService = AdditionalAgreementsService;

        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;
        this.DocumentsService = DocumentsService;
        this.CoreService = CoreService;
        this.PageService = PageService;

        this.queryParams = {};
        this.tabs = new Set();
        this.documentsCount = 0;
        this.isTicketDetail = false;
        this.updatesEntityTitle = '';
        this.activeTabs = [];

        this.subtabs = {
          costs: 'all',
          finance: 'all',
          reassignments: 'all',
          documents: 'all',
          logistics: 'all',
          billoflading: 'all',
          samples: 'all',
          ccd: 'all',
          transfer: 'all',
          disbursmentbl: 'all',
          consignments: 'all',
          tasks: this.multicontract.contracts_set === 1 ? 'position.id' : 'multicontract',
          history: 'all',
        };
        this.contractSetIds = [];
      }

      $onInit() {
        this.$scope.$on(
          'gt-filter-updated_' + this.filterLevel,
          (ev, data: QueryParams & { tab: string[] }) => {
            this.queryParams = data;
            this.updateData();
            if (data.tab.length) {
              this.tabs = new Set(data.tab);
            }
          },
        );
        this.setDefaultTabs();

        this.connectToPassport = (contract: any, positions: any) => {
          return this.MulticontractService.connectToExistingPassport(
            contract,
            contract.$_passportId,
            positions,
          );
        };

        this.viewPermissionCode = this.MulticontractService.getPermissionName(
          this.multicontract,
          'view',
        );
        this.addPermissionCode = this.MulticontractService.getPermissionName(
          this.multicontract,
          'add',
        );
        this.changePermissionCode = this.MulticontractService.getPermissionName(
          this.multicontract,
          'change',
        );
      }

      changeTabs(tab: string) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.MulticontractService.getTableData({ id: this.multicontract.id })
          .then((data: any) => {
            if (!data.results.length) {
              return this.updateError();
            }
            this.multicontract = data.results[0];
            this.contractSetIds = this.multicontract?.contracts_set?.map(
              (contract: any) => contract.id,
            );
            const firstId = this.contractSetIds.length < 2 ? this.contractSetIds[0] : 'all';
            this.subtabs = {
              costs: firstId,
              finance: firstId,
              documents: firstId,
              logistics: firstId,
              tasks: this.contractSetIds.length < 2 ? firstId : 'multicontract',
              history: firstId,
            };
            this.modelName = this.MulticontractService.getContractModelName(
              this.multicontract,
            ).toLowerCase();
            this.CoreService.getModelContentType(this.modelName).then((data: any) => {
              this.contentType = data.id;
            });
            this.PageService.setPageTitle(
              this.$rootScope.pageTitle + ' #' + this.multicontract.number,
            );
            this.contractListInitQueryParams = {
              multicontract: this.multicontract.id,
              stage: this.multicontract.stage,
              contract_type: this.multicontract.contract_type,
              deal_type:
                this.multicontract.use_type !== 'commodity' ? this.multicontract.use_type : null,
            };
            this.requestListInitQueryParams = {
              multicontract: this.multicontract.id,
              contract_type: this.multicontract.contract_type,
              deal_type:
                this.multicontract.use_type !== 'commodity' ? this.multicontract.use_type : null,
            };
            this.updatesEntityTitle = `Multi${this.multicontract.stage}`;
            this.updatesEntityPositionTitle =
              this.multicontract.stage.charAt(0).toUpperCase() + this.multicontract.stage.slice(1);
            this.updateLogEntries();
            this.updateAdditionalAgreements();
            this.GtUtils.overlay('hide');
          })
          .catch(() => {
            this.updateError();
          });
      }

      updateError() {
        notify(this.gettext("Multicontract doesn't exist"));
        this.GtUtils.overlay('hide');
        this.$state.go('contracts.multicontract.' + this.queryParams.contract_type);
      }

      updateLogEntries() {
        return this.AccountsService.LogEntry.query(
          {
            object_id: this.multicontract.id,
            content_type: this.multicontract.content_type,
          },
          (data: any) => {
            this.logEntries = data.results;
            this.logEntriesCount = data.count;
          },
        );
      }

      updateAdditionalAgreements() {
        return this.AdditionalAgreementsService.AdditionalAgreement.query(
          {
            multicontract: this.multicontract.id,
          },
          (data: any) => {
            this.additionalAgreements = data.results;
          },
        );
      }

      openDocxModal() {
        return this.DocumentsService.generateDocxModal('Multicontract', this.multicontract.id);
      }

      openPositionDocxModal(position: any) {
        return this.DocumentsService.generateDocxModal(
          position.contract_purpose.charAt(0).toUpperCase() +
            position.contract_purpose.slice(1) +
            'Contract',
          position.id,
        );
      }

      editMulticontract() {
        this.MulticontractService.multicontractModal(this.multicontract).then(() =>
          this.updateData(),
        );
      }

      setFactVolumeToPlans(params: object) {
        if (!this.multicontract.is_positions_volume_by_execution) {
          return notify(
            this.gettext('Property "positions volume by execution" is disabled.'),
            'error',
          );
        }
        if (!confirm(this.gettext('Are you sure that you want to set fact volume to plans?'))) {
          return;
        }
        this.GtUtils.overlay('show');
        return this.MulticontractService.setFactVolumeToPlans({
          ...params,
        }).then(this.GtUtils.overlay('hide'), notify(this.gettext('Successfully updated.')));
      }

      setCargoConfirmation() {
        if (!confirm(this.gettext('Are you sure that you want to set cargo confirmation?'))) {
          return;
        }
        this.GtUtils.overlay('show');
        return this.MulticontractService.setCargoConfirmation({
          id: this.multicontract.id,
        }).then(this.GtUtils.overlay('hide'), notify(this.gettext('Successfully updated.')));
      }

      openRoleModal({ id, model }: any) {
        return this.ClientsService.roleModal({ id: id, model_name: model });
      }

      getPositionFilterLevel(id: number, text: any) {
        return text + '-' + id;
      }

      documentsBulkDownloadWithPositions() {
        this.DocumentsService.queryDocuments({
          multicontract_with_positions: this.multicontract.id,
        }).then((data: any) => {
          return this.DocumentsService.bulkDownload(data.results.map((doc: any) => doc.id));
        });
      }

      goToList(type: any) {
        type = type || this.multicontract.contract_type;
        const useType = this.multicontract.use_type;
        const chargeUseType = this.multicontract.positions_charge_use_type || '';
        const chargeUseTypeFormated =
          chargeUseType.length >= 1
            ? chargeUseType[0].toUpperCase() + chargeUseType.substring(1)
            : chargeUseType;
        const servicesType = useType === 'services' ? chargeUseTypeFormated : '';
        const nextState =
          useType === 'services' || useType === 'intermediate' || useType === 'export'
            ? `multi${this.multicontract.stage}sList.${useType}${servicesType}`
            : `multi${this.multicontract.stage}sList.${type}`;
        this.$state.go(nextState);
      }

      setDefaultTabs() {
        const queryParams = this.gtFilterService.getQueryParams(this.filterLevel);
        if (queryParams.tab) {
          this.tabs = new Set(queryParams.tab as string[]);
        } else {
          this.tabs = new Set(['info', 'positions']);
        }
        this.queryParams.tab = Array.from(this.tabs);
        this.gtFilterService.updateQueryParams({ tab: Array.from(this.tabs) }, this.filterLevel);
      }

      getTelegramConfigUrl() {
        return `/admin/notifications/telegrambotconfigset/?indicator=${this.multicontract.id}`;
      }

      reverseContract(contractId: number) {
        if (!confirm(this.gettext('Do you want to reverse contract?'))) {
          return;
        }
        return this.MulticontractService.reverseDeal(contractId);
      }

      createPassport(contract: any, positions: any) {
        return this.MulticontractService.createConnectToPassport(contract, positions);
      }

      cloneContract(ObjId: number) {
        if (!confirm(this.gettext('Do you want to clone this Contract?'))) {
          return null;
        }
        return this.MulticontractService.cloneContract(ObjId).then(() => this.updateData());
      }

      cancelContract(ObjId: number) {
        if (!confirm(this.gettext('Do you want to cancel this Contract?'))) {
          return Promise.resolve();
        }
        return this.MulticontractService.cancelContract(ObjId).then(() => this.updateData());
      }

      addLogistic(contract: any) {
        return this.MulticontractService.addLogistic(contract);
      }

      addFinance(multicontractId: number, invoiceCondition: any) {
        this.MulticontractService.addFinance(multicontractId, invoiceCondition);
      }

      openDocumentModal() {
        return this.DocumentsService.documentListModal({
          model_name: this.modelName,
          object_id: this.multicontract.id,
        }).then(() => this.updateData());
      }

      getApprovalContractModel(contract: any) {
        const purpose = this.MulticontractService.getContractModelName(contract);
        return 'contracts.' + purpose.charAt(0).toUpperCase() + purpose.slice(1);
      }

      approvalAction(action: any, contractId: number, contract: any) {
        this.AccountsService.voteApprovable(
          action,
          contractId,
          null,
          this.getApprovalContractModel(contract),
        ).then(() => this.updateData());
      }

      finalApprove(item: any) {
        return this.MulticontractService.finalApprove(item).then(
          () => {
            notify(this.gettext('Multirequest saved.'));
            this.updateData();
          },
          (data: any) => {
            this.GtUtils.errorClb(data);
          },
        );
      }

      createMultiContract(item: any) {
        if (!confirm(this.gettext('Do you want to create multicontract?'))) {
          return;
        }

        return this.MulticontractService.createContractFromTicket({
          id: item.id,
        }).then(
          () => {
            this.GtUtils.dismissAllModals();
            this.GtUtils.overlay('hide');
          },
          (data: any) => {
            this.GtUtils.errorClb(data);
          },
        );
      }

      openEmailModal(contract: any) {
        return this.ClientsService.sendEmailModal(
          this.MulticontractService.getContractModelName(contract),
          contract.id,
        );
      }

      capitalize(text: any) {
        if (!text) {
          return text;
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    },
  ],
};
