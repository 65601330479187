import ng from 'angular';

import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').directive('freightRow', directive);

  function directive() {
    return {
      template: require('./freight-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        freight: '<?',
        onUpdate: '&',
        edit: '=?',
      },
    };
  }

  Controller.$inject = [
    '$rootScope',
    'ClientsService',
    'LogisticsService',
    'CoreService',
    'GtUtils',
    'gettext',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    ClientsService: any,
    LogisticsService: LogisticsService,
    CoreService: CoreService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.createDeliverer = createDeliverer;
    vm.freight = vm.freight || getNewTariff();
    vm.save = save;
    vm.destroy = destroy;
    vm.toggleEdit = toggleEdit;
    vm.buQueryParams = { user: $rootScope.user.id };

    ////////////////

    function save() {
      if (vm.freight.id) {
        return LogisticsService.LogisticFreight.update(
          vm.freight,
          function () {
            vm.edit = false;
            vm.onUpdate();
          },
          _error,
        ).$promise;
      }
      return LogisticsService.LogisticFreight.save(
        vm.freight,
        function () {
          vm.freight = getNewTariff();
          vm.onUpdate();
        },
        _error,
      ).$promise;
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete Freight?');
      if (!confirm(msg)) {
        return;
      }
      return LogisticsService.LogisticFreight.delete(
        {
          id: vm.freight.id,
        },
        vm.onUpdate,
        _error,
      );
    }

    function createDeliverer() {
      return ClientsService.roleModal({
        model_name: 'Deliverer',
        prices_section: true,
      });
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
    }

    function toggleEdit(key: any) {
      vm.edit = key;
    }

    function getNewTariff() {
      return { business_unit: Object.assign([], CoreService.getDefaultBuList()).shift() };
    }
  }
})();
