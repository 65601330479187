import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import { useComponent } from '~/shared/lib/components';

import type { PnlLogisticListContainerProps } from './pnl-logistic-list.model';
import { PnlLogisticListModel } from './pnl-logistic-list.model';

export const PnlLogisticContainer = (props: PnlLogisticListContainerProps) => {
  const { ComponentProvider } = useComponent(props, PnlLogisticListModel);

  return (
    <ComponentProvider>
      <EntityListContainer />
    </ComponentProvider>
  );
};
