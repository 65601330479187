import { ResetPasswordPage } from '~/pages/common/reset-password';
import { createFileRoute, redirect } from '~/shared/lib/router';

export const Route = createFileRoute('/reset-password')({
  component: ResetPasswordPage,
  beforeLoad: ({ context }) => {
    const { isAuthenticated } = context.auth;

    if (isAuthenticated) {
      return redirect({ to: '/' });
    }
  },
});
