import { coreSeasonsList, coreSeasonsPredictionsRetrieve } from '~/shared/api';
import { EntityRepository, type Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Season, SeasonListParams } from '../lib/types';

export class SeasonRepository extends EntityRepository<Season, SeasonListParams> {
  readonly entityName = 'core.Season';

  getFn = (_id: number): Promise<Season> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: Season): Promise<Season> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Season>): Promise<Season> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: SeasonListParams): Promise<Paginated<Season>> => {
    const { data } = await coreSeasonsList({ query });
    return { records: data.results, count: data.count };
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await coreSeasonsPredictionsRetrieve({ query: { title: input } });
    return data.results;
  };
}
