import type { FieldDef } from '~/core/datasets';
import { dateApiString } from '~/shared/lib/date';
import { t } from '~/shared/lib/i18n';
import { emailSchema, requireSchema } from '~/shared/lib/utils';

import type { User, UserQueryParams } from '../../lib/types';

const langOptions = {
  uk: { value: 'uk', label: 'Ukrainian' },
  en: { value: 'en', label: 'English' },
  ru: { value: 'ru', label: 'Russian' },
};

export const getUserFields = (useWorkStatus: boolean) => {
  const wokStatusFields = [
    'profile.work_status',
    'profile.work_status_start',
    'profile.work_status_end',
    'profile.work_status_note',
  ];
  const fields: FieldDef<User, UserQueryParams>[] = [
    {
      key: 'username',
      dataType: 'string',
      title: t('username / login'),
      schema: requireSchema('Username is required'),
    },
    {
      key: 'first_name',
      dataType: 'string',
      title: t('first name'),
      schema: requireSchema('First name is required'),
    },
    {
      key: 'last_name',
      dataType: 'string',
      title: t('last name'),
    },
    {
      key: 'profile.phone_number',
      setValue: ({ record, value }) => ({
        ...record,
        profile: { ...record.profile, phone_number: value },
      }),
      dataType: 'phone',
      title: t('phone'),
    },
    {
      key: 'email',
      dataType: 'text',
      title: t('email'),
      editing: true,
      schema: emailSchema(),
    },
    {
      key: 'is_active',
      dataType: 'checkbox',
      title: t('active'),
      editing: false,
    },
    {
      key: 'is_staff',
      dataType: 'checkbox',
      title: t('access admin'),
      editing: false,
    },
    {
      key: 'is_superuser',
      dataType: 'checkbox',
      title: t('superuser'),
      editing: false,
    },
    {
      key: 'profile.business_units',
      dataType: 'entity',
      title: t('business units'),
      typeExtra: { entity: 'core.BusinessUnit' },
      setValue: ({ record, value }) => ({
        ...record,
        profile: {
          ...record.profile,
          business_units_ids: value?.map((unit) => unit.id),
        },
      }),
    },
    {
      key: 'date_joined',
      dataType: 'date',
      title: t('date joined'),
      editing: false,
    },
    {
      key: 'last_login',
      dataType: 'date',
      title: t('last login'),
      editing: false,
    },
    {
      key: 'profile.telegram_username',
      dataType: 'string',
      setValue: ({ record, value }) => ({
        ...record,
        profile: { ...record.profile, telegram_username: value },
      }),
      title: t('telegram'),
    },
    {
      key: 'profile.language',
      dataType: 'select',
      typeExtra: { options: Object.values(langOptions) },
      getValue: ({ profile: { language: lng } }) => langOptions[lng],
      setValue: ({ record, value }) => ({
        ...record,
        profile: {
          ...record.profile,
          language: value?.value as unknown as User['profile']['language'],
        },
      }),
      title: t('language'),
    },
    {
      key: 'profile.simple_system',
      setValue: ({ record, value }) => ({
        ...record,
        profile: { ...record.profile, simple_system: !!value },
      }),
      dataType: 'checkbox',
      title: t('simple'),
    },
    {
      key: 'profile.wizard',
      dataType: 'checkbox',
      setValue: ({ record, value }) => ({
        ...record,
        profile: { ...record.profile, wizard: !!value },
      }),
      title: t('wizard'),
    },
    {
      key: 'profile.work_status',
      dataType: 'string',
      setValue: ({ record, value }) => ({
        ...record,
        profile: { ...record.profile, work_status: value ?? '' },
      }),
      title: t('work status'),
    },
    {
      key: 'profile.work_status_start',
      dataType: 'date',
      setValue: ({ record, value }) => ({
        ...record,
        profile: { ...record.profile, work_status_start: value ? dateApiString(value) : null },
      }),
      title: t('work status start'),
    },
    {
      key: 'profile.work_status_end',
      dataType: 'date',
      setValue: ({ record, value }) => ({
        ...record,
        profile: { ...record.profile, work_status_end: value ? dateApiString(value) : null },
      }),
      title: t('work status end'),
    },
    {
      key: 'profile.work_status_note',
      dataType: 'text',
      setValue: ({ record, value }) => ({
        ...record,
        profile: { ...record.profile, work_status_note: value ?? '' },
      }),
      title: t('work status note'),
    },
  ];
  return useWorkStatus ? fields : fields.filter((f) => !wokStatusFields.includes(f.key));
};
