import ng from 'angular';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive('allInfoTab', directive);

  function directive() {
    return {
      template: require('./info-tab.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        model: '=',
        formName: '=',
        fieldServiceName: '@',
      },
    };
  }

  Controller.$inject = ['GtUtils', 'FormFieldParamsService', 'ModelFieldsService'];

  function Controller(
    this: any,
    GtUtils: GtUtilsService,
    FormFieldParamsService: FormFieldParamsService,
    ModelFieldsService: any,
  ) {
    const vm = this;

    vm.openParamsModal = openParamsModal;

    vm.form = undefined;
    vm.fields = [];
    vm.updating = true;

    vm.$onInit = function () {
      updateFields();
    };

    function openParamsModal() {
      FormFieldParamsService.fieldsConfigModal(getFormConfig(), { draggableFields: true }).then(
        updateFields,
      );
    }

    function updateFields() {
      FormFieldParamsService.getFields(getFormConfig())
        .then(function (fields: any) {
          const consolidatedFields = moveFieldsToFirstGroup(fields);
          reorderFields(consolidatedFields);
        })
        .catch(GtUtils.errorClb);
    }

    function reorderFields(consolidatedFields: any) {
      return FormFieldParamsService.formFieldParams
        .query({ form_name: vm.formName })
        .$promise.then(function (data: any) {
          const indexMap = data.reduce((map: any, item: any) => {
            map[item.field_name] = item.index;
            return map;
          }, {});

          consolidatedFields.fieldGroup[0].fieldGroup.forEach((field: any) => {
            field.index =
              typeof indexMap[field.key] !== 'undefined' ? Number(indexMap[field.key]) : 9999;
          });

          consolidatedFields.fieldGroup[0].fieldGroup.sort((a: any, b: any) => a.index - b.index);

          consolidatedFields.fieldGroup[0].fieldGroup.forEach((field: any) => {
            delete field.index;
          });

          vm.fields = [consolidatedFields];
          vm.updating = false;
        });
    }

    function moveFieldsToFirstGroup(fields: any) {
      const targetFieldGroup = fields[0].fieldGroup[0].fieldGroup;

      for (let i = 1; i < fields.length; i++) {
        const groupFieldGroup = fields[i].fieldGroup[0].fieldGroup;

        targetFieldGroup.push(...groupFieldGroup);
      }

      return fields[0];
    }

    function getFormConfig() {
      const modelFieldService = ModelFieldsService.getFieldService(vm.fieldServiceName);
      return modelFieldService.getFields(vm.model, vm.formName);
    }
  }
})();
