import ng from 'angular';

import { formatDate } from '~/shared/lib/date';
import { notify } from '~/shared/lib/notify';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { html } from '^/shared/utils';
(function () {
  'use strict';
  ng.module('finances.legacy').component('bankOperationsContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      addButton: '<?',
    },
    template: require('./bank-operations-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'FinancesService', 'gtFilterService', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    FinancesService: FinancesService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.gridOptions = {};

    vm.openObject = openObject;
    vm.openBankOperationModal = openBankOperationModal;
    vm.openGeneralTransactionModal = openGeneralTransactionModal;
    vm.payments = [];
    vm.paymentsCount = 0;
    vm.updatePayments = updatePayments;
    vm.applyFilter = applyFilter;
    vm.destroy = destroy;
    vm.balanceTotal = {};
    vm.bank_account = undefined;
    vm.business_unit_list = [];
    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'bank-operations-container';
      vm.queryParams = {
        ordering: 'date',
        serializer: 'table_bank_operations',
        date_predicate: 'date',
        ...vm.initQueryParams,
      };
      vm.start_date = new Date();
      vm.end_date = new Date();
      vm.end_date.setDate(vm.start_date.getDate() + 1);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updatePayments();
      });
      FinancesService.BankAccount.query(
        {
          page_size: 1,
          ordering: 'create_time',
        },
        function (data: any) {
          vm.start_date = new Date();
          vm.end_date = new Date();
          vm.end_date.setDate(vm.start_date.getDate() + 1);
          vm.bank_account = data.results[0].id;
          applyFilter();
        },
      );
    };

    vm.$onChanges = function () {
      vm.queryParams = {
        ordering: 'date',
        serializer: 'table_bank_operations',
        date_predicate: 'date',
        ...vm.initQueryParams,
      };
    };

    ////////////////

    function updatePayments() {
      GtUtils.overlay('show');
      vm.newBankOperation = {
        condition: 'prepay',
        use: 'cargo',
        ...vm.queryParams,
      };
      return FinancesService.BankOperation.reportList(vm.queryParams, function (data: any) {
        vm.balanceTotal = {
          balance_start: data.start_balance,
          balance_end: data.end_balance,
        };
        vm.newBankOperation = {
          condition: 'prepay',
          use: 'cargo',
          client_role_model: 'buyer',
          ...vm.queryParams,
        };
        vm.payments = data.results;
        vm.paymentsCount = data.count;
        GtUtils.overlay('hide');
        updateGridData();
      }).$promise;
    }

    function destroy(object: any) {
      let msg = '';
      if (object.document_type == 'finance_transaction') {
        msg = gettext('Are you sure that you want delete this transaction?');
        if (!confirm(msg)) {
          return;
        }
        FinancesService.FinanceTransaction.delete({ id: object.id }, function () {
          notify(gettext('Transaction removed'));
          updatePayments();
        });
      } else {
        msg = gettext('Are you sure that you want delete this bank operation?');
        if (!confirm(msg)) {
          return;
        }
        FinancesService.BankOperation.delete({ id: object.id }, function () {
          notify(gettext('Bank operation removed'));
          updatePayments();
        });
      }
    }

    function openObject(object: any) {
      if (object.document_type == 'finance_transaction') {
        openGeneralTransactionModal(object);
      } else {
        const bankOperation = { ...object };
        delete bankOperation.counterparty;
        openBankOperationModal(bankOperation);
      }
    }

    function openBankOperationModal(object: any) {
      object.bank_account = object.bank_account || vm.bank_account;
      return FinancesService.bankOperationModal(object).then(updatePayments);
    }

    function openGeneralTransactionModal(object: any) {
      return FinancesService.generalTransactionModal(object).then(updatePayments);
    }

    function applyFilter() {
      vm.queryParams = Object.assign(vm.queryParams, {
        start_date: formatDate(vm.start_date, 'dd.MM.yyyy'),
        end_date: formatDate(vm.end_date, 'dd.MM.yyyy'),
        bankaccount_id: vm.bank_account,
        business_unit_list: vm.business_unit_list,
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    }

    function updateGridData() {
      vm.gridOptions = {
        data: [],
        enableSorting: false,
        columnDefs: [
          {
            field: 'date',
            displayName: gettext('Date of payment'),
            enableColumnMenu: false,
            enableSorting: false,
            cellFilter: 'date:" dd/MM"',
            minWidth: 60,
          },
          {
            field: 'id',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate: html`<a
              class="btn to-pay-btn btn-success btn-xs"
              ng-click="grid.appScope.vm.openObject(row.entity)"
              style="margin-top: 2px;"
            >
              <i
                class="fa"
                ng-class="{'fa-wrench': row.entity.document_type == 'finance_transaction', 'fa-compass': row.entity.document_type == 'bank_operation',}"
              ></i>
              {{COL_FIELD}}
            </a>`,
          },
          {
            field: 'debit_amount',
            displayName: gettext('Dt'),
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate: html`<span class="tooltip" data-tip="\${{COL_FIELD || 0 | number:2}}">
              \${{COL_FIELD || 0 | number:2}}
            </span>`,
          },
          {
            field: 'credit_amount',
            displayName: gettext('Ct'),
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate: html`<span class="tooltip" data-tip="\${{COL_FIELD || 0 | number:2}}">
              \${{COL_FIELD || 0 | number:2}}
            </span>`,
          },
          {
            field: 'counterparty',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate: html`<span class="tooltip" data-tip="{{COL_FIELD}}">
              {{COL_FIELD}}
            </span>`,
          },
          {
            field: 'business_unit_title',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate: html`<span class="tooltip" data-tip="{{COL_FIELD}}">
              {{COL_FIELD}}
            </span>`,
          },
          {
            field: 'balance',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate: html`<span
              class="tooltip pull-right"
              data-tip="{{COL_FIELD || 0 | number:2}}"
              style="margin-right: 30px"
            >
              (<span
                ng-class="{'alert-success': row.entity.sign == '+', 'alert-danger': row.entity.sign == '-'}"
                >{{row.entity.sign}}\${{row.entity.delta || 0 | number:2}}</span
              >) {{COL_FIELD || 0 | number:2}}
            </span>`,
          },
          {
            field: 'delete',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate: html`<a
              style="margin-top: 2px;"
              class="btn btn-danger btn-xs"
              ng-click="grid.appScope.vm.destroy(row.entity)"
            >
              <i class="fa fa-trash"></i>
            </a>`,
          },
        ],
      };
      vm.payments.forEach(function (payment: any) {
        if (payment.debit_amount) {
          payment.sign = '+';
          payment.delta = payment.debit_amount;
        } else if (payment.credit_amount) {
          payment.sign = '-';
          payment.delta = payment.credit_amount;
        }
        vm.gridOptions.data.push({
          id: payment.id,
          document_type: payment.document_type,
          date: payment.date,
          debit_amount: payment.debit_amount,
          credit_amount: payment.credit_amount,
          counterparty: payment.counterparty,
          business_unit_title: payment.business_unit_title,
          balance: payment.balance,
          amount: payment.amount_USD,
          sign: payment.sign,
          delta: payment.delta,
        });
      });
    }
  }
})();
