import type ng from 'angular';

import template from './costs-distribution-container.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const CostsDistributionContainer = {
  bindings: {
    items: '<?',
    mode: '<',
    selectAll: '&',
    onFinish: '&',
  },
  template,
  controller: [
    '$state',
    '$rootScope',
    '$scope',
    '$q',
    'ContractChargeFormFieldsService',
    'ContractsService',
    'FinancesService',
    'GtUtils',
    class CostsDistributionContainerController {
      $isValid = true;
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      ContractChargeFormFieldsService: any;
      ContractsService: ContractsService;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      checkboxVisibilityFunc: any;
      commonFields: any;
      createdCosts: any;
      createdCostsIds: any;
      defaultCurrencyId: number;
      excludeGroups: any;
      fields: any;
      items: any;
      mode: any;
      modes: any;
      onFinish: any;
      preparedCosts: any;
      progressBar: any;
      requiredFields: any;
      selectAll: any;
      selectedAll: any;
      setContractChoices: any;
      totals: any;
      transportCharge: any;
      volumeChoice: any;
      volumeChoices: any;
      constructor(
        $state: ng.ui.IStateService,
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $q: ng.IQService,
        ContractChargeFormFieldsService: any,
        ContractsService: ContractsService,
        FinancesService: FinancesService,
        GtUtils: GtUtilsService,
      ) {
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$scope = $scope;
        this.$q = $q;
        this.ContractChargeFormFieldsService = ContractChargeFormFieldsService;
        this.ContractsService = ContractsService;
        this.FinancesService = FinancesService;
        this.GtUtils = GtUtils;

        this.defaultCurrencyId = this.ContractChargeFormFieldsService.defaultCurrency.id;
        this.transportCharge = {};
        this.preparedCosts = [];
        this.selectedAll = false;
        this.mode = 'logistic';
        this.modes = [
          { key: 'clone', title: 'Clone charge to transport' },
          { key: 'distribute_volume', title: 'Distribute charge on transport by volume' },
          { key: 'distribute_count', title: 'Distribute charge on transport equal parts' },
        ];
        this.volumeChoice = 'movements';
        this.volumeChoices = [
          { key: 'movements', title: 'From movements' },
          { key: 'volume_received', title: 'Volume received' },
          { key: 'volume_departed', title: 'Volume departed' },
          { key: 'volume_departed_consignment', title: 'Volume departed consignment' },
          { key: 'volume_departed_real', title: 'Volume departed real' },
          { key: 'volume_boarded', title: 'Volume boarded' },
        ];
        this.setContractChoices = [
          { key: 'sale_contract', title: 'Sale contract' },
          { key: 'purchase_contract', title: 'Purchase contract' },
        ];
        this.totals = {};
        this.items = [];
        this.excludeGroups = ['ALLOCATION', 'CUSTOM_VALUES'];
        this.commonFields = [
          'charge',
          'is_gain',
          'status',
          'commodity',
          'date',
          'currency',
          'currency_exchange',
          'price',
          'price_t',
          'vat_option',
          'vat_value',
          'clientrole',
          'responsible',
          'additional_info',
          'trader_note',
        ];
        this.requiredFields = [
          'charge',
          'status',
          'date',
          'currency',
          'currency_exchange',
          'price',
          'price_t',
          'vat_option',
          'vat_value',
          'clientrole',
        ];
        this.checkboxVisibilityFunc = () => true;
      }
      getDefaultVolumeChoice() {
        return this.$rootScope.user.settings.DEFAULT_VALUES
          .costs_distribution_default_volume_source;
      }
      $onInit() {
        this.volumeChoice = this.getDefaultVolumeChoice();
        this.updateFields();
        this.$scope.$watchCollection(
          () => this.transportCharge,
          () => this.validateBaseCost(),
        );
        this.initTransportCharge();
        this.updateCheckboxVisibilityFunc();
      }
      $onChanges(changes: any) {
        if (changes.items) {
          this.refreshCheckboxes();
        }
      }
      updateCheckboxVisibilityFunc() {
        if (this.mode === 'contract') {
          this.checkboxVisibilityFunc = () => true;
          return;
        }
        if (this.mode === 'logistic' && this.volumeChoice === 'movements') {
          this.checkboxVisibilityFunc = () => true;
          return;
        }
        if (this.mode === 'logistic') {
          this.checkboxVisibilityFunc = (item: any) => Boolean(item[this.volumeChoice]);
        }
      }
      refreshCheckboxes() {
        const selectedIds = this.getSelectedItems().map((item: any) => item.id);
        this.updateCheckboxVisibilityFunc();

        this.items.forEach((item: any) => {
          item._showCheckbox = this.checkboxVisibilityFunc(item);
          item._selected = item._showCheckbox && selectedIds.includes(item.id);
        });
      }

      toggleSelectAll() {
        this.selectedAll = !this.selectedAll;
        this.selectAll({ checked: this.selectedAll });
      }
      validateBaseCost() {
        let result = true;
        result &&= ['charge', 'status', 'date', 'currency', 'clientrole'].every(Boolean);
        result &&= ['price', 'price_t'].some(Boolean);
        result &&= !this.transportCharge.vat_option || Boolean(this.transportCharge.vat_value);
        result &&=
          this.transportCharge.currency === this.defaultCurrencyId ||
          Boolean(this.transportCharge.currency_exchange);
        this.$isValid = result;
      }
      getSelectedItems() {
        return this.items.filter((item: any) => item._selected);
      }

      initTransportCharge() {
        return {
          currency: this.defaultCurrencyId,
          currency_exchange: undefined,
        };
      }
      initProgressBar() {
        this.progressBar = {
          count: this.preparedCosts.length,
          progress: 0,
          percentage: 0,
        };
      }
      processProgressPar() {
        this.progressBar.progress++;
        this.progressBar.percentage = Math.round(
          (100 * this.progressBar.progress) / this.progressBar.count,
        );
        return this.progressBar.progress;
      }
      getClassName(count: number) {
        return (
          {
            1: 'form-group-container col-sm-12 col-xs-12',
            2: 'form-group-container col-sm-6 col-xs-6',
            3: 'form-group-container col-sm-4 col-xs-4',
            4: 'form-group-container col-sm-3 col-xs-3',
            5: 'form-group-container col-sm-2 col-xs-2',
            6: 'form-group-container col-sm-2 col-xs-2',
          }[count] ?? 'form-group-container col-sm-1 col-xs-1'
        );
      }
      updateFields() {
        this.ContractChargeFormFieldsService.getFields(undefined, this.getFormConfig()).then(
          (fields: any) => (this.fields = fields),
        );
      }

      getFieldGroups() {
        const fieldGroups = this.ContractChargeFormFieldsService.getFieldGroups(
          this.transportCharge,
          true,
        );
        const groups = Object.keys(fieldGroups);

        return [groups, fieldGroups];
      }

      getFormConfig() {
        const [groups, fieldGroups] = this.getFieldGroups();
        const className = 'contract-modal-form-container' + ' ' + this.getClassName(groups.length);
        return {
          formName: 'contract-charge-distribute',
          fieldsDef: groups.map((label: any) => ({
            className: className,

            fieldGroup: [
              {
                templateOptions: { label: label },
                wrapper: 'gt-panel',
                fieldGroup: fieldGroups[label].filter((field: any) =>
                  this.commonFields.includes(field.key),
                ),
              },
            ],
          })),
        };
      }

      connectIdByMode(item: any) {
        const result: any = {};
        result[this.mode] = item.id;
        return result;
      }
      getVolumeByMode(item: any) {
        if (this.mode === 'logistic' && this.volumeChoice === 'movements') {
          return (
            item.volume_received ||
            item.volume_departed_real ||
            item.volume_departed_consignment ||
            item.volume_departed
          );
        }
        if (this.mode === 'logistic') {
          return item[this.volumeChoice];
        }
        if (this.mode === 'contract') {
          return item.final_volume || item.volume;
        }
        if (this.mode === 'passport') {
          return item.volume;
        }
      }
      getCostVolumeByMode(item: any) {
        if (this.mode === 'logistic' && this.volumeChoice === 'movements') {
          return 0;
        }
        if (this.mode === 'logistic') {
          return item[this.volumeChoice];
        }
        if (this.mode === 'contract') {
          return item.final_volume || item.volume;
        }
        if (this.mode === 'passport') {
          return item.volume;
        }
      }
      calcCostCoeff(item: any, base: any) {
        if (!base) {
          return 0;
        }
        if (item._$base === base) {
          return 1;
        }
        return item._$base / base;
      }
      distributeCost(selectedItems: any) {
        let amount = this.transportCharge.price;
        let base = selectedItems.reduce((res: any, item: any) => res + item._$base, 0);
        this.preparedCosts = selectedItems.map((item: any) => {
          const cost = {
            ...this.transportCharge,
            ...this.connectIdByMode(item),
            volume: item._$volume,
          };
          cost.price = Math.round(amount * 100 * this.calcCostCoeff(item, base)) / 100;
          amount -= cost.price;
          base -= item._$base;
          return cost;
        });
        this.createdCosts = [];
      }
      distributeCostsEvenly() {
        this.distributeCost(
          this.getSelectedItems().map((item: any) => ({
            ...item,
            _$base: 1,
          })),
        );
      }
      distributeCostsByVolume() {
        this.distributeCost(
          this.getSelectedItems().map((item: any) => ({
            ...item,
            _$base: this.getVolumeByMode(item),
            _$volume: this.getCostVolumeByMode(item),
          })),
        );
      }
      cloneCosts() {
        this.preparedCosts = this.getSelectedItems().map((item: any) => ({
          ...this.transportCharge,
          ...this.connectIdByMode(item),
          volume: this.getVolumeByMode(item),
        }));
        this.createdCosts = [];
      }
      cleanDistribution() {
        this.preparedCosts = [];
      }
      afterSave() {
        this.createdCosts = this.preparedCosts.filter((item: any) => item.id);
        this.createdCostsIds = this.createdCosts.map((item: any) => item.id);
        this.preparedCosts = this.preparedCosts.filter((item: any) => !item.id);
      }
      saveCost(charge: any) {
        let when;
        if (charge.id) {
          when = this.ContractsService.ContractCharge.update(
            { id: charge.id },
            { charge },
          ).$promise;
        } else {
          when = this.ContractsService.ContractCharge.save(charge).$promise;
        }
        return when
          .then((data: any) => this.getCostRowData(data.id))
          .then((costData: any) => (charge = Object.assign(charge, { ...charge, ...costData })))
          .then(() => {
            if (this.preparedCosts.filter((cost: any) => !cost.id).length) {
              return;
            }
            this.afterSave();
          });
      }
      getCostRowData(id: number) {
        return this.ContractsService.ContractCharge.queryInfo({ id_list: [id] }).$promise.then(
          (data: any) => data.results.pop(),
        );
      }
      saveCosts() {
        this.initProgressBar();
        const promises = this.preparedCosts.map((cost: any, index: number) => {
          let when;
          if (cost.id) {
            when = this.ContractsService.ContractCharge.update({ id: cost.id }, { cost }).$promise;
          } else {
            when = this.ContractsService.ContractCharge.save(cost).$promise;
          }
          return when
            .then((saved: any) => {
              if (saved) {
                this.preparedCosts[index] = saved;
              }
              return this.processProgressPar();
            })
            .catch(this.GtUtils.errorClb);
        });
        this.$q.all(promises).then(() => this.afterSave());
      }
      close() {
        return this.onFinish();
      }
    },
  ],
};
