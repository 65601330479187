import {
  Charge,
  ChargePatch,
  ChargePost,
  ChargeRecord,
  ChargesQueryParams,
} from 'src/features/finances/contract-charges';

import {
  contractsContractChargesCreate,
  contractsContractChargesDestroy,
  contractsContractChargesListInfoRetrieve,
  contractsContractChargesPartialUpdate,
  contractsContractChargesRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';
import { EntityOption } from '~/shared/ui/data-types';

@singleton()
export class ChargesRepository extends EntityRepository<
  Charge,
  ChargesQueryParams,
  ChargeRecord,
  ChargePost,
  ChargePatch
> {
  readonly entityName = 'finances.Charge';

  getFn = async (id: number) => {
    const { data } = await contractsContractChargesRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: ChargesQueryParams) => {
    const { data } = await contractsContractChargesListInfoRetrieve({ query });
    return { records: data.results, count: data.count, totals: data.totals };
  };

  searchFn = (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  createFn = async (dto: ChargePost) => {
    const res = await contractsContractChargesCreate({
      body: { ...dto },
    });

    return res.data;
  };

  updateFn = async (entity: ChargePatch) => {
    const { data } = await contractsContractChargesPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  deleteFn = async (id: number) => {
    await contractsContractChargesDestroy({ path: { id } });
  };
}
