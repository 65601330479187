export const GtLogo = {
  bindings: {
    clientSettings: '<',
  },
  template: '<img ng-src="{[{ $ctrl.logoPath }]}" alt="GrainTrack logo" />',
  controller: [
    class {
      clientSettings: any;
      logoPath: any;
      constructor() {
        this.logoPath = '';
      }

      $onChanges() {
        if (this.clientSettings) {
          this.logoPath = this.getLogoPath(this.clientSettings);
        }
      }

      getLogoPath(settings: any) {
        const { theme, logo_dark: logoDark, logo_light: logoLight, host } = settings;

        const src = '/assets/logo';
        const logo = logoDark || logoLight ? 'client' : 'default';

        const config: any = {
          dark: {
            default: host === 'commotrack' ? `${src}/alt_logo.png` : `${src}/logo.png`,
            client: logoDark,
          },
          light: {
            default: host === 'commotrack' ? `${src}/alt_logo_light.png` : `${src}/logo_light.png`,
            client: logoLight,
          },
        };

        return config[theme === 'dark' ? 'dark' : 'light'][logo];
      }
    },
  ],
};
