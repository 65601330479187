import type { FinancesAnalyticsService } from '^/app/finances/components/analytics/analytics.service';

export const FinancesCountsByMonthChart = {
  bindings: {},
  template:
    '<charts-block config="$ctrl.chartConfig" chart-type="bar" hide-header="true"></charts-block>',
  controller: [
    'FinancesAnalyticsService',
    class {
      FinancesAnalyticsService: FinancesAnalyticsService;
      chartConfig: any;
      constructor(FinancesAnalyticsService: FinancesAnalyticsService) {
        this.FinancesAnalyticsService = FinancesAnalyticsService;
        this.chartConfig = undefined;
      }

      $onInit() {
        this.FinancesAnalyticsService.getCountsByMonthChartConfig().then(
          (data: any) => (this.chartConfig = data),
        );
      }
    },
  ],
};
