import { financesBalancePaymentPredictionsRetrieve } from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { BalancePayment, BalancePaymentQueryParams } from '../lib';

export class BalancePaymentRepository extends EntityRepository<
  BalancePayment,
  BalancePaymentQueryParams
> {
  readonly entityName = 'finances.BalancePayment';

  getFn = (_id: number): Promise<BalancePayment> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: BalancePaymentQueryParams): Promise<Paginated<BalancePayment>> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: BalancePayment): Promise<BalancePayment> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<BalancePayment>): Promise<BalancePayment> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await financesBalancePaymentPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
}
