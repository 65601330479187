import template from './transportation-storage-expenses-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const TransportationStorageExpensesPage = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    'gettext',
    'PageService',
    'LogisticsService',
    class {
      gettext: ng.gettext.gettextFunction;
      PageService: PageService;
      LogisticsService: LogisticsService;

      filterLevel: string;
      quickAdd = false;
      relatedModel: string;
      constructor(
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
        LogisticsService: LogisticsService,
      ) {
        this.gettext = gettext;
        this.PageService = PageService;
        this.LogisticsService = LogisticsService;

        this.filterLevel = '';
        this.relatedModel = 'logistics.TransportationStorageExpense';
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'transportation-storage-expenses-page';
        this.PageService.setConfig(this.getPageConfig());
      }
      getPageConfig() {
        const config: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          quickAdd: () => {
            this.quickAdd = !this.quickAdd;
          },
          pages: this.LogisticsService.getPagesConfig(),
        };

        config.filters = {
          groupBy: {},
          filterLevel: this.filterLevel,
          clicked: false,
          search: true,
          dates: true,
          useFlexDateRange: true,
          invert_filters: true,
          dateSelects: [],
          selects: [],
          nestedMultiSelects: [
            {
              argument: 'warehouse_type_list',
              placeholder: this.gettext('Warehouse type'),
              items: [
                { id: 'warehouse', title: this.gettext('Warehouse') },
                { id: 'farm', title: this.gettext('Farm') },
                { id: 'elevator', title: this.gettext('Elevator') },
                { id: 'terminal', title: this.gettext('Terminal') },
                { id: 'other', title: this.gettext('Other') },
              ],
            },
          ],
          multiSelects: [
            {
              argument: 'warehouse_list',
              placeholder: this.gettext('Counterparty'),
              resource: 'logistics.warehouse',
              queryParams: () => ({ is_group: '0' }),
              related_fk: 'warehouse',
              related_model: this.relatedModel,
            },
            {
              argument: 'invoice_list',
              placeholder: this.gettext('Invoice'),
              resource: 'finances.Finance',
              related_fk: 'positions__invoiceposition__invoice',
              related_model: this.relatedModel,
            },
          ],
        };
        return config;
      }
    },
  ],
};
