import ng from 'angular';

import { UserListContainer } from '~/features/accounts';
import { DashboardContainer } from '~/features/common/bi-dashboards';
import { CounterpartiesContainer } from '~/features/crm/counterparties/components';
import { RequestsListContainer } from '~/features/deals/requests/components';
import { CertsLogisticContainer } from '~/features/execution/logistic/certs/components/certs-logistic-container';
import { CostsLogisticContainer } from '~/features/execution/logistic/costs/components/costs-logistic-container';
import { ExportLogisticContainer } from '~/features/execution/logistic/export/components/export-logistic-container';
import { InfoLogisticContainer } from '~/features/execution/logistic/info/components/info-logistic-container';
import { PnlLogisticContainer } from '~/features/execution/logistic/pnl/components/pnl-logistic-container';
import { PurchaseLogisticContainer } from '~/features/execution/logistic/purchase/components/purchase-logistic-container';
import { QualitiesLogisticContainer } from '~/features/execution/logistic/qualities/components/qualities-logistic-container';
import { SaleLogisticContainer } from '~/features/execution/logistic/sale/components/sale-logistic-container';
import { FinanceListContainer } from '~/features/finances/finances/components/finance-container/finance-list-container';

import { angularize } from '^/shared/utils/react';

const gtMainContainers = ng.module('gtMainContainers', []);

angularize(DashboardContainer, 'gtmDashboardContainer', gtMainContainers, {
  store: '<',
  title: '<?',
  selector: '<?',
});

angularize(UserListContainer, 'gtmUserListContainer', gtMainContainers, {
  viewMode: '<?',
  viewModel: '<?',
  initPageParams: '<?',
  containerId: '<?',
});

angularize(CounterpartiesContainer, 'counterpartiesListContainer', gtMainContainers, {
  containerId: '<',
  model: '<?',
  viewMode: '<?',
  legacyHandlers: '<?',
});

angularize(RequestsListContainer, 'requestsListContainer', gtMainContainers, {
  viewModel: '<?',
  viewMode: '<?',
  initPageParams: '<?',
});

angularize(InfoLogisticContainer, 'infoLogisticListContainer', gtMainContainers, {
  viewMode: '<?',
  viewModel: '<?',
  initPageParams: '<?',
  containerId: '<?',
});
angularize(PurchaseLogisticContainer, 'purchaseLogisticListContainer', gtMainContainers, {
  viewMode: '<?',
  viewModel: '<?',
  initPageParams: '<?',
  containerId: '<?',
});
angularize(SaleLogisticContainer, 'saleLogisticListContainer', gtMainContainers, {
  viewMode: '<?',
  viewModel: '<?',
  initPageParams: '<?',
  containerId: '<?',
});
angularize(CostsLogisticContainer, 'costsLogisticListContainer', gtMainContainers, {
  viewMode: '<?',
  viewModel: '<?',
  initPageParams: '<?',
  containerId: '<?',
});
angularize(CertsLogisticContainer, 'certsLogisticListContainer', gtMainContainers, {
  viewMode: '<?',
  viewModel: '<?',
  initPageParams: '<?',
  containerId: '<?',
});
angularize(QualitiesLogisticContainer, 'qualitiesLogisticListContainer', gtMainContainers, {
  viewMode: '<?',
  viewModel: '<?',
  initPageParams: '<?',
  containerId: '<?',
});
angularize(ExportLogisticContainer, 'exportLogisticListContainer', gtMainContainers, {
  viewMode: '<?',
  viewModel: '<?',
  initPageParams: '<?',
  containerId: '<?',
});
angularize(PnlLogisticContainer, 'pnlLogisticListContainer', gtMainContainers, {
  viewMode: '<?',
  viewModel: '<?',
  initPageParams: '<?',
  containerId: '<?',
});

angularize(FinanceListContainer, 'financeListContainer', gtMainContainers, {
  viewModel: '<?',
  viewMode: '<?',
  initPageParams: '<?',
});

export const gtMainContainersModule = gtMainContainers.name;
