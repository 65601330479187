import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { HelpButtonsService } from '^/app/core/legacy/help-buttons/help-buttons.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('vesselPage', {
    bindings: {},
    template: require('./vessel-details-page.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$stateParams', 'HelpButtonsService', 'PageService', 'LogisticsService'];

  function Controller(
    this: any,
    $stateParams: ng.ui.IStateParamsService,
    HelpButtonsService: HelpButtonsService,
    PageService: PageService,
    LogisticsService: LogisticsService,
  ) {
    const vm = this;
    vm.tab = 'voyages';
    vm.vessel = { id: $stateParams.id };
    vm.openVesselModal = openVesselModal;

    activate();

    ////////////////

    function activate() {
      updateData();
    }

    function updateData() {
      HelpButtonsService.setConfig(getPageConfig());
      return LogisticsService.Vessel.get({ id: vm.vessel.id }, function (data: any) {
        vm.vessel = data;
        PageService.setPageTitle(vm.vessel.name);
      }).$promise;
    }

    function getPageConfig() {
      return {
        print: {},
      };
    }

    function openVesselModal(vessel: any) {
      return LogisticsService.vesselModal(vessel).then(updateData);
    }
  }
})();
