import { AuthStore } from '~/core/auth/services';
import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import { container, injectable } from '~/shared/lib/di';
import { map } from '~/shared/lib/state';

import { getUserFields } from '../../lib';
import { User, UserPatch, UserPost, UserQueryParams } from '../../lib/types';
import { UserRepository } from '../../services/user.repository';

export type UserListContainerProps = ContainerProps<UserQueryParams>;

@injectable()
export class UserListContainerModel extends EntityListContainerModel<
  User,
  UserQueryParams,
  UserRepository,
  UserListContainerProps,
  User,
  UserPost,
  UserPatch
> {
  entityRepoClass = UserRepository;

  private readonly authStore = container.resolve(AuthStore);

  fieldDefs$ = this.authStore.currentUser$.pipe(
    map((user) => getUserFields(user.settings?.USE_WORK_STATUS)),
  );
}
