import React from 'react';

import { useSidebar } from '~/core/theme/hooks';
import { cn } from '~/shared/lib/utils';
import { PreviewIcon } from '~/shared/ui/icons/';

export const SidebarToggle: React.FC = () => {
  const { sidebarToggled, sidebarOpen } = useSidebar();

  return (
    <div className="dark:bg-primary-foreground absolute top-[13px] right-[25px] z-20 h-4 w-4 rounded opacity-0 transition-opacity duration-300 group-hover:opacity-100">
      <button
        onClick={() => sidebarToggled(!sidebarOpen)}
        className={cn(
          'rounded bg-transparent p-1 focus:outline-none',
          'hover:opacity-80',
          'transition duration-100 ease-in-out',
        )}
      >
        <PreviewIcon
          size={17}
          className={cn(
            'text-text-main-tertiary transition-opacity duration-300 ease-in-out',
            sidebarOpen ? 'rotate-0' : 'rotate-180',
            'hover:opacity-90',
          )}
        />
      </button>
    </div>
  );
};

SidebarToggle.displayName = 'SidebarToggle';
