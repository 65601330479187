import type ng from 'angular';

import template from './voyage-tariff-list-table-view.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export const VoyageTariffListTableView = {
  bindings: {
    list: '<',
    count: '<',
    total: '<',
    filterLevel: '<',
    applyFilters: '&',
    saveItem: '&',
    destroy: '&',
    updateData: '&',
    setHovering: '&',
    tableName: '<?',
    tableClass: '<?',
  },
  template,
  controller: [
    'gettext',
    'GtUtils',
    '$window',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      GtUtils: GtUtilsService;
      applyFilters: any;
      count: number;
      destroy: any;
      filterLevel = 'voyage-tariff-list-table-view';
      gettext: ng.gettext.gettextFunction;
      list: any;
      numberInput: any;
      saveItem: any;
      selection: any;
      tableApi: any;
      tableClass: any;
      tableData: any;
      tableName = '';
      tableOptions: any;
      total: any;
      updateData: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        $window: ng.IWindowService,
        $rootScope: GtRootScopeService,
      ) {
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.$window = $window;
        this.$rootScope = $rootScope;

        this.list = [];
        this.count = 0;
        this.selection = false;
        this.numberInput = false;
        this.tableOptions = {};
        this.tableData = {};
      }

      $onChanges(changes: any) {
        this.tableName = this.tableName || 'voyage-tariff';
        this.tableClass =
          this.tableClass || 'contract-charges-table voyage-tariff-table table-with-inline-add';
        if (changes.list || changes.count || changes.total) {
          this.tableOptions = this.getTableOptions();
          this.tableData = { rows: this.list, count: this.count, total: this.total };
        }
      }

      clone(item: any) {
        Object.assign(this.tableData.rows[0], item, {
          author: undefined,
          create_time: undefined,
          editor: undefined,
          update_time: undefined,
          id: undefined,
        });
      }

      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass: this.tableClass,
          applyFilters: this.applyFilters,
          selection: this.selection,
          numberInput: this.numberInput,
          configurable: true,
          showMore: true,
          templateArgs: {
            saveItem: (item: any) => this.saveItem({ item: item }),
            destroy: (item: any) => this.destroy({ item: item }),
            clone: (item: any) => this.clone(item),
            updateData: () => this.updateData(),
            setHovering: (value: any) => this.tableApi.setHovering({ value: value }),
            scrollToTop: () => this.$window.scrollTo(0, 100),
            $rootScope: this.$rootScope,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          columnDefs: [
            {
              title: this.gettext('Actions'),
              columnName: 'actions',
              class: 'td-left-align',
              cellTemplate: html`<a
                  ng-click="args.clone(item); args.scrollToTop()"
                  class="btn btn-xs table-edit-btn"
                  ng-if="!$ctrl.edit && item.id"
                >
                  <i class="fa fa-files-o"></i>
                </a>
                <a
                  class="btn btn-xs table-edit-btn"
                  ng-hide="$ctrl.edit || !item.id"
                  ng-click="$ctrl.edit = true"
                >
                  <i class="fa fa-pencil-square"></i>
                </a>
                <span ng-if="$ctrl.edit || !item.id" class="input-group-table-edit-btn">
                  <a
                    class="btn-success btn_success btn btn-md"
                    ng-if="item.id"
                    ng-click="args.saveItem(item);$ctrl.edit = false"
                  >
                    <i class="fa fa-floppy-o"></i>
                  </a>
                  <a
                    class="btn-success btn_success btn btn-md"
                    ng-if="!item.id"
                    ng-click="args.saveItem(item)"
                  >
                    <i class="fa fa-floppy-o"></i>
                  </a>
                  <a class="btn btn-md" ng-if="item.id" ng-click="$ctrl.edit = false">
                    <i class="fa fa-times"></i>
                  </a>
                  <a
                    class="btn-danger btn_danger btn btn-md"
                    ng-if="item.id"
                    ng-click="args.destroy(item.id);$ctrl.edit = false"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </span>`,
            },
            {
              title: this.gettext('Commodity'),
              columnName: 'commodity',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.commodity_title }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.commodity"
                    placeholder="'Commodity'|translate"
                    resource-name="'crops.crop'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
            },
            {
              title: this.gettext('Loading port'),
              columnName: 'loadingPort',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.loading_port_name }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.loading_port"
                    placeholder="'Loading port'|translate"
                    resource-name="'logistics.port'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
            },
            {
              title: this.gettext('Destination port'),
              columnName: 'destinationPort',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.destination_port_name }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.destination_port"
                    placeholder="'Destination port'|translate"
                    resource-name="'logistics.port'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
            },
            {
              title: this.gettext('Counterparty'),
              columnName: 'counterparty',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.forwarder_name }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.forwarder"
                    placeholder="'Forwarder'|translate"
                    resource-name="'clients.clientrole'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
            },
            {
              title: this.gettext('Charge'),
              columnName: 'charge',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.charge_title }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.charge"
                    placeholder="'Charge'|translate"
                    resource-name="'finances.charge'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
            },
            {
              title: this.gettext('Measurement'),
              columnName: 'measurement',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.measurement_title }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.measurement"
                    placeholder="'Measurement'|translate"
                    resource-name="'stockexchanges.measurement'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
            },
            {
              title: this.gettext('Quantity'),
              columnName: 'quantity',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                  {[{ item.quantity || 0 | number: 3 }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <input
                    class="form-control"
                    gt-clear-input
                    type="number"
                    ng-model="item.quantity"
                  />
                </span>
              `,
            },
            {
              title: this.gettext('Price'),
              columnName: 'price',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                  {[{ item.price || 0 | number: 3 }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <input class="form-control" gt-clear-input type="number" ng-model="item.price" />
                </span>
              `,
            },
            {
              title: this.gettext('Min amount'),
              columnName: 'minAmount',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                  {[{ item.min_amount || 0 | number: 3 }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <input
                    class="form-control"
                    gt-clear-input
                    type="number"
                    ng-model="item.min_amount"
                  />
                </span>
              `,
            },
            {
              title: this.gettext('Max amount'),
              columnName: 'maxAmount',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                  {[{ item.max_amount || 0 | number: 3 }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <input
                    class="form-control"
                    gt-clear-input
                    type="number"
                    ng-model="item.max_amount"
                  />
                </span>
              `,
            },
            {
              title: this.gettext('Price per one'),
              columnName: 'priceT',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                  {[{ item.price_t || 0 | number: 3 }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <input
                    class="form-control"
                    gt-clear-input
                    type="number"
                    ng-model="item.price_t"
                  />
                </span>
              `,
            },
            {
              title: this.gettext('Currency'),
              columnName: 'currency',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.currency_symbol }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.currency"
                    placeholder="'Currency'|translate"
                    resource-name="'finances.currency'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
            },
            {
              title: this.gettext('Currency rate'),
              columnName: 'currencyRate',
              class: 'td-left-align small-font-cell',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id">
                  {[{ item.currency_exchange_rate }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.currency_exchange"
                    placeholder="'Currency rate'|translate"
                    resource-name="'finances.currencyExchange'"
                    query-params="{local_currency: item.currency}"
                    ng-disable="!item.currency"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>`,
            },
            {
              title: this.gettext('Is gain'),
              columnName: 'is_gain',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id && item.is_gain">
                  <i class="fa fa-check-circle"></i>
                </span>
                <span ng-if="!$ctrl.edit && item.id && !item.is_gain">
                  <i class="fa fa-circle-o"></i>
                </span>

                <input
                  ng-if="$ctrl.edit || !item.id"
                  style="width: 100%;"
                  type="checkbox"
                  ng-model="item.is_gain"
                />`,
            },
            {
              title: this.gettext('Is passport cost'),
              columnName: 'useAsCost',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id && item.use_as_cost">
                  <i class="fa fa-check-circle"></i>
                </span>
                <span ng-if="!$ctrl.edit && item.id && !item.use_as_cost">
                  <i class="fa fa-circle-o"></i>
                </span>

                <input
                  ng-if="$ctrl.edit || !item.id"
                  style="width: 100%;"
                  type="checkbox"
                  ng-model="item.use_as_cost"
                />`,
            },
            {
              title: this.gettext('Use in position'),
              columnName: 'useInPosition',
              cellTemplate: html`<span ng-if="!$ctrl.edit && item.id && item.use_in_position">
                  <i class="fa fa-check-circle"></i>
                </span>
                <span ng-if="!$ctrl.edit && item.id && !item.use_in_position">
                  <i class="fa fa-circle-o"></i>
                </span>

                <input
                  ng-if="$ctrl.edit || !item.id"
                  style="width: 100%;"
                  type="checkbox"
                  ng-model="item.use_in_position"
                />`,
            },
            {
              title: this.gettext('Info'),
              columnName: 'additionalInfo',
              cellTemplate: html`<span
                  ng-if="!$ctrl.edit && item.id && item.additional_info"
                  class="smaller-label"
                >
                  <i class="fa fa-commenting"></i> {[{ item.additional_info || "---"}]}
                </span>

                <input
                  ng-if="$ctrl.edit || !item.id"
                  class="form-control gt_test_field_additional_info"
                  placeholder="{[{ 'Info'|translate }]}"
                  type="text"
                  ng-model="item.additional_info"
                />`,
            },
          ],
        };
      }
    },
  ],
};
