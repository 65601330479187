import type ng from 'angular';

import { startOfDay } from '~/shared/lib/date';
import { notify } from '~/shared/lib/notify';

import template from './shipment-schedule-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const ShipmentScheduleListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'ShipmentScheduleService',
    'gtFilterService',
    'gettext',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      ShipmentScheduleService: any;
      data: any;
      dateRange: any;
      filterLevel = 'shipment-schedule-list-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        ShipmentScheduleService: any,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.ShipmentScheduleService = ShipmentScheduleService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.data = {};
        this.queryParams = {
          status_list: ['new', 'approved', 'process'],
          ...this.initQueryParams,
        };
      }

      $onInit() {
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          if (!data.plan_start_date || !data.plan_end_date) {
            this.queryParams = Object.assign(data, this.initQueryParams);
          } else {
            this.queryParams = data;
          }
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');

        return this.ShipmentScheduleService.getTableData(this.queryParams).then((data: any) => {
          this.data = data;
          const start = startOfDay(this.queryParams.plan_start_date as string);
          const end = startOfDay(this.queryParams.plan_end_date as string);
          this.dateRange = this.ShipmentScheduleService.getDateRange(start, end);
          this.GtUtils.overlay('hide');
        });
      }

      saveDays(position: any) {
        this.GtUtils.overlay('show');
        return this.ShipmentScheduleService.saveDays(position).then(
          () => {
            notify(this.gettext('Shipment days saved.'));
            this.updateData();
            this.GtUtils.overlay('hide');
          },
          (data: any) => {
            position.errors = data.data;
            this.GtUtils.overlay('hide');
            this.GtUtils.errorClb(data);
          },
        );
      }

      openShipmentScheduleModal(plan: any, data: any) {
        return this.ShipmentScheduleService.shipmentScheduleModal(plan, data).then(() =>
          this.updateData(),
        );
      }

      applyFilter() {
        if (!this.queryParams.plan_start_date || !this.queryParams.plan_end_date) {
          return notify(this.gettext('Dates are reqired'), 'error');
        }

        const start = startOfDay(this.queryParams.plan_start_date as string);
        const end = startOfDay(this.queryParams.plan_end_date as string);
        if (start > end) {
          return notify(this.gettext('First date must be less then Second'), 'error');
        }
        return this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }
    },
  ],
};
