import React from 'react';

import { cn } from '~/shared/lib/utils';
import { Input } from '~/shared/ui/kit/input';
import { Tooltip } from '~/shared/ui/kit/tooltip';

import type { DataTypeProps } from '../../lib';
import { messageIntent } from '../../lib/constants';

export const PersonWrite: React.FC<DataTypeProps<'person'>> = ({
  value,
  onChange,
  ref,
  disabled,
  message,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.({ ...value, firstName: e.target.value });
  };

  const inputElement = (
    <Input
      ref={ref}
      defaultValue={value?.firstName ?? ''}
      onBlur={handleChange}
      disabled={disabled}
      fullWidth
      intent={message ? 'danger' : 'transparent'}
    />
  );

  return message ? (
    <Tooltip content={message.text ?? ''} side="bottom" sideOffset={4}>
      <div className={cn('relative', messageIntent[message.type])}>{inputElement}</div>
    </Tooltip>
  ) : (
    inputElement
  );
};
