import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './stock-market-prices-table.html?raw';
import type { StockMarketPricesService } from '../stock-market-prices.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';

export const StockMarketPricesTable = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    'gtFilterService',
    'StockMarketPricesService',
    'GtUtils',
    class {
      $rootScope: GtRootScopeService;
      GtUtils: GtUtilsService;
      StockMarketPricesService: StockMarketPricesService;
      derivatives: any;
      derivativesCount: number;
      filterLevel = 'stock-market-prices-table';
      form: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      constructor(
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
        StockMarketPricesService: StockMarketPricesService,
        GtUtils: GtUtilsService,
      ) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.StockMarketPricesService = StockMarketPricesService;
        this.GtUtils = GtUtils;

        this.derivatives = [];
        this.derivativesCount = 0;
        this.queryParams = { date_predicate: 'date' };
        this.form = null;
      }

      $onInit() {
        this.gtFilterService.subscribe(
          this.filterLevel,
          (params) => {
            this.queryParams = params;
            this.updateTableData();
          },
          this.queryParams,
        );
        this.updateTableData();
      }

      save() {
        if (this.form.$invalid) {
          return notify(this.gettext('Form invalid'), 'error');
        }
        return this.StockMarketPricesService.createStockMarketPrice(this.form)
          .then(() => {
            this.gtFilterService.updateQueryParams({}, this.filterLevel);
          })
          .catch(this.GtUtils.errorClb);
      }

      updateTableData() {
        this.GtUtils.overlay('show');
        this.StockMarketPricesService.getDerivativesTable(this.queryParams).then(
          (response: any) => {
            this.derivatives = response.results;
            this.derivativesCount = response.count;
            this.GtUtils.overlay('hide');
          },
        );
      }

      update(future: any) {
        return this.StockMarketPricesService.update(future)
          .then(() => {
            notify(this.gettext('Future updated'));
            this.gtFilterService.updateQueryParams({}, this.filterLevel);
          })
          .catch(this.GtUtils.errorClb);
      }

      delete(future: any) {
        return this.StockMarketPricesService.delete(future)
          .then(() => {
            notify(this.gettext('Future deleted'));
            this.gtFilterService.updateQueryParams({}, this.filterLevel);
          })
          .catch(this.GtUtils.errorClb);
      }

      parseActiveQuotes() {
        this.GtUtils.overlay('show');
        this.StockMarketPricesService.StockMarketPrice.parseActiveQuotes()
          .$promise.then(() => {
            this.updateTableData();
            notify(this.gettext('Quotes updated.'));
          })
          .catch((e: any) => {
            this.GtUtils.errorClb(e);
            this.GtUtils.overlay('hide');
          });
      }
    },
  ],
};
