import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').controller('LogiticsLogisticModalController', Controller);

  Controller.$inject = [
    '$scope',
    '$uibModalInstance',
    'logistic',
    'LogisticsService',
    'GtUtils',
    'AccountsService',
    'CropsService',
    'gettext',
    'FormFieldParamsService',
    'LogisticFormFieldsService',
    'CustomValuesService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    logistic: any,
    LogisticsService: LogisticsService,
    GtUtils: GtUtilsService,
    AccountsService: AccountsService,
    CropsService: any,
    gettext: ng.gettext.gettextFunction,
    FormFieldParamsService: FormFieldParamsService,
    LogisticFormFieldsService: any,
    CustomValuesService: CustomValuesService,
  ) {
    const vm = this;

    vm.close = close;
    vm.clone = clone;
    vm.destroy = destroy;
    vm.save = save;
    vm.option = 'edit';

    vm.logistic = logistic || {};
    vm.logEntries = [];
    vm.logEntriesCount = 0;
    vm.updateLogEntries = updateLogEntries;
    vm.errors = [];
    vm.fields = undefined;
    vm.form = undefined;
    vm.openQualitiesModal = openQualitiesModal;
    vm.warehouseParams = { logistic: vm.logistic.id };
    vm.openFieldsConfigModal = openFieldsConfigModal;

    activate();

    ////////////////

    function activate() {
      if (vm.logistic.id) {
        updateData();
      } else {
        updateFields();
        $scope.$watch('vm.logistic.auto_name', getVehicleNumber);
        $scope.$on('custom-values-updated__logistic', function (event: any, data: any) {
          vm.customValues = data;
        });
      }
    }

    function updateData() {
      return LogisticsService.Logistic.get(
        {
          id: vm.logistic.id,
          serializer: 'modal',
        },
        function (data: any) {
          vm.logistic = data;
          updateLogEntries();
          updateFields();
        },
      ).$promise;
    }

    function openFieldsConfigModal() {
      FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(updateFields);
    }

    function updateFields() {
      FormFieldParamsService.getFields(getFormConfig())
        .then((fields: any) => (vm.fields = fields))
        .catch(GtUtils.errorClb);
    }

    function updateLogEntries() {
      return AccountsService.LogEntry.query(
        {
          object_id: vm.logistic.id,
          content_type: vm.logistic.content_type,
        },
        function (data: any) {
          vm.logEntries = data.results;
          vm.logEntriesCount = data.count;
        },
      );
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete this vehicle?');
      if (!confirm(msg)) {
        return;
      }
      LogisticsService.Logistic.delete({ id: vm.logistic.id }, function () {
        notify(gettext('Vehicle removed'));
        close(null, true);
      });
    }

    function save() {
      vm.form.$invalid = true;
      if (vm.logistic.purchase_payment_conditions === 0) {
        vm.logistic.purchase_payment_conditions = null;
      }
      if (vm.logistic.sale_payment_conditions === 0) {
        vm.logistic.sale_payment_conditions = null;
      }
      if (vm.logistic.id) {
        return LogisticsService.Logistic.update(
          vm.logistic,
          function (data: any) {
            close(data, true);
            notify(gettext('Vehicle updated'));
          },
          _error,
        );
      }
      let savedLogistic: any;
      return LogisticsService.Logistic.save(vm.logistic)
        .$promise.then((data: any) => {
          savedLogistic = data;
        })
        .then(
          () =>
            vm.logistic._clonedFrom &&
            LogisticsService.LogisticQuality.query({ logistic: savedLogistic.id })
              .$promise.then((data: any) =>
                Promise.all(
                  data.results.map(({ id }: any) =>
                    LogisticsService.LogisticQuality.delete({ id }),
                  ),
                ),
              )
              .then(
                () =>
                  LogisticsService.LogisticQuality.query({ logistic: vm.logistic._clonedFrom })
                    .$promise,
              )
              .then(({ results }: any) =>
                results.map(
                  ({
                    value,
                    quality,
                    value_alt: valueAlt,
                    additional,
                    additional_alt: additionalAlt,
                    commodity,
                  }: any) => ({
                    value,
                    quality,
                    value_alt: valueAlt,
                    additional,
                    additional_alt: additionalAlt,
                    commodity,
                    logistic: savedLogistic.id,
                    contract: null,
                  }),
                ),
              )
              .then(
                (qualities: any) =>
                  LogisticsService.LogisticQuality.bulkCreateOrUpdate(qualities).$promise,
              ),
        )
        .then(() => {
          close(savedLogistic, true);
          notify(gettext('Vehicle saved'));
          CustomValuesService.createCustomValues(savedLogistic.id, vm.customValues);
        })
        .catch(_error);
    }

    function openQualitiesModal() {
      CropsService.qualitiesModal(
        vm.logistic.qualities,
        null,
        vm.logistic.cargo,
        null,
        vm.logistic.id,
      ).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        vm.logistic.qualities = data;
      });
    }

    function getVehicleNumber(params: object) {
      if (vm.logistic.auto_name) {
        return LogisticsService.Logistic.generateNumber(
          params,
          function (data: any) {
            if (data.vehicle_number) {
              vm.logistic.vehicle_number = data.vehicle_number;
            }
          },
          _error,
        );
      }
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
      vm.logistic.errors = data.data;
    }

    function clone() {
      const msg = gettext('Do you want to clone this Logistic?');
      if (!confirm(msg)) {
        return;
      }
      const newObject = { ...{ _clonedFrom: vm.logistic.id }, ...vm.logistic };
      delete newObject.qualities;
      delete newObject.id;
      return LogisticsService.logisticModal(newObject);
    }

    function getFormConfig() {
      return LogisticFormFieldsService.getFields(vm.logistic);
    }
  }
})();
