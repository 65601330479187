import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';

  ng.module('finances.legacy').component('paymentplansPageView', {
    bindings: {
      filterLevel: '<?',
    },
    template: require('./paymentplans-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    '$scope',
    'FinancesService',
    'GtUtils',
    'PageService',
    'gettext',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $scope: ng.IScope,
    FinancesService: FinancesService,
    GtUtils: GtUtilsService,
    PageService: PageService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'paymentplans-page-view';
      PageService.setConfig(getPageConfig());
    };

    // //////////////

    function getPageConfig() {
      return {
        class: 'page-header-main-payments-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.ScreenCaptureTask(),
        ],
        pages: FinancesService.getPagesConfig(),
        filters: {
          filterLevel: vm.filterLevel,
          clicked: false,
          search: true,
          nestedMultiSelects: [
            {
              argument: 'approval_status_list',
              placeholder: gettext('Approval status'),
              items: [
                { id: 'wait', title: 'Waiting for approval' },
                { id: 'approved', title: 'Approved' },
                { id: 'process', title: 'Approval in process' },
                { id: 'rejected', title: 'Rejected' },
              ],
            },
          ],
          nestedSelects: [
            {
              argument: 'wait_my_approval',
              class: '',
              items: [{ id: $rootScope.user.id, title: gettext('Yes') }],
              placeholder: gettext('Waiting my approval'),
            },
          ],
        },
      };
    }
  }
})();
