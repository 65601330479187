import ng from 'angular';

import { CustomValuesContainer } from './custom-values/custom-values-container/custom-values-container.component';
import { CustomValuesDetails } from './custom-values/custom-values-details/custom-values-details.component';
import { CustomValuesPageView } from './custom-values/custom-values-page-view/custom-values-page-view.component';
import { CustomValuesTableView } from './custom-values/custom-values-table-view/custom-values-table-view.component';
import { CustomValuesService } from './custom-values.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const customValuesModule = ng
  .module('core.customValues', [])
  .service('CustomValuesService', CustomValuesService)
  .component('customValuesPageView', CustomValuesPageView)
  .component('customValuesContainer', CustomValuesContainer)
  .component('customValuesTableView', CustomValuesTableView)
  .component('customValuesDetails', CustomValuesDetails)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('custom-fields', {
        parent: 'gt.page',
        url: '/custom-fields',
        abstract: true,
        template: '<ui-view/>',
      });
      $stateProvider.state('custom-fields.values', {
        url: '/custom-fields/values',
        component: 'customValuesPageView',
        data: {
          pageTitle: gettext('Custom values'),
        },
        resolve: {
          filterLevel: () => {
            return 'custom-values-page-view';
          },
          queryParams: [
            'PageService',
            (PageService: PageService) => {
              return PageService.syncUrlFilter('custom-values-page-view');
            },
          ],
        },
      });
      $stateProvider.state('custom-fields.fields', {
        url: '/custom-fields/fields',
        component: 'customFieldsPageView',
        data: {
          pageTitle: gettext('Custom fields'),
        },
        resolve: {
          filterLevel: () => {
            return 'custom-fields-page-view';
          },
          queryParams: [
            'PageService',
            (PageService: PageService) => {
              return PageService.syncUrlFilter('custom-fields-page-view');
            },
          ],
        },
      });
    },
  ]).name;
