import { clientsExportersPredictionsRetrieve } from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Exporter, ExporterListParams } from '../lib';

export class ExporterRepository extends EntityRepository<Exporter, ExporterListParams> {
  readonly entityName = 'clients.Exporter';

  createFn = (_entity: Exporter): Promise<Exporter> => {
    throw new Error('Method not implemented.');
  };
  getFn = (_id: number): Promise<Exporter> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: ExporterListParams): Promise<Paginated<Exporter>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await clientsExportersPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  create = (_entity: Exporter): Promise<Exporter> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Exporter>): Promise<Exporter> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
