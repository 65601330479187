import ng from 'angular';

import { WarehouseDetailsContainer } from './container/warehouse-details-container.component';
import { WarehouseModal } from './modal/warehouse-modal.component';
import { WarehouseDetailsPage } from './page/warehouse-details-page.component';
import { WarehouseDetailsView } from './view/warehouse-details-view.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const warehouseConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('logistics.warehouseDetails', {
      url: '/warehouses/:id',
      component: 'warehouseDetailsPage',
      data: { pageTitle: gettext('Warehouse') },
      resolve: {
        filterLevel: () => 'warehouse-details-page',
        queryParams: [
          'PageService',
          (PageService: PageService) => PageService.syncUrlFilter('warehouse-details-page'),
        ],
      },
    });
  },
];

export const warehouseDetails = ng
  .module('warehouse.details', [])
  .component('warehouseDetailsContainer', WarehouseDetailsContainer)
  .component('warehouseDetailsPage', WarehouseDetailsPage)
  .component('warehouseDetailsView', WarehouseDetailsView)
  .component('warehouseModal', WarehouseModal)
  .config(warehouseConfig).name;
