import type ng from 'angular';

import {
  type CropQuality,
  type Voyage,
  cropsCropQualitiesList,
  logisticsVoyagesRetrieve,
} from '~/shared/api';
import { errorHandler } from '~/shared/lib/errors';

import template from './voyage-qualities-table.html?raw';

import { html } from '^/shared/utils';

export const voyageQualitiesTable = {
  bindings: {
    voyageId: '<',
  },
  template,
  controller: [
    '$scope',
    'gettext',
    'CropsService',
    'LogisticsService',
    class {
      $scope: ng.IScope;
      gettext: ng.gettext.gettextFunction;
      CropsService: any;
      LogisticsService: any;

      voyage?: Voyage;
      voyageId!: number;

      cropQualities: CropQuality[] = [];
      commodityIdList?: string[];
      tableOptions: any;
      tableData: any;

      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        CropsService: any,
        LogisticsService: any,
      ) {
        this.$scope = $scope;
        this.CropsService = CropsService;
        this.LogisticsService = LogisticsService;
        this.gettext = gettext;

        this.tableOptions = {};
        this.tableData = {};
      }

      $onInit() {
        this.fetchVoyage();
      }

      fetchVoyage() {
        logisticsVoyagesRetrieve({ path: { id: this.voyageId } })
          .then((data) => {
            this.$scope.$apply(() => {
              this.voyage = data.data;
              this.tableOptions = this.getTableOptions();
              this.updateData()?.catch(errorHandler);
            });
          })
          .catch(errorHandler);
      }

      updateData() {
        if (!this.voyage) {
          return;
        }
        this.commodityIdList = this.voyage.few_commodity
          .map((quality) => quality.cargo)
          .filter((cargo): cargo is number => cargo != null)
          .map(String);
        return cropsCropQualitiesList({ query: { cargo_list: this.commodityIdList } })
          .then((data) => {
            this.$scope.$apply(() => {
              if (!this.voyage) {
                return;
              }
              this.cropQualities = data.data.results;
              const combinedQualities = [...this.voyage.qualities, ...this.cropQualities];
              combinedQualities.sort((a, b) => a.commodity_title.localeCompare(b.commodity_title));
              this.tableData = { rows: combinedQualities };
            });
          })
          .catch(errorHandler);
      }

      getTableOptions() {
        const options: any = {
          tableName: 'voyage-qualities-table',
          templateArgs: {
            findQuality: (title: string) => this.findQuality(title, null),
          },
          columnDefs: [] as any[],
        };

        options.columnDefs = [
          {
            columnName: 'commodity',
            title: this.gettext('Commodity'),
            cellTemplate: html`<span> {[{ item.commodity_title }]} </span>`,
          },
          {
            columnName: 'title',
            title: this.gettext('Quality'),
            cellTemplate: html`<span> <i class="fa fa-flask"></i> {[{ item.title }]} </span>`,
          },
          {
            columnName: 'commodity_default',
            title: this.gettext('Commodity default'),
            cellTemplate: html`
              <span>
                {[{ args.findQuality(item.title).value }]} - {[{
                args.findQuality(item.title).additional }]}, {[{
                args.findQuality(item.title).value_alt }]} - {[{
                args.findQuality(item.title).additional_alt }]}
              </span>
            `,
          },
          {
            columnName: 'value',
            title: this.gettext('Value'),
            cellTemplate: html`<span>{[{ item.value }]}</span>`,
          },
          {
            columnName: 'additional',
            title: this.gettext('Property'),
            cellTemplate: html`{[{ item.additional }]}`,
          },
          {
            columnName: 'value_alt',
            title: this.gettext('Value alternative'),
            cellTemplate: html`{[{ item.value_alt }]}`,
          },
          {
            columnName: 'property_alt',
            title: this.gettext('Property alternative'),
            cellTemplate: html`{[{ item.additional_alt }]}`,
          },
        ];

        return options;
      }

      openQualitiesModal() {
        if (!this.voyage) {
          return;
        }

        return this.CropsService.qualitiesModal(
          this.voyage.qualities,
          null,
          null,
          null,
          null,
          null,
          this.commodityIdList,
          null,
          this.voyage.id,
        ).then((data: any) => {
          if (!data || data === 'cancel') {
            return;
          }

          if (!this.voyage) {
            return;
          }

          this.fetchVoyage();
        });
      }

      findQuality(title: string, commodityId: number | null) {
        if (!title) {
          return { value: 0 };
        }

        return (
          this.cropQualities
            .filter((quality) => {
              return quality.title === title && quality.crop === commodityId;
            })
            .shift() ?? { value: '--' }
        );
      }
    },
  ],
};
