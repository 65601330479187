export type MessageError = {
  message: string;
};

export const isMessageError = (error: unknown): error is MessageError => {
  return (error as MessageError).message != undefined;
};

type Success<T> = {
  data: T;
  error: null;
};

type Failure<E> = {
  data: null;
  error: E;
};

type Result<T, E = Error> = Success<T> | Failure<E>;

export const tryCatch = async <E = Error, T = unknown>(
  promise: Promise<T>,
): Promise<Result<T, E>> => {
  try {
    const data = await promise;
    return { data, error: null };
  } catch (error) {
    return { data: null, error: error as E };
  }
};
