import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').directive('gtClearInput', directive);

  function directive() {
    const directive = {
      link: link,
      restrict: 'A',
      scope: {
        modelValue: '=ngModel',
      },
    };
    return directive;

    function link(scope: any, element: any) {
      const input = element[0];

      element.bind('focus', function () {
        input.select();
        if (input.type == 'number' && input.valueAsNumber === 0) {
          input.value = undefined;
        }
      });

      element.bind('blur', function () {
        if (input.type == 'number' && input.value === '') {
          input.value = 0;
          scope.modelValue = 0;
        }
      });
    }
  }
})();
