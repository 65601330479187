import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('tariffsPageView', {
    bindings: {
      queryParams: '<?',
    },
    template: require('./tariffs-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['gettext', 'PageService', 'LogisticsService'];

  function Controller(
    this: any,
    gettext: ng.gettext.gettextFunction,
    PageService: PageService,
    LogisticsService: LogisticsService,
  ) {
    const vm = this;
    vm.filterLevel = 'tariffs-auto-page-view';
    vm.queryParams = {
      transportation_type: 'auto',
      ...vm.queryParams,
    };

    vm.$onInit = function () {
      vm.transType = vm.queryParams.transportation_type;
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-logistics-tab',
        buttons: [
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.Import('logistics.logistictariff'),
          new PageService.buttons.Export('logistics.logistictariff', vm.filterLevel),
          new PageService.buttons.Filters(),
        ],
        pages: [],
      };
      config.pages = LogisticsService.getPagesConfig();

      config.filters = {
        filterLevel: vm.filterLevel,
        ordering: [
          {
            title: gettext('RECENTLY UPDATED'),
            predicate: 'update_time',
          },
        ],
        search: true,
        dates: true,
        selects: [],
      };
      config.filters.nestedSelects = [];
      config.filters.multiSelects = [];
      config.filters.nestedMultiSelects = [];

      if (
        vm.transType === 'auto' ||
        vm.transType === 'elevator' ||
        vm.transType == 'railway' ||
        vm.transType == 'broker' ||
        vm.transType == 'surveyor'
      ) {
        config.filters.selects.push({
          argument: 'crop',
          placeholder: gettext('Commodity'),
          resource: 'crops.Crop',
        });
      }

      if (vm.transType == 'broker' || vm.transType == 'surveyor' || vm.transType == 'elevator') {
        config.filters.selects.push({
          argument: vm.transType,
          placeholder: gettext('Select ' + vm.transType),
          resource: 'clients.' + vm.transType,
        });
      }

      if (vm.transType === 'auto') {
        config.filters.selects.push(
          {
            argument: 'deliverer',
            placeholder: gettext('forwarder'),
            resource: 'clients.deliverer',
          },
          {
            argument: 'farm',
            placeholder: gettext('farm'),
            resource: 'clients.farm',
          },
          {
            argument: 'port',
            placeholder: gettext('port'),
            resource: 'logistics.Port',
          },
          {
            argument: 'terminal',
            placeholder: gettext('terminal'),
            resource: 'logistics.terminal',
          },
        );
        config.filters.multiSelects.push({
          argument: 'city_list',
          placeholder: gettext('City'),
          resource: 'location.City',
        });
      }

      if (vm.transType == 'railway') {
        config.filters.selects.push(
          {
            argument: 'departure_station',
            placeholder: gettext('Depart station'),
            resource: 'logistics.Station',
          },
          {
            argument: 'destination_station',
            placeholder: gettext('Recieve station'),
            resource: 'logistics.Station',
          },
          {
            argument: 'elevator',
            placeholder: gettext('elevator'),
            resource: 'clients.Elevator',
          },
          {
            argument: 'port',
            placeholder: gettext('port'),
            resource: 'logistics.Port',
          },
          {
            argument: 'terminal',
            placeholder: gettext('terminal'),
            resource: 'logistics.terminal',
          },
        );
      }

      if (
        vm.transType === 'auto' ||
        vm.transType === 'elevator' ||
        vm.transType == 'railway' ||
        vm.transType == 'broker' ||
        vm.transType == 'surveyor'
      ) {
        config.filters.selects.push(
          {
            argument: 'charge',
            placeholder: gettext('charge'),
            resource: 'finances.charge',
          },
          {
            argument: 'business_unit',
            placeholder: gettext('business unit'),
            resource: 'core.BusinessUnit',
          },
          {
            argument: 'author',
            placeholder: gettext('author'),
            resource: 'auth.User',
          },
        );
      }

      return config;
    }
  }
})();
