import React from 'react';

import { t } from '~/shared/lib/i18n';
import { cn } from '~/shared/lib/utils';
import { Input } from '~/shared/ui/kit/input';
import { Tooltip } from '~/shared/ui/kit/tooltip';

import { messageIntent } from '../../lib/constants';
import { type DataTypeProps } from '../../lib/types';

export const NumberWrite: React.FC<DataTypeProps<'number'>> = ({
  value,
  onChange,
  ref,
  disabled,
  message,
  typeExtra,
}) => {
  const inputElement = (
    <Input
      intent={message ? 'danger' : 'transparent'}
      data-testid="number-field"
      type="number"
      ref={ref}
      onBlur={(e) => onChange?.(Number(e.target.value))}
      defaultValue={value}
      disabled={disabled}
      fullWidth
      {...typeExtra?.inputProps}
    />
  );

  return message ? (
    <Tooltip content={t(message.text ?? '')} side="bottom" sideOffset={4}>
      <div className={cn('relative w-full', messageIntent[message.type])}>{inputElement}</div>
    </Tooltip>
  ) : (
    inputElement
  );
};
