import template from './invoice-list-list-view.html?raw';

import type { GtRootScopeService } from '^/app/core/types';

export const InvoiceListListView = {
  bindings: {
    finances: '<?',
    count: '<?',
    filterLevel: '<?',
    onCreatePaymentPlan: '&?',
    createPlanButton: '<?',
    total: '<',
    applyFilters: '&?',
    openFinanceModal: '&?',
    openDocumentModal: '&?',
    openDocxModal: '&?',
    openRoleModal: '&?',
    clone: '&?',
    isGreen: '&?',
    getIcon: '&?',
    addInvoiceButton: '<?',
    addInvoiceButtonFunc: '&?',
    showPaymentsExportButton: '<?',
    paymentsExportConfig: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      count = 0;
      finances: object[] = [];
      total = {};
      gettext: ng.gettext.gettextFunction;
      invoiceExportButton = false;
      numberInput = false;
      partialTab = { tab: 'partial' };
      paymentsExportConfig = '';
      selection = false;
      showPaymentsExportButton = false;

      constructor($rootScope: GtRootScopeService, gettext: ng.gettext.gettextFunction) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;
      }
    },
  ],
};
