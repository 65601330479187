import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('voyagesPageView', {
    bindings: {
      filterLevel: '<?',
      queryParams: '<?',
    },
    template: require('./voyages-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['PageService', 'LogisticsService', 'gtFilterService', 'gettext', 'GtUtils'];

  function Controller(
    this: any,
    PageService: PageService,
    LogisticsService: LogisticsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
  ) {
    const vm = this;
    vm.filterLevel = 'voyages-page-view';

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-logistics-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.Import('logistics.voyage'),
          new PageService.buttons.Export('logistics.voyage', vm.filterLevel),
          new PageService.buttons.ScreenCaptureTask(),
        ],
        pages: [],
      };
      config.filters = {
        filterLevel: vm.filterLevel,
        clicked: false,
        search: true,
        dates: true,
        useFlexDateRange: true,
        invert_filters: true,
        dateSelects: [
          {
            argument: 'max_bl_date',
            placeholder: gettext('Max bl date'),
          },
        ],
        nestedSelects: [
          gtFilterService.getBoolFilter('shortage_rate', gettext('Shortage rate')),
          gtFilterService.getBoolFilter('demmurage_rate', gettext('Demmurage rate')),
          gtFilterService.getBoolFilter('dead_freight', gettext('Dead freight')),
          gtFilterService.getBoolFilter('with_passport', gettext('With Passport')),
          gtFilterService.getBoolFilter('has_docs', gettext('Has docs')),
          gtFilterService.getBoolFilter('is_charterer_owner', gettext('Charterer is owner')),
          gtFilterService.getBoolFilter('is_consecutive', gettext('Consecutive voyages')),
          gtFilterService.getBoolFilter(
            'hide_from_freight_position',
            gettext('Do not use in Freight position'),
          ),
          gtFilterService.getBoolFilter('has_broker', gettext('Has broker')),
          gtFilterService.getBoolFilter('has_port_loading', gettext('Has loading port')),
          gtFilterService.getBoolFilter(
            'has_port_loading_intermediate',
            gettext('Has loading port intermediate'),
          ),
          gtFilterService.getBoolFilter('has_port_destination', gettext('Has destination port')),
          gtFilterService.getBoolFilter(
            'has_port_destination_intermediate',
            gettext('Has destination port intermediate'),
          ),
          gtFilterService.getBoolFilter('has_agent_loading', gettext('Has loading agent')),
          gtFilterService.getBoolFilter(
            'has_agent_loading_intermediate',
            gettext('Has loading intermediate agent'),
          ),
          gtFilterService.getBoolFilter(
            'has_agent_destination',
            gettext('Has destination intermediate agent'),
          ),
          gtFilterService.getBoolFilter(
            'has_agent_destination_intermediate',
            gettext('Has destination agent'),
          ),
          gtFilterService.getBoolFilter(
            'owner_equals_disponent',
            gettext('Owner equals Disponent'),
          ),
        ],
      };
      config.filters.multiSelects = [
        {
          argument: 'vessel',
          placeholder: gettext('vessel'),
          resource: 'logistics.Vessel',
        },
        {
          argument: 'passport',
          placeholder: gettext('passports'),
          resource: 'passports.Passport',
        },
        {
          argument: 'vessel_owner_list',
          placeholder: gettext('vessel owner'),
          resource: 'clients.other',
          related_fk: 'vessel__owner_id',
          related_model: 'logistics.Voyage',
        },
        {
          argument: 'vessel_managing_company_list',
          placeholder: gettext('vessel Managing company'),
          resource: 'clients.other',
          related_fk: 'vessel__managing_company_id',
          related_model: 'logistics.Voyage',
        },
        {
          argument: 'disponent_owner_list',
          placeholder: gettext('disponent owner'),
          resource: 'clients.other',
        },
        {
          argument: 'port_loading_list',
          placeholder: gettext('port loading'),
          resource: 'logistics.port',
        },
        {
          argument: 'port_destination_list',
          placeholder: gettext('port destination'),
          resource: 'logistics.port',
        },
        {
          argument: 'port_loading_country_list',
          placeholder: gettext('port loading country'),
          resource: 'location.country',
        },
        {
          argument: 'port_destination_country_list',
          placeholder: gettext('port destination country'),
          resource: 'location.country',
        },
        {
          argument: 'business_unit_list',
          placeholder: gettext('business unit'),
          resource: 'core.BusinessUnit',
          getQueryParams: GtUtils.getBUQueryParams,
        },
        {
          argument: 'basis_docs_list',
          placeholder: gettext('Has basis doc'),
          resource: 'contracts.basisdoc',
        },
        {
          argument: 'without_basis_docs_list',
          placeholder: gettext('Has no basis doc'),
          resource: 'contracts.basisdoc',
        },
        {
          argument: 'broker_list',
          placeholder: gettext('Broker'),
          resource: 'clients.broker',
        },
        {
          argument: 'charterer_list',
          placeholder: gettext('Charterer'),
          resource: 'clients.ClientRole',
        },
        {
          argument: 'agent_list',
          placeholder: gettext('Agent'),
          resource: 'clients.other',
        },
        {
          argument: 'size_list',
          placeholder: gettext('Vessel size'),
          resource: 'logistics.vesselsize',
        },
        {
          argument: 'author_list',
          placeholder: gettext('author'),
          resource: 'auth.user',
          related_fk: 'author',
          related_model: 'logistics.Voyage',
        },
      ];
      config.filters.nestedMultiSelects = [
        gtFilterService.getPassportStatusFilter('passport_status_list', 'passport status'),
        {
          argument: 'status_list',
          placeholder: gettext('Voyage status'),
          items: LogisticsService.getVoyageStatuses(),
        },
      ];
      config.pages = LogisticsService.getPagesConfig();
      return config;
    }
  }
})();
