import type {
  FinancePosition,
  FinancePositionListParams,
  FinancePositionPatch,
  FinancePositionPost,
  FinancePositionRecord,
} from '~/features/finances/finance-position/lib';
import {
  financesInvoicePositionListList,
  financesInvoicePositionsCreate,
  financesInvoicePositionsDestroy,
  financesInvoicePositionsPartialUpdate,
  financesInvoicePositionsRetrieve,
  financesPaymentInfosPredictionsRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/lib/entities';

export class FinancePositionRepository extends EntityRepository<
  FinancePosition,
  FinancePositionListParams,
  FinancePositionRecord,
  FinancePositionPost,
  FinancePositionPatch
> {
  readonly entityName = 'finances.FinancePosition';

  getFn = async (id: number) => {
    const { data } = await financesInvoicePositionsRetrieve({ path: { id: id } });
    return data;
  };
  queryFn = async (query: FinancePositionListParams) => {
    const { data } = await financesInvoicePositionListList({ query });
    return { records: data.results, count: data.count };
  };
  searchFn = async (input: string) => {
    const { data } = await financesPaymentInfosPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  createFn = async (entity: FinancePositionPost) => {
    const { data } = await financesInvoicePositionsCreate({ body: entity });
    return data;
  };
  updateFn = async (entity: FinancePositionPatch): Promise<FinancePosition> => {
    const { data } = await financesInvoicePositionsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };
  deleteFn = async (id: number) => {
    await financesInvoicePositionsDestroy({ path: { id } });
  };
  buildNewRecord = (): FinancePositionRecord =>
    ({
      id: -Date.now(),
      use: 'cargo',
    }) as unknown as FinancePositionRecord;
}
