import template from './warehouse-rest-content-table-view.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { html } from '^/shared/utils';

export const WarehouseRestContentTableView = {
  bindings: {
    data: '<',
    totals: '<',
    applyFilters: '&?',
  },
  template,
  controller: [
    '$rootScope',
    'gtFilterService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      applyFilters: any;
      data: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      tableData: any;
      tableOptions: any;
      totals: any;
      constructor(
        $rootScope: GtRootScopeService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.tableOptions = {};
        this.totals = {};
        this.queryParams = { page_size: 1000000 };
        this.tableData = {};
        this.filterLevel = 'warehouse-rest-content-table-view';
      }

      $onInit() {
        this.data = this.data || [];
        this.totals = this.totals || {};
        this.tableOptions = this.getTableOptions();
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      $onChanges(changes: any) {
        if (changes.data || changes.totals) {
          this.tableData = { rows: this.data, total: this.totals };
        }
      }

      getTableOptions() {
        const options: any = {
          tableName: 'warehouse-rest-content-table-view',
          configurable: true,
          totalsRow: true,
          tableClass:
            'table main-table table-responsive table-condensed places-table offers-table clients-table warehouse-table table-hover',
          filterLevel: this.filterLevel,
          rowData: this.data,
          columnDefs: [] as any[],
          tabs: [],
          applyFilters: this.applyFilters,
          showMore: false,
        };

        options.columnDefs = [
          {
            columnName: 'Commodity',
            title: this.gettext('Commodity'),
            class: 'td-left-align',
            cellTemplate: html`<div>{[{item.commodity_title}]}</div>`,
            totalTemplate: html`<span>Total</span>`,
          },
          {
            columnName: 'Warehouse',
            title: this.gettext('Warehouse'),
            class: 'td-left-align',
            cellTemplate: html`<div>{[{item.warehouse_title}]}</div>`,
          },
          {
            columnName: 'stocks_mt',
            title: this.gettext('Stocks, MT'),
            class: 'td-center-align',
            cellTemplate: html`<div>{[{item.volume | gtDecimal:2}]}</div>`,
          },
          {
            columnName: 'mtm_per_1',
            title: this.gettext('MTM, 1 MT/$'),
            class: 'td-center-align',
            cellTemplate: html`<div>{[{ item.avg_price_mtm | gtDecimal:2 }]}</div>`,
          },
          {
            columnName: 'contract_price_avg_per_1',
            title: this.gettext('Contract price average, 1 MT/$'),
            class: 'td-center-align',
            cellTemplate: html`<div>{[{ item.avg_price | gtDecimal:2}]}</div>`,
          },
          {
            columnName: 'inventory_value_in_stock',
            title: this.gettext('Inventory Value in Stock, $'),
            class: 'td-center-align',
            cellTemplate: html`<div>{[{item.amount | gtDecimal:2 }]}</div>`,
            totalTemplate: html`<div>{[{item.amount | gtDecimal:2 }]}</div>`,
          },
          {
            columnName: 'mtm_inventory_valuation',
            title: this.gettext('MTM Inventory Valuation, $'),
            class: 'td-center-align',
            cellTemplate: html`<div>{[{item.amount_mtm | gtDecimal:2}]}</div>`,
            totalTemplate: html`<div>{[{item.amount_mtm }]}</div>`,
          },
          {
            columnName: 'diff_contract_price_mtm',
            title: this.gettext('Diff btwn contract price and MTM, $'),
            class: 'td-center-align',
            cellTemplate: html`<div>{[{(item.amount - item.amount_mtm) | gtDecimal:2 }]}</div>`,
            totalTemplate: html`<div>{[{(item.amount - item.amount_mtm) | gtDecimal:2}]}</div>`,
          },
        ];

        return options;
      }
    },
  ],
};
