import React from 'react';
import type { DayPickerProps } from 'react-day-picker';
import { DayPicker } from 'react-day-picker';

import { NextMonthButton, PreviousMonthButton } from './components';

const datePickerClasses = {
  selected:
    'text-text-main-primary [&>button]:text-text-main-on-primary-section bg-background-section-primary rounded-sm',
  today:
    'text-text-main-primary [&>button]:border [&>button]:stroke-section-strong [&>button]:rounded-sm [&>button]:w-full [&>button]:h-full h-5 w-5',
  months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
  caption: 'flex justify-center pt-1 relative items-center',
  caption_label: 'text-xs text-text-main-secondary font-medium',
  month_caption: 'py-2 ml-2',
  nav: 'absolute right-3 top-3 flex items-center space-x-1',
  nav_button: 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
  nav_button_previous: 'absolute left-1',
  nav_button_next: 'absolute right-1',
  table: 'w-full border-collapse space-y-1',
  month_grid: 'w-full h-full',
  head_row: 'flex',
  head_cell: 'text-muted-foreground rounded-md font-normal text-[0.8rem]',
  row: 'flex w-full mt-2',
  day_range_end: 'day-range-end',
  outside: 'text-text-main-extraLight',
  day_disabled: 'text-muted-foreground opacity-50',
  day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
  day_hidden: 'invisible',
  day: 'relative p-0 h-7 w-9 text-center text-xs font-medium rounded-sm [&>button]:w-full [&>button]:h-full [&>button]:rounded-md [&>button]:hover:bg-transparent-light [&>button:not(.text-text-main-on-primary-section)]:hover:bg-transparent-light',
  weeks: 'w-full text-text-main-primary',
  weekday: 'text-text-main-primary font-normal text-xs pl-3',
};

export const DatePicker: React.FC<DayPickerProps> = ({ classNames, ...props }) => {
  return (
    <DayPicker
      data-testid="date-picker"
      showOutsideDays
      className="border-stroke-main-medium bg-background-main-primary relative h-[280px] w-[290px] rounded-sm border px-4 py-2"
      classNames={{ ...datePickerClasses, ...classNames }}
      weekStartsOn={1}
      components={{
        PreviousMonthButton,
        NextMonthButton,
      }}
      {...props}
    />
  );
};
