import template from './cashflow-report-tree.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

export const CashflowReportTree = {
  bindings: {
    filterLevel: '<',
    data: '<',
    config: '<',
    indicatorView: '<',
    chartsConfigs: '<',
    levelsCount: '<',
    selectedExpenses: '<',
    showPaymentPlanCreation: '<',
    selectedExpensesOnChange: '&',
  },
  template,
  controller: [
    'GtUtils',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      GtUtils: GtUtilsService;
      config: any;
      currencyIcons: any;
      data: any;
      defaultCurrency: any;
      defaultCurrencyIcon: any;
      levelsCount = 0;
      selectedExpenses: any;
      selectedExpensesOnChange: any;
      showPaymentPlanCreation: any;
      verticalGroups: any;
      constructor(GtUtils: GtUtilsService, $rootScope: GtRootScopeService) {
        this.GtUtils = GtUtils;
        this.$rootScope = $rootScope;

        this.currencyIcons = {
          EUR: 'fa-euro-sign',
          USD: 'fa-dollar-sign',
          UAH: 'fa-hryvnia-sign',
          RUB: 'fa-ruble-sign',
          GBP: 'fa-sterling-sign',
          KZT: 'fa-tenge-sign',
        };

        this.defaultCurrency = this.$rootScope.user.settings.DEFAULT_CURRENCY;
        this.defaultCurrencyIcon = this.currencyIcons[this.defaultCurrency];
        this.verticalGroups = [];
        this.selectedExpenses = [];
        this.showPaymentPlanCreation = false;
      }

      $onInit() {
        this.updateVerticalGroups();
      }

      updateVerticalGroups() {
        this.verticalGroups = [
          {
            name: 'incoming',
            title: 'Incoming',
            indicator: 'incoming',
            indicatorLocal: 'incoming_local',
            icon: 'fa-plus',
            class: 'positive-number',
            showDetails: true,
            expand: false,
            ngClass: {
              'alert-success': true,
              'positive-number': true,
            },
          },
          {
            name: 'outgoing',
            title: 'Outgoing',
            indicator: 'outgoing',
            indicatorLocal: 'outgoing_local',
            icon: 'fa-minus',
            class: 'negative-number',
            showDetails: true,
            expand: false,
            ngClass: {
              'alert-danger': true,
              'negative-number': true,
            },
          },
          {
            name: 'total',
            title: 'Total',
            indicator: 'total',
            indicatorLocal: 'total_local',
            expand: false,
            showDetails: false,
            ngClass: {},
          },
        ];
      }

      getIcon(modelName: string) {
        return this.GtUtils.getIcon('clients.' + modelName);
      }

      updateVisibility(row: any, expand: any) {
        row.expand = expand;
        if (!expand) {
          this.data
            .filter((v: any) => v.parentIndex === row.index)
            .forEach((x: any) => {
              this.updateVisibility(x, expand);
            });
        }
      }

      rowHasData(row: any, group: any): any {
        if (this.levelsCount !== row.level) {
          return true;
        }
        return (
          row &&
          ((row.level === 'detailsGrouped' && this.rowHasData(row.parent, group)) ||
            this.config.columns
              .filter((v: any) => v.group === 'months')
              .some(
                (v: any) =>
                  Boolean(row.indicators[v.predicate]) &&
                  (Boolean(row.indicators[v.predicate][group.indicator]) ||
                    Boolean(row.indicators[v.predicate][group.indicatorLocal])),
              ))
        );
      }

      showArrows() {
        return true;
      }

      selectAllGroupExpenses(row: any, column: any) {
        const { group } = column;
        const allFinances = this.data.find((item: any) => item.parentID === row.objectID);
        const allExpenses = allFinances[group].filter(
          (finance: any) => finance.direction === column.predicate,
        );
        const allExpensesIDs = allExpenses.map((expense: any) => expense.objectID);

        const selectedExpensesFromGroup = this.selectedExpenses
          .map((expense: any) => expense.objectID)
          .filter((expenseID: any) => allExpensesIDs.includes(expenseID));

        if (selectedExpensesFromGroup.length === 0) {
          this.selectedExpenses = Object.assign(this.selectedExpenses, [
            ...this.selectedExpenses,
            ...allExpenses,
          ]);
        } else {
          this.selectedExpenses = Object.assign(
            this.selectedExpenses,
            this.selectedExpenses.filter(
              (expense: any) => !selectedExpensesFromGroup.includes(expense.objectID),
            ),
          );
        }
        this.selectedExpensesOnChange();
      }

      selectAllExpenses(row: any, column: any) {
        const allFinances = this.data.find((item: any) => item.parentID === row.objectID);
        const allExpenses = this.config.columns
          .filter(
            (col: any) =>
              col.showCheckbox && col.group !== column.group && col.predicate === column.predicate,
          )
          .map((col: any) => col.group)
          .reduce(
            (res: any, cur: any) => [
              ...res,
              ...allFinances[cur].filter((f: any) => f.direction === column.predicate),
            ],
            [],
          );
        const allExpensesIDs = allExpenses.map((expense: any) => expense.objectID);

        const selectedExpensesFromGroup = this.selectedExpenses
          .map((expense: any) => expense.objectID)
          .filter((expenseID: any) => allExpensesIDs.includes(expenseID));

        if (selectedExpensesFromGroup.length === 0) {
          this.selectedExpenses = Object.assign(this.selectedExpenses, [
            ...this.selectedExpenses,
            ...allExpenses,
          ]);
        } else {
          this.selectedExpenses = Object.assign(
            this.selectedExpenses,
            this.selectedExpenses.filter(
              (expense: any) => !selectedExpensesFromGroup.includes(expense.objectID),
            ),
          );
        }
        this.selectedExpensesOnChange();
      }

      selectExpense(expense: any) {
        if (this.selectedExpenses.find((item: any) => item.objectID === expense.objectID)) {
          this.selectedExpenses.splice(
            this.selectedExpenses.findIndex((item: any) => item.objectID === expense.objectID),
            1,
          );
        } else {
          this.selectedExpenses.push(expense);
        }
        this.selectedExpensesOnChange();
      }

      isExpenseChecked(expense: any) {
        return Boolean(
          this.selectedExpenses.find((item: any) => item.objectID === expense.objectID),
        );
      }

      isAllGroupChecked(row: any, column: any) {
        if (!row.children?.[column.group]) {
          return false;
        }
        const { group } = column;

        const allExpenses = row.children[group]
          .filter(
            (expense: any) =>
              expense.direction === column.columnName || column.columnName === 'total',
          )
          .map((expense: any) => expense.objectID);

        const selectedExpensesFromGroup = this.selectedExpenses.filter((expense: any) =>
          allExpenses.includes(expense.objectID),
        );

        return (
          allExpenses.length === selectedExpensesFromGroup.length &&
          selectedExpensesFromGroup.length !== 0
        );
      }

      isAllChecked(row: any, column: any) {
        if (!row.children) {
          return false;
        }
        return this.config.columns
          .filter(
            (col: any) =>
              col.showCheckbox && col.group !== column.group && col.predicate === column.predicate,
          )
          .reduce((res: any, col: any) => res && this.isAllGroupChecked(row, col), true);
      }

      isCheckAllCheckboxShown(row: any, column: any) {
        return (
          this.showPaymentPlanCreation &&
          column.showCheckbox &&
          row.level === this.levelsCount &&
          (row[column.predicate] || row[column.predicateLocal])
        );
      }

      isCheckAllGroupCheckboxShown(row: any, column: any) {
        return (
          this.showPaymentPlanCreation &&
          column.showCheckbox &&
          row.level === this.levelsCount &&
          (row.indicators[column.group][column.predicate] ||
            row.indicators[column.group][column.predicateLocal])
        );
      }

      isDetailsCheckboxShown(row: any, column: any) {
        return (
          this.showPaymentPlanCreation &&
          column.showCheckbox &&
          !row.finance_type.includes('bank_transaction') &&
          (row.total || row.total_local)
        );
      }
    },
  ],
};
