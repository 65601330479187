import template from './customs-cargo-declaration-invoice-position-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const CustomsCargoDeclarationInvoicePositionListContainer = {
  bindings: {
    ccdInvoicePositionList: '=?',
    initQueryParams: '<?',
    filterLevel: '<',
    onUpdate: '&?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'gettext',
    class CustomsCargoDeclarationInvoicePositionListContainer {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      batchSaving: any;
      ccdInvoicePositionList: any;
      dataCount: number;
      extra: any;
      filterLevel = 'customs-cargo-declaration-invoice-position-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      newItem: any;
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;

        this.queryParams = {};
        this.newItem = {};
        this.extra = {};
        this.dataCount = 0;
      }

      $onInit() {
        this.ccdInvoicePositionList = this.ccdInvoicePositionList || [];

        this.extra = this.extra || {};
        this.queryParams = { page_size: 25, ...this.initQueryParams };
        this.newItem = {
          ...this.initQueryParams,
        };
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      $onChanges(changes: any) {
        if (changes.ccdInvoicePositionList) {
          this.updateData();
        }
      }

      updateData() {
        this.dataCount = this.ccdInvoicePositionList.length;
      }

      _error(data: any) {
        this.GtUtils.errorClb(data);
        this.GtUtils.overlay('hide');
        this.batchSaving = false;
      }
    },
  ],
};
