import type { FilterOption, Preset, SortingOption } from '~/core/page-filters';
import type { FilterValue, SortingValue } from '~/core/page-filters/types';
import { dateApiString, parseDate } from '~/shared/lib/date';
import type { EntityQueryParams } from '~/shared/lib/entities';
import type { ListOption } from '~/shared/lib/types';
import {
  formatCamelCaseToTitle,
  genUuid,
  replaceUnderscoresWithSpaces,
  rmUndefined,
  toTitle,
} from '~/shared/lib/utils';
import type {
  DataType,
  DataTypeExtra,
  DataTypeValue,
  DateRange,
  EntityOption,
} from '~/shared/ui/data-types';
import { type IconName } from '~/shared/ui/icons';

import type {
  DataParams,
  DataRecord,
  FieldDef,
  FilterDef,
  QueryParamsSet,
  QueryParamsSetPost,
} from '../lib/types';

const dataTypeToIcon: Record<DataType, IconName> = {
  string: 'PencilIcon',
  number: 'PlusIcon',
  date: 'GridIcon',
  daterange: 'GridIcon',
  entity: 'CubeIcon',
  select: 'ListIcon',
  multiselect: 'ListIcon',
  checkbox: 'CheckIcon',

  template: 'PencilIcon',
  person: 'PencilIcon',
  label: 'PencilIcon',
  phone: 'PencilIcon',
  text: 'PencilIcon',
  url: 'PencilIcon',
  email: 'PencilIcon',
};

const parseParamsValue = <P extends EntityQueryParams, T extends DataType>(
  value: unknown,
  filterDef: FilterDef<P> & { dataType: T },
  params: Record<string, unknown>,
): DataTypeValue<T> => {
  if (filterDef.dataType === 'string') {
    return value as DataTypeValue<T>;
  } else if (filterDef.dataType === 'number') {
    return parseFloat(String(value));
  } else if (filterDef.dataType === 'date') {
    return new Date(String(value));
  } else if (filterDef.dataType === 'daterange') {
    return {
      from: parseDate(String(params.start_date)),
      to: parseDate(String(params.end_date)),
    };
  } else if (filterDef.dataType === 'entity') {
    return [
      {
        id: parseInt(String(value), 10),
        title: '',
      },
    ];
  } else if (filterDef.dataType === 'select') {
    return (filterDef.typeExtra as DataTypeExtra<'select'>)?.options.find((o) => o.value === value);
  } else if (filterDef.dataType === 'multiselect') {
    return (filterDef.typeExtra as DataTypeExtra<'multiselect'>)?.options.filter((o) =>
      (value as string[]).includes(o.value),
    );
  } else if (filterDef.dataType === 'checkbox') {
    return Boolean(value);
  }
  throw new Error('Unknown dataType');
};

const serializeValue = <P extends EntityQueryParams, T extends DataType>(
  filerValue: FilterValue<T>,
  filterDef: FilterDef<P>,
  params: P,
): P => {
  if (filerValue.value == undefined) {
    return params;
  }
  const currentValue = (params[filerValue.argument as keyof P] as unknown[]) ?? [];
  if (filterDef.dataType === 'string') {
    return {
      ...params,
      [filerValue.argument]: [...currentValue, filerValue.value],
    };
  } else if (filterDef.dataType === 'number') {
    return {
      ...params,
      [filerValue.argument]: [...(currentValue as number[]), filerValue.value],
    };
  } else if (filterDef.dataType === 'entity') {
    const values = filerValue.value as EntityOption[];
    return {
      ...params,
      [filerValue.argument]: [...currentValue, ...values.map((v) => v.id)],
    };
  } else if (filterDef.dataType === 'daterange') {
    const dataRange = filerValue.value as DateRange;
    const currentDateArg = (params['date_predicate' as keyof P] as string[]) ?? [];
    return {
      ...params,
      date_predicate: [...currentDateArg, filerValue.argument],
      start_date: dataRange.from ? dateApiString(dataRange.from) : undefined,
      end_date: dataRange.to ? dateApiString(dataRange.to) : undefined,
    };
  } else if (filterDef.dataType === 'select') {
    const value = filerValue.value as ListOption;
    return value ? { ...params, [filerValue.argument]: [...currentValue, value.value] } : params;
  } else if (filterDef.dataType === 'multiselect') {
    const values = filerValue.value as ListOption[];
    return {
      ...params,
      [filerValue.argument]: [...currentValue, ...values.map((v) => v.value)],
    };
  } else if (filterDef.dataType === 'checkbox') {
    return { ...params, [filerValue.argument]: { true: 1, false: 0 }[filerValue.value.toString()] };
  }
  throw new Error('Unknown dataType');
};

export const filterDefToFilterOption = <P extends EntityQueryParams>(
  filterDef: FilterDef<P>,
): FilterOption<DataType> => {
  return {
    argument: filterDef.argument,
    title: filterDef.title ?? replaceUnderscoresWithSpaces(filterDef.argument, true),
    dataType: filterDef.dataType,
    typeExtra: filterDef.typeExtra,
    icon: filterDef.icon ?? 'FilterIcon',
    groupName: filterDef.groupName ?? formatCamelCaseToTitle(filterDef.dataType),
    groupIcon: filterDef.groupIcon ?? dataTypeToIcon[filterDef.dataType],
    operatorOptions: filterDef.operatorOptions ?? ['is'],
  };
};

export const fieldDefToSortingOption = <R extends DataRecord, P extends DataParams>(
  fieldDef: FieldDef<R, P>,
): SortingOption => {
  return rmUndefined({
    argument: String(fieldDef.sorting?.argument ?? fieldDef.key).replace(/^_/, ''),
    title: fieldDef.sorting?.title ?? fieldDef.title ?? toTitle(fieldDef.key),
    icon: fieldDef.sorting?.icon ?? 'SortArrowsIcon',
  });
};

const parseQueryParams = <P extends EntityQueryParams>(
  queryParams: string,
  filterDefMap: Record<string, FilterDef<P>>,
): { filters: FilterValue<DataType>[]; sorting?: SortingValue } => {
  const rawParams: Record<string, unknown> = {};

  try {
    Object.entries(JSON.parse(queryParams) as Record<string, unknown>).forEach(([key, value]) => {
      if (key == 'date_predicate') {
        (value as string[]).forEach((pred) => (rawParams[pred] = 'date_predicate'));
      } else {
        rawParams[key] = value;
      }
    });
  } catch (e) {
    console.warn('Failed to parse QueryParamsSet filter values', e);
  }

  return {
    filters: Object.entries(rawParams)
      .filter(([arg]) => filterDefMap[arg])
      .flatMap(([argument, values]) => {
        const filterDef = filterDefMap[argument];
        return (Array.isArray(values) ? values : [values]).map((value) => {
          return {
            id: genUuid(),
            argument,
            operator: 'is',
            value:
              filterDef.fromParams?.(value, rawParams as P) ??
              parseParamsValue(value, filterDef, rawParams as P),
          };
        });
      }),
    sorting: rawParams.ordering
      ? {
          argument: String(rawParams.ordering as string).replace(/^-/, ''),
          direction: String(rawParams.ordering as string).startsWith('-') ? 'desc' : 'asc',
        }
      : undefined,
  };
};

export const queryParamsSetToPreset = <P extends EntityQueryParams>(
  params: QueryParamsSet,
  filterDefMap: Record<string, FilterDef<P>>,
): Preset => {
  const { filters, sorting } = parseQueryParams(params.query_params, filterDefMap);
  return {
    id: params.id,
    title: params.title,
    icon: params.icon as IconName,
    datasetId: params.filter_level,
    filters,
    sorting,
  };
};

export const presetToQueryParams = <P extends EntityQueryParams>(
  preset: Preset,
  filterDefMap: Record<string, FilterDef<P>>,
): P => {
  const params = preset.filters.reduce(
    (acc, filterValue) => serializeValue(filterValue, filterDefMap[filterValue.argument], acc),
    {} as P,
  );
  if (preset.sorting?.argument) {
    params.ordering = `${preset.sorting.direction === 'desc' ? '-' : ''}${preset.sorting.argument}`;
  }
  return rmUndefined(params);
};

export const presetToQueryParamsSet = <P extends EntityQueryParams>(
  preset: Preset,
  filterDefMap: Record<string, FilterDef<P>>,
): QueryParamsSetPost => {
  return {
    id: preset.id,
    title: preset.title,
    icon: preset.icon,
    filter_level: preset.datasetId,
    query_params: JSON.stringify(presetToQueryParams(preset, filterDefMap)),
  };
};

export const fieldDefToFilterDef = <
  R extends DataRecord,
  P extends EntityQueryParams,
  T extends DataType,
>(
  field: FieldDef<R, P>,
): FilterDef<P, T>[] =>
  field.filters?.map((filter) => {
    if (!filter.dataType && field.dataType === 'date') {
      filter.dataType = 'daterange';
    }

    return {
      argument: String(filter.argument ?? field.key).replace(/^_/, '') as unknown as string,
      title: filter.title ?? field.title,
      dataType: filter.dataType ?? field.dataType,
      typeExtra: filter.typeExtra ?? field.typeExtra,
      icon: filter.icon,
      groupName: filter.groupName,
      groupIcon: filter.groupIcon,
      operatorOptions: filter.operatorOptions,
    } as FilterDef<P, T>;
  }) ?? [];
