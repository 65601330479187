import type ng from 'angular';

import template from './person-list-table-view.html?raw';
import nameTpl from './templates/name.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import { html } from '^/shared/utils';

export const PersonListTableView = {
  bindings: {
    newPerson: '<?',
    quickAdd: '<?',
    persons: '<',
    count: '<',
    applyFilters: '&',
    openPersonModal: '&',
    clonePerson: '&',
    createUser: '&',
    openEmailModal: '&',
  },
  template,
  controller: [
    'gettext',
    'ClientsService',
    'AccountsService',
    class {
      AccountsService: AccountsService;
      ClientsService: any;
      applyFilters: any;
      clonePerson: any;
      count = 0;
      createUser: any;
      gettext: ng.gettext.gettextFunction;
      newPerson: any;
      openEmailModal: any;
      openPersonModal: any;
      persons: any;
      quickAdd: any;
      tableData: any;
      tableOptions: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        ClientsService: any,
        AccountsService: AccountsService,
      ) {
        this.gettext = gettext;

        this.newPerson = {};
        this.quickAdd = false;

        this.tableOptions = {};
        this.tableData = {};
        this.ClientsService = ClientsService;
        this.AccountsService = AccountsService;
      }

      $onInit() {
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.persons || changes.count) {
          this.tableData = { rows: this.persons, count: this.count };
        }
      }

      getTableOptions() {
        return {
          tableName: 'persons',
          tableClass: 'persons-table default-overflow',
          configurable: false,
          applyFilters: this.applyFilters,
          templateArgs: {
            openPersonModal: (person: any) => this.openPersonModal({ person: person }),
            openEmailModal: (person: any) => this.openEmailModal({ person: person }),
            clonePerson: (person: any) => this.clonePerson({ person: person }),
            createUser: (person: any) => this.createUser({ person: person }),
            updatePerson: (person: any) => {
              person.verified = !person.verified;
              return this.ClientsService.Person.update({ partial: true }, person).$promise;
            },
            checkPerms: () => !this.AccountsService.hasPerm('can_verify_person'),
          },
          columnDefs: [
            {
              columnName: 'name',
              title: this.gettext('name'),
              cellTemplate: nameTpl,
              showAlways: true,
              class: 'company',
            },
            {
              columnName: 'position',
              title: this.gettext('position'),
              cellTemplate: html`
                <div>
                  <span
                    ng-if="!item.$_edit"
                    ng-repeat="company in item.companies_set"
                    class="companies_list"
                    ui-sref="gt.page.client({ id: company.id })"
                  >
                    <i class="fa fa-building"></i> {[{ company.name | cut:true:25:'...'}]}
                    <span ng-if="item.position">({[{ item.position }]})</span>
                  </span>
                  <div class="clearfix"></div>
                  <span ng-if="item.responsibility_set && !item.$_edit">
                    <span
                      class="smaller-label"
                      ng-repeat="responsibility in item.responsibility_set"
                      ><i class="fa fa-suitcase"></i> {[{ responsibility.title }]}
                      <div class="clearfix"></div>
                    </span>
                  </span>
                  <div class="clearfix"></div>
                  <span ng-if="item.birthday && !item.$_edit">
                    <i class="fa fa-birthday-cake"></i> {[{ item.birthday | date:'dd.MM.yy' }]}
                    <span clas="smaller-label label-opacity">
                      ({[{($root.serverTime | date:'yyyy') - (item.birthday | date:'yyyy') }]}
                      <translate> years</translate>)
                    </span>
                  </span>
                </div>
              `,
            },
            {
              columnName: 'phone',
              title: this.gettext('phone'),
              cellTemplate: html`
                <div ng-if="item.$_edit">
                  <input
                    class="form-control"
                    type="tel"
                    ng-model="item.phone"
                    placeholder="{[{ 'Phone' | translate }]}"
                  />
                </div>
                  <a class="btn-link label-strong" href="tel:{[{ item.phone }]}" ng-if="item.phone && !item.$_edit">
                    <i class="fa fa-phone-square"></i> {[{ item.phone }]}
                  </a>
                  <span class="label-opacity" ng-if="!item.phone && !item.$_edit">
                    <i class="fa fa-phone-square"></i> ---
                  </span>
                </a>
              `,
            },
            {
              columnName: 'email',
              title: this.gettext('email'),
              cellTemplate: html`
                <div ng-if="item.$_edit">
                  <input
                    class="form-control"
                    type="email"
                    ng-model="item.email"
                    placeholder="{[{ 'Email' | translate }]}"
                  />
                </div>
                <a
                  class="btn-link label-strong"
                  ng-if="item.email && !item.$_edit"
                  ng-click="args.openEmailModal(item);"
                >
                  <i class="fa fa-envelope"></i> {[{ item.email }]}
                </a>
              `,
            },
            {
              columnName: 'telegram_username',
              title: this.gettext('Telegram'),
              cellTemplate: html`
                <div ng-if="item.$_edit">
                  <input
                    class="form-control"
                    type="text"
                    ng-model="item.telegram_username"
                    placeholder="{[{ 'Telegram' | translate }]}"
                  />
                </div>
                <a class="btn-link" ng-if="item.telegram_username && !item.$_edit"">
                  <i class="fa fa-telegram"></i> {[{ item.telegram_username }]}
                </a>
                <span class="label-opacity" ng-if="!item.telegram_username && !item.$_edit">
                  <i class="fa fa-telegram"></i> ---
                </span>
              `,
            },
            {
              columnName: 'verified',
              title: this.gettext('Verified'),
              type: 'gt-checkbox',
              cellTemplate: html`
                <input
                  type="checkbox"
                  ng-model="item.verified"
                  ng-click="args.updatePerson(item)"
                  ng-disabled="args.checkPerms()"
                />
              `,
            },
          ],
          rowData: [],
        };
      }
    },
  ],
};
