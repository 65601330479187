import type ng from 'angular';

import type { FinancesService } from '../../legacy/finances.srv';

import type { QueryParams } from '^/app/core/types';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class ReassignmentService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  FinancesService: FinancesService;
  ReassignmentPositionResource: any;
  ReassignmentResource: any;
  constructor(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    FinancesService: FinancesService,
  ) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.FinancesService = FinancesService;
    this.ReassignmentResource = $resource(
      '/api/finances/reassignment/:id/',
      {
        id: '@id',
      },
      {
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/reassignments/list/',
        },
        update: { method: 'PATCH' },
        save: { method: 'POST' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/reassignment/predictions/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/reassignment/bulk_create_or_update/',
        },
        details: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/reassignment-details/:id/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/reassignment/export_column_names/',
        },
        totals: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/reassignment-totals/',
        },
        getPositionFromReassignment: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/position-from-reassignment/:id/',
        },
        getAverageRate: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/reassignment/get_average_rate/',
        },
      },
    );
    this.ReassignmentPositionResource = $resource(
      '/api/finances/reassignment-positions/:id/',
      {
        id: '@id',
      },
      {
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/reassignments-positions/list/',
        },
      },
    );
  }

  updateReassignment(reassignment: any) {
    return this.ReassignmentResource.update(reassignment).$promise;
  }

  getPositionFromReassignment(params: QueryParams) {
    return this.ReassignmentResource.getPositionFromReassignment(params).$promise;
  }

  reassignmentModal(reassignment: any, extraData?: Record<string, unknown>) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<reassignment-modal
        reassignment="reassignment"
        extra-data="extraData"
        modal-instance="$uibModalInstance"
      >
      </reassignment-modal>`,
      controller: [
        '$scope',
        'reassignment',
        'extraData',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          reassignment: any,
          extraData: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).reassignment = reassignment;
          ($scope as any).extraData = extraData;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        reassignment: () => reassignment,
        extraData: () => extraData,
      },
    }).result;
  }

  openNewParentReassignmentModal(reassignment: any, callback: any) {
    return this.getReassignmentModalData({
      id: reassignment.id,
    }).then((data: any) => {
      const parentReassignment = { ...data };

      delete parentReassignment.id;
      delete parentReassignment.distributor;
      parentReassignment.child_reassignment = data.id;
      parentReassignment.sub_distributor = data.distributor;

      return this.reassignmentModal(parentReassignment).then(() => callback());
    });
  }

  getReassignmentModalData(params: object) {
    return this.ReassignmentResource.get(params).$promise;
  }

  getAverageRate(params: QueryParams) {
    return this.ReassignmentResource.getAverageRate(params).$promise;
  }

  getReassignmentsTableData(params: QueryParams) {
    return this.ReassignmentResource.query(params).$promise;
  }

  getReassignmentsTotals(params: QueryParams) {
    return this.ReassignmentResource.totals(params).$promise;
  }

  getReassignmentPositions(params: QueryParams) {
    return this.ReassignmentPositionResource.query(params).$promise;
  }

  saveReassignment(reassignment: any, reassignmentPositions: any) {
    return this.ReassignmentResource.bulkCreateOrUpdate({
      reassignment: reassignment,
      reassignment_positions: reassignmentPositions,
    }).$promise;
  }

  getReassignmentDetails(params: QueryParams) {
    return this.ReassignmentResource.details(params).$promise;
  }

  reassignmentPositionIsValid(reassignmentPositions: any) {
    if (!reassignmentPositions) {
      return false;
    }
    let isValid = true;
    reassignmentPositions.forEach((position: any) => {
      if (!position.reassignment_amount || position.reassignment_amount < 0) {
        isValid = false;
      }
      if (position.reassignment_amount > position.disbursement_bl_amount) {
        isValid = false;
      }
    });

    return isValid;
  }

  destroy(params: object) {
    return this.ReassignmentResource.delete(params).$promise;
  }
}
ReassignmentService.$inject = ['$resource', '$uibModal', 'FinancesService'];
