import ng from 'angular';

import { OffsetContainer } from './offset-container/offset-container.component';
import { OffsetItem } from './offset-item/offset-item.component';
import { OffsetListTableView } from './offset-list-table-view/offset-list-table-view.component';
import { OffsetModal } from './offset-modal/offset-modal.component';
import { OffsetPageContainer } from './offset-page/offset-page.component';
import { OffsetPageView } from './offset-page-view/offset-page-view.component';
import { OffsetPositionsTableView } from './offset-positions-table-view/offset-positions-table-view.component';
import { OffsetService } from './offset.service';

const offsetConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('finances.offsetsPage', {
        url: '/offset',
        component: 'offsetPageView',
        resolve: {
          filterLevel: () => {
            return 'finances-offset-page-view';
          },
        },
        data: {
          pageTitle: gettext('Offset'),
        },
      })
      .state('gt.offset', {
        url: '/offset/:id',
        component: 'offsetPageContainer',
        data: {
          pageTitle: gettext('Offset'),
        },
      });
  },
];

export const offset = ng
  .module('finances.offsets', [])
  .component('offsetContainer', OffsetContainer)
  .component('offsetPageContainer', OffsetPageContainer)
  .component('offsetPageView', OffsetPageView)
  .component('offsetModal', OffsetModal)
  .component('offsetItem', OffsetItem)
  .component('offsetListTableView', OffsetListTableView)
  .component('offsetPositionsTableView', OffsetPositionsTableView)
  .service('OffsetService', OffsetService)
  .config(offsetConfig).name;
