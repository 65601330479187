import template from './cashflow-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const CashflowContainer = {
  bindings: {
    filterLevel: '<',
    queryParams: '<',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'CashflowService',
    'GtUtils',
    class {
      $scope: ng.IScope;
      CashflowService: any;
      GtUtils: GtUtilsService;
      filterLevel = 'cashflow-container';
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      reports: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        CashflowService: any,
        GtUtils: GtUtilsService,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.CashflowService = CashflowService;
        this.GtUtils = GtUtils;

        this.reports = [];
      }

      $onInit() {
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateCashFlow();
        });
        this.updateCashFlow();
      }

      updateCashFlow() {
        this.GtUtils.overlay('show');
        this.reports = [];
        return this.CashflowService.getCashflowData(this.queryParams).then((data: any) => {
          this.GtUtils.overlay('hide');
          this.reports = data.reports;
        });
      }
    },
  ],
};
