import ng from 'angular';

import { AdditionalAgreementsService } from './additional-agreements.service';
import { analytics } from './analytics/analytics.module';
import { BasisDocsTable } from './basis-docs/basis-docs-table/basis-docs-table.component';
import { AdditionalAgreementModal } from './components/additional-agreements-modal/additional-agreement-modal.component';
import { ContractChargeModal } from './components/contract-charge-modal/contract-charge-modal.component';
import { ContractChargesListContainer } from './components/contract-charges-list-container/contract-charges-list-container.component';
import { ContractChargesListTable } from './components/contract-charges-list-table/contract-charges-list-table.component';
import { ContractListBlockView } from './components/contract-list-block-view/contract-list-block-view.component';
import { contractListContainer } from './components/contract-list-container/contract-list-container.component';
import { ContractListListView } from './components/contract-list-list-view/contract-list-list-view.component';
import { ContractListModal } from './components/contract-list-modal/contract-list-modal.component';
import { ContractListTableView } from './components/contract-list-table-view/contract-list-table-view.component';
import { ContractPlControlContainer } from './components/contract-pl-control-container/contract-pl-control-container.component';
import { ContractsConsigneeWidget } from './components/contracts-consignee-widget/contracts-consignee-widget.component';
import { ContractsDerivativesWidget } from './components/contracts-derivatives-widget/contracts-derivatives-widget.component';
import { ContractChargeFormFieldsService } from './contract-charge-form-fields.service';
import { ContractFilterConfigService } from './contract-filter-config.service';
import { ContractFormFieldsService } from './contract-form-fields.service';
import { ContractPlContainer } from './contract-pl-container/contract-pl-container.component';
import { ContractPnlMtmListContainer } from './contract-pnl-mtm/contract-pnl-mtm-list-container/contract-pnl-mtm-list-container.component';
import { ContractPnlMtmListTableView } from './contract-pnl-mtm/contract-pnl-mtm-list-table-view/contract-pnl-mtm-list-table-view.component';
import { ContractPnlMtmService } from './contract-pnl-mtm/contract-pnl-mtm.service';
import { contractQualityRules } from './contract-quality-rules/contract-quality-rules.module';
import { contractDetails } from './details/contract-details.module';
import { GeneralAgreementFormFieldsService } from './general-agreement-form-fields.service';
import { generalAgreements } from './general-agreements/general-agreements.module';
import { importExport } from './import-export/import-export.module';
import { contractsLegacyModule } from './legacy';
import { oilExtension } from './oil-extension/oil-extension.module';
import { RequestFilterConfigService } from './request-filter-config.service';
import { saleExportContractAllocations } from './sale-export-contract-allocations/sale-export-contract-allocations.module';
import { TerminalCertsLogistic } from './terminal-certs-logistic/terminal-certs-logistic.component';
import { TerminalCertsLogisticService } from './terminal-certs-logistic/terminal-certs-logistic.service';

import { consignmentsContainer } from '^/app/deals/contracts/legacy/containers/consignments-container/consignments-container.cmp';

export const contracts = ng
  .module('deals.contracts', [
    contractsLegacyModule,
    analytics,
    importExport,
    contractDetails,
    oilExtension,
    contractQualityRules,
    saleExportContractAllocations,
    generalAgreements,
  ])
  .service('TerminalCertsLogisticService', TerminalCertsLogisticService)
  .service('ContractPnlMtmService', ContractPnlMtmService)
  .service('ContractFormFieldsService', ContractFormFieldsService)
  .service('GeneralAgreementFormFieldsService', GeneralAgreementFormFieldsService)
  .service('AdditionalAgreementsService', AdditionalAgreementsService)
  .service('ContractFilterConfigService', ContractFilterConfigService)
  .service('RequestFilterConfigService', RequestFilterConfigService)
  .service('ContractChargeFormFieldsService', ContractChargeFormFieldsService)
  .component('terminalCertsLogistic', TerminalCertsLogistic)
  .component('terminalCertsLogistic', TerminalCertsLogistic)
  .component('contractListContainer', contractListContainer)
  .component('contractListTableView', ContractListTableView)
  .component('contractListBlockView', ContractListBlockView)
  .component('contractListListView', ContractListListView)
  .component('contractListModal', ContractListModal)
  .component('contractPnlMtmListContainer', ContractPnlMtmListContainer)
  .component('contractPnlMtmListTableView', ContractPnlMtmListTableView)
  .component('contractsDerivativesWidget', ContractsDerivativesWidget)
  .component('contractChargeModal', ContractChargeModal)
  .component('contractPlContainer', ContractPlContainer)
  .component('contractPlControlContainer', ContractPlControlContainer)
  .component('additionalAgreementModal', AdditionalAgreementModal)
  .component('contractChargesListContainer', ContractChargesListContainer)
  .component('contractsConsigneeWidget', ContractsConsigneeWidget)
  .component('contractChargesListTable', ContractChargesListTable)
  .component('consignmentsContainer', consignmentsContainer)
  .component('basisDocsTable', BasisDocsTable).name;
