import ng from 'angular';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { AdditionalAgreementsService } from '^/app/deals/contracts/additional-agreements.service';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive('additionalAgreementRow', directive);

  function directive() {
    return {
      template: require('./additional-agreement-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        additionalAgreement: '<',
        edit: '<?',
      },
    };
  }

  Controller.$inject = [
    '$scope',
    'AdditionalAgreementsService',
    'gettext',
    'GtUtils',
    'ContractsService',
    'DocumentsService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    AdditionalAgreementsService: AdditionalAgreementsService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    DocumentsService: any,
  ) {
    const vm = this;
    vm.cancel = cancel;
    vm.save = save;
    vm.destroy = destroy;
    vm.openModal = openModal;
    vm.openDocxModal = openDocxModal;

    ////////////////

    function save() {
      if (!vm.additionalAgreement.id) {
        return AdditionalAgreementsService.AdditionalAgreement.save(
          vm.additionalAgreement,
          function (data: any) {
            vm.additionalAgreement = data;
            vm.edit = false;
            emitUpdate();
          },
          (e: any) => GtUtils.errorClb(e),
        ).$promise;
      }
      return AdditionalAgreementsService.AdditionalAgreement.update(
        vm.additionalAgreement,
        function (data: any) {
          vm.additionalAgreement = data;
          vm.edit = false;
        },
        (e: any) => GtUtils.errorClb(e),
      ).$promise;
    }

    function cancel() {
      vm.edit = false;
    }

    function destroy() {
      if (!confirm(gettext('Are you sure that you want delete?'))) {
        return;
      }
      return AdditionalAgreementsService.AdditionalAgreement.delete(
        { id: vm.additionalAgreement.id },
        emitUpdate,
        (e: any) => GtUtils.errorClb(e),
      ).$promise;
    }

    function openModal(additionalAgreement: any) {
      return ContractsService.additionalAgreementModal(additionalAgreement, {}).then(() =>
        emitUpdate(),
      );
    }

    function openDocxModal() {
      return ContractsService.Contract.query({
        id: vm.additionalAgreement.contract,
        serializer: 'modal',
      }).$promise.then((contract: any) => {
        return DocumentsService.generateDocxModal(
          contract.contract_type.charAt(0).toUpperCase() +
            contract.contract_type.slice(1) +
            'Contract',
          contract.id,
          { params: { additional_agreement_id: vm.additionalAgreement.id } },
        );
      });
    }

    function emitUpdate() {
      $scope.$emit('additional-agreements-update');
    }
  }
})();
