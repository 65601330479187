import template from './vehicles-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const VehiclesContainer = {
  bindings: {
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'VehiclesService',
    'LogisticsService',
    class {
      $scope: ng.IScope;
      LogisticsService: LogisticsService;
      VehiclesService: any;
      filterLevel = '';
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      vehicles: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        VehiclesService: any,
        LogisticsService: LogisticsService,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.VehiclesService = VehiclesService;
        this.LogisticsService = LogisticsService;
      }

      $onInit() {
        this.vehicles = [];
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateVehiclesList();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      openVehicleModal(vehicle: any) {
        this.VehiclesService.openVehicleModal(vehicle).then(() => this.updateVehiclesList());
      }

      updateVehiclesList() {
        this.LogisticsService.Vehicle.query(this.queryParams).$promise.then(
          (data: any) => (this.vehicles = data.results),
        );
      }
    },
  ],
};
