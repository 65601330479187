import type ng from 'angular';

import template from './transactions-container.tpl.html?raw';

import { type GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import { type GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import { type ReportsService } from '^/app/reports/legacy/reports.srv';

class TransactionsContainerController implements ng.IController {
  transactions = [];
  transactionsCount = 0;
  filterLevel?: string = 'transactions-container';
  initQueryParams: QueryParams = {};
  queryParams: QueryParams = {};
  searchPrompt?: string;

  static readonly $inject = ['$scope', 'GtUtils', 'ReportsService', 'gtFilterService'];

  constructor(
    private readonly $scope: ng.IScope,
    private GtUtils: GtUtilsService,
    private ReportsService: ReportsService,
    private gtFilterService: GtFilterService,
  ) {}

  $onInit() {
    this.filterLevel = this.filterLevel ?? 'transactions-container';
    this.queryParams = {
      serializer: 'table_info',
      ...this.initQueryParams,
    };
    this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
      this.queryParams = data;
      this.updateTransactions();
    });
    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
  }

  $onChanges() {
    this.queryParams = {
      serializer: 'table_info',
      ...this.initQueryParams,
    };
  }

  copyToClipboard(docNumber: string) {
    this.GtUtils.copyToClipboard(docNumber);
  }

  updateTransactions() {
    this.GtUtils.overlay('show');
    return this.ReportsService.TransactionReport.query(this.queryParams, (data: any) => {
      this.transactions = data.results;
      this.transactionsCount = data.count;
      this.GtUtils.overlay('hide');
    });
  }
}

export const transactionsContainer: ng.IComponentOptions = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    searchPrompt: '<?',
  },
  template: template,
  controller: TransactionsContainerController,
};
