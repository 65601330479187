export class PotentialPurchaseService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getData(params: object) {
    return this.$http
      .get('/api/contracts/general-agreements/potential-purchase/list/', {
        params: params,
      })
      .then((response: any) => {
        return response.data;
      });
  }
}
PotentialPurchaseService.$inject = ['$http'];
