import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('common.crops.legacy').controller('WarehouseModalController', Controller);

  Controller.$inject = [
    '$scope',
    '$window',
    '$uibModalInstance',
    'gtFilterService',
    'CropsService',
    'warehouse',
    'crop',
    'extraData',
    'filterLevel',
    'warehouseType',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $window: ng.IWindowService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    gtFilterService: GtFilterService,
    CropsService: any,
    warehouse: any,
    crop: any,
    extraData: any,
    filterLevel: string,
    warehouseType: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.close = close;
    vm.option = 'view';
    vm.lossQueryParams = {
      cargo: crop.id,
    };
    vm.logisticsQueryParams = {
      cargo_list: crop.id,
      warehouse: warehouse.id,
      logistic_type: 'main',
      warehouse_type: warehouseType,
    };
    vm.warehouseQueryParams = {
      commodity_list: crop.id,
      warehouse: warehouse.id,
    };
    vm.intermediateLogisticsQueryParams = {
      cargo_list: crop.id,
      logistic_type: 'intermediate',
      warehouse: warehouse.id,
    };

    activate();

    ////////////////

    function activate() {
      vm.filterLevel = filterLevel;
      vm.crop = crop;
      vm.warehouse = warehouse;
      vm.extraData = extraData;
      vm.subtab = 'in';

      const params = gtFilterService.getQueryParams(vm.filterLevel);
      params.page_size = undefined;
      vm.lossQueryParams = Object.assign(vm.lossQueryParams, params);
      vm.lossQueryParams.warehouse = warehouse.id;
      vm.lossQueryParams.warehouse_type = warehouse.warehouse_type;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return false;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
