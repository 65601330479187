import React, { useEffect, useState } from 'react';
import type { DateRange as DayPickerDateRange } from 'react-day-picker';

import { Button } from '~/shared/ui/kit/button';
import { DatePicker } from '~/shared/ui/kit/date-picker';

import type { DateRange } from '../../data-types';

export const DateRangePicker: React.FC<{
  dates: DateRange;
  datesChanged?: (dates?: DateRange) => void;
  required?: boolean;
}> = ({ datesChanged, required, dates }) => {
  const [tempRange, setTempRange] = useState<DateRange>({
    from: dates.from ?? undefined,
    to: dates.to ?? undefined,
  });

  useEffect(() => {
    setTempRange({ from: dates.from ?? undefined, to: dates.to ?? undefined });
  }, [dates]);

  const handleSelect = (selected: DayPickerDateRange | undefined) => {
    if (!selected?.from) {
      return;
    }

    setTempRange((prev) => {
      if (!prev.from || prev.to || (selected.from && selected.from < prev.from)) {
        return { from: selected.from, to: undefined };
      } else if (!prev.to && selected.to) {
        return { from: prev.from, to: selected.to };
      }
      return prev;
    });
  };

  const applySelection = () => {
    if (tempRange.from && tempRange.to) {
      datesChanged?.(tempRange);
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <DatePicker
        mode="range"
        selected={tempRange as DayPickerDateRange}
        onSelect={handleSelect}
        numberOfMonths={2}
        required={required}
        classNames={{
          root: 'w-[560px]',
          nav: 'absolute right-6 top-3 flex items-center space-x-1',
          range_middle:
            'aria-selected:bg-background-section-light [&>button]:text-text-main-primary aria-selected:rounded-none',
        }}
      />
      <Button
        data-testid="apply-selection-button"
        variant="primary"
        fullWidth
        onClick={applySelection}
        disabled={!tempRange.from || !tempRange.to}
      >
        Apply
      </Button>
    </div>
  );
};
