import {
  ContractSerializer,
  IntermediateContractPatch,
  IntermediateContractPost,
  IntermediateContractRecord,
  IntermediateContractsQueryParams,
} from '~/features/deals/contracts';
import {
  IntermediateContract,
  contractsIntermediateContractsCreate,
  contractsIntermediateContractsDestroy,
  contractsIntermediateContractsListExecutionList,
  contractsIntermediateContractsListInfoList,
  contractsIntermediateContractsPartialUpdate,
  contractsIntermediateContractsPredictionsRetrieve,
  contractsIntermediateContractsRetrieve,
  contractsIntermediateContractsTotalsExecutionRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';
import { EntityOption } from '~/shared/ui/data-types';

export const intermediateContractsListDatasourceMap = {
  table_info: contractsIntermediateContractsListInfoList,
  table_execution: contractsIntermediateContractsListExecutionList,
  table_reconciliation: contractsIntermediateContractsListExecutionList,
  table_prepayments_accounting: contractsIntermediateContractsListInfoList,
  default: contractsIntermediateContractsListInfoList,
};

@singleton()
export class IntermediateContractsRepository extends EntityRepository<
  IntermediateContract,
  IntermediateContractsQueryParams,
  IntermediateContractRecord,
  IntermediateContractPost,
  IntermediateContractPatch
> {
  readonly entityName = 'contracts.IntermediateContract';
  listDatasource: (typeof intermediateContractsListDatasourceMap)[keyof typeof intermediateContractsListDatasourceMap] =
    intermediateContractsListDatasourceMap.default;

  setDatasources = (dsKey: ContractSerializer) => {
    this.listDatasource =
      intermediateContractsListDatasourceMap[dsKey] ||
      intermediateContractsListDatasourceMap.default;
  };

  getFn = async (id: number) => {
    const { data } = await contractsIntermediateContractsRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: IntermediateContractsQueryParams) => {
    const { data } = await this.listDatasource({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await contractsIntermediateContractsPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };
  createFn = async (dto: IntermediateContractPost) => {
    const res = await contractsIntermediateContractsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  updateFn = async (entity: IntermediateContractPatch) => {
    const { data } = await contractsIntermediateContractsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  deleteFn = async (id: number) => {
    await contractsIntermediateContractsDestroy({ path: { id } });
  };

  getTotals = async (query: IntermediateContractsQueryParams) => {
    const { data } = await contractsIntermediateContractsTotalsExecutionRetrieve({ query });
    return data;
  };
}
