import type ng from 'angular';

export class InvoicePositionService {
  InvoicePositionResource: any;

  static readonly $inject = ['$resource'];

  constructor(private readonly $resource: ng.resource.IResourceService) {
    this.InvoicePositionResource = this.$resource(
      '/api/finances/invoice-positions/:id/',
      {
        id: '@id',
      },
      {
        list: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/invoice-position-list/',
        },
        total: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/invoice-position-totals/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/invoice-positions/predictions/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/invoice-positions/bulk_create_or_update/',
        },
      },
    );
  }
  getInvoicePositionTableData(params: object) {
    return this.InvoicePositionResource.list(params).$promise;
  }

  getInvoicePositionTotalsData(params: object) {
    return this.InvoicePositionResource.total(params).$promise;
  }

  bulkCreateOrUpdate(params: object, positions: any) {
    return this.InvoicePositionResource.bulkCreateOrUpdate(params, positions).$promise;
  }

  getUpdatedObjects(positions: any, params: object) {
    return positions.map((item: any) => ({
      id: item.id,
      ...params,
    }));
  }
}
