import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './logistics-documents-modal.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const LogisticsDocumentsModal = {
  bindings: {
    modalInstance: '<',
    queryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    '$q',
    'GtUtils',
    'CoreService',
    'DocumentsService',
    'gettext',
    class {
      $q: ng.IQService;
      $scope: ng.IScope;
      CoreService: CoreService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      allDocumentsSelected: any;
      certificateTypes: any;
      contentTypeId?: string;
      errors: any;
      files?: Record<string, any[]>;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      constructor(
        $scope: ng.IScope,
        $q: ng.IQService,
        GtUtils: GtUtilsService,
        CoreService: CoreService,
        DocumentsService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.$q = $q;
        this.GtUtils = GtUtils;
        this.CoreService = CoreService;
        this.DocumentsService = DocumentsService;
        this.gettext = gettext;
        this.certificateTypes = undefined;
        this.errors = undefined;
        this.allDocumentsSelected = undefined;
      }

      $onInit() {
        this.errors = {};
        this.allDocumentsSelected = false;
        this.certificateTypes = [
          'railway',
          'vet',
          'quarantine',
          'quality',
          'declaration',
          'eur_one',
          'broker_confirmation',
          'additional_document_one',
          'additional_document_two',
          'additional_document_three',
        ];
        this.certificateTypes.forEach((certificateType: any) => {
          if (!this.files) {
            return;
          }
          this.files[certificateType] = [];
          this.errors[certificateType] = {};
        });

        this.CoreService.getModelContentType('contractbase').then(
          (ct: any) => (this.contentTypeId = ct.id),
        );

        this.$scope.$on('logistics__checkboxes-update', (ev: any, rawLogistics: any) => {
          this.getFlatFilesList()
            .filter((file) => file.isSelected)
            .forEach((file) => (file.rawLogistics = rawLogistics));
        });

        this.$scope.$watch(
          () => this.allDocumentsSelected,
          (allDocumentsSelected: any) => this.toggleAll(allDocumentsSelected),
        );
      }

      fileSelect(files: any, certificateType: any) {
        delete this.errors[certificateType].file_size;
        if (!this.files?.[certificateType]) {
          return;
        }
        this.files[certificateType] = files.map((file: any) => {
          return {
            file: file,
          };
        });
        this.files[certificateType].forEach(function (this: any, fileObj: any) {
          if (!fileObj.file.size) {
            this.errors[certificateType].file_size = this.gettext('empty file');
          } else if (fileObj.file.size > 52428800) {
            this.errors[certificateType].file_size = this.gettext(
              'single document size up to 50 mb',
            );
          }
          fileObj.title = fileObj.file.name;
          fileObj.certificate_type = certificateType;
          fileObj.certificate_date = new Date();
        });

        if (this.errors[certificateType].file_size) {
          notify(this.errors[certificateType].file_size, 'error');
          return false;
        }
      }

      getFlatFilesList() {
        if (!this.files) {
          return [];
        }
        return Object.values(this.files).flat();
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      toggleAll(allDocumentsSelected: any) {
        const files = this.getFlatFilesList();
        if (allDocumentsSelected) {
          files.forEach((file) => (file.isSelected = true));
        } else {
          files.forEach((file) => (file.isSelected = false));
        }
      }

      deleteItem(file: any) {
        this.certificateTypes.forEach((certificateType: any) => {
          const index = this.files?.[certificateType].indexOf(file);
          if (index !== -1 && index !== undefined) {
            this.files?.[certificateType].splice(index, 1);
          }
        });
      }

      save() {
        let chain = this.$q.when();
        const filteredFiles = this.getFilteredFiles();
        filteredFiles.forEach((file) => {
          const groupedLogistics = this.CoreService.groupBy(file.rawLogistics, 'contract');
          Object.keys(groupedLogistics).forEach((groupKey) => {
            chain = chain.then(
              () =>
                this.DocumentsService.attachDocumentToLogistics({
                  object_id: groupKey,
                  content_type: this.contentTypeId,
                  title: file.title,
                  file: file.file,
                  logistics: this.perpareLogistics(groupedLogistics[groupKey], file),
                }),
              (err: any) => this.error(err),
            );
          });
        });
        return chain.then(
          () => this.success(),
          (err: any) => this.error(err),
        );
      }

      success() {
        notify(this.gettext('Documents saved'));
        this.getFilteredFiles().forEach((item) => this.deleteItem(item));
      }

      error(err: any) {
        this.GtUtils.errorClb(err);
      }

      getFilteredFiles() {
        return this.getFlatFilesList().filter((file) => file.isSelected && file.rawLogistics);
      }

      perpareLogistics(rawLogistics: any, file: any) {
        return rawLogistics.map((rawLogistic: any) => {
          return {
            logistic: rawLogistic.id,
            certificate_type: file.certificate_type,
          };
        });
      }
    },
  ],
};
