import { logisticsVoyagesList, logisticsVoyagesPredictionsRetrieve } from '~/shared/api';
import { EntityRepository, type Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Voyage, VoyageListParams } from '../lib';

export class VoyageRepository extends EntityRepository<Voyage, VoyageListParams> {
  readonly entityName = 'logistics.Voyage';

  getFn = (_id: number): Promise<Voyage> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: Voyage): Promise<Voyage> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Voyage>): Promise<Voyage> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: VoyageListParams): Promise<Paginated<Voyage>> => {
    const { data } = await logisticsVoyagesList({ query });
    return { records: data.results, count: data.count };
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await logisticsVoyagesPredictionsRetrieve({ query: { voyage_name: input } });
    return data.results;
  };
}
