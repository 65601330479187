import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './logistic-details-item-container.html?raw';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const logisticDetailsItemContainer = {
  bindings: {
    logistic: '<',
    filterLevel: '<',
    updateConfigWithFilterLevel: '&?',
  },
  template,
  controller: [
    '$scope',
    '$state',
    '$rootScope',
    '$stateParams',
    'gtFilterService',
    'DocumentsService',
    'ClientsService',
    'FormFieldParamsService',
    'LogisticsService',
    'GtUtils',
    'PageService',
    'gettext',
    'LogisticFormFieldsService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      ClientsService: any;
      DocumentsService: any;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      LogisticFormFieldsService: any;
      LogisticsService: LogisticsService;
      activeTabs: any;
      fields: any;
      filterLevel = 'logistic-page-view';
      form: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      hideWizard: any;
      logistic: any;
      logisticsQueryParams: QueryParams = {};
      tab = 'comments';
      updateConfigWithFilterLevel: any;
      updating: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        $rootScope: GtRootScopeService,
        $stateParams: ng.ui.IStateParamsService,
        gtFilterService: GtFilterService,
        DocumentsService: any,
        ClientsService: any,
        FormFieldParamsService: FormFieldParamsService,
        LogisticsService: LogisticsService,
        GtUtils: GtUtilsService,
        PageService: PageService,
        gettext: ng.gettext.gettextFunction,
        LogisticFormFieldsService: any,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.gtFilterService = gtFilterService;
        this.DocumentsService = DocumentsService;
        this.ClientsService = ClientsService;
        this.LogisticsService = LogisticsService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.GtUtils = GtUtils;
        this.PageService = PageService;
        this.gettext = gettext;
        this.LogisticFormFieldsService = LogisticFormFieldsService;

        this.logistic = {};

        this.activeTabs = [];

        this.form = undefined;
        this.fields = [];
        this.logisticsQueryParams = {};
        if (this.$rootScope.user.profile?.wizard) {
          this.hideWizard = false;
        } else {
          this.hideWizard = true;
        }
      }

      $onInit() {
        this.logisticsQueryParams = { invoice: this.logistic.id };
        this.tab = this.$stateParams.tab || 'comments';
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
          if (data.tab) {
            this.tab = data.tab;
          }
        });
        if (this.$stateParams.tab) {
          this.gtFilterService.updateQueryParams({ tab: this.$stateParams.tab }, this.filterLevel);
        }
        this.updateData();
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.LogisticsService.Logistic.query(
          { id: this.logistic.id, serializer: 'modal' },
          (data: any) => {
            this.logistic = data;
            this.getQualities(this.logistic);
            this.getDetails();
            this.PageService.setPageTitle(
              this.$rootScope.pageTitle + ' #' + this.logistic.vehicle_number,
            );
            this.gtFilterService.updateQueryParams({}, this.filterLevel);
            this.updating = false;
            this.updateConfigWithFilterLevel({
              logistic: this.logistic,
              filterLevel: this.filterLevel,
            });
            this.updateFields();
            this.GtUtils.overlay('hide');
            return this.logistic;
          },
          () => {
            this.GtUtils.overlay('hide');
            notify(this.gettext("Logistic doesn't exist"));
            this.$state.go('logistics.transport');
          },
        ).$promise;
      }

      openLogisticModal(logistic: any) {
        return this.LogisticsService.logisticModal(logistic).then(() => this.updateData());
      }

      openDocumentModal() {
        return this.DocumentsService.documentModal('Logistic', this.logistic.id).then(() =>
          this.updateData(),
        );
      }

      openDocxModal() {
        return this.DocumentsService.generateDocxModal('Logistic', this.logistic.id);
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => {
            this.fields = fields;
            this.GtUtils.overlay('hide');
          })
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        return this.LogisticFormFieldsService.getFields(this.logistic);
      }

      openEmailModal() {
        return this.ClientsService.sendEmailModal('Logistic', this.logistic.id);
      }

      changeTab(tab: string) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);

        const tabsWithHiddenWizard: any = ['execution'];

        if (this.activeTabs.some((tab: string) => tabsWithHiddenWizard.includes(tab))) {
          this.hideWizard = true;
        } else {
          this.hideWizard = false;
        }
        if (!this.$rootScope.user.profile?.wizard) {
          this.hideWizard = true;
        }
      }

      getQualities(logistic: any) {
        this.LogisticsService.LogisticQuality.query({ logistic: logistic.id }).$promise.then(
          (data: any) => {
            this.logistic = { ...this.logistic, qualities: data.results };
          },
        );
      }

      getDetails() {
        this.LogisticsService.Logistic.query({
          id: this.logistic.id,
          serializer: 'details',
        }).$promise.then((data: any) => {
          this.logistic = { ...this.logistic, ...data };
        });
      }

      clone(logistic: any) {
        return this.LogisticsService.cloneLogistic(logistic);
      }
    },
  ],
};
