import type ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import template from './customs-cargo-declaration-page-view.html?raw';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const CustomsCargoDeclarationPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'PageService',
    'LogisticsService',
    'gettext',
    'CustomValuesService',
    class CustomsCargoDeclarationPageView {
      $scope: ng.IScope;
      CustomValuesService: CustomValuesService;
      LogisticsService: LogisticsService;
      PageService: PageService;
      filterLevel = 'customs-cargo-declaration-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        PageService: PageService,
        LogisticsService: LogisticsService,
        gettext: ng.gettext.gettextFunction,
        CustomValuesService: CustomValuesService,
      ) {
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.LogisticsService = LogisticsService;
        this.gettext = gettext;
        this.CustomValuesService = CustomValuesService;
        this.$scope = $scope;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        const pageConfig: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
          ],
          pages: this.LogisticsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            dates: true,
            dateSelects: [
              {
                argument: 'date',
                placeholder: this.gettext('Date'),
              },
              { argument: 'last_payment_date', placeholder: this.gettext('Payment date') },
              {
                argument: 'border_crossing_date',
                placeholder: this.gettext('Border Crossing Date'),
              },
            ],
            multiSelects: [
              {
                argument: 'contracts_list',
                placeholder: this.gettext('contract'),
                resource: 'contracts.contractbase',
                queryParams: {
                  has_customs_declaration: '1',
                },
              },
              {
                argument: 'origin_of_commodity_list',
                placeholder: this.gettext('origin'),
                resource: 'location.country',
                related_fk: 'invoice_positions__contract__origin_of_crop',
                related_model: 'logistics.CustomsCargoDeclaration',
              },
              {
                argument: 'destination_of_commodity_list',
                placeholder: this.gettext('destination'),
                resource: 'location.country',
                related_model: 'logistics.CustomsCargoDeclaration',
              },
              {
                argument: 'commodity_list',
                placeholder: this.gettext('Commodity'),
                resource: 'crops.Crop',
                related_fk: 'invoice_positions__crop_id',
                related_model: 'logistics.CustomsCargoDeclaration',
              },
              {
                argument: 'invoice_list',
                placeholder: this.gettext('Invoice'),
                resource: 'finances.Finance',
                related_fk: 'invoice_positions__invoice_id',
                related_model: 'logistics.CustomsCargoDeclaration',
              },
              {
                argument: 'type_of_cd_list',
                placeholder: this.gettext('Type of CD'),
                resource: 'logistics.cdtype',
              },
              {
                argument: 'id_list',
                placeholder: this.gettext('Declaration number'),
                resource: 'logistics.CustomsCargoDeclaration',
              },
              {
                argument: 'export_contract_list',
                placeholder: this.gettext('Export Contract'),
                resource: 'contracts.exportcontract',
                related_fk: 'invoice_positions__contract_id',
                related_model: 'logistics.CustomsCargoDeclaration',
              },
              {
                argument: 'exporter_list',
                placeholder: this.gettext('Exporter'),
                resource: 'clients.clientrole',
                related_model: 'logistics.CustomsCargoDeclaration',
              },
              {
                argument: 'customs_broker_list',
                placeholder: this.gettext('Customs Broker'),
                resource: 'clients.clientrole',
                related_model: 'logistics.CustomsCargoDeclaration',
              },
              {
                argument: 'terminal_list',
                placeholder: this.gettext('Terminal'),
                resource: 'logistics.terminal',
                related_fk: 'terminal',
                related_model: 'logistics.CustomsCargoDeclaration',
              },
              {
                argument: 'port_list',
                placeholder: this.gettext('Port'),
                resource: 'logistics.port',
                related_fk: 'terminal__port',
                related_model: 'logistics.CustomsCargoDeclaration',
              },
              {
                argument: 'sale_contract_list',
                placeholder: this.gettext('Sale Contract'),
                resource: 'contracts.salecontract',
                queryParams: {
                  has_customs_declaration: '1',
                },
              },
              {
                argument: 'purchase_contract_list',
                placeholder: this.gettext('Purchase Contract'),
                resource: 'contracts.purchasecontract',
                queryParams: {
                  has_customs_declaration: '1',
                },
              },
            ],
            selects: [],
            nestedSelects: [],
            nestedMultiSelects: [
              {
                argument: 'invoice_status_list',
                placeholder: this.gettext('Invoice Status'),
                items: [
                  { id: 'new', title: this.gettext('New') },
                  { id: 'process', title: this.gettext('Process') },
                  { id: 'paid', title: this.gettext('Paid') },
                  { id: 'canceled', title: this.gettext('Canceled') },
                ],
              },
              {
                argument: 'declaration_type_list',
                placeholder: this.gettext('Declaration type'),
                items: [
                  { id: 'export', title: this.gettext('Export') },
                  { id: 'import', title: this.gettext('Import') },
                  { id: 'transit', title: this.gettext('Transit') },
                ],
              },
              {
                argument: 'stage_list',
                placeholder: this.gettext('Stage'),
                items: [
                  { id: 'periodic', title: this.gettext('Periodic customs declaration') },
                  { id: 'cargo', title: this.gettext('Cargo customs declaration') },
                ],
              },
              {
                argument: 'result_status_list',
                placeholder: this.gettext('Result status'),
                items: [
                  { title: this.gettext('Default'), id: 'default' },
                  { title: this.gettext('Approved'), id: 'approved' },
                  { title: this.gettext('Rejected'), id: 'rejected' },
                  { title: this.gettext('Overdue'), id: 'overdue' },
                ],
              },
              {
                argument: 'days_until_final_day_list',
                placeholder: this.gettext('Days until final day'),
                items: [
                  { id: '-0', title: this.gettext('Less 0') },
                  { id: '0-10', title: this.gettext('0 - 10') },
                  { id: '11-30', title: this.gettext('11 - 30') },
                  { id: '31-60', title: this.gettext('31 - 60') },
                  { id: '61-90', title: this.gettext('61 - 90') },
                  { id: '91-', title: this.gettext('More 90') },
                ],
              },
            ],
          },
        };
        this.CustomValuesService.getCustomFieldFilters({
          purpose_model: 'customscargodeclaration',
        })
          .then((customFieldsFilters) => {
            pageConfig.filters.multiSelects.push(...customFieldsFilters.multiSelects);
            pageConfig.filters.selects.push(...customFieldsFilters.selects);
            pageConfig.filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
            pageConfig.filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
            pageConfig.filters.dateSelects.push(...customFieldsFilters.dateSelects);
            this.$scope.$applyAsync();
          })
          .catch(errorHandler);
        return pageConfig;
      }
    },
  ],
};
