import type ng from 'angular';

import type { User } from '~/features/accounts';
import { notify } from '~/shared/lib/notify';

import template from './user-settings-container.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const UserSettingsContainer = {
  bindings: { userId: '<' },
  template,
  controller: [
    '$scope',
    'Upload',
    'GtUtils',
    'gettext',
    'AccountsService',
    'CoreService',
    class {
      $scope: ng.IScope;
      Upload: ng.angularFileUpload.IUploadService;
      AccountsService: AccountsService;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      currentPassword = '';
      defaultBisnessUnit?: number;

      uploadingFile?: File;
      errors: Error[] = [];
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      newPassword = '';
      newPasswordConfirm = '';
      user?: User;
      userId = 0;
      constructor(
        $scope: ng.IScope,
        Upload: ng.angularFileUpload.IUploadService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        AccountsService: AccountsService,
        CoreService: CoreService,
      ) {
        this.$scope = $scope;
        this.Upload = Upload;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.AccountsService = AccountsService;
        this.CoreService = CoreService;
        this.filterLevel = 'user-settings-container';
      }

      $onInit() {
        if (!this.userId) {
          throw new Error('userId is required');
        }

        this.updateData();
      }

      updateData = () => {
        this.GtUtils.overlay('show');
        this.AccountsService.User.get(this.userId).then(
          (data) => {
            this.user = data;
            this.defaultBisnessUnit = this.user.profile.business_units
              .filter((bu) => bu.is_default)
              .map((bu) => bu.businessunit)
              .shift();
            this.GtUtils.overlay('hide');
          },
          (data) => {
            this._error(data);
            this.GtUtils.overlay('hide');
          },
        );
      };

      changePreferences(newPrefsObj: object) {
        this.GtUtils.overlay('show');
        this.AccountsService.UserSelf.update(
          {
            id: this.user?.id,
            profile: newPrefsObj,
          },
          (data: any) => {
            if (this.user) {
              this.user.profile = data.profile;
            }
            this.GtUtils.overlay('hide');
          },
          (data: any) => {
            this._error(data);
            this.GtUtils.overlay('hide');
          },
        );
      }

      saveDefaultBu() {
        this.user?.profile.business_units.forEach((bu) => {
          if (bu.businessunit == this.defaultBisnessUnit) {
            bu.is_default = true;
          } else {
            bu.is_default = false;
          }
        });

        this.changePreferences({ business_units: this.user?.profile.business_units });
      }

      changePassword() {
        this.GtUtils.overlay('show');
        this.AccountsService.UserSelf.changePassword({
          id: this.user?.id,
          current: this.currentPassword,
          new: this.newPassword,
          confirm: this.newPasswordConfirm,
        })
          .then((data: any) => {
            notify(data.msg);
            this.currentPassword = '';
            this.newPassword = '';
            this.newPasswordConfirm = '';
            this.GtUtils.overlay('hide');
          })
          .catch((data: any) => {
            this._error(data);
            this.GtUtils.overlay('hide');
          });
      }

      fileSelect(files: FileList) {
        if (files[0].size > 1048576 || !this.user) {
          notify(this.gettext('single document size up to 1 mb'), 'error');
          this.errors.push(new Error('File size is too big'));
          return;
        }
        this.uploadingFile = files[0];
        this.errors = [];
      }

      saveAttachment() {
        this.Upload.upload({
          url: `/api/accounts/users/self/${this.user?.id}/update_avatar/`,
          method: 'POST',
          data: this.uploadingFile,
        })
          .then(this.updateData)
          .catch(this._error);
      }

      _error = (data: any) => {
        this.GtUtils.errorClb(data);
        this.errors = data.data;
      };
    },
  ],
};
