import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './reassignment-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

export const ReassignmentPageContainer = {
  template,
  controller: [
    '$stateParams',
    '$state',
    '$rootScope',
    'PageService',
    'ReassignmentService',
    'gettext',
    'gtFilterService',
    'GtUtils',
    class {
      $rootScope: GtRootScopeService;
      $state: ng.ui.IStateService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      ReassignmentService: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      reassignment: any;
      reassignmentPositions: any;
      tab: string;
      constructor(
        $stateParams: ng.ui.IStateParamsService,
        $state: ng.ui.IStateService,
        $rootScope: GtRootScopeService,
        PageService: PageService,
        ReassignmentService: any,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
      ) {
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.PageService = PageService;
        this.ReassignmentService = ReassignmentService;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.reassignment = { id: $stateParams.id };
        this.GtUtils = GtUtils;
        this.tab = 'info';
        this.filterLevel = 'reassignment-page';
      }

      $onInit() {
        this.PageService.setConfig({});
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.ReassignmentService.getReassignmentDetails({ id: this.reassignment.id })
          .then((data: any) => {
            this.reassignment = data;
            this.PageService.setPageTitle(
              this.$rootScope.pageTitle + ' #' + this.reassignment.number,
            );
            this.GtUtils.overlay('hide');
          })
          .catch(() => {
            notify(this.gettext("Reassignment doesn't exist"));
            this.$state.go('finances.reassignmentsPage');
          });
      }

      reassignmentPositionIsValid() {
        return this.ReassignmentService.reassignmentPositionIsValid(this.reassignmentPositions);
      }
    },
  ],
};
