import type ng from 'angular';

import template from './sample-control-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const SampleControlPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gtFilterService',
    'PageService',
    'GtUtils',
    'LogisticsService',
    'gettext',
    class SampleControlPageView {
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      PageService: PageService;
      filterLevel = 'sample-control-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        gtFilterService: GtFilterService,
        PageService: PageService,
        GtUtils: GtUtilsService,
        LogisticsService: LogisticsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.GtUtils = GtUtils;
        this.LogisticsService = LogisticsService;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
          ],
          pages: this.LogisticsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            dates: true,
            dateSelects: [
              {
                argument: 'in_date',
                placeholder: this.gettext('Sample In date'),
              },
              {
                argument: 'out_date',
                placeholder: this.gettext('Sample Out date'),
              },
              {
                argument: 'result_date',
                placeholder: this.gettext('Sample result date'),
              },
              {
                argument: 'received_date',
                placeholder: this.gettext('Sample received date'),
              },
            ],
            nestedSelects: [
              {
                argument: 'result_status',
                placeholder: this.gettext('Result status'),
                items: [
                  { id: 'approved', title: 'Approved' },
                  { id: 'rejected', title: 'Rejected' },
                ],
              },
              {
                argument: 'sample_type',
                placeholder: this.gettext('Sample type'),
                items: [
                  { id: 'type', title: 'Type' },
                  { id: 'preshipment', title: 'Preshipment' },
                  { id: 'stocklot', title: 'Stocklot' },
                ],
              },
              this.gtFilterService.getBoolFilter('has_result_date', this.gettext('Result date')),
              this.gtFilterService.getBoolFilter(
                'has_received_date',
                this.gettext('Received date'),
              ),
            ],
            multiSelects: [
              {
                argument: 'purchase_contract_list',
                placeholder: this.gettext('purchase contract'),
                resource: 'contracts.purchasecontract',
              },
              {
                argument: 'sale_contract_list',
                placeholder: this.gettext('sale contract'),
                resource: 'contracts.salecontract',
              },
              {
                argument: 'supplier_list',
                placeholder: this.gettext('supplier'),
                resource: 'clients.supplier',
              },
              {
                argument: 'buyer_list',
                placeholder: this.gettext('buyer'),
                resource: 'clients.buyer',
              },
              {
                argument: 'receiver_list',
                placeholder: this.gettext('receiver'),
                resource: 'clients.clientrole',
              },
              {
                argument: 'destination_country_list',
                placeholder: this.gettext('destination country'),
                resource: 'location.country',
              },
            ],
            nestedMultiSelects: [this.gtFilterService.getCropsFilter('commodity_list')],
          },
        };
      }
    },
  ],
};
