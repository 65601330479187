import ng from 'angular';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { HelpButtonsService } from '^/app/core/legacy/help-buttons/help-buttons.srv';

(function () {
  'use strict';
  ng.module('company.legacy').controller('RreportsTeamController', Controller);

  Controller.$inject = ['GtUtils', 'TeamService', 'HelpButtonsService'];

  function Controller(
    this: any,
    GtUtils: GtUtilsService,
    TeamService: any,
    HelpButtonsService: HelpButtonsService,
  ) {
    const vm = this;

    vm.teamCount = 0;
    vm.traders = [];
    vm.team = null;

    activate();

    ////////////////

    function activate() {
      HelpButtonsService.setConfig(getPageConfig());
      updateData();
    }

    function updateData() {
      GtUtils.overlay('show');
      return TeamService.Trader.query(function (callbackdata: any) {
        vm.team = callbackdata;
        vm.traders = callbackdata.traders;
        vm.teamCount = callbackdata.traders_counter;
        GtUtils.overlay('hide');
      }).$promise;
    }

    function getPageConfig() {
      return {
        print: {},
        update: {
          method: updateData,
        },
      };
    }
  }
})();
