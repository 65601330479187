import React from 'react';

import { cn } from '~/shared/lib/utils';

export type LinkProps = {
  href: string;
  children: React.ReactNode;
  disabled?: boolean;
};

export const Link: React.FC<LinkProps> = ({ href, children, disabled }) => {
  return (
    <a
      {...(!disabled && { href: href })}
      target="_blank"
      className={cn(
        'border-stroke-main-strong bg-transparent-lighter text-text-main-primary hover:bg-transparent-medium active:bg-transparent-strong rounded-full border px-2 py-0.5 text-xs transition duration-300',
        disabled && 'cursor-not-allowed',
      )}
      rel="noreferrer"
      aria-disabled={disabled}
    >
      {children}
    </a>
  );
};
