import { coreCustomStatusesList, coreCustomStatusesPredictionsRetrieve } from '~/shared/api';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { CustomStatus, CustomStatusQueryParams } from '../lib/types';

export class CustomStatusRepository extends EntityRepository<
  CustomStatus,
  CustomStatusQueryParams
> {
  readonly entityName = 'core.IndicatorCustomStatus';

  getFn = (_id: number): Promise<CustomStatus> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: CustomStatus): Promise<CustomStatus> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<CustomStatus>): Promise<CustomStatus> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: CustomStatusQueryParams) => {
    const { data } = await coreCustomStatusesList({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await coreCustomStatusesPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };

  searchWithContentType = (content_type__model: string) => {
    return async (input: string): Promise<EntityOption[]> => {
      const { data } = await coreCustomStatusesPredictionsRetrieve({
        query: { search: input, content_type__model },
      });
      return data.results;
    };
  };
}
