import ng from 'angular';

import { clientDetailsItemContainer } from './item-container/client-details-item-container.component';
import { clientDetailsItemView } from './item-view/client-details-item-view.component';
import { clientDetailsPage } from './page/client-details-page.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const clientConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('gt.page.client', {
      url: '/clients/clients/:id',
      params: {
        tab: null,
      },
      component: 'clientDetailsPage',
      resolve: {
        filterLevel: () => {
          return 'client-side-list';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('client-side-list');
          },
        ],
      },
      data: {
        pageTitle: gettext('Counterparties'),
      },
    });
  },
];

export const clientDetails = ng
  .module('crm.clients.details', [])
  .component('clientDetailsPage', clientDetailsPage)
  .component('clientDetailsItemContainer', clientDetailsItemContainer)
  .component('clientDetailsItemView', clientDetailsItemView)
  .config(clientConfig).name;
