import type ng from 'angular';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { html } from '^/shared/utils';

export class PassportFormFieldsService {
  $rootScope: GtRootScopeService;
  $window: ng.IWindowService;
  AccountsService: AccountsService;
  CoreService: CoreService;
  FinancesService: FinancesService;
  FormFieldParamsService: FormFieldParamsService;
  GtUtils: GtUtilsService;
  LogisticsService: LogisticsService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $rootScope: GtRootScopeService,
    gettext: ng.gettext.gettextFunction,
    $window: ng.IWindowService,
    GtUtils: GtUtilsService,
    AccountsService: AccountsService,
    CoreService: CoreService,
    LogisticsService: LogisticsService,
    FormFieldParamsService: FormFieldParamsService,
    FinancesService: FinancesService,
  ) {
    this.$rootScope = $rootScope;
    this.gettext = gettext;
    this.$window = $window;
    this.GtUtils = GtUtils;
    this.AccountsService = AccountsService;
    this.CoreService = CoreService;
    this.LogisticsService = LogisticsService;
    this.FormFieldParamsService = FormFieldParamsService;
    this.FinancesService = FinancesService;
  }

  getFields(passport: any, formName: string) {
    formName = formName || 'passport-edit-modal';
    let isExchangeFieldRequired: any;
    this.FormFieldParamsService.isRequiredCurrencyExchangeField(
      'passport-edit-modal',
      passport.business_unit,
    ).then((required: any) => (isExchangeFieldRequired = required));
    const fieldsConfig: any = {
      formName: formName,
      fieldsDef: [
        { className: 'form-group-container col-sm-3 col-xs-12', fieldGroup: [] },
        { className: 'form-group-container col-sm-3 col-xs-12', fieldGroup: [] },
        { className: 'form-group-container col-sm-3 col-xs-12', fieldGroup: [] },
        { className: 'form-group-container col-sm-3 col-xs-12', fieldGroup: [] },
      ],
    };

    // col0 - TITLE
    fieldsConfig.fieldsDef[0].fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('TITLE'),
      },
      fieldGroup: [
        {
          key: 'title',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Title'),
            placeholder: this.gettext('Type title of passport'),
            hint: this.gettext('The name for internal use, can be whatever you want'),
            required: true,
          },
        },
        {
          key: 'multipassport',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Multipassport'),
            placeholder: this.gettext('Choose Multipassport'),
            hint: this.gettext('Group passports into a chain'),
            resource: 'passports.Multipassport',
            addFunc: () => {
              this.$window.open('/admin/passports/multipassport/add/');
            },
            addIcon: 'fa fa-exchange',
            addPerms: ['add_multipassport'],
          },
        },
        {
          key: 'voyage',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Voyage'),
            placeholder: this.gettext('Choose vessel'),
            hint: this.gettext('One of the vessel voyages, which will execute this deal'),
            resource: 'logistics.Voyage',
            addFunc: () => {
              return this.LogisticsService.voyageModal();
            },
            addPerms: ['add_voyage'],
            addIcon: 'fa fa-ship',
          },
        },
        {
          template: html`
            <div style="margin: 8px 0" ng-if="to.voyage">
              <div>
                <span class="label label_default" style="margin: 0 30% 10px">
                  <translate>Vessel size: </translate> {[{ to.vesselSize || 'N/A' }]}
                </span>
              </div>
            </div>
          `,
          hideExpression: () => !passport.voyage,
          expressionProperties: {
            'templateOptions.voyage': () => passport.voyage,
            'templateOptions.vesselSize': () => {
              return this.LogisticsService.VesselSize.query({
                voyage: passport.voyage,
              }).$promise.then((data: any) => {
                return data.results[0]?.title;
              });
            },
          },
        },
        {
          key: 'conclusion_date',
          type: 'gt-date-select',
          defaultValue: new Date(),
          templateOptions: {
            label: this.gettext('Conclusion (date)'),
            placeholder: this.gettext('date'),
            required: true,
          },
        },
        {
          key: 'business_date',
          type: 'gt-date-select',
          defaultValue: new Date(),
          templateOptions: {
            label: this.gettext('Business (date)'),
            placeholder: this.gettext('date'),
            minView: 'month',
            startView: 'month',
            required: true,
          },
        },
        {
          key: 'cargo',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Product'),
            placeholder: this.gettext('Choose product'),
            resource: 'crops.Crop',
            required: true,
            help: this.gettext('To filter contracts for allocation'),
            addFunc: () => {
              this.$window.open('/admin/crops/crop/add/');
            },
            addIcon: 'fa-wheat-awn',
            addPerms: ['add_crop'],
          },
          validation: {
            show: true,
          },
        },
        {
          key: 'total_volume_plan',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Volume'),
            placeholder: this.gettext('Type volume'),
            hint: this.gettext(
              'Total volume of the deal. Choose plan value, at first, but then substitute on delivered volume',
            ),
            help: this.gettext('Estimated volume of the deal for contract allocation'),
            required: true,
            type: 'number',
            addon: this.gettext('t'),
          },
          validation: {
            show: true,
          },
        },
        {
          key: 'currency',
          type: 'gt-ui-select',
          // defaultValue: vm.defaultCurrency,
          templateOptions: {
            label: this.gettext('Currency'),
            placeholder: this.gettext('USD, EUR'),
            resource: 'finances.Currency',
            required: true,
            help: this.gettext('Pick currency in which you want to convert USD'),
            addFunc: () => {
              this.$window.open('/admin/finances/currency/add/');
            },
            addIcon: 'fa fa-dollar',
            addPerms: ['add_currency'],
          },
        },
        {
          key: 'currency_exchange',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Currency rate'),
            resource: 'finances.CurrencyExchange',
            hint: this.gettext(
              'If you want to convert all financial data in passport by one rate to USD',
            ),
            addFunc: () => {
              this.$window.open('/admin/finances/currencyexchange/add/');
            },
            queryParams: () => {
              return { local_currency: passport.currency };
            },
            addIcon: 'fa fa-money',
            addPerms: ['add_currencyexchange'],
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.currency',
            'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
              isExchangeFieldRequired &&
              scope.model.currency !== this.FinancesService.getDefaultCurrency().id,
          },
        },
        {
          template: html`
            <custom-values-container
              filter-level="'passport-custom-values-container'"
              init-query-params="{
                object_id: model.id,
                purpose: model.content_type,
                purpose_model: 'passport',
              }"
              mode="model.id ? 'edit' : 'create'"
              object-id="model.id"
            ></custom-values-container>
          `,
        },
      ],
    });
    // col1 - FINANCES
    fieldsConfig.fieldsDef[1].fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('FINANCES'),
        defaultHide: true,
      },
      fieldGroup: [
        {
          key: 'custom_margin',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Custom margin'),
            placeholder: this.gettext('Custom margin'),
            type: 'number',
            hint: this.gettext('This value will be added to your margin'),
            required: true,
          },
        },
        {
          key: 'discount',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('discount'),
            placeholder: this.gettext('0-100'),
            hint: this.gettext('The discount of VAT compensation by the deal'),
            addon: this.gettext('%'),
            type: 'number',
          },
        },
        {
          key: 'sale_price_multiplicator',
          type: 'gt-input',
          defaultValue: 100,
          templateOptions: {
            label: this.gettext('sale price multiplicator'),
            placeholder: this.gettext('1-100'),
            hint: this.gettext('The number on which sale price will be multiplied'),
            addon: this.gettext('%'),
            type: 'number',
            required: true,
          },
        },
        {
          key: 'premium_value',
          type: 'gt-input',
          defaultValue: 10,
          templateOptions: {
            label: this.gettext('Premium value'),
            placeholder: this.gettext('Type value'),
            type: 'number',
          },
          validation: {
            show: true,
          },
        },
        {
          key: 'vat_return_rate',
          type: 'gt-input',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.field_passport_vat_return_rate,
          templateOptions: {
            label: this.gettext('VAT return rate'),
            placeholder: this.gettext('Type rate'),
            hint: this.gettext('The rate of return for VAT for cargo'),
            type: 'number',
          },
          validation: {
            show: true,
          },
        },
        {
          key: 'vat_costs_return_rate',
          type: 'gt-input',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.field_passport_vat_return_rate,
          templateOptions: {
            label: this.gettext('VAT costs return rate'),
            placeholder: this.gettext('Type rate'),
            hint: this.gettext('The rate of return for VAT for costs'),
            type: 'number',
          },
          validation: {
            show: true,
          },
        },
        {
          key: 'margin_selector',
          type: 'gt-select',
          defaultValue: 'contract',
          templateOptions: {
            label: this.gettext('margin selector'),
            placeholder: this.gettext('Choose margin'),
            help: this.gettext(
              'Depends on a stage of passport execution. Select which margin we will display in PNL',
            ),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: 'ticket', value: 'tiket' },
              { name: 'contract', value: 'contract' },
              { name: 'analytical', value: 'contract_analytical' },
              { name: 'execution', value: 'execution' },
              { name: 'invoice', value: 'invoice' },
              { name: 'payment', value: 'payment' },
            ],
          },
        },
        {
          key: 'derivative_account',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Derivative account'),
            placeholder: this.gettext('Derivative account'),
            resource: 'stockexchanges.derivativeaccount',
            queryParams: { is_active: 1 },
            help: this.gettext(
              'Allocate this passport to certain account. This account will be a criteria to match sales and purchases in Derivatives - Account Position report',
            ),
            addFunc: () => {
              this.$window.open('/admin/stockexchanges/derivativeaccount/add/');
            },
            addPerms: ['add_derivativeaccount'],
            addIcon: 'fa fa-suitcase',
          },
        },
        {
          key: 'unload_costs_t',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Transport costs per t'),
            placeholder: this.gettext('Type number'),
            hint: this.gettext('Transport costs per t'),
            type: 'number',
          },
        },
        {
          key: 'unload_costs_unit',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Transport costs per unit'),
            placeholder: this.gettext('Type number'),
            hint: this.gettext('Transport costs per unit'),
            type: 'number',
          },
        },
      ],
    });
    // col2 - STATUS
    fieldsConfig.fieldsDef[2].fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('STATUS'),
        defaultHide: true,
      },
      fieldGroup: [
        {
          key: 'status',
          type: 'gt-select',
          defaultValue: 'Request',
          templateOptions: {
            label: this.gettext('Status'),
            placeholder: this.gettext('Choose status'),
            hint: this.gettext('Will be change automatically, based on passport execution'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Request'), value: 'Request' },
              { name: this.gettext('Approved'), value: 'Approved' },
              {
                name: this.gettext('Processing'),
                value: 'Processing',
              },
              { name: this.gettext('Cargo partial executed'), value: 'cargo_partial_executed' },
              { name: this.gettext('Cargo executed'), value: 'cargo_executed' },
              { name: this.gettext('Executed'), value: 'Executed' },
              { name: this.gettext('Cancelled'), value: 'Cancelled' },
            ],
          },
        },
        {
          key: 'custom_status',
          type: 'gt-ui-select',
          defaultValue: this.GtUtils.getCustomStatusId('passport'),
          templateOptions: {
            label: this.gettext('Custom status'),
            placeholder: this.gettext('Choose custom status'),
            hint: this.gettext('You can create and apply your own statuses for passport'),
            resource: 'core.CustomStatus',
            addFunc: () => {
              this.$window.open('/admin/core/customstatus/add/');
            },
            addIcon: 'fa fa-tasks',
            addPerms: true,
            queryParams: {
              content_type__model: 'passport',
            },
          },
        },
        {
          key: 'logistic_type',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('logistic type'),
            placeholder: this.gettext('Choose type of logistic'),
            resource: 'logistics.logisticType',
            addon: this.gettext('logistic type'),
            addFunc: () => this.$window.open('/admin/logistics/logistictype/add/', '_blank'),
            addIcon: 'fa fa-truck-plane',
            addPerms: ['add_logistictype'],
          },
        },
        {
          key: 'origin_of_crop',
          type: 'gt-ui-select',
          defaultValue: this.GtUtils.getDefaultOriginId(),
          templateOptions: {
            label: this.gettext('Origin'),
            placeholder: this.gettext('Type origin'),
            resource: 'location.Country',
            addon: this.gettext('country'),
            addFunc: () => this.$window.open('/admin/location/country/add/', '_blank'),
            addIcon: 'fa fa-location-arrow',
            addPerms: ['add_country'],
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.voyage',
          },
        },
        {
          key: 'destination_of_crop',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Destination'),
            placeholder: this.gettext('Type destination'),
            resource: 'location.Country',
            addon: this.gettext('country'),
            addFunc: () => this.$window.open('/admin/location/country/add/', '_blank'),
            addIcon: 'fa fa-location-arrow',
            addPerms: ['add_country'],
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.voyage',
          },
        },
        {
          key: 'current_progress',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Additional info'),
            placeholder: this.gettext('Type here any specific information about this deal'),
          },
        },
        {
          key: 'sap_order',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Sap Order'),
            resource: 'contracts.SapOrder',
          },
        },
      ],
    });

    // col3 - CONFIG
    fieldsConfig.fieldsDef[3].fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('CONFIG'),
        defaultHide: true,
      },
      fieldGroup: [
        {
          key: 'responsible',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.id,
          templateOptions: {
            label: this.gettext('Responsible'),
            placeholder: this.gettext('User to be responsible'),
            resource: 'auth.User',
          },
        },
        {
          template: html`
            <div style="margin: 8px 0">
              <work-status-display
                status="to.responsibleWorkStatus"
              </work-status-display>
            </div>
          `,
          hideExpression: () =>
            !passport.responsible || !this.$rootScope.user.settings.USE_WORK_STATUS,
          expressionProperties: {
            'templateOptions.responsibleWorkStatus': (
              viewValue: any,
              modelValue: any,
              scope: any,
            ) => {
              return scope.model.responsibleWorkStatus;
            },
          },
        },
        {
          key: 'approval_config',
          type: 'gt-ui-select',
          defaultValue: this.AccountsService.getDefaultApprovalConfigId('passports.Passport'),
          templateOptions: {
            label: this.gettext('Approval Config'),
            resource: 'accounts.ApprovalConfig',
            hint: this.gettext(
              'Choose configuration to launch the approval process by this object. You need first to set up configuration an Admin section, and here just pick it up',
            ),
            addFunc: () => {
              this.$window.open('/admin/accounts/approvalconfig/');
            },
            addIcon: 'fa fa-cogs',
            addPerms: true,
            queryParams: () => {
              return {
                object_type: 'passports.Passport',
                is_active: 1,
                bu_list: passport.business_unit,
              };
            },
          },
        },
        {
          key: 'business_unit',
          type: 'gt-ui-select',
          defaultValue: this.CoreService.getDefaultBuId(passport),
          templateOptions: {
            label: this.gettext('Business unit'),
            placeholder: this.gettext('Business Unit'),
            queryParams: this.GtUtils.getBUQueryParams(),
            resource: 'core.BusinessUnit',
            hint: this.gettext(
              'If you pick something here, information about this object will be displayed only for people from those business units',
            ),
            addFunc: () => {
              this.$window.open('/admin/core/businessunit/add/');
            },
            addIcon: 'fa fa-home',
            addPerms: true,
          },
        },
        {
          key: 'season',
          type: 'gt-ui-select',
          defaultValue: this.CoreService.getDefaultSeasonId(),
          templateOptions: {
            label: this.gettext('Season'),
            placeholder: this.gettext('Season'),
            resource: 'core.Season',
            hint: this.gettext('For purposes of filtering information by specific season'),
            addFunc: () => {
              this.$window.open('/admin/core/season/add/');
            },
            addIcon: 'fa fa-calendar',
            addPerms: true,
            queryParams: () => {
              return { is_current: 1 };
            },
          },
        },
        {
          key: 'is_volume_diff_aspiration',
          defaultValue: this.$rootScope.user.settings.ENABLE_ASPIRATION_AUTOMATICALLY,
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Aspiration'),
            hint: this.gettext('Check this if you want to use volume position as aspiration'),
          },
        },
        {
          key: 'use_commodity_parent_match',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Use commodity parent match'),
            hint: this.gettext('Check this if you want to use match with commodity parent'),
          },
        },
      ],
    });
    return {
      formName: formName,
      fieldsDef: fieldsConfig.fieldsDef,
    };
  }
}

PassportFormFieldsService.$inject = [
  '$rootScope',
  'gettext',
  '$window',
  'GtUtils',
  'AccountsService',
  'CoreService',
  'LogisticsService',
  'FormFieldParamsService',
  'FinancesService',
];
