import { formatDate, parseDate as parseDateUtil } from '~/shared/lib/date';
import type { RecordErrors } from '~/shared/lib/entities';
import { v } from '~/shared/lib/forms';
import type { ListOption } from '~/shared/lib/types';
import { isNumberArray, isObjectsEqual, requireSchema } from '~/shared/lib/utils';
import type { DataTypeValue } from '~/shared/ui/data-types';
import { findEntity, getEntity, isEntityOption, isEntityOptionArray } from '~/shared/ui/data-types';

import type { DataErrors, DataParams, DataRecord, FieldDef, InferValue } from '../lib/types';

export const getRecordValue = <R extends DataRecord, P extends DataParams>(
  record: R,
  field: FieldDef<R, P>,
): DataTypeValue<FieldDef<R, P>['dataType']> => {
  if (field.getValue) {
    return field.getValue?.(record, field);
  }
  const recValue = record[field.key];

  if (recValue == undefined) {
    return undefined;
  }

  switch (field.dataType) {
    case 'entity': {
      if (typeof recValue === 'number') {
        return [{ id: recValue, title: '' }];
      }

      if (isNumberArray(recValue)) {
        return recValue.map((id) => ({ id, title: '' }));
      }

      if (isEntityOption(recValue)) {
        return [recValue];
      }

      if (isEntityOptionArray(recValue)) {
        return recValue;
      }

      throw new Error(
        `Entity ${field.key.toString()} value is uncommon (${JSON.stringify(recValue)}), provide getValue`,
      );
    }
    case 'string':
    case 'number':
    case 'template':
    case 'checkbox':
    case 'date':
    case 'daterange':
    case 'person':
    case 'label':
    case 'select':
    case 'multiselect':
    case 'phone':
    case 'text':
    case 'url':
    case 'email':
    default:
      return record[field.key];
  }
};

export const setRecordValue = async <R extends DataRecord, P extends DataParams>({
  record,
  field,
  value,
}: {
  record: R;
  field: FieldDef<R, P>;
  value?: InferValue<R>;
}): Promise<R | undefined> => {
  if (
    !field.setValue &&
    (field.key.toString().startsWith('_') || field.key.toString().includes('.'))
  ) {
    throw new Error(
      `Field ${field.key.toString()} is read-only. Set editing=false or define setValue()`,
    );
  }

  if (record[field.key] == null && value == '') {
    return Promise.resolve(undefined);
  }

  const editedRecord = await Promise.resolve(
    field.setValue?.({ record, value } as never) ?? setDefaultValue(record, field, value),
  );
  if ((value ?? record[field.key]) != undefined && !isObjectsEqual(record, editedRecord)) {
    return editedRecord;
  }
  return Promise.resolve(undefined);
};

const setDefaultValue = <R extends DataRecord, P extends DataParams>(
  record: R,
  field: FieldDef<R, P>,
  value: InferValue<R>,
): R => {
  if (value == undefined) {
    return { ...record, [field.key]: undefined };
  }

  switch (field.dataType) {
    case 'date': {
      const date = !field.typeExtra?.stringify
        ? formatDate(
            value as DataTypeValue<'date'>,
            field.typeExtra?.stringify ?? "yyyy-MM-dd'T'HH:mm:ss",
          )
        : (value as DataTypeValue<'date'>).toISOString();
      return { ...record, [field.key]: date };
    }
    case 'daterange':
      throw new Error(`Provide own setter for ${field.key.toString()}`);

    case 'select':
      return { ...record, [field.key]: (value as DataTypeValue<'select'>).value };

    case 'multiselect':
      return {
        ...record,
        [field.key]: (value as DataTypeValue<'multiselect'>).map((i) => i.value),
      };

    case 'entity':
      return {
        ...record,
        [field.key]: (value as DataTypeValue<'entity'>).map((i) => i.id),
      };

    default:
      return { ...record, [field.key]: value };
  }
};

export const serializeFieldValue = async <R extends DataRecord, P extends DataParams>(
  record: R,
  field: FieldDef<R, P>,
) => {
  const value = getRecordValue(record, field);

  if (value == undefined || value == null) {
    return '';
  }

  switch (field.dataType) {
    case 'checkbox':
      return value ? 'TRUE' : 'FALSE';
    case 'date':
      return formatDate(value as DataTypeValue<'date'>, field.typeExtra?.stringify ?? 'dd.MM.yyyy');
    case 'daterange': {
      const from = (value as DataTypeValue<'daterange'>).from;
      const to = (value as DataTypeValue<'daterange'>).to;
      return `${from ? formatDate(from, field.typeExtra?.stringify ?? 'dd.MM.yyyy') : ''} - ${to ? formatDate(to, field.typeExtra?.print ?? 'dd.MM.yyyy') : ''}`;
    }
    case 'select':
      return (value as DataTypeValue<'select'>).label;
    case 'multiselect':
      return (value as DataTypeValue<'multiselect'>).map((i) => i.label).join(', ');
    case 'entity': {
      const entities = await Promise.all(
        (value as DataTypeValue<'entity'>).map(async (i) =>
          i.title ? i.title : (await getEntity(field.typeExtra.entity, i.id)).title,
        ),
      );
      return entities.join(', ');
    }
    case 'person': {
      const first = (value as DataTypeValue<'person'>).firstName ?? '';
      const last = (value as DataTypeValue<'person'>).lastName ?? '';
      return `${first} ${last}`.trim();
    }
    default:
      return String(value as string).trim();
  }
};

export const parseFieldValue = async <R extends DataRecord, P extends DataParams>({
  field,
  value,
}: {
  field: FieldDef<R, P>;
  value: string;
}): Promise<{ value: InferValue<R>; error?: string }> => {
  await Promise.resolve();

  if (value === '') {
    return { value: undefined };
  }

  switch (field.dataType) {
    case 'number':
      return parseNumber(value);
    case 'checkbox':
      return parseCheckbox(value);
    case 'date':
      return parseDate(value);
    case 'daterange':
      return parseDateRange(value);
    case 'select':
      return parseSelect(value, field);
    case 'multiselect':
      return parseMultiSelect(value, field);
    case 'entity':
      return parseEntity(value, field);
    default:
      return { value: String(value).trim() };
  }
};

const parseNumber = (value: string) => {
  const parsed = parseFloat(value);
  return isNaN(parsed)
    ? { value: undefined, error: `Invalid number: ${value}` }
    : { value: parsed };
};

const parseCheckbox = (value: string) => {
  return {
    value: !!value,
    error: ['true', 'false', '1', '0', 'yes', 'no', 'on', 'off'].includes(value.toLowerCase())
      ? undefined
      : `Invalid checkbox: ${value}`,
  };
};

const parseDate = (value: string) => {
  const date = parseDateUtil(value);
  return date ? { value: date } : { value: undefined, error: `Invalid date: ${value}` };
};

const parseDateRange = (value: string) => {
  const [start, end] = value.split('-').map((i) => parseDateUtil(i));
  return [start, end].some((d) => !d)
    ? { value: undefined, error: `Invalid date range: ${value}` }
    : { value: { start, end } };
};

const parseSelect = <R extends DataRecord, P extends DataParams>(
  value: string,
  field: FieldDef<R, P> & { dataType: 'select' },
) => {
  const option = field.typeExtra.options.find(
    (option) =>
      option.value.toLowerCase() === value.toLowerCase().trim() ||
      option.label.toLowerCase() === value.toLowerCase().trim(),
  );
  return option ? { value: option } : { value: undefined, error: `Invalid option: ${value}` };
};

const parseMultiSelect = <R extends DataRecord, P extends DataParams>(
  value: string,
  field: FieldDef<R, P> & { dataType: 'multiselect' },
) => {
  const inputValues = value.split(',');
  const optionsValues = field.typeExtra.options.map((option) => option.value);
  const values = inputValues.map((v) =>
    field.typeExtra.options.find(
      (option) =>
        option.value.toLowerCase() === v.toLowerCase().trim() ||
        option.label.toLowerCase() === v.toLowerCase().trim(),
    ),
  );
  return values.some((v) => !v)
    ? {
        value: values.filter(Boolean),
        error: `Invalid options: ${inputValues.filter((v) => !optionsValues.includes(v)).join(', ')}`,
      }
    : { value: values };
};

const parseEntity = async <R extends DataRecord, P extends DataParams>(
  value: string,
  field: FieldDef<R, P> & { dataType: 'entity' },
) => {
  const names = value.split(',').map((v) => v.trim());
  const values = await Promise.all(
    names.map(async (v) => {
      return await findEntity(field.typeExtra.entity, v);
    }),
  );
  const entities = values.map((v) => v.entity).filter(Boolean);
  return values.some((v) => !v.exact)
    ? {
        value: entities,
        error: `Couldn't find entities: ${names
          .filter((name) => !values.find((v) => v.entity?.title === name))
          .join(', ')}`,
      }
    : { value: entities };
};

export const validateRecord = async <R extends DataRecord, P extends DataParams>({
  fields,
  record,
}: {
  fields: FieldDef<R, P>[];
  record: R;
}): Promise<DataErrors> => {
  const errors: Record<string, string[]> = {};
  await Promise.all(
    fields
      .filter(
        ({ validate, schema, dataType, required }) =>
          !!validate || !!schema || dataType.includes('select') || required,
      )
      .map(async (field) => {
        const key = String(field.key);
        const value = getRecordValue(record, field) as never;
        const valError = await field.validate?.({ record, value });
        const schema = field.required ? getRequiredFieldSchema(field) : field.schema;
        const schemaError = schema ? v.safeParse(schema, value) : undefined;

        if (schemaError?.issues?.length) {
          errors[key] = [
            ...(errors[key] ?? []),
            ...schemaError.issues.map((issue) => issue.message),
          ];
        }
        if (valError) {
          errors[key] = [...(errors[key] ?? []), valError];
        }
        if (value && (field.dataType == 'select' || field.dataType == 'multiselect')) {
          const options = field.typeExtra.options.map((option) => option.value);
          const selectedOptions = ((Array.isArray(value) ? value : [value]) as ListOption[]).map(
            (v) => v.value,
          );
          if (!selectedOptions.every((v) => options.includes(v))) {
            errors[key] = [
              ...(errors[key] ?? []),
              `Invalid options: ${selectedOptions.filter((v) => !options.includes(v)).join(', ')}`,
            ];
          }
        }
      }),
  );

  const fieldErrors = Object.entries(errors)
    .filter(([, messages]) => messages.length)
    .reduce<RecordErrors>(
      (acc, [key, messages]) => ({ ...acc, [key]: { type: 'danger', text: messages.join(', ') } }),
      {},
    );

  return { [record.id]: fieldErrors };
};

const getRequiredFieldSchema = <R extends DataRecord, P extends DataParams>(
  field: FieldDef<R, P>,
) => {
  const required = requireSchema(`${field.title ?? String(field.key)} is required`);
  return field.schema ? v.pipe(required, field.schema) : required;
};
