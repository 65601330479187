import { financesPaymentInfosPredictionsRetrieve } from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { PaymentInfo, PaymentInfoListParams } from '../lib/types';

export class PaymentInfoRepository extends EntityRepository<PaymentInfo, PaymentInfoListParams> {
  readonly entityName = 'finances.PaymentInfo';

  getFn = (_id: number): Promise<PaymentInfo> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: PaymentInfoListParams): Promise<Paginated<PaymentInfo>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await financesPaymentInfosPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };
  createFn = (_entity: PaymentInfo): Promise<PaymentInfo> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<PaymentInfo>): Promise<PaymentInfo> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
