import type ng from 'angular';

import template from './bill-of-lading-sale-contract-grouped-list.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { BillOfLadingService } from '^/app/execution/components/transport/bill-of-lading/bill-of-lading.service';

export const billOfLadingSaleContractGroupedList = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
    onUpdate: '&?',
  },
  template,
  controller: [
    '$scope',
    '$q',
    '$state',
    'GtUtils',
    'gtFilterService',
    'ContractsService',
    'BillOfLadingService',
    'gettext',
    'DocumentsService',
    class {
      $q: ng.IQService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      BillOfLadingService: BillOfLadingService;
      ContractsService: ContractsService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      billsOfLadingGroups: any;
      contractToUpdateIdList: any;
      filterLevel = 'bill-of-lading-sale-contract-grouped-list';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      totalVolumeSum: any;
      constructor(
        $scope: ng.IScope,
        $q: ng.IQService,
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        ContractsService: ContractsService,
        BillOfLadingService: BillOfLadingService,
        gettext: ng.gettext.gettextFunction,
        DocumentsService: any,
      ) {
        this.$scope = $scope;
        this.$q = $q;
        this.$state = $state;
        this.gettext = gettext;
        this.ContractsService = ContractsService;
        this.DocumentsService = DocumentsService;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.BillOfLadingService = BillOfLadingService;

        this.billsOfLadingGroups = undefined;
        this.totalVolumeSum = undefined;
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };

        this.billsOfLadingGroups = [];
        this.totalVolumeSum = 0;

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData(contractType?: string) {
        this.GtUtils.overlay('show');
        return this.BillOfLadingService.getGroupedBySaleContract({
          contract_type: contractType,
          ...this.queryParams,
        }).then((data: any) => {
          const contractIdList = (data.results.length && data.results[0].contract_id_list) || [];
          const oppositeContractIdList =
            (data.results.length && data.results[0].opposite_contract_id_list) || [];
          const bothContractIdListUnique: any = [
            ...new Set(contractIdList.concat(oppositeContractIdList)),
          ];
          this.ContractsService.Contract.contractbaseListBase(
            {
              id_list: bothContractIdListUnique,
            },
            (contractData: any) => {
              this.contractToUpdateIdList =
                bothContractIdListUnique.length &&
                contractData.results.map((contract: any) => contract.id);
              this.billsOfLadingGroups =
                (data.results.length && data.results[0].billofladings_info) || [];
              this.totalVolumeSum = (data.results.length && data.results[0].total_volume_sum) || 0;

              this.GtUtils.overlay('hide');
            },
          );
        });
      }

      openContractListModal() {
        return this.ContractsService.contractListModal(
          {
            id_list: this.contractToUpdateIdList,
            serializer: 'table_execution',
          },
          {
            extraFields: ['transport_open_balance_volume'],
          },
        );
      }
    },
  ],
};
