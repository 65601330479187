import template from './costs-statistics-container.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const CostsStatisticsContainer = {
  bindings: {
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    'CostsStatisticsReportService',
    'GtUtils',
    'gtFilterService',
    'CoreService',
    class {
      $scope: ng.IScope;
      CoreService: CoreService;
      CostsStatisticsReportService: any;
      GtUtils: GtUtilsService;
      data: any;
      defaultQueryParams: QueryParams = {};
      filterLevel = 'costs-statistics-container';
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      savedFilterChoices: any;
      constructor(
        $scope: ng.IScope,
        CostsStatisticsReportService: any,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        CoreService: CoreService,
      ) {
        this.$scope = $scope;
        this.CostsStatisticsReportService = CostsStatisticsReportService;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.CoreService = CoreService;
        this.data = {};
        this.defaultQueryParams = {
          page_size: 25,
          is_by_default: 0,
        };
      }

      $onInit() {
        this.queryParams = {
          ...this.gtFilterService.getQueryParams(this.filterLevel),
          ...this.defaultQueryParams,
        };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.CostsStatisticsReportService.getData(this.queryParams).then((data: any) => {
          this.data = data;
          this.GtUtils.overlay('hide');
        });
      }

      resetFilter() {
        this.queryParams = this.defaultQueryParams;
        this.applyFilter();
      }

      applyFilter() {
        return this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }
    },
  ],
};
