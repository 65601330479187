import React, { useMemo } from 'react';

import { cn } from '~/shared/lib/utils';
import { Avatar } from '~/shared/ui/kit/avatar';

import type { DataTypeProps } from '../../lib';

export const PersonRead: React.FC<DataTypeProps<'person'>> = ({ value, disabled }) => {
  const fullName = useMemo(() => {
    return `${value?.firstName ?? ''}${value?.lastName ? ' ' + value?.lastName : ''}`;
  }, [value?.firstName, value?.lastName]);

  return (
    <div
      className={cn(
        'text-text-main-primary flex items-center gap-1',
        disabled && 'cursor-not-allowed',
      )}
    >
      <Avatar
        src={value?.avatarSrc ?? ''}
        fallback={value?.firstName?.charAt(0)}
        alt={value?.firstName?.charAt(0) ?? ''}
        size="lg"
      />
      <div
        className="text-text-main-primary m-0 truncate text-xs whitespace-nowrap"
        title={fullName}
      >
        {fullName}
      </div>
    </div>
  );
};
