import React from 'react';

import { cn } from '~/shared/lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandList,
} from '~/shared/ui/kit/command';

export const SearchCommandList: React.FC<{
  options: React.ReactNode;
  placeholder?: string;
  groupClassName?: string;
}> = ({ options, groupClassName, placeholder = 'Search' }) => {
  return (
    <Command>
      <CommandInput placeholder={placeholder} />
      <CommandList>
        <CommandEmpty className="text-text-main-secondary px-3 py-2 text-xs">
          No results found.
        </CommandEmpty>
        <CommandGroup className={cn('p-1', groupClassName)}>{options}</CommandGroup>
      </CommandList>
    </Command>
  );
};
