import type ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import type { CoreService } from '^/app/core/core.service';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class LoyaltyProgramsService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  ContractOptionGroupMonthesResource: any;
  CoreService: CoreService;
  LoyaltyProgramsResource: any;
  constructor(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    CoreService: CoreService,
  ) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.CoreService = CoreService;
    this.LoyaltyProgramsResource = $resource(
      '/api/contracts/contract-options/:id/',
      {
        id: '@id',
      },
      {
        get: {
          method: 'GET',
        },
        save: {
          method: 'POST',
        },
        query: {
          method: 'GET',
          isArray: false,
        },
        update: {
          method: 'PATCH',
        },
        list: {
          method: 'GET',
          url: '/api/contracts/contract-options-report/list/',
        },
        executionReportList: {
          method: 'GET',
          url: '/api/contracts/contract-options-execution-report/list/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/contract-options/predictions/',
        },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/contract-options/:id/delete_confirmation/',
        },
      },
    );
    this.ContractOptionGroupMonthesResource = $resource(
      '/api/contracts/contract-option-group-monthes/:id/',
      {
        id: '@id',
      },
      {
        get: {
          method: 'GET',
        },
      },
    );
  }

  loyaltyProgramModal(item: any, data: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<loyalty-program-modal
        item="item"
        data="data"
        modal-instance="$uibModalInstance"
      >
      </loyalty-program-modal>`,
      controller: [
        '$scope',
        'item',
        'data',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          item: any,
          data: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).item = item;
          ($scope as any).data = data;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      size: 'sm',
      appendTo: getModalRoot(),
      resolve: {
        item: () => item,
        data: () => data,
      },
    }).result;
  }

  save(item: any) {
    if (item.id) {
      return this.LoyaltyProgramsResource.update(item).$promise;
    }
    return this.LoyaltyProgramsResource.save(item).$promise;
  }

  getTableData(params: object) {
    return this.LoyaltyProgramsResource.list(params, (data: any) => {
      data.results.forEach((program: any) => {
        program.dateRange = this.getDateRange(program.start_date, program.end_date);
        program.months_map_totals = {};
        program.month_totals?.forEach((month: any) => {
          month.key = formatDate(month.month, 'yyyy-MM-dd');
          program.months_map_totals[month.key] = month;
        });
        program.groups_set?.forEach((group: any) => {
          group.months_map_set = {};
          group.months_set?.forEach((month: any) => {
            month.key = formatDate(month.month, 'yyyy-MM-dd');
            group.months_map_set[month.key] = month;
          });
        });
      });
    }).$promise;
  }

  getTableDataExecutionReport(params: object) {
    return this.LoyaltyProgramsResource.executionReportList(params, (data: any) => {
      data.results.forEach((program: any) => {
        program.dateRange = this.getDateRange(program.start_date, program.end_date);
        program.months_map_set = {};
        program?.months_set?.forEach((month: any) => {
          month.key = formatDate(month.month, 'yyyy-MM-dd');
          program.months_map_set[month.key] = month;
        });
      });
    }).$promise;
  }

  getModalData(params: object) {
    return this.LoyaltyProgramsResource.get(params).$promise;
  }

  delete(item: any) {
    return this.CoreService.confirmDeletionModal(this.LoyaltyProgramsResource, item.id);
  }

  getContractOptionGroupMonthes(params: object) {
    return this.ContractOptionGroupMonthesResource.get(params).$promise;
  }

  getDateRange(startArg: Date, endArg: Date) {
    startArg.setHours(0);
    endArg.setHours(0);
    let start = new Date(startArg.getFullYear(), startArg.getMonth(), 1);
    const end = new Date(endArg.getTime());
    const dateRange: { obj: Date; key: string; title: string }[] = [];
    while (start <= end) {
      dateRange.push({
        obj: start,
        key: formatDate(start, 'yyyy-MM-dd'),
        title: formatDate(start, 'MMM yyyy'),
      });
      const newDate = start.setMonth(start.getMonth() + 1);
      start = new Date(newDate);
    }
    return dateRange;
  }

  getMainProgram() {
    return this.LoyaltyProgramsResource.get().$promise.then((data: any) => {
      const mainPrograms = data.results.filter((program: any) => program.is_main);
      return mainPrograms.length === 1 ? mainPrograms[0].id : null;
    });
  }
}

LoyaltyProgramsService.$inject = ['$resource', '$uibModal', 'CoreService'];
