import type { CurrentUser } from '~/core/auth/lib';
import type { FieldDef } from '~/core/datasets';
import type { ListOption } from '~/shared/lib/types';
import { requireSchema } from '~/shared/lib/utils';

import type { RequestRecord, RequestStage } from './types';

const contractTypeOptions: Record<NonNullable<RequestRecord['contract_type']>, ListOption> = {
  sale: {
    value: 'sale',
    label: 'Sale',
    tagProps: { variant: 'orange' },
  },
  purchase: {
    value: 'purchase',
    label: 'Purchase',
    tagProps: { variant: 'green' },
  },
};

const requestStatusOptions: Record<string, ListOption> = {
  new: {
    value: 'new',
    label: 'New',
    tagProps: { variant: 'yellow' },
  },
  approved: {
    value: 'approved',
    label: 'Approved',
    tagProps: { variant: 'green' },
  },
  processed: {
    value: 'processed',
    label: 'Processed',
    tagProps: { variant: 'blue' },
  },
  rejected: {
    value: 'rejected',
    label: 'Rejected',
    tagProps: { variant: 'red' },
  },
  cancelled: {
    value: 'cancelled',
    label: 'Cancelled',
    tagProps: { variant: 'orange' },
  },
  executed: {
    value: 'executed',
    label: 'Executed',
    tagProps: { variant: 'turquoise' },
  },
  sent: {
    value: 'sent',
    label: 'sent',
    tagProps: { variant: 'purple' },
  },
  lost: {
    value: 'lost',
    label: 'lost',
    tagProps: { variant: 'pink' },
  },
  draft: {
    value: 'draft',
    label: 'draft',
    tagProps: { variant: 'gray' },
  },
  expired: {
    value: 'expired',
    label: 'expired',
    tagProps: { variant: 'sky' },
  },
};

const dealTypeOptions: Record<RequestRecord['deal_type'], ListOption> = {
  spot: {
    value: 'spot',
    label: 'Spot',
    tagProps: { variant: 'green' },
  },
  forward: {
    value: 'forward',
    label: 'Forward',
    tagProps: { variant: 'blue' },
  },
  brokerage: {
    value: 'brokerage',
    label: 'Brokerage',
    tagProps: { variant: 'gray' },
  },
  services: {
    value: 'services',
    label: 'Services',
    tagProps: { variant: 'orange' },
  },
  intermediate: {
    value: 'intermediate',
    label: 'Intermediate',
    tagProps: { variant: 'pink' },
  },
  export: {
    value: 'export',
    label: 'Export',
    tagProps: { variant: 'purple' },
  },
};

const getCommonColumns = (stage: RequestStage): FieldDef<RequestRecord>[] => [
  {
    key: 'contract_number',
    title: 'Contract number',
    dataType: 'text',
    schema: requireSchema('Number is required'),
  },
  { key: 'total_value', title: 'Total value', dataType: 'number' },
  {
    key: 'contract_type',
    title: 'Contract type',
    dataType: 'select',
    typeExtra: { options: Object.values(contractTypeOptions) },
    getValue: (rec) => (rec.contract_type ? contractTypeOptions[rec.contract_type] : undefined),
    setValue: ({ record, value }) => ({
      ...record,
      contract_type: value?.value as RequestRecord['contract_type'],
    }),
    editing: stage === 'ticket',
    schema: requireSchema('Select a valid contract type'),
  },
  {
    key: 'custom_status',
    title: 'Custom status',
    dataType: 'entity',
    typeExtra: {
      entity: stage == 'indicator' ? 'core.IndicatorCustomStatus' : 'core.RequestCustomStatus',
    },
    getValue: (rec) =>
      rec.custom_status ? [{ id: rec.custom_status, title: rec.custom_status_title }] : undefined,
    setValue: ({ record, value }) => {
      return value?.length
        ? { ...record, custom_status: value[0].id, custom_status_title: value[0].title }
        : record;
    },
  },
  {
    key: 'request_status',
    title: 'Request status',
    dataType: 'select',
    typeExtra: { options: Object.values(requestStatusOptions) },
    getValue: (rec) => (rec.request_status ? requestStatusOptions[rec.request_status] : undefined),
    setValue: ({ record, value }) => ({
      ...record,
      request_status: String(value?.value),
    }),
  },
  { key: 'volume', title: 'Volume', dataType: 'number' },
  {
    key: 'measurement',
    title: 'Measurement',
    dataType: 'entity',
    typeExtra: { entity: 'stockexchanges.Measurement' },
    getValue: (rec) =>
      rec.measurement ? [{ id: rec.measurement, title: rec.measurement_title }] : undefined,
    setValue: ({ record, value }) => {
      return value?.length
        ? { ...record, measurement: value[0].id, measurement_title: value[0].title }
        : record;
    },
  },
  { key: 'editor_first_name', title: 'Editor', dataType: 'text', editing: false },
  { key: 'price', title: 'Price', dataType: 'number' },
  {
    key: 'business_unit',
    title: 'Business unit',
    dataType: 'entity',
    typeExtra: { entity: 'core.BusinessUnit' },
    getValue: (rec) =>
      rec.business_unit ? [{ id: rec.business_unit, title: rec.business_unit_title }] : undefined,
    setValue: ({ record, value }) => {
      return value?.length
        ? { ...record, business_unit: value[0].id, business_unit_title: value[0].title }
        : record;
    },
  },
  {
    key: 'basis',
    title: 'Basis',
    dataType: 'entity',
    typeExtra: { entity: 'logistics.Basis' },
    getValue: (rec) => (rec.basis ? [{ id: rec.basis, title: rec.basis_name }] : undefined),
    setValue: ({ record, value }) => {
      return value?.length ? { ...record, basis: value[0].id, basis_name: value[0].title } : record;
    },
  },
  {
    key: 'update_time',
    dataType: 'date',
    editing: false,
    typeExtra: { print: 'yy-MM-dd HH:mm' },
  },
  { key: 'quantity', title: 'Quantity of measurement', dataType: 'number' },
  {
    key: 'buyer',
    title: 'Buyer',
    dataType: 'entity',
    typeExtra: { entity: 'clients.ClientRole' },
    getValue: (rec) => (rec.buyer ? [{ id: rec.buyer, title: rec.buyer_name }] : undefined),
    setValue: ({ record, value }) => {
      return value?.length ? { ...record, buyer: value[0].id, buyer_name: value[0].title } : record;
    },
  },
  {
    key: 'supplier',
    title: 'Supplier',
    dataType: 'entity',
    typeExtra: { entity: 'clients.ClientRole' },
    getValue: (rec) =>
      rec.supplier ? [{ id: rec.supplier, title: rec.supplier_name }] : undefined,
    setValue: ({ record, value }) => {
      return value?.length
        ? { ...record, supplier: value[0].id, supplier_name: value[0].title }
        : record;
    },
  },
  {
    key: 'warehouse',
    dataType: 'entity',
    typeExtra: { entity: 'logistics.Warehouse' },
    editing: true,
    getValue: (rec) =>
      rec.warehouse ? [{ id: rec.warehouse, title: rec.warehouse_title }] : undefined,
    setValue: ({ record, value }) => {
      return value?.length
        ? { ...record, warehouse: value[0].id, warehouse_title: value[0].title }
        : record;
    },
  },
  {
    key: 'port_set',
    title: 'Port',
    dataType: 'entity',
    typeExtra: { entity: 'logistics.Port', multiple: true },
    getValue: (rec) => rec.port_set?.map((p) => ({ id: p.id, title: p.name })) ?? undefined,
    editing: false,
  },
  {
    key: 'currency',
    title: 'Currency symbol',
    dataType: 'entity',
    typeExtra: { entity: 'finances.Currency' },
    getValue: (rec) =>
      rec.currency ? [{ id: rec.currency, title: rec.currency_symbol }] : undefined,
    setValue: ({ record, value }) => {
      return value?.length
        ? { ...record, currency: value[0].id, currency_symbol: value[0].title }
        : record;
    },
    schema: requireSchema('Currency is required'),
  },
  {
    key: 'warehouses_data',
    title: 'Warehouses',
    dataType: 'entity',
    typeExtra: { entity: 'logistics.Warehouse', multiple: true },
    getValue: (rec) => rec.warehouses_data?.map((w) => ({ id: w.id, title: w.title })) ?? undefined,
    editing: false,
  },
  {
    key: 'broker',
    title: 'Broker',
    dataType: 'entity',
    typeExtra: { entity: 'clients.ClientRole' },
    getValue: (rec) => (rec.broker ? [{ id: rec.broker, title: rec.broker_name }] : undefined),
    setValue: ({ record, value }) => {
      return value?.length
        ? { ...record, broker: value[0].id, broker_name: value[0].title }
        : record;
    },
  },
  {
    key: 'broker_contacts_data',
    title: 'Broker details',
    dataType: 'text',
    editing: false,
    getValue: (rec) =>
      rec.broker_contacts_data?.length
        ? rec.broker_contacts_data.map((item) => `${item.first_name} ${item.last_name}`).join(', ')
        : '',
  },
];

export const getIndicatorFields = (): FieldDef<RequestRecord>[] => [
  ...getCommonColumns('indicator'),
  {
    key: '_period',
    title: 'Period',
    dataType: 'text',
    editing: false,
    getValue: (rec) => `${rec.date_of_execution ?? '-'} - ${rec.end_of_execution ?? '-'}`,
  },
  { key: 'contracts_volume', title: 'Contracts volume', dataType: 'number', editing: false },
  {
    key: 'contracts_final_volume',
    title: 'Contracts final volume',
    dataType: 'number',
    editing: false,
  },
  { key: 'tickets_volume', title: 'Tickets volume', dataType: 'number', editing: false },
  { key: 'unconnected_volume', title: 'Unconnected volume', dataType: 'number', editing: false },
  {
    key: 'unconnected_final_volume',
    title: 'Unconnected final volume',
    dataType: 'number',
    editing: false,
  },
  {
    key: 'unconnected_ticket_volume',
    title: 'Unconnected ticket volume',
    dataType: 'number',
    editing: false,
  },
  { key: 'additional_info', title: 'Notes', dataType: 'text' },
  { key: 'latest_update_text', title: 'Comments', dataType: 'text', editing: false },
  { key: 'analytical_price', title: 'Analytical price', dataType: 'number' },
  {
    key: 'deal_type',
    title: 'Deal type',
    dataType: 'select',
    typeExtra: { options: Object.values(dealTypeOptions) },
    getValue: (rec) => (rec.deal_type ? dealTypeOptions[rec.deal_type] : undefined),
    setValue: ({ record, value }) => ({
      ...record,
      deal_type: value?.value as RequestRecord['deal_type'],
    }),
    schema: requireSchema('Deal type is requried'),
  },
  { key: 'crop_year', title: 'Crop year', dataType: 'text' },
  {
    key: 'season',
    title: 'Season',
    dataType: 'entity',
    typeExtra: { entity: 'core.Season' },
    getValue: (rec) => (rec.season ? [{ id: rec.season, title: rec.season_title }] : undefined),
    setValue: ({ record, value }) => {
      return value?.length
        ? { ...record, season: value[0].id, season_title: value[0].title }
        : record;
    },
  },
  { key: 'measurement_price', title: 'Measurement price', dataType: 'number' },
  { key: 'fixed_price_bonus', title: 'Fixed price bonus', dataType: 'number' },
  {
    key: 'cargo',
    dataType: 'entity',
    typeExtra: { entity: 'crops.Commodity' },
    getValue: (rec) => (rec.cargo ? [{ id: rec.cargo, title: rec.crop_title }] : undefined),
    setValue: ({ record, value }) => {
      return value?.length ? { ...record, cargo: value[0].id, crop_title: value[0].title } : record;
    },
    schema: requireSchema('Cargo is required'),
  },
  {
    key: 'estimated_date_execution',
    title: 'Delivery estimated month',
    dataType: 'date',
    typeExtra: { print: "MMMM'yy" },
    editing: false,
  },
  {
    key: 'ship_classification_title',
    title: 'Ship classification',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'descendants_contracts',
    title: 'Contract',
    dataType: 'text',
    editing: false,
    getValue: (rec) =>
      rec.descendants_contracts?.length
        ? rec.descendants_contracts.map((item) => item.contract_number).join(', ')
        : '',
  },
  {
    key: 'available_in_bot',
    title: 'Bot',
    dataType: 'checkbox',
  },
  {
    key: 'descendants_tickets',
    title: 'Ticket',
    dataType: 'text',
    editing: false,
    getValue: (rec) =>
      rec.descendants_tickets?.length
        ? rec.descendants_tickets.map((item) => item.contract_number).join(', ')
        : '',
  },
  {
    key: 'date',
    title: 'Date',
    dataType: 'date',
    typeExtra: { print: 'dd MMMM yy HH:mm' },
    editing: false,
  },
];

const approvalStatusOptions: Record<RequestRecord['approval_status'], ListOption> = {
  wait: { value: 'wait', label: 'Wait', tagProps: { variant: 'yellow' } },
  rejected: { value: 'rejected', label: 'Rejected', tagProps: { variant: 'red' } },
  approved: { value: 'approved', label: 'Approved', tagProps: { variant: 'green' } },
  process: {
    value: 'process',
    label: 'Process',
    tagProps: { variant: 'gray' },
  },
};

export const getRequestsFields = (user: CurrentUser): FieldDef<RequestRecord>[] => [
  ...getCommonColumns('ticket'),
  {
    key: 'multicontract_number',
    title: 'Multirequest',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'price_indicative',
    title: 'Price (with costs, without VAT)',
    dataType: 'number',
  },
  {
    key: 'price_indicative_with_vat',
    title: 'Price (with costs, with VAT)',
    dataType: 'number',
    editing: false,
  },
  {
    key: 'author_first_name',
    title: 'Creator',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'counterparty_approval_status',
    title: 'Counterparty approval status',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'currency_exchange',
    title: 'Exchange rate',
    typeExtra: { entity: 'finances.CurrencyExchange' },
    dataType: 'entity',
    getValue: (rec) =>
      rec.currency_exchange
        ? [{ id: rec.currency_exchange, title: rec.currency_exchange_rate }]
        : undefined,
    setValue: ({ record, value }) => {
      return value?.length
        ? {
            ...record,
            currency_exchange: value[0].id,
            currency_exchange_rate: value[0].title,
          }
        : record;
    },
    validate: ({ record, value }) => {
      if (
        record.currency_symbol?.toLowerCase() !==
          user.settings.DEFAULT_CURRENCY.toLocaleLowerCase() &&
        !value?.length
      ) {
        return 'exchange rate is required';
      }
    },
  },
  {
    key: 'generalagreement_percent_conditions',
    title: 'General agreement',
    dataType: 'number',
    editing: false,
  },
  { key: 'total_charges', title: 'Changes', dataType: 'number' },

  {
    key: 'date_of_execution',
    title: 'Delivery',
    dataType: 'date',
    editing: false,
    typeExtra: { print: 'yy-MM-dd' },
  },
  {
    key: 'contractcharges_charge_title_list',
    title: 'Costs details',
    dataType: 'text',
    getValue: (rec) =>
      rec.contractcharges_charge_title_list?.length
        ? rec.contractcharges_charge_title_list.join(', ')
        : '',
  },
  {
    key: 'conclusion_date',
    title: 'Conclusion',
    dataType: 'date',
    typeExtra: { print: 'yyy-MM-dd' },
  },
  {
    key: 'balance_payment',
    title: 'Payment Events',
    typeExtra: { entity: 'finances.BalancePayment' },
    dataType: 'entity',
    getValue: (rec) =>
      rec.balance_payment
        ? [{ id: rec.balance_payment, title: rec.balancepayment_title }]
        : undefined,
    setValue: ({ record, value }) => {
      return value?.length
        ? {
            ...record,
            balance_payment: value[0].id,
            balancepayment_title: value[0].title,
          }
        : record;
    },
  },
  {
    key: 'contract_conclusion_date',
    title: 'Multirequest conclusion date',
    typeExtra: { print: 'yy-MM-dd' },
    dataType: 'date',
    editing: false,
  },
  {
    key: 'contract_custom_status_title',
    title: 'Multirequest custom status',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'price_indicative_default_currency',
    title: 'Price (with costs, without VAT), $',
    dataType: 'number',
    editing: false,
  },
  {
    key: '_creator',
    title: 'Creator',
    dataType: 'person',
    editing: false,
    getValue: (record) => ({
      firstName: record.author_first_name,
      lastName: record.author_last_name,
      avatarSrc: record.author_avatar,
    }),
  },
  {
    key: 'type_of_activities_data',
    title: 'Type of activities',
    dataType: 'text',
    getValue: (rec) =>
      rec.type_of_activities_data?.length
        ? rec.type_of_activities_data.map((item) => item.title).join(', ')
        : '',
    editing: false,
  },
  {
    key: 'sap_orders_data',
    title: 'Sap orders',
    dataType: 'text',
    getValue: (rec) =>
      rec.sap_orders_data?.length ? rec.sap_orders_data.map((item) => item.number).join(', ') : '',
    editing: false,
  },
  {
    key: 'create_time',
    title: 'Creation',
    dataType: 'date',
    editing: false,
    typeExtra: { print: 'dd.MM.yy HH:mm' },
  },
  {
    key: 'volume_options',
    title: 'Options',
    dataType: 'number',
  },
  {
    key: '_charges',
    title: 'Charges',
    dataType: 'string',
    editing: false,
    getValue: (rec) =>
      `${(rec.total_charges ?? 0).toFixed(2)} / ${(rec.total_charges_local ?? 0).toFixed(2)}`,
  },
  {
    key: 'responsible',
    title: 'Responsible',
    dataType: 'entity',
    typeExtra: { entity: 'accounts.User' },
    getValue: (rec) =>
      rec.responsible
        ? [
            {
              id: rec.responsible,
              title: `${rec.responsible_first_name} ${rec.responsible_last_name}`,
            },
          ]
        : undefined,
    setValue: ({ record, value }) =>
      value?.length
        ? {
            ...record,
            responsible: value[0].id,
            responsible_first_name: value[0].title.split(' ')[0],
            responsible_last_name: value[0].title.split(' ')[1],
          }
        : record,
  },
  {
    key: 'approval_status',
    title: 'Approval Status',
    dataType: 'select',
    getValue: (rec) =>
      rec.approval_status ? approvalStatusOptions[rec.approval_status] : undefined,
    typeExtra: {
      options: Object.values(approvalStatusOptions),
    },
  },
  {
    key: 'connections',
    title: 'Passport',
    dataType: 'entity',
    typeExtra: { entity: 'passports.Passport' },
    getValue: (rec) =>
      rec.connections.map((item) => ({ id: item.passport, title: item.passport_title })),
    editing: false,
  },
  {
    key: 'total_connected',
    title: 'Connected',
    dataType: 'number',
    editing: false,
  },
  {
    key: 'generalagreement_status',
    dataType: 'text',
    title: 'Agreement approval status',
    editing: false,
  },
  {
    key: 'parent_indicator_price',
    title: 'Parent indicator price',
    dataType: 'number',
    editing: false,
  },
  {
    key: '_payment_events',
    title: 'Payment events',
    dataType: 'text',
    editing: false,
    getValue: (rec) => rec.prepaypayment_title ?? rec.balancepayment_title,
  },
  {
    key: 'from_request_title',
    title: 'Chain',
    dataType: 'text',
    editing: false,
  },
];

export const getRequestsContainerFields = (user: CurrentUser, stage = 'ticket') => {
  return stage === 'ticket' ? getRequestsFields(user) : getIndicatorFields();
};
