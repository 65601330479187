import type ng from 'angular';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export class GeneralAgreementFormFieldsService {
  $rootScope: GtRootScopeService;
  $window: ng.IWindowService;
  AccountsService: AccountsService;
  ClientsService: any;
  CoreService: CoreService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $rootScope: GtRootScopeService,
    $window: ng.IWindowService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    CoreService: CoreService,
    AccountsService: AccountsService,
    ClientsService: any,
  ) {
    this.$rootScope = $rootScope;
    this.$window = $window;
    this.gettext = gettext;
    this.GtUtils = GtUtils;
    this.CoreService = CoreService;
    this.AccountsService = AccountsService;
    this.ClientsService = ClientsService;
  }

  getFields(agreement: any, onFileSelect: any) {
    const col1: any = {
      className: 'form-group-container col-md-6 col-xs-12',
      fieldGroup: [],
    };
    const col2: any = {
      className: 'form-group-container col-md-6 col-xs-12',
      fieldGroup: [],
    };
    col1.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: this.gettext('GENERAL'),
        },
        fieldGroup: [
          {
            key: 'is_main_deal',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Main deal'),
            },
            hideExpression: () => false,
          },
          {
            key: 'agreement_type',
            type: 'gt-select',
            defaultValue: 'purchase',
            templateOptions: {
              label: this.gettext('Type'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: this.gettext('Purchase'), value: 'purchase' },
                { name: this.gettext('Sale'), value: 'sale' },
                { name: this.gettext('Credit'), value: 'credit' },
              ],
              onSelected: () => {
                this.$rootScope.$broadcast('agreement-type-updated');
              },
            },
            hideExpression: () => false,
          },
          {
            key: 'deal_type',
            type: 'gt-select',
            defaultValue: 'spot',
            templateOptions: {
              label: this.gettext('Deal type'),
              placeholder: this.gettext('Choose type'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: this.gettext('Spot'), value: 'spot' },
                { name: this.gettext('Services'), value: 'services' },
                { name: this.gettext('Intermediate'), value: 'intermediate' },
                { name: this.gettext('Export'), value: 'export' },
              ],
            },
            hideExpression: () => false,
          },
          {
            key: 'status',
            type: 'gt-select',
            defaultValue: 'new',
            templateOptions: {
              label: this.gettext('Status'),
              placeholder: this.gettext('Choose status'),
              hint: this.gettext('Status depends on current progress on general agreement'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: this.gettext('New'), value: 'new' },
                { name: this.gettext('Process'), value: 'process' },
                { name: this.gettext('Approved'), value: 'approved' },
                { name: this.gettext('Rejected'), value: 'rejected' },
              ],
            },
            hideExpression: () => false,
          },
          {
            key: 'auto_number',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Generate agreement number'),
              hint: this.gettext('Check this if you want agreement number to be auto generated'),
            },
            hideExpression: () => false,
          },
          {
            key: 'number',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Number (#)'),
              placeholder: this.gettext('Number (#)'),
              type: 'text',
              required: true,
              addon: this.gettext('#'),
            },
            validation: {
              show: true,
            },
            hideExpression: () => false,
          },
          {
            key: 'amount',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Amount'),
              placeholder: this.gettext('Amount'),
              type: 'number',
            },
            hideExpression: () => agreement.volume > 0,
          },
          {
            key: 'currency',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('currency'),
              placeholder: this.gettext('currency'),
              resource: 'finances.Currency',
            },
            hideExpression: () => agreement.volume > 0,
          },
          {
            key: 'potential_buyers',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('POTENTIAL BUYERS'),
              resource: 'clients.Buyer',
              getQueryParams: () => ({ buyers: agreement.buyer }),
              addFunc: () => this.ClientsService.roleModal({ model_name: 'Buyer' }),
              addIcon: this.GtUtils.getIcon('clients.Buyer'),
              addPerms: ['add_buyer'],
            },
            hideExpression: () => agreement.agreement_type === 'purchase',
          },
          {
            key: 'buyer',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('BUYER'),
              placeholder: this.gettext('Buyer'),
              resource: 'clients.Buyer',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Buyer' });
              },
              addIcon: this.GtUtils.getIcon('clients.Buyer'),
              addPerms: ['add_buyer'],
            },
            hideExpression: () => agreement.agreement_type !== 'sale',
          },
          {
            key: 'buyer',
            type: 'gt-ui-select',
            templateOptions: {
              required: true,
              label: this.gettext('BUYER'),
              placeholder: this.gettext('Owner'),
              resource: 'clients.Owner',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Owner' });
              },
              addIcon: this.GtUtils.getIcon('clients.Owner'),
              addPerms: ['add_owner'],
              hint: this.gettext(
                'In the case, if general agreement is PURCHASE - then the BUYER is our company. It is chosen from the list of Owners',
              ),
            },
            hideExpression: () => agreement.agreement_type !== 'purchase',
          },
          {
            key: 'buyer_payment_info',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Buyer payment info'),
              resource: 'finances.PaymentInfo',
              queryParams: () => {
                return {
                  client_role: agreement.buyer,
                  currency_nullable: agreement.currency,
                  status: 'valid',
                };
              },
            },
            hideExpression: () => false,
          },
          {
            key: 'potential_suppliers',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('POTENTIAL SUPPLIERS'),
              resource: 'clients.Supplier',
              getQueryParams: () => {
                return { suppliers: agreement.supplier };
              },
              addFunc: () => {
                return this.ClientsService.roleModal({
                  model_name: 'Supplier',
                });
              },
              addIcon: this.GtUtils.getIcon('clients.Supplier'),
              addPerms: ['add_supplier'],
            },
            hideExpression: () => agreement.agreement_type === 'sale',
          },
          {
            key: 'supplier',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('SUPPLIER'),
              placeholder: this.gettext('Supplier'),
              resource: 'clients.Supplier',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Supplier' });
              },
              addIcon: this.GtUtils.getIcon('clients.Supplier'),
              addPerms: ['add_supplier'],
            },
            hideExpression: () => agreement.agreement_type !== 'purchase',
          },
          {
            key: 'supplier',
            type: 'gt-ui-select',
            templateOptions: {
              required: true,
              label: this.gettext('SUPPLIER'),
              placeholder: this.gettext('Owner'),
              resource: 'clients.Owner',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Owner' });
              },
              addIcon: this.GtUtils.getIcon('clients.Owner'),
              addPerms: ['add_owner'],
              hint: this.gettext(
                'In the case, if general agreement is SALE - then the SUPPLIER is our company. It is chosen from the list of Owners',
              ),
            },
            hideExpression: () => agreement.agreement_type !== 'sale',
          },
          {
            key: 'supplier_payment_info',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Supplier payment info'),
              resource: 'finances.PaymentInfo',
              queryParams: () => {
                return {
                  client_role: agreement.supplier,
                  currency_nullable: agreement.currency,
                  status: 'valid',
                };
              },
            },
            hideExpression: () => false,
          },

          {
            key: 'type_of_activities',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Type of activities'),
              resource: 'core.TypeOfActivity',
              hint: this.gettext('If you want to add type or activity to this company'),
              addFunc: () => this.$window.open('/admin/core/typeofactivity/add/'),
              addPerms: true,
              addIcon: 'fa fa-tasks',
            },
            hideExpression: () => false,
          },
        ].filter((field) => !field.hideExpression()),
      },
    ];
    col2.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: this.gettext('DATES'),
        },
        fieldGroup: [
          {
            key: 'conclusion_date',
            type: 'gt-date-select',
            defaultValue: new Date(),
            templateOptions: {
              required: true,
              label: this.gettext('Conclusion date'),
              placeholder: this.gettext('conclusion date'),
              type: 'date',
            },
            validation: {
              show: true,
            },
            hideExpression: () => false,
          },
          {
            key: 'commodity',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Product'),
              placeholder: this.gettext('Choose product'),
              resource: 'crops.Crop',
              addIcon: 'fa fa-wheat-awn',
            },
            hideExpression: () => false,
          },
          {
            key: 'regional_managers',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Sales managers'),
              resource: 'accounts.RegionalManager',
              addFunc: () => this.$window.open('/admin/accounts/regionalmanager/add/'),
              addPerms: true,
              addIcon: 'fa fa-tasks',
            },
            hideExpression: () => false,
          },
          {
            key: 'budget',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Budget'),
              resource: 'finances.Budget',
              addFunc: () => {
                this.$window.open('/admin/finances/budget/add/');
              },
              addPerms: true,
              addIcon: 'fa fa-dollar',
              title: agreement.budget_title,
            },
            hideExpression: () => false,
          },
          {
            key: 'signatory',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Signatory'),
              placeholder: this.gettext('Choose signatory'),
              resource: 'accounts.RegionalManager',
              addPerms: true,
              addIcon: 'fa fa-user',
            },
            hideExpression: () => false,
          },
          {
            key: 'date_of_execution',
            type: 'gt-date-select',
            templateOptions: {
              label: this.gettext('Delivery (start date)'),
              placeholder: this.gettext('date'),
              type: 'date',
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'end_of_execution',
            type: 'gt-date-select',
            templateOptions: {
              label: this.gettext('Delivery (end date)'),
              placeholder: this.gettext('date'),
              type: 'date',
            },
          },
          {
            key: 'percent_conditions',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('percent conditions'),
              placeholder: this.gettext('Type percent conditions'),
              type: 'number',
              addon: this.gettext('%'),
            },
          },
          {
            key: 'volume',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Volume'),
              placeholder: this.gettext('Type volume'),
              type: 'number',
              addon: this.gettext('t'),
            },
            hideExpression: (viewValue: any, modelValue: any, scope: any) => scope.model.amount > 0,
          },
          {
            key: 'tax_registration',
            type: 'gt-select',
            defaultValue: 'free_tax_invoice',
            templateOptions: {
              label: this.gettext('Tax registration'),
              placeholder: this.gettext('Choose tax registration'),
              hint: this.gettext('Choose tax registration'),
              options: [
                {
                  title: this.gettext('Free tax invoice'),
                  id: 'free_tax_invoice',
                },
                { title: this.gettext('On first event'), id: 'on_first_event' },
              ],
            },
          },
          {
            key: 'note',
            type: 'gt-textarea',
            templateOptions: {
              label: this.gettext('Note'),
              placeholder: this.gettext('Specific information about this object'),
            },
          },
          {
            key: 'approval_config',
            type: 'gt-ui-select',
            defaultValueResolve: () =>
              this.AccountsService.getDefaultApprovalConfigId('contracts.GeneralAgreement'),
            templateOptions: {
              label: this.gettext('Approval Config'),
              resource: 'accounts.ApprovalConfig',
              queryParams: () => {
                return {
                  object_type: 'contracts.GeneralAgreement',
                  is_active: 1,
                  bu_list: agreement.business_unit,
                };
              },
            },
          },
          {
            key: 'is_according_to_contract_template',
            type: 'gt-select',
            templateOptions: {
              label: this.gettext('According to contract template'),
              options: [
                { title: this.gettext('---'), id: undefined },
                { title: this.gettext('Yes'), id: true },
                { title: this.gettext('No'), id: false },
              ],
            },
          },
          {
            key: 'has_digital_signature',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Digital signature'),
              hint: this.gettext('Check this if the agreement is digitally signed'),
            },
          },
          {
            key: 'documents',
            type: 'gt-documents',
            templateOptions: {
              label: this.gettext('Contract document'),
              onFileSelect: (files: any) => onFileSelect(files),
              deleteEventSource: 'argeement-modal',
              connectedField: 'is_according_to_contract_template',
            },
            hideExpression: () => agreement.is_according_to_contract_template !== false,
          },
          {
            template: html`
              <custom-values-container
                filter-level="'generalagreement-custom-values-container'"
                init-query-params="{
                  object_id: model.id,
                  purpose: model.content_type,
                  purpose_model: 'generalagreement',
                }"
                mode="model.id ? 'edit' : 'create'"
                object-id="model.id"
              ></custom-values-container>
            `,
          },
        ],
      },
    ];

    return {
      formName: 'general-agreement-edit-modal',
      fieldsDef: [col1, col2],
    };
  }
}
GeneralAgreementFormFieldsService.$inject = [
  '$rootScope',
  '$window',
  'gettext',
  'GtUtils',
  'CoreService',
  'AccountsService',
  'ClientsService',
];
