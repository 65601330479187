import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export class BankAccountTurnoverTotalsService {
  FinancesService: FinancesService;
  constructor(FinancesService: any) {
    this.FinancesService = FinancesService;
  }

  getTurnoverTotal(params: object) {
    return this.FinancesService.BankAccount.turnoverTotal(params).$promise;
  }
}
BankAccountTurnoverTotalsService.$inject = ['FinancesService'];
