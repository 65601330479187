import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { BillOfLadingService } from '^/app/execution/components/transport/bill-of-lading/bill-of-lading.service';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').directive('lineupRow', directive);

  function directive() {
    return {
      template: require('./lineup-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        passport: '<',
        filterLevel: '<',
        isFirst: '<',
        subtab: '<?',
      },
    };
  }

  Controller.$inject = [
    'gtFilterService',
    'GtUtils',
    'ContractsService',
    'ReportsService',
    'DocumentsService',
    'BillOfLadingService',
    'gettext',
  ];

  function Controller(
    this: any,
    gtFilterService: GtFilterService,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    ReportsService: ReportsService,
    DocumentsService: any,
    BillOfLadingService: BillOfLadingService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.edit = false;
    vm.editStatus = false;
    vm.openPassportModal = openPassportModal;
    vm.onUpdate = onUpdate;
    vm.editContract = editContract;
    vm.editPassport = editPassport;
    vm.deleteFact = deleteFact;
    vm.deleteBill = deleteBill;
    vm.updatePassport = updatePassport;
    vm.openDocumentModal = openDocumentModal;
    vm.save = save;
    vm.getNgClass = getNgClass;
    vm.showPassportDetail = false;
    vm.connectToPassport = connectToPassport;
    vm.createMarginControl = createMarginControl;

    ////////////////

    vm.$onInit = function () {
      vm.passport.suppliers = [];
      vm.passport.buyers = [];
      vm.passport.owners = [];
      vm.passport.sale_facts = vm.passport.sale_facts || [];
      vm.passport.purchase_facts = vm.passport.purchase_facts || [];
      vm.passport.sale_facts.forEach(function (fact: any) {
        vm.passport.buyers.push(fact.buyer_name);
        vm.passport.owners.push(fact.owner_name);
      });
      vm.passport.purchase_facts.forEach(function (fact: any) {
        vm.passport.suppliers.push(fact.supplier_name);
        vm.passport.owners.push(fact.owner_name);
      });
      vm.passport.suppliers = [...new Set(vm.passport.suppliers)];
      vm.passport.buyers = [...new Set(vm.passport.buyers)];
      vm.passport.owners = [...new Set(vm.passport.owners)];

      vm.filterLevelSale = vm.filterLevel + '-sale';
      vm.filterLevelPurchase = vm.filterLevel + '-purchase';

      vm.queryParamsSale = {
        contract_type: 'sale',
        id_list: vm.passport.sale_fact_contract_ids || [-1],
        serializer: 'table_execution',
      };
      vm.queryParamsPurchase = {
        contract_type: 'purchase',
        id_list: vm.passport.purchase_fact_contract_ids || [-1],
        serializer: 'table_execution',
      };
    };

    function openPassportModal(passport: any, args: any) {
      if (args?.tab) {
        vm.tab = 'info';
      }
      return ContractsService.passportModal(passport, args).then(function (data: any) {
        if (args?.tab) {
          vm.tab = args.tab;
        }
        if (!data || data == 'cancel') {
          return;
        }
        onUpdate();
      });
    }

    function onUpdate() {
      gtFilterService.updateQueryParams({}, vm.filterLevel);
    }

    function deleteFact(fact: any, type: keyof ContractsService) {
      ContractsService[type].delete({ id: fact.fact_id }, onUpdate);
    }

    function deleteBill(bill: any) {
      BillOfLadingService.deleteBillOfLading(bill).then(onUpdate);
    }

    function editContract(fact: any) {
      ContractsService.contractModal({ id: fact.contract_id })
        .then(function (data: any) {
          if (!data || data == 'cancel') {
            return;
          }
          onUpdate();
        })
        .catch(GtUtils.errorClb);
    }

    function editPassport(passport: any) {
      ReportsService.lineupModal({ id: passport.id }).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        onUpdate();
      });
    }

    function openDocumentModal(id: number) {
      return DocumentsService.documentListModal({
        model_name: 'ContractBase',
        object_id: id,
      });
    }

    function updateContracts() {
      let contracts: any = [];
      if (vm.passport.sale_facts?.length) {
        contracts = contracts.concat(
          vm.passport.sale_facts.map(function (contract: any) {
            return {
              id: contract.contract_id,
              contract_number: contract.contract_number,
              basis: contract.basis_id,
              cargo: contract.crop_id,
              date_of_execution: contract.date_of_execution,
              end_of_execution: contract.end_of_execution,
              ports: contract.ports,
              price: contract.price,
              volume: contract.volume,
              volume_options: contract.volume_options,
            };
          }),
        );
      }
      if (vm.passport.purchase_facts?.length) {
        contracts = contracts.concat(
          vm.passport.purchase_facts.map(function (contract: any) {
            return {
              id: contract.contract_id,
              contract_number: contract.contract_number,
              basis: contract.basis_id,
              cargo: contract.crop_id,
              date_of_execution: contract.date_of_execution,
              end_of_execution: contract.end_of_execution,
              ports: contract.ports,
              price: contract.price,
              volume: contract.volume,
              volume_options: contract.volume_options,
            };
          }),
        );
      }
      return ContractsService.Contract.bulkUpdate(contracts).$promise;
    }

    function updateConsignments() {
      let consignments: any = [];
      if (vm.passport.billoflading_set?.length) {
        consignments = consignments.concat(
          vm.passport.billoflading_set.map(function (billoflading: any) {
            return {
              id: billoflading.id,
              volume: billoflading.volume,
              date: billoflading.date,
            };
          }),
        );
      }
      return BillOfLadingService.bulkUpdateBillOfLading(consignments);
    }

    function updatePassport() {
      const passport = {
        id: vm.passport.id,
        vessel_id: vm.passport.vessel_id,
        voyage_id: vm.passport.voyage_id,
        etc: vm.passport.etc,
        current_progress: vm.passport.current_progress,
        status: vm.passport.status,
      };
      return ContractsService.Passport.lineupUpdate(passport).$promise.then(function () {
        return ContractsService.Passport.update({
          id: vm.passport.id,
          status: vm.passport.status,
          custom_status: vm.passport.custom_status,
          is_updated_today: vm.passport.is_updated_today,
        }).$promise;
      });
    }

    function connectToPassport(contract: any, passport: any) {
      if (contract.contractId == -1) {
        return notify(gettext('This is not contract.'));
      }

      if (
        !commodityConformityCheck(
          contract.commodityId,
          passport.cargo_set.map((item: any) => item.id),
        )
      ) {
        return false;
      }
      return ContractsService.connectToPassport({
        id: passport.id,
        deal_id: contract.contractId,
        stage: 'contract',
        type: contract.contractType,
        volume: contract.volume,
      }).then(
        (res: any) => {
          if (res == undefined) {
            return false;
          } else if (contract.factId !== undefined) {
            ContractsService[contract.factType as keyof ContractsService].delete(
              { id: contract.factId },
              onUpdate,
            );
            notify(gettext('Passport updated'));
          } else {
            onUpdate();
            notify(gettext('Passport updated'));
            gtFilterService.updateQueryParams(
              {},
              `lineup-contracts-table-${contract.contractType}`,
            );
          }
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }

    function commodityConformityCheck(contractCommodity: any, passportFactCommodities: any) {
      if (passportFactCommodities.length && passportFactCommodities.includes(contractCommodity)) {
        return true;
      } else {
        return confirm(
          'The commodity of the contract is different from the commodity of the passport contracts. Are you sure you want to continue? ',
        )
          ? true
          : false;
      }
    }

    function save() {
      updateContracts().then(function () {
        updateConsignments().then(function () {
          updatePassport().then(function () {
            onUpdate();
            vm.edit = false;
          });
        });
      });
    }

    function getNgClass(passport: any) {
      if (passport.custom_status_codename) {
        return {
          label_success: passport.custom_status_codename == 'new',
          label_warning: passport.custom_status_codename == 'processing',
          label_default: passport.custom_status_codename == 'executed',
          label_danger: passport.custom_status_codename == 'cancelled',
          label_calculate: passport.custom_status_codename == 'approved',
        };
      }
    }

    function createMarginControl() {
      ContractsService.MarginControl.save({
        passport: vm.passport.id,
        custom_status: vm.passport.custom_status,
        ticket_margin_t: vm.passport.plans_margin_per_t,
        contract_margin_t: vm.passport.facts_margin_per_t,
        execution_margin_t: vm.passport.facts_transport_margin_per_t,
        invoice_margin_t: vm.passport.facts_invoice_margin_per_t,
        payment_margin_t: vm.passport.facts_payment_margin_per_t,
        ticket_margin: vm.passport.plans_margin_with_vat,
        contract_margin: vm.passport.facts_margin_with_vat,
        execution_margin: vm.passport.facts_transport_margin_with_vat,
        invoice_margin: vm.passport.invoices_margin_with_vat,
        payment_margin: vm.passport.payments_margin_with_vat,
      }).$promise.then(
        () => {
          vm.onUpdate();
          notify(gettext('Margin control created'));
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }
  }
})();
