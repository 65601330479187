import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import { useComponent } from '~/shared/lib/components';

import type { RequestsListContainerProps } from './requests-list.model';
import { RequestsListModel } from './requests-list.model';

export const RequestsListContainer = (props: RequestsListContainerProps) => {
  const { ComponentProvider } = useComponent(props, RequestsListModel);

  return (
    <ComponentProvider>
      <EntityListContainer />;
    </ComponentProvider>
  );
};
