import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '~/shared/lib/utils';

const textareaVariants = cva('', {
  variants: {
    intent: {
      danger: 'border-stroke-additional-dangerLight text-text-additional-danger focus:ring-0',
      highlighted:
        'border-stroke-main-medium shadow-strong backdrop-blur-strong focus:border-stroke-additional-info focus:shadow-strong focus:ring-background-additional-infoLight',
      default:
        'border-stroke-main-medium focus:border-stroke-additional-info focus:ring-background-additional-infoLight',
    },
  },
  defaultVariants: {
    intent: 'default',
  },
});

type TextareaVariants = VariantProps<typeof textareaVariants>;

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  {
    resize?: boolean;
    helperText?: React.ReactNode;
    label?: React.ReactNode;
    fullWidth?: boolean;
  } & React.TextareaHTMLAttributes<HTMLTextAreaElement> &
    TextareaVariants
>(({ fullWidth, className, label, resize = false, helperText, intent, ...props }, ref) => {
  return (
    <div className={cn('relative flex flex-col', fullWidth ? 'w-full' : 'w-max')}>
      <label className="flex flex-col">
        <span className="text-text-main-tertiary text-xs font-semibold">{label}</span>
        <textarea
          className={cn(
            'bg-transparent-light text-text-main-primary placeholder-text-main-light rounded-sm border p-2 text-xs',
            'selection:bg-background-additional-infoLight focus:ring focus:outline-none',
            'disabled:text-text-main-light disabled:cursor-not-allowed',
            textareaVariants({ intent }),
            resize ? 'resize-y' : 'resize-none',
            className,
          )}
          ref={ref}
          data-testid="textarea-el"
          {...props}
        />
      </label>
      {helperText && (
        <span className="text-text-main-light absolute -bottom-5 text-xs">{helperText}</span>
      )}
    </div>
  );
});

Textarea.displayName = 'Textarea';
