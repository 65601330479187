import React from 'react';

import { dateFormat as dateFormatFn } from '~/shared/lib/date';
import { cn } from '~/shared/lib/utils';

import type { DataTypeProps } from '../../lib';

const isValidDate = (date: Date | string): date is Date =>
  date instanceof Date && !isNaN(date.getTime());

export const DateRead: React.FC<DataTypeProps<'date'>> = ({ disabled, value, typeExtra }) => {
  if (value && !isValidDate(new Date(value))) {
    console.warn('Invalid date:', value);
    return <></>;
  }

  return value ? (
    <span
      className={cn('text-text-main-primary text-xs', disabled && 'cursor-not-allowed pl-2')}
      data-testid="date-field"
    >
      {dateFormatFn(new Date(value), typeExtra?.print ?? 'dd/MM/yy')}
    </span>
  ) : (
    <></>
  );
};
