import {
  coreCustomFieldsCreate,
  coreCustomFieldsDestroy,
  coreCustomFieldsList,
  coreCustomFieldsPartialUpdate,
  coreCustomFieldsRetrieve,
} from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type {
  CustomField,
  CustomFieldCreate,
  CustomFieldUpdate,
  CustomFieldsQueryParams,
} from '../lib/types';

export class CustomFieldsRepository extends EntityRepository<
  CustomField,
  CustomFieldsQueryParams,
  CustomFieldCreate
> {
  readonly entityName = 'core.CustomField';

  getFn = async (id: number): Promise<CustomField> => {
    const { data } = await coreCustomFieldsRetrieve({ path: { id } });
    return data;
  };
  queryFn = async (query: CustomFieldsQueryParams): Promise<Paginated<CustomField>> => {
    const { data } = await coreCustomFieldsList({ query });
    return { records: data.results, count: data.count };
  };
  searchFn = (_input: string, _id?: number): Promise<EntityOption[]> => {
    throw new Error('Method not implemented.');
  };
  createFn = async (entity: CustomFieldCreate): Promise<CustomField> => {
    const { data } = await coreCustomFieldsCreate({ body: entity });
    return data;
  };
  updateFn = async (entity: CustomFieldUpdate): Promise<CustomField> => {
    const { data } = await coreCustomFieldsPartialUpdate({ body: entity, path: { id: entity.id } });
    return data;
  };
  deleteFn = async (id: number): Promise<void> => {
    await coreCustomFieldsDestroy({ path: { id } });
  };
}
