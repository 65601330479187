import React from 'react';

import { TextFieldRead, TextFieldWrite } from '~/shared/ui/data-types/components';

export const HeaderRender = ({ title, writable }: { title: string; writable?: boolean }) => {
  return writable ? (
    <TextFieldWrite
      value={title}
      typeExtra={{ inputProps: { textColor: 'tertiary', fontWeight: 'semibold' } }}
    />
  ) : (
    <TextFieldRead value={title} className="text-text-main-tertiary font-semibold" />
  );
};
