import ng from 'angular';

(function () {
  'use strict';
  ng.module('common.crops.legacy').directive('cropsQualitiesItem', directive);

  function directive() {
    return {
      template: require('./qualities-item.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        qualities: '<?',
        qualitiesPlan: '<?',
        qualitiesCrop: '<?',
        totalsQualities: '<?',
      },
    };
  }

  Controller.$inject = ['$scope'];

  function Controller(this: any, $scope: ng.IScope) {
    const vm = this;

    vm.findQuality = findQuality;
    vm.qualityList = [];

    activate();

    ////////////////

    function activate() {
      updateData();
      $scope.$watch('vm.qualities', updateData);
      $scope.$watch('vm.qualitiesPlan', updateData);
      $scope.$watch('vm.qualitiesCrop', updateData);
    }

    function updateData() {
      if (vm.qualities?.length) {
        vm.qualityList = vm.qualities;
      } else if (vm.qualitiesPlan?.length) {
        vm.qualityList = vm.qualitiesPlan;
      } else {
        vm.qualityList = vm.qualitiesCrop || [];
      }
    }

    function findQuality(title: any, commodityId: number, list: any) {
      if (!title || !list || list.constructor != Array) {
        return { value: 0 };
      }
      return (
        list
          .filter(function (quality) {
            return (
              quality.title == title &&
              (quality.crop == commodityId || quality.commodity_id == commodityId)
            );
          })
          .shift() || { value: '--' }
      );
    }
  }
})();
