import type ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import type { FinancesService } from '../../legacy/finances.srv';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export class BudgetsService {
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  FinancesService: FinancesService;
  GtUtils: GtUtilsService;
  constructor(
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    GtUtils: GtUtilsService,
    FinancesService: FinancesService,
  ) {
    this.$resource = $resource;
    this.$q = $q;
    this.GtUtils = GtUtils;
    this.FinancesService = FinancesService;
  }

  getFilledFinanceAccounts(params: { year: string }) {
    return this.FinancesService.FinanceAccount.query(params).$promise.then((data: any) => {
      data.results = this.prepareRowData(data.results, params);
      data.totals = this.prepareTotalsData(data.totals);
      return data;
    });
  }

  getFullItems(account: any, year: any) {
    return Array.from({ length: 12 }, (v, k) => {
      const newItem = {
        month: formatDate(new Date(year, k), 'yyyy-MM-dd'),
        account: account.id,
        title: account.title,
      };
      return (
        account.budget_items.filter((item: any) => item.month.getMonth() == k).shift() || newItem
      );
    });
  }

  prepareRowData(results: any, params: { year: string }) {
    results.forEach((account: any) => {
      const fullItems = this.getFullItems(account, params.year);
      fullItems.forEach((item, index) => {
        if (index && item.plan_amount && fullItems[index - 1].plan_amount) {
          const prevPlanAmount = fullItems[index - 1].plan_amount;
          item.diff_plan_prc = ((item.plan_amount - prevPlanAmount) / prevPlanAmount) * 100;
        }
        if (index && item.fact_amount && fullItems[index - 1].fact_amount) {
          const prevFactAmount = fullItems[index - 1].fact_amount;
          item.diff_fact_prc = (item.fact_amount / prevFactAmount) * 100;
        }
      });
      account.budget_items = fullItems;
      account.budget_items_orig = account.budget_items.map((item: any) => JSON.stringify(item));
      account.level_arr = [].constructor(account.level);
      account.cell_class = account.descendant_count ? `${account.budget_account_type}-row` : '';

      account.q1 = {
        plan_amount: 0,
        fact_amount: 0,
      };
      account.q2 = {
        plan_amount: 0,
        fact_amount: 0,
      };
      account.q3 = {
        plan_amount: 0,
        fact_amount: 0,
      };
      account.q4 = {
        plan_amount: 0,
        fact_amount: 0,
      };

      account.q1.plan_amount =
        (account.budget_items[0].plan_amount || 0) +
        (account.budget_items[1].plan_amount || 0) +
        (account.budget_items[2].plan_amount || 0);
      account.q1.fact_amount =
        (account.budget_items[0].fact_amount || 0) + account.budget_items[1].fact_amount ||
        0 + account.budget_items[2].fact_amount ||
        0;

      account.q2.plan_amount =
        account.q1.plan_amount +
        (account.budget_items[3].plan_amount || 0) +
        (account.budget_items[4].plan_amount || 0) +
        (account.budget_items[5].plan_amount || 0);
      account.q2.fact_amount =
        account.q1.fact_amount +
        (account.budget_items[3].fact_amount || 0) +
        (account.budget_items[4].fact_amount || 0) +
        (account.budget_items[5].fact_amount || 0);

      account.q3.plan_amount =
        account.q2.plan_amount +
        (account.budget_items[6].plan_amount || 0) +
        (account.budget_items[7].plan_amount || 0) +
        (account.budget_items[8].plan_amount || 0);
      account.q3.fact_amount =
        account.q2.fact_amount +
        (account.budget_items[6].fact_amount || 0) +
        (account.budget_items[7].fact_amount || 0) +
        (account.budget_items[8].fact_amount || 0);

      account.q4.plan_amount =
        account.q3.plan_amount +
        (account.budget_items[9].plan_amount || 0) +
        (account.budget_items[10].plan_amount || 0) +
        (account.budget_items[11].plan_amount || 0);
      account.q4.fact_amount =
        account.q3.fact_amount +
        (account.budget_items[9].fact_amount || 0) +
        (account.budget_items[10].fact_amount || 0) +
        (account.budget_items[11].fact_amount || 0);
    });
    return results;
  }

  prepareTotalsData(totals: any) {
    Object.keys(totals).forEach((i) => {
      totals[i].expense_amount = parseFloat(totals[i].expense_amount || 0);
      totals[i].income_amount = parseFloat(totals[i].income_amount || 0);
      totals[i].expense_amount_fact = parseFloat(totals[i].expense_amount_fact || 0);
      totals[i].income_amount_fact = parseFloat(totals[i].income_amount_fact || 0);
    });
    totals.q1 = {
      expense_amount: 0,
      income_amount: 0,
      expense_amount_fact: 0,
      income_amount_fact: 0,
    };
    totals.q2 = {
      expense_amount: 0,
      income_amount: 0,
      expense_amount_fact: 0,
      income_amount_fact: 0,
    };
    totals.q3 = {
      expense_amount: 0,
      income_amount: 0,
      expense_amount_fact: 0,
      income_amount_fact: 0,
    };
    totals.q4 = {
      expense_amount: 0,
      income_amount: 0,
      expense_amount_fact: 0,
      income_amount_fact: 0,
    };

    totals.q1.expense_amount =
      totals[1].expense_amount + totals[2].expense_amount + totals[3].expense_amount;
    totals.q1.income_amount =
      totals[1].income_amount + totals[2].income_amount + totals[3].income_amount;
    totals.q1.expense_amount_fact =
      totals[1].expense_amount_fact + totals[2].expense_amount_fact + totals[3].expense_amount_fact;
    totals.q1.income_amount_fact =
      totals[1].income_amount_fact + totals[2].income_amount_fact + totals[3].income_amount_fact;

    totals.q2.expense_amount =
      totals.q1.expense_amount +
      totals[4].expense_amount +
      totals[5].expense_amount +
      totals[6].expense_amount;
    totals.q2.income_amount =
      totals.q1.income_amount +
      totals[4].income_amount +
      totals[5].income_amount +
      totals[6].income_amount;
    totals.q2.expense_amount_fact =
      totals.q1.expense_amount_fact +
      totals[4].expense_amount_fact +
      totals[5].expense_amount_fact +
      totals[6].expense_amount_fact;
    totals.q2.income_amount_fact =
      totals.q1.income_amount_fact +
      totals[4].income_amount_fact +
      totals[5].income_amount_fact +
      totals[6].income_amount_fact;

    totals.q3.expense_amount =
      totals.q2.expense_amount +
      totals[7].expense_amount +
      totals[8].expense_amount +
      totals[9].expense_amount;
    totals.q3.income_amount =
      totals.q2.income_amount +
      totals[7].income_amount +
      totals[8].income_amount +
      totals[9].income_amount;
    totals.q3.expense_amount_fact =
      totals.q2.expense_amount_fact +
      totals[7].expense_amount_fact +
      totals[8].expense_amount_fact +
      totals[9].expense_amount_fact;
    totals.q3.income_amount_fact =
      totals.q2.income_amount_fact +
      totals[7].income_amount_fact +
      totals[8].income_amount_fact +
      totals[9].income_amount_fact;

    totals.q4.expense_amount =
      totals.q3.expense_amount +
      totals[10].expense_amount +
      totals[11].expense_amount +
      totals[12].expense_amount;
    totals.q4.income_amount =
      totals.q3.income_amount +
      totals[10].income_amount +
      totals[11].income_amount +
      totals[12].income_amount;
    totals.q4.expense_amount_fact =
      totals.q3.expense_amount_fact +
      totals[10].expense_amount_fact +
      totals[11].expense_amount_fact +
      totals[12].expense_amount_fact;
    totals.q4.income_amount_fact =
      totals.q3.income_amount_fact +
      totals[10].income_amount_fact +
      totals[11].income_amount_fact +
      totals[12].income_amount_fact;

    return totals;
  }

  saveBudgetItemsFromAccount(accounts: any) {
    let chain = this.$q.when();
    accounts.forEach((account: any) =>
      account.budget_items.forEach((item: any, key: any) => {
        const oldPlan = JSON.parse(account.budget_items_orig[key]).plan_amount;
        if (item.plan_amount != oldPlan) {
          item.plan_amount = item.plan_amount || 0;
          const saveFunc = item.id
            ? this.FinancesService.BudgetItem.update
            : this.FinancesService.BudgetItem.save;
          chain = chain.then(() => saveFunc(item).$promise.then(false, this.GtUtils.errorClb));
        }
      }),
    );
    return chain;
  }
}
BudgetsService.$inject = ['$resource', '$q', 'GtUtils', 'FinancesService'];
