import ng from 'angular';
import React from 'react';

import { mainApp } from './ajs';

export const GTLegacyComponent: React.FC = () => {
  const ajsRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    ng.element(() => {
      if (ajsRef.current) {
        // eslint-disable-next-line import/no-internal-modules
        void import('./ajs/styles.less');

        ng.bootstrap(ajsRef.current, [mainApp.name], { strictDi: true });
      }
    });
  }, []);

  return (
    <div
      id="ajs-root"
      ref={ajsRef}
      dangerouslySetInnerHTML={{ __html: '<ui-view>Loading...</ui-view>' }}
    ></div>
  );
};
