import { CertsLogisticRecord } from '~/features/execution/logistic';
import { CertsLogisticRepository } from '~/features/execution/logistic/certs/services';
import {
  BaseLogisticListContainerProps,
  BaseLogisticListModel,
} from '~/features/execution/logistic/common/components/logistic-container/base-logistic-list.model';
import { injectable } from '~/shared/lib/di';

export type CertsLogisticListContainerProps = BaseLogisticListContainerProps;

@injectable()
export class CertsLogisticListModel extends BaseLogisticListModel<
  CertsLogisticRecord,
  CertsLogisticRepository
> {
  entityRepoClass = CertsLogisticRepository;
}
