import ng from 'angular';

import { SampleControlListContainer } from './sample-control-list-container/sample-control-list-container.component';
import { SampleControlListTableView } from './sample-control-list-table-view/sample-control-list-table-view.component';
import { SampleControlPageView } from './sample-control-page-view/sample-control-page-view.component';
import { SampleControlService } from './sample-control.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const sampleControl = ng
  .module('execution.transport.sampleControl', [])
  .service('SampleControlService', SampleControlService)
  .component('sampleControlPageView', SampleControlPageView)
  .component('sampleControlListContainer', SampleControlListContainer)
  .component('sampleControlListTableView', SampleControlListTableView)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('logistics.sampleControl', {
        url: '/sample-control',
        component: 'sampleControlPageView',
        data: {
          pageTitle: gettext('Sample Control'),
          permissions: {
            only: 'view_logistic',
          },
        },
        resolve: {
          filterLevel: () => {
            return 'sample-control-page-view';
          },
          queryParams: [
            'PageService',
            (PageService: PageService) => {
              return PageService.syncUrlFilter('sample-control-page-view');
            },
          ],
        },
      });
    },
  ]).name;
