import type ng from 'angular';

import template from './bank-transaction-list-table-view.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { html } from '^/shared/utils';

export const BankTransactionListTableView = {
  bindings: {
    transactions: '<',
    filterLevel: '<',
    total: '<',
    openTransactionModal: '&',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    'gtFilterService',
    class {
      $rootScope: GtRootScopeService;
      count: number;
      filterLevel = 'bank-transaction-list-table-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      openTransactionModal: any;
      rows: any;
      tableData: any;
      tableOptions: any;
      total: any;
      transactions: any;
      constructor(
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
      ) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;

        this.transactions = undefined;
        this.total = undefined;
        this.count = 0;
        this.gtFilterService = gtFilterService;
      }

      $onInit() {
        this.transactions = [];
        this.total = {};
        this.count = 0;
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.transactions || changes.count || changes.total) {
          this.tableData = { rows: this.transactions, total: this.total };
        }
      }

      getTableOptions() {
        const options: any = {
          tableName: 'bankTransactions',
          tableClass: ['request-table'],
          filterLevel: this.filterLevel,
          configurable: true,
          applyFilters: (params: { params: QueryParams }) => {
            this.gtFilterService.updateQueryParams(params.params, this.filterLevel);
          },
          templateArgs: {
            rows: this.rows,
            transactions: this.transactions || [{}],
            queryParams: this.initQueryParams,
            $rootScope: this.$rootScope,
            openTransactionModal: (item: any) => this.openTransactionModal({ item: item }),
          },
          columnDefs: [] as any[],
          tabs: [],
        };
        options.columnDefs = [
          {
            columnName: 'number',
            title: this.gettext('Number'),
            cellTemplate: html`
              <a ng-click="args.editTransaction(item)">
                # <span>{[{ item.number | cut:true:25:' ...' }]}</span>
                <a
                  class="btn btn-xs btn-blue-border hover-element"
                  ng-click="args.openTransactionModal(item)"
                  style="display: inline-flex; padding: 2px !important;"
                >
                  <i class="fa fa-pencil-square"></i>
                </a>
              </a>
            `,
            predicate: 'number',
          },
          {
            columnName: 'transaction_date',
            title: this.gettext('Transaction Date'),
            predicate: 'transaction_date',
            cellTemplate: html`
              <span>
                <i class="fa fa-calendar"></i> {[{ item.transaction_date | date: 'dd.MM.yy' }]}
              </span>
            `,
          },
          {
            columnName: 'general_expenses_title',
            title: this.gettext('general expense'),
            predicate: 'general_expenses_title',
            cellTemplate: html`<span> {[{ item.general_expenses_title }]} </span> `,
          },
          {
            columnName: 'description',
            title: this.gettext('Description'),
            predicate: 'description',
            cellTemplate: html`<span> {[{ item.description }]} </span> `,
          },
          {
            columnName: 'Type',
            title: this.gettext('Outgoing/Incoming'),
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li><translate>Outgoing</translate></li>
                <li><translate>Incoming</translate></li>
              </ul>
            `,
          },

          {
            columnName: 'bank_account_title',
            title: this.gettext('Bank account'),
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>
                  <i class="fa fa-university"></i> {[{ item.outgoing_bank_account_title |
                  cut:true:30:' ...' }]}
                </li>
                <li>
                  <i class="fa fa-university"></i> {[{ item.incoming_bank_account_title |
                  cut:true:30:' ...' }]}
                </li>
              </ul>
            `,
          },
          {
            columnName: 'client_name',
            title: this.gettext('Bank client'),
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>
                  <i class="fa fa-building"></i> {[{ item.outgoing_client_name | cut:true:30:' ...'
                  }]}
                  <span class="smaller-label label-smoke" ng-if="item.outgoing_related_client_name"
                    ><i class="fa fa-building"></i> {[{ item.outgoing_related_client_name |
                    cut:true:30:' ...' }]}</span
                  >
                </li>
                <li>
                  <i class="fa fa-building"></i> {[{ item.incoming_client_name | cut:true:30:' ...'
                  }]}
                  <span class="smaller-label label-smoke" ng-if="item.incoming_related_client_name"
                    ><i class="fa fa-building"></i> {[{ item.incoming_related_client_name |
                    cut:true:30:' ...' }]}</span
                  >
                </li>
              </ul>
            `,
          },
          {
            columnName: 'amount',
            title: this.gettext('Amount'),
            predicate: 'outgoing_amount',
            class: 'td-right-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>{[{ item.outgoing_amount || 0 | number:2 }]}</li>
                <li>{[{ item.incoming_amount || 0 | number:2 }]}</li>
              </ul>
            `,
            totalBlockTemplates: [
              `
                <span class="label">
                  {[{ item.outgoing_amount || 0 | number:2 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>outgoing</translate> <translate>amount</translate>
                </span>
              `,
              `
                <span class="label">
                  {[{ item.incoming_amount || 0 | number:2 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>incoming</translate>  <translate>amount</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'business_unit',
            title: this.gettext('Business unit'),
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>
                  <i class="fa fa-home"></i> {[{ item.outgoing_businessunit_title || '---' |
                  cut:true:30:' ...' }]}
                </li>
                <li>
                  <i class="fa fa-home"></i> {[{ item.incoming_businessunit_title || '---' |
                  cut:true:30:' ...' }]}
                </li>
              </ul>
            `,
          },
          {
            columnName: 'date',
            title: this.gettext('Date'),
            predicate: 'outgoing_date',
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>
                  <i class="fa fa-calendar"></i> {[{ item.outgoing_date || '---' | date:
                  'dd.MM.yy'}]}
                </li>
                <li>
                  <i class="fa fa-calendar"></i> {[{ item.incoming_date || '---' | date:
                  'dd.MM.yy'}]}
                </li>
              </ul>
            `,
          },
          {
            columnName: 'currency_symbol',
            title: this.gettext('Currency'),
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>
                  {[{ item.outgoing_currency_symbol || '---' | cut:true:30:' ...'}]} {[{
                  item.outgoing_currency_exchange_rate || 0 | number:4}]}
                </li>
                <li>
                  {[{ item.incoming_currency_symbol || '---' | cut:true:30:' ...'}]} {[{
                  item.incoming_currency_exchange_rate || 0 | number:4 }]}
                </li>
              </ul>
            `,
          },
          {
            columnName: 'contract_number',
            title: this.gettext('Contract number'),
            predicate: 'outgoing_contract_number',
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>
                  <i class="fa fa-file-text"></i> {[{ item.outgoing_contract_number || '---' |
                  cut:true:30:' ...'}]}
                </li>
                <li>
                  <i class="fa fa-file-text"></i> {[{ item.incoming_contract_number || '---' |
                  cut:true:30:' ...'}]}
                </li>
              </ul>
            `,
          },
          {
            columnName: 'passport_title',
            title: this.gettext('Passport title'),
            predicate: 'outgoing_passport_title',
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>
                  <i class="fa fa-exchange"></i> {[{ item.outgoing_passport_title || '---' |
                  cut:true:30:' ...'}]}
                </li>
                <li>
                  <i class="fa fa-exchange"></i> {[{ item.incoming_passport_title || '---' |
                  cut:true:30:' ...'}]}
                </li>
              </ul>
            `,
          },
          {
            columnName: 'address_title',
            title: this.gettext('Address'),
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>
                  <i class="fa fa-location-arrow"></i> {[{ item.outgoing_address_title || '---' |
                  cut:true:30:' ...'}]}
                </li>
                <li>
                  <i class="fa fa-location-arrow"></i> {[{ item.incoming_address_title || '---' |
                  cut:true:30:' ...'}]}
                </li>
              </ul>
            `,
          },
          {
            columnName: 'issuance_fee_amount',
            title: this.gettext('Issuance fee'),
            predicate: 'outgoing_issuance_fee_amount',
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>{[{ item.outgoing_issuance_fee_amount || 0 | number:2 }]}</li>
                <li>{[{ item.incoming_issuance_fee_amount || 0 | number:2 }]}</li>
              </ul>
            `,
            totalBlockTemplates: [
              `
                <span class="label">
                  {[{ item.outgoing_issuance_fee_amount || 0 | number:2 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>outgoing</translate> <translate>issuance fee</translate>
                </span>
              `,
              `
                <span class="label">
                  {[{ item.incoming_issuance_fee_amount || 0 | number:2 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>incoming</translate>  <translate>issuance fee</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'cashback_amount',
            title: this.gettext('Cashback'),
            predicate: 'outgoing_cashback_amount',
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>{[{ item.outgoing_cashback_amount || 0 | number:2 }]}</li>
                <li>{[{ item.incoming_cashback_amount || 0 | number:2 }]}</li>
              </ul>
            `,
            totalBlockTemplates: [
              `
                <span class="label">
                  {[{ item.outgoing_cashback_amount || 0 | number:2 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>outgoing</translate> <translate>cashback</translate>
                </span>
              `,
              `
                <span class="label">
                  {[{ item.incoming_cashback_amount || 0 | number:2 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>incoming</translate>  <translate>cashback</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'invoices_number_list',
            title: this.gettext('Invoices'),
            class: 'td-left-align',
            cellTemplate: html`
              <ul class="ul-inside-table">
                <li>
                  <span ng-repeat="item in item.outgoing_invoices_number_list">
                    {[{ item || '---' | cut:true:30:' ...' }]}
                  </span>
                </li>
                <li>
                  <span ng-repeat="item in item.incoming_invoices_number_list">
                    {[{ item || '---' | cut:true:30:' ...' }]}
                  </span>
                </li>
              </ul>
            `,
          },

          {
            columnName: 'info',
            title: this.gettext('Info'),
            class: ' td-left-align',
            cellTemplate: html`
              <span
                ng-class="{tooltip: item.additional_info, 'label-opacity': !item.additional_info}"
                data-tip="{[{item.additional_info}]}"
              >
                <i class="fa fa-comment"></i> {[{item.additional_info || '---' | cut:true:30:'
                ...'}]}
              </span>
            `,
          },
        ];

        return options;
      }
    },
  ],
};
