import type ng from 'angular';

import template from './analytics-container.html?raw';
import type { ContractsAnalyticsService } from '../analytics.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';

export const AnalyticsContainer = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gettext',
    'ContractsAnalyticsService',
    'gtFilterService',
    class {
      ContractsAnalyticsService: ContractsAnalyticsService;
      byCropYearChartConfig: any;
      byCropYearTableOptions: any;
      byDestinationChartConfig: any;
      byDestinationTableOptions: any;
      byOriginChartConfig: any;
      byOriginTableOptions: any;
      dealsByConclusionDateChartConfig: any;
      filterLevel = 'contracts-analytics-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      priceExecutionChartConfig: any;
      purchaseByBasisChartConfig: any;
      purchaseByBasisTableOptions: any;
      purchaseByBrokerChartConfig: any;
      purchaseByBrokerTableOptions: any;
      purchaseByElevatorChartConfig: any;
      purchaseByElevatorTableOptions: any;
      purchaseByPortChartConfig: any;
      purchaseByPortTableOptions: any;
      purchaseByStatusChartConfig: any;
      purchaseByStatusTableOptions: any;
      queryParams: QueryParams = {};
      saleByBasisChartConfig: any;
      saleByBasisTableOptions: any;
      saleByBrokerChartConfig: any;
      saleByBrokerTableOptions: any;
      saleByElevatorChartConfig: any;
      saleByElevatorTableOptions: any;
      saleByPortChartConfig: any;
      saleByPortTableOptions: any;
      saleByStatusChartConfig: any;
      saleByStatusTableOptions: any;
      valueExecutionChartConfig: any;
      volumeExecutionChartConfig: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        ContractsAnalyticsService: ContractsAnalyticsService,
        gtFilterService: GtFilterService,
      ) {
        this.gettext = gettext;
        this.ContractsAnalyticsService = ContractsAnalyticsService;
        this.gtFilterService = gtFilterService;

        this.queryParams = {};

        this.volumeExecutionChartConfig = undefined;
        this.valueExecutionChartConfig = undefined;
        this.priceExecutionChartConfig = undefined;
        this.dealsByConclusionDateChartConfig = undefined;

        this.saleByPortChartConfig = undefined;
        this.saleByPortTableOptions = {};
        this.purchaseByPortChartConfig = undefined;
        this.purchaseByPortTableOptions = {};

        this.saleByElevatorChartConfig = undefined;
        this.saleByElevatorTableOptions = {};
        this.purchaseByElevatorChartConfig = undefined;
        this.purchaseByElevatorTableOptions = {};

        this.saleByBrokerChartConfig = undefined;
        this.saleByBrokerTableOptions = {};
        this.purchaseByBrokerChartConfig = undefined;
        this.purchaseByBrokerTableOptions = {};

        this.saleByBasisChartConfig = undefined;
        this.saleByBasisTableOptions = {};

        this.purchaseByBasisChartConfig = undefined;
        this.purchaseByBasisTableOptions = {};

        this.byCropYearChartConfig = undefined;
        this.byCropYearTableOptions = {};

        this.byOriginChartConfig = undefined;
        this.byOriginTableOptions = {};

        this.byDestinationChartConfig = undefined;
        this.byDestinationTableOptions = {};

        this.saleByStatusChartConfig = undefined;
        this.saleByStatusTableOptions = {};
        this.purchaseByStatusChartConfig = undefined;
        this.purchaseByStatusTableOptions = {};
      }

      $onInit() {
        this.gtFilterService.subscribe(
          this.filterLevel,
          (queryParams: QueryParams) => {
            this.queryParams = queryParams;
            this.updateAnalyticsData();
          },
          this.queryParams,
        );
        this.updateAnalyticsData();
      }

      updateAnalyticsData() {
        this.ContractsAnalyticsService.getAnalyticsData(this.queryParams);
        this.updateVolumeExecutionChartConfig();
        this.updatePriceExecutionChartConfig();
        this.updateValueExecutionChartConfig();
        this.updateDealsByConclusionDateChartConfig();

        this.updateSaleByPortChartConfig();
        this.saleByPortTableOptions = this.getSaleByPortTableOptions();

        this.updatePurchaseByPortChartConfig();
        this.purchaseByPortTableOptions = this.getPurchaseByPortTableOptions();

        this.updateSaleByElevatorChartConfig();
        this.saleByElevatorTableOptions = this.getSaleByElevatorTableOptions();

        this.updatePurchaseByElevatorChartConfig();
        this.purchaseByElevatorTableOptions = this.getPurchaseByElevatorTableOptions();

        this.updateSaleByBrokerChartConfig();
        this.saleByBrokerTableOptions = this.getSaleByBrokerTableOptions();

        this.updatePurchaseByBrokerChartConfig();
        this.purchaseByBrokerTableOptions = this.getPurchaseByBrokerTableOptions();

        this.updateSaleByBasisChartConfig();
        this.saleByBasisTableOptions = this.getSaleByBasisTableOptions();

        this.updatePurchaseByBasisChartConfig();
        this.purchaseByBasisTableOptions = this.getPurchaseByBasisTableOptions();

        this.updateByCropYearChartConfig();
        this.byCropYearTableOptions = this.getByCropYearTableOptions();

        this.updateByOriginChartConfig();
        this.byOriginTableOptions = this.getByOriginTableOptions();

        this.updateByDestinationChartConfig();
        this.byDestinationTableOptions = this.getByDestinationTableOptions();

        this.updateSaleByStatusChartConfig();
        this.saleByStatusTableOptions = this.getSaleByStatusTableOptions();

        this.updatePurchaseByStatusChartConfig();
        this.purchaseByStatusTableOptions = this.getPurchaseByStatusTableOptions();
      }

      updateVolumeExecutionChartConfig() {
        this.ContractsAnalyticsService.getVolumeExecutionChartConfig().then(
          (data: any) => (this.volumeExecutionChartConfig = data),
        );
      }

      updatePriceExecutionChartConfig() {
        this.ContractsAnalyticsService.getPriceExecutionChartConfig().then(
          (data: any) => (this.priceExecutionChartConfig = data),
        );
      }

      updateValueExecutionChartConfig() {
        this.ContractsAnalyticsService.getValueExecutionChartConfig().then(
          (data: any) => (this.valueExecutionChartConfig = data),
        );
      }

      updateDealsByConclusionDateChartConfig() {
        this.ContractsAnalyticsService.getDealsByConclusionDateChartConfig().then(
          (data: any) => (this.dealsByConclusionDateChartConfig = data),
        );
      }

      // port
      updateSaleByPortChartConfig() {
        this.ContractsAnalyticsService.getSaleByPortChartConfig().then(
          (data: any) => (this.saleByPortChartConfig = data),
        );
      }

      getSaleByPortTableOptions() {
        return {
          tableName: 'sale_by_port',
          filterLevel: 'sale_by_port',
          columnDefs: [
            {
              columnName: 'ports__name',
              title: this.gettext('port'),
            },
            {
              columnName: 'sale_volume',
              cellTemplate: '{[{ item.sale_volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getSaleByPortData().then((d: any) => api.setRowData(d)),
        };
      }

      updatePurchaseByPortChartConfig() {
        this.ContractsAnalyticsService.getPurchaseByPortChartConfig().then(
          (data: any) => (this.purchaseByPortChartConfig = data),
        );
      }

      getPurchaseByPortTableOptions() {
        return {
          tableName: 'sale_by_port',
          filterLevel: 'sale_by_port',
          columnDefs: [
            {
              columnName: 'ports__name',
              title: this.gettext('port'),
            },
            {
              columnName: 'purchase_volume',
              cellTemplate: '{[{ item.purchase_volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getPurchaseByPortData().then((d: any) =>
              api.setRowData(d),
            ),
        };
      }

      // elevator
      updateSaleByElevatorChartConfig() {
        this.ContractsAnalyticsService.getSaleByElevatorChartConfig().then(
          (data: any) => (this.saleByElevatorChartConfig = data),
        );
      }

      getSaleByElevatorTableOptions() {
        return {
          tableName: 'sale_by_port',
          filterLevel: 'sale_by_port',
          columnDefs: [
            {
              columnName: 'elevator__name',
              title: this.gettext('elevator'),
            },
            {
              columnName: 'sale_volume',
              cellTemplate: '{[{ item.sale_volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getSaleByElevatorData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      updatePurchaseByElevatorChartConfig() {
        this.ContractsAnalyticsService.getPurchaseByElevatorChartConfig().then(
          (data: any) => (this.purchaseByElevatorChartConfig = data),
        );
      }

      getPurchaseByElevatorTableOptions() {
        return {
          tableName: 'sale_by_elevator',
          filterLevel: 'sale_by_elevator',
          columnDefs: [
            {
              columnName: 'elevator__name',
              title: this.gettext('elevator'),
            },
            {
              columnName: 'purchase_volume',
              cellTemplate: '{[{ item.purchase_volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getPurchaseByElevatorData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      // broker
      updateSaleByBrokerChartConfig() {
        this.ContractsAnalyticsService.getSaleByBrokerChartConfig().then(
          (data: any) => (this.saleByBrokerChartConfig = data),
        );
      }

      getSaleByBrokerTableOptions() {
        return {
          tableName: 'sale_by_port',
          filterLevel: 'sale_by_port',
          columnDefs: [
            {
              columnName: 'broker__name',
              title: this.gettext('broker'),
            },
            {
              columnName: 'sale_volume',
              cellTemplate: '{[{ item.sale_volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getSaleByBrokerData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      updatePurchaseByBrokerChartConfig() {
        this.ContractsAnalyticsService.getPurchaseByBrokerChartConfig().then(
          (data: any) => (this.purchaseByBrokerChartConfig = data),
        );
      }

      getPurchaseByBrokerTableOptions() {
        return {
          tableName: 'sale_by_broker',
          filterLevel: 'sale_by_broker',
          columnDefs: [
            {
              columnName: 'broker__name',
              title: this.gettext('broker'),
            },
            {
              columnName: 'purchase_volume',
              cellTemplate: '{[{ item.purchase_volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getPurchaseByBrokerData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      // basis
      updateSaleByBasisChartConfig() {
        this.ContractsAnalyticsService.getSaleByBasisChartConfig().then(
          (data: any) => (this.saleByBasisChartConfig = data),
        );
      }

      getSaleByBasisTableOptions() {
        return {
          tableName: 'by_basis',
          filterLevel: 'by_basis',
          columnDefs: [
            {
              columnName: 'basis__name',
              title: this.gettext('basis'),
            },
            {
              columnName: 'sale_volume',
              cellTemplate: '{[{ item.sale_volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getSaleByBasisChartConfig().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      updatePurchaseByBasisChartConfig() {
        this.ContractsAnalyticsService.getPurchaseByBasisChartConfig().then(
          (data: any) => (this.purchaseByBasisChartConfig = data),
        );
      }

      getPurchaseByBasisTableOptions() {
        return {
          tableName: 'by_basis',
          filterLevel: 'by_basis',
          columnDefs: [
            {
              columnName: 'basis__name',
              title: this.gettext('basis'),
            },
            {
              columnName: 'purchase_volume',
              cellTemplate: '{[{ item.purchase_volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getSaleByBasisChartConfig().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      // crop year
      updateByCropYearChartConfig() {
        this.ContractsAnalyticsService.getByCropYearChartConfig().then(
          (data: any) => (this.byCropYearChartConfig = data),
        );
      }

      getByCropYearTableOptions() {
        return {
          tableName: 'by_crop_year',
          filterLevel: 'by_crop_year',
          columnDefs: [
            {
              columnName: 'crop_year',
              title: this.gettext('crop year'),
            },
            {
              columnName: 'volume',
              cellTemplate: '{[{ item.volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getByCropYearData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      // origin
      updateByOriginChartConfig() {
        this.ContractsAnalyticsService.getByOriginChartConfig().then(
          (data: any) => (this.byOriginChartConfig = data),
        );
      }

      getByOriginTableOptions() {
        return {
          tableName: 'by_crop_year',
          filterLevel: 'by_crop_year',
          columnDefs: [
            {
              columnName: 'origin_of_crop__title',
              title: this.gettext('origin'),
            },
            {
              columnName: 'volume',
              cellTemplate: '{[{ item.volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getByOriginData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      // destination
      updateByDestinationChartConfig() {
        this.ContractsAnalyticsService.getByDestinationChartConfig().then(
          (data: any) => (this.byDestinationChartConfig = data),
        );
      }

      getByDestinationTableOptions() {
        return {
          tableName: 'by_crop_year',
          filterLevel: 'by_crop_year',
          columnDefs: [
            {
              columnName: 'destination_of_crop__title',
              title: this.gettext('destination'),
            },
            {
              columnName: 'volume',
              cellTemplate: '{[{ item.volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getByDestinationData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      // status
      updateSaleByStatusChartConfig() {
        this.ContractsAnalyticsService.getSaleByStatusChartConfig().then(
          (data: any) => (this.saleByStatusChartConfig = data),
        );
      }

      getSaleByStatusTableOptions() {
        return {
          tableName: 'sale_by_port',
          filterLevel: 'sale_by_port',
          columnDefs: [
            {
              columnName: 'status',
              title: this.gettext('status'),
            },
            {
              columnName: 'sale_volume',
              cellTemplate: '{[{ item.sale_volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
            {
              columnName: 'sale_count',
              title: this.gettext('count'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getSaleByStatusData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      updatePurchaseByStatusChartConfig() {
        this.ContractsAnalyticsService.getPurchaseByStatusChartConfig().then(
          (data: any) => (this.purchaseByStatusChartConfig = data),
        );
      }

      getPurchaseByStatusTableOptions() {
        return {
          tableName: 'sale_by_status',
          filterLevel: 'sale_by_status',
          columnDefs: [
            {
              columnName: 'status',
              title: this.gettext('status'),
            },
            {
              columnName: 'purchase_volume',
              cellTemplate: '{[{ item.purchase_volume || 0 | number:2  }]}',
              title: this.gettext('volume'),
            },
            {
              columnName: 'purchase_count',
              title: this.gettext('count'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.ContractsAnalyticsService.getPurchaseByStatusData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }
    },
  ],
};
