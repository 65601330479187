import React from 'react';

import { Link } from '~/shared/lib/router';
import { cn } from '~/shared/lib/utils';
import { ChevronRightIcon } from '~/shared/ui/icons';

export const PageNavigation: React.FC<{
  href: string;
  isActive: boolean;
  label: string;
}> = ({ href, isActive, label }) => {
  return (
    <Link
      to={href}
      className="group text-text-main-secondary hover:bg-transparent-light flex w-full items-center gap-2 rounded-sm py-0.5 pl-4 font-normal transition duration-300 ease-in-out"
      activeProps={{ className: 'bg-transparent-light' }}
      activeOptions={{ exact: true }}
    >
      <ChevronRightIcon
        size={16}
        className={cn(
          'text-stroke-main-strong hover:bg-background-main-quarternary group-hover:text-stroke-main-stronger rounded transition duration-300 ease-in-out',
          isActive && 'text-stroke-main-inverted rotate-90',
        )}
      />
      <span
        className={cn(
          'text-text-main-secondary group-hover:text-text-main-secondary text-xs font-normal transition duration-300 ease-in-out',
          isActive && 'text-text-main-primary font-medium',
        )}
      >
        {label}
      </span>
    </Link>
  );
};
