import React from 'react';

import { useComponentModel } from '~/shared/lib/components';
import { errorHandler } from '~/shared/lib/errors';
import { useForm } from '~/shared/lib/forms';
import { useTranslation } from '~/shared/lib/i18n';
import { useObservableEagerState } from '~/shared/lib/state';
import { cn } from '~/shared/lib/utils';
import { BucketIcon, CloseIcon, CopyIcon, FloppyDiskIcon } from '~/shared/ui/icons';
import { Input } from '~/shared/ui/kit/input';

import { ColumnsDndContainer } from './columns-dnd-container';
import type { DatasetViewsModel } from './dataset-views.model';
import type { ColumnsConfig } from '../../lib/types';

export const ConfigModal = ({ config }: { config: ColumnsConfig }) => {
  const model = useComponentModel<DatasetViewsModel>();

  const { t } = useTranslation();
  const loading = useObservableEagerState(model.loading$);
  const setEditingHandler = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      model.setEditingColumnsConfig(null);
    },
    [model],
  );
  const editConfigTitleHandler = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      e.stopPropagation();
      model.editColumnsConfig({ title: e.target.value });
    },
    [model],
  );
  const duplicateConfigHandler = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      model.duplicateColumnsConfig();
    },
    [model],
  );
  const deleteConfigHandler = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      model.deleteColumnsConfig();
    },
    [model],
  );

  const form = useForm({
    defaultValues: config,
    onSubmit: () => model.saveColumnsConfig(),
  });
  return (
    <div className="max-w-[400px] min-w-[300px] rounded-lg">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit().catch(errorHandler);
        }}
        className="w-full"
      >
        <span
          className={cn(
            'border-stroke-main-light flex gap-2 border-b-1 py-2 pl-2',
            loading ? 'pointer-events-none animate-pulse' : '',
          )}
        >
          <button disabled={loading} onClick={setEditingHandler}>
            <CloseIcon className="text-text-main-tertiary transition hover:opacity-50" />
          </button>
          <div className="text-text-main-secondary">
            {config.id < 0 ? t('Add View') : config.title}
          </div>
        </span>
        <div className={cn('mt-2 mb-1 flex w-full gap-2 px-2', loading ? 'animate-pulse' : '')}>
          <form.Field name="title">
            {(field) => (
              <Input
                disabled={loading}
                placeholder="View title"
                intent={field.state.meta.errors.length ? 'danger' : 'default'}
                name={field.name}
                defaultValue={config.title}
                onBlur={editConfigTitleHandler}
                fullWidth
                required
              />
            )}
          </form.Field>
        </div>
        <div className="border-stroke-main-light border-t"></div>
        <div className="mt-2 mb-2 ml-2 flex w-full flex-col gap-2">
          <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
            {([canSubmit]) => (
              <button
                disabled={!canSubmit || loading}
                type="submit"
                className={cn(
                  'text-text-main-primary inline-flex items-center gap-2',
                  canSubmit && !loading
                    ? 'rounded-lg transition duration-200 ease-in-out hover:opacity-50'
                    : 'opacity-50',
                )}
              >
                <FloppyDiskIcon /> <span>Save View</span>
              </button>
            )}
          </form.Subscribe>
          <button
            className={cn(
              'text-text-main-primary inline-flex items-center gap-2 rounded-lg transition duration-200 ease-in-out hover:opacity-50',
              loading ? 'pointer-events-none opacity-50' : '',
            )}
            disabled={loading}
            onClick={duplicateConfigHandler}
          >
            <CopyIcon /> <span>Duplicate View</span>
          </button>
          <button
            disabled={loading}
            className={cn(
              'text-text-additional-danger inline-flex items-center gap-2 rounded-lg transition duration-200 ease-in-out hover:opacity-50',
              loading ? 'pointer-events-none opacity-50' : '',
            )}
            onClick={deleteConfigHandler}
          >
            <BucketIcon /> <span>Delete View</span>
          </button>
        </div>
      </form>
      <ColumnsDndContainer />
    </div>
  );
};
