import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import {
  Charge,
  ChargePatch,
  ChargePost,
  ChargeRecord,
  ChargesQueryParams,
} from '~/features/finances/contract-charges';
import { injectable } from '~/shared/lib/di';
import { shareReplay, startWith, switchMap, tap } from '~/shared/lib/state';

import { ChargesRepository } from './charges.repository';

export type ChargesListContainerProps = ContainerProps<ChargesQueryParams>;

@injectable()
export class ChargesListViewModel extends EntityListContainerModel<
  Charge,
  ChargesQueryParams,
  ChargesRepository,
  ChargesListContainerProps,
  ChargeRecord,
  ChargePost,
  ChargePatch
> {
  entityRepoClass = ChargesRepository;

  readonly pageData$ = this.pageParamsSubj.pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap((params) => this.repo.query(params)),
    tap(() => this.loadingSubj.next(false)),
    shareReplay({ bufferSize: 1, refCount: false }),
    startWith({ count: 0, records: [], totals: [] }),
  );
}
