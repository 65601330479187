import type ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import template from './contract-status-page.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const ContractStatusPage = {
  bindings: {},
  template,
  controller: [
    'PageService',
    'ReportsService',
    'CoreService',
    'gtFilterService',
    'GtUtils',
    'gettext',
    '$rootScope',
    '$timeout',
    '$scope',
    '$window',
    '$httpParamSerializer',
    class {
      $httpParamSerializer: any;
      $scope: ng.IScope;
      $timeout: ng.ITimeoutService;
      $window: ng.IWindowService;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      contractStatuses: any;
      cropYears: any;
      datepicker: any;
      datepickerConfig: any;
      defaultQueryParams: QueryParams = {};
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      ReportsService: ReportsService;
      rootScope: any;
      constructor(
        PageService: PageService,
        ReportsService: ReportsService,
        CoreService: CoreService,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        $rootScope: GtRootScopeService,
        $timeout: ng.ITimeoutService,
        $scope: ng.IScope,
        $window: ng.IWindowService,
        $httpParamSerializer: any,
      ) {
        this.gettext = gettext;
        this.rootScope = $rootScope;
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.CoreService = CoreService;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.$window = $window;
        this.$httpParamSerializer = $httpParamSerializer;
        this.queryParams = {};
        this.filterLevel = '';
        this.datepicker = false;
        this.defaultQueryParams = { date_predicate: 'period_of_execution' };
        this.cropYears = [];
        this.contractStatuses = [];
      }

      $onInit() {
        this.filterLevel = 'contract-status-page-view';
        this.PageService.setConfig(this.getPageConfig());
        this.datepickerConfig = this.getDatePickerConfig();
        this.defaultQueryParams = {
          status_list: ['new', 'delivery_period', 'shipment', 'approved', 'cargo_executed'],
          crop_year_list: [this.rootScope.user.settings.DEFAULT_VALUES.crop_year],
          contract_type: 'sale',
          business_unit_list: this.CoreService.getBuList(),
          start_date_of_execution: undefined,
          end_date_of_execution: undefined,
        };
        this.queryParams = { ...this.defaultQueryParams };
        this.cropYears = this.GtUtils.getYearList();
        this.contractStatuses = [
          { id: 'washout', title: this.gettext('Washout') },
          { id: 'new', title: this.gettext('New') },
          { id: 'delivery_period', title: this.gettext('Delivery period') },
          { id: 'shipment', title: this.gettext('Shipment') },
          { id: 'cargo_executed', title: this.gettext('Cargo executed') },
          { id: 'executed', title: this.gettext('Executed') },
          { id: 'approved', title: this.gettext('Approved') },
          { id: 'cancelled', title: this.gettext('Cancelled') },
        ];
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      getDatePickerConfig() {
        const config = this.gtFilterService.getDateRangePickerConfig();
        config.eventHandlers = {
          'apply.daterangepicker': () => {
            this.$timeout(() => {
              this.setDates();
            });
          },
          'cancel.daterangepicker': () => {
            this.$timeout(() => {
              this.datepicker = false;
              this.setDates();
            });
          },
        };
        return config;
      }

      setDates() {
        if (!this.datepicker) {
          this.queryParams.start_date_of_execution = undefined;
          this.queryParams.end_date_of_execution = undefined;
        } else {
          this.queryParams.start_date_of_execution = formatDate(
            this.datepicker.startDate,
            'yyyy-MM-dd',
          );
          this.queryParams.end_date_of_execution = formatDate(
            this.datepicker.endDate,
            'yyyy-MM-dd',
          );
        }
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilter() {
        return this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      resetFilter() {
        this.queryParams = { ...this.defaultQueryParams };
        this.datepicker = false;
        this.applyFilter();
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [new this.PageService.buttons.Print(), new this.PageService.buttons.Refresh()],
          pages: this.ReportsService.getPagesConfig(),
        };
      }

      downloadXlsxReport() {
        this.$window.open(
          `/api/reports/contract-status/?${this.$httpParamSerializer({
            ...this.gtFilterService.getQueryParams(this.filterLevel),
            as_xlsx: 1,
            page_size: 999,
          })}`,
          '_blank',
        );
      }
    },
  ],
};
