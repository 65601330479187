import ng from 'angular';

import template from './passport-details-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const passportDetailsPage = {
  bindings: {
    filterLevel: '<',
  },
  template,
  controller: [
    '$stateParams',
    '$scope',
    'PageService',
    'PassportFilterConfigService',
    class {
      $scope: ng.IScope;
      $stateParams: ng.ui.IStateParamsService;
      PageService: PageService;
      PassportFilterConfigService: any;
      filterLevel = 'passport-details-page';
      params: object;
      passport: any;
      updating: any;
      constructor(
        $stateParams: ng.ui.IStateParamsService,
        $scope: ng.IScope,
        PageService: PageService,
        PassportFilterConfigService: any,
      ) {
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.PageService = PageService;
        this.PassportFilterConfigService = PassportFilterConfigService;

        this.passport = {};
        this.params = {};
        this.updating = true;
      }

      $onInit() {
        this.passport = { id: this.$stateParams.id };
        const config = this.PageService.getConfig();
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            filters: this.PassportFilterConfigService.getFilterConfig(this.filterLevel),
            pages: [],
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            filters: this.PassportFilterConfigService.getFilterConfig(this.filterLevel),
            pages: [],
          });
        }
      }

      updateConfigWithFilterLevel(passport: any, filterLevel: string) {
        const config = this.PageService.getConfig();
        let isOpen;

        if (ng.equals(config, {}) || config.sidebar?.is_open) {
          isOpen = true;
        } else {
          isOpen = false;
        }
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            sidebar: {
              verboseName: 'Passports',
              modelName: 'passports.passport',
              instance: passport,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            sidebar: {
              verboseName: 'Passports',
              modelName: 'passports.passport',
              instance: passport,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
            filters: this.PassportFilterConfigService.getFilterConfig(filterLevel),
            pages: [],
          });
        }
      }
    },
  ],
};
