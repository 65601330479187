import { PurchaseLogisticRecord, PurchaseLogisticRepository } from '~/features/execution/logistic';
import {
  BaseLogisticListContainerProps,
  BaseLogisticListModel,
} from '~/features/execution/logistic/common/components/logistic-container/base-logistic-list.model';
import { injectable } from '~/shared/lib/di';

export type PurchaseLogisticListContainerProps = BaseLogisticListContainerProps;

@injectable()
export class PurchaseLogisticListModel extends BaseLogisticListModel<
  PurchaseLogisticRecord,
  PurchaseLogisticRepository
> {
  entityRepoClass = PurchaseLogisticRepository;
}
