import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './market-prices-table.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { MarketPricesService } from '^/app/finances/stock-exchange/stock-market-prices/market-prices.service';
import { html } from '^/shared/utils';

class MarketPricesTableController implements ng.IController {
  filterLevel?: string;

  form: any = null;
  m2mPriceDetailing = 'month';
  prices = [];
  pricesCount = 0;
  queryParams: QueryParams = {
    page_size: 25,
    ordering: '-update_time',
  };
  tableData = {};
  tableOptions: any;

  static readonly $inject = [
    '$rootScope',
    'gettext',
    'MarketPricesService',
    'GtUtils',
    'gtFilterService',
  ];
  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly MarketPricesService: MarketPricesService,
    private readonly GtUtils: GtUtilsService,
    private readonly gtFilterService: GtFilterService,
  ) {
    this.m2mPriceDetailing = this.$rootScope.user.settings.MTM_PRICES_DETAILING;
  }

  $onInit() {
    this.filterLevel = this.filterLevel ?? 'market-prices-table';
    this.tableOptions = this.getTableOptions();

    this.gtFilterService.subscribe(
      this.filterLevel,
      (params) => {
        this.queryParams = params;
        this.queryParams.ordering = this.queryParams.ordering ?? '-update_time';
        this.updateTableData();
      },
      this.queryParams,
    );
    this.updateTableData();
  }

  save() {
    if (this.form.$invalid) {
      return notify(this.gettext('Form invalid'), 'error');
    }
    this.form.indication_date = this.form.current_date || this.form.period;

    if (!this.form.indication_date) {
      notify(this.gettext('Please select a date'), 'error');
    }
    return this.MarketPricesService.create(this.form)
      .then(() => {
        this.gtFilterService.updateQueryParams({}, this.filterLevel);
      })
      .catch(this.GtUtils.errorClb);
  }

  updateTableData() {
    this.GtUtils.overlay('show');
    this.MarketPricesService.query(this.queryParams).then(
      (data: any) => {
        this.tableData = { rows: data.results, count: data.count, total: {} };
        this.GtUtils.overlay('hide');
      },
      () => {
        notify(this.gettext('Something went wrong...'), 'error');
        this.GtUtils.overlay('hide');
      },
    );
  }

  update(price: any) {
    return this.MarketPricesService.update(price)
      .then(() => {
        notify(this.gettext('Market price updated'));
        this.gtFilterService.updateQueryParams({}, this.filterLevel);
      })
      .catch(this.GtUtils.errorClb);
  }

  delete(price: any) {
    return this.MarketPricesService.delete(price)
      .then(() => {
        notify(this.gettext('Market price deleted'));
        this.gtFilterService.updateQueryParams({}, this.filterLevel);
      })
      .catch(this.GtUtils.errorClb);
  }

  getTableOptions() {
    return {
      tableName: 'market-prices',
      configurable: true,
      tableClass: ['indicators-table', 'table-hover'],
      filterLevel: this.filterLevel,
      applyFilters: (args: any) =>
        this.gtFilterService.updateQueryParams(args.params, this.filterLevel),
      templateArgs: {
        m2mPriceDetailing: this.m2mPriceDetailing,
        dateFormat: this.m2mPriceDetailing === 'month' ? 'MMM yy' : 'dd.MM.yy',
        update: (item: any) => this.update(item),
        delete: (item: any) => this.delete(item),
      },
      columnDefs: [
        {
          columnName: 'commodity',
          filters: [
            {
              type: 'ui-multiselect',
              predicate: 'cargo_list',
              label: this.gettext('commodity'),
              resource: 'crops.Crop',
            },
          ],
          cellTemplate: html`
            <div ng-if="!item._$edit">{[{ item.cargo_title || '---' }]}</div>
            <div ng-if="item._$edit">
              <gt-resource-select
                ng-model="item.cargo"
                placeholder="'Commodity'|translate"
                resource-name="'crops.Crop'"
                allow-clear="true"
                required
              ></gt-resource-select>
            </div>
          `,
          title: this.gettext('Commodity'),
        },
        {
          columnName: 'port',
          filters: [
            {
              type: 'ui-multiselect',
              predicate: 'port_list',
              label: this.gettext('port'),
              resource: 'logistics.port',
            },
          ],
          predicate: 'port',
          cellTemplate: html`
            <div ng-if="!item._$edit">{[{ item.port_name | cut:true:35:' ' || '---' }]}</div>
            <div ng-if="item._$edit">
              <gt-resource-select
                ng-model="item.port"
                placeholder="'Port'|translate"
                resource-name="'logistics.Port'"
                allow-clear="true"
                required
              ></gt-resource-select>
            </div>
          `,
          title: this.gettext('Port'),
        },
        {
          columnName: 'basis',
          filters: [
            {
              type: 'ui-multiselect',
              predicate: 'basis_list',
              label: this.gettext('basis'),
              resource: 'logistics.basis',
            },
          ],
          predicate: 'basis',
          cellTemplate: html`
            <div ng-if="!item._$edit">{[{ item.basis_name || '---' }]}</div>
            <div ng-if="item._$edit">
              <gt-resource-select
                ng-model="item.basis"
                placeholder="'Basis'|translate"
                resource-name="'logistics.Basis'"
                allow-clear="true"
                required
              ></gt-resource-select>
            </div>
          `,
          title: this.gettext('Basis'),
        },
        {
          columnName: 'indication_date',
          predicate: 'indication_date',
          filters: [
            {
              type: 'daterange',
              startDateField: 'indication_date_start_date',
              endDateField: 'indication_date_end_date',
            },
          ],
          cellTemplate: html`
            <div ng-if="!item._$edit">
              <i class="fa fa-calendar"></i> {[{ item.indication_date | date:args.dateFormat ||
              '---' }]}
            </div>
            <div ng-if="item._$edit">
              <gt-date-select
                date-model="item.indication_date"
                placeholder="'Date' | translate"
                start-view="args.m2mPriceDetailing"
                min-view="args.m2mPriceDetailing"
              ></gt-date-select>
            </div>
          `,
          title: this.gettext('Date'),
        },
        {
          columnName: 'create_time',
          predicate: 'create_time',
          cellTemplate: html`
            <div ng-if="!item._$edit">
              <i class="fa fa-calendar"></i> {[{ item.create_time | date:'dd.MM.yy' || '---' }]}
            </div>
            <div ng-if="item._$edit">
              <gt-date-select
                date-model="item.create_time"
                placeholder="'Date' | translate"
              ></gt-date-select>
            </div>
          `,
          title: this.gettext('Create date'),
        },
        {
          columnName: 'author',
          cellTemplate: html`
            <span class="btn-link">
              {[{ item.author_last_name }]} {[{ item.author_first_name }]}
              <span ng-if="!item.author_first_name && !item.author_last_name">
                {[{ item.author_username }]}
              </span>
            </span>
          `,
          title: this.gettext('Author'),
        },
        {
          columnName: 'update_time',
          predicate: 'update_time',
          cellTemplate: html`
            <div ng-if="!item._$edit">
              <i class="fa fa-calendar"></i> {[{ item.update_time | date:'dd.MM.yy' || '---' }]}
            </div>
            <div ng-if="item._$edit">
              <gt-date-select
                date-model="item.update_time"
                placeholder="'Date' | translate"
              ></gt-date-select>
            </div>
          `,
          title: this.gettext('Update date'),
        },
        {
          columnName: 'editor',
          cellTemplate: html`
            <span class="btn-link">
              {[{ item.editor_last_name }]} {[{ item.editor_first_name }]}
              <span ng-if="!item.author_first_name && !item.author_last_name">
                {[{ item.editor_username }]}
              </span>
            </span>
          `,
          title: this.gettext('Author'),
        },
        {
          columnName: 'price',
          cellTemplate: html`
            <div ng-if="!item._$edit">
              <span class="label-strong">{[{ item.price || 0 | number: 2 }]}</span>
            </div>
            <div ng-if="item._$edit">
              <input type="number" class="form-control" ng-model="item.price" />
            </div>
          `,
          title: this.gettext('Price'),
        },
        {
          columnName: 'prev_price',
          cellTemplate: html`
            <span class="label-strong" ng-if="item.prev_price">
              {[{ item.prev_price || 0 | number: 2 }]}
            </span>
          `,
          title: this.gettext('Previous Price'),
        },
        {
          columnName: 'price_diff',
          cellTemplate: html`
            <span
              class="label-strong"
              ng-if="item.prev_price && item.prev_price !== item.price"
              ng-class="{
                'negative-number': item.prev_price > item.price,
                'positive-number': item.prev_price < item.price
              }"
            >
              {[{ (item.price - item.prev_price) || 0 | number: 2 }]}
            </span>
          `,
          title: this.gettext('Price diff'),
        },
        {
          columnName: 'additional_info',
          cellTemplate: html`
            <div ng-if="!item._$edit">
              {[{ item.additional_info | cut:true:50:'...' || '---' }]}
            </div>
            <div ng-if="item._$edit">
              <input type="text" ng-model="item.additional_info" class="form-control" />
            </div>
          `,
          title: this.gettext('Info'),
        },
        {
          columnName: 'edit',
          cellTemplate: html`
            <div class="pull-right" ng-if="!item._$edit">
              <a
                class="btn btn-xs"
                data-tip="{[{ 'edit changes'|translate }]}"
                ng-click="item._$edit = true"
              >
                <i class="fa fa-pencil-square"></i>
              </a>
            </div>
            <div class="pull-right" ng-if="item._$edit">
              <a
                class="btn btn-xs btn-success"
                data-tip="{[{ 'save changes'|translate }]}"
                ng-click="args.update(item)"
              >
                <i class="fa fa-floppy-o"></i>
              </a>
              <a
                class="btn btn-xs btn-danger"
                data-tip="{[{ 'delete item'|translate }]}"
                ng-click="args.delete(item)"
              >
                <i class="fa fa-trash"></i>
              </a>
              <a
                class="btn btn-xs"
                data-tip="{[{ 'cancel changes'|translate }]}"
                ng-click="item._$edit = false"
              >
                <i class="fa fa-times"></i>
              </a>
            </div>
          `,
          title: this.gettext('Edit'),
        },
      ],
    };
  }
}

export const marketPricesTable: ng.IComponentOptions = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: MarketPricesTableController,
};
