import ng from 'angular';

import { TasksTemplatesPageView } from './tasks-templates-page-view/tasks-templates-page-view.component';
import { TasksTemplatesService } from './tasks-templates.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const tasksTemplatesModule = ng
  .module('company.tasksTemplates', [])
  .component('tasksTemplatesPageView', TasksTemplatesPageView)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('tasksTemplates', {
        parent: 'company',
        url: '/tasks-templates',
        component: 'tasksTemplatesPageView',
        data: {
          pageTitle: gettext('Tasks templates'),
        },
        resolve: {
          filterLevel: () => {
            return 'tasks-templates-list-page';
          },
          initQueryParams: [
            'PageService',
            function (PageService: PageService) {
              return {
                ...PageService.syncUrlFilter('tasks-templates-list-page'),
              };
            },
          ],
        },
      });
    },
  ])
  .service('TasksTemplatesService', TasksTemplatesService).name;
