import React from 'react';

import type { Message } from '~/shared/lib/types';
import { ActionBar, type ActionBarConfig } from '~/shared/ui/components/action-bar';
import { CloseEyeIcon, EyeIcon } from '~/shared/ui/icons/gt-icons';
import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';
import { Tag } from '~/shared/ui/kit/tag';

import type { ActionDef, DataRecord } from '../../lib/types';

export const InlineEditToolbar = <R extends DataRecord>({
  config,
  selectedCount,
  errors,
  actions,
  records,
}: {
  config: ActionBarConfig[];
  selectedCount?: number;
  errors: { id: string; field: string; msg: Message }[];
  actions: ActionDef<R>[];
  records: R[];
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <ActionBar
      title={
        <>
          {!!errors.length && (
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <div className="flex cursor-pointer items-center gap-2">
                  {open ? (
                    <EyeIcon className="text-icon-danger h-3 w-3" />
                  ) : (
                    <CloseEyeIcon className="text-icon-danger h-3 w-3" />
                  )}
                  <Tag variant="red" fontWeight="medium" suffix={errors.length} rounded="full">
                    Errors:
                  </Tag>
                </div>
              </PopoverTrigger>
              <PopoverContent side="bottom" sideOffset={8} className="max-w-[300px] p-3">
                <ul className="text-text-main-primary max-h-[200px] overflow-y-auto text-xs">
                  {errors.map((err) => (
                    <li key={`${err.id}-${err.field}`} className="mb-1">
                      <span className="font-medium">
                        #{parseInt(err.id, 10) < 0 ? 'NEW' : err.id}
                      </span>{' '}
                      (<span className="text-text-main-secondary">{err.field}</span>):{' '}
                      {err.msg.text}
                    </li>
                  ))}
                </ul>
              </PopoverContent>
            </Popover>
          )}
          {selectedCount !== undefined && (
            <div className="text-text-main-secondary text-md flex gap-1 font-medium">
              <span>{selectedCount}</span> <span>selected:</span>
            </div>
          )}
        </>
      }
      config={config}
      actions={actions}
      records={records}
    />
  );
};
