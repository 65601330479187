export class FactIntermediateListService {
  FactIntermediateListResource: any;
  constructor($resource: ng.resource.IResourceService) {
    this.FactIntermediateListResource = $resource(
      '/api/passports/contract-passport-facts/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        save: { method: 'POST' },
        update: { method: 'PATCH' },
        delete: { method: 'DELETE' },
      },
    );
  }

  query(params: object) {
    return this.FactIntermediateListResource.query(params).$promise;
  }

  save(item: any) {
    if (item.id) {
      return this.FactIntermediateListResource.update(item).$promise;
    }
    return this.FactIntermediateListResource.save(item).$promise;
  }

  delete(item: any) {
    return this.FactIntermediateListResource.delete(item).$promise;
  }
}
FactIntermediateListService.$inject = ['$resource'];
