import ng from 'angular';

import type { HelpButtonsService } from '^/app/core/legacy/help-buttons/help-buttons.srv';

(function () {
  'use strict';
  ng.module('core.legacy').controller('PageController', Controller);

  Controller.$inject = ['$scope', 'HelpButtonsService'];

  function Controller(this: any, $scope: ng.IScope, HelpButtonsService: HelpButtonsService) {
    const vm = this;
    vm.hbConfig = HelpButtonsService.getConfig();

    activate();

    ////////////////

    function activate() {
      updateData();
      $scope.$on('hb-config-updated', updateData);
    }

    function updateData() {
      vm.hbConfig = HelpButtonsService.getConfig();
      HelpButtonsService.setStartIntroFunc(($scope as any).startIntro);
    }
  }
})();
