import React from 'react';

import { useComponentModel } from '~/shared/lib/components';
import { useTranslation } from '~/shared/lib/i18n';
import { useObservableEagerState } from '~/shared/lib/state';
import { cn } from '~/shared/lib/utils';
import { CloseEyeIcon, DragIcon, EyeIcon, ShieldExclamationIcon } from '~/shared/ui/icons';
import {
  DndContext,
  type DragEndEvent,
  DragOverlay,
  type DragStartEvent,
  type DropAnimation,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  defaultDropAnimation,
  pointerWithin,
  restrictToVerticalAxis,
  sortableKeyboardCoordinates,
  useSensor,
  useSensors,
} from '~/shared/ui/lib/dnd';

import { ColumnsDndList } from './columns-dnd-list';
import type { DatasetViewsModel } from './dataset-views.model';
import { getConfigParamsTitle } from '../../helpers/configs';

const dropAnimationConfig: DropAnimation = {
  ...defaultDropAnimation,
  duration: 250,
  easing: 'ease-in-out',
};
export const ColumnsDndContainer = () => {
  const model = useComponentModel<DatasetViewsModel>();
  const { t } = useTranslation();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const columns = useObservableEagerState(model.editingColumns$);
  const draggableItem = useObservableEagerState(model.draggableItem$);
  const loading = useObservableEagerState(model.loading$);

  const onDragStartHandler = React.useCallback(
    (e: DragStartEvent) => model.handleDragStart(parseInt(e.active.id.toString(), 10)),
    [model],
  );
  const onDragEndHandler = React.useCallback(
    (e: DragEndEvent) =>
      model.handleDragEnd(
        parseInt(e.active.id.toString(), 10),
        e.over ? parseInt(e.over.id.toString(), 10) : undefined,
      ),
    [model],
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragStart={onDragStartHandler}
      onDragEnd={onDragEndHandler}
      modifiers={[
        restrictToVerticalAxis,
        ({ transform }) => ({
          ...transform,
          x: transform.x - 250,
          y: transform.y - 150,
        }),
      ]}
    >
      <div className="flex-block text-text-main-secondary pd-20 w-full gap-20">
        <ColumnsDndList
          items={columns.visible}
          titleText={t('Visible')}
          titleBtnText={t('Hide All')}
          loading={loading}
          titleBtnHandler={model.hideAllColumns}
          toggleVisibility={model.toggleColumnVisibility}
          toggleRequirement={model.toggleColumnRequirement}
          onChange={model.editColumn}
        />
        <ColumnsDndList
          items={columns.hidden}
          titleText={t('Hidden')}
          titleBtnText={t('Show All')}
          loading={loading}
          titleBtnHandler={model.showAllColumns}
          toggleVisibility={model.toggleColumnVisibility}
          toggleRequirement={model.toggleColumnRequirement}
          onChange={model.editColumn}
        />
      </div>

      <DragOverlay dropAnimation={dropAnimationConfig}>
        {draggableItem ? (
          <div className="text-text-main-tertiary bg-background-main-primary flex items-center gap-2 rounded-md p-2 shadow-lg">
            <DragIcon />
            {getConfigParamsTitle(draggableItem)}
            <ShieldExclamationIcon
              className={cn(
                'ml-auto',
                draggableItem.required ? 'text-text-additional-danger' : 'text-text-main-secondary',
              )}
            />
            {draggableItem.visible ? <EyeIcon /> : <CloseEyeIcon />}
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
