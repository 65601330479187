import {
  BaseLogisticRepository,
  LogisticQueryParams,
  PurchaseLogisticListTotals,
  PurchaseLogisticRecord,
} from '~/features/execution/logistic/common';
import {
  logisticsLogisticsListSupplierList,
  logisticsLogisticsTotalsPurchaseRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';

@singleton()
export class PurchaseLogisticRepository extends BaseLogisticRepository<
  PurchaseLogisticRecord,
  PurchaseLogisticListTotals
> {
  queryFn = async (query: LogisticQueryParams) => {
    const { data } = await logisticsLogisticsListSupplierList({ query });
    return { records: data.results, count: data.count };
  };
  getListTotals = async (query: LogisticQueryParams) => {
    const { data } = await logisticsLogisticsTotalsPurchaseRetrieve({ query });
    return data;
  };
}
