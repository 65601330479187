import type { Port, PortPost, PortQueryParams } from '~/features/common/port/lib/types';
import type { PassportListParams } from '~/features/deals/passports';
import { logisticsPortsPredictionsRetrieve } from '~/shared/api';
import type { Paginated, Patched } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';

export class PortRepository extends EntityRepository<Port, PortQueryParams, Port, PortPost> {
  readonly entityName = 'logistics.Port';

  getFn = (_id: number): Promise<Port> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_query: PassportListParams): Promise<Paginated<Port>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (search: string) => {
    const { data } = await logisticsPortsPredictionsRetrieve({ query: { search } });
    return data.results;
  };
  createFn = (_entity: PortPost): Promise<Port> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Patched<PortPost>): Promise<Port> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
