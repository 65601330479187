import { notify } from '~/shared/lib/notify';

import template from './confirm-deletion-modal.html?raw';
import type { GtUtilsService } from '../../legacy/gt-utils/gt-utils.srv';

export const ConfirmDeletionModal = {
  bindings: {
    modalInstance: '<',
    resource: '<',
    objectId: '<',
  },
  template,
  controller: [
    'GtUtils',
    'gettext',
    class {
      GtUtils: GtUtilsService;
      gettext: ng.gettext.gettextFunction;
      loading = true;
      modalInstance: any;
      objectId?: number;
      resource: any;
      results: any;
      constructor(GtUtils: GtUtilsService, gettext: ng.gettext.gettextFunction) {
        this.GtUtils = GtUtils;
        this.gettext = gettext;
      }

      $onInit() {
        this.loading = true;
        this.resource.deleteConfirmation({ id: this.objectId }).$promise.then((data: any) => {
          this.results = data;
          this.loading = false;
        });
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.closeModal(data);
      }

      closeModal(data: any) {
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        return this.resource.delete({ id: this.objectId }).$promise.then(() => {
          notify(this.gettext('Object removed'));
          this.close('delete', true);
        });
      }

      capitalize(string: any) {
        return this.GtUtils.capitalize(string);
      }
    },
  ],
};
