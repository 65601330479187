import ng from 'angular';

import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').directive('warehouseLostsRow', directive);

  function directive() {
    return {
      template: require('./warehouse-losts-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        lost: '<',
        cargo: '<',
        onSave: '&',
        onDelete: '&',
      },
    };
  }

  Controller.$inject = ['$rootScope', 'GtUtils', 'CoreService'];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    GtUtils: GtUtilsService,
    CoreService: CoreService,
  ) {
    const vm = this;

    vm.toggleEdit = toggleEdit;
    vm.errors = {};
    vm.saveLost = saveLost;
    vm.deleteLost = deleteLost;

    ////////////////

    vm.$onInit = function () {
      vm.purchaseContractQueryParams = { clear_cargo: vm.cargo };
      vm.buQueryParams = GtUtils.getBUQueryParams();
      vm.edit = !vm.lost.id;
      vm.lost = vm.lost || {};
      if (!vm.lost.id) {
        CoreService.getDefaultBuId(vm.lost).then((data) => (vm.lost.business_unit = data));
      }
      if (!vm.edit) {
        vm.lost.cargo = vm.lost.cargo || vm.cargo;
        vm.lost.warehouse = vm.lost.warehouse || vm.warehouse;
      }
    };
    function saveLost() {
      vm.onSave({ lost: vm.lost });
    }

    function deleteLost() {
      vm.onDelete({ lost: vm.lost });
    }

    function toggleEdit(key: any) {
      vm.edit = key;
    }
  }
})();
