import ng from 'angular';

import type { GtFilterService } from '../../gt-filter/gt-filter.srv';

(function () {
  'use strict';

  ng.module('core.legacy').directive('gtTheadRow', directive);

  function directive() {
    return {
      template: require('./gt-thead-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        columns: '<',
        filterLevel: '<?',
        updateList: '&?',
        columnVisibility: '<?',
      },
    };
  }

  Controller.$inject = ['$scope', '$log', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $log: ng.ILogService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;

    vm.queryParams = {};
    vm.predicate = null;
    vm.ordering = 0;
    vm.colClick = colClick;
    vm.getIcon = getIcon;

    vm.$onInit = function () {
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
    };

    vm.$onChanges = function (changes: any) {
      if (changes.columns && vm.columns) {
        vm.columns.forEach(function (col: any, idx: number) {
          if (!col.columnName) {
            $log.error('Empty columnName in column:', col);
            throw new Error(
              `Column doesn't have columnName value. Index ${idx}, filterLevel: ${vm.filterLevel}`,
            );
          }
        });
      }
    };

    // //////////////

    function colClick(column: any) {
      if (column.onHeadClick) {
        column.onHeadClick();
      } else if (column.predicate) {
        order(column);
      }
    }

    function order(column: any) {
      if (column.predicate) {
        if (column.predicate != vm.predicate || !vm.ordering) {
          vm.predicate = column.predicate;
          vm.ordering = -1;
          vm.queryParams.ordering = '-' + vm.predicate;
        } else if (vm.ordering == -1) {
          vm.ordering = 1;
          vm.queryParams.ordering = vm.predicate;
        } else {
          vm.ordering = 0;
          vm.queryParams.ordering = null;
        }
        vm.queryParams._sortTrype = undefined;
        vm.queryParams._sortReverse = undefined;
      } else if (column.sortType) {
        vm.queryParams._sortReverse = undefined;
        if (column.sortType != vm.predicate || !vm.ordering) {
          vm.predicate = column.sortType;
          vm.ordering = -1;
          vm.queryParams._sortTrype = vm.predicate;
        } else if (vm.ordering == -1) {
          vm.ordering = 1;
          vm.queryParams._sortTrype = vm.predicate;
        } else {
          vm.ordering = 0;
          vm.queryParams._sortTrype = undefined;
        }
        vm.queryParams._sortReverse = vm.ordering == -1;
        vm.queryParams._sortTrype = column.sortType;
        vm.queryParams._dontReload = true;
        vm.queryParams.ordering = undefined;
      }
      if (vm.updateList) {
        return vm.updateList({ params: vm.queryParams });
      } else {
        return gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
      }
    }

    function getIcon(column: any) {
      if (column.predicate) {
        if (vm.predicate != column.predicate || !vm.ordering) {
          return 'fa-sort';
        } else if (vm.ordering == -1) {
          return 'fa-sort-amount-desc';
        }
        return 'fa-sort-amount-asc';
      } else if (vm.queryParams._sortTrype != column.sortType || !vm.ordering) {
        return 'fa-sort';
      } else if (vm.ordering == -1) {
        return 'fa-sort-amount-desc';
      } else {
        return 'fa-sort-amount-asc';
      }
    }
  }
})();
