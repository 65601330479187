import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('balanceClientContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      mode: '<?',
      amountTotal: '<?',
    },
    template: require('./balance-client-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'ReportsService', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    ReportsService: any,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;

    vm.financeAccounts = [];
    vm.financeAccountsUndistributed = 0;
    vm.updateFinanceAccounts = updateFinanceAccounts;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'balance-client-container';
      vm.mode = vm.mode || 'debitor';
      vm.queryParams = { ...vm.initQueryParams };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateFinanceAccounts();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    vm.$onChanges = function () {
      vm.queryParams = { ...vm.initQueryParams };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    ////////////////

    function updateFinanceAccounts() {
      GtUtils.overlay('show');
      ReportsService.FinanceBalance.debitorCreditorBalance(vm.queryParams, function (data: any) {
        vm.financeAccounts = data.results;
        ReportsService.FinanceBalance.debitorCreditorBalanceTotal(
          vm.queryParams,
          function (data: any) {
            vm.financeAccountsUndistributed = vm.amountTotal - data.amount;
            GtUtils.overlay('hide');
          },
        );
      });
    }
  }
})();
