import ng from 'angular';

import type { ContractRecord } from '~/features/deals/contracts';
import type { Request } from '~/features/deals/requests';
import { formatDate } from '~/shared/lib/date';
import { notify, notifyError } from '~/shared/lib/notify';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreUtils } from '^/app/core/core.utils';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import type { LoyaltyProgramsService } from '^/app/reports/budgets/loyalty-programs/loyalty-programs.service';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

function Service(
  $resource: ng.resource.IResourceService,
  $rootScope: GtRootScopeService,
  $q: ng.IQService,
  $location: ng.ILocationService,
  $http: ng.IHttpService,
  $uibModal: ng.ui.bootstrap.IModalService,
  $state: ng.ui.IStateService,
  $filter: ng.IFilterService,
  $injector: ng.auto.IInjectorService,
  LogisticsService: LogisticsService,
  FinancesService: FinancesService,
  gettext: ng.gettext.gettextFunction,
  CoreUtils: CoreUtils,
  CropsService: any,
  AccountsService: AccountsService,
  FormFieldParamsService: FormFieldParamsService,
  CustomValuesService: CustomValuesService,
  LoyaltyProgramsService: LoyaltyProgramsService,
  PotentialService: any,
  ClientsService: any,
) {
  const actions = {
    get: {
      method: 'GET',
    },
    save: {
      method: 'POST',
    },
    query: {
      method: 'GET',
      isArray: false,
    },
    remove: {
      method: 'DELETE',
    },
    delete: {
      method: 'DELETE',
    },
    update: {
      method: 'PATCH',
    },
  };

  const stagesResource = $resource(
    '/api/workflow/contract-stages/?contract_id=:contract_id&contract_type=:contract_type/',
    {
      contract_id: '@contract_id',
      contract_type: '@contract_type',
    },
    actions,
  );

  const stageResource: any = $resource(
    '/api/workflow/stages/:slug/:id/',
    {
      slug: '@slug',
      id: '@id',
    },
    actions,
  );

  const ContractResource: any = $resource(
    '/api/contracts/contracts/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      status: { method: 'GET', url: '/api/contracts/contracts/status/' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contracts/predictions/',
      },
      predictionsDetails: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contracts/:id/predictions_details/',
      },
      generateNumber: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contracts/generate_number/',
      },
      bulkUpdate: {
        method: 'POST',
        url: '/api/contracts/contracts/bulk_create_or_update/',
      },
      getRoleLimits: {
        method: 'GET',
        url: '/api/contracts/contracts/get_role_limits/',
      },
      consolidatedRiskPosition: {
        method: 'GET',
        url: '/api/contracts/contracts/consolidated_risk_position/',
      },
      uniqueCounterparties: {
        method: 'GET',
        url: '/api/contracts/contracts/get_unique_counterparties/',
      },
      contractbaseListBase: {
        method: 'GET',
        url: '/api/contracts/contractbase-list/base/',
      },
      setFinalVolumeFromExecution: {
        method: 'POST',
        isArray: false,
        url: '/api/contracts/contracts/set_final_volume_from_execution/',
      },
      derivativePriceList: {
        method: 'GET',
        url: '/api/contracts/contract-derivative-price/list/',
      },
      updateStatus: {
        method: 'POST',
        isArray: false,
        url: '/api/contracts/contracts/update_status/',
      },
      setVoyageData: {
        method: 'POST',
        isArray: false,
        url: '/api/contracts/contracts/set_voyage_data/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contracts/export_column_names/',
      },
      getPl: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contractbase-list/pl/',
      },
      createPnlControl: {
        method: 'GET',
        url: '/api/contracts/contracts/create_pnl_control/',
      },
      getContractFactPl: {
        method: 'GET',
        url: '/api/contracts/contracts/get_contract_fact_pl/',
      },
      shareQualityRules: {
        method: 'GET',
        url: '/api/contracts/contracts/share_quality_rules/',
      },
      executionTotals: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contractbase-totals/execution/',
      },
    },
  );

  const PurchaseContractResource: any = $resource(
    '/api/contracts/purchase-contracts/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      deleteConfirmation: {
        method: 'GET',
        url: '/api/contracts/purchase-contracts/:id/delete_confirmation/',
      },
      details: {
        method: 'GET',
        url: '/api/contracts/purchase-contracts-details/:id/',
        isArray: false,
      },
      detailsFull: {
        method: 'GET',
        url: '/api/contracts/purchase-contracts-details/full/:id/',
        isArray: false,
      },
      queryLight: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts-list/info/',
      },
      queryLightExecution: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts-list/execution/',
      },
      queryAccountingPrepayments: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts-list/prepayments_accounting/',
      },
      traidingQuery: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts-list/traiding/',
      },
      approvalsQuery: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts-list/approvals/',
      },
      qualityQuery: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts-list/quality/',
      },
      physicalPosition: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts-list/physical-position/',
      },
      physicalPositionTotal: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts-totals/physical-position/',
      },
      payments: {
        method: 'GET',
        url: '/api/contracts/purchase-contracts/:id/payments/',
      },
      logistics: {
        method: 'GET',
        url: '/api/contracts/purchase-contracts/:id/logistics/',
      },
      position: {
        method: 'GET',
        url: '/api/contracts/purchase-contracts-list/position/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts/predictions/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts/export_column_names/',
      },
      executionTotals: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/purchase-contracts-totals/execution/',
      },
    },
  );

  const SaleContractResource: any = $resource(
    '/api/contracts/sale-contracts/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      deleteConfirmation: {
        method: 'GET',
        url: '/api/contracts/sale-contracts/:id/delete_confirmation/',
      },
      details: {
        method: 'GET',
        url: '/api/contracts/sale-contracts-details/:id/',
        isArray: false,
      },
      detailsFull: {
        method: 'GET',
        url: '/api/contracts/sale-contracts-details/full/:id/',
        isArray: false,
      },
      queryLight: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts-list/info/',
      },
      queryLightExecution: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts-list/execution/',
      },
      executionTotals: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts-totals/execution/',
      },
      traidingQuery: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts-list/traiding/',
      },
      approvalsQuery: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts-list/approvals/',
      },
      qualityQuery: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts-list/quality/',
      },
      physicalPosition: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts-list/physical-position/',
      },
      physicalPositionTotal: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts-totals/physical-position/',
      },
      payments: {
        method: 'GET',
        url: '/api/contracts/sale-contracts/:id/payments/',
      },
      logistics: {
        method: 'GET',
        url: '/api/contracts/sale-contracts/:id/logistics/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts/predictions/',
      },
      getPassports: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts/:id/get_passports/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sale-contracts/export_column_names/',
      },
    },
  );

  const ServicesContractResource = $resource(
    '/api/contracts/services-contracts/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      deleteConfirmation: {
        method: 'GET',
        url: '/api/contracts/services-contracts/:id/delete_confirmation/',
      },
      details: {
        method: 'GET',
        url: '/api/contracts/services-contracts-details/:id/',
        isArray: false,
      },
      detailsFull: {
        method: 'GET',
        url: '/api/contracts/services-contracts-details/full/:id/',
        isArray: false,
      },
      queryLight: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/services-contracts-list/info/',
      },
      queryLightExecution: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/services-contracts-list/execution/',
      },
      traidingQuery: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/services-contracts-list/traiding/',
      },
      approvalsQuery: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/services-contracts-list/approvals/',
      },
      qualityQuery: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/services-contracts-list/quality/',
      },
      physicalPosition: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/services-contracts-list/physical-position/',
      },
      physicalPositionTotal: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/services-contracts-totals/physical-position/',
      },
      payments: {
        method: 'GET',
        url: '/api/contracts/services-contracts/:id/payments/',
      },
      logistics: {
        method: 'GET',
        url: '/api/contracts/services-contracts/:id/logistics/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/services-contracts/predictions/',
      },
      getPassports: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/services-contracts/:id/get_passports/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/services-contracts/export_column_names/',
      },
    },
  );

  const IntermediateContractResource = $resource(
    '/api/contracts/intermediate-contracts/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      deleteConfirmation: {
        method: 'GET',
        url: '/api/contracts/intermediate-contracts/:id/delete_confirmation/',
      },
      details: {
        method: 'GET',
        url: '/api/contracts/intermediate-contracts-details/:id/',
        isArray: false,
      },
      detailsFull: {
        method: 'GET',
        url: '/api/contracts/intermediate-contracts-details/full/:id/',
        isArray: false,
      },
      queryLight: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/intermediate-contracts-list/info/',
      },
      queryLightExecution: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/intermediate-contracts-list/execution/',
      },
      executionTotals: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/intermediate-contracts-totals/execution/',
      },
      group: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/intermediate-contracts/group/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/intermediate-contracts/predictions/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/intermediate-contracts/export_column_names/',
      },
    },
  );

  const ExportContractResource = $resource(
    '/api/contracts/export-contracts/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      deleteConfirmation: {
        method: 'GET',
        url: '/api/contracts/export-contracts/:id/delete_confirmation/',
      },
      details: {
        method: 'GET',
        url: '/api/contracts/export-contracts-details/:id/',
        isArray: false,
      },
      detailsFull: {
        method: 'GET',
        url: '/api/contracts/export-contracts-details/full/:id/',
        isArray: false,
      },
      queryLight: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/export-contracts-list/info/',
      },
      queryLightExecution: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/export-contracts-list/execution/',
      },
      executionTotals: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/export-contracts-totals/execution/',
      },
      group: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/export-contracts/group/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/export-contracts/predictions/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/export-contracts/export_column_names/',
      },
    },
  );

  const ContractChargeResource: any = $resource(
    '/api/contracts/contract-charges/:id/',
    {
      id: '@id',
    },
    {
      get: {
        method: 'GET',
      },
      save: {
        method: 'POST',
      },
      query: {
        method: 'GET',
        isArray: false,
      },
      remove: {
        method: 'DELETE',
      },
      delete: {
        method: 'DELETE',
      },
      update: {
        method: 'PATCH',
      },
      queryInfo: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-charges-list/info/',
      },
      bulkUpdateTariffs: {
        method: 'POST',
        url: '/api/contracts/contract-charges/bulk_update_tariffs/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-charges/predictions/',
      },
      create_charge_from_quality: {
        method: 'POST',
        url: '/api/contracts/contract-charges/create_charge_from_quality/',
      },
      bulkCreateOrUpdate: {
        method: 'POST',
        isArray: false,
        url: '/api/contracts/contract-charges/bulk_create_or_update/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-charges/export_column_names/',
      },
      setStatusClosed: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-charges/set_status_close/',
      },
      getFinancingData: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-charges/get_financing_data/',
      },
      createChargesByDefault: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-charges/create_charges_by_default/',
      },
    },
  );

  const ContractQualityResource = $resource(
    '/api/contracts/contract-qualities/:id/',
    {
      id: '@id',
    },
    {
      get: {
        method: 'GET',
      },
      save: {
        method: 'POST',
      },
      query: {
        method: 'GET',
        isArray: false,
      },
      remove: {
        method: 'DELETE',
      },
      delete: {
        method: 'DELETE',
      },
      update: {
        method: 'PATCH',
      },
      bulkUpdate: {
        method: 'POST',
        url: '/api/contracts/contract-qualities/bulk_update/',
      },
      bulkCreateOrUpdate: {
        method: 'POST',
        isArray: false,
        url: '/api/contracts/contract-qualities/bulk_create_or_update/',
      },
    },
  );

  const ContractPriceResource = $resource(
    '/api/contracts/contract-prices/:id/',
    {
      id: '@id',
    },
    {
      get: {
        method: 'GET',
      },
      save: {
        method: 'POST',
        isArray: true,
      },
      query: {
        method: 'GET',
        isArray: false,
      },
      remove: {
        method: 'DELETE',
      },
      delete: {
        method: 'DELETE',
      },
      update: {
        method: 'PATCH',
      },
      bulkCreateOrUpdate: {
        method: 'POST',
        url: '/api/contracts/contract-prices/bulk_create_or_update/',
      },
    },
  );

  const PurchaseFactResource: any = $resource(
    '/api/passports/purchase-facts/:id/',
    {
      id: '@id',
    },
    {
      get: {
        method: 'GET',
      },
      save: {
        method: 'POST',
      },
      query: {
        method: 'GET',
        isArray: false,
      },
      remove: {
        method: 'DELETE',
      },
      delete: {
        method: 'DELETE',
      },
      update: {
        method: 'PATCH',
      },
      queryLight: {
        method: 'GET',
        url: '/api/passports/purchase-facts-list/',
      },
      total: {
        method: 'GET',
        url: '/api/passports/purchase-facts/total',
      },
    },
  );

  const SaleFactResource: any = $resource(
    '/api/passports/sale-facts/:id/',
    {
      id: '@id',
    },
    {
      get: {
        method: 'GET',
      },
      save: {
        method: 'POST',
      },
      query: {
        method: 'GET',
        isArray: false,
      },
      remove: {
        method: 'DELETE',
      },
      delete: {
        method: 'DELETE',
      },
      update: {
        method: 'PATCH',
      },
      queryLight: {
        method: 'GET',
        url: '/api/passports/sale-facts-list/',
      },
      total: {
        method: 'GET',
        url: '/api/passports/sale-facts/total',
      },
    },
  );

  const RequestResource: any = $resource(
    '/api/contracts/requests/:id/',
    {
      id: '@id',
    },
    {
      deleteConfirmation: {
        method: 'GET',
        url: '/api/contracts/requests/:id/delete_confirmation/',
      },
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      totalInfo: {
        method: 'GET',
        url: '/api/contracts/request-totals/info/',
      },
      createContract: {
        method: 'GET',
        isArray: false,
        params: {
          id: '@id',
        },
        url: '/api/contracts/requests/:id/create_contract/',
      },
      createTicket: {
        method: 'GET',
        isArray: false,
        params: {
          id: '@id',
          bite: '@bite',
        },
        url: '/api/contracts/requests/:id/create_ticket/?bite=:bite',
      },
      updateQualityFromCropArea: {
        method: 'PATCH',
        isArray: false,
        params: {
          id: '@id',
          crop_area: '@crop_area',
        },
        url: '/api/contracts/requests/:id/update-quality-from-crop-area/?crop_area=:crop_area',
      },
      pricesOffer: {
        method: 'POST',
        isArray: false,
        url: '/api/contracts/requests/prices_offer/',
      },
      queryInfo: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/request-list/info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/requests/predictions/',
      },
      predictionsDetails: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/requests/:id/predictions_details/',
      },
      priceEstimated: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/requests/:id/price_estimated/',
      },
      getPriceIndicative: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/requests/:id/get_price_indicative/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/requests/export_column_names/',
      },
    },
  );

  const IndicatorResource = $resource(
    '/api/contracts/indicators/:id/',
    { id: '@id' },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      queryInfo: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/indicator-list/info/',
      },
      totalInfo: {
        method: 'GET',
        url: '/api/contracts/indicator-totals/info/',
      },
    },
  );

  const PassportResource: any = $resource(
    '/api/passports/passports/:id/',
    { id: '@id' },
    {
      query: { method: 'GET', isArray: false, cancellable: true },
      update: { method: 'PATCH' },
      partialUpdate: { method: 'PUT' },
      bulkCreateOrUpdate: {
        method: 'POST',
        url: '/api/passports/passports/bulk_create_or_update/',
      },
      details: {
        method: 'GET',
        url: '/api/passports/passports/:id/result/',
      },
      result: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/passport-result/list/',
        cancellable: true,
      },
      customsData: {
        method: 'GET',
        isArray: true,
        url: '/api/passports/passport-customs-data/',
        cancellable: true,
      },
      resultTotals: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/passport-result/totals/',
        cancellable: true,
      },
      contractsTotals: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/passport-contracts/totals/',
      },
      groupedCharges: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/passport-grouped-charges/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/passports/predictions/',
      },
      attach_deal: {
        method: 'POST',
        isArray: false,
        params: {
          id: '@id',
          deal_id: '@deal_id',
          stage: '@stage',
          contract_purpose: '@contract_purpose',
          volume: '@volume',
        },
        url:
          '/api/passports/passports/:id/attach_deal/?deal_id=:deal_id&' +
          'stage=:stage&contract_purpose=:contract_purpose&volume=:volume',
      },
      changeFacts: {
        method: 'POST',
        url: '/api/passports/passports/change_facts/',
      },
      lineup: {
        method: 'GET',
        url: '/api/passports/passports/:id/lineup/',
      },
      lineupUpdate: {
        method: 'POST',
        isArray: false,
        params: {
          id: '@id',
        },
        url: '/api/passports/passports/:id/lineup_update/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/passports/export_column_names/',
      },
      updateFactsFromExecution: {
        method: 'POST',
        url: '/api/passports/passports/set_fact_volume_from_execution/',
      },
      createUpdateWithFacts: {
        method: 'POST',
        url: '/api/passports/passports/create_update_with_facts/',
      },
      updateStatus: {
        method: 'POST',
        isArray: false,
        url: '/api/passports/passports/update_status/',
      },
      updateBusinessDate: {
        method: 'POST',
        isArray: false,
        url: '/api/passports/passports/update_business_date/',
      },
      updateVolume: {
        method: 'POST',
        isArray: false,
        url: '/api/passports/passports/update_volume/',
      },
      unconnectedVolume: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/passports/:id/unconnected_volume/',
      },
      downloadAllPassportDocuments: {
        method: 'GET',
        isArray: false,
        params: {
          id: '@id',
        },
        url: '/api/passports/passports/full_document_download/?passport_id=:id',
      },
      generateTitle: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/passports/generate_title/',
      },
    },
  );

  const PassportPurchasePlanResource: any = $resource(
    '/api/passports/purchase-plans/:id/',
    {
      id: '@id',
    },
    {
      save: {
        method: 'POST',
      },
      dropByRequest: {
        method: 'POST',
        isArray: false,
        url: '/api/passports/purchase-plans/drop_by_request/',
      },
      get: {
        method: 'GET',
      },
      query: {
        method: 'GET',
        isArray: false,
      },
      remove: {
        method: 'DELETE',
      },
      delete: {
        method: 'DELETE',
      },
      update: {
        method: 'PATCH',
      },
      queryLight: {
        method: 'GET',
        url: '/api/passports/purchase-plans-list/',
      },
    },
  );

  const PassportSalePlanResource: any = $resource(
    '/api/passports/sale-plans/:id/',
    {
      id: '@id',
    },
    {
      save: {
        method: 'POST',
      },
      dropByRequest: {
        method: 'POST',
        isArray: false,
        url: '/api/passports/sale-plans/drop_by_request/',
      },
      get: {
        method: 'GET',
      },
      query: {
        method: 'GET',
        isArray: false,
      },
      remove: {
        method: 'DELETE',
      },
      delete: {
        method: 'DELETE',
      },
      update: {
        method: 'PATCH',
      },
      queryLight: {
        method: 'GET',
        url: '/api/passports/sale-plans-list/',
      },
    },
  );

  const BasisDocResource = $resource(
    '/api/contracts/basis-docs/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/basis-docs/predictions/',
      },
    },
  );

  const ContractBasisDocResource = $resource(
    '/api/contracts/contract-basis-docs/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
    },
  );

  const PurchasePlanResource: any = $resource(
    '/api/contracts/purchase-plans/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      total: {
        method: 'GET',
        url: '/api/contracts/purchase-plans/total/',
      },
    },
  );

  const PurchasePlanVolumeResource: any = $resource(
    '/api/contracts/purchase-plans-volume/:id/',
    {
      id: '@id',
    },
    {
      bulkCreateOrUpdate: {
        method: 'POST',
        url: '/api/contracts/purchase-plans-volume/bulk_create_or_update/',
      },
      volumePerUsers: {
        method: 'GET',
        isArray: true,
        url: '/api/contracts/purchase-plans-volume/volume_per_user/',
      },
      volumeForPurchasePlan: {
        method: 'GET',
        isArray: true,
        url: '/api/contracts/purchase-plans-volume/volume_for_purchase_plan/',
      },
      purchasePlanVolumeTotal: {
        method: 'GET',
        isArray: true,
        url: '/api/contracts/purchase-plans-volume/purchase_plans_volume_total/',
      },
    },
  );

  const PricesPositionIndicatorResource: any = $resource(
    '/api/contracts/prices-position-indicators/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      delete: { method: 'DELETE' },
      bulkUpdate: {
        method: 'POST',
        url: '/api/contracts/prices-position-indicators/bulk_update/',
      },
      bulkCreate: {
        method: 'POST',
        url: '/api/contracts/prices-position-indicators/bulk_create/',
      },
      bulkDelete: {
        method: 'DELETE',
        url: '/api/contracts/prices-position-indicators/bulk_delete/',
      },
      list: {
        method: 'GET',
        url: '/api/contracts/prices-position-indicator-list/',
      },
    },
  );

  const ConsignmentsResource: any = $resource(
    '/api/contracts/consignments/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH', isArray: false },
      info: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-consignments-list/info/',
      },
      totals: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-consignments-totals/',
      },
      calcAmounts: {
        method: 'POST',
        isArray: false,
        url: '/api/contracts/consignments/calc_amounts/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/consignments/predictions/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/consignments/export_column_names/',
      },
    },
  );

  const GeneralAgreementsResource: any = $resource(
    '/api/contracts/general-agreements/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/general-agreements/predictions/',
      },
      generateNumber: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/general-agreements/generate_number/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/general-agreements/export_column_names/',
      },
    },
  );

  const ContractOptionsResource: any = $resource(
    '/api/contracts/contract-options/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-options/predictions/',
      },
      getMainOption: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-options/get_main_option/',
      },
    },
  );

  const ContractConsigneeResource = $resource(
    '/api/contracts/contract-consignees/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/contract-consignees/get_consignee_list/',
      },
    },
  );

  const ContractTerminalCertsResource: any = $resource(
    '/api/contracts/contract-terminal-certs/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH', isArray: false },
    },
  );

  const PaperTradeResource = $resource(
    '/api/contracts/paper-trade/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/paper-trade/predictions/',
      },
    },
  );

  const MarginControlResource = $resource(
    '/api/passports/margin-controls/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      save: { method: 'POST' },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/margin-controls/predictions/',
      },
    },
  );

  const ContractPlControlResource = $resource(
    '/api/contracts/contract-pl-control/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      save: { method: 'POST' },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/contract-pl-control/predictions/',
      },
    },
  );

  const SapOrdersResource = $resource(
    '/api/contracts/sap-orders/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      save: { method: 'POST' },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sap-orders/predictions/',
      },
    },
  );

  const SamplingConditionResource = $resource(
    '/api/contracts/sampling-condition/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      save: { method: 'POST' },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/sampling-condition/predictions/',
      },
    },
  );

  const MultipassportResource = $resource(
    '/api/passports/multipassports/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      save: { method: 'POST' },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/passports/multipassports/predictions/',
      },
    },
  );

  const CargoPortStockResource = $resource(
    '/api/contracts/cargo-port-stocks/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      save: { method: 'POST' },
      update: { method: 'PATCH' },
    },
  );

  const ContractOilExtensionResource = $resource(
    '/api/contracts/contract-oil-extension/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      save: { method: 'POST' },
      update: { method: 'PATCH' },
    },
  );

  const CertificationSchemeResource = $resource(
    '/api/contracts/certification-schemes/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/contracts/certification-schemes/predictions/',
      },
    },
  );

  let selectedContract: any = null;
  let quickAdd = false;

  const contractStageLabel: any = {
    finished: 'status_success',
    inactive: 'status_default',
    problem: 'status_exclamation',
    processing: 'status_exclamation',
    cancelled: 'status_danger',
  };

  const contractStageIcon: any = {
    finished: 'fa-check-circle',
    inactive: 'fa-circle-o',
    problem: 'fa-exclamation-circle',
    processing: 'fa-circle-o-notch fa-spin',
    cancelled: 'fa-times-circle-o',
  };

  const calculateContractFactTotalPrice = (contractFact: {
    conclusion_price: number;
    price: number;
  }) => {
    return contractFact.price - contractFact.conclusion_price;
  };

  return {
    getSelectedContract: getSelectedContract,
    setSelectedContract: setSelectedContract,
    updateStages: updateStages,

    getStatusClassLabel: getStatusClassLabel,
    getStatusClassIcon: getStatusClassIcon,
    getStageBySlug: getStageBySlug,
    getRequestsForSale: getRequestsForSale,
    getRequests: getRequests,
    getRequestsInfo: getRequestsInfo,
    saveRequest: saveRequest,
    deleteRequest: deleteRequest,
    getRequestCalculatedAmount: getRequestCalculatedAmount,
    getPassportIdListByContract: getPassportIdListByContract,
    getContractResource: getContractResource,

    stageResource: stageResource,
    Contract: ContractResource,
    Request: RequestResource,
    Indicator: IndicatorResource,
    PurchaseContract: PurchaseContractResource,
    SaleContract: SaleContractResource,
    ServicesContract: ServicesContractResource,
    IntermediateContract: IntermediateContractResource,
    ExportContract: ExportContractResource,
    ContractCharge: ContractChargeResource,
    ContractQuality: ContractQualityResource,
    ContractPrice: ContractPriceResource,
    updateContract: updateContract,
    refreshContract: refreshContract,
    getContract: getContract,
    getContractsLight: getContractsLight,
    setCurrencyOwner: setCurrencyOwner,
    setCurrencyExchange: setCurrencyExchange,
    getGeneralAgreement: getGeneralAgreement,
    saveContract: saveContract,
    getContractList: getContractList,
    getContractTotal: getContractTotal,
    getRequest: getRequest,
    getUniqueCounterparties: getUniqueCounterparties,
    getContracts: getContracts,

    statusModal: statusModal,
    contractModal: contractModal,
    contractModalQuick: contractModalQuick,
    contractListModal: contractListModal,
    requestModal: requestModal,
    requestModalQuick: requestModalQuick,
    reverseDeal: reverseDeal,
    getQuickAdd: getQuickAdd,
    setQuickAdd: setQuickAdd,
    basisDocsModal: basisDocsModal,
    purchasePlanModal: purchasePlanModal,
    consignmentsModal: consignmentsModal,
    generalAgreementsModal: generalAgreementsModal,
    additionalAgreementModal: additionalAgreementModal,
    getContractNumber: getContractNumber,
    getAgreementNumber: getAgreementNumber,
    contractStageModal: contractStageModal,
    contractChargeModal: contractChargeModal,
    createServiceContract: createServiceContract,
    getConclusionDateRange: getConclusionDateRange,
    checkExecuteExpiration: checkExecuteExpiration,
    checkTicketCreatePossibility: checkTicketCreatePossibility,
    checkEditPossibility: checkEditPossibility,

    Passport: PassportResource,
    ContractBasisDoc: ContractBasisDocResource,
    BasisDoc: BasisDocResource,
    passportModal: passportModal,
    getPassportSalePlan: getPassportSalePlan,
    getPassportPurchasePlan: getPassportPurchasePlan,
    executePassport: executePassport,
    approveDeal: approveDeal,
    cancelDeal: cancelDeal,
    PurchasePlan: PurchasePlanResource,
    PurchasePlanVolume: PurchasePlanVolumeResource,
    PurchaseFact: PurchaseFactResource,
    SaleFact: SaleFactResource,
    PricesPositionIndicator: PricesPositionIndicatorResource,
    Consignments: ConsignmentsResource,
    GeneralAgreements: GeneralAgreementsResource,
    ContractTerminalCerts: ContractTerminalCertsResource,
    ContractOptions: ContractOptionsResource,
    ContractConsignee: ContractConsigneeResource,
    PaperTrade: PaperTradeResource,
    PassportPurchasePlan: PassportPurchasePlanResource,
    PassportSalePlan: PassportSalePlanResource,
    MarginControl: MarginControlResource,
    ContractPlControl: ContractPlControlResource,
    SapOrders: SapOrdersResource,
    SamplingCondition: SamplingConditionResource,
    CargoPortStock: CargoPortStockResource,
    Multipassport: MultipassportResource,
    ContractOilExtension: ContractOilExtensionResource,
    CertificationSchemeResource: CertificationSchemeResource,

    cloneRequest: cloneRequest,
    cloneContract: cloneContract,
    cloneGeneralAgreement: cloneGeneralAgreement,
    cloneConsignment: cloneConsignment,
    createTicket: createTicket,
    createChildTicket: createChildTicket,

    addLogistic: addLogistic,
    addFinance: addFinance,
    addBalanceInvoice: addBalanceInvoice,

    getDefaultContract: getDefaultContract,

    getPagesConfig: getPagesConfig,
    getPassportPagesConfig: getPassportPagesConfig,
    getMulticontractPagesConfig: getMulticontractPagesConfig,
    getMulticontractServicesPagesConfig: getMulticontractServicesPagesConfig,
    createPassportContract: createPassportContract,

    connectToPassport: connectToPassport,

    getRoleLimits: getRoleLimits,
    createMultiContractCharge: createMultiContractCharge,
    calcContractChargePrice: calcContractChargePrice,
    isChargeUnique: isChargeUnique,
    setVatValue: setVatValue,
    checkChargeAndConfirmNotUnique: checkChargeAndConfirmNotUnique,
    setContractReadonly: setContractReadonly,
    setApprovalToNull: setApprovalToNull,
    getContractPurpose: getContractPurpose,
    getVolumeAvailableToConnect: getVolumeAvailableToConnect,
    getCustomFieldTableColumns: getCustomFieldTableColumns,
    getCustomFieldFilters: getCustomFieldFilters,
    getMainLoyaltyProgram: getMainLoyaltyProgram,
    isCargoFieldDisabled: isCargoFieldDisabled,
    getDefaultsParams: getDefaultsParams,
    isTicketDisabledForPassportCreate: isTicketDisabledForPassportCreate,
    getMainContractOptions: getMainContractOptions,
    calculateContractFactTotalPrice: calculateContractFactTotalPrice,
  };

  ///////////////

  function getSelectedContract() {
    return selectedContract;
  }

  function getMainContractOptions() {
    return ContractOptionsResource.getMainOption().$promise;
  }

  function getPrefix(contract: any) {
    const prefix: any = [contract.deal_type || '', contract.contract_type || '']
      .filter((v) => Boolean(v))
      .join('-');
    return `${prefix}${prefix ? '-' : ''}`;
  }

  function generatePassportTitle(query: any) {
    const { saleContractNumber, purchaseContractNumber, ...extra } = query;
    const params = {
      sale_contract_number: saleContractNumber,
      purchase_contract_number: purchaseContractNumber,
      ...extra,
    };
    return PassportResource.generateTitle(params).$promise;
  }

  function setSelectedContract(contract: any) {
    selectedContract = contract;
    $rootScope.$broadcast('contract_selected');

    if (!contract) {
      return;
    }

    const url = {
      contract_type: contract.contract_type,
      contract_id: contract.id,
    };

    if (contract.contract_type === 'purchase') {
      url.contract_type = 'purchase';
    }

    $location.path('/contracts');
    $location.search(url);
  }

  function getQuickAdd() {
    return quickAdd;
  }

  function setQuickAdd(value: any) {
    quickAdd = value;
    $rootScope.$broadcast('contract-quickadd-updated');
    return quickAdd;
  }

  function updateStages(contract: any) {
    return stagesResource.save({
      contract_id: contract.id,
      contract_type: contract.contract_type,
      stages: contract.stages,
    }).$promise;
  }

  function getStageBySlug(stages: any, slug: any) {
    return (
      stages?.filter(Boolean).find(function (stage: any) {
        return stage.slug === slug;
      }) || {}
    );
  }

  function getStatusClassLabel(stages: any, slug: any) {
    const stage = getStageBySlug(stages, slug);
    return contractStageLabel[stage.status];
  }

  function getStatusClassIcon(stages: any, slug: any) {
    const stage = getStageBySlug(stages, slug);
    return contractStageIcon[stage.status];
  }

  function getRequestsForSale(contractId: number) {
    return $http.get('/api/export-contracts/' + contractId + '/requests');
  }

  function getRequests(params: object) {
    return RequestResource.query(params).$promise;
  }
  function getRequestsInfo(params: object) {
    return RequestResource.queryInfo(params).$promise;
  }

  function saveRequest(request: any) {
    if (request.id) {
      return RequestResource.update(request).$promise;
    }
    return RequestResource.save(request).$promise;
  }

  function deleteRequest(request: any) {
    return RequestResource.delete(request).$promise;
  }

  function statusModal(contract: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./status-edit/status-edit.tpl.html?raw'),
      controller: 'ContractsStatusEditController as vm',
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      resolve: {
        contract: () => {
          return contract;
        },
      },
    }).result;
  }

  function contractModal(contract: any, extraArgs?: any) {
    const dealType = contract.deal_type || '';
    const hyphen = dealType ? '-' : '';
    return FormFieldParamsService.getContractPriceWidgetFields(
      dealType + hyphen + 'contract-edit-modal',
      contract.business_unit,
    ).then((priceWidgetFields: any) => {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./components/contract-modal/contract-modal.tpl.html?raw'),
        controller: 'ContractsContractModalController as vm',
        windowClass: 'modal-template',
        appendTo: getModalRoot(),
        resolve: {
          contract: () => ({ ...contract }),
          priceWidgetFields: () => [...priceWidgetFields],
          extraArgs: () => {
            return extraArgs;
          },
        },
      }).result;
    });
  }

  function contractModalQuick(contract: ContractRecord) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./components/contract-modal/contract-modal.tpl.html?raw'),
      controller: 'ContractsContractModalController as vm',
      windowClass: 'modal-template modal-template-half-width quick-modal',
      appendTo: getModalRoot(),
      resolve: {
        contract: () => ({ ...contract }),
      },
    }).result;
  }

  function contractListModal(queryParams: QueryParams, args: any) {
    return $uibModal.open({
      backdrop: 'static',
      component: 'ContractListModal',
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      resolve: {
        queryParams: () => queryParams,
        args: () => args,
      },
    }).result;
  }

  function requestModal(request: Partial<Request>, extraArgs?: any) {
    return FormFieldParamsService.getContractPriceWidgetFields(
      `${getPrefix(request)}request-edit-modal`,
      request.business_unit?.toString(),
    ).then((priceWidgetFields: any) => {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./../../requests/legacy/request-modal/request-modal.tpl.html?raw'),
        appendTo: getModalRoot(),
        controller: 'RequestsRequestModalController as vm',
        windowClass: 'modal-template',
        resolve: {
          request: () => {
            return request;
          },
          extraArgs: () => {
            return extraArgs;
          },
          priceWidgetFields: () => {
            return priceWidgetFields;
          },
        },
      }).result;
    });
  }
  function requestModalQuick(request: any, extraArgs = {}) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./../../requests/legacy/request-modal/request-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'RequestsRequestModalController as vm',
      windowClass: 'modal-template modal-template-half-width quick-modal',
      resolve: {
        request: () => {
          return request;
        },
        extraArgs: () => {
          return extraArgs;
        },
      },
    }).result;
  }

  function basisDocsModal(objectId: number) {
    return $uibModal.open({
      template: require('./common/basis-docs-modal/basis-docs-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'BasisDocsModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      resolve: {
        objectId: () => {
          return objectId;
        },
      },
    }).result;
  }

  function purchasePlanModal(purchasePlan: any) {
    return $uibModal.open({
      template: require('./components/purchase-plan-modal/purchase-plan-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'PurchasePlanModalController as vm',
      windowClass: 'modal-template',
      resolve: {
        purchasePlan: () => {
          return purchasePlan;
        },
      },
    }).result;
  }

  function getContractResource(params: any) {
    if (params.deal_type === 'services') {
      return ServicesContractResource;
    }

    if (params.deal_type === 'intermediate') {
      return IntermediateContractResource;
    }
    if (params.deal_type === 'export') {
      return ExportContractResource;
    }

    return (
      {
        sale: SaleContractResource,
        purchase: PurchaseContractResource,
      } as any
    )[params.contract_type];
  }

  function updateContract(contract: any) {
    return getContractResource(contract).update({ partial: true }, contract).$promise;
  }

  function saveContract(contract: any) {
    return getContractResource(contract).save(contract).$promise;
  }

  function refreshContract(contract: any, optimizedQuery?: any, serializer?: any) {
    const params = { id: contract.id, serializer: serializer || 'full' };
    let chain;
    const resource = getContractResource(contract);

    if (optimizedQuery) {
      chain = resource.detailsFull(params).$promise;
    } else {
      chain = resource.get(params).$promise;
    }
    chain = chain.then(function (contract: any) {
      contract._show_contractprice = Boolean(contract?.contract_prices?.length);
      contract._show_derivatives = Boolean(contract?.derivatives?.length);
      setContractReadonly(contract);
      return contract;
    });

    return chain;
  }

  function getContract(params: any, typeOnly: any) {
    let resource = getContractResource(params);

    if (params.contract_type || typeOnly) {
      return resource.get(params).$promise;
    }

    return resource.get({ id: params.id, fields: 'contract_type' }).$promise.then(function (
      contract: any,
    ) {
      if (contract.contract_type === 'sale') {
        resource = SaleContractResource;
      } else if (params.contract_type === 'purchase') {
        resource = PurchaseContractResource;
      }
      return resource.get(params).$promise;
    });
  }

  function getContractsLight(queryParams: QueryParams & { serializer: string }) {
    // temporary way to handle old and new contract endpoint systems
    const newEndpointMapper: any = {
      table_info: 'queryLight',
      table_reconciliation: 'queryLightExecution',
      table_execution: 'queryLightExecution',
      table_prices: 'traidingQuery',
      table_approvals: 'approvalsQuery',
      table_quality: 'qualityQuery',
      table_mtm: 'mtmTab',
      table_prepayments_accounting: 'queryAccountingPrepayments',
    };

    const resource = getContractResource(queryParams);

    return resource[newEndpointMapper[queryParams.serializer || 'table_info']](queryParams)
      .$promise;
  }

  function reverseDeal(contract: any, notRefresh?: boolean) {
    let newObj: any = {};
    ContractResource.query({ id: contract.id, serializer: 'modal' }, function (data: any) {
      if (notRefresh) {
        newObj = ng.extend(newObj, contract);
        contract = ng.extend({}, contract);
      } else {
        newObj = ng.extend(newObj, data);
        delete newObj.general_agreement;
      }

      delete newObj.id;
      delete newObj.costs;
      delete newObj.qualities;
      delete newObj.contract_number;
      delete newObj.buyer;
      delete newObj.supplier;
      delete newObj.exporters;
      delete newObj.multicontract;
      delete newObj.approval_config;
      delete newObj.responsible;

      newObj.contract_type = newObj.contract_type === 'sale' ? 'purchase' : 'sale';
      newObj.counterparty_opposite =
        newObj.contract_type === 'sale' ? data.buyer_client : data.supplier_client;

      newObj.price = contract.price_opposite;
      newObj.price_opposite = contract.price;
      newObj.currency = contract.currency_opposite || contract.currency;
      newObj.currency_opposite = contract.currency;
      newObj.basis = contract.basis_opposite || contract.basis;
      newObj.basis_opposite = contract.basis;
      newObj.cargo = contract.commodity_opposite || contract.cargo;
      newObj.commodity_opposite = contract.cargo;
      newObj.ports = contract.ports_opposite || contract.ports;
      newObj.ports_opposite = contract.ports;
      newObj.date_of_execution = contract.date_of_execution_opposite || contract.date_of_execution;
      newObj.end_of_execution = contract.end_of_execution;
      newObj.date_of_execution_opposite = contract.date_of_execution;
      newObj.load_rate = contract.load_rate;
      newObj.discharge_rate = contract.discharge_rate;
      newObj.volume = contract.volume;
      newObj.interest_rate = 0;
      newObj.loan_percentage = 0;
      newObj.estimated_opposite_payment_date = null;

      if (newObj.stage !== 'contract') {
        return requestModal(newObj).then(function (data: any) {
          if (!data && data === 'cancel') {
            return;
          }
          newObj = data;
          return createPassport();
        });
      }

      return contractModal(newObj).then(function (data: any) {
        if (!data && data === 'cancel') {
          return;
        }
        newObj = data;
        return createPassport(newObj.contract_number);
      });
    });

    function setDefaultCurrency() {
      if (!contract.currency) {
        return FinancesService.Currency.query(
          { search: 'USD' },
          (data: any) => (contract.currency = data.results[0].id),
        ).$promise;
      } else {
        return $q.when();
      }
    }

    function createPassport(allocatedContractNumber?: any) {
      return setDefaultCurrency().then(function () {
        const marginSelectorValue = newObj.stage !== 'contract' ? 'tiket' : 'contract';
        let saleContractNumber: any;
        let purchaseContractNumber: any;
        if (contract.contract_type === 'sale') {
          saleContractNumber = contract.contract_number;
          purchaseContractNumber = allocatedContractNumber;
        } else {
          saleContractNumber = allocatedContractNumber;
          purchaseContractNumber = contract.contract_number;
        }

        return AccountsService.ApprovalConfig.getLatestActiveByObjectType({
          object_type: 'passports.Passport',
        }).$promise.then(function (response: any) {
          const approvalConfig = response.approval_config;

          return generatePassportTitle({ saleContractNumber, purchaseContractNumber }).then(
            function (data: any) {
              const passport = {
                title: data.passport_title,
                cargo: newObj.cargo,
                total_volume_plan: contract.volume,
                currency: contract.currency,
                business_unit: contract.business_unit,
                responsible: contract.responsible,
                business_date: contract.conclusion_date,
                origin_of_crop: contract.origin_of_crop,
                destination_of_crop: contract.destination_of_crop,
                vat_return_rate:
                  $rootScope.user.settings.DEFAULT_VALUES.field_passport_vat_return_rate,
                margin_selector: marginSelectorValue,
                approval_config: approvalConfig.id,
              };

              return PassportResource.save(
                passport,
                function (data: any) {
                  if (newObj.stage === 'contract') {
                    saveFacts(data);
                  } else {
                    savePlans(data);
                  }
                },
                errorClb,
              );
            },
          );
        });
      }).$promise;
    }

    function savePlans(passport: any) {
      const planResource =
          (contract.contract_type === 'sale' && PassportSalePlanResource) ||
          PassportPurchasePlanResource,
        newPlanResource =
          (contract.contract_type === 'sale' && PassportPurchasePlanResource) ||
          PassportSalePlanResource;
      return planResource
        .save({
          volume: contract.volume,
          passport: passport.id,
          request: contract.id,
        })
        .$promise.then(function () {
          return newPlanResource.save(
            {
              volume: newObj.volume,
              passport: passport.id,
              request: newObj.id,
            },
            function () {
              $state.go('gt.page.passport', { id: passport.id });
            },
          );
        })
        .catch(function (data: any) {
          errorClb(data);
        });
    }

    function saveFacts(passport: any) {
      const factResource =
          (contract.contract_type === 'sale' && SaleFactResource) || PurchaseFactResource,
        newFactResource =
          (contract.contract_type === 'sale' && PurchaseFactResource) || SaleFactResource;
      return factResource
        .save({
          volume: contract.volume,
          passport: passport.id,
          contract: contract.id,
        })
        .$promise.then(function () {
          return newFactResource.save(
            {
              volume: newObj.volume,
              passport: passport.id,
              contract: newObj.id,
            },
            function () {
              $state.go('gt.page.passport', { id: passport.id });
            },
          );
        })
        .catch(function (data: any) {
          errorClb(data);
        });
    }
  }

  function errorClb(data: any) {
    $rootScope.$broadcast('spinner-toggled', false);
    if (typeof data.data === 'string') {
      return notifyError(gettext('A server error occurred. Please contact the administrator.'));
    }
    if (data.data?.detail?.message) {
      return notifyError(data.data.detail.message);
    }
    ng.forEach(data.data, function (value: any, key: any) {
      notifyError(key + ': ' + data.data[key]);
    });
  }

  function passportModal(passport: any, data = {}) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./../../passports/legacy/passport-modal/passport-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'PassportsPassportModalController as vm',
      controllerAs: 'vm',
      windowClass: 'modal-template',
      resolve: {
        passport: () => passport,
        data: () => data,
      },
    }).result;
  }

  function getPassportSalePlan(params: object) {
    return PassportSalePlanResource.queryLight(params).$promise;
  }

  function getPassportPurchasePlan(params: object) {
    return PassportPurchasePlanResource.queryLight(params).$promise;
  }

  function contractStageModal(stage: any) {
    return $uibModal.open({
      templateUrl:
        '/static/app/contracts/stage-' +
        stage.slug +
        '-edit/stage-' +
        stage.slug +
        '-edit.tpl.html?raw',
      controller: 'StageEditController as vm',
      appendTo: getModalRoot(),
      windowClass: 'modal-template modal-template-half-width',
      resolve: {
        stage: () => {
          return { ...stage };
        },
      },
    }).result;
  }

  function executePassport(passport: any) {
    if (!confirm(gettext('Do you want to execute this passport?'))) {
      return $q.when();
    }

    let chain = Promise.resolve();

    ng.forEach(passport.sale_plans, function (plan: any) {
      if (plan.request.contract_from_request_count > 0) {
        return;
      }
      chain = chain.then(function () {
        return RequestResource.createContract({
          id: plan.request.id,
        }).$promise;
      });
    });

    ng.forEach(passport.purchase_plans, function (plan: any) {
      if (plan.request.contract_from_request_count > 0) {
        return;
      }
      chain = chain.then(function () {
        return RequestResource.createContract({
          id: plan.request.id,
        }).$promise;
      });
    });

    return chain.then(function () {
      passport.status = 'Processing';
      return PassportResource.update(passport).$promise;
    });
  }

  function approveDeal(deal: any) {
    if (deal.stage === 'contract') {
      deal.status = 'shipment';
      return updateContract({
        id: deal.id,
        contract_type: deal.contract_type,
        status: deal.status,
      });
    }
    deal._request_status = deal.request_status;
    deal.request_status = 'approved';
    return RequestResource.update(deal).$promise.then(
      function () {
        deal.request_status = deal._request_status;
        return deal;
      },
      function (data: any) {
        const GtUtils = $injector.get<GtUtilsService>('GtUtils');
        GtUtils.errorClb(data);
      },
    );
  }

  function cancelDeal(deal: any) {
    if (deal.stage === 'contract') {
      deal.status = 'cancelled';
      deal.approval_status = 'rejected';
      return updateContract({
        id: deal.id,
        contract_type: deal.contract_type,
        status: deal.status,
        approval_status: deal.approval_status,
      });
    }
    if (deal.descendants && deal.descendants.length > 0) {
      return false;
    }
    deal._request_status = deal.request_status;
    deal._approval_status = deal.approval_status;
    deal.request_status = 'cancelled';
    deal.approval_status = 'rejected';
    return RequestResource.update(deal).$promise.then(
      function () {
        deal.request_status = deal._request_status;
        deal.approval_status = deal._approval_status;
        return deal;
      },
      function (data: any) {
        const GtUtils = $injector.get<GtUtilsService>('GtUtils');
        GtUtils.errorClb(data);
      },
    );
  }

  function cloneRequest(request: any, confirmText?: string, extendObj = {}) {
    const text = confirmText ?? gettext('Do you want to clone this Request?');
    if (!confirm(text)) {
      return $q.resolve();
    }
    return getRequest({
      id: request.id,
      serializer: 'modal',
    }).then(function (request: any) {
      const newRequest = CoreUtils.cleanBeforeClone({ ...request, ...extendObj }, [
        'request_status',
        'costs',
        'qualities',
        'approval_status',
      ]);
      newRequest.contract_prices.forEach((price: any) => {
        CoreUtils.cleanBeforeClone(price, ['id', 'contract']);
      });
      return requestModal(newRequest, { clone: true });
    });
  }

  function createChildTicket(request: any) {
    return cloneRequest(request, gettext('Do you want to create ticket?'), {
      from_request: request.id,
    });
  }

  function cloneContract(contract: any) {
    const excludeFields: any = [
      'author',
      'editor',
      'author_first_name',
      'editor_first_name',
      'author_last_name',
      'author_avatar',
      'editor_avatar',
      'create_time',
      'update_time',
      'id',
      'status',
      'costs',
      'from_request',
      'kpi_signing_success',
      'multicontract',
      'contract',
      'final_volume',
    ];

    const additionalFields = $rootScope.user.settings.EXCLUDE_FIELDS_ON_CONTRACT_COPY;

    if (
      additionalFields &&
      (contract.contract_type === 'sale' || contract.contract_type === 'purchase')
    ) {
      const fieldsArray = additionalFields.split(',').map((field: any) => field.trim());
      excludeFields.push(...fieldsArray);
    }

    const initialContract = { derivatives: [], qualities: [], contract_prices: [] };

    const cleanObj = (object: any, initialObject = {}) =>
      Object.keys(object)
        .filter((key) => object[key] && !excludeFields.includes(key))
        .reduce((obj, key) => ({ ...obj, [key]: object[key] }), initialObject);

    return refreshContract(contract, false, 'modal').then((data: any) => {
      const {
        derivatives,
        qualities,
        contract_prices: contractPrices,
        ...newContract
      }: any = cleanObj(data, initialContract);

      return contractModal({
        ...newContract,
        derivatives: derivatives.map((derivative: any) => cleanObj(derivative)),
        qualities: qualities.map((quality: any) => cleanObj(quality)),
        contract_prices: contractPrices.map((price: any) => cleanObj(price)),
      });
    });
  }

  function cloneGeneralAgreement(agreement: any) {
    if (!confirm(gettext('Do you want to clone this General Agreement ?'))) {
      return $q.resolve();
    }
    return GeneralAgreementsResource.get({
      id: agreement.id,
    }).$promise.then(function (data: any) {
      agreement = data;
      const newObject = ng.extend({}, agreement);
      delete newObject.id;
      delete newObject.status;
      delete newObject.costs;
      delete newObject.qualities;
      delete newObject.from_request;
      return generalAgreementsModal(newObject);
    });
  }

  function cloneConsignment(consignmentId: number) {
    if (!confirm(gettext('Do you want to clone this Consignment?'))) {
      return $q.resolve();
    }
    return ConsignmentsResource.get({
      id: consignmentId,
    }).$promise.then(function (data: any) {
      const consignment = data;
      const newObject = ng.extend({}, consignment);
      delete newObject.id;
      return consignmentsModal(newObject);
    });
  }

  function createTicketFromPotential(id: number, unconnectedVolume: any) {
    return PotentialService.cropAreaResource.get({ id }).$promise.then((data: any) => {
      const request: any = {
        cargo: data.cargo,
        crop_year: data.crop_year,
        price: data.price,
        stage: 'ticket',
        currency: data.currency,
        currency_exchange: data.currency_exchange,
        contract_type: 'purchase',
        deal_type: 'spot',
        conclusion_date: new Date(),
        from_potential: id,
        request_status: 'new',
        approval_status: 'wait',
        responsible: $rootScope.user.id,
        id: null,
      };
      return ClientsService.Client.get({ id: data.client }).$promise.then((client: any) => {
        request.volume = parseFloat(
          prompt(
            gettext('This action will create new ticket. ' + 'How much to bite off?'),
            `${data.free_volume}`,
          ) ?? '',
        );
        if (!request.volume || request.volume <= 0) {
          if (request.volume) {
            alert(gettext('Wrong volume'));
          }
          return;
        }
        if (request.volume > unconnectedVolume) {
          notify(
            gettext('Indicator free volume exceeded! Available free volume: ') + unconnectedVolume,
            'error',
          );
          return;
        }
        const supplierRole = client.role_names_set.find(
          (role: any) => role.role_name === 'supplier',
        );
        if (supplierRole) {
          request.potential_supplier = supplierRole.id;
          request.potential_supplier_name = client.name;
        }
        return requestModal(request).then((ticket: any) =>
          RequestResource.updateQualityFromCropArea({
            id: ticket.id,
            crop_area: id,
          }).$promise.then(() => $state.go('gt.page.request', { id: ticket.id })),
        );
      });
    });
  }

  function createTicketFromIndicator(id: number) {
    return getRequestsInfo({ id_list: [id] }).then((indicatorData: any) => {
      const indicator = indicatorData.results[0] || {};
      let request: any = {};
      return FinancesService.CurrencyExchange.query({
        local_currency: indicator.currency,
      }).$promise.then((data: any) => {
        request = {
          ...indicator,
          stage: 'ticket',
          conclusion_date: new Date(),
          from_request: indicator.id,
          request_status: 'new',
          approval_status: 'wait',
          currency_exchange:
            indicator.currency !== FinancesService.getDefaultCurrency().id
              ? data.results[0]?.id
              : null,
          responsible: $rootScope.user.id,
          id: null,
          quantity: null,
        };
        request.volume = parseFloat(
          prompt(
            gettext('This action will create new ticket. How much to bite off?'),
            `${request.unconnected_final_volume}`,
          ) ?? '',
        );
        if (!request.volume || request.volume <= 0) {
          if (request.volume) {
            alert(gettext('Wrong volume'));
          }
          return;
        }
        if (request.volume > request.unconnected_final_volume) {
          notify(
            gettext('Indicator free volume exceeded! Available free volume: ') +
              request.unconnected_final_volume,
            'error',
          );
          return;
        }
        return requestModal(request, { createTicketFromIndicator: true }).then((data: any) => {
          if (data === 'save') {
            $state.go('gt.page.request', { id: data.id });
          }
        });
      });
    });
  }

  function createTicket(id: number, unconnectedVolume?: number, createFrom?: string) {
    if (createFrom === 'potential') {
      return createTicketFromPotential(id, unconnectedVolume);
    }
    createTicketFromIndicator(id);
  }

  function addLogistic(contract: any) {
    return refreshContract(contract).then(function (contract: any) {
      const logistic: any = { cargo: contract.cargo };
      if (contract.contract_type === 'sale') {
        logistic.buyer_contract = contract.id;
      } else {
        logistic.supplier_contract = contract.id;
      }
      return LogisticsService.logisticModal(logistic);
    });
  }

  function addFinance(contract: any, paymentConditionsKey?: string, invoiceStatus?: string) {
    return refreshContract(contract, true).then(function (contract: any) {
      const amount = contract.total_value_local;

      const finance = {
        invoice_type: contract.contract_type === 'sale' ? 'outgoing' : 'incoming',
        clientrole_from: contract.contract_type === 'sale' ? contract.buyer : contract.supplier,
        clientrole_to: contract.contract_type === 'sale' ? contract.supplier : contract.buyer,
        business_unit: contract.business_unit,
        season: contract.season,
        contract: contract.id,
        cargo: contract.cargo,
        volume: contract.final_volume || contract.volume,
        amount: amount,
        full_amount: amount,
        currency_exchange: contract.currency_exchange,
        currency: contract.currency,
        responsible: contract.responsible,
        responsible_for_signing: contract.responsible_for_signing,
        discount: contract.discount,
        discount_amount: contract.discount_amount,
        discount_mt: contract.discount_mt,
        counterparty_business_reference: contract.counterparty_business_reference,
        payment_conditions_option: contract.payment_conditions_option,
        payment_conditions:
          (invoiceStatus === 'prepayment' &&
            contract[paymentConditionsKey ?? 'payment_conditions']) ||
          100,
        _days_for_invoice_payment: contract.days_for_invoice_payment,
      };

      const contractData = {
        contract_purpose: contract.contract_purpose,
        total_value_local: contract.total_value_local,
        payment_conditions: contract[paymentConditionsKey ?? 'payment_conditions'] || 100,
        customs_broker: contract.customs_broker,
        destination_of_crop: contract.destination_of_crop,
      };

      let financePosition: any = {
        use: 'cargo',
        contract: contract.id,
        crop: contract.cargo,
        quantity: contract.final_volume || contract.volume,
        price_per_piece: contract.price - contract.discount_mt || 0,
        vat_option: contract.VAT_option,
        vat_value: contract.VAT_value,
        amount: amount,
      };
      if (contract.deal_type === 'services') {
        financePosition.use = 'costs';
        financePosition.vat_option = true;
        financePosition._charge_id = contract.charge;
        financePosition.vat_value = $rootScope.user.settings.CHARGE_VAT_VALUE;
      }

      if (invoiceStatus === 'prepayment') {
        financePosition = {
          ...financePosition,
          ...{
            quantity:
              ((contract.final_volume || contract.volume) * contract.payment_conditions) / 100,
            quantity_total: contract.final_volume || contract.volume,
            amount: (amount * contract.payment_conditions) / 100,
          },
        };
      }

      return FinancesService.financeModal(finance, {
        contract: contractData,
        financePositions: [financePosition],
      });
    });
  }

  function addBalanceInvoice(contract: any) {
    return refreshContract(contract, true).then(function (contract: any) {
      const finance = {
        invoice_type: 'incoming',
        full_amount:
          contract.price * (contract.volume_with_option - (contract.payment_volume || 0)),
        business_unit: contract.business_unit,
        season: contract.season,
        currency_exchange: contract.currency_exchange,
        currency: contract.currency,
        responsible: contract.responsible,
        responsible_for_signing: contract.responsible_for_signing,
        discount: contract.discount,
        discount_amount: contract.discount_amount,
        discount_mt: contract.discount_mt,
        counterparty_business_reference: contract.counterparty_business_reference,
        condition: 'balance',
      };

      if (contract.contract_type === 'sale') {
        finance.invoice_type = 'outgoing';
      }

      const financePosition = {
        use: 'cargo',
        contract: contract.id,
        crop: contract.cargo,
        quantity: contract.volume_with_option - (contract.payment_volume || 0),
        price_per_piece: contract.price - contract.discount_mt || 0,
        amount:
          contract.price - contract.discount_mt ||
          0 * (contract.volume_with_option - contract.payment_volume || 0),
        quantity_total: contract.volume_with_option - contract.payment_volume || 0,
        vat_option: contract.VAT_option,
        vat_value: contract.VAT_value,
      };

      return FinancesService.financeModal(finance, {
        financePositions: [financePosition],
      });
    });
  }

  function getDefaultContract(contract: any) {
    const newContract: any = {
      payment_conditions_descr: $rootScope.user.settings.DEFAULT_VALUES.payment_conditions_descr,
      payment_conditions: $rootScope.user.settings.DEFAULT_VALUES.payment_conditions,
      payment_conditions_auto: $rootScope.user.settings.DEFAULT_VALUES.payment_conditions_auto,
      volume_options: $rootScope.user.settings.DEFAULT_VALUES.volume_options,
      volume_options_company: $rootScope.user.settings.DEFAULT_VALUES.volume_options_company,
      delivery_condition: $rootScope.user.settings.DEFAULT_VALUES.delivery_condition,
      crop_year: $rootScope.user.settings.DEFAULT_VALUES.crop_year,
    };
    if (contract.contract_type === 'purchase') {
      newContract.buyer = $rootScope.user.settings.DEFAULT_VALUES.owner;
      newContract.supplier = undefined;
      newContract.basis = $rootScope.user.settings.DEFAULT_VALUES.basis_purchase;
    } else {
      newContract.contract_type = 'sale';
      newContract.supplier = $rootScope.user.settings.DEFAULT_VALUES.owner;
      newContract.buyer = undefined;
      newContract.basis = $rootScope.user.settings.DEFAULT_VALUES.basis_sale;
    }
    return newContract;
  }

  function getPagesConfig() {
    const config: any = [
      {
        title: gettext('Tickets'),
        permissions: [],
        state: 'tickets.' + $rootScope.user.profile?.deals_show,
        icon: 'fa-file-text-o',
        tabs: [
          {
            title: gettext('Sale'),
            state: 'tickets.sale',
            icon: 'fa-arrow-up',
          },
          {
            title: gettext('Purchase'),
            state: 'tickets.purchase',
            icon: 'fa-arrow-down',
          },
        ],
      },
    ];

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_export) {
      config[0].tabs.push({
        title: gettext('Export'),
        state: 'tickets.export',
        icon: 'fa fa-arrow-up-from-bracket',
      });
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_contract) {
      config.unshift({
        title: gettext('Contracts'),
        permissions: [],
        state: 'contracts.' + $rootScope.user.profile?.deals_show,
        icon: 'fa-file-text',
        counter: 'contracts.contractbase',
        counterParams: { stage: 'contract' },
        tabs: [],
      });
    }

    if (
      !$rootScope.user.settings.ONLY_GENERAL_AGREEMENTS &&
      $rootScope.user.settings.SYSTEM_BLOCKS.block_deals_contract
    ) {
      config[0].tabs.push(
        {
          title: gettext('Sale'),
          state: 'contracts.sale',
          icon: 'fa-arrow-up',
        },
        {
          title: gettext('Purchase'),
          state: 'contracts.purchase',
          icon: 'fa-arrow-down',
        },
      );
    }

    if (
      $rootScope.user.settings.SYSTEM_BLOCKS.block_deals_contract &&
      $rootScope.user.settings.SYSTEM_BLOCKS.block_deals_services &&
      !$rootScope.user.settings.ONLY_GENERAL_AGREEMENTS
    ) {
      config.push({
        title: gettext('Services'),
        state: 'contracts.' + $rootScope.user.profile?.services_show,
        icon: 'fa-dollar-sign',
        tabs: [
          {
            title: gettext('Direct Tickets'),
            state: 'tickets.servicesPositionsDirect',
            icon: 'fa fa-file-text-o',
            permissions: ['view_servicesticket'],
          },
          {
            title: gettext('General Tickets'),
            state: 'tickets.servicesPositionsGeneral',
            icon: 'fa fa-money',
            permissions: ['view_servicesticket'],
          },
          {
            title: gettext('Direct Contracts'),
            state: 'contracts.servicesPositionsDirect',
            icon: 'fa fa-file-text',
          },
          {
            title: gettext('General Contracts'),
            state: 'contracts.servicesPositionsGeneral',
            icon: 'fa fa-money',
          },
        ],
      });
    }

    if (
      $rootScope.user.settings.SYSTEM_BLOCKS.block_deals_contract &&
      $rootScope.user.settings.SYSTEM_BLOCKS.block_deals_intermediate &&
      !$rootScope.user.settings.ONLY_GENERAL_AGREEMENTS
    ) {
      config[0].tabs.push({
        title: gettext('Intermediate'),
        state: 'contracts.intermediate',
        icon: 'fa fa-left-right',
      });
    }

    if (
      $rootScope.user.settings.SYSTEM_BLOCKS.block_deals_contract &&
      $rootScope.user.settings.SYSTEM_BLOCKS.block_deals_export &&
      !$rootScope.user.settings.ONLY_GENERAL_AGREEMENTS
    ) {
      config[0].tabs.push({
        title: gettext('Export'),
        state: 'contracts.export',
        icon: 'fa fa-arrow-up-from-bracket',
      });
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_agreements) {
      const agreements = {
        title: gettext('Agreements'),
        state: 'contracts.agreements',
        icon: 'fa-handshake-o',
        permissions: ['view_general_agreement'],
        tabs: [
          {
            title: gettext('Sale'),
            state: 'contracts.saleAgreements',
            icon: 'fa-arrow-up',
            permissions: ['view_general_agreement'],
          },
          {
            title: gettext('Purchase'),
            state: 'contracts.purchaseAgreements',
            icon: 'fa-arrow-down',
            permissions: ['view_general_agreement'],
          },
        ],
      };
      if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_services) {
        agreements.tabs.push({
          title: gettext('Services'),
          state: 'contracts.servicesAgreements',
          icon: 'fa-dollar-sign',
          permissions: ['view_general_agreement'],
        });
      }
      if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_intermediate) {
        agreements.tabs.push({
          title: gettext('Intermediate'),
          state: 'contracts.intermediateAgreements',
          icon: 'fa-left-right',
          permissions: ['view_general_agreement'],
        });
      }
      if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_export) {
        agreements.tabs.push({
          title: gettext('Export'),
          state: 'contracts.exportAgreements',
          icon: 'fa-left-right',
          permissions: ['view_general_agreement'],
        });
      }
      config.push(agreements);
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_multiticket) {
      config.push({
        title: gettext('MultiTicket'),
        state: 'tickets.multirequest',
        icon: 'fa-clipboard',
      });
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_calculator) {
      config.push({
        title: gettext('Calculator'),
        state: 'tickets.prices',
        icon: 'fa-calculator',
      });
    }

    return config;
  }

  function getMulticontractPagesConfig() {
    const config: any = [
      {
        title: gettext('Multicontracts'),
        state: 'multicontractsList.sale',
        icon: 'fa-clipboard',
        counter: 'contracts.multicontract',
        counterParams: { stage: 'contract' },
        tabs: [],
      },
    ];

    if (
      $rootScope.user.settings.SYSTEM_BLOCKS.block_deals_multicontract &&
      !$rootScope.user.settings.ONLY_GENERAL_AGREEMENTS
    ) {
      config[0].tabs.push(
        {
          title: gettext('Sale'),
          state: 'multicontractsList.sale',
          icon: 'fa-arrow-up',
          permissions: ['view_salemulticontract'],
        },
        {
          title: gettext('Purchase'),
          state: 'multicontractsList.purchase',
          icon: 'fa-arrow-down',
          permissions: ['view_purchasemulticontract'],
        },
      );

      if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_intermediate) {
        config[0].tabs.push({
          title: gettext('Intermediate'),
          state: 'multicontractsList.intermediate',
          icon: 'fa fa-left-right',
          permissions: ['view_intermediatemulticontract'],
        });
      }

      if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_export) {
        config[0].tabs.push({
          title: gettext('Export'),
          state: 'multicontractsList.export',
          icon: 'fa fa-arrow-up-from-bracket',
          permissions: ['view_exportmulticontract'],
        });
      }
    }

    return config;
  }

  function getMulticontractServicesPagesConfig() {
    const config: any = [
      {
        title: gettext('MultiServices'),
        state: 'multicontractsList.servicesDirect',
        icon: 'fa-dollar-sign',
        tabs: [],
        permissions: ['view_servicesmulticontract'],
      },
    ];

    if (
      $rootScope.user.settings.SYSTEM_BLOCKS.block_deals_multiservices_ticket &&
      !$rootScope.user.settings.ONLY_GENERAL_AGREEMENTS
    ) {
      config[0].tabs.push(
        {
          title: gettext('Direct Tickets'),
          state: 'multiticketsList.servicesDirect',
          icon: 'fa fa-file-text',
          permissions: ['view_servicesticket'],
        },
        {
          title: gettext('General Tickets'),
          state: 'multiticketsList.servicesGeneral',
          icon: 'fa fa-money',
          permissions: ['view_servicesticket'],
        },
      );
    }

    if (
      $rootScope.user.settings.SYSTEM_BLOCKS.block_deals_multiservices_contract &&
      !$rootScope.user.settings.ONLY_GENERAL_AGREEMENTS
    ) {
      config[0].tabs.push(
        {
          title: gettext('Direct Contracts'),
          state: 'multicontractsList.servicesDirect',
          icon: 'fa fa-file-text',
          permissions: ['view_servicesmulticontract'],
        },
        {
          title: gettext('General Contracts'),
          state: 'multicontractsList.servicesGeneral',
          icon: 'fa fa-money',
          permissions: ['view_servicesmulticontract'],
        },
      );
    }

    return config;
  }

  function getPassportPagesConfig() {
    const config: any = [
      {
        title: gettext($rootScope.user.settings.PASSPORT_TITLE),
        permissions: [],
        state: 'abstract-passports.passports',
        icon: 'fa-exchange',
        counter: 'passports.passport',
        tabs: [],
      },
    ];

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_deals_multipassport) {
      config[0].tabs.push(
        {
          title: gettext($rootScope.user.settings.PASSPORT_TITLE),
          state: 'abstract-passports.passports',
          icon: 'fa-exchange',
        },
        {
          title: gettext('Multipassport'),
          state: 'abstract-passports.multipassports',
          icon: 'fa-clipboard',
        },
      );
    }

    return config;
  }

  function createPassportContract(contracts: any[], multicontract?: any, onModalClose?: any) {
    const contract = multicontract || (contracts.length ? contracts[0] : {});
    const aggregatedData = contracts.reduce(
      (acc: any, contract: any) => {
        if (acc.cargo !== contract.cargo) {
          acc.cargo = null;
        }
        acc.volumeSum += contract.volume;
        return acc;
      },
      { cargo: contracts[0].cargo, volumeSum: 0 },
    );

    const passport: any = {
      cargo: aggregatedData.cargo,
      total_volume_plan: aggregatedData.volumeSum,
      conclusion_date: contract.conclusion_date,
      currency_exchange: contract.currency_exchange,
      volume_options: contract.volume_options,
      business_unit: contract.business_unit,
      responsible: contract.responsible,
      currency: contract.currency,
      business_date: contract.conclusion_date,
      origin_of_crop: contract.origin_of_crop,
      destination_of_crop: contract.destination_of_crop,
    };

    if (multicontract && multicontract.contract_type === 'sale') {
      passport.sap_orders = [
        ...new Set(
          (multicontract.contracts_set || []).flatMap((contract: any) =>
            Array.isArray(contract.sap_orders_ids) ? contract.sap_orders_ids : [],
          ),
        ),
      ];
    } else if (contract && contract.contract_type === 'sale') {
      passport.sap_orders = (contract.sap_orders_data || []).map((sapOrder: any) => sapOrder.id);
    }

    const contractType =
      contract.contract_type === 'sale' ? 'saleContractNumber' : 'purchaseContractNumber';

    const params: any = { [contractType]: contract.contract_number };

    if (multicontract) {
      const volumeFloat = parseFloat(multicontract.volume);
      params.multicontract_volume = Number.isInteger(volumeFloat)
        ? volumeFloat.toString()
        : volumeFloat.toFixed(2);

      params.multicontract_number = multicontract.number;
      params.multicontract_conclusion_date = multicontract.conclusion_date;
      params.multicontract_buyer_id = multicontract.buyer;
      params.multicontract_supplier_id = multicontract.supplier;
    }

    generatePassportTitle(params).then((data: any) => {
      passport.title = data.passport_title;
      return data;
    });

    const onSave = function (position: any, createdPassport: any) {
      const connectionModelResource = (
        {
          ticket: {
            sale: PassportSalePlanResource,
            purchase: PassportPurchasePlanResource,
          },
          contract: {
            sale: SaleFactResource,
            purchase: PurchaseFactResource,
          },
        } as any
      )[position.stage][position.contract_type];
      let connectVolume = position.final_volume || position.volume;
      if (connectVolume > createdPassport.total_volume_plan) {
        connectVolume = createdPassport.total_volume_plan;
      }
      return connectionModelResource.save({
        contract: position.id,
        request: position.id,
        passport: createdPassport.id,
        volume: connectVolume,
      }).$promise;
    };

    const onSaveForAll = function (createdPassport: any) {
      const promises = contracts.map((contract: any) => onSave(contract, createdPassport));
      return Promise.all(promises);
    };

    return passportModal(passport, {
      onSave: onSaveForAll,
      tab: ({ ticket: 'plan', contract: 'fact' } as any)[contract.stage],
    }).then((passport: any) => {
      if (!passport || passport === 'cancel') {
        return;
      }
      return (
        onModalClose?.() ||
        $state.go('gt.page.passport', {
          id: passport.id || 0,
        })
      ).$promise;
    });
  }

  function getMainLoyaltyProgram() {
    return LoyaltyProgramsService.getMainProgram();
  }

  function connectToPassport(params: any) {
    let message = 'How much to take from deal?';
    if (params.contract_purpose === 'export' && params.volume <= 0) {
      message = message + ' Not enough value. Please create new export contract';
    }
    const volumeStr =
      prompt($filter<(str: string) => string>('translate')(gettext(message)), params.volume) ?? '';
    if (!volumeStr) {
      return $q.resolve();
    }
    const volume = $filter<(str: string) => number>('commaToDecimal')(volumeStr);

    if (!volume || volume <= 0) {
      notifyError(gettext('Volume must be greater than 0'));
      return $q.resolve();
    }
    if ($rootScope.user.settings.PASSPORT_CHECK_VOLUME && volume > params.volume) {
      notifyError(gettext('This contract cannot be connected with this volume to passport.'));

      return $q.resolve();
    }

    return PassportResource.attach_deal({
      id: params.id,
      deal_id: params.deal_id,
      stage: params.stage,
      contract_purpose: params.contract_purpose,
      volume: volumeStr,
    }).$promise;
  }

  function consignmentsModal(consignment: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./components/consignment-modal/consignment-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'ConsignmentModalController as vm',
      windowClass: 'modal-template',
      resolve: {
        consignment: () => {
          return consignment;
        },
      },
    }).result;
  }

  function generalAgreementsModal(agreement?: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./components/general-agreement-modal/general-agreement-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'GeneralAgreementModalController as vm',
      windowClass: 'modal-template-three-thirds-width',
      resolve: {
        agreement: () => {
          return agreement;
        },
      },
    }).result;
  }

  function contractChargeModal(contractCharge: any) {
    return $uibModal.open({
      backdrop: 'static',
      appendTo: getModalRoot(),
      template: html`<contract-charge-modal
        contract-charge="contractCharge"
        modal-instance="$uibModalInstance"
      >
      </contract-charge-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
          ($scope as any).$uibModalInstance = $uibModalInstance;
          ($scope as any).contractCharge = contractCharge;
        },
      ],
      windowClass: 'modal-template',
      size: 'sm',
    }).result;
  }

  function additionalAgreementModal(additionalAgreement: any, contract: any, multicontract?: any) {
    return $uibModal.open({
      backdrop: 'static',
      appendTo: getModalRoot(),
      template: html`<additional-agreement-modal
        additional-agreement="additionalAgreement"
        contract="contract"
        multicontract="multicontract"
        modal-instance="$uibModalInstance"
      >
      </additional-agreement-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
          ($scope as any).$uibModalInstance = $uibModalInstance;
          ($scope as any).additionalAgreement = additionalAgreement;
          ($scope as any).contract = contract;
          ($scope as any).multicontract = multicontract;
        },
      ],
      windowClass: 'modal-template-third-width',
      size: 'sm',
    }).result;
  }

  function getContractNumber(
    position: any,
    multicontract?: any,
  ): Promise<{ contract_number: string }> {
    let conclusionDate;
    let dateOfExecution;
    let endOfExecution;
    let estimatedDateExecution;
    const contract = multicontract || {};
    if (!position.auto_name) {
      return Promise.resolve({ contract_number: position.contract_number });
    }

    if (position.conclusion_date) {
      conclusionDate = formatDate(position.conclusion_date, 'yyyy-MM-dd');
    }
    if (position.date_of_execution) {
      dateOfExecution = formatDate(position.date_of_execution, 'yyyy-MM-dd');
    }
    if (position.end_of_execution) {
      endOfExecution = formatDate(position.end_of_execution, 'yyyy-MM-dd');
    }
    if (position.estimated_date_execution) {
      estimatedDateExecution = formatDate(position.estimated_date_execution, 'yyyy-MM-dd');
    }

    const params = {
      supplier_id: position.supplier,
      buyer_id: position.buyer,
      cargo_id: position.cargo,
      charge_id: position.charge,
      currency_id: position.currency,
      basis_id: position.basis,
      price: position.price,
      volume: position.volume,
      contract_type: position.contract_type,
      deal_type: position.deal_type || contract.use_type,
      responsible_id: position.responsible,
      create_time: position.create_time,
      conclusion_date: conclusionDate,
      date_of_execution: dateOfExecution,
      end_of_execution: endOfExecution,
      estimated_date_execution: estimatedDateExecution,
      business_unit: position.business_unit,
      origin_of_crop_id: position.origin_of_crop,
      destination_of_crop_id: position.destination_of_crop,
      origins_of_crop: position.origins_of_crop,
      stage: position.stage,
      counterparty_opposite_id: position.counterparty_opposite,
      ports: position.ports,
      terminal_id: position.terminal,
      shipment_terminal_id: position.shipment_terminal,
      general_agreement_id: position.general_agreement,
      fixed_price_bonus: position.fixed_price_bonus,
      contract: position.contract,
      contract_number: contract.number,
      sequence_number: position.sequence_number,
    };
    return ContractResource.generateNumber(params, (data: any) => data.contract_number).$promise;
  }

  function getAgreementNumber(agreement: any) {
    let dateOfExecution;
    if (agreement.date_of_execution) {
      dateOfExecution = formatDate(agreement.date_of_execution, 'yyyy-MM-dd');
    }

    let dateOfConclusion;
    if (agreement.conclusion_date) {
      dateOfConclusion = formatDate(agreement.conclusion_date, 'yyyy-MM-dd');
    }

    const params = {
      agreement_type: agreement.agreement_type,
      commodity_id: agreement.commodity,
      date_of_execution: dateOfExecution,
      conclusion_date: dateOfConclusion,
    };

    return GeneralAgreementsResource.generateNumber(params, (data: any) => data.agreement_number)
      .$promise;
  }

  function getRoleLimits(roleName: string, roleId: number) {
    roleName = roleName.toLowerCase();
    return ContractResource.getRoleLimits({
      role_name: roleName,
      role_id: roleId,
    }).$promise;
  }

  function getContractTotal(params: object, view: any) {
    if (view === 'list') {
      return {};
    }
    return getContractResource(params)
      .executionTotals(params)
      .$promise.then((totalData: any) => {
        return totalData;
      });
  }

  function getContractList(params: any) {
    return getContractsLight(params);
  }

  function getRequest(params: object) {
    return RequestResource.get(params, function (request: any) {
      request._show_contractprice = Boolean(request.contract_prices.length);
      return request;
    }).$promise;
  }

  function getRequestCalculatedAmount(request: any) {
    if (!request.VAT_option) {
      return $q.when().then(function () {
        return request;
      });
    }
    if (!request.price || !request.volume || !request.VAT_value) {
      return $q.when().then(function () {
        request.calculated_amount_with_vat = 0;
        return request;
      });
    }

    return FinancesService.applyVat({
      value: request.price,
      volume: request.volume,
      vat_value: request.VAT_value,
    }).then((value) => {
      request.calculated_amount_with_vat = value;
      return value;
    });
  }

  function getPassportIdListByContract(contractId: number) {
    if (!contractId) {
      return $q.resolve([]);
    }
    return PassportResource.predictions({
      contract: contractId,
    }).$promise.then((data: any) => {
      return data.results;
    });
  }

  function createMultiContractCharge(charge: any, params: any) {
    if (!charge.multicontract || charge.id) {
      return [];
    }

    const newContractCharges: any = [];
    let newContractCharge: any = {};
    const perVolume = charge.price_t > 0 ? 't' : 'per_deal';

    params.contracts_set
      .filter((position: any) => {
        return position.status != 'draft';
      })
      .map((position: any) => {
        newContractCharge = {
          ...charge,
          contract_true_volume: position.true_volume,
          contract_price: position.price,
          contract: position.id,
        };
        if (Boolean(charge.percentage) || Boolean(charge.price_t)) {
          newContractCharge = calcContractChargePrice(newContractCharge, perVolume, false);
        } else {
          newContractCharge.price =
            Math.round(
              ((charge.price * position.true_volume) / params.contracts_true_volume_sum) * 100,
            ) / 100;
        }
        newContractCharges.push(newContractCharge);
      });
    return newContractCharges;
  }

  function calcContractChargePrice(contractCharge: any, perVolume: any, isPriceChanged?: boolean) {
    const charge = { ...contractCharge };
    const volume = charge.volume || charge.contract_true_volume;
    const priceT = charge.price_t || charge.contract_price;
    let chargePrice = 0;
    if (isPriceChanged) {
      charge.price_t = 0;
      return charge;
    }

    if (charge.percentage) {
      chargePrice = Number((priceT * volume * charge.percentage) / 100);
      charge.price = Math.round((chargePrice + Number.EPSILON) * 100) / 100 || 0;
      return charge;
    }
    if (perVolume === 't') {
      chargePrice = Number(charge.price_t * volume);
      charge.price = Math.round((chargePrice + Number.EPSILON) * 100) / 100 || 0;
      return charge;
    }
    if (charge.volume) {
      chargePrice = Number(charge.price / volume);
      charge.price_t = Math.round((chargePrice + Number.EPSILON) * 100) / 100 || 0;
      return charge;
    }
    return charge;
  }

  function isChargeUnique(idParam: any, charge: any) {
    if (!idParam) {
      return $q.resolve(true);
    }
    return ContractChargeResource.queryInfo(idParam).$promise.then(
      (data: any) =>
        !data.results.some(
          (cost: any) =>
            cost.charge === charge.charge &&
            cost.price === charge.price &&
            cost.is_gain === Boolean(charge.is_gain),
        ),
    );
  }

  function checkChargeAndConfirmNotUnique(charge: any) {
    if (charge.id) {
      return $q.when(true);
    }
    if (!charge.contract && !charge.passport && !charge.logistic) {
      errorClb('You have to fill passport or contract or logistic');
    }

    const text = `The ${charge.passport ? 'passport' : 'contract'}
      already contains a cost with the same name and amount. Are you sure you want to save it?`;
    const passportParams = charge.passport ? { passport_list: charge.passport } : undefined;
    const contractParams = charge.contract ? { contract_list: charge.contract } : undefined;
    const idParams = passportParams ?? contractParams ?? {};
    return isChargeUnique(idParams, charge).then((result: any) => result || confirm(gettext(text)));
  }

  function setVatValue(contract: any) {
    if (!contract.VAT_option || !contract.cargo) {
      return;
    }
    CropsService.Crop.get({ id: contract.cargo }).$promise.then((crop: any) => {
      contract.VAT_value = crop.vat_value || $rootScope.user.settings.VAT_VALUE;
    });
  }

  function getUniqueCounterparties(ids: any) {
    return ContractResource.uniqueCounterparties({ ids }).$promise;
  }

  function getContracts(params: object) {
    return ContractResource.query(params).$promise;
  }

  function setCurrencyOwner(contract: any) {
    if (contract.id) {
      return false;
    }

    FinancesService.Currency.query({ id: contract.currency }).$promise.then((data: any) => {
      const fallbackOwner = data.is_local
        ? $rootScope.user.settings.DEFAULT_VALUES.owner
        : $rootScope.user.settings.DEFAULT_VALUES.foreign_owner;

      if (contract.contract_type === 'sale') {
        contract.supplier = contract.supplier || fallbackOwner;
      } else {
        contract.buyer = contract.buyer || fallbackOwner;
      }
    });
  }

  function setCurrencyExchange(contractBaseObject: any) {
    if (
      !contractBaseObject.currency ||
      contractBaseObject.currency == FinancesService.getDefaultCurrencyId()
    ) {
      return;
    }
    FinancesService.getCurrencyExchangeByDate(
      contractBaseObject.currency,
      formatDate(contractBaseObject.conclusion_date, 'dd.MM.yyyy'),
    ).then((data: any) => {
      if (data.count === 0) {
        notify(gettext('No currency exchange rate found'), 'error');
        contractBaseObject.currency_exchange = null;
        return;
      }
      contractBaseObject.currency_exchange = data.results[0].id;
      contractBaseObject.currency = data.results[0].local_currency;
    });
  }

  function getGeneralAgreement(this: any, contract: any) {
    if (contract.id || !contract.general_agreement) {
      return;
    }

    this.GeneralAgreements.get({
      id: contract.general_agreement,
      serializer: 'full',
    }).$promise.then(
      (data: any) => {
        contract.general_agreement = data.id;
        contract.currency = data.currency;
        contract.business_unit = data.business_unit;
        handleCropInAncestors(contract, data.commodity);
      },
      (error: any) => errorClb(error),
    );
  }

  function handleCropInAncestors(contract: any, commodity: any) {
    isCropInAncestors(contract, commodity)
      .then((shouldChange: any) => {
        if (!shouldChange.is_child_crop_in_ancestors) {
          contract.cargo = commodity;
        }
      })
      .catch((error: any) => {
        errorClb(error);
      });
  }

  function isCropInAncestors(contract: any, cargo: any) {
    if (!contract.from_request || contract.stage !== 'ticket') {
      return $q.resolve({ should_change_cargo: true });
    }

    return CropsService.Crop.isCropInAncestors({
      child_crop_id: contract.cargo,
      parent_crop_id: cargo,
    }).$promise;
  }

  function setContractReadonly(contract: any) {
    contract.readonly =
      !AccountsService.hasPerm('edit_closed_elements') && contract.is_blocked_for_use;
  }

  function setApprovalToNull(contract: any) {
    if (!contract.id && contract.is_position_closing) {
      contract.approval_config = null;
    }
  }

  function getContractPurpose(contract: any) {
    if (['services', 'intermediate', 'export'].includes(contract.deal_type)) {
      return contract.deal_type;
    }
    return contract.contract_type;
  }

  function getVolumeAvailableToConnect(contract: any, passportId: number) {
    const availableToConnect = Math.max(0, contract.available_to_connect);
    if (contract.deal_type !== 'export' || availableToConnect === 0) {
      return Promise.resolve(availableToConnect);
    }
    return PassportResource.unconnectedVolume({ id: passportId }).$promise.then((data: any) => {
      return Math.min(availableToConnect, data.unconnected_volume);
    });
  }

  function createServiceContract(item: any) {
    const resource = item?.stage === 'ticket' ? RequestResource : ContractResource;
    return resource.query({ id: item.id, serializer: 'modal' }).$promise.then((data: any) => {
      const serviceContract = {
        deal_type: 'services',
        contract_type: 'purchase',
        cargo: data?.cargo,
        volume: data?.volume,
        contract_prices: data?.contract_prices,
        contractprices_data: data?.contractprices_data,
        _show_contractprice: Boolean(data?.contract_prices?.length),
        volume_options: data?.volume_options,
        prices_auto: data?.prices_auto,
        prices_transshipment: data?.prices_transshipment,
        prices_railway: data?.prices_railway,
        delivery_condition: data?.delivery_condition,
        delivery_conditions: data?.delivery_conditions,
        deliverer: data?.deliverer,
        farm: data?.farm,
        farms: data?.farms,
        elevator: data?.elevator,
        elevators: data?.elevators,
        loading_addresses: data?.loading_addresses,
        delivery_addresses: data?.delivery_addresses,
        station: data?.station,
        station_receiving: data?.station_receiving,
        freight_estimated: data?.freight_estimated,
        ship_classification: data?.ship_classification,
        terminal: data?.terminal,
        shipment_terminal: data?.shipment_terminal,
        estimated_date_execution: data?.estimated_date_execution,
        date_of_execution: data?.date_of_execution,
        end_of_execution: data?.end_of_execution,
        arrival_period_start: data?.arrival_period_start,
        arrival_period_end: data?.arrival_period_end,
        loadport: data?.loadport,
        disport: data?.disport,
        load_rate: data?.load_rate,
        discharge_rate: data?.discharge_rate,
        demmurage_rate: data?.demmurage_rate,
        dispatch_rate: data?.dispatch_rate,
        implied_freight_level: data?.implied_freight_level,
        from_request: data?.stage === 'ticket' ? data?.id : null,
        buyer: data?.contract_type === 'purchase' ? data?.buyer : data?.supplier,
        supplier: null,
        consignee: data?.buyer,
      };
      return contractModal(serviceContract);
    });
  }

  function getConclusionDateRange(contract: any) {
    if (!$rootScope.user.settings.VALIDATE_CONTRACT_CONCLUSION_DATE) {
      return $q.when().then(() => ({}));
    }
    const min = (a: any, b: any) => (a > b ? b : a);
    switch (contract.stage) {
      case 'ticket':
        if (!contract.from_request) {
          return $q.when().then(() => ({ minDate: new Date() }));
        }
        return getRequest({ id: contract.from_request }).then((indicator: any) => ({
          minDate: min(indicator.expiration_start || new Date(), new Date()),
          maxDate: indicator.expiration_end,
        }));

      case 'indicator':
        return $q.when().then(() => ({
          minDate: new Date(),
          maxDate: new Date(),
        }));
      case 'contract':
        return $q.when().then(() => ({ minDate: new Date() }));
      default:
        return $q.when().then(() => ({}));
    }
  }

  function getCustomFieldTableColumns() {
    return CustomValuesService.getCustomFieldTableColumns({ purpose_model: 'contractbase' });
  }
  function getCustomFieldFilters() {
    return CustomValuesService.getCustomFieldFilters({ purpose_model: 'contractbase' });
  }

  function isCargoFieldDisabled(request: any) {
    return RequestResource.get({ id: request.from_request }).$promise.then((data: any) => {
      return (
        $rootScope.user.settings.TICKET_CHECK_CROP_FROM_INDICATOR && data.stage === 'indicator'
      );
    });
  }

  function checkExecuteExpiration(contract: any) {
    if (contract.expiration_end || contract.expiration_start) {
      const today = new Date();

      today.setHours(0, 0, 0, 0);

      let expirationCondition = false;
      if (contract.expiration_end) {
        let expirationDate: Date | null = null;

        if (typeof contract.expiration_end === 'string') {
          expirationDate = new Date(contract.expiration_end);
          if (isNaN(expirationDate.getTime())) {
            expirationDate = null;
          }
        } else if (contract.expiration_end instanceof Date) {
          expirationDate = contract.expiration_end;
        }

        if (expirationDate) {
          expirationDate.setHours(0, 0, 0, 0);
          expirationCondition = today > expirationDate;
        }
      }

      if (expirationCondition) {
        if ($rootScope.user.settings.TICKET_CREATION_RESTRICTION) {
          if (['approved', 'processed'].includes(contract.request_status)) {
            contract.request_status = 'expired';
            return false;
          }
        } else {
          contract.request_status = 'expired';
          return false;
        }
      }
    }
    return true;
  }

  function checkTicketCreatePossibility(contract: any) {
    let checkResult = true;

    if ($rootScope.user.settings.TICKET_CREATION_RESTRICTION) {
      checkResult = ['approved', 'processed'].includes(contract.request_status);
    }

    if (AccountsService.hasPerm('expired_indicator_restriction')) {
      checkResult =
        checkResult && contract.request_status !== 'expired' && checkExecuteExpiration(contract);
    }

    if ($rootScope.user.settings.TICKET_VOLUME_VALIDATION && contract.tickets_volume) {
      checkResult = checkResult && contract.volume > contract.contracts_final_volume;
    }

    return checkResult;
  }

  function checkEditPossibility(contract: any, editRestrictedStatuses: any) {
    if (AccountsService.hasPerm('expired_indicator_restriction')) {
      return (
        !editRestrictedStatuses.includes(contract.request_status) &&
        checkExecuteExpiration(contract)
      );
    }
    return !editRestrictedStatuses.includes(contract.request_status);
  }

  function getDefaultsParams() {
    const startDate = $rootScope.user.settings.DEFAULT_CONTRACT_START_DATE
      ? formatDate($rootScope.user.settings.DEFAULT_CONTRACT_START_DATE, 'dd.MM.yyyy')
      : `01.01.${new Date().getFullYear()}`;
    const endDate = $rootScope.user.settings.DEFAULT_CONTRACT_START_DATE
      ? formatDate($rootScope.user.settings.DEFAULT_CONTRACT_END_DATE, 'dd.MM.yyyy')
      : `01.01.${new Date().getFullYear() + 1}`;
    return {
      start_date: startDate,
      end_date: endDate,
      date_predicate: 'conclusion_date',
    };
  }

  function isTicketDisabledForPassportCreate(ticket: any) {
    return (
      (ticket.request_status == 'executed' &&
        !$rootScope.user.settings.ALLOW_CREATE_PASSPORT_FROM_EXECUTED_TICKETS) ||
      ticket.request_status == 'rejected' ||
      ticket.request_status == 'cancelled'
    );
  }
}

(function () {
  'use strict';

  ng.module('deals.contracts.legacy').factory('ContractsService', Service);

  Service.$inject = [
    '$resource',
    '$rootScope',
    '$q',
    '$location',
    '$http',
    '$uibModal',
    '$state',
    '$filter',
    '$injector',
    'LogisticsService',
    'FinancesService',
    'gettext',
    'CoreUtils',
    'CropsService',
    'AccountsService',
    'FormFieldParamsService',
    'CustomValuesService',
    'LoyaltyProgramsService',
    'PotentialService',
    'ClientsService',
  ];
})();

export type ContractsService = ReturnType<typeof Service>;
