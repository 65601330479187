import { clientsBanksList, clientsBanksPredictionsRetrieve } from '~/shared/api';
import { EntityRepository, type Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Bank, BankQueryParams } from '../types';

export class BankRepository extends EntityRepository<Bank, BankQueryParams> {
  readonly entityName = 'clients.Bank';

  getFn = (_id: number): Promise<Bank> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await clientsBanksPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  createFn = (_entity: Bank): Promise<Bank> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Bank>): Promise<Bank> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: BankQueryParams): Promise<Paginated<Bank>> => {
    const { data } = await clientsBanksList({ query });
    return { records: data.results, count: data.count };
  };
}
