import React from 'react';

import { toggleHandler } from '~/core/theme/components/menu/helpers';
import { useMenu } from '~/core/theme/hooks/use-menu';
import { Link } from '~/shared/lib/router';
import { useObservableEagerState } from '~/shared/lib/state';
import { cn } from '~/shared/lib/utils';
import type { IconComponent } from '~/shared/ui/icons';

import type { SidebarMenuItem } from '../types';

export const NavigationButton: React.FC<{
  item: SidebarMenuItem;
  Icon: IconComponent;
}> = ({ item, Icon }) => {
  const { menuStore } = useMenu();

  const openMenuIds = useObservableEagerState(menuStore.openMenuIds$);

  const toggleMenuHandler = React.useCallback(
    () =>
      toggleHandler({
        id: item.id,
        currentIds: openMenuIds,
        updateMethod: (ids: string[]) => menuStore.openMenuIdsChanged(ids),
      }),
    [openMenuIds, item.id, menuStore],
  );

  return (
    <button
      type="button"
      className={cn(
        'text-text-main-secondary hover:bg-transparent-light hover:text-text-main-secondary flex h-[24px] w-full cursor-pointer items-center gap-2 rounded-md px-2 transition duration-300 ease-in-out',
        openMenuIds.includes(item.id) && 'bg-transparent-light',
        item.isActive && 'bg-background-section-primary text-stroke-main-on-primary-section',
      )}
      onClick={toggleMenuHandler}
    >
      {item.menus.length ? (
        <>
          <Icon size={16} />
          <span className="text-text-muted-foreground text-xs font-medium">{item.groupLabel}</span>
        </>
      ) : (
        <Link to={item.href} className="flex w-full items-center gap-2">
          <Icon size={16} />
          <span className="text-text-muted-foreground text-xs font-medium">{item.groupLabel}</span>
        </Link>
      )}
    </button>
  );
};
