import React from 'react';

import { LoginForm } from '~/core/auth/components';
import type { AuthCredentials } from '~/core/auth/lib';
import { Link } from '~/shared/lib/router';
import { FadedLogo } from '~/shared/ui/components/faded-logo';
import { Button } from '~/shared/ui/kit/button';
import { Card } from '~/shared/ui/kit/card';

export const LoginCard: React.FC<{ onLogin: (credentials: AuthCredentials) => void }> = ({
  onLogin,
}) => {
  return (
    <Card className="flex w-[400px] flex-col gap-8" data-testid="login-card">
      <FadedLogo />
      <h2 className="mx-auto">Sign in to GrainTrack</h2>
      <div className="border-t-transparent-light mx-auto flex w-[208px] flex-col gap-2 border-t pt-3">
        <LoginForm submit={onLogin} />
        <Link to="/reset-password">
          <Button variant="fadedTertiary" fullWidth>
            Forgot Password?
          </Button>
        </Link>
      </div>
      <p className="text-text-main-tertiary text-center">
        By using Graintrack, you agree to the <span className="font-medium">Terms of Service</span>{' '}
        and <span className="font-medium">Data Processing Agreement</span>.
      </p>
    </Card>
  );
};
