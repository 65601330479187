import React from 'react';

import type { ListOption } from '~/shared/lib/types';
import { cn } from '~/shared/lib/utils';
import { Checkbox } from '~/shared/ui/kit/checkbox';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '~/shared/ui/kit/dropdown-menu';
import { Input } from '~/shared/ui/kit/input';
import { Tag } from '~/shared/ui/kit/tag';

import type { DataTypeProps } from '../../lib';

type ExtendedListOption = ListOption & { tagProps?: Record<string, unknown> };

export const MultiSelectWriteContent: React.FC<
  DataTypeProps<'multiselect'> & {
    isOpen?: boolean;
    options: ExtendedListOption[];
    contentClassName?: string;
  }
> = ({ value, options, onChange: valueChanged, contentClassName }) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredOptions = React.useMemo(() => {
    return options.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [options, searchTerm]);

  return (
    <DropdownMenuContent className={cn('w-[160px] p-0', contentClassName)}>
      <Input
        type="text"
        intent="transparent"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onBlur={(e) => e.target.focus()}
        placeholder="Search"
        fullWidth
      />
      <DropdownMenuSeparator className="bg-transparent-lighter my-0 w-full" />

      <div className="scrollbar flex max-h-[200px] flex-col gap-2 overflow-y-auto p-2">
        {filteredOptions.length ? (
          filteredOptions.map((item) => (
            <DropdownMenuItem
              key={item.value}
              className="flex items-center gap-2"
              onSelect={(event) => {
                event.preventDefault();
                const currentValues = Array.isArray(value) ? value : [];
                valueChanged?.(
                  currentValues.some((v) => v.value === item.value)
                    ? currentValues.filter((v) => v.value !== item.value)
                    : [...currentValues, item],
                );
              }}
            >
              <Checkbox
                size="small"
                checked={Array.isArray(value) && value.some((v) => v.value === item.value)}
              />
              <Tag {...item.tagProps}>{item.label}</Tag>
            </DropdownMenuItem>
          ))
        ) : (
          <span className="text-xs">No Data</span>
        )}
      </div>
    </DropdownMenuContent>
  );
};
