import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import { html } from '^/shared/utils';

export const StockMarketPricesPage = {
  bindings: {},
  template: html`
    <div class="col-sm-12 col-md-12">
      <consolidated-stock-market-prices-table
        filter-level="'consolidated-stock-market-prices'"
      ></consolidated-stock-market-prices-table>
    </div>
    <div class="clearfix"></div>
    <div class="horizontal-divider"></div>
    <div class="clearfix"></div>
    <div class="col-sm-12 col-md-12">
      <stock-market-prices-table filter-level="'stock-market-prices'"></stock-market-prices-table>
    </div>
    <div class="clearfix"></div>
    <div class="horizontal-divider"></div>
    <div class="clearfix"></div>
    <div class="col-sm-12 col-md-12">
      <consolidated-market-prices-table
        ng-if="$ctrl.renderTable"
        filter-level="'consolidated-market-prices'"
      ></consolidated-market-prices-table>
    </div>
    <div class="clearfix"></div>
    <div class="horizontal-divider"></div>
    <div class="clearfix"></div>
    <div class="col-sm-12 col-md-12">
      <market-prices-table filter-level="'market-prices'"></market-prices-table>
    </div>
  `,
  controller: [
    '$timeout',
    'gtFilterService',
    'PageService',
    'MarketService',
    class {
      MarketService: any;
      _$timeout: ng.ITimeoutService;
      _PageService: PageService;
      _gtFilterService: GtFilterService;
      renderTable: any;
      constructor(
        $timeout: ng.ITimeoutService,
        gtFilterService: GtFilterService,
        PageService: PageService,
        MarketService: any,
      ) {
        this._$timeout = $timeout;
        this._gtFilterService = gtFilterService;
        this._PageService = PageService;
        this.MarketService = MarketService;
        this.renderTable = true;
      }

      $onInit() {
        this._PageService.setConfig(this.getPageConfig());
        this._gtFilterService.subscribe(
          'stock-market-prices',
          () => {
            this._gtFilterService.updateQueryParams({}, 'consolidated-stock-market-prices');
          },
          {},
        );
        this._gtFilterService.subscribe(
          'market-prices',
          () => {
            this.renderTable = false;
            this._$timeout(() => (this.renderTable = true));
          },
          {},
        );
      }

      getPageConfig() {
        return {
          class: 'page-header-main-prices-tab',
          buttons: [new this._PageService.buttons.Print(), new this._PageService.buttons.Refresh()],
          pages: this.MarketService.getPageConfig(),
        };
      }
    },
  ],
};
