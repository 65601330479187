import type ng from 'angular';

import { formatDate, substractDate } from '~/shared/lib/date';

import template from './operations-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const OperationsPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'PageService',
    'gtFilterService',
    'ReportsService',
    'OperationsService',
    'gettext',
    '$location',
    'GtUtils',
    '$q',
    class {
      $location: ng.ILocationService;
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      OperationsService: any;
      PageService: PageService;
      ReportsService: ReportsService;
      closeDetails: any;
      closeMatchedDetails: any;
      filterLevel = 'operations-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      shortQueryParams: QueryParams = {};
      longOperationsTotals: any;
      longQueryParams: QueryParams = {};
      matchOperationsTotals: any;
      matchQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      shortOperationsTotals: any;
      shortqQueryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        PageService: PageService,
        gtFilterService: GtFilterService,
        ReportsService: ReportsService,
        OperationsService: any,
        gettext: ng.gettext.gettextFunction,
        $location: ng.ILocationService,
        GtUtils: GtUtilsService,
        $q: ng.IQService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.ReportsService = ReportsService;
        this.OperationsService = OperationsService;
        this.GtUtils = GtUtils;
        this.$q = $q;
        this.gettext = gettext;
        this.$location = $location;
        this.closeDetails = true;
        this.closeMatchedDetails = true;
        this.initQueryParams = {
          subtab:
            this.getSubtabFromURL() ?? this.$rootScope.user.settings.DEFAULT_TAB_DERIVATIVE_REPORT,
          page_size: 999,
          date_predicate: 'match_date',
          start_date: formatDate(substractDate(new Date(), { months: 6 }), 'dd.MM.yyyy'),
          end_date: formatDate(new Date(), 'dd.MM.yyyy'),
          derivative_account_is_active:
            this.$rootScope.user.settings.DEFAULT_TAB_DERIVATIVE_REPORT === 'accounts_position' &&
            1,
          ...this.gtFilterService.getQueryParams(this.filterLevel),
        };
      }
      getSubtabFromURL() {
        const searchParams = new URLSearchParams(this.$location.search());
        return searchParams.get('subtab');
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'operations-page-view';
        this.queryParams = { ...this.initQueryParams };
        this.shortQueryParams = { ...this.queryParams, deal_type: 'short' };
        this.longQueryParams = { ...this.queryParams, deal_type: 'long' };
        this.matchQueryParams = { ...this.queryParams };
        this.PageService.setConfig(this.getPageConfig());
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          if (this.queryParams.subtab === 'accounts_position') {
            this.GtUtils.overlay('show');
            this.queryParams = data;
            this.gtFilterService.setQueryParams(data, 'consolidated-month-accounts-container');
            this.gtFilterService.setQueryParams(data, 'consolidated-operations-long');
            this.gtFilterService.setQueryParams(data, 'consolidated-operations-short');
            this.gtFilterService.setQueryParams(data, 'matched-operations-container');
            this.gtFilterService.setQueryParams(data, 'comm-fees-grouped-container');

            this.$q
              .all([
                this.OperationsService.getOperationConsolidatedTotals(this.shortQueryParams),
                this.OperationsService.getOperationConsolidatedTotals(this.longQueryParams),
                this.OperationsService.getOperationsMatchedTotals(this.queryParams),
              ])
              .then((results: any) => {
                this.shortOperationsTotals = results[0];
                this.longOperationsTotals = results[1];
                this.matchOperationsTotals = results[2];
                this.GtUtils.overlay('hide');
              })
              .catch((error: any) => {
                this.GtUtils.errorClb(error);
                this.GtUtils.overlay('hide');
              });
          } else {
            this.gtFilterService.updateQueryParams(
              {},
              'consolidated-operations-passport-container',
            );
          }
        });
      }

      applyFiltersOperationsContainer(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, 'operations-container');
      }

      applyFilters(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      resetFilters() {
        this.gtFilterService.setQueryParams({ ...this.initQueryParams }, this.filterLevel);
      }

      dropQueryParams(params: string[]) {
        params.forEach((param) => {
          delete this.queryParams[param];
        });
        this.gtFilterService.setQueryParams(
          this.queryParams,
          'consolidated-month-accounts-container',
        );
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          pages: this.ReportsService.getPagesConfig(),
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
        };
      }
    },
  ],
};
