import type ng from 'angular';

import template from './warehouse-commodity-batch-movements-view.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export const WarehouseCommodityBatchMovementsView = {
  bindings: {
    movements: '<',
    count: '<',
    tableName: '<?',
    filterLevel: '<?',
    applyFilters: '&',
  },
  template,
  controller: [
    '$rootScope',
    'GtUtils',
    'gettext',
    'CoreService',
    class {
      $rootScope: GtRootScopeService;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      applyFilters: any;
      count: number;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      movements: any;
      tableData: any;
      tableName: string;
      tableOptions: any;
      constructor(
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        CoreService: CoreService,
      ) {
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.CoreService = CoreService;

        this.movements = [];
        this.count = 0;
        this.tableName = '';
        this.filterLevel = '';
        this.tableOptions = {};
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'warehouse-commodity-batch-movements-view';
        this.tableName = this.tableName || this.filterLevel;
        this.tableOptions = this.getTableOptions();
      }
      $onChanges(changes: any) {
        if (changes.movements || changes.count) {
          this.prepareTableData();
        }
      }

      prepareTableData() {
        this.tableData = {
          rows: this.movements.map((movement: any) => ({
            ...movement,
            amountT: movement.volume ? movement.amount / movement.volume : 0,
            totalAmountT: movement.volume ? movement.total_amount / movement.volume : 0,

            costs_data: {
              count: movement.costs?.length || 0,
              rows: movement.costs || [],
            },
          })),
          count: this.count,
        };
      }

      goToObject(item: any) {
        return this.GtUtils.goDetails(item.content_type, item.object_id);
      }

      getIcon(contentType: any) {
        return this.CoreService.getContentType(contentType).then((data: any) => {
          return this.GtUtils.getIcon(data.label);
        });
      }

      getTableOptions() {
        const columnDefs = [
          {
            columnName: 'period',
            class: 'td-left-align',
            title: this.gettext('Period'),
            cellTemplate: html`<i class="fa fa-calendar"></i> {[{ item.period | date:'dd.MM.yy'}]}`,
          },
          {
            columnName: 'business_unit',
            class: 'td-left-align',
            title: this.gettext('Business unit'),
            cellTemplate: html`{[{ item.business_unit.title }]}`,
          },
          {
            columnName: 'recorder',
            class: 'td-left-align',
            title: this.gettext('Movement recorder'),
            cellTemplate: html`<span ng-if="item.recorder">
              <a ui-sref="{[{item.recorder.sref}]}" ng-if="item.recorder.sref" class="btn-link">
                {[{ item.recorder.title }]}
              </a>
              <a
                href="{[{item.recorder.href}]}"
                ng-if="!item.recorder.sref && item.recorder.href"
                class="btn-link"
              >
                {[{ item.recorder.title }]}
              </a>
              <span ng-if="!item.recorder.sref && !item.recorder.href"
                >{[{ item.recorder.title }]}</span
              >
            </span>`,
          },
          {
            columnName: 'recordType',
            class: 'td-left-align',
            title: this.gettext('Record type'),
            cellTemplate: html`
              <span ng-if="item.record_type === 'receipt'" class="label label-success">
                <translate>Receipt</translate>
              </span>
              <span ng-if="item.record_type === 'dispatch'" class="label label-warning">
                <translate>Dispatch</translate>
              </span>
            `,
          },
          {
            columnName: 'warehouse',
            class: 'td-left-align',
            title: this.gettext('Warehouse'),
            cellTemplate: html`
              <span>
                <i class="fa fa-warehouse"></i>
                <a class="btn-link" ui-sref="logistics.warehouseDetails({id: item.warehouse.pk })">
                  {[{ item.warehouse.title }]}
                </a>
              </span>
            `,
          },
          {
            columnName: 'commodity',
            class: 'td-left-align',
            title: this.gettext('Commodity'),
            cellTemplate: html`
              <span ng-if="item.commodity.pk">
                <i class="fa fa-wheat-awn"></i>
                <a class="btn-link" href="/admin/crops/crop/{[{item.commodity.pk}]}">
                  {[{ item.commodity.title }]}
                </a>
              </span>
            `,
          },
          {
            columnName: 'stockReserveUnit',
            class: 'td-left-align',
            title: this.gettext('Stock reserve unit'),
            cellTemplate: html`
              <span ng-if="item.stock_reserve_unit.pk">
                <i class="fa fa-lock"></i>
                <a
                  class="btn-link"
                  ui-sref="logistics.stockReserveUnit({ id: item.stock_reserve_unit.pk})"
                >
                  {[{ item.stock_reserve_unit.title }]}
                </a>
              </span>
            `,
          },
          {
            columnName: 'contractPosition',
            class: 'td-left-align',
            title: this.gettext('Contract'),
            cellTemplate: html`<span ng-if="item.contract_position.pk">
              <i class="fa fa-file-o"></i>
              <a ui-sref="gt.page.contract({id: item.contract_position.pk })" class="btn-link">
                {[{ item.contract_position.title }]}
              </a>
              <span ng-if="!!item.contract_position.contract">
                (<i class="fa fa-file-archive"></i>
                <a
                  ui-sref="gt.multicontract({id: item.contract_position.contract })"
                  class="btn-link"
                >
                  {[{ item.contract_position.contract_number }]}
                </a>
                )
              </span>
            </span>`,
          },
          {
            columnName: 'receiver',
            class: 'td-left-align',
            title: this.gettext('Receiver'),
            cellTemplate: html`<span ng-if="item.receiver.pk">
              <i class="fa fa-file-o"></i>
              <a ui-sref="gt.page.client({id: item.receiver.pk })" class="btn-link">
                {[{ item.receiver.title }]}
              </a>
            </span>`,
          },
          {
            columnName: 'producer',
            class: 'td-left-align',
            title: this.gettext('Producer'),
            cellTemplate: html`<span ng-if="item.producer.pk">
              <i class="fa fa-file-o"></i>
              <a ui-sref="gt.page.client({id: item.producer.pk })" class="btn-link">
                {[{ item.producer.title }]}
              </a>
            </span>`,
          },
          {
            columnName: 'saleContractPosition',
            class: 'td-left-align',
            title: this.gettext('Sale Contract'),
            cellTemplate: html`<span ng-if="item.sale_contract_position.pk">
              <i class="fa fa-file-o"></i>
              <a ui-sref="gt.page.contract({id: item.sale_contract_position.pk })" class="btn-link">
                {[{ item.sale_contract_position.title }]}
              </a>
              <span ng-if="!!item.sale_contract_position.contract">
                (<i class="fa fa-file-archive"></i>
                <a
                  ui-sref="gt.multicontract({id: item.sale_contract_position.contract })"
                  class="btn-link"
                >
                  {[{ item.sale_contract_position.contract_number }]}
                </a>
                )
              </span>
            </span>`,
          },
          {
            columnName: 'costDetailed',
            class: 'td-left-align',
            title: this.gettext('Costs'),
            cellTemplate: html`
              <span ng-if="item.costs.length">
                <table>
                  <thead>
                    <tr>
                      <th><translate>Charge</translate></th>
                      <th><translate>Transport charge</translate></th>
                      <th><translate>Amount</translate></th>
                      <th><translate>Volume</translate></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="cost in item.costs">
                      <td>
                        <span ng-if="cost.charge.pk">
                          <i class="fa fa-dollar-sign"></i>
                          <a class="btn-link" href="/admin/finances/charge/{[{cost.charge.pk}]}">
                            {[{ cost.charge.title }]}
                          </a>
                        </span>
                      </td>
                      <td>
                        <span ng-if="cost.transport_charge.pk">
                          <i class="fa fa-money"></i>
                          <a
                            class="btn-link"
                            ui-sref="finances.contractCharges({ id: cost.transport_charge.pk })"
                          >
                            {[{ cost.transport_charge.title }]}
                          </a>
                        </span>
                      </td>
                      <td><span ng-if="cost.amount">{[{ cost.amount }]}</span></td>
                      <td><span ng-if="cost.volume">{[{ cost.volume }]}</span></td>
                    </tr>
                  </tbody>
                </table>
              </span>
            `,
          },
          {
            columnName: 'batch',
            class: 'td-left-align',
            title: this.gettext('Batch'),
            cellTemplate: html`<span ng-if="item.batch">
              <a ui-sref="{[{item.batch.sref}]}" ng-if="item.batch.sref" class="btn-link">
                {[{ item.batch.title }]}
              </a>
              <a
                href="{[{item.batch.href}]}"
                ng-if="!item.batch.sref && item.batch.href"
                class="btn-link"
              >
                {[{ item.batch.title }]}
              </a>
              <span ng-if="!item.batch.sref && !item.batch.href">{[{ item.batch.title }]}</span>
            </span>`,
          },
          {
            columnName: 'volume',
            class: 'td-right-align',
            title: this.gettext('Volume'),
            cellTemplate: html`
              <span
                ng-if="item.volume"
                ng-class="{'negative-number': item.volume < 0, 'positive-number': item.volume > 0}"
              >
                {[{ item.volume | number:6 }]}
                <span class="smaller-label label-smoke">{[{ args.unit }]}</span>
              </span>
            `,
          },
          {
            columnName: 'reservedVolume',
            class: 'td-right-align',
            title: this.gettext('Reserved Volume'),
            cellTemplate: html`
              <span
                ng-if="item.reserved_volume"
                ng-class="{'negative-number': item.reserved_volume < 0, 'positive-number': item.reserved_volume > 0}"
              >
                {[{ item.reserved_volume | number:6 }]}
                <span class="smaller-label label-smoke">{[{ args.unit }]}</span>
              </span>
            `,
          },
          {
            columnName: 'freeVolume',
            class: 'td-right-align',
            title: this.gettext('Free Volume'),
            cellTemplate: html`
              <span
                ng-if="item.free_volume"
                ng-class="{'negative-number': item.free_volume < 0, 'positive-number': item.free_volume > 0}"
              >
                {[{ item.free_volume | number:6 }]}
                <span class="smaller-label label-smoke">{[{ args.unit }]}</span>
              </span>
            `,
          },
          {
            columnName: 'amount',
            class: 'td-right-align',
            title: this.gettext('Amount'),
            cellTemplate: html`
              <span
                ng-if="item.amount"
                ng-class="{'negative-number': item.amount < 0, 'positive-number': item.amount > 0}"
              >
                {[{ item.amount | number:2 }]}
                <span class="smaller-label label-smoke">{[{ args.currency }]}</span>
              </span>
            `,
          },
          {
            columnName: 'AmountT',
            class: 'td-right-align',
            title: this.gettext('Self-cost'),
            cellTemplate: html`
              <div ng-if="item.amountT">
                <span
                  ng-class="{
                    'negative-number': item.amountT < 0,
                    'positive-number': item.amountT > 0
                  }"
                >
                  {[{ item.amountT | number:2 }]}
                  <span class="smaller-label label-smoke">
                    {[{ args.currency }]}/{[{ args.unit }]}
                  </span>
                </span>
              </div>
            `,
          },
          {
            columnName: 'costsAmount',
            class: 'td-right-align',
            title: this.gettext('Costs'),
            cellTemplate: html`
              <span
                ng-if="item.costs_amount"
                ng-class="{'negative-number': item.costs_amount < 0, 'positive-number': item.costs_amount > 0}"
              >
                {[{ item.costs_amount | number:2 }]}
                <span class="smaller-label label-smoke">{[{ args.currency }]}</span>
              </span>
            `,
          },
          {
            columnName: 'totalAmount',
            class: 'td-left-align',
            title: this.gettext('Total amount'),
            cellTemplate: html`
              <span
                ng-if="item.total_amount"
                class="pull-left smaller-label"
                ng-class="{'negative-number': item.total_amount < 0, 'positive-number': item.total_amount > 0}"
              >
                {[{ item.total_amount | number:2 }]}
                <span class="smaller-label label-smoke">{[{ args.currency }]}</span>
              </span>
            `,
          },
          {
            columnName: 'totalAmountT',
            class: 'td-right-align',
            title: this.gettext('Full self-cost'),
            cellTemplate: html`
              <div ng-if="item.totalAmountT">
                <span
                  ng-class="{
                    'negative-number': item.totalAmountT < 0,
                    'positive-number': item.totalAmountT > 0
                  }"
                >
                  {[{ item.totalAmountT | number:2 }]}
                  <span class="smaller-label label-smoke">
                    {[{ args.currency }]}/{[{ args.unit }]}
                  </span>
                </span>
              </div>
            `,
          },
          {
            columnName: 'description',
            class: 'td-left-align',
            title: this.gettext('Description'),
            cellTemplate: html`
              <span ng-if="item.description" class="pull-left smaller-label">
                {[{ item.description }]}
              </span>
            `,
          },
        ];
        return {
          tableName: this.tableName,
          tableClass:
            'contract-charges-table customs-cargo-declaration-table sample-control-table table-with-inline-add',
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          configurable: true,
          changePageSize: true,
          showMore: true,
          templateArgs: {
            goToObject: (item: any) => this.goToObject({ item: item }),
            getIcon: (item: any) => this.getIcon(item),
            unit: this.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT,
            currency: this.$rootScope.user.settings.DEFAULT_CURRENCY,
          },
          // applyFilters: this.changeTab,
          columnDefs: columnDefs,
          activeTab: 'info',
          tabs: [
            {
              serializer: 'info',
              title: this.gettext('Info'),
              icon: 'fa-dollar',
              columns: columnDefs
                .map((col) => col.columnName)
                .filter((col) => !col.startsWith('~'))
                .filter((col) => !col.startsWith('costDetailed')),
            },
            {
              serializer: 'costDetailed',
              title: this.gettext('Detailed'),
              icon: 'fa-money',
              columns: columnDefs
                .map((col) => col.columnName)
                .filter((col) => !col.startsWith('~')),
            },
          ],
        };
      }
    },
  ],
};
