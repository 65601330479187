import type ng from 'angular';

import template from './client-mailing-modal.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { MailingService } from '^/app/crm/mailing/mailing.service';
import { html } from '^/shared/utils';

export const ClientMailingModal = {
  bindings: {
    modalInstance: '<',
    clientMailing: '<',
  },
  template,
  controller: [
    '$q',
    'GtUtils',
    'gettext',
    'MailingService',
    class {
      $q: ng.IQService;
      GtUtils: GtUtilsService;
      MailingService: MailingService;
      clientMailing: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      processing: any;
      constructor(
        $q: ng.IQService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        MailingService: MailingService,
      ) {
        this.$q = $q;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.MailingService = MailingService;

        this.modalInstance = undefined;
        this.clientMailing = undefined;

        this.form = undefined;
        this.fields = [];
        this.processing = false;
      }

      $onInit() {
        this.fields = this.getFormConfig().fieldsDef;
      }

      close(data?: string) {
        this.modalInstance.close(data ?? 'close');
      }

      delete() {
        if (!confirm(this.GtUtils.translate(this.gettext('Are you sure?')))) {
          return;
        }

        this.MailingService.deleteClientMailing(this.clientMailing).then(() => this.close());
      }

      send() {
        if (!confirm(this.GtUtils.translate(this.gettext('Are you sure?')))) {
          return;
        }
        this.save(true).then(() => {
          this.processing = true;
          this.MailingService.sendClientMailing(this.clientMailing).then((res: any) => {
            this.clientMailing = res.data;
            this.processing = false;
            this.close();
          });
        });
      }

      save(throwError: any) {
        this.processing = true;
        return this.MailingService.saveClientMailing(this.clientMailing).then(
          (res: any) => {
            this.clientMailing = res.data;
            this.processing = false;
            return res;
          },
          (e: any) => {
            this.GtUtils.errorClb(e);
            if (throwError) {
              return this.$q.reject(e);
            }
          },
        );
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container documents-form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        const col2: any = {
          className: 'form-group-container documents-form-group-container col-sm-5 col-xs-12',
          fieldGroup: [],
        };
        const col3: any = {
          className: 'form-group-container documents-form-group-container col-sm-4 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('From'),
          },
          fieldGroup: [
            {
              template: html`
                <emails-edit-view
                  not-editable="true"
                  emails="[{email:model.email_from, id:1}]"
                ></emails-edit-view>
              `,
            },
          ],
        });

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('To'),
          },
          fieldGroup: [
            {
              template: html`
                <emails-edit-view
                  emails="model.emails_to"
                  save-email="saveEmail(email)"
                  delete-email="deleteEmail(email)"
                ></emails-edit-view>
              `,
              controller: [
                '$scope',
                'MailingService',
                function ($scope: ng.IScope, MailingService: MailingService) {
                  ($scope as any).deleteEmail = (email: any) =>
                    MailingService.deleteMailingTo(email);
                  ($scope as any).saveEmail = (email: any) =>
                    MailingService.saveMailingTo({
                      email: email.email,
                      mailing: ($scope as any).model.id,
                    });
                },
              ],
            },
          ],
        });

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('Cc'),
          },
          fieldGroup: [
            {
              template: html`
                <emails-edit-view
                  emails="model.emails_cc"
                  save-email="saveEmail(email)"
                  delete-email="deleteEmail(email)"
                ></emails-edit-view>
              `,
              controller: [
                '$scope',
                'MailingService',
                function ($scope: ng.IScope, MailingService: MailingService) {
                  ($scope as any).deleteEmail = (email: any) =>
                    MailingService.deleteMailingCc(email);
                  ($scope as any).saveEmail = (email: any) =>
                    MailingService.saveMailingCc({
                      email: email.email,
                      mailing: ($scope as any).model.id,
                    });
                },
              ],
            },
          ],
        });

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('Bcc'),
          },
          fieldGroup: [
            {
              template: html`
                <emails-edit-view
                  emails="model.emails_bcc"
                  save-email="saveEmail(email)"
                  delete-email="deleteEmail(email)"
                ></emails-edit-view>
              `,
              controller: [
                '$scope',
                'MailingService',
                function ($scope: ng.IScope, MailingService: MailingService) {
                  ($scope as any).deleteEmail = (email: any) =>
                    MailingService.deleteMailingBcc(email);
                  ($scope as any).saveEmail = (email: any) =>
                    MailingService.saveMailingBcc({
                      email: email.email,
                      mailing: ($scope as any).model.id,
                    });
                },
              ],
            },
          ],
        });

        col2.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: '',
          },
          fieldGroup: [
            {
              key: 'subject',
              type: 'gt-textarea',
              templateOptions: {
                label: this.gettext('Subject'),
                placeholder: this.gettext('Subject'),
                required: true,
              },
            },
            {
              key: 'body',
              type: 'gt-textarea',
              templateOptions: {
                label: this.gettext('Body'),
                required: true,
              },
            },
          ],
        });

        col3.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('Attachments'),
          },
          fieldGroup: [
            {
              template: html`
                <attachments-edit-view
                  attachments="model.attachments"
                  delete-attachment="deleteAttachment(attachment)"
                  save-file="saveAttachment(file)"
                ></attachments-edit-view>
              `,
              controller: [
                '$scope',
                'MailingService',
                function ($scope: ng.IScope, MailingService: MailingService) {
                  ($scope as any).deleteAttachment = (item: any) =>
                    MailingService.deleteMailingAttachment(item);
                  ($scope as any).saveAttachment = (file: any) =>
                    MailingService.saveAttachment(
                      {
                        mailing: ($scope as any).model.id,
                      },
                      file,
                    );
                },
              ],
            },
          ],
        });

        return {
          formName: 'client-mailing-modal',
          fieldsDef: [col1, col2, col3],
        };
      }
    },
  ],
};
