import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import { useComponent } from '~/shared/lib/components';

import type { PurchaseLogisticListContainerProps } from './purchase-logistic-list.model';
import { PurchaseLogisticListModel } from './purchase-logistic-list.model';

export const PurchaseLogisticContainer = (props: PurchaseLogisticListContainerProps) => {
  const { ComponentProvider } = useComponent(props, PurchaseLogisticListModel);

  return (
    <ComponentProvider>
      <EntityListContainer />
    </ComponentProvider>
  );
};
