import ng from 'angular';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').controller('VesselModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    'LogisticsService',
    'vessel',
    'AccountsService',
    'FormFieldParamsService',
    'CoreService',
    'GtUtils',
    'gettext',
    '$window',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    LogisticsService: LogisticsService,
    vessel: any,
    AccountsService: AccountsService,
    FormFieldParamsService: FormFieldParamsService,
    CoreService: CoreService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    $window: ng.IWindowService,
  ) {
    const vm = this;
    vm.vessel = vessel || {};
    vm.close = close;
    vm.save = save;
    vm.saveAndContinue = saveAndContinue;
    vm.saveAfterCheck = saveAfterCheck;
    vm.destroy = destroy;
    vm.tab = 'edit';
    vm.fields = undefined;
    vm.form = undefined;
    vm.updateLogEntries = updateLogEntries;
    vm.openFieldsConfigModal = openFieldsConfigModal;
    vm.logEntries = [];
    vm.logEntriesCount = 0;

    activate();

    ////////////////

    function activate() {
      if (vm.vessel.id) {
        updateData().then(updateFields);
      } else {
        updateFields().catch(GtUtils.errorClb);
      }
    }

    function updateData() {
      return LogisticsService.Vessel.get({ id: vm.vessel.id }, function (data: any) {
        vm.vessel = data;
        updateLogEntries();
      }).$promise;
    }

    function updateFields() {
      return FormFieldParamsService.getFields(getFields()).then(
        (fields: any) => (vm.fields = fields),
      );
    }

    function openFieldsConfigModal() {
      FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(() => updateFields());
    }

    function getFormConfig() {
      return getFields();
    }

    function updateLogEntries() {
      return AccountsService.LogEntry.query(
        {
          object_id: vm.vessel.id,
          content_type: vm.vessel.content_type,
        },
        function (data: any) {
          vm.logEntries = data.results;
          vm.logEntriesCount = data.count;
        },
      );
    }

    function destroy() {
      if (!confirm(gettext('Are you sure?'))) {
        return;
      }
      return LogisticsService.Vessel.delete(
        {
          id: vm.vessel.id,
        },
        function (data: any) {
          return close(data, true);
        },
      );
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function save(closeAfterSave: any) {
      vm.form.$invalid = true;
      const callback = closeAfterSave ? (v: any) => close(v, true) : (v: any) => (vm.vessel = v);
      const saveFunc = vm.vessel.id ? LogisticsService.Vessel.update : LogisticsService.Vessel.save;
      return saveFunc(vm.vessel, callback);
    }

    function saveAndContinue() {
      vm.form.$invalid = true;
      if (vm.vessel.id) {
        return LogisticsService.Vessel.update(vm.vessel, function (data: any) {
          vm.vessel = data;
        });
      }
      return LogisticsService.Vessel.save(vm.vessel, function (data: any) {
        vm.vessel = data;
      });
    }

    function saveAfterCheck(closeAfterSave = true) {
      return checkDuplicates().then((result: any) => (result && save(closeAfterSave)) || false);
    }

    function checkDuplicates() {
      return LogisticsService.Vessel.query({
        name: vm.vessel.name,
        imo: vm.vessel.imo,
      }).$promise.then(
        (data: any) =>
          vm.vessel.id ||
          !data.count ||
          confirm(
            gettext(
              'Vessel with such name and IMO already exists. Are you sure you want to save it?',
            ),
          ),
      );
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-xs-12',
        fieldGroup: [],
      };

      col1.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('INFO'),
          },
          fieldGroup: [
            {
              key: 'name',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Name'),
                required: true,
              },
            },
            {
              key: 'imo',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Imo'),
                addon: gettext('#'),
              },
            },
            {
              key: 'age',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Year'),
                type: 'number',
                addon: gettext('year'),
              },
            },
            {
              key: 'owner_info',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Owner info'),
              },
            },
            {
              key: 'owner',
              type: 'gt-ui-select',
              templateOptions: {
                resource: 'clients.Other',
                label: gettext('Owner'),
                addon: gettext('Owner'),
              },
            },
            {
              key: 'managing_company',
              type: 'gt-ui-select',
              templateOptions: {
                resource: 'clients.Other',
                label: gettext('Managing company'),
                addon: gettext('Managing company'),
              },
            },
            {
              key: 'flag',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Flag'),
                addon: gettext('country'),
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              defaultValueResolve: () => CoreService.getDefaultBuId(vm.vessel),
              templateOptions: {
                label: gettext('Business unit'),
                placeholder: gettext('Business Unit'),
                queryParams: GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
              },
            },
          ],
        },
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('LOADING'),
          },
          fieldGroup: [
            {
              key: 'size',
              type: 'gt-ui-select',
              templateOptions: {
                resource: 'logistics.vesselsize',
                label: gettext('vessel size'),
                addon: gettext('vessel size'),
                addFunc: () => $window.open('/admin/logistics/vesselsize/add/'),
                addPerms: ['add_vesselsize'],
                addIcon: 'fa fa-ruler',
                hint: gettext('Vessel'),
              },
            },
            {
              key: 'capacity',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Capacity avg'),
                type: 'number',
              },
            },
            {
              key: 'capacity_min',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Capacity min'),
                type: 'number',
              },
            },
            {
              key: 'capacity_max',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Capacity max'),
                type: 'number',
              },
            },
            {
              key: 'marinetraffic',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Marinetraffic'),
                addon: gettext('website'),
              },
            },
            {
              key: 'additional_info',
              type: 'gt-textarea',
              templateOptions: {
                label: gettext('Additional Info'),
                placeholder: gettext('Specific information about this object'),
                className: 'additional-info',
              },
            },
            {
              key: 'blacklisted',
              type: 'gt-checkbox',
              templateOptions: {
                label: gettext('Blacklisted'),
              },
            },
          ],
        },
      ];

      return {
        formName: 'vessel-edit-modal',
        fieldsDef: [col1],
      };
    }
  }
})();
