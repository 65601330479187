import ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('contractChargesPageView', {
    bindings: {
      queryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./contract-charges-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'FinancesService',
    'PageService',
    'gettext',
    'gtFilterService',
    'CustomValuesService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    FinancesService: FinancesService,
    PageService: PageService,
    gettext: ng.gettext.gettextFunction,
    gtFilterService: GtFilterService,
    CustomValuesService: CustomValuesService,
  ) {
    const vm = this;
    vm.tableName = 'contract-charges-list';

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'contract-charges-page-view';
      vm.tableName = 'contract-charges-list';
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-payments-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.ScreenCaptureTask(),
        ],
        pages: FinancesService.getPagesConfig(),
      };
      config.filters = {
        filterLevel: vm.filterLevel,
        dates: true,
        useFlexDateRange: true,
        selects: [
          {
            argument: 'clientrole',
            placeholder: gettext('counterparty'),
            resource: 'clients.clientrole',
          },
        ],
        dateSelects: [
          {
            argument: 'business_date',
            placeholder: gettext('Business date'),
          },
          {
            argument: 'create_time',
            placeholder: gettext('Created date'),
          },
          {
            argument: 'update_time',
            placeholder: gettext('Updated date'),
          },
          {
            argument: 'date',
            placeholder: gettext('Charge date'),
          },
          {
            argument: 'expected_date_of_charge',
            placeholder: gettext('Expected date of charge'),
          },
        ],
        multiSelects: [
          {
            argument: 'id_list',
            placeholder: gettext('costs number'),
            resource: 'contracts.contractcharge',
          },
          {
            argument: 'client_list',
            placeholder: gettext('client'),
            resource: 'clients.client',
          },
          {
            argument: 'contract_list',
            placeholder: gettext('Contract'),
            resource: 'contracts.ContractBase',
            queryParams: {
              stage: 'contract',
            },
          },
          {
            argument: 'indicator_list',
            placeholder: gettext('Indicator'),
            resource: 'contracts.ContractBase',
            queryParams: {
              stage: 'indicator',
            },
          },
          {
            argument: 'ticket_list',
            placeholder: gettext('Ticket'),
            resource: 'contracts.ContractBase',
            queryParams: {
              stage: 'ticket',
            },
          },
          {
            argument: 'contract_bu_list',
            placeholder: gettext('contract business unit'),
            resource: 'core.BusinessUnit',
          },
          {
            argument: 'charges_list',
            placeholder: gettext('charge'),
            resource: 'finances.Charge',
          },
          {
            argument: 'parent_charges_list',
            placeholder: gettext('parent charge'),
            resource: 'finances.Charge',
          },
          {
            argument: 'uninvoiced_charge_list',
            placeholder: gettext('uninvoiced charge'),
            resource: 'finances.Charge',
          },
          {
            argument: 'passport_list',
            placeholder: gettext('passports'),
            resource: 'passports.Passport',
          },
          {
            argument: 'currency_list',
            placeholder: gettext('currency'),
            resource: 'finances.currency',
          },
          {
            argument: 'clientrole_list',
            placeholder: gettext('counterparty'),
            resource: 'clients.clientrole',
          },
          {
            argument: 'author_list',
            placeholder: gettext('author'),
            resource: 'auth.user',
          },
          {
            argument: 'payment_bank_account_list',
            placeholder: gettext('payment bank account'),
            resource: 'finances.bankaccount',
          },
          {
            argument: 'invoice_list',
            placeholder: gettext('invoices'),
            resource: 'finances.Finance',
          },
          {
            argument: 'passport_custom_status_list',
            placeholder: gettext('passport custom status'),
            resource: 'core.CustomStatus',
            queryParams: {
              content_type__model: 'passport',
            },
          },
          {
            argument: 'commodity_list',
            placeholder: gettext('Product'),
            resource: 'crops.Crop',
          },
          {
            argument: 'bu_list',
            placeholder: gettext('BU'),
            resource: 'core.BusinessUnit',
          },
          {
            argument: 'buyer_list',
            placeholder: gettext('Buyer'),
            resource: 'clients.Buyer',
          },
          {
            argument: 'supplier_list',
            placeholder: gettext('Supplier'),
            resource: 'clients.Supplier',
          },
          {
            argument: 'origin_list',
            placeholder: gettext('Origin'),
            resource: 'location.Country',
          },
          {
            argument: 'destination_list',
            placeholder: gettext('Destination'),
            resource: 'location.Country',
          },
          {
            argument: 'loadport_list',
            placeholder: gettext('Loadport'),
            resource: 'logistics.Port',
          },
          {
            argument: 'disport_list',
            placeholder: gettext('Disport'),
            resource: 'logistics.Port',
          },
          {
            argument: 'voyage_list',
            placeholder: gettext('Voyage'),
            resource: 'logistics.Voyage',
          },
          {
            argument: 'customs_declaration_list',
            placeholder: gettext('Customs declaration'),
            resource: 'logistics.CustomsCargoDeclaration',
          },
          {
            argument: 'multicontract_list',
            placeholder: gettext('Multicontract'),
            resource: 'contracts.Multicontract',
          },
        ],
        nestedSelects: [
          gtFilterService.getBoolFilter('has_multicontract', gettext('Has multicontract')),
          gtFilterService.getBoolFilter('has_analytical_amount', gettext('Has Analytical amount')),
          gtFilterService.getBoolFilter('has_our_amount', gettext('Has Our amount')),
          gtFilterService.getBoolFilter('has_final_amount', gettext('Has Final amount')),
          gtFilterService.getBoolFilter('has_several_passports', gettext('Has several passports')),
          gtFilterService.getBoolFilter('has_invoice_documents', gettext('Has invoice documents')),
          gtFilterService.getBoolFilter('fully_invoiced', gettext('Fully invoiced')),
          gtFilterService.getBoolFilter('not_invoiced', gettext('Not invoiced')),
          gtFilterService.getBoolFilter(
            'has_different_basis_with_passport_contracts',
            gettext('Has cost basis warning'),
          ),
          gtFilterService.getBoolFilter(
            'has_passport_key',
            gettext('Passport filled in  manually'),
          ),
          gtFilterService.getBoolFilter(
            'final_amount_diff_invoiced',
            gettext('final amount <> invoiced'),
          ),
          gtFilterService.getBoolFilter(
            'final_amount_diff_amount',
            gettext('final amount <> amount'),
          ),
          gtFilterService.getBoolFilter('our_amount_diff_amount', gettext('our amount <> amount')),
          gtFilterService.getBoolFilter('invoiced_diff_amount', gettext('invoiced <> amount')),
          gtFilterService.getBoolFilter(
            'analytical_amount_diff_amount',
            gettext('analytical amount <> amount'),
          ),
          gtFilterService.getBoolFilter('invoiced_diff_paid', gettext('invoiced <> paid')),
          gtFilterService.getBoolFilter('paid_diff_amount', gettext('paid <> amount')),
          gtFilterService.getBoolFilter('has_invoice', gettext('Has invoice')),
          gtFilterService.getBoolFilter('has_payment', gettext('Has payment')),
          gtFilterService.getBoolFilter('is_gain', gettext('Is gain')),
          gtFilterService.getBoolFilter('with_documents', gettext('Has documents')),
          gtFilterService.getBoolFilter(
            'has_amount_per_deal_execution_is_gain',
            gettext('Has Amount Per Deal Execution'),
          ),
          gtFilterService.getBoolFilter('has_contract_key', gettext('Has contract')),
          gtFilterService.getBoolFilter(
            'counterparty_diffs_contract_counterparty',
            gettext('Cost-Contract counterparty difference'),
          ),
          gtFilterService.getBoolFilter(
            'counterparty_diffs_invoice_counterparty',
            gettext('Cost-Invoice counterparty difference'),
          ),
          gtFilterService.getBoolFilter(
            'invoice_counterparty_diffs_contract_counterparty',
            gettext('Invoice-Contract counterparty difference'),
          ),
          gtFilterService.getBoolFilter('has_logistic', gettext('Has logistic')),
          gtFilterService.getYesFilter('has_ticket', gettext('Has ticket')),
          gtFilterService.getYesFilter('has_indicator', gettext('Has indicator')),
        ],
        nestedMultiSelects: [
          {
            argument: 'status_list',
            placeholder: gettext('Status'),
            items: [
              { id: 'validated', title: 'Validated' },
              { id: 'under_discussion', title: 'Under discussion' },
              { id: 'pending_confirmation', title: 'Pending confirmation' },
              { id: 'forecast', title: 'Forecast' },
              { id: 'planned', title: 'Planned' },
              { id: 'cancelled', title: 'Cancelled' },
              { id: 'closed', title: 'Closed' },
            ],
          },
          gtFilterService.getClientRoleFilter('clientrole_name_list', gettext('client role name')),
          gtFilterService.getPassportStatusFilter('passport_status_list', 'passport status'),
          gtFilterService.getContractStatusFilter(
            'contract_status_from_invoice_list',
            'contract status from invoices',
          ),
          {
            argument: 'passport_approval_status_list',
            placeholder: gettext('passport approval status'),
            items: [
              { id: 'wait', title: gettext('Waiting for approval') },
              { id: 'approved', title: gettext('Approved') },
              { id: 'process', title: gettext('Approval in process') },
              { id: 'rejected', title: gettext('Rejected') },
            ],
          },
        ],
      };
      CustomValuesService.getCustomFieldFilters({
        purpose_model: 'contractcharge',
      })
        .then((customFieldsFilters) => {
          config.filters.multiSelects.push(...customFieldsFilters.multiSelects);
          config.filters.selects.push(...customFieldsFilters.selects);
          config.filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
          config.filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
          config.filters.dateSelects.push(...customFieldsFilters.dateSelects);
          $scope.$applyAsync();
        })
        .catch(errorHandler);
      return config;
    }
  }
})();
