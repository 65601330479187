import type ng from 'angular';

import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class MailingService {
  $http: ng.IHttpService;
  $uibModal: ng.ui.bootstrap.IModalService;
  Upload: ng.angularFileUpload.IUploadService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $http: ng.IHttpService,
    $uibModal: ng.ui.bootstrap.IModalService,
    Upload: ng.angularFileUpload.IUploadService,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.Upload = Upload;
    this.gettext = gettext;
  }

  getPagesConfig() {
    return [
      {
        title: this.gettext('Newsletters'),
        permissions: [],
        state: 'mailing.logistics',
        icon: 'fa-envelope',
        tabs: [
          {
            title: this.gettext('Templates'),
            state: 'mailing.templates',
            icon: 'fa-envelope-open',
          },
          {
            title: this.gettext('Logistics'),
            state: 'mailing.logistics',
            icon: 'fa-train',
          },
          {
            title: this.gettext('Invoices'),
            state: 'mailing.invoices',
            icon: 'fa-credit-card-alt',
          },
        ],
      },
    ];
  }

  getLogisticsTableData(params?: object) {
    params = params ?? {};
    return this.$http({
      method: 'GET',
      url: '/api/logistics/logistics-list/info/',
      params: params,
    }).then((response: any) => response.data);
  }

  createMailingsFromLogistics(postData: any) {
    return this.$http({
      method: 'POST',
      url: '/api/emailtemplates/client-mailings/from_logistics/',
      data: postData,
    }).then((response: any) => response.data);
  }

  getFinancesTableData(params?: object) {
    params = params ?? {};

    return this.$http({
      method: 'GET',
      url: '/api/finances/mailing-invoices-list/',
      params: params,
    }).then((response: any) => response.data);
  }

  getFinancesTableTotals(params?: object) {
    params = params ?? {};

    return this.$http({
      method: 'GET',
      url: '/api/finances/mailing-invoices-total/',
      params: params,
    }).then((response: any) => response.data);
  }

  createMailingsFromFinances(postData: any) {
    return this.$http({
      method: 'POST',
      url: '/api/emailtemplates/client-mailings/from_finances/',
      data: postData,
    }).then((response: any) => response.data);
  }

  clientMailingModal(clientMailing: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`
        <client-mailing-modal client-mailing="clientMailing" modal-instance="$uibModalInstance">
        </client-mailing-modal>
      `,
      controller: [
        '$scope',
        '$uibModalInstance',
        function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
          ($scope as any).clientMailing = clientMailing;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result;
  }

  getClientMailings(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/emailtemplates/client-mailings/',
      params: params,
    }).then((response: any) => response.data);
  }

  deleteClientMailing(mailing: any) {
    return this.$http({
      method: 'DELETE',
      url: `/api/emailtemplates/client-mailings/${mailing.id}/`,
    });
  }

  sendClientMailing(mailing: any) {
    return this.$http({
      method: 'post',
      url: `/api/emailtemplates/client-mailings/${mailing.id}/send/`,
    });
  }

  saveClientMailing(mailing: any) {
    return this.$http({
      method: 'PATCH',
      url: `/api/emailtemplates/client-mailings/${mailing.id}/`,
      data: mailing,
    });
  }

  saveMailingTo(email: any) {
    if (email.id) {
      return this.$http({
        method: 'PATCH',
        url: `/api/emailtemplates/mailings-to/${email.id}/`,
        data: email,
      });
    }
    return this.$http({
      method: 'POST',
      url: '/api/emailtemplates/mailings-to/',
      data: email,
    });
  }

  deleteMailingTo(email: any) {
    return this.$http({
      method: 'DELETE',
      url: `/api/emailtemplates/mailings-to/${email.id}/`,
    });
  }

  saveMailingCc(email: any) {
    if (email.id) {
      return this.$http({
        method: 'PATCH',
        url: `/api/emailtemplates/mailings-cc/${email.id}/`,
        data: email,
      });
    }
    return this.$http({
      method: 'POST',
      url: '/api/emailtemplates/mailings-cc/',
      data: email,
    });
  }

  deleteMailingCc(email: any) {
    return this.$http({
      method: 'DELETE',
      url: `/api/emailtemplates/mailings-cc/${email.id}/`,
    });
  }

  saveMailingBcc(email: any) {
    if (email.id) {
      return this.$http({
        method: 'PATCH',
        url: `/api/emailtemplates/mailings-bcc/${email.id}/`,
        data: email,
      });
    }
    return this.$http({
      method: 'POST',
      url: '/api/emailtemplates/mailings-bcc/',
      data: email,
    });
  }

  deleteMailingBcc(email: any) {
    return this.$http({
      method: 'DELETE',
      url: `/api/emailtemplates/mailings-bcc/${email.id}/`,
    });
  }

  deleteMailingAttachment(attachment: any) {
    return this.$http({
      method: 'DELETE',
      url: `/api/emailtemplates/mailing-attachments/${attachment.id}/`,
    });
  }

  saveAttachment(attachment: Record<string, unknown>, file: File) {
    return this.Upload.upload({
      url: '/api/emailtemplates/mailing-attachments/',
      method: 'POST',
      data: { ...attachment, file },
    });
  }
}
MailingService.$inject = ['$http', '$uibModal', 'Upload', 'gettext'];
