import type ng from 'angular';
import type { Subscription } from 'rxjs';

import {
  type CounterpartyQueryParams,
  type CounterpartyRecord,
} from '~/features/crm/counterparties';
import { CounterpartiesContainerModel } from '~/features/crm/counterparties/components/counterparties-container/counterparties-container.model';
import { container } from '~/shared/lib/di';
import { notify } from '~/shared/lib/notify';

import template from './client-list-container.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { DocumentsService } from '^/app/company/documents/legacy/documents.srv.ts';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ClientsService } from '^/app/crm/clients/legacy/clients.srv.ts';

type ViewMode = 'table' | 'block' | 'spreadsheet' | 'list' | 'grid';
type CounterpartyListQueryParams = CounterpartyQueryParams & { next?: boolean };

class ClientListContainer implements ng.IController {
  containerModel: CounterpartiesContainerModel;
  pageData: { count: number; records: CounterpartyRecord[] } = { records: [], count: 0 };

  subs: Record<string, Subscription> = {};

  queryParams: CounterpartyListQueryParams = { page_size: 25, page: 1 };
  initQueryParams: Partial<CounterpartyListQueryParams> = {};
  filterLevel = 'client-list-container';
  clients: CounterpartyRecord[] = [];
  clientsCount = 0;
  quickAdd = false;
  newClient: Partial<CounterpartyRecord & { $_edit: boolean }> = {};
  newClientTemplate: Partial<CounterpartyRecord> = {};
  activeFilterPresets = [];
  view: ViewMode = 'table';
  editing = false;

  static readonly $inject = [
    '$scope',
    '$rootScope',
    '$state',
    'ClientsService',
    'gtFilterService',
    'AccountsService',
    'GtUtils',
    'gettext',
    'PageService',
    'DocumentsService',
  ];
  private readonly handlers;

  constructor(
    private readonly $scope: ng.IScope,
    private readonly $rootScope: GtRootScopeService,
    private readonly $state: ng.ui.IStateService,
    private readonly ClientsService: ClientsService,
    private readonly gtFilterService: GtFilterService,
    private readonly AccountsService: AccountsService,
    private readonly GtUtils: GtUtilsService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly PageService: PageService,
    private readonly DocumentsService: DocumentsService,
  ) {
    this.containerModel = container.resolve(CounterpartiesContainerModel);
    this.handlers = {
      openEditModal: (counterparties: CounterpartyRecord[]) =>
        this.openClientModal(counterparties[0]),
      clone: (counterparties: CounterpartyRecord[]) => this.cloneClient(counterparties[0]),
      openEmailModal: (counterparties: CounterpartyRecord[]) =>
        this.openEmailModal(counterparties[0]),
      openDocxModal: (counterparties: CounterpartyRecord[]) =>
        this.openDocxModal(counterparties[0]),
    };
  }

  $onInit() {
    this.subs.pageData = this.containerModel.pageData$.subscribe((data) => {
      this.pageData = data;
      this.updateData();
      this.$scope.$applyAsync();
    });

    this.subs.loading = this.containerModel.loading$.subscribe((loading) => {
      this.GtUtils.overlay(loading ? 'show' : 'hide');
      this.$scope.$applyAsync();
    });

    this.subs.editing = this.containerModel.editingMode$.subscribe((editing) => {
      this.editing = editing;
      this.$scope.$applyAsync();
    });

    this.queryParams = { ...this.queryParams, ...this.initQueryParams };
    this.gtFilterService.subscribe(
      this.filterLevel,
      (params) => {
        this.queryParams = params;
        this.containerModel.pageParamsUpdated(this.queryParams);
      },
      this.queryParams,
    );
  }

  $onDestroy() {
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());
  }

  applyFilters(params: QueryParams) {
    this.gtFilterService.updateQueryParams(params, this.filterLevel);
  }

  getQueryParams() {
    return this.gtFilterService.getQueryParams(this.filterLevel);
  }

  changeView = (view: ViewMode) => {
    this.view = view;
    this.$scope.$applyAsync();
  };

  updateData(origin?: CounterpartyRecord) {
    this.newClientTemplate = { ...origin };
    this.PageService.setTabCounter('clients.client', this.pageData.count);
    if (this.queryParams.next) {
      this.clients = this.clients.concat(this.pageData.records);
      delete this.queryParams.next;
    } else {
      this.clients = this.pageData.records;
    }
    this.clientsCount = this.pageData.count;
    this.newClient = { $_edit: true };
    if (this.quickAdd && this.$rootScope.user.settings.ENABLE_INLINE_ADD) {
      this.clients.unshift(this.newClient as CounterpartyRecord);
    }
  }

  toggleInlineAdd() {
    if (this.quickAdd) {
      this.clients.shift();
    } else {
      this.newClient = { $_edit: true, ...this.newClientTemplate, ...this.newClient };
      this.clients.unshift(this.newClient as CounterpartyRecord);
    }
    this.clients = [...this.clients];
    this.quickAdd = !this.quickAdd;
  }

  cloneClient = (client: CounterpartyRecord, tab?: string) => {
    return this.ClientsService.cloneClient(client, tab).then(() =>
      this.containerModel.pageParamsChanged({}),
    );
  };

  openNewClientModal() {
    return this.ClientsService.clientModal().then(() => this.containerModel.pageParamsChanged({}));
  }

  openNewClientModalQuick() {
    return this.ClientsService.clientModalQuick().then(() =>
      this.containerModel.pageParamsChanged({}),
    );
  }

  openClientModal = (client: CounterpartyRecord, tab?: string) => {
    return this.ClientsService.clientModal(client.id, undefined, tab).then(() =>
      this.containerModel.pageParamsChanged({}),
    );
  };

  openClientModalQuick(client: CounterpartyRecord, tab: string) {
    return this.ClientsService.clientModalQuick(client.id, undefined, tab).then(() =>
      this.containerModel.pageParamsChanged({}),
    );
  }

  openClientDetails(client: CounterpartyRecord, tab: string) {
    return this.$state.go('gt.page.client', { id: client.id, tab: tab });
  }

  openEmailModal = (client: CounterpartyRecord) => {
    return this.ClientsService.sendEmailModal('Client', client.id);
  };

  approveClient = (client: CounterpartyRecord, approve: boolean) => {
    let whenApproval;

    if (approve) {
      whenApproval = this.AccountsService.approveObject('Client', client.id);
    } else {
      whenApproval = this.AccountsService.declineObject('Client', client.id);
    }

    whenApproval
      .then(() => {
        this.updateData();
        notify(this.gettext('Approval updated'));
      })
      .catch(() => this.GtUtils.errorClb)
      .finally(() => {
        this.containerModel.pageParamsChanged({});
      });
  };

  openDocxModal = (client: CounterpartyRecord) => {
    return this.DocumentsService.generateDocxModal('Client', client.id);
  };
}

export const clientListContainer: ng.IComponentOptions = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    globalAddButton: '@?',
    view: '<?',
  },
  template,
  controller: ClientListContainer,
};
