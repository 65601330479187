import type ng from 'angular';

import template from './dbl-invoice-position-list-container.html?raw';
import type { QueryParams } from '../../../../core/types';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';

class DBLInvoicePositionListContainerController implements ng.IController {
  filterLevel?: string;
  queryParams: QueryParams = {
    has_free_dbl_amount: 1,
    page_size: 25,
  };
  dblInvoicePositionList?: any;
  purchaseContract?: number;
  saleContract?: number;
  dataCount?: number;
  useList?: string[];
  disbursementbl?: any;
  batchSaving?: boolean;
  onUpdate?: (args: { data: any }) => void;

  static readonly $inject = ['gtFilterService'];

  constructor(private readonly gtFilterService: GtFilterService) {}

  $onInit() {
    this.filterLevel = this.filterLevel ?? 'disburementdbl-invoice-position-page-view';
    this.setQueryParams();
  }

  $onChanges(changes: ng.IOnChangesObject) {
    if (
      [changes.dblInvoicePositionList, changes.purchaseContract, changes.saleContract].some(Boolean)
    ) {
      this.updateData();
    }
  }

  updateData() {
    this.dataCount = this.dblInvoicePositionList?.length;
    this.setQueryParams();
  }

  setQueryParams() {
    this.queryParams = {
      contract_list: [this.purchaseContract ?? 0, this.saleContract ?? 0],
      use_list: this.useList,
      disbursement_bl: this.disbursementbl?.id,
      has_free_dbl_amount: 1,
      page_size: 25,
    };
    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
  }
}

export const dblInvoicePositionListContainer: ng.IComponentOptions = {
  bindings: {
    dblInvoicePositionList: '<?',
    filterLevel: '<?',
    purchaseContract: '<?',
    saleContract: '<?',
    disbursementbl: '<?',
    useList: '<',
    onUpdate: '<',
  },
  template: template,
  controller: DBLInvoicePositionListContainerController,
};
