import template from './passport-pnl-table.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const PassportPnlTable = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'PassportPnlService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      PassportPnlService: any;
      filterLevel: string;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      showChildCrops: any;
      tableData: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        PassportPnlService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.PassportPnlService = PassportPnlService;

        this.filterLevel = '';
        this.queryParams = {};
        this.tableData = {};
        this.showChildCrops = false;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'passport-pnl-container';

        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateTableData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters() {
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateTableData() {
        if (this.queryParams.passport) {
          this.tableData = this.PassportPnlService.getPassportPnlTableData(this.queryParams).then(
            (data: any) => {
              this.tableData = data.results;
            },
          );
        }
      }
    },
  ],
};
