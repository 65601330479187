import { ComponentModel } from '~/shared/lib/components';
import { injectable } from '~/shared/lib/di';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from '~/shared/lib/state';
import { DataType } from '~/shared/ui/data-types';

import { emptyPreset } from '../constants';
import { FilterOption, FilterValue, PageFiltersProps, Preset, SortingValue } from '../types';

@injectable()
export class PageFiltersModel extends ComponentModel<PageFiltersProps> {
  private readonly presetSubj = new BehaviorSubject<Preset>(emptyPreset);

  preset$ = this.presetSubj.pipe(shareReplay({ bufferSize: 1, refCount: false }));

  presetOptions$ = this.props$.pipe(
    distinctUntilKeyChanged('presetOptions'),
    map(({ presetOptions }) => presetOptions),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  filtersOptions$ = this.props$.pipe(
    distinctUntilKeyChanged('filtersOptions'),
    map((props) => props.filtersOptions),
  );

  filtersOptionsByArgument$ = this.filtersOptions$.pipe(
    map((filtersOptions) =>
      filtersOptions.reduce<Record<string, FilterOption<DataType>>>(
        (acc, filter) => ({ ...acc, [filter.argument]: filter }),
        {},
      ),
    ),
  );

  sortingOptions$ = this.props$.pipe(
    distinctUntilKeyChanged('sortingOptions'),
    map((props) => props.sortingOptions),
  );

  presetOriginal$ = combineLatest({
    presetId: this.preset$.pipe(
      map(({ id }) => id),
      distinctUntilChanged(),
    ),
    opts: this.presetOptions$,
  }).pipe(
    map(({ presetId, opts }) => opts.find((o) => o.id === presetId)),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  private readonly filtersOriginalById$ = this.presetOriginal$.pipe(
    map((preset) =>
      (preset?.filters ?? []).reduce(
        (acc, filter) => ({ ...acc, [filter.id]: filter }),
        {} as Record<string, FilterValue<DataType>>,
      ),
    ),
  );

  filtersChangedById$ = combineLatest(
    [this.preset$, this.filtersOriginalById$],
    (preset, filtersOriginal) => ({ preset, filtersOriginal }),
  ).pipe(
    map(({ preset, filtersOriginal }) => {
      return preset.filters
        .filter((filter) => {
          return JSON.stringify(filter) !== JSON.stringify(filtersOriginal[filter.id]);
        })
        .reduce(
          (acc, filter) => ({ ...acc, [filter.id]: filter }),
          {} as Record<string, FilterValue<DataType>>,
        );
    }),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  filtersDeletedById$ = combineLatest(
    [this.preset$, this.filtersOriginalById$],
    (preset, filtersOriginalById) => ({ preset, filtersOriginalById }),
  ).pipe(
    map(({ preset, filtersOriginalById }) => {
      return Object.values(filtersOriginalById)
        .filter((filter) => !preset.filters.some((f) => f.id === filter.id))
        .reduce(
          (acc, filter) => ({ ...acc, [filter.id]: filter }),
          {} as Record<string, FilterValue<DataType>>,
        );
    }),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  filters$ = combineLatest([this.preset$, this.filtersOptionsByArgument$]).pipe(
    withLatestFrom(
      combineLatest({
        filtersById: this.filtersOriginalById$,
        changed: this.filtersChangedById$,
        deleted: this.filtersDeletedById$,
      }),
    ),
    map(([[preset, options], { filtersById, changed, deleted }]) => {
      const filters = preset.filters.reduce(
        (acc, filter) => ({ ...acc, [filter.id]: filter }),
        filtersById,
      );

      return Object.values(filters).map((filter) => ({
        value: filter,
        option: options[filter.argument],
        mode: (() => {
          if (changed[filter.id]) {
            return 'changed';
          }
          if (deleted[filter.id]) {
            return 'disabled';
          }
          return 'default';
        })(),
      }));
    }),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  sortingChanged$ = combineLatest([this.preset$, this.presetOriginal$]).pipe(
    map(([preset, presetOriginal]) => {
      return preset.sorting?.argument !== presetOriginal?.sorting?.argument;
    }),
  );

  dirty$ = combineLatest([
    this.filtersChangedById$,
    this.filtersDeletedById$,
    this.sortingChanged$,
  ]).pipe(
    map(
      ([changed, deleted, ordering]) =>
        !!(Object.keys(changed).length || Object.keys(deleted).length || ordering),
    ),
  );

  sorting$ = this.preset$.pipe(
    distinctUntilKeyChanged('sorting'),
    map((preset) => preset.sorting),
  );

  changePreset = (preset: Preset, force?: boolean) => {
    this.presetSubj.next(preset);
    this.props$
      .pipe(
        take(1),
        map((props) => props.onChange(preset, force)),
      )
      .subscribe();
  };

  savePreset = (preset: Preset) => {
    this.props$
      .pipe(
        take(1),
        switchMap((props) => props.onSave(preset)),
        delay(100),
        withLatestFrom(this.presetOriginal$),
        map(([, presetOrig]) => this.changePreset(presetOrig ?? preset)),
      )
      .subscribe();
  };

  deletePreset = (preset: Preset) => {
    this.props$
      .pipe(
        take(1),
        switchMap((props) => props.onDelete(preset)),
        tap(this.resetPreset),
      )
      .subscribe();
  };

  changeSorting = (sorting?: SortingValue) => {
    this.preset$
      .pipe(
        take(1),
        map((preset) => this.changePreset({ ...preset, sorting })),
      )
      .subscribe();
  };

  deleteFilter = (filter: FilterValue<DataType>) => {
    this.preset$
      .pipe(
        take(1),
        tap((preset) =>
          this.changePreset({
            ...preset,
            filters: preset.filters.filter((f) => f.id !== filter.id),
          }),
        ),
      )
      .subscribe();
  };

  changeFilter = (filter: FilterValue<DataType>) => {
    this.preset$
      .pipe(
        take(1),
        map((preset) => {
          const filters = preset.filters.filter((f) => f.id !== filter.id);
          return this.changePreset({
            ...preset,
            filters: [...filters, filter],
          });
        }),
      )
      .subscribe();
  };

  addFilter = (filter: FilterValue<DataType>) => {
    this.preset$
      .pipe(
        take(1),
        map((preset) => {
          return this.changePreset({
            ...preset,
            filters: [...preset.filters, filter],
          });
        }),
      )
      .subscribe();
  };

  resetPreset = () => {
    combineLatest([this.presetOriginal$, this.dirty$])
      .pipe(
        take(1),
        map(([presetOrig, dirty]) =>
          this.changePreset(presetOrig && dirty ? presetOrig : emptyPreset, true),
        ),
      )
      .subscribe();
  };
}
