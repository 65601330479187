import { embedDashboard } from '@superset-ui/embedded-sdk';
import React from 'react';

export const DashboardEmbedded: React.FC<{
  dashboardUUID: string;
  fetchGuestToken: () => Promise<string>;
  biDomain: string;
}> = ({ dashboardUUID, fetchGuestToken, biDomain }) => {
  const biContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (biContainerRef.current) {
      embedDashboard({
        id: dashboardUUID,
        supersetDomain: biDomain,
        fetchGuestToken,
        mountPoint: biContainerRef.current,
        dashboardUiConfig: {
          hideTitle: true,
          filters: {
            expanded: false,
          },
        },
      }).catch((error) => {
        console.error('Failed to embed dashboard', error);
      });
    }
  }, [biContainerRef, dashboardUUID, fetchGuestToken, biDomain]);

  return (
    <div
      ref={biContainerRef}
      className="mt-6 [&>iframe]:h-screen [&>iframe]:w-full"
      data-testid="dashboard-embeded"
    />
  );
};
