import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive('generalAgreementsQuickEdit', directive);

  function directive() {
    return {
      template: require('./general-agreements-quick-edit.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        quickAdd: '=?',
        quickAddInit: '<?',
        onSave: '&?',
      },
    };
  }

  Controller.$inject = ['$scope', 'ContractsService', 'FinancesService', 'GtUtils', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    ContractsService: ContractsService,
    FinancesService: FinancesService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.save = save;
    vm.close = close;
    vm.currencyExchange = {};
    vm.saveDisabled = false;
    vm.onTypeChange = onTypeChange;

    vm.$onInit = function () {
      vm.agreement = getInitAgreement();
    };

    ////////////////

    function getInitAgreement() {
      return {
        tax_registration: 'free_tax_invoice',
        agreement_type: 'sale',
        ...(vm.quickAddInit || {}),
      };
    }

    function onTypeChange() {
      vm.agreement.buyer = undefined;
      vm.agreement.supplier = undefined;
    }

    function close() {
      vm.quickAdd = false;
    }

    function save() {
      vm.saveDisabled = true;

      if (!vm.agreement.id) {
        return ContractsService.GeneralAgreements.save(
          vm.agreement,
          function () {
            vm.agreement = getInitAgreement();
            vm.onSave();
            vm.saveDisabled = false;
            notify(gettext('Agreement saved'));
          },
          _error,
        );
      }
      return ContractsService.GeneralAgreements.update(
        vm.agreement,
        function () {
          vm.agreement = getInitAgreement();
          vm.onSave();
          vm.saveDisabled = false;
        },
        _error,
      );
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
      vm.saveDisabled = false;
    }
  }
})();
