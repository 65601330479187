import type ng from 'angular';

import template from './analytical-plan-fact-pnl-report-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const AnalyticalPlanFactPnlReportPage = {
  bindings: {
    filterLevel: '<',
  },
  template,
  controller: [
    '$rootScope',
    'PageService',
    'ReportsService',
    'gtFilterService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      PageService: PageService;
      ReportsService: ReportsService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        $rootScope: GtRootScopeService,
        PageService: PageService,
        ReportsService: ReportsService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;
        this.$rootScope = $rootScope;
        this.filterLevel = 'analytical-plan-fact-pnl-report-page';
      }
      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.gtFilterService.setQueryParams(
          {
            group_by: 'commodity',
            start_date: `01.01.${this.$rootScope.user.settings.DEFAULT_VALUES.crop_year}`,
            end_date: `31.12.${this.$rootScope.user.settings.DEFAULT_VALUES.crop_year}`,
          },
          this.filterLevel,
        );
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.Filters(),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            search: false,
            dates: true,
            useFlexDateRange: true,
            invert_filters: true,
            multiSelects: [
              {
                argument: 'commodity_list',
                placeholder: this.gettext('commodity'),
                resource: 'crops.crop',
              },
              {
                argument: 'parent_commodity_list',
                placeholder: this.gettext('Parent commodity'),
                resource: 'crops.crop',
              },
              {
                argument: 'analytical_parent_commodity_list',
                placeholder: this.gettext('Analytical parent commodity'),
                resource: 'crops.crop',
              },
              {
                argument: 'business_unit_list',
                placeholder: this.gettext('business units'),
                resource: 'core.businessunit',
              },

              {
                argument: 'seasons_list',
                placeholder: this.gettext('Seasons'),
                resource: 'core.season',
              },
              {
                argument: 'passport_custom_status_list',
                placeholder: this.gettext('Custom statuses'),
                resource: 'core.customstatus',
              },
            ],
            nestedSelects: [],
            nestedMultiSelects: [
              {
                argument: 'passport_status_list',
                placeholder: this.gettext('Passport status'),
                items: [
                  { id: 'Request', title: this.gettext('Approved') },
                  { id: 'Approved', title: this.gettext('Proforma Invoice') },
                  { id: 'Processing', title: this.gettext('Processing') },
                  { id: 'cargo_partial_executed', title: this.gettext('Cargo partial executed') },
                  { id: 'cargo_executed', title: this.gettext('Cargo executed') },
                  { id: 'Executed', title: this.gettext('Executed') },
                  { id: 'Cancelled', title: this.gettext('Cancelled') },
                ],
              },
            ],
          },
        };
      }
    },
  ],
};
