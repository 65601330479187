import { Preset } from '~/core/page-filters';
import { ComponentModel } from '~/shared/lib/components';
import { injectable } from '~/shared/lib/di';
import { EntityQueryParams } from '~/shared/lib/entities';
import { notifySuccess, notifyWarn } from '~/shared/lib/notify';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilKeyChanged,
  filter,
  from,
  lastValueFrom,
  map,
  switchMap,
  take,
  tap,
} from '~/shared/lib/state';
import { askUser } from '~/shared/ui/lib/ask-user';

import {
  fieldDefToFilterDef,
  fieldDefToSortingOption,
  filterDefToFilterOption,
  presetToQueryParams,
  presetToQueryParamsSet,
  queryParamsSetToPreset,
} from '../../helpers/filters';
import { DataRecord, DatasetIdSplit, FieldDef, FilterDef } from '../../lib/types';
import { QueryParamsSetRepository } from '../../services/query-params-set.repository';

export type DatasetFiltersProps<R extends DataRecord, P extends EntityQueryParams> = {
  datasetId$: Observable<DatasetIdSplit>;
  fields$: Observable<FieldDef<R, P>[]>;
  onParamsChange: ({ params, replace }: { params: P; replace?: boolean }) => void;
};

@injectable()
export class DatasetFiltersModel<
  R extends DataRecord,
  P extends EntityQueryParams,
> extends ComponentModel<DatasetFiltersProps<R, P>> {
  private readonly presetsUpdatedSubj = new BehaviorSubject<void>(undefined);

  constructor(private readonly queryParamsSetRepo: QueryParamsSetRepository) {
    super();
  }

  filterDefs$ = this.props$.pipe(
    distinctUntilKeyChanged('fields$'),
    switchMap(({ fields$ }) => fields$),
    map((fields) => fields.flatMap(fieldDefToFilterDef)),
  );

  datasetId$ = this.props$.pipe(
    distinctUntilKeyChanged('datasetId$'),
    switchMap(({ datasetId$ }) => datasetId$),
  );

  filterDefsByArgument$ = this.filterDefs$.pipe(
    map((defs) =>
      defs.reduce<Record<string, FilterDef<P>>>(
        (acc, filter) => ({ ...acc, [filter.argument]: filter }),
        {},
      ),
    ),
  );

  filtersOptions$ = this.filterDefs$.pipe(map((defs) => defs.map(filterDefToFilterOption)));
  sortingOptions$ = this.props$.pipe(
    distinctUntilKeyChanged('fields$'),
    switchMap(({ fields$ }) => fields$),
    map((fields) => fields.filter((f) => f.sorting).map(fieldDefToSortingOption)),
  );
  presetOptions$ = combineLatest({
    filterDefMap: this.filterDefsByArgument$,
    presetsUpdated: this.presetsUpdatedSubj,
    datasetId: this.datasetId$,
  }).pipe(
    switchMap(({ datasetId, filterDefMap }) =>
      this.queryParamsSetRepo
        .query({ page_size: 1000, filter_level: datasetId.instanceName })
        .then(({ records }) => records.map((param) => queryParamsSetToPreset(param, filterDefMap))),
    ),
  );

  presetChanged = (preset: Preset) => {
    this.filterDefsByArgument$
      .pipe(
        take(1),
        map((defs) => presetToQueryParams(preset, defs)),
        tap((params) => this.props.onParamsChange({ params, replace: true })),
      )
      .subscribe();
  };

  savePreset = (preset: Preset) => {
    const result$ = combineLatest([this.filterDefsByArgument$, this.datasetId$]).pipe(
      take(1),
      switchMap(([defs, datasetId]) =>
        this.queryParamsSetRepo.saveEntity(
          presetToQueryParamsSet({ ...preset, datasetId: datasetId.instanceName }, defs),
        ),
      ),
      tap(() => notifySuccess('Preset saved')),
      map(() => this.presetsUpdatedSubj.next()),
    );
    return lastValueFrom(result$);
  };

  deletePreset = (preset: Preset) => {
    if (preset.id < 0) {
      return Promise.resolve(notifyWarn('Cannot delete unsaved preset'));
    }
    return lastValueFrom(
      from(askUser()).pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.queryParamsSetRepo.delete(preset.id)),
        map(() => notifySuccess('Preset deleted')),
        tap(() => this.presetsUpdatedSubj.next()),
      ),
    );
  };
}
