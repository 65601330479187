import ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import type { QueryParams } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('BalanceModalController', Controller);

  Controller.$inject = ['$uibModalInstance', 'mode', 'queryParams', 'amount', 'gettext'];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    mode: any,
    queryParams: QueryParams,
    amount: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.amount = amount;
    vm.mode = mode || 'crop';
    vm.close = close;
    vm.queryParams = {
      business_unit_list: queryParams.business_unit_list,
      finance_account_id: queryParams.finance_account_id,
      start_date:
        queryParams.start_date && formatDate(queryParams.start_date as string, 'yyyy-MM-dd'),
      end_date: queryParams.end_date && formatDate(queryParams.end_date as string, 'yyyy-MM-dd'),
    };

    ////////////////

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
