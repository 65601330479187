import ng from 'angular';

import { notify } from '~/shared/lib/notify';

(function () {
  'use strict';

  ng.module('company.documents.legacy').controller('DocumentModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    'DocumentsService',
    'contentType',
    'objectId',
    'type',
    'gettext',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    DocumentsService: any,
    contentType: any,
    objectId: number,
    type: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.errors = {};
    vm.inProgress = false;
    vm.close = close;
    vm.save = save;
    vm.fileSelect = fileSelect;
    vm.hasDocType = hasDocType;
    vm.files = [];
    vm.document = {
      file: null,
      title: '',
      content_type: contentType,
      object_id: objectId,
      checklist_point: null,
    };
    vm.documents = [];
    vm.type = type;
    vm.basicDocTypes = ['contract', 'contractbase', 'finance', 'voyage'];
    vm.clientDocumentTypes = ['client'];
    vm.documentChecklistTypes = [
      'logistic',
      'consignment',
      'billoflading',
      'contractcharge',
      'disbursementbl',
      'generalagreement',
      'vehicle',
      'driver',
      'salemulticontract',
      'purchasemulticontract',
      'intermediatemulticontract',
      'exportmulticontract',
      'servicesmulticontract',
      'salemultiticket',
      'purchasemultiticket',
      'exportmultiticket',
      'servicesmultiticket',
    ];
    vm.fieldNameByType = {
      ...vm.basicDocTypes.reduce((res: any, t: any) => {
        res[t] = 'basis_doc';
        return res;
      }, {}),
      ...vm.clientDocumentTypes.reduce((res: any, t: any) => {
        res[t] = 'client_document_type';
        return res;
      }, {}),
      ...vm.documentChecklistTypes.reduce((res: any, t: any) => {
        res[t] = 'checklist_point';
        return res;
      }, {}),
    };

    ////////////////
    function prepareDoc(file: any) {
      const newDoc: any = {
        file: file,
        title: file.title || file.name,
        content_type: contentType,
        object_id: objectId,
      };
      if (vm.fieldNameByType[vm.type]) {
        newDoc[vm.fieldNameByType[vm.type]] = file[vm.fieldNameByType[vm.type]];
      }
      return newDoc;
    }
    function save() {
      vm.documents = [];
      vm.inProgress = true;
      let chain = Promise.resolve();
      vm.files.forEach(function (file: any) {
        vm.documents.push(prepareDoc(file));
        chain = chain.then(function () {
          return DocumentsService.saveDocument(vm.documents.pop()).then(
            function () {
              vm.inProgress = false;
              notify(gettext('Document saved'));
            },
            function () {
              vm.inProgress = false;
              notify(gettext('Server error'), 'error');
            },
          );
        });
      });
      return chain.then(
        function (data) {
          vm.close(data, true);
        },
        function () {
          notify(gettext('Server error'), 'error');
        },
      );
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function fileSelect(files: any) {
      delete vm.errors.file_size;
      vm.files = files;
      vm.files.forEach(function (file: any) {
        if (!file.size) {
          vm.errors.file_size = gettext('empty file');
        } else if (file.size > 52428800) {
          vm.errors.file_size = gettext('single document size up to 50 mb');
        }
        file.title = file.name;
      });

      if (vm.errors.file_size) {
        notify(vm.errors.file_size, 'error');
        return false;
      }
    }

    function hasDocType() {
      return (
        vm.documentChecklistTypes.includes(vm.type) ||
        vm.basicDocTypes.includes(vm.type) ||
        vm.clientDocumentTypes.includes(vm.type)
      );
    }
  }
})();
