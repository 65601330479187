import type { FinancePositionRecord } from '~/features/finances/finance-position/lib';
import type { EntityName } from '~/shared/lib/entities';

export const getSubuseEntityRecord = (position: FinancePositionRecord) => {
  let value = null;
  let entityName: EntityName | null = null;
  if (['cargo', 'costs', 'washout'].includes(position.use)) {
    value = { id: position.crop, title: position.subuse };
    entityName = 'crops.Commodity';
  }
  if (position.use === 'expenses') {
    value = {
      id: position.expenses_option,
      title: position.expenses_option ? position.subuse : '',
    };
    entityName = 'finances.GeneralExpenses';
  }
  if (position.use === 'other') {
    value = {
      id: position.other_activity,
      title: position.other_activity ? position.subuse : '',
    };
    entityName = 'finances.OtherActivity';
  }
  return { value: { id: value?.id ?? 0, title: value?.title ?? '' }, entityName };
};

export const getSubuseIdField = (
  position: FinancePositionRecord,
): 'crop' | 'expenses_option' | 'other_activity' | undefined => {
  if (['cargo', 'costs', 'washout'].includes(position.use)) {
    return 'crop';
  }
  if (position.use === 'expenses') {
    return 'expenses_option';
  }
  if (position.use === 'other') {
    return 'other_activity';
  }
};

export const calculateAmount = (entity: FinancePositionRecord) => {
  entity.amount = (entity.price_per_piece ?? 0) * (entity.quantity ?? 0);
  return entity;
};
