import ng from 'angular';

import template from './reassignment-position-table.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const ReassignmentPositionTable = {
  bindings: {
    reassignmentPositions: '<',
    edit: '<?',
  },
  template,
  controller: [
    '$scope',
    'gettext',
    'ReassignmentService',
    'FinancesService',
    'GtUtils',
    class {
      $scope: ng.IScope;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      ReassignmentService: any;
      gettext: ng.gettext.gettextFunction;
      reassignmentPositions: any;
      totalAmount: any;
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        ReassignmentService: any,
        FinancesService: FinancesService,
        GtUtils: GtUtilsService,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.ReassignmentService = ReassignmentService;
        this.FinancesService = FinancesService;
        this.GtUtils = GtUtils;
        this.totalAmount = undefined;
      }

      $onInit() {
        this.totalAmount = 0;
        this.reassignmentPositions = this.reassignmentPositions || [];
        this.$scope.$watch(
          () => this.reassignmentPositions,
          () => this.updateTotals(),
          true,
        );
      }

      updateTotals() {
        this.totalAmount = 0;
        ng.forEach(this.reassignmentPositions, (reassignmentPosition: any) => {
          this.totalAmount += reassignmentPosition.reassignment_amount;
        });
        this.totalAmount = Math.round(this.totalAmount * 100) / 100;

        if (this.totalAmount) {
          this.$scope.$emit('reassignment-position-table__change-amount', this.totalAmount);
        }
      }
    },
  ],
};
