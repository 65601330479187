import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './sale-export-contract-allocations-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const SaleExportContractAllocationsContainer = {
  bindings: {
    initQueryParams: '<?',
    readonly: '<?',
    filterLevel: '<',
    fullVolume: '<?',
    columns: '<?',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'gettext',
    'GtUtils',
    'SaleExportContractAllocationService',
    class SaleExportContractAllocationsContainerComponent {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      SaleExportContractAllocationService: any;
      addMode: any;
      allocations: any;
      exportContractQueryParams: QueryParams = {};
      filterLevel: string;
      fullVolume: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      passportQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      readonly: any;
      saleContractQueryParams: QueryParams = {};
      tableName?: string;
      totals: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        SaleExportContractAllocationService: any,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.SaleExportContractAllocationService = SaleExportContractAllocationService;

        this.filterLevel = '';
        this.queryParams = {};
        this.allocations = undefined;
        this.totals = {};
        this.readonly = false;

        this.initQueryParams = {};
        this.passportQueryParams = {};
        this.exportContractQueryParams = {};
        this.addMode = false;
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.filterLevel = this.filterLevel || 'sale-export-contract-allocations-container';
        this.tableName = this.filterLevel;

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });

        this.$scope.$watch(
          () => this.initQueryParams,
          () => this.updateQueryParams(),
        );
      }

      updateQueryParams() {
        this.exportContractQueryParams = {
          is_fully_allocated: '0',
          status_list: ['new', 'shipment', 'delivery_period'],
          relative_cargo: this.initQueryParams.contractCommodity,
        };
        this.saleContractQueryParams = {
          relative_cargo: this.initQueryParams.contractCommodity,
          passport_list: [this.initQueryParams.passport as any],
        };
        this.passportQueryParams = {
          cargo: this.initQueryParams.contractCommodity,
          id_list: [this.initQueryParams.passport as any],
        };

        if (this.initQueryParams.contractDealType === 'export') {
          this.tableName = 'export-contracts-allocations-table';
          this.queryParams = {
            export_contract: this.initQueryParams.contractId,
          };
          this.exportContractQueryParams.id_list = [this.initQueryParams.contractId as any];
        } else {
          this.tableName = 'sale-contracts-allocations-table';
          this.queryParams = {
            sale_contract: this.initQueryParams.contractId,
            passport: this.initQueryParams.passport,
          };
          this.saleContractQueryParams.id_list = [this.initQueryParams.contractId as any];
        }
        this.fullVolume = this.initQueryParams.contractVolume;
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        return this.SaleExportContractAllocationService.getList(this.queryParams).then(
          (data: any) => {
            this.allocations = data.results;
            this.allocations = [this.getNewConnection(), ...this.allocations];
          },
        );
      }

      saveItem(item: any) {
        return this.SaleExportContractAllocationService.save(item).then(() => {
          this.updateData();
          if (!item.id) {
            this.createPassportAfterQuestion(item);
          }
        }, this.GtUtils.errorClb);
      }

      createPassportAfterQuestion(allocation: any) {
        return this.SaleExportContractAllocationService.needPassportCreate(allocation).then(() =>
          notify('passport'),
        );
      }

      deleteItem(item: any) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return false;
        }
        return this.SaleExportContractAllocationService.delete({ id: item.id }).then(
          () => this.updateData(),
          this.GtUtils.errorClb,
        );
      }

      getNewConnection() {
        return {
          ...this.queryParams,
          volume: this.allocations.reduce((res: any, v: any) => res - v.volume, this.fullVolume),
          _editMode: true,
        };
      }

      onContractSelect(selectedItem: any) {
        return this.SaleExportContractAllocationService.getNotAllocatedVolume({
          id: selectedItem.export_contract,
          deal_type: 'export',
        }).then((result: any) => {
          if (result.volume - result.sale_contract_allocated_volume < this.fullVolume) {
            selectedItem.volume = result.sale_contract_allocated_volume;
            notify('Can`t fully allocate sale contract at export contract!', 'info');
            notify('Volume was set at free export contract value', 'success');
          }
          return result;
        });
      }
    },
  ],
};
