import React from 'react';

import { cn } from '~/shared/lib/utils';

import { type DataTypeProps, messageIntent } from '../../lib';

export const Template: React.FC<DataTypeProps<'template'>> = ({ message, typeExtra }) => {
  return (
    <div className={cn(message && messageIntent[message.type])}>
      {typeExtra?.Render && <typeExtra.Render {...typeExtra.props} />}
    </div>
  );
};
