import {
  ContractSerializer,
  PurchaseContractPatch,
  PurchaseContractPost,
  PurchaseContractRecord,
  PurchaseContractsQueryParams,
} from '~/features/deals/contracts';
import {
  PurchaseContract,
  contractsPurchaseContractsCreate,
  contractsPurchaseContractsDestroy,
  contractsPurchaseContractsListExecutionList,
  contractsPurchaseContractsListInfoList,
  contractsPurchaseContractsPartialUpdate,
  contractsPurchaseContractsPredictionsRetrieve,
  contractsPurchaseContractsRetrieve,
  contractsPurchaseContractsTotalsExecutionRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';
import { EntityOption } from '~/shared/ui/data-types';

export const purchaseContractsListDatasourceMap = {
  table_info: contractsPurchaseContractsListInfoList,
  table_execution: contractsPurchaseContractsListExecutionList,
  table_reconciliation: contractsPurchaseContractsListExecutionList,
  table_prepayments_accounting: contractsPurchaseContractsListInfoList,
  default: contractsPurchaseContractsListInfoList,
};

@singleton()
export class PurchaseContractsRepository extends EntityRepository<
  PurchaseContract,
  PurchaseContractsQueryParams,
  PurchaseContractRecord,
  PurchaseContractPost,
  PurchaseContractPatch
> {
  readonly entityName = 'contracts.PurchaseContract';
  listDatasource: (typeof purchaseContractsListDatasourceMap)[keyof typeof purchaseContractsListDatasourceMap] =
    purchaseContractsListDatasourceMap.default;

  setDatasources = (dsKey: ContractSerializer) => {
    this.listDatasource =
      purchaseContractsListDatasourceMap[dsKey] || purchaseContractsListDatasourceMap.default;
  };

  getFn = async (id: number) => {
    const { data } = await contractsPurchaseContractsRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: PurchaseContractsQueryParams) => {
    const { data } = await this.listDatasource({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await contractsPurchaseContractsPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };

  createFn = async (dto: PurchaseContractPost) => {
    const res = await contractsPurchaseContractsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  updateFn = async (entity: PurchaseContractPatch) => {
    const { data } = await contractsPurchaseContractsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  deleteFn = async (id: number) => {
    await contractsPurchaseContractsDestroy({ path: { id } });
  };

  getTotals = async (query: PurchaseContractsQueryParams) => {
    const { data } = await contractsPurchaseContractsTotalsExecutionRetrieve({ query });
    return data;
  };
}
