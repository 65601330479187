import { from, shareReplay, switchMap } from 'rxjs';

import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import {
  ContractSerializer,
  SaleContractPatch,
  SaleContractPost,
  SaleContractRecord,
  SaleContractsQueryParams,
} from '~/features/deals/contracts';
import { SaleContractsRepository } from '~/features/deals/contracts/sale/services/sale-contracts.repository';
import { SaleContract } from '~/shared/api';
import { injectable } from '~/shared/lib/di';

export type SaleContractsListContainerProps = ContainerProps<SaleContractsQueryParams>;

@injectable()
export class SaleContractsListViewModel extends EntityListContainerModel<
  SaleContract,
  SaleContractsQueryParams,
  SaleContractsRepository,
  SaleContractsListContainerProps,
  SaleContractRecord,
  SaleContractPost,
  SaleContractPatch
> {
  entityRepoClass = SaleContractsRepository;

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.repo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  public tableChanged(table: ContractSerializer) {
    this.repo.setDatasources(table);
  }
}
