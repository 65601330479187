import type ng from 'angular';

import template from './bill-of-lading-invoiceposition-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const billOfLadingInvoicepositionListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
    contractIdList: '<',
    extra: '<',
    onUpdate: '&?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'BillOfLadingInvoicepositionService',
    'gettext',
    class billOfLadingInvoicepositionContainer {
      $scope: ng.IScope;
      BillOfLadingInvoicepositionService: any;
      GtUtils: GtUtilsService;
      batchSaving: any;
      data: any;
      dataCount: number;
      extra: any;
      filterLevel = 'bill-of-lading-invoiceposiiton-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      newItem: any;
      onUpdate: any;
      queryParams: QueryParams = {};
      total: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        BillOfLadingInvoicepositionService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.BillOfLadingInvoicepositionService = BillOfLadingInvoicepositionService;

        this.queryParams = {};
        this.data = [];
        this.newItem = {};
        this.extra = {};
        this.dataCount = 0;
      }

      $onInit() {
        this.extra = this.extra || {};
        this.queryParams = { page_size: 25, ...this.initQueryParams };
        this.newItem = {
          ...this.initQueryParams,
        };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: QueryParams) {
        this.gtFilterService.updateQueryParams(
          {
            ...this.initQueryParams,
            ...params,
          },
          this.filterLevel,
        );
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.BillOfLadingInvoicepositionService.getData(this.queryParams)
          .then((data: any) => {
            if (this.queryParams.next) {
              this.data = [...this.data, ...data.data.results];
              delete this.queryParams.next;
            } else {
              data.data.results.unshift(this.newItem);
              this.data = data.data.results;
            }
            this.dataCount = data.data.count;
            this.total = data.totals;
          }, this.GtUtils.errorClb)
          .finally(() => {
            this.GtUtils.overlay('hide');
          });
      }

      _error(data: any) {
        this.GtUtils.errorClb(data);
        this.GtUtils.overlay('hide');
        this.batchSaving = false;
      }

      updateItem(item: any) {
        this.BillOfLadingInvoicepositionService.update(item).then(
          () => this.updateData(),
          this.GtUtils.errorClb,
        );
      }

      saveItem(item: any) {
        return this.BillOfLadingInvoicepositionService.save(item).then(() => {
          this.updateData();
          this.onUpdate();
        }, this.GtUtils.errorClb);
      }

      destroy(id: number) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return false;
        }
        return this.BillOfLadingInvoicepositionService.delete({ id: id }).then(
          () => this.updateData(),
          this.GtUtils.errorClb,
        );
      }
    },
  ],
};
