import { from, shareReplay, switchMap } from 'rxjs';

import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import {
  ContractSerializer,
  ServicesContractPatch,
  ServicesContractPost,
  ServicesContractQueryParams,
  ServicesContractRecord,
} from '~/features/deals/contracts';
import { ServicesContractsRepository } from '~/features/deals/contracts/service/services/services-contracts.repository';
import { ServicesContract } from '~/shared/api';
import { injectable } from '~/shared/lib/di';

export type ServicesContractsListContainerProps = ContainerProps<ServicesContractQueryParams>;

@injectable()
export class ServicesContractsListViewModel extends EntityListContainerModel<
  ServicesContract,
  ServicesContractQueryParams,
  ServicesContractsRepository,
  ServicesContractsListContainerProps,
  ServicesContractRecord,
  ServicesContractPost,
  ServicesContractPatch
> {
  entityRepoClass = ServicesContractsRepository;

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.repo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  public tableChanged(table: ContractSerializer) {
    this.repo.setDatasources(table);
  }
}
