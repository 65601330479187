import type ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import template from './finance-position-table.tpl.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { InvoicePositionConfigService } from '^/app/finances/components/invoice-position-config/invoice-position-config.service';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

class FinancePositionTableController implements ng.IController {
  financePositions: any[] = [];
  initQueryParams: QueryParams = {};
  isInvalid = false;
  model: keyof FinancesService = 'Finance';
  finance: any;
  usesList: any;
  updateCounterparties?: () => void;
  updateInvoiceFormFields?: () => void;
  updateFinanceAccounts: any;
  changeShowReserve?: (_param: { value: boolean }) => void;
  showReserve: any;

  theadConfig: { tabs: string[]; columns: { title: string; columnName: string }[] } = {
    columns: [],
    tabs: [],
  };
  initFinancePositions: any[] = [];
  hovering = false;
  totalWithoutVat = 0;
  totalVat = 0;
  total = 0;
  useVat = false;
  isFinanceVolumeInKg = false;

  static readonly $inject = [
    '$rootScope',
    '$scope',
    'gettext',
    'GtUtils',
    'FinancesService',
    'ContractsService',
    'InvoicePositionConfigService',
    'AccountsService',
  ];

  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly $scope: ng.IScope,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly GtUtils: GtUtilsService,
    private readonly FinancesService: FinancesService,
    private readonly ContractsService: ContractsService,
    private readonly InvoicePositionConfigService: InvoicePositionConfigService,
    private readonly AccountsService: AccountsService,
  ) {}

  $onInit() {
    if (!this.finance) {
      throw new Error('finance is required');
    }
    if (!this.usesList) {
      throw new Error('usesList is reuired');
    }
    this.theadConfig = this.getTheadConfig();
    this.updatePositionsAmount();
    this.setReadOnly();
    this.$rootScope.$on('update-position-amount', () => {
      this.updatePositionsQuantity();
      this.updatePositionsAmount();
    });
    this.$scope.$watch(
      '$ctrl.financePositions',
      () => {
        this.calculateVatValue();
        this.updateTotals();
      },
      true,
    );
    this.$scope.$watch('$ctrl.useVat', this.clearVat.bind(this));
    this.$scope.$watchCollection('$ctrl.financePositions', this.onPositionsChange.bind(this));
    this.$scope.$watch('$ctrl.finance.is_volume_in_kg', (newVal: boolean) => {
      this.isFinanceVolumeInKg = newVal;
      this.updatePositionsAmount();
    });

    if (this.updateCounterparties) {
      this.updateCounterparties();
    }
  }

  $onChanges(changes: ng.IOnChangesObject) {
    if ('updateFinanceAccounts' in changes) {
      this.financePositions.forEach((position) => this.getFinanceAccounts(position));
    }
  }

  updatePositionsAmount() {
    this.financePositions.forEach((position) => {
      this.calculateAmount(position).catch(errorHandler);
    });
  }

  updatePositionsQuantity() {
    this.financePositions.forEach((position) => {
      this.calculatePositionQuantityTotal(position);
    });
  }

  updateReserveAmount() {
    if (this.changeShowReserve) {
      this.changeShowReserve({ value: true });
    }
    this.financePositions.forEach((position) => {
      this.updatePositionReserveAmount(position);
    });
  }

  applyReserveAmount() {
    this.financePositions.forEach((position) => {
      this.setPositionReserveValues(position);
    });
  }

  getPositionReserveAvailable(position: any) {
    const reserveAmount = position.reserve_amount || 0;
    const reserveAmountUsed = position.reserve_amount_used || 0;
    const reserveAmountUsedInit = position.reserve_amount_used_init || 0;

    return reserveAmount - reserveAmountUsed + reserveAmountUsedInit;
  }

  updatePositionReserveAmount(position: any) {
    const bankAccountList: number[] = [(this.finance.bank_account as number) || 0];
    const invoiceType = this.finance.invoice_type;
    const clientroleFrom = this.finance.clientrole_from;
    const clientroleTo = this.finance.clientrole_to;
    return this.FinancesService.BankAccount.getBankAccountsBalance({
      id_list: bankAccountList,
      invoice_type: invoiceType,
      clientrole_from: clientroleFrom,
      clientrole_to: clientroleTo,
      use: position.use,
      crop: position.crop,
      charge: position.charge || position.reserve_charge,
      contract: position.contract,
      passport: position.passport,
    })
      .$promise.then((bankAccountList: any) => {
        position.reserve_amount = bankAccountList.reduce((sum: any, account: any) => {
          return (
            sum + (account.new_incoming_reserve_amount || account.new_outgoing_reserve_amount || 0)
          );
        }, 0);
        position.reserve_amount_available = this.getPositionReserveAvailable(position);
      })
      .catch(errorHandler);
  }

  setPositionReserveValues(position: any) {
    position.reserve_amount_used = position.amount;
    position.reserve_amount_available = this.getPositionReserveAvailable(position);
    if (position.reserve_amount_available < 0) {
      position.reserve_amount_available = 0;
      position.reserve_amount_used = position.reserve_amount || position.reserve_amount_used_init;
    }
  }

  calculatePositionQuantityTotal(position: any) {
    if (position.use === 'cargo') {
      let quantity;
      position.quantity_total = position.quantity_total || position.quantity;
      if (this.finance.payment_conditions <= 100) {
        quantity =
          Math.round(
            ((position.quantity_total * this.finance.payment_conditions) / 100) * 1000000,
          ) / 1000000;
      }
      position.quantity = quantity ?? position.quantity;
      position.quantity = Number(position.quantity.toFixed(6));
    }
  }

  calculateVatValue() {
    this.financePositions.forEach((position) => {
      if (
        position.vat_option &&
        position.price_per_piece &&
        position.quantity &&
        position.vat_value
      ) {
        this.FinancesService.applyVat({
          value: position.price_per_piece,
          volume: position.quantity,
          vat_value: position.vat_value,
          vat_type: 'vat',
          is_volume_in_kg: this.isFinanceVolumeInKg,
        })
          .then((response) => {
            this.$scope.$apply(() => {
              position.calculated_vat_value = Math.round(response * 1000) / 1000;
            });
          })
          .catch(errorHandler);
        this.FinancesService.applyVat({
          value: position.price_per_piece,
          volume: 1,
          vat_value: position.vat_value,
          vat_type: 'without',
        })
          .then((response) => {
            this.$scope.$apply(() => {
              position.price_per_piece_without_vat = Math.round(response * 1000) / 1000;
            });
          })
          .catch(errorHandler);
      } else {
        position.price_per_piece_without_vat = position.price_per_piece;
        position.calculated_vat_value = 0;
      }
    });
  }

  setHovering(value: boolean) {
    this.hovering = value;
  }

  onPositionsChange() {
    this.initFinancePositions = this.financePositions
      .filter((p) => p.id !== undefined)
      .map((position) => {
        return {
          id: position.id,
          contract: position.contract,
          passport: position.passport,
          use: position.use,
        };
      });
    this.setReadOnly();
  }

  setReadOnly() {
    this.financePositions.forEach((position) => {
      position.read_only = position.read_only || this.positionDisabled(position);
    });
  }

  addPosition() {
    const newPosition = {
      quantity: 0,
      quantity_total: 0,
      price_per_piece: 0,
      vat: 0,
      ...this.initQueryParams,
    };
    this.financePositions.push(newPosition);
  }

  destroy(financePosition: any) {
    if (!confirm(this.gettext('Are you sure that you want delete Position?'))) {
      return;
    }
    if (financePosition.id) {
      this.FinancesService[this.model].delete({
        id: financePosition.id,
      });
    }
    this.financePositions.splice(this.financePositions.indexOf(financePosition), 1);
  }

  clone(financePosition: any) {
    const newPosition = { ...financePosition, _charge_id: financePosition.charge };
    delete newPosition.id;
    delete newPosition.$$hashKey;
    delete newPosition.charge;
    delete newPosition.contractcharge;
    this.financePositions.push(newPosition);
  }

  changedAmount(position: any) {
    this.calculateAmount(position).catch(errorHandler);
  }

  async calculateAmount(position: any) {
    if (position?.read_only) {
      return;
    }
    if (
      position.vat_option &&
      position.price_per_piece &&
      position.quantity &&
      position.vat_value
    ) {
      const response = await this.FinancesService.applyVat({
        value: position.price_per_piece,
        volume: position.quantity,
        vat_value: position.vat_value,
        vat_type: 'with',
        is_volume_in_kg: this.isFinanceVolumeInKg,
      });
      const calculatedPercent = position.disbursement_bl_volume
        ? (position.quantity * 100) / position.disbursement_bl_volume / 100
        : 1;
      const amountCorrectionWithPercentage =
        Math.round((position.amount_correction || 0) * calculatedPercent * 100) / 100;
      this.$scope.$apply(() => {
        position.amount = Math.round((response + amountCorrectionWithPercentage) * 100) / 100;
      });

      if (!position.quantity_total) {
        return;
      }

      const responseTotal = await this.FinancesService.applyVat({
        value: position.price_per_piece,
        volume: position.quantity_total,
        vat_value: position.vat_value,
        vat_type: 'with',
        is_volume_in_kg: this.isFinanceVolumeInKg,
      });

      this.$scope.$apply(() => {
        position.amount_total = responseTotal + amountCorrectionWithPercentage;
      });
    } else {
      const calculatedPercent = position.disbursement_bl_volume
        ? (position.quantity * 100) / position.disbursement_bl_volume / 100
        : 1;
      const volumeCoeff = this.isFinanceVolumeInKg ? 1000 : 1;
      const amount =
        position.quantity *
        volumeCoeff *
        Number((position.price_per_piece / volumeCoeff).toFixed(2));

      const amountCorrectionWithPercentage =
        Math.round((position.amount_correction || 0) * calculatedPercent * 100) / 100;
      position.amount = Math.round((amount + amountCorrectionWithPercentage) * 100) / 100;

      const amountTotal =
        Math.round(
          (position.quantity_total || position.quantity) *
            volumeCoeff *
            Number((position.price_per_piece / volumeCoeff).toFixed(2)) *
            100,
        ) / 100;
      position.amount_total =
        Math.round((amountTotal + amountCorrectionWithPercentage) * 100) / 100;
    }
  }

  updateTotals() {
    let totalQuantityTotalAmount = 0;
    let totalQuantityTotalAmountWithoutVat = 0;
    let totalQuantityTotal = 0;
    let totalVolume = 0;
    let quantityTotalAmountVAT = 0;

    this.totalWithoutVat = 0;
    this.totalVat = 0;
    this.total = 0;

    this.financePositions.forEach((financePosition) => {
      let amount = financePosition.amount;
      let pricePerPieceWithoutVat = financePosition.price_per_piece_without_vat;

      if (
        (this.finance.invoice_type === 'outgoing' &&
          ['costs', 'expenses'].includes(financePosition.use)) ||
        (this.finance.invoice_type === 'incoming' && ['gains'].includes(financePosition.use))
      ) {
        amount = -amount;
        pricePerPieceWithoutVat = -pricePerPieceWithoutVat;
      }

      this.total += amount;
      this.totalWithoutVat += pricePerPieceWithoutVat * financePosition.quantity;
      this.totalVat += financePosition.calculated_vat_value || 0;
      quantityTotalAmountVAT +=
        (financePosition.calculated_vat_value / financePosition.quantity) *
        (financePosition.quantity_total || financePosition.quantity);

      if (financePosition.use === 'cargo') {
        totalQuantityTotal += financePosition.quantity_total || financePosition.quantity;
        totalQuantityTotalAmount += financePosition.amount_total || financePosition.amount;
        totalQuantityTotalAmountWithoutVat +=
          pricePerPieceWithoutVat * (financePosition.quantity_total || financePosition.quantity);
        totalVolume += financePosition.quantity;
      }
    });

    this.total = Math.round(this.total * 100) / 100;
    this.totalWithoutVat = Math.round(this.totalWithoutVat * 100) / 100;

    if (this.total) {
      this.$scope.$emit('finance-position-table__change-full-amount', {
        newFullAmount: this.total,
        fullAmountWithoutVat: this.totalWithoutVat,
        totalVat: this.totalVat,
        totalQuantityTotalAmount: Math.round(totalQuantityTotalAmount * 100) / 100,
        quantityTotalAmountWithoutVat: Math.round(totalQuantityTotalAmountWithoutVat * 100) / 100,
        quantityTotalAmountVAT: Math.round(quantityTotalAmountVAT * 100) / 100,
        totalQuantityTotal: Math.round(totalQuantityTotal * 100) / 100,
        totalVolume: totalVolume,
      });
    }
  }

  clearVat() {
    if (!this.useVat) {
      this.totalVat = 0;
      this.financePositions.forEach((financePosition) => {
        financePosition.vat = 0;
      });
    }
  }

  clearContractCharge(position: any) {
    position._charge_id = position.charge;
  }

  showContractDetails(position: any) {
    return this.ContractsService.Contract.predictionsDetails({
      id: position.contract,
      full_title: true,
    }).$promise.then((data: any) => (position.contractDetails = data));
  }

  checkFieldRequired(position: any, field: any) {
    return this.FinancesService.getRequiredFields(position).includes(field);
  }

  positionDisabled(position: any) {
    if (!position.id || !position.contract) {
      return false;
    }

    return (
      !this.$rootScope.user.settings.INVOICE_POSITION_DISABLE_FOR_EXECUTED_CONTRACTS &&
      ['executed', 'cancelled'].includes(position.contract_status)
    );
  }

  showDeleteCtrChargeWarning(position: any) {
    const initPosition = this.initFinancePositions.find((p) => p.id === position.id);
    if (!initPosition) {
      return;
    }

    return (
      ['costs', 'gains'].includes(initPosition.use) && initPosition.contract !== position.contract
    );
  }

  showDeletePassportChargeWarning(position: any) {
    const initPosition = this.initFinancePositions.find((p) => p.id === position.id);
    if (!initPosition) {
      return;
    }

    return (
      ['costs', 'gains'].includes(initPosition.use) && initPosition.passport !== position.passport
    );
  }

  useChangeBlocked(position: any) {
    const initPosition = this.initFinancePositions.find((p) => p.id === position.id);
    if (!initPosition) {
      return;
    }
    return ['costs', 'gains'].includes(initPosition.use);
  }

  getFinanceAccounts(position: any) {
    if (!position.use) {
      return;
    }
    position.currency = this.finance.currency;
    position.invoice_type = this.finance.invoice_type;
    position.charge = position._charge_id;
    if (position.contractcharge) {
      return this.ContractsService.ContractCharge.get({ id: position.contractcharge })
        .$promise.then((data: any) => (position.charge = data.charge))
        .then(() => {
          this.InvoicePositionConfigService.getFinanceAccounts(position).then(function (data: any) {
            position.debit_account = position.debit_account || data.debit_account;
            position.credit_account = position.credit_account || data.credit_account;
          });
        });
    } else {
      this.InvoicePositionConfigService.getFinanceAccounts(position).then(function (data: any) {
        position.debit_account = position.debit_account || data.debit_account;
        position.credit_account = position.credit_account || data.credit_account;
      });
    }
  }

  hasFinblockPermission(position: any) {
    if (!['costs', 'gains'].includes(position.use)) {
      return true;
    }
    return !this.finance.id || this.AccountsService.hasPerm('configurate_finblock_entries');
  }

  getTheadConfig() {
    const config: { tabs: string[]; columns: { title: string; columnName: string }[] } = {
      tabs: [],
      columns: [],
    };

    config.columns = [
      {
        title: this.gettext('Position'),
        columnName: 'note',
      },
      {
        title: this.gettext('Allocation'),
        columnName: 'contract',
      },
      {
        title: this.gettext('Quantity'),
        columnName: 'quantity',
      },
      {
        title: this.gettext('Price'),
        columnName: 'price',
      },
      {
        title: this.gettext('Amount'),
        columnName: 'amount',
      },
    ];
    return config;
  }
}

export const financePositionTable: ng.IComponentOptions = {
  bindings: {
    financePositions: '=?',
    initQueryParams: '<?',
    isInvalid: '<?',
    model: '<',
    finance: '<',
    usesList: '<',
    updateCounterparties: '&?',
    updateInvoiceFormFields: '&?',
    updateFinanceAccounts: '<?',
    changeShowReserve: '&?',
    showReserve: '<?',
  },
  template: template,
  controller: FinancePositionTableController,
};
