import React from 'react';
import { Helmet, HelmetData } from 'react-helmet-async';

import { useTheme } from '~/core/theme/hooks';
import { Outlet, TanStackRouterDevtools } from '~/shared/lib/router';
import { useObservableEagerState } from '~/shared/lib/state';

const helmetData = new HelmetData({});

export const RootLayout: React.FC = () => {
  const { themeStore } = useTheme();
  const title = useObservableEagerState(themeStore.title$);

  return (
    <main className="prose bg-noise h-screen antialiased">
      <Helmet helmetData={helmetData}>
        <title>{title}</title>
      </Helmet>
      <Outlet />
      <TanStackRouterDevtools initialIsOpen={false} />
    </main>
  );
};
