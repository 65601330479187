import type ng from 'angular';

import template from './analytics-container.html?raw';
import type { FinancesAnalyticsService } from '../analytics.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';

export const AnalyticsContainer = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gettext',
    'FinancesAnalyticsService',
    'gtFilterService',
    class {
      FinancesAnalyticsService: FinancesAnalyticsService;
      amountsByMonthChartConfig: any;
      filterLevel = 'finances-analytics-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      incomingPaymentDaysChartConfig: any;
      incomingPaymentDaysTableOptions: any;
      outgoingPaymentDaysChartConfig: any;
      outgoingPaymentDaysTableOptions: any;
      queryParams: QueryParams & { use: string } = { use: 'cargo' };
      topIncomingFinancesTableOptions: any;
      topIncomingPaymentsTableOptions: any;
      topOutgoingFinancesTableOptions: any;
      topOutgoingPaymentsTableOptions: any;
      totalFinances: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        FinancesAnalyticsService: FinancesAnalyticsService,
        gtFilterService: GtFilterService,
      ) {
        this.gettext = gettext;
        this.FinancesAnalyticsService = FinancesAnalyticsService;
        this.gtFilterService = gtFilterService;

        this.amountsByMonthChartConfig = undefined;

        this.topOutgoingFinancesTableOptions = this.getTopOutgoingFinancesTableOptions();
        this.topIncomingFinancesTableOptions = this.getTopIncomingFinancesTableOptions();
        this.topOutgoingPaymentsTableOptions = this.getTopOutgoingPaymentsTableOptions();
        this.topIncomingPaymentsTableOptions = this.getTopIncomingPaymentsTableOptions();
        this.totalFinances = {};
        this.incomingPaymentDaysChartConfig = undefined;
        this.incomingPaymentDaysTableOptions = this.getIncomingPaymentDaysTableOptions();
        this.outgoingPaymentDaysChartConfig = undefined;
        this.outgoingPaymentDaysTableOptions = this.getOutgoingPaymentDaysTableOptions();
      }

      $onInit() {
        this.gtFilterService.subscribe(
          this.filterLevel,
          (params: any) => {
            this.queryParams = params;
            this.updateAnalyticsData();
          },
          this.queryParams,
        );
        this.updateAnalyticsData();
      }

      updateAnalyticsData() {
        this.updateAmountsByMonthChartConfig();
        this.updateTotalFinances();
        this.updateIncomingPaymentDaysChartConfig();
        this.updateOutgoingPaymentDaysChartConfig();
      }

      updateAmountsByMonthChartConfig() {
        this.FinancesAnalyticsService.getAmountsByMonthChartConfig(this.queryParams).then(
          (data: any) => {
            this.amountsByMonthChartConfig = data;
          },
        );
      }

      getTopOutgoingFinancesTableOptions() {
        return {
          tableName: 'TopOutgoingFinances',
          columnDefs: [
            {
              columnName: 'number',
              title: this.gettext('invoice'),
            },
            {
              columnName: 'amount_USD',
              cellTemplate: '{[{ item.amount_USD | currency  }]}',
              title: this.gettext('amount'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.FinancesAnalyticsService.getTopOutgoingFinancesData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      getTopIncomingFinancesTableOptions() {
        return {
          tableName: 'TopOutgoingFinances',
          columnDefs: [
            {
              columnName: 'number',
              title: this.gettext('invoice'),
            },
            {
              columnName: 'amount_USD',
              cellTemplate: '{[{ item.amount_USD | currency  }]}',
              title: this.gettext('amount'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.FinancesAnalyticsService.getTopIncomingFinancesData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      getTopOutgoingPaymentsTableOptions() {
        return {
          tableName: 'TopOutgoingFinances',
          columnDefs: [
            {
              columnName: 'finance__number',
              title: this.gettext('payment'),
            },
            {
              columnName: 'amount_USD',
              cellTemplate: '{[{ item.amount_USD | currency  }]}',
              title: this.gettext('amount'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.FinancesAnalyticsService.getTopOutgoingPaymentsData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      getTopIncomingPaymentsTableOptions() {
        return {
          tableName: 'TopOutgoingFinances',
          columnDefs: [
            {
              columnName: 'finance__number',
              title: this.gettext('payment'),
            },
            {
              columnName: 'amount_USD',
              cellTemplate: '{[{ item.amount_USD | currency  }]}',
              title: this.gettext('amount'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.FinancesAnalyticsService.getTopIncomingPaymentsData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      updateTotalFinances() {
        this.FinancesAnalyticsService.getTotalFinances().then(
          (data: any) => (this.totalFinances = data),
        );
      }

      updateIncomingPaymentDaysChartConfig() {
        this.FinancesAnalyticsService.getIncomingPaymentDaysChartConfig().then((data: any) => {
          this.incomingPaymentDaysChartConfig = data;
        });
      }

      getIncomingPaymentDaysTableOptions() {
        return {
          tableName: 'IncomingPaymentDays',
          columnDefs: [
            {
              columnName: 'group_date',
              cellTemplate: '{[{ item.group_date | translate }]}',
              title: this.gettext('days'),
            },
            {
              columnName: 'group_date_sum',
              title: this.gettext('count'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.FinancesAnalyticsService.getIncomingPaymentDaysData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }

      updateOutgoingPaymentDaysChartConfig() {
        this.FinancesAnalyticsService.getOutgoingPaymentDaysChartConfig().then((data: any) => {
          this.outgoingPaymentDaysChartConfig = data;
        });
      }

      getOutgoingPaymentDaysTableOptions() {
        return {
          tableName: 'OutgoingPaymentDays',
          columnDefs: [
            {
              columnName: 'group_date',
              cellTemplate: '{[{ item.group_date | translate }]}',
              title: this.gettext('days'),
            },
            {
              columnName: 'group_date_sum',
              title: this.gettext('count'),
            },
          ],
          rowData: [],
          onInit: (api: any) =>
            this.FinancesAnalyticsService.getOutgoingPaymentDaysData().then((data: any) =>
              api.setRowData(data),
            ),
        };
      }
    },
  ],
};
