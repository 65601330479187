import React from 'react';

import { cn } from '~/shared/lib/utils';
import { Link } from '~/shared/ui/kit/link';

import type { DataTypeProps } from '../../lib';

export const UrlRead: React.FC<DataTypeProps<'url'>> = ({ disabled, value }) => {
  return value ? (
    <Link href={`https://${value.toString()}`} disabled={disabled}>
      <span className={cn('text-text-main-primary text-xs', disabled && 'cursor-not-allowed')}>
        {value}
      </span>
    </Link>
  ) : null;
};
