import { v } from '~/shared/lib/forms';

export const emailSchema = (msg = 'Value must be a valid email') =>
  v.pipe(v.string(), v.email(msg));

export const requireSchema = (msg = 'Value is required') =>
  v.custom((input) => {
    if (typeof input === 'string') {
      return v.safeParse(v.pipe(v.string(), v.minLength(1)), input).success;
    }
    if (typeof input === 'number') {
      return v.safeParse(v.pipe(v.number(), v.minValue(1)), input).success;
    }
    if (Array.isArray(input)) {
      return v.safeParse(v.pipe(v.array(v.unknown()), v.minLength(1)), input).success;
    }
    return input !== null && input !== undefined;
  }, msg);
