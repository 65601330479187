import { locationCountriesList, locationCountriesPredictionsRetrieve } from '~/shared/api';
import { EntityRepository, type Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Country, CountryQueryParams } from '../lib';

export class CountryRepository extends EntityRepository<Country, CountryQueryParams> {
  readonly entityName = 'location.Country';

  getFn = (_id: number): Promise<Country> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await locationCountriesPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  createFn = (_entity: Country): Promise<Country> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Country>): Promise<Country> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: CountryQueryParams): Promise<Paginated<Country>> => {
    const { data } = await locationCountriesList({ query });
    return { records: data.results, count: data.count };
  };
}
