import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './disbursementbl-consignment.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const DisbursementBLConsignment = {
  template,
  bindings: {
    initQueryParams: '<?',
    disbursementblConsignments: '=?',
    disbursementblId: '<?',
    disbursementblDate: '<?',
    disbursementbl: '<?',
  },
  controller: [
    '$scope',
    '$rootScope',
    'FinancesService',
    'gettext',
    'GtUtils',
    'ContractsService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      ContractsService: ContractsService;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      contractId?: number;
      disbursementbl: any;
      disbursementblConsignments: any;
      disbursementblDate: any;
      elementSelected: any;
      formattedDate: any;
      gettext: ng.gettext.gettextFunction;
      initQueryParams: QueryParams = {};
      isValidating: any;
      newDisbursementBLConsignment: any;
      originalValues: any;
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        FinancesService: FinancesService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        ContractsService: ContractsService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.FinancesService = FinancesService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.ContractsService = ContractsService;
        this.queryParams = {};
        this.disbursementblConsignments = [];
        this.newDisbursementBLConsignment = {};
        this.originalValues = {};
        this.isValidating = false;
      }

      $onInit() {
        this.disbursementblConsignments = this.disbursementblConsignments || [];
        this.queryParams = { ...this.initQueryParams };
        this.getInitialNewDisbursementBLConsignment();
        const date = new Date(this.disbursementblDate);
        this.contractId =
          this.disbursementbl.dbl_type === 'sale'
            ? this.disbursementbl.sale_contract
            : this.disbursementbl.purchase_contract;
        this.formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

        this.$scope.$on('dbl-date-changed', (oldValue: any, newValue: any) => {
          if (oldValue !== newValue) {
            this.formattedDate = `${newValue.getFullYear()}-${newValue.getMonth() + 1}-${newValue.getDate()}`;
          }
        });

        this.elementSelected = (element: any) => {
          if (element?.consignment) {
            return this.ContractsService.Consignments.get({
              id: element.consignment,
            }).$promise.then((response: any) => {
              element.consignment_number = response.number;
              element.volume_connected = response.free_volume;
            });
          }
        };

        this.$scope.$watch(
          '$ctrl.disbursementblConsignments',
          (newValues: any, oldValues: any) => {
            if (!this.isValidating) {
              newValues.forEach((newValue: any, index: number) => {
                if (newValue !== oldValues[index]) {
                  this.validateConsignment(newValue, oldValues[index]);
                }
              });
            }
          },
          true,
        );
      }

      addDisbursementBLConsignment() {
        if (
          !this.newDisbursementBLConsignment.consignment ||
          !this.newDisbursementBLConsignment.volume_connected
        ) {
          notify(this.gettext('Consignment and volume are required.'));
          return;
        }

        for (const consignment of this.disbursementblConsignments) {
          if (consignment.consignment === this.newDisbursementBLConsignment.consignment) {
            notify(this.gettext('This consignment already connected.'));
            return;
          }
        }

        this.FinancesService.DisbursementBLConsignment.checkVolumeAvailability({
          consignment: this.newDisbursementBLConsignment.consignment,
          volume: this.newDisbursementBLConsignment.volume_connected,
          id: this.newDisbursementBLConsignment.id,
        })
          .$promise.then((response: any) => {
            if (!response.sufficient_volume) {
              notify(this.gettext(response.data || 'Insufficient free volume'));
              return;
            }
            this.disbursementblConsignments.unshift(this.newDisbursementBLConsignment);
            this.getInitialNewDisbursementBLConsignment();
          })
          .catch((error: any) => {
            this.GtUtils.errorClb(error);
          });
      }

      getInitialNewDisbursementBLConsignment() {
        this.newDisbursementBLConsignment = {
          id: null,
          consignment: null,
          consignment_number: null,
          volume_connected: null,
        };
      }

      removeElement(element: any) {
        const index = this.disbursementblConsignments.indexOf(element);
        if (index !== -1) {
          this.disbursementblConsignments.splice(index, 1);
        }
      }

      validateConsignment(consignment: any, originalValues: any) {
        if (!consignment.consignment || !consignment.volume_connected) {
          notify(this.gettext('Consignment and volume are required.'));
          this.resetConsignment(consignment, originalValues);
          return;
        }

        for (const existingConsignment of this.disbursementblConsignments) {
          if (
            existingConsignment.consignment === consignment.consignment &&
            existingConsignment !== consignment
          ) {
            notify(this.gettext('This consignment already connected.'));
            this.resetConsignment(consignment, originalValues);
            return;
          }
        }

        this.FinancesService.DisbursementBLConsignment.checkVolumeAvailability({
          consignment: consignment.consignment,
          volume: consignment.volume_connected,
          id: consignment.id,
        })
          .$promise.then((response: any) => {
            if (!response.sufficient_volume) {
              notify(this.gettext(response.data || 'Insufficient free volume'));
              this.resetConsignment(consignment, originalValues);
            }
          })
          .catch((error: any) => {
            this.GtUtils.errorClb(error);
            this.resetConsignment(consignment, originalValues);
          });
      }

      resetConsignment(consignment: any, originalValues: any) {
        this.isValidating = true;
        consignment.consignment = originalValues.consignment;
        consignment.volume_connected = originalValues.volume_connected;
        this.isValidating = false;
      }
    },
  ],
};
