import type ng from 'angular';

import template from './shipment-schedule-list-table-view.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const ShipmentScheduleListTableView = {
  bindings: {
    data: '<?',
    dateRange: '<?',
    filterLevel: '<?',
    openModal: '&?',
    saveDays: '&?',
  },
  template,
  controller: [
    'gettext',
    'GtUtils',
    class {
      GtUtils: GtUtilsService;
      data: any;
      dateRange: any;
      gettext: ng.gettext.gettextFunction;
      theadConfig: any;
      constructor(gettext: ng.gettext.gettextFunction, GtUtils: GtUtilsService) {
        this.gettext = gettext;
        this.GtUtils = GtUtils;

        this.data = [];
        this.dateRange = [];
      }

      $onInit() {
        this.theadConfig = this.getTheadConfig();
      }

      $onChanges(changes: any) {
        this.data = this.data || [];
        this.dateRange = this.dateRange || [];

        if (changes.dateRange && this.theadConfig) {
          this.theadConfig = this.getTheadConfig();
        }
      }

      getTheadConfig() {
        const config: any = { tabs: [], columns: [] };

        config.columns = [
          // plan
          {
            columnName: 'plan',
            title: this.gettext('Plan'),
          },
          {
            columnName: 'planExecutionRange',
            title: this.gettext('Plan execution range'),
          },
          {
            columnName: 'properties',
            title: this.gettext('Properties'),
          },
          // positions
          {
            columnName: 'departure',
            title: this.gettext('Station Departure'),
          },
          {
            columnName: 'warehouse',
            title: this.gettext('Warehouse'),
          },
          {
            columnName: 'rollingStock',
            title: this.gettext('Rolling stock'),
          },
          {
            columnName: 'cargoShipper',
            title: this.gettext('Cargo shipper'),
          },
          {
            columnName: 'deliverer',
            title: this.gettext('Deliverer'),
          },
          {
            columnName: 'exporter',
            title: this.gettext('Exporter'),
          },
          // days
          {
            columnName: 'total',
            title: this.gettext('Total'),
          },
        ];

        this.dateRange.forEach((day: any) => {
          config.columns.push({ columnName: day.title, title: day.title });
        });

        return config;
      }
    },
  ],
};
