import React from 'react';

import { useAuth } from '~/core/auth/hooks';
import { GlobalSearchButton } from '~/core/global-search';
import { Logo } from '~/core/theme/components/logo/logo';
import { UserAvatar } from '~/core/theme/components/user-avatar';
import { useSidebar } from '~/core/theme/hooks';
import { cn } from '~/shared/lib/utils';

import { Menu } from '../menu';

export const Sidebar: React.FC = () => {
  const { sidebarOpen } = useSidebar();
  const { currentUser } = useAuth();

  if (!sidebarOpen) {
    return null;
  }

  return (
    <aside>
      <section
        className={`group top-0 left-0 z-20 ${
          sidebarOpen ? 'translate-x-0' : 'hidden'
        } w-[220px] transition-transform duration-300 ease-in-out`}
      >
        <div className="relative flex h-screen flex-col pb-3">
          <Logo />
          <div className="border-stroke-main-medium w-full border-t border-b">
            <GlobalSearchButton />
          </div>
          <Menu />
          <div
            className={cn(
              'mt-2 shrink-0 flex-col px-2 pt-2',
              !sidebarOpen && 'border-stroke-main-medium border-t',
            )}
          >
            <UserAvatar
              name={currentUser.first_name ?? currentUser.username}
              src={currentUser.avatar}
            />
          </div>
        </div>
      </section>
    </aside>
  );
};
