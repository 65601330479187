import type ng from 'angular';

import template from './intermodal-transports-page.html?raw';
import { type QueryParams } from '../../types';

import { type PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import { type GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

class IntermodalTransportsPageController implements ng.IController {
  filterLevel?: string;
  queryParams?: QueryParams;

  static readonly $inject = ['gettext', 'gtFilterService', 'LogisticsService', 'PageService'];
  constructor(
    private readonly gettext: ng.gettext.gettextFunction,
    private gtFilterService: GtFilterService,
    private LogisticsService: LogisticsService,
    private PageService: PageService,
  ) {}

  $onInit() {
    this.filterLevel = this.filterLevel ?? 'intermodal-transports-page-view';
    this.queryParams = this.queryParams ?? {};
    this.PageService.setConfig(this.getPageConfig());
  }

  applyFilter() {
    if (this.queryParams && !this.queryParams.without_buyer) {
      delete this.queryParams.without_buyer;
    }
    if (this.queryParams) {
      return this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
    }
  }

  resetFilter() {
    this.queryParams = {};
    this.applyFilter();
  }

  getPageConfig() {
    return {
      class: 'page-header-main-logistics-tab',
      buttons: [
        new this.PageService.buttons.Filters(),
        new this.PageService.buttons.Print(),
        new this.PageService.buttons.Refresh(this.filterLevel),
      ],
      pages: this.LogisticsService.getPagesConfig(),

      filters: {
        filterLevel: this.filterLevel,
        clicked: false,
        invert_filters: true,
        multiSelects: [
          {
            argument: 'id_list',
            placeholder: this.gettext('vehicles'),
            resource: 'logistics.Logistic',
            related_fk: 'id',
            related_model: 'logistics.Logistic',
          },
          {
            argument: 'exporter_list',
            placeholder: this.gettext('exporters'),
            resource: 'clients.exporter',
            related_fk: 'exporter_id',
            related_model: 'logistics.Logistic',
          },
          {
            argument: 'owner_list',
            placeholder: this.gettext('owners'),
            resource: 'clients.owner',
          },
          {
            argument: 'receiver_list',
            placeholder: this.gettext('receivers'),
            resource: 'clients.client',
            related_fk: 'receiver_id',
            related_model: 'logistics.Logistic',
          },
          {
            argument: 'producer_list',
            placeholder: this.gettext('producers'),
            resource: 'clients.client',
            related_fk: 'producer_id',
            related_model: 'logistics.Logistic',
          },
          {
            argument: 'supplier_contract_supplier_list',
            placeholder: this.gettext('suppliers'),
            resource: 'clients.supplier',
            related_fk: 'supplier_contract__supplier_id',
            related_model: 'logistics.Logistic',
          },
          {
            argument: 'buyer_contract_buyer_list',
            placeholder: this.gettext('buyers'),
            resource: 'clients.buyer',
            related_fk: 'buyer_contract__buyer_id',
            related_model: 'logistics.Logistic',
          },
          {
            argument: 'companygroups_list',
            placeholder: this.gettext('company groups'),
            resource: 'clients.companygroup',
            related_fk: 'logistics_companygroups_list',
            related_model: 'logistics.Logistic',
          },
          {
            argument: 'consignment_list',
            placeholder: this.gettext('Consignments'),
            resource: 'contracts.consignment',
            related_fk: 'logisticconsignment__consignment',
            related_model: 'logistics.Logistic',
          },
        ],
        nestedSelects: [this.gtFilterService.getBoolFilter('has_bl', this.gettext('Has BL'))],
      },
    };
  }
}

export const intermodalTransportsPage: ng.IComponentOptions = {
  bindings: {
    queryParams: '<?',
    filterLevel: '<?',
  },
  template: template,
  controller: IntermodalTransportsPageController,
};
