import {
  ConsignmentPatch,
  ConsignmentPost,
  ConsignmentQueryParams,
  ConsignmentRecord,
} from '~/features/execution/consignments';
import {
  Consignment,
  contractsConsignmentsCreate,
  contractsConsignmentsDestroy,
  contractsConsignmentsPartialUpdate,
  contractsConsignmentsRetrieve,
  contractsContractConsignmentsListInfoList,
  contractsContractConsignmentsTotalsRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';
import { EntityOption } from '~/shared/ui/data-types';

@singleton()
export class ConsignmentsRepository extends EntityRepository<
  Consignment,
  ConsignmentQueryParams,
  ConsignmentRecord,
  ConsignmentPost,
  ConsignmentPatch
> {
  readonly entityName = 'contracts.Consignment';

  getFn = async (id: number) => {
    const { data } = await contractsConsignmentsRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: ConsignmentQueryParams) => {
    const { data } = await contractsContractConsignmentsListInfoList({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  createFn = async (dto: ConsignmentPost) => {
    const res = await contractsConsignmentsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  updateFn = async (entity: ConsignmentPatch) => {
    const { data } = await contractsConsignmentsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  deleteFn = async (id: number) => {
    await contractsConsignmentsDestroy({ path: { id } });
  };

  getTotals = async (query: ConsignmentQueryParams) => {
    const { data } = await contractsContractConsignmentsTotalsRetrieve({ query });
    return data;
  };
}
