import ng from 'angular';

import { requestDetailsItemContainer } from './item-container/request-details-item-container.component';
import { requestDetailsPage } from './item-page/request-details-page.component';
import { requestDetailsItemView } from './item-view/request-details-item-view.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const requestConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('gt.page.request', {
      url: '/request/:id',
      component: 'requestDetailsPage',
      resolve: {
        filterLevel: () => {
          return 'request-side-list';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('request-side-list');
          },
        ],
      },
      data: {
        pageTitle: gettext('Request'),
        permissions: {
          only: 'view_request',
        },
      },
    });
  },
];

export const requestDetails = ng
  .module('deals.requests.details', [])
  .component('requestDetailsPage', requestDetailsPage)
  .component('requestDetailsItemContainer', requestDetailsItemContainer)
  .component('requestDetailsItemView', requestDetailsItemView)
  .config(requestConfig).name;
