import type ng from 'angular';

import template from './cashflow-balance-report-tree.html?raw';

import type { GtRootScopeService } from '^/app/core/types';

export const CashflowBalanceReportTree = {
  bindings: {
    filterLevel: '<',
    balanceData: '<',
    turnoverData: '<',
    config: '<',
    indicatorView: '<',
    chartsConfigs: '<',
    balanceLevelsCount: '<',
    turnoverLevelsCount: '<',
  },
  template,
  controller: [
    'gettext',
    '$rootScope',
    '$scope',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      balanceLevelsCount = 0;
      config: any;
      currencyIcons: any;
      defaultCurrency: any;
      defaultCurrencyIcon: any;
      gettext: ng.gettext.gettextFunction;
      indicatorView: any;
      turnoverLevelsCount = 0;
      verticalGroups: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
      ) {
        this.gettext = gettext;
        this.$rootScope = $rootScope;
        this.$scope = $scope;

        this.defaultCurrency = undefined;
        this.currencyIcons = undefined;
        this.verticalGroups = [];
      }
      $onInit() {
        this.currencyIcons = {
          EUR: 'fa-euro-sign',
          USD: 'fa-dollar-sign',
          UAH: 'fa-hryvnia-sign',
          RUB: 'fa-ruble-sign',
          GBP: 'fa-sterling-sign',
          KZT: 'fa-tenge-sign',
        };
        this.defaultCurrency = this.$rootScope.user.settings.DEFAULT_CURRENCY;
        this.defaultCurrencyIcon = this.currencyIcons[this.defaultCurrency];
        this.updateVerticalGroups();
        this.$scope.$watch(
          () => this.balanceLevelsCount,
          () => this.updateVerticalGroups(),
        );
        this.$scope.$watch(
          () => this.turnoverLevelsCount,
          () => this.updateVerticalGroups(),
        );
      }
      updateVerticalGroups() {
        this.verticalGroups = [
          {
            name: 'start_balance',
            title: this.gettext('Start balance'),
            table: 'balanceData',
            indicator: 'start_balance',
            indicatorLocal: 'start_balance_local',
            icon: 'fa-flag-o',
            class: 'alert-success',
            showDetails: false,
            levelsCount: this.balanceLevelsCount,
            ngClass: {},
          },
          {
            name: 'incoming',
            table: 'turnoverData',
            title: this.gettext('Incoming'),
            indicator: 'incoming',
            indicatorLocal: 'incoming_local',
            icon: 'fa-plus',
            class: 'alert-success',
            showDetails: true,
            ngClass: {
              'alert-success': true,
              'positive-number': true,
            },
            levelsCount: this.turnoverLevelsCount,
          },
          {
            name: 'outgoing',
            table: 'turnoverData',
            title: this.gettext('Outgoing'),
            indicator: 'outgoing',
            indicatorLocal: 'outgoing_local',
            icon: 'fa-minus',
            class: 'alert-danger',
            showDetails: true,
            ngClass: {
              'alert-danger': true,
              'negative-number': true,
            },
            levelsCount: this.turnoverLevelsCount,
          },
          {
            name: 'total',
            table: 'turnoverData',
            title: this.gettext('Total'),
            indicator: 'total',
            indicatorLocal: 'total_local',
            class: 'alert-success',
            showDetails: false,
            ngClass: {},
            levelsCount: this.turnoverLevelsCount,
          },
          {
            name: 'end_balance',
            table: 'balanceData',
            title: this.gettext('End balance'),
            indicator: 'end_balance',
            indicatorLocal: 'end_balance_local',
            icon: 'fa-flag',
            class: 'alert-success',
            showDetails: false,
            levelsCount: this.balanceLevelsCount,
            ngClass: {},
          },
        ];
      }

      updateVisibility(table: any, row: any, expand: any) {
        row.expand = expand;
        if (!expand) {
          (this as any)[table]
            .filter((v: any) => v.parentIndex === row.index)
            .forEach((x: any) => this.updateVisibility(table, x, expand));
        }
      }

      rowHasData(row: any, group: any): any {
        return (
          row &&
          ((row.level === 'detailsGrouped' && this.rowHasData(row.parent, group)) ||
            this.config.columns
              .filter((v: any) => v.group === 'months')
              .some(
                (v: any) =>
                  row?.indicators &&
                  Boolean(row.indicators[v.predicate]) &&
                  (Boolean(row.indicators[v.predicate][group.indicator]) ||
                    Boolean(row.indicators[v.predicate][group.indicatorLocal])),
              ))
        );
      }

      rowIsVisible(row: any, verticalGroup: any) {
        if (this.indicatorView === 'vertical') {
          return (
            verticalGroup.expand &&
            Boolean(row.parent.expand) &&
            row.level !== 0 &&
            this.rowHasData(row, verticalGroup)
          );
        }
      }

      showArrows(row: any, verticalGroup: any) {
        if (verticalGroup.levelsCount != row.level) {
          return true;
        }
        if (!verticalGroup.showDetails) {
          return false;
        }

        const columns = this.config.columns
          .filter((c: any) => c.group === 'months')
          .map((c: any) => c.predicate || c.group);
        return (this as any)[verticalGroup.table]
          .filter((v: any) => v.parentIndex === row.index)
          .reduce(
            (flat: any, child: any) =>
              flat.concat(columns.reduce((r: any, c: any) => r.concat(child[c]), [])),
            [],
          )
          .some(
            (child: any) =>
              child && (child[verticalGroup.indicatorLocal] || child[verticalGroup.indicator]),
          );
      }
    },
  ],
};
