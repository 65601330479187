import React from 'react';

import { cn } from '~/shared/lib/utils';
import { type DataType, useDataTypeComponent } from '~/shared/ui/data-types';
import { CloseIcon, ThreeDotsIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import type { FilterItemProps } from '../../types';
import { DynamicFilterControl } from '../filters-selector';
const modeStylesMap: Record<string, string> = {
  disabled: 'border-stroke-main-stronger bg-background-main-quarternary',
  default: 'border-stroke-main-medium bg-background-main-primary',
  changed: 'border-stroke-additional-info bg-background-additional-info-light',
};

const textStylesMap: Record<string, string> = {
  disabled: 'text-text-additional-warning',
  default: 'text-text-main-tertiary',
  changed: 'text-text-additional-info',
};

export const FilterItem = <T extends DataType>({
  filter,
  option,
  mode,
  onDelete,
  onChange,
}: FilterItemProps<T>) => {
  const DataTypeComponent = useDataTypeComponent(
    option.dataType,
    mode == 'disabled' ? 'read' : 'write',
    {
      typeExtra: option.typeExtra,
    },
  );

  const [style, textStyle] = React.useMemo(
    () => [mode && modeStylesMap[mode], mode ? textStylesMap[mode] : 'text-text-main-secondary'],
    [mode],
  );

  if (mode === 'disabled') {
    return (
      <div
        className={cn('flex h-6 cursor-default items-center gap-1 rounded-sm border px-1', style)}
      >
        <p className="text-xs font-medium capitalize">{option.title}</p>
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex h-6 w-max items-center gap-1 rounded-sm border px-1',
        mode && modeStylesMap[mode],
      )}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex cursor-pointer items-center gap-1 transition duration-100 ease-in-out hover:opacity-60">
            <p className={cn('text-xs font-medium capitalize', textStyle)}>{option.title}</p>
            <p className={cn('text-xs font-normal', textStyle)}>{filter.operator}</p>
            <span className="pointer-events-none text-xs">
              <DataTypeComponent value={filter.value} />
            </span>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[200px]">
          <div className="border-stroke-main-light flex items-center justify-between border-b px-2 py-1">
            <p className="text-xs font-medium">{option.title}</p>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger displayChevron={false}>
                <ThreeDotsIcon
                  size={14}
                  className="text-text-main-secondary"
                  data-testid="inactive-filter-add-actions"
                />
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent sideOffset={10}>
                <button
                  className="text-text-additional-danger px-1 text-xs"
                  onClick={() => onDelete?.(filter)}
                >
                  Delete Filter
                </button>
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          </div>
          <div className="flex w-full flex-col gap-0.5 p-1">
            <DynamicFilterControl
              option={option}
              filter={filter}
              onChange={onChange}
              onDelete={onDelete}
            />
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
      <button onClick={() => onDelete?.(filter)}>
        <CloseIcon
          size={14}
          className={cn(
            'text-xs font-medium capitalize transition duration-200 ease-in-out hover:opacity-50',
            textStyle,
          )}
          data-testid="delete-filter"
        />
      </button>
    </div>
  );
};
