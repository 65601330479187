import { notify } from '~/shared/lib/notify';

import type { GtUtilsService } from '../legacy/gt-utils/gt-utils.srv';

export class CoordinatesService {
  GtUtils: GtUtilsService;
  decimalCheck: any;
  dmsCheck: any;
  googleMapCheck: any;
  constructor(GtUtils: GtUtilsService) {
    this.GtUtils = GtUtils;
    // eslint-disable-next-line sonarjs/slow-regex
    this.decimalCheck = /(-?\d+\.\d+)\s*,\s*(-?\d+\.\d+)/;
    // eslint-disable-next-line sonarjs/slow-regex,sonarjs/regex-complexity
    this.dmsCheck = /(\d+)°(\d+')?(\d+\.\d+")?([NSEW])\s+(\d+)°(\d+')?(\d+\.\d+")?([NSEW])/;
    // eslint-disable-next-line sonarjs/slow-regex
    this.googleMapCheck = /@?(-?\d+\.\d+)\s*,\s*(-?\d+\.\d+)/;
  }
  parseCoordinates(input: any) {
    let result = this.decimalCheck.exec(input) || this.googleMapCheck.exec(input);
    if (result) {
      return this.formatResult(parseFloat(result[1]), parseFloat(result[2]));
    }

    result = this.dmsCheck.exec(input);
    if (result) {
      return this.formatResult(
        this.dmsToDecimal(
          result[1],
          result[2] ? result[2].slice(0, -1) : 0,
          result[3] ? result[3].slice(0, -1) : 0,
          result[4],
        ),
        this.dmsToDecimal(
          result[5],
          result[6] ? result[6].slice(0, -1) : 0,
          result[7] ? result[7].slice(0, -1) : 0,
          result[8],
        ),
      );
    }
    notify('Check the text format of the filled in coordinates!', 'warning');
    return this.formatResult(0, 0);
  }

  dmsToDecimal(degree: any, minute = 0, second = 0, direction = 'S') {
    let dd = Number(degree) + Number(minute) / 60 + Number(second) / 3600;
    dd = direction === 'S' || direction === 'W' ? dd * -1 : dd;
    return dd;
  }

  decimalToDms(dd: any, dirs: any) {
    const direction = dd < 0 ? dirs[1] : dirs[0];
    const degrees = Math.floor(dd);
    const tempMin = Math.abs(dd - degrees) * 60;
    const minutes = Math.floor(tempMin);
    const seconds = ((tempMin - minutes) * 60).toFixed(1);
    return `${degrees}°${minutes}'${seconds}"${direction}`;
  }
  dmsByDecimal(latitude: number, longitude: number) {
    return `${this.decimalToDms(latitude, 'NS')}+${this.decimalToDms(longitude, 'EW')}`;
  }

  formatResult(latitude: number, longitude: number) {
    return {
      latitude: Math.round(latitude * 100000) / 100000,
      longitude: Math.round(longitude * 100000) / 100000,
      coordinates: this.dmsByDecimal(latitude, longitude),
    };
  }
  getPlaceURL(coordinates: string) {
    return `https://www.google.com/maps/place/${coordinates}`;
  }
}
CoordinatesService.$inject = ['GtUtils'];
