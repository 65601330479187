import template from './tasks-templates-page-view.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const TasksTemplatesPageView = {
  template,
  controller: [
    'PageService',
    'TasksTemplatesService',
    'CoreService',
    class {
      CoreService: CoreService;
      PageService: PageService;
      TasksTemplatesService: any;
      filterLevel = '';
      updateFlag: any;
      constructor(PageService: PageService, TasksTemplatesService: any, CoreService: CoreService) {
        this.PageService = PageService;
        this.TasksTemplatesService = TasksTemplatesService;
        this.CoreService = CoreService;
      }

      $onInit() {
        this.filterLevel = 'tasks-templates-page-view';
        this.PageService.setConfig(this.getPageConfig());
        this.PageService.syncUrlFilter(this.filterLevel);
        this.updateFlag = false;
      }

      updateData() {
        this.updateFlag = !this.updateFlag;
      }

      getPageConfig() {
        return {
          class: 'page-header-main-pl-tab',
          buttons: [new this.PageService.buttons.Filters()],
          filters: {
            filterLevel: this.filterLevel,
            search: true,
          },
          pages: this.TasksTemplatesService.getPageConfig(),
        };
      }
    },
  ],
};
