import React from 'react';

import { dateFormat as dateFormatFn } from '~/shared/lib/date';
import { cn } from '~/shared/lib/utils';
import type { DataTypeProps } from '~/shared/ui/data-types';
import { messageIntent } from '~/shared/ui/data-types';
import { DatePicker } from '~/shared/ui/kit/date-picker';
import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';
import { Tooltip } from '~/shared/ui/kit/tooltip';

const fallbackFormat = 'dd/MM/yy';

export const DateWrite: React.FC<DataTypeProps<'date'>> = ({
  value,
  disabled,
  message,
  onChange,
  typeExtra,
}) => {
  const formattedDate = value ? dateFormatFn(value, typeExtra?.print ?? fallbackFormat) : '';

  if (disabled) {
    return <p className="text-text-main-primary text-xs opacity-70">{formattedDate}</p>;
  }

  const inputElement = (
    <Popover>
      <PopoverTrigger
        data-testid="date-field"
        className={cn(
          'text-text-main-primary block flex h-full min-h-[32px] w-full items-center rounded-sm px-2 py-1 text-left',
          message ? 'border-stroke-additional-danger border-b-2' : 'border-transparent',
        )}
      >
        {formattedDate}
      </PopoverTrigger>
      <PopoverContent className="w-full border-none p-0">
        <DatePicker mode="single" selected={value ?? new Date()} onSelect={onChange} required />
      </PopoverContent>
    </Popover>
  );

  return (
    <div className={cn('relative w-full min-w-[140px]', message && messageIntent[message.type])}>
      {message ? (
        <Tooltip content={message.text ?? ''} side="bottom" sideOffset={4}>
          <div className="w-full">{inputElement}</div>
        </Tooltip>
      ) : (
        inputElement
      )}
    </div>
  );
};
