import ng from 'angular';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').controller('ReportsController', Controller);

  Controller.$inject = ['$location', '$stateParams', 'AccountsService', 'GtUtils', 'gettext'];

  function Controller(
    this: any,
    $location: ng.ILocationService,
    $stateParams: ng.ui.IStateParamsService,
    AccountsService: AccountsService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.hbConfig = _prepareHbConfig();
    vm.tab = 'dashboard';
    vm.report = 'result';
    vm.IntroOptions = {};
    vm.sum = sum;
    vm.changeType = changeType;
    vm.tabClick = tabClick;
    vm.roleTabs = getRoleTabs();

    activate();

    ////////////////

    function activate() {
      if ($stateParams.type) {
        changeType($stateParams.type);
      }

      let roleIndex = 0;

      vm.roleTabs.filter(function (role: any, index: number) {
        if (role.roleName == ($stateParams.roleName || 'Client')) {
          roleIndex = index;
        }
      });
      tabClick(roleIndex);
    }

    function changeType(type: any) {
      $location.path('/reports/' + type);
      vm.tab = type;
    }

    function sum(operands: any) {
      let result = 0;
      ng.forEach(operands, function (operand: any) {
        result += parseFloat(operand);
      });
      return result;
    }

    function _prepareHbConfig() {
      return {
        print: {},
      };
    }

    function tabClick(roleIndex: number | null) {
      if (roleIndex !== null) {
        vm.role = vm.roleTabs[roleIndex];
      }

      if (!vm.role.tabs) {
        return;
      }
      vm.type = vm.typeTabs
        .filter(function (type: any) {
          return [vm.role.tabs[0]].includes(type.slug);
        })
        .shift();
    }

    function getRoleTabs() {
      const roles: any = [];
      if (AccountsService.hasPerm('view_buyer')) {
        roles.push({
          title: gettext('Buyers'),
          counter: 0,
          icon: GtUtils.getIcon('clients.Buyer'),
          roleName: 'Buyer',
          addButton: true,
        });
      }
      if (AccountsService.hasPerm('view_seller')) {
        roles.push({
          title: gettext('Suppliers'),
          counter: 0,
          icon: GtUtils.getIcon('clients.Supplier'),
          roleName: 'Supplier',
          addButton: true,
        });
      }
      if (AccountsService.hasPerm('view_deliverer')) {
        roles.push({
          title: gettext('Forwarders'),
          counter: 0,
          icon: GtUtils.getIcon('clients.Deliverer'),
          roleName: 'Deliverer',
          addButton: true,
        });
      }
      if (AccountsService.hasPerm('view_broker')) {
        roles.push({
          title: gettext('Brokers'),
          counter: 0,
          icon: GtUtils.getIcon('clients.Broker'),
          roleName: 'Broker',
          addButton: true,
        });
      }
      if (AccountsService.hasPerm('view_surveyor')) {
        roles.push({
          title: gettext('Surveyors'),
          counter: 0,
          icon: GtUtils.getIcon('clients.Surveyor'),
          roleName: 'Surveyor',
          addButton: true,
        });
      }
      if (AccountsService.hasPerm('view_insurer')) {
        roles.push({
          title: gettext('Insurers'),
          counter: 0,
          icon: GtUtils.getIcon('clients.Insurer'),
          roleName: 'Insurer',
          addButton: true,
        });
      }
      if (AccountsService.hasPerm('view_bank')) {
        roles.push({
          title: gettext('Banks'),
          counter: 0,
          icon: GtUtils.getIcon('clients.Bank'),
          roleName: 'Bank',
          addButton: true,
        });
      }
      if (AccountsService.hasPerm('view_owner')) {
        roles.push({
          title: gettext('Other'),
          counter: 0,
          icon: GtUtils.getIcon('clients.Owner'),
          roleName: 'Other',
          addButton: true,
        });
      }

      return roles;
    }
  }
})();
