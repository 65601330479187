import type ng from 'angular';
import type { Subscription } from 'rxjs';

import {
  type BaseContractQueryParams,
  type ContractClassification,
  type ContractRecord,
  type ContractsListViewModel,
  ExportContractsListViewModel,
  IntermediateContractsListViewModel,
  PurchaseContractsListViewModel,
  SaleContractsListViewModel,
  ServicesContractsListViewModel,
  type ViewMode,
} from '~/features/deals/contracts';
import type { ContractTotalsBase } from '~/shared/api';
import { formatDate } from '~/shared/lib/date';
import { container } from '~/shared/lib/di';
import { notify } from '~/shared/lib/notify';

import template from './contract-list-container.html?raw';
import type { ImportExportContractsService } from '../../import-export/import-export.service';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { MulticontractService } from '^/app/deals/multicontract/multicontract.service';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';
import { html } from '^/shared/utils';

type TimelineChartItem = {
  id: string;
  start?: string | null;
  end?: string | null;
  name: string;
  popupHtml: string;
};

class ContractListContainerController implements ng.IController {
  viewModel: ContractsListViewModel;
  viewModelMap: Record<string, ContractsListViewModel>;
  pageData: {
    records: ContractRecord[];
    count: number;
  } = { records: [], count: 0 };

  subs: Record<string, Subscription> = {};

  filterLevel = 'contract-list-container';
  queryParams: BaseContractQueryParams = { page_size: 25, page: 1, serializer: 'table_info' };
  initQueryParams: Partial<BaseContractQueryParams> = {};
  view: ViewMode = 'table';
  contracts: ContractRecord[] = [];
  timelineChartItems: TimelineChartItem[] = [];
  contractsTotal: Partial<ContractTotalsBase> | null = {};
  contractType?: ContractClassification;

  contractsCount = 0;
  inlineOrderingParams: any = [];
  reportConfig: any = null;

  contractPermissions: any;
  contractPurpose?: string;
  filterLevelNew?: string;
  newContract: any;
  onUpdate: any;
  quickAddInit: any;
  resource: any;
  savedFilterChoices: any;
  savedReportConfigs: any;

  static readonly $inject = [
    '$scope',
    '$rootScope',
    '$q',
    'GtUtils',
    'gettext',
    'gtFilterService',
    'ContractsService',
    'DocumentsService',
    'ClientsService',
    'LogisticsService',
    'ImportExportContractsService',
    'CoreService',
    'MulticontractService',
    'ReportsService',
  ];

  constructor(
    private readonly $scope: ng.IScope,
    private readonly $rootScope: GtRootScopeService,
    private readonly $q: ng.IQService,
    private readonly GtUtils: GtUtilsService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly gtFilterService: GtFilterService,
    private readonly ContractsService: ContractsService,
    private readonly DocumentsService: any,
    private readonly ClientsService: any,
    private readonly LogisticsService: LogisticsService,
    private readonly ImportExportContractsService: ImportExportContractsService,
    private readonly CoreService: CoreService,
    private readonly MulticontractService: MulticontractService,
    private readonly ReportsService: ReportsService,
  ) {
    this.viewModel = container.resolve(SaleContractsListViewModel);

    const saleViewModel = container.resolve(SaleContractsListViewModel);
    const purchaseViewModel = container.resolve(PurchaseContractsListViewModel);
    const exportViewModel = container.resolve(ExportContractsListViewModel);
    const intermediateViewModel = container.resolve(IntermediateContractsListViewModel);
    const servicesViewModel = container.resolve(ServicesContractsListViewModel);

    this.viewModelMap = {
      sale: saleViewModel,
      purchase: purchaseViewModel,
      export: exportViewModel,
      intermediate: intermediateViewModel,
      services: servicesViewModel,
    };
  }

  $onInit() {
    this.queryParams = {
      serializer: 'table_info',
      page_size: this.initQueryParams.page_size ?? 25,
      page: this.initQueryParams.page ?? 1,
      ...this.initQueryParams,
    };
    this.contractType = this.determineContractType(this.queryParams);
    this.viewModel = this.getViewModelForContractType(this.contractType);

    this.setInitialViewMode();
    this.viewModel.tableChanged(this.queryParams.serializer);
    this.viewModel.pageParamsChanged(this.queryParams);

    this.subs.pageData = this.viewModel.pageData$.subscribe((data) => {
      this.pageData = data;
      this.updateData();
      this.$scope.$applyAsync();
    });

    this.subs.totals = this.viewModel.totals$.subscribe((totals) => {
      this.contractsTotal = totals;
      this.$scope.$applyAsync();
    });

    this.subs.loading = this.viewModel.loading$.subscribe((loading) => {
      this.GtUtils.overlay(loading ? 'show' : 'hide');
      this.$scope.$applyAsync();
    });

    this.gtFilterService.subscribe(this.filterLevel, this.queryParamsChanged, this.queryParams);

    this.contractPermissions = this.getContractPermission(
      this.queryParams.contract_type,
      this.queryParams.deal_type,
    );

    this.$rootScope.$on(
      `gt-report-config-selected_${this.filterLevel}`,
      (event: any, config: any) => {
        this.reportConfig = config ? config.column_params : null;
      },
    );

    this.$rootScope.$on(
      `gt-report-config-created_${this.filterLevel}`,
      (event: any, config: any) => {
        this.savedReportConfigs = [...this.savedReportConfigs, config];
      },
    );

    this.$rootScope.$on(
      `gt-report-config-updated_${this.filterLevel}`,
      (event: any, config: any) => {
        const index = this.savedReportConfigs.findIndex((item: any) => item.id === config.id);
        this.savedReportConfigs[index] = config;
      },
    );

    this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
    this.savedReportConfigs = this.CoreService.getSavedReportConfigs(this.filterLevel);
    this.contractPurpose = this.ContractsService.getContractPurpose({
      contract_type: this.queryParams.contract_type,
      deal_type: this.queryParams.deal_type,
    });
  }

  $onChanges(changes: ng.IOnChangesObject) {
    if (changes.initQueryParams.currentValue) {
      this.queryParams = {
        serializer: 'table_info',
        page_size: this.initQueryParams.page_size ?? 25,
        page: this.initQueryParams.page ?? 1,
        ...this.initQueryParams,
      };
      this.viewModel.tableChanged(this.queryParams.serializer);
      this.viewModel.pageParamsChanged(this.queryParams);
    }
  }

  $onDestroy() {
    this.gtFilterService.unsubscribe(this.filterLevel, this.queryParamsChanged);
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());
  }

  queryParamsChanged = (params: BaseContractQueryParams) => {
    this.filterLevelNew = this.getFilterLevel(this.queryParams) ?? this.filterLevel;
    this.queryParams = {
      ...this.initQueryParams,
      ...params,
      deal_type: this.initQueryParams.deal_type,
    };
    this.viewModel.tableChanged(this.queryParams.serializer);
    this.viewModel.pageParamsChanged(this.queryParams);
  };

  applyFilters(params: QueryParams) {
    this.gtFilterService.updateQueryParams(params, this.filterLevel);
  }

  updateOrdering() {
    this.inlineOrderingParams = [
      { title: this.gettext('Conclusion date'), value: 'conclusion_date' },
      { title: this.gettext('Start of execution'), value: 'date_of_execution' },
      { title: this.gettext('End of execution'), value: 'end_of_execution' },
    ];
  }

  getResource(queryParams: BaseContractQueryParams) {
    return queryParams.serializer === 'table_prepayments_accounting'
      ? 'prepayments_accounting'
      : undefined;
  }

  getContractPermission(contractType: any, dealType: any) {
    let contractPermissions;
    if (dealType === 'services') {
      contractPermissions = 'add_servicescontract';
    } else if (dealType === 'export' || dealType === 'intermediate') {
      contractPermissions = 'add_contractbase';
    } else {
      contractPermissions = `add_${contractType}contract`;
    }
    return contractPermissions;
  }

  getFilterLevel(queryParams: BaseContractQueryParams) {
    const newFilterLevel =
      queryParams.serializer === 'table_prepayments_accounting'
        ? 'prepayments-accounting-page-view'
        : undefined;

    if (!newFilterLevel) {
      return;
    }
    const params = { ...this.gtFilterService.getQueryParams(this.filterLevel) };
    this.gtFilterService.setQueryParams(params, newFilterLevel);
    return newFilterLevel;
  }

  updateData() {
    this.newContract = this.getNewContract();

    this.contracts = this.pageData.records;
    this.contracts.forEach((item: ContractRecord) =>
      this.ContractsService.setContractReadonly(item),
    );
    this.contractsCount = this.pageData.count;

    this.timelineChartItems = this.pageData.records
      .filter((item: ContractRecord) => item.date_of_execution ?? item.end_of_execution)
      .map((item: ContractRecord) => ({
        id: item.id.toString(),
        start: item.date_of_execution ?? item.end_of_execution,
        end: item.end_of_execution ?? item.date_of_execution,
        name: item.contract_number?.toString() ?? '',
        popupHtml: html`
          Commodity: {[{item.crop_title}]}<br />
          Volume: {[{item.volume}]}<br />
          Buyer: {[{item.buyer_name}]}<br />
          Supplier: {[{item.supplier_name}]}<br />
          <span
            ><a
              href="#/contract/{[{item.id}]}?contract_type={[{item.contract_type}]}&stage=contract"
              target="_blank"
              >Details <i class="fa fa-arrow-right"></i></a
          ></span>
        `,
      }));
  }

  updateContractsExecutionDates(data: any) {
    this.GtUtils.overlay('show');
    let chain = this.$q.when();
    data
      .map((item: any) => {
        return this.contracts
          .filter((contract: any) => contract.id === item.id)
          .reduce((acc: any, contract: any) => {
            acc[contract.id] = {
              ...contract,
              id: item.id,
              date_of_execution: item.start,
              end_of_execution: item.end,
            };
            return acc;
          }, {});
      })
      .forEach((item: any) => {
        chain = chain.then(() => this.ContractsService.updateContract(item));
      });

    chain.then(() => {
      this.GtUtils.overlay('hide');
      this.updateData();
    });
  }

  getNewContract() {
    const businessUnit = this.$rootScope.user.profile?.business_units.find(
      (bu: any) => bu.is_default,
    )?.businessunit;
    return {
      deal_type: 'spot',
      ...this.queryParams,
      ...this.quickAddInit,
      business_unit: businessUnit,
    };
  }

  openContractModal(contract: any) {
    return this.ContractsService.contractModal(contract).then(() => {
      this.viewModel.pageParamsChanged(this.queryParams);
      this.$scope.$applyAsync();
      this.onUpdate();
    });
  }

  createServiceContract(contract: any) {
    return this.ContractsService.createServiceContract(contract).then(() => {
      this.updateData();
      this.onUpdate();
    });
  }

  openContractModalQuick(contract: any) {
    return this.ContractsService.contractModalQuick(contract).then(() => {
      this.updateData();
      this.onUpdate();
    });
  }

  cloneContract(contract: any) {
    if (!confirm(this.gettext('Do you want to clone this Contract?'))) {
      return this.$q.resolve();
    }
    return this.ContractsService.cloneContract(contract).then(() => this.updateData());
  }

  openEmailModal(contract: any) {
    return this.ClientsService.sendEmailModal(
      contract.contract_purpose.charAt(0).toUpperCase() +
        contract.contract_purpose.slice(1) +
        'Contract',
      contract.id,
    );
  }

  openDocxModal(contract: any) {
    return this.DocumentsService.generateDocxModal(
      contract.contract_purpose.charAt(0).toUpperCase() +
        contract.contract_purpose.slice(1) +
        'Contract',
      contract.id,
    );
  }

  openDocumentModal(contract: any) {
    return this.DocumentsService.documentListModal({
      model_name: 'ContractBase',
      object_id: contract.id,
    }).then(() => this.updateData());
  }

  approveContract(contract: any) {
    return this.ContractsService.Contract.query(
      { id: contract.id, serializer: 'modal' },
      (data: any) => this.ContractsService.approveDeal(data).then(() => this.updateData()),
    );
  }

  addLogistic(contract: any) {
    return this.ContractsService.addLogistic(contract);
  }

  addFinance(contract: any, cond: any, invoiceStatus: any) {
    return this.ContractsService.addFinance(contract, cond, invoiceStatus);
  }

  addBalanceInvoice(contract: any) {
    return this.ContractsService.addBalanceInvoice(contract);
  }

  reverseContract(contract: any) {
    if (!confirm(this.gettext('Do you want to reverse contract?'))) {
      return;
    }
    return this.ContractsService.reverseDeal(contract);
  }

  createPassport(contract: any) {
    if (contract.total_connected && contract.volume - contract.total_connected <= 0) {
      return notify(this.gettext('This contracts already fully connected'), 'error');
    }

    return this.ContractsService.createPassportContract([contract]).then(() => this.updateData());
  }

  connectToPassport(contract: any, passportId: number) {
    return this.ContractsService.getVolumeAvailableToConnect(contract, passportId)
      .then((data: any) => {
        return this.ContractsService.connectToPassport({
          id: passportId,
          deal_id: contract.id,
          stage: 'contract',
          contract_purpose: this.ContractsService.getContractPurpose(contract),
          volume: data,
        });
      })
      .then(() => this.updateData());
  }

  setFinalVolumeFromExecution(contractId: number) {
    if (!confirm(this.gettext('Do you want to update final volume from execution?'))) {
      return false;
    }
    return this.ContractsService.Contract.setFinalVolumeFromExecution(
      {
        id_list: [contractId],
      },
      () => {
        this.updateData();
        notify(this.gettext('Contract updated'));
      },
    );
  }

  openVoyageModal(voyage: any) {
    return this.LogisticsService.voyageModal(voyage).then(() => this.updateData());
  }

  openRoleModal(roleId: number, roleModel: any) {
    return this.ClientsService.roleModal({ id: roleId, model_name: roleModel });
  }

  openStageModal(stage: any) {
    return this.ContractsService.contractStageModal(stage).then(() => this.updateData());
  }

  openMulticontractModal(contract: any) {
    return this.MulticontractService.createMultiContractFromPosition(contract).then(() =>
      this.updateData(),
    );
  }

  openImportExportContractsModal() {
    return this.ImportExportContractsService.importExportModal(
      this.queryParams.contract_type as string,
    ).then(() => this.updateData());
  }

  updateConnections() {
    const contractType = (this.contracts.length && this.contracts[0].contract_type) ?? 'sale';
    this.$rootScope.$broadcast(contractType + '-connections-updated', this.contracts);
  }

  updateStatus() {
    this.GtUtils.overlay('show');
    return this.ContractsService.Contract.updateStatus(this.queryParams, () => {
      this.updateData();
      notify(this.gettext('Contracts status updated'));
    });
  }

  createPnlControl() {
    this.GtUtils.overlay('show');
    return this.ContractsService.Contract.createPnlControl({
      ...this.queryParams,
      bulk_create: true,
    })
      .$promise.then(() => {
        this.updateData();
        notify(this.gettext('Contracts control is being created. Wait a few minutes'));
      })
      .catch((err: any) => {
        this.GtUtils.errorClb(err);
      })
      .finally(() => {
        this.GtUtils.overlay('hide');
      });
  }

  setVoyageData() {
    this.GtUtils.overlay('show');
    return this.ContractsService.Contract.setVoyageData({}, () => {
      this.updateData();
      notify(this.gettext('Contracts updated'));
    });
  }

  createUpdateRequest() {
    this.GtUtils.overlay('show');
    return this.ReportsService.CalculatedValueUpdateRequest.save(
      {
        view_name: 'view_materialized_contract_list',
      },
      (data: any) => {
        if (this.contractsTotal) {
          this.contractsTotal.last_update_request_status = 'pending';
          this.contractsTotal.last_update_request_update_time = formatDate(
            data.create_time,
            "yyyy-MM-dd'T'HH:mm",
          );
        }
        notify(this.gettext('The totals have been updated'));

        this.GtUtils.overlay('hide');
      },
      (data: any) => {
        this.GtUtils.errorClb(data);
      },
    );
  }

  setInitialViewMode() {
    let initialViewMode: ViewMode = 'table';

    if (this.$rootScope.isDeviceMobile) {
      initialViewMode = 'block';
    } else if (['list', 'block'].includes(this.$rootScope.user.profile?.contracts_view ?? '')) {
      initialViewMode = this.$rootScope.user.profile?.contracts_view as ViewMode;
    }

    this.changeViewMode(initialViewMode);
  }

  changeViewMode = (viewMode: ViewMode) => {
    this.view = viewMode;
    this.$scope.$applyAsync();
  };

  private determineContractType(
    queryParams: BaseContractQueryParams,
  ): 'sale' | 'purchase' | 'services' | 'export' | 'intermediate' {
    if (queryParams.deal_type === 'services') {
      return 'services';
    }
    if (queryParams.deal_type === 'intermediate') {
      return 'intermediate';
    }
    if (queryParams.deal_type === 'export') {
      return 'export';
    }
    if (queryParams.contract_type === 'purchase') {
      return 'purchase';
    }
    return 'sale';
  }

  private getViewModelForContractType(
    contractType: 'sale' | 'purchase' | 'services' | 'export' | 'intermediate',
  ): ContractsListViewModel {
    return this.viewModelMap[contractType];
  }
}

export const contractListContainer: ng.IComponentOptions = {
  bindings: {
    filterLevel: '<?',
    tableName: '<?',
    initQueryParams: '<?',
    quickAddInit: '<?',
    view: '<?',
    expanded: '<?',
    extraFields: '<?',
    showConnected: '<?',
    onUpdate: '&?',
    simplifiedView: '<?',
    hideButton: '<?',
  },
  template,
  controller: ContractListContainerController,
};
