import { AuthStore } from '~/core/auth/services';
import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import {
  Finance,
  FinanceListParams,
  FinanceMenuActions,
  FinancePatch,
  FinancePost,
  FinanceRecord,
  getFinanceFields,
} from '~/features/finances/finances/lib';
import { FinanceRepository } from '~/features/finances/finances/services';
import { container, injectable } from '~/shared/lib/di';
import { from, lastValueFrom, switchMap, take } from '~/shared/lib/state';

export type FinanceListContainerProps = ContainerProps<FinanceListParams>;

@injectable()
export class FinanceListModel extends EntityListContainerModel<
  Finance,
  FinanceListParams,
  FinanceRepository,
  FinanceListContainerProps,
  FinanceRecord,
  FinancePost,
  FinancePatch
> {
  entityRepoClass = FinanceRepository;

  private readonly authStore = container.resolve(AuthStore);
  private menuActions: FinanceMenuActions = {
    openEditModal: (_finance: FinanceRecord) => {
      throw new Error('openEditModal not implemented.');
    },
    openEditAdminModal: (_finance: FinanceRecord) => {
      throw new Error('openEditAdminModal not implemented.');
    },
    openToPayModal: (_finance: FinanceRecord) => {
      throw new Error('openToPayModal not implemented.');
    },
    openDocxModal: (_finance: FinanceRecord) => {
      throw new Error('openDocxModal not implemented.');
    },
    clone: (_finance: FinanceRecord) => {
      throw new Error('clone not implemented.');
    },
    approve: (_finance: FinanceRecord) => {
      throw new Error('approve not implemented.');
    },
    decline: (_finance: FinanceRecord) => {
      throw new Error('decline not implemented.');
    },
    reactivate: (_finance: FinanceRecord) => {
      throw new Error('reactivate not implemented.');
    },
  };

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.repo.getFinanceListTotals(params))),
  );

  getFields = async () => {
    const user = await lastValueFrom(this.authStore.currentUser$.pipe(take(1)));
    return getFinanceFields(user);
  };

  setMenuActions = (menuActions: FinanceMenuActions) => {
    this.menuActions = menuActions;
  };
}
