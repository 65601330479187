import React from 'react';

import { t } from '~/shared/lib/i18n';
import { cn } from '~/shared/lib/utils';
import { SortableContext, verticalListSortingStrategy } from '~/shared/ui/lib/dnd';

import { ParamsDndListItem } from './columns-dnd-list-item';
import type { ColumnConfigParams } from '../../lib/types';

export const ColumnsDndList = ({
  items,
  titleText,
  titleBtnText,
  loading,
  titleBtnHandler,
  toggleVisibility,
  toggleRequirement,
  onChange,
}: {
  items: ColumnConfigParams[];
  titleText: string;
  titleBtnText: string;
  loading: boolean;
  titleBtnHandler: () => void;
  toggleVisibility: (params: ColumnConfigParams) => void;
  toggleRequirement: (params: ColumnConfigParams) => void;
  onChange: (params: ColumnConfigParams) => void;
}) => {
  return (
    <SortableContext items={items} strategy={verticalListSortingStrategy}>
      <div className="bg-transparent-lighter flex items-baseline justify-between px-2 text-xs">
        <span className="p-1">{t(titleText)}</span>
        <button
          disabled={loading}
          onClick={titleBtnHandler}
          className={cn(
            'ml-auto cursor-pointer border-none bg-transparent text-blue-600 hover:text-blue-800',
            loading ? 'pointer-events-none opacity-50' : '',
          )}
        >
          {t(titleBtnText)}
        </button>
      </div>
      <div className="max-h-50 w-full overflow-x-hidden overflow-y-auto">
        {items.map((item) => (
          <ParamsDndListItem
            key={item.id}
            params={item}
            loading={loading}
            toggleVisibility={toggleVisibility}
            toggleRequirement={toggleRequirement}
            onChange={onChange}
          />
        ))}
      </div>
    </SortableContext>
  );
};
