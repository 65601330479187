import {
  type Entity,
  type EntityAutocomplete,
  type EntityName,
  type EntityQueryParams,
  type EntityRepository,
  entityAutocompleteWithSearch,
} from '~/shared/lib/entities';

import type { EntityOption } from './types';

export const entityAutocompletesRegistry: Partial<Record<EntityName, typeof EntityAutocomplete>> =
  {};
export const entitySearchesRegistry: Partial<
  Record<EntityName, EntityRepository<Entity, EntityQueryParams>['search']>
> = {};

export const findEntity = async (
  entityName: EntityName,
  search: string,
): Promise<{ entity?: EntityOption; exact: boolean }> => {
  const searchFn = entitySearchesRegistry[entityName];
  if (!searchFn) {
    throw new Error(`Entity "${entityName}" is not registered`);
  }
  const results = await searchFn(search);
  const entity =
    results.find((r) => r.title === search) ?? results.find((r) => r.title.includes(search));

  return { entity, exact: entity?.title === search };
};

export const getEntity = async (entityName: EntityName, id: number): Promise<EntityOption> => {
  const searchFn = entitySearchesRegistry[entityName];
  if (!searchFn) {
    throw new Error(`Entity "${entityName}" is not registered`);
  }

  return (await searchFn('', id))[0];
};

export const registerEntityAutocomplete = (
  entityName: EntityName,
  search: EntityRepository<Entity, EntityQueryParams>['search'],
) => {
  if (entityAutocompletesRegistry[entityName]) {
    throw new Error(`Autocomplete "${entityName}" is already registered.`);
  }
  entitySearchesRegistry[entityName] = search;
  entityAutocompletesRegistry[entityName] = entityAutocompleteWithSearch(search)();
};
