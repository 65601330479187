import ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import type { GtFilterService } from './gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '../../types';
import type { GtUtilsService } from '../gt-utils/gt-utils.srv';

import type { CoreService } from '^/app/core/core.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { HelpButtonsService } from '^/app/core/legacy/help-buttons/help-buttons.srv';

(function () {
  'use strict';
  ng.module('core.legacy').directive('gtFilter', directive);

  function directive() {
    return {
      template: require('./gt-filter.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {},
    };
  }

  Controller.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    'CoreService',
    'PageService',
    'HelpButtonsService',
    'gtFilterService',
    'gettext',
    '$location',
    'GtUtils',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    $timeout: ng.ITimeoutService,
    CoreService: CoreService,
    PageService: PageService,
    HelpButtonsService: HelpButtonsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    $location: ng.ILocationService,
    GtUtils: GtUtilsService,
  ) {
    const vm = this;

    vm.config = {};
    vm.order = order;
    vm.close = close;
    vm.close_not_clear = closeNotClear;
    vm.apply = apply;
    vm.setDates = setDates;
    vm.orderIcon = orderIcon;
    vm.datepicker = '';
    vm.chosedSelects = {};
    vm.refreshItems = refreshItems;
    vm.chosedNestedSelects = {};
    vm.chosedNestedMultiSelects = {};
    vm.setNestedSelect = setNestedSelect;
    vm.setNestedMultiSelect = setNestedMultiSelect;
    vm.toggleDateSelect = toggleDateSelect;
    vm.clearSelection = clearSelection;
    vm.openFilterConfigModal = openFilterConfigModal;
    vm.queryParams = {};
    vm.filterInfo = {};
    vm.showFilterInfo = false;
    vm.resourceSelected = resourceSelected;
    vm.selectedResources = {};
    vm.filterQueryParams = {};
    vm.datepickerConfig = getDatePickerConfig(false);
    vm.groupBy = null;
    vm.groupBySelect = groupBySelect;
    vm.idInRange = idInRange;
    vm.periodTitle = 'Data range';
    vm.selectDateRange = selectDateRange;
    vm.rangesConfig = gtFilterService.getPeriodsConfig();
    vm.oldStartDate = undefined;
    vm.oldEndDate = undefined;
    vm.filterUnwatch = null;

    vm.$onInit = function () {
      vm.config.searchPrompt = GtUtils.translate(gettext(vm.config.searchPrompt || 'Search'));

      if (vm.config.dateSelects) {
        vm.config.dateSelects.push({
          argument: 'update_time',
          placeholder: gettext('Update time'),
        });
      }

      $scope.$on('hb-config-updated', function (ev: any, data: any) {
        if (!data?.filter) {
          vm.config = { clicked: false };
          return;
        }
        if (!data?.filter) {
          return;
        }
        if (data.filter.dateSelects) {
          const contains = data.filter.dateSelects.some(function (dateSelect: any) {
            return dateSelect.argument == 'update_time';
          });
          if (!contains) {
            data.filter.dateSelects.push({
              argument: 'update_time',
              placeholder: gettext('Update time'),
            });
          }
        }
        if (data.filter.multiSelects) {
          ng.forEach(data.filter.multiSelects, function (multiSelect: any) {
            if (multiSelect.queryParamsLookups) {
              multiSelect.getQueryParams = function () {
                const queryParams: QueryParams = {};
                ng.forEach(multiSelect.queryParamsLookups, function (queryParamsLookup: any) {
                  queryParams[queryParamsLookup] = vm.queryParams[queryParamsLookup];
                });
                return queryParams;
              };
            }
          });
        }

        CoreService.applyFilterConfig({ ...data.filter }).then((filter: any) => {
          vm.config = filter;
        });

        vm.queryParams = gtFilterService.getQueryParams(vm.config.filterLevel);
        $scope.$on('gt-filter-updated_' + vm.config.filterLevel, function (ev: any, data: any) {
          vm.queryParams = data;
        });
      });

      $scope.$on('page-config-updated', function (ev: any, data: any) {
        if (vm.filterUnwatch) {
          vm.filterUnwatch();
        }
        if (!data?.filters) {
          vm.config = { clicked: false };
          return;
        }
        vm.datepickerConfig = getDatePickerConfig();
        if (data.filters.dateSelects) {
          const contains = data.filters.dateSelects.some(function (dateSelect: any) {
            return dateSelect.argument == 'update_time';
          });
          if (!contains) {
            data.filters.dateSelects.push({
              argument: 'update_time',
              placeholder: gettext('Update time'),
            });
          }
        }
        if (data.filters.multiSelects) {
          ng.forEach(data.filters.multiSelects, function (multiSelect: any) {
            if (multiSelect.queryParamsLookups) {
              multiSelect.getQueryParams = function () {
                const queryParams: QueryParams = {};
                ng.forEach(multiSelect.queryParamsLookups, function (queryParamsLookup: any) {
                  queryParams[queryParamsLookup] = vm.queryParams[queryParamsLookup];
                });
                return queryParams;
              };
            }
            multiSelect.placeholder = GtUtils.translate(multiSelect.placeholder);
          });
        }

        if (data.filters.nestedSelects) {
          ng.forEach(data.filters.nestedSelects, function (nestedSelect: any) {
            nestedSelect.placeholder = GtUtils.translate(nestedSelect.placeholder);
          });
        }

        CoreService.applyFilterConfig({ ...data.filters }).then((filters: any) => {
          vm.config = filters;

          vm.queryParams = gtFilterService.getQueryParams(vm.config.filterLevel);
          updateFilterQueryParams();
          updateFilterInfo();

          vm.filterUnwatch = $scope.$on(
            'gt-filter-updated_' + vm.config.filterLevel,
            function (ev: any, data: any) {
              vm.queryParams = data;
              updateFilterQueryParams();
              if (!vm.queryParams.start_date || !vm.queryParams.end_date) {
                vm.datepicker = '';
              }
              updateFilterInfo();
            },
          );
        });
      });
    };

    ////////////////

    function updateFilterQueryParams() {
      vm.filterQueryParams = {};
      vm.filterQueryParams = [vm.config.selects || [], vm.config.multiSelects || []]
        .flat()
        .filter((select) => select.queryParams)
        .reduce((res, select) => {
          res[select.argument] =
            typeof select.queryParams === 'function' ? select.queryParams() : select.queryParams;
          return res;
        }, {});

      (vm.config.selects || []).forEach(function (select: any) {
        if (select.related_fk && select.related_model) {
          const _params = { ...vm.queryParams };
          if (_params[select.argument]) {
            delete _params[select.argument];
          }
          vm.filterQueryParams[select.argument] = {
            ...vm.filterQueryParams[select.argument],
            related_fk: select.related_fk,
            related_model: select.related_model,
            related_queryset: JSON.stringify(_params),
          };
        }
      });

      (vm.config.multiSelects || []).forEach(function (select: any) {
        if (select.related_fk && select.related_model) {
          const _params = { ...vm.queryParams };
          if (_params[select.argument]) {
            delete _params[select.argument];
          }
          vm.filterQueryParams[select.argument] = {
            ...vm.filterQueryParams[select.argument],
            related_fk: select.related_fk,
            related_model: select.related_model,
            related_queryset: JSON.stringify(_params),
          };
        }
      });

      (vm.config.nestedSelects || []).forEach(function (item: any) {
        if (vm.queryParams[item.argument] === undefined) {
          delete vm.chosedNestedSelects[item.argument];
        }
      });

      (vm.config.nestedMultiSelects || []).forEach(function (item: any) {
        if (vm.queryParams[item.argument] === undefined) {
          delete vm.chosedNestedMultiSelects[item.argument];
        }
      });
    }

    function idInRange(nestedMultiSelectArgument: any, nestedItem: any) {
      let result = false;
      if (ng.equals(vm.chosedNestedMultiSelects, {})) {
        return result;
      }
      vm.chosedNestedMultiSelects[nestedMultiSelectArgument]?.forEach(function (option: any) {
        if (nestedItem === undefined || option.id == nestedItem.id) {
          result = true;
        }
      });
      return result;
    }

    function groupBySelect() {
      $rootScope.$broadcast('group-by', vm.groupBy);
    }

    function updateFilterInfo() {
      vm.filterInfo = {};

      if (vm.config.invert_filters && vm.queryParams.invert_filters) {
        vm.filterInfo['Invert filters'] = gettext('Yes');
      }

      if (vm.config.search && vm.queryParams.search) {
        vm.filterInfo.Search = vm.queryParams.search;
      }

      if (vm.config.dates && vm.queryParams.start_date) {
        vm.filterInfo['Start date'] = vm.queryParams.start_date;
      }

      if (vm.config.dates && vm.queryParams.end_date) {
        vm.filterInfo['End date'] = vm.queryParams.end_date;
      }

      if (vm.config.dates && vm.queryParams.period) {
        vm.filterInfo.Period =
          gtFilterService.getPeriodsConfig()[vm.queryParams.period] || vm.periodTitle;
      }

      vm.config.multiSelects = vm.config.multiSelects || [];
      vm.config.multiSelects.forEach(function (item: any) {
        if (vm.queryParams[item.argument]?.length || vm.queryParams[item.argument] === 0) {
          vm.filterInfo[item.placeholder] = findItemTitle(
            item.argument,
            vm.queryParams[item.argument],
          );
        }
      });

      vm.config.searchFilters = vm.config.searchFilters || [];
      vm.config.searchFilters.forEach(function (item: any) {
        if (vm.queryParams[item.argument]) {
          vm.filterInfo[item.placeholder] = vm.queryParams[item.argument];
        }
      });

      vm.config.nestedSelects = vm.config.nestedSelects || [];
      vm.config.nestedSelects.forEach(function (item: any) {
        if (vm.queryParams[item.argument] || vm.queryParams[item.argument] === 0) {
          vm.filterInfo[item.placeholder] = findItemTitle(
            item.argument,
            vm.queryParams[item.argument],
          );
          if (!(item.argument in vm.chosedNestedMultiSelects)) {
            vm.chosedNestedSelects[item.argument] = {
              id: parseInt(vm.queryParams[item.argument], 10) || vm.queryParams[item.argument],
            };
          }
        }
      });

      vm.config.nestedMultiSelects = vm.config.nestedMultiSelects || [];
      vm.config.nestedMultiSelects.forEach(function (item: any) {
        if (vm.queryParams[item.argument]?.length || vm.queryParams[item.argument] === 0) {
          vm.filterInfo[item.placeholder] = findItemTitle(
            item.argument,
            vm.queryParams[item.argument],
          );
          if (!(item.argument in vm.chosedNestedMultiSelects)) {
            if (Array.isArray(vm.queryParams[item.argument])) {
              vm.chosedNestedMultiSelects[item.argument] = vm.queryParams[item.argument].map(
                (item: any) => ({
                  id: item,
                }),
              );
            } else {
              vm.chosedNestedMultiSelects[item.argument] = [{ id: vm.queryParams[item.argument] }];
            }
          }
        }
      });
      vm.showFilterInfo = Boolean(Object.keys(vm.filterInfo).length);
    }

    function findItemTitle(key: string, val: any): string[] {
      vm.config.nestedSelects = vm.config.nestedSelects ?? [];
      vm.config.selects = vm.config.selects ?? [];
      vm.config.multiSelects = vm.config.multiSelects ?? [];

      const nestedTitle = getNestedSelectTitle(key, val, vm.config.nestedSelects);
      if (nestedTitle.length) {
        return nestedTitle;
      }
      const selectedTitle = getSelectTitle(key, vm.config.selects, vm.selectedResources);
      if (selectedTitle.length) {
        return selectedTitle;
      }
      const multiSelectTitles = getMultiSelectTitles(key, val, vm.config.multiSelects);
      if (multiSelectTitles.length) {
        return multiSelectTitles;
      }
      return getFallbackTitle(val);
    }

    function getNestedSelectTitle(
      key: string,
      val: any,
      nestedSelects: { argument: string; items: { id: number; title: string }[] }[],
    ): string[] {
      const nesSelect = nestedSelects.find((select) => select.argument === key);
      if (!nesSelect) {
        return [];
      }
      const nes = nesSelect.items.find((item) => item.id === val);
      return nes ? [nes.title] : [];
    }

    function getSelectTitle(
      key: string,
      selects: { argument: string }[],
      selectedResources: Record<string, string>,
    ): string[] {
      const curSelect = selects.find((select) => select.argument === key);

      if (!curSelect) {
        return [];
      }

      if (selectedResources[key]) {
        return [selectedResources[key]];
      }

      return [];
    }

    function getMultiSelectTitles(
      key: string,
      val: any,
      multiSelects: { argument: string; resource: string }[],
    ): string[] {
      const mulSelect: string[] = [];

      multiSelects
        .filter((select) => select.argument === key)
        .forEach((select) => {
          const cachedResults = getCachedResults(select.resource);
          if (Array.isArray(cachedResults)) {
            mulSelect.push(...getMatchingTitles(val, cachedResults));
          }
        });

      return mulSelect;
    }

    function getCachedResults(resource: string): { id: any; title: string }[] {
      const cachedValue = GtUtils.getCachedValue('predictions', resource);

      if (cachedValue && Array.isArray(cachedValue.results)) {
        return cachedValue.results as { id: any; title: string }[];
      }

      return [];
    }

    function getMatchingTitles(val: any, cachedResults: { id: any; title: string }[]): string[] {
      return cachedResults
        .filter(
          (cachedResult) =>
            val.includes(cachedResult.id) || val.includes(cachedResult.id.toString()),
        )
        .map((cachedResult) => cachedResult.title);
    }

    function getFallbackTitle(val: any): string[] {
      if (Array.isArray(val)) {
        return val.map((v) => String(v));
      }
      return [String(val)];
    }

    function resourceSelected(this: any, argument: any) {
      vm.selectedResources[argument] = this.$select?.selected?.title;
      apply();
    }

    function apply(params?: any) {
      vm.queryParams.page = undefined;
      if (params) {
        vm.queryParams = params;
        gtFilterService.setQueryParams(vm.queryParams, vm.config.filterLevel, false);
      } else {
        gtFilterService.updateQueryParams(vm.queryParams, vm.config.filterLevel);
      }
      updateFilterInfo();
    }

    function order(orderingItem: any) {
      const pos = _substringPosition(orderingItem.predicate);
      if (!vm.queryParams.ordering || pos == -1) {
        vm.queryParams.ordering = orderingItem.predicate;
      } else if (pos === 0) {
        vm.queryParams.ordering = '-' + orderingItem.predicate;
      } else {
        vm.queryParams.ordering = undefined;
      }
      apply();
    }

    function orderIcon(orderingItem: any) {
      const pos = _substringPosition(orderingItem.predicate);
      if (!vm.queryParams.ordering || pos == -1) {
        return 'fa-sort';
      }
      if (pos === 0) {
        return 'fa-sort-amount-desc';
      }
      return 'fa-sort-amount-asc';
    }

    function _substringPosition(predicate: any) {
      if (!vm.queryParams.ordering) {
        return -1;
      }
      return vm.queryParams.ordering.indexOf(predicate);
    }

    function clear() {
      const registeredFilterLevels = gtFilterService.getRegisteredCleanFilterLevels();
      if (registeredFilterLevels.includes(vm.config.filterLevel)) {
        gtFilterService.clearFilter(vm.config.filterLevel);
      } else {
        vm.datepicker = '';
        vm.chosedSelects = {};
        vm.chosedNestedSelects = {};
        vm.chosedNestedMultiSelects = {};
        vm.selectedResources = {};
        vm.filterInfo = {};
        vm.showFilterInfo = false;
        vm.queryParams.search = undefined;
        vm.queryParams.invert_filters = undefined;
        vm.queryParams.date_predicate = undefined;
        vm.queryParams.crop_qualities_list = undefined;
        vm.queryParams.period = undefined;
        vm.groupBy = undefined;
        (vm.config.ordering || []).forEach(function (order: any) {
          vm.queryParams[order.predicate] = undefined;
        });

        (vm.config.selects || []).forEach(function (select: any) {
          vm.queryParams[select.argument] = undefined;
        });

        (vm.config.nestedSelects || []).forEach(function (select: any) {
          vm.queryParams[select.argument] = undefined;
        });

        (vm.config.nestedMultiSelects || []).forEach(function (select: any) {
          vm.queryParams[select.argument] = undefined;
        });

        (vm.config.multiSelects || []).forEach(function (select: any) {
          vm.queryParams[select.argument] = undefined;
        });

        (vm.config.searchFilters || []).forEach(function (searchFilter: any) {
          vm.queryParams[searchFilter.argument] = undefined;
        });

        vm.queryParams.cargo_list = undefined;
        vm.queryParams.crop_type = undefined;
        vm.queryParams.ordering = undefined;
        setDates(vm.queryParams);
      }
    }

    function close() {
      $rootScope.$broadcast('gt-favorite-filter-clear');
      clear();
      vm.config.clicked = false;
      apply();
      HelpButtonsService.updateConfig({ filter: { ...vm.config } });
    }

    function closeNotClear() {
      vm.config.clicked = false;
      HelpButtonsService.updateConfig({ filter: { ...vm.config } });
    }

    function toggleDateSelect(dateSelect: any) {
      if (!vm.queryParams.date_predicate) {
        vm.queryParams.date_predicate = [];
      } else if (!ng.isArray(vm.queryParams.date_predicate)) {
        // handle case when date predicate is parsed from querystring as
        // a single string
        vm.queryParams.date_predicate = [vm.queryParams.date_predicate];
      }
      const index = vm.queryParams.date_predicate.indexOf(dateSelect.argument);
      if (index >= 0) {
        vm.queryParams.date_predicate.splice(index, 1);
      } else {
        vm.queryParams.date_predicate.push(dateSelect.argument);
      }
      if (vm.queryParams.start_date || vm.queryParams.end_date) {
        apply();
      } else {
        updateFilterInfo();
      }
    }

    function setDates(params?: any) {
      if (!vm.datepicker) {
        vm.queryParams.start_date = vm.oldStartDate;
        vm.queryParams.end_date = vm.oldEndDate;
      } else {
        vm.queryParams.start_date = formatDate(vm.datepicker.startDate, 'dd.MM.yyyy');
        vm.queryParams.end_date = formatDate(vm.datepicker.endDate, 'dd.MM.yyyy');
        vm.queryParams.period = undefined;
      }
      apply(params);
    }

    function getDatePickerConfig(useRanges = true) {
      const config = gtFilterService.getDateRangePickerConfig(useRanges);
      config.eventHandlers = {
        'apply.daterangepicker': () => {
          $timeout(function () {
            setDates();
          });
        },
        'cancel.daterangepicker': () => {
          if (!vm.datepicker) {
            return;
          }
          vm.datepicker.startDate = new Date();
          vm.datepicker.endDate = new Date();
          $timeout(function () {
            vm.datepicker = false;
            setDates();
          });
        },
      };
      return config;
    }

    function clearSelection(argument: any) {
      delete vm.chosedSelects[argument];
      vm.queryParams[argument] = undefined;
      apply();
    }

    function refreshItems(search: any, selectItem: any) {
      if (!selectItem.refreshMethod) {
        return;
      }
      return selectItem.refreshMethod(search).then(function (list: any) {
        selectItem.items = list.results;
      });
    }

    function setNestedSelect(argument: any, nestedItem: any) {
      if (nestedItem) {
        vm.chosedNestedSelects[argument] = nestedItem;
        vm.queryParams[argument] = nestedItem.id;
        if (nestedItem.extraList && vm.chosedNestedSelects[argument].extra) {
          vm.queryParams[argument] = vm.chosedNestedSelects[argument].extra.id;
        }
      } else {
        delete vm.chosedNestedSelects[argument];
        vm.queryParams[argument] = undefined;
      }
      if (nestedItem?.extraList) {
        vm.queryParams[argument] = undefined;
        vm.queryParams.cargo_list = undefined;
        setNestedMultiSelect('cargo_list', nestedItem);
      }
      apply();
    }

    function setNestedMultiSelect(argument: any, nestedItem: any) {
      if (
        idInRange(argument, nestedItem) &&
        vm.chosedNestedMultiSelects[argument] &&
        vm.queryParams[argument] &&
        nestedItem
      ) {
        vm.chosedNestedMultiSelects[argument] = vm.chosedNestedMultiSelects[argument].filter(
          function (option: any) {
            return option.id !== nestedItem.id;
          },
        );
        vm.queryParams[argument] = vm.queryParams[argument].filter(function (option: any) {
          return option !== nestedItem.id;
        });
        if (
          vm.queryParams[argument] &&
          vm.queryParams[argument].length == 0 &&
          vm.chosedNestedMultiSelects[argument].length == 0
        ) {
          delete vm.chosedNestedMultiSelects[argument];
          vm.queryParams[argument] = undefined;
        }
      } else if (nestedItem) {
        if (vm.chosedNestedMultiSelects[argument]?.length) {
          vm.chosedNestedMultiSelects[argument].push(nestedItem);
        } else {
          vm.chosedNestedMultiSelects[argument] = [nestedItem];
        }

        if (
          vm.queryParams[argument] &&
          Array.isArray(vm.queryParams[argument]) &&
          vm.queryParams[argument].length
        ) {
          vm.queryParams[argument].push(nestedItem.id);
        } else {
          vm.queryParams[argument] = [nestedItem.id];
        }
      } else {
        delete vm.chosedNestedMultiSelects[argument];
        vm.queryParams[argument] = undefined;
      }
      apply();
    }

    function openFilterConfigModal() {
      return CoreService.openFilterConfigModal().then(() => {
        PageService.updateConfig({});
      });
    }

    function selectDateRange(range: any) {
      if (vm.queryParams.start_date && vm.queryParams.end_date) {
        vm.oldStartDate = vm.queryParams.start_date;
        vm.oldEndDate = vm.queryParams.end_date;
        vm.queryParams.start_date = undefined;
        vm.queryParams.end_date = undefined;
      }
      vm.queryParams.period = range.id;
      vm.periodTitle = range.title;

      vm.apply();
    }
  }
})();
