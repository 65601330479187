import template from './emails-edit-view.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const EmailsEditView = {
  bindings: {
    emails: '<',
    saveEmail: '&',
    deleteEmail: '&',
    notEditable: '@',
  },
  template,
  controller: [
    'GtUtils',
    class {
      GtUtils: GtUtilsService;
      deleteEmail: any;
      emails: any;
      saveEmail: any;
      constructor(GtUtils: GtUtilsService) {
        this.GtUtils = GtUtils;
      }

      $onInit() {
        this.emails = this.emails || [];
      }

      addEmail() {
        if (this.emails.filter((i: any) => !i.id).length) {
          return;
        }
        this.emails.push({ email: '' });
      }

      delete(email: any) {
        this.deleteEmail({ email: email }).then(() => this.removeEmailFromList(email));
      }

      save(email: any) {
        this.saveEmail({ email: email }).then((res: any) => {
          this.removeEmailFromList(email);
          this.emails.push(res.data);
        }, this.GtUtils.errorClb);
      }

      removeEmailFromList(email: any) {
        const idx = this.emails.indexOf(email);
        if (idx > -1) {
          this.emails.splice(idx, 1);
        }
      }
    },
  ],
};
