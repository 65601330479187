import template from './invoice-position-config-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { QueryParams } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const InvoicePositionConfigPage = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    'PageService',
    'FinancesService',
    'gettext',
    class {
      FinancesService: FinancesService;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      queryParams?: QueryParams = {};
      constructor(
        PageService: PageService,
        FinancesService: FinancesService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.FinancesService = FinancesService;
        this.gettext = gettext;

        this.filterLevel = '';
        this.queryParams = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'invoice-position-config-page';
        this.queryParams = this.queryParams ?? {};
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-payments-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.FinancesService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            search: true,
            nestedSelects: [
              {
                argument: 'invoice_type',
                items: [
                  { id: 'incoming', title: this.gettext('Incoming') },
                  { id: 'outgoing', title: this.gettext('Outgoing') },
                ],
                placeholder: this.gettext('Invoice type'),
              },
              {
                argument: 'contract_type',
                items: [
                  { id: 'sale', title: this.gettext('Sale') },
                  { id: 'purchase', title: this.gettext('Purcahse') },
                ],
                placeholder: this.gettext('Contract type'),
              },
              {
                argument: 'use',
                items: [
                  {
                    id: 'cargo',
                    title: this.gettext('Cargo'),
                  },
                  {
                    id: 'costs',
                    title: this.gettext('Costs'),
                  },
                  {
                    id: 'gains',
                    title: this.gettext('Gains'),
                  },
                  {
                    id: 'expenses',
                    title: this.gettext('Expenses'),
                  },
                  {
                    id: 'washout',
                    title: this.gettext('Washout'),
                  },
                  {
                    id: 'credit',
                    title: this.gettext('Credit'),
                  },
                  {
                    id: 'other',
                    title: this.gettext('Other'),
                  },
                ],
                placeholder: this.gettext('Use'),
              },
            ],
            multiSelects: [
              {
                argument: 'debit_account_list',
                placeholder: this.gettext('debit accounts'),
                resource: 'finances.FinanceAccount',
              },
              {
                argument: 'credit_account_list',
                placeholder: this.gettext('credit accounts'),
                resource: 'finances.FinanceAccount',
              },
              {
                argument: 'currency_list',
                placeholder: this.gettext('currencies'),
                resource: 'finances.Currency',
              },
              {
                argument: 'charge_list',
                placeholder: this.gettext('charges'),
                resource: 'finances.Charge',
              },
              {
                argument: 'expenses_option_list',
                placeholder: this.gettext('general expenses'),
                resource: 'finances.GeneralExpenses',
              },
              {
                argument: 'other_activity_list',
                placeholder: this.gettext('other activites'),
                resource: 'finances.OtherActivity',
              },
            ],
          },
        };
      }
    },
  ],
};
