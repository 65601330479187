import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './logistic-set-export-contract-container.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const LogisticSetExportContractContainer = {
  bindings: {
    selectedLogistics: '<',
    selectAll: '&',
    onFinish: '&',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gettext',
    'LogisticsService',
    'ContractsService',
    class {
      $scope: ng.IScope;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      businessUnitId?: number;
      buyerContractId?: number;
      cargoId?: number;
      chunkSize: any;
      cropId?: number;
      customStatusId?: number;
      data: any;
      error: any;
      excludeStatusList: any;
      exportContractId?: number;
      exporterId?: number;
      gettext: ng.gettext.gettextFunction;
      onFinish: any;
      passportData: any;
      passportId?: number;
      price: any;
      sale_price: any;
      selectAllChecked: any;
      selectedLogistics: any;
      supplierContractId?: number;
      terminalId?: number;
      updateFields: any;
      vehicle_type: any;
      volume_departed: any;
      volume_received: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        LogisticsService: LogisticsService,
        ContractsService: ContractsService,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.LogisticsService = LogisticsService;
        this.ContractsService = ContractsService;

        this.excludeStatusList = ['executed', 'cancelled'];

        this.selectedLogistics = [];
        this.selectAllChecked = false;
        this.data = {};
        this.error = false;
        this.updateFields = {};
        this.passportData = {};
        this.chunkSize = 50;
      }

      $onInit() {
        this.$scope.$watchCollection(
          () => this.selectedLogistics,
          () => this.updateData(),
        );
      }
      updatePassportData(passportId: number) {
        if (!passportId) {
          this.passportData = {};
          this.updateFields.unload_costs_t = 0;
          this.updateFields.unload_costs_unit = 0;
          return;
        }

        return this.ContractsService.Passport.get({
          serializer: 'modal',
          id: passportId,
        })
          .$promise.then((data: any) => {
            this.passportData = data;
            this.updateFields.unload_costs_t = data.unload_costs_t;
            this.updateFields.unload_costs_unit = data.unload_costs_unit;
            this.error = false;
          })
          .catch((data: any) => {
            this.error = true;
            this._error(data);
          });
      }
      updateData() {
        if (!this.selectedLogistics.length) {
          this.data = {};
          return;
        }

        const chunks: any = [];

        for (let i = 0; i < this.selectedLogistics.length; i += this.chunkSize) {
          chunks.push(this.selectedLogistics.slice(i, i + this.chunkSize));
        }

        const requests: any = chunks.map((chunk: any) => {
          return this.LogisticsService.Logistic.totalsInfo({
            id_list: chunk.map((logistic: any) => logistic.id),
          }).$promise;
        });

        return Promise.all(requests)
          .then((results) => {
            this.data = results.reduce(
              (acc, data) => {
                acc.volume_departed_sum += data.volume_departed_sum || 0;
                acc.volume_received_sum += data.volume_received_sum || 0;
                return acc;
              },
              { volume_departed_sum: 0, volume_received_sum: 0 },
            );

            this.error = false;
          })
          .catch((err) => this._error(err));
      }

      _error(data: any) {
        this.error = true;
        this.GtUtils.errorClb(data);
        this.GtUtils.overlay('hide');
      }

      getLogisticsToUpdate() {
        return this.selectedLogistics.map((item: any) => ({
          id: item.id,
          export_contract: this.exportContractId,
          buyer_contract: this.buyerContractId,
          supplier_contract: this.supplierContractId,
          business_unit: this.businessUnitId,
          custom_status: this.customStatusId,
          exporter: this.exporterId,
          shipment_terminal: this.terminalId,
          cargo: this.cargoId,
          price: this.price,
          sale_price: this.sale_price,
          volume_departed: this.volume_departed,
          volume_received: this.volume_received,
          vehicle_type: this.vehicle_type,
          ...this.updateFields,
        }));
      }
      close() {
        return this.onFinish();
      }
      sliceLogisticsData() {
        const logisticsData = this.getLogisticsToUpdate();
        const logisticChunks: any = [];
        for (let i = 0; i < logisticsData.length; i += this.chunkSize) {
          logisticChunks.push(logisticsData.slice(i, i + this.chunkSize));
        }
        return logisticChunks;
      }
      confirm() {
        this.GtUtils.overlay('show');
        const logisticChunks = this.sliceLogisticsData();
        const promises = logisticChunks.map((chunkData: any) => {
          return this.LogisticsService.Logistic.bulkCreateOrUpdate({ partial: true }, chunkData)
            .$promise;
        });
        return Promise.all(promises)
          .then(() => {
            notify(this.gettext('Updated.'));
            this.GtUtils.overlay('hide');
          })
          .catch((data) => this._error(data));
      }
    },
  ],
};
