import type ng from 'angular';
export class ModelFieldsService {
  $injector: ng.auto.IInjectorService;
  modelFieldMap: any;
  constructor($injector: ng.auto.IInjectorService) {
    this.$injector = $injector;

    this.modelFieldMap = null;
  }

  getModelFieldMap() {
    if (!this.modelFieldMap) {
      this.modelFieldMap = {
        'contracts.contracts': this.$injector.get<any>('ContractFormFieldsService'),
        'passports.passports': this.$injector.get<any>('PassportFormFieldsService'),
        'contracts.requests': this.$injector.get<any>('RequestFormFieldsService'),
        'logistics.customscargodeclaration': this.$injector.get<any>('CCDFormFieldsService'),
        'clients.clients': this.$injector.get<any>('ClientFormFieldsService'),
      };
    }
    return this.modelFieldMap;
  }

  getModelNames() {
    return Object.keys(this.getModelFieldMap());
  }

  getFieldService(modelName: string) {
    const modelFullName = this.getModelName(modelName);
    return modelFullName ? this.getModelFieldMap()[modelFullName] : null;
  }

  getModelName(shortModelName: string) {
    if (!shortModelName) {
      return 'N/A';
    }
    shortModelName = shortModelName.toLowerCase();
    const modelNames = this.getModelNames();
    if (modelNames.includes(shortModelName)) {
      return shortModelName;
    }
    return modelNames.filter((key) => shortModelName == key.split('.')[1]).shift();
  }
}
ModelFieldsService.$inject = ['$injector'];
