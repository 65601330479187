import type ng from 'angular';

import template from './passport-connection-shipment-plan-list-table-view.html?raw';
import type { PassportConnectionShipmentPlanService } from '../passport-connection-shipment-plan.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import { html } from '^/shared/utils';

export const PassportConnectionShipmentPlanListTableView = {
  bindings: {
    data: '<',
    count: '<',
    total: '<',
    filterLevel: '<?',
    contractId: '<',
    passportId: '<',
    openModal: '&',
    openCloneModal: '&',
    destroy: '&',
  },
  template,
  controller: [
    '$scope',
    'gettext',
    'GtUtils',
    'PassportConnectionShipmentPlanService',
    'gtFilterService',
    class {
      $scope: ng.IScope;
      PassportConnectionShipmentPlanService: PassportConnectionShipmentPlanService;
      GtUtils: GtUtilsService;
      gtFilterService: GtFilterService;
      count = 0;
      contractId!: number;
      passportId!: number;
      data: any;
      destroy: any;
      filterLevel = 'passport-connection-shipment-plan-list-table-view';
      gettext: ng.gettext.gettextFunction;
      openCloneModal: any;
      openModal: any;
      remove: any;
      save: any;
      tableData: any;
      tableOptions: any;
      total: any;
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        PassportConnectionShipmentPlanService: PassportConnectionShipmentPlanService,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.PassportConnectionShipmentPlanService = PassportConnectionShipmentPlanService;
        this.gtFilterService = gtFilterService;
      }

      $onInit() {
        this.queryParams = { contract: this.contractId };
        if (this.passportId) {
          this.queryParams.passport = this.passportId;
        }
        this.tableData = { rows: this.data, count: this.count, total: this.total };
        this.tableOptions = this.getTableOptions();

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data) => {
          this.queryParams = data.params;
          this.updateData();
        });
      }

      $onChanges(changes: any) {
        if (changes.data || changes.count || changes.total) {
          this.tableData = { rows: this.data, count: this.count, total: this.total };
        }
      }

      updateData() {
        this.GtUtils.overlay('show');

        this.queryParams = {
          ...this.queryParams,
          contract: this.contractId,
        };

        if (this.passportId) {
          this.queryParams.passport = this.passportId;
        }

        return this.PassportConnectionShipmentPlanService.getTableGroupedData(
          this.queryParams,
        ).then((data: any) => {
          this.tableData = {
            rows: this.getRows(data.data),
            count: data.count,
            total: this.getTotal(data.total),
          };
          this.GtUtils.overlay('hide');
        });
      }

      private getRows(data: any) {
        return data?.[0]?.data?.[0]?.data;
      }

      private getTotal(data: any) {
        return data?.[0]?.data?.[0];
      }

      applyFilters = (params: QueryParams) => {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      };

      getTableOptions() {
        return {
          tableName: `${this.filterLevel}-table`,
          rowData: this.data,
          tableClass: 'table table-responsive table-condensed main-table contract-charges-table',
          configurable: true,
          applyFilters: this.applyFilters,
          templateArgs: {
            save: (item: any) => this.save(item),
            remove: (item: any) => this.remove(item),
            openModal: (item: any) => this.openModal({ plan: item }),
            openCloneModal: (item: any) => this.openCloneModal({ plan: item }),
            destroy: (itemId: number) => this.destroy({ planId: itemId }),
          },
          columnDefs: [
            {
              columnName: 'actions',
              title: this.gettext('Actions'),
              class: 'td-left-align no-blur',
              cellTemplate: html`
                <a
                  class="btn btn-xs btn-add-dark"
                  ng-if="!args.readOnly"
                  permission
                  permission-only="'change_passportconnectionshipmentplan'"
                >
                  <i
                    class="fa fa-pencil-square"
                    ng-click="args.openModal(item)"
                    style="width: auto; cursor: pointer"
                  ></i>
                </a>
                <a
                  class="btn btn-xs btn-add-dark"
                  ng-if="!args.readOnly"
                  permission
                  permission-only="'add_passportconnectionshipmentplan'"
                >
                  <i
                    class="fa fa-files-o"
                    ng-click="args.openCloneModal(item)"
                    style="width: auto; cursor: pointer"
                  ></i>
                </a>
              `,
              totalTemplate:
                ' <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate>',
            },
            {
              columnName: 'commodityTitle',
              title: this.gettext('commodity'),
              class: 'td-left-align wrap-text',
              cellTemplate: html`{[{ item.commodity_title }]} `,
            },
            {
              columnName: 'businessunitName',
              title: this.gettext('businessunit'),
              class: 'td-left-align',
              cellTemplate: html`{[{ item.businessunit_name }]} `,
            },
            {
              columnName: 'measurementName',
              title: this.gettext('measurement'),
              class: 'td-left-align',
              cellTemplate: html`{[{ item.measurement_name }]} `,
            },
            {
              columnName: 'logisticTypeName',
              title: this.gettext('logistic type'),
              class: 'td-left-align',
              filters: [
                {
                  type: 'ui-multiselect',
                  predicate: 'logistic_type_list',
                  label: this.gettext('Logistic type'),
                  resource: 'logistics.logisticType',
                },
              ],
              cellTemplate: html`{[{ item.logistic_type_name }]} `,
            },
            {
              columnName: 'producerName',
              title: this.gettext('producer'),
              class: 'td-left-align wrap-text',
              filters: [
                {
                  type: 'ui-multiselect',
                  predicate: 'producer_list',
                  label: this.gettext('Producer'),
                  resource: 'clients.Client',
                  queryParams: { is_producer: '1' },
                },
              ],
              cellTemplate: html`{[{ item.producer_name }]} `,
            },
            {
              columnName: 'exporterName',
              title: this.gettext('exporter'),
              class: 'td-left-align wrap-text',
              filters: [
                {
                  type: 'ui-multiselect',
                  predicate: 'exporter_list',
                  label: this.gettext('Exporter'),
                  resource: 'clients.Exporter',
                },
              ],
              cellTemplate: html`
                <span ng-if="item.exporter_name"> {[{ item.exporter_name }]} </span>
              `,
            },
            {
              columnName: 'commodityShipperName',
              title: this.gettext('commodity shipper'),
              class: 'td-left-align wrap-text',
              cellTemplate: html`
                <span ng-if="item.commodity_shipper_name">
                  {[{ item.commodity_shipper_name }]}
                </span>
              `,
            },
            {
              columnName: 'commodityReceiverName',
              title: this.gettext('commodity_receiver'),
              class: 'td-left-align wrap-text',
              cellTemplate: html`
                <span ng-if="item.commodity_receiver_name">
                  {[{ item.commodity_receiver_name }]}
                </span>
              `,
            },
            {
              columnName: 'shipmentWarehouseName',
              title: this.gettext('shipment warehouse'),
              class: 'td-left-align wrap-text',
              filters: [
                {
                  type: 'ui-multiselect',
                  predicate: 'shipment_warehouse_list',
                  label: this.gettext('Shipment warehouse'),
                  resource: 'logistics.warehouse',
                },
              ],
              cellTemplate: html`
                <span ng-if="item.shipment_warehouse_name">
                  {[{ item.shipment_warehouse_name }]}
                </span>
              `,
            },
            {
              columnName: 'unloadingWarehouseName',
              title: this.gettext('unloading warehouse'),
              class: 'td-left-align wrap-text',
              filters: [
                {
                  type: 'ui-multiselect',
                  predicate: 'unloading_warehouse_list',
                  label: this.gettext('Unloading warehouse'),
                  resource: 'logistics.warehouse',
                },
              ],
              cellTemplate: html`
                <span ng-if="item.unloading_warehouse_name">
                  {[{ item.unloading_warehouse_name }]}
                </span>
              `,
            },
            {
              columnName: 'volume',
              title: this.gettext('Volume, mt'),
              class: 'td-right-align',
              cellTemplate: html`
                {[{ item.volume || 0 |number:3 }]} ({[{ item.quantity || 0 }]} {[{
                item.measurement_name }]})
              `,
              totalTemplate: html`{[{ item.volume || 0 |number:3 }]} `,
            },
            {
              columnName: 'discharge_approval_volume',
              title: this.gettext('Discharge approval volume, mt'),
              class: 'td-right-align',
              cellTemplate: html`{[{ item.discharge_approval_volume || 0 |number:3 }]} `,
              totalTemplate: html`{[{ item.discharge_approval_volume || 0 |number:3 }]} `,
            },
            {
              columnName: 'volumeDelivered',
              title: this.gettext('Volume delivered, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: html`{[{ item.execution_data_basis_volume || 0 |number:3 }]} `,
              totalTemplate: html`{[{ item.execution_data_basis_volume || 0 |number:3 }]} `,
            },
            {
              columnName: 'volumeRest',
              title: this.gettext('Volume rest, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: html`
                {[{ item.plan_volume_rest || 0 |number:3 }]} ({[{ item.plan_quantity_rest || 0
                |number:0 }]} {[{ item.measurement_name }]})
              `,
              totalTemplate: html`{[{ item.plan_volume_rest || 0 |number:3 }]} `,
            },
            {
              columnName: 'volumeAcceptedAtUnloading',
              title: this.gettext('Volume accepted at unloading, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: html`
                {[{ item.execution_data_export_invoice_volume || 0 |number:3 }]} ({[{
                item.execution_data_export_invoice_count || 0 }]})
              `,
              totalTemplate: html`
                {[{ item.execution_data_export_invoice_volume || 0 |number:3 }]} ({[{
                item.execution_data_export_invoice_count || 0 }]})
              `,
            },
            {
              columnName: 'volumeCCD',
              title: this.gettext('Customs declaration volume, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: html`{[{ item.plan_ccd_volume || 0 |number:3 }]} `,
              totalTemplate: html`{[{ item.plan_ccd_volume || 0 |number:3 }]} `,
            },
            {
              columnName: 'volumeRestPlanBeforeCCD',
              title: this.gettext('Volume rest plan before CD, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: html`{[{ item.plan_ccd_rest_plan_volume || 0 |number:3 }]} `,
              totalTemplate: html`{[{ item.plan_ccd_rest_plan_volume || 0 |number:3 }]} `,
            },
            {
              columnName: 'volumeRestFactBeforeCCD',
              title: this.gettext('Volume rest fact before CD, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: html`{[{ item.plan_ccd_rest_fact_volume || 0 |number:3 }]} `,
              totalTemplate: html`{[{ item.plan_ccd_rest_fact_volume || 0 |number:3 }]} `,
            },
            {
              columnName: 'volumeNormalWriteOff',
              title: this.gettext('Normal write-off volume, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: html`{[{ item.volume_normal_write_off || 0 | number:6 }]} `,
              totalTemplate: html`{[{ item.volume_normal_write_off || 0 |number:6 }]} `,
            },
            {
              columnName: 'quantityFact',
              title: this.gettext('Quantity fact'),
              class: 'td-right-align highlighted-td',
              cellTemplate: html`{[{ item.execution_data_count || 0 }]} `,
              totalTemplate: html`{[{ item.execution_data_count || 0 }]} `,
            },
            {
              columnName: 'purchaseContract',
              title: this.gettext('Purchase contract'),
              class: 'td-right-align highlighted-td',
              cellTemplate: html`{[{ item.purchase_contract_number || 0 }]} `,
            },
            {
              columnName: 'delivered',
              title: this.gettext('Logistic volume delivered, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: html`{[{ item.logistics_volume_departed || 0 }]} `,
              totalTemplate: html`{[{ item.logistics_volume_departed || 0 }]} `,
            },
          ],
        };
      }
    },
  ],
};
