import ng from 'angular';

import template from './pnl-mtm-consolidated-table.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const PnlMtmConsolidatedTable = {
  bindings: {
    filterLevel: '<?',
    applyContractFilters: '&',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    '$state',
    '$httpParamSerializer',
    'GtUtils',
    'gtFilterService',
    'PnlMtmService',
    class {
      $httpParamSerializer: any;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      GtUtils: GtUtilsService;
      PnlMtmService: any;
      applyContractFilters: any;
      contractQueryParams: QueryParams = {};
      filterLevel = 'pnl-mtm-consolidated-table';
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      realized: any;
      tableData: any;
      unrealized: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        $httpParamSerializer: any,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        PnlMtmService: any,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$httpParamSerializer = $httpParamSerializer;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.PnlMtmService = PnlMtmService;

        this.tableData = {};
        this.contractQueryParams = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'pnl-mtm-table';
        this.applyContractFilters = this.applyContractFilters || undefined;
        this.queryParams = { ...this.initQueryParams };

        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateTableData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyContractIdsFilter(cell: any, saleIds?: number[], purchaseIds?: number[]) {
        const params = {
          consolidated_basis_codename: cell.basis_codename,
          consolidated_date: cell.realized_date || cell.unrealized_date,
          id_list: [] as number[],
        };

        if (!cell.show) {
          ng.forEach(this.realized, (basis: any) => {
            ng.forEach(basis, (month: any) => {
              month.show = false;
            });
          });
          ng.forEach(this.unrealized, (basis: any) => {
            ng.forEach(basis, (month: any) => {
              month.show = false;
            });
          });
        }
        cell.show = !cell.show;
        if (cell.show) {
          params.id_list = ([] as number[]).concat(saleIds ?? [], purchaseIds ?? []);
        } else {
          params.id_list = [];
        }
        this.applyContractFilters({ params: params });
      }

      updateTableData() {
        this.GtUtils.overlay('show');
        this.tableData = this.PnlMtmService.getTableData(this.queryParams).then((data: any) => {
          this.tableData = data.results;
          this.realized = data.results.dict_data.realized;
          this.unrealized = data.results.dict_data.unrealized;
          this.GtUtils.overlay('hide');
        });
      }

      getInvoicePOsitionHref(contractIds: any) {
        return (
          this.$state.href('finances.invoicePositionPage') +
          '?use_list=washout&' +
          this.$httpParamSerializer({
            contract_list: contractIds,
          })
        );
      }
    },
  ],
};
