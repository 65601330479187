import ng from 'angular';

import template from './logentries-list.tpl.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';

(function () {
  'use strict';
  ng.module('accounts.legacy').directive('logentriesList', directive);

  function directive() {
    return {
      template,
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        queryParams: '=?',
        logEntries: '=?',
        logEntriesCount: '=?',
        filterLevel: '<?',
      },
    };
  }

  Controller.$inject = ['AccountsService'];

  function Controller(this: any, AccountsService: AccountsService) {
    const vm = this;
    vm.filterLevel = vm.filterLevel || 'logentries-list';

    activate();

    ////////////////

    function activate() {
      if (vm.queryParams) {
        updateData();
      }
    }

    function updateData() {
      return AccountsService.LogEntry.query(vm.queryParams, function (data: any) {
        vm.logEntries = data.results;
        vm.logEntriesCount = data.count;
      }).$promise;
    }
  }
})();
