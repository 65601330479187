import { Root } from '@radix-ui/react-toggle';
import React from 'react';

import { cn } from '~/shared/lib/utils';
import { CheckIcon } from '~/shared/ui/icons';

export const Chip = React.forwardRef<
  HTMLButtonElement,
  { children: React.ReactNode } & { selected?: boolean; onClick?: () => void }
>(({ children, selected, onClick }, ref) => {
  return (
    <Root
      className={cn(
        'flex items-center justify-center gap-1 rounded-sm border py-0.5 pr-2 pl-2 transition duration-300 ease-in-out',
        'border-stroke-main-medium bg-background-main-primary hover:border-stroke-main-strong hover:bg-background-main-secondary',
        selected &&
          'border-stroke-section-light bg-stroke-section-extraLight [&>p]:text-text-section-primary pl-1 [&>svg]:block',
        'dark:hover:border-stroke-main-on-primary-section dark:hover:bg-background-main-invertedSecondary dark:hover:text-text-main-inverted',
        selected &&
          'dark:[&>div]:text-text-main-on-primary-section dark:[&>svg]:text-text-main-on-primary-section',
        'disabled:hover:border-stroke-main-medium disabled:dark:hover:border-stroke-main-medium disabled:dark:hover:bg-background-main-primary disabled:cursor-not-allowed disabled:opacity-20',
      )}
      data-testid="chip"
      ref={ref}
      onClick={onClick}
    >
      <CheckIcon
        size={14}
        className="text-stroke-section-primary hidden"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <p className="text-text-main-primary text-xs font-normal">{children}</p>
    </Root>
  );
});

Chip.displayName = 'Chip';
