import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './screen-capture-task-modal.html?raw';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { NotificationService } from '^/app/crm/notifications/notifications.service';
import { html } from '^/shared/utils';

export const ScreenCaptureTaskModal = {
  bindings: {
    modalInstance: '<',
    currentPage: '<',
  },
  template,
  controller: [
    '$rootScope',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'NotificationService',
    class {
      $rootScope: GtRootScopeService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      NotificationService: NotificationService;
      currentPage: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      task: any;
      constructor(
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        NotificationService: NotificationService,
      ) {
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.NotificationService = NotificationService;

        this.form = undefined;
        this.fields = [];
      }

      $onInit() {
        this.task = {
          useremail: [{ email: this.$rootScope.user.email, recipient_type: 'to' }],
          ...this.currentPage,
        };
        this.updateFields();
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      saveScreenCaptureTask() {
        this.task.notification_time = this.dateToTime(this.task.notification_time);
        return this.NotificationService.saveScreenCaptureTask(this.task)
          .then((task: any) => task, this.GtUtils.errorClb)
          .finally(() => notify('Screen capture task saved'));
      }

      sendScreenShot() {
        this.saveScreenCaptureTask()
          .then((task: any) => this.NotificationService.doScreenCaptureTask(task.id))
          .then(() => notify('You will receive notification in 10 minutes'), this.GtUtils.errorClb)
          .finally(() => this.modalInstance.close());
      }

      dateToTime(date: any) {
        return date instanceof Date ? date.toLocaleTimeString() : undefined;
      }

      closeModal() {
        if (!confirm('Close modal?')) {
          return false;
        }
        return this.modalInstance.close();
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-md-12 col-xs-12',
          fieldGroup: [
            {
              wrapper: 'gt-panel',
              templateOptions: {},
              fieldGroup: [
                {
                  key: 'name',
                  type: 'gt-input',
                  templateOptions: {
                    label: this.gettext('Name'),
                    type: 'text',
                  },
                },
                {
                  key: 'author',
                  type: 'gt-ui-select',
                  defaultValue: this.$rootScope.user.id,
                  templateOptions: {
                    label: this.gettext('Author'),
                    placeholder: this.gettext('Author'),
                    resource: 'auth.User',
                  },
                },
                {
                  key: 'business_unit',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Business unit'),
                    placeholder: this.gettext('Business Unit'),
                    queryParams: this.GtUtils.getBUQueryParams(),
                    resource: 'core.BusinessUnit',
                  },
                },
                {
                  key: 'from_user',
                  type: 'gt-ui-select',
                  defaultValue: this.$rootScope.user.id,
                  templateOptions: {
                    required: true,
                    label: this.gettext('From user'),
                    placeholder: this.gettext('From user'),
                    resource: 'auth.User',
                  },
                },
                {
                  key: 'url',
                  type: 'gt-textarea',
                  templateOptions: {
                    required: true,
                    label: this.gettext('Page url'),
                    placeholder: this.gettext('Page url'),
                  },
                },
                {
                  key: 'scale',
                  type: 'gt-input',
                  defaultValue: 0.5,
                  templateOptions: {
                    type: 'number',
                    label: this.gettext('Page scale'),
                    placeholder: this.gettext('Page scale'),
                  },
                },
                {
                  key: 'telegram_chat',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Telegram Chat'),
                    placeholder: this.gettext('Telegram Chat'),
                    resource: 'notifications.telegramchat',
                  },
                },
                {
                  key: 'notification_time',
                  type: 'gt-date-select',
                  templateOptions: {
                    startView: 'hour',
                    minView: 'minute',
                    label: this.gettext('Notification time'),
                    placeholder: this.gettext('Pick a time'),
                  },
                },
                {
                  key: 'comment',
                  type: 'gt-textarea',
                  templateOptions: {
                    label: this.gettext('Comment'),
                    placeholder: this.gettext('Additional info for screenshot'),
                  },
                },
              ],
            },
          ],
        };

        const col2: any = {
          className: 'form-group-container col-md-12 col-xs-12',
          fieldGroup: [
            {
              wrapper: 'gt-panel',
              templateOptions: {},
              fieldGroup: [
                {
                  key: 'useremail',
                  template: html`<table
                      class="main-table table-template table-condensed contract-charges-table table"
                      style="width: 100% !important"
                    >
                      <thead>
                        <th><translate> Recipient type </translate></th>
                        <th><translate> Mail </translate></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr ng-repeat="user in model[options.key] track by $index">
                          <td>
                            <select
                              ng-model="user.recipient_type"
                              class="form-control gt_test_field"
                            >
                              <option value="to">To</option>
                              <option value="cc">Cc</option>
                              <option value="bcc">Bcc</option>
                            </select>
                          </td>
                          <td>
                            <input list="emails" class="form-control" ng-model="user.email" />
                            <datalist id="emails">
                              <option
                                class="form-control"
                                ng-repeat="item in emailList"
                                value="{[{item.email}]}"
                              ></option>
                            </datalist>
                          </td>
                          <td>
                            <a ng-click="delete($index)">
                              <span class="label label_default"><i class="fa fa-minus"></i> </span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <a class="btn-group pull-right" ng-click="add()">
                      <span class="label label_default"><i class="fa fa-plus"></i> </span>
                    </a>`,
                  templateOptions: {},
                  controller: [
                    '$scope',
                    'NotificationService',
                    function ($scope: ng.IScope, NotificationService: NotificationService) {
                      NotificationService.getUserEmails().then(
                        (data: any) => (($scope as any).emailList = data.results),
                      );
                      ($scope as any).add = () =>
                        ($scope as any).model.useremail.push({ recipient_type: 'to' });
                      ($scope as any).delete = ($index: number) =>
                        (($scope as any).model.useremail = ($scope as any).model.useremail.filter(
                          (_: any, index: number) => index !== $index,
                        ));
                    },
                  ],
                },
              ],
            },
          ],
        };

        return {
          formName: 'screen-capture-task-modal',
          fieldsDef: [col1, col2],
        };
      }
    },
  ],
};
