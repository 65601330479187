import { locationCitiesList, locationCitiesPredictionsRetrieve } from '~/shared/api';
import { EntityRepository, type Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { City, CityQueryParams } from '../lib';

export class CityRepository extends EntityRepository<City, CityQueryParams> {
  readonly entityName = 'location.City';

  getFn = (_id: number): Promise<City> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: City): Promise<City> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<City>): Promise<City> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: CityQueryParams): Promise<Paginated<City>> => {
    const { data } = await locationCitiesList({ query });
    return { records: data.results, count: data.count };
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await locationCitiesPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };
}
