import type ng from 'angular';

import template from './contract-signature-container.html?raw';
import type { ContractsService } from '../../contracts/legacy/contracts.srv';
import signatureStageFormTemplate from '../../contracts/legacy/stage-edit/stage-signature-edit.tpl.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';

export const ContractSignatureContainer = {
  bindings: {
    signatureStageId: '<',
    filterLevel: '<',
    openDocxModal: '&',
  },
  template,
  controller: [
    '$scope',
    '$uibModal',
    'gtFilterService',
    'ContractsService',
    class {
      $scope: ng.IScope;
      $uibModal: ng.ui.bootstrap.IModalService;
      ContractsService: ContractsService;
      filterLevel = 'contract-signature-container';
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      signatureStage: any;
      signatureStageId?: number;
      constructor(
        $scope: ng.IScope,
        $uibModal: ng.ui.bootstrap.IModalService,
        gtFilterService: GtFilterService,
        ContractsService: ContractsService,
      ) {
        this.$scope = $scope;
        this.$uibModal = $uibModal;
        this.gtFilterService = gtFilterService;
        this.ContractsService = ContractsService;

        this.signatureStage = {};
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.filterLevel = this.filterLevel || 'contract-signature-container';

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        if (!this.signatureStageId) {
          return null;
        }
        this.ContractsService.stageResource.query(
          { slug: 'signature', id: this.signatureStageId },
          (data: any) => {
            this.signatureStage = data;
          },
        );
      }

      getStatusClassLabel() {
        return (
          {
            finished: 'status_success',
            inactive: 'status_default',
            problem: 'status_exclamation',
            processing: 'status_exclamation',
            cancelled: 'status_danger',
          } as any
        )[this.signatureStage.status];
      }

      editStage() {
        this.$uibModal
          .open({
            template: signatureStageFormTemplate,
            controller: 'StageEditController as vm',
            windowClass: 'modal-template modal-template-half-width',
            resolve: {
              stage: () => {
                return { ...this.signatureStage };
              },
            },
          })
          .result.then(() => {
            this.updateData();
          });
      }
    },
  ],
};
