import template from './crop-quality-filter.html?raw';

export const CropQualityFilter = {
  bindings: {
    cropId: '<',
    onSelect: '&',
  },
  template,
  controller: [
    'CropsService',
    class {
      cropId?: number;
      cropsService: any;
      onSelect: any;
      qualityFilters: any;
      constructor(CropsService: any) {
        this.cropsService = CropsService;
        this.qualityFilters = [];
      }

      $onChanges(changes: any) {
        if (changes.cropId && this.cropId) {
          this.cropsService.CropQuality.query({ crop: this.cropId }, (data: any) => {
            this.qualityFilters = [];
            data.results.forEach((item: any) => {
              this.qualityFilters.push({ quality: item.quality, title: item.title });
            });
          });
        }
      }

      applyFilter() {
        const n = '';
        const selected = this.qualityFilters
          .filter((i: any) => [i.min, i.max, i.min_a, i.max_a].some((e) => e))
          .map(
            (i: any) =>
              `${i.quality || n}:${i.min || n}:${i.max || n}:${i.min_a || n}:${i.max_a || n}`,
          );
        this.onSelect({ $event: selected });
      }
    },
  ],
};
