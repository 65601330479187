import ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import type { GtUtilsService } from '../../gt-utils/gt-utils.srv';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtRootScopeService } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('core.legacy').controller('ColumnParamsModalController', Controller);

  Controller.$inject = [
    '$rootScope',
    '$uibModalInstance',
    '$filter',
    '$injector',
    'gettext',
    'tableOptions',
  ];
  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $filter: ng.IFilterService,
    $injector: ng.auto.IInjectorService,
    gettext: ng.gettext.gettextFunction,
    tableOptions: any,
  ) {
    const vm = this;

    vm.close = close;
    vm.save = save;
    vm.tableOptions = tableOptions;
    vm.columnParams = [];
    vm.filterPresets = [];
    vm.defaultConfig = {
      config_level: vm.tableOptions.filterLevel,
      column_params: [],
      title: 'New Config',
    };
    vm.filterPreset = { title: '' };
    vm.tableConfig = {};
    vm.columnParamsOrig = [];
    vm.overlay = false;
    vm.selectAll = selectAll;
    vm.getFilterPresets = getFilterPresets;
    vm.selectFilterPreset = selectFilterPreset;
    vm.saveFilterPreset = saveFilterPreset;
    vm.deleteFilterPreset = deleteFilterPreset;
    vm.isReportConfigTabVisible = isReportConfigTabVisible;
    vm.CoreService = $injector.get<any>('CoreService');
    vm.GtUtils = $injector.get<GtUtilsService>('GtUtils');
    vm.AccountsService = $injector.get<AccountsService>('AccountsService');
    vm.newTitle = '';
    vm.saving = false;

    vm.enableReportConfigFor = [
      'sale-contracts-page-view',
      'purchase-contracts-page-view',
      'intermediate-contracts-page-view',
      'export-contracts-page-view',
      'services-contracts-page-view',
      'export-contracts-page-view',
      'multicontract-sale-list-page',
      'multicontract-purchase-list-page',
      'multicontract-intermediate-list-page',
      'multicontract-export-list-page',
      'multicontract-services-list-page',
      'invoice-positions',
      'passports',
      'logistics-page-view',
      'finances',
      'intermediate-logistics',
      'contracts-contract-charges-list-table',
      'contract-charges-list',
      'passport-contract-charges-list-table',
    ];

    activate();

    ////////////////

    function activate() {
      updateData().catch(errorHandler);
    }

    function updateData() {
      vm.GtUtils.overlay('show');
      vm.saving = true;
      const userIds: number[] = [];
      return (vm.AccountsService as AccountsService).User.query({ page_size: 9999 }).then(
        (usersData) => {
          usersData.records.forEach((user) => {
            userIds.push(user.id);
          });
          return vm.CoreService.ColumnParams.query(
            {
              table_name: vm.tableOptions.tableName,
              has_columnssetconfig: 0,
            },
            (data: any) => {
              vm.columnParams = [];
              vm.tableOptions.columnDefs.forEach(function (column: any) {
                if (!column.columnName) {
                  return false;
                }
                const colParams = data
                  .filter((params: { column_name: string }) => {
                    return params.column_name == column.columnName;
                  })
                  .shift() || {
                  column_name: column.columnName,
                  table_name: vm.tableOptions.tableName,
                  index: null,
                  visible: $rootScope.user.settings.NEW_FIELD_VISIBILITY,
                  users: userIds,
                  isNew: true,
                };
                colParams.showAlways = column.showAlways;
                vm.columnParams.push(colParams);
              });

              vm.columnParams = $filter('orderBy')(vm.columnParams, 'index');
              vm.columnParams.map((column: any, index: number) => (column.index = index));
              vm.columnParamsOrig = JSON.parse(JSON.stringify(vm.columnParams));
              vm.defaultConfig.column_params = vm.columnParams.map((o: any) => ({
                ...o,
                visible: true,
              }));

              getFilterPresets();
            },
          );
        },
      );
    }

    function save() {
      try {
        validateForm(vm.columnParams);
      } catch (error: any) {
        return vm.notify(error.message, 'error');
      }

      let chain = Promise.resolve();
      vm.GtUtils.overlay('show');
      vm.saving = true;
      vm.columnParams
        .filter(function (item: any, index: number) {
          return (
            (!item.id && item.column_name) ||
            JSON.stringify(item) !== JSON.stringify(vm.columnParamsOrig[index])
          );
        })
        .forEach(function (params: { id: number }) {
          let saveFunc: any;
          if (params.id) {
            saveFunc = vm.CoreService.ColumnParams.update;
          } else {
            saveFunc = vm.CoreService.ColumnParams.save;
          }

          chain = chain.then(function () {
            return saveFunc(params, () => '', vm.GtUtils.errorClb).$promise;
          });
        });
      return chain
        .then(function () {
          close(false, true);
        })
        .finally(function () {
          vm.GtUtils.overlay('hide');
          vm.saving = false;
        });
    }

    function validateForm(columns: any) {
      const idx = columns.map((el: any) => el.index);
      if (idx.length !== new Set(idx).size) {
        throw Error('Duplicated indexes');
      }

      if (!columns.filter((el: any) => el.visible).length) {
        throw Error('You must leave some visible columns');
      }
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function selectAll(columns: any) {
      const show = columns.some(function (el: any) {
        return !el.showAlways && el.visible;
      });
      columns.forEach(function (el: any) {
        el.visible = el.showAlways || !show;
      });
    }

    vm.moveItem = function (position: number, newPosition: number, list: { index: number }[]) {
      const item = list[position];
      list.splice(position, 1);
      list.splice(newPosition, 0, item);
      list.filter(Boolean).forEach((i, idx) => (i.index = idx));
    };

    function getDefaultCongif() {
      const cleanObj = (object: any) =>
        Object.keys(object)
          .filter(
            (key) => object[key] && !['id'].includes(key) && !['columnssetconfig'].includes(key),
          )
          .reduce((obj, key) => ({ ...obj, [key]: object[key] }), {});

      return vm.columnParams.map((item: any) => ({
        ...cleanObj(item),

        table_name: vm.filterPreset.id
          ? `${item.table_name}_${vm.filterPreset.id}`
          : item.table_name,
      }));
    }

    function getFilterPresets() {
      vm.CoreService.ColumnsSetConfigs.query({
        config_level: vm.tableOptions.filterLevel,
      })
        .$promise.then((data: any) => {
          vm.filterPresets = [{ ...vm.defaultConfig }, ...data.results];
          selectFilterPreset();
          vm.GtUtils.overlay('hide');
          vm.saving = false;
        })
        .catch(vm.GtUtils.errorClb);
    }

    function saveFilterPreset() {
      try {
        if (vm.filterPreset) {
          validateForm(vm.filterPreset.column_params);
        }
      } catch (error: any) {
        return vm.notify(error.message, 'error');
      }

      vm.GtUtils.overlay('show');
      vm.saving = true;

      if (vm.filterPreset.id) {
        vm.CoreService.ColumnsSetConfigs.update({ ...vm.filterPreset })
          .$promise.then((data: any) => {
            vm.filterPreset = data;
            getFilterPresets();
            vm.notify(gettext('Report config updated'));

            $rootScope.$broadcast(`gt-report-config-updated_${vm.tableOptions.filterLevel}`, data);
          })
          .catch(vm.GtUtils.errorClb)
          .finally(() => {
            vm.GtUtils.overlay('hide');
            vm.saving = false;
          });
      } else {
        if (!vm.newTitle) {
          return vm.notify(gettext('Title can not be empty'), 'error');
        }

        if (
          vm.filterPresets
            .map((item: any) => item.title)
            .find((title: any) => title === vm.newTitle)
        ) {
          return vm.notify(gettext('Title must be unique'), 'error');
        }

        vm.CoreService.ColumnsSetConfigs.create({
          ...vm.filterPreset,
          title: vm.newTitle,
          config_level: vm.tableOptions.filterLevel,
        })
          .$promise.then((data: any) => {
            vm.filterPreset = data;
            getFilterPresets();

            $rootScope.$broadcast(`gt-report-config-created_${vm.tableOptions.filterLevel}`, data);

            vm.GtUtils.updateCahce('report_configs', vm.tableOptions.filterLevel, data.id);

            vm.notify(gettext('Report config created'));
          })
          .catch(vm.GtUtils.errorClb)
          .finally(() => {
            vm.GtUtils.overlay('hide');
            vm.saving = false;
          });
      }
    }

    function deleteFilterPreset() {
      vm.CoreService.ColumnsSetConfigs.delete({ id: vm.filterPreset.id })
        .$promise.then((data: any) => {
          vm.filterPreset = data;
          getFilterPresets();
          vm.notify(gettext('Report config deleted'));
        })
        .catch(vm.GtUtils.errorClb)
        .finally(() => vm.GtUtils.overlay('hide'));
    }

    function selectFilterPreset() {
      const { column_params: columnParams } = vm.filterPreset;
      vm.filterPreset.column_params = columnParams?.length
        ? vm.filterPreset.column_params
        : getDefaultCongif();
    }

    function isReportConfigTabVisible() {
      return vm.enableReportConfigFor.includes(vm.tableOptions.tableName);
    }
  }
})();
