import template from './warehouses-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const WarehousesPage = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gettext',
    'PageService',
    'LogisticsService',
    class {
      gettext: ng.gettext.gettextFunction;
      PageService: PageService;
      LogisticsService: LogisticsService;

      filterLevel: string;
      quickAdd = false;

      constructor(
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
        LogisticsService: LogisticsService,
      ) {
        this.LogisticsService = LogisticsService;
        this.PageService = PageService;
        this.gettext = gettext;
        this.filterLevel = 'warehouses-page';
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'warehouses-page';
        this.PageService.setConfig(this.getPageConfig());
      }
      getPageConfig() {
        const config: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          quickAdd: () => {
            this.quickAdd = !this.quickAdd;
          },
          pages: this.LogisticsService.getPagesConfig(),
        };

        config.filters = {
          groupBy: {},
          filterLevel: this.filterLevel,
          clicked: false,
          search: true,
          dates: true,
          useFlexDateRange: true,
          invert_filters: true,
          dateSelects: [],
          selects: [],
          nestedMultiSelects: [
            {
              argument: 'warehouse_type_list',
              placeholder: this.gettext('Warehouse type'),
              items: [
                { id: 'warehouse', title: this.gettext('Warehouse') },
                { id: 'farm', title: this.gettext('Farm') },
                { id: 'elevator', title: this.gettext('Elevator') },
                { id: 'terminal', title: this.gettext('Terminal') },
                { id: 'other', title: this.gettext('Other') },
              ],
            },
          ],
          multiSelects: [
            {
              argument: 'client_list',
              placeholder: this.gettext('Warehouse owner'),
              resource: 'clients.Client',
              related_fk: 'client',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'responsible_list',
              placeholder: this.gettext('responsible'),
              resource: 'clients.Person',
              related_fk: 'responsible',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'terminal_list',
              placeholder: this.gettext('Terminal'),
              resource: 'logistics.Terminal',
              related_fk: 'terminal',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'country_list',
              placeholder: this.gettext('Country'),
              resource: 'location.country',
              related_fk: 'address__country',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'region_list',
              placeholder: this.gettext('Region'),
              resource: 'location.region',
              related_fk: 'address__region',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'district_list',
              placeholder: this.gettext('District'),
              resource: 'location.district',
              related_fk: 'address__district',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'city_list',
              placeholder: this.gettext('city'),
              resource: 'location.city',
              related_fk: 'address__city',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'parent_list',
              placeholder: this.gettext('Warehouse parent'),
              resource: 'logistics.warehouse',
              related_fk: 'parent',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'warehouse_list',
              placeholder: this.gettext('Warehouse'),
              resource: 'logistics.warehouse',
              related_fk: 'id',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'port_list',
              placeholder: this.gettext('Port'),
              resource: 'logistics.port',
              related_fk: 'terminal__port_id',
              related_model: 'logistics.warehouse',
            },
          ],
        };
        return config;
      }
    },
  ],
};
