import type ng from 'angular';

import template from './core-inline-report-config-view.html?raw';
import type { GtUtilsService } from '../../legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '../../types';

export const CoreInlineReportConfigView = {
  bindings: {
    filterLevel: '<',
    choices: '<',
    reportConfigFilterLevel: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$location',
    'GtUtils',

    class {
      $location: ng.ILocationService;
      $rootScope: GtRootScopeService;
      GtUtils: GtUtilsService;
      choices: any;
      config: any;
      filterLevel = 'core-inline-report-config-view';
      reportConfigFilterLevel = '';
      constructor(
        $rootScope: GtRootScopeService,
        $location: ng.ILocationService,
        GtUtils: GtUtilsService,
      ) {
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.GtUtils = GtUtils;
        this.config = null;
      }

      $onInit() {
        const params = this.$location.search();
        const cachedReportConfigId = this.GtUtils.getCachedValue(
          'report_configs',
          this.reportConfigFilterLevel || this.filterLevel,
        );

        if (params.report_config || cachedReportConfigId) {
          this.config = this.choices.find(
            (item: any) => item.id == params.report_config || item.id === cachedReportConfigId,
          );
          this.selectConfig(this.config);
        }

        this.$rootScope.$on(
          `gt-report-config-created_${this.filterLevel}`,
          (event: any, config: any) => {
            this.selectConfig(config);
          },
        );

        this.$rootScope.$on(
          `gt-report-config-updated_${this.filterLevel}`,
          (event: any, config: any) => {
            if (this.config && this.config.id === config.id) {
              this.selectConfig(config);
            }
          },
        );
      }

      updateConfig() {
        this.$rootScope.$broadcast(`gt-report-config-selected_${this.filterLevel}`, this.config);

        this.GtUtils.updateCache(
          'report_configs',
          this.filterLevel,
          this.config ? this.config.id : null,
        );
      }

      selectConfig(choice: any) {
        this.config = choice;
        const params = this.$location.search();
        params.report_config = choice ? choice.id : null;
        this.$location.search(params);
        this.updateConfig();
      }

      unselectConfig() {
        this.config = null;
        const params = this.$location.search();
        delete params.report_config;
        this.GtUtils.updateCache('report_configs', this.filterLevel, {});
        this.$location.search(params);
        this.updateConfig();
      }
    },
  ],
};
