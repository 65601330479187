import ng from 'angular';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('company.legacy').controller('TraderTeamController', Controller);

  Controller.$inject = [
    '$rootScope',
    '$stateParams',
    'PageService',
    'TeamService',
    'AccountsService',
    'GtUtils',
    'LogisticsService',
    'GtSidebarListService',
    'gettext',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $stateParams: ng.ui.IStateParamsService,
    PageService: PageService,
    TeamService: any,
    AccountsService: AccountsService,
    GtUtils: GtUtilsService,
    LogisticsService: LogisticsService,
  ) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.trader = {};
    vm.tab = $stateParams.tab || 'settings';
    vm.contractsTab = 'sale';
    vm.clientsCrops = [];
    vm.voyages = [];
    vm.updateClientsCrops = updateClientsCrops;
    vm.updateVoyages = updateVoyages;

    // history
    vm.logentriesCount = 0;
    vm.openUserModal = openUserModal;

    activate();

    ////////////////

    function activate() {
      const config = PageService.getConfig();
      if (config.is_detail_page) {
        PageService.updateConfig({
          pages: [],
        });
      } else {
        PageService.setConfig({
          is_detail_page: true,
          pages: [],
        });
      }
      updateData();
    }

    function updateData() {
      GtUtils.overlay('show');
      return TeamService.Trader.get({ id: $stateParams.id }, function (data: any) {
        vm.trader = data;
        PageService.setPageTitle(vm.trader.first_name + ' ' + vm.trader.last_name);
        const filterLevel = 'users-page-view';
        const config = PageService.getConfig();
        if (config.is_detail_page) {
          PageService.updateConfig({
            sidebar: {
              verboseName: 'Users',
              modelName: 'auth.user',
              instance: vm.trader,
              filterLevel: filterLevel,
              ...($rootScope.user.perms.includes('view_user')
                ? { is_open: false }
                : { is_open: true }),
            },
          });
        } else {
          PageService.setConfig({
            is_detail_page: true,
            sidebar: {
              verboseName: 'Users',
              modelName: 'auth.user',
              instance: vm.trader,
              filterLevel: filterLevel,
              ...($rootScope.user.perms.includes('view_user')
                ? { is_open: false }
                : { is_open: true }),
            },
            pages: [],
          });
        }
        GtUtils.overlay('hide');
      });
    }

    function updateClientsCrops() {
      GtUtils.overlay('show');
      return TeamService.Trader.clients_crops({ id: $stateParams.id }, function (data: any) {
        vm.clientsCrops = data;
        GtUtils.overlay('hide');
      });
    }

    function updateVoyages(params: QueryParams) {
      GtUtils.overlay('show');
      params = { ...params };
      params.author = $stateParams.id;
      return LogisticsService.Voyage.query(params, function (data: any) {
        vm.voyages = data.results;
        vm.voyagesCount = data.count;
        GtUtils.overlay('hide');
      }).$promise;
    }

    function openUserModal(item: any) {
      return AccountsService.userModal(item).then(() => updateData());
    }
  }
})();
