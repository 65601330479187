import React from 'react';

import { cn } from '~/shared/lib/utils';
import { ChevronRightIcon } from '~/shared/ui/icons';

export const ExpandMenuItems: React.FC<{
  id: string;
  isOpen: boolean;
  isActive: boolean;
  label: string;
  onClick: (value: string) => void;
}> = ({ isOpen, label, onClick, id, isActive }) => {
  return (
    <div className="relative flex w-full flex-col gap-0.5 pt-0.5">
      <button
        className="group text-text-main-secondary hover:bg-transparent-light flex w-full cursor-pointer items-center gap-2 rounded-sm py-0.5 pl-4 font-normal"
        onClick={() => onClick(id)}
      >
        <ChevronRightIcon
          size={16}
          className={cn(
            'text-stroke-main-strong hover:bg-background-main-quarternary group-hover:text-stroke-main-stronger rounded transition duration-300 ease-in-out',
            (isOpen || isActive) && 'text-stroke-main-inverted rotate-90',
          )}
        />
        <span
          className={cn(
            'text-text-main-secondary group-hover:text-text-main-secondary text-xs font-normal transition duration-300 ease-in-out',
            (isOpen || isActive) && 'text-text-main-primary font-medium',
          )}
        >
          {label}
        </span>
      </button>
    </div>
  );
};
