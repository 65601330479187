import React from 'react';

import { formatDateRange } from '~/shared/lib/date';
import { cn } from '~/shared/lib/utils';
import { DateRangePicker } from '~/shared/ui/components/date-range-picker';
import type { DataTypeProps, DateRange } from '~/shared/ui/data-types';
import { messageIntent } from '~/shared/ui/data-types';
import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';
import { Tooltip } from '~/shared/ui/kit/tooltip';

export const DateRangeWrite: React.FC<DataTypeProps<'daterange'>> = ({
  value,
  disabled,
  message,
  onChange,
  typeExtra,
}) => {
  const format = typeExtra?.print ?? 'dd/MM/yy';

  const formattedDateRange = React.useMemo(() => formatDateRange(value, format), [value, format]);

  if (disabled) {
    return <p className="text-text-main-primary px-3 text-xs opacity-70">{formattedDateRange}</p>;
  }

  const inputElement = (
    <Popover>
      <PopoverTrigger
        className={cn(
          'text-text-main-secondary flex h-full min-h-[32px] w-full items-center rounded-sm px-2 py-1 text-left',
          message ? 'border-stroke-additional-danger border-b-2' : 'border-transparent',
        )}
      >
        {formattedDateRange}
      </PopoverTrigger>
      <PopoverContent className="w-full border-none p-0">
        <DateRangePicker
          dates={value ?? {}}
          datesChanged={(dates?: DateRange) => {
            onChange?.(dates);
          }}
        />
      </PopoverContent>
    </Popover>
  );

  return (
    <div className={cn('relative w-full', message && messageIntent[message.type])}>
      {message ? (
        <Tooltip content={message.text ?? ''} side="bottom" sideOffset={4}>
          <div className="w-full">{inputElement}</div>
        </Tooltip>
      ) : (
        inputElement
      )}
    </div>
  );
};
