import type ng from 'angular';

import { type ContractsService } from './legacy/contracts.srv';
import { type GtRootScopeService } from '../../core/types';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { html } from '^/shared/utils';

export class ContractChargeFormFieldsService {
  static readonly $inject = [
    '$rootScope',
    '$window',
    'gettext',
    'GtUtils',
    'ContractsService',
    'FormFieldParamsService',
    'AccountsService',
  ];
  defaultCurrency: any;
  defaultVatValue: any;
  private isManualPriceOverride = false;

  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly $window: ng.IWindowService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly GtUtils: GtUtilsService,
    private readonly ContractsService: ContractsService,
    private readonly FormFieldParamsService: FormFieldParamsService,
    private readonly AccountsService: AccountsService,
  ) {
    this.defaultCurrency = this.$rootScope.user.settings.DEFAULT_CURRENCY_OBJECT;
    this.defaultVatValue = this.$rootScope.user.settings.CHARGE_VAT_VALUE;
  }

  calculateChargePrice(charge: any, perVolume: string) {
    if (!this.isManualPriceOverride) {
      const newPrices = this.ContractsService.calcContractChargePrice(charge, perVolume);
      Object.keys(newPrices).forEach((key) => (charge[key] = newPrices[key]));
    }

    if (perVolume !== 'manual') {
      this.isManualPriceOverride = false;
    }
  }
  handleManualPriceChange() {
    this.isManualPriceOverride = true;
  }
  vatOptionChanged(charge: any) {
    charge.vat_value = charge.vat_option ? this.defaultVatValue : 0;
  }
  getFieldGroups(charge: any, exchangeFieldRequired?: any) {
    let isExchangeFieldRequired = exchangeFieldRequired;
    if (isExchangeFieldRequired === undefined) {
      this.FormFieldParamsService.isRequiredCurrencyExchangeField(
        'contract-charge-edit-modal',
        charge?.business_unit,
      ).then((required: any) => (isExchangeFieldRequired = required === undefined || required));
    }
    return {
      TYPE: [
        {
          key: 'charge',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Charge'),
            resource: 'finances.Charge',
            required: true,
            hint: this.gettext('Choose from predefined list of types. To add new one click +'),
            addFunc: () => {
              this.$window.open('/admin/finances/charge/add/', '_blank');
            },
            queryParams: () => ({
              tariff_use_list: charge.predictionsQueryParams?.tariff_use_list,
            }),
            addPerms: ['add_charge'],
            addIcon: 'fa fa-money',
          },
        },
        {
          key: 'is_gain',
          type: 'gt-checkbox',
          defaultValue: false,
          templateOptions: {
            label: this.gettext('Is gain'),
          },
        },
        {
          key: 'status',
          type: 'gt-select',
          defaultValue: 'forecast',
          templateOptions: {
            label: this.gettext('Status'),
            placeholder: this.gettext('Choose status'),
            valueProp: 'value',
            labelProp: 'name',
            hint: this.gettext(
              'If you want so set negotiation process and defined whether charge is validated or under discussion',
            ),
            options: [
              { value: 'validated', name: this.gettext('Validated') },
              { value: 'pending_confirmation', name: this.gettext('Pending confirmation') },
              { value: 'under_discussion', name: this.gettext('Under discussion') },
              { value: 'forecast', name: this.gettext('Forecast') },
              { value: 'planned', name: this.gettext('Planned') },
              { value: 'cancelled', name: this.gettext('Cancelled') },
              { value: 'closed', name: this.gettext('Closed') },
            ],
          },
        },
        {
          key: 'commodity',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Product'),
            resource: 'crops.Crop',
            hint: this.gettext('Choose from existing products. To add new one click +'),
            addFunc: () => {
              this.$window.open('/admin/crops/crop/add/', '_blank');
            },
            addPerms: ['add_crop'],
            addIcon: 'fa fa-wheat-awn',
          },
          hideExpression: () => this.$rootScope.user.settings.ENABLE_INLINE_ADD,
        },
        {
          key: 'date',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Expected month of charge'),
            placeholder: this.gettext('date'),
            hint: this.gettext('Pick here a date, when you expect to have this charge'),
            addIcon: 'fa fa-calendar',
            minView: 'month',
            startView: 'month',
          },
          hideExpression: () => this.$rootScope.user.settings.ENABLE_INLINE_ADD,
        },
        {
          key: 'customs_declaration',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Customs declaration'),
            resource: 'logistics.CustomsCargoDeclaration',
            hint: this.gettext('Choose from existing customs declarations. To add new one click +'),
            addFunc: () => {
              this.$window.open('/admin/logistics/customscargodeclaration/add/', '_blank');
            },
            queryParams: () => ({
              export_contract: charge.contract,
            }),
            addPerms: ['add_customscargodeclaration'],
          },
          hideExpression: () => this.$rootScope.user.settings.ENABLE_INLINE_ADD,
        },
        {
          key: 'expected_date_of_charge',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Expected date of charge'),
            placeholder: this.gettext('date'),
            addIcon: 'fa fa-calendar',
            minView: 'day',
            startView: 'day',
          },
        },
      ],
      AMOUNT: [
        {
          key: 'currency',
          type: 'gt-ui-select',
          defaultValue: this.defaultCurrency.id,
          templateOptions: {
            label: this.gettext('Currency'),
            resource: 'finances.currency',
            addIcon: 'fa fa-dollar',
            addPerms: ['add_currency'],
            addFunc: () => {
              this.$window.open('/admin/finances/currency/add/');
            },
          },
        },
        {
          key: 'currency_exchange',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Currency exchange'),
            resource: 'finances.currencyexchange',
            help: this.gettext('Set if only you want to convert amount in USD by a certain rate'),
            addFunc: () => {
              this.$window.open('/admin/finances/currencyexchange/add/');
            },
            addIcon: 'fa fa-money',
            addPerms: ['add_currencyexchange'],
            queryParams: () => ({
              local_currency: charge.currency,
            }),
          },
          expressionProperties: {
            'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
              isExchangeFieldRequired && scope.model.currency !== this.defaultCurrency.id,
          },
        },
        {
          key: 'price',
          type: 'gt-input',
          defaultValue: charge.price,
          templateOptions: {
            label: this.gettext('Amount per whole deal'),
            help: this.gettext('Total absolute value of charge'),
            type: 'number',
            onChange: () => {
              this.handleManualPriceChange();
              this.calculateChargePrice(charge, '');
            },
          },
        },
        {
          key: 'price_t',
          type: 'gt-input',
          defaultValue: charge.price_t,
          templateOptions: {
            label: this.gettext('Amount per 1 t'),
            help: this.gettext(
              'Value of charge per 1 metric ton. Will be converted to total amount automatically, based on contract quantity',
            ),
            type: 'number',
            onChange: () => {
              this.calculateChargePrice(charge, 't');
            },
          },
        },
        {
          key: 'percentage',
          type: 'gt-input',
          defaultValue: charge.percentage,
          templateOptions: {
            label: this.gettext('% from the deal'),
            help: this.gettext(
              'Value of charge in %. Will be converted to total amount automatically, based on contract amount',
            ),
            type: 'number',
            onChange: () => {
              this.calculateChargePrice(charge, '');
            },
          },
        },
        {
          key: 'volume',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Volume in mt'),
            hint: this.gettext('Set it only if volume of charge differs from contract volume'),
            type: 'number',
          },
        },
        {
          key: 'vat_option',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('VAT'),
            onChange: () => {
              this.vatOptionChanged(charge);
            },
          },
        },
        {
          key: 'vat_value',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('VAT'),
            type: 'number',
          },
          hideExpression: () => !charge.vat_option,
        },
        {
          key: 'counterparty_amount',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Counterparty amount'),
            type: 'number',
          },
        },
        {
          key: 'our_amount',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Our amount'),
            type: 'number',
          },
        },
        {
          key: 'final_amount',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Final Amount'),
            type: 'number',
          },
        },
        {
          key: 'analytical_amount',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Analytical Amount'),
            type: 'number',
          },
        },
      ],
      COUNTERPARTY: [
        {
          key: 'clientrole',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Counterparty'),
            help: this.gettext(
              'Company with whom we have these charge or gain, not a contract counterparty',
            ),
            queryParams: () => ({ for_charge: charge.charge }),
            resource: 'clients.clientrole',
          },
        },
        {
          key: 'responsible',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Responsible'),
            resource: 'auth.User',
            onSelect: () => {
              this.AccountsService.User.get(charge.responsible)
                .then((userDetails) => {
                  charge.responsibleWorkStatus = userDetails.profile.work_status;
                  return userDetails;
                })
                .catch(this.GtUtils.errorClb);
            },
          },
        },
        {
          template: html`
            <div style="margin: 8px 0">
              <work-status-display
                status="to.responsibleWorkStatus"
              </work-status-display>
            </div>
          `,
          hideExpression: () =>
            !charge.responsible || !this.$rootScope.user.settings.USE_WORK_STATUS,
          expressionProperties: {
            'templateOptions.responsibleWorkStatus': (
              viewValue: any,
              modelValue: any,
              scope: any,
            ) => {
              return scope.model.responsibleWorkStatus;
            },
          },
          defaultValue: charge.responsible
            ? this.AccountsService.User.get(charge.responsible)
                .then((userDetails) => {
                  charge.responsibleWorkStatus = userDetails.profile.work_status;
                  return userDetails;
                })
                .catch(this.GtUtils.errorClb)
            : null,
        },
      ],
      ALLOCATION: [
        {
          key: 'contract',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Contract'),
            resource: 'contracts.ContractBase',
            help: this.gettext(
              'Appoint to which CONTRACT you want to allocate this charge or gain',
            ),
            onSelect: () => {
              charge.contracts = [];
            },
            queryParams: () => ({
              multicontract: charge.multicontract || undefined,
              with_passport: charge.with_passport,
              id_list: charge.contract,
            }),
          },
          hideExpression: () =>
            Boolean(charge.passport) && this.$rootScope.user.settings.PASSPORT_COSTS_VALIDATE,
          expressionProperties: {
            'templateOptions.disabled': () =>
              Boolean(charge.passport) || Boolean(charge.multicontract),
          },
        },
        {
          key: 'contracts',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Contracts'),
            resource: 'contracts.ContractBase',
            help: this.gettext(
              'Appoint to which CONTRACT you want to allocate this charge or gain',
            ),
            queryParams: { with_passport: charge.passport },
          },
          expressionProperties: {
            'templateOptions.disabled': () => !charge.passport || charge.multicontract,
          },
          hideExpression: () =>
            Boolean(charge.contract) && this.$rootScope.user.settings.PASSPORT_COSTS_VALIDATE,
        },
        {
          key: 'passport',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
            help: this.gettext(
              'Appoint to which PASSPORT you want to allocate this charge or gain in cases when contract has more than one passport and charge has to be allocated to a specific one. In other cases leave these field blank',
            ),
            resource: 'passports.passport',
            queryParams: { id_list: charge.passport },
          },
          hideExpression: () =>
            Boolean(charge.contract) && this.$rootScope.user.settings.PASSPORT_COSTS_VALIDATE,
          expressionProperties: {
            'templateOptions.disabled': () =>
              Boolean(charge.contract) || Boolean(charge.multicontract),
          },
        },
        {
          key: 'logistic',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Logistic'),
            resource: 'logistics.logistic',
          },
        },
        {
          key: 'multicontract',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Multicontract'),
            resource: 'contracts.multicontract',
            queryParams: () => ({ with_passport: charge.with_passport }),
          },
          expressionProperties: {
            'templateOptions.disabled': () => Boolean(charge.passport) || Boolean(charge.contract),
          },
        },
      ],
      NOTE: [
        {
          key: 'additional_info',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Additional Info'),
            placeholder: this.gettext('Specific information about this object'),
            className: 'additional-info',
          },
        },
        {
          key: 'trader_note',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Trader note'),
            placeholder: this.gettext('Specific information about this object'),
          },
        },
      ],
      CUSTOM_VALUES: [
        {
          template: html`
            <custom-values-container
              filter-level="'contractcharge-custom-values-container'"
              init-query-params="{
                      object_id: model.id,
                      purpose: model.content_type,
                      purpose_model: 'contractcharge',
                    }"
              mode="model.id ? 'edit' : 'create'"
              object-id="model.id"
            ></custom-values-container>
          `,
        },
      ],
    };
  }
  getFormConfig(charge: any) {
    const fieldGroups = this.getFieldGroups(charge);

    return {
      formName: 'contract-charge-edit-modal',
      fieldsDef: [
        {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [
            {
              templateOptions: { label: 'TYPE' },
              wrapper: 'gt-panel',
              fieldGroup: fieldGroups.TYPE,
            },
          ],
        },
        {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [
            {
              templateOptions: { label: 'AMOUNT' },
              wrapper: 'gt-panel',
              fieldGroup: fieldGroups.AMOUNT,
            },
          ],
        },
        {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [
            {
              templateOptions: { label: 'COUNTERPARTY' },
              wrapper: 'gt-panel',
              fieldGroup: fieldGroups.COUNTERPARTY,
            },
          ],
        },
        {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [
            {
              templateOptions: { label: 'ALLOCATION' },
              wrapper: 'gt-panel',
              fieldGroup: fieldGroups.ALLOCATION,
            },
            {
              templateOptions: { label: 'NOTE' },
              wrapper: 'gt-panel',
              fieldGroup: fieldGroups.NOTE,
            },
            {
              templateOptions: { label: '' },
              wrapper: 'gt-panel',
              fieldGroup: fieldGroups.CUSTOM_VALUES,
            },
          ],
        },
      ],
    };
  }

  getFields(charge: any, formConfig: any) {
    if (!formConfig) {
      formConfig = this.getFormConfig(charge);
    }
    return this.FormFieldParamsService.getFields(formConfig);
  }
  openFieldsConfigModal(charge: any) {
    return this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig(charge));
  }
}
