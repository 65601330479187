import ng from 'angular';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('accounts.legacy').controller('ApproversModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    'GtUtils',
    'AccountsService',
    'objectId',
    'contentType',
    'permission',
    'gettext',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    GtUtils: GtUtilsService,
    AccountsService: AccountsService,
    objectId: number,
    contentType: any,
    permission: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.approvals = [];
    vm.close = close;
    vm.save = save;

    updateData();

    ////////////////

    function updateData() {
      AccountsService.getApprovals({
        object_id: objectId,
        content_type: contentType,
      }).then((data: any) => {
        vm.approvals = data.results;
        AccountsService.User.query({ page_size: 9999, has_perm: permission })
          .then((userData: any) => {
            userData.results
              .filter((user: any) => !vm.approvals.map((appr: any) => appr.user).includes(user.id))
              .forEach((user: any) => {
                vm.approvals.push({
                  user: user.id,
                  user_name: user.first_name + ' ' + user.last_name,
                  object_id: objectId,
                  content_type: contentType,
                });
              });
            vm.approvals = vm.approvals.map((appr: any) => ({
              ...appr,
              _checked: Boolean(appr.id),
            }));
          })
          .catch(GtUtils.errorClb);
      });
    }

    function save() {
      let chain = Promise.resolve();
      ng.forEach(vm.approvals, function (approval: any) {
        if (
          !approval._dirty ||
          (approval._checked && approval.id) ||
          (!approval._checked && !approval.id)
        ) {
          return;
        }
        chain = chain.then(function () {
          if (!approval._checked) {
            return AccountsService.deleteApproval(approval);
          } else {
            return AccountsService.saveApproval(approval);
          }
        });
      });
      return chain.then(function () {
        close(null, true);
      });
    }

    function close(data: any, silent: any) {
      if (silent || confirm(gettext('Close modal?'))) {
        $uibModalInstance.close(data || 'cancel');
      }
    }
  }
})();
