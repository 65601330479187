import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('clientCommentariesContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
    },
    template: require('./client-commentaries-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'gettext', 'ClientsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    ClientsService: any,
  ) {
    const vm = this;
    vm.edit = false;
    vm.updateData = updateData;
    vm.saveCommentary = saveCommentary;
    vm.deleteCommentary = deleteCommentary;
    vm.clientCommentaries = [];
    vm.clientCommentariesCount = 0;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'client-commentaries-container';
      vm.queryParams = { ...vm.initQueryParams };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateData();
      });
      updateData();
    };

    ////////////////

    function updateData() {
      ClientsService.ClientCommentary.query(vm.queryParams, function (data: any) {
        vm.clientCommentaries = data.results;
        vm.clientCommentaries.unshift({
          client: vm.queryParams.client,
          edit: true,
        });
        vm.clientCommentariesCount = data.count;
      });
    }

    function saveCommentary(commentary: any) {
      if (!commentary.id) {
        return ClientsService.ClientCommentary.save(commentary, successClb, (e: any) =>
          GtUtils.errorClb(e),
        );
      }
      return ClientsService.ClientCommentary.update(commentary, successClb, (e: any) =>
        GtUtils.errorClb(e),
      );
    }

    function deleteCommentary(commentary: any) {
      return ClientsService.ClientCommentary.delete(commentary, deleteClb, (e: any) =>
        GtUtils.errorClb(e),
      );
    }

    function successClb() {
      notify(gettext('Commentary successfully saved.'));
      updateData();
    }

    function deleteClb() {
      notify(gettext('Commentary successfully deleted.'));
      updateData();
    }
  }
})();
