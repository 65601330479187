import template from './loyality-program-data.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';

export const LoyalityProgramData = {
  bindings: {
    obj: '<',
    dateFrom: '<',
    dateTo: '<',
    bonusPercentage: '<',
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'LoyalityProgramDataService',
    class {
      $scope: ng.IScope;
      LoyalityProgramDataService: any;
      bonusPercentage: any;
      dateFrom: any;
      dateTo: any;
      filterLevel = 'loyality-program-data';
      firstLoad: any;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      obj: any;
      premiumData: any;
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        LoyalityProgramDataService: any,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.LoyalityProgramDataService = LoyalityProgramDataService;

        this.obj = {};
        this.premiumData = [];
        this.dateFrom = undefined;
        this.dateTo = undefined;
        this.firstLoad = true;
        this.bonusPercentage = 0;
      }

      $onInit() {
        this.queryParams = { page_size: 20, ...this.initQueryParams };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updatePremiumData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);

        this.$scope.$watch(
          () => this.bonusPercentage,
          () => this.updatePremiumData(),
        );
        this.$scope.$watch(
          () => this.obj.cargo,
          () => this.updatePremiumData(),
        );
        this.$scope.$watch(
          () => this.obj.contract_option,
          () => this.updatePremiumData(),
        );
        this.$scope.$watch(
          () => this.obj.volume,
          () => this.updatePremiumData(),
        );
        this.$scope.$watch(
          () => this.obj.price,
          () => this.updatePremiumData(),
        );
      }

      $onChanges(changes: any) {
        if (changes.dateFrom || changes.dateTo) {
          if (this.firstLoad) {
            this.firstLoad = false;
            return;
          }
          this.updatePremiumData();
        }
      }

      updatePremiumData() {
        this.LoyalityProgramDataService.fetchPremiumData(
          this.obj,
          this.dateFrom,
          this.dateTo,
          this.bonusPercentage,
        ).then((data: any) => {
          if (data) {
            this.premiumData = data?.results;
          }
        });
      }
    },
  ],
};
