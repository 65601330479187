import { serializeFieldValue } from './records';
import type { Selection } from '../components/dataset/dataset.model';
import type { DataParams, DataRecord, FieldDef } from '../lib/types';

export const parsePasteData = (data: string) => {
  const result = data
    .replace(/\r\n?/g, '\n')
    .split('\n')
    .map((row) => (row.length === 0 ? [''] : row.split('\t').map((cell) => cell.trim())));

  if (result.length > 256) {
    throw new Error('You can paste up to 256 rows at a time');
  }

  const columnsCount = result[0].length;
  if (!result.every((row) => row.length === columnsCount)) {
    console.warn(result);
    throw new Error('All rows must have the same number of columns');
  }

  return result;
};

export const serializeCopyData = async <R extends DataRecord, P extends DataParams>(
  records: R[],
  fields: FieldDef<R, P>[],
) => {
  const rows: string[] = [];
  for (const record of records) {
    const row: string[] = [];
    for (const field of fields) {
      const value = await serializeFieldValue(record, field);
      row.push(value);
    }
    rows.push(row.join('\t'));
  }
  return rows.join('\n');
};

export const getSelected = <R extends DataRecord, P extends DataParams>(
  selection: Selection,
  records: R[],
  fields: FieldDef<R, P>[],
): { records: R[]; fields: FieldDef<R, P>[]; cells: [number, string][] } => {
  const sRecords = records.slice(
    records.findIndex((r) => r.id === selection.start.rowId),
    records.findIndex((r) => r.id === selection.end.rowId) + 1,
  );
  const sFields = fields.slice(
    fields.findIndex((f) => f.key === selection.start.colKey),
    fields.findIndex((f) => f.key === selection.end.colKey) + 1,
  );
  return {
    records: sRecords,
    fields: sFields,
    cells: sRecords.flatMap((r) => sFields.map((f) => [r.id, f.key] as [number, string])),
  };
};
