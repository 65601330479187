import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';
import { html } from '^/shared/utils';

export const StatisticsReportPage = {
  bindings: {
    filterLevel: '<',
    queryParams: '<?',
  },
  template: html`
    <statistics-report-container
      filter-level="$ctrl.filterLevel"
      init-query-params="$ctrl.queryParams"
    >
    </statistics-report-container>
  `,

  controller: [
    'PageService',
    'ReportsService',
    class {
      PageService: PageService;
      ReportsService: ReportsService;
      filterLevel = 'statistics-report-page';
      constructor(PageService: PageService, ReportsService: ReportsService) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
        };
      }
    },
  ],
};
