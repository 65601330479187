import React from 'react';

import { cn } from '~/shared/lib/utils';

import { type DataTypeProps } from '../../lib/types';

export const NumberRead: React.FC<DataTypeProps<'number'>> = ({ disabled, value }) => (
  <p className={cn('text-xs', disabled && 'cursor-not-allowed pl-2')} data-testid="number-field">
    {value && String(value)}
  </p>
);
