import type ng from 'angular';

import type { MarketAnalyticsService } from '../analytics.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '^/app/core/types';
import { html } from '^/shared/utils';

export const MarketPortPrices = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template: html`
    <div class="col-xs-12">
      <gt-resource-select
        ng-model="$ctrl.queryParams.crop"
        placeholder="'Commodity'|translate"
        resource-name="'crops.Crop'"
        allow-clear="true"
        on-select-clb="$ctrl.updateData($event)"
      ></gt-resource-select>
    </div>
    <gt-table options="$ctrl.tableOptions"></gt-table>
  `,
  controller: [
    'gettext',
    'MarketAnalyticsService',
    'gtFilterService',
    class {
      MarketAnalyticsService: MarketAnalyticsService;
      filterLevel = 'market-port-prices';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      tableApi: any;
      tableOptions: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        MarketAnalyticsService: MarketAnalyticsService,
        gtFilterService: GtFilterService,
      ) {
        this.gettext = gettext;
        this.MarketAnalyticsService = MarketAnalyticsService;
        this.gtFilterService = gtFilterService;

        this.filterLevel = this.filterLevel || 'market-port-prices';
        this.tableOptions = this.getTableOptions();
        this.tableApi = undefined;
      }

      $onInit() {
        this.gtFilterService.subscribe(this.filterLevel, (params) => {
          this.queryParams = params;
          this.updateData();
        });
      }

      getTableOptions() {
        return {
          tableName: 'sale_by_port',
          filterLevel: 'sale_by_port',
          columnDefs: [
            {
              columnName: 'port',
              cellTemplate: '<i class="fa fa-anchor"></i> {[{ item.port }]}',
              title: this.gettext('title'),
            },
            {
              columnName: 'min_value',
              cellTemplate: html`
                <span class="label label-bold negative-number">
                  \${[{ item.min_value_USD || 0 | gtDecimal: 1 }]}
                  <span class="label-opacity" ng-if="item.min_value != item.min_value_USD">
                    {[{ item.min_value || 0 | gtDecimal: 1 }]}
                  </span>
                </span>
                <span ng-if="item.min_basis"> {[{ item.min_basis }]} </span>
                <span ng-if="item.min_buyer_name">
                  {[{item.min_date_of_execution | date:'MMM'}]}
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.min_buyer_name">
                  <i class="fa fa-bookmark"></i> {[{ item.min_buyer_name }]}
                </span>
                <div class="clearfix"></div>
                <span class="label-opacity" ng-if="item.min_update_time">
                  <translate>updated</translate>
                  <i class="fa fa-calendar"></i> {[{item.min_update_time | date:'dd.MM'}]}
                  <i class="fa fa-clock-o"></i> {[{item.min_update_time | date:'HH:mm'}]}
                </span>
              `,
              title: this.gettext('Min'),
            },
            {
              columnName: 'value',
              cellTemplate: html`
                <span class="label label-bold positive-number">
                  \${[{ item.value_USD || 0 | gtDecimal: 1 }]}
                  <span class="label-opacity" ng-if="item.value != item.value_USD">
                    {[{ item.value || 0 | gtDecimal: 1 }]}
                  </span>
                </span>
                <span ng-if="item.basis"> {[{ item.basis }]} </span>
                <span ng-if="item.date_of_execution">
                  {[{item.date_of_execution | date:'MMM'}]}
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.buyer_name">
                  <i class="fa fa-bookmark"></i> {[{ item.buyer_name }]}
                </span>
                <div class="clearfix"></div>
                <span class="label-opacity" ng-if="item.update_time">
                  <translate>updated</translate>
                  <i class="fa fa-calendar"></i> {[{item.update_time | date:'dd.MM'}]}
                  <i class="fa fa-clock-o"></i> {[{item.update_time | date:'HH:mm'}]}
                </span>
              `,
              title: this.gettext('Max'),
            },
          ],
          rowData: [],
          onInit: (api: any) => (this.tableApi = api),
        };
      }

      updateData() {
        this.MarketAnalyticsService.getPortPricesData(this.queryParams).then((data) =>
          this.tableApi.setRowData(data),
        );
      }
    },
  ],
};
