import { InfoLogisticRecord, InfoLogisticRepository } from '~/features/execution/logistic';
import {
  BaseLogisticListContainerProps,
  BaseLogisticListModel,
} from '~/features/execution/logistic/common/components/logistic-container/base-logistic-list.model';
import { injectable } from '~/shared/lib/di';

export type InfoLogisticListContainerProps = BaseLogisticListContainerProps;

@injectable()
export class InfoLogisticListModel extends BaseLogisticListModel<
  InfoLogisticRecord,
  InfoLogisticRepository
> {
  entityRepoClass = InfoLogisticRepository;
}
