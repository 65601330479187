import type { GtRootScopeService } from '../../types';

export const GtTableComponent = {
  bindings: {
    options: '<',
    onInit: '&?',
    isRowDraggable: '<?',
  },
  template: require('./gt-table.html?raw'),
  controller: [
    '$rootScope',
    '$interpolate',
    '$sce',
    '$compile',
    class {
      TotalsScope: any;
      _$compile: any;
      _$interpolate: any;
      _$rootScope: GtRootScopeService;
      _$sce: any;
      allSelected: any;
      columnVisibility: any;
      hovering: any;
      isRowDraggable: any;
      onInit: any;
      options: any;
      renderedRows: any;
      renderedTotal: any;
      rowTpl: any;
      showRows: any;
      constructor($rootScope: GtRootScopeService, $interpolate: any, $sce: any, $compile: any) {
        this._$rootScope = $rootScope;
        this._$interpolate = $interpolate;
        this._$sce = $sce;
        this._$compile = $compile;

        this.renderedRows = {};
        this.showRows = [];
        this.renderedTotal = undefined;
        this.hovering = false;
        this.columnVisibility = [];
        this.allSelected = false;
        this.options = undefined;
      }

      $onInit() {
        this.updateTableClass();
        this.updateColumnVisibility();

        this.options.api = {
          setRowData: (data: any) => this.setRowData(data),
          setRowCount: (data: any) => this.setRowCount(data),
          setTotalData: (data: any) => this.setTotalData(data),
          getRowData: () => this.getRowData(),
          setHovering: (value: any) => this.setHovering(value),
          getSelectedRowData: () => this.getSelectedRowData(),
          setColumnDefs: (columns: any) => this.setColumnDefs(columns),
          clearRenderedRows: () => this.clearRenderedRows(),
          moveItem: (position: any, newPosition: any, list: any) =>
            this.moveItem(position, newPosition, list),
        };

        this.onInit?.({ $event: { api: this.options.api } });
        this.options.onInit?.(this.options.api);
      }

      $onChanges(changes: any) {
        if (changes.options) {
          this.options.columnDefs.unshift({
            columnName: '$_selection',
            title: 'all',
            onHeadClick: () => this.toggleSelection(),
            cellTemplate: '<input type="checkbox" ng-model="item.$_selected">',
          });
        }
      }

      getRenderedRow(index: number) {
        if (!this.renderedRows[index]) {
          if (!this.rowTpl) {
            this.rowTpl = [];
            this.options.columnDefs
              .filter((col: any) => this.columnVisibility[col.columnName])
              .forEach((col: any, idx: number) => {
                const tpl = col.cellTemplate || `{[{ item.${col.columnName} }]}`;
                col.class = col.class || '';
                const drv = !idx && this.isRowDraggable ? 'gt-draggable-element' : '';
                this.rowTpl.push(
                  `<td class="${col.class}" ng-class="${col.classExpr}" ${drv} data="item.index">${tpl}</td>`,
                );
              });
            this.rowTpl = this.rowTpl.join('');
          }
          const newRow: any = {
            scope: this._$rootScope.$new(true),
            rendered: undefined,
          };
          newRow.scope.args = this.options.templateArgs;
          newRow.scope.options = this.options;
          newRow.rendered = this._$compile(this.rowTpl)(newRow.scope);
          this.renderedRows[index] = newRow;
        }
        return this.renderedRows[index];
      }

      updateTableClass() {
        this.options._tableClass = JSON.parse(JSON.stringify(this.options.tableClass || []));
        if (this.options.totalsRow) {
          this.options._tableClass.push('table-with-total');
        }
      }

      updateColumnVisibility() {
        this.columnVisibility = {};
        this.columnVisibility.$_selection = this.options.selection;
        this.options.columnDefs
          .filter((i: any) => i.columnName != '$_selection')
          .forEach((col: any) => {
            this.columnVisibility[col.columnName] = true;
          });
      }

      setRowData(data: any) {
        this.options.rowCount = undefined;
        this.options.rowData = data.sort((a: any, b: any) => a.index - b.index);
        this.showRows = [];
        data.forEach((item: any, index: number) => {
          const row = this.getRenderedRow(index);
          item.index = index;
          row.scope.item = item;
          row.scope.index = index;
          this.showRows.push(row.rendered);
        });
        this.options.rowCount = this.options.rowCount || (this.showRows.length && 1);
      }

      setRowCount(data: any) {
        this.options.rowCount = data;
      }

      setTotalData(data: any) {
        if (!this.options.totalsRow) {
          return;
        }
        this.options.totalData = data;

        if (!this.TotalsScope) {
          this.TotalsScope = this._$rootScope.$new(true);
        }

        if (!this.renderedTotal) {
          const tpl: any = [];
          this.options.columnDefs
            .filter((col: any) => this.columnVisibility[col.columnName])
            .forEach((col: any) => tpl.push(`<td>${col.totalTemplate || ''}</td>`));
          this.renderedTotal = this._$compile(tpl.join(''))(this.TotalsScope);
        }

        this.TotalsScope.item = this.options.totalData;
        this.TotalsScope.args = this.options.templateArgs;
        this.TotalsScope.options = this.options;
        this.updateTableClass();
      }

      getRowData() {
        return this.options.rowData;
      }

      setHovering(value: any) {
        this.hovering = value;
      }

      getSelectedRowData() {
        return this.options.rowData.filter((item: any) => Boolean(item.$_selected));
      }

      toggleSelection() {
        this.allSelected = !this.allSelected;
        return this.options.rowData.forEach((item: any) => (item.$_selected = this.allSelected));
      }

      setColumnDefs(columns: any) {
        this.options.columnDefs = columns;
        this.updateColumnVisibility();
        this.renderedRows = {};
        this.renderedTotal = undefined;
      }

      // FIX: not implemented yet
      updateData(params: object) {
        this.options?.onDataUpdate?.(params);
      }

      clearRenderedRows() {
        this.renderedRows = {};
        this.renderedTotal = undefined;
      }

      moveItem(position: any, newPosition: any, list: any) {
        const item = list[position];
        list.splice(position, 1);
        list.splice(newPosition, 0, item);
        list.forEach((i: any, idx: number) => (i.index = idx));
        this.setRowData(list);
      }
    },
  ],
};
