import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './template-playground.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { NotificationService } from '^/app/crm/notifications/notifications.service';

export const templatePlayground = {
  bindings: {},
  template,
  controller: [
    'gettext',
    'NotificationService',
    'GtUtils',
    'ResourcesService',
    class {
      GtUtils: GtUtilsService;
      errors: any;
      fieldsData: any;
      gettext: ng.gettext.gettextFunction;
      notificationService: NotificationService;
      resourceObj: any;
      resources: any;
      responseResult: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        notificationService: NotificationService,
        GtUtils: GtUtilsService,
        ResourcesService: any,
      ) {
        this.gettext = gettext;
        this.notificationService = notificationService;
        this.GtUtils = GtUtils;

        this.resources = ResourcesService.getModelNames();

        this.fieldsData = {
          resourceName: this.resources[0],
          resourceObjId: null,
          ext: '{}',
          templateText: null,
        };

        this.responseResult = '';
        this.errors = {};
      }

      applyFilters() {
        this.GtUtils.getResource(this.fieldsData.resourceName).get(
          { id: this.fieldsData.resourceObjId },
          (data: any) => (this.resourceObj = JSON.stringify(data, null, 2)),
        );

        this.notificationService
          .getTemplateExt(this.fieldsData.resourceName, this.fieldsData.resourceObjId)
          .then((res: any) => (this.fieldsData.ext = JSON.stringify(res.data, null, 2)));
      }

      checkTemplate() {
        this.validateData();

        if (Object.keys(this.errors).length) {
          return notify(this.gettext('Something went wrong'), 'error');
        }
        this.GtUtils.overlay('show');
        this.notificationService
          .checkTemplate(
            this.fieldsData.resourceName,
            this.fieldsData.resourceObjId,
            this.fieldsData.templateText,
          )
          .then(
            (res: any) => (this.responseResult = res.data.result),
            (err: any) => (this.errors.responseResult = err.data.result),
          )
          .finally(() => this.GtUtils.overlay('hide'));
      }

      validateData() {
        this.responseResult = '';
        this.errors = {};

        if (this.fieldsData.ext === '') {
          this.fieldsData.ext = '{}';
        }

        if (!this.isJsonValid(this.fieldsData.ext)) {
          this.errors.ext = 'Json is not correct';
        }

        if (!this.fieldsData.resourceObjId) {
          this.errors.resourceObjId = 'Choose the resource object';
        }

        if (!this.fieldsData.templateText) {
          this.errors.templateText = 'Enter template text';
        }
      }

      isJsonValid(json: any) {
        try {
          return JSON.parse(json);
        } catch {
          return false;
        }
      }
    },
  ],
};
