import template from './contract-pnl-mtm-list-table-view.html?raw';

import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export const ContractPnlMtmListTableView = {
  bindings: {
    contracts: '<',
    count: '<',
    filterLevel: '<',
    total: '<',
    applyFilters: '&',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      applyFilters: any;
      contracts: any;
      count: number;
      filterLevel = 'contract-pnl-mtm-list-table-view';
      gettext: ng.gettext.gettextFunction;
      tableData: any;
      tableOptions: any;
      total: any;
      constructor($rootScope: GtRootScopeService, gettext: ng.gettext.gettextFunction) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;

        this.contracts = [];
        this.count = 0;

        this.tableOptions = {};
        this.tableData = {};
      }

      $onInit() {
        this.contracts = this.contracts || [];
        this.count = this.count || 0;
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.contracts || changes.count || changes.total) {
          this.tableData = { rows: this.contracts, count: this.count, total: this.total };
        }
      }

      getTableOptions() {
        const options: any = {
          tableName: 'contract-pnl-mtm-list-table-view',
          tableClass: 'warehouse-table mtm-position-table-detail contract-status-table',
          filterLevel: this.filterLevel,
          applyFilters: this.applyFilters,
          templateArgs: {
            statusClass: {
              washout: 'contract_calculate',
              new: 'contract_new',
              cancelled: 'contract_cancelled',
              executed: 'contract_executed',
              shipment: 'contract_processing',
            },
            $rootScope: this.$rootScope,
          },
          columnDefs: [] as any[],
          tabs: [],
          showMore: true,
          changePageSize: true,
          configurable: true,
        };

        options.tabs = [
          {
            serializer: 'table_info',
            icon: 'fa-info-circle',
            columns: [
              'contract_number',
              'contract_type',
              'category',
              'sides',
              'cargo',
              'parent_cargo',
              'crop_year',
              'passports',
              'currency_exchange_plan',
              'currency_exchange_fact',
              'vat_return_rate',
              'currency',
              'price',
              'usd_price',
              'quantity',
              'loading_option',
              'received_volume',
              'discharge_volume',
              'volume_lost',
              'at_terminal_volume',
              'on_road_volume',
              'open_balance',
              'basis',
              'opposite_basis',
              'port',
              'terminal',
              'warehouse',
              'market_zone',
              'estimated_date_execution',
              'date_of_execution',
              'end_of_execution',
              'status',
              'responsible',
              'charges',
              'realized_value',
              'self_cost',
              'self_cost_value',
              'contract_vat_return_value',
              'cost_vat_return_value_usd',
              'mtm_volume',
              'mtm_dtd_price',
              'mtm_dtd_value',
              'mtm_ltd_price',
              'mtm_ltd_value',
              'diff_value',
              'diff_price',
              '',
            ],
          },
        ];

        options.columnDefs = [
          {
            columnName: 'contract_number',
            title: this.gettext('contract number'),
            cellTemplate: html`
              <a ui-sref="gt.page.contract({id: item.id })" target="_blank">
                <i class="fa fa-file-text"></i> {[{ item.contract_number }]}
              </a>
            `,
          },
          {
            columnName: 'contract_type',
            title: this.gettext('contract type'),
            cellTemplate: html`
              <span
                class="label text-capitalize"
                ng-class="{'label_danger': item.contract_type == 'sale', 'label_success': item.contract_type == 'purchase'}"
              >
                <i
                  class="fa"
                  ng-class="{
                  'fa-arrow-up': item.contract_type == 'sale',
                  'fa-arrow-down': item.contract_type == 'purchase'
                }"
                ></i>
                <span ng-if="item.contract_type == 'sale'">
                  <translate>Sale</translate>
                </span>
                <span ng-if="item.contract_type == 'purchase'">
                  <translate>Purchase</translate>
                </span>
                <span ng-if="item.swap" class="tooltip" data-tip="{[{ 'Swap'|translate }]}">
                  <i class="fa fa-diamond"></i> <translate>Swap</translate>
                </span>
              </span>
            `,
          },
          {
            columnName: 'category',
            title: this.gettext('category'),
            cellTemplate: html`
              <span
                class="label text-capitalize"
                ng-class="{'label_warning': item.paper_trade_id, 'label_default': !item.paper_trade_id}"
              >
                <translate ng-if="item.paper_trade_id">Derivative</translate>
                <translate ng-if="!item.paper_trade_id">Physical</translate>
              </span>
            `,
          },
          {
            columnName: 'sides',
            title: this.gettext('sides'),
            cellTemplate: html`
              <span
                ng-if="item.contract_type=='sale'"
                ng-class="{'label-opacity': !item.buyer_name}"
              >
                <i class="fa fa-bookmark"></i> {[{ item.buyer_name || "---" | cut:true:30:'...' }]}
              </span>
              <span
                ng-if="item.contract_type=='purchase'"
                ng-class="{'label-opacity': !item.supplier_name}"
              >
                <i class="fa fa-bookmark-o"></i> {[{item.supplier_name || "---" |
                cut:true:30:'...'}]}
              </span>
            `,
          },
          {
            columnName: 'cargo',
            title: this.gettext('cargo'),
            cellTemplate: html`{[{ item.crop_title || "---" | cut:true:30:'...' }]}`,
          },
          {
            columnName: 'parent_cargo',
            title: this.gettext('parent cargo'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.parent_crop_title}"
                >{[{ item.parent_crop_title || "---" | cut:true:30:'...' }]}</span
              >
            `,
          },
          {
            columnName: 'crop_year',
            title: this.gettext('crop year'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.crop_year}">{[{ item.crop_year }]}</span>
            `,
          },
          {
            columnName: 'passports',
            title: this.gettext('Passports'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <a
                ng-repeat="passport in item.passports_data"
                ui-sref="gt.page.passport({id: passport.id })"
                target="_blank"
              >
                <i class="fa fa-exchange"></i> {[{ passport.title }]} <i class="fa fa-ship"></i> {[{
                passport.vessel_name || '---' | cut:true:50:'...' }]}
                <i class="fa fa-calendar"></i> {[{ passport.conclusion_date || "---" |
                date:'dd.MM.yy'}]}
                <div class="clearfix"></div>
              </a>
            `,
          },
          {
            columnName: 'currency_exchange_plan',
            title: this.gettext('exchange plan'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.currency_exchange_rate}"
                >{[{ item.currency_exchange_rate || '---'}]}</span
              >
            `,
          },
          {
            columnName: 'currency_exchange_fact',
            title: this.gettext('exchange fact'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.last_currencyexchange_rate}"
                >{[{ item.last_currencyexchange_rate || '---'}]}</span
              >
            `,
          },
          {
            columnName: 'vat_return_rate',
            title: this.gettext('vat return rate'),
            cellTemplate: html`
              <span ng-if="item.vat_return_rate">{[{ item.vat_return_rate }]} %</span>
              <span class="label-opacity" ng-if="!item.vat_return_rate">---</span>
            `,
          },
          {
            columnName: 'currency',
            title: this.gettext('currency'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.currency_symbol}"
                >{[{ item.currency_symbol }]}</span
              >
            `,
          },
          {
            columnName: 'price',
            title: this.gettext('price'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.price}">{[{ item.price | number:2}]}</span>
            `,
          },
          {
            columnName: 'usd_price',
            title: this.gettext('usd price'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.usd_price}"
                >{[{ item.usd_price | number:2}]}</span
              >
            `,
          },
          {
            columnName: 'quantity',
            title: this.gettext('quantity'),
            cellTemplate: html`
              <span>
                {[{ item.quantity | number:3 }]}
                <span class="smaller-label">
                  {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                </span>
              </span>
            `,
          },
          {
            columnName: 'loading_option',
            title: this.gettext('loading option'),
            cellTemplate: html`
              <span ng-if="item.loading_option == 2">+</span>
              <span ng-if="item.loading_option == 1">-</span>
              <span ng-if="item.loading_option == 0">---</span>
              <span ng-if="item.volume_options && item.loading_option != 0">
                {[{ item.volume_options}]} %
              </span>
            `,
          },
          {
            columnName: 'received_volume',
            title: this.gettext('received volume'),
            cellTemplate: html`
              <span ng-if="item.contract_type == 'sale'" class="label-opacity">---</span>
              <span
                ng-if="item.contract_type == 'purchase'"
                ng-class="{'label-opacity': !item.transport_received_volume_sum}"
              >
                {[{ item.transport_received_volume_sum || 0 | number:3 }]}
                <span class="smaller-label">
                  {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                >
              </span>
            `,
          },
          {
            columnName: 'discharge_volume',
            title: this.gettext('discharge volume'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.transport_discharge_volume_basis_sum}">
                {[{ item.transport_discharge_volume_basis_sum || 0 | number:3 }]}
                <span class="smaller-label">
                  {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                >
              </span>
            `,
          },
          {
            columnName: 'volume_lost',
            title: this.gettext('volume lost'),
            cellTemplate: html`
              <span ng-if="item.contract_type == 'sale'" class="label-opacity">---</span>
              <span
                ng-if="item.contract_type == 'purchase'"
                ng-class="{'label-opacity': !item.transport_volume_lost_sum, 'negative-number': item.transport_volume_lost_sum < 0}"
              >
                {[{ item.transport_volume_lost_sum || 0 | number:3 }]}
                <span class="smaller-label">
                  {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                >
              </span>
            `,
          },
          {
            columnName: 'at_terminal_volume',
            title: this.gettext('terminal volume'),
            cellTemplate: html`
              <span ng-if="item.contract_type == 'sale'" class="label-opacity">---</span>
              <span
                ng-if="item.contract_type == 'purchase'"
                ng-class="{'label-opacity': !item.transport_at_terminal_volume_sum}"
              >
                {[{ item.transport_at_terminal_volume_sum || 0 | number:3 }]}
                <span class="smaller-label">
                  {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                >
              </span>
            `,
          },
          {
            columnName: 'on_road_volume',
            title: this.gettext('road volume'),
            cellTemplate: html`
              <span ng-if="item.contract_type == 'sale'" class="label-opacity">---</span>
              <span
                ng-if="item.contract_type == 'purchase'"
                ng-class="{'label-opacity': !item.transport_on_road_volume_sum}"
              >
                {[{ item.transport_on_road_volume_sum || 0 | number:3 }]}
                <span class="smaller-label">
                  {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                >
              </span>
            `,
          },
          {
            columnName: 'open_balance',
            title: this.gettext('open balance'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.open_balance}">
                {[{ item.open_balance || 0 | number:3 }]}
                <span class="smaller-label">
                  {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                >
              </span>
            `,
          },
          {
            columnName: 'basis',
            title: this.gettext('basis'),
            cellTemplate: html`
              <span class="label label_default">{[{ item.basis_name || '---' }]}</span>
            `,
          },
          {
            columnName: 'opposite_basis',
            title: this.gettext('Opposite basis'),
            cellTemplate: html`
              <span class="label label_default">{[{ item.opposite_basis_name || '---' }]}</span>
            `,
          },
          {
            columnName: 'port',
            title: this.gettext('port'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span ng-repeat="port in item.ports_data">
                <i class="fa fa-anchor"></i> {[{ port.name | cut:true:30:'...' }]}
                <div class="clearfix"></div>
              </span>
              <span ng-if="!item.ports_data" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'warehouse',
            title: this.gettext('elevator/farm'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <!-- ELEVATOR -->
              <span
                ng-if="item.elevator_name"
                class="btn-link"
                ng-class="{'label label_danger': item.elevator_blacklisted}"
              >
                <a
                  ng-if="item.elevator_name"
                  ng-click="args.openRoleModal(item.elevator, 'Elevator')"
                >
                  <i class="fa fa-industry"></i> {[{ item.elevator_name | cut:true:30:'...' || "---"
                  }]}
                </a>
              </span>
              <div class="clearfix"></div>
              <!-- FARM -->
              <span
                ng-if="item.farm_name"
                class="btn-link"
                ng-class="{'label label_danger': item.elevator_blacklisted}"
              >
                <a ng-if="item.farm_name" ng-click="args.openRoleModal(item.farm, 'Farm')">
                  <i class="fa fa-building-o"></i> {[{ item.farm_name | cut:true:30:'...' || "---"
                  }]}
                </a>
              </span>
            `,
          },
          {
            columnName: 'market_zone',
            title: this.gettext('market zone'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span ng-if="item.market_zone_name"
                ><i class="fa fa-anchor"></i> {[{ item.market_zone_name | cut:true:30:'...'
                }]}</span
              >
            `,
          },
          {
            columnName: 'terminal',
            title: this.gettext('terminal'),
            cellTemplate: html`
              <span ng-if="item.shipment_terminal_name">
                <i class="fa fa-anchor"></i> {[{ item.shipment_terminal_name | cut:true:30:'...' }]}
              </span>
              <span ng-if="!item.shipment_terminal_name" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'estimated_date_execution',
            title: this.gettext('execution month'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.estimated_date_execution}">
                <i class="fa fa-calendar"></i> {[{item.estimated_date_execution || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'date_of_execution',
            title: this.gettext('start of execution'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.date_of_execution}">
                <i class="fa fa-calendar"></i> {[{item.date_of_execution || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'end_of_execution',
            title: this.gettext('end of execution'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.end_of_execution}">
                <i class="fa fa-calendar"></i> {[{item.end_of_execution || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'status',
            title: this.gettext('status'),
            cellTemplate: html`
              <span
                class="label"
                ng-class="{'label_success': item.status == 'new', 'label_warning': item.status == 'shipment', 'label_default': item.status == 'executed'}"
              >
                {[{ item.status }]}
              </span>
            `,
          },
          {
            columnName: 'responsible',
            title: this.gettext('responsible'),
            cellTemplate: html`
              <span ng-class="{'label-opacity': !item.responsible_first_name}">
                {[{ item.responsible_first_name || '---'}]}
              </span>
            `,
          },
          {
            columnName: 'charges',
            title: this.gettext('charges'),
            cellTemplate: html`
              <span ng-if="item.cost_price_usd"> {[{ item.cost_price_usd | number:2 }]} $ </span>
              <span ng-if="!item.cost_price_usd" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'realized_value',
            title: this.gettext('realized value'),
            cellTemplate: html`
              <span ng-if="item.realized_value"> {[{item.realized_value | number:2 }]} $ </span>
              <span ng-if="!item.realized_value" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'self_cost',
            title: this.gettext('Selfcost per MT'),
            cellTemplate: html`
              <span ng-if="item.self_cost"> {[{item.self_cost | number:2 }]} $ </span>
              <span ng-if="!item.self_cost" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'self_cost_value',
            title: this.gettext('Selfcost Amount, $'),
            cellTemplate: html`
              <span ng-if="item.self_cost_value"> {[{item.self_cost_value | number:2 }]} $ </span>
              <span ng-if="!item.self_cost_value" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'mtm_volume',
            title: this.gettext('mtm volume'),
            cellTemplate: html`
              <span ng-if="item.mtm_volume">
                {[{ item.mtm_volume | number:3 }]}
                <span class="smaller-label">
                  {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                </span>
              </span>
              <span ng-if="!item.mtm_volume" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'cost_vat_return_value_usd',
            title: this.gettext('costs vat return value'),
            cellTemplate: html`
              <span ng-if="item.cost_vat_return_value_usd">
                {[{item.cost_vat_return_value_usd | number:2 }]} $
              </span>
              <span ng-if="!item.cost_vat_return_value_usd" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'contract_vat_return_value',
            title: this.gettext('contract vat return value'),
            cellTemplate: html`
              <span ng-if="item.contract_vat_return_value_usd">
                {[{item.contract_vat_return_value_usd | number:2 }]} $
              </span>
              <span ng-if="!item.contract_vat_return_value_usd" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'mtm_dtd_price',
            title: this.gettext('mtm dtd price'),
            cellTemplate: html`
              <span ng-if="item.mtm_dtd_price"> {[{ item.mtm_dtd_price | number:2 }]} $ </span>
              <span ng-if="!item.mtm_dtd_price" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'mtm_dtd_value',
            title: this.gettext('mtm dtd value'),
            cellTemplate: html`
              <span ng-if="item.mtm_dtd_value"> {[{ item.mtm_dtd_value | number:2 }]} $ </span>
              <span ng-if="!item.mtm_dtd_value" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'mtm_ltd_price',
            title: this.gettext('mtm ltd price'),
            cellTemplate: html`
              <span ng-if="item.mtm_ltd_price"> {[{ item.mtm_ltd_price | number:2 }]} $ </span>
              <span ng-if="!item.mtm_ltd_price" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'mtm_ltd_value',
            title: this.gettext('mtm ltd value'),
            cellTemplate: html`
              <span ng-if="item.mtm_ltd_value"> {[{ item.mtm_ltd_value | number:2 }]} $ </span>
              <span ng-if="!item.mtm_ltd_value" class="label-opacity">---</span>
            `,
          },
          {
            columnName: 'diff_value',
            title: this.gettext('diff value'),
            cellTemplate: html`
              <span ng-if="item.mtm_dtd_value && item.mtm_ltd_value">
                {[{ item.mtm_dtd_value - item.mtm_ltd_value | number:2 }]} $
              </span>
              <span ng-if="!item.mtm_dtd_value && !item.mtm_ltd_value" class="label-opacity"
                >---</span
              >
            `,
          },
          {
            columnName: 'diff_price',
            title: this.gettext('diff price'),
            cellTemplate: html`
              <span ng-if="item.mtm_dtd_price && item.mtm_ltd_price">
                {[{ item.mtm_dtd_price - item.mtm_ltd_price | number:2 }]} $
              </span>
              <span ng-if="!item.mtm_dtd_price && !item.mtm_ltd_pric" class="label-opacity"
                >---</span
              >
            `,
          },
        ];

        return options;
      }
    },
  ],
};
