import template from './consolidated-contract-pnl-table-view.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';

export const ConsolidatedContractPnlTableView = {
  bindings: {
    queryParams: '<?',
    titleList: '<?',
    monthList: '<?',
    data: '<?',
    applyFilters: '&?',
  },
  template,
  controller: [
    'AccountsService',
    class {
      AccountsService: AccountsService;
      showMtm: any;
      showUnits: any;
      constructor(AccountsService: AccountsService) {
        this.showMtm = false;
        this.showUnits = false;
        this.AccountsService = AccountsService;
      }
    },
  ],
};
