import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('freightsContainer', {
    bindings: {
      queryParams: '<?',
      filterLevel: '<',
      hideExpiredCheckbox: '<?',
    },
    template: require('./freights-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'gtFilterService', 'LogisticsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    LogisticsService: LogisticsService,
  ) {
    const vm = this;

    vm.showExpired = false;
    vm.freights = [];
    vm.freightsCount = 0;
    vm.updateFreights = updateFreights;
    vm.updateQueryParams = updateQueryParams;

    vm.$onInit = function () {
      vm.queryParams = { show_expired: false, ...vm.queryParams };
      if (vm.hideExpiredCheckbox) {
        delete vm.queryParams.show_expired;
      }
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateFreights();
      });
      $scope.$watch('vm.showExpired', function (newValue: any) {
        vm.queryParams.show_expired = newValue;
        updateQueryParams();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    ////////////////

    function updateQueryParams() {
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }

    function updateFreights() {
      GtUtils.overlay('show');
      return LogisticsService.LogisticFreight.query(vm.queryParams, function (data: any) {
        vm.freights = data.results;
        vm.freightsCount = data.count;
        GtUtils.overlay('hide');
      }).$promise;
    }
  }
})();
