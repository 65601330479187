import uiRouter from '@uirouter/angularjs';
import ng from 'angular';
import chartJs from 'angular-chart.js';
import ngCookies from 'angular-cookies';
import 'angular-date-interceptor';
import daterangepicker from 'angular-daterangepicker';
import ngFormly from 'angular-formly';
import ngFormlyTemplatesBootstrap from 'angular-formly-templates-bootstrap';
import gettext from 'angular-gettext';
import { permission, uiPermission } from 'angular-permission';
import ngResource from 'angular-resource';
import uiBootstrap from 'angular-ui-bootstrap';
import AsyncFilterModule from 'angularjs-async-filter';
import uiBootstrapDatetimepicker from 'angularjs-bootstrap-datetimepicker';
import 'angularjs-bootstrap-datetimepicker/src/js/datetimepicker.templates';
import $ from 'jquery';
import moment from 'moment';
import ngFileUpload from 'ng-file-upload';
import uiSelect from 'ui-select';

import { accountsModule } from './accounts';
import { commonModule } from './common';
import { companyModule } from './company';
import { coreModule } from './core';
import { crmModule } from './crm';
import { dealsModule } from './deals';
import { executionModule } from './execution';
import { financesModule } from './finances';
import { reportsModule } from './reports';
import { gtMainContainersModule } from '../shared/main/containers.module';
import { gtUIComponentsModule } from '../shared/ui/components.module';
import { gtUIKitModule } from '../shared/ui/uikit.module';
import { html } from '../shared/utils';

import 'angular-ui-calendar';

import 'angular-ui-grid/ui-grid.js';
import 'daterangepicker/daterangepicker.js';

(window as any).$ = $;
(window as any).moment = moment;
(window as any).html = html;

export const AppAjsModule = ng.module('mainApp', [
  uiRouter,
  permission,
  uiPermission,
  uiBootstrap,
  ngResource,
  ngCookies,
  ngFileUpload,
  daterangepicker,
  'angularDateInterceptor',
  uiSelect,
  ngFormly,
  ngFormlyTemplatesBootstrap,
  gettext,
  chartJs,
  'ui.grid',
  'ui.calendar',
  uiBootstrapDatetimepicker,
  AsyncFilterModule,

  // our modules
  gtUIKitModule,
  gtUIComponentsModule,
  gtMainContainersModule,
  coreModule,
  dealsModule,
  executionModule,
  accountsModule,
  crmModule,
  companyModule,
  commonModule,
  financesModule,
  reportsModule,
]);
