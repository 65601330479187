import { RequestsRepository } from '~/features/deals/requests';
import { container } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notify } from '~/shared/lib/notify';

import template from './request-details-item-view.html?raw';

import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const requestDetailsItemView = {
  bindings: {
    request: '<',
    tab: '<',
    fields: '<',
    goBackButton: '<',
    updateRequest: '&?',
    openRequestModal: '&?',
    openRequestModalQuick: '&?',
    openRoleModal: '&?',
    openRoleDetails: '&?',
    updateQualities: '&?',
    openQualitiesModal: '&?',
    updateFields: '&?',
    getFormConfig: '&?',
    createContract: '&?',
    openEmailModal: '&?',
    openDocxModal: '&?',
    createTicket: '&?',
    createChildTicket: '&?',
    clone: '&?',
    reverse: '&?',
    approve: '&?',
    cancel: '&?',
    createPassport: '&?',
    connectToPassport: '&?',
    logEntries: '<',
    logEntriesCount: '<',
    additionalAgreementsFields: '<?',
    saveFunction: '&',
    hideWizard: '<?',
    changeTab: '&?',
  },
  template,
  controller: [
    '$rootScope',
    'ContractsService',
    class {
      $rootScope: GtRootScopeService;
      approve: any;
      cancel: any;
      changeTab?: () => void;
      checkEditPossibility: any;
      checkTicketCreatePossibility: any;
      clone: any;
      createChildTicket: any;
      createContract: any;
      createPassport: any;
      createTicket: any;
      fields: any;
      getFormConfig: any;
      goBackButton: any;
      hideWizard: any;
      isTicketDisabledForPassportCreate: any;
      logEntries: any;
      logEntriesCount: number;
      openDocxModal: any;
      openEmailModal: any;
      openQualitiesModal: any;
      openRequestModal: any;
      openRequestModalQuick: any;
      openRoleModal: any;
      request: any;
      reverse: any;
      saveFunction: any;
      showDetail: any;
      tab = '';
      updateFields: any;
      updateQualities: any;
      updateRequest: any;

      requestsRepo;

      constructor($rootScope: GtRootScopeService, ContractsService: ContractsService) {
        this.$rootScope = $rootScope;
        this.request = {};
        this.fields = [];
        this.goBackButton = {};
        this.logEntries = [];
        this.logEntriesCount = 0;

        this.requestsRepo = container.resolve(RequestsRepository);

        this.checkTicketCreatePossibility = () =>
          ContractsService.checkTicketCreatePossibility(this.request);
        this.checkEditPossibility = () => {
          const restrictedStatuses: any = ['executed', 'expired'];

          if ($rootScope.user.settings.TICKET_DENY_EDIT_AFTER_APPROVE) {
            restrictedStatuses.push('approved');
          }

          return ContractsService.checkEditPossibility(this.request, restrictedStatuses);
        };

        if (this.$rootScope.user.profile?.recap) {
          this.showDetail = true;
        } else {
          this.showDetail = false;
        }
        this.isTicketDisabledForPassportCreate = () =>
          ContractsService.isTicketDisabledForPassportCreate(this.request);
      }

      save() {
        this.saveFunction();
      }

      getTelegramConfigUrl() {
        return `/admin/notifications/telegrambotconfigset/?indicator=${this.request.id}`;
      }

      capitalize(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }

      onApproval({ objectType, id }: { objectType: string; id: number }) {
        if (objectType === 'contracts.Ticket') {
          this.requestsRepo
            .get(id)
            .then((ticketData) => {
              if (ticketData.approval_status === 'approved') {
                notify(
                  'Final approval has been granted. Automatic contract creation from the ticket is now activated.',
                );
              }
            })
            .catch(errorHandler);
        }
      }
    },
  ],
};
