import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './paymentplans-container.tpl.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('paymentplansContainer', {
    bindings: {
      filterLevel: '<?',
    },
    template,
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'GtUtils',
    'AccountsService',
    'FinancesService',
    'gtFilterService',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $state: ng.ui.IStateService,
    $stateParams: ng.ui.IStateParamsService,
    GtUtils: GtUtilsService,
    AccountsService: AccountsService,
    FinancesService: FinancesService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.paymentPlans = [];
    vm.paymentPlansCount = 0;
    vm.updatePaymentPlans = updatePaymentPlans;
    vm.queryParams = {};
    vm.expandedPlan = $stateParams.id;
    vm.expandedPlanApprovals = undefined;
    vm.openPaymentPlanModal = openPaymentPlanModal;
    vm.createPayments = createPayments;
    vm.openApprovalsModal = openApprovalsModal;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'paymentplans-container';
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updatePaymentPlans();
      });
      updatePaymentPlans();
    };

    ////////////////

    function updatePaymentPlans() {
      GtUtils.overlay('show');
      return FinancesService.PaymentPlan.list(vm.queryParams, function (data: any) {
        vm.paymentPlansCount = data.count;
        vm.paymentPlans = data.results;
        GtUtils.overlay('hide');
      }).$promise;
    }

    function openPaymentPlanModal(paymentPlan: any) {
      return FinancesService.paymentPlanModal(paymentPlan).then(updatePaymentPlans);
    }

    function openApprovalsModal(plan: any) {
      return AccountsService.approvalsModal(plan.id, plan.content_type).then(updatePaymentPlans);
    }

    function executePaymentPlan(plan: any) {
      plan.payment_status = 'executed';
      if (plan.payment_date) {
        plan.date = plan.payment_date;
      }
      FinancesService.PaymentPlan.update(plan);
    }

    function createPayments(paymentPlan: any) {
      const msg = GtUtils.translate(gettext('Do you want to create payment by this plan?'));
      if (!confirm(msg)) {
        return;
      }
      GtUtils.overlay('show');
      return FinancesService.PaymentPlan.createPayments(
        { id: paymentPlan.id },
        paymentPlan.payment_date ? { payment_date: paymentPlan.payment_date } : {},
        function () {
          executePaymentPlan(paymentPlan);
          GtUtils.overlay('hide');
          notify(GtUtils.translate(gettext('Payments were created')));
          return $state.go('finances.payments.list');
        },
        (error: any) => {
          GtUtils.errorClb(error);
          GtUtils.overlay('hide');
        },
      ).$promise;
    }
  }
})();
