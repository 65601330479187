import React from 'react';

import type { ResetPasswordCredentials } from '~/features/reset-password/lib';
import { Link } from '~/shared/lib/router';
import { FadedLogo } from '~/shared/ui/components/faded-logo';
import { ArrowLeftIcon } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';
import { Card } from '~/shared/ui/kit/card';

import { CardTitle } from './card-title';
import { ResetPasswordForm } from '../form';

export const ResetPasswordCard: React.FC<{
  onPasswordReset: (creds: ResetPasswordCredentials) => void;
  isSend: boolean;
  loading: boolean;
}> = ({ onPasswordReset, isSend, loading }) => {
  return (
    <Card className="relative flex w-[400px] flex-col gap-8" data-testid="reset-password-card">
      {!isSend && (
        <Link to="/login" className="absolute top-6 left-6">
          <ArrowLeftIcon size={32} className="text-stroke-main-stronger" />
        </Link>
      )}
      <FadedLogo />
      <CardTitle isSubmit={isSend} />
      <div className="mx-auto w-[208px]">
        {isSend ? (
          <Link to="/login" className="w-[205px]">
            <Button variant="fadedSecondary" size="md" fullWidth>
              Return To Sign In Page
            </Button>
          </Link>
        ) : (
          <ResetPasswordForm submit={onPasswordReset} loading={loading} />
        )}
      </div>
      <p className="text-text-main-tertiary text-center">
        By using Graintrack, you agree to the <span className="font-medium">Terms of Service</span>{' '}
        and <span className="font-medium">Data Processing Agreement</span>.
      </p>
    </Card>
  );
};
