import ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { html } from '^/shared/utils';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('indicatorsTable', {
    bindings: {
      indicators: '<',
      filterLevel: '<?',
      total: '<?',
      count: '<',
      onUpdate: '&?',
      saveRequest: '&',
      openRequestModal: '&',
      cloneRequest: '&',
      createTicketFromIndicator: '&',
      creatingMultiTicket: '<',
    },
    template: require('./indicators-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$timeout',
    'GtUtils',
    'gtFilterService',
    'gettext',
    'ContractsService',
    'ClientsService',
    'CustomValuesService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $timeout: ng.ITimeoutService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    ContractsService: ContractsService,
    ClientsService: any,
    CustomValuesService: CustomValuesService,
  ) {
    const vm = this;
    vm.queryParams = { serializer: 'table_info' };
    vm.setHovering = setHovering;
    vm.hovering = false;
    vm.selection = false;
    vm.showAllItems = showAllItems;

    vm.openRoleModal = openRoleModal;
    vm.openUpdateModal = openUpdateModal;

    vm.tableData = {};
    vm.tableOptions = {};

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'indicators-table';
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        setTableOptions().catch(errorHandler);
      });
    };

    vm.$onChanges = function (changesObj: any) {
      if (changesObj.indicators || changesObj.total) {
        $timeout(function () {
          vm.tableData = {
            rows: vm.indicators || [],
            count: vm.count,
            total: vm.total || [],
          };

          vm.rows = vm.tableData.rows.reduce((options: any, item: any, index: number) => {
            options[item.id] = { index: index, editable: false };
            return options;
          }, {});
        });
      }
      if (changesObj.creatingMultiTicket) {
        vm.selection = vm.creatingMultiTicket;
        setTableOptions().catch(errorHandler);
      }
    };

    ////////////////

    function showAllItems(item: any, type: any) {
      const property = 'showAll' + GtUtils.capitalize(type);
      item[property] = !item[property];
    }

    function setHovering(value: any) {
      vm.hovering = value;
    }

    function openUpdateModal(id: number) {
      return ClientsService.clientUpdateModal(id).then(vm.onUpdate);
    }

    function openRoleModal(id: number, model: any) {
      return ClientsService.roleModal({ id: id, model_name: model });
    }

    function getCustomFieldTableColumns() {
      return CustomValuesService.getCustomFieldTableColumns({
        purpose_model: 'indicator',
      });
    }

    function setTableOptions() {
      const options: any = {
        tableName: vm.filterLevel || 'indicators-table',
        tableClass:
          'table table-responsive main-table table-condensed offers-table indicators-table table-hover',
        filterLevel: vm.filterLevel,
        applyFilters: (params: { params: QueryParams }) => {
          gtFilterService.updateQueryParams(params.params, vm.filterLevel);
        },
        templateArgs: {
          clone: (item: any) => vm.cloneRequest({ request: item }),
          setHovering: (value: any) => setHovering(value),
          openRequestModal: (item: any) => vm.openRequestModal({ request: item }),
          createTicket: (item: any) => vm.createTicketFromIndicator({ indicator: item }),
          openUpdateModal: (item: any) => openUpdateModal(item),
          saveRequest: (item: any) => vm.saveRequest({ request: item }),
          checkTicketCreatePossibility: (item: any) =>
            !ContractsService.checkTicketCreatePossibility(item),
          checkEditPossibility: (item: any) =>
            !ContractsService.checkEditPossibility(item, ['executed', 'expired']),
          showAllItems: (showAll: any, type: any) => vm.showAllItems(showAll, type),
        },
        columnDefs: [] as any[],
        tabs: [],
        configurable: true,
        showMore: true,
        changePageSize: true,
        selection: vm.selection,
        numberInput: vm.selection,
        selectionCellTemplate: html`
          <input
            ng-if="item.$_showCheckbox"
            type="checkbox"
            ng-model="item.$_selected"
            ng-change="args.onRowSelect()"
            style="margin-right:5px; float: none"
          />
        `,
        numberInputCellTemplate: html`
          <input
            ng-class="{
                'positive-number': item.unconnected_ticket_volume > 0,
                'negative-number': item.unconnected_ticket_volume < 0
              }"
            ng-if="item.$_showCheckbox"
            type="number"
            ng-model="item.$_inputedNumber"
            ng-change="args.onRowSelect()"
            style="margin-right:5px;"
          />
        `,
      };
      options.columnDefs = [
        {
          columnName: 'contract_number',
          title: gettext('Indicator number'),
          predicate: 'contract_number',
          cellTemplate: html`
            <ul
              ng-if="!item.$_edit"
              class="nav navbar-nav item-settings-dropdown"
              ng-mouseenter="args.setHovering(true)"
              ng-mouseleave="args.setHovering(false)"
            >
              <li>
                <a
                  class="dropdown-toggle user-dropdown-btn space-between"
                  ui-sref="gt.page.request({id: item.id})"
                >
                  <i class="fa fa fa-file"></i>
                  <span ng-if="!item.contract_number"> {[{item.id}]} </span>
                  <span ng-if="item.contract_number"> {[{item.contract_number }]} </span>
                  <i class="fa fa-sort-desc"></i>
                </a>

                <ul class="dropdown-menu">
                  <li>
                    <a ui-sref="gt.page.request({id: item.id})" class="btn btn-xs col-xs-12">
                      <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                    </a>
                  </li>
                  <li>
                    <a
                      ng-disabled="args.checkEditPossibility(item)"
                      permission
                      permission-only="'change_indicator'"
                      class="btn btn-xs col-xs-12"
                      ng-click="args.openRequestModal(item)"
                    >
                      <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                    </a>
                  </li>
                  <li>
                    <a
                      permission
                      permission-only="'edit_closed_elements'"
                      class="btn btn-xs col-xs-12"
                      ng-click="args.openRequestModal(item)"
                    >
                      <i class="fa fa-user-secret"></i>
                      <translate>Edit (admin)</translate>
                    </a>
                  </li>
                  <li>
                    <a
                      class="btn btn-xs col-xs-12"
                      ng-click="args.rows[item.id].editable = true"
                      ng-show="$root.user.settings.ENABLE_INLINE_ADD"
                      style="display: inline-flex; padding: 2px !important;"
                    >
                      <i class="fa fa-pencil-square"></i> <translate>Quick edit</translate>
                    </a>
                  </li>
                  <li>
                    <a
                      permission
                      permission-only="'change_indicator'"
                      class="btn btn-xs col-xs-12"
                      ng-click="args.clone(item)"
                    >
                      <i class="fa fa-files-o"></i> <translate>Clone</translate>
                    </a>
                  </li>
                  <li>
                    <a
                      permission
                      permission-only="'change_indicator'"
                      class="btn btn-xs col-xs-12"
                      ng-click="item.request_status = 'approved';args.saveRequest(item)"
                    >
                      <i class="fa fa-check"></i> <translate>Approve</translate>
                    </a>
                  </li>
                  <li>
                    <a
                      permission
                      permission-only="'change_indicator'"
                      class="btn btn-xs col-xs-12"
                      ng-click="item.request_status = 'cancelled';args.saveRequest(item)"
                    >
                      <i class="fa fa-times"></i> <translate>Decline</translate>
                    </a>
                  </li>

                  <li>
                    <a
                      ng-disabled="args.checkTicketCreatePossibility(item)"
                      permission
                      permission-only="'create_ticket_from_offer'"
                      class="btn btn-xs col-xs-12 tooltip"
                      data-tip="{[{ 'Bite off some volume from this Indicator to create a Ticket'|translate }]}"
                      ng-click="args.createTicket(item)"
                    >
                      <i class="fa fa-chain-broken"></i> <translate>Create Ticket</translate>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div ng-if="args.rows[item.id].editable || !item.id" class="edit">
              <a
                class="btn btn-xs btn-success btn_success col-xs-12"
                ng-click="args.saveRequest(item); args.rows[item.id].editable = false;"
              >
                <i class="fa fa-floppy-o"></i>
              </a>
              <div class="col-xs-12"></div>
              <a class="btn btn-xs col-xs-12" ng-click="args.rows[item.id].editable=false">
                <i class="fa fa-times"></i>
              </a>
            </div>
          `,
          totalTemplate: html`
            <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate>
          `,
        },
        {
          columnName: 'contract_type',
          title: gettext('Contract type'),
        },
        {
          title: gettext('cargo'),
          columnName: 'cargo',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-resource-select
                ng-model="item.cargo"
                placeholder="'Commodity'|translate"
                resource-name="'crops.Crop'"
                allow-clear="true"
                on-open-close="args.onMenuHover({ value: isOpen })"
              ></gt-resource-select>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">{[{ item.crop_title }]}</div>
          `,
        },
        {
          title: gettext('volume'),
          columnName: 'volume',
          predicate: 'volume',
          class: 'th-left-align',
          filters: [
            {
              type: 'text',
              predicate: 'min_volume',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_volume',
              addon: gettext('Max'),
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <input
                class="form-control"
                type="text"
                ng-model="item.volume"
                placeholder="{[{ 'Volume' | translate }]}"
              />
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                {[{ item.volume || 0 | gtDecimal:2 }]}
                <span class="smaller-label">
                  {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
                </span>
              </span>
            </div>
          `,
          totalTemplate: html`
            <div ng-if="item.volume">
              {[{ item.volume || 0 | gtDecimal:2 }]}
              <span style="font-size: 0.8em">
                {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
              </span>
            </div>
          `,
        },
        {
          columnName: 'contracts_volume',
          title: gettext('Contracts volume'),
          class: 'td-right-align',
          predicate: 'contracts_volume',
          cellTemplate: html`<div ng-if="!args.rows[item.id].editable && item.id">
            <span
              ng-if="!item.$_edit"
              class="bigger-label"
              ng-class="{'label-opacity': item.tickets_volume == 0}"
            >
              {[{ item.contracts_volume || 0 | gtDecimal:2 }]}
              <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
            </span>
          </div>`,
          totalTemplate: html`<div ng-if="item.tickets_volume">
            {[{ item.contracts_volume || 0 | gtDecimal:2 }]}
            <span style="font-size: 0.8em">
              {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
            </span>
          </div>`,
        },
        {
          columnName: 'contracts_final_volume',
          title: gettext('Contracts final volume'),
          class: 'td-right-align',
          predicate: 'contracts_volume',
          cellTemplate: html`<div ng-if="!args.rows[item.id].editable && item.id">
            <span
              ng-if="!item.$_edit"
              class="bigger-label"
              ng-class="{'label-opacity': item.contracts_final_volume == 0}"
            >
              {[{ item.contracts_final_volume || 0 | gtDecimal:2 }]}
              <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
            </span>
          </div>`,
          totalTemplate: html`<div ng-if="item.tickets_volume">
            {[{ item.contracts_final_volume || 0 | gtDecimal:2 }]}
            <span style="font-size: 0.8em">
              {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
            </span>
          </div>`,
        },
        {
          columnName: 'tickets_volume',
          title: gettext('Tickets volume'),
          class: 'td-right-align',
          predicate: 'volume',
          cellTemplate: html`<div ng-if="!args.rows[item.id].editable && item.id">
            <span
              ng-if="!item.$_edit"
              class="bigger-label"
              ng-class="{'label-opacity': item.tickets_volume == 0}"
            >
              {[{ item.tickets_volume || 0 | gtDecimal:2 }]}
              <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
            </span>
          </div>`,
          totalTemplate: html`<div ng-if="item.tickets_volume">
            {[{ item.tickets_volume || 0 | gtDecimal:2 }]}
            <span style="font-size: 0.8em">
              {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
            </span>
          </div>`,
        },
        {
          columnName: 'unconnected_volume',
          title: gettext('Unconnected volume (contract)'),
          class: 'td-right-align',
          predicate: 'volume',
          cellTemplate: html`<div ng-if="!args.rows[item.id].editable && item.id">
            <span
              ng-if="!item.$_edit"
              class="bigger-label"
              ng-class="{'label-opacity': item.unconnected_volume == 0}"
            >
              {[{ item.unconnected_volume || 0 | gtDecimal:2 }]}
              <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
            </span>
          </div>`,
          totalTemplate: html`<div ng-if="item.unconnected_volume">
            {[{ item.unconnected_volume || 0 | gtDecimal:2 }]}
            <span style="font-size: 0.8em">
              {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
            </span>
          </div>`,
        },
        {
          columnName: 'unconnected_final_volume',
          title: gettext('Unconnected volume (by contract final volume)'),
          class: 'td-right-align',
          predicate: 'volume',
          cellTemplate: html`<div ng-if="!args.rows[item.id].editable && item.id">
            <span
              ng-if="!item.$_edit"
              class="bigger-label"
              ng-class="{'label-opacity': item.unconnected_final_volume == 0}"
            >
              {[{ item.unconnected_final_volume || 0 | gtDecimal:2 }]}
              <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
            </span>
          </div>`,
          totalTemplate: html`<div ng-if="item.unconnected_volume">
            {[{ item.unconnected_final_volume || 0 | gtDecimal:2 }]}
            <span style="font-size: 0.8em">
              {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
            </span>
          </div>`,
        },
        {
          columnName: 'estimated_date_execution',
          title: gettext('Delivery estimated month'),
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item.estimated_date_execution">
              {[{ item.estimated_date_execution | date:"MMMM'’'yy" }]}
              <span ng-if="item.estimated_date_execution_part_of_month">
                , {[{ item.estimated_date_execution_part_of_month === 'first' ? '1st Half' :
                (item.estimated_date_execution_part_of_month === 'second' ? '2nd Half' :
                item.estimated_date_execution_part_of_month) }]}
              </span>
            </span>
          `,
        },
        {
          columnName: 'unconnected_ticket_volume',
          title: gettext('Unconnected volume (ticket)'),
          class: 'td-right-align',
          predicate: 'volume',
          cellTemplate: html`<div ng-if="!args.rows[item.id].editable && item.id">
            <span
              ng-if="!item.$_edit"
              class="bigger-label"
              ng-class="{'label-opacity': item.unconnected_ticket_volume == 0}"
            >
              {[{ item.unconnected_ticket_volume || 0 | gtDecimal:2 }]}
              <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
            </span>
          </div>`,
        },
        {
          title: gettext('buyer'),
          columnName: 'buyer',
          filters: [
            {
              type: 'ui-select',
              predicate: 'buyer',
              label: gettext('buyer'),
              resource: 'clients.buyer',
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-resource-select
                ng-model="item.buyer"
                placeholder="'Buyer'|translate"
                resource-name="'clients.Buyer'"
                allow-clear="true"
                on-open-close="args.onMenuHover({ value: isOpen })"
              ></gt-resource-select>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="btn-xs btn-link"
                ng-click="args.openRoleModal(item.buyer, 'Buyer')"
              >
                <i class="fa fa-bookmark"></i> {[{ item.buyer_name || "---" | cut:true:25:'...' }]}
              </span>
            </div>
          `,
        },
        {
          title: gettext('supplier'),
          columnName: 'supplier',
          filters: [
            {
              type: 'ui-select',
              predicate: 'supplier',
              label: gettext('supplier'),
              resource: 'clients.Supplier',
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-resource-select
                ng-model="item.supplier"
                placeholder="'Supplier'|translate"
                resource-name="'clients.Supplier'"
                allow-clear="true"
                on-open-close="args.onMenuHover({ value: isOpen })"
              ></gt-resource-select>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="btn-xs btn-link"
                ng-click="args.openRoleModal(item.supplier, 'Supplier')"
              >
                <i class="fa fa-bookmark-o"></i> {[{ item.supplier_name || "---" | cut:true:25:'...'
                }]}
              </span>
            </div>
          `,
        },
        {
          columnName: 'broker',
          title: gettext('broker'),
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item.broker_name">
              <i class="fa fa-briefcase"></i> {[{ item.broker_name || '---'}]}
            </span>
            <div class="clearfix"></div>
            <span ng-if="item.agent_name">
              <i class="fa fa-suitcase"></i> {[{ item.agent_name || '---'}]}
            </span>
          `,
        },
        {
          title: gettext('Broker details'),
          columnName: 'broker_contacts',
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-repeat="broker_contact in item.broker_contacts_data">
              <span>{[{broker_contact.first_name}]} {[{broker_contact.second_name}]}</span>
            </div>
          `,
        },
        {
          title: gettext('Warehouse'),
          columnName: 'warehouse',
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item.warehouse_data">
              <i
                class="fa {[{ $root.gtUtils.getIcon('warehouses.' + item.warehouse_data.type) }]}"
              ></i>
              <a
                class="btn-xs btn-link"
                ui-sref="logistics.warehouseDetails({ id: item.warehouse_data.id })"
              >
                {[{ item.warehouse_data.title }]}
              </a>
              <span class="label-smoke smaller-label" ng-if="item.warehouse_data.port.name">
                {[{ item.warehouse_data.port.name }]}
              </span>
            </span>
          `,
        },
        {
          title: gettext('Warehouses'),
          columnName: 'warehouses',
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-repeat="warehouse in item.warehouses_data">
              <i class="fa {[{ $root.gtUtils.getIcon('warehouses.' + warehouse.type) }]}"></i>
              <a class="btn-xs btn-link" ui-sref="logistics.warehouseDetails({ id: warehouse.id })">
                {[{ warehouse.title }]}
              </a>
              <span class="label-smoke smaller-label" ng-if="warehouse.port.name">
                {[{ warehouse.port.name }]}
              </span>
              <div class="clearfix"></div>
            </div>
          `,
        },
        {
          title: gettext('measurement'),
          columnName: 'measurement',
          filters: [
            {
              type: 'ui-select',
              predicate: 'measurement',
              label: gettext('measurement'),
              resource: 'stockexchanges.Measurement',
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-resource-select
              ng-model="item.measurement_id"
              placeholder="'Measurement'|translate"
              resource-name="'stockexchanges.Measurement'"
              allow-clear="true"
              on-open-close="args.onMenuHover({ value: isOpen })"
            ></gt-resource-select>
              </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
            <span ng-if="item.measurement_title">
              {[{ item.measurement_title || "---" |
              cut:true:25:'...' }]}
            </div>
            `,
        },
        {
          columnName: 'quantity_of_measurement',
          title: gettext('quantity of measurement'),
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item.quantity"> {[{ item.quantity || 0 | gtDecimal:3 }]} </span>
          `,
        },
        {
          title: gettext('port'),
          columnName: 'port',
          filters: [
            {
              type: 'ui-select',
              predicate: 'port',
              label: gettext('port'),
              resource: 'logistics.Port',
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">---</div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-repeat="port in item.port_set"
                ng-if="!item.terminal_port_name && item.port_set.length"
              >
                <a target="_blank" ng-href="/admin/logistics/port/{[{ port.id }]}">
                  <i class="fa fa-anchor"></i> {[{ port[$root.user.settings.PORT_NAME_DISPLAY_MODE]
                  || port.name }]}
                  <div class="clearfix"></div>
                </a>
                <div class="clearfix"></div>
              </span>
              <div class="clearfix"></div>
              <span
                ng-if="item.terminal_port_name"
                class="tooltip"
                data-tip="{[{item.terminal_port_name}]}"
              >
                <i class="fa fa-anchor"></i> {[{ item.terminal_port_name | cut:true:30:'...'}]}
                <span
                  ng-if="item.terminal_name"
                  class="tooltip smaller-label"
                  data-tip="{[{item.terminal_name}]}"
                >
                  ({[{ item.terminal_name || "---" | cut:true:30:'...'}]})
                </span>
              </span>
              <span class="label-opacity" ng-if="!item.terminal_port_name && !item.port_set.length"
                ><i class="fa fa-anchor"></i> ---</span
              >
            </div>
          `,
        },
        {
          title: gettext('price'),
          columnName: 'price',
          predicate: 'price_in_USD',
          class: 'th-left-align',
          filters: [
            {
              type: 'text',
              predicate: 'min_price',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_price',
              addon: gettext('Max'),
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <input
                class="form-control"
                type="text"
                ng-model="item.price"
                placeholder="{[{ 'Price' | translate }]}"
              />
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span class="bigger-label" ng-if="item.price">
                {[{item.price || 0 | gtDecimal:2 }]}
                <span class="smaller-label"
                  >{[{ item.currency_symbol }]}/ {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
                </span>
              </span>
              <span ng-if="!item.price">---</span>
            </div>
          `,
          totalTemplate: html`
            <div ng-if="item.total_value">
              {[{ item.total_value || 0 | gtDecimal:2 }]}
              <span style="font-size: 0.8em">
                {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
              </span>
            </div>
          `,
        },
        {
          title: gettext('period'),
          columnName: 'period',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">---</div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span ng-if="item.date_of_execution">
                <i class="fa fa-calendar"></i> {[{item.date_of_execution | date:'dd.MM.y' }]} -
                {[{item.end_of_execution | date:'dd.MM.yy' }]}
              </span>
            </div>
          `,
        },
        {
          title: gettext('basis'),
          columnName: 'basis',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">---</div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span class="label label_default" ng-class="{ 'label-opacity': !item.basis_name }">
                {[{ item.basis_name || "---"}]}
              </span>
            </div>
          `,
        },
        {
          columnName: 'available_in_bot',
          title: gettext('Bot'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <input type="checkbox" ng-model="item.available_in_bot" />
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <i class="fa fa-check-square-o" ng-if="item.available_in_bot"></i>
              <i class="fa fa-square-o" ng-if="!item.available_in_bot"></i>
            </div>
          `,
        },

        {
          title: gettext('status'),
          columnName: 'status',
          filters: [
            {
              type: 'select',
              predicate: 'request_status',
              label: gettext('Choose status'),
              values: {
                new: gettext('New'),
                approved: gettext('Approved'),
                rejected: gettext('Rejected'),
                executed: gettext('Executed'),
              },
            },
            {
              type: 'select',
              predicate: 'approval_status_list',
              label: gettext('Choose approve status'),
              values: {
                wait: gettext('Waiting for approval'),
                approved: gettext('Approved'),
                process: gettext('Approval in process'),
                rejected: gettext('Rejected'),
              },
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">---</div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                class="label"
                ng-class="{'label_success': item.request_status == 'new', 'label_default': item.request_status == 'executed', 'label_warning': item.request_status == 'approved', 'label-default': item.request_status == 'rejected' || item.request_status == 'cancelled'}"
              >
                <span> <translate>{[{ item.request_status }]}</translate> </span>
              </span>
            </div>
          `,
        },
        {
          title: gettext('ticket'),
          columnName: 'ticket',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">---</div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span ng-if="item.descendants_tickets.length">
                <a
                  ng-repeat="desc in item.descendants_tickets | limitTo: (item.showAllTickets ? item.descendants_tickets.length : 3)"
                  ui-sref="gt.page.request({id: desc.id})"
                  class="btn-link"
                >
                  <i class="fa fa-file-text-o"></i>
                  #{[{ desc.contract_number || desc.id }]} ( {[{ desc.volume || 0 }]}<span
                    class="smaller-label"
                    >t</span
                  >
                  )
                </a>
              </span>
              <span ng-if="item.descendants_tickets.length > 3">
                <span ng-if="item.showAllTickets">
                  <a ng-click="args.showAllItems(item, 'tickets')" class="btn btn-xs btn-default">
                    <translate>Show less</translate> <i class="fa fa-chevron-left"></i>
                  </a>
                </span>
                <span ng-if="!item.showAllTickets">
                  <a ng-click="args.showAllItems(item, 'tickets')" class="btn btn-xs btn-default">
                    <translate>Show more</translate> <i class="fa fa-chevron-right"></i>
                  </a>
                </span>
              </span>
            </div>
          `,
        },
        {
          title: gettext('Contract'),
          columnName: 'contract',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">---</div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span ng-if="item.descendants_contracts.length">
                <a
                  ng-repeat="desc in item.descendants_contracts | limitTo: (item.showAllContracts ? item.descendants_contracts.length : 3)"
                  ui-sref="gt.page.contract({id: desc.id})"
                  class="btn-link"
                >
                  <i class="fa fa-file-text"></i>#{[{ desc.contract_number || desc.id }]} ( {[{
                  desc.volume || 0 }]}<span class="smaller-label">t</span> )
                </a>
              </span>
              <span ng-if="item.descendants_contracts.length > 3">
                <span ng-if="item.showAllContracts">
                  <a ng-click="args.showAllItems(item, 'contracts')" class="btn btn-xs btn-default">
                    <translate>Show less</translate> <i class="fa fa-chevron-left"></i>
                  </a>
                </span>
                <span ng-if="!item.showAllContracts">
                  <a ng-click="args.showAllItems(item, 'contracts')" class="btn btn-xs btn-default">
                    <translate>Show more</translate> <i class="fa fa-chevron-right"></i>
                  </a>
                </span>
              </span>
            </div>
          `,
        },
        {
          title: gettext('ship classification'),
          columnName: 'ship_classification',
          class: 'small-font-cell',
          cellTemplate: html`
            <span ng-if="item.ship_classification_title">
              {[{ item.ship_classification_title }]}
            </span>
          `,
        },
        {
          title: gettext('notes'),
          columnName: 'notes',
          class: 'small-font-cell',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">---</div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                class="tooltip small-font-cell smaller-label"
                data-tip="{[{item.additional_info}]}"
                ng-if="item.additional_info"
              >
                <i class="fa fa-comment"></i> {[{item.additional_info | cut:true:30:' ...'}]}
              </span>
              <span class="label-opacity" ng-if="!item.additional_info">
                <i class="fa fa-comment"></i> ---
              </span>
            </div>
          `,
        },
        {
          title: gettext('comments'),
          columnName: 'comments',
          class: 'small-font-cell',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">---</div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <a ng-click="args.openUpdateModal(item.id)">
                <span
                  class="tooltip smaller-label"
                  data-tip="{[{item.latest_update_text}]}"
                  ng-if="item.latest_update_text"
                >
                  <i class="fa fa-comment"></i> {[{ item.latest_update_text | cut:true:35:' ...' }]}
                </span>
                <span class="label-opacity" ng-if="!item.latest_update_text">
                  <i class="fa fa-comment"></i> ---
                </span>
              </a>
            </div>
          `,
        },
        {
          title: gettext('business unit'),
          columnName: 'business_unit',
          filters: [
            {
              type: 'ui-multiselect',
              predicate: 'business_unit_list',
              label: gettext('business unit'),
              resource: 'core.BusinessUnit',
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">---</div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                class="tooltip"
                data-tip="{[{ item.business_unit_title }]}"
                ng-if="item.business_unit_title"
              >
                {[{ item.business_unit_title }]}
              </span>
            </div>
          `,
        },
        {
          title: gettext('date'),
          columnName: 'date',
          predicate: 'date',
          class: 'th-center-align',
          filters: [
            {
              type: 'daterange',
              startDateField: 'start_date',
              endDateField: 'end_date',
            },
            {
              type: 'daterange',
              placeholder: 'Date of execution',
              startDateField: 'date_of_execution_start_date',
              endDateField: 'end_of_execution_end_date',
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">---</div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                class="updated_time tooltip"
                data-tip="{[{item.date | date:'dd MMMM yy HH:mm'}]}"
              >
                <i class="fa fa-clock-o"></i>
                <span
                  am-time-ago="item.date"
                  am-full-date-threshold="30"
                  am-full-date-format="dd/MM/yy HH:mm"
                >
                </span>
              </span>
              <span
                class="tooltip"
                data-tip="{[{ item.author_first_name }]} {[{ item.author_last_name }]}"
              >
                <div
                  class="user-avatar"
                  ng-if="item.author_first_name && item.author_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.author_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.author_avatar.length == 0">
                  {[{ item.author_first_name | cut:true:1:' ' }]}
                </div>
              </span>
            </div>
          `,
        },
        {
          columnName: 'total_value',
          title: gettext('Total value'),
          class: 'td-right-align',
          cellTemplate: html`
            <span class="bigger-label" ng-if="item.total_value">
              {[{ item.total_value || 0 | number:2 }]}
            </span>
          `,
        },
        {
          columnName: 'analytical_price',
          title: gettext('analytical price'),
          class: 'td-right-align',
          cellTemplate: html`
            <span ng-if="item.analytical_price">
              {[{ item.analytical_price | number:2}]} {[{ item.currency_symbol}]}
            </span>
          `,
        },
        {
          columnName: 'editor',
          title: gettext('editor'),
          class: 'small-font-cell',
          filters: [
            {
              type: 'ui-select',
              predicate: 'editor',
              label: gettext('editor'),
              resource: 'auth.user',
            },
          ],
          cellTemplate: html`
            <span
              class="tooltip"
              data-tip="{[{ 'last updated' | translate }]} - {[{item.update_time | date:'dd.MM.yy HH:mm'}]} - {[{ item.editor_first_name }]} {[{ item.editor_last_name }]}, {[{ 'created' | translate }]} - {[{item.conclusion_date | date:'dd.MM.yy HH:mm'}]} - {[{ item.author_first_name }]} {[{ item.author_last_name }]}"
            >
              <div class="user-avatar" ng-if="item.editor_first_name && item.editor_avatar.length">
                <img ng-src="/pictures/{[{item.editor_avatar}]}" />
              </div>
              <div class="no-user-avatar" ng-if="!item.editor_avatar.length">
                {[{ item.editor_first_name | cut:true:1:' ' }]}
              </div>
              {[{ item.editor_first_name }]}
            </span>
          `,
        },
        {
          columnName: 'update_time',
          title: gettext('update_time'),
          class: 'td-left-align',

          cellTemplate: html`
            <span>
              <i class="fa fa-clock-o"></i> {[{item.update_time | date:'dd.MM.yy HH:mm'}]}
            </span>
          `,
        },
        {
          columnName: 'custom_status',
          title: gettext('custom status'),
          class: 'td-left-align',
          cellTemplate: html`
            <span
              ng-if="item.custom_status_title"
              class="label"
              ng-class="{ 'label_calculate': item.custom_status_codename == 'washout', 'label_success': item.custom_status_codename == 'new', 'label-default': item.custom_status_codename == 'cancelled', 'label_default': item.custom_status_codename == 'executed', 'label_warning': item.custom_status_codename == 'shipment' } "
            >
              {[{ item.custom_status_title }]}
            </span>
          `,
        },
        {
          columnName: 'deal_type',
          title: gettext('deal type'),
          class: 'td-left-align',
          cellTemplate: html`<span ng-if="item.deal_type"> {[{ item.deal_type }]} </span>`,
        },
        {
          columnName: 'crop_year',
          title: gettext('crop year'),
          class: 'td-left-align',
          cellTemplate: html`<span ng-if="item.crop_year"> {[{ item.crop_year }]} </span>`,
        },
        {
          columnName: 'season_title',
          title: gettext('season'),
          class: 'td-left-align',
          cellTemplate: html`<span ng-if="item.season_title"> {[{ item.season_title }]} </span>`,
        },
        {
          columnName: 'measurement_price',
          title: gettext('measurement price'),
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item.measurement_price">
              {[{ item.measurement_price }]} {[{ item.currency_symbol ||
              $root.user.settings.DEFAULT_CURRENCY}]}/{[{ item.measurement_title }]}
            </span>
          `,
        },
        {
          columnName: 'currency_symbol',
          title: gettext('currency'),
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item.currency_symbol"> {[{ item.currency_symbol}]} </span>
          `,
        },
        {
          columnName: 'request_status',
          title: gettext('status'),
          class: 'td-left-align',
          cellTemplate: html`
            <span
              class="label"
              ng-class="{ 'label_success': item.request_status =='new', 'label_warning': item.request_status =='approved' || item.request_status == 'processed' , 'label_calculate': item.request_status =='sent', 'label_danger': item.request_status =='cancelled', 'label_default': item.request_status =='executed' }"
              style="margin-right: 5px"
            >
              {[{ item.request_status }]}
            </span>
          `,
        },
        {
          columnName: 'fixed_price_bonus',
          title: gettext('fixed price bonus'),
          class: 'td-right-align',
          cellTemplate: html`
            <span class="bigger-label" ng-if="item.fixed_price_bonus">
              {[{ item.fixed_price_bonus || 0 | number:2 }]} {[{ item.currency_symbol ||
              $root.user.settings.DEFAULT_CURRENCY}]}/{[{ item.measurement_title }]}
            </span>
            <span ng-if="!item.fixed_price_bonus">---</span>
          `,
        },
      ];
      return getCustomFieldTableColumns()
        .then((columns: any) => {
          options.columnDefs.push(...columns);
          vm.tableOptions = options;
          $scope.$applyAsync();
        })
        .catch(errorHandler);
    }
  }
})();
