import React from 'react';

import { cn } from '~/shared/lib/utils';
import { SearchCommandList } from '~/shared/ui/components/search-command-list';
import type { DataType } from '~/shared/ui/data-types';
import { PlusIcon, useIcon } from '~/shared/ui/icons';
import { CommandItem } from '~/shared/ui/kit/command';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { DynamicFilterControl } from './dynamic-filter-control';
import { OperatorSelect } from './operator-select';
import { newFilterValue } from '../../helpers';
import type { FilterItemProps, FilterOption, FilterValue } from '../../types';
import { FilterButton } from '../filter-button';

const FilterListItem = <T extends DataType>({
  option,
  filter,
  onChange,
  onDelete,
}: FilterItemProps<T>) => {
  const Icon = useIcon(option.icon);

  return (
    <CommandItem key={option.argument}>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger
          className="hover:bg-transparent-light m-1 w-full p-1 transition duration-300"
          displayChevron={false}
        >
          <button className="flex items-center gap-2">
            <Icon size={14} className="text-text-main-primary" />
            <p className="text-text-main-primary text-xs">{option.title}</p>
          </button>
        </DropdownMenuSubTrigger>
        <DropdownMenuSubContent sideOffset={6} className="p-0">
          <div className="border-stroke-main-light border-b">
            <OperatorSelect option={option} filter={filter} onChange={onChange} />
          </div>
          <DynamicFilterControl
            option={option}
            filter={newFilterValue(option)}
            onChange={onChange}
            onDelete={onDelete}
          />
        </DropdownMenuSubContent>
      </DropdownMenuSub>
    </CommandItem>
  );
};

export const FiltersSelector = ({
  filters,
  options,
  onChange,
}: {
  filters: FilterValue<DataType>[];
  options: FilterOption<DataType>[];
  onChange: (filter: FilterValue<DataType>) => void;
}) => {
  const valuesByArgument = React.useMemo(
    () =>
      filters.reduce<Record<string, FilterValue<DataType>>>(
        (acc, filterValue) => ({ ...acc, [filterValue.argument]: filterValue }),
        {},
      ),
    [filters],
  );

  const optionsByGroup = React.useMemo(() => {
    return options.reduce<Record<string, FilterOption<DataType>[]>>(
      (acc, filterOption) => ({
        ...acc,
        [filterOption.groupName]: [...(acc[filterOption.groupName] || []), filterOption],
      }),
      {},
    );
  }, [options]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <FilterButton intent="empty">
          <div className="flex items-center gap-1">
            <PlusIcon size={14} className="text-text-main-secondary" />
            <p className="text-text-main-secondary text-xs">Filter</p>
          </div>
        </FilterButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <SearchCommandList
          groupClassName="p-0"
          options={Object.entries(optionsByGroup).map(([groupName, options], index) => (
            <div key={groupName}>
              <p
                className={cn(
                  'border-stroke-main-light bg-transparent-lighter text-text-main-tertiary border-t px-2 py-1 text-xs font-semibold',
                  index === 0 && 'border-t-0',
                )}
              >
                {groupName}
              </p>
              {options.map((option) => (
                <FilterListItem
                  key={option.argument}
                  option={option}
                  filter={valuesByArgument[option.argument]}
                  onChange={onChange}
                />
              ))}
            </div>
          ))}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
