import React from 'react';

import { AddFileIcon, CloseIcon } from '~/shared/ui/icons';
import {
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';

export const MenuItem: React.FC<
  React.PropsWithChildren & {
    label: string;
    title: string;
  }
> = ({ children, label, title }) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

  return (
    <DropdownMenuSub open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenuSubTrigger className="trasition hover:bg-transparent-light flex cursor-pointer items-center gap-2 px-2 py-1 duration-300 ease-in-out">
        <AddFileIcon size={14} className="text-text-main-secondary" />
        <p className="text-text-main-primary text-xs whitespace-nowrap">{label}</p>
      </DropdownMenuSubTrigger>
      <DropdownMenuSubContent className="flex flex-col gap-2 p-0" sideOffset={2}>
        <div className="border-stroke-main-medium flex items-center gap-2 border-b px-2 pt-1 pb-2">
          <CloseIcon
            size={14}
            className="text-text-main-tertiary cursor-pointer transition duration-300 ease-in-out hover:opacity-70"
            onClick={() => setIsDropdownOpen(false)}
          />
          <p className="text-text-main-primary text-xs font-medium">{title}</p>
        </div>
        {children}
      </DropdownMenuSubContent>
    </DropdownMenuSub>
  );
};
