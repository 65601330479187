import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './persons-container.tpl.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('personsContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      view: '<?',
      position: '<?',
      onUpdate: '&?',
    },
    template,
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$rootScope',
    'ClientsService',
    'GtUtils',
    'gtFilterService',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    ClientsService: any,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.persons = [];
    vm.personsCount = 0;
    vm.openPersonModal = openPersonModal;
    vm.updatePersons = updatePersons;
    vm.toggleInlineAdd = toggleInlineAdd;
    vm.applyFilters = applyFilters;
    vm.createUser = createUser;
    vm.clonePerson = clonePerson;
    vm.openEmailModal = openEmailModal;
    vm.createPerson = createPerson;
    vm.deletePerson = deletePerson;
    vm.openPersonModal = openPersonModal;
    vm.position = vm.position || 'full';

    vm.$onInit = function () {
      if ($rootScope.user.profile?.persons_view == 'block') {
        vm.view = 'block';
      } else if ($rootScope.user.profile?.persons_view == 'list') {
        vm.view = 'list';
      } else {
        vm.view = 'table';
      }

      vm.quickAdd = false;
      vm.filterLevel = vm.filterLevel || 'persons-container';
      vm.queryParams = { ...vm.initQueryParams };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updatePersons();
      });
      updatePersons();
    };

    ////////////////

    function initNewPersonTemplate() {
      const newPersonTemplate = { ...vm.initQueryParams };
      if (newPersonTemplate.companies && !(newPersonTemplate.companies instanceof Array)) {
        newPersonTemplate.companies = [newPersonTemplate.companies];
      }
      return newPersonTemplate;
    }

    function updatePersons() {
      vm.newPersonTemplate = initNewPersonTemplate();
      GtUtils.overlay('show');
      return ClientsService.Person.query(vm.queryParams, function (data: any) {
        vm.persons = data.results;
        vm.personsCount = data.count;
        vm.newPerson = { $_edit: true, ...vm.newPersonTemplate };
        if (vm.quickAdd) {
          vm.persons.unshift(vm.newPerson);
        }
        GtUtils.overlay('hide');
        vm.onUpdate?.({ data: data });
      }).$promise;
    }

    function toggleInlineAdd() {
      if (vm.quickAdd) {
        vm.persons.shift();
      } else {
        vm.newPerson = { $_edit: true, ...vm.newPersonTemplate, ...vm.newPerson };
        vm.persons.unshift(vm.newPerson);
      }
      vm.persons = Object.assign([], vm.persons);

      vm.quickAdd = !vm.quickAdd;
    }

    function applyFilters(params: QueryParams) {
      gtFilterService.updateQueryParams(params, vm.filterLevel);
    }

    function clonePerson(person: any) {
      return ClientsService.clonePerson(person).then(function () {
        updatePersons();
      });
    }

    function openPersonModal(person: any) {
      return ClientsService.personModal({ ...initNewPersonTemplate(), ...person }).then(
        function () {
          updatePersons();
        },
      );
    }

    function openEmailModal(person: any) {
      return ClientsService.sendEmailModal('Person', person.id);
    }

    function createUser(person: any) {
      return ClientsService.createUser(person).then(function () {
        updatePersons();
      });
    }

    function createPerson(person: any) {
      GtUtils.overlay('show');
      return ClientsService.Person.save(
        person,
        function () {
          notify(gettext(GtUtils.translate('Person created')));
          updatePersons();
          GtUtils.overlay('hide');
        },
        function (error: any) {
          GtUtils.errorClb(error);
          GtUtils.overlay('hide');
        },
      );
    }

    function deletePerson(person: any) {
      const msg = gettext('Are you sure that you want to delete?');
      if (!confirm(msg)) {
        return;
      }
      vm.persons.splice(vm.persons.indexOf(person), 1);
      updatePersons();
    }
  }
})();
