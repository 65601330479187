import template from './multipassport-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const MultipassportListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'MultipassportService',
    'gtFilterService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      MultipassportService: any;
      data: any;
      filterLevel = 'multipassport-list-container';
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        MultipassportService: any,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.MultipassportService = MultipassportService;
        this.gtFilterService = gtFilterService;

        this.data = {};
        this.queryParams = {};
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.MultipassportService.getTableData(this.queryParams).then((data: any) => {
          this.data = data;
          this.GtUtils.overlay('hide');
        });
      }

      updateQueryParams(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      openMultipassportModal(item: any, data: any) {
        return this.MultipassportService.multipassportModal(item, data).then(() =>
          this.updateData(),
        );
      }
    },
  ],
};
