import type { InvoicePurpose, InvoicingVolumeBase, LogisticTable } from './types';

export const logisticTotalsMaxChunkSize = 50;

export const logisticInvoicingConfig: Record<
  InvoicePurpose,
  {
    allowedTables: LogisticTable[];
    defaultTable: LogisticTable;
    contractType: 'sale' | 'purchase';
  }
> = {
  incoming: {
    allowedTables: ['table_supplier', 'table_qualities', 'table_certs'],
    defaultTable: 'table_supplier',
    contractType: 'purchase',
  },
  outgoing: {
    allowedTables: ['table_buyer', 'table_qualities'],
    defaultTable: 'table_buyer',
    contractType: 'sale',
  },
  export: {
    allowedTables: ['table_export', 'table_qualities'],
    defaultTable: 'table_export',
    contractType: 'purchase',
  },
  consolidated_costs: {
    allowedTables: ['table_costs'],
    defaultTable: 'table_costs',
    contractType: 'purchase',
  },
  costs: {
    allowedTables: ['table_buyer', 'table_supplier', 'table_qualities'],
    defaultTable: 'table_supplier',
    contractType: 'purchase',
  },
};

export const volumeBaseMap: Record<
  | 'volume_departed'
  | 'volume_departed_consignment'
  | 'volume_received'
  | 'volume_departed_real'
  | 'volume_boarded',
  InvoicingVolumeBase
> = {
  volume_departed: 'quantity_to_be_invoiced_volume_departed_based',
  volume_departed_consignment: 'quantity_to_be_invoiced_volume_departed_consignment_based',
  volume_received: 'quantity_to_be_invoiced_volume_received_based',
  volume_departed_real: 'quantity_to_be_invoiced_volume_departed_real_based',
  volume_boarded: 'quantity_to_be_invoiced_volume_boarded_based',
};
