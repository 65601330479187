import {
  contractsIndicatorListInfoList,
  contractsIndicatorTotalsInfoRetrieve,
  contractsRequestListInfoList,
  contractsRequestTotalsInfoRetrieve,
  contractsRequestsCreate,
  contractsRequestsDestroy,
  contractsRequestsPartialUpdate,
  contractsRequestsRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';

import type {
  Request,
  RequestPatch,
  RequestPost,
  RequestQueryParams,
  RequestRecord,
  RequestStage,
} from '../lib';

export const listApiMap = {
  ticket: contractsRequestListInfoList,
  indicator: contractsIndicatorListInfoList,
};

export const listTotalsApiMap = {
  ticket: contractsRequestTotalsInfoRetrieve,
  indicator: contractsIndicatorTotalsInfoRetrieve,
};

@singleton()
export class RequestsRepository extends EntityRepository<
  Request,
  RequestQueryParams,
  RequestRecord,
  RequestPost,
  RequestPatch
> {
  readonly entityName = 'contracts.Request';

  stage: RequestStage = 'ticket';
  contractType: RequestRecord['contract_type'] = 'sale';

  setStage = (stage: RequestStage) => (this.stage = stage);
  setContractType = (contractType: RequestRecord['contract_type']) =>
    (this.contractType = contractType);

  getFn = async (id: number) => {
    const { data } = await contractsRequestsRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: RequestQueryParams) => {
    const { data } = await listApiMap[this.stage]({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = (_input: string) => Promise.resolve([]);

  createFn = async (entity: RequestPost) => {
    const { data } = await contractsRequestsCreate({
      body: entity,
    });

    return data;
  };

  updateFn = async (entity: RequestPatch) => {
    const { data } = await contractsRequestsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  deleteFn = async (id: number) => {
    await contractsRequestsDestroy({ path: { id } });
  };

  getListTotals = async (query: RequestQueryParams) => {
    const { data } = await listTotalsApiMap[this.stage]({ query });
    return data;
  };

  buildNewRecord = () =>
    ({
      id: -Date.now(),
      contract_type: this.contractType,
      request_status: 'new',
      deal_type: 'spot',
      stage: this.stage,
      approval_status: 'wait',
    }) as unknown as RequestRecord;
}
