import { clientsCompanyGroupsPredictionsRetrieve } from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository, Paginated } from '~/shared/lib/entities';

import { CompanyGroup, CompanyGroupPatch, CompanyGroupPost, CompanyGroupQueryParams } from '../lib';

@singleton()
export class CompanyGroupRepository extends EntityRepository<
  CompanyGroup,
  CompanyGroupQueryParams,
  CompanyGroup,
  CompanyGroupPost,
  CompanyGroupPatch
> {
  readonly entityName = 'clients.CompanyGroup';

  getFn = (_id: number): Promise<CompanyGroup> => {
    throw new Error('method not implemented');
  };
  queryFn = (_params: CompanyGroupQueryParams): Promise<Paginated<CompanyGroup>> => {
    throw new Error('method not implemented');
  };
  searchFn = async (search: string, _id?: number) => {
    const { data } = await clientsCompanyGroupsPredictionsRetrieve({
      query: { search },
    });
    return data.results;
  };

  createFn = (_entity: CompanyGroupPost): Promise<CompanyGroup> => {
    throw new Error('method not implemented');
  };
  updateFn = (_entity: CompanyGroupPatch): Promise<CompanyGroup> => {
    throw new Error('method not implemented');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('method not implemented');
  };
}
