import React from 'react';

import type { DataType } from '~/shared/ui/data-types';
import { useDataTypeComponent } from '~/shared/ui/data-types';

import type { FilterItemProps } from '../../types';

export const DynamicFilterControl = <T extends DataType>(props: FilterItemProps<T>) => {
  const Filter = useDataTypeComponent(props.option.dataType, 'write', {
    onChange: (value) => props.onChange({ ...props.filter, value }),
    typeExtra: props.option.typeExtra,
  });

  return <Filter value={props.filter.value} />;
};
