import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { html } from '^/shared/utils';

(function () {
  'use strict';

  ng.module('finances.legacy').component('paymentsTable', {
    bindings: {
      payments: '<?',
      paymentsCount: '<?',
      onUpdate: '&',
      updateInvoiceCurrencyExchange: '&?',
      total: '<',
      filterLevel: '<?',
      mode: '<',
      edition: '<?',
      savedFilterChoices: '<?',
      bankAccountQueryParams: '<?',
      simplePayments: '<?',
      invoiceCurrency: '<?',
      invoiceApprovalStatus: '<?',
    },
    template: require('./payments-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    '$timeout',
    '$filter',
    'gettext',
    'GtUtils',
    'gtFilterService',
    'FinancesService',
    'DocumentsService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $timeout: ng.ITimeoutService,
    $filter: ng.IFilterService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    FinancesService: FinancesService,
    DocumentsService: any,
  ) {
    const vm = this;

    vm.totals = vm.totals || {};
    vm.hovering = false;
    vm.setHovering = setHovering;
    vm.save = save;
    vm.destroy = destroy;
    vm.onPercentageChange = onPercentageChange;
    vm.onCommissionPercentageChange = onCommissionPercentageChange;
    vm.openDocxModal = openDocxModal;
    vm.updateCurrencyExchangeByPaymantDate = updateCurrencyExchangeByPaymantDate;
    vm.edition = vm.edition || false;

    vm.count = 0;
    vm.tableData = {};

    ////////////////

    vm.$onInit = function () {
      vm.payments = vm.payments || [];
      vm.filterLevel = vm.filterLevel || 'payments-table';
      vm.mode = vm.mode || 'finance';
      vm.tableOptions = getTableOptions();
    };

    vm.$onChanges = function (changesObj: any) {
      if (changesObj.payments || changesObj.total) {
        $timeout(function () {
          vm.tableData = {
            rows: vm.payments || [],
            count: vm.count,
            total: vm.total || [],
          };

          vm.rows = vm.tableData.rows.reduce((options: any, item: any, index: number) => {
            options[item.id] = { index: index, editable: false };
            return options;
          }, {});
        });
      }
    };

    function setHovering(value: any) {
      vm.hovering = value;
    }
    function save(item: any) {
      vm.errors = null;
      if (item.id) {
        return FinancesService.Payment.update(item, null, (e: any) =>
          GtUtils.errorClb(e),
        ).$promise.then(() => saveCurrencyExchangeToInvoice());
      }
      return FinancesService.Payment.save(
        item,
        () => vm.onUpdate(),
        (e: any) => GtUtils.errorClb(e),
      ).$promise.then(() => saveCurrencyExchangeToInvoice());
    }

    function saveCurrencyExchangeToInvoice(item?: { currency_exchange: number }) {
      if ($rootScope.user.settings.SUBSTITUTE_CURRENCY_EXCHANGE && item?.currency_exchange) {
        vm.updateInvoiceCurrencyExchange({ data: item.currency_exchange });
      }
    }

    function updateCurrencyExchangeByPaymantDate(date: any) {
      FinancesService.CurrencyExchange.query({
        with_invoice: vm.payment.finance,
        exchange_date: $filter('date')(date, 'dd.MM.yyyy'),
      }).$promise.then((data: any) => {
        vm.payment.currency_exchange = data.results.length ? data.results.reverse()[0].id : null;
      });
    }

    function destroy(item: any) {
      const msg = gettext('Are you sure that you want delete this?');
      if (!confirm(msg)) {
        return;
      }
      return FinancesService.Payment.delete(
        { id: item.id },
        () => vm.onUpdate(),
        (e: any) => GtUtils.errorClb(e),
      ).$promise;
    }

    function getPercentage(item?: { amount: number; finance_amount_USD: number }) {
      const percentage = (item?.amount ?? 0) / (item?.finance_amount_USD ?? 1);
      return Math.round(percentage * 100 * 1000) / 1000;
    }

    function getCommissionPercentage(item?: { amount: number; commission: number }) {
      const commision = (item?.commission ?? 0) / (item?.amount ?? 1);
      return Math.round(commision * 100 * 1000) / 1000;
    }

    function onPercentageChange(calcPercent: any, item: any) {
      if (calcPercent) {
        vm.percentage = getPercentage();
      } else {
        const amount = item.finance_amount_USD * vm.percentage;
        item.amount = amount / 100;
      }
      onCommissionPercentageChange();
    }

    function onCommissionPercentageChange(
      calcPercent?: number,
      item?: { amount: number; commission: number },
    ) {
      if (calcPercent) {
        vm.commissionPercentage = getCommissionPercentage();
      } else {
        if (!item) {
          throw new Error('item is required');
        }
        const commision = item.amount * vm.commissionPercentage;
        item.commission = commision / 100;
      }
    }

    function openDocxModal(item: any) {
      return DocumentsService.generateDocxModal('Payment', item.id);
    }

    function getTableOptions() {
      const options: any = {
        tableName: vm.filterLevel,
        tableClass: 'table table-responsive main-table table-template payment-partial-table',
        filterLevel: vm.filterLevel,
        applyFilters: (params: { params: QueryParams }) => {
          gtFilterService.updateQueryParams(params.params, vm.filterLevel);
        },
        templateArgs: {
          setHovering: (value: any) => setHovering(value),
          save: (item: any) => save({ item: item }),
          destroy: (item: any) => destroy({ item: item }),
        },
        columnDefs: [] as any[],
        tabs: [],
        configurable: true,
        showMore: true,
        changePageSize: true,
      };
      options.columnDefs = [
        {
          title: gettext('Invoice'),
          columnName: 'number',
          class: 'td-left-align company',
          cellTemplate: html`
            <a
              ng-if="args.mode != 'credit-note'"
              class="initial-transform btn-link"
              ui-sref="gt.page.payment({id: item.finance})"
            >
              <i class="fa fa-credit-card-alt"></i> {[{item.finance_number | cut:true:50:'...' }]}
            </a>
          `,
          totalTemplate: html`
            <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate>
          `,
        },

        {
          title: gettext('date'),
          columnName: 'date',
          class: 'td-left-align nowrap',
          classExpr: "{ 'alert-success edit': args.rows[item.id].editable || !item.id }",
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-date-select
                id="id_payment_date"
                date-model="vm.payment.date"
                on-open-close="vm.onHover({ value: isOpen })"
                on-selected="vm.updateCurrencyExchangeByPaymantDate(vm.payment.date)"
              ></gt-date-select>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <i class="fa fa-calendar"></i> {[{ item.date | date:'dd.MM.yy'}]}
            </div>
          `,
        },
        {
          title: gettext('bank'),
          columnName: 'bank_name',
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item.bank_name">
              <i class="fa fa-university"></i> {[{ item.bank_name || "---"}]}
            </span>
          `,
        },
        {
          title: gettext('Amount'),
          columnName: 'amount',
          class: 'td-right-align nowrap',

          classExpr:
            "{ 'alert-danger': item.finance_invoice_type == 'incoming', 'alert-success': item.finance_invoice_type == 'outgoing' }",
          cellTemplate: html`
            <span args.mode !="credit-note">
              <span
                class="bigger-label label negative-number span-right"
                ng-if="item.finance_invoice_type == 'incoming'"
              >
                <i class="fa fa-minus"></i> {[{ item.amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency }]} </span>
              </span>
              <span
                class="bigger-label label positive-number span-right nowrap"
                ng-if="item.finance_invoice_type == 'outgoing'"
              >
                <i class="fa fa-plus"></i> {[{ item.amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency }]} </span>
              </span>
            </span>
          `,
          totalTemplate: html`
            <div ng-repeat="total in item.totals">
              <label> {[{ total.currency_symbol }]} </label>
              <div class="clearfix"></div>
              <span class="label positive-number label-strong tooltip">
                + {[{ total.total_outgoing || 0 | number: 2 }]}
              </span>
              <div class="clearfix"></div>
              <span class="label negative-number label-strong tooltip">
                - {[{ total.total_incoming || 0 | number: 2 }]}
              </span>
              <div class="clearfix"></div>
              <span class="label label-strong tooltip">
                = {[{ total.total_outgoing_without_incoming || 0 | number: 2 }]}
              </span>
            </div>
          `,
        },
        {
          title: gettext('Amount alternative'),
          columnName: 'amount_alternative',
          class: 'td-left-align',
          classExpr: "{ 'alert-success edit': args.rows[item.id].editable || !item.id }",
        },
        {
          title: gettext('Amount $'),
          columnName: 'amount_usd',
          class: 'td-right-align nowrap',
          cellTemplate: html`
            <span> $ {[{ item.amount_USD || 0 | number:2 }]} </span>
            <span ng-if="item.currency_exchange" class="label label_default">
              {[{ item.currency_exchange_rate || 0 | number:4 }]}
            </span>
            <span ng-if="item.invoice_currencyexchange_rate" class="label label_default">
              <i class="fa fa-credit-card-alt"></i>
              {[{ item.invoice_currencyexchange_rate || 0 | number:4 }]}
            </span>
          `,
          totalTemplate: html`
            <div>
              <label> $ </label>
              <div class="clearfix"></div>
              <span class="label positive-number label-strong tooltip">
                + {[{ item.total_outgoing_usd || 0 | number: 2 }]}
              </span>
              <div class="clearfix"></div>
              <span class="label negative-number label-strong tooltip">
                - {[{ item.total_incoming_usd || 0 | number: 2 }]}
              </span>
              <div class="clearfix"></div>
              <span class="label label-strong tooltip">
                = {[{ item.total_balance_usd || 0 | number: 2 }]}
              </span>
            </div>
          `,
        },
        {
          title: gettext('Commission'),
          columnName: 'commission',
          class: 'td-right-align',
          cellTemplate: html`
            <span ng-if="item.commission">
              {[{ item.commission || 0 | number:2 }]}
              <span class="smaller-label"> {[{ item.currency}]} </span>
            </span>
            <span class="label label_default smaller-label" ng-if="vm.commissionPercentage">
              {[{ vm.commissionPercentage || 0 | number:2 }]} %
            </span>
          `,
          totalTemplate: html`
            <div ng-repeat="total in item.totals">
              <label> {[{ total.currency_symbol }]} </label>
              <div class="clearfix"></div>
              <span class="label positive-number label-strong tooltip">
                + {[{ total.total_commission || 0 | number: 2 }]}
              </span>
            </div>
          `,
        },

        {
          title: gettext('From'),
          columnName: 'clientrole_from_name',
          class: 'td-left-align',
          cellTemplate: html`
            <span
              ng-if="item.clientrole_from_name"
              ui-sref="gt.role({ id: item.clientrole_from, role: item.clientrole_from_role_name })"
              class="btn-xs btn-link inside-table-btn"
            >
              <i class="fa fa-bookmark-o"></i> {[{ item.clientrole_from_name | cut:true:25:'...' ||
              "---" }]}
            </span>
          `,
        },

        {
          title: gettext('To'),
          columnName: 'clientrole_to_name',
          class: 'td-left-align',
          cellTemplate: html`
            <span
              ng-if="item.clientrole_to_name"
              ui-sref="gt.role({ id: item.clientrole_to, role: item.clientrole_to_role_name })"
              class="btn-xs btn-link inside-table-btn"
            >
              <i class="fa fa-bookmark"></i> {[{ item.clientrole_to_name | cut:true:25:'...' ||
              "---" }]}
            </span>
          `,
        },

        {
          title: gettext('Finance account'),
          columnName: 'financial_account',
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="$rootScope.user.settings.USE_FINANCE_ACCOUNTING">
              <span ng-if="item.debit_fin_account_title">
                {[{ item.debit_fin_account_title }]}
              </span>
              <span ng-if="item.credit_fin_account_title">
                / {[{ item.credit_fin_account_title }]}
              </span>
            </span>
            <div class="clearfix"></div>
            <span ng-if="item.account_name">
              <i class="fa fa-balance-scale"></i> {[{ item.account_name | cut:true:25:'...' ||
              "---"}]}
            </span>
          `,
        },

        {
          title: gettext('Business unit'),
          columnName: 'business_unit',
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item.business_unit_title">
              {[{ item.business_unit_title | cut:true:30:' ...' }]}
            </span>
          `,
        },
        {
          title: gettext('Note'),
          columnName: 'note',
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <a
                permission
                permission-only="['change_payment', 'add_payment']"
                class="btn btn-md btn-success btn_success col-xs-12"
                ng-click="args.save(item); args.rows[item.id].editable = false;"
                ng-disabled="!!$root.user.settings.BANK_ACCOUNT_REQUIRED_FOR_PAYMENT && !vm.payment.bank_account"
              >
                <i class="fa fa-floppy-o"></i> <translate>Save</translate>
              </a>
              <a
                class="btn btn-md col-xs-12"
                ng-if="vm.payment.id"
                ng-click="args.rows[item.id].editable = false;"
              >
                <i class="fa fa-times"></i> <translate>Close</translate>
              </a>
              <a
                permission
                permission-only="'delete_payment'"
                class="btn btn-md btn-danger btn_danger col-xs-12"
                ng-if="vm.payment.id"
                ng-click="args.destroy(item);args.rows[item.id].editable = false"
              >
                <i class="fa fa-trash"></i> <translate>Delete</translate>
              </a>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <small class="small-font-cell smaller-label yeswrap" ng-if="item.additional_info">
                {[{ item.additional_info }]}
              </small>
              <div class="clearfix"></div>
              <a
                class="btn btn-xs btn-blue-border"
                permission
                permission-only="'change_payment'"
                ng-click="args.rows[item.id].editable = true"
              >
                <i class="fa fa-pencil-square"></i>
              </a>
              <a
                ng-if="vm.edition"
                class="btn btn-xs btn-blue-border"
                ng-click="vm.openDocxModal()"
              >
                <i class="fa fa-file" aria-hidden="true"></i>
              </a>
            </div>
          `,
        },
      ];
      return options;
    }
  }
})();
