import { CostsLogisticRecord } from '~/features/execution/logistic';
import {
  BaseLogisticListContainerProps,
  BaseLogisticListModel,
} from '~/features/execution/logistic/common/components/logistic-container/base-logistic-list.model';
import { CostsLogisticRepository } from '~/features/execution/logistic/costs/services';
import { injectable } from '~/shared/lib/di';

export type CostsLogisticListContainerProps = BaseLogisticListContainerProps;

@injectable()
export class CostsLogisticListModel extends BaseLogisticListModel<
  CostsLogisticRecord,
  CostsLogisticRepository
> {
  entityRepoClass = CostsLogisticRepository;
}
