import type ng from 'angular';

import template from './analytics-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const AnalyticsPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'PageService',
    'ContractsService',
    'gettext',
    'MarketService',
    class {
      ContractsService: ContractsService;
      MarketService: any;
      PageService: PageService;
      filterLevel = 'market-analytics-page-view';
      gettext: ng.gettext.gettextFunction;
      constructor(
        PageService: PageService,
        ContractsService: ContractsService,
        gettext: ng.gettext.gettextFunction,
        MarketService: any,
      ) {
        this.PageService = PageService;
        this.ContractsService = ContractsService;
        this.gettext = gettext;
        this.MarketService = MarketService;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-prices-tab',
          buttons: [new this.PageService.buttons.Print(), new this.PageService.buttons.Refresh()],
          pages: this.MarketService.getPageConfig(),
        };
      }
    },
  ],
};
