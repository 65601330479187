export class FreightPositionService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getListData(params: { contract_type: string }) {
    if (params.contract_type == 'sale') {
      return this.$http({
        method: 'GET',
        url: '/api/contracts/sale-contracts-list/freight-position/',
        params: params,
      });
    } else {
      return this.$http({
        method: 'GET',
        url: '/api/contracts/purchase-contracts-list/freight-position/',
        params: params,
      });
    }
  }

  getTotalsData(params: { contract_type: string }) {
    if (params.contract_type == 'sale') {
      return this.$http({
        method: 'GET',
        url: '/api/contracts/sale-contracts-totals/freight-position/',
        params: params,
      });
    } else {
      return this.$http({
        method: 'GET',
        url: '/api/contracts/purchase-contracts-totals/freight-position/',
        params: params,
      });
    }
  }

  getConcolidatedData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/contracts/contracts/consolidated_freight_position/',
      params: params,
    });
  }
}
FreightPositionService.$inject = ['$http'];
