import type ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import template from './general-agreements-table-view.html?raw';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { QueryParams } from '^/app/core/types';
import { html } from '^/shared/utils';

export const GeneralAgreementsTableView = {
  bindings: {
    agreements: '<',
    count: '<',
    filterLevel: '<?',
    openGeneralAgreementsModal: '&?',
    removeAgreement: '&?',
    changeApproval: '&?',
    cloneGeneralAgreement: '&?',
    openDocxModal: '&?',
    openContractModal: '&?',
    openRoleModal: '&?',
    applyFilters: '&?',
  },
  template,
  controller: [
    '$scope',
    'gettext',
    'CustomValuesService',
    class {
      $scope: ng.IScope;
      CustomValuesService: CustomValuesService;
      agreements: any;
      applyFilters: any;
      changeApproval: any;
      cloneGeneralAgreement: any;
      count: number;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      hovering: any;
      openContractModal: any;
      openDocxModal: any;
      openGeneralAgreementsModal: any;
      openRoleModal: any;
      queryParams: QueryParams = {};
      removeAgreement: any;
      tableData: any;
      tableOptions: any;
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        CustomValuesService: CustomValuesService,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.CustomValuesService = CustomValuesService;

        this.hovering = false;
        this.tableData = {};
        this.tableOptions = {};
        this.filterLevel = '';
        this.agreements = [];
        this.count = 0;
      }

      $onInit() {
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.setTableOptions().catch(errorHandler);
        });
        this.setTableOptions().catch(errorHandler);
      }

      $onChanges(changes: any) {
        if (changes.agreements || changes.total || changes.count) {
          this.updateTableData();
        }
      }

      updateTableData() {
        this.tableData = {
          rows: this.agreements,
          count: this.count,
        };
      }

      getCustomFieldTableColumns() {
        return this.CustomValuesService.getCustomFieldTableColumns({
          purpose_model: 'generalagreement',
        });
      }

      setHovering(value: any) {
        this.hovering = value;
      }

      setTableOptions() {
        const options: any = {
          tableName: 'general-agreements-table',
          filterLevel: this.filterLevel,
          templateArgs: {
            openGeneralAgreementsModal: (agreement: any) =>
              this.openGeneralAgreementsModal({ agreement: agreement }),
            remove: (agreement: any) => this.removeAgreement({ agreement: agreement }),
            clone: (agreement: any) => this.cloneGeneralAgreement({ agreement: agreement }),
            approvalAction: (action: any, agreementId: number, contentType: any) =>
              this.changeApproval({
                action: action,
                agreementId: agreementId,
                contentType: contentType,
              }),
            openDocxModal: (id: number) => this.openDocxModal({ id: id }),
            openContractModal: (agreement: any) => this.openContractModal({ agreement: agreement }),
            openRoleModal: (id: number, model: any) => this.openRoleModal({ id: id, model: model }),
          },
          applyFilters: this.applyFilters,
          columnDefs: [] as any[],
          tabs: [],
          configurable: true,
          rowData: this.agreements,
          tableClass: 'main-table request-table',
        };

        options.columnDefs = [
          {
            columnName: 'number',
            title: this.gettext('#'),
            predicate: 'number',
            showAlways: true,
            class: 'td-left-align',
            cellTemplate: html`
              <div class="space-between">
                <ul
                  class="nav navbar-nav item-settings-dropdown"
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                >
                  <li>
                    <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                      # {[{ item.number || "---" }]}
                      <span ng-if="item.is_main_deal" class="badge label_default">
                        <translate>MAIN</translate>
                      </span>
                      <i class="fa fa-sort-desc hover-element"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          ui-sref="gt.generalagreement({id: item.id})"
                          class="btn btn-xs"
                          ng-click="this.$root.gtUtils.dismissAllModals();"
                        >
                          <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'change_generalagreement'"
                          ng-click="args.openGeneralAgreementsModal(item)"
                          class="btn btn-xs"
                        >
                          <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'change_generalagreement'"
                          ng-click="args.clone(item)"
                          class="btn btn-xs aligned-btn"
                        >
                          <i class="fa fa-files-o"></i> <translate>Clone</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          ng-click="args.remove(item)"
                          permission
                          permission-only="'delete_generalagreement'"
                          class="btn btn-xs tooltip"
                          data-tip="{[{ 'delete agreement'|translate }]}"
                        >
                          <i class="fa fa-trash"></i> <translate>Delete</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-click="args.approvalAction('approve', item.id, item.content_type);"
                        >
                          <i class="fa fa-thumbs-up"></i> <translate>Approve</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-click="args.approvalAction('decline', item.id, item.content_type);"
                        >
                          <i class="fa fa-thumbs-down"></i> <translate>Decline</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-disabled="item.approval_status != 'rejected'"
                          ng-click="args.approvalAction('reactivate', item.id, item.content_type);"
                        >
                          <i class="fa fa-refresh"></i> <translate>Reactivate approving</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Generate document'|translate }]}"
                          ng-click="args.openDocxModal(item.id)"
                        >
                          <i class="fa fa-file" aria-hidden="true"></i> +
                          <translate>Template</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Create contract'|translate }]}"
                          ng-click="args.openContractModal(item)"
                        >
                          <i class="fa fa-file-text" aria-hidden="true"></i> +
                          <translate>Contract</translate>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <span
                  class="label header-badge pull-right"
                  ng-class="{ 'label_success': item.status == 'new', 'label_default': item.status == 'approved', 'label_warning': item.status == 'process', 'label_danger': item.status == 'rejected'} "
                >
                  <span ng-if="item.status == 'new'"> <translate>New</translate> </span>
                  <span ng-if="item.status == 'process'"> <translate>In progress</translate> </span>
                  <span ng-if="item.status == 'approved'"> <translate>Approved</translate> </span>
                  <span ng-if="item.status == 'rejected'"> <translate>Rejected</translate> </span>
                </span>
              </div>
            `,
          },
          {
            columnName: 'regional_managers',
            title: this.gettext('regional managers'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="exporter_set" ng-if="item.regional_managers_set">
                <span ng-repeat="regional_manager in item.regional_managers_set">
                  {[{ regional_manager.first_name }]} {[{ regional_manager.last_name }]}
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
          },
          {
            columnName: 'budget',
            title: this.gettext('Budget'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span ng-if="item.budget_title"> {[{ item.budget_title }]} </span>
            `,
          },
          {
            columnName: 'type_of_activities',
            title: this.gettext('Type of activities'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="exporter_set" ng-if="item.type_of_activities_set">
                <span ng-repeat="item in item.type_of_activities_set">
                  {[{ item.title }]}
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
          },
          {
            columnName: 'custom_status',
            title: this.gettext('custom status'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span
                ng-if="item.custom_status_title"
                class="label"
                ng-class="{ 'label_calculate': item.custom_status_codename == 'washout', 'label_success': item.custom_status_codename == 'new', 'label-default': item.custom_status_codename == 'cancelled', 'label_default': item.custom_status_codename == 'executed', 'label_warning': item.custom_status_codename == 'shipment' } "
              >
                {[{ item.custom_status_title }]}
              </span>
            `,
          },
          {
            title: this.gettext('Approval Status'),
            columnName: 'approval_staus',
            class: 'td-left-align',
            cellTemplate: html`
              <span
                class="label"
                ng-if="item.approval_status != 'wait'"
                ng-class="{'label_success': item.approval_status == 'approved', 'label_warning': item.approval_status == 'process', 'label_danger': item.approval_status == 'rejected' }"
                style="margin-left: 2px"
              >
                <i
                  class="fa"
                  ng-class="{'fa-check': item.approval_status == 'approved', 'fa-spin fa-spinner': item.approval_status == 'process', 'fa-times': item.approval_status == 'rejected' }"
                ></i>
                <translate>{[{ item.approval_status }]}</translate>
              </span>
            `,
          },
          {
            title: this.gettext('Type'),
            columnName: 'type',
            class: 'td-left-align',
            cellTemplate: html`
              <span
                class="label"
                ng-class="{'label_success': item.agreement_type == 'purchase', 'label_danger': item.agreement_type == 'sale' }"
              >
                <i
                  class="fa"
                  ng-class="{'fa-arrow-down': item.agreement_type == 'purchase', 'fa-arrow-up': item.agreement_type == 'sale' }"
                ></i>
                <span ng-if="item.agreement_type == 'purchase'">
                  <translate>Purchase</translate>
                </span>
                <span ng-if="item.agreement_type == 'sale'"> <translate>Sale</translate> </span>
              </span>
            `,
          },
          {
            title: this.gettext('Conclusion date'),
            columnName: 'conclusion_date',
            class: 'td-left-align',
            cellTemplate: html`
              <i class="fa fa-calendar"></i> {[{ item.conclusion_date | date:'dd.MM.yy' || "---" }]}
            `,
          },
          {
            columnName: 'contracts',
            title: this.gettext('Contracts'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span ng-if="item.contract_numbers && item.contract_numbers.length <= 3">
                <a
                  ng-repeat="position in item.contract_numbers"
                  ui-sref="gt.page.contract({id: position.id })"
                  style="display: block"
                >
                  <i class="fa fa-file-text"></i> {[{position.contract_number | cut:true:40:'...' ||
                  "---" }]}
                  <div class="clearfix"></div>
                </a>
              </span>

              <span
                class="btn-link"
                ng-if="item.contract_numbers && item.contract_numbers.length > 3"
                ng-click="item.contractsExpanded = !item.contractsExpanded"
              >
                <i class="fa fa-file-text"></i> {[{item.contract_numbers.length}]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.contractsExpanded">
                <a
                  ng-repeat="position in item.contract_numbers"
                  ui-sref="gt.page.contract({id: position.id })"
                  style="display: block"
                >
                  <i class="fa fa-file-text"></i> {[{position.contract_number | cut:true:40:'...' ||
                  "---" }]}
                  <div class="clearfix"></div>
                </a>
              </span>
              <span ng-if="!item.contract_numbers.length"> --- </span>
            `,
          },
          {
            columnName: 'passports',
            title: this.gettext('Passports'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span ng-if="item.contract_numbers && item.contract_numbers.length <= 3">
                <span ng-repeat="contract in item.contract_numbers">
                  <span ng-if="contract.passport_numbers.length">
                    <i class="fa fa-file-text"></i> {[{contract.contract_number | cut:true:40:'...'
                    || "---" }]}:
                    <a
                      ng-repeat="passport in contract.passport_numbers"
                      ui-sref="gt.page.passport({id: passport.passport })"
                      class="label smaller-label label_default"
                    >
                      <i class="fa fa-exchange"></i> {[{passport.passport_title | cut:true:40:'...'
                      || "---" }]}
                    </a>
                    <div class="clearfix"></div>
                  </span>
                </span>
              </span>
              <span
                class="btn-link"
                ng-if="item.contract_numbers && item.contract_numbers.length > 3"
                ng-click="item.passportsExpanded = !item.passportsExpanded"
              >
                <i class="fa fa-file-text"></i> {[{item.contract_numbers.length}]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.passportsExpanded">
                <span ng-repeat="contract in item.contract_numbers" style="display: block">
                  <i class="fa fa-file-text"></i> {[{contract.contract_number | cut:true:40:'...' ||
                  "---" }]}:
                  <a
                    ng-repeat="passport in contract.passport_numbers"
                    ui-sref="gt.page.passport({id: passport.passport })"
                    class="label smaller-label label_default"
                  >
                    <i class="fa fa-exchange"></i> {[{passport.passport_title | cut:true:40:'...' ||
                    "---" }]}
                  </a>
                  <div class="clearfix"></div>
                </span>
              </span>
              <span ng-if="!item.contract_numbers || item.contract_numbers.length === 0">
                ---
              </span>
            `,
          },
          {
            title: this.gettext('Supplier'),
            columnName: 'supplier',
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="exporter_set" ng-if="item.potential_suppliers_set">
                <span ng-repeat="supplier in item.potential_suppliers_set">
                  <i class="fa fa-bookmark-o"></i> {[{ supplier.name | cut:true:25:' ...'}]}
                </span>
              </span>
              <span
                style="margin-top: 4px;"
                ng-if="item.supplier_name"
                class="tooltip btn-link"
                data-tip="{[{ item.supplier_name }]}"
              >
                <a
                  ng-click="args.openRoleModal(item.supplier, 'Supplier')"
                  ng-if="item.agreement_type =='purchase'"
                  ng-class="{'bigger-label': this.$root.user.settings.DEFAULT_VALUES['hide_owner']}"
                >
                  <i class="fa fa-bookmark-o"></i> {[{item.supplier_name | cut:true:30:'...' ||
                  "---" }]}
                </a>
                <a
                  ng-click="args.openRoleModal(item.supplier, 'Owner')"
                  ng-if="item.agreement_type =='sale' && !this.$root.user.settings.DEFAULT_VALUES['hide_owner']"
                >
                  <i class="fa fa-home"></i> {[{item.supplier_name | cut:true:30:'...' || "---" }]}
                </a>
              </span>
              <div class="clearfix"></div>
              <span class="label-opacity" ng-if="!item.supplier_name" style="margin-top: 4px;">
                <i class="fa fa-bookmark-o"></i> ---
              </span>
            `,
          },
          {
            title: this.gettext('Buyer'),
            columnName: 'buyer',
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="exporter_set" ng-if="item.potential_buyers_set">
                <span ng-repeat="buyer in item.potential_buyers_set">
                  <i class="fa fa-bookmark"></i> {[{ buyer.name | cut:true:25:' ...'}]}
                </span>
              </span>
              <span
                ng-if="item.buyer_name"
                class="tooltip btn-link"
                data-tip="{[{ item.buyer_name }]}"
              >
                <a
                  ng-click="args.openRoleModal(item.buyer, 'Buyer')"
                  ng-if="item.agreement_type =='sale'"
                  ng-class="{'bigger-label': this.$root.user.settings.DEFAULT_VALUES['hide_owner']}"
                >
                  <i class="fa fa-bookmark"></i> {[{ item.buyer_name | cut:true:30:'...' || "---"
                  }]}
                </a>
                <a
                  ng-click="args.openRoleModal(item.buyer, 'Owner')"
                  ng-if="item.agreement_type =='purchase' && !this.$root.user.settings.DEFAULT_VALUES['hide_owner']"
                >
                  <i class="fa fa-home"></i> {[{ item.buyer_name | cut:true:30:'...' || "---" }]}
                </a>
              </span>
              <span class="label-opacity" ng-if="!item.buyer_name">
                <i class="fa fa-bookmark"></i> ---
              </span>
            `,
          },
          {
            title: this.gettext('Product'),
            columnName: 'commodity',
            class: 'td-left-align',
            cellTemplate: html`
              <span ng-if="item.commodity_title">
                {[{item.commodity_title | cut:true:30:'...' }]}
              </span>
              <span ng-if="!item.commodity_title"> --- </span>
            `,
          },
          {
            title: this.gettext('Conditions'),
            columnName: 'conditions',
            class: 'td-left-align',
            cellTemplate: html`
              <span ng-if="item.percent_conditions">
                {[{item.percent_conditions || 0 | gtDecimal:2 }]}%
              </span>
            `,
          },
          {
            title: this.gettext('Ticket volume'),
            columnName: 'tickets_volume',
            class: 'td-right-align',
            cellTemplate: html`
              <div class="progress">
                <div
                  class="progress-bar progress_bar_default"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: {[{ item.total_tickets_volume / (item.volume || 1) * 100 }]}%"
                >
                  {[{ item.total_tickets_volume || 0 | number:3 }]} / {[{item.volume || 0 | number:3
                  }]}
                </div>
              </div>
            `,
          },
          {
            title: this.gettext('Volume'),
            columnName: 'volume',
            class: 'td-right-align',
            cellTemplate: html`
              <div class="progress">
                <div
                  class="progress-bar progress_bar_default"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: {[{ item.total_volume / (item.volume || 1) * 100 }]}%"
                >
                  {[{ item.total_volume || 0 | number:3 }]} / {[{item.volume || 0 | number:3 }]}
                </div>
              </div>
            `,
          },
          {
            title: this.gettext('Volume departed'),
            columnName: 'volume_departed',
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.total_volume_departed">
                {[{ item.total_volume_departed || 0 | number:3 }]}
              </span>
              <span ng-if="!item.total_volume_departed" class="label-opacity"> --- </span>
            `,
          },
          {
            title: this.gettext('Agreement value'),
            columnName: 'agreement_value',
            class: 'td-left-align',
            cellTemplate: html`{[{item.amount || 0 | number:2 }]} `,
          },
          {
            title: this.gettext('Contracts value'),
            columnName: 'contracts_value',
            class: 'td-right-align',
            cellTemplate: html`{[{item.total_value || 0 | number:2 }]} `,
          },
          {
            title: this.gettext('Remains'),
            columnName: 'remains',
            class: 'td-left-align',
            cellTemplate: html`{[{ item.amount - item.total_value || 0 | number:2 }]} `,
          },
          {
            title: this.gettext('Execution'),
            columnName: 'execution',
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span
                class="tooltip"
                ng-if="item.date_of_execution"
                ng-class="{
                'label label_warning': ((item.end_of_execution - this.$root.serverTime) / 1000 / 60 / 60 / 24 > 0 && (item.end_of_execution - this.$root.serverTime) / 1000 / 60 / 60 / 24 < this.$root.user.settings.DATE_EXECUTION_REMINDER && item.end_of_execution > $root.serverTime) && !item.final_volume,
                'label label_danger': ((item.end_of_execution - this.$root.serverTime) / 1000 / 60 / 60 / 24 <= 0 && (item.end_of_execution - this.$root.serverTime) / 1000 / 60 / 60 / 24 > this.$root.user.settings.DATE_EXECUTION_ALERT) && !item.final_volume
              }"
                data-tip="{[{ 'execution period' | translate }]} {[{item.date_of_execution | date:'dd MMM yy'}]} - {[{item.end_of_execution | date:'dd MMM yy'}]}"
              >
                <i class="fa fa-calendar"></i> {[{item.date_of_execution || "---" |
                date:'dd.MM.yy'}]}
                <div class="clearfix"></div>
                <i class="fa fa-calendar"></i> {[{item.end_of_execution || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            title: this.gettext('Notes'),
            columnName: 'notes',
            class: 'td-left-align small-font-cell',
            cellTemplate: html`{[{ item.note | cut:true:40:'...' || "---" }]} `,
          },
          {
            title: this.gettext('Tax'),
            columnName: 'Tax',
            class: 'td-left-align',
            cellTemplate: html`
              <span class="label label_default" ng-if="item.tax_registration == 'free_tax_invoice'">
                <translate>Free tax invoice</translate>
              </span>
              <span class="label label_default" ng-if="item.tax_registration == 'on_first_event'">
                <translate>On first event</translate>
              </span>
            `,
          },
        ];

        return this.getCustomFieldTableColumns().then((columns: any) => {
          options.columnDefs.push(...columns);
          this.tableOptions = options;
          this.$scope.$applyAsync();
        });
      }
    },
  ],
};
