import ng from 'angular';

import { DisbursementblListContainer } from './disbursementbl-list-container/disbursementbl-list-container.component';
import { DisbursementblListPage } from './disbursementbl-list-page/disbursementbl-list-page.component';
import { DisbursementblListTableView } from './disbursementbl-list-table-view/disbursementbl-list-table-view.component';
import { DisbursementBlService } from './disbursementbls.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const disbursementblsConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('logistics.disbursementbls', {
      url: '/disbursementbl/list/',
      component: 'disbursementblListPage',
      data: {
        pageTitle: gettext('Disbursement bill of lading'),
      },
      resolve: {
        filterLevel: () => {
          return 'disbursementbl-list-page';
        },
        queryParams: [
          'PageService',
          (PageService: PageService) => {
            return PageService.syncUrlFilter('disbursementbl-list-page');
          },
        ],
      },
    });
  },
];

export const disbursementbls = ng
  .module('execution.transport.disbursementbls', [])
  .component('disbursementblListPage', DisbursementblListPage)
  .component('disbursementblListContainer', DisbursementblListContainer)
  .component('disbursementblListTableView', DisbursementblListTableView)
  .service('DisbursementBlService', DisbursementBlService)
  .config(disbursementblsConfig).name;
