import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './settings-parsing-container.html?raw';
import type { SettingsParsingService } from '../settings-parsing.service';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const SettingsParsingContainer = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'SettingsParsingService',
    'CoreService',
    'gtFilterService',
    'gettext',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      SettingsParsingService: SettingsParsingService;
      CoreService: CoreService;
      gtFilterService: GtFilterService;
      gettext: ng.gettext.gettextFunction;

      count: number;
      filterLevel: string;
      queryParams: QueryParams = {};
      quotesParsingConfigs: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        SettingsParsingService: SettingsParsingService,
        CoreService: CoreService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.SettingsParsingService = SettingsParsingService;
        this.CoreService = CoreService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.quotesParsingConfigs = [];
        this.filterLevel = 'settings-parsing-container-filter-level';
        this.queryParams = {};
        this.count = 0;
      }

      $onInit() {
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        return this.SettingsParsingService.getQuoteParsingConfigsData(this.queryParams).then(
          (data: any) => {
            this.quotesParsingConfigs = data.results;
            this.count = data.count;
          },
        );
      }

      applyFilters(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      openQuotesParsingConfigsModal(parsingConfig: any) {
        return this.SettingsParsingService.quotesParsingConfigsModal(parsingConfig).then(() =>
          this.updateData(),
        );
      }

      deleteQuotesParsingConfig(parsingConfig: any) {
        if (!confirm(this.gettext('Are you sure that you want to delete parsing config?'))) {
          return;
        }
        return this.SettingsParsingService.destroyQuotesParsingConfig(parsingConfig).then(() => {
          this.updateData();
          notify(this.gettext('Parsing config template removed'));
        });
      }
    },
  ],
};
