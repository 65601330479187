import React from 'react';

import { ChevronDownIcon } from '~/shared/ui/icons';
import { Dropdown, type DropdownMenuItem } from '~/shared/ui/kit/dropdown-menu';

import type { ActionDef, DataRecord } from '../../lib/types';

export const RecordsActions = <R extends DataRecord>({
  actions,
  records,
}: {
  actions: ActionDef<R>[];
  records: R[];
}) => {
  const items: DropdownMenuItem[] = React.useMemo(
    () =>
      actions.map((action) => ({
        ...action,
        value: action.label,
        onClick: async () => await Promise.resolve(action.action(records)),
        disabled: action?.isDisabled?.(records),
      })),
    [actions, records],
  );

  return <Dropdown items={items} trigger={<ChevronDownIcon />} />;
};
