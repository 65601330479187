import { financesGeneralExpensesPredictionsRetrieve } from '@gt/api';

import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { GeneralExpenses, GeneralExpensesListParams } from '../lib';

export class GeneralExpensesRepository extends EntityRepository<
  GeneralExpenses,
  GeneralExpensesListParams
> {
  readonly entityName = 'finances.GeneralExpenses';

  getFn = (_id: number): Promise<GeneralExpenses> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: GeneralExpensesListParams): Promise<Paginated<GeneralExpenses>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await financesGeneralExpensesPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  createFn = (_entity: GeneralExpenses): Promise<GeneralExpenses> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<GeneralExpenses>): Promise<GeneralExpenses> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
