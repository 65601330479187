import { financesBankAccountsPredictionsRetrieve } from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { BankAccount, BankAccountListParams } from '../lib';

export class BankAccountRepository extends EntityRepository<BankAccount, BankAccountListParams> {
  readonly entityName = 'finances.BankAccount';

  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await financesBankAccountsPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };
  getFn = (_id: number): Promise<BankAccount> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: BankAccountListParams): Promise<Paginated<BankAccount>> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: BankAccount): Promise<BankAccount> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<BankAccount>): Promise<BankAccount> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
