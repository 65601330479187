import type ng from 'angular';

import template from './voyage-tariff-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const VoyageTariffListContainer = {
  bindings: {
    initQueryParams: '<?',
    tableName: '<?',
    tableClass: '<?',
    addNewRaw: '<?',
    showOnlyTable: '<?',
    filterLevel: '<',
    onUpdate: '&',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'VoyageTariffService',
    'gettext',
    class voyageTariffContainer {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      VoyageTariffService: any;
      addNewRaw: any;
      dataCount: number;
      dataList: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      onUpdate: any;
      queryParams: QueryParams = {};
      showOnlyTable: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        VoyageTariffService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.VoyageTariffService = VoyageTariffService;

        this.filterLevel = '';
        this.queryParams = {};

        this.dataList = [];
        this.dataCount = 0;

        this.addNewRaw = true;
        this.showOnlyTable = false;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'voyage-tariff-page-view';
        this.queryParams = { page_size: 20, ...this.initQueryParams };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: QueryParams) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.VoyageTariffService.getListData(this.queryParams).then((data: any) => {
          if (this.addNewRaw) {
            data.data.results.unshift({
              voyage: this.queryParams.voyage,
              price: 0,
            });
          }
          this.dataList = data.data.results;
          this.dataCount = data.data.count;
          this.GtUtils.overlay('hide');
        });
      }

      saveItem(item: any) {
        return this.VoyageTariffService.save(item).then(
          () => {
            this.updateData();
            this.onUpdate(this.dataList);
          },
          (error: any) => {
            this.GtUtils.errorClb(error);
            this.updateData();
          },
        );
      }

      destroy(item: any) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return false;
        }
        return this.VoyageTariffService.delete({ id: item }).then(
          () => this.updateData(),
          this.GtUtils.errorClb,
        );
      }
    },
  ],
};
