import React from 'react';

import { container } from '~/shared/lib/di';

import { ThemeStore } from '../services';

export const useTheme = () => {
  const content = React.useMemo(() => ({ themeStore: container.resolve(ThemeStore) }), []);

  return content;
};
