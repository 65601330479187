import { CaretSortIcon } from '@radix-ui/react-icons';
import React from 'react';

import { useComponent } from '~/shared/lib/components';
import { Async } from '~/shared/lib/state';
import type { ListOption } from '~/shared/lib/types';
import { addClassList } from '~/shared/lib/utils';
import { messageIntent } from '~/shared/ui/data-types/lib/constants';
import { CheckIcon, ResetIcon, SearchIcon } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '~/shared/ui/kit/command';
import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';

import type { AutocompleteProps } from './autocomplete.model';
import { AutocompleteModel } from './autocomplete.model';

const ActiveAutocomplete = ({
  value,
  message,
  loading,
  emptyMessage,
  options,
  onSelect,
  onChange,
  onOpenChange,
  onSearch,
}: Omit<AutocompleteProps, 'value'> & {
  value: ListOption[];
  onSelect: (value: string) => void;
}) => {
  const messageRef = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<HTMLSpanElement>(null);

  const [open, setOpen] = React.useState(false);

  const onOpenChangeHandler = React.useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);

      onOpenChange?.(isOpen);
      if (!isOpen) {
        onChange?.(value);
      }
    },
    [onChange, onOpenChange, value],
  );

  const handleMouseEnter = React.useCallback(() => {
    if (titleRef.current) {
      titleRef.current.style.animation = 'scrollText 3.5s linear infinite';
    }
  }, []);
  const handleMouseLeave = React.useCallback(() => {
    if (titleRef.current) {
      titleRef.current.style.animation = 'none';
      titleRef.current.style.transform = 'translateX(0%)';
    }
  }, []);
  React.useMemo(
    () => addClassList(messageRef?.current, message ? messageIntent[message.type] : ''),
    [message],
  );

  return (
    <div ref={messageRef} data-testid="autocomplete">
      {message && (
        <p className="text-text-main-on-primary-section text-xs font-medium">{message.text}</p>
      )}
      <Popover open={open} onOpenChange={onOpenChangeHandler}>
        <PopoverTrigger asChild>
          <Button variant="tertiary" aria-expanded={open} fullWidth>
            <div className="relative flex w-full items-center justify-between overflow-hidden">
              <div className="relative flex flex-1 justify-start overflow-hidden">
                <span
                  className="text-text-main-primary inline-block max-w-[calc(100%-24px)] text-xs whitespace-nowrap transition-transform duration-2000 ease-out"
                  role="marquee"
                  aria-hidden="true"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  ref={titleRef}
                >
                  {value?.map((i) => i.label).join(', ') ?? ''}
                </span>
              </div>
              <CaretSortIcon
                className="text-text-main-primary h-4 w-4 shrink-0 opacity-50"
                aria-hidden="true"
              />
            </div>
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-[200px] p-0">
          <Command shouldFilter={false}>
            <CommandInput
              onValueChange={onSearch}
              role="input"
              className="text-text-main-primary h-9"
              postfix={<SearchIcon />}
              data-testid="autocomplete-input"
            />
            <CommandList className="max-h-[300px] overflow-y-auto">
              {loading ? (
                <div className="flex items-center justify-center py-2">
                  <ResetIcon className="text-text-main-on-primary-section h-5 w-5 animate-spin" />
                </div>
              ) : (
                <>
                  <div className="text-text-main-primary">
                    <CommandEmpty>{emptyMessage}</CommandEmpty>
                  </div>
                  <CommandGroup>
                    {options.map((opt) => (
                      <CommandItem
                        key={opt.value}
                        value={opt.value}
                        onSelect={onSelect}
                        className="hover:bg-transparent-light text-text-main-primary flex cursor-pointer justify-between rounded-md px-2 py-2 pl-2 text-xs"
                      >
                        <span className="truncate">{opt.label}</span>
                        {value?.find((v) => v.value === opt.value) && <CheckIcon />}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const DisabledAutocomplete: React.FC<{ value?: ListOption[] }> = ({ value }) => {
  return (
    <div data-testid="disabled-autocomplete">
      {value?.length ? value.map((i) => i.label).join(', ') : ''}
    </div>
  );
};

export const Autocomplete = (props: AutocompleteProps) => {
  const { model } = useComponent(props, AutocompleteModel);

  if (props.disabled) {
    return <DisabledAutocomplete value={props.value} />;
  }
  return (
    <Async args={{ value: model.combinedValues$ }}>
      {({ value }) => <ActiveAutocomplete {...props} onSelect={model.selectValue} value={value} />}
    </Async>
  );
};
