import template from './consolidated-operations-passport-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const ConsolidatedOperationsPassportContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'OperationsService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      OpenLongPosition: any;
      OpenShortPosition: any;
      OperationsService: any;
      closeLongDetails: any;
      closeMatchedDetails: any;
      closeShortDetails: any;
      filterLevel = 'consolidated-operations-passport-container';
      groupedDerivatives: any;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      operations: any;
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        OperationsService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.OperationsService = OperationsService;

        this.operations = {};
        this.groupedDerivatives = {};
        this.closeLongDetails = true;
        this.closeShortDetails = true;
        this.closeMatchedDetails = true;
      }

      $onInit() {
        this.OpenShortPosition = Object.keys(this.initQueryParams).length;
        this.OpenLongPosition = Object.keys(this.initQueryParams).length;
        this.filterLevel = this.filterLevel || 'consolidated-operations-passport-container';
        this.queryParams = { page_size: 999, ...this.initQueryParams };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.OperationsService.getOperationConsolidatedPassport(this.queryParams)
          .then((data: any) => (this.operations = data), this.GtUtils.errorClb)
          .then(() => this.groupUpDerivatives())
          .finally(() => this.GtUtils.overlay('hide'));
      }

      groupUpDerivatives(key?: any) {
        key = key || 'month';

        const roundOf = (value: any) =>
          value && value !== null ? Math.round(value * 10000) / 10000 : 0;

        const sortKey = (object: any) =>
          Object.keys(object)
            .sort((a, b) => object[b][key] - object[a][key])
            .reduce((sorted: any, prop) => {
              sorted[prop] = object[prop];
              return sorted;
            }, {});

        this.groupedDerivatives = sortKey(
          Object.values(
            this.operations.results.reduce((grouped: any, operation: any) => {
              const id = operation.derivative_id;
              if (!grouped[id]) {
                grouped[id] = {
                  id: id,
                  title: operation.derivative_title,
                  month: operation.derivative_month.setDate(1),
                  commodity: operation.derivative_cargo_title,
                  commodity_id: operation.derivative_cargo_id,
                  long_quantity_sum: operation.long_quantity_sum,
                  short_quantity_sum: operation.short_quantity_sum,
                  long_net_pnl_mtm: operation.long_net_pnl_mtm || 0,
                  short_net_pnl_mtm: operation.short_net_pnl_mtm || 0,
                };
              } else {
                grouped[id].long_quantity_sum += operation.long_quantity_sum;
                grouped[id].short_quantity_sum += operation.short_quantity_sum;
                grouped[id].long_net_pnl_mtm += operation.long_net_pnl_mtm || 0;
                grouped[id].short_net_pnl_mtm += operation.short_net_pnl_mtm || 0;
              }
              return grouped;
            }, {}),
          )
            .filter((item: any) => !(item.long_net_pnl_mtm === 0 && item.short_net_pnl_mtm === 0))
            .map((item: any) => ({
              ...item,
              long_quantity_sum: roundOf(item.long_quantity_sum),
              short_quantity_sum: roundOf(item.short_quantity_sum),
              long_net_pnl_mtm: roundOf(item.long_net_pnl_mtm),
              short_net_pnl_mtm: roundOf(item.short_net_pnl_mtm),
            }))
            .reduce((grouped, derivative) => {
              grouped[derivative[key]] = [...(grouped[derivative[key]] || []), derivative];
              return grouped;
            }, {}),
        );
      }

      applyFilters(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
        this.gtFilterService.updateQueryParams(params, 'operations-page-view');
      }

      resetFilters() {
        this.gtFilterService.setQueryParams(
          {
            page_size: 20,
            ...this.initQueryParams,
          },
          this.filterLevel,
        );
      }
    },
  ],
};
