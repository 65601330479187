import ng from 'angular';

import { ConsolidatedFreightPositionContainer } from './consolidated-freight-position-container/consolidated-freight-position-container.component';
import { FreightPositionListContainer } from './freight-position-list-container/freight-position-list-container.component';
import { FreightPositionListTableView } from './freight-position-list-table-view/freight-position-list-table-view.component';
import { FreightPositionPageView } from './freight-position-page-view/freight-position-page-view.component';
import { FreightPositionService } from './freight-position.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const freightPosition = ng
  .module('reports.freightPosition', [])
  .service('FreightPositionService', FreightPositionService)
  .component('freightPositionPageView', FreightPositionPageView)
  .component('freightPositionListContainer', FreightPositionListContainer)
  .component('freightPositionListTableView', FreightPositionListTableView)
  .component('consolidatedFreightPositionContainer', ConsolidatedFreightPositionContainer)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('position.freight', {
        url: '/freight',
        component: 'freightPositionPageView',
        data: {
          pageTitle: gettext('Freight Position'),
        },
        resolve: {
          filterLevel: () => {
            return 'freight-position-page-view';
          },
          queryParams: [
            'PageService',
            (PageService: PageService) => PageService.syncUrlFilter('freight-position-page-view'),
          ],
        },
      });
    },
  ]).name;
