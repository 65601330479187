import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

export const logisticDetailsPage = {
  bindings: {},
  template: require('./logistic-detail-page.html?raw'),
  controller: [
    '$rootScope',
    '$scope',
    '$stateParams',
    '$state',
    'GtUtils',
    'PageService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      invoice: any;
      logistic: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $stateParams: ng.ui.IStateParamsService,
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        PageService: PageService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.PageService = PageService;
        this.gettext = gettext;

        this.invoice = {};
        this.filterLevel = 'logistic-page-view';
      }

      $onInit() {
        this.logistic = { id: this.$stateParams.id };
        const config = this.PageService.getConfig();
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            pages: [],
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            pages: [],
          });
        }
      }

      updateConfigWithFilterLevel(logistic: any, filterLevel: string) {
        const config = this.PageService.getConfig();
        let isOpen;

        if (ng.equals(config, {}) || config?.sidebar?.is_open) {
          isOpen = true;
        } else {
          isOpen = false;
        }
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            sidebar: {
              verboseName: 'Logistic',
              modelName: 'logistic.logistic',
              instance: logistic,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
            pages: [],
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            sidebar: {
              verboseName: 'Logistic',
              modelName: 'logistic.logistic',
              instance: logistic,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
            pages: [],
          });
        }
      }
    },
  ],
};
