import React from 'react';

import { Link } from '~/shared/lib/router';
import { Button } from '~/shared/ui/kit/button';

import darkImgSrc from './dark-access-denied.svg';
import lightImgSrc from './light-access-denied.svg';

export const UnauthorizedPage: React.FC = () => {
  return (
    <section className="bg-background-main-secondary h-screen">
      <section className="flex h-full flex-col items-center justify-center gap-8">
        <div>
          <img
            src={lightImgSrc}
            alt="server-error-img"
            className="block dark:hidden"
            width={240}
            height={216}
          />
          <img
            src={darkImgSrc}
            alt="server-error-img"
            className="hidden dark:block"
            width={240}
            height={216}
          />
        </div>
        <div className="flex flex-col items-center gap-4">
          <h1 className="text-text-main-primary text-2xl font-semibold">Access Denied</h1>
          <p className="text-text-main-secondary text-xs">
            You are attempting to access a restricted resource.
          </p>
        </div>
        <Link to="/" className="w-[200px]">
          <Button variant="default" size="md" fontWeight="semibold" fullWidth>
            Back to content
          </Button>
        </Link>
      </section>
    </section>
  );
};
