import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './intermodal-transports-list-container.html?raw';
import type { IntermodalTransportsService } from '../../intermodal-transports.service';
import { type QueryParams } from '../../types';

import { type GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import { type GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

type IntermodalLogistic = {
  id?: number;
  children_list?: IntermodalLogistic[];
  supplier_contract?: number;
  buyer_contract?: number;
  volume_transloaded?: number;
  balance?: number;
  selected_bl_volume?: number;
  bl_volume_balance?: number;
  bl_boarded_balance?: number;
  vehicle_number?: string;
  logistic_to?: number;
  logistic_to__vehicle_number?: string;
  logistic_from?: number;
  vehicle_type?: string;
  level?: number;
  isNew?: boolean;
  isConnecting?: boolean;
  _showCheckbox?: boolean;
  _showChildrenCheckbox?: boolean;
  _selected?: boolean;
  exporter_id?: number;
  exporter_name?: string;
};

type ChildLogisticFilter = {
  vehicle_number_iexact?: string;
  supplier_contract?: number;
};

type VehicleTypeOption = {
  id: string | null;
  title: string;
};

class IntermodalTransportsListContainerController implements ng.IController {
  queryParams: QueryParams = {};
  filterLevel?: string;
  checkboxField?: string;
  childLogisticFilter: ChildLogisticFilter = {};
  connectingToBl?: boolean;
  hovering?: boolean;
  initQueryParams?: QueryParams;
  invoiceCondition?: string;
  invoiceType?: string;
  invoicing?: boolean;
  invoicingType?: string;
  logistics?: IntermodalLogistic[];
  logisticsCount?: number;
  exportersList: string[] = [];
  role?: string;
  selectedLogistics?: IntermodalLogistic[];
  setHovering?: (value: boolean) => boolean;
  volumeBase?: string;
  readonly?: boolean;
  filtersEnabled = false;
  showFilters = false;
  totalSelectedVolume = 0;

  filters = {
    vehicle_type: '',
  };
  vehicleTypes: VehicleTypeOption[] = [];

  filtersConfig: { type: string; predicate: string; label: string; resource: string }[] = [];
  filtersForSaleContract: { type: string; predicate: string; label: string; resource: string }[] =
    [];
  filtersForPurchaseContract: {
    type: string;
    predicate: string;
    label: string;
    resource: string;
  }[] = [];
  receivingDateFilterConfig: {
    type: string;
    startDateField: string;
    endDateField: string;
    label: string;
  }[] = [];
  static readonly $inject = [
    '$scope',
    'gettext',
    'GtUtils',
    'gtFilterService',
    'IntermodalTransportsService',
    'LogisticsService',
  ];

  constructor(
    private readonly $scope: ng.IScope,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly GtUtils: GtUtilsService,
    private readonly gtFilterService: GtFilterService,
    private readonly IntermodalTransportsService: IntermodalTransportsService,
    private readonly LogisticsService: LogisticsService,
  ) {}

  $onInit() {
    this.invoicing = false;
    this.filtersConfig = [
      {
        type: 'ui-multiselect',
        predicate: 'exporter_list',
        label: this.gettext('Exporter'),
        resource: 'clients.clientrole',
      },
    ];
    this.filtersForSaleContract = [
      {
        type: 'ui-multiselect',
        predicate: 'buyer_contract_list',
        label: this.gettext('Sale Contracts'),
        resource: 'contracts.SaleContract',
      },
    ];
    this.filtersForPurchaseContract = [
      {
        type: 'ui-multiselect',
        predicate: 'supplier_contract_list',
        label: this.gettext('Purchase Contracts'),
        resource: 'contracts.PurchaseContract',
      },
    ];
    this.receivingDateFilterConfig = [
      {
        type: 'daterange',
        startDateField: 'receiving_date_range_after',
        endDateField: 'receiving_date_range_before',
        label: this.gettext('Receiving date range'),
      },
    ];

    this.vehicleTypes = [
      { id: null, title: this.gettext('All') },
      { id: 'car', title: this.gettext('Car') },
      { id: 'wagon', title: this.gettext('Wagon') },
      { id: 'container', title: this.gettext('Container') },
      { id: 'vessel', title: this.gettext('Vessel') },
    ];

    this.connectingToBl = false;
    this.hovering = false;
    this.setHovering = (value: boolean) => (this.hovering = value);
    this.logistics = [];
    this.logisticsCount = 0;
    this.selectedLogistics = [];
    this.invoiceCondition = 'prepay';

    this.filterLevel = this.filterLevel ?? 'intermodal-transports-container';
    this.queryParams = { ...this.initQueryParams };
    this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
      this.updateData(data);
    });
    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
  }

  $onChanges(changes: any) {
    if (changes.initQueryParams) {
      this.queryParams = Object.assign(this.queryParams, this.initQueryParams);
      this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
    }
  }
  applyReceivingDateFilter() {
    const date = this.queryParams.receiving_date;

    if (!date) {
      delete this.queryParams.receiving_date;
    } else if (typeof date === 'string') {
      const parsedDate = new Date(date);
      if (!isNaN(parsedDate.getTime())) {
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
        const day = String(parsedDate.getDate()).padStart(2, '0');
        this.queryParams.receiving_date = `${year}-${month}-${day}`;
      }
    }

    this.updateData();
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
    this.filtersEnabled = !this.filtersEnabled;
  }

  tableFilters = (logistic: IntermodalLogistic) => {
    if (!this.filtersEnabled) {
      return true;
    }

    const matchesVehicleType =
      !this.filters.vehicle_type || logistic.vehicle_type === this.filters.vehicle_type;

    const matchesExporters =
      !this.queryParams.exporter_list?.length ||
      this.queryParams.exporter_list.includes(logistic.exporter_id ?? 0);

    return matchesVehicleType && matchesExporters;
  };

  applyVehicleTypeFilter() {
    if (!this.queryParams.vehicle_type_list) {
      delete this.queryParams.vehicle_type_list;
    }
    this.updateData();
  }

  cleanEditMode() {
    this.connectingToBl = false;
    this.invoicing = false;
    this.invoicingType = undefined;
    this.logistics?.forEach((item: any) => {
      item._selected = false;
      item._showCheckbox = false;
      item._showChildrenCheckbox = false;
    });
  }
  updateTableData() {
    this.GtUtils.overlay('show');
    return this.IntermodalTransportsService.getIntermodalTransportsListInfo(this.queryParams)
      .then((data: any) => {
        if (this.queryParams.next) {
          this.logistics = this.logistics ? this.logistics.concat(data.results) : data.results;
          delete this.queryParams.next;
        } else {
          this.logistics = data.results;
        }
        this.logisticsCount = data.count;

        if (this.logistics && this.logistics.length > 0) {
          this.exportersList = Array.from(
            new Set(
              this.logistics
                .filter((logistic) => logistic.exporter_name)
                .map((logistic) => logistic.exporter_name!),
            ),
          );
        } else {
          this.exportersList = [];
        }

        return this.logistics;
      })
      .finally(() => this.GtUtils.overlay('hide'));
  }
  updateData(queryParams?: Record<string, string | string[]>) {
    this.GtUtils.overlay('show');
    this.cleanEditMode();
    this.queryParams = queryParams ?? this.queryParams;

    const startDate = this.queryParams.receiving_date_range_after;
    const endDate = this.queryParams.receiving_date_range_before;

    if (startDate && endDate && startDate === endDate) {
      this.queryParams.receiving_date_range_after = startDate;
      this.queryParams.receiving_date_range_before = endDate;
    }

    return this.updateTableData().then(() => this.GtUtils.overlay('hide'));
  }

  loadLogisticTemplate(item: IntermodalLogistic) {
    item.children_list = item.children_list ?? [];
    item.children_list.unshift({
      level: (item.level ?? 0) + 1,
      isConnecting: true,
      supplier_contract: item.supplier_contract,
    });
  }

  loadLogistic(item: IntermodalLogistic, child: IntermodalLogistic) {
    let isNew = false;
    const logisticsQueryParams: ChildLogisticFilter = {
      vehicle_number_iexact: child.vehicle_number,
    };
    if (child.supplier_contract) {
      logisticsQueryParams.supplier_contract = child.supplier_contract;
    }
    this.LogisticsService.Logistic.get(logisticsQueryParams).$promise.then((response: any) => {
      if (!response.count) {
        isNew = true;
      } else if (response.count > 1) {
        this.childLogisticFilter = {
          vehicle_number_iexact: child.vehicle_number,
        };
        if (child.supplier_contract) {
          this.childLogisticFilter.supplier_contract = child.supplier_contract;
        }
      } else {
        const childLogistic = response.results[0];
        child.logistic_to = childLogistic.id;
        child.logistic_to__vehicle_number = childLogistic.vehicle_number;
        child.logistic_to__vehicle_number = childLogistic.vehicle_number;
      }
      child.isConnecting = false;
      child.logistic_from = item.id;
      child.level = (item.level ?? 0) + 1;
      child.vehicle_type = isNew ? 'container' : undefined;
      child.isNew = isNew;
      child.volume_transloaded = item.balance;
      child.supplier_contract = child.supplier_contract ?? item.supplier_contract;
      child.buyer_contract = item.buyer_contract;
    });
  }

  create(item: any) {
    let chain;
    if (item.isNew) {
      chain = this.LogisticsService.Logistic.save(
        {
          vehicle_number: item.vehicle_number,
          vehicle_type: item.vehicle_type,
          consignment_number: item.consignment_number,
          loading_date: item.loading_date,
          volume_received: item.volume_transloaded,
          buyer_contract: item.buyer_contract,
          supplier_contract: item.supplier_contract,
          cargo: item.cargo_id,
          station_receiving_id: item.station_receiving_id,
          is_supplier_invoiced: item.is_supplier_invoiced,
          is_supplier_paid: item.is_supplier_paid,
          is_buyer_invoiced: item.is_buyer_invoiced,
          is_buyer_paid: item.is_buyer_paid,
        },
        (data: any) => {
          item.logistic_to = data.id;
        },
      ).$promise;
    } else {
      chain = Promise.resolve();
    }
    chain.then(() => {
      const newTransport = {
        date_transloaded: item.date_transloaded,
        volume_transloaded: item.volume_transloaded,
        logistic_from: item.logistic_from,
        logistic_to: item.logistic_to,
      };
      this.IntermodalTransportsService.createIntermodalTransport(newTransport)
        .then(() => {
          this.LogisticsService.Logistic.update(
            { partial: true },
            {
              id: item.logistic_to,
              transloader_invoiced: item.transloader_invoiced,
              transloader_paid: item.transloader_paid,
              buyer_invoiced: item.buyer_invoiced,
              buyer_paid: item.buyer_paid,
            },
            () => {
              notify(this.gettext('Logistic loaded'));
              this.updateData();
            },
          );
        })
        .catch(this.GtUtils.errorClb);
    });
  }

  update(item: any) {
    const transport = {
      id: item.intermodaltransport_id,
      date_transloaded: item.date_transloaded,
      volume_transloaded: item.volume_transloaded,
    };
    this.IntermodalTransportsService.updateIntermodalTransport(transport).then(() => {
      notify(this.gettext('Logistic updated'));
      this.updateData();
    });
  }

  clone(logistic: any, child: any) {
    logistic.children_list = logistic.children_list || [];
    const clonedChild = { ...child };
    delete clonedChild.id;
    delete clonedChild.$$hashKey;
    delete clonedChild.intermodaltransport_id;
    clonedChild.isNew = true;
    clonedChild.level = (logistic.level || 0) + 1;
    clonedChild.logistic_from = logistic.id;
    logistic.children_list.unshift(clonedChild);
  }

  delete(id: number) {
    this.IntermodalTransportsService.delete(id).then(() => this.updateData());
  }

  deleteFromList(item: any, parent: any) {
    parent.children_list.splice(parent.children_list.indexOf(item), 1);
  }

  applyFilters(params: QueryParams) {
    this.gtFilterService.updateQueryParams(params, this.filterLevel);
  }
  applyLeakyFilters({ params }: { params: QueryParams }) {
    this.queryParams = { ...this.queryParams, ...params };
    this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);

    this.updateData();
  }

  private formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  getLoadingDate(item: any, logisticResource: any) {
    logisticResource?.get({ id: item.logistic_to, fields: ['loading_date'] }, (data: any) => {
      item.loading_date = data.loading_date;
      item.date_transloaded = data.loading_date;
    });
  }

  selectAllByValue(checked: boolean) {
    if (!checked) {
      this.resetSelectedLogistics();
      return;
    }
    if (this.invoicingType === 'incoming') {
      this.selectMainLogistics(checked);
    } else if (this.invoicingType === 'outgoing') {
      this.selectChildrenLogistics(checked);
    } else {
      this.selectMainLogistics(checked);
      this.selectChildrenLogistics(checked, true);
    }
    this.updateTotalSelectedVolume();
  }

  selectMainLogistics(checked: boolean) {
    this.selectedLogistics = [];
    this.logistics?.forEach((logistic: any) => {
      if (!logistic._showCheckbox) {
        return;
      }
      if (
        this.selectedLogistics &&
        !this.selectedLogistics.map((log) => log.id).includes(logistic.id)
      ) {
        logistic._selected = checked;
      }
    });
    this.selectedLogistics = this.logistics?.filter((log) => log._selected);
  }

  selectChildrenLogistics(checked: boolean, append?: boolean) {
    if (!append) {
      this.selectedLogistics = [];
    }
    this.logistics?.forEach((logistic) => {
      logistic.children_list?.forEach((child: any) => {
        if (!child._showChildrenCheckbox) {
          return;
        }
        child._selected = checked;
      });

      const selectedChilds = logistic.children_list?.filter((child: any) => child._selected);
      if (selectedChilds?.length) {
        selectedChilds.forEach((childLogistic: any) => {
          if (!this.selectedLogistics?.map((log: any) => log.id).includes(childLogistic.id)) {
            this.selectedLogistics?.push(childLogistic);
          }
        });
      }
    });
  }

  updateInvoicingParams(changedFields: any) {
    if (!changedFields) {
      return;
    }

    Object.entries(changedFields)
      .filter(([_, value]) => value != undefined)
      .forEach(([key, value]) => {
        if (key in this) {
          (this[key as keyof IntermodalTransportsListContainerController] as any) = value;
        }
      });

    this.refreshCheckboxes();
  }

  startConnectingToBl() {
    if (
      !this.queryParams.supplier_contract &&
      !this.queryParams.buyer_contract &&
      !this.queryParams.passport
    ) {
      this.setConnectingBl();
    }

    this.queryParams.page_size = 999999;

    this.updateTableData().then(() => this.setConnectingBl());
  }
  setConnectingBl() {
    this.connectingToBl = true;
    this.volumeBase = 'volume_received';
    return this.refreshCheckboxes();
  }
  setInvoicing(invoicingType: string) {
    this.invoicing = true;
    this.invoicingType = invoicingType;
    this.refreshCheckboxes();
  }
  refreshBlCheckboxes() {
    this.logistics?.forEach((item) => {
      this.validateBillCheckbox(item);
      item.children_list?.forEach((child: any) => {
        this.validateBillCheckbox(child);
      });
    });
    if (this.logistics) {
      this.logistics = [...this.logistics];
    }
  }
  refreshCheckboxes() {
    if (this.invoicing) {
      return this.refreshInvoiceCheckboxes();
    }
    if (this.connectingToBl) {
      return this.refreshBlCheckboxes();
    }
    return this.logistics?.forEach((item) => {
      item._showCheckbox =
        item.selected_bl_volume && item.selected_bl_volume > 0 ? this.connectingToBl : undefined;
      item._showChildrenCheckbox = item._showCheckbox;
    });
  }

  validateBillCheckbox(item: IntermodalLogistic) {
    if (this.volumeBase === 'volume_boarded') {
      item.selected_bl_volume = item.bl_boarded_balance;
      item._showCheckbox =
        item.selected_bl_volume && item.selected_bl_volume > 0 ? this.connectingToBl : undefined;
      item._showChildrenCheckbox = item._showCheckbox;
    }
    if (this.volumeBase === 'volume_received') {
      item.selected_bl_volume = item.bl_volume_balance;
      item._showCheckbox =
        item.selected_bl_volume && item.selected_bl_volume > 0 ? this.connectingToBl : undefined;
      item._showChildrenCheckbox = item._showCheckbox;
    }
  }

  refreshInvoiceCheckboxes() {
    this.checkboxField =
      this.invoicingType == 'outgoing' ? '_showChildrenCheckbox' : '_showCheckbox';
    this.role = this.invoiceType == 'outgoing' ? 'buyer' : 'supplier';
    this.logistics?.forEach((item) => {
      if (this.invoicingType == 'incoming') {
        this.validateInvoiceCheckbox(item);
      } else if (this.invoicingType == 'outgoing' && item.children_list?.length) {
        item.children_list.forEach((child) => {
          this.validateInvoiceCheckbox(child);
        });
      }
    });
  }

  validateInvoiceCheckbox(item: any) {
    if (!this.invoicing) {
      item._showChildrenCheckbox = false;
      item._showCheckbox = false;
      item._selected = false;
    } else if (this.checkboxField) {
      item[this.checkboxField] = true;
    }
    if (this.checkboxField && !item[this.role + '_contract']) {
      item[this.checkboxField] = false;
      return;
    }

    if (
      this.checkboxField &&
      ((item[this.role + '_ten_invoice_id'] && item[this.role + '_nineteen_invoice_id']) ||
        (item[this.role + '_invoiced'] && item.payment_conditions == 100) ||
        (this.invoiceCondition == 'prepay' && item[this.role + '_invoiced']) ||
        item[this.role + '_to_be_invoiced'] == 0 ||
        (this.invoiceCondition == 'balance' &&
          (item[this.role + '_invoiced_balance'] ||
            item[this.role + '_to_be_invoiced_balance'] == 0)))
    ) {
      item[this.checkboxField] = false;
    }
  }

  updateTotalSelectedVolume() {
    this.totalSelectedVolume = (this.selectedLogistics ?? []).reduce(
      (sum, item) => sum + (item.selected_bl_volume ?? 0),
      0,
    );
  }

  resetSelectedLogistics() {
    this.selectedLogistics = [];
    this.totalSelectedVolume = 0;
    this.logistics?.forEach((logistic) => {
      logistic._selected = false;
      logistic._showCheckbox = false;
      logistic._showChildrenCheckbox = false;

      logistic.children_list?.forEach((child) => {
        child._selected = false;
        child._showCheckbox = false;
        child._showChildrenCheckbox = false;
      });
    });
  }

  onFinish() {
    this.resetSelectedLogistics();
    this.updateData();
  }
  onCheckboxClick(logistic: IntermodalLogistic) {
    logistic._selected = !logistic._selected;
    if (logistic._selected && this.selectedLogisticsIsDuplicated(logistic)) {
      return;
    }

    const index = this.selectedLogistics?.indexOf(logistic);
    if (logistic._selected) {
      this.selectedLogistics?.push(logistic);
    } else if (index !== undefined && index !== -1) {
      this.selectedLogistics?.splice(index, 1);
    }

    this.updateTotalSelectedVolume();
  }

  selectedLogisticsIsDuplicated(logistic: IntermodalLogistic) {
    const index = this.selectedLogistics?.map((log: any) => log.id).indexOf(logistic.id);
    if (index != -1) {
      notify(this.GtUtils.translate(this.gettext('This logistic already selected.')), 'warning');
      logistic._selected = false;
      logistic._showChildrenCheckbox = false;
      logistic._showCheckbox = false;
      return true;
    }
    return false;
  }

  openLogisticModal(logistic: IntermodalLogistic) {
    this.LogisticsService.logisticModal(logistic).then(() => this.updateData());
  }
}

export const intermodalTransportsListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    readonly: '<',
  },
  template: template,
  controller: IntermodalTransportsListContainerController,
};
