import template from './client-list-list-view.html?raw';

import type { GtRootScopeService } from '^/app/core/types';

export const ClientListListView = {
  bindings: {
    clients: '<',
    count: '<',
    filterLevel: '<',
    applyFilters: '&',
    openClientModal: '&',
    openClientDetails: '&',
    openEmailModal: '&',
    approveClient: '&',
    createConfirmationTask: '&',
    getQueryParams: '&',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      clients = [];
      count = 0;
      gettext: ng.gettext.gettextFunction;
      constructor($rootScope: GtRootScopeService, gettext: ng.gettext.gettextFunction) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;
      }
    },
  ],
};
