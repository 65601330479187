import React from 'react';

import type { BiDashboard } from '~/features/common/bi-dashboards/types';
import { cn } from '~/shared/lib/utils';
import { ChartArcsIcon, CheckIcon, ChevronDownIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

export const DashboardSelect: React.FC<{
  dashboard: BiDashboard;
  dashboardOptions: BiDashboard[];
  onSelect: (value: BiDashboard) => void;
}> = ({ dashboardOptions, dashboard, onSelect }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex w-fit items-center gap-1 text-xs outline-none">
        <ChartArcsIcon size={14} className="text-text-main-secondary" />
        <div className="rounded-sm p-2">
          <div className="flex items-center gap-1">
            <p className="text-text-main-secondary text-xs">{dashboard.title}</p>
            <ChevronDownIcon size={14} className="text-stroke-main-stronger" />
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex w-[250px] flex-col gap-0.5 p-1">
        {dashboardOptions.map((dashboardItem) => (
          <DropdownMenuItem
            className={cn(
              'hover:bg-transparent-light flex w-full cursor-pointer items-center justify-between p-1 transition duration-300 ease-in-out',
              dashboardItem.id === dashboard.id && 'bg-transparent-light',
            )}
            onClick={() => onSelect(dashboardItem)}
            key={dashboardItem.id}
          >
            <p className="text-text-main-primary text-xs">{dashboardItem.title}</p>
            {dashboardItem.id === dashboard.id && (
              <CheckIcon size={14} className="text-stroke-main-stronger" />
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
