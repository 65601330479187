import {
  accountsLoginCreate,
  accountsLogoutCreate,
  accountsUsersCurrentUserRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';

import { type AuthCredentials, type CurrentUser } from '../lib';

@singleton()
export class AuthRepository {
  async login(credentials: AuthCredentials): Promise<string> {
    await accountsLoginCreate({ body: credentials });
    return 'ok';
  }

  async logout(): Promise<void> {
    await accountsLogoutCreate();
  }

  getCurrentUser = async (): Promise<CurrentUser> => {
    const { data } = await accountsUsersCurrentUserRetrieve();
    return data;
  };
}
