import type ng from 'angular';

import template from './drivers-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const DriversPageView = {
  template,
  controller: [
    '$scope',
    'gettext',
    'PageService',
    'DriversService',
    class {
      $scope: ng.IScope;
      DriversService: any;
      PageService: PageService;
      filterLevel = 'drivers-page-view';
      gettext: ng.gettext.gettextFunction;
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
        DriversService: any,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.PageService = PageService;
        this.DriversService = DriversService;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.PageService.syncUrlFilter(this.filterLevel);
      }

      getPageConfig() {
        return {
          class: 'page-header-main-pl-tab',
          buttons: [new this.PageService.buttons.Filters()],
          filters: {
            filterLevel: this.filterLevel,
            search: true,
            dates: true,
            dateSelects: [
              {
                argument: 'create_time',
                placeholder: this.gettext('Created at'),
              },
              {
                argument: 'update_time',
                placeholder: this.gettext('Updated at'),
              },
              {
                argument: 'license_expire_date',
                placeholder: this.gettext('License expiration date'),
              },
              {
                argument: 'passport_expire_date',
                placeholder: this.gettext('Passport expiration date'),
              },
            ],
            searchFilters: [
              {
                argument: 'first_name_search',
                placeholder: this.gettext('First name'),
              },
              {
                argument: 'middle_name_search',
                placeholder: this.gettext('Middle name'),
              },
              {
                argument: 'last_name_search',
                placeholder: this.gettext('Last name'),
              },
              {
                argument: 'ukrainian_id_search',
                placeholder: this.gettext('Driver passport seria & number (Ukrainian)'),
              },
              {
                argument: 'passport_number_search',
                placeholder: this.gettext('Driver passport seria & number'),
              },
            ],
            multiSelects: [
              {
                argument: 'business_unit_list',
                placeholder: this.gettext('Business unit'),
                resource: 'core.BusinessUnit',
              },
              {
                argument: 'author_list',
                placeholder: this.gettext('Author'),
                resource: 'auth.User',
              },
            ],
          },
          pages: this.DriversService.getPageConfig(),
        };
      }
    },
  ],
};
