import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('disbursementblContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      tableName: '<?',
      showConnected: '<?',
      connectedDbls: '<?',
      contract: '<?',
      hideDocsCols: '<?',
      readonly: '<',
    },
    template: require('./disbursementbl-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'FinancesService', 'GtUtils', 'gtFilterService', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    FinancesService: FinancesService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.disbursementblList = [];
    vm.disbursementblCount = 0;
    vm.totals = {};
    vm.onConnect = onConnect;
    vm.save = save;
    vm.saveAll = saveAll;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'disbursementbl-container';
      vm.tableName = vm.tableName || vm.filterLevel;
      vm.queryParams = { ...vm.initQueryParams };
      updateData();
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('disbursementbl-list__remove', function (ev: any, data: any) {
        remove(data);
      });
      $scope.$on('disbursementbl-list__update', function () {
        updateData();
      });
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateData();
      });
    };

    ////////////////

    function updateData() {
      return FinancesService.DisbursementBL.info(vm.queryParams, function (data: any) {
        $scope.$emit('disbursementbl-container__updated', data);
        vm.disbursementblList = data.results;
        if (vm.connectedDbls) {
          vm.disbursementblList.forEach(function (dbl: any) {
            if (vm.connectedDbls.indexOf(dbl.id) > -1) {
              dbl._toConnect = true;
            }
          });
        }
        vm.disbursementblCount = data.count;
        FinancesService.DisbursementBL.totals(vm.queryParams, function (totals: any) {
          vm.totals = totals;
        });
      }).$promise;
    }

    function save(item: any) {
      return FinancesService.DisbursementBL.update(
        item,
        () => updateData(),
        (data: any) => GtUtils.errorClb(data),
      ).$promise;
    }

    function saveAll(items: any) {
      return FinancesService.DisbursementBL.bulkCreateOrUpdate(
        items,
        () => updateData(),
        (data: any) => GtUtils.errorClb(data),
      ).$promise;
    }

    function onConnect(item: any) {
      $scope.$emit('disbursementbl-connection__update', item);
    }

    function remove(disbursementbl: any) {
      return FinancesService.DisbursementBL.delete(
        {
          id: disbursementbl.id,
        },
        function () {
          notify(gettext('Disbursement bill of lading removed'));
          return updateData();
        },
      ).$promise;
    }
  }
})();
