import type ng from 'angular';

import template from './custom-values-container.html?raw';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

type CustomValuesQueryParams = QueryParams & { purpose_model?: string };

export const CustomValuesContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
    mode: '<?',
    objectId: '<?',
    customValues: '<?',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'CustomValuesService',
    'CoreService',
    class {
      $scope: ng.IScope;
      CoreService: CoreService;
      CustomValuesService: CustomValuesService;
      count: number;
      customValues: any;
      data: any;
      filterLevel = 'custom-values-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      GtUtils: GtUtilsService;
      initQueryParams: Partial<CustomValuesQueryParams> = {};
      mode: any;
      objectId?: number;
      queryParams?: CustomValuesQueryParams;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        CustomValuesService: CustomValuesService,
        CoreService: CoreService,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.CustomValuesService = CustomValuesService;
        this.CoreService = CoreService;
        this.GtUtils = this.CustomValuesService.GtUtils;
        this.gettext = this.CustomValuesService.gettext;

        this.filterLevel = '';

        this.data = [];
        this.mode = undefined;
        this.count = 0;
        this.initQueryParams = {};
        this.customValues = [];
      }
      $onInit() {
        this.data = [];

        this.$scope.$on(
          'gt-filter-updated_' + this.filterLevel,
          (ev, data: CustomValuesQueryParams) => {
            this.queryParams = data;
            if (!data.purpose || data.purpose_model) {
              if (!data.purpose_model) {
                throw new Error('Purpose model should be a string');
              }
              return this.CoreService.getModelContentType(data.purpose_model).then(
                (contentType: any) => {
                  this.queryParams = { ...data, purpose: contentType.id };
                  this.updateData();
                },
              );
            }
          },
        );
        this.queryParams = { page_size: 20, ...this.initQueryParams };
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        if (this.filterLevel === 'custom-values-page-view') {
          return this.CustomValuesService.CustomValueResource.query({
            serializer: 'info',
            ...this.queryParams,
          }).$promise.then((response: any) => {
            this.data = response.results;
            this.count = response.count;
          });
        }
        return this.CustomValuesService.getFieldValuesList(this.queryParams ?? {}, this.mode).then(
          (response: any) => {
            this.data = response.results;
            this.count = response.count;
            this.$scope.$applyAsync();
          },
        );
      }

      saveItem(item: any) {
        if (this.objectId) {
          return this.CustomValuesService.saveValue(item).then(() => this.updateData());
        } else {
          const newCustomValue = {
            field: item.field,
            purpose: item.purpose,
            purpose_model: item.purpose_model,
            ...(item.field.field_type === 'number' && { value_number: item.value_number }),
            ...(item.field.field_type === 'date' && { value_date: item.value_date }),
            ...(item.field.field_type === 'boolean' && { value_boolean: item.value_boolean }),
            ...(item.field.field_type === 'string' && { value_string: item.value_string }),
            ...(item.field.field_type === 'text' && { value_text: item.value_text }),
            ...(item.field.field_type === 'choice' && { value_choice: item.value_choice }),
            ...(item.field.field_type === 'multiple_choices' && {
              value_multiple_choices: item.value_multiple_choices,
            }),
            value: this.CustomValuesService.getValue(item),
          };

          item.value = this.CustomValuesService.getValue(item);

          const dataIndex = this.data.findIndex(
            (dataItem: any) => dataItem.field.id === item.field.id,
          );
          const customValuesIndex = this.customValues.findIndex(
            (customValue: any) => customValue.field.id === item.field.id,
          );

          if (dataIndex !== -1) {
            this.data = [...this.data.slice(0, dataIndex), item, ...this.data.slice(dataIndex + 1)];
          } else {
            this.data = [...this.data, item];
          }

          if (customValuesIndex !== -1) {
            this.customValues = [
              ...this.customValues.slice(0, customValuesIndex),
              newCustomValue,
              ...this.customValues.slice(customValuesIndex + 1),
            ];
          } else {
            this.customValues = [...this.customValues, newCustomValue];
          }
          this.$scope.$emit('custom-values-updated__' + item.purpose_model, this.customValues);
        }
      }

      deleteItem(item: { id: number }) {
        return this.CustomValuesService.deleteValue(item.id).then(() => this.updateData());
      }
    },
  ],
};
