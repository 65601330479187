import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('transhipmentPageView', {
    bindings: {},
    template: require('./transhipment-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'ClientsService',
    'GtUtils',
    'gtFilterService',
    'PageService',
    'LogisticsService',
    'FinancesService',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    ClientsService: any,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    PageService: PageService,
    LogisticsService: LogisticsService,
    FinancesService: FinancesService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.elevators = [];
    vm.count = 0;
    vm.openElevatorModal = openElevatorModal;
    vm.updateElevators = updateElevators;
    vm.theadConfig = getTheadConfig();
    vm.filterLevel = 'transhipment-page-view';
    vm.queryParams = { show_expired: false };
    vm.uahRate = 1;
    vm.currency = 'uah';
    vm.convert = convert;

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateElevators();
      });
      updateElevators();
    };

    ////////////////

    function updateElevators() {
      GtUtils.overlay('show');
      FinancesService.CurrencyExchange.query(
        {
          currency: GtUtils.getLocalCurrency(),
        },
        function (data: any) {
          vm.uahRate = data.count && data.results[0].rate;
        },
      );
      return ClientsService.Elevator.query(vm.queryParams, function (data: any) {
        vm.elevators = data.results;
        vm.count = data.count;
        GtUtils.overlay('hide');
      }).$promise;
    }

    function convert(value: any, from: any) {
      from = from || 'uah';
      value = parseFloat(value) || 0;
      const rate = vm.uahRate || 1;
      if (from == vm.currency) {
        return value;
      } else if (from == 'uah') {
        return value / rate;
      } else {
        return value * rate;
      }
    }

    function getPageConfig() {
      const config: any = { class: 'page-header-main-logistics-tab', pages: [] };
      config.buttons = [
        new PageService.buttons.Print(),
        new PageService.buttons.Refresh(vm.filterLevel),
      ];
      config.pages = LogisticsService.getPagesConfig();
      return config;
    }

    function openElevatorModal(elevator: any) {
      elevator = elevator || { model_name: 'Elevator' };
      return ClientsService.roleModal(elevator).then(function (data: any) {
        if (data != 'cancel') {
          updateElevators();
        }
      });
    }

    function getTheadConfig() {
      return [
        { title: gettext('Elevator'), predicate: 'name' },
        { title: gettext('Region'), predicate: 'regions' },
        { title: gettext('District'), predicate: 'district' },
        { title: gettext('City'), predicate: 'city' },
        { title: gettext('Notes'), predicate: 'logistics_info' },
        { title: gettext('Receiving'), predicate: 'receiving_service' },
        { title: gettext('Cleaning'), predicate: 'cleaning_service' },
        { title: gettext('Drying'), predicate: 'drying_service' },
        { title: gettext('Storage'), predicate: 'storage_service' },
        { title: gettext('Loading'), predicate: 'loading_service' },
        { title: gettext('Other'), predicate: 'services' },
        { title: gettext('TOTAL'), predicate: 'calculated_services' },
      ];
    }
  }
})();
