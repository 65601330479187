import type ng from 'angular';

import template from './loan-container.html?raw';
import numberCellTemplate from './number-cell-template.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ImportExportContractsService } from '^/app/deals/contracts/import-export/import-export.service';
import { html } from '^/shared/utils';

export const LoanContainer = {
  bindings: {
    filterLevel: '<',
    queryParams: '<',
    addButton: '<?',
  },
  template,
  controller: [
    '$timeout',
    'LoanService',
    'gtFilterService',
    'ImportExportContractsService',
    'GtUtils',
    'gettext',
    class {
      $timeout: ng.ITimeoutService;
      GtUtils: GtUtilsService;
      ImportExportContractsService: ImportExportContractsService;
      LoanService: any;
      Notification: any;
      filterLevel = 'loan-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams?: QueryParams = {};
      showAllCurrenciesTotals: any;
      tableApi: any;
      tableOptions: any;
      total: any;
      constructor(
        $timeout: ng.ITimeoutService,
        LoanService: any,
        gtFilterService: GtFilterService,
        ImportExportContractsService: ImportExportContractsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$timeout = $timeout;
        this.LoanService = LoanService;
        this.gtFilterService = gtFilterService;
        this.ImportExportContractsService = ImportExportContractsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.tableOptions = this.getTableOptions();
        this.tableApi = undefined;
        this.showAllCurrenciesTotals = true;
        this.total = {};
        this.queryParams = this.queryParams ?? {};
        this.queryParams.serializer = 'table_info';
      }

      $onInit() {
        this.gtFilterService.setQueryParams(this.queryParams ?? {}, this.filterLevel);
        this.gtFilterService.subscribe(
          this.filterLevel,
          (params) => {
            this.queryParams = params;
            this.updateTableData();
          },
          this.queryParams,
        );

        this.updateTableData();
      }

      updateTableData = () => {
        this.GtUtils.overlay('show');
        return this.LoanService.getLoanTableData(this.queryParams).then((data: any) => {
          this.tableApi.setRowData(data.results);
          this.tableApi.setRowCount(data.count);
          this.LoanService.getLoanCurrencyTotalsData(this.queryParams).then((data: any) => {
            this.total = data;
            this.GtUtils.overlay('hide');
          });
        });
      };

      editLoan = (loan: any) => {
        this.LoanService.loanModal(loan).then(() =>
          this.gtFilterService.setQueryParams(this.queryParams ?? {}, this.filterLevel),
        );
      };

      openImportExportContractsModal() {
        return this.ImportExportContractsService.importExportModal().then(() =>
          this.updateTableData(),
        );
      }

      openLoanModal() {
        return this.LoanService.loanModal().then(() => this.updateTableData());
      }

      connectToAgreement = (item: any) => {
        this.GtUtils.overlay('show');
        this.$timeout(() => {
          this.GtUtils.overlay('hide');
          return this.LoanService.connectToAgreement({
            id: item.agreementConnectId,
            deal_id: item.id,
          }).then(
            (data: any) => {
              this.updateTableData();
              if (data) {
                this.Notification.info(this.gettext('Loan updated'));
              }
            },
            (error: any) => {
              error.data.forEach((value: any, key: any) => {
                this.Notification.error(key + ': ' + error.data[key]);
              });
            },
          );
        }, 1000);
      };

      getTableOptions() {
        const options: any = {
          tableName: 'loans',
          filterLevel: this.filterLevel,
          templateArgs: {
            $timeout: this.$timeout,
            gettext: this.gettext,
            updateTableData: this.updateTableData,
            tableApi: this.tableApi,
            editLoan: this.editLoan,
            LoanService: this.LoanService,
            Notification: this.Notification,
            gtFilterService: this.gtFilterService,
            filterLevel: this.filterLevel,
            queryParams: this.queryParams,
            connectToAgreement: this.connectToAgreement,
            GtUtils: this.GtUtils,
            setHovering: (value: any) => this.tableApi.setHovering(value),
          },
          columnDefs: [] as any[],
          tabs: [],
          rowData: [],
          rowCount: 0,
          onInit: (api: any) => (this.tableApi = api),
        };

        options.columnDefs = [
          {
            columnName: 'number',
            title: this.gettext('Loan number'),
            cellTemplate: numberCellTemplate,
          },
          {
            columnName: 'status',
            title: this.gettext('Status'),
            class: 'td-left-align',
            cellTemplate: html`
              <span
                class="label tooltip"
                data-tip="{[{ item.status }]}"
                ng-class="{
            'label_success': item.status == 'forecast',
            'label_warning': item.status == 'processing',
            'label_default': item.status == 'cancelled',
            'label_default': item.status == 'done'
          }"
              >
                {[{ item.status }]}
                <i class="fa fa-circle-check" ng-if="item.status == 'done'"></i>
              </span>
            `,
          },
          {
            columnName: 'agreement_number',
            title: this.gettext('Agreement number'),
            class: 'td-left-align',
            cellTemplate: html`
              <span ng-if="item.agreement_number"> {[{ item.agreement_number }]} </span>
              <span ng-if="!item.agreement_number"> --- </span>
            `,
          },
          {
            columnName: 'sides',
            title: this.gettext('Sides'),
            class: 'td-left-align',
            cellTemplate: html`
              <span> {[{ item.lender_name || '---' }]} </span>
              <div class="clearfix"></div>
              <span> {[{ item.borrower_name || '---' }]} </span>
            `,
          },
          {
            columnName: 'agreement_limit',
            title: this.gettext('Agreement limit'),
            class: 'td-right-align',
            cellTemplate: html`<span> {[{ item.agreement_limit || 0 | number: 2 }]} </span> `,
          },
          {
            columnName: 'amount',
            title: this.gettext('Loan amount'),
            class: 'td-right-align',
            cellTemplate: html`<span> {[{ item.amount || 0 | number: 2 }]} </span> `,
          },
          {
            columnName: 'interest_rate',
            title: this.gettext('Interest rate, %'),
            class: 'td-right-align',
            cellTemplate: html`<span> {[{ item.interest_rate || 0 | number: 2 }]} </span> `,
          },
          {
            columnName: 'finance_purpose_title',
            title: this.gettext('Financial purpose'),
            class: 'td-left-align',
            cellTemplate: html`<span> {[{ item.finance_purpose_title || '---' }]} </span> `,
          },
          {
            columnName: 'passport_title',
            title: this.gettext('Passport_connected'),
            class: 'td-left-align',
            cellTemplate: html`
              <a ng-if="item.passport" ui-sref="gt.page.passport({id: item.passport})">
                {[{ item.passport_title || '---' }]}
              </a>
              <span ng-if="!item.passport"> {[{ '---' }]} </span>
            `,
          },
        ];

        return options;
      }
    },
  ],
};
