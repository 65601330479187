import * as Select from '@radix-ui/react-select';
import * as React from 'react';

import type { ListOption } from '~/shared/lib/types';
import { cn } from '~/shared/lib/utils';
import { ArrowBottomIcon, CheckIcon } from '~/shared/ui/icons';

export const SelectComponent: React.FC<{
  value: ListOption['value'];
  onSelect: (value: ListOption['value']) => void;
  options: ListOption[];
}> = ({ value, onSelect, options }) => {
  return (
    <div className="ml-1.5">
      <Select.Root value={value} onValueChange={onSelect}>
        <Select.Trigger
          className={cn(
            'border-stroke-main-light text-text-main-primary hover:bg-transparent-light flex min-h-6 items-center justify-between rounded-md border px-3 py-1.5 text-xs transition duration-200 ease-in-out outline-none',
          )}
          aria-label="Select option"
        >
          <Select.Value className="text-xs" />
          <Select.Icon className="ml-2">
            <ArrowBottomIcon size={16} className="text-text-main-primary" />
          </Select.Icon>
        </Select.Trigger>

        <Select.Portal>
          <Select.Content
            position="popper"
            className="border-stroke-main-medium bg-background-main-primary z-50 overflow-hidden rounded-md border shadow-lg"
          >
            <Select.Viewport className="p-1">
              {options.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
};

const SelectItem = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof Select.Item>
>(({ children, ...props }, ref) => (
  <Select.Item
    ref={ref}
    className={cn(
      'text-text-main-primary hover:bg-transparent-light flex cursor-pointer items-center justify-between rounded-md px-3 py-1.5 outline-none',
    )}
    {...props}
  >
    <Select.ItemText>
      <div className="text-xs">{children}</div>
    </Select.ItemText>
    <Select.ItemIndicator>
      <CheckIcon size={14} className="text-text-main-primary ml-1" />
    </Select.ItemIndicator>
  </Select.Item>
));

SelectItem.displayName = 'SelectItem';
SelectComponent.displayName = 'SelectComponent';

export default SelectComponent;
