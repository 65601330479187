import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('CreditNoteModalController', Controller);

  Controller.$inject = [
    '$scope',
    '$window',
    '$uibModalInstance',
    'FinancesService',
    'creditNote',
    'GtUtils',
    'ClientsService',
    'extraData',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $window: ng.IWindowService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    FinancesService: FinancesService,
    creditNote: any,
    GtUtils: GtUtilsService,
    ClientsService: any,
    extraData: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.creditNote = creditNote || {};
    vm.errors = [];
    vm.tab = extraData?.tab || 'edit';
    vm.form = undefined;
    vm.save = save;
    vm.close = close;
    vm.clone = clone;
    vm.destroy = destroy;
    vm.updateData = updateData;
    vm.financePositionIsValid = financePositionIsValid;
    vm.cloneToInvoice = cloneToInvoice;
    vm.fields = getFields();
    vm.financePositions = [];
    vm.payment = {};

    activate();

    ////////////////

    function activate() {
      vm.creditNote.date = vm.creditNote.date || new Date();
      if (vm.creditNote.id) {
        updateData();
      }
      $scope.$watch('vm.creditNote.discount', function () {
        if (vm.creditNote.full_amount) {
          vm.creditNote.amount = vm.creditNote.full_amount * (vm.creditNote.discount / 100);
          vm.creditNote.amount = Math.round(vm.creditNote.amount * 1000) / 1000;
        }
      });
      $scope.$watch('vm.creditNote.full_amount', function (fullAmount: any) {
        if (fullAmount) {
          vm.creditNote.amount = fullAmount * (vm.creditNote.discount / 100);
          vm.creditNote.amount = Math.round(vm.creditNote.amount * 1000) / 1000;
        }
      });
      $scope.$watch('vm.creditNote.discount_amount', function () {
        if (vm.creditNote.discount_amount == 0) {
          vm.creditNote.discount = 100;
        }
        if (vm.creditNote.full_amount) {
          vm.creditNote.amount =
            vm.creditNote.full_amount - (Number(vm.creditNote.discount_amount) || 0);
          vm.creditNote.amount = Math.round(vm.creditNote.amount * 1000) / 1000;
        }
      });
      $scope.$on(
        'finance-position-table__change-full-amount',
        function (ev: any, newFullAmount: any) {
          vm.creditNote.amount = newFullAmount;
        },
      );
    }

    function updateData() {
      return FinancesService.CreditNote.get({ id: vm.creditNote.id }, function (data: any) {
        vm.creditNote = data;
        FinancesService.CreditNotePosition.get(
          { credit_note: vm.creditNote.id },
          function (data: any) {
            vm.financePositions = data.results;
          },
        );
        if (vm.creditNote.discount_amount != 0) {
          vm.creditNote.full_amount = vm.creditNote.amount + vm.creditNote.discount_amount;
          vm.creditNote.full_amount = Math.round(vm.creditNote.full_amount * 1000) / 1000;
        } else {
          const discoupntPart = vm.creditNote.amount / vm.creditNote.discount;
          vm.creditNote.full_amount = discoupntPart * 100;
          vm.creditNote.full_amount = Math.round(vm.creditNote.full_amount * 1000) / 1000;
        }
        if (vm.creditNote.client_role_model) {
          vm.creditNote.client_role_model = vm.creditNote.client_role_model.toLowerCase();
        } else {
          vm.creditNote.client_role_model = 'buyer';
        }
        vm.payment = {
          credit_note: vm.creditNote.id,
          currency_exchange: vm.creditNote.currency_exchange,
        };
      }).$promise;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function clone() {
      const msg = gettext('Do you want to clone this Invoice?');
      if (!confirm(msg)) {
        return;
      }
      const newObject = { ...vm.creditNote };
      delete newObject.id;
      return FinancesService.creditNoteModal(newObject);
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete this credit note?');
      if (!confirm(msg)) {
        return;
      }
      FinancesService.CreditNote.delete({ id: vm.creditNote.id }, function () {
        notify(gettext('Credit note removed'));
        close(null, true);
      });
    }

    function save() {
      vm.form.$invalid = true;
      if (vm.creditNote.use !== 'costs') {
        vm.creditNote.client_role_model = undefined;
      }
      if (vm.creditNote.id) {
        return FinancesService.CreditNote.update(
          vm.creditNote,
          function () {
            updateFinancePositions();
            notify(gettext('Credit note updated'));
          },
          _error,
        );
      }
      return FinancesService.CreditNote.save(
        vm.creditNote,
        function (data: any) {
          vm.creditNote.id = data.id;
          updateFinancePositions();
          notify(gettext('Credit note saved'));
        },
        _error,
      );
    }

    function updateFinancePositions() {
      ng.forEach(vm.financePositions, function (financePosition: any) {
        financePosition.credit_note = vm.creditNote.id;
      });
      FinancesService.CreditNotePosition.bulkCreateOrUpdate(
        vm.financePositions,
        function (data: any) {
          close(data, true);
        },
      );
    }

    function financePositionIsValid() {
      return vm.financePositions.every(function (financePosition: any) {
        return (
          financePosition.price_per_piece &&
          financePosition.quantity &&
          financePosition.amount &&
          financePosition.debit_account &&
          financePosition.credit_account
        );
      });
    }

    function cloneToInvoice() {
      return FinancesService.CreditNote.cloneToInvoice(
        {},
        {
          id: vm.creditNote.id,
        },
        function (data: any) {
          FinancesService.financeModal(data).then(function () {
            close(null, true);
          });
        },
      );
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
      vm.creditNote.errors = data.data;
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };
      const col2: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };
      const col3: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };
      const col4: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };

      col1.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('PAYMENT'),
        },
        fieldGroup: [
          {
            key: 'number',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Credit note number'),
              placeholder: gettext('Type credit note number'),
              addon: gettext('#'),
              required: true,
            },
          },
          {
            key: 'amount',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Amount'),
              placeholder: gettext('Invoiced amount'),
              type: 'number',
              required: true,
              maxlength: '13', // real value is 14. this is hundred of millions and 3 dig after coma.
              // for example 100 000 000,200
            },
          },
          {
            key: 'discount',
            type: 'gt-input',
            defaultValue: 100,
            templateOptions: {
              label: gettext('Invoice discount'),
              placeholder: gettext('0-200'),
              hint: gettext('Pick from 0 to 200'),
              addon: gettext('%'),
              type: 'number',
              required: true,
              hidden: true,
            },
            validators: {
              notFalse: function ($viewValue: any, $modelValue: any) {
                return (
                  parseFloat($viewValue || $modelValue || 0) >= 0 &&
                  parseFloat($viewValue || $modelValue || 0) <= 200
                );
              },
            },
          },
          {
            key: 'discount_amount',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: gettext('Invoice discount amount'),
              placeholder: gettext('Use this or %'),
              hint: gettext('Use this or discount %'),
              type: 'number',
              required: true,
              hidden: true,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.discount != 100 && !model.id',
            },
          },
          {
            key: 'currency_exchange',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Currency and rate'),
              placeholder: gettext('USD, UAH'),
              resource: 'finances.CurrencyExchange',
              addFunc: () => {
                $window.open('/admin/finances/currencyexchange/add/');
              },
              addIcon: GtUtils.getIcon('finances.CurrencyExchange'),
              addPerms: ['add_currencyexchange'],
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.amount',
            },
            hideExpression: "!$root.user.settings.DEFAULT_VALUES['field_invoice_currency']",
          },
          {
            key: 'not_pay',
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('Not pay'),
            },
          },
        ],
      });
      col2.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('CONDITIONS'),
        },
        fieldGroup: [
          {
            key: 'invoice_type',
            type: 'gt-select',
            defaultValue: 'incoming',
            templateOptions: {
              label: gettext('Type'),
              placeholder: gettext('Choose type'),
              hint: gettext(
                'Incoming - all invoiced issued to us (means we will spend cash).' +
                  ' Outgoing - all invoices issued by us (means: we will receive cash) ',
              ),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: gettext('Incoming'), value: 'incoming' },
                { name: gettext('Outgoing'), value: 'outgoing' },
              ],
            },
          },

          {
            key: 'volume',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: gettext('Volume'),
              placeholder: gettext('Type volume'),
              type: 'number',
              addon: gettext('t'),
              hidden: true,
            },
            hideExpression:
              'model.use == "costs" || model.use == "liability" || model.use == "expenses" || model.use == "forex"',
          },
          {
            key: 'client_role_model',
            type: 'gt-select',
            defaultValue: 'buyer',
            templateOptions: {
              label: gettext('role'),
              placeholder: gettext('Choose role'),
              hint: gettext('Chose a role of a counterparty to be bound to a ' + 'finance'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: gettext('Supplier'), value: 'supplier' },
                { name: gettext('Exporter'), value: 'exporter' },
                { name: gettext('Farm'), value: 'farm' },
                { name: gettext('Elevator'), value: 'elevator' },
                { name: gettext('Forwarder'), value: 'deliverer' },
                { name: gettext('Bank'), value: 'bank' },
                { name: gettext('Owner'), value: 'owner' },
                { name: gettext('Other'), value: 'other' },
                { name: gettext('Surveyor'), value: 'surveyor' },
                { name: gettext('Buyer'), value: 'buyer' },
                { name: gettext('Broker'), value: 'broker' },
                { name: gettext('Insurer'), value: 'insurer' },
              ],
            },
          },
          {
            key: 'client_role',
            type: 'gt-ui-select',
            templateOptions: {
              // Add client but display only clients with role
              label: gettext('Related counterparty'),
              placeholder: gettext('Choose counterparty'),
              addPerms: ['add_client'],
              addIcon: GtUtils.getIcon('clients.Client'),
              addFunc: () => {
                // Need to not return id
                ClientsService.clientModal();
              },
              resource: 'clients.ClientRole',
              hint: gettext('Pick a counterparty that will be bound to this ' + 'invoice'),
            },
          },
        ],
      });
      col3.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('DATES'),
        },
        fieldGroup: [
          {
            key: 'date',
            type: 'gt-date-select',
            templateOptions: {
              label: gettext('Date of issuance'),
              placeholder: gettext('date'),
              hint: gettext('When you have issued invoice'),
              type: 'date',
              required: true,
            },
          },
          {
            key: 'date_of_execution_fact',
            type: 'gt-date-select',
            templateOptions: {
              label: gettext('Date of payment'),
              placeholder: gettext('date'),
              hint: gettext('When you expect payment has actually been made'),
              type: 'date',
            },
          },
        ],
      });
      col4.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('OTHER'),
        },
        fieldGroup: [
          {
            key: 'bank_account',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Bank Account'),
              resource: 'finances.BankAccount',
            },
            hideExpression: "!$root.user.settings.DEFAULT_VALUES['field_invoice_bank']",
          },
          {
            key: 'additional_info',
            type: 'gt-textarea',
            templateOptions: {
              label: gettext('Additional Info'),
              placeholder: gettext('Write down the specific information  about this invoice'),
              className: 'additional-info',
            },
          },
        ],
      });

      return [col1, col2, col3, col4];
    }
  }
})();
