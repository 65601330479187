import type ng from 'angular';

import template from './contract-quality-rules-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const ContractQualityRulesListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
    disableEdit: '<',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'ContractQualityRulesService',
    'gettext',
    class sampleControlContainer {
      $scope: ng.IScope;
      ContractQualityRulesService: any;
      GtUtils: GtUtilsService;
      dataCount: number;
      dataList: any;
      dataTotal: any;
      disableEdit: any;
      filterLevel = 'contract-quality-rules-list-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        ContractQualityRulesService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.ContractQualityRulesService = ContractQualityRulesService;

        this.dataList = [];
        this.dataCount = 0;
        this.dataTotal = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'contract-quality-rules-list-container';
        this.queryParams = { page_size: 20, ...this.initQueryParams };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);

        this.$scope.$watch(
          () => this.initQueryParams,
          (newVal: any, oldVal: any) => {
            if (newVal.contract != oldVal.contract) {
              this.gtFilterService.updateQueryParams(this.initQueryParams, this.filterLevel);
            }
          },
        );
      }

      applyFilters(params: QueryParams) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.ContractQualityRulesService.getListData(this.queryParams).then((data: any) => {
          if (!this.disableEdit) {
            data.data.results.unshift({
              contract: this.queryParams.contract,
              showEdit: true,
              quality_rule_template: this.queryParams.quality_rule_template,
              positions: [{ from_value: 0, to_value: 0, coefficient: 0 }],
            });
          }
          this.dataList = data.data.results;
          this.dataCount = data.data.count;
          this.dataTotal = data.totals;
          this.GtUtils.overlay('hide');
        });
      }

      saveItem(item: any) {
        return this.ContractQualityRulesService.save(item).then(
          () => this.updateData(),
          this.GtUtils.errorClb,
        );
      }

      destroy(item: any) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return false;
        }
        return this.ContractQualityRulesService.delete(item).then(
          () => this.updateData(),
          this.GtUtils.errorClb,
        );
      }
    },
  ],
};
