import type ng from 'angular';

import template from './transportation-storage-expenses-table.html?raw';

import { html } from '^/shared/utils';

export const TransportationStorageExpensesView = {
  bindings: {
    expenses: '<?',
    count: '<?',
    openModal: '&',
    clone: '&',
    filterLevel: '<?',
    applyFilters: '&',
    createInvoice: '&',
  },
  template,
  controller: [
    'gettext',
    class {
      applyFilters: any;
      createInvoice: any;
      clone: any;
      count: number;
      expenses: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      openModal: any;
      tableData: any;
      tableName: string;
      tableOptions: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;

        this.expenses = [];
        this.count = 0;
        this.tableData = {};
        this.tableName = '';
        this.filterLevel = '';
        this.openModal = () => '';
        this.applyFilters = () => '';
        this.createInvoice = () => '';
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'transportation-storage-expenses-view';
        this.tableName = this.tableName || this.filterLevel;
        this.tableOptions = this.getTableOptions();
      }
      $onChanges(changes: any) {
        if (changes.expenses || changes.count) {
          this.tableData = { rows: this.expenses, count: this.count };
        }
      }
      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass:
            'contract-charges-table customs-cargo-declaration-table sample-control-table table-with-inline-add',
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          configurable: true,
          changePageSize: true,
          showMore: true,
          templateArgs: {
            openModal: (item: any) => this.openModal({ item: item }),
            clone: (item: any) => this.clone({ item: item }),
            createInvoice: (id: number) => this.createInvoice({ id: id }),
          },
          tabs: [],
          columnDefs: [
            {
              columnName: 'date',
              class: 'td-left-align',
              title: this.gettext('Date'),
              cellTemplate: html`{[{ item.date | date:'dd.MM.yy'}]}`,
            },
            {
              columnName: 'number',
              class: 'td-left-align',
              title: this.gettext('Number'),
              cellTemplate: html`
                <a class="btn-link" ui-sref="logistics.transportationStorageExpense(item)"
                  >{[{ item.number }]}</a
                >
                <ul
                  class="nav navbar-nav item-settings-dropdown actions-btn pull-right"
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                >
                  <li>
                    <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                      <i class="fa fa-ellipsis hide-on-hover"></i>
                      <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="btn btn-xs col-xs-12"
                          ui-sref="logistics.transportationStorageExpense(item)"
                        >
                          <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'change_transportationstorageexpense'"
                          class="btn btn-xs aligned-btn"
                          ng-disabled="item.result_status == 'approved'"
                          ng-click="args.openModal(item)"
                        >
                          <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'edit_closed_elements'"
                          class="btn btn-xs aligned-btn"
                          ng-click="args.openModal(item)"
                        >
                          <i class="fa fa-user-secret"></i> <translate>Edit (admin)</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          ng-click="args.clone(item)"
                          class="btn btn-xs aligned-btn"
                          permission
                          permission-only="'change_transportationstorageexpense'"
                        >
                          <i class="fa fa-files-o"></i> <translate>Clone</translate>
                        </a>
                      </li>
                      </li>
                      <li>
                        <a
                          ng-click="args.createInvoice(item.id)"
                          class="btn btn-xs aligned-btn"
                          permission
                          permission-only="'add_finance'"
                          ng-disabled="item.invoices_data.invoices.length > 0"
                        >
                          <i class="fa fa-money"></i> <translate>Create invoice</translate>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'status',
              class: 'td-left-align',
              title: this.gettext('Status'),
              cellTemplate: html`<span>{[{ item.status | translate}]}</span>`,
            },
            {
              columnName: 'base',
              class: 'td-left-align',
              title: this.gettext('Base'),
              cellTemplate: html`<span>{[{ item.base | translate}]}</span>`,
            },
            {
              columnName: 'strategy',
              class: 'td-left-align',
              title: this.gettext('Strategy'),
              cellTemplate: html`<span>{[{ item.strategy | translate}]}</span>`,
            },
            {
              columnName: 'startDate',
              class: 'td-left-align',
              title: this.gettext('Start date'),
              cellTemplate: html`<span>{[{ item.start_date | date:'dd.MM.yy'}]}</span>`,
            },
            {
              columnName: 'endDate',
              class: 'td-left-align',
              title: this.gettext('End date'),
              cellTemplate: html`<span>{[{ item.end_date | date:'dd.MM.yy'}]}</span>`,
            },
            {
              columnName: 'warehouse',
              class: 'td-left-align',
              title: this.gettext('Warehouse'),
              cellTemplate: html`<span>{[{ item.warehouse.title | translate}]}</span>`,
            },
            {
              columnName: 'commodity',
              class: 'td-left-align',
              title: this.gettext('Commodity'),
              cellTemplate: html`<span>{[{ item.commodity.title }]}</span>`,
            },
            {
              columnName: 'contractPosition',
              class: 'td-left-align',
              title: this.gettext('Contract'),
              cellTemplate: html`
                <a ui-sref="gt.page.contract({id: item.contract_position.id })" target="_blank">
                  <span>{[{ item.contract_position.contract_number }]}</span>
                </a>
              `,
            },
            {
              columnName: 'contract',
              class: 'td-left-align',
              title: this.gettext('Multicontract'),
              cellTemplate: html`
                <a
                  ui-sref="gt.multicontract({id: item.contract_position.contract.id })"
                  target="_blank"
                >
                  <span>{[{ item.contract_position.contract.number }]}</span>
                </a>
              `,
            },
            {
              columnName: 'costs_amount',
              class: 'td-left-align',
              title: this.gettext('Costs amount'),
              cellTemplate: html`<span>{[{item.costs_amount}]}</span>`,
            },
            {
              columnName: 'currency',
              class: 'td-left-align',
              title: this.gettext('currency'),
              cellTemplate: html`<span>{[{ item.currency.symbol }]}</span>`,
            },
            {
              columnName: 'charge',
              class: 'td-left-align',
              title: this.gettext('charge'),
              cellTemplate: html`<span>{[{ item.charge.title }]}</span>`,
            },
            {
              columnName: 'currency_exchange',
              class: 'td-left-align',
              title: this.gettext('currency rate'),
              cellTemplate: html`<span>{[{ item.currency_exchange.title }]}</span>`,
            },
            {
              title: this.gettext('Invoice'),
              columnName: 'invoiced',
              class: 'td-left-align minWidth',
              cellTemplate: html`
                <div class="td-left-align">
                  <ul class="inside-table-ul">
                    <li ng-repeat="invoice in item.invoices_data.invoices">
                      <a
                        ui-sref="gt.page.payment({id: invoice.id})"
                        target="_blank"
                        style="padding-left:5px;"
                        class="smaller-label"
                      >
                        <span class="smaller-label secondary-text">
                          {[{invoice.amount | number:2}]} {[{item.currency.symbol}]}
                        </span>
                        <div class="clearfix"></div>
                        <span class="smaller-label secondary-text" ng-if="invoice.date">
                          <i class="fa fa-calendar"></i> {[{invoice.date | date:'dd/MM/yy'}]}
                        </span>
                        <div class="clearfix"></div>
                        <i class="fa fa-credit-card"></i> {[{invoice.number | cut:true:30:'...' }]}
                        <span class="label label_danger" ng-if="invoice.not_pay"
                          ><translate>Not pay</translate></span
                        >
                        <span class="negative-number" ng-if="invoice.status == 'canceled'"
                          >(<translate>{[{ invoice.status }]})</translate></span
                        >
                        <span
                          class="badge label_danger tooltip smaller-label"
                          data-tip="With discount"
                          ng-if="invoice.discount != 100"
                          style="font-size: 6px !important"
                        >
                          <i class="fa fa-exclamation-triangle"></i>
                        </span>
                        <div class="clearfix"></div>
                        <span
                          ng-if="invoice.invoice_type == 'incoming'"
                          class="smaller-label secondary-text"
                        >
                          <i class="fa fa-building"></i>
                          {[{ invoice.client_from_name | cut:true:30:'...' }]}</span
                        >
                        <div class="clearfix"></div>
                        <span
                          ng-if="invoice.invoice_type == 'outgoing'"
                          class="smaller-label secondary-text"
                        >
                          <i class="fa fa-building"></i>
                          {[{ invoice.client_to_name | cut:true:30:'...' }]}</span
                        >
                        <div class="clearfix"></div>
                      </a>
                    </li>
                  </ul>
                </div>
              `,
            },
            {
              title: this.gettext('Payment'),
              columnName: 'payments',
              class: 'td-left-align minWidth',
              classExpr: html`{ 'edit': item._$editMode || !item.id }`,
              cellTemplate: html`
                <span>
                  {[{ item.invoices_data.payments_amount || 0 | number:2 }]}
                  <span>{[{ item.currency.symbol }]}</span>
                </span>
              `,
            },
          ],
        };
      }
    },
  ],
};
