import ng from 'angular';

import template from './logistics-tab.tpl.html?raw';

import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive('contractsContractItemLogisticsTab', directive);

  function directive() {
    return {
      template,
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        contract: '<',
        readonly: '<',
        callFrom: '<',
        filterLevel: '<?',
        resourceQueryParams: '<?',
        subtab: '<?',
      },
    };
  }

  Controller.$inject = ['LogisticsService', 'ContractsService'];

  function Controller(
    this: any,
    LogisticsService: LogisticsService,
    ContractsService: ContractsService,
  ) {
    const vm = this;

    vm.blQueryParams = {};
    vm.disbursmentblQueryParams = {};
    vm.newLogisticInitData = {};
    vm.consignmentsQueryParams = {};
    vm.sampleControlQueryParams = {};
    vm.resourceQueryParams = {};
    vm.CCDQueryParams = {};
    vm.ccdCreationParams = {};
    vm.setSubtab = setSubtab;
    vm.paramsKeyPostfix = '';

    ////////////////

    vm.$onInit = function () {
      vm.callFrom = vm.callFrom || 'position';
      vm.paramsKeyPostfix = vm.callFrom === 'position' ? '' : '__multicontract';
      vm.paramsKey = vm.callFrom === 'position' ? '' : 'multi';
      vm.filterLevel = vm.filterLevel || 'logistics-tab-contract';
      vm.logisticsFilterLevel = 'logistics-tab-contract-' + vm.contract.contract_type;
      vm.logisticsQueryParams = { serializer: 'table_info' };

      setSubtab(vm.subtab);
      updateData();
    };

    function updateData() {
      const useType = vm.contract.use_type || vm.contract.deal_type;

      const contractKey =
        useType === 'services' || useType === 'export' || useType === 'intermediate'
          ? useType
          : vm.contract.contract_type;

      const logisticsQueryParamsMap: any = {
        services: { forwarder_contract: vm.contract.id },
        export: {
          [`export_contract${vm.paramsKeyPostfix}`]: vm.contract.id,
          [`export_contract__currency${vm.paramsKeyPostfix}`]: vm.contract.currency,
        },
        sale: {
          [`buyer_contract${vm.paramsKeyPostfix}`]: vm.contract.id,
          [`buyer_contract__currency${vm.paramsKeyPostfix}`]: vm.contract.currency,
          ['buyer_multicontract']: vm.resourceQueryParams.multicontract,
        },
        purchase: {
          [`supplier_contract${vm.paramsKeyPostfix}`]: vm.contract.id,
          [`supplier_contract__currency${vm.paramsKeyPostfix}`]: vm.contract.currency,
          ['supplier_multicontract']: vm.resourceQueryParams.multicontract,
        },
        intermediate: {
          [`intermediate_contract${vm.paramsKeyPostfix}`]: vm.contract.id,
          [`intermediate_contract__currency${vm.paramsKeyPostfix}`]: vm.contract.currency,
        },
        default: {},
      };

      vm.logisticsQueryParams = {
        ...vm.logisticsQueryParams,
        ...logisticsQueryParamsMap[contractKey],
      };

      const disbursmentblQueryParamsMap: any = {
        sale: {
          [`sale_contract${vm.paramsKeyPostfix}`]: vm.contract.id,
          ['sale_multicontract']: vm.resourceQueryParams.multicontract,
        },
        purchase: {
          [`purchase_contract${vm.paramsKeyPostfix}`]: vm.contract.id,
          ['purchase_multicontract']: vm.resourceQueryParams.multicontract,
        },
        services: {
          [`services_contract${vm.paramsKeyPostfix}`]: vm.contract.id,
        },
        default: {},
      };
      vm.disbursmentblQueryParams = {
        contract_type: vm.contract.contract_type,
        buyer: vm.contract.buyer,
        supplier: vm.contract.supplier,
        cargo: vm.contract.cargo,
        price: vm.contract.price,
        contract_currency: vm.contract.currency,
        currency_exchange: vm.contract.currency_exchange,
        premium_bonus_percentage: vm.contract.premium_bonus_percentage,
        dbl_type: vm.contract.contract_type,
        responsible: vm.contract.responsible,
        responsible_for_signing: vm.contract.responsible_for_signing,
        sap_order: vm.contract.sap_orders?.length > 0 ? vm.contract.sap_orders[0] : null,
        ...disbursmentblQueryParamsMap[contractKey],
      };

      vm.newLogisticInitData = {
        ...vm.logisticsQueryParams,
        ...{
          cargo: vm.contract.cargo,
          contract_currency_exchange: vm.contract.currency_exchange,
        },
      };

      vm.blQueryParams = {
        [`contract${vm.paramsKeyPostfix}`]: vm.contract.id,
      };
      vm.sampleControlQueryParams = { ...vm.blQueryParams };
      vm.consignmentsQueryParams = {
        [`contract${vm.paramsKeyPostfix}`]: vm.contract.id,
        ['multicontract']: vm.resourceQueryParams.multicontract,
        ['commodity']: vm.contract.cargo,
      };
      vm.CCDQueryParams[`export_${vm.paramsKey}contract_list`] = [vm.contract.id];
      vm.CCDCreationParams = {
        contract: vm.contract.id,
        customs_broker: vm.contract.customs_broker,
        destination_of_crop: vm.contract.destination_of_crop,
      };

      if (vm.callFrom === 'position') {
        return ContractsService.refreshContract(vm.contract, true).then((data: any) => {
          vm.contract = data;
        });
      }
    }

    function setSubtab(subtab: string) {
      vm.subtab = subtab;
      LogisticsService.setSubtabToLocalStorage(subtab);
    }
  }
})();
