import ng from 'angular';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('core.legacy').component('gtPage', {
    bindings: {},
    template: require('./gt-page.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'GtUtils'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    $state: ng.ui.IStateService,
    $stateParams: ng.ui.IStateParamsService,
    GtUtils: GtUtilsService,
  ) {
    const vm = this;
    vm.config = {};
    vm.activePage = undefined;

    vm.$onInit = function () {
      $scope.$on('page-config-updated', function (ev: any, config: any) {
        vm.config = config;
        updateActivePage();
      });
      $scope.$on('page-counters-updated', function (ev: any, params: object) {
        updateCounters(params);
      });
      $scope.$on('sidebar-list-toggle-hide', (_: any, newValue: any) => {
        vm.config.sidebar.is_open = newValue;
      });
    };

    ////////////////

    function updateCounters(params: any) {
      vm.config.pages.forEach(function (page: any) {
        if (page.counter == params.model) {
          prepareCounter(page, params.queryParams);
          page.tabs = page.tabs || [];
          page.tabs.forEach(function (tab: string) {
            prepareCounter(tab, params.queryParams);
          });
        }
      });
    }

    function updateActivePage() {
      checkPermissions();
      const stateParams = $stateParams;
      delete stateParams['#'];
      vm.activePage =
        vm.config.pages
          .filter(function (page: any) {
            page.tabs = page.tabs || [];
            page.title = stateParams.title || page.title;
            prepareCounter(page);
            page.tabs.forEach(function (tab: string) {
              prepareCounter(tab);
            });

            page.tabs = page.tabs.filter(function (tab: any) {
              return !(tab.showConfig !== undefined && !tab.showConfig);
            });

            if (
              page.state == $state.current.name &&
              JSON.stringify(page.stateParams || {}) == JSON.stringify(stateParams)
            ) {
              return true;
            }
            return Boolean(
              page.tabs.filter(function (tab: any) {
                return (
                  tab.state == $state.current.name &&
                  JSON.stringify(tab.stateParams || {}) == JSON.stringify(stateParams)
                );
              }).length,
            );
          })
          .shift() || vm.config.pages[0];
    }

    function prepareCounter(page: any, queryParams?: QueryParams) {
      if (typeof page.counter == 'string') {
        GtUtils.getCounters([page.counter], queryParams ?? page.counterParams)
          .then(function (counters: any) {
            page.counterNumber = counters[page.counter];
          })
          .catch(GtUtils.errorClb);
      }
    }

    function checkPermissions() {
      const userPermissions = $rootScope.user.perms;
      if (vm.config.pages) {
        vm.config.pages.forEach((page: any) => {
          if (page.permissions && page.permissions.length > 0) {
            const hasPermission = page.permissions.some((perm: any) =>
              userPermissions.includes(perm),
            );
            page.hide = hasPermission;
          } else {
            page.hide = true;
          }
        });
      }
    }
  }
})();
