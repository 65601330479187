import React from 'react';

import { useTranslation } from '~/shared/lib/i18n';
import { cn } from '~/shared/lib/utils';
import { Checkbox } from '~/shared/ui/kit/checkbox';
import { Tooltip } from '~/shared/ui/kit/tooltip';

import { type DataTypeProps, messageIntent } from '../../lib';

export const CheckboxWrite: React.FC<DataTypeProps<'checkbox'>> = ({
  disabled,
  value,
  onChange,
  message,
}) => {
  const { t } = useTranslation();

  const checkboxElement = (
    <Checkbox checked={value} size="small" onClick={() => onChange?.(!value)} disabled={disabled} />
  );

  return (
    <div className={cn('relative inline-flex p-2', message && messageIntent[message.type])}>
      {message ? (
        <Tooltip content={t(message.text ?? '')} side="bottom" sideOffset={4}>
          {checkboxElement}
        </Tooltip>
      ) : (
        checkboxElement
      )}
    </div>
  );
};
