import ng from 'angular';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CoreService } from '^/app/core/core.service';

(function () {
  'use strict';
  ng.module('accounts.legacy').controller('ApprovalsModalController', Controller);

  Controller.$inject = [
    '$scope',
    '$uibModalInstance',
    'AccountsService',
    'objectId',
    'contentType',
    'CoreService',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    AccountsService: AccountsService,
    objectId: number,
    contentType: any,
    CoreService: CoreService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.objectId = objectId;
    vm.contentType = contentType;
    vm.approval = {};
    vm.user = {};
    vm.isDisabled = isDisabled;
    vm.close = close;
    vm.save = save;
    vm.openApproversModal = openApproversModal;
    vm.canSave = canSave;
    vm.updating = false;

    activate();

    ////////////////

    function activate() {
      updateData();
    }

    function updateData() {
      vm.updating = true;
      return AccountsService.getObjectApproval(objectId, contentType).then(function (
        approval: any,
      ) {
        vm.approval = approval;
        vm.updating = false;
      });
    }

    function isDisabled() {
      return Boolean(vm.approval.approved || vm.approval.declined);
    }

    function canSave() {
      return !(!vm.approval.comment && vm.approval.approved == vm.approval.declined);
    }

    function save() {
      return AccountsService.saveApproval(vm.approval).then(function (data: any) {
        close(data, true);
      });
    }

    function openApproversModal() {
      return CoreService.ContentType.get({ id: contentType }, function (data: any) {
        return AccountsService.approversModal(objectId, contentType, [
          `should_approve_${data.model}`,
        ]).then(() => {
          updateData();
          $scope.$broadcast('update-approvals');
        });
      }).$promise;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
