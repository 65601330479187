import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './warehouse-details-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { WarehousesFormFieldsService } from '^/app/execution/components/warehouse/warehouse/details/warehouse-form-fields.service';
import type { WarehousesService } from '^/app/execution/components/warehouse/warehouse/warehouse.service';

export const WarehouseDetailsContainer = {
  bindings: {
    warehouse: '<',
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    '$state',
    '$stateParams',
    'gettext',
    'gtFilterService',
    'GtUtils',
    'WarehousesService',
    'WarehousesFormFieldsService',
    class {
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      gettext: ng.gettext.gettextFunction;
      GtUtils: GtUtilsService;
      WarehousesFormFieldsService: WarehousesFormFieldsService;
      WarehousesService: WarehousesService;
      activeTabs: any;
      fields: any;
      filterLevel: string;
      gtFilterService: GtFilterService;
      tab = [''];
      warehouse: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        $stateParams: ng.ui.IStateParamsService,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        WarehousesService: WarehousesService,
        WarehousesFormFieldsService: WarehousesFormFieldsService,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.WarehousesService = WarehousesService;
        this.WarehousesFormFieldsService = WarehousesFormFieldsService;
        this.warehouse = {};
        this.filterLevel = '';
        this.activeTabs = [];
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'warehouses-details-container';
        this.tab = this.$stateParams.tab || ['movements'];
        if (this.$stateParams.tab) {
          this.gtFilterService.updateQueryParams({ tab: this.$stateParams.tab }, this.filterLevel);
        }
        this.$scope.$on(
          'gt-filter-updated_' + this.filterLevel,
          (_: object, data: { tab: string[] }) => {
            if (data.tab.length) {
              this.tab = data.tab;
            }
          },
        );
        this.updateData();
        this.updateFields().catch(this.GtUtils.errorClb);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.WarehousesService.getWarehouse(this.warehouse.id)
          .then((data: any) => (this.warehouse = data))
          .catch(() => {
            notify(this.gettext('Warehouse doesn`t exist'));
            return this.$state.go('logistics.warehousesList');
          })
          .finally(() => this.GtUtils.overlay('hide'));
      }

      openModal() {
        return this.WarehousesService.warehouseModal({ ...this.warehouse }).then(() =>
          this.updateData(),
        );
      }

      updateFields() {
        return this.WarehousesFormFieldsService.getFields(this.warehouse).then((fields: any) => {
          this.fields = fields;
        });
      }

      changeTab(tab: string) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);
      }
    },
  ],
};
