import ng from 'angular';

import { ViewModeSwitcher } from '~/core/datasets/components/view-mode-switcher';
import { Pagination } from '~/shared/ui/components/pagination';

import { angularize } from '^/shared/utils/react';

const gtUIComponents = ng.module('gtUIComponents', []);

angularize(ViewModeSwitcher, 'gtuViewModeSwitcher', gtUIComponents, {
  view: '<',
  onChange: '<',
  limit: '<?',
});

angularize(Pagination, 'gtuPagination', gtUIComponents, {
  pageCurrent: '<',
  pageSize: '<',
  recordsTotal: '<',
  pageSizeOptions: '<',
  currentPageChanged: '<',
  pageSizeChanged: '<',
});

export const gtUIComponentsModule = gtUIComponents.name;
