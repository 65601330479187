import React from 'react';

import { BucketIcon } from '~/shared/ui/icons';
import { Dialog } from '~/shared/ui/kit/dialog';
import { Input } from '~/shared/ui/kit/input';

import { GlobalSearchItem } from './global-search-item';
import type { SearchItem } from '../../lib/types';

export const GlobalSearchDialog: React.FC<{
  open: boolean;
  openChanged: () => void;
  searchText: string;
  searchTextChanged: (text: string) => void;
  searchResults: Record<string, SearchItem[]>;
}> = ({ searchText, searchResults, searchTextChanged, open, openChanged }) => {
  return (
    <Dialog open={open} onOpen={openChanged} overlayVisible={false}>
      <div className="border-stroke-main-medium border-b p-0">
        <div className="flex h-[48px] items-center px-3">
          <Input
            value={searchText}
            fontSize="base"
            intent="transparent"
            placeholder="Search records or quick actions ..."
            onChange={(e) => searchTextChanged(e.target.value)}
            suffix={
              <BucketIcon
                size={16}
                className="text-stroke-main-stronger hover:text-text-additional-danger cursor-pointer transition duration-500 ease-in-out"
                onClick={() => searchTextChanged('')}
                data-testid="clear-global-search"
              />
            }
            fullWidth
          />
        </div>
      </div>
      <div className="scrollbar max-h-[500px] overflow-y-auto px-2">
        {Object.keys(searchResults).length ? (
          Object.entries(searchResults).map(([group, items]) => (
            <div key={group}>
              <p className="text-text-main-tertiary mt-2.5 text-xs font-semibold">{group}</p>
              {items.map((item) => (
                <GlobalSearchItem key={item.title} item={item} searchText={searchText} />
              ))}
            </div>
          ))
        ) : (
          <div className="text-xs">Not found</div>
        )}
      </div>
    </Dialog>
  );
};
