import React from 'react';

import { useForm, v } from '~/shared/lib/forms';
import { Button } from '~/shared/ui/kit/button';
import { Input } from '~/shared/ui/kit/input';

import type { ResetPasswordCredentials } from '../../lib';

export const ResetPasswordForm: React.FC<{
  submit: (credentials: ResetPasswordCredentials) => void;
  loading?: boolean;
}> = ({ submit, loading }) => {
  const form = useForm({
    onSubmit: ({ value }) => submit(value),
    defaultValues: { username: '' },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit().catch(console.error);
      }}
      className="flex flex-col gap-4"
      data-testid="reset-pass-form"
    >
      <form.Field
        name="username"
        validators={{
          onChange: v.pipe(v.string(), v.minLength(1, 'Username is required')),
        }}
      >
        {(field) => (
          <Input
            type="text"
            placeholder="Type Here"
            intent={field.state.meta.errors.length ? 'danger' : 'default'}
            label="Usernamse"
            onChange={(e) => field.handleChange(e.target.value)}
            helperText={
              !!field.state.meta.errors.length && (
                <p className="text-text-additional-danger">
                  {field.state.meta.errors.map(String).join(', ')}
                </p>
              )
            }
          />
        )}
      </form.Field>
      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <Button
            type="submit"
            variant="fadedSecondary"
            size="md"
            disabled={loading ?? !canSubmit}
            fullWidth
          >
            {isSubmitting ? '...' : 'Send Me New Password'}
          </Button>
        )}
      </form.Subscribe>
    </form>
  );
};
