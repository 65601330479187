import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './mailing-finances-table.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { MailingService } from '^/app/crm/mailing/mailing.service';
import { html } from '^/shared/utils';

export const MailingFinancesTable = {
  bindings: {
    filterLevel: '<?',
    mailingsFilterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$timeout',
    'gettext',
    'GtUtils',
    'MailingService',
    'gtFilterService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $timeout: ng.ITimeoutService;
      GtUtils: GtUtilsService;
      MailingService: MailingService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      mailingAttachDocs: any;
      mailingExportConfig: any;
      mailingTemplate: any;
      mailingsFilterLevel: string;
      queryParams: QueryParams = {};
      tableApi: any;
      tableOptions: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $timeout: ng.ITimeoutService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        MailingService: MailingService,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.MailingService = MailingService;
        this.gtFilterService = gtFilterService;

        this.filterLevel = '';
        this.mailingsFilterLevel = '';

        this.tableOptions = {};
        this.tableApi = {};
        this.queryParams = {};
        this.mailingTemplate = undefined;
        this.mailingExportConfig = undefined;
        this.mailingAttachDocs = false;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'mailing-finances-table';
        this.tableOptions = this.getTableOptions();
        this.tableApi = {};
        this.queryParams = this.getDefaultQueryParams();
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.updateData();
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.MailingService.getFinancesTableData(this.queryParams).then((data: any) => {
          this.MailingService.getFinancesTableTotals(this.queryParams).then((totals: any) => {
            this.tableApi.clearRenderedRows();
            this.tableApi.setRowData([]);
            this.$timeout(() => {
              this.tableApi.setRowData(data.results);
              this.tableApi.setRowCount(data.count);
              this.tableApi.setTotalData(totals);
              this.GtUtils.overlay('hide');
            });
          });
          return data;
        });
      }

      applyFilter() {
        this.queryParams.page = undefined;
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      clearFilters() {
        this.queryParams = this.getDefaultQueryParams();
        this.applyFilter();
      }

      getDefaultQueryParams() {
        return {
          invoice_type: 'outgoing',
          start_date_of_execution_fact: null,
          end_date_of_execution_fact: null,
          clientrole_from_list: [],
          clientrole_to_list: [],
          status_list: ['new', 'process'],
        };
      }

      getTableOptions() {
        return {
          tableName: 'mailing-finances-table',
          filterLevel: this.filterLevel,
          selection: true,
          totalsRow: true,
          configurable: true,
          columnDefs: [
            {
              columnName: 'clientrole_to',
              title: this.gettext('Related counterparty to'),
              cellTemplate: html`
                <a ui-sref="gt.page.client({ id: item.clientrole_to_client_id })" target="_blank">
                  {[{ item.clientrole_to_name }]}
                </a>
              `,
              totalTemplate: html`
                <i class="fa-solid fa-chart-simple"></i>
                <translate>TOTAL</translate>
              `,
            },
            {
              columnName: 'clientrole_from',
              title: this.gettext('Related counterparty from'),
              cellTemplate: html`
                <a ui-sref="gt.page.client({ id: item.clientrole_from_client_id })" target="_blank">
                  {[{ item.clientrole_from_name }]}
                </a>
              `,
            },
            {
              columnName: 'contract_numbers',
              title: this.gettext('Contract numbers'),
              cellTemplate: html`
                <span ng-repeat="contract in item.position_contracts">
                  <a ui-sref="gt.page.contract({ id: contract.contract_id })" target="_blank">
                    {[{ contract.contract_number }]}
                  </a>
                  <span ng-if="contract.crop_title">({[{ contract.crop_title }]})</span>
                  <div class="clearfix"></div>
                </span>
              `,
            },
            {
              columnName: 'invoice_number',
              title: this.gettext('Invoice number'),
              cellTemplate: html`
                <a ui-sref="gt.page.payment({id: item.id})" target="_blank">
                  {[{ item.invoice_number }]}
                </a>
              `,
            },
            {
              columnName: 'create_time',
              title: this.gettext('Create time'),
              cellTemplate: "{[{ item.create_time | date:'dd.MM.yy HH:mm' }]}",
            },
            {
              columnName: 'condition',
              title: this.gettext('Condition'),
            },
            {
              columnName: 'amount',
              title: this.gettext('amount'),
              cellTemplate: '{[{ item.amount || 0 | number:2 }]} {[{ item.currency_symbol }]}',
              totalTemplate: html`
                <div ng-repeat="total in item.totals">
                  {[{ total.total_amount || 0 | number: 2 }]} {[{ total.currency_symbol || '---' }]}
                  <div class="clearfix"></div>
                </div>
              `,
            },
            {
              columnName: 'to_pay',
              title: this.gettext('To pay'),
              cellTemplate: '{[{ item.to_pay || 0 | number:2 }]} {[{ item.currency_symbol }]}',
              totalTemplate: html`
                <div ng-repeat="total in item.totals">
                  {[{ total.to_pay_total || 0 | number: 2 }]} {[{ total.currency_symbol || '---' }]}
                  <div class="clearfix"></div>
                </div>
              `,
            },
            {
              columnName: 'volume',
              title: this.gettext('Volume'),
              cellTemplate: '{[{ item.volume || 0 | number:3 }]}',
            },
          ],
          rowData: [],
          onInit: (api: any) => (this.tableApi = api),
        };
      }

      // eslint-disable-next-line sonarjs/function-return-type
      createMailing() {
        const selectedItems = this.tableApi.getSelectedRowData();

        if (!selectedItems.length) {
          notify(
            this.GtUtils.translate(this.gettext('Please select at least one invoice')),
            'warning',
          );
          return false;
        }

        const msg = this.GtUtils.translate(
          this.gettext(
            `Do you want to create mailing by contracts from selected ${selectedItems.length} invoice(s)?`,
          ),
        );

        if (!confirm(msg)) {
          return false;
        }
        this.GtUtils.overlay('show');
        return this.MailingService.createMailingsFromFinances({
          finances: selectedItems.map((i: any) => i.id),
          template_id: this.mailingTemplate,
          collection_id: this.mailingExportConfig,
          docs: this.mailingAttachDocs,
        }).then(() => {
          this.updateData();
          this.gtFilterService.updateQueryParams({}, this.mailingsFilterLevel);
        }, this.GtUtils.errorClb);
      }
    },
  ],
};
