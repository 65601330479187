import ng from 'angular';

import type { GtFilterService } from '../../gt-filter/gt-filter.srv';

import type { HelpButtonsService } from '^/app/core/legacy/help-buttons/help-buttons.srv';

(function () {
  'use strict';
  ng.module('core.legacy').directive('filterButton', directive);

  function directive() {
    return {
      template: require('./filter-button.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        level: '=?',
      },
    };
  }

  Controller.$inject = ['$scope', 'HelpButtonsService', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    HelpButtonsService: HelpButtonsService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;

    vm.clicked = HelpButtonsService.getConfig(($scope as any).level).filter?.clicked ?? false;
    vm.click = click;

    activate();

    ////////////////

    function activate() {
      $scope.$on('hb-config-updated', function () {
        vm.clicked = HelpButtonsService.getConfig(($scope as any).level).filter?.clicked ?? false;
      });
    }

    function click() {
      if (vm.clicked) {
        gtFilterService.setQueryParams({});
      }
      vm.clicked = !vm.clicked;
      const hbConfig = HelpButtonsService.getConfig(($scope as any).level);
      hbConfig.filter = { ...hbConfig.filter, clicked: vm.clicked };
      HelpButtonsService.setConfig(hbConfig, ($scope as any).level);
    }
  }
})();
