import ng from 'angular';

import type { ViewMode } from '~/core/datasets';

import type { GtRootScopeService } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('company.legacy').component('teamTable', {
    bindings: {
      filterLevel: '<?',
      users: '<?',
      count: '<?',
      onUpdate: '&',
      openUserModal: '<?',
      viewMode: '<?',
    },
    template: require('./team-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$rootScope', 'gettext'];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.viewMode = 'table' as ViewMode;
    vm.$onInit = function () {
      vm.theadConfig = getTheadConfig();
    };
    vm.changeViewMode = (view: ViewMode) => {
      vm.viewMode = view;
      $rootScope.$applyAsync();
    };
    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: gettext('username / login'),
          columnName: 'username',
          predicate: 'username',
        },
        {
          title: gettext('first name'),
          columnName: 'first_name',
          predicate: 'first_name',
        },
        {
          title: gettext('last name'),
          columnName: 'last_name',
          predicate: 'last_name',
        },
        {
          title: gettext('phone'),
          columnName: 'phone',
        },
        {
          title: gettext('email'),
          columnName: 'email',
          predicate: 'email',
        },
        {
          title: gettext('active'),
          columnName: 'active',
          predicate: 'is_active',
        },
        {
          title: gettext('access admin'),
          columnName: 'access_admin',
          predicate: 'is_staff',
        },
        {
          title: gettext('superuser'),
          columnName: 'superuser',
          predicate: 'is_superuser',
        },
        {
          title: gettext('business units'),
          columnName: 'business_unit',
        },
        {
          title: gettext('date joined'),
          columnName: 'date_joined',
          predicate: 'date_joined',
        },
        {
          title: gettext('last login'),
          columnName: 'last_login',
          predicate: 'last_login',
        },
        {
          title: gettext('telegram'),
          columnName: 'telegram',
        },
        {
          title: gettext('language'),
          columnName: 'language',
        },
        {
          title: gettext('simple'),
          columnName: 'simple_system',
        },
        {
          title: gettext('wizard'),
          columnName: 'wizard',
        },
      ];

      if ($rootScope.user.settings.USE_WORK_STATUS) {
        config.columns.push({
          title: gettext('work status'),
          columnName: 'work_status',
        });
        config.columns.push({
          title: gettext('work status start'),
          columnName: 'work_status_start',
        });
        config.columns.push({
          title: gettext('work status end'),
          columnName: 'work_status_end',
        });
        config.columns.push({
          title: gettext('work status note'),
          columnName: 'work_status_note',
        });
      }

      return config;
    }
  }
})();
