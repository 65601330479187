import {
  BaseLogisticRepository,
  InfoLogisticListTotals,
  LogisticQueryParams,
  QualitiesLogisticRecord,
} from '~/features/execution/logistic/common';
import {
  logisticsLogisticsListQualitiesList,
  logisticsLogisticsTotalsInfoRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';

@singleton()
export class QualitiesLogisticRepository extends BaseLogisticRepository<
  QualitiesLogisticRecord,
  InfoLogisticListTotals
> {
  queryFn = async (query: LogisticQueryParams) => {
    const { data } = await logisticsLogisticsListQualitiesList({ query });
    return { records: data.results, count: data.count };
  };
  getListTotals = async (query: LogisticQueryParams) => {
    const { data } = await logisticsLogisticsTotalsInfoRetrieve({ query });
    return data;
  };
}
