import type ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export class BankBalanceReportService {
  $http: ng.IHttpService;
  ReportsService: ReportsService;
  dates: any;
  details_template: any;
  gettext: ng.gettext.gettextFunction;
  indicators: any;
  indicators_template: any;
  mode: any;
  periodMasks: any;
  pretifyDate: any;
  results: any;
  constructor(
    $http: ng.IHttpService,
    gettext: ng.gettext.gettextFunction,
    ReportsService: ReportsService,
  ) {
    this.$http = $http;
    this.gettext = gettext;
    this.ReportsService = ReportsService;
    this.periodMasks = {
      year: 'dd.MM.yyyy',
      quarter: 'Q [QUARTER] yyyy',
      month: 'MMM yyyy',
      week: 'WW [week starts at] dd.MM.yyyy',
      day: 'dd.MM.yyyy',
      default: 'dd.MM.yyyy',
    };
  }

  getReportData(params: { mode: string }) {
    const periodMask = this.periodMasks[params.mode] || this.periodMasks.default;
    this.pretifyDate = (date: Date | string) => formatDate(date, periodMask);
    this.mode = params.mode;
    return this.$http({
      method: 'GET',
      url: '/api/reports/bank-balance-report/',
      params: params,
    }).then((response: any) => this.prepareBankBalanceData(response.data));
  }

  prepareBankBalanceData(data: any) {
    this.indicators = data.extra_list.indicators;
    this.indicators_template = data.extra_list.indicators_template;
    this.details_template = data.extra_list.details_template;
    this.dates = data.extra_list.dates.map((v: any) => ({
      title: this.pretifyDate(v.date),
      date: v.date,
      str: v.key,
    }));
    this.results = this.unpack(data.results);
    this.setRowsVisibility(1);
    return {
      results: this.results,
      dates: this.dates,
      indicators: this.indicators,
      chartsConfigs: this.results
        .filter((v: any) => v.level === 1)
        .map((currencyData: any) => ({
          currency: currencyData.currency_symbol,
          hideChart: true,
          chartConfig: this.getChartConfig(currencyData),
        })),
    };
  }

  unpack(data: any, parent = {}) {
    const result: any = [];
    for (const branch of data) {
      if (!branch) {
        continue;
      }
      Object.assign(branch, { indicators: this.deepCopy(this.indicators_template) });
      branch.parent = parent;
      if (branch.level === 'rests') {
        result.push(this.setDetails(branch.parent));
        break;
      } else if (branch.data?.length) {
        result.push(branch);
        const children = this.unpack(branch.data, branch);

        if (branch.data) {
          this.indicators.forEach((ind: any) =>
            this.dates.forEach((d: any) =>
              branch.data.forEach(
                (row: any) => (branch.indicators[d.str][ind] += row.indicators[d.str][ind]),
              ),
            ),
          );
        }
        result.push(...children);
      } else {
        result.push(branch);
      }
    }
    return result;
  }

  deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  setDetails(branch: any) {
    const details = {
      parent: branch,
      ...this.deepCopy(this.details_template),
    };
    branch.data.forEach((rest: any) => {
      const strDate = formatDate(rest.date, 'yyyyMMdd');
      this.indicators.forEach((ind: any) => (branch.indicators[strDate][ind] += rest[ind]));
      rest.data?.filter((x: any) => x).forEach((row: any) => details[strDate].push(row));
    });
    delete branch.data;
    return details;
  }

  getChartConfig(data: any) {
    return {
      hideChart: true,
      labels: this.dates.map((v: any) => v.str),
      data: {
        StartBalance: this.dates.map((v: any) =>
          data.indicators[v.str].start_balance_local.toFixed(2),
        ),
        EndBalance: this.dates.map((v: any) => data.indicators[v.str].end_balance_local.toFixed(2)),
        Incoming: this.dates.map((v: any) => data.indicators[v.str].incoming_local.toFixed(2)),
        Outgoing: this.dates.map((v: any) => data.indicators[v.str].outgoing_local.toFixed(2)),
        Total: this.dates.map((v: any) => data.indicators[v.str].amount_local.toFixed(2)),
      },
    };
  }

  setRowsVisibility(level: number) {
    this.results.forEach((v: any, i: any) => {
      v.index = i;
      v.parentIndex = v.parent.index;
      v.expand = v.show = Boolean(v.level < level);
    });
  }

  parseGroups(groupChoices: any, groupsFilter = '') {
    return this.ReportsService.parseGroupsFromString(groupChoices, groupsFilter);
  }

  getTheadConfig(groupsTitle: any, indicatorView: any) {
    const config: any = { tabs: [], columns: [], columnSets: [] };
    config.columnSets = [
      {
        groupTitle: this.gettext('groups'),
        group: 'groups',
      },
    ];
    config.columns = [
      {
        title: groupsTitle,
        columnName: 'group_data',
        predicate: 'group_data',
        group: 'groups',
        class: '',
      },
    ];

    this.dates?.forEach((date: any) => {
      if (indicatorView === 'horizontal') {
        config.columnSets.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          icon: 'fa-calendar',
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: this.gettext('start balance'),
          predicate: 'start_balance',
          predicateLocal: 'start_balance_local',
          title: this.gettext('start balance'),
          icon: 'fa-flag-o',
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: this.gettext('incoming'),
          predicate: 'incoming',
          predicateLocal: 'incoming_local',
          title: this.gettext('incoming'),
          icon: 'fa-plus',
          class: 'positive-number',
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: this.gettext('outgoing'),
          predicate: 'outgoing',
          predicateLocal: 'outgoing_local',
          title: this.gettext('outgoing'),
          icon: 'fa-minus',
          class: 'negative-number',
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: this.gettext('total'),
          predicate: 'amount',
          predicateLocal: 'amount_local',
          title: this.gettext('total'),
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: this.gettext('end balance'),
          predicate: 'end_balance',
          predicateLocal: 'end_balance_local',
          title: this.gettext('end balance'),
          icon: 'fa-flag',
        });
      }
      if (indicatorView === 'vertical') {
        config.columns.push({
          group: 'months',
          groupTitle: 'months',
          columnName: this.gettext(date.str),
          title: this.gettext(date.title),
          predicate: date.str,
        });
      }
      if (indicatorView === 'mixed') {
        config.columnSets.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          icon: 'fa-calendar',
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: this.gettext('start balance'),
          predicate: 'start_balance',
          predicateLocal: 'start_balance_local',
          title: this.gettext('start balance'),
          icon: 'fa-flag-0',
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: 'amounts',
          title: 'amounts',
          predicate: 'amounts',
          predicateLocal: 'amounts_local',
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: this.gettext('end balance'),
          predicate: 'end_balance',
          predicateLocal: 'end_balance_local',
          title: this.gettext('end balance'),
          icon: 'fa-flag',
        });
      }
    });
    config.columnSets.forEach((item: any) => {
      item.count = config.columns.filter((v: any) => v.group === item.group).length;
    });
    return config;
  }
}
BankBalanceReportService.$inject = ['$http', 'gettext', 'ReportsService'];
