import ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';
import { html } from '^/shared/utils';

(function () {
  'use strict';
  ng.module('reports.legacy').component('confirmationsReportPageView', {
    bindings: {},
    template: require('./confirmations-report-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'GtUtils',
    'ReportsService',
    'PageService',
    'ClientsService',
    'gtFilterService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    ReportsService: ReportsService,
    PageService: PageService,
    ClientsService: any,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;
    vm.gridOptions = {};
    vm.selectedDate = new Date();
    vm.queryParams = {
      serializer: 'confirmations_report',
      report_date: formatDate(vm.selectedDate, 'dd.MM.yyyy'),
      page_size: 1000,
    };
    vm.daysInMonth = GtUtils.daysInMonth(vm.selectedDate);
    vm.filterLevel = 'confirmations-report-page-view';
    vm.dateChanged = dateChanged;
    vm.clients = [];
    vm.clientsCount = 0;
    vm.openClientModal = openClientModal;

    vm.queryParamsUnconfirmed = {
      serializer: 'confirmations_report',
      wo_date_confirmation: true,
      ordering: '-create_time',
      status_list: ['New', 'Pending'],
    };
    vm.filterLevelUnconfirmed = 'confirmations-report-page-view-unconfirmed';
    vm.unconfirmedClientsCount = 0;
    vm.unconfirmedGridOptions = {};
    vm.getRoleIcon = getRoleIcon;
    vm.templates = {
      cellName: html`
        <a ng-click="grid.appScope.vm.openClientModal(row.entity.id)" class="content-label">
          <i class="fa fa-building"></i> {{ COL_FIELD }}
          <span ng-if="row.entity.suppliers.length">
            (<span ng-repeat="suppl in row.entity.suppliers">
              <i class="fa fa-bookmark-o"></i> <span>{{ suppl.name }}</span> </span
            >)
          </span>
        </a>
      `,
      cellRoleNamesSet: html`
        <span class="label" ng-repeat="role in COL_FIELD">
          <i ng-class="grid.appScope.vm.getRoleIcon(role.role_name)"></i>
          <translate>{{ role.role_name }}</translate>
        </span>
      `,
      cellStatus: html`
        <span ng-if="row.entity.status">
          <span
            class="label"
            ng-class="{ 'label_success':row.entity.status == 'Approved' || row.entity.status == 'Approved_condition', 'label_warning':row.entity.status == 'Pending', 'label-default':row.entity.status == 'Rejected', 'label_default':row.entity.status == 'New' }"
          >
            <span
              ng-if="row.entity.status != 'Approved_condition' && row.entity.status != 'Out_of_approval'"
              >{[{ row.entity.status |translate }]}</span
            >
            <span ng-if="row.entity.status == 'Approved_condition'">
              <translate>Approved condition</translate>
            </span>
            <span class="label-opacity" ng-if="row.entity.status == 'Out_of_approval'">
              <translate>Out of approval</translate>
            </span>
          </span>
          <span class="label label_danger" ng-if="row.entity.blacklisted">
            <i class="fa fa-exclamation-triangle"></i> <translate>black list</translate>
          </span>
        </span>
      `,
      cellDate: html`
        <div>
          <span class="date-label" ng-if="COL_FIELD">
            <i class="fa fa-calendar"></i> {{ COL_FIELD | date: 'dd.MM.yy' }}
          </span>
        </div>
      `,
      cellCreateTime: html`
        <span ng-repeat="client in COL_FIELD">
          <a
            ng-if="client.name"
            ng-click="grid.appScope.vm.openClientModal(client.id)"
            class="content-label"
            ng-class="{'label_danger': client.blacklisted }"
          >
            <i class="fa fa-building"></i> {{ client.name }}
            <span ng-if="client.suppliers.length"
              >(
              <span class="label" ng-repeat="suppl in client.suppliers">
                <i class="fa fa-bookmark-o"></i> <span>{{ suppl.name }}</span> </span
              >)
            </span>
            <span ng-if="client.role_names_set.length"
              >(
              <span class="label" ng-repeat="role in client.role_names_set">
                <i ng-class="grid.appScope.vm.getRoleIcon(role.role_name)"></i>
                <translate>{{ role.role_name }}</translate> </span
              >)
            </span>
          </a>
          <span ng-if="!client.name">--</span>
          <div class="clearfix"></div>
        </span>
      `,
    };

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
      updateClients();
      updateUnconfirmedClients();
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      gtFilterService.setQueryParams(vm.queryParamsUnconfirmed, vm.filterLevelUnconfirmed);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateClients();
      });
      $scope.$on('gt-filter-updated_' + vm.filterLevelUnconfirmed, function (ev: any, data: any) {
        vm.queryParamsUnconfirmed = data;
        updateUnconfirmedClients();
      });
    };

    ////////////////

    function updateClients() {
      GtUtils.overlay('show');
      return ClientsService.Client.query(vm.queryParams, function (data: any) {
        vm.clients = data.results;
        vm.clientsCount = data.count;
        updateGridOptions();
        GtUtils.overlay('hide');
      });
    }

    function updateUnconfirmedClients() {
      GtUtils.overlay('show');
      return ClientsService.Client.query(vm.queryParamsUnconfirmed, function (data: any) {
        vm.unconfirmedClientsCount = data.count;
        vm.unconfirmedGridOptions = {
          columnDefs: [
            {
              name: 'Name',
              field: 'name',
              cellClass: 'highlighted-td',
              cellTemplate: vm.templates.cellName,
              minWidth: 150,
              maxWidth: 200,
            },
            {
              name: 'Roles',
              field: 'role_names_set',
              cellTemplate: vm.templates.cellRoleNamesSet,
              maxWidth: 110,
            },
            {
              name: 'Status',
              cellTemplate: vm.templates.cellStatus,
              width: 60,
            },
            {
              name: 'Create',
              field: 'create_time',
              cellTemplate: vm.templates.cellDate,
              width: 80,
            },
            {
              name: 'Request',
              field: 'date_request',
              cellTemplate: vm.templates.cellDate,
              width: 80,
            },
            {
              name: 'Received',
              field: 'date_received',
              cellTemplate: vm.templates.cellDate,
              width: 80,
            },
            {
              name: 'Notes',
              field: 'confirmation_info',
              cellTemplate: html`<span class="tooltip" data-tip="{{COL_FIELD}}">
                {{COL_FIELD}}
              </span>`,
              minWidth: 400,
            },
          ],
          data: data.results,
        };
        GtUtils.overlay('hide');
      });
    }

    function updateGridOptions() {
      vm.gridOptions = {
        columnDefs: [
          {
            name: 'Date',
            field: 'date',
            cellClass: 'highlighted-td',
            cellTemplate: vm.templates.cellDate,
            width: 80,
          },
          {
            name: 'Created',
            field: 'create_time',
            cellTemplate: vm.templates.cellCreateTime,
          },
          {
            name: 'Requested',
            field: 'date_request',
            cellTemplate: vm.templates.cellCreateTime,
          },
          {
            name: 'Received',
            field: 'date_received',
            cellTemplate: vm.templates.cellCreateTime,
          },
          {
            name: 'Confirmed',
            field: 'date_confirmation',
            cellTemplate: vm.templates.cellCreateTime,
          },
        ],
        data: [],
      };
      for (let d = vm.daysInMonth; d > 0; d--) {
        const thisDay = new Date(vm.selectedDate.getFullYear(), vm.selectedDate.getMonth(), d);
        const row: any = {
          date: thisDay,
          create_time: [],
          date_request: [],
          date_received: [],
          date_confirmation: [],
        };
        let emptyRow = true;
        for (const client of vm.clients) {
          let skipClient = true;
          if (GtUtils.isSameDay(client.create_time, thisDay)) {
            row.create_time.push(client);
            emptyRow = false;
            skipClient = false;
          } else {
            row.create_time.push({ name: null });
          }
          if (GtUtils.isSameDay(client.date_request, thisDay)) {
            row.date_request.push(client);
            skipClient = false;
            emptyRow = false;
          } else {
            row.date_request.push({ name: null });
          }
          if (GtUtils.isSameDay(client.date_received, thisDay)) {
            row.date_received.push(client);
            skipClient = false;
            emptyRow = false;
          } else {
            row.date_received.push({ name: null });
          }
          if (GtUtils.isSameDay(client.date_confirmation, thisDay)) {
            row.date_confirmation.push(client);
            skipClient = false;
            emptyRow = false;
          } else {
            row.date_confirmation.push({ name: null });
          }

          if (skipClient) {
            row.create_time.pop();
            row.date_request.pop();
            row.date_received.pop();
            row.date_confirmation.pop();
          }
        }
        if (!emptyRow) {
          vm.gridOptions.data.push(row);
        }
      }
    }

    function dateChanged() {
      vm.daysInMonth = GtUtils.daysInMonth(vm.selectedDate);
      vm.queryParams.report_date = formatDate(vm.selectedDate, 'dd.MM.yyyy');
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }

    function getPageConfig() {
      return {
        class: 'page-header-main-clients-tab',
        buttons: [new PageService.buttons.Print()],
        pages: ReportsService.getPagesConfig(),
      };
    }

    function openClientModal(clientId: number) {
      return ClientsService.clientModal(clientId).then(updateClients);
    }

    function getRoleIcon(roleName: string) {
      return 'fa ' + GtUtils.getIcon('clients.' + roleName);
    }
  }
})();
