import React from 'react';

import { ToolbarSeparator } from '~/shared/ui/kit/toolbar';

import { ActionView } from './action-view';
import type { ActionBarConfig } from '../types';

export const ActionBarList: React.FC<{ config: ActionBarConfig[] }> = ({ config }) => {
  return (
    <>
      {config.map(({ key, Icon, iconContext, view, label, action, disabled }) =>
        key.includes('separator') ? (
          <ToolbarSeparator key={key} className="h-5" />
        ) : (
          <ActionView
            view={view}
            action={action}
            disabled={disabled}
            key={key}
            iconContext={iconContext}
            Icon={Icon}
            label={label}
          />
        ),
      )}
    </>
  );
};
