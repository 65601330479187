import type ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

export class InvoiceFilterConfigService {
  $rootScope: GtRootScopeService;
  CustomValuesService: CustomValuesService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  gtFilterService: GtFilterService;

  static readonly $inject = [
    '$rootScope',
    'GtUtils',
    'gtFilterService',
    'gettext',
    'CustomValuesService',
  ];

  constructor(
    $rootScope: GtRootScopeService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    CustomValuesService: CustomValuesService,
  ) {
    this.$rootScope = $rootScope;
    this.GtUtils = GtUtils;
    this.gtFilterService = gtFilterService;
    this.gettext = gettext;
    this.CustomValuesService = CustomValuesService;
  }

  getFilterConfig(filterLevel: string) {
    const filters: any = {
      groupBy: {
        clientrole_from: this.gettext('Client from'),
        clientrole_to: this.gettext('Client to'),
        status: this.gettext('Status'),
        season: this.gettext('Season'),
        business_unit: this.gettext('Business unit'),
      },
      filterLevel: filterLevel,
      ordering: [
        {
          predicate: 'update_time',
          title: this.gettext('RECENTLY UPDATED'),
        },
        {
          predicate: 'status',
          title: this.gettext('STATUS'),
        },
      ],
      clicked: false,
      search: true,
      dates: true,
      useFlexDateRange: true,
      invert_filters: true,
      dateSelects: [
        {
          argument: 'date',
          placeholder: this.gettext('Date'),
        },
        {
          argument: 'date_of_receiving',
          placeholder: this.gettext('Date of receiving'),
        },
        {
          argument: 'date_of_execution',
          placeholder: this.gettext('Date of payment (plan)'),
        },
        {
          argument: 'payment__date',
          placeholder: this.gettext('Date of payment (fact)'),
        },
        {
          argument: 'date_of_withdrawal',
          placeholder: this.gettext('Date of withdrawal'),
        },
        {
          argument: 'positions__passport__business_date',
          placeholder: this.gettext('passport business date'),
        },
      ],
      selects: [],
      multiSelects: [
        {
          argument: 'id_list',
          placeholder: this.gettext('invoice number'),
          resource: 'finances.Finance',
          related_fk: 'id',
          related_model: 'finances.Finance',
        },
        {
          argument: 'clientrole_from_list',
          placeholder: this.gettext('from company'),
          resource: 'clients.clientrole',
          related_fk: 'clientrole_from',
          related_model: 'finances.Finance',
        },
        {
          argument: 'clientrole_to_list',
          placeholder: this.gettext('to company'),
          resource: 'clients.clientrole',
          related_fk: 'clientrole_to',
          related_model: 'finances.Finance',
        },
        {
          argument: 'client_list',
          placeholder: this.gettext('client'),
          resource: 'clients.client',
        },
        {
          argument: 'currency_list',
          placeholder: this.gettext('currency'),
          resource: 'finances.currency',
          related_fk: 'currency',
          related_model: 'finances.Finance',
        },
        {
          argument: 'bank_account_list',
          placeholder: this.gettext('bank account'),
          resource: 'finances.bankaccount',
          related_fk: 'bank_account',
          related_model: 'finances.Finance',
        },
        {
          argument: 'counterpaty_payment_info_list',
          placeholder: this.gettext('counterparty bank account'),
          resource: 'finances.PaymentInfo',
          related_fk: 'counterpaty_payment_info',
          related_model: 'finances.Finance',
        },
        {
          argument: 'contract_list',
          placeholder: this.gettext('contract'),
          resource: 'contracts.ContractBase',
          related_fk: 'positions__contract',
          related_model: 'finances.Finance',
        },
        {
          argument: 'contract_basis_list',
          placeholder: this.gettext('contract incoterms'),
          resource: 'logistics.Basis',
          related_fk: 'positions__contract__basis',
          related_model: 'finances.Finance',
        },
        {
          argument: 'commodity_list',
          placeholder: this.gettext('product'),
          resource: 'crops.Crop',
          related_fk: 'positions__crop',
          related_model: 'finances.Finance',
        },
        {
          argument: 'charge_list',
          placeholder: this.gettext('charge'),
          resource: 'finances.Charge',
          related_fk: 'positions__contractcharge__charge',
          related_model: 'finances.Finance',
        },
        {
          argument: 'expenses_option_list',
          placeholder: this.gettext('General expense'),
          resource: 'finances.GeneralExpenses',
          related_fk: 'positions__expenses_option',
          related_model: 'finances.Finance',
        },
        {
          argument: 'other_activity_list',
          placeholder: this.gettext('Other activity'),
          resource: 'finances.OtherActivity',
          related_fk: 'positions__other_activity',
          related_model: 'finances.Finance',
        },
        {
          argument: 'business_unit_list',
          placeholder: this.gettext('business unit'),
          resource: 'core.BusinessUnit',
          getQueryParams: this.GtUtils.getBUQueryParams,
        },
        {
          argument: 'season_list',
          placeholder: this.gettext('season'),
          resource: 'core.Season',
          related_fk: 'season',
          related_model: 'finances.Finance',
        },
        {
          argument: 'paper_trade_list',
          placeholder: this.gettext('paper trade'),
          resource: 'contracts.papertrade',
          related_fk: 'positions__contract__paper_trade',
          related_model: 'finances.Finance',
        },
        {
          argument: 'passport',
          placeholder: this.gettext('Passports (contract and direct)'),
          resource: 'passports.passport',
        },
        {
          argument: 'passport_list',
          placeholder: this.gettext('Contract passports'),
          resource: 'passports.passport',
        },
        {
          argument: 'author_list',
          placeholder: this.gettext('authors'),
          resource: 'auth.user',
          related_fk: 'author',
          related_model: 'finances.Finance',
        },
        {
          argument: 'editor_list',
          placeholder: this.gettext('editors'),
          resource: 'auth.user',
          related_fk: 'editor',
          related_model: 'finances.Finance',
        },
        {
          argument: 'responsible_list',
          placeholder: this.gettext('responsible'),
          resource: 'auth.user',
          related_fk: 'responsible',
          related_model: 'finances.Finance',
        },
        {
          argument: 'responsible_for_signing_list',
          placeholder: this.gettext('responsible for signing'),
          resource: 'auth.user',
          related_fk: 'responsible_for_signing',
          related_model: 'finances.Finance',
        },
        {
          argument: 'payment_info_list',
          placeholder: this.gettext('Payment bank name'),
          resource: 'finances.PaymentInfo',
        },
        {
          argument: 'position_contract_passport_custom_status_list',
          placeholder: this.gettext('contracts passport custom status'),
          resource: 'core.CustomStatus',
          queryParams: {
            content_type__model: 'passport',
          },
        },
        {
          argument: 'position_contract_passport_vessel_list',
          placeholder: this.gettext('contracts passport vessel'),
          resource: 'logistics.vessel',
        },
        {
          argument: 'position_contract_passport_voyage_list',
          placeholder: this.gettext('contracts passport voyage'),
          resource: 'logistics.vessel',
        },
        {
          argument: 'passport_voyage_list',
          placeholder: this.gettext('passport voyage'),
          resource: 'logistics.vessel',
        },
        {
          argument: 'next_approver_list',
          placeholder: this.gettext('next approver'),
          resource: 'auth.user',
        },
        {
          argument: 'clientrole_list',
          placeholder: this.gettext('contract counterparty'),
          resource: 'clients.ClientRole',
          getQueryParams: () => {
            return { role_name_list: ['supplier', 'buyer'] };
          },
        },
        {
          argument: 'billoflading_list',
          placeholder: this.gettext('BLs'),
          resource: 'logistics.billoflading',
          related_fk: 'positions__invoicepositionbilloflading__bill_of_lading_id',
          related_model: 'finances.Finance',
        },
        {
          argument: 'company_group_owner_list',
          placeholder: this.gettext('Company group owner'),
          resource: 'clients.CompanyGroup',
          queryParams: {
            is_invoice_owner: '1',
          },
        },
        {
          argument: 'company_group_counterparty_list',
          placeholder: this.gettext('Company group counterparty'),
          resource: 'clients.CompanyGroup',
          queryParams: {
            is_invoice_counterparty: '1',
          },
        },
        {
          argument: 'payment_conditions_option_list',
          placeholder: this.gettext('Payment conditions option'),
          resource: 'finances.PaymentCondition',
          related_fk: 'payment_conditions_option',
        },
        {
          argument: 'tse_list',
          placeholder: this.gettext('TSE'),
          resource: 'logistics.transportationstorageexpense',
          related_fk: 'positions__tse_positions__expense',
          related_model: 'finances.Finance',
        },
        {
          argument: 'credit_insurer_list',
          placeholder: this.gettext('Credit insurer'),
          resource: 'clients.ClientRole',
        },
      ],
      nestedSelects: [
        this.gtFilterService.getBoolFilter('with_logistic', this.gettext('Has logistic')),
        this.gtFilterService.getBoolFilter(
          'with_invoiced_logistic',
          this.gettext('Invoiced logistic'),
        ),

        this.gtFilterService.getBoolFilter(
          'with_date_of_execution',
          this.gettext('Has date of execution'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_passport',
          this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
        ),
        this.gtFilterService.getBoolFilter('with_voyage', this.gettext('With voyage')),
        this.gtFilterService.getBoolFilter(
          'with_position_voyage',
          this.gettext('With contracts voyage'),
        ),
        this.gtFilterService.getBoolFilter('with_documents', this.gettext('Has documents')),
        this.gtFilterService.getBoolFilter(
          'with_clientrole_from',
          this.gettext('Has issuer counterparty'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_clientrole_to',
          this.gettext('Has receiver counterparty'),
        ),
        this.gtFilterService.getBoolFilter('with_contract', this.gettext('Has contract')),

        this.gtFilterService.getBoolFilter('with_business_unit', this.gettext('Has Business Unit')),
        this.gtFilterService.getBoolFilter(
          'has_credit_insurer',
          this.gettext('Has Credit Insurer'),
        ),
        this.gtFilterService.getBoolFilter('not_pay', this.gettext('Do not pay')),
        this.gtFilterService.getBoolFilter('with_season', this.gettext('Has season')),
        this.gtFilterService.getBoolFilter('with_payment_info', this.gettext('Has Payment Info')),
        this.gtFilterService.getBoolFilter('has_discount', this.gettext('Has Discount')),
        this.gtFilterService.getBoolFilter('billofladings_any_is_loi', this.gettext('Has LOI')),
        this.gtFilterService.getBoolFilter(
          'has_withdrawal_date',
          this.gettext('Has withdrawal date'),
        ),
        this.gtFilterService.getBoolFilter(
          'has_invoicepositionlogistic',
          this.gettext('Has export logistics'),
        ),

        {
          argument: 'with_delay',
          class: '',
          items: [
            { id: 1, title: this.gettext('Yes') },
            { id: 0, title: this.gettext('No') },
          ],
          placeholder: this.gettext('Delayed payment'),
        },

        this.gtFilterService.getBoolFilter(
          'has_contract_internal_chain',
          this.gettext('Internal chain'),
        ),
        {
          argument: 'wait_my_approval',
          class: '',
          items: [{ id: this.$rootScope.user.id, title: this.gettext('Yes') }],
          placeholder: this.gettext('Waiting my approval'),
        },
        {
          argument: 'overpaid',
          class: '',
          items: [{ id: 1, title: this.gettext('Yes') }],
          placeholder: this.gettext('Overpaid'),
        },
        {
          argument: 'with_outstanding_payment',
          class: '',
          items: [{ id: 1, title: this.gettext('Yes') }],
          placeholder: this.gettext('Outstanding payment'),
        },
      ],
      nestedMultiSelects: [
        {
          argument: 'status_list',
          placeholder: this.gettext('Status'),
          items: [
            {
              id: 'new',
              title: this.gettext('New'),
            },
            {
              id: 'process',
              title: this.gettext('Process'),
            },
            {
              id: 'paid',
              title: this.gettext('Paid'),
            },
            {
              id: 'canceled',
              title: this.gettext('Canceled'),
            },
          ],
        },
        {
          argument: 'with_invoiced_logistic_total_value',
          placeholder: this.gettext('Logistic difference with invoice'),
          items: [
            {
              id: '1',
              title: this.gettext('Difference > 0'),
            },
            {
              id: '2',
              title: this.gettext('Difference >= 0.1'),
            },
            {
              id: '0',
              title: this.gettext('Difference = 0'),
            },
          ],
        },
        {
          argument: 'invoice_type',
          placeholder: this.gettext('Type'),
          items: [
            {
              id: 'outgoing',
              title: this.gettext('Outgoing'),
            },
            {
              id: 'incoming',
              title: this.gettext('incoming'),
            },
          ],
        },
        {
          argument: 'assignment',
          placeholder: this.gettext('Assignment'),
          items: [
            {
              id: 'commercial',
              title: this.gettext('Commercial'),
            },
            {
              id: 'proforma',
              title: this.gettext('Proforma'),
            },
            {
              id: 'debit',
              title: this.gettext('Debit'),
            },
            {
              id: 'credit',
              title: this.gettext('Credit'),
            },
            {
              id: 'reserve',
              title: this.gettext('Reserve'),
            },
          ],
        },
        {
          argument: 'condition',
          placeholder: this.gettext('Condition'),
          items: [
            {
              id: 'prepay',
              title: this.gettext('Prepay'),
            },
            {
              id: 'balance',
              title: this.gettext('Balance'),
            },
          ],
        },

        {
          argument: 'contract_deal_type',
          class: '',
          items: [
            { id: 'spot', title: this.gettext('Spot') },
            { id: 'forward', title: this.gettext('Forward') },
            { id: 'intermediate', title: this.gettext('Intermediate') },
            { id: 'export', title: this.gettext('Export') },
          ],
          placeholder: this.gettext('Contract deal type'),
        },
        {
          argument: 'without_status_list',
          placeholder: this.gettext('Without a status'),
          items: [
            {
              id: 'new',
              title: this.gettext('New'),
            },
            {
              id: 'process',
              title: this.gettext('Process'),
            },
            {
              id: 'paid',
              title: this.gettext('Paid'),
            },
            {
              id: 'canceled',
              title: this.gettext('Canceled'),
            },
          ],
        },
        {
          argument: 'approval_status_list',
          placeholder: this.gettext('Approval status'),
          items: [
            { id: 'wait', title: 'Waiting for approval' },
            { id: 'approved', title: 'Approved' },
            { id: 'process', title: 'Approval in process' },
            { id: 'rejected', title: 'Rejected' },
          ],
        },
        {
          argument: 'use_list',
          placeholder: this.gettext('Use'),
          items: [
            {
              id: 'cargo',
              title: this.gettext('Cargo'),
            },
            {
              id: 'costs',
              title: this.gettext('Costs'),
            },
            {
              id: 'gains',
              title: this.gettext('Gains'),
            },
            {
              id: 'expenses',
              title: this.gettext('General expenses'),
            },
            {
              id: 'washout',
              title: this.gettext('Washout'),
            },
            {
              id: 'credit',
              title: this.gettext('Credit'),
            },
            {
              id: 'other',
              title: this.gettext('Other'),
            },
          ],
        },
        {
          argument: 'logistic_season_list',
          placeholder: this.gettext('Logistic season'),
          items: this.GtUtils.getYearList(),
        },
        {
          argument: 'contract_crop_year_list',
          placeholder: this.gettext('Contract crop year'),
          items: this.GtUtils.getYearList(),
        },
        this.gtFilterService.getClientRoleFilter(
          'clientrole_from_type_list',
          this.gettext('client role from type'),
        ),
        this.gtFilterService.getClientRoleFilter(
          'clientrole_to_type_list',
          this.gettext('client role to type'),
        ),
        {
          argument: 'position_passport_and_contract_passport_voyage_status_list',
          placeholder: this.gettext('Voyage status'),
          items: [
            { id: 'New', title: this.gettext('New') },
            { id: 'pol_under_way', title: this.gettext('POL under way') },
            { id: 'at_loading_port', title: this.gettext('At loading port') },
            { id: 'Loading', title: this.gettext('Loading') },
            { id: 'Loaded', title: this.gettext('Loaded') },
            { id: 'pod_under_way', title: this.gettext('POD under way') },
            { id: 'at_discharging_port', title: this.gettext('At discharging port') },
            { id: 'Discharging', title: this.gettext('Discharging') },
            { id: 'Discharged', title: this.gettext('Discharged') },
            { id: 'Executed', title: this.gettext('Executed') },
            { id: 'Washout', title: this.gettext('Washout') },
          ],
        },
        this.gtFilterService.getPassportStatusFilter(
          'position_contract_passport_status_list',
          this.gettext('Passport status'),
        ),
        this.gtFilterService.getContractStatusFilter(
          'position_contract_status_list',
          this.gettext('contract status'),
        ),
      ],
    };
    this.CustomValuesService.getCustomFieldFilters({
      purpose_model: 'finance',
    })
      .then((customFieldsFilters) => {
        filters.multiSelects.push(...customFieldsFilters.multiSelects);
        filters.selects.push(...customFieldsFilters.selects);
        filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
        filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
        filters.dateSelects.push(...customFieldsFilters.dateSelects);
        this.$rootScope.$applyAsync();
      })
      .catch(errorHandler);
    return filters;
  }
}
