import type ng from 'angular';

import template from './disbursementbl-list-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const DisbursementblListPage = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gtFilterService',
    'PageService',
    'LogisticsService',
    'GtUtils',
    'gettext',
    class {
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      PageService: PageService;
      filterLevel = 'disbursementbl-list-page';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        gtFilterService: GtFilterService,
        PageService: PageService,
        LogisticsService: LogisticsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.LogisticsService = LogisticsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
            new this.PageService.buttons.Import('finances.disbursementbl'),
            new this.PageService.buttons.Export('finances.disbursementbl', this.filterLevel),
          ],
          pages: this.LogisticsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            search: true,
            dates: true,
            dateSelects: [
              {
                argument: 'create_time',
                placeholder: this.gettext('Create date'),
              },
              {
                argument: 'date',
                placeholder: this.gettext('Dbl date'),
              },
              {
                argument: 'purchase_contract__conclusion_date',
                placeholder: this.gettext('Contract conclusion date'),
              },
              {
                argument: 'offsetposition__finance_offset__date',
                placeholder: this.gettext('Offset date'),
              },
              {
                argument:
                  'reassignmentposition__reassignment__offsetposition__finance_offset__date',
                placeholder: this.gettext('Offset through reassignment date'),
              },
            ],
            multiSelects: [
              {
                argument: 'sap_order_list',
                placeholder: this.gettext('sap order'),
                resource: 'contracts.SapOrder',
              },
              {
                argument: 'purchase_contract_supplier_list',
                placeholder: this.gettext('suppliers'),
                resource: 'clients.supplier',
              },
              {
                argument: 'purchase_contract_distributor_list',
                placeholder: this.gettext('suppliers/distributors'),
                resource: 'clients.supplier',
                getQueryParams: () => {
                  return { is_distributor: 1 };
                },
              },
              {
                argument: 'purchase_contract_contractoption_list',
                placeholder: this.gettext('option'),
                resource: 'contracts.ContractOption',
              },
              {
                argument: 'purchase_contract_list',
                placeholder: this.gettext('purchase contract'),
                resource: 'contracts.PurchaseContract',
              },
              {
                argument: 'purchase_contract_cargo_list',
                placeholder: this.gettext('cargo'),
                resource: 'crops.crop',
              },
              {
                argument: 'purchase_contract_responsible_list',
                placeholder: this.gettext('responsible'),
                resource: 'auth.user',
              },
              {
                argument: 'purchase_contract_port_list',
                placeholder: this.gettext('port'),
                resource: 'logistics.port',
              },
              {
                argument: 'purchase_contract_shipment_terminal_list',
                placeholder: this.gettext('shipment terminal'),
                resource: 'logistics.terminal',
              },
              {
                argument: 'purchase_contract_custom_status_list',
                placeholder: this.gettext('purchase contract custom status'),
                resource: 'core.customstatus',
                getQueryParams: () => {
                  return {
                    content_type__app_label: 'contracts',
                    content_type__model: 'contractbase',
                  };
                },
              },
              {
                argument: 'purchase_contract_payment_conditions_option_list',
                placeholder: this.gettext('purchase contract payment condition option'),
                resource: 'finances.paymentcondition',
              },
              {
                argument: 'plan_distributor_list',
                placeholder: this.gettext('plan distributors'),
                resource: 'clients.supplier',
              },
              {
                argument: 'fact_distributor_list',
                placeholder: this.gettext('fact distributors'),
                resource: 'clients.supplier',
              },
              {
                argument: 'purchase_contract_responsible_for_signing_list',
                placeholder: this.gettext('responsible for signing'),
                resource: 'auth.user',
              },
              {
                argument: 'purchase_contract_author_list',
                placeholder: this.gettext('purchase contract author'),
                resource: 'auth.user',
              },
              {
                argument: 'contract_business_unit_list',
                placeholder: this.gettext('contract business unit'),
                resource: 'core.BusinessUnit',
              },
              {
                argument: 'activity_list',
                placeholder: this.gettext('Activity type'),
                resource: 'core.TypeOfActivity',
              },
              {
                argument: 'purchase_contract_multicontract_list',
                placeholder: this.gettext('Multicontract'),
                resource: 'contracts.Multicontract',
              },
            ],
            nestedSelects: [
              this.gtFilterService.getBoolFilter(
                'without_invoice_offset_reassignment',
                this.gettext('without invoice, offset, reassignment'),
              ),
              this.gtFilterService.getBoolFilter('with_invoice', this.gettext('Has invoice')),
              this.gtFilterService.getBoolFilter('has_offset', this.gettext('Has offset')),
              this.gtFilterService.getBoolFilter(
                'with_reassignment',
                this.gettext('Has reassignment'),
              ),
              this.gtFilterService.getBoolFilter('has_docs_copy', this.gettext('Has docs copy')),
              this.gtFilterService.getBoolFilter(
                'has_docs_original',
                this.gettext('Has docs original'),
              ),
              this.gtFilterService.getBoolFilter(
                'is_tax_return_registered',
                this.gettext('tax return registered'),
              ),
              this.gtFilterService.getBoolFilter(
                'is_sap_registered',
                this.gettext('sap registered'),
              ),
              {
                argument: 'reassignment_status',
                placeholder: this.gettext('Reassignment status'),
                items: [
                  { id: 'new', title: this.gettext('new') },
                  { id: 'done', title: this.gettext('done') },
                ],
              },
            ],
            nestedMultiSelects: [
              {
                argument: 'status_list',
                placeholder: this.gettext('Status'),
                items: [
                  { id: 'new', title: this.gettext('new') },
                  { id: 'ready', title: this.gettext('ready') },
                  { id: 'process', title: this.gettext('process') },
                  { id: 'done', title: this.gettext('done') },
                  { id: 'blocked', title: this.gettext('blocked') },
                ],
              },
              {
                argument: 'date_list',
                placeholder: this.gettext('year'),
                items: this.GtUtils.getYearList(),
              },
              {
                argument: 'purchase_contract_status_list',
                placeholder: this.gettext('Contract status'),
                items: [
                  { id: 'washout', title: this.gettext('Washout') },
                  { id: 'new', title: this.gettext('New') },
                  { id: 'delivery_period', title: this.gettext('Delivery period') },
                  { id: 'shipment', title: this.gettext('Shipment') },
                  { id: 'cargo_executed', title: this.gettext('Cargo executed') },
                  { id: 'executed', title: this.gettext('Executed') },
                  { id: 'approved', title: this.gettext('Approved') },
                  { id: 'cancelled', title: this.gettext('Cancelled') },
                ],
              },
            ],
          },
        };
      }
    },
  ],
};
