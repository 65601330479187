import type ng from 'angular';

import template from './reassignment-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const ReassignmentPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'PageService',
    'FinancesService',
    'gtFilterService',
    'GtUtils',
    'gettext',
    class {
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      filterLevel = 'finances-reassignment-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        PageService: PageService,
        FinancesService: FinancesService,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.FinancesService = FinancesService;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-payments-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          filters: {
            filterLevel: this.filterLevel,
            search: true,
            dates: true,
            dateSelects: [
              {
                argument: 'create_time',
                placeholder: this.gettext('Create date'),
              },
              {
                argument: 'date',
                placeholder: this.gettext('Reassignment date'),
              },
            ],
            nestedSelects: [
              this.gtFilterService.getBoolFilter(
                'has_all_dbl_docs_copy',
                this.gettext('Has docs copy'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_all_dbl_docs_original',
                this.gettext('Has docs original'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_unconnected_amount',
                this.gettext('Has balance to connect'),
              ),
            ],
            multiSelects: [
              {
                argument: 'id_list',
                placeholder: this.gettext('reassignment'),
                resource: 'finances.reassignment',
                related_fk: 'id',
                related_model: 'finances.reassignment',
              },
              {
                argument: 'manager_list',
                placeholder: this.gettext('managers'),
                resource: 'auth.User',
                related_fk: 'manager_id',
                related_model: 'finances.reassignment',
              },
              {
                argument: 'business_unit_list',
                placeholder: this.gettext('business unit'),
                resource: 'core.BusinessUnit',
                getQueryParams: this.GtUtils.getBUQueryParams,
                related_fk: 'business_unit_id',
                related_model: 'finances.reassignment',
              },
              {
                argument: 'distributor_list',
                placeholder: this.gettext('distributor'),
                resource: 'clients.ClientRole',
                getQueryParams: () => {
                  return { role_name: 'supplier', is_distributor: '1' };
                },
                related_fk: 'distributor_id',
                related_model: 'finances.reassignment',
              },
              {
                argument: 'contract_sap_orders_list',
                placeholder: this.gettext('sap order'),
                resource: 'contracts.saporder',
                related_fk: 'contract__sap_orders',
                related_model: 'finances.reassignment',
              },
              {
                argument: 'contract_payment_conditions_option_list',
                placeholder: this.gettext('payment condition option'),
                resource: 'finances.paymentcondition',
                related_fk: 'contract__payment_conditions_option_id',
                related_model: 'finances.reassignment',
              },
              {
                argument: 'contract_list',
                placeholder: this.gettext('contract'),
                resource: 'contracts.contractbase',
                related_fk: 'contract_id',
                related_model: 'finances.reassignment',
              },
              {
                argument: 'contract_loyalty_program_list',
                placeholder: this.gettext('loyalty program'),
                resource: 'contracts.contractoption',
                related_fk: 'contract__contract_option_id',
                related_model: 'finances.reassignment',
              },
              {
                argument: 'contract_supplier_list',
                placeholder: this.gettext('contract supplier'),
                resource: 'clients.supplier',
                related_fk: 'contract__supplier_id',
                related_model: 'finances.reassignment',
              },
              {
                argument: 'offset_list',
                placeholder: this.gettext('offset'),
                resource: 'finances.financeoffset',
                related_fk: 'offsetposition__finance_offset_id',
                related_model: 'finances.reassignment',
              },
              {
                argument: 'multicontract_list',
                placeholder: this.gettext('Multicontract'),
                resource: 'contracts.Multicontract',
              },
            ],
          },
          pages: this.FinancesService.getPagesConfig(),
        };
      }
    },
  ],
};
