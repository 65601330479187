import { AuthStore } from '~/core/auth/services';
import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import { container, injectable } from '~/shared/lib/di';
import { lastValueFrom, switchMap, take } from '~/shared/lib/state';

import {
  Request,
  RequestPatch,
  RequestPost,
  RequestQueryParams,
  RequestRecord,
  RequestStage,
  getRequestsContainerFields,
} from '../../lib';
import { RequestsRepository } from '../../services/requests.repository';

export type RequestsListContainerProps = ContainerProps<RequestQueryParams>;

@injectable()
export class RequestsListModel extends EntityListContainerModel<
  Request,
  RequestQueryParams,
  RequestsRepository,
  RequestsListContainerProps,
  RequestRecord,
  RequestPost,
  RequestPatch
> {
  private readonly authStore = container.resolve(AuthStore);

  entityRepoClass = RequestsRepository;

  readonly totals$ = this.pageParams$.pipe(switchMap((params) => this.repo.getListTotals(params)));

  getFields = async () => {
    const queryParams = await lastValueFrom(this.pageParams$.pipe(take(1)));
    const user = await lastValueFrom(this.authStore.currentUser$.pipe(take(1)));
    return getRequestsContainerFields(user, queryParams.stage);
  };
  setStage = (stage: RequestStage) => this.repo.setStage(stage);
  setContractType = (contractType: RequestRecord['contract_type']) =>
    this.repo.setContractType(contractType);

  createRequest = async (request: RequestPost) => await this.repo.create(request);

  updateRequest = async (request: RequestPatch) => await this.repo.update(request);
}
