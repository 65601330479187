import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('FinanceBankModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    '$window',
    'FinancesService',
    'GtUtils',
    'gettext',
    'financeBank',
    'FormFieldParamsService',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $window: ng.IWindowService,
    FinancesService: FinancesService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    financeBank: any,
    FormFieldParamsService: FormFieldParamsService,
  ) {
    const vm = this;

    vm.financeBank = financeBank || {};
    vm.save = save;
    vm.close = close;
    vm.destroy = destroy;
    vm.updateData = updateData;
    vm.form = undefined;

    vm.fields = [];

    activate();

    ////////////////

    function activate() {
      if (vm.financeBank.id) {
        updateData();
      }
      FormFieldParamsService.resolveDefaultValues(getFields()).then(function (fields: any) {
        vm.fields = fields;
      });
    }

    function updateData() {
      return FinancesService.FinanceBank.get({ id: vm.financeBank.id }, function (data: any) {
        vm.financeBank = data;
      }).$promise;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function destroy() {
      const msg = GtUtils.translate(gettext('Are you sure that you want delete this bank?'));
      if (!confirm(msg)) {
        return;
      }
      return FinancesService.FinanceBank.delete({ id: vm.financeBank.id }, function () {
        notify(GtUtils.translate(gettext('Bank removed')));
        close(null, true);
      }).$promise;
    }

    function save() {
      let saveFunction;
      vm.form.$invalid = true;

      if (vm.financeBank.id) {
        saveFunction = FinancesService.FinanceBank.update;
      } else {
        saveFunction = FinancesService.FinanceBank.save;

        if (vm.financeBank.bank_branches_text) {
          vm.financeBank.bank_branches = vm.financeBank.bank_branches_text
            .split(',')
            .map((branch: any) => ({
              name: branch.trim(),
            }));
        } else {
          vm.financeBank.bank_branches = [];
        }
      }

      return saveFunction(
        vm.financeBank,
        function (data: any) {
          close(data, true);
          notify(GtUtils.translate(gettext('Bank saved')));
        },
        (e: any) => GtUtils.errorClb(e),
      ).$promise;
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-md-6 col-sm-12',
        fieldGroup: [],
      };

      const col2: any = {
        className: 'form-group-container col-md-6 col-sm-12',
        fieldGroup: [],
      };

      col1.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('INFO'),
        },
        fieldGroup: [
          {
            key: 'name',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Title'),
              placeholder: gettext('Type name'),
              type: 'text',
              required: true,
            },
          },
          {
            key: 'full_name',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Full name'),
              placeholder: gettext('Type full name'),
              type: 'text',
            },
          },
          {
            key: 'full_name_eng',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Full name (eng)'),
              placeholder: gettext('Type full name (eng)'),
              type: 'text',
            },
          },
          {
            key: 'country',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Country'),
              resource: 'location.Country',
              placeholder: gettext('country'),
              addButtonUrl: '/admin/location/country/add/',
              addPerms: ['add_country'],
              addFunc: () => {
                $window.open('/admin/location/country/add/');
              },
              addIcon: GtUtils.getIcon('location.Country'),
            },
          },
          {
            key: 'bank_branches_text',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Bank branches'),
              placeholder: gettext('Type bank branches separated by comma'),
              type: 'text',
            },
          },
        ],
      });

      col2.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('INVOICES LIST'),
        },
        fieldGroup: [
          {
            key: 'bank_code',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Bank code'),
              placeholder: gettext('Type bank code'),
              type: 'text',
            },
          },
          {
            key: 'swift',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Swift'),
              placeholder: gettext('Type swift'),
              type: 'text',
            },
          },
          {
            key: 'address',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Address'),
              placeholder: gettext('Type address'),
              type: 'text',
            },
          },
          {
            key: 'address_eng',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Address (eng)'),
              placeholder: gettext('Type address (eng)'),
              type: 'text',
            },
          },
        ],
      });

      return [col1, col2];
    }
  }
})();
