import {
  type Warehouse,
  logisticsWarehousesList,
  logisticsWarehousesPredictionsRetrieve,
} from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { WarehouseListParams } from '../types';

export class WarehouseRepository {
  query = async (query: WarehouseListParams): Promise<Paginated<Warehouse>> => {
    const { data } = await logisticsWarehousesList({ query });
    return { records: data.results, count: data.count };
  };
  search = async (input: string): Promise<EntityOption[]> => {
    const { data } = await logisticsWarehousesPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
}
