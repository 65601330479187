import { financesFinanceAccountsPredictionsRetrieve } from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { FinanceAccount, FinanceAccountListParams } from '../lib';

export class FinanceAccountRepository extends EntityRepository<
  FinanceAccount,
  FinanceAccountListParams
> {
  readonly entityName = 'finances.FinanceAccount';

  getFn = (_id: number): Promise<FinanceAccount> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: FinanceAccountListParams): Promise<Paginated<FinanceAccount>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await financesFinanceAccountsPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  createFn = (_entity: FinanceAccount): Promise<FinanceAccount> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<FinanceAccount>): Promise<FinanceAccount> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
