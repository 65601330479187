import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').directive('bankAccountItem', directive);

  function directive() {
    return {
      template: require('./bank-account-item.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        bankAccount: '=',
        updateList: '=?',
      },
    };
  }

  Controller.$inject = ['gtFilterService'];

  function Controller(this: any, gtFilterService: GtFilterService) {
    const vm = this;

    vm.updateList = vm.updateList || gtFilterService.updateQueryParams;
    vm.tab = 'info';

    ////////////////
  }
})();
