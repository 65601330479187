import ng from 'angular';

import type { HelpButtonsService } from '^/app/core/legacy/help-buttons/help-buttons.srv';

(function () {
  'use strict';
  ng.module('core.legacy').directive('importButton', directive);

  function directive() {
    return {
      template: require('./import-button.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {},
    };
  }

  Controller.$inject = ['$scope', 'HelpButtonsService'];

  function Controller(this: any, $scope: ng.IScope, HelpButtonsService: HelpButtonsService) {
    const vm = this;
    vm.link = '';

    activate();

    ////////////////

    function activate() {
      $scope.$on('hb-config-updated', updateData);
      updateData();
    }

    function updateData() {
      if (HelpButtonsService.getConfig().import) {
        vm.link = HelpButtonsService.getConfig().import?.link;
      }
    }
  }
})();
