import type ng from 'angular';

import template from './bank-transaction-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const BankTransactionPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'PageService',
    'FinancesService',
    'gtFilterService',
    'GtUtils',
    'gettext',
    class {
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      filterLevel = 'bank-transaction-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        PageService: PageService,
        FinancesService: FinancesService,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.FinancesService = FinancesService;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-payments-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          filters: {
            filterLevel: this.filterLevel,
            search: true,
            dates: true,
            dateSelects: [
              {
                argument: 'create_time',
                placeholder: this.gettext('Created'),
              },
              {
                argument: 'update_time',
                placeholder: this.gettext('Updated'),
              },
              {
                argument: 'transaction_date',
                placeholder: this.gettext('Transaction'),
              },
            ],
            multiSelects: [
              {
                argument: 'business_unit_list',
                placeholder: this.gettext('business unit'),
                resource: 'core.BusinessUnit',
                related_model: 'finances.BankTransaction',
              },
              {
                argument: 'currency_list',
                placeholder: this.gettext('currency'),
                resource: 'finances.Currency',
                related_model: 'finances.BankTransaction',
              },
              {
                argument: 'passport_list',
                placeholder: this.gettext('passport'),
                resource: 'passports.Passport',
                related_model: 'finances.BankTransaction',
              },
              {
                argument: 'contract_list',
                placeholder: this.gettext('contract'),
                resource: 'contracts.ContractBase',
                related_model: 'finances.BankTransaction',
              },
              {
                argument: 'invoice_list',
                placeholder: this.gettext('invoice'),
                resource: 'finances.Finance',
                related_model: 'finances.BankTransaction',
              },
              {
                argument: 'bank_account_list',
                placeholder: this.gettext('bank account'),
                resource: 'finances.BankAccount',
                related_model: 'finances.BankTransaction',
              },
              {
                argument: 'counterparty_list',
                placeholder: this.gettext('counterparty'),
                resource: 'clients.ClientRole',
                related_model: 'finances.BankTransaction',
              },
              {
                argument: 'related_counterparty_list',
                placeholder: this.gettext('related counterparty'),
                resource: 'clients.ClientRole',
                related_model: 'finances.BankTransaction',
              },
              {
                argument: 'general_expenses_list',
                placeholder: this.gettext('general expenses'),
                resource: 'finances.GeneralExpenses',
                related_model: 'finances.BankTransaction',
              },
            ],
          },
          pages: this.FinancesService.getPagesConfig(),
        };
      }
    },
  ],
};
