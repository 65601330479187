import React from 'react';

import { cn } from '~/shared/lib/utils';

export const Card = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; className?: string }
>(({ children, className, ...props }, ref) => {
  return (
    <div
      className={cn('bg-background-main-primary w-max rounded-md p-3', className)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
});

Card.displayName = 'Card';
