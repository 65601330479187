import type { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import React from 'react';

import { ChevronLeftIcon } from '~/shared/ui/icons';

export const PreviousMonthButton = (
  props: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => {
  return (
    <button {...props}>
      <ChevronLeftIcon size={16} className="text-text-main-secondary" />
    </button>
  );
};
