export class ContractPnlMtmService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getTableData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/contracts/contract-pnl-mtm/list/',
      params: params,
    }).then((response: any) => response.data);
  }
}
ContractPnlMtmService.$inject = ['$http'];
