import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { MailingService } from '^/app/crm/mailing/mailing.service';

(function () {
  'use strict';
  ng.module('company.emailTemplates').component('emailtemplatesPageView', {
    bindings: {
      filterLevel: '<?',
    },
    template: require('./emailtemplates-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['PageService', 'MailingService'];

  function Controller(this: any, PageService: PageService, MailingService: MailingService) {
    const vm = this;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'emailtemplates-page-view';
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      return {
        class: 'page-header-main-crm-tab',
        buttons: [new PageService.buttons.Print(), new PageService.buttons.Refresh(vm.filterLevel)],
        pages: MailingService.getPagesConfig(),
      };
    }
  }
})();
