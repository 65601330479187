import { from, shareReplay, switchMap } from 'rxjs';

import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import {
  ContractSerializer,
  IntermediateContractPatch,
  IntermediateContractPost,
  IntermediateContractRecord,
  IntermediateContractsQueryParams,
} from '~/features/deals/contracts';
import { IntermediateContractsRepository } from '~/features/deals/contracts/intermediate/services/intermediate-contracts.repository';
import { IntermediateContract } from '~/shared/api';
import { injectable } from '~/shared/lib/di';

export type IntermediateContractsListContainerProps =
  ContainerProps<IntermediateContractsQueryParams>;

@injectable()
export class IntermediateContractsListViewModel extends EntityListContainerModel<
  IntermediateContract,
  IntermediateContractsQueryParams,
  IntermediateContractsRepository,
  IntermediateContractsListContainerProps,
  IntermediateContractRecord,
  IntermediateContractPost,
  IntermediateContractPatch
> {
  entityRepoClass = IntermediateContractsRepository;

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.repo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  public tableChanged(table: ContractSerializer) {
    this.repo.setDatasources(table);
  }
}
