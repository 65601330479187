import type { ContractQueryParams } from '~/features/deals/contracts';
import { contractsContractsList, contractsContractsPredictionsRetrieve } from '~/shared/api';
import type { EntityOption } from '~/shared/ui/data-types';

export class ContractsRepository {
  query = async (query: ContractQueryParams) => {
    const { data } = await contractsContractsList({ query });
    return { records: data.results, count: data.count };
  };

  search = async (input: string): Promise<EntityOption[]> => {
    const { data } = await contractsContractsPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };
}
