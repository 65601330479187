import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').controller('PurchasePlanModalController', Controller);

  Controller.$inject = [
    '$window',
    '$uibModalInstance',
    'ContractsService',
    'purchasePlan',
    'GtUtils',
    'gettext',
  ];

  function Controller(
    this: any,
    $window: ng.IWindowService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    ContractsService: ContractsService,
    purchasePlan: any,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.fields = getFields();
    vm.purchasePlan = purchasePlan || {};
    vm.save = save;
    vm.saveAndContinue = saveAndContinue;
    vm.destroy = destroy;
    vm.close = close;
    vm.form = undefined;
    vm.updating = false;
    vm.loadingVolume = false;
    vm.tab = 'edit';
    vm.users = [];
    vm.purchasePlanVolume = [];

    activate();

    ////////////////

    function activate() {
      if (vm.purchasePlan.id) {
        updateData();
      }
    }

    function updateData() {
      vm.updating = true;
      return ContractsService.PurchasePlan.get(
        {
          id: purchasePlan?.id || vm.purchasePlan.id,
          extrafields: ['content_type'],
        },
        function (data: any) {
          vm.purchasePlan = data;
          vm.updating = false;
        },
      ).$promise;
    }

    function destroy() {
      if (!confirm(gettext('Are you sure?'))) {
        return;
      }
      ContractsService.PurchasePlan.delete(
        {
          id: vm.purchasePlan.id,
        },
        function () {
          notify(gettext('Contract removed'));
          close('delete', true);
        },
      );
    }

    function formRequest() {
      vm.purchasePlanVolume = [];
      vm.users.forEach(function (user: any) {
        if (user.is_involved) {
          vm.purchasePlanVolume.push({
            purchase_plan: vm.purchasePlan.id,
            volume_in_percent: user.volume_in_percent || 0,
            user: user.user_id,
          });
        }
      });
    }

    function savePurchasePlanVolume(data: any, saveMessage: any) {
      vm.loadingVolume = true;
      formRequest();
      ContractsService.PurchasePlanVolume.bulkCreateOrUpdate(
        { purchase_plan_id: vm.purchasePlan.id },
        vm.purchasePlanVolume,
        function () {
          close(data, true);
          notify(gettext(saveMessage));
        },
        function (data: any) {
          _error(data);
          vm.form.$invalid = false;
        },
      );
    }

    function saveAndContinurPurchasePlanVolume(data: any, saveMessage: any) {
      vm.loadingVolume = true;
      formRequest();
      ContractsService.PurchasePlanVolume.bulkCreateOrUpdate(
        { purchase_plan_id: vm.purchasePlan.id },
        vm.purchasePlanVolume,
        function () {
          vm.purchasePlan = data;
          notify(gettext(saveMessage));
          vm.form.$invalid = false;
          updateData().then(function () {
            vm.loadingVolume = false;
          });
        },
        function (data: any) {
          _error(data);
          vm.form.$invalid = false;
          updateData().then(function () {
            vm.loadingVolume = false;
          });
        },
      );
    }

    function save() {
      vm.form.$invalid = true;
      const purchasePlan = { ...vm.purchasePlan };
      delete purchasePlan.purchase_plan_volume;
      if (vm.purchasePlan.id) {
        return ContractsService.PurchasePlan.update(
          purchasePlan,
          function (data: any) {
            savePurchasePlanVolume(data, 'Purchase Plan updated');
          },
          _error,
        ).$promise;
      }
      return ContractsService.PurchasePlan.save(
        purchasePlan,
        function (data: any) {
          savePurchasePlanVolume(data, 'Purchase Plan saved');
        },
        _error,
      ).$promise;
    }

    function saveAndContinue() {
      vm.form.$invalid = true;
      const purchasePlan = { ...vm.purchasePlan };
      delete purchasePlan.purchase_plan_volume;
      if (vm.purchasePlan.id) {
        return ContractsService.PurchasePlan.update(
          purchasePlan,
          function (data: any) {
            saveAndContinurPurchasePlanVolume(data, 'Purchase Plan updated');
          },
          _error,
        ).$promise;
      }
      return ContractsService.PurchasePlan.save(
        purchasePlan,
        function (data: any) {
          saveAndContinurPurchasePlanVolume(data, 'Purchase Plan saved');
        },
        _error,
      ).$promise;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
      vm.purchasePlan.errors = data.data;
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-xs-12',
        fieldGroup: [],
      };

      col1.fieldGroup.push({
        wrapper: 'gt-panel',
        fieldGroup: [
          {
            key: 'cargo',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('COMMODITY'),
              placeholder: gettext('Choose commodity'),
              resource: 'crops.Crop',
              required: true,
              addFunc: () => {
                $window.open('/admin/crops/crop/add/');
              },
              addIcon: GtUtils.getIcon('crops.Crop'),
              addPerms: ['add_crop'],
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'volume',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Volume'),
              placeholder: gettext('Type volume'),
              required: true,
              type: 'number',
              addon: gettext('t'),
            },
          },
          {
            key: 'date_of_execution',
            type: 'gt-date-select',
            templateOptions: {
              label: gettext('Delivery (start date)'),
              placeholder: gettext('date'),
              required: true,
              useWatch: true,
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'end_of_execution',
            type: 'gt-date-select',
            templateOptions: {
              label: gettext('Delivery (end date)'),
              placeholder: gettext('date'),
              useWatch: true,
              required: true,
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'kpi_year',
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('For KPI year report'),
            },
          },
          {
            key: 'margin_relative',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: gettext('Margin relative'),
              required: false,
              type: 'number',
              addon: gettext('$'),
            },
          },
          {
            key: 'margin_absolute',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: gettext('Margin absolute'),
              required: false,
              type: 'number',
              addon: gettext('$'),
            },
          },
          {
            key: 'additional_info',
            type: 'gt-textarea',
            templateOptions: {
              label: gettext('Additional Info'),
              placeholder: gettext('Specific information about this object'),
              className: 'additional-info',
            },
          },
        ],
      });

      return [col1];
    }
  }
})();
