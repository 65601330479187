import ng from 'angular';

import type { AdditionalAgreementsService } from '^/app/deals/contracts/additional-agreements.service';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive(
    'contractsContractItemAdditionalAgreementsTab',
    directive,
  );

  function directive() {
    return {
      template: require('./additional-agreements-tab.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        contract: '=',
        multicontract: '<?',
        emitUpdate: '&?',
      },
    };
  }

  Controller.$inject = ['$scope', 'AdditionalAgreementsService', 'ContractsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    AdditionalAgreementsService: AdditionalAgreementsService,
    ContractsService: ContractsService,
  ) {
    const vm = this;

    vm.additionalAgreements = [];
    vm.newAdditionalAgreements = [];
    vm.openModal = openModal;

    vm.$onInit = function () {
      $scope.$on('additional-agreements-update', function () {
        updateData();
      });

      updateData();
    };

    function updateData() {
      return AdditionalAgreementsService.AdditionalAgreement.query(
        {
          contract: vm.contract.id,
        },
        function (data: any) {
          vm.additionalAgreements = data.results;
          vm.additionalAgreementsCount = data.count;
          vm.newAdditionalAgreements = [{ contract: vm.contract.id }];
        },
      ).$promise;
    }

    function openModal(additionalAgreement: any) {
      return ContractsService.additionalAgreementModal(
        additionalAgreement,
        vm.contract,
        vm.multicontract,
      ).then(() => {
        $scope.$emit('additional-agreements-update');
        vm.emitUpdate?.();
      });
    }
  }
})();
