import ng from 'angular';

import { Avatar } from '~/shared/ui/kit/avatar';
import { Banner } from '~/shared/ui/kit/banner';
import { Button } from '~/shared/ui/kit/button';
import { Card } from '~/shared/ui/kit/card';
import { Checkbox } from '~/shared/ui/kit/checkbox';
import { Chip } from '~/shared/ui/kit/chip';
import { Collapse } from '~/shared/ui/kit/collapse';
import { DatePicker } from '~/shared/ui/kit/date-picker';
import { Dialog } from '~/shared/ui/kit/dialog';
import { Input } from '~/shared/ui/kit/input';
import { Link } from '~/shared/ui/kit/link';
import { Skeleton } from '~/shared/ui/kit/skeleton';
import { Switch } from '~/shared/ui/kit/switch';
import { Tag } from '~/shared/ui/kit/tag';
import { Textarea } from '~/shared/ui/kit/textarea';

import { angularize } from '^/shared/utils/react';

const gtUIKit = ng.module('gtUIKit', []);

angularize(Avatar, 'gtuAvatar', gtUIKit, {
  size: '@?',
  src: '@',
  alt: '@',
  fallback: '@?',
});

angularize(Card, 'gtuCard', gtUIKit, {
  children: '@?',
  className: '@?',
});

angularize(Banner, 'gtuBanner', gtUIKit, {
  children: '@?',
  intent: '@?',
});

angularize(Button, 'gtuButton', gtUIKit, { children: '@', variant: '@?' });

angularize(Checkbox, 'gtuCheckbox', gtUIKit, {
  id: '@?',
  label: '@?',
  labelPosition: '@?',
  icon: '@?',
  disabled: '<?',
  size: '@?',
});

angularize(Chip, 'gtuChip', gtUIKit, {
  children: '@?',
  selected: '<?',
  onClick: '&?',
});

angularize(Collapse, 'gtuCollapse', gtUIKit, {
  toggle: '@',
  content: '@',
  open: '<?',
  onOpen: '&?',
});

angularize(DatePicker, 'gtuDatePicker', gtUIKit, {
  mode: '@?',
  selected: '<?',
  onSelect: '&?',
  required: '<?',
});

angularize(Dialog, 'gtuDialog', gtUIKit, {
  open: '<?',
  onOpen: '&?',
  title: '@?',
  children: '@',
  overlayVisible: '<?',
});

angularize(Input, 'gtuInput', gtUIKit, {
  type: '@?',
  disabled: '<?',
  intent: '@?',
  label: '@?',
  fontWeight: '@?',
  textColor: '@?',
  prefix: '@?',
  suffix: '@?',
  helperText: '@?',
  fullWidth: '<?',
  fontSize: '@?',
  placeholder: '@?',
  value: '@?',
  onChange: '&?',
});

angularize(Link, 'gtuLink', gtUIKit, {
  href: '@',
  children: '@',
  disabled: '<?',
});

angularize(Skeleton, 'gtuSkeleton', gtUIKit, {
  height: '@?',
  width: '@?',
});

angularize(Switch, 'gtuSwitch', gtUIKit, {
  section: '@?',
  size: '@?',
  checked: '<?',
  onChange: '&?',
});

angularize(Tag, 'gtuTag', gtUIKit, {
  variant: '@?',
  fontWeight: '@?',
  rounded: '@?',
  disabled: '<?',
  prefix: '<?',
  suffix: '<?',
  children: '@?',
});

angularize(Textarea, 'gtuTextarea', gtUIKit, {
  label: '@',
  helperText: '@',
  fullWidth: '<?',
  resize: '<?',
  intent: '@',
});

export const gtUIKitModule = gtUIKit.name;
