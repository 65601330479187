import type ng from 'angular';

import template from './dbl-invoice-position-list-table-view.html?raw';
import { type InvoicePositionService } from '../../invoice-position/invoice-position.service';

import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { html } from '^/shared/utils';

type TableData = {
  rows: any[];
  count: number;
  total: number;
};

class DBLInvoicePositionListTableViewController implements ng.IController {
  count = 0;
  total = 0;
  queryParams: QueryParams = {
    has_free_dbl_amount: 1,
    page_size: 25,
  };
  filterLevel?: string;
  tableOptions: ReturnType<DBLInvoicePositionListTableViewController['getTableOptions']>;
  tableData?: TableData;
  tableName?: string;
  dblInvoicePositionList?: any[];
  purchaseContract?: number;
  saleContract?: number;
  dataCount?: number;
  useList?: string[];
  disbursementbl?: number;
  onUpdate?: (args: { dblInvoicePositionList: any[] }) => void;

  static readonly $inject = ['$rootScope', 'gettext', 'InvoicePositionService'];

  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly InvoicePositionService: InvoicePositionService,
  ) {
    this.tableOptions = this.getTableOptions();
  }

  $onInit() {
    this.setQueryParams();
    this.tableName = this.tableName ?? 'disburementbl-invoice-position';

    this.$rootScope.$watch(
      () => this.dblInvoicePositionList ?? [],
      (newValue: any[], oldValue: any[]) => {
        if (this.onUpdate) {
          this.onUpdate({ dblInvoicePositionList: newValue });
        }
        newValue.forEach((item) => {
          if (item.id) {
            const changedItem = oldValue.filter((oldItem) => oldItem.id === item.id);
            if (changedItem.length && changedItem[0].invoiceposition !== item.invoiceposition) {
              this.updateItem(item);
            }
          } else {
            this.updateItem(item);
          }
        });
        this.updateData();
      },
      true,
    );
  }

  $onChanges(changes: ng.IOnChangesObject) {
    if (
      [
        changes.dblInvoicePositionList,
        changes.count,
        changes.total,
        changes.purchaseContract,
        changes.saleContract,
        changes.disbursementbl,
      ].some(Boolean)
    ) {
      this.updateData();
    }
  }

  addItem() {
    const newItem = {
      amount: 0,
      _edit: true,
      disbursement_bl: this.disbursementbl,
    };
    this.dblInvoicePositionList?.push(newItem);
  }

  updateData() {
    this.setQueryParams();
    this.tableOptions = this.getTableOptions();
    this.tableData = {
      rows: this.dblInvoicePositionList ?? [],
      count: this.count,
      total: this.total,
    };
  }

  updateItem(item: any) {
    this.InvoicePositionService.InvoicePositionResource.get(
      { id: item.invoiceposition },
      (data: any) => {
        item.invoiceposition_title = data.title;
        item.invoiceposition_free_amount = data.free_amount_for_dbl;
        item.invoiceposition_currency_symbol = data.invoice_currency_symbol;
        item.amount = item._editedManually ? item.amount : data.free_amount_for_dbl;
      },
    );
  }

  setQueryParams() {
    if (this.dblInvoicePositionList) {
      const invoicePositionTotalAmount = this.dblInvoicePositionList
        .filter((position) => position.invoiceposition && position.amount)
        .reduce((acc, position) => {
          if (position.invoiceposition) {
            if (!acc[position.invoiceposition]) {
              acc[position.invoiceposition] = {
                totalAmount: 0,
                freeAmount: 0,
              };
            }
            acc[position.invoiceposition].totalAmount =
              acc[position.invoiceposition].totalAmount + (position.amount ?? 0);
            acc[position.invoiceposition].freeAmount = position.invoiceposition_free_amount ?? 0;
          }
          return acc;
        }, {});

      const excludeInvoicePositions = Object.entries(invoicePositionTotalAmount)
        .filter((item: any) => item[1].totalAmount >= item[1].freeAmount)
        .map((item) => Number(item[0]));

      this.queryParams = {
        contract_list: [this.purchaseContract ?? 0, this.saleContract ?? 0],
        use_list: this.useList,
        disbursement_bl: this.disbursementbl,
        exclude_ids: excludeInvoicePositions,
        has_free_dbl_amount: 1,
        page_size: 25,
      };
    }
  }

  removeItem(item: any) {
    this.dblInvoicePositionList?.splice(this.dblInvoicePositionList.indexOf(item), 1);
  }

  getTableOptions() {
    return {
      tableName: this.tableName,
      tableClass: 'contract-charges-table',
      configurable: false,
      showMore: false,
      changePageSize: false,
      templateArgs: {
        removeItem: (item: any) => this.removeItem(item),
        queryParams: this.queryParams,
        $rootScope: this.$rootScope,
      },
      filterLevel: this.filterLevel,
      tabs: [],
      columnDefs: [
        {
          title: this.gettext('Amount'),
          columnName: 'amount',
          class: 'td-right-align nowrap',
          cellTemplate: html`
            <span class="label-strong" ng-if="!item._edit">
              {[{ item.amount || 0 | number: 3 }]}
              <span class="smaller-label"> {[{ item.invoiceposition_currency_symbol }]} </span>
            </span>
            <span ng-if="item._edit">
              <input
                class="form-control"
                gt-clear-input
                type="number"
                ng-model="item.amount"
                ng-change="item._editedManually = true"
              />
            </span>
          `,
        },
        {
          title: this.gettext('Invoice Position'),
          columnName: 'invoiceposition',
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item._edit ">
              <gt-resource-select
                ng-model="item.invoiceposition"
                placeholder="'invoice position'|translate"
                resource-name="'finances.invoiceposition'"
                allow-clear="true"
                query-params="args.queryParams"
              ></gt-resource-select>
            </span>
            <span ng-if="!item._edit && item.invoiceposition">
              <a ui-sref="gt.page.payment({id: item.invoice_id })" class="btn-link" target="_blank">
                <i class="fa fa-credit-card-alt"></i> {[{ item.invoiceposition_title }]} {[{
                item.invoice_status | translate }]}
              </a>
            </span>
          `,
        },
        {
          title: this.gettext('Actions'),
          columnName: 'actions',
          class: 'td-left-align',
          cellTemplate: html`<div>
              <a
                class="btn btn-xs btn-blue-border"
                ng-hide="item._edit"
                ng-click="item._edit = true;"
              >
                <i class="fa fa-pencil-square"></i>
              </a>
            </div>
            <span ng-if="item._edit">
              <a
                class="btn btn-md btn-success btn_success"
                ng-if="item._edit"
                ng-click="item._edit = false"
              >
                <i class="fa fa-floppy-o"></i>
              </a>
              <a class="btn btn-md" ng-if="item._edit" ng-click="item._edit = false">
                <i class="fa fa-times"></i>
              </a>
              <a
                class="btn btn-md btn-danger btn_danger"
                ng-if="item._edit"
                ng-click="args.removeItem(item.id);item._edit = false;"
              >
                <i class="fa fa-trash"></i>
              </a>
            </span>`,
        },
      ],
    };
  }
}

export const dblInvoicePositionListTableView: ng.IComponentOptions = {
  bindings: {
    dblInvoicePositionList: '<?',
    count: '<',
    total: '<',
    filterLevel: '<',
    purchaseContract: '<?',
    saleContract: '<?',
    disbursementbl: '<?',
    useList: '<',
    onUpdate: '<',
  },
  template: template,
  controller: DBLInvoicePositionListTableViewController,
};
