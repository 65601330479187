import React from 'react';

import { Tag } from '~/shared/ui/kit/tag';

import type { DataTypeProps } from '../../lib';

export const SelectRead: React.FC<DataTypeProps<'select'>> = ({ disabled, value, typeExtra }) => {
  const tagProps =
    value?.tagProps ?? typeExtra?.options?.find((option) => option.value === value?.value);

  return value ? (
    <Tag disabled={disabled} {...tagProps}>
      {value.label}
    </Tag>
  ) : null;
};
