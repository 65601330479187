import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './mailing-logistics-table.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { MailingService } from '^/app/crm/mailing/mailing.service';

export const MailingLogisticsTable = {
  bindings: {
    filterLevel: '<?',
    mailingsFilterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$timeout',
    'gettext',
    'GtUtils',
    'MailingService',
    'gtFilterService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $timeout: ng.ITimeoutService;
      GtUtils: GtUtilsService;
      MailingService: MailingService;
      filterLevel = 'mailing-logistics-table';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      mailingAttachDocs: any;
      mailingContractSide: any;
      mailingExportConfig: any;
      mailingTemplate: any;
      mailingsFilterLevel: string;
      queryParams: QueryParams = {};
      selectedTotals: any;
      tableApi: any;
      tableOptions: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $timeout: ng.ITimeoutService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        MailingService: MailingService,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.MailingService = MailingService;
        this.gtFilterService = gtFilterService;

        this.filterLevel = '';
        this.mailingsFilterLevel = '';

        this.tableOptions = {};
        this.tableApi = {};
        this.mailingContractSide = 'supplier';
        this.mailingTemplate = undefined;
        this.mailingExportConfig = undefined;
        this.mailingAttachDocs = false;
        this.queryParams = {};
        this.selectedTotals = {
          volume_departed: 0,
          volume_departed_consignment: 0,
          volume_received: 0,
          count: 0,
        };
      }

      $onInit() {
        this.tableOptions = this.getTableOptions();
        this.queryParams = this.getDefaultQueryParams();
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.MailingService.getLogisticsTableData(this.queryParams).then((data: any) => {
          this.tableApi.clearRenderedRows();
          this.tableApi.setRowData([]);
          this.$timeout(() => {
            this.tableApi.setRowData(data.results);
            this.tableApi.setRowCount(data.count);
            this.GtUtils.overlay('hide');
          });
          return data;
        });
      }

      applyFilter() {
        this.queryParams.page = undefined;
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      clearFilters() {
        this.queryParams = this.getDefaultQueryParams();
        this.applyFilter();
      }

      getDefaultQueryParams() {
        return {
          start_update_time: '',
          end_update_time: '',
          start_shipment_date: undefined,
          end_shipment_date: undefined,
          start_receiving_date: undefined,
          end_receiving_date: undefined,
          with_mailing: '',
          update_status: '',
          station_list: [],
          supplier_contract_supplier_list: [],
          buyer_contract_buyer_list: [],
          cargo_list: [],
          contract_list: [],
          terminal_list: [],
          page_size: 200,
        };
      }

      getTableOptions() {
        return {
          tableName: 'mailing-logistics-table',
          filterLevel: this.filterLevel,
          selection: true,
          changePageSize: true,
          configurable: true,
          columnDefs: [
            {
              columnName: 'vehicle_number',
              title: this.gettext('vehicle number'),
              cellTemplate:
                '<span ng-if="item.vehicle_number"># {[{ item.vehicle_number }]}</span>',
            },
            {
              columnName: 'consignment_number',
              title: this.gettext('consignment number'),
              cellTemplate:
                '<span ng-if="item.consignment_number"># {[{ item.consignment_number }]}</span>',
            },
            {
              columnName: 'crop_title',
              title: this.gettext('crop'),
              class: 'td-left-align',
            },
            {
              columnName: 'cargo_class',
              title: this.gettext('crop class'),
              class: 'td-left-align',
            },
            {
              columnName: 'loading_date',
              title: this.gettext('loading date'),
              cellTemplate:
                '<span ng-if="item.loading_date"><i class="fa fa-calendar"></i> {[{ item.loading_date | date:\'dd.MM.yy\' }]}</span>',
            },
            {
              columnName: 'volume_departed',
              title: this.gettext('volume departed'),
              cellTemplate:
                '<span ng-if="item.volume_departed">{[{ item.volume_departed | number:3 }]}</span>',
            },
            {
              columnName: 'volume_departed_consignment',
              title: this.gettext('volume departed consignment'),
              cellTemplate:
                '<span ng-if="item.volume_departed_consignment">{[{ item.volume_departed_consignment | number:3 }]}</span>',
            },
            {
              columnName: 'receiving_date',
              title: this.gettext('receiving date'),
              cellTemplate:
                '<span ng-if="item.receiving_date"><i class="fa fa-calendar"></i> {[{ item.receiving_date | date:\'dd.MM.yy\' }]}</span>',
            },
            {
              columnName: 'volume_received',
              title: this.gettext('volume received'),
              cellTemplate:
                '<span ng-if="item.volume_received">{[{ item.volume_received | number:3 }]}</span>',
            },
            {
              columnName: 'buyer_name',
              title: this.gettext('buyer'),
              cellTemplate:
                '<span ng-if="item.buyer_name"><i class="fa fa-bookmark"></i> {[{ item.buyer_name }]}</span>',
            },
            {
              columnName: 'buyer_contract_contract_number',
              title: this.gettext('buyer contract'),
              cellTemplate:
                '<span ng-if="item.buyer_contract_contract_number"># {[{ item.buyer_contract_contract_number }]}</span>',
            },
            {
              columnName: 'exporter_name',
              title: this.gettext('exporter'),
              cellTemplate:
                '<span ng-if="item.exporter_name"><i class="fa fa-shield"></i> {[{ item.exporter_name }]}</span>',
            },
            {
              columnName: 'exporter_contract_number',
              title: this.gettext('exporter contract'),
              cellTemplate:
                '<span ng-if="item.exporter_contract_number"># {[{ item.exporter_contract_number }]}</span>',
            },
            {
              columnName: 'supplier_name',
              title: this.gettext('supplier'),
              cellTemplate:
                '<span ng-if="item.supplier_name"><i class="fa fa-bookmark-o"></i> {[{ item.supplier_name }]}</span>',
            },
            {
              columnName: 'supplier_contract_contract_number',
              title: this.gettext('supplier contract'),
              cellTemplate:
                '<span ng-if="item.supplier_contract_contract_number"># {[{ item.supplier_contract_contract_number }]}</span>',
            },
            {
              columnName: 'station_name',
              title: this.gettext('station departing'),
              cellTemplate:
                '<span ng-if="item.station_name"><i class="fa fa-train"></i> {[{ item.station_name }]}</span>',
            },
            {
              columnName: 'station_receiving_name',
              title: this.gettext('station receiving'),
              cellTemplate:
                '<span ng-if="item.station_receiving_name"><i class="fa fa-train"></i> {[{ item.station_receiving_name }]}</span>',
            },
            {
              columnName: 'port_title',
              title: this.gettext('port'),
              cellTemplate:
                '<span ng-if="item.port_title"><i class="fa fa-anchor"></i> {[{ item.port_title }]}</span>',
            },
          ],
          rowData: [],
          onInit: (api: any) => (this.tableApi = api),
        };
      }

      // eslint-disable-next-line sonarjs/function-return-type
      createMailing() {
        const selectedItems = this.tableApi.getSelectedRowData();

        if (!selectedItems.length) {
          notify(
            this.GtUtils.translate(this.gettext('Please select at least one logistic')),
            'warning',
          );
          return false;
        }

        const msg = this.GtUtils.translate(
          this.gettext(
            `Do you want to create mailing by ${this.mailingContractSide} contracts from \
  selected ${selectedItems.length} logisitc(s)?`,
          ),
        );

        if (!confirm(msg)) {
          return false;
        }
        this.GtUtils.overlay('show');
        return this.MailingService.createMailingsFromLogistics({
          logistics: selectedItems.map((l: any) => l.id),
          side: this.mailingContractSide,
          template_id: this.mailingTemplate,
          collection_id: this.mailingExportConfig,
          docs: this.mailingAttachDocs,
        })
          .then(() => {
            this.updateData();
            this.gtFilterService.updateQueryParams({}, this.mailingsFilterLevel);
          }, this.GtUtils.errorClb)
          .finally(() => {
            this.GtUtils.overlay('hide');
          });
      }

      refreshTotals() {
        this.selectedTotals = {
          volume_departed: 0,
          volume_departed_consignment: 0,
          volume_received: 0,
          count: 0,
        };

        const selectedItems = this.tableApi.getSelectedRowData();
        if (!selectedItems.length) {
          return false;
        }

        selectedItems.forEach((logistic: any) => {
          this.selectedTotals.volume_departed += logistic.volume_departed;
          this.selectedTotals.volume_departed_consignment += logistic.volume_departed_consignment;
          this.selectedTotals.volume_received += logistic.volume_received;
          this.selectedTotals.count += 1;
        });
      }
    },
  ],
};
