import type ng from 'angular';
import type { IFieldArray } from 'AngularFormly';

import template from './multicontract-position-table.html?raw';
import type { MulticontractService } from '../../multicontract.service';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

class MulticontractPositionTableController implements ng.IController {
  positions: any[] = [];
  contract: any = { positions_charge_use_type_list: [] };
  updatePositions: (positions: object) => void;
  updateTotals: (data: object) => void;
  withDerivative = false;
  queryParams: QueryParams = {};
  formNamePrefix?: string;
  businessUnit?: number;
  positionFormName = '';
  updateFieldsTrigger = false;

  static readonly $inject = [
    '$rootScope',
    'MulticontractService',
    'FormFieldParamsService',
    'ContractsService',
    'GtUtils',
  ];

  constructor(
    private $rootScope: GtRootScopeService,
    private MulticontractService: MulticontractService,
    private FormFieldParamsService: FormFieldParamsService,
    private ContractsService: ContractsService,
    private GtUtils: GtUtilsService,
  ) {
    this.updatePositions = (_positions: object) => {
      throw Error('updtePositions callback is required');
    };
    this.updateTotals = (_data: object) => {
      throw new Error('updateTotals callback is required');
    };
  }

  $onChanges(changes: Record<string, ng.IChangesObject<any> | undefined>) {
    if (
      (changes.businessUnit &&
        Boolean(changes.businessUnit.currentValue) &&
        changes.businessUnit.currentValue !== changes.businessUnit.previousValue) ||
      changes.positions
    ) {
      this.updateFields();
    }
    if (
      changes.formNamePrefix &&
      changes.formNamePrefix.currentValue !== changes.formNamePrefix.previousValue &&
      changes.formNamePrefix.currentValue !== 'undefined-'
    ) {
      this.positionFormName = this.formNamePrefix + 'position-edit-form';
    }
  }

  $onInit() {
    this.withDerivative = this.withDerivative || false;
    this.addPosition(true);
  }

  updateFields() {
    this.updateFieldsTrigger = !this.updateFieldsTrigger;
  }

  getPermissionName(params: Record<string, string>, permType: string) {
    return this.MulticontractService.getPermissionName(params, permType);
  }

  getPositionContractNumber(position: any) {
    this.ContractsService.getContractNumber(position, this.contract)
      .then((data) => (position.contract_number = data.contract_number || position.contract_number))
      .catch(this.GtUtils.errorClb);
  }

  calculatePositionsTotals() {
    const notDraftPositions = this.positions.filter((position) => position.status !== 'draft');
    const data = {
      data: {
        positions_volume_sum: notDraftPositions.reduce(
          (totalVolume, position) => totalVolume + (position.volume ?? 0),
          0,
        ),
        positions_final_volume_sum: notDraftPositions.reduce(
          (totalVolume: number, position) => totalVolume + (position.final_volume ?? 0),
          0,
        ),
      },
      final_volume: {
        positions_final_volume_sum: notDraftPositions.reduce(
          (totalVolume, position) => totalVolume + (position.final_volume ?? 0),
          0,
        ),
      },
    };
    this.updateTotals(data);
  }

  addPosition(init: boolean) {
    if (init && this.positions.length) {
      return false;
    }

    this.positions.unshift({
      ...this.contract,
      status: 'new',
      use_type: this.contract.use_type,
      deal_type: this.contract.use_type,
      contract_type: this.contract.contract_type,
      conclusion_date: this.contract.conclusion_date,
      stage: this.contract.stage,
      VAT_value: this.$rootScope.user.settings.VAT_VALUE,
      derivatives: [],
      qualities: [],
      positions_charge_use_type_list: this.contract.positions_charge_use_type_list,
      sequence_number: this.positions.length + 1,
    });

    this.updateFields();
  }

  isDerivativeSelected(position: { derivatives?: boolean[] }) {
    return position.derivatives?.some((derivative) => derivative);
  }

  openFieldsConfigModal() {
    this.FormFieldParamsService.fieldsConfigModal(this.getPositionFormConfig()).then(() =>
      this.updateFields(),
    );
  }

  getPositionFormConfig() {
    return this.MulticontractService.getContractPositionFormConfig(
      this.queryParams,
      this.positionFormName,
    ) as IFieldArray;
  }

  clone(position: any) {
    position.derivatives = position.derivatives ?? [];
    const excludeFields = ['id', 'index', 'contract_number'];
    const cleanObj = (object: any) =>
      Object.keys(object)
        .filter((key) => object[key] && !excludeFields.includes(key))
        .reduce(
          (obj, key) => ({
            ...obj,
            [key]: object[key],
          }),
          {},
        );

    this.positions.unshift({
      ...cleanObj(position),
      derivatives: position.derivatives.map(cleanObj),
      sequence_number: this.positions.length + 1,
      positions_charge_use_type_list: [],
    });
    this.updatePositions({ positions: this.positions });
  }

  destroy(position: any) {
    if (!position.id) {
      return Promise.resolve(this.positions.splice(this.positions.indexOf(position), 1));
    }
    return this.MulticontractService.deletePosition(position).then((data: string) => {
      if (data == 'delete') {
        this.positions.splice(this.positions.indexOf(position), 1);
      }
    });
  }

  setEndOfExecution(position: any, $event: { date?: Date }) {
    const date = $event.date;
    if (position.end_of_execution || !date) {
      return false;
    }
    position.end_of_execution = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      .toISOString()
      .split('T')[0];
  }

  changeIsActive(position: any) {
    if (
      position.status === 'draft' ||
      this.$rootScope.user.settings.ALLOW_SET_MANY_FEW_CONTRACT_PRICE
    ) {
      return;
    }
    const prevStatus = position.status;
    this.positions
      .filter((item) => item.status != 'draft')
      .forEach((item) => (item.status = 'draft'));
    position.status = prevStatus;
  }
}

export const multicontractPositionTable: ng.IComponentOptions = {
  bindings: {
    positions: '<',
    contract: '<',
    updatePositions: '&',
    updateTotals: '&',
    openPositionModal: '&',
    formValidChange: '&',
    withDerivative: '<',
    queryParams: '<',
    formNamePrefix: '<',
    businessUnit: '<',
  },
  template,
  controller: MulticontractPositionTableController,
};
