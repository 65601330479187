import type { Crop } from '~/shared/api';
import { cropsCropsList, cropsCropsPredictionsRetrieve } from '~/shared/api';
import { EntityRepository, type Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Commodity, CommodityQueryParams } from '../lib';

export class CommodityRepository extends EntityRepository<Commodity, CommodityQueryParams> {
  readonly entityName = 'crops.Commodity';

  getFn = (_id: number): Promise<Crop> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: Crop): Promise<Crop> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Crop>): Promise<Crop> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: CommodityQueryParams): Promise<Paginated<Crop>> => {
    const { data } = await cropsCropsList({ query });
    return { records: data.results, count: data.count };
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await cropsCropsPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
}
