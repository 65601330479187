import { notify } from '~/shared/lib/notify';

import template from './contract-pl-control-container.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const ContractPlControlContainer = {
  bindings: {
    contractId: '<',
  },
  template,
  controller: [
    'ContractsService',
    'GtUtils',
    'gettext',
    class {
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      contractId?: number;
      gettext: ng.gettext.gettextFunction;
      marginControls: any;
      marginCount: number;
      constructor(
        ContractsService: ContractsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.marginControls = [];
        this.marginCount = 0;
      }

      $onInit() {
        this.updateData();
      }

      updateData(params?: any) {
        this.GtUtils.overlay('show');
        return this.ContractsService.ContractPlControl.query({
          ...(params?.params || {}),
          contract: this.contractId,
        })
          .$promise.then((data: any) => {
            this.marginControls = data.results;
            this.marginCount = data.count;
          })
          .catch(this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }

      createControl() {
        this.GtUtils.overlay('show');
        return this.ContractsService.Contract.createPnlControl({ id_list: [this.contractId] })
          .$promise.then(() =>
            this.updateData().then(() => notify(this.gettext('Contracts control created'))),
          )
          .catch(this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }
    },
  ],
};
