import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('vesselsPageView', {
    bindings: {
      filterLevel: '<?',
      queryParams: '<?',
    },
    template: require('./vessels-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['PageService', 'LogisticsService'];

  function Controller(this: any, PageService: PageService, LogisticsService: LogisticsService) {
    const vm = this;
    vm.filterLevel = vm.filterLevel || 'vessels-page-view';

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-logistics-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
        ],
        pages: [],
      };
      config.filters = {
        filterLevel: vm.filterLevel,
        clicked: false,
        search: true,
        dates: true,
      };
      config.filters.nestedSelects = [];
      config.filters.multiSelects = [
        {
          argument: 'owner_list',
          placeholder: 'Owner',
          resource: 'clients.other',
          related_fk: 'owner_id',
          related_model: 'logistics.vessel',
        },
        {
          argument: 'managing_company_list',
          placeholder: 'Managing company',
          resource: 'clients.other',
          related_fk: 'managing_company_id',
          related_model: 'logistics.vessel',
        },
      ];
      config.filters.nestedMultiSelects = [];
      config.pages = LogisticsService.getPagesConfig();
      return config;
    }
  }
})();
