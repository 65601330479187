import ng from 'angular';

import { type CoreUtils } from '^/app/core/core.utils';
import { type GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

function Service(
  $rootScope: GtRootScopeService,
  $resource: ng.resource.IResourceService,
  $uibModal: ng.ui.bootstrap.IModalService,
  $q: ng.IQService,
  gettext: ng.gettext.gettextFunction,
  CoreUtils: CoreUtils,
  GtUtils: GtUtilsService,
) {
  const LogisticTariffResource: any = $resource(
    '/api/logistics/logistic-tariffs/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/logistic-tariffs/predictions/',
      },
    },
  );

  const LogisticFreightResource: any = $resource(
    '/api/logistics/logistic-freight/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      updateOrCreate: {
        method: 'POST',
        url: '/api/logistics/logistic-freight/update_or_create/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/logistic-freight/predictions/',
      },
    },
  );

  const LogisticTransshipmentTariffResource: any = $resource(
    '/api/logistics/transshipment-tariffs/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/transshipment-tariffs/predictions/',
      },
    },
  );

  const LogisticResource: any = $resource(
    '/api/logistics/logistics/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false, cancellable: true },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/logistics/predictions/',
      },
      delete: { method: 'DELETE' },
      queryLight: {
        method: 'GET',
        isArray: false,
        cancellable: true,
        url: '/api/logistics/logistics-list/info/',
      },
      listUnconnected: {
        method: 'GET',
        isArray: false,
        cancellable: true,
        url: '/api/logistics/logistics-list/unconnected/',
      },
      group: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/logistics/group/',
      },
      update: { method: 'PATCH' },
      tableTotal: {
        method: 'GET',
        url: '/api/logistics/logistics/table_total/',
        cancellable: true,
      },
      calcAmounts: {
        method: 'POST',
        url: '/api/logistics/logistics/calc_amounts/',
        isArray: true,
        transformResponse: function (data: any) {
          return JSON.parse(data);
        },
      },
      calcPurposeAmounts: {
        method: 'POST',
        url: '/api/logistics/logistics/calc_purpose_amounts/',
      },
      connectContracts: {
        method: 'GET',
        url: '/api/logistics/logistics/connect_contracts/',
      },
      totalsUnconnected: {
        method: 'GET',
        url: '/api/logistics/logistics-totals/unconnected/',
      },
      totalsInfo: {
        method: 'GET',
        url: '/api/logistics/logistics-totals/info/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/logistics/export_column_names/',
      },
      bulkCreateOrUpdate: {
        method: 'POST',
        isArray: false,
        url: '/api/logistics/logistics/bulk_create_or_update/',
      },
      getDblLogisticsList: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/logistics/dbl_logistics_list/',
      },
      generateNumber: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/logistics/generate_vehicle_number/',
      },
      queryPnl: {
        method: 'GET',
        isArray: false,
        cancellable: true,
        url: '/api/logistics/logistics-list/pnl/',
      },
    },
  );

  const LogisticQualityResource: any = $resource(
    '/api/logistics/logistic-qualities/:id/',
    {
      id: '@id',
    },
    {
      get: {
        method: 'GET',
      },
      save: {
        method: 'POST',
      },
      query: {
        method: 'GET',
        isArray: false,
      },
      remove: {
        method: 'DELETE',
      },
      delete: {
        method: 'DELETE',
      },
      update: {
        method: 'PATCH',
      },
      bulkCreateOrUpdate: {
        method: 'POST',
        isArray: false,
        url: '/api/logistics/logistic-qualities/bulk_create_or_update/',
      },
    },
  );

  const VesselResource: any = $resource(
    '/api/logistics/vessels/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/vessels/predictions/',
      },
    },
  );

  const VoyageResource: any = $resource(
    '/api/logistics/voyages/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/voyages/predictions/',
      },
      updateStatus: {
        method: 'POST',
        isArray: false,
        url: '/api/logistics/voyages/update_status/',
      },
      connectToPassport: {
        method: 'POST',
        isArray: false,
        url: '/api/logistics/voyages/connect_to_passport/',
      },
    },
  );

  const DriverResource = $resource(
    '/api/logistics/drivers/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        url: '/api/logistics/drivers/predictions/',
      },
    },
  );

  const VehicleResource = $resource(
    '/api/logistics/vehicles/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        url: '/api/logistics/vehicles/predictions/',
      },
    },
  );

  const PortResource: any = $resource(
    '/api/logistics/ports/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      contracts_info: {
        method: 'GET',
        url: '/api/logistics/ports/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/ports/predictions/',
      },
    },
  );

  const StationResource = $resource(
    '/api/logistics/stations/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      contracts_info: {
        method: 'GET',
        url: '/api/logistics/stations/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/stations/predictions/',
      },
    },
  );

  const TerminalResource = $resource(
    '/api/logistics/terminals/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      contracts_info: {
        method: 'GET',
        url: '/api/logistics/terminals/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/terminals/predictions/',
      },
    },
  );

  const WarehouseLossResource = $resource(
    '/api/logistics/warehouse-losses/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
        cancellable: true,
      },
      update: {
        method: 'PATCH',
      },
      tableTotal: {
        method: 'GET',
        url: '/api/logistics/warehouse-losses/table_total/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/warehouse-losses/export_column_names/',
      },
    },
  );

  const BasisResource: any = $resource(
    '/api/logistics/basises/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/basises/predictions/',
      },
    },
  );

  const DeliveryConditionResource = $resource(
    '/api/logistics/delivery-conditions/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/delivery-conditions/predictions/',
      },
    },
  );

  const AcceptanceCertificateResource = $resource(
    '/api/logistics/acceptance-certificates/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/acceptance-certificates/predictions/',
      },
    },
  );

  const ShipClassificationResource = $resource(
    '/api/logistics/ship-classifications/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/ship-classifications/predictions/',
      },
    },
  );

  const PlacesOfDestinationResource = $resource(
    '/api/logistics/places-of-destination/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/places-of-destination/predictions/',
      },
    },
  );

  const LogisticConsignmentResource: any = $resource(
    '/api/logistics/logistic-consignment/:id/',
    {
      id: '@id',
    },

    {
      query: { method: 'GET', isArray: false },
      bulkCreateOrUpdate: {
        method: 'POST',
        isArray: false,
        url: '/api/logistics/logistic-consignment/bulk_create_or_update/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/logistic-consignment/predictions/',
      },
    },
  );

  const LogisticTypeResource = $resource(
    '/api/logistics/logistic-types/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/logistic-types/predictions/',
      },
    },
  );

  const VesselSizeResource = $resource(
    '/api/logistics/vessel-sizes/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/vessel-sizes/predictions/',
      },
    },
  );

  const VehicleSubtypeResource = $resource(
    '/api/logistics/vehicle-subtypes/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/logistics/vehicle-subtypes/predictions/',
      },
    },
  );

  return {
    LogisticTariff: LogisticTariffResource,
    LogisticFreight: LogisticFreightResource,
    LogisticTransshipmentTariff: LogisticTransshipmentTariffResource,
    Logistic: LogisticResource,
    LogisticQuality: LogisticQualityResource,
    Basis: BasisResource,
    Vessel: VesselResource,
    Voyage: VoyageResource,
    Port: PortResource,
    Station: StationResource,
    Terminal: TerminalResource,
    WarehouseLoss: WarehouseLossResource,
    DeliveryCondition: DeliveryConditionResource,
    vesselModal: vesselModal,
    voyageModal: voyageModal,
    logisticModal: logisticModal,
    logisticsDocumentsModal: logisticsDocumentsModal,
    AcceptanceCertificate: AcceptanceCertificateResource,
    ShipClassification: ShipClassificationResource,
    PlacesOfDestination: PlacesOfDestinationResource,
    LogisticConsignment: LogisticConsignmentResource,
    Driver: DriverResource,
    Vehicle: VehicleResource,

    getPagesConfig: getPagesConfig,
    getTerminals: getTerminals,
    logisticDocumentModal: logisticDocumentModal,
    cloneLogistic: cloneLogistic,
    VesselSize: VesselSizeResource,
    LogisticType: LogisticTypeResource,
    VehicleSubtype: VehicleSubtypeResource,
    getLogistics: getLogistics,
    updateLogistics: updateLogistics,
    bulkDeleteLogistics: bulkDeleteLogistics,
    setSubtabToLocalStorage: setSubtabToLocalStorage,
    getSubtabFromLocalStorage: getSubtabFromLocalStorage,
    getVoyageStatuses: getVoyageStatuses,
  };

  ////////////////

  function vesselModal(vessel?: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./containers/vessel-modal/vessel-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'VesselModalController as vm',
      windowClass: 'modal-template-half-width',
      resolve: {
        vessel: () => {
          return vessel;
        },
      },
    }).result;
  }

  function voyageModal(voyage?: any, extraData?: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./containers/voyage-modal/voyage-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'VoyageModalController as vm',
      windowClass: 'modal-template',
      resolve: {
        voyage: () => voyage,
        extraData: () => extraData,
      },
    }).result;
  }

  function logisticModal(logistic: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./logistic-modal/logistic-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'LogiticsLogisticModalController as vm',
      windowClass: 'modal-template',
      resolve: {
        logistic: () => {
          return { ...logistic };
        },
      },
    }).result;
  }

  function logisticDocumentModal(doc: any, extra: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./logistic-document-modal/logistic-document-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'LogiticsLogisticDocumentModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      resolve: {
        doc: () => {
          return doc;
        },
        extra: () => {
          return extra;
        },
      },
    }).result;
  }

  function logisticsDocumentsModal(queryParams: QueryParams) {
    return $uibModal.open({
      backdrop: 'static',
      appendTo: getModalRoot(),
      template: html`<logistics-documents-modal
        query-params="queryParams"
        modal-instance="$uibModalInstance"
      >
      </logistics-documents-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
          ($scope as any).queryParams = queryParams;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template modal-template-half-width',
      size: 'sm',
    }).result;
  }

  function cloneLogistic(object: any) {
    // FIX: move in view component
    if (!confirm(gettext('Do you want to clone this Logistic?'))) {
      return $q.resolve();
    }
    return LogisticResource.get({
      id: object.id,
      serializer: 'modal',
    }).$promise.then(function (object: any) {
      const newObject = CoreUtils.cleanBeforeClone({ ...object, _clonedFrom: object.id });

      const fieldsToExclude = [
        'qualities',
        'disbursementbl',
        'buyer_nineteen_invoice',
        'buyer_ten_invoice',
        'supplier_nineteen_invoice',
        'supplier_ten_invoice',
        'cost_invoiceposition',
      ];

      fieldsToExclude.forEach((field: string) => {
        delete newObject[field];
      });

      return logisticModal(newObject);
    });
  }

  function _getSystemConfig(configName: string) {
    return $rootScope.user.settings.SYSTEM_BLOCKS[configName];
  }

  function getPagesConfig() {
    const config: any = [
      {
        title: gettext('Transport'),
        permissions: [],
        state: 'logistics.transport',
        icon: 'fa-train',
        counter: 'logistics.Logistic',
        tabs: [
          {
            title: gettext('Registry'),
            state: 'logistics.transport',
            icon: 'fa-train',
          },
        ],
      },
      {
        title: gettext('Tariffs'),
        permissions: [],
        state: 'logistics.tariffs.auto',
        icon: 'fa-compass',
        tabs: [
          {
            title: gettext('Auto'),
            state: 'logistics.tariffs.auto',
            icon: 'fa-truck',
          },
          {
            title: gettext('Elevator'),
            state: 'logistics.tariffs.elevator',
            icon: 'fa-industry',
          },
          {
            title: gettext('Transshipment'),
            state: 'logistics.tariffs.transshipment',
            icon: 'fa-random',
          },
          {
            title: gettext('Terminal Transshipment'),
            state: 'logistics.tariffs.terminalTransshipment',
            icon: 'fa-anchor',
          },
          {
            title: gettext('Railway'),
            state: 'logistics.tariffs.railway',
            icon: 'fa-train',
          },
          {
            title: gettext('Freight'),
            state: 'logistics.tariffs.freight',
            icon: 'fa-anchor',
          },
          {
            title: gettext('Broker'),
            state: 'logistics.tariffs.broker',
            icon: 'fa-briefcase',
          },
          {
            title: gettext('Surveyor'),
            state: 'logistics.tariffs.surveyor',
            icon: 'fa-flask',
          },
        ],
      },
      {
        title: gettext('Voyages'),
        permissions: [],
        state: 'logistics.voyages',
        icon: 'fa-signs-post',
        counter: 'logistics.voyage',
      },
      {
        title: gettext('Vessels'),
        permissions: [],
        state: 'logistics.vessels',
        icon: 'fa-ship',
        counter: 'logistics.vessel',
      },
      {
        title: gettext('Bills of lading'),
        permissions: [],
        state: 'logistics.billoflading',
        icon: 'fa-drivers-license',
        counter: 'logistics.billoflading',
      },
    ];
    if (_getSystemConfig('block_execution_warehouse')) {
      config.push(
        {
          title: gettext('Warehouses list'),
          name: 'warehouses_list',
          state: 'logistics.warehouseList',
          permissions: ['can_view_warehouses_list'],
          icon: 'fa-warehouse',
        },
        {
          title: gettext('Stock Reserve Units'),
          name: 'stock_reserve_units_list',
          state: 'logistics.stockReserveUnits',
          permissions: ['can_view_stock_reserve_units_list'],
          icon: 'fa-lock',
        },
        {
          title: gettext('Reservations'),
          name: 'reservations_list',
          state: 'logistics.reservationList',
          permissions: ['can_view_reservations_list'],
          icon: 'fa-arrow-down-up-lock',
        },
        {
          title: gettext('Warehouse'),
          name: 'warehouse_rests',
          state: 'logistics.warehouse',
          icon: 'fa-warehouse',
          permissions: ['can_view_warehouse_rests'],
        },
        {
          title: gettext('Warehouse-Rest'),
          name: 'warehouse_rests_with_amounts',
          state: 'logistics.warehouseRests',
          icon: 'fa-cart-arrow-down',
          permissions: ['can_view_warehouse_rests_with_amounts'],
        },
        {
          title: gettext('Warehouses balance'),
          name: 'warehouse_balance',
          state: 'logistics.warehousesBalance',
          icon: 'fa-pie-chart',
          permissions: ['can_view_warehouse_balance'],
        },
        {
          title: gettext('Shipment'),
          name: 'warehouse_shipment',
          permissions: ['can_view_warehouse_shipment'],
          state: 'logistics.warehouseShipment',
          icon: 'fa-cart-arrow-down',
        },
        {
          title: gettext('Trade'),
          name: 'warehouse_trade',
          state: 'logistics.warehousetrade',
          icon: 'fa-area-chart',
          permissions: ['can_view_warehouse_trade'],
        },
        {
          title: gettext('Losses'),
          name: 'warehouse_losses',
          state: 'logistics.warehouseLosses',
          permissions: ['can_view_warehouse_losses'],
          icon: 'fa-times',
        },
      );
    }
    config.push(
      {
        title: gettext('Consignments'),
        state: 'logistics.consignments',
        icon: 'fa-file',
      },
      {
        title: gettext('Transportation Storage Expenses'),
        state: 'logistics.transportationStorageExpenses',
        icon: 'fa-money',
      },
    );
    if (_getSystemConfig('block_execution_shiplans')) {
      config.push({
        title: gettext('Shipment Plan'),
        state: 'logistics.planningShipmentSchedule',
        icon: 'fa-calendar-check-o',
        tabs: [
          {
            title: gettext('Shipment Plan'),
            state: 'logistics.planningShipmentSchedule',
            icon: 'fa-calendar-check-o',
          },
          {
            title: gettext('Shipment Plan by Passport'),
            state: 'logistics.planningPassportConnectionShipmentPlan',
            icon: 'fa-calendar-check',
            permissions: ['view_passportconnectionshipmentplan'],
          },
        ],
      });
    }

    if (_getSystemConfig('block_execution_transfer')) {
      config[0].tabs.push({
        title: gettext('Transload'),
        state: 'logistics.transfer',
        icon: 'fa-random',
      });
    }

    if (_getSystemConfig('block_execution_unconnected')) {
      config[0].tabs.push({
        title: gettext('Unconnected'),
        state: 'logistics.problems',
        icon: 'fa-chain-broken',
      });
    }

    if (_getSystemConfig('block_execution_disbursment')) {
      config.push({
        title: gettext('Disbursement bill of lading'),
        state: 'logistics.disbursementbls',
        icon: 'fa-th-large',
        permissions: ['view_disbursementbl'],
      });
    }

    if (_getSystemConfig('block_execution_sample_control')) {
      config.push({
        title: gettext('Sample Control'),
        state: 'logistics.sampleControl',
        icon: 'fa-flask',
      });
    }

    if (_getSystemConfig('block_execution_ccd')) {
      config.push({
        title: gettext('Customs declaration'),
        state: 'logistics.customsCargoDeclaration',
        icon: 'fa-file-invoice-dollar',
      });
    }

    return config;
  }

  function getTerminals(params: object) {
    return TerminalResource.query(params).$promise;
  }

  function getLogistics(params: object) {
    return LogisticResource.query(params).$promise;
  }

  function updateLogistics(logistics: any) {
    return LogisticResource.bulkCreateOrUpdate(logistics).$promise;
  }

  function bulkDeleteLogistics(logisticIds: any) {
    const deletedIds: any = [];

    const deleteOperations = logisticIds.map((logisticId: number) =>
      LogisticResource.delete({ id: logisticId })
        .$promise.then(() => deletedIds.push(logisticId))
        .catch(GtUtils.errorClb),
    );

    return Promise.allSettled(deleteOperations).then(() => deletedIds);
  }

  function setSubtabToLocalStorage(subtab: string) {
    localStorage.setItem('selected-execution-subtub', subtab);
  }

  function getSubtabFromLocalStorage() {
    return localStorage.getItem('selected-execution-subtub');
  }

  function getVoyageStatuses() {
    return [
      { id: 'New', title: gettext('New') },
      { id: 'pol_under_way', title: gettext('POL under way') },
      { id: 'at_loading_port', title: gettext('At loading port') },
      { id: 'Loading', title: gettext('Loading') },
      { id: 'Loaded', title: gettext('Loaded') },
      { id: 'pod_under_way', title: gettext('POD under way') },
      { id: 'at_discharging_port', title: gettext('At discharging port') },
      { id: 'Discharging', title: gettext('Discharging') },
      { id: 'Discharged', title: gettext('Discharged') },
      { id: 'Executed', title: gettext('Executed') },
      { id: 'Washout', title: gettext('Washout') },
      { id: 'Cancelled', title: gettext('Cancelled') },
    ];
  }
}

(function () {
  'use strict';
  ng.module('execution.legacy').factory('LogisticsService', Service);

  Service.$inject = [
    '$rootScope',
    '$resource',
    '$uibModal',
    '$q',
    'gettext',
    'CoreUtils',
    'GtUtils',
  ];
})();

export type LogisticsService = ReturnType<typeof Service>;
