import template from './notification.html?raw';

import type { GtRootScopeService } from '^/app/core/types';
import type { NotificationService } from '^/app/crm/notifications/notifications.service';

export const notification = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$interval',
    'NotificationService',
    class {
      $interval: any;
      $rootScope: GtRootScopeService;
      NotificationService: NotificationService;
      messagesCount = 0;
      constructor(
        $rootScope: GtRootScopeService,
        $interval: any,
        NotificationService: NotificationService,
      ) {
        this.$rootScope = $rootScope;
        this.$interval = $interval;
        this.NotificationService = NotificationService;
      }

      $onInit() {
        this.updateData();
      }

      updateData() {
        return this.NotificationService.getUnreadCount().then((data: any) => {
          this.messagesCount = data.count;
        });
      }
    },
  ],
};
