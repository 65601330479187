import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '~/shared/lib/utils';

const buttonVariants = cva(
  'flex cursor-pointer items-center justify-center px-2 text-xs transition duration-300 ease-in-out disabled:cursor-not-allowed disabled:opacity-20',
  {
    variants: {
      variant: {
        primary:
          'bg-background-section-primary text-text-main-on-primary-section ring-background-section-light hover:bg-background-section-secondary active:bg-background-section-primaryHover disabled:hover:bg-background-section-primary border border-transparent focus:ring',
        secondary:
          'border-stroke-section-primary text-text-section-primary ring-background-section-light hover:bg-background-section-extraLight active:bg-background-section-medium border focus:ring disabled:hover:bg-transparent',
        tertiary:
          'text-text-section-primary hover:bg-background-section-light focus:border-stroke-section-primary active:bg-background-section-medium border border-transparent focus:ring disabled:hover:bg-transparent',
        active:
          'border-stroke-section-light bg-background-section-extraLight text-text-section-secondary hover:bg-background-section-light border focus:ring disabled:hover:bg-transparent',
        fadedPrimary:
          'active:bg-background-main-quartenary border-stroke-main-light bg-background-main-secondary hover:bg-background-main-tertiary focus:border-stroke-additional-info focus:ring-stroke-additional-infoLight border focus:ring',
        fadedSecondary:
          'border-stroke-main-medium text-text-main-secondary hover:bg-transparent-light focus:border-stroke-additional-info focus:bg-transparent-primary focus:ring-stroke-additional-infoLight active:bg-transparent-medium border focus:ring',
        fadedTertiary:
          'text-text-main-secondary hover:bg-transparent-light focus:border-stroke-additional-info focus:ring-stroke-additional-infoLight active:bg-transparent-medium border border-transparent hover:border-transparent focus:ring',
        danger:
          'border-stroke-additional-dangerLight text-text-additional-danger hover:bg-transparent-light focus:border-stroke-additional-danger focus:bg-transparent-primary focus:ring-stroke-additional-dangerLight active:bg-transparent-medium border focus:ring',
        default:
          'border-transparent-strong bg-background-main-invertedPrimary text-text-main-inverted hover:bg-background-main-invertedSecondary border',
      },
      size: {
        sm: 'h-[24px]',
        md: 'h-[32px]',
        lg: 'h-[40px]',
      },
      rounded: {
        full: 'rounded-sm',
        left: 'rounded-l-sm border-r-0',
        right: 'rounded-r-sm',
      },
      fontWeight: {
        normal: 'font-normal',
        medium: 'font-medium',
        semibold: 'font-semibold',
        bold: 'font-bold',
      },
    },
    defaultVariants: {
      variant: 'fadedPrimary',
      size: 'sm',
      rounded: 'full',
      fontWeight: 'medium',
    },
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
export type ButtonProps = {
  asChild?: boolean;
  children: React.ReactNode;
  fullWidth?: boolean;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> &
  ButtonVariants;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, size, fontWeight, fullWidth, rounded, children, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          fullWidth ? 'w-full' : 'w-max',
          buttonVariants({ variant, size, rounded, fontWeight }),
        )}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);

Button.displayName = 'Button';
