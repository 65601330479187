import type ng from 'angular';

import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export class PaymentPlanFinancesService {
  FinancesService: FinancesService;
  PaymentPlanFinanceResource: any;
  constructor($resource: ng.resource.IResourceService, FinancesService: any) {
    this.PaymentPlanFinanceResource = $resource(
      '/api/finances/payment-plan-finances/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/payment-plan-finances/bulk_create_or_update/',
        },
      },
    );
    this.FinancesService = FinancesService;
  }

  getPaymentPlanFinances(params: object) {
    return this.PaymentPlanFinanceResource.query(params).$promise;
  }

  bulkCreateOrUpdate(PaymentPlanFinances: any) {
    return this.PaymentPlanFinanceResource.bulkCreateOrUpdate(PaymentPlanFinances).$promise;
  }

  deletePaymentPlanFinance(params: object) {
    return this.PaymentPlanFinanceResource.delete(params).$promise;
  }

  matchFinances(paymentPlanFinanceRow: any) {
    return this.FinancesService.Finance.get({ id: paymentPlanFinanceRow.finance }).$promise.then(
      (invoiceData: any) => {
        Object.keys(paymentPlanFinanceRow)
          .filter((k) => Object.keys(invoiceData).filter((kk) => kk == k).length)
          .forEach((key) => (paymentPlanFinanceRow[key] = invoiceData[key]));
        paymentPlanFinanceRow.finance_number = invoiceData.number;
        paymentPlanFinanceRow.payment_plan = null;
        paymentPlanFinanceRow.id = null;
        paymentPlanFinanceRow.toPay = invoiceData.to_pay;
        paymentPlanFinanceRow.currency_symbol = invoiceData.currency_symbol;
        this.FinancesService.InvoicePosition.get({
          invoice: paymentPlanFinanceRow.finance,
        }).$promise.then((positions: any) => {
          paymentPlanFinanceRow.invoicepositions = positions.results;
        });
      },
    );
  }

  getFinancesTotals(idList: any) {
    return this.FinancesService.Finance.query({ id_list: idList }).$promise.then((response: any) =>
      response.results
        .map(
          ({
            currency_symbol: currency,
            invoice_type: type,
            clientrole_to_name: clientTo,
            clientrole_from_name: clientFrom,
            amount,
          }: any) => ({
            currency,
            amount,
            type,
            client: type === 'outgoing' ? clientTo : clientFrom,
          }),
        )
        .reduce((acc: any, cur: any) => {
          const rows = acc.filter(
            (item: any) => item.client === cur.client && item.currency === cur.currency,
          );
          if (!rows.length) {
            return [...acc, cur];
          }
          rows[0].amount += cur.amount;
          return acc;
        }, []),
    );
  }

  getPaymentsByFinance(financeId: number) {
    return this.PaymentPlanFinanceResource.query({
      page_size: 10000,
      finance: financeId,
    }).$promise;
  }

  calculatePaymentPlanFinancesTotals(finances: any) {
    return finances
      ?.map((item: any) => ({
        currency: item.currency_symbol,
        value: item.value,
        client: item.clientrole_from_name,
      }))
      .reduce((acc: any, cur: any) => {
        const totalIndex = acc.findIndex(
          (item: any) => item.client === cur.client && item.currency === cur.currency,
        );

        if (totalIndex > -1) {
          acc[totalIndex].value += cur.value;
        } else {
          const { client, currency, value } = cur;
          acc = [...acc, { client, currency, value }];
        }

        return acc;
      }, []);
  }
}
PaymentPlanFinancesService.$inject = ['$resource', 'FinancesService'];
