import type ng from 'angular';

import type { FinancesService } from '../../legacy/finances.srv';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export class InvoiceFormFieldsService {
  $rootScope: GtRootScopeService;
  $window: ng.IWindowService;
  AccountsService: AccountsService;
  ClientsService: any;
  CoreService: CoreService;
  CustomValuesService: CustomValuesService;
  FinancesService: FinancesService;
  FormFieldParamsService: FormFieldParamsService;
  GtUtils: GtUtilsService;
  changeSides: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $rootScope: GtRootScopeService,
    $window: ng.IWindowService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    CoreService: CoreService,
    ClientsService: any,
    AccountsService: AccountsService,
    FinancesService: FinancesService,
    FormFieldParamsService: FormFieldParamsService,
    CustomValuesService: CustomValuesService,
  ) {
    this.$rootScope = $rootScope;
    this.$window = $window;
    this.gettext = gettext;
    this.GtUtils = GtUtils;
    this.CoreService = CoreService;
    this.ClientsService = ClientsService;
    this.AccountsService = AccountsService;
    this.FinancesService = FinancesService;
    this.FormFieldParamsService = FormFieldParamsService;
    this.CustomValuesService = CustomValuesService;
  }

  getFormName(invoice: any) {
    let formName = 'finance-edit-modal';
    if (invoice.assignment == 'reserve') {
      formName = `reserve-${formName}`;
    }
    return formName;
  }

  getFields(finance: any, positions: any, formName: string) {
    const col1: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col2: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col3: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col4: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    let isExchangeFieldRequired: any;
    this.FormFieldParamsService.isRequiredCurrencyExchangeField(
      this.getFormName(finance),
      finance.business_unit,
    ).then((required: any) => (isExchangeFieldRequired = required));

    // filter statuses
    const financeStatuses: any = [{ name: this.gettext('New'), value: 'new' }];
    if (finance.status === 'new' || finance.status === 'canceled') {
      financeStatuses.push({ name: this.gettext('Canceled'), value: 'canceled' });
    } else {
      financeStatuses.push(
        { name: this.gettext('Process'), value: 'process' },
        { name: this.gettext('Paid'), value: 'paid' },
      );
    }

    col1.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('MAIN'),
      },
      fieldGroup: [
        {
          key: 'number',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Invoice number #'),
            placeholder: this.gettext('Type invoice number'),
            addon: this.gettext('#'),
            required: true,
          },
          validation: { show: true },
          expressionProperties: {
            'templateOptions.help': (viewValue: any, modelValue: any, scope: any) => {
              if (!scope.model.id && scope.model.number) {
                this.FinancesService.isInvoiceUnique({ ...scope.model }).then(
                  (result: any) =>
                    (scope.to.help = result
                      ? ''
                      : 'Finance with this number and counterparties already exists'),
                );
              }
            },
          },
        },
        {
          key: 'auto_name',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Generate'),
            hint: this.gettext('Check this if you want finance number to be auto generated'),
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.id',
          },
        },
        {
          key: 'invoice_type',
          type: 'gt-select',
          defaultValue: 'incoming',
          templateOptions: {
            onSelected: () => {
              if (!confirm(this.gettext('Do you want replace sides in invoice?'))) {
                return;
              } else {
                [finance.clientrole_from, finance.clientrole_to] = [
                  finance.clientrole_to,
                  finance.clientrole_from,
                ];
                this.changeSides = true;
              }
            },

            label: this.gettext('Type'),
            placeholder: this.gettext('Choose type'),
            help: this.gettext(
              'Incoming - invoice is received from counterparty, Outgoing - invoice is issued by us',
            ),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Incoming'), value: 'incoming' },
              { name: this.gettext('Outgoing'), value: 'outgoing' },
            ],
          },
        },
        {
          key: 'assignment',
          type: 'gt-select',
          defaultValue: 'commercial',
          templateOptions: {
            label: this.gettext('assignment'),
            placeholder: this.gettext('Choose assignment'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { value: 'commercial', name: this.gettext('Commercial Invoice') },
              { value: 'proforma', name: this.gettext('Proforma Invoice') },
              { value: 'debit', name: this.gettext('Debit Note') },
              { value: 'credit', name: this.gettext('Credit Note') },
              { value: 'reserve', name: this.gettext('Reserve') },
            ],
          },
        },
        {
          key: 'condition',
          type: 'gt-select',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.invoice_condition || 'prepay',
          templateOptions: {
            label: this.gettext('Condition'),
            placeholder: this.gettext('Choose Condition'),
            hint: this.gettext(
              'Prepay - amount of money, will be paid by contract, before final delivery, ' +
                ' Balance of money, will be paid after end of delivery',
            ),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Prepay'), value: 'prepay' },
              { name: this.gettext('Balance'), value: 'balance' },
            ],
          },
        },

        {
          key: 'packing_list',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Packing List'),
            placeholder: this.gettext('Packing List'),
          },
        },
        {
          key: 'related_invoice',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Related invoice'),
            placeholder: this.gettext('Related invoice number'),
            resource: 'finances.Finance',
            queryParams: () => {
              return {
                contract_list: positions
                  .filter((position: any) => position.contract)
                  .map((position: any) => position.contract),
              };
            },
          },
        },

        {
          key: 'status',
          type: 'gt-select',
          defaultValue: 'new',
          templateOptions: {
            label: this.gettext('Status'),
            placeholder: this.gettext('Choose status'),
            help: this.gettext(
              'Will be change automatically, based on payment. New - no payment yet, Process - paid not in full, Paid - paid in full',
            ),
            disabled: finance.status !== 'new' && finance.status !== 'canceled',
            valueProp: 'value',
            labelProp: 'name',
            options: financeStatuses,
          },
        },
        {
          key: 'business_unit',
          type: 'gt-ui-select',
          defaultValueResolve: () => this.CoreService.getDefaultBuId(finance),
          templateOptions: {
            label: this.gettext('Business unit'),
            placeholder: this.gettext('Business Unit'),
            queryParams: this.GtUtils.getBUQueryParams(),
            resource: 'core.BusinessUnit',
            hint: this.gettext(
              'If you pick something here, information about this object will be displayed only for people from those business units',
            ),
            addFunc: () => {
              this.$window.open('/admin/core/businessunit/add/');
            },
            addIcon: 'fa fa-home',
            addPerms: true,
          },
        },
        {
          key: 'season',
          type: 'gt-ui-select',
          defaultValueResolve: () => this.CoreService.getDefaultSeasonId(),
          templateOptions: {
            label: this.gettext('Season'),
            placeholder: this.gettext('Season'),
            resource: 'core.Season',
            hint: this.gettext('For purposes of filtering information by specific season'),
            addFunc: () => {
              this.$window.open('/admin/core/season/add/');
            },
            addIcon: 'fa fa-calendar',
            addPerms: true,
            queryParams: () => {
              return { is_current: 1 };
            },
          },
        },
        {
          key: 'approval_config',
          type: 'gt-ui-select',
          defaultValueResolve: () =>
            this.AccountsService.getDefaultApprovalConfigId('finances.Finance'),
          templateOptions: {
            label: this.gettext('Approval Config'),
            resource: 'accounts.ApprovalConfig',
            queryParams: () => {
              return {
                object_type: 'finances.Finance',
                is_active: 1,
                bu_list: finance.business_unit,
              };
            },
            hint: this.gettext(
              'Choose configuration to launch the approval process by this object. You need first to set up configuration an Admin section, and here just pick it up',
            ),
            addFunc: () => {
              this.$window.open('/admin/accounts/approvalconfig/add');
            },
            addIcon: 'fa fa-cogs',
            addPerms: true,
          },
        },
        {
          key: 'finance_account',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Finance Account'),
            resource: 'finances.FinanceAccount',
            hint: this.gettext('Fill it to display Invoice in Budget report'),
            addFunc: () => {
              this.$window.open('/admin/finances/financeaccount/add');
            },
            addIcon: 'fa fa-dollar',
            addPerms: true,
          },
          hideExpression: "!$root.user.settings['USE_FINANCE_ACCOUNTING']",
        },
        {
          key: 'secret_invoice',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Secret invoice'),
            addPerms: ['secret_invoice'],
            help: this.gettext(
              'Only users with permission secret_invoice will be able see this invoice',
            ),
          },
          hideExpression: () => !this.AccountsService.hasPerm('secret_invoice'),
        },
        {
          key: 'is_volume_in_kg',
          type: 'gt-checkbox',
          defaultValue: false,
          templateOptions: {
            label: this.gettext('Volume in kg'),
          },
        },
        {
          key: 'credit_insurer',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Credit insurer'),
            placeholder: this.gettext('Choose credit insurer'),
            resource: 'clients.ClientRole',
            help: this.gettext('Who issued invoice'),
          },
        },
        {
          template: html`
            <custom-values-container
              filter-level="'invoice-custom-values-container'"
              init-query-params="{
            object_id: model.id,
            purpose: model.content_type,
            purpose_model: 'finance',
          }"
              mode="model.id ? 'edit' : 'create'"
              object-id="model.id"
            ></custom-values-container>
          `,
        },
      ],
    });
    col2.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('SIDES'),
      },
      fieldGroup: [
        {
          key: 'dont_validate_counterparties',
          type: 'gt-checkbox',
          defaultValue: this.$rootScope.user.settings.DONT_VALIDATE_COUNTERPARTIES,
          templateOptions: {
            label: this.gettext('Do not validate'),
            help: this.gettext(
              'Check this if the counterparty in invoice is different than the one in the contract',
            ),
          },
        },
        {
          key: 'clientrole_from',
          type: 'gt-ui-select',
          templateOptions: {
            required: true,
            label: this.gettext('Counterparty from'),
            placeholder: this.gettext('Choose counterparty'),
            addPerms: ['add_client'],
            addIcon: this.GtUtils.getIcon('clients.Client'),
            addFunc: () => this.ClientsService.clientModal(),
            resource: 'clients.ClientRole',
            queryParams: () => {
              return { roles_list: finance.clientrole_from };
            },
            help: this.gettext('Who issued invoice'),
          },
          controller: [
            '$scope',
            'FinancesService',
            function ($scope: ng.IScope, FinancesService: any) {
              $scope.$watchCollection('model.clientrole_from', (newVal: any, oldVal: any) => {
                if (newVal !== oldVal) {
                  if (finance.invoice_type == 'outgoing') {
                    FinancesService.getDefaultBankAccount(finance).then(
                      (data: any) => (finance.bank_account = data),
                    );
                  } else {
                    FinancesService.getDefaultCounterpatyPaymentInfo(finance).then(
                      (data: any) => (finance.counterpaty_payment_info = data),
                    );
                  }
                }
              });
            },
          ],
          expressionProperties: {
            'templateOptions.disabled': () => {
              return (
                positions.some((position: any) => position.use == 'cargo') &&
                !finance.dont_validate_counterparties
              );
            },
          },
        },
        {
          key: 'clientrole_to',
          type: 'gt-ui-select',
          templateOptions: {
            required: true,
            label: this.gettext('Counterparty to'),
            placeholder: this.gettext('Choose counterparty'),
            addPerms: ['add_client'],
            addIcon: this.GtUtils.getIcon('clients.Client'),
            addFunc: () => this.ClientsService.clientModal(),
            resource: 'clients.ClientRole',
            queryParams: () => {
              return { roles_list: finance.clientrole_to };
            },
            help: this.gettext('Who receives invoice'),
          },
          controller: [
            '$scope',
            'FinancesService',
            function ($scope: ng.IScope, FinancesService: any) {
              $scope.$watchCollection('model.clientrole_to', (newVal: any, oldVal: any) => {
                if (newVal !== oldVal) {
                  if (finance.invoice_type === 'outgoing') {
                    FinancesService.getDefaultCounterpatyPaymentInfo(finance).then(
                      (data: any) => (finance.counterpaty_payment_info = data),
                    );
                  } else {
                    FinancesService.getDefaultBankAccount(finance).then(
                      (data: any) => (finance.bank_account = data),
                    );
                  }
                }
              });
            },
          ],
          expressionProperties: {
            'templateOptions.disabled': () => {
              return (
                positions.some((position: any) => position.use == 'cargo') &&
                !finance.dont_validate_counterparties
              );
            },
          },
        },
        {
          template: html`
            <div style="margin: 8px 0">
              <cp-status-display
                status="to.clientroleToStatus"
              </cp-status-display>
            </div>
          `,
          hideExpression: () => !finance.clientrole_to || finance.invoice_type == 'incoming',
          expressionProperties: {
            'templateOptions.clientroleToStatus': (viewValue: any, modelValue: any, scope: any) => {
              return scope.model.clientroleToStatus;
            },
          },
          defaultValue: finance.clientrole_to
            ? this.ClientsService.ClientRole.get({ id: finance.clientrole_to }).$promise.then(
                (clientroleToDetails: any) => {
                  finance.clientroleToStatus = clientroleToDetails.approval_status;
                  return clientroleToDetails;
                },
              )
            : null,
        },
        {
          template: html`
            <div style="margin: 8px 0">
              <cp-status-display
                status="to.clientroleFromStatus"
              </cp-status-display>
            </div>
          `,
          hideExpression: () => !finance.clientrole_from || finance.invoice_type == 'outgoing',
          expressionProperties: {
            'templateOptions.clientroleFromStatus': (
              viewValue: any,
              modelValue: any,
              scope: any,
            ) => {
              return scope.model.clientroleFromStatus;
            },
          },
          defaultValue: finance.clientrole_from
            ? this.ClientsService.ClientRole.get({ id: finance.clientrole_from }).$promise.then(
                (clientroleFromDetails: any) => {
                  finance.clientroleFromStatus = clientroleFromDetails.approval_status;
                  return clientroleFromDetails;
                },
              )
            : null,
        },
        {
          key: 'bank_account',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Bank Account'),
            resource: 'finances.BankAccount',
            help: this.gettext('This is bank details of our company'),
            addFunc: () => {
              return this.FinancesService.paymentInfoModal();
            },
            addIcon: 'fa fa-university',
            addPerms: true,
            queryParams: () => {
              if (finance.invoice_type == 'incoming') {
                return {
                  client_role: finance.clientrole_to,
                  currency: finance.currency,
                  status: 'valid',
                };
              } else {
                return {
                  client_role: finance.clientrole_from,
                  currency: finance.currency,
                  status: 'valid',
                };
              }
            },
          },
          controller: [
            'FinancesService',
            function (FinancesService: any) {
              if (!finance.id && !finance.bank_account) {
                FinancesService.getDefaultBankAccount(finance).then(
                  (data: any) => (finance.bank_account = data),
                );
              }
            },
          ],
        },

        {
          key: 'counterpaty_payment_info',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Counterparty bank account'),
            addFunc: () => {
              return this.FinancesService.paymentInfoModal();
            },
            addIcon: 'fa fa-university',
            addPerms: true,
            queryParams: () => {
              if (finance.invoice_type == 'incoming') {
                return {
                  client_role: finance.clientrole_from,
                  currency_nullable: finance.currency,
                  status: 'valid',
                };
              } else {
                return {
                  client_role: finance.clientrole_to,
                  currency_nullable: finance.currency,
                  status: 'valid',
                };
              }
            },
            resource: 'finances.PaymentInfo',
          },
          controller: [
            'FinancesService',
            function (FinancesService: any) {
              if (!finance.id && !finance.counterpaty_payment_info) {
                FinancesService.getDefaultCounterpatyPaymentInfo(finance).then(
                  (data: any) => (finance.counterpaty_payment_info = data),
                );
              }
            },
          ],
        },
        {
          key: 'counterparty_business_reference',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Counterparty business reference'),
            tooltip: () => {
              return finance.counterparty_business_reference;
            },
          },
        },
      ],
    });
    col3.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('CURRENCY'),
      },
      fieldGroup: [
        {
          key: 'payment_conditions',
          type: 'gt-input',
          defaultValue: 100,
          templateOptions: {
            label: this.gettext('Invoice percent'),
            placeholder: this.gettext('0-100'),
            hint: this.gettext('Pick from 0 to 100 to set up prepay'),
            addon: this.gettext('0-100%'),
            type: 'number',
          },
          validators: {
            notFalse: ($viewValue: any, $modelValue: any) => {
              return (
                parseFloat($viewValue || $modelValue || 0) >= 0 &&
                parseFloat($viewValue || $modelValue || 0) <= 200
              );
            },
          },
        },
        {
          key: 'updatePositionsAmount',
          template: html`
            <div class="clearfix"></div>
            <div class="container">
              <a
                class="btn btn_success centered"
                style="font-size: 0.7em"
                ng-click="to.broadcastPositinUpdate()"
              >
                <i class="fa fa-angle-double-up"></i> <translate>Recalculate invoice</translate>
              </a>
            </div>
            <br />
          `,
          templateOptions: {
            label: this.gettext('Recalculate invoice'),
            broadcastPositinUpdate: () => this.$rootScope.$broadcast('update-position-amount'),
          },
        },
        {
          key: 'interest_rate',
          type: 'gt-input',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.interest_rate,
          templateOptions: {
            label: this.gettext('Interest rate'),
            placeholder: this.gettext('0-100'),
            hint: this.gettext('Pick from 0 to 100 to set up interest rate'),
            addon: this.gettext('%'),
            type: 'number',
            addIcon: 'fa fa-percent',
          },
          validators: {
            notFalse: ($viewValue: any, $modelValue: any) => {
              return (
                parseFloat($viewValue || $modelValue || 0) >= 0 &&
                parseFloat($viewValue || $modelValue || 0) <= 100
              );
            },
          },
        },
        {
          key: 'discount_calculation',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Discount calculation, %'),
            placeholder: this.gettext('0-100'),
            addon: this.gettext('0-100%'),
            type: 'number',
          },
          validators: {
            notFalse: ($viewValue: any, $modelValue: any) => {
              return (
                parseFloat($viewValue || $modelValue || 0) >= 0 &&
                parseFloat($viewValue || $modelValue || 0) <= 100
              );
            },
          },
        },
        {
          key: 'discount',
          type: 'gt-input',
          defaultValue: 100,
          templateOptions: {
            label: this.gettext('Discount, %'),
            placeholder: this.gettext('0-200'),
            hint: this.gettext(
              'Pick from 0 to 200 to increase or decrease the amount. Less than 100 - decreases, more than 100 - increases',
            ),
            addon: this.gettext('0-200%'),
            type: 'number',
            required: true,
          },
          validators: {
            notFalse: ($viewValue: any, $modelValue: any) => {
              return (
                parseFloat($viewValue || $modelValue || 0) >= 0 &&
                parseFloat($viewValue || $modelValue || 0) <= 200
              );
            },
          },
        },
        {
          key: 'discount_amount',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Amount of discount'),
            placeholder: this.gettext('Use this or %'),
            hint: this.gettext('This amount will change final invoice amount'),
            type: 'number',
            required: true,
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.discount != 100 && !model.id',
          },
        },
        {
          key: 'discount_mt',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Discount per 1 mt'),
            hint: this.gettext('Appoint it if you want to apply discount per 1 mt'),
            type: 'number',
            required: true,
          },
        },

        {
          key: 'not_pay',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Not pay'),
            help: this.gettext(
              'This will add a mark that you have no intention to pay this invoice in a nearest future',
            ),
          },
        },
        {
          key: 'payment_conditions_option',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Payment conditions option'),
            placeholder: this.gettext('Choose option'),
            resource: 'finances.PaymentCondition',
            addFunc: () => {
              this.$window.open('/admin/finances/paymentcondition/');
            },
            addIcon: 'fa fa-dollar',
            addPerms: ['add_paymentcondition'],
            queryParams: () => {
              return { is_active: 1 };
            },
          },
        },

        {
          key: 'currency',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Currency'),
            placeholder: this.gettext('USD, UAH'),
            resource: 'finances.Currency',
            addFunc: () => {
              this.$window.open('/admin/finances/currency/add/');
            },
            onSelect: () => {
              this.FinancesService.getDefaultBankAccount(finance).then(
                (data: any) => (finance.bank_account = data),
              );
              this.FinancesService.getDefaultCounterpatyPaymentInfo(finance).then(
                (data: any) => (finance.counterpaty_payment_info = data),
              );
            },
            addIcon: 'fa fa-dollar',
            addPerms: ['add_currency'],
            required: true,
          },
        },
        {
          key: 'currency_exchange',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Exchange rate'),
            resource: 'finances.CurrencyExchange',
            addFunc: () => {
              this.$window.open('/admin/finances/currencyexchange/add/');
            },
            queryParams: () => {
              return { local_currency: finance.currency };
            },
            addIcon: 'fa fa-money',
            addPerms: ['add_currencyexchange'],
            help: this.gettext(
              'Pick exchange rate if you want to convert invoice value to USD by certain rate',
            ),
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.currency',
            'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
              isExchangeFieldRequired &&
              scope.model.currency !== this.FinancesService.getDefaultCurrency().id,
          },
        },
        {
          key: 'additional_currency',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Additional currency'),
            placeholder: this.gettext('USD, UAH'),
            resource: 'finances.Currency',
            addFunc: () => {
              this.$window.open('/admin/finances/currency/add/');
            },
            addIcon: 'fa fa-money',
            addPerms: ['add_currency'],
            title: finance.additional_currency_symbol,
          },
        },
        {
          key: 'additional_currency_exchange',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Additional exchange rate'),
            resource: 'finances.CurrencyExchange',
            addFunc: () => {
              this.$window.open('/admin/finances/currencyexchange/add/');
            },
            queryParams: () => {
              return { local_currency: finance.additional_currency };
            },
            addIcon: 'fa fa-money',
            addPerms: ['add_currencyexchange'],
            title: finance.additional_currency_exchange_title,
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.additional_currency',
          },
        },
        {
          key: 'mul_amount_rate',
          template: require('./templates/mul-amount-rate.tpl.html?raw'),
          templateOptions: {
            label: this.gettext('Multiply amount with rate'),
          },
          controller: [
            '$scope',
            'FinancesService',
            'GtUtils',
            function (scope: any, FinancesService: FinancesService, GtUtils: GtUtilsService) {
              scope.mulAmountRate = () => {
                FinancesService.CurrencyExchange.get(
                  { id: finance.currency_exchange },
                  (data: any) => {
                    if (!data.rate) {
                      return;
                    }
                    positions.forEach((position: any) => {
                      position.price_per_piece =
                        Math.round(position.price_per_piece * data.rate * 10000) / 10000;
                      position.amount =
                        Math.round(position.price_per_piece * position.quantity * 10000) / 10000;
                    });
                  },
                  (data: any) => {
                    GtUtils.errorClb(data);
                  },
                );
              };
            },
          ],
        },
        {
          key: 'daily_fines_rate',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Daily fines rate'),
            placeholder: this.gettext('0-100'),
            hint: this.gettext('Pick from 0 to 100 to set up Daily fines rate'),
            addon: this.gettext('%'),
            type: 'number',
            addIcon: 'fa fa-percent',
          },
          validators: {
            notFalse: ($viewValue: any, $modelValue: any) => {
              return (
                parseFloat($viewValue || $modelValue || 0) >= 0 &&
                parseFloat($viewValue || $modelValue || 0) <= 100
              );
            },
          },
        },
        {
          key: 'fines_days_count',
          type: 'gt-select',
          templateOptions: {
            label: this.gettext('Fines days count method'),
            placeholder: this.gettext('Choose fines days count method'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Calendar days'), value: 'calendar_days' },
              { name: this.gettext('Business days'), value: 'business_days' },
            ],
          },
        },
        {
          key: 'fines_invoicing',
          type: 'gt-select',
          templateOptions: {
            label: this.gettext('Fines invoicing method'),
            placeholder: this.gettext('Choose fines invoicing method'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Costs'), value: 'costs' },
              { name: this.gettext('Position'), value: 'position' },
            ],
          },
        },
        {
          key: 'invoice_fines',
          template: html`
            <div class="clearfix"></div>
            <div class="container">
              <a
                ng-if="model.fines_invoicing === 'costs'"
                class="btn btn_success centered"
                style="font-size: 0.7em"
                ng-click="to.invoiceFinesCosts()"
              >
                <i class="fa fa-angle-double-up"></i> <translate>Invoice fines</translate>
              </a>
              <a
                ng-if="model.fines_invoicing === 'position'"
                class="btn btn_success centered"
                style="font-size: 0.7em"
                ng-click="to.invoiceFinesPosition()"
              >
                <i class="fa fa-angle-double-up"></i> <translate>Invoice fines</translate>
              </a>
            </div>
            <br />
          `,
          templateOptions: {
            label: this.gettext('Multiply amount with rate'),
            invoiceFinesCosts: () => this.FinancesService.invoiceFinesCosts(finance),
            invoiceFinesPosition: () =>
              this.FinancesService.invoiceFinesPosition(finance, positions),
          },
        },
      ],
    });
    col4.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('DATES & NOTES'),
      },
      fieldGroup: [
        {
          key: 'date',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Date of issuance'),
            placeholder: this.gettext('date'),
            hint: this.gettext('When you have issued invoice'),
            required: true,
          },
        },
        {
          key: 'date_of_certification',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Date of certification'),
            placeholder: this.gettext('date'),
            hint: this.gettext('When all the certification have been resolved'),
            type: 'date',
          },
        },
        {
          key: 'date_of_receiving',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Date of receiving'),
            placeholder: this.gettext('date'),
            hint: this.gettext('When you have received invoice'),
            type: 'date',
          },
        },
        {
          key: 'date_of_execution',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Date of payment (plan)'),
            placeholder: this.gettext('date'),
            hint: this.gettext('When you expect payment to be made'),
            type: 'date',
            dateModel: finance.date_of_execution,
            useWatch: true,
          },
        },
        {
          key: 'date_of_execution_fact',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Date of payment'),
            placeholder: this.gettext('date'),
            hint: this.gettext('When payment was actually made'),
            type: 'date',
          },
        },
        {
          key: 'date_of_withdrawal',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Date of withdrawal'),
            placeholder: this.gettext('date'),
            hint: this.gettext('Date of documents withdrawal'),
            type: 'date',
          },
        },
        {
          key: 'additional_info',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Additional Info'),
            placeholder: this.gettext('Write down the specific information  about this invoice'),
            className: 'additional-info',
            tooltip: () => {
              return finance.additional_info;
            },
          },
        },
        {
          key: 'marks',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Marks'),
          },
        },
        {
          key: 'po_number',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('PO number'),
          },
        },
        {
          key: 'note_one',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Note one'),
          },
        },
        {
          key: 'note_two',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Note two'),
          },
        },
        {
          key: 'author',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.id,
          templateOptions: {
            label: this.gettext('Author'),
            placeholder: this.gettext('Author'),
            resource: 'auth.User',
            disabled: true,
          },
        },
        {
          key: 'responsible',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.id,
          templateOptions: {
            label: this.gettext('Responsible'),
            placeholder: this.gettext('User to be responsible'),
            resource: 'auth.User',
            onSelect: () => {
              this.AccountsService.User.get(finance.responsible)
                .then((userDetails) => {
                  finance.responsibleWorkStatus = userDetails.profile.work_status;
                  return userDetails;
                })
                .catch(this.GtUtils.errorClb);
            },
          },
        },
        {
          template: html`
            <div style="margin: 8px 0">
              <work-status-display
                status="to.responsibleWorkStatus"
              </work-status-display>
            </div>
          `,
          hideExpression: () =>
            !finance.responsible || !this.$rootScope.user.settings.USE_WORK_STATUS,
          expressionProperties: {
            'templateOptions.responsibleWorkStatus': (
              viewValue: any,
              modelValue: any,
              scope: any,
            ) => {
              return scope.model.responsibleWorkStatus;
            },
          },
          defaultValue: finance.responsible
            ? this.AccountsService.User.get(finance.responsible).then((userDetails: any) => {
                finance.responsibleWorkStatus = userDetails.profile.work_status;
                return userDetails;
              })
            : null,
        },
        {
          key: 'responsible_for_signing',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.id,
          templateOptions: {
            label: this.gettext('Responsible for signing'),
            placeholder: this.gettext('User to be responsible_for_signing'),
            resource: 'auth.User',
            onSelect: () => {
              this.AccountsService.User.get(finance.responsible_for_signing)
                .then((userDetails: any) => {
                  finance.responsibleForSigningWorkStatus = userDetails.profile.work_status;
                  return userDetails;
                })
                .catch(this.GtUtils.errorClb);
            },
          },
        },
        {
          template: html`
            <div style="margin: 8px 0">
              <work-status-display
                status="to.responsibleForSigningWorkStatus"
              </work-status-display>
            </div>
          `,
          hideExpression: () =>
            !finance.responsible_for_signing || !this.$rootScope.user.settings.USE_WORK_STATUS,
          expressionProperties: {
            'templateOptions.responsibleForSigningWorkStatus': (
              viewValue: any,
              modelValue: any,
              scope: any,
            ) => {
              return scope.model.responsibleForSigningWorkStatus;
            },
          },
          defaultValue: finance.responsible_for_signing
            ? this.AccountsService.User.get(finance.responsible_for_signing).then(
                (userDetails: any) => {
                  finance.responsibleForSigningWorkStatus = userDetails.profile.work_status;
                  return userDetails;
                },
              )
            : null,
        },
        {
          key: 'delivery_hyperlink',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Delivery status'),
            placeholder: this.gettext('website'),
            hint: this.gettext('Paste a hyperlink to DHL here to track the status of delivery'),
          },
        },
        {
          key: 'marine_cover_policy_number',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Marine Cover Policy No.'),
            tooltip: () => {
              return finance.marine_cover_policy_number;
            },
          },
        },
        {
          key: 'letter_of_credit_number',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Letter of Credit No.'),
            tooltip: () => {
              return finance.letter_of_credit_number;
            },
          },
        },
      ],
    });

    return {
      formName: formName || this.getFormName(finance),
      fieldsDef: [col1, col2, col3, col4],
    };
  }

  getCustomFieldTableColumns() {
    return this.CustomValuesService.getCustomFieldTableColumns({ purpose_model: 'finance' });
  }
}
InvoiceFormFieldsService.$inject = [
  '$rootScope',
  '$window',
  'gettext',
  'GtUtils',
  'CoreService',
  'ClientsService',
  'AccountsService',
  'FinancesService',
  'FormFieldParamsService',
  'CustomValuesService',
];
