import ng from 'angular';

import { SalePlanReportContainer } from './sale-plan-report-container/sale-plan-report-container.component';
import { SalePlanReportPage } from './sale-plan-report-page/sale-plan-report-page.component';
import { SalePlanReportTableView } from './sale-plan-report-table-view/sale-plan-report-table-view.component';
import { SalePlanReportService } from './sale-plan-report.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const salePlanReport = ng
  .module('reports.salePlanReport', [])
  .service('SalePlanReportService', SalePlanReportService)
  .component('salePlanReportPage', SalePlanReportPage)
  .component('salePlanReportContainer', SalePlanReportContainer)
  .component('salePlanReportTableView', SalePlanReportTableView)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('reports.salePlanReport', {
        url: '/sale-plans/',
        component: 'salePlanReportPage',
        data: {
          pageTitle: gettext('sale Plan'),
        },
        resolve: {
          filterLevel: () => {
            return 'sale-plan-report-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('sale-plan-report-page');
            },
          ],
        },
      });
    },
  ]).name;
