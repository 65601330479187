import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';
import { html } from '^/shared/utils';

export const PassportPnlPage = {
  bindings: {},
  template: html`<passport-pnl-table filter-level="$ctrl.filterLevel"></passport-pnl-table>`,
  controller: [
    'PageService',
    'ReportsService',
    'gettext',
    class {
      PageService: PageService;
      ReportsService: ReportsService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      passportId?: number;
      constructor(
        PageService: PageService,
        ReportsService: ReportsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.gettext = gettext;

        this.filterLevel = '';
      }

      $onInit() {
        this.filterLevel = 'passport-pnl-page-view';
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {},
        };
      }
    },
  ],
};
