import type ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import template from './shipment-schedule-list-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const ShipmentScheduleListPage = {
  bindings: {
    initQueryParams: '<?',
  },
  template,
  controller: [
    'LogisticsService',
    'PageService',
    'GtUtils',
    'gettext',
    class {
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      groupBy: any;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        LogisticsService: LogisticsService,
        PageService: PageService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.LogisticsService = LogisticsService;
        this.PageService = PageService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.filterLevel = 'shipment-schedule-list-page';
        this.groupBy = null;

        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.queryParams = {
          plan_start_date: formatDate(firstDay, 'dd.MM.yyyy'),
          plan_end_date: formatDate(lastDay, 'dd.MM.yyyy'),
          ...this.initQueryParams,
        };
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.LogisticsService.getPagesConfig(),
        };

        config.filters = {
          filterLevel: this.filterLevel,
          clicked: false,
          search: false,
          dates: false,
          multiSelects: [
            {
              argument: 'sale_contract_list',
              placeholder: this.gettext('Select sale contract'),
              resource: 'contracts.SaleContract',
            },
            {
              argument: 'purchase_contract_list',
              placeholder: this.gettext('Select purchase contract'),
              resource: 'contracts.PurchaseContract',
            },
            {
              argument: 'cargo_list',
              placeholder: this.gettext('Select cargo'),
              resource: 'crops.Crop',
            },
            {
              argument: 'station_destination_list',
              placeholder: this.gettext('Select station destination'),
              resource: 'logistics.Station',
            },
            {
              argument: 'departure_station_list',
              placeholder: this.gettext('Select departure station'),
              resource: 'logistics.Station',
            },
            {
              argument: 'terminal_list',
              placeholder: this.gettext('Select terminal'),
              resource: 'logistics.Terminal',
            },
            {
              argument: 'elevator_list',
              placeholder: this.gettext('Select elevator'),
              resource: 'clients.Elevator',
            },
            {
              argument: 'cargo_receiver_list',
              placeholder: this.gettext('Select cargo receiver'),
              resource: 'clients.ClientRole',
            },
            {
              argument: 'cargo_shipper_list',
              placeholder: this.gettext('Select cargo shipper'),
              resource: 'clients.ClientRole',
            },
            {
              argument: 'responsible_list',
              placeholder: this.gettext('Select responsible'),
              resource: 'auth.user',
            },
            {
              argument: 'business_unit_list',
              placeholder: this.gettext('Select business unit'),
              resource: 'core.BusinessUnit',
              getQueryParams: this.GtUtils.getBUQueryParams,
            },
          ],
          nestedSelects: [],
          nestedMultiSelects: [
            {
              argument: 'status_list',
              placeholder: this.gettext('Status'),
              items: [
                {
                  id: 'new',
                  title: this.gettext('New'),
                },
                {
                  id: 'approved',
                  title: this.gettext('Approved'),
                },
                {
                  id: 'process',
                  title: this.gettext('Process'),
                },
                {
                  id: 'executed',
                  title: this.gettext('Executed'),
                },
                {
                  id: 'cancelled',
                  title: this.gettext('Cancelled'),
                },
              ],
            },
            {
              argument: 'contract_type_list',
              placeholder: this.gettext('Contract type'),
              items: [
                {
                  id: 'sale',
                  title: this.gettext('Sale'),
                },
                {
                  id: 'purchase',
                  title: this.gettext('Purchase'),
                },
              ],
            },
          ],
        };

        return config;
      }
    },
  ],
};
