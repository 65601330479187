import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { html } from '^/shared/utils';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').controller('ConsignmentModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    '$scope',
    '$window',
    'gettext',
    'ContractsService',
    'consignment',
    'GtUtils',
    'FormFieldParamsService',
    'CropsService',
    'CustomValuesService',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $scope: ng.IScope,
    $window: ng.IWindowService,
    gettext: ng.gettext.gettextFunction,
    ContractsService: ContractsService,
    consignment: any,
    GtUtils: GtUtilsService,
    FormFieldParamsService: FormFieldParamsService,
    CropsService: any,
    CustomValuesService: CustomValuesService,
  ) {
    const vm = this;

    vm.save = save;
    vm.remove = remove;
    vm.close = close;
    vm.openFieldsConfigModal = openFieldsConfigModal;
    vm.openQualitiesModal = openQualitiesModal;
    vm.fields = undefined;
    vm.tab = 'edit';
    vm.isConsignmentValid = false;

    activate();

    function activate() {
      vm.consignment = consignment || {};

      if (vm.consignment.id) {
        updateData().then(() => updateFields());
      } else {
        updateFields();
      }

      if (vm.consignment.contract) {
        ContractsService.Contract.get({ id: vm.consignment.contract }).$promise.then(
          (data: any) => {
            if (data.contract_type === 'sale') {
              vm.consignment.type = 'dispatch';
            } else if (data.contract_type === 'purchase') {
              vm.consignment.type = 'receipt';
            }
            vm.consignment.manager = vm.consignment.manager || data.responsible;
          },
        );
      }

      $scope.$on('custom-values-updated__consignment', function (event: any, data: any) {
        vm.customValues = data;
      });

      $scope.$watchGroup(
        [
          function () {
            return vm.consignment.farm;
          },
          function () {
            return vm.consignment.elevator;
          },
          function () {
            return vm.consignment.terminal;
          },
        ],
        function (newValues: any) {
          vm.isConsignmentValid = false;
          const requiredFieldscount: number = ['farm', 'elevator', 'terminal'].filter(
            isFieldRequired,
          ).length;

          const selectedFieldsCount = newValues.filter(function (value: any) {
            return Boolean(value);
          }).length;

          if (vm.form && requiredFieldscount >= 1 && selectedFieldsCount >= 1) {
            vm.isConsignmentValid = true;
            vm.form.$setValidity('consignmentValid', true);
          }
        },
      );
    }

    function isFieldRequired(fieldName: string) {
      for (const key of Object.keys(vm.form)) {
        if (key.includes(fieldName)) {
          return vm.form[key].$valid;
        }
      }
    }

    function updateData() {
      return ContractsService.Consignments.get({ id: vm.consignment.id }, function (data: any) {
        vm.consignment = data;
      }).$promise;
    }

    function openQualitiesModal() {
      CropsService.qualitiesModal(
        [],
        null,
        vm.consignment.contract_cargo,
        null,
        null,
        vm.consignment.id,
      ).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        vm.consignment.qualities = data;
      });
    }

    function updateFields() {
      FormFieldParamsService.getFields(getFields())
        .then((fields: any) => (vm.fields = fields))
        .catch(GtUtils.errorClb);
    }

    function remove(consignment: { id: number }) {
      if (!confirm(gettext('Are you sure that you want delete this consignment?'))) {
        return;
      }

      ContractsService.Consignments.delete({ id: consignment.id }).$promise.then(() => {
        notify(gettext('Consignment deleted successfully.'), 'success');
        $uibModalInstance.close();
      });
    }

    function openFieldsConfigModal() {
      FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(() => updateFields());
    }

    function getFormConfig() {
      return getFields();
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-md-6 col-xs-12',
        fieldGroup: [],
      };
      const col2: any = {
        className: 'form-group-container col-md-6 col-xs-12',
        fieldGroup: [],
      };

      col1.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {},
          fieldGroup: [
            {
              key: 'date',
              defaultValue: new Date(),
              type: 'gt-date-select',
              templateOptions: {
                label: gettext('Date date'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'number',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Number'),
                placeholder: gettext('Number'),
                type: 'text',
              },
            },
            {
              key: 'volume',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Volume'),
                placeholder: gettext('Volume'),
                type: 'number',
                addon: gettext('t'),
                required: true,
              },
              validators: {
                notFalse: function ($viewValue: any, $modelValue: any) {
                  return ($viewValue || $modelValue || 0) >= 0;
                },
              },
            },
            {
              key: 'type',
              type: 'gt-select',
              templateOptions: {
                label: gettext('Type'),
                placeholder: gettext('Type of consignment'),
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: gettext('Receipt'), value: 'receipt' },
                  { name: gettext('Dispatch'), value: 'dispatch' },
                ],
              },
              expressionProperties: {
                'templateOptions.disabled': () => Boolean(vm.consignment.contract),
                'templateOptions.required': () => !vm.consignment.contract,
              },
            },
            {
              key: 'commodity',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Commodity'),
                placeholder: gettext('Choose commodity'),
                resource: 'crops.Crop',
                addFunc: () => {
                  $window.open('/admin/crops/crop/add/');
                },
                addIcon: 'fa fa-wheat-awn',
                addPerms: ['add_crop'],
              },
            },

            {
              template: html`
                <custom-values-container
                  filter-level="'consignment-custom-values-container'"
                  init-query-params="{
                    object_id: model.id,
                    purpose: model.content_type,
                    purpose_model: 'consignment',
                  }"
                  mode="model.id ? 'edit' : 'create'"
                  object-id="model.id"
                ></custom-values-container>
              `,
            },
          ],
        },
      ];

      col2.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {},
          fieldGroup: [
            {
              key: 'warehouse',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Warehouse'),
                resource: 'logistics.warehouse',
                queryParams: { is_group: '0' },
                required: true,
              },
            },
            {
              key: 'exclude_from_warehouse',
              type: 'gt-checkbox',
              templateOptions: {
                label: gettext('Exclude from warehouse'),
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Business unit'),
                placeholder: gettext('Business Unit'),
                queryParams: GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
              },
              expressionProperties: {
                'templateOptions.disabled': () => Boolean(vm.consignment.contract),
              },
            },
            {
              key: 'voyage',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Voyage'),
                placeholder: gettext('Business Unit'),
                resource: 'logistics.voyage',
              },
            },
            {
              key: 'receiver',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Receiver'),
                placeholder: gettext('Choose receiver'),
                resource: 'clients.Client',
                queryParams: { is_receiver: '1' },
              },
            },
            {
              key: 'producer',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Producer'),
                placeholder: gettext('Choose producer'),
                resource: 'clients.Client',
                queryParams: { is_producer: '1' },
              },
            },
            {
              key: 'contract',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Contract'),
                placeholder: gettext('Choose contract'),
                resource: 'contracts.ContractBase',
                queryParams: () => ({
                  multicontract: consignment.multicontract,
                  contract_type: consignment.type === 'dispatch' ? 'sale' : 'purchase',
                  from_stock_reserve_unit:
                    consignment.type === 'dispatch' ? consignment.stock_reserve_unit : undefined,
                  stage: 'contract',
                }),
                onSelect: () => {
                  ContractsService.Contract.get({ id: vm.consignment.contract }).$promise.then(
                    (data: any) => {
                      vm.consignment.manager = data.responsible;

                      if (data.contract_type === 'sale') {
                        vm.consignment.type = 'dispatch';
                      } else if (data.contract_type === 'purchase') {
                        vm.consignment.type = 'receipt';
                      }
                    },
                  );
                },
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'passport',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Passport'),
                resource: 'passports.passport',
                queryParams: () => ({ contract_list: [consignment.contract] }),
              },
            },
            {
              key: 'stock_reserve_unit',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Stock Reserve Unit'),
                placeholder: gettext('Choose Stock Reserve Unit'),
                resource: 'logistics.stockreserveunit',
                queryParams: () => ({
                  sale_contract: consignment.type === 'dispatch' ? consignment.contract : undefined,
                }),
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'manager',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Manager'),
                placeholder: gettext('Choose manager'),
                resource: 'auth.User',
              },
            },
            {
              key: 'additional_info',
              type: 'gt-textarea',
              templateOptions: {
                label: gettext('Additional Info'),
                placeholder: gettext('Specific information about this object'),
                className: 'additional-info',
              },
            },
          ],
        },
      ];

      return {
        formName: 'consignment-edit-modal',
        fieldsDef: [col1, col2],
      };
    }

    function save() {
      vm.form.$invalid = true;
      if (vm.consignment.id) {
        return ContractsService.Consignments.update(
          vm.consignment,
          function (data: any) {
            notify(gettext('Consignment saved'));
            close(data, true);
          },
          _error,
        ).$promise;
      }
      return ContractsService.Consignments.save(
        vm.consignment,
        function (data: any) {
          notify(gettext('Consignment saved'));
          close(data, true);
          CustomValuesService.createCustomValues(data.id, vm.customValues);
        },
        _error,
      ).$promise;
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
      vm.consignment.errors = data.data;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
