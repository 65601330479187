import { locationDistrictsList, locationDistrictsPredictionsRetrieve } from '~/shared/api';
import { EntityRepository, type Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { District, DistrictQueryParams } from '../lib';

export class DistrictRepository extends EntityRepository<District, DistrictQueryParams> {
  readonly entityName = 'location.District';

  getFn = (_id: number): Promise<District> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: District): Promise<District> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<District>): Promise<District> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: DistrictQueryParams): Promise<Paginated<District>> => {
    const { data } = await locationDistrictsList({ query });
    return { records: data.results, count: data.count };
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await locationDistrictsPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
}
