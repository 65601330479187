import ng from 'angular';

import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive('contractsContractItemQualityTab', directive);

  function directive() {
    return {
      template: require('./quality-tab.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',

      scope: {
        contract: '=?',
        contractId: '=?',
      },
    };
  }

  Controller.$inject = ['ContractsService', 'CropsService'];

  function Controller(this: any, ContractsService: ContractsService, CropsService: any) {
    const vm = this;

    vm.updateQualities = updateQualities;
    vm.openQualitiesModal = openQualitiesModal;
    vm.cropQualities = [];
    vm.planQualities = [];
    vm.commodityIdList = [];

    vm.$onInit = function () {
      if (vm.contractId && !vm.contract) {
        return ContractsService.Contract.query({
          id: vm.contractId,
          serializer: 'modal',
        }).$promise.then(function (fetched: any) {
          vm.contract = fetched;
          updateQualities();
        });
      } else if (vm.contract) {
        updateQualities();
      }
    };

    function updateQualities() {
      if (!vm.contract) {
        return;
      }

      vm.commodityIdList = (vm.contract.contract_prices || []).map(function (price: any) {
        return price.cargo;
      });

      vm.commodityIdList.push(vm.contract.cargo);

      if (!vm.commodityIdList.length && !vm.contract.from_request) {
        return;
      }
      CropsService.CropQuality.query({ cargo_list: vm.commodityIdList }, function (data: any) {
        vm.cropQualities = data.results;
      });
      if (!vm.contract.qualities?.length) {
        ContractsService.ContractQuality.query({ contract: vm.contract.id }, function (data: any) {
          vm.contract.qualities = data.results;
        });
      }
      if (vm.contract.from_request) {
        ContractsService.ContractQuality.query(
          { contract: vm.contract.from_request },
          function (data: any) {
            vm.planQualities = data.results;
          },
        );
      }
    }

    function openQualitiesModal() {
      if (!vm.contract) {
        return;
      }

      return CropsService.qualitiesModal(
        vm.contract.qualities,
        vm.contract.from_request,
        vm.contract.cargo,
        vm.contract.id,
        null,
        null,
        vm.commodityIdList,
      ).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        vm.contract.qualities = data;
        updateQualities();
      });
    }
  }
})();
