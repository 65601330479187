import { CustomFieldRepository } from '~/core/custom-fields/services/custom-field.repository';
import { ComponentModel } from '~/shared/lib/components';
import { injectable } from '~/shared/lib/di';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  from,
  map,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from '~/shared/lib/state';
import { askUser } from '~/shared/ui/lib/ask-user';

import {
  buildNewColumnConfig,
  columnConfigDefFromFieldDef,
  columnsConfigFromColumnsSetConfig,
  updateColumnsIndexes,
} from '../../helpers/configs';
import { applyColumnParams, fieldDefFromCustomField } from '../../helpers/fields';
import {
  ColumnConfigParams,
  ColumnsConfig,
  DataParams,
  DataRecord,
  DatasetArgs,
  DatasetIdSplit,
  FieldDef,
} from '../../lib/types';
import { ColumnsSetConfigRepository } from '../../services/columns-set-config.repository';

export type DatasetViewsProps<R extends DataRecord, P extends DataParams> = {
  datasetId$: Observable<DatasetIdSplit>;
  fieldDefs$: DatasetArgs<R, P>['fieldDefs$'];
  onFieldsChanged: (fields: FieldDef<R, P>[]) => void;
};

@injectable()
export class DatasetViewsModel<
  R extends DataRecord = DataRecord,
  P extends DataParams = DataParams,
> extends ComponentModel<DatasetViewsProps<R, P>> {
  private readonly editingColumnsConfigSubj = new BehaviorSubject<ColumnsConfig | null>(null);
  private readonly activeColumnsConfigSubj = new BehaviorSubject<ColumnsConfig | null>(null);
  private readonly loadingSubj = new BehaviorSubject<boolean>(false);
  private readonly draggableItemSubj = new BehaviorSubject<ColumnConfigParams | null>(null);

  constructor(
    private readonly customFieldRepo: CustomFieldRepository,
    private readonly columnConfigsRepo: ColumnsSetConfigRepository,
  ) {
    super();
  }

  onInit = () => this.fields$.subscribe(this.props.onFieldsChanged);

  readonly fieldDefs$ = this.props$.pipe(switchMap(({ fieldDefs$ }) => fieldDefs$));
  readonly datasetId$ = this.props$.pipe(switchMap(({ datasetId$ }) => datasetId$));

  readonly customFields$ = this.datasetId$.pipe(
    switchMap((datasetId) =>
      this.customFieldRepo.query({
        table_name: datasetId.appName,
        purpose_model: datasetId.modelName,
      }),
    ),
  );

  readonly draggableItem$ = this.draggableItemSubj.asObservable();
  readonly loading$ = this.loadingSubj.asObservable();
  readonly activeColumnsConfig$ = this.activeColumnsConfigSubj.asObservable();

  readonly defaultColumnParams$ = combineLatest([this.fieldDefs$, this.customFields$]).pipe(
    map(([fields, { records: customFields }]) =>
      columnConfigDefFromFieldDef([
        ...fields,
        ...customFields.map((i) => fieldDefFromCustomField<R, P>(i)),
      ]),
    ),
  );

  readonly columnParams$ = combineLatest([
    this.defaultColumnParams$,
    this.activeColumnsConfig$,
  ]).pipe(
    map(([defaultColumns, activeConfig]) =>
      activeConfig ? activeConfig.column_params : defaultColumns,
    ),
  );

  readonly columnConfigs$ = combineLatest([this.datasetId$, this.defaultColumnParams$]).pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap(async ([datasetId, defaultColumnParams]) => {
      const { records } = await this.columnConfigsRepo.query({
        config_level: datasetId.datasetId,
      });
      return records.map((record) =>
        columnsConfigFromColumnsSetConfig(record, defaultColumnParams),
      );
    }),
    tap(() => this.loadingSubj.next(false)),
  );

  readonly editingColumnsConfig$ = this.editingColumnsConfigSubj.asObservable();
  readonly editingColumns$ = this.editingColumnsConfig$.pipe(
    map((config) => ({
      visible:
        config?.column_params
          .filter((col) => col.requiredByDefault || col.visible)
          .toSorted((a, b) => a.index - b.index) ?? [],
      hidden:
        config?.column_params
          .filter((col) => !col.visible && !col.requiredByDefault)
          .toSorted((a, b) => a.index - b.index) ?? [],
    })),
  );

  readonly fields$ = combineLatest([this.fieldDefs$, this.customFields$, this.columnParams$]).pipe(
    switchMap(([fieldDefs, { records: customFields }, columnParams]) => {
      const customFieldDefs = customFields.map((i) => fieldDefFromCustomField<R, P>(i));
      return of(applyColumnParams([...fieldDefs, ...customFieldDefs], columnParams));
    }),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  public setActiveColumnsConfig(config: ColumnsConfig) {
    this.activeColumnsConfigSubj.next(
      config.id === this.activeColumnsConfigSubj.value?.id ? null : config,
    );
  }

  public setEditingColumnsConfig(config: ColumnsConfig | null) {
    this.editingColumnsConfigSubj
      .pipe(
        take(1),
        switchMap((config) => (config ? from(askUser()) : Promise.resolve(true))),
        filter((answer) => answer),
        tap(() => this.editingColumnsConfigSubj.next(config)),
      )
      .subscribe();
  }

  editColumn = (editedCol: ColumnConfigParams) => {
    this.editingColumnsConfig$
      .pipe(
        take(1),
        filter(Boolean),
        tap((config) =>
          this.editingColumnsConfigSubj.next({
            ...config,
            column_params: config.column_params.map((col) =>
              col.id === editedCol.id ? editedCol : col,
            ),
          }),
        ),
      )
      .subscribe();
  };
  editAllColumns = (edited: Partial<ColumnConfigParams>) => {
    this.editingColumnsConfig$
      .pipe(
        take(1),
        filter(Boolean),
        tap((config) =>
          this.editingColumnsConfigSubj.next({
            ...config,
            column_params: config.column_params.map((col) => ({ ...col, ...edited })),
          }),
        ),
      )
      .subscribe();
  };

  toggleColumnVisibility = (column: ColumnConfigParams) =>
    this.editColumn({ ...column, visible: !column.visible });
  toggleColumnRequirement = (column: ColumnConfigParams) =>
    this.editColumn({ ...column, required: !column.required });
  hideAllColumns = () => this.editAllColumns({ visible: false });
  showAllColumns = () => this.editAllColumns({ visible: true });
  // @oleksii-gt це терміново прибрати
  reloadColumnConfigs = () => this.propsSubj.next(this.propsSubj.value);

  createColumnsConfig = () => {
    this.defaultColumnParams$
      .pipe(
        take(1),
        tap((defaultColumnParams) =>
          this.setEditingColumnsConfig(buildNewColumnConfig(defaultColumnParams)),
        ),
      )
      .subscribe();
  };

  editColumnsConfig = (edited: Partial<ColumnsConfig>) => {
    this.editingColumnsConfig$
      .pipe(
        take(1),
        filter(Boolean),
        tap((config) => this.editingColumnsConfigSubj.next({ ...config, ...edited })),
      )
      .subscribe();
  };

  duplicateColumnsConfig = () => {
    combineLatest([this.editingColumnsConfig$.pipe(filter(Boolean)), this.defaultColumnParams$])
      .pipe(
        take(1),
        tap(([config, defaultColumnParams]) =>
          this.setEditingColumnsConfig(buildNewColumnConfig(defaultColumnParams, config)),
        ),
      )
      .subscribe();
  };

  saveColumnsConfig = () => {
    // @oleksii-gt логіка має бути декларативною, прибери це все і зроби нормально
    combineLatest({
      editing: this.editingColumnsConfig$.pipe(filter(Boolean)),
      datasetId: this.datasetId$,
      defaultParams: this.defaultColumnParams$,
    })
      .pipe(
        take(1),
        withLatestFrom(askUser()),
        filter(([, confirm]) => confirm),
        tap(() => this.loadingSubj.next(true)),
        switchMap(async ([{ editing, datasetId, defaultParams }]) =>
          columnsConfigFromColumnsSetConfig(
            await this.columnConfigsRepo.saveRecord({
              ...editing,
              config_level: datasetId.datasetId,
              column_params: editing.column_params.map((col) => ({
                ...col,
                required: col.requiredByDefault ? col.requiredByDefault : (col.required ?? false),
                visible: col.requiredByDefault || col.visible,

                columnssetconfig_id: editing.id,
                table_name: datasetId.appName,
              })),
            }),
            defaultParams,
          ),
        ),
        tap((config) => {
          this.editingColumnsConfigSubj.next(null);
          this.activeColumnsConfigSubj.next(
            config.id === this.activeColumnsConfigSubj.value?.id
              ? config
              : this.activeColumnsConfigSubj.value,
          );
          this.reloadColumnConfigs();
        }),
      )
      .subscribe();
  };

  deleteColumnsConfig = () => {
    combineLatest([this.editingColumnsConfig$.pipe(filter(Boolean)), askUser()])
      .pipe(
        take(1),
        filter(([, answer]) => answer),
        tap(() => this.loadingSubj.next(true)),
        switchMap(([config]) =>
          config.id > 0 ? from(this.columnConfigsRepo.delete(config.id)) : from(Promise.resolve()),
        ),
        tap(() => {
          this.editingColumnsConfigSubj.next(null);
          this.reloadColumnConfigs();
        }),
      )
      .subscribe();
  };

  handleDragStart = (id: number) => {
    this.editingColumnsConfig$
      .pipe(
        take(1),
        filter(Boolean),
        tap((config) =>
          this.draggableItemSubj.next(config.column_params.find((col) => col.id === id) ?? null),
        ),
      )
      .subscribe();
  };

  handleDragEnd = (draggedId: number, overId?: number) => {
    if (!overId) {
      this.draggableItemSubj.next(null);
      return;
    }
    this.editingColumnsConfig$
      .pipe(
        take(1),
        filter(Boolean),
        tap((config) =>
          this.editingColumnsConfigSubj.next({
            ...config,
            column_params: updateColumnsIndexes(config.column_params, draggedId, overId),
          }),
        ),
      )
      .subscribe();
  };
}
