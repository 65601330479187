import template from './multipassport-list-table-view.html?raw';

import type { QueryParams } from '^/app/core/types';

export const MultipassportListTableView = {
  bindings: {
    multipassports: '<',
    count: '<',
    filterLevel: '<',
    totals: '<',
    applyFilters: '&',
  },
  template,
  controller: [
    class {
      count: number;
      currencyType: any;
      filterLevel = 'multipassport-list-table-view';
      multipassports: any;
      queryParams: QueryParams = {};
      totals: any;
      constructor() {
        this.multipassports = [];
        this.count = 0;
        this.totals = {};

        this.queryParams = {};
        this.currencyType = 'usd';
      }

      $onInit() {
        this.multipassports = this.multipassports || [];
        this.count = this.count || 0;
        this.totals = this.totals || 0;
      }

      db_convert(usd: any, local: any) {
        if (this.currencyType.toLowerCase() == 'usd') {
          return usd;
        } else {
          return local;
        }
      }
    },
  ],
};
