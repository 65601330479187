import ng from 'angular';

import { MulticontractDetailsInfoTab } from './components/multicontract-details-info-table/multicontract-details-info-tab.component';
import { MulticontractDetailsLogentriesTabComponent } from './components/multicontract-details-logentries-tab/multicontract-details-logentries-tab.component';
import { MulticontractItemContainer } from './components/multicontract-item-container/multicontract-item-container.component';
import { MulticontractListContainer } from './components/multicontract-list-container/multicontract-list-container.component';
import { MulticontractListPage } from './components/multicontract-list-page/multicontract-list-page.component';
import { MulticontractListTableView } from './components/multicontract-list-table-view/multicontract-list-table-view.component';
import { MulticontractModal } from './components/multicontract-modal/multicontract-modal.component';
import { MulticontractPageContainer } from './components/multicontract-page/multicontract-page.component';
import { multicontractPositionTable } from './components/multicontract-position-table/multicontract-position-table.component';
import { MulticontractFilterConfigService } from './multicontract-filter-config.service';
import { MulticontractFormFieldsService } from './multicontract-form-fields.service';
import { MulticontractPositionForm } from './multicontract-position-form/multicontract-position-form.component';
import { MulticontractService } from './multicontract.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const multicontract = ng
  .module('contracts.multicontract', [])
  .service('MulticontractService', MulticontractService)
  .service('MulticontractFilterConfigService', MulticontractFilterConfigService)
  .service('MulticontractFormFieldsService', MulticontractFormFieldsService)
  .component('multicontractModal', MulticontractModal)
  .component('multicontractListContainer', MulticontractListContainer)
  .component('multicontractListTableView', MulticontractListTableView)
  .component('multicontractPositionTable', multicontractPositionTable)
  .component('multicontractPositionForm', MulticontractPositionForm)
  .component('multicontractListPage', MulticontractListPage)
  .component('multicontractPageContainer', MulticontractPageContainer)
  .component('multicontractDetailsInfoTab', MulticontractDetailsInfoTab)
  .component('multicontractItemContainer', MulticontractItemContainer)
  .component(
    'multicontractDetailsLogentriesTabComponent',
    MulticontractDetailsLogentriesTabComponent,
  )
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider
        .state('multicontractsList', {
          parent: 'gt.page',
          url: '/multicontracts',
          abstract: true,
          template: '<ui-view/>',
        })
        .state('multicontractsList.sale', {
          url: '/sale',
          component: 'multicontractListPage',
          data: {
            pageTitle: gettext('Multicontracts'),
          },
          resolve: {
            filterLevel: () => {
              return 'multicontract-sale-list-page';
            },
            initQueryParams: [
              'PageService',
              'ContractsService',
              function (PageService: PageService, ContractsService: ContractsService) {
                return {
                  ...ContractsService.getDefaultsParams(),
                  ...PageService.syncUrlFilter('multicontract-sale-list-page'),
                  ...{
                    contract_type: 'sale',
                    stage: 'contract',
                    use_type: 'commodity',
                  },
                };
              },
            ],
          },
        })
        .state('multicontractsList.purchase', {
          url: '/purchase',
          component: 'multicontractListPage',
          data: {
            pageTitle: gettext('Multicontracts'),
          },
          resolve: {
            filterLevel: () => {
              return 'multicontract-purchase-list-page';
            },
            initQueryParams: [
              'PageService',
              'ContractsService',
              function (PageService: PageService, ContractsService: ContractsService) {
                return {
                  ...ContractsService.getDefaultsParams(),
                  ...PageService.syncUrlFilter('multicontract-purchase-list-page'),
                  ...{
                    contract_type: 'purchase',
                    stage: 'contract',
                    use_type: 'commodity',
                  },
                };
              },
            ],
          },
        })
        .state('multicontractsList.intermediate', {
          url: '/intermediate',
          component: 'multicontractListPage',
          data: {
            pageTitle: gettext('Multicontracts'),
          },
          resolve: {
            filterLevel: () => {
              return 'multicontract-intermediate-list-page';
            },
            initQueryParams: [
              'PageService',
              'ContractsService',
              function (PageService: PageService, ContractsService: ContractsService) {
                return {
                  ...ContractsService.getDefaultsParams(),
                  ...PageService.syncUrlFilter('multicontract-intermediate-list-page'),
                  ...{
                    use_type: 'intermediate',
                    stage: 'contract',
                  },
                };
              },
            ],
          },
        })
        .state('multicontractsList.export', {
          url: '/export',
          component: 'multicontractListPage',
          data: {
            pageTitle: gettext('Multicontracts'),
          },
          resolve: {
            filterLevel: () => {
              return 'multicontract-export-list-page';
            },
            initQueryParams: [
              'PageService',
              'ContractsService',
              function (PageService: PageService, ContractsService: ContractsService) {
                return {
                  ...ContractsService.getDefaultsParams(),
                  ...PageService.syncUrlFilter('multicontract-export-list-page'),
                  ...{
                    use_type: 'export',
                    stage: 'contract',
                  },
                };
              },
            ],
          },
        })
        .state('multicontractsList.servicesDirect', {
          url: '/direct',
          component: 'multicontractListPage',
          resolve: {
            filterLevel: () => {
              return 'services-direct-contracts-page-view';
            },
            useType: () => {
              return 'services';
            },
            initQueryParams: [
              'PageService',
              'ContractsService',
              function (PageService: PageService, ContractsService: ContractsService) {
                return {
                  ...ContractsService.getDefaultsParams(),
                  ...PageService.syncUrlFilter('services-direct-contracts-page-view'),
                  ...{
                    use_type: 'services',
                    stage: 'contract',
                    contract_type: 'purchase',
                    positions_charge_use_type_list: ['direct'],
                  },
                };
              },
            ],
          },
          data: {
            pageTitle: gettext('Contracts'),
          },
        })
        .state('multicontractsList.servicesGeneral', {
          url: '/general',
          component: 'multicontractListPage',
          resolve: {
            filterLevel: () => {
              return 'services-general-contracts-page-view';
            },
            useType: () => {
              return 'services';
            },
            initQueryParams: [
              'PageService',
              'ContractsService',
              function (PageService: PageService, ContractsService: ContractsService) {
                return {
                  ...ContractsService.getDefaultsParams(),
                  ...PageService.syncUrlFilter('services-general-contracts-page-view'),
                  ...{
                    use_type: 'services',
                    stage: 'contract',
                    contract_type: 'purchase',
                    positions_charge_use_type_list: ['general'],
                  },
                };
              },
            ],
          },
          data: {
            pageTitle: gettext('Contracts'),
            permissions: {
              only: 'view_servicescontract',
            },
          },
        });
    },
  ])
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('gt.multicontract', {
        url: '/multicontract/:id',
        component: 'multicontractPageContainer',
        data: {
          pageTitle: gettext('Multicontracts'),
        },
        resolve: {
          filterLevel: () => 'multicontract-page',
          queryParams: [
            'PageService',
            (PageService: PageService) => PageService.syncUrlFilter('multicontract-page'),
          ],
        },
      });
    },
  ])
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider
        .state('multiticketsList', {
          parent: 'gt.page',
          url: '/multiticket',
          abstract: true,
          template: '<ui-view/>',
        })
        .state('multiticketsList.sale', {
          url: '/sale/',
          component: 'multicontractListPage',
          data: {
            pageTitle: gettext('Multiticket'),
          },
          resolve: {
            filterLevel: () => {
              return 'multiticket-sale-list-page';
            },
            initQueryParams: [
              'PageService',
              function (PageService: PageService) {
                const queryParams = PageService.syncUrlFilter('multiticket-sale-list-page');
                return {
                  ...queryParams,
                  ...{
                    contract_type: 'sale',
                    use_type: 'commodity',
                    stage: 'ticket',
                  },
                };
              },
            ],
          },
        })
        .state('multiticketsList.purchase', {
          url: '/purchase/',
          component: 'multicontractListPage',
          data: {
            pageTitle: gettext('Multiticket'),
          },
          resolve: {
            filterLevel: () => {
              return 'multiticket-purchase-list-page';
            },
            initQueryParams: [
              'PageService',
              function (PageService: PageService) {
                const queryParams = PageService.syncUrlFilter('multiticket-purchase-list-page');
                return {
                  ...queryParams,
                  ...{
                    contract_type: 'purchase',
                    use_type: 'commodity',
                    stage: 'ticket',
                  },
                };
              },
            ],
          },
        })
        .state('multiticketsList.export', {
          url: '/export/',
          component: 'multicontractListPage',
          data: {
            pageTitle: gettext('Multiticket'),
          },
          resolve: {
            filterLevel: () => {
              return 'multiticket-export-list-page';
            },
            initQueryParams: [
              'PageService',
              function (PageService: PageService) {
                const queryParams = PageService.syncUrlFilter('multiticket-export-list-page');
                return {
                  ...queryParams,
                  ...{
                    use_type: 'export',
                    stage: 'ticket',
                  },
                };
              },
            ],
          },
        })
        .state('multiticketsList.servicesGeneral', {
          url: '/services/',
          component: 'multicontractListPage',
          resolve: {
            filterLevel: () => {
              return 'services-general-ticket-page-view';
            },
            useType: () => {
              return 'services';
            },
            initQueryParams: [
              'PageService',
              function (PageService: PageService) {
                const queryParams = PageService.syncUrlFilter('services-general-ticket-page-view');
                queryParams.positions_charge_use_type_list = ['general'];
                queryParams.use_type = 'services';
                queryParams.stage = 'ticket';
                queryParams.contract_type = 'purchase';
                return queryParams;
              },
            ],
          },
          data: {
            pageTitle: gettext('Tickets'),
          },
        })
        .state('multiticketsList.servicesDirect', {
          url: '/services/',
          component: 'multicontractListPage',
          resolve: {
            filterLevel: () => {
              return 'services-direct-ticket-page-view';
            },
            useType: () => {
              return 'services';
            },
            initQueryParams: [
              'PageService',
              function (PageService: PageService) {
                const queryParams = PageService.syncUrlFilter('services-direct-ticket-page-view');
                queryParams.positions_charge_use_type_list = ['direct'];
                queryParams.use_type = 'services';
                queryParams.stage = 'ticket';
                queryParams.contract_type = 'purchase';
                return queryParams;
              },
            ],
          },
          data: {
            pageTitle: gettext('Tickets'),
          },
        });
    },
  ])
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('gt.multiticket', {
        url: '/multiticket/:id',
        component: 'multicontractPageContainer',
        data: {
          pageTitle: gettext('Multitiket'),
        },
        resolve: {
          filterLevel: () => 'multiticket-page',
          queryParams: [
            'PageService',
            (PageService: PageService) => {
              return { ...PageService.syncUrlFilter('multiticket-page') };
            },
          ],
          isTicketPage: () => true,
        },
      });
    },
  ]).name;
