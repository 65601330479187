import { errorHandler } from '~/shared/lib/errors';
import { notify } from '~/shared/lib/notify';

import template from './approvals-by-config-container.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

export const ApprovalsByConfigContainer = {
  bindings: {
    approvableId: '<',
    approvalConfigId: '<',
    disableReactivateButton: '<',
    approvableStatus: '<?',
    voted: '&?',
    onApproval: '&?',
  },
  template: template,
  controller: [
    '$rootScope',
    'GtUtils',
    'AccountsService',
    class {
      $rootScope: GtRootScopeService;
      AccountsService: AccountsService;
      GtUtils: GtUtilsService;
      approvableId = 0;
      approvalConfigId = 0;
      approvableStatus = 'wait';
      initialApprovalStatus = false;
      config: any;
      filterLevel: string;
      levelApprovals: any;
      showArchived: any;
      updating: any;
      voted: any;
      onApproval?: ({ objectType, id }: { objectType: string; id: number }) => void;
      constructor(
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        AccountsService: AccountsService,
      ) {
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.AccountsService = AccountsService;

        this.filterLevel = 'approvals-by-config-container';
        this.config = {};
        this.levelApprovals = {};
        this.showArchived = false;
        this.updating = true;
      }

      $onInit() {
        if (!this.approvableId || !this.approvalConfigId) {
          throw new Error('approvableId and approvalConfigId are required');
        }
        this.initialApprovalStatus = this.approvableStatus === 'approved';
        this.updateData();
      }

      updateData() {
        this.updating = true;

        this.fetchData()
          .catch(errorHandler)
          .finally(() => {
            this.$rootScope.$applyAsync(() => {
              this.updating = false;
            });
          });
      }

      fetchData() {
        return this.AccountsService.getApprovalsByConfig(
          this.approvableId,
          this.approvalConfigId,
        ).then((data) => {
          this.config = data.config;
          this.levelApprovals = data.levelApprovals;

          const isApproved = this.initialApprovalStatus;

          if (!isApproved) {
            this.onApproval?.({
              objectType: this.config.object_type,
              id: this.approvableId,
            });
          }
        });
      }

      approvalAction(action: 'approve' | 'decline' | 'reactivate') {
        return this.AccountsService.voteApprovable(
          action,
          this.approvableId,
          null,
          this.config.object_type,
        ).then(() => {
          this.updateData();
          this.voted?.();
        });
      }

      approvalResentRequest(approvalId: number) {
        this.AccountsService.approvalResentRequest(approvalId)
          .then((data: any) => {
            notify(data.msg);
          })
          .catch((data: any) => {
            notify(data.data.errors, 'error');
          });
      }
    },
  ],
};
