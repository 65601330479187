import {
  accountsApprovalConfigsReactivateByConfigPartialUpdate,
  accountsApprovalsApproveRetrieve,
  accountsApprovalsDeclineRetrieve,
  accountsApprovalsList,
  financesBalancePaymentPredictionsRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Approval, ApprovalQueryParams } from '../lib';

export class ApprovalRepository extends EntityRepository<Approval, ApprovalQueryParams> {
  readonly entityName = 'accounts.Approval';

  getFn = (_id: number): Promise<Approval> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: ApprovalQueryParams) => {
    const { data } = await accountsApprovalsList({ query });
    return { records: data.results, count: data.count };
  };
  createFn = (_entity: Approval): Promise<Approval> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Approval>): Promise<Approval> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await financesBalancePaymentPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  reactivateByConfig = async (params: {
    approvableId: number;
    approvableContentTypeId: number;
    approvableModelName?: string;
  }) => {
    const { approvableId, approvableContentTypeId, approvableModelName } = params;

    await accountsApprovalConfigsReactivateByConfigPartialUpdate({
      body: {
        approvable_id: approvableId,
        approvable_content_type_id: approvableContentTypeId,
        approvable_model_name: approvableModelName,
      },
    });
  };
  approve = async (id: number) => {
    await accountsApprovalsApproveRetrieve({ path: { id } });
  };
  decline = async (id: number) => {
    await accountsApprovalsDeclineRetrieve({ path: { id } });
  };
}
