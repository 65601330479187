import { formatDate } from '~/shared/lib/date';

import template from './cashflow-table.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const CashflowTable = {
  bindings: {
    filterLevel: '<',
    report: '<',
  },
  template,
  controller: [
    'GtUtils',
    'gtFilterService',
    'CashflowService',
    class {
      CashflowService: any;
      gtFilterService: GtFilterService;
      GtUtils: GtUtilsService;
      business_unit_id?: number;
      filterLevel = 'cashflow-table';
      rebuildTrigger: any;
      report: any;
      constructor(GtUtils: GtUtilsService, gtFilterService: GtFilterService, CashflowService: any) {
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.CashflowService = CashflowService;

        this.rebuildTrigger = false;
      }

      openCashflowDateModal(periodReport: any) {
        return this.CashflowService.openCashflowDateModal(periodReport).then(() => {
          this.GtUtils.overlay('show');
          const dateFrom = periodReport.report_period;
          dateFrom.setDate(dateFrom.getDate() + 1);
          this.CashflowService.recreate({
            date_from: formatDate(dateFrom, 'dd.MM.yyyy'),
            date_to: this.report.end_date,
            currency: this.report.currency_id,
            business_unit: this.business_unit_id,
          })
            .then(() => {
              this.gtFilterService.updateQueryParams({}, this.filterLevel);
              this.GtUtils.overlay('hide');
            })
            .catch((e: any) => {
              this.GtUtils.errorClb(e);
              this.GtUtils.overlay('hide');
            });
        });
      }

      rowIsVisible(
        isFinanceList: any,
        showFinanceList: any,
        isGroupTotal: any,
        showGroupTotal: any,
        isUseTotal: any,
        showUseTotal: any,
      ) {
        return (
          (isFinanceList && showFinanceList && showGroupTotal && showUseTotal) ||
          (isGroupTotal && showGroupTotal && showUseTotal) ||
          (isUseTotal && showUseTotal) ||
          (!isFinanceList && !isGroupTotal && !isUseTotal)
        );
      }

      getIcon(modelName: string) {
        return this.GtUtils.getIcon('clients.' + modelName);
      }
    },
  ],
};
