import type ng from 'angular';

import type { MulticontractService } from './multicontract.service';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { html } from '^/shared/utils';

export class MulticontractFormFieldsService {
  $rootScope: GtRootScopeService;
  $window: ng.IWindowService;
  AccountsService: AccountsService;
  ClientsService: any;
  ContractsService: ContractsService;
  CoreService: CoreService;
  GtUtils: GtUtilsService;
  MulticontractService: MulticontractService;
  gettext: ng.gettext.gettextFunction;
  updateResponsibleForPosition: any;
  static readonly $inject = [
    '$window',
    '$rootScope',
    'gettext',
    'GtUtils',
    'ClientsService',
    'CoreService',
    'ContractsService',
    'AccountsService',
    'MulticontractService',
  ];
  constructor(
    $window: ng.IWindowService,
    $rootScope: GtRootScopeService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    ClientsService: any,
    CoreService: CoreService,
    ContractsService: ContractsService,
    AccountsService: AccountsService,
    MulticontractService: MulticontractService,
  ) {
    this.$window = $window;
    this.$rootScope = $rootScope;
    this.gettext = gettext;
    this.GtUtils = GtUtils;
    this.ClientsService = ClientsService;
    this.CoreService = CoreService;
    this.ContractsService = ContractsService;
    this.AccountsService = AccountsService;
    this.MulticontractService = MulticontractService;
  }

  isRequired(scope: any) {
    return Boolean(
      scope.fields
        .filter((field: any) => field.key === scope.options.key)
        .find((field: any) => field.templateOptions.required),
    );
  }

  getApprovalContractModel(contract: any) {
    const purpose = this.MulticontractService.getContractModelName(contract);
    return 'contracts.' + purpose.charAt(0).toUpperCase() + purpose.slice(1);
  }

  getMulticontractFormConfig(multicontract: any, positions: any, formNamePrefix: any) {
    const col1: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col2: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col3: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col4: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };

    col1.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('GENERAL'),
      },
      fieldGroup: [
        {
          key: 'number',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Number'),
            addon: this.gettext('#'),
            required: multicontract.stage === 'contract',
          },
        },
        {
          key: 'auto_name',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Generate number'),
            hint: this.gettext('Check this if you want number to be auto generated'),
            onChange: () => this.$rootScope.$broadcast('generate-number'),
          },
        },
        {
          key: 'setPositionsNumber',
          template: html`
            <div class="clearfix"></div>
            <div class="container">
              <a
                class="btn btn_success centered"
                style="font-size: 0.7em"
                ng-click="to.broadcastPositinUpdate()"
              >
                <i class="fa fa-angle-double-up"></i> <translate>Set positions number</translate>
              </a>
            </div>
            <br />
          `,
          templateOptions: {
            label: this.gettext('Set positions number'),
            broadcastPositinUpdate: () => this.$rootScope.$broadcast('copy-number-to-positions'),
          },
        },
        {
          key: 'contract_type',
          type: 'gt-select',
          defaultValue: multicontract.use_type === 'services' ? 'purchase' : 'sale',
          templateOptions: {
            label: this.gettext('Type'),
            placeholder: this.gettext('Sale or Purchase'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Purchase'), value: 'purchase' },
              { name: this.gettext('Sale'), value: 'sale' },
            ],
            onSelected: () => {
              [multicontract.buyer, multicontract.supplier] = [
                multicontract.supplier,
                multicontract.buyer,
              ];
            },
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.id',
          },
        },
        {
          key: 'request_status', // if model.stage == "ticket"
          type: 'gt-select',
          defaultValue: 'new',
          templateOptions: {
            label: this.gettext('Status'),
            placeholder: this.gettext('Choose status'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('New'), value: 'new' },
              { name: this.gettext('Approved'), value: 'approved' },
              { name: this.gettext('Rejected'), value: 'rejected' },
              { name: this.gettext('Cancelled'), value: 'cancelled' },
              { name: this.gettext('Executed'), value: 'executed' },
              { name: this.gettext('Processed'), value: 'processed' },
              { name: this.gettext('Sent'), value: 'sent' },
              { name: this.gettext('Lost'), value: 'lost' },
            ],
            disabled: !this.AccountsService.hasPerm('approve_request'),
          },
          hideExpression: () => true,
        },
        {
          key: 'status', // if model.stage == "contract"
          type: 'gt-select',
          defaultValue: 'new',
          templateOptions: {
            label: this.gettext('Status'),
            placeholder: this.gettext('Choose status'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { value: 'new', name: this.gettext('New') },
              { value: 'delivery_period', name: this.gettext('Delivery period') },
              { value: 'shipment', name: this.gettext('Shipment') },
              { value: 'cancelled', name: this.gettext('Cancelled') },
              { value: 'washout', name: this.gettext('Washout') },
              {
                value: 'partial_executed',
                name: this.gettext('Partial executed'),
              },
              { value: 'cargo_executed', name: this.gettext('Cargo executed') },
              { value: 'executed', name: this.gettext('Executed') },
            ],
          },
          hideExpression: () => multicontract.stage === 'ticket',
        },
        {
          key: 'custom_status',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Custom status'),
            placeholder: this.gettext('Choose custom status'),
            resource: 'core.CustomStatus',
            hint: this.gettext('custom status'),
            addFunc: () => {
              this.$window.open('/admin/core/customstatus/add/');
            },
            addIcon: 'fa fa-tasks',
            addPerms: true,
            queryParams: {
              content_type__model: 'multicontract',
            },
          },
        },
        {
          key: 'deal_type',
          type: 'gt-select',
          defaultValue: 'spot',
          templateOptions: {
            label: this.gettext('Deal type'),
            placeholder: this.gettext('Choose type'),
            hint: this.gettext('Spot or Forward'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Spot'), value: 'spot' },
              { name: this.gettext('Forward'), value: 'forward' },
              { name: this.gettext('Brokerage'), value: 'brokerage' },
            ],
          },
        },
        {
          key: 'use_type',
          type: 'gt-select',
          defaultValue: 'commodity',
          templateOptions: {
            label: this.gettext('Use type'),
            placeholder: this.gettext('Choose type'),
            valueProp: 'value',
            labelProp: 'name',
            disabled: true,
            options: [
              { name: this.gettext('Commodity'), value: 'commodity' },
              { name: this.gettext('Intermediate'), value: 'intermediate' },
              { name: this.gettext('Export'), value: 'export' },
              { name: this.gettext('Services'), value: 'services' },
            ],
          },
        },
        {
          key: 'conclusion_date',
          type: 'gt-date-select',
          defaultValue: new Date(),
          templateOptions: {
            label: this.gettext('Conclusion (date)'),
            placeholder: this.gettext('date'),
            required: true,
            disabled: this.$rootScope.user.settings.DISABLE_CONCLUSION_DATE,
            ...(multicontract?.conclusionDateRange || {}),
          },
          validation: {
            show: true,
          },
        },
        {
          key: 'internal_chain',
          type: 'gt-checkbox',
          defaultValue: false,
          templateOptions: {
            label: this.gettext('Internal chain'),
            hint: this.gettext('Check this if you want to use it as internal chain'),
          },
        },
        {
          key: 'business_unit',
          type: 'gt-ui-select',
          defaultValueResolve: () => this.CoreService.getDefaultBuId(multicontract),
          templateOptions: {
            label: this.gettext('Business unit'),
            placeholder: this.gettext('Business Unit'),
            queryParams: this.GtUtils.getBUQueryParams(),
            resource: 'core.BusinessUnit',
            onSelect: () => {
              this.$rootScope.$broadcast('buisness-unit-changed');
            },
          },
        },
        {
          template: html`
            <custom-values-container
              filter-level="'multicontract-custom-values-container'"
              init-query-params="{
                      object_id: model.id,
                      purpose: model.content_type,
                      purpose_model: 'multi'+model.stage,
                    }"
              mode="model.id ? 'edit' : 'create'"
              object-id="model.id"
            ></custom-values-container>
          `,
        },
      ],
    });

    col2.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('COUNTERPARTIES'),
      },
      fieldGroup: [
        {
          key: 'buyer',
          type: 'gt-ui-select',
          defaultValue:
            multicontract.contract_type === 'sale'
              ? undefined
              : this.$rootScope.user.settings.DEFAULT_VALUES.owner,
          templateOptions: {
            label:
              multicontract.contract_type === 'sale'
                ? this.gettext('Buyer')
                : this.gettext('Buyer/Owner'),
            addFunc:
              multicontract.contract_type === 'sale'
                ? () => this.ClientsService.roleModal({ model_name: 'Buyer' })
                : () => this.ClientsService.roleModal({ model_name: 'Owner' }),
            addIcon:
              multicontract.contract_type === 'sale'
                ? this.GtUtils.getIcon('clients.Buyer')
                : this.GtUtils.getIcon('clients.Owner'),
            addPerms: multicontract.contract_type === 'sale' ? ['add_buyer'] : ['add_owner'],
            title:
              multicontract.contract_type === 'sale'
                ? multicontract.buyer_name
                : multicontract.owner_name,
          },
          expressionProperties: {
            'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
              this.isRequired(scope),
            'templateOptions.resource': (viewValue: any, modelValue: any, scope: any) => {
              if (scope.model.contract_type === 'sale' && scope.model.deal_type === 'services') {
                return 'clients.ClientRole';
              }
              return this.ClientsService.getClientResource(
                scope.options.key,
                scope.model.contract_type,
              );
            },
          },
        },
        {
          key: 'supplier',
          type: 'gt-ui-select',
          defaultValue:
            multicontract.contract_type === 'purchase'
              ? undefined
              : this.$rootScope.user.settings.DEFAULT_VALUES.owner,
          templateOptions: {
            label:
              multicontract.contract_type === 'purchase'
                ? this.gettext('Supplier')
                : this.gettext('Supplier/Owner'),
            addFunc:
              multicontract.contract_type === 'purchase'
                ? () => this.ClientsService.roleModal({ model_name: 'Supplier' })
                : () => this.ClientsService.roleModal({ model_name: 'Owner' }),
            addIcon:
              multicontract.contract_type === 'purchase'
                ? this.GtUtils.getIcon('clients.Supplier')
                : this.GtUtils.getIcon('clients.Owner'),
            addPerms: multicontract.contract_type === 'purchase' ? ['add_supplier'] : ['add_owner'],
            title:
              multicontract.contract_type === 'purchase'
                ? multicontract.supplier_name
                : multicontract.owner_name,
          },
          expressionProperties: {
            'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
              this.isRequired(scope),
            'templateOptions.resource': (viewValue: any, modelValue: any, scope: any) => {
              if (
                (scope.model.contract_type === 'purchase' && scope.model.use_type === 'services') ||
                scope.model.use_type === 'intermediate' ||
                scope.model.use_type === 'export'
              ) {
                return 'clients.ClientRole';
              }
              return this.ClientsService.getClientResource(
                scope.options.key,
                scope.model.contract_type,
              );
            },
            'templateOptions.queryParams': (viewValue: any, modelValue: any, scope: any) => {
              if (scope.model.use_type === 'intermediate') {
                return this.$rootScope.user.settings.SUPPLIER_MUST_BE_OWNER
                  ? { role_name_list: ['owner'] }
                  : { role_name_list: ['supplier', 'owner'] };
              } else if (scope.model.use_type === 'export') {
                return { own_supplier_or_owner: '1' };
              } else {
                return null;
              }
            },
          },
        },
        {
          key: 'guarantor',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('guarantor'),
            placeholder: this.gettext('guarantor'),
            resource: 'clients.Other',
            title: multicontract.guarantor_name,
          },
        },
        {
          key: 'ignore_client_status',
          type: 'gt-checkbox',
          defaultValue:
            this.$rootScope.user.settings.DEFAULT_VALUES.ignore_client_compliance_status,
          templateOptions: {
            label: this.gettext('Ignore client status '),
            hint: this.gettext('Ignore counterparty status'),
            addPerms: ['can_ignore_client_compliance_status'],
          },
          hideExpression: () =>
            !this.AccountsService.hasPerm('can_ignore_client_compliance_status'),
        },
        {
          key: 'ignore_limits',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Ignore limits '),
            hint: this.gettext('Ignore counterparty limits'),
          },
          hideExpression: ($viewValue: any, $modelValue: any, scope: any) => {
            return scope.$root.user.settings.CONTRACTS_LIMIT_ACTION === 'forbid';
          },
          expressionProperties: {
            'templateOptions.required': ($viewValue: any, $modelValue: any, scope: any) => {
              if (scope.$root.user.settings.CONTRACTS_LIMIT_DEFAULT) {
                scope.model.ignore_limits = true;
                return scope.model.ignore_limits;
              } else {
                return scope.to.required;
              }
            },
          },
        },
      ],
    });

    col3.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('CARGO'),
      },
      fieldGroup: [
        {
          key: 'volume',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Volume'),
            placeholder: this.gettext('Type volume'),
            type: 'number',
            addon: this.gettext('t'),
          },
        },
        {
          key: 'positions_volume_sum',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Volume sum from positions'),
            disabled: true,
            type: 'number',
            addon: this.gettext('t'),
          },
        },
        {
          key: 'positions_final_volume_sum',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Final volume sum from positions'),
            disabled: true,
            type: 'number',
            addon: this.gettext('t'),
          },
          hideExpression: () => multicontract.stage === 'ticket',
        },
        {
          key: 'is_positions_volume_by_execution',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Volume in positions from execution'),
            hint: this.gettext('Volume in positions from execution'),
          },
          hideExpression: () => multicontract.stage === 'ticket',
        },
        {
          key: 'volume_options_company',
          type: 'gt-select',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.volume_options_company,
          templateOptions: {
            label: this.gettext('Option'),
            placeholder: this.gettext('option'),
            hint: this.gettext('Choose who decide the option'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Buyer'), value: 'buyer' },
              { name: this.gettext('Seller'), value: 'seller' },
            ],
          },
        },
        {
          key: 'budget',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Budget'),
            resource: 'finances.Budget',
            addFunc: () => {
              this.$window.open('/admin/finances/budget/add/');
            },
            addPerms: true,
            addIcon: 'fa fa-dollar',
            title: multicontract.budget_title,
          },
        },
      ],
    });

    col4.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('SIGNING DETAILS'),
      },
      fieldGroup: [
        {
          key: 'approval_config',
          type: 'gt-ui-select',
          defaultValueResolve: () => {
            return this.AccountsService.getDefaultApprovalConfigId(
              this.getApprovalContractModel(multicontract),
            );
          },
          templateOptions: {
            label: this.gettext('Approval Config'),
            resource: 'accounts.ApprovalConfig',
            addFunc: () => {
              this.$window.open('/admin/accounts/approvalconfig/');
            },
            addIcon: 'fa fa-cogs',
            addPerms: true,
            queryParams: () => {
              return {
                object_type: this.getApprovalContractModel(multicontract),
                is_active: 1,
                bu_list: multicontract.business_unit,
              };
            },
          },
        },
        {
          key: 'general_agreement',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('General Agreement'),
            resource: 'contracts.GeneralAgreement',
            addFunc: () => {
              return this.ContractsService.generalAgreementsModal();
            },
            addIcon: 'fa fa-handshake-o',
            addPerms: true,
            queryParams: () => {
              const queryParams: QueryParams = {
                agreement_type: multicontract.contract_type,
              };

              if (
                multicontract.contract_type === 'purchase' ||
                multicontract.contract_type === 'sale'
              ) {
                queryParams.buyer = multicontract.buyer;
                queryParams.supplier = multicontract.supplier;
              }

              if (multicontract.use_type === 'commodity') {
                queryParams.deal_type = 'spot';
              } else {
                queryParams.deal_type = multicontract.use_type;
              }

              return queryParams;
            },
          },
        },
        {
          key: 'type_of_activities',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Type of activities'),
            resource: 'core.TypeOfActivity',
            hint: this.gettext('If you want to add type or activity to this company'),
            addFunc: () => this.$window.open('/admin/core/typeofactivity/add/'),
            addPerms: true,
            addIcon: 'fa fa-tasks',
          },
        },
        {
          key: 'is_according_to_contract_template',
          type: 'gt-select',
          templateOptions: {
            label: this.gettext('According to contract template'),
            options: [
              { title: this.gettext('---'), id: undefined },
              { title: this.gettext('Yes'), id: true },
              { title: this.gettext('No'), id: false },
            ],
          },
          hideExpression: () => multicontract.stage === 'ticket',
        },
        {
          key: 'documents',
          type: 'gt-documents',
          templateOptions: {
            label: this.gettext('Multi Contract document'),
            deleteEventSource: 'multicontract-modal',
            connectedField: 'is_according_to_contract_template',
            onFileSelect: (files: any) => this.$rootScope.$broadcast('on-file-selected', files),
            mode: 'contract',
          },
          hideExpression: () => multicontract.is_according_to_contract_template !== false,
        },
        {
          key: 'responsible',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.id,
          templateOptions: {
            label: this.gettext('Responsible'),
            resource: 'auth.User',
            onChange: (newValue: any, position: { id: number }) => {
              this.updateResponsibleForPosition(position.id, newValue);
            },
          },
        },
        {
          template: html`
            <div style="margin: 8px 0">
              <work-status-display
                status="to.responsibleWorkStatus"
              </work-status-display>
            </div>
          `,
          hideExpression: () =>
            !multicontract.responsible || !this.$rootScope.user.settings.USE_WORK_STATUS,
          expressionProperties: {
            'templateOptions.responsibleWorkStatus': (
              viewValue: any,
              modelValue: any,
              scope: any,
            ) => {
              return scope.model.responsibleWorkStatus;
            },
          },
        },

        {
          key: 'responsible_for_signing',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.id,
          templateOptions: {
            label: this.gettext('Responsible for signing'),
            resource: 'auth.User',
          },
        },
        {
          template: html`
            <div style="margin: 8px 0">
              <work-status-display
                status="to.responsibleForSigningWorkStatus"
              </work-status-display>
            </div>
          `,
          hideExpression: () =>
            !multicontract.responsible_for_signing ||
            !this.$rootScope.user.settings.USE_WORK_STATUS,
          expressionProperties: {
            'templateOptions.responsibleForSigningWorkStatus': (
              viewValue: any,
              modelValue: any,
              scope: any,
            ) => {
              return scope.model.responsibleForSigningWorkStatus;
            },
          },
        },
        {
          key: 'additional_info',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Additional Info'),
            placeholder: this.gettext('Specific information about this object'),
            className: 'additional-info',
          },
        },
      ],
    });

    return {
      formName: formNamePrefix + multicontract.stage + '-edit-form',
      fieldsDef: [col1, col2, col3, col4],
    };
  }
}
