import React from 'react';

import { cn } from '~/shared/lib/utils';
import type { DataType } from '~/shared/ui/data-types';
import { ArrowBottomIcon, CheckIcon } from '~/shared/ui/icons';
import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';

import type { FilterItemProps } from '../../types';

export const OperatorSelect = <T extends DataType>({
  option,
  filter,
  onChange,
}: FilterItemProps<T>) => {
  return (
    <div className="flex items-center gap-1 px-2 py-1">
      <p className="text-text-main-primary text-xs font-medium">{option.title}</p>
      <Popover>
        <PopoverTrigger className="text-text-main-tertiary flex items-center gap-0.5 text-xs outline-none">
          <p>{filter?.operator}</p>
          <ArrowBottomIcon size={14} className="text-stroke-main-stronger pt-0.5" />
        </PopoverTrigger>
        <PopoverContent className="flex w-[200px] flex-col gap-0.5 p-1">
          {option?.operatorOptions.map((operator) => (
            <button
              key={operator}
              className={cn(
                'hover:bg-transparent-light flex w-full cursor-pointer items-center justify-between rounded-sm px-2 py-1 transition duration-300 ease-in-out',
                operator === filter?.operator && 'bg-transparent-light',
              )}
              onClick={() => onChange({ ...filter, operator })}
            >
              <p className="text-text-main-primary text-xs">{operator}</p>
              {operator === filter?.operator && (
                <CheckIcon size={14} className="text-text-main-secondary" />
              )}
            </button>
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
};
