import React from 'react';

import { DropdownMenu, DropdownMenuTrigger } from '~/shared/ui/kit/dropdown-menu';
import { Tag } from '~/shared/ui/kit/tag';

export const MultiSelect: React.FC<{
  value?: { value: string; label: string; tagProps?: object }[];
  disabled?: boolean;
  placeholder?: string;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  children?: React.ReactNode;
}> = ({ value, disabled, isOpen, onOpenChange, children }) => {
  return (
    <DropdownMenu open={isOpen} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger className="flex min-h-[24px] w-full items-center gap-2">
        {value && value.length > 0 ? (
          <>
            {value.slice(0, 2).map((item) => (
              <Tag key={item.value} disabled={disabled} {...item.tagProps}>
                {item.label}
              </Tag>
            ))}
            {value.length > 2 && (
              <Tag variant="gray" disabled={disabled}>
                +{value.length - 2}
              </Tag>
            )}
          </>
        ) : (
          <span className="h-[24px] w-full min-w-[40px]"></span>
        )}
      </DropdownMenuTrigger>
      {children && <>{children}</>}
    </DropdownMenu>
  );
};
