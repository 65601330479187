import { logisticTotalsMaxChunkSize } from './config';
import type { BaseLogisticListTotals, LogisticQueryParams } from './types';

export const chunkQuery = (query: LogisticQueryParams): LogisticQueryParams[] => {
  if (!query.id_list || query.id_list.length <= logisticTotalsMaxChunkSize) {
    return [query];
  }

  return Array.from(
    { length: Math.ceil(query.id_list.length / logisticTotalsMaxChunkSize) },
    (_, index) => ({
      ...query,
      id_list: query.id_list?.slice(
        index * logisticTotalsMaxChunkSize,
        (index + 1) * logisticTotalsMaxChunkSize,
      ),
    }),
  );
};

export const concatListTotalsChunks = async <
  TListTotals extends BaseLogisticListTotals = BaseLogisticListTotals,
>(
  listTotals: Promise<BaseLogisticListTotals>[],
): Promise<TListTotals> => {
  const totalsArray = await Promise.all(listTotals);
  return totalsArray.reduce<Record<string, number>>((acc, totals) => {
    Object.entries(totals).forEach(([key, value]) => {
      if (typeof value === 'number') {
        acc[key] = (acc[key] ?? 0) + value;
      }
    });
    return acc;
  }, {}) as TListTotals;
};
