import type ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';
import { notify } from '~/shared/lib/notify';

import template from './contract-list-table-view.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { html } from '^/shared/utils';

export const ContractListTableView = {
  bindings: {
    contracts: '<',
    count: '<',
    filterLevel: '<',
    tableName: '<',
    total: '<',
    activeTab: '<',
    activeReportConfig: '<',
    applyFilters: '&',
    openContractModal: '&',
    cloneContract: '&',
    openEmailModal: '&',
    openDocxModal: '&',
    openDocumentModal: '&',
    approveContract: '&',
    addLogistic: '&',
    addFinance: '&',
    addBalanceInvoice: '&',
    createServiceContract: '&',
    reverseContract: '&',
    createPassport: '&',
    connectToPassport: '&',
    openVoyageModal: '&',
    openRoleModal: '&',
    openStageModal: '&',
    setFinalVolumeFromExecution: '&',
    openMulticontractModal: '&',
    createUpdateRequest: '&',
    extraFields: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    'AccountsService',
    'ContractsService',
    'GtUtils',
    'gtFilterService',
    class {
      $rootScope: GtRootScopeService;
      GtUtils: GtUtilsService;
      AccountsService: AccountsService;
      ContractsService: ContractsService;
      activeReportConfig: any;
      activeTab: string;
      addBalanceInvoice: any;
      addFinance: any;
      addLogistic: any;
      applyFilters: any;
      cloneContract: any;
      connectToPassport: any;
      contracts: any;
      count: number;
      createPassport: any;
      createServiceContract: any;
      createUpdateRequest: any;
      extraFields: any;
      filterLevel = 'purchase-contracts-page-view';
      fullQueryParams: Record<string, string> = {};
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      openContractModal: any;
      openDocumentModal: any;
      openDocxModal: any;
      openEmailModal: any;
      openMulticontractModal: any;
      openRoleModal: any;
      openStageModal: any;
      openVoyageModal: any;
      queryParams: QueryParams = {};
      reverseContract: any;
      setFinalVolumeFromExecution: any;
      tableData: any;
      tableName?: string;
      tableOptions: any;
      total: any;
      updateData: any;
      constructor(
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        AccountsService: AccountsService,
        ContractsService: ContractsService,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
      ) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.AccountsService = AccountsService;
        this.ContractsService = ContractsService;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.contracts = [];
        this.count = 0;

        this.tableOptions = {};
        this.tableData = {};
        this.queryParams = { serializer: 'table_info' };
        this.activeTab = 'table_info';
        this.extraFields = [];
      }

      $onInit() {
        this.contracts = this.contracts || [];
        this.count = this.count || 0;
        this.activeTab = this.activeTab || 'table_info';
        this.extraFields = this.extraFields || [];
        this.fullQueryParams = this.gtFilterService.getQueryParams(this.filterLevel) as Record<
          string,
          string
        >;
        this.tableName =
          this.tableName ?? `${this.fullQueryParams.contract_type}-contract-list-table-view`;
        this.setTableOptions().catch(errorHandler);
      }

      $onChanges(changes: any) {
        if (changes.activeReportConfig?.currentValue) {
          this.setTableOptions().catch(errorHandler);
        }

        if (changes.contracts || changes.count || changes.total) {
          this.tableData = { rows: this.contracts, count: this.count, total: this.total };
        }
      }

      setTableOptions() {
        const showPrepaymentAccounting =
          this.$rootScope.user.settings.SHOW_PREPAYMENT_ACCOUNTING === true &&
          this.filterLevel === 'purchase-contracts-page-view';
        const showReconciliationExecutionReports =
          this.filterLevel !== 'export-contracts-page-view';
        const options: any = {
          tableName: this.tableName,
          tableClass: 'request-table',
          tableAltClass: 'request-table sticky-table-first-td',
          filterLevel: this.filterLevel,
          applyFilters: this.applyFilters,
          activeTab: this.activeTab,
          activeReportConfig: this.activeReportConfig,
          templateArgs: {
            openContractModal: (contract: any) => this.openContractModal({ contract: contract }),
            cloneContract: (contract: any) => this.cloneContract({ contract: contract }),
            cancelContract: (contract: any) =>
              this.ContractsService.cancelDeal(contract).then(() => {
                notify(this.gettext('Contract cancelled'));
                this.updateData();
              }),
            openEmailModal: (contract: any) => this.openEmailModal({ contract: contract }),
            openDocxModal: (contract: any) => this.openDocxModal({ contract: contract }),
            openDocumentModal: (contract: any) => this.openDocumentModal({ contract: contract }),
            approvalAction: (action: any, contractId: number, contractType: any) =>
              this.AccountsService.voteApprovable(
                action,
                contractId,
                null,
                `contracts.${contractType}contract`,
              ).then(() => this.applyFilters()),
            addLogistic: (contract: any) => this.addLogistic({ contract: contract }),
            addFinance: (contract: any, cond: any, invoiceStatus: any) => {
              this.addFinance({ contract: contract, cond: cond, invoiceStatus: invoiceStatus });
            },
            addBalanceInvoice: (contract: any) => {
              this.addBalanceInvoice({ contract: contract });
            },
            reverseContract: (contract: any) => this.reverseContract({ contract: contract }),
            createPassport: (contract: any) => this.createPassport({ contract: contract }),
            createServiceContract: (contract: any) =>
              this.createServiceContract({ contract: contract }),
            connectToPassport: (contract: any) =>
              this.connectToPassport({ contract: contract, passportId: contract.$_passportId }),
            openVoyageModal: (voyage: any) => this.openVoyageModal({ voyage: voyage }),
            openRoleModal: (id: number, model: any) =>
              this.openRoleModal({ roleId: id, roleModel: model }),
            openStageModal: (stage: any) => this.openStageModal({ stage: stage }),
            openMulticontractModal: (contract: any) =>
              this.openMulticontractModal({ contract: contract }),
            setFinalVolumeFromExecution: (contractId: number) =>
              this.setFinalVolumeFromExecution({ contractId: contractId }),
            createUpdateRequest: () => this.createUpdateRequest(),
            statusClass: {
              washout: 'contract_calculate',
              new: 'contract_new',
              cancelled: 'contract_cancelled',
              executed: 'contract_executed',
              shipment: 'contract_processing',
              delivery_period: 'contract_processing',
              cargo_executed: 'contract_processing',
              draft: 'label_info',
            },
            measurementUnit: this.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT,
            defaultCurrency: this.$rootScope.user.settings.DEFAULT_CURRENCY,
            editContractPermission:
              this.filterLevel !== 'services-direct-contracts-page-view'
                ? 'change_contractbase'
                : 'change_servicescontract',
          },
          columnDefs: [] as any[],
          tabs: [],
          configurable: true,
          showMore: true,
          changePageSize: true,
        };

        options.tabs = [
          {
            serializer: 'table_info',
            title: this.gettext('Info'),
            icon: 'fa-info-circle',
            columns: [
              'number',
              'status',
              'contract_type',
              'folder_number',
              'custom_status',
              'multicontract',
              'consignees',
              'approval_status',
              'signature',
              'passport_status',
              'shipment_status',
              'finance_status',
              'docs_status',
              'properties',
              'ticket',
              'price_indicative',
              'price_indicative_with_vat',
              'price_indicative_default_currency',
              'parent_indicator',
              'parent_indicator_price',
              'vessel',
              'pass',
              'passports',
              'not_allocated',
              'paymentEvents',
              'concluded',
              'buyer',
              'supplier',
              'broker',
              'agent',
              'cargo',
              'measurement',
              'quantity_of_measurement',
              'volume',
              'options',
              'final_volume',
              'price',
              'costs',
              'currency_exchange_rate',
              'total_value',
              'basis',
              'origin',
              'origins_of_crop',
              'destination',
              'destinations_of_crop',
              'port',
              'market_zone',
              'execution',
              'arrival_period',
              'business_unit',
              'fixed_price_bonus',
              'regional_managers',
              'vehicle_restrictions',
              'bill_of_ladings',

              'info',
              'editor',
              'responsible',
              'responsible_for_signing',
              'generalagreement',
              'derivative',

              'analytical_price',
              'analytical_value',
              'analytical_diff',
              'documents',
              'conclusion_price',

              'customs',
              'saleContractByPassportBuyers',
              'intermediateContractByPassportBuyers',
              'periodicCustomsDeclaration',
              'exporter',
              'saleVolume',
              'saleLogisticVolumes',
              'exportVolume',
              'freeExportQuota',
              'logistic_executed_volume',
              'free_export_volume',
              'unfulfilled_volume',
              'days_for_invoice_payment',
              'free_volume_pcd',
              'pre_pcd_logistic_volume',
              'no_customs_logistics',
              'volume_without_pcd',
              'executed_export_volume',
              'sale_export_logistic_data',
              'openExportVolume',
              'toCustomVolume',
              'openQuote',
              'ccdVolume',
              'pcdVolume',
              'loses',
              'pcdExecution',
              'logisticsTerminalVolumes',
              'blsLossesVolume',
              'logisticsLossesVolume',
              'paymentConditionsOptions',
              'customsTerminalsTotalVolume',
              'customsTerminalsCustomsClearedVolume',
              'customsTerminalsCustomsNotClearedVolume',
              'loadingShortage',

              'transport_volume_departed_consignment',
              'transport_volume_departed_real',
              'transport_volume_departed',
              'volume_boarded',
              'volume_received',
              'billofladings_volume_sum',
              'billofladings_unloading_volume_sum',

              'physicalMargin',
              'derivativeMargin',
              'mtmMargin',
              'totalMargin',
              'analyticalPhysicalMargin',
              'analyticalDerivativeMargin',
              'analyticalMtmMargin',
              'analyticalTotalMargin',
              'costDetails',
              'type_of_activities',
              'company_group',
              'commodity_last_parent',
              'commodity_last_parent_analytic',
              'cp_approval_status',
              'cp_custom_status',
              'payment_status',
              'warehouse',
              'warehouse_consignments',
              'transport_volume_basis',
            ],
          },
          {
            serializer: 'table_reconciliation',
            title: this.gettext('Reconciliation'),
            icon: 'fa-check-circle-o',
            permission: showReconciliationExecutionReports,
            columns: [
              'number',
              'status',
              'contract_type',
              'basis',
              'folder_number',
              'custom_status',
              'consignees',
              'cargo',
              'buyer',
              'supplier',
              'execution',
              'volume',
              'options',
              'final_volume',
              'open',
              'min_batch_of_payment',
              'transport_volume_basis',
              'pass',
              'price',
              'execution_price',
              'invoice_price',
              'total_value',
              'execution_value',
              'invoiced',
              'not_invoiced',
              'paid',
              'balance',
              'balance_payment',
              'debit_credit',
              'conditions',
              'cargo_confirm',
              'payment_confirm',
              'final_confirm',
              'approvals',
              'transport_open_balance_volume',
              'mtm_price',
              'selfcost',
              'vessel',
              'quality',
              'loadingShortage',
              'warehouse',
            ],
          },
          {
            serializer: 'table_execution',
            title: this.gettext('Execution'),
            icon: 'fa-truck',
            permission: showReconciliationExecutionReports,
            columns: [
              'number',
              'status',
              'contract_type',
              'basis',
              'cargo',
              'folder_number',
              'consignees',
              'buyer',
              'supplier',
              'execution',
              'volume',
              'options',
              'final_volume',
              'open',
              'transport_volume_basis',
              'price',
              'total_value',
              'execution_value',
              'invoiced',
              'not_invoiced',
              'paid',
              'balance',
              'balance_payment',
              'debit_credit',
              'conditions',
              'cargo_confirm',
              'payment_confirm',
              'final_confirm',
              'approvals',
              'quality',
              'blsLossesVolume',
              'logisticsLossesVolume',
              'loadingShortage',
              'open_balance',
              'transport_volume_departed_consignment',
              'transport_volume_departed_real',
              'transport_volume_departed',
              'volume_boarded',
              'volume_received',
              'billofladings_volume_sum',
              'billofladings_unloading_volume_sum',
              'warehouse',
              'warehouse_consignments',
            ],
          },
          {
            serializer: 'table_prepayments_accounting',
            title: this.gettext('CP/Seeds Prepayments'),
            icon: 'fa-train',
            permission: showPrepaymentAccounting,
            columns: [
              'number',
              'status',
              'contract_type',
              'folder_number',
              'consignees',
              'concluded',
              'buyer',
              'supplier',
              'cargo',
              'volume',
              'options',
              'final_volume',
              'price',
              'total_value',
              'ticket',
              'contract_option_title',
              'percentage',
              'custom_status',
              'plan_distributors',
              'request_crop_protection_amount',
              'request_seeds_amount',
              'fact_distributors',
              'fact_crop_protection_sum',
              'fact_seeds_sum',
              'financeoffset_list',
              'reassignment_list',
              'district',
              'financeoffset_value_sum',
              'reassignment_value_sum',
              'dbls_sum_volume',
              'dbls_sum_amount',
              'warehouse',
            ],
          },
        ];

        options.columnDefs = [
          {
            columnName: 'number',
            title: this.gettext('number'),
            predicate: 'contract_number',
            showAlways: true,
            classExpr: 'args.statusClass[item.status]',
            hint: this.gettext(
              'Status of te deal: NEW - recently created, SHIPMENT - the execution has been started, EXECUTED - the Contract is closed and executed, CANCELLED - rejected by the manager ',
            ),

            cellTemplate: html`
              <div class="space-between">
                <ul class="nav navbar-nav item-settings-dropdown">
                  <li>
                    <a
                      class="dropdown-toggle user-dropdown-btn"
                      ui-sref="gt.page.contract({id: item.id })"
                      class="btn btn-xs aligned-btn"
                      ng-click="$root.gtUtils.dismissAllModals();"
                      ng-if="item.status !== 'draft'"
                    >
                      <span class="initial-transform">
                        <i class="fa fa-file-text"></i> {[{item.contract_number | cut:true:40:'...'
                        || "---" }]}
                      </span>
                      <i class="fa fa-arrow-right hover-element hide-on-bounce"></i>
                      <i class="fa fa-arrow-right fa-bounce bounce-on-hover"></i>
                    </a>
                    <a
                      class="dropdown-toggle user-dropdown-btn"
                      class="btn btn-xs aligned-btn"
                      ng-if="item.status === 'draft'"
                    >
                      <span class="initial-transform">
                        <i class="fa fa-file-text"></i> {[{item.contract_number | cut:true:40:'...'
                        || "---" }]}
                      </span>
                    </a>
                  </li>
                </ul>
                <ul
                  class="nav navbar-nav item-settings-dropdown actions-btn pull-right"
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                >
                  <li>
                    <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                      <i class="fa fa-ellipsis hide-on-hover"></i>
                      <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                    </a>
                    <ul
                      class="dropdown-menu"
                      ng-class="{ 'show-menu': item.$_passportDropdownOpened }"
                    >
                      <li>
                        <a
                          ng-if="item.status !== 'draft'"
                          class="btn btn-xs col-xs-12"
                          ui-sref="gt.page.contract({id: item.id })"
                        >
                          <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="args.editContractPermission"
                          class="btn btn-xs aligned-btn"
                          ng-disabled="item.status == 'executed'"
                          ng-click="args.openContractModal(item)"
                        >
                          <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'edit_closed_elements'"
                          class="btn btn-xs aligned-btn"
                          ng-click="args.openContractModal(item)"
                        >
                          <i class="fa fa-user-secret"></i> <translate>Edit (admin)</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'can_clone_contract'"
                          ng-click="args.cloneContract(item)"
                          class="btn btn-xs aligned-btn"
                        >
                          <i class="fa fa-files-o"></i> <translate>Clone</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'cancel_request'"
                          class="btn btn-xs col-xs-12 tooltip"
                          data-tip="{[{ 'Cancel'|translate }]}"
                          ng-click="args.cancelContract(item);"
                        >
                          <i class="fa fa-times"></i> <translate>Cancel contract</translate>
                        </a>
                      </li>
                      <li
                        class="horizontal-divider"
                        ng-if="item.approval_config && $root.user.settings.SYSTEM_BLOCKS['block_crm_approvals']"
                      ></li>
                      <li
                        ng-if="item.approval_config && $root.user.settings.SYSTEM_BLOCKS['block_crm_approvals']"
                      >
                        <a
                          class="dropdown-toggle user-dropdown-btn"
                          data-toggle="dropdown"
                          ng-click="item.DropdowApprove = !item.DropdowApprove"
                        >
                          <span class="initial-transform">
                            <i class="fa fa-thumbs-up"></i> <translate>Approval</translate>
                          </span>
                          <i class="fa fa-sort-desc hover-element"></i>
                        </a>
                      </li>
                      <li
                        ng-if="item.approval_config && $root.user.settings.SYSTEM_BLOCKS['block_crm_approvals'] && item.DropdowApprove"
                      >
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-click="args.approvalAction('approve', item.id, item.contract_type );"
                        >
                          <i class="fa fa-thumbs-up"></i> <translate>Approve</translate>
                        </a>
                      </li>
                      <li
                        ng-if="item.approval_config && $root.user.settings.SYSTEM_BLOCKS['block_crm_approvals'] && item.DropdowApprove"
                      >
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-click="args.approvalAction('decline', item.id, item.contract_type);"
                        >
                          <i class="fa fa-thumbs-down"></i> <translate>Decline</translate>
                        </a>
                      </li>
                      <li
                        ng-if="item.approval_config && $root.user.settings.SYSTEM_BLOCKS['block_crm_approvals'] && item.DropdowApprove"
                      >
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-disabled="item.approval_status != 'rejected'"
                          ng-click="args.approvalAction('reactivate', item.id, item.contract_type );"
                        >
                          <i class="fa fa-refresh"></i> <translate>Reactivate approving</translate>
                        </a>
                      </li>
                      <li class="horizontal-divider"></li>
                      <li ng-if="item.status !== 'draft'">
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Generate an email'|translate }]}"
                          ng-click="args.openEmailModal(item)"
                        >
                          <i class="fa fa-envelope"></i> <translate>Create an email</translate>
                        </a>
                      </li>
                      <li ng-if="item.status !== 'draft'">
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Generate document'|translate }]}"
                          ng-click="args.openDocxModal(item)"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-file" aria-hidden="true"></i>
                          <translate>Download a template</translate>
                        </a>
                      </li>
                      <li ng-if="item.status !== 'draft'">
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Add document'|translate }]}"
                          ng-click="args.openDocumentModal(item)"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-upload"></i> <translate>Upload a document</translate>
                        </a>
                      </li>
                      <li class="horizontal-divider"></li>

                      <li
                        ng-if="$root.user.settings.SYSTEM_BLOCKS['block_execution_logistics'] && item.status !== 'draft'"
                      >
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Add logistic'|translate }]}"
                          ng-click="args.addLogistic(item)"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-truck"></i> <translate>Create transport</translate>
                        </a>
                      </li>
                      <li ng-if="item.status !== 'draft'">
                        <a
                          class="dropdown-toggle user-dropdown-btn"
                          data-toggle="dropdown"
                          ng-click="item.DropdownInvoices = !item.DropdownInvoices"
                        >
                          <span class="initial-transform">
                            <i class="fa fa-credit-card-alt"></i> <translate>Invoices</translate>
                          </span>
                          <i class="fa fa-sort-desc hover-element"></i>
                        </a>
                      </li>
                      <li ng-if="item.DropdownInvoices">
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Add finance'|translate }]}"
                          ng-click="args.addFinance(item, 'payment_conditions')"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-credit-card-alt"></i>
                          <translate>Create invoice</translate>
                        </a>
                      </li>
                      <li ng-if="item.DropdownInvoices">
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Create Prepayment Invoice'|translate }]}"
                          ng-click="args.addFinance(item, 'payment_conditions', 'prepayment')"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-credit-card-alt"></i>
                          <translate>Create Prepayment Invoice</translate>
                        </a>
                      </li>
                      <li ng-if="item.DropdownInvoices">
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Create Balance Invoice'|translate }]}"
                          ng-click="args.addBalanceInvoice(item)"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-credit-card-alt"></i>
                          <translate>Create Balance Invoice</translate>
                        </a>
                      </li>

                      <li
                        ng-if="
                        item.deal_type !='intermediate' &&
                        item.deal_type !='services' &&
                        item.deal_type !='export' &&
                        item.status !== 'draft'
                      "
                      >
                        <a
                          class="dropdown-toggle user-dropdown-btn"
                          ng-click="item.DropdownPassport = !item.DropdownPassport"
                          ng-disabled="item.readonly"
                        >
                          <span class="initial-transform">
                            <i class="fa fa-exchange"></i> <translate>Allocation</translate>
                          </span>
                          <i class="fa fa-sort-desc hover-element"></i>
                        </a>
                      </li>
                      <li ng-if="item.DropdownPassport">
                        <a
                          ng-click="args.reverseContract(item)"
                          permission
                          permission-only="'add_contractbase'"
                          class="btn btn-xs aligned-btn tooltip"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-exchange"></i>
                          <span ng-if="item.contract_type == 'sale'">
                            <translate>Connect with purchase contract</translate>
                          </span>
                          <span ng-if="item.contract_type == 'purchase'">
                            <translate>Connect with sale contract</translate>
                          </span>
                        </a>
                      </li>
                      <li ng-if="item.DropdownPassport">
                        <a
                          ng-click="args.createPassport(item)"
                          class="btn btn-xs aligned-btn tooltip"
                          permission
                          permission-only="'add_passport'"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-exchange"></i>
                          <translate>Create passport with this contract</translate>
                        </a>
                      </li>
                      <li ng-if="item.DropdownPassport">
                        <a
                          ng-if="!item.$_passportConnectShow"
                          ng-click="item.$_passportConnectShow = true"
                          class="btn btn-xs aligned-btn tooltip"
                          permission
                          permission-only="'add_passport'"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-exchange"></i>
                          <translate>Connect contract to existing passport</translate>
                        </a>
                      </li>
                      <li ng-if="item.$_passportConnectShow">
                        <gt-resource-select
                          ng-if="$root.user.settings.PASSPORT_CHECK_CROP"
                          ng-model="item.$_passportId"
                          placeholder="'Passport' | translate"
                          resource-name="'passports.passport'"
                          allow-clear="true"
                          on-select="args.connectToPassport"
                          on-select-args="[item]"
                          query-params="{ cargo: item.cargo }"
                          on-open-close="item.$_passportDropdownOpened = !item.$_passportDropdownOpened"
                          submenu="true"
                          ;
                        ></gt-resource-select>

                        <gt-resource-select
                          ng-if="!$root.user.settings.PASSPORT_CHECK_CROP"
                          ng-model="item.$_passportId"
                          placeholder="'Passport' | translate"
                          resource-name="'passports.passport'"
                          allow-clear="true"
                          on-select="args.connectToPassport"
                          on-select-args="[item]"
                          on-open-close="item.$_passportDropdownOpened = !item.$_passportDropdownOpened"
                          submenu="true"
                          ;
                        ></gt-resource-select>
                      </li>
                      <li ng-if="item.status !== 'draft'">
                        <a
                          class="btn btn-xs aligned-btn"
                          ng-click="args.createServiceContract(item)"
                        >
                          <i class="fa fa-truck"></i> <translate>Create Service Request</translate>
                        </a>
                      </li>
                      <li ng-if="$root.user.settings.SYSTEM_BLOCKS['block_deals_multicontract']">
                        <a
                          ng-click="args.openMulticontractModal(item)"
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'New multicontract' | translate }]}"
                          permission
                          permission-only="'add_multicontract'"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-clipboard"></i>
                          <translate>New multicontract</translate>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!-- / ITEM SETTINGS BTN -->
            `,
            totalTemplate: html`
              <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate>
              <span ng-if="item.use_cashe" class="smaller-label label-opacity">
                <div>
                  <translate>Updated on:</translate>
                  {[{item.last_completed_update_request_update_time | date:'dd.MM.yy HH:mm'}]}
                  <a
                    class="btn"
                    ng-click="args.createUpdateRequest()"
                    ng-if="item.last_update_request_status !== 'pending' || !item.last_update_request_status"
                  >
                    <i class="fa fa-refresh"></i>
                  </a>
                </div>
                <div ng-if="item.last_update_request_status === 'pending'">
                  <translate>Requested to update on:</translate>
                  {[{item.last_update_request_update_time | date:'dd.MM.yy HH:mm'}]}
                </div>
                <div ng-if="item.last_update_request_status === 'failed'">
                  <translate>Error:</translate>
                  {[{item.last_update_request_error_message}]}
                </div>
              </span>
              <span ng-if="!item.use_cashe" class="smaller-label label-opacity">
                <i class="fa fa-database"></i> <translate>Dynamic</translate>
              </span>
            `,
          },
          {
            title: this.gettext('Documents'),
            columnName: 'documents',
            class: 'td-left-align',
            classExpr: "{ 'alert-success edit': args.rows[item.id].editable || !item.id }",
            cellTemplate: html`
              <div
                class="btn-link smaller-label"
                ng-if="item.documents_data && item.documents_data.length > 3"
                ng-click="item.BlDocsExpanded = !item.BlDocsExpanded"
              >
                <i class="fa fa-download"></i> {[{item.documents_data.length}]}
              </div>
              <div ng-if="item.documents_data && item.documents_data.length <= 3">
                <div class="clearfix"></div>
                <div ng-repeat="doc in item.documents_data">
                  <a href="{[{doc.file}]}" class="btn-link smaller-label" target="_blank">
                    <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                    <span class="label label_info smaller-label" ng-if="doc.basis_doc_title"
                      >{[{doc.basis_doc_title | cut:true:25:'...'}]}
                    </span>
                  </a>
                </div>
              </div>

              <div ng-if="item.BlDocsExpanded">
                <div class="clearfix"></div>
                <div ng-repeat="doc in item.documents_data">
                  <a href="{[{doc.file}]}" class="btn-link smaller-label" target="_blank">
                    <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                    <span class="label label_info smaller-label" ng-if="doc.basis_doc_title"
                      >{[{doc.basis_doc_title | cut:true:25:'...'}]}
                    </span>
                  </a>
                  <div class="clearfix"></div>
                </div>
              </div>
            `,
          },
          {
            columnName: 'status',
            title: this.gettext('status'),
            class: 'td-left-align small-font-cell status-cell',
            filters: [
              {
                type: 'select',
                predicate: 'status',
                label: this.gettext('Choose status'),
                values: {
                  new: this.gettext('New'),
                  shipment: this.gettext('Shipment'),
                  executed: this.gettext('Executed'),
                  cargo_executed: this.gettext('Cargo executed'),
                  delivery_period: this.gettext('Delivery'),
                  cancelled: this.gettext('Cancelled'),
                  washout: this.gettext('Washout'),
                  draft: this.gettext('Draft'),
                },
              },
            ],
            cellTemplate: html`
              <span
                class="label"
                ng-class="{
                  'label_success': item.status == 'new',
                  'label_default': item.status == 'executed',
                  'label_danger': item.status == 'cancelled',
                  'label_info': item.status == 'draft',
                  'label_warning': item.status == 'delivery_period' || item.status == 'cargo_executed' || item.status == 'shipment'
                }"
              >
                <span ng-if="item.status == 'new'"> <translate>New</translate> </span>
                <span ng-if="item.status == 'washout'"> <translate>Washout</translate> </span>
                <span ng-if="item.status == 'shipment'"> <translate>In progress</translate> </span>
                <span ng-if="item.status == 'delivery_period'">
                  <translate>Delivery period</translate>
                </span>
                <span ng-if="item.status == 'cargo_executed'">
                  <translate>Cargo executed</translate>
                </span>
                <span ng-if="item.status == 'executed'"> <translate>Executed</translate> </span>
                <span ng-if="item.status == 'cancelled'"> <translate>Cancelled</translate> </span>
                <span ng-if="item.status == 'draft'"> <translate>Draft</translate> </span>
              </span>
            `,
          },
          {
            columnName: 'contract_type',
            title: this.gettext('Contract type'),
            class: 'td-left-align',
            cellTemplate: html`<span> {[{ item.contract_type }]} </span>`,
          },
          {
            columnName: 'folder_number',
            title: this.gettext('folder number'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="label" ng-if="item.folder_number">
                <i class="fa fa-folder"></i> {[{item.folder_number | cut:true:40:'...' || "---" }]}
              </span>
            `,
          },
          {
            columnName: 'custom_status',
            title: this.gettext('custom status'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span
                ng-if="item.custom_status_title"
                class="label"
                ng-class="{ 'label_calculate': item.custom_status_codename == 'washout', 'label_success': item.custom_status_codename == 'new', 'label-default': item.custom_status_codename == 'cancelled', 'label_default': item.custom_status_codename == 'executed', 'label_warning': item.custom_status_codename == 'shipment' } "
              >
                {[{ item.custom_status_title }]}
              </span>
            `,
          },
          {
            columnName: 'multicontract',
            title: this.gettext('multicontract'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="label" ng-if="item.multicontract">
                <a ui-sref="gt.multicontract({id: item.multicontract })">
                  <i class="fa fa-clipboard"></i>
                  {[{ item.multicontract_number | cut:true:40:'...' || "---" }]}
                </a>
              </span>
            `,
          },
          {
            columnName: 'consignees',
            title: this.gettext('consignees'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="label">
                <div
                  ng-if="item.contract_consignees_data.length > 0"
                  ng-repeat="consignee in item.contract_consignees_data"
                >
                  {[{consignee.consignee_name}]}: {[{consignee.cargo_title}]}
                  {[{consignee.volume}]}t
                </div>
                <div ng-if="item.contract_consignees_data.length == 0 && item.consignee_name">
                  {[{item.consignee_name}]}t
                </div>
              </span>
            `,
          },
          {
            columnName: 'type_of_activities',
            title: this.gettext('type of activities'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="label" ng-if="item.type_of_activities_data">
                <div ng-repeat="item in item.type_of_activities_data">{[{item.title}]}</div>
              </span>
            `,
          },
          {
            columnName: 'company_group',
            title: this.gettext('Company group'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="label" ng-if="item.company_group_title">
                <i class="fa fa-briefcase"></i>{[{item.company_group_title}]}
              </span>
            `,
          },
          {
            columnName: 'signature',
            title: this.gettext('signature'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span
                class="label label_success"
                ng-if="item.signature_stage_all_signed"
                data-tip="{[{item.signature_stage_all_signed | date:'dd.MM.yy'}]}"
              >
                <i class="fa fa-check"></i> <i class="fa fa-pencil-square"></i>
              </span>
              <span class="label label_success" ng-if="item.signature_stage_originals_received">
                <i class="fa fa-check-square-o"></i> <i class="fa fa-file"></i>
              </span>
            `,
          },
          {
            columnName: 'shipment_status',
            title: this.gettext('shipment status'),
            class: 'td-left-align',
            cellTemplate: html`
              <label
                class="contract-status-block"
                ng-class="{'status_success': item.shipment_status == 'shipment_ended',
           'status_exclamation': item.shipment_status == 'shipment_in_process',
           'status_danger': item.shipment_status == 'shipment_waited',
           'status_default': !item.shipment_status }"
              >
                <i
                  class="fa"
                  ng-class="{'fa-check-circle': item.shipment_status == 'shipment_ended',
            'fa-circle-o-notch fa-spin': item.shipment_status == 'shipment_in_process',
            'fa-exclamation-circle': item.shipment_status == 'shipment_waited',
            'fa-circle-o': !item.shipment_status }"
                ></i>
                <span ng-if="item.shipment_status">{[{item.shipment_status}]}</span>
                <span ng-if="!item.shipment_status"><translate>no status</translate></span>
              </label>
            `,
          },
          {
            columnName: 'docs_status',
            title: this.gettext('docs status'),
            class: 'td-left-align',
            cellTemplate: html`
              <label
                class="contract-status-block"
                ng-class="{'status_success': (item.docs_status == 'all_originals' || item.docs_status == 'all_copies'),
         'status_exclamation': item.docs_status == 'in_progress',
         'status_default': !item.docs_status }"
              >
                <i
                  class="fa"
                  ng-class="{'fa-check-circle': item.docs_status == 'all_originals',
          'fa-circle-o-notch fa-spin': item.docs_status == 'in_progress',
          'fa-exclamation-circle': item.docs_status == 'all_copies',
          'fa-circle-o': !item.docs_status }"
                ></i>
                <span ng-if="item.docs_status">{[{item.docs_status}]}</span>
                <span ng-if="!item.docs_status"><translate>no status</translate></span>
              </label>
            `,
          },
          {
            columnName: 'passport_status',
            title: this.gettext('passport status'),
            class: 'td-left-align',
            cellTemplate: html`
              <label
                class="contract-status-block"
                ng-class="{'status_success': item.passport_status == 'fully_allocated',
         'status_exclamation': item.passport_status == 'connected_to_passport',
         'status_default': !item.passport_status }"
              >
                <i
                  class="fa"
                  ng-class="{'fa-check-circle': item.passport_status == 'fully_allocated',
          'fa-circle-o-notch fa-spin': item.passport_status == 'connected_to_passport',
          'fa-circle-o': !item.passport_status }"
                ></i>
                <span ng-if="item.passport_status">{[{item.passport_status}]}</span>
                <span ng-if="!item.passport_status"><translate>no status</translate></span>
              </label>
            `,
          },
          {
            columnName: 'responsible',
            title: this.gettext('responsible'),
            class: 'td-left-align small-font-cell',
            filters: [
              {
                type: 'ui-select',
                predicate: 'responsible',
                label: this.gettext('responsible'),
                resource: 'auth.user',
              },
            ],
            cellTemplate: html`
              <span
                class="tooltip"
                data-tip="{[{ 'responsible' | translate }]} - {[{ item.responsible_first_name }]}"
              >
                <div
                  class="user-avatar"
                  ng-if="item.responsible_first_name && item.responsible_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.responsible_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.responsible_avatar.length == 0">
                  {[{ item.responsible_first_name | cut:true:1:' ' }]}
                </div>
                {[{ item.responsible_first_name }]}
              </span>
            `,
          },
          {
            columnName: 'responsible_for_signing',
            title: this.gettext('Responsible for signing'),
            class: 'td-left-align small-font-cell',
            filters: [
              {
                type: 'ui-select',
                predicate: 'responsible_for_signing',
                label: this.gettext('Responsible for signing'),
                resource: 'auth.user',
              },
            ],
            cellTemplate: html`
              <span>
                <div
                  class="user-avatar"
                  ng-if="item.responsible_for_signing && item.responsible_for_signing_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.responsible_for_signing_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.responsible_for_signing_avatar.length == 0">
                  {[{ item.responsible_for_signing_first_name | cut:true:1:' ' }]}
                </div>
                {[{ item.responsible_for_signing_first_name }]}
              </span>
            `,
          },
          {
            columnName: 'approval_status',
            title: this.gettext('approval status'),
            class: 'td-left-align small-font-cell',
            filters: [
              {
                type: 'select',
                predicate: 'approval_status',
                label: this.gettext('Choose status'),
                values: {
                  wait: this.gettext('Wait'),
                  approved: this.gettext('Approved'),
                  process: this.gettext('Process'),
                  rejected: this.gettext('Rejected'),
                },
              },
            ],
            cellTemplate: html`
              <span
                class="label pull-right"
                ng-if="item.approval_status != 'wait'"
                ng-class="{'label_success': item.approval_status == 'approved', 'label_warning': item.approval_status == 'process', 'label_danger': item.approval_status == 'rejected' }"
                style="margin-left: 2px;"
              >
                <i
                  class="fa"
                  ng-class="{'fa-check': item.approval_status == 'approved', 'fa-spin fa-spinner': item.approval_status == 'process', 'fa-times': item.approval_status == 'rejected' }"
                ></i>
                <translate>{[{ item.approval_status }]}</translate>
              </span>
            `,
          },
          {
            columnName: 'cp_approval_status',
            title: this.gettext('CP approval status'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span
                class="label pull-right"
                ng-class="{
                  'label_success': item.cp_approval_status == 'approved',
                  'label_warning': item.cp_approval_status == 'process',
                  'label_danger': item.cp_approval_status == 'rejected',
                  'label_default': item.cp_approval_status == 'wait'
                }"
                style="margin-left: 2px;"
              >
                <i
                  class="fa"
                  ng-class="{'fa-check': item.cp_approval_status == 'approved', 'fa-spin fa-spinner': item.cp_approval_status == 'process', 'fa-times': item.cp_approval_status == 'rejected' }"
                ></i>
                <translate>{[{ item.cp_approval_status }]}</translate>
              </span>
            `,
          },
          {
            columnName: 'cp_custom_status',
            title: this.gettext('CP custom status'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span
                ng-if="item.cp_custom_status_title"
                class="label"
                ng-class="{ 'label_calculate': item.cp_custom_status_codename == 'washout', 'label_success': item.cp_custom_status_codename == 'new', 'label-default': item.cp_custom_status_codename == 'cancelled', 'label_default': item.cp_custom_status_codename == 'executed', 'label_warning': item.cp_custom_status_codename == 'shipment' } "
              >
                {[{ item.cp_custom_status_title }]}
              </span>
            `,
          },
          {
            columnName: 'payment_status',
            title: this.gettext('Payment status'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span
                ng-if="item.payment_status"
                class="label"
                ng-class="{ 'label_success': item.payment_status == 'executed', 'label_warning': item.payment_status == 'not_executed' } "
              >
                <span ng-if="item.payment_status == 'executed'">
                  <translate>Executed</translate>
                </span>
                <span ng-if="item.payment_status == 'not_executed'">
                  <translate>Not executed</translate>
                </span>
              </span>
            `,
          },
          {
            columnName: 'warehouse_consignments',
            title: this.gettext('Warehouse consignments'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span class="bigger-label">
                <span>{[{ item.consignment_volume || 0 | number:3 }]}</span> /
                <span
                  >{[{ item.contract_volume_remaining_after_consignments || 0 | number:3 }]}</span
                >
              </span>
            `,
            totalTemplate: html`
              <span class="bigger-label">
                <span>{[{ item.consignment_volume_total || 0 | number:3 }]}</span> /
                <span
                  >{[{ item.contract_volume_remaining_after_consignments_total || 0 | number:3
                  }]}</span
                >
              </span>
            `,
          },
          {
            columnName: 'costDetails',
            title: this.gettext('Cost details'),
            class: 'td-left-align',
            cellTemplate: html`
              <span class="bigger-label" ng-if="item.charges_data">
                <span ng-repeat="charge in item.charges_data">
                  <span class="bigger-label">{[{ charge.title | cut:true:30:' ...' }]};</span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
          },
          {
            columnName: 'properties',
            title: this.gettext('type'),
            class: 'td-left-align small-font-cell contents-display',
            cellTemplate: html`
              <span
                ng-if="item.swap"
                style="margin-left: 2px;"
                class="tooltip label"
                data-tip="{[{ 'Swap'|translate }]}"
              >
                <i class="fa fa-diamond"></i> <translate>Swap</translate>
              </span>
              <span
                ng-if="item.paper_trade"
                style="margin-left: 2px;"
                class="tooltip label"
                data-tip="{[{ 'Paper'|translate }]}"
              >
                <i class="fa fa-file"></i> <translate>Paper</translate>
              </span>
              <span
                ng-if="item.deal_type =='brokerage'"
                style="margin-left: 2px;"
                class="label label_info"
              >
                <i class="fa fa-calendar-o"></i> <translate>Brokerage</translate>
              </span>
              <span
                ng-if="item.deal_type =='forward'"
                style="margin-left: 2px;"
                class="tooltip label"
                data-tip="{[{ item.deal_type }]}"
              >
                <i class="fa fa-calendar-o"></i> <translate>Forward</translate>
              </span>
              <span
                ng-if="item.iscc"
                style="margin-left: 2px;"
                class="tooltip label"
                data-tip="{[{ 'ISCC'|translate }]}"
              >
                <translate>{[{ 'ISCC'|translate }]}</translate>
              </span>
              <span
                class="tooltip label"
                data-tip="{[{ 'SAP order numbers'|translate }]}"
                ng-if="item.sap_orders_data"
              >
                <span ng-repeat="sap_order in item.sap_orders_data">
                  {[{ sap_order.number | cut:true:30:' ...' }]}
                </span>
              </span>
            `,
          },
          {
            columnName: 'ticket',
            title: this.gettext('ticket'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <a
                class="label pull-right tooltip btn-link"
                style="margin-left: 2px;"
                data-tip="{[{ 'This contract was made from ticket'|translate}]} #{[{ item.from_request_title || item.from_request }]}"
                ng-if="item.from_request"
                ui-sref="gt.page.request({id: item.from_request})"
                ng-click="$root.gtUtils.dismissAllModals();"
              >
                <i class="fa fa-file-text-o"></i> {[{ item.from_request_title || item.from_request
                }]}
              </a>
            `,
          },
          {
            columnName: 'price_indicative',
            class: 'td-left-align',
            title: this.gettext('Price (with costs, without VAT)'),
            cellTemplate: html`
              <span> {[{ item.price_indicative || "---"}]} </span>
              <span class="smaller-label" ng-if="item.price_indicative">
                {[{item.price_indicative_currency_symbol || item.currency_symbol ||
                args.defaultCurrency }]}/ {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
              </span>
            `,
          },
          {
            columnName: 'price_indicative_with_vat',
            class: 'td-left-align',
            title: this.gettext('Price (with costs, with VAT)'),
            cellTemplate: html`
              <span> {[{ item.price_indicative_with_vat || "---"}]} </span>
              <span class="smaller-label" ng-if="item.price_indicative_with_vat">
                {[{item.price_indicative_currency_symbol || item.currency_symbol ||
                $root.user.settings.DEFAULT_CURRENCY }]}/ {[{
                $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
              </span>
            `,
          },
          {
            columnName: 'price_indicative_default_currency',
            class: 'td-left-align',
            title: this.gettext('Price (with costs, without VAT), $'),
            cellTemplate: html`
              <span> {[{ item.price_indicative_default_currency || "---"}]} </span>
              <span class="smaller-label" ng-if="item.price_indicative_default_currency">
                $ / {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
              </span>
            `,
          },
          {
            columnName: 'parent_indicator',
            title: this.gettext('parent indicator'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <a
                class="label pull-right tooltip btn-link"
                style="margin-left: 2px;"
                data-tip="{[{ 'This contract was made from indicator'|translate}]} #{[{ item.parent_indicator_title || item.parent_indicator }]}"
                ng-if="item.parent_indicator"
                ui-sref="gt.page.request({id: item.parent_indicator})"
                ng-click="$root.gtUtils.dismissAllModals();"
              >
                <i class="fa fa-file-text-o"></i> {[{ item.parent_indicator_title ||
                item.parent_indicator }]}
              </a>
            `,
          },
          {
            columnName: 'parent_indicator_price',
            title: this.gettext('parent indicator price'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span ng-if="item.parent_indicator_price">
                {[{ item.parent_indicator_price }]} {[{ item.parent_indicator_price_symbol }]}/ {[{
                args.measurementUnit }]}
              </span>
              <span ng-if="!item.parent_indicator_price"> --- </span>
            `,
          },
          {
            columnName: 'vessel',
            title: this.gettext('vessel'),
            class: 'td-left-align',
            cellTemplate: html`
              <div ng-repeat="connection in item.connections">
                <span
                  ng-click="args.openVoyageModal({id: connection.voyage_id})"
                  class="btn-link"
                  ng-if="connection.vessel_name"
                >
                  <i class="fa fa-ship"></i> {[{ connection.vessel_name }]}</span
                >
                <span ng-if="connection.vessel_name">
                  <span
                    class="label smaller-label"
                    ng-class="{
                   'label_success': connection.voyage_status == 'New',
                   'label_default': connection.voyage_status == 'Washout',
                   'label-default': connection.voyage_status == 'Executed',
                   'label_calculate': connection.voyage_status == 'pod_under_way' || connection.voyage_status == 'at_discharging_port' || connection.voyage_status == 'Discharging' || connection.voyage_status == 'Discharged',
                   'label_warning': connection.voyage_status == 'pol_under_way' || connection.voyage_status == 'at_loading_port' || connection.voyage_status == 'Loading' || connection.voyage_status == 'Loaded'
                } "
                    style="margin-left: 5px"
                    ng-if="connection.voyage_status"
                  >
                    {[{ connection.voyage_status }]}
                  </span>
                </span>
              </div>
            `,
          },
          {
            columnName: 'pass',
            title: this.gettext('allocated'),
            class: 'td-right-align',
            cellTemplate: html`

          <span
            class="label label_success"
            data-tip="{[{ 'fully connected to passports'|translate }]}"
            ng-if="item.total_connected && (item.total_connected == (item.final_volume || item.volume_estimated_open || item.volume))"
          >
            <i class="fa fa-exchange"></i> <i class="fa fa-check-circle"></i>
          </span>
          <span
            ng-if="item.total_connected && (item.total_connected != (item.final_volume || item.volume_estimated_open || item.volume))"
          >
            <span>
              <i class="fa fa-exchange"></i> {[{ item.total_connected || 0 | number:3 }]}
              <span class="smaller-label"> {[{ args.measurementUnit }]}</span>
            </span>
          </span>
          <div class="clearfix"></span>

            `,
            totalTemplate: html`
              <span ng-if="item.connected">
                <span
                  ><i class="fa fa-exchange"></i> {[{ item.total_connected || 0 | number:3 }]}
                </span>
              </span>
            `,
          },
          {
            columnName: 'passports',
            title: this.gettext('passports'),
            class: 'small-font-cell td-left-align',
            cellTemplate: html`
              <span ng-repeat="conn in item.connections">
                <a class="btn-link" ui-sref="gt.page.passport({id: conn.passport })">
                  <i class="fa fa-exchange"></i> {[{conn.passport_title | cut:false:20:'...' ||
                  "---" }]}
                  <span class="smaller-label label-smoke"
                    ><i class="fa fa-check"></i> {[{ conn.volume | number:3 }]}

                    <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                    <span class="smaller-label">
                      <i class="fa fa-calendar-o"></i> {[{ conn.passport_business_date || "---" |
                      date:'MMM yy'}]}
                    </span>
                  </span>
                </a>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'not_allocated',
            title: this.gettext('not allocated'),
            class: 'small-font-cell td-right-align',
            cellTemplate: html`
              <span
                ng-if="item.available_to_connect != 0"
                ng-class="{'label label_warning': !item.final_volume, 'label label_danger': item.final_volume}"
              >
                {[{ item.available_to_connect || 0 | number:3}]}
                <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
              </span>
            `,
            totalTemplate: html`<span>{[{ item.available_to_connect || 0 | number:3 }]} </span>`,
          },
          {
            columnName: 'concluded',
            title: this.gettext('concluded'),
            predicate: 'conclusion_date',
            hint: this.gettext('time, date and user, who created this deal'),
            class: 'small-font-cell',
            filters: [
              {
                type: 'daterange',
                startDateField: 'start_date',
                endDateField: 'end_date',
              },
            ],
            cellTemplate: html`
              <i class="fa fa-calendar"></i> {[{ item.conclusion_date || "---" | date:'dd.MM.yy'}]}
            `,
          },
          {
            columnName: 'paymentEvents',
            class: 'td-left-align',
            title: this.gettext('Payment events'),
            cellTemplate: html`
              <span ng-if="item.prepaypayment_title">
                <translate>prepay</translate> {[{ item.prepaypayment_title }]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.balancepayment_title">
                <translate>balance</translate> {[{ item.balancepayment_title }]}
              </span>
            `,
          },
          {
            columnName: 'supplier',
            title: this.gettext('supplier'),
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: this.fullQueryParams.contract_type === 'sale' ? 'owner' : 'supplier',
                label: this.gettext('supplier'),
                resource:
                  this.fullQueryParams.contract_type === 'sale'
                    ? 'clients.Owner'
                    : 'clients.Supplier',
              },
            ],
            cellTemplate: html`
              <span
                ng-if="item.supplier_name"
                ng-class="{'label label_danger': item.supplier_blacklisted}"
              >
                <a
                  ng-click="args.openRoleModal(item.supplier, 'Supplier')"
                  ng-if="item.contract_type =='purchase'"
                  class="btn-link bigger-label"
                >
                  <i class="fa fa-bookmark-o"></i> {[{item.supplier_name | cut:true:30:'...' ||
                  "---" }]}
                  <i class="fa fa-check-circle" ng-if="item.supplier_date_confirmation"></i>
                </a>
                <a
                  ng-click="args.openRoleModal(item.supplier, 'Owner')"
                  ng-if="item.contract_type =='sale'"
                  class="bigger-label btn-link"
                >
                  <i class="fa fa-home"></i> {[{item.supplier_name | cut:true:30:'...' || "---" }]}
                  <i class="fa fa-check-circle" ng-if="item.supplier_date_confirmation"></i>
                </a>
              </span>
              <div class="clearfix"></div>
              <span class="label-opacity" ng-if="!item.supplier_name">
                <i class="fa fa-bookmark-o"></i> ---
              </span>
            `,
          },
          {
            columnName: 'buyer',
            title: this.gettext('buyer'),
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: this.fullQueryParams.contract_type === 'sale' ? 'buyer' : 'owner',
                label: this.gettext('buyer'),
                resource:
                  this.fullQueryParams.contract_type === 'sale' ? 'clients.Buyer' : 'clients.Owner',
              },
            ],
            cellTemplate: html`
              <span
                ng-if="item.buyer_name"
                ng-class="{'label label_danger': item.buyer_blacklisted}"
              >
                <a
                  ng-click="args.openRoleModal(item.buyer, 'Buyer')"
                  ng-if="item.contract_type =='sale'"
                  class="btn-link bigger-label"
                >
                  <i class="fa fa-bookmark"></i> {[{ item.buyer_name | cut:true:30:'...' || "---"
                  }]}
                  <i class="fa fa-check-circle" ng-if="item.buyer_date_confirmation"></i>
                </a>
                <a
                  ng-click="args.openRoleModal(item.buyer, 'Owner')"
                  ng-if="item.contract_type =='purchase'"
                  class="bigger-label btn-link"
                >
                  <i class="fa fa-home"></i> {[{ item.buyer_name | cut:true:30:'...' || "---" }]}
                  <i class="fa fa-check-circle" ng-if="item.buyer_date_confirmation"></i>
                </a>
              </span>
              <span class="label-opacity" ng-if="!item.buyer_name">
                <i class="fa fa-bookmark"></i> ---
              </span>
            `,
          },
          {
            columnName: 'exporter',
            title: this.gettext('exporter'),
            class: 'td-left-align',
            cellTemplate: html`
              <span class="exporter_set" ng-if="item.exporter_set.length">
                <span ng-repeat="exporter in item.exporter_set">
                  <a
                    class="smaller-label"
                    ng-class="{ 'label_danger': exporter.blacklisted}"
                    data-tip="{[{exporter.name}]}"
                    ng-click="args.openRoleModal(exporter.id, 'Exporter')"
                  >
                    <i class="fa fa-shield"></i> {[{ exporter.name | cut:true:25:' ...'}]}
                    <i class="fa fa-check" ng-if="exporter.date_confirmation"></i>
                    <div class="clearfix"></div>
                  </a>
                </span>
              </span>
              <div class="clearfix"></div>
              <span> {[{ item.guarantor_name }]} </span>
            `,
          },
          {
            columnName: 'broker',
            title: this.gettext('broker'),
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'broker',
                label: this.gettext('broker'),
                resource: 'clients.Broker',
              },
            ],
            cellTemplate: html`
              <span ng-if="item.broker_name">
                <a class="btn-link" ui-sref="gt.page.client({id: item.broker_id, tab: 'persons'})">
                  <i class="fa fa-briefcase"></i>{[{ item.broker_name || '---'}]}
                </a>
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.broker_contacts_data.length">
                <translate> Broker details </translate>
                <a
                  class="smaller-label label label_default"
                  ng-click="item.brokerDetailsExpanded = !item.brokerDetailsExpanded"
                >
                  <i class="fa fa-suitcase"></i> {[{item.broker_contacts_data.length}]}
                </a>
                <div class="clearfix"></div>
                <person-info
                  ng-if="item.broker_contacts_data && item.brokerDetailsExpanded"
                  ng-click="item.brokerDetailsExpanded = !item.brokerDetailsExpanded"
                  broker-contacts="item.broker_contacts_data"
                ></person-info>
              </span>
            `,
          },
          {
            columnName: 'agent',
            title: this.gettext('agent'),
            class: 'td-left-align',
            cellTemplate: html`
              <span ng-if="item.agent_name">
                <i class="fa fa-suitcase"></i> {[{ item.agent_name || '---'}]}
              </span>
            `,
          },
          {
            columnName: 'cargo',
            title: this.gettext('product'),
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'cargo',
                label: this.gettext('product'),
                resource: 'crops.Crop',
              },
            ],
            cellTemplate: html`
              <span
                class="bigger-label"
                ng-if="item.crop_title && !item.contractprices_data.length"
              >
                {[{ item['crop_' + [$root.user.settings.CROP_TITLE_DISPLAY_MODE]] || item.crop_title
                }]}
              </span>
              <span ng-repeat="cargo in item.contractprices_data | unique:'cargo_title' || []">
                <span class="bigger-label">{[{ cargo.cargo_title }]}</span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'commodity_last_parent',
            title: this.gettext('Parent commodity'),
            class: 'td-left-align',
            cellTemplate: html`
              <span
                class="bigger-label"
                ng-if="item.commodity_last_parent_title && !item.contractprices_data.length"
              >
                {[{ item.commodity_last_parent_title }]}
              </span>
              <span
                ng-repeat="contractprice in item.contractprices_data | unique:'commodity_last_parent_title' || []"
              >
                <span class="bigger-label">{[{ contractprice.commodity_last_parent_title }]}</span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'commodity_last_parent_analytic',
            title: this.gettext('Parent analytic commodity'),
            class: 'td-left-align',
            cellTemplate: html`
              <span
                class="bigger-label"
                ng-if="item.commodity_last_parent_analytic_title && !item.contractprices_data.length"
              >
                {[{ item.commodity_last_parent_analytic_title }]}
              </span>
              <span
                ng-repeat="contractprice in item.contractprices_data | unique:'commodity_last_parent_analytic_title' || []"
              >
                <span class="bigger-label"
                  >{[{ contractprice.commodity_last_parent_analytic_title }]}</span
                >
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'volume',
            title: this.gettext('volume'),
            predicate: 'volume',
            class: 'td-right-align',
            filters: [
              {
                type: 'text',
                predicate: 'min_volume',
                addon: this.gettext('Min'),
              },
              {
                type: 'text',
                predicate: 'max_volume',
                addon: this.gettext('Max'),
              },
            ],
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.contractprices_volume_sum || item.volume || 0 | number:3 }]}
                <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
              </span>
              <div class="clearfix"></div>
              <span class="smaller-label" ng-if="item.volume_estimated_open">
                <span class="label-smoke">
                  <translate>est.</translate>
                </span>
                {[{ item.volume_estimated_open || 0 | number:3 }]}
                <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
              </span>
            `,
            totalTemplate: html`
              <span ng-if="item.volume_with_docs_total" ng-if="$root.user.settings.RESULT_VAT">
                <span class="smaller-label label-smoke"><translate>with docs</translate></span>
                <div class="clearfix"></div>
                <i class="fa fa-file-text"></i> {[{ item.volume_with_docs_total || 0 | number:3 }]}
              </span>
              <div class="clearfix"></div>
              <span class="smaller-label label-smoke"><translate>contract</translate></span>
              <div class="clearfix"></div>
              <span ng-if="item.volume_sum"> {[{ item.volume_sum || 0 | number:3 }]} </span>
            `,
            totalBlockTemplates: [
              html`
                <span class="label">
                  {[{ item.volume_sum || 0 | number:3 }]}
                  <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>volume</translate>
                </span>
                <div class="horizontal-divider"></div>
                <table
                  class="table-responsive table-condensed main-table basis-port-prices-table inside-table-table table"
                >
                  <tr ng-repeat="item in item.currencies_data">
                    <td>{[{ item.symbol }]}</td>
                    <td>{[{ item.volume_sum || 0 | number:3 }]}</td>
                  </tr>
                </table>
              `,
              html`
                <span class="label">
                  <i class="fa fa-exchange"></i> {[{ item.connected || 0 | number:3 }]}
                  <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>connected</translate>
                </span>
                <div class="horizontal-divider"></div>
                <table
                  class="table-responsive table-condensed main-table basis-port-prices-table inside-table-table table"
                >
                  <tr ng-repeat="item in item.currencies_data">
                    <td>{[{ item.symbol }]}</td>
                    <td>{[{ item.connected || 0 | number:3 }]}</td>
                  </tr>
                </table>
              `,
              html`
                <span class="label">
                  <i class="fa fa-truck"></i> {[{ item.transport_volume_basis_sum || 0 | number:3
                  }]}
                  <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>executed</translate>
                  <translate>volume</translate>
                </span>
                <div class="horizontal-divider"></div>
                <table
                  class="table-responsive table-condensed main-table basis-port-prices-table inside-table-table table"
                >
                  <tr ng-repeat="item in item.currencies_data">
                    <td>{[{ item.symbol }]}</td>
                    <td>{[{ item.transport_volume_basis_sum || 0 | number:3 }]}</td>
                  </tr>
                </table>
              `,
              html`
                <span class="label">
                  <i class="fa fa-check-circle"></i> {[{ item.final_volume_sum || 0 | number:3 }]}
                  <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>final</translate>
                  <translate>volume</translate>
                </span>
                <div class="horizontal-divider"></div>
                <table
                  class="table-responsive table-condensed main-table basis-port-prices-table inside-table-table table"
                >
                  <tr ng-repeat="item in item.currencies_data">
                    <td>{[{ item.symbol }]}</td>
                    <td>{[{ item.final_volume_sum || 0 | number:3 }]}</td>
                  </tr>
                </table>
              `,
              html`
                <span class="label">
                  <translate>Value</translate>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>by currencies</translate>
                </span>
                <div class="horizontal-divider"></div>
                <table
                  class="table-responsive table-condensed main-table basis-port-prices-table inside-table-table table"
                >
                  <tr ng-repeat="item in item.currencies_data">
                    <td>{[{ item.symbol }]}</td>
                    <td>{[{ item.total_value || 0 | number:0 }]}</td>
                  </tr>
                </table>
              `,
              html`
                <span class="label">
                  <translate>Invoiced</translate>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>by currencies</translate>
                </span>
                <div class="horizontal-divider"></div>
                <table
                  class="table-responsive table-condensed main-table basis-port-prices-table inside-table-table table"
                >
                  <tr ng-repeat="item in item.currencies_data">
                    <td>{[{ item.symbol }]}</td>
                    <td>{[{ item.invoiced_value || 0 | number:0 }]}</td>
                  </tr>
                </table>
              `,
              html`
                <span class="label">
                  <translate>Paid</translate>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>by currencies</translate>
                </span>
                <div class="horizontal-divider"></div>
                <table
                  class="table-responsive table-condensed main-table basis-port-prices-table inside-table-table table"
                >
                  <tr ng-repeat="item in item.currencies_data">
                    <td>{[{ item.symbol }]}</td>
                    <td>{[{ item.payment_value || 0 | number:0 }]}</td>
                  </tr>
                </table>
              `,
              html`
                <span class="label">
                  <translate>Not invoiced</translate>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>by currencies</translate>
                </span>
                <div class="horizontal-divider"></div>
                <table
                  class="table-responsive table-condensed main-table basis-port-prices-table inside-table-table table"
                >
                  <tr ng-repeat="item in item.currencies_data">
                    <td>{[{ item.symbol }]}</td>
                    <td>{[{ item.not_invoiced_value || 0 | number:2 }]}</td>
                  </tr>
                </table>
              `,
              html`
                <span class="label">
                  <translate>Invoices</translate> - <translate>Paid</translate>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>by currencies</translate>
                </span>
                <div class="horizontal-divider"></div>
                <table
                  class="table-responsive table-condensed main-table basis-port-prices-table inside-table-table table"
                >
                  <tr ng-repeat="item in item.currencies_data">
                    <td>{[{ item.symbol }]}</td>
                    <td>{[{ item.debit_credit_value || 0 | number:2 }]}</td>
                  </tr>
                </table>
              `,
              html`
                <span class="label">
                  <translate>Amount</translate> - <translate>Paid</translate>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>by currencies</translate>
                </span>
                <div class="horizontal-divider"></div>
                <table
                  class="table-responsive table-condensed main-table basis-port-prices-table inside-table-table table"
                >
                  <tr ng-repeat="item in item.currencies_data">
                    <td>{[{ item.symbol }]}</td>
                    <td>{[{ item.balance_payment_value || 0 | number:2 }]}</td>
                  </tr>
                </table>
              `,
            ],
          },
          {
            columnName: 'final_volume',
            title: this.gettext('final volume'),
            class: 'td-right-align',
            cellTemplate: html`
              <span
                class="btn btn-xs btn-green-border"
                ng-click="args.setFinalVolumeFromExecution(item.id)"
              >
                <i class="fa fa-check-circle"></i> <translate>Final</translate>
              </span>
              <span class="label label_success" ng-if="item.final_volume">
                <span>
                  <i class="fa fa-check-circle"></i>
                </span>
                {[{ item.final_volume || 0 | number:3 }]}
                <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
              </span>
            `,
            totalTemplate: html`
              <span ng-if="item.final_volume_sum">
                <span>
                  <i class="fa fa-check-circle"></i> {[{ item.final_volume_sum || 0 | number:3 }]}
                </span>
              </span>
            `,
          },
          {
            columnName: 'measurement',
            title: this.gettext('measurement'),
            class: 'td-left-align',
            cellTemplate: html`
              <span ng-if="item.measurement_title"> {[{ item.measurement_title }]} </span>
            `,
          },
          {
            columnName: 'quantity_of_measurement',
            title: this.gettext('quantity of measurement'),
            class: 'td-left-align',
            cellTemplate: html`
              <span ng-if="item.quantity"> {[{ item.quantity || 0 | gtDecimal:3 }]} </span>
            `,
          },
          {
            columnName: 'price',
            title: this.gettext('price'),
            class: 'td-right-align',
            predicate: 'price_in_USD',
            hint: this.gettext('the value of the deal (except charges)'),
            filters: [
              {
                type: 'text',
                predicate: 'min_price',
                addon: this.gettext('Min'),
              },
              {
                type: 'text',
                predicate: 'max_price',
                addon: this.gettext('Max'),
              },
            ],
            cellTemplate: html`
              <span
                ng-if="item.agreement_create_time"
                class="tooltip"
                data-tip="Price changed by agreement #{[{ item.agreement_number || '---' }]} at {[{ item.agreement_create_time | date:'dd MMM, yyyy, HH:mm' }]}"
              >
                <i class="fa fa-exclamation"></i>
              </span>
              <span class="bigger-label" ng-if="item.price && item.swap">
                {[{ item.price || 0 | number:2 }]}
              </span>
              <span class="bigger-label" ng-if="item.price && !item.swap">
                {[{ item.price || 0 | number:2 }]}
              </span>
              <span ng-if="!item.price">---</span>

              <span ng-if="item.price" class="smaller-label"
                >{[{ item.currency_symbol || args.defaultCurrency}]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.measurement_price && item.measurement_price > 0">
                {[{ item.measurement_price }]} {[{ item.currency_symbol ||
                args.defaultCurrency}]}/{[{ item.measurement_title }]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.measurement_price_kg && item.measurement_price_kg > 0">
                {[{ item.measurement_price_kg }]} {[{ item.currency_symbol ||
                args.defaultCurrency}]}/kg
              </span>
              <div class="clearfix"></div>
              <a
                class="smaller-label label label_default"
                ng-if="item.contractprices_data.length"
                ng-click="item.contractpricesExpanded = !item.contractpricesExpanded"
              >
                <i class="fa fa-anchor"></i> {[{item.contractprices_data.length}]}
              </a>
              <div class="clearfix"></div>
              <contract-prices-info
                ng-if="item.contractprices_data && item.contractpricesExpanded"
                ng-click="item.contractpricesExpanded = !item.contractpricesExpanded"
                contract-prices="item.contractprices_data"
              ></contract-prices-info>
              <div class="clearfix"></div>
              <span class="label-smoke smaller-label" ng-if="item.VAT_value && item.VAT_option"
                ><translate>VAT</translate> {[{ item.VAT_value | number:0}]}%</span
              >
              <span class="label-smoke smaller-label" ng-if="item.vat_return_rate"
                ><translate>VAT return</translate> {[{ item.vat_return_rate | number:0}]}%</span
              >
              <div class="clearfix"></div>
              <span class="label label_warning" ng-if="item.discount_mt">
                <i class="fa fa-exclamation-circle"></i>
                <span class="smaller-label">
                  <translate>discount</translate>
                  {[{ item.discount_mt }]} {[{ item.currency_symbol || args.defaultCurrency}]}/{[{
                  args.measurementUnit }]}
                </span>
              </span>
            `,
            totalTemplate: html`
              <div ng-if="item.total_value">
                <span class="smaller-label label-smoke"><translate>total value</translate></span
                >: {[{ item.total_value || 0 | number:2 }]}
                <span style="font-size:0.8em;"> <translate>$</translate> </span>
              </div>
            `,
          },
          {
            columnName: 'costs',
            title: this.gettext('costs'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.total_charges">
                {[{ item.total_charges | number:2 }]}
                <span
                  class="tooltip smaller-label label-smoke"
                  data-tip="{[{ 'charges per 1 mt'|translate }]}"
                >
                  <span ng-if="item.total_charges > 0">+</span> {[{ item.contractcharges_price_t |
                  number:2 }]}
                  <span class="smaller-label">/ {[{ args.measurementUnit }]} </span>
                </span></span
              >
            `,
          },
          {
            columnName: 'execution_price',
            title: this.gettext('execution price'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-class="{'label label_warning': item.discount_mt}">
                <i class="fa fa-exclamation-triangle" ng-if="item.discount_mt"></i>
                {[{ item.price - item.discount_mt | number:2}]}
                <span ng-if="item.price" class="smaller-label">
                  {[{ item.currency_symbol || args.defaultCurrency}]}
                </span>
              </span>
            `,
          },
          {
            columnName: 'analytical_price',
            title: this.gettext('analytical price'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.analytical_price">
                {[{ item.analytical_price | number:2}]} {[{ item.currency_symbol}]}
              </span>
            `,
          },
          {
            columnName: 'analytical_value',
            title: this.gettext('analytical value'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.analytical_price">
                {[{ item.analytical_value | number:2}]} {[{ item.currency_symbol}]}
              </span>
            `,
            totalTemplate: html`
              <span
                ng-repeat="item in item.currencies_data | orderBy: 'id'"
                ng-if="item.analytical_value"
              >
                {[{ item.analytical_value | number:2 }]} {[{ item.symbol }]}
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'analytical_diff',
            title: this.gettext('analytical diff'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.analytical_price">
                {[{ item.analytical_diff | number:2}]} {[{ item.currency_symbol}]}
              </span>
            `,
            totalTemplate: html`
              <span
                ng-repeat="item in item.currencies_data | orderBy: 'id'"
                ng-if="item.analytical_diff"
              >
                {[{ item.analytical_diff | number:2 }]} {[{ item.symbol }]}
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'invoice_price',
            title: this.gettext('invoice price'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-repeat="useItem in item.invoiceposition_data">
                <span
                  ng-if="useItem.use == 'cargo'"
                  ng-repeat="curItem in useItem.data"
                  ng-class="{'label label_warning': (curItem.amount_sum / (curItem.amount_quantity_sum || 1)) - item.price - item.discount_mt == 0}"
                >
                  <i
                    class="fa fa-exclamation-triangle"
                    ng-if="(curItem.amount_sum / (curItem.amount_quantity_sum || 1)) - item.price - item.discount_mt !=0"
                  ></i>
                  {[{ curItem.amount_sum / (curItem.amount_quantity_sum || 1) | number:2 }]}
                  <span class="smaller-label"> {[{ curItem.currency_symbol }]} </span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
          },

          {
            columnName: 'currency_exchange_rate',
            title: this.gettext('exchange rate'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.currency_exchange_rate || '---' | number:4 }]}
              </span>
            `,
          },
          {
            columnName: 'total_value',
            title: this.gettext('Total value'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label" ng-if="item.total_value">
                {[{ item.total_value || 0 | number:2 }]}
              </span>
            `,
            totalTemplate: html`
              <span ng-repeat="item in item.currencies_data | orderBy: 'id'">
                {[{ item.total_value | number:2 }]} {[{ item.symbol }]}
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'execution_value',
            title: this.gettext('Execution value'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label" ng-if="item.transport_value_basis_sum">
                {[{ item.transport_value_basis_sum || 0 | number:2 }]}
              </span>
              <div class="clearfix"></div>
              <span class="smaller-label label label_success" ng-if="item.transport_value_basis_sum && item.total_value - item.transport_value_basis_sum == 0">
                <i class="fa fa-check-circle"></i>
              </span>
              <span
                class="smaller-label label label_warning"
                ng-if="item.transport_value_basis_sum && item.total_value - item.transport_value_basis_sum !=0"
              >
                {[{ item.total_value - item.transport_value_basis_sum | number:2 }]}
              </span>
              <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'origin',
            title: this.gettext('origin'),
            cellTemplate: html`
              <span ng-if="item.origin_of_crop_title">
                <i class="fa fa-level-up"></i> {[{ item.origin_of_crop_title }]}
              </span>
              <div
                class="smaller-label exporter_set"
                ng-if="!item.origin_of_crop_title && item.origins_of_crop"
                ng-repeat="item in item.origins_of_crop"
              >
                <i class="fa fa-level-up"></i> {[{ item.title }]}
              </div>
            `,
          },
          {
            columnName: 'origins_of_crop',
            title: this.gettext('origins of crop'),
            cellTemplate: html`
              <span
                ng-if="item.origins_of_crop_data"
                ng-repeat="origin_of_crop in item.origins_of_crop_data"
              >
                <i class="fa fa-level-up"></i> {[{ origin_of_crop.title }]}
                <div class="clearfix"></div>
              </span>
            </div>
                `,
          },
          {
            columnName: 'destination',
            title: this.gettext('destination'),
            cellTemplate: html`
              <span ng-if="item.destination_of_crop_title">
                <i class="fa fa-level-down"></i> {[{ item.destination_of_crop_title }]}
              </span>
              <div
                class="smaller-label exporter_set"
                ng-if="!item.destination_of_crop_title && item.destinations_of_crop"
                ng-repeat="item in item.destinations_of_crop"
              >
                <i class="fa fa-level-down"></i> {[{ item.title }]}
              </div>
            `,
          },
          {
            columnName: 'destinations_of_crop',
            title: this.gettext('destinations of crop'),
            cellTemplate: html`
              <span
                ng-if="item.destinations_of_crop_data"
                ng-repeat="destination_of_crop in item.destinations_of_crop_data"
              >
                <i class="fa fa-level-down"></i> {[{ destination_of_crop.title }]}
                <div class="clearfix"></div>
              </span>
            </div>
                `,
          },
          {
            columnName: 'basis',
            title: this.gettext('basis'),
            filters: [
              {
                type: 'ui-select',
                predicate: 'basis',
                label: this.gettext('basis'),
                resource: 'logistics.Basis',
              },
            ],
            cellTemplate: html`
              <span
                class="label label_default"
                ng-if="item.basis_name && !item.contractprices_data.length"
              >
                {[{ item.basis_name }]}
              </span>
              <span ng-repeat="basis in item.contractprices_data | unique:'basis_name'">
                <span class="label label_default">{[{ basis.basis_name }]}</span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'port',
            title: this.gettext('port'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <div class="clearfix"></div>
              <span
                ng-if="item.contractprices_data.length"
                ng-click="item.contractpricesExpanded = !item.contractpricesExpanded"
                class="smaller-label label label_default"
              >
                <i class="fa fa-anchor"></i> {[{item.contractprices_data.length}]}
              </span>
              <div class="clearfix"></div>
              <span
                ng-repeat="port in item.contractprices_data | unique:'port_name'"
                ng-if="item.contractpricesExpanded"
              >
                <i class="fa fa-anchor"></i> {[{ port[$root.user.settings.PORT_NAME_DISPLAY_MODE] ||
                port.port_name | cut:true:30:'...'}]}
                <div class="clearfix"></div>
              </span>
              <span
                ng-if="!item.contractprices_data.length"
                ng-repeat="port in item.port_set | unique:'name'"
              >
                <i class="fa fa-anchor"></i> {[{ port[$root.user.settings.PORT_NAME_DISPLAY_MODE] ||
                port.name | cut:true:30:'...'}]}
                <div class="clearfix"></div>
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.loadport_name">
                <span class="smaller-label label-smoke"><translate>loadport</translate>: </span
                ><i class="fa fa-anchor"></i> {[{ item.loadport_name | cut:true:30:'...'}]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.disport_name">
                <span class="smaller-label label-smoke"><translate>disport</translate>: </span
                ><i class="fa fa-anchor"></i> {[{ item.disport_name | cut:true:30:'...'}]}
              </span>
              <div class="clearfix"></div>
              <!-- TERMINAL -->
              <span ng-if="item.shipment_terminal_name" class="btn-link">
                ({[{ item.shipment_terminal_name | cut:true:30:'...' || "---" }]})
              </span>
            `,
          },
          {
            columnName: 'warehouse',
            title: this.gettext('warehouse'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <div class="clearfix"></div>
              <a
                class="btn btn-xs col-xs-12"
                target="_blank"
                class="smaller-label"
                ng-if="item.warehouse_data.id"
                ui-sref="logistics.warehouseDetails({id: item.warehouse_data.id })"
                style="text-align: left"
              >
                <i class="fa fa-building-o"></i> {[{ item.warehouse_data.title |
                cut:true:30:'...'}]}({[{ item.warehouse_data.type }]})
              </a>
              <div class="clearfix"></div>
              <span>
                <a
                  ng-if="item.warehouses_data.length"
                  ng-click="item.warehousesExpanded = !item.warehousesExpanded"
                  class="smaller-label label label_default"
                  style="text-align: left"
                >
                  <i class="fa fa-building-o"></i> {[{item.warehouses_data.length}]}
                </a>
              </span>
              <div class="clearfix"></div>
              <span
                ng-if="item.warehousesExpanded"
                class="smaller-label"
                ng-repeat="warehouse in item.warehouses_data | unique:'title'"
              >
                <a
                  class="btn btn-xs col-xs-12"
                  target="_blank"
                  ui-sref="logistics.warehouseDetails({id: warehouse.id })"
                  style="text-align: left"
                >
                  <i class="fa fa-building-o"></i> {[{ warehouse.title | cut:true:30:'...'}]}({[{
                  warehouse.type }]})
                </a>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'market_zone',
            title: this.gettext('market zone'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span ng-if="item.market_zone_name"
                ><i class="fa fa-anchor"></i> {[{ item.market_zone_name | cut:true:30:'...'}]}</span
              >
            `,
          },
          {
            columnName: 'execution',
            title: this.gettext('delivery'),
            predicate: 'date_of_execution',
            hint: this.gettext('shipment period of the contract'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span
                ng-if="item.date_of_execution"
                ng-class="{
                  'label label_warning': ((item.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 > 0 && (item.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 < $root.user.settings.DATE_EXECUTION_REMINDER && item.end_of_execution > $root.serverTime) && !item.final_volume,
                  'label label_danger': ((item.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 <= 0 && (item.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 > $root.user.settings.DATE_EXECUTION_ALERT) && !item.final_volume
                }"
              >
                <i class="fa fa-calendar"></i> {[{item.date_of_execution || "---" |
                date:'dd.MM.yy'}]}
                <div class="clearfix"></div>
                <i class="fa fa-calendar"></i> {[{item.end_of_execution || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'arrival_period',
            title: this.gettext('arrival period'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <span ng-if="item.arrival_period_start">
                <i class="fa fa-calendar"></i> {[{item.arrival_period_start || "---" |
                date:'dd.MM.yy'}]}
                <div class="clearfix"></div>
                <i class="fa fa-calendar"></i> {[{item.arrival_period_end || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'business_unit',
            title: this.gettext('Business unit'),
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'business_unit_list',
                label: this.gettext('business unit'),
                resource: 'core.BusinessUnit',
              },
            ],
            cellTemplate: html`
              <i class="fa fa-home"></i> {[{ item.business_unit_title || '---' | cut:true:30:' ...'
              }]}
            `,
          },

          {
            columnName: 'options',
            title: this.gettext('option'),
            hint: this.gettext('the actual tolerance range of the contract'),
            class: 'td-right-align',
            cellTemplate: html`
              <div ng-if="item.volume">
                <span ng-if="item.volume_options">
                  <div class="clearfix"></div>
                  {[{ item.volume_options || 0 | number:1}]}% - {[{ (item.volume * 1) -
                  ((item.volume * 1) * item.volume_options / 100) || 0 | number:3 }]} + {[{
                  (item.volume * 1) + ((item.volume * 1) * item.volume_options / 100) || 0 |
                  number:3 }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'open_balance',
            title: this.gettext('open balance'),
            hint: this.gettext('the open balance of the contract'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.open_balance">
                <i class="fa fa-industry"></i> {[{ item.open_balance || 0 | number:3 }]}
              </span>
            `,
            totalTemplate: html`
              <span class="smaller-label label-smoke"><translate>open volume</translate></span>
              <div class="clearfix"></div>
              <i class="fa fa-industry"></i> {[{ item.open_volume_sum || 0 | number:3}]}
            `,
            totalBlockTemplates: [
              html`
                <span class="label">
                  <i class="fa fa-industry"></i> {[{ item.open_volume_sum || 0 | number:3 }]}
                  <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>open volume</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'min_batch_of_payment',
            title: this.gettext('min payment / not invoiced'),
            class: 'td-right-align',
            cellTemplate: html`
              <span
                ng-if="item.min_batch_of_payment && !item.invoiceposition_data.length"
                ng-class="{'label label_success': item.min_batch_of_payment >=item.transport_volume_basis, 'label label_danger': item.min_batch_of_payment < item.transport_volume_basis}"
              >
                {[{ item.min_batch_of_payment || 0 | number:3 }]}
                <span ng-if="item.transport_volume_basis">
                  / {[{ item.transport_volume_basis || 0 | number:3 }]}</span
                >
                <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
              </span>
              <span
                ng-repeat="useItem in item.invoiceposition_data"
                ng-if="item.invoiceposition_data.length && item.min_batch_of_payment"
              >
                <span ng-if="useItem.use == 'cargo'" ng-repeat="curItem in useItem.data">
                  <span
                    ng-if="curItem.currency_id == item.currency"
                    ng-class="{'label label_success': item.min_batch_of_payment >=((item.transport_value_basis_sum - curItem.amount_sum) / (item.price || 1)), 'label label_danger': item.min_batch_of_payment < ((item.transport_value_basis_sum - curItem.amount_sum) / (item.price || 1))}"
                  >
                    {[{ item.min_batch_of_payment | number:3 }]} /
                    {[{(item.transport_value_basis_sum - curItem.amount_sum) / (item.price || 1) |
                    number:3}]}
                    <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                  </span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
          },
          {
            columnName: 'departed',
            title: this.gettext('departed'),
            hint: this.gettext('the volume, departed to port'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.volume_departed">
                <i class="fa fa-arrow-right"></i> {[{ item.volume_departed || 0 | number:3 }]}
              </span>
            `,
            totalTemplate: html`
              <span class="smaller-label label-smoke"><translate>departed</translate></span>
              <div class="clearfix"></div>
              <i class="fa fa-arrow-right"></i> {[{item.transport_volume_departed_true_sum || 0 |
              number:3}]}
            `,
            totalBlockTemplates: [
              html`
                <span class="label">
                  <i class="fa fa-arrow-right"></i> {[{ item.transport_volume_departed_true_sum || 0
                  | number:3 }]}
                  <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>departed</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'road',
            title: this.gettext('road'),
            hint: this.gettext('the volume,that is currently on the road '),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.on_cars">
                <i class="fa fa-train"></i> {[{ item.on_cars || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`
              <span class="smaller-label label-smoke"><translate>on road</translate></span>
              <div class="clearfix"></div>
              <i class="fa fa-train"></i> {[{item.transport_volume_on_road_sum || 0 | number:3}]}
            `,
            totalBlockTemplates: [
              html`
                <span class="label">
                  <i class="fa fa-train"></i> {[{ item.transport_volume_on_road_sum || 0 | number:3
                  }]}
                  <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>on road</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'delivered',
            title: this.gettext('delivered'),
            hint: this.gettext('the volume, delivered to port'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.at_port">
                <i class="fa fa-anchor"></i> {[{ item.at_port || 0 | number:3 }]}
              </span>
            `,
            totalTemplate: html`
              <span class="smaller-label label-smoke"><translate>delivered</translate></span>
              <div class="clearfix"></div>
              <i class="fa fa-anchor"></i> {[{item.transport_volume_at_port_sum || 0 | number:3}]}
            `,
            totalBlockTemplates: [
              html`
                <span class="label">
                  <i class="fa fa-anchor"></i> {[{ item.transport_volume_at_port_sum || 0 | number:3
                  }]}
                  <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>at port</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'transport_volume_basis',
            title: this.gettext('Executed'),
            hint: this.gettext('the volume, from transport by basis'),
            class: 'td-right-align',
            cellTemplate: html`
              <div class="progress" ng-if="!vm.edit">
                <div
                  ng-if="item.transport_volume_basis"
                  ng-class="{'progress_bar_danger': item.open_balance < 0, 'progress_bar_warning': item.open_balance > 0, 'progress_bar_default': item.open_balance == 0 }"
                  class="progress-bar"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: {[{
                (item.transport_volume_basis / (item.transport_volume_basis + item.open_balance || 1)) *
                  100 | number: 2
              }]}%"
                >
                  {[{ item.transport_volume_basis | number: 3 }]} / {[{ item.open_balance || 0 |
                  number: 3 }]}
                </div>
              </div>
            `,
            totalTemplate: html`
              <span class="smaller-label label-smoke"><translate>executed</translate></span>
              <div class="clearfix"></div>
              <i class="fa fa-anchor"></i> {[{item.transport_volume_basis_sum || 0 | number:3}]} /
              {[{ item.open_volume_sum || 0 | number:3}]}
            `,
          },
          {
            columnName: 'final',
            title: this.gettext('final'),
            hint: this.gettext('final volume of the contract'),
            class: 'td-right-align',
            cellTemplate: html`
              <span
                class="btn btn-xs btn-green-border"
                ng-click="args.setFinalVolumeFromExecution(item.id)"
              >
                <i class="fa fa-check-circle"></i> <translate>Final</translate>
              </span>
              <span ng-if="item.final_volume">
                <i class="fa fa-check-circle"></i> {[{item.final_volume || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`
              <span class="smaller-label label-smoke"><translate>final</translate></span>
              <div class="clearfix"></div>
              <i class="fa fa-check-circle"></i> {[{item.final_volume_sum || 0 | number:3}]}
            `,
          },
          {
            columnName: 'transport_open_balance_volume',
            title: this.gettext('transport open balance volume'),
            hint: this.gettext('the volume, for transport from open balance calculation'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.transport_open_balance_volume">
                {[{ item.transport_open_balance_volume || 0 | number:3 }]}
                <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                <div class="clearfix"></div>
                <span
                  class="btn btn-xs btn-green-border"
                  ng-click="args.setFinalVolumeFromExecution(item.id)"
                >
                  <i class="fa fa-check-circle"></i> <translate>Final</translate>
                </span>
              </span>
            `,
          },
          {
            columnName: 'dbls_volume_sum',
            title: this.gettext('Disbursement bl volume'),
            class: 'td-right-align',
            cellTemplate: html`
              {[{ item.dbls_volume_sum || 0 | number:2 }]} {[{ args.measurementUnit }]}
            `,
          },
          {
            columnName: 'dbls_amount_sum',
            title: this.gettext('Disbursement bl value'),
            class: 'td-right-align',
            cellTemplate: html`
              {[{ item.dbls_amount_sum || 0 | number:2 }]} {[{ $root.user.settings.LOCAL_CURRENCY
              }]}
            `,
          },
          {
            columnName: 'dbls_sum_amount',
            title: this.gettext('Disbursement bl volume'),
            class: 'td-right-align',
            cellTemplate: html`
              {[{ item.dbls_sum_amount || 0 | number:2 }]} {[{ args.measurementUnit }]}
            `,
          },
          {
            columnName: 'dbls_sum_volume',
            title: this.gettext('Disbursement bl value'),
            class: 'td-right-align',
            cellTemplate: html`
              {[{ item.dbls_sum_volume || 0 | number:2 }]} {[{ args.measurementUnit }]}
            `,
          },
          {
            columnName: 'reassignment_value_sum',
            title: this.gettext('Reassignment value'),
            class: 'td-right-align',
            cellTemplate: html`
              <i class="fa fa-reply-all"></i> {[{ item.reassignment_value_sum || 0 | number:2 }]}
              {[{ $root.user.settings.LOCAL_CURRENCY }]}
            `,
            totalTemplate: html`
              <span>
                {[{ item.reassignments_sum || 0 | number:2 }]} {[{
                $root.user.settings.LOCAL_CURRENCY }]}
              </span>
              <div class="clearfix"></div>
            `,
          },
          {
            columnName: 'financeoffset_value_sum',
            title: this.gettext('Finance offset value'),
            class: 'td-right-align',
            cellTemplate: html`
              <i class="fa fa-magnet"></i> {[{ item.financeoffset_value_sum || 0 | number:2 }]} {[{
              $root.user.settings.LOCAL_CURRENCY }]}
            `,
            totalTemplate: html`
              <span>
                {[{ item.offsetposition_sum || 0 | number:2 }]} {[{
                $root.user.settings.LOCAL_CURRENCY }]}
              </span>
              <div class="clearfix"></div>
            `,
          },
          {
            columnName: 'mtm_price',
            title: this.gettext('mtm price'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.mtm_price">
                {[{ item.mtm_price || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
          },
          {
            columnName: 'selfcost',
            title: this.gettext('selfcost'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.selfcost">
                {[{ item.selfcost || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
          },
          {
            columnName: 'invoiced',
            title: this.gettext('invoiced'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-repeat="useItem in item.invoiceposition_data">
                <span ng-if="useItem.use == 'cargo'" ng-repeat="curItem in useItem.data">
                  {[{ curItem.amount_sum | number:2 }]} {[{ curItem.currency_symbol }]}
                  <div class="clearfix"></div>
                  <span
                    class="smaller-label label label_success"
                    ng-if="(curItem.currency_id == item.currency) && item.transport_value_basis_sum && curItem.amount_sum - item.transport_value_basis_sum ==0"
                  >
                    <i class="fa fa-check-circle"></i> <translate>full invoiced</translate>
                  </span>
                  <span
                    class="smaller-label label label_warning"
                    ng-if="(curItem.currency_id == item.currency) && item.transport_value_basis_sum && curItem.amount_sum - item.transport_value_basis_sum !=0"
                  >
                    {[{ curItem.amount_sum - item.transport_value_basis_sum | number:2 }]} {[{
                    curItem.currency_symbol }]}
                  </span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
            totalTemplate: html`
              <span ng-repeat="item in item.currencies_data | orderBy: 'id'">
                {[{ item.invoiced_value | number:2 }]} {[{ item.symbol }]}
                <div class="clearfix"></div>
                <span class="smaller-label label-opacity">
                  {[{ item.invoiced_volume | number:3 }]} {[{ args.measurementUnit }]}
                </span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'not_invoiced',
            title: this.gettext('not invoiced'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-repeat="useItem in item.invoiceposition_data">
                <span ng-if="useItem.use == 'cargo'" ng-repeat="curItem in useItem.data">
                  <span ng-if="curItem.currency_id == item.currency">
                    {[{ item.transport_value_basis_sum - curItem.amount_sum | number:2 }]} {[{
                    curItem.currency_symbol }]}
                  </span>
                  <div class="clearfix"></div>
                  <span
                    class="smaller-label label-opacity"
                    ng-if="curItem.currency_id == item.currency"
                  >
                    {[{ (item.transport_value_basis_sum - curItem.amount_sum) / (item.price || 1) |
                    number:2 }]} {[{ args.measurementUnit }]}
                  </span>
                  <span ng-if="curItem.currency_id != item.currency">---</span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
            totalTemplate: html`
              <span ng-repeat="item in item.currencies_data | orderBy: 'id'">
                {[{ item.not_invoiced_value | number:2 }]} {[{ item.symbol }]}
                <div class="clearfix"></div>
                <span class="smaller-label label-opacity">
                  {[{ item.not_invoiced_volume | number:3 }]} {[{ args.measurementUnit }]}
                </span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'paid',
            title: this.gettext('paid'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-repeat="useItem in item.invoiceposition_data">
                <span ng-if="useItem.use == 'cargo'" ng-repeat="curItem in useItem.data">
                  {[{ curItem.payment_sum | number:2 }]} {[{ curItem.currency_symbol }]}
                  <div class="clearfix"></div>
                  <span
                    class="smaller-label label label_success"
                    ng-if="(curItem.currency_id == item.currency) && curItem.amount_sum && curItem.payment_sum - curItem.amount_sum == 0"
                  >
                    <i class="fa fa-check-circle"></i> <translate>full paid</translate>
                  </span>
                  <span
                    class="smaller-label label label_warning"
                    ng-if="(curItem.currency_id == item.currency) && curItem.amount_sum && curItem.payment_sum - curItem.amount_sum != 0"
                  >
                    {[{ curItem.amount_sum - curItem.payment_sum | number:2 }]} {[{
                    curItem.currency_symbol }]}
                  </span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
            totalTemplate: html`
              <span ng-repeat="item in item.currencies_data | orderBy: 'id'">
                {[{ item.payment_value | number:2 }]} {[{ item.symbol }]}
                <div class="clearfix"></div>
                <span class="smaller-label label-opacity">
                  {[{ item.payment_volume | number:3 }]} {[{ args.measurementUnit }]}
                </span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'balance',
            title: this.gettext('balance'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-repeat="useItem in item.invoiceposition_data">
                <span ng-if="useItem.use == 'cargo'" ng-repeat="curItem in useItem.data">
                  {[{ curItem.balance | number:2 }]} {[{ curItem.currency_symbol }]}
                  <div class="clearfix"></div>
                  <span
                    class="smaller-label label-opacity"
                    ng-if="curItem.currency_id == item.currency"
                  >
                    {[{ curItem.balance / (item.price || 1) | number:2 }]} {[{ args.measurementUnit
                    }]}
                  </span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
            totalTemplate: html`
              <span ng-repeat="item in item.currencies_data | orderBy: 'id'">
                {[{ item.balance_value | number:2 }]} {[{ item.symbol }]}
                <div class="clearfix"></div>
                <span class="smaller-label label-opacity">
                  {[{ item.balance_volume | number:3 }]} {[{ args.measurementUnit }]}
                </span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'balance_payment',
            title: this.gettext('balance with payment'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-repeat="useItem in item.invoiceposition_data">
                <span ng-if="useItem.use == 'cargo'" ng-repeat="curItem in useItem.data">
                  <span ng-if="curItem.currency_id == item.currency">
                    {[{ item.total_value - curItem.payment_sum | number:2 }]} {[{
                    curItem.currency_symbol }]}
                  </span>
                  <span ng-if="curItem.currency_id != item.currency">
                    {[{ curItem.payment_sum | number:2 }]} {[{ curItem.currency_symbol }]}
                  </span>
                  <div class="clearfix"></div>
                  <span
                    class="smaller-label label-opacity"
                    ng-if="curItem.currency_id == item.currency"
                  >
                    {[{ curItem.payment_sum / (item.price || 1) - item.true_volume | number:2 }]}
                    {[{ args.measurementUnit }]}
                  </span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
            totalTemplate: html`
              <span ng-repeat="item in item.currencies_data | orderBy: 'id'">
                {[{ item.balance_payment_value | number:2 }]} {[{ item.symbol }]}
                <div class="clearfix"></div>
                <span class="smaller-label label-opacity">
                  {[{ item.balance_payment_volume | number:3 }]} {[{ args.measurementUnit }]}
                </span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'debit_credit',
            title: this.gettext('debit/credit'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-repeat="useItem in item.invoiceposition_data">
                <span ng-if="useItem.use == 'cargo'" ng-repeat="curItem in useItem.data">
                  <span ng-if="curItem.currency_id == item.currency">
                    {[{ item.transport_value_basis_sum - curItem.payment_sum | number:2 }]} {[{
                    curItem.currency_symbol }]}
                  </span>
                  <div class="clearfix"></div>
                  <span
                    class="smaller-label label-opacity"
                    ng-if="curItem.currency_id == item.currency"
                  >
                    {[{ (item.transport_value_basis_sum - curItem.payment_sum) / (item.price || 1) |
                    number:2 }]} {[{ args.measurementUnit }]}
                  </span>
                  <span ng-if="curItem.currency_id != item.currency">---</span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
            totalTemplate: html`
              <span ng-repeat="item in item.currencies_data | orderBy: 'id'">
                {[{ item.debit_credit_value | number:2 }]} {[{ item.symbol }]}
                <div class="clearfix"></div>
                <span class="smaller-label label-opacity">
                  {[{ item.debit_credit_volume | number:3 }]} {[{ args.measurementUnit }]}
                </span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'terminal_certs_logsitcs_set',
            title: this.gettext('logistics by terminal'),
            hint: this.gettext('logistics to invoice by terminal'),
            class: 'td-right-align',
            cellTemplate: html`
              <table class="table-responsive table-no-total table" style="margin-bottom: 0">
                <tbody>
                  <tr
                    ng-repeat="terminal in item.terminal_certs_logsitcs_set"
                    ng-if="terminal.prepay_volume > 0 || terminal.balance_volume > 0"
                  >
                    <td class="td-right-align">{[{ terminal.name }]}</td>
                    <td class="td-right-align">
                      <a
                        class="btn btn-xs btn-success"
                        ng-if="terminal.prepay_volume > 0"
                        href="{[{ terminal.prepayref }]}"
                        target="_blank"
                      >
                        <i class="fa fa-credit-card-alt"></i> {[{ terminal.prepay_volume }]}
                        <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                      </a>
                      <span ng-if="terminal.prepay_volume <= 0">-</span>
                    </td>
                    <td class="td-right-align">
                      <a
                        class="btn btn-xs btn-success"
                        ng-if="terminal.balance_volume > 0"
                        href="{[{ terminal.balanceref }]}"
                        target="_blank"
                      >
                        <i class="fa fa-credit-card-alt"></i> {[{ terminal.balance_volume }]}
                        <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                      </a>
                      <span ng-if="terminal.balance_volume <= 0">-</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            `,
          },
          {
            columnName: 'cargo_confirm',
            title: this.gettext('cargo confirm'),
            hint: this.gettext('contract is confirmed by cargo'),
            cellTemplate: html`
              <div class="alert-success" ng-if="item.cargo_confirmation">
                <i class="fa fa-check-circle" style="font-size: 16px !important;"></i>
              </div>
            `,
          },
          {
            columnName: 'conditions',
            title: this.gettext('conditions'),
            hint: this.gettext(
              'the number of issued invoiced by this contract. To know more - go to contract details',
            ),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.payment_conditions">
                <i class="fa fa-train"></i> {[{ item.payment_conditions }]}%
              </span>
              <span ng-if="item.payment_conditions_auto">
                <i class="fa fa-truck"></i> {[{ item.payment_conditions_auto }]}%
              </span>
            `,
          },
          {
            columnName: 'payment_confirm',
            title: this.gettext('payment confirm'),
            cellTemplate: html`
              <div class="alert-success" ng-if="item.payment_confirmation">
                <i class="fa fa-check-circle" style="font-size: 16px !important;"></i>
              </div>
            `,
          },
          {
            columnName: 'final_confirm',
            title: this.gettext('final confirm'),
            cellTemplate: html`
              <div class="alert-success" ng-if="item.final_confirmation">
                <i class="fa fa-check-circle" style="font-size: 16px !important;"></i>
              </div>
            `,
          },
          {
            columnName: 'internal_purchases',
            title: this.gettext('internal purchases'),
            hint: this.gettext('caontacts logistic prices'),
            class: 'td-right-align',
            cellTemplate: html`
              <div
                class="td-left-align"
                ng-repeat="logistic in item.internal_purchases"
                style="min-width: 350px;"
              >
                <span class="smaller-label exporter_set"
                  >{[{ logistic.crop_title }]}: {[{ logistic.price_in_USD || 0 | number:2 }]}
                </span>
                <span class="smaller-label">
                  <i class="fa fa-arrow-right"></i> {[{ logistic.volume_departed || 0 | number:3 }]}
                  / <i class="fa fa-train"></i> {[{ logistic.on_cars || 0 | number:3}]} /
                  <i class="fa fa-anchor"></i> {[{ logistic.volume_received || 0 | number:3 }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'approvals',
            title: this.gettext('approvals'),
            hint: this.gettext('the list of users, who approved this deal'),
            class: 'td-right-align',
            cellTemplate: html`
              <div class="inside-table-items" ng-repeat="approval in item.approvals">
                <div ng-if="approval.approved || approval.declined">
                  <div class="user-avatar" ng-if="approval.user_avatar.length != 0">
                    <img ng-src="/pictures/{[{approval.user_avatar}]}" />
                  </div>
                  <div class="no-user-avatar" ng-if="approval.user_avatar.length == 0">
                    {[{ approval.user_name | cut:true:1:' ' }]}
                  </div>
                  <i ng-if="!approval.approved && !approval.declined" class="fa fa-circle-o"></i>
                  <i ng-if="approval.approved" class="fa fa-check-circle"></i>
                  <span ng-if="approval.declined" class="negative-number">
                    <i class="fa fa-times-circle"></i>
                  </span>
                </div>
              </div>
            `,
          },
          {
            columnName: 'quality',
            title: this.gettext('quality'),
            hint: this.gettext('the list of quality for this deal'),
            class: 'td-right-align',
            cellTemplate: html`
              <div class="smaller-label exporter_set" ng-repeat="quality in item.qualities">
                <i class="fa fa-flask"></i> {[{ quality.title }]} - {[{ quality.value }]}
              </div>
            `,
          },
          {
            columnName: 'request_crop_protection_amount',
            title: this.gettext('Crop protection'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.request_crop_protection || 0 | number:2 }]} {[{
                $root.user.settings.LOCAL_CURRENCY }]}
              </span>
            `,
            totalTemplate: html`
              <span>
                {[{item.crop_protection_sum || 0 | number:2}]} {[{
                $root.user.settings.LOCAL_CURRENCY }]}
              </span>
            `,
          },
          {
            columnName: 'request_seeds_amount',
            title: this.gettext('Seeds'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.request_seeds || 0 | number:2 }]} {[{ $root.user.settings.LOCAL_CURRENCY
                }]}
              </span>
            `,
            totalTemplate: html`
              <span>
                {[{item.seeds_sum || 0 | number:2}]} {[{ $root.user.settings.LOCAL_CURRENCY }]}
              </span>
            `,
          },
          {
            columnName: 'plan_distributors',
            title: this.gettext('Planned distributors'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <div ng-repeat="distributor in item.plan_distributors">
                <span><i class="fa fa-truck"></i> {[{ distributor }]}</span>
                <div class="clearfix"></div>
              </div>
            `,
          },
          {
            columnName: 'fact_distributors',
            title: this.gettext('Fact distributors'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <div ng-repeat="distributor in item.fact_distributors">
                <span><i class="fa fa-truck"></i> {[{ distributor }]}</span>
                <div class="clearfix"></div>
              </div>
            `,
          },
          {
            columnName: 'fact_crop_protection_sum',
            title: this.gettext('Fact crop protection'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.fact_crop_protection_sum || 0 | number:2 }]} {[{
                $root.user.settings.LOCAL_CURRENCY }]}
              </span>
            `,

            totalTemplate: html`
              <span>
                {[{item.fact_crop_protection_sum || 0 | number:2}]} {[{
                $root.user.settings.LOCAL_CURRENCY }]}
              </span>
            `,
          },
          {
            columnName: 'fact_seeds_sum',
            title: this.gettext('Fact seeds'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.fact_seeds_sum || 0 | number:2 }]} {[{ $root.user.settings.LOCAL_CURRENCY
                }]}
              </span>
            `,
            totalTemplate: html`
              <span>
                {[{ item.fact_seeds_sum || 0 | number:2 }]} {[{ $root.user.settings.LOCAL_CURRENCY
                }]}
              </span>
            `,
          },
          {
            columnName: 'reassignment_list',
            title: this.gettext('Reassignment list'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <div ng-repeat="reassignment in item.reassignment_list">
                <a ui-sref=" gt.reassignment({id: reassignment.id })" target="_blank">
                  <span># {[{ reassignment.number }]}</span>
                </a>
                <div class="clearfix"></div>
              </div>
            `,
          },
          {
            columnName: 'financeoffset_list',
            title: this.gettext('Offset list'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
              <div ng-repeat="offset in item.financeoffset_list">
                <a ui-sref=" gt.offset({id: offset.id })" target="_blank">
                  <span># {[{ offset.number }]}</span>
                </a>
                <div class="clearfix"></div>
              </div>
            `,
          },
          {
            columnName: 'district',
            title: this.gettext('District'),
            class: 'td-right-align',
            cellTemplate: html`<span class="bigger-label"> {[{ item.district_title }]} </span>`,
          },
          {
            columnName: 'percentage',
            title: this.gettext('Percentage'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label"> {[{ item.percentage || 0 | number:2 }]} % </span>
            `,
          },
          {
            columnName: 'contract_option_title',
            title: this.gettext('Contract option'),
            class: 'td-left-align',
            cellTemplate: html`
              <span class="bigger-label"> {[{ item.contract_option_title }]} </span>
            `,
          },
          {
            columnName: 'vehicle_restrictions',
            title: this.gettext('Vehicle restrictions'),
            class: ' td-left-align  small-font-cell',
            cellTemplate: html`
              <span class="label" ng-if="item.vehicle_restrictions">
                {[{item.vehicle_restrictions | cut:true:40:'...' || "---" }]}
              </span>
            `,
          },
          {
            columnName: 'fixed_price_bonus',
            title: this.gettext('fixed price bonus'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label" ng-if="item.fixed_price_bonus">
                {[{ item.fixed_price_bonus || 0 | number:2 }]}
              </span>
              <span ng-if="!item.fixed_price_bonus">---</span>
            `,
          },
          {
            columnName: 'regional_managers',
            title: this.gettext('regional managers'),
            class: 'td-left-align',
            cellTemplate: html`
              <span class="exporter_set" ng-if="item.regional_managers.length">
                <span ng-repeat="regional_manager in item.regional_managers">
                  {[{ regional_manager.first_name }]} {[{ regional_manager.last_name }]}
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
          },
          {
            columnName: 'conclusion_price',
            title: this.gettext('conclusion price'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label" ng-if="item.conclusion_price">
                {[{ item.conclusion_price || 0 | number:2 }]}
              </span>
              <span ng-if="!item.conclusion_price">---</span>
            `,
          },
          {
            columnName: 'ending_status',
            title: this.gettext('Ending status'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label" ng-if="item.ending_status">
                {[{ item.ending_status}]}
              </span>
              <span ng-if="!item.ending_status">---</span>
            `,
          },
          {
            columnName: 'days_for_status_ending',
            title: this.gettext('Days before ending status'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label" ng-if="item.days_for_status_ending">
                {[{ item.days_for_status_ending}]}
              </span>
              <span ng-if="!item.days_for_status_ending">---</span>
            `,
          },
          {
            columnName: 'loadingShortage',
            title: this.gettext('Loading shortage (boarding losses)'),
            class: 'td-right-align',
            cellTemplate: html`
              <span
                ng-if="!!item.transport_loading_shortage"
                ng-class="{
                  'negative-number': item.transport_loading_shortage < 0,
                  'positive-number': item.transport_loading_shortage > 0
                }"
              >
                {[{ item.transport_loading_shortage || 0 | gtDecimal:3 }]}
                <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
              </span>
            `,
            totalTemplate: html`
              <span
                ng-if="item.transport_loading_shortage_sum !=0"
                ng-class="{
                  'negative-number': item.transport_loading_shortage_sum < 0,
                  'positive-number': item.transport_loading_shortage_sum > 0
                }"
              >
                {[{ item.transport_loading_shortage_sum || 0 | gtDecimal:3 }]}
                <span class="smaller-label">{[{args.measurementUnit}]}</span>
              </span>
              <span ng-if="item.transport_loading_shortage_sum == 0"> 0 </span>
            `,
          },
          {
            columnName: 'physicalMargin',
            title: this.gettext('Physical margin'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.physical_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
            totalTemplate: html`
              <span>
                {[{ item.physical_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
          },
          {
            columnName: 'derivativeMargin',
            title: this.gettext('Derivative margin'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.derivative_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
            totalTemplate: html`
              <span>
                {[{ item.derivative_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
          },
          {
            columnName: 'mtmMargin',
            title: this.gettext('Mtm margin'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.mtm_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
            totalTemplate: html`
              <span> {[{ item.mtm_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]} </span>
            `,
          },
          {
            columnName: 'totalMargin',
            title: this.gettext('Total margin'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.total_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
            totalTemplate: html`
              <span> {[{ item.total_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]} </span>
            `,
          },
          {
            columnName: 'analyticalPhysicalMargin',
            title: this.gettext('analytical physical margin'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.physical_analytical_margin || 0 | number:2 }]} {[{args.defaultCurrency}]}
              </span>
            `,
            totalTemplate: html`
              <span>
                {[{ item.physical_analytical_margin || 0 | number:2 }]} {[{args.defaultCurrency}]}
              </span>
            `,
          },
          {
            columnName: 'analyticalDerivativeMargin',
            title: this.gettext('analytical derivative margin'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.derivative_analytical_margin || 0 | number:2 }]} {[{args.defaultCurrency}]}
              </span>
            `,
            totalTemplate: html`
              <span>
                {[{ item.derivative_analytical_margin || 0 | number:2 }]} {[{args.defaultCurrency}]}
              </span>
            `,
          },
          {
            columnName: 'analyticalMtmMargin',
            title: this.gettext('analytical MTM margin'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.mtm_analytical_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
            totalTemplate: html`
              <span>
                {[{ item.mtm_analytical_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
          },
          {
            columnName: 'analyticalTotalMargin',
            title: this.gettext('analytical total margin'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label">
                {[{ item.total_analytical_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
            totalTemplate: html`
              <span>
                {[{ item.total_analytical_margin || 0 | number:2 }]} {[{ args.defaultCurrency }]}
              </span>
            `,
          },
          {
            columnName: 'days_for_invoice_payment',
            title: this.gettext('Number of days for payment'),
            class: 'td-left-align',
            predicate: 'days_for_invoice_payment',
            cellTemplate: html`
              <span> {[{ item.days_for_invoice_payment | number:0 }]} </span>
              <span class="smaller-label"><translate>days</translate></span>
            `,
          },
        ];

        if (this.fullQueryParams.contract_type === 'purchase') {
          options.columnDefs.push({
            columnName: 'unconnected_stock',
            title: this.gettext('Unconnected Stock'),
            class: 'td-right-align',
            cellTemplate: html`
              <span class="bigger-label"> {[{ item.unconnected_stock || 0 | number:2 }]} </span>
            `,
            totalTemplate: html`
              <span> {[{ item.unconnected_stock_sum || 0 | number:2 }]} </span>
            `,
          });
          options.tabs
            .filter((tab: any) => tab.serializer === 'table_info')
            .forEach((tab: any) => tab.columns.push('unconnected_stock'));
        }

        const extraColumnDefs: any = [
          {
            columnName: 'transport_volume_departed',
            title: this.gettext('Departed w/o consignment'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.transport_volume_departed">
                {[{ item.transport_volume_departed || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`{[{item.transport_volume_departed_sum || 0 | number:3}]} `,
          },
          {
            columnName: 'transport_volume_departed_consignment',
            title: this.gettext('Departed consignment'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.transport_volume_departed_consignment">
                {[{ item.transport_volume_departed_consignment || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`
              {[{item.transport_volume_departed_consignment_sum || 0 | number:3}]}
            `,
          },
          {
            columnName: 'transport_volume_departed_real',
            title: this.gettext('Departed real'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.transport_volume_departed_real">
                {[{ item.transport_volume_departed_real || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`{[{item.transport_volume_departed_real_sum || 0 | number:3}]} `,
          },
          {
            columnName: 'volume_received',
            title: this.gettext('Received'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.transport_volume_received">
                {[{ item.transport_volume_received || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`{[{item.transport_volume_received_sum || 0 | number:3}]} `,
          },
          {
            columnName: 'billofladings_volume_sum',
            title: this.gettext('BL Volume'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.all_billofladings_volume_sum">
                {[{ item.all_billofladings_volume_sum || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`{[{item.all_billofladings_volume_sum_total || 0 | number:3}]} `,
          },
          {
            columnName: 'billofladings_unloading_volume_sum',
            title: this.gettext('Unloading volume'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.all_billofladings_unloading_volume_sum">
                {[{ item.all_billofladings_unloading_volume_sum || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`{[{item.all_billofladings_unloading_volume_sum_total || 0 |
            number:3}]} `,
          },
          {
            columnName: 'volume_boarded',
            title: this.gettext('Boarded'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.transport_volume_boarded">
                {[{ item.transport_volume_boarded || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`{[{item.transport_volume_boarded_sum || 0 | number:3}]} `,
          },
          {
            columnName: 'blsLossesVolume',
            title: this.gettext('BLs losses volume'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.billofladings_losses_volume_sum">
                {[{ item.billofladings_losses_volume_sum || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`{[{item.billofladings_losses_volume_sum || 0 | number:3}]} `,
          },
          {
            columnName: 'logisticsLossesVolume',
            title: this.gettext('Logistics losses volume'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.transport_volume_losses">
                {[{ item.transport_volume_losses || 0 | number:3}]}
              </span>
            `,
            totalTemplate: html`{[{item.transport_volume_losses || 0 | number:3}]} `,
          },
          {
            columnName: 'paymentConditionsOptions',
            title: this.gettext('Payment conditions options'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.payment_conditions_option_title">
                {[{ item.payment_conditions_option_title }]}
                <div class="clearfix"></div>
              </span>
              <span ng-repeat="option in item.payment_conditions_options_data">
                <span>{[{ option.title }]}</span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'generalagreement',
            title: this.gettext('general agreement'),
            class: 'td-left-align',
            cellTemplate: html`
              <span class="bigger-label" ng-if="item.generalagreement_number">
                <i class="fa fa-handshake-o"></i> {[{ item.generalagreement_number }]}
                <span ng-if="item.generalagreement_percent_conditions">
                  {[{ item.generalagreement_percent_conditions }]} %
                </span>
              </span>
            `,
          },
          {
            columnName: 'derivative',
            title: this.gettext('derivative'),
            class: 'td-left-align',
            cellTemplate: html`
              <span class="bigger-label" ng-repeat="derivative in item.derivative_list">
                {[{ derivative.derivative_title }]}
                <div class="clearfix"></div>
                <span class="smaller-label">
                {[{ derivative.price_premium }]} <translate>premium</translate>
                 </span>
                 <div class="clearfix"></div
              </span>
            `,
          },
          {
            columnName: 'customs',
            title: this.gettext('customs'),
            class: 'td-left-align',
            cellTemplate: html`
              <span class="bigger-label" ng-repeat="ccd in item.ccd_data">
                {[{ ccd.customs_name }]}
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'saleContractByPassportBuyers',
            title: this.gettext('final buyer'),
            class: 'td-left-align',
            cellTemplate: html`
              <span
                class="bigger-label"
                ng-repeat="contract in item.sale_contract_by_passport_data | unique:'sale_contract_buyer_name'"
              >
                {[{ contract.sale_contract_buyer_name }]}
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'intermediateContractByPassportBuyers',
            title: this.gettext('Intermediate buyer'),
            class: 'td-left-align',
            cellTemplate: html`
              <span
                class="bigger-label"
                ng-repeat="contract in item.intermediate_contract_by_passport_data | unique:'intermediate_contract_buyer_name'"
              >
                {[{ contract.intermediate_contract_buyer_name }]}
                <div class="clearfix"></div>
              </span>
            `,
          },
        ];

        options.columnDefs.push(...extraColumnDefs);

        options.columnDefs.push(
          {
            columnName: 'info',
            title: this.gettext('info'),
            class: ' td-left-align  small-font-cell',
            cellTemplate: html`
              <span
                ng-class="{tooltip: item.additional_info, 'label-opacity': !item.additional_info}"
                data-tip="{[{item.additional_info}]}"
              >
                <i class="fa fa-comment"></i> {[{item.additional_info || '---' | cut:true:30:'
                ...'}]}
              </span>
            `,
          },
          {
            columnName: 'editor',
            title: this.gettext('editor'),
            class: 'small-font-cell',
            filters: [
              {
                type: 'ui-select',
                predicate: 'editor',
                label: this.gettext('editor'),
                resource: 'auth.user',
              },
            ],
            cellTemplate: html`
              <span
                class="tooltip"
                data-tip="{[{ 'last updated' | translate }]} - {[{item.update_time | date:'dd.MM.yy HH:mm'}]} - {[{ item.editor_first_name }]} {[{ item.editor_last_name }]}, {[{ 'created' | translate }]} - {[{item.conclusion_date | date:'dd.MM.yy HH:mm'}]} - {[{ item.author_first_name }]} {[{ item.author_last_name }]}"
              >
                <div
                  class="user-avatar"
                  ng-if="item.editor_first_name && item.editor_avatar.length"
                >
                  <img ng-src="/pictures/{[{item.editor_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="!item.editor_avatar.length">
                  {[{ item.editor_first_name | cut:true:1:' ' }]}
                </div>
                {[{ item.editor_first_name }]}
              </span>
            `,
          },
        );
        if (['purchase', 'sale'].includes(this.fullQueryParams.contract_type)) {
          const customsColumns: any = [
            {
              columnName: 'customsClearedVolume',
              title: this.gettext('Customs cleared volume (t)'),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.customs_cleared_volume">
                  <i class="fa fa-exchange"></i>{[{ item.customs_cleared_volume || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
              totalTemplate: html` <span ng-if="item.customs_cleared_volume_sum">
                <i class="fa fa-exchange"></i>{[{ item.customs_cleared_volume_sum || 0 | number:3
                }]}
                <span class="smaller-label">{[{ args.measurementUnit }]}</span>
              </span>`,
            },
            {
              columnName: 'customsUnclearedVolume',
              title: this.gettext('Customs uncleared volume (t)'),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.customs_uncleared_volume">
                  <i class="fa fa-exchange"></i>{[{ item.customs_uncleared_volume || 0 | number:3
                  }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
              totalTemplate: html` <span ng-if="item.customs_uncleared_volume_sum">
                <i class="fa fa-exchange"></i>{[{ item.customs_uncleared_volume_sum || 0 | number:3
                }]}
                <span class="smaller-label">{[{ args.measurementUnit }]}</span>
              </span>`,
            },
            {
              columnName: 'customsClearedAmount',
              title: this.gettext(
                `Customs cleared amount (${this.$rootScope.user.settings.DEFAULT_CURRENCY})`,
              ),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.customs_cleared_amount">
                  <i class="fa fa-exchange"></i>{[{ item.customs_cleared_amount || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.defaultCurrency }]}</span>
                </span>
              `,
              totalTemplate: html`
                <span ng-if="item.customs_cleared_amount_sum">
                  <i class="fa fa-exchange"></i>{[{ item.customs_cleared_amount_sum || 0 | number:3
                  }]}
                  <span class="smaller-label">{[{ args.defaultCurrency }]}</span>
                </span>
              `,
            },
            {
              columnName: 'customsUnclearedAmount',
              title: this.gettext(
                `Customs uncleared amount (${this.$rootScope.user.settings.DEFAULT_CURRENCY})`,
              ),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.customs_uncleared_amount">
                  <i class="fa fa-exchange"></i>{[{ item.customs_uncleared_amount || 0 | number:3
                  }]}
                  <span class="smaller-label">{[{ args.defaultCurrency }]}</span>
                </span>
              `,
              totalTemplate: html`
                <span ng-if="item.customs_uncleared_amount_sum">
                  <i class="fa fa-exchange"></i>{[{ item.customs_uncleared_amount_sum || 0 |
                  number:3 }]}
                  <span class="smaller-label">{[{ args.defaultCurrency }]}</span>
                </span>
              `,
            },
            {
              columnName: 'customsPreparedVolume',
              title: this.gettext('Customs prepared volume (t)'),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.customs_prepared_volume">
                  <i class="fa fa-exchange"></i>{[{ item.customs_prepared_volume || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
              totalTemplate: html`
                <span ng-if="item.customs_prepared_volume_sum">
                  <i class="fa fa-exchange"></i>{[{ item.customs_prepared_volume_sum || 0 | number:3
                  }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'customsUnpreparedVolume',
              title: this.gettext('Customs unprepared volume (t)'),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.customs_unprepared_volume">
                  <i class="fa fa-exchange"></i>{[{ item.customs_unprepared_volume || 0 | number:3
                  }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
              totalTemplate: html`
                <span ng-if="item.customs_unprepared_volume_sum">
                  <i class="fa fa-exchange"></i>{[{ item.customs_unprepared_volume_sum || 0 |
                  number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'customsPreparedAmount',
              title: this.gettext(
                `Customs prepared amount (${this.$rootScope.user.settings.DEFAULT_CURRENCY})`,
              ),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.customs_prepared_amount">
                  <i class="fa fa-exchange"></i>{[{ item.customs_prepared_amount || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.defaultCurrency }]}</span>
                </span>
              `,
              totalTemplate: html`
                <span ng-if="item.customs_prepared_amount_sum">
                  <i class="fa fa-exchange"></i>{[{ item.customs_prepared_amount_sum || 0 | number:3
                  }]}
                  <span class="smaller-label">{[{ args.defaultCurrency }]}</span>
                </span>
              `,
            },
            {
              columnName: 'customsUnpreparedAmount',
              title: this.gettext(
                `Customs unprepared amount (${this.$rootScope.user.settings.DEFAULT_CURRENCY})`,
              ),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.customs_unprepared_amount">
                  <i class="fa fa-exchange"></i>{[{ item.customs_unprepared_amount || 0 | number:3
                  }]}
                  <span class="smaller-label">{[{ args.defaultCurrency }]}</span>
                </span>
              `,
              totalTemplate: html`
                <span ng-if="item.customs_unprepared_amount_sum">
                  <i class="fa fa-exchange"></i>{[{ item.customs_unprepared_amount_sum || 0 |
                  number:3 }]}
                  <span class="smaller-label">{[{ args.defaultCurrency }]}</span>
                </span>
              `,
            },
          ];
          options.columnDefs.push(...customsColumns);
          options.tabs
            .filter((tab: any) => tab.serializer === 'table_execution')
            .forEach((tab: any) =>
              tab.columns.push(...customsColumns.map((col: any) => col.columnName)),
            );
        }

        if (this.tableName === 'export-contracts-page-view') {
          options.columnDefs.push(
            {
              columnName: 'periodicCustomsDeclaration',
              title: this.gettext('Periodic customs declaration'),
              class: 'td-left-align',
              cellTemplate: html`
                <ul>
                  <li
                    class="bigger-label"
                    ng-repeat="ccd in item.ccd_data | filter:{stage: 'periodic'}"
                  >
                    {[{ ccd.number }]} ({[{ ccd.date | date:'dd.MM.yy' }]})
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'saleVolume',
              title: this.gettext('Sale volume'),
              class: 'td-left-align',
              cellTemplate: html`
                <ul>
                  <li ng-repeat="connection in item.sale_export_data">
                    <span class="bigger-label">
                      <i class="fa fa-file"></i>
                      <a ui-sref="gt.page.contract({ id: connection.sale_contract_id})">
                        {[{ connection.sale_contract_number }]}
                      </a>
                      (<i class="fa fa-exchange"></i>
                      <a ui-sref="gt.page.passport({ id: connection.passport})">
                        {[{ connection.passport_title }]} </a
                      >) -> {[{ connection.volume || 0 | number:3 }]}
                    </span>
                    <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'openQuote',
              title: this.gettext('Open quote'),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.open_quote">
                  <i class="fa fa-exchange"></i>{[{ item.open_quote | number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'ccdVolume',
              title: this.gettext('CCD volume'),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.ccd_volume">
                  <i class="fa fa-exchange"></i>{[{ item.ccd_volume || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'pcdVolume',
              title: this.gettext('PCD volume'),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.pcd_volume">
                  <i class="fa fa-exchange"></i>{[{ item.pcd_volume || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'no_customs_logistics',
              title: this.gettext('no customs logistics'),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.no_customs_logistics">
                  <i class="fa fa-exchange"></i>{[{ item.no_customs_logistics | number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'loses',
              title: this.gettext('Loses'),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item.logistic_volume_loses">
                  <i class="fa fa-exchange"></i>
                  {[{ item.logistic_volume_loses || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'pcdExecution',
              title: this.gettext('PCD execution'),
              class: 'td-left-align',
              cellTemplate: html`
                <span class="bigger-label" ng-repeat="row in item.cd_data">
                  <span class="label label_default">
                    <a ui-sref="customsCargoDeclarationDetails({id: row.pcd_id })">
                      <span class=""> # {[{row.pcd_number}]} </span>
                    </a>
                  </span>
                  <span class="label smaller-label">
                    {[{row.pcd_volume || 0 | number:3 }]} ->>> {[{row.ccd_volume || 0 | number:3 }]}
                  </span>
                </span>
              `,
            },
            {
              columnName: 'logisticsTerminalVolumes',
              title: this.gettext('Volumes by terminals'),
              class: 'td-left-align',
              cellTemplate: html`
                <span class="bigger-label" ng-repeat="group in item.logistic_data">
                  <i class="fa fa-anchor"></i> {[{ group.shipment_terminal_name }]} - {[{
                  group.terminal_volume | number: 3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'customsTerminalsTotalVolume',
              title: this.gettext('Total volumes by customs terminal'),
              class: 'td-left-align',
              cellTemplate: html`
                <ul>
                  <li class="label-strong">
                    <span>{[{item.customs_terminals_total_volume || 0 | number:3 }]}</span>
                  </li>
                  <li ng-repeat="terminal_data in item.customs_terminals_data">
                    <span class="bigger-label">
                      <i class="fa fa-anchor"> </i>{[{ terminal_data.customs_terminal_name }]} -
                      {[{terminal_data.logistics_volume_sum || 0 | number: 3 }]}
                    </span>
                    <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'customsTerminalsCustomsClearedVolume',
              title: this.gettext('Customs cleared volumes by customs terminal'),
              class: 'td-left-align',
              cellTemplate: html`
                <ul>
                  <li class="label-strong">
                    <span>{[{item.customs_terminals_cleared_volume || 0 | number:3 }]}</span>
                  </li>
                  <li ng-repeat="terminal_data in item.customs_terminals_data">
                    <span class="bigger-label">
                      <i class="fa fa-anchor"> </i>{[{ terminal_data.customs_terminal_name }]} -
                      {[{terminal_data.customs_cleared_volume || 0 | number: 3 }]}
                    </span>
                    <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'customsTerminalsCustomsNotClearedVolume',
              title: this.gettext('Customs not cleared by customs terminal'),
              class: 'td-left-align',
              cellTemplate: html`
                <ul>
                  <li class="label-strong">
                    <span> {[{item.customs_terminals_not_cleared_volume || 0 | number:3 }]} </span>
                  </li>
                  <li ng-repeat="terminal_data in item.customs_terminals_data">
                    <span class="bigger-label">
                      <i class="fa fa-anchor"> </i>{[{ terminal_data.customs_terminal_name }]} -
                      {[{terminal_data.customs_not_cleared_volume || 0 | number: 3 }]}
                    </span>
                    <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'logisticWithoutCCD',
              title: this.gettext('Logistics volume without CCD'),
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="(item.logistics_not_boarded_volume_sum || 0) - (item.ccd_volume || 0)">
                  {[{ (item.logistics_not_boarded_volume_sum || 0) - (item.ccd_volume || 0) || 0 |
                  number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'bill_of_ladings',
              title: this.gettext('Bill of lading'),
              class: 'td-left-align',
              cellTemplate: html`
                <ul class="bigger-label" ng-if="item.billofladings_data.length">
                  <li ng-repeat="bill_of_lading in item.billofladings_data">
                    {[{ bill_of_lading.number }]}
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'logistic_executed_volume',
              title: this.gettext('logistic executed volume'),
              class: 'td-left-align',
              cellTemplate: html`
                <span class="bigger-label" ng-if="item.logistic_executed_volume">
                  {[{ item.logistic_executed_volume | number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'free_volume_pcd',
              title: this.gettext('Free volume PCD'),
              class: 'td-left-align',
              cellTemplate: html`
                <span class="bigger-label">{[{ item.free_volume_pcd | number:3 }]}</span>
                <span class="smaller-label">{[{ args.measurementUnit }]}</span>
              `,
            },
            {
              columnName: 'volumeWithoutPcd',
              title: this.gettext('Volume without PCD'),
              class: 'td-left-align',
              cellTemplate: html`
                <span class="bigger-label">
                  {[{ item.volume_without_pcd | number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'pre_pcd_logistic_volume',
              title: this.gettext('pre PCD logistic volume'),
              class: 'td-left-align',
              cellTemplate: html`
                <span class="bigger-label" ng-if="item.pre_pcd_logistic_volume">
                  {[{ item.pre_pcd_logistic_volume | number:3 }]}
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'executed_export_volume',
              title: this.gettext('executed export volume'),
              class: 'td-left-align',
              cellTemplate: html`
                <span class="bigger-label" ng-if="item.executed_export_volume">
                  {[{ item.executed_export_volume | number:3 }]}
                  <span class="smaller-label"> {[{ args.measurementUnit }]} </span>
                </span>
              `,
            },
            {
              columnName: 'saleLogisticVolumes',
              title: this.gettext('Sale logistic volumes'),
              class: 'td-left-align',
              cellTemplate: html`
                <ul>
                  <li class="label-strong">
                    <span>{[{item.sale_export_logistic_volume || 0 | number:3 }]}</span>
                  </li>
                  <li ng-repeat="connection in item.sale_export_logistic_data">
                    <span class="bigger-label">
                      <i class="fa fa-file"></i>
                      <a ui-sref="gt.page.contract({ id: connection.sale_contract_id})">
                        {[{ connection.sale_contract_number }]} -> {[{ connection.volume || 0 |
                        number:3 }]}
                      </a>
                    </span>
                    <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'free_export_volume',
              title: this.gettext('free export volume'),
              class: 'td-left-align',
              cellTemplate: html`
                <span>
                  <span ng-if="!$root.user.settings.USE_TOLERANCE_IN_EXPORT_CONTRACT">
                    {[{ item.volume - item.sale_export_volume | number:3 }]}
                  </span>
                  <span ng-if="$root.user.settings.USE_TOLERANCE_IN_EXPORT_CONTRACT">
                    {[{ item.volume_with_option - item.sale_export_volume | number:3 }]}
                  </span>
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'volume_without_pcd',
              title: this.gettext('volume without pcd'),
              class: 'td-left-align',
              cellTemplate: html`
                <span>
                  <span ng-if="!$root.user.settings.USE_TOLERANCE_IN_EXPORT_CONTRACT">
                    {[{ item.volume - item.pcd_volume | number:3 }]}
                  </span>
                  <span ng-if="$root.user.settings.USE_TOLERANCE_IN_EXPORT_CONTRACT">
                    {[{ item.volume_with_option - item.pcd_volume | number:3 }]}
                  </span>
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
            {
              columnName: 'unfulfilled_volume',
              title: this.gettext('Unfulfilled volume'),
              class: 'td-left-align',
              cellTemplate: html`
                <span>
                  <span ng-if="!$root.user.settings.USE_TOLERANCE_IN_EXPORT_CONTRACT">
                    {[{ item.volume - item.export_basis_volume | number:3 }]}
                  </span>
                  <span ng-if="$root.user.settings.USE_TOLERANCE_IN_EXPORT_CONTRACT">
                    {[{ item.volume_with_option - item.export_basis_volume | number:3 }]}
                  </span>
                  <span class="smaller-label">{[{ args.measurementUnit }]}</span>
                </span>
              `,
            },
          );
          options.tabs
            .filter((tab: any) => !tab.columns.includes('open_balance'))
            .forEach((tab: any) => tab.columns.push('open_balance'));
        }

        if (this.$rootScope.user.settings.SIMPLE_TABLES) {
          options.tabs = options.tabs.filter((item: any) => {
            return !['table_execution'].includes(item.serializer);
          });
        }

        options.tabs = options.tabs.filter((tab: any) => tab.permission !== false);

        return this.ContractsService.getCustomFieldTableColumns()
          .then((columns: any) => {
            options.columnDefs.push(...columns);
            options.tabs.forEach((tab: any) => {
              tab.columns.push(...columns.map((column: any) => column.columnName));
            });
            this.tableOptions = options;
            this.$rootScope.$applyAsync();
          })
          .catch(errorHandler);
      }
    },
  ],
};
