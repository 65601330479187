import React from 'react';

import { formatDateRange } from '~/shared/lib/date';
import { cn } from '~/shared/lib/utils';

import type { DataTypeProps } from '../../lib';

export const DateRangeRead: React.FC<DataTypeProps<'daterange'>> = ({
  disabled,
  value,
  typeExtra,
}) => {
  const format = typeExtra?.print ?? 'dd/MM/yy';

  const formattedDateRange = React.useMemo(() => formatDateRange(value, format), [value, format]);

  return (
    <span className={cn('text-text-main-tertiary text-xs', disabled && 'cursor-not-allowed')}>
      {formattedDateRange}
    </span>
  );
};
