import type ng from 'angular';

import template from './cashflow-balance-report-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const CashflowBalanceReportContainer = {
  bindings: {
    filterLevel: '<',
    indicatorView: '<',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'CashflowReportService',
    'BankBalanceReportService',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      BankBalanceReportService: any;
      CashflowReportService: any;
      GtUtils: GtUtilsService;
      balanceData: any;
      config: any;
      filterLevel = 'cashflow-balance-report-container';
      gettext: ng.gettext.gettextFunction;
      groupChoices: any;
      gtFilterService: GtFilterService;
      hideFilters: any;
      indicatorView: any;
      queryParams: QueryParams = {};
      selectedGroups: any;
      turnoverData: any;
      unselectedGroups: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        CashflowReportService: any,
        BankBalanceReportService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.CashflowReportService = CashflowReportService;
        this.BankBalanceReportService = BankBalanceReportService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.queryParams = {};
        this.hideFilters = true;
        this.selectedGroups = {};
        this.unselectedGroups = {};
        this.balanceData = [];
        this.turnoverData = [];
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'cashflow-balance-report-page-view';
        this.groupChoices = {
          turnover: [
            { param: 'currency', title: this.gettext('currency'), icon: 'fa-money' },
            { param: 'account', title: this.gettext('bank account'), icon: 'fa-bank' },
            { param: 'use', title: this.gettext('use'), icon: 'fa-building' },
            { param: 'subuse', title: this.gettext('subuse'), icon: 'fa-university' },
            { param: 'counterparty', title: this.gettext('counterparty'), icon: 'fa-list' },
            { param: 'owner', title: this.gettext('owner'), icon: 'fa-home' },
            { param: 'finance_type', title: this.gettext('finance type'), icon: 'fa-level-up' },
            {
              param: 'report_column_type',
              title: this.gettext('report column type'),
              icon: 'fa-level-down',
            },
            { param: 'generalexpenses', title: this.gettext('generalexpenses'), icon: 'fa-money' },
            { param: 'otheractivity', title: this.gettext('otheractivity'), icon: 'fa-money' },
            { param: 'charge', title: this.gettext('charge'), icon: 'fa-money' },
            { param: 'businessunit', title: this.gettext('businessunit'), icon: 'fa-money' },
          ],
          rests: [
            { param: 'currency', title: this.gettext('currency'), icon: 'fa-money' },
            { param: 'account', title: this.gettext('bank account'), icon: 'fa-bank' },
            { param: 'owner', title: this.gettext('owner'), icon: 'fa-building' },
          ],
        };

        this.queryParams = this.gtFilterService.getQueryParams(this.filterLevel);
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          [this.selectedGroups.rests, this.unselectedGroups.rests] =
            this.BankBalanceReportService.parseGroups(
              this.groupChoices.rests,
              this.queryParams.rests_groupping,
            );
          [this.selectedGroups.turnover, this.unselectedGroups.turnover] =
            this.BankBalanceReportService.parseGroups(
              this.groupChoices.turnover,
              this.queryParams.turnover_groupping,
            );
          this.updateData();
        });
        this.$scope.$watch(
          () => this.indicatorView,
          () => this.changeView(),
        );
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }
      selectGroup(group: any, set: any) {
        this.selectedGroups[set].push(group);
        this.unselectedGroups[set].splice(this.unselectedGroups[set].indexOf(group), 1);
      }
      unselectGroup(group: any, set: any) {
        this.unselectedGroups[set].push(group);
        this.selectedGroups[set].splice(this.selectedGroups[set].indexOf(group), 1);
      }
      updateData() {
        this.GtUtils.overlay('show');
        this.CashflowReportService.getReportData({
          ...this.queryParams,
          group_by: this.queryParams.turnover_groupping,
        }).then((data: any) => {
          this.turnoverData = data.results;
          this.BankBalanceReportService.getReportData({
            ...this.queryParams,
            without_turnovers: true,
            group_by: this.queryParams.rests_groupping,
          }).then((data: any) => {
            this.GtUtils.overlay('hide');
            this.balanceData = data.results;
            this.changeView();
          });
        });
      }

      applyFilter() {
        this.queryParams.rests_groupping = this.selectedGroups.rests
          .map((v: any) => v.param)
          .join(',');
        this.queryParams.turnover_groupping = this.selectedGroups.turnover
          .map((v: any) => v.param)
          .join(',');
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }
      changeView() {
        this.config = this.BankBalanceReportService.getTheadConfig('', 'vertical');
      }
    },
  ],
};
