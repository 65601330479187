import React from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '~/shared/lib/utils';
import { Input } from '~/shared/ui/kit/input';
import { Tooltip } from '~/shared/ui/kit/tooltip';

import { type DataTypeProps, messageIntent } from '../../lib';

export const PhoneWrite: React.FC<DataTypeProps<'phone'>> = ({
  value,
  onChange,
  ref,
  disabled,
  message,
  typeExtra,
}) => {
  const { t } = useTranslation();

  const inputElement = (
    <Input
      type="text"
      data-testid="text-field"
      intent={message ? 'danger' : 'transparent'}
      textColor={message ? 'danger' : 'primary'}
      ref={ref}
      onBlur={(e) => onChange?.(e.target.value)}
      defaultValue={value}
      disabled={disabled}
      fullWidth
      {...typeExtra?.inputProps}
    />
  );

  return message ? (
    <Tooltip content={t(message?.text ?? '')} side="bottom" sideOffset={4}>
      <div className={cn('relative', messageIntent[message?.type])}>{inputElement}</div>
    </Tooltip>
  ) : (
    inputElement
  );
};
