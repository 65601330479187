import type ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import template from './consignments-page.html?raw';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const consignmentsPage = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'PageService',
    'GtUtils',
    'LogisticsService',
    'gettext',
    'CustomValuesService',
    class ConsignmentsPage {
      $scope: ng.IScope;
      CustomValuesService: CustomValuesService;
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      PageService: PageService;
      filterLevel = 'consignments-page';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams?: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        PageService: PageService,
        GtUtils: GtUtilsService,
        LogisticsService: LogisticsService,
        gettext: ng.gettext.gettextFunction,
        CustomValuesService: CustomValuesService,
      ) {
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.GtUtils = GtUtils;
        this.LogisticsService = LogisticsService;
        this.CustomValuesService = CustomValuesService;
        this.gettext = gettext;
        this.$scope = $scope;

        this.queryParams = {};
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.queryParams = this.queryParams ?? {};
      }

      getPageConfig() {
        const pageConfig: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.LogisticsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            search: true,
            dates: true,
            invert_filters: true,
            dateSelects: [
              {
                argument: 'date',
                placeholder: this.gettext('Date'),
              },
              {
                argument: 'create_time',
                placeholder: this.gettext('Create date'),
              },
            ],
            nestedSelects: [
              this.gtFilterService.getBoolFilter(
                'exclude_from_warehouse',
                this.gettext('Exclude from warehouse'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_passport_key',
                this.gettext('Passport filled in  manually'),
              ),
            ],
            selects: [],
            nestedMultiSelects: [],
            multiSelects: [
              {
                argument: 'farm_list',
                placeholder: this.gettext('farms'),
                resource: 'clients.farm',
              },
              {
                argument: 'elevator_list',
                placeholder: this.gettext('elevators'),
                resource: 'clients.elevator',
              },
              {
                argument: 'terminal_list',
                placeholder: this.gettext('terminals'),
                resource: 'logistics.terminal',
              },
              {
                argument: 'consignment_list',
                placeholder: this.gettext('consignments'),
                resource: 'contracts.consignment',
              },
              {
                argument: 'contract_list',
                placeholder: this.gettext('contracts'),
                resource: 'contracts.ContractBase',
              },
              {
                argument: 'contract_buyer_list',
                placeholder: this.gettext('buyers'),
                resource: 'clients.buyer',
              },
              {
                argument: 'contract_supplier_list',
                placeholder: this.gettext('suppliers'),
                resource: 'clients.supplier',
              },
              {
                argument: 'passport_list',
                placeholder: this.gettext('passports'),
                resource: 'passports.passport',
              },
              {
                argument: 'commodity_list',
                placeholder: this.gettext('commodity'),
                resource: 'crops.crop',
              },
              {
                argument: 'manager_list',
                placeholder: this.gettext('managers'),
                resource: 'auth.user',
              },
              {
                argument: 'business_unit_list',
                placeholder: this.gettext('business unit'),
                resource: 'core.BusinessUnit',
                getQueryParams: this.GtUtils.getBUQueryParams,
              },
              {
                argument: 'receiver_list',
                placeholder: this.gettext('receivers'),
                resource: 'clients.client',
                related_fk: 'receiver_id',
                related_model: 'contracts.Consignment',
              },
              {
                argument: 'producer_list',
                placeholder: this.gettext('producers'),
                resource: 'clients.client',
                related_fk: 'producer_id',
                related_model: 'contracts.Consignment',
              },
              {
                argument: 'stock_reserve_unit_list',
                placeholder: this.gettext('Stock reserve units'),
                resource: 'logistics.stockreserveunit',
                related_fk: 'stock_reserve_unit_id',
                related_model: 'contracts.Consignment',
              },
              {
                argument: 'voyage_list',
                placeholder: this.gettext('Voyages'),
                resource: 'logistics.voyage',
                related_fk: 'voyage_id',
                related_model: 'contracts.Consignment',
              },
              {
                argument: 'vessel_list',
                placeholder: this.gettext('Vessels'),
                resource: 'logistics.vessel',
                related_fk: 'voyage__vessel_id',
                related_model: 'contracts.Consignment',
              },
            ],
          },
        };
        this.CustomValuesService.getCustomFieldFilters({
          purpose_model: 'consignment',
        })
          .then((customFieldsFilters) => {
            pageConfig.filters.multiSelects.push(...customFieldsFilters.multiSelects);
            pageConfig.filters.selects.push(...customFieldsFilters.selects);
            pageConfig.filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
            pageConfig.filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
            pageConfig.filters.dateSelects.push(...customFieldsFilters.dateSelects);
            this.$scope.$applyAsync();
          })
          .catch(errorHandler);
        return pageConfig;
      }
    },
  ],
};
