import type ng from 'angular';

import type { QueryParams } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export class LogisticConsignmentLinkService {
  $http: ng.IHttpService;
  LogisticsService: LogisticsService;
  constructor($http: ng.IHttpService, LogisticsService: LogisticsService) {
    this.$http = $http;
    this.LogisticsService = LogisticsService;
  }

  getGroupedData(queryParams: QueryParams) {
    return this.$http({
      method: 'POST',
      url: '/api/logistics/logistics/get_passport_data_by_consignment/',
      data: queryParams,
    }).then((response: any) => response.data);
  }

  bulkSave(positions: any) {
    return this.LogisticsService.LogisticConsignment.bulkCreateOrUpdate(positions).$promise;
  }
}
LogisticConsignmentLinkService.$inject = ['$http', 'LogisticsService'];
