import { accountsApprovalConfigsRetrieve, accountsApprovalsList } from '~/shared/api';
import { singleton } from '~/shared/lib/di';

import { ApprovalsPageList, ApprovalsQueryParams } from '../lib/types';

@singleton()
export class AccountsRepository {
  getApprovalConfig = async (id: number) => {
    const { data } = await accountsApprovalConfigsRetrieve({ path: { id } });
    return data;
  };

  queryApprovals = async (params: ApprovalsQueryParams): Promise<ApprovalsPageList> => {
    const { data } = await accountsApprovalsList({ query: params });
    return { count: data.count, next: data.next, previous: data.previous, records: data.results };
  };
}
