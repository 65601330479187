import { coreCustomFieldsList } from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';

import { CustomField, CustomFieldQueryParams } from '../lib/types';

@singleton()
export class CustomFieldRepository extends EntityRepository<CustomField, CustomFieldQueryParams> {
  readonly entityName = 'core.CustomField';

  getFn = (_id: number) => {
    throw new Error('Method not implemented.');
  };

  queryFn = async (params: CustomFieldQueryParams) => {
    const { data } = await coreCustomFieldsList({ query: params });
    return { records: data.results, count: data.count };
  };

  searchFn = (_input: string) => {
    throw new Error('Method not implemented.');
  };

  createFn = (_dto: CustomField) => {
    throw new Error('Method not implemented.');
  };

  updateFn = () => {
    throw new Error('Method not implemented.');
  };

  deleteFn = (_id: number) => {
    throw new Error('Method not implemented.');
  };
}
