import ng from 'angular';

import { InvoicePositionConfigContainer } from './invoice-position-config-container/invoice-position-config-container.component';
import { InvoicePositionConfigPage } from './invoice-position-config-page/invoice-position-config-page.component';
import { InvoicePositionConfigService } from './invoice-position-config.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const invoicePositionCongigConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('finances.invoicePositionConfigPage', {
      url: '/invoice-position-configs/',
      component: 'invoicePositionConfigPage',
      data: {
        pageTitle: gettext('Invoice Position configs'),
      },
      resolve: {
        filterLevel: () => {
          return 'invoice-position-config-page';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('invoice-position-config-page');
          },
        ],
      },
    });
  },
];

export const invoicePositionConfig = ng
  .module('finances.invoice-position-config', [])
  .component('invoicePositionConfigContainer', InvoicePositionConfigContainer)
  .component('invoicePositionConfigPage', InvoicePositionConfigPage)
  .service('InvoicePositionConfigService', InvoicePositionConfigService)
  .config(invoicePositionCongigConfig).name;
