import { logisticsAcceptanceCertificatesPredictionsRetrieve } from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { AcceptanceCertificate, AcceptanceCertificateQueryParams } from '../lib';

export class AcceptanceCertificateRepository extends EntityRepository<
  AcceptanceCertificate,
  AcceptanceCertificateQueryParams
> {
  readonly entityName = 'logistics.AcceptanceCertificate';

  getFn = (_id: number): Promise<AcceptanceCertificate> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (
    _params: AcceptanceCertificateQueryParams,
  ): Promise<Paginated<AcceptanceCertificate>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await logisticsAcceptanceCertificatesPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };
  createFn = (_entity: AcceptanceCertificate): Promise<AcceptanceCertificate> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<AcceptanceCertificate>): Promise<AcceptanceCertificate> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
