import React from 'react';

import { Dropdown, type DropdownMenuItem } from '~/shared/ui/kit/dropdown-menu';
import type { TagVariants } from '~/shared/ui/kit/tag';
import { Tag } from '~/shared/ui/kit/tag';

import { type FinanceRecord } from '../../lib';
const vesselPassportStatusVariant: Record<string, TagVariants['variant']> = {
  cargo_partial_executed: 'orange',
  cargo_executed: 'orange',
  pod_under_way: 'orange',
  at_loading_port: 'orange',
  Discharging: 'sky',
  Discharged: 'sky',
  New: 'green',
  Processing: 'orange',
  Request: 'green',
  Cancelled: 'gray',
  Executed: 'gray',
  Approved: 'sky',
  Loaded: 'orange',
  Loading: 'orange',
};
export const PositionPassportsVesselsTemplate: React.FC<{
  positionPassports: FinanceRecord['position_passports'];
}> = ({ positionPassports }) => {
  const dropdownItems: DropdownMenuItem[] = positionPassports.map((item) => ({
    label: (
      <div
        className="flex max-w-[250px] items-center overflow-hidden whitespace-nowrap"
        data-testid="position-passports-template"
      >
        <span>{item.vessel_name}</span>
        <Tag variant={vesselPassportStatusVariant[item.status]}>{item.status}</Tag>
      </div>
    ),
    value: String(item.id),
  }));

  return dropdownItems.length > 1 ? (
    <Dropdown
      title={dropdownItems[0].label}
      items={dropdownItems.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
    />
  ) : (
    <Dropdown title={dropdownItems[0].label} />
  );
};
