import { shareReplay } from 'rxjs';

import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import {
  ConsignmentPatch,
  ConsignmentPost,
  ConsignmentQueryParams,
  ConsignmentRecord,
} from '~/features/execution/consignments';
import { ConsignmentsRepository } from '~/features/execution/consignments/services/consignments.repository';
import { Consignment } from '~/shared/api';
import { injectable } from '~/shared/lib/di';
import { from, switchMap } from '~/shared/lib/state';

export type ConsignmentsListContainerProps = ContainerProps<ConsignmentQueryParams>;

@injectable()
export class ConsignmentsListViewModel extends EntityListContainerModel<
  Consignment,
  ConsignmentQueryParams,
  ConsignmentsRepository,
  ConsignmentsListContainerProps,
  ConsignmentRecord,
  ConsignmentPost,
  ConsignmentPatch
> {
  entityRepoClass = ConsignmentsRepository;

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.repo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );
}
