export class PurchasePlanService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getPurchasePlanTableData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/purchase-plan/',
      params: params,
    }).then((response: any) => this.preparePurchasePlanReportRows(response.data));
  }

  preparePurchasePlanReportRows(data: any) {
    const preparedData: any = {
      users: [],
      userTotals: {},
      crops: [],
      cropTotals: {},
      cells: {},
      totals: {},
    };

    preparedData.totals = data.totals;

    preparedData.users = Array.from(new Set(data.cells.map((cell: any) => `${cell.username}`)));

    preparedData.crops = Array.from(new Set(data.cells.map((cell: any) => `${cell.crop_title}`)));

    data.cells.forEach((cell: any) => {
      const userSlug = `${cell.username}`;
      const cropSlug = `${cell.crop_title}`;
      if (!preparedData.cells[userSlug]) {
        preparedData.cells[userSlug] = {};
      }
      preparedData.cells[userSlug][cropSlug] = cell;
    });

    data.user_totals.forEach((cell: any) => {
      const userSlug = `${cell.username}`;
      preparedData.userTotals[userSlug] = cell;
    });

    data.crop_totals.forEach((cell: any) => {
      const cropSlug = `${cell.crop_title}`;
      preparedData.cropTotals[cropSlug] = cell;
    });

    return preparedData;
  }
}
PurchasePlanService.$inject = ['$http'];
