import type ng from 'angular';

import { formatDate } from '~/shared/lib/date';
export class ConsolidatedCropPositionService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getCropPositionTableData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/consolidated-crop-position-report/',
      params: params,
    }).then((response: any) => this.prepareCropPositionReportRows(response.data.data));
  }

  prepareCropPositionReportRows(data: any) {
    const preparedData = {
      cropReports: [],
    };
    preparedData.cropReports = data.map((rawCropReport: any) =>
      this.prepareCropReport(rawCropReport),
    );
    return preparedData;
  }

  prepareCropReport(rawCropReport: any) {
    const cropReport: any = {
      months: [],
      inventory: {},
      basisReports: {},
      basisTotals: {},
      paperReports: {},
      paperTotals: {},
      inventory_volume_sum: rawCropReport.inventory_volume_sum,
      inventory_volume_on_road: rawCropReport.inventory_volume_on_road,
      basisesTotal: 0,
      papertrade_volume_sum: rawCropReport.papertrade_volume_sum,
      volume_sum: rawCropReport.volume_sum,
      cropName: rawCropReport.commodity_title,
    };
    rawCropReport.basises = rawCropReport.basises || [];
    rawCropReport.papertrades = rawCropReport.papertrades || [];
    cropReport.basisReports = rawCropReport.basises.map((row: any) =>
      this.basisReportPrepareBasisReport(row),
    );
    cropReport.paperReports = rawCropReport.papertrades.map((row: any) =>
      this.basisReportPreparePaperReport(row),
    );
    cropReport.months = Array.from(
      new Set([
        ...cropReport.basisReports.map((report: any) => Object.keys(report.months)).flat(),
        ...cropReport.paperReports.map((report: any) => Object.keys(report.months)).flat(),
      ]),
    );
    cropReport.basisTotals = cropReport.months.map((month: any) =>
      cropReport.basisReports
        .map((row: any) => row.months[month]?.volume_sum || 0)
        .reduce((res: any, vol: any) => res + vol, 0),
    );
    cropReport.paperTotals = cropReport.months.map((month: any) =>
      cropReport.paperReports
        .map((row: any) => row.months[month]?.volume_sum || 0)
        .reduce((res: any, vol: any) => res + vol, 0),
    );
    cropReport.basisTotal = cropReport.basisReports.reduce(
      (res: any, item: any) => item.total + res,
      0,
    );
    cropReport.paperTotal = cropReport.paperReports.reduce(
      (res: any, item: any) => item.total + res,
      0,
    );
    cropReport.total = cropReport.basisTotal + cropReport.paperTotal;
    return cropReport;
  }

  basisReportPrepareBasisReport(row: any) {
    return {
      basisName: row.basis_id,
      basisId: row.basis_id,
      months: row.months.reduce((res: any, data: any) => {
        res[formatDate(data.current_month, 'MMMM, yyyy')] = data;
        return res;
      }, {}),
      total: row.volume_sum,
      inventory: {
        total: row.inventory_volume_sum,
        total_on_road: row.inventory_volume_on_road,
        inventories: row.inventories || [],
      },
    };
  }

  basisReportPreparePaperReport(row: any) {
    return {
      paperName: row.papertrade_title,
      paperId: row.papertrade_id,
      months: row.months.reduce((res: any, data: any) => {
        res[formatDate(data.current_month, 'MMMM, yyyy')] = data;
        return res;
      }, {}),
      total: row.volume_sum,
      inventories: row.inventories || [],
    };
  }
}
ConsolidatedCropPositionService.$inject = ['$http'];
