import React from 'react';

export const CardTitle: React.FC<{ isSubmit: boolean }> = ({ isSubmit }) => {
  return (
    <div className="mx-auto flex flex-col gap-1 text-center">
      {isSubmit ? (
        <>
          <h2 className="mx-auto">Success</h2>
          <p className="text-text-main-tertiary w-[244px]">
            We’ve send you new password via email. Check your inbox and try sign in again
          </p>
        </>
      ) : (
        <>
          <h2 className="mx-auto">Reset password</h2>
          <p className="text-text-main-tertiary w-[228px]">
            To reset password - enter your username and we will send you new password
          </p>
        </>
      )}
    </div>
  );
};
