import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('BankAccountPageController', Controller);

  Controller.$inject = [
    '$rootScope',
    '$stateParams',
    '$state',
    'FinancesService',
    'GtUtils',
    'PageService',
    'gettext',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $stateParams: ng.ui.IStateParamsService,
    $state: ng.ui.IStateService,
    FinancesService: FinancesService,
    GtUtils: GtUtilsService,
    PageService: PageService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.bankAccount = { id: $stateParams.id };
    vm.updateData = updateData;

    activate();

    ////////////////

    function activate() {
      updateData();
    }

    function updateData() {
      GtUtils.overlay('show');
      return FinancesService.BankAccount.get(
        { id: vm.bankAccount.id },
        function (data: any) {
          vm.bankAccount = data;
          PageService.setPageTitle(
            $rootScope.pageTitle +
              ' ' +
              vm.bankAccount.bank_name +
              ' ' +
              vm.bankAccount.account_name,
          );
          GtUtils.overlay('hide');
        },
        function () {
          notify(gettext("Bank account doesn't exist"));
          $state.go('finances.bankAccount.list');
        },
      );
    }
  }
})();
