import type ng from 'angular';

import template from './reservation-details-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

export const ReservationDetailsPage = {
  template,
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  controller: [
    '$stateParams',
    'gettext',
    'PageService',
    class {
      $stateParams: ng.ui.IStateParamsService;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      item: any;
      constructor(
        $stateParams: ng.ui.IStateParamsService,
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
      ) {
        this.$stateParams = $stateParams;
        this.gettext = gettext;
        this.PageService = PageService;
        this.item = {};
        this.filterLevel = 'reservation-details-page';
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'reservation-details-page';
        this.item = { id: this.$stateParams.id };
        this.PageService.setConfig({ is_detail_page: true, pages: [] });
      }
    },
  ],
};
