import ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import { html } from '../../../shared/utils';
import type { RequestFormFieldsService } from '../requests/request-form-field.service';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { CoreUtils } from '^/app/core/core.utils';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { getModalRoot } from '^/shared/ui/modal';

export class MulticontractService {
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  $rootScope: GtRootScopeService;
  $state: ng.ui.IStateService;
  $uibModal: ng.ui.bootstrap.IModalService;
  AccountsService: AccountsService;
  ContractFormFieldsService: any;
  ContractsService: ContractsService;
  CoreService: CoreService;
  CoreUtils: CoreUtils;
  ExportMulticontractResource: any;
  ExportTicketResource: any;
  FinancesService: FinancesService;
  FormFieldParamsService: FormFieldParamsService;
  GtUtils: GtUtilsService;
  IntermediateMulticontractResource: any;
  LogisticsService: LogisticsService;
  MulticontractResource: any;
  MultiticketResource: any;
  PurchaseMulticontractResource: any;
  PurchaseTicketResource: any;
  RequestFormFieldsService: RequestFormFieldsService;
  SaleMulticontractResource: any;
  SaleTicketResource: any;
  ServicesMulticontractResource: any;
  ServicesTicketResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    $rootScope: GtRootScopeService,
    $q: ng.IQService,
    $state: ng.ui.IStateService,
    ContractsService: ContractsService,
    FinancesService: FinancesService,
    CoreService: CoreService,
    gettext: ng.gettext.gettextFunction,
    CoreUtils: CoreUtils,
    GtUtils: GtUtilsService,
    AccountsService: AccountsService,
    FormFieldParamsService: FormFieldParamsService,
    ContractFormFieldsService: any,
    RequestFormFieldsService: RequestFormFieldsService,
    LogisticsService: LogisticsService,
  ) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$q = $q;
    this.$state = $state;
    this.ContractsService = ContractsService;
    this.FinancesService = FinancesService;
    this.CoreService = CoreService;
    this.gettext = gettext;
    this.CoreUtils = CoreUtils;
    this.GtUtils = GtUtils;
    this.AccountsService = AccountsService;
    this.FormFieldParamsService = FormFieldParamsService;
    this.ContractFormFieldsService = ContractFormFieldsService;
    this.RequestFormFieldsService = RequestFormFieldsService;
    this.LogisticsService = LogisticsService;
    this.MultiticketResource = $resource(
      '/api/contracts/multicontract/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        list: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/ticket-list/info/',
        },
        saleList: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/ticket-list/sale/info/',
        },
        purchaseList: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/ticket-list/purchase/info/',
        },
        exportList: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/ticket-list/export/info/',
        },
        servicesList: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/ticket-list/services/info/',
        },
      },
    );
    this.MulticontractResource = $resource(
      '/api/contracts/multicontract/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        list: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/multicontract-list/info/',
        },
        saleList: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/multicontract-list/sale/info/',
        },
        purchaseList: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/multicontract-list/purchase/info/',
        },
        intermediateList: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/multicontract-list/intermediate/info/',
        },
        exportList: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/multicontract-list/export/info/',
        },
        servicesList: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/multicontract-list/services/info/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/contracts/multicontract/bulk_create_or_update/',
        },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/multicontract/:id/delete_confirmation/',
        },
        setFactVolumeToPlans: {
          method: 'GET',
          url: '/api/contracts/multicontract/set_fact_volume_to_plans/',
        },
        setCargoConfirmation: {
          method: 'GET',
          url: '/api/contracts/multicontract/:id/set_cargo_confirmation/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/multicontract/predictions/',
        },
        generateNumber: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/multicontract/generate_number/',
        },
        finalApprove: {
          method: 'POST',
          isArray: false,
          url: '/api/contracts/multicontract/:id/final_approve/',
        },
      },
    );
    this.SaleMulticontractResource = $resource(
      '/api/contracts/sale-multicontracts/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/sale-multicontracts/:id/delete_confirmation/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/sale-multicontracts/predictions/',
        },
      },
    );
    this.PurchaseMulticontractResource = $resource(
      '/api/contracts/purchase-multicontracts/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/purchase-multicontracts/:id/delete_confirmation/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/purchase-multicontracts/predictions/',
        },
      },
    );
    this.IntermediateMulticontractResource = $resource(
      '/api/contracts/intermediate-multicontracts/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/intermediate-multicontracts/:id/delete_confirmation/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/intermediate-multicontracts/predictions/',
        },
      },
    );
    this.ExportMulticontractResource = $resource(
      '/api/contracts/export-multicontracts/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/export-multicontracts/:id/delete_confirmation/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/export-multicontracts/predictions/',
        },
      },
    );
    this.ServicesMulticontractResource = $resource(
      '/api/contracts/services-multicontracts/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/services-multicontracts/:id/delete_confirmation/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/services-multicontracts/predictions/',
        },
      },
    );
    this.SaleTicketResource = $resource(
      '/api/contracts/sale-tickets/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/sale-tickets/:id/delete_confirmation/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/sale-tickets/predictions/',
        },
      },
    );
    this.PurchaseTicketResource = $resource(
      '/api/contracts/purchase-tickets/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/purchase-tickets/:id/delete_confirmation/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/purchase-tickets/predictions/',
        },
      },
    );
    this.ExportTicketResource = $resource(
      '/api/contracts/export-tickets/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/export-tickets/:id/delete_confirmation/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/export-tickets/predictions/',
        },
      },
    );
    this.ServicesTicketResource = $resource(
      '/api/contracts/services-tickets/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/contracts/services-tickets/:id/delete_confirmation/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/contracts/services-tickets/predictions/',
        },
      },
    );
  }

  multicontractModal(item: any, data?: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<multicontract-modal
        item="item"
        data="data"
        modal-instance="$uibModalInstance"
      >
      </multicontract-modal>`,
      controller: [
        '$scope',
        'item',
        'data',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          item: any,
          data: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).item = item;
          ($scope as any).data = data;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        item: () => item,
        data: () => data,
      },
    }).result;
  }

  getMutiticketsPagesConfig() {
    const config: any = [
      {
        title: this.gettext('Multitickets'),
        state: 'multiticketsList.sale',
        icon: 'far fa-clipboard',
        counter: 'contracts.multicontract',
        counterParams: { stage: 'ticket' },
        tabs: [],
      },
    ];

    if (
      this.$rootScope.user.settings.SYSTEM_BLOCKS.block_deals_multicontract &&
      !this.$rootScope.user.settings.ONLY_GENERAL_AGREEMENTS
    ) {
      config[0].tabs.push(
        {
          title: this.gettext('Sale'),
          state: 'multiticketsList.sale',
          icon: 'fa-arrow-up',
          permissions: ['view_saleticket'],
        },
        {
          title: this.gettext('Purchase'),
          state: 'multiticketsList.purchase',
          icon: 'fa-arrow-down',
          permissions: ['view_purchaseticket'],
        },
      );

      if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_deals_export) {
        config[0].tabs.push({
          title: this.gettext('Export'),
          state: 'multiticketsList.export',
          icon: 'fa fa-arrow-up-from-bracket',
          permissions: ['view_exportticket'],
        });
      }
    }

    return config;
  }

  bulkSave(item: any, positions: any) {
    return this.MulticontractResource.bulkCreateOrUpdate({
      item: item,
      positions: positions,
    }).$promise;
  }

  createMultiContractFromPosition(contract: any) {
    const resource = this.ContractsService.getContractResource(contract);

    return resource.get({ id: contract.id, serializer: 'modal' }).$promise.then((data: any) => {
      const newMulticontract: any = {
        number: data.contract_number,
        stage: data.stage,
        with_derivative: Boolean(data.derivative),
        contract_type: data.contract_type,
        status: data.status,
        conclusion_date: data.conclusion_date,
        internal_chain: data.internal_chain,
        ignore_limits: data.ignore_limits,
        business_unit: data.business_unit,
        additional_info: data.additional_info,
        season: data.season,
        buyer: data.buyer,
        supplier: data.supplier,
        general_agreement: data.general_agreement,
        volume: data.volume,
        volume_options: data.volume_options,
        volume_options_company: data.volume_options_company,
        loading_option: data.loading_option,
        crop_year: data.crop_year,
        origin_of_crop: data.origin_of_crop,
        destination_of_crop: data.destination_of_crop,
        currency: data.currency,
        currency_exchange: data.currency_exchange,
        payment_conditions_option: data.payment_conditions_option,
        balance_payment: data.balance_payment,
        payment_conditions: data.payment_conditions,
        payment_conditions_auto: data.payment_conditions_auto,
        use_type: data.use_type,
      };
      newMulticontract.positions = [{ ...data }];
      return this.multicontractModal(newMulticontract, { ...newMulticontract });
    });
  }

  getTableData(params: any) {
    const { use_type: useType, contract_type: contractType, stage } = params;

    const multicontractResources = {
      services: this.MulticontractResource.servicesList,
      export: this.MulticontractResource.exportList,
      intermediate: this.MulticontractResource.intermediateList,
      commodity: {
        sale: this.MulticontractResource.saleList,
        purchase: this.MulticontractResource.purchaseList,
      },
      default: this.MulticontractResource.list,
    };
    const multiticketResources = {
      services: this.MultiticketResource.servicesList,
      export: this.MultiticketResource.exportList,
      commodity: {
        sale: this.MultiticketResource.saleList,
        purchase: this.MultiticketResource.purchaseList,
      },
    };
    const resources: any = stage === 'ticket' ? multiticketResources : multicontractResources;

    const selectedResource =
      resources[useType]?.[contractType] || resources[useType] || this.MulticontractResource.list;

    return selectedResource({ ...params }).$promise;
  }

  setFactVolumeToPlans(params: object) {
    return this.MulticontractResource.setFactVolumeToPlans(params).$promise;
  }

  setCargoConfirmation(params: object) {
    return this.MulticontractResource.setCargoConfirmation(params).$promise;
  }

  getModalData(params: object) {
    return this.MulticontractResource.get(params).$promise;
  }

  delete(item: { id: number }) {
    const resource = this.getContractResource(item);
    return this.CoreService.confirmDeletionModal(resource, item.id);
  }

  deletePosition(position: any) {
    const resourceMap: any = {
      ticket: this.ContractsService.Request,
      contract: this.ContractsService.getContractResource(position),
    };

    return this.CoreService.confirmDeletionModal(resourceMap[position.stage], position.id);
  }

  addFinance(multicontractId: number, invoiceType?: string) {
    return this.MulticontractResource.get({ id: multicontractId }).$promise.then(
      (multicontract: any) => {
        const { invoice, invoicePositions } = this.createInvoice(multicontract, invoiceType);
        return this.FinancesService.financeModal(invoice, {
          financePositions: invoicePositions,
        });
      },
    );
  }

  createInvoice(multicontract: any, invoiceCondition: any) {
    const getPaymentConditions = (paymentConditions: any) => {
      if (invoiceCondition == 'balance') {
        return 100 - paymentConditions;
      } else if (invoiceCondition == 'prepayment') {
        return paymentConditions;
      } else {
        return 100;
      }
    };

    const getInvoicePosition = (contract: any) => {
      return {
        use: 'cargo',
        contract: contract.id,
        crop: contract.cargo,
        quantity: contract.final_volume || contract.volume,
        quantity_total: contract.final_volume || contract.volume,
        price_per_piece: contract.price - contract.discount_mt || 0,
        vat_option: contract.VAT_option,
        vat_value: contract.VAT_value,
      };
    };

    const invoice = {
      invoice_type: multicontract.contract_type == 'sale' ? 'outgoing' : 'incoming',
      condition: invoiceCondition,
      business_unit: multicontract.business_unit,
      season: multicontract.season,
      contract: multicontract.id,
      volume: multicontract.volume,
      currency_exchange: multicontract.currency_exchange,
      currency: multicontract.currency,
      payment_conditions_option: multicontract.payment_conditions_option,
      payment_conditions: getPaymentConditions(multicontract.payment_conditions || 100),
    };

    const invoicePositions = multicontract.positions
      .filter((positon: any) => positon.status !== 'draft')
      .map((position: any) => getInvoicePosition(position));

    return { invoice: invoice, invoicePositions: invoicePositions };
  }

  cloneContract(objId: number) {
    return this.getModalData({
      id: objId,
      serializer: 'modal',
    }).then((obj: any) => {
      const oldPositions: any = [...obj.positions];
      const newObj = this.CoreUtils.cleanBeforeClone({ ...obj }, [
        'status',
        'approval_status',
        'positions',
      ]);
      newObj.positions = [];
      oldPositions.forEach((item: any) => {
        newObj.positions.push(
          this.CoreUtils.cleanBeforeClone({ ...item, use_type: obj.use_type }, [
            'multicontract',
            'status',
            'approval_status',
          ]),
        );
      });
      return this.multicontractModal(newObj, { clone: true, ...newObj });
    });
  }

  getContractResource(params: object) {
    return (this as any)[`${this.getContractModelName(params)}Resource`];
  }

  getContractModelName(params: any, secondPart?: any) {
    const nameFirstPart = params.use_type === 'commodity' ? params.contract_type : params.use_type;
    const nameSecondPart = params.stage === 'contract' ? 'Multicontract' : 'Ticket';

    if (!nameFirstPart) {
      return '';
    }

    const nameFirstPartFirstLetterUpperCase =
      nameFirstPart.charAt(0).toUpperCase() + nameFirstPart.slice(1);
    return `${nameFirstPartFirstLetterUpperCase}${secondPart || nameSecondPart}`;
  }

  getPermissionName(params: any, permType: any) {
    if (!params.stage || !params.contract_type || !params.use_type) {
      return null;
    }
    const modelName = this.getContractModelName(params).toLowerCase();
    return `${permType}_${modelName}`;
  }

  cancelContract(objId: number) {
    return this.getModalData({
      id: objId,
      serializer: 'modal',
    }).then((obj: any) => {
      obj.status = 'cancelled';
      obj.approval_status = 'rejected';
      obj.positions.forEach((item: any) => {
        item.status = 'cancelled';
        item.request_status = 'cancelled';
        item.approval_status = 'rejected';
      });
      return this.bulkSave(obj, obj.positions);
    });
  }

  getContractNumber(contract: any, positions: any) {
    let date;
    if (!contract.auto_name) {
      return this.$q.resolve();
    }

    if (contract.conclusion_date) {
      date = formatDate(contract.conclusion_date, 'yyyy-MM-dd');
    }

    const params = {
      supplier_id: contract.supplier,
      buyer_id: contract.buyer,
      currency_id: contract.currency,
      use_type: contract.use_type,
      contract_type: contract.contract_type,
      responsible_id: contract.responsible,
      stage: contract.stage,
      date: date,
      create_time: contract.create_time,
      conclusion_date: date,
      business_unit: contract.business_unit,
      general_agreement_id: contract.general_agreement,
      commodities_id: [...new Set(positions.map((position: any) => position.cargo))],
      charges_id: [...new Set(positions.map((position: any) => position.charge))],
      prices: [...new Set(positions.map((position: any) => position.price))],
      volumes: [...new Set(positions.map((position: any) => position.volume))],
      origins_of_crop_id: [...new Set(positions.map((position: any) => position.origin_of_crop))],
      counterparties_opposite_id: [
        ...new Set(positions.map((position: any) => position.counterparty_opposite)),
      ],

      terminals_id: [...new Set(positions.map((position: any) => position.terminal))],
      shipment_terminals_id: [
        ...new Set(positions.map((position: any) => position.shipment_terminal)),
      ],
      fixed_price_bonuses: [
        ...new Set(positions.map((position: any) => position.fixed_price_bonus)),
      ],

      ports_id: [...new Set(positions.flatMap((obj: any) => obj.ports))],
    };
    return this.MulticontractResource.generateNumber(params, (data: any) => data.contract_number)
      .$promise;
  }

  createContractFromTicket(item: any) {
    this.GtUtils.overlay('show');
    const deleteFields: any = ['id', 'approval_config'];
    if (!this.$rootScope.user.settings.COPY_TICKET_CONCLUSION_DATE) {
      deleteFields.push('conclusion_date');
    }
    return this.MulticontractResource.get({ id: item.id }, (multirequest: any) => {
      const multiContractPositions = JSON.parse(JSON.stringify([...multirequest.positions]));
      let multiRequestPositions = JSON.parse(JSON.stringify([...multirequest.positions]));

      let multicontract: any = { positions: [...multiContractPositions] };
      multicontract = ng.extend(multicontract, multirequest);
      multicontract.from_multirequest = multirequest.id;
      multicontract.stage = 'contract';
      multicontract.status = 'new';

      multicontract.positions.forEach((position: any) => {
        position.from_request = position.id;
        position.stage = 'contract';
        position.status = position.request_status === 'draft' ? 'draft' : 'new';
      });
      const deleteFieldFromPosition = (position: any, field: any) => {
        delete position[field];
        position.contract_prices.forEach((contractPrice: any) => delete contractPrice[field]);
      };
      deleteFields.forEach((key: any) => {
        delete multicontract[key];
        multicontract.positions.forEach((position: any) => deleteFieldFromPosition(position, key));
      });

      return this.multicontractModal(multicontract, { ...multicontract }).then((data: any) => {
        if (data == 'close') {
          return false;
        } else {
          multirequest.status = 'executed';
          multiRequestPositions = multiRequestPositions.map((pos: any) => {
            if (pos.request_status === 'approved') {
              return { ...pos, request_status: 'executed' };
            }
            return pos;
          });
          delete multirequest.positions;
          return this.bulkSave(multirequest, multiRequestPositions).then(() => {
            this.GtUtils.dismissAllModals();
            this.GtUtils.overlay('hide');
            this.$state.go('gt.multicontract', { id: data.item.id });
          });
        }
      });
    }).$promise;
  }

  createConnectToPassport(contract: any, positions?: any) {
    return this.ContractsService.createPassportContract(positions, contract);
  }

  connectToExistingPassport(contract: any, passportId: number, positions: any) {
    const promises = positions.map((position: any) =>
      this.ContractsService.Passport.attach_deal({
        id: passportId,
        deal_id: position.id,
        stage: position.stage,
        contract_purpose: position.contract_purpose,
        volume: position.true_volume,
      }),
    );
    return Promise.all(promises).then(() => {
      return this.ContractsService.passportModal(
        { id: passportId },
        { tab: contract.stage === 'ticket' ? 'plan' : 'fact' },
      );
    });
  }

  reverseDeal(contractId: number) {
    return this.getModalData({ id: contractId }).then((data: any) => {
      const contract = data;
      const deleteFields: any = [
        'id',
        'conclusion_date',
        'number',
        'status',
        'request_status',
        'contract_number',
        'costs',
        'qualities',
        'buyer',
        'supplier',
        'exporters',
        'multicontract',
        'approval_config',
        'responsible',
        'estimated_opposite_payment_date',
        'interest_rate',
        'loan_percentage',
      ];
      const oppositeContract = { ...contract };
      const oppositePositions: any = [];

      deleteFields.forEach((key: any) => {
        delete oppositeContract[key];
      });

      oppositeContract.contract_type =
        oppositeContract.contract_type === 'sale' ? 'purchase' : 'sale';

      oppositeContract.counterparty_opposite =
        contract.contract_type === 'sale' ? contract.buyer_client : contract.supplier_client;

      contract.positions.forEach((position: any) => {
        const oppositePosition = { ...position };
        oppositePosition.price = position.price_opposite;
        oppositePosition.price_opposite = position.price;
        oppositePosition.currency = position.currency_opposite || position.currency;
        oppositePosition.currency_opposite = position.currency;
        oppositePosition.basis = position.basis_opposite || position.basis;
        oppositePosition.basis_opposite = position.basis;
        oppositePosition.cargo = position.commodity_opposite || position.cargo;
        oppositePosition.commodity_opposite = position.cargo;
        oppositePosition.ports = position.ports_opposite || position.ports;
        oppositePosition.ports_opposite = position.ports;
        oppositePosition.date_of_execution =
          position.date_of_execution_opposite || position.date_of_execution;
        oppositePosition.end_of_execution = position.end_of_execution;
        oppositePosition.date_of_execution_opposite = position.date_of_execution;
        oppositePosition.load_rate = position.load_rate;
        oppositePosition.discharge_rate = position.discharge_rate;
        oppositePosition.volume = position.volume;

        deleteFields.forEach((key: any) => {
          delete oppositePosition[key];
        });
        oppositePositions.push(oppositePosition);
      });

      oppositeContract.positions = oppositePositions;

      return this.multicontractModal(oppositeContract, { ...oppositeContract }).then(
        (data: any) => {
          if (data == 'close') {
            return false;
          } else {
            this.GtUtils.dismissAllModals();
            this.GtUtils.overlay('hide');
            this.createConnectToPassport(contract.positions.concat(data.item.positions));
          }
        },
      );
    });
  }

  finalApprove(item: any) {
    return this.MulticontractResource.finalApprove({ id: item.id }, { item: item }).$promise;
  }

  addLogistic(contract: any) {
    const logistic = {
      buyer_multicontract: contract.contract_type === 'sale' ? contract.id : null,
      supplier_multicontract: contract.contract_type === 'purchase' ? contract.id : null,
    };

    return this.LogisticsService.logisticModal(logistic);
  }

  getFormNamePrefix(data: any) {
    if (!data.contract_type && !data.use_type) {
      return null;
    }

    const useTypeChoices = {
      commodity: 'commodity',
      spot: 'commodity',
      forward: 'commodity',
      brokerage: 'commodity',
      export: 'export',
      services: 'services',
      intermediate: 'intermediate',
    };
    const useType = useTypeChoices[data.use_type as keyof typeof useTypeChoices];

    return useType === 'commodity'
      ? `${data.contract_type}-${useType}-${data.stage}-`
      : `${useType}-${data.stage}-`;
  }

  getContractPositionFormConfig(position: any, formName: string, priceWidgetFields?: any) {
    return (
      position.stage === 'contract' ? this.ContractFormFieldsService : this.RequestFormFieldsService
    ).getFields(position, formName, priceWidgetFields);
  }
}

MulticontractService.$inject = [
  '$resource',
  '$uibModal',
  '$rootScope',
  '$q',
  '$state',
  'ContractsService',
  'FinancesService',
  'CoreService',
  'gettext',
  'CoreUtils',
  'GtUtils',
  'AccountsService',
  'FormFieldParamsService',
  'ContractFormFieldsService',
  'RequestFormFieldsService',
  'LogisticsService',
];
