import type ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';
import { notify } from '~/shared/lib/notify';

import template from './reassignment-modal.html?raw';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const ReassignmentModal = {
  bindings: {
    reassignment: '<',
    extraData: '<',
    modalInstance: '<',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$state',
    '$q',
    '$window',
    'gettext',
    'ReassignmentService',
    'FormFieldParamsService',
    'GtUtils',
    'ClientsService',
    'CoreService',
    'FinancesService',
    class {
      extraData: any;
      fields: any;
      form: any;
      isParentReassignment: any;
      modalInstance: any;
      reassignment: any;
      reassignmentPositions: any;
      subReassignmentQueryParams: QueryParams = {};
      updating: any;
      constructor(
        private readonly $scope: ng.IScope,
        private readonly $rootScope: GtRootScopeService,
        private readonly $state: ng.ui.IStateService,
        private readonly $q: ng.IQService,
        private readonly $window: ng.IWindowService,
        private readonly gettext: ng.gettext.gettextFunction,
        private readonly ReassignmentService: any,
        private readonly FormFieldParamsService: FormFieldParamsService,
        private readonly GtUtils: GtUtilsService,
        private readonly ClientsService: any,
        private readonly CoreService: CoreService,
        private readonly FinancesService: FinancesService,
      ) {
        this.reassignmentPositions = [];

        this.updating = true;
        this.form = undefined;
        this.fields = undefined;
        this.reassignment = {};
        this.subReassignmentQueryParams = {};
        this.isParentReassignment = false;
      }

      $onInit() {
        this.reassignmentPositions = this.extraData?.reassignmentPositions || [];
        this.$scope.$watch(
          () => this.reassignment.amount,
          () => this.updateValues(),
        );
        this.$scope.$watch(
          () => this.reassignmentPositions,
          () => this.updateAmountByPositions(),
          true,
        );
        this.$scope.$watch(
          () => this.reassignment.reassignment_rate,
          () => this.updateValues(),
        );
        this.updateParentReassignmentData();

        let promise;

        if (this.reassignment.id) {
          promise = this.updateData();
        } else if (this.isParentReassignment) {
          promise = this.getParentReassignmentPositionsData();
        } else {
          promise = this.$q.when();
        }

        promise.then(() => {
          this.updateFields();
          this.updating = false;
        });
      }

      updateAmountByPositions() {
        const amountSum = this.reassignmentPositions.reduce(
          (res: any, pos: any) => res + pos.reassignment_amount,
          0,
        );
        this.reassignment.amount = Math.round(amountSum * 100) / 100;
        this.updateValues().catch(errorHandler);
      }

      updateValues() {
        this.updateUsdAmount();
        return this.updateVatValues();
      }

      updateUsdAmount() {
        this.reassignment.amount_usd =
          Math.round((this.reassignment.amount / this.reassignment.reassignment_rate) * 100) / 100;
      }

      updateVatValues() {
        const lastPosition =
          this.reassignmentPositions[this.reassignmentPositions.length - 1] || {};
        return this.FinancesService.applyVat({
          value: this.reassignment.amount,
          volume: 1,
          vat_value: lastPosition.disbursementbl_contract_vat_value || 0,
          vat_type: 'vat',
        })
          .then((result) => {
            this.$scope.$apply(() => {
              this.reassignment.vat_amount = result;
            });
          })
          .catch(errorHandler);
      }

      getParentReassignmentPositionsData() {
        return this.ReassignmentService.getReassignmentPositions({
          reassignment: this.reassignment.child_reassignment,
        }).then((data: any) => {
          this.reassignmentPositions = data.results.reduce((res: any, pos: any) => {
            delete pos.id;
            return [...res, pos];
          }, []);
        });
      }

      updateParentReassignmentData() {
        this.isParentReassignment =
          this.reassignment.child_reassignment &&
          this.reassignment.child_reassignment !== undefined;
        this.subReassignmentQueryParams = { id_list: [this.reassignment.child_reassignment] };
      }

      updateData() {
        return this.ReassignmentService.getReassignmentModalData({
          id: this.reassignment.id,
        }).then((data: any) => {
          this.reassignment = data;
          this.updateParentReassignmentData();
          this.updateValues().catch(errorHandler);
          return this.ReassignmentService.getReassignmentPositions({
            reassignment: this.reassignment.id,
          }).then((data: any) => {
            this.reassignmentPositions = data.results;
            this.updating = false;
          });
        });
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }
      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }
      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        return this.modalInstance.close(data || 'cancel');
      }
      save(stayAfterSave: any) {
        this.form.$invalid = true;
        this.ReassignmentService.saveReassignment(this.reassignment, this.reassignmentPositions)
          .then((data: any) => {
            if (stayAfterSave) {
              this.reassignment = data.reassignment;
              this.updateData();
            } else {
              this.close('saved', true);
            }
            notify(this.gettext('Reassignment saved'));
          })
          .catch(this.GtUtils.errorClb);
      }
      destroy() {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return;
        }
        return this.ReassignmentService.destroy({ id: this.reassignment.id })
          .then(() => {
            this.close('deleted', true);
            this.$state.go('finances.reassignmentsPage');
          })
          .catch(this.GtUtils.errorClb);
      }
      reassignmentPositionIsValid() {
        return this.ReassignmentService.reassignmentPositionIsValid(this.reassignmentPositions);
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-md-6 col-xs-12',
          fieldGroup: [],
        };
        const col2: any = {
          className: 'form-group-container col-md-6 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('DETAILS'),
          },
          fieldGroup: [
            {
              key: 'number',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Reassignment number'),
                placeholder: this.gettext('Type reassignment number'),
                addon: this.gettext('#'),
                required: true,
              },
            },
            {
              key: 'status',
              type: 'gt-select',
              defaultValue: 'new',
              templateOptions: {
                label: this.gettext('Status'),
                placeholder: this.gettext('Choose status'),
                hint: this.gettext(
                  'New - after creation of reassignment. ' +
                    'Process - reassignment partly connected to offset. ' +
                    'Done - reassignment fully connected to offset.',
                ),
                disabled: true,
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: this.gettext('New'), value: 'new' },
                  { name: this.gettext('Done'), value: 'done' },
                ],
              },
            },
            {
              key: 'date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Date'),
                placeholder: this.gettext('date'),
              },
            },
            {
              key: 'sub_distributor',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Sub distributor'),
                placeholder: this.gettext('Sub distributor'),
                addPerms: ['add_client'],
                addIcon: this.GtUtils.getIcon('clients.Client'),
                queryParams: { role_name: 'supplier', is_distributor: '1' },
                addFunc: () => {
                  this.ClientsService.clientModal();
                },
                resource: 'clients.ClientRole',
                hint: this.gettext('Pick a distributor that will be bound to this reassignment'),
              },
              expressionProperties: {
                'templateOptions.disabled': () => this.isParentReassignment,
              },
              hideExpression: () => !this.isParentReassignment,
            },
            {
              key: 'distributor',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Distributor'),
                placeholder: this.gettext('Distributor'),
                addPerms: ['add_client'],
                addIcon: this.GtUtils.getIcon('clients.Client'),
                queryParams: { role_name: 'supplier', is_distributor: '1' },
                addFunc: () => {
                  this.ClientsService.clientModal();
                },
                resource: 'clients.ClientRole',
                hint: this.gettext('Pick a distributor that will be bound to this reassignment'),
              },
            },
            {
              key: 'owner',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Owner'),
                placeholder: this.gettext('Owner'),
                addPerms: ['add_client'],
                addIcon: this.GtUtils.getIcon('clients.Client'),
                queryParams: { role_name: 'owner' },
                addFunc: () => {
                  this.ClientsService.clientModal();
                },
                resource: 'clients.ClientRole',
                hint: this.gettext('Pick an owner that will be bound to this reassignment'),
              },
              expressionProperties: {
                'templateOptions.disabled': () => this.isParentReassignment,
              },
            },
            {
              key: 'contract',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Contract'),
                placeholder: this.gettext('Choose contract'),
                resource: 'contracts.ContractBase',
                required: true,
              },
              expressionProperties: {
                'templateOptions.disabled': () => this.isParentReassignment,
              },
            },
            {
              key: 'crop_protection',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Crop protection'),
                required: false,
                type: 'number',
              },
              expressionProperties: {
                'templateOptions.disabled': ($viewValue: any, $modelValue: any, scope: any) =>
                  scope.model.seeds && scope.model.seeds !== 0,
              },
            },
            {
              key: 'seeds',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Seeds'),
                required: false,
                type: 'number',
              },
              expressionProperties: {
                'templateOptions.disabled': ($viewValue: any, $modelValue: any, scope: any) =>
                  scope.model.crop_protection && scope.model.crop_protection !== 0,
              },
            },

            {
              key: 'has_docs_copy',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Has docs copy'),
              },
            },
            {
              key: 'has_docs_original',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Has docs original'),
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              defaultValueResolve: () => this.CoreService.getDefaultBuId(this.reassignment),
              templateOptions: {
                label: this.gettext('Business unit'),
                placeholder: this.gettext('Business Unit'),
                queryParams: this.GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
              },
              expressionProperties: {
                'templateOptions.disabled': () => this.isParentReassignment,
              },
            },
          ],
        });

        col2.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('FINANCES'),
          },
          fieldGroup: [
            {
              key: 'amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Amount'),
                placeholder: this.gettext('Amount'),
                type: 'number',
                disabled: true,
                required: true,
                maxlength: '13', // real value is 14. this is hundred of millions and 3 dig after coma.
                // for example 100 000 000,200
              },
            },
            {
              key: 'vat_amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('VAT amount'),
                placeholder: this.gettext('VAT amount'),
                type: 'number',
                disabled: true,
                maxlength: '13', // real value is 14. this is hundred of millions and 3 dig after coma.
                // for example 100 000 000,200
              },
            },
            {
              key: 'amount_usd',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Amount USD'),
                placeholder: this.gettext('Amount USD'),
                type: 'number',
                disabled: true,
                maxlength: '13', // real value is 14. this is hundred of millions and 3 dig after coma.
                // for example 100 000 000,200
              },
            },
            {
              key: 'reassignment_rate',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Reassignment rate'),
                placeholder: this.gettext('Reassignment rate'),
                type: 'number',
                disabled: true,
                required: true,
                maxlength: '14', // real value is 14. this is hundred of millions and 3 dig after coma.
                // for example 100 000 000,200
              },
            },
            {
              key: 'manager',
              type: 'gt-ui-select',
              defaultValue: this.$rootScope.user.id,
              templateOptions: {
                label: this.gettext('Manager'),
                placeholder: this.gettext('User to be manager for the reassignment'),
                resource: 'auth.User',
              },
              expressionProperties: {
                'templateOptions.disabled': () => this.isParentReassignment,
              },
            },
            {
              key: 'additional_info',
              type: 'gt-textarea',
              templateOptions: {
                label: this.gettext('Additional Info'),
                placeholder: this.gettext('Specific information about this object'),
                className: 'additional-info',
              },
            },
          ],
        });

        return {
          formName: 'reassignment-edit-modal',
          fieldsDef: [col1, col2],
        };
      }
    },
  ],
};
