import React from 'react';

import type { EntityName } from '~/shared/lib/entities';

import type { DataType, DataTypeProps } from './lib';
import { entityAutocompletesRegistry, getDataTypeComponent } from './lib';

export const useEntityAutocomplete = (entityName: EntityName) => {
  const EntityAutocomplete = React.useMemo(() => {
    if (!entityAutocompletesRegistry[entityName]) {
      throw new Error(`EntityAutocomplete "${entityName}" is not registered.`);
    }
    return entityAutocompletesRegistry[entityName];
  }, [entityName]);

  return EntityAutocomplete;
};

export const useDataTypeComponent = <T extends DataType>(
  dataType: T,
  mode: 'read' | 'write',
  props: Omit<DataTypeProps<T>, 'value' | 'message'> = {},
) => {
  const result = React.useMemo(() => {
    const InnerComponent = getDataTypeComponent(dataType, mode);

    const Component = (args: Pick<DataTypeProps<T>, 'value' | 'message'>) => (
      <InnerComponent {...{ ...props, ...args }} />
    );
    Component.displayName = `DataTypeComponent(${dataType})`;

    return Component;
  }, [dataType, mode, props]);

  return result;
};
