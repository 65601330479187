export class CostsStatisticsReportService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/costs-statistics-report/',
      params: params,
    }).then((data: any) => {
      return data.data.results[0];
    });
  }
}
CostsStatisticsReportService.$inject = ['$http'];
