import { ContainerProps } from '~/core/entity-lists';
import { SaleLogisticRecord, SaleLogisticRepository } from '~/features/execution/logistic';
import { BaseLogisticListModel } from '~/features/execution/logistic/common/components/logistic-container/base-logistic-list.model';
import { injectable } from '~/shared/lib/di';

export type SaleLogisticListContainerProps = ContainerProps;

@injectable()
export class SaleLogisticListModel extends BaseLogisticListModel<
  SaleLogisticRecord,
  SaleLogisticRepository
> {
  entityRepoClass = SaleLogisticRepository;
}
