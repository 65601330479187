import type ng from 'angular';

import template from './general-transactions-container.tpl.html?raw';

import { type GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import { type GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import { type FinancesService } from '^/app/finances/legacy/finances.srv';

class GeneralTransactionsContainerController implements ng.IController {
  transactions = [];
  transactionsCount = 0;
  newGeneralTransaction = {};
  filterLevel?: string;
  initQueryParams?: QueryParams;
  addButton?: any;
  searchPrompt?: string;
  queryParams?: QueryParams;

  static readonly $inject = ['$scope', 'GtUtils', 'FinancesService', 'gtFilterService'];

  constructor(
    private readonly $scope: ng.IScope,
    private GtUtils: GtUtilsService,
    private FinancesService: FinancesService,
    private gtFilterService: GtFilterService,
  ) {}

  $onInit() {
    this.filterLevel = this.filterLevel ?? 'general-transactions-container';
    this.queryParams = {
      serializer: 'table_info',
      ...this.initQueryParams,
    };
    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
    this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
      this.queryParams = data;
      this.updateGeneralTransactions();
    });
    this.updateGeneralTransactions();
  }

  $onChanges() {
    this.queryParams = {
      serializer: 'table_info',
      ...this.initQueryParams,
    };
  }
  copyToClipboard(id: number) {
    this.GtUtils.copyToClipboard(id);
  }

  updateGeneralTransactions() {
    this.GtUtils.overlay('show');
    this.newGeneralTransaction = { ...this.queryParams };
    return this.FinancesService.FinanceTransaction.tabInfo(this.queryParams, (data: any) => {
      this.transactions = data.results;
      this.transactionsCount = data.count;
      this.GtUtils.overlay('hide');
    }).$promise;
  }

  openGeneralTransactionModal(object: any) {
    return this.FinancesService.generalTransactionModal(object).then(
      this.updateGeneralTransactions.bind(this),
    );
  }
}

export const generalTransactionsContainer: ng.IComponentOptions = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    addButton: '<?',
    searchPrompt: '<?',
  },
  template: template,
  controller: GeneralTransactionsContainerController,
};
