import React from 'react';

import type { PageFiltersProps } from '~/core/page-filters/types';
import { useComponent } from '~/shared/lib/components';

import { PageFiltersModel } from '../../services/page-filters.model';
import { FiltersLine } from '../filters-line';

export const PageFilters = (props: PageFiltersProps) => {
  const { ComponentProvider } = useComponent(props, PageFiltersModel);

  return (
    <ComponentProvider>
      {props.mode === 'line' ? <FiltersLine /> : <>Mode {props.mode} not implemented</>}
    </ComponentProvider>
  );
};
