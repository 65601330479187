import { clientsClientrolesList, clientsClientrolesPredictionsRetrieve } from '~/shared/api';
import { EntityRepository, type Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { ClientRole, ClientRoleQueryParams } from '../lib/types';

export class ClientRoleRepository extends EntityRepository<ClientRole, ClientRoleQueryParams> {
  readonly entityName = 'clients.ClientRole';

  getFn = (_id: number): Promise<ClientRole> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: ClientRole): Promise<ClientRole> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<ClientRole>): Promise<ClientRole> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: ClientRoleQueryParams): Promise<Paginated<ClientRole>> => {
    const { data } = await clientsClientrolesList({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await clientsClientrolesPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
}
