import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').directive('logisticsTariffItem', directive);

  function directive() {
    return {
      template: require('./tariff-item.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        tariff: '=?',
        updateList: '=?',
      },
    };
  }

  Controller.$inject = [
    'ClientsService',
    'gtFilterService',
    'LogisticsService',
    'CropsService',
    'gettext',
  ];

  function Controller(
    this: any,
    ClientsService: any,
    gtFilterService: GtFilterService,
    LogisticsService: LogisticsService,
    CropsService: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.createDeliverer = createDeliverer;
    vm.save = save;
    vm.destroy = destroy;
    vm.errors = {};
    vm.placeFilter = { crop_place: true };

    vm.$onInit = function () {
      vm.tariff = vm.tariff || {};
      vm.mode = vm.tariff.id ? 'view' : 'edit';
      vm.updateList = vm.updateList || gtFilterService.updateQueryParams;
      CropsService.Crop.query({}, function (data: any) {
        vm.crops = data.results;
      });
    };

    function updateData() {
      return LogisticsService.LogisticTariff.get({ id: vm.tariff.id }, function (data: any) {
        vm.tariff = data;
        vm.mode = 'view';
        vm.errors = {};
      }).$promise;
    }

    function save() {
      if (vm.tariff.id) {
        return LogisticsService.LogisticTariff.update(vm.tariff, updateData, _error).$promise;
      }
      return LogisticsService.LogisticTariff.save(
        vm.tariff,
        function () {
          vm.updateList().then(function () {
            vm.tariff = vm.tariff || {};
            vm.errors = {};
          });
        },
        _error,
      ).$promise;
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete Tariff?');
      if (!confirm(msg)) {
        return;
      }
      return LogisticsService.LogisticTariff.delete(
        vm.tariff,
        function () {
          vm.updateList().then(function () {
            vm.tariff = {};
          });
        },
        _error,
      ).$promise;
    }

    function createDeliverer() {
      return ClientsService.roleModal({
        model_name: 'Deliverer',
        prices_section: true,
      });
    }

    function _error(data: any) {
      vm.errors = data.data;
    }
  }
})();
