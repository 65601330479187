import type ng from 'angular';

import type { AccountsService } from '^/app/accounts/accounts.service';

export class UserPermissionService {
  $resource: ng.resource.IResourceService;
  AccountsService: AccountsService;
  UserPermissionResource: any;
  constructor($resource: ng.resource.IResourceService, AccountsService: AccountsService) {
    this.$resource = $resource;
    this.AccountsService = AccountsService;
    this.UserPermissionResource = $resource(
      '/api/accounts/permissions/:id/',
      {
        id: '@id',
      },
      {
        list: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/permissions/',
        },
        gropedByApps: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/permissions/grouped_by_apps/',
        },
      },
    );
  }
  getUserPermissionData(params: object) {
    return this.UserPermissionResource.get(params).$promise;
  }
  getUserGroupPermissionData(params: object) {
    return this.AccountsService.Group.query(params).$promise;
  }
  getGroupedUserPermissionData(params: object) {
    return this.UserPermissionResource.gropedByApps(params).$promise;
  }
}
UserPermissionService.$inject = ['$resource', 'AccountsService'];
