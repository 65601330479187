import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').controller('GeneralAgreementPageViewController', Controller);

  Controller.$inject = [
    '$rootScope',
    '$stateParams',
    '$state',
    'ContractsService',
    'ClientsService',
    'AccountsService',
    'DocumentsService',
    'GtUtils',
    'PageService',
    'gettext',
    'FormFieldParamsService',
    'GeneralAgreementFormFieldsService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $stateParams: ng.ui.IStateParamsService,
    $state: ng.ui.IStateService,
    ContractsService: ContractsService,
    ClientsService: any,
    AccountsService: AccountsService,
    DocumentsService: any,
    GtUtils: GtUtilsService,
    PageService: PageService,
    gettext: ng.gettext.gettextFunction,
    FormFieldParamsService: FormFieldParamsService,
    GeneralAgreementFormFieldsService: any,
  ) {
    const vm = this;

    vm.agreement = { id: $stateParams.id };
    vm.log_entries_count = 0;
    vm.editAgreement = editAgreement;
    vm.updateData = updateData;
    vm.updateFields = updateFields;
    vm.getFormConfig = getFormConfig;
    vm.goBack = goBack;
    vm.tab = 'specification';

    vm.openGeneralAgreementsModal = openGeneralAgreementsModal;
    vm.openRequestModal = openRequestModal;
    vm.openContractModal = openContractModal;
    vm.openDocxModal = openDocxModal;
    vm.openRoleModal = openRoleModal;
    vm.openRoleDetails = openRoleDetails;
    vm.approvalAction = approvalAction;
    vm.fields = [];
    vm.form = undefined;

    vm.quickContractModals = $rootScope.user.settings.QUICK_MODALS;
    vm.newContract = {};
    vm.newRequest = {};

    activate();

    ////////////////

    function activate() {
      PageService.setConfig({});
      updateData();
    }

    function updateData() {
      GtUtils.overlay('show');
      return ContractsService.GeneralAgreements.get(
        {
          id: vm.agreement.id,
          serializer: 'full',
        },
        function (res: any) {
          vm.agreement = res;

          vm.newContract = {
            contract_type: res.agreement_type,
            currency: res.currency,
            buyer: res.buyer,
            supplier: res.supplier,
            deal_type: res.deal_type,
            general_agreement: res.id,
            cargo: res.commodity,
            business_unit: res.business_unit,
            VAT_option: res.vat_option,
            VAT_value: res.vat_value,
          };
          vm.newRequest = {
            contract_type: res.agreement_type,
            currency: res.currency,
            buyer: res.buyer,
            supplier: res.supplier,
            deal_type: res.deal_type,
            general_agreement: res.id,
            cargo: res.commodity,
            business_unit: res.business_unit,
            VAT_option: res.vat_option,
            VAT_value: res.vat_value,
            stage: 'ticket',
          };
          PageService.setPageTitle($rootScope.pageTitle + ' #' + vm.agreement.number);
          updateFields();
          GtUtils.overlay('hide');
        },
        function () {
          notify(GtUtils.translate(gettext("Agreement doesn't exist")));
          $state.go('contracts.agreements');
          GtUtils.overlay('hide');
        },
      ).$promise;
    }

    function updateFields() {
      FormFieldParamsService.getFields(getFormConfig())
        .then(function (fields: any) {
          vm.fields = fields;
          vm.updating = false;
        })
        .catch(GtUtils.errorClb);
    }

    function getFormConfig() {
      return GeneralAgreementFormFieldsService.getFields(vm.agreement);
    }

    function editAgreement() {
      ContractsService.generalAgreementsModal(vm.agreement).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        if (!data.id) {
          return vm.goBack();
        }
        updateData();
      });
    }

    function openGeneralAgreementsModal() {
      return ContractsService.generalAgreementsModal(vm.agreement).then(updateData);
    }

    function openDocxModal() {
      return DocumentsService.generateDocxModal('GeneralAgreement', vm.agreement.id);
    }

    function openRoleModal(id: number, model: any) {
      return ClientsService.roleModal({ id: id, model_name: model });
    }

    function approvalAction(action: any) {
      return AccountsService.voteApprovable(
        action,
        vm.agreement.id,
        vm.agreement.content_type,
        '',
      ).then(() => updateData());
    }

    function goBack() {
      $state.go('contracts.agreements');
    }

    function openContractModal(contract: any) {
      return ContractsService.contractModal(contract).then((data: any) => {
        if (!data || data == 'cancel') {
          return;
        }
        updateData();
      });
    }

    function openRequestModal(request: any) {
      return ContractsService.requestModal(request).then((data: any) => {
        if (!data || data == 'cancel') {
          return;
        }
        updateData();
      });
    }

    function openRoleDetails(id: number) {
      return ClientsService.openRoleDetails({ id: id });
    }
  }
})();
