import ng from 'angular';

import { VehicleDetailsItemContainer } from './item-container/vehicle-details-item-container.component';
import { VehicleDetailsItemPage } from './item-page/vehicle-details-item-page.component';
import { VehicleDetailsItemView } from './item-view/vehicle-details-item-view.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const vehicleConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('vehicleDetails', {
      parent: 'gt.page',
      url: '/vehicle-details/:id',
      component: 'vehicleDetailsItemPage',
      resolve: {
        filterLevel: () => {
          return 'vehicle-details-item-page';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('vehicle-details-item-page');
          },
        ],
      },
      data: {
        pageTitle: gettext('Vehicle details'),
      },
    });
  },
];

export const vehicleDetails = ng
  .module('transport.vehicles.details', [])
  .component('vehicleDetailsItemPage', VehicleDetailsItemPage)
  .component('vehicleDetailsItemContainer', VehicleDetailsItemContainer)
  .component('vehicleDetailsItemView', VehicleDetailsItemView)
  .config(vehicleConfig).name;
