import { financesCurrencyExchangesPredictionsRetrieve } from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { CurrencyExchange, CurrencyExchangeQueryParams } from '../lib';

export class CurrencyExchangeRepository extends EntityRepository<
  CurrencyExchange,
  CurrencyExchangeQueryParams
> {
  readonly entityName = 'finances.CurrencyExchange';

  getFn = (_id: number): Promise<CurrencyExchange> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: CurrencyExchangeQueryParams): Promise<Paginated<CurrencyExchange>> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: CurrencyExchange): Promise<CurrencyExchange> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<CurrencyExchange>): Promise<CurrencyExchange> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await financesCurrencyExchangesPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };
}
