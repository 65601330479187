import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('lineupPageView', {
    bindings: {},
    template: require('./lineup-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    'gtFilterService',
    'ReportsService',
    'PageService',
    'GtUtils',
    'gettext',
    'LogisticsService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    gtFilterService: GtFilterService,
    ReportsService: ReportsService,
    PageService: PageService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    LogisticsService: LogisticsService,
  ) {
    const vm = this;
    vm.filterLevel = 'lineup-page-view';
    vm.quickAdd = false;

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-clients-tab',
        quickAdd: () => {
          vm.quickAdd = !vm.quickAdd;
        },
        pages: ReportsService.getPagesConfig(),
      };
      config.buttons = [
        new PageService.buttons.Refresh(vm.filterLevel),
        new PageService.buttons.Filters(),
        new PageService.buttons.ScreenCaptureTask(),
      ];
      config.filters = {
        filterLevel: vm.filterLevel,
        clicked: false,
        search: true,
        multiSelects: [
          {
            argument: 'passport_list',
            placeholder: gettext($rootScope.user.settings.PASSPORT_TITLE),
            resource: 'passports.passport',
          },
          {
            argument: 'voyage_list',
            placeholder: gettext('voyage'),
            resource: 'logistics.voyage',
          },
          {
            argument: 'cargo_list',
            placeholder: gettext('passport commodity'),
            resource: 'crops.crop',
          },
          {
            argument: 'contract_cargo_list',
            placeholder: gettext('contract commodity'),
            resource: 'crops.crop',
          },
          {
            argument: 'supplier_list',
            placeholder: gettext('supplier'),
            resource: 'clients.supplier',
            related_fk: 'purchase_facts__contract__supplier',
            related_model: 'passports.Passport',
          },
          {
            argument: 'buyer_list',
            placeholder: gettext('buyer'),
            resource: 'clients.buyer',
            related_fk: 'sale_facts__contract__buyer',
            related_model: 'passports.Passport',
          },
          {
            argument: 'vessel_list',
            placeholder: gettext('vessel'),
            resource: 'logistics.vessel',
            related_fk: 'voyage__vessel',
            related_model: 'passports.Passport',
          },
          {
            argument: 'sale_contract_list',
            placeholder: gettext('sale contract'),
            resource: 'contracts.salecontract',
            related_fk: 'sale_facts__contract',
            related_model: 'passports.Passport',
          },
          {
            argument: 'purchase_contract_list',
            placeholder: gettext('purchase contract'),
            resource: 'contracts.purchasecontract',
            related_fk: 'purchase_facts__contract',
            related_model: 'passports.Passport',
          },
          {
            argument: 'country_of_destination_list',
            placeholder: gettext('destination country'),
            resource: 'location.Country',
            related_fk: 'destination_of_crop',
            related_model: 'passports.Passport',
          },
          {
            argument: 'country_of_origin_list',
            placeholder: gettext('origin country'),
            resource: 'location.Country',
            related_fk: 'origin_of_crop',
            related_model: 'passports.Passport',
          },
          {
            argument: 'custom_status_list',
            placeholder: gettext('custom status'),
            resource: 'core.CustomStatus',
            related_fk: 'custom_status',
            related_model: 'passports.Passport',
            getQueryParams: () => {
              return { content_type__model: 'passport' };
            },
          },
          {
            argument: 'business_unit_list',
            placeholder: gettext('business unit'),
            resource: 'core.BusinessUnit',
            getQueryParams: GtUtils.getBUQueryParams,
          },
          {
            argument: 'responsible_list',
            placeholder: gettext('responsible'),
            resource: 'auth.user',
            related_fk: 'responsible',
            related_model: 'passports.Passport',
          },
          {
            argument: 'owner_list',
            placeholder: gettext('owner'),
            resource: 'clients.owner',
            related_fk: 'owner_list',
            related_model: 'passports.Passport',
          },
        ],
        nestedSelects: [
          gtFilterService.getBoolFilter('with_origin_country', gettext('With origin country')),
          gtFilterService.getBoolFilter(
            'with_destination_country',
            gettext('With destination country'),
          ),
          gtFilterService.getBoolFilter('with_business_unit', gettext('Has Business Unit')),
          gtFilterService.getBoolFilter('with_responsible', gettext('Has responsible')),
          gtFilterService.getBoolFilter('with_control_date', gettext('Has control date')),
          gtFilterService.getBoolFilter('with_bl', gettext('Has BL')),
        ],
        nestedMultiSelects: [
          gtFilterService.getPassportStatusFilter('status_list'),
          {
            argument: 'voyage_status_list',
            placeholder: gettext('Voyage status'),
            items: LogisticsService.getVoyageStatuses(),
          },
        ],
      };
      return config;
    }
  }
})();
