import type ng from 'angular';

import template from './client-logistic-payment-reconciliation-report-container.html?raw';
import type { ClientLogisticPaymentReconciliationReportService } from '../client-logistic-payment-reconciliation-report.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import { html } from '^/shared/utils';

export const ClientLogisticPaymentReconciliationReportContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'ClientLogisticPaymentReconciliationReportService',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      Service: ClientLogisticPaymentReconciliationReportService;
      data: any;
      filterLevel = 'client-logistic-payment-reconciliation-report-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams & { contract_type: string } = { contract_type: '' };
      tableData: any;
      tableOptions: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        ClientLogisticPaymentReconciliationReportService: ClientLogisticPaymentReconciliationReportService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.Service = ClientLogisticPaymentReconciliationReportService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.initQueryParams = {};

        this.tableOptions = {};
      }
      $onInit() {
        this.filterLevel =
          this.filterLevel || 'client-logistic-payment-reconciliation-report-container';

        this.queryParams = {
          contract_type: '',
          has_connected_logistic: '1',
          ...this.initQueryParams,
        };
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.tableOptions = this.getTableOptions();
        this.updateData();
      }

      updateData() {
        this.Service.getReportData(this.queryParams).then((response: any) => {
          this.data = response.data;
          this.tableData = this.data
            .filter((row: any) => row.data?.length || this.data.length === 1)
            .map((row: any) => ({
              rows: row.data,
              total: row,
              count: row.data?.length || 1,
            }));
        });
      }

      updateFilter() {
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
        this.updateData();
      }

      clearFilter() {
        this.gtFilterService.setQueryParams(this.initQueryParams, this.filterLevel);
        this.queryParams = { contract_type: '', ...this.initQueryParams };
        this.updateData();
      }

      getContractResource() {
        return (
          {
            sale: 'contracts.salecontract',
            purchase: 'contracts.purchasecontract',
          }[this.queryParams.contract_type] ?? 'contracts.contractbase'
        );
      }

      getTableOptions() {
        return {
          tableName: 'client-logistic-payment-reconciliation',
          filterLevel: this.filterLevel,
          configurable: true,
          tableClass: 'table-right-align contract-charges-table',

          columnDefs: [
            {
              title: this.gettext('Counterparty'),
              columnName: 'client_name',
              class: 'td-left-align',
              totalTemplate: html`
                <i class="fa-solid fa-chart-simple"></i>
                <translate>TOTAL</translate>
              `,
            },
            {
              title: this.gettext('Upload date'),
              columnName: 'date',
              class: 'td-left-align',
              cellTemplate: "{[{ item.date | date:'dd-MM-yyyy'}]}",
            },
            {
              title: this.gettext('Terminal'),
              class: 'td-left-align',
              columnName: 'terminal_name',
            },
            {
              title: this.gettext('Commodity'),
              class: 'td-left-align',
              columnName: 'commodity_title',
            },

            {
              title: this.gettext('Vehicle number'),
              class: 'td-left-align',
              columnName: 'vehicle_number',
            },
            {
              title: this.gettext('Consignment number'),
              class: 'td-left-align',
              columnName: 'consignment_number',
            },
            {
              title: this.gettext('Declared volume'),
              columnName: 'volume',
              cellTemplate: '{[{ item.volume | number:3}]}',
              totalTemplate: html`
                <span
                  ><translate>Period totals data</translate>:
                  <div class="clearfix"></div>
                  {[{ item.volume | number:3 }]}
                </span>
                <div class="clearfix"></div>
                <br />
                <span
                  ><translate>TOTAL data</translate>:
                  <div class="clearfix"></div>
                  {[{ item.total_volume | number:3 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Volume accepted'),
              columnName: 'volume_real',
              showTotals: true,
              cellTemplate: '{[{ item.volume_real | number:3}]}',
              totalTemplate: html`
                <span
                  ><translate>Period totals data</translate>:
                  <div class="clearfix"></div>
                  {[{ item.volume_real | number:3 }]}
                </span>
                <div class="clearfix"></div>
                <br />
                <span
                  ><translate>TOTAL data</translate>:
                  <div class="clearfix"></div>
                  {[{ item.total_volume_real | number:3 }]}
                </span>
              `,
            },

            {
              title: this.gettext('Price with discount'),
              columnName: 'price',
              cellTemplate: '{[{ item.price | number:2}]}',
            },
            {
              title: this.gettext('Amount with discount'),
              columnName: 'logistic_value',
              cellTemplate: '{[{ item.logistic_value | number:2}]}',
              totalTemplate: html`
                <span
                  ><translate>Period totals data</translate>:
                  <div class="clearfix"></div>
                  {[{ item.logistic_value | number:2 }]}
                </span>
                <div class="clearfix"></div>
                <br />
                <span
                  ><translate>TOTAL data</translate>:
                  <div class="clearfix"></div>
                  {[{ item.total_logistic_value | number:2 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Paid'),
              columnName: 'payment_value',
              cellTemplate: '{[{ item.payment_value | number:2}]}',
              totalTemplate: html`
                <span
                  ><translate>Period totals data</translate>:
                  <div class="clearfix"></div>
                  {[{ item.payment_value | number:2 }]}
                </span>
                <div class="clearfix"></div>
                <br />
                <span
                  ><translate>TOTAL data</translate>:
                  <div class="clearfix"></div>
                  {[{ item.total_payment_value | number:2 }]}
                </span>
              `,
            },
            {
              title: this.gettext('Debts'),
              columnName: 'balance',
              cellTemplate: '{[{ item.balance | number:2}]}',
              totalTemplate: html`
                <span
                  ><translate>Period totals data</translate>:
                  <div class="clearfix"></div>
                  {[{ item.balance | number:2 }]}
                </span>
                <div class="clearfix"></div>
                <br />
                <span
                  ><translate>TOTAL data</translate>:
                  <div class="clearfix"></div>
                  {[{ item.total_balance | number:2 }]}
                </span>
              `,
            },

            {
              title: this.gettext('Quality'),
              columnName: 'logisticqualities',
              class: 'td-left-align',
              cellTemplate: html`
                <span>
                  <ul>
                    <li ng-repeat="q in item.logisticqualities">
                      <span class="smaller-label">{[{q.title}]} : {[{q.value | number:2}]}</span>
                    </li>
                  </ul>
                </span>
              `,
            },
          ],
        };
      }

      downloadXlsxReport() {
        this.Service.downloadXlsxReport(this.queryParams);
      }
    },
  ],
};
