import template from './warehouses-rest-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const WarehousesRestContainer = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<',
  },
  template,
  controller: [
    '$scope',
    '$window',
    '$httpParamSerializer',
    'GtUtils',
    'CropsService',
    'ReportsService',
    'gtFilterService',

    class {
      $httpParamSerializer: any;
      $scope: ng.IScope;
      $window: ng.IWindowService;
      CropsService: any;
      GtUtils: GtUtilsService;
      ReportsService: ReportsService;
      crops: any;
      data: any;
      filterLevel = 'warehouses-rest-container';
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      totals: any;
      warehouses: any;
      constructor(
        $scope: ng.IScope,
        $window: ng.IWindowService,
        $httpParamSerializer: any,
        GtUtils: GtUtilsService,
        CropsService: any,
        ReportsService: ReportsService,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.$window = $window;
        this.$httpParamSerializer = $httpParamSerializer;
        this.GtUtils = GtUtils;
        this.CropsService = CropsService;
        this.ReportsService = ReportsService;
        this.gtFilterService = gtFilterService;
        this.warehouses = [];
        this.crops = [];
        this.initQueryParams = {};
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'warehouses-container';
        this.queryParams = {
          page_size: 999,
          show_all_stock: 0,
          ...this.initQueryParams,
        };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateWarehouses();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);

        this.$scope.$watch('this.showAllStock', (newValue: any, oldValue: any) => {
          if (newValue !== oldValue) {
            this.queryParams.show_all_stock = newValue ? '1' : '0';
            this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
          }
        });
      }

      updateWarehouses() {
        this.GtUtils.overlay('show');
        this.ReportsService.WarehouseRest.query(this.queryParams).$promise.then((data: any) => {
          this.data = data[0].data;
          this.totals = data[0];
          this.GtUtils.overlay('hide');
        });
      }

      applyFilter(filters: any) {
        this.gtFilterService.updateQueryParams(filters, this.filterLevel);
      }
    },
  ],
};
