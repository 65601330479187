import { clientsDeliverersPredictionsRetrieve } from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Deliverer, DelivererQueryParams } from '../lib';

export class DelivererRepository extends EntityRepository<Deliverer, DelivererQueryParams> {
  readonly entityName = 'clients.Deliverer';

  getFn = (_id: number): Promise<Deliverer> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: DelivererQueryParams): Promise<Paginated<Deliverer>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await clientsDeliverersPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  createFn = (_entity: Deliverer): Promise<Deliverer> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Deliverer>): Promise<Deliverer> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
