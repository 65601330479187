import type { CustomField, CustomFieldDataItem } from '~/core/custom-fields';
import type { ListOption } from '~/shared/lib/types';
import type { DataType } from '~/shared/ui/data-types';

import type { ColumnParams, DataParams, DataRecord, FieldDef } from '../lib/types';

const dataTypes: Record<CustomField['field_type'], DataType> = {
  boolean: 'checkbox',
  date: 'date',
  number: 'number',
  string: 'string',
  text: 'text',
  choice: 'select',
  multiple_choices: 'multiselect',
};

export function updateCustomFieldValue<R extends DataRecord>(
  record: R,
  fieldId: number,
  newValues: Partial<CustomFieldDataItem>,
): R {
  return {
    ...record,
    custom_fields_data: record.custom_fields_data?.map((entry) =>
      entry.field_id === fieldId ? { ...entry, ...newValues } : entry,
    ),
  };
}

export function parseMultiSelectValue(
  record: DataRecord,
  fieldId: number,
  choices: { id: number; title: string }[],
) {
  const entry = record.custom_fields_data?.find((f) => f.field_id === fieldId);
  if (!entry?.value?.trim()) {
    return undefined;
  }

  const titles = entry.value
    .split('\n')
    .map((line) => line.replace('•', '').trim())
    .filter(Boolean);

  const matched = choices
    .filter((c) => titles.includes(c.title))
    .map((c) => ({ value: c.id.toString(), label: c.title }));
  return matched.length ? matched : undefined;
}

export function updateMultiSelectValue<R extends DataRecord>(
  record: R,
  fieldId: number,
  selected: ListOption[],
) {
  const joinedString = selected?.map((opt) => `•${opt.label}`).join('\n');
  return updateCustomFieldValue(record, fieldId, {
    value: joinedString,
    value_multiple_choices: selected?.map((opt) => Number(opt.value)),
  });
}

export function parseSelectValue(
  record: DataRecord,
  fieldId: number,
  choices: { id: number; title: string }[],
) {
  const entry = record.custom_fields_data?.find((f) => f.field_id === fieldId);
  if (!entry?.value) {
    return undefined;
  }
  const numericId = parseInt(entry.value, 10);

  if (isNaN(numericId)) {
    const byTitle = choices.find((c) => c.title === entry.value);
    return byTitle ? { value: String(byTitle.id), label: byTitle.title } : undefined;
  }

  const found = choices.find((c) => c.id === numericId);
  return found ? { value: found.id.toString(), label: found.title } : undefined;
}

export function updateSelectValue<R extends DataRecord>(
  record: R,
  fieldId: number,
  option: ListOption,
) {
  const numericId = parseInt(option.value, 10);
  return updateCustomFieldValue(record, fieldId, {
    value: isNaN(numericId) ? option.label : numericId.toString(),
    value_choice: isNaN(numericId) ? undefined : numericId,
  });
}

export const fieldDefFromCustomField = <R extends DataRecord, P extends DataParams>(
  customField: CustomField,
) => {
  const dataType = dataTypes[customField.field_type];

  const field = {
    key: `_custom_field.${customField.key}`,
    title: customField.title,
    editing: true,
  };

  switch (dataType) {
    case 'multiselect':
      return {
        ...field,
        typeExtra: {
          options: customField.choices.map((choice) => ({
            value: choice.id.toString(),
            label: choice.title,
          })),
        },
        dataType: 'multiselect',
        getValue: (rec) => parseMultiSelectValue(rec, customField.id, customField.choices),
        setValue: ({ record, value }) =>
          value ? updateMultiSelectValue(record, customField.id, value) : record,
      } as FieldDef<R, P>;
    case 'select':
      return {
        ...field,
        typeExtra: {
          options: customField.choices.map((choice) => ({
            value: choice.id.toString(),
            label: choice.title,
          })),
        },
        dataType: 'select',
        getValue: (rec) => parseSelectValue(rec, customField.id, customField.choices),
        setValue: ({ record, value }) =>
          value ? updateSelectValue(record, customField.id, value) : undefined,
      } as FieldDef<R, P>;
    case 'text':
      return {
        ...field,
        typeExtra: {} as never,
        dataType: 'text',
        getValue: (rec) => rec.custom_fields_data?.find((f) => f.field_id == customField.id)?.value,
        setValue: ({ record, value }) =>
          updateCustomFieldValue(record, customField.id, { value_text: value, value }),
      } as FieldDef<R, P>;
    case 'string':
      return {
        ...field,
        typeExtra: {} as never,
        dataType: 'string',
        getValue: (rec) => rec.custom_fields_data?.find((f) => f.field_id == customField.id)?.value,
        setValue: ({ record, value }) =>
          updateCustomFieldValue(record, customField.id, { value_string: value, value }),
      } as FieldDef<R, P>;
    case 'number':
      return {
        ...field,
        typeExtra: {} as never,
        dataType: 'number',
        getValue: (rec) => {
          const val = rec.custom_fields_data?.find((f) => f.field_id == customField.id)?.value;
          return val ? parseFloat(val) : undefined;
        },
        setValue: ({ record, value }) =>
          updateCustomFieldValue(record, customField.id, {
            value_number: value,
            value: String(value),
          }),
      } as FieldDef<R, P>;
    case 'checkbox':
      return {
        ...field,
        typeExtra: {} as never,
        dataType: 'checkbox',
        getValue: (rec) => {
          return !!rec.custom_fields_data?.find((f) => f.field_id == customField.id)?.value;
        },
        setValue: ({ record, value }) =>
          updateCustomFieldValue(record, customField.id, {
            value_boolean: value,
            value: String(value),
          }),
      } as FieldDef<R, P>;
    case 'date':
      return {
        ...field,
        typeExtra: {} as never,
        dataType: 'date',
        getValue: (rec) => {
          const val = rec.custom_fields_data?.find((f) => f.field_id == customField.id)?.value;
          return val ? new Date(val) : undefined;
        },
        setValue: ({ record, value }) =>
          updateCustomFieldValue(record, customField.id, {
            value_date: value,
            value: String(value),
          }),
      } as FieldDef<R, P>;
    default:
      throw new Error(`Unknown customField type: ${customField.field_type}`);
  }
};

export const applyColumnParams = <R extends DataRecord, P extends DataParams>(
  fields: FieldDef<R, P>[],
  params: ColumnParams[],
): FieldDef<R, P>[] => {
  const fieldsByKey = fields.reduce(
    (acc, field) => ({ ...acc, [field.key]: field }),
    {} as Record<string, FieldDef<R, P>>,
  );

  const paramsByKey = params
    .filter((p) => p.column_name in fieldsByKey)
    .reduce(
      (acc, param) => ({ ...acc, [param.column_name]: param }),
      {} as Record<string, ColumnParams>,
    );

  const orderedFields = fields.toSorted((a, b) => {
    const aIndex = paramsByKey[a.key.toString()]?.index ?? 0;
    const bIndex = paramsByKey[b.key.toString()]?.index ?? 0;
    return aIndex - bIndex;
  });
  return orderedFields
    .map((field) => {
      const param = paramsByKey[field.key.toString()];
      if (param) {
        field.title = param.new_title?.length ? param.new_title : field.title;
        field.visible = param.visible ?? true;
      }
      return field;
    })
    .filter((field) => field.visible !== false);
};
