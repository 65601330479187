import React from 'react';

import { Tag } from '~/shared/ui/kit/tag';

export const PaymentDelayTemplate: React.FC<{
  paidDiffDays?: number;
  paidDiffCoef?: number;
}> = ({ paidDiffDays, paidDiffCoef }) => {
  if (paidDiffDays === undefined && paidDiffCoef === undefined) {
    return <span className="text-text-secondary text-xs">---</span>;
  }

  return (
    <div
      data-testid="payment-delay-template"
      className="flex max-w-[200px] items-center gap-2 overflow-hidden whitespace-nowrap"
    >
      {paidDiffDays && <Tag variant={paidDiffDays < 0 ? 'red' : 'green'}>{paidDiffDays}</Tag>}
      {paidDiffCoef && <span className="text-text-secondary text-xs">AVG {paidDiffCoef}</span>}
    </div>
  );
};
