import type ng from 'angular';

import template from './credit-report-container.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';

export const CreditReportContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'gettext',
    'GtUtils',
    'gtFilterService',
    'CreditReportService',
    'CoreService',
    class creditReportContainer {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      CoreService: CoreService;
      CreditReportService: any;
      GtUtils: GtUtilsService;
      contractStatuses: any;
      data: any;
      dateFilterOptions: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      savedFilterChoices: any;
      showFilters: any;
      startQueryParams: QueryParams = {};
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        CreditReportService: any,
        CoreService: CoreService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.CreditReportService = CreditReportService;
        this.CoreService = CoreService;

        this.filterLevel = '';
        this.queryParams = {};
        this.initQueryParams = {};
        this.showFilters = false;

        this.data = {};
        this.dateFilterOptions = [
          {
            predicate: 'passport_business_date',
            title: gettext('Passport business date'),
          },
          {
            predicate: 'date_of_execution',
            title: gettext('Execution date'),
          },
        ];
        this.contractStatuses = [
          { id: 'washout', title: this.gettext('Washout') },
          { id: 'new', title: this.gettext('New') },
          { id: 'delivery_period', title: this.gettext('Delivery period') },
          { id: 'shipment', title: this.gettext('Shipment') },
          { id: 'cargo_executed', title: this.gettext('Cargo executed') },
          { id: 'executed', title: this.gettext('Executed') },
          { id: 'cancelled', title: this.gettext('Cancelled') },
        ];
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'credit-report-page-view';
        this.startQueryParams = {
          owner_list: [this.$rootScope.user.settings.DEFAULT_VALUES.owner],
          is_fully_paid: 0,
          internal_chain: 0,
          status_list: ['new', 'delivery_period', 'shipment', 'cargo_executed'],
        };
        this.queryParams = { ...this.initQueryParams, ...this.startQueryParams };
        this.queryParams.group_by = 'buyer';

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
      }

      applyFilters() {
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      setQueryParamsByGroupBy() {
        this.queryParams = { ...this.startQueryParams, ...this.queryParams };
        this.applyFilters();
      }

      updateData() {
        if (!this.queryParams.group_by) {
          return;
        }
        this.GtUtils.overlay('show');
        return this.CreditReportService.getData(this.queryParams).then((data: any) => {
          this.data = data.data;
          this.GtUtils.overlay('hide');
        });
      }

      resetFilter() {
        this.queryParams = { ...this.startQueryParams };
        this.applyFilters();
      }
    },
  ],
};
