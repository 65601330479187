import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import { useComponent } from '~/shared/lib/components';

import type { QualitiesLogisticListContainerProps } from './qualities-logistic-list.model';
import { QualitiesLogisticListModel } from './qualities-logistic-list.model';

export const QualitiesLogisticContainer = (props: QualitiesLogisticListContainerProps) => {
  const { ComponentProvider } = useComponent(props, QualitiesLogisticListModel);

  return (
    <ComponentProvider>
      <EntityListContainer />;
    </ComponentProvider>
  );
};
