import {
  type DateArg,
  differenceInCalendarDays,
  differenceInMonths,
  format,
  isValid,
  parse,
} from 'date-fns';

export const dateFormat = (date: Date, formatType: string) => format(date, formatType);

export const dateFormatToRecent = (date: Date, formatType: string) => {
  const todayDate = new Date();
  const daysDifference = differenceInCalendarDays(todayDate, date);
  const monthsDifference = differenceInMonths(todayDate, date);

  if (monthsDifference < 1) {
    return `${daysDifference * -1} days ago`;
  }

  return dateFormat(date, formatType);
};

export const dateApiString = (date: Date) => format(date, 'dd.MM.yyyy');

export const formatDateRange = (
  range?: { from?: Date | string; to?: Date | string },
  formatType = 'dd/MM/yy',
): string => {
  if (!range) {
    return '';
  }

  const fromStr = range.from ? dateFormat(new Date(range.from), formatType) : '';
  const toStr = range.to ? dateFormat(new Date(range.to), formatType) : '';

  return `${fromStr} - ${toStr}`;
};

export const parseDate = (date: string, fmt?: string): Date | undefined => {
  for (const format of fmt ? [fmt] : dateFormats) {
    const parsed = parse(date, format, new Date());
    if (isValid(parsed)) {
      return parsed;
    }
  }
  return undefined;
};
export const dateFormats = [
  'dd.MM.yyyy',
  'dd.MM.yy',
  'dd-MM-yyyy',
  'dd-MM-yy',
  'dd/MM/yyyy',
  'dd/MM/yy',
  'yyyy-MM-dd',
  "yyyy-MM-dd'T'HH:mm:ss",
];
export const formatDate = (date: DateArg<Date>, fmt = 'yyyy-MM-DD'): string => format(date, fmt);
