import type ng from 'angular';

import template from './oil-extension-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const OilExtensionListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'OilExtensionService',
    'gettext',
    class voyageTariffContainer {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      OilExtensionService: any;
      dataCount: number;
      dataList: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        OilExtensionService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.OilExtensionService = OilExtensionService;

        this.filterLevel = '';
        this.queryParams = {};

        this.dataList = [];
        this.dataCount = 0;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'oil-extension-page-view';
        this.queryParams = { page_size: 20, ...this.initQueryParams };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: QueryParams) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.OilExtensionService.getListData(this.queryParams).then((data: any) => {
          data.data.results.unshift({
            contract: this.queryParams.contract,
          });
          this.dataList = data.data.results;
          this.dataCount = data.data.count;
          this.GtUtils.overlay('hide');
        });
      }

      saveItem(item: any) {
        return this.OilExtensionService.save(item).then(
          () => this.updateData(),
          this.GtUtils.errorClb,
        );
      }

      destroy(item: any) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return false;
        }
        return this.OilExtensionService.delete({ id: item }).then(
          () => this.updateData(),
          this.GtUtils.errorClb,
        );
      }
    },
  ],
};
