import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import { useComponent } from '~/shared/lib/components';

import type { InfoLogisticListContainerProps } from './info-logistic-list.model';
import { InfoLogisticListModel } from './info-logistic-list.model';

export const InfoLogisticContainer = (props: InfoLogisticListContainerProps) => {
  const { ComponentProvider } = useComponent(props, InfoLogisticListModel);

  return (
    <ComponentProvider>
      <EntityListContainer />;
    </ComponentProvider>
  );
};
