import type ng from 'angular';

import {
  endOfDay,
  endOfMonth,
  endOfYear,
  formatDate,
  parseDate,
  startOfDay,
  startOfMonth,
  startOfYear,
} from '~/shared/lib/date';

import template from './cashflow-report-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const CashflowReportPage = {
  bindings: {
    variant: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'PageService',
    'gtFilterService',
    'ReportsService',
    'FinancesService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      FinancesService: FinancesService;
      PageService: PageService;
      ReportsService: ReportsService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      indicatorView: any;
      indicatorViews: any;
      modes: any;
      queryParams: QueryParams = {};
      showBalanceReport: any;
      showCashflowBalanceReport: any;
      showCashflowReport: any;
      variant: any;
      constructor(
        $rootScope: GtRootScopeService,
        PageService: PageService,
        gtFilterService: GtFilterService,
        ReportsService: ReportsService,
        FinancesService: FinancesService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.ReportsService = ReportsService;
        this.FinancesService = FinancesService;
        this.gettext = gettext;

        this.filterLevel = 'cashflow-report-page-view';
        this.indicatorViews = [];
        this.modes = [];
        this.showBalanceReport = true;
        this.showCashflowReport = true;
        this.queryParams = {};
      }

      $onInit() {
        this.variant = this.variant || 'split-tables';
        this.showBalanceReport = this.variant === 'split-tables';
        this.showCashflowReport = this.showBalanceReport;
        this.showCashflowBalanceReport = !this.showCashflowReport;

        this.indicatorViews = ['horizontal', 'mixed', 'vertical'];
        this.modes = ['day', 'week', 'month', 'quarter', 'year'];
        this.indicatorView = 'horizontal';

        this.PageService.setConfig(this.getPageConfig());
        this.queryParams = {
          mode: 'month',
          view: 'tree',
          turnover_groupping: 'currency,bank_account,owner',
          rests_groupping: 'currency,finance_type,owner',
          start_date: formatDate(this.$rootScope.user.settings.CASHFLOW_START_DATE, 'dd.MM.yyyy'),
          end_date: formatDate(
            new Date(
              parseInt(this.$rootScope.user.settings.DEFAULT_VALUES.crop_year, 10),
              new Date().getMonth() + 1,
              0,
            ),
            'dd.MM.yyyy',
          ),
          report_column_type_list: ['fact'],
        };
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.Filters(),
          ],
          pages: this.ReportsService.getPagesConfig(),
        };
        config.filters = {
          filterLevel: this.filterLevel,
          clicked: false,
          search: false,
          dates: true,
          useFlexDateRange: true,
          invert_filters: true,
          multiSelects: [
            {
              argument: 'clientrole_to_list',
              placeholder: this.gettext('receivers'),
              resource: 'clients.clientrole',
            },
            {
              argument: 'clientrole_from_list',
              placeholder: this.gettext('senders'),
              resource: 'clients.clientrole',
            },
            {
              argument: 'bank_account_list',
              placeholder: this.gettext('bank accounts'),
              resource: 'finances.bankaccount',
            },
            {
              argument: 'business_unit_list',
              placeholder: this.gettext('business units'),
              resource: 'core.businessunit',
            },
            {
              argument: 'contract_list',
              placeholder: this.gettext('contracts'),
              resource: 'contracts.contractbase',
            },
            {
              argument: 'charge_list',
              placeholder: this.gettext('costs'),
              resource: 'finances.charge',
            },
            {
              argument: 'general_expenses_list',
              placeholder: this.gettext('general expenses'),
              resource: 'finances.generalexpenses',
            },
            {
              argument: 'other_activities_list',
              placeholder: this.gettext('other activities'),
              resource: 'finances.otheractivity',
            },
            {
              argument: 'cargo_list',
              placeholder: this.gettext('commodity'),
              resource: 'crops.crop',
            },
            {
              argument: 'owner_list',
              placeholder: this.gettext('owners'),
              resource: 'clients.owner',
            },
            {
              argument: 'currency_list',
              placeholder: this.gettext('currency'),
              resource: 'finances.currency',
            },
            {
              argument: 'counterparty_list',
              placeholder: this.gettext('Counterparty'),
              resource: 'clients.client',
            },
          ],
          nestedSelects: [
            {
              argument: 'invoice_type',
              placeholder: this.gettext('payment type'),
              items: [
                { id: 'incoming', title: this.gettext('expense') },
                { id: 'outgoing', title: this.gettext('receipt') },
              ],
            },
            this.gtFilterService.getBoolFilter(
              'show_debts_indate',
              this.gettext('Show debts by plan date'),
            ),
            this.gtFilterService.getBoolFilter(
              'show_bank_transaction',
              this.gettext('Show bank transaction'),
            ),
            this.gtFilterService.getBoolFilter(
              'show_past_periods_debts',
              this.gettext('Show past periods debts'),
            ),
            this.gtFilterService.getBoolFilter(
              'show_intermediate_contracts_cargo_positions',
              this.gettext('Show intermediate contract`s cargo positions'),
            ),
          ],
          nestedMultiSelects: [
            {
              argument: 'report_column_type_list',
              placeholder: this.gettext('amount type'),
              items: [
                { id: 'debt', title: this.gettext('Debts') },
                { id: 'plan', title: this.gettext('Plans') },
                { id: 'fact', title: this.gettext('Facts') },
                { id: 'overpaid', title: this.gettext('Overpaids') },
              ],
            },
            {
              argument: 'finance_type_list',
              placeholder: this.gettext('finance type'),
              items: [
                {
                  id: 'payment_comission',
                  title: this.gettext('payment commission'),
                },
                { id: 'payment', title: this.gettext('payment') },
                { id: 'invoice', title: this.gettext('invoice') },
                { id: 'offset plus', title: this.gettext('offset plus') },
                { id: 'offset minus', title: this.gettext('offset minus') },
                { id: 'bank_transaction', title: this.gettext('Bank transaction') },
                {
                  id: 'bank_transaction_commission',
                  title: this.gettext('Bank transaction commission'),
                },
              ],
            },
            {
              argument: 'invoice_assignment',
              placeholder: this.gettext('Invoice Assignment'),
              items: [
                { id: 'commercial', title: this.gettext('Commercial Invoice') },
                { id: 'proforma', title: this.gettext('Proforma Invoice') },
                { id: 'debit', title: this.gettext('Debit Note') },
                { id: 'credit', title: this.gettext('Credit Note') },
              ],
            },
            {
              argument: 'contract_deal_type',
              placeholder: this.gettext('Contract deal type'),
              items: [
                { id: 'spot', title: this.gettext('Spot') },
                { id: 'forward', title: this.gettext('Forward') },
                { id: 'intermediate', title: this.gettext('Intermediate') },
                { id: 'export', title: this.gettext('Export') },
              ],
            },
            {
              argument: 'overpaid_directions_list',
              placeholder: this.gettext('Overpaid directions'),
              items: [
                { id: 'incoming', title: this.gettext('Incoming') },
                { id: 'outgoing', title: this.gettext('Outgoing') },
              ],
            },
          ],
        };
        return config;
      }

      changeMode(mode: any) {
        if (this.queryParams.mode == mode) {
          return;
        }
        this.queryParams.mode = mode;
        this.applyFilters();
      }

      changeView(indicatorView: any) {
        if (this.indicatorView === indicatorView) {
          return;
        }
        this.indicatorView = indicatorView;
      }

      applyFilters() {
        this.setDatesByMode();
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      setDatesByMode() {
        const mode = this.queryParams.mode as 'day' | 'month' | 'year';
        const start = parseDate(this.queryParams.start_date as string, 'dd.MM.yyyy');
        const end = parseDate(this.queryParams.end_date as string, 'dd.MM.yyyy');
        const modeToFn = {
          day: {
            start: startOfDay,
            end: endOfDay,
          },
          month: {
            start: startOfMonth,
            end: endOfMonth,
          },
          year: {
            start: startOfYear,
            end: endOfYear,
          },
        };
        const startFn = modeToFn[mode].start;
        const endFn = modeToFn[mode].end;

        if (start && end) {
          this.queryParams.start_date = formatDate(startFn(start), 'dd.MM.yyyy');
          this.queryParams.end_date = formatDate(endFn(end), 'dd.MM.yyyy');
        }
      }
    },
  ],
};
