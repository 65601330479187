import type ng from 'angular';

import { formatDate } from '~/shared/lib/date';

import template from './cashflow-modal.html?raw';

export const CashflowModal = {
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
  template,
  controller: [
    'gettext',
    'CashflowService',
    class {
      CashflowService: any;
      cashflow: any;
      close: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      resolve: any;
      constructor(gettext: ng.gettext.gettextFunction, CashflowService: any) {
        this.gettext = gettext;
        this.CashflowService = CashflowService;
        this.form = undefined;
        this.fields = [];
        this.cashflow = {};
      }

      $onInit() {
        this.cashflow = this.resolve.params || {};
        this.fields = this.getFields();
      }

      recreate() {
        this.cashflow.date_from =
          this.cashflow.date_from && formatDate(this.cashflow.date_from, 'dd.MM.yyyy');
        this.cashflow.date_to =
          this.cashflow.date_to && formatDate(this.cashflow.date_to, 'dd.MM.yyyy');
        this.CashflowService.recreate(this.cashflow).then(() => this.closeModal({}, true));
      }

      closeModal(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.close({ $value: 'cancel' });
      }

      getFields() {
        const col1: any = {
          className: 'form-group-container col-md-12 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('CASH FLOW RECREATE'),
          },
          fieldGroup: [
            {
              key: 'date_from',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Start date'),
                placeholder: this.gettext('date'),
                type: 'date',
                required: true,
                addon: this.gettext('date'),
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'date_to',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('End date'),
                placeholder: this.gettext('date'),
                type: 'date',
                required: true,
                addon: this.gettext('date'),
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'currency',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Currency'),
                placeholder: this.gettext('Currency'),
                resource: 'finances.Currency',
                required: true,
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Business Unit'),
                placeholder: this.gettext('Business Unit'),
                resource: 'core.BusinessUnit',
              },
            },
          ],
        });

        return [col1];
      }
    },
  ],
};
