import { notify } from '~/shared/lib/notify';

import template from './general-agreements-container.html?raw';
import type { ContractsService } from '../../../legacy/contracts.srv';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';

export const GeneralAgreementsContainer = {
  bindings: {
    initQueryParams: '<?',
    addButton: '<?',
    filterLevel: '<?',
    quickAddInit: '<?',
    onUpdate: '&?',
    rebuildPageConfig: '&?',
  },
  template,
  controller: [
    'UtilsService',
    '$scope',
    'gtFilterService',
    'ContractsService',
    'AccountsService',
    'ClientsService',
    'DocumentsService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      AccountsService: AccountsService;
      ClientsService: any;
      ContractsService: ContractsService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      agreements: any;
      agreementsCount: number;
      filterLevel = 'contracts-general-agreements';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      rebuildPageConfig: any;
      constructor(
        UtilsService: any,
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        ContractsService: ContractsService,
        AccountsService: AccountsService,
        ClientsService: any,
        DocumentsService: any,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.ContractsService = ContractsService;
        this.AccountsService = AccountsService;
        this.ClientsService = ClientsService;
        this.DocumentsService = DocumentsService;
        this.GtUtils = UtilsService.GtUtils;
        this.$rootScope = UtilsService.$rootScope;
        this.gettext = UtilsService.gettext;
        this.agreementsCount = 0;
        this.initQueryParams = {};
      }

      $onInit() {
        this.queryParams = {
          serializer: 'full',
          deal_type: 'spot',
          agreement_type: this.$rootScope.user.profile?.default_general_agreement_tab_view,
          ...this.initQueryParams,
        };

        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.$scope.$on('general-agreements__remove', (ev: any, data: any) => {
          this.removeAgreement(data);
        });
        this.$scope.$on('general-agreements__update', () => {
          this.updateAgreements();
        });
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateAgreements();
        });
        this.updateAgreements();
      }

      updateAgreements() {
        this.GtUtils.overlay('show');
        return this.ContractsService.GeneralAgreements.query(this.queryParams).$promise.then(
          (data: any) => {
            this.agreements = data.results;
            this.agreementsCount = data.count;
            this.GtUtils.overlay('hide');
          },
        );
      }

      removeAgreement(agreement: any) {
        if (!confirm(this.gettext('Are you sure that you want delete agreement?'))) {
          return;
        }
        return this.ContractsService.GeneralAgreements.delete({
          id: agreement.id,
        }).$promise.then(() => {
          notify(this.gettext('Agreement removed'));
          return this.updateAgreements();
        });
      }

      cloneGeneralAgreement(agreement: any) {
        return this.ContractsService.cloneGeneralAgreement(agreement).then(() =>
          this.updateAgreements(),
        );
      }

      openGeneralAgreementsModal(agreement: any) {
        return this.ContractsService.generalAgreementsModal(agreement).then(() =>
          this.updateAgreements(),
        );
      }

      updateQueryParams(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
        this.rebuildPageConfig({ params: params });
      }

      approvalAction(action: any, agreementId: number, contentType: any) {
        return this.AccountsService.voteApprovable(action, agreementId, contentType, '').then(() =>
          this.updateAgreements(),
        );
      }

      openRoleModal(id: number, model: any) {
        return this.ClientsService.roleModal({ id: id, model_name: model });
      }

      openDocxModal(id: number) {
        return this.DocumentsService.generateDocxModal('GeneralAgreement', id);
      }

      openContractModal(agreement: any) {
        return this.ContractsService.contractModal({
          contract_type: agreement.agreement_type,
          currency: agreement.currency,
          buyer: agreement.buyer,
          supplier: agreement.supplier,
          deal_type: agreement.deal_type,
          general_agreement: agreement.id,
          owner: agreement.owner,
          VAT_option: agreement.vat_option,
          VAT_value: agreement.vat_value,
        }).then(() => {
          this.updateAgreements();
        });
      }
    },
  ],
};
