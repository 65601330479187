import { ContainerProps } from '~/core/entity-lists';
import {
  QualitiesLogisticRecord,
  QualitiesLogisticRepository,
} from '~/features/execution/logistic';
import { BaseLogisticListModel } from '~/features/execution/logistic/common/components/logistic-container/base-logistic-list.model';
import { injectable } from '~/shared/lib/di';

export type QualitiesLogisticListContainerProps = ContainerProps;

@injectable()
export class QualitiesLogisticListModel extends BaseLogisticListModel<
  QualitiesLogisticRecord,
  QualitiesLogisticRepository
> {
  entityRepoClass = QualitiesLogisticRepository;
}
