import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { FinancesService } from '../../../finances.srv';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').directive('paymentInfoRow', directive);

  function directive() {
    return {
      template: require('./payment-info-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        paymentInfo: '<',
        updateList: '&?',
        saveItem: '&?',
        deleteItem: '&?',
        setHovering: '&?',
        edit: '<?',
        editOnly: '<?',
      },
    };
  }

  Controller.$inject = ['$scope', 'FinancesService', 'gettext', 'GtUtils'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    FinancesService: FinancesService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
  ) {
    const vm = this;
    let firstLoad = true;
    vm.cancel = cancel;
    vm.save = save;
    vm.destroy = destroy;
    vm.addFinanceBank = addFinanceBank;
    vm.openPaymentInfoModal = openPaymentInfoModal;
    vm.clonePaymentInfo = clonePaymentInfo;

    ////////////////

    vm.$onInit = function () {
      vm.edit = vm.editOnly || false;
      if (!vm.paymentInfo.id) {
        vm.edit = true;
      }
      $scope.$watchCollection('vm.paymentInfo.financebank', function () {
        if (firstLoad) {
          firstLoad = false;
          return false;
        }
        financebankAutofill();
      });
    };

    function financebankAutofill() {
      if (!vm.paymentInfo.financebank) {
        return;
      }
      FinancesService.FinanceBank.get({ id: vm.paymentInfo.financebank }).$promise.then(function (
        data: any,
      ) {
        vm.paymentInfo.bank_name_eng = data.full_name_eng;
        vm.paymentInfo.bank_code = data.bank_code;
        vm.paymentInfo.swift = data.swift;
        vm.paymentInfo.legal_address = data.address;
        vm.paymentInfo.legal_address_eng = data.address_eng;
        vm.paymentInfo.country = data.country;
      });
    }

    function openPaymentInfoModal(paymentInfo: any) {
      FinancesService.paymentInfoModal(paymentInfo).then(() => vm.updateList());
    }

    function save() {
      return vm.saveItem({ item: vm.paymentInfo }).then(
        function () {
          vm.edit = vm.editOnly || false;
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }

    function clonePaymentInfo(paymentInfo: any) {
      if (!confirm('Do you want to clone this Bank details?')) {
        return;
      }

      const clonedPaymentInfo = cleanPaymentInfo(paymentInfo);

      return FinancesService.savePaymentInfo(clonedPaymentInfo)
        .then((savedPaymentInfo: any) => {
          updateListSuccess();
          notify(gettext('Bank details is cloned'));

          const tempPaymentInfo = ng.copy(savedPaymentInfo);
          vm.openPaymentInfoModal(tempPaymentInfo);
        })
        .catch(handleError);
    }

    function cleanPaymentInfo(paymentInfo: any) {
      const excludeFields: any = ['id', 'create_time', 'update_time', 'status', 'bank_accounts'];
      return Object.keys(paymentInfo)
        .filter((key) => paymentInfo[key] && !excludeFields.includes(key))
        .reduce((obj, key) => ({ ...obj, [key]: paymentInfo[key] }), {});
    }

    function updateListSuccess() {
      vm.updateList();
    }

    function handleError(e: any) {
      GtUtils.errorClb(e);
    }

    function cancel() {
      vm.edit = false;
      vm.edit = vm.editOnly || false;
      vm.updateList();
    }

    function destroy() {
      if (!confirm(gettext('Are you sure that you want delete?'))) {
        return;
      }
      return vm.deleteItem({ item: vm.paymentInfo }).then(
        function () {
          vm.updateList();
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }

    function addFinanceBank() {
      FinancesService.financeBankModal().then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        vm.paymentInfo.financebank = data.id;
      });
    }
  }
})();
