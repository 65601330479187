import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './customs-cargo-declaration-list-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { CustomsCargoDeclarationService } from '^/app/execution/components/transport/customs-cargo-declaration/customs-cargo-declaration.service';

export const CustomsCargoDeclarationListContainer = {
  bindings: {
    initQueryParams: '<?',
    tableName: '<?',
    filterLevel: '<',
    readonly: '<',
    invoiceCreatingModal: '<?',
    ccdList: '<?',
    ccdCreationParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'CustomsCargoDeclarationService',
    'ContractsService',
    'gettext',
    class customsCargoDeclarationContainer {
      $scope: ng.IScope;
      ContractsService: ContractsService;
      CustomsCargoDeclarationService: CustomsCargoDeclarationService;
      GtUtils: GtUtilsService;
      ccdCreationParams: any;
      ccdList: any;
      dataCount: number;
      dataList: any;
      dataTotal: any;
      defaultDataList: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams?: QueryParams = {};
      invoiceCreatingModal: any;
      newDeclaration: any;
      queryParams: QueryParams = {};
      tableName: string;
      exportConfigName: string;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        CustomsCargoDeclarationService: CustomsCargoDeclarationService,
        ContractsService: ContractsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.exportConfigName = 'customs-cargo-declaration-page-view';
        this.$scope = $scope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.CustomsCargoDeclarationService = CustomsCargoDeclarationService;
        this.ContractsService = ContractsService;

        this.filterLevel = '';
        this.tableName = '';
        this.queryParams = {};

        this.dataList = [];
        this.ccdList = [];
        this.defaultDataList = [];
        this.dataCount = 0;
        this.dataTotal = {};
        this.invoiceCreatingModal = false;
        this.ccdCreationParams = undefined;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'customs-cargo-declaration-page-view';
        this.tableName = this.tableName || this.filterLevel;
        if (this.invoiceCreatingModal) {
          this.defaultDataList = JSON.parse(JSON.stringify(this.ccdList));
          this.dataList = this.ccdList.filter((ccd: any) => !ccd?.id);
        } else {
          this.dataList = [];
        }
        this.queryParams = { page_size: 25, ...this.initQueryParams };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        if (this.initQueryParams) {
          this.newDeclaration = {
            ...this.initQueryParams,
            export_contract: this.initQueryParams.export_contract,
          };
        }
      }

      applyFilters(params: QueryParams) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.CustomsCargoDeclarationService.getCCDData(this.queryParams).then(
          (data: any) => {
            this.dataList = this.invoiceCreatingModal
              ? [...this.dataList, ...data.data.results]
              : data.data.results;
            this.dataCount = data?.data.count || this.dataList.length;
            this.dataTotal = data.totals;
            this.GtUtils.overlay('hide');
          },
        );
      }

      saveItem(item: any) {
        return this.CustomsCargoDeclarationService.saveCCD(item).then(
          () => this.updateData(),
          this.GtUtils.errorClb,
        );
      }

      saveAll() {
        const listToSave = this.dataList.map((ccd: any) =>
          this.CustomsCargoDeclarationService.updateCcdFieldsModel(ccd, {}),
        );
        return this.CustomsCargoDeclarationService.bulkCreateOrUpdate(listToSave).then(() => {
          this.dataList = [];
          this.updateData();
          notify(this.gettext('Custom declarations saved'));
        }, this.GtUtils.errorClb);
      }

      destroy(item: any) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return false;
        }
        return this.CustomsCargoDeclarationService.deleteCCD(item.id).then(
          () => this.updateData(),
          this.GtUtils.errorClb,
        );
      }

      openModal(declaration: any) {
        declaration = declaration || this.newDeclaration;

        if (declaration && this.ccdCreationParams) {
          declaration.customs_broker = this.ccdCreationParams.customs_broker;
          declaration.destination_of_crop = this.ccdCreationParams.destination_of_crop;
        }

        this.CustomsCargoDeclarationService.openModal(declaration).then((data: any) => {
          if (declaration) {
            declaration.export_contract = this.initQueryParams?.export_contract;
            if (this.invoiceCreatingModal && data !== 'close') {
              const index = this.dataList.indexOf(declaration);
              if (index !== -1) {
                this.dataList.splice(index, 1);
              }
            }
          }
          this.updateData();
        });
      }
    },
  ],
};
