import type ng from 'angular';
import type { IFieldArray } from 'AngularFormly';

import { errorHandler } from '~/shared/lib/errors';
import { notify } from '~/shared/lib/notify';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractFormFieldsService } from '^/app/deals/contracts/contract-form-fields.service';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export class ContractModalController implements ng.IController {
  form: any;
  updating = true;
  fields: IFieldArray = [];
  tab = 'edit';
  deleteContractPermission = 'delete_contractbase';
  stayAfterSave = false;
  userChangedTolerance = false;
  measurementPriceMutex = false;
  initMeasurementPrice = false;
  initMeasurementPriceKg = false;
  fieldsTemplateOptions: any = undefined;
  savedFiles: any = [];
  tempId = 1;
  customValues: any = undefined;
  contractChargesFilterLevel = '';

  static readonly $inject = [
    '$scope',
    '$rootScope',
    '$uibModalInstance',
    '$q',
    'ContractsService',
    'AccountsService',
    'CropsService',
    'FinancesService',
    'LogisticsService',
    'CoreService',
    'contract',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'ContractFormFieldsService',
    'DocumentsService',
    '$state',
    'priceWidgetFields',
    '$timeout',
    'extraArgs',
    'CustomValuesService',
    'StockExchangesService',
  ];
  constructor(
    private readonly $scope: ng.IScope,
    private readonly $rootScope: GtRootScopeService,
    private readonly $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    private readonly $q: ng.IQService,
    private readonly ContractsService: ContractsService,
    private readonly AccountsService: AccountsService,
    private readonly CropsService: any,
    private readonly FinancesService: FinancesService,
    private readonly LogisticsService: LogisticsService,
    private readonly CoreService: CoreService,
    private contract: any,
    private readonly GtUtils: GtUtilsService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly FormFieldParamsService: FormFieldParamsService,
    private readonly ContractFormFieldsService: ContractFormFieldsService,
    private readonly DocumentsService: any,
    private readonly $state: ng.ui.IStateService,
    private priceWidgetFields: any,
    private readonly $timeout: ng.ITimeoutService,
    private readonly extraArgs: any,
    private readonly CustomValuesService: CustomValuesService,
    private readonly StockExchangesService: any,
  ) {
    this.contract = this.contract || { deal_type: 'spot' };
    this.extraArgs = this.extraArgs || {};

    this.activate();
  }
  setPriceWoVat = () => {
    if (this.contract.price && this.contract.VAT_option) {
      this.FinancesService.applyVat({
        value: this.contract.price,
        volume: 1,
        vat_value: this.contract.VAT_value,
        vat_type: 'without',
      })
        .then((value) => {
          this.contract._priceWoVat = value;
          return this.FinancesService.applyVat({
            value: this.contract.price,
            volume: 1,
            vat_value: this.contract.VAT_value,
            vat_type: 'vat',
          });
        })
        .then((value) => {
          this.contract._priceVatValue = value;
        })
        .catch(errorHandler);
    }
  };

  onCurrencyChange = () => {
    this.updateContractNumber();
    this.ContractsService.setCurrencyOwner(this.contract);
    this.ContractsService.setCurrencyExchange(this.contract);
  };

  setApprovalToNull = () => {
    this.ContractsService.setApprovalToNull(this.contract);
  };

  onGeneralAgreementChange = () => {
    this.updateContractNumber();
    this.ContractsService.getGeneralAgreement(this.contract);
  };

  setPriceByMeasurementPriceKg = () => {
    const {
      measurement,
      cargo,
      quantity,
      measurement_price_kg: measurementPriceKg,
    } = this.contract;
    if (!this.initMeasurementPriceKg && this.contract.contract_number) {
      this.initMeasurementPriceKg = true;
      return null;
    }
    if ([measurement, cargo, quantity, measurementPriceKg].some((value) => !value)) {
      return null;
    }

    this.measurementPriceMutex = true;

    this.StockExchangesService.getVolumeByMeasurementQuantity(
      this.contract.measurement,
      this.contract.cargo,
      this.contract.quantity,
    )
      .then((volumeNew: any) => {
        this.contract.volume = volumeNew;
        this.StockExchangesService.getPriceByMeasurementPriceKg(
          this.contract.measurement,
          this.contract.cargo,
          measurementPriceKg,
          this.contract.volume,
          this.contract.quantity,
        ).then(({ measurementPrice, price }: any) => {
          this.contract.price = price;
          this.contract.measurement_price = measurementPrice;
        });
      })
      .catch((error: any) => {
        console.error('Error in setPriceByMeasurementPriceKg:', error);
      });
  };

  setPriceByMeasurementPrice = () => {
    if (!this.initMeasurementPrice && this.contract.contract_number) {
      this.initMeasurementPrice = true;
      return null;
    }
    if (this.measurementPriceMutex) {
      this.measurementPriceMutex = false;
      return null;
    }
    const { measurement, cargo, quantity, measurement_price: measurementPrice } = this.contract;

    if ([measurement, cargo, quantity, measurementPrice].some((value) => !value)) {
      return null;
    }

    const resetPriceKg = this.initMeasurementPrice;

    this.StockExchangesService.getVolumeByMeasurementQuantity(
      this.contract.measurement,
      this.contract.cargo,
      this.contract.quantity,
    )
      .then((volumeNew: any) => {
        this.contract.volume = volumeNew;
        this.StockExchangesService.getPriceByMeasurementPrice(
          this.contract.measurement,
          this.contract.cargo,
          measurementPrice,
        ).then((priceNew: any) => {
          this.contract.price = priceNew;
          if (resetPriceKg) {
            this.contract.measurement_price_kg = null;
          }
        });
      })
      .catch((error: any) => {
        console.error('Error in setPriceByMeasurementPrice:', error);
      });
  };

  getContractOptionType = () => {
    this.ContractsService.ContractOptions.get({ id: this.contract.contract_option }).$promise.then(
      (data: any) => {
        this.contract.contract_option_program_type = data.program_type;
      },
    );
  };

  getPremiumBonusPercentage = () => {
    this.LogisticsService.Basis.get({ id: this.contract.basis }).$promise.then((basis: any) => {
      this.contract.premium_bonus_percentage = basis.premium_bonus_percentage;
    });
  };

  setContractPricesCargo = () => {
    this.$scope.$broadcast('contract-price-cargo__updated', this.contract.cargo);
  };

  updateUahRate = () => {
    return this.FinancesService.CurrencyExchange.query({
      currency: this.GtUtils.getLocalCurrency(),
    }).$promise;
  };

  updateContractNumber = () => {
    this.ContractsService.getContractNumber(this.contract)
      .then((data: any) => {
        this.contract.contract_number = data.contract_number;
      })
      .catch(this._error);
  };

  setResponsibleWorkStatus = () => {
    this.AccountsService.User.get(this.contract.responsible)
      .then((userDetails) => {
        this.contract.responsibleWorkStatus = userDetails.profile.work_status;
      })
      .catch(this.GtUtils.errorClb);
  };

  setResponsibleForSigningWorkStatus = () => {
    this.AccountsService.User.get(this.contract.responsible_for_signing)
      .then((userDetails) => {
        this.contract.responsibleForSigningWorkStatus = userDetails.profile.work_status;
      })
      .catch(this.GtUtils.errorClb);
  };

  getToleranceForVolume = () => {
    if (!this.contract.volume || this.userChangedTolerance) {
      return;
    }

    this.CoreService.getRangeRuleValue('contract.tolerance', this.contract.volume)
      .then((data: any) => {
        if (data.value && !this.userChangedTolerance) {
          this.contract.volume_options = data.value;
        }
      })
      .catch((error: any) => this._error(error));
  };

  getVatReturnDefaultRate = () => {
    if (this.contract.VAT_option) {
      return this.CropsService.Crop.get({ id: this.contract.cargo }).$promise.then((crop: any) => {
        if (!this.contract.vat_return_rate && this.contract.VAT_option) {
          this.contract.vat_return_rate = crop.default_vat_return;
        }
      });
    } else {
      this.contract.vat_return_rate = 0;
    }
  };

  updateData = () => {
    this.updating = true;
    return this.ContractsService.Contract.query({
      id: this.contract.id,
      serializer: 'modal',
    })
      .$promise.then((data: any) => {
        this.contract = data;
        this.contract._show_contractprice = Boolean(this.contract.contract_prices?.length);
        this.contract._show_derivatives = Boolean(this.contract.derivatives?.length);
        this.contract._show_contract_consignees = Boolean(
          this.contract.contract_consignees?.length,
        );
      })
      .then(() => {
        const { content_type: contentType, id: objectId } = this.contract;
        return this.DocumentsService.queryDocuments({
          content_type: contentType,
          object_id: objectId,
        });
      })
      .then(({ results }: any) => (this.contract.documents = results))
      .then(() => {
        this.priceRounding();
        this.updateRequiredBasises();
        this.getBasises();
        this.getFormConfig();
      })
      .catch(this.GtUtils.errorClb)
      .finally(() => (this.updating = false));
  };

  priceRounding = () => {
    if (this.contract._show_contractprice || this.contract._show_derivatives) {
      this.contract.price = Math.round(this.contract.price * 100) / 100;
    }
  };

  updateRequiredBasises = () => {
    return this.LogisticsService.Basis.query(
      {
        codename_list: ['FCA', 'CPT', 'DAT', 'DAP'],
      },
      (data: any) => {
        this.contract._basis_required_terminal = data.results.map((basis: any) => basis.id);
      },
    );
  };

  openFieldsConfigModal = () => {
    this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(this.updateFields);
  };

  seContracttMainLoyaltyProgram() {
    if (
      this.$rootScope.user.settings.CONTRACT_OPTION_MODEL &&
      this.contract.contract_type === 'purchase' &&
      !this.contract.contract_option
    ) {
      this.ContractsService.getMainLoyaltyProgram().then((programId: number) => {
        this.contract.contract_option = programId;
      });
    }
  }

  updateFields = () => {
    this.updating = true;
    return this.updatePriceWidgetFields().then(() => {
      return this.FormFieldParamsService.getFields(
        this.getFormConfig(),
        this.contract.business_unit,
      )
        .then((fields) => {
          if (!this.fields.length) {
            this.fields = fields;
          } else {
            const oldLength = this.fields.length;
            this.fields.push(...fields);
            this.$timeout(() => {
              this.fields.splice(0, oldLength);
            }, 100);
            this.$timeout(() => (this.updating = false), 4000);
          }
          this.fieldsTemplateOptions = this.getFlatFields(fields);
        })
        .finally(() => (this.updating = false));
    });
  };

  updatePriceWidgetFields = () => {
    const dealType = this.contract.deal_type || '';
    const hyphen = dealType ? '-' : '';
    return this.FormFieldParamsService.getContractPriceWidgetFields(
      dealType + hyphen + 'contract-edit-modal',
      this.contract.business_unit,
    ).then((fields: any) => {
      this.priceWidgetFields = fields;
    });
  };

  getFlatFields = (fields: any) => {
    return this.FormFieldParamsService.getFlatFields({ fieldsDef: fields }).map((field: any) => {
      const res = field.templateOptions;
      res.key = field.key;
      return res;
    });
  };

  getPrefix = () => {
    const prefix: any = [this.contract.contract_type || '', this.contract.deal_type || '']
      .filter((v) => Boolean(v))
      .join('-');
    return `${prefix}${prefix ? '-' : ''}`;
  };

  getFormConfig = () => {
    const widgetFieldsConfig: any = {};
    this.priceWidgetFields.forEach((field: any) => {
      widgetFieldsConfig[field.field_name] = {
        visible: field.visible,
        required: field.required,
      };
    });
    this.contract.onFileSelect = this.onFileSelect;
    return this.ContractFormFieldsService.getFields(
      this.contract,
      `${this.getPrefix()}contract-edit-modal`,
      widgetFieldsConfig,
    );
  };

  destroy = () => {
    const resource = this.ContractsService.getContractResource(this.contract);
    this.CoreService.confirmDeletionModal(resource, this.contract.id).then((data: any) => {
      if (data == 'delete') {
        this.close('delete', true);
      }
    });
  };

  save = () => {
    const resource = this.ContractsService.getContractResource(this.contract);

    if (this.contract.text_loading_address) {
      this.contract.text_loading_address = null;
    }
    if (this.contract.contract_prices == null) {
      delete this.contract.contract_prices;
    }
    if (this.contract.contract_type == 'sale') {
      this.contract.owner = this.contract.supplier;
    }

    if (this.contract.contract_type == 'purchase') {
      this.contract.owner = this.contract.buyer;
    }

    this.updating = true;
    this.userChangedTolerance = false;

    return this.contract.id ? this.updateContract(resource) : this.createContract(resource);
  };

  updateContract = (resource: any) => {
    this.GtUtils.overlay('show');
    resource
      .update(this.contract)
      .$promise.then((contract: any) => {
        this.updateDocuments(contract);
        this._success(contract);
        this.userChangedTolerance = false;
      })
      .catch((error: any) => this._error(error))
      .finally(() => this.GtUtils.overlay('hide'));
  };

  createContract = (resource: any) => {
    this.GtUtils.overlay('show');
    resource
      .save(this.contract)
      .$promise.then((contract: any) => {
        this.updateDocuments(contract);
        this._success(contract);
        this.userChangedTolerance = false;
        this.CustomValuesService.createCustomValues(contract.id, this.customValues);
      })
      .catch((error: any) => this._error(error))
      .finally(() => this.GtUtils.overlay('hide'));
  };

  updateDocuments = (contract: any) => {
    return this.ContractsService.Contract.query({ id: contract.id, serializer: 'modal' })
      .$promise.then((data: any) => (contract.content_type = data.content_type))
      .then(() => {
        this.DocumentsService.saveDocuments(
          this.savedFiles.map(({ tempId, file }: any) => {
            const documentOptions = this.contract.documents.filter(
              (doc: any) => doc.tempId === tempId,
            );
            if (documentOptions.length) {
              file.basis_doc = documentOptions[0].basis_doc;
            }
            return file;
          }),
          contract.id,
          contract.content_type,
        );

        return contract;
      });
  };

  _success = (data: any) => {
    notify(this.gettext('Contract saved'));
    if (this.stayAfterSave) {
      this.contract = { id: data.id };
      this.updateData();
      this.close(data, true);
      this.$state.go('gt.page.contract', { id: this.contract.id });
    } else {
      this.close(data, true);
    }
  };

  _error = (error: any) => {
    this.GtUtils.errorClb(error);
    this.contract.errors = error.data;
  };

  close = (data: any, silent: any) => {
    if (!silent && !confirm(this.gettext('Close modal?'))) {
      return false;
    }

    if (this.extraArgs.openedFromPassportModal) {
      this.$rootScope.$broadcast('closePassportModal');
    }

    this.$uibModalInstance.close(data || 'cancel');
  };

  getBasises = () => {
    this.LogisticsService.Basis.query({ fields: ['id', 'codename'] }, (data: any) => {
      this.contract._basises = data.results
        .filter((basis: any) => {
          return ['FAS', 'FOB', 'CIF', 'CFR', 'CIP'].includes(basis.codename.toUpperCase());
        })
        .map((basis: any) => {
          return basis.id;
        });
    });
  };

  onFileSelect = ([file]: any) => {
    const { name, type } = file;
    this.savedFiles.push({ tempId: this.tempId, file });
    this.contract.documents.push({
      tempId: this.tempId,
      name,
      type,
      class: this.DocumentsService.getFileIcoClass(name),
    });
    this.tempId++;
  };

  removeDocument = (event: any, fileToRemove: any) => {
    if (fileToRemove.tempId) {
      this.savedFiles = this.savedFiles.filter(({ tempId }: any) => tempId !== fileToRemove.tempId);
      this.contract.documents = this.contract.documents.filter(
        ({ tempId }: any) => tempId !== fileToRemove.tempId,
      );
    } else {
      this.CoreService.confirmDeletionModal(this.DocumentsService.Document, fileToRemove.id)
        .then((data: any) => {
          if (data === 'delete') {
            notify(this.gettext('Doc removed'));
            this.contract.documents = this.contract.documents.filter(
              ({ id }: any) => id !== fileToRemove.id,
            );
          }
        })
        .catch((error: any) => {
          this.GtUtils.errorClb(error);
          this.contract.documents.push(fileToRemove);
        });
    }
  };

  clearApprovalConfig = () => {
    this.contract.approval_config = null;
  };

  private activate = () => {
    let when;
    this.contract.documents = [];

    if (!this.contract.deal_type) {
      this.contract.deal_type = 'spot';
    }

    this.deleteContractPermission =
      this.contract.deal_type === 'services' ? 'delete_servicescontract' : 'delete_contractbase';

    this.$rootScope.$on('contract-edit_deal-type-updated', () => {
      this.updateFields();
    });
    this.$scope.$on('custom-values-updated__contractbase', (event: any, data: any) => {
      this.customValues = data;
    });

    if (this.contract.id) {
      when = this.updateData();
    } else {
      if (
        this.$rootScope.user.settings.USE_LOCAL_CURRENCY_FOR_PURCHASE &&
        this.contract.contract_type == 'purchase' &&
        !this.contract.currency
      ) {
        this.updateUahRate().then((data: any) => {
          this.contract.currency_exchange = data.count && data.results[0].id;
          this.contract.currency = data.count && data.results[0].local_currency;
        });
      }
      this.seContracttMainLoyaltyProgram();
      this.getBasises();
      this.updateRequiredBasises();
      this.updating = false;

      this.$scope.$watch('vm.contract.cargo', () => {
        this.ContractsService.setVatValue(this.contract);
        this.updateContractNumber();
      });

      this.$scope.$watch('vm.contract.contract_type', (newVal: any, oldVal: any) => {
        this.updateContractNumber();
        if (newVal !== oldVal) {
          this.updateFields();
          this.seContracttMainLoyaltyProgram();
        }
      });
      this.$scope.$watch(
        'vm.contract.responsible',
        (newVal: number | undefined, oldVal: number | undefined) => {
          if (newVal && newVal !== oldVal) {
            this.updateContractNumber();
            this.setResponsibleWorkStatus();
          }
        },
      );
      this.$scope.$watch(
        'vm.contract.responsible_for_signing',
        (newVal: number | undefined, oldVal: number | undefined) => {
          if (newVal && newVal !== oldVal) {
            this.setResponsibleForSigningWorkStatus();
          }
        },
      );
      this.$scope.$watch('vm.contract.general_agreement', this.onGeneralAgreementChange);
      this.$scope.$watchGroup(
        ['vm.contract.conclusion_date', 'vm.contract.auto_name'],
        this.updateContractNumber,
      );
      this.$scope.$watch('vm.contract.currency', this.onCurrencyChange);
      this.$scope.$watch('vm.contract.is_position_closing', this.setApprovalToNull);
      this.$scope.$watch('vm.contract.VAT_option', () => {
        this.ContractsService.setVatValue(this.contract);
        this.getVatReturnDefaultRate();
      });
      this.$scope.$watch('vm.contract.business_unit', (newVal: any, oldVal: any) => {
        if (newVal !== oldVal) {
          this.updateFields();
          this.clearApprovalConfig();
        }
      });
      when = this.$q.when();
    }

    this.$scope.$watch('vm.contract.tolerance', (newValue: any, oldValue: any) => {
      if (newValue !== oldValue) {
        this.userChangedTolerance = true;
      }
    });

    this.$scope.$watch('vm.contract.price', this.setPriceWoVat);
    this.$scope.$watch('vm.contract.VAT_option', this.setPriceWoVat);
    this.$scope.$watch('vm.contract.VAT_value', this.setPriceWoVat);
    this.$scope.$watch('vm.contract.volume', this.getToleranceForVolume);

    when.then(() => {
      this.$scope.$watchGroup(
        [
          'vm.contract.measurement',
          'vm.contract.cargo',
          'vm.contract.quantity',
          'vm.contract.measurement_price',
        ],
        () => {
          this.setPriceByMeasurementPrice();
          this.initMeasurementPrice = true;
        },
      );
      this.$scope.$watchGroup(
        [
          'vm.contract.measurement',
          'vm.contract.cargo',
          'vm.contract.quantity',
          'vm.contract.measurement_price_kg',
        ],
        () => {
          this.setPriceByMeasurementPriceKg();
          this.initMeasurementPriceKg = true;
        },
      );
      this.$scope.$watch('vm.contract.cargo', this.setContractPricesCargo);
      this.$scope.$watch('vm.contract.contract_option', this.getContractOptionType);
      this.$scope.$watch('vm.contract.basis', this.getPremiumBonusPercentage);
      this.$scope.$watch('vm.contract.deal_type', () => {
        this.contractChargesFilterLevel =
          this.contract.deal_type !== 'services'
            ? 'contracts-contract-charges-list-table'
            : 'service-contracts-contract-charges-list-table';
      });

      this.ContractsService.getConclusionDateRange(this.contract).then((dataRange: any) => {
        this.contract.conclusionDateRange = dataRange;
        return this.updateFields();
      });
    });

    this.$rootScope.$on('documents-list__remove-from-contract-modal', this.removeDocument);
  };
}
