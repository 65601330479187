import template from './warehouse-balance-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const WarehouseBalancePage = {
  template,
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  controller: [
    'PageService',
    'LogisticsService',
    'gettext',
    class {
      LogisticsService: LogisticsService;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      constructor(
        PageService: PageService,
        LogisticsService: LogisticsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.LogisticsService = LogisticsService;
        this.gettext = gettext;
        this.filterLevel = 'warehouse-balance-page';
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'warehouse-balance-page';
        this.PageService.setConfig(this.getPageConfig());
      }
      getPageConfig() {
        const config: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          pages: this.LogisticsService.getPagesConfig(),
        };

        config.filters = {
          groupBy: {},
          filterLevel: this.filterLevel,
          clicked: false,
          search: true,
          dates: true,
          useFlexDateRange: true,
          invert_filters: true,
          dateSelects: [],
          selects: [],
          nestedMultiSelects: [
            {
              argument: 'warehouse_type_list',
              placeholder: this.gettext('Warehouse type'),
              items: [
                { id: 'warehouse', title: this.gettext('Warehouse') },
                { id: 'farm', title: this.gettext('Farm') },
                { id: 'elevator', title: this.gettext('Elevator') },
                { id: 'terminal', title: this.gettext('Terminal') },
                { id: 'other', title: this.gettext('Other') },
              ],
            },
          ],
          multiSelects: [
            {
              argument: 'commodity',
              placeholder: this.gettext('Commodity'),
              resource: 'crops.Crop',
            },
            {
              argument: 'business_unit_list',
              placeholder: this.gettext('Business unit'),
              resource: 'core.businessunit',
            },
            {
              argument: 'client_list',
              placeholder: this.gettext('Warehouse owner'),
              resource: 'clients.Client',
              related_fk: 'client',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'warehouse',
              placeholder: this.gettext('Warehouse'),
              resource: 'logistics.warehouse',
              related_fk: 'id',
              related_model: 'logistics.warehouse',
              queryParams: () => ({ is_group: '0' }),
            },
            {
              argument: 'contract_position',
              placeholder: this.gettext('Contract Position'),
              resource: 'contracts.purchasecontract',
            },
            {
              argument: 'sale_contract_position',
              placeholder: this.gettext('Sale Contract Position'),
              resource: 'contracts.salecontract',
            },
            {
              argument: 'contract',
              placeholder: this.gettext('Contract'),
              queryParams: () => ({ contract_type: 'purchase' }),
              resource: 'contracts.multicontract',
            },
            {
              argument: 'charge',
              placeholder: this.gettext('Charge'),
              resource: 'finances.charge',
            },
            {
              argument: 'warehouse_parent',
              placeholder: this.gettext('Warehouse parent'),
              resource: 'logistics.warehouse',
              related_fk: 'parent',
              related_model: 'logistics.warehouse',
              queryParams: () => ({ is_group: '1' }),
            },
            {
              argument: 'responsible_list',
              placeholder: this.gettext('responsible'),
              resource: 'clients.Person',
              related_fk: 'responsible',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'country_list',
              placeholder: this.gettext('Country'),
              resource: 'location.country',
              related_fk: 'address__country',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'region_list',
              placeholder: this.gettext('Region'),
              resource: 'location.region',
              related_fk: 'address__region',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'district_list',
              placeholder: this.gettext('District'),
              resource: 'location.district',
              related_fk: 'address__district',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'city_list',
              placeholder: this.gettext('city'),
              resource: 'location.city',
              related_fk: 'address__city',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'terminal_list',
              placeholder: this.gettext('Terminal'),
              resource: 'logistics.Terminal',
              related_fk: 'terminal',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'owner',
              placeholder: this.gettext('Batch Owner'),
              resource: 'clients.Client',
              related_fk: 'owner',
              related_model: 'warehouses.WarehouseCommodityRecord',
            },
            {
              argument: 'producer',
              placeholder: this.gettext('Producer'),
              resource: 'clients.Client',
              related_fk: 'producer',
              related_model: 'warehouses.WarehouseCommodityRecord',
            },
            {
              argument: 'receiver',
              placeholder: this.gettext('Receiver'),
              resource: 'clients.Client',
              related_fk: 'receiver',
              related_model: 'warehouses.WarehouseCommodityRecord',
            },
            {
              argument: 'port_list',
              placeholder: this.gettext('Port'),
              resource: 'logistics.port',
              related_fk: 'terminal__port_id',
              related_model: 'logistics.warehouse',
            },
            {
              argument: 'stock_reserve_unit',
              placeholder: this.gettext('Stock Reserve Unit'),
              resource: 'logistics.stockReserveUnit',
            },
            {
              argument: 'reserve_passport',
              placeholder: this.gettext('Reserve Passport'),
              resource: 'passports.Passport',
              related_fk: 'passport_id',
              related_model: 'warehouses.StockReserveUnit',
            },
            {
              argument: 'reserve_client',
              placeholder: this.gettext('Reserve Client'),
              resource: 'clients.Client',
              related_fk: 'client_id',
              related_model: 'warehouses.StockReserveUnit',
            },
            {
              argument: 'reserve_contract',
              placeholder: this.gettext('Reserve Contract'),
              resource: 'contracts.salecontract',
              related_fk: 'contract_position_id',
              related_model: 'warehouses.StockReserveUnit',
            },
            {
              argument: 'reserve_voyage',
              placeholder: this.gettext('Reserve Voyage'),
              resource: 'logistics.Voyage',
              related_fk: 'passport__voyage_id',
              related_model: 'warehouses.StockReserveUnit',
            },
            {
              argument: 'reserve_vessel',
              placeholder: this.gettext('Reserve Vessel'),
              resource: 'logistics.Vessel',
              related_fk: 'passport__voyage__vessel_id',
              related_model: 'warehouses.StockReserveUnit',
            },
            {
              argument: 'batch_voyage',
              placeholder: this.gettext('Batch Voyage'),
              resource: 'logistics.Voyage',
            },
            {
              argument: 'batch_vessel',
              placeholder: this.gettext('Batch Vessel'),
              resource: 'logistics.Vessel',
            },
          ],
        };
        return config;
      }
    },
  ],
};
