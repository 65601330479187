import { financesChargesPredictionsRetrieve } from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository, Paginated } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Charge, ChargeListParams } from '../lib';

@singleton()
export class ChargeRepository extends EntityRepository<Charge, ChargeListParams> {
  readonly entityName = 'finances.Charge';

  getFn = (_id: number): Promise<Charge> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: Charge): Promise<Charge> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Charge>): Promise<Charge> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_query: ChargeListParams): Promise<Paginated<Charge>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await financesChargesPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
}
