import type ng from 'angular';

import type { ContractsService } from './legacy/contracts.srv';
export class AdditionalAgreementsService {
  $resource: ng.resource.IResourceService;
  AdditionalAgreement: any;
  ContractsService: ContractsService;
  constructor($resource: ng.resource.IResourceService, ContractsService: ContractsService) {
    this.ContractsService = ContractsService;
    this.$resource = $resource;
    this.AdditionalAgreement = $resource(
      '/api/contracts/additional-agreements/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        delete: { method: 'DELETE' },
      },
    );
  }

  getContract(id: number) {
    return this.ContractsService.Contract.query({
      id,
      serializer: 'modal',
    }).$promise;
  }

  applyAgreementToContract(contract: any, additionalAgreement: any, contractPriceToUpdate: any) {
    const resource = this.ContractsService.getContractResource(contract);
    const volumeDecimalPlaces = 6;
    if (!additionalAgreement._update_contract_prices) {
      return Promise.resolve();
    }
    if (!contract.contract_prices?.length) {
      const contractVolume = contract.volume;
      contract.volume = (contract.volume - additionalAgreement.volume).toFixed(volumeDecimalPlaces);
      contract.contract_prices = [
        this.getContractPrice(
          contract,
          (contractVolume - additionalAgreement.volume).toFixed(volumeDecimalPlaces),
        ),
        this.getContractPrice(additionalAgreement),
      ];
    } else if (!additionalAgreement._update_full_contract_price) {
      contract.contract_prices = [
        ...contract.contract_prices.filter((price: any) => price.id !== contractPriceToUpdate.id),
        {
          ...contractPriceToUpdate,
          volume: (contractPriceToUpdate.volume - additionalAgreement.volume).toFixed(
            volumeDecimalPlaces,
          ),
          port: additionalAgreement.port || contractPriceToUpdate.port,
        },
        {
          ...this.getContractPrice(additionalAgreement),
          port: additionalAgreement.port || undefined,
        },
      ];
    } else {
      contractPriceToUpdate = {
        ...contractPriceToUpdate,
        ...this.getContractPrice(additionalAgreement),
        port: additionalAgreement.port || contractPriceToUpdate.port,
      };
      contract.contract_prices = [
        ...contract.contract_prices.filter((price: any) => price.id !== contractPriceToUpdate.id),
        contractPriceToUpdate,
      ];
    }
    contract.contract_prices.forEach((price: any) => {
      for (const key in price) {
        if (price[key] === null) {
          delete price[key];
        }
      }
    });
    return resource.update(contract).$promise;
  }

  getContractPrice(agreement: any, volume?: any) {
    const contractId = agreement?.contract || agreement.id;
    return {
      contract: contractId,
      price: agreement.price,
      basis: agreement.basis,
      port: agreement.ports?.[0],
      cargo: agreement.cargo,
      volume: volume || agreement.volume,
      date_of_execution: agreement.date_of_execution,
      end_of_execution: agreement.end_of_execution,
      additional_info: agreement.additional_info,
      is_active: true,
    };
  }
}

AdditionalAgreementsService.$inject = ['$resource', 'ContractsService'];
