import React from 'react';

import { cn, genId } from '~/shared/lib/utils';
import { SearchCommandList } from '~/shared/ui/components/search-command-list';
import { BucketIcon, ThreeDotsIcon, useIcon } from '~/shared/ui/icons';
import { CommandItem } from '~/shared/ui/kit/command';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import type { Preset } from '../../types';
import { FilterButton } from '../filter-button';
import { MenuItem } from './menu-item';
import { PresetForm } from './preset-form';

const ListItem: React.FC<{
  selectedPresetId: Preset['id'];
  item: Preset;
  onSelect: (preset: Preset) => void;
  onDelete: (preset: Preset) => void;
}> = ({ item, selectedPresetId, onSelect, onDelete }) => {
  const Icon = useIcon(item.icon);

  return (
    <CommandItem
      className={cn(
        'hover:bg-transparent-light flex cursor-pointer items-center justify-between px-2 py-0.5 transition duration-300 ease-in-out',
        selectedPresetId === item.id && 'bg-transparent-light',
      )}
      onSelect={() => onSelect(item)}
    >
      <div className="flex items-center gap-2">
        <Icon size={14} className="text-text-main-secondary" />
        <p className="text-text-main-primary text-xs">{item.title}</p>
      </div>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger displayChevron={false}>
          <ThreeDotsIcon size={14} className="text-text-main-secondary" />
        </DropdownMenuSubTrigger>
        <DropdownMenuSubContent className="p-1" sideOffset={14}>
          <button className="flex items-center gap-2 rounded-sm p-1" onClick={() => onDelete(item)}>
            <BucketIcon size={14} className="text-text-additional-danger" />
            <p className="text-text-additional-danger text-xs">Delete Preset</p>
          </button>
        </DropdownMenuSubContent>
      </DropdownMenuSub>
    </CommandItem>
  );
};

export const PresetSelector: React.FC<{
  preset: Preset;
  presetOptions: Preset[];
  onSelect: (preset: Preset) => void;
  onSave: (preset: Preset) => void;
  onDelete: (preset: Preset) => void;
}> = ({ preset, presetOptions, onSelect, onDelete, onSave }) => {
  const createHandler = React.useCallback(
    (preset: Preset) => onSave({ ...preset, id: -genId() }),
    [onSave],
  );

  const Icon = useIcon(preset.icon);

  return (
    <div className="flex items-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <FilterButton intent={preset.id > 0 ? 'default' : 'empty'} rounded="left">
            <div className="flex items-center gap-1">
              <Icon
                size={14}
                className={preset.id > 0 ? 'text-text-main-primary' : 'text-text-main-secondary'}
              />
              <p
                className={cn(
                  'text-xs font-medium',
                  preset.id > 0 ? 'text-text-main-primary' : 'text-text-main-secondary',
                )}
              >
                {preset?.title || 'Presets'}
              </p>
            </div>
          </FilterButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="p-0">
          <SearchCommandList
            options={presetOptions.map((item) => (
              <ListItem
                key={item.id}
                selectedPresetId={preset.id}
                item={item}
                onSelect={onSelect}
                onDelete={onDelete}
              />
            ))}
          />
        </DropdownMenuContent>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <FilterButton intent={preset.id > 0 ? 'default' : 'empty'} rounded="right">
            <ThreeDotsIcon
              size={14}
              className={preset.id > 0 ? 'text-text-main-primary' : 'text-text-main-secondary'}
            />
          </FilterButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="flex w-[180px] flex-col gap-0.5 p-1">
          <MenuItem label="Save as New Preset" title="New Preset">
            <PresetForm preset={preset} onSave={createHandler} />
          </MenuItem>
          {preset.id > 0 && (
            <MenuItem label="Update Preset" title={preset.title}>
              <PresetForm preset={preset} onSave={onSave} />
            </MenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
