import ng from 'angular';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').controller('ClientUpdateModalController', Controller);

  Controller.$inject = ['$uibModalInstance', 'id'];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    id: number,
  ) {
    const vm = this;

    vm.close = close;
    vm.filterLevel = 'client-update-modal';
    vm.queryParams = {
      contract: id,
    };
    vm.newUpdate = {
      message_type: 'call',
      contract: id,
      update: '',
    };

    ////////////////

    function close() {
      $uibModalInstance.close('cancel');
    }
  }
})();
