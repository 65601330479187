import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import {
  FinancePosition,
  FinancePositionListParams,
  FinancePositionPatch,
  FinancePositionPost,
  FinancePositionRecord,
  calculateAmount,
} from '~/features/finances/finance-position/lib';
import { FinancePositionRepository } from '~/features/finances/finance-position/services';
import { injectable } from '~/shared/lib/di';

export type FinancePositionListContainerProps = ContainerProps<FinancePositionListParams>;

@injectable()
export class FinancePositionListModel extends EntityListContainerModel<
  FinancePosition,
  FinancePositionListParams,
  FinancePositionRepository,
  FinancePositionListContainerProps,
  FinancePositionRecord,
  FinancePositionPost,
  FinancePositionPatch
> {
  entityRepoClass = FinancePositionRepository;

  createNewRecord = () => {
    if (!this.pageParamsSubj.value.invoice) {
      throw new Error('Invoice is not defined');
    }
    const invoiceId = parseInt(this.pageParamsSubj.value.invoice, 10);
    return Promise.resolve({
      ...this.repo.buildNewRecord(),
      invoice: invoiceId,
      invoice_id: invoiceId,
    });
  };
  beforeSaveRecords = (records: FinancePositionRecord[]) =>
    Promise.resolve(records.map((record) => calculateAmount(record)));
}
