import type ng from 'angular';

import { endOfYear, formatDate, startOfYear, substractDate } from '~/shared/lib/date';

import template from './invoice-risk-report-page-view.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const InvoiceRiskReportPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gtFilterService',
    'PageService',
    'GtUtils',
    'ReportsService',
    'gettext',
    class InvoiceRiskReportPageView {
      GtUtils: GtUtilsService;
      PageService: PageService;
      ReportsService: ReportsService;
      filterLevel = 'invoice-risk-report-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      constructor(
        gtFilterService: GtFilterService,
        PageService: PageService,
        GtUtils: GtUtilsService,
        ReportsService: ReportsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.GtUtils = GtUtils;
        this.ReportsService = ReportsService;
        this.gettext = gettext;
        this.queryParams = {};
      }

      $onInit() {
        this.queryParams = {
          start_date: formatDate(
            startOfYear(substractDate(new Date(), { years: 1 })),
            'dd.MM.yyyy',
          ),
          end_date: formatDate(endOfYear(new Date()), 'dd.MM.yyyy'),
          predicate: 'invoice_date',
          position_use_list: ['cargo'],
          group_by: 'vessel_name',
          has_balance: 1,
          currency_list: [1, 3],
        };
        this.filterLevel = this.filterLevel || 'invoice-risk-report-page-view';
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
        };
      }
    },
  ],
};
