import ng from 'angular';

import type { CoreService } from '../../core.service';
import type { GtRootScopeService } from '../../types';

export class GtTableService {
  $q: ng.IQService;
  $rootScope: GtRootScopeService;
  CoreService: CoreService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $rootScope: GtRootScopeService,
    $q: ng.IQService,
    gettext: ng.gettext.gettextFunction,
    CoreService: CoreService,
  ) {
    this.$rootScope = $rootScope;
    this.$q = $q;
    this.gettext = gettext;
    this.CoreService = CoreService;
  }

  getColumnParamsList(params: object) {
    return this.CoreService.ColumnParams.query(params).$promise;
  }

  getColumns(tableOptions: any) {
    // FIX: refactor this when all requirements will be implemented
    let columns = tableOptions.columnDefs;

    const reportConfig = tableOptions.activeReportConfig;

    // filter by active tab
    if (tableOptions.activeTab && tableOptions.tabs && !reportConfig) {
      const inTabColumns = tableOptions.tabs.filter(
        (tab: any) => tab.serializer == tableOptions.activeTab,
      )[0].columns;

      columns = columns.filter((colDef: any) => inTabColumns.indexOf(colDef.columnName) > -1);
    }

    // filter by hideExpr
    columns = columns.filter((colDef: any) => {
      if (!colDef.hideExpr) {
        return true;
      }
      if (ng.isFunction(colDef.hideExpr)) {
        return !colDef.hideExpr();
      }
      return !colDef.hideExpr;
    });

    if (!tableOptions.configurable) {
      return this.$q.when(columns);
    }

    // apply saved serverConfig

    const getParams = () => {
      return { table_name: tableOptions.tableName, has_columnssetconfig: 0 };
    };

    return this.getColumnParamsList(getParams()).then((data: any) => {
      columns = columns
        .filter((colDef: any) => {
          let colConfig;

          if (reportConfig) {
            colConfig = reportConfig.find((conf: any) => conf.column_name == colDef.columnName);
          } else {
            colConfig = data.find((conf: any) => conf.column_name == colDef.columnName);
          }

          colDef.index = colConfig?.index || 0;
          colDef.title = colConfig?.new_title || colDef.title;

          if (!colConfig && reportConfig) {
            return false;
          }

          if (!colConfig) {
            return this.$rootScope.user.settings.NEW_FIELD_VISIBILITY;
          }

          if (colDef.showAlways) {
            return true;
          }

          if (!colConfig.users.includes(this.$rootScope.user.id)) {
            return false;
          }

          return colConfig.visible;
        })
        .sort((a: any, b: any) => a.index - b.index);
      return columns;
    });
  }

  columnParamsModal(tableOptions: any) {
    // FIX: move columnParamsModal from CoreService here
    return this.CoreService.columnParamsModal(tableOptions);
  }
}
GtTableService.$inject = ['$rootScope', '$q', 'gettext', 'CoreService'];
