import { clientsElevatorsPredictionsRetrieve } from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { Elevator, ElevatorQueryParams } from '../lib/types';

export class ElevatorRepository extends EntityRepository<Elevator, ElevatorQueryParams> {
  readonly entityName = 'clients.Elevator';

  createFn = (_entity: Elevator): Promise<Elevator> => {
    throw new Error('Method not implemented.');
  };
  getFn = (_id: number): Promise<Elevator> => {
    throw new Error('Method not implemented.');
  };
  queryFn = (_params: ElevatorQueryParams): Promise<Paginated<Elevator>> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await clientsElevatorsPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
  create = (_entity: Elevator): Promise<Elevator> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<Elevator>): Promise<Elevator> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
}
