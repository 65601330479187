import template from './user-permission-container.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export const UserPermissionContainer = {
  bindings: { user: '=' },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'UserPermissionService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      UserPermissionService: any;
      apps: any;
      filterLevel: string;
      groups: any;
      selectAllAdd: any;
      selectAllChange: any;
      selectAllCustom: any;
      selectAllDelete: any;
      selectAllGroup: any;
      selectAllView: any;
      user: any;
      constructor($scope: ng.IScope, GtUtils: GtUtilsService, UserPermissionService: any) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.UserPermissionService = UserPermissionService;
        this.filterLevel = 'user-permission-container';
        this.selectAllGroup = false;
        this.selectAllAdd = false;
        this.selectAllChange = false;
        this.selectAllDelete = false;
        this.selectAllView = false;
        this.selectAllCustom = false;
      }

      $onInit() {
        this.updateData();
      }

      updateData() {
        let modelCustomPermLength = 0;

        this.GtUtils.overlay('show');
        return this.UserPermissionService.getGroupedUserPermissionData({
          user_id: this.user.id,
        }).then((data: any) => {
          this.apps = data.data;
          this.apps.forEach((app: any) => {
            app.model_set.forEach((model: any) => {
              model._selected = false;
              modelCustomPermLength = model.permission_set.filter(
                (perm: any) => perm.perm_type == 'custom',
              ).length;
              if (modelCustomPermLength) {
                model.hasCustomPerms = true;
                model.customPermLength = modelCustomPermLength;
                app.hasCustomPerms = true;
              }
            });
          });
          this.UserPermissionService.getUserGroupPermissionData({}).then((data: any) => {
            this.groups = data.results.map((group: any) => ({
              ...group,
              _selected: this.user.groups.includes(group.id),
            }));
          });
          this.GtUtils.overlay('hide');
        });
      }

      changePerms() {
        const selectedPerms: any = [];
        this.apps.forEach((app: any) => {
          app.model_set.forEach((model: any) => {
            model.permission_set
              .filter((perm: any) => perm._selected)
              .forEach((perm: any) => {
                selectedPerms.push(perm.id);
              });
          });
        });
        this.user.user_permissions = selectedPerms;
      }

      selectModel(selectedModel: any) {
        this.apps.forEach((app: any) => {
          app.model_set
            .filter((model: any) => model.name == selectedModel.name)
            .forEach((model: any) => {
              model.permission_set
                .filter(
                  (perm: any) =>
                    !perm._selected_by_group &&
                    (perm.perm_type == 'base' || perm.perm_type == 'view'),
                )
                .forEach((perm: any) => {
                  perm._selected = model._selected;
                });
            });
        });
        this.changePerms();
      }

      selectType(type: any, selectedModel: any) {
        this.apps.forEach((app: any) => {
          app.model_set.forEach((model: any) => {
            model.permission_set
              .filter(
                (perm: any) => !perm._selected_by_group && perm.codename == type + '_' + model.name,
              )
              .forEach((perm: any) => {
                perm._selected = selectedModel;
              });
          });
        });
        this.changePerms();
      }

      selectPermType(permType: any, selectedModel: any) {
        this.apps.forEach((app: any) => {
          app.model_set.forEach((model: any) => {
            model.permission_set
              .filter((perm: any) => !perm._selected_by_group && perm.perm_type == permType)
              .forEach((perm: any) => {
                perm._selected = selectedModel;
              });
          });
        });
        this.changePerms();
      }

      selectGroup(selectedModel: any) {
        this.groups.forEach((group: any) => {
          group._selected = selectedModel;
        });
        this.changePerms();
      }
    },
  ],
};
