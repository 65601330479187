import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './payment-info-modal.html?raw';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { html } from '^/shared/utils';

export const PaymentInfoModal = {
  bindings: {
    modalInstance: '<',
    paymentInfo: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'FinancesService',
    'ClientsService',
    '$window',
    class {
      $scope: ng.IScope;
      $window: ng.IWindowService;
      ClientsService: any;
      FinancesService: FinancesService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      clientRoles: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      paymentInfo: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        FinancesService: FinancesService,
        ClientsService: any,
        $window: ng.IWindowService,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.FinancesService = FinancesService;
        this.ClientsService = ClientsService;
        this.$window = $window;

        this.form = undefined;
        this.clientRoles = [];
        this.fields = [];
      }

      $onInit() {
        this.paymentInfo = this.paymentInfo || {};

        const fetchClientRoles = () => {
          return this.ClientsService.Client.get({ id: this.paymentInfo.client }).$promise;
        };

        const updateClientRoles = (data: any) => {
          this.clientRoles = data.role_names_set.map((role: any) => role.role_name);
        };

        const updateTableIfOwner = () => {
          if (this.clientRoles.includes('owner')) {
            this.updateFields();
          }
        };

        const processClientRoles = () => {
          fetchClientRoles().then((data: any) => {
            updateClientRoles(data);
            updateTableIfOwner();
          });
        };

        if (this.paymentInfo.client) {
          processClientRoles();
        }

        this.updateFields();

        this.$scope.$watch('$ctrl.paymentInfo.client', (newValue: any, oldValue: any) => {
          if (oldValue !== newValue) {
            processClientRoles();
          }
        });
        this.$scope.$watch('$ctrl.paymentInfo.financebank', (newValue: any, oldValue: any) => {
          if (oldValue !== newValue) {
            this.updateFields();
          }
        });
        this.$scope.$on('bankAccountsUpdated', (_event: any, bankAccounts: any) => {
          this.paymentInfo.bank_accounts = bankAccounts;
          this.updateFields();
        });
      }

      save(paymentInfo: any, initNew: any) {
        return this.FinancesService.savePaymentInfo(paymentInfo).then(() => {
          notify(this.gettext('Payment info saved'));
          if (initNew) {
            this.initNewPaymentInfo();
          } else {
            this.modalInstance.close();
          }
        }, this.GtUtils.errorClb);
      }

      deletePaymentInfo(paymentInfo: any) {
        return this.FinancesService.deletePaymentInfo(paymentInfo).then(
          () => this.modalInstance.close(),
          this.GtUtils.errorClb,
        );
      }

      initNewPaymentInfo() {
        this.paymentInfo = {};
        this.updateFields();
      }

      close(data: any) {
        if (confirm(this.gettext('Close modal?'))) {
          this.modalInstance.close(data || 'close');
        }
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-sm-6 col-xs-12',
          fieldGroup: [],
        };
        const col2: any = {
          className: 'form-group-container col-sm-6 col-xs-12',
          fieldGroup: [],
        };
        const col3: any = {
          className: 'form-group-container col-sm-12 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push(
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'CURRENCY',
            },
            fieldGroup: [
              {
                key: 'currency',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Currency'),
                  resource: 'finances.Currency',
                },
              },
              {
                key: 'status',
                type: 'gt-select',
                defaultValue: 'valid',
                templateOptions: {
                  label: this.gettext('Status'),
                  placeholder: this.gettext('Choose status'),
                  valueProp: 'value',
                  labelProp: 'name',
                  options: [
                    { value: 'valid', name: this.gettext('Valid') },
                    { value: 'invalid', name: this.gettext('Invalid') },
                  ],
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'MAIN',
            },
            fieldGroup: [
              {
                key: 'client',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Client'),
                  resource: 'clients.Client',
                },
              },
              {
                key: 'account',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Account'),
                },
              },
              {
                key: 'financebank',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Bank'),
                  resource: 'finances.FinanceBank',
                  addFunc: () => this.FinancesService.financeBankModal(),
                  addIcon: 'fa-university',
                  addPerms: ['add_bank'],
                  onSelect: () => {
                    this.FinancesService.FinanceBank.get({
                      id: this.paymentInfo.financebank,
                    }).$promise.then((data: any) => {
                      this.paymentInfo.bank_name_eng = data.full_name_eng;
                      this.paymentInfo.bank_name = data.name;
                      this.paymentInfo.bank_code = data.bank_code;
                      this.paymentInfo.swift = data.swift;
                      this.paymentInfo.legal_address = data.address;
                      this.paymentInfo.legal_address_eng = data.address_eng;
                      this.paymentInfo.country = data.country;
                    });
                  },
                },
              },

              {
                key: 'bank_name_eng',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Bank Eng'),
                },
                hideExpression: () => !this.paymentInfo.financebank,
              },
              {
                key: 'bank_branch',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Bank branch'),
                  resource: 'finances.BankBranch',
                  addFunc: () => this.FinancesService.bankBranchModal(this.paymentInfo.financebank),
                  addIcon: 'fa-university',
                  queryParams: { bank: this.paymentInfo.financebank },
                  addPerms: ['add_bankbranch'],
                },
              },
              {
                key: 'bank_code',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Code'),
                },
              },
              {
                key: 'iban',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('IBAN'),
                },
              },
              {
                key: 'swift',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('SWIFT'),
                },
              },
            ],
          },
        );
        col2.fieldGroup.push(
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'CONTACT',
              defaultHide: true,
            },
            fieldGroup: [
              {
                key: 'contact',
                type: 'gt-textarea',
                templateOptions: {
                  label: this.gettext('Contact info'),
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'COUNTRY',
            },
            fieldGroup: [
              {
                key: 'country',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Country'),
                  resource: 'location.country',
                },
              },
              {
                key: 'legal_address',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Address'),
                },
              },
              {
                key: 'legal_address_eng',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Address Eng'),
                },
                hideExpression: () => !this.paymentInfo.legal_address_eng,
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'CORRESPONDENT',
              defaultHide: true,
            },
            fieldGroup: [
              {
                key: 'corr_bank',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr bank'),
                },
              },
              {
                key: 'corr_account',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr account'),
                },
              },

              {
                key: 'corr_iban',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr iban'),
                },
              },
              {
                key: 'corr_swift',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr swift'),
                },
              },
              {
                key: 'corr_bank_1',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr bank 1'),
                },
                hideExpression: () => !this.paymentInfo.corr_bank,
              },
              {
                key: 'corr_account_1',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr account 1'),
                },
                hideExpression: () => !this.paymentInfo.corr_bank,
              },
              {
                key: 'corr_iban_1',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr iban 1'),
                },
                hideExpression: () => !this.paymentInfo.corr_bank,
              },
              {
                key: 'corr_swift_1',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr swift 1'),
                },
                hideExpression: () => !this.paymentInfo.corr_bank,
              },
              {
                key: 'corr_bank_2',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr bank 2'),
                },
                hideExpression: () => !this.paymentInfo.corr_bank_1,
              },
              {
                key: 'corr_account_2',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr account 2'),
                },
                hideExpression: () => !this.paymentInfo.corr_bank_1,
              },
              {
                key: 'corr_iban_2',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr iban 2'),
                },
                hideExpression: () => !this.paymentInfo.corr_bank_1,
              },
              {
                key: 'corr_swift_2',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Corr swift 2'),
                },
                hideExpression: () => !this.paymentInfo.corr_bank_1,
              },
            ],
          },
        );

        if (this.clientRoles.includes('owner')) {
          col3.fieldGroup.push({
            wrapper: 'gt-panel',
            hideExpression: () => {
              return !this.clientRoles.includes('owner');
            },
            fieldGroup: [
              {
                template: html`
                  <div class="central-container" style="margin-top: 10px">
                    <label class="contract-description-label">
                      <translate>BANK ACCOUNTS</translate></label
                    >
                  </div>
                `,
              },
              {
                template: html`
                  <bank-accounts-widget
                    bank-accounts="model.bank_accounts"
                    payment-info="model"
                  </bank-accounts-widget>
                `,
              },
            ],
          });
        }

        return {
          formName: 'operation-edit-modal',
          fieldsDef: [col1, col2, col3],
        };
      }
    },
  ],
};
