import template from './stock-reserve-units-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const StockReserveUnitsContainer = {
  template,
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    readonly: '<?',
  },
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'StockReserveUnitsService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      StockReserveUnitsService: any;
      count: number;
      filterLevel: string;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      items: any;
      queryParams: QueryParams = {};
      readonly: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        StockReserveUnitsService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.StockReserveUnitsService = StockReserveUnitsService;
        this.items = [];
        this.count = 0;
        this.filterLevel = '';
        this.readonly = false;
        this.initQueryParams = {};
      }
      $onInit() {
        this.queryParams = { page_size: 20, ...this.initQueryParams };
        this.filterLevel = this.filterLevel || 'stock-reserve-units-container';
        this.readonly = this.readonly || false;
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }
      applyFilters(params: QueryParams) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(
          { ...this.initQueryParams, ...params },
          this.filterLevel,
        );
      }
      updateData() {
        this.GtUtils.overlay('show');
        return this.StockReserveUnitsService.getStockReserveUnitList(this.queryParams)
          .then((data: any) => {
            this.items = data.results;
            this.count = data.count;
          })
          .finally(() => this.GtUtils.overlay('hide'));
      }
      openModal(item: any) {
        item = item || { ...this.queryParams };
        return this.StockReserveUnitsService.openStockReserveUnitModal(item, {}).then(() =>
          this.updateData(),
        );
      }
      clone(item: any) {
        const newItem: any = {};
        Object.keys(item)
          .filter((f) => Boolean(item[f]))
          .forEach((f) => {
            newItem[f] = item[f]?.pk || item[f];
          });
        return this.StockReserveUnitsService.clone(newItem, {}).then(() => this.updateData());
      }
    },
  ],
};
