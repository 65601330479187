import React from 'react';

import { useComponent } from '~/shared/lib/components';
import { Async } from '~/shared/lib/state';
import { Pagination } from '~/shared/ui/components/pagination';
import { ProgressBar } from '~/shared/ui/kit/progress-bar';

import { DatasetModel } from './dataset.model';
import type {
  DataParams,
  DataRecord,
  DatasetViewProps,
  ViewMode,
  ViewProps,
} from '../../lib/types';
import { DatasetFilters } from '../dataset-filters';
import { DatasetViews } from '../dataset-views/dataset-views';
import { TableView } from '../table-view';

export const Dataset = <R extends DataRecord, P extends DataParams>(
  props: DatasetViewProps<R, P>,
) => {
  const { model, ComponentProvider } = useComponent(props, DatasetModel<R, P>);

  const ViewComponent = React.useMemo(() => {
    const viewVariants: Record<ViewMode, React.FC<ViewProps<R, P>>> = {
      grid: TableView,
      table: TableView,
    };
    return viewVariants[props.view ?? 'grid'];
  }, [props.view]);

  return (
    <section className="mt-2 !flex flex-col gap-3">
      <ComponentProvider>
        <DatasetViews
          onFieldsChanged={model.setFields}
          fieldDefs$={props.fieldDefs$}
          datasetId$={model.datasetId$}
        />
        <DatasetFilters
          datasetId$={model.datasetId$}
          fields$={model.fields$}
          onParamsChange={model.changePageParams}
        />
        <Async args={{ loading: props.loading$ }}>
          {({ loading }) => (loading ? <ProgressBar /> : null)}
        </Async>
        <ViewComponent
          editing$={props.editing$}
          loading$={props.loading$}
          selectable={props.selectable}
        />

        <Async args={{ params: props.pageParams$, data: props.data$ }}>
          {({ params, data }) => (
            <Pagination
              pageCurrent={params.page}
              pageSize={params.page_size}
              recordsTotal={data.count}
              currentPageChanged={(page) => model.changePageParams({ params: { page } as P })}
              pageSizeChanged={(pageSize) =>
                model.changePageParams({ params: { page_size: pageSize } as P })
              }
              pageSizeOptions={[25]}
            />
          )}
        </Async>
      </ComponentProvider>
    </section>
  );
};
