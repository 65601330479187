import React from 'react';

import type { CurrentUser } from '~/core/auth/lib';
import type { FieldDef } from '~/core/datasets';
import { FinancePositionTemplate } from '~/features/finances/finances/components/finance-position-template/finance-position-template';
import { PaymentDelayTemplate } from '~/features/finances/finances/components/payment-delay-template/payment-delay-template';
import { PaymentPlansTemplate } from '~/features/finances/finances/components/payment-plans-template/payment-plans-template';
import { PositionPassportsVesselsTemplate } from '~/features/finances/finances/components/position-passports-vessels-template/position-passports-vessels-template';
import { formatDate } from '~/shared/lib/date';
import type { ListOption } from '~/shared/lib/types';
import { requireSchema } from '~/shared/lib/utils';
import { Tag, type TagVariants } from '~/shared/ui/kit/tag';

import type { FinanceRecord } from './types';

const defaultDateFormat = 'yy/MM/dd';
const invoiceTypeOptions: Record<FinanceRecord['invoice_type'], ListOption> = {
  incoming: {
    value: 'incoming',
    label: 'Incoming',
    tagProps: { variant: 'green' },
  },
  outgoing: {
    value: 'outgoing',
    label: 'Outgoing',
    tagProps: { variant: 'yellow' },
  },
};
const statusOptions: Record<FinanceRecord['status'], ListOption> = {
  new: {
    value: 'new',
    label: 'New',
    tagProps: { variant: 'blue' },
  },
  process: {
    value: 'process',
    label: 'Process',
    tagProps: { variant: 'orange' },
  },
  paid: {
    value: 'paid',
    label: 'Paid',
    tagProps: { variant: 'green' },
  },
  canceled: {
    value: 'canceled',
    label: 'Cancelled',
    tagProps: { variant: 'red' },
  },
};
const conditionOptions: Record<FinanceRecord['condition'], ListOption> = {
  prepay: {
    value: 'prepay',
    label: 'Prepay',
    tagProps: { variant: 'yellow' },
  },
  balance: {
    value: 'balance',
    label: 'Balance',
    tagProps: { variant: 'green' },
  },
};
const assignmentOptions: Record<FinanceRecord['assignment'], ListOption> = {
  commercial: {
    value: 'commercial',
    label: 'Commercial',
    tagProps: { variant: 'green' },
  },
  proforma: {
    value: 'proforma',
    label: 'Proforma',
    tagProps: { variant: 'yellow' },
  },
  debit: {
    value: 'debit',
    label: 'Debit',
    tagProps: { variant: 'orange' },
  },
  credit: {
    value: 'credit',
    label: 'Credit',
    tagProps: { variant: 'blue' },
  },
  reserve: {
    value: 'reserve',
    label: 'Reserve',
    tagProps: { variant: 'pink' },
  },
};

export const getFinanceFields = (user: CurrentUser): FieldDef<FinanceRecord>[] => [
  {
    key: 'number',
    title: 'Number',
    dataType: 'template',
    sticky: false,
    schema: requireSchema('Number is required'),
  },
  {
    key: 'invoice_type',
    title: 'Invoice type',
    dataType: 'select',
    typeExtra: {
      options: Object.values(invoiceTypeOptions),
    },
    getValue: (rec) => (rec.invoice_type ? invoiceTypeOptions[rec.invoice_type] : undefined),
    setValue: ({ record, value }) => ({
      ...record,
      invoice_type: value?.value as FinanceRecord['invoice_type'],
    }),
    schema: requireSchema('Select a valid invoice type'),
  },
  {
    key: '_uses',
    title: 'Uses',
    dataType: 'template',
    render: ({ record }) => (
      <FinancePositionTemplate
        title={record.invoicepositions_set?.length ? record.invoicepositions_set[0].use : ''}
        value={record}
        count={record.invoicepositions_set?.length}
      />
    ),
  },
  {
    key: 'status',
    title: 'Status',
    dataType: 'select',
    typeExtra: { options: Object.values(statusOptions) },
    getValue: (rec) => (rec.status ? statusOptions[rec.status] : undefined),
    setValue: ({ record, value }) => ({
      ...record,
      status: value?.value as FinanceRecord['status'],
    }),
  },
  {
    key: 'condition',
    title: 'Condition',
    dataType: 'select',
    typeExtra: { options: Object.values(conditionOptions) },
    getValue: (rec) => (rec.condition ? conditionOptions[rec.condition] : undefined),
    setValue: ({ record, value }) => ({
      ...record,
      condition: value?.value as FinanceRecord['condition'],
    }),
  },
  {
    key: 'contract_clients_data',
    title: 'Contract counterparty',
    dataType: 'template',
    render: ({ record }) => {
      return (
        <div className="pl-2">
          <span>
            {record.contract_clients_data?.map((item) => (
              <span key={item.client_id}>
                {item.client_name}
                <div className="clear-both block"></div>
              </span>
            ))}
          </span>
        </div>
      );
    },

    editing: false,
  },
  {
    key: 'company_group_owner_title',
    title: 'Company group owner title',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'company_group_counterparty_title',
    title: 'Company group counterparty title',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'assignment',
    title: 'Assignment',
    dataType: 'select',
    typeExtra: { options: Object.values(assignmentOptions) },
    getValue: (rec) => (rec.assignment ? assignmentOptions[rec.assignment] : undefined),
    setValue: ({ record, value }) => ({
      ...record,
      assignment: value?.value as FinanceRecord['assignment'],
    }),
  },
  {
    key: 'related_invoice_number',
    title: 'Related invoice number',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'date_of_withdrawal',
    title: 'Date of withdrawal',
    dataType: 'date',
    typeExtra: { print: 'yy/MM/dd' },
  },
  {
    key: 'date_of_receiving',
    title: 'Received',
    dataType: 'date',

    typeExtra: { print: defaultDateFormat },
  },
  {
    key: 'date_of_execution',
    title: 'Paid plan',
    dataType: 'date',

    typeExtra: { print: defaultDateFormat },
  },
  {
    key: 'paid_diff_days',
    title: 'Payment delay',
    dataType: 'template',
    editing: false,
    render: ({ record }) => (
      <PaymentDelayTemplate
        paidDiffDays={record.paid_diff_days}
        paidDiffCoef={record.paid_diff_coef}
      />
    ),
  },
  {
    key: 'date_of_payment',
    title: 'Paid date',
    dataType: 'template',
    editing: false,
    render: ({ record }) => {
      let displayDate = '';
      const date = record.date_of_execution ?? record.date_of_payment;
      if (date) {
        displayDate = formatDate(new Date(date), defaultDateFormat);
      }

      if (!displayDate) {
        return null;
      }

      const statusVariant: TagVariants['variant'] =
        !record.date_of_execution ||
        new Date(record.date_of_payment) < new Date(record.date_of_execution)
          ? 'green'
          : 'red';

      return <Tag variant={statusVariant}>{displayDate}</Tag>;
    },
  },
  { key: 'amount', title: 'Amount', dataType: 'number', editing: false },
  { key: 'amount_USD', title: 'Amount USD', dataType: 'number' },
  {
    key: 'currency',
    title: 'Currency',
    dataType: 'entity',
    typeExtra: { entity: 'finances.Currency' },
    getValue: (rec) =>
      rec.currency ? [{ id: rec.currency, title: rec.currency_symbol }] : undefined,
    setValue: ({ record, value }) =>
      value?.length
        ? { ...record, currency: value[0].id, currency_symbol: value[0].title }
        : record,
    schema: requireSchema('Currency is required'),
  },
  {
    key: 'currency_exchange',
    title: 'Currency rate',
    dataType: 'entity',
    typeExtra: { entity: 'finances.CurrencyExchange' },
    getValue: (rec) =>
      rec.currency_exchange
        ? [{ id: rec.currency_exchange, title: String(rec.currency_exchange_rate) }]
        : undefined,
    setValue: ({ record, value }) =>
      value?.length
        ? ({
            ...record,
            currency_exchange: value[0].id,
            currency_exchange_rate: value[0].title,
          } as unknown as FinanceRecord)
        : record,
    validate: ({ record, value }) => {
      if (
        record.currency_symbol?.toLowerCase() !==
          user.settings.DEFAULT_CURRENCY.toLocaleLowerCase() &&
        !value?.length
      ) {
        return 'exchange rate is required';
      }
    },
  },
  {
    key: 'payments_currency_exchange_rate',
    title: 'Payments currency exchange rate',
    dataType: 'number',
    editing: false,
  },
  { key: 'volume', title: 'Volume', dataType: 'number', editing: false },
  {
    key: 'full_invoiced_volume',
    title: 'Full invoice volume',
    dataType: 'number',
    editing: false,
  },
  { key: 'interest_rate', title: 'Interest rate', dataType: 'number' },
  {
    key: 'paid_diff_coef',
    title: 'Interest rate delay',
    dataType: 'number',
    editing: false,
    getValue: (record) =>
      Number(
        (
          ((record.amount ?? 0 * (record.interest_rate ?? 0 / 100)) / 360) *
          (record.paid_diff_coef ?? 0)
        ).toFixed(2),
      ),
  },
  {
    key: 'discount',
    title: 'Discount',
    dataType: 'number',
  },
  { key: 'payment_conditions', title: 'Payment conditions', dataType: 'number' },
  {
    key: 'fact_amount',
    title: 'Total paid',
    dataType: 'template',
    editing: false,
    render: ({ record }) => {
      return record.fact_amount ? (
        <span className="pl-2">
          {record.invoice_type === 'incoming' ? '-' : '+'} {Number(record.fact_amount).toFixed(2)}
          <span className="smaller-label"> {record.currency_symbol} </span>
          {!record.to_pay && <span>✔</span>}
        </span>
      ) : null;
    },
  },
  {
    key: 'fact_default_currency_amount',
    title: 'Total paid USD',
    dataType: 'template',
    editing: false,
    render: ({ record }) => {
      return (
        <div className="pl-2">
          <span>
            {Boolean(record.fact_amount) && record.invoice_type === 'incoming' && (
              <span>{record.fact_default_currency_amount}</span>
            )}
            {Boolean(record.fact_amount) && record.invoice_type == 'outgoing' && (
              <span>{record.fact_default_currency_amount}</span>
            )}
          </span>
        </div>
      );
    },
  },
  { key: 'to_pay', title: 'To pay', dataType: 'number' },
  {
    key: 'logistics_total_value',
    title: 'Logistics total value',
    dataType: 'number',
    editing: false,
  },
  { key: 'invoiced_logistics', title: 'Invoiced logistics', dataType: 'number', editing: false },
  {
    key: 'clientrole_from',
    title: 'Side from',
    dataType: 'entity',
    typeExtra: { entity: 'clients.ClientRole' },
    getValue: (rec) =>
      rec.clientrole_from
        ? [{ id: rec.clientrole_from, title: rec.clientrole_from_name }]
        : undefined,
    setValue: ({ record, value }) => {
      return value?.length
        ? { ...record, clientrole_from: value[0].id, clientrole_from_name: value[0].title }
        : record;
    },
    schema: requireSchema('Side from is required'),
  },
  {
    key: 'clientrole_to',
    title: 'Side to',
    dataType: 'entity',
    typeExtra: { entity: 'clients.ClientRole' },
    getValue: (rec) =>
      rec.clientrole_to ? [{ id: rec.clientrole_to, title: rec.clientrole_to_name }] : undefined,
    setValue: ({ record, value }) => {
      return value?.length
        ? { ...record, clientrole_to: value[0].id, clientrole_to_name: value[0].title }
        : record;
    },
    schema: requireSchema('Side to is required'),
  },
  {
    key: 'client_from_approval_status',
    title: 'Side from status',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'client_to_approval_status',
    title: 'Side to status',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'quantity_total_amount_sum',
    title: 'Quantity total amount sum',
    dataType: 'number',
    editing: false,
  },
  {
    key: '_passports',
    title: 'Passports',
    dataType: 'template',
    render: ({ record }) => (
      <FinancePositionTemplate
        title={
          record.invoicepositions_set?.length ? (
            <a href={`/#/passport/${record.invoicepositions_set[0].passport}`}>
              {record.invoicepositions_set[0].passport_title}
            </a>
          ) : (
            ''
          )
        }
        value={record}
        count={record.invoicepositions_set?.length}
      />
    ),
  },
  {
    key: '_position_price',
    title: 'Position price',
    dataType: 'template',
    render: ({ record }) => (
      <FinancePositionTemplate
        title={
          record.invoicepositions_set?.length ? record.invoicepositions_set[0].price_per_piece : ''
        }
        value={record}
        count={record.invoicepositions_set?.length}
      />
    ),
  },
  {
    key: '_position_quantity',
    title: 'Position quantity',
    dataType: 'template',
    render: ({ record }) => (
      <FinancePositionTemplate
        title={
          record.invoicepositions_set?.length ? record.invoicepositions_set[0].price_per_piece : ''
        }
        value={record}
        count={record.invoicepositions_set?.length}
      />
    ),
  },
  {
    key: '_position_charges',
    title: 'Position charges',
    dataType: 'template',
    render: ({ record }) => (
      <FinancePositionTemplate
        title={record.invoicepositions_set?.length ? record.invoicepositions_set[0].quantity : ''}
        value={record}
        count={record.invoicepositions_set?.length}
      />
    ),
  },
  {
    key: 'position_passports',
    title: 'Vessels',
    dataType: 'template',
    editing: false,
    render: ({ record }) => {
      if (!record.position_passports?.length) {
        return <></>;
      }
      return <PositionPassportsVesselsTemplate positionPassports={record.position_passports} />;
    },
  },
  {
    key: '_contract',
    title: 'Contract',
    dataType: 'template',
    render: ({ record }) => (
      <FinancePositionTemplate
        title={
          record.invoicepositions_set?.length ? (
            <a href={`/#/contract/${record.invoicepositions_set[0].contract}`}>
              {record.invoicepositions_set[0].contract_number}
            </a>
          ) : (
            ''
          )
        }
        value={record}
        count={record.invoicepositions_set?.length}
      />
    ),
  },
  {
    key: 'position_contracts',
    title: 'Contract payment conditions',
    dataType: 'text',
    editing: false,
    getValue: (record) =>
      record.position_contracts
        ?.map((item) => item.payment_conditions_option_title ?? item.id)
        .join(', '),
  },
  {
    key: 'bank_name',
    title: 'Bank',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'counterpaty_payment_info',
    title: 'Counterparty payment info bank name',
    dataType: 'entity',
    typeExtra: { entity: 'finances.PaymentInfo' },
    getValue: (rec) =>
      rec.counterpaty_payment_info
        ? [{ id: rec.counterpaty_payment_info, title: rec.counterpaty_payment_info_bank_name }]
        : undefined,
    setValue: ({ record, value }) =>
      value?.length
        ? {
            ...record,
            counterpaty_payment_info: value[0].id,
            counterpaty_payment_info_bank_name: value[0].title,
          }
        : record,
  },
  {
    key: 'owner_bank_account_name',
    title: 'Owner bank account',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'finance_account',
    title: 'Finance account title',
    dataType: 'entity',
    typeExtra: { entity: 'finances.FinanceAccount' },
    getValue: (rec) =>
      rec.finance_account
        ? [{ id: rec.finance_account, title: rec.finance_account_title }]
        : undefined,
    setValue: ({ record, value }) =>
      value?.length
        ? {
            ...record,
            finance_account: value[0].id,
            finance_account_title: value[0].title,
          }
        : record,
  },
  {
    key: '_subuses',
    title: 'Subuses',
    dataType: 'template',
    render: ({ record }) => (
      <FinancePositionTemplate
        title={record.invoicepositions_set?.length ? record.invoicepositions_set[0].subuses : ''}
        value={record}
        count={record.invoicepositions_set?.length}
      />
    ),
  },
  {
    key: 'document_count',
    title: 'Documents',
    dataType: 'number',
    editing: false,
  },
  {
    key: 'business_unit',
    title: 'Business unit',
    dataType: 'entity',
    typeExtra: { entity: 'core.BusinessUnit' },
    getValue: (rec) =>
      rec.business_unit ? [{ id: rec.business_unit, title: rec.business_unit_title }] : undefined,
    setValue: ({ record, value }) =>
      value?.length
        ? {
            ...record,
            business_unit: value[0].id,
            business_unit_title: value[0].title,
          }
        : record,
  },
  {
    key: 'responsible',
    title: 'Responsible',
    dataType: 'entity',
    typeExtra: { entity: 'accounts.User' },
    getValue: (rec) =>
      rec.responsible ? [{ id: rec.responsible, title: rec.responsible_first_name }] : undefined,
    setValue: ({ record, value }) =>
      value?.length
        ? {
            ...record,
            responsible: value[0].id,
            responsible_first_name: value[0].title,
          }
        : record,
  },
  {
    key: 'responsible_for_signing',
    title: 'Responsible for signing',
    dataType: 'entity',
    typeExtra: { entity: 'accounts.User' },
    getValue: (rec) =>
      rec.responsible_for_signing
        ? [{ id: rec.responsible_for_signing, title: rec.responsible_for_signing_first_name }]
        : undefined,
    setValue: ({ record, value }) =>
      value?.length
        ? {
            ...record,
            responsible_for_signing: value[0].id,
            responsible_for_signing_first_name: value[0].title,
          }
        : record,
  },
  {
    key: 'paymentplans_data',
    title: 'Payment plans',
    dataType: 'template',
    editing: false,
    render: ({ record }) => {
      if (!record.paymentplans_data?.length) {
        return <></>;
      }
      return (
        <PaymentPlansTemplate
          paymentPlans={record.paymentplans_data}
          currencySymbol={record.currency_symbol}
        />
      );
    },
  },
  { key: 'additional_info', title: 'Additional info', dataType: 'text' },
  { key: 'delivery_hyperlink', title: 'Delivery hyperlink', dataType: 'text' },
  {
    key: 'editor_first_name',
    title: 'Editor',
    dataType: 'person',
    editing: false,
    getValue: (rec) => ({
      firstName: rec.editor_first_name,
      lastName: rec.editor_last_name,
      avatarSrc: rec.editor_avatar,
    }),
  },
  {
    key: 'payments_data',
    title: 'Payment bank name',
    dataType: 'text',
    editing: false,
    getValue: (rec) =>
      rec.payments_data?.length ? rec.payments_data.map((item) => item.bank_name).join(', ') : '',
  },
  {
    key: '_customs_declaration',
    title: 'Customs declaration',
    dataType: 'template',
    editing: false,
    render: ({ record }) => {
      return (
        <>
          {record.invoicepositions_set?.map((position) =>
            position.customs_declaration_data
              ? position.customs_declaration_data.map((custom_declaration) =>
                  custom_declaration.id ? (
                    <span key={custom_declaration.id}>
                      <span className="value">{custom_declaration.number}</span>
                      <div className="clear-both block"></div>
                    </span>
                  ) : null,
                )
              : null,
          )}
        </>
      );
    },
  },
  { key: 'daily_fines_rate', title: 'Daily fines rate', dataType: 'number' },
  {
    key: 'fines_days_count',
    title: 'Fines days count',
    dataType: 'template',
    editing: false,
    render: ({ record }) => {
      return (
        <span>
          {record.fines_days}

          {record.fines_days_count === 'calendar_days' && <Tag variant="green">Calendar days</Tag>}

          {record.fines_days_count === 'business_days' && <Tag variant="yellow">Business days</Tag>}
        </span>
      );
    },
  },
  { key: 'fines_invoicing', title: 'Fines invoicing', dataType: 'text', editing: false },
  { key: 'fines_amount', title: 'Fines amount', dataType: 'number' },
  {
    key: 'fines_amount_default',
    title: 'Fines amount default',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'amount_not_reserved',
    title: 'Amount not reserver',
    dataType: 'number',
    editing: false,
  },
  {
    key: 'payment_conditions_option',
    title: 'Payment conditions option',
    dataType: 'entity',
    typeExtra: { entity: 'finances.PaymentCondition' },
    getValue: (rec) =>
      rec.payment_conditions_option
        ? [{ id: rec.payment_conditions_option, title: rec.payment_conditions_option_title }]
        : undefined,
    setValue: ({ record, value }) =>
      value?.length
        ? {
            ...record,
            payment_conditions_option: value[0].id,
            payment_conditions_option_title: value[0].title,
          }
        : record,
  },
  {
    key: 'date',
    title: 'Issuance',
    dataType: 'date',
    typeExtra: { print: 'dd.MM.yy' },
    editing: false,
  },
  {
    key: 'payment_amount',
    title: 'Payments',
    dataType: 'text',
    editing: false,
    getValue: (rec) =>
      rec.payment_amount ? `${rec.payment_amount.toFixed(2)} ${rec.currency_symbol}` : '',
  },
  {
    key: 'payment_default_currency_amount',
    title: 'Payments USD',
    dataType: 'text',
    editing: false,
    getValue: (rec) =>
      rec.payment_default_currency_amount
        ? `${rec.payment_default_currency_amount.toFixed(2)}`
        : '',
  },
  {
    key: '_counterpatry',
    dataType: 'text',
    title: 'Counterparty',
    editing: false,
    getValue: (rec) =>
      !rec.clientrole_from && !rec.clientrole_to
        ? ''
        : `${rec.clientrole_from_name ?? 'N/A'} - ${rec.clientrole_to_name ?? 'N/A'}`,
  },
  {
    key: 'offset_amount',
    title: 'Offset',
    dataType: 'number',
    editing: false,
  },
  {
    key: 'offset_default_currency_amount',
    title: 'Offset USD',
    dataType: 'number',
    editing: false,
  },
  {
    key: 'tse_data',
    title: 'TSE',
    dataType: 'text',
    editing: false,
    getValue: (rec) =>
      rec.tse_data?.length ? rec.tse_data.map((item) => item.number).join(', ') : '',
  },
  {
    key: 'bl_data',
    title: 'Bill of lading',
    dataType: 'text',
    editing: false,
    getValue: (rec) =>
      rec.bl_data?.length ? rec.bl_data.map((item) => item.number).join(', ') : '',
  },
];
