import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './contract-details-item-container.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const contractDetailsItemContainer = {
  bindings: {
    contract: '=',
    updateConfigWithFilterLevel: '&?',
    mode: '<?', // 'full' (default) or 'light'
    contractMode: '@?',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$stateParams',
    '$state',
    '$q',
    'GtUtils',
    'AccountsService',
    'ContractsService',
    'PageService',
    'gtFilterService',
    'DocumentsService',
    'gettext',
    'ClientsService',
    class {
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      AccountsService: AccountsService;
      ClientsService: any;
      ContractsService: ContractsService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      PageService: PageService;
      activeTabs: any;
      contract: any;
      contractChargesFilterLevel: string;
      costsPredictionsQueryParams: QueryParams = {};
      filterLevel = 'contract-side-list';
      filterLevelUpdates: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      hideWizard: any;
      loadCompleted: any;
      mode: any;
      passportQueryParams: QueryParams = {};
      tab = [''];
      updateConfigWithFilterLevel?: (args: any) => void;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $stateParams: ng.ui.IStateParamsService,
        $state: ng.ui.IStateService,
        $q: ng.IQService,
        GtUtils: GtUtilsService,
        AccountsService: AccountsService,
        ContractsService: ContractsService,
        PageService: PageService,
        gtFilterService: GtFilterService,
        DocumentsService: any,
        gettext: ng.gettext.gettextFunction,
        ClientsService: any,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$q = $q;
        this.GtUtils = GtUtils;
        this.AccountsService = AccountsService;
        this.ContractsService = ContractsService;
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.DocumentsService = DocumentsService;
        this.gettext = gettext;
        this.ClientsService = ClientsService;

        this.activeTabs = [];
        this.contract = {};
        this.mode = 'full';
        this.loadCompleted = false;
        this.contractChargesFilterLevel = '';
        this.hideWizard = false;
        this.costsPredictionsQueryParams = {};
      }

      $onInit() {
        this.tab = this.$stateParams.tab ||
          this.$rootScope.user.profile?.contract_tab || ['quality'];

        if (this.$stateParams.tab) {
          this.gtFilterService.updateQueryParams({ tab: this.$stateParams.tab }, this.filterLevel);
        }

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
          if (data.tab) {
            this.tab = typeof data.tab === 'string' ? [data.tab] : data.tab;
          }
        });

        this.filterLevelUpdates = 'contract-item-updates' + this.contract.id;
        this.getContractType().then((contractData: any) => {
          this.contract.contract_type = contractData.contract_type;
          this.contract.deal_type = contractData.deal_type;
          this.updateData();
          this.costsPredictionsQueryParams = this.getCostsPredictionsQueryParams();
        });

        if (this.$rootScope.user.profile?.wizard) {
          this.hideWizard = false;
        } else {
          this.hideWizard = true;
        }
      }

      changeTab(tab: string) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);

        if (!this.$rootScope.user.profile?.wizard) {
          this.hideWizard = true;
        }
      }

      getContractType() {
        return this.ContractsService.Contract.get({
          id: this.contract.id,
          fields: ['contract_type', 'deal_type'],
          stage: 'contract',
        }).$promise;
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.ContractsService.refreshContract(this.contract, true)
          .then((contract: any) => {
            this.contract = contract;
            this.PageService.setPageTitle(
              this.$rootScope.pageTitle + ' #' + this.contract.contract_number,
            );
            this.contract._basis_required_terminal = [];
            this.filterLevel = `${contract.contract_type}-contracts-page-view`;
            this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
              if (data.tab) {
                this.tab = typeof data.tab === 'string' ? [data.tab] : data.tab;
              }
            });
            this.passportQueryParams = (this.contract.connections || []).map(
              (conn: any) => conn.passport,
            );
            const previousQueryParams = this.gtFilterService.getQueryParams(this.filterLevel);
            this.gtFilterService.updateQueryParams({}, this.filterLevel);
            this.PageService.syncUrlFilter(this.filterLevel);
            this.updateConfigWithFilterLevel?.({
              contract: this.contract,
              filterLevel: this.filterLevel,
            });
            this.gtFilterService.updateQueryParams(previousQueryParams, this.filterLevel);
            this.GtUtils.overlay('hide');
            this.contractChargesFilterLevel =
              this.contract.deal_type !== 'services'
                ? 'contracts-contract-charges-list-table'
                : 'service-contracts-contract-charges-list-table';
            this.loadCompleted = true;
            return this.ContractsService.Contract.query(
              {
                id: this.contract.id,
                serializer: 'modal',
              },
              (contractModal: any) => {
                this.contract = { ...this.contract, ...contractModal };
                return this.contract;
              },
            );
          })
          .catch(() => {
            this.GtUtils.overlay('hide');
            notify(this.gettext("Contract doesn't exist"));
            this.$state.go('contracts.sale');
          });
      }

      openRoleModal(id: number, model: any) {
        return this.ClientsService.roleModal({ id: id, model_name: model });
      }

      openRoleDetails(id: number) {
        return this.ClientsService.openRoleDetails({ id: id });
      }

      openEmailModal() {
        return this.ClientsService.sendEmailModal(
          this.contract.contract_type.charAt(0).toUpperCase() +
            this.contract.contract_type.slice(1) +
            'Contract',
          this.contract.id,
        );
      }

      editContract() {
        this.ContractsService.contractModal(this.contract)
          .then((res: string | { id: number }) => {
            if (res == 'cancel') {
              return;
            }
            if (res == 'delete') {
              this.$state.go('contracts.sale');
              return;
            }
            if (typeof res === 'object') {
              this.contract.id = res.id;
            }
            this.updateData();
          })
          .catch(this.GtUtils.errorClb);
      }

      editContractQuick() {
        this.ContractsService.contractModalQuick(this.contract).then(
          (res: string | { id: number }) => {
            if (res == 'cancel') {
              return;
            }
            if (res == 'delete') {
              this.$state.go('contracts.sale');
              return;
            }
            if (typeof res === 'object') {
              this.contract.id = res.id;
            }
            this.updateData();
          },
        );
      }

      openDocxModal() {
        return this.DocumentsService.generateDocxModal(
          this.contract.contract_purpose.charAt(0).toUpperCase() +
            this.contract.contract_purpose.slice(1) +
            'Contract',
          this.contract.id,
        );
      }

      clone() {
        if (!confirm(this.gettext('Do you want to clone this Contract?'))) {
          return this.$q.resolve();
        }
        return this.ContractsService.cloneContract(this.contract).then(() => {
          this.updateData();
        });
      }

      reverse() {
        if (!confirm(this.gettext(this.GtUtils.translate('Do you want to reverse contract?')))) {
          return;
        }
        return this.updateData().then(() => {
          this.contract.stage = 'contract';
          return this.ContractsService.reverseDeal(this.contract);
        });
      }

      createPassport() {
        if (
          this.contract.total_connected &&
          this.contract.volume - this.contract.total_connected <= 0
        ) {
          return notify(this.gettext('This contracts already fully connected '), 'error');
        }
        return this.updateData().then(() => {
          this.ContractsService.createPassportContract([this.contract]);
        });
      }

      connectToPassport(passportId: number) {
        return this.ContractsService.getVolumeAvailableToConnect(this.contract, passportId)
          .then((data: any) => {
            return this.ContractsService.connectToPassport({
              id: passportId,
              deal_id: this.contract.id,
              stage: 'contract',
              contract_purpose: this.ContractsService.getContractPurpose(this.contract),
              volume: data,
            });
          })
          .then(() => {
            this.updateData();
            notify(this.gettext('Passport updated'), 'info');
          }, this.GtUtils.errorClb);
      }

      addLogistic() {
        return this.ContractsService.addLogistic(this.contract);
      }

      addFinance(paymentConditionsKey: any, invoiceStatus: any) {
        return this.ContractsService.addFinance(this.contract, paymentConditionsKey, invoiceStatus);
      }

      addBalanceInvoice() {
        return this.ContractsService.addBalanceInvoice(this.contract);
      }

      getCostsPredictionsQueryParams() {
        if (this.contract.deal_type == 'export') {
          return { tariff_use_list: ['export'] };
        }
        return {};
      }

      goBack(type: any) {
        type = type || this.contract.contract_type;
        if (this.contract.deal_type == 'services') {
          this.$state.go('contracts.' + this.contract.deal_type);
        } else if (this.contract.deal_type == 'intermediate') {
          this.$state.go('contracts.' + this.contract.deal_type);
        } else if (this.contract.deal_type == 'export') {
          this.$state.go('contracts.' + this.contract.deal_type);
        } else {
          this.$state.go('contracts.' + type);
        }
      }
    },
  ],
};
