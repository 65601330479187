import type ng from 'angular';

import template from './transportation-storage-expense-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const TransportationStorageExpenseContainer = {
  bindings: {
    expense: '<',
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    '$state',
    'gtFilterService',
    'GtUtils',
    'gettext',
    'TransportationStorageExpenseFormFieldsService',
    'TransportationStorageExpenseService',
    class {
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      GtUtils: GtUtilsService;
      TransportationStorageExpenseFormFieldsService: any;
      TransportationStorageExpenseService: any;
      activeTabs: any;
      expense: any;
      fields: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams?: QueryParams = {};
      tab = [''];
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        TransportationStorageExpenseFormFieldsService: any,
        TransportationStorageExpenseService: any,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.TransportationStorageExpenseFormFieldsService =
          TransportationStorageExpenseFormFieldsService;
        this.TransportationStorageExpenseService = TransportationStorageExpenseService;

        this.expense = {};
        this.filterLevel = '';
        this.activeTabs = [];
        this.queryParams = {};
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'transportation-storage-expense-container';
        this.queryParams = this.queryParams ?? { tab: ['positions'] };
        this.tab = (this.queryParams.tab as string[] | undefined) ?? ['positions'];
        if (this.queryParams.tab) {
          this.gtFilterService.updateQueryParams({ tab: this.queryParams.tab }, this.filterLevel);
        }
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
          if (data.tab) {
            this.tab = data.tab;
          }
        });
        this.updateData();
      }
      updateData() {
        this.GtUtils.overlay('show');
        return this.TransportationStorageExpenseService.getExpenseDetails(this.expense.id)
          .then((data: any) => {
            this.expense = data;
            this.updateFields();
          })
          .catch(() => {
            this.GtUtils.errorClb(this.gettext('Warehouse doesn`t exist'));
            return this.$state.go('logistics.warehousesList');
          })
          .finally(() => this.GtUtils.overlay('hide'));
      }
      modalResponse(data: string) {
        if (data === 'cancel') {
          return;
        }
        if (data === 'delete') {
          this.$state.go('logistics.transportationStorageExpenses');
        }
        this.updateData();
      }
      openModal() {
        return this.TransportationStorageExpenseService.expenseModal(this.expense).then(
          (data: any) => this.modalResponse(data),
        );
      }
      clone() {
        return this.TransportationStorageExpenseService.clone(this.expense).then((data: any) =>
          this.modalResponse(data),
        );
      }
      createInvoice() {
        return this.TransportationStorageExpenseService.createInvoice(this.expense.id).then(
          (data: any) => this.modalResponse(data),
        );
      }

      updateFields() {
        return this.TransportationStorageExpenseFormFieldsService.getExpenseFields(
          this.expense,
          this.filterLevel + '-positions-form',
        ).then((fields: any) => {
          this.fields = fields;
        });
      }

      changeTab(tab: string) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);
      }
    },
  ],
};
