import type ng from 'angular';

import template from './potentials-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';

export const potentialsPage = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'PageService',
    'MarketService',
    'gettext',
    class PotentialsPage {
      $scope: ng.IScope;
      MarketService: any;
      PageService: PageService;
      filterLevel = 'potentials-page';
      gettext: ng.gettext.gettextFunction;
      groupBy: any;
      gtFilterService: GtFilterService;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        PageService: PageService,
        MarketService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.MarketService = MarketService;
        this.gettext = gettext;

        this.groupBy = null;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.$scope.$on('group-by', (event: any, data: any) => {
          this.groupBy = data;
        });
      }

      getPageConfig() {
        return {
          class: 'page-header-main-prices-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Import('clients.croparea'),
          ],
          pages: this.MarketService.getPageConfig(),
          filters: {
            groupBy: {
              cargo: this.gettext('Cargo'),
              client_cargo: this.gettext('Client & cargo'),
              storage_cargo: this.gettext('Storage & cargo'),
            },
            filterLevel: this.filterLevel,
            clicked: false,
            search: true,
            dates: true,
            dateSelects: [
              {
                argument: 'create_time',
                placeholder: this.gettext('Create date'),
              },
            ],
            nestedSelects: [],
            multiSelects: [],
          },
        };
      }
    },
  ],
};
