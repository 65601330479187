import ng from 'angular';

import { getModalRoot } from '^/shared/ui/modal';

(function () {
  'use strict';

  ng.module('company.emailTemplates').factory('EmailTemplates', EmailTemplates);

  EmailTemplates.$inject = ['$resource', '$uibModal'];

  function EmailTemplates(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
  ) {
    const EmailTemplateResource = $resource(
      '/api/emailtemplates/emailtemplates/:id/',
      {
        id: '@id',
      },
      {
        update: { method: 'PATCH' },
        query: {
          method: 'GET',
          isArray: false,
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/emailtemplates/emailtemplates/predictions/',
        },
        emailData: {
          method: 'GET',
          isArray: false,
          url: '/api/emailtemplates/emailtemplates/:id/email_data/',
        },
      },
    );

    return {
      EmailTemplate: EmailTemplateResource,
      previewEmailModal: previewEmailModal,
      emailTemplateModal: emailTemplateModal,
    };

    ////////////////

    function previewEmailModal(body: any, recipients: any, extra: any) {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./components/preview-email-modal/preview-email-modal.tpl.html?raw'),
        controller: 'PreviewEmailModalController as vm',
        windowClass: 'modal-template modal-template-half-width',
        appendTo: getModalRoot(),
        resolve: {
          body: () => {
            return body;
          },
          recipients: () => {
            return recipients;
          },
          extra: () => {
            return extra;
          },
        },
      }).result;
    }

    function emailTemplateModal(emailTemplate: any) {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./components/email-modal/email-modal.tpl.html?raw'),
        appendTo: getModalRoot(),
        controller: 'EmailModalController as vm',
        windowClass: 'modal-template modal-template-three-thirds-width',
        resolve: {
          emailTemplate: () => {
            return emailTemplate;
          },
        },
      }).result;
    }
  }
})();
