import React from 'react';

import { useIcon } from '~/shared/ui/icons';

import type { SearchItem } from '../../lib/types';

export const GlobalSearchItem: React.FC<{ item: SearchItem; searchText: string }> = ({
  item,
  searchText,
}) => {
  const Icon = useIcon(item.icon);

  return (
    <button
      className="flex h-10 items-center gap-2 outline-none"
      onClick={item.action}
      data-testid="global-search-result-item"
    >
      <span className="bg-transparent-light flex h-6 w-6 items-center justify-center rounded-sm">
        <span className="flex h-4 w-4 items-center justify-center">
          <Icon size={16} className="text-stroke-main-stronger" />
        </span>
      </span>
      <p className="text-left text-xs font-medium">
        {item.title.split('').map((char, index) => (
          <span
            key={index + char}
            className={
              searchText.toLowerCase().includes(char.toLowerCase())
                ? 'text-text-additional-warning'
                : 'text-text-main-secondary'
            }
          >
            {char}
          </span>
        ))}
      </p>
    </button>
  );
};
