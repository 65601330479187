import { container } from 'tsyringe';

import { ApprovalRepository } from '~/features/common/approval';
import type { CounterpartyRecord } from '~/features/crm/counterparties/lib';
import { injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';

@injectable()
export class CounterpartiesService {
  private readonly approvalRepo = container.resolve(ApprovalRepository);

  public approveRecordsForUser = async (records: CounterpartyRecord[], userId: number) => {
    await Promise.all(
      records.map((record) =>
        this.approvalRepo.query({
          object_id: record.id,
          content_type: record.content_type,
          user: userId,
          is_archived: '0',
        }),
      ),
    )
      .then((approvals) =>
        approvals.map((approval) =>
          approval.records.map((approval) =>
            !approval.approved ? this.approvalRepo.approve(approval.id) : Promise.resolve(),
          ),
        ),
      )
      .catch(errorHandler);
  };

  public declineRecordsForUser = async (records: CounterpartyRecord[], userId: number) => {
    await Promise.all(
      records.map((record) =>
        this.approvalRepo.query({
          object_id: record.id,
          content_type: record.content_type,
          user: userId,
          is_archived: '0',
        }),
      ),
    )
      .then((approvals) =>
        approvals.map((approval) =>
          approval.records.map((approval) =>
            !approval.declined ? this.approvalRepo.decline(approval.id) : Promise.resolve(),
          ),
        ),
      )
      .catch(errorHandler);
  };
}
