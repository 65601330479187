import type ng from 'angular';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export class TransportationStorageExpenseFormFieldsService {
  FinancesService: FinancesService;
  FormFieldParamsService: FormFieldParamsService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    gettext: ng.gettext.gettextFunction,
    FormFieldParamsService: FormFieldParamsService,
    FinancesService: FinancesService,
  ) {
    this.gettext = gettext;
    this.FormFieldParamsService = FormFieldParamsService;
    this.FinancesService = FinancesService;
  }

  getExpenseFormConfig(expense: any, formName: string) {
    const fieldsGroupMain: any = {
      className: 'form-group-container col-sm-4 col-xs-12',
      fieldGroup: [],
    };
    const fieldsGroupFinances: any = {
      className: 'form-group-container col-sm-4 col-xs-12',
      fieldGroup: [],
    };
    const fieldsGroupFilters: any = {
      className: 'form-group-container col-sm-4 col-xs-12',
      fieldGroup: [],
    };

    fieldsGroupMain.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('MAIN'),
      },
      fieldGroup: [
        {
          key: 'number',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Number'),
            type: 'text',
            required: true,
          },
        },
        {
          key: 'date',
          type: 'gt-date-select',
          defaultValue: new Date(),
          templateOptions: {
            label: this.gettext('Date'),
            placeholder: this.gettext('Date'),
            required: true,
          },
        },
        {
          key: 'start_date',
          type: 'gt-date-select',
          defaultValue: new Date(),
          templateOptions: {
            label: this.gettext('Start Date'),
            placeholder: this.gettext('Start Date'),
            type: 'date',
            startView: 'day',
            minView: 'day',
            required: true,
          },
        },
        {
          key: 'end_date',
          type: 'gt-date-select',
          defaultValue: new Date(),
          templateOptions: {
            label: this.gettext('End Date'),
            placeholder: this.gettext('Start Date'),
            required: true,
            type: 'date',
            startView: 'day',
            minView: 'day',
          },
        },
      ],
    });
    fieldsGroupFinances.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('AMOUNT'),
      },
      fieldGroup: [
        {
          key: 'status',
          type: 'gt-select',
          defaultValue: 'new',
          templateOptions: {
            label: this.gettext('Status'),
            placeholder: this.gettext('Choose status'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('New'), value: 'new' },
              { name: this.gettext('Processed'), value: 'processed' },
              { name: this.gettext('Cancelled'), value: 'cancelled' },
            ],
          },
        },
        {
          key: 'strategy',
          type: 'gt-select',
          defaultValue: 'storage_time',
          templateOptions: {
            label: this.gettext('Strategy'),
            placeholder: this.gettext('Choose strategy'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Direct'), value: 'direct' },
              { name: this.gettext('Batch rest age (only on balance)'), value: 'rest_age' },
              { name: this.gettext('Batch storage time'), value: 'storage_time' },
            ],
          },
        },
        {
          key: 'base',
          type: 'gt-select',
          defaultValue: 'volume_metric',
          templateOptions: {
            label: this.gettext('Base'),
            placeholder: this.gettext('Choose base'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Volume'), value: 'volume_metric' },
              { name: this.gettext('Amount (basic)'), value: 'amount_metric' },
              { name: this.gettext('Total amount'), value: 'total_amount_metric' },
            ],
          },
        },
        {
          key: 'costs_amount',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Costs amount'),
            type: 'number',
            required: true,
          },
        },
        {
          key: 'charge',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Charge'),
            placeholder: this.gettext('Choose currency'),
            resource: 'finances.charge',
            required: true,
          },
        },
        {
          key: 'currency',
          type: 'gt-ui-select',
          defaultValue: this.FinancesService.getDefaultCurrencyId(),
          templateOptions: {
            label: this.gettext('Currency'),
            placeholder: this.gettext('Choose currency'),
            resource: 'finances.currency',
          },
        },
        {
          key: 'currency_exchange',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Rate'),
            placeholder: this.gettext('Choose rate'),
            resource: 'finances.currencyexchange',
          },
          expressionProperties: {
            'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
              scope.model.currency !== this.FinancesService.getDefaultCurrencyId(),
            'templateOptions.queryParams': (_viewValue: any, _modelValue: any, scope: any) => ({
              local_currency: scope.model.currency,
            }),
          },
        },
      ],
    });
    fieldsGroupFilters.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('FILTERS'),
      },
      fieldGroup: [
        {
          key: 'warehouse',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Warehouse'),
            placeholder: this.gettext('Choose warehouse'),
            resource: 'logistics.warehouse',
            hint: this.gettext('custom status'),
          },
        },
        {
          key: 'contract_position',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Contract position'),
            placeholder: this.gettext('Choose contract position'),
            resource: 'contracts.PurchaseContract',
          },
        },
        {
          key: 'commodity',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Commodity'),
            placeholder: this.gettext('Choose commodity'),
            resource: 'crops.Crop',
          },
        },
        {
          key: 'batch',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Batch'),
            placeholder: this.gettext('Choose batch'),
            resource: 'warehouses.recorderreference',
            queryParams: () => ({
              is_batch: true,
              commodity: expense.commodity,
              contract_position: expense.contract_position,
              warehouse: expense.warehouse,
            }),
            hint: this.gettext('batch'),
          },
        },
      ],
    });

    return {
      formName: formName,
      fieldsDef: [fieldsGroupMain, fieldsGroupFinances, fieldsGroupFilters],
    };
  }

  getExpenseFields(expense: any, formName: string) {
    return this.FormFieldParamsService.getFields(this.getExpenseFormConfig(expense, formName));
  }

  openExpenseFieldConfigModal(expense: any, formName: string) {
    return this.FormFieldParamsService.fieldsConfigModal(
      this.getExpenseFormConfig(expense, formName),
    );
  }

  getPositionFormConfig(position: any, formName: string) {
    const fieldClass = 'table-form-field';
    const fieldGroupInfo = {
      className: 'form-group-container col-sm-1 col-xs-12',
      fieldGroup: [
        {
          key: 'id',
          type: 'gt-input',
          className: fieldClass,
          templateOptions: {
            label: '#',
            disabled: true,
          },
        },
        {
          key: 'date',
          type: 'gt-date-select',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('Date'),
            placeholder: this.gettext('Date'),
          },
        },
      ],
    };
    const fieldGroupParams = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [
        {
          key: 'business_unit',
          type: 'gt-ui-select',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('Business Unit'),
            placeholder: this.gettext('Choose Business Unit'),
            resource: 'core.businessUnit',
          },
        },
        {
          key: 'receiver',
          type: 'gt-ui-select',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('Receiver'),
            placeholder: this.gettext('Choose receiver'),
            resource: 'clients.Client',
            queryParams: { is_receiver: '1' },
          },
        },
        {
          key: 'producer',
          type: 'gt-ui-select',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('Producer'),
            placeholder: this.gettext('Choose producer'),
            resource: 'clients.Client',
            queryParams: { is_producer: '1' },
          },
        },
      ],
    };
    const fieldGroupDimensions = {
      className: 'form-group-container col-sm-4 col-xs-12',
      fieldGroup: [
        {
          key: 'warehouse',
          type: 'gt-ui-select',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('Warehouse'),
            placeholder: this.gettext('Choose warehouse'),
            resource: 'logistics.warehouse',
            hint: this.gettext('custom status'),
            queryParams: () => ({ is_group: '0' }),
          },
        },
        {
          key: 'commodity',
          type: 'gt-ui-select',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('Commodity'),
            placeholder: this.gettext('Choose commodity'),
            resource: 'crops.Crop',
          },
        },
        {
          key: 'stock_reserve_unit',
          type: 'gt-ui-select',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('Stock Reserve Unit'),
            placeholder: this.gettext('Choose Reserve Unit'),
            resource: 'logistics.stockreserveunit',
          },
        },
        {
          key: 'contract_position',
          type: 'gt-ui-select',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('Contract position'),
            placeholder: this.gettext('Choose contract position'),
            resource: 'contracts.PurchaseContract',
          },
        },
        {
          key: 'batch',
          type: 'gt-ui-select',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('Batch'),
            placeholder: this.gettext('Choose batch'),
            resource: 'warehouses.recorderreference',
            hint: this.gettext('batch'),
            queryParams: () => ({
              is_batch: true,
              commodity: position.commodity,
              contract_position: position.contract_position,
              warehouse: position.warehouse,
            }),
          },
        },
      ],
    };
    const fieldGroupMetrics = {
      className: 'form-group-container col-sm-2 col-xs-12',
      fieldGroup: [
        {
          key: 'volume_metric',
          type: 'gt-input',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('volume metric'),
            type: 'number',
          },
        },
        {
          key: 'amount_metric',
          type: 'gt-input',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('amount metric'),
            type: 'number',
          },
        },
        {
          key: 'total_amount_metric',
          type: 'gt-input',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('total amount _metric'),
            type: 'number',
            addon: this.gettext('t'),
          },
        },
      ],
    };
    const fieldGroupResources = {
      className: 'form-group-container col-sm-2 col-xs-12',
      fieldGroup: [
        {
          key: 'distribution_base',
          type: 'gt-input',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('distribution base'),
            type: 'number',
            disabled: true,
          },
        },
        {
          key: 'costs_amount',
          type: 'gt-input',
          className: fieldClass,
          templateOptions: {
            label: this.gettext('Amount'),
            type: 'number',
          },
        },
      ],
    };
    return {
      formName: formName,
      fieldsDef: [
        {
          fieldGroup: [
            fieldGroupInfo,
            fieldGroupParams,
            fieldGroupDimensions,
            fieldGroupMetrics,
            fieldGroupResources,
          ],
        },
      ],
    };
  }

  getPositionFields(position: any, formName: string) {
    return this.FormFieldParamsService.getFields(this.getPositionFormConfig(position, formName));
  }
}
TransportationStorageExpenseFormFieldsService.$inject = [
  'gettext',
  'FormFieldParamsService',
  'FinancesService',
];
