import type ng from 'angular';

import type { ContractsService } from '../../contracts/legacy/contracts.srv';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { html } from '^/shared/utils';

export const MarketUserVolumesGrid = {
  bindings: {
    queryParams: '<?',
    filterLevel: '<?',
  },
  template: html`<gt-table
    options="$ctrl.tableOptions"
    filter-level="$ctrl.filterLevel"
  ></gt-table>`,
  controller: [
    '$rootScope',
    'ContractsService',
    'gettext',
    'gtFilterService',
    class {
      $rootScope: GtRootScopeService;
      ContractsService: ContractsService;
      filterLevel = 'market-user-volumes';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      tableApi: any;
      tableOptions: any;
      constructor(
        $rootScope: GtRootScopeService,
        ContractsService: ContractsService,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
      ) {
        this.$rootScope = $rootScope;
        this.ContractsService = ContractsService;
        this.gettext = gettext;
        this.tableOptions = this.getTableOptions();
        this.tableApi = undefined;
        this.gtFilterService = gtFilterService;
      }

      $onInit() {
        this.queryParams = this.gtFilterService.getQueryParams(this.filterLevel);
        this.gtFilterService.subscribe(this.filterLevel, (params) => {
          this.queryParams = params;
          this.updateData();
        });
        this.updateData();
      }

      updateData() {
        const params = { ...this.queryParams };
        delete params.page;
        delete params.serializer;
        delete params.stage;
        delete params.contract_type;
        delete params.min_price;
        return this.ContractsService.PurchasePlanVolume.volumePerUsers(params, (items: any) => {
          this.ContractsService.PurchasePlanVolume.purchasePlanVolumeTotal((purchasePlans: any) => {
            this.tableApi.setRowData(items);
            this.tableApi.setRowCount(items.length);
            // set purchase_volume
            items.forEach((item: any) => {
              purchasePlans.forEach((purchasePlan: any) => {
                if (item.user_id == purchasePlan.user_id) {
                  item.purchase_volume = purchasePlan.purchase_volume;
                }
              });
            });
          });
        });
      }

      getTableOptions() {
        const options: any = {
          tableName: 'market-user-volumes',
          filterLevel: this.filterLevel,
          columnDefs: [
            {
              columnName: 'author_first_name',
              cellTemplate: html`
                <span class="btn-link">
                  {[{ item.author_last_name }]} {[{ item.author_first_name }]}
                  <span ng-if="!item.author_first_name && !item.author_last_name">
                    {[{ item.author_username }]}
                  </span>
                </span>
              `,
              title: this.gettext('Title'),
            },
            {
              columnName: 'purchase_volume',
              cellTemplate: html`
                <div class="progress">
                  <div
                    class="progress-bar progress_bar_default"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="width:
                {[{item.volume_total / (item.purchase_volume || 1) * 100 | number:2 }]}%"
                  >
                    {[{item.volume_total || 0 | number:3 }]} / {[{item.purchase_volume || 0 |
                    number:3 }]}
                  </div>
                </div>
              `,
              title: this.gettext('Total'),
            },
            {
              columnName: 'purchase_volume',
              cellTemplate: html`
              <span>
                {[{item.volume_new || 0 | gtDecimal:3}]}
                <span class="clearfix"></div>
                <span class="smaller-label label-opacity" ng-if="item.volume_new && item.volume_total">
                  {[{item.volume_new / (item.volume_total || 1) * 100 || 0 | number:2}]}%
                </span>
              </span>
          `,
              title: this.gettext('New'),
            },
          ],
          rowData: [],
          onInit: (api: any) => (this.tableApi = api),
        };

        if (this.$rootScope.user.settings.MARKET_PURCHASE_PLANS == 'indicators') {
          options.columnDefs.push({
            columnName: 'purchase_volume',
            cellTemplate: html`
            <span>
              {[{item.volume_approved || 0 | gtDecimal:3}]}
              <span class="clearfix"></div>
              <span class="smaller-label label-opacity" ng-if="item.volume_approved && item.volume_total">
                {[{item.volume_approved / (item.volume_total || 1) * 100 || 0 | number:2}]}%
              </span>
            </span>
        `,
            title: this.gettext('Approved'),
          });
        } else {
          options.columnDefs.push({
            columnName: 'purchase_volume',
            cellTemplate: html`
            <span>
              {[{item.volume_shipment || 0 | gtDecimal:3}]}
              <span class="clearfix"></div>
              <span class="smaller-label label-opacity" ng-if="item.volume_shipment && item.volume_total">
                {[{item.volume_shipment / (item.volume_total || 1) * 100 || 0 | number:2}]}%
              </span>
            </span>
        `,
            title: this.gettext('Shipment'),
          });
        }

        options.columnDefs.push(
          {
            columnName: 'purchase_volume',
            cellTemplate: html`
            <span>
              {[{item.volume_executed || 0 | gtDecimal:3}]}
              <span class="clearfix"></div>
              <span class="smaller-label label-opacity" ng-if="item.volume_executed && item.volume_total">
                {[{item.volume_executed / (item.volume_total || 1) * 100 || 0 | number:2}]}%
              </span>
            </span>
        `,
            title: this.gettext('Executed'),
          },
          {
            columnName: 'purchase_volume',
            cellTemplate: html`
            <span>
              {[{item.volume_rejected|| 0 | gtDecimal:3}]}
              <span class="clearfix"></div>
              <span class="smaller-label label-opacity" ng-if="item.volume_rejected && item.volume_total">
                {[{item.volume_rejected / (item.volume_total || 1) * 100 || 0 | number:2}]}%
              </span>
            </span>
        `,
            title: this.gettext('Cancel'),
          },
        );

        return options;
      }
    },
  ],
};
