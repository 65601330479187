import type ng from 'angular';
export class ControlsService {
  $http: ng.IHttpService;
  $resource: ng.resource.IResourceService;
  controlResource: any;
  constructor($resource: ng.resource.IResourceService, $http: ng.IHttpService) {
    this.$resource = $resource;
    this.$http = $http;
    this.controlResource = $resource('/api/core/control/:id/', { id: '@id' });
  }

  getControls(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/core/control/',
      params: params,
    }).then((data: any) => data.data);
  }

  getControlConfig(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/core/control-config/',
      params: params,
    }).then((data: any) => data.data);
  }

  saveControl(item: any) {
    return this.controlResource.save(item).$promise;
  }

  getNewControl(configId: number) {
    return {
      config: configId,
    };
  }
}
ControlsService.$inject = ['$resource', '$http'];
