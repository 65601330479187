import ng from 'angular';

import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive('contractsContractItemDashboardTab', directive);

  function directive() {
    return {
      template: require('./dashboard-tab.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        contract: '<',
        mode: '=?',
        contractMode: '@?',
        openEmailModal: '&?',
        editContract: '&?',
        openDocxModal: '&?',
        reverse: '&?',
        createPassport: '&?',
        connectToPassport: '&?',
        openRoleDetails: '&?',
        changeTab: '&?',
        approve: '&?',
        hideWizard: '&?',
        onlyExecution: '<?',
        onlyClosing: '<?',
        needToRefresh: '<?',
        onUpdateData: '&?',
      },
    };
  }

  Controller.$inject = ['$uibModal', 'ContractsService', 'ClientsService', 'DocumentsService'];

  function Controller(
    this: any,
    $uibModal: ng.ui.bootstrap.IModalService,
    ContractsService: ContractsService,
    ClientsService: any,
    DocumentsService: any,
  ) {
    const vm = this;

    vm.getStageBySlug = getStageBySlug;
    vm.editStage = editStage;
    vm.getStatusClassIcon = getStatusClassIcon;
    vm.getStatusClassLabel = getStatusClassLabel;
    vm.mode = vm.mode || 'full';
    vm.needToRefresh = false;

    vm.openPassportModal = openPassportModal;
    vm.openDocumentModal = openDocumentModal;
    vm.openRoleModal = openRoleModal;

    vm.stageTemplateMapper = {
      signature: require('../../stage-edit/stage-signature-edit.tpl.html?raw'),
      'contract-executed': require('../../stage-edit/stage-contract-executed-edit.tpl.html?raw'),
      'supplier-approval': require('../../stage-edit/stage-supplier-approval-edit.tpl.html?raw'),
    };

    vm.$onInit = function () {
      if (vm.needToRefresh) {
        updateData();
      }
    };

    ////////////////

    function updateData() {
      if (vm.needToRefresh) {
        return ContractsService.refreshContract(vm.contract, true).then(function (data: any) {
          vm.contract = data;
        });
      } else {
        vm.onUpdateData();
      }
    }

    function getStageBySlug(slug: any) {
      return ContractsService.getStageBySlug(vm.contract.stages, slug);
    }

    function getStatusClassIcon(slug: any) {
      return ContractsService.getStatusClassIcon(vm.contract.stages, slug);
    }

    function getStatusClassLabel(slug: any) {
      return ContractsService.getStatusClassLabel(vm.contract.stages, slug);
    }

    function openDocumentModal(this: any, contract: any) {
      return DocumentsService.documentListModal({
        model_name: 'ContractBase',
        object_id: contract.id,
      }).then(() => this.updateData());
    }

    function openRoleModal(id: number, model: any) {
      if (!id) {
        return;
      }
      return ClientsService.roleModal({ id: id, model_name: model }).then(() => updateData());
    }

    function openPassportModal(passport: any) {
      return ContractsService.passportModal(passport);
    }

    function editStage(stageSlug: any) {
      const stage = getStageBySlug(stageSlug);
      const template = vm.stageTemplateMapper[stageSlug];

      $uibModal
        .open({
          template,
          controller: 'StageEditController as vm',
          windowClass: 'modal-template modal-template-half-width',
          resolve: {
            stage: () => {
              return { ...stage };
            },
          },
        })
        .result.then(function () {
          updateData();
        });
    }
  }
})();
