import type ng from 'angular';

import template from './warehouse-balance-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const WarehouseBalanceContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    readonly: '<?',
    showParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'gettext',
    'GtUtils',
    'gtFilterService',
    'WarehouseMovementsService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      WarehouseMovementsService: any;
      _queryParams: QueryParams = {};
      balanceData: any;
      balanceRequest: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      groupChoices: any;
      gtFilterService: GtFilterService;
      hideFilters: any;
      horizontalGroups: any;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      readonly: any;
      selectedGroups: any;
      showParams: any;
      filtersSelected: boolean;
      unselectedGroups: any;
      verticalGroups: any;
      visibilityParams: any;
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        WarehouseMovementsService: any,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.WarehouseMovementsService = WarehouseMovementsService;

        this.balanceData = {};
        this.filterLevel = '';
        this.hideFilters = false;

        this.initQueryParams = {};
        this._queryParams = {};
        this.queryParams = {};
        this.balanceRequest = undefined;

        this.groupChoices = [
          { param: 'business_unit', title: this.gettext('Business unit') },
          { param: 'commodity', title: this.gettext('Commodity') },
          { param: 'contract_position', title: this.gettext('Purchase contract') },
          { param: 'batch', title: this.gettext('Batch') },
          { param: 'warehouse', title: this.gettext('Warehouse') },
          { param: 'port', title: this.gettext('Port') },
          { param: 'terminal', title: this.gettext('Terminal') },
          { param: 'warehouse_parent', title: this.gettext('Warehouse parent') },
          { param: 'country', title: this.gettext('Country') },
          { param: 'district', title: this.gettext('District') },
          { param: 'region', title: this.gettext('Region') },
          { param: 'city', title: this.gettext('City') },
          { param: 'owner', title: this.gettext('Batch owner') },
          { param: 'producer', title: this.gettext('Producer') },
          { param: 'receiver', title: this.gettext('Receiver') },
          { param: 'warehouse_type', title: this.gettext('Warehouse type') },
          { param: 'contract_position_company', title: this.gettext('Purchase contract owner') },
          { param: 'contract_position_client', title: this.gettext('Purchase contract client') },
          { param: 'stock_reserve_unit', title: this.gettext('Stock Reserve Unit') },
          { param: 'reserve_passport', title: this.gettext('Reserve Passport') },
          { param: 'reserve_passport_voyage', title: this.gettext('Reserve Passport Voyage') },
          { param: 'reserve_passport_vessel', title: this.gettext('Reserve Passport Vessel') },
          { param: 'reserve_client', title: this.gettext('Reserve Client') },
          { param: 'reserve_contract_position', title: this.gettext('Reserve Contract') },
          { param: 'batch_voyage', title: this.gettext('Batch Voyage') },
          { param: 'batch_vessel', title: this.gettext('Batch Vessel') },
        ];
        this.unselectedGroups = [];
        this.selectedGroups = [];
        this.verticalGroups = [];
        this.horizontalGroups = [];
        this.visibilityParams = ['verticalGroups', 'horizontalGroups', 'aggregatesView'];
        this.showParams = false;
        this.filtersSelected = false;
      }
      $onInit() {
        this.queryParams = { page_size: 20, ...this.initQueryParams };
        this.convertQueryParams();
        this.filterLevel = this.filterLevel || 'warehouse-balance-container';
        this.readonly = this.readonly || false;
        this.initGroups();
        this.gtFilterService.setQueryParams(this.prepareParams(), this.filterLevel);
        this.$scope.$on(
          'gt-filter-updated_' + this.filterLevel,
          (ev: any, queryParams: QueryParams) => this.onFilterUpdate(queryParams),
        );
      }
      convertQueryParams() {
        if (!this.queryParams.verticalGroups) {
          this.queryParams.verticalGroups = [];
        }
        if (!this.queryParams.horizontalGroups) {
          this.queryParams.horizontalGroups = [];
        }
        if (!Array.isArray(this.queryParams.verticalGroups)) {
          this.queryParams.verticalGroups = [this.queryParams.verticalGroups];
        }
        if (!Array.isArray(this.queryParams.horizontalGroups)) {
          this.queryParams.horizontalGroups = [this.queryParams.horizontalGroups];
        }
      }
      initGroups() {
        this.verticalGroups = [];
        this.horizontalGroups = [];
        this.unselectedGroups = [...this.groupChoices];
        this.convertQueryParams();
        ((this.queryParams.verticalGroups as string[] | undefined) ?? [])
          .filter((groupName: string) =>
            this.groupChoices.map((ch: any) => ch.param).includes(groupName),
          )
          .map(
            (groupName: string) => this.groupChoices.filter((ch: any) => ch.param === groupName)[0],
          )
          .forEach((group: string) => this.selectVerticalGroup(group));
        (this.queryParams.horizontalGroups as string[])
          .filter((groupName: string) =>
            this.groupChoices.map((ch: any) => ch.param).includes(groupName),
          )
          .map(
            (groupName: string) => this.groupChoices.filter((ch: any) => ch.param === groupName)[0],
          )
          .forEach((group: string) => this.selectHorizontalGroup(group));
      }
      excludeVisibilityParams() {
        return Object.keys(this.queryParams)
          .filter((key) => !this.visibilityParams.includes(key))
          .reduce((result: any, key) => {
            result[key] = this.queryParams[key];
            return result;
          }, {});
      }
      checkNeedUpdateData() {
        if (!this.balanceData.length) {
          return true;
        }
        if (
          JSON.stringify((this as any).excludeVisibilityParams()) !==
          JSON.stringify((this as any)._queryParams)
        ) {
          (this as any)._queryParams = (this as any).excludeVisibilityParams();
          return true;
        }
        return false;
      }

      onFilterUpdate(queryParams: QueryParams) {
        this.queryParams = queryParams;
        this.initGroups();
        if (this.checkNeedUpdateData()) {
          this.updateData();
        }
      }
      prepareParams() {
        return {
          ...this.queryParams,
          verticalGroups: this.verticalGroups.map((g: any) => g.param),
          horizontalGroups: this.horizontalGroups.map((g: any) => g.param),
          groups: [...this.verticalGroups, ...this.horizontalGroups]
            .map((g) => g.param)
            .sort((a, b) => a.localeCompare(b)),
        };
      }
      updateData() {
        this.GtUtils.overlay('show');
        this.filtersSelected = true;
        this.cancelIfUpdating();
        this.balanceRequest = this.WarehouseMovementsService.getBalances(this.prepareParams());
        return this.balanceRequest.$promise
          .then((data: any) => {
            this.balanceData = this.WarehouseMovementsService.prepareBalancesData(data);
          })
          .finally(() => {
            this.balanceRequest = undefined;
            this.GtUtils.overlay('hide');
          });
      }
      cancelIfUpdating() {
        if (this.balanceRequest?.$cancelRequest) {
          this.balanceRequest.$cancelRequest();
        }
      }
      updateGrouping() {
        this.gtFilterService.updateQueryParams(this.prepareParams(), this.filterLevel);
      }
      selectVerticalGroup(group: any) {
        this.verticalGroups.push(group);
        this.unselectedGroups.splice(this.unselectedGroups.indexOf(group), 1);
      }
      selectHorizontalGroup(group: any) {
        this.horizontalGroups.push(group);
        this.unselectedGroups.splice(this.unselectedGroups.indexOf(group), 1);
      }
      unselectGroup(group: any) {
        this.unselectedGroups.push(group);
        if (this.horizontalGroups.includes(group)) {
          this.horizontalGroups.splice(this.horizontalGroups.indexOf(group), 1);
        }
        if (this.verticalGroups.includes(group)) {
          this.verticalGroups.splice(this.verticalGroups.indexOf(group), 1);
        }
      }
    },
  ],
};
