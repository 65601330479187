import { from, shareReplay, switchMap } from 'rxjs';

import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import {
  ContractSerializer,
  PurchaseContractPatch,
  PurchaseContractPost,
  PurchaseContractRecord,
  PurchaseContractsQueryParams,
} from '~/features/deals/contracts';
import { PurchaseContractsRepository } from '~/features/deals/contracts/purchase/services/purchase-contracts.repository';
import { PurchaseContract } from '~/shared/api';
import { injectable } from '~/shared/lib/di';

export type PurchaseContractsListContainerProps = ContainerProps<PurchaseContractsQueryParams>;

@injectable()
export class PurchaseContractsListViewModel extends EntityListContainerModel<
  PurchaseContract,
  PurchaseContractsQueryParams,
  PurchaseContractsRepository,
  PurchaseContractsListContainerProps,
  PurchaseContractRecord,
  PurchaseContractPost,
  PurchaseContractPatch
> {
  entityRepoClass = PurchaseContractsRepository;

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.repo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  public tableChanged(table: ContractSerializer) {
    this.repo.setDatasources(table);
  }
}
