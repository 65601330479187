import {
  BillOfLadingPatch,
  BillOfLadingPost,
  BillOfLadingQueryParams,
  BillOfLadingRecord,
} from '~/features/execution/bills-of-lading';
import {
  BillOfLading,
  logisticsBillsOfLadingCreate,
  logisticsBillsOfLadingDestroy,
  logisticsBillsOfLadingListInfoList,
  logisticsBillsOfLadingListTotalsRetrieve,
  logisticsBillsOfLadingPartialUpdate,
  logisticsBillsOfLadingRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';
import { EntityOption } from '~/shared/ui/data-types';

@singleton()
export class BillsOfLadingRepository extends EntityRepository<
  BillOfLading,
  BillOfLadingQueryParams,
  BillOfLadingRecord,
  BillOfLadingPost,
  BillOfLadingPatch
> {
  readonly entityName = 'logistics.BillOfLading';

  getFn = async (id: number) => {
    const { data } = await logisticsBillsOfLadingRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: BillOfLadingQueryParams) => {
    const { data } = await logisticsBillsOfLadingListInfoList({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  createFn = async (dto: BillOfLadingPost) => {
    const res = await logisticsBillsOfLadingCreate({
      body: { ...dto },
    });

    return res.data;
  };

  updateFn = async (entity: BillOfLadingPatch) => {
    const { data } = await logisticsBillsOfLadingPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  deleteFn = async (id: number) => {
    await logisticsBillsOfLadingDestroy({ path: { id } });
  };

  getTotals = async (query: BillOfLadingQueryParams) => {
    const { data } = await logisticsBillsOfLadingListTotalsRetrieve({ query });
    return data;
  };
}
