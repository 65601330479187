import ng from 'angular';

import type { CoreService } from '^/app/core/core.service';

(function () {
  'use strict';
  ng.module('core.legacy').component('gtExportButton', {
    bindings: {
      filterLevel: '<',
      resource: '<',
      title: '<?',
      extraData: '<?',
      disabled: '<?',
    },
    template: require('./gt-export-button.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['CoreService'];

  function Controller(this: any, CoreService: CoreService) {
    const vm = this;
    vm.openGtExportModal = openGtExportModal;

    ////////////////

    function openGtExportModal(
      filterLevel: string,
      resource: any,
      extraData: Record<string, string> = {},
    ) {
      return CoreService.gtExportModal(filterLevel, resource, extraData);
    }
  }
})();
