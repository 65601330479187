import ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';
import { notify } from '~/shared/lib/notify';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { RequestFormFieldsService } from '^/app/deals/requests/request-form-field.service';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('deals.requests.legacy').controller('RequestsRequestModalController', Controller);

  Controller.$inject = [
    '$scope',
    '$rootScope',
    '$uibModalInstance',
    '$q',
    'request',
    'extraArgs',
    'priceWidgetFields',
    'ClientsService',
    'GtUtils',
    'ContractsService',
    'FinancesService',
    'gettext',
    'FormFieldParamsService',
    'RequestFormFieldsService',
    'LogisticsService',
    '$state',
    '$timeout',
    'CoreService',
    'StockExchangesService',
    'CustomValuesService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $q: ng.IQService,
    request: any,
    extraArgs: any,
    priceWidgetFields: any,
    ClientsService: any,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    FinancesService: FinancesService,
    gettext: ng.gettext.gettextFunction,
    FormFieldParamsService: FormFieldParamsService,
    RequestFormFieldsService: RequestFormFieldsService,
    LogisticsService: LogisticsService,
    $state: ng.ui.IStateService,
    $timeout: ng.ITimeoutService,
    CoreService: CoreService,
    StockExchangesService: any,
    CustomValuesService: CustomValuesService,
  ) {
    const vm = this;

    vm.request = request || {};
    vm.extraArgs = extraArgs || {};
    vm.priceWidgetFields = priceWidgetFields || [];
    vm.tab = 'edit';
    vm.save = save;
    vm.destroy = destroy;
    vm.close = close;
    vm.clone = clone;
    vm.form = undefined;
    vm.fields = undefined;
    vm.updating = true;
    vm.openFieldsConfigModal = openFieldsConfigModal;
    vm.getFlatFields = getFlatFields;
    vm.measurementPriceMutex = false;
    vm.initMeasurementPrice = false;
    vm.initMeasurementPriceKg = false;

    activate();

    ////////////////
    function activate() {
      let when;
      if (vm.request.id) {
        when = updateData();
      } else {
        if (
          $rootScope.user.settings.USE_LOCAL_CURRENCY_FOR_PURCHASE &&
          vm.request.contract_type == 'purchase' &&
          !vm.extraArgs.clone &&
          !vm.request.currency_exchange
        ) {
          updateUahRate().then((data: any) => {
            if (data.count) {
              vm.request.currency = data.results[0].local_currency;
              vm.request.currency_exchange = data.results[0].id;
            }
            vm.updating = false;
          });
        }
        setRequestMainLoyaltyProgram();
        vm.request.currency_opposite = vm.request.currency;
        $scope.$watch('vm.request.contract_type', function (newValue: any, oldValue: any) {
          if (!oldValue || oldValue === newValue) {
            return;
          }
          vm.request.buyer = null;
          vm.request.supplier = null;
        });
        $scope.$watch('vm.request.buyer', function (ev: any) {
          if (ev && vm.request.contract_type === 'sale') {
            changeConditions(ev, 'Buyer');
          }
        });
        $scope.$watch('vm.request.supplier', function (ev: any) {
          if (ev && vm.request.contract_type === 'purchase') {
            changeConditions(ev, 'Supplier');
          }
        });
        when = $q.when();
      }

      $scope.$watch('vm.request.price', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          setPriceWoVat();
        }
      });
      $scope.$watch('vm.request.VAT_option', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          setPriceWoVat();
        }
      });
      $scope.$watch('vm.request.VAT_value', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          setPriceWoVat();
        }
      });
      $scope.$watch('vm.request.volume', getToleranceForVolume);

      if (vm.request.stage === 'indicator') {
        $scope.$on('custom-values-updated__indicator', function (event: any, data: any) {
          vm.customValues = data;
        });
      }
      if (vm.request.stage === 'ticket') {
        $scope.$on('custom-values-updated__request', function (event: any, data: any) {
          vm.customValues = data;
        });
      }

      when.then(() => {
        $scope.$watchGroup(
          [
            'vm.request.measurement',
            'vm.request.cargo',
            'vm.request.quantity',
            'vm.request.measurement_price',
          ],
          () => {
            setPriceByMeasurementPrice();
            vm.initMeasurementPrice = true;
          },
        );
        $scope.$watchGroup(
          [
            'vm.request.measurement',
            'vm.request.cargo',
            'vm.request.quantity',
            'vm.request.measurement_price_kg',
          ],
          () => {
            setPriceByMeasurementPriceKg();
            vm.initMeasurementPriceKg = true;
          },
        );
        $scope.$watch('vm.request.volume', setContractPricesVolume);
        $scope.$watch('vm.request.cargo', (newVal, oldVal) => {
          if (newVal !== oldVal) {
            ContractsService.setVatValue(vm.request);
            setContractPricesCargo();
          }
        });
        $scope.$watch('vm.request.VAT_value', (newVal, oldVal) => {
          if (newVal !== oldVal) {
            getCalculatedAmount();
          }
        });
        $scope.$watch('vm.request.VAT_option', (newVal, oldVal) => {
          if (newVal !== oldVal) {
            ContractsService.setVatValue(vm.request);
            getCalculatedAmount();
          }
        });
        $scope.$watch('vm.request.contract_type', (newVal: any, oldVal: any) => {
          if (newVal !== oldVal) {
            updateFields();
            setRequestMainLoyaltyProgram();
          }
        });
        $scope.$watch('vm.request.volume', getCalculatedAmount);
        $scope.$watch('vm.request.price', getCalculatedAmount);
        $scope.$watch('vm.request.contract_option', getContractOptionType);
        $scope.$watch('vm.request.currency', onCurrencyChange);
        $scope.$watch('vm.request.is_position_closing', setApprovalToNull);
        $scope.$watch('vm.request.general_agreement', onGeneralAgreementChange);
        $scope.$watch('vm.request.basis', getPremiumBonusPercentage);
        $scope.$watch('vm.request.auto_name', getContractNumber);
        $scope.$watch('vm.request.business_unit', (newVal: any, oldVal: any) => {
          if (newVal !== oldVal) {
            updateFields();
            clearApprovalConfig();
          }
        });
        $scope.$watch('vm.request.deal_type', (newVal: any, oldVal: any) => {
          if (newVal !== oldVal) {
            updateFields();
          }
        });

        if (vm.request.contract_type === 'purchase') {
          $scope.$watch('vm.request.supplier', function (newVal: any, oldVal: any) {
            if (newVal !== oldVal) {
              vm.request.general_agreement = undefined;
            }
          });
        } else if (vm.request.contract_type === 'sale') {
          $scope.$watch('vm.request.buyer', function (newVal: any, oldVal: any) {
            if (newVal !== oldVal) {
              vm.request.general_agreement = undefined;
            }
          });
        }

        ContractsService.getConclusionDateRange(vm.request).then((dataRange: any) => {
          vm.request.conclusionDateRange = dataRange;
          return updateFields();
        });
      });
    }

    function setPriceByMeasurementPriceKg() {
      if (!vm.initMeasurementPriceKg && vm.request.contract_number) {
        vm.initMeasurementPriceKg = true;
        return null;
      }
      const { measurement, cargo, quantity, measurement_price_kg: measurementPriceKg } = vm.request;

      if ([measurement, cargo, quantity, measurementPriceKg].some((value) => !value)) {
        return null;
      }

      vm.measurementPriceMutex = true;

      StockExchangesService.getVolumeByMeasurementQuantity(
        vm.request.measurement,
        vm.request.cargo,
        vm.request.quantity,
      )
        .then((volumeNew: any) => {
          vm.request.volume = volumeNew;
          StockExchangesService.getPriceByMeasurementPriceKg(
            vm.request.measurement,
            vm.request.cargo,
            measurementPriceKg,
            vm.request.volume,
            vm.request.quantity,
          ).then(({ measurementPrice, price }: any) => {
            vm.request.price = price;
            vm.request.measurement_price = measurementPrice;
          });
        })
        .catch((error: any) => {
          console.error('Error in setPriceByMeasurementPriceKg:', error);
        });
    }

    function setPriceByMeasurementPrice() {
      if (!vm.initMeasurementPrice && vm.request.contract_number) {
        vm.initMeasurementPrice = true;
        return null;
      }
      if (vm.measurementPriceMutex) {
        vm.measurementPriceMutex = false;
        return null;
      }
      const { measurement, cargo, quantity, measurement_price: measurementPrice } = vm.request;

      if ([measurement, cargo, quantity, measurementPrice].some((value) => !value)) {
        return null;
      }

      const resetPriceKg = vm.initMeasurementPrice;

      StockExchangesService.getVolumeByMeasurementQuantity(
        vm.request.measurement,
        vm.request.cargo,
        vm.request.quantity,
      )
        .then((volumeNew: any) => {
          vm.request.volume = volumeNew;
          StockExchangesService.getPriceByMeasurementPrice(
            vm.request.measurement,
            vm.request.cargo,
            measurementPrice,
          ).then((priceNew: any) => {
            vm.request.price = priceNew;
            if (resetPriceKg) {
              vm.request.measurement_price_kg = null;
            }
          });
        })
        .catch((error: any) => {
          console.error('Error in setPriceByMeasurementPrice:', error);
        });
    }

    function setPriceWoVat() {
      if (vm.request.price && vm.request.VAT_option) {
        FinancesService.applyVat({
          value: vm.request.price,
          volume: 1,
          vat_value: vm.request.VAT_value,
          vat_type: 'without',
        })
          .then((response) => {
            vm.request._priceWoVat = response;
            return FinancesService.applyVat({
              value: vm.request.price,
              volume: 1,
              vat_value: vm.request.VAT_value,
              vat_type: 'vat',
            });
          })
          .then((response) => {
            vm.request._priceVatValue = response;
          })
          .catch(errorHandler);
      }
    }

    function onCurrencyChange() {
      ContractsService.setCurrencyOwner(vm.request);
      ContractsService.setCurrencyExchange(vm.request);
    }

    function getContractNumber() {
      return ContractsService.getContractNumber(vm.request).then(
        (data: any) => {
          vm.request.contract_number = data.contract_number;
        },
        (error: any) => _error(error),
      );
    }

    function onGeneralAgreementChange() {
      $scope.$broadcast('general-agreement__updated', vm.request.general_agreement);
      ContractsService.getGeneralAgreement(vm.request);
    }

    function getCalculatedAmount() {
      ContractsService.getRequestCalculatedAmount(vm.request);
    }

    function getContractOptionType() {
      ContractsService.ContractOptions.get({ id: vm.request.contract_option }).$promise.then(
        (data: any) => {
          vm.request.contract_option_program_type = data.program_type;
        },
      );
    }

    function setApprovalToNull() {
      ContractsService.setApprovalToNull(vm.request);
    }

    function getPremiumBonusPercentage() {
      LogisticsService.Basis.get({ id: vm.request.basis }).$promise.then((basis: any) => {
        vm.request.premium_bonus_percentage = basis.premium_bonus_percentage;
      });
    }

    function changeConditions(roleId: number, roleName: string) {
      ClientsService[roleName].get(
        {
          id: roleId,
          fields: ['payment_conditions', 'payment_conditions_auto', 'payment_conditions_option'],
        },
        function (data: any) {
          vm.request.payment_conditions = vm.request.payment_conditions || data.payment_conditions;
          vm.request.payment_conditions_auto =
            vm.request.payment_conditions_auto || data.payment_conditions_auto;
          vm.request.payment_conditions_option =
            vm.request.payment_conditions_option || data.payment_conditions_option;
        },
      );
    }

    function setContractPricesVolume() {
      $scope.$broadcast('contract-price-volume__updated', vm.request.volume);
    }

    function setContractPricesCargo() {
      $scope.$broadcast('contract-price-cargo__updated', vm.request.cargo);
    }

    function updateUahRate() {
      return FinancesService.CurrencyExchange.query({
        currency: GtUtils.getLocalCurrency(),
      }).$promise;
    }

    function updateData() {
      vm.updating = true;

      return ContractsService.getRequest({
        id: vm.request.id,
        serializer: 'modal',
      }).then(function (data: any) {
        return ContractsService.getRequestCalculatedAmount(data).then(() => {
          vm.updating = false;
          vm.request = data;
          getFormConfig();
        });
      });
    }

    function setRequestMainLoyaltyProgram() {
      if (
        $rootScope.user.settings.CONTRACT_OPTION_MODEL &&
        vm.request.contract_type === 'purchase' &&
        !vm.request.contract_option
      ) {
        ContractsService.getMainLoyaltyProgram().then((programId: number) => {
          vm.request.contract_option = programId;
        });
      }
    }

    function openFieldsConfigModal() {
      FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(updateFields);
    }

    function updateFields() {
      vm.updating = true;

      return updatePriceWidgetFields()
        .then(() => {
          return FormFieldParamsService.getFields(getFormConfig(), vm.request.business_unit).then(
            (fields: any) => {
              if (!vm.fields) {
                vm.fields = fields;
              } else {
                const oldLength = vm.fields.length;
                vm.fields.push(...fields);
                $timeout(() => vm.fields.splice(0, oldLength), 100);
              }
              vm.fieldsTemplateOptions = getFlatFields(fields);
            },
          );
        })
        .finally(() => {
          vm.updating = false;
        });
    }

    function getToleranceForVolume() {
      if (vm.request.volume === undefined || vm.request.volume === null) {
        return;
      }

      return CoreService.getRangeRuleValue(
        vm.request.stage === 'indicator' ? 'indicator.tolerance' : 'ticket.tolerance',
        vm.request.volume,
      ).then(
        (data: any) => {
          if (
            data.value &&
            (vm.request.volume_options === undefined || vm.request.volume_options === 0)
          ) {
            vm.request.volume_options = data.value;
          }
        },
        (error: any) => _error(error),
      );
    }

    function getFlatFields(fields: any) {
      return FormFieldParamsService.getFlatFields({ fieldsDef: fields }).map((field: any) => ({
        ...field.templateOptions,
        key: field.key,
      }));
    }

    function clone() {
      return ContractsService.cloneRequest(vm.request);
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function destroy() {
      const msg = gettext('Do you want delete this Request?');
      if (!confirm(msg)) {
        return;
      }
      ContractsService.Request.delete({ id: vm.request.id }, function () {
        notify(gettext('Request removed'));
        close('delete', true);
      });
    }

    function save(stayAfter: any) {
      vm.form.$invalid = true;
      vm.request.errors = {};

      if (vm.request.contract_type == 'sale') {
        vm.request.owner = vm.request.supplier;
      }

      if (vm.request.contract_type == 'purchase') {
        vm.request.owner = vm.request.buyer;
      }

      if (vm.request.id) {
        return ContractsService.Request.update(
          vm.request,
          function (data: any) {
            if (!stayAfter) {
              close(data, true);
            } else {
              vm.request = { id: data.id };
              updateData();
            }
            notify(gettext('Request updated'));
          },
          _error,
        ).$promise;
      }

      const afterFunction = function (data: any) {
        CustomValuesService.createCustomValues(data.id, vm.customValues);

        if (!stayAfter) {
          close(data, true);
        } else {
          vm.request = { id: data.id };
          updateData();
          close(data, true);
          $state.go('gt.page.request', { id: vm.request.id });
        }
        notify(gettext('Request saved'));
      };

      if (vm.extraArgs.createTicketFromIndicator) {
        return ContractsService.Request.createTicket(
          {
            id: request.from_request,
            bite: request.volume,
          },
          () => '',
          _error,
        ).$promise.then((ticket: any) => {
          vm.request.id = ticket.id;
          return ContractsService.Request.update(vm.request, afterFunction, _error).$promise;
        });
      }

      return ContractsService.Request.save(vm.request, afterFunction, _error).$promise;
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
      vm.request.errors = data.data;
    }

    function getFormConfig() {
      let config = 'request-edit-modal';
      const widgetFieldsConfig: any = {};
      if (vm.request.stage === 'indicator') {
        config = 'indicator-edit-modal';
      }
      vm.priceWidgetFields.forEach((field: any) => {
        widgetFieldsConfig[field.field_name] = {
          visible: field.visible,
          required: field.required,
        };
      });
      return RequestFormFieldsService.getFields(
        vm.request,
        [RequestFormFieldsService.getFormNamePrefix(vm.request), config].join(''),
        widgetFieldsConfig,
      );
    }

    function updatePriceWidgetFields() {
      return FormFieldParamsService.getContractPriceWidgetFields(
        [RequestFormFieldsService.getFormNamePrefix(vm.request), 'request-edit-modal'].join(''),
        vm.request.business_unit,
      ).then(function (fields: any) {
        vm.priceWidgetFields = fields;
      });
    }

    function clearApprovalConfig() {
      vm.request.approval_config = null;
    }
  }
})();
