import ng from 'angular';

import { passportDetailsItemContainer } from './item-container/passport-details-item-container.component';
import { passportDetailsPage } from './item-page/passport-details-page.component';
import { passportDetailsItemView } from './item-view/passport-details-item-view.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const passportConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('gt.page.passport', {
      url: '^/passport/:id',
      component: 'passportDetailsPage',
      resolve: {
        filterLevel: () => {
          return 'passports-page-view';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('passports-page-view');
          },
        ],
      },
      data: {
        pageTitle: gettext('Passport'),
      },
    });
  },
];

export const passportDetails = ng
  .module('deals.passports.details', [])
  .component('passportDetailsPage', passportDetailsPage)
  .component('passportDetailsItemContainer', passportDetailsItemContainer)
  .component('passportDetailsItemView', passportDetailsItemView)
  .config(passportConfig).name;
