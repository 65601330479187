import type ng from 'angular';

import template from './warehouse-shipment-list-table-view.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export const WarehouseShipmentListTableView = {
  bindings: {
    list: '<',
    count: '<',
    total: '<',
    filterLevel: '<',
    applyFilters: '&',
    onCreateCosts: '&?',
    onGetContractChargesData: '&?',
    contractChargesData: '<',
    tableName: '<?',
  },
  template,
  controller: [
    'gettext',
    'GtUtils',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      GtUtils: GtUtilsService;
      applyFilters: any;
      contractChargesData: any;
      count: number;
      filterLevel = 'warehouse-shipment-list-table-view';
      gettext: ng.gettext.gettextFunction;
      list: any;
      onGetContractChargesData: any;
      selection: any;
      tableData: any;
      tableName = 'warehouse-shipment-list-table-view';
      tableOptions: any;
      total: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        $rootScope: GtRootScopeService,
      ) {
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.$rootScope = $rootScope;

        this.list = [];
        this.count = 0;
        this.tableOptions = {};
        this.tableData = {};
        this.contractChargesData = [];
        this.selection = false;
      }

      $onInit() {
        this.list = this.list || [];
        this.count = this.count || 0;
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.list || changes.count || changes.total) {
          this.tableData = { rows: this.list, count: this.count, total: this.total };
        }

        if (changes.contractChargesData) {
          this.contractChargesData = this.contractChargesData || [];
        }
      }

      toggleSelection() {
        this.selection = !this.selection;
        this.tableOptions = this.getTableOptions();
      }

      getContractChargesData() {
        const selectedConsignment = this.list
          .filter((item: any) => item.$_selected)
          .map((item: any) => item.id);

        if (!selectedConsignment.length) {
          this.contractChargesData = [];
          return false;
        }
        this.onGetContractChargesData({
          consignments: selectedConsignment,
        });
      }

      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass: 'contract-charges-table warehouse-shipment-table',
          applyFilters: this.applyFilters,
          configurable: true,
          selection: this.selection,
          templateArgs: {
            applyFilters: () => this.applyFilters(),
            getContractChargesData: () => this.getContractChargesData(),
            $rootScope: this.$rootScope,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          selectionCellTemplate: html`<input
            ng-if="item.id && !item.is_costs_created && item.tariffs_data"
            ng-init="item.$_selected = false"
            type="checkbox"
            ng-change="args.getContractChargesData()"
            ng-model="item.$_selected"
          />`,
          columnDefs: [
            {
              columnName: 'consignment_number',
              class: 'td-left-align',
              title: this.gettext('Consignment number'),
              cellTemplate: html`
                <i class="fa fa-file"></i><span> {[{ item.consignment_number || '---' }]}</span>
              `,
            },
            {
              columnName: 'consignment_date',
              title: this.gettext('Date'),
              class: 'td-left-align',
              cellTemplate: html`
                <span>
                  <i class="fa fa-calendar"></i> {[{ item.consignment_date | date:'dd.MM.yy' ||
                  '---' }]}
                </span>
              `,
            },
            {
              columnName: 'consignment_volume',
              title: this.gettext('Volume'),
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span class="label-strong">
                  {[{ item.consignment_volume || 0 | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
            },
            {
              columnName: 'consignment_elevator_name',
              class: 'td-left-align',
              title: this.gettext('Elevator'),
              cellTemplate: html`
                <span
                  ><i class="fa fa-industry"></i> {[{ item.consignment_elevator_name || '---'
                  }]}</span
                >
              `,
            },
            {
              columnName: 'consignment_contract_number',
              title: this.gettext('Contract'),
              class: 'td-left-align',
              cellTemplate: html`
                <span>
                  <i class="fa fa-file-text"></i>
                  <a ui-sref="gt.page.contract({id: item.consignment_contract_id })">
                    {[{ item.consignment_contract_number || '---' | cut:true:30:' ' }]}
                  </a>
                </span>
              `,
            },
            {
              columnName: 'consignment_contract_commodity_title',
              class: 'td-left-align',
              title: this.gettext('Commodity'),
              cellTemplate: html`
                <span>{[{ item.consignment_contract_commodity_title || '---' }]}</span>
              `,
            },
            {
              columnName: 'logistic_vehicle_number',
              class: 'td-left-align',
              title: this.gettext('Vehicle number'),
              cellTemplate: html`
                <i class="fa fa-truck"></i
                ><span> {[{ item.logistic_vehicle_number || '---' }]}</span>
              `,
            },
            {
              columnName: 'logistic_loading_date',
              title: this.gettext('Loading date'),
              class: 'td-left-align',
              cellTemplate: html`
                <span>
                  <i class="fa fa-calendar"></i> {[{ item.logistic_loading_date | date:'dd.MM.yy' ||
                  '---' }]}
                </span>
              `,
            },
            {
              columnName: 'logistic_volume_departed',
              title: this.gettext('Volume departed'),
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span class="label-strong">
                  {[{ item.logistic_volume_departed || 0 | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
            },
            {
              columnName: 'logistic_elevator_name',
              class: 'td-left-align',
              title: this.gettext('Shipment elevator'),
              cellTemplate: html`
                <span
                  ><i class="fa fa-industry"></i> {[{ item.logistic_elevator_name || '---' }]}</span
                >
              `,
            },
            {
              columnName: 'volume',
              title: this.gettext('Volume'),
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span class="label-strong">
                  {[{ item.volume || 0 | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
              `,
            },
            {
              columnName: 'logistic_sale_contract_number',
              title: this.gettext('sale contract'),
              class: 'td-left-align',
              cellTemplate: html`
                <span>
                  <i class="fa fa-file-text"></i>
                  <a ui-sref="gt.page.contract({id: item.logistic_sale_contract_id })">
                    {[{ item.logistic_sale_contract_number || '---' | cut:true:30:' ' }]}
                  </a>
                </span>
              `,
            },
            {
              columnName: 'logistic_purchase_contract_number',
              title: this.gettext('purchase contract'),
              class: 'td-left-align',
              cellTemplate: html`
                <span>
                  <i class="fa fa-file-text"></i>
                  <a ui-sref="gt.page.contract({id: item.logistic_purchase_contract_id })">
                    {[{ item.logistic_purchase_contract_number || '---' | cut:true:30:' ' }]}
                  </a>
                </span>
              `,
            },
            {
              columnName: 'logistic_export_contract_number',
              title: this.gettext('export contract'),
              class: 'td-left-align',
              cellTemplate: html`
                <span>
                  <i class="fa fa-file-text"></i>
                  <a ui-sref="gt.page.contract({id: item.logistic_export_contract_id })">
                    {[{ item.logistic_export_contract_number || '---' | cut:true:30:' ' }]}
                  </a>
                </span>
              `,
            },
            {
              columnName: 'days',
              title: this.gettext('Days'),
              class: 'td-right-align nowrap',
              cellTemplate: html`{[{ item.days }]}`,
            },
            {
              columnName: 'charge',
              title: this.gettext('Charge'),
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <div ng-repeat="tariff in item.tariffs_data">
                  {[{ tariff.charge_title }]}
                  <div class="clearfix"></div>
                </div>
              `,
            },
            {
              columnName: 'avg_price',
              title: this.gettext('AVG price'),
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <div ng-repeat="tariff in item.tariffs_data">
                  {[{ tariff.avg_price || 0 }]} <small>{[{item.local_currency_symbol}]}</small>
                  <div class="clearfix"></div>
                </div>
              `,
            },
            {
              columnName: 'charge_amount',
              title: this.gettext('Charge amount'),
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <div ng-repeat="tariff in item.tariffs_data">
                  {[{ tariff.amount || 0 }]} <small>{[{item.local_currency_symbol}]}</small>
                  <div class="clearfix"></div>
                </div>
              `,
            },
            {
              columnName: 'charge_amount_vat',
              title: this.gettext('VAT'),
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <div ng-repeat="tariff in item.tariffs_data">
                  {[{ tariff.amount_vat || 0 }]} <small>{[{item.local_currency_symbol}]}</small>
                  <div class="clearfix"></div>
                </div>
              `,
            },
          ],
        };
      }
    },
  ],
};
