import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { forwardRef } from 'react';

import { cn } from '~/shared/lib/utils';

export const Dialog = forwardRef<
  HTMLDivElement,
  {
    open: boolean;
    onOpen: (open: boolean) => void;
    title?: string;
    // forceMount?: boolean;
    children: React.ReactNode;
    overlayVisible?: boolean;
  }
>(({ open, onOpen, title, children, overlayVisible = true }, ref) => {
  return (
    <DialogPrimitive.Root open={open} onOpenChange={onOpen}>
      <DialogPrimitive.Portal>
        {overlayVisible && (
          <DialogPrimitive.Overlay className="bg-opacity-50 fixed inset-0 z-[998] bg-gray-100" />
        )}
        <DialogPrimitive.Content
          ref={ref}
          className="bg-background-main-secondary shadow-strong fixed top-1/2 left-1/2 z-[999] flex max-h-[85vh] w-[90vw] max-w-[320px] -translate-x-1/2 -translate-y-1/2 transform flex-col gap-6 rounded-sm p-6 text-center focus:outline-none"
        >
          <DialogPrimitive.Title
            className={cn('text-text-main-primary text-base font-semibold', !title && 'hidden')}
          >
            {title}
          </DialogPrimitive.Title>
          <DialogPrimitive.Description className="text-text-main-primary text-xs" asChild>
            <section>{children}</section>
          </DialogPrimitive.Description>
          <DialogPrimitive.Close asChild></DialogPrimitive.Close>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
});

Dialog.displayName = 'Dialog';
