import React from 'react';

import { container } from '~/shared/lib/di';
import { useObservableEagerState } from '~/shared/lib/state';

import { AuthStore } from '../services';

export const useAuth = () => {
  const authStore = React.useMemo(() => container.resolve(AuthStore), []);
  const isAuthenticated = useObservableEagerState(authStore.isAuthenticated$);
  const currentUser = useObservableEagerState(authStore.currentUser$);
  const loading = useObservableEagerState(authStore.loading$);
  const initialized = useObservableEagerState(authStore.initialized$);

  const auth = React.useMemo(
    () => ({
      authStore,
      isAuthenticated,
      loading,
      initialized,
      currentUser,
    }),
    [authStore, isAuthenticated, loading, initialized, currentUser],
  );

  return auth;
};
