import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './bank-transaction-modal.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

const SIMPLE_FEE = 'simple_fee';
const COMPLEX_FEE = 'complex_fee';

export const BankTransactionModal = {
  bindings: {
    modalInstance: '<',
    transaction: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$window',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'BankTransactionService',
    'AccountsService',
    'CoreService',
    'ClientsService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $window: ng.IWindowService;
      GtUtils: GtUtilsService;
      gettext: ng.gettext.gettextFunction;
      FormFieldParamsService: FormFieldParamsService;
      BankTransactionService: any;
      AccountsService: AccountsService;
      CoreService: CoreService;
      ClientsService: any;
      fields: any;
      form: any;
      modalInstance: any;
      transaction: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $window: ng.IWindowService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        BankTransactionService: any,
        AccountsService: AccountsService,
        CoreService: CoreService,
        ClientsService: any,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$window = $window;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.BankTransactionService = BankTransactionService;
        this.AccountsService = AccountsService;
        this.CoreService = CoreService;
        this.ClientsService = ClientsService;
        this.form = undefined;
        this.fields = [];
      }

      $onInit() {
        this.transaction = this.transaction || { positions: [] };
        if (this.transaction.id) {
          this.updateData();
        } else {
          this.updateFields();
        }
      }

      calculateIncomingIssuanceFee(operationType: any) {
        if (
          !this.transaction[`${operationType}_amount`] ||
          !this.transaction[`${operationType}_issuance_fee_percent`]
        ) {
          this.calculeteTotalAmount(operationType);
          return;
        }
        let feeAmount = this.transaction[`${operationType}_issuance_fee_amount`];
        if (this.transaction[`${operationType}_issuance_fee_simple`]) {
          feeAmount =
            (parseFloat(this.transaction[`${operationType}_amount`]) *
              parseFloat(this.transaction[`${operationType}_issuance_fee_percent`])) /
            100;
        }
        if (this.transaction[`${operationType}_issuance_fee_complex`]) {
          feeAmount =
            (parseFloat(this.transaction[`${operationType}_amount`]) /
              (100 - parseFloat(this.transaction[`${operationType}_issuance_fee_percent`]))) *
              100 -
            parseFloat(this.transaction[`${operationType}_amount`]);
        }
        this.transaction[`${operationType}_issuance_fee_amount`] =
          Math.round(feeAmount * 100) / 100;
        this.transaction[`${operationType}_cashback_amount`] = Math.round(feeAmount * 100) / 100;
        this.calculeteTotalAmount(operationType);
      }

      calculeteTotalAmount(operationType: any) {
        let amount = 0;
        if (this.transaction[`${operationType}_is_cashback`]) {
          amount = parseFloat(this.transaction[`${operationType}_cashback_amount`]) || 0;
        } else {
          amount = -parseFloat(this.transaction[`${operationType}_issuance_fee_amount`]) || 0;
        }
        const total = parseFloat(this.transaction[`${operationType}_amount`]) + amount;
        this.transaction[`${operationType}_total`] = Math.round(total * 100) / 100;
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.BankTransactionService.getBankTransaction(this.transaction.id).then(
          (data: any) => {
            const outgoing = data.operations.find(
              (operation: any) => operation.operation_type === 'outgoing',
            );
            const outgoingKeys = Object.keys(outgoing);
            const incoming = data.operations.find(
              (operation: any) => operation.operation_type === 'incoming',
            );
            const incomingKeys = Object.keys(incoming);
            delete data.operations;

            this.transaction = data;
            outgoingKeys.forEach((key) => {
              this.transaction[`outgoing_${key}`] = outgoing[key];
            });
            incomingKeys.forEach((key) => {
              this.transaction[`incoming_${key}`] = incoming[key];
            });
            this.transaction.outgoing_issuance_fee_simple =
              this.transaction.outgoing_issuance_fee_type === SIMPLE_FEE;
            this.transaction.outgoing_issuance_fee_complex =
              this.transaction.outgoing_issuance_fee_type === COMPLEX_FEE;
            this.transaction.incoming_issuance_fee_simple =
              this.transaction.incoming_issuance_fee_type === SIMPLE_FEE;
            this.transaction.incoming_issuance_fee_complex =
              this.transaction.incoming_issuance_fee_type === COMPLEX_FEE;
            this.calculeteTotalAmount('outgoing');
            this.calculeteTotalAmount('incoming');
            this.updateFields();
            this.GtUtils.overlay('hide');
          },
        );
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      delete() {
        this.GtUtils.overlay('show');
        return this.BankTransactionService.deleteBankTransaction(this.transaction.id)
          .then(() => {
            this.modalInstance.close('close');
          })
          .catch((error: any) => {
            this.GtUtils.errorClb(error);
          })
          .finally(() => {
            this.GtUtils.overlay('hide');
          });
      }

      save() {
        const transaction = this.getTransactionToSave();

        return this.BankTransactionService.save(transaction)
          .then((data: any) => {
            notify(this.gettext('Bank transaction saved.'));
            this.modalInstance.close(data || 'close');
          })
          .catch((error: any) => {
            this.transaction.errors = error.data;
            this.GtUtils.errorClb(error);
          });
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig(this.transaction))
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig(this.transaction)).then(
          () => this.updateFields(),
        );
      }

      getTransactionToSave() {
        const outgoingFields = this.fields[1].fieldGroup[0].fieldGroup;
        const outgoingFieldKeys = outgoingFields.map((field: any) => field.key);
        const outgoing: any = { operation_type: 'outgoing' };

        const incomingFields = this.fields[2].fieldGroup[0].fieldGroup;
        const incomingFieldKeys = incomingFields.map((field: any) => field.key);
        const incoming: any = { operation_type: 'incoming' };

        const transaction: any = {};
        for (const key in this.transaction) {
          if (incomingFieldKeys.includes(key)) {
            const newKey = key.replace('incoming_', '');
            incoming[newKey] = this.transaction[key];
          } else if (outgoingFieldKeys.includes(key)) {
            const newKey = key.replace('outgoing_', '');
            outgoing[newKey] = this.transaction[key];
          } else {
            transaction[key] = this.transaction[key];
          }
        }

        const outFee = outgoing.issuance_fee_complex ? COMPLEX_FEE : null;

        outgoing.issuance_fee_type = outgoing.issuance_fee_simple ? SIMPLE_FEE : outFee;

        const inFee = incoming.issuance_fee_complex ? COMPLEX_FEE : null;

        incoming.issuance_fee_type = incoming.issuance_fee_simple ? SIMPLE_FEE : inFee;

        if (outgoing.is_cashback) {
          outgoing.issuance_fee_amount = null;
        } else {
          outgoing.cashback_amount = null;
        }

        if (incoming.is_cashback) {
          incoming.issuance_fee_amount = null;
        } else {
          incoming.cashback_amount = null;
        }

        transaction.operations = [outgoing, incoming];

        return transaction;
      }

      getFormConfig(transaction: any) {
        const col1: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        const col2: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        const col3: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        const col4: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('GENERAL'),
          },
          fieldGroup: [
            {
              key: 'transaction_date',
              type: 'gt-date-select',
              defaultValue: new Date(),
              templateOptions: {
                label: this.gettext('Transaction (date)'),
                placeholder: this.gettext('date'),
                required: true,
              },
              validation: {
                show: true,
              },
            },

            {
              key: 'number',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Number'),
                addon: this.gettext('#'),
                required: true,
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'general_expenses',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('General expenses'),
                placeholder: this.gettext('Incoming'),
                resource: 'finances.GeneralExpenses',
              },
            },
          ],
        });

        col2.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('OUTGOING'),
          },
          fieldGroup: [
            {
              key: 'outgoing_date',
              type: 'gt-date-select',
              defaultValue: new Date(),
              templateOptions: {
                label: this.gettext('Outgoing (date)'),
                placeholder: this.gettext('date'),
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'outgoing_counterparty',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Owner'),
                addFunc: () => this.ClientsService.roleModal({ model_name: 'Owner' }),
                addIcon: this.GtUtils.getIcon('clients.Owner'),
                addPerms: ['add_owner'],
                title: transaction.counterparty_name,
                resource: 'clients.Owner',
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'outgoing_related_counterparty',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Related owner'),
                queryParams: () => {
                  return {
                    client_related_counterparty: transaction.outgoing_counterparty,
                  };
                },
                addFunc: () => this.ClientsService.roleModal({ model_name: 'Owner' }),
                addIcon: this.GtUtils.getIcon('clients.Owner'),
                addPerms: ['add_owner'],
                title: transaction.related_counterparty_name,
                resource: 'clients.Owner',
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'outgoing_currency',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Currency'),
                placeholder: this.gettext('USD, UAH'),
                resource: 'finances.Currency',
                addFunc: () => {
                  this.$window.open('/admin/finances/currency/add/');
                },
                addIcon: 'fa-dollar',
                addPerms: ['add_currency'],
                title: transaction.currency_symbol,
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'outgoing_bank_account',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Bank account'),
                resource: 'finances.BankAccount',
                queryParams: () => {
                  return {
                    client_role: transaction.outgoing_counterparty,
                    currency: transaction.outgoing_currency,
                  };
                },
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'outgoing_currency_exchange',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Exchange rate'),
                resource: 'finances.CurrencyExchange',
                addFunc: () => {
                  this.$window.open('/admin/finances/currencyexchange/add/');
                },
                queryParams: () => {
                  return { local_currency: transaction.currency };
                },
                addIcon: 'fa fa-money',
                addPerms: ['add_currencyexchange'],
                help: this.gettext(
                  'Fill it only if you want this contract to be converted by a certain rate',
                ),
              },
              expressionProperties: {
                'templateOptions.disabled': '!model.currency',
              },
            },
            {
              key: 'outgoing_is_cashback',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Cashback'),
                onChange: () => {
                  this.calculeteTotalAmount('outgoing');
                },
              },
            },
            {
              key: 'outgoing_issuance_fee_simple',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Simple percent'),
                hint: this.gettext('Check this if you want calculate simple percent'),
                onChange: () => {
                  this.transaction.outgoing_issuance_fee_complex = false;
                  this.calculateIncomingIssuanceFee('outgoing');
                },
              },
            },
            {
              key: 'outgoing_issuance_fee_complex',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Complex percent'),
                hint: this.gettext('Check this if you want calculate complex percent'),
                onChange: () => {
                  this.transaction.outgoing_issuance_fee_simple = false;
                  this.calculateIncomingIssuanceFee('outgoing');
                },
              },
            },
            {
              key: 'outgoing_issuance_fee_percent',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Percent, %'),
                addon: this.gettext('%'),
                type: 'number',
                onChange: () => {
                  this.calculateIncomingIssuanceFee('outgoing');
                },
              },
            },
            {
              key: 'outgoing_issuance_fee_amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Fee amount'),
                type: 'number',
                onChange: () => {
                  this.calculeteTotalAmount('outgoing');
                },
              },
              hideExpression: () => this.transaction.outgoing_is_cashback,
            },
            {
              key: 'outgoing_cashback_amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Cashback amount'),
                type: 'number',
                onChange: () => {
                  this.calculeteTotalAmount('outgoing');
                },
              },
              hideExpression: () => !this.transaction.outgoing_is_cashback,
            },
            {
              key: 'outgoing_amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Amount'),
                type: 'number',
                onChange: () => {
                  this.calculateIncomingIssuanceFee('outgoing');
                },
              },
            },
            {
              key: 'outgoing_total',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Total'),
                readonly: true,
                type: 'number',
              },
            },
            {
              key: 'outgoing_contract',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Contract'),
                resource: 'contracts.ContractBase',
              },
            },
            {
              key: 'outgoing_passport',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
                resource: 'passports.passport',
              },
            },
            {
              key: 'outgoing_invoices',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Invoices'),
                resource: 'finances.Finance',
              },
            },
            {
              key: 'outgoing_address',
              type: 'gt-ui-select',
              templateOptions: {
                label: 'Address',
                resource: 'location.Address',
                addFunc: () => {
                  this.$window.open('/admin/location/address/add/');
                },
                addIcon: 'fa-location-arrow',
                addPerms: ['add_station'],
              },
            },
          ],
        });

        col3.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('INCOMING'),
          },
          fieldGroup: [
            {
              key: 'incoming_date',
              type: 'gt-date-select',
              defaultValue: new Date(),
              templateOptions: {
                label: this.gettext('Incoming (date)'),
                placeholder: this.gettext('date'),
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'incoming_counterparty',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Owner'),
                addFunc: () => this.ClientsService.roleModal({ model_name: 'Owner' }),
                addIcon: this.GtUtils.getIcon('clients.Owner'),
                addPerms: ['add_owner'],
                title: transaction.counterparty_name,
                resource: 'clients.Owner',
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'incoming_related_counterparty',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Related owner'),
                queryParams: () => {
                  return {
                    client_related_counterparty: transaction.incoming_counterparty,
                  };
                },
                addFunc: () => this.ClientsService.roleModal({ model_name: 'Owner' }),
                addIcon: this.GtUtils.getIcon('clients.Owner'),
                addPerms: ['add_owner'],
                title: transaction.related_counterparty_name,
                resource: 'clients.Owner',
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'incoming_currency',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Currency'),
                placeholder: this.gettext('USD, UAH'),
                resource: 'finances.Currency',
                addFunc: () => {
                  this.$window.open('/admin/finances/currency/add/');
                },
                addIcon: 'fa-dollar',
                addPerms: ['add_currency'],
                title: transaction.currency_symbol,
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'incoming_bank_account',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Bank account'),
                resource: 'finances.BankAccount',
                queryParams: () => {
                  return {
                    client_role: transaction.incoming_counterparty,
                    currency: transaction.incoming_currency,
                  };
                },
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'incoming_currency_exchange',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Exchange rate'),
                resource: 'finances.CurrencyExchange',
                addFunc: () => {
                  this.$window.open('/admin/finances/currencyexchange/add/');
                },
                queryParams: () => {
                  return { local_currency: transaction.currency };
                },
                addIcon: 'fa fa-money',
                addPerms: ['add_currencyexchange'],
                help: this.gettext(
                  'Fill it only if you want this contract to be converted by a certain rate',
                ),
              },
              expressionProperties: {
                'templateOptions.disabled': '!model.currency',
              },
            },
            {
              key: 'incoming_is_cashback',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Cashback'),
                onChange: () => {
                  this.calculeteTotalAmount('incoming');
                },
              },
            },
            {
              key: 'incoming_issuance_fee_simple',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Simple percent'),
                hint: this.gettext('Check this if you want calculate simple percent'),
                onChange: () => {
                  this.transaction.incoming_issuance_fee_complex = false;
                  this.calculateIncomingIssuanceFee('incoming');
                },
              },
            },
            {
              key: 'incoming_issuance_fee_complex',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Complex percent'),
                hint: this.gettext('Check this if you want calculate complex percent'),
                onChange: () => {
                  this.transaction.incoming_issuance_fee_simple = false;
                  this.calculateIncomingIssuanceFee('incoming');
                },
              },
            },
            {
              key: 'incoming_issuance_fee_percent',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Percent, %'),
                addon: this.gettext('%'),
                type: 'number',
                onChange: () => {
                  this.calculateIncomingIssuanceFee('incoming');
                },
              },
            },
            {
              key: 'incoming_issuance_fee_amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Fee amount'),
                type: 'number',
                onChange: () => {
                  this.calculeteTotalAmount('incoming');
                },
              },
              hideExpression: () => this.transaction.incoming_is_cashback,
            },
            {
              key: 'incoming_cashback_amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Cashback amount'),
                type: 'number',
                onChange: () => {
                  this.calculeteTotalAmount('incoming');
                },
              },
              hideExpression: () => !this.transaction.incoming_is_cashback,
            },
            {
              key: 'incoming_amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Amount'),
                type: 'number',
                onChange: () => {
                  this.calculateIncomingIssuanceFee('incoming');
                },
              },
            },
            {
              key: 'incoming_total',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Total'),
                type: 'number',
                readonly: true,
              },
            },
            {
              key: 'incoming_contract',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Contract'),
                resource: 'contracts.ContractBase',
              },
            },
            {
              key: 'incoming_passport',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
                resource: 'passports.passport',
              },
            },
            {
              key: 'incoming_invoices',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Invoices'),
                resource: 'finances.Finance',
              },
            },
            {
              key: 'incoming_address',
              type: 'gt-ui-select',
              templateOptions: {
                label: 'Address',
                resource: 'location.Address',
                addFunc: () => {
                  this.$window.open('/admin/location/address/add/');
                },
                addIcon: 'fa-location-arrow',
                addPerms: ['add_station'],
              },
            },
          ],
        });

        col4.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('INFO'),
          },
          fieldGroup: [
            {
              key: 'additional_info',
              type: 'gt-textarea',
              templateOptions: {
                label: this.gettext('Additional Info'),
                placeholder: this.gettext('Specific information about this object'),
                className: 'additional-info',
              },
            },
            {
              key: 'description',
              type: 'gt-textarea',
              templateOptions: {
                label: this.gettext('Description'),
                placeholder: this.gettext('Specific information about this object'),
              },
            },
          ],
        });

        return {
          formName: 'bank-transaction-edit-modal',
          fieldsDef: [col1, col2, col3, col4],
        };
      }
    },
  ],
};
