import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './multicontract-list-container.html?raw';
import type { MulticontractService } from '../../multicontract.service';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const MulticontractListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    useType: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$q',
    'GtUtils',
    'gettext',
    'DocumentsService',
    'MulticontractService',
    'ContractsService',
    'gtFilterService',
    'ClientsService',
    'CoreService',
    class {
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      ClientsService: any;
      ContractsService: ContractsService;
      CoreService: CoreService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      MulticontractService: MulticontractService;
      addPermissionCode: any;
      changePermissionCode: any;
      contractPurpose: any;
      dataCount: number;
      dataList: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      inlineOrderingParams: any;
      pageTitle: any;
      queryParams: QueryParams = {};
      reportConfig: any;
      savedFilterChoices: any;
      savedReportConfigs: any;
      viewPermissionCode: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $q: ng.IQService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        DocumentsService: any,
        MulticontractService: MulticontractService,
        ContractsService: ContractsService,
        gtFilterService: GtFilterService,
        ClientsService: any,
        CoreService: CoreService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$q = $q;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.DocumentsService = DocumentsService;
        this.MulticontractService = MulticontractService;
        this.ContractsService = ContractsService;
        this.gtFilterService = gtFilterService;
        this.ClientsService = ClientsService;
        this.CoreService = CoreService;

        this.filterLevel = '';
        this.dataList = [];
        this.dataCount = 0;
        this.queryParams = {};
        this.pageTitle = '';

        this.reportConfig = null;
      }

      $onInit() {
        this.pageTitle =
          this.initQueryParams.stage === 'ticket'
            ? this.gettext('Tickets')
            : this.gettext('Contracts');
        this.filterLevel = this.filterLevel || 'multicontract-list-container';
        this.queryParams = { ...this.initQueryParams };

        this.viewPermissionCode = this.MulticontractService.getPermissionName(
          this.queryParams,
          'view',
        );
        this.addPermissionCode = this.MulticontractService.getPermissionName(
          this.queryParams,
          'add',
        );
        this.changePermissionCode = this.MulticontractService.getPermissionName(
          this.queryParams,
          'change',
        );

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
          this.updateOrdering();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);

        this.$rootScope.$on(
          `gt-report-config-created_${this.filterLevel}`,
          (event: any, config: any) => {
            this.savedReportConfigs = [...this.savedReportConfigs, config];
          },
        );

        this.$rootScope.$on(
          `gt-report-config-updated_${this.filterLevel}`,
          (event: any, config: any) => {
            const index = this.savedReportConfigs.findIndex((item: any) => item.id === config.id);
            this.savedReportConfigs[index] = config;
          },
        );

        this.$rootScope.$on(
          `gt-report-config-selected_${this.filterLevel}`,
          (event: any, config: any) => {
            this.reportConfig = config ? config.column_params : null;
          },
        );

        this.savedReportConfigs = this.CoreService.getSavedReportConfigs(this.filterLevel);
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
        this.contractPurpose = this.ContractsService.getContractPurpose({
          contract_type: this.queryParams.contract_type,
          deal_type: this.queryParams.deal_type,
        });
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.MulticontractService.getTableData(this.queryParams).then((data: any) => {
          this.dataList = data.results;
          this.dataCount = data.count;
          this.GtUtils.overlay('hide');
        });
      }

      updateOrdering() {
        this.inlineOrderingParams = [
          { title: 'Conclusion date contract', value: 'conclusion_date' },
          {
            title: 'Conclusion date contract position',
            value: 'positions_max_conclusion_date',
          },
          { title: 'Start of execution', value: 'positions_max_date_of_execution' },
          { title: 'End of execution', value: 'positions_max_end_of_execution' },
        ];
      }

      openMulticontractModal(item: any, data: any) {
        item = item || { positions: [], contract_type: 'sale', ...this.queryParams };
        data = { data, ...this.queryParams };
        return this.MulticontractService.multicontractModal(item, data).then(() =>
          this.updateData(),
        );
      }

      openPositionModal(position: any) {
        if (position.stage === 'contract') {
          return this.ContractsService.contractModal(position).then(() => {
            this.updateData();
          });
        }
        if (position.stage === 'ticket') {
          return this.ContractsService.requestModal(position).then(() => {
            this.updateData();
          });
        }
      }

      updateQueryParams(params: QueryParams) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      openDocxModal(modelName: string, contractId: number) {
        return this.DocumentsService.generateDocxModal(modelName, contractId);
      }

      openPositonDocxModal(contract: any) {
        return this.DocumentsService.generateDocxModal(
          contract.contract_purpose.charAt(0).toUpperCase() +
            contract.contract_purpose.slice(1) +
            'Contract',
          contract.id,
        );
      }

      openPositionEmailModal(position: any) {
        const positionModelNameMap: any = {
          ticket: 'Request',
          contract:
            position.contract_purpose.charAt(0).toUpperCase() +
            position.contract_purpose.slice(1) +
            position.stage.charAt(0).toUpperCase() +
            position.stage.slice(1),
        };

        return this.ClientsService.sendEmailModal(
          positionModelNameMap[position.stage],
          position.id,
        );
      }

      openEmailModal(contract: any) {
        return this.ClientsService.sendEmailModal(
          this.MulticontractService.getContractModelName(contract),
          contract.id,
        );
      }

      openDocumentModal(modelName: string, object: any) {
        return this.DocumentsService.documentListModal({
          model_name:
            modelName || this.MulticontractService.getContractModelName(object).toLowerCase(),
          object_id: object.id,
        }).then(() => this.updateData());
      }

      addFinance(multicontractId: number, invoiceCondition: any) {
        this.MulticontractService.addFinance(multicontractId, invoiceCondition);
      }

      setFactVolumeToPlans(params: object) {
        if (!confirm(this.gettext('Are you sure that you want to set fact volume to plans?'))) {
          return;
        }
        this.GtUtils.overlay('show');
        return this.MulticontractService.setFactVolumeToPlans(params).then(
          () => {
            this.GtUtils.overlay('hide');
            notify(this.gettext('Successfully updated.'));
          },
          (error: any) => {
            this.GtUtils.errorClb(error);
          },
        );
      }

      setCargoConfirmation(multicontractId: number) {
        if (!confirm(this.gettext('Are you sure that you want to set cargo confirmation?'))) {
          return;
        }
        this.GtUtils.overlay('show');
        return this.MulticontractService.setCargoConfirmation({
          id: multicontractId,
        }).then(this.GtUtils.overlay('hide'), notify(this.gettext('Successfully updated.')));
      }

      updateStatus() {
        this.GtUtils.overlay('show');
        return this.ContractsService.Contract.updateStatus(this.queryParams, () => {
          this.updateData();
          this.GtUtils.overlay('hide');
          notify(this.gettext('Contracts status updated'));
        });
      }

      createPnlControl() {
        this.GtUtils.overlay('show');
        return this.ContractsService.Contract.createPnlControl(
          { ...this.queryParams, bulk_create: true },
          () => {
            this.updateData();
            this.GtUtils.overlay('hide');
            notify(this.gettext('Contracts control is being created. Wait a few minutes'));
          },
        );
      }

      setVoyageData() {
        this.GtUtils.overlay('show');
        return this.ContractsService.Contract.setVoyageData({}, () => {
          this.updateData();
          this.GtUtils.overlay('hide');
          notify(this.gettext('Contracts updated'));
        });
      }

      cloneContract(ObjId: number) {
        if (!confirm(this.gettext('Do you want to clone this Contract?'))) {
          return null;
        }
        return this.MulticontractService.cloneContract(ObjId).then(() => this.updateData());
      }

      cancelContract(ObjId: number) {
        if (!confirm(this.gettext('Do you want to cancel this Contract?'))) {
          return this.$q.resolve();
        }
        return this.MulticontractService.cancelContract(ObjId).then(() => this.updateData());
      }

      getApprovalContractModel(contract: any) {
        const purpose = this.MulticontractService.getContractModelName(contract);
        return 'contracts.' + purpose.charAt(0).toUpperCase() + purpose.slice(1);
      }

      finalApprove(item: any) {
        return this.MulticontractService.finalApprove(item).then(
          () => {
            notify(this.gettext('Multirequest saved.'));
            this.updateData();
          },
          (data: any) => {
            this.GtUtils.errorClb(data);
          },
        );
      }

      createMultiContract(item: any) {
        if (!confirm(this.gettext('Do you want to create multicontract?'))) {
          return;
        }

        return this.MulticontractService.createContractFromTicket({
          id: item.id,
        }).then(
          () => {
            this.GtUtils.dismissAllModals();
            this.GtUtils.overlay('hide');
          },
          (data: any) => {
            this.GtUtils.errorClb(data);
          },
        );
      }

      reverseContract(contractId: number) {
        if (!confirm(this.gettext('Do you want to reverse contract?'))) {
          return;
        }
        return this.MulticontractService.reverseDeal(contractId);
      }

      createPassport(contract: any, positions: any) {
        return this.MulticontractService.createConnectToPassport(contract, positions);
      }

      connectToPassport(contract: any, passportId: number, positions: any) {
        return this.MulticontractService.connectToExistingPassport(contract, passportId, positions);
      }
    },
  ],
};
