import {
  financesPaymentConditionsList,
  financesPaymentConditionsPredictionsRetrieve,
} from '~/shared/api';
import type { Paginated } from '~/shared/lib/entities';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { PaymentCondition, PaymentConditionListParams } from '../lib';

export class PaymentConditionRepository extends EntityRepository<
  PaymentCondition,
  PaymentConditionListParams
> {
  readonly entityName = 'finances.PaymentCondition';

  getFn = (_id: number): Promise<PaymentCondition> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: PaymentConditionListParams): Promise<Paginated<PaymentCondition>> => {
    const { data } = await financesPaymentConditionsList({ query });
    return { records: data.results, count: data.count };
  };
  createFn = (_entity: PaymentCondition): Promise<PaymentCondition> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<PaymentCondition>): Promise<PaymentCondition> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  searchFn = async (input: string, _id?: number): Promise<EntityOption[]> => {
    const { data } = await financesPaymentConditionsPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };
}
