import React from 'react';

import type { ListOption } from '~/shared/lib/types';
import { cn } from '~/shared/lib/utils';
import { Tag } from '~/shared/ui/kit/tag';

export const MultiSelectRecords: React.FC<{
  items?: ListOption[];
  disabled?: boolean;
  className?: string;
}> = ({ items, disabled, className }) => {
  if (items && !Array.isArray(items)) {
    throw new Error('items should be an array');
  }
  return (
    <div className={cn('flex gap-2', className)}>
      {items?.map((item) => (
        <Tag key={item.value} disabled={disabled} {...item.tagProps}>
          {item.label}
        </Tag>
      ))}
    </div>
  );
};
