import ng from 'angular';

import { StockReserveUnitContainer } from './stock-reserve-unit/stock-reserve-unit-container/stock-reserve-unit-container.component';
import { StockReserveUnitModal } from './stock-reserve-unit/stock-reserve-unit-modal/stock-reserve-unit-modal.component';
import { StockReserveUnitPage } from './stock-reserve-unit/stock-reserve-unit-page/stock-reserve-unit-page.component';
import { StockReserveUnitView } from './stock-reserve-unit/stock-reserve-unit-view/stock-reserve-unit-view.component';
import { StockReserveUnitFormFieldsService } from './stock-reserve-unit-form-fields.service';
import { StockReserveUnitsService } from './stock-reserve-unit.service';
import { StockReserveUnitsContainer } from './stock-reserve-units/stock-reserve-units-container/stock-reserve-units-container.component';
import { StockReserveUnitsPage } from './stock-reserve-units/stock-reserve-units-page/stock-reserve-units-page.component';
import { StockReserveUnitsTable } from './stock-reserve-units/stock-reserve-units-table/stock-reserve-units-table.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const config: any = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('logistics.stockReserveUnit', {
        url: '/stock-reserve-unit/:id',
        component: 'stockReserveUnitPage',
        data: { pageTitle: gettext('Stock reserve unit') },
        resolve: {
          filterLevel: () => 'stock-reserve-unit-page',
          queryParams: [
            'PageService',
            (PageService: PageService) => PageService.syncUrlFilter('stock-reserve-unit-page'),
          ],
        },
      })
      .state('logistics.stockReserveUnits', {
        url: '/stock-reserve-units/',
        component: 'stockReserveUnitsPage',
        data: { pageTitle: gettext('Stock reserve units') },
        resolve: {
          filterLevel: () => 'stock-reserve-units-page',
          queryParams: [
            'PageService',
            (PageService: PageService) => PageService.syncUrlFilter('stock-reserve-units-page'),
          ],
        },
      });
  },
];

export const stockReserveUnitsModule = ng
  .module('execution.stockReserveUnit', [])
  .service('StockReserveUnitsService', StockReserveUnitsService)
  .component('stockReserveUnitsPage', StockReserveUnitsPage)
  .component('stockReserveUnitsContainer', StockReserveUnitsContainer)
  .component('stockReserveUnitsTable', StockReserveUnitsTable)
  .component('stockReserveUnitPage', StockReserveUnitPage)
  .component('stockReserveUnitContainer', StockReserveUnitContainer)
  .component('stockReserveUnitView', StockReserveUnitView)
  .component('stockReserveUnitModal', StockReserveUnitModal)
  .service('StockReserveUnitFormFieldsService', StockReserveUnitFormFieldsService)
  .config(config).name;
