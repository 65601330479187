import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React, { forwardRef } from 'react';

import { cn } from '~/shared/lib/utils';

export type TooltipProps = {
  sideOffset?: number;
  side?: 'top' | 'bottom' | 'left' | 'right';
  padding?: number;
  children: React.ReactNode;
  content: React.ReactNode;
};

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  ({ sideOffset = 4, side = 'bottom', padding = 8, children, content }, ref) => {
    return (
      <div data-testid="tooltip-content">
        <TooltipPrimitive.Provider>
          <TooltipPrimitive.Root delayDuration={100}>
            <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
            <TooltipPrimitive.Portal>
              <TooltipPrimitive.Content
                ref={ref}
                side={side}
                sideOffset={sideOffset}
                collisionPadding={8}
                className={cn(
                  'text-text-main-on-primary-section backdrop-blur-strong animate-in fade-in-0 zoom-in-95 z-[9999] overflow-visible rounded-md px-3 py-1.5 text-xs',
                )}
                style={{
                  padding: `${padding}px`,
                  backgroundColor: 'rgba(23, 23, 23, 0.8)',
                }}
                data-testid="tooltip-content"
              >
                {content}
                <TooltipPrimitive.Arrow />
              </TooltipPrimitive.Content>
            </TooltipPrimitive.Portal>
          </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
      </div>
    );
  },
);

Tooltip.displayName = 'Tooltip';
