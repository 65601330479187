import type ng from 'angular';

import template from './consolidated-accounts-container.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const ConsolidatedAccountsContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    accounts: '<?',
    accountsAreContracts: '<?',
  },
  template,
  controller: [
    '$scope',
    'gettext',
    'GtUtils',
    'gtFilterService',
    'StockExchangesService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      accounts: any;
      filterLevel = 'consolidated-accounts-container';
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      report: any;
      stockExchangesService: any;
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        StockExchangesService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.stockExchangesService = StockExchangesService;
        this.report = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'consolidated-accounts-container';
        this.queryParams = { ...this.initQueryParams, page_size: '999' };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      $onChanges(changes: any) {
        if (changes.accounts && this.accounts.length) {
          this.report = { accounts: this.accounts };
        }
      }

      updateData() {
        if (this.accounts?.length) {
          this.report = { accounts: this.accounts };
          return;
        }
        this.GtUtils.overlay('show');
        this.report = this.stockExchangesService
          .getAccountBalance(this.queryParams)
          .then((report: any) => {
            this.report = report;
            this.GtUtils.overlay('hide');
          });
      }
    },
  ],
};
