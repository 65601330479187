import type { FieldDef } from '~/core/datasets';
import type { CounterpartyQueryParams, CounterpartyRecord } from '~/features/crm/counterparties';
import type { EntityMenuAction } from '~/shared/lib/entities';
import type { ListOption } from '~/shared/lib/types';

const statusOptions: Record<string, ListOption> = {
  Approved: { value: 'Approved', label: 'Approved', tagProps: { variant: 'green' } },
  Pending: { value: 'Pending', label: 'Pending', tagProps: { variant: 'yellow' } },
  'Re-approval': { value: 'Re-approval', label: 'Re-approval', tagProps: { variant: 'yellow' } },
  Rejected: { value: 'Rejected', label: 'Rejected', tagProps: { variant: 'red' } },
  Approved_condition: { value: 'Approved_condition', label: 'Approved condition' },
  New: { value: 'New', label: 'New', tagProps: { variant: 'blue' } },
};

const roleOptions: Record<string, ListOption> = {
  broker: { value: 'broker', label: 'Broker', tagProps: { variant: 'green' } },
  deliverer: { value: 'deliverer', label: 'Deliverer', tagProps: { variant: 'gray' } },
  supplier: { value: 'supplier', label: 'Supplier', tagProps: { variant: 'blue' } },
  insurer: { value: 'insurer', label: 'Insurer', tagProps: { variant: 'blue' } },
  exporter: { value: 'exporter', label: 'Exporter', tagProps: { variant: 'orange' } },
  customs_broker: {
    value: 'customs_broker',
    label: 'Customs broker',
    tagProps: { variant: 'pink' },
  },
  owner: { value: 'owner', label: 'Owner', tagProps: { variant: 'purple' } },
  buyer: { value: 'buyer', label: 'Buyer', tagProps: { variant: 'red' } },
  other: { value: 'other', label: 'Other', tagProps: { variant: 'sky' } },
  surveyor: { value: 'surveyor', label: 'Surveyor', tagProps: { variant: 'turquoise' } },
  farm: { value: 'farm', label: 'Farm', tagProps: { variant: 'yellow' } },
  elevator: { value: 'elevator', label: 'Elevator', tagProps: { variant: 'gray' } },
  bank: { value: 'bank', label: 'Bank', tagProps: { variant: 'green' } },
};

const regularStatusOptions: Record<string, ListOption> = {
  active: { value: 'active', label: 'Active', tagProps: { variant: 'green' } },
  potencial: { value: 'potencial', label: 'Potencial' },
  inactive: { value: 'inactive', label: 'Inactive', tagProps: { variant: 'red' } },
};

const relationOptions: Record<string, ListOption> = {
  Hot: { value: 'Hot', label: 'Hot', tagProps: { variant: 'red' } },
  Neutral: { value: 'Neutral', label: 'Neutral', tagProps: { variant: 'gray' } },
  Warm: { value: 'Warm', label: 'Warm', tagProps: { variant: 'yellow' } },
  Cold: { value: 'Cold', label: 'Cold', tagProps: { variant: 'blue' } },
  Angry: { value: 'Angry', label: 'Angry', tagProps: { variant: 'purple' } },
};

const approvalStatusOptions: Record<string, ListOption> = {
  wait: { value: 'wait', label: 'Wait', tagProps: { variant: 'yellow' } },
  rejected: { value: 'rejected', label: 'Rejected', tagProps: { variant: 'red' } },
  approved: { value: 'approved', label: 'Approved', tagProps: { variant: 'green' } },
};

const sizeOptions: Record<string, ListOption> = {
  Big: { value: 'Big', label: 'Big', tagProps: { variant: 'green' } },
  Medium: { value: 'Medium', label: 'Medium', tagProps: { variant: 'orange' } },
  Small: { value: 'Small', label: 'Small', tagProps: { variant: 'red' } },
};

export const getCounterpartyFields = <
  R extends CounterpartyRecord,
  P extends CounterpartyQueryParams,
>(
  handlers: Record<string, EntityMenuAction<R>>,
) => {
  const fields: FieldDef<R, P>[] = [
    {
      key: 'name',
      title: 'Title',
      dataType: 'text',
      sticky: true,
      required: true,
      sorting: {},
      filters: [
        {
          argument: '_id_list',
          title: 'Counterparty',
          dataType: 'entity',
          typeExtra: { entity: 'clients.Client' },
        },
      ],
      actions: [
        {
          label: 'Details',
          icon: 'ArrowRightIcon',
          action: (records) => (window.location.href = `/#/clients/clients/${records[0].id}`),
          multiple: false,
          underline: true,
        },
        {
          label: 'Edit',
          icon: 'EditIcon',
          action: handlers.openEditModal,
        },
        {
          label: 'Clone',
          icon: 'CopyIcon',
          action: handlers.clone,
        },
        {
          label: 'Email',
          icon: 'NewspaperIcon',
          action: handlers.openEmailModal,
        },
        {
          label: 'Approve',
          icon: 'LikeIcon',
          action: handlers.approve,
          multiple: true,
        },
        {
          label: 'Decline',
          icon: 'DislikeIcon',
          action: handlers.decline,
          multiple: true,
        },
        {
          label: 'Reactivate',
          icon: 'ResetIcon',
          action: handlers.reactivateApprovals,
          multiple: true,
        },
        {
          label: '+ Template',
          icon: 'NewspaperIcon',
          action: handlers.openDocxModal,
        },
      ],
    },
    {
      key: 'company_group_title',
      title: 'Company group',
      dataType: 'entity',
      typeExtra: { entity: 'clients.CompanyGroup' },
      getValue: (rec) =>
        rec.company_group ? [{ id: rec.company_group, title: rec.company_group_title }] : undefined,
      setValue: ({ record, value }) => {
        return value?.length
          ? { ...record, company_group: value[0].id, company_group_title: value[0].title }
          : record;
      },
      filters: [
        {
          argument: '_company_group_list',
          dataType: 'entity',
          typeExtra: { entity: 'clients.CompanyGroup' },
        },
      ],
      sorting: { argument: 'company_group' },
    },
    {
      key: 'status',
      title: 'Status',
      editing: false,
      dataType: 'select',
      getValue: (rec) => (rec.status ? statusOptions[rec.status] : undefined),
      typeExtra: {
        options: Object.values(statusOptions),
      },

      filters: [
        {
          argument: '_status_list',
          title: 'Compliance Status',
          dataType: 'multiselect',
        },
      ],
      sorting: {},
    },
    {
      key: 'role_names',
      title: 'Roles',
      dataType: 'multiselect',
      getValue: (rec) =>
        Object.values(roleOptions).filter((opt) => rec.role_names?.includes(opt.value)),
      typeExtra: {
        options: Object.values(roleOptions),
      },
      size: 210,
      filters: [
        {
          argument: '_role_name_list',
          title: 'Roles',
          dataType: 'multiselect',
        },
        {
          argument: '_deliverer_list',
          title: 'Deliverer',
          dataType: 'entity',
          typeExtra: { entity: 'clients.Deliverer' },
        },
        {
          argument: '_customclientrole_list',
          title: 'Custom Client Role',
          dataType: 'entity',
          typeExtra: { entity: 'clients.CustomClientRole' },
        },
        {
          argument: '_is_distributor',
          title: 'Distributor',
          dataType: 'checkbox',
        },
        {
          argument: '_with_role',
          title: 'With Role',
          dataType: 'checkbox',
        },
        {
          argument: '_producer',
          title: 'Producer',
          dataType: 'checkbox',
        },
        {
          argument: '_agent',
          title: 'Agent',
          dataType: 'checkbox',
        },
      ],
    },
    {
      key: 'documents_count',
      title: 'Documents',
      dataType: 'number',
      editing: false,
      filters: [
        {
          argument: '_with_documents',
          title: 'With Documents',
          dataType: 'checkbox',
        },
      ],
      sorting: { argument: 'term_of_proxy' },
    },
    {
      key: 'updates_count',
      title: 'Updates',
      dataType: 'number',
      editing: false,
    },
    {
      key: 'invoices_count',
      title: 'Invoices',
      dataType: 'number',
      editing: false,
      sorting: { argument: 'bank_data_is_checked' },
    },
    {
      key: 'contracts_count',
      title: 'Contracts',
      dataType: 'number',
      editing: false,
      filters: [
        {
          argument: '_basis_list',
          title: 'Basis',
          dataType: 'entity',
          typeExtra: { entity: 'logistics.Basis' },
        },
        {
          argument: '_with_grain_quality_check',
          title: 'Has Grain Quality Check',
          dataType: 'checkbox',
        },
        {
          argument: '_has_contracts',
          title: 'Has Contracts',
          dataType: 'checkbox',
        },
        {
          argument: '_croparea_cargo_list',
          title: 'Potential Crops',
          dataType: 'entity',
          typeExtra: { entity: 'crops.Commodity' },
        },
        {
          argument: '_indicator_cargo_list',
          title: 'Indicator Crops',
          dataType: 'entity',
          typeExtra: { entity: 'crops.Commodity' },
        },
        {
          argument: '_contract_cargo_list',
          title: 'Contract Crops',
          dataType: 'entity',
          typeExtra: { entity: 'crops.Commodity' },
        },
      ],
    },
    {
      key: 'approval_status',
      title: 'Approval Status',
      dataType: 'select',
      editing: false,
      getValue: (rec) =>
        rec.approval_status ? approvalStatusOptions[rec.approval_status] : undefined,
      typeExtra: {
        options: Object.values(approvalStatusOptions),
      },
      filters: [
        {
          argument: '_approval_status_list',
          title: 'Approval Status',
          dataType: 'multiselect',
        },
        {
          argument: '_client_data_is_checked',
          title: 'Client Data Is Checked',
          dataType: 'checkbox',
        },
        {
          argument: '_bank_data_is_checked',
          title: 'Bank Data Is Checked',
          dataType: 'checkbox',
        },
        {
          argument: '_financebank_list',
          title: 'Bank',
          dataType: 'entity',
          typeExtra: { entity: 'clients.Bank' },
        },
        {
          argument: '_with_bank_details',
          title: 'Has Bank Details',
          dataType: 'checkbox',
        },
        {
          argument: '_can_confirm',
          title: 'Can Confirm',
          dataType: 'checkbox',
        },
        {
          argument: '_with_date_request',
          title: 'With Date Request',
          dataType: 'checkbox',
        },
        {
          argument: '_with_date_received',
          title: 'With Date Received',
          dataType: 'checkbox',
        },
        {
          argument: '_with_date_confirmation',
          title: 'With Date Confirmation',
          dataType: 'checkbox',
        },
        {
          argument: '_wait_my_approval',
          title: 'Waiting my approval',
          dataType: 'checkbox',
        },
      ],
      sorting: { argument: 'client_data_is_checked' },
    },
    {
      key: 'address',
      title: 'Address',
      dataType: 'text',
    },
    {
      key: 'additional_info',
      title: 'Additional Info',
      dataType: 'text',
      filters: [
        {
          argument: '_type_of_activities_list',
          title: 'Type of Activities',
          dataType: 'entity',
          typeExtra: { entity: 'finances.OtherActivity', multiple: true },
        },
      ],
    },
    {
      key: 'regular_status',
      title: 'Regular Status',
      dataType: 'select',
      getValue: (rec) =>
        rec.regular_status ? regularStatusOptions[rec.regular_status] : undefined,
      typeExtra: {
        options: Object.values(regularStatusOptions),
      },

      filters: [
        {
          argument: '_custom_status_list',
          title: 'Custom Status',
          dataType: 'entity',
          typeExtra: { entity: 'core.IndicatorCustomStatus' },
        },
        {
          argument: '_with_custom_status',
          title: 'With Custom Status',
          dataType: 'checkbox',
        },
        {
          argument: '_regular_status_list',
          title: 'Status',
          dataType: 'multiselect',
        },
      ],
    },
    {
      key: 'abbreviation',
      title: 'Abbreviation',
      dataType: 'text',
    },
    {
      key: 'phone',
      title: 'Phone',
      dataType: 'phone',
    },
    {
      key: 'phone_additional',
      title: 'Additional Phone',
      dataType: 'phone',
    },
    {
      key: 'region_titles',
      title: 'Region',
      dataType: 'entity',
      typeExtra: { entity: 'location.Region', multiple: true },
      getValue: (rec) => rec.regions?.map((rc) => ({ id: rc.id, title: rc.title })) ?? [],
      setValue: ({ record, value }) => ({
        ...record,
        regions: value?.map(({ id, title }) => ({ id, name: title })),
      }),
      filters: [
        {
          argument: '_region_list',
          title: 'Region',
          dataType: 'entity',
          typeExtra: { entity: 'location.Region' },
        },
      ],
    },
    {
      key: 'district_title',
      title: 'District',
      dataType: 'entity',
      typeExtra: { entity: 'location.District' },
      getValue: (rec) =>
        rec.district ? [{ id: rec.district, title: rec.district_title }] : undefined,
      setValue: ({ record, value }) => {
        return value?.length
          ? { ...record, district: value[0].id, district_title: value[0].title }
          : record;
      },
      filters: [
        {
          argument: '_district_list',
          title: 'District',
          dataType: 'entity',
          typeExtra: { entity: 'location.District' },
        },
      ],
    },
    {
      key: 'current_address',
      title: 'Current Address',
      dataType: 'text',
    },
    {
      key: 'email',
      title: 'Email',
      dataType: 'email',
    },
    {
      key: 'website',
      title: 'Website',
      dataType: 'url',
    },
    {
      key: 'relations',
      title: 'Relations',
      dataType: 'select',
      getValue: (rec) => (rec.relations ? relationOptions[rec.relations] : undefined),
      typeExtra: {
        options: Object.values(relationOptions),
      },
      filters: [
        {
          argument: '_blacklisted',
          title: 'Blacklisted',
          dataType: 'checkbox',
        },
        {
          argument: '_size_list',
          title: 'Size',
          dataType: 'multiselect',
          typeExtra: {
            options: [
              { value: 'Big', label: 'Big' },
              { value: 'Medium', label: 'Medium' },
              { value: 'Small', label: 'Small' },
            ],
          },
        },
      ],
    },
    { key: 'person', title: 'Person', dataType: 'text' },
    {
      key: 'author_first_name',
      title: 'Author First Name',
      dataType: 'person',
      getValue: (record) => ({
        firstName: record.author_first_name,
        lastName: record.author_last_name,
        avatarSrc: record.author_avatar,
      }),
      editing: false,
      filters: [
        {
          argument: '_author_list',
          title: 'Author',
          dataType: 'entity',
          typeExtra: { entity: 'accounts.User' },
        },
        {
          argument: '_responsible_list',
          title: 'Responsible',
          dataType: 'entity',
          typeExtra: { entity: 'accounts.User' },
        },
        {
          argument: '_responsible_for_confirmation_list',
          title: 'Responsible for Confirmation',
          dataType: 'entity',
          typeExtra: { entity: 'accounts.User' },
        },
      ],
    },
    {
      key: 'editor_first_name',
      title: 'Editor First Name',
      dataType: 'person',
      getValue: (record) => ({
        firstName: record.editor_first_name,
        lastName: record.editor_last_name,
        avatarSrc: record.editor_avatar,
      }),
      editing: false,
    },

    {
      key: 'create_time',
      title: 'Create Time',
      editing: false,
      dataType: 'date',
      filters: [
        { title: 'Create Date' },
        {
          argument: '_date_request',
          title: 'Request Date',
        },
        {
          argument: '_date_received',
          title: 'Received Date',
        },
        {
          argument: '_renewal_date',
          title: 'Renewal Date',
        },
        {
          argument: '_date_confirmation',
          title: 'Confirmation Date',
        },
        {
          argument: '_future_contact',
          title: 'Future Contact',
        },
        {
          argument: '_updates__future_contact',
          title: 'Next Update',
        },
        {
          argument: '_overdue_future_contact',
          title: 'Contact date expired',
          dataType: 'checkbox',
        },
        {
          argument: '_today_future_contact',
          title: 'Contact today',
          dataType: 'checkbox',
        },
      ],
      sorting: {},
    },
    {
      key: 'update_time',
      title: 'Update Time',
      editing: false,
      dataType: 'date',
      sorting: {},
    },
    {
      key: 'supplier_names',
      title: 'Supplier Names',
      dataType: 'text',
      editing: false,
    },
    {
      key: 'exporter_names',
      title: 'Exporter Names',
      dataType: 'text',
      editing: false,
    },
    {
      key: 'sap_vendor_number',
      title: 'SAP Vendor Number',
      dataType: 'text',
    },
    {
      key: 'related_counterparties',
      title: 'Related Counterparties',
      dataType: 'entity',
      typeExtra: { entity: 'clients.Client', multiple: true },
      getValue: (rec) =>
        rec.related_counterparties?.map((rc) => ({ id: rc.id, title: rc.name })) ?? [],
      setValue: ({ record, value }) => ({
        ...record,
        related_counterparties: value?.map(({ id, title }) => ({ id, name: title })),
      }),
      filters: [
        {
          argument: '_related_counterparties_list',
        },
      ],
    },
    {
      key: 'do_num_of_voyages',
      title: 'Number of Voyages',
      dataType: 'text',
      editing: false,
    },
    {
      key: 'renewal_date',
      title: 'Renewal Date',
      dataType: 'date',
      editing: false,
    },
    {
      key: 'predictions_order',
      title: 'Predictions Order',
      dataType: 'number',
    },
    {
      key: 'company_code',
      title: 'Code',
      dataType: 'text',
      sorting: {},
    },
    {
      key: 'business_units',
      title: 'Business Units',
      dataType: 'entity',
      typeExtra: { entity: 'core.BusinessUnit', multiple: true },
      filters: [
        {
          argument: '_business_unit_list',
        },
        {
          argument: '_with_business_unit',
          title: 'With Business Unit',
          dataType: 'checkbox',
        },
      ],
    },
    {
      key: 'payment_conditions_option_title',
      title: 'Payment Conditions Option',
      dataType: 'entity',
      typeExtra: { entity: 'finances.PaymentCondition' },
      getValue: (rec) =>
        rec.payment_conditions_option
          ? [{ id: rec.payment_conditions_option, title: rec.payment_conditions_option_title }]
          : undefined,
      setValue: ({ record, value }) => {
        return value?.length
          ? {
              ...record,
              payment_conditions_option: value[0].id,
              payment_conditions_option_title: value[0].title,
            }
          : record;
      },
      filters: [
        {
          argument: '_payment_conditions_option_list',
          title: 'Payment Conditions Option',
          dataType: 'entity',
          typeExtra: { entity: 'finances.PaymentCondition' },
        },
      ],
    },
    {
      key: 'city',
      title: 'City',
      dataType: 'entity',
      typeExtra: { entity: 'location.City' },
      getValue: (rec) => (rec.city ? [{ id: rec.city, title: rec.city_title }] : undefined),
      setValue: ({ record, value }) => ({
        ...record,
        city: value?.[0]?.id,
        city_title: value?.[0]?.title,
      }),

      filters: [
        {
          argument: '_city_list',
          dataType: 'entity',
          typeExtra: { entity: 'location.City' },
        },
      ],
    },
    {
      key: 'country_name',
      title: 'Country',
      dataType: 'entity',
      typeExtra: { entity: 'location.Country' },
      getValue: (rec) => (rec.country ? [{ id: rec.country, title: rec.country_name }] : undefined),
      setValue: ({ record, value }) => ({
        ...record,
        country: value?.[0]?.id,
        country_name: value?.[0]?.title,
      }),
      filters: [
        {
          argument: '_country_list',
          title: 'Country',
          dataType: 'entity',
          typeExtra: { entity: 'location.Country' },
        },
        {
          argument: '_is_resident',
          title: 'Resident',
          dataType: 'checkbox',
        },
        {
          argument: '_local_currency',
          title: 'Local Currency',
          dataType: 'checkbox',
        },
        {
          argument: '_foreign_currency',
          title: 'Foreign Currency',
          dataType: 'checkbox',
        },
      ],
      sorting: {},
    },
    {
      key: 'custom_status_title',
      title: 'Custom status',
      dataType: 'entity',
      typeExtra: { entity: 'clients.ClientsCustomStatus' },
      getValue: (rec) =>
        rec.custom_status ? [{ id: rec.custom_status, title: rec.custom_status_title }] : undefined,
      setValue: ({ record, value }) => {
        return value?.length
          ? { ...record, custom_status: value[0].id, custom_status_title: value[0].title }
          : record;
      },
    },
    {
      key: 'logistics_count',
      title: 'Logistics',
      dataType: 'number',
      editing: false,
    },
    {
      key: 'persons_count',
      title: 'Stuff',
      dataType: 'number',
      editing: false,
    },
    {
      key: '_updated_by',
      title: 'Updated',
      dataType: 'person',
      getValue: (record) => ({
        firstName: record.editor_first_name,
        lastName: record.editor_last_name,
        avatarSrc: record.editor_avatar,
      }),
      editing: false,
    },
    {
      key: 'warehouses_list',
      title: 'Warehouse',
      dataType: 'text',
      editing: false,
      getValue: (rec) =>
        rec.warehouses_list?.length ? rec.warehouses_list.map((item) => item.title).join(', ') : '',
    },
    { key: 'date_request', title: 'Requested', dataType: 'date' },
    {
      key: 'size',
      dataType: 'select',
      title: 'Size',
      getValue: (rec) => sizeOptions[rec.size],
      typeExtra: {
        options: Object.values(sizeOptions),
      },
    },
    {
      key: 'limit_one_deal_in_usd_spot',
      title: 'Value per deal (spot)',
      dataType: 'number',
      editing: false,
    },
    {
      key: 'limit_general_volume_forward',
      title: 'Total volume (forward)',
      dataType: 'number',
      editing: false,
    },
    { key: 'person_signatory_docs', title: 'Signatory docs', dataType: 'string' },
    {
      key: 'basis_names',
      dataType: 'string',
      editing: false,
      title: 'Basises',
      getValue: (rec) => rec.basis_names?.join(', '),
    },
    { key: 'future_contact', dataType: 'date', title: 'Future contact' },
    {
      key: 'limit_general_in_usd_forward',
      dataType: 'number',
      editing: false,
      title: 'Value general (forward)',
    },
    {
      key: 'limit_general_in_usd_spot',
      dataType: 'number',
      editing: false,
      title: 'Value general (spot)',
    },
    { key: 'transports_count', dataType: 'number', editing: false, title: 'Transports count' },
    { key: 'full_name', dataType: 'string', editing: false, title: 'Full name' },
    { key: 'cash_flow', dataType: 'string', editing: false, title: 'Cash flow' },
    {
      key: 'limit_general_volume_spot',
      dataType: 'string',
      editing: false,
      title: 'Total volume (spot)',
    },
    { key: 'first_contact', dataType: 'date', title: 'First contact' },
    { key: 'assets', dataType: 'string', editing: false },
    { key: 'can_confirm', dataType: 'checkbox', editing: false, title: 'Can confirm' },
    { key: 'person_signatory', title: 'Signatory', dataType: 'string' },
    {
      key: 'limit_one_deal_volume_spot',
      title: 'Volume per deal (spot)',
      dataType: 'number',
      editing: false,
    },
    { key: 'address_eng', title: 'Address eng', dataType: 'string' },
    { key: 'pledge', dataType: 'string', editing: false },
    {
      key: 'clientupdate_next_future_contact',
      dataType: 'date',
      title: 'Next contact',
      editing: false,
    },
    { key: 'person_signatory_eng_docs', dataType: 'string', title: 'Signatory docs eng' },
    { key: 'full_name_eng', title: 'Full name eng', dataType: 'string', editing: false },
    { key: 'person_signatory_eng', title: 'Signatory eng', dataType: 'string' },
    { key: 'date_first_confirmation', title: 'First confirmed', dataType: 'date' },
    {
      key: 'limit_open_balance_volume_forward',
      title: 'Open balance (forward)',
      dataType: 'number',
      editing: false,
    },
    {
      key: 'limit_open_balance_volume_spot',
      title: 'Open balance (spot)',
      dataType: 'number',
      editing: false,
    },
    {
      key: 'limit_one_deal_in_usd_forward',
      title: 'Value per deal (forward)',
      dataType: 'number',
      editing: false,
    },
    {
      key: 'clientupdate_last_create_time',
      title: 'Last update',
      dataType: 'date',
      editing: false,
    },
    { key: 'date_received', title: 'Received', dataType: 'date' },
    { key: 'crop_area', dataType: 'number', title: 'Crop area', editing: false },
    {
      key: 'limit_one_deal_volume_forward',
      title: 'Volume per deal (forward)',
      dataType: 'number',
      editing: false,
    },
  ];
  return Promise.resolve(fields);
};
