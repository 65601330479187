import type ng from 'angular';

import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class ReservationsService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  ReservationDetailResource: any;
  ReservationsListResource: any;
  SRUService: any;
  constructor(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    SRUService: any,
  ) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.SRUService = SRUService;
    this.ReservationDetailResource = $resource(
      '/api/warehouses/reservations/:id/',
      {
        id: '@id',
      },
      {
        save: { method: 'POST' },
        update: { method: 'PATCH' },
        delete: { method: 'DELETE' },
        predictions: {
          method: 'GET',
          url: '/api/warehouses/reservations/predictions/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/warehouses/reservations/export_column_names/',
        },
      },
    );
    this.ReservationsListResource = $resource(
      '/api/warehouses/reservations-list/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        getBalance: {
          method: 'POST',
          isArray: false,
          url: '/api/warehouses/reservations-list/balance/',
          cancellable: true,
        },
      },
    );
  }
  get(id: number) {
    return this.ReservationDetailResource.get({ id: id }).$promise;
  }
  save(item: any) {
    if (item.id) {
      return this.ReservationDetailResource.update(item).$promise;
    }
    return this.ReservationDetailResource.save(item).$promise;
  }
  delete(item: any) {
    return this.ReservationDetailResource.delete({ id: item.id }).$promise;
  }
  _getList(params: object) {
    return this.ReservationsListResource.query({
      serializer: 'depth',
      ...params,
    }).$promise;
  }
  openModal(item: any, extra: any) {
    let _item = {};
    if (item) {
      _item = { ...item };
    }
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<reservations-modal
        item="item"
        modal-instance="$uibModalInstance"
      ></reservations-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
          ($scope as any).item = _item;
          ($scope as any).extra = extra;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result;
  }
  getBalanceParams(item: any) {
    const balanceParams = {
      rest_date: item.date,
      groups: [],
      stock_reserve_unit: item.from_stock_reserve_unit,
      business_unit: item.business_unit,
      commodity: item.commodity,
      warehouse: item.warehouse,
      contract_position: item.contract_position,
      producer: item.producer,
      receiver: item.receiver,
      batch: item.batch,
    };
    if (item.from_stock_reserve_unit) {
      return this.SRUService.get(item.from_stock_reserve_unit).then((sru: any) => ({
        ...balanceParams,
        business_unit: sru.business_unit,
      }));
    } else if (!item.business_unit && item.to_stock_reserve_unit) {
      this.SRUService.get(item.to_stock_reserve_unit).then((sru: any) => ({
        ...balanceParams,
        business_unit: sru.business_unit,
      }));
    }
    return Promise.resolve().then(() => balanceParams);
  }
  getBalance(params: object) {
    return this.ReservationsListResource.getBalance(params);
  }
}
ReservationsService.$inject = ['$resource', '$uibModal', 'StockReserveUnitsService'];
