import type ng from 'angular';

import type { QueryParams } from '^/app/core/types';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class SettingsParsingService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  QuotesParsingConfigsResource: any;
  constructor($resource: ng.resource.IResourceService, $uibModal: ng.ui.bootstrap.IModalService) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;

    this.QuotesParsingConfigsResource = this.$resource(
      '/api/stockexchanges/quotes-parsing-configs/:id/',
      { id: '@id' },
      {
        query: { method: 'GET' },
        update: { method: 'PATCH' },
        getData: {
          method: 'GET',
          isArray: false,
          url: '/api/stockexchanges/quotes-parsing-configs-info/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/stockexchanges/quotes-parsing-configs/predictions/',
        },
      },
    );
  }

  getQuoteParsingConfigsData(queryParams: QueryParams) {
    return this.QuotesParsingConfigsResource.getData(queryParams).$promise;
  }

  getQuoteParsingConfigModalData(quoteParsingConfig: any) {
    return this.QuotesParsingConfigsResource.get(quoteParsingConfig).$promise;
  }

  saveQuoteParsingConfig(quoteParsingConfig: any) {
    if (quoteParsingConfig.id) {
      return this.QuotesParsingConfigsResource.update(quoteParsingConfig).$promise;
    }
    return this.QuotesParsingConfigsResource.save(quoteParsingConfig).$promise;
  }

  destroyQuotesParsingConfig(quoteParsingConfig: any) {
    return this.QuotesParsingConfigsResource.delete(quoteParsingConfig).$promise;
  }

  quotesParsingConfigsModal(quoteParsingConfig: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      appendTo: getModalRoot(),
      template: html`<quotes-parsing-configs-modal
        quote-parsing-config="quoteParsingConfig"
        modal-instance="$uibModalInstance"
      >
      </quotes-parsing-configs-modal>`,
      controller: [
        '$scope',
        'quoteParsingConfig',
        '$uibModalInstance',
        function (
          $scope: ng.IScope,
          quoteParsingConfig: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) {
          ($scope as any).quoteParsingConfig = quoteParsingConfig;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template modal-template-half-width',
      size: 'sm',
      resolve: {
        quoteParsingConfig: () => quoteParsingConfig,
      },
    }).result;
  }
}

SettingsParsingService.$inject = ['$resource', '$uibModal'];
