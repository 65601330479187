import ng from 'angular';

import type { HelpButtonsService } from '^/app/core/legacy/help-buttons/help-buttons.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('voyagesTable', {
    bindings: {
      voyages: '<',
      count: '<',
      filterLevel: '<?',
      onChange: '&',
      addButton: '@?',
      view: '<?',
    },
    template: require('./voyages-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'LogisticsService', 'HelpButtonsService', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    LogisticsService: LogisticsService,
    HelpButtonsService: HelpButtonsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.theadConfig = getTheadConfig();
    vm.expanded = false;

    vm.block = 'info';
    vm.toggBlock = toggBlock;
    vm.updateData = updateData;
    vm.queryParams = {};
    vm.search = search;
    vm.setHovering = setHovering;
    vm.hovering = false;
    vm.openVoyageModal = openVoyageModal;

    ////////////////

    vm.$onInit = function () {
      vm.view = vm.view || 'block';
      $scope.$on('hb-config-updated', function () {
        vm.expanded = HelpButtonsService.getTableConfig().expanded;
      });
    };

    function setHovering(value: any) {
      vm.hovering = value;
    }

    function search() {
      return updateData(vm.queryParams);
    }

    function toggBlock(block: any) {
      vm.block = block;
      vm.theadConfig = getTheadConfig();
      updateData({ serializer: 'table_' + block });
    }

    function updateData(params: object) {
      $scope.$emit('voyages-update', params);
    }

    function openVoyageModal(voyage: any) {
      return LogisticsService.voyageModal(voyage).then(vm.onChange);
    }

    function getTheadConfig() {
      return [
        {
          title: gettext('voyage'),
          predicate: 'voyage_name',
        },

        {
          title: gettext('parameters'),
          predicate: 'vessel__capacity',
        },
        {
          title: gettext('commodity'),
        },
        {
          title: gettext('dates'),
          predicate: 'cp_date',
        },
        {
          title: gettext('Port of loading'),
          predicate: 'port_loading_name',
        },

        {
          title: gettext('Port of destination'),
          predicate: 'port_destination_name',
        },

        {
          title: gettext('Rates'),
        },
        {
          title: gettext('Contracts'),
        },
        {
          title: gettext('notes'),
        },
        {
          title: gettext('docs'),
        },

        {
          title: gettext('last update'),
          predicate: 'update_time',
        },
      ];
    }
  }
})();
