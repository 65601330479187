export class DebitCreditService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getReportData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/debit-credit/',
      params: params,
    }).then((res: any) => res.data);
  }
}
DebitCreditService.$inject = ['$http'];
