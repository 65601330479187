import { client } from '@gt/api';

import { getCookies } from '~/shared/lib/utils';

let ctrfToken = getCookies(document).csrftoken;

client.setConfig({
  baseUrl: '',
});

client.interceptors.response.use((response) => {
  setTimeout(() => (ctrfToken = getCookies(document).csrftoken), 0);
  return response;
});

client.interceptors.request.use((request) => {
  request.headers.set('X-CSRFToken', ctrfToken);
  return request;
});

export { client };
