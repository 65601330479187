import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import { useComponent } from '~/shared/lib/components';

import type { ExportLogisticListContainerProps } from './export-logistic-list.model';
import { ExportLogisticListModel } from './export-logistic-list.model';

export const ExportLogisticContainer = (props: ExportLogisticListContainerProps) => {
  const { ComponentProvider } = useComponent(props, ExportLogisticListModel);

  return (
    <ComponentProvider>
      <EntityListContainer />
    </ComponentProvider>
  );
};
