import type ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';
import { html } from '^/shared/utils';

export const PurchasePlanPage = {
  bindings: {},
  template: html`
    <purchase-plan-table
      filter-level="$ctrl.filterLevel"
      table-data="$ctrl.tableData"
    ></purchase-plan-table>
  `,
  controller: [
    'gettext',
    'PageService',
    'ReportsService',
    class {
      PageService: PageService;
      ReportsService: ReportsService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      constructor(
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
        ReportsService: ReportsService,
      ) {
        this.gettext = gettext;
        this.PageService = PageService;
        this.ReportsService = ReportsService;

        this.filterLevel = 'purchase-plan-report-page-view';
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.Filters(),
          ],
          pages: this.ReportsService.getPagesConfig(),
        };

        config.filters = {
          filterLevel: this.filterLevel,
          clicked: false,
          search: false,
          dates: true,
          dateSelects: [],
          multiSelects: [],
          nestedSelects: [],
          nestedMultiSelects: [],
        };

        return config;
      }
    },
  ],
};
