import { coreBusinessUnitsList, coreBusinessUnitsPredictionsRetrieve } from '~/shared/api';
import { EntityRepository } from '~/shared/lib/entities';
import type { EntityOption } from '~/shared/ui/data-types';

import type { BusinessUnit, BusinessUnitQueryParams } from '../lib/';

export class BusinessUnitRepository extends EntityRepository<
  BusinessUnit,
  BusinessUnitQueryParams
> {
  readonly entityName = 'core.BusinessUnit';

  getFn = (_id: number): Promise<BusinessUnit> => {
    throw new Error('Method not implemented.');
  };
  createFn = (_entity: BusinessUnit): Promise<BusinessUnit> => {
    throw new Error('Method not implemented.');
  };
  updateFn = (_entity: Partial<BusinessUnit>): Promise<BusinessUnit> => {
    throw new Error('Method not implemented.');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: BusinessUnitQueryParams) => {
    const { data } = await coreBusinessUnitsList({ query });
    return { records: data, count: data.length };
  };

  searchFn = async (input: string): Promise<EntityOption[]> => {
    const { data } = await coreBusinessUnitsPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
}
