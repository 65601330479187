import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './passport-connection-shipment-plan-list-container.html?raw';
import type { PassportConnectionShipmentPlanService } from '../passport-connection-shipment-plan.service';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const PassportConnectionShipmentPlanListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    '$state',
    'GtUtils',
    'PassportConnectionShipmentPlanService',
    'gtFilterService',
    'gettext',
    class {
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      GtUtils: GtUtilsService;
      PassportConnectionShipmentPlanService: PassportConnectionShipmentPlanService;
      close: any;
      count: number;
      data: any;
      filterLevel = 'passport-connection-shipment-plan-container';
      gettext: ng.gettext.gettextFunction;

      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      passportTotalShow: any;
      queryParams: QueryParams = {};
      totals: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        PassportConnectionShipmentPlanService: PassportConnectionShipmentPlanService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.PassportConnectionShipmentPlanService = PassportConnectionShipmentPlanService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;
        this.data = {};
        this.totals = {};
        this.count = 0;
        this.passportTotalShow = false;
        this.queryParams = {
          ...this.initQueryParams,
        };
      }

      $onInit() {
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          if (!data.plan_start_date || !data.plan_end_date) {
            this.queryParams = Object.assign(data, this.initQueryParams);
          } else {
            this.queryParams = data;
          }
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');

        return this.PassportConnectionShipmentPlanService.getTableGroupedData(
          this.queryParams,
        ).then((data: any) => {
          this.totals = data;
          this.data = data.data;
          this.count = data.count;
          this.GtUtils.overlay('hide');
        });
      }

      openModal(plan: any) {
        return this.PassportConnectionShipmentPlanService.modal(plan).then(() => this.updateData());
      }

      openCloneModal(plan: any) {
        return this.PassportConnectionShipmentPlanService.getModalData({ id: plan.id })
          .then((data: any) => {
            const clonedPlan = { ...data, id: null };
            return this.PassportConnectionShipmentPlanService.modal(clonedPlan).then(() =>
              this.updateData(),
            );
          })
          .catch(() => {
            this.GtUtils.overlay('hide');
            notify(this.gettext("Plan doesn't exist"));
            this.$state.go('logistics.planningPassportConnectionShipmentPlan');
          });
      }

      destroy(planId: number) {
        if (!confirm(this.gettext("Are you sure that you want delete 'Plan'?"))) {
          return;
        }
        return this.PassportConnectionShipmentPlanService.delete(planId).then(() => {
          notify(this.gettext('Plan removed'));
          this.updateData();
          this.close('delete', true);
        });
      }

      applyFilter() {
        return this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }
    },
  ],
};
