import ng from 'angular';

import template from './additional-agreements-table.tpl.html?raw';

import type { GtRootScopeService } from '^/app/core/types';
(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('additionalAgreementsTable', {
    bindings: {
      additionalAgreements: '<',
      count: '<',
      edit: '@?',
    },
    template,
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$rootScope', 'gettext'];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.theadConfig = getTheadConfig();

    activate();

    ////////////////

    function activate() {
      vm.count = vm.count || 0;
      vm.edit = vm.edit || false;
    }

    function getTheadConfig() {
      const config: any = [
        {
          title: gettext('Created'),
        },
        {
          title: gettext('Number'),
        },
        {
          title: gettext('Date'),
        },
        {
          title: gettext('Cargo'),
        },
        {
          title: gettext('Volume'),
        },
        {
          title: gettext('Volume options'),
        },
        {
          title: gettext('Price'),
        },
        {
          title: gettext('Premium price'),
          hideExpression: !$rootScope.user.settings.CONTRACT_OPTION_MODEL,
        },
        {
          title: gettext('Ports'),
        },
        {
          title: gettext('Exporters'),
        },
        {
          title: gettext('Date of execution'),
        },
        {
          title: gettext('End of execution'),
        },
        {
          title: gettext('Original available'),
        },
        {
          title: gettext('Additional info'),
        },
        {
          title: gettext(''),
        },
      ];

      return config.filter((col: any) => !col.hideExpression);
    }
  }
})();
