import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './warehouse-modal.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { WarehousesFormFieldsService } from '^/app/execution/components/warehouse/warehouse/details/warehouse-form-fields.service';
import type { WarehousesService } from '^/app/execution/components/warehouse/warehouse/warehouse.service';

export const WarehouseModal = {
  bindings: {
    modalInstance: '<',
    warehouse: '<?',
  },
  template,
  controller: [
    '$scope',
    '$state',
    'GtUtils',
    'gettext',
    'WarehousesService',
    'WarehousesFormFieldsService',
    'ClientsService',
    'AccountsService',
    class {
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      GtUtils: GtUtilsService;
      WarehousesFormFieldsService: WarehousesFormFieldsService;
      ClientsService: any;
      AccountsService: AccountsService;
      WarehousesService: WarehousesService;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      warehouse: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        WarehousesService: WarehousesService,
        WarehousesFormFieldsService: WarehousesFormFieldsService,
        ClientsService: any,
        AccountsService: AccountsService,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.WarehousesService = WarehousesService;
        this.WarehousesFormFieldsService = WarehousesFormFieldsService;
        this.ClientsService = ClientsService;
        this.AccountsService = AccountsService;

        this.modalInstance = undefined;

        this.form = undefined;
        this.fields = [];
        this.warehouse = {};
      }

      $onInit() {
        this.warehouse = this.warehouse || this.initNewWarehouse();

        this.$scope.$watch(
          '$ctrl.warehouse.responsible',
          (newVal: number | undefined, oldVal: number | undefined) => {
            if (newVal !== oldVal) {
              this.setResponsibleWorkStatus();
            }
          },
        );

        if (this.warehouse.id) {
          return this.updateData();
        }
        return this.updateFields();
      }

      initNewWarehouse() {
        return {
          warehouse_type: 'warehouse',
        };
      }

      updateData() {
        this.GtUtils.overlay('show');

        return this.WarehousesService.getWarehouse(this.warehouse.id)
          .then((data: any) => {
            this.warehouse = data;
            this.updateFields().catch(this.GtUtils.errorClb);
          })
          .catch(() => {
            notify(this.gettext("Warehouse doesn't exist"));
            this.$state.go('logistics.warehousesList');
          })
          .finally(() => this.GtUtils.overlay('hide'));
      }

      close(data: any, silent: boolean) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        if (!confirm(this.gettext('Are you sure that you want delete this warehouse?'))) {
          return;
        }
        return this.WarehousesService.deleteWarehouse({ id: this.warehouse.id }).then(() => {
          notify(this.gettext('Warehouse deleted'));
          this.close('delete', true);
        });
      }

      save() {
        return this.WarehousesService.saveWarehouse(this.warehouse)
          .then((data: any) => this.close(data, true))
          .catch(this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }

      openFieldsConfigModal() {
        this.WarehousesFormFieldsService.openWarehouseFieldsConfigModal(this.warehouse).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        return this.WarehousesFormFieldsService.getFields(this.warehouse).then((fields: any) => {
          this.fields = fields;
        });
      }

      setResponsibleWorkStatus = () => {
        return this.ClientsService.Person.query({ id: this.warehouse.responsible }).$promise.then(
          (personDetails: any) => {
            if (personDetails.user) {
              return this.AccountsService.User.get(personDetails.user).then((userDetails: any) => {
                this.warehouse.responsibleWorkStatus = userDetails.profile.work_status;
                return userDetails;
              });
            }
          },
        );
      };
    },
  ],
};
