import React from 'react';

import { RecordsActions } from '~/core/datasets/components/records-actions';
import type { ActionDef, DataRecord } from '~/core/datasets/lib/types';
import { Toolbar, ToolbarSeparator } from '~/shared/ui/kit/toolbar';

import { ActionBarList } from './action-bar-list';
import type { ActionBarConfig } from './types';

export const ActionBar = <R extends DataRecord>({
  title,
  config,
  actions = [],
  records = [],
}: {
  title: React.ReactNode;
  config: ActionBarConfig[];
  // @Y-Havryliv тобі добре відомо, що ми не можемо імпортувати в shared з core. Виправ одразу
  actions?: ActionDef<R>[];
  records?: R[];
}) => {
  return (
    <div className="pointer-events-none fixed bottom-0 left-0 z-40 flex w-full justify-center">
      <Toolbar
        className="bg-background-main-primary pointer-events-auto flex h-[60px] max-w-fit min-w-0 items-center gap-2 px-5 shadow-2xl"
        data-testid="action-bar"
      >
        {title}
        <ToolbarSeparator className="h-5" />
        <ActionBarList config={config} />
        {!!records.length && (
          <RecordsActions data-testid="records-actions" actions={actions} records={records} />
        )}
      </Toolbar>
    </div>
  );
};
