import React from 'react';

import { Tag } from '~/shared/ui/kit/tag';

import type { DataTypeProps } from '../../lib';

export const LabelRead: React.FC<DataTypeProps<'label'>> = ({ disabled, value }) =>
  value ? (
    <Tag disabled={disabled} {...value.tagProps}>
      {value.label}
    </Tag>
  ) : null;
