import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').config(configFunction);

  configFunction.$inject = ['$stateProvider', 'gettext'];

  function configFunction(
    $stateProvider: ng.ui.IStateProvider,
    gettext: ng.gettext.gettextFunction,
  ) {
    $stateProvider
      .state('reports.confirmations', {
        url: '/confirmations',
        component: 'confirmationsReportPageView',
        data: {
          pageTitle: gettext('Confirmations'),
          permissions: {
            only: 'view_confirmationsreport',
          },
        },
      })
      .state('reports.signings', {
        url: '/signings',
        component: 'signingsReportPageView',
        data: {
          pageTitle: gettext('Signings'),
          permissions: {
            only: 'view_signingsreport',
          },
        },
      })
      .state('kpi', {
        parent: 'reports',
        url: '/kpi',
        abstract: true,
        template: '<ui-view>',
      })
      .state('kpi.confirmations', {
        url: '/confirmations',
        component: 'kpiconfirmationsReportPageView',
        data: {
          pageTitle: gettext('KPI confirmations'),
          permissions: {
            only: 'view_kpiconfirmationsreport',
          },
        },
      })
      .state('kpi.signings', {
        url: '/signings',
        component: 'kpisigningsReportPageView',
        data: {
          pageTitle: gettext('KPI signings'),
          permissions: {
            only: 'view_kpisigningsreport',
          },
        },
      })
      .state('position', {
        parent: 'reports',
        url: '/position',
        abstract: true,
        template: '<ui-view>',
      })
      .state('position.physical', {
        url: '/physical',
        component: 'physicalPositionPageView',
        data: {
          pageTitle: gettext('Physical position'),
          permissions: {
            only: 'view_position',
          },
        },
        resolve: {
          filterLevel: () => 'physical-position-page',
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return {
                internal_chain: '0',
                ...PageService.syncUrlFilter('physical-position-page'),
              };
            },
          ],
          subtab: [
            'PageService',
            function (PageService: PageService) {
              return (
                PageService.syncUrlFilter('physical-position-page').subtab?.toString() ??
                'consolidated'
              );
            },
          ],
          marketPriceType: [
            'PageService',
            function (PageService: PageService) {
              return (
                PageService.syncUrlFilter('physical-position-page').marketPriceType?.toString() ??
                'opposite_price_usd'
              );
            },
          ],
          sourcePrice: [
            'PageService',
            function (PageService: PageService) {
              return (
                PageService.syncUrlFilter('physical-position-page').sourcePrice?.toString() ??
                'contract_price'
              );
            },
          ],
        },
      })
      .state('lineup', {
        parent: 'reports',
        url: '/lineup',
        abstract: true,
        template: '<ui-view>',
      })
      .state('lineup.lineup', {
        url: '/',
        component: 'lineupPageView',
        data: {
          pageTitle: gettext('Lineup'),
          permissions: {
            only: 'view_lineup',
          },
        },
        resolve: {
          filterLevel: () => {
            return 'lineup-page-view';
          },
          queryParams: [
            'PageService',
            'gtFilterService',
            function (PageService: PageService, gtFilterService: GtFilterService) {
              const queryParams = PageService.syncUrlFilter('lineup-page-view');
              queryParams.cargo_list = parseInt(String(queryParams.cargo_list), 10) || null;
              gtFilterService.setQueryParams(queryParams, 'lineup-page-view');
            },
          ],
        },
      })
      .state('gt.old-page.reports', {
        url: '/reports/:type?',
        controller: 'ReportsController',
        controllerAs: 'vm',
        template: require('./reports.tpl.html?raw'),
        reloadOnSearch: false,
        data: {
          pageTitle: gettext('Reports'),
          permissions: {
            only: 'view_reports',
          },
        },
      });
  }
})();
