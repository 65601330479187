import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './contract-pl-container.html?raw';
import type { ContractsService } from '../legacy/contracts.srv';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';

export const ContractPlContainer = {
  bindings: {
    contractData: '<',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'ContractsService',
    'gtFilterService',
    'gettext',
    class {
      $scope: ng.IScope;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      charges: any;
      contract: any;
      contractData: any;
      data: any;
      filterLevel = 'contract-pl-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        ContractsService: ContractsService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.ContractsService = ContractsService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.data = {};
        this.charges = [];
        this.contract = {};
      }

      $onInit() {
        this.contract = { ...this.contractData };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, () => {
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.getCharges();
      }

      updateData() {
        this.GtUtils.overlay('show');

        return this.ContractsService.refreshContract(this.contract, true)
          .then((contractData: any) => {
            this.contract = { ...this.contract, ...contractData };

            return this.ContractsService.Contract.getContractFactPl({
              id_list: [this.contract.id],
            }).$promise;
          })
          .then((plData: any) => {
            this.contract.connections.forEach((conn: any) => {
              conn.total_margin = plData.results
                .filter((row: any) => row.passport_id === conn.passport)
                .pop()?.total_margin;
            });

            return this.ContractsService.Contract.getPl({ contract: this.contract.id }).$promise;
          })
          .then((data: any) => {
            this.data = (data.results || [])[0];
          })
          .catch((err: any) => {
            this.GtUtils.errorClb(err);
            notify(this.gettext("Contract doesn't exist"));
          })
          .finally(() => {
            this.GtUtils.overlay('hide');
          });
      }

      getCharges() {
        this.GtUtils.overlay('show');
        return this.ContractsService.ContractCharge.queryInfo(
          {
            contract_list: [this.contract.id],
            status_list: [
              'validated',
              'under_discussion',
              'pending_confirmation',
              'forecast',
              'closed',
            ],
          },
          (data: any) => {
            this.charges = data.results;
            this.GtUtils.overlay('hide');
          },
        ).$promise;
      }
    },
  ],
};
