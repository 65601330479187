import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './vehicles-modal.html?raw';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const VehicleModal = {
  bindings: {
    modalInstance: '<',
    vehicle: '<?',
    extra: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$state',
    'gettext',
    'GtUtils',
    'CoreService',
    'FormFieldParamsService',
    'LogisticsService',
    'VehiclesService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      gettext: ng.gettext.gettextFunction;
      GtUtils: GtUtilsService;
      CoreService: CoreService;
      FormFieldParamsService: FormFieldParamsService;
      LogisticsService: LogisticsService;
      VehiclesService: any;

      extra: any;
      fields: any;
      form: any;
      modalInstance: any;
      tab: string;
      vehicle: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        CoreService: CoreService,
        FormFieldParamsService: FormFieldParamsService,
        LogisticsService: LogisticsService,
        VehiclesService: any,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.CoreService = CoreService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.LogisticsService = LogisticsService;
        this.VehiclesService = VehiclesService;

        this.tab = 'edit';
        this.form = undefined;
        this.fields = [];
      }

      $onInit() {
        this.vehicle = this.vehicle || this.initNewVehicle();
        this.extra = this.extra || {};
        this.tab = this.extra.tab || 'edit';
        if (this.vehicle.id) {
          this.updateData();
        } else {
          this.getContentType();
          this.updateFields();
        }
      }

      updateData() {
        this.GtUtils.overlay('show');

        return this.VehiclesService.getVehicle(this.vehicle.id)
          .then((data: any) => {
            this.vehicle = data;
            this.updateFields();
            this.GtUtils.overlay('hide');
          })
          .catch(() => {
            this.GtUtils.overlay('hide');
            notify(this.gettext("Vehicle doesn't exist"));
            this.$state.go('vehicles.list');
          });
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        if (!confirm(this.gettext("Are you sure that you want delete 'Vehicle'?"))) {
          return;
        }
        return this.VehiclesService.deleteVehicle(this.vehicle.id).then(() => {
          notify(this.gettext('Vehicle removed'));
          this.close('delete', true);
        });
      }

      save() {
        return this.VehiclesService.saveVehicle(this.vehicle).then((data: any) => {
          this.GtUtils.overlay('hide');
          this.close(data, true);
        }, this.GtUtils.errorClb);
      }

      initNewVehicle() {
        return { ...this.vehicle };
      }

      getContentType() {
        this.CoreService.getModelContentType('vehicle').then((data: any) => {
          this.vehicle.content_type = data.id;
        });
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        const classes = 'form-group-container col-xs-12 col-sm-8 center-block';
        const fieldsConfig: any = {
          formName: 'vehicle-modal',
          fieldsDef: [{ className: classes, fieldGroup: [] }],
        };
        fieldsConfig.fieldsDef[0].fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: 'INFO',
          },
          fieldGroup: [
            {
              key: 'number',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Vehicle number'),
                type: 'text',
              },
            },
            {
              key: 'technical_passport_number',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Technical passport number'),
                type: 'text',
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Business unit'),
                placeholder: this.gettext('Business Unit'),
                resource: 'core.BusinessUnit',
              },
            },
            {
              key: 'vehicle_type',
              type: 'gt-select',
              templateOptions: {
                label: this.gettext('Vehicle type'),
                placeholder: this.gettext('Choose vehicle type'),
                valueProp: 'value',
                labelProp: 'name',
                hint: this.gettext('Choose vehicle type'),
                options: [
                  { value: 'car', name: this.gettext('Car') },
                  { value: 'trailer', name: this.gettext('Trailer') },
                ],
              },
            },
            {
              key: 'vehicle_subtype',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Vehicle subtype'),
                placeholder: this.gettext('Vehicle subtype'),
                resource: 'logistics.VehicleSubtype',
              },
            },
            {
              key: 'predictions_order',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Predictions order'),
                type: 'number',
              },
            },
          ],
        });

        return fieldsConfig;
      }
    },
  ],
};
