import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('common.crops.legacy').controller('LogisticsPricesPageController', Controller);

  Controller.$inject = [
    '$scope',
    '$stateParams',
    '$location',
    '$q',
    'ClientsService',
    'gtFilterService',
    'CropsService',
    'gettext',
    'GtUtils',
    'FinancesService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $stateParams: ng.ui.IStateParamsService,
    $location: ng.ILocationService,
    $q: ng.IQService,
    ClientsService: any,
    gtFilterService: GtFilterService,
    CropsService: any,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    FinancesService: FinancesService,
  ) {
    const vm = this;

    vm.filterLevel = 'crops-logistics-page';
    vm.rolesList = ['Elevator', 'Farm'];
    vm.role = 'Farm';
    vm.roleIcon = '';
    vm.places = [];
    vm.placesCount = 0;
    vm.crops = [];
    vm.queryParams = { page_size: 5 };
    vm.changeRole = changeRole;
    vm.prices = {};
    vm.uahRate = 0;
    vm.convert = convert;
    vm.currency = 'uah';
    vm.changeType = changeType;
    vm.maintab = 'places';
    vm.pricesQueryParams = { show_expired: false };

    vm.$onInit = function () {
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateData();
      });
      updateData();
      if ($stateParams.type) {
        changeType($stateParams.type);
      }
      if ($stateParams.roleName && $stateParams.roleId && $stateParams.cropId) {
        vm.role = $stateParams.roleName;
        vm.editTariffs(parseInt($stateParams.roleId, 10), parseInt($stateParams.cropId, 10));
      }
    };

    ////////////////

    function updateData() {
      GtUtils.overlay('show');
      $q.all([updateCounterparties(), updateCrops()]).then(function () {
        vm.theadConfig = getTheadConfig();
        vm.roleIcon = GtUtils.getIcon('clients.' + vm.role);
        updateUahRate();
        GtUtils.overlay('hide');
      });
    }

    function changeType(type: any) {
      $location.path('/logistics/' + type);
      vm.tab = type;
    }

    function updateCounterparties() {
      return ClientsService[vm.role].query(vm.queryParams, function (data: any) {
        vm.places = data.results;
        vm.placesCount = data.count;
      }).$promise;
    }

    function updateCrops() {
      return CropsService.Crop.query({ prices_section: 1 }, function (data: any) {
        vm.crops = data.results;
      }).$promise;
    }

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: gettext(vm.role),
          predicate: 'name',
        },
        {
          title: gettext('Location'),
        },
      ];
      if (vm.role == 'Elevator') {
        config.columns.push({
          title: gettext('Services'),
        });
      }
      config.columns.push({
        title: gettext('Ports'),
      });
      vm.crops.forEach(function (crop: any) {
        config.columns.push({
          title: gettext(crop.title),
          class: 'th-center-align',
        });
      });
      return config;
    }

    function updateUahRate() {
      FinancesService.CurrencyExchange.query(
        {
          currency: GtUtils.getLocalCurrency(),
        },
        function (data: any) {
          vm.uahRate = data.count && data.results[0].rate;
        },
      );
    }

    function convert(value: any, from: any) {
      from = from || 'usd';
      value = parseFloat(value) || 0;
      const rate = vm.uahRate || 1;
      if (from == vm.currency) {
        return value;
      } else if (from == 'usd') {
        return value * rate;
      } else {
        return value / rate;
      }
    }

    function changeRole() {
      gtFilterService.updateQueryParams({ page: undefined }, vm.filterLevel);
    }
  }
})();
