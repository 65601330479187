import ng from 'angular';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

(function () {
  'use strict';
  ng.module('company.documents.legacy').config(configFunction);

  configFunction.$inject = ['$stateProvider', 'gettext'];

  function configFunction(
    $stateProvider: ng.ui.IStateProvider,
    gettext: ng.gettext.gettextFunction,
  ) {
    $stateProvider
      .state('documents', {
        parent: 'gt.page',
        url: '/documents',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('documents.list', {
        url: '/list',
        component: 'documentsPageView',
        resolve: {
          filterLevel: () => {
            return 'documents-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('documents-page-view');
            },
          ],
        },
        data: {
          pageTitle: gettext('Documents'),
          permissions: {
            only: 'view_document',
          },
        },
      });
  }
})();
