import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import React from 'react';

import { cn } from '~/shared/lib/utils';
import { SearchCommandList } from '~/shared/ui/components/search-command-list';
import { ArrowBottomIcon, SortArrowsIcon, ThreeDotsIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { FilterButton } from '../filter-button';
import { SortingItem } from './sorting-item';
import type { SortingOption, SortingValue } from '../../types';

export const SortingSelector: React.FC<{
  sorting?: SortingValue;
  sortingOptions: SortingOption[];
  onChange: (value?: SortingValue) => void;
}> = ({ sorting, sortingOptions, onChange }) => {
  const [open, setOpen] = React.useState(false);

  const selectHandler = React.useCallback(
    (value: string) => {
      onChange({ argument: value, direction: 'asc' });
      setOpen(false);
    },
    [onChange],
  );

  return (
    <div className="flex items-center space-x-4">
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <FilterButton intent={sorting ? 'default' : 'empty'}>
            <div className="flex items-center gap-1">
              <SortArrowsIcon
                size={14}
                className={cn(
                  'pt-0.5',
                  sorting ? 'text-text-section-secondary' : 'text-text-main-secondary',
                )}
              />

              {sorting?.argument ? (
                <p>{`${sortingOptions.find((s) => s.argument === sorting.argument)?.title}: ${sorting.direction == 'asc' ? 'A-Z' : 'Z-A'}`}</p>
              ) : (
                <>Sort</>
              )}
            </div>
          </FilterButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="p-0" side="bottom" align="start">
          <div className="border-stroke-main-light flex items-center justify-between border-b px-2">
            <Popover>
              <PopoverTrigger>
                <div className="text-text-main-secondary ml-1 flex cursor-pointer items-center gap-1 text-xs font-medium">
                  {sorting?.direction === 'asc' ? 'Ascending' : 'Descending'}
                  <ArrowBottomIcon size={14} className="text-stroke-main-strong" />
                </div>
              </PopoverTrigger>
              <PopoverContent className="bg-background-main-primary z-10 flex flex-col gap-0.5 rounded-md p-1 text-xs shadow-2xl">
                <button
                  onClick={() =>
                    onChange(
                      sorting?.argument
                        ? { direction: 'asc', argument: sorting?.argument }
                        : undefined,
                    )
                  }
                  className={cn(
                    'text-text-main-tertiary hover:bg-transparent-light rounded-sm p-1 text-left text-xs transition duration-300 ease-in-out',
                    sorting?.direction === 'asc' &&
                      'bg-background-main-tertiary text-text-main-primary',
                  )}
                >
                  Ascending
                </button>
                <button
                  onClick={() =>
                    onChange(
                      sorting?.argument
                        ? { direction: 'desc', argument: sorting?.argument }
                        : undefined,
                    )
                  }
                  className={cn(
                    'text-text-main-tertiary hover:bg-transparent-light rounded-sm p-1 text-left text-xs transition duration-300 ease-in-out',
                    sorting?.direction === 'desc' &&
                      'bg-background-main-tertiary text-text-main-primary',
                  )}
                >
                  Descending
                </button>
              </PopoverContent>
            </Popover>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger displayChevron={false}>
                <ThreeDotsIcon size={14} className="text-text-main-secondary cursor-pointer" />
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent sideOffset={11}>
                <button
                  className="text-text-additional-danger px-2 py-1 text-xs"
                  onClick={() => onChange()}
                >
                  Delete Sort
                </button>
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          </div>
          <SearchCommandList
            placeholder="Search fields to sort by"
            options={sortingOptions.map((option) => (
              <SortingItem
                key={option.title}
                selectedOption={sorting}
                option={option}
                selectHandler={selectHandler}
              />
            ))}
          />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
