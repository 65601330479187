import { logisticsBasisesList, logisticsBasisesPredictionsRetrieve } from '~/shared/api';
import type { EntityOption } from '~/shared/ui/data-types';

import type { BasisListParams } from '../types';

export class BasisRepository {
  query = async (query: BasisListParams) => {
    const { data } = await logisticsBasisesList({ query });
    return { records: data.results, count: data.count };
  };

  search = async (input: string): Promise<EntityOption[]> => {
    const { data } = await logisticsBasisesPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };
}
