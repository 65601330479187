import type { BillOfLading } from '@gt/api';
import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './logistics-bl-formation.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { BillOfLadingService } from '^/app/execution/components/transport/bill-of-lading/bill-of-lading.service';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const LogisticsBLFormationContainer = {
  bindings: {
    selectedLogistics: '<',
    selectAll: '&',
    onFinish: '&',
    onParamsChanged: '&',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'CoreService',
    'GtUtils',
    'LogisticsService',
    'BillOfLadingService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      BillOfLadingService: BillOfLadingService;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      amountsData: any;
      gettext: ng.gettext.gettextFunction;
      onFinish: any;
      onParamsChanged: any;
      selectAll: any;
      selectAllChecked: any;
      selectAllOnPageChecked: any;
      selectedLogistics: any;
      volumeBase: any;
      volumeBases: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        CoreService: CoreService,
        GtUtils: GtUtilsService,
        LogisticsService: LogisticsService,
        BillOfLadingService: BillOfLadingService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.CoreService = CoreService;
        this.GtUtils = GtUtils;
        this.LogisticsService = LogisticsService;
        this.BillOfLadingService = BillOfLadingService;
        this.gettext = gettext;
        this.volumeBases = [
          { id: 'volume_received', title: this.gettext('Volume received') },
          { id: 'volume_boarded', title: this.gettext('Volume boarded') },
        ];
        this.selectAllOnPageChecked = false;
        this.selectAllChecked = false;
        this.amountsData = {};
        this.volumeBase = 'volume_received';
      }

      selectAllItems() {
        this.selectAllChecked = !this.selectAllChecked;
        this.selectAll({ checked: this.selectAllChecked });
        this.updateBill();
      }
      changeVolumeBase(source: any) {
        if (source.id === this.volumeBase) {
          return;
        }
        this.volumeBase = source.id;
        return this.refreshCheckboxes();
      }
      refreshCheckboxes() {
        return this.onParamsChanged({
          changedFields: {
            volumeBase: this.volumeBase,
          },
        });
      }

      $onInit() {
        this.selectedLogistics = this.selectedLogistics || [];
        this.selectAllChecked = false;
        this.amountsData = {};
      }

      getDistinctLogisticValues(field: string) {
        return [...new Set(this.selectedLogistics.map((item: any) => item[field]))];
      }
      getUniqueLogisticValue(field: string) {
        const distinctValues = this.getDistinctLogisticValues(field);
        if (distinctValues.length === 1) {
          return distinctValues[0];
        }
        return null;
      }

      connectToBl() {
        if (
          this.selectedLogistics.filter((logistic: any) => logistic.selected_bl_volume <= 0).length
        ) {
          return notify(
            this.gettext('Logistics have already connected to bill of lading.'),
            'error',
          );
        }
        this.updateBill();

        const newBill = {
          sale_contract: this.getUniqueLogisticValue('buyer_contract'),
          purchase_contract: this.getUniqueLogisticValue('supplier_contract'),
          volume: this.amountsData.bl_amount_volume,
          container_number: this.getDistinctLogisticValues('vehicle_number').length.toString(),
        };
        const extraData = {
          logisticBillofladingData: this.selectedLogistics.map((logistic: any) => ({
            logistic: logistic.id,
            volume: logistic.selected_bl_volume,
          })),
        };
        this.BillOfLadingService.billOfLadingModal(newBill, extraData)
          .then((blData: BillOfLading) => {
            if (this.shouldUpdateSelectedLogistics()) {
              this.updateSelectedLogistics(blData);
            }
            this.close();
          })
          .catch(this.GtUtils.errorClb);
      }

      updateBill() {
        this.amountsData.bl_amount_volume = this.selectedLogistics.reduce(
          (accumulator: any, currentValue: any) => {
            return Math.round((accumulator + currentValue.selected_bl_volume) * 1000) / 1000;
          },
          0,
        );
      }

      close() {
        return this.onFinish();
      }

      private shouldUpdateSelectedLogistics() {
        return (
          this.volumeBase === 'volume_received' &&
          this.$rootScope.user.settings.REWRITE_TRANSPORT_FIELDS_WHEN_CREATING_BL_FROM_LOGISTICS
        );
      }

      private updateSelectedLogistics(blData: BillOfLading) {
        this.selectedLogistics.forEach((logistic: any) => {
          const newVolumeBoarded =
            (logistic.volume_boarded || 0) + (logistic.selected_bl_volume || 0);
          this.LogisticsService.Logistic.update(
            { partial: true },
            { id: logistic.id, volume_boarded: newVolumeBoarded, boarding_date: blData.date },
          );
        });
      }
    },
  ],
};
