import {
  ContractSerializer,
  ExportContractPatch,
  ExportContractPost,
  ExportContractRecord,
  ExportContractsQueryParams,
} from '~/features/deals/contracts';
import {
  ExportContract,
  contractsExportContractsCreate,
  contractsExportContractsDestroy,
  contractsExportContractsListInfoList,
  contractsExportContractsPartialUpdate,
  contractsExportContractsPredictionsRetrieve,
  contractsExportContractsRetrieve,
  contractsExportContractsTotalsExecutionRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';

export const exportContractsListDatasourceMap = {
  table_info: contractsExportContractsListInfoList,
  table_execution: contractsExportContractsListInfoList,
  table_reconciliation: contractsExportContractsListInfoList,
  table_prepayments_accounting: contractsExportContractsListInfoList,
  default: contractsExportContractsListInfoList,
};

@singleton()
export class ExportContractsRepository extends EntityRepository<
  ExportContract,
  ExportContractsQueryParams,
  ExportContractRecord
> {
  readonly entityName = 'contracts.ExportContract';
  listDatasource: (typeof exportContractsListDatasourceMap)[keyof typeof exportContractsListDatasourceMap] =
    exportContractsListDatasourceMap.default;

  setDatasources(dsKey: ContractSerializer) {
    this.listDatasource =
      exportContractsListDatasourceMap[dsKey] || exportContractsListDatasourceMap.default;
  }

  getFn = async (id: number) => {
    const { data } = await contractsExportContractsRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: ExportContractsQueryParams) => {
    const { data } = await this.listDatasource({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (input: string) => {
    const { data } = await contractsExportContractsPredictionsRetrieve({
      query: { search: input },
    });
    return data.results;
  };

  createFn = async (dto: ExportContractPost) => {
    const res = await contractsExportContractsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  updateFn = async (entity: ExportContractPatch) => {
    const { data } = await contractsExportContractsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  deleteFn = async (id: number) => {
    await contractsExportContractsDestroy({ path: { id } });
  };

  getTotals = async (query: ExportContractsQueryParams) => {
    const { data } = await contractsExportContractsTotalsExecutionRetrieve({ query });
    return data;
  };
}
