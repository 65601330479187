import type ng from 'angular';

import { formatDate } from '~/shared/lib/date';

export class ConsolidatedContractPnlService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getData(params: object, timeout: any) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/consolidated-contract-pnl/',
      params: params,
      timeout: timeout,
    }).then((res: any) => {
      const data = res.data.results;
      if (!data?.data) {
        return {};
      }
      let titleList = res.data.results.title_list;
      titleList = titleList?.filter(this.onlyUnique) || [];
      const years = data.data || [];

      years.forEach((year: any) => {
        this.processYear(year, titleList);
      });

      this.addEmptyPeriods(data);
      this.calculateDiffs(data);
      data.data.forEach((year: any) => {
        year.month_list = year.data.reduce((res: any, quarter: any) => {
          res.push(...(quarter.data || []));
          return res;
        }, []);
      });
      return data;
    });
  }

  processYear(year: any, titleList: any) {
    year.month_list = [];
    year.data.forEach((quarter: any) => {
      this.processQuarter(quarter, year, titleList);
    });
  }

  processQuarter(quarter: any, year: any, titleList: any) {
    year.month_list.push(...(quarter.data || []));
    quarter.data.forEach((month: any) => {
      this.processMonth(month, year, titleList);
    });
  }

  processMonth(month: any, year: any, titleList: any) {
    month.data.forEach((passportGroup: any) => {
      this.processPassportGroup(passportGroup, year, titleList);
    });
  }

  processPassportGroup(passportGroup: any, year: any, titleList: any) {
    titleList
      .filter((title: any) => passportGroup.title === title)
      .forEach((title: any) => {
        if (!year[title]) {
          year[title] = this.initializeYearData();
        }
        this.updateYearData(year[title], passportGroup);
      });
  }

  initializeYearData() {
    return {
      margin_sum: 0,
      margin_t_sum: 0,
      fact_volume_sum: 0,
      valorization_sum: 0,
      margin_without_valorization_sum: 0,
      turnover_sum: 0,
      fact_internal_volume_sum: 0,
      absolute_margin_without_valorization_sum: 0,
    };
  }

  updateYearData(yearData: any, passportGroup: any) {
    yearData.margin_sum += passportGroup.margin_sum;
    yearData.fact_volume_sum += passportGroup.fact_volume_sum;
    yearData.valorization_sum += passportGroup.valorization_sum;
    yearData.margin_without_valorization_sum +=
      passportGroup.margin_sum - passportGroup.valorization_sum;
    yearData.turnover_sum += passportGroup.turnover_sum;
    yearData.margin_t_sum = yearData.margin_sum / (yearData.fact_volume_sum || 1);
    yearData.fact_internal_volume_sum += passportGroup.fact_internal_volume_sum;
    yearData.absolute_margin_without_valorization_sum +=
      passportGroup.margin_sum - passportGroup.valorization_sum;
  }

  calcRowDiffs(curr: any, prev: any) {
    if (curr.emptyData && prev.emptyData) {
      return;
    }
    curr.marginDiff = (curr?.margin_sum || 0) - (prev?.margin_sum || 0);
    curr.marginTDiff = (curr?.margin_t_sum || 0) - (prev?.margin_t_sum || 0);
    curr.volumeDiff = (curr?.fact_volume_sum || 0) - (prev?.fact_volume_sum || 0);

    curr.marginDiffPercent = !prev?.margin_sum
      ? 0
      : Math.round((curr?.marginDiff / Math.abs(prev?.margin_sum)) * 10000 || 0) / 100;
    curr.marginTDiffPercent = !prev?.margin_t_sum
      ? 0
      : Math.round((curr?.marginTDiff / Math.abs(prev?.margin_t_sum)) * 10000 || 0) / 100;
    curr.volumeDiffPercent = !prev?.fact_volume_sum
      ? 0
      : Math.round((curr?.volumeDiff / Math.abs(prev?.fact_volume_sum)) * 10000 || 0) / 100;
  }

  calculateDiffs(data: any) {
    const flatData = data.data.reduce((result: any, year: any) => {
      result[year.year_key] = year.data.reduce(
        (res: any, quarter: any) => [quarter, ...quarter.data, ...res],
        [],
      );
      return result;
    }, {});
    data.data.slice(1).forEach((year: any, yearIndex: number) => {
      const prevYear = data.data[yearIndex];
      this.calcRowDiffs(year, prevYear);
      year.title_list
        .filter((title: any) => year[title] && prevYear[title])
        .forEach((title: any) => {
          this.calcRowDiffs(year[title], prevYear[title]);
        });
      flatData[year.year_key].forEach((period: any) =>
        flatData[prevYear.year_key]
          .filter(
            (prevPeriod: any) =>
              period.quarter_key === prevPeriod.quarter_key &&
              period.period_month === prevPeriod.period_month,
          )
          .forEach((prevPeriod: any) => this.calcRowDiffs(period, prevPeriod)),
      );
    });
  }

  onlyUnique = (value: any, index: number, self: any) => {
    return self.indexOf(value) === index;
  };

  getDateRangeByYear(year: any) {
    let start = new Date(year, 0, 1);
    const end = new Date(year, 11, 1);
    const dateRange: any = [];
    while (start <= end) {
      dateRange.push({
        obj: start,
        key: Number(formatDate(start, 'MM')),
        title: formatDate(start, 'MMM'),
        shortKey: Number(formatDate(start, 'M')),
        yearKey: formatDate(start, 'yyyy'),
      });
      const newDate = start.setMonth(start.getMonth() + 1);
      start = new Date(newDate);
    }
    return dateRange;
  }

  getConsolidatedPositionData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/contracts/contracts/consolidated_risk_position_by_month_cargo/',
      params: params,
    }).then((res: any) => res.data.results);
  }

  addEmptyPeriods(data: any) {
    const quarterPeriodsKeys = [
      { value: 1, months: [1, 2, 3] },
      { value: 2, months: [4, 5, 6] },
      { value: 3, months: [7, 8, 9] },
      { value: 4, months: [10, 11, 12] },
    ];
    data.data.forEach((year: any) => {
      const quarterSet = new Set(year.data.map((quarter: any) => quarter.quarter_key));
      quarterPeriodsKeys.forEach((baseQuarter) => {
        if (!quarterSet.has(baseQuarter.value)) {
          year.data.push({
            quarter_key: baseQuarter.value,
            year_key: year.year_key,
            data: [],
            emptyData: true,
          });
        }
        const currentPeriod = year.data.find(
          (quarter: any) => quarter.quarter_key === baseQuarter.value,
        );
        const monthSet = new Set(currentPeriod.data.map((month: any) => month.period_month));
        baseQuarter.months.forEach((baseMonth) => {
          if (!monthSet.has(baseMonth)) {
            currentPeriod.data.push({ period_month: baseMonth, emptyData: true });
          }
        });
      });
    });
  }
}
ConsolidatedContractPnlService.$inject = ['$http'];
