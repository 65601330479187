import ng from 'angular';

import { billOfLadingListContainer } from './bill-of-lading-list-container/bill-of-lading-list-container.component';
import { BillOfLadingListTableView } from './bill-of-lading-list-table-view/bill-of-lading-list-table-view.component';
import { BillOfLadingModal } from './bill-of-lading-modal/bill-of-lading-modal.component';
import { billOfLadingPageView } from './bill-of-lading-page-view/bill-of-lading-page-view.component';
import { billOfLadingSaleContractGroupedList } from './bill-of-lading-sale-contract-grouped-list/bill-of-lading-sale-contract-grouped-list.component';
import { BillOfLadingService } from './bill-of-lading.service';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const billOfLadingConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('logistics.billoflading', {
      url: '/bills-of-lading/?id',
      component: 'billOfLadingPageView',
      resolve: {
        filterLevel: () => {
          return 'bill-of-lading-page-view';
        },
        queryParams: [
          'PageService',
          '$stateParams',
          function (PageService: PageService, $stateParams: ng.ui.IStateParamsService) {
            const params = PageService.syncUrlFilter('bill-of-lading-page-view');
            if ($stateParams.id) {
              params.id_list = [$stateParams.id];
            }
            return params;
          },
        ],
      },
      data: {
        pageTitle: gettext('Bill of lading'),
        permissions: {
          only: 'view_logistic',
        },
      },
    });
  },
];

export const billOfLading = ng
  .module('execution.transport.billOfLading', [])
  .component('billOfLadingListContainer', billOfLadingListContainer)
  .component('billOfLadingPageView', billOfLadingPageView)
  .component('billOfLadingSaleContractGroupedList', billOfLadingSaleContractGroupedList)
  .component('billOfLadingListTableView', BillOfLadingListTableView)
  .component('billOfLadingModal', BillOfLadingModal)
  .service('BillOfLadingService', BillOfLadingService)
  .config(billOfLadingConfig).name;
