import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import React from 'react';

import { cn } from '~/shared/lib/utils';
import { ChevronDownIcon } from '~/shared/ui/icons';

const CollapsibleRoot = CollapsiblePrimitive.Root;
const CollapsibleTrigger = CollapsiblePrimitive.CollapsibleTrigger;
const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent;

export const Collapse = React.forwardRef<
  HTMLButtonElement,
  {
    toggle: React.ReactNode;
    content: React.ReactNode;
    open?: boolean;
    onOpen?: (open: boolean) => void;
  }
>(({ open, onOpen, toggle, content }, ref) => {
  return (
    <CollapsibleRoot open={open} onOpenChange={onOpen}>
      <CollapsibleTrigger className="w-full" ref={ref}>
        <div className="bg-transparent-lighter text-text-main-light flex h-6 w-full items-center justify-between px-2 text-xs font-semibold">
          {toggle}
          <ChevronDownIcon
            size={14}
            className={cn(
              'text-stroke-main-stronger transition duration-300 ease-in-out data-[state=open]:rotate-180',
              open && 'rotate-180',
            )}
          />
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="w-full">{content}</CollapsibleContent>
    </CollapsibleRoot>
  );
});

Collapse.displayName = 'Collapsible';
