import ng from 'angular';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('generalAgreementItem', {
    bindings: {
      agreement: '<',
      updateList: '<?',
      filterLevel: '<?',
      tab: '<?',
    },
    template: require('./general-agreement-item.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'ContractsService',
    'gtFilterService',
    'DocumentsService',
    'GtUtils',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    ContractsService: ContractsService,
    gtFilterService: GtFilterService,
    DocumentsService: any,
    GtUtils: GtUtilsService,
  ) {
    const vm = this;

    vm.clone = clone;
    vm.openDocxModal = openDocxModal;
    vm.tab = 'specification';
    vm.editAgreement = editAgreement;
    vm.documentsCount = 0;

    vm.$onInit = function () {
      vm.saleFactsFilterLevel = 'sale-facts-' + vm.agreement.id;
      vm.purchaseFactsFilterLevel = 'purchase-facts-' + vm.agreement.id;
      $scope.$on('documents-container__updated', function () {
        updateCounters();
      });
      updateCounters();
    };

    vm.$onChanges = function () {
      vm.updateList =
        vm.updateList ||
        function () {
          gtFilterService.updateQueryParams({}, vm.filterLevel);
        };
    };

    function editAgreement() {
      ContractsService.generalAgreementsModal(vm.agreement).then(function (res: any) {
        if (res == 'cancel') {
          return;
        }
        vm.agreement = res;
        vm.updateList();
      });
    }

    function openDocxModal() {
      return DocumentsService.generateDocxModal('GeneralAgreement', vm.agreement.id);
    }

    function clone() {
      return ContractsService.cloneGeneralAgreement(vm.agreement);
    }

    function updateCounters() {
      GtUtils.getCounters(['documents.document'], {
        object_id: vm.agreement.id,
        content_type: vm.agreement.content_type,
      })
        .then(function (data: any) {
          vm.documentsCount = data['documents.document'];
        })
        .catch(GtUtils.errorClb);
    }
  }
})();
