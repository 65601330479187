import type ng from 'angular';

import template from './customs-cargo-declaration-invoice-position-list-table-view.html?raw';

import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export const CustomsCargoDeclarationInvoicePositionListTableView = {
  bindings: {
    ccdInvoicePositionList: '=?',
    count: '<',
    total: '<',
    filterLevel: '<',
    destroy: '&',
    initParams: '<',
  },
  template,
  controller: [
    'gettext',
    'InvoicePositionService',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      InvoicePositionService: any;
      ccdInvoicePositionList: any;
      count: number;
      filterLevel = 'customs-cargo-declaration-invoice-position-list-table-view';
      gettext: ng.gettext.gettextFunction;
      initParams: any;
      tableData: any;
      tableName = 'customs-cargo-declaration-invoice-position';
      tableOptions: any;
      total: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        InvoicePositionService: any,
        $rootScope: GtRootScopeService,
      ) {
        this.gettext = gettext;
        this.InvoicePositionService = InvoicePositionService;
        this.$rootScope = $rootScope;

        this.count = 0;
        this.tableOptions = {};
        this.tableData = {};
      }

      $onInit() {
        this.ccdInvoicePositionList = this.ccdInvoicePositionList || [];
        this.initParams = this.initParams || {};

        this.$rootScope.$watch(
          () => this.ccdInvoicePositionList,
          (newValue: any, oldValue: any) => {
            newValue.forEach((item: any) => {
              if (item.id) {
                const changedItem = oldValue.filter((oldItem: any) => oldItem.id === item.id);
                if (
                  changedItem.length &&
                  changedItem[0].invoice_position !== item.invoice_position
                ) {
                  this.updateItem(item);
                }
              } else {
                this.updateItem(item);
              }
            });
            this.updateData();
          },
          true,
        );
      }

      $onChanges(changes: any) {
        if (changes.ccdInvoicePositionList || changes.count || changes.total) {
          this.updateData();
        }
      }

      addItem() {
        const newItem = {
          volume: 0,
          _edit: true,
          ...this.initParams,
        };
        this.ccdInvoicePositionList.push(newItem);
      }

      updateData() {
        this.tableOptions = this.getTableOptions();
        this.tableData = {
          rows: this.ccdInvoicePositionList,
          count: this.count,
          total: this.total,
        };
      }

      updateItem(item: any) {
        this.InvoicePositionService.InvoicePositionResource.get(
          { id: item.invoice_position },
          (data: any) => (item.invoice_position_title = data.title),
        );
      }

      removeItem(item: any) {
        this.ccdInvoicePositionList.splice(this.ccdInvoicePositionList.indexOf(item), 1);
      }

      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass: 'contract-charges-table',
          configurable: false,
          showMore: false,
          changePageSize: false,
          templateArgs: {
            removeItem: (item: any) => this.removeItem({ item: item }),
            $rootScope: this.$rootScope,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          columnDefs: [
            {
              columnName: 'volume',
              class: 'td-right-align nowrap',
              cellTemplate: html`
                <span class="label-strong" ng-if="!item._edit">
                  {[{ item.volume || 0 | number: 3 }]}
                  <span class="smaller-label"
                    >{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span
                  >
                </span>
                <span ng-if="item._edit">
                  <input class="form-control" gt-clear-input type="number" ng-model="item.volume" />
                </span>
              `,
              title: this.gettext('Volume'),
            },
            {
              title: this.gettext('Invoice Position'),
              columnName: 'invoice_position',
              class: 'td-left-align',
              cellTemplate: html`
                <span ng-if="item._edit ">
                  <gt-resource-select
                    ng-model="item.invoice_position"
                    placeholder="'invoice position'|translate"
                    resource-name="'finances.invoiceposition'"
                    allow-clear="true"
                    query-params="{
                       use_list:['cargo',],
                    }"
                  ></gt-resource-select>
                </span>
                <span ng-if="!item._edit && item.invoice_position">
                  <a
                    ui-sref="gt.page.payment({id: item.invoice_id })"
                    class="btn-link"
                    target="_blank"
                  >
                    <i class="fa fa-credit-card-alt"></i> {[{ item.invoice_position_title }]} {[{
                    item.invoice_status | translate }]}
                  </a>
                </span>
              `,
            },
            {
              columnName: 'actions',
              class: 'td-left-align',
              cellTemplate: html`<div>
                  <a
                    class="btn btn-xs btn-blue-border"
                    ng-hide="item._edit"
                    ng-click="item._edit = true;"
                  >
                    <i class="fa fa-pencil-square"></i>
                  </a>
                </div>
                <span ng-if="item._edit">
                  <a
                    class="btn btn-md btn-success btn_success"
                    ng-if="item._edit"
                    ng-click="item._edit = false"
                  >
                    <i class="fa fa-floppy-o"></i>
                  </a>
                  <a class="btn btn-md" ng-if="item._edit" ng-click="item._edit = false">
                    <i class="fa fa-times"></i>
                  </a>
                  <a
                    class="btn btn-md btn-danger btn_danger"
                    ng-if="item._edit"
                    ng-click="args.removeItem(item.id);item._edit = false;"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </span>`,
              title: this.gettext('Actions'),
            },
          ],
        };
      }
    },
  ],
};
