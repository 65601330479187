import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './passport-details-item-view.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const passportDetailsItemView = {
  bindings: {
    passport: '<',
    tab: '<',
    filterLevel: '<',
    changeTab: '&?',
    openRoleDetails: '&?',
    openPassportModal: '&?',
    openDocxModal: '&?',
    createVoyage: '&?',
    updateFactsByExecution: '&?',
    updateVolumeFromExportExecution: '&?',
    updateData: '&?',
    createContractFromTickets: '&?',
    openEmailModal: '&?',
    hideWizard: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'ContractsService',
    'LogisticsService',
    'GtUtils',
    'gettext',
    '$window',
    '$httpParamSerializer',
    class {
      $httpParamSerializer: any;
      $scope: ng.IScope;
      $window: ng.IWindowService;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      contracts: any;
      createContractFromTickets: any;
      createVoyage: any;
      gettext: ng.gettext.gettextFunction;
      hideWizard: any;
      openDocxModal: any;
      openPassportModal: any;
      openRoleDetails: any;
      passport: any;
      showDetail: any;
      tab: string;
      updateData: any;
      updateFactsByExecution: any;
      updateVolumeFromExportExecution: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        ContractsService: ContractsService,
        LogisticsService: LogisticsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        $window: ng.IWindowService,
        $httpParamSerializer: any,
      ) {
        this.$scope = $scope;
        this.ContractsService = ContractsService;
        this.LogisticsService = LogisticsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.$window = $window;
        this.$httpParamSerializer = $httpParamSerializer;
        this.passport = {};
        this.tab = '';

        this.hideWizard = undefined;
        this.openRoleDetails = undefined;
        this.openPassportModal = undefined;
        this.openDocxModal = undefined;
        this.createVoyage = undefined;
        this.updateFactsByExecution = undefined;
        this.updateVolumeFromExportExecution = undefined;
        this.updateData = undefined;
        this.createContractFromTickets = undefined;

        this.contracts = {
          sale: [],
          purchase: [],
          export: [],
        };

        if ($rootScope.user.profile?.recap) {
          this.showDetail = true;
        } else {
          this.showDetail = false;
        }
      }

      $onInit() {
        this.$scope.$watch(
          () => this.passport,
          (cur: any) => cur?.id && this.getContracts(),
        );
      }

      getContracts() {
        this.contracts = {
          sale: [],
          purchase: [],
          export: [],
        };

        this.ContractsService.getContracts({
          with_passport: this.passport.id,
        })
          .then((contracts: any) => {
            this.contracts = {
              sale: contracts.results.filter(
                ({ contract_type: contractType }: any) => contractType === 'sale',
              ),
              purchase: contracts.results.filter(
                ({ contract_type: contractType }: any) => contractType === 'purchase',
              ),
              export: contracts.results.filter(
                ({ deal_type: dealType }: any) => dealType === 'export',
              ),
            };
          })
          .catch(this.GtUtils.errorClb);
      }

      setSaleContractToPurchaseLogistics() {
        if (this.contracts.sale.length !== 1) {
          notify(this.gettext('Passport have no sale contract or more then one'), 'error');
          return;
        }

        this.getLogistics({
          with_buyer_contract: 0,
        }).then((logistics: any) => {
          const { id: saleContractId } = this.contracts.sale[0];
          this.updateLogistics(logistics, {
            buyer_contract: saleContractId,
          });
        });
      }

      setExportContractToPurchaseLogistics() {
        if (this.contracts.export.length !== 1) {
          notify(this.gettext('Passport have no export contract or more then one'), 'error');
          return;
        }

        this.getLogistics({
          with_export_contract: 0,
        }).then((logistics: any) => {
          const { id: exportContractId } = this.contracts.export[0];
          this.updateLogistics(logistics, {
            export_contract: exportContractId,
          });
        });
      }

      getLogistics(params: object) {
        const purchaseContracts = this.contracts.purchase.map((contract: any) => contract.id);
        if (purchaseContracts.length === 0) {
          return notify(
            this.gettext('There is no purchase contracs assigned to this passport'),
            'error',
          );
        }

        return this.LogisticsService.getLogistics({
          supplier_contract_list: purchaseContracts,
          page_size: 100000,
          ...params,
        })
          .then((logistics: any) => logistics.results)
          .catch(this.GtUtils.errorClb);
      }

      updateLogistics(logistics: any, updateData: any) {
        const updatedLogistics = logistics.map((logistic: any) => ({
          ...logistic,
          ...updateData,
        }));

        this.LogisticsService.updateLogistics(updatedLogistics)
          .then((logistics: any) => {
            const message = logistics?.results.length
              ? `${logistics.results.length} ${this.gettext(
                  'logistic(s) have been successfully linked to the sales contract',
                )}`
              : this.gettext('No logistic to update');
            notify(message);
          })
          .catch(this.GtUtils.errorClb);
      }
    },
  ],
};
