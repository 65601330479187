import { clientsCustomclientrolesPredictionsRetrieve } from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository, Paginated } from '~/shared/lib/entities';

import {
  CustomClientRole,
  CustomClientRolePatch,
  CustomClientRolePost,
  CustomClientRoleQueryParams,
} from '../lib';

@singleton()
export class CustomClientRoleRepository extends EntityRepository<
  CustomClientRole,
  CustomClientRoleQueryParams,
  CustomClientRole,
  CustomClientRolePost,
  CustomClientRolePatch
> {
  readonly entityName = 'clients.CustomClientRole';

  getFn = (_id: number): Promise<CustomClientRole> => {
    throw new Error('method not implemented');
  };
  queryFn = (_params: CustomClientRoleQueryParams): Promise<Paginated<CustomClientRole>> => {
    throw new Error('method not implemented');
  };
  searchFn = async (search: string, _id?: number) => {
    const { data } = await clientsCustomclientrolesPredictionsRetrieve({
      query: { search },
    });
    return data.results;
  };

  createFn = (_entity: CustomClientRolePost): Promise<CustomClientRole> => {
    throw new Error('method not implemented');
  };
  updateFn = (_entity: CustomClientRolePatch): Promise<CustomClientRole> => {
    throw new Error('method not implemented');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('method not implemented');
  };
}
