import { coreGlobalSearchRetrieve } from '~/shared/api';
import { singleton } from '~/shared/lib/di';

import { SearchItem } from '../lib/types';

@singleton()
export class GlobalSearchRepository {
  async search(text: string): Promise<SearchItem[]> {
    const { data } = await coreGlobalSearchRetrieve({ query: { search: text } });

    return Object.entries(data as Record<string, { id: number; title: string }[]>)
      .map(([group, items]) =>
        items.map(({ id, title }) => ({
          title,
          group,
          icon: 'HouseIcon' as const,
          action: () => console.info(`Search result ${group} #${id} clicked`),
        })),
      )
      .flat();
  }
}
