import ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';
import { notify } from '~/shared/lib/notify';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('accounts.legacy').controller('UserModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    'item',
    'GtUtils',
    'AccountsService',
    'gettext',
    'FormFieldParamsService',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    item: any,
    GtUtils: GtUtilsService,
    AccountsService: AccountsService,
    gettext: ng.gettext.gettextFunction,
    FormFieldParamsService: FormFieldParamsService,
  ) {
    const vm = this;
    vm.item = item || { profile: {} };

    vm.filterConfig = {};
    vm.tab = 'edit';
    vm.close = close;
    vm.destroy = destroy;
    vm.save = save;
    vm.updating = false;
    vm.form = undefined;
    vm.formName = 'user-edit-modal';
    vm.openFieldsConfigModal = openFieldsConfigModal;
    vm.fields = undefined;

    activate();

    ////////////////

    function activate() {
      if (vm.item.id) {
        updateData();
      } else {
        vm.item = { user_groups_ids: [], user_permissions: [] };
        updateFields();
      }
    }

    function updateData() {
      AccountsService.User.get(vm.item.id)
        .then((data: any) => {
          vm.item = data;
          vm.item.user_permissions = data.perms;
          vm.item.business_units_ids = vm.item.profile.business_units_ids;
          updateFields();
        })
        .catch(errorHandler);
    }

    function openFieldsConfigModal() {
      FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(updateFields);
    }

    function updateFields() {
      FormFieldParamsService.getFields(getFormConfig())
        .then((fields: any) => (vm.fields = fields))
        .catch(errorHandler);
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete user?');
      if (!confirm(msg)) {
        return;
      }
      setUpdating(true);
      AccountsService.User.delete(vm.item.id)
        .then(() => {
          notify(gettext('User removed'));
          close('destroy', true);
        })
        .catch(errorHandler)
        .finally(() => setUpdating(false));
    }
    function setUpdating(value: any) {
      vm.updating = value;
      GtUtils.overlay(value ? 'show' : 'hide');
    }
    function setProfileBusinessUnits() {
      if (!vm.item.business_units_ids) {
        return;
      }
      if (!vm.item.profile) {
        vm.item.profile = {};
      }
      vm.item.profile.business_units_ids = vm.item.business_units_ids;
      vm.item.profile.business_units = vm.item.business_units_ids.map((bu: any) => ({
        businessunit: bu,
      }));
    }
    function deleteAvatar() {
      if (!vm.item.id) {
        return;
      }
      if (!vm.item.profile?.avatar) {
        return;
      }
      delete vm.item.profile.avatar;
    }

    function prepareToSave() {
      setProfileBusinessUnits();
      deleteAvatar();
      if (!vm.item.is_active) {
        vm.item.is_active = false;
      }
    }

    function save() {
      prepareToSave();
      const message = vm.item.id ? gettext('User updated') : gettext('User created');
      const saveResource = vm.item.id ? AccountsService.User.update : AccountsService.User.save;
      setUpdating(true);
      return saveResource(vm.item)
        .then((data) => close(data, true))
        .catch(errorHandler)
        .finally(() => {
          setUpdating(false);
          notify(message);
        });
    }

    function getFormConfig() {
      const col1: any = {
        className: 'form-group-container col-sm-4 col-xs-12',
        fieldGroup: [],
      };
      const col2: any = {
        className: 'form-group-container col-sm-4 col-xs-12',
        fieldGroup: [],
      };
      const col3: any = {
        className: 'form-group-container col-sm-4 col-xs-12',
        fieldGroup: [],
      };

      col1.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('Info'),
        },
        fieldGroup: [
          {
            key: 'username',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Username'),
              placeholder: gettext('Type username of user'),
              hint: gettext('The username for internal use'),
              required: true,
            },
          },
          {
            key: 'first_name',
            type: 'gt-input',
            templateOptions: {
              label: gettext('First name'),
              placeholder: gettext('Type first name of user'),
              hint: gettext('The first name for internal use'),
              required: true,
            },
          },
          {
            key: 'last_name',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Last name'),
              placeholder: gettext('Type last name of user'),
              hint: gettext('The last name for internal use'),
              required: true,
            },
          },
          {
            key: 'email',
            type: 'gt-input',
            templateOptions: {
              label: gettext('email'),
              placeholder: gettext('Type email of user'),
              hint: gettext('The email for internal use'),
              required: true,
            },
          },
          {
            key: 'profile.phone_number',
            type: 'gt-input',
            templateOptions: {
              label: gettext('phone number'),
              placeholder: gettext('Type phone number of user'),
              hint: gettext('The phone number for internal use'),
            },
          },
          {
            key: 'profile.telegram_username',
            type: 'gt-input',
            templateOptions: {
              label: gettext('telegram username'),
              placeholder: gettext('Type telegram username of user'),
              hint: gettext('The telegram username for internal use'),
            },
          },
        ],
      });

      col2.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('access rights'),
        },
        fieldGroup: [
          {
            key: 'is_active',
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('is active'),
            },
          },
          {
            key: 'is_staff',
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('is staff'),
            },
          },
          {
            key: 'is_superuser',
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('is superuser'),
            },
          },
        ],
      });

      col3.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('Business Units'),
        },
        fieldGroup: [
          {
            key: 'business_units_ids',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: gettext('Business Units'),
              resource: 'core.businessunit',
              hint: gettext('You can pick up multiple objects, clicking with CTRL'),
              addIcon: GtUtils.getIcon('core.BusinessUnit'),
              queryParams: { use: 'user_modal' },
            },
          },
        ],
      });

      return {
        formName: 'user-edit-modal',
        fieldsDef: [col1, col2, col3],
      };
    }
  }
})();
