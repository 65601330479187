export class SampleControlService {
  $resource: ng.resource.IResourceService;
  sampleControlResource: any;
  constructor($resource: ng.resource.IResourceService) {
    this.$resource = $resource;
    this.sampleControlResource = $resource(
      '/api/logistics/sample-control/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        listInfo: {
          method: 'GET',
          url: '/api/logistics/sample-control-list/info/',
        },
      },
    );
  }

  getListData(params: object) {
    return this.sampleControlResource.listInfo(params).$promise.then((data: any) => {
      return { data: data };
    });
  }

  save(item: any) {
    if (item.id) {
      return this.sampleControlResource.update(item).$promise;
    } else {
      return this.sampleControlResource.save(item).$promise;
    }
  }

  delete(bill: any) {
    return this.sampleControlResource.delete(bill).$promise;
  }
}
SampleControlService.$inject = ['$resource'];
