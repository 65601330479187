import React from 'react';

import { dateFormat } from '~/shared/lib/date';
import { Trans } from '~/shared/lib/i18n';

export const GreetingCard: React.FC<{ name: string }> = ({ name }) => {
  return (
    <section className="border-stroke-main-medium bg-background-main-primary w-[240px] rounded-md border p-6">
      <div className="text-text-main-tertiary text-xs" data-testid="greeting-date">
        {dateFormat(new Date(), 'dd MMMM yyyy')}
      </div>
      <div className="mt-6 flex flex-col">
        <h2 className="text-xl font-semibold">
          <Trans>Hello, {name}!</Trans>
        </h2>
        <p className="text-text-main-secondary text-xs">
          <Trans i18nKey="Check out what’s new!" />
        </p>
      </div>
    </section>
  );
};
