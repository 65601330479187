import { logisticsTerminalsPredictionsRetrieve } from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository, Paginated } from '~/shared/lib/entities';

import { Terminal, TerminalPatch, TerminalPost, TerminalQueryParams } from '../lib';

@singleton()
export class TerminalRepository extends EntityRepository<
  Terminal,
  TerminalQueryParams,
  Terminal,
  TerminalPost,
  TerminalPatch
> {
  readonly entityName = 'logistics.Terminal';

  getFn = (_id: number): Promise<Terminal> => {
    throw new Error('method not implemented');
  };
  queryFn = (_params: TerminalQueryParams): Promise<Paginated<Terminal>> => {
    throw new Error('method not implemented');
  };
  searchFn = async (search: string) => {
    const { data } = await logisticsTerminalsPredictionsRetrieve({
      query: { search, is_actual: '1' },
    });
    return data.results;
  };
  createFn = (_entity: TerminalPost): Promise<Terminal> => {
    throw new Error('method not implemented');
  };
  updateFn = (_entity: TerminalPatch): Promise<Terminal> => {
    throw new Error('method not implemented');
  };
  deleteFn = (_id: number): Promise<void> => {
    throw new Error('method not implemented');
  };
}
