import React from 'react';

import { Checkbox } from '~/shared/ui/kit/checkbox';

import { type DataTypeProps } from '../../lib/types';

export const CheckboxRead: React.FC<DataTypeProps<'checkbox'>> = ({
  disabled,
  value,
  onChange: valueChanged,
}) => {
  return (
    <Checkbox
      checked={value}
      size="small"
      onClick={() => valueChanged?.(!value)}
      disabled={disabled}
    />
  );
};
