import { BehaviorSubject, Subject, map, merge, shareReplay, startWith, switchMap, tap } from 'rxjs';

import { singleton } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { setErrorsUser } from '~/shared/lib/errors/state';
import { AbilityBuilder, ability, createMongoAbility } from '~/shared/lib/perms';

import { AuthRepository } from './auth.repository';
import { type AuthCredentials, type CurrentUser } from '../lib';

@singleton()
export class AuthStore {
  private readonly guestUser = {
    id: -1, // -1: not initialized; 0: guest; > 0: user from DB
    first_name: 'Guest',
    username: 'guest',
    settings: {
      DEFAULT_CURRENCY_OBJECT: {},
      CURRENCY_OBJECTS: {},
      LOCAL_CURRENCY_OBJECT: {},
      BASIS_OBJECTS: {},
      DEFAULT_VALUES: {},
      SYSTEM_BLOCKS: {},
    },
  } as unknown as CurrentUser;

  private readonly userChangedSubj = new BehaviorSubject('');
  private readonly loginRequestedSubj = new Subject<AuthCredentials>();
  private readonly logoutRequestedSubj = new Subject<void>();
  private readonly loadingSubj = new BehaviorSubject(true);

  constructor(private readonly authRepo: AuthRepository) {}

  private readonly userLoggedIn$ = this.loginRequestedSubj.pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap((credentials) =>
      this.authRepo.login(credentials).catch((err) => {
        errorHandler(err);
        return 'error';
      }),
    ),
  );

  private readonly userLoaded$ = merge(this.userLoggedIn$, this.userChangedSubj).pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap((result) => {
      return result === 'error'
        ? Promise.resolve(this.guestUser)
        : this.authRepo.getCurrentUser().catch((err) => {
            if (this.guestUser.id != -1) {
              errorHandler(err);
            }
            this.guestUser.id = 0;
            return this.guestUser;
          });
    }),
  );

  private readonly userLoggedOut$ = this.logoutRequestedSubj.pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap(() => this.authRepo.logout().catch(errorHandler)),
    map(() => this.guestUser),
  );

  readonly currentUser$ = merge(this.userLoaded$, this.userLoggedOut$).pipe(
    startWith(this.guestUser),
    tap((user) => {
      if (user.id > 0) {
        this.updateAbility(user);
        setErrorsUser({
          id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          username: user.username,
          email: user.email,
        });
      }
    }),
    tap(() => this.loadingSubj.next(false)),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  readonly isAuthenticated$ = this.currentUser$.pipe(
    map((user) => !!user.id && user.id !== -1),
    startWith(false),
  );

  readonly loading$ = this.loadingSubj.asObservable();
  readonly initialized$ = this.currentUser$.pipe(map((u) => u.id !== -1));

  public loginRequested(credentials: AuthCredentials) {
    this.loginRequestedSubj.next(credentials);
  }

  public logoutRequested() {
    this.logoutRequestedSubj.next();
  }

  public userChanged() {
    this.userChangedSubj.next('');
  }

  private updateAbility(user: CurrentUser) {
    const { can, rules } = new AbilityBuilder(createMongoAbility);
    user.perms
      .filter((perm) => perm.includes('_'))
      .map((perm) => [perm.split('_')[0], perm.split('_').slice(1).join('_')])
      .forEach(([action, subject]) => can(action, subject));
    ability.update(rules);
  }
}
