import type ng from 'angular';

import template from './filter-config-modal.html?raw';
import type { CoreService } from '../../core.service';
import type { PageService } from '../../legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '../../legacy/gt-utils/gt-utils.srv';
import type { FormFieldParamsService } from '../form-field-params/form-field-params.service';

import { html } from '^/shared/utils';

export const FilterConfigModal = {
  bindings: { modalInstance: '<' },
  template,
  controller: [
    'CoreService',
    'PageService',
    'FormFieldParamsService',
    'GtUtils',
    'gettext',
    class {
      CoreService: CoreService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      fields: any;
      filterConfig: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      pageConfig: any;
      constructor(
        CoreService: CoreService,
        PageService: PageService,
        FormFieldParamsService: FormFieldParamsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.CoreService = CoreService;
        this.PageService = PageService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.fields = [];
      }

      $onInit() {
        this.pageConfig = this.PageService.getConfig();
        this.filterConfig = {
          filter_level: this.pageConfig.filters?.filterLevel,
        };

        this.getConfig(this.filterConfig.filter_level).then(() => this.updateFields());
      }

      getConfig(filterLevel: string) {
        return this.CoreService.getFilterConfig(filterLevel).then((config: any) => {
          this.filterConfig = { ...this.filterConfig, ...config };
        });
      }

      updateFields() {
        return this.FormFieldParamsService.getFields(this.buildFormConfig()).then(
          (fields: any) => (this.fields = fields),
        );
      }

      buildFormConfig() {
        const filterType: any = {
          dateSelects: 'DATE RANGE',
          selects: 'SELECT FILTERS',
          multiSelects: 'MULTISELECT FILTERS',
          nestedSelects: 'OPTIONS',
          nestedMultiSelects: 'MULTISELECT OPTIONS',
          searchFilters: 'SEARCH FILTERS',
        };

        const buildColumns = (pageFilters: any) => {
          return Object.keys(filterType)
            .filter((type) => pageFilters[type]?.length)
            .map((type) => buildColumn(type, filterType[type], pageFilters[type]));
        };

        const buildColumn = (type: any, colLabel: any, fields: any) => {
          return {
            className: 'form-group-container col-sm-12 col-xs-12 page-filter-modal',
            fieldGroup: [
              {
                wrapper: 'gt-panel',
                templateOptions: { label: this.gettext(`${colLabel}`) },
                fieldGroup: [
                  {
                    template: html`
                      <div class="row column-headers">
                        <div class="col-xs-4 title-column label">
                          <strong>Title</strong>
                        </div>
                        <div class="col-xs-4 new-title-column label">
                          <strong>New Title</strong>
                        </div>
                        <div class="col-xs-4 select-column label">
                          <strong>Select</strong>
                        </div>
                      </div>
                    `,
                  },
                  {
                    className: 'row select-all-row',
                    template: `
                      <div class="col-xs-4"></div>
                      <div class="col-xs-4"></div>
                      <div class="col-xs-4 select-all-wrapper">
                        <input
                          id="selectAllCheckbox"
                          type="checkbox"
                          ng-model="model.${type}.selected"
                        />
                        <span for="selectAllCheckbox">Select/Unselect All</span>
                      </div>
                    `,
                  },
                  {
                    template: '<h3></h3>',
                  },
                  ...fields.map((field: any) => ({
                    className: 'row',

                    fieldGroup: [
                      {
                        className: 'col-xs-4 title-column',
                        ...buildDefaultTitleField(type, field),
                      },
                      {
                        className: 'col-xs-4 new-title-column',
                        ...buildCustomTitleField(type, field, 'gt-input'),
                      },
                      {
                        className: 'col-xs-4 select-column',
                        ...buildIsVisibleField(type, field, 'gt-checkbox'),
                      },
                    ],
                  })),
                ],
              },
            ],
          };
        };

        const buildIsVisibleField = (type: any, field: any, inputType: any) => {
          return {
            key: `${type}.${field.argument}`,
            type: inputType,
            defaultValue: true,
            templateOptions: { label: '' },
            controller: [
              '$scope',
              function ($scope: ng.IScope) {
                $scope.$watchCollection(`model.${type}.selected`, (newVal: any, oldVal: any) => {
                  if (newVal !== oldVal) {
                    ($scope as any).model[type][field.argument] = ($scope as any).model[
                      type
                    ].selected;
                  }
                });
              },
            ],
          };
        };

        const buildDefaultTitleField = (type: any, field: any) => {
          return {
            key: `${type}.${field.argument}`,
            template: `<span>${field.placeholder}</span>`,
            templateOptions: { label: this.gettext(`${field.placeholder || field.title}`) },
          };
        };

        const buildCustomTitleField = (type: any, field: any, inputType: any) => {
          return {
            key: `${type}.${field.argument}Title`,
            type: inputType,
            defaultValue: this.gettext(`${field.placeholder}`),
          };
        };

        return {
          formName: 'filter-config-modal',
          fieldsDef: buildColumns(this.pageConfig.filters),
        };
      }

      save() {
        this.GtUtils.overlay('show');
        const resource = this.filterConfig.id
          ? this.CoreService.PageFilterConfig.update
          : this.CoreService.PageFilterConfig.save;

        resource({
          ...this.filterConfig,
          config: JSON.stringify(this.filterConfig.config),
        })
          .$promise.then(() => this.close(false, true), this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return false;
        }
        this.modalInstance.close(data || 'close');
      }
    },
  ],
};
