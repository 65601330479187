import type ng from 'angular';

import type { ContractsService } from '../legacy/contracts.srv';
export class SaleExportContractAllocationService {
  $resource: ng.resource.IResourceService;
  ContractsService: ContractsService;
  allocalionResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $resource: ng.resource.IResourceService,
    gettext: ng.gettext.gettextFunction,
    ContractsService: ContractsService,
  ) {
    this.$resource = $resource;
    this.gettext = gettext;
    this.ContractsService = ContractsService;
    this.allocalionResource = $resource(
      '/api/contracts/sale-export-contract-allocations/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: {
          method: 'PATCH',
        },
        delete: {
          method: 'DELETE',
        },
        updateVolumeFromPassportExecution: {
          method: 'POST',
          url: '/api/contracts/sale-export-contract-allocations/update_volume_from_passport_execution/',
        },
      },
    );
  }
  getList(params: object) {
    return this.allocalionResource.query(params).$promise;
  }

  save(params: { id: number }) {
    if (params.id) {
      return this.allocalionResource.update(params).$promise;
    }
    return this.allocalionResource.save(params).$promise;
  }

  delete(params: { id: number }) {
    return this.allocalionResource.delete({ id: params.id }).$promise;
  }

  updateVolumeFromPassportExecution(params: object) {
    return this.allocalionResource.updateVolumeFromPassportExecution(params).$promise;
  }

  getNotAllocatedVolume(params: object) {
    return this.ContractsService.refreshContract(params, true, 'light').then((result: any) => {
      return result;
    });
  }
}
SaleExportContractAllocationService.$inject = ['$resource', 'gettext', 'ContractsService'];
