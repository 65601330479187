import type ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import template from './custom-values-table-view.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export const CustomValuesTableView = {
  bindings: {
    tableData: '<',
    filterLevel: '<',
    applyFilters: '&',
    tableName: '<?',
    saveItem: '&',
    deleteItem: '&',
    objectId: '<?',
  },
  template,
  controller: [
    'gettext',
    '$window',
    '$rootScope',
    'GtUtils',
    'ResourcesService',
    class {
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      GtUtils: GtUtilsService;
      ResourcesService: any;
      deleteItem: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      initialLoad: any;
      objectId?: number;
      saveItem: any;
      tableData: any;
      tableName?: string;
      tableOptions: any;
      updateData: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        $window: ng.IWindowService,
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        ResourcesService: any,
      ) {
        this.gettext = gettext;
        this.$window = $window;
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.ResourcesService = ResourcesService;

        this.tableOptions = {};
        this.tableData = {};
        this.filterLevel = 'custom-values-table-view';
      }

      $onInit() {
        this.tableOptions = this.getTableOptions();
        this.tableName = this.tableName ?? 'customs-cargo-declaration';
        this.initialLoad = true;
      }

      $onChanges() {
        this.tableName = this.tableName ?? 'custom-values-table';
        if (this.objectId === undefined && this.initialLoad && this.tableData?.rows) {
          for (const item of this.tableData.rows) {
            this.clearItemValues(item);
          }
        }

        this.tableOptions = this.getTableOptions();
        this.initialLoad = false;
      }

      clearItemValues(item: any) {
        item.value = undefined;
        item.value_choice = undefined;
        item.value_multiple_choices = undefined;
        item.value_date = undefined;
        item.value_number = undefined;
        item.value_string = undefined;
        item.value_text = undefined;
        item.value_boolean = undefined;
      }

      getOwnerRepr(item: any): Promise<string> {
        const resource = this.getResourceName(item);
        if (!resource) {
          return Promise.resolve('---');
        }

        return this.GtUtils.getPredictions(
          resource,
          { id: item.item.object_id },
          this.filterLevel,
          true,
        )
          .then((predictions: any) => {
            if (Array.isArray(predictions)) {
              return predictions.pop()?.title || '---';
            }
            return '---';
          })
          .catch(errorHandler);
      }

      goToObject(item: any) {
        return this.GtUtils.goDetails(item.item.purpose, item.item.object_id, 'customValues');
      }
      getResourceName(item: any) {
        return this.ResourcesService.getResource(item.item.purpose_model);
      }
      getIcon(item: any) {
        return this.GtUtils.getIcon(item.purpose_model);
      }

      getTableOptions() {
        const options: any = {
          tableName: this.tableName,
          tableClass:
            'table-condensed main-table contract-charges-table table-hover table-with-inline-add custom-values-table',
          configurable: true,
          showMore: true,
          templateArgs: {
            saveItem: (item: any) => this.saveItem({ item: item }),
            deleteItem: (item: any) => this.deleteItem({ item: item }),
            updateData: () => this.updateData(),
            $rootScope: this.$rootScope,
            fieldTypes: {
              boolean: this.gettext('Boolean'),
              date: this.gettext('Date'),
              number: this.gettext('Number'),
              string: this.gettext('String'),
              text: this.gettext('Text'),
              choice: this.gettext('Choice'),
              multiple_choices: this.gettext('Multiple choices'),
            },
            goToObject: (item: any) => this.goToObject({ item: item }),
            getIcon: (item: any) => this.getIcon({ item: item }),
            getResourceName: (item: any) => this.getResourceName({ item: item }),
            getOwnerRepr: (item: any) => this.getOwnerRepr({ item: item }),
          },
          filterLevel: this.filterLevel,
          tabs: [],
          columnDefs: [] as any[],
        };
        if (this.filterLevel === 'custom-values-page-view') {
          options.columnDefs.push(
            {
              columnName: 'purpose_model',
              class: 'td-left-align',
              title: this.gettext('Purpose'),
              cellTemplate: html`{[{ item.purpose_model }]}`,
            },
            {
              columnName: 'owner',
              class: 'td-left-align',
              title: this.gettext('Owner'),
              cellTemplate: html`
                <div ng-if="item">
                  <a ng-click="args.goToObject(item)">
                    <i class="fa {[{ args.getIcon(item) }]}"></i>{[{ item.object_id }]}
                  </a>
                </div>
              `,
            },
          );
        }

        options.columnDefs.push(
          {
            columnName: 'field',
            class: 'td-left-align',
            title: this.gettext('Field'),
            classExpr:
              "{'not-empty': (!item.value && !item.value_boolean && !item.value_number && !item.value_string && !item.value_text && !item.value_choice && !item.value_multiple_choices) }",
            cellTemplate: html`{[{ item.field.title }]}`,
          },
          {
            columnName: 'value',
            class: 'td-left-align',
            title: this.gettext('Value'),
            classExpr:
              "{ 'edit': item.editMode, 'not-empty': (!item.value && !item.value_boolean && !item.value_number && !item.value_string && !item.value_text && !item.value_choice && !item.value_multiple_choices) }",
            cellTemplate: html`
              <span ng-if="item && !item.editMode && item.id">
                <span ng-if="item.field.field_type === 'multiple_choices'">
                  <i class="fa fa-check-double"></i>
                  <span ng-repeat="value in item.value"
                    >{[{value}]}
                    <div class="clearfix"></div
                  ></span>
                </span>
                <span ng-if="item.field.field_type !== 'multiple_choices'">
                  <i
                    class="fa"
                    ng-class="{'fa-check-square': item.field.field_type === 'boolean',
                    'fa-calendar': item.field.field_type === 'date',
                    'fa-calculator': item.field.field_type === 'number',
                    'fa-align-center': item.field.field_type === 'string',
                    'fa-keyboard': item.field.field_type === 'text',
                    'fa-check': item.field.field_type === 'choice'}"
                  ></i>
                  {[{ item.value }]}
                </span>
              </span>
              <span ng-if="item && !item.editMode && !item.id">
                <span ng-if="item.value">
                  <span ng-if="item.field.field_type === 'multiple_choices'">
                    <i class="fa fa-check-double"></i>
                    <span ng-repeat="value in item.value"
                      >{[{value}]}
                      <div class="clearfix"></div
                    ></span>
                  </span>
                  <span ng-if="item.field.field_type !== 'multiple_choices'">
                    <i
                      class="fa"
                      ng-class="{'fa-check-square': item.field.field_type === 'boolean',
                      'fa-calendar': item.field.field_type === 'date',
                      'fa-calculator': item.field.field_type === 'number',
                      'fa-align-center': item.field.field_type === 'string',
                      'fa-keyboard': item.field.field_type === 'text',
                      'fa-check': item.field.field_type === 'choice'}"
                    ></i>
                    {[{ item.value }]}
                  </span>
                </span>
              </span>
              <span ng-if="item && item.editMode">
                <span ng-if="item.field.field_type === 'boolean'">
                  <input type="checkbox" ng-model="item.value_boolean" />
                </span>
                <span ng-if="item.field.field_type === 'date'">
                  <gt-date-select
                    placeholder="'Select date'"
                    date-model="item.value_date"
                  ></gt-date-select>
                </span>
                <span ng-if="item.field.field_type === 'number'">
                  <input class="form-control" type="number" ng-model="item.value_number" />
                </span>
                <span ng-if="item.field.field_type === 'string'">
                  <input ng-model="item.value_string" />
                </span>
                <span ng-if="item.field.field_type === 'text'">
                  <textarea
                    class="form-control"
                    placeholder="{[{ 'text'|translate }]}"
                    ng-model="item.value_text"
                  ></textarea>
                </span>
                <span ng-if="item.field.field_type === 'choice'">
                  <gt-resource-select
                    ng-model="item.value_choice"
                    placeholder="'Make your choice'|translate"
                    resource-name="'core.customfieldchoice'"
                    query-params="{field: item.field.id}"
                    allow-clear="true"
                  ></gt-resource-select>
                </span>
                <span ng-if="item.field.field_type === 'multiple_choices'">
                  <gt-resource-multiselect
                    ng-model="item.value_multiple_choices"
                    placeholder="'Make your choices'|translate"
                    resource-name="'core.customfieldchoice'"
                    query-params="{field: item.field.id}"
                    allow-clear="true"
                  ></gt-resource-multiselect>
                </span>
              </span>
              <a
                permission
                permission-only="'change_customvalue'"
                ng-if="!item.editMode"
                class="btn btn-xs btn-blue-border hover-element edit-button"
                ng-click="item.editMode = true"
                style="display: inline-flex; padding: 2px !important;"
              >
                <i class="fa fa-pencil-square"></i>
              </a>
              <div ng-if="item.editMode" class="edit">
                <div class="input-group-btn">
                  <a
                    class="btn btn-xs btn-success btn_success col-xs-12"
                    ng-click="args.saveItem(item); item.editMode = false;"
                  >
                    <i class="fa fa-floppy-o"></i> <translate>Save</translate>
                  </a>
                </div>
                <div class="input-group-btn">
                  <a class="btn btn-xs col-xs-12" ng-click="item.editMode = false">
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <div ng-if="item.id" class="input-group-btn">
                  <a
                    class="btn btn-xs btn-danger btn_danger col-xs-12"
                    ng-click="args.deleteItem(item); item.editMode = false"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </div>
              </div>
            `,
          },
        );
        return options;
      }
    },
  ],
};
