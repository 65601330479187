import type ng from 'angular';

import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class MultipassportService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  MultipassportResource: any;
  constructor($resource: ng.resource.IResourceService, $uibModal: ng.ui.bootstrap.IModalService) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.MultipassportResource = $resource(
      '/api/passports/multipassports/:id/',
      {
        id: '@id',
      },
      {
        save: {
          method: 'POST',
        },
        update: {
          method: 'PATCH',
        },
        list: {
          method: 'GET',
          isArray: false,
          url: '/api/passports/multipassports/list/info/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/passports/multipassports/predictions/',
        },
      },
    );
  }

  getTableData(params: object) {
    return this.MultipassportResource.list(Object.assign(params, {})).$promise;
  }

  multipassportModal(item: any, data: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<multipassport-modal
        item="item"
        data="data"
        modal-instance="$uibModalInstance"
      >
      </multipassport-modal>`,
      controller: [
        '$scope',
        'item',
        'data',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          item: any,
          data: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).item = item;
          ($scope as any).data = data;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        item: () => item,
        data: () => data,
      },
    }).result;
  }

  save(item: any) {
    if (item.id) {
      return this.MultipassportResource.update(item).$promise;
    }
    return this.MultipassportResource.save(item).$promise;
  }
}

MultipassportService.$inject = ['$resource', '$uibModal'];
