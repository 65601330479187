import ng from 'angular';

import type { GtRootScopeService } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('contractPriceWidget', {
    bindings: {
      contractType: '<?',
      initQueryParams: '<?',
      contractPrices: '=?',
      onChange: '<?',
      initContract: '<?',
      fieldsConfig: '<?',
      contractGeneralAgreement: '<?',
    },
    template: require('./contract-price-widget.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', '$rootScope'];

  function Controller(this: any, $scope: ng.IScope, $rootScope: GtRootScopeService) {
    const vm = this;
    vm.removeElement = removeElement;
    vm.addContractPrice = addContractPrice;
    vm.isSaveDisabled = isSaveDisabled;
    vm.changeIsActive = changeIsActive;
    vm.contractGeneralAgreement = [];

    vm.$onInit = function () {
      vm.queryParams = { ...vm.initQueryParams };
      $scope.$on('contract-price-volume__updated', function (ev: any, data: any) {
        vm.newContractPrice.volume = data;
      });

      $scope.$on('contract-price-cargo__updated', function (ev: any, data: any) {
        vm.newContractPrice.cargo = data;
      });

      $scope.$on('general-agreement__updated', function (ev: any, data: any) {
        vm.contractGeneralAgreement = data;
      });

      $scope.$watch(
        'vm.contractPrices',
        () => {
          vm.contractPrices = vm.contractPrices || [];
          const prices = vm.contractPrices.filter((item: any) => item.is_active);
          if (prices.length) {
            const result = prices.reduceRight(
              (acc: any, curr: any) => ({
                ...curr,
                ports: [...(acc.ports || []), ...(curr.port ? [curr.port] : [])],
                price: undefined,
                volume: acc.volume + (curr.volume || 0),
              }),
              { ports: [], volume: 0 },
            );

            result.volume = Math.round(result.volume * 100) / 100;

            vm.onChange(result);
          }
        },
        true,
      );
      getInitialNewContractPrice();
    };

    function changeIsActive(contractPrice: any) {
      if ($rootScope.user.settings.ALLOW_SET_MANY_FEW_CONTRACT_PRICE) {
        return;
      }

      const isActive = contractPrice.is_active;
      vm.contractPrices.map((item: any) => (item.is_active = false));
      contractPrice.is_active = isActive;
    }

    function getInitialNewContractPrice() {
      const lastContractPrice = vm.contractPrices?.[vm.contractPrices.length - 1] || undefined;
      if (lastContractPrice) {
        vm.initContract = {};
      }

      vm.newContractPrice = {
        ...getNewContractPrice(lastContractPrice),
        ...vm.initContract,
      };
    }

    function getNewContractPrice(lastContractPrice: any) {
      return {
        is_active: !lastContractPrice,
        price: 0,
        basis: lastContractPrice?.basis || undefined,
        port: lastContractPrice?.port || undefined,
        cargo: undefined,
        volume: undefined,
        measurement: undefined,
        quantity: undefined,
        date_of_execution: lastContractPrice?.date_of_execution || undefined,
        end_of_execution: lastContractPrice?.end_of_execution || undefined,
        additional_info: undefined,
        contract_option: lastContractPrice?.contract_option || undefined,
      };
    }

    function removeElement(element: any) {
      if (element.is_active) {
        vm.onChange({});
      }
      const index = vm.contractPrices.indexOf(element);
      vm.contractPrices.splice(index, 1);
    }

    function addContractPrice() {
      if (vm.contractPrices.length === 0) {
        vm.newContractPrice.is_active = true;
      }
      vm.contractPrices.unshift(vm.newContractPrice);
      vm.newContractPrice = getNewContractPrice(vm.newContractPrice);
    }

    function isSaveDisabled() {
      if (vm.initContract && vm.initContract.deal_type == 'services') {
        return !vm.newContractPrice.price || !vm.newContractPrice.cargo;
      }
      const isPriceFieldValid = vm.fieldsConfig?.price?.required
        ? !vm.newContractPrice?.price
        : vm.fieldsConfig?.price?.required;
      const isDerivativeFieldValid = vm.fieldsConfig?.derivatives?.required
        ? !vm.newContractPrice?.derivative
        : vm.fieldsConfig?.derivatives?.required;
      const isBasisFieldValid = vm.fieldsConfig?.basis?.required
        ? !vm.newContractPrice?.basis
        : vm.fieldsConfig?.basis?.required;
      const isVolumeFieldValid = vm.fieldsConfig?.volume?.required
        ? !vm.newContractPrice?.volume
        : vm.fieldsConfig?.volume?.required;
      const isDateOfExecutionFieldValid = vm.fieldsConfig?.date_of_execution?.required
        ? !vm.newContractPrice?.date_of_execution
        : vm.fieldsConfig?.date_of_execution?.required;
      const isEndOfExecutionFieldValid = vm.fieldsConfig?.end_of_execution?.required
        ? !vm.newContractPrice?.end_of_execution
        : vm.fieldsConfig?.end_of_execution?.required;

      return (
        isPriceFieldValid ||
        isDerivativeFieldValid ||
        isBasisFieldValid ||
        !vm.newContractPrice.cargo ||
        isVolumeFieldValid ||
        isDateOfExecutionFieldValid ||
        isEndOfExecutionFieldValid
      );
    }
  }
})();
