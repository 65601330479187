import ng from 'angular';

import { CustomsCargoDeclarationDetailsItemContainer } from './item-container/customs-cargo-declaration-details-item-container.component';
import { CustomsCargoDeclarationDetailsItemPage } from './item-page/customs-cargo-declaration-details-item-page.component';
import { CustomsCargoDeclarationDetailsItemView } from './item-view/customs-cargo-declaration-details-item-view.component';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';

const ccdConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('customsCargoDeclarationDetails', {
      parent: 'gt.page',
      url: '/customs-cargo-declaration-details/:id',
      component: 'customsCargoDeclarationDetailsItemPage',
      resolve: {
        filterLevel: () => {
          return 'customs-cargo-declaration-details-item-page';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('customs-cargo-declaration-details-item-page');
          },
        ],
      },
      data: {
        pageTitle: gettext('Cargo details'),
        permissions: {
          only: 'view_customscargodeclaration',
        },
      },
    });
  },
];

export const customsCargoDeclarationDetails = ng
  .module('execution.transport.customsCargoDeclaration.details', [])
  .component('customsCargoDeclarationDetailsItemPage', CustomsCargoDeclarationDetailsItemPage)
  .component(
    'customsCargoDeclarationDetailsItemContainer',
    CustomsCargoDeclarationDetailsItemContainer,
  )
  .component('customsCargoDeclarationDetailsItemView', CustomsCargoDeclarationDetailsItemView)
  .config(ccdConfig).name;
