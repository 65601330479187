export class TerminalCertsLogisticService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getTerminalCertsLogistics(contractId: number, contractType: any) {
    return this.$http
      .get('/api/contracts/contracts/terminal_certs_logistic/', {
        params: {
          contract_id: contractId,
          contract_type: contractType,
        },
      })
      .then((data: any) => data.data);
  }
}
TerminalCertsLogisticService.$inject = ['$http'];
