import ng from 'angular';

import warehouseModalTpl from './containers/warehouse-modal/warehouse-modal.tpl.html?raw';
import objectCropQualitiesEditTpl from './object-crop-qualities-edit/object-crop-qualities-edit.tpl.html?raw';
import qualitiesEditTpl from './qualities-edit/qualities-edit.tpl.html?raw';

import { getModalRoot } from '^/shared/ui/modal';

(function () {
  'use strict';
  ng.module('common.crops.legacy').factory('CropsService', Service);

  Service.$inject = ['$resource', '$uibModal'];

  function Service(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
  ) {
    const QualityResource = $resource(
      '/api/crops/qualities/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          url: '/api/crops/qualities/predictions/',
        },
      },
    );

    const CropResource = $resource(
      '/api/crops/crops/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false, cache: true },
        update: { method: 'PATCH' },
        prices: {
          method: 'GET',
          isArray: true,
          url: '/api/crops/crops/:id/port_prices/',
        },
        predictions: {
          method: 'GET',
          url: '/api/crops/crops/predictions/',
        },
        isCropInAncestors: {
          method: 'GET',
          isArray: false,
          params: { child_crop_id: '@child_crop_id', parent_crop_id: '@parent_crop_id' },
          url: '/api/crops/crops/is_crop_in_ancestors/?child_crop_id=:child_crop_id&parent_crop_id=:parent_crop_id',
        },
      },
    );

    const CropQualityResource = $resource(
      '/api/crops/crop-qualities/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          url: '/api/crops/crop-qualities/predictions/',
        },
      },
    );

    const WarehouseContentResource = $resource(
      '/api/crops/warehouse-contents/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/crops/warehouse-contents/predictions/',
        },
      },
    );

    const QualityRuleTemplateResource = $resource(
      '/api/crops/quality-rule-templates/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/crops/quality-rule-templates/predictions/',
        },
      },
    );

    const ConsignmentQualityResource = $resource(
      '/api/crops/consignment-qualities/:id/',
      {
        id: '@id',
      },
      {
        get: {
          method: 'GET',
        },
        save: {
          method: 'POST',
        },
        query: {
          method: 'GET',
          isArray: false,
        },
        remove: {
          method: 'DELETE',
        },
        delete: {
          method: 'DELETE',
        },
        update: {
          method: 'PATCH',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/crops/consignment-qualities/bulk_create_or_update/',
        },
      },
    );

    const VoyageQualityResource = $resource(
      '/api/logistics/voyage-qualities/:id/',
      {
        id: '@id',
      },
      {
        get: {
          method: 'GET',
        },
        save: {
          method: 'POST',
        },
        query: {
          method: 'GET',
          isArray: false,
        },
        remove: {
          method: 'DELETE',
        },
        delete: {
          method: 'DELETE',
        },
        update: {
          method: 'PATCH',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/logistics/voyage-qualities/bulk_create_or_update/',
        },
      },
    );

    return {
      qualitiesModal: qualitiesModal,
      WarehouseContent: WarehouseContentResource,
      Crop: CropResource,
      Quality: QualityResource,
      CropQuality: CropQualityResource,
      ConsignmentQuality: ConsignmentQualityResource,
      VoyageQuality: VoyageQualityResource,
      QualityRuleTemplate: QualityRuleTemplateResource,
      getMainCrops: getMainCrops,
      warehouseModal: warehouseModal,
      getCrops: getCrops,
      objectCropQualitiesModal: objectCropQualitiesModal,
      makeDetailingCropVolumeByDays: makeDetailingCropVolumeByDays,
    };

    ////////////////

    function qualitiesModal(
      qualities: any,
      planId: number,
      cropId: number,
      contractId: number,
      logisticId = null,
      consignmentId = null,
      commodityIdList = null,
      cropAreaId = null,
      voyageId: number,
    ) {
      return $uibModal.open({
        backdrop: 'static',
        template: qualitiesEditTpl,
        controller: 'CropsQualitiesEditController as vm',
        windowClass: 'modal-template modal-template-three-width',
        appendTo: getModalRoot(),
        resolve: {
          qualities: () => qualities,
          planId: () => planId,
          cropId: () => cropId,
          contractId: () => contractId,
          logisticId: () => logisticId,
          consignmentId: () => consignmentId,
          commodityIdList: () => commodityIdList,
          cropAreaId: () => cropAreaId,
          voyageId: () => voyageId,
        },
      }).result;
    }

    function objectCropQualitiesModal(
      qualities: any,
      cropId: number,
      objectId: number,
      cropQualityResorce: any,
    ) {
      return $uibModal.open({
        backdrop: 'static',
        template: objectCropQualitiesEditTpl,
        controller: 'ObjectCropQualitiesEditController as vm',
        windowClass: 'modal-template modal-template-half-width',
        appendTo: getModalRoot(),
        resolve: {
          qualities: () => qualities,
          cropId: () => cropId,
          objectId: () => objectId,
          cropQualityResurce: () => cropQualityResorce,
        },
      }).result;
    }

    function warehouseModal(
      warehouse: any,
      crop: any,
      extraData: any,
      filterLevel: string,
      warehouseType: any,
    ) {
      return $uibModal.open({
        backdrop: 'static',
        template: warehouseModalTpl,
        controller: 'WarehouseModalController as vm',
        appendTo: getModalRoot(),
        windowClass: 'modal-template',
        resolve: {
          warehouse: () => warehouse,
          crop: () => crop,
          extraData: () => extraData,
          filterLevel: () => filterLevel,
          warehouseType: () => warehouseType,
        },
      }).result;
    }

    function getMainCrops(params?: object) {
      return CropResource.query(params ?? {}).$promise.then(function (data: any) {
        const crops = data.results.filter(function (crop: any) {
          return crop.level === 0;
        });
        ng.forEach(data.results, function (value: any) {
          if (value.level === 0) {
            return false;
          }
          const parent = crops
            .filter(function (crop: any) {
              return crop.id == value.parent || crop.id == value.parent_parent;
            })
            .shift();
          if (!parent) {
            return false;
          }
          parent.children = parent.children || [];
          parent.children.push(value);
        });
        return crops;
      });
    }

    function getCrops(params: object) {
      return CropResource.query(params).$promise;
    }

    function makeDetailingCropVolumeByDays(warehouseData: any) {
      const compare = (prev: any, next: any) => {
        if (prev.warehouse_date > next.warehouse_date) {
          return -1;
        }
        if (prev.warehouse_date < next.warehouse_date) {
          return 1;
        }
        return 0;
      };
      const calculateVolume = (item: any) =>
        item.consignment_data.reduce((v: any, i: any) => v + i.warehouse_volume * i.coef, 0);

      const volumeByDays: any = [
        ...warehouseData.consignment_data,
        ...warehouseData.loss_data,
        ...warehouseData.sale_logistic_data,
        ...warehouseData.shipment_logistic_data,
        ...warehouseData.unloading_logistic_data,
      ]
        .filter((item) => item.warehouse_date)
        .sort(compare)
        .reduce((grouped, consignment, index, source) => {
          if (
            !grouped.some(
              (item: any) => item.date.getTime() === consignment.warehouse_date.getTime(),
            )
          ) {
            grouped.push({
              date: consignment.warehouse_date,
              consignment_data: source.filter(
                (item) =>
                  new Date(consignment.warehouse_date).getTime() ===
                  new Date(item.warehouse_date).getTime(),
              ),
            });
          }
          return grouped;
        }, [])
        .reduceRight((result: any, item: any, index: number, grouped: any) => {
          result[index] = {
            ...item,
            volume:
              index === grouped.length - 1
                ? calculateVolume(item)
                : result[index + 1].volume + calculateVolume(item),
          };
          return result;
        }, []);

      return { volumeByDays: volumeByDays, totalVolume: { volume_sum: warehouseData.volume } };
    }
  }
})();
