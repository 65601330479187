import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './contract-charge-modal.html?raw';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { MulticontractService } from '^/app/deals/multicontract/multicontract.service';

export const ContractChargeModal = {
  bindings: {
    modalInstance: '<',
    contractCharge: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'GtUtils',
    'gettext',
    'ContractsService',
    'ContractChargeFormFieldsService',
    'CustomValuesService',
    'MulticontractService',
    class {
      $scope: ng.IScope;
      $rootScope: GtRootScopeService;
      ContractChargeFormFieldsService: any;
      ContractsService: ContractsService;
      CustomValuesService: CustomValuesService;
      GtUtils: GtUtilsService;
      charge: any;
      contractCharge: any;
      customValues: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      MulticontractService: MulticontractService;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        ContractsService: ContractsService,
        ContractChargeFormFieldsService: any,
        CustomValuesService: CustomValuesService,
        MulticontractService: MulticontractService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.ContractsService = ContractsService;
        this.ContractChargeFormFieldsService = ContractChargeFormFieldsService;
        this.CustomValuesService = CustomValuesService;
        this.MulticontractService = MulticontractService;

        this.form = undefined;
        this.fields = [];
      }

      $onInit() {
        this.charge = this.contractCharge || {};
        this.updateFields();
        this.$scope.$on('custom-values-updated__contractcharge', (event: any, data: any) => {
          this.customValues = data;
        });
      }

      saveCharge(charge: any) {
        return this.ContractsService.ContractCharge.save(charge).$promise.then(
          (savedCharge: { id: number }) => {
            this.CustomValuesService.createCustomValues(savedCharge.id, this.customValues);
            return savedCharge;
          },
        );
      }

      save(
        charge: {
          contract: any;
          passport: any;
          logistic: any;
          multicontract: any;
          vat_value: number;
          id: number;
        },
        initNew: any,
      ) {
        if (!charge.contract && !charge.passport && !charge.logistic && !charge.multicontract) {
          notify(
            this.gettext(
              'Must include either a contract or a passport or a logistic, fill in at least one field.',
            ),
            'error',
          );
          return false;
        }

        charge.vat_value = charge.vat_value || 0;

        if (charge.multicontract && !charge.id) {
          this.GtUtils.overlay('show');
          return this.MulticontractService.getTableData({ id: charge.multicontract })
            .then((data: { results: any[] }) => {
              const multicontract = data.results[0];
              const contractCharges = this.ContractsService.createMultiContractCharge(
                charge,
                multicontract,
              );

              return Promise.all(
                contractCharges.map((newCharge: any) => this.saveCharge(newCharge)),
              )
                .then(() => {
                  notify(
                    this.gettext('Contract charges saved for each contract in the multicontract'),
                  );
                  this.$rootScope.$broadcast('contractChargeUpdated');
                  this.GtUtils.overlay('hide');

                  if (!initNew) {
                    this.modalInstance.close();
                  } else {
                    this.initNewCharge();
                  }
                })
                .catch((error) => {
                  console.error('Error saving charges:', error);
                  this.GtUtils.overlay('hide');
                  notify(this.gettext('An error occurred while saving charges.'), 'error');
                });
            })
            .catch((error: any) => {
              console.error('Error fetching multicontract data:', error);
              this.GtUtils.overlay('hide');
              notify(this.gettext('An error occurred while fetching multicontract data.'), 'error');
            });
        }

        if (charge.id) {
          return this.ContractsService.ContractCharge.update(charge).$promise.then(() => {
            notify(this.gettext('Contract charge updated'));
            this.$rootScope.$broadcast('contractChargeUpdated');
            if (initNew) {
              this.initNewCharge();
            } else {
              this.modalInstance.close();
            }
          }, this.GtUtils.errorClb);
        }

        return this.ContractsService.checkChargeAndConfirmNotUnique(charge).then((save: any) => {
          if (save) {
            return this.ContractsService.ContractCharge.save(charge).$promise.then(
              (savedCharge: { id: number }) => {
                this.CustomValuesService.createCustomValues(savedCharge.id, this.customValues);
                notify(this.gettext('Contract charge saved'));
                this.$rootScope.$broadcast('contractChargeUpdated');
                if (initNew) {
                  this.initNewCharge();
                } else {
                  this.modalInstance.close();
                }
              },
              this.GtUtils.errorClb,
            );
          } else {
            return false;
          }
        });
      }

      delete(charge: any) {
        if (!confirm(this.gettext('Are you sure that you want delete Contract Charge?'))) {
          return false;
        }
        return this.ContractsService.ContractCharge.delete({ id: charge.id }).$promise.then(
          () => this.modalInstance.close(),
          this.GtUtils.errorClb,
        );
      }

      initNewCharge() {
        this.charge = {
          contract: this.charge.contract,
          passport: this.charge.passport,
          currency: this.charge.currency,
        };
        this.updateFields();
      }

      close(data: any) {
        if (confirm(this.gettext('Close modal?'))) {
          return this.modalInstance.close(data || 'close');
        } else {
          return false;
        }
      }

      openFieldsConfigModal() {
        this.ContractChargeFormFieldsService.openFieldsConfigModal(this.charge).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.ContractChargeFormFieldsService.getFields(this.charge).then(
          (fields: any) => (this.fields = fields),
        );
      }

      calculateChargePrice(charge: any, perVolume: any) {
        Object.assign(charge, this.ContractsService.calcContractChargePrice(charge, perVolume));
      }

      getFormConfig() {
        return this.ContractChargeFormFieldsService.getFormConfig(this.charge);
      }
    },
  ],
};
