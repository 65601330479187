import ng from 'angular';

import type { GtUtilsService } from '../../gt-utils/gt-utils.srv';

import type { GtRootScopeService } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('core.legacy').component('gtIframe', {
    bindings: {
      url: '<',
      absoluteUrl: '<',
      width: '&?',
      height: '&?',
    },
    template: require('./gt-iframe.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$rootScope', '$element', '$timeout', 'GtUtils'];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $element: ng.IAugmentedJQuery,
    $timeout: ng.ITimeoutService,
    GtUtils: GtUtilsService,
  ) {
    const vm = this;
    vm.height = vm.height || '100%';
    vm.width = vm.width || '100%';

    vm.$onInit = function () {
      GtUtils.overlay('show');
      if (vm.absoluteUrl) {
        vm.url = vm.absoluteUrl;
      } else {
        vm.url = '/' + $rootScope.user.profile?.language + vm.url;
      }
    };

    vm.$postLink = function () {
      $element.find('iframe').on('load', function () {
        $timeout(function () {
          GtUtils.overlay('hide');
        }, 0);
      });
    };

    ////////////////
  }
})();
