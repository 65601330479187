import type ng from 'angular';

import template from './general-transactions-page-view.tpl.html?raw';

import { type PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import { type GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import { type FinancesService } from '^/app/finances/legacy/finances.srv';

class GeneralTransactionsPageViewController implements ng.IController {
  tableExpanded = false;
  quickAdd = false;
  groupBy = null;
  searchPrompt: string;
  filterLevel?: string;

  static readonly $inject = ['gettext', 'GtUtils', 'FinancesService', 'PageService'];

  constructor(
    private readonly gettext: ng.gettext.gettextFunction,
    private GtUtils: GtUtilsService,
    private FinancesService: FinancesService,
    private PageService: PageService,
  ) {
    this.searchPrompt = this.GtUtils.translate(this.gettext('Number'));
  }

  $onInit() {
    this.filterLevel = this.filterLevel ?? 'general-transactions-page-view';
    this.PageService.setConfig(this.getPageConfig());
  }

  getPageConfig() {
    const config = {
      class: 'page-header-main-payments-tab',
      buttons: [
        new this.PageService.buttons.Filters(),
        new this.PageService.buttons.Print(),
        new this.PageService.buttons.Refresh(this.filterLevel),
      ],
      quickAdd: () => {
        this.quickAdd = !this.quickAdd;
      },
      pages: this.FinancesService.getPagesConfig(),
      filters: {
        filterLevel: this.filterLevel,
        ordering: [],
        clicked: false,
        search: true,
        dates: true,
        searchPrompt: this.searchPrompt,
        selects: [
          {
            argument: 'to_finance_account',
            placeholder: this.gettext('finance debit account'),
            resource: 'finances.FinanceAccount',
            related_fk: 'to_finance_account',
            related_model: 'finances.FinanceTransaction',
          },
          {
            argument: 'from_finance_account',
            placeholder: this.gettext('finance credit account'),
            resource: 'finances.FinanceAccount',
            related_fk: 'from_finance_account',
            related_model: 'finances.FinanceTransaction',
          },
          {
            argument: 'to_counterparty',
            placeholder: this.gettext('debit counterparty'),
            resource: 'clients.client',
            related_fk: 'to_counterparty',
            related_model: 'finances.FinanceTransaction',
          },
          {
            argument: 'from_counterparty',
            placeholder: this.gettext('credit counterparty'),
            resource: 'clients.client',
            related_fk: 'from_counterparty',
            related_model: 'finances.FinanceTransaction',
          },
        ],
        multiSelects: [
          {
            argument: 'business_unit_list',
            placeholder: this.gettext('business unit'),
            resource: 'core.BusinessUnit',
          },
        ],
      },
    };
    return config;
  }
}

export const generalTransactionsPageView: ng.IComponentOptions = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template: template,
  controller: GeneralTransactionsPageViewController,
  controllerAs: 'vm',
};
