import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').component('positionsFactsEditContainer', {
    bindings: {
      initQueryParams: '<?',
      saleFilterLevel: '<?',
      purchaseFilterLevel: '<?',
      onUpdate: '&?',
      saveFacts: '<?',
    },
    template: require('./positions-facts-edit-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'ContractsService', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;
    vm.saleFacts = [];
    vm.saleFactsCount = 0;
    vm.saleTotal = 0;
    vm.purchaseFacts = [];
    vm.purchaseFactsCount = 0;
    vm.purchaseTotal = 0;
    vm.save = save;
    vm.passport = undefined;
    vm.connectToPassport = connectToPassport;

    vm.$onInit = function () {
      vm.saleFactsQueryParams = { ...vm.initQueryParams };
      vm.saleFactsFilterLevel = vm.saleFilterLevel || 'positions-facts-edit-container-sales';
      vm.purchaseFactsQueryParams = { ...vm.initQueryParams };
      vm.purchaseFactsFilterLevel =
        vm.purchaseFilterLevel || 'positions-facts-edit-container-purchases';
      gtFilterService.setQueryParams(vm.saleFactsQueryParams, vm.saleFactsFilterLevel);
      gtFilterService.setQueryParams(vm.purchaseFactsQueryParams, vm.purchaseFactsFilterLevel);

      $scope.$on('gt-filter-updated_' + vm.saleFactsFilterLevel, function (ev: any, data: any) {
        vm.saleFactsQueryParams = data;
        updateSaleFacts();
      });
      $scope.$on('gt-filter-updated_' + vm.purchaseFactsFilterLevel, function (ev: any, data: any) {
        vm.purchaseFactsQueryParams = data;
        updatePurchaseFacts();
      });
      $scope.$on('sale-connections-updated', rebuildSaleFacts);
      $scope.$on('purchase-connections-updated', rebuildPurchaseFacts);
      updateSaleFacts()
        .then(updatePurchaseFacts)
        .then(function () {
          return ContractsService.Passport.get(
            {
              id: vm.initQueryParams.passport,
            },
            function (data: any) {
              vm.passport = data;
            },
          ).$promise;
        });
    };

    vm.$onChanges = function (changes: any) {
      if (changes.saveFacts && vm.saveFacts === true) {
        vm.save();
      }
    };

    ////////////////

    function rebuildSaleFacts(ev: any, contracts: any) {
      const prevCount = vm.saleFacts.length;
      vm.saleFacts = cleanFacts(vm.saleFacts);
      vm.saleFactsCount -= prevCount - vm.saleFacts.length;
      contracts.forEach(function (contract: any) {
        if (
          !contract._toConnect ||
          contractConnectedCheck(vm.saleFacts, contract.id) ||
          !commodityConformityCheck(contract)
        ) {
          return false;
        }
        vm.saleFacts.push(constructFact(contract));
        vm.saleFactsCount++;
      });
      vm.saleTotal = calculateTotal(vm.saleFacts);
    }

    function rebuildPurchaseFacts(ev: any, contracts: any) {
      const prevCount = vm.purchaseFacts.length;
      vm.purchaseFacts = cleanFacts(vm.purchaseFacts);
      vm.purchaseFactsCount -= prevCount - vm.purchaseFacts.length;
      contracts.forEach(function (contract: any) {
        if (
          !contract._toConnect ||
          contractConnectedCheck(vm.purchaseFacts, contract.id) ||
          !commodityConformityCheck(contract)
        ) {
          return false;
        }
        vm.purchaseFacts.push(constructFact(contract));
        vm.purchaseFactsCount++;
      });
      vm.purchaseTotal = calculateTotal(vm.purchaseFacts);
    }

    function contractConnectedCheck(facts: any, contractId: number) {
      return Boolean((facts || []).filter((fact: any) => fact.contract === contractId).length);
    }
    function cleanFacts(facts: any) {
      return facts.filter((fact: any) => fact.id || fact.volume);
    }
    function constructFact(contract: any) {
      return {
        passport: vm.passport.id,
        contract_supplier_name: contract.supplier_name,
        contract_buyer_name: contract.buyer_name,
        volume: parseFloat(contract.available_to_connect),
        contract_object: contract,
        contract_cargo: contract.cargo_id,
        contract_cargo_title: contract.crop_title,
        contract_parent_cargo: contract.parent_cargo_id,
        contract_final_volume: contract.final_volume,
        contract_volume: contract.volume,
        contract_total_connected: contract.total_connected,
        contract_number: contract.contract_number,
        contract_create_time: contract.create_time,
        contract_type: contract.contract_type,
        contract_price: contract.price,
        contract_basis_name: contract.basis_name,
        contract_port_set: contract.port_set,
        contract_date_of_execution: contract.date_of_execution,
        contract_end_of_execution: contract.end_of_execution,
        contract_available_to_connect: contract.available_to_connect,
        contract: contract.id,
        contract_currency_symbol: contract.currency_symbol,
        _dirty: true,
      };
    }
    function updateSaleFacts() {
      GtUtils.overlay('show');
      return ContractsService.SaleFact.queryLight(vm.saleFactsQueryParams, function (data: any) {
        vm.saleFacts = data.results;
        vm.saleFactsCount = data.count;
        vm.saleTotal = calculateTotal(vm.saleFacts);
        GtUtils.overlay('hide');
      }).$promise;
    }

    function updatePurchaseFacts() {
      GtUtils.overlay('show');
      return ContractsService.PurchaseFact.queryLight(
        vm.purchaseFactsQueryParams,
        function (data: any) {
          vm.purchaseFacts = data.results;
          vm.purchaseFactsCount = data.count;
          vm.purchaseTotal = calculateTotal(vm.purchaseFacts);
          GtUtils.overlay('hide');
        },
      ).$promise;
    }

    function calculateTotal(facts: any) {
      return facts.reduce((total: any, fact: any) => {
        return total + fact.volume;
      }, 0);
    }

    function commodityConformityCheck(contract: any) {
      const passportFacts = contract.contract_type === 'sale' ? vm.purchaseFacts : vm.saleFacts;
      if (passportFacts.length === 0) {
        return true;
      }
      if (passportFacts.map((fact: any) => fact.contract_cargo).includes(contract.cargo_id)) {
        return contract._toConnect;
      } else {
        contract._toConnect = confirm(
          `The commodity of the ${GtUtils.capitalize(
            contract.contract_type,
          )} contract is different from the commodity of the ${GtUtils.capitalize(
            passportFacts[0].contract_type,
          )} contract. Are you sure you want to continue?`,
        );
        return contract._toConnect;
      }
    }

    function save(): void {
      let chain = Promise.resolve();

      vm.purchaseFacts.concat(vm.saleFacts).forEach((fact: any) => {
        if (fact._error) {
          notify(fact._error, 'error');
        }
      });

      GtUtils.overlay('show');
      vm.saleFacts.forEach(function (fact: any) {
        if (!fact._dirty) {
          return;
        }
        chain = chain.then(function () {
          delete fact.contract_object;
          if (fact.id && fact.volume) {
            return ContractsService.SaleFact.update(fact).$promise;
          } else if (!fact.volume && fact.id) {
            return ContractsService.SaleFact.delete({ id: fact.id }).$promise;
          } else if (fact.volume) {
            return ContractsService.SaleFact.save(fact).$promise;
          }
        });
      });
      vm.purchaseFacts.forEach(function (fact: any) {
        if (!fact._dirty) {
          return;
        }
        chain = chain.then(function () {
          delete fact.contract_object;
          if (fact.id && fact.volume) {
            return ContractsService.PurchaseFact.update(fact).$promise;
          } else if (!fact.volume && fact.id) {
            return ContractsService.PurchaseFact.delete({ id: fact.id }).$promise;
          } else if (fact.volume) {
            return ContractsService.PurchaseFact.save(fact).$promise;
          }
        });
      });
      chain.then(
        function () {
          GtUtils.overlay('hide');
          vm.onUpdate({ withPage: true });
        },
        function (data) {
          GtUtils.errorClb(data);
          GtUtils.overlay('hide');
          vm.onUpdate({ withPage: true });
        },
      );
    }
    function connectToPassport(contract: any) {
      if (contract.contractId === -1) {
        return notify('This is not contract.');
      }

      if (!commodityConformityCheck(contract)) {
        return false;
      }
      return ContractsService.connectToPassport({
        id: vm.passport.id,
        deal_id: contract.contractId,
        stage: 'contract',
        type: contract.contractType,
        volume: contract.volume,
      }).then(
        (res: any) => {
          if (res === undefined) {
            return false;
          } else if (contract.factId !== undefined) {
            (contract.factType == 'sale'
              ? ContractsService.SaleContract
              : ContractsService.PurchaseContract
            ).delete({ id: contract.factId }, vm.onUpdate);
          } else if (contract.contractType === 'sale') {
            updateSaleFacts();
          } else {
            updatePurchaseFacts();
          }
          notify('Passport updated');
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }
  }
})();
